'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PulseLine = undefined;

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Base = _styledComponents2.default.div.withConfig({
  displayName: 'PulseSkeletons__Base',
  componentId: 'sc-18uighg-0'
})(['border-radius:4px;animation-duration:2.2s;animation-fill-mode:forwards;animation-iteration-count:infinite;animation-name:pulse;animation-timing-function:linear;background:#ddd;background:linear-gradient(to right,#f6f6f6 8%,#eee 18%,#f6f6f6 33%);background-size:1200px 100%;@-webkit-keyframes pulse{0%{background-position:-100% 0;}100%{background-position:100% 0;}}@keyframes pulse{0%{background-position:-1200px 0;}100%{background-position:1200px 0;}}']);

var PulseLine = (0, _styledComponents2.default)(Base).withConfig({
  displayName: 'PulseSkeletons__PulseLine',
  componentId: 'sc-18uighg-1'
})(['height:10px;width:60px;', ' ', ' ', ''], function (props) {
  return props.margin && 'margin: ' + props.margin + ';  ';
}, function (props) {
  return props.height && 'height: ' + props.height + ';  ';
}, function (props) {
  return props.width && 'width: ' + props.width + ';  ';
});
exports.PulseLine = PulseLine;