'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHelmet = require('react-helmet');

var _Templates = require('./Templates');

var _Templates2 = _interopRequireDefault(_Templates);

var _useBlogPost = require('./useBlogPost');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var config = _ref.config,
      _ref$config = _ref.config,
      postId = _ref$config.postId,
      template = _ref$config.template;

  var postData = (0, _useBlogPost.useBlogPost)(postId);
  var Template = _Templates2.default[template];

  return postData ? _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _reactHelmet.Helmet,
      null,
      _react2.default.createElement(
        'title',
        null,
        postData.title
      ),
      _react2.default.createElement('meta', { name: 'description', content: postData.description }),
      _react2.default.createElement('meta', { name: 'keywords', content: postData.keywords })
    ),
    _react2.default.createElement(Template, { config: config, postData: postData })
  ) : null;
};