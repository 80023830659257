'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TextImage = require('./TextImage');

var _TextImage2 = _interopRequireDefault(_TextImage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TextImage2.default;