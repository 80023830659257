'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Location;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Location(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 28 25' },
      _react2.default.createElement('path', { d: 'M21.195 11.634a7.721 7.721 0 0 0-6.83-6.818V3h-1.72v1.816a7.558 7.558 0 0 0-6.83 6.818H4v1.732h1.816a7.721 7.721 0 0 0 6.829 6.818V22h1.72v-1.816a7.721 7.721 0 0 0 6.83-6.818h1.816v-1.732h-1.816zM13.5 18.548a6.048 6.048 0 1 1 6.059-6.048 6.017 6.017 0 0 1-6.059 6.048z' })
    )
  );
}

Location.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};