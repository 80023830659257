'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _trunc = require('babel-runtime/core-js/math/trunc');

var _trunc2 = _interopRequireDefault(_trunc);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _numbers = require('../../../shared/localisation/numbers');

var _vehicleTypes = require('../../../types/AstonMartin/vehicleTypes');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DistanceFromRetailer = function DistanceFromRetailer(_ref) {
  var vehicle = _ref.vehicle,
      translations = _ref.translations;
  return _react2.default.createElement(
    'div',
    {
      style: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        bottom: '0',
        fontFamily: 'AstonMartinSans-Light',
        fontSize: '16px',
        textTransform: 'none'
      }
    },
    _react2.default.createElement(
      'span',
      null,
      '' + vehicle.retailerName + (!!vehicle.distance ? ' - ' + (0, _trunc2.default)((0, _numbers.converKmToMiles)(vehicle.distance)) + ' ' + translations.milesAway : '')
    )
  );
};

exports.default = DistanceFromRetailer;