'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.default = SelectField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldValidationError = require('./FieldValidationError');

var _FieldValidationError2 = _interopRequireDefault(_FieldValidationError);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'SelectField__Select',
  componentId: 'sc-1moydws-0'
})(['font-family:Bentley-Light;font-style:normal;font-weight:400;font-size:16px;line-height:19px;background:#ffffff;border:1px solid #bbbbbb;color:#757575;border:1px solid #bbbbbb;padding:14px 30px 14px 11px;&:disabled{border:none;-moz-appearance:none;-webkit-appearance:none;padding:0 8px;::-ms-expand{display:none;}}&:focus{color:#444;outline:none;}-webkit-appearance:none;-moz-appearance:none;background-image:url("data:image/svg+xml,%3Csvg width=\'24\' height=\'48\' viewBox=\'0 0 24 24\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1.51977 0L0.826416 0.673568L5.9998 6L11.1735 0.673568L10.48 0L5.9998 4.61198L1.51977 0Z\' fill=\'%237F988F\'/%3E%3C/svg%3E");background-repeat:no-repeat;background-position-x:100%;background-position-y:10px;', ''], function (_ref) {
  var minimal = _ref.minimal;
  return minimal && '\n      border: none;\n      background-color: transparent;\n      padding: 0;\n      background-position: right;\n      background-position-x: 92%;\n      background-size: 12px 6px;\n      background-image: url(\'https://res.cloudinary.com/motortrak/v1669817141/locator/bentley/global/icons/chevron-down.svg\');\n      font-family: Bentley-Regular;\n      font-style: normal;\n      font-weight: 400;\n      font-size: 12px;\n      line-height: 19px;\n      color: #333333;\n      min-width: 45px;\n  ';
});

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'SelectField__Label',
  componentId: 'sc-1moydws-1'
})(['color:#727376;font-size:16px;font-family:Bentley-SemiBold;font-weight:normal;', ''], function (_ref2) {
  var minimal = _ref2.minimal;
  return minimal && 'font-family: Bentley-Regular;\n      font-style: normal;\n      font-weight: 400;\n      font-size: 12px;\n      line-height: 19px;\n      color: #333333;\n      margin-right: 5px;\n      display: block;\n  ';
});

function SelectField(_ref3) {
  var required = _ref3.required,
      id = _ref3.id,
      label = _ref3.label,
      onChange = _ref3.onChange,
      disabled = _ref3.disabled,
      options = _ref3.options,
      valueFromOption = _ref3.valueFromOption,
      labelFromOption = _ref3.labelFromOption,
      pleaseSelect = _ref3.pleaseSelect,
      error = _ref3.error,
      value = _ref3.value,
      dataCy = _ref3.dataCy,
      removeMargin = _ref3.removeMargin,
      minimal = _ref3.minimal,
      _ref3$orientation = _ref3.orientation,
      orientation = _ref3$orientation === undefined ? 'vertical' : _ref3$orientation;

  return _react2.default.createElement(
    _FormField2.default,
    { removeMargin: removeMargin, orientation: orientation },
    label && _react2.default.createElement(
      Label,
      { minimal: minimal },
      label,
      !required && !minimal && ' (optional)'
    ),
    _react2.default.createElement(
      Select,
      {
        onChange: function (_onChange) {
          function onChange(_x) {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function (_ref4) {
          var _ref4$target = _ref4.target,
              val = _ref4$target.value,
              op = _ref4$target.options,
              selectedIndex = _ref4$target.selectedIndex;

          var optionLabel = op[selectedIndex].innerText;
          onChange({ id: id, value: val, label: optionLabel });
        }),
        disabled: disabled,
        value: value,
        'data-cy': dataCy || '',
        minimal: minimal
      },
      [!value && pleaseSelect && _react2.default.createElement(
        'option',
        { key: 'default' },
        pleaseSelect
      )].concat((0, _toConsumableArray3.default)(options.map(function (o) {
        var optionLabel = labelFromOption(o);
        var optionValue = valueFromOption(o);
        return _react2.default.createElement(
          'option',
          { key: '' + optionValue + optionLabel, value: optionValue },
          optionLabel
        );
      }))).filter(Boolean)
    ),
    error && _react2.default.createElement(
      _FieldValidationError2.default,
      null,
      error
    )
  );
}

SelectField.defaultProps = {
  valueFromOption: function valueFromOption(i) {
    return i.value;
  },
  labelFromOption: function labelFromOption(i) {
    return i.label;
  },
  error: '',
  disabled: false,
  required: false,
  removeMargin: false
};