'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Icon__IconWrapper',
  componentId: 'sc-124ycrs-0'
})(['display:flex;align-items:center;svg > path{stroke:', ';}svg{width:', ';height:', ';}'], function (_ref) {
  var colour = _ref.colour;
  return colour || '#fff';
}, function (_ref2) {
  var width = _ref2.width;
  return width;
}, function (_ref3) {
  var height = _ref3.height;
  return height;
});

var Icon = function Icon(_ref4) {
  var colour = _ref4.colour,
      width = _ref4.width,
      height = _ref4.height,
      mobileStyleOverride = _ref4.mobileStyleOverride,
      children = _ref4.children;

  return _react2.default.createElement(
    IconWrapper,
    {
      colour: colour,
      width: width,
      height: height,
      mobileStyleOverride: mobileStyleOverride
    },
    children
  );
};

exports.default = Icon;