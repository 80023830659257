'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _location = require('../../../shared/location');

var _location2 = _interopRequireDefault(_location);

var _useRegionsHook2 = require('../../../shared/hooks/useRegionsHook');

var _searchResultsTypes = require('../../../types/AstonMartin/searchResultsTypes');

var _useLocationFilter2 = require('../../../hooks/useLocationFilter');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _OverlaySearch = require('../../../components/AstonMartin/OverlaySearch');

var _OverlaySearch2 = _interopRequireDefault(_OverlaySearch);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'AstonMartinLocationOverlay__Overlay',
  componentId: 'sc-18m5pab-0'
})(['background-color:rgba(0,0,0,0.7);backdrop-filter:blur(12px);color:#fff;display:flex;justify-content:center;position:fixed;height:100vh;width:100%;top:0;left:0;visibility:hidden;opacity:0;', ';overflow:hidden;transition:visibility 0.35s,opacity 0.35s;', ';'], function (_ref) {
  var open = _ref.open;
  return open && 'z-index: 1000';
}, function (_ref2) {
  var open = _ref2.open;
  return open && 'visibility: visible; opacity: 1;';
});

var OverlayContent = _styledComponents2.default.div.withConfig({
  displayName: 'AstonMartinLocationOverlay__OverlayContent',
  componentId: 'sc-18m5pab-1'
})(['width:100%;max-width:680px;padding:24px;display:flex;align-items:center;justify-content:center;']);

exports.default = function (_ref3) {
  var availableFilterValues = _ref3.shared.filters.availableFilterValues,
      translations = _ref3.config.translations,
      config = _ref3.config,
      dispatch = _ref3.dispatch,
      router = _ref3.router;

  var _useLocationFilter = (0, _useLocationFilter2.useLocationFilter)(),
      _onButtonClick = _useLocationFilter.onButtonClick,
      updateLocationFilter = _useLocationFilter.updateLocationFilter,
      latitude = _useLocationFilter.latitude,
      longitude = _useLocationFilter.longitude;

  var _useLocation = (0, _location2.default)(),
      setLat = _useLocation.setLat,
      setLong = _useLocation.setLong,
      googleAvailable = _useLocation.googleAvailable;

  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      keepOpen = _useState2[0],
      setKeepOpen = _useState2[1];

  var isDealerPage = !!router.pathProps.retailerId;

  var _useRegionsHook = (0, _useRegionsHook2.useRegionsHook)(),
      setRegionFilters = _useRegionsHook.setRegionFilters;

  var checkForMainlandUK = true;

  (0, _react.useEffect)(function () {
    if (router.platformMode && router.params.dealerLatitude && router.params.dealerLongitude) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('location', {
        latitude: router.params.dealerLatitude,
        longitude: router.params.dealerLongitude,
        locationTown: router.params.city,
        userCountry: router.params.country
      }));

      setRegionFilters(router.params.country);
    }
  }, []);

  var handleBrowserLocation = (0, _react.useCallback)(function (_ref4) {
    var lat = _ref4.latitude,
        long = _ref4.longitude,
        locationTown = _ref4.locationTown,
        userCountry = _ref4.userCountry,
        overlayOpen = _ref4.overlayOpen;

    setLat(lat);
    setLong(long);
    setKeepOpen(overlayOpen);
    updateLocationFilter({
      latitude: lat,
      longitude: long,
      locationTown: locationTown,
      userCountry: userCountry
    });
  }, [setLat, setLong, updateLocationFilter]);

  return !config.disabled ? _react2.default.createElement(
    Overlay,
    {
      open: availableFilterValues && !latitude && !longitude && googleAvailable && !isDealerPage || keepOpen === true && !isDealerPage
    },
    _react2.default.createElement(
      OverlayContent,
      null,
      _react2.default.createElement(_OverlaySearch2.default, {
        iconUrl: config.locationIconUrlWhite,
        browserLocationCallback: function browserLocationCallback(lat, long, town, countryCode) {
          handleBrowserLocation({
            latitude: lat,
            longitude: long,
            locationTown: town,
            userCountry: countryCode,
            overlayOpen: true
          });
        },
        inputLocationCallback: function inputLocationCallback(lat, long, town, countryCode) {
          handleBrowserLocation({
            latitude: lat,
            longitude: long,
            locationTown: town,
            userCountry: countryCode,
            overlayOpen: true
          });
        },
        translations: translations,
        onButtonClick: function onButtonClick() {
          setKeepOpen(false);
          _onButtonClick(checkForMainlandUK);
        }
      })
    )
  ) : null;
};