'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GenesisInput = exports.LocationIcon = exports.MarginLeft = exports.InputContainer = exports.SuggestionItem = exports.SuggestionsContainer = exports.Input = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    &&& {font-size: 16px;}\n  '], ['\n    &&& {font-size: 16px;}\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 16px;\n  '], ['\n    font-size: 16px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _useLocation2 = require('../../hooks/useLocation');

var _useLocation3 = _interopRequireDefault(_useLocation2);

var _settings = require('../../shared/selectors/settings');

var _Spinner = require('../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _TextLinkButton = require('./TextLinkButton');

var _TextLinkButton2 = _interopRequireDefault(_TextLinkButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Input = exports.Input = _styledComponents2.default.input.withConfig({
  displayName: 'GenesisInput__Input',
  componentId: 'sc-14th583-0'
})(['width:100%;border:0;background-color:transparent;color:#fff;font-family:\'GenesisSansTextGlobal-Regular\';font-size:18px;margin:8px 16px;vertical-align:middle;&[type=\'text\']{transform:translateY(2px);}&:focus{outline:none;}::placeholder{color:#bbb;}', ';'], _Genesis2.default.max.medium(_templateObject));

var SuggestionsContainer = exports.SuggestionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GenesisInput__SuggestionsContainer',
  componentId: 'sc-14th583-1'
})(['display:flex;width:100%;max-width:580px;margin-top:8px;position:relative;align-items:flex-start;flex-direction:column;background:#1a1a1a;box-shadow:0px 3px 15px 0px rgba(0,0,0,0.1);cursor:pointer;']);

var SuggestionItem = exports.SuggestionItem = _styledComponents2.default.div.withConfig({
  displayName: 'GenesisInput__SuggestionItem',
  componentId: 'sc-14th583-2'
})(['display:flex;height:48px;margin:0px 8px;align-items:center;gap:7px;align-self:stretch;border-bottom:1px solid #333;font-family:\'GenesisSansTextGlobal-Regular\';font-size:18px;color:#666666;transition:all 0.2s linear;&:hover{color:#fff;}&:last-child{border:none;}', ';cursor:pointer;'], _Genesis2.default.max.medium(_templateObject2));

var InputContainer = exports.InputContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GenesisInput__InputContainer',
  componentId: 'sc-14th583-3'
})(['display:flex;max-width:580px;width:100%;justify-content:space-between;align-items:space-between;background:#1e1e1e;margin-top:64px;box-sizing:border-box;align-items:center;']);

var MarginLeft = exports.MarginLeft = _styledComponents2.default.div.withConfig({
  displayName: 'GenesisInput__MarginLeft',
  componentId: 'sc-14th583-4'
})(['margin-left:8px;']);

var LocationIcon = exports.LocationIcon = _styledComponents2.default.img.withConfig({
  displayName: 'GenesisInput__LocationIcon',
  componentId: 'sc-14th583-5'
})(['width:25px;height:25px;border-right:1px solid #aaaba6;padding:16px;cursor:pointer;']);
var SpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'GenesisInput__SpinnerWrapper',
  componentId: 'sc-14th583-6'
})(['']);
var GenesisInput = exports.GenesisInput = function GenesisInput(_ref) {
  var placeholder = _ref.placeholder,
      buttonLabel = _ref.buttonLabel,
      _onClick = _ref.onClick,
      ignorePreferredLocation = _ref.ignorePreferredLocation;

  var _useState = (0, _react.useState)({
    lat: '',
    lng: ''
  }),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      location = _useState2[0],
      setLocation = _useState2[1];

  var _useLocation = (0, _useLocation3.default)(function (lat, lng) {
    return setLocation({ lat: lat, lng: lng });
  }, function (lat, lng) {
    return setLocation({ lat: lat, lng: lng });
  }, { ignorePreferredLocation: ignorePreferredLocation }),
      formattedLocation = _useLocation.formattedLocation,
      getBrowserLocation = _useLocation.getBrowserLocation,
      getInputLocation = _useLocation.getInputLocation,
      onInputChange = _useLocation.onInputChange,
      suggestions = _useLocation.suggestions,
      visible = _useLocation.visible,
      setCurrentlySelected = _useLocation.setCurrentlySelected,
      onInputKeyDown = _useLocation.onInputKeyDown,
      onInputBlur = _useLocation.onInputBlur,
      onInputFocus = _useLocation.onInputFocus,
      isLoading = _useLocation.isLoading;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      colours = _useSelector.colours;

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      InputContainer,
      null,
      _react2.default.createElement(LocationIcon, {
        src: 'https://res.cloudinary.com/motortrak/locator/genesis/icons/locate-icon.svg',
        onClick: getBrowserLocation,
        role: 'button'
      }),
      _react2.default.createElement(Input, {
        type: 'text',
        placeholder: placeholder,
        onChange: onInputChange,
        onKeyDown: onInputKeyDown,
        onInputBlur: onInputBlur,
        onInputFocus: onInputFocus,
        value: formattedLocation
      }),
      isLoading && _react2.default.createElement(
        SpinnerWrapper,
        null,
        _react2.default.createElement(_Spinner2.default, { size: 16, colour: colours.primaryBrandColour })
      ),
      _react2.default.createElement(
        _TextLinkButton2.default,
        {
          variant: 'primary',
          onClick: function onClick() {
            return _onClick(location, formattedLocation);
          },
          disabled: !location.lng && !location.lng,
          styleOverride: function styleOverride() {
            return 'margin: 0px 16px; color: #fff';
          }
        },
        buttonLabel
      )
    ),
    suggestions && suggestions.length > 0 && visible && _react2.default.createElement(
      SuggestionsContainer,
      null,
      suggestions.map(function (suggestion, index) {
        return _react2.default.createElement(
          SuggestionItem,
          {
            role: 'button',
            tabIndex: 0,
            key: index,
            onMouseDown: function onMouseDown() {
              setCurrentlySelected(0);
              getInputLocation(suggestion);
            },
            onKeyDown: function onKeyDown(e) {
              if (e.key === 'Enter') {
                setCurrentlySelected(0);
                getInputLocation(suggestion);
              }
            }
          },
          _react2.default.createElement(
            MarginLeft,
            null,
            suggestion.description
          )
        );
      })
    )
  );
};