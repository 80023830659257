'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = one;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propHelper = function propHelper(a) {
  return a && {
    left: 'flex-start',
    right: 'flex-end'
  }[a] || 'center';
};


var HeaderWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'One__HeaderWrapper',
  componentId: 'sc-4vctpj-0'
})(['display:flex;flex-direction:row;flex-wrap:nowrap;align-content:flex-start;width:100%;margin:0;position:relative;', ';*{box-sizing:border-box;}'], function (_ref) {
  var backgroundColour = _ref.backgroundColour;
  return backgroundColour ? 'background-color: ' + backgroundColour.value : '';
});

var LogoWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'One__LogoWrapper',
  componentId: 'sc-4vctpj-1'
})(['display:flex;width:100px;height:100%;align-items:center;justify-content:', ';'], function (_ref2) {
  var horizontalAlignment = _ref2.horizontalAlignment;
  return propHelper(horizontalAlignment);
});

var HeaderNavigationWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'One__HeaderNavigationWrapper',
  componentId: 'sc-4vctpj-2'
})(['display:flex;flex-direction:column;flex:1 1 100%;justify-content:space-between;word-break:break-word;']);

var NavBarContainer = _styledComponents2.default.nav.withConfig({
  displayName: 'One__NavBarContainer',
  componentId: 'sc-4vctpj-3'
})(['display:flex;width:100%;justify-content:', ';'], function (_ref3) {
  var horizontalAlignment = _ref3.horizontalAlignment;
  return propHelper(horizontalAlignment);
});

var LinksList = _styledComponents2.default.ul.withConfig({
  displayName: 'One__LinksList',
  componentId: 'sc-4vctpj-4'
})(['padding:0;margin:0;li{list-style:none;display:inline-block;}']);

var MenuListItem = _styledComponents2.default.li.withConfig({
  displayName: 'One__MenuListItem',
  componentId: 'sc-4vctpj-5'
})(['margin:0;padding:0 20px;']);

function one(props) {
  var _props$config = props.config,
      _props$config$menu = _props$config.menu,
      menu = _props$config$menu === undefined ? { links: [] } : _props$config$menu,
      _props$config$logo = _props$config.logo,
      logo = _props$config$logo === undefined ? {} : _props$config$logo,
      _props$config$headerF = _props$config.headerFont,
      headerFont = _props$config$headerF === undefined ? {} : _props$config$headerF,
      _props$config$transla = _props$config.translations,
      translations = _props$config$transla === undefined ? {} : _props$config$transla;


  var links = (menu || { items: [] }).links.map(function (value) {
    return (0, _extends3.default)({}, value, {
      label: translations[value.itemId]
    });
  });
  return _react2.default.createElement(
    HeaderWrapper,
    null,
    _react2.default.createElement(
      LogoWrapper,
      { horizontalAlignment: logo.horizontalAlignment },
      _react2.default.createElement(
        _Global.Link,
        { href: logo.url },
        _react2.default.createElement('img', { alt: translations.imageLabel, src: logo.src })
      )
    ),
    _react2.default.createElement(
      HeaderNavigationWrapper,
      null,
      _react2.default.createElement(
        NavBarContainer,
        { horizontalAlignment: menu.horizontalAlignment },
        _react2.default.createElement(
          LinksList,
          null,
          links.map(function (link) {
            return _react2.default.createElement(
              MenuListItem,
              { key: link.label },
              _react2.default.createElement(
                _Global.Link,
                {
                  styleOverride: function styleOverride() {
                    return (0, _Global.fontStyleOverride)(headerFont);
                  },
                  href: link.url
                },
                link.label
              )
            );
          })
        )
      )
    )
  );
}