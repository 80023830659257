'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    '], ['\n    width: 100%;\n    ']);

exports.default = RegionCheckbox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../Global');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Checkbox = _styledComponents2.default.div.withConfig({
  displayName: 'RegionCheckbox__Checkbox',
  componentId: 'sc-1qd6emw-0'
})(['display:flex;width:100%;box-sizing:border-box;border-bottom:1px solid #d5d5d5;padding:', ';align-items:center;justify-content:space-between;outline:none;background-color:#ffffff;color:', ';&:last-child{border-bottom:none;}&:hover{cursor:', ';background-color:', ';}@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){width:228px;', ';}'], function (_ref) {
  var region = _ref.region;
  return region ? '8px 8px 8px 16px' : '8px';
}, function (_ref2) {
  var enabled = _ref2.enabled;
  return enabled ? '#333333' : '#d5d5d5';
}, function (_ref3) {
  var enabled = _ref3.enabled;
  return enabled ? 'pointer' : 'default';
}, function (_ref4) {
  var enabled = _ref4.enabled;
  return enabled ? ' #f8f8f8' : 'none';
}, _theme2.default.max.medium(_templateObject));

function RegionCheckbox(_ref5) {
  var onClick = _ref5.onClick,
      label = _ref5.label,
      filterHeadingFont = _ref5.filtersStyling.filterHeadingFont,
      renderIcon = _ref5.renderIcon,
      region = _ref5.region,
      enabled = _ref5.enabled;

  var filterStyling = enabled ? filterHeadingFont : (0, _extends3.default)({}, filterHeadingFont, { colour: { value: '#D5D5D5' } });
  return _react2.default.createElement(
    Checkbox,
    { enabled: enabled, region: region, onClick: onClick },
    _react2.default.createElement(
      _Global.HeadingTwo,
      { styleOverride: function styleOverride() {
          return (0, _Global.fontStyleOverride)(filterStyling);
        } },
      label
    ),
    renderIcon
  );
}