'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _trunc = require('babel-runtime/core-js/math/trunc');

var _trunc2 = _interopRequireDefault(_trunc);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _numbers = require('../../../shared/localisation/numbers');

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DistanceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFromRetailer__DistanceContainer',
  componentId: 'sc-142satd-0'
})(['border-top:1px solid #fff;display:flex;padding:10px 0;']);

var DistanceLabel = _styledComponents2.default.span.withConfig({
  displayName: 'DistanceFromRetailer__DistanceLabel',
  componentId: 'sc-142satd-1'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:14px;text-transform:uppercase;']);

var DistanceIcon = _styledComponents2.default.img.withConfig({
  displayName: 'DistanceFromRetailer__DistanceIcon',
  componentId: 'sc-142satd-2'
})(['margin-right:6px;']);

var DistanceFromRetailer = function DistanceFromRetailer(_ref) {
  var vehicle = _ref.vehicle,
      translations = _ref.translations,
      locale = _ref.locale,
      hideDistanceFrom = _ref.hideDistanceFrom;
  return _react2.default.createElement(
    DistanceContainer,
    null,
    _react2.default.createElement(DistanceIcon, { src: 'https://res.cloudinary.com/motortrak/image/upload/v1697233489/locator/genesis/icons/location-pin-title.svg' }),
    _react2.default.createElement(
      DistanceLabel,
      null,
      '' + vehicle.retailerName + (!!vehicle.distance && !hideDistanceFrom ? ' - ' + (0, _numbers.localiseNumber)((0, _trunc2.default)((0, _numbers.converKmToMiles)(vehicle.distance)), locale) + ' ' + translations.milesAway : '')
    )
  );
};

exports.default = DistanceFromRetailer;