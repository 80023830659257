'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Print;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Print(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 24 20' },
      _react2.default.createElement('path', { d: 'M17.333 5.217H22v10.435h-4.667V20H5.667v-4.348H1V5.217h4.667V0h11.666v5.217zm-.777 0V.87H6.444v4.347h10.112zM6.444 12.174v6.956h10.112v-6.956H6.444z' }),
      _react2.default.createElement('path', { d: 'M7 13h8.556v.875H7V13zm0 1.75h8.556v.875H7v-.875zm0 1.75h8.556v.875H7V16.5z' })
    )
  );
}

Print.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};