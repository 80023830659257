'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    opacity: initial;\n  '], ['\n    opacity: initial;\n  ']);

exports.default = Video;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ClickWrapper = require('./ClickWrapper');

var _types = require('./types');

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VideoBg = _styledComponents2.default.video.withConfig({
  displayName: 'Video__VideoBg',
  componentId: 'sc-1vykwcg-0'
})(['object-fit:cover;width:100vw;height:100vh;opacity:0.7;', ';'], _theme2.default.min.medium(_templateObject));
function Video(_ref) {
  var url = _ref.url,
      preview = _ref.preview,
      mediaContent = _ref.mediaContent,
      onLinkClick = _ref.onLinkClick;

  var isMobile = (0, _useCheckIsMobileScreen2.default)();
  return _react2.default.createElement(
    _ClickWrapper.ClickWrapper,
    {
      fullScreen: mediaContent.fullScreen,
      pointer: url && url.value,
      onClick: function onClick(e) {
        if (preview) {
          e.preventDefault();
        }
        if (url) {
          onLinkClick(url && url.value);
        }
      }
    },
    _react2.default.createElement(
      VideoBg,
      {
        'data-cy': 'hero-carouse-slide-video',
        autoPlay: true,
        muted: true,
        loop: true,
        playsInline: true,
        poster: isMobile ? mediaContent.mobilePosterImage : mediaContent.posterImage
      },
      _react2.default.createElement('source', { src: mediaContent.value, type: 'video/mp4' })
    )
  );
}