'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilterSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FilterSection__FilterSectionWrapper',
  componentId: 'sc-mcxzdy-0'
})(['display:flex;flex-direction:column;width:100%;border-top:1px solid #d5d5d5;&:last-child{border-bottom:1px solid #d5d5d5;}']);


var FilterSectionTitle = _styledComponents2.default.div.withConfig({
  displayName: 'FilterSection__FilterSectionTitle',
  componentId: 'sc-mcxzdy-1'
})(['min-height:50px;display:flex;align-items:center;justify-content:space-between;padding:0 16px;width:100%;box-sizing:border-box;', ';'], function (_ref) {
  var disabled = _ref.disabled;
  return !disabled && '\n    cursor: pointer;\n    &:hover {\n      background-color: #d5d5d5;\n    }\n  ';
});

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'FilterSection__IconWrapper',
  componentId: 'sc-mcxzdy-2'
})(['width:20px;height:20px;']);

var FilterSection = function (_Component) {
  (0, _inherits3.default)(FilterSection, _Component);

  function FilterSection(props) {
    (0, _classCallCheck3.default)(this, FilterSection);

    var _this = (0, _possibleConstructorReturn3.default)(this, (FilterSection.__proto__ || (0, _getPrototypeOf2.default)(FilterSection)).call(this, props));

    Object.defineProperty(_this, 'onClickHandler', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (!_this.props.disabled) {
          _this.setState(function (prevState) {
            return { open: !prevState.open };
          });
        }
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            translations = _this$props.translations,
            children = _this$props.children,
            title = _this$props.title,
            active = _this$props.active,
            disabled = _this$props.disabled,
            primaryBrandColour = _this$props.globalStyling.colours.primaryBrandColour,
            stylingConfig = _this$props.stylingConfig;
        var open = _this.state.open;

        var displayIcon = open && stylingConfig.filterSectionOpenIcon || !open && stylingConfig.filterSectionCloseIcon;
        var disabledIcon = stylingConfig.filterSectionDisabledIcon;
        var titleColour = active ? primaryBrandColour : stylingConfig.filterHeadingFont.colour;
        var filterIcon = open ? stylingConfig.filterSectionOpenIcon : stylingConfig.filterSectionCloseIcon;
        return _react2.default.createElement(
          FilterSectionWrapper,
          null,
          _react2.default.createElement(
            FilterSectionTitle,
            {
              tabIndex: 0,
              'data-cy': title,
              disabled: disabled,
              'aria-disabled': disabled,
              onClick: _this.onClickHandler,
              onKeyDown: function onKeyDown(e) {
                if (e.keyCode === 13) {
                  // 13 is the code for the Enter key
                  _this.onClickHandler();
                }
              },
              'aria-label': disabled ? title + ' ' + translations.notClickableAriaLabel : translations.clickOrHitEnterAriaLabel + ' ' + title,
              'aria-expanded': open
            },
            _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return '\n              ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, stylingConfig.filterHeadingFont, {
                    colour: disabled ? { value: '#757575' } : titleColour
                  })) + '\n            ';
                }
              },
              title
            ),
            disabled && disabledIcon && _react2.default.createElement(IconWrapper, { src: disabledIcon, alt: '' }),
            !disabled && displayIcon && _react2.default.createElement(IconWrapper, { src: filterIcon, alt: '' })
          ),
          open && children
        );
      }
    });

    _this.state = {
      open: props.active && !_this.props.disabled
    };
    return _this;
  }

  (0, _createClass3.default)(FilterSection, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      if (this.props.disabled && this.state.open) {
        this.setState(function () {
          return { open: false };
        });
      }
    }
  }, {
    key: 'setOpenState',
    value: function setOpenState(open) {
      this.setState(function () {
        return { open: open };
      });
    }
  }]);
  return FilterSection;
}(_react.Component);

exports.default = FilterSection;