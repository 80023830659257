'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: auto;\n    margin: 0 auto;\n  '], ['\n    width: auto;\n    margin: 0 auto;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n    justify-content: center;\n  '], ['\n    flex-direction: row;\n    justify-content: center;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: flex-end;\n    padding: 0 5px;\n  '], ['\n    justify-content: flex-end;\n    padding: 0 5px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _components = require('../components');

var _Global = require('../components/Global');

var _FormFields = require('./FormFields');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _useQuickContact2 = require('./useQuickContact');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledTextWrapper = (0, _styledComponents2.default)(_components.TextWrapper).withConfig({
  displayName: 'QuickContact__StyledTextWrapper',
  componentId: 'sc-wcnbb0-0'
})(['width:100%;box-sizing:border-box;', ';'], _theme2.default.min.medium(_templateObject));

var FormRow = _styledComponents2.default.div.withConfig({
  displayName: 'QuickContact__FormRow',
  componentId: 'sc-wcnbb0-1'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.min.medium(_templateObject2));

var SubmissionFormRow = (0, _styledComponents2.default)(FormRow).withConfig({
  displayName: 'QuickContact__SubmissionFormRow',
  componentId: 'sc-wcnbb0-2'
})(['', ';'], _theme2.default.min.medium(_templateObject3));

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      translations = _ref$config.translations,
      fields = _ref$config.fields,
      labelFont = _ref$config.labelFont,
      padding = _ref$config.padding,
      border = _ref$config.border,
      backgroundColour = _ref$config.backgroundColour,
      primaryButton = _ref.globalStyling.uiElements.primaryButton;

  var reducedFieldState = fields.reduce(function (acc, currVal) {
    return (0, _extends4.default)({}, acc, (0, _defineProperty3.default)({}, currVal.name, (0, _extends4.default)({}, currVal, { value: '' })));
  }, {});

  var _useQuickContact = (0, _useQuickContact2.useQuickContact)(reducedFieldState, translations),
      values = _useQuickContact.values,
      handleSubmit = _useQuickContact.handleSubmit,
      handleBlur = _useQuickContact.handleBlur,
      handleChange = _useQuickContact.handleChange,
      submissionStatus = _useQuickContact.submissionStatus,
      errors = _useQuickContact.errors;

  return _react2.default.createElement(
    _components.Wrapper,
    { border: border, backgroundColour: backgroundColour },
    _react2.default.createElement(
      StyledTextWrapper,
      { padding: padding },
      _react2.default.createElement(
        FormRow,
        null,
        _react2.default.createElement(_FormFields.InputField, {
          type: 'text',
          name: 'firstName',
          font: labelFont,
          label: translations.firstNameLabel,
          value: values.firstName.value,
          onChange: handleChange,
          onBlur: handleBlur,
          error: errors.firstName,
          required: fields.find(function (f) {
            return f.name === 'firstName';
          }).status === 'required'
        }),
        _react2.default.createElement(_FormFields.InputField, {
          type: 'text',
          name: 'lastName',
          font: labelFont,
          label: translations.lastNameLabel,
          value: values.lastName.value,
          onChange: handleChange,
          onBlur: handleBlur,
          error: errors.lastName,
          required: fields.find(function (f) {
            return f.name === 'lastName';
          }).status === 'required'
        })
      ),
      _react2.default.createElement(
        FormRow,
        null,
        _react2.default.createElement(_FormFields.InputField, {
          type: 'text',
          name: 'email',
          font: labelFont,
          label: translations.emailLabel,
          value: values.email.value,
          onChange: handleChange,
          onBlur: handleBlur,
          error: errors.email,
          required: fields.find(function (f) {
            return f.name === 'email';
          }).status === 'required'
        }),
        _react2.default.createElement(_FormFields.InputField, {
          type: 'text',
          name: 'phoneNumber',
          font: labelFont,
          label: translations.phoneNumberLabel,
          value: values.phoneNumber.value,
          onChange: handleChange,
          onBlur: handleBlur,
          error: errors.phoneNumber,
          required: fields.find(function (f) {
            return f.name === 'phoneNumber';
          }).status === 'required'
        })
      ),
      _react2.default.createElement(
        FormRow,
        null,
        _react2.default.createElement(_FormFields.TextAreaField, {
          name: 'comments',
          font: labelFont,
          value: values.comments.value,
          label: translations.commentsLabel,
          onChange: handleChange,
          onBlur: handleBlur,
          error: errors.comments,
          required: fields.find(function (f) {
            return f.name === 'comments';
          }).status === 'required'
        })
      ),
      _react2.default.createElement(
        SubmissionFormRow,
        null,
        _react2.default.createElement(_Global.Button, {
          buttonStyle: primaryButton && primaryButton.buttonStyle,
          text: translations.submitButtonLabel,
          onClick: handleSubmit,
          disabled: submissionStatus === 'submitted'
        })
      )
    )
  );
};