'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHelmet = require('react-helmet');

var _vehicleInformation = require('../../shared/vehicleInformation');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Spinner = require('../../components/Spinner');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VehicleOverview = function (_Component) {
  (0, _inherits3.default)(VehicleOverview, _Component);

  function VehicleOverview() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, VehicleOverview);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = VehicleOverview.__proto__ || (0, _getPrototypeOf2.default)(VehicleOverview)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            pathname = _this$props.history.location.pathname,
            dispatch = _this$props.dispatch;


        var vehicleId = pathname.split('/').pop();

        dispatch(_vehicleInformation.actions.getVehicleInfo({
          vehicleId: vehicleId
        }));
      }
    }), Object.defineProperty(_this, 'componentWillUnmount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var dispatch = _this.props.dispatch;

        dispatch(_vehicleInformation.actions.clearVehicleInfo());
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(VehicleOverview, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          globalStyling = _props.globalStyling,
          vehicle = _props.shared.vehicleInformation.vehicle;

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          _reactHelmet.Helmet,
          null,
          vehicle && _react2.default.createElement(
            'title',
            null,
            vehicle.name + ' ' + vehicle.description
          )
        ),
        _react2.default.createElement(
          _ContentWrapper2.default,
          null,
          _react2.default.createElement(
            'div',
            { 'data-cy': 'vehicle-details' },
            _react2.default.createElement(
              _Spinner.SpinnerWrapper,
              { globalStyling: globalStyling, loaded: !!vehicle },
              function () {
                return _react2.default.createElement(
                  'div',
                  null,
                  (0, _stringify2.default)(vehicle)
                );
              }
            )
          )
        )
      );
    }
  }]);
  return VehicleOverview;
}(_react.Component);

exports.default = VehicleOverview;