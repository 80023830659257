'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FilterPill;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _IconX = require('../JaguarIcons/Global/IconX');

var _IconX2 = _interopRequireDefault(_IconX);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Pill = _styledComponents2.default.div.withConfig({
  displayName: 'FilterPill__Pill',
  componentId: 'sc-5grpu8-0'
})(['border-radius:20px;border:1px solid #999999;padding:6px 18px;', ';color:#444444;display:inline-flex;align-items:center;background:#ffffff;'], function (_ref) {
  var direction = _ref.direction;
  return direction === 'rtl' ? 'margin: 0 0 9px 12px' : 'margin: 0 12px 9px 0';
});

var CloseButton = _styledComponents2.default.a.withConfig({
  displayName: 'FilterPill__CloseButton',
  componentId: 'sc-5grpu8-1'
})(['display:flex;align-items:center;text-decoration:none;color:#444444;font-size:13px;background:none;border:none;cursor:pointer;']);

function FilterPill(_ref2) {
  var pillText = _ref2.pillText,
      onClose = _ref2.onClose,
      font = _ref2.font,
      direction = _ref2.direction;

  return _react2.default.createElement(
    Pill,
    { direction: direction },
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n          ' + (0, _Global.fontStyleOverride)(font) + '\n          margin: 0 7px;';
        }
      },
      pillText
    ),
    _react2.default.createElement(
      CloseButton,
      { onClick: onClose, 'data-cy': pillText + ' pill' },
      _react2.default.createElement(_IconX2.default, { height: '13px' })
    )
  );
}