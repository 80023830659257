'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translateFromTemplate = undefined;

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var translateFromTemplate = exports.translateFromTemplate = function translateFromTemplate(translationsKey, placeholdersAndValues, translations) {
  var template = translations[translationsKey];
  var replaceValue = function replaceValue(t, key) {
    return t.replace('{' + key + '}', placeholdersAndValues[key] !== undefined && placeholdersAndValues[key] !== null ? placeholdersAndValues[key].toString() : '');
  };

  return template ? (0, _keys2.default)(placeholdersAndValues).reduce(replaceValue, template) : '';
};