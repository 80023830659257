'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _Global = require('../Global');

var _ = require('..');

var _router = require('../../../actions/router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var buttonAlignMapping = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

var ButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'HeadingTextButton__ButtonWrapper',
  componentId: 'sc-1m8xiy3-0'
})(['display:flex;justify-content:', ';'], function (props) {
  return props.alignment || 'center';
});

exports.default = function (props) {
  var _props$config = props.config,
      headlineFont = _props$config.headlineFont,
      translations = _props$config.translations,
      bodyFont = _props$config.bodyFont,
      alignment = _props$config.alignment,
      button = _props$config.button,
      padding = _props$config.padding,
      backgroundColour = _props$config.backgroundColour,
      _props$globalStyling = props.globalStyling;
  _props$globalStyling = _props$globalStyling === undefined ? {} : _props$globalStyling;
  var _props$globalStyling$ = _props$globalStyling.uiElements,
      uiElements = _props$globalStyling$ === undefined ? {} : _props$globalStyling$,
      children = props.children;


  var dispatch = (0, _reactRedux.useDispatch)();
  return _react2.default.createElement(
    _.Wrapper,
    { backgroundColour: backgroundColour },
    children && children(props),
    _react2.default.createElement(
      _.TextWrapper,
      { padding: padding },
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)((0, _extends3.default)({}, headlineFont, { alignment: alignment }));
          }
        },
        translations && translations.headlineText
      ),
      translations.bodyText && _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n            ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, bodyFont, { alignment: alignment })) + ';\n            flex: 1 0 auto;\n          ';
          }
        },
        translations.bodyText
      ),
      button && _react2.default.createElement(
        ButtonWrapper,
        { alignment: buttonAlignMapping[alignment] },
        _react2.default.createElement(_Global.Button, {
          buttonStyle: uiElements.primaryButton && uiElements.primaryButton.buttonStyle,
          applyStyle: 'primary',
          styleOverride: function styleOverride() {
            return (0, _Global.buttonStyleOverride)(button);
          },
          text: translations.buttonText,
          onClick: function onClick() {
            return dispatch(_router.actions.navigate(button.url));
          }
        })
      )
    )
  );
};