'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     flex: 1 1 calc(50% - 10px);\n  '], ['\n     flex: 1 1 calc(50% - 10px);\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SpecItems__Container',
  componentId: 'sc-1ftqsr1-0'
})(['color:#fff;display:flex;flex-wrap:wrap;']);

var SpecItem = _styledComponents2.default.div.withConfig({
  displayName: 'SpecItems__SpecItem',
  componentId: 'sc-1ftqsr1-1'
})(['border-top:1px solid #fff;display:flex;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:14px;flex-direction:row;padding:10px 10px 8px 0;text-transform:uppercase;width:100%;', ';'], _Genesis2.default.min.medium(_templateObject));

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'SpecItems__Label',
  componentId: 'sc-1ftqsr1-2'
})(['margin-right:8px;']);

var SpecItems = function SpecItems(_ref) {
  var specItems = _ref.specItems;

  return _react2.default.createElement(
    Container,
    null,
    specItems.map(function (item, index) {
      return _react2.default.createElement(
        SpecItem,
        { key: index },
        _react2.default.createElement(
          Label,
          null,
          item.label,
          ':'
        ),
        ' ',
        item.content
      );
    })
  );
};

exports.default = SpecItems;