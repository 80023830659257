'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _navigation = require('../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledLink = _styledComponents2.default.a.withConfig({
  displayName: 'BentleyLink__StyledLink',
  componentId: 'sc-1qfn5eo-0'
})(['text-decoration:none;color:#335b4c;&:hover{border-color:#003220;color:#003220;}']);

var BentleyLink = function BentleyLink(_ref) {
  var link = _ref.link,
      children = _ref.children;

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl;

  return _react2.default.createElement(
    StyledLink,
    { href: link, target: getTargetUrl(link) },
    children
  );
};

exports.default = BentleyLink;