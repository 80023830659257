'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n  '], ['\n    flex-direction: row;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    align-items: center;\n    box-sizing: content-box;\n    display: flex;\n    flex-direction: column;\n    padding: 50px 10px;\n    justify-content: space-between;\n    width: 60px;\n  '], ['\n    align-items: center;\n    box-sizing: content-box;\n    display: flex;\n    flex-direction: column;\n    padding: 50px 10px;\n    justify-content: space-between;\n    width: 60px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 400px;\n    width: 40%;\n  '], ['\n    max-width: 400px;\n    width: 40%;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 16px 30px;\n  '], ['\n    padding: 16px 30px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    border-top: none;\n    padding: 0;\n  '], ['\n    border-top: none;\n    padding: 0;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 10px 0;\n  '], ['\n    padding: 10px 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('.');

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _useCheckIsMobileScreenRollsRoyce = require('../../../hooks/useCheckIsMobileScreenRollsRoyce');

var _useCheckIsMobileScreenRollsRoyce2 = _interopRequireDefault(_useCheckIsMobileScreenRollsRoyce);

var _useVehiclePrices2 = require('../../../hooks/useVehiclePrices');

var _useVehiclePrices3 = _interopRequireDefault(_useVehiclePrices2);

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

var _CarfaxLogo = require('../CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

var _CompareButton = require('../CompareButton');

var _CompareButton2 = _interopRequireDefault(_CompareButton);

var _Button = require('../Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _ImgCollage = require('../ImgCollage');

var _ImgCollage2 = _interopRequireDefault(_ImgCollage);

var _ShortlistButton = require('../ShortlistButton');

var _ShortlistButton2 = _interopRequireDefault(_ShortlistButton);

var _Emissions = require('./Emissions');

var _Emissions2 = _interopRequireDefault(_Emissions);

var _types = require('./types');

var _useTranslateMonthName = require('../../../hooks/useTranslateMonthName');

var _useTranslateMonthName2 = _interopRequireDefault(_useTranslateMonthName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_LOCALE_CODE = 'en_gb';

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__Container',
  componentId: 'sc-1o03klr-0'
})(['box-sizing:content-box;border:1px solid #efefef;display:flex;box-shadow:0 5px 10px rgba(0,0,0,0.2);margin-bottom:30px;width:100%;flex-direction:column;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject));

var Utilities = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__Utilities',
  componentId: 'sc-1o03klr-1'
})(['display:none;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject2));

var UtilitiesMobileTablet = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__UtilitiesMobileTablet',
  componentId: 'sc-1o03klr-2'
})(['align-items:center;box-sizing:border-box;display:flex;flex-direction:row;padding:7.5px 0;justify-content:space-evenly;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject3));

var UtilitiesItem = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__UtilitiesItem',
  componentId: 'sc-1o03klr-3'
})(['cursor:pointer;']);

var UtilitiesItemText = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__UtilitiesItemText',
  componentId: 'sc-1o03klr-4'
})(['display:block;text-align:center;font-family:\'RivieraNights-Medium\';font-size:14px;']);

var UtilitiesDivider = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__UtilitiesDivider',
  componentId: 'sc-1o03klr-5'
})(['', ';background-color:rgba(59,59,59,0.4);'], function (_ref) {
  var vertical = _ref.vertical;
  return vertical ? ' width: 1px; height: 40px' : ' height: 1px; width: 100%';
});

var ImageSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__ImageSection',
  componentId: 'sc-1o03klr-6'
})(['width:100%;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject4));

var VehicleInfoSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleInfoSection',
  componentId: 'sc-1o03klr-7'
})(['cursor:pointer;display:flex;flex-direction:column;flex:1;padding:0;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject5));

var BottomRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__BottomRow',
  componentId: 'sc-1o03klr-8'
})(['align-items:center;border-top:1px solid rgba(59,59,59,0.4);display:flex;flex-direction:row;margin-top:auto;min-height:54px;padding:0 12px;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject6));

var MoreDetails = (0, _styledComponents2.default)(_Button2.default).withConfig({
  displayName: 'VehicleItem__MoreDetails',
  componentId: 'sc-1o03klr-9'
})(['min-width:227px;']);

var CarfaxWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__CarfaxWrapper',
  componentId: 'sc-1o03klr-10'
})(['padding:10px 12px;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject7));

var primaryButtonStyle = function primaryButtonStyle() {
  return 'width: auto; &:hover, &:active{\n  background-color: #502387;\n}';
};

var VehicleItem = function VehicleItem(_ref2) {
  var vehicle = _ref2.vehicle,
      onMoreInfoClick = _ref2.onMoreInfoClick,
      translations = _ref2.translations,
      locale = _ref2.locale,
      config = _ref2.config,
      handleZeroPriceAsText = _ref2.handleZeroPriceAsText;

  var _useVehiclePrices = (0, _useVehiclePrices3.default)(vehicle, translations, handleZeroPriceAsText),
      handlePrice = _useVehiclePrices.grossPrice,
      netPrice = _useVehiclePrices.netPrice;

  var desktopsml = (0, _useCheckIsMobileScreenRollsRoyce2.default)('medium');
  var desktop = (0, _useCheckIsMobileScreenRollsRoyce2.default)('extraLarge');

  var isValidLocale = function isValidLocale(locale) {
    var localeRegExp = /^[a-z]{2}_[a-z]{2}$/;
    return localeRegExp.test(locale);
  };

  var extractMonthAndYear = function extractMonthAndYear(dateString, locale) {
    var _dateString$split$map = dateString.split('-').map(Number),
        _dateString$split$map2 = (0, _slicedToArray3.default)(_dateString$split$map, 2),
        year = _dateString$split$map2[0],
        month = _dateString$split$map2[1];

    if (isValidLocale(locale)) {
      return (0, _useTranslateMonthName2.default)(month - 1, locale) + ' ' + year;
    } else {
      return (0, _useTranslateMonthName2.default)(month - 1, DEFAULT_LOCALE_CODE) + ' ' + year;
    }
  };

  var registrationDate = vehicle.regDate ? extractMonthAndYear(vehicle.regDate, locale) : '';

  var showSalesTaxDisclaimer = config.showSectionSalesTaxDisclaimer && !!vehicle.sectionSalesTaxDisclaimer;

  return _react2.default.createElement(
    Container,
    { 'data-cy': 'search-result-vehicle-item' },
    !desktop && _react2.default.createElement(
      Utilities,
      null,
      _react2.default.createElement(
        UtilitiesItem,
        { onClick: function onClick(e) {
            return onMoreInfoClick(e, vehicle);
          } },
        _react2.default.createElement('img', { src: config.iconImages, alt: 'Images Available' }),
        _react2.default.createElement(
          UtilitiesItemText,
          null,
          vehicle.images.length + 1
        )
      ),
      _react2.default.createElement(UtilitiesDivider, null),
      vehicle.video && _react2.default.createElement(
        UtilitiesItem,
        { onClick: function onClick(e) {
            return onMoreInfoClick(e, vehicle);
          } },
        _react2.default.createElement('img', { src: config.iconVideo, alt: 'Video Available' })
      ),
      vehicle.video && _react2.default.createElement(UtilitiesDivider, null),
      _react2.default.createElement(
        UtilitiesItem,
        null,
        _react2.default.createElement(_CompareButton2.default, {
          vehicle: vehicle,
          iconOff: config.iconCompareOff,
          iconOn: config.iconCompareOn
        })
      ),
      _react2.default.createElement(UtilitiesDivider, null),
      _react2.default.createElement(
        UtilitiesItem,
        null,
        _react2.default.createElement(_ShortlistButton2.default, {
          vehicle: vehicle,
          iconOff: config.iconShortlistOff,
          iconOn: config.iconShortlistOn
        })
      )
    ),
    _react2.default.createElement(
      ImageSection,
      null,
      _react2.default.createElement(_ImgCollage2.default, {
        onClick: function onClick(e) {
          return onMoreInfoClick(e, vehicle);
        },
        images: vehicle.images,
        config: config,
        video: vehicle.video,
        placeholder: '',
        imageAltText: vehicle.name,
        translations: translations
      })
    ),
    desktop && _react2.default.createElement(
      UtilitiesMobileTablet,
      null,
      _react2.default.createElement(
        UtilitiesItem,
        { onClick: function onClick(e) {
            return onMoreInfoClick(e, vehicle);
          } },
        _react2.default.createElement('img', { src: config.iconImages, alt: 'Images Available' }),
        _react2.default.createElement(
          UtilitiesItemText,
          null,
          vehicle.images.length + 1
        )
      ),
      _react2.default.createElement(UtilitiesDivider, { vertical: true }),
      vehicle.video && _react2.default.createElement(
        UtilitiesItem,
        { onClick: function onClick(e) {
            return onMoreInfoClick(e, vehicle);
          } },
        _react2.default.createElement('img', { src: config.iconVideo, alt: 'Video Available' })
      ),
      vehicle.video && _react2.default.createElement(UtilitiesDivider, { vertical: true }),
      !desktopsml && _react2.default.createElement(
        UtilitiesItem,
        null,
        _react2.default.createElement(_CompareButton2.default, {
          vehicle: vehicle,
          iconOff: config.iconCompareOff,
          iconOn: config.iconCompareOn
        })
      ),
      !desktopsml && _react2.default.createElement(UtilitiesDivider, { vertical: true }),
      _react2.default.createElement(
        UtilitiesItem,
        null,
        _react2.default.createElement(_ShortlistButton2.default, {
          vehicle: vehicle,
          iconOff: config.iconShortlistOff,
          iconOn: config.iconShortlistOn
        })
      )
    ),
    _react2.default.createElement(
      VehicleInfoSection,
      { onClick: function onClick(e) {
          return onMoreInfoClick(e, vehicle);
        } },
      _react2.default.createElement(_.VehicleLabel, {
        translations: translations,
        bespoke: vehicle.bespoke,
        modelCollectionName: vehicle.modelCollectionName
      }),
      _react2.default.createElement(_.VehicleTitle, {
        vehicleName: (vehicle.modelYear !== 0 ? vehicle.modelYear : '') + ' ' + vehicle.modelVariant,
        price: handlePrice,
        netPrice: netPrice,
        onClick: function onClick() {
          return onMoreInfoClick('VDP', vehicle);
        },
        translations: translations,
        retailerCountryCode: vehicle.retailerCountryCode,
        showSalesTaxDisclaimer: showSalesTaxDisclaimer
      }),
      _react2.default.createElement(_.SpecItems, {
        specItems: [{ label: translations.exterior, content: vehicle.exterior }, { label: translations.interior, content: vehicle.interior }, { label: translations.veneer, content: vehicle.veneer }]
      }),
      _react2.default.createElement(_.SpecItems, {
        specItems: [{ label: translations.modelYear, content: vehicle.modelYear }, {
          label: translations.registrationDate,
          content: registrationDate
        }],
        twoColumns: true
      }),
      config.displayEmissions && _react2.default.createElement(_Emissions2.default, {
        emissionsData: vehicle.emissions,
        consumptionsData: vehicle.consumption,
        efficiency: vehicle.energyEfficiencyRating,
        emissionsClass: vehicle.engineDetails.emissionsClass,
        translations: translations,
        config: config
      }),
      vehicle.carfax && vehicle.carfax.historyReportUrl && _react2.default.createElement(
        CarfaxWrapper,
        null,
        _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicle.carfax })
      ),
      _react2.default.createElement(
        BottomRow,
        null,
        _react2.default.createElement(_.DistanceFromRetailer, {
          vehicle: vehicle,
          translations: translations,
          locale: locale,
          hideDistanceFrom: config.hideDistanceFrom
        }),
        !desktop && _react2.default.createElement(MoreDetails, {
          variant: 'primary',
          text: translations.searchResultTileMoreInfo,
          onClick: function onClick(e) {
            onMoreInfoClick(e, vehicle);
          },
          styleOverride: primaryButtonStyle,
          icon: 'https://res.cloudinary.com/motortrak/v1706726136/locator/rolls-royce/global/button-arrow.svg'
        })
      )
    )
  );
};

exports.default = VehicleItem;