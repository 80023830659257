'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n  '], ['\n    display: flex;\n  ']);
// import { useSelector } from 'react-redux';


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ModalOverlay = require('../../components/ModalOverlay');

var _Global = require('../../components/Global');

var _UserLocation = require('../../components/UserLocation');

var _UserLocation2 = _interopRequireDefault(_UserLocation);

var _location = require('../../shared/location');

var _location2 = _interopRequireDefault(_location);

var _useRegionsHook2 = require('../../shared/hooks/useRegionsHook');

var _router = require('../../actions/router');

var _sessionPreferences = require('../../shared/sessionPreferences');

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MandatoryLocation__Wrapper',
  componentId: 'sc-qk8v41-0'
})(['padding:45px;*{box-sizing:border-box;}']);

var LocationCtaWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MandatoryLocation__LocationCtaWrapper',
  componentId: 'sc-qk8v41-1'
})(['display:block;', ';'], _theme2.default.min.medium(_templateObject));

exports.default = function (_ref) {
  var _ref$shared = _ref.shared,
      _ref$shared$filters = _ref$shared.filters,
      availableFilterValues = _ref$shared$filters.availableFilterValues,
      total = _ref$shared$filters.total,
      _ref$shared$sessionPr = _ref$shared.sessionPreferences.location,
      location = _ref$shared$sessionPr === undefined ? {} : _ref$shared$sessionPr,
      _ref$config = _ref.config,
      translations = _ref$config.translations,
      headingFont = _ref$config.headingFont,
      subHeadingFont = _ref$config.subHeadingFont,
      linkFont = _ref$config.linkFont,
      locationIconUrl = _ref$config.locationIconUrl,
      config = _ref.config,
      dispatch = _ref.dispatch,
      globalStyling = _ref.globalStyling,
      router = _ref.router;

  var _useLocation = (0, _location2.default)(),
      lat = _useLocation.lat,
      long = _useLocation.long,
      setLat = _useLocation.setLat,
      setLong = _useLocation.setLong,
      locationTown = _useLocation.locationTown,
      setLocationTown = _useLocation.setLocationTown,
      userCountry = _useLocation.userCountry,
      setUserCountry = _useLocation.setUserCountry,
      googleAvailable = _useLocation.googleAvailable;

  var _useRegionsHook = (0, _useRegionsHook2.useRegionsHook)(),
      setRegionFilters = _useRegionsHook.setRegionFilters;

  var latitude = location.latitude,
      longitude = location.longitude;


  (0, _react.useEffect)(function () {
    if (router.platformMode && router.params.dealerLatitude && router.params.dealerLongitude) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('location', {
        latitude: router.params.dealerLatitude,
        longitude: router.params.dealerLongitude,
        locationTown: router.params.city,
        userCountry: router.params.country
      }));

      setRegionFilters(router.params.city);
    }
  }, []);

  (0, _react.useEffect)(function () {
    if (document.getElementById('Search-popup-modal')) {
      document.getElementById('Search-popup-modal').focus();
    }
  }, [availableFilterValues, latitude, longitude, googleAvailable]);

  var onSearchClick = function onSearchClick() {
    dispatch(_sessionPreferences.actions.updateSessionPreferences('location', {
      latitude: lat,
      longitude: long,
      locationTown: locationTown,
      userCountry: userCountry
    }));

    setRegionFilters(userCountry);
  };

  return !config.disabled ? _react2.default.createElement(
    _ModalOverlay.Overlay,
    {
      open: availableFilterValues && !latitude && !longitude && googleAvailable,
      role: 'dialog',
      'aria-modal': 'true'
    },
    _react2.default.createElement(
      _ModalOverlay.ModalContentWrapper,
      { modalWidth: 520, 'data-cy': 'mandatory-location' },
      _react2.default.createElement(
        Wrapper,
        null,
        _react2.default.createElement(
          _Global.HeadingOne,
          {
            id: 'Search-popup-modal',
            tabIndex: '1' // eslint-disable-line
            , styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(headingFont);
            }
          },
          translations.heading
        ),
        _react2.default.createElement(
          _Global.HeadingOne,
          { styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(subHeadingFont);
            } },
          (0, _translateFromTemplate.translateFromTemplate)('vehicleCount', { COUNT: total }, translations)
        ),
        _react2.default.createElement(
          LocationCtaWrapper,
          null,
          _react2.default.createElement(
            'div',
            { style: { flex: '1 1 0%' } },
            _react2.default.createElement(_UserLocation2.default, {
              direction: globalStyling.direction,
              placeholderText: translations.locationPlaceholder,
              locationAlternativeText: translations.locationAlternativeText,
              iconUrl: locationIconUrl,
              browserLocationCallback: function browserLocationCallback(latResponse, longResponse, locationTownResponse, userCountryResponse) {
                setLat(latResponse);
                setLong(longResponse);
                setLocationTown(locationTownResponse);
                setUserCountry(userCountryResponse);
              },
              inputLocationCallback: function inputLocationCallback(latResponse, longResponse, locationTownResponse, userCountryResponse) {
                setLat(latResponse);
                setLong(longResponse);
                setLocationTown(locationTownResponse);
                setUserCountry(userCountryResponse);
              },
              autocompletePosition: 'below',
              mandatoryLocationInput: true
            })
          ),
          _react2.default.createElement(_Global.Button, {
            tabIndex: '1' // eslint-disable-line
            , text: translations.searchText,
            mobileStyleOverride: function mobileStyleOverride() {
              return 'margin-top: 10px;';
            },
            'data-cy': 'mandatory-location-search-button',
            onClick: onSearchClick,
            applyStyle: 'primary',
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            disabled: !lat && !long,
            styleOverride: function styleOverride() {
              return '\n              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {\n                flex-basis: 20%;\n              }\n              height: 46px;\n            ';
            }
          })
        ),
        !router.platformMode && _react2.default.createElement(_Global.Button, {
          buttonStyle: 'ButtonStyle1',
          styleOverride: function styleOverride() {
            return '\n            ' + (0, _Global.buttonStyleOverride)({}, { value: '#FFFFFF' }, { value: '#FFFFFF' }) + ';\n            ' + (0, _Global.fontStyleOverride)(linkFont) + ';\n            height: auto;\n            margin-top: 40px;\n            display: flex;\n            flex: 1 0 20%;\n            width: 100%;\n          ';
          },
          onClick: function onClick() {
            return dispatch(_router.actions.navigate('/'));
          },
          text: translations.returnToHomeLink,
          tabIndex: '1' // eslint-disable-line
        })
      )
    )
  ) : null;
};