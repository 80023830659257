'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SlideFour = exports.SlideTwo = exports.SlideOne = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: auto;\n    padding: 0;\n\n    &:after {\n      opacity: 1;\n    }\n  '], ['\n    width: auto;\n    padding: 0;\n\n    &:after {\n      opacity: 1;\n    }\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      position: static;\n      top: auto;\n    '], ['\n      position: static;\n      top: auto;\n    ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n      position: static;\n      top: auto;\n      width: 100%;\n    '], ['\n      position: static;\n      top: auto;\n      width: 100%;\n    ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 20px 25px 20px;;\n  '], ['\n    padding: 0 20px 25px 20px;;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    height: 100%;\n    width: auto;\n  '], ['\n    height: 100%;\n    width: auto;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    flex-direction: row-reverse;\n    position: absolute;\n    top: 10%;\n    padding: 0 50px;\n    box-sizing: border-box;\n    height: calc(100% - 80px);\n  '], ['\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    flex-direction: row-reverse;\n    position: absolute;\n    top: 10%;\n    padding: 0 50px;\n    box-sizing: border-box;\n    height: calc(100% - 80px);\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n    margin-left: -32px;\n  '], ['\n    padding: 0;\n    margin-left: -32px;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n  '], ['\n    margin: 0;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    height: 60px;\n    width: auto;\n  '], ['\n    height: 60px;\n    width: auto;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 10px;\n  '], ['\n    margin-top: 10px;\n  ']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 20px 0 10px 0;\n  '], ['\n    margin: 20px 0 10px 0;\n  ']),
    _templateObject13 = (0, _taggedTemplateLiteral3.default)(['\n    position: absolute;\n    left: 0;\n    top: 40px;\n  '], ['\n    position: absolute;\n    left: 0;\n    top: 40px;\n  ']),
    _templateObject14 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']),
    _templateObject15 = (0, _taggedTemplateLiteral3.default)(['\n      &:after {\n        opacity: 1;\n      };\n      padding: 0;\n  '], ['\n      &:after {\n        opacity: 1;\n      };\n      padding: 0;\n  ']),
    _templateObject16 = (0, _taggedTemplateLiteral3.default)(['\n  width: 60%;\n  margin: -90px auto 0 auto;\n  '], ['\n  width: 60%;\n  margin: -90px auto 0 auto;\n  ']),
    _templateObject17 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    margin: 0;\n  '], ['\n    width: 100%;\n    margin: 0;\n  ']); /* eslint-disable arrow-parens */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _sessionPreferences = require('../../shared/sessionPreferences');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Global = require('../../components/Global');

var _Form = require('../../components/RollsRoyce/Form');

var _YouTubePlayer = require('../../components/YouTubePlayer');

var _YouTubePlayer2 = _interopRequireDefault(_YouTubePlayer);

var _useCheckIsMobileScreen = require('../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var opacity = function opacity(theme) {
  return '\n  &:after {\n    content: \'\';\n    width: 100%;\n    height: 100%;\n    ' + (theme.backgroundColour && 'background-color: ' + theme.backgroundColour + ';') + '\n    ' + (theme.backgroundOpacity && 'opacity: ' + theme.backgroundOpacity + ';') + '\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 1;\n  }\n  position: relative;\n  margin: 0 0 1px 0;\n';
};

var OpacityWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Slides__OpacityWrapper',
  componentId: 'sc-alsy4d-0'
})(['', ';padding:10px ', ' 10px ', ';', ';'], function (_ref) {
  var theme = _ref.theme;
  return opacity(theme);
}, function (_ref2) {
  var rightPadding = _ref2.rightPadding;
  return '' + (rightPadding !== undefined ? rightPadding + 'px' : '15%');
}, function (_ref3) {
  var leftPadding = _ref3.leftPadding;
  return '' + (leftPadding !== undefined ? leftPadding + 'px' : '15%');
}, _theme2.default.max.medium(_templateObject));

var FullWidthOpacityWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Slides__FullWidthOpacityWrapper',
  componentId: 'sc-alsy4d-1'
})(['', ' width:100%;padding:', '%;', ';'], function (_ref4) {
  var theme = _ref4.theme;
  return opacity(theme);
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.padding || theme.padding === 0 ? theme.padding : 5;
}, _theme2.default.max.medium(_templateObject));

var SlideContainer = _styledComponents2.default.div.withConfig({ displayName: 'SlideContainer' }).withConfig({
  displayName: 'Slides__SlideContainer',
  componentId: 'sc-alsy4d-2'
})(['display:flex;flex-direction:column;min-height:1px;']);

var BackgroundImage = _styledComponents2.default.img.withConfig({
  displayName: 'BackgroundImage'
}).withConfig({
  displayName: 'Slides__BackgroundImage',
  componentId: 'sc-alsy4d-3'
})(['display:flex;max-width:100%;width:100%;']);

var SlideContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SlideContentContainer'
}).withConfig({
  displayName: 'Slides__SlideContentContainer',
  componentId: 'sc-alsy4d-4'
})(['position:absolute;top:', ';', ' ', ';'], function (_ref6) {
  var top = _ref6.top;
  return top ? top + '%' : '50px';
}, function (_ref7) {
  var align = _ref7.align,
      alignmentWidth = _ref7.alignmentWidth;
  return align && alignmentWidth && align + ': ' + alignmentWidth + '%;';
}, _theme2.default.max.medium(_templateObject2));

var SlideOneContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SlideOneContentContainer'
}).withConfig({
  displayName: 'Slides__SlideOneContentContainer',
  componentId: 'sc-alsy4d-5'
})(['position:absolute;top:140px;text-align:center;', ' ', ';'], function (_ref8) {
  var width = _ref8.width;
  return width && 'width: ' + width + 'px; left: calc(50% - ' + width + 'px / 2);';
}, _theme2.default.max.medium(_templateObject3));

var TopSlideContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TopSlideContentContainer'
}).withConfig({
  displayName: 'Slides__TopSlideContentContainer',
  componentId: 'sc-alsy4d-6'
})(['display:flex;flex-direction:column;font-weight:bold;margin:0 auto;', ' ', ';'], function (_ref9) {
  var width = _ref9.width;
  return width && 'width : ' + width + 'px;';
}, _theme2.default.max.medium(_templateObject4));

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Slides__ButtonContainer',
  componentId: 'sc-alsy4d-7'
})(['display:flex;align-items:center;flex-direction:column;', ';'], _theme2.default.max.medium(_templateObject5));

var ClickWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Slides__ClickWrapper',
  componentId: 'sc-alsy4d-8'
})(['cursor:', ';min-height:1px;max-height:', ';display:flex;align-items:center;overflow:hidden;'], function (_ref10) {
  var pointer = _ref10.pointer;
  return pointer ? 'pointer' : 'default';
}, function (_ref11) {
  var fullScreen = _ref11.fullScreen;
  return fullScreen ? '100vh' : '600px';
});

var SectionChevron = _styledComponents2.default.img.withConfig({
  displayName: 'Slides__SectionChevron',
  componentId: 'sc-alsy4d-9'
})(['cursor:pointer;position:absolute;color:white;left:calc(50% - 18px);bottom:50px;']);

var buttonStyleOverride = function buttonStyleOverride(linkTheme) {
  return '\n  min-width: ' + (linkTheme.buttonWidth ? linkTheme.buttonWidth + 'px' : '340px') + ';\n  height: ' + (linkTheme.buttonHeight ? linkTheme.buttonHeight + 'px' : '34px') + ';\n  justify-content: flex-start;\n  position: relative;\n  z-index: 2;\n  ' + (linkTheme.backgroundColour && 'background-color: ' + linkTheme.backgroundColour + ';') + '\n  ' + (linkTheme.fontFamily && 'font-family: ' + linkTheme.fontFamily + ';') + '\n  ' + (linkTheme.textTransform && 'text-transform: ' + linkTheme.textTransform + ';') + '\n  ' + (linkTheme.fontSize && 'font-size: ' + linkTheme.fontSize + ';') + '\n  ' + (linkTheme.colour && 'color: ' + linkTheme.colour + ';') + '\n  ' + (linkTheme.characterSpacing && 'letter-spacing: ' + linkTheme.characterSpacing + ';') + '\n  ' + (linkTheme.alignment && 'justify-content: ' + linkTheme.alignment + ';') + '\n  svg > rect:not([fill=\'none\']) {\n    ' + (linkTheme.colour && 'fill: ' + linkTheme.colour + ';') + '\n  }\n  svg > path:not([fill]) {\n    ' + (linkTheme.colour && 'fill: ' + linkTheme.colour + ';') + '\n  }\n';
};

var mobileButtonStyleOverride = function mobileButtonStyleOverride() {
  return '\n  margin: 5px 0;\n  min-width: unset;\n';
};

var headingOneStyleOverride = function headingOneStyleOverride(headerTheme) {
  return '\nposition: relative;\nz-index: 2;\nheight: auto;\noverflow-wrap: break-word;\n' + (headerTheme.fontFamily && 'font-family: ' + headerTheme.fontFamily + ';') + '\n' + (headerTheme.textTransform && 'text-transform: ' + headerTheme.textTransform + ';') + '\n' + (headerTheme.fontSize && 'font-size: ' + headerTheme.fontSize + ';') + '\n' + (headerTheme.colour && 'color: ' + headerTheme.colour + ';') + '\n' + (headerTheme.characterSpacing && 'letter-spacing: ' + headerTheme.characterSpacing + ';') + '\n';
};

var headingTwoStyleOverride = function headingTwoStyleOverride(bodyTheme) {
  return '\nposition: relative;\nmax-height: 200px;\nz-index: 2;\noverflow-wrap: break-word;\n' + (bodyTheme.fontFamily && 'font-family: ' + bodyTheme.fontFamily + ';') + '\n' + (bodyTheme.textTransform && 'text-transform: ' + bodyTheme.textTransform + ';') + '\n' + (bodyTheme.fontSize && 'font-size: ' + bodyTheme.fontSize + ';') + '\n' + (bodyTheme.colour && 'color: ' + bodyTheme.colour + ';') + '\n' + (bodyTheme.characterSpacing && 'letter-spacing: ' + bodyTheme.characterSpacing + ';') + '\n' + (bodyTheme.lineHeight && 'line-height: ' + bodyTheme.lineHeight + ';') + '\n' + (bodyTheme.whiteSpace ? 'white-space: ' + bodyTheme.whiteSpace + ';' : '') + '\n';
};

var Image = function Image(_ref12) {
  var url = _ref12.url,
      preview = _ref12.preview,
      mediaContent = _ref12.mediaContent,
      translations = _ref12.translations,
      onLinkClick = _ref12.onLinkClick;
  return _react2.default.createElement(
    ClickWrapper,
    {
      fullScreen: mediaContent.fullScreen,
      pointer: url && url.value,
      onClick: function onClick(e) {
        if (preview) {
          e.preventDefault();
        }
        if (url) {
          onLinkClick(url && url.value);
        }
      }
    },
    _react2.default.createElement(BackgroundImage, {
      'data-cy': mediaContent.value,
      src: mediaContent.value,
      alt: translations && translations.alternativeText || ''
    })
  );
};

var YouTubeWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Slides__YouTubeWrapper',
  componentId: 'sc-alsy4d-10'
})(['', ';'], _theme2.default.max.medium(_templateObject6));

var SlideOne = exports.SlideOne = function SlideOne(_ref13) {
  var url = _ref13.url,
      translations = _ref13.translations,
      topLink = _ref13.topLink,
      midLink = _ref13.midLink,
      bottomLink = _ref13.bottomLink,
      headerTheme = _ref13.headerTheme,
      contentWidth = _ref13.contentWidth,
      _ref13$mediaContent = _ref13.mediaContent,
      mediaContent = _ref13$mediaContent === undefined ? { value: '', label: '' } : _ref13$mediaContent,
      _ref13$preview = _ref13.preview,
      preview = _ref13$preview === undefined ? false : _ref13$preview,
      onLinkClick = _ref13.onLinkClick,
      linkTheme = _ref13.linkTheme,
      slideContentWidth = _ref13.slideContentWidth,
      headerMobileFontSize = _ref13.headerMobileFontSize,
      headerMobileFontColour = _ref13.headerMobileFontColour,
      dispatch = _ref13.dispatch;

  var isMobile = (0, _useCheckIsMobileScreen2.default)();
  return _react2.default.createElement(
    SlideContainer,
    { dispatch: dispatch },
    _react2.default.createElement(Image, { url: url, preview: preview, mediaContent: mediaContent, onLinkClick: onLinkClick }),
    mediaContent.fullScreen && _react2.default.createElement(SectionChevron, {
      'data-cy': 'go-to-section',
      src: mediaContent.sectionChevron,
      onClick: function onClick() {
        document.location = mediaContent.sectionURL;
      }
    }),
    _react2.default.createElement(
      _ContentWrapper2.default,
      { contentWidth: slideContentWidth },
      _react2.default.createElement(
        SlideOneContentContainer,
        { width: contentWidth },
        _react2.default.createElement(
          TopSlideContentContainer,
          null,
          translations.header && _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n                  margin: 0;\n                  line-height: 1.1;\n                  text-align:center;\n                  ' + headingOneStyleOverride(headerTheme) + '\n                ';
              },
              mobileStyleOverride: function mobileStyleOverride() {
                return [{
                  queryPath: 'max.medium',
                  template: 'margin: 20px 0 0;\n                    ' + (headerMobileFontSize && 'font-size : ' + headerMobileFontSize + 'px;') + '\n                    ' + (headerMobileFontColour && 'color : ' + headerMobileFontColour)
                }];
              }
            },
            translations.header
          ),
          translations.body && _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n                  margin: 0;\n                  line-height: 1.1;\n                  text-align:center;\n                  ' + headingOneStyleOverride(headerTheme) + '\n                ';
              },
              mobileStyleOverride: function mobileStyleOverride() {
                return [{
                  queryPath: 'max.medium',
                  template: 'margin: 20px 0;\n                    ' + (headerMobileFontSize && 'font-size : ' + headerMobileFontSize + 'px;') + '\n                    ' + (headerMobileFontColour && 'color : ' + headerMobileFontColour)
                }];
              }
            },
            translations.body
          )
        ),
        _react2.default.createElement(
          ButtonContainer,
          null,
          topLink.on && _react2.default.createElement(_Form.Button, {
            variant: 'tertiary',
            'data-cy': 'button-topLink',
            text: translations.topLink,
            onClick: function onClick() {
              return onLinkClick(topLink.url);
            },
            styleOverride: function styleOverride() {
              return '\n                height:46px; \n                min-width:280px;\n              ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.medium',
                template: '\n                  min-width: auto;\n                  background-color: #6D30A7;\n                  color: #fff;\n                  span {\n                    font-size:14px;\n                    line-height: 24px;\n                  }'
              }];
            },
            icon: isMobile ? topLink.mobileIcon : topLink.desktopIcon
          }),
          midLink.on && _react2.default.createElement(_Global.Button, {
            buttonStyle: linkTheme.buttonStyle,
            text: translations.midLink,
            applyStyle: 'tertiary',
            'data-cy': 'button-midLink',
            styleOverride: function styleOverride() {
              return '\n                margin: 5px 0 0 0;\n                text-transform: uppercase;\n                :after {\n                  content: none;\n                }\n                ' + buttonStyleOverride(linkTheme) + '\n              ';
            },
            mobileStyleOverride: mobileButtonStyleOverride,
            onClick: function onClick() {
              return onLinkClick(midLink.url);
            }
          }),
          bottomLink.on && _react2.default.createElement(_Global.Button, {
            buttonStyle: linkTheme.buttonStyle,
            text: translations.bottomLink,
            'data-cy': 'button-bottomLink',
            applyStyle: 'tertiary',
            styleOverride: function styleOverride() {
              return '\n                margin: 5px 0 0 0;\n                text-transform: uppercase;\n                :after {\n                  content: none;\n                }\n                ' + buttonStyleOverride(linkTheme) + '\n              ';
            },
            mobileStyleOverride: mobileButtonStyleOverride,
            onClick: function onClick() {
              return onLinkClick(bottomLink.url);
            }
          })
        )
      )
    ),
    _react2.default.createElement(_reactWaypoint2.default, {
      dispatch: dispatch,
      topOffset: '20%',
      onPositionChange: function onPositionChange(props) {
        return dispatch(_sessionPreferences.actions.updateSessionPreferences('isHeroVisible', props.currentPosition === 'inside'));
      }
    })
  );
};

var SlideTwo = exports.SlideTwo = function SlideTwo(_ref14) {
  var alignmentWidth = _ref14.alignmentWidth,
      align = _ref14.align,
      url = _ref14.url,
      translations = _ref14.translations,
      topLink = _ref14.topLink,
      midLink = _ref14.midLink,
      bottomLink = _ref14.bottomLink,
      headerTheme = _ref14.headerTheme,
      contentWidth = _ref14.contentWidth,
      _ref14$mediaContent = _ref14.mediaContent,
      mediaContent = _ref14$mediaContent === undefined ? { value: '', label: '' } : _ref14$mediaContent,
      bodyTheme = _ref14.bodyTheme,
      _ref14$preview = _ref14.preview,
      preview = _ref14$preview === undefined ? false : _ref14$preview,
      onLinkClick = _ref14.onLinkClick,
      linkTheme = _ref14.linkTheme,
      top = _ref14.top;
  return _react2.default.createElement(
    SlideContainer,
    null,
    _react2.default.createElement(Image, { url: url, preview: preview, mediaContent: mediaContent, onLinkClick: onLinkClick }),
    _react2.default.createElement(
      SlideContentContainer,
      {
        align: align,
        alignmentWidth: alignmentWidth,
        top: top
      },
      _react2.default.createElement(
        FullWidthOpacityWrapper,
        { theme: bodyTheme },
        _react2.default.createElement(
          TopSlideContentContainer,
          { width: contentWidth },
          translations.header && _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n                ' + headingOneStyleOverride(headerTheme) + '\n              ';
              },
              mobileStyleOverride: function mobileStyleOverride() {
                return [{
                  queryPath: 'max.medium',
                  template: 'margin: 20px;'
                }];
              }
            },
            translations.header
          ),
          translations.body && _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return '\n                ' + headingTwoStyleOverride(bodyTheme) + '\n              ';
              },
              mobileStyleOverride: function mobileStyleOverride() {
                return [{
                  queryPath: 'max.medium',
                  template: 'margin: 20px;'
                }];
              }
            },
            translations.body
          )
        ),
        _react2.default.createElement(
          ButtonContainer,
          null,
          topLink.on && _react2.default.createElement(_Global.Button, {
            buttonStyle: linkTheme.buttonStyle,
            text: translations.topLink,
            'data-cy': 'button-topLink',
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return '\n              margin: 10px auto;\n              ' + buttonStyleOverride(linkTheme) + '\n            ';
            },
            mobileStyleOverride: mobileButtonStyleOverride,
            onClick: function onClick() {
              return onLinkClick(topLink.url);
            }
          }),
          midLink.on && _react2.default.createElement(_Global.Button, {
            buttonStyle: linkTheme.buttonStyle,
            text: translations.midLink,
            applyStyle: 'primary',
            'data-cy': 'button-midLink',
            styleOverride: function styleOverride() {
              return '\n              margin: 10px auto;\n              ' + buttonStyleOverride(linkTheme) + '\n            ';
            },
            mobileStyleOverride: mobileButtonStyleOverride,
            onClick: function onClick() {
              return onLinkClick(midLink.url);
            }
          }),
          bottomLink.on && _react2.default.createElement(_Global.Button, {
            buttonStyle: linkTheme.buttonStyle,
            text: translations.bottomLink,
            'data-cy': 'button-bottomLink',
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return '\n              margin: 10px auto;\n              ' + buttonStyleOverride(linkTheme) + '\n            ';
            },
            mobileStyleOverride: mobileButtonStyleOverride,
            onClick: function onClick() {
              return onLinkClick(bottomLink.url);
            }
          })
        )
      )
    )
  );
};

// Lamborghini Slide Type
var SlideContent = _styledComponents2.default.div.withConfig({
  displayName: 'Slides__SlideContent',
  componentId: 'sc-alsy4d-11'
})(['', ';'], _theme2.default.min.large(_templateObject7));
var ImageContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Slides__ImageContentWrapper',
  componentId: 'sc-alsy4d-12'
})(['display:flex;align-items:center;justify-content:center;box-sizing:border-box;padding:10px;', ';', ';'], _theme2.default.min.medium(_templateObject8), _theme2.default.min.large(_templateObject9));
var ContentImage = _styledComponents2.default.img.withConfig({
  displayName: 'Slides__ContentImage',
  componentId: 'sc-alsy4d-13'
})(['', ';width:300px;z-index:2;'], _theme2.default.max.medium(_templateObject10));
var ContentText = _styledComponents2.default.div.withConfig({
  displayName: 'Slides__ContentText',
  componentId: 'sc-alsy4d-14'
})(['display:flex;flex:1 1 0%;flex-flow:column wrap;']);
var SlideFourButtonContainer = (0, _styledComponents2.default)(ButtonContainer).withConfig({
  displayName: 'Slides__SlideFourButtonContainer',
  componentId: 'sc-alsy4d-15'
})(['&{margin:0;}padding:0 20px;box-sizing:border-box;display:flex;justify-content:center;flex-direction:column;', ';', ';', ';', ';'], _theme2.default.min.small(_templateObject11), _theme2.default.min.medium(_templateObject12), _theme2.default.min.large(_templateObject13), _theme2.default.max.medium(_templateObject14));
var SlideFourOpacityWrapper = (0, _styledComponents2.default)(OpacityWrapper).withConfig({
  displayName: 'Slides__SlideFourOpacityWrapper',
  componentId: 'sc-alsy4d-16'
})(['&:after{opacity:', ';}', ';', ''], function (_ref15) {
  var theme = _ref15.theme;
  return theme.backgroundOpacity;
}, _theme2.default.max.large(_templateObject15), function (_ref16) {
  var width = _ref16.width;
  return width && 'width : ' + width + 'px;';
});
var SlideFourTopSlideContentContainer = (0, _styledComponents2.default)(TopSlideContentContainer).withConfig({
  displayName: 'Slides__SlideFourTopSlideContentContainer',
  componentId: 'sc-alsy4d-17'
})(['', ';', ';margin:0;align-self:flex-end;'], _theme2.default.max.large(_templateObject16), _theme2.default.max.small(_templateObject17));

var SlideFour = exports.SlideFour = function SlideFour(_ref17) {
  var url = _ref17.url,
      translations = _ref17.translations,
      topLink = _ref17.topLink,
      midLink = _ref17.midLink,
      bottomLink = _ref17.bottomLink,
      headerTheme = _ref17.headerTheme,
      contentWidth = _ref17.contentWidth,
      _ref17$mediaContent = _ref17.mediaContent,
      mediaContent = _ref17$mediaContent === undefined ? { value: '', label: '' } : _ref17$mediaContent,
      bodyTheme = _ref17.bodyTheme,
      _ref17$preview = _ref17.preview,
      preview = _ref17$preview === undefined ? false : _ref17$preview,
      onLinkClick = _ref17.onLinkClick,
      linkTheme = _ref17.linkTheme,
      slideContentWidth = _ref17.slideContentWidth,
      headerLeftPadding = _ref17.headerLeftPadding,
      headerRightPadding = _ref17.headerRightPadding,
      headingImage = _ref17.headingImage,
      youtubeVideo = _ref17.youtubeVideo;
  return _react2.default.createElement(
    SlideContainer,
    null,
    youtubeVideo && _react2.default.createElement(
      YouTubeWrapper,
      null,
      _react2.default.createElement(_YouTubePlayer2.default, {
        src: youtubeVideo.youtubeId,
        autoplay: youtubeVideo.autoplay,
        modestBranding: youtubeVideo.modestBranding,
        muted: youtubeVideo.muted,
        looping: youtubeVideo.looping,
        translations: translations
      })
    ),
    url && _react2.default.createElement(Image, { url: url, preview: preview, mediaContent: mediaContent, onLinkClick: onLinkClick, translations: translations }),
    url && _react2.default.createElement(
      _ContentWrapper2.default,
      { contentWidth: slideContentWidth },
      _react2.default.createElement(
        SlideContent,
        null,
        _react2.default.createElement(
          SlideFourTopSlideContentContainer,
          null,
          _react2.default.createElement(
            SlideFourOpacityWrapper,
            {
              theme: headerTheme,
              leftPadding: headerLeftPadding,
              rightPadding: headerRightPadding
            },
            headingImage && _react2.default.createElement(
              ImageContentWrapper,
              null,
              headingImage.value && _react2.default.createElement(ContentImage, {
                src: headingImage.value,
                alt: translations && translations.alternativeText || ''
              })
            )
          )
        ),
        _react2.default.createElement(
          SlideFourButtonContainer,
          null,
          (translations.header || translations.body) && _react2.default.createElement(
            SlideFourOpacityWrapper,
            {
              theme: headerTheme,
              leftPadding: headerLeftPadding,
              rightPadding: headerRightPadding,
              width: contentWidth
            },
            _react2.default.createElement(
              ContentText,
              null,
              translations.header && _react2.default.createElement(
                _Global.HeadingOne,
                {
                  id: 'main-start-here',
                  styleOverride: function styleOverride() {
                    return '\n              margin: 5px 0;\n              ' + headingOneStyleOverride(headerTheme) + '\n            ';
                  },
                  mobileStyleOverride: function mobileStyleOverride() {
                    return [{
                      queryPath: 'max.small',
                      template: 'font-size: 28px; padding: 10px 10px 0'
                    }];
                  }
                },
                translations.header
              ),
              translations.body && _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n              margin: 10px 0;\n              ' + headingTwoStyleOverride(bodyTheme) + '\n            ';
                  },
                  mobileStyleOverride: function mobileStyleOverride() {
                    return [{
                      queryPath: 'max.small',
                      template: 'font-size: 18px; padding: 0 10px'
                    }];
                  }
                },
                translations.body
              )
            )
          ),
          topLink.on && _react2.default.createElement(_Global.Button, {
            tabindex: '0',
            buttonStyle: linkTheme.buttonStyle,
            text: translations.topLink,
            'data-cy': 'button-topLink',
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return '\n                height: ' + linkTheme.buttonHeight + 'px;\n                font-size: ' + linkTheme.fontSize + ';\n                font-family: ' + linkTheme.fontFamily + ';\n                margin: 10px 0;\n                max-width: 300px;\n                align-self: flex-start;\n                && {\n                  &:after {\n                    width: 24px;\n                    height: 24px;\n                    background-size: 24px;\n                  };\n                };\n              ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return '\n                margin: 5px auto;\n                height: ' + linkTheme.buttonHeight + 'px;\n              ';
            },
            onClick: function onClick() {
              return onLinkClick(topLink.url);
            }
          }),
          midLink.on && _react2.default.createElement(_Global.Button, {
            buttonStyle: linkTheme.buttonStyle,
            text: translations.midLink,
            'data-cy': 'button-midLink',
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return '\n                margin: 5px 0 0 0;\n                ' + buttonStyleOverride(linkTheme) + '\n              ';
            },
            mobileStyleOverride: mobileButtonStyleOverride,
            onClick: function onClick() {
              return onLinkClick(midLink.url);
            }
          }),
          bottomLink.on && _react2.default.createElement(_Global.Button, {
            buttonStyle: linkTheme.buttonStyle,
            text: translations.bottomLink,
            'data-cy': 'button-bottomLink',
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return '\n                margin: 5px 0 0 0;\n                ' + buttonStyleOverride(linkTheme) + '\n              ';
            },
            mobileStyleOverride: mobileButtonStyleOverride,
            onClick: function onClick() {
              return onLinkClick(bottomLink.url);
            }
          })
        )
      )
    )
  );
};