'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VehicleTitleInfo = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     font-size: 34px;\n  '], ['\n     font-size: 34px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicleTypes = require('../../../../types/RollsRoyce/vehicleTypes');

var _Global = require('../../../Global');

var _types = require('../../VehicleCard/types');

var _VehicleCard = require('../../VehicleCard');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var REGULAR_FONTS = 'RivieraNights-Regular,Jost-Regular';

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__Wrapper',
  componentId: 'sc-o5g7gf-0'
})(['width:100%;', ''], function (_ref) {
  var darkMode = _ref.darkMode;
  return darkMode && '&&& > * {color: #fff !important;}';
});

var VehicleTitle = (0, _styledComponents2.default)(_Global.HeadingOne).withConfig({
  displayName: 'VehicleTitleInfo__VehicleTitle',
  componentId: 'sc-o5g7gf-1'
})(['font-size:22px;letter-spacing:0.5px;line-height:auto;text-transform:uppercase;margin:0;', ';'], _theme2.default.min.large(_templateObject));

var VehicleSubTitle = (0, _styledComponents2.default)(_Global.HeadingTwo).withConfig({
  displayName: 'VehicleTitleInfo__VehicleSubTitle',
  componentId: 'sc-o5g7gf-2'
})(['font-size:14px;letter-spacing:0.5px;margin:0;']);

var Line = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__Line',
  componentId: 'sc-o5g7gf-3'
})(['display:', ';padding-top:14px;margin-bottom:14px;border-bottom:1px solid #3b3b3b;margin:0;width:100%;', ''], function (_ref2) {
  var visible = _ref2.visible;
  return visible === true ? 'block' : 'none';
}, function (_ref3) {
  var darkMode = _ref3.darkMode;
  return darkMode && 'border-color: #fff;';
});

var VehicleTitleInfo = function VehicleTitleInfo(_ref4) {
  var vehicle = _ref4.vehicle,
      translations = _ref4.translations,
      darkMode = _ref4.darkMode;
  var _vehicle$modelVariant = vehicle.modelVariant,
      modelVariant = _vehicle$modelVariant === undefined ? null : _vehicle$modelVariant,
      _vehicle$modelYear = vehicle.modelYear,
      modelYear = _vehicle$modelYear === undefined ? null : _vehicle$modelYear,
      _vehicle$specificatio = vehicle.specification,
      _vehicle$specificatio2 = _vehicle$specificatio.fuelType,
      fuelType = _vehicle$specificatio2 === undefined ? null : _vehicle$specificatio2,
      _vehicle$specificatio3 = _vehicle$specificatio.transmission,
      transmission = _vehicle$specificatio3 === undefined ? null : _vehicle$specificatio3,
      odometer = _vehicle$specificatio.odometer;


  return _react2.default.createElement(
    Wrapper,
    { id: 'Vehicle-Title', darkMode: darkMode },
    _react2.default.createElement(_VehicleCard.VehicleLabel, {
      translations: translations,
      bespoke: vehicle.bespoke,
      modelCollectionName: vehicle.modelCollectionName,
      isVdp: true
    }),
    _react2.default.createElement(
      VehicleTitle,
      {
        styleOverride: function styleOverride() {
          return '' + (0, _Global.fontStyleOverride)({
            typeface: {
              value: REGULAR_FONTS
            }
          });
        }
      },
      modelVariant
    ),
    _react2.default.createElement(
      VehicleSubTitle,
      {
        styleOverride: function styleOverride() {
          return (0, _Global.fontStyleOverride)({
            typeface: { value: REGULAR_FONTS }
          }) + ' \n      ';
        }
      },
      translations.modelYear + ' ' + modelYear + ' | ' + transmission + ' | ' + fuelType + ' ' + (odometer ? '| ' + odometer.display : '') + ' '
    ),
    _react2.default.createElement(Line, { visible: true, darkMode: darkMode })
  );
};

exports.VehicleTitleInfo = VehicleTitleInfo;