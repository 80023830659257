'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.widthOptions = undefined;

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: ', ';\n  '], ['\n    padding: ', ';\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n  '], ['\n    flex-direction: column;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: ', ';\n    width: auto;\n  '], ['\n    padding: ', ';\n    width: auto;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    width: auto;\n    padding: 0;\n    height: 300px;\n  '], ['\n    width: auto;\n    padding: 0;\n    height: 300px;\n  ']);
/* eslint-env browser */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _path = require('ramda/src/path');

var _path2 = _interopRequireDefault(_path);

var _DirectionSearcher = require('./DirectionSearcher');

var _DirectionSearcher2 = _interopRequireDefault(_DirectionSearcher);

var _GoogleMap = require('./GoogleMap');

var _GoogleMap2 = _interopRequireDefault(_GoogleMap);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _RetailerInformation = require('../modules/RetailerInformation/RetailerInformation');

var _Pin = require('./JaguarIcons/Global/Pin');

var _Pin2 = _interopRequireDefault(_Pin);

var _Global = require('./Global');

var _ContentWrapper = require('./ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _distance = require('../shared/localisation/distance');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var widthOptions = exports.widthOptions = {
  widthOptionOne: { mapWidth: 50, retailerWidth: 50 },
  widthOptionTwo: { mapWidth: 60, retailerWidth: 40 },
  widthOptionThree: { mapWidth: 70, retailerWidth: 30 }
};

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__Container',
  componentId: 'sc-118v541-0'
})(['', ' display:flex;flex-direction:column;align-items:', ';'], function (_ref) {
  var bgColour = _ref.bgColour;
  return bgColour && 'background-color: ' + bgColour + ';';
}, function (_ref2) {
  var alignItems = _ref2.alignItems;
  return alignItems ? '' + alignItems : 'center';
});

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__HeaderContainer',
  componentId: 'sc-118v541-1'
})(['display:flex;padding:', ';', ';', ''], function (_ref3) {
  var padding = _ref3.padding;
  return '' + (padding === 0 ? '' + padding : '32px 0');
}, _theme2.default.max.large(_templateObject, function (_ref4) {
  var padding = _ref4.padding;
  return '' + (padding === 0 ? '' + padding : '32px 5%');
}), _theme2.default.max.medium(_templateObject2, function (_ref5) {
  var hideOnMobile = _ref5.hideOnMobile;
  return hideOnMobile && 'display: none';
}));

var FullWidthContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__FullWidthContainer',
  componentId: 'sc-118v541-2'
})(['display:flex;width:100%;justify-content:center;', ';', ';', ';', ';'], function (_ref6) {
  var backgroundColour = _ref6.backgroundColour;
  return '' + (backgroundColour && 'background-color: ' + backgroundColour);
}, function (_ref7) {
  var border = _ref7.border;
  return '' + (border && 'border-top: ' + border);
}, function (_ref8) {
  var border = _ref8.border;
  return (border && 'border-bottom: ' + border) + '\n  ';
}, function (_ref9) {
  var borderTop = _ref9.borderTop;
  return (borderTop && 'border-top: ' + borderTop) + '\n  ';
});

var RetailerContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__RetailerContainer',
  componentId: 'sc-118v541-3'
})(['display:flex;justify-content:center;height:auto;', ';', ';', ';'], function (_ref10) {
  var minHeight = _ref10.minHeight;
  return minHeight && 'min-height: ' + minHeight + 'px';
}, _theme2.default.max.large(_templateObject3), function (_ref11) {
  var retailerContainerPadding = _ref11.retailerContainerPadding;
  return retailerContainerPadding && 'padding: ' + retailerContainerPadding;
});

var RetailerInfo = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__RetailerInfo',
  componentId: 'sc-118v541-4'
})(['', ';display:flex;justify-content:space-around;flex-direction:column;padding:', ';', ';', ';', ';@media (min-width:1366px){', ';}'], function (_ref12) {
  var width = _ref12.width;
  return width && 'width: ' + width + '%';
}, function (_ref13) {
  var padding = _ref13.padding;
  return '' + (padding ? '' + padding : '32px 48px');
}, function (_ref14) {
  var justifyContent = _ref14.justifyContent;
  return justifyContent && 'justify-content: ' + justifyContent;
}, function (_ref15) {
  var boxSizing = _ref15.boxSizing;
  return boxSizing && 'box-sizing: ' + boxSizing;
}, _theme2.default.max.large(_templateObject4, function (_ref16) {
  var mobilePadding = _ref16.mobilePadding;
  return '' + (mobilePadding ? mobilePadding + '% 5%' : '0 5%');
}), function (_ref17) {
  var desktopPadding = _ref17.desktopPadding;
  return desktopPadding && 'padding-left: ' + desktopPadding + 'px;';
});

var WellMapHeader = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__WellMapHeader',
  componentId: 'sc-118v541-5'
})(['', ';'], function (_ref18) {
  var padding = _ref18.padding;
  return padding && 'padding: ' + padding.top + 'px ' + padding.right + 'px ' + padding.bottom + 'px ' + padding.left + 'px';
});

var MapContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__MapContainer',
  componentId: 'sc-118v541-6'
})(['', ';', ';'], function (_ref19) {
  var width = _ref19.width;
  return width && 'width: ' + width + '%';
}, _theme2.default.max.large(_templateObject5));
var GoogleMapStatic = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__GoogleMapStatic',
  componentId: 'sc-118v541-7'
})(['width:100%;height:100%;background-repeat:no-repeat;background-position:center;line-height:0;', ';'], function (_ref20) {
  var url = _ref20.url;
  return url && 'background-image: url(' + url + ')';
});
var PhoneLink = _styledComponents2.default.a.withConfig({
  displayName: 'PhoneLink'
}).withConfig({
  displayName: 'VdpRetailerMap__PhoneLink',
  componentId: 'sc-118v541-8'
})(['text-decoration:none;']);

var Link = _styledComponents2.default.a.withConfig({
  displayName: 'VdpRetailerMap__Link',
  componentId: 'sc-118v541-9'
})(['text-decoration:none;color:', ';'], function (props) {
  return props.colour ? '' + props.colour : 'unset';
});

var StyledInfoSection = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__StyledInfoSection',
  componentId: 'sc-118v541-10'
})(['padding:8px 0;', ';'], function (_ref21) {
  var borderBottom = _ref21.borderBottom;
  return '' + (borderBottom.lineBreak && 'border-bottom: ' + borderBottom.borderSectionColour);
});

var DirectionSearcherContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__DirectionSearcherContainer',
  componentId: 'sc-118v541-11'
})(['', ';', ';'], function (_ref22) {
  var horizontalPadding = _ref22.horizontalPadding;
  return horizontalPadding && 'padding: ' + horizontalPadding + 'px 0';
}, function (_ref23) {
  var borderBottom = _ref23.borderBottom;
  return borderBottom && 'border-bottom: ' + borderBottom;
});

var InfoSection = function InfoSection(_ref24) {
  var children = _ref24.children,
      lineBreak = _ref24.lineBreak,
      borderSectionColour = _ref24.borderSectionColour,
      qaHook = _ref24.qaHook;
  return _react2.default.createElement(
    StyledInfoSection,
    {
      'data-cy': qaHook,
      borderBottom: { lineBreak: lineBreak, borderSectionColour: borderSectionColour }
    },
    children
  );
};

InfoSection.defaultProps = {
  lineBreak: false,
  qaHook: undefined
};

var FlexDisplay = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__FlexDisplay',
  componentId: 'sc-118v541-12'
})(['display:flex;align-items:center;']);

var DistanceSpan = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__DistanceSpan',
  componentId: 'sc-118v541-13'
})(['font-size:12px;padding-left:5px;']);

var BusinessInfoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__BusinessInfoContainer',
  componentId: 'sc-118v541-14'
})(['padding:20px 0;margin:10px 0 0;', ';'], function (props) {
  return props.businessBorderColor ? 'border-top: 1px solid ' + props.businessBorderColor : 'border: unset';
});
var BusinessInfo = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__BusinessInfo',
  componentId: 'sc-118v541-15'
})(['font-size:12px;font-weight:bold;padding:5px 0;']);
var BusinessInfoValue = _styledComponents2.default.span.withConfig({
  displayName: 'VdpRetailerMap__BusinessInfoValue',
  componentId: 'sc-118v541-16'
})(['font-weight:normal;']);

var DistanceFrom = function DistanceFrom(_ref25) {
  var distanceFromRetailer = _ref25.distanceFromRetailer,
      iconColour = _ref25.iconColour;

  if (distanceFromRetailer) {
    return _react2.default.createElement(
      FlexDisplay,
      null,
      _react2.default.createElement(_Pin2.default, { colour: iconColour, width: '1.8em', height: '1.8em' }),
      _react2.default.createElement(
        DistanceSpan,
        null,
        distanceFromRetailer
      )
    );
  }
  return null;
};

var VdpRetailerMap = function (_Component) {
  (0, _inherits3.default)(VdpRetailerMap, _Component);

  function VdpRetailerMap(props) {
    (0, _classCallCheck3.default)(this, VdpRetailerMap);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VdpRetailerMap.__proto__ || (0, _getPrototypeOf2.default)(VdpRetailerMap)).call(this, props));

    Object.defineProperty(_this, 'getDistanceFromRetailer', {
      enumerable: true,
      writable: true,
      value: function value(position, country) {
        var _position$coords = position.coords,
            userLat = _position$coords.latitude,
            userLong = _position$coords.longitude;

        var _this$flipRetailerCoo = (0, _slicedToArray3.default)(_this.flipRetailerCoordinates().coordinates, 2),
            lat = _this$flipRetailerCoo[0],
            long = _this$flipRetailerCoo[1];

        var distanceService = new window.google.maps.DistanceMatrixService();
        distanceService.getDistanceMatrix({
          origins: [new window.google.maps.LatLng(userLat, userLong)],
          destinations: [new window.google.maps.LatLng(lat, long)],
          travelMode: 'DRIVING'
        }, function (response, status) {
          if (status === 'OK') {
            var distanceKM = response.rows[0].elements[0].distance && Math.ceil(response.rows[0].elements[0].distance.value / 1000);
            var distanceText = response.rows[0].elements[0].distance && response.rows[0].elements[0].distance.text;
            if (distanceKM && _this.props.translations.distanceLabelMiles) {
              _this.setState({
                distanceFromRetailer: (0, _distance.distanceLabel)(distanceKM, _this.props.translations, country)
              });
            } else if (distanceText) {
              _this.setState({
                distanceFromRetailer: (0, _translateFromTemplate.translateFromTemplate)('vdpRetailerDistanceSuccess', {
                  DISTANCE: distanceText
                }, _this.props.translations)
              });
            } else {
              _this.setState({
                distanceFromRetailer: _this.props.translations.vdpRetailerDistanceFailed
              });
            }
          } else {
            _this.setState({
              distanceFromRetailer: null
            });
          }
        });
      }
    });
    Object.defineProperty(_this, 'flipRetailerCoordinates', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            config = _this$props.config,
            retailer = _this$props.retailer;

        return config.flipRetailerCoordinates ? retailer.location && retailer.location.coordinates && retailer.location.coordinates.length === 2 && {
          coordinates: [retailer.location.coordinates[1], retailer.location.coordinates[0]]
        } : retailer.location;
      }
    });


    _this.state = {
      distanceFromRetailer: null
    };
    return _this;
  }

  (0, _createClass3.default)(VdpRetailerMap, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (this.props.locale !== prevProps.locale) {
        this.setState({
          distanceFromRetailer: null
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          config = _props.config,
          retailer = _props.retailer,
          translations = _props.translations,
          viewRetailerStock = _props.viewRetailerStock,
          viewGroupStock = _props.viewGroupStock,
          globalStyling = _props.globalStyling,
          makeAnEnquiry = _props.makeAnEnquiry,
          googleAvailable = _props.googleAvailable,
          hideContactButton = _props.hideContactButton,
          vehicleInfo = _props.vehicleInfo;
      var distanceFromRetailer = this.state.distanceFromRetailer;

      var showButton = !hideContactButton || !RegExp(hideContactButton.value).test((0, _path2.default)(hideContactButton.selectorProp.split('.'), vehicleInfo));

      return _react2.default.createElement(
        Container,
        {
          id: 'retailerMap',
          bgColour: config.backgroundColour,
          alignItems: config.alignItems
        },
        _react2.default.createElement(
          WellMapHeader,
          { padding: config.wellMapHeaderPadding },
          _react2.default.createElement(
            _ContentWrapper2.default,
            { contentWidth: globalStyling.contentWidth },
            _react2.default.createElement(
              HeaderContainer,
              {
                padding: config.headerContainerPadding,
                hideOnMobile: true
              },
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n                width: 100%;\n                ' + (0, _Global.fontStyleOverride)(config.headerFont) + '\n              ';
                  }
                },
                translations.retailerMapHeader
              )
            )
          )
        ),
        _react2.default.createElement(
          FullWidthContainer,
          {
            border: config.borderContainerColour,
            backgroundColour: config.backgroundColour,
            borderTop: config.borderTop
          },
          _react2.default.createElement(
            _ContentWrapper2.default,
            { contentWidth: globalStyling.contentWidth },
            _react2.default.createElement(
              RetailerContainer,
              {
                'data-cy': 'retailer-module',
                minHeight: config.contentHeight,
                retailerContainerPadding: config.retailerContainerPadding
              },
              _react2.default.createElement(
                RetailerInfo,
                {
                  width: widthOptions[config.widthOptionStyle].retailerWidth,
                  padding: config.padding,
                  justifyContent: config.justifyContent,
                  boxSizing: config.boxSizing,
                  mobilePadding: config.mobileRetailerInfoPadding,
                  desktopPadding: config.desktopPadding
                },
                config.visibility.showRetailerName && _react2.default.createElement(
                  InfoSection,
                  {
                    lineBreak: config.visibility.showLineBreakRetailerName,
                    borderSectionColour: config.borderSectionColour
                  },
                  _react2.default.createElement(
                    _Global.HeadingTwo,
                    {
                      styleOverride: function styleOverride() {
                        return '\n                      ' + (0, _Global.fontStyleOverride)(config.retailerFont) + '\n                    ';
                      }
                    },
                    retailer.name
                  )
                ),
                config.visibility.showAddress && _react2.default.createElement(
                  InfoSection,
                  {
                    qaHook: 'retailer-address',
                    lineBreak: config.visibility.showLineAddress,
                    borderSectionColour: config.borderSectionColour
                  },
                  [].concat((0, _toConsumableArray3.default)(retailer.address), [config.visibility.showLineAddressPostCode && retailer.postcode]).filter(Boolean).map(function (line, index) {
                    return _react2.default.createElement(
                      _Global.Paragraph,
                      {
                        key: 'map-info-' + line + '-' + index.toString(),
                        styleOverride: function styleOverride() {
                          return '\n                            margin: 0;\n                            ' + (0, _Global.fontStyleOverride)(config.retailerDetailsFont) + '\n                          ';
                        }
                      },
                      line
                    );
                  })
                ),
                (config.visibility.showPhone || config.visibility.showEmail) && _react2.default.createElement(
                  InfoSection,
                  {
                    qaHook: 'retailer-contact-info',
                    lineBreak: config.visibility.showLineContact,
                    borderSectionColour: config.borderSectionColour
                  },
                  config.visibility.showPhone && retailer.phone && _react2.default.createElement(
                    PhoneLink,
                    { href: 'tel:' + retailer.phone },
                    _react2.default.createElement(
                      _Global.Paragraph,
                      {
                        styleOverride: function styleOverride() {
                          return '\n                            margin: 0;\n                            ' + (0, _Global.fontStyleOverride)(config.retailerDetailsFont) + '\n                          ';
                        }
                      },
                      translations.vdpRetailerPhoneLabel + ': ' + retailer.phone
                    )
                  ),
                  config.visibility.showEmail && _react2.default.createElement(
                    _Global.Paragraph,
                    {
                      styleOverride: function styleOverride() {
                        return '\n                          margin: 0;\n                          ' + (0, _Global.fontStyleOverride)(config.retailerDetailsFont) + '\n                        ';
                      }
                    },
                    translations.vdpRetailerEmailLabel,
                    ':',
                    ' ',
                    retailer.email.split(',').map(function (e) {
                      return e;
                    })
                  ),
                  config.visibility.showRetailerMapDealerUrl && retailer.url && _react2.default.createElement(
                    Link,
                    { href: retailer.url },
                    _react2.default.createElement(
                      _Global.Paragraph,
                      {
                        styleOverride: function styleOverride() {
                          return '\n                            margin: 0;\n                            ' + (0, _Global.fontStyleOverride)(config.retailerDetailsFont) + '\n                          ';
                        }
                      },
                      translations.vdpRetailerWebsiteLabel + ': ' + retailer.url
                    )
                  ),
                  config.visibility.showBusinessInfo && _react2.default.createElement(
                    BusinessInfoContainer,
                    {
                      businessBorderColor: config.businessBorderColor
                    },
                    retailer.kiaDeGeschaftsfuhrer && _react2.default.createElement(
                      BusinessInfo,
                      null,
                      translations.businessManagerLabel + ': ',
                      _react2.default.createElement(
                        BusinessInfoValue,
                        null,
                        retailer.kiaDeGeschaftsfuhrer
                      )
                    ),
                    retailer.kiaDeHandelsregister && _react2.default.createElement(
                      BusinessInfo,
                      null,
                      translations.businessCommercialLabel + ': ',
                      _react2.default.createElement(
                        BusinessInfoValue,
                        null,
                        retailer.kiaDeHandelsregister
                      )
                    ),
                    retailer.kiaDeUmsatzsteuerIdentifikationsnummer && _react2.default.createElement(
                      BusinessInfo,
                      null,
                      translations.businessTaxNoLabel + ': ',
                      _react2.default.createElement(
                        BusinessInfoValue,
                        null,
                        retailer.kiaDeUmsatzsteuerIdentifikationsnummer
                      )
                    ),
                    retailer.kiaDeSitzDerGesellschaft && _react2.default.createElement(
                      BusinessInfo,
                      null,
                      translations.businessSeat + ': ',
                      _react2.default.createElement(
                        BusinessInfoValue,
                        null,
                        retailer.kiaDeSitzDerGesellschaft
                      )
                    ),
                    retailer.kiaDeImpressumslink && _react2.default.createElement(
                      BusinessInfo,
                      null,
                      _react2.default.createElement(
                        Link,
                        {
                          colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
                          href: retailer.kiaDeImpressumslink,
                          target: '_blank'
                        },
                        '' + translations.businessLinkLabel
                      )
                    )
                  )
                ),
                _react2.default.createElement(
                  DirectionSearcherContainer,
                  {
                    horizontalPadding: config.paddingBetweenDirectionSearcher,
                    borderBottom: config.visibility && config.visibility.showLineDirectionSearcher && config.borderSectionColour
                  },
                  config.visibility.showDistance && distanceFromRetailer && _react2.default.createElement(
                    InfoSection,
                    { qaHook: 'retailer-location' },
                    _react2.default.createElement(DistanceFrom, {
                      distanceFromRetailer: distanceFromRetailer,
                      iconColour: config.distanceIconColour
                    })
                  ),
                  config.visibility.showDirectionSearcher && googleAvailable && _react2.default.createElement(
                    InfoSection,
                    null,
                    _react2.default.createElement(_DirectionSearcher2.default, {
                      onGetCurrentPosition: function onGetCurrentPosition(position, country) {
                        return _this2.getDistanceFromRetailer(position, country);
                      },
                      placeholder: translations.vdpRetailerGetDirections,
                      destination: this.flipRetailerCoordinates(),
                      locationButtonWidth: config.locationButtonWidth,
                      buttonBGColour: config.directionSearcherBGColour,
                      opacityDirectionButton: config.opacityDirectionButton,
                      iconColour: config.locationIconColour,
                      borderRadiusButton: config.directionSearcherButtonBorderRadius,
                      retailerLocationCountry: retailer.country,
                      locationFilterLabel: translations.locationFilterLabel,
                      globalStyling: globalStyling,
                      resetDistanceFromRetailer: this.resetDistanceFromRetailer,
                      directionSearcherStyles: config.directionSearcherStyles
                    })
                  )
                ),
                config.visibility.showRetailerStockButton && !this.props.platformMode && _react2.default.createElement(
                  InfoSection,
                  null,
                  _react2.default.createElement(_Global.Button, {
                    'data-cy': 'view-retailer-stock-button',
                    applyStyle: 'primary',
                    buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
                    styleOverride: function styleOverride() {
                      return '\n                        width: 100%;\n                      ';
                    },
                    text: translations.vdpRetailerStockButtonText,
                    onClick: function onClick() {
                      return viewRetailerStock(retailer);
                    }
                  })
                ),
                config.visibility.showGroupStockButton && retailer.group && _react2.default.createElement(
                  InfoSection,
                  null,
                  _react2.default.createElement(_Global.Button, {
                    'data-cy': 'view-dealer-group-stock-button',
                    applyStyle: 'primary',
                    buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
                    styleOverride: function styleOverride() {
                      return '\n                        width: 100%;\n                      ';
                    },
                    text: translations.vdpGroupStockButtonText,
                    onClick: function onClick() {
                      return viewGroupStock(retailer);
                    }
                  })
                ),
                config.visibility.showEnquiryButton && showButton && _react2.default.createElement(
                  InfoSection,
                  null,
                  _react2.default.createElement(_Global.Button, {
                    applyStyle: 'secondary',
                    buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle,
                    styleOverride: function styleOverride() {
                      return '\n                        width: 100%;\n                      ';
                    },
                    text: translations.vdpRetailerEnquiryButtonText,
                    onClick: function onClick() {
                      return makeAnEnquiry();
                    }
                  })
                )
              ),
              _react2.default.createElement(
                MapContainer,
                {
                  width: widthOptions[config.widthOptionStyle].mapWidth
                },
                config.map.provider === 'google' ? config.map.type === 'dynamic' && googleAvailable ? _react2.default.createElement(_GoogleMap2.default, {
                  mapName: 'vdpMap',
                  retailerLocation: this.flipRetailerCoordinates(),
                  iconPin: config.iconPin
                }) : _react2.default.createElement(GoogleMapStatic, { url: retailer.dealerLocationMapUrl }) : null
              )
            )
          )
        )
      );
    }
  }]);
  return VdpRetailerMap;
}(_react.Component);

exports.default = VdpRetailerMap;