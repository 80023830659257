'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n  '], ['\n    display: flex;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n\n  padding-top: 12px;\n    ', '\n  '], ['\n\n  padding-top: 12px;\n    ', '\n  ']);

exports.default = LinkList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterColumnTitle = _styledComponents2.default.h3.withConfig({
  displayName: 'LinkList__FooterColumnTitle',
  componentId: 'sc-h8v3m3-0'
})(['font-size:14px;font-weight:normal;margin:0;margin-right:13px;padding:0;padding-top:12px;text-decoration:none;color:#000;']);
var FooterLink = _styledComponents2.default.a.withConfig({
  displayName: 'LinkList__FooterLink',
  componentId: 'sc-h8v3m3-1'
})(['text-decoration:none;margin-right:13px;color:#000;&:hover{text-decoration:underline;}', ';'], _theme2.default.max.large(_templateObject));
var IconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'LinkList__IconContainer',
  componentId: 'sc-h8v3m3-2'
})(['padding-top:30px;', ';'], _theme2.default.min.large(_templateObject2));
var Col = _styledComponents2.default.div.withConfig({
  displayName: 'LinkList__Col',
  componentId: 'sc-h8v3m3-3'
})(['display:flex;flex-direction:column;margin-bottom:12px;', ';'], _theme2.default.max.large(_templateObject3, function (_ref) {
  var open = _ref.open;
  return !open && 'display:none;';
}));

var ListTitle = _styledComponents2.default.div.withConfig({
  displayName: 'LinkList__ListTitle',
  componentId: 'sc-h8v3m3-4'
})(['display:flex;justify-content:space-between;', ';& h3{padding-top:30px;}'], _theme2.default.min.large(_templateObject2));

function LinkList(_ref2) {
  var title = _ref2.title,
      items = _ref2.items,
      navigateIfInternal = _ref2.navigateIfInternal,
      config = _ref2.config;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var isExternal = function isExternal(href) {
    return href.match(/^http/);
  };
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      ListTitle,
      null,
      _react2.default.createElement(
        FooterColumnTitle,
        { onClick: function onClick() {
            return setOpen(!open);
          } },
        title
      ),
      _react2.default.createElement(
        IconContainer,
        {
          onClick: function onClick() {
            return setOpen(!open);
          },
          role: 'button',
          tabIndex: 0
        },
        _react2.default.createElement('img', {
          src: open ? config.expandedMinus : config.expandPlus,
          alt: open ? config.translations.collapseIcon : config.translations.expandIcon
        })
      )
    ),
    _react2.default.createElement(
      Col,
      { open: open },
      items.map(function (_ref3) {
        var label = _ref3.label,
            link = _ref3.link,
            navId = _ref3.navId;
        return _react2.default.createElement(
          FooterLink,
          {
            href: link,
            'data-cy': 'footer-' + label,
            target: isExternal(link) ? '_blank' : '_self',
            onClick: function onClick() {
              return navigateIfInternal(link);
            },
            key: navId
          },
          _react2.default.createElement(
            FooterColumnTitle,
            null,
            label
          )
        );
      })
    )
  );
}