'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = MobileFilters;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__Container',
  componentId: 'sc-o93poc-0'
})(['display:flex;width:100%;']);

var MobileFiltersOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__MobileFiltersOverlay',
  componentId: 'sc-o93poc-1'
})(['display:block;width:100%;position:fixed;top:0;left:0;bottom:0;z-index:100;background:white;overflow-y:auto;', ''], function (_ref) {
  var visible = _ref.visible;
  return visible && 'display: none;';
});

var SearchMenuContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__SearchMenuContainer',
  componentId: 'sc-o93poc-2'
})(['display:flex;width:100%;justify-content:space-between;align-items:center;height:40px;cursor:pointer;']);

var IconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__IconContainer',
  componentId: 'sc-o93poc-3'
})(['display:flex;align-items:center;padding:4px 0;margin-top:-1px;']);

var MenuLabel = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__MenuLabel',
  componentId: 'sc-o93poc-4'
})(['display:flex;color:#151515;font-family:\'RivieraNights-Regular\';font-size:10px;text-transform:uppercase;letter-spacing:2px;flex:1 0 0%;padding:0 10px;']);

var CloseFiltersIcon = _styledComponents2.default.img.withConfig({
  displayName: 'MobileFilters__CloseFiltersIcon',
  componentId: 'sc-o93poc-5'
})(['cursor:pointer;padding:0 20px;']);

var FilterHeader = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__FilterHeader',
  componentId: 'sc-o93poc-6'
})(['display:flex;padding-top:22px;justify-content:flex-end;']);

var Menu = function Menu(_ref2) {
  var toggle = _ref2.toggle,
      activeFiltersCount = _ref2.activeFiltersCount,
      translations = _ref2.translations;
  return _react2.default.createElement(
    SearchMenuContainer,
    { onClick: toggle },
    _react2.default.createElement(
      IconContainer,
      null,
      _react2.default.createElement('img', { src: 'https://res.cloudinary.com/motortrak/v1708730201/locator/rolls-royce/global/icons/filters-cog.svg' })
    ),
    _react2.default.createElement(
      MenuLabel,
      null,
      activeFiltersCount ? (0, _translateFromTemplate.translateFromTemplate)('mobileSearchMenuLabel', {
        COUNT: activeFiltersCount
      }, translations) : translations.mobileSearchMenuNoCountLabel
    )
  );
};

var FilterOverlay = function FilterOverlay(_ref3) {
  var toggle = _ref3.toggle,
      filters = _ref3.filters,
      visible = _ref3.visible;
  return _react2.default.createElement(
    MobileFiltersOverlay,
    { visible: visible },
    _react2.default.createElement(
      FilterHeader,
      null,
      _react2.default.createElement(CloseFiltersIcon, {
        onClick: toggle,
        src: 'https://res.cloudinary.com/motortrak/v1708427966/locator/rolls-royce/global/icons/close.svg'
      })
    ),
    filters
  );
};

function MobileFilters(props) {
  var filterProps = props.filters.props;
  var globalStyling = props.globalStyling,
      config = props.config;

  var chevronColour = config.chevronColour && config.chevronColour.value || globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value;
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(Menu, (0, _extends3.default)({
      toggle: filterProps.toggle,
      displayMobileFilterOptions: filterProps.displayMobileFilterOptions
    }, props, {
      chevronColour: chevronColour
    })),
    _react2.default.createElement(FilterOverlay, (0, _extends3.default)({}, (0, _extends3.default)({ toggle: filterProps.toggle }, props), {
      visible: !filterProps.displayMobileFilterOptions
    }))
  );
}