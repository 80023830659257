'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      margin-left: 18px;\n    '], ['\n      margin-left: 18px;\n    ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactPhoneInput = require('react-phone-input-2');

var _reactPhoneInput2 = _interopRequireDefault(_reactPhoneInput);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _ipInformation = require('../../../shared/localisation/ipInformation');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FieldError = _styledComponents2.default.div.withConfig({
  displayName: 'PhoneNumInput__FieldError',
  componentId: 'sc-7qmzoz-0'
})(['width:100%;display:flex;justify-content:flex-start;color:#9e1b32;']);

var StyledDiv = _styledComponents2.default.div.withConfig({
  displayName: 'PhoneNumInput__StyledDiv',
  componentId: 'sc-7qmzoz-1'
})(['width:100%;margin-bottom:50px;* > input:-webkit-autofill,* > input:-webkit-autofill:hover,* > input:-webkit-autofill:focus,* > input:-webkit-autofill:active{-webkit-box-shadow:0 0 0 30px #1b0d2e inset !important;-webkit-text-fill-color:white !important;background-clip:content-box !important;}&& .phone-input__button{background-color:#1b0d2e;border:none;border-radius:0;border-bottom:solid 2px #676776;margin-left:2px;', ' *{background-color:#1b0d2e !important;}}'], _RollsRoyce2.default.min.medium(_templateObject));

var userCountryCode = (0, _ipInformation.getIpCountryCode)() || 'gb';

var PhoneNumberInput = function PhoneNumberInput(_ref) {
  var value = _ref.value,
      preferredCountries = _ref.preferredCountries,
      onChange = _ref.onChange,
      error = _ref.error,
      localisations = _ref.localisations;

  return _react2.default.createElement(
    StyledDiv,
    null,
    _react2.default.createElement(_reactPhoneInput2.default, {
      placeholder: '',
      country: userCountryCode.toLowerCase(),
      localization: localisations,
      value: value,
      countryCodeEditable: false,
      autocompleteSearch: true,
      preferredCountries: preferredCountries,
      onChange: onChange,
      inputProps: {
        name: 'phone',
        id: 'phone',
        focus: true
      },
      containerStyle: {
        width: '100%',
        height: '45px',
        borderRadius: 'unset'
      },
      inputStyle: {
        width: '100%',
        height: '45px',
        borderRadius: 'unset',
        backgroundColor: '#1B0D2E',
        color: '#FFFFFF',
        border: 'none',
        borderBottom: 'solid 2px #676776',
        fontSize: '16px',
        fontFamily: 'RivieraNights-light'
      },
      buttonClass: 'phone-input__button'
    }),
    error && _react2.default.createElement(
      FieldError,
      null,
      error
    )
  );
};

exports.default = PhoneNumberInput;