'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      media = _ref$config.media,
      _ref$config$translati = _ref$config.translations,
      headlineText = _ref$config$translati.headlineText,
      bodyText = _ref$config$translati.bodyText,
      overlayConfig = (0, _objectWithoutProperties3.default)(_ref$config, ['media', 'translations']),
      _ref$globalStyling = _ref.globalStyling;
  _ref$globalStyling = _ref$globalStyling === undefined ? {} : _ref$globalStyling;
  var _ref$globalStyling$ui = _ref$globalStyling.uiElements,
      uiElements = _ref$globalStyling$ui === undefined ? {} : _ref$globalStyling$ui,
      dispatch = _ref.dispatch,
      handleComplete = _ref.handleComplete;

  var Component = {
    video: _components.Video
  }[media.componentType];
  return _react2.default.createElement(
    _components.Wrapper,
    null,
    _react2.default.createElement(Component, { src: media.src, handleComplete: handleComplete }),
    _react2.default.createElement(_components.Overlay, (0, _extends3.default)({}, overlayConfig, {
      headlineText: headlineText,
      bodyText: bodyText,
      uiElements: uiElements,
      dispatch: dispatch
    }))
  );
};