'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RenderRow = exports.TableDataFirstColumn = exports.TableData = exports.TableRow = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TableRow = exports.TableRow = _styledComponents2.default.tr.withConfig({
  displayName: 'TableElements__TableRow',
  componentId: 'sc-3hwuyt-0'
})(['font-size:20px;']);
var BaseTableData = _styledComponents2.default.td.withConfig({
  displayName: 'TableElements__BaseTableData',
  componentId: 'sc-3hwuyt-1'
})(['color:#fff;']);

var TableData = exports.TableData = (0, _styledComponents2.default)(BaseTableData).withConfig({
  displayName: 'TableElements__TableData',
  componentId: 'sc-3hwuyt-2'
})(['']);

var TableDataFirstColumn = exports.TableDataFirstColumn = (0, _styledComponents2.default)(BaseTableData).withConfig({
  displayName: 'TableElements__TableDataFirstColumn',
  componentId: 'sc-3hwuyt-3'
})(['padding:0px 20px;background-color:#1a1a1a;text-transform:uppercase;color:#fff;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:16px;']);

var DisclaimerStyle = _styledComponents2.default.span.withConfig({
  displayName: 'TableElements__DisclaimerStyle',
  componentId: 'sc-3hwuyt-4'
})(['font-size:12px;margin:0;padding:0;']);

var ItemStyle = _styledComponents2.default.p.withConfig({
  displayName: 'TableElements__ItemStyle',
  componentId: 'sc-3hwuyt-5'
})(['border-bottom:1px solid #aaaba6;font-size:16px;padding-bottom:10px;']);

var RenderRow = exports.RenderRow = function RenderRow(_ref) {
  var content = _ref.content,
      unit = _ref.unit,
      disclaimer = _ref.disclaimer,
      valueOveride = _ref.valueOveride;

  if (typeof content === 'string' && content.includes('null')) {
    content = valueOveride;
  }

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      ItemStyle,
      null,
      content,
      ' ',
      unit,
      disclaimer && _react2.default.createElement(
        DisclaimerStyle,
        { style: { fontSize: '12px' } },
        _react2.default.createElement('br', null),
        disclaimer
      )
    )
  );
};