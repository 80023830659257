'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n  '], ['\n    ', ';\n  ']);

exports.default = CookiePolicy;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__Container',
  componentId: 'sc-1pbsdnb-0'
})(['box-sizing:border-box;', ';', ';padding:5px 20px;', ';display:flex;flex-direction:row;align-items:center;'], function (_ref) {
  var fullWidth = _ref.fullWidth;
  return 'width: ' + (fullWidth ? '100%' : '90%');
}, function (props) {
  return props.colour && 'color: ' + props.colour;
}, function (_ref2) {
  var fullWidth = _ref2.fullWidth;
  return 'margin: ' + (fullWidth ? '0' : '0 auto');
});

var StyledPolicyLink = _styledComponents2.default.a.withConfig({
  displayName: 'CookiePolicy__StyledPolicyLink',
  componentId: 'sc-1pbsdnb-1'
})(['', ';text-decoration:underline;cursor:pointer;padding-left:5px;'], function (props) {
  return props.colour && 'color:  ' + props.colour;
});

var StickyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__StickyContainer',
  componentId: 'sc-1pbsdnb-2'
})(['', ';', ';', ';@media print{display:none;}'], function (_ref3) {
  var isSticky = _ref3.isSticky;
  return isSticky && '\n    position: fixed;\n    width: 100%;\n    z-index: 99;\n    bottom: 0px;\n  ';
}, _theme2.default.min.medium(_templateObject, function (_ref4) {
  var maxWidth = _ref4.maxWidth;
  return maxWidth && 'max-width: ' + maxWidth + 'px';
}), function (_ref5) {
  var position = _ref5.position;
  return (0, _Global.positionLayout)(position);
});

function CookiePolicy(props) {
  var domEl = document.getElementById('footer-sticky-bar-container');

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      hide = _useState2[0],
      setHide = _useState2[1];

  var config = props.config,
      globalStyling = props.globalStyling;

  var fullWidth = !!globalStyling.contentWidth;

  // Cookie name should be per brand.
  // Otherwise it might be some conflict with multiple brands.
  var localStorageKey = config.browserCookieName || 'cookiesAccepted';

  var cookiesAccepted = localStorage.getItem(localStorageKey);
  if (cookiesAccepted === 'true' || hide) return null;

  var CookieContent = function CookieContent() {
    return _react2.default.createElement(
      _ContentWrapper2.default,
      {
        colour: config.backgroundColour && config.backgroundColour,
        borderColour: config.borderColour && config.borderColour,
        contentWidth: globalStyling.contentWidth
      },
      _react2.default.createElement(
        Container,
        { fullWidth: fullWidth, 'data-nosnippet': true },
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(config.cookiesBodyFont);
            }
          },
          config.translations.bodyText,
          config.policyLink.on && _react2.default.createElement(
            StyledPolicyLink,
            {
              colour: config.cookiesBodyLinkFontColour && config.cookiesBodyLinkFontColour.value,
              target: config.newTab && '_blank',
              rel: config.newTab && 'noopener noreferrer',
              href: config.policyLink.url
            },
            config.translations.policyLink
          ),
          config.translations.secondBodyText
        ),
        _react2.default.createElement(_Global.Button, {
          'data-cy': 'cookieButton',
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          applyStyle: 'primary',
          text: config.translations.buttonText,
          styleOverride: function styleOverride() {
            return 'margin-left: 15px';
          },
          onClick: function onClick() {
            localStorage.setItem(localStorageKey, true);
            setHide(true);
          }
        })
      )
    );
  };
  return domEl ? _reactDom2.default.createPortal(_react2.default.createElement(CookieContent, null), domEl) : _react2.default.createElement(
    StickyContainer,
    {
      isSticky: config.isSticky,
      maxWidth: config.maxWidth,
      position: config.position
    },
    _react2.default.createElement(CookieContent, null)
  );
}