'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding-bottom: 75%;\n  '], ['\n    padding-bottom: 75%;\n  ']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _styledComponents2.default.div.withConfig({
  displayName: 'Image',
  componentId: 'sc-1spr4ay-0'
})(['width:100%;background-image:url(', ');background-size:', ';background-repeat:no-repeat;background-position:center;min-height:', 'px;', ';'], function (props) {
  return props.src;
}, function (props) {
  return props.backgroundSize || 'cover';
}, function (props) {
  return props.imageHeight || 300;
}, _theme2.default.max.medium(_templateObject));