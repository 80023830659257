'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_VDP';

var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_VEHICLE_INFO', 'GET_VEHICLE_INFO_SUCCESS', 'GET_VEHICLE_INFO_FAILURE', 'GET_SIMILAR_VEHICLES', 'GET_SIMILAR_VEHICLES_SUCCESS', 'GET_SIMILAR_VEHICLES_FAILURE', 'SIMILAR_VEHICLES_NEXT', 'SIMILAR_VEHICLES_PREV', 'SIMILAR_VEHICLES_NEXT_MOBILE', 'SIMILAR_VEHICLES_PREV_MOBILE', 'ACCESSORIES_NEXT_MOBILE', 'ACCESSORIES_PREV_MOBILE', 'UPDATE_MY_DEAL_WITH_PART_EXCHANGE', 'UPDATE_MY_DEAL_PRODUCT', 'SAVE_MY_DEAL', 'SAVE_MY_DEAL_SUCCESS', 'SAVE_MY_DEAL_FAILURE', 'PRINT_MY_DEAL', 'PRINT_MY_DEAL_SUCCESS', 'PRINT_VEHICLE', 'PRINT_VEHICLE_SUCCESS', 'SEND_FINANCE_LEAD_SUCCESS', 'SEND_FINANCE_LEAD_FAILURE', 'GET_FINANCE_SUCCESS', 'GET_SIMILAR_VEHICLES_FINANCE_SUCCESS', 'CHANGE_FINANCE_PRODUCT', 'CHANGE_FINANCE_PRODUCT_SUCCESS', 'UPDATE_VEHICLE_PRICES', 'UPDATE_VEHICLE_PRICES_SUCCESS']);

var id = exports.id = 'VDP';

var actions = exports.actions = {
  sendFinanceLeadSuccess: (0, _reducerMacros.moduleAction)(constants.SEND_FINANCE_LEAD_SUCCESS, id),
  sendFinanceLeadFailure: (0, _reducerMacros.moduleAction)(constants.SEND_FINANCE_LEAD_FAILURE, id),
  getVehicleInfo: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VEHICLE_INFO, id, function (payload) {
    return payload;
  }),
  getVehicleInfoSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VEHICLE_INFO_SUCCESS, id, function (payload) {
    return payload;
  }),
  getVehicleInfoFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VEHICLE_INFO_FAILURE, id, function (error) {
    return error;
  }),
  getSimilarVehicles: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_SIMILAR_VEHICLES, id, function (payload) {
    return payload;
  }),
  getSimilarVehiclesSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_SIMILAR_VEHICLES_SUCCESS, id, function (payload) {
    return payload;
  }),
  getSimilarVehiclesFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_SIMILAR_VEHICLES_FAILURE, id, function (error) {
    return error;
  }),
  similarVehiclesNext: (0, _reducerMacros.moduleAction)(constants.SIMILAR_VEHICLES_NEXT, id),
  similarVehiclesPrev: (0, _reducerMacros.moduleAction)(constants.SIMILAR_VEHICLES_PREV, id),
  similarVehiclesNextMobile: (0, _reducerMacros.moduleAction)(constants.SIMILAR_VEHICLES_NEXT_MOBILE, id),
  similarVehiclesPrevMobile: (0, _reducerMacros.moduleAction)(constants.SIMILAR_VEHICLES_PREV_MOBILE, id),
  accessoriesNextMobile: (0, _reducerMacros.moduleAction)(constants.ACCESSORIES_NEXT_MOBILE, id),
  accessoriesPrevMobile: (0, _reducerMacros.moduleAction)(constants.ACCESSORIES_PREV_MOBILE, id),
  updateMyDealWithPartExchange: (0, _reducerMacros.moduleActionWithArgs)(constants.UPDATE_MY_DEAL_WITH_PART_EXCHANGE, id, function (finance) {
    return finance;
  }),
  updateMyDealProduct: (0, _reducerMacros.moduleActionWithArgs)(constants.UPDATE_MY_DEAL_PRODUCT, id, function (finance) {
    return finance;
  }),
  saveMyDeal: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_MY_DEAL, id, function (payload) {
    return payload;
  }),
  saveMyDealSuccess: (0, _reducerMacros.moduleAction)(constants.SAVE_MY_DEAL_SUCCESS, id),
  saveMyDealFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_MY_DEAL_FAILURE, id, function (error) {
    return error;
  }),
  printMyDeal: (0, _reducerMacros.moduleActionWithArgs)(constants.PRINT_MY_DEAL, id, function (finance) {
    return finance;
  }),
  printMyDealSuccess: (0, _reducerMacros.moduleAction)(constants.PRINT_MY_DEAL_SUCCESS, id),
  printVehicle: (0, _reducerMacros.moduleActionWithArgs)(constants.PRINT_VEHICLE, id, function (payload) {
    return payload;
  }),
  printVehicleSuccess: (0, _reducerMacros.moduleAction)(constants.PRINT_MY_DEAL_SUCCESS, id),
  getFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  }),
  getSimilarVehiclesFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_SIMILAR_VEHICLES_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  }),
  changeFinanceProduct: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_FINANCE_PRODUCT, id, function (payload) {
    return payload;
  }),
  changeFinanceProductSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_FINANCE_PRODUCT_SUCCESS, id, function (payload) {
    return payload;
  }),
  updateVehiclePrices: (0, _reducerMacros.moduleActionWithArgs)(constants.UPDATE_VEHICLE_PRICES, id, function (payload) {
    return payload;
  }),
  updateVehiclePricesSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.UPDATE_VEHICLE_PRICES_SUCCESS, id, function (payload) {
    return payload;
  })
};

var replaceIfExists = function replaceIfExists(vehicles, vehicle) {
  return [].concat((0, _toConsumableArray3.default)(vehicles.map(function (v) {
    return v.id === vehicle.id ? vehicle : v;
  })));
};

var initialState = exports.initialState = {
  vdpConfig: null,
  error: null,
  similarVehicles: null,
  vehiclesInView: null,
  similarVehiclePage: 1,
  similarVehiclePageTotal: null,
  selectedVehicleMobile: 0,
  similarVehiclePageMobile: 1,
  similarVehiclePageTotalMobile: null,
  accessories: null,
  accessoriesInView: null,
  accessoriesPage: 1,
  accessoriesPageTotal: null,
  selectedAccessoryMobile: 0,
  accessoriesPageMobile: 1,
  accessoriesPageTotalMobile: null,
  context: '',
  printing: false
};

// the pagination logic in a nutshell is as follows...
// we have a similarVehicles array which we get from the service which can be a maximum
// of 9 vehicles long. We then slice the array into a vehiclesInView array which is
// a view of up to 3 vehicles on a "page".
// On desktop we then just increase or decrease the "page" number accordingly and re-slice the
// vehiclesInView array to match.

// on mobile it becomes more complicated because we only show 1 vehicle at a time.
// when on mobile there is a concept of a "selected vehicle" which is just the index of a vehicle
// in the vehiclesInView array. We need to increase/decrease the index in view up until
// the boundaries of the vehiclesInView array are hit and then increase or decrease the "page"
// number as before on desktop.
var maxVehiclesPerPage = 3;
var maxAccessoriesPerPage = 15;
// as per comments above
var take = function take(n) {
  return function (collection) {
    return collection.slice(0, n);
  };
};
var takeAccessories = take(maxAccessoriesPerPage);
var takeAccessoriesInView = take(maxAccessoriesPerPage);

function defineVehiclesInView(state, similarVehiclePage) {
  return state.similarVehicles.contents.slice(maxVehiclesPerPage * (similarVehiclePage - 1),
  // eslint-disable-next-line
  maxVehiclesPerPage * (similarVehiclePage - 1) + maxVehiclesPerPage);
}

function similarVehiclesNextMobile(state) {
  var selectedVehicleMobile = state.selectedVehicleMobile;

  selectedVehicleMobile = selectedVehicleMobile === state.vehiclesInView.length - 1 ? 0 : selectedVehicleMobile + 1;

  var similarVehiclePageMobile = state.similarVehiclePageMobile;

  similarVehiclePageMobile = similarVehiclePageMobile === state.similarVehiclePageTotalMobile ? 1 : similarVehiclePageMobile += 1;

  var similarVehiclePage = state.similarVehiclePage;

  similarVehiclePage = state.selectedVehicleMobile === state.vehiclesInView.length - 1 ? similarVehiclePage += 1 : similarVehiclePage;

  if (state.similarVehiclePageMobile === state.similarVehiclePageTotalMobile) {
    similarVehiclePage = 1;
  }

  var vehiclesInView = defineVehiclesInView(state, similarVehiclePage);

  return (0, _extends3.default)({}, state, {
    selectedVehicleMobile: selectedVehicleMobile,
    similarVehiclePageMobile: similarVehiclePageMobile,
    similarVehiclePage: similarVehiclePage,
    vehiclesInView: vehiclesInView
  });
}
function similarVehiclesPrevMobile(state) {
  var similarVehiclePageMobile = state.similarVehiclePageMobile;

  similarVehiclePageMobile = similarVehiclePageMobile === 1 ? state.similarVehiclePageTotalMobile : similarVehiclePageMobile -= 1;

  var similarVehiclePage = state.similarVehiclePage;

  similarVehiclePage = state.selectedVehicleMobile === 0 ? similarVehiclePage -= 1 : similarVehiclePage;

  if (state.similarVehiclePageMobile === 1) {
    similarVehiclePage = state.similarVehiclePageTotal;
  }

  var vehiclesInView = defineVehiclesInView(state, similarVehiclePage);

  var selectedVehicleMobile = state.selectedVehicleMobile;

  selectedVehicleMobile = selectedVehicleMobile === 0 ? vehiclesInView.length - 1 : selectedVehicleMobile -= 1;

  return (0, _extends3.default)({}, state, {
    selectedVehicleMobile: selectedVehicleMobile,
    similarVehiclePageMobile: similarVehiclePageMobile,
    similarVehiclePage: similarVehiclePage,
    vehiclesInView: vehiclesInView
  });
}

function defineAccessoriesInView(state, accessoriesPage) {
  return state.accessories.slice(maxAccessoriesPerPage * (accessoriesPage - 1),
  // eslint-disable-next-line
  maxAccessoriesPerPage * (accessoriesPage - 1) + maxAccessoriesPerPage);
}

function accessoriesNextMobile(state) {
  var selectedAccessoryMobile = state.selectedAccessoryMobile;

  selectedAccessoryMobile = selectedAccessoryMobile === state.accessoriesInView.length - 1 ? 0 : selectedAccessoryMobile + 1;

  var accessoriesPageMobile = state.accessoriesPageMobile;

  accessoriesPageMobile = accessoriesPageMobile === state.accessoriesPageTotalMobile ? 1 : accessoriesPageMobile += 1;

  var accessoriesPage = state.accessoriesPage;

  accessoriesPage = state.selectedAccessoryMobile === state.accessoriesInView.length - 1 ? accessoriesPage += 1 : accessoriesPage;

  if (state.accessoriesPageMobile === state.accessoriesPageTotalMobile) {
    accessoriesPage = 1;
  }

  var accessoriesInView = defineAccessoriesInView(state, accessoriesPage);

  return (0, _extends3.default)({}, state, {
    selectedAccessoryMobile: selectedAccessoryMobile,
    accessoriesPageMobile: accessoriesPageMobile,
    accessoriesPage: accessoriesPage,
    accessoriesInView: accessoriesInView
  });
}
function accessoriesPrevMobile(state) {
  var accessoriesPageMobile = state.accessoriesPageMobile;

  accessoriesPageMobile = accessoriesPageMobile === 1 ? state.accessoriesPageTotalMobile : accessoriesPageMobile -= 1;

  var accessoriesPage = state.accessoriesPage;

  accessoriesPage = state.selectedAccessoryMobile === 0 ? accessoriesPage -= 1 : accessoriesPage;

  if (state.accessoriesPageMobile === 1) {
    accessoriesPage = state.accessoriesPageTotal;
  }

  var accessoriesInView = defineAccessoriesInView(state, accessoriesPage);

  var selectedAccessoryMobile = state.selectedAccessoryMobile;

  selectedAccessoryMobile = selectedAccessoryMobile === 0 ? accessoriesInView.length - 1 : selectedAccessoryMobile -= 1;

  return (0, _extends3.default)({}, state, {
    selectedAccessoryMobile: selectedAccessoryMobile,
    accessoriesPageMobile: accessoriesPageMobile,
    accessoriesPage: accessoriesPage,
    accessoriesInView: accessoriesInView
  });
}

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.GET_VEHICLE_INFO_SUCCESS:
      {
        var _action$payload = action.payload,
            vdpConfig = _action$payload.vdpConfig,
            accessoriesCollection = _action$payload.accessories,
            placeholdingImage = _action$payload.placeholdingImage;

        var accessories = accessoriesCollection ? takeAccessories(accessoriesCollection) : null;
        var accessoriesPageTotal = accessories ? Math.ceil(accessories.length / maxAccessoriesPerPage) : 0;
        var accessoriesInView = accessories ? takeAccessoriesInView(accessories) : undefined;
        return (0, _extends3.default)({}, state, {
          vdpConfig: vdpConfig,
          accessories: accessories,
          placeholdingImage: placeholdingImage,
          accessoriesInView: accessoriesInView,
          accessoriesPageTotal: accessoriesPageTotal,
          accessoriesPageTotalMobile: accessories ? accessories.length : 0
        });
      }
    case constants.GET_FINANCE_SUCCESS:
      {
        return (0, _extends3.default)({}, state, {
          vdpConfig: (0, _extends3.default)({}, state.vdpConfig, state.vdpConfig.id === action.payload.id ? action.payload : {})
        });
      }
    case constants.GET_SIMILAR_VEHICLES_FINANCE_SUCCESS:
      {
        return (0, _extends3.default)({}, state, {
          similarVehicles: (0, _extends3.default)({}, state.similarVehicles, {
            contents: replaceIfExists(state.similarVehicles.contents || [], action.payload)
          }),
          vehiclesInView: replaceIfExists(state.vehiclesInView || [], action.payload)
        });
      }
    case constants.GET_SIMILAR_VEHICLES_SUCCESS:
      {
        var similarVehicles = action.payload.similarVehicles;

        var similarVehiclePageTotal = Math.ceil(similarVehicles.contents.length / maxVehiclesPerPage);
        var vehiclesInView = similarVehicles.contents.slice(0, 3);
        return (0, _extends3.default)({}, state, {
          similarVehicles: similarVehicles,
          vehiclesInView: vehiclesInView,
          similarVehiclePageTotal: similarVehiclePageTotal,
          similarVehiclePageTotalMobile: similarVehicles.contents.length
        });
      }
    case constants.SIMILAR_VEHICLES_NEXT:
      {
        var similarVehiclePage = state.similarVehiclePage;

        similarVehiclePage = similarVehiclePage === state.similarVehiclePageTotal ? 1 : similarVehiclePage += 1;
        var _vehiclesInView = defineVehiclesInView(state, similarVehiclePage);
        return (0, _extends3.default)({}, state, {
          vehiclesInView: _vehiclesInView,
          similarVehiclePage: similarVehiclePage
        });
      }
    case constants.SIMILAR_VEHICLES_PREV:
      {
        var _similarVehiclePage = state.similarVehiclePage;

        _similarVehiclePage = _similarVehiclePage === 1 ? state.similarVehiclePageTotal : _similarVehiclePage -= 1;
        var _vehiclesInView2 = defineVehiclesInView(state, _similarVehiclePage);
        return (0, _extends3.default)({}, state, {
          vehiclesInView: _vehiclesInView2,
          similarVehiclePage: _similarVehiclePage
        });
      }
    case constants.SIMILAR_VEHICLES_NEXT_MOBILE:
      {
        return similarVehiclesNextMobile(state);
      }

    case constants.SIMILAR_VEHICLES_PREV_MOBILE:
      {
        return similarVehiclesPrevMobile(state);
      }
    case constants.UPDATE_MY_DEAL_PRODUCT:
      return (0, _extends3.default)({}, state, {
        vdpConfig: (0, _extends3.default)({}, state.vdpConfig, {
          finance: (0, _extends3.default)({
            partExchange: state.vdpConfig.finance && state.vdpConfig.finance.partExchange
          }, action.payload)
        })
      });
    case constants.ACCESSORIES_NEXT_MOBILE:
      {
        return accessoriesNextMobile(state);
      }
    case constants.ACCESSORIES_PREV_MOBILE:
      {
        return accessoriesPrevMobile(state);
      }
    case constants.GET_SIMILAR_VEHICLES_FAILURE:
    case constants.GET_VEHICLE_INFO_FAILURE:
    case constants.SAVE_MY_DEAL_FAILURE:
      return (0, _extends3.default)({}, state, {
        error: action.payload
      });
    case constants.CHANGE_FINANCE_PRODUCT_SUCCESS:
      return (0, _extends3.default)({}, state, {
        context: action.payload
      });
    case constants.PRINT_MY_DEAL:
      return (0, _extends3.default)({}, state, {
        printing: true
      });
    case constants.PRINT_MY_DEAL_SUCCESS:
      return (0, _extends3.default)({}, state, {
        printing: false
      });
    case constants.UPDATE_VEHICLE_PRICES_SUCCESS:
      return (0, _extends3.default)({}, state, {
        vdpConfig: action.payload
      });
    default:
      return state;
  }
}