'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buttonStyleOverride = exports.fontStyleOverride = undefined;

var _fallbackFonts = require('./fallbackFonts');

var _fallbackFonts2 = _interopRequireDefault(_fallbackFonts);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var fontStyleOverride = exports.fontStyleOverride = function fontStyleOverride() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      typeface = _ref.typeface,
      fontSize = _ref.fontSize,
      kerning = _ref.kerning,
      transform = _ref.transform,
      colour = _ref.colour,
      lineHeight = _ref.lineHeight,
      alignment = _ref.alignment,
      padding = _ref.padding,
      textDecoration = _ref.textDecoration,
      margin = _ref.margin;

  return [typeface && 'font-family: ' + typeface.value + ',' + _fallbackFonts2.default + ';', fontSize && 'font-size: ' + fontSize + 'px;', kerning !== undefined && 'letter-spacing: ' + kerning + 'px;', transform && 'text-transform: ' + transform + ';', colour && 'color: ' + colour.value + ';', lineHeight && 'line-height: ' + lineHeight + 'px;', alignment && 'text-align: ' + alignment + ';', padding && padding.top && 'padding-top: ' + padding.top + 'px;', padding && padding.bottom && 'padding-bottom: ' + padding.bottom + 'px;', padding && padding.left && 'padding-left: ' + padding.left + 'px;', padding && padding.right && 'padding-right: ' + padding.right + 'px;', textDecoration && 'text-decoration: ' + textDecoration, margin && margin.top && 'margin-top: ' + margin.top + ';', margin && margin.bottom && 'margin-bottom: ' + margin.bottom + ';', margin && margin.left && 'margin-left: ' + margin.left + ';', margin && margin.right && 'margin-right: ' + margin.right + ';'].filter(Boolean).join('');
};

var buttonStyleOverride = exports.buttonStyleOverride = function buttonStyleOverride(font, backgroundColour, hoverBackgroundColour, buttonSize, hoverColour, border, fullBorder) {
  return '\n    ' + fontStyleOverride(font) + '\n    ' + [buttonSize && buttonSize.width && 'width: ' + buttonSize.width + ';', buttonSize && buttonSize.height && 'height: ' + buttonSize.height + '!important;', buttonSize && buttonSize.minWidth && 'min-width: ' + buttonSize.minWidth + ';', buttonSize && buttonSize.padding && 'padding: ' + buttonSize.padding + ';', buttonSize && buttonSize.fontSize && 'font-size: ' + buttonSize.fontSize + 'px;', buttonSize && buttonSize.minHeight && 'min-height: ' + buttonSize.minHeight + ';', backgroundColour && 'background-color: ' + backgroundColour.value + ';', border && border.radius && 'border-radius: ' + border.radius + ';', border && 'border: 1px solid ' + border.value + ';', fullBorder && 'border: ' + fullBorder + ';'].filter(Boolean).join('') + '\n\n    svg > rect:not([fill=\'none\']) {\n      ' + (font && font.colour && 'fill: ' + font.colour.value) + ';\n    }\n    svg > path:not([fill]) {\n      ' + (font && font.colour && 'fill: ' + font.colour.value) + ';\n    }\n    &:hover {\n      ' + [hoverBackgroundColour && 'background-color: ' + hoverBackgroundColour.value + ';', hoverColour && 'color: ' + hoverColour.value + ';'].filter(Boolean).join('') + '\n    }\n  ';
};