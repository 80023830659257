'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    min-height: ', 'px;\n  '], ['\n    min-height: ', 'px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 10px;\n  '], ['\n    padding: 10px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding-bottom: ', '\n  '], ['\n    padding-bottom: ', '\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    height: auto;\n    flex-direction: column;\n    align-items: flex-start;\n  '], ['\n    height: auto;\n    flex-direction: column;\n    align-items: flex-start;\n  ']);
/* eslint-disable no-return-assign, no-param-reassign */


exports.default = Footer;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FooterNavigation = require('../../components/FooterNavigation');

var _FooterNavigation2 = _interopRequireDefault(_FooterNavigation);

var _FooterBottomNavigation = require('../../components/FooterBottomNavigation');

var _FooterBottomNavigation2 = _interopRequireDefault(_FooterBottomNavigation);

var _SocialMediaLinks = require('../../components/SocialMediaLinks');

var _SocialMediaLinks2 = _interopRequireDefault(_SocialMediaLinks);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../components/Global');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var justifyContent = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

var Link = _styledComponents2.default.a.withConfig({
  displayName: 'Footer__Link',
  componentId: 'sc-smgc5d-0'
})(['text-decoration:none;', ';'], function (_ref) {
  var colour = _ref.colour;
  return colour && 'color: ' + colour.value;
});

var Section = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__Section',
  componentId: 'sc-smgc5d-1'
})(['list-style-type:none;align-items:center;display:flex;', ';', ';justify-content:', ';background-color:', ';', ' ', ';', ';'], function (_ref2) {
  var borderTop = _ref2.borderTop;
  return '' + (borderTop && 'border-top: ' + borderTop);
}, function (_ref3) {
  var borderBottom = _ref3.borderBottom;
  return '' + (borderBottom && 'border-bottom: ' + borderBottom);
}, function (props) {
  return props.align ? justifyContent[props.align] : justifyContent.left;
}, function (props) {
  return props.backgroundColour;
}, _theme2.default.min.large(_templateObject, function (props) {
  return props.height;
}), _theme2.default.max.large(_templateObject2), function (_ref4) {
  var mobilePadding = _ref4.mobilePadding;
  return '' + (mobilePadding && 'padding: ' + mobilePadding);
});

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'Footer__Image',
  componentId: 'sc-smgc5d-2'
})(['height:', 'px;margin:16px 0;'], function (props) {
  return props.height;
});

var SectionWithTextAlign = (0, _styledComponents2.default)(Section).withConfig({
  displayName: 'Footer__SectionWithTextAlign',
  componentId: 'sc-smgc5d-3'
})(['text-align:', ';display:', ';align-items:center;white-space:pre-line;overflow:hidden;flex-flow:column;padding-bottom:', ';', ';'], function (_ref5) {
  var align = _ref5.align;
  return align || 'left';
}, function (_ref6) {
  var visible = _ref6.visible;
  return visible ? 'flex' : 'none';
}, function (_ref7) {
  var footerStickyBar = _ref7.footerStickyBar,
      cookiesAccepted = _ref7.cookiesAccepted;
  return footerStickyBar ? cookiesAccepted ? footerStickyBar.paddingBottom && footerStickyBar.paddingBottom + 'px' || 0 : footerStickyBar.paddingBottomCookies && footerStickyBar.paddingBottomCookies + 'px' || 0 : 0;
}, _theme2.default.max.large(_templateObject3, function (_ref8) {
  var footerStickyBar = _ref8.footerStickyBar,
      cookiesAccepted = _ref8.cookiesAccepted;
  return footerStickyBar ? cookiesAccepted ? footerStickyBar.paddingBottomMobile && footerStickyBar.paddingBottomMobile + 'px' || 0 : footerStickyBar.paddingBottomCookiesMobile && footerStickyBar.paddingBottomCookiesMobile + 'px' || 0 : 0;
}));

var Links = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__Links',
  componentId: 'sc-smgc5d-4'
})(['display:flex;justify-content:center;padding:0;padding-bottom:', ';', ';margin:15px 0;'], function (_ref9) {
  var stickyBarConfig = _ref9.stickyBarConfig,
      cookiesAccepted = _ref9.cookiesAccepted;
  return stickyBarConfig ? cookiesAccepted ? stickyBarConfig.paddingBottom && stickyBarConfig.paddingBottom + 'px' || 0 : stickyBarConfig.paddingBottomCookies && stickyBarConfig.paddingBottomCookies + 'px' || 0 : 0;
}, _theme2.default.max.large(_templateObject3, function (_ref10) {
  var stickyBarConfig = _ref10.stickyBarConfig,
      cookiesAccepted = _ref10.cookiesAccepted;
  return stickyBarConfig ? cookiesAccepted ? stickyBarConfig.paddingBottomMobile && stickyBarConfig.paddingBottomMobile + 'px' || 0 : stickyBarConfig.paddingBottomCookiesMobile && stickyBarConfig.paddingBottomCookiesMobile + 'px' || 0 : 0;
}));

var FooterContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterContainer',
  componentId: 'sc-smgc5d-5'
})(['', ';', ';', ';display:flex;justify-content:space-between;align-items:center;margin-bottom:50px;', ';'], function (_ref11) {
  var height = _ref11.height;
  return '' + (height && 'height: ' + height + 'px');
}, function (_ref12) {
  var borderTop = _ref12.borderTop;
  return '' + (borderTop && 'border-top: ' + borderTop);
}, function (_ref13) {
  var borderBottom = _ref13.borderBottom;
  return '' + (borderBottom && 'border-bottom : ' + borderBottom);
}, _theme2.default.max.large(_templateObject4));

var FooterWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterWrapper',
  componentId: 'sc-smgc5d-6'
})(['@media print{display:none;}']);

var CenterLogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__CenterLogoContainer',
  componentId: 'sc-smgc5d-7'
})(['display:flex;align-items:center;position:absolute;', ';'], function (_ref14) {
  var height = _ref14.height;
  return '' + (height && 'height: ' + height + 'px');
});

var BrandLogo = _styledComponents2.default.img.withConfig({
  displayName: 'Footer__BrandLogo',
  componentId: 'sc-smgc5d-8'
})(['', ';height:30.31px;width:120px;'], function (_ref15) {
  var source = _ref15.source;
  return source && 'cursor: pointer;';
});

var SectionWithMidLogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__SectionWithMidLogoContainer',
  componentId: 'sc-smgc5d-9'
})(['display:flex;flex-direction:column;align-items:center;padding-top:26.12px;height:45px;']);

var EmissionsDisclaimer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__EmissionsDisclaimer',
  componentId: 'sc-smgc5d-10'
})(['font-size:14px;color:#fff;max-width:1200px;text-align:center;line-height:20px;']);

function Footer(_ref16) {
  var config = _ref16.config,
      preview = _ref16.preview,
      navigateIfInternal = _ref16.navigateIfInternal,
      globalStyling = _ref16.globalStyling,
      shared = _ref16.shared;

  var stickyBarConfig = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences.stickyBarConfig;
  });

  var footerStickyBar = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences.footerStickyBar;
  });

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      emissionsEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.emissionsEnabled
    };
  }),
      emissionsEnabled = _useSelector.emissionsEnabled;

  var pageBackgroundColour = globalStyling.colours.pageBackgroundColour && globalStyling.colours.pageBackgroundColour.value;
  var sectionBackgroundColour = function sectionBackgroundColour(colour) {
    return colour || pageBackgroundColour;
  };
  var extractColour = function extractColour(colour) {
    return colour && colour.value;
  };
  var oneRowFooter = function oneRowFooter() {
    return _react2.default.createElement(
      _ContentWrapper2.default,
      {
        colour: config.topBGColour,
        contentWidth: globalStyling.contentWidth
      },
      config.footerLogoBar && _react2.default.createElement(
        SectionWithMidLogoContainer,
        null,
        _react2.default.createElement(
          CenterLogoContainer,
          null,
          _react2.default.createElement(BrandLogo, {
            'data-cy': 'footer-logo',
            src: config.brandLogoUrl,
            alt: 'logo'
          })
        )
      ),
      _react2.default.createElement(
        FooterContainer,
        {
          height: config.topHeight,
          borderTop: config.borderTop,
          borderBottom: config.borderBottom
        },
        _react2.default.createElement(_FooterNavigation2.default, {
          theme: {
            font: config.topLinkFont,
            hoverColour: config.topLinkHoverColour
          },
          items: config.topItems.items.map(function (value, idx) {
            return (0, _extends3.default)({}, value, {
              label: config.translations['topItems-' + idx]
            });
          }).filter(function (item) {
            return item.link;
          }),
          preview: preview,
          navigateIfInternal: navigateIfInternal,
          oneRow: true,
          footerGrid: config.footerGrid,
          footerGridColumns: config.footerGridColumns,
          footerGridColumnsMobile: config.footerGridColumnsMobile
        }),
        _react2.default.createElement(_SocialMediaLinks2.default, (0, _extends3.default)({}, config, {
          socialIconsColour: config.socialIconsColour.value,
          preview: preview,
          facebookIconLink: config.facebookIconLink,
          twitterIconLink: config.twitterIconLink,
          youtubeIconLink: config.youtubeIconLink,
          instagramIconLink: config.instagramIconLink,
          linkedinIconLink: config.linkedinIconLink,
          translations: config.translations
        }))
      )
    );
  };
  return _react2.default.createElement(
    FooterWrapper,
    { 'data-nosnippet': true },
    config.oneRowFooter ? oneRowFooter() : _react2.default.createElement(
      _react.Fragment,
      null,
      config.showAdditionalTopSection && _react2.default.createElement(
        _ContentWrapper2.default,
        {
          colour: config.topBGColour,
          contentWidth: globalStyling.contentWidth
        },
        _react2.default.createElement(Section, {
          height: '50',
          backgroundColour: sectionBackgroundColour(extractColour(config.topBGColour)),
          align: config.topTierAlign
        })
      ),
      _react2.default.createElement(
        _ContentWrapper2.default,
        {
          colour: config.topBGColour,
          contentWidth: globalStyling.contentWidth
        },
        _react2.default.createElement(
          Section,
          {
            height: config.topHeight,
            backgroundColour: sectionBackgroundColour(extractColour(config.topBGColour)),
            align: config.topTierAlign,
            borderTop: config.topTierBorderTopHeight + 'px solid ' + (config.topTierBorderTopColour && config.topTierBorderTopColour.value)
          },
          _react2.default.createElement(_FooterNavigation2.default, {
            theme: {
              font: config.topLinkFont,
              hoverColour: config.topLinkHoverColour
            },
            align: config.topTierAlign,
            items: config.topItems.items.map(function (value, idx) {
              return (0, _extends3.default)({}, value, {
                label: config.translations['topItems-' + idx]
              });
            }).filter(function (item) {
              return item.link;
            }),
            preview: preview,
            navigateIfInternal: navigateIfInternal,
            footerGrid: config.footerGrid,
            footerGridColumns: config.footerGridColumns,
            footerGridColumnsMobile: config.footerGridColumnsMobile
          })
        )
      ),
      _react2.default.createElement(
        _ContentWrapper2.default,
        {
          colour: config.midBGColour,
          contentWidth: globalStyling.contentWidth
        },
        _react2.default.createElement(
          Section,
          {
            height: config.midHeight,
            backgroundColour: sectionBackgroundColour(extractColour(config.midBGColour)),
            align: config.midTierAlign,
            borderTop: config.midTierBorderTopHeight + 'px solid ' + (config.midTierBorderTopColour && config.midTierBorderTopColour.value)
          },
          _react2.default.createElement(_SocialMediaLinks2.default, (0, _extends3.default)({}, config, {
            socialIconsColour: config.socialIconsColour.value,
            preview: preview
          }))
        ),
        config.translations.emissionsDisclaimer && emissionsEnabled && _react2.default.createElement(
          Section,
          {
            height: config.midHeight,
            backgroundColour: sectionBackgroundColour(extractColour(config.midBGColour)),
            align: config.midTierAlign,
            borderTop: config.midTierBorderTopHeight + 'px solid ' + (config.midTierBorderTopColour && config.midTierBorderTopColour.value)
          },
          _react2.default.createElement(
            EmissionsDisclaimer,
            null,
            config.translations.emissionsDisclaimer
          )
        )
      ),
      _react2.default.createElement(
        SectionWithTextAlign,
        {
          height: config.bottomHeight,
          visible: config.translations.bottomLinkText,
          backgroundColour: sectionBackgroundColour(extractColour(config.bottomBGColour)),
          align: config.bottomTierAlign,
          borderTop: config.bottomTierBorderTopHeight + 'px solid ' + (config.bottomTierBorderTopColour && config.bottomTierBorderTopColour.value),
          paddingBottom: config.paddingBottom,
          footerStickyBar: footerStickyBar,
          cookiesAccepted: shared.sessionPreferences.cookiesAccepted
        },
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          config.showBottomImage && config.bottomImage && _react2.default.createElement(Image, {
            height: config.bottomImage.height,
            'data-cy': 'bottomImage',
            src: config.bottomImage.url,
            onError: function onError(_ref17) {
              var target = _ref17.target;
              return target.src = config.bottomImage.fallbackUrl;
            },
            alt: config.translations.logoAlternativeText || ''
          }),
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return '\n                ' + (0, _Global.fontStyleOverride)(config.bottomFont) + '\n                width: 80%;\n                margin: 0 auto;\n              ';
              }
            },
            config.translations.bottomText
          ),
          config.bottomItems ? _react2.default.createElement(_FooterBottomNavigation2.default, {
            theme: {
              font: config.bottomLinkFont,
              hoverColour: config.bottomLinkHoverColour
            },
            items: config.bottomItems.items.map(function (value, idx) {
              return (0, _extends3.default)({}, value, {
                label: config.translations['bottomItems-' + idx]
              });
            }),
            preview: preview,
            navigateIfInternal: navigateIfInternal
          }) : _react2.default.createElement(
            Links,
            {
              stickyBarConfig: stickyBarConfig,
              cookiesAccepted: shared.sessionPreferences.cookiesAccepted
            },
            config.translations.bottomLinkText && _react2.default.createElement(
              Link,
              {
                'data-cy': 'privacyFooterLink',
                href: config.bottomLinkText.url,
                onClick: function onClick(event) {
                  if (preview || navigateIfInternal(config.bottomLinkText.url)) {
                    event.preventDefault();
                  }
                }
              },
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return (0, _Global.fontStyleOverride)(config.bottomLinkFont);
                  }
                },
                config.translations.bottomLinkText
              )
            )
          )
        )
      )
    )
  );
}