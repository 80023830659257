'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 336px;\n  '], ['\n    max-width: 336px;\n  ']);

exports.default = FilterByCarContainer;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FilterByTile = require('./FilterByTile');

var _FilterByTile2 = _interopRequireDefault(_FilterByTile);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilterTileGrid = _styledComponents2.default.div.withConfig({
  displayName: 'FilterTileGrid'
}).withConfig({
  displayName: 'FilterByCarContainer__FilterTileGrid',
  componentId: 'sc-faehgu-0'
})(['display:flex;justify-content:space-around;flex-wrap:wrap;']);

var EmptyTile = _styledComponents2.default.div.withConfig({
  displayName: 'EmptyTile'
}).withConfig({
  displayName: 'FilterByCarContainer__EmptyTile',
  componentId: 'sc-faehgu-1'
})(['display:flex;flex-direction:column;padding:20px;width:100%;', ';'], _theme2.default.min.medium(_templateObject));

function FilterByCarContainer(_ref) {
  var _ref$cars = _ref.cars,
      cars = _ref$cars === undefined ? [] : _ref$cars,
      itemClicked = _ref.itemClicked,
      _ref$selectedModels = _ref.selectedModels,
      selectedModels = _ref$selectedModels === undefined ? [] : _ref$selectedModels,
      translations = _ref.translations,
      selectedIconColour = _ref.selectedIconColour,
      locale = _ref.locale,
      titleFont = _ref.titleFont,
      featureFlags = _ref.featureFlags,
      globalStyling = _ref.globalStyling,
      fullTileSelect = _ref.fullTileSelect;

  return _react2.default.createElement(
    FilterTileGrid,
    null,
    cars.map(function (car) {
      return _react2.default.createElement(_FilterByTile2.default, {
        key: '' + car.id + car.model,
        align: 'left',
        onIconClick: function onIconClick() {
          return itemClicked(car.modelDisplay);
        },
        car: car,
        isSelected: selectedModels.includes(car.modelDisplay),
        translations: translations,
        selectedIconColour: selectedIconColour,
        locale: locale,
        titleFont: titleFont,
        featureFlags: featureFlags,
        globalStyling: globalStyling,
        fullTileSelect: fullTileSelect
      });
    }),
    cars.length % 2 !== 0 && _react2.default.createElement(EmptyTile, null)
  );
}