'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    text-align: center;\n    padding: 0 5%;\n    height: 150px;\n  '], ['\n    text-align: center;\n    padding: 0 5%;\n    height: 150px;\n  ']);

exports.default = MyAccountHeading;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountHeading__Container',
  componentId: 'sc-13jziir-0'
})(['width:auto;height:200px;padding:0 130px;display:flex;flex-direction:column;justify-content:center;', ';'], _theme2.default.max.large(_templateObject));

function MyAccountHeading(_ref) {
  var heading = _ref.heading,
      subHeading = _ref.subHeading;

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        styleOverride: function styleOverride() {
          return '\n          font-size: 26px;\n          text-transform: uppercase;\n        ';
        }
      },
      heading
    ),
    subHeading && _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n            font-size: 18px;\n            margin-top: 20px;\n          ';
        }
      },
      subHeading
    )
  );
}
MyAccountHeading.defaultProps = {
  subHeading: ''
};