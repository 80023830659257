'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LabelledCheckBox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _CheckboxUnchecked = require('./JaguarIcons/Global/CheckboxUnchecked');

var _CheckboxUnchecked2 = _interopRequireDefault(_CheckboxUnchecked);

var _Tick = require('./JaguarIcons/Global/Tick');

var _Tick2 = _interopRequireDefault(_Tick);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'LabelledCheckBox__Container',
  componentId: 'sc-1w7zhj4-0'
})(['display:flex;align-items:center;cursor:pointer;text-align:left;']);

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'LabelledCheckBox__Label',
  componentId: 'sc-1w7zhj4-1'
})(['font-size:', ';color:', ';padding:', ';'], function (_ref) {
  var labelFontSize = _ref.labelFontSize;
  return labelFontSize ? labelFontSize + 'px' : '16px';
}, function (_ref2) {
  var colour = _ref2.colour;
  return colour || '#444444';
}, function (_ref3) {
  var direction = _ref3.direction;
  return direction === 'rtl' ? '10px 14px 0px 20px' : '10px 20px 10px 14px';
});

var Body = _styledComponents2.default.div.withConfig({
  displayName: 'LabelledCheckBox__Body',
  componentId: 'sc-1w7zhj4-2'
})(['font-size:14px;color:#7e7e7e;padding:0px 20px 0px 14px;']);

var Checkbox = function Checkbox(_ref4) {
  var checked = _ref4.checked,
      onColour = _ref4.onColour,
      checkboxDimension = _ref4.checkboxDimension;
  return checked ? _react2.default.createElement(_Tick2.default, {
    background: onColour,
    colour: '#fff',
    fill: '#FFF',
    width: (checkboxDimension || 1.5) + 'em',
    height: (checkboxDimension || 1.5) + 'em'
  }) : _react2.default.createElement(_CheckboxUnchecked2.default, {
    width: (checkboxDimension || 1.5) + 'em',
    height: (checkboxDimension || 1.5) + 'em'
  });
};

Checkbox.defaultProps = {
  offColour: '#000000',
  onColour: '#000000',
  error: ''
};

function LabelledCheckBox(_ref5) {
  var onClick = _ref5.onClick,
      checked = _ref5.checked,
      label = _ref5.label,
      description = _ref5.description,
      offColour = _ref5.offColour,
      onColour = _ref5.onColour,
      labelFontSize = _ref5.labelFontSize,
      checkboxDimension = _ref5.checkboxDimension,
      globalStyling = _ref5.globalStyling,
      colour = _ref5.colour,
      required = _ref5.required,
      error = _ref5.error;

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      Container,
      {
        onClick: onClick,
        role: 'checkbox',
        'aria-checked': checked,
        tabIndex: '0',
        onKeyDown: function onKeyDown(e) {
          // Spacebar key = 32
          if (e.keyCode === 32) {
            onClick();
          }
          // Tab key = 9
          if (e.keyCode !== 9) {
            e.preventDefault();
          }
        }
      },
      _react2.default.createElement(Checkbox, {
        checked: checked,
        offColour: offColour,
        onColour: onColour,
        checkboxDimension: checkboxDimension
      }),
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          Label,
          {
            role: 'presentation',
            labelFontSize: labelFontSize,
            colour: colour,
            direction: globalStyling.direction,
            error: error
          },
          label,
          required && '*'
        ),
        description && _react2.default.createElement(
          Body,
          null,
          description
        )
      )
    ),
    error && _react2.default.createElement(
      'div',
      {
        style: {
          color: '#9e1b32'
        }
      },
      error
    )
  );
}
LabelledCheckBox.defaultProps = {
  offColour: '#000000',
  onColour: '#000000',
  description: ''
};