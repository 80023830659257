'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    background: #FFFFFF\n  '], ['\n    background: #FFFFFF\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    gap: 4px;\n    align-items: flex-start;\n    flex-wrap: wrap;\n  '], ['\n    gap: 4px;\n    align-items: flex-start;\n    flex-wrap: wrap;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: initial;\n  '], ['\n    justify-content: initial;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    line-height: 44px;\n  '], ['\n    line-height: 44px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n      padding: 0 40px;\n  '], ['\n      padding: 0 40px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    order: 2;\n  '], ['\n    order: 2;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    height: 50px;\n    margin: 18px;\n    align-self: center;\n  '], ['\n    height: 50px;\n    margin: 18px;\n    align-self: center;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    flex-wrap: wrap;\n    gap: 10px;\n  '], ['\n    flex-wrap: wrap;\n    gap: 10px;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    align-items: flex-start;\n  '], ['\n    flex-direction: column;\n    align-items: flex-start;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    min-width: auto;\n    width: 100%;\n    margin: 0;\n  '], ['\n    min-width: auto;\n    width: 100%;\n    margin: 0;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 1fr\n    grid-gap: 10px;\n    height: auto;\n  '], ['\n    grid-template-columns: 1fr\n    grid-gap: 10px;\n    height: auto;\n  ']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n    flex: 1;\n    align-self: stretch;\n  '], ['\n    flex: 1;\n    align-self: stretch;\n  ']),
    _templateObject13 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']),
    _templateObject14 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 35px 20px;\n  '], ['\n    padding: 35px 20px;\n  ']),
    _templateObject15 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 200px;\n  '], ['\n    padding: 0 200px;\n  ']),
    _templateObject16 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 0 60px;\n  '], ['\n    margin: 0 0 60px;\n  ']),
    _templateObject17 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 0 30px;\n  '], ['\n    margin: 0 0 30px;\n  ']),
    _templateObject18 = (0, _taggedTemplateLiteral3.default)(['\n    text-align: center;\n    ', '\n  '], ['\n    text-align: center;\n    ', '\n  ']),
    _templateObject19 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 1fr 1fr;\n  '], ['\n    grid-template-columns: 1fr 1fr;\n  ']),
    _templateObject20 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 1fr;\n    width: 100%;\n    margin: 0;\n  '], ['\n    grid-template-columns: 1fr;\n    width: 100%;\n    margin: 0;\n  ']),
    _templateObject21 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 1fr 1fr  1fr;\n  '], ['\n    grid-template-columns: 1fr 1fr  1fr;\n  ']),
    _templateObject22 = (0, _taggedTemplateLiteral3.default)([' padding: 0 10px;'], [' padding: 0 10px;']),
    _templateObject23 = (0, _taggedTemplateLiteral3.default)(['\n    background:#FFF;\n  '], ['\n    background:#FFF;\n  ']),
    _templateObject24 = (0, _taggedTemplateLiteral3.default)(['\n    column-count: 2;\n  '], ['\n    column-count: 2;\n  ']),
    _templateObject25 = (0, _taggedTemplateLiteral3.default)(['\n    column-count: 1;\n  '], ['\n    column-count: 1;\n  ']),
    _templateObject26 = (0, _taggedTemplateLiteral3.default)(['\n    text-align: center;\n  '], ['\n    text-align: center;\n  ']),
    _templateObject27 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    row-gap: 20px;\n  '], ['\n    flex-direction: column;\n    row-gap: 20px;\n  ']),
    _templateObject28 = (0, _taggedTemplateLiteral3.default)(['\n    flex: 1;\n  '], ['\n    flex: 1;\n  ']),
    _templateObject29 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n  '], ['\n    width: 50%;\n  ']),
    _templateObject30 = (0, _taggedTemplateLiteral3.default)(['\n    text-align: right;\n    margin-right: 120px;\n  '], ['\n    text-align: right;\n    margin-right: 120px;\n  ']),
    _templateObject31 = (0, _taggedTemplateLiteral3.default)(['\n     padding: 20px 20px 0;\n     width: initial;\n  '], ['\n     padding: 20px 20px 0;\n     width: initial;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactAnchorLinkSmoothScrollV = require('react-anchor-link-smooth-scroll-v2');

var _reactAnchorLinkSmoothScrollV2 = _interopRequireDefault(_reactAnchorLinkSmoothScrollV);

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _vehicle = require('../../helpers/vehicle');

var _useCheckIsMobileScreen = require('../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _dates = require('../../shared/localisation/dates');

var _ipInformation = require('../../shared/localisation/ipInformation');

var _numbers = require('../../shared/localisation/numbers');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicleDetailsPageTypes = require('../../types/Bentley/vehicleDetailsPageTypes');

var _vehicleTypes = require('../../types/Bentley/vehicleTypes');

var _EmissionType = require('../../types/EmissionType');

var _vehicleTypes2 = require('../../types/vehicleTypes');

var _mathHelper = require('../../utilities/mathHelper');

var _Global = require('../Global');

var _Spinner = require('../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _CarfaxLogo = require('./CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

var _CompareButton = require('./CompareButton');

var _CompareButton2 = _interopRequireDefault(_CompareButton);

var _Button = require('./Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Promotion = require('./Promotion');

var _Promotion2 = _interopRequireDefault(_Promotion);

var _ShortlistButton = require('./ShortlistButton');

var _ShortlistButton2 = _interopRequireDefault(_ShortlistButton);

var _VehicleInfoBanner = require('./VehicleInfoBanner');

var _VehicleInfoBanner2 = _interopRequireDefault(_VehicleInfoBanner);

var _WhyCertified = require('./WhyCertified');

var _WhyCertified2 = _interopRequireDefault(_WhyCertified);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__Container',
  componentId: 'sc-10ttj47-0'
})(['display:flex;flex-direction:column;align-items:center;', ''], _theme2.default.max.small(_templateObject));

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__HeaderContainer',
  componentId: 'sc-10ttj47-1'
})(['display:flex;flex-direction:row;justify-content:space-between;width:100%;align-items:flex-end;', ''], _theme2.default.max.large(_templateObject2));

var PriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__PriceContainer',
  componentId: 'sc-10ttj47-2'
})(['display:flex;flex-direction:column;justify-content:center;', ';'], _theme2.default.max.large(_templateObject3));

var PricingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__PricingWrapper',
  componentId: 'sc-10ttj47-3'
})(['display:flex;flex-direction:row;justify-content:center;align-items:center;']);

var PrimaryPrice = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__PrimaryPrice',
  componentId: 'sc-10ttj47-4'
})(['font-family:Bentley-Regular;display:flex;flex-direction:column;align-items:flex-end;font-style:normal;font-weight:400;font-size:24px;line-height:24px;color:#222;', ';'], _theme2.default.min.large(_templateObject4));

var MainInfoBar = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__MainInfoBar',
  componentId: 'sc-10ttj47-5'
})(['display:flex;justify-content:space-between;align-items:center;padding:0 0 15px;flex-direction:column;gap:20px;', ''], _theme2.default.min.medium(_templateObject5));

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__Column',
  componentId: 'sc-10ttj47-6'
})(['display:flex;flex-direction:column;width:', ';', ')}'], function (_ref) {
  var width = _ref.width;
  return width || '33.33%';
}, function (_ref2) {
  var maxWidth = _ref2.maxWidth;
  return maxWidth && 'max-width: ' + maxWidth + ';';
});

var ApprovedLogoWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__ApprovedLogoWrapper',
  componentId: 'sc-10ttj47-7'
})(['margin:0 auto;']);

var ApprovedLogo = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleDetails__ApprovedLogo',
  componentId: 'sc-10ttj47-8'
})(['height:100%;', ';', ';'], _theme2.default.max.large(_templateObject6), _theme2.default.max.small(_templateObject7));

var ActionRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__ActionRow',
  componentId: 'sc-10ttj47-9'
})(['display:flex;flex-direction:row;justify-content:space-between;gap:25px;align-items:center;width:100%;box-sizing:border-box;order:1;', ';', ';'], _theme2.default.max.large(_templateObject8), _theme2.default.max.small(_templateObject9));

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__ButtonContainer',
  componentId: 'sc-10ttj47-10'
})(['display:grid;grid-template-columns:1fr 1fr;width:55%;min-width:450px;flex:auto;grid-gap:15px;', ';', ';'], _theme2.default.max.large(_templateObject10), _theme2.default.max.medium(_templateObject11));

var DealerAddress = _styledComponents2.default.span.withConfig({
  displayName: 'VehicleDetails__DealerAddress',
  componentId: 'sc-10ttj47-11'
})(['font-family:Bentley-Light;font-style:normal;font-weight:300;font-size:14px;line-height:22px;color:#222;']);

var DealerNameAndNumber = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__DealerNameAndNumber',
  componentId: 'sc-10ttj47-12'
})(['font-style:normal;font-weight:300;font-size:18px;line-height:25px;color:#222;']);

var PhoneNum = _styledComponents2.default.a.withConfig({
  displayName: 'VehicleDetails__PhoneNum',
  componentId: 'sc-10ttj47-13'
})(['font-family:Bentley-Light;color:#222;padding-left:5px;font-style:normal;font-weight:300;font-size:14px;line-height:22px;text-decoration-line:underline;']);

var AvailableLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__AvailableLabel',
  componentId: 'sc-10ttj47-14'
})(['font-weight:600;font-size:14px;line-height:22px;margin-bottom:5px;color:#222;text-decoration:none;']);

var DealerCard = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__DealerCard',
  componentId: 'sc-10ttj47-15'
})(['font-family:Bentley-Regular;padding:0 10px;background:#ffffff;flex:auto;align-self:stretch;height:auto;order:2;border:2px solid #335b4c;display:flex;flex-direction:column;justify-content:space-around;', ';'], _theme2.default.max.large(_templateObject12));

var SectionSpacer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SectionSpacer',
  componentId: 'sc-10ttj47-16'
})(['', ' ', ';'], function (_ref3) {
  var spacerSizeSmall = _ref3.spacerSizeSmall;
  return spacerSizeSmall && 'height: ' + spacerSizeSmall + 'px;';
}, _theme2.default.min.large(_templateObject13, function (_ref4) {
  var spacerSizeLarge = _ref4.spacerSizeLarge;
  return spacerSizeLarge && 'height: ' + spacerSizeLarge + 'px;';
}));

var SectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SectionWrapper',
  componentId: 'sc-10ttj47-17'
})(['font-family:Bentley-Regular;color:#222;display:flex;flex-direction:column;padding:30px 20px;', ''], function (_ref5) {
  var paddingTop = _ref5.paddingTop;
  return paddingTop && 'padding-top: ' + paddingTop + 'px;';
});

var SummarySectionWrapper = (0, _styledComponents2.default)(SectionWrapper).withConfig({
  displayName: 'VehicleDetails__SummarySectionWrapper',
  componentId: 'sc-10ttj47-18'
})(['padding:20px;', ''], _theme2.default.min.large(_templateObject14));

var SectionTitle = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SectionTitle',
  componentId: 'sc-10ttj47-19'
})(['font-size:24px;text-align:center;text-transform:uppercase;padding-bottom:40px;']);

var IconRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__IconRow',
  componentId: 'sc-10ttj47-20'
})(['display:flex;flex-direction:row;justify-content:center;align-items:center;flex:1;gap:8px;justify-content:space-around;margin:0 0 48px;', ' ', ' ', ''], _theme2.default.min.large(_templateObject15), _theme2.default.max.large(_templateObject16), _theme2.default.max.small(_templateObject17));

var IconColumn = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__IconColumn',
  componentId: 'sc-10ttj47-21'
})(['display:flex;flex-direction:column;justify-content:space-between;align-items:center;box-sizing:border-box;']);

var SummaryIcon = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleDetails__SummaryIcon',
  componentId: 'sc-10ttj47-22'
})(['height:50px;width:50px;']);

var SummaryLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SummaryLabel',
  componentId: 'sc-10ttj47-23'
})(['font-family:Bentley-Light;font-style:normal;font-weight:300;font-size:16px;line-height:22px;color:#222;padding-top:20px;']);

var SummaryValue = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SummaryValue',
  componentId: 'sc-10ttj47-24'
})(['font-family:Bentley-Regular;font-weight:400;font-size:16px;line-height:22px;color:#222;padding:5px 0;']);

var InfoTableItem = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__InfoTableItem',
  componentId: 'sc-10ttj47-25'
})(['display:flex;flex-direction:row;justify-content:space-between;align-items:center;border-bottom:1px solid #acacac;width:100%;', ''], _theme2.default.max.small(_templateObject18, function (_ref6) {
  var center = _ref6.center;
  return center && 'justify-content: center;';
}));

var InfoTableLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__InfoTableLabel',
  componentId: 'sc-10ttj47-26'
})(['font-family:Bentley-SemiBold;font-weight:300;font-size:14px;line-height:19px;letter-spacing:1px;text-transform:uppercase;color:#222;margin:10px 0;']);

var InfoTableValue = (0, _styledComponents2.default)(InfoTableLabel).withConfig({
  displayName: 'VehicleDetails__InfoTableValue',
  componentId: 'sc-10ttj47-27'
})(['font-family:Bentley-Light;text-transform:uppercase;text-align:right;letter-spacing:normal;']);

var FeatureTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__FeatureTable',
  componentId: 'sc-10ttj47-28'
})(['display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:20px 30px;', ' ', ';'], _theme2.default.max.medium(_templateObject19), _theme2.default.max.small(_templateObject20));

var PerformanceTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__PerformanceTable',
  componentId: 'sc-10ttj47-29'
})(['display:grid;grid-template-columns:auto auto auto auto;', ' ', ' ', ';'], _theme2.default.max.extraLarge(_templateObject21), _theme2.default.max.large(_templateObject19), _theme2.default.max.small(_templateObject20));

var HighlightedInfo = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__HighlightedInfo',
  componentId: 'sc-10ttj47-30'
})(['font-family:Bentley-SemiBold;margin:30px 5px 0;display:flex;flex-direction:row;']);

var HighlightedSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__HighlightedSection',
  componentId: 'sc-10ttj47-31'
})(['font-family:Bentley-SemiBold;color:#335b4c;border-left:4px solid #94c11d;padding:0 10px;']);

var HighlightedLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__HighlightedLabel',
  componentId: 'sc-10ttj47-32'
})(['font-family:Bentley-Regular;font-weight:400;font-size:18px;text-transform:uppercase;white-space:nowrap;']);

var HighlightedValue = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__HighlightedValue',
  componentId: 'sc-10ttj47-33'
})(['font-family:Bentley-Regular;font-weight:400;font-size:38px;line-height:38px;flex:1;']);

var SubSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SubSection',
  componentId: 'sc-10ttj47-34'
})(['gap:20px;']);

var SubSectionLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SubSectionLabel',
  componentId: 'sc-10ttj47-35'
})(['font-family:Bentley-Regular;color:#222;font-weight:400;font-size:12px;text-transform:uppercase;&:first-child{padding-right:5px;}']);

var SecondValueContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SecondValueContainer',
  componentId: 'sc-10ttj47-36'
})(['display:flex;flex-direction:row;white-space:nowrap;']);

var MarkdownWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__MarkdownWrapper',
  componentId: 'sc-10ttj47-37'
})(['display:flex;flex-direction:row;text-align:center;font-family:Bentley-Light;font-size:14px;letter-spacing:0px;line-height:18px;padding:0 20px;flex:1;', ''], _theme2.default.max.medium(_templateObject22));

var SectionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SectionContainer',
  componentId: 'sc-10ttj47-38'
})(['width:100%;display:flex;justify-content:center;', ' ', ''], function (_ref7) {
  var bgColor = _ref7.bgColor;
  return bgColor && ' background:' + bgColor + ';';
}, _theme2.default.max.small(_templateObject23));

var SecondaryPrice = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SecondaryPrice',
  componentId: 'sc-10ttj47-39'
})(['font-family:Bentley-Regular;color:\'#222222\';font-weight:400;font-size:12px;line-height:16px;display:flex;align-items:center;justify-content:flex-end;']);

var SubPriceLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SubPriceLabel',
  componentId: 'sc-10ttj47-40'
})(['font-family:Bentley-light;font-size:9px;font-weight:300;line-height:12px;padding-left:3px;']);

var InfoTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__InfoTable',
  componentId: 'sc-10ttj47-41'
})(['display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:20px 30px;', ';'], _theme2.default.max.medium(_templateObject20));

var EquipmentTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__EquipmentTable',
  componentId: 'sc-10ttj47-42'
})(['column-count:3;column-gap:30px;', ';', ';'], _theme2.default.max.medium(_templateObject24), _theme2.default.max.small(_templateObject25));

var EquipmentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__EquipmentContainer',
  componentId: 'sc-10ttj47-43'
})(['margin-bottom:18px;break-inside:avoid;', ';'], _theme2.default.max.small(_templateObject26));

var EquipmentTitle = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__EquipmentTitle',
  componentId: 'sc-10ttj47-44'
})(['font-family:Bentley-SemiBold;font-size:14px;line-height:22px;border-bottom:1px solid #acacac;text-transform:uppercase;padding-bottom:8px;,']);

var Equipment = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__Equipment',
  componentId: 'sc-10ttj47-45'
})(['font-family:Bentley-Light;font-size:14px;line-height:22px;margin:9px 0;']);

var EmissionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__EmissionContainer',
  componentId: 'sc-10ttj47-46'
})(['margin-bottom:18px;break-inside:avoid;', ';'], _theme2.default.max.small(_templateObject26));

var EmissionsTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__EmissionsTable',
  componentId: 'sc-10ttj47-47'
})(['display:grid;grid-template-columns:1fr 1fr;grid-gap:10px 60px;', ';'], _theme2.default.max.large(_templateObject20));

var EmissionTitle = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__EmissionTitle',
  componentId: 'sc-10ttj47-48'
})(['font-family:Bentley-SemiBold;font-size:16px;line-height:22px;border-bottom:1px solid #acacac;text-transform:uppercase;padding-bottom:8px;']);

var EnergyEfficiencyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__EnergyEfficiencyContainer',
  componentId: 'sc-10ttj47-49'
})(['display:flex;flex-direction:column;row-gap:10px;']);

var EnergyEfficiencyTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__EnergyEfficiencyTable',
  componentId: 'sc-10ttj47-50'
})(['display:flex;flex-direction:row;column-gap:30px;', ''], _theme2.default.max.medium(_templateObject27));

var EnergyEfficiencyImage = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__EnergyEfficiencyImage',
  componentId: 'sc-10ttj47-51'
})(['flex:0.8;display:flex;align-items:center;', ''], _theme2.default.max.medium(_templateObject28));

var PdfPreviewImg = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleDetails__PdfPreviewImg',
  componentId: 'sc-10ttj47-52'
})(['max-width:378px;cursor:pointer;', ''], _theme2.default.max.medium(_templateObject29));

var PdfDownloadContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__PdfDownloadContainer',
  componentId: 'sc-10ttj47-53'
})(['display:flex;flex-direction:column;justify-content:center;align-items:center;']);

var SectionParagraph = _styledComponents2.default.p.withConfig({
  displayName: 'VehicleDetails__SectionParagraph',
  componentId: 'sc-10ttj47-54'
})(['text-align:center;font-family:Bentley-Light;font-size:14px;margin:0px auto;margin-bottom:20px;max-width:50em;']);

var HighlightedDisclaimersContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__HighlightedDisclaimersContainer',
  componentId: 'sc-10ttj47-55'
})(['font-family:Bentley-Light;font-size:9px;text-transform:uppercase;padding:20px 0 0;margin-left:5px;', ''], _theme2.default.min.large(_templateObject30));

var CarPassLink = _styledComponents2.default.a.withConfig({
  displayName: 'VehicleDetails__CarPassLink',
  componentId: 'sc-10ttj47-56'
})(['cursor:pointer;']);
var CarPassLogo = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleDetails__CarPassLogo',
  componentId: 'sc-10ttj47-57'
})(['width:90px;max-width:128px;padding:0 20px;', ''], _theme2.default.min.medium(_templateObject31));

var Pricing = function Pricing(_ref8) {
  var localisedRetailPrice = _ref8.localisedRetailPrice,
      priceSubjectToVat = _ref8.priceSubjectToVat,
      translations = _ref8.translations,
      carfax = _ref8.carfax,
      hidePrice = _ref8.hidePrice,
      showSalesTaxDisclaimer = _ref8.showSalesTaxDisclaimer,
      carPassUrl = _ref8.carPassUrl,
      carPassLogo = _ref8.carPassLogo;
  return _react2.default.createElement(
    PricingWrapper,
    null,
    carPassUrl && carPassLogo && _react2.default.createElement(
      CarPassLink,
      { href: carPassUrl, target: '_blank' },
      _react2.default.createElement(CarPassLogo, { src: carPassLogo })
    ),
    _react2.default.createElement(_CarfaxLogo2.default, { carfax: carfax }),
    !hidePrice && _react2.default.createElement(
      PriceContainer,
      null,
      localisedRetailPrice && _react2.default.createElement(
        PrimaryPrice,
        null,
        localisedRetailPrice
      ),
      priceSubjectToVat && _react2.default.createElement(
        SecondaryPrice,
        null,
        priceSubjectToVat,
        _react2.default.createElement(
          SubPriceLabel,
          null,
          translations.subjectToVat
        )
      ),
      showSalesTaxDisclaimer && _react2.default.createElement(
        SecondaryPrice,
        null,
        translations.differentialTaxation
      )
    )
  );
};

var HighlightedDisclaimers = function HighlightedDisclaimers(_ref9) {
  var countryCode = _ref9.countryCode,
      translations = _ref9.translations,
      isVisible = _ref9.isVisible;

  var northAmericaAndChinaCountryCodes = ['US', 'CA', 'DO', 'CN'];
  var isNorthAmericaOrChina = northAmericaAndChinaCountryCodes.includes(countryCode);
  var disclaimer1 = isNorthAmericaOrChina ? translations.highlightDisclaimer_1_NA_China : translations.highlightDisclaimer_1_Other_Regions;

  if (isVisible) {
    return _react2.default.createElement(
      HighlightedDisclaimersContainer,
      null,
      _react2.default.createElement(
        'div',
        null,
        disclaimer1
      ),
      _react2.default.createElement(
        'div',
        null,
        translations.highlightDisclaimer_2_All_Regions
      )
    );
  }

  return null;
};

var HighlightedItem = function HighlightedItem(_ref10) {
  var label = _ref10.label,
      unit = _ref10.unit,
      _ref10$value = _ref10.value,
      value = _ref10$value === undefined ? '-' : _ref10$value,
      secondValue = _ref10.secondValue,
      showAsterisk = _ref10.showAsterisk;

  return _react2.default.createElement(
    HighlightedInfo,
    null,
    _react2.default.createElement(
      HighlightedSection,
      null,
      _react2.default.createElement(
        HighlightedLabel,
        null,
        label
      ),
      _react2.default.createElement(
        SubSection,
        null,
        _react2.default.createElement(
          HighlightedValue,
          null,
          value,
          ' ',
          showAsterisk && '*'
        ),
        _react2.default.createElement(
          SecondValueContainer,
          null,
          _react2.default.createElement(
            SubSectionLabel,
            null,
            unit
          ),
          _react2.default.createElement(
            SubSectionLabel,
            null,
            secondValue
          )
        )
      )
    )
  );
};

exports.default = function (_ref11) {
  var config = _ref11.config,
      locale = _ref11.locale,
      makeAnEnquiry = _ref11.makeAnEnquiry,
      placeholdingImage = _ref11.placeholdingImage,
      printingState = _ref11.printingState,
      printVehiclePdf = _ref11.printVehiclePdf,
      translations = _ref11.translations,
      vehicle = _ref11.vehicle,
      showPromotion = _ref11.showPromotion;

  var countryCode = (0, _ipInformation.getIpCountryCode)();
  var localisedRetailPrice = !!vehicle.price.value && (0, _numbers.localiseCurrency)(vehicle.price.originalValue || vehicle.price.value, locale, vehicle.price.originalCurrency || vehicle.price.currency, 0);
  var priceSubjectToVat = !!vehicle.priceLessTaxes && (0, _numbers.localiseCurrency)(vehicle.priceLessTaxes, locale, vehicle.price.currency, 0);

  var dashIfNull = function dashIfNull(value) {
    return value || '-';
  };

  var isHighlightDisclaimerVisible = function isHighlightDisclaimerVisible() {
    var modelNames = ['Continental GT (2018-)', 'Continental GTC (2019-)', 'Flying Spur (2020-)', 'Continental GT', 'Continental GTC', 'Flying Spur'];
    var modelCheck = modelNames.includes(vehicle.modelGroupName);
    var cylinders = vehicle.specification.engineDetails.cylinders;
    var modelYear = vehicle.modelYear;

    var isW12 = cylinders === 'W12';

    return modelCheck && !isW12 && modelYear >= 2023;
  };

  var isNotDesktop = (0, _useCheckIsMobileScreen2.default)('large');
  var mobile = (0, _useCheckIsMobileScreen2.default)('medium');

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      showStickyInfoBanner = _useState2[0],
      setShowStickyInfoBanner = _useState2[1];

  var colourAndFinishOrder = ['main paint ', 'hood ', 'main hide ', 'secondary hide ', 'interior colour split ', 'veneer ', 'embroidered emblems ', 'contrast stitching ', 'headlining '];

  var _useMemo = (0, _react.useMemo)(function () {
    var registrationDate = {
      label: 'registrationDate',
      value: vehicle.registrationDate ? (0, _dates.localeFormattedDate)(vehicle.registrationDate, locale, {
        month: '2-digit',
        year: 'numeric'
      }) : undefined
    };
    var transmission = {
      label: 'transmission',
      value: vehicle.specification.transmission
    };
    var bodystyle = {
      label: 'bodystyle',
      value: vehicle.specification.bodystyle
    };
    var modelYear = {
      label: 'modelYear',
      value: vehicle.modelYear
    };

    var engineType = {
      label: 'engineType',
      value: (vehicle.specification.engineDetails.cylinders ? vehicle.specification.engineDetails.cylinders.replace('Hybrid', '') : '') + ' ' + vehicle.specification.fuelType
    };
    var vin = {
      label: 'vin',
      value: vehicle.vin
    };

    var summaryData = vehicle.retailerInformation.region !== 'Americas' && !config.customVehicleTileFields ? [registrationDate, transmission, bodystyle, modelYear, engineType, vin] : [transmission, bodystyle, modelYear, engineType, vin];

    var techSpecs = vehicle.techspecs ? [vehicle.techspecs.accelerationZeroToHundredKPH, vehicle.techspecs.maxSpeedMPH, vehicle.techspecs.torqueNM, vehicle.techspecs.powerBHP] : [];

    if (!vehicle.otherEquipment) {
      return [[], [], summaryData.filter(function (item) {
        return !!item.value;
      }), techSpecs];
    }

    var splitCategories = ['exterior_colours', 'interior_colours', 'veneer'];

    var exteriorColours = vehicle.otherEquipment.find(function (x) {
      return x.category === splitCategories[0];
    });
    var interiorColours = vehicle.otherEquipment.find(function (x) {
      return x.category === splitCategories[1];
    });
    var veneer = vehicle.otherEquipment.find(function (x) {
      return x.category === splitCategories[2];
    });

    var coloursAndFinishesItems = [].concat((0, _toConsumableArray3.default)(exteriorColours ? exteriorColours.equipment : []), (0, _toConsumableArray3.default)(interiorColours ? interiorColours.equipment : []), (0, _toConsumableArray3.default)(veneer ? veneer.equipment : [])).map(function (item) {
      var _item$split = item.split(/\s+-\s+/),
          _item$split2 = (0, _slicedToArray3.default)(_item$split, 2),
          label = _item$split2[0],
          value = _item$split2[1];

      return { label: label, value: value };
    }).filter(function (item) {
      return !!item.value;
    });

    var others = vehicle.otherEquipment.filter(function (other) {
      return !splitCategories.includes(other.category);
    });

    return [coloursAndFinishesItems, others, summaryData.filter(function (item) {
      return !!item.value;
    }), techSpecs];
  }, [vehicle]),
      _useMemo2 = (0, _slicedToArray3.default)(_useMemo, 4),
      coloursAndFinishesItems = _useMemo2[0],
      otherEquipment = _useMemo2[1],
      summaryInfo = _useMemo2[2],
      techSpecsValues = _useMemo2[3];

  var orderedColoursAndFinishes = coloursAndFinishesItems.sort(function (a, b) {
    return colourAndFinishOrder.indexOf(a.label.toLowerCase()) - colourAndFinishOrder.indexOf(b.label.toLowerCase());
  });

  var emissionsNull = function emissionsNull(emissions) {
    return (0, _keys2.default)(emissions).filter(function (key) {
      return !key.includes('nedc');
    }).every(function (key) {
      return emissions[key] === null;
    });
  };

  var emissionsMedia = countryCode && countryCode === 'CH' ? config.energyEfficiencyRatingMediaSwiss : config.energyEfficiencyRatingMedia;

  var emissionsData = (0, _react.useMemo)(function () {
    var wltpConsumptions = {
      category: 'wltpConsumptions',
      items: [vehicle.consumption.wltpConsumptionLow ? {
        label: 'wltpConsumptionLow',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionLow)
      } : null, vehicle.consumption.wltpConsumptionMid ? {
        label: 'wltpConsumptionMid',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionMid)
      } : null, vehicle.consumption.wltpConsumptionHigh ? {
        label: 'wltpConsumptionHigh',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionHigh)
      } : null, vehicle.consumption.wltpConsumptionExtraHigh ? {
        label: 'wltpConsumptionExtraHigh',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionExtraHigh)
      } : null, vehicle.consumption.wltpConsumptionCombined ? {
        label: 'wltpConsumptionCombined',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionCombined)
      } : null].filter(function (item) {
        return item !== null;
      })
    };

    var wltpEmissions = {
      category: 'wltpEmissions',
      items: [vehicle.emissions.wltpEmissionsCombined ? {
        label: 'wltpEmissionsCombined',
        value: vehicle.emissions.wltpEmissionsCombined + ' g/km'
      } : null, vehicle.emissions.co2ClassWeightedCombined ? {
        label: 'weightedCombined',
        value: '' + vehicle.emissions.co2ClassWeightedCombined
      } : null, vehicle.emissions.co2ClassDischaredBattery ? {
        label: 'classWithDischargedBattery',
        value: '' + vehicle.emissions.co2ClassDischaredBattery
      } : null, vehicle.energyEfficiencyRating ? {
        label: 'energyEfficiencyClass',
        value: '' + vehicle.energyEfficiencyRating
      } : null].filter(function (item) {
        return item !== null;
      })
    };

    return [wltpConsumptions, wltpEmissions].filter(function (category) {
      return category.items.some(function (item) {
        return item && item.value !== null;
      });
    });
  }, [vehicle]);

  var showGermanNvlEmissions = config.isNVL && vehicle.modelYear >= 2024 && vehicle.retailerInformation.countryCode === 'DE' && vehicle.modelData && vehicle.modelData.germanEmissionPdf;

  var _vehicle$retailerInfo = vehicle.retailerInformation,
      _vehicle$retailerInfo2 = _vehicle$retailerInfo.name,
      dealerName = _vehicle$retailerInfo2 === undefined ? '' : _vehicle$retailerInfo2,
      _vehicle$retailerInfo3 = _vehicle$retailerInfo.phone,
      dealerTelephone = _vehicle$retailerInfo3 === undefined ? '' : _vehicle$retailerInfo3;


  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Column,
      { width: '100%', maxWidth: '1200px' },
      _react2.default.createElement(
        SummarySectionWrapper,
        null,
        _react2.default.createElement(
          MainInfoBar,
          null,
          _react2.default.createElement(
            HeaderContainer,
            null,
            _react2.default.createElement(
              Column,
              { style: { flex: 1 } },
              !config.hideYear && _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n                    font-family: Bentley-Regular;\n                    font-style: normal;\n                    font-weight: 400;\n                    font-size: 18px;\n                    line-height: 25px;\n                    color: #94C11D;\n                  ';
                  }
                },
                dashIfNull(vehicle.modelYear)
              ),
              !isNotDesktop && _react2.default.createElement(
                _Global.HeadingOne,
                {
                  styleOverride: function styleOverride() {
                    return '\n                    font-family: Bentley-Regular;\n                    font-style: normal;\n                    font-weight: 400;\n                    font-size: 24px;\n                    line-height: 44px;\n                    margin: 0;\n                    text-transform: uppercase;\n                  ';
                  }
                },
                vehicle.description || vehicle.name
              )
            ),
            _react2.default.createElement(Pricing, {
              localisedRetailPrice: localisedRetailPrice || null,
              priceSubjectToVat: priceSubjectToVat || null,
              translations: translations,
              carfax: vehicle.carfax,
              hidePrice: config.hidePrice,
              showSalesTaxDisclaimer: vehicle.sectionSalesTaxDisclaimer === 1,
              carPassUrl: vehicle.carpassUrl,
              carPassLogo: config.carPassLogo
            }),
            isNotDesktop && _react2.default.createElement(
              'div',
              { style: { width: '100%' } },
              _react2.default.createElement(
                _Global.HeadingOne,
                {
                  styleOverride: function styleOverride() {
                    return '\n                      font-family: Bentley-Regular;\n                      font-style: normal;\n                      font-weight: 400;\n                      font-size: 24px;\n                      line-height: 33px;\n                      margin: 0;\n                      text-transform: uppercase;\n                    ';
                  }
                },
                vehicle.description || vehicle.name
              )
            )
          ),
          _react2.default.createElement(
            ActionRow,
            null,
            !mobile && vehicle.approved && _react2.default.createElement(
              _reactAnchorLinkSmoothScrollV2.default,
              { href: '#WhyCertified', offset: 150 },
              _react2.default.createElement(ApprovedLogo, {
                src: config.approvedLogo,
                alt: 'Why Certified by Bentley'
              })
            ),
            _react2.default.createElement(
              ButtonContainer,
              null,
              _react2.default.createElement(
                _Button2.default,
                {
                  variant: 'primary',
                  onClick: function onClick(e) {
                    return makeAnEnquiry(e, vehicle);
                  }
                },
                translations.contact
              ),
              _react2.default.createElement(
                _Button2.default,
                {
                  variant: 'tertiary',
                  onClick: function onClick(e) {
                    return printVehiclePdf(e, vehicle);
                  },
                  disabled: printingState.isPrinting
                },
                printingState.isPrinting ? _react2.default.createElement(_Spinner2.default, { colour: { value: '#94c11d' } }) : _react2.default.createElement(
                  'span',
                  null,
                  translations.print
                )
              ),
              !mobile && _react2.default.createElement(
                _CompareButton2.default,
                {
                  vehicle: vehicle,
                  iconOff: config.iconCompareOff,
                  iconOn: config.iconCompareOn,
                  variant: 'iconButtonText'
                },
                'Compare'
              ),
              _react2.default.createElement(
                _ShortlistButton2.default,
                {
                  vehicle: vehicle,
                  iconOff: config.iconShortlistOff,
                  iconOn: config.iconShortlistOn,
                  variant: 'iconButtonText'
                },
                'Shortlist'
              ),
              mobile && vehicle.approved && _react2.default.createElement(
                ApprovedLogoWrapper,
                null,
                _react2.default.createElement(
                  _reactAnchorLinkSmoothScrollV2.default,
                  { href: '#WhyCertified' },
                  _react2.default.createElement(ApprovedLogo, {
                    src: config.approvedLogo,
                    alt: 'Why Certified by Bentley'
                  })
                )
              )
            ),
            _react2.default.createElement(
              DealerCard,
              null,
              _react2.default.createElement(
                AvailableLabel,
                null,
                translations.availableAt
              ),
              _react2.default.createElement(
                'div',
                null,
                _react2.default.createElement(
                  DealerNameAndNumber,
                  null,
                  dealerName,
                  _react2.default.createElement(
                    PhoneNum,
                    { href: 'tel:' + dealerTelephone },
                    dealerTelephone
                  )
                ),
                _react2.default.createElement(
                  'div',
                  null,
                  _react2.default.createElement(
                    DealerAddress,
                    null,
                    vehicle.retailerInformation.address.filter(function (address) {
                      return address && address !== 'NULL';
                    }).join(', ')
                  )
                )
              )
            )
          ),
          _react2.default.createElement(_reactWaypoint2.default, {
            onPositionChange: function onPositionChange(_ref12) {
              var currentPosition = _ref12.currentPosition;

              setShowStickyInfoBanner(currentPosition === _reactWaypoint2.default.above);
            }
          }),
          showStickyInfoBanner && _react2.default.createElement(_VehicleInfoBanner2.default, {
            vehicleDetails: vehicle,
            config: config,
            placeholdingImage: placeholdingImage,
            makeAnEnquiry: makeAnEnquiry
          })
        ),
        orderedColoursAndFinishes && orderedColoursAndFinishes.length > 0 ? _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(SectionSpacer, {
            role: 'separator',
            spacerSizeSmall: '20',
            spacerSizeLarge: '60'
          }),
          _react2.default.createElement(
            SectionTitle,
            null,
            translations.coloursAndFinishes
          ),
          _react2.default.createElement(
            Column,
            { width: '100%' },
            _react2.default.createElement(
              InfoTable,
              null,
              orderedColoursAndFinishes.map(function (item, index) {
                return _react2.default.createElement(
                  InfoTableItem,
                  { key: index },
                  _react2.default.createElement(
                    InfoTableLabel,
                    null,
                    item.label
                  ),
                  _react2.default.createElement(
                    InfoTableValue,
                    {
                      style: {
                        textTransform: 'uppercase'
                      }
                    },
                    dashIfNull(item.value)
                  )
                );
              })
            )
          )
        ) : _react2.default.createElement(_react.Fragment, null),
        _react2.default.createElement(SectionSpacer, {
          role: 'separator',
          spacerSizeSmall: '20',
          spacerSizeLarge: '60'
        }),
        _react2.default.createElement(
          SectionTitle,
          null,
          translations.summaryInfo
        ),
        _react2.default.createElement(
          IconRow,
          null,
          _react2.default.createElement(
            IconColumn,
            null,
            _react2.default.createElement(SummaryIcon, {
              style: { paddingLeft: 11 },
              src: config.fuelTypeIcon
            }),
            _react2.default.createElement(
              SummaryLabel,
              null,
              translations.fuelType
            ),
            _react2.default.createElement(
              SummaryValue,
              null,
              vehicle.specification.fuelType
            )
          ),
          _react2.default.createElement(
            IconColumn,
            null,
            _react2.default.createElement(SummaryIcon, { src: config.capacityIcon }),
            _react2.default.createElement(
              SummaryLabel,
              null,
              translations.capacity
            ),
            _react2.default.createElement(
              SummaryValue,
              null,
              (0, _numbers.localiseNumber)(Number(vehicle.specification.capacity), locale) + ' cc'
            )
          ),
          _react2.default.createElement(
            IconColumn,
            null,
            _react2.default.createElement(SummaryIcon, { src: config.driverIcon }),
            _react2.default.createElement(
              SummaryLabel,
              null,
              translations.driver
            ),
            _react2.default.createElement(
              SummaryValue,
              null,
              dashIfNull(vehicle.handDrive)
            )
          ),
          !config.customVehicleTileFields && _react2.default.createElement(
            IconColumn,
            null,
            _react2.default.createElement(SummaryIcon, { src: config.mileageIcon }),
            _react2.default.createElement(
              SummaryLabel,
              null,
              translations.mileage
            ),
            _react2.default.createElement(
              SummaryValue,
              null,
              dashIfNull((0, _numbers.localiseNumber)(Number(vehicle.specification.odometer.reading), locale)) + ' ' + vehicle.specification.odometer.units
            )
          )
        ),
        _react2.default.createElement(
          Column,
          { width: '100%' },
          _react2.default.createElement(
            InfoTable,
            null,
            summaryInfo.map(function (_ref13, index) {
              var label = _ref13.label,
                  value = _ref13.value;
              return _react2.default.createElement(
                InfoTableItem,
                { key: index },
                _react2.default.createElement(
                  InfoTableLabel,
                  null,
                  translations[label]
                ),
                _react2.default.createElement(
                  InfoTableValue,
                  null,
                  value
                )
              );
            })
          )
        ),
        _react2.default.createElement(SectionSpacer, {
          role: 'separator',
          spacerSizeSmall: '20',
          spacerSizeLarge: '60'
        })
      ),
      vehicle.bespokeFeatures && _react2.default.createElement(
        SectionWrapper,
        null,
        _react2.default.createElement(
          SectionTitle,
          null,
          translations.highlightedFeatures
        ),
        _react2.default.createElement(
          FeatureTable,
          null,
          vehicle.bespokeFeatures.map(function (feature, index) {
            return _react2.default.createElement(
              InfoTableItem,
              { key: index, center: true },
              _react2.default.createElement(
                InfoTableValue,
                null,
                feature.name
              )
            );
          })
        )
      )
    ),
    showPromotion && _react2.default.createElement(_Promotion2.default, { config: config, enquire: function enquire(e) {
        return makeAnEnquiry(e, vehicle);
      } }),
    techSpecsValues.some(function (value) {
      return !!value;
    }) && _react2.default.createElement(
      SectionContainer,
      { bgColor: '#F2F2F2' },
      _react2.default.createElement(
        Column,
        { width: '100%', maxWidth: '1000px' },
        _react2.default.createElement(
          SectionWrapper,
          { paddingTop: '0' },
          _react2.default.createElement(
            PerformanceTable,
            null,
            _react2.default.createElement(HighlightedItem, {
              label: translations.torque,
              unit: translations.nm,
              value: dashIfNull(vehicle.techspecs.torqueNM)
            }),
            _react2.default.createElement(HighlightedItem, {
              label: translations.power,
              unit: config.showPs ? translations.ps : translations.bhp,
              value: config.showPs ? dashIfNull((0, _mathHelper.bhpToPsConverter)(vehicle.techspecs.powerBHP)) : dashIfNull(vehicle.techspecs.powerBHP),
              secondValue: '(' + dashIfNull(vehicle.techspecs.powerKW) + ' ' + translations.kw + ')'
            }),
            _react2.default.createElement(HighlightedItem, {
              label: translations.acceleration,
              unit: translations.sec,
              value: dashIfNull(vehicle.techspecs.accelerationZeroToHundredKPH)
            }),
            _react2.default.createElement(HighlightedItem, {
              label: translations.topSpeed,
              unit: translations.kph,
              value: dashIfNull(vehicle.techspecs.maxSpeedKPH),
              secondValue: '(' + dashIfNull(vehicle.techspecs.maxSpeedMPH) + ' ' + translations.mph + ')',
              showAsterisk: isHighlightDisclaimerVisible()
            })
          ),
          _react2.default.createElement(HighlightedDisclaimers, {
            countryCode: vehicle.retailerInformation.countryCode,
            translations: translations,
            isVisible: isHighlightDisclaimerVisible()
          })
        )
      )
    ),
    otherEquipment && otherEquipment.length > 0 ? _react2.default.createElement(
      SectionContainer,
      null,
      _react2.default.createElement(
        Column,
        { width: '100%', maxWidth: '1200px' },
        _react2.default.createElement(
          SectionWrapper,
          null,
          _react2.default.createElement(
            SectionTitle,
            null,
            translations.features
          ),
          _react2.default.createElement(
            EquipmentTable,
            null,
            otherEquipment.map(function (equipment, idx) {
              return _react2.default.createElement(
                EquipmentContainer,
                { key: idx },
                _react2.default.createElement(
                  EquipmentTitle,
                  null,
                  translations[equipment.category] || equipment.category.replaceAll('_', ' ')
                ),
                equipment.equipment.map(function (e, index) {
                  return _react2.default.createElement(
                    Equipment,
                    { key: index },
                    e
                  );
                })
              );
            })
          )
        )
      )
    ) : _react2.default.createElement(_react.Fragment, null),
    vehicle.approved && _react2.default.createElement(_WhyCertified2.default, {
      id: 'whyCertified',
      config: config,
      translations: translations
    }),
    !emissionsNull(vehicle.emissions) && _react2.default.createElement(
      SectionContainer,
      null,
      _react2.default.createElement(
        Column,
        { width: '100%', maxWidth: '1200px' },
        _react2.default.createElement(
          SectionWrapper,
          null,
          _react2.default.createElement(
            SectionTitle,
            { style: { paddingBottom: '20px' } },
            translations.emissions
          ),
          _react2.default.createElement(
            SectionParagraph,
            null,
            vehicle.modelData && translations[vehicle.modelData.id] || ''
          ),
          showGermanNvlEmissions && _react2.default.createElement(
            PdfDownloadContainer,
            null,
            _react2.default.createElement(PdfPreviewImg, {
              src: vehicle.modelData.pdfPreview,
              onClick: function onClick() {
                return vehicle.modelData && window.open(vehicle.modelData.germanEmissionPdf, '_blank');
              },
              'aria-label': 'download pdf'
            }),
            _react2.default.createElement(
              _Button2.default,
              {
                variant: 'primary',
                onClick: function onClick() {
                  return vehicle.modelData && window.open(vehicle.modelData.germanEmissionPdf, '_blank');
                },
                styleOverride: function styleOverride() {
                  return 'margin-top: 20px';
                }
              },
              translations.downloadPDF
            )
          ),
          !config.isNVL && _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(
              EmissionsTable,
              null,
              emissionsData.map(function (emission, idx) {
                return _react2.default.createElement(
                  EmissionContainer,
                  { key: idx },
                  _react2.default.createElement(
                    EmissionTitle,
                    {
                      style: {
                        textTransform: 'capitalize'
                      }
                    },
                    translations[emission.category]
                  ),
                  emission.items.map(function (e, index) {
                    return _react2.default.createElement(
                      InfoTableItem,
                      { key: index },
                      _react2.default.createElement(
                        InfoTableLabel,
                        {
                          style: {
                            textTransform: 'capitalize'
                          }
                        },
                        translations[e.label]
                      ),
                      _react2.default.createElement(
                        InfoTableValue,
                        {
                          style: {
                            textTransform: e.label === 'weightedCombined' || e.label === 'classWithDischargedBattery' || e.label === 'energyEfficiencyClass' ? 'uppercase' : 'lowercase'
                          }
                        },
                        e.value
                      )
                    );
                  })
                );
              })
            ),
            _react2.default.createElement(SectionSpacer, {
              role: 'separator',
              spacerSizeSmall: '20',
              spacerSizeLarge: '60'
            }),
            vehicle.energyEfficiencyRating && _react2.default.createElement(
              EnergyEfficiencyContainer,
              null,
              _react2.default.createElement(
                EnergyEfficiencyTable,
                null,
                _react2.default.createElement(
                  EnergyEfficiencyImage,
                  null,
                  emissionsMedia[vehicle.energyEfficiencyRating] && _react2.default.createElement('img', {
                    src: emissionsMedia[vehicle.energyEfficiencyRating],
                    style: {
                      objectFit: 'cover',
                      width: '100%',
                      maxWidth: countryCode && countryCode === 'CH' ? 250 : 350
                    },
                    alt: vehicle.energyEfficiencyRating
                  })
                )
              )
            )
          ),
          vehicle.emissions.co2ClassWeightedCombined && _react2.default.createElement(
            EnergyEfficiencyContainer,
            null,
            _react2.default.createElement(
              EnergyEfficiencyTable,
              null,
              _react2.default.createElement(
                EnergyEfficiencyImage,
                null,
                emissionsMedia[vehicle.emissions.co2ClassWeightedCombined] && _react2.default.createElement('img', {
                  src: emissionsMedia[vehicle.emissions.co2ClassWeightedCombined],
                  style: {
                    objectFit: 'cover',
                    width: '100%',
                    maxWidth: countryCode && countryCode === 'CH' ? 250 : 350
                  },
                  alt: vehicle.emissions.co2ClassWeightedCombined
                })
              )
            )
          )
        )
      )
    ),
    vehicle.extraInformation && _react2.default.createElement(
      SectionContainer,
      { bgColor: '#F2F2F2' },
      _react2.default.createElement(
        Column,
        { width: '100%', maxWidth: '1200px' },
        _react2.default.createElement(
          SectionWrapper,
          null,
          _react2.default.createElement(
            SectionTitle,
            null,
            translations.extraInformation
          ),
          _react2.default.createElement(
            MarkdownWrapper,
            null,
            vehicle.extraInformation
          )
        )
      )
    )
  );
};