'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  return _react2.default.createElement(
    _components.HeadingTextButton,
    props,
    function (_ref) {
      var _ref$config = _ref.config,
          imageLabel = _ref$config.translations.imageLabel,
          src = _ref$config.image.src,
          imageBehaviour = _ref$config.imageBehaviour,
          imagePosition = _ref$config.imagePosition,
          imageHeight = _ref$config.imageHeight;
      return _react2.default.createElement(_components.Image, (0, _extends3.default)({ src: src, imageBehaviour: imageBehaviour, imagePosition: imagePosition, imageHeight: imageHeight }, {
        role: 'img',
        'aria-label': imageLabel
      }));
    }
  );
};