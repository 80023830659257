'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     margin: 50px auto;\n  '], ['\n     margin: 50px auto;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Spinner = require('../../../components/Spinner');

var _useModelSearch2 = require('./useModelSearch');

var _ModelVariantTile = require('../../../components/RollsRoyce/ModelsSearch/ModelVariantTile');

var _ModelVariantTile2 = _interopRequireDefault(_ModelVariantTile);

var _modelTypes = require('../../../types/RollsRoyce/modelTypes');

var _globalStyling = require('../../../types/globalStyling');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ModelSearchWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__ModelSearchWrapper',
  componentId: 'sc-jqeq1i-0'
})(['margin:30px auto;', ''], _theme2.default.min.medium(_templateObject));

var ModelSearch = function ModelSearch(_ref) {
  var config = (0, _objectWithoutProperties3.default)(_ref.config, []),
      globalStyling = _ref.globalStyling;

  var _useModelSearch = (0, _useModelSearch2.useModelSearch)(config.searchSections),
      getModelGroups = _useModelSearch.getModelGroups,
      initialiseFilters = _useModelSearch.initialiseFilters,
      clearState = _useModelSearch.clearState,
      modelGroups = _useModelSearch.modelGroups,
      clearQueryParams = _useModelSearch.clearQueryParams;

  (0, _react.useEffect)(function () {
    clearQueryParams();
    clearState();
    initialiseFilters();
    getModelGroups();
    return function () {
      clearState();
    };
  }, []);

  var currentModels = (0, _react.useMemo)(function () {
    return config.searchSections.filter(function (section) {
      return section.name === 'Current';
    });
  }, [config.searchSections]);

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      _Spinner.SpinnerWrapper,
      {
        loaded: modelGroups.length > 0,
        globalStyling: globalStyling
      },
      function () {
        return _react2.default.createElement(
          ModelSearchWrapper,
          null,
          currentModels.map(function (section) {
            return _react2.default.createElement(_ModelVariantTile2.default, {
              key: section.name,
              section: section,
              config: config,
              width: '100%'
            });
          })
        );
      }
    )
  );
};

exports.default = ModelSearch;