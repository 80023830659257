'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _TableElements = require('./TableElements');

var _ShortlistButton = require('../ShortlistButton');

var _ShortlistButton2 = _interopRequireDefault(_ShortlistButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ShortlistRow = function ShortlistRow(_ref) {
  var comparedVehicles = _ref.comparedVehicles,
      iconOn = _ref.iconOn,
      iconOff = _ref.iconOff;
  return _react2.default.createElement(
    _TableElements.TableRow,
    null,
    _react2.default.createElement(_TableElements.TableDataFirstColumn, null),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        _TableElements.TableData,
        { key: '' + vehicle.id },
        _react2.default.createElement(
          'div',
          {
            style: {
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
              marginTop: '20px'
            }
          },
          _react2.default.createElement(_ShortlistButton2.default, {
            vehicle: vehicle,
            iconOn: iconOn,
            iconOff: iconOff
          })
        )
      );
    })
  );
};

exports.default = ShortlistRow;