'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n'], ['\n    flex-direction: column;\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n    width: 100%;\n    padding-left: 0;\n'], ['\n    flex-direction: row;\n    width: 100%;\n    padding-left: 0;\n']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    margin-top: 20px;\n'], ['\n    width: 100%;\n    margin-top: 20px;\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width:100%;\n'], ['\n    width:100%;\n']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n    width: 100%;\n    display: ', ';\n     &:first-child {\n      margin-top: 13px;\n    }\n'], ['\n    flex-direction: row;\n    width: 100%;\n    display: ', ';\n     &:first-child {\n      margin-top: 13px;\n    }\n']);

exports.labelWithIcon = labelWithIcon;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCompareVehicle2 = require('../../../hooks/useCompareVehicle');

var _useShortListVehicle2 = require('../../../hooks/useShortListVehicle');

var _SocialShareButton = require('../../../modules/Header/SocialShareButton');

var _SocialShareButton2 = _interopRequireDefault(_SocialShareButton);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _useChangeReleaseFlag = require('../../../utilities/useChangeReleaseFlag');

var _Global = require('../../Global');

var _Spinner = require('../../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _StyledIcon = require('../../StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var buttonActions = {
  testdrive: 'testdrive',
  reserve: 'reserve',
  contact: 'contact',
  phone: 'phone'
};

// const LabelIconWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   ${media.max.medium`
//     flex-direction: row;
//     width: 100%;
// `};
// `;

var UserActionsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpUserActions__UserActionsWrapper',
  componentId: 'sc-1bq64f1-0'
})(['display:flex;justify-content:space-between;', ';'], _theme2.default.max.medium(_templateObject));

var SocialMediaContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpUserActions__SocialMediaContainer',
  componentId: 'sc-1bq64f1-1'
})(['width:35%;flex-direction:column;display:flex;justify-content:center;', ';'], _theme2.default.max.medium(_templateObject2));

var ButtonsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpUserActions__ButtonsWrapper',
  componentId: 'sc-1bq64f1-2'
})(['display:flex;flex-direction:column;justify-content:center;width:65%;', ';'], _theme2.default.max.medium(_templateObject3));

var PhoneLink = _styledComponents2.default.a.withConfig({
  displayName: 'VdpUserActions__PhoneLink',
  componentId: 'sc-1bq64f1-3'
})(['display:flex;flex-direction:column;text-decoration:none;', ';'], _theme2.default.max.medium(_templateObject4));

var LabelWithIconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpUserActions__LabelWithIconWrapper',
  componentId: 'sc-1bq64f1-4'
})(['display:flex;margin-top:13px;margin-bottom:13px;', ';', ';'], function (_ref) {
  var cursorPointer = _ref.cursorPointer;
  return cursorPointer && 'cursor: pointer;';
}, _theme2.default.max.medium(_templateObject5, function (_ref2) {
  var onlyDesktop = _ref2.onlyDesktop;
  return onlyDesktop ? 'none' : 'flex';
}));

var AnchorText = _styledComponents2.default.div.withConfig({
  displayName: 'VdpUserActions__AnchorText',
  componentId: 'sc-1bq64f1-5'
})(['font-size:14px;', ' ', ''], function (_ref3) {
  var font = _ref3.font;
  return (0, _Global.fontStyleOverride)(font);
}, function (_ref4) {
  var styleOverride = _ref4.styleOverride;
  return styleOverride;
});

var ErrorAnchorText = (0, _styledComponents2.default)(AnchorText).withConfig({
  displayName: 'VdpUserActions__ErrorAnchorText',
  componentId: 'sc-1bq64f1-6'
})(['cursor:pointer;']);

function labelWithIcon(label, icon, onClick, font, onlyDesktop) {
  return _react2.default.createElement(
    LabelWithIconWrapper,
    {
      onClick: onClick,
      cursorPointer: true,
      onlyDesktop: onlyDesktop
    },
    _react2.default.createElement(_StyledIcon2.default, { link: icon, width: 16, height: 16 }),
    _react2.default.createElement(
      AnchorText,
      {
        font: font,
        styleOverride: function styleOverride() {
          return '\n                margin-left: 8px;                \n            ';
        }
      },
      label
    )
  );
}

var VdpUserActions = function VdpUserActions(props) {
  var config = props.config,
      globalStyling = props.globalStyling,
      translations = props.translations,
      makeATestDriveRequest = props.makeATestDriveRequest,
      makeAReservation = props.makeAReservation,
      makeAnEnquiry = props.makeAnEnquiry,
      vehicleInfo = props.vehicleInfo,
      socialShare = props.socialShare,
      printingState = props.printingState,
      printVehiclePdf = props.printVehiclePdf;
  var icons = globalStyling.icons;

  var _useCompareVehicle = (0, _useCompareVehicle2.useCompareVehicle)({
    vehicleId: vehicleInfo.id
  }),
      isCompared = _useCompareVehicle.isCompared,
      toggleCompare = _useCompareVehicle.toggleCompare;

  var _useShortListVehicle = (0, _useShortListVehicle2.useShortListVehicle)({
    vehicleId: vehicleInfo.id
  }),
      isShortlisted = _useShortListVehicle.isShortlisted,
      toggleShortlist = _useShortListVehicle.toggleShortlist;

  var financeEnabled = (0, _useChangeReleaseFlag.useChangeReleaseFlag)('WO-21607') && vehicleInfo.specification.odometer && vehicleInfo.price.value !== 0 && (config.financeAvailableCountries || []).includes(vehicleInfo.retailerInformation.country);

  var printingDisplay = printingState.error ? _react2.default.createElement(
    ErrorAnchorText,
    { onClick: printVehiclePdf },
    translations.printError
  ) : labelWithIcon(translations.printPage, icons.print, printVehiclePdf, config.userActions, false);

  var renderActionButton = function renderActionButton(buttonTag, text, onClickEvent) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      config.buttonsToDisplay.includes(buttonTag) && _react2.default.createElement(_Global.Button, {
        text: text,
        applyStyle: 'primary',
        buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
        onClick: onClickEvent,
        styleOverride: function styleOverride() {
          return 'margin-bottom: 5px; height: 40px';
        },
        mobileStyleOverride: function mobileStyleOverride() {
          return 'margin-bottom: 5px;';
        }
      })
    );
  };
  return _react2.default.createElement(
    UserActionsWrapper,
    null,
    _react2.default.createElement(
      SocialMediaContainer,
      null,
      config.socialShareConfig && config.socialShareConfig.socialShareEnabled && _react2.default.createElement(
        LabelWithIconWrapper,
        { onlyDesktop: false },
        _react2.default.createElement(_SocialShareButton2.default, (0, _extends3.default)({
          config: (0, _extends3.default)({}, config.socialShareConfig, config)
        }, {
          socialShare: socialShare
        }))
      ),
      printingState.isPrinting ? _react2.default.createElement(
        LabelWithIconWrapper,
        null,
        _react2.default.createElement(_Spinner2.default, {
          size: 10,
          colour: globalStyling.colours.primaryBrandColour
        })
      ) : _react2.default.createElement(
        _react.Fragment,
        null,
        printingDisplay
      ),
      !config.hideCompareShortlist && labelWithIcon(translations.compareActionLabel, isCompared ? icons.compareselected : icons.compare, function () {
        return toggleCompare(vehicleInfo);
      }, config.userActions, true),
      !config.hideCompareShortlist && labelWithIcon(translations.shortlistActionLabel, isShortlisted ? icons.heartselected : icons.heart, function () {
        return toggleShortlist(vehicleInfo);
      }, config.userActions, false)
    ),
    _react2.default.createElement(
      ButtonsWrapper,
      null,
      config.buttonsToDisplay && _react2.default.createElement(
        _react.Fragment,
        null,
        financeEnabled && _react2.default.createElement(_Global.Button, {
          'data-cy': 'finance-button',
          text: translations.financeButtonText,
          applyStyle: 'primary',
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          onClick: function onClick() {
            var y = document.getElementById('pluginContent').getBoundingClientRect().top + window.pageYOffset - 130;
            window.scrollTo({ top: y, behavior: 'smooth' });
          },
          styleOverride: function styleOverride() {
            return '\n                        width: 100%;\n                        margin-bottom: 5px;\n                        height: 40px;\n                    ';
          },
          mobileStyleOverride: function mobileStyleOverride() {
            return 'margin-bottom: 5px;';
          }
        }),
        renderActionButton(buttonActions.testdrive, translations.vdpBookTestDrive, makeATestDriveRequest),
        renderActionButton(buttonActions.reserve, translations.vdpReserveThisVehicle, makeAReservation),
        renderActionButton(buttonActions.contact, translations.vdpContactUs, makeAnEnquiry),
        config.buttonsToDisplay && config.buttonsToDisplay.includes(buttonActions.phone) && _react2.default.createElement(
          PhoneLink,
          {
            href: 'tel:' + vehicleInfo.retailerInformation.phone
          },
          _react2.default.createElement(_Global.Button, {
            text: vehicleInfo.retailerInformation.phone,
            applyStyle: 'secondary',
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            styleOverride: function styleOverride() {
              return '\n                        height: 40px;\n                    ';
            }
          })
        )
      )
    )
  );
};

exports.default = VdpUserActions;