'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _components = require('../components');

var _settings = require('../../shared/selectors/settings');

var _vehicle = require('../../helpers/vehicle');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SpecContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleSpecifications__SpecContainer',
  componentId: 'sc-zoief3-0'
})(['display:flex;width:100%;flex-wrap:wrap;']);

var Spec = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleSpecifications__Spec',
  componentId: 'sc-zoief3-1'
})(['display:flex;width:100%;padding:10px;']);

var SpecLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleSpecifications__SpecLabel',
  componentId: 'sc-zoief3-2'
})(['display:flex;flex:0 0 50%;font-weight:bold;']);

var SpecValue = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleSpecifications__SpecValue',
  componentId: 'sc-zoief3-3'
})(['display:flex;flex:0 0 50%;']);

var specificationSelector = function specificationSelector(state) {
  if (!state.dataProvider.vehicleInfo) {
    return {};
  }

  return state.dataProvider.vehicleInfo.specification;
};

exports.default = function (props) {
  var specification = (0, _reactRedux.useSelector)(specificationSelector);
  var locale = (0, _reactRedux.useSelector)(_settings.getLocale);
  var _props$config = props.config,
      properties = _props$config.properties,
      translations = _props$config.translations;


  return (0, _keys2.default)(specification).length && _react2.default.createElement(
    _components.Wrapper,
    null,
    _react2.default.createElement(
      SpecContainer,
      null,
      properties.map(function (p) {
        return {
          label: translations[p.translation],
          value: (0, _vehicle.vehiclePropFormatter)(p, specification, locale)
        };
      }).filter(function (i) {
        return i.value !== undefined;
      }).map(function (_ref) {
        var label = _ref.label,
            value = _ref.value;
        return _react2.default.createElement(
          Spec,
          { key: '' + label + value },
          _react2.default.createElement(
            SpecLabel,
            null,
            label
          ),
          _react2.default.createElement(
            SpecValue,
            null,
            value
          )
        );
      })
    )
  );
};