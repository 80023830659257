'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

/* eslint-disable import/prefer-default-export */
var sortOptions = exports.sortOptions = {
  priceAsc: {
    field: 'fullPrice',
    order: 'asc',
    value: 'priceAsc'
  },
  priceDesc: {
    field: 'fullPrice',
    order: 'desc',
    value: 'priceDesc'
  },
  distanceAsc: {
    field: 'distance',
    order: 'asc',
    value: 'distanceAsc'
  },
  mileageAsc: {
    field: 'odometer',
    order: 'asc',
    value: 'mileageAsc'
  },
  mileageDesc: {
    field: 'odometer',
    order: 'desc',
    value: 'mileageDesc'
  },
  ageAsc: {
    field: 'age',
    order: 'asc',
    value: 'ageAsc'
  },
  ageDesc: {
    field: 'age',
    order: 'desc',
    value: 'ageDesc'
  }
};