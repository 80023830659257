'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Electric;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Electric(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 28 32' },
      _react2.default.createElement('path', { d: 'M22.874 11.603a.467.467 0 0 0-.423-.27h-5.53l5.458-8.617A.466.466 0 0 0 21.985 2h-7.467c-.177 0-.338.1-.418.258l-7 14a.466.466 0 0 0 .418.675h4.799l-5.23 12.42a.468.468 0 0 0 .788.481l14.933-17.733a.467.467 0 0 0 .066-.498z' })
    )
  );
}

Electric.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};