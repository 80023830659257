'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _One = require('./Templates/One');

var _One2 = _interopRequireDefault(_One);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var templates = {
  One: _One2.default
};

exports.default = function (_ref) {
  var config = _ref.config;

  var Template = templates[config.template];
  return _react2.default.createElement(Template, { config: config });
};