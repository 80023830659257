'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none\n  '], ['\n    display: none\n  ']); /* eslint-disable no-confusing-arrow */


exports.PaginationOne = PaginationOne;
exports.PaginationTwo = PaginationTwo;
exports.GenesisPagination = GenesisPagination;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _range = require('ramda/src/range');

var _range2 = _interopRequireDefault(_range);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Pagination = _styledComponents2.default.div.withConfig({
  displayName: 'Pagination',
  componentId: 'sc-1rht078-0'
})(['position:absolute;top:500px;', ';', ';'], function (_ref) {
  var alignment = _ref.alignment;
  return alignment && alignment.align + ': ' + alignment.alignmentWidth + '%';
}, _theme2.default.max.extraLarge(_templateObject));

var PaginationCenter = _styledComponents2.default.div.withConfig({
  displayName: 'Pagination__PaginationCenter',
  componentId: 'sc-1rht078-1'
})(['display:flex;position:absolute;width:100%;justify-content:center;top:90%;gap:16px;', ';'], _theme2.default.max.large(_templateObject2));

var AlignmentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Pagination__AlignmentWrapper',
  componentId: 'sc-1rht078-2'
})(['display:flex;position:absolute;']);

var PageRectangular = _styledComponents2.default.span.withConfig({
  displayName: 'Pagination__PageRectangular',
  componentId: 'sc-1rht078-3'
})(['cursor:pointer;text-align:center;color:', ';font-size:12px;line-height:15px;background-color:', ';border:', ';margin:1px;padding:1px 6px;'], function (_ref2) {
  var active = _ref2.active,
      activeColour = _ref2.activeColour,
      inactiveColour = _ref2.inactiveColour;
  return active ? activeColour : inactiveColour;
}, function (_ref3) {
  var active = _ref3.active,
      activeColour = _ref3.activeColour,
      inactiveColour = _ref3.inactiveColour;
  return active ? inactiveColour : activeColour;
}, function (_ref4) {
  var active = _ref4.active,
      activeColour = _ref4.activeColour;
  return active ? '1px solid ' + activeColour : 'unset';
});

var PageRadio = _styledComponents2.default.span.withConfig({
  displayName: 'Pagination__PageRadio',
  componentId: 'sc-1rht078-4'
})(['cursor:pointer;height:13px;width:13px;text-align:center;font-size:12px;line-height:15px;', ';background-color:', ';border:', ';border-radius:15px;margin:0 13px;'], function (_ref5) {
  var paginationOpacity = _ref5.paginationOpacity;
  return paginationOpacity && 'opacity: ' + paginationOpacity;
}, function (_ref6) {
  var active = _ref6.active,
      activeColour = _ref6.activeColour,
      inactiveColour = _ref6.inactiveColour;
  return active ? activeColour : inactiveColour;
}, function (_ref7) {
  var active = _ref7.active,
      activeColour = _ref7.activeColour,
      inactiveColour = _ref7.inactiveColour;
  return '1px solid ' + (active ? activeColour : inactiveColour);
});

function PaginationOne(_ref8) {
  var items = _ref8.items,
      onPageclick = _ref8.onPageclick,
      activeItem = _ref8.activeItem,
      activeColour = _ref8.activeColour,
      inactiveColour = _ref8.inactiveColour,
      align = _ref8.align,
      alignmentWidth = _ref8.alignmentWidth,
      paginationWidth = _ref8.paginationWidth;

  var slides = items || [];
  var slideRange = (0, _range2.default)(0, slides.length);
  return _react2.default.createElement(
    _ContentWrapper2.default,
    { contentWidth: paginationWidth },
    _react2.default.createElement(
      Pagination,
      { alignment: { align: align, alignmentWidth: alignmentWidth } },
      _react2.default.createElement(
        AlignmentWrapper,
        null,
        slides.length > 1 && slideRange.map(function (i) {
          return _react2.default.createElement(
            PageRectangular,
            {
              key: 'slide-page-' + i,
              active: i === activeItem,
              onClick: function onClick() {
                return onPageclick(i);
              },
              activeColour: activeColour,
              inactiveColour: inactiveColour
            },
            i + 1
          );
        })
      )
    )
  );
}

function PaginationTwo(_ref9) {
  var items = _ref9.items,
      onPageclick = _ref9.onPageclick,
      activeItem = _ref9.activeItem,
      activeColour = _ref9.activeColour,
      inactiveColour = _ref9.inactiveColour,
      paginationOpacity = _ref9.paginationOpacity;

  var slides = items || [];
  var slideRange = (0, _range2.default)(0, slides.length);

  return _react2.default.createElement(
    PaginationCenter,
    null,
    slides.length > 1 && slideRange.map(function (i) {
      return _react2.default.createElement(PageRadio, {
        key: 'slide-page-' + i,
        active: i === activeItem,
        onClick: function onClick() {
          return onPageclick(i);
        },
        activeColour: activeColour,
        inactiveColour: inactiveColour,
        paginationOpacity: paginationOpacity
      });
    })
  );
}

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'Pagination__Icon',
  componentId: 'sc-1rht078-5'
})(['cursor:pointer;height:14px;width:14px;']);

var GenesisPaginationCenter = _styledComponents2.default.div.withConfig({
  displayName: 'Pagination__GenesisPaginationCenter',
  componentId: 'sc-1rht078-6'
})(['bottom:0;display:flex;gap:16px;justify-content:center;margin-bottom:40px;position:absolute;width:100%;@media screen and (min-width:1023px){margin-bottom:56px;}@media screen and (min-width:1199px){margin-bottom:78px;}@media screen and (min-width:1919px){margin-bottom:160px;}']);

var RadioIconActive = 'https://res.cloudinary.com/motortrak/v1695728288/locator/genesis/icons/active_radio.svg';
var RadioIconInactive = 'https://res.cloudinary.com/motortrak/v1695728292/locator/genesis/icons/inactive_radio.svg';
var pauseIcon = 'https://res.cloudinary.com/motortrak/v1695728289/locator/genesis/icons/carousel__pausebutton.svg';
var playIcon = 'https://res.cloudinary.com/motortrak/v1696332650/locator/genesis/icons/play.svg';

function GenesisPagination(_ref10) {
  var items = _ref10.items,
      onPageclick = _ref10.onPageclick,
      activeItem = _ref10.activeItem,
      togglePause = _ref10.togglePause,
      paused = _ref10.paused;

  var slides = items || [];
  var slideRange = (0, _range2.default)(0, slides.length);
  return _react2.default.createElement(
    GenesisPaginationCenter,
    null,
    slides.length > 1 && slideRange.map(function (i) {
      return _react2.default.createElement(Icon, {
        key: 'slide-page-' + i,
        src: i === activeItem ? RadioIconActive : RadioIconInactive,
        onClick: function onClick() {
          return onPageclick(i);
        },
        role: 'button',
        tabIndex: 0
      });
    }),
    slides.length > 1 && _react2.default.createElement(Icon, {
      role: 'button',
      src: paused ? playIcon : pauseIcon,
      onClick: togglePause,
      tabIndex: 0
    })
  );
}