'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MAP_SEARCH';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_RETAILERS', 'GET_RETAILERS_SUCCESS']);

var id = exports.id = 'GenesisRetailerLocationSearch';

var actions = exports.actions = {
  getRetailers: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_RETAILERS, id, function (payload) {
    return payload;
  }),
  getRetailersSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_RETAILERS_SUCCESS, id, function (payload) {
    return payload;
  }),
  getLocation: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_LOCATION, id, function (payload) {
    return payload;
  })
};

var initialState = exports.initialState = {
  retailers: []
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.GET_RETAILERS:
      {
        var _ref = action.payload || {},
            lat = _ref.lat,
            lng = _ref.lng;

        return (0, _extends3.default)({}, state, {
          loadingNearest: !!(lat && lng)
        });
      }
    case constants.GET_RETAILERS_SUCCESS:
      {
        var _action$payload = action.payload,
            _retailers = _action$payload.retailers,
            _action$payload$centr = (0, _slicedToArray3.default)(_action$payload.centreLocation.coordinates, 2),
            _lat = _action$payload$centr[0],
            _lng = _action$payload$centr[1];

        var formattedRetailers = _retailers.map(function (retailer) {
          return (0, _extends3.default)({}, retailer, {
            location: {
              lat: retailer.location.coordinates[0],
              lng: retailer.location.coordinates[1]
            }
          });
        });
        return (0, _extends3.default)({}, state, {
          retailers: formattedRetailers,
          centreLocation: { lat: _lat, lng: _lng },
          loadingNearest: false
        });
      }
    default:
      return state;
  }
}