'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Checkbox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'Checkbox__Container',
  componentId: 'sc-qtnnb3-0'
})(['position:relative;cursor:', ';border:solid 1px ', ';background-color:', ';width:14px;height:14px;min-height:14px;min-width:14px;'], function (_ref) {
  var enabled = _ref.enabled;
  return enabled ? 'pointer' : '';
}, function (_ref2) {
  var checked = _ref2.checked,
      color = _ref2.color,
      enabled = _ref2.enabled;
  return enabled ? checked ? color : '#707070' : '#d1d1d1';
}, function (_ref3) {
  var checked = _ref3.checked,
      color = _ref3.color,
      enabled = _ref3.enabled;
  return enabled ? checked ? color : 'transparent' : '';
});

var CheckImage = _styledComponents2.default.div.withConfig({
  displayName: 'Checkbox__CheckImage',
  componentId: 'sc-qtnnb3-1'
})(['position:absolute;transform:rotate(45deg);height:7px;width:3px;left:38%;top:12%;border-bottom:1px solid #ffffff;border-right:1px solid #ffffff;']);

function Checkbox(_ref4) {
  var onClick = _ref4.onClick,
      checked = _ref4.checked,
      _ref4$enabled = _ref4.enabled,
      enabled = _ref4$enabled === undefined ? true : _ref4$enabled;

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      colours = _useSelector.colours;

  return _react2.default.createElement(
    Container,
    {
      checked: checked,
      onClick: onClick,
      color: colours.primaryBrandColour.value,
      enabled: enabled
    },
    checked && _react2.default.createElement(CheckImage, null)
  );
}