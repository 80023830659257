'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AstonLink;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Arrow = require('../../assets/AstonMartin/Arrow');

var _Arrow2 = _interopRequireDefault(_Arrow);

var _Plus = require('../../assets/AstonMartin/Plus');

var _Plus2 = _interopRequireDefault(_Plus);

var _Spinner = require('../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LinkWrap = _styledComponents2.default.div.withConfig({
  displayName: 'AstonLink__LinkWrap',
  componentId: 'sc-ix1zel-0'
})(['min-height:40px;display:flex;', ''], function (_ref) {
  var justifyContent = _ref.justifyContent;
  return 'justify-content: ' + (justifyContent || 'space-between') + ';';
});


var StyledLink = _styledComponents2.default.a.withConfig({
  displayName: 'AstonLink__StyledLink',
  componentId: 'sc-ix1zel-1'
})(['background:none !important;border:none;padding:0 !important;', ' display:flex;justify-content:space-between;', ':link{text-decoration:none;}:visited{text-decoration:none;}:hover{text-decoration:underline;}:active{text-decoration:underline;}'], function (_ref2) {
  var cursor = _ref2.cursor;
  return 'cursor: ' + (cursor || 'pointer') + ';';
}, function (_ref3) {
  var colour = _ref3.colour;
  return colour && 'color: ' + colour + ';';
});

var LinkText = _styledComponents2.default.span.withConfig({
  displayName: 'AstonLink__LinkText',
  componentId: 'sc-ix1zel-2'
})(['margin-right:1em;font-size:14px;line-height:', ';display:flex;align-items:center;justify-content:space-between;', ':link{text-decoration:none;}:visited{text-decoration:none;}:hover{text-decoration:underline;}:active{text-decoration:underline;}'], function (_ref4) {
  var lineHeight = _ref4.lineHeight;
  return lineHeight && '' + lineHeight || '20px';
}, function (_ref5) {
  var fontFamily = _ref5.fontFamily;
  return fontFamily && 'font-family: ' + fontFamily + ';';
});

function AstonLink(_ref6) {
  var colour = _ref6.colour,
      text = _ref6.text,
      link = _ref6.link,
      fontFamily = _ref6.fontFamily,
      onClick = _ref6.onClick,
      lineHeight = _ref6.lineHeight,
      target = _ref6.target,
      _ref6$icon = _ref6.icon,
      icon = _ref6$icon === undefined ? 'arrow' : _ref6$icon,
      _ref6$cursor = _ref6.cursor,
      cursor = _ref6$cursor === undefined ? 'pointer' : _ref6$cursor,
      justifyContent = _ref6.justifyContent,
      busy = _ref6.busy,
      spinnerColour = _ref6.spinnerColour,
      busyText = _ref6.busyText;

  return _react2.default.createElement(
    LinkWrap,
    { justifyContent: justifyContent },
    busy ? _react2.default.createElement(
      LinkText,
      { fontFamily: fontFamily, lineHeight: lineHeight },
      busyText,
      ' ',
      _react2.default.createElement(_Spinner2.default, { size: 12, colour: spinnerColour || colour })
    ) : _react2.default.createElement(
      StyledLink,
      {
        'data-cy': text,
        href: link,
        target: target,
        colour: colour,
        onClick: onClick,
        cursor: cursor
      },
      _react2.default.createElement(
        LinkText,
        { fontFamily: fontFamily, lineHeight: lineHeight },
        text
      ),
      icon === 'arrow' ? _react2.default.createElement(_Arrow2.default, { colour: colour }) : _react2.default.createElement(_Plus2.default, { colour: colour })
    )
  );
}

AstonLink.defaultProps = {
  colour: '#fff'
};