'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CheckboxGroup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Tick = require('../JaguarIcons/Global/Tick');

var _Tick2 = _interopRequireDefault(_Tick);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Group = _styledComponents2.default.label.withConfig({
  displayName: 'Group'
}).withConfig({
  displayName: 'CheckboxGroup__Group',
  componentId: 'sc-1qrcfsw-0'
})(['height:55px;display:', ';align-items:center;justify-content:space-between;color:#444;width:100%;border-bottom:1px solid #d8d8d8;'], function (_ref) {
  var visible = _ref.visible;
  return visible ? 'flex' : 'none';
});


var Input = _styledComponents2.default.input.withConfig({
  displayName: 'Input'
}).withConfig({
  displayName: 'CheckboxGroup__Input',
  componentId: 'sc-1qrcfsw-1'
})(['display:none;&:checked + span{display:block;}']);

var TickContainer = _styledComponents2.default.span.withConfig({
  displayName: 'TickContainer'
}).withConfig({
  displayName: 'CheckboxGroup__TickContainer',
  componentId: 'sc-1qrcfsw-2'
})(['margin:5px 5px 0 0;order:2;display:none;']);

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'Label'
}).withConfig({
  displayName: 'CheckboxGroup__Label',
  componentId: 'sc-1qrcfsw-3'
})(['order:1;color:', ';font-size:16px;white-space:nowrap;'], function (_ref2) {
  var isDisabled = _ref2.isDisabled;
  return isDisabled ? '#ACACAC' : '#666';
});

function CheckboxGroup(_ref3) {
  var isDisabled = _ref3.isDisabled,
      isChecked = _ref3.isChecked,
      value = _ref3.value,
      handleClick = _ref3.handleClick,
      id = _ref3.id,
      isCheckedColour = _ref3.isCheckedColour;

  return _react2.default.createElement(
    Group,
    { visible: !isDisabled },
    _react2.default.createElement(Input, {
      checked: isChecked,
      value: value,
      id: id,
      onChange: handleClick,
      disabled: isDisabled,
      type: 'checkbox'
    }),
    _react2.default.createElement(
      TickContainer,
      { isDisabled: isDisabled },
      _react2.default.createElement(_Tick2.default, {
        width: '24px',
        height: '24px',
        colour: isCheckedColour,
        fill: isCheckedColour
      })
    ),
    _react2.default.createElement(
      Label,
      { isDisabled: isDisabled },
      value
    )
  );
}

CheckboxGroup.defaultProps = {
  isDisabled: false,
  isChecked: true
};