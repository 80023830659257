'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Brick = require('./Brick');

var _Brick2 = _interopRequireDefault(_Brick);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Brick2.default;