'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 10px 20px;\n    display: flex;\n    flex-direction: column;\n  '], ['\n    padding: 10px 20px;\n    display: flex;\n    flex-direction: column;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FinanceContainer = require('../../containers/FinanceContainer');

var _FinanceContainer2 = _interopRequireDefault(_FinanceContainer);

var _ModelSummary = require('../../components/ModelSummary/ModelSummary');

var _ModelSummary2 = _interopRequireDefault(_ModelSummary);

var _RepresentativeExample = require('../../components/RepresentativeExample');

var _RepresentativeExample2 = _interopRequireDefault(_RepresentativeExample);

var _PathnameSubNavBar = require('../../components/PathnameSubNavBar');

var _PathnameSubNavBar2 = _interopRequireDefault(_PathnameSubNavBar);

var _router = require('../../actions/router');

var _searchFilter = require('../../shared/searchFilter');

var _filters = require('../../shared/filters');

var _numbers = require('../../shared/localisation/numbers');

var _Global = require('../../components/Global');

var _FinancePopup = require('../../components/FinancePopup');

var _FinancePopup2 = _interopRequireDefault(_FinancePopup);

var _routing = require('../../shared/routing');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _finance = require('../../helpers/finance');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TopImageText = _styledComponents2.default.div.withConfig({
  displayName: 'ExploreRange__TopImageText',
  componentId: 'sc-swv9hy-0'
})(['display:inline-block;background-color:rgba(0,0,0,0.8);']);

var RepresentativeExampleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RepresentativeExampleContainer'
}).withConfig({
  displayName: 'ExploreRange__RepresentativeExampleContainer',
  componentId: 'sc-swv9hy-1'
})(['', ';'], _theme2.default.min.large(_templateObject));

var TopImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TopImageContainer'
}).withConfig({
  displayName: 'ExploreRange__TopImageContainer',
  componentId: 'sc-swv9hy-2'
})(['', ';height:400px;max-height:700px;min-height:400px;background-repeat:no-repeat;background-position:center center;background-size:cover;display:flex;align-items:center;', ';'], function (_ref) {
  var url = _ref.url;
  return '' + (url && 'background-image: url(' + url + ')');
}, _theme2.default.max.large(_templateObject2));

var renderModelFinance = function renderModelFinance(from, summary, finance, translations, locale) {
  return function () {
    return _react2.default.createElement(
      'div',
      null,
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n        font-size: 20px;\n      ';
          }
        },
        from
      ),
      _react2.default.createElement(
        'div',
        { style: { display: 'flex', alignItems: 'center' } },
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n        font-size: 20px;\n      ';
            }
          },
          summary
        ),
        finance && _react2.default.createElement(_FinancePopup2.default, {
          data: finance,
          translations: translations,
          locale: locale
        })
      )
    );
  };
};

var ExploreRange = function (_Component) {
  (0, _inherits3.default)(ExploreRange, _Component);

  function ExploreRange() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, ExploreRange);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = ExploreRange.__proto__ || (0, _getPrototypeOf2.default)(ExploreRange)).call.apply(_ref2, [this].concat(args))), _this), Object.defineProperty(_this, 'componentDidUpdate', {
      enumerable: true,
      writable: true,
      value: function value(prevProps) {
        var getModelGroups = _this.props.actions.getModelGroups;
        var _this$props = _this.props,
            _this$props$config$en = _this$props.config.endpoints,
            endpoints = _this$props$config$en === undefined ? {} : _this$props$config$en,
            shared = _this$props.shared;

        if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language) {
          getModelGroups(endpoints.groups);
        }
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(ExploreRange, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // We call init to force a first run of the reducer
      // - there used to be a more generic solution,
      //   but it broke the builder
      var _props$actions = this.props.actions,
          init = _props$actions.init,
          getModelGroups = _props$actions.getModelGroups;
      var _props$config$endpoin = this.props.config.endpoints,
          endpoints = _props$config$endpoin === undefined ? {} : _props$config$endpoin;

      init();
      getModelGroups(endpoints.groups);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          preview = _props.preview,
          dispatch = _props.dispatch,
          shared = _props.shared,
          state = _props.state,
          pathByModule = _props.pathByModule,
          globalStyling = _props.globalStyling,
          config = _props.config,
          marketInfo = _props.marketInfo,
          featureFlags = _props.featureFlags,
          history = _props.history;
      var _props$config = this.props.config,
          translations = _props$config.translations,
          previewBannerUrl = _props$config.previewBannerUrl;

      var representativeExample = preview ? {} : shared.financeCalculator.representativeExample;
      var vehicles = state.modelGroups;
      var lowerCaseLinks = config.modelLinks.map(function (ml) {
        return ml.toLowerCase();
      });
      var modelDictionary = config.modelLinks.map(function (ml) {
        return {
          key: ml.toLowerCase(),
          value: ml
        };
      });
      var links = (vehicles || []).filter(function (model) {
        return lowerCaseLinks.includes(model.model.toLowerCase());
      }).map(function (_ref3) {
        var model = _ref3.model,
            modelDisplay = _ref3.modelDisplay;
        return {
          text: modelDisplay || model,
          onClick: function onClick() {
            return dispatch(_router.actions.navigate((0, _routing.formatUrl)('/range/' + model)));
          },
          availableOnMobile: true,
          path: (0, _routing.formatUrl)('range/' + model)
        };
      });

      var searchLink = {
        text: translations.searchRangeLabel,
        onClick: function onClick() {
          return dispatch(_router.actions.navigate('/search'));
        },
        availableOnMobile: true
      };

      var goToModule = function goToModule(name) {
        return dispatch(_router.actions.navigate(pathByModule(name)));
      };

      var _props$config2 = this.props.config,
          market = _props$config2.market,
          make = _props$config2.make;


      var searchClick = function searchClick(modelRange) {
        if (config.showLamboCTAs) {
          dispatch(_filters.actions.resetFilters());

          dispatch(_router.actions.navigateWithQuery('/searchresults', {
            model: modelRange
          }));
        } else {
          dispatch(_searchFilter.actions.resetFilters());
          dispatch(_searchFilter.actions.filterByModelRange({
            section: 'models',
            filter: modelRange
          }));
          dispatch(_searchFilter.actions.getSearchFilters({
            market: market,
            make: make,
            filters: 'models=' + modelRange
          }));
          goToModule('SearchResults');
        }
      };

      var findOutMoreUrl = function findOutMoreUrl(vehicle) {
        return vehicle.externalUrl || (0, _routing.formatUrl)('/range/' + vehicle.model);
      };

      return vehicles ? _react2.default.createElement(
        'div',
        null,
        config.hideSubNav ? null : _react2.default.createElement(_PathnameSubNavBar2.default, {
          links: [].concat((0, _toConsumableArray3.default)(links), [searchLink]),
          title: 'Explore Range',
          exploreRangeBackgroundColor: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
          exploreRangeForegroundColour: config.exploreRangeForegroundColour,
          subNavBGColour: config.subNavBGColour,
          subNavBorderColour: config.subNavBorderColour,
          subNavFontTheme: config.subNavFont,
          isExploreRange: true,
          subNavBarItemSelectedBackgroundColour: config.subNavBarItemSelectedBackgroundColour,
          subNavBarItemSelectedFontColour: config.subNavBarItemSelectedFontColour,
          contentWidth: globalStyling.contentWidth,
          history: history
        }),
        featureFlags.finance && config.showRepresentativeExample && _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          _react2.default.createElement(
            RepresentativeExampleContainer,
            null,
            _react2.default.createElement(_FinanceContainer2.default, {
              component: function component(props) {
                return _react2.default.createElement(_RepresentativeExample2.default, (0, _extends3.default)({}, props, {
                  representativeExample: representativeExample,
                  preview: preview
                }));
              }
            })
          )
        ),
        _react2.default.createElement(
          TopImageContainer,
          {
            role: 'img',
            'aria-label': translations.title,
            url: previewBannerUrl
          },
          _react2.default.createElement(
            _ContentWrapper2.default,
            { contentWidth: globalStyling.contentWidth },
            _react2.default.createElement(
              TopImageText,
              null,
              _react2.default.createElement(
                _Global.HeadingOne,
                {
                  styleOverride: function styleOverride() {
                    return '\n                  margin: 0;\n                  ' + (0, _Global.fontStyleOverride)(config.heroHeadingFont) + '\n                ';
                  }
                },
                translations.title
              ),
              translations.subTitle && _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n                    box-sizing: border-box;\n                    border-top: 1px solid #FFFFFF;\n                    max-width: 500px;\n                    ' + (0, _Global.fontStyleOverride)(config.heroSubheadingFont) + '\n                  ';
                  }
                },
                translations.subTitle
              )
            )
          )
        ),
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          vehicles.filter(function (model) {
            return config.modelLinks.map(function (ml) {
              return ml.toLowerCase();
            }).includes(model.model.toLowerCase());
          }).map(function (vehicle, index) {
            return _react2.default.createElement(_ModelSummary2.default, (0, _extends3.default)({
              title: vehicle.modelDisplay || modelDictionary.find(function (ml) {
                return ml.key === vehicle.model.toLowerCase();
              }).value,
              key: vehicle.model
            }, vehicle, {
              findOutMore: function findOutMore() {
                return dispatch(_router.actions.navigate(findOutMoreUrl(vehicle)));
              },
              modelFinanceComponent: vehicle.isModelAvailable && featureFlags.finance ? renderModelFinance(translations.fromText + ' ' + (0, _numbers.localiseCurrency)(vehicle.priceFrom, 'es-ES', 'EUR', 0), vehicle.financePrice && (0, _finance.translateFinanceFromTemplate)(translations.financeSummary, vehicle.finance, marketInfo.locale)) : function () {
                return null;
              },
              financeButtonText: translations.financeButtonText,
              searchButtonText: translations.searchButtonText,
              availableText: '' + (vehicle.isModelAvailable ? vehicle.availability + ' ' + (vehicle.availability >= 2 ? translations.availableText : translations.availableTextSingle) : '' + translations.noAvailableText),
              findOutMoreText: translations.findOutMoreText,
              disclaimerText: translations.disclaimerText,
              alignment: index % 2 === 0 ? 'row-reverse' : 'row',
              searchButtonOnClick: function searchButtonOnClick() {
                return searchClick(vehicle.modelDisplay || vehicle.model);
              },
              showSearchButton: vehicle.isModelAvailable,
              financeButtonOnClick: function financeButtonOnClick() {
                return dispatch(_router.actions.navigate('/financeoptions'));
              },
              globalStyling: globalStyling,
              modelSummaryHeaderFont: config.modelSummaryHeaderFont,
              lamboDescriptionStyling: config.lamboDescriptionStyling,
              showLamboCTAs: config.showLamboCTAs
            }));
          })
        )
      ) : null;
    }
  }]);
  return ExploreRange;
}(_react.Component);

exports.default = ExploreRange;