'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mixins = exports.screenSizeOptions = undefined;

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _styledComponents = require('styled-components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var screenSizeOptions = exports.screenSizeOptions = {
  small: 576,
  medium: 769,
  large: 992,
  extraLarge: 1200,
  xxl: 1536
}; /* eslint-disable */

var generateMediaQueries = function generateMediaQueries(widthType) {
  return (0, _keys2.default)(screenSizeOptions).reduce(function (acc, label) {
    acc[label] = function () {
      return (0, _styledComponents.css)(['@media screen and (', '-width:', 'px){', ';}'], widthType, screenSizeOptions[label], _styledComponents.css.apply(undefined, arguments));
    };
    return acc;
  }, {});
};

var media = {
  min: generateMediaQueries('min'),
  max: generateMediaQueries('max')
};

var arrowComtentTemplate = function arrowComtentTemplate() {
  return '\n  :after {\n    content: \'\';\n    border-style: solid;\n    border-width: 0.15em 0.15em 0 0;\n    display: inline-block;\n    height: 0.45em;\n    left: 0;\n    position: relative;\n    top: 0.4em;\n    transform: rotate(45deg);\n    vertical-align: top;\n    width: 0.45em;\n    margin: -1px 0 0 5px;\n  }\n';
};

var themeTemplate = function themeTemplate(theme) {
  return '\ncolor: ' + theme.colour + ';\ntext-transform: ' + theme.textTransform + ';\nletter-spacing: ' + theme.characterSpacing + ';\nfont-size: ' + theme.fontSize + ';\nfont-family: ' + theme.fontFamily + ';\n';
};

var mixins = exports.mixins = {
  arrowComtentTemplate: arrowComtentTemplate,
  themeTemplate: themeTemplate
};

exports.default = media;