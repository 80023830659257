'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function VdpRemarks(_ref) {
  var content = _ref.content;

  // eslint-disable-next-line react/no-danger
  return _react2.default.createElement('div', { dangerouslySetInnerHTML: { __html: content } });
}

exports.default = VdpRemarks;