'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FormFieldContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FormField__FormFieldContainer',
  componentId: 'sc-7rle7h-0'
})(['position:relative;margin:15px 0;width:100%;display:inline-flex;aline-items:center;border-bottom:solid 0.5px #707070;&:focus{outline:none;}']);

function FormField(_ref) {
  var children = _ref.children;

  return _react2.default.createElement(
    FormFieldContainer,
    null,
    children
  );
}