'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SocialMediaLinks;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _footerTypes = require('../../../types/Genesis/footerTypes');

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SocialLinks = _styledComponents2.default.ul.withConfig({
  displayName: 'SocialMediaLinks__SocialLinks',
  componentId: 'sc-1k78fzu-0'
})(['list-style-type:none;display:flex;flex-wrap:nowrap;padding-left:0;padding-right:0;margin:3px 0 0;']);

var SocialLink = _styledComponents2.default.a.withConfig({
  displayName: 'SocialMediaLinks__SocialLink',
  componentId: 'sc-1k78fzu-1'
})(['padding:0 8px;']);

var IconImage = _styledComponents2.default.img.withConfig({
  displayName: 'SocialMediaLinks__IconImage',
  componentId: 'sc-1k78fzu-2'
})(['width:auto;height:42px;', ' &:hover{color:#cd9a81;}'], function (_ref) {
  var shareIcons = _ref.shareIcons;
  return shareIcons && 'height: 16px;';
});

function SocialMediaLinks(_ref2) {
  var socialMediaIconsList = _ref2.socialMediaIconsList,
      shareIcons = _ref2.shareIcons;

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl;

  return _react2.default.createElement(
    SocialLinks,
    null,
    socialMediaIconsList.map(function (socialMediaItem) {
      return _react2.default.createElement(
        'li',
        { key: socialMediaItem.id },
        _react2.default.createElement(
          SocialLink,
          {
            href: socialMediaItem.url,
            target: getTargetUrl(socialMediaItem.url),
            'data-cy': socialMediaItem.id
          },
          _react2.default.createElement(IconImage, { src: socialMediaItem.icon, shareIcons: shareIcons })
        )
      );
    })
  );
}