'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  padding: 20px;\n  ', ';\n'], ['\n  padding: 20px;\n  ', ';\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 40px;\n    display: flex;\n    flex-direction: column;\n  '], ['\n    padding: 0 40px;\n    display: flex;\n    flex-direction: column;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  ', ';\n'], ['\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  ', ';\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 40px;\n    flex-direction: row;\n  '], ['\n    padding: 40px;\n    flex-direction: row;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n  display: block;\n  ', ';\n'], ['\n  display: block;\n  ', ';\n']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    width: calc(100% - 350px);\n  '], ['\n    width: calc(100% - 350px);\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  ', ';\n'], ['\n  display: flex;\n  ', ';\n']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n  display: none;\n  ', ';\n'], ['\n  display: none;\n  ', ';\n']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n    max-width: 350px;\n  '], ['\n    display: flex;\n    flex-direction: column;\n    max-width: 350px;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    background-color: #dedede;\n    border-bottom: none;\n    width: 100%;\n    display: block;\n  '], ['\n    background-color: #dedede;\n    border-bottom: none;\n    width: 100%;\n    display: block;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _querystring = require('querystring');

var _querystring2 = _interopRequireDefault(_querystring);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _FinanceContainer = require('../../containers/FinanceContainer');

var _FinanceContainer2 = _interopRequireDefault(_FinanceContainer);

var _FilterByCarContainer = require('../../components/FilterByCarContainer');

var _FilterByCarContainer2 = _interopRequireDefault(_FilterByCarContainer);

var _RepresentativeExample = require('../../components/RepresentativeExample');

var _RepresentativeExample2 = _interopRequireDefault(_RepresentativeExample);

var _Global = require('../../components/Global');

var _FooterActionBanner = require('../../components/FooterActionBanner');

var _FooterActionBanner2 = _interopRequireDefault(_FooterActionBanner);

var _MobileSearchPanel = require('../../components/MobileSearchPanel');

var _MobileSearchPanel2 = _interopRequireDefault(_MobileSearchPanel);

var _SearchFiltersContainer = require('../../containers/SearchFiltersContainer');

var _SearchFiltersContainer2 = _interopRequireDefault(_SearchFiltersContainer);

var _searchFilter = require('../../shared/searchFilter');

var _router = require('../../actions/router');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _SearchFilterStickyLocationSearchBar = require('../../components/SearchFilterStickyLocationSearchBar');

var _SearchFilterStickyLocationSearchBar2 = _interopRequireDefault(_SearchFilterStickyLocationSearchBar);

var _searchFiltersHelper = require('../../components/SearchFilters/search-filters-helper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var namedDiv = function namedDiv(displayName) {
  return _styledComponents2.default.div.withConfig({ displayName: displayName });
};

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Search__Wrapper',
  componentId: 'sc-1gf5hs9-0'
})(['display:flex;flex-direction:column;']);
var LeasingContainer = namedDiv('LeasingContainer')(_templateObject, _theme2.default.min.medium(_templateObject2));
var Container = namedDiv('Container')(_templateObject3, _theme2.default.min.medium(_templateObject4));

var RightColumn = namedDiv('RightColumn')(_templateObject5, _theme2.default.min.medium(_templateObject6));

var DesktopOnly = _styledComponents2.default.div.withConfig({
  displayName: 'Search__DesktopOnly',
  componentId: 'sc-1gf5hs9-1'
})(['display:block;', ';'], _theme2.default.max.large(_templateObject7));

var MobileOnly = namedDiv('MobileOnly')(_templateObject8, _theme2.default.min.medium(_templateObject7));
var LeftColumn = namedDiv('LeftColumn')(_templateObject9, _theme2.default.min.medium(_templateObject10));

var Line = _styledComponents2.default.hr.withConfig({
  displayName: 'Search__Line',
  componentId: 'sc-1gf5hs9-2'
})(['display:none;', ';'], _theme2.default.min.medium(_templateObject11));

var Search = function (_Component) {
  (0, _inherits3.default)(Search, _Component);

  function Search(props) {
    (0, _classCallCheck3.default)(this, Search);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Search.__proto__ || (0, _getPrototypeOf2.default)(Search)).call(this, props));

    Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        // We call init to force a first run of the reducer
        // - there used to be a more generic solution,
        //   but it broke the builder
        var _this$props = _this.props,
            init = _this$props.actions.init,
            history = _this$props.history,
            dispatch = _this$props.dispatch,
            router = _this$props.router;

        init();

        var queryParams = _querystring2.default.parse(history.location.search.substring(1));

        _this.retailerId = router.pathProps.retailerId;

        if (queryParams.models) {
          var models = typeof queryParams.models === 'string' ? [queryParams.models] : [].concat((0, _toConsumableArray3.default)(queryParams.models));

          dispatch(_searchFilter.actions.selectMultipleCheckboxes({
            section: 'models',
            values: models
          }));
        }

        _this.getModelGroupsAndInventory('');
      }
    });
    Object.defineProperty(_this, 'getInventoryGroup', {
      enumerable: true,
      writable: true,
      value: function value(url) {
        var actions = _this.props.actions;

        actions.getInventoryGroup({
          filters: url
        });
      }
    });
    Object.defineProperty(_this, 'getModelGroupsAndInventory', {
      enumerable: true,
      writable: true,
      value: function value(url) {
        var actions = _this.props.actions;

        actions.getModelGroupsAndInventory({
          filters: url
        });
      }
    });
    Object.defineProperty(_this, 'toggleModelFilter', {
      enumerable: true,
      writable: true,
      value: function value(models) {
        _this.props.dispatch(_searchFilter.actions.toggleCheckboxFilter({
          section: 'models',
          filter: models
        }));
      }
    });
    Object.defineProperty(_this, 'toggleStickySearchBar', {
      enumerable: true,
      writable: true,
      value: function value(_ref) {
        var currentPosition = _ref.currentPosition;

        if (currentPosition) {
          var _showStickySearch = currentPosition === 'below';
          _this.setState({ showStickySearch: _showStickySearch });
        }
      }
    });
    Object.defineProperty(_this, 'resetFilters', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _this.props.dispatch(_searchFilter.actions.resetFilters());
      }
    });

    _this.state = {
      showStickySearch: true
    };
    return _this;
  }

  (0, _createClass3.default)(Search, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _props = this.props,
          config = _props.config,
          shared = _props.shared;


      if (prevProps.shared.searchFilter.filterSearchUrl !== shared.searchFilter.filterSearchUrl && !shared.searchFilter.filterSearchUrl.checkboxUrl) {
        window.history.replaceState({}, '', window.location.pathname);
      }

      if (!this.props.preview) {
        var modelSearchUrl = this.props.shared.searchFilter.modelSearchUrl;

        if (prevProps.shared.searchFilter.modelSearchUrl !== modelSearchUrl || shared.sessionPreferences.location !== prevProps.shared.sessionPreferences.location) {
          var options = (0, _searchFiltersHelper.distanceOptions)(config.translations);
          var defaultOption = config.distanceFilterInitialSelectedIndex && 'MaxDistance=' + options[config.distanceFilterInitialSelectedIndex].value + '&' || '';

          var _modelSearchUrl$check = modelSearchUrl.checkboxUrl,
              checkboxUrl = _modelSearchUrl$check === undefined ? '' : _modelSearchUrl$check,
              _modelSearchUrl$range = modelSearchUrl.rangeUrl,
              rangeUrl = _modelSearchUrl$range === undefined ? '' : _modelSearchUrl$range,
              _modelSearchUrl$locat = modelSearchUrl.locationUrl,
              locationUrl = _modelSearchUrl$locat === undefined ? '' : _modelSearchUrl$locat,
              _modelSearchUrl$maxDi = modelSearchUrl.maxDistanceUrl,
              maxDistanceUrl = _modelSearchUrl$maxDi === undefined ? defaultOption : _modelSearchUrl$maxDi;


          this.getInventoryGroup('' + [checkboxUrl, rangeUrl, locationUrl, maxDistanceUrl].filter(Boolean).join(''));
        }
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          shared = _props2.shared,
          searchConfig = _props2.state.searchConfig,
          dispatch = _props2.dispatch,
          pathByModule = _props2.pathByModule,
          config = _props2.config,
          preview = _props2.preview,
          marketInfo = _props2.marketInfo,
          globalStyling = _props2.globalStyling,
          featureFlags = _props2.featureFlags;
      var showStickySearch = this.state.showStickySearch;


      var carCount = 0;

      var goToModule = function goToModule(name) {
        return dispatch(_router.actions.navigate(pathByModule(name)));
      };
      var representativeExample = preview ? {} : shared.financeCalculator.representativeExample;

      var ResetBtn = function ResetBtn() {
        return _react2.default.createElement(_Global.Button, {
          onClick: _this2.resetFilters,
          text: config.translations.searchCTAReset,
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          applyStyle: 'secondary'
        });
      };
      var SearchBtn = function SearchBtn() {
        return _react2.default.createElement(_Global.Button, {
          onClick: function onClick() {
            return goToModule('SearchResults');
          },
          text: config.translations.searchCTASearch,
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          applyStyle: 'primary'
        });
      };

      var selectedFilterModels = shared.searchFilter.selectedCheckboxFilters.models;

      if (searchConfig && searchConfig.inventoryGroup.length > 0) {
        carCount = searchConfig.inventoryGroup.filter(function (_ref2) {
          var modelDisplay = _ref2.modelDisplay;
          return selectedFilterModels.length === 0 || selectedFilterModels.some(function (filterModel) {
            return filterModel === modelDisplay;
          });
        }).reduce(function (acc, _ref3) {
          var count = _ref3.count;
          return acc + count;
        }, 0);
      }

      return _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: globalStyling.contentWidth },
        _react2.default.createElement(
          Wrapper,
          null,
          preview ? null : _react2.default.createElement(
            MobileOnly,
            null,
            _react2.default.createElement(_MobileSearchPanel2.default, {
              resetFilters: this.resetFilters,
              goBack: function goBack() {
                return goToModule('Home');
              },
              config: (0, _extends3.default)({}, marketInfo, {
                translations: config.translations,
                stylingConfig: config.stylingConfig,
                filterVisibility: config.filterVisibility,
                distanceFilterInitialSelectedIndex: config.distanceFilterInitialSelectedIndex,
                dropdown: config.dropdown,
                locationIconUrl: config.locationIconUrl
              }),
              buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
              featureFlags: featureFlags
            })
          ),
          _react2.default.createElement(
            LeasingContainer,
            null,
            _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return '\n              text-align: center;\n              margin-top: 34px;\n              margin-bottom: ' + (config.titleBottomMargin || '10') + 'px;\n              display: block;\n              font-family: ' + (globalStyling && globalStyling.fonts.primaryFont.value) + '\n            ';
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{ queryPath: 'max.medium', template: 'display:none;' }];
                }
              },
              config.translations.searchHeader
            ),
            _react2.default.createElement(Line, null),
            featureFlags.finance && config.showFinanceExample && _react2.default.createElement(
              _react.Fragment,
              null,
              _react2.default.createElement(_FinanceContainer2.default, {
                component: function component(props) {
                  return _react2.default.createElement(_RepresentativeExample2.default, (0, _extends3.default)({}, props, {
                    representativeExample: representativeExample,
                    preview: preview
                  }));
                }
              }),
              _react2.default.createElement(Line, null)
            )
          ),
          _react2.default.createElement(
            Container,
            null,
            searchConfig && _react2.default.createElement(
              LeftColumn,
              null,
              preview ? null : _react2.default.createElement(_SearchFiltersContainer2.default, {
                config: (0, _extends3.default)({}, marketInfo, {
                  dropdown: config.dropdown,
                  translations: config.translations,
                  stylingConfig: config.stylingConfig,
                  filterVisibility: config.filterVisibility,
                  locationIconUrl: config.locationIconUrl,
                  distanceFilterInitialSelectedIndex: config.distanceFilterInitialSelectedIndex,
                  searchCountryCode: marketInfo.searchCountryCode
                }),
                featureFlags: featureFlags
              }),
              _react2.default.createElement(_Global.Button, {
                onClick: function onClick() {
                  return goToModule('SearchResults');
                },
                buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
                applyStyle: 'primary',
                text: config.translations.searchCTASearch,
                styleOverride: function styleOverride() {
                  return 'margin: 10px 0;';
                }
              }),
              _react2.default.createElement(_Global.Button, {
                onClick: this.resetFilters,
                buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle,
                applyStyle: 'secondary',
                text: config.translations.searchCTAReset
              })
            ),
            searchConfig && searchConfig.inventoryGroup && _react2.default.createElement(
              RightColumn,
              null,
              _react2.default.createElement(_FilterByCarContainer2.default, {
                cars: searchConfig.inventoryGroup,
                itemClicked: this.toggleModelFilter,
                selectedModels: shared.searchFilter.selectedCheckboxFilters.models,
                translations: config.translations,
                selectedIconColour: config.carFilterSelectedIconColour && config.carFilterSelectedIconColour.value,
                locale: marketInfo.locale,
                titleFont: config.modelTitleFont,
                featureFlags: featureFlags,
                globalStyling: globalStyling,
                fullTileSelect: config.fullTileSelect
              })
            )
          ),
          _react2.default.createElement(
            DesktopOnly,
            null,
            _react2.default.createElement(_SearchFilterStickyLocationSearchBar2.default, {
              count: carCount,
              translations: config.translations,
              dropdownTheme: config.dropdown,
              display: showStickySearch,
              globalStyling: globalStyling,
              pathByModule: pathByModule,
              distanceFilterInitialSelectedIndex: config.distanceFilterInitialSelectedIndex,
              locationIconUrl: config.locationIconUrl,
              searchCountryCode: marketInfo.searchCountryCode,
              fullTileSelect: config.fullTileSelect
            })
          ),
          _react2.default.createElement(_reactWaypoint2.default, { onPositionChange: this.toggleStickySearchBar }),
          _react2.default.createElement(_FooterActionBanner2.default, { leftButton: ResetBtn, rightButton: SearchBtn })
        )
      );
    }
  }]);
  return Search;
}(_react.Component);

exports.default = Search;