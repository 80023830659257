'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.default = RegionalFilter;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _intersection = require('ramda/src/intersection');

var _intersection2 = _interopRequireDefault(_intersection);

var _difference = require('ramda/src/difference');

var _difference2 = _interopRequireDefault(_difference);

var _Global = require('../../components/Global');

var _StyledIcon = require('../../components/StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _RegionCheckboxGroup = require('../../components/RegionCheckboxGroup');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__Container',
  componentId: 'sc-ye9suh-0'
})(['display:flex;flex-direction:column;width:100%;padding:16px;position:relative;box-sizing:border-box;']);


var HeadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__HeadingWrapper',
  componentId: 'sc-ye9suh-1'
})(['padding-bottom:8px;']);

var SelectContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__SelectContainer',
  componentId: 'sc-ye9suh-2'
})(['display:flex;height:40px;cursor:pointer;width:100%;padding:0 8px;border:1px solid #d5d5d5;box-sizing:border-box;align-items:center;justify-content:space-between;']);

var ExpandContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__ExpandContainer',
  componentId: 'sc-ye9suh-3'
})(['width:88.5%;border:1px solid #d5d5d5;box-sizing:border-box;position:absolute;top:', 'px;max-height:210px;overflow-y:auto;background-color:#ffffff;z-index:2;'], function (_ref) {
  var longTitle = _ref.longTitle;
  return longTitle ? 104 : 81;
});

function RegionalFilter(_ref2) {
  var translations = _ref2.translations,
      globalStyling = _ref2.globalStyling,
      filtersStyling = _ref2.filtersStyling,
      availableFilterValues = _ref2.availableFilterValues,
      updateFilters = _ref2.updateFilters,
      filterKey = _ref2.filterKey,
      selectedData = _ref2.selectedData;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      toggleOpen = _useState2[1];

  var regions = availableFilterValues.regions;


  var renderIcon = function renderIcon(toggle, openIcon, closeIcon) {
    return toggle ? _react2.default.createElement(_StyledIcon2.default, { link: openIcon, width: 20, height: 20 }) : _react2.default.createElement(_StyledIcon2.default, { link: closeIcon, width: 20, height: 20 });
  };

  /* formatTitles: If all subregions of a region are selected. We return the region.
  Else we return a list of the selected subregions.
   flattenedTitles flattens formatTitles into a single and sorts it alphabetically.
  */
  var formatTitles = (0, _keys2.default)(regions).map(function (key) {
    var enabledSubregionsForRegion = regions[key].some(function (r) {
      return r.enabled;
    }) && regions[key].filter(function (r) {
      return r.enabled;
    }).map(function (sr) {
      return sr.name;
    });
    var selected = selectedData && selectedData.length > 0 ? selectedData : [];
    var enabledRegionKey = regions[key].some(function (r) {
      return r.enabled;
    }) && [key];

    return (0, _difference2.default)(enabledSubregionsForRegion, selected || []).length === 0 ? enabledRegionKey : (0, _intersection2.default)(enabledSubregionsForRegion, selected);
  }).filter(function (el) {
    return el.length > 0;
  });

  var flattenedTitles = formatTitles.length > 0 ? formatTitles.flat().sort().join(', ') : formatTitles;

  var selectedValues = flattenedTitles.length > 0 ? [flattenedTitles] : translations.regionFilterPlaceholderText;

  var primaryColour = globalStyling && globalStyling.colours && globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value;

  var regionalCheckboxes = (0, _keys2.default)(regions).map(function (key) {
    return _react2.default.createElement(_RegionCheckboxGroup.RegionCheckboxGroup, {
      key: key,
      region: key,
      subregions: regions[key],
      filtersStyling: filtersStyling,
      updateFilters: updateFilters,
      selectedData: selectedData,
      filterKey: filterKey,
      renderIcon: renderIcon
    });
  });
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      HeadingWrapper,
      null,
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)(filtersStyling.filterHeadingFont);
          }
        },
        translations.searchIn
      )
    ),
    _react2.default.createElement(
      SelectContainer,
      {
        onClick: function onClick() {
          return toggleOpen(!open);
        },
        'aria-label': translations.searchIn
      },
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n              ' + (0, _Global.fontStyleOverride)(filtersStyling.filterHeadingFont) + '\n              text-overflow: ellipsis;\n              overflow: hidden;\n              white-space: nowrap;\n            ';
          }
        },
        selectedValues
      ),
      renderIcon(open, filtersStyling.arrowUpIcon || filtersStyling.filterSectionOpenIcon, filtersStyling.arrowDownIcon || filtersStyling.filterSectionCloseIcon)
    ),
    open ? _react2.default.createElement(
      ExpandContainer,
      {
        primaryColour: primaryColour,
        longTitle: translations.searchIn.length > 35
      },
      regionalCheckboxes
    ) : null
  );
}