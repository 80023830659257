'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TableElements = require('./TableElements');

var _Form = require('../Form');

var _vehicleTypes = require('../../../types/AstonMartin/vehicleTypes');

var _useVehicleActions2 = require('../../../hooks/useVehicleActions');

var _useVehicleActions3 = _interopRequireDefault(_useVehicleActions2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BookTestDriveRow = function BookTestDriveRow(_ref) {
  var comparedVehicles = _ref.comparedVehicles,
      buttonLabel = _ref.buttonLabel;

  var _useVehicleActions = (0, _useVehicleActions3.default)(),
      navigateToBookTestDrive = _useVehicleActions.navigateToBookTestDrive;

  return _react2.default.createElement(
    _TableElements.TableRow,
    null,
    _react2.default.createElement(_TableElements.TableDataFirstColumn, null),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        _TableElements.TableData,
        { key: '' + vehicle.id },
        _react2.default.createElement(_Form.PrimaryButton, {
          onClick: function onClick() {
            return vehicle.status !== 'sold' && navigateToBookTestDrive(vehicle.id);
          },
          text: buttonLabel,
          styleOverride: function styleOverride() {
            return '\n              font-size: 14px;\n              padding-left: 0;\n            ';
          }
        })
      );
    })
  );
};

exports.default = BookTestDriveRow;