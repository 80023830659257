'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Icon;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('../../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Icon(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _components.IconWrapper,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 18 18', width: '1em', height: '1em' },
      _react2.default.createElement(
        'g',
        { fill: 'none', fillRule: 'evenodd' },
        _react2.default.createElement('path', { stroke: '#0065DB', d: 'M.5 1.5h17v15H.5z' }),
        _react2.default.createElement('path', {
          fill: '#0065DB',
          d: 'M2 3h14v2H2zM2 6h2v2H2zM2 9h2v2H2zM6 6h2v2H6zM6 9h2v2H6z'
        }),
        _react2.default.createElement('path', { fill: '#D6D6D6', d: 'M2 12h14v3H2z' }),
        _react2.default.createElement('path', {
          fill: '#0065DB',
          d: 'M10 6h2v2h-2zM10 9h2v2h-2zM14 6h2v2h-2zM14 9h2v2h-2z'
        })
      )
    )
  );
}

Icon.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};