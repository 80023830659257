'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _settings = require('../../../shared/selectors/settings');

var _styledComponentFactory = require('../../Global/styledComponentFactory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Base styles for buttons
 */
var BaseButton = (0, _styledComponentFactory.createElement)('button');
var ButtonBaseStyle = (0, _styledComponents2.default)(BaseButton).withConfig({
  displayName: 'PrimaryButton__ButtonBaseStyle',
  componentId: 'sc-1ldjsv4-0'
})(['border:0;background-color:#335b4c;font-family:', ';color:#ffffff;padding:0 20px;height:40px;display:flex;align-items:center;text-transform:uppercase;&:hover{background-color:#113120;}'], function (_ref) {
  var fonts = _ref.fonts;
  return fonts.primaryButtonFont.value;
});

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'PrimaryButton__Label',
  componentId: 'sc-1ldjsv4-1'
})(['font-size:14px;text-transform:uppercase;line-height:14px;']);

/**
 * Primary button styles
 */
var Button = (0, _styledComponents2.default)(ButtonBaseStyle).withConfig({
  displayName: 'PrimaryButton__Button',
  componentId: 'sc-1ldjsv4-2'
})(['']);

/**
 * Here you can create variant.
 */

/**
 * @deprecated Please use Form/Button.tsx with variant.
 */
var PrimaryButton = function PrimaryButton(props) {
  var onClick = props.onClick,
      text = props.text,
      styleOverride = props.styleOverride,
      mobileStyleOverride = props.mobileStyleOverride,
      rest = (0, _objectWithoutProperties3.default)(props, ['onClick', 'text', 'styleOverride', 'mobileStyleOverride']);

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts,
      colours = _useSelector.colours;

  return _react2.default.createElement(
    Button,
    (0, _extends3.default)({
      onClick: onClick,
      fonts: fonts,
      colours: colours,
      styleOverride: styleOverride,
      mobileStyleOverride: mobileStyleOverride && (0, _styledComponentFactory.mediaProps)(mobileStyleOverride())
    }, rest),
    _react2.default.createElement(
      Label,
      null,
      text
    )
  );
};

PrimaryButton.defaultProps = {
  styleOverride: function styleOverride() {
    return '';
  },
  mobileStyleOverride: function mobileStyleOverride() {
    return [];
  },
  text: '',
  fullWidth: false
};

exports.default = PrimaryButton;