'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 5%;\n  '], ['\n    padding: 0 5%;\n  ']);

exports.default = MyAccountContent;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountContent__Container',
  componentId: 'sc-6xei5z-0'
})(['background-color:#f0f0f0;height:auto;min-height:450px;padding:0 130px;width:auto;color:#444444;', ';'], _theme2.default.max.large(_templateObject));

function MyAccountContent(_ref) {
  var children = _ref.children;

  return _react2.default.createElement(
    Container,
    null,
    children
  );
}