'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _filters = require('../../../../shared/filters');

var _FiltersSelection = require('./FiltersSelection');

var _FiltersSelection2 = _interopRequireDefault(_FiltersSelection);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ItemRow = _styledComponents2.default.div.withConfig({
  displayName: 'MultiSelectFilter__ItemRow',
  componentId: 'sc-1yomlwk-0'
})(['align-items:center;border-bottom:1px solid #1e1e1e;color:', ';cursor:', ';display:flex;font:16px \'GenesisSansTextGlobal-Regular\';height:auto;padding:10px 0;text-transform:uppercase;transition:all 0.2s ease-out;&:first-child{padding-top:20px;}', ';'], function (_ref) {
  var isSelected = _ref.isSelected;
  return isSelected ? '#fff' : '#AAABA6';
}, function (_ref2) {
  var disabledStyle = _ref2.disabledStyle;
  return disabledStyle ? 'default' : 'pointer';
}, function (_ref3) {
  var disabledStyle = _ref3.disabledStyle;
  return disabledStyle && 'opacity: .3';
});

var Selection = (0, _styledComponents2.default)(ItemRow).withConfig({
  displayName: 'MultiSelectFilter__Selection',
  componentId: 'sc-1yomlwk-1'
})(['justify-content:space-between;']);

var CountAvailable = _styledComponents2.default.div.withConfig({
  displayName: 'MultiSelectFilter__CountAvailable',
  componentId: 'sc-1yomlwk-2'
})(['border-top:1px solid #999;border-bottom:1px solid #999;font-size:9px;padding:5px 0;white-space:nowrap;']);

var MultiSelectFilter = function MultiSelectFilter(props) {
  var filterKey = props.filterKey,
      data = props.data,
      selectedData = props.selectedData,
      updateFilters = props.updateFilters,
      getDisabledValue = props.getDisabledValue,
      _props$noResultsLabel = props.noResultsLabel,
      noResultsLabel = _props$noResultsLabel === undefined ? '0' : _props$noResultsLabel;


  var filterContent = data.sort();

  return _react2.default.createElement(
    'div',
    null,
    filterContent.map(function (d) {
      var itemSelected = selectedData && selectedData.some(function (sd) {
        return sd === d.value.toString();
      });
      var isDisabled = getDisabledValue(d);

      return _react2.default.createElement(
        Selection,
        {
          key: d.value,
          'data-cy': d.display,
          disabledStyle: isDisabled,
          onClick: function onClick() {
            return !isDisabled && updateFilters([{
              key: filterKey,
              value: _filters.helpers.newArray(selectedData || [], d.value.toString())
            }]);
          },
          isSelected: itemSelected
        },
        _react2.default.createElement(
          'div',
          { style: { display: 'flex', flexDirection: 'row' } },
          _react2.default.createElement(_FiltersSelection2.default, {
            onClick: function onClick() {
              return null;
            } // Selection is performed on parent's.
            , checked: itemSelected,
            enabled: !isDisabled
          }),
          _react2.default.createElement(
            'span',
            { style: { margin: '2px 10px 0 0' } },
            d.display
          )
        ),
        _react2.default.createElement(
          CountAvailable,
          null,
          d.selectedCount || noResultsLabel,
          ' Available'
        )
      );
    })
  );
};

MultiSelectFilter.defaultProps = {
  applyOrdering: function applyOrdering(a, b) {
    if (a.display < b.display) {
      return -1;
    }
    if (a.display > b.display) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }
};

exports.default = MultiSelectFilter;