'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends4 = require('babel-runtime/helpers/extends');

var _extends5 = _interopRequireDefault(_extends4);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormFields = require('./FormFields');

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'FormLocation__Container',
  componentId: 'sc-1kd7yhl-0'
})(['display:flex;flex-direction:column;width:100%;padding:15px;']);

var Title = _styledComponents2.default.h3.withConfig({
  displayName: 'Title'
}).withConfig({
  displayName: 'FormLocation__Title',
  componentId: 'sc-1kd7yhl-1'
})(['text-align:center;text-transform:uppercase;']);

var CloseButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CloseButtonWrapper'
}).withConfig({
  displayName: 'FormLocation__CloseButtonWrapper',
  componentId: 'sc-1kd7yhl-2'
})(['display:flex;cursor:pointer;justify-content:flex-end;']);

var buildKeysWithInitialValues = function buildKeysWithInitialValues(options, initialValues) {
  return options.reduce(function (ac, _ref) {
    var key = _ref.key;
    return (0, _extends5.default)({}, ac, (0, _defineProperty3.default)({}, key, initialValues[key.toLowerCase()]));
  }, {});
};

var FormLocation = function (_React$Component) {
  (0, _inherits3.default)(FormLocation, _React$Component);

  function FormLocation(props) {
    (0, _classCallCheck3.default)(this, FormLocation);

    var _this = (0, _possibleConstructorReturn3.default)(this, (FormLocation.__proto__ || (0, _getPrototypeOf2.default)(FormLocation)).call(this, props));

    var _this$props = _this.props,
        formOptions = _this$props.formOptions,
        initialValues = _this$props.initialValues;

    _this.state = {
      keys: buildKeysWithInitialValues(formOptions, initialValues)
    };
    return _this;
  }

  (0, _createClass3.default)(FormLocation, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          formOptions = _props.formOptions,
          onSubmit = _props.onSubmit,
          title = _props.title,
          confirm = _props.confirm,
          onClose = _props.onClose,
          renderClose = _props.renderClose,
          initialValues = _props.initialValues,
          globalStyling = _props.globalStyling;

      return _react2.default.createElement(
        Container,
        null,
        renderClose && _react2.default.createElement(
          CloseButtonWrapper,
          { onClick: onClose },
          renderClose()
        ),
        _react2.default.createElement(
          Title,
          null,
          title
        ),
        formOptions.map(function (_ref2) {
          var placeholder = _ref2.placeholder,
              key = _ref2.key,
              selectLabel = _ref2.label,
              options = _ref2.options,
              includePlaceholderOption = _ref2.includePlaceholderOption;
          return _react2.default.createElement(_FormFields.SelectField, {
            key: key,
            doubleRow: false,
            type: 'text',
            value: _this2.state.keys[key],
            onChange: function onChange(data) {
              var value = data.value;

              _this2.setState(function (prevState) {
                return {
                  keys: (0, _extends5.default)({}, prevState.keys, (0, _defineProperty3.default)({}, key, value))
                };
              });
            },
            placeholder: !includePlaceholderOption && placeholder,
            label: selectLabel,
            options: options,
            initialValue: initialValues[key.toLowerCase()],
            globalStyling: globalStyling
          });
        }),
        _react2.default.createElement(_Global.Button, {
          buttonStyle: 'ButtonStyle1',
          onClick: function onClick() {
            return onSubmit(_this2.state.keys);
          },
          text: confirm
        })
      );
    }
  }]);
  return FormLocation;
}(_react2.default.Component);

Object.defineProperty(FormLocation, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    onClose: function onClose() {}
  }
});
exports.default = FormLocation;