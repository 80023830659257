'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = VehicleResults;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _NoResults = require('../NoResults');

var _NoResults2 = _interopRequireDefault(_NoResults);

var _searchResultsTypes = require('../../../types/Bentley/searchResultsTypes');

var _VehicleCard = require('../VehicleCard');

var _Pagination = require('./Pagination');

var _Pagination2 = _interopRequireDefault(_Pagination);

var _vehicleTypes = require('../../../types/Bentley/vehicleTypes');

var _SimilarCars = require('./SimilarCars');

var _SimilarCars2 = _interopRequireDefault(_SimilarCars);

var _useDealerInfo2 = require('../../../hooks/useDealerInfo');

var _useDealerInfo3 = _interopRequireDefault(_useDealerInfo2);

var _AppShared = require('../../../types/AppShared');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Results = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleResults__Results',
  componentId: 'sc-10n1xay-0'
})(['width:100%;']);

var ListResults = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleResults__ListResults',
  componentId: 'sc-10n1xay-1'
})(['width:100%;']);

function VehicleResults(_ref) {
  var config = _ref.config,
      results = _ref.results,
      onMoreInfoClick = _ref.onMoreInfoClick,
      onEnquireClick = _ref.onEnquireClick,
      translations = _ref.translations,
      locale = _ref.locale,
      onPageNumberChange = _ref.onPageNumberChange,
      currentPage = _ref.currentPage,
      pageSize = _ref.pageSize,
      totalResults = _ref.totalResults,
      getSimilarCars = _ref.getSimilarCars,
      shared = _ref.shared;

  var vehicles = totalResults ? results[currentPage] : [];

  var _useDealerInfo = (0, _useDealerInfo3.default)(),
      isDealer = _useDealerInfo.isDealer;

  return totalResults > 0 ? _react2.default.createElement(
    Results,
    null,
    _react2.default.createElement(
      ListResults,
      { 'data-cy': 'search-result-vehicle-container' },
      vehicles.map(function (vehicle) {
        return _react2.default.createElement(_VehicleCard.VehicleItem, {
          config: config,
          key: vehicle.id,
          translations: translations,
          onMoreInfoClick: onMoreInfoClick,
          onEnquireClick: onEnquireClick,
          locale: locale,
          vehicle: vehicle,
          shared: shared
        });
      })
    ),
    _react2.default.createElement(_Pagination2.default, {
      pageSize: pageSize,
      currentPage: currentPage,
      results: results,
      totalResults: totalResults,
      onPageNumberChange: onPageNumberChange,
      translations: translations
    })
  ) : _react2.default.createElement(
    Results,
    null,
    _react2.default.createElement(_NoResults2.default, { text: translations.noSearchResults }),
    !isDealer && _react2.default.createElement(_SimilarCars2.default, {
      locale: locale,
      translations: translations,
      config: config,
      getSimilarCars: getSimilarCars,
      onMoreInfoClick: onMoreInfoClick,
      onEnquireClick: onEnquireClick,
      shared: shared
    })
  );
}