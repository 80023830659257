'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 20px;\n  '], ['\n    padding: 0 20px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      width: initial;\n      width: 42px;\n  '], ['\n      width: initial;\n      width: 42px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n     flex-direction: row;\n  '], ['\n     flex-direction: row;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n      width: 78%;\n  '], ['\n      width: 78%;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n      width: 80%;\n  '], ['\n      width: 80%;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n      width: 85%;\n  '], ['\n      width: 85%;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    width: auto;\n    height: 100vh;\n  '], ['\n    width: auto;\n    height: 100vh;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    width: 22%;\n    flex-direction: column;\n    height: 100vh;\n  '], ['\n    display: flex;\n    width: 22%;\n    flex-direction: column;\n    height: 100vh;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n     width: 40%;\n  '], ['\n     width: 40%;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n     width: 45%;\n  '], ['\n     width: 45%;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    height: 100%;\n    flex-wrap: wrap;\n  '], ['\n    height: 100%;\n    flex-wrap: wrap;\n  ']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n  '], ['\n    width: 50%;\n  ']),
    _templateObject13 = (0, _taggedTemplateLiteral3.default)(['\n    width: 33.33%;\n  '], ['\n    width: 33.33%;\n  ']),
    _templateObject14 = (0, _taggedTemplateLiteral3.default)(['\n     width: 100%;\n    '], ['\n     width: 100%;\n    ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _emblaCarouselReact = require('embla-carousel-react');

var _emblaCarouselReact2 = _interopRequireDefault(_emblaCarouselReact);

var _emblaCarouselFade = require('embla-carousel-fade');

var _emblaCarouselFade2 = _interopRequireDefault(_emblaCarouselFade);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _useCheckIsMobileScreenRollsRoyce = require('../../../../hooks/useCheckIsMobileScreenRollsRoyce');

var _useCheckIsMobileScreenRollsRoyce2 = _interopRequireDefault(_useCheckIsMobileScreenRollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UtilityWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleZoomGallery__UtilityWrapper',
  componentId: 'sc-x8thps-0'
})(['display:flex;position:absolute;justify-content:space-between;width:100%;top:calc(50% - 20px);z-index:1;padding:0 5px;', ';& img{cursor:pointer;filter:drop-shadow(1px 1px 2px #000);border-radius:3px;width:22px;height:auto;', ';}'], _RollsRoyce2.default.min.small(_templateObject), _RollsRoyce2.default.min.small(_templateObject2));

var UtilityWrapperItem = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleZoomGallery__UtilityWrapperItem',
  componentId: 'sc-x8thps-1'
})(['display:flex;border-radius:8px;background:rgba(0,0,0,0.7);padding:6px;']);

var SlideCount = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleZoomGallery__SlideCount',
  componentId: 'sc-x8thps-2'
})(['border-radius:8px;background:rgba(0,0,0,0.7);padding:6px 10px;font-family:\'RivieraNights-Regular\';font-size:14px;position:absolute;top:10px;left:10px;color:white;z-index:1;']);

var GalleryWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleZoomGallery__GalleryWrapper',
  componentId: 'sc-x8thps-3'
})(['display:flex;height:100vh;align-items:center;flex-direction:column;', ''], _RollsRoyce2.default.min.medium(_templateObject3));

var MainImageWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleZoomGallery__MainImageWrapper',
  componentId: 'sc-x8thps-4'
})(['position:relative;overflow:hidden;width:100%;margin-top:auto;', ' ', ' ', ''], _RollsRoyce2.default.min.large(_templateObject4), _RollsRoyce2.default.min.extraLarge(_templateObject5), _RollsRoyce2.default.min.xxl(_templateObject6));

var MainImage = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleZoomGallery__MainImage',
  componentId: 'sc-x8thps-5'
})(['height:auto;width:100vw;', ''], _RollsRoyce2.default.min.medium(_templateObject7));

var ThumbnailWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleZoomGallery__ThumbnailWrapper',
  componentId: 'sc-x8thps-6'
})(['overflow:hidden;width:100%;margin-bottom:auto;', ' ', ' ', ''], _RollsRoyce2.default.min.medium(_templateObject8), _RollsRoyce2.default.min.extraLarge(_templateObject9), _RollsRoyce2.default.min.xxl(_templateObject10));

var ThumbnailContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleZoomGallery__ThumbnailContainer',
  componentId: 'sc-x8thps-7'
})(['align-content:flex-start;display:flex;flex-direction:row;justify-content:flex-start;', ''], _RollsRoyce2.default.min.medium(_templateObject11));

var ThumbnailInnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleZoomGallery__ThumbnailInnerWrapper',
  componentId: 'sc-x8thps-8'
})(['background-color:#000;', ' ', ''], _RollsRoyce2.default.min.medium(_templateObject12), _RollsRoyce2.default.min.large(_templateObject13));

var Thumbnail = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleZoomGallery__Thumbnail',
  componentId: 'sc-x8thps-9'
})(['cursor:pointer;opacity:', ';transition:opacity 0.3s,border 0.3s;display:flex;flex-wrap:nowrap;img{width:80px;height:auto;object-fit:cover;aspect-ratio:4 / 3;', '}'], function (props) {
  return props.isSelected ? '1' : '0.5';
}, _RollsRoyce2.default.min.medium(_templateObject14));

var Carousel = function Carousel(_ref) {
  var slides = _ref.slides,
      vehicleInfo = _ref.vehicleInfo;

  var isTouch = (0, _useCheckIsMobileScreenRollsRoyce2.default)('medium');

  var handlePrevious = function handlePrevious() {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  };

  var handleNext = function handleNext() {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  };

  var _useEmblaCarousel = (0, _emblaCarouselReact2.default)({ loop: true }, !isTouch ? [(0, _emblaCarouselFade2.default)()] : []),
      _useEmblaCarousel2 = (0, _slicedToArray3.default)(_useEmblaCarousel, 2),
      emblaRef = _useEmblaCarousel2[0],
      emblaApi = _useEmblaCarousel2[1];

  var _useEmblaCarousel3 = (0, _emblaCarouselReact2.default)({
    containScroll: 'keepSnaps',
    dragFree: true,
    axis: isTouch ? 'x' : 'y'
  }),
      _useEmblaCarousel4 = (0, _slicedToArray3.default)(_useEmblaCarousel3, 2),
      emblaThumbsRef = _useEmblaCarousel4[0],
      emblaThumbsApi = _useEmblaCarousel4[1];

  var _useState = (0, _react.useState)(1),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      selectedIndex = _useState2[0],
      setSelectedIndex = _useState2[1];

  var updateSelectedIndex = (0, _react.useCallback)(function () {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    emblaThumbsApi && emblaThumbsApi.scrollTo(emblaApi.selectedScrollSnap());
  }, [emblaApi, emblaThumbsApi]);

  (0, _react.useEffect)(function () {
    if (!emblaApi) return;

    emblaApi.on('select', updateSelectedIndex);
    updateSelectedIndex();

    return function () {
      emblaApi.off('select', updateSelectedIndex);
    };
  }, [emblaApi, updateSelectedIndex]);

  var handleThumbClick = function handleThumbClick(index) {
    if (!emblaApi) return;
    emblaApi.scrollTo(index);
  };

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      SlideCount,
      null,
      selectedIndex + 1,
      ' / ',
      slides.length
    ),
    _react2.default.createElement(
      GalleryWrapper,
      null,
      _react2.default.createElement(
        MainImageWrapper,
        { ref: emblaRef },
        _react2.default.createElement(
          'div',
          { style: { display: 'flex' } },
          slides.map(function (slide, index) {
            return _react2.default.createElement(
              'div',
              { key: index },
              _react2.default.createElement(MainImage, { src: slide.image, alt: '' })
            );
          })
        ),
        _react2.default.createElement(
          UtilityWrapper,
          null,
          _react2.default.createElement(
            UtilityWrapperItem,
            null,
            _react2.default.createElement('img', {
              src: 'https://res.cloudinary.com/motortrak/v1719420204/locator/rolls-royce/global/icons/zoom-previous.svg',
              alt: 'Previous Image',
              onClick: handlePrevious
            })
          ),
          _react2.default.createElement(
            UtilityWrapperItem,
            null,
            _react2.default.createElement('img', {
              src: 'https://res.cloudinary.com/motortrak/v1719420205/locator/rolls-royce/global/icons/zoom-next.svg',
              alt: 'Next Image',
              onClick: handleNext
            })
          )
        )
      ),
      _react2.default.createElement(
        ThumbnailWrapper,
        { ref: emblaThumbsRef },
        _react2.default.createElement(
          ThumbnailContainer,
          null,
          slides.map(function (thumb, index) {
            return _react2.default.createElement(
              ThumbnailInnerWrapper,
              {
                key: index,
                onClick: function onClick() {
                  return handleThumbClick(index);
                }
              },
              _react2.default.createElement(
                Thumbnail,
                { isSelected: index === selectedIndex },
                _react2.default.createElement('img', { src: thumb.image, alt: vehicleInfo.name })
              )
            );
          })
        )
      )
    )
  );
};

exports.default = Carousel;