'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 4px 8px 4px;\n  '], ['\n    padding: 4px 8px 4px;\n  ']);

exports.default = ModelGridTile;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _ToggleIcon = require('./ToggleIcon');

var _ToggleIcon2 = _interopRequireDefault(_ToggleIcon);

var _numbers = require('../shared/localisation/numbers');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _finance = require('../helpers/finance');

var _FinancePopup = require('./FinancePopup');

var _FinancePopup2 = _interopRequireDefault(_FinancePopup);

var _Global = require('./Global');

var _Spinner = require('./Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _PulseSkeletons = require('./PulseSkeletons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var alignItems = {
  left: 'flext-start',
  center: 'center',
  right: 'flex-end'
};

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelGridTile__IconWrapper',
  componentId: 'sc-nj8xl4-0'
})(['cursor:pointer;']);

var TileItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelGridTile__TileItemContainer',
  componentId: 'sc-nj8xl4-1'
})(['background-color:', ';box-sizing:border-box;display:flex;flex-direction:column;height:100%;padding:', ';width:100%;', ';opacity:', ';'], function (_ref) {
  var backgroundColour = _ref.backgroundColour;
  return backgroundColour && backgroundColour.value || 'transparent';
}, function (_ref2) {
  var padding = _ref2.padding;
  return padding ? '' + padding : '0 0 16px 16px';
}, _theme2.default.max.large(_templateObject), function (_ref3) {
  var modelExistsOrSelected = _ref3.modelExistsOrSelected;
  return modelExistsOrSelected ? '1' : '0.3';
});

var TileContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelGridTile__TileContentWrapper',
  componentId: 'sc-nj8xl4-2'
})(['', ';', ';padding:12px;height:100%;cursor:', ';'], function (_ref4) {
  var borderColour = _ref4.borderColour;
  return borderColour && 'border: 1px solid ' + borderColour.value;
}, function (_ref5) {
  var backgroundColour = _ref5.backgroundColour;
  return backgroundColour && 'background-color: ' + backgroundColour;
}, function (_ref6) {
  var count = _ref6.count;
  return count ? 'pointer' : 'cursor';
});

var TopContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelGridTile__TopContainer',
  componentId: 'sc-nj8xl4-3'
})(['display:flex;justify-content:space-between;']);

var ModelAndPrice = _styledComponents2.default.div.withConfig({
  displayName: 'ModelGridTile__ModelAndPrice',
  componentId: 'sc-nj8xl4-4'
})(['align-self:left;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'ModelGridTile__Image',
  componentId: 'sc-nj8xl4-5'
})(['display:flex;align-self:center;width:100%;']);

var BottomContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelGridTile__BottomContainer',
  componentId: 'sc-nj8xl4-6'
})(['display:flex;flex-direction:column;margin-top:', ';align-items:', ';'], function (_ref7) {
  var marginTop = _ref7.marginTop;
  return (marginTop || -40) + 'px';
}, function (props) {
  return props.align ? alignItems[props.align] : alignItems.left;
});

var FinanceText = _styledComponents2.default.div.withConfig({
  displayName: 'ModelGridTile__FinanceText',
  componentId: 'sc-nj8xl4-7'
})(['display:flex;justify-content:space-between;align-items:center;width:103%;']);

var Gap = _styledComponents2.default.div.withConfig({
  displayName: 'ModelGridTile__Gap',
  componentId: 'sc-nj8xl4-8'
})(['margin-right:16px;']);

function Toggle(_ref8) {
  var onClick = _ref8.onClick,
      isSelected = _ref8.isSelected,
      icon = _ref8.icon,
      iconActive = _ref8.iconActive;

  return isSelected ? _react2.default.createElement(
    IconWrapper,
    { onClick: onClick },
    _react2.default.createElement(_ToggleIcon2.default, { icon: iconActive })
  ) : _react2.default.createElement(
    IconWrapper,
    { onClick: onClick },
    _react2.default.createElement(_ToggleIcon2.default, { icon: icon })
  );
}

var ToggleButton = (0, _styledComponents2.default)(Toggle).withConfig({
  displayName: 'ModelGridTile__ToggleButton',
  componentId: 'sc-nj8xl4-9'
})(['align-self:right;']);

function ModelGridTile(_ref9) {
  var isSelected = _ref9.isSelected,
      align = _ref9.align,
      onIconClick = _ref9.onIconClick,
      vehicleModel = _ref9.vehicleModel,
      translations = _ref9.translations,
      titleFont = _ref9.titleFont,
      featureFlags = _ref9.featureFlags,
      backgroundColour = _ref9.backgroundColour,
      borderColour = _ref9.borderColour,
      showStockNumber = _ref9.showStockNumber,
      showPrice = _ref9.showPrice,
      showSubtitle = _ref9.showSubtitle,
      modelCardStyling = _ref9.modelCardStyling,
      locale = _ref9.locale,
      globalStyling = _ref9.globalStyling,
      titleBottomMargin = _ref9.titleBottomMargin,
      showFinancePopup = _ref9.showFinancePopup,
      loading = _ref9.loading;
  var modelDisplay = vehicleModel.modelDisplay,
      minimumPrice = vehicleModel.minimumPrice,
      count = vehicleModel.count,
      imageUrl = vehicleModel.imageUrl,
      finance = vehicleModel.finance,
      financeLoaded = vehicleModel.financeLoaded,
      modelSubTitle = vehicleModel.modelSubTitle,
      currency = vehicleModel.currency,
      translationKey = vehicleModel.translationKey;


  var FinanceLoadingText = function FinanceLoadingText() {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(_Spinner2.default, { colour: globalStyling.colours.primaryBrandColour, size: 12 }),
      _react2.default.createElement(Gap, null),
      translations.financeLoadingText
    );
  };

  var renderFinance = function renderFinance() {
    if (featureFlags.finance) {
      return finance ? _react2.default.createElement(
        FinanceText,
        null,
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n                margin: 0;\n                font-size: 15px;\n                color: #000000;\n                ' + (0, _Global.fontStyleOverride)(modelCardStyling.modelCardBottomContainer) + '\n              ';
            }
          },
          (0, _finance.translateFinanceFromTemplate)(translations.financeFrom, finance, locale)
        ),
        showFinancePopup && _react2.default.createElement(_FinancePopup2.default, {
          translations: translations,
          data: finance,
          locale: locale
        })
      ) : _react2.default.createElement(
        FinanceText,
        null,
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n                margin: 0;\n                font-size: 15px;\n                color: #000000;\n                line-height: 24px;\n                display: flex;\n                align-items: center;\n              ';
            }
          },
          featureFlags.finance && !financeLoaded && count ? _react2.default.createElement(FinanceLoadingText, null) : '\xA0'
        )
      );
    }
    return null;
  };

  var priceFrom = showPrice && minimumPrice && currency ? (0, _translateFromTemplate.translateFromTemplate)('searchCarFilterFromValue', {
    VALUE: (0, _numbers.localiseCurrency)(minimumPrice, locale, currency, 0)
  }, translations) : '';
  var subtitle = showSubtitle ? '' : ''; // ToDo: add subtitle from car here
  var showSubheader = showSubtitle || showPrice;
  var subheader = '' + subtitle + (showSubtitle && showPrice ? ', ' : '') + (priceFrom || '\xA0');

  var imageToDisplay = imageUrl || globalStyling.placeholderBrandImageUrl;

  var models = (0, _reactRedux.useSelector)(function (_ref10) {
    var shared = _ref10.shared;
    return shared && shared.filters && shared.filters.availableFilterValues ? shared.filters.availableFilterValues.model : [];
  });

  var availableVehicles = (0, _react.useMemo)(function () {
    var result = models.find(function (model) {
      return model.display === modelDisplay;
    }) || {};
    return result.selectedCount;
  }, [models, modelDisplay]);

  var modelExistsOrSelected = availableVehicles === 0 ? false : availableVehicles || isSelected;

  (0, _react.useEffect)(function () {
    if (isSelected && !modelExistsOrSelected) {
      // unselect model
      onIconClick();
    }
  }, [modelExistsOrSelected, isSelected]);

  var modelTitle = translationKey ? translations[translationKey] : modelDisplay;
  return _react2.default.createElement(
    TileItemContainer,
    {
      backgroundColour: backgroundColour,
      padding: modelCardStyling.tilePadding,
      modelExistsOrSelected: modelExistsOrSelected,
      onClick: modelExistsOrSelected ? onIconClick : function () {}
    },
    _react2.default.createElement(
      TileContentWrapper,
      {
        borderColour: borderColour,
        backgroundColour: modelCardStyling && modelCardStyling.modelCardBGColour && modelCardStyling.modelCardBGColour.value,
        count: modelExistsOrSelected
      },
      _react2.default.createElement(
        TopContainer,
        null,
        _react2.default.createElement(
          ModelAndPrice,
          null,
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n                ' + (0, _Global.fontStyleOverride)(titleFont) + '\n                margin-top: 0;\n                ' + (titleBottomMargin && 'margin-bottom:' + titleBottomMargin + 'px') + '\n              ';
              }
            },
            !loading ? modelTitle : _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '0 0 5px 0', width: '50px' })
          ),
          modelSubTitle && _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n                    margin: 0;\n                    font-size: 15px;\n                    color: #000000;\n                    ' + (0, _Global.fontStyleOverride)(modelCardStyling.subtitleFont) + '\n                  ';
              }
            },
            !loading ? modelSubTitle : _react2.default.createElement(_PulseSkeletons.PulseLine, null)
          ),
          showSubheader ? _react2.default.createElement(
            _Global.HeadingThree,
            {
              styleOverride: function styleOverride() {
                return '\n                  margin: 0;\n                  font-size: 16px;\n                  color: #333333;\n                  ' + (0, _Global.fontStyleOverride)(modelCardStyling.subtitleFont) + '\n                ';
              }
            },
            !loading ? subheader : _react2.default.createElement(_PulseSkeletons.PulseLine, null)
          ) : null
        ),
        !loading && _react2.default.createElement(ToggleButton, {
          isSelected: isSelected,
          icon: modelCardStyling.modelSelectIcon ? modelCardStyling.modelSelectIcon.icon : undefined,
          iconActive: modelCardStyling.modelSelectIcon && modelCardStyling.modelSelectIcon.iconActive
        })
      ),
      imageToDisplay && _react2.default.createElement(Image, { src: imageToDisplay }),
      _react2.default.createElement(
        BottomContainer,
        {
          align: align,
          marginTop: modelCardStyling.modelCardBottomContainer && modelCardStyling.modelCardBottomContainer.marginTop
        },
        showStockNumber && _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n              margin: 0;\n              font-size: 16px;\n              ' + (0, _Global.fontStyleOverride)(modelCardStyling.modelCardBottomContainer) + '\n            ';
            }
          },
          !loading ? (0, _translateFromTemplate.translateFromTemplate)(availableVehicles !== 1 ? 'searchCarFilterNumberAvailable' : 'searchCarFilterNumberAvailableSingle', {
            COUNT: availableVehicles
          }, translations) : _react2.default.createElement(_PulseSkeletons.PulseLine, { width: '40px' })
        ),
        renderFinance()
      )
    )
  );
}

ModelGridTile.defaultProps = {
  onClick: function onClick() {},
  modelTheme: {},
  numberAvailableTheme: {},
  financeTextTheme: {},
  selected: false,
  showStockNumber: true,
  showPrice: true,
  showSubtitle: true
};

ToggleButton.defaultProps = {
  icon: 'https://res.cloudinary.com/motortrak/image/upload/v1552917142/cms/global/icons/checkbox-disabled-default.svg',
  iconActive: 'https://res.cloudinary.com/motortrak/image/upload/v1552917142/cms/global/icons/checkbox-enabled-default.svg'
};