'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RetailerInformation;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _VdpRetailer = require('../../components/VdpRetailer');

var _VdpRetailer2 = _interopRequireDefault(_VdpRetailer);

var _Location = require('../../types/Location');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function RetailerInformation(_ref) {
  var config = _ref.config,
      googleAvailable = _ref.shared.google.googleAvailable;

  return _react2.default.createElement(_VdpRetailer2.default, {
    retailer: config.retailerInformation,
    googleAvailable: googleAvailable
  });
}