'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _rcSlider = require('rc-slider');

var _rcSlider2 = _interopRequireDefault(_rcSlider);

require('rc-slider/assets/index.css');

var _mathHelper = require('../../../../utilities/mathHelper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OneHandledRangeFilter__ContentWrapper',
  componentId: 'sc-1r9egqp-0'
})(['padding-bottom:15px;']);


var Label = _styledComponents2.default.div.withConfig({
  displayName: 'OneHandledRangeFilter__Label',
  componentId: 'sc-1r9egqp-1'
})(['display:flex;justify-content:flex-end;align-items:center;font-size:14px;margin-bottom:10px;']);

var HandleContainer = (0, _styledComponents2.default)(_rcSlider.Handle).withConfig({
  displayName: 'OneHandledRangeFilter__HandleContainer',
  componentId: 'sc-1r9egqp-2'
})(['margin-top:-8px;position:absolute;touch-action:pan-x;cursor:grab;outline:none;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OneHandledRangeFilter__IconWrapper',
  componentId: 'sc-1r9egqp-3'
})(['width:20px;height:20px;', ';background-size:cover;'], function (_ref) {
  var src = _ref.src;
  return 'background-image: url(' + src + ')';
});
var calcMaxValue = function calcMaxValue(maxValue, step) {
  return maxValue % step !== 0 ? maxValue + (step - maxValue % step) : maxValue;
};

var OneHandledRangeFilter = function (_Component) {
  (0, _inherits3.default)(OneHandledRangeFilter, _Component);

  function OneHandledRangeFilter(props) {
    (0, _classCallCheck3.default)(this, OneHandledRangeFilter);

    var _this = (0, _possibleConstructorReturn3.default)(this, (OneHandledRangeFilter.__proto__ || (0, _getPrototypeOf2.default)(OneHandledRangeFilter)).call(this, props));

    _initialiseProps.call(_this);

    _this.state = {
      currentValue: props.selectedValue
    };
    return _this;
  }

  return OneHandledRangeFilter;
}(_react.Component);

Object.defineProperty(OneHandledRangeFilter, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    step: 1,
    minValue: 0
  }
});

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  Object.defineProperty(this, 'componentDidUpdate', {
    enumerable: true,
    writable: true,
    value: function value(prevProps) {
      var selectedValue = _this2.props.selectedValue;

      if (prevProps.selectedValue !== selectedValue) {
        _this2.updateSliderValue(selectedValue);
      }
    }
  });
  Object.defineProperty(this, 'updateSliderValue', {
    enumerable: true,
    writable: true,
    value: function value(_value) {
      _this2.setState(function () {
        return { currentValue: _this2.roundValues(_value) };
      });
    }
  });
  Object.defineProperty(this, 'updateFilter', {
    enumerable: true,
    writable: true,
    value: function value() {
      var filterKey = _this2.props.filterKey;
      var currentValue = _this2.state.currentValue;

      _this2.props.updateFilters([{ key: filterKey, value: currentValue }]);
    }
  });
  Object.defineProperty(this, 'roundValues', {
    enumerable: true,
    writable: true,
    value: function value(_value2) {
      var _props = _this2.props,
          step = _props.step,
          minValue = _props.minValue,
          maxValue = _props.maxValue;

      var minRangeValue = Number(minValue) || 0;
      var maxRangeValue = calcMaxValue(Number(maxValue), step);
      return (0, _mathHelper.roundRangeValues)(minRangeValue, maxRangeValue, _value2, _value2, step)[0];
    }
  });
  Object.defineProperty(this, 'render', {
    enumerable: true,
    writable: true,
    value: function value() {
      var _props2 = _this2.props,
          step = _props2.step,
          minValue = _props2.minValue,
          maxValue = _props2.maxValue,
          stylingConfig = _props2.stylingConfig,
          formatter = _props2.formatter;
      var currentValue = _this2.state.currentValue;


      var CustomHandle = function CustomHandle(props) {
        return _react2.default.createElement(
          HandleContainer,
          {
            key: props.index,
            value: props.value,
            offset: props.offset
          },
          _react2.default.createElement(IconWrapper, { src: stylingConfig.filterSliderIcon, alt: '' })
        );
      };

      var calculatedMax = calcMaxValue(maxValue, step);
      return _react2.default.createElement(
        ContentWrapper,
        null,
        _react2.default.createElement(
          Label,
          null,
          formatter(currentValue)
        ),
        _react2.default.createElement(_rcSlider2.default, {
          trackStyle: { backgroundColor: '#000000', height: '1px' },
          handle: CustomHandle,
          step: step,
          value: currentValue,
          min: minValue || 0,
          max: calculatedMax,
          onChange: _this2.updateSliderValue,
          onAfterChange: _this2.updateFilter,
          railStyle: { height: '1px' },
          stepStyle: { height: '1px' }
        })
      );
    }
  });
};

exports.default = OneHandledRangeFilter;


OneHandledRangeFilter.defaultProps = {
  formatter: function formatter(value) {
    return value;
  }
};