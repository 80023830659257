"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getBadge = function getBadge(vehicle, badgeWithStripUrl, badgeWithoutStripUrl, warrantyOdometer, warrantyBadgeMonths, warrantyBadgeMaxMileage) {
  if (vehicle.approved && warrantyOdometer < warrantyBadgeMaxMileage) {
    return vehicle.ageMonths < warrantyBadgeMonths ? badgeWithStripUrl : badgeWithoutStripUrl;
  }
  return null;
};

exports.default = function (_ref) {
  var vehicle = _ref.vehicle,
      badgeWithStripUrl = _ref.badgeWithStripUrl,
      badgeWithoutStripUrl = _ref.badgeWithoutStripUrl,
      warrantyOdometer = _ref.warrantyOdometer,
      warrantyBadgeMonths = _ref.warrantyBadgeMonths,
      warrantyBadgeMaxMileage = _ref.warrantyBadgeMaxMileage;

  var badgeUrl = getBadge(vehicle, badgeWithStripUrl, badgeWithoutStripUrl, warrantyOdometer, warrantyBadgeMonths, warrantyBadgeMaxMileage);
  return badgeUrl ? _react2.default.createElement("img", { src: badgeUrl, alt: "warranty badge" }) : null;
};