'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: auto;\n    margin: 0 auto;\n  '], ['\n    width: auto;\n    margin: 0 auto;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n    justify-content: center;\n  '], ['\n    flex-direction: row;\n    justify-content: center;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _components = require('../components');

var _Global = require('../components/Global');

var _formFields = require('../components/formFields');

var formFields = _interopRequireWildcard(_formFields);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _useForm2 = require('./useForm');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledTextWrapper = (0, _styledComponents2.default)(_components.TextWrapper).withConfig({
  displayName: 'Form__StyledTextWrapper',
  componentId: 'sc-1deuajx-0'
})(['width:100%;box-sizing:border-box;', ';'], _theme2.default.min.medium(_templateObject));

var FormRow = _styledComponents2.default.div.withConfig({
  displayName: 'Form__FormRow',
  componentId: 'sc-1deuajx-1'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.min.medium(_templateObject2));

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      translations = _ref$config.translations,
      fields = _ref$config.fields,
      padding = _ref$config.padding,
      border = _ref$config.border,
      headingFont = _ref$config.headingFont,
      button = _ref$config.button,
      formURL = _ref$config.formURL,
      requireVehicle = _ref$config.requireVehicle,
      config = _ref.config,
      _ref$globalStyling$ui = _ref.globalStyling.uiElements,
      uiElements = _ref$globalStyling$ui === undefined ? {} : _ref$globalStyling$ui;

  var _useForm = (0, _useForm2.useForm)(fields, translations, formURL, requireVehicle),
      formState = _useForm.formState,
      handleBlur = _useForm.handleBlur,
      handleChange = _useForm.handleChange,
      handleSubmit = _useForm.handleSubmit,
      errors = _useForm.errors,
      submissionStatus = _useForm.submissionStatus,
      vehicle = _useForm.vehicle,
      vehicleDisplay = _useForm.vehicleDisplay;

  var FormButton = formFields.FormButton;


  return !vehicle ? null : _react2.default.createElement(
    _components.Wrapper,
    { border: border },
    _react2.default.createElement(
      StyledTextWrapper,
      { padding: padding },
      submissionStatus === 'unsubmitted' && _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          _Global.HeadingOne,
          { styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(headingFont);
            } },
          vehicleDisplay
        ),
        (0, _values2.default)(formState).map(function (field) {
          var FieldComponent = formFields[field.type];
          return FieldComponent && _react2.default.createElement(
            FormRow,
            { key: field.name },
            _react2.default.createElement(FieldComponent, {
              name: field.name,
              id: field.id,
              label: translations[field.id + 'Label'],
              placeholder: translations[field.id + 'Placeholder'],
              value: formState[field.name].value,
              onChange: handleChange,
              onBlur: handleBlur,
              error: errors[field.name],
              required: !!field.validation,
              isDisabled: field.isDisabled,
              options: field.options,
              uiElements: uiElements,
              translations: translations
            })
          );
        }),
        button && _react2.default.createElement(FormButton, { config: config, handleSubmit: handleSubmit })
      ),
      submissionStatus === 'isSubmitting' && _react2.default.createElement(
        'div',
        null,
        'Working'
      ),
      submissionStatus === 'submitted' && _react2.default.createElement(
        'div',
        null,
        'Done'
      )
    )
  );
};