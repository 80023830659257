'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formattedVehicleDescription = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    height: 50%;\n  '], ['\n    width: 100%;\n    height: 50%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      ', '\n  '], ['\n      ', '\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n  display: none;\n'], ['\n  display: none;\n']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    width: 60%;\n    padding: 0 20%;\n    background-color: #000;\n  '], ['\n    width: 60%;\n    padding: 0 20%;\n    background-color: #000;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n  width: 70%;\n  padding: 0 15%;\n'], ['\n  width: 70%;\n  padding: 0 15%;\n']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n  width: 100%;\n  padding: 0;\n  background: none;\n'], ['\n  width: 100%;\n  padding: 0;\n  background: none;\n']);

exports.default = VdpGallery;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _VehicleGallery = require('./VehicleGallery');

var _VehicleGallery2 = _interopRequireDefault(_VehicleGallery);

var _Compare = require('./JaguarIcons/Global/Compare');

var _Compare2 = _interopRequireDefault(_Compare);

var _Heart = require('./JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

var _SocialShare = require('./JaguarIcons/Global/SocialShare');

var _SocialShare2 = _interopRequireDefault(_SocialShare);

var _Global = require('./Global');

var _SocialSharePopup = require('./SocialSharePopup');

var _SocialSharePopup2 = _interopRequireDefault(_SocialSharePopup);

var _vehicle = require('../helpers/vehicle');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _dates = require('../shared/localisation/dates');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGalleryContainer'
}).withConfig({
  displayName: 'VdpGallery__Container',
  componentId: 'sc-1gov6cq-0'
})(['display:flex;flex-direction:column;justify-content:', ';width:', ';max-width:1000px;', ';'], function (_ref) {
  var alignment = _ref.alignment;
  return alignment || 'flex-start';
}, function (_ref2) {
  var galleryWidth = _ref2.galleryWidth;
  return galleryWidth ? galleryWidth + '%;' : '60%;';
}, _theme2.default.max.large(_templateObject));

var VehicleHeaderBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGallery__VehicleHeaderBarContainer',
  componentId: 'sc-1gov6cq-1'
})(['display:flex;justify-content:space-between;padding:', '};', ';'], function (_ref3) {
  var padding = _ref3.padding,
      direction = _ref3.direction;
  return direction === 'rtl' ? '20px 20px 20px 0' : '' + (padding || '20px 0 20px 20px');
}, _theme2.default.max.large(_templateObject2, function (_ref4) {
  var margin = _ref4.margin;
  return margin && 'margin: 0 ' + margin + '%;';
}));

var ButtonsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGallery__ButtonsContainer',
  componentId: 'sc-1gov6cq-2'
})(['display:flex;']);
var SingleButton = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGallery__SingleButton',
  componentId: 'sc-1gov6cq-3'
})(['border-left:', ';padding:', ';', '};cursor:pointer;'], function (props) {
  return props.noBorder ? 'none' : '1px solid #dedede';
}, function (_ref5) {
  var heartMobileNoPadding = _ref5.heartMobileNoPadding;
  return heartMobileNoPadding ? '0' : '0 8px';
}, function (_ref6) {
  var direction = _ref6.direction;
  return direction === 'rtl' && 'border-left: none; border-right: 1px solid #dedede';
});
var ButtonLabel = _styledComponents2.default.span.withConfig({
  displayName: 'VdpGallery__ButtonLabel',
  componentId: 'sc-1gov6cq-4'
})(['font-size:14px;line-height:17px;color:#7e7e7e;']);
var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGallery__IconWrapper',
  componentId: 'sc-1gov6cq-5'
})(['display:flex;div:first-child{margin:auto;}']);
var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGallery__MobileOnly',
  componentId: 'sc-1gov6cq-6'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject3));

var DesktopOnly = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGallery__DesktopOnly',
  componentId: 'sc-1gov6cq-7'
})(['display:block;', ';'], _theme2.default.max.large(_templateObject4));
var MediaWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGallery__MediaWrapper',
  componentId: 'sc-1gov6cq-8'
})(['width:100%;', ' ', ' ', ''], _theme2.default.max.large(_templateObject5), _theme2.default.max.medium(_templateObject6), _theme2.default.max.small(_templateObject7));

var SubHeading = function SubHeading(_ref7) {
  var children = _ref7.children;
  return _react2.default.createElement(
    'div',
    null,
    children.filter(Boolean).join(' | ')
  );
};

// eslint-disable-next-line no-confusing-arrow
var formattedVehicleDescription = exports.formattedVehicleDescription = function formattedVehicleDescription(type, vehicle, shortVehicleTitles, subtitleData) {
  var description = vehicle.description,
      registrationDate = vehicle.registrationDate,
      powerOutput = vehicle.powerOutput,
      transmissionType = vehicle.transmissionType,
      _vehicle$specificatio = vehicle.specification,
      fuelType = _vehicle$specificatio.fuelType,
      odometer = _vehicle$specificatio.odometer,
      locale = vehicle.locale;


  if (subtitleData) {
    return (0, _vehicle.vehicleDataFormatter)(subtitleData, vehicle, locale, ' | ');
  }

  return type === 'VehicleDescription' ? description : _react2.default.createElement(
    SubHeading,
    null,
    shortVehicleTitles ? [transmissionType, fuelType, powerOutput] : [odometer && odometer.display, transmissionType, fuelType, powerOutput, registrationDate && (0, _dates.localeFormattedDate)(registrationDate, locale, {
      month: '2-digit',
      year: 'numeric'
    })]
  );
};
var VehicleSubHeader = function VehicleSubHeader(_ref8) {
  var subHeaderType = _ref8.subHeaderType,
      vehicleInfo = _ref8.vehicleInfo,
      shortVehicleTitles = _ref8.shortVehicleTitles,
      subtitleData = _ref8.subtitleData;
  return _react2.default.createElement(
    _Global.HeadingTwo,
    { styleOverride: function styleOverride() {
        return 'font-size: 14px;';
      } },
    formattedVehicleDescription(subHeaderType, vehicleInfo, shortVehicleTitles, subtitleData)
  );
};

var VehicleHeaderBar = function VehicleHeaderBar(_ref9) {
  var translations = _ref9.translations,
      vehicleInfo = _ref9.vehicleInfo,
      compareVehicle = _ref9.compareVehicle,
      showShareButton = _ref9.showShareButton,
      shortlistVehicle = _ref9.shortlistVehicle,
      shortListedIconColour = _ref9.shortListedIconColour,
      compareIconColour = _ref9.compareIconColour,
      subHeaderType = _ref9.subHeaderType,
      vehicleHeadingFont = _ref9.vehicleHeadingFont,
      vehicleHeadingColour = _ref9.vehicleHeadingColour,
      vehicleHeadingPadding = _ref9.vehicleHeadingPadding,
      vehicleHeadingMargin = _ref9.vehicleHeadingMargin,
      heartMobileNoPadding = _ref9.heartMobileNoPadding,
      socialShare = _ref9.socialShare,
      config = _ref9.config,
      direction = _ref9.direction,
      brandName = _ref9.brandName;
  return _react2.default.createElement(
    VehicleHeaderBarContainer,
    {
      margin: vehicleHeadingMargin,
      padding: vehicleHeadingPadding,
      direction: direction
    },
    _react2.default.createElement(
      'div',
      { 'data-cy': 'vehicle-title-description' },
      _react2.default.createElement(
        _Global.HeadingOne,
        {
          id: 'main-start-here',
          styleOverride: function styleOverride() {
            return '\n          font-size: 25px;\n          font-weight: 600;\n          text-transform: uppercase;\n          ' + (vehicleHeadingColour && 'color: ' + vehicleHeadingColour.value) + ';\n          letter-spacing: ' + (vehicleInfo.finance ? 'inherit' : '0.72px') + ';\n          margin-bottom: ' + (vehicleInfo.finance ? 'auto' : '5px') + ';\n          margin-top:0;\n          ' + (0, _Global.fontStyleOverride)(vehicleHeadingFont) + '\n        ';
          }
        },
        config.hideYear ? config.titleBrandPrefix ? brandName + ' ' + vehicleInfo.description : '' + vehicleInfo.description : vehicleInfo.registration + ' ' + vehicleInfo.description
      ),
      _react2.default.createElement(VehicleSubHeader, {
        subHeaderType: subHeaderType,
        vehicleInfo: vehicleInfo,
        shortVehicleTitles: config.shortVehicleTitles,
        subtitleData: config.subtitleData,
        translations: translations
      })
    ),
    _react2.default.createElement(
      DesktopOnly,
      null,
      _react2.default.createElement(
        ButtonsContainer,
        null,
        _react2.default.createElement(
          SingleButton,
          {
            'data-cy': 'compare-vehicle-button',
            onClick: function onClick() {
              return compareVehicle(vehicleInfo);
            },
            direction: direction
          },
          _react2.default.createElement(
            IconWrapper,
            null,
            _react2.default.createElement(_Compare2.default, { width: '28px', height: '25px', colour: compareIconColour })
          ),
          _react2.default.createElement(
            ButtonLabel,
            null,
            translations.compareActionLabel
          )
        ),
        _react2.default.createElement(
          SingleButton,
          {
            'data-cy': 'shortlist-vehicle-button',
            onClick: function onClick() {
              return shortlistVehicle(vehicleInfo);
            },
            direction: direction
          },
          _react2.default.createElement(
            IconWrapper,
            null,
            _react2.default.createElement(_Heart2.default, { width: '28px', height: '25px', colour: shortListedIconColour })
          ),
          _react2.default.createElement(
            ButtonLabel,
            null,
            translations.shortlistActionLabel
          )
        ),
        showShareButton ? _react2.default.createElement(
          _SocialSharePopup2.default,
          { socialShare: socialShare, config: config },
          _react2.default.createElement(
            SingleButton,
            null,
            _react2.default.createElement(
              IconWrapper,
              null,
              _react2.default.createElement(_SocialShare2.default, { width: '28px', height: '25px', colour: '#ACACAC' })
            ),
            _react2.default.createElement(
              ButtonLabel,
              null,
              translations.socialshareActionLabel
            )
          )
        ) : null
      )
    ),
    _react2.default.createElement(
      MobileOnly,
      null,
      _react2.default.createElement(
        ButtonsContainer,
        null,
        _react2.default.createElement(
          SingleButton,
          {
            heartMobileNoPadding: heartMobileNoPadding,
            noBorder: true,
            onClick: function onClick() {
              return shortlistVehicle(vehicleInfo);
            }
          },
          _react2.default.createElement(
            IconWrapper,
            null,
            _react2.default.createElement(_Heart2.default, { width: '28px', height: '25px', colour: shortListedIconColour })
          )
        ),
        showShareButton ? _react2.default.createElement(
          _SocialSharePopup2.default,
          { socialShare: socialShare, config: config },
          _react2.default.createElement(
            SingleButton,
            null,
            _react2.default.createElement(
              IconWrapper,
              null,
              _react2.default.createElement(_SocialShare2.default, { width: '28px', height: '25px', colour: '#ACACAC' })
            )
          )
        ) : null
      )
    )
  );
};

function VdpGallery(props) {
  var brandName = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.make;
  });
  return _react2.default.createElement(
    Container,
    {
      galleryWidth: props.galleryWidth,
      alignment: props.config.vdpGalleryBottomAlignment
    },
    _react2.default.createElement(VehicleHeaderBar, {
      shortListedIconColour: props.shortListedIconColour,
      compareIconColour: props.compareIconColour,
      translations: props.translations,
      vehicleInfo: props.vehicleInfo,
      compareVehicle: props.compareVehicle,
      shortlistVehicle: props.shortlistVehicle,
      subHeaderType: props.subHeaderType,
      vehicleHeadingFont: props.vehicleHeadingFont,
      vehicleHeadingColour: props.vehicleHeadingColour,
      vehicleHeadingPadding: props.vehicleHeadingPadding,
      vehicleHeadingMargin: props.vehicleHeadingMargin,
      heartMobileNoPadding: props.heartMobileNoPadding,
      showShareButton: props.showShareButton,
      socialShare: props.socialShare,
      config: props.config,
      hideZoomMobile: props.config.hideZoomMobile,
      direction: props.globalStyling.direction,
      brandName: brandName
    }),
    _react2.default.createElement(
      MediaWrapper,
      { 'data-cy': 'vehicle-gallery' },
      _react2.default.createElement(_VehicleGallery2.default, {
        config: props.vehicleImages,
        placeholdingImage: props.placeholdingImage,
        views: props.vehicleInfo.views,
        vehicleInfo: props.vehicleInfo,
        imageCarousel: props.imageCarousel,
        rotateGallery: true,
        isLooping: false,
        hideZoomMobile: props.config.hideZoomMobile,
        translations: props.translations,
        globalStyling: props.globalStyling,
        vehicleGalleryArrowFont: props.vehicleGalleryArrowFont
      })
    ),
    props.translations.galleryFootnote && _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n          ' + (0, _Global.fontStyleOverride)(props.config.galleryFootnoteFont) + ';\n          font-style: italic;\n          padding: 0 16px;\n          ';
        }
      },
      props.translations.galleryFootnote
    )
  );
}