'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formattedVehicleDescription = undefined;
exports.default = VdpGallery;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _VehicleGallery = require('./VehicleGallery');

var _VehicleGallery2 = _interopRequireDefault(_VehicleGallery);

var _vehicle = require('../../helpers/vehicle');

var _dates = require('../../shared/localisation/dates');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGalleryContainer'
}).withConfig({
  displayName: 'VdpGallery__Container',
  componentId: 'sc-2xztag-0'
})(['display:flex;flex-direction:column;align-items:center;justify-content:center;']);


var SubHeading = function SubHeading(_ref) {
  var children = _ref.children;
  return _react2.default.createElement(
    'div',
    null,
    children.filter(Boolean).join(' | ')
  );
};

// eslint-disable-next-line no-confusing-arrow
var formattedVehicleDescription = exports.formattedVehicleDescription = function formattedVehicleDescription(type, vehicle, shortVehicleTitles, subtitleData) {
  var description = vehicle.description,
      registrationDate = vehicle.registrationDate,
      powerOutput = vehicle.powerOutput,
      transmissionType = vehicle.transmissionType,
      _vehicle$specificatio = vehicle.specification,
      fuelType = _vehicle$specificatio.fuelType,
      odometer = _vehicle$specificatio.odometer,
      locale = vehicle.locale;


  if (subtitleData) {
    return (0, _vehicle.vehicleDataFormatter)(subtitleData, vehicle, locale, ' | ');
  }

  return type === 'VehicleDescription' ? description : _react2.default.createElement(
    SubHeading,
    null,
    shortVehicleTitles ? [transmissionType, fuelType, powerOutput] : [odometer && odometer.display, transmissionType, fuelType, powerOutput, registrationDate && (0, _dates.localeFormattedDate)(registrationDate, locale, {
      month: '2-digit',
      year: 'numeric'
    })]
  );
};

function VdpGallery(props) {
  return _react2.default.createElement(
    Container,
    {
      galleryWidth: props.galleryWidth,
      alignment: props.config.vdpGalleryBottomAlignment
    },
    _react2.default.createElement(_VehicleGallery2.default, {
      vehicleImages: props.vehicleImages,
      config: props.config,
      placeholdingImage: props.placeholdingImage,
      views: props.vehicleInfo.views,
      vehicleInfo: props.vehicleInfo,
      imageCarousel: props.imageCarousel,
      rotateGallery: true,
      isLooping: false,
      hideZoomMobile: props.config.hideZoomMobile,
      translations: props.translations,
      globalStyling: props.globalStyling,
      vehicleGalleryArrowFont: props.vehicleGalleryArrowFont
    })
  );
}