'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    border-top: none;\n    padding: 0 0 15px;\n  '], ['\n    border-top: none;\n    padding: 0 0 15px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 25px;\n  '], ['\n    font-size: 25px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 20px;\n  '], ['\n    font-size: 20px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _GermanTaxDisclaimer = require('../CustomCountryComponents/GermanTaxDisclaimer');

var _GermanTaxDisclaimer2 = _interopRequireDefault(_GermanTaxDisclaimer);

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitle__Container',
  componentId: 'sc-i8yjwm-0'
})(['align-items:center;border-top:1px solid rgba(59,59,59,0.4);display:flex;justify-content:space-between;padding:15px 12px;gap:10px;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject));

var VehicleHeading = _styledComponents2.default.h2.withConfig({
  displayName: 'VehicleTitle__VehicleHeading',
  componentId: 'sc-i8yjwm-1'
})(['color:#151515;font-family:\'RivieraNights-Regular\';font-size:14px;text-transform:uppercase;letter-spacing:0.5px;font-weight:normal;cursor:pointer;margin:0;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject2));

var PriceWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitle__PriceWrapper',
  componentId: 'sc-i8yjwm-2'
})(['align-items:flex-end;display:flex;flex-direction:column;', ';'], function (props) {
  return props.netPrice && ' min-width: 190px;';
});

var VehiclePrice = _styledComponents2.default.h2.withConfig({
  displayName: 'VehicleTitle__VehiclePrice',
  componentId: 'sc-i8yjwm-3'
})(['color:#151515;display:flex;font-family:\'RivieraNights-Medium\';font-size:14px;letter-spacing:0.5px;font-weight:normal;cursor:pointer;margin:0;', ';', ';'], function (_ref) {
  var hasZeroPriceAsText = _ref.hasZeroPriceAsText;
  return hasZeroPriceAsText && ' text-transform: uppercase';
}, _RollsRoyce2.default.min.extraLarge(_templateObject3));
var NetPrice = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitle__NetPrice',
  componentId: 'sc-i8yjwm-4'
})(['display:flex;font-family:\'RivieraNights-Medium\';font-size:12px;line-height:28px;letter-spacing:0.5px;']);

var NetPriceDELabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitle__NetPriceDELabel',
  componentId: 'sc-i8yjwm-5'
})(['font-family:\'RivieraNights-Regular\';font-style:italic;padding-left:5px;']);

var VehicleTitle = function VehicleTitle(_ref2) {
  var vehicleName = _ref2.vehicleName,
      onClick = _ref2.onClick,
      price = _ref2.price,
      netPrice = _ref2.netPrice,
      translations = _ref2.translations,
      retailerCountryCode = _ref2.retailerCountryCode,
      showSalesTaxDisclaimer = _ref2.showSalesTaxDisclaimer;

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      VehicleHeading,
      { onClick: onClick },
      vehicleName
    ),
    _react2.default.createElement(
      PriceWrapper,
      { netPrice: netPrice && true },
      _react2.default.createElement(
        VehiclePrice,
        { hasZeroPriceAsText: !netPrice },
        price !== 'Call' && price !== 'Sold' && netPrice ? (0, _translateFromTemplate.translateFromTemplate)('grossPrice', { VALUE: price }, translations) : price,
        _react2.default.createElement(_GermanTaxDisclaimer2.default, {
          countryCode: retailerCountryCode,
          showSalesTaxDisclaimer: showSalesTaxDisclaimer,
          salesTaxDisclaimer: translations.salesTaxDisclaimer
        })
      ),
      price !== 'Call' && price !== 'Sold' && netPrice && _react2.default.createElement(
        NetPrice,
        null,
        (0, _translateFromTemplate.translateFromTemplate)('netPrice', { VALUE: netPrice }, translations),
        retailerCountryCode === 'DE' && _react2.default.createElement(
          NetPriceDELabel,
          null,
          translations.netPriceLabel
        )
      )
    )
  );
};

exports.default = VehicleTitle;