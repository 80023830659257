'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PageTitle;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _PageBanner = require('../../components/AstonMartin/PageBanner');

var _PageBanner2 = _interopRequireDefault(_PageBanner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function PageTitle(_ref) {
  var config = _ref.config;

  return _react2.default.createElement(_PageBanner2.default, { config: config });
}