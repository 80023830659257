'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ShortlistedGridVehicles = require('../../components/ShortlistedGridVehicles');

var _ShortlistedGridVehicles2 = _interopRequireDefault(_ShortlistedGridVehicles);

var _theme = require('../../theme');

var _financeCalculator = require('../../shared/financeCalculator');

var _router = require('../../actions/router');

var _compare = require('../../shared/compare');

var _shortlist = require('../../shared/shortlist');

var _vehicleStore = require('../../shared/selectors/vehicleStore');

var _settings = require('../../shared/sortOptions/settings');

var _sessionPreferences = require('../../shared/sessionPreferences');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _vehicle = require('../../helpers/vehicle');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ShortlistGrid = function (_Component) {
  (0, _inherits3.default)(ShortlistGrid, _Component);

  function ShortlistGrid(props) {
    (0, _classCallCheck3.default)(this, ShortlistGrid);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ShortlistGrid.__proto__ || (0, _getPrototypeOf2.default)(ShortlistGrid)).call(this, props));

    _initialiseProps.call(_this);

    var selectedView = window.innerWidth < _theme.screenSizeOptions.large ? 'GRID' : 'LIST';

    var sortValueDictionary = {
      priceAsc: function priceAsc(a, b) {
        return a.price.value - b.price.value;
      },
      priceDesc: function priceDesc(a, b) {
        return b.price.value - a.price.value;
      },
      mileageAsc: function mileageAsc(a, b) {
        return (a.odometer || { reading: 0 }).reading - (b.odometer || { reading: 0 }).reading;
      },
      mileageDesc: function mileageDesc(a, b) {
        return (b.odometer || { reading: 0 }).reading - (a.odometer || { reading: 0 }).reading;
      },
      ageAsc: function ageAsc(a, b) {
        return b.registration - a.registration;
      },
      ageDesc: function ageDesc(a, b) {
        return a.registration - b.registration;
      }
    };

    _this.state = {
      selectedView: selectedView,
      sortValueDictionary: sortValueDictionary
    };
    return _this;
  }

  (0, _createClass3.default)(ShortlistGrid, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _props = this.props,
          init = _props.actions.init,
          dispatch = _props.dispatch,
          preview = _props.preview,
          shared = _props.shared,
          featureFlags = _props.featureFlags;

      init();
      if (!preview && shared.financeCalculator.representativeExample) {
        dispatch(_financeCalculator.actions.loadRepresentativeExample());
      }
      if (!preview && shared.sessionPreferences.currency) {
        dispatch(_shortlist.actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
          statePath: (0, _vehicleStore.getVehicleStatePath)(featureFlags)
        }));
      }
      window.addEventListener('resize', this.onViewportChange);
      window.scroll(0, 0);
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _props2 = this.props,
          shared = _props2.shared,
          dispatch = _props2.dispatch,
          featureFlags = _props2.featureFlags;

      if (prevProps.shared.currencyConversion.exchangeRates !== shared.currencyConversion.exchangeRates) {
        dispatch(_shortlist.actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
          statePath: (0, _vehicleStore.getVehicleStatePath)(featureFlags)
        }));
      }

      if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language) {
        dispatch(_shortlist.actions.getVehicles());
      }
    }
  }]);
  return ShortlistGrid;
}(_react.Component);

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  Object.defineProperty(this, 'onSortChange', {
    enumerable: true,
    writable: true,
    value: function value(payload, sortField) {
      // payload only used for filters update on VehicleSearchResults
      _this2.props.dispatch(_sessionPreferences.actions.updateSessionPreferences('shortlistSort', _settings.sortOptions[sortField]));
    }
  });
  Object.defineProperty(this, 'onViewportChange', {
    enumerable: true,
    writable: true,
    value: function value() {
      if (window.innerWidth < _theme.screenSizeOptions.extraLarge) {
        _this2.setState(function () {
          return { selectedView: 'GRID' };
        });
      }
      if (window.innerWidth > _theme.screenSizeOptions.extraLarge) {
        _this2.setState(function () {
          return { selectedView: 'LIST' };
        });
      }
    }
  });
  Object.defineProperty(this, 'render', {
    enumerable: true,
    writable: true,
    value: function value() {
      var _state = _this2.state,
          selectedView = _state.selectedView,
          sortValueDictionary = _state.sortValueDictionary;
      var _props3 = _this2.props,
          preview = _props3.preview,
          config = _props3.config,
          actions = _props3.actions,
          shared = _props3.shared,
          dispatch = _props3.dispatch,
          globalStyling = _props3.globalStyling,
          marketInfo = _props3.marketInfo,
          featureFlags = _props3.featureFlags;


      var shortlistVehicles = (0, _vehicleStore.getShortList)(featureFlags);
      var compareVehicles = (0, _vehicleStore.getCompareList)(featureFlags);

      var distanceAsc = _settings.sortOptions.distanceAsc,
          optionsForSort = (0, _objectWithoutProperties3.default)(_settings.sortOptions, ['distanceAsc']);


      var shortlistedVehicles = shortlistVehicles(shared);
      var sharedCompareVehicles = preview ? [] : compareVehicles(shared);
      var sharedShortListedVehicles = shortlistVehicles(shared);

      var onMoreInfoClick = function onMoreInfoClick(name, vehicle) {
        var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, shared.sessionPreferences.language);
        dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
      };

      var onEnquiryClick = function onEnquiryClick(id) {
        dispatch(_router.actions.navigate('/enquiry/' + id));
      };

      var representativeExample = preview ? {} : shared.financeCalculator.representativeExample;

      var isListed = function isListed(id, vehicles) {
        return vehicles.find(function (vehicle) {
          return vehicle.id === (id || {});
        });
      };

      var getCompareIconColour = function getCompareIconColour(id) {
        return isListed(id, sharedCompareVehicles) ? config.compareIconListedColour && config.compareIconListedColour.value : '#ACACAC';
      };

      var getShortListedIconColour = function getShortListedIconColour(id) {
        return isListed(id, sharedShortListedVehicles) ? config.shortlistIconListedColour && config.shortlistIconListedColour.value : '#ACACAC';
      };

      var getSortValue = function getSortValue() {
        var _shared$sessionPrefer = shared.sessionPreferences,
            searchSort = _shared$sessionPrefer.searchSort,
            shortlistSort = _shared$sessionPrefer.shortlistSort;

        return shortlistSort ? shortlistSort.value : searchSort.value;
      };

      return _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: globalStyling.contentWidth },
        _react2.default.createElement(_ShortlistedGridVehicles2.default, {
          config: config,
          vehicles: shortlistedVehicles,
          placeholdingImage: shared.shortlist.placeholdingImage,
          representativeExample: representativeExample,
          sortOptions: optionsForSort,
          sortValue: getSortValue(),
          sortValueDictionary: sortValueDictionary,
          onSortChange: _this2.onSortChange,
          selectedView: selectedView,
          getCompareIconColour: getCompareIconColour,
          getShortListedIconColour: getShortListedIconColour,
          onMoreInfoClick: onMoreInfoClick,
          onEnquiryClick: onEnquiryClick,
          onHeartClick: function onHeartClick(vehicle) {
            return actions.toggleVehicle(vehicle);
          },
          onCloseClick: function onCloseClick(vehicle) {
            return actions.toggleVehicle(vehicle);
          },
          onCompareClick: function onCompareClick(vehicle) {
            return dispatch(_compare.actions.toggleVehicle(vehicle));
          },
          compareVehicle: function compareVehicle(vehicle) {
            return dispatch(_compare.actions.toggleVehicle(vehicle));
          },
          shortlistVehicle: function shortlistVehicle(vehicle) {
            return dispatch(_shortlist.actions.toggleVehicle(vehicle));
          },
          globalStyling: globalStyling,
          locale: marketInfo.locale,
          preview: preview,
          optionalSelectedColour: config.optionalSelectedColour && config.optionalSelectedColour.value,
          optionalSelectedBgColour: config.optionalSelectedBgColour && config.optionalSelectedBgColour.value,
          translations: config.translations
        })
      );
    }
  });
};

exports.default = ShortlistGrid;