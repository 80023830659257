'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

var _thirdPartyIntegrationReducer = require('./thirdPartyIntegrationReducer');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_ENQUIRY';

var constants = exports.constants = (0, _extends3.default)({}, (0, _reducerMacros.moduleConstants)(modulePrefix, ['SUBMIT_MESSAGE', 'SUBMIT_MESSAGE_SUCCESSFUL', 'SUBMIT_MESSAGE_FAILURE', 'RESET_ENQUIRY', 'GET_LOCALE_SUCCESS', 'INIT', 'REQUEST_PRIVACY_POLICY', 'GET_LANGUAGE_OPTIONS', 'GET_LANGUAGE_OPTIONS_SUCCESS']), _thirdPartyIntegrationReducer.constants);

var id = exports.id = 'Enquiry';

var actions = exports.actions = (0, _extends3.default)({
  submitMessage: (0, _reducerMacros.moduleActionWithArgs)(constants.SUBMIT_MESSAGE, id, function (enquiryForm) {
    var preview = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return { enquiryForm: enquiryForm, preview: preview };
  }),
  submitMessageSuccessful: (0, _reducerMacros.moduleAction)(constants.SUBMIT_MESSAGE_SUCCESSFUL, id),
  submitMessageFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.SUBMIT_MESSAGE_FAILURE, id, function (error) {
    return error;
  }),
  resetEnquiry: (0, _reducerMacros.moduleAction)(constants.RESET_ENQUIRY, id),
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  getLocaleSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_LOCALE_SUCCESS, id, function (payload) {
    return payload;
  }),
  requestPrivacyPolicy: (0, _reducerMacros.moduleActionWithArgs)(constants.REQUEST_PRIVACY_POLICY, id, function (payload) {
    return payload;
  }),
  getLanguageOptions: (0, _reducerMacros.moduleAction)(constants.GET_LANGUAGE_OPTIONS, id),
  getLanguageOptionsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_LANGUAGE_OPTIONS_SUCCESS, id, function (languageOptions) {
    return languageOptions;
  })
}, _thirdPartyIntegrationReducer.actions);

var initialState = exports.initialState = {
  error: null,
  submissionStatus: 'NotRequested',
  locale: ''
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  var integratedState = (0, _thirdPartyIntegrationReducer.reducer)(state, action);
  switch (action.type) {
    case constants.SUBMIT_MESSAGE:
      return (0, _extends3.default)({}, integratedState, {
        error: null,
        submissionStatus: 'Requested'
      });
    case constants.SUBMIT_THIRD_PARTY_LEAD:
      return (0, _extends3.default)({}, integratedState, {
        error: null,
        submissionStatus: 'Requested'
      });
    case constants.SUBMIT_MESSAGE_SUCCESSFUL:
      return (0, _extends3.default)({}, integratedState, {
        submissionStatus: 'Successful'
      });
    case constants.SUBMIT_MESSAGE_FAILURE:
      return (0, _extends3.default)({}, integratedState, {
        error: action.payload,
        submissionStatus: 'Failed'
      });
    case constants.RESET_ENQUIRY:
      return (0, _extends3.default)({}, integratedState, {
        error: null,
        submissionStatus: 'NotRequested'
      });
    case constants.GET_LOCALE_SUCCESS:
      return (0, _extends3.default)({}, integratedState, {
        locale: action.payload
      });
    case constants.GET_LANGUAGE_OPTIONS_SUCCESS:
      return (0, _extends3.default)({}, state, {
        languageOptions: action.payload
      });
    default:
      return integratedState;
  }
}