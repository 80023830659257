'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.default = useCookiePolicy;

var _react = require('react');

var _reactRedux = require('react-redux');

var _sessionPreferences = require('../../shared/sessionPreferences');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useCookiePolicy() {
  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      domEl = _useState2[0],
      setDomEl = _useState2[1];

  var _useState3 = (0, _react.useState)(true),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      isVisible = _useState4[0],
      setVisible = _useState4[1];

  var dispatch = (0, _reactRedux.useDispatch)();

  (0, _react.useEffect)(function () {
    var el = document.getElementById('header');

    if (el) {
      setDomEl(el);
    }
  }, []);

  (0, _react.useEffect)(function () {
    if (isVisible === false) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('cookiesAccepted', true));
    }
  }, [isVisible]);

  return {
    domEl: domEl,
    isVisible: isVisible,
    setVisible: setVisible
  };
}