'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    text-align: center;\n  '], ['\n    text-align: center;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: ', ';\n  '], ['\n    display: ', ';\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    '], ['\n    width: 100%;\n    ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display:  ', ';\n    '], ['\n    display:  ', ';\n    ']);

exports.default = SimilarVehicles;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SearchResultTile = require('./SearchResultTile');

var _SearchResultTile2 = _interopRequireDefault(_SearchResultTile);

var _VdpHeader = require('./VdpHeader');

var _VdpHeader2 = _interopRequireDefault(_VdpHeader);

var _Chunky = require('./JaguarIcons/Arrows/Chunky');

var _Chunky2 = _interopRequireDefault(_Chunky);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Compare = require('./JaguarIcons/Global/Compare');

var _Compare2 = _interopRequireDefault(_Compare);

var _Heart = require('./JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarVehiclesContainer'
}).withConfig({
  displayName: 'VdpSimilarVehicles__Container',
  componentId: 'sc-m3nzjw-0'
})(['margin-bottom:20px;padding-top:20px;']);
var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSimilarVehicles__HeaderContainer',
  componentId: 'sc-m3nzjw-1'
})(['width:90%;margin:0 5%;', ';'], _theme2.default.max.large(_templateObject));
var Section = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSimilarVehicles__Section',
  componentId: 'sc-m3nzjw-2'
})(['width:90%;margin:0 5%;display:flex;flex-wrap:wrap;justify-content:', ';align-items:center;'], function (props) {
  return props.alignment;
});

var MobileSection = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSimilarVehicles__MobileSection',
  componentId: 'sc-m3nzjw-3'
})(['', ';'], _theme2.default.min.large(_templateObject2, function (props) {
  return props.hideOnDesktop ? 'none' : 'flex';
}));

var VehicleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleContainer'
}).withConfig({
  displayName: 'VdpSimilarVehicles__VehicleContainer',
  componentId: 'sc-m3nzjw-4'
})(['width:90%;display:flex;justify-content:space-between;', ';'], _theme2.default.max.large(_templateObject3));

var Button = _styledComponents2.default.button.withConfig({
  displayName: 'VdpSimilarVehicles__Button',
  componentId: 'sc-m3nzjw-5'
})(['cursor:pointer;background:none;border:none;display:', ';&:focus{outline:none;}', ';'], function (props) {
  return props.hideOnDesktop ? 'none' : 'flex';
}, _theme2.default.max.large(_templateObject4, function (props) {
  return props.hideOnDesktop ? 'flex' : 'none';
}));

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSimilarVehicles__IconWrapper',
  componentId: 'sc-m3nzjw-6'
})(['margin-right:10px;width:25px;height:25px;cursor:pointer;']);

var CompareIconButton = function CompareIconButton(onCompareClick, colour) {
  return _react2.default.createElement(
    IconWrapper,
    { 'data-cy': 'compare-vehicle-button', onClick: onCompareClick },
    _react2.default.createElement(_Compare2.default, { width: '2em', height: '1.75em', colour: colour })
  );
};

var HeartIconButton = function HeartIconButton(onHeartClick, colour) {
  return _react2.default.createElement(
    IconWrapper,
    { 'data-cy': 'shortlist-vehicle-button', onClick: onHeartClick },
    _react2.default.createElement(_Heart2.default, { width: '2em', height: '1.75em', colour: colour })
  );
};

function SimilarVehicles(_ref) {
  var similarVehicles = _ref.similarVehicles,
      vehiclesInView = _ref.vehiclesInView,
      next = _ref.next,
      previous = _ref.previous,
      nextMobile = _ref.nextMobile,
      previousMobile = _ref.previousMobile,
      totalPages = _ref.totalPages,
      mobileSelected = _ref.mobileSelected,
      mobileCurrentPage = _ref.mobileCurrentPage,
      mobileTotalPages = _ref.mobileTotalPages,
      translations = _ref.translations,
      compareVehicle = _ref.compareVehicle,
      shortlistVehicle = _ref.shortlistVehicle,
      moreInfoClick = _ref.moreInfoClick,
      isListed = _ref.isListed,
      comparedVehicles = _ref.comparedVehicles,
      shortlistedVehicles = _ref.shortlistedVehicles,
      buttonTheme = _ref.buttonTheme,
      placeholdingImage = _ref.placeholdingImage,
      globalStyling = _ref.globalStyling,
      gridTile = _ref.gridTile,
      locale = _ref.locale,
      selectedHeartColour = _ref.selectedHeartColour,
      selectedCompareColour = _ref.selectedCompareColour,
      icon360 = _ref.icon360,
      config = _ref.config;

  var hasMultiplePages = totalPages > 1;
  var hasMultiplePagesMobile = mobileTotalPages > 1;

  return vehiclesInView && similarVehicles && similarVehicles.contents.length > 2 ? _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      HeaderContainer,
      null,
      _react2.default.createElement(
        _VdpHeader2.default,
        { qaHook: 'similar-vehicles-header' },
        translations.vdpSimilarVehiclesHeader
      )
    ),
    _react2.default.createElement(
      Section,
      { alignment: hasMultiplePages ? 'space-between' : 'center' },
      hasMultiplePages && _react2.default.createElement(
        Button,
        { style: { transform: 'rotateY(180deg)' }, onClick: previous },
        _react2.default.createElement(_Chunky2.default, { width: '2em', height: '2em' })
      ),
      _react2.default.createElement(
        VehicleContainer,
        null,
        vehiclesInView.map(function (v, index) {
          var heartColour = isListed(v.id, shortlistedVehicles) ? selectedHeartColour : '#ACACAC';
          var compareColour = isListed(v.id, comparedVehicles) ? selectedCompareColour : '#ACACAC';
          return _react2.default.createElement(_SearchResultTile2.default, {
            align: 'left',
            key: v.id,
            carResult: v,
            onMoreInfoClicked: function onMoreInfoClicked() {
              return moreInfoClick('VDP', v);
            },
            translations: translations,
            renderBottomIcon1: function renderBottomIcon1() {
              return CompareIconButton(function () {
                return compareVehicle(v);
              }, compareColour);
            },
            renderBottomIcon2: function renderBottomIcon2() {
              return HeartIconButton(function () {
                return shortlistVehicle(v);
              }, heartColour);
            },
            mobileSelectedForSimVehicle: mobileSelected === index,
            inSimilarVehicles: true,
            buttonTheme: buttonTheme,
            placeholdingImage: placeholdingImage,
            globalStyling: globalStyling,
            visibility: gridTile.visibility,
            locale: locale,
            icon360: icon360,
            config: config
          });
        })
      ),
      hasMultiplePages && _react2.default.createElement(
        Button,
        { onClick: next },
        _react2.default.createElement(_Chunky2.default, { width: '2em', height: '2em' })
      )
    ),
    _react2.default.createElement(
      MobileSection,
      { hideOnDesktop: true },
      _react2.default.createElement(
        Section,
        { alignment: 'center' },
        hasMultiplePagesMobile && _react2.default.createElement(
          Button,
          {
            style: { transform: 'rotateY(180deg)' },
            onClick: previousMobile,
            hideOnDesktop: true
          },
          _react2.default.createElement(_Chunky2.default, { width: '2em', height: '2em' })
        ),
        mobileCurrentPage + '/' + mobileTotalPages,
        hasMultiplePagesMobile && _react2.default.createElement(
          Button,
          { onClick: nextMobile, hideOnDesktop: true },
          _react2.default.createElement(_Chunky2.default, { width: '2em', height: '2em' })
        )
      )
    )
  ) : null;
}