'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n  '], ['\n    ', ';\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: flex-start;\n  '], ['\n    justify-content: flex-start;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n    margin: 0 5%;\n  '], ['\n    display: block;\n    margin: 0 5%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  '], ['\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    padding-right: 3%;\n    box-sizing: border-box;\n    max-width: 50%;\n  '], ['\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    padding-right: 3%;\n    box-sizing: border-box;\n    max-width: 50%;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    box-sizing: border-box;\n    max-width: 50%;\n    padding-left: 3%;\n  '], ['\n    box-sizing: border-box;\n    max-width: 50%;\n    padding-left: 3%;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n     display: block;\n  '], ['\n     display: block;\n  ']);

exports.default = VdpMyDeal;

var _splitEvery = require('ramda/src/splitEvery');

var _splitEvery2 = _interopRequireDefault(_splitEvery);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _JaguarArrow = require('../../dist/assets/JaguarArrow');

var _JaguarArrow2 = _interopRequireDefault(_JaguarArrow);

var _FinanceContainer = require('../containers/FinanceContainer');

var _FinanceContainer2 = _interopRequireDefault(_FinanceContainer);

var _navigation = require('../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _helpers = require('../shared/currencyConversion/helpers');

var _numbers = require('../shared/localisation/numbers');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _FinanceExample = require('./FinanceExample');

var _FinanceExample2 = _interopRequireDefault(_FinanceExample);

var _FinanceProducts = require('./FinanceProducts/FinanceProducts');

var _FinanceProducts2 = _interopRequireDefault(_FinanceProducts);

var _Global = require('./Global');

var _Close = require('./JaguarIcons/Global/Close');

var _Close2 = _interopRequireDefault(_Close);

var _Calculator = require('./JaguarIcons/VDP/Calculator2');

var _Calculator2 = _interopRequireDefault(_Calculator);

var _Print = require('./JaguarIcons/VDP/Print');

var _Print2 = _interopRequireDefault(_Print);

var _TradeIn = require('./JaguarIcons/VDP/TradeIn2');

var _TradeIn2 = _interopRequireDefault(_TradeIn);

var _Modal = require('./Modal');

var _Modal2 = _interopRequireDefault(_Modal);

var _OpenClose = require('./OpenClose');

var _OpenClose2 = _interopRequireDefault(_OpenClose);

var _Spinner = require('./Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _VdpAccordion = require('./VdpAccordion');

var _VdpAccordion2 = _interopRequireDefault(_VdpAccordion);

var _VdpFeaturedSpecification = require('./VdpFeaturedSpecification');

var _VdpFeaturedSpecification2 = _interopRequireDefault(_VdpFeaturedSpecification);

var _VdpPopupAccordion = require('./VdpPopupAccordion');

var _VdpPopupAccordion2 = _interopRequireDefault(_VdpPopupAccordion);

var _VehicleValuation = require('./VehicleValuation');

var _VehicleValuation2 = _interopRequireDefault(_VehicleValuation);

var _WarrantyBadge = require('./WarrantyBadge');

var _WarrantyBadge2 = _interopRequireDefault(_WarrantyBadge);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'MyDealContainer'
}).withConfig({
  displayName: 'VdpMyDeal__Container',
  componentId: 'sc-infv88-0'
})(['width:100%;box-sizing:border-box;background-color:#fff;display:flex;flex-direction:column;flex-grow:1;justify-content:space-between;', ';'], _theme2.default.min.large(_templateObject, function (_ref) {
  var borderWidth = _ref.borderWidth,
      borderColour = _ref.borderColour;
  return borderWidth && borderColour ? 'border: ' + borderWidth + 'px solid ' + borderColour.value : 'border: 2px solid #ACACAC';
}));

var Header = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__Header',
  componentId: 'sc-infv88-1'
})(['height:45px;display:flex;align-items:center;justify-content:center;', ';box-sizing:border-box;', ';', ';', ';'], function (_ref2) {
  var font = _ref2.font;
  return font && 'font-family: ' + font + ';';
}, function (_ref3) {
  var financeTitle = _ref3.financeTitle;
  return financeTitle && financeTitle.transform ? 'text-transform: ' + financeTitle.transform : 'text-transform: uppercase';
}, function (_ref4) {
  var financeTitle = _ref4.financeTitle;
  return financeTitle && financeTitle.fontSize ? 'font-size: ' + financeTitle.fontSize + 'px;}' : 'font-size: 16px;';
}, _theme2.default.min.large(_templateObject2));

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__IconWrapper',
  componentId: 'sc-infv88-2'
})(['display:flex;align-items:center;cursor:pointer;']);

var OfferPriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__OfferPriceContainer',
  componentId: 'sc-infv88-3'
})(['display:flex;justify-content:space-between;padding-bottom:10px;align-items:flex-end;']);

var TermsConditionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__TermsConditionsContainer',
  componentId: 'sc-infv88-4'
})(['background:#eeeeee;']);

var TermsConditionsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__TermsConditionsWrapper',
  componentId: 'sc-infv88-5'
})(['padding:20px;font-size:11px;']);

var FinanceCellContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__FinanceCellContainer',
  componentId: 'sc-infv88-6'
})(['margin:0 5%;display:flex;padding:10px 0;']);

var FinanceSection = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__FinanceSection',
  componentId: 'sc-infv88-7'
})(['', ';&:first-child{', ';box-sizing:border-box;', ';}&:last-child{padding-left:10px;', ';}'], function (_ref5) {
  var specialOfferStyling = _ref5.specialOfferStyling;
  return 'width: ' + (specialOfferStyling ? 100 : 50) + '%;';
}, function (_ref6) {
  var showBorder = _ref6.showBorder;
  return showBorder && 'border-right: 1px solid #dedede;';
}, function (_ref7) {
  var direction = _ref7.direction;
  return direction === 'rtl' ? 'padding-left: 10px' : 'padding-right: 10px';
}, function (_ref8) {
  var hideHeadings = _ref8.hideHeadings;
  return hideHeadings ? 'padding-left: 0' : 'padding-left: 10px';
});

var FinanceCell = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__FinanceCell',
  componentId: 'sc-infv88-8'
})(['padding:5px 0;&:first-child{', ';box-sizing:border-box;}'], function (_ref9) {
  var showBorder = _ref9.showBorder;
  return showBorder && 'border-bottom: 1px solid #dedede;';
});

var FinanceCellHeader = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__FinanceCellHeader',
  componentId: 'sc-infv88-9'
})(['', ';font-weight:600;', ';', ''], function (_ref10) {
  var priceStyling = _ref10.priceStyling,
      offerPriceStyling = _ref10.offerPriceStyling;
  return 'font-size: ' + (priceStyling ? priceStyling.vdpFontSize : offerPriceStyling ? offerPriceStyling.vdpFontSize : 26) + 'px;';
}, function (_ref11) {
  var financeTitle = _ref11.financeTitle,
      priceStyling = _ref11.priceStyling,
      offerPriceStyling = _ref11.offerPriceStyling;
  return priceStyling ? 'color: ' + priceStyling.colour.value + ';}' : offerPriceStyling ? 'color: ' + offerPriceStyling.colour.value + ';}' : financeTitle && financeTitle.colour ? 'color: ' + financeTitle.colour.value + ';}' : 'color: #444';
}, function (_ref12) {
  var priceStyling = _ref12.priceStyling;
  return priceStyling && 'text-decoration: ' + priceStyling.textDecoration + ';';
});

var FinanceCellLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__FinanceCellLabel',
  componentId: 'sc-infv88-10'
})(['font-size:14px;color:#8b8b8b;']);

var ContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__ContentContainer',
  componentId: 'sc-infv88-11'
})(['display:none;', ';'], _theme2.default.min.large(_templateObject3));

var ContentContainerMobile = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__ContentContainerMobile',
  componentId: 'sc-infv88-12'
})(['margin:0 5%;', ';'], _theme2.default.min.large(_templateObject4));

var ActionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__ActionsContainer',
  componentId: 'sc-infv88-13'
})(['margin:0 5%;padding-bottom:20px;', ';'], _theme2.default.min.large(_templateObject5));

var LeftActionButtons = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__LeftActionButtons',
  componentId: 'sc-infv88-14'
})(['', ';'], _theme2.default.min.large(_templateObject6));

var RightActionButtons = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__RightActionButtons',
  componentId: 'sc-infv88-15'
})(['display:flex;flex-direction:column;flex-grow:1;', ';'], _theme2.default.min.large(_templateObject7));

var ActionButton = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__ActionButton',
  componentId: 'sc-infv88-16'
})(['width:100%;padding:10px 0;display:flex;align-items:center;cursor:pointer;&:first-child{border-bottom:1px solid #dedede;}']);

var ActionButtonText = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__ActionButtonText',
  componentId: 'sc-infv88-17'
})(['padding-left:10px;font-size:15px;font-weight:600;color:#343434;text-transform:none;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;']);

var ModalTitleWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModalTitleWrapper'
}).withConfig({
  displayName: 'VdpMyDeal__ModalTitleWrapper',
  componentId: 'sc-infv88-18'
})(['display:flex;background-color:#f0f0f0;width:100%;align-items:center;']);

var UpdateDealActionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'UpdateDealActionContainer'
}).withConfig({
  displayName: 'VdpMyDeal__UpdateDealActionContainer',
  componentId: 'sc-infv88-19'
})(['display:flex;width:93%;justify-content:flex-end;margin:0 auto 50px;']);

var CloseButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CloseButtonWrapper'
}).withConfig({
  displayName: 'VdpMyDeal__CloseButtonWrapper',
  componentId: 'sc-infv88-20'
})(['display:flex;justify-content:center;cursor:pointer;width:10%;']);

var FinanceTabContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceTabContainer'
}).withConfig({
  displayName: 'VdpMyDeal__FinanceTabContainer',
  componentId: 'sc-infv88-21'
})(['height:40px;align-items:center;display:flex;']);

var FinanceTab = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceTab'
}).withConfig({
  displayName: 'VdpMyDeal__FinanceTab',
  componentId: 'sc-infv88-22'
})(['text-align:center;flex:1 1 0%;height:100%;display:flex;align-items:center;justify-content:center;font-size:16px;font-weight:600;background:', ';color:', ';cursor:pointer;'], function (_ref13) {
  var selected = _ref13.selected;
  return selected ? '#ffffff' : '#f0f0f0';
}, function (_ref14) {
  var fontColour = _ref14.fontColour;
  return '' + fontColour;
});

var ItemHeader = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__ItemHeader',
  componentId: 'sc-infv88-23'
})(['color:#444444;text-transform:none;font-weight:600;font-size:14px;margin-bottom:5px;white-space:pre-line;']);

var WarrantyBadgeContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__WarrantyBadgeContainer',
  componentId: 'sc-infv88-24'
})(['padding:10px 0;text-align:right;width:100%;']);

var FeaturesSpecContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__FeaturesSpecContainer',
  componentId: 'sc-infv88-25'
})(['margin:0 5%;']);

var FinanceSpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__FinanceSpinnerWrapper',
  componentId: 'sc-infv88-26'
})(['display:flex;padding:0 15px;justify-content:center;align-items:center;height:100%;']);

var FinanceLoadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__FinanceLoadingWrapper',
  componentId: 'sc-infv88-27'
})(['margin:10px;']);

var DealerInformation = _styledComponents2.default.div.withConfig({
  displayName: 'VdpMyDeal__DealerInformation',
  componentId: 'sc-infv88-28'
})(['padding:15px;display:none;', ';'], _theme2.default.min.large(_templateObject8));

var AddressLine = _styledComponents2.default.address.withConfig({
  displayName: 'VdpMyDeal__AddressLine',
  componentId: 'sc-infv88-29'
})(['font-style:normal;']);

var PhoneLink = _styledComponents2.default.a.withConfig({
  displayName: 'VdpMyDeal__PhoneLink',
  componentId: 'sc-infv88-30'
})(['border-top:1px solid rgb(213,213,213);display:block;', ';font-weight:normal;padding:10px 0;margin-top:10px;text-decoration:none;'], function (_ref15) {
  var fontStyles = _ref15.fontStyles;
  return fontStyles && fontStyles;
});

function VdpMyDeal(_ref16) {
  var vehicleInfo = _ref16.vehicleInfo,
      translations = _ref16.translations,
      finance = _ref16.finance,
      downpaymentRange = _ref16.downpaymentRange,
      termsRange = _ref16.termsRange,
      financeProductExample = _ref16.financeProductExample,
      requestFinanceProductUpdate = _ref16.requestFinanceProductUpdate,
      updateMyDeal = _ref16.updateMyDeal,
      updateMyDealWithPartExchange = _ref16.updateMyDealWithPartExchange,
      saveMyDeal = _ref16.saveMyDeal,
      printMyDeal = _ref16.printMyDeal,
      iconColour = _ref16.iconColour,
      getValuationMakes = _ref16.getValuationMakes,
      valuationMakes = _ref16.valuationMakes,
      getValuationModels = _ref16.getValuationModels,
      valuationModels = _ref16.valuationModels,
      getValuationVersions = _ref16.getValuationVersions,
      valuationVersions = _ref16.valuationVersions,
      getValuationYears = _ref16.getValuationYears,
      valuationYears = _ref16.valuationYears,
      getValuationMonths = _ref16.getValuationMonths,
      valuationMonths = _ref16.valuationMonths,
      getVehicleValuation = _ref16.getVehicleValuation,
      vehicleValuation = _ref16.vehicleValuation,
      vehicleValuationRequestLimit = _ref16.vehicleValuationRequestLimit,
      editVehicleValuation = _ref16.editVehicleValuation,
      cancelEditVehicleValuation = _ref16.cancelEditVehicleValuation,
      valuationError = _ref16.valuationError,
      featureFlags = _ref16.featureFlags,
      globalStyling = _ref16.globalStyling,
      hasFinance = _ref16.hasFinance,
      financeLoaded = _ref16.financeLoaded,
      locale = _ref16.locale,
      resetFinanceProduct = _ref16.resetFinanceProduct,
      financeProducts = _ref16.financeProducts,
      context = _ref16.context,
      financeCellData = _ref16.financeCellData,
      printing = _ref16.printing,
      financeTranslations = _ref16.financeTranslations,
      showFinanceDescriptions = _ref16.showFinanceDescriptions,
      financePricingTable = _ref16.financePricingTable,
      hideActionButtons = _ref16.hideActionButtons,
      featuredSpec = _ref16.featuredSpec,
      badgeWithStripUrl = _ref16.badgeWithStripUrl,
      badgeWithoutStripUrl = _ref16.badgeWithoutStripUrl,
      warrantyBadgeMonths = _ref16.warrantyBadgeMonths,
      warrantyBadgeMaxMileage = _ref16.warrantyBadgeMaxMileage,
      featureSpecificationData = _ref16.featureSpecificationData,
      convertedKwToHp = _ref16.convertedKwToHp,
      config = _ref16.config;
  var currency = vehicleInfo.price.currency;
  var retailerInformation = vehicleInfo.retailerInformation;

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      rates: state.shared.currencyConversion.exchangeRates[currency] ? state.shared.currencyConversion.exchangeRates[currency].rates : null,
      secondaryCurrency: state.config.config.global.inventory.secondaryCurrency || null
    };
  }),
      rates = _useSelector.rates,
      secondaryCurrency = _useSelector.secondaryCurrency;

  var financeColumns = (0, _splitEvery2.default)(2, financeCellData);

  var financeCellTextNoDigits = function financeCellTextNoDigits(priceEntry, curCurrency) {
    return (0, _numbers.localiseCurrency)(priceEntry, locale, curCurrency, 0) || translations.searchResultsNotAvailableLabel;
  };

  var FinanceLoadingText = function FinanceLoadingText() {
    return _react2.default.createElement(
      FinanceSpinnerWrapper,
      null,
      _react2.default.createElement(_Spinner2.default, { colour: globalStyling.colours.primaryBrandColour }),
      _react2.default.createElement(
        FinanceLoadingWrapper,
        null,
        translations.financeLoadingText
      )
    );
  };

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl;

  return _react2.default.createElement(
    Container,
    {
      'data-cy': 'my-deal',
      borderWidth: financePricingTable && financePricingTable.borderWidth,
      borderColour: financePricingTable && financePricingTable.borderColour
    },
    _react2.default.createElement(
      _Spinner.SpinnerWrapper,
      {
        loaded: financeLoaded || true,
        globalStyling: globalStyling,
        label: translations.financeLoadingText
      },
      function () {
        return _react2.default.createElement(
          _react.Fragment,
          null,
          financePricingTable && financePricingTable.hideFinanceHeadings ? _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(
              FinanceCellContainer,
              { 'data-cy': 'pricing-and-apr' },
              _react2.default.createElement(
                FinanceSection,
                {
                  hideHeadings: financePricingTable && financePricingTable.hideFinanceHeadings,
                  direction: globalStyling.direction,
                  specialOfferStyling: config.specialOfferStyling && config.specialOfferStyling
                },
                vehicleInfo.price.previousValue ? _react2.default.createElement(
                  'div',
                  null,
                  _react2.default.createElement(
                    OfferPriceContainer,
                    null,
                    _react2.default.createElement(
                      FinanceCell,
                      null,
                      _react2.default.createElement(
                        FinanceCellHeader,
                        {
                          financeTitle: financePricingTable && financePricingTable.financeTitle,
                          priceStyling: config.specialOfferStyling.priceStyle
                        },
                        financeCellTextNoDigits(vehicleInfo.price.previousValue && Number(vehicleInfo.price.previousValue), vehicleInfo.price.currency)
                      ),
                      secondaryCurrency && rates && _react2.default.createElement(
                        _react.Fragment,
                        null,
                        _react2.default.createElement(
                          FinanceCellHeader,
                          null,
                          financeCellTextNoDigits((0, _helpers.convertSecondaryPriceCurrency)(vehicleInfo.price.value, rates, vehicleInfo.price.currency, secondaryCurrency), secondaryCurrency)
                        ),
                        _react2.default.createElement(
                          FinanceCellLabel,
                          null,
                          (0, _translateFromTemplate.translateFromTemplate)('exchangeRate', {
                            EXCHANGERATE: rates[secondaryCurrency].toFixed(2)
                          }, translations)
                        )
                      ),
                      !secondaryCurrency && _react2.default.createElement(
                        FinanceCellLabel,
                        null,
                        financeTranslations && financeTranslations.financeVehiclePrice || translations.searchResultsPriceLabel
                      )
                    ),
                    _react2.default.createElement(
                      FinanceCell,
                      null,
                      _react2.default.createElement(
                        FinanceCellHeader,
                        {
                          financeTitle: financePricingTable && financePricingTable.financeTitle,
                          offerPriceStyling: config.specialOfferStyling.offerPriceStyle
                        },
                        financeCellTextNoDigits(vehicleInfo.price.value && Number(vehicleInfo.price.value), vehicleInfo.price.currency)
                      ),
                      secondaryCurrency && rates && _react2.default.createElement(
                        _react.Fragment,
                        null,
                        _react2.default.createElement(
                          FinanceCellHeader,
                          null,
                          financeCellTextNoDigits((0, _helpers.convertSecondaryPriceCurrency)(vehicleInfo.price.value, rates, vehicleInfo.price.currency, secondaryCurrency), secondaryCurrency)
                        ),
                        _react2.default.createElement(
                          FinanceCellLabel,
                          null,
                          (0, _translateFromTemplate.translateFromTemplate)('exchangeRate', {
                            EXCHANGERATE: rates[secondaryCurrency].toFixed(2)
                          }, translations)
                        )
                      ),
                      !secondaryCurrency && _react2.default.createElement(
                        _Global.Div,
                        {
                          styleOverride: function styleOverride() {
                            return '\n                                ' + (0, _Global.fontStyleOverride)(config.specialOfferStyling.offerTextStyle) + '\n                              ';
                          }
                        },
                        translations.specialOfferPrice
                      )
                    )
                  ),
                  _react2.default.createElement(
                    TermsConditionsContainer,
                    null,
                    _react2.default.createElement(
                      TermsConditionsWrapper,
                      null,
                      _react2.default.createElement(
                        _Global.HeadingThree,
                        {
                          styleOverride: function styleOverride() {
                            return '\n\n                            ';
                          }
                        },
                        translations.offerTermModalTitle
                      ),
                      _react2.default.createElement(
                        _Global.Paragraph,
                        {
                          styleOverride: function styleOverride() {
                            return '\n                                color: #7E7E7E;\n                                white-space: pre-line;\n                                line-height: 17px;\n                                margin: 0;\n                                margin-bottom: 1.5em;\n                              ';
                          }
                        },
                        vehicleInfo.offer.termsConditions
                      ),
                      _react2.default.createElement(
                        'a',
                        {
                          href: vehicleInfo.offer.detailsUrl,
                          target: getTargetUrl(vehicleInfo.offer.detailsUrl),
                          style: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            color: '#444444',
                            textDecoration: 'none',
                            fontSize: '11px',
                            padding: '0px 0px 5px'
                          }
                        },
                        _react2.default.createElement(
                          IconWrapper,
                          null,
                          _react2.default.createElement(_JaguarArrow2.default, {
                            width: '1em',
                            height: '1em',
                            colour: config.specialOfferStyling.offerPriceStyle.colour.value || '#9E1B32'
                          })
                        ),
                        _react2.default.createElement(
                          'span',
                          null,
                          translations.offerTermModalLinkText
                        )
                      )
                    )
                  )
                ) : _react2.default.createElement(
                  FinanceCell,
                  null,
                  _react2.default.createElement(
                    FinanceCellHeader,
                    {
                      financeTitle: financePricingTable && financePricingTable.financeTitle
                    },
                    financeCellTextNoDigits(vehicleInfo.price.value && Number(vehicleInfo.price.value), vehicleInfo.price.currency)
                  ),
                  secondaryCurrency && rates && _react2.default.createElement(
                    _react.Fragment,
                    null,
                    _react2.default.createElement(
                      FinanceCellHeader,
                      null,
                      financeCellTextNoDigits((0, _helpers.convertSecondaryPriceCurrency)(vehicleInfo.price.value, rates, vehicleInfo.price.currency, secondaryCurrency), secondaryCurrency)
                    ),
                    _react2.default.createElement(
                      FinanceCellLabel,
                      null,
                      (0, _translateFromTemplate.translateFromTemplate)('exchangeRate', {
                        EXCHANGERATE: rates[secondaryCurrency].toFixed(2)
                      }, translations)
                    )
                  ),
                  !secondaryCurrency && _react2.default.createElement(
                    FinanceCellLabel,
                    null,
                    translations && translations.priceIncludesVat ? (0, _translateFromTemplate.translateFromTemplate)('priceIncludesVat', {
                      VALUE: retailerInformation.countryCode === 'BE' ? translations.vatRateBelgium : retailerInformation.countryCode === 'LU' ? translations.vatRateLuxembourg : '%'
                    }, translations) : financeTranslations && financeTranslations.financeVehiclePrice || translations.searchResultsPriceLabel
                  ),
                  vehicleInfo.sectionSalesTaxDisclaimer === 1 && _react2.default.createElement(
                    FinanceCellLabel,
                    null,
                    translations.differentialTaxation
                  ),
                  _react2.default.createElement(
                    WarrantyBadgeContainer,
                    null,
                    _react2.default.createElement(_WarrantyBadge2.default, {
                      vehicle: vehicleInfo,
                      badgeWithStripUrl: badgeWithStripUrl,
                      badgeWithoutStripUrl: badgeWithoutStripUrl,
                      warrantyOdometer: vehicleInfo.specification.odometer.reading,
                      warrantyBadgeMonths: warrantyBadgeMonths,
                      warrantyBadgeMaxMileage: warrantyBadgeMaxMileage
                    })
                  )
                )
              )
            ),
            translations.vatDisclaimer && _react2.default.createElement(
              FinanceCellContainer,
              null,
              _react2.default.createElement(
                _Global.Paragraph,
                { styleOverride: function styleOverride() {
                    return 'font-size: 12px;';
                  } },
                translations.vatDisclaimer
              )
            )
          ) : _react2.default.createElement(
            FinanceCellContainer,
            { 'data-cy': 'pricing-and-apr' },
            financeColumns.map(function (col) {
              return _react2.default.createElement(
                FinanceSection,
                { showBorder: financeLoaded },
                col.map(function (item) {
                  return _react2.default.createElement(
                    FinanceCell,
                    { showBorder: financeLoaded },
                    _react2.default.createElement(
                      FinanceCellHeader,
                      {
                        financeTitle: financePricingTable && financePricingTable.financeTitle
                      },
                      item.value
                    ),
                    _react2.default.createElement(
                      FinanceCellLabel,
                      null,
                      item.label
                    )
                  );
                })
              );
            })
          ),
          _react2.default.createElement(
            ContentContainer,
            null,
            hasFinance && !featuredSpec && _react2.default.createElement(
              Header,
              {
                font: globalStyling.fonts.primaryFont && globalStyling.fonts.primaryFont.value,
                financeTitle: financePricingTable && financePricingTable.financeTitle
              },
              translations.myDealHeader
            ),
            _react2.default.createElement(_FinanceContainer2.default, {
              component: function component(props) {
                return hasFinance && financeLoaded && !featuredSpec && _react2.default.createElement(
                  _react.Fragment,
                  null,
                  _react2.default.createElement(
                    ItemHeader,
                    null,
                    props.translateFinanceTemplate('financeMyDealTitle', {
                      PRODUCT: props.finance.translations['finance' + context + 'Label']
                    })
                  ),
                  _react2.default.createElement(_FinanceExample2.default, (0, _extends3.default)({}, props, {
                    data: finance,
                    singleColumn: true,
                    labelFontOverride: financePricingTable && financePricingTable.infoLabelFont,
                    valueFontOverride: financePricingTable && financePricingTable.infoValueFont,
                    financePricingTable: financePricingTable
                  }))
                );
              }
            })
          ),
          hasFinance && _react2.default.createElement(
            ContentContainerMobile,
            null,
            _react2.default.createElement(
              _VdpAccordion2.default,
              { title: translations.showFinanceInformation },
              _react2.default.createElement(_FinanceContainer2.default, {
                component: function component(props) {
                  return _react2.default.createElement(
                    _react.Fragment,
                    null,
                    _react2.default.createElement(
                      ItemHeader,
                      null,
                      props.translateFinanceTemplate('financeMyDealTitle', {
                        PRODUCT: props.finance.translations['finance' + context + 'Label']
                      })
                    ),
                    _react2.default.createElement(_FinanceExample2.default, (0, _extends3.default)({}, props, {
                      data: finance,
                      singleColumn: true
                    }))
                  );
                }
              })
            )
          ),
          featuredSpec && _react2.default.createElement(
            FeaturesSpecContainer,
            null,
            _react2.default.createElement(_VdpFeaturedSpecification2.default, {
              translations: translations,
              featuredSpec: featuredSpec,
              vehicleInfo: vehicleInfo,
              locale: locale,
              dataConfig: featureSpecificationData,
              convertedKwToHp: convertedKwToHp
            }),
            _react2.default.createElement(
              DealerInformation,
              null,
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n                      ' + (0, _Global.fontStyleOverride)(featuredSpec.title) + '\n                    ';
                  }
                },
                vehicleInfo.retailerInformation.name
              ),
              [].concat((0, _toConsumableArray3.default)(vehicleInfo.retailerInformation.address), [config.retailerMapDetails.visibility.showLineAddressPostCode && vehicleInfo.retailerInformation.postcode]).map(function (line) {
                return _react2.default.createElement(
                  AddressLine,
                  null,
                  line
                );
              }),
              _react2.default.createElement(
                PhoneLink,
                {
                  href: 'tel:' + vehicleInfo.retailerInformation.phone,
                  fontStyles: (0, _Global.fontStyleOverride)(featuredSpec.infoLabelFont)
                },
                translations.vdpRetailerPhoneLabel,
                ': ',
                vehicleInfo.retailerInformation.phone
              )
            )
          ),
          !hideActionButtons && hasFinance && _react2.default.createElement(
            ActionsContainer,
            null,
            _react2.default.createElement(
              LeftActionButtons,
              null,
              _react2.default.createElement(
                _OpenClose2.default,
                null,
                function (_ref17) {
                  var open = _ref17.open,
                      openClose = _ref17.openClose;
                  return _react2.default.createElement(
                    'div',
                    null,
                    open ? _react2.default.createElement(
                      _Modal2.default,
                      {
                        overlay: true,
                        onClickOutside: function onClickOutside() {
                          cancelEditVehicleValuation();
                          openClose();
                        }
                      },
                      _react2.default.createElement(
                        ModalTitleWrapper,
                        null,
                        _react2.default.createElement(
                          _Global.HeadingTwo,
                          {
                            styleOverride: function styleOverride() {
                              return '\n                                  display: flex;\n                                  justify-content: center;\n                                  width: 80%;\n                                  margin-left: 10%;\n                                  padding: 20px 0;\n                                  text-transform: uppercase;\n                                  font-weight: 600;\n                                  font-size: 18px;\n                                ';
                            }
                          },
                          translations.addPartExchangeTitle
                        ),
                        _react2.default.createElement(
                          CloseButtonWrapper,
                          {
                            onClick: function onClick() {
                              cancelEditVehicleValuation();
                              openClose();
                            }
                          },
                          _react2.default.createElement(_Close2.default, {
                            width: '1em',
                            height: '1em',
                            colour: '#ACACAC'
                          })
                        )
                      ),
                      _react2.default.createElement(_VehicleValuation2.default, {
                        vehicleInfo: vehicleInfo,
                        translations: translations,
                        getValuationMakes: getValuationMakes,
                        valuationMakes: valuationMakes,
                        getValuationModels: getValuationModels,
                        valuationModels: valuationModels,
                        getValuationVersions: getValuationVersions,
                        valuationVersions: valuationVersions,
                        getValuationYears: getValuationYears,
                        valuationYears: valuationYears,
                        getValuationMonths: getValuationMonths,
                        valuationMonths: valuationMonths,
                        getVehicleValuation: getVehicleValuation,
                        vehicleValuation: vehicleValuation,
                        updateMyDealWithValuation: function updateMyDealWithValuation() {
                          updateMyDealWithPartExchange({
                            finance: (0, _extends3.default)((0, _extends3.default)({}, finance, {
                              downpayment: Math.min(vehicleValuation[(0, _keys2.default)(vehicleValuation)[0]].valuation, downpaymentRange.fullRangeValues.max)
                            }), {
                              partExchange: (0, _extends3.default)({}, vehicleValuation[(0, _keys2.default)(vehicleValuation)[0]])
                            }),
                            vehicleDetails: (0, _extends3.default)({}, vehicleInfo)
                          });
                          openClose();
                        },
                        vehicleValuationRequestLimit: vehicleValuationRequestLimit,
                        editVehicleValuation: editVehicleValuation,
                        cancelEditVehicleValuation: cancelEditVehicleValuation,
                        globalStyling: globalStyling,
                        networkError: valuationError
                      })
                    ) : _react2.default.createElement(
                      ActionButton,
                      {
                        'data-cy': 'part-exchange-button',
                        onClick: function onClick() {
                          if (featureFlags.partExchange) {
                            openClose();
                          }
                        }
                      },
                      _react2.default.createElement(_TradeIn2.default, {
                        width: '2em',
                        height: '2em',
                        colour: iconColour
                      }),
                      _react2.default.createElement(
                        ActionButtonText,
                        null,
                        finance.partExchange ? finance.partExchange.vehicle : translations.addPartExchangeLabel
                      )
                    )
                  );
                }
              ),
              _react2.default.createElement(
                _OpenClose2.default,
                null,
                function (_ref18) {
                  var open = _ref18.open,
                      openClose = _ref18.openClose;
                  return _react2.default.createElement(
                    'div',
                    null,
                    open ? _react2.default.createElement(
                      _Modal2.default,
                      {
                        overlay: true,
                        onClickOutside: function onClickOutside() {
                          openClose();
                          resetFinanceProduct();
                        }
                      },
                      _react2.default.createElement(
                        ModalTitleWrapper,
                        null,
                        _react2.default.createElement(
                          _Global.HeadingTwo,
                          {
                            styleOverride: function styleOverride() {
                              return '\n                            display: flex;\n                            justify-content: center;\n                            width: 80%;\n                            margin-left: 10%;\n                            padding: 20px 0;\n                            text-transform: uppercase;\n                          ';
                            }
                          },
                          financeTranslations.financeConfigureModalTitle
                        ),
                        _react2.default.createElement(
                          CloseButtonWrapper,
                          {
                            onClick: function onClick() {
                              openClose();
                              resetFinanceProduct();
                            }
                          },
                          _react2.default.createElement(_Close2.default, {
                            width: '1em',
                            height: '1em',
                            colour: '#ACACAC'
                          })
                        )
                      ),
                      _react2.default.createElement(
                        FinanceTabContainer,
                        null,
                        financeProducts && financeProducts.map(function (financeProduct) {
                          return _react2.default.createElement(
                            FinanceTab,
                            {
                              onClick: financeProduct.onClick,
                              selected: context === financeProduct.path,
                              fontColour: context === financeProduct.path && globalStyling.colours.primaryBrandColour.value
                            },
                            financeProduct.text
                          );
                        })
                      ),
                      _react2.default.createElement(_FinanceProducts2.default, {
                        downpaymentRange: downpaymentRange,
                        termsRange: termsRange,
                        financeExample: financeProductExample || finance,
                        financeExampleRenderProp: function financeExampleRenderProp() {
                          return _react2.default.createElement(_FinanceContainer2.default, {
                            component: function component(props) {
                              return _react2.default.createElement(
                                _VdpPopupAccordion2.default,
                                {
                                  title: props.translateFinanceTemplate('financeMyDealTitle', {
                                    PRODUCT: props.finance.translations['finance' + context + 'Label']
                                  })
                                },
                                _react2.default.createElement(_FinanceExample2.default, (0, _extends3.default)({}, props, {
                                  data: financeProductExample || finance
                                }))
                              );
                            }
                          });
                        },
                        requestFinanceUpdate: requestFinanceProductUpdate,
                        translations: translations,
                        globalStyling: globalStyling,
                        locale: locale,
                        context: context,
                        showDescription: !!showFinanceDescriptions
                      }),
                      _react2.default.createElement(
                        UpdateDealActionContainer,
                        null,
                        _react2.default.createElement(_Global.Button, {
                          applyStyle: 'primary',
                          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
                          text: translations.updateMyDealLabel,
                          onClick: function onClick() {
                            updateMyDeal(financeProductExample || finance);
                            openClose();
                          }
                        })
                      )
                    ) : _react2.default.createElement(
                      ActionButton,
                      {
                        'data-cy': 'edit-finance-button',
                        onClick: function onClick() {
                          openClose();
                        }
                      },
                      _react2.default.createElement(_Calculator2.default, {
                        width: '2em',
                        height: '2em',
                        colour: iconColour
                      }),
                      _react2.default.createElement(
                        ActionButtonText,
                        null,
                        translations.addFinanceLabel
                      )
                    )
                  );
                }
              )
            ),
            _react2.default.createElement(
              RightActionButtons,
              null,
              printing ? _react2.default.createElement(
                ActionButton,
                { 'data-cy': 'printing-my-deal-button' },
                _react2.default.createElement(_Spinner2.default, { colour: { value: iconColour } }),
                _react2.default.createElement(
                  ActionButtonText,
                  null,
                  translations.printingDeal
                )
              ) : _react2.default.createElement(
                ActionButton,
                {
                  'data-cy': 'print-my-deal-button',
                  onClick: function onClick() {
                    return printMyDeal(finance);
                  }
                },
                _react2.default.createElement(_Print2.default, { width: '2em', height: '2em', colour: iconColour }),
                _react2.default.createElement(
                  ActionButtonText,
                  null,
                  translations.printDealLabel
                )
              ),
              _react2.default.createElement(_Global.Button, {
                'data-cy': 'save-my-deal-button',
                applyStyle: 'secondary',
                text: translations.saveDealLabel,
                onClick: saveMyDeal,
                buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
              })
            )
          )
        );
      }
    ),
    featureFlags.finance && !financeLoaded ? _react2.default.createElement(FinanceLoadingText, null) : '\xA0'
  );
}

VdpMyDeal.defaultProps = {
  iconColour: '#9E1B32'
};