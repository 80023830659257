'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.podReducers = undefined;

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _Text = require('./Text');

var _Text2 = _interopRequireDefault(_Text);

var _Image = require('./Image');

var _Image2 = _interopRequireDefault(_Image);

var _TextImage = require('./TextImage');

var _TextImage2 = _interopRequireDefault(_TextImage);

var _TextCTA = require('./TextCTA');

var _TextCTA2 = _interopRequireDefault(_TextCTA);

var _ImageTitle = require('./ImageTitle');

var _ImageTitle2 = _interopRequireDefault(_ImageTitle);

var _Brick = require('./Brick');

var _Brick2 = _interopRequireDefault(_Brick);

var _Spacer = require('./Spacer');

var _Spacer2 = _interopRequireDefault(_Spacer);

var _IconGrid = require('./IconGrid');

var _IconGrid2 = _interopRequireDefault(_IconGrid);

var _Video = require('./Video');

var _Video2 = _interopRequireDefault(_Video);

var _HeroCarousel = require('./HeroCarousel');

var _HeroCarousel2 = _interopRequireDefault(_HeroCarousel);

var _VehicleSpecifications = require('./VehicleSpecifications');

var _VehicleSpecifications2 = _interopRequireDefault(_VehicleSpecifications);

var _VehicleCarousel = require('./VehicleCarousel');

var _VehicleCarousel2 = _interopRequireDefault(_VehicleCarousel);

var _VehicleEquipment = require('./VehicleEquipment');

var _VehicleEquipment2 = _interopRequireDefault(_VehicleEquipment);

var _QuickContact = require('./QuickContact');

var _QuickContact2 = _interopRequireDefault(_QuickContact);

var _Heading = require('./Heading');

var _Heading2 = _interopRequireDefault(_Heading);

var _VehicleRetailerInfo = require('./VehicleRetailerInfo');

var _VehicleRetailerInfo2 = _interopRequireDefault(_VehicleRetailerInfo);

var _Header = require('./Header');

var _Header2 = _interopRequireDefault(_Header);

var _Footer = require('./Footer');

var _Footer2 = _interopRequireDefault(_Footer);

var _Filters = require('./Filters');

var _Filters2 = _interopRequireDefault(_Filters);

var _BlogPost = require('./BlogPost');

var _BlogPost2 = _interopRequireDefault(_BlogPost);

var _CookiePolicy = require('./CookiePolicy');

var _CookiePolicy2 = _interopRequireDefault(_CookiePolicy);

var _EnquiryRedirect = require('./EnquiryRedirect');

var _EnquiryRedirect2 = _interopRequireDefault(_EnquiryRedirect);

var _Form = require('./Form');

var _Form2 = _interopRequireDefault(_Form);

var _FeatureInventoryRange = require('./FeatureInventoryRange');

var _FeatureInventoryRange2 = _interopRequireDefault(_FeatureInventoryRange);

var _SearchResults = require('./SearchResults');

var _SearchResults2 = _interopRequireDefault(_SearchResults);

var _PostSummary = require('./PostSummary');

var _PostSummary2 = _interopRequireDefault(_PostSummary);

var _ModelSearch = require('./ModelSearch');

var ModelSearchPod = _interopRequireWildcard(_ModelSearch);

var _FeaturedVehicle = require('./FeaturedVehicle');

var FeaturedVehiclePod = _interopRequireWildcard(_FeaturedVehicle);

var _DealerLocator = require('./DealerLocator');

var DealerLocatorPod = _interopRequireWildcard(_DealerLocator);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ModelSearch = ModelSearchPod.default,
    modelSearchReducer = (0, _objectWithoutProperties3.default)(ModelSearchPod, ['default']);
var FeaturedVehicle = FeaturedVehiclePod.default,
    featuredVehicleReducer = (0, _objectWithoutProperties3.default)(FeaturedVehiclePod, ['default']);
var DealerLocator = DealerLocatorPod.default,
    dealerLocatorReducer = (0, _objectWithoutProperties3.default)(DealerLocatorPod, ['default']);


var pods = {
  Text: _Text2.default,
  Image: _Image2.default,
  TextImage: _TextImage2.default,
  TextCTA: _TextCTA2.default,
  ImageTitle: _ImageTitle2.default,
  Brick: _Brick2.default,
  Spacer: _Spacer2.default,
  IconGrid: _IconGrid2.default,
  Video: _Video2.default,
  HeroCarousel: _HeroCarousel2.default,
  VehicleSpecifications: _VehicleSpecifications2.default,
  VehicleCarousel: _VehicleCarousel2.default,
  VehicleEquipment: _VehicleEquipment2.default,
  QuickContact: _QuickContact2.default,
  Heading: _Heading2.default,
  VehicleRetailerInfo: _VehicleRetailerInfo2.default,
  ModelSearch: ModelSearch,
  PostSummary: _PostSummary2.default,
  Header: _Header2.default,
  Footer: _Footer2.default,
  Filters: _Filters2.default,
  BlogPost: _BlogPost2.default,
  FeatureInventoryRange: _FeatureInventoryRange2.default,
  CookiePolicy: _CookiePolicy2.default,
  EnquiryRedirect: _EnquiryRedirect2.default,
  FeaturedVehicle: FeaturedVehicle,
  Form: _Form2.default,
  SearchResults: _SearchResults2.default,
  DealerLocator: DealerLocator
};

var podReducers = exports.podReducers = {
  modelSearchReducer: modelSearchReducer,
  featuredVehicleReducer: featuredVehicleReducer,
  dealerLocatorReducer: dealerLocatorReducer
};

exports.default = pods;