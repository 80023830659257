'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submodules = exports.icon = exports.description = exports.availableToAdministration = exports.id = exports.name = exports.initialState = exports.reducer = exports.constants = exports.actions = exports.defaultConfig = exports.config = exports.effects = exports.component = exports.reducerId = undefined;

var _reducer = require('./reducer');

Object.defineProperty(exports, 'reducerId', {
  enumerable: true,
  get: function get() {
    return _reducer.id;
  }
});

var _ModelMatrixSearch = require('./ModelMatrixSearch');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ModelMatrixSearch).default;
  }
});

var _effects = require('./effects');

Object.defineProperty(exports, 'effects', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_effects).default;
  }
});

var _config = require('../Search/config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _defaultConfig = require('./defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});
Object.defineProperty(exports, 'actions', {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
Object.defineProperty(exports, 'constants', {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
Object.defineProperty(exports, 'reducer', {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, 'initialState', {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});

var _Icon = require('../Search/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Filters = require('../../linked-modules/Filters');

var Filters = _interopRequireWildcard(_Filters);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'ModelMatrixSearch';
var id = exports.id = 'ModelMatrixSearch';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Search Vehicles Page';
var icon = exports.icon = _Icon2.default;
var submodules = exports.submodules = [Filters];