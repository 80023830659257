'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  SET_PREFERRED_LOCATION: 'SET_PREFERRED_LOCATION',
  CLEAR_PREFERRED_LOCATION: 'CLEAR_PREFERRED_LOCATION'
};

var actions = exports.actions = {
  setPreferredLocation: function setPreferredLocation(payload) {
    return {
      type: constants.SET_PREFERRED_LOCATION,
      payload: payload
    };
  },
  clearPreferredLocation: function clearPreferredLocation() {
    return {
      type: constants.CLEAR_PREFERRED_LOCATION
    };
  }
};

var initialState = { lat: 0, lng: 0, set: false };

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var _ref = arguments[1];
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case constants.SET_PREFERRED_LOCATION:
      return (0, _extends3.default)({}, state, payload, {
        set: true
      });

    case constants.CLEAR_PREFERRED_LOCATION:
      return initialState;
    default:
      return state;
  }
}