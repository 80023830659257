'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Power;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Power(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 27 26', width: '1em', height: '1em' },
      _react2.default.createElement('path', {
        d: 'M13.365 10.534h6.16L9.372 24.5l2.808-9.617H6.525L16.249.5z',
        fill: '#7E8083',
        fillRule: 'nonzero'
      })
    )
  );
}

Power.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};