'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 0 10px;\n    font-size: 34px;\n  '], ['\n    padding: 0 0 10px;\n    font-size: 34px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    column-count: 2;\n  '], ['\n    column-count: 2;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    column-count: 1;\n    border-bottom: 1px solid #151515;\n    padding: 0;\n    padding-bottom: 10px;\n  '], ['\n    column-count: 1;\n    border-bottom: 1px solid #151515;\n    padding: 0;\n    padding-bottom: 10px;\n  ']);

exports.default = RegionCheckboxGroup;

var _difference = require('ramda/src/difference');

var _difference2 = _interopRequireDefault(_difference);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _filters = require('../../../shared/filters');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _RegionCheckbox = require('./RegionCheckbox');

var _RegionCheckbox2 = _interopRequireDefault(_RegionCheckbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RegionHeader = _styledComponents2.default.div.withConfig({
  displayName: 'RegionCheckboxGroup__RegionHeader',
  componentId: 'sc-1dgl694-0'
})(['color:#151515;display:flex;font-family:\'RivieraNights-Light\',\'Jost-Light\';align-items:center;font-weight:300;border-bottom:1px solid #151515;flex-direction:row;font-size:22px;letter-spacing:2px;line-height:44px;cursor:pointer;padding:10px 0;', ''], _theme2.default.min.medium(_templateObject));

var Checkbox = _styledComponents2.default.div.withConfig({
  displayName: 'RegionCheckboxGroup__Checkbox',
  componentId: 'sc-1dgl694-1'
})(['display:flex;box-sizing:border-box;border-bottom:1px solid #d5d5d5;padding:0 20px 0 0;align-items:center;justify-content:space-between;outline:none;background-color:#ffffff;color:', ';&:last-child{border-bottom:none;}&:hover{cursor:', ';}'], function (_ref) {
  var enabled = _ref.enabled;
  return enabled ? '#333333' : '#d5d5d5';
}, function (_ref2) {
  var enabled = _ref2.enabled;
  return enabled ? 'pointer' : 'default';
});
var SubRegionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RegionCheckboxGroup__SubRegionContainer',
  componentId: 'sc-1dgl694-2'
})(['box-sizing:border-box;margin-top:10px;width:100%;padding:10px 10px 40px 0;column-count:3;gap:15%;', ' ', ''], _theme2.default.max.large(_templateObject2), _theme2.default.max.medium(_templateObject3));

function RegionCheckboxGroup(_ref3) {
  var filtersStyling = _ref3.filtersStyling,
      region = _ref3.region,
      subregions = _ref3.subregions,
      updateFilters = _ref3.updateFilters,
      filterKey = _ref3.filterKey,
      selectedData = _ref3.selectedData,
      renderIcon = _ref3.renderIcon;

  var updateFiltersForRegions = function updateFiltersForRegions(values) {
    var filteredValues = values.map(function (v) {
      return v.name;
    });
    if (selectedData) {
      filteredValues = filteredValues.every(function (v) {
        return selectedData.includes(v);
      }) ? (0, _difference2.default)(selectedData, filteredValues) : [].concat((0, _toConsumableArray3.default)(filteredValues), (0, _toConsumableArray3.default)(selectedData));
    }

    updateFilters([{
      key: filterKey,
      value: filteredValues
    }]);
  };

  var updateFiltersForSubregions = function updateFiltersForSubregions(subRegion) {
    updateFilters([{
      key: filterKey,
      value: _filters.helpers.newArray(selectedData && selectedData.length > 0 ? selectedData.filter(Boolean) : [], subRegion)
    }]);
  };

  var shouldRenderIcon = function shouldRenderIcon(checkBoxEnabled, renderLogic) {
    return checkBoxEnabled ? renderIcon(renderLogic, filtersStyling.checkboxSelectedIcon, filtersStyling.checkboxIcon) : null;
  };

  var shouldRenderRegion = selectedData && (0, _difference2.default)(subregions.map(function (sr) {
    return sr.name;
  }), selectedData).length === 0;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      RegionHeader,
      { onClick: function onClick() {
          return updateFiltersForRegions(subregions);
        } },
      _react2.default.createElement(
        Checkbox,
        {
          enabled: true,
          onClick: function onClick() {
            return updateFiltersForRegions(subregions);
          }
        },
        shouldRenderIcon(true, shouldRenderRegion)
      ),
      region
    ),
    _react2.default.createElement(
      SubRegionContainer,
      null,
      subregions.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      }).map(function (subregion) {
        var shouldRenderSubregionIcon = selectedData && selectedData.includes(subregion.name);
        return _react2.default.createElement(_RegionCheckbox2.default, {
          key: region + '-' + subregion.name,
          onClick: function onClick() {
            return updateFiltersForSubregions(subregion.name);
          },
          label: subregion.name,
          filtersStyling: filtersStyling,
          renderIcon: renderIcon(shouldRenderSubregionIcon, filtersStyling.checkboxSelectedIcon, filtersStyling.checkboxIcon),
          enabled: true
        });
      })
    )
  );
}