'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     ', '\n  '], ['\n     ', '\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__ImageContainer',
  componentId: 'sc-9pyex9-0'
})(['height:fit-content;width:100%;margin:0 auto;max-width:1920px;height:fit-content(768px);display:grid;grid-template-columns:auto;grid-template-rows:auto;grid-column-gap:0px;grid-row-gap:0px;cursor:pointer;grid-template-areas:\'large large large\' \'small1 small2 small3\';']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'ImgCollage__Image',
  componentId: 'sc-9pyex9-1'
})(['aspect-ratio:4 / 3;display:block;object-fit:cover;width:100%;max-width:100%;', ''], function (_ref) {
  var gridArea = _ref.gridArea;
  return gridArea && 'grid-area: ' + gridArea + ';';
});

var MainImage = (0, _styledComponents2.default)(Image).withConfig({
  displayName: 'ImgCollage__MainImage',
  componentId: 'sc-9pyex9-2'
})(['width:1920px;']);

var UtilityInfo = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__UtilityInfo',
  componentId: 'sc-9pyex9-3'
})(['position:absolute;display:flex;background:rgba(0,0,0,0.3);backdrop-filter:blur(12.5px);font-size:10px;text-transform:uppercase;font-size:14px;letter-spacing:0.5px;line-height:21px;padding:7px 0;border-bottom-right-radius:8px;', ';', ';'], _RollsRoyce2.default.min.medium(_templateObject, function (_ref2) {
  var vdp = _ref2.vdp;
  return vdp && 'font-size: 14px;';
}), _RollsRoyce2.default.min.large(_templateObject2, function (_ref3) {
  var vdp = _ref3.vdp;
  return vdp && 'font-size: 18px;';
}));

var InfoImg = _styledComponents2.default.img.withConfig({
  displayName: 'ImgCollage__InfoImg',
  componentId: 'sc-9pyex9-4'
})(['padding:0 14px;']);

var CountInfoImg = (0, _styledComponents2.default)(InfoImg).withConfig({
  displayName: 'ImgCollage__CountInfoImg',
  componentId: 'sc-9pyex9-5'
})(['']);

var CountContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__CountContainer',
  componentId: 'sc-9pyex9-6'
})(['display:box;display:flex;box-align:center;align-items:center;box-pack:center;justify-content:center;']);

var CountText = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__CountText',
  componentId: 'sc-9pyex9-7'
})(['position:absolute;font-size:8px;font-family:\'RivieraNights-Regular,Jost-Regular\';padding-top:6px;padding-left:6px;font-weight:900;color:#fff;']);

var ImgCollage = function ImgCollage(_ref4) {
  var images = _ref4.images,
      video = _ref4.video,
      onClick = _ref4.onClick,
      placeholder = _ref4.placeholder,
      videoOnClick = _ref4.videoOnClick,
      config = _ref4.config,
      imageAltText = _ref4.imageAltText,
      isVDP = _ref4.isVDP,
      modalOpen = _ref4.modalOpen;

  var getImage = function getImage(position) {
    return images[images.length === 1 ? 0 : position] || placeholder;
  };

  var stopPropagation = function stopPropagation(func) {
    return function (event) {
      event.stopPropagation();
      func();
    };
  };

  var _config$galleryOverla = config.galleryOverlayUrls,
      galleryOverlayUrls = _config$galleryOverla === undefined ? null : _config$galleryOverla;

  return _react2.default.createElement(
    ImageContainer,
    { onClick: onClick, isVDP: isVDP },
    isVDP ? _react2.default.createElement(
      _react2.default.Fragment,
      null,
      galleryOverlayUrls && !modalOpen && _react2.default.createElement(
        UtilityInfo,
        { vdp: true },
        _react2.default.createElement(InfoImg, {
          src: galleryOverlayUrls.zoomOverlayUrl,
          alt: 'zoom images overlay icon',
          onClick: onClick
        }),
        video && _react2.default.createElement(InfoImg, {
          src: galleryOverlayUrls.videoOverlayUrl,
          alt: 'play video image overlay icon',
          onClick: stopPropagation(videoOnClick)
        }),
        _react2.default.createElement(
          CountContainer,
          null,
          _react2.default.createElement(CountInfoImg, {
            src: galleryOverlayUrls.imagesOverlayUrl,
            alt: 'image count icon'
          }),
          _react2.default.createElement(
            CountText,
            null,
            images.length + 1
          )
        )
      )
    ) : null,
    _react2.default.createElement(MainImage, { src: getImage(0), gridArea: 'large', alt: imageAltText }),
    _react2.default.createElement(Image, { src: getImage(1), gridArea: 'small1', alt: imageAltText }),
    _react2.default.createElement(Image, { src: getImage(2), gridArea: 'small2', alt: imageAltText }),
    _react2.default.createElement(Image, { src: getImage(3), gridArea: 'small3', alt: imageAltText })
  );
};

ImgCollage.defaultProps = {
  images: [],
  onClick: function onClick() {}
};

exports.default = ImgCollage;