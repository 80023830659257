'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleVehiclePrice = exports.convertPriceCurrency = exports.convertSecondaryPriceCurrency = exports.convertVehiclesPriceCurrency = exports.convertVehiclePriceCurrency = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _numbers = require('../localisation/numbers');

var _translateFromTemplate = require('../localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var convertVehiclePriceCurrency = exports.convertVehiclePriceCurrency = function convertVehiclePriceCurrency(vehicle, exchangeRates, currency) {
  if (!vehicle || vehicle.price && vehicle.price.value === 0) {
    return vehicle;
  }
  var originalValue = vehicle.price.originalValue || vehicle.price.value;
  var originalCurrency = vehicle.price.originalCurrency || vehicle.price.currency;

  var value = originalValue;
  if (currency !== originalCurrency) {
    var newBaseRate = exchangeRates[vehicle.price.currency];
    value = Math.ceil(vehicle.price.value / newBaseRate);
  }

  var price = (0, _extends3.default)({}, vehicle.price, {
    value: value,
    currency: currency,
    originalValue: originalValue,
    originalCurrency: originalCurrency
  });
  return (0, _extends3.default)({}, vehicle, {
    price: price
  });
};

var convertVehiclesPriceCurrency = exports.convertVehiclesPriceCurrency = function convertVehiclesPriceCurrency(vehicles, exchangeRates, currency) {
  return vehicles.map(function (v) {
    return convertVehiclePriceCurrency(v, exchangeRates, currency);
  });
};

var convertSecondaryPriceCurrency = exports.convertSecondaryPriceCurrency = function convertSecondaryPriceCurrency() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var exchangeRates = arguments[1];
  var exchangeCurrency = arguments[2];
  var incomingCurrency = arguments[3];

  if (value === 0) {
    return value;
  }

  var exchangeValue = value;
  if (exchangeCurrency !== incomingCurrency && exchangeRates && exchangeCurrency) {
    var newBaseRate = exchangeRates[incomingCurrency];

    exchangeValue = Math.round(value * newBaseRate);
  }

  return exchangeValue;
};

var convertPriceCurrency = exports.convertPriceCurrency = function convertPriceCurrency(value, exchangeRates, exchangeCurrency, incomingCurrency) {
  var step = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 1;
  var type = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 'max';

  if (value === 0) {
    return value;
  }

  var roundFunc = function roundFunc(val) {
    return type === 'max' ? Math.ceil(val) : Math.floor(val);
  };

  if (exchangeCurrency !== incomingCurrency && exchangeRates && exchangeCurrency) {
    var newBaseRate = exchangeRates[incomingCurrency];
    return roundFunc(value / newBaseRate / step) * step;
  }
  return roundFunc(value / step) * step;
};

var handleVehiclePrice = exports.handleVehiclePrice = function handleVehiclePrice(price, options) {
  var translations = options.translations,
      retailerCountryCode = options.retailerCountryCode,
      handleZeroPriceAsText = options.handleZeroPriceAsText,
      translationsSold = options.translationsSold,
      priceSpecial = options.priceSpecial,
      vehiclePrice = options.vehiclePrice,
      zeroPriceText = options.zeroPriceText,
      currencyType = options.currencyType,
      locale = options.locale,
      fractionDigits = options.fractionDigits;


  if (handleZeroPriceAsText && vehiclePrice === 0) {
    return priceSpecial === 'SOLD' && translationsSold || zeroPriceText || '-';
  }

  if (translations && translations.priceIncludesVat) {
    var PRICE = price ? (0, _numbers.localiseCurrency)(price, locale, currencyType, fractionDigits || 0) : '-';

    var VALUE = retailerCountryCode === 'BE' ? translations.vatRateBelgium : retailerCountryCode === 'LU' ? translations.vatRateLuxembourg : '';

    return (0, _translateFromTemplate.translateFromTemplate)('priceIncludesVat', {
      PRICE: PRICE,
      VALUE: VALUE
    }, translations);
  }

  return price ? (0, _numbers.localiseCurrency)(price, locale, currencyType, fractionDigits || 0) : '-';
};