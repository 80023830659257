'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = exports.modulePrefix = undefined;

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends7 = require('babel-runtime/helpers/extends');

var _extends8 = _interopRequireDefault(_extends7);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

var _validation = require('../../helpers/validation');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = exports.modulePrefix = 'MOD_TEST_DRIVE';

var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_VEHICLE_INFO', 'GET_VEHICLE_INFO_SUCCESS', 'GET_VEHICLE_INFO_FAILURE', 'SET_FORM_VALUE_FROM_DROPDOWN', 'SET_BOOKING_FORM_VALUE', 'CONTINUE_AS_GUEST', 'GUEST_DETAILS_SUBMITTED', 'LOGIN_AND_CONFIRM', 'LOGIN_SUCCESS', 'LOGIN_FAILED', 'SOMETHING_WENT_WRONG', 'USER_LOGGED_IN', 'SEND_TEST_DRIVE_LEAD_SUCCESS', 'SAVE_TEST_DRIVE_SUCCESS', 'CONFIRM_DATE_AND_TIME', 'CONFIRM_REQUEST', 'TOGGLE_CALENDAR', 'INTERESTED_CLICKED', 'RADIO_GROUP_MODE_CHANGED', 'RESET', 'CHANGE_CONTEXT']);

var id = exports.id = 'TestDrive';

var selectDateModeOptionsList = [{ value: 'AnyDate' }, { value: 'SpecificDate' }];

var selectTimeModeOptionsList = [{ label: 'Any', value: 'Any' }, { label: 'Morning', value: 'Morning' }, { label: 'Afternoon', value: 'Afternoon' }];

var actions = exports.actions = {
  getVehicleInfo: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VEHICLE_INFO, id, function (vehicleId) {
    return {
      vehicleId: vehicleId
    };
  }),
  getVehicleInfoSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VEHICLE_INFO_SUCCESS, id, function (config) {
    return config;
  }),
  getVehicleInfoFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VEHICLE_INFO_FAILURE, id, function (error) {
    return error;
  }),
  setFormValueFromDropdown: (0, _reducerMacros.moduleActionWithArgs)(constants.SET_FORM_VALUE_FROM_DROPDOWN, id, function (selected, translations) {
    return {
      selected: selected,
      translations: translations
    };
  }),
  setBookingFormValue: (0, _reducerMacros.moduleActionWithArgs)(constants.SET_BOOKING_FORM_VALUE, id, function (key, value, translations, validation) {
    return {
      key: key,
      value: value,
      translations: translations,
      validation: validation
    };
  }),
  continueAsGuest: (0, _reducerMacros.moduleAction)(constants.CONTINUE_AS_GUEST, id),
  guestDetailsSubmitted: (0, _reducerMacros.moduleActionWithArgs)(constants.GUEST_DETAILS_SUBMITTED, id, function (guestDetails, newAccount) {
    return {
      guestDetails: guestDetails,
      newAccount: newAccount
    };
  }),
  toggleCalendar: (0, _reducerMacros.moduleAction)(constants.TOGGLE_CALENDAR, id),
  setSelectDateMode: (0, _reducerMacros.moduleActionWithArgs)(constants.SET_SELECT_DATE_MODE, id, function (selectedValue) {
    return {
      selectedValue: selectedValue
    };
  }),
  loginAndConfirm: (0, _reducerMacros.moduleActionWithArgs)(constants.LOGIN_AND_CONFIRM, id, function (loginDetails) {
    return (0, _extends8.default)({}, loginDetails);
  }),
  loginSuccess: (0, _reducerMacros.moduleAction)(constants.LOGIN_SUCCESS, id),
  loginFailed: (0, _reducerMacros.moduleActionWithArgs)(constants.LOGIN_FAILED, id, function (payload) {
    return (0, _extends8.default)({}, payload);
  }),
  userLoggedIn: (0, _reducerMacros.moduleAction)(constants.USER_LOGGED_IN, id),
  somethingWentWrong: (0, _reducerMacros.moduleAction)(constants.SOMETHING_WENT_WRONG, id),
  sendTestDriveLeadSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.SEND_TEST_DRIVE_LEAD_SUCCESS, id, function (userCreated, newUserEmail) {
    return {
      userCreated: userCreated,
      newUserEmail: newUserEmail
    };
  }),
  saveTestDriveSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_TEST_DRIVE_SUCCESS, id, function (guestDetails) {
    return { guestDetails: guestDetails };
  }),
  confirmDateAndTime: (0, _reducerMacros.moduleAction)(constants.CONFIRM_DATE_AND_TIME, id),
  confirmRequest: (0, _reducerMacros.moduleActionWithArgs)(constants.CONFIRM_REQUEST, id, function (payload) {
    return payload;
  }),
  setSelectRadioGroupMode: (0, _reducerMacros.moduleActionWithArgs)(constants.RADIO_GROUP_MODE_CHANGED, id, function (key, value) {
    return {
      key: key,
      value: value
    };
  }),
  interestedClicked: (0, _reducerMacros.moduleAction)(constants.INTERESTED_CLICKED, id),
  reset: (0, _reducerMacros.moduleAction)(constants.RESET, id),
  changeContext: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_CONTEXT, id, function (payload) {
    return payload;
  })
};

function validate(form, translations) {
  return {
    title: (0, _validation.required)(form.title, translations),
    firstname: (0, _validation.required)(form.firstname, translations),
    lastname: (0, _validation.required)(form.lastname, translations),
    email: (0, _validation.validEmail)(form.email, translations),
    emailConfirm: (0, _validation.valueMatchesRequired)(form.email, form.emailConfirm, translations),
    phone: (0, _validation.validPhone)(form.phone, translations),
    postcode: (0, _validation.required)(form.postcode, translations),
    accountPassword: (0, _validation.meetsPasswordPolicy)(form.accountPassword, translations, false),
    accountPasswordConfirm: (0, _validation.valueMatches)(form.accountPassword, form.accountPasswordConfirm, translations.formValidationPasswordMatch),
    termsAndConditionsNotChecked: !form.termsAndConditionsAccept ? [translations.testDriveUserDetailsTermsAndConditionsNotChecked] : undefined
  };
}

var initialState = exports.initialState = {
  testDriveConfig: null,
  error: null,
  context: 'DateAndTime',
  open: false,
  selectDateModeOptions: selectDateModeOptionsList,
  selectDateMode: selectDateModeOptionsList[0],
  selectTimeModeOptions: selectTimeModeOptionsList,
  selectTimeMode: selectTimeModeOptionsList[0],
  selectedDateMode: 'AnyDate',
  selectedTimeMode: 'Any',
  disableCalendar: true,
  bookingForm: {
    interestedChecked: false,
    termsAndConditionsAccept: false
  },
  bookingFormError: {},
  somethingWentWrong: false,
  userCreated: false,
  newUserEmail: null
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.RESET:
      return (0, _extends8.default)({}, initialState, {
        error: state.error
      });
    case constants.GET_VEHICLE_INFO_SUCCESS:
      {
        var testDriveConfig = action.payload.testDriveConfig;

        return (0, _extends8.default)({}, state, {
          testDriveConfig: testDriveConfig
        });
      }
    case constants.GET_VEHICLE_INFO_FAILURE:
      return (0, _extends8.default)({}, state, {
        error: action.payload
      });
    case constants.SET_FORM_VALUE_FROM_DROPDOWN:
      {
        var _action$payload = action.payload,
            selected = _action$payload.selected,
            translations = _action$payload.translations;

        var bookingForm = (0, _extends8.default)({}, state.bookingForm, (0, _defineProperty3.default)({}, selected.keyValue, selected.value));
        var errors = validate(bookingForm, translations);
        return (0, _extends8.default)({}, state, {
          bookingForm: bookingForm,
          bookingFormError: (0, _extends8.default)({}, state.bookingFormError, (0, _defineProperty3.default)({}, selected.keyValue, errors[selected.keyValue]))
        });
      }
    case constants.SET_BOOKING_FORM_VALUE:
      {
        var _action$payload2 = action.payload,
            key = _action$payload2.key,
            _value = _action$payload2.value,
            _translations = _action$payload2.translations,
            validation = _action$payload2.validation;

        var _bookingForm = {};
        if (key === 'date') {
          var toUTC = Date.UTC(_value.getFullYear(), _value.getMonth(), _value.getDate());
          var getTimezoneOffset = _value.getTimezoneOffset() * 60000;
          var getEpoch = toUTC + getTimezoneOffset;
          _bookingForm = (0, _extends8.default)({}, state.bookingForm, (0, _defineProperty3.default)({}, key, getEpoch));
        } else {
          _bookingForm = (0, _extends8.default)({}, state.bookingForm, (0, _defineProperty3.default)({}, key, _value));
        }

        var bookingFormError = (0, _extends8.default)({}, state.bookingFormError);
        if (validation) {
          var _errors = validate(_bookingForm, _translations);
          bookingFormError[key] = _errors[key];
        }

        return (0, _extends8.default)({}, state, {
          bookingForm: _bookingForm,
          bookingFormError: bookingFormError
        });
      }
    case constants.RADIO_GROUP_MODE_CHANGED:
      {
        var _extends6;

        var _action$payload3 = action.payload,
            _key = _action$payload3.key,
            _value2 = _action$payload3.value;

        var newBookingForm = (0, _extends8.default)({}, state.bookingForm);
        if (!state.bookingForm.date && _key === 'selectedDateMode' && _value2 === 'SpecificDate') {
          newBookingForm = (0, _extends8.default)({}, state.bookingForm, {
            date: new Date()
          });
        }
        return (0, _extends8.default)({}, state, (_extends6 = {
          open: false,
          disableCalendar: _key === 'selectedDateMode' ? _value2 === 'AnyDate' : state.disableCalendar
        }, (0, _defineProperty3.default)(_extends6, _key, _value2), (0, _defineProperty3.default)(_extends6, 'bookingForm', newBookingForm), _extends6));
      }
    case constants.CONTINUE_AS_GUEST:
      return (0, _extends8.default)({}, state, {
        context: 'UserDetails'
      });
    case constants.GUEST_DETAILS_SUBMITTED:
      {
        return (0, _extends8.default)({}, state, {
          guestDetails: action.payload.guestDetails
        });
      }
    case constants.TOGGLE_CALENDAR:
      return (0, _extends8.default)({}, state, {
        open: !state.open,
        disableCalendar: state.selectedDateMode === 'AnyDate'
      });
    case constants.CONFIRM_DATE_AND_TIME:
      return (0, _extends8.default)({}, state, {
        context: 'Login'
      });
    case constants.CONFIRM_REQUEST:
      {
        var _bookingFormError = validate(action.payload.bookingForm, action.payload.translations);
        var valid = (0, _values2.default)(_bookingFormError).filter(function (val) {
          return val !== undefined;
        }).length === 0;
        return (0, _extends8.default)({}, state, {
          bookingFormError: _bookingFormError,
          context: valid ? 'Summary' : state.context
        });
      }
    case constants.LOGIN_SUCCESS:
      {
        return (0, _extends8.default)({}, state, {
          loginFailed: undefined
        });
      }
    case constants.SEND_TEST_DRIVE_LEAD_SUCCESS:
      {
        return (0, _extends8.default)({}, state, {
          userCreated: action.payload.userCreated,
          newUserEmail: action.payload.newUserEmail,
          context: 'Summary'
        });
      }
    case constants.LOGIN_FAILED:
      {
        return (0, _extends8.default)({}, state, {
          loginFailed: action.payload.reason
        });
      }
    case constants.SOMETHING_WENT_WRONG:
      {
        return (0, _extends8.default)({}, state, {
          context: 'DateAndTime',
          somethingWentWrong: true
        });
      }
    case constants.INTERESTED_CLICKED:
      {
        return (0, _extends8.default)({}, state, {
          bookingForm: (0, _extends8.default)({}, state.bookingForm, {
            interestedChecked: !state.bookingForm.interestedChecked
          })
        });
      }
    case constants.CHANGE_CONTEXT:
      {
        return (0, _extends8.default)({}, state, {
          context: action.payload
        });
      }

    default:
      return state;
  }
}