'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledCustomText = undefined;
exports.default = CustomText;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledCustomText = exports.StyledCustomText = _styledComponents2.default.div.withConfig({
  displayName: 'StyledCustomText'
}).withConfig({
  displayName: 'CustomText__StyledCustomText',
  componentId: 'sc-1vw5eap-0'
})(['color:', ';margin:0 6px;text-transform:', ';letter-spacing:', ';font-size:', ';font-family:', ';'], function (_ref) {
  var theme = _ref.theme;
  return '' + theme.colour;
}, function (_ref2) {
  var theme = _ref2.theme;
  return '' + theme.textTransform;
}, function (_ref3) {
  var theme = _ref3.theme;
  return '' + theme.characterSpacing;
}, function (_ref4) {
  var theme = _ref4.theme;
  return '' + theme.fontSize;
}, function (_ref5) {
  var theme = _ref5.theme;
  return '' + theme.fontFamily;
});

function CustomText(_ref6) {
  var theme = _ref6.theme,
      content = _ref6.content;

  return _react2.default.createElement(
    StyledCustomText,
    { theme: theme },
    content
  );
}