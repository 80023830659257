'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconButtonStyle = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _router = require('../../../actions/router');

var _Spinner = require('../../../components/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _globalDrawers = require('../../../shared/globalDrawers');

var _lamborghiniFinanceCalc = require('../../../shared/lamborghiniFinanceCalc');

var _numbers = require('../../../shared/localisation/numbers');

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

var _AppShared = require('../../../types/AppShared');

var _financeDrawerTypes = require('../../../types/Lamborghini/financeDrawerTypes');

var _globalStyling = require('../../../types/globalStyling');

var _FinanceDrawerStyles = require('./FinanceDrawerStyles');

var _FinanceForm = require('./FinanceForm');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconButtonStyle = exports.IconButtonStyle = _styledComponents2.default.img.withConfig({
  displayName: 'FinanceDrawer__IconButtonStyle',
  componentId: 'sc-il5as6-0'
})(['width:24px;padding:15px;cursor:pointer;', ''], function (_ref) {
  var hide = _ref.hide;
  return hide && 'opacity: 0; cursor: default;';
});

var DrawerCloseContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceDrawer__DrawerCloseContainer',
  componentId: 'sc-il5as6-1'
})(['display:flex;justify-content:flex-end;margin-top:43px;margin-right:80px;width:100%;']);

var FinanceDrawer = function FinanceDrawer(_ref2) {
  var config = _ref2.config,
      shared = _ref2.shared,
      globalStyling = _ref2.globalStyling;
  var translations = config.translations;

  var dispatch = (0, _reactRedux.useDispatch)();
  var _shared$globalDrawers = shared.globalDrawers,
      financeDrawer = _shared$globalDrawers.financeDrawer,
      currentVehicle = _shared$globalDrawers.currentVehicle;
  var _shared$lamborghiniFi = shared.lamborghiniFinanceCalc,
      financeData = _shared$lamborghiniFi.finance,
      paymentData = _shared$lamborghiniFi.payments,
      error = _shared$lamborghiniFi.error;

  var _useState = (0, _react.useState)(config.provinces[0] || ''),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      selectedProvince = _useState2[0],
      setSelectedProvince = _useState2[1];

  var _useState3 = (0, _react.useState)(12),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      selectedTerm = _useState4[0],
      setSelectedTerm = _useState4[1];

  var _useState5 = (0, _react.useState)(0),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      downPayment = _useState6[0],
      setDownPayment = _useState6[1];

  var _useState7 = (0, _react.useState)(config.financeScores[0] || ''),
      _useState8 = (0, _slicedToArray3.default)(_useState7, 2),
      ficoScore = _useState8[0],
      setFicoScore = _useState8[1];

  var step = 500;

  var dealerCountry = currentVehicle && currentVehicle.retailerInformation.countryCode;

  var termValues = financeData && (0, _values2.default)(financeData.Terms).sort(function (a, b) {
    return Number(a) - Number(b);
  });

  var incrementDownPayment = function incrementDownPayment() {
    return setDownPayment(Math.min(downPayment + step, financeData.MaxDownPayment));
  };

  var decrementDownPayment = function decrementDownPayment() {
    return setDownPayment(Math.max(downPayment - step, 0));
  };

  var onDownPaymentChange = function onDownPaymentChange(v) {
    var value = Number(v);
    if (value > financeData.MaxDownPayment) {
      setDownPayment(financeData.MaxDownPayment);
    } else if (value < 0) {
      setDownPayment(0);
    } else {
      setDownPayment(value);
    }
  };

  var resetForm = function resetForm() {
    setSelectedTerm(12);
    setDownPayment(0);
    setFicoScore(config.financeScores[0] || '');
    setSelectedProvince(config.provinces[0] || '');
    dispatch(_lamborghiniFinanceCalc.actions.clearFinance());
  };

  (0, _react.useEffect)(function () {
    // call api to get finance data
    if (currentVehicle) {
      dispatch(_lamborghiniFinanceCalc.actions.clearFinance());
      dispatch(_lamborghiniFinanceCalc.actions.init(ficoScore));
    }
  }, [currentVehicle, ficoScore, financeDrawer]);

  // reset form if current vehicle changes
  (0, _react.useEffect)(function () {
    resetForm();
  }, [currentVehicle && currentVehicle.id]);

  // set default values
  (0, _react.useEffect)(function () {
    // if term is not in terms set defualt term
    if (financeData && !(0, _values2.default)(financeData.Terms).includes(selectedTerm)) {
      setSelectedTerm(financeData.Term);
    }

    if (financeData && downPayment === 0) {
      setSelectedTerm(financeData.Term);
      setDownPayment(financeData.DefaultDownPayment);
    }
  }, [financeData]);

  // update monthly payment info
  (0, _react.useEffect)(function () {
    if (financeData && downPayment !== 0) {
      dispatch(_lamborghiniFinanceCalc.actions.getMonthlyPayment((0, _extends3.default)({
        term: selectedTerm,
        downPayment: downPayment
      }, dealerCountry === 'US' && { creditScore: ficoScore }, dealerCountry === 'CA' && { provinceCode: selectedProvince })));
    }
  }, [financeData, selectedTerm, downPayment]);

  (0, _react.useEffect)(function () {
    // add position fixed to body when drawer is open
    if (financeDrawer) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [financeDrawer]);

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(_FinanceDrawerStyles.PageOverlay, {
      open: financeDrawer,
      onClick: function onClick() {
        dispatch(_globalDrawers.actions.toggleFinanceDrawer());
      }
    }),
    _react2.default.createElement(
      _react2.default.Fragment,
      null,
      error && error.origin && error.origin === _lamborghiniFinanceCalc.constants.LAMBO_FINANCE_INIT ? _react2.default.createElement(
        _FinanceDrawerStyles.Drawer,
        { open: financeDrawer },
        _react2.default.createElement(
          DrawerCloseContainer,
          null,
          _react2.default.createElement(IconButtonStyle, {
            src: 'https://res.cloudinary.com/motortrak/image/upload/v1555597639/locator/lamborghini/content/x.svg',
            onClick: function onClick() {
              dispatch(_globalDrawers.actions.toggleFinanceDrawer());
            },
            style: { alignSelf: 'flex-end' }
          })
        ),
        _react2.default.createElement(
          _FinanceDrawerStyles.SpinnerContainer,
          null,
          _react2.default.createElement(
            'p',
            null,
            translations.error
          )
        )
      ) : _react2.default.createElement(
        _FinanceDrawerStyles.Drawer,
        { open: financeDrawer },
        financeData && currentVehicle && paymentData ? _react2.default.createElement(_FinanceForm.FinanceForm, {
          ficoScore: ficoScore,
          setFicoScore: setFicoScore,
          dealerCountry: dealerCountry,
          dispatch: dispatch,
          selectedProvince: selectedProvince,
          setSelectedProvince: setSelectedProvince,
          config: config,
          setSelectedTerm: setSelectedTerm,
          selectedTerm: selectedTerm,
          termValues: termValues,
          downPayment: downPayment,
          incrementDownPayment: incrementDownPayment,
          decrementDownPayment: decrementDownPayment,
          onDownPaymentChange: onDownPaymentChange,
          currentVehicle: currentVehicle,
          financeData: financeData,
          paymentData: paymentData,
          translations: translations,
          step: step,
          error: error
        }) : _react2.default.createElement(
          _FinanceDrawerStyles.SpinnerContainer,
          null,
          _react2.default.createElement(_Spinner2.default, { colour: globalStyling.colours.primaryBrandColour })
        )
      ),
      financeDrawer && financeData && paymentData && currentVehicle && !error && _react2.default.createElement(
        _FinanceDrawerStyles.FloatingFooter,
        null,
        _react2.default.createElement(
          _FinanceDrawerStyles.TextCol,
          null,
          _react2.default.createElement(
            _FinanceDrawerStyles.FooterTopText,
            null,
            translations.estimatedMonthlyPayment
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.FooterMainText,
            null,
            (0, _translateFromTemplate.translateFromTemplate)('footerText', {
              MONTHLY_PAYMENT: (0, _numbers.localiseCurrency)(paymentData.BaseMonthlyPayment, currentVehicle.locale, currentVehicle.price.currency, 0.01),
              TERM: paymentData.Term,
              RATE: paymentData.Rate,
              DOWN_PAYMENT: (0, _numbers.localiseCurrency)(paymentData.DownPayment, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            }, translations)
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.SummaryLink,
            { href: '#summary' },
            translations.viewPaymentSummary
          )
        ),
        _react2.default.createElement(
          _FinanceDrawerStyles.SubmitButton,
          {
            onClick: function onClick() {
              dispatch(_router.actions.navigateWithPayload('/enquiry/' + currentVehicle.id, paymentData));
              resetForm();
            },
            style: { alignSelf: 'center' }
          },
          translations.contactDealer
        )
      )
    )
  );
};
exports.default = FinanceDrawer;