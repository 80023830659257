'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    height: 142px;\n    align-items: center;\n  '], ['\n    height: 142px;\n    align-items: center;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    height: 50%;\n    text-align: left;\n    font-size: 18px;\n    margin: 0;\n  '], ['\n    height: 50%;\n    text-align: left;\n    font-size: 18px;\n    margin: 0;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    min-height: 60px;\n    width: 30%;\n  '], ['\n    min-height: 60px;\n    width: 30%;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    width: 40%;\n  '], ['\n    width: 40%;\n  ']);

exports.default = Summary;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _CircleTick = require('./JaguarIcons/Global/CircleTick');

var _CircleTick2 = _interopRequireDefault(_CircleTick);

var _Button = require('./Global/Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Summary__Container',
  componentId: 'sc-3en307-0'
})(['height:auto;width:80%;margin:0 auto;text-align:center;margin-bottom:32px;', ';'], _theme2.default.max.large(_templateObject));

var CarTile = _styledComponents2.default.div.withConfig({
  displayName: 'Summary__CarTile',
  componentId: 'sc-3en307-1'
})(['height:225px;display:flex;margin-bottom:32px;', ';'], _theme2.default.max.large(_templateObject2));

var CarTextAndIconAndCheckList = _styledComponents2.default.div.withConfig({
  displayName: 'Summary__CarTextAndIconAndCheckList',
  componentId: 'sc-3en307-2'
})(['display:flex;flex-direction:column;width:100%;margin-left:30px;']);

var CarTextAndIcon = _styledComponents2.default.div.withConfig({
  displayName: 'Summary__CarTextAndIcon',
  componentId: 'sc-3en307-3'
})(['display:flex;align-items:center;width:100%;height:100%;']);

var CarText = _styledComponents2.default.div.withConfig({
  displayName: 'Summary__CarText',
  componentId: 'sc-3en307-4'
})(['text-align:left;width:100%;height:100%;margin:0 12px 0 0;display:flex;flex-direction:column;justify-content:space-between;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Summary__IconWrapper',
  componentId: 'sc-3en307-5'
})(['', ';'], _theme2.default.max.large(_templateObject3));

var ModelName = _styledComponents2.default.h3.withConfig({
  displayName: 'Summary__ModelName',
  componentId: 'sc-3en307-6'
})(['font-size:20px;font-weight:600;margin:12px 0;', ';'], _theme2.default.max.large(_templateObject4));

var SubHeading = _styledComponents2.default.div.withConfig({
  displayName: 'Summary__SubHeading',
  componentId: 'sc-3en307-7'
})(['height:50%;text-align:left;font-size:16px;color:#444444;margin:7px 0 0 0;']);

var CarImage = _styledComponents2.default.img.withConfig({
  displayName: 'Summary__CarImage',
  componentId: 'sc-3en307-8'
})(['height:auto;width:100%;max-width:300px;', ';', ';'], _theme2.default.max.large(_templateObject5), _theme2.default.max.medium(_templateObject6));

var IconColumn = _styledComponents2.default.div.withConfig({
  displayName: 'Summary__IconColumn',
  componentId: 'sc-3en307-9'
})(['display:flex;height:100%;flex-direction:column;']);

function Summary(_ref) {
  var testDriveConfig = _ref.testDriveConfig,
      _ref$globalStyling = _ref.globalStyling,
      colours = _ref$globalStyling.colours,
      uiElements = _ref$globalStyling.uiElements,
      translations = _ref.translations,
      navigateToVdp = _ref.navigateToVdp;

  var primaryBrandColour = colours.primaryBrandColour && colours.primaryBrandColour.value;
  var testDriveSpec = testDriveConfig.specification;
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      CarTile,
      null,
      testDriveConfig.images.length > 0 ? _react2.default.createElement(CarImage, { src: testDriveConfig.images[0] }) : _react2.default.createElement(CarImage, { src: testDriveConfig.placeholdingImage }),
      _react2.default.createElement(
        CarTextAndIconAndCheckList,
        null,
        _react2.default.createElement(
          CarTextAndIcon,
          null,
          _react2.default.createElement(
            CarText,
            null,
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                ModelName,
                null,
                testDriveConfig.name
              ),
              _react2.default.createElement(
                SubHeading,
                null,
                [testDriveSpec.odometer.display, testDriveSpec.engine, testDriveSpec.transmission, testDriveSpec.fuelType].filter(Boolean).join(' | ')
              )
            ),
            _react2.default.createElement(_Button2.default, {
              applyStyle: 'primary',
              buttonStyle: uiElements.primaryButton && uiElements.primaryButton.buttonStyle,
              text: translations.viewCarDetails,
              onClick: navigateToVdp
            })
          ),
          _react2.default.createElement(
            IconColumn,
            null,
            _react2.default.createElement(
              IconWrapper,
              null,
              _react2.default.createElement(_CircleTick2.default, {
                colour: primaryBrandColour,
                width: '4em',
                height: '4em'
              })
            )
          )
        )
      )
    )
  );
}