'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.optionComponents = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _DropdownRangeFilter = require('./DropdownRangeFilters/DropdownRangeFilter');

var _DropdownRangeFilter2 = _interopRequireDefault(_DropdownRangeFilter);

var _FilterSection = require('./FilterSection');

var _FilterSection2 = _interopRequireDefault(_FilterSection);

var _LocationDistanceFilter = require('./LocationDistanceFilter');

var _LocationDistanceFilter2 = _interopRequireDefault(_LocationDistanceFilter);

var _LocationFilter = require('./LocationFilter');

var _LocationFilter2 = _interopRequireDefault(_LocationFilter);

var _MultiSelectFilter = require('./MultiSelectFilter');

var _MultiSelectFilter2 = _interopRequireDefault(_MultiSelectFilter);

var _RegionalFilter = require('./RegionalFilter');

var _RegionalFilter2 = _interopRequireDefault(_RegionalFilter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var optionComponents = exports.optionComponents = {
  subregions: function subregions(filterKey, props) {
    return _react2.default.createElement(_RegionalFilter2.default, {
      key: filterKey,
      filterKey: filterKey,
      selectedData: props.selectedFilters.subregions,
      filtersStyling: props.config.stylingConfig,
      updateFilters: props.updateFilters,
      globalStyling: props.globalStyling,
      availableFilterValues: props.availableFilterValues,
      translations: props.config.translations
    });
  },
  location: function location(filterKey, props, ref) {
    return _react2.default.createElement(_LocationFilter2.default, {
      updateSessionPreferences: props.updateSessionPreferences,
      availableFilterValues: props.availableFilterValues,
      selectedFilters: props.selectedFilters,
      selectedData: props.selectedFilters,
      updateFilters: props.updateFilters,
      globalStyling: props.globalStyling,
      filtersStyling: props.config.stylingConfig,
      translations: props.config.translations,
      changeLocation: props.changeLocation,
      location: props.location,
      searchCountryCode: props.searchCountryCode,
      innerRef: ref,
      key: filterKey
    });
  },
  distance: function distance(filterKey, props, ref) {
    var googleAvailable = props.shared.google.googleAvailable;

    return googleAvailable && _react2.default.createElement(_LocationDistanceFilter2.default, {
      updateSessionPreferences: props.updateSessionPreferences,
      availableFilterValues: props.availableFilterValues,
      selectedFilters: props.selectedFilters,
      selectedData: props.selectedFilters,
      updateFilters: props.updateFilters,
      globalStyling: props.globalStyling,
      filtersStyling: props.config.stylingConfig,
      translations: props.config.translations,
      changeLocation: props.changeLocation,
      location: props.location,
      searchCountryCode: props.searchCountryCode,
      ref: ref,
      key: filterKey
    });
  },
  exteriorColours: function exteriorColours(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.exteriorColoursFilterTitle,
        active: !!props.selectedFilters.exteriorColours,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.exteriorColours,
        selectedData: props.selectedFilters.exteriorColours,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  interiorShades: function interiorShades(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.interiorShadeFilterTitle,
        active: !!props.selectedFilters.interiorShades,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.interiorShades,
        selectedData: props.selectedFilters.interiorShades,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  model: function model(filterKey, props, ref) {
    var translations = props.config.translations;

    var translateModel = function translateModel(models) {
      return models.map(function (model) {
        return translations[model.value] ? (0, _extends3.default)({}, model, { display: translations[model.value] }) : model;
      });
    };

    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.modelFilterTitle,
        active: !!props.selectedFilters.model,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: translateModel(props.availableFilterValues.model),
        selectedData: props.selectedFilters.model,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  modelVariants: function modelVariants(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.variantFilterTitle,
        active: !!props.selectedFilters.modelVariants,
        disabled: !props.selectedFilters.model || !props.availableFilterValues.modelVariants.length,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.modelVariants,
        selectedData: props.selectedFilters.modelVariants,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  yearRange: function yearRange(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.ageFilterTitle,
        active: !!props.selectedFilters.yearRangeMin || !!props.selectedFilters.yearRangeMax,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_DropdownRangeFilter2.default, {
        filterKeyMin: 'yearRangeMin',
        filterKeyMax: 'yearRangeMax',
        config: props.config,
        minLabel: props.config.translations.minYear,
        maxLabel: props.config.translations.maxYear,
        defaultLabel: props.config.translations.any,
        availableValues: {
          min: props.availableFilterValues.yearRange.min,
          max: props.availableFilterValues.yearRange.max
        },
        selectedValues: {
          min: props.selectedFilters.yearRangeMin,
          max: props.selectedFilters.yearRangeMax
        },
        updateFilters: props.updateFilters,
        step: 1
      })
    );
  },
  modelYearRange: function modelYearRange(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.modelYearFilterTitle,
        active: !!props.selectedFilters.modelYearRangeMin || !!props.selectedFilters.modelYearRangeMax,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_DropdownRangeFilter2.default, {
        filterKeyMin: 'modelYearRangeMin',
        filterKeyMax: 'modelYearRangeMax',
        minLabel: props.config.translations.minYear,
        maxLabel: props.config.translations.maxYear,
        defaultLabel: props.config.translations.any,
        availableValues: {
          min: props.availableFilterValues.modelYearRange.min,
          max: props.availableFilterValues.modelYearRange.max
        },
        selectedValues: {
          min: props.selectedFilters.modelYearRangeMin,
          max: props.selectedFilters.modelYearRangeMax
        },
        updateFilters: props.updateFilters,
        step: 1
      })
    );
  }
};