'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var config = (0, _config.Config)('SearchResultsPage', { id: 'search-results-page' });

exports.default = config;