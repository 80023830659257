'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EmissionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsGroup__EmissionsContainer',
  componentId: 'sc-1y8if2w-0'
})(['display:flex;flex-direction:column;width:100%;']);

var EmissionsGroupHeader = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsGroup__EmissionsGroupHeader',
  componentId: 'sc-1y8if2w-1'
})(['display:flex;font-family:\'RobotoBold\';font-size:16px;line-height:40px;color:#1f1f1f;']);

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsGroup__Row',
  componentId: 'sc-1y8if2w-2'
})(['display:flex;line-height:40px;width:100%;border-bottom:1px solid \'#d8d8d8\';padding:10px 0;border-top:1px solid #d8d8d8;']);

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsGroup__Label',
  componentId: 'sc-1y8if2w-3'
})(['font-family:\'RobotoBold\';font-size:14px;line-height:16px;color:#1f1f1f;width:50%;']);

var Value = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsGroup__Value',
  componentId: 'sc-1y8if2w-4'
})(['display:flex;justify-content:flex-end;font-family:\'Roboto\';font-size:14px;line-height:16px;color:#1f1f1f;width:50%;']);

var EmissionsGroup = function EmissionsGroup(props) {
  var title = props.title,
      data = props.data,
      translations = props.translations;
  var low = data.low,
      mid = data.mid,
      high = data.high,
      extraHigh = data.extraHigh,
      combined = data.combined,
      nedcUrban = data.nedcUrban,
      nedcExtraUrban = data.nedcExtraUrban;


  var rows = [{ label: translations.low, value: low }, { label: translations.mid, value: mid }, { label: translations.high, value: high }, { label: translations.extraHigh, value: extraHigh }, { label: translations.nedcUrban, value: nedcUrban }, { label: translations.nedcExtraUrban, value: nedcExtraUrban }, { label: translations.combined, value: combined }];

  var displayEmissionsGroupTitle = rows.filter(function (a) {
    return a.value;
  });

  return _react2.default.createElement(
    EmissionsContainer,
    null,
    displayEmissionsGroupTitle.length > 0 && _react2.default.createElement(
      EmissionsGroupHeader,
      null,
      title
    ),
    rows.filter(function (a) {
      return a.value;
    }).map(function (_ref) {
      var label = _ref.label,
          value = _ref.value;
      return _react2.default.createElement(
        Row,
        { key: label },
        _react2.default.createElement(
          Label,
          null,
          label
        ),
        _react2.default.createElement(
          Value,
          null,
          value
        )
      );
    })
  );
};

exports.default = EmissionsGroup;