'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

var _filters = require('../../shared/filters');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ItemRowWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MultiSelectFilter__ItemRowWrapper',
  componentId: 'sc-1c5lla0-0'
})(['margin:12px 0 0;']);

var ItemRow = _styledComponents2.default.div.withConfig({
  displayName: 'MultiSelectFilter__ItemRow',
  componentId: 'sc-1c5lla0-1'
})(['height:auto;cursor:pointer;color:', ';background-color:#ffffff;display:flex;align-items:center;font-size:14px;font-weight:300;line-height:28px;letter-spacing:0.5px;margin-bottom:10px;'], function (_ref) {
  var colour = _ref.colour;
  return colour;
});

var Selection = (0, _styledComponents2.default)(ItemRow).withConfig({
  displayName: 'MultiSelectFilter__Selection',
  componentId: 'sc-1c5lla0-2'
})(['gap:10px;']);

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'MultiSelectFilter__IconWrapper',
  componentId: 'sc-1c5lla0-3'
})(['width:20px;height:20px;margin-left:auto;']);

var MultiSelectFilter = function MultiSelectFilter(_ref2) {
  var translations = _ref2.translations,
      filterKey = _ref2.filterKey,
      data = _ref2.data,
      selectedData = _ref2.selectedData,
      updateFilters = _ref2.updateFilters,
      applyOrdering = _ref2.applyOrdering,
      stylingConfig = _ref2.stylingConfig,
      getDisabledValue = _ref2.getDisabledValue;
  return _react2.default.createElement(
    ItemRowWrapper,
    null,
    data.sort(applyOrdering).map(function (d) {
      var itemSelected = selectedData && selectedData.some(function (sd) {
        return sd === d.value.toString();
      });
      var isDisabled = getDisabledValue(d);
      return _react2.default.createElement(
        Selection,
        {
          tabIndex: '0',
          'aria-label': isDisabled ? translations.cannotSelectAriaLabel : translations.clickToSelectAriaLabel,
          'aria-disabled': isDisabled,
          key: d.value,
          'data-cy': d.display,
          showHover: !getDisabledValue(d),
          onClick: function onClick() {
            return (itemSelected || !getDisabledValue(d)) && updateFilters([{
              key: filterKey,
              value: _filters.helpers.newArray(selectedData || [], d.value.toString())
            }]);
          }
        },
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n              word-wrap: break-word;\n              margin: 0;\n              width: 36px;\n              font-size: 14px;\n              line-height: 28px;\n              ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, stylingConfig.filterMenuFont, getDisabledValue(d) && {
                colour: stylingConfig.filterMenuDisabledColour || {
                  value: '#989898'
                }
              })) + '\n            ';
            }
          },
          '' + (d.selectedCount !== 0 ? '(' + d.selectedCount + ')' : '')
        ),
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n                  word-wrap: break-word;\n                  margin: 0;\n                  line-height: 28px;\n                  ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, stylingConfig.filterMenuFont, getDisabledValue(d) && {
                colour: stylingConfig.filterMenuDisabledColour || {
                  value: '#989898'
                }
              })) + '\n                ';
            }
          },
          d.display
        ),
        itemSelected ? _react2.default.createElement(IconWrapper, {
          src: stylingConfig.filterTickIcon,
          alt: 'checkbox selected'
        }) : d.selectedCount > 0 && _react2.default.createElement(IconWrapper, {
          src: stylingConfig.filterNoTickIcon,
          alt: 'checkbox not selected'
        })
      );
    })
  );
};

MultiSelectFilter.defaultProps = {
  applyOrdering: function applyOrdering(a, b) {
    if (a.display < b.display) {
      return -1;
    }
    if (a.display > b.display) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }
};

exports.default = MultiSelectFilter;