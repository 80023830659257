'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Emissions = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _SearchCo2Accordian = require('../components/SearchCo2Accordian');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EmissionsDataValue = _styledComponents2.default.div.withConfig({
  displayName: 'Emission__EmissionsDataValue',
  componentId: 'sc-1j8dhc6-0'
})(['display:flex;align-items:center;']);
var StyledBadgeLabel = (0, _styledComponents2.default)(_SearchCo2Accordian.BadgeLabel).withConfig({
  displayName: 'Emission__StyledBadgeLabel',
  componentId: 'sc-1j8dhc6-1'
})(['margin-right:3px;']);
var Emissions = function Emissions(vehicleInfo) {
  var globalStyling = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.globalStyling;
  });
  return _react2.default.createElement(
    EmissionsDataValue,
    null,
    _react2.default.createElement(
      StyledBadgeLabel,
      {
        emissionsClass: vehicleInfo.specification.engineDetails.emissionsClass
      },
      vehicleInfo.specification.engineDetails.emissionsClass
    ),
    _react2.default.createElement(_SearchCo2Accordian.PdfButton, { vehicle: vehicleInfo, globalStyling: globalStyling })
  );
};

exports.Emissions = Emissions;