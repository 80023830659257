'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  padding: 5%;\n'], ['\n  padding: 5%;\n']);

exports.default = TestDriveSelectDateAndTime;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _LabelledCheckBox = require('./LabelledCheckBox');

var _LabelledCheckBox2 = _interopRequireDefault(_LabelledCheckBox);

var _DatePickerWrapper = require('./DatePickerWrapper');

var _DatePickerWrapper2 = _interopRequireDefault(_DatePickerWrapper);

var _RadioGroup = require('./RadioGroup');

var _RadioGroup2 = _interopRequireDefault(_RadioGroup);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

require('../modules/TestDrive/reducer');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RadioGroupLabel = _styledComponents2.default.span.withConfig({
  displayName: 'TestDriveSelectDateAndTime__RadioGroupLabel',
  componentId: 'sc-edzvyz-0'
})(['font-weight:600;margin-bottom:6px;']);

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'TestDriveSelectDateAndTime__Container',
  componentId: 'sc-edzvyz-1'
})(['', ';'], _theme2.default.min.large(_templateObject));

var BorderedBlock = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSelectDateAndTime__BorderedBlock',
  componentId: 'sc-edzvyz-2'
})(['border:1px solid #d8d8d8;padding:20px;']);

var HorizontalWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'HorizontalWrapper'
}).withConfig({
  displayName: 'TestDriveSelectDateAndTime__HorizontalWrapper',
  componentId: 'sc-edzvyz-3'
})(['flex-direction:column;']);

var Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSelectDateAndTime__Overlay',
  componentId: 'sc-edzvyz-4'
})(['opacity:', ';'], function (props) {
  return props.disabled ? '0.3' : '1';
});

var SelectTimeWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SelectTimeWrapper'
}).withConfig({
  displayName: 'TestDriveSelectDateAndTime__SelectTimeWrapper',
  componentId: 'sc-edzvyz-5'
})(['margin:14px 0;']);

var TimeRadioButtonsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SelectTimeRadioButtonsWrapper'
}).withConfig({
  displayName: 'TestDriveSelectDateAndTime__TimeRadioButtonsWrapper',
  componentId: 'sc-edzvyz-6'
})(['border-top:1px solid #d8d8d8;border-bottom:1px solid #d8d8d8;margin:14px 0;']);

var InterestedText = _styledComponents2.default.div.withConfig({
  displayName: 'InterestedText'
}).withConfig({
  displayName: 'TestDriveSelectDateAndTime__InterestedText',
  componentId: 'sc-edzvyz-7'
})(['margin-top:20px;color:#444444;line-height:22px;']);

var CheckboxWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CheckboxWrapper'
}).withConfig({
  displayName: 'TestDriveSelectDateAndTime__CheckboxWrapper',
  componentId: 'sc-edzvyz-8'
})(['align-items:center;margin-top:15px;display:flex;']);

var onDateRadioGroupChange = function onDateRadioGroupChange(_ref) {
  var setSelectRadioGroupMode = _ref.setSelectRadioGroupMode;
  return function (_ref2) {
    var value = _ref2.value;
    return setSelectRadioGroupMode('selectedDateMode', value);
  };
};

var onTimeRadioGroupChange = function onTimeRadioGroupChange(_ref3) {
  var setSelectRadioGroupMode = _ref3.setSelectRadioGroupMode;
  return function (x) {
    return setSelectRadioGroupMode('selectedTimeMode', x.value);
  };
};

var onToggleCalendarChange = function onToggleCalendarChange(_ref4) {
  var disableCalendar = _ref4.disableCalendar,
      toggleCalendar = _ref4.toggleCalendar;
  return disableCalendar ? function () {} : toggleCalendar;
};

var asOption = function asOption(key, value) {
  return { value: value, label: key };
};

function TestDriveSelectDateAndTime(props) {
  var translations = props.translations,
      selectDateModeOptions = props.selectDateModeOptions,
      selectedDateMode = props.selectedDateMode,
      disableCalendar = props.disableCalendar,
      open = props.open,
      bookingForm = props.bookingForm,
      selected = props.selected,
      onSelectDate = props.onSelectDate,
      selectTimeModeOptions = props.selectTimeModeOptions,
      selectedTimeMode = props.selectedTimeMode,
      interestedClicked = props.interestedClicked,
      globalStyling = props.globalStyling;

  var firstSelectable = new Date();

  var translatedDateModeOptions = selectDateModeOptions.map(function (opt) {
    return {
      value: opt.value,
      label: translations[opt.value]
    };
  });

  var translatedTimeModeOptions = selectTimeModeOptions.map(function (opt) {
    return {
      value: opt.value,
      label: translations[opt.value]
    };
  });

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      BorderedBlock,
      null,
      _react2.default.createElement(
        RadioGroupLabel,
        null,
        translations.testDriveSelectDateLabel
      ),
      _react2.default.createElement(
        HorizontalWrapper,
        null,
        _react2.default.createElement(_RadioGroup2.default, {
          horizontal: true,
          options: translatedDateModeOptions,
          value: asOption(selectedDateMode, selectedDateMode),
          onChange: onDateRadioGroupChange(props),
          globalStyling: globalStyling
        })
      ),
      _react2.default.createElement(
        Overlay,
        { disabled: disableCalendar },
        _react2.default.createElement(_DatePickerWrapper2.default, {
          open: open,
          date: bookingForm && bookingForm.date || firstSelectable,
          selected: selected,
          onSelectDate: onSelectDate,
          toggleCalendar: onToggleCalendarChange(props),
          globalStyling: globalStyling,
          translations: translations
        })
      ),
      _react2.default.createElement(
        SelectTimeWrapper,
        null,
        _react2.default.createElement(
          RadioGroupLabel,
          null,
          translations.testDriveSelectTimeLabel
        ),
        _react2.default.createElement(
          TimeRadioButtonsWrapper,
          null,
          _react2.default.createElement(_RadioGroup2.default, {
            options: translatedTimeModeOptions,
            value: asOption(selectedTimeMode, selectedTimeMode),
            onChange: onTimeRadioGroupChange(props),
            globalStyling: globalStyling
          })
        )
      )
    ),
    _react2.default.createElement(
      InterestedText,
      null,
      translations.testDriveOfferText
    ),
    _react2.default.createElement(
      CheckboxWrapper,
      null,
      _react2.default.createElement(_LabelledCheckBox2.default, {
        checked: bookingForm.interestedChecked,
        onClick: interestedClicked,
        label: translations.testDriveInterestedCheckboxLabel
      })
    )
  );
}