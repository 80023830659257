'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _reactRedux = require('react-redux');

var _enquiry = require('../../actions/enquiry');

var _settings = require('../../shared/selectors/settings');

var _vehicle = require('../../helpers/vehicle');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      redirect = _ref$config.redirect,
      properties = _ref$config.properties,
      template = _ref$config.template;

  var dispatch = (0, _reactRedux.useDispatch)();
  var vehicle = (0, _reactRedux.useSelector)(function (state) {
    return state.vehicleInContext;
  });
  var locale = (0, _reactRedux.useSelector)(_settings.getLocale);
  var path = (0, _reactRedux.useSelector)(function (state) {
    return state.router.path;
  });
  var hashRouting = (0, _reactRedux.useSelector)(function (state) {
    return state.config.settings.hashRouting;
  });
  var hash = (0, _reactRedux.useSelector)(function (state) {
    return state.router.hash;
  });

  var _split$reverse = (hashRouting ? hash : path).split('/').reverse(),
      _split$reverse2 = (0, _slicedToArray3.default)(_split$reverse, 1),
      vehicleId = _split$reverse2[0];

  (0, _react.useEffect)(function () {
    dispatch(_enquiry.actions.getEnquiryVehicleInfo(vehicleId));
  }, []);

  (0, _react.useEffect)(function () {
    if (vehicle && properties) {
      var formattedArgs = (0, _vehicle.urlTemplateFormatter)(properties, vehicle, locale, template);
      window.open(redirect + '/' + formattedArgs, '_blank');
      window.history.go(-1);
      return function () {
        dispatch(_enquiry.actions.clearEnquiryVehicleInfo());
      };
    }

    return function () {};
  }, [vehicle]);

  return null;
};