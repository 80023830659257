'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _vehicleTypes = require('../../../types/Bentley/vehicleTypes');

var _TableElements = require('./TableElements');

var _PhoneLink = require('../PhoneLink');

var _PhoneLink2 = _interopRequireDefault(_PhoneLink);

var _comparePageTypes = require('../../../types/Bentley/comparePageTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RetailerPhoneRow = function RetailerPhoneRow(_ref) {
  var comparedVehicles = _ref.comparedVehicles,
      config = _ref.config;
  return _react2.default.createElement(
    _TableElements.TableRow,
    null,
    _react2.default.createElement(_TableElements.TableDataFirstColumn, null),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        _TableElements.TableData,
        { key: '' + vehicle.id },
        _react2.default.createElement(
          'div',
          {
            style: {
              display: 'flex',
              alignItems: 'center',
              marginTop: '40px'
            }
          },
          _react2.default.createElement(_PhoneLink2.default, { vehicle: vehicle, iconPhoneUrl: config.iconPhoneUrl })
        )
      );
    })
  );
};

exports.default = RetailerPhoneRow;