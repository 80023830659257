'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useShortListVehicle2 = require('../../hooks/useShortListVehicle');

var _settings = require('../../shared/selectors/settings');

var _vehicleTypes = require('../../types/Bentley/vehicleTypes');

var _globalStyling = require('../../types/globalStyling');

var _styledComponentFactory = require('../Global/styledComponentFactory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/** Creating button with createElement to be able to use:
 * styleOverride?: () => string;
 * mobileStyleOverride?: () => { queryPath: string; template: string }[];
 */
var ButtonElement = (0, _styledComponentFactory.createElement)('button');

var variantStyles = function variantStyles(fonts) {
  var variant = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'icon';
  return {
    iconButtonText: (0, _styledComponents.css)(['border:solid 1px #335b4c !important;color:#222;font-family:', ';'], fonts.tertiaryButtonFont && fonts.tertiaryButtonFont.value)
  }[variant];
};

var BaseButton = (0, _styledComponents2.default)(ButtonElement).withConfig({
  displayName: 'ShortlistButton__BaseButton',
  componentId: 'sc-14q9x6z-0'
})(['display:flex;align-items:center;text-transform:uppercase;background-color:transparent;padding:0;margin:0;', ''], function (_ref) {
  var fonts = _ref.fonts,
      variant = _ref.variant;
  return variantStyles(fonts, variant);
});

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'ShortlistButton__Label',
  componentId: 'sc-14q9x6z-1'
})(['font-size:14px;text-transform:uppercase;line-height:14px;']);

var Button = function Button(props) {
  var text = props.text,
      children = props.children,
      styleOverride = props.styleOverride,
      mobileStyleOverride = props.mobileStyleOverride,
      vehicle = props.vehicle,
      iconOn = props.iconOn,
      iconOff = props.iconOff,
      rest = (0, _objectWithoutProperties3.default)(props, ['text', 'children', 'styleOverride', 'mobileStyleOverride', 'vehicle', 'iconOn', 'iconOff']);

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts,
      colours = _useSelector.colours;

  var _useShortListVehicle = (0, _useShortListVehicle2.useShortListVehicle)({
    vehicleId: vehicle.id
  }),
      isShortlisted = _useShortListVehicle.isShortlisted,
      toggleShortlist = _useShortListVehicle.toggleShortlist;

  return _react2.default.createElement(
    BaseButton,
    (0, _extends3.default)({
      onClick: function onClick() {
        return toggleShortlist(vehicle);
      },
      fonts: fonts,
      colours: colours
    }, rest, {
      styleOverride: styleOverride,
      mobileStyleOverride: mobileStyleOverride && (0, _styledComponentFactory.mediaProps)(mobileStyleOverride())
    }),
    _react2.default.createElement('img', {
      src: isShortlisted ? iconOn : iconOff,
      style: { width: '40px', height: '40px' },
      alt: ''
    }),
    _react2.default.createElement(
      Label,
      null,
      text || children
    )
  );
};

Button.defaultProps = {
  children: ''
};

exports.default = Button;