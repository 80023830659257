'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.default = SelectField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _settings = require('../../../shared/selectors/settings');

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldValidationError = require('./FieldValidationError');

var _FieldValidationError2 = _interopRequireDefault(_FieldValidationError);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'SelectField__Label',
  componentId: 'sc-1xb14nb-0'
})(['color:#000;font-size:14px;font-family:', ' font-weight:normal;position:absolute;left:0px;pointer-events:none;top:10px;transition:0.2s ease all;select:focus ~ &,select:not(:placeholder-shown).select:not(:focus) ~ .label,select:valid ~ &{top:-8px;font-size:12px;color:#000;}'], function (_ref) {
  var fonts = _ref.fonts;
  return fonts.secondaryFont.value;
});

// TODO: Chevron image must be updated as per design.
var Select = _styledComponents2.default.select.withConfig({
  displayName: 'SelectField__Select',
  componentId: 'sc-1xb14nb-1'
})(['color:#000;width:100%;height:45px;font-size:14px;font-family:', ';line-height:40px;box-sizing:border-box;background-color:transparent;border:0;&:disabled{border:none;-moz-appearance:none;-webkit-appearance:none;padding:0 8px;::-ms-expand{display:none;}}&:focus{color:#444;outline:none;}-webkit-appearance:none;-moz-appearance:none;background-image:url("data:image/svg+xml;utf8,<svg fill=\'black\' height=\'24\' viewBox=\'0 0 24 24\' width=\'24\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/><path d=\'M0 0h24v24H0z\' fill=\'none\'/></svg>");background-repeat:no-repeat;background-position-x:100%;background-position-y:10px;'], function (_ref2) {
  var fonts = _ref2.fonts;
  return fonts.secondaryFont.value;
});

function SelectField(_ref3) {
  var label = _ref3.label,
      required = _ref3.required,
      id = _ref3.id,
      onChange = _ref3.onChange,
      disabled = _ref3.disabled,
      options = _ref3.options,
      valueFromOption = _ref3.valueFromOption,
      labelFromOption = _ref3.labelFromOption,
      pleaseSelect = _ref3.pleaseSelect,
      error = _ref3.error,
      value = _ref3.value,
      dataCy = _ref3.dataCy;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    _FormField2.default,
    { required: required },
    _react2.default.createElement(
      Select,
      {
        onChange: function (_onChange) {
          function onChange(_x) {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function (_ref4) {
          var _ref4$target = _ref4.target,
              val = _ref4$target.value,
              op = _ref4$target.options,
              selectedIndex = _ref4$target.selectedIndex;

          var optionLabel = op[selectedIndex].innerText;
          onChange({ id: id, value: val, label: optionLabel });
        }),
        disabled: disabled,
        error: error,
        value: value,
        'data-cy': dataCy,
        fonts: fonts
      },
      [!value && pleaseSelect && _react2.default.createElement(
        'option',
        { key: 'default', value: null },
        pleaseSelect
      )].concat((0, _toConsumableArray3.default)(options.map(function (o) {
        var optionLabel = labelFromOption(o);
        var optionValue = valueFromOption(o);
        return _react2.default.createElement(
          'option',
          { key: '' + optionValue + optionLabel, value: optionValue },
          optionLabel
        );
      }))).filter(Boolean)
    ),
    _react2.default.createElement(
      Label,
      { fonts: fonts },
      label,
      required && '*'
    ),
    error && _react2.default.createElement(
      _FieldValidationError2.default,
      null,
      error
    )
  );
}

SelectField.defaultProps = {
  valueFromOption: function valueFromOption(i) {
    return i.value;
  },
  labelFromOption: function labelFromOption(i) {
    return i.label;
  },
  error: '',
  disabled: false,
  required: false
};