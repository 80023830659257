'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../Global');

require('../../../types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LinksList = _styledComponents2.default.ul.withConfig({
  displayName: 'LinksList',
  componentId: 'sc-1vneo94-0'
})(['padding:0;margin:0;']);

var MenuListItem = _styledComponents2.default.li.withConfig({
  displayName: 'LinksList__MenuListItem',
  componentId: 'sc-1vneo94-1'
})(['margin:0;padding:0 20px;list-style:none;display:inline-block;']);

exports.default = function (_ref) {
  var links = _ref.links,
      font = _ref.font;
  return _react2.default.createElement(
    LinksList,
    null,
    links.filter(function (link) {
      return link.url;
    }).map(function (link) {
      return _react2.default.createElement(
        MenuListItem,
        { key: link.label },
        _react2.default.createElement(
          _Global.Link,
          { styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(font);
            }, href: link.url },
          link.iconUrl && _react2.default.createElement('img', {
            src: link.iconUrl,
            backgroundSize: 'contain',
            height: '26',
            alt: link.label
          }),
          !link.iconUrl && link.label
        )
      );
    })
  );
};