'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var emptyTheme = {
  colour: '',
  textTransform: '',
  characterSpacing: '',
  fontSize: '',
  fontFamily: ''
};

var vehicleToCarResult = function vehicleToCarResult(v) {
  return (0, _extends3.default)({}, v, {
    odometer: v.specification && v.specification.odometer || v.odometer,
    engine: v.specification && v.specification.engine || v.engine,
    transmission: v.specification && v.specification.transmission || v.transmission,
    fuel: v.specification && v.specification.fuelType || v.fuel,
    price: v.price || v.price.originalValue || v.price.value,
    priceFull: 0,
    priceText: 'TODO',
    priceMonthly: v.price && v.price.monthly,
    monthlyText: 'TODO',
    priceApr: v.price && v.price.apr,
    aprText: 'TODO',
    locale: v.locale,
    currencyType: v.price && v.price.originalCurrency || v.price.currency,
    originalCurrency: v.price && v.price.originalCurrency,
    performance: v.performance,
    powerOutput: v.powerOutput,
    distanceFromRetailer: undefined,
    retailerName: v.retailerInformation && v.retailerInformation.name || v.retailerName,
    numberAvailable: 0,
    selected: false,
    modelTheme: emptyTheme,
    priceTheme: emptyTheme,
    numberAvailableTheme: emptyTheme,
    financeTextTheme: emptyTheme,
    align: 'left',
    equipmentMetaData: v.equipmentMetaData,
    exterior: v.specification && v.specification.exterior || v.exterior,
    interior: v.specification && v.specification.interior || v.interior,
    bodystyle: v.specification && v.specification.bodystyle,
    retailerPhone: v.retailerInformation && v.retailerInformation.phone,
    colourWithTrim: v.colourWithTrim,
    veneer: v.specification && v.specification.veneer,
    warranty: v.specification && v.specification.warranty,
    engineDetails: v.specification && v.specification.engineDetails || v.engineDetails,
    oemId: v.retailerInformation && v.retailerInformation.oemId
  });
};

exports.default = vehicleToCarResult;