'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding-left: 20px;\n    height: 200px;\n  '], ['\n    padding-left: 20px;\n    height: 200px;\n  ']);

exports.default = PageBanner;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _settings = require('../../shared/selectors/settings');

var _Global = require('../Global');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'PageBanner__Container',
  componentId: 'sc-1fbqkpr-0'
})(['background-image:', ';background-position:bottom;background-size:cover;height:380px;display:flex;padding:70px 0 0 70px;text-transform:uppercase;', ';'], function (_ref) {
  var imageUrl = _ref.imageUrl;
  return 'url(' + imageUrl + ')';
}, _theme2.default.max.small(_templateObject));

function PageBanner(_ref2) {
  var config = _ref2.config;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      Container,
      { imageUrl: config.imageUrl },
      _react2.default.createElement(
        _Global.HeadingOne,
        {
          styleOverride: function styleOverride() {
            return '\n              color: #ffffff; \n              font-size: 44px; \n              font-family: ' + fonts.heading1Font.value + '\n          ';
          },
          mobileStyleOverride: function mobileStyleOverride() {
            return [{
              queryPath: 'max.small',
              template: 'font-size: ' + fonts.mobileHeading1Font.fontSize + 'px; \n              font-family: ' + fonts.mobileHeading1Font.value
            }];
          }
        },
        config.translations.pageTitle
      )
    )
  );
}