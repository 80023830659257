'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TextAreaField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldError = require('./FieldError');

var _FieldError2 = _interopRequireDefault(_FieldError);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TextArea = _styledComponents2.default.textarea.withConfig({
  displayName: 'TextAreaField__TextArea',
  componentId: 'sc-1ikvjq3-0'
})(['width:100%;min-height:110px;font-size:16px;line-height:20px;box-sizing:border-box;border:1px solid ', ';color:#7e7e7e;background-color:#fff;padding:0px 15px;&:disabled{border:none;background-color:#d6d6d6;}&:focus{border:1px solid #999999;color:#444444;outline:none;}'], function (props) {
  return props.error ? '#BF0C0C' : '#d8d8d8';
});

function TextAreaField(_ref) {
  var label = _ref.label,
      value = _ref.value,
      onChange = _ref.onChange,
      onBlur = _ref.onBlur,
      placeholder = _ref.placeholder,
      error = _ref.error,
      required = _ref.required,
      disabled = _ref.disabled,
      font = _ref.font;

  return _react2.default.createElement(
    _FormField2.default,
    { label: label, required: required, font: font },
    _react2.default.createElement(TextArea, {
      value: value,
      onChange: onChange,
      onBlur: onBlur,
      disabled: disabled,
      placeholder: placeholder,
      error: error
    }),
    error && _react2.default.createElement(
      _FieldError2.default,
      null,
      error
    )
  );
}
TextAreaField.defaultProps = {
  error: ''
};