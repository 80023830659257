'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 48px 48px 48px 0;\n  '], ['\n    padding: 48px 48px 48px 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 30px;\n  '], ['\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 30px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _vehicle = require('../../helpers/vehicle');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _EmissionType = require('../../types/EmissionType');

var _vehicleTypes = require('../../types/vehicleTypes');

var _Accordion = require('./Accordion/Accordion');

var _Accordion2 = _interopRequireDefault(_Accordion);

var _VehicleEfficiency = require('./VehicleEfficiency');

var _VehicleEfficiency2 = _interopRequireDefault(_VehicleEfficiency);

var _VehicleEfficiencyClass = require('./VehicleEfficiencyClass');

var _VehicleEfficiencyClass2 = _interopRequireDefault(_VehicleEfficiencyClass);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__Wrapper',
  componentId: 'sc-1a1dwzg-0'
})(['display:flex;flex-direction:column;align-items:center;padding:24px;']);

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__Container',
  componentId: 'sc-1a1dwzg-1'
})(['display:flex;flex-direction:column;justify-content:center;align-items:center;max-width:1336px;width:100%;']);

var FeaturesHeader = _styledComponents2.default.h2.withConfig({
  displayName: 'VehicleFeatures__FeaturesHeader',
  componentId: 'sc-1a1dwzg-2'
})(['padding:0 24px 48px 0;font-family:AstonMartinFlare-Medium;font-size:16px;font-weight:normal;align-self:baseline;', ';'], _theme2.default.min.large(_templateObject));

var AccordionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__AccordionContainer',
  componentId: 'sc-1a1dwzg-3'
})(['display:flex;flex-direction:column;width:80%;max-width:1080px;justify-content:space-between;align-items:center;', ';'], _theme2.default.max.medium(_templateObject2));
var ItemWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__ItemWrapper',
  componentId: 'sc-1a1dwzg-4'
})(['display:grid;grid-template-columns:1fr;grid-gap:10px;margin-bottom:40px;', ';'], _theme2.default.min.medium(_templateObject3));

var UnderlinedItem = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__UnderlinedItem',
  componentId: 'sc-1a1dwzg-5'
})(['font-size:14px;border-bottom:0.5px solid #707070;font-family:AstonMartinSans-Light;padding-bottom:14px;width:100%;']);

exports.default = function (_ref) {
  var translations = _ref.translations,
      vehicle = _ref.vehicle,
      config = _ref.config;

  var _ref2 = vehicle.otherEquipment && vehicle.otherEquipment.find(function (_ref3) {
    var category = _ref3.category;
    return category === 'default';
  }) || {
    equipment: []
  },
      standardEquipment = _ref2.equipment;

  standardEquipment.push.apply(standardEquipment, (0, _toConsumableArray3.default)((0, _vehicle.formatEquipmentColours)(vehicle, translations)));

  var _useMemo = (0, _react.useMemo)(function () {
    var nedcConsumption = {
      category: translations.nedcConsumptions,
      items: [{
        label: translations.urban,
        value: vehicle.consumption.nedcConsumptionLow ? (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.nedcConsumptionLow) : undefined
      }, {
        label: translations.extraUrban,
        value: vehicle.consumption.nedcConsumptionMid ? (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.nedcConsumptionMid) : undefined
      }, {
        label: translations.nedcConsumptionCombined,
        value: vehicle.consumption.nedcConsumptionCombined ? (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.nedcConsumptionCombined) : undefined
      }]
    };

    var nedcEmissions = {
      category: translations.nedcEmissions,
      items: [{
        label: translations.urban,
        value: vehicle.emissions.nedcEmissionsLow ? vehicle.emissions.nedcEmissionsLow + ' g/km' : undefined
      }, {
        label: translations.extraUrban,
        value: vehicle.emissions.nedcEmissionsMid ? vehicle.emissions.nedcEmissionsMid + ' g/km' : undefined
      }, {
        label: translations.nedcEmissionsCombined,
        value: vehicle.emissions.nedcEmissionsCombined ? vehicle.emissions.nedcEmissionsCombined + ' g/km' : undefined
      }]
    };

    var wltpConsumptions = {
      category: translations.wltpConsumptions,
      items: [{
        label: translations.wltpConsumptionLow,
        value: vehicle.consumption.wltpConsumptionLow ? (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionLow) : undefined
      }, {
        label: translations.wltpConsumptionMid,
        value: vehicle.consumption.wltpConsumptionMid ? (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionMid) : undefined
      }, {
        label: translations.wltpConsumptionHigh,
        value: vehicle.consumption.wltpConsumptionHigh ? (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionHigh) : undefined
      }, {
        label: translations.wltpConsumptionExtraHigh,
        value: vehicle.consumption.wltpConsumptionExtraHigh ? (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionExtraHigh) : undefined
      }, {
        label: translations.wltpConsumptionCombined,
        value: vehicle.consumption.wltpConsumptionCombined ? (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionCombined) : undefined
      }]
    };

    var wltpEmissions = {
      category: translations.wltpEmissions,
      items: [{
        label: translations.wltpConsumptionLow,
        value: vehicle.emissions.wltpEmissionsLow ? vehicle.emissions.wltpEmissionsLow + ' g/km' : undefined
      }, {
        label: translations.wltpConsumptionMid,
        value: vehicle.emissions.wltpEmissionsMid ? vehicle.emissions.wltpEmissionsMid + ' g/km' : undefined
      }, {
        label: translations.wltpConsumptionHigh,
        value: vehicle.emissions.wltpEmissionsHigh ? vehicle.emissions.wltpEmissionsHigh + ' g/km' : undefined
      }, {
        label: translations.wltpConsumptionExtraHigh,
        value: vehicle.emissions.wltpEmissionsExtraHigh ? vehicle.emissions.wltpEmissionsExtraHigh + ' g/km' : undefined
      }, {
        label: translations.wltpConsumptionCombined,
        value: vehicle.emissions.wltpEmissionsCombined ? vehicle.emissions.wltpEmissionsCombined + ' g/km' : undefined
      }]
    };

    return [nedcConsumption.items.some(function (i) {
      return i.value;
    }) ? nedcConsumption : undefined, nedcEmissions.items.some(function (i) {
      return i.value;
    }) ? nedcEmissions : undefined, wltpConsumptions.items.some(function (i) {
      return i.value;
    }) ? wltpConsumptions : undefined, wltpEmissions.items.some(function (i) {
      return i.value;
    }) ? wltpEmissions : undefined,,];
  }, [vehicle]),
      _useMemo2 = (0, _slicedToArray3.default)(_useMemo, 4),
      nedcConsumption = _useMemo2[0],
      nedcEmissions = _useMemo2[1],
      wltpConsumptions = _useMemo2[2],
      wltpEmissions = _useMemo2[3];

  return _react2.default.createElement(
    Wrapper,
    null,
    standardEquipment && standardEquipment.length > 0 && _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        FeaturesHeader,
        null,
        translations.features
      ),
      _react2.default.createElement(
        AccordionContainer,
        null,
        _react2.default.createElement(
          _Accordion2.default,
          {
            title: translations.standardFeatures,
            isExpandable: true,
            isExpanded: true,
            titleCasingDefault: true,
            iconOpen: config.accordionPlusIconUrl,
            iconClose: config.accordionMinusIconUrl
          },
          _react2.default.createElement(
            ItemWrapper,
            null,
            standardEquipment.map(function (equipment, i) {
              return _react2.default.createElement(
                UnderlinedItem,
                { key: i + '-' + equipment.replace(/\s/g, '-') },
                equipment
              );
            })
          )
        ),
        vehicle.bespokeFeatures && _react2.default.createElement(
          _Accordion2.default,
          {
            title: translations.optionalFeatures,
            isExpandable: true,
            isExpanded: true,
            titleCasingDefault: true,
            iconOpen: config.accordionPlusIconUrl,
            iconClose: config.accordionMinusIconUrl
          },
          _react2.default.createElement(
            ItemWrapper,
            null,
            vehicle.bespokeFeatures.map(function (equipment, i) {
              return _react2.default.createElement(
                UnderlinedItem,
                {
                  key: i + '-' + equipment.name.replace(/\s/g, '-')
                },
                equipment.name
              );
            })
          )
        ),
        (nedcConsumption || nedcEmissions || wltpEmissions || wltpConsumptions || vehicle.energyEfficiencyRating) && _react2.default.createElement(
          _Accordion2.default,
          {
            title: translations.efficiency,
            isExpandable: true,
            isExpanded: true,
            iconOpen: config.accordionPlusIconUrl,
            iconClose: config.accordionMinusIconUrl
          },
          _react2.default.createElement(_VehicleEfficiency2.default, {
            nedcConsumption: nedcConsumption,
            nedcEmissions: nedcEmissions,
            wltpConsumptions: wltpConsumptions,
            wltpEmissions: wltpEmissions
          }),
          vehicle.energyEfficiencyRating && _react2.default.createElement(
            'div',
            {
              style: {
                marginTop: 40
              }
            },
            _react2.default.createElement(_VehicleEfficiencyClass2.default, {
              energyEfficiencyRating: vehicle.energyEfficiencyRating,
              energyEfficiencyClass: translations.energyEfficiencyClass,
              energyEfficiencyRatingMedia: config.energyEfficiencyRatingMedia,
              energyEfficiencyText: translations.energyEfficiencyText,
              disclaimer: translations.disclaimer
            })
          )
        )
      )
    )
  );
};