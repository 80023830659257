'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Plus = require('../../components/JaguarIcons/Global/Plus');

var _Plus2 = _interopRequireDefault(_Plus);

var _Minus = require('../../components/JaguarIcons/Global/Minus');

var _Minus2 = _interopRequireDefault(_Minus);

var _Shared = require('./Shared');

var _ComparedVehicleType = require('../../types/ComparedVehicleType');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var OptionalExtrasRow = function OptionalExtrasRow(_ref) {
  var translations = _ref.translations,
      label = _ref.label,
      comparedVehicles = _ref.comparedVehicles,
      optionalExtras = _ref.optionalExtras,
      toggleOptionalExtras = _ref.toggleOptionalExtras,
      font = _ref.font,
      optionalExtrasBackgroundColour = _ref.optionalExtrasBackgroundColour,
      optionalIconColourFont = _ref.optionalIconColourFont;
  return _react2.default.createElement(
    _Shared.TableRow,
    { fontSize: font && font.fontSize },
    _react2.default.createElement(
      _Shared.TableData,
      {
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: optionalExtrasBackgroundColour ? '' + optionalExtrasBackgroundColour.value : '#f0f0f0',
          borderWidth: '1px',
          color: font ? '' + font.colour.value : '#000000',
          textTransform: optionalIconColourFont ? 'capitalize' : 'uppercase',
          lineHeight: '40px',
          fontSize: font ? font.fontSize : 20
        }
      },
      label,
      _react2.default.createElement(
        _Shared.IconButton,
        {
          'aria-label': optionalExtras ? translations.collapseOptionalExtrasAriaLabel : translations.expandOptionalExtrasAriaLabel,
          onClick: toggleOptionalExtras,
          'aria-expanded': optionalExtras
        },
        optionalExtras ? _react2.default.createElement(_Minus2.default, {
          'aria-label': translations.collapseOptionalExtrasAriaLabel,
          'aria-expanded': optionalExtras,
          width: '2em',
          height: '2em',
          colour: optionalIconColourFont ? optionalIconColourFont.colour.value : '#7e7e7e'
        }) : _react2.default.createElement(_Plus2.default, {
          'aria-label': translations.expandOptionalExtrasAriaLabel,
          'aria-expanded': optionalExtras,
          width: '2em',
          height: '2em',
          colour: optionalIconColourFont ? optionalIconColourFont.colour.value : '#7e7e7e'
        })
      )
    ),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(_Shared.TableData, {
        key: '' + vehicle.id,
        style: {
          backgroundColor: optionalExtrasBackgroundColour ? '' + optionalExtrasBackgroundColour.value : '#f0f0f0'
        }
      });
    })
  );
};

exports.default = OptionalExtrasRow;