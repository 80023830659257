'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      margin: 0;\n      height: ', ';\n      width: 100%;\n      ', ';\n    '], ['\n      margin: 0;\n      height: ', ';\n      width: 100%;\n      ', ';\n    ']);

exports.default = Button;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _JaguarArrow = require('../../../assets/JaguarArrow');

var _JaguarArrow2 = _interopRequireDefault(_JaguarArrow);

var _KiaArrow = require('../../../assets/KiaArrow');

var _KiaArrow2 = _interopRequireDefault(_KiaArrow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ButtonStyles = {
  ButtonStyle1: function ButtonStyle1() {
    return '';
  },
  ButtonStyle2: function ButtonStyle2() {
    return '';
  },
  ButtonStyle3: function ButtonStyle3() {
    return '\n    border-radius: 15px;\n    justify-content: space-between;\n    height: 30px;\n    padding: 0;\n\n    span {\n      margin: 0 5px 1px 15px;\n      width: 100%;\n    }\n  ';
  },
  ButtonStyle4: function ButtonStyle4() {
    return '';
  },
  ButtonStyleBTT: function ButtonStyleBTT() {
    return '';
  },
  ButtonStyle5: function ButtonStyle5() {
    return '\n    border-radius: 2px;\n    opacity: 0.8;\n\n    &:hover {\n    opacity: 1;\n  }\n  ';
  },
  ButtonStyle6: function ButtonStyle6() {
    return '\n    transition: background-color 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);\n    border: none;\n    position: relative;\n    overflow: hidden;\n    display: inline-block;\n    padding: 8px 16px;\n    span {\n      transition: all 300ms cubic-bezier(0.46, 0.03, 0.52, 0.96);\n      position: relative;\n      left: 0;\n      line-height: 16px;\n    }\n    &:hover span {\n      left: -10px;\n    }\n    &:hover:after {\n      right: 10px;\n    }\n    &:after {\n      content: \'\';\n      right: -30px;\n      transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);\n      position: absolute;\n      line-height: 16px;\n      width: 16px;\n      height: 16px;\n      background-size: 16px 16px;\n      background-image: url(\'https://res.cloudinary.com/motortrak/image/upload/v1553264076/locator/lamborghini/content/dropdown_copy_3.png\');\n    }\n  ';
  }
};

var StyledButton = _styledComponents2.default.button.attrs(function (props) {
  return {
    className: 'global ' + props.applyStyle + ' ' + (props.disabled ? 'disabled' : '')
  };
}).withConfig({
  displayName: 'Button__StyledButton',
  componentId: 'sc-2ab8sv-0'
})(['&&&{', ';', ';', ';}'], _theme2.default.max.medium(_templateObject, function (_ref) {
  var buttonStyle = _ref.buttonStyle;
  return buttonStyle === 'ButtonStyle3' ? 'unset' : '41px';
}, function (_ref2) {
  var mobileStyleOverride = _ref2.mobileStyleOverride;
  return mobileStyleOverride && mobileStyleOverride();
}), function (_ref3) {
  var buttonStyle = _ref3.buttonStyle;
  return buttonStyle && ButtonStyles[buttonStyle]();
}, function (_ref4) {
  var styleOverride = _ref4.styleOverride;
  return styleOverride && styleOverride();
});

var ButtonText = _styledComponents2.default.span.withConfig({
  displayName: 'Button__ButtonText',
  componentId: 'sc-2ab8sv-1'
})(['margin:0 10px;']);

var ButtonTextStyleFour = _styledComponents2.default.span.withConfig({
  displayName: 'Button__ButtonTextStyleFour',
  componentId: 'sc-2ab8sv-2'
})(['margin:0 5px;']);

var ButtonStyleContent = {
  ButtonStyle1: function ButtonStyle1(text) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonText,
        null,
        text
      ),
      _react2.default.createElement('div', null)
    );
  },
  ButtonStyle2: function ButtonStyle2(text) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonText,
        null,
        text
      ),
      _react2.default.createElement(_JaguarArrow2.default, { width: '1.2em', height: '1.2em' })
    );
  },
  ButtonStyle3: function ButtonStyle3(text) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonText,
        null,
        text
      ),
      _react2.default.createElement(_KiaArrow2.default, { width: '1.7em', height: '1.7em' })
    );
  },
  ButtonStyle4: function ButtonStyle4(text) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(_JaguarArrow2.default, { width: '10px', height: '10px' }),
      _react2.default.createElement(
        ButtonTextStyleFour,
        null,
        text
      )
    );
  },
  ButtonStyleBTT: function ButtonStyleBTT(text) {
    return text;
  },
  ButtonStyle5: function ButtonStyle5(text) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonText,
        null,
        text
      )
    );
  },
  ButtonStyle6: function ButtonStyle6(text) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonText,
        null,
        text
      )
    );
  }
};

function Button(_ref5) {
  var buttonStyle = _ref5.buttonStyle,
      text = _ref5.text,
      styleOverride = _ref5.styleOverride,
      mobileStyleOverride = _ref5.mobileStyleOverride,
      applyStyle = _ref5.applyStyle,
      onClick = _ref5.onClick,
      props = (0, _objectWithoutProperties3.default)(_ref5, ['buttonStyle', 'text', 'styleOverride', 'mobileStyleOverride', 'applyStyle', 'onClick']);

  return _react2.default.createElement(
    StyledButton,
    (0, _extends3.default)({}, props, {
      buttonStyle: buttonStyle,
      styleOverride: styleOverride,
      mobileStyleOverride: mobileStyleOverride,
      applyStyle: applyStyle,
      onClick: onClick
    }),
    ButtonStyleContent[buttonStyle](text)
  );
}

Button.defaultProps = {
  styleOverride: null,
  mobileStyleOverride: null,
  buttonStyle: 'ButtonStyle2',
  text: '',
  applyStyle: 'primary'
};