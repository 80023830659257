'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    height: 38px;\n  '], ['\n    height: 38px;\n  ']);

exports.default = SearchResultGridTile;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _helpers = require('../shared/currencyConversion/helpers');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('./Global');

var _IconWrapper = require('./IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

var _CarfaxLogo = require('./RollsRoyce/CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

var _SearchResultGridBlock = require('./SearchResultGridBlock');

var _StyledIcon = require('./StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _Tooltip = require('./Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TileItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__TileItemContainer',
  componentId: 'sc-1y9od53-0'
})(['background-color:', ';display:flex;flex-direction:column;border:1px solid #CCCCCC;flex:1 0 0%;height:100%;justify-content:flex-start;width:100%;}'], function (_ref) {
  var backgroundColor = _ref.backgroundColor;
  return backgroundColor && '' + backgroundColor;
});

var BottomContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__BottomContainer',
  componentId: 'sc-1y9od53-1'
})(['display:flex;flex-direction:column;flex-grow:1;']);

var HorizontalLine = _styledComponents2.default.hr.withConfig({
  displayName: 'SearchResultGridTile__HorizontalLine',
  componentId: 'sc-1y9od53-2'
})(['display:', ';height:1px;border:0;border-top:1px solid #dedede;margin:0;padding:0;width:100%;'], function (_ref2) {
  var visible = _ref2.visible;
  return visible === true ? 'block' : 'none';
});

var MoreInfoRow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__MoreInfoRow',
  componentId: 'sc-1y9od53-3'
})(['display:flex;align-items:center;padding:8px 16px;justify-content:space-between;']);

var FinanceRow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__FinanceRow',
  componentId: 'sc-1y9od53-4'
})(['display:flex;flex-grow:1;justify-content:space-between;align-items:center;max-width:100%;padding:8px 0 8px 16px;']);

var TitleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__TitleContainer',
  componentId: 'sc-1y9od53-5'
})(['display:flex;flex-wrap:wrap;flex-direction:column;justify-content:space-between;padding:8px 0 8px 16px;', ';'], function (_ref3) {
  var badgeBackgroundColour = _ref3.badgeBackgroundColour;
  return badgeBackgroundColour && '\n    background-color: ' + badgeBackgroundColour + ';\n    color:#FFFFFF;';
});

var VehicleSpecs = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__VehicleSpecs',
  componentId: 'sc-1y9od53-6'
})(['display:flex;flex-direction:column;justify-content:space-around;position:relative;']);

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultGridTile__Logo',
  componentId: 'sc-1y9od53-7'
})(['height:28px;margin:10px;', ';'], _theme2.default.min.small(_templateObject));

var PriceWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__PriceWrapper',
  componentId: 'sc-1y9od53-8'
})(['text-align:left;padding:1px 0;']);

var OtherPrice = (0, _styledComponents2.default)(PriceWrapper).withConfig({
  displayName: 'SearchResultGridTile__OtherPrice',
  componentId: 'sc-1y9od53-9'
})(['', ';', ';', ';', ';'], function (_ref4) {
  var font = _ref4.font;
  return font && font.fontSize && 'font-size: ' + font.fontSize + 'px';
}, function (_ref5) {
  var font = _ref5.font;
  return font && font.colour && 'color: ' + font.colour.value;
}, function (_ref6) {
  var font = _ref6.font;
  return font && font.typeface && 'font-family: ' + font.typeface.value;
}, function (_ref7) {
  var font = _ref7.font;
  return font && font.kerning && 'letter-spacing: ' + font.kerning + 'px';
});

var TitleRow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__TitleRow',
  componentId: 'sc-1y9od53-10'
})(['display:flex;padding-top:8px;line-height:16px;']);

var VdpButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__VdpButtonWrapper',
  componentId: 'sc-1y9od53-11'
})(['display:flex;justify-content:flex-end;justify-content:', ';width:50%;margin:5px;', ';'], function (_ref8) {
  var flex = _ref8.flex;
  return flex || 'flex-end';
}, function (_ref9) {
  var fillSection = _ref9.fillSection;
  return fillSection && 'width: 100%';
});

var BespokeFeatureLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__BespokeFeatureLabel',
  componentId: 'sc-1y9od53-12'
})(['', ';padding-top:8px;'], function (_ref10) {
  var font = _ref10.font;
  return font && (0, _Global.fontStyleOverride)(font);
});

var CollectionsLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__CollectionsLabel',
  componentId: 'sc-1y9od53-13'
})(['line-height:16px;text-transform:uppercase;letter-spacing:0.57px;color:#281432;font-size:12px;font-family:RivieraNights-Medium,Jost-Medium;']);

var PriceWithTooltip = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridTile__PriceWithTooltip',
  componentId: 'sc-1y9od53-14'
})(['display:flex;gap:10px;align-items:center;']);

function SearchResultGridTile(_ref11) {
  var config = _ref11.config,
      align = _ref11.align,
      vehicle = _ref11.vehicle,
      placeholdingImage = _ref11.placeholdingImage,
      translations = _ref11.translations,
      renderTopRightIcon = _ref11.renderTopRightIcon,
      compareVehicle = _ref11.compareVehicle,
      shortlistVehicle = _ref11.shortlistVehicle,
      shortListedIconColour = _ref11.shortListedIconColour,
      compareIconColour = _ref11.compareIconColour,
      onMoreInfoClicked = _ref11.onMoreInfoClicked,
      globalStyling = _ref11.globalStyling,
      visibility = _ref11.visibility,
      locale = _ref11.locale,
      headerItemFont = _ref11.headerItemFont,
      badgeStyling = _ref11.badgeStyling,
      handleZeroPriceAsText = _ref11.handleZeroPriceAsText,
      showNumberOfImagesInsteadOfViews = _ref11.showNumberOfImagesInsteadOfViews,
      icon360 = _ref11.icon360,
      iconYoutubeVideo = _ref11.iconYoutubeVideo,
      onEnquiryClick = _ref11.onEnquiryClick;
  var status = vehicle.status;


  var country = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences.location && state.shared.sessionPreferences.location.userCountry;
  });

  var imageSrc = vehicle.images.length > 0 ? vehicle.images[0] : placeholdingImage;
  var badge = badgeStyling && badgeStyling.badgeVariant && vehicle.modelVariant && vehicle.modelVariant.toLowerCase().includes(badgeStyling.badgeVariant.toLowerCase());

  var showModelAndVariant = badgeStyling && badgeStyling.displayModelAndVariant && vehicle.modelVariant !== null;

  var displayExteriorSection = vehicle.exterior || vehicle.interior || vehicle.veneer;

  var showSalesTaxDisclaimer = config.showSectionSalesTaxDisclaimer && !!vehicle.sectionSalesTaxDisclaimer;

  var handlePrice = (0, _helpers.handleVehiclePrice)(!showSalesTaxDisclaimer ? vehicle.price.value : vehicle.netPrice || vehicle.price.value, {
    currencyType: vehicle.price.currency,
    locale: locale.replace('_', '-'),
    handleZeroPriceAsText: handleZeroPriceAsText,
    priceSpecial: vehicle.priceSpecial,
    translationsSold: translations.sold,
    vehiclePrice: vehicle.price.value,
    zeroPriceText: translations.zeroPriceText
  });

  var netPrice = vehicle.netPrice ? (0, _helpers.handleVehiclePrice)(vehicle.netPrice, {
    currencyType: vehicle.price.currency,
    locale: locale.replace('_', '-'),
    handleZeroPriceAsText: config.handleZeroPriceAsText,
    priceSpecial: vehicle.priceSpecial,
    zeroPriceText: translations.zeroPriceText,
    translationsSold: translations.sold,
    vehiclePrice: vehicle.price.value
  }) : undefined;
  return _react2.default.createElement(
    TileItemContainer,
    { 'data-cy': 'vehicle-tile', backgroundColor: 'white' },
    _react2.default.createElement(
      TitleContainer,
      {
        badgeBackgroundColour: badge && badgeStyling && badgeStyling.badgeBackgroundColour
      },
      _react2.default.createElement(
        TitleRow,
        null,
        _react2.default.createElement(_SearchResultGridBlock.VehicleTitle, {
          vehicle: vehicle,
          titleFont: headerItemFont && headerItemFont.titleFont,
          subtitleFont: headerItemFont && headerItemFont.subtitleFont,
          visible: visibility,
          badge: badge,
          globalStyling: globalStyling,
          badgeStyling: badgeStyling,
          displayModelAndVariant: showModelAndVariant
        })
      ),
      _react2.default.createElement(
        TitleRow,
        null,
        vehicle.modelCollectionName && _react2.default.createElement(
          CollectionsLabel,
          null,
          vehicle.modelCollectionName
        )
      ),
      _react2.default.createElement(
        TitleRow,
        null,
        _react2.default.createElement(_SearchResultGridBlock.VehicleSubTitle, {
          vehicle: vehicle,
          locale: locale,
          titleFont: headerItemFont && headerItemFont.titleFont,
          subtitleFont: headerItemFont && headerItemFont.subtitleFont,
          visible: visibility,
          badge: badge,
          globalStyling: globalStyling,
          badgeStyling: badgeStyling,
          translations: translations
        })
      )
    ),
    status === 'sold' && renderTopRightIcon(),
    _react2.default.createElement(_SearchResultGridBlock.ImageTileSection, {
      vehicle: vehicle,
      imageSrc: imageSrc,
      translations: translations,
      onMoreInfoClick: onMoreInfoClicked,
      width: '100%',
      showNumberOfImages: showNumberOfImagesInsteadOfViews,
      icon: config.imagesIcon,
      icon360: icon360,
      iconYoutubeVideo: iconYoutubeVideo,
      globalStyling: globalStyling,
      shortlistVehicle: shortlistVehicle,
      compareVehicle: compareVehicle,
      compareIconColour: compareIconColour,
      shortListedIconColour: shortListedIconColour,
      config: config
    }),
    _react2.default.createElement(
      BottomContainer,
      { align: align },
      _react2.default.createElement(
        FinanceRow,
        null,
        _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            PriceWrapper,
            null,
            _react2.default.createElement(
              PriceWithTooltip,
              null,
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return badge && headerItemFont ? (0, _Global.fontStyleOverride)(badgeStyling.titleFont) : (0, _Global.fontStyleOverride)(headerItemFont.titleFont);
                  }
                },
                handlePrice
              ),
              netPrice && showSalesTaxDisclaimer && _react2.default.createElement(
                'div',
                { style: { paddingTop: 4 } },
                _react2.default.createElement(
                  _Tooltip2.default,
                  { text: translations.salesTaxDisclaimer },
                  _react2.default.createElement(
                    _IconWrapper2.default,
                    null,
                    _react2.default.createElement(_StyledIcon2.default, { width: 20, height: 20, link: config.infoIcon })
                  )
                )
              )
            ),
            vehicle.bespoke === 1 && _react2.default.createElement(
              BespokeFeatureLabel,
              { font: config.bespokeFeatureLabelFont },
              translations.bespokeFeatureLabel
            )
          ),
          vehicle.carfax && _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicle.carfax }),
          netPrice && !showSalesTaxDisclaimer && _react2.default.createElement(
            _react2.default.Fragment,
            null,
            _react2.default.createElement(
              OtherPrice,
              { font: config && config.mobilePriceWrapperFont },
              (0, _translateFromTemplate.translateFromTemplate)('netPrice', { VALUE: netPrice }, translations)
            ),
            vehicle.retailerCountryCode === 'DE' && _react2.default.createElement(
              OtherPrice,
              { font: config && config.mobilePriceWrapperFont },
              _react2.default.createElement(
                'div',
                null,
                translations.netPriceLabel
              )
            )
          )
        ),
        _react2.default.createElement(Logo, { src: config.tileLogo })
      ),
      _react2.default.createElement(HorizontalLine, { visible: true }),
      displayExteriorSection && _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          FinanceRow,
          null,
          _react2.default.createElement(
            VehicleSpecs,
            null,
            _react2.default.createElement(_SearchResultGridBlock.ExteriorSection, {
              vehicle: vehicle,
              translations: translations,
              icon: config.coloursIcon,
              config: config
            })
          )
        ),
        _react2.default.createElement(HorizontalLine, { visible: true })
      ),
      vehicle.bespokeFeatures && _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          FinanceRow,
          null,
          _react2.default.createElement(_SearchResultGridBlock.BespokeFeatureSection, {
            vehicle: vehicle,
            icon: config.designIcon,
            config: config
          })
        ),
        _react2.default.createElement(HorizontalLine, { visible: true })
      ),
      visibility.showDistance && _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          FinanceRow,
          null,
          _react2.default.createElement(_SearchResultGridBlock.RetailerSection, {
            vehicle: vehicle,
            icon: config.pinIcon,
            translations: translations,
            config: config,
            country: country
          })
        ),
        _react2.default.createElement(HorizontalLine, { visible: true })
      ),
      _react2.default.createElement(
        MoreInfoRow,
        null,
        status !== 'sold' && _react2.default.createElement(
          VdpButtonWrapper,
          {
            flex: 'flex-start',
            fillSection: config.hideEnquiry
          },
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'more-info-button',
            onClick: status !== 'sold' && onMoreInfoClicked,
            text: translations.searchResultTileMoreInfo,
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            applyStyle: 'secondary',
            styleOverride: function styleOverride() {
              return 'white-space: nowrap; width: 100%';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return 'span { margin: 0!important }';
            }
          })
        ),
        !config.hideEnquiry && _react2.default.createElement(
          VdpButtonWrapper,
          null,
          _react2.default.createElement(_Global.Button, {
            onClick: function onClick() {
              return onEnquiryClick();
            },
            text: translations.searchResultTileEnquire,
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return 'white-space: nowrap; ; width: 100%';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return 'span { margin: 0!important }';
            }
          })
        )
      )
    )
  );
}

SearchResultGridTile.defaultProps = {
  onMoreInfoClicked: function onMoreInfoClicked() {},
  modelTheme: {},
  numberAvailableTheme: {},
  financeTextTheme: {},
  images: [],
  renderTopRightIcon: function renderTopRightIcon() {},
  price: {},
  buttonTheme: {
    fontFamily: 'inherit'
  }
};