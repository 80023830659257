'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useOutsideClick = require('../../../../hooks/useOutsideClick');

var _useOutsideClick2 = _interopRequireDefault(_useOutsideClick);

var _numbers = require('../../../../shared/localisation/numbers');

var _translateFromTemplate = require('../../../../shared/localisation/translateFromTemplate');

var _AppState = require('../../../../types/AppState');

var _searchResultsTypes = require('../../../../types/Genesis/searchResultsTypes');

var _filtersTypes = require('../../../../types/Genesis/filtersTypes');

var _DropdownlistIcon = require('./DropdownlistIcon');

var _DropdownlistIcon2 = _interopRequireDefault(_DropdownlistIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__Container',
  componentId: 'sc-hsuohe-0'
})(['border-bottom:1px solid #fff;box-sizing:border-box;color:#fff;display:flex;flex-direction:column;padding:20px 0 10px;position:relative;width:100%;']);

var SelectContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__SelectContainer',
  componentId: 'sc-hsuohe-1'
})(['align-items:center;box-sizing:border-box;cursor:pointer;display:flex;font-family:\'GenesisSansHeadGlobal-Regular\';font-weight:normal;justify-content:space-between;width:100%;span{font-size:16px;text-transform:uppercase;}']);

var ExpandContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__ExpandContainer',
  componentId: 'sc-hsuohe-2'
})(['background-color:#000;box-sizing:border-box;overflow-y:auto;position:relative;width:100%;z-index:2;']);

var Option = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__Option',
  componentId: 'sc-hsuohe-3'
})(['padding:8px 5px;&:hover{background-color:#4d4d4d;color:#fff;}']);

var SupTitle = _styledComponents2.default.sup.withConfig({
  displayName: 'DistanceFilter__SupTitle',
  componentId: 'sc-hsuohe-4'
})(['font-size:10px;font-family:\'GenesisSansHeadGlobal-Regular\';margin-bottom:5px;text-transform:uppercase;']);

var LocationDistanceFilter = function LocationDistanceFilter(_ref) {
  var updateFilters = _ref.updateFilters,
      translations = _ref.translations,
      availableFilterValues = _ref.availableFilterValues,
      arrowUpIcon = _ref.arrowUpIcon,
      arrowDownIcon = _ref.arrowDownIcon,
      innerRef = _ref.innerRef;
  var distanceDefaultOption = translations.distanceDefaultOption;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      toggleOpen = _useState2[1];

  var _useState3 = (0, _react.useState)({
    value: undefined,
    label: distanceDefaultOption
  }),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      selectedValue = _useState4[0],
      setSelectedValue = _useState4[1];

  var isClickOutside = (0, _useOutsideClick2.default)(innerRef);
  var subregions = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.filters.selectedFilters ? state.shared.filters.selectedFilters.subregions : undefined;
  });

  (0, _react.useEffect)(function () {
    if ((subregions || []).length === 0) {
      setSelectedValue({
        value: undefined,
        label: (0, _translateFromTemplate.translateFromTemplate)('distanceDefaultOption', {}, translations)
      });

      updateFilters([{
        key: 'maxDistance',
        value: undefined
      }]);
    }
  }, [subregions, _translateFromTemplate.translateFromTemplate, translations]);

  (0, _react.useEffect)(function () {
    if (open && isClickOutside) {
      toggleOpen(false);
    }
  }, [isClickOutside, open]);

  var handleClick = (0, _react.useCallback)(function (option) {
    // @ts-ignore: Can't type hook state.
    setSelectedValue(option);
    toggleOpen(!open);
    updateFilters([{
      key: 'maxDistance',
      value: option.value ? Math.ceil((0, _numbers.convertMilesToKm)(option.value)) : []
    }]);
  }, [open]);

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      SupTitle,
      null,
      translations.distanceSup
    ),
    _react2.default.createElement(
      SelectContainer,
      { onClick: function onClick() {
          return toggleOpen(!open);
        } },
      _react2.default.createElement(
        'span',
        null,
        selectedValue.label
      ),
      _react2.default.createElement(_DropdownlistIcon2.default, {
        toggle: open,
        openIcon: arrowUpIcon,
        closeIcon: arrowDownIcon
      })
    ),
    open ? _react2.default.createElement(
      ExpandContainer,
      null,
      _react2.default.createElement(
        'div',
        {
          style: {
            display: 'flex',
            flexDirection: 'column',
            padding: '4px 0',
            fontSize: '14px',
            cursor: 'pointer'
          }
        },
        availableFilterValues.map(function (option) {
          var label = (0, _translateFromTemplate.translateFromTemplate)('distances', {
            MILES: option
          }, translations);
          return _react2.default.createElement(
            Option,
            {
              key: option,
              onClick: function onClick() {
                handleClick({ value: option, label: label });
              }
            },
            _react2.default.createElement(
              'span',
              null,
              label
            )
          );
        }),
        _react2.default.createElement(
          Option,
          {
            onClick: function onClick() {
              handleClick({ value: undefined, label: distanceDefaultOption });
            }
          },
          _react2.default.createElement(
            'span',
            null,
            distanceDefaultOption
          )
        )
      )
    ) : null
  );
};

exports.default = LocationDistanceFilter;