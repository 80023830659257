'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Rating = undefined;

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'Rating__Row',
  componentId: 'sc-1ml08z4-0'
})(['display:flex;height:100%;align-items:center;']);

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'Rating__Icon',
  componentId: 'sc-1ml08z4-1'
})(['height:20px;margin-left:2px;']);

var Rating = function Rating(_ref) {
  var rating = _ref.rating,
      max = _ref.max,
      fill = _ref.fill,
      half = _ref.half,
      empty = _ref.empty;

  var stars = [];
  for (var i = 0; i < max; i++) {
    stars = [].concat((0, _toConsumableArray3.default)(stars), [_react2.default.createElement(Icon, {
      alt: rating - i >= 1 ? 'star' : rating - i > 0 ? 'half star' : 'no star',
      src: '' + (rating - i >= 1 ? fill : rating - i > 0 ? half : empty)
    })]);
  }

  return _react2.default.createElement(
    Row,
    null,
    stars
  );
};

exports.Rating = Rating;