'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _searchFilter = require('../shared/searchFilter');

var _sessionPreferences = require('../shared/sessionPreferences');

var _router = require('../actions/router');

var _StickySearchBar = require('../components/StickySearchBar');

var _StickySearchBar2 = _interopRequireDefault(_StickySearchBar);

var _searchFiltersHelper = require('../components/SearchFilters/search-filters-helper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mapStateToProps = function mapStateToProps(_ref) {
  var shared = _ref.shared;
  return {
    filterSearchUrl: shared.searchFilter.filterSearchUrl,
    maxDistance: shared.searchFilter.maxDistance,
    location: shared.sessionPreferences.location || {},
    distanceOptions: _searchFiltersHelper.distanceOptions
  };
};


var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    resetFilters: function resetFilters() {
      return dispatch(_searchFilter.actions.resetFilters());
    },
    handleLocationChange: function handleLocationChange(_ref2) {
      var latitude = _ref2.latitude,
          longitude = _ref2.longitude,
          locationTown = _ref2.locationTown;

      dispatch(_sessionPreferences.actions.updateSessionPreferences('location', {
        latitude: latitude,
        longitude: longitude,
        locationTown: locationTown
      }));
    },
    handleMaxDistanceChange: function handleMaxDistanceChange(maxDistance) {
      return dispatch(_searchFilter.actions.changeMaxDistance({ maxDistance: maxDistance }));
    },
    buttonPress: function buttonPress() {
      return dispatch(_router.actions.navigate('searchresults'));
    }
  };
};

function SearchFilterLocationSearchBarContainer(_ref3) {
  var locationIconUrl = _ref3.locationIconUrl;

  return _react2.default.createElement(_StickySearchBar2.default, { locationIconUrl: locationIconUrl });
}

exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_StickySearchBar2.default, SearchFilterLocationSearchBarContainer);