'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 36px;\n  '], ['\n    font-size: 36px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useLocation2 = require('../../../hooks/useLocation');

var _useLocation3 = _interopRequireDefault(_useLocation2);

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _modelTypes = require('../../../types/AstonMartin/modelTypes');

var _Spinner = require('../../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _AstonLink = require('../AstonLink');

var _AstonLink2 = _interopRequireDefault(_AstonLink);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__Wrapper',
  componentId: 'sc-jkuu71-0'
})(['flex-direction:column;display:flex;width:100%;']);

var LocationSuggestions = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__LocationSuggestions',
  componentId: 'sc-jkuu71-1'
})(['position:absolute;width:100%;top:40px;background-color:rgba(0,0,0,0.1);border:1px solid #ffffff;box-sizing:border-box;z-index:10;']);

var Suggestion = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__Suggestion',
  componentId: 'sc-jkuu71-2'
})(['font-size:12px;padding:5px;cursor:pointer;outline:none;', ';&:hover{background-color:', ';}&:last-child{border-bottom:none;}'], function (_ref) {
  var inFocus = _ref.inFocus,
      color = _ref.color;
  return inFocus && 'background-color: ' + color + ';';
}, function (_ref2) {
  var color = _ref2.color;
  return color;
});

var ActionWrapper = _styledComponents2.default.button.withConfig({
  displayName: 'OverlaySearch__ActionWrapper',
  componentId: 'sc-jkuu71-3'
})(['background-color:transparent;border:0;cursor:pointer;padding:', ';display:flex;flex:0 0 auto;font-size:16px;font-family:AstonMartinFlare-Regular;color:#fff;text-transform:uppercase;'], function (_ref3) {
  var position = _ref3.position;
  return position === 'start' ? '0 10px 0 0' : '0 0 0 10px';
});

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__Title',
  componentId: 'sc-jkuu71-4'
})(['display:flex;flex-direction:column;text-transform:uppercase;font-family:AstonMartinFlare-Regular;font-size:22px;', ';'], _theme2.default.min.large(_templateObject));

var InputWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__InputWrapper',
  componentId: 'sc-jkuu71-5'
})(['display:flex;position:relative;flex-direction:row;align-items:center;border-bottom:solid 0.5px #ffffff;margin-top:20px;']);

var Input = _styledComponents2.default.input.withConfig({
  displayName: 'OverlaySearch__Input',
  componentId: 'sc-jkuu71-6'
})(['padding:10px 0;display:block;width:100%;background-color:transparent;border:0;color:#ffffff;&::placeholder{color:#ffffff;font-size:16px;}&:focus{outline:none;}']);

var SpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__SpinnerWrapper',
  componentId: 'sc-jkuu71-7'
})(['margin:0 10px;']);

var OverlaySearch = function OverlaySearch(_ref4) {
  var iconUrl = _ref4.iconUrl,
      browserLocationCallback = _ref4.browserLocationCallback,
      inputLocationCallback = _ref4.inputLocationCallback,
      translations = _ref4.translations,
      onButtonClick = _ref4.onButtonClick;

  var _useLocation = (0, _useLocation3.default)(browserLocationCallback, inputLocationCallback, {}),
      formattedLocation = _useLocation.formattedLocation,
      getBrowserLocation = _useLocation.getBrowserLocation,
      getInputLocation = _useLocation.getInputLocation,
      onInputChange = _useLocation.onInputChange,
      suggestions = _useLocation.suggestions,
      visible = _useLocation.visible,
      currentlySelected = _useLocation.currentlySelected,
      setCurrentlySelected = _useLocation.setCurrentlySelected,
      inputRef = _useLocation.inputRef,
      onInputKeyDown = _useLocation.onInputKeyDown,
      onInputBlur = _useLocation.onInputBlur,
      onInputFocus = _useLocation.onInputFocus,
      isLoading = _useLocation.isLoading;

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      colours = _useSelector.colours;

  var ref = (0, _react.useRef)(inputRef);

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      Title,
      null,
      translations.locationPromptTitle
    ),
    _react2.default.createElement(
      InputWrapper,
      null,
      _react2.default.createElement(
        ActionWrapper,
        { position: 'start', onClick: getBrowserLocation },
        _react2.default.createElement('img', { src: iconUrl, height: 18, width: 18, alt: 'location-icon' })
      ),
      _react2.default.createElement(Input, {
        id: 'locationSearch',
        type: 'text',
        'data-cy': 'user-location-input',
        autoComplete: 'off',
        ref: ref,
        placeholder: translations.locationPlaceholder,
        onChange: onInputChange,
        onKeyDown: onInputKeyDown,
        onBlur: function onBlur(event) {
          return onInputBlur(event, translations.locationPlaceholder);
        },
        onFocus: onInputFocus,
        value: formattedLocation
      }),
      suggestions.length > 0 && formattedLocation.length > 0 && visible && _react2.default.createElement(
        LocationSuggestions,
        null,
        suggestions.map(function (s, index) {
          return _react2.default.createElement(
            Suggestion,
            {
              color: colours.primaryBrandColour.value,
              role: 'button',
              'data-cy': 'user-location-suggestion-list-option',
              onMouseDown: function onMouseDown() {
                setCurrentlySelected(0);
                getInputLocation(s);
              },
              onMouseEnter: function onMouseEnter() {
                return setCurrentlySelected(index);
              },
              inFocus: currentlySelected === index,
              key: s.description
            },
            s.description
          );
        })
      ),
      isLoading && _react2.default.createElement(
        SpinnerWrapper,
        null,
        _react2.default.createElement(_Spinner2.default, { size: 10, colour: colours.primaryBrandColour })
      ),
      _react2.default.createElement(
        ActionWrapper,
        { position: 'end', onClick: onButtonClick },
        _react2.default.createElement(_AstonLink2.default, { text: translations.searchCTAOverlay, colour: 'white' })
      )
    )
  );
};

exports.default = OverlaySearch;