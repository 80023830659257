'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactExpandAnimated = require('react-expand-animated');

var _reactExpandAnimated2 = _interopRequireDefault(_reactExpandAnimated);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _AccordionHeader = require('./AccordionHeader');

var _AccordionHeader2 = _interopRequireDefault(_AccordionHeader);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ExpandWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Accordion__ExpandWrapper',
  componentId: 'sc-1qml64p-0'
})(['width:100%;box-sizing:border-box;font-family:AstonMartinFlare-Medium;']);

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Accordion__Wrapper',
  componentId: 'sc-1qml64p-1'
})(['display:flex;justify-content:center;width:100%;']);

var Accordion = function Accordion(_ref) {
  var children = _ref.children,
      isExpanded = _ref.isExpanded,
      title = _ref.title,
      isExpandable = _ref.isExpandable,
      iconOpen = _ref.iconOpen,
      iconClose = _ref.iconClose;

  var _useState = (0, _react.useState)(isExpanded),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      ExpandWrapper,
      null,
      _react2.default.createElement(_AccordionHeader2.default, {
        title: title,
        isExpandable: isExpandable,
        onClick: function onClick() {
          return setOpen(!open);
        },
        isExpanded: open,
        iconOpen: iconOpen,
        iconClose: iconClose
      }),
      _react2.default.createElement(
        _reactExpandAnimated2.default,
        { open: isExpandable ? open : true, duration: 300 },
        children
      )
    )
  );
};

exports.default = Accordion;