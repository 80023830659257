'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactSwipeable = require('react-swipeable');

var _useCarousel2 = require('./useCarousel');

var _useModal2 = require('../../shared/hooks/useModal');

var _useScript = require('./useScript');

var _components = require('../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CarouselBackground = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__CarouselBackground',
  componentId: 'sc-mh1hcd-0'
})(['display:flex;width:100%;height:100%;flex-direction:column;justify-content:flex-start;background:#eeeeee;*{box-sizing:border-box;}']);


var CarouselWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__CarouselWrapper',
  componentId: 'sc-mh1hcd-1'
})(['width:100%;max-width:1000px;margin:0 auto;background:#ffffff;']);

var MediaWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__MediaWrapper',
  componentId: 'sc-mh1hcd-2'
})(['width:100%;max-width:1000px;margin:0 auto;']);

var MediaScriptContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__MediaScriptContainer',
  componentId: 'sc-mh1hcd-3'
})(['width:100%;']);

var SlideWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__SlideWrapper',
  componentId: 'sc-mh1hcd-4'
})(['padding-bottom:', ';position:relative;'], function (_ref) {
  var fullscreen = _ref.fullscreen;
  return fullscreen ? '100vh' : '75%';
});

var Slide = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleCarouselDetails__Slide',
  componentId: 'sc-mh1hcd-5'
})(['width:100%;display:block;position:absolute;@keyframes fadein{from{opacity:0.5;}to{opacity:1;}}', ' ', ''], function (_ref2) {
  var active = _ref2.active;
  return active && 'animation: fadein 0.6s ease-out;\n  ';
}, function (_ref3) {
  var fullscreen = _ref3.fullscreen;
  return fullscreen && '\n      height: 100%;\n      object-fit: contain;\n      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {\n        height: auto;\n      }\n  ';
});

var SlideButton = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__SlideButton',
  componentId: 'sc-mh1hcd-6'
})(['width:30px;height:30px;background:rgba(255,255,255,0.6);position:absolute;', ';', ';top:50%;margin-top:-15px;cursor:pointer;'], function (_ref4) {
  var left = _ref4.left;
  return left && 'left: 10px';
}, function (_ref5) {
  var right = _ref5.right;
  return right && 'right: 10px';
});

var ThumbnailsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__ThumbnailsWrapper',
  componentId: 'sc-mh1hcd-7'
})(['width:80%;height:80px;max-width:800px;overflow:hidden;margin:0 auto;position:relative;']);

var ThumbnailsScroller = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__ThumbnailsScroller',
  componentId: 'sc-mh1hcd-8'
})(['width:100%;position:relative;transition:left 0.5s ease;']);

var ThumbnailsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__ThumbnailsContainer',
  componentId: 'sc-mh1hcd-9'
})(['height:100%;white-space:nowrap;display:inline-block;box-sizing:border-box;']);

var ThumbnailsButton = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__ThumbnailsButton',
  componentId: 'sc-mh1hcd-10'
})(['width:20px;height:20px;background:rgba(255,255,255,0.6);position:absolute;', ';', ';top:50%;margin-top:-10px;z-index:42;cursor:pointer;'], function (_ref6) {
  var left = _ref6.left;
  return left && 'left: 10px';
}, function (_ref7) {
  var right = _ref7.right;
  return right && 'right: 10px';
});

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleCarouselDetails__Image',
  componentId: 'sc-mh1hcd-11'
})(['height:60px;margin:5px;cursor:pointer;', ''], function (_ref8) {
  var selected = _ref8.selected;
  return selected && 'border: 2px solid orange;\n  ';
});

var Footer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__Footer',
  componentId: 'sc-mh1hcd-12'
})(['display:flex;width:100%;min-height:80px;height:80px;', ';'], function (_ref9) {
  var footerBackgroundColour = _ref9.footerBackgroundColour;
  return footerBackgroundColour && 'background-color: ' + footerBackgroundColour.value;
});

var FooterButton = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleCarouselDetails__FooterButton',
  componentId: 'sc-mh1hcd-13'
})(['display:flex;justify-content:center;align-items:center;width:80px;cursor:pointer;', ''], function (_ref10) {
  var active = _ref10.active;
  return active && 'text-decoration: underline;\n  ';
});

var playerScriptSrc = '/player.js';

var mediaViewTypes = {
  INTERIOR: 'interior',
  EXTERIOR: 'exterior'
};

var RenderMedia = function RenderMedia(_ref11) {
  var mediaRef = _ref11.mediaRef,
      setShowMedia = _ref11.setShowMedia,
      setMediaView = _ref11.setMediaView,
      mediaView = _ref11.mediaView;
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      MediaWrapper,
      null,
      _react2.default.createElement(MediaScriptContainer, { ref: mediaRef }),
      _react2.default.createElement(
        Footer,
        null,
        _react2.default.createElement(
          FooterButton,
          { onClick: function onClick() {
              return setShowMedia(false);
            } },
          'Photos'
        ),
        _react2.default.createElement(
          FooterButton,
          {
            active: mediaView === mediaViewTypes.EXTERIOR,
            onClick: function onClick() {
              return setMediaView(mediaViewTypes.EXTERIOR);
            }
          },
          'Exterior'
        ),
        _react2.default.createElement(
          FooterButton,
          {
            active: mediaView === mediaViewTypes.INTERIOR,
            onClick: function onClick() {
              return setMediaView(mediaViewTypes.INTERIOR);
            }
          },
          'Interior'
        )
      )
    )
  );
};

exports.default = function (_ref12) {
  var footerBackgroundColour = _ref12.config.footerBackgroundColour,
      _ref12$vehicleInfo = _ref12.vehicleInfo,
      images = _ref12$vehicleInfo.images,
      vehicleId = _ref12$vehicleInfo.vehicleId,
      views = _ref12$vehicleInfo.views;

  var mediaRef = (0, _react.useRef)(null);
  var scrollerRef = (0, _react.useRef)(null);

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      showMedia = _useState2[0],
      setShowMedia = _useState2[1];

  var _useState3 = (0, _react.useState)(mediaViewTypes.EXTERIOR),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      mediaView = _useState4[0],
      setMediaView = _useState4[1];

  var _useModal = (0, _useModal2.useModal)(),
      isModalVisible = _useModal.isModalVisible,
      toggleModal = _useModal.toggleModal;

  var error = (0, _useScript.useScript)({
    playerScriptSrc: playerScriptSrc,
    mediaRef: mediaRef,
    showMedia: showMedia,
    mediaView: mediaView,
    vehicleId: vehicleId
  });

  var _useCarousel = (0, _useCarousel2.useCarousel)(scrollerRef, images.length),
      currentIndex = _useCarousel.currentIndex,
      prevIndex = _useCarousel.prevIndex,
      clickLeft = _useCarousel.clickLeft,
      clickRight = _useCarousel.clickRight,
      onThumbnailClick = _useCarousel.onThumbnailClick;

  var handlers = (0, _reactSwipeable.useSwipeable)({
    onSwipedLeft: clickRight,
    onSwipedRight: clickLeft,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  var Carousel = function Carousel(_ref13) {
    var fullscreen = _ref13.fullscreen;
    return _react2.default.createElement(
      SlideWrapper,
      (0, _extends3.default)({}, handlers, { fullscreen: fullscreen }),
      prevIndex !== null && _react2.default.createElement(Slide, {
        key: prevIndex,
        alt: 'car',
        src: images[prevIndex],
        fullscreen: fullscreen
      }),
      _react2.default.createElement(Slide, {
        key: images[currentIndex],
        alt: 'car',
        src: images[currentIndex],
        active: true,
        fullscreen: fullscreen
      }),
      _react2.default.createElement(
        SlideButton,
        { onClick: clickLeft, left: true },
        '\xAB'
      ),
      _react2.default.createElement(
        SlideButton,
        { onClick: clickRight, right: true },
        '\xBB'
      )
    );
  };

  return _react2.default.createElement(
    _components.Wrapper,
    null,
    _react2.default.createElement(
      _useModal2.Modal,
      { isModalVisible: isModalVisible, hide: toggleModal },
      _react2.default.createElement(Carousel, { fullscreen: true })
    ),
    _react2.default.createElement(
      CarouselBackground,
      null,
      showMedia ? _react2.default.createElement(RenderMedia, {
        mediaRef: mediaRef,
        setShowMedia: setShowMedia,
        setMediaView: setMediaView,
        mediaView: mediaView,
        error: error
      }) : _react2.default.createElement(
        CarouselWrapper,
        null,
        _react2.default.createElement(Carousel, null),
        _react2.default.createElement(
          Footer,
          { footerBackgroundColour: footerBackgroundColour },
          _react2.default.createElement(
            FooterButton,
            { onClick: function onClick() {
                return setShowMedia(true);
              } },
            '360'
          ),
          _react2.default.createElement(
            FooterButton,
            null,
            'Views: ',
            views
          ),
          _react2.default.createElement(
            ThumbnailsWrapper,
            null,
            _react2.default.createElement(
              ThumbnailsButton,
              { left: true, onClick: clickLeft },
              '\xAB'
            ),
            _react2.default.createElement(
              ThumbnailsButton,
              { right: true, onClick: clickRight },
              '\xBB'
            ),
            _react2.default.createElement(
              ThumbnailsScroller,
              { ref: scrollerRef },
              _react2.default.createElement(
                ThumbnailsContainer,
                null,
                images.map(function (image, index) {
                  return _react2.default.createElement(Image, {
                    key: image,
                    src: image,
                    onClick: function onClick() {
                      return onThumbnailClick(index);
                    },
                    selected: currentIndex === index
                  });
                })
              )
            )
          ),
          _react2.default.createElement(
            FooterButton,
            { onClick: toggleModal },
            'Zoom'
          )
        )
      )
    )
  );
};