'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EmissionsBar;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _emissions = require('../../shared/emissions');

var _Global = require('../../components/Global');

var _useCheckMobileScreen = require('../../hooks/useCheckMobileScreen');

var _useCheckMobileScreen2 = _interopRequireDefault(_useCheckMobileScreen);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsBar__Container',
  componentId: 'sc-4lstqt-0'
})(['box-sizing:border-box;display:flex;align-items:center;justify-content:center;width:100%;background:', ';'], function (_ref) {
  var background = _ref.background;
  return background || 'rgba(0, 0, 0, 0.8)';
});
function EmissionsBar(props) {
  var domEl = document.getElementById('footer-sticky-bar-container');
  var config = props.config,
      shared = props.shared;
  var emissionModels = config.emissionModels,
      font = config.font;
  // Bar will be accomodate based on:
  // - Cookie Policy bar.
  // - Mobile resolution.

  var sessionPreferences = shared.sessionPreferences;

  var isMobile = (0, _useCheckMobileScreen2.default)();
  var emissionsEnabled = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.featureFlags.emissionsEnabled;
  });

  var displayStickyBar = (0, _react.useMemo)(function () {
    return emissionsEnabled;
  });

  /* This text comes from external API */
  var emissionsInfo = (0, _reactRedux.useSelector)(function (state) {
    return emissionModels && emissionModels.length === 1 ? state.shared.emissions && state.shared.emissions.models && state.shared.emissions.models[emissionModels[0]] && state.shared.emissions.models[emissionModels[0]].disclaimer : state.shared.emissions && state.shared.emissions.aggregated && state.shared.emissions.aggregated.disclaimer;
  }) || config.translations.disclaimerText;

  if (!displayStickyBar) {
    return null;
  }

  var dispatch = (0, _reactRedux.useDispatch)();

  (0, _react.useEffect)(function () {
    dispatch(_emissions.actions.updateEmissionInfo({
      models: emissionModels
    }));
  }, []);

  var Component = function Component() {
    return emissionsInfo ? _react2.default.createElement(
      Container,
      { id: 'emissions-bar', background: config.background },
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n          ' + (0, _Global.fontStyleOverride)(font) + '\n          max-width: 80%;\n          padding: 5px 0;\n          text-align: center;\n      ';
          },
          mobileStyleOverride: function mobileStyleOverride() {
            return [{
              template: 'padding: 5px; max-width: 100%; text-align: left; margin-bottom:' + (isMobile && !sessionPreferences.cookiesAccepted && !domEl ? '125px' : '0'),
              queryPath: 'max.medium'
            }];
          }
        },
        emissionsInfo
      )
    ) : null;
  };

  return domEl ? _reactDom2.default.createPortal(_react2.default.createElement(Component, null), domEl) : _react2.default.createElement(Component, null);
}