'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display:none;\n  '], ['\n    display:none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display:block;\n    padding: 0 5%;\n  '], ['\n    display:block;\n    padding: 0 5%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    &:last-child {\n      border-bottom: none;\n    }\n  '], ['\n    width: 100%;\n    &:last-child {\n      border-bottom: none;\n    }\n  ']);

exports.default = VdpWellRr;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _VdpAccordion = require('../../VdpAccordion');

var _VdpAccordion2 = _interopRequireDefault(_VdpAccordion);

var _Global = require('../../Global');

var _VdpHighlightsImageOverlay = require('../../VdpHighlightsImageOverlay');

var _VdpHighlightsImageOverlay2 = _interopRequireDefault(_VdpHighlightsImageOverlay);

var _ContentWrapper = require('../../ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _VdpAtGlanceSection = require('./VdpAtGlanceSection');

var _VdpAtGlanceSection2 = _interopRequireDefault(_VdpAtGlanceSection);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var WellFeatures = _styledComponents2.default.div.withConfig({
  displayName: 'VdpWellRr__WellFeatures',
  componentId: 'sc-17v1j48-0'
})(['display:flex;flex-direction:column;align-items:center;', ';', ';'], function (_ref) {
  var horizontalPadding = _ref.horizontalPadding;
  return horizontalPadding && 'padding: ' + horizontalPadding + 'px 0';
}, function (_ref2) {
  var borderTop = _ref2.borderTop;
  return borderTop && 'border-top: ' + borderTop.size + 'px ' + borderTop.type + ' ' + borderTop.colour;
});

var DesktopContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpWellRr__DesktopContainer',
  componentId: 'sc-17v1j48-1'
})(['display:block;', ';', ':nth-child(odd){', ';}'], _theme2.default.max.large(_templateObject), WellFeatures, function (_ref3) {
  var backgroundColour = _ref3.backgroundColour;
  return backgroundColour && 'background-color: ' + backgroundColour;
});

var MobileContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpWellRr__MobileContainer',
  componentId: 'sc-17v1j48-2'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject2));

var ItemsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpWellRr__ItemsContainer',
  componentId: 'sc-17v1j48-3'
})(['display:flex;justify-content:space-between;flex-wrap:wrap;padding:0 16px;', ';'], _theme2.default.max.large(_templateObject3));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'VdpWellRr__Item',
  componentId: 'sc-17v1j48-4'
})(['display:flex;height:auto;width:30%;align-items:center;', ';', ';'], function (_ref4) {
  var border = _ref4.border;
  return border && 'border-bottom: ' + border.size + 'px ' + border.type + ' ' + border.colour;
}, _theme2.default.max.large(_templateObject4));

var Header = function Header(_ref5) {
  var title = _ref5.title,
      fontStyle = _ref5.fontStyle,
      paddingBottom = _ref5.paddingBottom;
  return _react2.default.createElement(
    _Global.HeadingTwo,
    {
      styleOverride: function styleOverride() {
        return '\n        width: 100%;\n        padding-bottom: ' + paddingBottom + 'px;\n        ' + (0, _Global.fontStyleOverride)(fontStyle) + '\n      ';
      },
      maxMediumMobileStyleOverride: function maxMediumMobileStyleOverride() {
        return '\n                font-size: 18px;\n                padding-top: 0;\n                padding-bottom: 10px;\n              ';
      }
    },
    title
  );
};

function VdpWellRr(_ref6) {
  var vehicle = _ref6.vehicle,
      translations = _ref6.translations,
      designList = _ref6.designList,
      equipmentList = _ref6.equipmentList,
      atGlanceSection = _ref6.atGlanceSection,
      vdpSectionsHeaderStyle = _ref6.vdpSectionsHeaderStyle,
      globalStyling = _ref6.globalStyling,
      infoIcon = _ref6.infoIcon,
      showOdometerInformation = _ref6.showOdometerInformation;

  var designItems = vehicle && vehicle.bespokeFeatures;

  var equipmentListDisplay = vehicle.otherEquipment && vehicle.otherEquipment.length > 0 && vehicle.otherEquipment.find(function (f) {
    return f.category === equipmentList.categoryName;
  });

  var DesignItems = function DesignItems() {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      designItems && designItems.map(function (f, index) {
        return _react2.default.createElement(
          Item,
          {
            key: 'designItem-' + index.toString(),
            border: designList.itemBorder
          },
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n              width: 100%;\n              margin: 12px 0;\n              ' + (0, _Global.fontStyleOverride)(designList.itemFont) + '\n            ';
              }
            },
            f.name
          ),
          f.imageUrl && _react2.default.createElement(_VdpHighlightsImageOverlay2.default, {
            imageUrl: f.imageUrl,
            infoIcon: infoIcon
          })
        );
      })
    );
  };
  var EquipmentItems = function EquipmentItems() {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      equipmentListDisplay && equipmentListDisplay.equipment.map(function (f, index) {
        return _react2.default.createElement(
          Item,
          {
            key: 'equipmentItem-' + index.toString(),
            border: equipmentList.itemBorder
          },
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n              width: 100%;\n              margin: 12px 0;\n              ' + (0, _Global.fontStyleOverride)(equipmentList.itemFont) + '\n            ';
              }
            },
            f
          )
        );
      })
    );
  };

  var AdditionalCommentItems = function AdditionalCommentItems() {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      vehicle.additionalComments.map(function (f, index) {
        return _react2.default.createElement(
          Item,
          {
            key: 'additionalComments-' + index.toString(),
            border: equipmentList.itemBorder
          },
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n              width: 100%;\n              margin: 12px 0;\n              ' + (0, _Global.fontStyleOverride)(equipmentList.itemFont) + '\n            ';
              }
            },
            f
          )
        );
      })
    );
  };

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement('div', { id: 'design-list' }),
    _react2.default.createElement(
      DesktopContainer,
      { backgroundColour: designList.backgroundColour },
      vehicle.specification && _react2.default.createElement(
        WellFeatures,
        {
          'data-cy': 'vehicle-information',
          borderTop: atGlanceSection.borderTop,
          horizontalPadding: atGlanceSection.horizontalPadding
        },
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          _react2.default.createElement(Header, {
            title: translations.vdpAtGlanceHeader,
            fontStyle: vdpSectionsHeaderStyle
          }),
          _react2.default.createElement(
            ItemsContainer,
            null,
            _react2.default.createElement(_VdpAtGlanceSection2.default, {
              translations: translations,
              vehicle: vehicle,
              showOdometerInformation: showOdometerInformation,
              atGlanceSection: atGlanceSection
            })
          )
        )
      ),
      designItems && designItems.length > 0 && _react2.default.createElement(
        WellFeatures,
        {
          'data-cy': 'vehicle-information',
          borderTop: designList.borderTop,
          horizontalPadding: designList.horizontalPadding
        },
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          translations.vdpDesignTitle && _react2.default.createElement(Header, {
            title: translations.vdpDesignTitle,
            fontStyle: vdpSectionsHeaderStyle,
            paddingBottom: designList.headerPaddingBottom
          }),
          _react2.default.createElement(
            ItemsContainer,
            null,
            _react2.default.createElement(DesignItems, null),
            designItems.length % 3 === 2 && _react2.default.createElement(Item, null)
          )
        )
      ),
      equipmentListDisplay && _react2.default.createElement(
        WellFeatures,
        {
          'data-cy': 'vehicle-information',
          borderTop: equipmentList.borderTop,
          horizontalPadding: equipmentList.horizontalPadding
        },
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          translations.vdpOtherEquipmentHeader && _react2.default.createElement(Header, {
            title: translations.vdpOtherEquipmentHeader,
            fontStyle: vdpSectionsHeaderStyle,
            paddingBottom: equipmentList.headerPaddingBottom
          }),
          _react2.default.createElement(
            ItemsContainer,
            null,
            _react2.default.createElement(EquipmentItems, null),
            equipmentListDisplay.equipment && equipmentListDisplay.equipment.length % 3 === 2 && _react2.default.createElement(Item, null)
          )
        )
      ),
      vehicle.additionalComments && _react2.default.createElement(
        WellFeatures,
        {
          'data-cy': 'vehicle-information',
          borderTop: atGlanceSection.borderTop,
          horizontalPadding: atGlanceSection.horizontalPadding
        },
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          translations.vdpAdditionalCommentsHeader && _react2.default.createElement(Header, {
            title: translations.vdpAdditionalCommentsHeader,
            fontStyle: atGlanceSection.retailerCommentsHeaderStyles
          }),
          _react2.default.createElement(
            ItemsContainer,
            null,
            _react2.default.createElement(AdditionalCommentItems, null),
            vehicle.additionalComments && vehicle.additionalComments.length % 3 === 2 && _react2.default.createElement(Item, null)
          )
        )
      )
    ),
    _react2.default.createElement(
      MobileContainer,
      null,
      translations.vdpSpecificationHeader && vehicle.specification && _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(Header, {
          title: translations.vdpAtGlanceHeader,
          fontStyle: vdpSectionsHeaderStyle
        }),
        _react2.default.createElement(_VdpAtGlanceSection2.default, {
          translations: translations,
          vehicle: vehicle,
          showOdometerInformation: showOdometerInformation,
          atGlanceSection: atGlanceSection
        })
      ),
      translations.vdpDesignTitle && designItems && _react2.default.createElement(
        _VdpAccordion2.default,
        { title: translations.vdpDesignTitle },
        _react2.default.createElement(
          ItemsContainer,
          null,
          _react2.default.createElement(DesignItems, null)
        )
      ),
      translations.vdpOtherEquipmentHeader && equipmentListDisplay && _react2.default.createElement(
        _VdpAccordion2.default,
        { title: translations.vdpOtherEquipmentHeader },
        _react2.default.createElement(
          ItemsContainer,
          null,
          _react2.default.createElement(EquipmentItems, null)
        )
      ),
      translations.vdpAdditionalCommentsHeader && vehicle.additionalComments && _react2.default.createElement(
        _VdpAccordion2.default,
        { title: translations.vdpAdditionalCommentsHeader },
        _react2.default.createElement(
          ItemsContainer,
          null,
          _react2.default.createElement(AdditionalCommentItems, null)
        )
      )
    )
  );
}