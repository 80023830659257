'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _components = require('../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EquipmentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEquipment__EquipmentContainer',
  componentId: 'sc-zg6kfp-0'
})(['display:flex;width:100%;flex-wrap:wrap;']);

var Equipment = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEquipment__Equipment',
  componentId: 'sc-zg6kfp-1'
})(['display:flex;width:100%;padding:10px;flex-wrap:wrap;']);

var EquipmentHeader = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEquipment__EquipmentHeader',
  componentId: 'sc-zg6kfp-2'
})(['display:flex;flex:1 0 100%;font-weight:bold;']);

var EquipmentValue = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEquipment__EquipmentValue',
  componentId: 'sc-zg6kfp-3'
})(['display:flex;flex:1 0 100%;']);

var equipmentSelector = function equipmentSelector(state) {
  if (!state.dataProvider.vehicleInfo) {
    return [];
  }

  return state.dataProvider.vehicleInfo.otherEquipment;
};

exports.default = function (_ref) {
  var showCategoryHeaders = _ref.config.showCategoryHeaders;

  var equipment = (0, _reactRedux.useSelector)(equipmentSelector);

  return equipment.length > 0 && _react2.default.createElement(
    _components.Wrapper,
    null,
    _react2.default.createElement(
      EquipmentContainer,
      null,
      equipment.map(function (o) {
        return _react2.default.createElement(
          Equipment,
          { key: o.category },
          showCategoryHeaders && _react2.default.createElement(
            EquipmentHeader,
            null,
            o.category
          ),
          o.equipment.map(function (item) {
            return _react2.default.createElement(
              EquipmentValue,
              { key: item },
              item
            );
          })
        );
      })
    )
  );
};