'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Compare;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Compare(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      {
        'data-ui-element': 'compare',
        width: '1em',
        height: '1em',
        viewBox: '0 0 27 25'
      },
      _react2.default.createElement('path', { d: 'M15.5 7h8.3v8.3L20.499 12 14.8 17.7 13.101 16l5.698-5.7L15.5 7zM5.3 13l5.9-5.9 1.7 1.7L7 14.7l3.3 3.3H2V9.7L5.3 13z' })
    )
  );
}

Compare.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};