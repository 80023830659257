'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  ', ';\n  padding: 0;\n  '], ['\n  display: flex;\n  ', ';\n  padding: 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 30%;\n    padding: 3% 5% 2% 5%;\n  '], ['\n    width: 30%;\n    padding: 3% 5% 2% 5%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  min-height: 350px;\n'], ['\n  min-height: 350px;\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n  min-height: 440px;\n'], ['\n  min-height: 440px;\n']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n  width: 60%;\n  background-position: center;\n  height: auto;\n'], ['\n  width: 60%;\n  background-position: center;\n  height: auto;\n']);
/* eslint-disable jsx-a11y/anchor-is-valid */


exports.default = ModelSummary;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

var _ThinRight = require('../JaguarIcons/Arrows/ThinRight');

var _ThinRight2 = _interopRequireDefault(_ThinRight);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ButtonContainer'
}).withConfig({
  displayName: 'ModelSummary__ButtonContainer',
  componentId: 'sc-1inppdp-0'
})(['display:flex;flex-wrap:nowrap;justify-content:space-between;margin-top:15px;']);

var ModelSummaryContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSummaryContainer'
}).withConfig({
  displayName: 'ModelSummary__ModelSummaryContainer',
  componentId: 'sc-1inppdp-1'
})(['', ';'], _theme2.default.min.large(_templateObject, function (_ref) {
  var alignContent = _ref.alignContent;
  return '' + (alignContent && 'flex-direction:' + alignContent);
}));

var ModelSummaryInformation = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSummaryInformation'
}).withConfig({
  displayName: 'ModelSummary__ModelSummaryInformation',
  componentId: 'sc-1inppdp-2'
})(['padding-right:5%;padding-left:5%;', ';'], _theme2.default.min.large(_templateObject2));

var ModelSummaryImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSummaryImageContainer'
}).withConfig({
  displayName: 'ModelSummary__ModelSummaryImageContainer',
  componentId: 'sc-1inppdp-3'
})(['', ';background-repeat:no-repeat;background-size:cover;height:auto;min-height:250px;', ';', ';', ';'], function (_ref2) {
  var url = _ref2.url;
  return '' + (url && 'background-image: url(' + url + ')');
}, _theme2.default.min.small(_templateObject3), _theme2.default.min.medium(_templateObject4), _theme2.default.min.large(_templateObject5));

var Link = _styledComponents2.default.div.withConfig({
  displayName: 'Link'
}).withConfig({
  displayName: 'ModelSummary__Link',
  componentId: 'sc-1inppdp-4'
})(['display:flex;justify-content:space-between;align-items:center;color:#444444;height:43px;background-color:transparent;font-weight:600;text-decoration:none;border-top:1px solid #dedede;border-bottom:1px solid #dedede;width:100%;font-size:13px;cursor:pointer;font-family:inherit;letter-spacing:0.1em;font-size:15px;line-height:21px;margin-bottom:3%;']);

var DisclaimerText = _styledComponents2.default.div.withConfig({
  displayName: 'DisclaimerText'
}).withConfig({
  displayName: 'ModelSummary__DisclaimerText',
  componentId: 'sc-1inppdp-5'
})(['color:#444444;font-size:12px;line-height:18px;margin-top:5%;margin-bottom:5%;']);

function ModelSummary(_ref3) {
  var title = _ref3.title,
      ModelFinance = _ref3.modelFinanceComponent,
      findOutMore = _ref3.findOutMore,
      description = _ref3.description,
      imageUrl = _ref3.imageUrl,
      alignment = _ref3.alignment,
      financeButtonText = _ref3.financeButtonText,
      searchButtonText = _ref3.searchButtonText,
      availableText = _ref3.availableText,
      findOutMoreText = _ref3.findOutMoreText,
      disclaimerText = _ref3.disclaimerText,
      searchButtonOnClick = _ref3.searchButtonOnClick,
      financeButtonOnClick = _ref3.financeButtonOnClick,
      globalStyling = _ref3.globalStyling,
      showSearchButton = _ref3.showSearchButton,
      modelSummaryHeaderFont = _ref3.modelSummaryHeaderFont,
      lamboDescriptionStyling = _ref3.lamboDescriptionStyling,
      showLamboCTAs = _ref3.showLamboCTAs;

  return _react2.default.createElement(
    ModelSummaryContainer,
    { alignContent: alignment },
    _react2.default.createElement(ModelSummaryImageContainer, {
      role: 'img',
      'aria-label': title + ' - ' + description,
      url: imageUrl
    }),
    _react2.default.createElement(
      ModelSummaryInformation,
      null,
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n            margin-top: 5%;\n            ' + (modelSummaryHeaderFont && (0, _Global.fontStyleOverride)(modelSummaryHeaderFont)) + '\n          ';
          }
        },
        title
      ),
      _react2.default.createElement(ModelFinance, null),
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n            font-size: 15px;\n            line-height: 21px;\n            margin-top: 2%;\n            margin-bottom: 5%;\n            ' + (lamboDescriptionStyling && '\n              border-top: ' + lamboDescriptionStyling.horizontalBorder + ';\n              border-bottom: ' + lamboDescriptionStyling.horizontalBorder + ';\n              ') + ';\n            ' + (lamboDescriptionStyling && 'padding: ' + lamboDescriptionStyling.horizontalPadding + 'px 0;') + ';\n          ';
          }
        },
        description
      ),
      !showLamboCTAs && _react2.default.createElement(
        Link,
        { onClick: findOutMore },
        findOutMoreText,
        _react2.default.createElement(_ThinRight2.default, { colour: '#444444' })
      ),
      _react2.default.createElement(
        ButtonContainer,
        null,
        !showLamboCTAs && _react2.default.createElement(_Global.Button, {
          styleOverride: function styleOverride() {
            return '\n                width: 48%;\n              ';
          },
          onClick: financeButtonOnClick,
          text: financeButtonText,
          applyStyle: 'secondary',
          buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle
        }),
        showLamboCTAs && _react2.default.createElement(_Global.Button, {
          styleOverride: function styleOverride() {
            return '\n                width: 48%;\n              ';
          },
          onClick: findOutMore,
          text: findOutMoreText,
          applyStyle: 'primary',
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
        }),
        showSearchButton && _react2.default.createElement(_Global.Button, {
          styleOverride: function styleOverride() {
            return '\n                width: 48%;\n              ';
          },
          onClick: searchButtonOnClick,
          text: searchButtonText,
          applyStyle: 'secondary',
          buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle
        })
      ),
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n            font-size: 16px;\n            line-height: 19px;\n            margin-top: 10%;\n            margin-bottom: 5%;\n          ';
          }
        },
        availableText
      ),
      _react2.default.createElement(
        DisclaimerText,
        null,
        disclaimerText
      )
    )
  );
}