'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Spacer = _styledComponents2.default.div.withConfig({
  displayName: 'Spacer',
  componentId: 'sc-sove72-0'
})(['width:100%;']);

exports.default = function () {
  return _react2.default.createElement(Spacer, null);
};