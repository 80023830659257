'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n    padding: 100px 0 50px;\n  '], ['\n    flex-direction: row;\n    padding: 100px 0 50px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n    margin-bottom: 20px;\n  '], ['\n    margin: 0;\n    margin-bottom: 20px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['border-bottom: 1px solid #1e1e1e;'], ['border-bottom: 1px solid #1e1e1e;']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n    padding: 0 5px 0 0;\n  '], ['\n    width: 50%;\n    padding: 0 5px 0 0;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n  padding: 30px'], ['\n  padding: 30px']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n     flex-direction: row;\n  '], ['\n     flex-direction: row;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n     margin-right: auto;\n  '], ['\n     margin-right: auto;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 30px;\n  '], ['\n    padding: 30px;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 12px;\n'], ['\n  font-size: 12px;\n']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    width: 150px;\n    height: 40px;\n  '], ['\n    width: 150px;\n    height: 40px;\n  ']);

exports.default = Footer;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _footerTypes = require('../../../types/Genesis/footerTypes');

var _LinkList = require('../LinkList');

var _LinkList2 = _interopRequireDefault(_LinkList);

var _SocialMediaLinks = require('./SocialMediaLinks');

var _SocialMediaLinks2 = _interopRequireDefault(_SocialMediaLinks);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterWrapper',
  componentId: 'sc-1w60jv8-0'
})(['align-items:center;background:#151515;display:flex;flex-direction:column;']);

var FooterContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterContainer',
  componentId: 'sc-1w60jv8-1'
})(['box-sizing:border-box;display:flex;width:100%;flex-direction:column;max-width:1920px;', ';'], function () {
  return _Genesis.mixins.pageGuttering();
});

var FooterTopWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterTopWrapper',
  componentId: 'sc-1w60jv8-2'
})(['align-items:center;border-top:1px solid #1e1e1e;display:flex;justify-content:center;background-color:#111;width:100%;']);

var FooterTopContent = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterTopContent',
  componentId: 'sc-1w60jv8-3'
})(['width:100%;max-width:1920px;', ';'], function () {
  return _Genesis.mixins.pageGuttering();
});

var MainLinksSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__MainLinksSectionWrapper',
  componentId: 'sc-1w60jv8-4'
})(['display:flex;flex-direction:column;justify-content:space-between;padding:0;', ';'], _Genesis2.default.min.large(_templateObject));

var MainLinksColumnTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__MainLinksColumnTitle',
  componentId: 'sc-1w60jv8-5'
})(['font-size:18px;font-family:\'GenesisSansHeadGlobal-Regular\';color:#fff;margin:20px 30px;text-transform:uppercase;', ';'], _Genesis2.default.min.large(_templateObject2));

var IconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__IconContainer',
  componentId: 'sc-1w60jv8-6'
})(['margin:20px 30px;', ';'], _Genesis2.default.min.large(_templateObject3));

var ListTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__ListTitle',
  componentId: 'sc-1w60jv8-7'
})(['display:flex;justify-content:space-between;margin:0px;cursor:pointer;', ''], _Genesis2.default.max.large(_templateObject4));

var MainLinksColumn = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__MainLinksColumn',
  componentId: 'sc-1w60jv8-8'
})(['display:flex;flex-direction:column;color:', ';'], _Genesis2.default.max.large(_templateObject5));

var SocialsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__SocialsContainer',
  componentId: 'sc-1w60jv8-9'
})(['display:flex;justify-content:space-between;padding:20px 0;']);

var EmissionsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__EmissionsWrapper',
  componentId: 'sc-1w60jv8-10'
})(['', ';'], function () {
  return _Genesis.mixins.pageGuttering();
});

var EmissionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__EmissionsContainer',
  componentId: 'sc-1w60jv8-11'
})(['padding:30px 0;color:#fff;max-width:1020px;', ''], _Genesis2.default.min.medium(_templateObject6));

var EmissionsTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__EmissionsTitle',
  componentId: 'sc-1w60jv8-12'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:16px;font-style:normal;font-weight:normal;line-height:19.5px;margin-bottom:10px;']);

var EmissionsParagraph = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__EmissionsParagraph',
  componentId: 'sc-1w60jv8-13'
})(['color:#fff;font-family:\'GenesisSansTextGlobal-Regular\';font-size:13px;font-style:normal;font-weight:normal;line-height:19.5px;white-space:pre-wrap;& a{color:#fff;text-decoration:none;}& em{font-style:unset;}']);

var ShareLabel = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__ShareLabel',
  componentId: 'sc-1w60jv8-14'
})(['align-self:center;color:#fff;font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;padding-right:12px;']);

var LinkToTop = _styledComponents2.default.a.withConfig({
  displayName: 'Footer__LinkToTop',
  componentId: 'sc-1w60jv8-15'
})(['color:#fff;cursor:pointer;display:flex;font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;text-decoration:none;&:hover{color:#cd9a81;}']);

var LinkToTopImg = _styledComponents2.default.img.withConfig({
  displayName: 'Footer__LinkToTopImg',
  componentId: 'sc-1w60jv8-16'
})(['padding-left:10px;width:16px;']);

var BottomSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__BottomSectionWrapper',
  componentId: 'sc-1w60jv8-17'
})(['background-color:#111;border-bottom:1px solid #1e1e1e;display:flex;justify-content:center;padding:30px 0 10px;width:100%;']);

var BottomSectionContent = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__BottomSectionContent',
  componentId: 'sc-1w60jv8-18'
})(['align-items:center;display:flex;flex-direction:column;width:100%;max-width:1920px;', ';', ';'], function () {
  return _Genesis.mixins.pageGuttering();
}, _Genesis2.default.min.large(_templateObject7));

var BottomLinksContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__BottomLinksContainer',
  componentId: 'sc-1w60jv8-19'
})(['text-align:center;display:flex;justify-content:flex-start;flex-wrap:wrap;', ';'], _Genesis2.default.min.large(_templateObject8));

var BottomLink = _styledComponents2.default.a.withConfig({
  displayName: 'Footer__BottomLink',
  componentId: 'sc-1w60jv8-20'
})(['color:#fff;font-size:16px;font-family:\'GenesisSansTextGlobal-Regular\';display:inline-flex;text-decoration:none;margin:10px 0 10px 0;letter-spacing:1px;:hover{color:#af6249;}']);

var CopyrightWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__CopyrightWrapper',
  componentId: 'sc-1w60jv8-21'
})(['align-items:center;box-sizing:border-box;background-color:#111;display:flex;flex-direction:column;justify-content:center;padding:50px 0;width:100%;', ''], _Genesis2.default.max.large(_templateObject9));

var CopyrightText = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__CopyrightText',
  componentId: 'sc-1w60jv8-22'
})(['color:#ccc;text-align:center;font-family:\'GenesisSansTextGlobal-Regular\';font-size:14px;font-style:normal;line-height:21px;margin-top:20px;', ';'], _Genesis2.default.max.small(_templateObject10));

var FooterLogo = _styledComponents2.default.img.withConfig({
  displayName: 'Footer__FooterLogo',
  componentId: 'sc-1w60jv8-23'
})(['width:128px;height:26px;', ''], _Genesis2.default.max.large(_templateObject11));

var Divider = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__Divider',
  componentId: 'sc-1w60jv8-24'
})(['border-right:1px solid #1e1e1e;height:10px;margin:5px 20px;']);

var ExpandImg = _styledComponents2.default.img.withConfig({
  displayName: 'Footer__ExpandImg',
  componentId: 'sc-1w60jv8-25'
})(['transform:', ';width:16px;transition:transform 0.2s linear;'], function (_ref) {
  var open = _ref.open;
  return open ? 'rotate(180deg)' : 'rotate(360deg)';
});

function Footer(_ref2) {
  var config = _ref2.config;

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      openGenesis = _useState2[0],
      setOpenGenesis = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      openModels = _useState4[0],
      setOpenModels = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      openSupport = _useState6[0],
      setOpenSupport = _useState6[1];

  var _useState7 = (0, _react.useState)(false),
      _useState8 = (0, _slicedToArray3.default)(_useState7, 2),
      openOwnership = _useState8[0],
      setOpenOwnership = _useState8[1];

  var discoverGenesisLinks = config.discoverGenesisLinks,
      modelLinks = config.modelLinks,
      ownershipLinks = config.ownershipLinks,
      supportLinks = config.supportLinks,
      socialMediaLinks = config.socialMediaLinks,
      shareLinks = config.shareLinks,
      expandIcon = config.expandIcon,
      translations = config.translations;


  var goToTop = function goToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return _react2.default.createElement(
    FooterWrapper,
    null,
    _react2.default.createElement(
      EmissionsWrapper,
      null,
      _react2.default.createElement(
        EmissionsContainer,
        null,
        _react2.default.createElement(
          EmissionsTitle,
          null,
          config.translations.emissionsTitle
        ),
        _react2.default.createElement(
          EmissionsParagraph,
          null,
          _react2.default.createElement(
            _reactMarkdown2.default,
            null,
            config.translations.emissionsParagraph
          )
        )
      )
    ),
    _react2.default.createElement(
      FooterTopWrapper,
      null,
      _react2.default.createElement(
        FooterTopContent,
        null,
        _react2.default.createElement(
          SocialsContainer,
          null,
          _react2.default.createElement(
            'div',
            { style: { display: 'flex' } },
            _react2.default.createElement(
              ShareLabel,
              null,
              translations.share
            ),
            _react2.default.createElement(_SocialMediaLinks2.default, { socialMediaIconsList: shareLinks, shareIcons: true })
          ),
          _react2.default.createElement(
            LinkToTop,
            { onClick: goToTop },
            translations.top,
            ' ',
            _react2.default.createElement(LinkToTopImg, { src: config.collapseIcon })
          )
        )
      )
    ),
    _react2.default.createElement(
      FooterContainer,
      null,
      _react2.default.createElement(
        MainLinksSectionWrapper,
        null,
        _react2.default.createElement(
          MainLinksColumn,
          null,
          _react2.default.createElement(
            ListTitle,
            { onClick: function onClick() {
                return setOpenGenesis(!openGenesis);
              } },
            _react2.default.createElement(
              MainLinksColumnTitle,
              null,
              config.translations.discoverGenesisTitle
            ),
            _react2.default.createElement(
              IconContainer,
              { role: 'button', tabIndex: 0 },
              _react2.default.createElement(ExpandImg, {
                open: openGenesis,
                src: expandIcon,
                alt: openGenesis ? config.translations.collapseIcon : config.translations.expandIcon
              })
            )
          ),
          _react2.default.createElement(_LinkList2.default, {
            items: discoverGenesisLinks.map(function (item) {
              return item;
            }),
            translations: config.translations,
            open: openGenesis
          })
        ),
        _react2.default.createElement(
          MainLinksColumn,
          null,
          _react2.default.createElement(
            ListTitle,
            { onClick: function onClick() {
                return setOpenModels(!openModels);
              } },
            _react2.default.createElement(
              MainLinksColumnTitle,
              null,
              config.translations.modelLinksTitle
            ),
            _react2.default.createElement(
              IconContainer,
              { role: 'button', tabIndex: 0 },
              _react2.default.createElement(ExpandImg, {
                open: openModels,
                src: expandIcon,
                alt: openModels ? config.translations.collapseIcon : config.translations.expandIcon
              })
            )
          ),
          _react2.default.createElement(_LinkList2.default, {
            items: modelLinks.map(function (item) {
              return item;
            }),
            translations: config.translations,
            open: openModels
          })
        ),
        _react2.default.createElement(
          MainLinksColumn,
          null,
          _react2.default.createElement(
            ListTitle,
            { onClick: function onClick() {
                return setOpenSupport(!openSupport);
              } },
            _react2.default.createElement(
              MainLinksColumnTitle,
              null,
              config.translations.supportTitle
            ),
            _react2.default.createElement(
              IconContainer,
              { role: 'button', tabIndex: 0 },
              _react2.default.createElement(ExpandImg, {
                open: openSupport,
                src: expandIcon,
                alt: openSupport ? config.translations.collapseIcon : config.translations.expandIcon
              })
            )
          ),
          _react2.default.createElement(_LinkList2.default, {
            items: supportLinks.map(function (item) {
              return item;
            }),
            translations: config.translations,
            open: openSupport
          })
        ),
        _react2.default.createElement(
          MainLinksColumn,
          null,
          _react2.default.createElement(
            ListTitle,
            { onClick: function onClick() {
                return setOpenOwnership(!openOwnership);
              } },
            _react2.default.createElement(
              MainLinksColumnTitle,
              null,
              config.translations.ownershipTitle
            ),
            _react2.default.createElement(
              IconContainer,
              { role: 'button', tabIndex: 0 },
              _react2.default.createElement(ExpandImg, {
                open: openOwnership,
                src: expandIcon,
                alt: openOwnership ? config.translations.collapseIcon : config.translations.expandIcon
              })
            )
          ),
          _react2.default.createElement(_LinkList2.default, {
            items: ownershipLinks.map(function (item) {
              return item;
            }),
            translations: config.translations,
            open: openOwnership
          })
        )
      )
    ),
    _react2.default.createElement(
      BottomSectionWrapper,
      null,
      _react2.default.createElement(
        BottomSectionContent,
        null,
        _react2.default.createElement(
          BottomLinksContainer,
          null,
          config.bottomFooterBarItems.map(function (_ref3) {
            var link = _ref3.link,
                label = _ref3.label,
                navId = _ref3.navId;
            return _react2.default.createElement(
              BottomLink,
              { href: link, key: navId, target: getTargetUrl(link) },
              config.translations['' + label],
              ' ',
              _react2.default.createElement(Divider, null)
            );
          })
        ),
        _react2.default.createElement(
          SocialsContainer,
          null,
          _react2.default.createElement(_SocialMediaLinks2.default, { socialMediaIconsList: socialMediaLinks })
        )
      )
    ),
    _react2.default.createElement(
      CopyrightWrapper,
      null,
      _react2.default.createElement(FooterLogo, { src: config.genesisLogo }),
      _react2.default.createElement(
        CopyrightText,
        null,
        '' + config.translations.copyrightSiteName
      )
    )
  );
}