'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('../../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  // THIS IS WIP
  // get count of stocks available
  // const stockCounts = props.postData
  //   ? props.postData.filter(modelData => {
  //       if (
  //         props.config.model
  //           .toLowerCase()
  //           .indexOf(modelData.model.toLowerCase()) > -1
  //       ) {
  //         let availableText;
  //         if (modelData.count !== 0) {
  //           if (modelData.count >= 2) {
  //             availableText = 'availableText';
  //           } else {
  //             availableText = 'singleAvailableText';
  //           }
  //         } else {
  //           availableText = 'unAvailableText';
  //         }
  //         props.config.translations.bodyText = translateFromTemplate(
  //           availableText,
  //           {
  //             COUNT: modelData.count,
  //           },
  //           props.config.translations,
  //         );
  //       }
  //     })
  //   : '';
  return _react2.default.createElement(
    _components.Wrapper,
    props,
    _react2.default.createElement(
      _components.HeadingTextButton,
      props,
      function (_ref) {
        var _ref$config = _ref.config,
            src = _ref$config.image.src,
            imageLabel = _ref$config.translations.imageLabel,
            imageBehaviour = _ref$config.imageBehaviour,
            imagePosition = _ref$config.imagePosition,
            imageHeight = _ref$config.imageHeight;
        return _react2.default.createElement(_components.Image, (0, _extends3.default)({ src: src, imageBehaviour: imageBehaviour, imagePosition: imagePosition, imageHeight: imageHeight }, {
          role: 'img',
          'aria-label': imageLabel
        }));
      }
    )
  );
};