'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      margin: 0;\n      height: ', ';\n      width: 100%;\n      ', ';\n      span {\n        margin: 0 5px 1px 15px;\n        width: 100%;\n        ', ';\n        ', ';\n      }\n      ', ';\n    '], ['\n      margin: 0;\n      height: ', ';\n      width: 100%;\n      ', ';\n      span {\n        margin: 0 5px 1px 15px;\n        width: 100%;\n        ', ';\n        ', ';\n      }\n      ', ';\n    ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      span {\n        margin: 0 5px 1px 15px;\n        width: 100%;\n      }\n    '], ['\n      span {\n        margin: 0 5px 1px 15px;\n        width: 100%;\n      }\n    ']);

exports.default = Button;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('.');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _JaguarArrow = require('../../assets/JaguarArrow');

var _JaguarArrow2 = _interopRequireDefault(_JaguarArrow);

var _KiaArrow = require('../../assets/KiaArrow');

var _KiaArrow2 = _interopRequireDefault(_KiaArrow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ButtonStyles = {
  ButtonStyle1: function ButtonStyle1() {
    return '\n  :focus-visible {\n    outline: 1px dotted #212121;\n    outline: 5px auto -webkit-focus-ring-color;\n  }';
  },
  ButtonStyle2: function ButtonStyle2() {
    return '';
  },
  ButtonStyle3: function ButtonStyle3() {
    return '\n    border-radius: 15px;\n    justify-content: space-between;\n    min-height: 30px;\n    padding: 4px 0;\n\n    span {\n      margin: 0 5px 1px 15px;\n      width: 100%;\n    }\n  ';
  },
  ButtonStyle4: function ButtonStyle4() {
    return '\n    transition: background-color 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);\n    border-radius: 20px;\n    position: relative;\n    overflow: hidden;\n    display: inline-block;\n    padding: 0 30px;\n    span {\n      transition: all 300ms cubic-bezier(0.46, 0.03, 0.52, 0.96);\n      position: relative;\n      left: 0;\n      line-height: 16px;\n    }\n    &:hover span {\n      left: -10px;\n    }\n    &:hover:after {\n      right: 25px;\n      opacity: 1;\n    }\n    &:after {\n      content: \'\';\n      right: 30px;\n      opacity: 0;\n      transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);\n      position: absolute;\n      line-height: 16px;\n      width: 16px;\n      height: 16px;\n      background-size: 16px 16px;\n      background-image: url(\'https://res.cloudinary.com/motortrak/image/upload/v1637665531/locator/rolls-royce/global/button-arrow.svg\');\n    }\n  ';
  },
  ButtonStyleBTT: function ButtonStyleBTT() {
    return '';
  },
  ButtonStyle5: function ButtonStyle5() {
    return '\n    border-radius: 2px;\n    opacity: 0.8;\n\n    &:hover {\n    opacity: 1;\n  }\n  ';
  },
  ButtonStyle6: function ButtonStyle6() {
    return '\n    transition: background-color 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);\n    border: none;\n    position: relative;\n    overflow: hidden;\n    display: inline-block;\n    padding: 8px 16px;\n    span {\n      transition: all 300ms cubic-bezier(0.46, 0.03, 0.52, 0.96);\n      position: relative;\n      left: 0;\n      line-height: 16px;\n    }\n    &:hover span {\n      left: -10px;\n    }\n    &:hover:after {\n      right: 10px;\n    }\n    &:after {\n      content: \'\';\n      right: -30px;\n      transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);\n      position: absolute;\n      line-height: 16px;\n      width: 16px;\n      height: 16px;\n      background-size: 16px 16px;\n      background-image: url(\'https://res.cloudinary.com/motortrak/image/upload/v1706278366/locator/lamborghini/content/dropdown_copy_3.png\');\n    }\n    :focus-visible {\n      outline: 1px dotted #212121;\n      outline: 5px auto -webkit-focus-ring-color;\n    }\n  ';
  }
};

var StyledButton = _styledComponents2.default.button.attrs(function (props) {
  return {
    className: 'global ' + props.applyStyle + ' ' + (props.disabled ? 'disabled' : '')
  };
}).withConfig({
  displayName: 'Button__StyledButton',
  componentId: 'sc-i2sm2a-0'
})(['&&&{', ';', ';', ';', ';}'], function (_ref) {
  var buttonStyle = _ref.buttonStyle;
  return buttonStyle && ButtonStyles[buttonStyle]();
}, _theme2.default.max.medium(_templateObject, function (_ref2) {
  var buttonStyle = _ref2.buttonStyle;
  return buttonStyle === 'ButtonStyle3' ? 'unset' : '41px';
}, function (_ref3) {
  var override = _ref3.buttonStyleOverride;
  return override && override();
}, function (_ref4) {
  var textStyleOverride = _ref4.textStyleOverride;
  return textStyleOverride && 'margin: ' + textStyleOverride.margin + ';';
}, function (_ref5) {
  var textStyleOverride = _ref5.textStyleOverride;
  return textStyleOverride && 'width: ' + textStyleOverride.width + ';';
}, function (_ref6) {
  var mobileStyleOverride = _ref6.mobileStyleOverride;
  return mobileStyleOverride && mobileStyleOverride();
}), _theme2.default.max.small(_templateObject2), function (_ref7) {
  var styleOverride = _ref7.styleOverride;
  return styleOverride && styleOverride();
});

var ButtonText = _styledComponents2.default.span.withConfig({
  displayName: 'Button__ButtonText',
  componentId: 'sc-i2sm2a-1'
})(['margin:0 10px;']);

var ButtonTextStyleFour = _styledComponents2.default.span.withConfig({
  displayName: 'Button__ButtonTextStyleFour',
  componentId: 'sc-i2sm2a-2'
})(['margin:0 5px;']);

var ButtonStyleContent = {
  ButtonStyle1: function ButtonStyle1(text) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonText,
        null,
        text
      ),
      _react2.default.createElement('div', null)
    );
  },
  ButtonStyle2: function ButtonStyle2(text) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonText,
        null,
        text
      ),
      _react2.default.createElement(_JaguarArrow2.default, { width: '1.2em', height: '1.2em' })
    );
  },
  ButtonStyle3: function ButtonStyle3(text, arrowStyleOverride) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonText,
        null,
        text
      ),
      _react2.default.createElement(_KiaArrow2.default, {
        width: '1.7em',
        height: '1.7em',
        mobileStyleOverride: arrowStyleOverride
      })
    );
  },
  ButtonStyle4: function ButtonStyle4(text) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonTextStyleFour,
        null,
        text
      )
    );
  },
  ButtonStyleBTT: function ButtonStyleBTT(text) {
    return text;
  },
  ButtonStyle5: function ButtonStyle5(text, className) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonText,
        { className: className },
        text
      )
    );
  },
  ButtonStyle6: function ButtonStyle6(text) {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ButtonText,
        null,
        text
      )
    );
  }
};

function Button(_ref8) {
  var label = _ref8.label,
      buttonStyle = _ref8.buttonStyle,
      text = _ref8.text,
      styleOverride = _ref8.styleOverride,
      applyStyle = _ref8.applyStyle,
      onClick = _ref8.onClick,
      className = _ref8.className,
      props = (0, _objectWithoutProperties3.default)(_ref8, ['label', 'buttonStyle', 'text', 'styleOverride', 'applyStyle', 'onClick', 'className']);

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global;
  }),
      globalStyling = _useSelector.globalStyling;

  var globalMobileStyleOverride = null;
  switch (applyStyle) {
    case 'primary':
      globalMobileStyleOverride = !globalStyling.uiElements.primaryButton || !globalStyling.uiElements.primaryButton.mobileStyleOverride ? null : {
        buttonStyleOverride: [globalStyling.uiElements.primaryButton.mobileStyleOverride.font, globalStyling.uiElements.primaryButton.mobileStyleOverride.backgroundColour, globalStyling.uiElements.primaryButton.mobileStyleOverride.hover, globalStyling.uiElements.primaryButton.mobileStyleOverride.buttonSize, globalStyling.uiElements.primaryButton.mobileStyleOverride.buttonHoverColour, globalStyling.uiElements.primaryButton.mobileStyleOverride.border],
        textStyleOverride: globalStyling.uiElements.primaryButton.mobileStyleOverride.buttonText,
        arrowStyleOverride: globalStyling.uiElements.primaryButton.mobileStyleOverride.arrowIcon
      };
      break;
    case 'secondary':
      globalMobileStyleOverride = !globalStyling.uiElements.secondaryButton || !globalStyling.uiElements.secondaryButton.mobileStyleOverride ? null : {
        buttonStyleOverride: [globalStyling.uiElements.secondaryButton.mobileStyleOverride.font, globalStyling.uiElements.secondaryButton.mobileStyleOverride.backgroundColour, globalStyling.uiElements.secondaryButton.mobileStyleOverride.hover, globalStyling.uiElements.secondaryButton.mobileStyleOverride.buttonSize, globalStyling.uiElements.secondaryButton.mobileStyleOverride.buttonHoverColour, globalStyling.uiElements.secondaryButton.mobileStyleOverride.border],
        textStyleOverride: globalStyling.uiElements.secondaryButton.mobileStyleOverride.buttonText,
        arrowStyleOverride: globalStyling.uiElements.secondaryButton.mobileStyleOverride.arrowIcon
      };
      break;
    default:
      globalMobileStyleOverride = null;
  }

  return _react2.default.createElement(
    StyledButton,
    (0, _extends3.default)({
      tabindex: '0'
    }, props, {
      className: className || '',
      buttonStyle: buttonStyle,
      styleOverride: styleOverride,
      textStyleOverride: globalMobileStyleOverride && globalMobileStyleOverride.textStyleOverride,
      buttonStyleOverride: function buttonStyleOverride() {
        return globalMobileStyleOverride && globalMobileStyleOverride.buttonStyleOverride ? _.buttonStyleOverride.apply(undefined, (0, _toConsumableArray3.default)(globalMobileStyleOverride.buttonStyleOverride)) : null;
      },
      applyStyle: applyStyle,
      onClick: onClick,
      'aria-label': label
    }),
    ButtonStyleContent[buttonStyle](text, className, globalMobileStyleOverride && globalMobileStyleOverride.arrowStyleOverride)
  );
}

Button.defaultProps = {
  applyStyle: 'primary',
  buttonStyle: 'ButtonStyle1',
  disabled: false,
  mobileStyleOverride: null,
  styleOverride: null,
  text: ''
};