'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearKey = exports.saveKey = exports.getKey = undefined;

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getKey = function getKey(key) {
  try {
    var value = localStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
}; // Contains all supported keys to be saved in local storage.


var saveKey = function saveKey(key, data) {
  try {
    localStorage.setItem(key, (0, _stringify2.default)(data));
  } catch (error) {
    console.error(' Error saving key in localStorage: ', error);
  }
};

var clearKey = function clearKey(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(' Error cleaning key in localStorage: ', error);
  }
};

exports.getKey = getKey;
exports.saveKey = saveKey;
exports.clearKey = clearKey;