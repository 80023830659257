'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = IconX;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function IconX(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 16 16', width: '1em', height: '1em' },
      _react2.default.createElement(
        'g',
        { fill: '#444', fillRule: 'nonzero' },
        _react2.default.createElement('path', { d: 'M4.707 12.707l-1.414-1.414 8-8 1.414 1.414z' }),
        _react2.default.createElement('path', { d: 'M3.293 4.707l1.414-1.414 8 8-1.414 1.414z' })
      )
    )
  );
}

IconX.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};