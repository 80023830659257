'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _chevronDown = require('react-icons/lib/io/chevron-down');

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var themeFromProps = function themeFromProps(_ref) {
  var font = _ref.font,
      hoverColour = _ref.hoverColour,
      backgroundColour = _ref.backgroundColour,
      backgroundHoverColour = _ref.backgroundHoverColour,
      iconColour = _ref.iconColour;
  return {
    fontFamily: font.typeface && font.typeface.label,
    fontSize: font.fontSize + 'px',
    characterSpacing: font.kerning + 'px',
    textTransform: font.transform,
    colours: {
      primary: font && font.colour && font.colour.value,
      hover: hoverColour && hoverColour.value,
      background: backgroundColour && backgroundColour.value,
      backgroundHoverColour: backgroundHoverColour && backgroundHoverColour.value,
      iconColour: iconColour && iconColour.value
    }
  };
};

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'DropDown__Container',
  componentId: 'sc-1vb9n6x-0'
})(['display:flex;flex-direction:column;outline:none;']);

var OptionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DropDown__OptionsContainer',
  componentId: 'sc-1vb9n6x-1'
})(['overflow:hidden;border-top:none;']);

var StyledOptions = _styledComponents2.default.div.withConfig({
  displayName: 'StyledOptions'
}).withConfig({
  displayName: 'DropDown__StyledOptions',
  componentId: 'sc-1vb9n6x-2'
})(['display:', ';flex-direction:column;z-index:12;font:', ';width:', ';', ' ', ' & h2{border:none;}'], function (_ref2) {
  var visible = _ref2.visible;
  return visible ? 'flex' : 'none';
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.link.fontSize + ' ' + theme.link.fontFamily;
}, function (_ref4) {
  var width = _ref4.width;
  return width ? width + 'px' : '100%';
}, function (props) {
  return props.layout === 'below' && (0, _styledComponents.css)(['position:', ';'], function (_ref5) {
    var position = _ref5.position;
    return position || 'absolute';
  });
}, function (props) {
  return props.layout === 'above' && (0, _styledComponents.css)(['position:absolute;top:-1px;margin-top:', 'px;background:#ffffff;'], function (_ref6) {
    var numberOptions = _ref6.numberOptions;
    return -numberOptions * 44;
  });
});

var LabelContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DropDown__LabelContainer',
  componentId: 'sc-1vb9n6x-3'
})(['overflow:hidden;display:flex;align-items:center;justify-content:space-between;padding:0 13px;cursor:pointer;', ';', ';'], function (_ref7) {
  var height = _ref7.height;
  return height ? 'height: ' + height + 'px' : 'height: unset';
}, function (_ref8) {
  var hover = _ref8.hover;
  return '' + (hover && 'background: ' + hover);
});

var Icon = (0, _styledComponents2.default)(_chevronDown2.default).withConfig({
  displayName: 'DropDown__Icon',
  componentId: 'sc-1vb9n6x-4'
})(['', ';', ';', ';cursor:pointer;'], function (_ref9) {
  var width = _ref9.width;
  return '' + (width && 'width: ' + width + 'px');
}, function (_ref10) {
  var height = _ref10.height;
  return '' + (height && 'height: ' + height + 'px');
}, function (_ref11) {
  var colour = _ref11.colour;
  return colour && 'color: ' + colour;
});

var isIE = /* @cc_on!@ */false || !!document.documentMode;

var Dropdown = function (_React$Component) {
  (0, _inherits3.default)(Dropdown, _React$Component);

  function Dropdown(props) {
    (0, _classCallCheck3.default)(this, Dropdown);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Dropdown.__proto__ || (0, _getPrototypeOf2.default)(Dropdown)).call(this, props));

    Object.defineProperty(_this, 'onDropdownBlur', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _this.setState(function () {
          return { open: false };
        });
      }
    });
    Object.defineProperty(_this, 'selectedOption', {
      enumerable: true,
      writable: true,
      value: function value(option) {
        return _this.props.options.find(function (o) {
          return _this.props.getValueFromOption(o) === _this.props.getValueFromOption(option);
        });
      }
    });
    Object.defineProperty(_this, 'placeHolderOrLabelFromOption', {
      enumerable: true,
      writable: true,
      value: function value(option) {
        return option ? (_this.props.getDisplayFromOption || _this.props.getLabelFromOption)(_this.selectedOption(option)) : _this.props.placeholder;
      }
    });
    Object.defineProperty(_this, 'toggle', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _this.setState(function (prevState) {
          return { open: !prevState.open };
        });
      }
    });

    _this.state = {
      open: false
    };
    return _this;
  }

  (0, _createClass3.default)(Dropdown, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          options = _props.options,
          onOptionClick = _props.onOptionClick,
          getLabelFromOption = _props.getLabelFromOption,
          getValueFromOption = _props.getValueFromOption,
          _props$theme = _props.theme,
          link = _props$theme.link,
          dropdown = _props$theme.dropdown,
          width = _props.width,
          height = _props.height,
          position = _props.position,
          layout = _props.layout,
          initialSelectedValue = _props.initialSelectedValue,
          selectedValue = _props.selectedValue,
          direction = _props.direction;

      var theme = {
        link: themeFromProps(link),
        dropdown: themeFromProps(dropdown)
      };
      var option = this.selectedOption({ value: selectedValue });
      return _react2.default.createElement(
        Container,
        { tabIndex: '0', onBlur: !isIE ? this.onDropdownBlur : function () {} },
        _react2.default.createElement(
          LabelContainer,
          {
            onClick: this.toggle,
            height: height,
            hover: link.hoverColour
          },
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return 'letter-spacing: 1px;\n              ' + (0, _Global.fontStyleOverride)(link.font) + '\n              padding: 0 8px;\n              width: auto;\n              display: flex;\n              align-items: center;\n              margin: 0;\n            ';
              }
            },
            this.placeHolderOrLabelFromOption(option || initialSelectedValue)
          ),
          _react2.default.createElement(Icon, {
            width: dropdown && dropdown.dropdownIconSize && dropdown.dropdownIconSize.width,
            height: dropdown && dropdown.dropdownIconSize && dropdown.dropdownIconSize.height,
            colour: dropdown && dropdown.iconColour && dropdown.iconColour.value
          })
        ),
        _react2.default.createElement(
          OptionsContainer,
          { theme: theme },
          _react2.default.createElement(
            StyledOptions,
            {
              position: position,
              theme: theme,
              visible: this.state.open,
              width: width,
              layout: layout,
              numberOptions: options.length
            },
            options.map(function (op) {
              return _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  onClick: function onClick() {
                    if (op.value !== _this2.props.selectedValue) {
                      onOptionClick(op);
                      _this2.setState(function (prevState) {
                        return { open: !prevState.open };
                      });
                    }
                  },
                  key: getValueFromOption(op),
                  styleOverride: function styleOverride() {
                    return '\n                    ' + (0, _Global.fontStyleOverride)(dropdown.font) + '\n                    ' + (dropdown.backgroundColour && 'background: ' + dropdown.backgroundColour.value) + ';\n                    padding: 15px 10px;\n                    border-bottom: 1px solid #ccc;\n                    white-space: nowrap;\n                    text-align:  ' + (direction === 'rtl' ? 'right' : 'left') + ';\n                    cursor: pointer;\n                    overflow: hidden;\n                    text-overflow: ellipsis;\n                    transition: background-color 0.5s ease;\n                    &:hover {\n                      ' + (dropdown.hoverColour && 'background: ' + dropdown.hoverColour.value) + ';\n                    }';
                  }
                },
                getLabelFromOption(op)
              );
            })
          )
        )
      );
    }
  }]);
  return Dropdown;
}(_react2.default.Component);

Object.defineProperty(Dropdown, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    options: [],
    onOptionClick: function onOptionClick() {},
    placeholder: '',
    initialSelectedValue: null,
    height: 30,
    layout: 'below'
  }
});
exports.default = Dropdown;