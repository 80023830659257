'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 90%;\n    padding: 0 5%;\n  '], ['\n    width: 90%;\n    padding: 0 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n     width: 100%;\n     padding: 20px 0;\n   '], ['\n     width: 100%;\n     padding: 20px 0;\n   ']);
/* eslint-env browser */


exports.default = TestDriveSummary;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Phone = require('./JaguarIcons/Global/Phone');

var _Phone2 = _interopRequireDefault(_Phone);

var _Pin = require('./JaguarIcons/Global/Pin');

var _Pin2 = _interopRequireDefault(_Pin);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Summary = require('./Summary');

var _Summary2 = _interopRequireDefault(_Summary);

var _Button = require('./Global/Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummaryContainer'
}).withConfig({
  displayName: 'TestDriveSummary__Container',
  componentId: 'sc-byohhw-0'
})(['height:auto;text-align:center;margin:20px auto 80px auto;max-width:930px;', ';'], _theme2.default.max.large(_templateObject));

var Block = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__Block',
  componentId: 'sc-byohhw-1'
})(['text-align:', ';margin-bottom:20px;line-height:21px;'], function (_ref) {
  var center = _ref.center;
  return center ? 'center' : 'left';
});

var Heading = _styledComponents2.default.span.withConfig({
  displayName: 'TestDriveSummary__Heading',
  componentId: 'sc-byohhw-2'
})(['font-size:20px;font-weight:600;color:#000000;']);

var DesktopOnly = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__DesktopOnly',
  componentId: 'sc-byohhw-3'
})(['display:block;', ';'], _theme2.default.max.large(_templateObject2));
var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__MobileOnly',
  componentId: 'sc-byohhw-4'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject3));
var SummaryFooter = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__SummaryFooter',
  componentId: 'sc-byohhw-5'
})(['display:flex;justify-content:space-evenly;width:50%;padding:0 25%;', ';'], _theme2.default.max.large(_templateObject4));

var ThanksDiv = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__ThanksDiv',
  componentId: 'sc-byohhw-6'
})(['display:flex;justify-content:space-between;padding:20px 0;']);

var ThanksText = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__ThanksText',
  componentId: 'sc-byohhw-7'
})(['text-align:left;font-size:16px;line-height:26px;color:#444444;']);

var ReserveCarBox = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__ReserveCarBox',
  componentId: 'sc-byohhw-8'
})(['border:1px solid #d8d8d8;']);

var ReserveCarBoxHead = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__ReserveCarBoxHead',
  componentId: 'sc-byohhw-9'
})(['display:flex;align-items:center;justify-content:center;background:#f0f0f0;height:50px;font-weight:600;color:#000000;']);

var ReserveCarBoxFoot = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__ReserveCarBoxFoot',
  componentId: 'sc-byohhw-10'
})(['display:flex;align-items:center;height:85px;padding:0 22px;']);

var DepositText = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__DepositText',
  componentId: 'sc-byohhw-11'
})(['max-width:68%;text-align:left;font-size:16px;line-height:26px;color:#444444;']);

var ReserveButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__ReserveButtonContainer',
  componentId: 'sc-byohhw-12'
})(['width:32%;']);

var SearchResultsActionButton = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__SearchResultsActionButton',
  componentId: 'sc-byohhw-13'
})(['padding:22px 5%;margin-top:32px;']);

var VisitUs = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveSummary__VisitUs',
  componentId: 'sc-byohhw-14'
})(['display:flex;align-items:center;cursor:pointer;']);

var ContactLink = _styledComponents2.default.a.withConfig({
  displayName: 'TestDriveSummary__ContactLink',
  componentId: 'sc-byohhw-15'
})(['display:flex;align-items:center;text-decoration:none;color:#000000;']);

function TestDriveSummary(props) {
  window.scroll(0, 0);
  var retailerInformation = props.testDriveConfig.retailerInformation;
  var colours = props.globalStyling.colours;

  var primaryBrandColour = colours.primaryBrandColour && colours.primaryBrandColour.value;
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        Block,
        { center: true },
        _react2.default.createElement(
          Heading,
          null,
          props.translations.testDriveSummaryHeader
        )
      ),
      _react2.default.createElement(
        DesktopOnly,
        null,
        _react2.default.createElement(
          Block,
          null,
          _react2.default.createElement(_Summary2.default, {
            bookingForm: props.bookingForm,
            testDriveConfig: props.testDriveConfig,
            globalStyling: props.globalStyling,
            translations: props.translations,
            navigateToVdp: props.navigateToVdp
          })
        ),
        _react2.default.createElement(
          Block,
          null,
          _react2.default.createElement(
            ThanksDiv,
            null,
            props.userCreated && _react2.default.createElement(
              'div',
              { style: { maxWidth: '80%', marginRight: '51px' } },
              _react2.default.createElement(
                Heading,
                null,
                props.translations.testDriveSummaryVerifyAccountHeader
              ),
              _react2.default.createElement(
                ThanksText,
                null,
                props.translations.testDriveSummaryVerifyAccountStart,
                _react2.default.createElement(
                  'b',
                  null,
                  props.newUserEmail
                )
              ),
              _react2.default.createElement(
                ThanksText,
                null,
                props.translations.testDriveSummaryVerifyAccountEnd
              )
            )
          ),
          _react2.default.createElement(
            ThanksDiv,
            null,
            _react2.default.createElement(
              'div',
              { style: { maxWidth: '80%', marginRight: '51px' } },
              _react2.default.createElement(
                Heading,
                null,
                props.translations.testDriveSummaryContentHeader
              ),
              _react2.default.createElement(
                ThanksText,
                null,
                props.translations.testDriveSummaryContent
              )
            ),
            _react2.default.createElement(
              'div',
              { style: { minWidth: '308px' } },
              _react2.default.createElement(
                Block,
                null,
                _react2.default.createElement(_Button2.default, {
                  applyStyle: 'secondary',
                  buttonStyle: props.globalStyling.uiElements.secondaryButton && props.globalStyling.uiElements.secondaryButton.buttonStyle,
                  text: props.translations.testDriveSummaryEmailButton,
                  onClick: function onClick() {
                    return props.contactUs(props.testDriveConfig);
                  }
                })
              ),
              props.userIsLoggedIn && _react2.default.createElement(_Button2.default, {
                applyStyle: 'secondary',
                buttonStyle: props.globalStyling.uiElements.secondaryButton && props.globalStyling.uiElements.secondaryButton.buttonStyle,
                text: props.translations.testDriveSummaryAccountButton,
                onClick: props.goToMyAccount
              })
            )
          )
        ),
        _react2.default.createElement(
          Block,
          null,
          _react2.default.createElement(
            ReserveCarBox,
            null,
            _react2.default.createElement(
              ReserveCarBoxHead,
              null,
              _react2.default.createElement(
                Heading,
                null,
                props.translations.testDriveSummaryReserveHeader
              )
            ),
            _react2.default.createElement(
              ReserveCarBoxFoot,
              null,
              _react2.default.createElement(
                DepositText,
                null,
                props.translations.testDriveSummaryReserveContent
              ),
              _react2.default.createElement(
                ReserveButtonContainer,
                null,
                _react2.default.createElement(_Button2.default, {
                  applyStyle: 'primary',
                  buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
                  text: props.translations.testDriveSummaryReserveButton,
                  onClick: function onClick() {
                    return props.contactUs(props.testDriveConfig);
                  }
                })
              )
            )
          )
        )
      ),
      _react2.default.createElement(
        MobileOnly,
        null,
        _react2.default.createElement(_Summary2.default, {
          bookingForm: props.bookingForm,
          testDriveConfig: props.testDriveConfig,
          globalStyling: props.globalStyling,
          translations: props.translations
        }),
        _react2.default.createElement(
          ThanksDiv,
          null,
          props.userCreated && _react2.default.createElement(
            'div',
            { style: { maxWidth: '80%', marginRight: '51px' } },
            _react2.default.createElement(
              Heading,
              null,
              props.translations.testDriveSummaryVerifyAccountHeader
            ),
            _react2.default.createElement(
              ThanksText,
              null,
              props.translations.testDriveSummaryVerifyAccountStart,
              _react2.default.createElement(
                'b',
                null,
                props.newUserEmail
              )
            ),
            _react2.default.createElement(
              ThanksText,
              null,
              props.translations.testDriveSummaryVerifyAccountEnd
            )
          )
        ),
        _react2.default.createElement(
          Block,
          null,
          props.translations.testDriveSummaryReserveHeader,
          props.translations.testDriveSummaryReserveContent
        ),
        _react2.default.createElement(
          Block,
          null,
          props.userIsLoggedIn && _react2.default.createElement(_Button2.default, {
            applyStyle: 'secondary',
            buttonStyle: props.globalStyling.uiElements.secondaryButton && props.globalStyling.uiElements.secondaryButton.buttonStyle,
            text: props.translations.testDriveSummaryAccountButton,
            onClick: props.goToMyAccount
          })
        ),
        _react2.default.createElement(
          Block,
          null,
          _react2.default.createElement(
            Heading,
            null,
            props.translations.testDriveSummaryNextSteps
          )
        ),
        _react2.default.createElement(
          Block,
          null,
          props.translations.testDriveSummaryContentHeader,
          props.translations.testDriveSummaryContent
        ),
        _react2.default.createElement(
          Block,
          null,
          _react2.default.createElement(_Button2.default, {
            applyStyle: 'secondary',
            buttonStyle: props.globalStyling.uiElements.secondaryButton && props.globalStyling.uiElements.secondaryButton.buttonStyle,
            text: props.translations.testDriveSummaryEmailButton,
            onClick: function onClick() {
              return props.contactUs(props.testDriveConfig);
            }
          })
        ),
        _react2.default.createElement(
          Block,
          null,
          _react2.default.createElement(_Button2.default, {
            applyStyle: 'secondary',
            buttonStyle: props.globalStyling.uiElements.secondaryButton && props.globalStyling.uiElements.secondaryButton.buttonStyle,
            text: props.translations.testDriveSummaryReserveButton,
            onClick: function onClick() {
              return props.contactUs(props.testDriveConfig);
            }
          })
        )
      ),
      _react2.default.createElement(
        SummaryFooter,
        null,
        _react2.default.createElement(
          VisitUs,
          { onClick: function onClick() {
              return props.onVisitUs(retailerInformation);
            } },
          _react2.default.createElement(_Pin2.default, { width: '2em', height: '2em', colour: primaryBrandColour }),
          props.translations.testDriveSummaryVisitUs
        ),
        _react2.default.createElement(
          ContactLink,
          { href: 'tel:' + retailerInformation.phone },
          _react2.default.createElement(_Phone2.default, { width: '2em', height: '2em', colour: primaryBrandColour }),
          retailerInformation.phone
        )
      )
    ),
    _react2.default.createElement(
      MobileOnly,
      null,
      _react2.default.createElement(
        SearchResultsActionButton,
        null,
        _react2.default.createElement(_Button2.default, {
          applyStyle: 'secondary',
          buttonStyle: props.globalStyling.uiElements.secondaryButton && props.globalStyling.uiElements.secondaryButton.buttonStyle,
          text: props.translations.testDriveSummaryReturnToSearchResults,
          onClick: function onClick() {}
        })
      )
    )
  );
}