'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n  '], ['\n    display: flex;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 100%;\n  '], ['\n    max-width: 100%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _router = require('../../actions/router');

var _Global = require('../../components/Global');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ResultTileWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ResultTileContainer__ResultTileWrapper',
  componentId: 'sc-1p6vvl-0'
})(['margin-bottom:16px;border:', ';', ';', ';'], function (_ref) {
  var border = _ref.border;
  return border || '1px solid #d8d8d8';
}, function (_ref2) {
  var backgroundColour = _ref2.backgroundColour;
  return backgroundColour && 'background-color: ' + backgroundColour;
}, function (_ref3) {
  var column = _ref3.column;
  return column && 'flex-direction: column';
});

var ListTile = (0, _styledComponents2.default)(ResultTileWrapper).withConfig({
  displayName: 'ResultTileContainer__ListTile',
  componentId: 'sc-1p6vvl-1'
})(['width:100% display:none;', ';'], _theme2.default.min.large(_templateObject));

var GridTile = (0, _styledComponents2.default)(ResultTileWrapper).withConfig({
  displayName: 'ResultTileContainer__GridTile',
  componentId: 'sc-1p6vvl-2'
})(['display:none;max-width:300px;margin:0 auto 16px;', ';'], _theme2.default.max.large(_templateObject));

var ContentColumn = _styledComponents2.default.div.withConfig({
  displayName: 'ResultTileContainer__ContentColumn',
  componentId: 'sc-1p6vvl-3'
})(['display:flex;flex:1 0 ', ';'], function (_ref4) {
  var basis = _ref4.basis;
  return basis + 'px';
});

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'ResultTileContainer__Image',
  componentId: 'sc-1p6vvl-4'
})(['height:auto;max-width:300px;display:flex;', ';'], _theme2.default.max.large(_templateObject2));

var RightContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ResultTileContainer__RightContentWrapper',
  componentId: 'sc-1p6vvl-5'
})(['width:100%;display:flex;flex-direction:column;justify-content:space-between;']);

var ImageFooter = _styledComponents2.default.div.withConfig({
  displayName: 'ResultTileContainer__ImageFooter',
  componentId: 'sc-1p6vvl-6'
})(['display:flex;align-items:center;height:30px;margin-top:-30px;opacity:0.7;background:#000000;color:#ffffff;']);

var Views = _styledComponents2.default.span.withConfig({
  displayName: 'ResultTileContainer__Views',
  componentId: 'sc-1p6vvl-7'
})(['padding-top:2px;']);

var ViewsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ResultTileContainer__ViewsContainer',
  componentId: 'sc-1p6vvl-8'
})(['display:flex;']);

var ViewsImage = _styledComponents2.default.img.withConfig({
  displayName: 'ResultTileContainer__ViewsImage',
  componentId: 'sc-1p6vvl-9'
})(['width:1.5em;height:1.25em;color:#ffffff;']);

var ImageContent = function ImageContent(_ref5) {
  var vehicle = _ref5.vehicle,
      showViews = _ref5.showViews,
      viewsIconUrl = _ref5.viewsIconUrl;
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(Image, { src: vehicle.images[0] }),
    _react2.default.createElement(
      ImageFooter,
      null,
      showViews && _react2.default.createElement(
        ViewsContainer,
        null,
        _react2.default.createElement(ViewsImage, { src: viewsIconUrl }),
        _react2.default.createElement(
          Views,
          null,
          vehicle.views
        )
      )
    )
  );
};

var RightContent = function RightContent(_ref6) {
  var globalStyling = _ref6.globalStyling,
      onMoreInfoClick = _ref6.onMoreInfoClick;
  return _react2.default.createElement(
    RightContentWrapper,
    null,
    _react2.default.createElement(
      'div',
      null,
      'Future content'
    ),
    _react2.default.createElement(_Global.Button, {
      onClick: onMoreInfoClick,
      buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
      applyStyle: 'primary',
      text: 'Hardcoded more info',
      styleOverride: function styleOverride() {
        return '\n        max-width: 100%;\n        margin: 10px;\n        align-self: auto;';
      }
    })
  );
};

var ListResultTile = function ListResultTile(_ref7) {
  var vehicle = _ref7.vehicle,
      placeholdingImage = _ref7.placeholdingImage,
      globalStyling = _ref7.globalStyling,
      onMoreInfoClick = _ref7.onMoreInfoClick,
      moduleConfig = _ref7.moduleConfig;
  return _react2.default.createElement(
    ListTile,
    {
      border: moduleConfig.border,
      backgroundColour: moduleConfig.backgroundColour
    },
    _react2.default.createElement(
      ContentColumn,
      { basis: 100 },
      _react2.default.createElement(ImageContent, {
        vehicle: vehicle,
        placeholdingImage: placeholdingImage,
        showViews: moduleConfig.showViews,
        viewsIconUrl: moduleConfig.viewsIconUrl
      })
    ),
    _react2.default.createElement(
      ContentColumn,
      { basis: 145 },
      'Center'
    ),
    _react2.default.createElement(
      ContentColumn,
      { basis: 135 },
      _react2.default.createElement(RightContent, {
        globalStyling: globalStyling,
        onMoreInfoClick: onMoreInfoClick
      })
    )
  );
};

var GridResultTile = function GridResultTile(_ref8) {
  var vehicle = _ref8.vehicle,
      placeholdingImage = _ref8.placeholdingImage,
      globalStyling = _ref8.globalStyling,
      onMoreInfoClick = _ref8.onMoreInfoClick,
      moduleConfig = _ref8.moduleConfig;
  return _react2.default.createElement(
    GridTile,
    {
      border: moduleConfig.border,
      backgroundColour: moduleConfig.backgroundColour,
      column: true
    },
    _react2.default.createElement(ImageContent, {
      vehicle: vehicle,
      placeholdingImage: placeholdingImage,
      showViews: moduleConfig.showViews
    }),
    _react2.default.createElement(
      'div',
      null,
      'Center'
    ),
    _react2.default.createElement(RightContent, {
      globalStyling: globalStyling,
      onMoreInfoClick: onMoreInfoClick
    })
  );
};

function ResultTile(props) {
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(ListResultTile, props),
    _react2.default.createElement(GridResultTile, props)
  );
}

var mapStateToProps = function mapStateToProps() {
  return {};
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, _ref9) {
  var pathByModule = _ref9.pathByModule,
      vehicle = _ref9.vehicle;
  return {
    onMoreInfoClick: function onMoreInfoClick() {
      return vehicle.status !== 'sold' && dispatch(_router.actions.navigate(pathByModule('VDP') + '/' + vehicle.id));
    }
  };
};

exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(ResultTile);