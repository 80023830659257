'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RangeHeading = require('./RangeHeading');

var _RangeHeading2 = _interopRequireDefault(_RangeHeading);

var _RangeSlider = require('../RangeSlider');

var _RangeSlider2 = _interopRequireDefault(_RangeSlider);

var _searchFiltersHelper = require('./search-filters-helper');

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RangeContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RangeContainer'
}).withConfig({
  displayName: 'PriceRangeFilter__RangeContainer',
  componentId: 'sc-1e94b5r-0'
})(['padding:20px;border-top:1px solid #d8d8d8;']);
var TabContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TabContainer'
}).withConfig({
  displayName: 'PriceRangeFilter__TabContainer',
  componentId: 'sc-1e94b5r-1'
})(['display:flex;justify-content:space-between;width:100%;margin-bottom:30px;']);
var Tab = _styledComponents2.default.button.withConfig({
  displayName: 'RangeTabButton'
}).withConfig({
  displayName: 'PriceRangeFilter__Tab',
  componentId: 'sc-1e94b5r-2'
})(['width:49%;border:1px solid ', ';height:45px;font-size:15px;font-weight:600;color:', ';margin:0;background-color:#ffffff;cursor:pointer;outline:none;text-transform:capitalize;font-family:', ';'], function (props) {
  return props.active ? '#7e7e7e' : '#d8d8d8';
}, function (_ref) {
  var active = _ref.active,
      selectedColour = _ref.selectedColour;
  return active ? selectedColour : '#444444';
}, function (_ref2) {
  var fontFamily = _ref2.fontFamily;
  return fontFamily.value;
});
var MonthlyBudgetText = _styledComponents2.default.div.withConfig({
  displayName: 'MonthlyBudgetText'
}).withConfig({
  displayName: 'PriceRangeFilter__MonthlyBudgetText',
  componentId: 'sc-1e94b5r-3'
})(['font-size:14px;text-align:center;font-weight:500;color:#7e7e7e;margin:25px 5px 0 5px;']);

var priceRangeContext = {
  monthly: 'MONTHLY',
  cash: 'CASH'
};

var PriceRangeFilter = function (_Component) {
  (0, _inherits3.default)(PriceRangeFilter, _Component);

  function PriceRangeFilter(props) {
    (0, _classCallCheck3.default)(this, PriceRangeFilter);

    var _this = (0, _possibleConstructorReturn3.default)(this, (PriceRangeFilter.__proto__ || (0, _getPrototypeOf2.default)(PriceRangeFilter)).call(this, props));

    Object.defineProperty(_this, 'onTabClick', {
      enumerable: true,
      writable: true,
      value: function value(context) {
        var _this$props = _this.props,
            priceRange = _this$props.priceRange,
            monthlyPaymentRange = _this$props.monthlyPaymentRange,
            removeRangeFilter = _this$props.removeRangeFilter,
            handleRangeFilters = _this$props.handleRangeFilters,
            rangeValues = _this$props.rangeValues,
            availableFilters = _this$props.availableFilters;

        var idToRemoveFromFilter = context === priceRangeContext.monthly ? priceRange.id : monthlyPaymentRange.id;
        var idRange = context === priceRangeContext.monthly ? monthlyPaymentRange.id : priceRange.id;
        // this is a patch until we refactor the filters.
        // monthly payment range will be Null when the emulator fails/does not return anything
        if (context === priceRangeContext.monthly && availableFilters.monthlyPaymentRange === null) {
          _this.setState(function () {
            return { context: context };
          });
          return;
        }
        _this.setState(function () {
          return { context: context };
        }, function () {
          removeRangeFilter(idToRemoveFromFilter);
          handleRangeFilters(idRange, [rangeValues[idRange].min, rangeValues[idRange].max]);
        });
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props2 = _this.props,
            stylingConfig = _this$props2.stylingConfig,
            translations = _this$props2.translations,
            availableFilters = _this$props2.availableFilters,
            priceRange = _this$props2.priceRange,
            monthlyPaymentRange = _this$props2.monthlyPaymentRange,
            onChangeRangeLabel = _this$props2.onChangeRangeLabel,
            handleRangeFilters = _this$props2.handleRangeFilters,
            resetRangeCount = _this$props2.resetRangeCount,
            rangeValues = _this$props2.rangeValues,
            featureFlags = _this$props2.featureFlags,
            headingValues = _this$props2.headingValues,
            locale = _this$props2.locale;

        var _ref3 = availableFilters.monthlyPaymentRange || {},
            duration = _ref3.duration,
            deposit = _ref3.deposit;

        var priceRangeValues = rangeValues[priceRange.id];
        var monthlyPaymentRangeValues = rangeValues[monthlyPaymentRange.id];
        var priceHeadingValues = headingValues[priceRange.id];
        var monthlyHeadingValues = headingValues[monthlyPaymentRange.id];

        return _react2.default.createElement(
          RangeContainer,
          null,
          featureFlags.budgetSearch && featureFlags.finance && _react2.default.createElement(
            TabContainer,
            null,
            _react2.default.createElement(
              Tab,
              {
                'data-cy': 'monthly-button',
                onClick: function onClick() {
                  return _this.onTabClick(priceRangeContext.monthly);
                },
                active: _this.state.context === priceRangeContext.monthly,
                selectedColour: stylingConfig.rangeButtonTextSelectedColour && stylingConfig.rangeButtonTextSelectedColour.value,
                fontFamily: stylingConfig.rangeButtonFont
              },
              translations.searchPriceByMonth
            ),
            _react2.default.createElement(
              Tab,
              {
                'data-cy': 'cash-button',
                onClick: function onClick() {
                  return _this.onTabClick(priceRangeContext.cash);
                },
                active: _this.state.context === priceRangeContext.cash,
                selectedColour: stylingConfig.rangeButtonTextSelectedColour && stylingConfig.rangeButtonTextSelectedColour.value,
                fontFamily: stylingConfig.rangeButtonFont
              },
              translations.searchPriceByCash
            )
          ),
          _this.state.context === priceRangeContext.cash && _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(_RangeHeading2.default, {
              range: priceHeadingValues,
              heading: translations.searchSubHeaderPrice,
              unit: translations.unitPrice,
              locale: locale,
              id: priceRange.id
            }),
            _react2.default.createElement(_RangeSlider2.default, {
              stylingConfig: stylingConfig,
              step: priceRange.step,
              rangeValue: priceRange.fullRangeValues,
              name: priceRange.name,
              defaultValue: (0, _searchFiltersHelper.getInitialRangeValues)(100, priceRangeValues),
              onAfterChange: function onAfterChange(values) {
                return handleRangeFilters(priceRange.id, values);
              },
              onChange: function onChange(values) {
                return onChangeRangeLabel(values, 'priceRangeLabel');
              },
              key: resetRangeCount
            })
          ),
          _this.state.context === priceRangeContext.monthly && availableFilters.monthlyPaymentRange !== null && _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(_RangeHeading2.default, {
              range: monthlyHeadingValues,
              heading: translations.searchSubHeaderPriceMonthly,
              unit: translations.unitPrice,
              locale: locale
            }),
            _react2.default.createElement(_RangeSlider2.default, {
              stylingConfig: stylingConfig,
              step: monthlyPaymentRange.step,
              rangeValue: monthlyPaymentRange.fullRangeValues,
              name: monthlyPaymentRange.name,
              defaultValue: (0, _searchFiltersHelper.getInitialRangeValues)(100, monthlyPaymentRangeValues),
              onAfterChange: function onAfterChange(values) {
                return handleRangeFilters(monthlyPaymentRange.id, values);
              },
              onChange: function onChange(values) {
                return onChangeRangeLabel(values, 'monthlyPaymentRangeLabel');
              },
              key: resetRangeCount
            }),
            _react2.default.createElement(
              MonthlyBudgetText,
              { 'data-cy': 'monthly-text' },
              duration && deposit ? (0, _translateFromTemplate.translateFromTemplate)('searchPriceByMonthText', {
                DURATION: duration,
                DEPOSIT: deposit
              }, translations) : translations.noResultsText
            )
          ),
          _this.state.context === priceRangeContext.monthly && availableFilters.monthlyPaymentRange === null && _react2.default.createElement(
            MonthlyBudgetText,
            null,
            translations.monthlyBudgetFailureText
          )
        );
      }
    });

    var selectedDefaultView = props.availableFilters.monthlyPaymentRange ? priceRangeContext.monthly : priceRangeContext.cash;

    _this.state = {
      context: props.featureFlags.finance && props.featureFlags.budgetSearch ? selectedDefaultView : priceRangeContext.cash
    };
    return _this;
  }

  return PriceRangeFilter;
}(_react.Component);

exports.default = PriceRangeFilter;