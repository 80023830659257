'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    color: #333333;\n  '], ['\n    color: #333333;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _vehicle = require('../../../helpers/vehicle');

var _ipInformation = require('../../../shared/localisation/ipInformation');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicleTypes = require('../../../types/Bentley/vehicleTypes');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__Container',
  componentId: 'sc-1k43wl0-0'
})(['display:flex;flex-direction:row;justify-content:space-between;align-items:flex-start;margin:6px 0;']);

var Information = _styledComponents2.default.ul.withConfig({
  displayName: 'Emissions__Information',
  componentId: 'sc-1k43wl0-1'
})(['font-family:Bentley-light;text-wrap:balance;font-size:14px;line-height:16px;color:#222222;text-transform:none;list-style-type:none;margin:0;padding:0;', ''], _theme2.default.max.small(_templateObject));

var countryCode = (0, _ipInformation.getIpCountryCode)();

var Emissions = function Emissions(props) {
  var emissionsData = props.emissionsData,
      consumptionsData = props.consumptionsData,
      efficiency = props.efficiency,
      translations = props.translations,
      config = props.config,
      vehicle = props.vehicle;
  var consumptionCombined = translations.consumptionCombined,
      co2EmissionsCombined = translations.co2EmissionsCombined,
      energyEfficiencyClass = translations.energyEfficiencyClass,
      weightedCombined = translations.weightedCombined,
      classWithDischargedBattery = translations.classWithDischargedBattery;


  var emissionsMedia = countryCode && countryCode === 'CH' ? config.energyEfficiencyRatingMediaSwiss : config.energyEfficiencyRatingMedia;

  return _react2.default.createElement(
    Container,
    null,
    config.isNVL && vehicle.modelData ? _react2.default.createElement(
      Information,
      null,
      translations[vehicle.modelData.id]
    ) : _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        Information,
        null,
        consumptionsData.wltpConsumptionCombined && _react2.default.createElement(
          'li',
          null,
          consumptionCombined + ': ' + ((0, _vehicle.fromMpgToLitresPer100ToString)(consumptionsData.wltpConsumptionCombined) || '-') + ' (WLTP)'
        ),
        emissionsData.wltpEmissionsCombined && _react2.default.createElement(
          'li',
          null,
          co2EmissionsCombined + ': ' + (emissionsData.wltpEmissionsCombined || '-') + ' g/km (WLTP)'
        ),
        emissionsData.co2ClassWeightedCombined && _react2.default.createElement(
          'li',
          null,
          weightedCombined,
          ':',
          ' ',
          emissionsData.co2ClassWeightedCombined || '-'
        ),
        emissionsData.co2ClassDischaredBattery && _react2.default.createElement(
          'li',
          null,
          classWithDischargedBattery,
          ':',
          ' ',
          emissionsData.co2ClassDischaredBattery || '-'
        ),
        !emissionsData.co2ClassWeightedCombined && efficiency && _react2.default.createElement(
          'li',
          null,
          energyEfficiencyClass,
          ': ',
          efficiency || '-'
        )
      ),
      efficiency && emissionsMedia[efficiency] && _react2.default.createElement('img', {
        src: emissionsMedia[efficiency],
        alt: efficiency,
        style: {
          maxWidth: countryCode && countryCode === 'CH' ? 40 : 75
        }
      })
    )
  );
};

exports.default = Emissions;