'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = VdpPerformance;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Electric = require('./JaguarIcons/VDP/Electric');

var _Electric2 = _interopRequireDefault(_Electric);

var _Efficiency = require('./JaguarIcons/VDP/Efficiency');

var _Efficiency2 = _interopRequireDefault(_Efficiency);

var _Mileage = require('./JaguarIcons/VDP/Mileage');

var _Mileage2 = _interopRequireDefault(_Mileage);

var _Fuel = require('./JaguarIcons/VDP/Fuel');

var _Fuel2 = _interopRequireDefault(_Fuel);

var _Stopwatch = require('./JaguarIcons/VDP/Stopwatch');

var _Stopwatch2 = _interopRequireDefault(_Stopwatch);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpPerformance__Container',
  componentId: 'sc-117ihfm-0'
})(['width:', ';height:auto;margin:20px 0;'], function (props) {
  return props.isMobile ? '50%' : '100%';
});


var InfoBlockRow = _styledComponents2.default.div.withConfig({
  displayName: 'VdpPerformance__InfoBlockRow',
  componentId: 'sc-117ihfm-1'
})(['display:flex;margin-top:20px;']);

var InfoBlockContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpPerformance__InfoBlockContainer',
  componentId: 'sc-117ihfm-2'
})(['display:flex;align-items:center;width:', ';border-right:', ';border-left:', ';border-color:#dedede;box-sizing:border-box;'], function (props) {
  return props.isMobile ? '100%' : '33%';
}, function (props) {
  return props.isCenter ? '1px solid' : 'none';
}, function (props) {
  return props.isCenter ? '1px solid' : 'none';
});
var IconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpPerformance__IconContainer',
  componentId: 'sc-117ihfm-3'
})(['width:30%;display:flex;justify-content:center;']);
var InfoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpPerformance__InfoContainer',
  componentId: 'sc-117ihfm-4'
})(['width:70%;']);
var InfoStyling = _styledComponents2.default.div.withConfig({
  displayName: 'VdpPerformance__InfoStyling',
  componentId: 'sc-117ihfm-5'
})(['color:#6b6b6b;font-size:16px;font-weight:600;padding:3px;']);

var InfoBlock = function InfoBlock(_ref) {
  var label = _ref.label,
      value = _ref.value,
      isCenter = _ref.isCenter,
      Icon = _ref.icon,
      iconProps = _ref.iconProps,
      isMobile = _ref.isMobile;
  return _react2.default.createElement(
    InfoBlockContainer,
    { isCenter: isCenter, isMobile: isMobile },
    _react2.default.createElement(
      IconContainer,
      null,
      _react2.default.createElement(Icon, (0, _extends3.default)({ colour: '#acacac' }, iconProps))
    ),
    _react2.default.createElement(
      InfoContainer,
      null,
      _react2.default.createElement(
        InfoStyling,
        null,
        label
      ),
      _react2.default.createElement(
        InfoStyling,
        null,
        value
      )
    )
  );
};

InfoBlock.defaultProps = {
  isCenter: false,
  isMobile: false
};

function VdpPerformance(props) {
  var _props$performance = props.performance,
      acceleration = _props$performance.acceleration,
      emissions = _props$performance.emissions,
      maxSpeed = _props$performance.maxSpeed,
      maxPower = _props$performance.maxPower,
      fuelConsumption = _props$performance.fuelConsumption;
  var isMobile = props.isMobile;


  return isMobile ? _react2.default.createElement(
    Container,
    { isMobile: true },
    _react2.default.createElement(InfoBlock, {
      label: 'Acceleration',
      value: acceleration,
      icon: _Stopwatch2.default,
      iconProps: { width: '4em', height: '4em' },
      isMobile: true
    }),
    _react2.default.createElement(InfoBlock, {
      label: 'Max Speed',
      value: maxSpeed,
      icon: _Mileage2.default,
      iconProps: { width: '3em', height: '3em' },
      isMobile: true
    }),
    _react2.default.createElement(InfoBlock, {
      label: 'Max Power',
      value: maxPower,
      icon: _Electric2.default,
      iconProps: { width: '3em', height: '3em' },
      isMobile: true
    }),
    _react2.default.createElement(InfoBlock, {
      label: 'Emissions(CO2)',
      value: emissions,
      icon: _Efficiency2.default,
      iconProps: { width: '3.5em', height: '3.5em' },
      isMobile: true
    }),
    _react2.default.createElement(InfoBlock, {
      label: 'Fuel Consumption',
      value: fuelConsumption,
      icon: _Fuel2.default,
      iconProps: { width: '3em', height: '3em' },
      isMobile: true
    })
  ) : _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      InfoBlockRow,
      null,
      _react2.default.createElement(InfoBlock, {
        label: 'Acceleration',
        value: acceleration,
        icon: _Stopwatch2.default,
        iconProps: { width: '4em', height: '4em' }
      }),
      _react2.default.createElement(InfoBlock, {
        label: 'Max Speed',
        value: maxSpeed,
        isCenter: true,
        icon: _Mileage2.default,
        iconProps: { width: '3em', height: '3em' }
      }),
      _react2.default.createElement(InfoBlock, {
        label: 'Max Power',
        value: maxPower,
        icon: _Electric2.default,
        iconProps: { width: '3em', height: '3em' }
      })
    ),
    _react2.default.createElement(
      InfoBlockRow,
      null,
      _react2.default.createElement(InfoBlock, {
        label: 'Emissions(CO2)',
        value: emissions,
        icon: _Efficiency2.default,
        iconProps: { width: '3.5em', height: '3.5em' }
      }),
      _react2.default.createElement(InfoBlock, {
        label: 'Fuel Consumption',
        value: fuelConsumption,
        isCenter: true,
        icon: _Fuel2.default,
        iconProps: { width: '3em', height: '3em' }
      })
    )
  );
}
VdpPerformance.defaultProps = {
  isMobile: false
};