'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ModelDescription = require('../../../components/Lamborghini/ModelLanding/ModelDescription');

var _ModelDescription2 = _interopRequireDefault(_ModelDescription);

var _ModelLandingTypes = require('../../../types/Lamborghini/ModelLandingTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ModelLandingPage = function ModelLandingPage(_ref) {
  var config = _ref.config;
  var translations = config.translations,
      modelLogoUrl = config.modelLogoUrl,
      variants = config.variants;

  var paragraphs = !!config.paragraphKeys && config.paragraphKeys.map(function (p) {
    return translations[p];
  }) || [];
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(_ModelDescription2.default, {
      modelLogo: modelLogoUrl,
      paragraphs: paragraphs,
      subtitle: translations.subtitle,
      modelVariants: variants,
      titleVariants: translations.titleVariants,
      logoAlternativeText: translations.logoModelAlternativeText
    })
  );
};

exports.default = ModelLandingPage;