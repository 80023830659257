'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SeparatedList = require('./SeparatedList');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function VdpHighlightedFeatures(_ref) {
  var features = _ref.features,
      columns = _ref.columns;

  return _react2.default.createElement(
    _SeparatedList.SeparatedListContainer,
    { style: { width: '100%' } },
    _react2.default.createElement(
      _SeparatedList.SeparatedList,
      { style: { columnCount: columns, columnGap: '40px' } },
      features.map(function (f) {
        return _react2.default.createElement(
          _SeparatedList.SeparatedListItem,
          { key: f, style: { lineHeight: '22px' }, flex: true },
          _react2.default.createElement(
            'div',
            { style: { paddingRight: '5px' } },
            '\u2014'
          ),
          _react2.default.createElement(
            'div',
            null,
            f
          )
        );
      })
    )
  );
}

exports.default = VdpHighlightedFeatures;