'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BasicButton = _styledComponents2.default.button.withConfig({
  displayName: 'Button__BasicButton',
  componentId: 'sc-yty8iy-0'
})(['height:45px;width:100%;font-size:13px;border:none;cursor:pointer;text-transform:uppercase;font-family:inherit;font-weight:600;letter-spacing:0.1em;&:focus{outline:none;}', ';'], function (_ref) {
  var withoutChevron = _ref.withoutChevron;
  return withoutChevron || _theme.mixins.arrowComtentTemplate();
});

var TertiaryButton = (0, _styledComponents2.default)(BasicButton).withConfig({
  displayName: 'Button__TertiaryButton',
  componentId: 'sc-yty8iy-1'
})(['color:#000000;background-color:#ffffff;border:1px solid #000000;&:active{color:#ffffff;background-color:#000000;}&:disabled{color:#444444;background-color:#ffffff;}']);

var SecondaryButton = (0, _styledComponents2.default)(BasicButton).withConfig({
  displayName: 'Button__SecondaryButton',
  componentId: 'sc-yty8iy-2'
})(['color:', ';letter-spacing:', ';font-family:', ';background-color:', ';text-transform:', ';font-size:', ';border:', ';&:active{color:#ffffff;background-color:#352c2c;border:none;}&:hover{color:#ffffff;background-color:#352c2c;border:none;}&:disabled{color:#9e1b32;background-color:#ffffff;}'], function (_ref2) {
  var theme = _ref2.theme;
  return theme.colour ? theme.colour : '#9e1b32';
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.characterSpacing ? theme.characterSpacing : '0.1em';
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.fontFamily ? theme.fontFamily : 'inherit';
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.backgroundColour ? theme.backgroundColour : 'transparent';
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.textTransform ? theme.textTransform : 'uppercase';
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.fontSize ? theme.fontSize : '13px';
}, function (_ref8) {
  var borderColour = _ref8.borderColour;
  return '1px solid ' + (borderColour || '#999999');
});

var PrimaryButton = (0, _styledComponents2.default)(BasicButton).withConfig({
  displayName: 'Button__PrimaryButton',
  componentId: 'sc-yty8iy-3'
})(['color:', ';letter-spacing:', ';font-family:', ';background-color:', ';text-transform:', ';font-size:', ';&:active{background-color:#352c2c;}&:hover{background-color:#352c2c;}&:disabled{background-color:#9e1b32;}'], function (_ref9) {
  var theme = _ref9.theme;
  return theme.colour ? theme.colour : '#ffffff';
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.characterSpacing ? theme.characterSpacing : '0.1em';
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.fontFamily ? theme.fontFamily : 'inherit';
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.backgroundColour ? theme.backgroundColour : '#9e1b32';
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.textTransform ? theme.textTransform : 'uppercase';
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.fontSize ? theme.fontSize : '13px';
});

function Button(_ref15) {
  var primary = _ref15.primary,
      secondary = _ref15.secondary,
      tertiary = _ref15.tertiary,
      rest = (0, _objectWithoutProperties3.default)(_ref15, ['primary', 'secondary', 'tertiary']);

  if (primary) return _react2.default.createElement(PrimaryButton, rest);
  if (secondary) return _react2.default.createElement(SecondaryButton, rest);
  if (tertiary) return _react2.default.createElement(TertiaryButton, rest);

  return _react2.default.createElement(BasicButton, rest);
}

Button.defaultProps = {
  theme: {
    fontFamily: 'inherit'
  }
};

exports.default = Button;