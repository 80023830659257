'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var _SectionIcons = require('../../helpers/SectionIcons');

var _SectionIcons2 = _interopRequireDefault(_SectionIcons);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var config = (0, _config.Config)('TextCTA', { id: 'TextCTA' }, (0, _config.Slice)('Layout', { id: 'layout' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.SideCarousel }, (0, _config.Align)('Alignment', 'alignment'), (0, _config.Pixels)('Padding Vertical', 'paddingVertical'), (0, _config.Pixels)('Padding Horizontal', 'paddingHorizontal'))), (0, _config.Slice)('Content', { id: 'content' }, (0, _config.Section)('Text with CTA', { icon: _SectionIcons2.default.TopTier }, (0, _config.TextArea)('Headline', 'headlineText'), (0, _config.TextArea)('Body Text', 'bodyText'), (0, _config.TextArea)('Small Body Text', 'smallBodyText'), (0, _config.Link)('Button', 'buttonText'))), (0, _config.Slice)('Styling', { id: 'styling' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.TopTier }, (0, _config.Colour)('Background Colour', 'backgroundColour'), (0, _config.Font)('Header Text', 'headlineFont'), (0, _config.Font)('Body Text', 'bodyFont'), (0, _config.Font)('Small Body Text', 'smallBodyFont'), (0, _config.Font)('Button Text', 'buttonFont'), (0, _config.Colour)('Button Colour', 'buttonBackgroundColour'), (0, _config.Colour)('Button hover', 'buttonHover'))));

exports.default = config;