'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'LanguagePicklist__Select',
  componentId: 'sc-uztlm3-0'
})(['background-color:transparent;border:none;color:', ';font-family:AstonMartinSans-Light;font-size:14px;outline:none;margin:10px;margin-left:-6px;width:130px;option{color:#000;}'], function (_ref) {
  var enableContrastMode = _ref.enableContrastMode;
  return enableContrastMode ? '#fff' : '#000';
});

function LanguagePicklist(_ref2) {
  var font = _ref2.font,
      value = _ref2.value,
      _onChange = _ref2.onChange,
      options = _ref2.options,
      dataCy = _ref2.dataCy,
      enableContrastMode = _ref2.enableContrastMode;

  return _react2.default.createElement(
    Select,
    {
      'data-cy': dataCy,
      font: font,
      value: value,
      onChange: function onChange(event) {
        return _onChange(event);
      },
      enableContrastMode: enableContrastMode
    },
    options.map(function (lang) {
      return _react2.default.createElement(
        'option',
        { key: (0, _v2.default)(), value: lang.value },
        lang.label
      );
    })
  );
}
exports.default = LanguagePicklist;