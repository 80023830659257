'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Emissions = undefined;

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  margin: 0px 0px 50px;\n'], ['\n  margin: 0px 0px 50px;\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 22px;\n    line-height: 29px; \n'], ['\n    font-size: 22px;\n    line-height: 29px; \n']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n     margin: 0 0 40px;\n  '], ['\n     margin: 0 0 40px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 1fr;\n    width: 100%;\n    margin: 0;\n  '], ['\n    grid-template-columns: 1fr;\n    width: 100%;\n    margin: 0;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    grid-gap: 10px;\n  '], ['\n    grid-gap: 10px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    text-align: center;\n    ', '\n  '], ['\n    text-align: center;\n    ', '\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    row-gap: 20px;\n  '], ['\n    flex-direction: column;\n    row-gap: 20px;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    flex: 1;\n  '], ['\n    flex: 1;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _types = require('../types');

var _vehicleTypes = require('../../../../types/vehicleTypes');

var _vehicleTypes2 = require('../../../../types/RollsRoyce/vehicleTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__Wrapper',
  componentId: 'sc-mgl99h-0'
})(['margin:0px 0px 100px;', ';'], _RollsRoyce2.default.max.small(_templateObject));

var SectionTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'Emissions__SectionTitle',
  componentId: 'sc-mgl99h-1'
})(['color:#151515;font-size:32px;font-weight:300;line-height:41px;letter-spacing:2px;margin:0px;text-transform:uppercase;text-align:center;', ';'], _RollsRoyce2.default.max.small(_templateObject2));

var SectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__SectionWrapper',
  componentId: 'sc-mgl99h-2'
})(['display:flex;flex-direction:column;']);

var SectionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__SectionContainer',
  componentId: 'sc-mgl99h-3'
})(['width:100%;display:flex;gap:56px;padding-top:40px;']);

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__Column',
  componentId: 'sc-mgl99h-4'
})(['display:flex;flex-direction:column;width:', ';'], function (_ref) {
  var width = _ref.width;
  return width || '33.33%';
});

var EmissionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionContainer',
  componentId: 'sc-mgl99h-5'
})(['margin:0 0 12px;break-inside:avoid;&:last-child{margin:0 0 40px;}', ';'], _RollsRoyce2.default.min.large(_templateObject3));

var EmissionsTable = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionsTable',
  componentId: 'sc-mgl99h-6'
})(['display:grid;grid-template-columns:1fr 1fr;grid-gap:56px;', ';', ';'], _RollsRoyce2.default.max.large(_templateObject4), _RollsRoyce2.default.max.medium(_templateObject5));

var InfoTableItem = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__InfoTableItem',
  componentId: 'sc-mgl99h-7'
})(['color:#151515;display:flex;flex-direction:row;justify-content:space-between;align-items:center;border-bottom:1px solid #acacac;width:100%;', ''], _RollsRoyce2.default.max.small(_templateObject6, function (_ref2) {
  var center = _ref2.center;
  return center && 'justify-content: center;';
}));

var EmissionTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionTitle',
  componentId: 'sc-mgl99h-8'
})(['border-bottom:1px solid #3b3b3b;padding-bottom:10px;font-size:16px;font-family:\'RivieraNights-Regular\';font-weight:normal;letter-spacing:0.5px;color:#151515;']);

var EfficiencyTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EfficiencyTitle',
  componentId: 'sc-mgl99h-9'
})(['color:#151515;text-transform:uppercase;padding-bottom:8px;margin:0px;font-size:16px;font-weight:400;line-height:21px;letter-spacing:0.5px;text-align:left;']);

var EmissionsDisclaimerText = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionsDisclaimerText',
  componentId: 'sc-mgl99h-10'
})(['color:#151515;font-size:12px;font-weight:normal;line-height:21px;letter-spacing:0.5px;& a{color:#151515;}']);

var InfoTableLabel = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__InfoTableLabel',
  componentId: 'sc-mgl99h-11'
})(['color:#151515;text-transform:uppercase;font-size:14px;font-weight:400;line-height:18px;letter-spacing:0.5px;text-align:left;margin:12px 10px 10px 10px;']);

var InfoTableValue = (0, _styledComponents2.default)(InfoTableLabel).withConfig({
  displayName: 'Emissions__InfoTableValue',
  componentId: 'sc-mgl99h-12'
})(['font-weight:400;text-align:right;margin:12px 10px 10px 0px;']);

var EnergyEfficiencyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EnergyEfficiencyContainer',
  componentId: 'sc-mgl99h-13'
})(['display:flex;flex-direction:column;row-gap:10px;']);

var EnergyEfficiencyTable = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EnergyEfficiencyTable',
  componentId: 'sc-mgl99h-14'
})(['display:flex;flex-direction:row;gap:56px;', ''], _RollsRoyce2.default.max.medium(_templateObject7));

var EnergyEfficiencyImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EnergyEfficiencyImageContainer',
  componentId: 'sc-mgl99h-15'
})(['flex:0.5;display:flex;flex-direction:column;', ''], _RollsRoyce2.default.max.medium(_templateObject8));

var EnergyEfficiencyText = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EnergyEfficiencyText',
  componentId: 'sc-mgl99h-16'
})(['flex:1;']);

var Emissions = function Emissions(_ref3) {
  var vehicle = _ref3.vehicle,
      translations = _ref3.translations,
      config = _ref3.config;

  var emissionsNull = function emissionsNull(emissions) {
    return (0, _keys2.default)(emissions).every(function (key) {
      return emissions[key] === null;
    });
  };

  var emissionsData = (0, _react.useMemo)(function () {
    var wltpConsumptions = {
      category: 'wltpConsumptions',
      items: [vehicle.consumption.wltpConsumptionLow ? {
        label: 'wltpConsumptionLow',
        value: vehicle.consumption.wltpConsumptionLow + ' l/100km'
      } : null, vehicle.consumption.wltpConsumptionMid ? {
        label: 'wltpConsumptionMid',
        value: vehicle.consumption.wltpConsumptionMid + ' l/100km'
      } : null, vehicle.consumption.wltpConsumptionHigh ? {
        label: 'wltpConsumptionHigh',
        value: vehicle.consumption.wltpConsumptionHigh + ' l/100km'
      } : null, vehicle.consumption.wltpConsumptionExtraHigh ? {
        label: 'wltpConsumptionExtraHigh',
        value: vehicle.consumption.wltpConsumptionExtraHigh + ' l/100km'
      } : null, vehicle.consumption.wltpConsumptionCombined ? {
        label: 'wltpConsumptionCombined',
        value: vehicle.consumption.wltpConsumptionCombined + ' l/100km'
      } : null].filter(function (item) {
        return item !== null;
      })
    };

    var wltpEmissions = {
      category: 'wltpEmissions',
      items: [vehicle.emissions.wltpEmissionsLow ? {
        label: 'wltpConsumptionLow',
        value: vehicle.emissions.wltpEmissionsLow + ' g/km'
      } : null, vehicle.emissions.wltpEmissionsMid ? {
        label: 'wltpConsumptionMid',
        value: vehicle.emissions.wltpEmissionsMid + ' g/km'
      } : null, vehicle.emissions.wltpEmissionsHigh ? {
        label: 'wltpConsumptionHigh',
        value: vehicle.emissions.wltpEmissionsHigh + ' g/km'
      } : null, vehicle.emissions.wltpEmissionsExtraHigh ? {
        label: 'wltpConsumptionExtraHigh',
        value: vehicle.emissions.wltpEmissionsExtraHigh + ' g/km'
      } : null, vehicle.emissions.wltpEmissionsCombined ? {
        label: 'wltpConsumptionCombined',
        value: vehicle.emissions.wltpEmissionsCombined + ' g/km'
      } : null].filter(function (item) {
        return item !== null;
      })
    };

    return [wltpConsumptions, wltpEmissions].filter(function (category) {
      return category.items.some(function (item) {
        return item && item.value !== null;
      });
    });
  }, [vehicle]);

  if (!emissionsNull(vehicle.emissions)) {
    return _react2.default.createElement(
      Wrapper,
      { id: 'Emissions' },
      _react2.default.createElement(
        SectionTitle,
        null,
        translations.titleEmissions
      ),
      _react2.default.createElement(
        SectionContainer,
        null,
        _react2.default.createElement(
          Column,
          { width: '100%' },
          _react2.default.createElement(
            SectionWrapper,
            null,
            _react2.default.createElement(
              EmissionsTable,
              null,
              emissionsData.map(function (emission, idx) {
                return _react2.default.createElement(
                  EmissionContainer,
                  { key: idx },
                  _react2.default.createElement(
                    EmissionTitle,
                    {
                      style: {
                        textTransform: 'capitalize'
                      }
                    },
                    translations[emission.category]
                  ),
                  emission.items.map(function (e, index) {
                    return _react2.default.createElement(
                      InfoTableItem,
                      { key: index },
                      _react2.default.createElement(
                        InfoTableLabel,
                        {
                          style: {
                            textTransform: 'capitalize'
                          }
                        },
                        translations[e.label]
                      ),
                      _react2.default.createElement(
                        InfoTableValue,
                        {
                          style: {
                            textTransform: 'lowercase'
                          }
                        },
                        e.value
                      )
                    );
                  })
                );
              })
            ),
            _react2.default.createElement(
              EnergyEfficiencyContainer,
              null,
              _react2.default.createElement(
                EnergyEfficiencyTable,
                null,
                vehicle.energyEfficiencyRating && config.energyEfficiencyRatingMedia && _react2.default.createElement(
                  EnergyEfficiencyImageContainer,
                  null,
                  _react2.default.createElement(
                    EfficiencyTitle,
                    null,
                    translations.energyEfficiencyClass
                  ),
                  _react2.default.createElement('img', {
                    src: config.energyEfficiencyRatingMedia[vehicle.energyEfficiencyRating],
                    style: {
                      objectFit: 'cover',
                      width: '100%',
                      maxWidth: 450,
                      maxHeight: 300
                    },
                    alt: vehicle.energyEfficiencyRating
                  })
                ),
                translations.disclaimerText && _react2.default.createElement(
                  EnergyEfficiencyText,
                  null,
                  _react2.default.createElement(
                    EfficiencyTitle,
                    null,
                    translations.titleDisclaimer
                  ),
                  _react2.default.createElement(
                    EmissionsDisclaimerText,
                    null,
                    _react2.default.createElement(
                      _reactMarkdown2.default,
                      { linkTarget: '_blank' },
                      translations.disclaimerText
                    )
                  )
                )
              )
            )
          )
        )
      )
    );
  } else {
    return null;
  }
};

exports.Emissions = Emissions;