'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  flex-direction: column;\n'], ['\n  flex-direction: column;\n']);

exports.default = RadioGroup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RadioButtonActive = require('./JaguarIcons/Global/RadioButtonActive');

var _RadioButtonActive2 = _interopRequireDefault(_RadioButtonActive);

var _RadioButtonInactive = require('./JaguarIcons/Global/RadioButtonInactive');

var _RadioButtonInactive2 = _interopRequireDefault(_RadioButtonInactive);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'RadioGroup__Container',
  componentId: 'sc-u9svdb-0'
})(['font-family:', ';display:flex;flex-direction:', ';', ';'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.horizontal ? 'column' : 'row';
}, _theme2.default.max.large(_templateObject));

var Option = _styledComponents2.default.div.withConfig({
  displayName: 'Option'
}).withConfig({
  displayName: 'RadioGroup__Option',
  componentId: 'sc-u9svdb-1'
})(['display:flex;cursor:pointer;align-items:center;padding-top:15px;padding-bottom:15px;width:', ';'], function (props) {
  return props.horizontal ? '' : 'calc(100% / ' + props.options.length + ')';
});

var OptionLabel = _styledComponents2.default.span.withConfig({
  displayName: 'RadioGroup__OptionLabel',
  componentId: 'sc-u9svdb-2'
})(['font-size:', ';padding-left:16px;'], function (props) {
  return props.theme.fontSizeContent;
});

var Radio = function Radio(_ref) {
  var on = _ref.on,
      globalStyling = _ref.globalStyling;
  return on ? _react2.default.createElement(_RadioButtonActive2.default, {
    colour: globalStyling.colours && globalStyling.colours.primaryBrandColour.value,
    width: '1.5em',
    height: '1.5em'
  }) : _react2.default.createElement(_RadioButtonInactive2.default, { colour: '#FFFFFF', width: '1.5em', height: '1.5em' });
};

function RadioGroup(_ref2) {
  var options = _ref2.options,
      getLabelFromOption = _ref2.getLabelFromOption,
      getValueFromOption = _ref2.getValueFromOption,
      onChange = _ref2.onChange,
      value = _ref2.value,
      horizontal = _ref2.horizontal,
      globalStyling = _ref2.globalStyling;

  return _react2.default.createElement(
    Container,
    { horizontal: horizontal },
    options.map(function (o) {
      return _react2.default.createElement(
        Option,
        {
          key: getValueFromOption(o),
          onClick: function onClick() {
            return onChange(o);
          },
          horizontal: horizontal,
          options: options
        },
        _react2.default.createElement(Radio, {
          on: !!value && getValueFromOption(o) === getValueFromOption(value),
          globalStyling: globalStyling
        }),
        _react2.default.createElement(
          OptionLabel,
          null,
          getLabelFromOption(o)
        )
      );
    })
  );
}

RadioGroup.defaultProps = {
  getLabelFromOption: function getLabelFromOption(opt) {
    return opt.label;
  },
  getValueFromOption: function getValueFromOption(opt) {
    return opt.value;
  },
  value: null,
  horizontal: false
};