'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Heart;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Heart(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      {
        'data-ui-element': 'shortlist',
        width: '1em',
        height: '1em',
        viewBox: '0 0 29 25'
      },
      _react2.default.createElement('path', { d: 'M14 21.416l-1.4-1.4c-5.2-4.6-8.6-7.7-8.6-11.5 0-3.1 2.4-5.5 5.5-5.5 1.7 0 3.4.8 4.5 2.1 1.1-1.3 2.8-2.1 4.5-2.1 3.1 0 5.5 2.4 5.5 5.5 0 3.8-3.4 6.9-8.6 11.5l-1.4 1.4z' })
    )
  );
}

Heart.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};