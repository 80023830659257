'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  ', '\n  margin-bottom: 0;\n  '], ['\n  ', '\n  margin-bottom: 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);
/* eslint-disable react/jsx-indent, no-return-assign */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHelmet = require('react-helmet');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _router = require('../../../actions/router');

var _VdpGallery = require('../../../components/Bentley/VdpGallery');

var _VdpGallery2 = _interopRequireDefault(_VdpGallery);

var _compare = require('../../../shared/compare');

var _filters = require('../../../shared/filters');

var _financeCalculator = require('../../../shared/financeCalculator');

var _printing = require('../../../shared/printing');

var _searchFilter = require('../../../shared/searchFilter');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _shortlist = require('../../../shared/shortlist');

var _vehicleValuations = require('../../../shared/vehicleValuations');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _converters = require('../../../helpers/converters');

var _converters2 = _interopRequireDefault(_converters);

var _vehicle = require('../../../helpers/vehicle');

var _pathBuilders = require('../../../path-builders');

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _VdpRetailerMap = require('../../../components/Bentley/VdpRetailerMap');

var _VdpRetailerMap2 = _interopRequireDefault(_VdpRetailerMap);

var _VehicleDetails = require('../../../components/Bentley/VehicleDetails');

var _VehicleDetails2 = _interopRequireDefault(_VehicleDetails);

var _vehicleStore = require('../../../shared/selectors/vehicleStore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var themeFromProps = function themeFromProps(_ref) {
  var typeface = _ref.typeface,
      fontSize = _ref.fontSize,
      kerning = _ref.kerning,
      transform = _ref.transform,
      colour = _ref.colour;
  return {
    fontFamily: typeface ? typeface.label : '',
    fontSize: fontSize ? fontSize + 'px' : '',
    characterSpacing: kerning ? kerning + 'px' : '',
    textTransform: transform || '',
    colour: colour ? colour.value : ''
  };
};

var GalleryWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VDP__GalleryWrapper',
  componentId: 'sc-14usrbm-0'
})(['margin-bottom:18px;', ';'], _theme2.default.max.medium(_templateObject, function (_ref2) {
  var removeBorders = _ref2.removeBorders;
  return removeBorders && 'padding: 0;';
}));

var VdpContentWrapper = (0, _styledComponents2.default)(_ContentWrapper2.default).withConfig({
  displayName: 'VDP__VdpContentWrapper',
  componentId: 'sc-14usrbm-1'
})(['display:block;', ';'], _theme2.default.max.large(_templateObject2));

var VDP = function (_Component) {
  (0, _inherits3.default)(VDP, _Component);

  function VDP() {
    var _ref3;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, VDP);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref3 = VDP.__proto__ || (0, _getPrototypeOf2.default)(VDP)).call.apply(_ref3, [this].concat(args))), _this), Object.defineProperty(_this, 'state', {
      enumerable: true,
      writable: true,
      value: {
        showStickyBanner: false,
        financeInformationForEnquiry: {
          deposit: null,
          payment: null,
          mileage: null,
          term: null,
          financeType: null
        }
      }
    }), Object.defineProperty(_this, 'componentDidUpdate', {
      enumerable: true,
      writable: true,
      value: function value(prevProps) {
        var _this$props = _this.props,
            state = _this$props.state,
            shared = _this$props.shared,
            actions = _this$props.actions,
            dispatch = _this$props.dispatch,
            router = _this$props.router,
            _this$props$history$l = _this$props.history.location,
            pathname = _this$props$history$l.pathname,
            hash = _this$props$history$l.hash,
            _this$props$history$l2 = _this$props$history$l.state,
            payload = _this$props$history$l2 === undefined ? { finance: null } : _this$props$history$l2,
            globalFinance = _this$props.finance,
            context = _this$props.state.context,
            hashRouting = _this$props.hashRouting;


        if (prevProps.shared.currencyConversion.exchangeRates !== shared.currencyConversion.exchangeRates) {
          dispatch(actions.updateVehiclePrices({
            vehicle: state.vdpConfig,
            similarVehicles: state.similarVehicles
          }));
        }

        if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language || prevProps.router.navigationHistory[0] !== router.navigationHistory[0]) {
          var vehicleId = (hashRouting ? hash : pathname).match(/(\/)([A-Za-z0-9]*)(-)/)[2];
          var _finance = payload.finance;

          var financeProductToUse = context || globalFinance && globalFinance.defaultProduct;
          actions.getVehicleInfo({
            vehicleId: vehicleId,
            finance: _finance,
            financeProductToUse: financeProductToUse
          });
        }
      }
    }), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props2 = _this.props,
            _this$props2$history$ = _this$props2.history.location,
            pathname = _this$props2$history$.pathname,
            hash = _this$props2$history$.hash,
            _this$props2$history$2 = _this$props2$history$.state,
            payload = _this$props2$history$2 === undefined ? { finance: null } : _this$props2$history$2,
            actions = _this$props2.actions,
            dispatch = _this$props2.dispatch,
            featureFlags = _this$props2.featureFlags,
            globalFinance = _this$props2.finance,
            context = _this$props2.state.context,
            config = _this$props2.config,
            financeState = _this$props2.finance,
            hashRouting = _this$props2.hashRouting;


        if (featureFlags.finance) {
          dispatch(_financeCalculator.actions.resetFinanceProduct({
            type: financeState.defaultProduct
          }));
        }

        var vehicleId = (hashRouting ? hash : pathname).match(/(\/)([A-Za-z0-9]*)(-)/)[2];
        var finance = payload.finance;

        var financeProductToUse = context || globalFinance && globalFinance.defaultProduct;
        actions.getVehicleInfo({
          vehicleId: vehicleId,
          finance: finance,
          financeProductToUse: financeProductToUse
        });
        dispatch(_shortlist.actions.getVehicles());
        if (!config.hideSimilarVehicle) {
          actions.getSimilarVehicles({ vehicleId: vehicleId, financeProductToUse: financeProductToUse });
        }
        if (featureFlags.finance) {
          dispatch(_financeCalculator.actions.loadFinanceOptions(vehicleId));
        }
      }
    }), Object.defineProperty(_this, 'isDealerWebsite', {
      enumerable: true,
      writable: true,
      value: function value() {
        var navigationHistory = _this.props.router.navigationHistory;

        if (!navigationHistory) {
          return false;
        }
        return navigationHistory.length >= 2 && navigationHistory[1].includes('dealer_');
      }
    }), Object.defineProperty(_this, 'toggleStickyBanner', {
      enumerable: true,
      writable: true,
      value: function value(showStickyBanner) {
        return _this.setState(function () {
          return { showStickyBanner: showStickyBanner };
        });
      }
    }), Object.defineProperty(_this, 'termsRange', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props3 = _this.props,
            config = _this$props3.config,
            shared = _this$props3.shared,
            _this$props3$state = _this$props3.state,
            vdpConfig = _this$props3$state.vdpConfig,
            context = _this$props3$state.context,
            finance = _this$props3.finance;

        var marketFinanceOptions = shared.financeCalculator.financeOptions[context || finance && finance.defaultProduct];
        if (marketFinanceOptions) {
          var sortedOptions = (marketFinanceOptions.durationOptions || []).sort(function (a, b) {
            return a - b;
          });
          var exampleDefaultTerm = shared.financeCalculator.financeProductExample[context] && shared.financeCalculator.financeProductExample[context].duration;
          var defaultTerm = (vdpConfig && vdpConfig.finance && vdpConfig || { finance: {} }).finance.duration || sortedOptions[0];
          return {
            id: 'termsRange',
            name: 'term',
            title: config.translations.term,
            unit: config.translations.months,
            step: 0,
            marks: sortedOptions.reduce(function (ac, cv) {
              return (0, _extends4.default)({}, ac, (0, _defineProperty3.default)({}, cv, '' + cv));
            }, {}),
            defaultValue: exampleDefaultTerm || defaultTerm,
            fullRangeValues: {
              min: sortedOptions[0],
              max: sortedOptions[sortedOptions.length - 1]
            }
          };
        }
        return {};
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(VDP, [{
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      var _props = this.props,
          dispatch = _props.dispatch,
          history = _props.history;

      var searchResultsMatcher = /.*\/searchresults\/.*/i;
      var goingToSrp = searchResultsMatcher.test(history.location.pathname);
      if (!goingToSrp) {
        dispatch(_sessionPreferences.actions.updateSessionPreferences('infiniteScroll', false));
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          _props2$state = _props2.state,
          vdpConfig = _props2$state.vdpConfig,
          placeholdingImage = _props2$state.placeholdingImage,
          context = _props2$state.context,
          printing = _props2$state.printing,
          _props2$actions = _props2.actions,
          updateMyDealProduct = _props2$actions.updateMyDealProduct,
          updateMyDealWithPartExchange = _props2$actions.updateMyDealWithPartExchange,
          saveMyDeal = _props2$actions.saveMyDeal,
          printMyDeal = _props2$actions.printMyDeal,
          changeFinanceProduct = _props2$actions.changeFinanceProduct,
          dispatch = _props2.dispatch,
          shared = _props2.shared,
          pathByModule = _props2.pathByModule,
          preview = _props2.preview,
          config = _props2.config,
          featureFlags = _props2.featureFlags,
          globalStyling = _props2.globalStyling,
          marketInfo = _props2.marketInfo,
          globalFinance = _props2.finance,
          socialShare = _props2.socialShare,
          googleAvailable = _props2.shared.google.googleAvailable;


      var sharedShortListedVehicles = (0, _vehicleStore.getShortList)(featureFlags)(shared);
      var sharedCompareVehicles = (0, _vehicleStore.getCompareList)(featureFlags)(shared);

      var defaultProductFinanceExample = preview ? {} : shared.financeCalculator.financeProductExample[context || globalFinance && globalFinance.defaultProduct];
      var isListed = function isListed(id, vehicles) {
        return vehicles.find(function (vehicle) {
          return vehicle.id === (id || {});
        });
      };
      var shortListedIconColour = isListed((vdpConfig || {}).id, sharedShortListedVehicles) ? config.iconColour && config.iconColour.value : '#ACACAC';
      var compareIconColour = isListed((vdpConfig || {}).id, sharedCompareVehicles) ? config.iconColour && config.iconColour.value : '#ACACAC';
      var vehicleDetails = vdpConfig;
      var brandLogoUrl = config.brandLogoUrl,
          pdfLineColor = config.pdfLineColor,
          translations = config.translations;

      var pdfConfig = { brandLogoUrl: brandLogoUrl, pdfLineColor: pdfLineColor, translations: translations };
      var language = marketInfo.locale;

      var goToModule = function goToModule(name, retailer) {
        var path = (0, _pathBuilders.pathWithDealerID)(pathByModule(name), retailer.name, config.specialDealerRedirection ? retailer.oemId : retailer.id, language, config.specialDealerRedirection && featureFlags.dealerUrl);

        window.open(window.location.origin + '/' + path, config.newTab);
      };

      var goToModuleWithGroup = function goToModuleWithGroup(name, retailer) {
        return dispatch(_router.actions.navigate((0, _pathBuilders.pathWithDealerGroup)(pathByModule(name), retailer)));
      };
      var _bookTestDrive = function _bookTestDrive(name, id) {
        return dispatch(_router.actions.navigate(pathByModule(name) + '/' + id));
      };
      var resetFilters = function resetFilters() {
        _this2.props.dispatch(_sessionPreferences.actions.updateSessionPreferences('resetSearchResultsPageNumber', true));
        _this2.props.dispatch(_searchFilter.actions.resetFilters());
        _this2.props.dispatch(_filters.actions.resetFilters());
      };
      var buttonBGColour = config.buttonPrimaryBGColour && config.buttonPrimaryBGColour.value;
      var buttonSecondaryBorderColour = config.buttonSecondaryBorderColour && config.buttonSecondaryBorderColour.value;
      var iconColour = config.iconColour && config.iconColour.value;
      var buttonTheme = (0, _extends4.default)({}, themeFromProps(config.buttonPrimaryFont || {}), {
        backgroundColour: buttonBGColour
      });
      var secondaryButtonTheme = (0, _extends4.default)({}, themeFromProps(config.buttonSecondaryFont || {}), {
        borderColour: buttonSecondaryBorderColour
      });
      var financeProducts = globalFinance && globalFinance.productTypes.map(function (pt) {
        return {
          text: globalFinance.translations['finance' + pt + 'Label'], // config.translations.pcpLabel,
          onClick: function onClick() {
            return changeFinanceProduct({
              vehicleId: vdpConfig.id,
              productType: pt
            });
          },
          path: pt
        };
      });

      var printVehiclePdf = function printVehiclePdf() {
        return dispatch(_printing.actions.printVehicleInfo(vehicleDetails, translations, config));
      };
      var printingState = shared.printing;

      var getVehicleTitle = function getVehicleTitle() {
        if (translations.customSeoTitle) {
          return (0, _vehicle.translateTemplateWithVehicleData)('customSeoTitle', config.customSeoProps, vehicleDetails, translations);
        }
        return config.hideYear ? vehicleDetails.description : vehicleDetails.registration + ' ' + vehicleDetails.description;
      };

      var getVehicleDescription = function getVehicleDescription() {
        if (translations.customSeoDescription) {
          return (0, _vehicle.translateTemplateWithVehicleData)('customSeoDescription', config.customSeoProps, vehicleDetails, translations);
        }
        return '';
      };

      var showPromotion = function showPromotion() {
        if (!config.promotion) return false;

        var _config$promotion = config.promotion,
            countryFilter = _config$promotion.countryFilter,
            modelGroupFilter = _config$promotion.modelGroupFilter;


        return countryFilter.includes(vehicleDetails.retailerInformation.countryCode) && modelGroupFilter.includes(vehicleDetails.modelGroupName);
      };

      // Convert finance information into querystring parameters.
      var navigateToEnquiryForm = function navigateToEnquiryForm() {
        var parameters = null;

        if (showPromotion()) {
          parameters = new URLSearchParams({
            campaignCode: config.promotion.campaignCode
          }).toString();
        }

        dispatch(_router.actions.navigate('/enquiry/' + vehicleDetails.id + (parameters ? '?' + parameters : '')));
      };

      var getExternalFormURL = function getExternalFormURL(redirect, formParams) {
        /* eslint-disable no-param-reassign */
        (0, _keys2.default)(formParams).forEach(function (key) {
          if (formParams[key] === 'modelGroupName') {
            formParams[key] = vehicleDetails.modelGroupName;
          }
          if (formParams[key] === 'registrationPlate') {
            formParams[key] = vehicleDetails.registrationPlate;
          }
          if (formParams[key] === 'dealer_name') {
            formParams[key] = vehicleDetails.retailerInformation.name;
          }
          if (formParams[key] === 'dealer_id_external') {
            formParams[key] = vehicleDetails.retailerInformation.kiaExternalDealerId;
          }
          if (formParams[key] === 'dealer_id_internal') {
            formParams[key] = vehicleDetails.retailerInformation.kiaInternalDealerId;
          }
        });
        /* eslint-enable no-param-reassign */

        var urlParams = (0, _keys2.default)(formParams).map(function (key) {
          return key + '=' + formParams[key];
        }).join('&');

        window.open('' + redirect + urlParams, '_blank');
      };

      return vehicleDetails ? _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          _reactHelmet.Helmet,
          null,
          _react2.default.createElement(
            'title',
            null,
            getVehicleTitle()
          ),
          _react2.default.createElement('meta', { name: 'description', content: getVehicleDescription() })
        ),
        _react2.default.createElement(
          GalleryWrapper,
          { removeBorders: config.showGalleryAndHighlights },
          _react2.default.createElement(
            _ContentWrapper2.default,
            { contentWidth: globalStyling.contentWidth },
            _react2.default.createElement(_VdpGallery2.default, {
              globalStyling: globalStyling,
              featureFlags: featureFlags,
              shortListedIconColour: shortListedIconColour,
              compareIconColour: compareIconColour,
              vehicleImages: {
                slides: vehicleDetails.images.map(function (i) {
                  return {
                    id: i,
                    image: i
                  };
                })
              },
              rotateGallery: config.rotateGallery,
              placeholdingImage: placeholdingImage,
              vehicleInfo: vehicleDetails,
              translations: config.translations,
              showShareButton: config.showShareButton,
              compareVehicle: function compareVehicle(vehicle) {
                return dispatch(_compare.actions.toggleVehicle((0, _converters2.default)(vehicle)));
              },
              shortlistVehicle: function shortlistVehicle(vehicle) {
                return dispatch(_shortlist.actions.toggleVehicle((0, _converters2.default)(vehicle)));
              },
              bookTestDrive: function bookTestDrive() {
                return _bookTestDrive('TestDrive', vehicleDetails.id);
              },
              reserveThisVehicle: function reserveThisVehicle() {
                return _bookTestDrive('TestDrive', vehicleDetails.id);
              },
              makeAnEnquiry: function makeAnEnquiry() {
                navigateToEnquiryForm();
              },
              finance: vehicleDetails.finance,
              updateMyDeal: updateMyDealProduct,
              updateMyDealWithPartExchange: updateMyDealWithPartExchange,
              saveMyDeal: function (_saveMyDeal) {
                function saveMyDeal() {
                  return _saveMyDeal.apply(this, arguments);
                }

                saveMyDeal.toString = function () {
                  return _saveMyDeal.toString();
                };

                return saveMyDeal;
              }(function () {
                return saveMyDeal({
                  vehicleDetails: vehicleDetails,
                  vdpConfig: config
                });
              }),
              termsRange: this.termsRange(),
              financeProductExample: defaultProductFinanceExample,
              showStickyBanner: this.state.showStickyBanner,
              toggleStickyBanner: this.toggleStickyBanner,
              buttonTheme: buttonTheme,
              buttonSecondaryTheme: secondaryButtonTheme,
              iconColour: iconColour,
              printMyDeal: function (_printMyDeal) {
                function printMyDeal(_x) {
                  return _printMyDeal.apply(this, arguments);
                }

                printMyDeal.toString = function () {
                  return _printMyDeal.toString();
                };

                return printMyDeal;
              }(function (finance) {
                return printMyDeal({
                  vehicle: vehicleDetails,
                  translations: globalFinance && globalFinance.translations,
                  finance: finance,
                  pdfConfig: pdfConfig
                });
              }),
              getValuationMakes: function getValuationMakes() {
                return dispatch(_vehicleValuations.actions.loadVehicleMakes());
              },
              valuationMakes: shared.vehicleValuations.makes || [],
              getValuationModels: function getValuationModels(makeId) {
                return dispatch(_vehicleValuations.actions.loadVehicleModels(makeId));
              },
              valuationModels: shared.vehicleValuations.models || [],
              getValuationVersions: function getValuationVersions(makeId, modelId) {
                return dispatch(_vehicleValuations.actions.loadVehicleVersions(makeId, modelId));
              },
              valuationVersions: shared.vehicleValuations.versions || [],
              getValuationYears: function getValuationYears(makeId, modelId, versionId) {
                dispatch(_vehicleValuations.actions.loadVehicleRegistrationYears(makeId, modelId, versionId));
              },
              valuationYears: shared.vehicleValuations.years || [],
              getValuationMonths: function getValuationMonths(makeId, modelId, year) {
                dispatch(_vehicleValuations.actions.loadVehicleRegistrationMonths(makeId, modelId, year));
              },
              valuationMonths: shared.vehicleValuations.months || [],
              vehicleValuation: shared.vehicleValuations.valuation,
              vehicleValuationRequestLimit: shared.vehicleValuations.valuationRequestLimit,
              editVehicleValuation: function editVehicleValuation(vehicleId) {
                return dispatch(_vehicleValuations.actions.editVehicleValuation(vehicleId));
              },
              cancelEditVehicleValuation: function cancelEditVehicleValuation() {
                return dispatch(_vehicleValuations.actions.cancelEditVehicleValuation());
              },
              valuationError: shared.vehicleValuations.networkError,
              showDeal: config.showDeal,
              showContactbuttons: config.showContactbuttons,
              onDemandBtn: config.onDemandBtn,
              showOnDemand: config.showOnDemand,
              makeATestDriveEnquiry: function makeATestDriveEnquiry() {
                return dispatch(_router.actions.navigate((config.testdriveCtaPath || 'enquiry') + '/' + vehicleDetails.id));
              },
              makeAnEnquiryExternal: function makeAnEnquiryExternal() {
                getExternalFormURL(config.enquiryFormRedirect, config.externalFormParams);
              },
              makeAReserveEnquiryExternal: function makeAReserveEnquiryExternal() {
                getExternalFormURL(config.reserveFormRedirect, config.externalFormParams);
              },
              makeATestDriveEnquiryExternal: function makeATestDriveEnquiryExternal() {
                getExternalFormURL(config.testDriveFormRedirect, config.externalFormParams);
              },
              makeAnOnDemandEnquiry: function makeAnOnDemandEnquiry() {
                return dispatch(_router.actions.navigate(config.onDemandCtaPath + '/' + vehicleDetails.id));
              },
              makeAReserveEnquiry: function makeAReserveEnquiry() {
                return dispatch(_router.actions.navigate((config.reserveCtaPath || 'enquiry') + '/' + vehicleDetails.id));
              },
              subHeaderType: config.subHeaderType,
              vehicleGalleryArrowFont: config.vehicleGalleryArrowFont,
              vehicleHeadingFont: config.vehicleNameHeadingFont,
              vehicleHeadingColour: config.vehicleNameHeadingColour,
              vehicleHeadingPadding: config.vehicleNameHeadingPadding,
              vehicleHeadingMargin: config.vehicleNameHeadingMargin,
              heartMobileNoPadding: config.heartMobileNoPadding,
              myDealButtonContainerStyles: config.myDealButtonContainerStyles = {},
              locale: marketInfo.locale,
              financeProducts: financeProducts,
              resetFinanceProduct: function resetFinanceProduct() {
                return dispatch(_financeCalculator.actions.resetFinanceProduct({
                  type: globalFinance && globalFinance.defaultProduct
                }));
              },
              context: context || globalFinance && globalFinance.defaultProduct,
              financeTranslations: globalFinance && globalFinance.translations,
              financeHeadingDisplay: globalFinance && globalFinance.financeHeadingDisplay,
              printing: printing,
              showFinanceDescriptions: globalFinance && globalFinance.showProductDescriptions,
              financePricingTable: config.financePricingTable,
              featuredSpec: config.featuredSpec,
              hideActionButtons: config.hideActionButtons,
              approvedImage: config.pricingSectionImage,
              galleryWidth: config.galleryWidth,
              bottomTextFont: config.bottomTextFont,
              socialShare: socialShare,
              stickyBannerStyling: config.stickyBannerStyling,
              config: config,
              badgeWithStripUrl: config.badgeWithStripUrl,
              badgeWithoutStripUrl: config.badgeWithoutStripUrl,
              warrantyBadgeMonths: config.warrantyBadgeMonths,
              warrantyBadgeMaxMileage: config.warrantyBadgeMaxMileage,
              hideYear: config.hideYear,
              featureSpecificationData: config.featureSpecificationData,
              imageCarousel: config.imageCarousel
            })
          )
        ),
        _react2.default.createElement(_VehicleDetails2.default, {
          vehicle: vehicleDetails,
          config: config,
          translations: config.translations,
          locale: marketInfo.locale,
          printVehiclePdf: printVehiclePdf,
          printingState: printingState,
          placeholdingImage: placeholdingImage,
          makeAnEnquiry: function makeAnEnquiry(e) {
            e.preventDefault();
            navigateToEnquiryForm();
          },
          bookATestDrive: function bookATestDrive(e) {
            e.preventDefault();
            dispatch(_router.actions.navigate('/testdrive/' + vehicleDetails.id));
          },
          reserveCar: function reserveCar(e) {
            e.preventDefault();
            dispatch(_router.actions.navigate('/reserve/' + vehicleDetails.id));
          },
          globalStyling: globalStyling,
          showPromotion: showPromotion()
        }),
        _react2.default.createElement(
          VdpContentWrapper,
          {
            contentWidth: globalStyling.contentWidth,
            id: 'retailer-map'
          },
          _react2.default.createElement(_VdpRetailerMap2.default, {
            buttonTheme: buttonTheme,
            buttonBGColour: buttonBGColour,
            retailer: vehicleDetails.retailerInformation,
            translations: config.translations,
            viewRetailerStock: function viewRetailerStock(retailer) {
              resetFilters();
              goToModule('BentleyVehicleSearchResults', retailer);
            },
            viewGroupStock: function viewGroupStock(retailer) {
              resetFilters();
              goToModuleWithGroup('BentleyVehicleSearchResults', retailer);
            },
            makeAnEnquiry: function makeAnEnquiry() {
              navigateToEnquiryForm();
            },
            globalStyling: globalStyling,
            config: config.retailerMapDetails,
            getDirectionsToRetailer: function getDirectionsToRetailer(url) {
              return dispatch(_router.actions.navigate(url));
            },
            locale: marketInfo.locale,
            googleAvailable: googleAvailable,
            hideContactButton: config.hideContactButton,
            vehicleInfo: vehicleDetails,
            isDWS: this.isDealerWebsite()
          })
        )
      ) : null;
    }
  }]);
  return VDP;
}(_react.Component);

exports.default = VDP;