'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n   width: 100%;\n'], ['\n   width: 100%;\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    height: 390px;\n    width: 100%;\n'], ['\n    height: 390px;\n    width: 100%;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../../dist/hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _RetailerInformation = require('../../../modules/RetailerInformation/RetailerInformation');

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__Wrapper',
  componentId: 'sc-1vmhb3f-0'
})(['position:relative;height:597px;width:100%;margin:0 auto;', ';', ';'], _RollsRoyce2.default.max.medium(_templateObject), _RollsRoyce2.default.max.small(_templateObject2));

var VdpRetailerMap = function VdpRetailerMap(_ref) {
  var googleAvailable = _ref.googleAvailable,
      config = _ref.config,
      mapKey = _ref.mapKey,
      retailer = _ref.retailer;
  var _config$hideGoogleMap = config.hideGoogleMap,
      hideGoogleMap = _config$hideGoogleMap === undefined ? null : _config$hideGoogleMap;
  var _retailer$name = retailer.name,
      retailerName = _retailer$name === undefined ? '' : _retailer$name,
      _retailer$address = retailer.address,
      address = _retailer$address === undefined ? [] : _retailer$address;

  var _address = (0, _slicedToArray3.default)(address, 3),
      _address$ = _address[0],
      street = _address$ === undefined ? '' : _address$,
      _address$2 = _address[1],
      town = _address$2 === undefined ? '' : _address$2,
      _address$3 = _address[2],
      postcode = _address$3 === undefined ? '' : _address$3;

  var place = [retailerName, street, town, postcode].reduce(function (prev, next) {
    if (next.length === 0) {
      return prev;
    }
    return prev + encodeURIComponent(String(next) + '+');
  }, '');

  var isMobile = (0, _useCheckIsMobileScreen2.default)();

  return _react2.default.createElement(
    'div',
    null,
    !hideGoogleMap && googleAvailable && mapKey && _react2.default.createElement(
      Wrapper,
      { id: 'retailerMap' },
      _react2.default.createElement('iframe', {
        src: 'https://www.google.com/maps/embed/v1/place?key=' + mapKey + '&q=place:' + place + '}',
        width: '100%',
        height: isMobile ? '390px' : '597px',
        style: { border: 0 },
        'aria-hidden': 'false',
        tabIndex: 0,
        allowFullScreen: false
      })
    )
  );
};

exports.default = VdpRetailerMap;