'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _styledComponents2.default.div.withConfig({
  displayName: 'FieldError',
  componentId: 'sc-al4j17-0'
})(['color:#bf0c0c;text-align:left;']);