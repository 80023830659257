'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _settings = require('../../../shared/selectors/settings');

var _styledComponentFactory = require('../../Global/styledComponentFactory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Base styles for buttons
 */
var BaseButton = (0, _styledComponentFactory.createElement)('button');
var ButtonBaseStyle = (0, _styledComponents2.default)(BaseButton).withConfig({
  displayName: 'SecondaryButton__ButtonBaseStyle',
  componentId: 'sc-11oix5p-0'
})(['border:1px solid #ffffff !important;background-color:transparent;font-family:', ';color:#ffffff;padding:0 20px;height:40px;display:flex;align-items:center;text-transform:uppercase;&:hover{border:2px solid #fff;color:#fff;background:rgba(255,255,255,0.2);}'], function (_ref) {
  var fonts = _ref.fonts;
  return fonts.secondaryButtonFont && fonts.secondaryButtonFont.value;
});

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'SecondaryButton__Label',
  componentId: 'sc-11oix5p-1'
})(['font-size:14px;letter-spacing:1.2px;text-transform:uppercase;line-height:14px;']);

/**
 * Secondary button styles
 */
var Button = (0, _styledComponents2.default)(ButtonBaseStyle).withConfig({
  displayName: 'SecondaryButton__Button',
  componentId: 'sc-11oix5p-2'
})(['']);

/**
 * @deprecated Please use Form/Button.tsx with variant.
 */
var SecondaryButton = function SecondaryButton(props) {
  var onClick = props.onClick,
      text = props.text,
      styleOverride = props.styleOverride,
      mobileStyleOverride = props.mobileStyleOverride,
      rest = (0, _objectWithoutProperties3.default)(props, ['onClick', 'text', 'styleOverride', 'mobileStyleOverride']);

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts,
      colours = _useSelector.colours;

  return _react2.default.createElement(
    Button,
    (0, _extends3.default)({
      applyStyle: 'secondary',
      onClick: onClick,
      fonts: fonts,
      colours: colours,
      styleOverride: styleOverride,
      mobileStyleOverride: mobileStyleOverride ? (0, _styledComponentFactory.mediaProps)(mobileStyleOverride()) : undefined
    }, rest),
    _react2.default.createElement(
      Label,
      null,
      text
    )
  );
};

exports.default = SecondaryButton;