'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reorderFinanceDisplayList = exports.getFinanceHeaderDisplayList = exports.getFinanceDisplayList = undefined;

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _numbers = require('../localisation/numbers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getCurrency = function getCurrency(data) {
  return data && data.currency || 'EUR';
};

var getFinanceDisplayList = exports.getFinanceDisplayList = function getFinanceDisplayList(data, globalFinance) {
  var _globalFinance$transl = globalFinance.translations,
      translations = _globalFinance$transl === undefined ? {} : _globalFinance$transl,
      locale = globalFinance.locale;

  var financeExampleDisplay = globalFinance.financeExampleDisplay[data.financingType];

  if (!financeExampleDisplay) {
    financeExampleDisplay = globalFinance.financeExampleDisplay[globalFinance.defaultProduct];
  }

  var currency = getCurrency(data);
  var transformExpenses = function transformExpenses() {
    var objectExpenses = [];
    if (data && data.expenses.length > 0) {
      objectExpenses = data.expenses.map(function (_ref) {
        var name = _ref.name,
            value = _ref.value;
        return {
          key: 'expenses',
          label: name,
          value: (0, _numbers.localiseCurrency)(parseFloat(value.replace('.', '').replace(',', '.')), locale, currency)
        };
      });
    }
    if (data && data.additionalServices.length > 0) {
      var additional = data.additionalServices.filter(function (o) {
        return o.value !== '';
      }).map(function (_ref2) {
        var name = _ref2.name,
            value = _ref2.value;
        return {
          key: 'services',
          label: name,
          value: (0, _numbers.localiseCurrency)(parseFloat(value.replace('.', '').replace(',', '.')), locale, currency)
        };
      });

      var additionalHeader = globalFinance.showServicesTitle && additional.length > 0 && {
        key: 'services',
        label: translations.optionalServicesTitle
      };

      objectExpenses = [additionalHeader].concat((0, _toConsumableArray3.default)(additional), (0, _toConsumableArray3.default)(objectExpenses)).filter(Boolean);
    }
    return objectExpenses;
  };

  return data ? [{
    key: 'monthlyPrice',
    label: translations.financeMonthlyPayment,
    value: data.monthlyPrice && (0, _numbers.localiseCurrency)(parseFloat(data.monthlyPrice.replace('.', '').replace(',', '.')), locale, currency)
  }, {
    key: 'numberOfPayments',
    label: translations.financeNumberOfPayments,
    value: data.numberOfPayments
  }, {
    key: 'totalCost',
    label: translations.financeTotalCost,
    value: data.totalPayable && (0, _numbers.localiseCurrency)(parseFloat(data.totalPayable.replace('.', '').replace(',', '.')), locale, currency)
  }, {
    key: 'deposit',
    label: translations.financeDeposit,
    value: data.downpayment && (0, _numbers.localiseCurrency)(data.downpayment, locale, currency)
  }, {
    key: 'apr',
    label: translations.financeAPR,
    value: data.apr && data.apr + ' %'
  }, {
    key: 'vehiclePrice',
    label: translations.financeVehiclePrice,
    value: data.vehiclePrice && (0, _numbers.localiseCurrency)(data.vehiclePrice, locale, currency)
  }, {
    key: 'interestRate',
    label: translations.financeInterestRate,
    value: data.interestRate && data.interestRate + ' %'
  }, {
    key: 'duration',
    label: translations.financeDurationOfAgreement,
    value: data.duration
  }, {
    key: 'totalAmount',
    label: translations.financeTotalAmount,
    value: data.totalAmount && (0, _numbers.localiseCurrency)(parseFloat(data.totalAmount.replace('.', '').replace(',', '.')), locale, currency)
  }, {
    key: 'totalAmountOwed',
    label: translations.financeTotalAmountOwed,
    value: data.totalAmountOwed && (0, _numbers.localiseCurrency)(parseFloat(data.totalAmountOwed.replace('.', '').replace(',', '.')), locale, currency)
  }, {
    key: 'totalCredit',
    label: translations.financeTotalCredit,
    value: data.totalCredit && (0, _numbers.localiseCurrency)(parseFloat(data.totalCredit.replace('.', '').replace(',', '.')), locale, currency)
  }, {
    key: 'financedAmount',
    label: translations.financedAmount,
    value: data.financedAmount && (0, _numbers.localiseCurrency)(parseFloat(data.financedAmount.replace('.', '').replace(',', '.')), locale, currency)
  }, {
    key: 'totalInstallments',
    label: translations.financeTotalInstalments,
    value: data.totalInstallmentsAmount && (0, _numbers.localiseCurrency)(parseFloat(data.totalInstallmentsAmount.replace('.', '').replace(',', '.')), locale, currency)
  }, {
    key: 'maxKm',
    label: translations.financeMaxKm,
    value: data.maxKilometres && data.maxKilometres + ' km'
  }, {
    key: 'paymentCosts',
    label: data.paymentCosts && data.paymentCosts.name,
    value: data.paymentCosts && data.paymentCosts.value && (0, _numbers.localiseCurrency)(parseFloat(data.paymentCosts.value.replace('.', '').replace(',', '.')), locale, currency)
  }, {
    key: 'gfv',
    label: translations.financeGfv,
    value: data.guaranteedResidualValue && (0, _numbers.localiseCurrency)(parseFloat(data.guaranteedResidualValue.replace('.', '').replace(',', '.')), locale, currency)
  }, {
    key: 'finalPayment',
    label: translations.financeFinalPayment,
    value: data.finalPayment && (0, _numbers.localiseCurrency)(parseFloat(data.finalPayment.replace('.', '').replace(',', '.')), locale, currency)
  }].concat((0, _toConsumableArray3.default)(transformExpenses())).filter(function (_ref3) {
    var key = _ref3.key;
    return financeExampleDisplay.includes(key);
  }).sort(function (a, b) {
    return financeExampleDisplay.findIndex(function (i) {
      return i === a.key;
    }) - financeExampleDisplay.findIndex(function (i) {
      return i === b.key;
    });
  }) : [];
};

var getFinanceHeaderDisplayList = exports.getFinanceHeaderDisplayList = function getFinanceHeaderDisplayList(data, globalFinance) {
  var financeLoaded = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var _globalFinance$transl2 = globalFinance.translations,
      translations = _globalFinance$transl2 === undefined ? {} : _globalFinance$transl2,
      locale = globalFinance.locale;
  var vehiclePrice = data.vehiclePrice,
      monthlyPrice = data.monthlyPrice,
      apr = data.apr,
      interestRate = data.interestRate;

  var currency = getCurrency(data);

  return [{
    key: 'vehiclePrice',
    label: translations.financeVehiclePrice,
    value: (0, _numbers.localiseCurrency)(vehiclePrice, locale, currency, 0)
  }, financeLoaded && {
    key: 'monthlyPrice',
    label: translations.financeMonthlyHeadlinePayment,
    value: (0, _numbers.localiseCurrency)(monthlyPrice && parseFloat(monthlyPrice.replace('.', '').replace(',', '.')), locale, currency, 2) || translations.searchResultsNotAvailableLabel
  }, financeLoaded && {
    key: 'apr',
    label: translations.financeAPR,
    value: (apr || translations.searchResultsNotAvailableLabel) + '%'
  }, financeLoaded && {
    key: 'interestRate',
    label: translations.financeInterestRate,
    value: (interestRate || translations.searchResultsNotAvailableLabel) + '%'
  }].filter(Boolean).sort(function (a, b) {
    return globalFinance.financeHeadingDisplay.findIndex(function (i) {
      return i === a.key;
    }) - globalFinance.financeHeadingDisplay.findIndex(function (i) {
      return i === b.key;
    });
  });
};

var reorderFinanceDisplayList = exports.reorderFinanceDisplayList = function reorderFinanceDisplayList(items) {
  var splitAt = Math.ceil(items.length / 2);
  var front = items.slice(0, splitAt);
  var back = items.slice(splitAt);

  return front.reduce(function (acc, curr, index) {
    return [].concat((0, _toConsumableArray3.default)(acc), [curr, back[index] || { display: '', value: '' }]);
  }, []);
};