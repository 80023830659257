'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.default = LightSelectField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FieldValidationError = require('./FieldValidationError');

var _FieldValidationError2 = _interopRequireDefault(_FieldValidationError);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'LightSelectField__Select',
  componentId: 'sc-y3wf8a-0'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;background:#f8f8f8;border:none;color:#808080;padding:12px 16px;&:disabled{border:none;-moz-appearance:none;-webkit-appearance:none;padding:0 8px;::-ms-expand{display:none;}}&:focus{color:black;outline:none;}-webkit-appearance:none;-moz-appearance:none;background-image:url(\'https://res.cloudinary.com/motortrak/image/upload/v1699547457/locator/genesis/icons/down-chev-light.svg\');background-repeat:no-repeat;background-position-x:calc(100% - 16px);background-position-y:20px;border-bottom:1px solid #111;> option{font-family:\'GenesisSansTextGlobal-Regular\',Arial;}']);

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'LightSelectField__Label',
  componentId: 'sc-y3wf8a-1'
})(['color:#111;font-size:14px;font-family:\'GenesisSansTextGlobal-Regular\';margin-bottom:16px;']);

function LightSelectField(_ref) {
  var required = _ref.required,
      id = _ref.id,
      label = _ref.label,
      onChange = _ref.onChange,
      disabled = _ref.disabled,
      options = _ref.options,
      valueFromOption = _ref.valueFromOption,
      labelFromOption = _ref.labelFromOption,
      pleaseSelect = _ref.pleaseSelect,
      error = _ref.error,
      value = _ref.value,
      dataCy = _ref.dataCy,
      removeMargin = _ref.removeMargin,
      _ref$orientation = _ref.orientation,
      orientation = _ref$orientation === undefined ? 'vertical' : _ref$orientation;

  return _react2.default.createElement(
    _FormField2.default,
    { removeMargin: removeMargin, orientation: orientation },
    label && _react2.default.createElement(
      Label,
      null,
      label,
      !required && ' (optional)'
    ),
    _react2.default.createElement(
      Select,
      {
        onChange: function (_onChange) {
          function onChange(_x) {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function (_ref2) {
          var _ref2$target = _ref2.target,
              val = _ref2$target.value,
              op = _ref2$target.options,
              selectedIndex = _ref2$target.selectedIndex;

          var optionLabel = op[selectedIndex].innerText;
          onChange({ id: id, value: val, label: optionLabel });
        }),
        disabled: disabled,
        value: value,
        'data-cy': dataCy || ''
      },
      [!value && pleaseSelect && _react2.default.createElement(
        'option',
        { disabled: true, selected: true, value: '' },
        pleaseSelect
      )].concat((0, _toConsumableArray3.default)(options.map(function (o) {
        var optionLabel = labelFromOption(o);
        var optionValue = valueFromOption(o);
        return _react2.default.createElement(
          'option',
          { key: '' + optionValue + optionLabel, value: optionValue },
          optionLabel
        );
      }))).filter(Boolean)
    ),
    error && _react2.default.createElement(
      _FieldValidationError2.default,
      null,
      error
    )
  );
}

LightSelectField.defaultProps = {
  valueFromOption: function valueFromOption(i) {
    return i.value;
  },
  labelFromOption: function labelFromOption(i) {
    return i.label;
  },
  error: '',
  disabled: false,
  required: false,
  removeMargin: false
};