'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.constants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends4 = require('babel-runtime/helpers/extends');

var _extends5 = _interopRequireDefault(_extends4);

exports.reducer = reducer;

var _localStorage = require('../../helpers/localStorage');

var localStorageManager = _interopRequireWildcard(_localStorage);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  INIT: 'INIT',
  UPDATE_SESSION_PREFERENCES: 'UPDATE_SESSION_PREFERENCES',
  UPDATE_MULTIPLE_SESSION_PREFERENCES: 'UPDATE_MULTIPLE_SESSION_PREFERENCES'
};

var actions = exports.actions = {
  updateSessionPreferences: function updateSessionPreferences(key, value) {
    return {
      type: constants.UPDATE_SESSION_PREFERENCES,
      payload: {
        key: key,
        value: value
      }
    };
  },
  updateMultipleSessionPreferences: function updateMultipleSessionPreferences(preferences) {
    return {
      type: constants.UPDATE_MULTIPLE_SESSION_PREFERENCES,
      payload: preferences
    };
  }
};

var initialState = exports.initialState = {
  error: null,
  cookiesAccepted: false,
  searchSort: null,
  language: null,
  infiniteScroll: false,
  disableCurrencyPopup: false,
  currency: null,
  marketSelected: false,
  location: {},
  isHeroVisible: false
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case constants.UPDATE_SESSION_PREFERENCES:
      if (payload.value && payload.value.locationTown && payload.value.userCountry) {
        var _payload$value = payload.value,
            locationTown = _payload$value.locationTown,
            userCountry = _payload$value.userCountry;

        localStorageManager.saveKey('userCity', locationTown);
        localStorageManager.saveKey('userCountryCode', userCountry);
      }
      return (0, _extends5.default)({}, state, (0, _defineProperty3.default)({}, payload.key, payload.value));
    case constants.UPDATE_MULTIPLE_SESSION_PREFERENCES:
      return (0, _extends5.default)({}, state, payload.reduce(function (acc, curr) {
        return (0, _extends5.default)({}, acc, (0, _defineProperty3.default)({}, curr.key, curr.value));
      }, {}));
    default:
      return state;
  }
}