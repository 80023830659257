'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _MarketSelector = require('./MarketSelector');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_MarketSelector).default;
  }
});

var _effects = require('./effects');

Object.defineProperty(exports, 'effects', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_effects).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'MarketSelector';
var id = exports.id = 'MarketSelector';
var availableToAdministration = exports.availableToAdministration = false;