'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var _SectionIcons = require('../../helpers/SectionIcons');

var _SectionIcons2 = _interopRequireDefault(_SectionIcons);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var config = (0, _config.Config)('CompareGridPage', {
  id: 'compare-grid-page'
}, (0, _config.Slice)('Styling', { id: 'styling' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.TopTier }, (0, _config.Font)('Sub header font', 'subHeaderFont'), (0, _config.Font)('Information font', 'infoFont'), (0, _config.Colour)('Border Colour', 'borderColour'), (0, _config.Colour)('Close Icon Colour', 'closeIconColour'), (0, _config.Colour)('Contact Icon Colour', 'phoneIconColour'), (0, _config.Colour)('Optional extras row background', 'optionalExtrasBackgroundColour'), (0, _config.Colour)('Shortlist icon colour', 'shortlistIconListedColour'), (0, _config.TextArea)('Call to action path', 'ctaPath'))));

exports.default = config;