'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _GlobalInfo = require('./JaguarIcons/Global/GlobalInfo');

var _GlobalInfo2 = _interopRequireDefault(_GlobalInfo);

var _Close = require('./JaguarIcons/Global/Close');

var _Close2 = _interopRequireDefault(_Close);

var _OpenClose = require('./OpenClose');

var _OpenClose2 = _interopRequireDefault(_OpenClose);

var _Modal = require('./Modal');

var _Modal2 = _interopRequireDefault(_Modal);

var _Global = require('./Global');

var _JaguarArrow = require('../assets/JaguarArrow');

var _JaguarArrow2 = _interopRequireDefault(_JaguarArrow);

var _navigation = require('../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OfferTermsPopup__IconWrapper',
  componentId: 'sc-p9w5c-0'
})(['display:inline-block;margin-right:5px;']);


var ActionButton = _styledComponents2.default.div.withConfig({
  displayName: 'OfferTermsPopup__ActionButton',
  componentId: 'sc-p9w5c-1'
})(['&:hover{cursor:pointer;}']);

var ModalTitleWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModalTitleWrapper'
}).withConfig({
  displayName: 'OfferTermsPopup__ModalTitleWrapper',
  componentId: 'sc-p9w5c-2'
})(['display:flex;background-color:#f0f0f0;width:100%;align-items:center;']);

var ModalBodyWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModalBodyWrapper'
}).withConfig({
  displayName: 'OfferTermsPopup__ModalBodyWrapper',
  componentId: 'sc-p9w5c-3'
})(['padding:30px;font-weight:bold;']);

var CloseButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CloseButtonWrapper'
}).withConfig({
  displayName: 'OfferTermsPopup__CloseButtonWrapper',
  componentId: 'sc-p9w5c-4'
})(['display:flex;justify-content:center;cursor:pointer;width:10%;']);

var OfferTermsPopup = function OfferTermsPopup(_ref) {
  var modalTitle = _ref.modalTitle,
      offerDetailsLinkText = _ref.offerDetailsLinkText,
      offerTermText = _ref.offerTermText,
      offerDetailsUrl = _ref.offerDetailsUrl,
      colourValue = _ref.colourValue;

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl;

  return _react2.default.createElement(
    _OpenClose2.default,
    null,
    function (_ref2) {
      var open = _ref2.open,
          openClose = _ref2.openClose;
      return _react2.default.createElement(
        'div',
        null,
        Boolean(open) ? _react2.default.createElement(
          _Modal2.default,
          { overlay: true, onClickOutside: openClose, width: 500 },
          _react2.default.createElement(
            ModalTitleWrapper,
            null,
            _react2.default.createElement(
              _Global.HeadingTwo,
              {
                'data-cy': 'Popup-Title',
                styleOverride: function styleOverride() {
                  return '\n                  width: 100%;\n                  margin-left: 30px;\n                  padding: 20px 0;\n                  font-size: 18px;\n                  text-transform: uppercase;\n                ';
                }
              },
              modalTitle
            ),
            _react2.default.createElement(
              CloseButtonWrapper,
              { onClick: openClose, 'data-cy': 'Close-Button' },
              _react2.default.createElement(_Close2.default, { width: '14px', height: '14px', colour: '#CCCCCC' })
            )
          ),
          _react2.default.createElement(
            ModalBodyWrapper,
            null,
            _react2.default.createElement(
              _Global.Paragraph,
              {
                styleOverride: function styleOverride() {
                  return '\n                  color: #7E7E7E;\n                  white-space: pre-line;\n                  font-size: 14px;\n                  line-height: 17px;\n                  margin: 0;\n                  margin-bottom: 1.5em;\n                ';
                }
              },
              offerTermText
            ),
            _react2.default.createElement(
              'a',
              {
                href: offerDetailsUrl,
                target: getTargetUrl(offerDetailsUrl),
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  color: '#444444',
                  textDecoration: 'none',
                  fontSize: '14px'
                }
              },
              _react2.default.createElement(
                IconWrapper,
                null,
                _react2.default.createElement(_JaguarArrow2.default, {
                  width: '1em',
                  height: '1em',
                  colour: colourValue || '#9E1B32'
                })
              ),
              _react2.default.createElement(
                'span',
                null,
                offerDetailsLinkText
              )
            )
          )
        ) : _react2.default.createElement(
          ActionButton,
          { onClick: openClose },
          _react2.default.createElement(_GlobalInfo2.default, {
            width: '.9em',
            height: '.9em',
            padding: '4px 0 0 0',
            colour: '#000000'
          })
        )
      );
    }
  );
};

exports.default = _react2.default.memo(OfferTermsPopup);