'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends4 = require('babel-runtime/helpers/extends');

var _extends5 = _interopRequireDefault(_extends4);

exports.default = reducer;

var _ = require('.');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments[1];

  switch (action.type) {
    case _.constants.GET_DATA_SUCCESS:
      return (0, _extends5.default)({}, state, (0, _defineProperty3.default)({}, action.dataKey, action.payload));
    case _.constants.CLEAR_DATA_PROVIDER_STATE:
      return (0, _extends5.default)({}, state, (0, _defineProperty3.default)({}, action.dataKey, null));
    default:
      return state;
  }
}