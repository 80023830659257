'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 100px;\n  '], ['\n    margin-top: 100px;\n  ']);

exports.default = RRMCFiftyFiftySection;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _RRMCFiftyFifty = require('./RRMCFiftyFifty');

var _RRMCFiftyFifty2 = _interopRequireDefault(_RRMCFiftyFifty);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RRMCFiftyFiftySectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RRMCFiftyFiftySection__RRMCFiftyFiftySectionWrapper',
  componentId: 'sc-ha55up-0'
})(['', ';'], _theme2.default.min.large(_templateObject));

function RRMCFiftyFiftySection(_ref) {
  var config = _ref.config;
  var _config$fiftyFiftyIte = config.fiftyFiftyItems,
      fiftyFiftyItems = _config$fiftyFiftyIte === undefined ? [] : _config$fiftyFiftyIte;


  var isMobile = (0, _useCheckIsMobileScreen2.default)();
  var isTablet = (0, _useCheckIsMobileScreen2.default)('large');
  return _react2.default.createElement(
    RRMCFiftyFiftySectionWrapper,
    null,
    fiftyFiftyItems.map(function (item, index) {
      return _react2.default.createElement(_RRMCFiftyFifty2.default, {
        key: item.translations.title,
        buttonLabel: item.translations.buttonLabel,
        title: item.translations.title,
        content: item.translations.content,
        imageUrl: isMobile ? item.mobileImgUrl : item.desktopImgUrl,
        isDesktop: !isTablet,
        imageLeft: index % 2 === 0,
        path: item.callToActionUrl,
        index: index
      });
    })
  );
}