'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    height: 45px;\n  '], ['\n    height: 45px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n    font-size: 14px;\n  '], ['\n    ', '\n    font-size: 14px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    height: 45px; \n  '], ['\n    height: 45px; \n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    width: 20px;\n    height: 20px;\n  '], ['\n    width: 20px;\n    height: 20px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    height: 47px;    \n  '], ['\n    height: 47px;    \n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Carousel = require('../../Carousel');

var _Carousel2 = _interopRequireDefault(_Carousel);

var _Eye = require('../../JaguarIcons/VDP/Eye');

var _Eye2 = _interopRequireDefault(_Eye);

var _VdpSlideshowThumbnail = require('./VdpSlideshowThumbnail');

var _VdpSlideshowThumbnail2 = _interopRequireDefault(_VdpSlideshowThumbnail);

var _VdpVideoThumbnail = require('./VdpVideoThumbnail');

var _VdpVideoThumbnail2 = _interopRequireDefault(_VdpVideoThumbnail);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ModalOverlay = require('../../VehicleGallery/ModalOverlay');

var _ModalOverlay2 = _interopRequireDefault(_ModalOverlay);

var _parseYouTubeId = require('../../YouTubePlayer/parseYouTubeId');

var _parseYouTubeId2 = _interopRequireDefault(_parseYouTubeId);

var _useVehicleGallery2 = require('../../VehicleGallery/useVehicleGallery');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var borderButtonStyle = (0, _styledComponents.css)(['border:solid 1px #d8d8d8;border-top:0;padding:0 10px;height:60px;display:flex;align-items:center;']);

var Footer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSlideshow__Footer',
  componentId: 'sc-159cp0j-0'
})(['display:flex;align-items:center;justify-content:flex-end;margin:0;height:60px;', ';'], _theme2.default.max.medium(_templateObject));

var Counter = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSlideshow__Counter',
  componentId: 'sc-159cp0j-1'
})(['text-align:center;font-size:16px;display:flex;justify-content:end;white-space:nowrap;width:60px;', ' border-left:0;height:100%;', ';font-family:', ';color:', ';'], borderButtonStyle, _theme2.default.max.medium(_templateObject2, function (_ref) {
  var hideZoomMobile = _ref.hideZoomMobile;
  return hideZoomMobile && ' margin-left: 8px;';
}), function (_ref2) {
  var overrideStyles = _ref2.overrideStyles;
  return overrideStyles.fontFamily;
}, function (_ref3) {
  var overrideStyles = _ref3.overrideStyles;
  return overrideStyles.fontColor;
});

var ViewsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSlideshow__ViewsContainer',
  componentId: 'sc-159cp0j-2'
})(['opacity:1;color:#fff;margin-right:8px;']);
var ViewsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSlideshow__ViewsWrapper',
  componentId: 'sc-159cp0j-3'
})(['padding-left:20px;display:flex;align-items:center;']);

var FilmStripContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSlideshow__FilmStripContainer',
  componentId: 'sc-159cp0j-4'
})(['position:relative;overflow-x:hidden;overflow-y:hidden;box-sizing:border-box;height:60px;flex:1 1 100%;', ';'], _theme2.default.max.medium(_templateObject3));

var ThumbnailsScroller = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSlideshow__ThumbnailsScroller',
  componentId: 'sc-159cp0j-5'
})(['min-width:0;width:100%;scroll-behavior:smooth;position:relative;', ';box-sizing:border-box;transition:', ';'], function (_ref4) {
  var direction = _ref4.direction;
  return direction === 'rtl' ? 'right: 0;' : 'left: 0;';
}, function (_ref5) {
  var direction = _ref5.direction;
  return direction === 'rtl' ? 'right 0.5s ease;' : 'left 0.5s ease;';
});

var MagnifyWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSlideshow__MagnifyWrapper',
  componentId: 'sc-159cp0j-6'
})(['cursor:pointer;', ' border-left:0;height:100%;', ';'], borderButtonStyle, _theme2.default.max.medium(_templateObject4, function (_ref6) {
  var hideZoomMobile = _ref6.hideZoomMobile;
  return hideZoomMobile && 'display: none;';
}));

var View360Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSlideshow__View360Wrapper',
  componentId: 'sc-159cp0j-7'
})(['cursor:pointer;', ' border-left:0;', ';'], borderButtonStyle, _theme2.default.max.medium(_templateObject4, function (_ref7) {
  var hideZoomMobile = _ref7.hideZoomMobile;
  return hideZoomMobile && 'display: none;';
}));

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'VdpSlideshow__IconWrapper',
  componentId: 'sc-159cp0j-8'
})(['width:32px;height:32px;', ';'], _theme2.default.max.medium(_templateObject5));

var ArrowIconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'VdpSlideshow__ArrowIconWrapper',
  componentId: 'sc-159cp0j-9'
})(['width:15px;height:15px;']);

var FlipperButton = _styledComponents2.default.button.withConfig({
  displayName: 'VdpSlideshow__FlipperButton',
  componentId: 'sc-159cp0j-10'
})(['cursor:pointer;height:61px;width:30px;padding-top:0;padding-bottom:0;background-color:#fff;border:solid 1px #d8d8d8;border-top:0;', ';', ';', ';&:focus{outline:0;}'], function (_ref8) {
  var direction = _ref8.direction;
  return direction === 'rtl' && 'transform: scaleX(-1);';
}, function (_ref9) {
  var arrowSize = _ref9.arrowSize;
  return arrowSize && 'font-size: ' + arrowSize + 'px;';
}, _theme2.default.max.medium(_templateObject6));

var VdpSlideshow = function VdpSlideshow(props) {
  var _props$config = props.config,
      config = _props$config === undefined ? {
    translations: {}
  } : _props$config,
      isLooping = props.isLooping,
      views = props.views,
      placeholdingImage = props.placeholdingImage,
      vehicleInfo = props.vehicleInfo,
      rotateGallery = props.rotateGallery,
      imageCarousel = props.imageCarousel,
      hideZoomMobile = props.hideZoomMobile,
      globalStyling = props.globalStyling,
      translations = props.translations,
      vehicleGalleryArrowFont = props.vehicleGalleryArrowFont;


  var parsedYouTubeId = vehicleInfo.video ? (0, _parseYouTubeId2.default)(vehicleInfo.video) : null;

  var slides = [].concat((0, _toConsumableArray3.default)(config.slides));
  var benefitImageUrl = translations.benefitsImageUrl;
  if (benefitImageUrl) {
    slides.push({ id: benefitImageUrl, image: benefitImageUrl });
  }

  var images = slides.map(function (slide) {
    return slide.image;
  });

  var _useVehicleGallery = (0, _useVehicleGallery2.useVehicleGallery)({
    rotateGallery: props.rotateGallery,
    direction: props.globalStyling.direction,
    slides: slides,
    placeholdingImage: placeholdingImage
  }),
      isOpen = _useVehicleGallery.isOpen,
      currentCarouselImage = _useVehicleGallery.currentCarouselImage,
      containerRef = _useVehicleGallery.containerRef,
      openLightbox = _useVehicleGallery.openLightbox,
      closeLightbox = _useVehicleGallery.closeLightbox,
      goToSlide = _useVehicleGallery.goToSlide,
      handleThumbnailClick = _useVehicleGallery.handleThumbnailClick,
      nextLeftImageIndex = _useVehicleGallery.nextLeftImageIndex,
      nextRightImageIndex = _useVehicleGallery.nextRightImageIndex;

  var carouselProps = {
    hideZoomMobile: hideZoomMobile,
    slides: slides.map(function (slide) {
      return (0, _extends3.default)({}, slide, { component: _Carousel.Slide });
    }),
    isLooping: isLooping,
    rotate: rotateGallery,
    onClickNext: function onClickNext() {
      return goToSlide(currentCarouselImage + 1);
    },
    onClickPrev: function onClickPrev() {
      return goToSlide(currentCarouselImage - 1);
    },
    slideIndex: currentCarouselImage,
    arrowRight: imageCarousel && imageCarousel.arrowRight,
    arrowLeft: imageCarousel && imageCarousel.arrowLeft,
    icon360: imageCarousel && imageCarousel.icon360,
    enable360View: imageCarousel && imageCarousel.enable360View,
    imageHeight: imageCarousel && imageCarousel.imageHeight,
    video: config.video,
    close360Icon: imageCarousel && imageCarousel.close360Icon,
    vehicleInfo: vehicleInfo,
    globalStyling: globalStyling,
    translations: translations,
    imageCarousel: imageCarousel,
    parsedYouTubeId: parsedYouTubeId
  };

  var leftArrow = function leftArrow() {
    return imageCarousel && imageCarousel.arrowLeft ? _react2.default.createElement(ArrowIconWrapper, { src: imageCarousel.arrowLeft, alt: 'Left arrow' }) : '<';
  };
  var rightArrow = function rightArrow() {
    return imageCarousel && imageCarousel.arrowRight ? _react2.default.createElement(ArrowIconWrapper, { src: imageCarousel.arrowRight, alt: 'Right arrow' }) : '>';
  };

  return _react2.default.createElement(
    _react.Fragment,
    null,
    isOpen && _react2.default.createElement(
      _ModalOverlay2.default,
      {
        close: closeLightbox,
        config: config,
        modalWidth: '80%',
        direction: globalStyling.direction
      },
      _react2.default.createElement(_Carousel2.default, (0, _extends3.default)({}, carouselProps, { fullscreen: true }))
    ),
    _react2.default.createElement(_Carousel2.default, (0, _extends3.default)({}, carouselProps, {
      renderFooter: function renderFooter(_ref10) {
        var showMedia = _ref10.showMedia;
        return _react2.default.createElement(
          Footer,
          {
            backgroundColour: imageCarousel && imageCarousel.footerBackgroundColour,
            border: imageCarousel && imageCarousel.borderFooter,
            horizontalPadding: imageCarousel && imageCarousel.horizontalPadding
          },
          imageCarousel && !imageCarousel.hideViews && _react2.default.createElement(
            ViewsContainer,
            null,
            _react2.default.createElement(
              ViewsWrapper,
              null,
              _react2.default.createElement(_Eye2.default, { width: '2em', height: '2em', colour: '#fff' }),
              _react2.default.createElement(
                'span',
                null,
                views
              )
            )
          ),
          parsedYouTubeId && _react2.default.createElement(_VdpVideoThumbnail2.default, {
            showMedia: showMedia,
            imageUrl: images && images[0]
          }),
          _react2.default.createElement(
            FlipperButton,
            {
              arrowSize: vehicleGalleryArrowFont && vehicleGalleryArrowFont.fontSize,
              left: true,
              onClick: function onClick() {
                goToSlide(nextLeftImageIndex);
              },
              direction: globalStyling.direction
            },
            leftArrow()
          ),
          _react2.default.createElement(
            FilmStripContainer,
            null,
            _react2.default.createElement(
              ThumbnailsScroller,
              {
                ref: containerRef,
                direction: globalStyling.direction
              },
              _react2.default.createElement(_VdpSlideshowThumbnail2.default, {
                height: 60,
                imageUrls: images,
                onImageClick: handleThumbnailClick,
                selectedImage: currentCarouselImage
              })
            )
          ),
          _react2.default.createElement(
            FlipperButton,
            {
              arrowSize: vehicleGalleryArrowFont && vehicleGalleryArrowFont.fontSize,
              onClick: function onClick() {
                goToSlide(nextRightImageIndex);
              },
              direction: globalStyling.direction
            },
            rightArrow()
          ),
          _react2.default.createElement(
            Counter,
            {
              hideZoomMobile: hideZoomMobile,
              overrideStyles: imageCarousel.footer.counter
            },
            currentCarouselImage + 1 + ' / ' + images.length
          ),
          _react2.default.createElement(
            MagnifyWrapper,
            {
              hideZoomMobile: hideZoomMobile,
              onClick: openLightbox
            },
            imageCarousel && imageCarousel.zoomIcon && _react2.default.createElement(IconWrapper, { src: imageCarousel.zoomIcon, alt: '' })
          ),
          vehicleInfo.media && (vehicleInfo.media.exterior || vehicleInfo.media.interior) ? _react2.default.createElement(
            View360Wrapper,
            null,
            imageCarousel && imageCarousel.icon360 && _react2.default.createElement(IconWrapper, { src: imageCarousel.icon360, alt: '' })
          ) : null
        );
      }
    }))
  );
};

exports.default = VdpSlideshow;