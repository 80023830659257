'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      transform: ', ';\n      top: ', ';\n      right: ', ';\n    '], ['\n      transform: ', ';\n      top: ', ';\n      right: ', ';\n    ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Chunky = require('../JaguarIcons/Arrows/Chunky');

var _Chunky2 = _interopRequireDefault(_Chunky);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TitleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'renderFilterTitle__TitleContainer',
  componentId: 'sc-1ks07kn-0'
})(['position:relative;width:100%;cursor:pointer;text-transform:uppercase;color:#444;line-height:55px;height:55px;border-top:1px solid #d8d8d8;']);

var Chevron = _styledComponents2.default.div.withConfig({
  displayName: 'renderFilterTitle__Chevron',
  componentId: 'sc-1ks07kn-1'
})(['display:flex;position:absolute;right:20px;top:5px;width:10px;height:10px;', ';'], _theme2.default.min.large(_templateObject, function (_ref) {
  var collapsed = _ref.collapsed;
  return collapsed ? 'rotate(0deg)' : 'rotate(90deg)';
}, function (_ref2) {
  var collapsed = _ref2.collapsed;
  return collapsed ? '5px' : '20px';
}, function (_ref3) {
  var collapsed = _ref3.collapsed;
  return collapsed ? '20px' : '0px';
}));

var renderFilterTitle = function renderFilterTitle(props) {
  return function (_ref4) {
    var collapsed = _ref4.collapsed;
    return _react2.default.createElement(
      TitleContainer,
      { collapsed: collapsed },
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n        font-size: 16px;\n      ';
          }
        },
        props.title
      ),
      _react2.default.createElement(
        Chevron,
        { collapsed: collapsed },
        _react2.default.createElement(_Chunky2.default, { colour: '#7E7E7E' })
      )
    );
  };
};

exports.default = renderFilterTitle;