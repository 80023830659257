'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var _SectionIcons = require('../../helpers/SectionIcons');

var _SectionIcons2 = _interopRequireDefault(_SectionIcons);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var config = (0, _config.Config)('Brick', { id: 'Brick' }, (0, _config.ListSlice)('Content', {
  id: 'items',
  max: 4,
  sectionLabel: 'Brick item',
  buttonLabel: 'Add new brick item',
  icon: _SectionIcons2.default.GridItem
}, (0, _config.Image)('Image', 'image'), (0, _config.TextArea)('Headline', 'headlineText'), (0, _config.TextArea)('Body Text', 'descriptionText')), (0, _config.Slice)('Styling', { id: 'styling' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.TopTier }, (0, _config.Colour)('Background', 'brickBackgroundColour'), (0, _config.Font)('Header text', 'brickItemHeaderFont'), (0, _config.Font)('Description text', 'brickItemDescriptionFont'), (0, _config.Toggle)('Border', 'brickPartitionBorderActive'), (0, _config.Colour)('Border Colour', 'brickPartitionBorderColour'))));

exports.default = config;