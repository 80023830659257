'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Pill = _styledComponents2.default.div.withConfig({
  displayName: 'FilterPill__Pill',
  componentId: 'sc-6rf9ob-0'
})(['border-bottom:1px solid #151515;padding:6px 0;color:#151515;display:inline-flex;align-items:center;font-family:\'RivieraNights-Regular\';font-size:12px;text-transform:uppercase;letter-spacing:2px;margin-right:25px;']);

var CloseButton = _styledComponents2.default.a.withConfig({
  displayName: 'FilterPill__CloseButton',
  componentId: 'sc-6rf9ob-1'
})(['display:flex;cursor:pointer;padding-left:10px;']);

var FilterPill = function FilterPill(_ref) {
  var pillText = _ref.pillText,
      onClose = _ref.onClose;

  return _react2.default.createElement(
    Pill,
    null,
    pillText,
    _react2.default.createElement(
      CloseButton,
      { onClick: onClose, 'data-cy': pillText + ' pill' },
      _react2.default.createElement('img', { src: 'https://res.cloudinary.com/motortrak/v1707585522/locator/rolls-royce/global/icons/filterpill-close.svg' })
    )
  );
};

exports.default = FilterPill;