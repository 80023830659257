'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var config = (0, _config.Config)('BackToTop', { id: 'BackToTop' }, (0, _config.Slice)('Styling', { id: 'styling' }, (0, _config.Section)('Global', {}, (0, _config.Toggle)('Active', 'active'), (0, _config.Colour)('Background colour', 'buttonBackgroundColour'), (0, _config.Colour)('Icon colour', 'buttonIconColour'))));

exports.default = config;