'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex: 1;\n    width: 100%;\n    @supports (display: grid) {\n      display: grid;\n      grid-template-columns: ', ';\n      grid-column-gap: 40px;\n      grid-row-gap: 20px;\n    }\n  '], ['\n    display: flex;\n    flex: 1;\n    width: 100%;\n    @supports (display: grid) {\n      display: grid;\n      grid-template-columns: ', ';\n      grid-column-gap: 40px;\n      grid-row-gap: 20px;\n    }\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 33.3%;\n    @supports (display: grid) {\n      margin: 0;\n      width: unset;\n    }\n  '], ['\n    width: 33.3%;\n    @supports (display: grid) {\n      margin: 0;\n      width: unset;\n    }\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _getWarrantyLabel = require('../helpers/getWarrantyLabel');

var _dates = require('../shared/localisation/dates');

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var WellFeaturesItemsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpFeatures__WellFeaturesItemsContainer',
  componentId: 'sc-jr3njx-0'
})(['', ';'], _theme2.default.min.large(_templateObject, function (_ref) {
  var warrantyYears = _ref.warrantyYears,
      combinedConsumption = _ref.combinedConsumption,
      hideDefaultFeatures = _ref.hideDefaultFeatures;
  return warrantyYears && warrantyYears > 0 && combinedConsumption && combinedConsumption > 0 && !hideDefaultFeatures ? '1fr 1fr 1fr' : '1fr 1fr';
}));

var WellFeaturesItem = _styledComponents2.default.div.withConfig({
  displayName: 'VdpFeatures__WellFeaturesItem',
  componentId: 'sc-jr3njx-1'
})(['background-color:#ffffff;border:1px solid #d5d5d5;display:flex;margin:20px 0;padding:', ';', ';'], function (_ref2) {
  var noIcon = _ref2.noIcon;
  return noIcon ? '20px 40px' : '20px';
}, _theme2.default.min.large(_templateObject2));

var WellFeaturesItemIcon = _styledComponents2.default.img.withConfig({
  displayName: 'VdpFeatures__WellFeaturesItemIcon',
  componentId: 'sc-jr3njx-2'
})(['margin-right:10px;width:40px;']);

var formattedLocale = function formattedLocale(locale) {
  return locale.replace('_', '-');
};

function VdpFeatures(_ref3) {
  var featuresList = _ref3.featuresList,
      translations = _ref3.translations,
      locale = _ref3.locale,
      vehicleDetails = _ref3.vehicleDetails;
  var specification = vehicleDetails.specification,
      damageStatus = vehicleDetails.damageStatus,
      numberOfOwners = vehicleDetails.numberOfOwners,
      combinedConsumption = vehicleDetails.specification.engineDetails.combinedConsumption;

  return _react2.default.createElement(
    WellFeaturesItemsContainer,
    {
      warrantyYears: specification.warranty.remainingWarrantyWholeYears,
      combinedConsumption: combinedConsumption,
      hideDefaultFeatures: featuresList.hideDefaultFeatures
    },
    specification.warranty.remainingWarrantyWholeYears > 0 && !featuresList.showWarrantyExpiryDate && !featuresList.hideDefaultFeatures && _react2.default.createElement(
      WellFeaturesItem,
      null,
      _react2.default.createElement(WellFeaturesItemIcon, {
        src: featuresList.warrantyIcon.value,
        alt: featuresList.warrantyIcon.label
      }),
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(featuresList.itemHeading) + '\n              margin: 5px 0;\n            ';
            }
          },
          translations.warrantyHeading
        ),
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(featuresList.value) + '\n              margin: 0;\n            ';
            }
          },
          (0, _getWarrantyLabel.getWarrantyLabel)(specification.warranty.remainingWarrantyWholeYears, translations.warrantyYearsLabel, translations.warrantyYearsLabel2to4)
        )
      )
    ),
    featuresList.showWarrantyExpiryDate && specification.warranty.expiryDate !== null && !featuresList.hideDefaultFeatures && _react2.default.createElement(
      WellFeaturesItem,
      null,
      _react2.default.createElement(WellFeaturesItemIcon, {
        src: featuresList.warrantyIcon.value,
        alt: featuresList.warrantyIcon.label
      }),
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(featuresList.itemHeading) + '\n              margin: 5px 0;\n            ';
            }
          },
          translations.warrantyHeading
        ),
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(featuresList.value) + '\n              margin: 0;\n            ';
            }
          },
          (0, _dates.localeFormattedDate)(specification.warranty.expiryDate, formattedLocale(locale), {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
        )
      )
    ),
    specification.odometer.display && !featuresList.hideDefaultFeatures && _react2.default.createElement(
      WellFeaturesItem,
      null,
      _react2.default.createElement(WellFeaturesItemIcon, {
        src: featuresList.odometerIcon.value,
        alt: featuresList.odometerIcon.label
      }),
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(featuresList.itemHeading) + '\n              margin: 5px 0;\n            ';
            }
          },
          translations.mileageHeading
        ),
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(featuresList.value) + '\n              margin: 0;\n            ';
            }
          },
          specification.odometer.display
        )
      )
    ),
    combinedConsumption > 0 && !featuresList.hideDefaultFeatures && _react2.default.createElement(
      WellFeaturesItem,
      null,
      _react2.default.createElement(WellFeaturesItemIcon, {
        src: featuresList.consumptionCombinedIcon.value,
        alt: featuresList.consumptionCombinedIcon.label
      }),
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(featuresList.itemHeading) + '\n              margin: 5px 0;\n            ';
            }
          },
          translations.consumptionCombined
        ),
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(featuresList.value) + '\n              margin: 0;\n            ';
            }
          },
          combinedConsumption,
          ' ',
          translations.mpgLabel
        )
      )
    ),
    featuresList.hideDefaultFeatures && damageStatus && _react2.default.createElement(
      WellFeaturesItem,
      { noIcon: true },
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(featuresList.itemHeading) + '\n              margin: 5px 0;\n            ';
            }
          },
          translations.featuresConditionLabel
        ),
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(featuresList.value) + '\n              margin: 0;\n            ';
            }
          },
          damageStatus
        )
      )
    ),
    featuresList.hideDefaultFeatures && (numberOfOwners || numberOfOwners === 0 || numberOfOwners === null) && _react2.default.createElement(
      WellFeaturesItem,
      { noIcon: true },
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n                    ' + (0, _Global.fontStyleOverride)(featuresList.itemHeading) + '\n                    margin: 5px 0;\n                  ';
            }
          },
          translations.featuresPreviousOwnersLabel
        ),
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n                    ' + (0, _Global.fontStyleOverride)(featuresList.value) + '\n                    margin: 0;\n                  ';
            }
          },
          numberOfOwners || '0'
        )
      )
    )
  );
}

exports.default = VdpFeatures;