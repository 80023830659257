'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['display:none;'], ['display:none;']);

exports.default = VehicleInfoBanner;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _useVehiclePrices2 = require('../../../hooks/useVehiclePrices');

var _useVehiclePrices3 = _interopRequireDefault(_useVehiclePrices2);

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

var _vehicleDetailsPageTypes = require('../../../types/Genesis/vehicleDetailsPageTypes');

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

var _CarfaxLogo = require('../CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

var _GermanTaxDisclaimer = require('../CustomCountryComponents/GermanTaxDisclaimer');

var _GermanTaxDisclaimer2 = _interopRequireDefault(_GermanTaxDisclaimer);

var _SubNavBar = require('../SubNavBar');

var _SubNavBar2 = _interopRequireDefault(_SubNavBar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Wrapper',
  componentId: 'sc-1kjwpdj-0'
})(['background:#fff;color:#151515;display:flex;height:180px;font-family:\'RivieraNights-Light\';font-weight:400;left:0;position:fixed;top:0;width:100%;z-index:95;transition:top 0.4s;box-sizing:border-box;display:flex;flex-direction:column;', ';', ''], _RollsRoyce2.default.max.large(_templateObject), function (_ref) {
  var showBanner = _ref.showBanner;
  return showBanner ? 'top: 0px;' : 'top: -200px;';
});

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Content',
  componentId: 'sc-1kjwpdj-1'
})(['', ';display:flex;margin:0 auto;max-width:1920px;width:100%;box-sizing:border-box;'], function () {
  return _RollsRoyce.mixins.pageGuttering();
});

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Column',
  componentId: 'sc-1kjwpdj-2'
})(['display:flex;flex-direction:column;height:100%;overflow:hidden;padding-top:16px;box-sizing:border-box;']);

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Row',
  componentId: 'sc-1kjwpdj-3'
})(['align-items:center;display:flex;justify-content:space-between;padding-left:21px;width:100%;box-sizing:border-box;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleInfoBanner__Image',
  componentId: 'sc-1kjwpdj-4'
})(['', ' aspect-ratio:3 / 2;display:block;object-fit:cover;max-height:111px;padding:5px 0px;'], _RollsRoyce2.default.max.medium(_templateObject));

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Title',
  componentId: 'sc-1kjwpdj-5'
})(['font-family:\'RivieraNights-Regular\';font-weight:400;text-transform:uppercase;font-size:32px;font-weight:normal;letter-spacing:2px;']);

var InfoRowStyles = '\n  align-items: center;\n  display: flex;\n  font-family: \'RivieraNights-Regular\';\n  font-size: 15px;\n  line-height: 24px;\n';

var InfoRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__InfoRow',
  componentId: 'sc-1kjwpdj-6'
})(['', ''], InfoRowStyles);

var InfoRowLast = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__InfoRowLast',
  componentId: 'sc-1kjwpdj-7'
})(['', ' margin:5px 0;'], InfoRowStyles);

var DealerText = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__DealerText',
  componentId: 'sc-1kjwpdj-8'
})(['font-size:12px;font-family:\'RivieraNights-Bold\';text-transform:uppercase;padding-left:10px;letter-spacing:2px;font-weight:normal;']);

var InfoRowLink = _styledComponents2.default.a.withConfig({
  displayName: 'VehicleInfoBanner__InfoRowLink',
  componentId: 'sc-1kjwpdj-9'
})(['', ' color:#151515;font-family:\'RivieraNights-Medium\';font-size:14px;font-weight:500;text-decoration:none;margin-right:20px;'], InfoRowStyles);

var Price = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Price',
  componentId: 'sc-1kjwpdj-10'
})(['display:flex;justify-content:end;font-family:\'RivieraNights-Medium\';font-weight:500;font-size:32px;letter-spacing:2px;margin-bottom:5px;text-align:right;', ';'], function (_ref2) {
  var hasZeroPriceAsText = _ref2.hasZeroPriceAsText;
  return hasZeroPriceAsText && ' text-transform: uppercase';
});

var SubNavContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__SubNavContainer',
  componentId: 'sc-1kjwpdj-11'
})(['display:flex;width:100%;background-color:#f2f2f2;', ';'], function () {
  return _RollsRoyce.mixins.pageGuttering();
});

var PriceWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__PriceWrapper',
  componentId: 'sc-1kjwpdj-12'
})(['align-items:flex-end;display:flex;flex-direction:column;align-items:flex-start;', ';'], function (_ref3) {
  var netPrice = _ref3.netPrice;
  return netPrice && ' min-width: 190px;';
});

var NetPrice = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__NetPrice',
  componentId: 'sc-1kjwpdj-13'
})(['display:flex;font-family:\'RivieraNights-Medium\';font-size:14px;line-height:28px;letter-spacing:0.5px;']);

var NetPriceDELabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__NetPriceDELabel',
  componentId: 'sc-1kjwpdj-14'
})(['font-family:\'RivieraNights-Regular\';font-style:italic;padding-left:5px;']);

var DealerNameIcon = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleInfoBanner__DealerNameIcon',
  componentId: 'sc-1kjwpdj-15'
})(['margin-top:-3px;']);

var PhoneIcon = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleInfoBanner__PhoneIcon',
  componentId: 'sc-1kjwpdj-16'
})(['margin-top:-2px;']);

function VehicleInfoBanner(_ref4) {
  var vehicleDetails = _ref4.vehicleDetails,
      config = _ref4.config,
      placeholdingImage = _ref4.placeholdingImage,
      _ref4$showBanner = _ref4.showBanner,
      showBanner = _ref4$showBanner === undefined ? false : _ref4$showBanner,
      history = _ref4.history,
      navigateToEnquiryForm = _ref4.navigateToEnquiryForm;
  var modelYear = vehicleDetails.modelYear,
      location = vehicleDetails.location,
      specification = vehicleDetails.specification,
      retailerInformation = vehicleDetails.retailerInformation,
      images = vehicleDetails.images,
      modelVariant = vehicleDetails.modelVariant;
  var fuelType = specification.fuelType,
      transmission = specification.transmission,
      odometer = specification.odometer;
  var dealerName = retailerInformation.name,
      phone = retailerInformation.phone;
  var translations = config.translations,
      handleZeroPriceAsText = config.handleZeroPriceAsText;

  var _useVehiclePrices = (0, _useVehiclePrices3.default)(vehicleDetails, translations, handleZeroPriceAsText),
      handlePrice = _useVehiclePrices.grossPrice,
      netPrice = _useVehiclePrices.netPrice;

  var showSalesTaxDisclaimer = !!vehicleDetails.sectionSalesTaxDisclaimer;

  return _react2.default.createElement(
    Wrapper,
    { showBanner: showBanner },
    _react2.default.createElement(
      Content,
      null,
      _react2.default.createElement(Image, {
        src: images.length > 0 ? images[0] : placeholdingImage,
        'data-cy': 'vehicle-banner-image',
        alt: vehicleDetails.name
      }),
      _react2.default.createElement(
        Row,
        null,
        _react2.default.createElement(
          Column,
          null,
          _react2.default.createElement(
            Title,
            null,
            modelVariant
          ),
          _react2.default.createElement(
            InfoRow,
            null,
            translations.modelYear + ' ' + modelYear + ' | ' + transmission + ' | ' + fuelType + ' ' + (odometer ? '| ' + odometer.display : '')
          ),
          _react2.default.createElement(
            InfoRowLast,
            null,
            phone && _react2.default.createElement(
              InfoRowLink,
              { href: 'tel:' + phone },
              _react2.default.createElement(PhoneIcon, {
                src: 'https://res.cloudinary.com/motortrak/v1713348802/locator/rolls-royce/global/icons/vdp-phone-icon.svg',
                alt: 'Call Rolls-Royce Motor Cars, ' + location
              }),
              _react2.default.createElement(
                'div',
                {
                  style: {
                    paddingLeft: '10px',
                    textDecoration: 'none',
                    letterSpacing: '2px'
                  }
                },
                phone
              )
            ),
            _react2.default.createElement(DealerNameIcon, {
              src: 'https://res.cloudinary.com/motortrak/v1713348803/locator/rolls-royce/global/icons/vdp-pin-icon.svg',
              alt: 'Rolls-Royce Motor Cars, ' + location
            }),
            _react2.default.createElement(
              DealerText,
              null,
              dealerName
            )
          )
        ),
        _react2.default.createElement(
          Column,
          { style: { justifyContent: 'space-between' } },
          _react2.default.createElement(
            PriceWrapper,
            { netPrice: netPrice && true },
            _react2.default.createElement(
              Price,
              { hasZeroPriceAsText: !netPrice },
              vehicleDetails.price.value > 0 && netPrice ? (0, _translateFromTemplate.translateFromTemplate)('grossPrice', { VALUE: handlePrice }, translations) : handlePrice,
              _react2.default.createElement(_GermanTaxDisclaimer2.default, {
                countryCode: vehicleDetails.retailerInformation.countryCode,
                showSalesTaxDisclaimer: showSalesTaxDisclaimer,
                salesTaxDisclaimer: translations.salesTaxDisclaimer,
                tooltipPlacement: 'bottom-end'
              })
            ),
            vehicleDetails.carfax && _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicleDetails.carfax }),
            vehicleDetails.price.value > 0 && netPrice && _react2.default.createElement(
              NetPrice,
              null,
              (0, _translateFromTemplate.translateFromTemplate)('netPrice', { VALUE: netPrice }, translations),
              vehicleDetails.retailerInformation.countryCode === 'DE' && _react2.default.createElement(
                NetPriceDELabel,
                null,
                translations.netPriceLabel
              )
            )
          )
        )
      )
    ),
    _react2.default.createElement(
      SubNavContainer,
      null,
      _react2.default.createElement(_SubNavBar2.default, {
        history: history,
        config: config,
        isVdpBanner: true,
        navigateToEnquiryForm: navigateToEnquiryForm
      })
    )
  );
}