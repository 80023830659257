'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var config = (0, _config.Config)('Test Drive', { id: 'test-drive' }, (0, _config.Slice)('Date & Time', { id: 'date-time' }));

exports.default = config;