'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SocialShare;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function SocialShare(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 16 16', width: '1em', height: '1em' },
      _react2.default.createElement('path', { d: 'M11.586 9.994c-.798 0-1.508.384-1.963.977L5.815 8.823a2.534 2.534 0 0 0-.003-1.654l3.805-2.146a2.466 2.466 0 0 0 1.966.983c1.364 0 2.478-1.122 2.478-2.503C14.06 2.123 12.95 1 11.583 1c-1.367 0-2.478 1.122-2.478 2.503 0 .29.049.57.14.828L5.445 6.477a2.458 2.458 0 0 0-1.966-.983C2.114 5.494 1 6.616 1 7.997 1 9.377 2.114 10.5 3.48 10.5c.801 0 1.515-.387 1.97-.985l3.804 2.148a2.514 2.514 0 0 0-.143.834c0 1.378 1.11 2.503 2.478 2.503 1.367 0 2.478-1.122 2.478-2.503 0-1.38-1.114-2.503-2.481-2.503zm0-8.206c.938 0 1.7.77 1.7 1.718 0 .947-.762 1.718-1.7 1.718-.939 0-1.701-.77-1.701-1.718s.765-1.718 1.7-1.718zM3.48 9.715c-.938 0-1.701-.77-1.701-1.718s.763-1.718 1.7-1.718c.94 0 1.702.77 1.702 1.718S4.416 9.715 3.48 9.715zm8.105 4.497c-.939 0-1.701-.77-1.701-1.718 0-.947.762-1.718 1.7-1.718.939 0 1.702.77 1.702 1.718s-.763 1.718-1.701 1.718z' })
    )
  );
}

SocialShare.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};