'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _CheckboxGroup = require('./CheckboxGroup');

var _CheckboxGroup2 = _interopRequireDefault(_CheckboxGroup);

var _SectionTitle = require('./SectionTitle');

var _SectionTitle2 = _interopRequireDefault(_SectionTitle);

var _Section = require('./Section');

var _Section2 = _interopRequireDefault(_Section);

var _SubSection = require('./SubSection');

var _SubSection2 = _interopRequireDefault(_SubSection);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CheckboxGroupList = function (_Component) {
  (0, _inherits3.default)(CheckboxGroupList, _Component);

  function CheckboxGroupList() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, CheckboxGroupList);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = CheckboxGroupList.__proto__ || (0, _getPrototypeOf2.default)(CheckboxGroupList)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'handleCheckboxClick', {
      enumerable: true,
      writable: true,
      value: function value(_ref2) {
        var target = _ref2.target;

        _this.props.handleCheckboxClick(target.id, target.value);
      }
    }), Object.defineProperty(_this, 'handleUncheckList', {
      enumerable: true,
      writable: true,
      value: function value(sectionId) {
        _this.props.clearCheckboxSection(sectionId);
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(CheckboxGroupList, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          checkboxGroups = _props.checkboxGroups,
          translations = _props.translations,
          stylingConfig = _props.stylingConfig;

      return _react2.default.createElement(
        _Section2.default,
        null,
        checkboxGroups.map(function (section) {
          return _react2.default.createElement(
            'div',
            { key: section.id },
            _react2.default.createElement(
              _SubSection2.default,
              null,
              section.titleKey && _react2.default.createElement(
                _SectionTitle2.default,
                { hasBorder: true },
                translations[section.titleKey]
              ),
              _react2.default.createElement(_CheckboxGroup2.default, {
                id: 'any',
                handleClick: function handleClick() {
                  return _this2.handleUncheckList(section.id);
                },
                value: translations.checkboxAnySelection,
                isChecked: !section.checkboxes.some(function (el) {
                  return el.isChecked;
                }),
                isDisabled: false,
                isCheckedColour: stylingConfig.checkboxSelectedColour && stylingConfig.checkboxSelectedColour.value
              }),
              section.checkboxes.map(function (checkbox) {
                return _react2.default.createElement(_CheckboxGroup2.default, {
                  id: checkbox.id,
                  key: checkbox.value,
                  handleClick: _this2.handleCheckboxClick,
                  value: checkbox.value,
                  isChecked: checkbox.isChecked,
                  isDisabled: checkbox.isDisabled,
                  isCheckedColour: stylingConfig.checkboxSelectedColour && stylingConfig.checkboxSelectedColour.value
                });
              })
            )
          );
        })
      );
    }
  }]);
  return CheckboxGroupList;
}(_react.Component);

exports.default = CheckboxGroupList;