'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    padding: 0 5%;\n    width: 90%;\n    margin-bottom: 100px;\n  '], ['\n    display: flex;\n    padding: 0 5%;\n    width: 90%;\n    margin-bottom: 100px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 30%;\n  '], ['\n    width: 30%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  width: 70%;\n  display: flex;\n  flex-flow: row wrap;\n'], ['\n  width: 70%;\n  display: flex;\n  flex-flow: row wrap;\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 33.3%;\n    flex: 0 1 33.3%;\n    border: 5px solid transparent;\n    box-sizing: border-box;\n    margin: 0;\n  '], ['\n    max-width: 33.3%;\n    flex: 0 1 33.3%;\n    border: 5px solid transparent;\n    box-sizing: border-box;\n    margin: 0;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    min-height: 200px;\n  '], ['\n    min-height: 200px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    min-height: 300px;\n  '], ['\n    min-height: 300px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    min-height: 250px;\n  '], ['\n    min-height: 250px;\n  ']);

exports.default = Performance;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'PerfContainer'
}).withConfig({
  displayName: 'Performance__Container',
  componentId: 'sc-o6j6xx-0'
})(['width:100%;display:block;', ';'], _theme2.default.min.large(_templateObject));
var InfoBox = _styledComponents2.default.div.withConfig({
  displayName: 'Performance__InfoBox',
  componentId: 'sc-o6j6xx-1'
})(['width:100%;display:block;color:#444;font-size:18px;', ';'], _theme2.default.min.large(_templateObject2));

var Engines = _styledComponents2.default.div.withConfig({
  displayName: 'Performance__Engines',
  componentId: 'sc-o6j6xx-2'
})(['width:100%;display:block;', ';'], _theme2.default.min.large(_templateObject3));
var Engine = _styledComponents2.default.div.withConfig({
  displayName: 'Performance__Engine',
  componentId: 'sc-o6j6xx-3'
})(['width:100%;margin-bottom:20px;', ';'], _theme2.default.min.large(_templateObject4));
var EngineImage = _styledComponents2.default.div.withConfig({
  displayName: 'Performance__EngineImage',
  componentId: 'sc-o6j6xx-4'
})(['height:180px;background-size:cover;background-repeat:no-repeat;background-position:center;background-image:', ';width:100%;', ';', ';', ';margin-bottom:24px;'], function (props) {
  return '' + (props.url && 'url(' + props.url + ')');
}, _theme2.default.min.small(_templateObject5), _theme2.default.min.medium(_templateObject6), _theme2.default.min.large(_templateObject7));

function Performance(_ref) {
  var performance = _ref.performance,
      translations = _ref.translations;

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      InfoBox,
      null,
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n            margin-right: 25px;\n          ';
          }
        },
        translations.performanceContent
      )
    ),
    _react2.default.createElement(
      Engines,
      null,
      performance.engines.map(function (e, i) {
        return _react2.default.createElement(
          Engine,
          { key: e.name },
          _react2.default.createElement(EngineImage, { url: e.imageUrl }),
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n                margin-bottom: 15px;\n                text-transform: uppercase;\n                font-size: 15px;\n              ';
              }
            },
            translations['performanceEngine' + (i + 1) + 'Name']
          ),
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n                font-size: 13px;\n              ';
              }
            },
            translations['performanceEngine' + (i + 1) + 'Description']
          )
        );
      })
    )
  );
}