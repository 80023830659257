'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _vehicleTypes = require('../../../types/AstonMartin/vehicleTypes');

var _Global = require('../../Global');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitle__IconWrapper',
  componentId: 'sc-106sphi-0'
})(['display:flex;align-items:center;']);

var VehicleTitle = function VehicleTitle(_ref) {
  var vehicle = _ref.vehicle,
      config = _ref.config,
      onClick = _ref.onClick;

  var mobile = (0, _useCheckIsMobileScreen2.default)();

  return _react2.default.createElement(
    'div',
    {
      style: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '15px'
      }
    },
    _react2.default.createElement(
      'div',
      { onClick: onClick },
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n              font-family: \'AstonMartinFlare-Regular\';\n              font-size: 18px;\n              color: #000000;\n              text-transform: none;\n              cursor: pointer;\n              :hover {\n                text-decoration: underline;\n              }\n              :active {\n                text-decoration: underline;\n              }\n            ';
          }
        },
        vehicle.name
      )
    ),
    vehicle.approved && _react2.default.createElement(
      IconWrapper,
      { 'data-cy': 'shortlist-vehicle-button' },
      _react2.default.createElement('img', {
        src: mobile ? config.timelessLogoMobile : config.timelessLogoDesktop,
        style: mobile ? { width: '80px', height: '26px' } : { width: '100px', height: '32px' },
        alt: ''
      })
    )
  );
};

exports.default = VehicleTitle;