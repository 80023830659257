'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCurrencySelector = undefined;

var _reactRedux = require('react-redux');

var _sessionPreferences = require('../shared/sessionPreferences');

var _currencyConversion = require('../shared/currencyConversion');

var useCurrencySelector = exports.useCurrencySelector = function useCurrencySelector() {
  var dispatch = (0, _reactRedux.useDispatch)();

  var onCurrencyChange = function onCurrencyChange(value) {
    dispatch(_sessionPreferences.actions.updateSessionPreferences('currency', value));
    if (value) {
      dispatch(_currencyConversion.actions.getExchangeRates(value));
    } else {
      dispatch(_currencyConversion.actions.clearExchangeRates());
    }
  };

  return {
    onCurrencyChange: onCurrencyChange
  };
};

exports.default = useCurrencySelector;