'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RetailerMap = require('../../../components/Genesis/RetailerMap');

var _RetailerMap2 = _interopRequireDefault(_RetailerMap);

var _Global = require('../../../components/Global');

var _pathBuilders = require('../../../path-builders');

var _filters = require('../../../shared/filters');

var _location = require('../../../shared/location');

var _searchFilter = require('../../../shared/searchFilter');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RetailerMapWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerLocationSearch__RetailerMapWrapper',
  componentId: 'sc-q7s2za-0'
})(['background-color:#111;', ';'], function (_ref) {
  var hasUserCentrics = _ref.hasUserCentrics;
  return hasUserCentrics && '.uc-embedding-container {\n      background-image: url("https://res.cloudinary.com/motortrak/image/upload/e_blur:300/v1678808033/locator/bentley/content/locator/dealer_locator.png");     background-position: center;\n      background-repeat: no-repeat;\n      background-size: cover;\n      min-height: 600px  \n      }';
});

var NoMaps = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerLocationSearch__NoMaps',
  componentId: 'sc-q7s2za-1'
})(['background-color:#111;display:flex;margin:50px;justify-content:space-around;']);

var RetailerLocationSearch = function (_Component) {
  (0, _inherits3.default)(RetailerLocationSearch, _Component);

  function RetailerLocationSearch() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, RetailerLocationSearch);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = RetailerLocationSearch.__proto__ || (0, _getPrototypeOf2.default)(RetailerLocationSearch)).call.apply(_ref2, [this].concat(args))), _this), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var actions = _this.props.actions;

        if (_this.props.shared.location.set === true) {
          actions.getRetailers({
            lat: _this.props.shared.location.lat,
            lng: _this.props.shared.location.lng
          });
        } else {
          actions.getRetailers({ forceUpdate: true });
        }
      }
    }), Object.defineProperty(_this, 'componentDidUpdate', {
      enumerable: true,
      writable: true,
      value: function value(prevProps) {
        var _this$props = _this.props,
            actions = _this$props.actions,
            shared = _this$props.shared,
            marketInfo = _this$props.marketInfo;

        if (prevProps.shared.sessionPreferences.market !== shared.sessionPreferences.market || prevProps.marketInfo.territory !== marketInfo.territory) {
          actions.getRetailers({ forceUpdate: true });
        }
      }
    }), Object.defineProperty(_this, 'returnPlace', {
      enumerable: true,
      writable: true,
      value: function value(position) {
        var actions = _this.props.actions;

        actions.getRetailers({
          lat: typeof position.lat === 'function' ? position.lat() : position.lat,
          lng: typeof position.lng === 'function' ? position.lng() : position.lng
        });
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(RetailerLocationSearch, [{
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      var dispatch = this.props.dispatch;

      dispatch(_location.actions.clearPreferredLocation());
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          dispatch = _props.dispatch,
          pathByModule = _props.pathByModule,
          actions = _props.actions,
          config = _props.config,
          globalStyling = _props.globalStyling,
          state = _props.state,
          _props$shared = _props.shared,
          googleAvailable = _props$shared.google.googleAvailable,
          language = _props$shared.sessionPreferences.language,
          translations = _props.config.translations,
          featureFlags = _props.featureFlags,
          maps = _props.maps,
          analytics = _props.analytics;


      var goToModule = function goToModule(name, retailer) {
        var path = (0, _pathBuilders.pathWithDealerID)(pathByModule(name), retailer.name, config.specialDealerRedirection ? retailer.oemId : retailer.id, language, config.specialDealerRedirection && featureFlags.dealerUrl);

        window.open(window.location.origin + '/' + path, '_blank');
      };

      var resetFilters = function resetFilters() {
        dispatch(_searchFilter.actions.resetFilters());
        dispatch(_filters.actions.resetFilters());
      };

      return googleAvailable ? _react2.default.createElement(
        RetailerMapWrapper,
        {
          hasUserCentrics: analytics && !!analytics.userCentricsKey,
          userCentricsMapDefaultImg: analytics && analytics.userCentricsDealerMapBackground ? analytics.userCentricsDealerMapBackground : 'none'
        },
        _react2.default.createElement(_RetailerMap2.default, {
          inputBoxOverride: config.inputBoxFont,
          infoWindowButtonType: config.infoWindowButtonType,
          searchCountryCode: config.searchCountryCode,
          globalStyling: globalStyling,
          loadingElement: _react2.default.createElement('div', { style: { height: '100%' } }),
          containerElement: _react2.default.createElement('div', { style: { height: '600px' } }),
          retailers: state.retailers,
          translations: translations,
          defaultCenter: config.mapCentreLocation,
          centreLocation: state.centreLocation,
          returnPlace: this.returnPlace,
          viewStockAction: function viewStockAction(retailer) {
            resetFilters();
            goToModule(config.searchModule || 'SearchResults', retailer);
          },
          zoomChangedAction: actions.getRetailers,
          locationIconColour: config.locationIconColour,
          pin: config.pin,
          mapSearchBoxColour: config.mapSearchBoxColour,
          mapZoomLevel: config.mapZoomLevel,
          headerFont: config.headerFont,
          flipCoordinates: config.flipCoordinates,
          loadingNearest: state.loadingNearest,
          config: config,
          googleAvailable: googleAvailable,
          mapStyles: config.mapStyles,
          mapKey: maps.apiKey,
          dispatch: this.props.dispatch
        })
      ) : _react2.default.createElement(
        NoMaps,
        null,
        _react2.default.createElement(
          _Global.HeadingTwo,
          null,
          translations.mapsUnavailable
        )
      );
    }
  }]);
  return RetailerLocationSearch;
}(_react.Component);

exports.default = RetailerLocationSearch;