'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _useVehicleGallery2 = require('../../../../hooks/useVehicleGallery');

var _Carousel = require('../../../Carousel');

var _Carousel2 = _interopRequireDefault(_Carousel);

var _YouTubePlayer = require('../../../YouTubePlayer');

var _YouTubePlayer2 = _interopRequireDefault(_YouTubePlayer);

var _parseYouTubeId = require('../../../YouTubePlayer/parseYouTubeId');

var _parseYouTubeId2 = _interopRequireDefault(_parseYouTubeId);

var _ImgCollage = require('../../ImgCollage');

var _ImgCollage2 = _interopRequireDefault(_ImgCollage);

var _ModalOverlay = require('./ModalOverlay');

var _ModalOverlay2 = _interopRequireDefault(_ModalOverlay);

var _VehicleZoomGallery = require('..//VehicleZoomGallery');

var _VehicleZoomGallery2 = _interopRequireDefault(_VehicleZoomGallery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VehicleGallery = function VehicleGallery(props) {
  var _props$config = props.config,
      config = _props$config === undefined ? {
    translations: {}
  } : _props$config,
      placeholdingImage = props.placeholdingImage,
      vehicleInfo = props.vehicleInfo,
      globalStyling = props.globalStyling,
      translations = props.translations,
      vehicleImages = props.vehicleImages;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      isVideoModalOpen = _useState2[0],
      setIsVideoModalOpen = _useState2[1];

  var parsedYouTubeId = vehicleInfo.video ? (0, _parseYouTubeId2.default)(vehicleInfo.video) : null;

  var slides = [].concat((0, _toConsumableArray3.default)(vehicleImages.slides));
  var benefitImageUrl = translations.benefitsImageUrl;
  if (benefitImageUrl) {
    slides.push({ id: benefitImageUrl, image: benefitImageUrl });
  }

  var _useVehicleGallery = (0, _useVehicleGallery2.useVehicleGallery)({
    rotateGallery: props.rotateGallery,
    direction: props.globalStyling.direction,
    slides: slides,
    placeholdingImage: placeholdingImage
  }),
      isOpen = _useVehicleGallery.isOpen,
      openLightbox = _useVehicleGallery.openLightbox,
      closeLightbox = _useVehicleGallery.closeLightbox;

  var carouselProps = {
    slides: slides.map(function (slide) {
      return (0, _extends3.default)({}, slide, { component: _Carousel2.default });
    }),
    vehicleInfo: vehicleInfo
  };

  return _react2.default.createElement(
    _react.Fragment,
    null,
    isOpen && _react2.default.createElement(
      _ModalOverlay2.default,
      {
        close: closeLightbox,
        closeText: translations.returnToDetails,
        config: config,
        modalWidth: '100%',
        direction: globalStyling.direction
      },
      _react2.default.createElement(_VehicleZoomGallery2.default, carouselProps)
    ),
    isVideoModalOpen && _react2.default.createElement(
      _ModalOverlay2.default,
      {
        close: function close() {
          return setIsVideoModalOpen(false);
        },
        config: config,
        modalWidth: '80%',
        direction: globalStyling.direction
      },
      _react2.default.createElement(_YouTubePlayer2.default, {
        src: parsedYouTubeId,
        autoplay: false,
        height: 'calc(100vw * 0.56)'
      })
    ),
    _react2.default.createElement(_ImgCollage2.default, {
      onClick: openLightbox,
      images: vehicleInfo.images,
      config: config,
      video: parsedYouTubeId,
      videoOnClick: function videoOnClick() {
        setIsVideoModalOpen(true);
      },
      imageAltText: vehicleInfo.name,
      translations: translations,
      views: vehicleInfo.views,
      isVDP: true,
      modalOpen: isOpen
    })
  );
};

exports.default = VehicleGallery;