'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _RollsRoyceJourneyGrid = require('./RollsRoyceJourneyGrid');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_RollsRoyceJourneyGrid).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'RollsRoyceJourneyGrid';
var id = exports.id = 'RollsRoyceJourneyGrid';