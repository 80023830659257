'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 40px;\n  '], ['\n    margin-top: 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 40px;\n    display: grid;\n    grid-template-columns: 49% 49%;\n    grid-gap: 20px;\n  '], ['\n    margin-top: 40px;\n    display: grid;\n    grid-template-columns: 49% 49%;\n    grid-gap: 20px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: grid;\n    grid-template-columns: 100%;\n    grid-gap: initial;\n  '], ['\n    display: grid;\n    grid-template-columns: 100%;\n    grid-gap: initial;\n  ']);

exports.default = VehicleResults;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _searchResultsTypes = require('../../../types/RollsRoyce/searchResultsTypes');

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

var _VehicleCard = require('../VehicleCard');

var _AppShared = require('../../../types/AppShared');

var _NoResults = require('../../../components/RollsRoyce/VehicleSearchResults/NoResults');

var _NoResults2 = _interopRequireDefault(_NoResults);

var _Spinner = require('../../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _Pagination = require('./Pagination');

var _Pagination2 = _interopRequireDefault(_Pagination);

var _SimilarCars = require('../../../components/RollsRoyce/VehicleSearchResults/SimilarCars');

var _SimilarCars2 = _interopRequireDefault(_SimilarCars);

var _useDealerInfo2 = require('../../../hooks/useDealerInfo');

var _useDealerInfo3 = _interopRequireDefault(_useDealerInfo2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ResultsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleResults__ResultsWrapper',
  componentId: 'sc-vaogmu-0'
})(['']);

var Results = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleResults__Results',
  componentId: 'sc-vaogmu-1'
})(['margin-top:30px;width:100%;', ';', ';', ';'], _RollsRoyce2.default.min.medium(_templateObject), _RollsRoyce2.default.min.large(_templateObject2), _RollsRoyce2.default.min.extraLarge(_templateObject3));

var SpinnerSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleResults__SpinnerSection',
  componentId: 'sc-vaogmu-2'
})(['display:flex;justify-content:center;width:100%;height:80px;']);

var resultsToDisplay = function resultsToDisplay(results, currentPage, infiniteScroll) {
  return infiniteScroll ? (0, _entries2.default)(results).sort(function (a, b) {
    return parseInt(a[0], 10) - parseInt(b[0], 10);
  }).reduce(function (previous, entry) {
    return [].concat((0, _toConsumableArray3.default)(previous), (0, _toConsumableArray3.default)(entry[1]));
  }, []) : results[currentPage];
};

var totalResultsLoaded = function totalResultsLoaded(results) {
  return (0, _values2.default)(results).reduce(function (previous, value) {
    return [].concat((0, _toConsumableArray3.default)(previous), (0, _toConsumableArray3.default)(value));
  }, []).length;
};

function VehicleResults(_ref) {
  var config = _ref.config,
      results = _ref.results,
      onMoreInfoClick = _ref.onMoreInfoClick,
      onEnquireClick = _ref.onEnquireClick,
      translations = _ref.translations,
      locale = _ref.locale,
      onPageNumberChange = _ref.onPageNumberChange,
      currentPage = _ref.currentPage,
      pageSize = _ref.pageSize,
      totalResults = _ref.totalResults,
      getSimilarCars = _ref.getSimilarCars,
      shared = _ref.shared,
      globalStyling = _ref.globalStyling,
      onShowAllResultsClick = _ref.onShowAllResultsClick,
      infiniteScroll = _ref.infiniteScroll,
      handleZeroPriceAsText = _ref.handleZeroPriceAsText;

  var vehicles = totalResults ? resultsToDisplay(results, currentPage, infiniteScroll) : [];

  var _useDealerInfo = (0, _useDealerInfo3.default)(),
      isDealer = _useDealerInfo.isDealer;

  return totalResults ? _react2.default.createElement(
    ResultsWrapper,
    null,
    _react2.default.createElement(
      Results,
      null,
      vehicles.map(function (vehicle) {
        return _react2.default.createElement(_VehicleCard.VehicleItem, {
          config: config,
          key: vehicle.id,
          translations: translations,
          onMoreInfoClick: onMoreInfoClick,
          onEnquireClick: onEnquireClick,
          locale: locale,
          vehicle: vehicle,
          shared: shared,
          handleZeroPriceAsText: handleZeroPriceAsText
        });
      })
    ),
    !infiniteScroll ? _react2.default.createElement(_Pagination2.default, {
      pageSize: pageSize,
      currentPage: currentPage,
      totalResults: totalResults,
      onShowAllResultsClick: onShowAllResultsClick,
      onPageNumberChange: onPageNumberChange,
      translations: translations,
      globalStyling: globalStyling
    }) : totalResults > totalResultsLoaded(results) && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        SpinnerSection,
        null,
        _react2.default.createElement(_Spinner2.default, { colour: globalStyling.colours.primaryBrandColour })
      )
    )
  ) : _react2.default.createElement(
    ResultsWrapper,
    null,
    _react2.default.createElement(
      Results,
      null,
      _react2.default.createElement(_NoResults2.default, { text: translations.noSearchResults }),
      !isDealer && _react2.default.createElement(_SimilarCars2.default, {
        locale: locale,
        translations: translations,
        config: config,
        getSimilarCars: getSimilarCars,
        onMoreInfoClick: onMoreInfoClick,
        onEnquireClick: onEnquireClick,
        shared: shared,
        handleZeroPriceAsText: handleZeroPriceAsText
      })
    )
  );
}