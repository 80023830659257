'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Spinner = require('../../components/Spinner');

var _useModelSearch2 = require('./useModelSearch');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ModelSearchWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__ModelSearchWrapper',
  componentId: 'sc-lbqmn3-0'
})(['padding:20px 0;height:100%;width:100%;']);

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      template = _ref$config.template,
      config = (0, _objectWithoutProperties3.default)(_ref$config, ['template']),
      globalStyling = _ref.globalStyling;

  var _useModelSearch = (0, _useModelSearch2.useModelSearch)(template),
      TileComponent = _useModelSearch.TileComponent,
      modelGroups = _useModelSearch.modelGroups,
      selectedModels = _useModelSearch.selectedModels,
      selectedVariants = _useModelSearch.selectedVariants,
      onModelTileClick = _useModelSearch.onModelTileClick,
      onVariantTileClick = _useModelSearch.onVariantTileClick,
      loaded = _useModelSearch.loaded;

  // When a model group is NOT visible, means that model group is
  // merged into another model group and does not need to be displayed for selection.


  var visibleModelGroups = modelGroups.filter(function (v) {
    return v.visible === undefined || v.visible !== undefined && v.visible !== false;
  });

  return _react2.default.createElement(
    _Spinner.SpinnerWrapper,
    { loaded: loaded, globalStyling: globalStyling },
    function () {
      return _react2.default.createElement(
        ModelSearchWrapper,
        null,
        visibleModelGroups.map(function (mg) {
          return _react2.default.createElement(TileComponent, {
            key: mg.modelGroupName,
            modelGroup: mg,
            config: config,
            onModelTileClick: onModelTileClick,
            onVariantTileClick: onVariantTileClick,
            selectedModels: selectedModels,
            selectedVariants: selectedVariants
          });
        })
      );
    }
  );
};