'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Location = undefined;
exports.default = LocationFilter;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

var _UserLocation = require('../../components/UserLocation');

var _UserLocation2 = _interopRequireDefault(_UserLocation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LocationFilter__ContentWrapper',
  componentId: 'sc-1s6fb17-0'
})(['padding:16px;']);

var HeadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LocationFilter__HeadingWrapper',
  componentId: 'sc-1s6fb17-1'
})(['padding-bottom:8px;']);

var Location = exports.Location = function Location(props) {
  var updateSessionPreferences = props.updateSessionPreferences,
      updateFilters = props.updateFilters,
      _props$location = props.location,
      latitude = _props$location.latitude,
      longitude = _props$location.longitude,
      searchCountryCode = props.searchCountryCode;


  var handleLocationChange = (0, _react.useCallback)(function (lat, long) {
    updateSessionPreferences('location', {
      latitude: lat,
      longitude: long
    });
    updateFilters({
      key: 'location',
      value: {
        latitude: lat,
        longitude: long
      }
    });
  }, [updateSessionPreferences]);

  return _react2.default.createElement(
    'div',
    null,
    props.translations.yourLocation && _react2.default.createElement(
      HeadingWrapper,
      null,
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return props.filtersStyling.filterHeadingFont;
          }
        },
        props.translations.yourLocation
      )
    ),
    _react2.default.createElement(_UserLocation2.default, {
      direction: props.globalStyling.direction,
      placeholderText: props.translations.locationInputPlaceholder,
      iconUrl: props.filtersStyling.filterLocationIcon,
      browserLocationCallback: handleLocationChange,
      inputLocationCallback: handleLocationChange,
      options: {
        latitude: latitude,
        longitude: longitude,
        countryCode: searchCountryCode
      }
    })
  );
};

function LocationFilter(props) {
  return _react2.default.createElement(
    ContentWrapper,
    null,
    _react2.default.createElement(Location, props)
  );
}