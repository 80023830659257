'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _VehicleSpecifications = require('./VehicleSpecifications');

var _VehicleSpecifications2 = _interopRequireDefault(_VehicleSpecifications);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _VehicleSpecifications2.default;