'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    gap:40px;\n  '], ['\n    gap:40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    gap:60px;\n  '], ['\n    flex-direction: column;\n    gap:60px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 25px;\n    height: 25px;\n  '], ['\n    width: 25px;\n    height: 25px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50px;\n    height: 50px;\n  '], ['\n    width: 50px;\n    height: 50px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 16px;\n    line-height: 22px;\n  '], ['\n    font-size: 16px;\n    line-height: 22px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 22px;\n    line-height: 30px;\n  '], ['\n    font-size: 22px;\n    line-height: 30px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 12px;\n    line-height: 16px;\n  '], ['\n    font-size: 12px;\n    line-height: 16px;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 14px;\n    line-height: 19px;\n  '], ['\n    font-size: 14px;\n    line-height: 19px;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 24px;\n    line-height: 33px;\n  '], ['\n    font-size: 24px;\n    line-height: 33px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicleDetailsPageTypes = require('../../types/Bentley/vehicleDetailsPageTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BackgroundImage = _styledComponents2.default.div.withConfig({
  displayName: 'WhyCertified__BackgroundImage',
  componentId: 'sc-1tvhw8p-0'
})(['display:flex;align-items:center;justify-content:center;flex-direction:column;cursor:pointer;background-image:url(', ');background-size:cover;background-position:center;width:100%;padding:40px 0;'], function (props) {
  return props.src;
});

var IconRow = _styledComponents2.default.div.withConfig({
  displayName: 'WhyCertified__IconRow',
  componentId: 'sc-1tvhw8p-1'
})(['display:flex;flex-direction:row;justify-content:space-between;padding:0 20px;margin-top:20px;gap:60px;font-family:Bentley-Regular;color:white;max-width:1200px;', ';', ';'], _theme2.default.max.large(_templateObject), _theme2.default.max.medium(_templateObject2));

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'WhyCertified__IconWrapper',
  componentId: 'sc-1tvhw8p-2'
})(['display:flex;flex-direction:column;align-items:center;']);

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'WhyCertified__Icon',
  componentId: 'sc-1tvhw8p-3'
})(['margin-top:20px;width:50px;height:50px;', ' ', ''], _theme2.default.max.large(_templateObject3), _theme2.default.max.medium(_templateObject4));

var IconTitle = _styledComponents2.default.div.withConfig({
  displayName: 'WhyCertified__IconTitle',
  componentId: 'sc-1tvhw8p-4'
})(['font-style:normal;font-weight:400;font-size:22px;line-height:30px;margin-top:10px;text-align:center;', ';', ';'], _theme2.default.max.large(_templateObject5), _theme2.default.max.medium(_templateObject6));

var IconText = _styledComponents2.default.div.withConfig({
  displayName: 'WhyCertified__IconText',
  componentId: 'sc-1tvhw8p-5'
})(['margin-top:10px;text-align:center;font-weight:300;font-size:14px;line-height:19px;', ';', ';'], _theme2.default.max.large(_templateObject7), _theme2.default.max.medium(_templateObject8));

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'WhyCertified__Title',
  componentId: 'sc-1tvhw8p-6'
})(['font-style:normal;font-weight:400;font-size:24px;line-height:33px;font-family:Bentley-Regular;text-transform:uppercase;color:white;text-align:center;', ' ', ''], _theme2.default.max.large(_templateObject6), _theme2.default.max.medium(_templateObject9));

var WhyCertified = function WhyCertified(_ref) {
  var config = _ref.config,
      translations = _ref.translations;
  return _react2.default.createElement(
    BackgroundImage,
    { src: config.whyBentleyBackground, id: 'WhyCertified' },
    _react2.default.createElement(
      Title,
      null,
      translations.whyCertified
    ),
    _react2.default.createElement(
      IconRow,
      null,
      _react2.default.createElement(
        IconWrapper,
        null,
        _react2.default.createElement(Icon, { src: config.whyBentleyTick }),
        _react2.default.createElement(
          IconTitle,
          null,
          translations.whyCertifiedWarranty
        ),
        _react2.default.createElement(
          IconText,
          null,
          translations.whyCertifiedWarrantyText
        )
      ),
      _react2.default.createElement(
        IconWrapper,
        null,
        _react2.default.createElement(Icon, { src: config.whyBentleyTick }),
        _react2.default.createElement(
          IconTitle,
          null,
          translations.whyCertifiedQuality
        ),
        _react2.default.createElement(
          IconText,
          null,
          translations.whyCertifiedQualityText
        )
      ),
      _react2.default.createElement(
        IconWrapper,
        null,
        _react2.default.createElement(Icon, { src: config.whyBentleyTick }),
        _react2.default.createElement(
          IconTitle,
          null,
          translations.whyCertifiedService
        ),
        _react2.default.createElement(
          IconText,
          null,
          translations.whyCertifiedServiceText
        )
      )
    )
  );
};

exports.default = WhyCertified;