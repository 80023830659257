'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCompareVehicle2 = require('../../hooks/useCompareVehicle');

var _vehicleTypes = require('../../types/AstonMartin/vehicleTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CompareButton__IconWrapper',
  componentId: 'sc-l5h9om-0'
})(['display:flex;align-items:center;cursor:pointer;']);

var CompareButton = function CompareButton(_ref) {
  var vehicle = _ref.vehicle,
      iconOn = _ref.iconOn,
      iconOff = _ref.iconOff;

  var _useCompareVehicle = (0, _useCompareVehicle2.useCompareVehicle)({
    vehicleId: vehicle.id
  }),
      isCompared = _useCompareVehicle.isCompared,
      toggleCompare = _useCompareVehicle.toggleCompare;

  return _react2.default.createElement(
    IconWrapper,
    {
      'data-qa-hook': 'shortlist-vehicle-button',
      onClick: function onClick() {
        return toggleCompare(vehicle);
      }
    },
    _react2.default.createElement('img', {
      src: isCompared ? iconOn : iconOff,
      style: { width: '40px', height: '40px' },
      alt: ''
    })
  );
};

exports.default = CompareButton;