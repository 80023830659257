'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _config = require('../../helpers/config');

var _SectionIcons = require('../../helpers/SectionIcons');

var _SectionIcons2 = _interopRequireDefault(_SectionIcons);

var _HeroCarousel = require('./HeroCarousel');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var slideContentStyleOptions = (0, _keys2.default)(_HeroCarousel.slideContentStyles);
var paginationStyleOptions = (0, _keys2.default)(_HeroCarousel.paginationStyles);

var hintText = function hintText() {
  return '\n  Please note that all images for this Module will be transformed to a resolution of 1366 x 600 pixels.\n  To optimise the display of images they should be as close to this resolution as possible.\n  ';
};

var config = (0, _config.Config)('NewHeroCarousel', { id: 'newHeroCarousel' }, (0, _config.Slice)('Slides', { id: 'slide-options' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.SideCarousel }, (0, _config.Toggle)('Slides loop', 'slidesLoopEnabled'), (0, _config.Number)('Slide transition speed', 'slideTransitionSpeed', {
  inputLabel: 'sec'
}), (0, _config.Pixels)('Padding Vertical', 'paddingVertical'), (0, _config.Pixels)('Padding Horizontal', 'paddingHorizontal'))), (0, _config.ListSlice)('Content', {
  id: 'items',
  max: 8,
  sectionLabel: 'Slide',
  buttonLabel: 'Add new slide',
  icon: _SectionIcons2.default.HeroCarousel,
  focus: 'displaySlide'
}, (0, _config.AlignLeftRight)('Align', 'align'), (0, _config.Image)('Image', 'mediaContent', { hintText: hintText }), (0, _config.PageAndExternalLink)('Slide URL', 'url'), (0, _config.TextArea)('Header Text', 'header'), (0, _config.TextArea)('Body Text', 'body'), (0, _config.Link)('Button', 'topLink'), (0, _config.Link)('Button', 'midLink'), (0, _config.Link)('Button', 'bottomLink')), (0, _config.Slice)('Styling', { id: 'styling' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.SideCarousel }, (0, _config.Font)('Header text', 'slideHeaderFont'), (0, _config.Colour)('Header Background Colour', 'headerBGColour'), (0, _config.Font)('Body text', 'slideBodyFont'), (0, _config.Colour)('Body Background Colour', 'bodyBGColour'), (0, _config.Font)('Button text', 'slideButtonFont'), (0, _config.Colour)('Button Colour', 'buttonBGColour'), (0, _config.Styles)('Slide Styles', 'slideContentStyle', {
  options: slideContentStyleOptions
}), (0, _config.Styles)('Pagination Styles', 'paginationStyle', {
  options: paginationStyleOptions
}))));

exports.default = config;