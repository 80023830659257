'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Global = require('../../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VehicleSubTitle = function VehicleSubTitle(_ref) {
  var vehicleBodyStyle = _ref.vehicleBodyStyle,
      vehicleFuelType = _ref.vehicleFuelType,
      vehicleTransmission = _ref.vehicleTransmission;

  return _react2.default.createElement(
    _Global.HeadingThree,
    {
      styleOverride: function styleOverride() {
        return '\n      font-family: \'GenesisSansHeadGlobal-Regular\';\n      text-transform: uppercase;\n      font-size: 14px;\n      color: #fff;\n      margin-bottom: 10px;\n    ';
      }
    },
    vehicleBodyStyle,
    ' | ',
    vehicleFuelType,
    ' | ',
    vehicleTransmission
  );
};

exports.default = VehicleSubTitle;