'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Spinner;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _globalStyling = require('../../types/globalStyling');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var spin = (0, _styledComponents.keyframes)(['from{transform:rotate(0deg);}to{transform:rotate(360deg);}']);

var AnimatedSpinner = _styledComponents2.default.div.withConfig({
  displayName: 'Spinner__AnimatedSpinner',
  componentId: 'sc-rf56o4-0'
})(['border:4px solid #d8d8d8;border-radius:50%;border-top:', ';width:', ';height:', ';animation:', ' 1s linear infinite;'], function (_ref) {
  var colour = _ref.colour;
  return '4px solid ' + colour.value;
}, function (_ref2) {
  var size = _ref2.size;
  return size + 'px';
}, function (_ref3) {
  var size = _ref3.size;
  return size + 'px';
}, spin);

function Spinner(_ref4) {
  var size = _ref4.size,
      colour = _ref4.colour;

  return _react2.default.createElement(AnimatedSpinner, { size: size, colour: colour, 'aria-label': 'Loading...' });
}

Spinner.defaultProps = {
  size: 20
};