'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _DealerNotFound = require('./DealerNotFound');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_DealerNotFound).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var id = exports.id = 'DealerNotFound';
var description = exports.description = 'Dealer Not Found Module';