'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _filters = require('../../../../shared/filters');

var _Checkbox = require('../../Form/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ItemRow = _styledComponents2.default.div.withConfig({
  displayName: 'MultiSelectFilter__ItemRow',
  componentId: 'sc-u0bzyt-0'
})(['height:auto;', ';padding:5px 20px;font:14px Bentley-Light;background-color:#f7f7f7;color:#333333;&:first-child{padding-top:20px;}&:last-child{padding-bottom:20px;}display:flex;align-items:center;text-transform:none;', ';'], function (_ref) {
  var disabledStyle = _ref.disabledStyle;
  return 'cursor: ' + (disabledStyle ? 'default' : 'pointer');
}, function (_ref2) {
  var disabledStyle = _ref2.disabledStyle;
  return disabledStyle && 'opacity: .3';
});

var Selection = (0, _styledComponents2.default)(ItemRow).withConfig({
  displayName: 'MultiSelectFilter__Selection',
  componentId: 'sc-u0bzyt-1'
})(['justify-content:space-between;', ';'], function (_ref3) {
  var disabledStyle = _ref3.disabledStyle;
  return !disabledStyle && '&:hover {\n      text-decoration: underline;\n    }';
});

var MultiSelectFilter = function MultiSelectFilter(props) {
  var filterKey = props.filterKey,
      data = props.data,
      selectedData = props.selectedData,
      updateFilters = props.updateFilters,
      getDisabledValue = props.getDisabledValue,
      _props$noResultsLabel = props.noResultsLabel,
      noResultsLabel = _props$noResultsLabel === undefined ? '' : _props$noResultsLabel,
      hideVehicleCounts = props.hideVehicleCounts;


  var filterContent = data.sort();

  return _react2.default.createElement(
    'div',
    null,
    filterContent.map(function (d) {
      var itemSelected = selectedData && selectedData.some(function (sd) {
        return sd === d.value.toString();
      });
      var isDisabled = getDisabledValue(d);

      return _react2.default.createElement(
        Selection,
        {
          key: d.value,
          'data-cy': d.display,
          disabledStyle: isDisabled,
          onClick: function onClick() {
            return !isDisabled && updateFilters([{
              key: filterKey,
              value: _filters.helpers.newArray(selectedData || [], d.value.toString())
            }]);
          }
        },
        _react2.default.createElement(
          'div',
          { style: { display: 'flex', flexDirection: 'row' } },
          _react2.default.createElement(_Checkbox2.default, {
            onClick: function onClick() {
              return null;
            } // Selection is performed on parent's.
            , checked: itemSelected,
            enabled: !isDisabled
          }),
          _react2.default.createElement(
            'span',
            { style: { marginLeft: '10px', marginRight: '10px' } },
            d.display
          )
        ),
        !hideVehicleCounts && _react2.default.createElement(
          'div',
          { style: { color: '#94C11D' } },
          '(',
          d.selectedCount || noResultsLabel,
          ')'
        )
      );
    })
  );
};

MultiSelectFilter.defaultProps = {
  applyOrdering: function applyOrdering(a, b) {
    if (a.display < b.display) {
      return -1;
    }
    if (a.display > b.display) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }
};

exports.default = MultiSelectFilter;