'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    left: 26px;\n    ', '\n  '], ['\n    left: 26px;\n    ', '\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n     padding: 15px 0 0 26px;\n  '], ['\n     padding: 15px 0 0 26px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _settings = require('../../../shared/selectors/settings');

var _globalStyling = require('../../../types/globalStyling');

var _styledComponentFactory = require('../../Global/styledComponentFactory');

var _FieldValidationError = require('./FieldValidationError');

var _FieldValidationError2 = _interopRequireDefault(_FieldValidationError);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _InputAdornment = require('./InputAdornment');

var _InputAdornment2 = _interopRequireDefault(_InputAdornment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/** Creating input with createElement to be able to use:
 * styleOverride?: () => string;
 * mobileStyleOverride?: () => { queryPath: string; template: string }[];
 */
var InputElement = (0, _styledComponentFactory.createElement)('input');

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'InputField__Label',
  componentId: 'sc-2tf3hf-0'
})(['color:#fff;font-size:16px;font-family:', ';position:absolute;pointer-events:none;top:15px;left:10px;transition:0.2s ease all;input:hover ~ &,input:focus ~ &,input:not(:placeholder-shown).input:not(:focus) ~ .label,input:not(:placeholder-shown).input:not(:hover) ~ .label,input:disabled ~ &,input:valid ~ &{top:-8px;font-size:12px;}transition:0.25s ease all;', ' ', ''], function (_ref) {
  var fonts = _ref.fonts;
  return fonts.secondaryFont.value;
}, function (_ref2) {
  var shrink = _ref2.shrink;
  return shrink && 'transform: translateY(-70%) translateX(-10px) scale(0.75);';
}, _RollsRoyce2.default.min.medium(_templateObject, function (_ref3) {
  var shrink = _ref3.shrink;
  return shrink && 'transform: translateY(-70%) translateX(-26px) scale(0.75);';
}));

var Input = (0, _styledComponents2.default)(InputElement).withConfig({
  displayName: 'InputField__Input',
  componentId: 'sc-2tf3hf-1'
})(['font-family:RivieraNights-Regular;border:none;border-bottom:2px solid ', ';color:white;font-size:16px;height:50px;padding:15px 0 0 10px;width:100%;box-sizing:border-box;border-radius:0;background-color:#1b0d2e !important;', ';', ' &:focus{outline:none;}&:disabled{-webkit-text-fill-color:#757575;-webkit-opacity:1;color:#757575;}&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active{-webkit-box-shadow:0 0 0 30px #1b0d2e inset !important;-webkit-text-fill-color:white !important;background-clip:content-box !important;}'], function (_ref4) {
  var error = _ref4.error;
  return error ? 'rgb(249, 69, 100)' : 'rgb(103, 103, 118)';
}, function (_ref5) {
  var orientation = _ref5.orientation;
  return orientation === 'horizontal' ? 'width: 100%' : '';
}, _RollsRoyce2.default.min.medium(_templateObject2));

var InputField = function InputField(_ref6) {
  var id = _ref6.id,
      _ref6$label = _ref6.label,
      label = _ref6$label === undefined ? '' : _ref6$label,
      _ref6$required = _ref6.required,
      required = _ref6$required === undefined ? false : _ref6$required,
      type = _ref6.type,
      value = _ref6.value,
      _ref6$onChange = _ref6.onChange,
      onChange = _ref6$onChange === undefined ? function () {} : _ref6$onChange,
      _ref6$onKeyPress = _ref6.onKeyPress,
      onKeyPress = _ref6$onKeyPress === undefined ? function () {} : _ref6$onKeyPress,
      _ref6$error = _ref6.error,
      error = _ref6$error === undefined ? '' : _ref6$error,
      _ref6$disabled = _ref6.disabled,
      disabled = _ref6$disabled === undefined ? false : _ref6$disabled,
      translations = _ref6.translations,
      _ref6$validate = _ref6.validate,
      validate = _ref6$validate === undefined ? false : _ref6$validate,
      startAdornment = _ref6.startAdornment,
      endAdornment = _ref6.endAdornment,
      inputRef = _ref6.inputRef,
      _ref6$placeholder = _ref6.placeholder,
      placeholder = _ref6$placeholder === undefined ? '' : _ref6$placeholder,
      _ref6$orientation = _ref6.orientation,
      orientation = _ref6$orientation === undefined ? 'vertical' : _ref6$orientation,
      styleOverride = _ref6.styleOverride,
      mobileStyleOverride = _ref6.mobileStyleOverride;

  var _React$useState = _react2.default.useState(false),
      _React$useState2 = (0, _slicedToArray3.default)(_React$useState, 2),
      shrink = _React$useState2[0],
      setShrink = _React$useState2[1];

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    _FormField2.default,
    { orientation: orientation },
    startAdornment && _react2.default.createElement(
      _InputAdornment2.default,
      null,
      startAdornment
    ),
    _react2.default.createElement(
      Label,
      { fonts: fonts, shrink: shrink },
      label,
      required
    ),
    _react2.default.createElement(Input, {
      id: 'reader-input',
      className: 'reader-input',
      type: type,
      value: value,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (event) {
        onChange({
          id: id,
          value: event.target.value,
          translations: translations,
          validate: validate,
          event: event
        });
      }),
      onKeyPress: onKeyPress,
      disabled: disabled,
      ref: inputRef,
      placeholder: placeholder,
      orientation: orientation,
      styleOverride: styleOverride,
      mobileStyleOverride: mobileStyleOverride,
      onFocus: function onFocus() {
        setShrink(true);
      },
      onBlur: function onBlur() {
        if (!value) {
          setShrink(false);
        }
      },
      error: !!error
    }),
    endAdornment && _react2.default.createElement(
      _InputAdornment2.default,
      null,
      endAdornment
    ),
    error && _react2.default.createElement(
      _FieldValidationError2.default,
      null,
      error
    )
  );
};

exports.default = InputField;