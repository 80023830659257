'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: safe;\n  '], ['\n    justify-content: safe;\n  ']);

exports.default = GridView;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SearchResultTile = require('../SearchResultTile');

var _SearchResultTile2 = _interopRequireDefault(_SearchResultTile);

var _SearchResultGridTile = require('../SearchResultGridTile');

var _SearchResultGridTile2 = _interopRequireDefault(_SearchResultGridTile);

var _Compare = require('../JaguarIcons/Global/Compare');

var _Compare2 = _interopRequireDefault(_Compare);

var _Heart = require('../JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Results = _styledComponents2.default.div.withConfig({
  displayName: 'GridView__Results',
  componentId: 'sc-19jc5bo-0'
})(['width:100%;display:flex;align-items:center;flex-wrap:wrap;', ';justify-content:', ';'], _theme2.default.max.small(_templateObject), function (_ref) {
  var length = _ref.length;
  return length && length < 3 ? 'flex-start' : 'space-between';
});

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'GridView__IconWrapper',
  componentId: 'sc-19jc5bo-1'
})(['margin-right:10px;width:25px;height:25px;cursor:pointer;']);

var CompareIconButton = function CompareIconButton(onCompareClick, iconColour) {
  return _react2.default.createElement(
    IconWrapper,
    { onClick: onCompareClick },
    _react2.default.createElement(_Compare2.default, { width: '2em', height: '1.75em', colour: iconColour })
  );
};

var HeartIconButton = function HeartIconButton(onHeartClick, iconColour) {
  return _react2.default.createElement(
    IconWrapper,
    { onClick: onHeartClick },
    _react2.default.createElement(_Heart2.default, { width: '2em', height: '1.75em', colour: iconColour })
  );
};

// this has the same flex basis as the TileItemContainer
// which allows the flex display to look like a grid.
var EmptyTile = _styledComponents2.default.div.withConfig({
  displayName: 'EmptyTile'
}).withConfig({
  displayName: 'GridView__EmptyTile',
  componentId: 'sc-19jc5bo-2'
})(['display:flex;flex-direction:column;flex-basis:32%;width:100%;']);

function GridView(_ref2) {
  var results = _ref2.results,
      translations = _ref2.translations,
      onMoreInfoClick = _ref2.onMoreInfoClick,
      onEnquiryClick = _ref2.onEnquiryClick,
      compareVehicle = _ref2.compareVehicle,
      shortlistVehicle = _ref2.shortlistVehicle,
      getShortListedIconColour = _ref2.getShortListedIconColour,
      getCompareIconColour = _ref2.getCompareIconColour,
      filtersExpanded = _ref2.filtersExpanded,
      globalStyling = _ref2.globalStyling,
      visibility = _ref2.visibility,
      locale = _ref2.locale,
      vehicleModelTitleFontWeight = _ref2.vehicleModelTitleFontWeight,
      headerItemFont = _ref2.headerItemFont,
      showGridTiles = _ref2.showGridTiles,
      badgeStyling = _ref2.badgeStyling,
      financeHeadingFont = _ref2.financeHeadingFont,
      showFinancePopup = _ref2.showFinancePopup,
      countDivMargin = _ref2.countDivMargin,
      compareShortlistIconWidth = _ref2.compareShortlistIconWidth,
      compareShortlistIconColour = _ref2.compareShortlistIconColour,
      icon360 = _ref2.icon360,
      iconYoutubeVideo = _ref2.iconYoutubeVideo;

  return _react2.default.createElement(
    Results,
    { length: results.length },
    results.map(function (vehicle) {
      return showGridTiles ? _react2.default.createElement(_SearchResultGridTile2.default, {
        key: vehicle.id,
        align: 'left',
        onHeartClick: function onHeartClick() {},
        vehicle: vehicle,
        placeholdingImage: results.placeholdingImage,
        checkLength: results.length < 3,
        onMoreInfoClicked: function onMoreInfoClicked() {
          return onMoreInfoClick('VDP', vehicle);
        },
        onEnquiryClick: function (_onEnquiryClick) {
          function onEnquiryClick() {
            return _onEnquiryClick.apply(this, arguments);
          }

          onEnquiryClick.toString = function () {
            return _onEnquiryClick.toString();
          };

          return onEnquiryClick;
        }(function () {
          return onEnquiryClick(vehicle.id);
        }),
        translations: translations,
        filtersExpanded: filtersExpanded,
        renderTopRightIcon: function renderTopRightIcon() {},
        compareVehicle: compareVehicle,
        shortlistVehicle: shortlistVehicle,
        shortListedIconColour: getShortListedIconColour(vehicle.id),
        compareIconColour: getCompareIconColour(vehicle.id),
        globalStyling: globalStyling,
        visibility: visibility,
        locale: locale,
        vehicleModelTitleFontWeight: vehicleModelTitleFontWeight,
        headerItemFont: headerItemFont,
        badgeStyling: badgeStyling,
        countDivMargin: countDivMargin,
        compareShortlistIconWidth: compareShortlistIconWidth,
        compareShortlistIconColour: compareShortlistIconColour,
        icon360: icon360,
        iconYoutubeVideo: iconYoutubeVideo
      }) : _react2.default.createElement(_SearchResultTile2.default, {
        key: vehicle.id,
        align: 'left',
        onHeartClick: function onHeartClick() {},
        carResult: vehicle,
        placeholdingImage: results.placeholdingImage,
        checkLength: results.length < 3,
        onMoreInfoClicked: function onMoreInfoClicked() {
          return onMoreInfoClick('VDP', vehicle);
        },
        onEnquiryClick: function (_onEnquiryClick2) {
          function onEnquiryClick() {
            return _onEnquiryClick2.apply(this, arguments);
          }

          onEnquiryClick.toString = function () {
            return _onEnquiryClick2.toString();
          };

          return onEnquiryClick;
        }(function () {
          return onEnquiryClick(vehicle.id);
        }),
        translations: translations,
        filtersExpanded: filtersExpanded,
        renderTopRightIcon: function renderTopRightIcon() {},
        renderBottomIcon1: function renderBottomIcon1() {
          return CompareIconButton(function () {
            return compareVehicle(vehicle);
          }, getCompareIconColour(vehicle.id));
        },
        renderBottomIcon2: function renderBottomIcon2() {
          return HeartIconButton(function () {
            return shortlistVehicle(vehicle);
          }, getShortListedIconColour(vehicle.id));
        },
        globalStyling: globalStyling,
        visibility: visibility,
        locale: locale,
        vehicleModelTitleFontWeight: vehicleModelTitleFontWeight,
        headerItemFont: headerItemFont,
        badgeStyling: badgeStyling,
        financeHeadingFont: financeHeadingFont,
        showFinancePopup: showFinancePopup,
        countDivMargin: countDivMargin,
        compareShortlistIconWidth: compareShortlistIconWidth,
        compareShortlistIconColour: compareShortlistIconColour,
        icon360: icon360,
        iconYoutubeVideo: iconYoutubeVideo
      });
    }),
    results.length % 3 !== 0 && _react2.default.createElement(EmptyTile, null)
  );
}