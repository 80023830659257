'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useShortListVehicle = undefined;

var _reactRedux = require('react-redux');

var _shortlist = require('../shared/shortlist');

var _vehicleStore = require('../shared/selectors/vehicleStore');

var _converters = require('../helpers/converters');

var _converters2 = _interopRequireDefault(_converters);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Hook to handle shortlist functionality for a specific vehicle.
 * Uses global state
 */
var useShortListVehicle = exports.useShortListVehicle = function useShortListVehicle(props) {
  var vehicleId = props.vehicleId;

  var dispatch = (0, _reactRedux.useDispatch)();
  var featureFlags = (0, _reactRedux.useSelector)(function (state) {
    return state.flags;
  });
  var shared = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  });

  // Gets from state all selected vehicles for shortlist.
  var selectedVehicles = (0, _vehicleStore.getShortList)(featureFlags)(shared);

  /**
   * Checks whether a vehicle is selected for comparison or not.
   */
  var isShortlisted = selectedVehicles.some(function (vehicle) {
    return Number(vehicle.id) === Number(vehicleId);
  });

  /**
   * Adds or removes a vehicle from shortlist.
   */
  var toggleShortlist = function toggleShortlist(vehicle) {
    return dispatch(_shortlist.actions.toggleVehicle((0, _converters2.default)(vehicle)));
  };

  return {
    isShortlisted: isShortlisted,
    toggleShortlist: toggleShortlist
  };
};