'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     max-width: 420px;\n  '], ['\n     max-width: 420px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _types = require('./types');

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

var _distance = require('../../../shared/localisation/distance');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DistanceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFromRetailer__DistanceContainer',
  componentId: 'sc-3mpvjz-0'
})(['display:flex;padding:10px 10px 10px 0;margin-right:auto;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject));

var DistanceLabel = _styledComponents2.default.span.withConfig({
  displayName: 'DistanceFromRetailer__DistanceLabel',
  componentId: 'sc-3mpvjz-1'
})(['color:#151515;font-family:\'RivieraNights-Regular\';font-size:11px;line-height:18px;letter-spacing:2px;text-transform:uppercase;display:flex;flex-direction:column;']);

var DistanceIcon = _styledComponents2.default.img.withConfig({
  displayName: 'DistanceFromRetailer__DistanceIcon',
  componentId: 'sc-3mpvjz-2'
})(['margin-right:13px;']);

var LocationText = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFromRetailer__LocationText',
  componentId: 'sc-3mpvjz-3'
})(['']);

var DistanceFromRetailer = function DistanceFromRetailer(_ref) {
  var vehicle = _ref.vehicle,
      translations = _ref.translations;
  return _react2.default.createElement(
    DistanceContainer,
    null,
    _react2.default.createElement(DistanceIcon, { src: 'https://res.cloudinary.com/motortrak/v1708348324/locator/rolls-royce/global/icons/distance-location.svg' }),
    _react2.default.createElement(
      DistanceLabel,
      null,
      _react2.default.createElement(
        LocationText,
        null,
        vehicle.retailerName
      ),
      _react2.default.createElement(
        LocationText,
        null,
        (0, _distance.distanceLabel)(vehicle.distance, translations, vehicle.retailerCountryCode)
      )
    )
  );
};

exports.default = DistanceFromRetailer;