'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 25px;\n    padding: 0;\n  '], ['\n    margin-bottom: 25px;\n    padding: 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 40px 110px;\n  '], ['\n    padding: 40px 110px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: grid;\n  '], ['\n    display: grid;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n     &:nth-child(2n - 1) {\n      padding: 60px 60px 0 0;\n     }\n     padding: 60px 0 0;\n  '], ['\n     &:nth-child(2n - 1) {\n      padding: 60px 60px 0 0;\n     }\n     padding: 60px 0 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _EmissionsGroup = require('./EmissionsGroup');

var _EmissionsGroup2 = _interopRequireDefault(_EmissionsGroup);

var _Global = require('../../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpEmissions__Container',
  componentId: 'sc-13j2eq0-0'
})(['display:flex;flex-direction:column;padding:20px 16px;', ';', ' max-width:1920px;margin:0 auto;'], _theme2.default.max.medium(_templateObject), _theme2.default.min.large(_templateObject2));

var EmissionsGridContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpEmissions__EmissionsGridContainer',
  componentId: 'sc-13j2eq0-1'
})(['', ';grid-template-columns:repeat(auto-fit,minmax(50%,1fr));'], _theme2.default.min.medium(_templateObject3));

var EmissionsGridItem = _styledComponents2.default.div.withConfig({
  displayName: 'VdpEmissions__EmissionsGridItem',
  componentId: 'sc-13j2eq0-2'
})(['padding:20px 20px 0;', ';'], _theme2.default.min.medium(_templateObject4));

function VdpEmissions(_ref) {
  var translations = _ref.translations,
      vehicleInfo = _ref.vehicleInfo,
      headerFont = _ref.headerFont;
  var consumption = vehicleInfo.consumption,
      emissions = vehicleInfo.emissions;


  var isEmissionsGroupAvailable = function isEmissionsGroupAvailable(data) {
    return data !== null && data !== undefined;
  };

  var hasWltpEmissions = isEmissionsGroupAvailable(emissions.wltpEmissionsCombined);

  var hasWltpConsumptions = isEmissionsGroupAvailable(consumption.wltpConsumptionLow || consumption.wltpConsumptionMid || consumption.wltpConsumptionHigh || consumption.wltpConsumptionExtraHigh || consumption.wltpConsumptionCombined);

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        styleOverride: function styleOverride() {
          return (0, _Global.fontStyleOverride)(headerFont);
        },
        mobileStyleOverride: function mobileStyleOverride() {
          return [{
            queryPath: 'max.small',
            template: 'font-size: 16px; text-align: left; margin: 20px; margin-bottom: 0px; padding-bottom: 0px'
          }];
        }
      },
      translations.vdpEmissionsHeader
    ),
    _react2.default.createElement(
      EmissionsGridContainer,
      null,
      hasWltpEmissions && _react2.default.createElement(
        EmissionsGridItem,
        null,
        _react2.default.createElement(_EmissionsGroup2.default, {
          title: translations.wltpEmissions,
          translations: translations,
          data: {
            combined: emissions.wltpEmissionsCombined
          }
        })
      ),
      hasWltpConsumptions && _react2.default.createElement(
        EmissionsGridItem,
        null,
        _react2.default.createElement(_EmissionsGroup2.default, {
          title: translations.wltpConsumptions,
          translations: translations,
          data: {
            low: consumption.wltpConsumptionLow,
            mid: consumption.wltpConsumptionMid,
            high: consumption.wltpConsumptionHigh,
            extraHigh: consumption.wltpConsumptionExtraHigh,
            combined: consumption.wltpConsumptionCombined
          }
        })
      )
    )
  );
}

exports.default = VdpEmissions;