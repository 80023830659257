'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    padding: 0;\n     ', ';\n  '], ['\n    flex-direction: column;\n    padding: 0;\n     ', ';\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    ', '\n    line-height: 30px;\n  '], ['\n    display: flex;\n    ', '\n    line-height: 30px;\n  ']);

exports.default = FooterNavigation;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterLinks = _styledComponents2.default.div.withConfig({
  displayName: 'FooterNavigation__FooterLinks',
  componentId: 'sc-v64kkk-0'
})(['', ';', ';padding:0;', ';', ';justify-content:', ';'], function (_ref) {
  var footerGrid = _ref.footerGrid;
  return footerGrid ? 'display: grid; row-gap: 5px; width: 100%; margin: 20px 0;' : 'display: flex';
}, function (_ref2) {
  var footerGridColumns = _ref2.footerGridColumns;
  return (footerGridColumns && 'grid-template-columns: repeat(' + footerGridColumns + ', 1fr)') + ';';
}, _theme2.default.max.large(_templateObject, function (_ref3) {
  var footerGridColumnsMobile = _ref3.footerGridColumnsMobile;
  return (footerGridColumnsMobile && 'grid-template-columns: repeat(' + footerGridColumnsMobile + ', 1fr);') + ';';
}), function (_ref4) {
  var oneRow = _ref4.oneRow;
  return !oneRow && 'flex: 0 0 100%;';
}, function (props) {
  return props.align ? props.align : 'left';
});

var FooterLink = _styledComponents2.default.a.withConfig({
  displayName: 'FooterNavigation__FooterLink',
  componentId: 'sc-v64kkk-1'
})(['text-decoration:none;', ';'], _theme2.default.max.large(_templateObject2, function (_ref5) {
  var borderColour = _ref5.borderColour;
  return borderColour && 'border-bottom: solid 1px ' + borderColour.value;
}));

function FooterNavigation(_ref6) {
  var _ref6$items = _ref6.items,
      items = _ref6$items === undefined ? [] : _ref6$items,
      preview = _ref6.preview,
      _ref6$theme = _ref6.theme,
      font = _ref6$theme.font,
      hoverColour = _ref6$theme.hoverColour,
      navigateIfInternal = _ref6.navigateIfInternal,
      oneRow = _ref6.oneRow,
      align = _ref6.align,
      footerGrid = _ref6.footerGrid,
      footerGridColumns = _ref6.footerGridColumns,
      footerGridColumnsMobile = _ref6.footerGridColumnsMobile;

  var isExternal = function isExternal(href) {
    return href.match(/^http/);
  };
  return _react2.default.createElement(
    FooterLinks,
    {
      align: align,
      oneRow: oneRow,
      footerGrid: footerGrid,
      footerGridColumns: footerGridColumns,
      footerGridColumnsMobile: footerGridColumnsMobile
    },
    items.map(function (_ref7) {
      var label = _ref7.label,
          link = _ref7.link,
          navId = _ref7.navId,
          consentRefresh = _ref7.consentRefresh;
      return _react2.default.createElement(
        FooterLink,
        {
          borderColour: font.colour,
          href: link,
          'data-cy': 'footer-' + label,
          target: isExternal(link) ? '_blank' : '_self',
          onClick: function onClick(event) {
            if (preview || navigateIfInternal(link)) {
              if (!consentRefresh) {
                event.preventDefault();
              }
            }
          },
          key: navId
        },
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n                ' + (0, _Global.fontStyleOverride)(font) + '\n                padding: 8px;\n                  &:hover {\n                  ' + (hoverColour && 'color: ' + hoverColour.value) + ';\n                  }\n                ';
            }
          },
          label
        )
      );
    })
  );
}