'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  margin-bottom: 95px;\n'], ['\n  margin-bottom: 95px;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../Global');

var _HeadingTwo = require('../../Global/HeadingTwo');

var _HeadingTwo2 = _interopRequireDefault(_HeadingTwo);

var _Accordion = require('../Accordion/Accordion');

var _Accordion2 = _interopRequireDefault(_Accordion);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Benefits__SectionWrapper',
  componentId: 'sc-q1yu56-0'
})(['margin-bottom:200px;', ';'], _theme2.default.max.small(_templateObject));

var BodyMaxWidth = _styledComponents2.default.div.withConfig({
  displayName: 'Benefits__BodyMaxWidth',
  componentId: 'sc-q1yu56-1'
})(['max-width:660px;']);

var AccordionBody = _styledComponents2.default.div.withConfig({
  displayName: 'Benefits__AccordionBody',
  componentId: 'sc-q1yu56-2'
})(['padding:1px 0 64px 0;']);

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Benefits__Container',
  componentId: 'sc-q1yu56-3'
})(['max-width:1000px;']);

var Benefits = function Benefits(_ref) {
  var config = _ref.config,
      translations = _ref.translations;

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      SectionWrapper,
      null,
      _react2.default.createElement(
        _HeadingTwo2.default,
        {
          styleOverride: function styleOverride() {
            return '\n          font-family: ' + fonts.primaryFont.value + ';\n          font-size: 16px;\n          color: #1A1D1D;\n          text-transform: uppercase;\n        ';
          }
        },
        translations.timelessBenefits
      )
    ),
    _react2.default.createElement(
      SectionWrapper,
      {
        style: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }
      },
      _react2.default.createElement(
        Container,
        null,
        _react2.default.createElement(
          SectionWrapper,
          {
            style: {
              display: 'flex',
              flexDirection: 'row-reverse'
            }
          },
          _react2.default.createElement(
            BodyMaxWidth,
            null,
            config.bodyBenefitsKeys.map(function (key) {
              return _react2.default.createElement(
                _Global.Paragraph,
                {
                  key: key,
                  styleOverride: function styleOverride() {
                    return 'color: #1a1d1d;\n                    font-family: ' + fonts.paragraphFont.value + ';\n                    font-size: 14px;\n                    font-weight: 300;\n                    line-height: 18px;';
                  }
                },
                translations[key]
              );
            })
          )
        ),
        config.accordionItems && (0, _entries2.default)(config.accordionItems).map(function (_ref2) {
          var _ref3 = (0, _slicedToArray3.default)(_ref2, 2),
              key = _ref3[0],
              value = _ref3[1];

          return _react2.default.createElement(
            _Accordion2.default,
            {
              key: key,
              title: translations[key].toString().toUpperCase(),
              isExpandable: true,
              iconOpen: config.accordionPlusIconUrl,
              iconClose: config.accordionMinusIconUrl
            },
            _react2.default.createElement(
              AccordionBody,
              null,
              value.map(function (k) {
                return _react2.default.createElement(
                  _Global.Paragraph,
                  {
                    key: k,
                    styleOverride: function styleOverride() {
                      return 'color: #1a1d1d;\n                          font-family: ' + fonts.paragraphFont.value + ';\n                          font-size: 14px;\n                          font-weight: 300;\n                          line-height: 18px;';
                    }
                  },
                  translations[k]
                );
              })
            )
          );
        })
      )
    )
  );
};

exports.default = Benefits;