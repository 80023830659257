'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VehicleTitleInfo = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    border-bottom: 1px solid #fff;\n  '], ['\n    border-bottom: 1px solid #fff;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n      align-items: center;\n      justify-content: center;\n  '], ['\n    ', '\n      align-items: center;\n      justify-content: center;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n   font-size: 18px;\n  '], ['\n   font-size: 18px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n   font-family: \'GenesisSansHeadGlobal-Light\';\n   font-size: 36px;\n  '], ['\n   font-family: \'GenesisSansHeadGlobal-Light\';\n   font-size: 36px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    border-bottom: 1px solid #fff;\n    justify-content: center;\n    margin-right: initial;\n    margin-bottom: 16px;\n    padding-bottom: 16px;\n    width: 100%;\n    align-items: center;\n  '], ['\n    border-bottom: 1px solid #fff;\n    justify-content: center;\n    margin-right: initial;\n    margin-bottom: 16px;\n    padding-bottom: 16px;\n    width: 100%;\n    align-items: center;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 18px;\n    text-align: left;\n  '], ['\n    font-size: 18px;\n    text-align: left;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    font-family: \'GenesisSansHeadGlobal-Light\';\n    font-size: 36px;\n  '], ['\n    font-family: \'GenesisSansHeadGlobal-Light\';\n    font-size: 36px;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%\n  '], ['\n    width: 100%\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%'], ['\n    width: 100%']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    gap: 10px;\n    margin: 0 20px;\n  '], ['\n    gap: 10px;\n    margin: 0 20px;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    text-align: left;\n  '], ['\n    text-align: left;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _useCheckIsMobileScreen = require('../../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _globalDrawers = require('../../../../shared/globalDrawers');

var _AppState = require('../../../../types/AppState');

var _Spinner = require('../../../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _SocialSharePopup = require('../../SocialShare/SocialSharePopup');

var _SocialSharePopup2 = _interopRequireDefault(_SocialSharePopup);

var _isCentralRetailer = require('../../../../helpers/isCentralRetailer');

var _isCentralRetailer2 = _interopRequireDefault(_isCentralRetailer);

var _vehicleTypes = require('../../../../types/Genesis/vehicleTypes');

var _CompareButton = require('../../CompareButton');

var _CompareButton2 = _interopRequireDefault(_CompareButton);

var _Form = require('../../Form');

var _ShortlistButton = require('../../ShortlistButton');

var _ShortlistButton2 = _interopRequireDefault(_ShortlistButton);

var _types = require('../types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__Wrapper',
  componentId: 'sc-jc7cu6-0'
})(['margin:40px 0;padding-bottom:10px;', ';'], _Genesis2.default.min.medium(_templateObject));

var FlexRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__FlexRow',
  componentId: 'sc-jc7cu6-1'
})(['display:flex;align-items:flex-end;justify-content:flex-end;', ';'], _Genesis2.default.max.medium(_templateObject2, function (_ref) {
  var colMobile = _ref.colMobile;
  return colMobile && ' flex-direction:column;';
}));

var VehicleTitleWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__VehicleTitleWrapper',
  componentId: 'sc-jc7cu6-2'
})(['margin-right:auto;']);

var ApprovedStrapLine = _styledComponents2.default.h4.withConfig({
  displayName: 'VehicleTitleInfo__ApprovedStrapLine',
  componentId: 'sc-jc7cu6-3'
})(['color:#c36f51;font-family:\'GenesisSansTextGlobal-Regular\';font-size:14px;font-weight:normal;margin:0;text-transform:uppercase;', ';'], _Genesis2.default.min.medium(_templateObject3));

var VehicleName = _styledComponents2.default.h1.withConfig({
  displayName: 'VehicleTitleInfo__VehicleName',
  componentId: 'sc-jc7cu6-4'
})(['color:#fff;font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;font-weight:normal;margin:5px 0;text-transform:uppercase;', ';'], _Genesis2.default.min.medium(_templateObject4));

var VehicleRetailerNameWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__VehicleRetailerNameWrapper',
  componentId: 'sc-jc7cu6-5'
})(['display:flex;margin-right:auto;flex-direction:column;', ';'], _Genesis2.default.max.medium(_templateObject5));

var VehicleRetailerName = _styledComponents2.default.h1.withConfig({
  displayName: 'VehicleTitleInfo__VehicleRetailerName',
  componentId: 'sc-jc7cu6-6'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:14px;font-weight:normal;margin:0 5px;text-transform:uppercase;text-align:center;', ';'], _Genesis2.default.min.medium(_templateObject6));

var VehiclePrice = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__VehiclePrice',
  componentId: 'sc-jc7cu6-7'
})(['color:#fff;font-family:\'GenesisSansTextGlobal-Regular\';font-weight:normal;font-size:16px;margin:5px 0;text-transform:uppercase;', ';'], _Genesis2.default.min.medium(_templateObject7));

var EnquiryButton = (0, _styledComponents2.default)(_Form.Button).withConfig({
  displayName: 'VehicleTitleInfo__EnquiryButton',
  componentId: 'sc-jc7cu6-8'
})(['', ';'], _Genesis2.default.max.medium(_templateObject8));

var TelephoneLink = _styledComponents2.default.a.withConfig({
  displayName: 'VehicleTitleInfo__TelephoneLink',
  componentId: 'sc-jc7cu6-9'
})(['font-family:\'GenesisSansHeadGlobal-Regular\';font-size:14px;padding:14px 24px;margin-right:16px;text-align:center;color:#fff;text-decoration:none;color:#fff;font-weight:normal;line-height:normal;text-decoration:none;', ';'], _Genesis2.default.max.medium(_templateObject9));

var VehiclePriceWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__VehiclePriceWrapper',
  componentId: 'sc-jc7cu6-10'
})(['display:flex;align-items:center;justify-content:center;']);

var Utilities = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__Utilities',
  componentId: 'sc-jc7cu6-11'
})(['display:flex;align-items:center;justify-content:center;margin-right:20px;gap:20px;margin:10px 10px 16px;', ';'], _Genesis2.default.min.medium(_templateObject10));

var Divider = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__Divider',
  componentId: 'sc-jc7cu6-12'
})(['border-right:1px solid #aaaba6;height:15px;']);

var PrintButton = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleTitleInfo__PrintButton',
  componentId: 'sc-jc7cu6-13'
})(['cursor:pointer;']);

var ShareButton = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleTitleInfo__ShareButton',
  componentId: 'sc-jc7cu6-14'
})(['cursor:pointer;']);

var CentralRetailerDisclaimer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitleInfo__CentralRetailerDisclaimer',
  componentId: 'sc-jc7cu6-15'
})(['color:#bbb;font-family:\'GenesisSansTextGlobal-Regular\';font-size:14px;line-height:16.8px;margin-top:5px;max-width:600px;text-align:center;', ';'], _Genesis2.default.min.medium(_templateObject11));

var VehicleTitleInfo = function VehicleTitleInfo(_ref2) {
  var vehicle = _ref2.vehicle,
      translations = _ref2.translations,
      localisedRetailPrice = _ref2.localisedRetailPrice,
      config = _ref2.config,
      printVehiclePdf = _ref2.printVehiclePdf,
      socialShare = _ref2.socialShare;

  var mobile = (0, _useCheckIsMobileScreen2.default)('medium');
  var dispatch = (0, _reactRedux.useDispatch)();
  var isPrinting = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.printing.isPrinting;
  });
  var isCentralRetailerRetailer = (0, _isCentralRetailer2.default)(vehicle.retailerInformation.id);

  return _react2.default.createElement(
    Wrapper,
    { id: 'Vehicle-Information' },
    _react2.default.createElement(
      FlexRow,
      null,
      _react2.default.createElement(
        VehicleTitleWrapper,
        null,
        vehicle.approved && _react2.default.createElement(
          ApprovedStrapLine,
          null,
          translations.approvedStraplineText
        ),
        _react2.default.createElement(
          VehicleName,
          null,
          vehicle.name
        )
      ),
      _react2.default.createElement(
        VehiclePriceWrapper,
        null,
        !mobile && _react2.default.createElement(
          Utilities,
          null,
          isPrinting ? _react2.default.createElement(_Spinner2.default, { size: 12, colour: { value: '#C36F51' } }) : _react2.default.createElement(PrintButton, {
            src: config.iconPrint,
            onClick: function onClick(e) {
              printVehiclePdf(e, vehicle);
            }
          }),
          _react2.default.createElement(Divider, null),
          !mobile && _react2.default.createElement(_CompareButton2.default, {
            vehicle: vehicle,
            iconOff: config.iconCompareOff,
            iconOn: config.iconCompareOn
          }),
          _react2.default.createElement(Divider, null),
          _react2.default.createElement(_ShortlistButton2.default, {
            vehicle: vehicle,
            iconOff: config.iconShortlistOff,
            iconOn: config.iconShortlistOn
          }),
          _react2.default.createElement(Divider, null),
          _react2.default.createElement(
            _SocialSharePopup2.default,
            { socialShare: socialShare, config: config },
            _react2.default.createElement(ShareButton, { src: config.iconShare })
          )
        ),
        _react2.default.createElement(
          VehiclePrice,
          null,
          localisedRetailPrice || vehicle.priceSpecial || translations.noRetailPrice
        )
      )
    ),
    mobile && _react2.default.createElement(
      FlexRow,
      null,
      _react2.default.createElement(
        Utilities,
        null,
        isPrinting ? _react2.default.createElement(_Spinner2.default, { size: 12, colour: { value: '#C36F51' } }) : _react2.default.createElement(PrintButton, {
          src: config.iconPrint,
          onClick: function onClick(e) {
            printVehiclePdf(e, vehicle);
          }
        }),
        _react2.default.createElement(Divider, null),
        _react2.default.createElement(_ShortlistButton2.default, {
          vehicle: vehicle,
          iconOff: config.iconShortlistOff,
          iconOn: config.iconShortlistOn
        }),
        _react2.default.createElement(Divider, null),
        _react2.default.createElement(
          _SocialSharePopup2.default,
          { socialShare: socialShare, config: config },
          _react2.default.createElement(ShareButton, { src: config.iconShare })
        )
      )
    ),
    _react2.default.createElement(
      FlexRow,
      { colMobile: true, style: { alignItems: 'center' } },
      _react2.default.createElement(
        VehicleRetailerNameWrapper,
        null,
        _react2.default.createElement(
          VehicleRetailerName,
          null,
          _react2.default.createElement('img', {
            src: 'https://res.cloudinary.com/motortrak/image/upload/v1697233489/locator/genesis/icons/location-pin-title.svg',
            style: { marginBottom: '-2px', marginRight: '5px' }
          }),
          vehicle.retailerInformation.name
        ),
        isCentralRetailerRetailer && _react2.default.createElement(
          CentralRetailerDisclaimer,
          null,
          translations.centralDealerDisclaimer
        )
      ),
      !isCentralRetailerRetailer && vehicle && vehicle.retailerInformation.phone && _react2.default.createElement(
        TelephoneLink,
        { href: 'tel:' + vehicle.retailerInformation.phone },
        _react2.default.createElement('img', {
          style: { paddingRight: '5px' },
          alt: 'Call ' + vehicle.retailerInformation.name,
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1700586964/locator/genesis/icons/sticky-header-phone.svg'
        }),
        vehicle.retailerInformation.phone
      ),
      _react2.default.createElement(
        EnquiryButton,
        {
          variant: 'secondary',
          onClick: function onClick() {
            return dispatch(_globalDrawers.actions.toggleEnquireDrawer(vehicle));
          }
        },
        translations.contact
      )
    )
  );
};

exports.VehicleTitleInfo = VehicleTitleInfo;