'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SeparatedListItem = exports.SeparatedList = exports.SeparatedListTitle = exports.SeparatedListContainer = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n  '], ['\n    margin: 0;\n  ']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SeparatedListContainer = exports.SeparatedListContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ListContainer'
}).withConfig({
  displayName: 'SeparatedList__SeparatedListContainer',
  componentId: 'sc-smx607-0'
})(['font-size:16px;line-height:22px;color:#343434;break-inside:avoid;display:inline;']);

var SeparatedListTitle = exports.SeparatedListTitle = _styledComponents2.default.div.withConfig({
  displayName: 'ListTitle'
}).withConfig({
  displayName: 'SeparatedList__SeparatedListTitle',
  componentId: 'sc-smx607-1'
})(['font-weight:600;font-size:16px;line-height:19px;color:#444444;margin-bottom:27px;']);

var SeparatedList = exports.SeparatedList = _styledComponents2.default.ul.withConfig({
  displayName: 'List'
}).withConfig({
  displayName: 'SeparatedList',
  componentId: 'sc-smx607-2'
})(['list-style:none;padding:0;margin:0 0 21px 0;', ';'], _theme2.default.max.large(_templateObject));

var SeparatedListItem = exports.SeparatedListItem = _styledComponents2.default.li.withConfig({
  displayName: 'ListItem'
}).withConfig({
  displayName: 'SeparatedList__SeparatedListItem',
  componentId: 'sc-smx607-3'
})(['padding:0 0 20px 10px;font-size:16px;', ';page-break-inside:avoid;break-inside:avoid;'], function (_ref) {
  var flex = _ref.flex;
  return flex && 'display: flex';
});