'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Timeless = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  padding: 110px 100px 0;\n'], ['\n  padding: 110px 100px 0;\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n  padding: 110px 50px 0;\n'], ['\n  padding: 110px 50px 0;\n']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  padding: 110px 25px 0;\n'], ['\n  padding: 110px 25px 0;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Benefits = require('../../../components/AstonMartin/Timeless/Benefits');

var _Benefits2 = _interopRequireDefault(_Benefits);

var _Ownership = require('../../../components/AstonMartin/Timeless/Ownership');

var _Ownership2 = _interopRequireDefault(_Ownership);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TimelessWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Timeless__TimelessWrapper',
  componentId: 'sc-6cohwf-0'
})(['max-width:1920px;padding:200px 235px 0;', ';', ';', ';'], _theme2.default.max.large(_templateObject), _theme2.default.max.medium(_templateObject2), _theme2.default.max.small(_templateObject3));

var Timeless = exports.Timeless = function Timeless(_ref) {
  var config = _ref.config;
  var items = config.items,
      translations = config.translations;


  return _react2.default.createElement(
    TimelessWrapper,
    null,
    _react2.default.createElement(_Benefits2.default, { config: config, translations: translations }),
    _react2.default.createElement(_Ownership2.default, { translations: translations, items: items })
  );
};

exports.default = Timeless;