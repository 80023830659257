'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InputAdornment = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var InputAdornmentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'InputAdornment__InputAdornmentWrapper',
  componentId: 'sc-177gg1c-0'
})(['display:flex;align-items:center;white-space:nowrap;']);

var InputAdornment = exports.InputAdornment = function InputAdornment(_ref) {
  var children = _ref.children;

  return _react2.default.createElement(
    InputAdornmentWrapper,
    null,
    children
  );
};

exports.default = InputAdornment;