'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    position: static;\n    overflow: visible;\n    width: 100%;\n    height: 100%;\n  '], ['\n    position: static;\n    overflow: visible;\n    width: 100%;\n    height: 100%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    .collapse-leave.collapse-leave-active,\n    .collapse-enter {\n      position: static;\n      display: none;\n      transition: none;\n    }\n  '], ['\n    .collapse-leave.collapse-leave-active,\n    .collapse-enter {\n      position: static;\n      display: none;\n      transition: none;\n    }\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n      .collapse-enter.collapse-enter-active,\n      .collapse-leave {\n        transition: none;\n        opacity: 1;\n        display: block;\n        height: 100%;\n        width: 100%\n      }\n    '], ['\n      .collapse-enter.collapse-enter-active,\n      .collapse-leave {\n        transition: none;\n        opacity: 1;\n        display: block;\n        height: 100%;\n        width: 100%\n      }\n    ']);

exports.default = SlideOut;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactAddonsCssTransitionGroup = require('react-addons-css-transition-group');

var _reactAddonsCssTransitionGroup2 = _interopRequireDefault(_reactAddonsCssTransitionGroup);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ToggleButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ToggleButtonWrapper'
}).withConfig({
  displayName: 'SlideOut__ToggleButtonWrapper',
  componentId: 'sc-1y5drvl-0'
})(['width:100%;cursor:pointer;text-transform:uppercase;color:#444;']);

var CloseButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CloseButtonWrapper'
}).withConfig({
  displayName: 'SlideOut__CloseButtonWrapper',
  componentId: 'sc-1y5drvl-1'
})(['display:flex;cursor:pointer;justify-content:flex-end;']);

var ToggleSection = _styledComponents2.default.div.withConfig({
  displayName: 'ToggleSection'
}).withConfig({
  displayName: 'SlideOut__ToggleSection',
  componentId: 'sc-1y5drvl-2'
})(['width:100%;position:relative;']);

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'SlideOut__Content',
  componentId: 'sc-1y5drvl-3'
})(['position:fixed;z-index:9999;background:', ';width:100vw;height:100vh;top:0;right:0;overflow-y:scroll;', ';'], function (_ref) {
  var backgroundColour = _ref.backgroundColour;
  return backgroundColour || '#FFF';
}, _theme2.default.min.large(_templateObject));
var StyledCollapse = _styledComponents2.default.div.withConfig({
  displayName: 'SlideOut__StyledCollapse',
  componentId: 'sc-1y5drvl-4'
})(['.collapse-leave.collapse-leave-active,.collapse-enter{width:100vw;height:100vh;background-color:', ';position:fixed;top:0;right:-100vw;transition:400ms;}', ';}.collapse-enter.collapse-enter-active,.collapse-leave{right:0;transition:400ms;', ';}'], function (_ref2) {
  var backgroundColour = _ref2.backgroundColour;
  return backgroundColour || '#FFF';
}, _theme2.default.min.large(_templateObject2), _theme2.default.min.large(_templateObject3));

var SlideOutWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SlideOutWrapper'
}).withConfig({
  displayName: 'SlideOut__SlideOutWrapper',
  componentId: 'sc-1y5drvl-5'
})(['display:', ';'], function (_ref3) {
  var visible = _ref3.visible;
  return visible === true ? 'block' : 'none';
});

function SlideOut(props) {
  var renderClose = props.renderClose,
      renderTitle = props.renderTitle,
      backgroundColour = props.backgroundColour,
      children = props.children,
      collapsed = props.collapsed,
      toggle = props.toggle,
      _props$visible = props.visible,
      visible = _props$visible === undefined ? true : _props$visible;

  return _react2.default.createElement(
    SlideOutWrapper,
    { visible: visible },
    _react2.default.createElement(
      ToggleSection,
      null,
      renderTitle && _react2.default.createElement(
        ToggleButtonWrapper,
        {
          'data-cy': 'openBurger',
          collapsed: collapsed,
          onClick: toggle
        },
        renderTitle({ collapsed: collapsed })
      ),
      _react2.default.createElement(
        StyledCollapse,
        { backgroundColour: backgroundColour },
        _react2.default.createElement(
          _reactAddonsCssTransitionGroup2.default,
          {
            transitionName: 'collapse',
            transitionEnterTimeout: 400,
            transitionLeaveTimeout: 400
          },
          collapsed ? null : _react2.default.createElement(
            Content,
            { backgroundColour: backgroundColour },
            renderClose && _react2.default.createElement(
              CloseButtonWrapper,
              { 'data-cy': 'closeBurger', onClick: toggle },
              renderClose()
            ),
            children
          )
        )
      )
    )
  );
}