'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CookiePolicyContent = exports.MarkdownRenderers = exports.disallowedTypes = exports.ButtonWrapper = exports.CookiePolicyWrapper = exports.CookiePolicyFixedTopWrapper = exports.buttonAlignMapping = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _useCookiePolicy2 = require('./useCookiePolicy');

var _useCookiePolicy3 = _interopRequireDefault(_useCookiePolicy2);

var _components = require('../components');

var _Global = require('../components/Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var buttonAlignMapping = exports.buttonAlignMapping = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};
var CookiePolicyFixedTopWrapper = exports.CookiePolicyFixedTopWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__CookiePolicyFixedTopWrapper',
  componentId: 'sc-1qshzvp-0'
})(['width:100%;display:flex;align-self:center;']);

var CookiePolicyWrapper = exports.CookiePolicyWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__CookiePolicyWrapper',
  componentId: 'sc-1qshzvp-1'
})(['width:', ';display:flex;position:fixed;z-index:999;', ';', ';', ';'], function (props) {
  return props.type === 'compact' ? '50vw' : '100%';
}, function (props) {
  return props.vPlacement === 'bottom' ? 'bottom: 0px' : 'top: 0px';
}, function (props) {
  return props.hPlacement === 'right' ? 'right: 0px' : 'left: 0px';
}, function (props) {
  return props.hPlacement === 'center' && 'left: 50%; transform: translateX(-50%)';
});

var ButtonWrapper = exports.ButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__ButtonWrapper',
  componentId: 'sc-1qshzvp-2'
})(['display:flex;justify-content:', ';padding-bottom:10px;'], function (props) {
  return props.alignment || 'center';
});

var disallowedTypes = exports.disallowedTypes = ['strong'];

var MarkdownRenderers = exports.MarkdownRenderers = function MarkdownRenderers(bodyFont) {
  return {
    paragraph: function paragraph(props) {
      return _react2.default.createElement(
        _Global.Paragraph,
        { styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)(bodyFont);
          } },
        props.children
      );
    },
    heading: function heading(props) {
      switch (props.level) {
        case 2:
          return _react2.default.createElement(
            _Global.HeadingTwo,
            null,
            props.children
          );
        default:
          return _react2.default.createElement(
            _Global.HeadingOne,
            null,
            props.children
          );
      }
    }
  };
};

var CookiePolicyContent = exports.CookiePolicyContent = function CookiePolicyContent(props) {
  var backgroundColour = props.backgroundColour,
      border = props.border,
      borderColour = props.borderColour,
      padding = props.padding,
      bodyFont = props.bodyFont,
      alignment = props.alignment,
      uiElements = props.uiElements,
      button = props.button,
      translations = props.translations,
      setVisible = props.setVisible;


  return _react2.default.createElement(
    _components.Wrapper,
    {
      backgroundColour: backgroundColour,
      border: border,
      borderColour: borderColour
    },
    translations.bodyText && _react2.default.createElement(
      _components.TextWrapper,
      { padding: padding },
      _react2.default.createElement(
        _reactMarkdown2.default,
        {
          renderers: MarkdownRenderers(bodyFont),
          disallowedTypes: disallowedTypes,
          unwrapDisallowed: true
        },
        translations.bodyText
      )
    ),
    _react2.default.createElement(
      ButtonWrapper,
      { alignment: buttonAlignMapping[alignment] },
      _react2.default.createElement(_Global.Button, {
        buttonStyle: uiElements.primaryButton && uiElements.primaryButton.buttonStyle,
        applyStyle: 'primary',
        styleOverride: function styleOverride() {
          return (0, _Global.buttonStyleOverride)(button);
        },
        text: translations.buttonText,
        onClick: function onClick() {
          return setVisible(false);
        }
      })
    )
  );
};

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      translations = _ref$config.translations,
      backgroundColour = _ref$config.backgroundColour,
      border = _ref$config.border,
      borderColour = _ref$config.borderColour,
      bodyFont = _ref$config.bodyFont,
      button = _ref$config.button,
      padding = _ref$config.padding,
      alignment = _ref$config.alignment,
      placement = _ref$config.placement,
      type = _ref$config.type,
      _ref$globalStyling = _ref.globalStyling;
  _ref$globalStyling = _ref$globalStyling === undefined ? {} : _ref$globalStyling;
  var _ref$globalStyling$ui = _ref$globalStyling.uiElements,
      uiElements = _ref$globalStyling$ui === undefined ? {} : _ref$globalStyling$ui;

  var _useCookiePolicy = (0, _useCookiePolicy3.default)(),
      domEl = _useCookiePolicy.domEl,
      isVisible = _useCookiePolicy.isVisible,
      setVisible = _useCookiePolicy.setVisible;

  var _placement$split = placement.split('-'),
      _placement$split2 = (0, _slicedToArray3.default)(_placement$split, 2),
      vPlacement = _placement$split2[0],
      hPlacement = _placement$split2[1];

  var cookiePolicyProps = {
    backgroundColour: backgroundColour,
    border: border,
    borderColour: borderColour,
    padding: padding,
    bodyFont: bodyFont,
    alignment: alignment,
    uiElements: uiElements,
    button: button,
    translations: translations,
    setVisible: setVisible
  };

  if (!isVisible) {
    return null;
  }

  if (type === 'full-width' && vPlacement === 'top' && hPlacement === 'center') {
    return domEl && _reactDom2.default.createPortal(_react2.default.createElement(
      CookiePolicyFixedTopWrapper,
      null,
      _react2.default.createElement(CookiePolicyContent, cookiePolicyProps)
    ), domEl);
  }

  return _reactDom2.default.createPortal(_react2.default.createElement(
    CookiePolicyWrapper,
    {
      hPlacement: hPlacement,
      vPlacement: vPlacement,
      type: type
    },
    _react2.default.createElement(CookiePolicyContent, cookiePolicyProps)
  ), document.body);
};