'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px 30px;\n    padding-right: 10px\n    border-bottom: 1px solid #1e1e1e;\n    ', ''], ['\n    padding: 20px 30px;\n    padding-right: 10px\n    border-bottom: 1px solid #1e1e1e;\n    ', '']);

exports.default = LinkList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _GenesisLink = require('./GenesisLink');

var _GenesisLink2 = _interopRequireDefault(_GenesisLink);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LinkText = _styledComponents2.default.span.withConfig({
  displayName: 'LinkList__LinkText',
  componentId: 'sc-1ceq8in-0'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;']);

var Col = _styledComponents2.default.div.withConfig({
  displayName: 'LinkList__Col',
  componentId: 'sc-1ceq8in-1'
})(['display:flex;flex-direction:column;align-items:flex-start;gap:30px;padding-bottom:40px;', ''], _Genesis2.default.max.large(_templateObject, function (_ref) {
  var open = _ref.open;
  return !open && 'display:none;';
}));

function LinkList(_ref2) {
  var items = _ref2.items,
      translations = _ref2.translations,
      open = _ref2.open;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      Col,
      { open: open },
      items.map(function (_ref3) {
        var label = _ref3.label,
            link = _ref3.link,
            id = _ref3.id;
        return _react2.default.createElement(
          _GenesisLink2.default,
          { key: id, link: link },
          _react2.default.createElement(
            LinkText,
            null,
            translations['' + label]
          )
        );
      })
    )
  );
}