'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserLocation = undefined;

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Spinner = require('../../../components/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _useRollsRoyceLocation = require('../../../hooks/useRollsRoyceLocation');

var _useRollsRoyceLocation2 = _interopRequireDefault(_useRollsRoyceLocation);

var _settings = require('../../../shared/selectors/settings');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__Wrapper',
  componentId: 'sc-w0fxok-0'
})(['display:flex;width:100%;']);

var BrowserLocation = _styledComponents2.default.button.withConfig({
  displayName: 'UserLocation__BrowserLocation',
  componentId: 'sc-w0fxok-1'
})(['flex:0 0 30px;background-color:#ffffff;border:1px solid #3b3b3b;border-right:none;height:', ';cursor:pointer;padding:5px 13px;padding-right:0px;box-sizing:border-box;display:flex;justify-content:center;align-items:center;', ' &:focus{border:1px solid #999999;outline:none;}&:after{content:\'\';background-color:#3b3b3b;width:1px;display:block;height:36px;margin-left:16px;}'], function (_ref) {
  var newFilters = _ref.newFilters;
  return newFilters ? '40px;' : '46px;';
}, function (_ref2) {
  var browserColour = _ref2.browserColour;
  return browserColour && 'background-color: ' + browserColour + ';';
});

var InputWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__InputWrapper',
  componentId: 'sc-w0fxok-2'
})(['position:relative;flex:1 1 0%;']);

var InputLocation = _styledComponents2.default.input.withConfig({
  displayName: 'UserLocation__InputLocation',
  componentId: 'sc-w0fxok-3'
})(['height:', ';width:100%;padding:0 12px;border:1px solid #3b3b3b;border-left:none;box-sizing:border-box;&:focus{outline:none;}'], function (_ref3) {
  var newFilters = _ref3.newFilters;
  return newFilters ? '40px;' : '46px;';
});

var LocationSuggestions = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__LocationSuggestions',
  componentId: 'sc-w0fxok-4'
})(['position:absolute;width:100%;', ';background-color:#ffffff;border:1px solid #3b3b3b;box-sizing:border-box;z-index:10;'], function (_ref4) {
  var position = _ref4.position;
  return position === 'below' ? 'top: 40px;' : 'bottom: 40px;';
});

var Suggestion = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__Suggestion',
  componentId: 'sc-w0fxok-5'
})(['font-size:12px;padding:5px;border-bottom:1px solid #d5d5d5;cursor:pointer;outline:none;', ';&:hover{background-color:#d5d5d5;}&:last-child{border-bottom:none;}'], function (_ref5) {
  var inFocus = _ref5.inFocus;
  return inFocus && 'background-color: #d5d5d5;';
});

var SpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__SpinnerWrapper',
  componentId: 'sc-w0fxok-6'
})(['position:absolute;right:10px;top:calc(50% - 10px);']);

var UserLocation = function UserLocation(_ref6) {
  var placeholderText = _ref6.placeholderText,
      locationAlternativeText = _ref6.locationAlternativeText,
      iconUrl = _ref6.iconUrl,
      browserLocationCallback = _ref6.browserLocationCallback,
      inputLocationCallback = _ref6.inputLocationCallback,
      _ref6$options = _ref6.options,
      options = _ref6$options === undefined ? {} : _ref6$options,
      newFilters = _ref6.newFilters;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      colours = _useSelector.colours;

  var _options$autocomplete = options.autocompletePosition,
      autocompletePosition = _options$autocomplete === undefined ? 'below' : _options$autocomplete,
      rest = (0, _objectWithoutProperties3.default)(options, ['autocompletePosition']);

  var _useLocation = (0, _useRollsRoyceLocation2.default)(browserLocationCallback, inputLocationCallback, rest),
      formattedLocation = _useLocation.formattedLocation,
      getBrowserLocation = _useLocation.getBrowserLocation,
      getInputLocation = _useLocation.getInputLocation,
      onInputChange = _useLocation.onInputChange,
      suggestions = _useLocation.suggestions,
      visible = _useLocation.visible,
      currentlySelected = _useLocation.currentlySelected,
      setCurrentlySelected = _useLocation.setCurrentlySelected,
      inputRef = _useLocation.inputRef,
      onInputKeyDown = _useLocation.onInputKeyDown,
      onInputBlur = _useLocation.onInputBlur,
      onInputFocus = _useLocation.onInputFocus,
      isLoading = _useLocation.isLoading,
      isError = _useLocation.isError;

  var ref = (0, _react.useRef)(inputRef);

  var showSuggestions = suggestions && suggestions.length > 0 && formattedLocation && formattedLocation.length > 0 && visible && !isError;

  return _react2.default.createElement(
    Wrapper,
    { role: 'group' },
    _react2.default.createElement(
      BrowserLocation,
      {
        onClick: getBrowserLocation,
        newFilters: newFilters,
        browserColour: options.browserColour,
        'aria-label': locationAlternativeText || ''
      },
      _react2.default.createElement('img', { src: iconUrl, alt: 'location-icon' })
    ),
    _react2.default.createElement(
      InputWrapper,
      null,
      _react2.default.createElement(InputLocation, {
        'data-cy': 'user-location-input',
        ref: ref,
        placeholder: placeholderText,
        value: formattedLocation,
        onChange: onInputChange,
        onKeyDown: onInputKeyDown,
        onBlur: function onBlur(event) {
          return onInputBlur(event, placeholderText);
        },
        onFocus: onInputFocus,
        newFilters: newFilters,
        'aria-label': placeholderText
      }),
      showSuggestions && _react2.default.createElement(
        LocationSuggestions,
        {
          position: autocompletePosition,
          'data-cy': 'user-location-suggestion-list',
          role: 'group',
          'aria-live': 'polite'
        },
        suggestions.map(function (s, index) {
          return _react2.default.createElement(
            Suggestion,
            {
              role: 'button',
              'data-cy': 'user-location-suggestion-list-option',
              onMouseDown: function onMouseDown() {
                setCurrentlySelected(0);
                getInputLocation(s);
              },
              tabIndex: 0,
              onMouseEnter: function onMouseEnter() {
                return setCurrentlySelected(index);
              },
              inFocus: currentlySelected === index,
              key: s.description,
              'aria-label': s.description
            },
            s.description
          );
        })
      ),
      isLoading && _react2.default.createElement(
        SpinnerWrapper,
        null,
        _react2.default.createElement(_Spinner2.default, { size: 16, colour: colours.primaryBrandColour })
      )
    )
  );
};

exports.UserLocation = UserLocation;
exports.default = UserLocation;