'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpinnerWrapper = exports.default = undefined;

var _Spinner = require('./Spinner');

Object.defineProperty(exports, 'default', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Spinner).default;
  }
});

var _SpinnerWrapper = require('./SpinnerWrapper');

var _SpinnerWrapper2 = _interopRequireDefault(_SpinnerWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.SpinnerWrapper = _SpinnerWrapper2.default;