'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _EnquiryForm = require('../../components/EnquiryForm');

var _EnquiryForm2 = _interopRequireDefault(_EnquiryForm);

var _TemplateTwo = require('./Templates/TemplateTwo');

var _TemplateTwo2 = _interopRequireDefault(_TemplateTwo);

var _LamborghiniThirdPartyTemplate = require('./Templates/LamborghiniThirdPartyTemplate');

var _LamborghiniThirdPartyTemplate2 = _interopRequireDefault(_LamborghiniThirdPartyTemplate);

var _AstonMartinTemplate = require('./Templates/AstonMartinTemplate');

var _AstonMartinTemplate2 = _interopRequireDefault(_AstonMartinTemplate);

var _EnquiryTemplate = require('../../components/Bentley/EnquiryTemplate');

var _EnquiryTemplate2 = _interopRequireDefault(_EnquiryTemplate);

var _reducer = require('../../shared/CustomerLogin/reducer');

require('../../types');

var _enquiry = require('../../actions/enquiry');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Enquiry = function (_Component) {
  (0, _inherits3.default)(Enquiry, _Component);

  function Enquiry() {
    (0, _classCallCheck3.default)(this, Enquiry);
    return (0, _possibleConstructorReturn3.default)(this, (Enquiry.__proto__ || (0, _getPrototypeOf2.default)(Enquiry)).apply(this, arguments));
  }

  (0, _createClass3.default)(Enquiry, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // We do this to force a first run of the reducer
      // - there used to be a more generic solution,
      //   but it broke the builder
      var _props = this.props,
          _props$actions = _props.actions,
          init = _props$actions.init,
          resetEnquiry = _props$actions.resetEnquiry,
          _props$router = _props.router,
          path = _props$router.path,
          hash = _props$router.hash,
          dispatch = _props.dispatch,
          hashRouting = _props.hashRouting;


      init();
      resetEnquiry();

      var _split$reverse = (hashRouting ? hash : path).split('/').reverse(),
          _split$reverse2 = (0, _slicedToArray3.default)(_split$reverse, 1),
          vehicleId = _split$reverse2[0];

      dispatch(_enquiry.actions.getEnquiryVehicleInfo(vehicleId));
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          _props2$state = _props2.state,
          error = _props2$state.error,
          submissionStatus = _props2$state.submissionStatus,
          locale = _props2$state.locale,
          vinValidity = _props2$state.vinValidity,
          _props2$actions = _props2.actions,
          submitMessage = _props2$actions.submitMessage,
          enquiryFormGoBack = _props2$actions.enquiryFormGoBack,
          requestPrivacyPolicy = _props2$actions.requestPrivacyPolicy,
          params = _props2.router.params,
          actions = _props2.actions,
          history = _props2.history,
          preview = _props2.preview,
          config = _props2.config,
          shared = _props2.shared,
          vehicleInContext = _props2.vehicleInContext,
          globalStyling = _props2.globalStyling,
          state = _props2.state,
          dispatch = _props2.dispatch;


      var noUser = {
        title: '',
        firstName: '',
        lastName: '',
        email: ''
      };
      var userDetails = preview ? noUser : (0, _reducer.getCustomerDetails)(shared) || noUser;
      switch (config.templateType) {
        case 'LamborghiniThirdParty':
          return _react2.default.createElement(_LamborghiniThirdPartyTemplate2.default, {
            config: config,
            vehicle: vehicleInContext,
            globalStyling: globalStyling,
            history: history,
            state: state,
            submissionStatus: submissionStatus,
            actions: actions,
            submitMessage: actions.submitThirdPartyLead,
            enquiryFormGoBack: enquiryFormGoBack,
            vinValidity: vinValidity,
            shared: shared,
            params: params
          });
        case 'TemplateTwo':
          return _react2.default.createElement(_TemplateTwo2.default, {
            config: config,
            vehicle: vehicleInContext,
            history: history,
            globalStyling: globalStyling,
            submitMessage: submitMessage,
            enquiryFormGoBack: enquiryFormGoBack,
            submissionStatus: submissionStatus,
            requestPrivacyPolicy: requestPrivacyPolicy,
            error: error
          });
        case 'AstonMartin':
          return _react2.default.createElement(_AstonMartinTemplate2.default, {
            config: config,
            vehicle: vehicleInContext,
            globalStyling: globalStyling,
            history: history,
            state: state,
            submissionStatus: submissionStatus,
            actions: actions,
            submitMessage: submitMessage,
            enquiryFormGoBack: enquiryFormGoBack,
            vinValidity: vinValidity,
            shared: shared,
            params: params,
            dispatch: dispatch
          });
        case 'Bentley':
          return _react2.default.createElement(_EnquiryTemplate2.default, {
            config: config,
            vehicle: vehicleInContext,
            globalStyling: globalStyling,
            history: history,
            state: state,
            submissionStatus: submissionStatus,
            actions: actions,
            submitMessage: submitMessage,
            enquiryFormGoBack: enquiryFormGoBack,
            vinValidity: vinValidity,
            shared: shared,
            params: params,
            dispatch: dispatch
          });
        default:
          return _react2.default.createElement(_EnquiryForm2.default, {
            preview: preview,
            vehicle: vehicleInContext,
            history: history,
            translations: config.translations,
            error: error,
            submitMessage: submitMessage,
            submissionStatus: submissionStatus,
            enquiryFormGoBack: enquiryFormGoBack,
            locale: locale,
            userDetails: userDetails,
            backgroundImage: config.backgroundImage,
            globalStyling: globalStyling,
            styledDisabledInput: config.styledDisabledInput,
            defaultHeaderFont: config.defaultHeaderFont,
            defaultHeaderBackgroundColour: config.defaultHeaderBackgroundColour,
            largeHeaderFont: config.largeHeaderFont,
            largeHeaderBackgroundColour: config.largeHeaderBackgroundColour,
            enquiryTypeDropdownVisible: config.enquiryTypeDropdownVisible,
            showPostCodeField: config.showPostCodeField,
            postCodeRequired: config.postCodeRequired,
            showAddressFields: config.showAddressFields,
            showVehicleOfInterest: config.showVehicleOfInterest,
            vehicleOfInterestPositionTop: config.vehicleOfInterestPositionTop,
            showVehicleOfInterestSingle: config.showVehicleOfInterestSingle,
            showAddressRows: config.showAddressRows,
            showBookingFields: config.showBookingFields,
            showDualCountyPostCodeRow: config.showDualCountyPostCodeRow,
            calendarStyles: config.calendarStyles,
            enquiryTypes: config.enquiryTypes,
            hideImageContainer: config.hideImageContainer,
            rrVehicleOfInterest: config.rrVehicleOfInterest,
            marketingLink: config.marketingLink,
            applyRRValidations: config.applyRRValidations,
            onDemandValidations: config.onDemandValidations,
            showUserCommunication: config.showUserCommunication,
            sendConsentContent: config.sendConsentContent,
            formWidth: config.formWidth,
            dataConsentFont: config.dataConsentFont,
            showRRCustomDataConsentSection: config.showRRCustomDataConsentSection,
            showJLRMarketingAndDistributionConsent: config.showJLRMarketingAndDistributionConsent,
            showJLRKoreaSpecificConsent: config.showJLRKoreaSpecificConsent,
            showTradeInFields: config.showTradeInFields,
            phoneNumberRequired: config.phoneNumberRequired,
            enquiryFormStyles: config.fontStyles,
            hideYear: config.hideYear,
            enquiryTypeLabel: config.enquiryTypeLabel,
            enquiryTypeValue: config.enquiryTypeValue,
            checkboxLabelStyles: config.checkboxLabelFont,
            useTimeOfDay: config.useTimeOfDay,
            showFullConsentSection: config.showFullConsentSection,
            consentRadioOptions: config.consentRadioOptions,
            commentsRequired: config.commentsRequired,
            commentsSingleLine: config.commentsSingleLine,
            config: config
          });
      }
    }
  }]);
  return Enquiry;
}(_react.Component);

exports.default = Enquiry;