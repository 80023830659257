'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// adding this specificity because Kia.com is overriding our styles and it's breaking the form on production
var Input = _styledComponents2.default.input.withConfig({
  displayName: 'InputField__Input',
  componentId: 'sc-qn012f-0'
})(['&&&&&{width:100%;height:45px;font-size:16px;font-family:inherit;line-height:40px;box-sizing:border-box;border:1px solid ', ';padding-left:15px;padding-right:15px;color:#7e7e7e;background-color:#fff;&:disabled{', ';background-color:#d6d6d6;', ';}&:focus{border:1px solid #999999;color:#444444;outline:none;}&::-webkit-outer-spin-button,&::-webkit-inner-spin-button{appearance:none;margin:0;}&[type=number]{appearance:textfield;}', ';', ';', ';'], function (_ref) {
  var error = _ref.error;
  return error ? '#9e1b32' : '#d8d8d8';
}, function (_ref2) {
  var error = _ref2.error;
  return !error && 'border: none';
}, function (_ref3) {
  var styledDisabledInput = _ref3.styledDisabledInput,
      globalStyling = _ref3.globalStyling;
  return styledDisabledInput && globalStyling && 'color: ' + (globalStyling && globalStyling.colours.primaryBrandColour.value) + ';\n      border: 1px solid ' + (globalStyling && globalStyling.colours.primaryBrandColour.value) + ';\n      background-color: ' + (globalStyling && globalStyling.pageBackgroundColour) + ';\n  ';
}, function (_ref4) {
  var truncateChars = _ref4.truncateChars;
  return truncateChars && (0, _styledComponents.css)(['white-space:nowrap;overflow:hidden;text-overflow:ellipsis;']);
}, function (_ref5) {
  var readOnly = _ref5.readOnly;
  return readOnly && (0, _styledComponents.css)(['cursor:not-allowed;']);
}, function (_ref6) {
  var upperCase = _ref6.upperCase;
  return upperCase && (0, _styledComponents.css)(['text-transform:uppercase;']);
});

var InputField = _react2.default.forwardRef(function (_ref7, ref) {
  var doubleRow = _ref7.doubleRow,
      label = _ref7.label,
      required = _ref7.required,
      type = _ref7.type,
      keyValue = _ref7.keyValue,
      value = _ref7.value,
      onChange = _ref7.onChange,
      onKeyPress = _ref7.onKeyPress,
      error = _ref7.error,
      disabled = _ref7.disabled,
      placeholder = _ref7.placeholder,
      translations = _ref7.translations,
      validate = _ref7.validate,
      truncateChars = _ref7.truncateChars,
      upperCase = _ref7.upperCase,
      globalStyling = _ref7.globalStyling,
      styledDisabledInput = _ref7.styledDisabledInput,
      readOnly = _ref7.readOnly,
      autoComplete = _ref7.autoComplete,
      maxLength = _ref7.maxLength;

  var _ref8 = globalStyling || {},
      _ref8$direction = _ref8.direction,
      direction = _ref8$direction === undefined ? '' : _ref8$direction;

  return _react2.default.createElement(
    _FormField2.default,
    {
      doubleRow: doubleRow,
      label: label,
      required: required,
      globalStyling: globalStyling,
      styledDisabledInput: styledDisabledInput,
      labelFor: keyValue
    },
    _react2.default.createElement(Input, {
      ref: ref,
      type: type,
      value: value,
      name: keyValue,
      id: keyValue,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (e) {
        return onChange(keyValue, upperCase ? e.target.value.toUpperCase() : e.target.value, translations, validate);
      }),
      onKeyPress: onKeyPress,
      disabled: disabled,
      placeholder: placeholder,
      error: error,
      truncateChars: truncateChars,
      upperCase: upperCase,
      globalStyling: globalStyling,
      dir: direction,
      readOnly: readOnly,
      autoComplete: autoComplete,
      maxLength: maxLength,
      styledDisabledInput: styledDisabledInput
    }),
    error && _react2.default.createElement(
      'div',
      {
        style: {
          color: '#9e1b32',
          textAlign: '' + (direction === 'rtl' ? 'right' : 'left')
        }
      },
      error
    )
  );
});

exports.default = InputField;


InputField.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  doubleRow: false,
  onChange: function onChange() {},
  onKeyPress: function onKeyPress() {},
  label: '',
  error: '',
  validate: false,
  readOnly: false,
  truncateChars: false,
  upperCase: false
};