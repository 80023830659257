'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 70%;\n  '], ['\n    width: 70%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 25%;\n  '], ['\n    width: 25%;\n  ']);

exports.default = SelectBar;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useSortResults2 = require('../../hooks/useSortResults');

var _useSortResults3 = _interopRequireDefault(_useSortResults2);

var _useCurrencySelector2 = require('../../hooks/useCurrencySelector');

var _useCurrencySelector3 = _interopRequireDefault(_useCurrencySelector2);

var _settings = require('../../shared/sortOptions/settings');

var _searchResultsTypes = require('../../types/AstonMartin/searchResultsTypes');

var _shortlistPageTypes = require('../../types/AstonMartin/shortlistPageTypes');

var _Form = require('./Form');

var _sortDropdownListOptions = require('../../constants/sortDropdownListOptions');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SortContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SelectBar__SortContainer',
  componentId: 'sc-ww2oot-0'
})(['display:flex;width:', ';justify-content:space-between;'], function (_ref) {
  var fullWidth = _ref.fullWidth;
  return fullWidth ? '100%' : '400px';
});

var Sort = _styledComponents2.default.div.withConfig({
  displayName: 'SelectBar__Sort',
  componentId: 'sc-ww2oot-1'
})(['display:flex;width:45%;', ';'], _theme2.default.max.small(_templateObject));
var Currency = _styledComponents2.default.div.withConfig({
  displayName: 'SelectBar__Currency',
  componentId: 'sc-ww2oot-2'
})(['display:flex;width:45%;', ';'], _theme2.default.max.small(_templateObject2));

function SelectBar(_ref2) {
  var currencies = _ref2.currencies,
      currencyValue = _ref2.currencyValue,
      searchSort = _ref2.searchSort,
      translations = _ref2.translations,
      fullWidth = _ref2.fullWidth;

  var sortValue = searchSort && searchSort.value;

  var _useSortResults = (0, _useSortResults3.default)({ sortOptions: _settings.sortOptions }),
      onSortChange = _useSortResults.onSortChange;

  var _useCurrencySelector = (0, _useCurrencySelector3.default)(),
      onCurrencyChange = _useCurrencySelector.onCurrencyChange;

  var sortOptionsField = function sortOptionsField(fieldValue) {
    return ((0, _values2.default)(_settings.sortOptions).find(function (so) {
      return so.value === fieldValue;
    }) || {}).field;
  };

  var sortOptionsOrder = function sortOptionsOrder(fieldValue) {
    return ((0, _values2.default)(_settings.sortOptions).find(function (so) {
      return so.value === fieldValue;
    }) || {}).order;
  };

  var updateFilters = function updateFilters(sortField) {
    var payload = [{ key: 'sortField', value: [sortOptionsField(sortField)] }, { key: 'sortDirection', value: [sortOptionsOrder(sortField)] }];
    onSortChange(payload, sortField);
  };
  var currencyOptions = currencies.map(function (c) {
    return {
      value: c,
      label: c ? translations['convertTo' + c] : translations.pleaseSelectCurrency
    };
  });

  return _react2.default.createElement(
    SortContainer,
    { fullWidth: fullWidth },
    _react2.default.createElement(
      Sort,
      null,
      _react2.default.createElement(_Form.SelectField, {
        dataCy: 'sort-dropdown',
        onChange: function onChange(e) {
          return updateFilters(e.value);
        },
        id: 'sort',
        value: sortValue,
        label: '',
        required: false,
        disabled: false,
        options: (0, _sortDropdownListOptions.sortDropdownListOptions)(translations)
      })
    ),
    _react2.default.createElement(
      Currency,
      null,
      _react2.default.createElement(_Form.SelectField, {
        dataCy: 'currency-dropdown',
        onChange: function onChange(e) {
          return onCurrencyChange(e.value);
        },
        id: 'currency',
        value: currencyValue,
        label: '',
        required: false,
        disabled: false,
        options: currencyOptions
      })
    )
  );
}