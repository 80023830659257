'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _settings = require('../../../shared/selectors/settings');

var _globalStyling = require('../../../types/globalStyling');

var _styledComponentFactory = require('../../Global/styledComponentFactory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/** Creating button with createElement to be able to use:
 * styleOverride?: () => string;
 * mobileStyleOverride?: () => { queryPath: string; template: string }[];
 */
var ButtonElement = (0, _styledComponentFactory.createElement)('button');

var variantStyles = function variantStyles(fonts) {
  var variant = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'primary';
  return {
    primary: (0, _styledComponents.css)(['background-color:#111;color:#ffffff;font-family:', ';box-shadow:inset 0 0 0 0 #fff;transition:ease-out 0.4s;border:1px solid #fff !important;&:hover{background-color:#fff;color:#000;border:1px solid #111 !important;box-shadow:inset 600px 0 0 0 #fff;}'], fonts.primaryButtonFont && fonts.primaryButtonFont.value),
    secondary: (0, _styledComponents.css)(['background-color:#111;color:#fff;border:1px solid #ffffff !important;background-color:transparent;font-family:\'GenesisSansTextGlobal-Regular\';box-shadow:inset 0 0 0 0 #fff;transition:ease-out 0.4s;height:56px;&:hover{border:2px solid #fff;color:#000;box-shadow:inset 600px 0 0 0 #fff;}']),
    tertiary: (0, _styledComponents.css)(['box-sizing:border-box;background-color:#fff;color:#111;font-family:', ';box-shadow:inset 0 0 0 0 #111;transition:ease-out 0.4s;&&{border:1px solid #111;}&:hover{background-color:#111;border:1px solid #fff;color:#fff;box-shadow:inset 600px 0 0 0 #111;}'], fonts.primaryButtonFont && fonts.primaryButtonFont.value)
  }[variant];
};

var BaseButton = (0, _styledComponents2.default)(ButtonElement).withConfig({
  displayName: 'Button__BaseButton',
  componentId: 'sc-1a7ysk3-0'
})(['padding:14px 24px;display:flex;align-items:center;background-color:transparent;box-sizing:border-box;', ' &:disabled{opacity:0.8;cursor:not-allowed;}'], function (_ref) {
  var fonts = _ref.fonts,
      variant = _ref.variant;
  return variantStyles(fonts, variant);
});

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'Button__Label',
  componentId: 'sc-1a7ysk3-1'
})(['font-size:16px;display:flex;align-items:center;justify-content:center;']);

var ButtonIcon = _styledComponents2.default.img.withConfig({
  displayName: 'Button__ButtonIcon',
  componentId: 'sc-1a7ysk3-2'
})(['margin-left:10px;']);

var Button = function Button(props) {
  var onClick = props.onClick,
      text = props.text,
      icon = props.icon,
      children = props.children,
      styleOverride = props.styleOverride,
      mobileStyleOverride = props.mobileStyleOverride,
      disabled = props.disabled,
      rest = (0, _objectWithoutProperties3.default)(props, ['onClick', 'text', 'icon', 'children', 'styleOverride', 'mobileStyleOverride', 'disabled']);

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts,
      colours = _useSelector.colours;

  return _react2.default.createElement(
    BaseButton,
    (0, _extends3.default)({
      onClick: function (_onClick) {
        function onClick(_x2) {
          return _onClick.apply(this, arguments);
        }

        onClick.toString = function () {
          return _onClick.toString();
        };

        return onClick;
      }(function (e) {
        return !disabled && onClick(e);
      }),
      fonts: fonts,
      colours: colours,
      disabled: disabled
    }, rest, {
      styleOverride: styleOverride
    }, (0, _extends3.default)({}, props, (0, _styledComponentFactory.mediaProps)(mobileStyleOverride && mobileStyleOverride()))),
    _react2.default.createElement(
      Label,
      null,
      text || children,
      icon && _react2.default.createElement(ButtonIcon, { src: icon, 'aria-hidden': 'true' })
    )
  );
};

Button.defaultProps = {
  styleOverride: function styleOverride() {
    return '';
  },
  mobileStyleOverride: function mobileStyleOverride() {
    return [];
  },
  children: ''
};

exports.default = Button;