'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validCheckboxes = exports.validPhone = exports.validEmail = exports.required = undefined;

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _validate = require('validate.js');

var _validate2 = _interopRequireDefault(_validate);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var required = exports.required = function required(value, translations) {
  return _validate2.default.single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    }
  });
}; /* eslint-disable import/extensions */
var validEmail = exports.validEmail = function validEmail(value, translations) {
  return _validate2.default.single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    },
    email: {
      message: translations.formValidationEmail
    }
  });
};

var validPhone = exports.validPhone = function validPhone(value, translations) {
  return _validate2.default.single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    },
    phone: {
      message: translations.formValidationPhone
    }
  });
};

_validate2.default.validators.isValidCheckboxes = function (value, options) {
  return (0, _values2.default)(value).some(function (v) {
    return v;
  }) ? undefined : options.message;
};

var validCheckboxes = exports.validCheckboxes = function validCheckboxes(value, translations) {
  return _validate2.default.single(value, {
    isValidCheckboxes: {
      message: translations.formValidationCheckboxes
    }
  });
};