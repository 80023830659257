'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n  '], ['\n    ', ';\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']); /* eslint-disable no-nested-ternary */


exports.default = IconGrid;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../Global');

var _ContentWrapper = require('../../ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__Container',
  componentId: 'sc-ysujtv-0'
})(['width:100%;display:flex;box-sizing:border-box;', ';', ';', ';', ';@media print{display:none;}'], function (_ref) {
  var backgroundImage = _ref.backgroundImage;
  return backgroundImage && 'background-image: url("' + backgroundImage.value + '")';
}, function (_ref2) {
  var backgroundImage = _ref2.backgroundImage;
  return backgroundImage && 'background-size: cover';
}, function (_ref3) {
  var backgroundImage = _ref3.backgroundImage;
  return backgroundImage && 'background-position: center';
}, function (_ref4) {
  var colour = _ref4.colour;
  return colour && 'background-color: ' + colour.value;
});

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__ImageOverlay',
  componentId: 'sc-ysujtv-1'
})(['', ';width:100%;'], function (_ref5) {
  var showOverlay = _ref5.showOverlay;
  return '' + (showOverlay && 'background-color: rgba(0,0,0,0.6);');
});

var IconGridContainer = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__IconGridContainer',
  componentId: 'sc-ysujtv-2'
})(['', ';box-sizing:border-box;text-align:center;width:100%;', ';'], function (_ref6) {
  var padding = _ref6.padding;
  return 'padding:\n    ' + (padding.horizontal !== undefined && padding.horizontal + 'px' || '30px') + '\n    ' + (padding.vertical !== undefined && padding.vertical + 'px' || '25px');
}, _theme2.default.max.medium(_templateObject, function (_ref7) {
  var padding = _ref7.padding;
  return '' + (padding.horizontal !== undefined && 'padding: ' + padding.horizontal + 'px 5%');
}));

var HeadlineWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__HeadlineWrapper',
  componentId: 'sc-ysujtv-3'
})(['width:100%;display:flex;flex-direction:column;', ';margin-top:0px;margin-bottom:40px;'], function (_ref8) {
  var align = _ref8.align;
  return '' + (align && 'align-items: ' + align);
});

var ItemsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__ItemsContainer',
  componentId: 'sc-ysujtv-4'
})(['width:100%;display:flex;flex-wrap:wrap;justify-content:center;padding:0px;']);

var IconGridItem = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__IconGridItem',
  componentId: 'sc-ysujtv-5'
})(['', ';min-width:33%;align-items:center;display:flex;flex-direction:column;justify-content:flex-start;', ';', ';'], function (_ref9) {
  var width = _ref9.width;
  return '' + (width && 'width:' + width + '%');
}, function (_ref10) {
  var paddingBottom = _ref10.paddingBottom;
  return 'padding-bottom: ' + (paddingBottom ? paddingBottom + 'px' : '30px');
}, _theme2.default.max.medium(_templateObject2));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'IconGrid__Image',
  componentId: 'sc-ysujtv-6'
})(['margin:', ';', ';', ';', ';', ';'], function (_ref11) {
  var margin = _ref11.margin;
  return '' + (margin || '4px 0');
}, function (_ref12) {
  var width = _ref12.width;
  return '' + (width && 'width:' + width + 'px');
}, function (_ref13) {
  var percentWidth = _ref13.percentWidth;
  return '' + (percentWidth && 'width:' + percentWidth + '%');
}, function (_ref14) {
  var maxWidth = _ref14.maxWidth;
  return '' + (maxWidth && 'max-width:' + maxWidth + 'px');
}, function (_ref15) {
  var height = _ref15.height;
  return '' + (height && 'height:' + height + 'px');
});

var alignItems = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

function IconGrid(_ref16) {
  var config = _ref16.config,
      globalStyling = _ref16.globalStyling;

  var showOverlay = config.backgroundImageActive && config.backgroundImage;
  return _react2.default.createElement(
    Container,
    {
      id: config.moduleId,
      colour: config.backgroundColour,
      backgroundImage: config.backgroundImageActive && config.backgroundImage
    },
    _react2.default.createElement(
      ImageOverlay,
      { showOverlay: showOverlay },
      _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: globalStyling.contentWidth },
        _react2.default.createElement(
          IconGridContainer,
          {
            padding: {
              horizontal: config.horizontalPadding,
              vertical: config.verticalPadding
            }
          },
          _react2.default.createElement(
            HeadlineWrapper,
            {
              align: alignItems[config.preownedBenefits.headLineAlignment]
            },
            config.translations.BenefitsHeadLineText && _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return '\n                    ' + (0, _Global.fontStyleOverride)(config.preownedBenefits.headlineFont) + '\n                  ';
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'max.medium',
                    template: '\n                    ' + (0, _Global.fontStyleOverride)(config.preownedBenefits.mobileHeadlineFont)
                  }];
                },
                displayHeadingDivider: config.preownedBenefits.displayHeadingDivider,
                headingDividerStyle: config.preownedBenefits.headingDividerStyle
              },
              config.translations.BenefitsHeadLineText
            ),
            config.translations.BenefitsSubHeadlineText && _react2.default.createElement(
              _Global.Paragraph,
              {
                styleOverride: function styleOverride() {
                  return '\n                    ' + (config.marginBetweenSubheader && 'margin: ' + config.marginBetweenSubheader + ';') + '\n                      ' + (0, _Global.fontStyleOverride)(config.preownedBenefits.descriptionFont) + '\n                      max-width: 65%;\n                    ';
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'max.medium',
                    template: 'max-width: 100%'
                  }];
                }
              },
              config.translations.BenefitsSubHeadlineText
            ),
            config.brandLogoUrl && _react2.default.createElement(Image, {
              src: config.brandLogoUrl.value,
              alt: config.brandLogoUrl.label,
              percentWidth: config.brandLogoUrl.percentWidth,
              maxWidth: config.brandLogoUrl.maxWidth,
              height: config.brandLogoUrl.height,
              margin: config.brandLogoUrl.margin
            }),
            config.preownedBenefits.headerImage && _react2.default.createElement(Image, {
              src: config.preownedBenefits.headerImage.value,
              alt: config.preownedBenefits.headerImage.label,
              percentWidth: config.preownedBenefits.headerImage.percentWidth,
              maxWidth: config.preownedBenefits.headerImage.maxWidth,
              height: config.preownedBenefits.headerImage.height,
              margin: config.preownedBenefits.headerImage.margin
            })
          ),
          _react2.default.createElement(
            ItemsContainer,
            null,
            config.preownedBenefits.items.map(function (icongrid, index) {
              return [_react2.default.createElement(
                IconGridItem,
                {
                  key: index.toString(),
                  paddingBottom: config.itemBottomPadding,
                  width: config.preownedBenefits.items.length !== 4 ? 100 / config.preownedBenefits.items.length : 50
                },
                icongrid.image.value && _react2.default.createElement(Image, {
                  src: icongrid.image.value,
                  alt: icongrid.image.label,
                  width: icongrid.image.width,
                  height: icongrid.image.height
                }),
                icongrid.translationKeys && config.translations[icongrid.translationKeys.title] && _react2.default.createElement(
                  _Global.HeadingTwo,
                  {
                    styleOverride: function styleOverride() {
                      return '\n                        ' + (0, _Global.fontStyleOverride)(config.preownedBenefits.itemHeaderFont) + '\n                        width: ' + (config.preownedBenefits.itemHeaderFont && config.preownedBenefits.itemHeaderFont.width ? config.preownedBenefits.itemHeaderFont.width + '%' : '80%') + ';\n                        margin: 4px 0;\n                      ';
                    },
                    displayHeadingDivider: config.preownedBenefits.displayHeadingDivider,
                    headingDividerStyle: config.preownedBenefits.headingDividerStyle
                  },
                  config.translations[icongrid.translationKeys.title]
                ),
                icongrid.translationKeys && config.translations[icongrid.translationKeys.description] && _react2.default.createElement(
                  _Global.Paragraph,
                  {
                    styleOverride: function styleOverride() {
                      return '\n                        ' + (0, _Global.fontStyleOverride)(config.itemDescriptionFont || config.descriptionFont) + '\n                        width: 80%;\n                        margin: 4px 0px;\n                      ';
                    }
                  },
                  config.translations[icongrid.translationKeys.description]
                )
              )];
            })
          )
        )
      )
    )
  );
}