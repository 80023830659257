'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../../theme');

var _numbers = require('../../../../shared/localisation/numbers');

var _settings = require('../../../../shared/selectors/settings');

var _vehicle = require('../../../../helpers/vehicle');

var _Global = require('../../../components/Global');

var _WindowDimensionsProvider = require('../../../../shared/WindowDimensionsProvider');

var _images = require('./images');

var _images2 = _interopRequireDefault(_images);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var eyeWhiteImg = _images2.default.eyeWhiteImg,
    imagesIcon = _images2.default.imagesIcon,
    mapPinImg = _images2.default.mapPinImg,
    approvedLogoImg = _images2.default.approvedLogoImg,
    compareIconImgOff = _images2.default.compareIconImgOff,
    shortlistIconImgOff = _images2.default.shortlistIconImgOff,
    compareIconImgOn = _images2.default.compareIconImgOn,
    shortlistIconImgOn = _images2.default.shortlistIconImgOn,
    registration = _images2.default.registration,
    odometer = _images2.default.odometer,
    modelyear = _images2.default.modelyear,
    exterior = _images2.default.exterior,
    interior = _images2.default.interior,
    transmission = _images2.default.transmission;


var highlightIcons = {
  registration: registration,
  odometer: odometer,
  modelyear: modelyear,
  exterior: exterior,
  interior: interior,
  transmission: transmission
};

var Image = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__Image',
  componentId: 'sc-nny90z-0'
})(['position:relative;width:100%;background-image:url(\'', '\');background-size:cover;background-repeat:no-repeat;background-position:center;min-height:', 'px;cursor:pointer;'], function (props) {
  return props.src;
}, function (props) {
  return props.imageHeight || 200;
});

var ImageMedium = (0, _styledComponents2.default)(Image).withConfig({
  displayName: 'DefaultTemplate__ImageMedium',
  componentId: 'sc-nny90z-1'
})(['height:400px;']);

var ImageExtraLarge = (0, _styledComponents2.default)(Image).withConfig({
  displayName: 'DefaultTemplate__ImageExtraLarge',
  componentId: 'sc-nny90z-2'
})(['flex:1 0 35%;']);

var ViewsImages = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__ViewsImages',
  componentId: 'sc-nny90z-3'
})(['position:absolute;bottom:0;background-color:rgba(0,0,0,0.7);width:100%;height:25px;width:100%;justify-content:space-between;flex-direction:row;color:#ffffff;']);

var ViewsCount = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__ViewsCount',
  componentId: 'sc-nny90z-4'
})(['display:flex;background-image:url(', ');background-repeat:no-repeat;background-position:8px center;background-size:20px;width:50%;padding-left:35px;align-items:center;'], function (props) {
  return props.src;
});

var ImagesCount = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__ImagesCount',
  componentId: 'sc-nny90z-5'
})(['display:flex;justify-content:flex-end;background-image:url(', ');background-repeat:no-repeat;background-position:right 8px center;background-size:20px;padding-right:35px;width:', ';height:25px;align-items:center;'], function (props) {
  return props.src;
}, function (_ref) {
  var showViews = _ref.showViews;
  return showViews ? '50%' : '100%';
});

var DetailsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__DetailsWrapper',
  componentId: 'sc-nny90z-6'
})(['flex-direction:column;padding:8px;']);

var DetailsWrapperExtraLarge = (0, _styledComponents2.default)(DetailsWrapper).withConfig({
  displayName: 'DefaultTemplate__DetailsWrapperExtraLarge',
  componentId: 'sc-nny90z-7'
})(['flex:1 1 50%;']);

var TitleWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__TitleWrapper',
  componentId: 'sc-nny90z-8'
})(['flex-direction:column;padding:8px;min-height:80px;']);

var TitleWrapperLarge = (0, _styledComponents2.default)(TitleWrapper).withConfig({
  displayName: 'DefaultTemplate__TitleWrapperLarge',
  componentId: 'sc-nny90z-9'
})(['min-height:100px;']);

var Title = _styledComponents2.default.h2.withConfig({
  displayName: 'DefaultTemplate__Title',
  componentId: 'sc-nny90z-10'
})(['font-size:18px;font-weight:600;text-transform:uppercase;color:rgb(31,31,31);margin-top:0;padding-top:0;']);

var Description = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__Description',
  componentId: 'sc-nny90z-11'
})(['font-size:14px;']);

var HighlightsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__HighlightsWrapper',
  componentId: 'sc-nny90z-12'
})(['flex:2 0;flex-wrap:wrap;border-top:1px solid #cccccc;border-bottom:1px solid #cccccc;']);

var Highlight = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__Highlight',
  componentId: 'sc-nny90z-13'
})(['width:50%;display:flex;flex-direction:column;flex:1 0 50%;font-size:14px;padding:8px 0 8px 40px;background-image:url(', ');background-position:4px center;background-repeat:no-repeat;background-size:30px;'], function (props) {
  return props.src;
});

var HighlightLabel = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__HighlightLabel',
  componentId: 'sc-nny90z-14'
})(['']);
var HighlightValue = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__HighlightValue',
  componentId: 'sc-nny90z-15'
})(['font-weight:bold;']);

var DistanceWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__DistanceWrapper',
  componentId: 'sc-nny90z-16'
})(['height:40px;align-items:center;padding:8px;']);

var MapPinIcon = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__MapPinIcon',
  componentId: 'sc-nny90z-17'
})(['background-image:url(', ');background-position:4px center;background-repeat:no-repeat;background-size:15px;width:25px;height:30px;'], function (props) {
  return props.src;
});

var Price = _styledComponents2.default.h2.withConfig({
  displayName: 'DefaultTemplate__Price',
  componentId: 'sc-nny90z-18'
})(['flex-direction:column;font-size:24px;width:50%;font-family:RobotoBold;color:rgb(31,31,31);margin-top:0;padding-top:0;']);

var PriceLabel = _styledComponents2.default.p.withConfig({
  displayName: 'DefaultTemplate__PriceLabel',
  componentId: 'sc-nny90z-19'
})(['font-size:14px;color:#cccccc;margin:0;padding:0;']);

var PriceLogo = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__PriceLogo',
  componentId: 'sc-nny90z-20'
})(['padding:8px;justify-content:space-between;']);

var ApprovedLogo = _styledComponents2.default.img.withConfig({
  displayName: 'DefaultTemplate__ApprovedLogo',
  componentId: 'sc-nny90z-21'
})(['height:auto;width:40px;']);

var CTALabel = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__CTALabel',
  componentId: 'sc-nny90z-22'
})(['display:none;']);

var CTA = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__CTA',
  componentId: 'sc-nny90z-23'
})(['flex:1 1;justify-content:flex-end;align-items:flex-end;padding:8px;']);

var CompareShortlist = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__CompareShortlist',
  componentId: 'sc-nny90z-24'
})(['display:flex;flex:1 1 25%;> img{margin:0 3px 0;}']);

var PriceLogoExtraLarge = (0, _styledComponents2.default)(PriceLogo).withConfig({
  displayName: 'DefaultTemplate__PriceLogoExtraLarge',
  componentId: 'sc-nny90z-25'
})(['flex-direction:column;flex:1 0 250px;border-bottom:none;padding:8px;button{width:100%;}', '{font-size:24px;height:45px;padding-left:8px;}', '{border-top:none;flex-direction:column;', '{display:block;}}', '{width:100%;flex:0 1 30px;align-items:center;margin-bottom:8px;}'], Price, CTA, CTALabel, CompareShortlist);

var CompareLink = _styledComponents2.default.img.withConfig({
  displayName: 'DefaultTemplate__CompareLink',
  componentId: 'sc-nny90z-26'
})(['width:40px;height:40px;']);

var FavouriteLink = _styledComponents2.default.img.withConfig({
  displayName: 'DefaultTemplate__FavouriteLink',
  componentId: 'sc-nny90z-27'
})(['width:40px;height:40px;']);

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__Wrapper',
  componentId: 'sc-nny90z-28'
})(['color:#666;border:1px solid #ccc;display:flex;flex-direction:column;justify-content:flex-start;width:calc(100% - 10px);margin:5px;div{display:flex;}', '{display:none;}'], CTALabel);

var WrapperMedium = (0, _styledComponents2.default)(Wrapper).withConfig({
  displayName: 'DefaultTemplate__WrapperMedium',
  componentId: 'sc-nny90z-29'
})(['width:calc(50% - 10px);']);

var WrapperLarge = (0, _styledComponents2.default)(Wrapper).withConfig({
  displayName: 'DefaultTemplate__WrapperLarge',
  componentId: 'sc-nny90z-30'
})(['width:calc(33.3333% - 10px);']);

var WrapperExtraLarge = (0, _styledComponents2.default)(Wrapper).withConfig({
  displayName: 'DefaultTemplate__WrapperExtraLarge',
  componentId: 'sc-nny90z-31'
})(['min-height:250px;flex-direction:row;']);

var PriceLabelWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DefaultTemplate__PriceLabelWrapper',
  componentId: 'sc-nny90z-32'
})(['display:flex;flex-wrap:wrap;']);

exports.default = function (_ref2) {
  var vehicle = _ref2.vehicle,
      _ref2$vehicle = _ref2.vehicle,
      name = _ref2$vehicle.name,
      price = _ref2$vehicle.price,
      images = _ref2$vehicle.images,
      views = _ref2$vehicle.views,
      retailerName = _ref2$vehicle.retailerName,
      distance = _ref2$vehicle.distance,
      approved = _ref2$vehicle.approved,
      uiElements = _ref2.globalStyling.uiElements,
      _ref2$config = _ref2.config,
      title = _ref2$config.title,
      description = _ref2$config.description,
      highlights = _ref2$config.highlights,
      translations = _ref2$config.translations,
      showViews = _ref2$config.showViews,
      pricing = _ref2$config.pricing,
      isCompare = _ref2.isCompare,
      isShortlisted = _ref2.isShortlisted,
      handleCompareVehicleClick = _ref2.handleCompareVehicleClick,
      handleShortlistVehicleClick = _ref2.handleShortlistVehicleClick,
      handleMoreInfoClick = _ref2.handleMoreInfoClick;

  var _useWindowDimensions = (0, _WindowDimensionsProvider.useWindowDimensions)(),
      width = _useWindowDimensions.width;

  var locale = (0, _reactRedux.useSelector)(_settings.getLocale);
  var descriptionOutput = description.map(function (d) {
    return (0, _vehicle.vehiclePropFormatter)(d, vehicle, locale);
  }).join(' | ');
  var titleOutput = title.map(function (t) {
    return (0, _vehicle.vehiclePropFormatter)(t, vehicle, locale);
  }).join(' ');
  var imagesCount = images.length;

  var financeCellTextNoDigits = function financeCellTextNoDigits(priceEntry, currency) {
    return (0, _numbers.localiseCurrency)(priceEntry, locale, currency, 0) || translations.searchResultsNotAvailableLabel;
  };

  var displayPrice = financeCellTextNoDigits(price.value && Number(price.value), price.currency);

  var formattedPricing = pricing.map(function (p) {
    if (p.prop === 'totalPrice') return displayPrice;
    return (0, _vehicle.vehiclePropFormatter)(p, vehicle, locale);
  });

  var Prices = function Prices() {
    return _react2.default.createElement(
      PriceLabelWrapper,
      null,
      formattedPricing.map(function (p) {
        return p && _react2.default.createElement(
          Price,
          null,
          p,
          _react2.default.createElement(
            PriceLabel,
            null,
            'price'
          )
        );
      })
    );
  };

  var Highlights = function Highlights() {
    return _react2.default.createElement(
      HighlightsWrapper,
      null,
      highlights && highlights.map(function (h) {
        return {
          label: translations[h.prop],
          value: (0, _vehicle.vehiclePropFormatter)(h, vehicle, locale),
          src: highlightIcons[h.prop.toLowerCase()]
        };
      }).filter(Boolean).map(function (_ref3) {
        var label = _ref3.label,
            value = _ref3.value,
            src = _ref3.src;
        return _react2.default.createElement(
          Highlight,
          { key: '' + label + value, src: src },
          _react2.default.createElement(
            HighlightLabel,
            null,
            label
          ),
          _react2.default.createElement(
            HighlightValue,
            null,
            value
          )
        );
      })
    );
  };

  var CTAWrapper = function CTAWrapper() {
    return _react2.default.createElement(
      CTA,
      null,
      _react2.default.createElement(
        CompareShortlist,
        null,
        _react2.default.createElement(CompareLink, {
          src: isCompare ? compareIconImgOn : compareIconImgOff,
          alt: translations.compareLabel,
          onClick: handleCompareVehicleClick
        }),
        _react2.default.createElement(
          CTALabel,
          null,
          'Compare'
        ),
        _react2.default.createElement(FavouriteLink, {
          src: isShortlisted ? shortlistIconImgOn : shortlistIconImgOff,
          alt: translations.favouriteLabel,
          onClick: handleShortlistVehicleClick
        }),
        _react2.default.createElement(
          CTALabel,
          null,
          'Shortlist'
        )
      ),
      _react2.default.createElement(_Global.Button, {
        buttonStyle: uiElements.primaryButton && uiElements.primaryButton.buttonStyle,
        applyStyle: 'primary',
        text: translations.ctaButtonLabel,
        onClick: handleMoreInfoClick
      })
    );
  };

  var Approved = function Approved() {
    return approved && _react2.default.createElement(ApprovedLogo, { src: approvedLogoImg, alt: translations.approvedLabel });
  };

  var PriceLogoWrapper = function PriceLogoWrapper() {
    return _react2.default.createElement(
      PriceLogo,
      null,
      _react2.default.createElement(Approved, null),
      _react2.default.createElement(
        PriceLabelWrapper,
        null,
        _react2.default.createElement(
          Price,
          null,
          displayPrice,
          _react2.default.createElement(
            PriceLabel,
            null,
            'price'
          )
        )
      )
    );
  };

  var ViewsImagesWrapper = function ViewsImagesWrapper() {
    return _react2.default.createElement(
      ViewsImages,
      null,
      showViews && _react2.default.createElement(
        ViewsCount,
        { src: eyeWhiteImg },
        views + ' ' + translations.viewsLabel
      ),
      _react2.default.createElement(
        ImagesCount,
        { src: imagesIcon, showViews: showViews },
        imagesCount
      )
    );
  };

  var TitleContent = function TitleContent() {
    return _react2.default.createElement(
      _react2.default.Fragment,
      null,
      _react2.default.createElement(
        Title,
        null,
        titleOutput
      ),
      _react2.default.createElement(
        Description,
        null,
        descriptionOutput
      )
    );
  };

  if (width > _theme.screenSizeOptions.extraLarge) {
    return _react2.default.createElement(
      WrapperExtraLarge,
      null,
      _react2.default.createElement(
        ImageExtraLarge,
        {
          src: images[0],
          onClick: handleMoreInfoClick,
          role: 'img',
          ariaLabel: name,
          title: name
        },
        _react2.default.createElement(ViewsImagesWrapper, null)
      ),
      _react2.default.createElement(
        DetailsWrapperExtraLarge,
        null,
        _react2.default.createElement(
          TitleWrapper,
          null,
          _react2.default.createElement(TitleContent, null)
        ),
        _react2.default.createElement(Highlights, null),
        _react2.default.createElement(
          DistanceWrapper,
          null,
          _react2.default.createElement(MapPinIcon, { src: mapPinImg }),
          retailerName,
          ' ',
          distance && distance + 'km away'
        )
      ),
      _react2.default.createElement(
        PriceLogoExtraLarge,
        null,
        _react2.default.createElement(Prices, null),
        _react2.default.createElement(Approved, null),
        _react2.default.createElement(CTAWrapper, null)
      )
    );
  }

  if (width > _theme.screenSizeOptions.large) {
    return _react2.default.createElement(
      WrapperLarge,
      null,
      _react2.default.createElement(
        TitleWrapperLarge,
        null,
        _react2.default.createElement(TitleContent, null)
      ),
      _react2.default.createElement(
        Image,
        {
          src: images[0],
          onClick: handleMoreInfoClick,
          role: 'img',
          ariaLabel: name,
          title: name
        },
        _react2.default.createElement(ViewsImagesWrapper, null)
      ),
      _react2.default.createElement(PriceLogoWrapper, null),
      _react2.default.createElement(Highlights, null),
      _react2.default.createElement(CTAWrapper, null)
    );
  }

  if (width > _theme.screenSizeOptions.medium) {
    return _react2.default.createElement(
      WrapperMedium,
      null,
      _react2.default.createElement(
        TitleWrapper,
        null,
        _react2.default.createElement(TitleContent, null)
      ),
      _react2.default.createElement(
        Image,
        {
          src: images[0],
          onClick: handleMoreInfoClick,
          role: 'img',
          ariaLabel: name,
          title: name
        },
        _react2.default.createElement(ViewsImagesWrapper, null)
      ),
      _react2.default.createElement(PriceLogoWrapper, null),
      _react2.default.createElement(Highlights, null),
      _react2.default.createElement(CTAWrapper, null)
    );
  }

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      TitleWrapper,
      null,
      _react2.default.createElement(TitleContent, null)
    ),
    _react2.default.createElement(
      ImageMedium,
      {
        src: images[0],
        onClick: handleMoreInfoClick,
        role: 'img',
        ariaLabel: name,
        title: name
      },
      _react2.default.createElement(ViewsImagesWrapper, null)
    ),
    _react2.default.createElement(PriceLogoWrapper, null),
    _react2.default.createElement(Highlights, null),
    _react2.default.createElement(CTAWrapper, null)
  );
};