'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectMenu = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _Global = require('../../components/Global');

var _SocialMediaShare = require('../../components/SocialMediaShare');

var _SocialMediaShare2 = _interopRequireDefault(_SocialMediaShare);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MobileMenuWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MobileMenu__MobileMenuWrapper',
  componentId: 'sc-mjhl8n-0'
})(['width:100%;height:100vh;', ';z-index:100;position:fixed;top:0;left:100%;transition:left 0.6s;', ';'], function (_ref) {
  var backgroundColour = _ref.backgroundColour;
  return backgroundColour && 'background-color: ' + backgroundColour;
}, function (_ref2) {
  var open = _ref2.open;
  return open && 'left: 0;';
});

var Close = _styledComponents2.default.div.withConfig({
  displayName: 'MobileMenu__Close',
  componentId: 'sc-mjhl8n-1'
})(['role:button;color:#ffffff;padding:20px;display:flex;justify-content:flex-end;']);
var Links = _styledComponents2.default.ul.withConfig({
  displayName: 'MobileMenu__Links',
  componentId: 'sc-mjhl8n-2'
})(['list-style:none;padding:0;width:100%;border-top:1px solid #808080;']);
var ActionsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MobileMenu__ActionsWrapper',
  componentId: 'sc-mjhl8n-3'
})(['role:button;display:flex;justify-content:center;padding:15px 20px;border-bottom:1px solid #808080;']);
var Select = _styledComponents2.default.select.withConfig({
  displayName: 'MobileMenu__Select',
  componentId: 'sc-mjhl8n-4'
})(['', ';outline:none;background-color:transparent;-moz-appearance:none;-webkit-appearance:none;padding:0 8px;::-ms-expand{display:none;}option{color:#000000;}margin:0 7.5px;text-align:center;', ''], function (_ref3) {
  var font = _ref3.font;
  return font && (0, _Global.fontStyleOverride)(font);
}, function (_ref4) {
  var selectBorderColour = _ref4.selectBorderColour;
  return selectBorderColour && selectBorderColour.value && 'border: 1px solid ' + selectBorderColour.value + ';';
});
var ShareIcon = _styledComponents2.default.img.withConfig({
  displayName: 'MobileMenu__ShareIcon',
  componentId: 'sc-mjhl8n-5'
})(['opacity:0.6;']);

var Link = _styledComponents2.default.a.withConfig({
  displayName: 'MobileMenu__Link',
  componentId: 'sc-mjhl8n-6'
})(['text-decoration:none;']);

var HamburgerMenuButton = _styledComponents2.default.button.withConfig({
  displayName: 'MobileMenu__HamburgerMenuButton',
  componentId: 'sc-mjhl8n-7'
})(['background:inherit;border:none;cursor:pointer;']);

function SocialMediaLinks(_ref5) {
  var config = _ref5.config,
      socialShare = _ref5.socialShare;

  return _react2.default.createElement(
    ActionsWrapper,
    null,
    _react2.default.createElement(ShareIcon, { src: config.socialShareIconUrl }),
    _react2.default.createElement(_SocialMediaShare2.default, {
      iconColour: '#FFFFFF',
      shareUrl: window.location.href,
      socialShare: socialShare,
      height: 0
    })
  );
}

var TitleWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MobileMenu__TitleWrapper',
  componentId: 'sc-mjhl8n-8'
})(['display:flex;justify-content:center;flex-direction:column;']);

function SelectMenu(_ref6) {
  var config = _ref6.config,
      changeLanguage = _ref6.changeLanguage,
      selectedLanguage = _ref6.selectedLanguage,
      languages = _ref6.languages,
      changeCurrency = _ref6.changeCurrency,
      selectedCurrency = _ref6.selectedCurrency,
      currencies = _ref6.currencies,
      onMenuToggle = _ref6.onMenuToggle,
      territories = _ref6.territories,
      selectedTerritory = _ref6.selectedTerritory,
      changeTerritory = _ref6.changeTerritory,
      enableTitles = _ref6.enableTitles;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    territories && _react2.default.createElement(
      TitleWrapper,
      null,
      enableTitles && _react2.default.createElement(
        _Global.HeadingThree,
        { styleOverride: function styleOverride() {
            return 'padding-bottom: 10px;';
          } },
        config.translations.territorySelector
      ),
      _react2.default.createElement(
        Select,
        {
          font: config.menuBarLinkFont,
          selectBorderColour: config.selectBorderColour,
          value: selectedTerritory,
          onChange: function onChange(event) {
            var territoryLanguageMap = {
              na: {
                en_gb: 'en_us'
              },
              row: {
                en_us: 'en_gb'
              }
            };

            var territory = territories.find(function (t) {
              return t.value === event.target.value;
            }).value;
            var language = territoryLanguageMap[territory][selectedLanguage.value] || selectedLanguage.value;
            changeTerritory({
              territory: territory,
              language: language
            });
          }
        },
        territories.map(function (territory) {
          return _react2.default.createElement(
            'option',
            { key: (0, _v2.default)(), value: territory.value },
            territory.value.toUpperCase()
          );
        })
      )
    ),
    _react2.default.createElement(
      TitleWrapper,
      null,
      enableTitles && _react2.default.createElement(
        _Global.HeadingThree,
        { styleOverride: function styleOverride() {
            return 'padding-bottom: 10px;';
          } },
        config.translations.langSelector
      ),
      _react2.default.createElement(
        Select,
        {
          'data-cy': 'mobile-locales',
          font: config.menuBarLinkFont,
          selectBorderColour: config.selectBorderColour,
          value: selectedLanguage.value,
          onChange: function onChange(event) {
            return changeLanguage(languages.find(function (l) {
              return l.value === event.target.value;
            }));
          }
        },
        languages.map(function (lang) {
          return _react2.default.createElement(
            'option',
            { key: (0, _v2.default)(), value: lang.value },
            lang.labelShorthand
          );
        })
      )
    ),
    _react2.default.createElement(
      TitleWrapper,
      null,
      enableTitles && _react2.default.createElement(
        _Global.HeadingThree,
        { styleOverride: function styleOverride() {
            return 'padding-bottom: 10px;';
          } },
        config.translations.currencySelector
      ),
      _react2.default.createElement(
        Select,
        {
          font: config.menuBarLinkFont,
          selectBorderColour: config.selectBorderColour,
          value: selectedCurrency ? selectedCurrency.value : '',
          onChange: function onChange(event) {
            changeCurrency(currencies.find(function (c) {
              return c.value === event.target.value;
            }));
            onMenuToggle(false);
          }
        },
        !selectedCurrency && _react2.default.createElement(
          'option',
          { value: '' },
          '\xA3 / $'
        ),
        currencies.map(function (curr) {
          return _react2.default.createElement(
            'option',
            { key: (0, _v2.default)(), value: curr.value },
            curr.labelShorthand
          );
        })
      )
    )
  );
}

var MobileMenu = function (_Component) {
  (0, _inherits3.default)(MobileMenu, _Component);

  function MobileMenu(props) {
    (0, _classCallCheck3.default)(this, MobileMenu);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MobileMenu.__proto__ || (0, _getPrototypeOf2.default)(MobileMenu)).call(this, props));

    Object.defineProperty(_this, 'onMenuToggle', {
      enumerable: true,
      writable: true,
      value: function value(open) {
        _this.setState(function () {
          return { open: open };
        });
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            config = _this$props.config,
            languages = _this$props.languages,
            currencies = _this$props.currencies,
            links = _this$props.links,
            selectedLanguage = _this$props.selectedLanguage,
            changeLanguage = _this$props.changeLanguage,
            selectedCurrency = _this$props.selectedCurrency,
            changeCurrency = _this$props.changeCurrency,
            socialShare = _this$props.socialShare,
            _onMenuToggle = _this$props.onMenuToggle,
            territories = _this$props.territories,
            selectedTerritory = _this$props.selectedTerritory,
            changeTerritory = _this$props.changeTerritory;
        var open = _this.state.open;

        var linkOverride = function linkOverride() {
          return '\n        border-bottom: 1px solid #808080;\n        padding-top: 10px;\n        padding-bottom: 10px;\n        ' + (0, _Global.fontStyleOverride)(config.menuBarLinkFont) + ';\n      ';
        };
        return _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            HamburgerMenuButton,
            {
              'aria-label': 'Click to open menu',
              type: 'button',
              onClick: function onClick() {
                return _this.onMenuToggle(true);
              }
            },
            _react2.default.createElement('img', { src: config.burgerMenuUrl, alt: 'menu button' })
          ),
          _react2.default.createElement(
            MobileMenuWrapper,
            {
              open: open,
              backgroundColour: config.backgroundColour && config.backgroundColour.value
            },
            _react2.default.createElement(
              Close,
              {
                role: 'button',
                onClick: function onClick() {
                  return _this.onMenuToggle(false);
                },
                alt: 'menu close button',
                'aria-label': 'menu close button'
              },
              'X'
            ),
            _react2.default.createElement(
              Links,
              null,
              links.map(function (link) {
                return _react2.default.createElement(
                  'li',
                  { key: (0, _v2.default)() },
                  _react2.default.createElement(
                    Link,
                    { href: link.link },
                    _react2.default.createElement(
                      _Global.HeadingTwo,
                      {
                        styleOverride: function styleOverride() {
                          return '\n                  ' + linkOverride() + ';\n                  padding: 15px 20px;\n                ';
                        },
                        onClick: function onClick() {
                          _this.onMenuToggle(false);
                        }
                      },
                      link.label
                    )
                  )
                );
              })
            ),
            _react2.default.createElement(
              ActionsWrapper,
              { 'data-cy': 'mobile-menu' },
              _react2.default.createElement(SelectMenu, {
                config: config,
                changeLanguage: changeLanguage,
                selectedLanguage: selectedLanguage,
                languages: languages,
                changeCurrency: changeCurrency,
                selectedCurrency: selectedCurrency,
                currencies: currencies,
                onMenuToggle: function onMenuToggle() {
                  return _onMenuToggle;
                },
                territories: territories,
                selectedTerritory: selectedTerritory,
                changeTerritory: changeTerritory
              })
            ),
            _react2.default.createElement(SocialMediaLinks, { config: config, socialShare: socialShare })
          )
        );
      }
    });


    _this.state = {
      open: false
    };
    return _this;
  }

  return MobileMenu;
}(_react.Component);

exports.default = MobileMenu;
exports.SelectMenu = SelectMenu;