'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _globalStyling = require('../../../../../dist/types/globalStyling');

var _useOutsideClick = require('../../../../hooks/useOutsideClick');

var _useOutsideClick2 = _interopRequireDefault(_useOutsideClick);

var _numbers = require('../../../../shared/localisation/numbers');

var _translateFromTemplate = require('../../../../shared/localisation/translateFromTemplate');

var _searchResultsTypes = require('../../../../types/AstonMartin/searchResultsTypes');

var _StyledIcon = require('../../../StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__Container',
  componentId: 'sc-z5ou2t-0'
})(['display:flex;flex-direction:column;width:100%;position:relative;box-sizing:border-box;margin-top:16px;']);

var SelectContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__SelectContainer',
  componentId: 'sc-z5ou2t-1'
})(['display:flex;height:40px;cursor:pointer;width:100%;padding:22px 15px;border:1px solid #d5d5d5;box-sizing:border-box;align-items:center;justify-content:space-between;span{font-size:14px;}']);

var ExpandContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__ExpandContainer',
  componentId: 'sc-z5ou2t-2'
})(['width:100%;border:1px solid #d5d5d5;box-sizing:border-box;position:absolute;top:42px;max-height:210px;overflow-y:auto;background-color:#ffffff;z-index:2;']);

var Option = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__Option',
  componentId: 'sc-z5ou2t-3'
})(['padding:4px 10px;&:hover{color:#fff;', ';}'], function (_ref) {
  var hoverColour = _ref.hoverColour;
  return 'background: ' + hoverColour;
});

var DistanceFilter = function DistanceFilter(_ref2) {
  var updateFilters = _ref2.updateFilters,
      translations = _ref2.translations,
      availableFilterValues = _ref2.availableFilterValues,
      arrowUpIcon = _ref2.arrowUpIcon,
      arrowDownIcon = _ref2.arrowDownIcon,
      innerRef = _ref2.innerRef,
      primaryBackgroundColour = _ref2.primaryBackgroundColour;
  var distanceDefaultOption = translations.distanceDefaultOption;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      toggleOpen = _useState2[1];

  var _useState3 = (0, _react.useState)({
    value: undefined,
    label: distanceDefaultOption
  }),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      selectedValue = _useState4[0],
      setSelectedValue = _useState4[1];

  var isClickOutside = (0, _useOutsideClick2.default)(innerRef);
  var subregions = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.filters.selectedFilters ? state.shared.filters.selectedFilters.subregions : undefined;
  });

  var renderIcon = function renderIcon(toggle, openIcon, closeIcon) {
    return toggle ? _react2.default.createElement(_StyledIcon2.default, { link: openIcon, width: 12, height: 12 }) : _react2.default.createElement(_StyledIcon2.default, { link: closeIcon, width: 12, height: 12 });
  };

  (0, _react.useEffect)(function () {
    if ((subregions || []).length === 0) {
      setSelectedValue({
        value: undefined,
        label: (0, _translateFromTemplate.translateFromTemplate)('distanceDefaultOption', {}, translations)
      });

      updateFilters([{
        key: 'maxDistance',
        value: undefined
      }]);
    }
  }, [subregions, _translateFromTemplate.translateFromTemplate, translations]);

  (0, _react.useEffect)(function () {
    if (open && isClickOutside) {
      toggleOpen(false);
    }
  }, [isClickOutside, open]);

  var handleClick = (0, _react.useCallback)(function (option) {
    setSelectedValue(option);
    toggleOpen(!open);
    updateFilters([{
      key: 'maxDistance',
      value: option.value ? Math.ceil((0, _numbers.convertMilesToKm)(option.value)) : []
    }]);
  }, [open]);

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      SelectContainer,
      { onClick: function onClick() {
          return toggleOpen(!open);
        } },
      _react2.default.createElement(
        'span',
        null,
        selectedValue.label
      ),
      renderIcon(open, arrowUpIcon, arrowDownIcon)
    ),
    open ? _react2.default.createElement(
      ExpandContainer,
      null,
      _react2.default.createElement(
        'div',
        {
          style: {
            display: 'flex',
            flexDirection: 'column',
            padding: '4px 0',
            fontSize: '14px',
            cursor: 'pointer'
          }
        },
        availableFilterValues.map(function (option) {
          var label = (0, _translateFromTemplate.translateFromTemplate)('distances', {
            MILES: option
          }, translations);
          return _react2.default.createElement(
            Option,
            {
              key: option,
              onClick: function onClick() {
                handleClick({ value: option, label: label });
              },
              hoverColour: primaryBackgroundColour.value
            },
            _react2.default.createElement(
              'span',
              null,
              label
            )
          );
        }),
        _react2.default.createElement(
          Option,
          {
            onClick: function onClick() {
              handleClick({ label: distanceDefaultOption });
            },
            hoverColour: primaryBackgroundColour.value
          },
          _react2.default.createElement(
            'span',
            null,
            distanceDefaultOption
          )
        )
      )
    ) : null
  );
};

exports.default = DistanceFilter;