'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ControlsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Controls__ControlsWrapper',
  componentId: 'sc-10x8ixa-0'
})(['width:100%;height:50px;background:#000000;display:flex;align-items:center;justify-content:end;padding-bottom:10px;margin-top:-5px;']);

var ImageViewToggle = _styledComponents2.default.button.withConfig({
  displayName: 'Controls__ImageViewToggle',
  componentId: 'sc-10x8ixa-1'
})(['width:25px;height:25px;outline:none;background:none;border:none;padding:0;margin:20px;cursor:pointer;align-self:center;']);

var ImageViewIcon = _styledComponents2.default.img.withConfig({
  displayName: 'Controls__ImageViewIcon',
  componentId: 'sc-10x8ixa-2'
})(['width:100%;filter:brightness(0) invert(1);']);

exports.default = function (_ref) {
  var imagesIcon = _ref.imagesIcon,
      toggleImageView = _ref.toggleImageView;

  return _react2.default.createElement(
    ControlsWrapper,
    null,
    _react2.default.createElement(
      ImageViewToggle,
      { onClick: toggleImageView },
      _react2.default.createElement(ImageViewIcon, { src: imagesIcon })
    )
  );
};