'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = StyledIcon;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'StyledIcon__Icon',
  componentId: 'sc-uaxxq4-0'
})(['', ';', ';', ';', ';'], function (_ref) {
  var height = _ref.height;
  return height && 'height: ' + height + 'px';
}, function (_ref2) {
  var width = _ref2.width;
  return width && 'width: ' + width + 'px';
}, function (_ref3) {
  var darkMode = _ref3.darkMode;
  return darkMode && 'filter: invert(100%) sepia(100%) saturate(9%) hue-rotate(206deg) brightness(101%) contrast(101%);';
}, function (_ref4) {
  var styleOverride = _ref4.styleOverride;
  return styleOverride && styleOverride;
});

function StyledIcon(_ref5) {
  var link = _ref5.link,
      width = _ref5.width,
      height = _ref5.height,
      alt = _ref5.alt,
      darkMode = _ref5.darkMode,
      styleOverride = _ref5.styleOverride;

  return _react2.default.createElement(Icon, {
    src: link,
    alt: alt,
    width: width,
    height: height,
    darkMode: darkMode,
    styleOverride: styleOverride
  });
}

StyledIcon.defaultProps = {
  alt: 'icon',
  width: 24,
  height: 24,
  darkMode: false
};