'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = GlobalInfo;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function GlobalInfo(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height,
      padding = _ref.padding;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height,
      padding: padding
    },
    _react2.default.createElement(
      'svg',
      {
        viewBox: '0 0 24 24',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
        width: width,
        height: width
      },
      _react2.default.createElement(
        'defs',
        null,
        _react2.default.createElement('path', {
          d: 'M11 21.043c5.547 0 10.043-4.496 10.043-10.043C21.043 5.453 16.547.957 11 .957 5.453.957.957 5.453.957 11c0 5.547 4.496 10.043 10.043 10.043zM11 22C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11z',
          id: 'globalInfoA'
        }),
        _react2.default.createElement('path', {
          d: 'M10.199 6.78c.096.097.206.172.33.227a.971.971 0 0 0 .398.082 1 1 0 0 0 .403-.082c.126-.055.237-.13.333-.227.096-.095.173-.205.23-.328a.985.985 0 0 0 0-.806 1.118 1.118 0 0 0-.23-.338 1.069 1.069 0 0 0-.333-.226A1 1 0 0 0 10.927 5a.97.97 0 0 0-.399.082 1.081 1.081 0 0 0-.555.563 1.03 1.03 0 0 0 .226 1.135zm2.388 9.206l-.936-.186V8.668H9v.71c0 .146.106.275.25.304l.937.186V15.8l-.937.186a.313.313 0 0 0-.25.304V17h3.838v-.71a.313.313 0 0 0-.251-.304z',
          id: 'globalInfoB'
        })
      ),
      _react2.default.createElement(
        'g',
        { transform: 'translate(1 1)', fill: '#999', fillRule: 'evenodd' },
        _react2.default.createElement('use', { fillRule: 'nonzero', xlinkHref: '#globalInfoA' }),
        _react2.default.createElement('use', { xlinkHref: '#globalInfoB' })
      )
    )
  );
}

GlobalInfo.defaultProps = {
  colour: null,
  width: '1em',
  height: '1em',
  background: null,
  padding: null
};