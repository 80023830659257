'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FooterBottomNavigation;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterBottomLinks = _styledComponents2.default.div.withConfig({
  displayName: 'FooterBottomNavigation__FooterBottomLinks',
  componentId: 'sc-1i9h48c-0'
})(['display:flex;flex-wrap:wrap;justify-content:center;padding:0;']);


var FooterBottomLink = _styledComponents2.default.a.withConfig({
  displayName: 'FooterBottomNavigation__FooterBottomLink',
  componentId: 'sc-1i9h48c-1'
})(['margin:15px 5px 0;text-decoration:none;']);

function FooterBottomNavigation(_ref) {
  var _ref$items = _ref.items,
      items = _ref$items === undefined ? [] : _ref$items,
      preview = _ref.preview,
      _ref$theme = _ref.theme,
      font = _ref$theme.font,
      hoverColour = _ref$theme.hoverColour,
      navigateIfInternal = _ref.navigateIfInternal;

  var isExternal = function isExternal(href) {
    return href.match(/^http/);
  };
  return _react2.default.createElement(
    FooterBottomLinks,
    null,
    items.map(function (_ref2) {
      var label = _ref2.label,
          link = _ref2.link,
          navId = _ref2.navId;
      return _react2.default.createElement(
        FooterBottomLink,
        {
          key: navId,
          borderColour: font.colour,
          href: link,
          target: isExternal(link) ? '_blank' : '_self',
          onClick: function onClick(event) {
            if (preview || navigateIfInternal(link)) {
              event.preventDefault();
            }
          }
        },
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(font) + '\n              padding: 8px;\n                &:hover {\n                ' + (hoverColour && 'color: ' + hoverColour.value) + ';\n                }\n              ';
            }
          },
          label
        )
      );
    })
  );
}