'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MyAccount;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _MyAccountLanding = require('./MyAccountLanding');

var _MyAccountLanding2 = _interopRequireDefault(_MyAccountLanding);

var _MyAccountDeals = require('./MyAccountDeals');

var _MyAccountDeals2 = _interopRequireDefault(_MyAccountDeals);

var _MyAccountTestDrives = require('./MyAccountTestDrives');

var _MyAccountTestDrives2 = _interopRequireDefault(_MyAccountTestDrives);

var _MyAccountDetails = require('./MyAccountDetails');

var _MyAccountDetails2 = _interopRequireDefault(_MyAccountDetails);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountContainer'
}).withConfig({
  displayName: 'MyAccount__Container',
  componentId: 'sc-1of1cy1-0'
})(['width:100%;height:auto;']);

var contextRenderer = {
  Account: function Account(props) {
    return _react2.default.createElement(_MyAccountLanding2.default, {
      featureFlags: props.featureFlags,
      translations: props.translations,
      changeAccountView: props.changeAccountView,
      userDetails: props.userDetails,
      signOut: props.signOut,
      onShortListClick: props.onShortListClick
    });
  },
  Deals: function Deals(props) {
    return _react2.default.createElement(_MyAccountDeals2.default, {
      finance: props.finance,
      translations: props.translations,
      myDeals: props.myDeals,
      goToVdp: props.goToVdp,
      deleteMyDeal: props.deleteMyDeal,
      printMyDeal: props.printMyDeal,
      globalStyling: props.globalStyling,
      iconColour: props.iconColour,
      printing: props.printing,
      brandLogoUrl: props.brandLogoUrl
    });
  },
  TestDrives: function TestDrives(props) {
    return _react2.default.createElement(_MyAccountTestDrives2.default, {
      translations: props.translations,
      testDrives: props.testDrives,
      onContactClick: props.onContactClick,
      onDeleteTestDriveClick: props.onDeleteTestDriveClick,
      goToVdp: props.goToVdp,
      globalStyling: props.globalStyling
    });
  },
  Details: function Details(props) {
    return _react2.default.createElement(_MyAccountDetails2.default, {
      globalStyling: props.globalStyling,
      translations: props.translations,
      userDetails: props.userDetails,
      saveUserDetails: props.saveUserDetails,
      changePassword: props.changePassword,
      deleteAccount: props.deleteAccount,
      feedback: props.detailsFeedback,
      config: props.config
    });
  }
};

function MyAccount(props) {
  return _react2.default.createElement(
    Container,
    null,
    contextRenderer[props.context](props)
  );
}