'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_REGISTER';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['INIT', 'REGISTER', 'REGISTER_FAILURE', 'REGISTER_SUCCESS']);

var id = exports.id = 'Register';

var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  register: (0, _reducerMacros.moduleActionWithArgs)(constants.REGISTER, id, function (payload) {
    return (0, _extends3.default)({}, payload);
  }),
  registerSuccess: (0, _reducerMacros.moduleAction)(constants.REGISTER_SUCCESS, id),
  registerFailure: (0, _reducerMacros.moduleAction)(constants.REGISTER_FAILURE, id)
};

var initialState = exports.initialState = {
  error: null,
  registerFailed: false,
  context: 'FORM'
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.INIT:
      return (0, _extends3.default)({}, initialState);
    case constants.REGISTER_FAILURE:
      return (0, _extends3.default)({}, state, {
        registerFailed: true
      });
    case constants.REGISTER_SUCCESS:
      return (0, _extends3.default)({}, state, {
        context: 'VERIFY'
      });
    default:
      return state;
  }
}