'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 18px;\n  '], ['\n    font-size: 18px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 20px;\n  '], ['\n    font-size: 20px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 60px;\n    line-height: 56px;\n  '], ['\n    font-size: 60px;\n    line-height: 56px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 80px;\n    line-height: 72px;\n  '], ['\n    font-size: 80px;\n    line-height: 72px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 56px;\n  '], ['\n    margin-top: 56px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 72px;\n  '], ['\n    margin-top: 72px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 84px;\n  '], ['\n    margin-top: 84px;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 126px;\n  '], ['\n    margin-top: 126px;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    bottom: unset;\n    top: 76%;\n  '], ['\n    bottom: unset;\n    top: 76%;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n   top: 73%\n  '], ['\n   top: 73%\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    top: 73%;\n  '], ['\n    top: 73%;\n  ']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n    position: absolute;\n    top: calc(50% - 23.5px);\n    height: 41px;\n    border-top: 3px solid #fff;\n    border-bottom: 3px solid #fff;\n    width: 226px;\n  '], ['\n    display: block;\n    position: absolute;\n    top: calc(50% - 23.5px);\n    height: 41px;\n    border-top: 3px solid #fff;\n    border-bottom: 3px solid #fff;\n    width: 226px;\n  ']),
    _templateObject13 = (0, _taggedTemplateLiteral3.default)(['\n     width: 250px;\n  '], ['\n     width: 250px;\n  ']),
    _templateObject14 = (0, _taggedTemplateLiteral3.default)(['\n     top: calc(50% - 33.5px);\n     height: 67px;\n     width: 338px;\n  '], ['\n     top: calc(50% - 33.5px);\n     height: 67px;\n     width: 338px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _TextLinkButton = require('../../../components/Genesis/TextLinkButton');

var _TextLinkButton2 = _interopRequireDefault(_TextLinkButton);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _sessionStorage = require('../../../helpers/sessionStorage');

var sessionStorageManager = _interopRequireWildcard(_sessionStorage);

var _ipInformation = require('../../../shared/localisation/ipInformation');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _types = require('../../../types');

var _Image = require('./Image');

var _Image2 = _interopRequireDefault(_Image);

var _Video = require('./Video');

var _Video2 = _interopRequireDefault(_Video);

var _types2 = require('./types');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SlideContainer = _styledComponents2.default.div.withConfig({ displayName: 'SlideContainer' }).withConfig({
  displayName: 'GenesisSlide__SlideContainer',
  componentId: 'sc-rf7a0g-0'
})(['background-color:#000;display:flex;flex-direction:column;min-height:1px;']);

var SlideOneContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GenesisSlide__SlideOneContentContainer',
  componentId: 'sc-rf7a0g-1'
})(['display:flex;justify-content:flex-start;flex-direction:row;position:absolute;width:100%;height:100%;top:0;right:0;justify-content:center;']);

var TopSlideContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GenesisSlide__TopSlideContentContainer',
  componentId: 'sc-rf7a0g-2'
})(['display:flex;flex-direction:column;width:100%;justify-content:space-between;']);

var Eyebrow = _styledComponents2.default.h2.withConfig({
  displayName: 'GenesisSlide__Eyebrow',
  componentId: 'sc-rf7a0g-3'
})(['max-width:450px;color:#fff;text-shadow:1px 1px 3px rgba(0,0,0,0.1);font-family:\'GenesisSansHeadGlobal-Regular\';font-size:16px;line-height:24px;font-style:normal;font-weight:normal;letter-spacing:-0.45px;margin:0;', ' ', ''], _Genesis2.default.min.extraLarge(_templateObject), _Genesis2.default.min.xxl(_templateObject2));

var Header = _styledComponents2.default.h1.withConfig({
  displayName: 'GenesisSlide__Header',
  componentId: 'sc-rf7a0g-4'
})(['text-shadow:1px 1px 3px rgba(0,0,0,0.1);font-family:\'GenesisSansHeadGlobal-Light\';font-size:42px;line-height:40px;font-style:normal;font-weight:normal;letter-spacing:-1.5px;color:#fff;margin:4px 0 8px;', ' ', ''], _Genesis2.default.min.large(_templateObject3), _Genesis2.default.min.xxl(_templateObject4));

var Subtitle = _styledComponents2.default.p.withConfig({
  displayName: 'GenesisSlide__Subtitle',
  componentId: 'sc-rf7a0g-5'
})(['font-family:\'GenesisSansHeadGlobal-Regular\';font-size:16px;line-height:24px;color:#fff;max-width:450px;margin:0;', ' ', ''], _Genesis2.default.min.extraLarge(_templateObject), _Genesis2.default.min.xxl(_templateObject2));

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GenesisSlide__HeaderContainer',
  componentId: 'sc-rf7a0g-6'
})(['margin-top:40px;', ' ', ' ', ' ', ' ', ';'], _Genesis2.default.min.small(_templateObject5), _Genesis2.default.min.large(_templateObject6), _Genesis2.default.min.extraLarge(_templateObject7), _Genesis2.default.min.xxl(_templateObject8), function () {
  return _Genesis.mixins.heroGuttering();
});

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GenesisSlide__ButtonContainer',
  componentId: 'sc-rf7a0g-7'
})(['position:absolute;bottom:90px;', ' ', ' ', ' ', ';'], _Genesis2.default.min.small(_templateObject9), _Genesis2.default.min.large(_templateObject10), _Genesis2.default.min.xxl(_templateObject11), function () {
  return _Genesis.mixins.heroGuttering();
});

var PowerLines = _styledComponents2.default.div.withConfig({
  displayName: 'GenesisSlide__PowerLines',
  componentId: 'sc-rf7a0g-8'
})(['display:none;', ' ', ';', ';'], _Genesis2.default.min.large(_templateObject12), _Genesis2.default.min.extraLarge(_templateObject13), _Genesis2.default.min.xxl(_templateObject14));
var GenesisSlide = function GenesisSlide(_ref) {
  var dispatch = _ref.dispatch,
      mediaContent = _ref.mediaContent,
      onLinkClick = _ref.onLinkClick,
      _ref$preview = _ref.preview,
      preview = _ref$preview === undefined ? false : _ref$preview,
      slideContentWidth = _ref.slideContentWidth,
      translations = _ref.translations,
      url = _ref.url,
      primaryCTA = _ref.primaryCTA,
      _ref$powerLines = _ref.powerLines,
      powerLines = _ref$powerLines === undefined ? true : _ref$powerLines;

  var _useNavigation = (0, _navigation2.default)(),
      navigateInternalUrl = _useNavigation.navigateInternalUrl;

  var ipCountryName = (0, _ipInformation.getIpCountryName)();
  var userCountryName = sessionStorageManager.getKey('userCountryLongName') ? sessionStorageManager.getKey('userCountryLongName') : ipCountryName;
  var displaySingleCountryStock = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.displaySingleCountryStock;
  });

  var navigate = function navigate(e, url) {
    e.preventDefault();
    navigateInternalUrl(url);
  };

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      SlideContainer,
      { dispatch: dispatch },
      mediaContent.type === 'image' && _react2.default.createElement(_Image2.default, {
        url: url,
        preview: preview,
        mediaContent: mediaContent,
        onLinkClick: onLinkClick,
        mask: true
      }),
      mediaContent.type === 'video' && _react2.default.createElement(_Video2.default, { url: url, preview: preview, mediaContent: mediaContent, onLinkClick: onLinkClick }),
      powerLines && _react2.default.createElement(PowerLines, null),
      _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: slideContentWidth },
        _react2.default.createElement(
          SlideOneContentContainer,
          null,
          _react2.default.createElement(
            TopSlideContentContainer,
            null,
            _react2.default.createElement(
              HeaderContainer,
              null,
              _react2.default.createElement(
                'div',
                null,
                translations.eyebrow && _react2.default.createElement(
                  Eyebrow,
                  null,
                  translations.eyebrow
                ),
                translations.header1 && _react2.default.createElement(
                  Header,
                  null,
                  translations.header1
                )
              ),
              translations.body && _react2.default.createElement(
                Subtitle,
                null,
                translations.body
              )
            ),
            _react2.default.createElement(
              ButtonContainer,
              null,
              _react2.default.createElement(_TextLinkButton2.default, {
                variant: 'primary',
                text: translations.primaryCTA,
                onClick: function onClick(e) {
                  navigate(e, '' + primaryCTA.url + (displaySingleCountryStock ? '&subregions=' + userCountryName : ''));
                }
              })
            )
          )
        )
      ),
      _react2.default.createElement(_reactWaypoint2.default, {
        dispatch: dispatch,
        topOffset: '20%',
        onPositionChange: function onPositionChange(props) {
          return dispatch(_sessionPreferences.actions.updateSessionPreferences('isHeroVisible', props.currentPosition === 'inside'));
        }
      })
    )
  );
};

exports.default = GenesisSlide;