'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _IconGrid = require('./IconGrid');

var _IconGrid2 = _interopRequireDefault(_IconGrid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _IconGrid2.default;