'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _DirectionSearcher = require('../../components/DirectionSearcher');

var _DirectionSearcher2 = _interopRequireDefault(_DirectionSearcher);

var _GoogleMap = require('../../components/GoogleMap');

var _GoogleMap2 = _interopRequireDefault(_GoogleMap);

var _Global = require('../../components/Global');

var _useVehicleRetailer2 = require('./useVehicleRetailer');

var _components = require('../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var pinIconUrl = 'https://res.cloudinary.com/motortrak/image/upload/v1566820052/cms/global/icons/pin.svg';


var Header = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleRetailerInfoDetails__Header',
  componentId: 'sc-7l9i56-0'
})(['display:flex;flex:1 0 100%;']);

var RetailerInfoWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleRetailerInfoDetails__RetailerInfoWrapper',
  componentId: 'sc-7l9i56-1'
})(['display:flex;width:100%;flex-wrap:wrap;flex-direction:row;align-self:stretch;']);

var RetailerInfo = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleRetailerInfoDetails__RetailerInfo',
  componentId: 'sc-7l9i56-2'
})(['display:flex;width:30%;flex-direction:column;']);

var MapContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleRetailerInfoDetails__MapContainer',
  componentId: 'sc-7l9i56-3'
})(['width:70%;']);

var DistanceFromRetailer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleRetailerInfoDetails__DistanceFromRetailer',
  componentId: 'sc-7l9i56-4'
})(['display:flex;align-items:center;']);

var PinIcon = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleRetailerInfoDetails__PinIcon',
  componentId: 'sc-7l9i56-5'
})(['src:', ';'], function (_ref) {
  var src = _ref.src;
  return src;
});

exports.default = function (_ref2) {
  var config = _ref2.config,
      translations = _ref2.config.translations,
      globalStyling = _ref2.globalStyling,
      _ref2$vehicleInfo = _ref2.vehicleInfo,
      vehicleId = _ref2$vehicleInfo.vehicleId,
      retailer = _ref2$vehicleInfo.retailerInformation,
      retailerLoc = _ref2$vehicleInfo.retailerInformation.location,
      googleAvailable = _ref2.shared.google.googleAvailable;

  var _useVehicleRetailer = (0, _useVehicleRetailer2.useVehicleRetailer)(retailerLoc, translations),
      distanceFromRetailer = _useVehicleRetailer.distanceFromRetailer,
      setDistanceFromRetailer = _useVehicleRetailer.setDistanceFromRetailer,
      setPosition = _useVehicleRetailer.setPosition,
      viewRetailerStock = _useVehicleRetailer.viewRetailerStock,
      makeAnEnquiry = _useVehicleRetailer.makeAnEnquiry;

  return _react2.default.createElement(
    _components.Wrapper,
    null,
    _react2.default.createElement(
      RetailerInfoWrapper,
      null,
      _react2.default.createElement(
        Header,
        null,
        translations.retailerMapHeader
      ),
      _react2.default.createElement(
        RetailerInfo,
        null,
        _react2.default.createElement(
          _Global.HeadingTwo,
          null,
          retailer.name
        ),
        retailer.address.map(function (line) {
          return _react2.default.createElement(
            _Global.Paragraph,
            { key: line },
            line
          );
        }),
        _react2.default.createElement(
          _Global.Paragraph,
          null,
          translations.vdpRetailerPhoneLabel + ': ' + retailer.phone
        ),
        _react2.default.createElement(
          _Global.Paragraph,
          null,
          translations.vdpRetailerEmailLabel,
          ': ',
          retailer.email
        ),
        distanceFromRetailer && _react2.default.createElement(
          DistanceFromRetailer,
          null,
          _react2.default.createElement(PinIcon, { alt: 'map pin', src: pinIconUrl }),
          _react2.default.createElement(
            'span',
            null,
            distanceFromRetailer
          )
        ),
        googleAvailable && _react2.default.createElement(_DirectionSearcher2.default, {
          onGetCurrentPosition: function onGetCurrentPosition(position) {
            return setPosition(position);
          },
          placeholder: translations.vdpRetailerGetDirections,
          destination: retailerLoc,
          retailerLocationCountry: retailer.country,
          locationFilterLabel: translations.locationFilterLabel,
          globalStyling: globalStyling,
          resetDistanceFromRetailer: function resetDistanceFromRetailer() {
            return setDistanceFromRetailer(null);
          }
        }),
        _react2.default.createElement(_Global.Button, {
          applyStyle: 'primary',
          text: translations.vdpRetailerStockButtonText,
          onClick: function onClick() {
            return viewRetailerStock(retailer);
          }
        }),
        _react2.default.createElement(_Global.Button, {
          applyStyle: 'secondary',
          text: translations.vdpRetailerEnquiryButtonText,
          onClick: function onClick() {
            return makeAnEnquiry(vehicleId);
          }
        })
      ),
      _react2.default.createElement(
        MapContainer,
        null,
        googleAvailable && _react2.default.createElement(_GoogleMap2.default, {
          mapName: 'vdpMap',
          retailerLocation: retailerLoc,
          iconPin: config.iconPin
        })
      )
    )
  );
};