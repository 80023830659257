'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  margin-bottom: 2px;\n  ', '\n  ', ';\n'], ['\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  margin-bottom: 2px;\n  ', '\n  ', ';\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  flex-grow: 1;\n  border-top: ', ';\n  border-left: ', ';\n  margin-right: 0.5%;\n  ', ';\n  ', '\n  ', '\n'], ['\n  display: flex;\n  flex-grow: 1;\n  border-top: ', ';\n  border-left: ', ';\n  margin-right: 0.5%;\n  ', ';\n  ', '\n  ', '\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100% !important;\n  '], ['\n    width: 100% !important;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 33%;\n\n    &:last-child {\n      margin-right: 0;\n    }\n  '], ['\n    max-width: 33%;\n\n    &:last-child {\n      margin-right: 0;\n    }\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n  height: ', 'px;\n'], ['\n  height: ', 'px;\n']);

exports.default = ModelGrid;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _GridItem = require('../../components/GridItem');

var _GridItem2 = _interopRequireDefault(_GridItem);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _router = require('../../actions/router');

var _Global = require('../../components/Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MAX_COLUMNS = 3;
var namedDiv = function namedDiv(displayName) {
  return _styledComponents2.default.div.withConfig({ displayName: displayName });
};

var Row = namedDiv('Row')(_templateObject, _theme2.default.max.medium(_templateObject2), function (_ref) {
  var height = _ref.height;
  return height && 'height:' + height + 'px';
});

var Column = namedDiv('Column')(_templateObject3, function (_ref2) {
  var borderTop = _ref2.borderTop;
  return '' + (borderTop || 'none');
}, function (_ref3) {
  var borderLeft = _ref3.borderLeft;
  return '' + (borderLeft || 'none');
}, function (_ref4) {
  var colour = _ref4.colour;
  return colour && 'background-color: ' + colour.value;
}, _theme2.default.max.large(_templateObject4), _theme2.default.min.medium(_templateObject5));

var HeaderStyleOverride = function HeaderStyleOverride() {
  return '\n  margin: 32px 0;\n  text-align: center;\n  font-weight: 600;\n';
};

var Gap = namedDiv('Gap')(_templateObject6, function (_ref5) {
  var height = _ref5.height;
  return height;
});

var onSearchClick = function onSearchClick(modelRange, make, market, dispatch) {
  dispatch(_router.actions.navigateWithQuery('/searchresults', { model: modelRange }));
};

var imageTransformationItemsPerRow = {
  1: 'h_600,w_1300',
  2: 'h_300,w_680',
  3: 'h_176,w_400'
};

var imageTransform = function imageTransform(url, item) {
  return url && url.replace('image/upload', 'image/upload/c_fill,' + imageTransformationItemsPerRow[item]);
};

function ModelGrid(_ref6) {
  var config = _ref6.config,
      globalStyling = _ref6.globalStyling,
      dispatch = _ref6.dispatch,
      _ref6$marketInfo = _ref6.marketInfo,
      make = _ref6$marketInfo.make,
      market = _ref6$marketInfo.market;

  var numberOfRows = config.items ? Math.ceil(config.items.length / MAX_COLUMNS) : 1;
  var numberOfLastRowItems = config.items ? config.items.length % MAX_COLUMNS : 0;
  var isLastRow = function isLastRow(rowValue) {
    return rowValue === numberOfRows - 1;
  };
  var getNumberOfColumns = function getNumberOfColumns(rowValue) {
    return isLastRow(rowValue) ? !numberOfLastRowItems ? MAX_COLUMNS : numberOfLastRowItems : MAX_COLUMNS;
  };
  var onSearchClickHandler = function onSearchClickHandler(model) {
    return onSearchClick(model, make, market, dispatch);
  };
  var buttonProps = {
    backgroundColour: config.buttonBackgroundColour,
    hover: config.buttonHover,
    font: config.buttonFont,
    buttonSize: config.buttonSize,
    buttonType: config.buttonType
  };
  var alignItems = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };
  return _react2.default.createElement(
    _ContentWrapper2.default,
    {
      contentWidth: globalStyling.contentWidth,
      colour: config.gridBackgroundColour
    },
    _react2.default.createElement(
      _Global.HeadingTwo,
      { styleOverride: HeaderStyleOverride },
      config.translations.header
    ),
    [].concat((0, _toConsumableArray3.default)(Array(numberOfRows).keys())).map(function (rowValue, x) {
      return _react2.default.createElement(
        Row,
        { key: rowValue },
        [].concat((0, _toConsumableArray3.default)(Array(getNumberOfColumns(rowValue)).keys())).map(function (columnValue, y) {
          var item = config.items && config.items[x * MAX_COLUMNS + y];
          var itemImage = item && item.image || {
            value: null,
            label: null
          };
          var itemLink = item && item.link && (0, _extends3.default)({}, item.link, {
            text: item.translations.link
          }) || {
            url: '',
            label: '',
            text: ''
          };
          var headline = item && item.translations.headline;
          var bodyText = item && item.translations.body;
          var bottomText = item && item.translations.bottomText;
          return _react2.default.createElement(
            Column,
            {
              width: '33%',
              key: columnValue,
              colour: config.gridItemBackgroundColour,
              borderTop: config.column && config.column.borderTop,
              borderLeft: config.column && config.column.borderLeft
            },
            _react2.default.createElement(_GridItem2.default, {
              linkText: itemLink.text,
              imageUrl: imageTransform(itemImage.value, getNumberOfColumns(rowValue)),
              headline: headline,
              bodyText: bodyText,
              bottomText: bottomText,
              bodyTextTheme: config.gridItemBodyFont || {},
              headlineTheme: config.gridItemHeaderFont || {},
              bottomTextTheme: config.gridItemBottomTextFont || {},
              align: alignItems[config.gridItemsAlign],
              buttonProps: buttonProps,
              globalStyling: globalStyling,
              bottomContentGrow: true,
              onClickLink: function onClickLink() {
                return onSearchClickHandler(item.models.join('&models='));
              },
              imageAspectRatioPercentage: config.gridItemImageAspectRatioPercentage
            })
          );
        })
      );
    }),
    _react2.default.createElement(Gap, { height: config.gapHeight })
  );
}