'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: ', ';\n  '], ['\n    margin-top: ', ';\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _parseAstonMartinRetailerName = require('../../helpers/parseAstonMartinRetailerName');

var _parseAstonMartinRetailerName2 = _interopRequireDefault(_parseAstonMartinRetailerName);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AstonLink = require('./AstonLink');

var _AstonLink2 = _interopRequireDefault(_AstonLink);

var _UserLocation = require('./UserLocation');

var _UserLocation2 = _interopRequireDefault(_UserLocation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__Container',
  componentId: 'sc-wbek1v-0'
})(['display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%;padding:0px;margin:40px;box-sizing:border-box;', ';'], _theme2.default.min.large(_templateObject, function (_ref) {
  var OpeningHours = _ref.OpeningHours;
  return OpeningHours ? '75px' : '150px';
}));
var LocationLabel = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__LocationLabel',
  componentId: 'sc-wbek1v-1'
})(['diplay:flex;flex-direction:row;align-items:center;margin-top:20px;margin-bottom:10px;font-size:16px;font-family:AstonMartinFlare-Regular;']);

var LinkContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__LinkContainer',
  componentId: 'sc-wbek1v-2'
})(['display:flex;flex-direction:column;justify-content:space-around;font-family:AstonMartinFlare-Medium;align-items:center;margin-top:40px font-size:14px;margin-top:10px;height:75px;']);

var AddressLabel = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__AddressLabel',
  componentId: 'sc-wbek1v-3'
})(['font-size:14px;line-height:22px;font-family:AstonMartinSans-Light;']);

var ContactLink = _styledComponents2.default.a.withConfig({
  displayName: 'RetailerInfo__ContactLink',
  componentId: 'sc-wbek1v-4'
})(['display:flex;text-decoration:none;font-size:12px;line-height:22px;font-family:AstonMartinSans-Regular;color:#000000;align-items:center;']);

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'RetailerInfo__Logo',
  componentId: 'sc-wbek1v-5'
})(['height:25px;']);
var LocationContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__LocationContainer',
  componentId: 'sc-wbek1v-6'
})(['width:220px;']);

var OpeningHoursContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__OpeningHoursContainer',
  componentId: 'sc-wbek1v-7'
})(['display:flex;flex-direction:column;align-items:center;margin-top:40px;font-size:14px;font-family:AstonMartinFlare-Medium;width:300px;']);

var OpeningHoursItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__OpeningHoursItemContainer',
  componentId: 'sc-wbek1v-8'
})(['display:flex;flex-direction:row;justify-content:space-between;width:100%;border-bottom:1px solid #000000;']);

var OpeningHoursItemText = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__OpeningHoursItemText',
  componentId: 'sc-wbek1v-9'
})(['font-size:14px;font-family:AstonMartinSans-Light;margin:10px 0px;']);

var RetailerInfo = function RetailerInfo(_ref2) {
  var config = _ref2.config,
      translations = _ref2.translations,
      vehicle = _ref2.vehicle,
      makeAnEnquiry = _ref2.makeAnEnquiry,
      viewRetailerStock = _ref2.viewRetailerStock;

  var _useState = (0, _react.useState)({
    latitude: '',
    longitude: ''
  }),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      location = _useState2[0],
      setLocation = _useState2[1];

  var MAPS_DIR_URL = function MAPS_DIR_URL(ori, des) {
    return 'https://www.google.com/maps/dir/?api=1&origin=' + ori + '&destination=' + des;
  };
  var originCoordinates = location.latitude + ',' + location.longitude;
  var dealerCoordinates = vehicle.retailerInformation.location.coordinates[1] + ',' + vehicle.retailerInformation.location.coordinates[0];

  var directionsUrl = MAPS_DIR_URL(originCoordinates, dealerCoordinates);

  var daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  return _react2.default.createElement(
    Container,
    { id: 'retailerInfo', OpeningHours: true },
    _react2.default.createElement(Logo, { src: config.astonLogo, alt: 'Aston Martin' }),
    _react2.default.createElement(
      LocationLabel,
      { mobile: true },
      (0, _parseAstonMartinRetailerName2.default)(vehicle.retailerInformation.name)
    ),
    vehicle.retailerInformation.address.map(function (address) {
      return _react2.default.createElement(
        AddressLabel,
        { key: address },
        address
      );
    }),
    vehicle.retailerInformation.phone && _react2.default.createElement(
      ContactLink,
      { href: 'tel:' + vehicle.retailerInformation.phone },
      translations.phoneLabel,
      vehicle.retailerInformation.phone
    ),
    _react2.default.createElement(
      LinkContainer,
      { mobile: true },
      _react2.default.createElement(_AstonLink2.default, {
        text: translations.contactDealer,
        onClick: makeAnEnquiry,
        colour: '#000000',
        fontFamily: 'AstonMartinFlare-Medium'
      }),
      _react2.default.createElement(_AstonLink2.default, {
        text: translations.viewDealerStock,
        onClick: function onClick() {
          return viewRetailerStock(vehicle.retailerInformation);
        },
        colour: '#000000',
        fontFamily: 'AstonMartinFlare-Medium'
      })
    ),
    _react2.default.createElement(
      LocationContainer,
      null,
      _react2.default.createElement(_UserLocation2.default, {
        iconUrl: config.locateUserIconUrl,
        browserLocationCallback: function browserLocationCallback(lat, long) {
          setLocation({
            latitude: lat,
            longitude: long
          });
        },
        inputLocationCallback: function inputLocationCallback(lat, long) {
          setLocation({
            latitude: lat,
            longitude: long
          });
        },
        translations: translations,
        onButtonClick: function onButtonClick() {
          window.open(directionsUrl, '_blank');
        }
      })
    ),
    _react2.default.createElement(
      OpeningHoursContainer,
      null,
      daysOfWeek.map(function (day) {
        return _react2.default.createElement(
          OpeningHoursItemContainer,
          { key: day },
          _react2.default.createElement(
            OpeningHoursItemText,
            null,
            translations[day]
          ),
          _react2.default.createElement(
            OpeningHoursItemText,
            null,
            vehicle.retailerInformation.openingHours[day]
          )
        );
      })
    )
  );
};

exports.default = RetailerInfo;