'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Line;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Hr = _styledComponents2.default.div.withConfig({
  displayName: 'Line__Hr',
  componentId: 'sc-17mr0fy-0'
})(['width:100%;border-bottom:1px solid #d5d5d5;']);

function Line() {
  return _react2.default.createElement(Hr, null);
}