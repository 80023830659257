'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 40px auto 0;\n     padding: 20px 60px;\n  '], ['\n    margin: 40px auto 0;\n     padding: 20px 60px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _BentleyEmissionsDisclaimerSectionTypes = require('../../../types/Bentley/BentleyEmissionsDisclaimerSectionTypes');

var _ipInformation = require('../../../shared/localisation/ipInformation');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'BentleyEmissionsDisclaimerSection__Wrapper',
  componentId: 'sc-1pgvu36-0'
})(['display:flex;background-color:#fff;']);

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'BentleyEmissionsDisclaimerSection__Container',
  componentId: 'sc-1pgvu36-1'
})(['margin:0 auto;max-width:1367px;padding:20px 32px;', ';width:100%;'], _theme2.default.min.medium(_templateObject));

var DisclaimerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'BentleyEmissionsDisclaimerSection__DisclaimerWrapper',
  componentId: 'sc-1pgvu36-2'
})(['max-width:900px;']);

var EmissionsDisclaimerText = _styledComponents2.default.p.withConfig({
  displayName: 'BentleyEmissionsDisclaimerSection__EmissionsDisclaimerText',
  componentId: 'sc-1pgvu36-3'
})(['color:#757575;font-family:Bentley-Light;font-size:14px;']);

var DatLink = _styledComponents2.default.a.withConfig({
  displayName: 'BentleyEmissionsDisclaimerSection__DatLink',
  componentId: 'sc-1pgvu36-4'
})(['color:#757575;text-decoration:none;&:hover{color:#003220;}']);

var BentleyEmissionsDisclaimerSection = function BentleyEmissionsDisclaimerSection(_ref) {
  var config = _ref.config;
  var translations = config.translations;

  var countryCode = (0, _ipInformation.getIpCountryCode)();
  var userLocationGermany = countryCode && countryCode === 'DE' ? true : false;

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        DisclaimerWrapper,
        null,
        _react2.default.createElement(
          EmissionsDisclaimerText,
          null,
          translations.disclaimerText
        ),
        userLocationGermany && _react2.default.createElement(
          EmissionsDisclaimerText,
          null,
          translations.disclaimerTextGermany,
          ' ',
          _react2.default.createElement(
            DatLink,
            { href: translations.datLinkURL, target: '_blank' },
            translations.datLinkText
          )
        )
      )
    )
  );
};

exports.default = BentleyEmissionsDisclaimerSection;