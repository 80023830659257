'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.paginationStyles = exports.slideContentStyles = exports.themeFromProps = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['height: 600px;'], ['height: 600px;']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['height: 450px;'], ['height: 450px;']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['height: 250px;'], ['height: 250px;']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _router = require('../../actions/router');

var _fallbackFonts = require('../../components/Global/fallbackFonts');

var _fallbackFonts2 = _interopRequireDefault(_fallbackFonts);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Pagination = require('./Pagination');

var _Slides = require('./Slides');

var _BentleySlide = require('./Slides/BentleySlide');

var _BentleySlide2 = _interopRequireDefault(_BentleySlide);

var _GenesisSlide = require('./Slides/GenesisSlide');

var _GenesisSlide2 = _interopRequireDefault(_GenesisSlide);

var _RollsRoyceSlide = require('./Slides/RollsRoyceSlide');

var _RollsRoyceSlide2 = _interopRequireDefault(_RollsRoyceSlide);

var _KiaSlide = require('./Slides/KiaSlide');

var _KiaSlide2 = _interopRequireDefault(_KiaSlide);

var _LamborghiniModelSlide = require('./Slides/LamborghiniModelSlide');

var _LamborghiniModelSlide2 = _interopRequireDefault(_LamborghiniModelSlide);

var _fullBleedBackground = require('./Slides/fullBleedBackground');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var themeFromProps = exports.themeFromProps = function themeFromProps(_ref) {
  var typeface = _ref.typeface,
      fontSize = _ref.fontSize,
      kerning = _ref.kerning,
      transform = _ref.transform,
      colour = _ref.colour,
      lineHeight = _ref.lineHeight,
      fontWeight = _ref.fontWeight,
      whiteSpace = _ref.whiteSpace;
  return {
    fontFamily: typeface ? typeface.label + ',' + _fallbackFonts2.default : '',
    fontSize: fontSize ? fontSize + 'px' : '',
    characterSpacing: kerning ? kerning + 'px' : '',
    textTransform: transform || '',
    colour: colour ? colour.value : '',
    lineHeight: lineHeight ? lineHeight + 'px' : '',
    fontWeight: fontWeight ? '' + fontWeight : '',
    whiteSpace: whiteSpace || ''
  };
};

var Placeholder = _styledComponents2.default.div.withConfig({ displayName: 'Placeholder' }).withConfig({
  displayName: 'HeroCarousel__Placeholder',
  componentId: 'sc-1ikugm1-0'
})(['', ';', ';', ';'], _theme2.default.max.extraLarge(_templateObject), _theme2.default.max.medium(_templateObject2), _theme2.default.max.large(_templateObject3));

var Container = _styledComponents2.default.div.withConfig({ displayName: 'Container' }).withConfig({
  displayName: 'HeroCarousel__Container',
  componentId: 'sc-1ikugm1-1'
})(['display:flex;flex-direction:column;position:relative;min-height:1px;']);

var slideContentStyles = exports.slideContentStyles = {
  slideContentOne: _Slides.SlideOne,
  slideContentTwo: _Slides.SlideTwo,
  slideContentThree: _KiaSlide2.default,
  slideContentFour: _Slides.SlideFour,
  FullBleedBG: _fullBleedBackground.FullBleedBG,
  LamborghiniModelSlide: _LamborghiniModelSlide2.default,
  BentleySlide: _BentleySlide2.default,
  GenesisSlide: _GenesisSlide2.default,
  RollsRoyceSlide: _RollsRoyceSlide2.default
};

var paginationStyles = exports.paginationStyles = {
  paginationOne: _Pagination.PaginationOne,
  paginationTwo: _Pagination.PaginationTwo,
  GenesisPagination: _Pagination.GenesisPagination
};

var HeroCarousel = function (_React$Component) {
  (0, _inherits3.default)(HeroCarousel, _React$Component);

  function HeroCarousel() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, HeroCarousel);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = HeroCarousel.__proto__ || (0, _getPrototypeOf2.default)(HeroCarousel)).call.apply(_ref2, [this].concat(args))), _this), Object.defineProperty(_this, 'state', {
      enumerable: true,
      writable: true,
      value: { counter: 0, step: 0, paused: false }
    }), Object.defineProperty(_this, 'interval', {
      enumerable: true,
      writable: true,
      value: 0
    }), Object.defineProperty(_this, 'handleClick', {
      enumerable: true,
      writable: true,
      value: function value(index) {
        _this.setState({ counter: index });
      }
    }), Object.defineProperty(_this, 'togglePause', {
      enumerable: true,
      writable: true,
      value: function value() {
        var paused = _this.state.paused;


        if (!_this.state.paused) {
          clearInterval(_this.interval);
        } else if (_this.state.paused && _this.props.config.slidesLoopEnabled) {
          _this.interval = setInterval(function () {
            _this.nextSlide();
          }, _this.props.config.slideTransitionSpeed * 1000);
        }
        _this.setState({ paused: !paused });
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(HeroCarousel, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      if (!this.props.config.slidesLoopEnabled) return;
      if (this.paused) return;
      this.interval = setInterval(function () {
        _this2.nextSlide();
      }, this.props.config.slideTransitionSpeed * 1000);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearInterval(this.interval);
    }
  }, {
    key: 'nextSlide',
    value: function nextSlide() {
      var _state = this.state,
          counter = _state.counter,
          step = _state.step;

      var numSlides = this.props.config.items.length;

      if (numSlides === 1) return;

      this.setState({
        counter: (counter + 1) % numSlides,
        step: (step + 1) % 3
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          dispatch = _props.dispatch,
          config = _props.config,
          globalStyling = _props.globalStyling,
          shared = _props.shared;

      var displayItem = this.props.preview ? config.displaySlide : this.state.counter;

      var slide = config.items[displayItem];

      var theme = {
        linkBackgroundColour: config.buttonBGColour,
        contentWidth: config.contentWidth,
        buttonOverrideStyle: config.slideButtonStyle,
        headerPositionStyle: config.headerPositionStyle,
        headerTheme: (0, _extends3.default)({}, themeFromProps(config.slideHeaderFont || {}), {
          backgroundColour: config.headerBGColour && config.headerBGColour.value,
          backgroundOpacity: config.backgroundOpacity
        }),
        bodyTheme: (0, _extends3.default)({}, themeFromProps(config.slideBodyFont || {}), {
          backgroundColour: config.bodyBGColour && config.bodyBGColour.value,
          backgroundOpacity: config.backgroundOpacity,
          padding: config.paddingContent
        }),
        linkTheme: (0, _extends3.default)({}, themeFromProps(config.slideButtonFont || {}), {
          buttonWidth: config.buttonWidth,
          buttonHeight: config.buttonHeight,
          alignment: config.buttonTextAlignment,
          backgroundColour: config.buttonBGColour && config.buttonBGColour.value,
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
        })
      };

      var Slide = slideContentStyles[config.slideContentStyle.value];
      var Pagination = config.paginationStyle && config.paginationStyle.value ? paginationStyles[config.paginationStyle.value] : undefined;

      return _react2.default.createElement(
        Container,
        null,
        slide ? _react2.default.createElement(Slide, (0, _extends3.default)({}, theme, slide, {
          preview: this.props.preview,
          onLinkClick: function onLinkClick(url) {
            return dispatch(_router.actions.navigate(url));
          },
          slideContentWidth: globalStyling.contentWidth,
          headerMobileFontSize: config.headerMobileFontSize,
          headerMobileFontColour: config.headerMobileFontColour,
          mobileSlideHeaderFontSize: config.mobileSlideHeaderFontSize,
          mobileButtonBackgroundColour: config.mobileButtonBackgroundColour,
          mobileButtonFontColour: config.mobileButtonFontColour,
          bodyMobileFontSize: config.bodyMobileFontSize,
          bodyMobileFontColour: config.bodyMobileFontColour,
          top: config.slideContentTop,
          headerLeftPadding: config.headerLeftPadding,
          headerRightPadding: config.headerRightPadding,
          youtubeVideo: config.video && config.video,
          shared: shared,
          dispatch: dispatch,
          powerLines: config.powerLines
        })) : _react2.default.createElement(Placeholder, null),
        Pagination && _react2.default.createElement(Pagination, {
          items: config.items,
          onPageclick: function onPageclick(item) {
            return _this3.handleClick(item);
          },
          activeItem: displayItem,
          activeColour: config.paginationActiveColour.value,
          inactiveColour: config.paginationInactiveColour.value,
          paginationOpacity: config.paginationOpacity,
          align: slide && slide.align,
          alignmentWidth: slide && slide.alignmentWidth,
          paginationWidth: globalStyling.contentWidth,
          paused: this.state.paused,
          togglePause: this.togglePause
        })
      );
    }
  }]);
  return HeroCarousel;
}(_react2.default.Component);

exports.default = HeroCarousel;