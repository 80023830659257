'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModelSearchBar = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 450px;\n\n  '], ['\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 450px;\n\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 550px;\n  '], ['\n    width: 550px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 700px;\n  '], ['\n    width: 700px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _UserLocation = require('../UserLocation/UserLocation');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SearcherContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearchBar__SearcherContainer',
  componentId: 'sc-175qtux-0'
})(['border-top:1px solid #335b4c;outline:none;background:white;border-width:1px 0;width:100%;z-index:10;']);

var SearchWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearchBar__SearchWrapper',
  componentId: 'sc-175qtux-1'
})(['display:block;padding:0 24px;box-sizing:border-box;margin:0 auto;', ';', ';', ';'], _theme2.default.min.small(_templateObject), _theme2.default.min.medium(_templateObject2), _theme2.default.min.large(_templateObject3));

var SearcherBar = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearchBar__SearcherBar',
  componentId: 'sc-175qtux-2'
})(['display:flex;flex:1 0 auto;']);

var ModelSearchBar = exports.ModelSearchBar = function ModelSearchBar(_ref) {
  var _ref$config = _ref.config,
      translations = _ref$config.translations,
      locationIconUrl = _ref$config.locationIconUrl,
      googleAvailable = _ref.googleAvailable,
      latitude = _ref.latitude,
      longitude = _ref.longitude,
      locationCallback = _ref.locationCallback,
      handleOnClick = _ref.handleOnClick;

  return _react2.default.createElement(
    SearcherContainer,
    { id: 'sticky-search' },
    _react2.default.createElement(
      SearchWrapper,
      null,
      _react2.default.createElement(
        SearcherBar,
        null,
        googleAvailable && _react2.default.createElement(_UserLocation.UserLocation, {
          placeholderText: translations.locationPlaceholder,
          iconUrl: locationIconUrl || '',
          browserLocationCallback: locationCallback,
          inputLocationCallback: locationCallback,
          options: {
            autocompletePosition: 'below',
            latitude: latitude,
            longitude: longitude
          },
          translations: translations,
          onButtonClick: handleOnClick
        })
      )
    )
  );
};

exports.default = ModelSearchBar;