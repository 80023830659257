'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      margin: 80px 0 370px;\n  '], ['\n      margin: 80px 0 370px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 390px;\n    min-width: 390px;\n  '], ['\n    max-width: 390px;\n    min-width: 390px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 36px;\n  '], ['\n    font-size: 36px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useModelSearch2 = require('../../../modules/RollsRoyce/ModelSearch/useModelSearch');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _modelTypes = require('../../../types/RollsRoyce/modelTypes');

var _Form = require('../../RollsRoyce/Form');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BodyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__BodyContainer',
  componentId: 'sc-c7fssw-0'
})(['width:100%;margin:50px 0 350px;', ';@media screen and (min-width:1920px){margin:100px 0 500px;}'], _theme2.default.min.extraLarge(_templateObject));

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__ButtonContainer',
  componentId: 'sc-c7fssw-1'
})(['display:flex;flex:1;justify-content:center;width:100%;']);

var Card = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__Card',
  componentId: 'sc-c7fssw-2'
})(['background-image:', ';background-size:cover;background-position:center;border-radius:8px;color:#ffffff;cursor:ew-resize;display:flex;flex-direction:column;justify-content:flex-end;max-width:330px;min-width:330px;&:first-child{margin-left:20px;}', ';'], function (_ref) {
  var backgroundImage = _ref.backgroundImage;
  return 'url(' + backgroundImage + ')';
}, _theme2.default.min.small(_templateObject2));

var CardDescription = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__CardDescription',
  componentId: 'sc-c7fssw-3'
})(['font-family:\'RivieraNights-Light\';font-size:14px;letter-spacing:0.5px;line-height:22px;margin:10px 0 30px;text-align:center;text-transform:uppercase;']);

var CardTitle = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__CardTitle',
  componentId: 'sc-c7fssw-4'
})(['display:flex;font-family:\'RivieraNights-Light\';font-size:32px;justify-content:center;letter-spacing:10px;line-height:46px;margin:0 20px;text-align:center;text-transform:uppercase;', ';'], _theme2.default.min.medium(_templateObject3));

var SpacerCard = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__SpacerCard',
  componentId: 'sc-c7fssw-5'
})(['max-width:16px;min-width:16px;']);

var TitleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__TitleContainer',
  componentId: 'sc-c7fssw-6'
})(['display:flex;flex-direction:column;']);

var ModelItem = function ModelItem(_ref2) {
  var config = _ref2.config,
      tile = _ref2.tile,
      translations = _ref2.translations;
  var imageUrl = tile.imageUrl,
      title = tile.title,
      translationNameKey = tile.translationNameKey;

  var _useModelSearch = (0, _useModelSearch2.useModelSearch)(config.searchSections),
      prepareSRPFilters = _useModelSearch.prepareSRPFilters;

  var handleClick = function handleClick() {
    prepareSRPFilters([tile.tileId]);
  };

  if (tile.tileId !== 'spacerCard') {
    return _react2.default.createElement(
      Card,
      { backgroundImage: imageUrl },
      _react2.default.createElement(
        BodyContainer,
        null,
        _react2.default.createElement(
          TitleContainer,
          null,
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              CardTitle,
              null,
              translationNameKey && translations[translationNameKey] ? translations[translationNameKey] : title
            )
          ),
          _react2.default.createElement(
            CardDescription,
            null,
            translations[tile.description]
          )
        ),
        _react2.default.createElement(
          ButtonContainer,
          null,
          _react2.default.createElement(_Form.Button, {
            variant: 'tertiary',
            text: translations[tile.buttonText],
            onClick: handleClick,
            icon: 'https://res.cloudinary.com/motortrak/image/upload/co_rgb:000000,e_colorize:100/v1637665531/locator/rolls-royce/global/icons/rr-button-arrow.svg',
            styleOverride: function styleOverride() {
              return '\n                height:46px; \n                min-width:240px;\n                font-family: \'RivieraNights-Medium\';\n                & span {\n                  letter-spacing: 2px;\n                }\n              ';
            }
          })
        )
      )
    );
  } else {
    return _react2.default.createElement(SpacerCard, null);
  }
};

exports.default = ModelItem;