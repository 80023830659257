'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  SET_ERROR: 'SET_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR'
};

var actions = exports.actions = {
  setError: function setError(error) {
    return { type: constants.SET_ERROR, payload: error };
  },
  clearError: function clearError() {
    return { type: constants.CLEAR_ERROR };
  }
};

var initialState = {
  error: null
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case constants.SET_ERROR:
      return (0, _extends3.default)({}, state, {
        error: '' + payload
      });
    case constants.CLEAR_ERROR:
      return (0, _extends3.default)({}, state, {
        error: null
      });
    default:
      return state;
  }
}