'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AstonMartinFooter;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Footer = require('../../../components/AstonMartin/Footer');

var _Footer2 = _interopRequireDefault(_Footer);

var _AppShared = require('../../../types/AppShared');

var _footerTypes = require('../../../types/AstonMartin/footerTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function AstonMartinFooter(_ref) {
  var config = _ref.config,
      navigateIfInternal = _ref.navigateIfInternal,
      dispatch = _ref.dispatch,
      shared = _ref.shared,
      marketInfo = _ref.marketInfo;

  return _react2.default.createElement(_Footer2.default, {
    config: config,
    shared: shared,
    navigateIfInternal: navigateIfInternal,
    dispatch: dispatch,
    marketInfo: marketInfo
  });
}