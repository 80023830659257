'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n    width: 100%;\n    position: static;\n  '], ['\n    margin: 0;\n    width: 100%;\n    position: static;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactHelmet = require('react-helmet');

var _reactHashLink = require('react-hash-link');

var _reactHashLink2 = _interopRequireDefault(_reactHashLink);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _GlobalStyling = require('../components/GlobalStyling');

var _GlobalStyling2 = _interopRequireDefault(_GlobalStyling);

var _CompiledModule = require('./CompiledModule');

var _CompiledModule2 = _interopRequireDefault(_CompiledModule);

var _useSite2 = require('./useSite');

var _useSite3 = _interopRequireDefault(_useSite2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RenderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Site__RenderContainer',
  componentId: 'sc-11xscjp-0'
})(['display:flex;flex-direction:column;height:auto;margin:0 auto;position:relative;min-height:100vh;', ';@media print{display:block;}'], _theme2.default.max.large(_templateObject));

var GutterRenderer = function GutterRenderer(_ref) {
  var featureFlags = _ref.featureFlags,
      children = _ref.children;
  return featureFlags.useGutters ? _react2.default.createElement(
    'div',
    { style: { backgroundColor: '#181818' } },
    _react2.default.createElement(
      'div',
      { style: { margin: '0px auto', maxWidth: '1366px' } },
      children
    )
  ) : _react2.default.createElement(
    'div',
    null,
    children
  );
};

var compileGlobal = function compileGlobal(_ref2) {
  var band = _ref2.band,
      globalStyling = _ref2.globalStyling,
      path = _ref2.path,
      index = _ref2.index;

  return _react2.default.createElement(_CompiledModule2.default, {
    key: '' + (band.instanceId || band.id),
    band: band,
    path: path,
    globalStyling: globalStyling,
    index: index
  });
};

var bodyId = '$$BODY$$';

exports.default = function (props) {
  var globalStyling = props.globalStyling,
      path = props.path,
      hash = props.hash;

  var _useSite = (0, _useSite3.default)(),
      maps = _useSite.maps,
      analytics = _useSite.analytics,
      featureFlags = _useSite.featureFlags,
      siteBands = _useSite.siteBands,
      Body = _useSite.Body,
      ignoreBandList = _useSite.ignoreBandList;

  return _react2.default.createElement(
    GutterRenderer,
    { featureFlags: featureFlags },
    _react2.default.createElement(
      RenderContainer,
      { className: 'content' },
      _react2.default.createElement(_GlobalStyling2.default, { globalStyling: globalStyling }),
      _react2.default.createElement(
        _reactHelmet.Helmet,
        null,
        maps && !window.google && _react2.default.createElement('script', {
          'data-usercentrics': 'Google Maps',
          pid: 'S1pcEj_jZX',
          async: true,
          defer: true,
          src: 'https://maps.googleapis.com/maps/api/js?key=' + maps.apiKey + '&libraries=geometry,drawing,places&callback=Function.prototype'
        }),
        !featureFlags.ePaaS && analytics && analytics.googleTagManagerIds && analytics.googleTagManagerIds.map(function (gtmId) {
          return _react2.default.createElement('script', {
            key: 'gtm-' + gtmId,
            src: 'https://www.googletagmanager.com/gtm.js?id=' + gtmId
          });
        }),
        _react2.default.createElement(
          'script',
          null,
          '\n          window.dataLayer = window.dataLayer || [];\n          window.dataLayer.push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\'});'
        )
      ),
      _react2.default.createElement(_reactHashLink2.default, null),
      siteBands.filter(function (band) {
        return ignoreBandList ? !ignoreBandList.includes(band.id) : band;
      }).map(function (band, index) {
        return band.id === bodyId || band.name === bodyId ? _react2.default.createElement(Body, {
          key: bodyId,
          path: path,
          hash: hash,
          globalStyling: globalStyling,
          ignoreBandList: ignoreBandList
        }) : compileGlobal({
          band: band,
          index: index,
          globalStyling: globalStyling,
          path: path
        });
      })
    )
  );
};