'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _Global = require('../Global');

var _ = require('..');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var disallowedTypes = ['delete', 'linkReference', 'imageReference', 'table', 'tableHead', 'tableBody', 'tableRow', 'tableCell', 'definition', 'html', 'virtualHtml', 'parsedHtml', 'listItem'];

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      bodyText = _ref$config.bodyText,
      bodyFont = _ref$config.bodyFont,
      padding = _ref$config.padding,
      alignment = _ref$config.alignment,
      backgroundColour = _ref$config.backgroundColour;

  var MarkdownRenderers = {
    paragraph: function paragraph(props) {
      return _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)(bodyFont) + ' display: inline;';
          }
        },
        props.children
      );
    },
    heading: function heading(props) {
      switch (props.level) {
        case 2:
          return _react2.default.createElement(
            _Global.HeadingTwo,
            null,
            props.children
          );
        default:
          return _react2.default.createElement(
            _Global.HeadingOne,
            null,
            props.children
          );
      }
    }
  };

  return _react2.default.createElement(
    _.Wrapper,
    { backgroundColour: backgroundColour },
    bodyText && _react2.default.createElement(
      _.TextWrapper,
      { padding: padding, alignment: alignment },
      _react2.default.createElement(
        _reactMarkdown2.default,
        {
          renderers: MarkdownRenderers,
          disallowedTypes: disallowedTypes,
          escapeHtml: false,
          unwrapDisallowed: true
        },
        bodyText
      )
    )
  );
};