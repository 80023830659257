'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends5 = require('babel-runtime/helpers/extends');

var _extends6 = _interopRequireDefault(_extends5);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  LOAD_REPRESENTATIVE_EXAMPLE: 'LOAD_REPRESENTATIVE_EXAMPLE',
  LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS: 'LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS',
  LOAD_FINANCE_OPTIONS: 'LOAD_FINANCE_OPTIONS',
  LOAD_FINANCE_OPTIONS_SUCCESS: 'LOAD_FINANCE_OPTIONS_SUCCESS',
  LOAD_FINANCE_PRODUCT: 'LOAD_FINANCE_PRODUCT',
  LOAD_FINANCE_PRODUCT_SUCCESS: 'LOAD_FINANCE_PRODUCT_SUCCESS',
  RESET_FINANCE_PRODUCT: 'RESET_FINANCE_PRODUCT'
};

var actions = exports.actions = {
  loadRepresentativeExample: function loadRepresentativeExample() {
    return {
      type: constants.LOAD_REPRESENTATIVE_EXAMPLE
    };
  },
  loadRepresentativeExampleSuccess: function loadRepresentativeExampleSuccess(representativeExample) {
    return {
      type: constants.LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS,
      payload: representativeExample
    };
  },
  loadFinanceOptions: function loadFinanceOptions(payload) {
    return {
      type: constants.LOAD_FINANCE_OPTIONS,
      payload: payload
    };
  },
  loadFinanceOptionsSuccess: function loadFinanceOptionsSuccess(payload) {
    return {
      type: constants.LOAD_FINANCE_OPTIONS_SUCCESS,
      payload: payload
    };
  },
  loadFinanceProduct: function loadFinanceProduct(payload) {
    return {
      type: constants.LOAD_FINANCE_PRODUCT,
      payload: payload
    };
  },
  loadFinanceProductSuccess: function loadFinanceProductSuccess(payload) {
    return {
      type: constants.LOAD_FINANCE_PRODUCT_SUCCESS,
      payload: payload
    };
  },
  resetFinanceProduct: function resetFinanceProduct(payload) {
    return {
      type: constants.RESET_FINANCE_PRODUCT,
      payload: payload
    };
  },
  setAstonMartinFinanceValues: function setAstonMartinFinanceValues(payload) {
    return {
      type: constants.SET_ASTON_MARTIN_FINANCE_VALUES,
      payload: payload
    };
  }
};

var initialState = {
  representativeExample: null,
  financeOptions: {},
  financeProductExample: {}
};

var transformMarketFinanceOptions = function transformMarketFinanceOptions(options) {
  return options.filter(function (o) {
    return !!o.financeType;
  }).reduce(function (acc, option) {
    var financeType = option.financeType,
        durationOptions = option.durationOptions,
        downpaymentRange = option.downpaymentRange;

    return (0, _extends6.default)({}, acc, (0, _defineProperty3.default)({}, financeType, {
      durationOptions: durationOptions,
      downpaymentRange: downpaymentRange
    }));
  }, {});
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case constants.LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS:
      return (0, _extends6.default)({}, state, {
        representativeExample: payload
      });
    case constants.LOAD_FINANCE_OPTIONS_SUCCESS:
      return (0, _extends6.default)({}, state, {
        financeOptions: (0, _extends6.default)({}, state.financeOptions, transformMarketFinanceOptions(payload.data))
      });
    case constants.LOAD_FINANCE_PRODUCT_SUCCESS:
      return (0, _extends6.default)({}, state, {
        financeProductExample: (0, _extends6.default)({}, state.financeProductExample, (0, _defineProperty3.default)({}, payload.type, payload.data))
      });
    case constants.RESET_FINANCE_PRODUCT:
      return (0, _extends6.default)({}, state, {
        financeProductExample: (0, _extends6.default)({}, state.financeProductExample, (0, _defineProperty3.default)({}, payload.type, null))
      });
    default:
      return state;
  }
}