'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n\n     ', ';\n  '], ['\n    width: 100%;\n\n     ', ';\n  ']);

exports.default = FormField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FormFieldContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FormField__FormFieldContainer',
  componentId: 'sc-1uz38wq-0'
})(['margin-bottom:20px;width:auto;display:flex;flex-direction:column;justify-content:flex-end;flex-grow:1;', ';', ';', ';'], function (props) {
  return props.doubleRow && (0, _styledComponents.css)(['flex-grow:initial;width:48%;']);
}, function (props) {
  return props.mobileCode && (0, _styledComponents.css)(['flex-grow:initial;margin-right:10px;']);
}, _theme2.default.max.large(_templateObject, function (props) {
  return props.mobileCode && (0, _styledComponents.css)(['width:auto;min-width:80px;']);
}));

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'FormField__Label',
  componentId: 'sc-1uz38wq-1'
})(['margin-bottom:10px;', ';', ';'], function (_ref) {
  var direction = _ref.direction;
  return direction === 'rtl' ? 'text-align: right;' : 'text-align: left;';
}, function (_ref2) {
  var styledDisabledInput = _ref2.styledDisabledInput,
      globalStyling = _ref2.globalStyling;
  return styledDisabledInput && globalStyling && 'color: ' + (globalStyling && globalStyling.colours.primaryBrandColour.value) + ';\n  ';
});

function FormField(_ref3) {
  var doubleRow = _ref3.doubleRow,
      mobileCode = _ref3.mobileCode,
      label = _ref3.label,
      required = _ref3.required,
      children = _ref3.children,
      globalStyling = _ref3.globalStyling,
      styledDisabledInput = _ref3.styledDisabledInput,
      labelFor = _ref3.labelFor;

  var _ref4 = globalStyling || {},
      _ref4$direction = _ref4.direction,
      direction = _ref4$direction === undefined ? '' : _ref4$direction;

  return _react2.default.createElement(
    FormFieldContainer,
    { doubleRow: doubleRow, mobileCode: mobileCode },
    label && _react2.default.createElement(
      Label,
      {
        direction: direction,
        styledDisabledInput: styledDisabledInput,
        globalStyling: globalStyling,
        htmlFor: labelFor
      },
      label,
      required && '*'
    ),
    children
  );
}