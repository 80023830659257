'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MarkdownRenderers = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    min-height: ', ';\n    max-height: 750px;\n    height: 100%;\n  '], ['\n    min-height: ', ';\n    max-height: 750px;\n    height: 100%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    max-height:none;\n  '], ['\n    flex-direction: column;\n    max-height:none;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display:block;\n  '], ['\n    display:block;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n  '], ['\n  ', ';\n  ']);

exports.default = FiftyFifty;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../components/Global');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _router = require('../../actions/router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'FiftyFifty__Container',
  componentId: 'sc-z9zw6x-0'
})(['display:flex;flex-direction:', ';', ';', ';', ';'], function (props) {
  return props.imageAlign === 'left' ? 'row' : 'row-reverse';
}, _theme2.default.min.large(_templateObject, function (props) {
  return props.minHeight;
}), _theme2.default.max.small(_templateObject2), function (_ref) {
  var border = _ref.border;
  return '\n    border-bottom: 1px solid ' + border + ';\n  ';
});

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'Column'
}).withConfig({
  displayName: 'FiftyFifty__Column',
  componentId: 'sc-z9zw6x-1'
})(['width:50%;display:flex;', ';'], _theme2.default.max.medium(_templateObject3));

var Spacer = _styledComponents2.default.div.withConfig({
  displayName: 'Spacer'
}).withConfig({
  displayName: 'FiftyFifty__Spacer',
  componentId: 'sc-z9zw6x-2'
})(['margin-bottom:20px;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'FiftyFifty__Image',
  componentId: 'sc-z9zw6x-3'
})(['align-self:center;width:100%;height:auto;']);

var contentAlignResolver = function contentAlignResolver(align) {
  if (align === 'center') {
    return 'center';
  }
  if (align === 'bottom') {
    return 'flex-end';
  }
  return 'flex-start';
};

var ColumnWithFlex = (0, _styledComponents2.default)(Column).withConfig({
  displayName: 'Column With Flex'
}).withConfig({
  displayName: 'FiftyFifty__ColumnWithFlex',
  componentId: 'sc-z9zw6x-4'
})(['justify-content:', ';display:flex;flex-direction:column;', ';'], function (props) {
  return contentAlignResolver(props.contentAlign);
}, _theme2.default.max.large(_templateObject4));

var TextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__TextContainer',
  componentId: 'sc-z9zw6x-5'
})(['line-height:21px;', ';'], function (_ref2) {
  var maxWidth = _ref2.maxWidth;
  return maxWidth && '\n  max-width: ' + maxWidth + 'px;\n';
});

var DivWithPadding = _styledComponents2.default.div.withConfig({
  displayName: 'Div With Padding'
}).withConfig({
  displayName: 'FiftyFifty__DivWithPadding',
  componentId: 'sc-z9zw6x-6'
})(['padding:25px 25px 25px 25px;', ' ', ';'], function (_ref3) {
  var contentPadding = _ref3.contentPadding;
  return contentPadding && 'padding: ' + contentPadding + ';';
}, _theme2.default.max.small(_templateObject5, function (_ref4) {
  var contentPaddingMobile = _ref4.contentPaddingMobile;
  return contentPaddingMobile && 'padding: ' + contentPaddingMobile + ';';
}));

var StyledUl = _styledComponents2.default.ul.withConfig({
  displayName: 'FiftyFifty__StyledUl',
  componentId: 'sc-z9zw6x-7'
})(['font-size:', ';color:', ';'], function (props) {
  return props.bodyFont && props.bodyFont.fontSize ? props.bodyFont.fontSize + 'px' : 'inherit';
}, function (props) {
  return props.bodyFont && props.bodyFont.colour && props.bodyFont.colour.value ? props.bodyFont.colour.value : 'inherit';
});

var MarkdownRenderers = exports.MarkdownRenderers = function MarkdownRenderers(bodyFont) {
  return {
    paragraph: function paragraph(props) {
      return _react2.default.createElement(
        _Global.Paragraph,
        { styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)(bodyFont);
          } },
        props.children
      );
    },
    list: function list(props) {
      return _react2.default.createElement(
        StyledUl,
        { bodyFont: bodyFont },
        props.children
      );
    }
  };
};

function FiftyFifty(_ref5) {
  var config = _ref5.config,
      globalStyling = _ref5.globalStyling,
      dispatch = _ref5.dispatch;

  var link = config.buttonText || {};

  return _react2.default.createElement(
    _ContentWrapper2.default,
    {
      colour: config.backgroundColour && config.backgroundColour,
      contentWidth: globalStyling.contentWidth,
      marginTopBottom: config.marginTopBottom && config.marginTopBottom.value
    },
    _react2.default.createElement(
      Container,
      {
        colour: config.backgroundColour && config.backgroundColour.value,
        imageAlign: config.imageAlign || 'left',
        border: config.bottomBorder && config.borderColour.value,
        minHeight: config.minHeight || '450px'
      },
      _react2.default.createElement(
        Column,
        null,
        _react2.default.createElement(Image, {
          'data-cy': config.selectedImage.value,
          src: config.selectedImage && config.selectedImage.value,
          alt: config.translations && config.translations.alternativeText || ''
        })
      ),
      _react2.default.createElement(
        ColumnWithFlex,
        { contentAlign: config.contentAlign },
        _react2.default.createElement(
          DivWithPadding,
          {
            contentPadding: config.contentTextPadding,
            contentPaddingMobile: config.contentTextPaddingMobile
          },
          _react2.default.createElement(
            Spacer,
            null,
            _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return (0, _Global.fontStyleOverride)(config.headlineFont);
                },
                displayHeadingDivider: config.displayHeadingDivider,
                'data-cy': config.translations.headlineText,
                headingDividerStyle: config.headingDividerStyle
              },
              config.translations.headlineText
            )
          ),
          _react2.default.createElement(
            TextContainer,
            { maxWidth: config.contentMaxWidth },
            _react2.default.createElement(
              _reactMarkdown2.default,
              { renderers: MarkdownRenderers(config.bodyFont) },
              config.translations.bodyText
            )
          )
        ),
        link.on && _react2.default.createElement(
          DivWithPadding,
          {
            contentPadding: config.contentTextPadding,
            contentPaddingMobile: config.contentTextPaddingMobile
          },
          _react2.default.createElement(_Global.Button, {
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return (0, _Global.buttonStyleOverride)(config.buttonFont, config.buttonBackgroundColour, config.buttonHover, config.buttonSize, config.buttonHoverColour);
            },
            text: config.translations.buttonText,
            onClick: function onClick() {
              return dispatch(_router.actions.navigate(config.buttonText.url));
            }
          })
        )
      )
    )
  );
}