'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TableData = _styledComponents2.default.td.withConfig({
  displayName: 'TableData',
  componentId: 'sc-1lmzaao-0'
})(['vertical-align:', ';border-bottom:', ';padding-left:10px;color:#444444;', ''], function (_ref) {
  var verticalAlign = _ref.verticalAlign;
  return verticalAlign ? verticalAlign : 'inherit';
}, function (_ref2) {
  var hideBottomBorder = _ref2.hideBottomBorder;
  return hideBottomBorder ? 'none' : '1px solid #d8d8d8';
}, function (_ref3) {
  var priceRow = _ref3.priceRow;
  return priceRow && 'padding-right: 10px;';
});

exports.default = TableData;