'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OverlayContent = exports.Overlay = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 20px;\n  '], ['\n    font-size: 20px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 14px;\n    width: 100%;\n    flex-direction: column;\n  '], ['\n    font-size: 14px;\n    width: 100%;\n    flex-direction: column;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ModelSearchBar = require('../../../components/Bentley/ModelSearchBar');

var _ModelVariantTile = require('../../../components/Bentley/ModelsSearch/ModelVariantTile');

var _ModelVariantTile2 = _interopRequireDefault(_ModelVariantTile);

var _Spinner = require('../../../components/Spinner');

var _modelTypes = require('../../../types/Bentley/modelTypes');

var _globalStyling = require('../../../types/globalStyling');

var _useModelSearch2 = require('./useModelSearch');

var _OverlaySearch = require('../../../components/Bentley/OverlaySearch');

var _OverlaySearch2 = _interopRequireDefault(_OverlaySearch);

var _useLocationFilter2 = require('../../../hooks/useLocationFilter');

var _location = require('../../../shared/location');

var _location2 = _interopRequireDefault(_location);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Overlay = exports.Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__Overlay',
  componentId: 'sc-4i7qsr-0'
})(['color:#fff;display:flex;justify-content:center;position:fixed;height:100vh;width:100%;top:0;left:0;background-color:rgba(255,255,255,0.7);backdrop-filter:blur(12px);visibility:hidden;opacity:0;', ';overflow:hidden;transition:visibility 0.35s,opacity 0.35s;', ';'], function (_ref) {
  var open = _ref.open;
  return open && 'z-index: 1000';
}, function (_ref2) {
  var open = _ref2.open;
  return open && 'visibility: visible; opacity: 1;';
});

var OverlayContent = exports.OverlayContent = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__OverlayContent',
  componentId: 'sc-4i7qsr-1'
})(['width:100%;max-width:680px;padding:24px;display:flex;align-items:center;justify-content:center;']);

var Header = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__Header',
  componentId: 'sc-4i7qsr-2'
})(['display:flex;justify-content:space-between;margin:40px 0;font-size:16px;font-family:AstonMartinFlare-Medium;text-transform:uppercase;text-align:center;justify-content:center;width:100%;']);

var HeaderPreviousModels = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__HeaderPreviousModels',
  componentId: 'sc-4i7qsr-3'
})(['font-family:Bentley-Regular;font-size:13px;line-height:18px;text-transform:uppercase;padding:20px 0;margin:40px 0;background-color:#e7e7e7;text-align:center;']);

var HeaderTitle = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__HeaderTitle',
  componentId: 'sc-4i7qsr-4'
})(['display:flex;width:1000px;justify-content:center;margin:0 30px;font-family:Bentley-Regular;font-size:30px;line-height:28px;', ';', ';'], _theme2.default.max.large(_templateObject), _theme2.default.max.small(_templateObject2));

var ModelSearchWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__ModelSearchWrapper',
  componentId: 'sc-4i7qsr-5'
})(['margin-bottom:40px;', ';'], _theme2.default.max.small(_templateObject3));

var StickyBottomSearchBarWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__StickyBottomSearchBarWrapper',
  componentId: 'sc-4i7qsr-6'
})(['position:sticky;bottom:0;left:0;width:100%;']);

var ModelSearch = function ModelSearch(_ref3) {
  var config = (0, _objectWithoutProperties3.default)(_ref3.config, []),
      globalStyling = _ref3.globalStyling;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var _useModelSearch = (0, _useModelSearch2.useModelSearch)(config.searchSections),
      getModelGroups = _useModelSearch.getModelGroups,
      onTileSelected = _useModelSearch.onTileSelected,
      initialiseFilters = _useModelSearch.initialiseFilters,
      clearState = _useModelSearch.clearState,
      modelGroups = _useModelSearch.modelGroups,
      clearQueryParams = _useModelSearch.clearQueryParams,
      selectedTiles = _useModelSearch.selectedTiles,
      prepareSRPFilters = _useModelSearch.prepareSRPFilters,
      isTileSelected = _useModelSearch.isTileSelected;

  var _useLocationFilter = (0, _useLocationFilter2.useLocationFilter)(),
      onButtonClick = _useLocationFilter.onButtonClick,
      updateLocationFilter = _useLocationFilter.updateLocationFilter,
      latitude = _useLocationFilter.latitude,
      longitude = _useLocationFilter.longitude;

  (0, _react.useEffect)(function () {
    clearQueryParams();
    clearState();
    initialiseFilters();
    getModelGroups();
    return function () {
      clearState();
    };
  }, []);

  var _useLocation = (0, _location2.default)(),
      setLat = _useLocation.setLat,
      setLong = _useLocation.setLong,
      googleAvailable = _useLocation.googleAvailable,
      setLocationTown = _useLocation.setLocationTown,
      setUserCountry = _useLocation.setUserCountry;

  var handleBrowserLocation = (0, _react.useCallback)(function (_ref4) {
    var lat = _ref4.latitude,
        long = _ref4.longitude,
        locationTown = _ref4.locationTown,
        userCountry = _ref4.userCountry;

    setLat(lat);
    setLong(long);
    setLocationTown(locationTown || '');
    setUserCountry(userCountry);
    updateLocationFilter({
      latitude: lat,
      longitude: long,
      locationTown: locationTown,
      userCountry: userCountry
    });
  }, [setLat, setLong, updateLocationFilter]);

  var locationCallback = function locationCallback(lat, long, town, countryCode) {
    handleBrowserLocation({
      latitude: lat,
      longitude: long,
      locationTown: town,
      userCountry: countryCode
    });
  };

  var handleOnClick = (0, _react.useCallback)(function () {
    prepareSRPFilters(selectedTiles);
    if (latitude && longitude) {
      onButtonClick(false, true);
      return;
    }

    setOpen(true);
  }, [latitude, longitude, onButtonClick]);

  var currentModels = (0, _react.useMemo)(function () {
    return config.searchSections.filter(function (section) {
      return section.name === 'Current';
    });
  }, [config.searchSections]);

  var previousModels = (0, _react.useMemo)(function () {
    return config.searchSections.filter(function (section) {
      return section.name === 'Previous';
    });
  }, [config.searchSections]);

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      Header,
      null,
      _react2.default.createElement(
        HeaderTitle,
        null,
        _react2.default.createElement(
          'span',
          null,
          config.translations.title
        )
      )
    ),
    _react2.default.createElement(
      _Spinner.SpinnerWrapper,
      {
        loaded: modelGroups.length > 0,
        globalStyling: globalStyling
      },
      function () {
        return _react2.default.createElement(
          ModelSearchWrapper,
          null,
          currentModels.map(function (section) {
            return _react2.default.createElement(_ModelVariantTile2.default, {
              key: section.name,
              section: section,
              config: config,
              onTileSelected: onTileSelected,
              isTileSelected: isTileSelected,
              width: '1520px' // 1500px (300px width * 5 tiles) + 20px of tile gaps.
            });
          }),
          previousModels.length > 0 && _react2.default.createElement(
            _react2.default.Fragment,
            null,
            _react2.default.createElement(
              HeaderPreviousModels,
              null,
              config.translations.previousModels
            ),
            previousModels.map(function (section) {
              return _react2.default.createElement(_ModelVariantTile2.default, {
                key: section.name,
                section: section,
                config: config,
                width: '1825px' // 1800px (300px width * 6 tiles) + 25px of tile gaps.
                , onTileSelected: onTileSelected,
                isTileSelected: isTileSelected
              });
            })
          )
        );
      }
    ),
    _react2.default.createElement(
      Overlay,
      { open: open },
      _react2.default.createElement(
        OverlayContent,
        null,
        googleAvailable && _react2.default.createElement(_OverlaySearch2.default, {
          iconUrl: config.locationIconUrl || '',
          browserLocationCallback: locationCallback,
          inputLocationCallback: locationCallback,
          translations: config.translations,
          onButtonClick: handleOnClick
        })
      )
    ),
    _react2.default.createElement(
      StickyBottomSearchBarWrapper,
      null,
      _react2.default.createElement(_ModelSearchBar.ModelSearchBar, {
        config: config,
        tilesSelection: selectedTiles,
        prepareSRPFilters: prepareSRPFilters,
        handleOnClick: handleOnClick,
        locationCallback: locationCallback,
        latitude: latitude,
        longitude: longitude,
        googleAvailable: googleAvailable
      })
    )
  );
};

exports.default = ModelSearch;