'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PlayVideo;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function PlayVideo(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 55 55' },
      _react2.default.createElement('path', { d: 'M27.5 51C40.479 51 51 40.479 51 27.5S40.479 4 27.5 4 4 14.521 4 27.5 14.521 51 27.5 51zm0 4C12.312 55 0 42.688 0 27.5S12.312 0 27.5 0 55 12.312 55 27.5 42.688 55 27.5 55z' }),
      _react2.default.createElement('path', { d: 'M24 38.417h-3V18.824c0-4.162 4.257-6.125 7.374-3.889 2.6 1.865 9.592 8.232 10.468 9.983 1.056 2.114.888 3.565 0 5.342C38.028 31.886 33.919 35.465 27 41l-1.874-2.343c6.415-5.131 10.512-8.7 11.032-9.74.506-1.011.566-1.526 0-2.657-.58-1.16-7.333-7.31-9.532-8.887-1.19-.854-2.626-.192-2.626 1.45v19.594z' })
    )
  );
}

PlayVideo.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};