'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.useScript = useScript;

var _react = require('react');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useScript(_ref) {
  var src = _ref.playerScriptSrc,
      mediaRef = _ref.mediaRef,
      showMedia = _ref.showMedia,
      mediaView = _ref.mediaView,
      vehicleId = _ref.vehicleId;

  var _useState = (0, _react.useState)({
    loaded: false,
    error: false
  }),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  (0, _react.useEffect)(function () {
    if (!showMedia) {
      return;
    }

    var script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    script.setAttribute('data-' + mediaView, true);
    script.setAttribute('data-subject', vehicleId);

    var onScriptLoad = function onScriptLoad() {
      setState({
        loaded: true,
        error: false
      });
    };

    var onScriptError = function onScriptError() {
      script.remove();

      setState({
        loaded: true,
        error: true
      });
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    while (mediaRef.current.firstChild) {
      mediaRef.current.removeChild(mediaRef.current.firstChild);
    }

    mediaRef.current.appendChild(script);

    return function () {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, [src, showMedia, mediaView]);

  return state.error;
} /* eslint-disable consistent-return */