'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var _SectionIcons = require('../../helpers/SectionIcons');

var _SectionIcons2 = _interopRequireDefault(_SectionIcons);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var config = (0, _config.Config)('ModelGrid', { id: 'modelGrid' }, (0, _config.Slice)('Layout', { id: 'layout' }, (0, _config.Section)('Grid items', { icon: _SectionIcons2.default.TopTier }, (0, _config.Align)('Align', 'gridItemsAlign'))), (0, _config.ListSlice)('Content', {
  id: 'items',
  max: 9,
  sectionLabel: 'Grid item',
  buttonLabel: 'Add new grid item',
  icon: _SectionIcons2.default.GridItem
}, (0, _config.Image)('Image', 'image'), (0, _config.TextArea)('Headline', 'headline'), (0, _config.TextArea)('Body Text', 'body'), (0, _config.Link)('Link Text', 'link')), (0, _config.Slice)('Styling', { id: 'styling' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.TopTier }, (0, _config.Colour)('Background', 'gridBackgroundColour'), (0, _config.Font)('Header text', 'gridItemHeaderFont'), (0, _config.Font)('Body text', 'gridItemBodyFont'), (0, _config.Font)('Button Text', 'buttonFont'), (0, _config.Colour)('Button Colour', 'buttonBackgroundColour'), (0, _config.Colour)('Button Hover', 'buttonHover'))));

exports.default = config;