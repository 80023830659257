'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});


var displayKiaEmissionsConsumption = function displayKiaEmissionsConsumption(vehicle, translations) {
  var fuel = vehicle.fuel,
      engineDetails = vehicle.engineDetails,
      emissions = vehicle.emissions,
      consumption = vehicle.consumption,
      energyEfficiencyRating = vehicle.energyEfficiencyRating;


  switch (fuel) {
    case 'Plug-in Hybrid – (Benzin/Elektro)':
    case 'Plug-in Hybrid– (Diesel/Elektro)':
      if (engineDetails && engineDetails.weightedCombinedFuelConsumption !== null && engineDetails.weightedCombinedCo2Emission !== null && emissions && emissions.co2ClassWeightedCombined !== null && emissions.co2ClassDischaredBattery !== null && consumption && consumption.wltpWeightedCombinedConsumptionDischargedBattery !== null && consumption.wltpPowerConsumptionWeightedCombined !== null && consumption.wltpBatteryRange !== null) {
        return translations.weightedCombinedFuelConsumptionLabel + ' ' + engineDetails.weightedCombinedFuelConsumption + ' l/100 km; ' + translations.powerConsumptionCombinedLabel + ' ' + consumption.wltpPowerConsumptionWeightedCombined + ' kWh/100 km; ' + translations.co2EmissionsCombinedLabel + ' ' + engineDetails.weightedCombinedCo2Emission + ' g/km; ' + translations.co2ClassCombinedLabel + ' ' + emissions.co2ClassWeightedCombined + '. ' + translations.fuelConsumptionWithDischargedBatteryLabel + ' ' + consumption.wltpWeightedCombinedConsumptionDischargedBattery + ' l/100 km; ' + translations.co2ClassWithDischargedBatteryLabel + ' ' + emissions.co2ClassDischaredBattery + '.';
      }
      break;

    case 'Strom':
      if (engineDetails && consumption && consumption.wltpPowerConsumptionCombined !== null && emissions && emissions.wltpEmissionsCombined !== null && energyEfficiencyRating !== null && consumption.wltpBatteryRange !== null) {
        return translations.combinedPowerLabel + ' ' + consumption.wltpPowerConsumptionCombined + ' kWh/100 km; ' + translations.combinedCO2EmissionsLabel + ' ' + emissions.wltpEmissionsCombined + ' g/km; ' + translations.co2ClassLabel + ' ' + energyEfficiencyRating + '.';
      }
      break;

    default:
      if (emissions && emissions.wltpEmissionsCombined !== null && consumption && consumption.wltpConsumptionCombined !== null && energyEfficiencyRating !== null) {
        return translations.fuelConsumptionLabel + ' ' + consumption.wltpConsumptionCombined + ' l/100 km; ' + translations.combinedCO2EmissionsLabel + ' ' + emissions.wltpEmissionsCombined + ' g/km; ' + translations.co2ClassLabel + ' ' + energyEfficiencyRating + '.';
      }
  }

  return null;
};

var displayKiaElectricRange = function displayKiaElectricRange(vehicle, translations) {
  var fuel = vehicle.fuel,
      engineDetails = vehicle.engineDetails,
      emissions = vehicle.emissions,
      consumption = vehicle.consumption,
      energyEfficiencyRating = vehicle.energyEfficiencyRating;


  switch (fuel) {
    case 'Plug-in Hybrid – (Benzin/Elektro)':
    case 'Plug-in Hybrid– (Diesel/Elektro)':
      if (engineDetails && engineDetails.weightedCombinedFuelConsumption !== null && engineDetails.weightedCombinedCo2Emission !== null && emissions && emissions.co2ClassWeightedCombined !== null && emissions.co2ClassDischaredBattery !== null && consumption && consumption.wltpWeightedCombinedConsumptionDischargedBattery !== null && consumption.wltpPowerConsumptionWeightedCombined !== null && consumption.wltpBatteryRange !== null) {
        return translations.electricRangeHybridLabel + ' ' + consumption.wltpBatteryRange + ' km.';
      }
      break;
    case 'Strom':
      if (engineDetails && consumption && consumption.wltpPowerConsumptionCombined !== null && emissions && emissions.wltpEmissionsCombined !== null && energyEfficiencyRating !== null && consumption.wltpBatteryRange !== null) {
        return translations.electricRangeLabel + ' ' + consumption.wltpBatteryRange + ' km.';
      }
      break;
  }

  return null;
};

exports.displayKiaEmissionsConsumption = displayKiaEmissionsConsumption;
exports.displayKiaElectricRange = displayKiaElectricRange;