'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mountComponent = exports.moduleActionWithArgs = exports.moduleAction = exports.moduleConstants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConstants = exports.moduleConstants = function moduleConstants(modulePrefix, keys) {
  return keys.reduce(function (constants, key) {
    return (0, _extends4.default)({}, constants, (0, _defineProperty3.default)({}, key, modulePrefix + '_' + key));
  }, {});
};

var moduleAction = exports.moduleAction = function moduleAction(type, mod) {
  return function (page, instance) {
    return {
      forModuleInstance: true,
      type: type,
      module: { id: mod, page: page, instance: instance }
    };
  };
};

var moduleActionWithArgs = exports.moduleActionWithArgs = function moduleActionWithArgs(type, mod, callback) {
  return function (page, instance) {
    for (var _len = arguments.length, rest = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      rest[_key - 2] = arguments[_key];
    }

    return (0, _extends4.default)({
      payload: callback.apply(undefined, rest)
    }, moduleAction(type, mod)(page, instance));
  };
};

var mountComponent = exports.mountComponent = function mountComponent(moduleConfig) {
  return {
    forModuleInstance: true,
    type: 'CMS_COMPONENTS_MOUNT',
    module: moduleConfig
  };
};