'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-family: \'RivieraNights-Medium\';\n    font-weight: normal;\n    padding: 96px 0 64px 0;\n  '], ['\n    font-family: \'RivieraNights-Medium\';\n    font-weight: normal;\n    padding: 96px 0 64px 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 20px;\n    font-weight: 400;\n  '], ['\n    font-size: 20px;\n    font-weight: 400;\n  ']);

exports.default = RollsRoyceJourneyGrid;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _RollsRoyceJourneyGridItem = require('./RollsRoyceJourneyGridItem');

var _RollsRoyceJourneyGridItem2 = _interopRequireDefault(_RollsRoyceJourneyGridItem);

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var JourneyGridWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceJourneyGrid__JourneyGridWrapper',
  componentId: 'sc-1a4exp0-0'
})(['display:flex;align-items:center;flex-direction:column;justify-content:center;align-items:center;background-color:#151515;width:100%;border-bottom:1px solid #151515;']);

var JourneyGridHeading = _styledComponents2.default.h2.withConfig({
  displayName: 'RollsRoyceJourneyGrid__JourneyGridHeading',
  componentId: 'sc-1a4exp0-1'
})(['color:#ffffff;text-transform:uppercase;font-family:\'RivieraNights-Light\';font-weight:normal;font-size:18px;letter-spacing:2.5px;line-height:30px;margin:0px;padding:32px 0 26px 0;', ';', ';'], _RollsRoyce2.default.min.small(_templateObject), _RollsRoyce2.default.min.large(_templateObject2));

var JourneyGridContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceJourneyGrid__JourneyGridContainer',
  componentId: 'sc-1a4exp0-2'
})(['display:flex;flex-direction:column;max-width:1328px;margin:0 16px;@media only screen and (min-width:769px){display:grid;gap:16px;grid-template-columns:repeat(3,1fr);grid-auto-rows:1fr;grid-column-gap:16px;}']);

function RollsRoyceJourneyGrid(_ref) {
  var config = _ref.config;
  var _config$items = config.items,
      items = _config$items === undefined ? [] : _config$items,
      _config$translations$ = config.translations.sectionTitle,
      sectionTitle = _config$translations$ === undefined ? '' : _config$translations$;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      renderList = _useState2[0],
      setRenderList = _useState2[1];

  return _react2.default.createElement(
    JourneyGridWrapper,
    null,
    _react2.default.createElement(_reactWaypoint2.default, {
      onEnter: function onEnter() {
        setRenderList(true);
      }
    }),
    renderList && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        JourneyGridHeading,
        null,
        sectionTitle
      ),
      _react2.default.createElement(
        JourneyGridContainer,
        null,
        items.map(function (item, index) {
          var _item$link$url = item.link.url,
              url = _item$link$url === undefined ? '' : _item$link$url,
              _item$image = item.image,
              _item$image$value = _item$image.value,
              value = _item$image$value === undefined ? '' : _item$image$value,
              _item$image$label = _item$image.label,
              label = _item$image$label === undefined ? '' : _item$image$label,
              _item$translations = item.translations,
              _item$translations$he = _item$translations.headline,
              headline = _item$translations$he === undefined ? '' : _item$translations$he,
              _item$translations$bo = _item$translations.body,
              body = _item$translations$bo === undefined ? '' : _item$translations$bo;

          return _react2.default.createElement(_RollsRoyceJourneyGridItem2.default, {
            key: headline,
            headline: headline,
            body: body,
            imageUrl: value,
            imageAlt: label,
            index: index,
            link: url
          });
        })
      )
    )
  );
}