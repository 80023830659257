'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactSwipeable = require('react-swipeable');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RenderMedia = require('../VehicleGallery/RenderMedia');

var _RenderMedia2 = _interopRequireDefault(_RenderMedia);

var _CarouselSlide = require('./CarouselSlide');

var _CarouselSlide2 = _interopRequireDefault(_CarouselSlide);

var _renderNavigation = require('./renderNavigation');

var _renderNavigation2 = _interopRequireDefault(_renderNavigation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable react/sort-comp */
var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Carousel__Container',
  componentId: 'sc-1tx9r37-0'
})(['width:100%;']);

var SwipeContainer = (0, _styledComponents2.default)(_reactSwipeable.Swipeable).withConfig({
  displayName: 'Carousel__SwipeContainer',
  componentId: 'sc-1tx9r37-1'
})(['height:100%;width:100%;position:relative;padding-bottom:15px;']);

var SlidesContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Carousel__SlidesContainer',
  componentId: 'sc-1tx9r37-2'
})(['width:100%;height:100%;overflow:hidden;']);

var Carousel = function (_Component) {
  (0, _inherits3.default)(Carousel, _Component);

  function Carousel(props) {
    (0, _classCallCheck3.default)(this, Carousel);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Carousel.__proto__ || (0, _getPrototypeOf2.default)(Carousel)).call(this, props));

    Object.defineProperty(_this, 'showMedia', {
      enumerable: true,
      writable: true,
      value: function value(mediaType) {
        return _this.setState({ isMediaVisible: true, mediaType: mediaType });
      }
    });
    Object.defineProperty(_this, 'hideMedia', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _this.setState({ isMediaVisible: false });
      }
    });
    Object.defineProperty(_this, 'goToPrev', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            rotate = _this$props.rotate,
            onClickPrev = _this$props.onClickPrev,
            slideIndex = _this$props.slideIndex;

        var canGoPrev = rotate || slideIndex > 0;
        if (canGoPrev) {
          onClickPrev();
        }
      }
    });
    Object.defineProperty(_this, 'goToNext', {
      enumerable: true,
      writable: true,
      value: function value() {
        var slideTotal = _this.state.slideTotal;
        var _this$props2 = _this.props,
            isLooping = _this$props2.isLooping,
            rotate = _this$props2.rotate,
            slideIndex = _this$props2.slideIndex,
            onClickNext = _this$props2.onClickNext;

        var canGoNext = rotate || isLooping || slideIndex + 1 < (slideTotal || 0);
        if (canGoNext) {
          onClickNext();
        }
      }
    });

    _this.state = {
      isMediaVisible: false
    };
    return _this;
  }

  (0, _createClass3.default)(Carousel, [{
    key: 'render',
    value: function render() {
      var _state = this.state,
          isMediaVisible = _state.isMediaVisible,
          mediaType = _state.mediaType;
      var slides = this.props.slides;
      var _props = this.props,
          renderFooter = _props.renderFooter,
          _props$renderNavigati = _props.renderNavigation,
          renderNavigationProp = _props$renderNavigati === undefined ? _renderNavigation2.default : _props$renderNavigati,
          slideIndex = _props.slideIndex,
          arrowRight = _props.arrowRight,
          arrowLeft = _props.arrowLeft,
          enable360View = _props.enable360View,
          vehicleInfo = _props.vehicleInfo,
          globalStyling = _props.globalStyling,
          close360Icon = _props.close360Icon,
          translations = _props.translations,
          imageCarousel = _props.imageCarousel,
          fullscreen = _props.fullscreen,
          parsedYouTubeId = _props.parsedYouTubeId,
          modalOverlayRef = _props.modalOverlayRef;


      var goLeft = globalStyling.direction === 'rtl' ? this.goToNext : this.goToPrev;
      var goRight = globalStyling.direction === 'rtl' ? this.goToPrev : this.goToNext;

      return _react2.default.createElement(
        Container,
        null,
        isMediaVisible ? _react2.default.createElement(_RenderMedia2.default, {
          hideMedia: this.hideMedia,
          mediaType: mediaType,
          vehicleInfo: vehicleInfo,
          enable360View: enable360View,
          close360Icon: close360Icon,
          globalStyling: globalStyling,
          translations: translations,
          imageCarousel: imageCarousel,
          parsedYouTubeId: parsedYouTubeId
        }) : _react2.default.createElement(
          SwipeContainer,
          {
            trackMouse: true,
            style: { touchAction: 'pan-y' },
            preventDefaultTouchmoveEvent: true,
            onSwipedLeft: goLeft,
            onSwipedRight: goRight
          },
          _react2.default.createElement(
            SlidesContainer,
            null,
            _react2.default.createElement(_CarouselSlide2.default, {
              slide: slides[slideIndex],
              imageCarousel: imageCarousel,
              fullscreen: fullscreen,
              alternativeText: vehicleInfo.name
            })
          ),
          renderNavigationProp({
            modalOverlayRef: modalOverlayRef,
            goToNext: goRight,
            goToPrev: goLeft,
            arrowRight: arrowRight,
            arrowLeft: arrowLeft,
            arrowStyles: imageCarousel && imageCarousel.mainNavigationArrows || null,
            leftAlternativeText: translations.leftArrowAlternativeText,
            rightAlternativeText: translations.rightArrowAlternativeText
          }),
          renderFooter && !isMediaVisible && renderFooter({
            showMedia: this.showMedia
          })
        )
      );
    }
  }]);
  return Carousel;
}(_react.Component);

Object.defineProperty(Carousel, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    renderNavigation: _renderNavigation2.default,
    isLooping: true,
    delay: 3000,
    imageWidth: 786,
    imageHeight: 650,
    transitionTime: 500,
    slides: [],
    rotate: false
  }
});
exports.default = Carousel;