'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 16px;\n    margin: 30px;\n  '], ['\n    font-size: 16px;\n    margin: 30px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 22px;\n  '], ['\n    font-size: 22px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n  flex-direction: column;\n  '], ['\n  flex-direction: column;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 16px;\n  '], ['\n    font-size: 16px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    border-top: none;\n    border-bottom: none;\n    font-size: 12px;\n    display: grid;\n    grid-template-columns: auto 1fr;\n    padding: 24px;\n    width: 100%;\n  '], ['\n    border-top: none;\n    border-bottom: none;\n    font-size: 12px;\n    display: grid;\n    grid-template-columns: auto 1fr;\n    padding: 24px;\n    width: 100%;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    :nth-child(odd) {\n      padding: 10px 20px 5px 0;\n    }\n  '], ['\n    :nth-child(odd) {\n      padding: 10px 20px 5px 0;\n    }\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 10px;\n    height: 100%;\n\n    :last-child {\n      border-bottom: none;\n    }\n  '], ['\n    padding: 10px;\n    height: 100%;\n\n    :last-child {\n      border-bottom: none;\n    }\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 40px\n    font-size: 14px;\n    height: 150px;\n    margin-bottom: 60px;\n    align-items: center;\n  '], ['\n    margin-top: 40px\n    font-size: 14px;\n    height: 150px;\n    margin-bottom: 60px;\n    align-items: center;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    height: 40px;\n    margin-bottom: 30px;\n  '], ['\n    height: 40px;\n    margin-bottom: 30px;\n  ']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: right;\n  '], ['\n    justify-content: right;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _parseAstonMartinRetailerName = require('../../helpers/parseAstonMartinRetailerName');

var _parseAstonMartinRetailerName2 = _interopRequireDefault(_parseAstonMartinRetailerName);

var _numbers = require('../../shared/localisation/numbers');

var _Global = require('../Global');

var _AstonLink = require('./AstonLink');

var _AstonLink2 = _interopRequireDefault(_AstonLink);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _dates = require('../../shared/localisation/dates');

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

var _ShortlistButton = require('./ShortlistButton');

var _ShortlistButton2 = _interopRequireDefault(_ShortlistButton);

var _CompareButton = require('./CompareButton');

var _CompareButton2 = _interopRequireDefault(_CompareButton);

var _CarfaxLogo = require('./CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DesktopOnly = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__DesktopOnly',
  componentId: 'sc-l1msel-0'
})(['display:flex;flex-direction:column;align-items:center;margin:40px 80px;', ';'], _theme2.default.max.large(_templateObject));
var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__MobileOnly',
  componentId: 'sc-l1msel-1'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject2));
var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__Container',
  componentId: 'sc-l1msel-2'
})(['display:flex;flex-direction:column;flex-direction:row;max-width:1336px;width:100%;']);

var LocationLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__LocationLabel',
  componentId: 'sc-l1msel-3'
})(['font-size:16px;font-family:AstonMartinFlare-Regular;display:flex;flex-direction:row;align-items:center;line-height:0px;margin:20px 0px;cursor:pointer;', ';'], _theme2.default.max.large(_templateObject3));

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__HeaderContainer',
  componentId: 'sc-l1msel-4'
})(['display:flex;flex-direction:row;justify-content:space-between;width:98%;']);
var PriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__PriceContainer',
  componentId: 'sc-l1msel-5'
})(['display:flex;flex-direction:column;']);
var PrimaryPrice = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__PrimaryPrice',
  componentId: 'sc-l1msel-6'
})(['font-size:28px;font-family:AstonMartinFlare-XBold;display:flex;flex-direction:column;align-items:center;', ';'], _theme2.default.max.large(_templateObject4));
var SecondaryPrice = (0, _styledComponents2.default)(PrimaryPrice).withConfig({
  displayName: 'VehicleDetails__SecondaryPrice',
  componentId: 'sc-l1msel-7'
})(['font-size:18px;', ';', ';'], _theme2.default.max.large(_templateObject5), _theme2.default.max.large(_templateObject6));
var SubPriceLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SubPriceLabel',
  componentId: 'sc-l1msel-8'
})(['font-size:10.5px;font-family:AstonMartinSans-Regular;color:#7a7a7a;padding:5px;']);
var MainInfoBar = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__MainInfoBar',
  componentId: 'sc-l1msel-9'
})(['display:flex;justify-content:space-between;align-items:center;']);
var InfoTableWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__InfoTableWrapper',
  componentId: 'sc-l1msel-10'
})(['background-color:#f4f4f2;display:block;width:100%;']);
var InfoTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__InfoTable',
  componentId: 'sc-l1msel-11'
})(['box-sizing:border-box;display:flex;flex-direction:row;border-top:0.5px solid #707070;border-bottom:0.5px solid #707070;width:98%;font-family:AstonMartinSans-Light;font-size:14px;line-height:18px;height:100%;', ';'], _theme2.default.max.large(_templateObject7));

var VinSubTitle = _styledComponents2.default.h4.withConfig({
  displayName: 'VehicleDetails__VinSubTitle',
  componentId: 'sc-l1msel-12'
})(['font-family:AstonMartinSans-Light;font-weight:normal;font-size:11px;padding:0;margin:0;']);

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__Column',
  componentId: 'sc-l1msel-13'
})(['display:flex;flex-direction:column;width:', ';', ')}'], function (_ref) {
  var width = _ref.width;
  return width || '33.33%';
}, function (_ref2) {
  var maxWidth = _ref2.maxWidth;
  return maxWidth && 'max-width: ' + maxWidth + ';';
});
var InfoTableDoubleRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__InfoTableDoubleRow',
  componentId: 'sc-l1msel-14'
})(['border-bottom:0.5px solid #707070;height:100%;padding:10px;display:flex;justify-content:center;flex-direction:column;margin-right:10px;:last-child{border-bottom:none;}']);
var InfoTableSingleRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__InfoTableSingleRow',
  componentId: 'sc-l1msel-15'
})(['border-bottom:0.5px solid #707070;padding:10px 0;', ';', ';'], _theme2.default.max.large(_templateObject8), _theme2.default.min.large(_templateObject9));

var TableAndLinkContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__TableAndLinkContainer',
  componentId: 'sc-l1msel-16'
})(['display:flex;flex-direction:row;padding-top:50px;justify-content:space-between;align-items:center;']);

var LinkContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__LinkContainer',
  componentId: 'sc-l1msel-17'
})(['display:flex;flex-direction:column;font-family:AstonMartinFlare-Medium;background-color:white;align-items:center;text-align:center;padding:0 4px;min-width:350px;padding-top:10px;', ' ', ';'], function (_ref3) {
  var isTimeless = _ref3.isTimeless;
  return isTimeless && 'height: 250px;';
}, _theme2.default.max.large(_templateObject10));
var AstonLinkContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__AstonLinkContainer',
  componentId: 'sc-l1msel-18'
})(['display:flex;flex-direction:column;justify-content:center;height:100%;margin-top:20px;']);
var MobileCol = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__MobileCol',
  componentId: 'sc-l1msel-19'
})(['background-color:#fff;display:flex;flex-direction:column;justify-content:space-between;align-items:center;justify-content:center;']);
var BoldTableText = _styledComponents2.default.span.withConfig({
  displayName: 'VehicleDetails__BoldTableText',
  componentId: 'sc-l1msel-20'
})(['font-family:AstonMartinSans-Medium;']);
var SubPriceLabelContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SubPriceLabelContainer',
  componentId: 'sc-l1msel-21'
})(['margin-top:20px;margin-bottom:20px;']);

var MobileHeadingContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__MobileHeadingContainer',
  componentId: 'sc-l1msel-22'
})(['display:flex;justify-content:space-between;align-self:baseline;background-color:#f4f4f2;padding:40px 24px;width:100%;gap:10px;box-sizing:border-box;']);
var AddressLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__AddressLabel',
  componentId: 'sc-l1msel-23'
})(['font-size:14px;line-height:22px;font-family:AstonMartinSans-Light;']);
var TimelessLogo = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleDetails__TimelessLogo',
  componentId: 'sc-l1msel-24'
})(['height:25px;', ';'], _theme2.default.min.large(_templateObject11));
var PriceContainerMobile = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__PriceContainerMobile',
  componentId: 'sc-l1msel-25'
})(['align-self:baseline;box-sizing:border-box;width:100%;display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin-bottom:20px;padding:32px 24px 0;']);
var SecondaryPriceContainerMobile = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SecondaryPriceContainerMobile',
  componentId: 'sc-l1msel-26'
})(['align-self:baseline;box-sizing:border-box;width:100%;display:flex;flex-direction:row;align-items:center;padding:24px;justify-content:space-between;']);

var CompareShortlistContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__CompareShortlistContainer',
  componentId: 'sc-l1msel-27'
})(['display:flex;gap:10px;flex-direction:flex-end;']);

var CarfaxLogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__CarfaxLogoContainer',
  componentId: 'sc-l1msel-28'
})(['display:flex;align-items:center;margin-right:20px;']);

var CarfaxLogoContainerMobile = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__CarfaxLogoContainerMobile',
  componentId: 'sc-l1msel-29'
})(['margin-bottom:20px;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleDetails__Image',
  componentId: 'sc-l1msel-30'
})(['max-width:140px;height:auto;']);

var ImageWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__ImageWrapper',
  componentId: 'sc-l1msel-31'
})(['display:flex;align-items:center;justify-content:center;flex-grow:1;z-index:9;cursor:pointer;', ';'], _theme2.default.max.large(_templateObject12));

var Pricing = function Pricing(_ref4) {
  var localisedRetailPrice = _ref4.localisedRetailPrice,
      translations = _ref4.translations,
      priceSubjectToVat = _ref4.priceSubjectToVat,
      priceIncludingVat = _ref4.priceIncludingVat,
      retailPricePlusTax = _ref4.retailPricePlusTax,
      priceLessTaxes = _ref4.priceLessTaxes,
      disclaimer = _ref4.disclaimer;
  return _react2.default.createElement(
    PriceContainer,
    null,
    localisedRetailPrice && _react2.default.createElement(
      PrimaryPrice,
      null,
      localisedRetailPrice,
      _react2.default.createElement(
        SubPriceLabel,
        null,
        translations.retailPrice
      ),
      disclaimer && _react2.default.createElement(
        SubPriceLabel,
        null,
        disclaimer
      )
    ),
    _react2.default.createElement(
      SubPriceLabelContainer,
      null,
      priceSubjectToVat && _react2.default.createElement(
        SecondaryPrice,
        null,
        priceSubjectToVat,
        _react2.default.createElement(
          SubPriceLabel,
          null,
          translations.subjectToVat
        )
      ),
      priceIncludingVat && _react2.default.createElement(
        SecondaryPrice,
        null,
        priceIncludingVat,
        _react2.default.createElement(
          SubPriceLabel,
          null,
          translations.includingVat
        )
      ),
      retailPricePlusTax && _react2.default.createElement(
        SecondaryPrice,
        null,
        retailPricePlusTax,
        _react2.default.createElement(
          SubPriceLabel,
          null,
          translations.retailPricePlusTax
        )
      ),
      priceLessTaxes && _react2.default.createElement(
        SecondaryPrice,
        null,
        priceLessTaxes,
        _react2.default.createElement(
          SubPriceLabel,
          null,
          translations.priceLessTaxes
        )
      )
    )
  );
};

exports.default = function (_ref5) {
  var translations = _ref5.translations,
      vehicle = _ref5.vehicle,
      config = _ref5.config,
      locale = _ref5.locale,
      printVehiclePdf = _ref5.printVehiclePdf,
      printingState = _ref5.printingState,
      globalStyling = _ref5.globalStyling,
      makeAnEnquiry = _ref5.makeAnEnquiry,
      bookATestDrive = _ref5.bookATestDrive,
      viewRetailerStock = _ref5.viewRetailerStock;

  var localisedRetailPrice = !!vehicle.price.value && (0, _numbers.localiseCurrency)(vehicle.price.originalValue || vehicle.price.value, locale, vehicle.price.originalCurrency || vehicle.price.currency, 0);
  var priceSubjectToVat = !!vehicle.priceExcludesVat && (0, _numbers.localiseCurrency)(vehicle.priceExcludesVat, locale, vehicle.price.currency, 0);
  var priceIncludingVat = !!vehicle.priceSpecial && (0, _numbers.localiseCurrency)(vehicle.priceSpecial, locale, vehicle.price.currency, 0);
  var retailPricePlusTax = !!vehicle.retailPricePlusTax && (0, _numbers.localiseCurrency)(vehicle.retailPricePlusTax, locale, vehicle.price.currency, 0);
  var priceLessTaxes = !!vehicle.priceLessTaxes && (0, _numbers.localiseCurrency)(vehicle.priceLessTaxes, locale, vehicle.price.currency, 0);
  var dashIfNull = function dashIfNull(value) {
    return value || '-';
  };

  var displaySpecItem = function displaySpecItem() {
    return vehicle.seatColour && vehicle.specification.interior && vehicle.seatColour !== vehicle.specification.interior ? [(0, _translateFromTemplate.translateFromTemplate)('interiorColoursSpecification', {
      INTERIOR: vehicle.seatColour,
      INTERIOR2: vehicle.specification.interior
    }, translations)] : vehicle.seatColour ? vehicle.seatColour : vehicle.specification.interior ? vehicle.specification.interior : '-';
  };

  var isTimeless = vehicle.approved;

  var goToAnchor = function goToAnchor(ele) {
    return document.getElementById(ele).scrollIntoView(true);
  };

  var registrationDate = vehicle.registrationDate ? (0, _dates.localeFormattedDate)(vehicle.registrationDate, locale, {
    month: '2-digit',
    year: 'numeric'
  }).split('/').join('.') : '';

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      DesktopOnly,
      null,
      _react2.default.createElement(
        Container,
        null,
        _react2.default.createElement(
          Column,
          { width: '100%', maxWidth: '1080px' },
          _react2.default.createElement(
            MainInfoBar,
            null,
            _react2.default.createElement(
              HeaderContainer,
              null,
              _react2.default.createElement(
                'div',
                { style: { flexGrow: 1 } },
                _react2.default.createElement(
                  _Global.HeadingOne,
                  {
                    styleOverride: function styleOverride() {
                      return '\n                      font-family: AstonMartinFlare-Regular;\n                      font-size: 44px;\n                      margin-bottom: 0;\n                      margin-top: 0;\n                      color: #000000\n                  ';
                    }
                  },
                  vehicle.name
                ),
                _react2.default.createElement(
                  VinSubTitle,
                  null,
                  translations.vinLabel,
                  ': ',
                  vehicle.vin
                )
              ),
              vehicle.carfax && _react2.default.createElement(
                CarfaxLogoContainer,
                null,
                _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicle.carfax })
              ),
              _react2.default.createElement(
                CompareShortlistContainer,
                null,
                _react2.default.createElement(_ShortlistButton2.default, {
                  iconOff: config.iconShortlistOff,
                  iconOn: config.iconShortlistOn,
                  vehicle: vehicle
                }),
                _react2.default.createElement(_CompareButton2.default, {
                  iconOff: config.iconCompareOff,
                  iconOn: config.iconCompareOn,
                  vehicle: vehicle
                })
              )
            )
          ),
          _react2.default.createElement(
            TableAndLinkContainer,
            null,
            _react2.default.createElement(
              InfoTableWrapper,
              null,
              _react2.default.createElement(
                InfoTable,
                null,
                _react2.default.createElement(
                  Column,
                  { width: '30%' },
                  _react2.default.createElement(
                    InfoTableDoubleRow,
                    null,
                    translations.exteriorColour,
                    _react2.default.createElement('br', null),
                    _react2.default.createElement(
                      BoldTableText,
                      null,
                      dashIfNull(vehicle.specification.exterior)
                    )
                  ),
                  _react2.default.createElement(
                    InfoTableDoubleRow,
                    null,
                    translations.interiorColour,
                    _react2.default.createElement('br', null),
                    _react2.default.createElement(
                      BoldTableText,
                      null,
                      displaySpecItem()
                    )
                  ),
                  _react2.default.createElement(
                    InfoTableDoubleRow,
                    null,
                    translations.carpetColour,
                    _react2.default.createElement('br', null),
                    _react2.default.createElement(
                      BoldTableText,
                      null,
                      dashIfNull(vehicle.carpterColour)
                    )
                  )
                ),
                _react2.default.createElement(
                  Column,
                  { width: '30%' },
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    translations.firstRegistration
                  ),
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    translations.mileage
                  ),
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    translations.topSpeed
                  ),
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    translations.modelYear
                  ),
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    translations.maximumPower
                  ),
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    translations.transmission
                  )
                ),
                _react2.default.createElement(
                  Column,
                  { width: '40%' },
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    dashIfNull(registrationDate)
                  ),
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    dashIfNull(vehicle.specification.odometer && vehicle.specification.odometer.reading + ' ' + vehicle.specification.odometer.units)
                  ),
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    dashIfNull(vehicle.techspecs.displayMaxSpeed)
                  ),
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    dashIfNull(vehicle.modelYear)
                  ),
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    dashIfNull(vehicle.techspecs.displayPower)
                  ),
                  _react2.default.createElement(
                    InfoTableSingleRow,
                    null,
                    dashIfNull(vehicle.transmissionType)
                  )
                )
              )
            )
          )
        ),
        _react2.default.createElement(
          LinkContainer,
          null,
          _react2.default.createElement(Pricing, {
            localisedRetailPrice: localisedRetailPrice,
            translations: translations,
            priceSubjectToVat: priceSubjectToVat,
            priceIncludingVat: priceIncludingVat,
            retailPricePlusTax: retailPricePlusTax,
            priceLessTaxes: priceLessTaxes,
            disclaimer: vehicle.sectionSalesTaxDisclaimer === 1 ? translations.differentialTaxation : null
          }),
          isTimeless && _react2.default.createElement(TimelessLogo, { src: config.timelessLogo, alt: 'timeless-logo' }),
          _react2.default.createElement(
            ImageWrapper,
            {
              onClick: function onClick() {
                goToAnchor('retailerInfo');
              }
            },
            _react2.default.createElement(Image, { src: config.astonLogo, alt: 'brand logo' })
          ),
          _react2.default.createElement(
            LocationLabel,
            {
              onClick: function onClick() {
                goToAnchor('retailerInfo');
              }
            },
            (0, _parseAstonMartinRetailerName2.default)(vehicle.retailerInformation.name)
          ),
          _react2.default.createElement(
            AddressLabel,
            null,
            vehicle.retailerInformation.address.join(', ')
          ),
          _react2.default.createElement(
            AstonLinkContainer,
            null,
            _react2.default.createElement(_AstonLink2.default, {
              text: translations.enquire,
              onClick: makeAnEnquiry,
              colour: '#000000',
              fontFamily: 'AstonMartinFlare-Regular',
              lineHeight: '40px',
              justifyContent: 'center'
            }),
            _react2.default.createElement(_AstonLink2.default, {
              text: translations.testdrive,
              onClick: bookATestDrive,
              colour: '#000000',
              fontFamily: 'AstonMartinFlare-Regular',
              lineHeight: '40px',
              justifyContent: 'center'
            }),
            _react2.default.createElement(_AstonLink2.default, {
              text: translations.viewDealerStock,
              onClick: function onClick() {
                return viewRetailerStock(vehicle.retailerInformation);
              },
              colour: '#000000',
              fontFamily: 'AstonMartinFlare-Regular',
              lineHeight: '40px',
              justifyContent: 'center'
            }),
            _react2.default.createElement(_AstonLink2.default, {
              text: translations.print,
              onClick: function onClick(e) {
                e.preventDefault();
                printVehiclePdf();
              },
              colour: '#000000',
              fontFamily: 'AstonMartinFlare-Regular',
              lineHeight: '40px',
              justifyContent: 'center',
              busy: printingState.isPrinting,
              spinnerColour: globalStyling.colours.primaryBrandColour
            })
          )
        )
      )
    ),
    _react2.default.createElement(
      MobileOnly,
      null,
      _react2.default.createElement(
        MobileCol,
        null,
        _react2.default.createElement(
          MobileHeadingContainer,
          null,
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              _Global.HeadingOne,
              {
                styleOverride: function styleOverride() {
                  return '\n                  font-family: AstonMartinFlare-Regular;\n                  font-size: 18px;\n                  margin-bottom: 0;\n                  margin-top: 10px;\n                  text-transform: uppercase;\n                  color: #000000;\n                ';
                }
              },
              vehicle.name
            ),
            _react2.default.createElement(
              VinSubTitle,
              null,
              translations.vinLabel,
              ': ',
              vehicle.vin
            )
          ),
          _react2.default.createElement(_ShortlistButton2.default, {
            iconOff: config.iconShortlistOff,
            iconOn: config.iconShortlistOn,
            vehicle: vehicle
          })
        ),
        _react2.default.createElement(
          PriceContainerMobile,
          null,
          localisedRetailPrice && _react2.default.createElement(
            PrimaryPrice,
            { mobile: true },
            localisedRetailPrice,
            _react2.default.createElement(
              SubPriceLabel,
              null,
              translations.retailPrice
            ),
            vehicle.sectionSalesTaxDisclaimer === 1 && _react2.default.createElement(
              SubPriceLabel,
              null,
              translations.differentialTaxation
            )
          ),
          _react2.default.createElement(TimelessLogo, { src: config.timelessLogo, alt: 'timeless-logo' })
        ),
        _react2.default.createElement(
          SecondaryPriceContainerMobile,
          null,
          priceSubjectToVat && _react2.default.createElement(
            SecondaryPrice,
            { mobile: true },
            priceSubjectToVat,
            _react2.default.createElement(
              SubPriceLabel,
              null,
              translations.subjectToVat
            )
          ),
          priceIncludingVat && _react2.default.createElement(
            SecondaryPrice,
            { mobile: true },
            priceIncludingVat,
            _react2.default.createElement(
              SubPriceLabel,
              null,
              translations.includingVat
            )
          ),
          retailPricePlusTax && _react2.default.createElement(
            SecondaryPrice,
            { mobile: true },
            retailPricePlusTax,
            _react2.default.createElement(
              SubPriceLabel,
              null,
              translations.retailPricePlusTax
            )
          ),
          priceLessTaxes && _react2.default.createElement(
            SecondaryPrice,
            { mobile: true },
            priceLessTaxes,
            _react2.default.createElement(
              SubPriceLabel,
              null,
              translations.priceLessTaxes
            )
          )
        ),
        vehicle.carfax && _react2.default.createElement(
          CarfaxLogoContainerMobile,
          null,
          _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicle.carfax })
        ),
        _react2.default.createElement(TimelessLogo, {
          src: config.astonLogo,
          alt: 'Aston Martin',
          onClick: function onClick() {
            goToAnchor('retailerInfo');
          }
        }),
        _react2.default.createElement(
          LocationLabel,
          {
            mobile: true,
            onClick: function onClick() {
              goToAnchor('retailerInfo');
            }
          },
          (0, _parseAstonMartinRetailerName2.default)(vehicle.retailerInformation.name)
        ),
        vehicle.retailerInformation.address.map(function (address, index) {
          return _react2.default.createElement(
            AddressLabel,
            { key: index + '-' + address },
            address
          );
        }),
        _react2.default.createElement(
          LinkContainer,
          { mobile: true },
          _react2.default.createElement(_AstonLink2.default, {
            text: translations.enquire,
            onClick: makeAnEnquiry,
            colour: '#000000',
            fontFamily: 'AstonMartinFlare-Medium',
            lineHeight: '40px'
          }),
          _react2.default.createElement(_AstonLink2.default, {
            text: translations.testdrive,
            onClick: bookATestDrive,
            colour: '#000000',
            fontFamily: 'AstonMartinFlare-Medium',
            lineHeight: '40px'
          }),
          _react2.default.createElement(_AstonLink2.default, {
            text: translations.viewDealerStock,
            onClick: function onClick() {
              return viewRetailerStock(vehicle.retailerInformation);
            },
            colour: '#000000',
            fontFamily: 'AstonMartinFlare-Medium',
            lineHeight: '40px'
          }),
          _react2.default.createElement(_AstonLink2.default, {
            text: translations.print,
            onClick: function onClick(e) {
              e.preventDefault();
              printVehiclePdf();
            },
            colour: '#000000',
            fontFamily: 'AstonMartinFlare-Medium',
            lineHeight: '40px',
            busy: printingState.isPrinting,
            busyText: translations.loadingLabel,
            spinnerColour: globalStyling.colours.primaryBrandColour
          })
        ),
        _react2.default.createElement(
          InfoTableWrapper,
          null,
          _react2.default.createElement(
            InfoTable,
            { mobile: true },
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              translations.exteriorColour
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              dashIfNull(vehicle.specification.exterior)
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              translations.interiorColour
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              displaySpecItem()
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              translations.carpetColour
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              dashIfNull(vehicle.carpterColour)
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              translations.firstRegistration
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              dashIfNull(registrationDate)
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              translations.mileage
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              dashIfNull(vehicle.specification.odometer && vehicle.specification.odometer.reading + ' ' + vehicle.specification.odometer.units)
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              translations.topSpeed
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              dashIfNull(vehicle.techspecs.displayMaxSpeed)
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              translations.modelYear
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              dashIfNull(vehicle.registration)
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              translations.maximumPower
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              dashIfNull(vehicle.techspecs.displayPower)
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              translations.transmission
            ),
            _react2.default.createElement(
              InfoTableSingleRow,
              null,
              dashIfNull(vehicle.transmissionType)
            )
          )
        )
      )
    )
  );
};