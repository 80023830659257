'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  printVehicleInfo: 'PRINT_VEHICLE_INFO',
  printEmissionInfo: 'PRINT_EMISSION_INFO',
  printingSuccessful: 'PRINTING_SUCCESSFUL',
  printingFailure: 'PRINTING_FAILURE'
};

var actions = exports.actions = {
  printVehicleInfo: function printVehicleInfo(vehicle, translations, config) {
    return {
      type: constants.printVehicleInfo,
      payload: {
        vehicle: vehicle,
        translations: translations,
        config: config
      }
    };
  },
  printEmissionInfo: function printEmissionInfo(vehicle) {
    return {
      type: constants.printEmissionInfo,
      payload: vehicle
    };
  },
  printingSuccessful: function printingSuccessful() {
    return { type: constants.printingSuccessful };
  },
  printingFailure: function printingFailure() {
    return { type: constants.printingFailure };
  }
};

var initialState = {
  isPrinting: false,
  error: false
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var type = action.type;

  switch (type) {
    case constants.printVehicleInfo:
      return (0, _extends3.default)({}, state, {
        isPrinting: true,
        error: false
      });
    case constants.printEmissionInfo:
      return (0, _extends3.default)({}, state, {
        isPrinting: true,
        error: false
      });
    case constants.printingSuccessful:
      return (0, _extends3.default)({}, state, {
        isPrinting: false,
        error: false
      });
    case constants.printingFailure:
      return {
        isPrinting: false,
        error: true
      };
    default:
      return state;
  }
}