'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'ModSiteSearch';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['SearchFor', 'SearchForSuccess']);

var id = 'SiteSearch';

var actions = exports.actions = {
  searchFor: (0, _reducerMacros.moduleActionWithArgs)(constants.SearchFor, id, function (term) {
    return { term: term };
  }),
  searchForSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.SearchForSuccess, id, function (results) {
    return { results: results };
  })
};

var initialState = exports.initialState = {
  searchResults: []
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.SearchForSuccess:
      return (0, _extends3.default)({}, state, {
        searchResults: action.payload.results
      });
    default:
      return state;
  }
}