'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SocialMediaShare;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactShare = require('react-share');

var _Facebook = require('../JaguarIcons/Global/Facebook');

var _Facebook2 = _interopRequireDefault(_Facebook);

var _Twitter = require('../JaguarIcons/Global/Twitter');

var _Twitter2 = _interopRequireDefault(_Twitter);

var _Whatsapp = require('../JaguarIcons/Global/Whatsapp');

var _Whatsapp2 = _interopRequireDefault(_Whatsapp);

var _Email = require('../JaguarIcons/Global/Email');

var _Email2 = _interopRequireDefault(_Email);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SocialMediaShareContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SocialMediaShare__SocialMediaShareContainer',
  componentId: 'sc-1y584hq-0'
})(['display:flex;justify-content:center;align-items:center;height:', ';'], function (_ref) {
  var height = _ref.height;
  return height !== 0 ? height + 'px;' : 'auto;';
});


var ShareButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SocialMediaShare__ShareButtonWrapper',
  componentId: 'sc-1y584hq-1'
})(['cursor:pointer;padding:0 8px;', ';'], function (_ref2) {
  var enabled = _ref2.enabled;
  return !enabled && 'display: none';
});

function SocialMediaShare(_ref3) {
  var shareUrl = _ref3.shareUrl,
      socialShare = _ref3.socialShare,
      iconColour = _ref3.iconColour,
      height = _ref3.height;

  return _react2.default.createElement(
    SocialMediaShareContainer,
    { height: height },
    _react2.default.createElement(
      ShareButtonWrapper,
      {
        'data-cy': 'socialShareFacebook',
        enabled: socialShare.facebook
      },
      _react2.default.createElement(
        _reactShare.FacebookShareButton,
        { url: shareUrl },
        _react2.default.createElement(_Facebook2.default, { width: '32px', height: '32px', colour: iconColour })
      )
    ),
    _react2.default.createElement(
      ShareButtonWrapper,
      {
        'data-cy': 'socialShareTwitter',
        enabled: socialShare.twitter
      },
      _react2.default.createElement(
        _reactShare.TwitterShareButton,
        { url: shareUrl },
        _react2.default.createElement(_Twitter2.default, { width: '32px', height: '32px', colour: iconColour })
      )
    ),
    _react2.default.createElement(
      ShareButtonWrapper,
      {
        'data-cy': 'socialShareWhatsApp',
        enabled: socialShare.whatsApp
      },
      _react2.default.createElement(
        _reactShare.WhatsappShareButton,
        { url: shareUrl },
        _react2.default.createElement(_Whatsapp2.default, { width: '32px', height: '32px', colour: iconColour })
      )
    ),
    _react2.default.createElement(
      ShareButtonWrapper,
      {
        'data-cy': 'socialShareEmail',
        enabled: socialShare.email
      },
      _react2.default.createElement(
        _reactShare.EmailShareButton,
        { url: shareUrl },
        _react2.default.createElement(_Email2.default, { width: '32px', height: '32px', colour: iconColour })
      )
    )
  );
}

SocialMediaShare.defaultProps = {
  iconColour: '#000000',
  height: 125
};