'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    border-bottom: 1px solid #333333;\n  '], ['\n    border-bottom: 1px solid #333333;\n  ']);

exports.default = MobileFilters;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../../Global');

var _Criteria = require('../../../JaguarIcons/Global/Criteria');

var _Criteria2 = _interopRequireDefault(_Criteria);

var _translateFromTemplate = require('../../../../shared/localisation/translateFromTemplate');

var _AppShared = require('../../../../types/AppShared');

var _AppState = require('../../../../types/AppState');

var _reactRedux = require('react-redux');

var _searchResultsTypes = require('../../../../types/Bentley/searchResultsTypes');

var _settingsTyped = require('../../../../shared/selectors/settingsTyped');

var _SelectBar = require('../../SelectBar');

var _SelectBar2 = _interopRequireDefault(_SelectBar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__Container',
  componentId: 'sc-w4rj3y-0'
})(['display:flex;width:100%;']);

var MobileFiltersOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__MobileFiltersOverlay',
  componentId: 'sc-w4rj3y-1'
})(['display:block;width:100%;position:fixed;top:0;left:0;bottom:0;z-index:100;background:white;overflow-y:auto;']);

var SearchMenuContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__SearchMenuContainer',
  componentId: 'sc-w4rj3y-2'
})(['display:flex;height:40px;width:100%;justify-content:space-between;align-items:center;', ''], _theme2.default.max.medium(_templateObject));

var IconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__IconContainer',
  componentId: 'sc-w4rj3y-3'
})(['display:flex;align-items:center;padding:6px 0 0 12px;']);

var MenuLabel = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__MenuLabel',
  componentId: 'sc-w4rj3y-4'
})(['display:flex;color:#fff;flex:1 0 0%;padding:0 8px;font-size:12px;']);

var FilterHeader = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__FilterHeader',
  componentId: 'sc-w4rj3y-5'
})(['display:flex;justify-content:flex-end;border-bottom:solid 0.5px #d5d5d5;']);

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'MobileFilters__IconWrapper',
  componentId: 'sc-w4rj3y-6'
})(['padding:20px 20px 20px 40px;width:12px;height:12px;']);

var MenuContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__MenuContainer',
  componentId: 'sc-w4rj3y-7'
})(['display:flex;flex-direction:column;width:100%;']);

var Menu = function Menu(_ref) {
  var chevronColour = _ref.chevronColour,
      toggle = _ref.toggle,
      translations = _ref.translations,
      shared = _ref.shared,
      searchSort = _ref.searchSort,
      currencies = _ref.currencies,
      currencyValue = _ref.currencyValue,
      hideSelectBar = _ref.hideSelectBar;
  return _react2.default.createElement(
    MenuContainer,
    null,
    _react2.default.createElement(
      SearchMenuContainer,
      { onClick: toggle },
      _react2.default.createElement(
        IconContainer,
        null,
        _react2.default.createElement(_Criteria2.default, { colour: chevronColour })
      ),
      _react2.default.createElement(
        MenuLabel,
        null,
        _react2.default.createElement(
          _Global.HeadingThree,
          { styleOverride: function styleOverride() {
              return 'font-family: Bentley-Regular';
            } },
          shared.filters.activeFiltersCount ? (0, _translateFromTemplate.translateFromTemplate)('mobileSearchMenuLabel', {
            COUNT: shared.filters.activeFiltersCount
          }, translations) : translations.mobileSearchMenuNoCountLabel
        )
      )
    ),
    !hideSelectBar && _react2.default.createElement(
      SearchMenuContainer,
      null,
      searchSort && _react2.default.createElement(_SelectBar2.default, {
        searchSort: searchSort,
        translations: translations,
        minimal: true,
        currencies: currencies,
        currencyValue: currencyValue,
        fullWidth: true
      })
    )
  );
};

var FilterOverlay = function FilterOverlay(_ref2) {
  var toggle = _ref2.toggle,
      filters = _ref2.filters,
      filtersCloseIcon = _ref2.filtersCloseIcon;

  return _react2.default.createElement(
    MobileFiltersOverlay,
    null,
    _react2.default.createElement(
      FilterHeader,
      null,
      _react2.default.createElement(IconWrapper, { src: filtersCloseIcon, alt: '', onClick: toggle })
    ),
    filters
  );
};

function MobileFilters(props) {
  var filterProps = props.filters.props;
  var shared = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  });

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      colours = _useSelector.colours;

  var translations = props.translations,
      filters = props.filters,
      filtersCloseIcon = props.filtersCloseIcon;

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(Menu, {
      toggle: filterProps.toggle,
      chevronColour: colours.primaryBrandColour.value,
      shared: shared,
      displayMobileFilterOptions: filterProps.displayMobileFilterOptions,
      translations: translations,
      searchSort: props.searchSort,
      currencyValue: shared.sessionPreferences.currency,
      currencies: props.currencies,
      hideSelectBar: props.hideSelectBar
    }),
    filterProps.displayMobileFilterOptions && _react2.default.createElement(FilterOverlay, {
      toggle: filterProps.toggle,
      filters: filters,
      filtersCloseIcon: filtersCloseIcon
    })
  );
}