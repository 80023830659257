'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: ', ';\n    flex-direction: row;\n  '], ['\n    padding: ', ';\n    flex-direction: row;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none\n  '], ['\n    display: none\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n    max-width: 350px;\n    margin-right: 16px;\n  '], ['\n    display: flex;\n    flex-direction: column;\n    max-width: 350px;\n    margin-right: 16px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n            width: 100%;\n            min-width: 0;\n            margin: 0;\n            ', '\n        '], ['\n            width: 100%;\n            min-width: 0;\n            margin: 0;\n            ', '\n        ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n              width: 100%;\n              min-width: 0;\n              margin: 0;\n            '], ['\n              width: 100%;\n              min-width: 0;\n              margin: 0;\n            ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../components/Global');

var _FooterActionBanner = require('../../components/FooterActionBanner');

var _FooterActionBanner2 = _interopRequireDefault(_FooterActionBanner);

var _financeCalculator = require('../../shared/financeCalculator');

var _filters = require('../../shared/filters');

var _ModelMatrixGrid = require('../../components/ModelMatrixGrid');

var _ModelMatrixGrid2 = _interopRequireDefault(_ModelMatrixGrid);

var _FiltersContainer = require('../../linked-modules/Filters/FiltersContainer');

var _FiltersContainer2 = _interopRequireDefault(_FiltersContainer);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _MobileFilters = require('../../components/MobileFilters');

var _MobileFilters2 = _interopRequireDefault(_MobileFilters);

var _FiltersStickyLocationSearchBar = require('../../components/FiltersStickyLocationSearchBar');

var _FiltersStickyLocationSearchBar2 = _interopRequireDefault(_FiltersStickyLocationSearchBar);

var _reducer = require('./reducer');

var _filters2 = require('../../helpers/filters');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ModelMatrixSearch__Container',
  componentId: 'sc-kaa2uo-0'
})(['padding:0;display:flex;flex-direction:column;', ';'], _theme2.default.min.large(_templateObject, function (_ref) {
  var padding = _ref.padding;
  return padding ? '' + padding : '16px';
}));

var MobileContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelMatrixSearch__MobileContainer',
  componentId: 'sc-kaa2uo-1'
})(['display:flex;', ';'], _theme2.default.min.large(_templateObject2));

var RightColumn = _styledComponents2.default.div.withConfig({
  displayName: 'ModelMatrixSearch__RightColumn',
  componentId: 'sc-kaa2uo-2'
})(['display:block;width:100%;']);
var LeftColumn = _styledComponents2.default.div.withConfig({
  displayName: 'ModelMatrixSearch__LeftColumn',
  componentId: 'sc-kaa2uo-3'
})(['display:none;', ';'], _theme2.default.min.large(_templateObject3));

var DesktopOnly = _styledComponents2.default.div.withConfig({
  displayName: 'ModelMatrixSearch__DesktopOnly',
  componentId: 'sc-kaa2uo-4'
})(['display:block;', ';'], _theme2.default.max.large(_templateObject4));

var FiltersContainerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelMatrixSearch__FiltersContainerWrapper',
  componentId: 'sc-kaa2uo-5'
})(['width:280px;']);

var SearchButtonTopContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelMatrixSearch__SearchButtonTopContainer',
  componentId: 'sc-kaa2uo-6'
})(['display:flex;justify-content:flex-end;padding:8px 4px;', ';'], _theme2.default.max.small(_templateObject4));

var ModelMatrixSearch = function (_Component) {
  (0, _inherits3.default)(ModelMatrixSearch, _Component);

  function ModelMatrixSearch(props) {
    (0, _classCallCheck3.default)(this, ModelMatrixSearch);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ModelMatrixSearch.__proto__ || (0, _getPrototypeOf2.default)(ModelMatrixSearch)).call(this, props));

    Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        // We call init to force a first run of the reducer
        // - there used to be a more generic solution,
        //   but it broke the builder
        var _this$props = _this.props,
            _this$props$actions = _this$props.actions,
            getModelGroups = _this$props$actions.getModelGroups,
            getInventoryGroup = _this$props$actions.getInventoryGroup,
            history = _this$props.history,
            dispatch = _this$props.dispatch,
            featureFlags = _this$props.featureFlags,
            shared = _this$props.shared;

        var query = (0, _filters2.queryFromHistory)(history, true);

        _this.setState({
          query: query
        });

        dispatch(_filters.actions.initialiseFilters(query));
        getModelGroups();
        getInventoryGroup();
        if (featureFlags.finance && !shared.financeCalculator.representativeExample) {
          dispatch(_financeCalculator.actions.loadRepresentativeExample());
        }
      }
    });
    Object.defineProperty(_this, 'toggle', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _this.setState(function (_ref2) {
          var displayMobileFilterOptions = _ref2.displayMobileFilterOptions;
          return {
            displayMobileFilterOptions: !displayMobileFilterOptions
          };
        });
      }
    });
    Object.defineProperty(_this, 'toggleModelFilter', {
      enumerable: true,
      writable: true,
      value: function value(models) {
        var shared = _this.props.shared;

        var selectedModelFilter = shared.filters.selectedFilters && shared.filters.selectedFilters.model;
        _this.props.dispatch(_filters.actions.updateFilters([{
          key: 'model',
          value: _filters.helpers.newArray(selectedModelFilter || [], models)
        }]));
      }
    });
    Object.defineProperty(_this, 'toggleStickySearchBar', {
      enumerable: true,
      writable: true,
      value: function value(_ref3) {
        var currentPosition = _ref3.currentPosition,
            previousPosition = _ref3.previousPosition;

        if (currentPosition && previousPosition) {
          _this.setState(function () {
            return {
              showStickySearch: currentPosition === 'below'
            };
          });
        }
      }
    });
    Object.defineProperty(_this, 'resetFilters', {
      enumerable: true,
      writable: true,
      value: function value() {
        var dispatch = _this.props.dispatch;

        dispatch(_filters.actions.resetFilters());
        dispatch(_filters.actions.initialiseFilters());
      }
    });
    Object.defineProperty(_this, 'onStickySearchBarReset', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.filtersContainerRef.current.collapseFilters();
      }
    });
    Object.defineProperty(_this, 'updateForMarket', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props2 = _this.props,
            dispatch = _this$props2.dispatch,
            getInventoryGroup = _this$props2.actions.getInventoryGroup;

        dispatch(_filters.actions.initialiseFilters(_this.state.query));
        getInventoryGroup();
      }
    });

    _this.state = {
      showStickySearch: true,
      displayMobileFilterOptions: false
    };
    _this.filtersContainerRef = _react2.default.createRef();
    return _this;
  }

  (0, _createClass3.default)(ModelMatrixSearch, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _props = this.props,
          history = _props.history,
          shared = _props.shared,
          _props$actions = _props.actions,
          getModelGroups = _props$actions.getModelGroups,
          getInventoryGroup = _props$actions.getInventoryGroup,
          convertPrices = _props$actions.convertPrices;

      var query = (0, _filters2.queryFromHistory)(history, true);
      if (shared.sessionPreferences.location !== prevProps.shared.sessionPreferences.location) {
        getInventoryGroup();
      }

      if (this.state.query !== query) {
        this.setState({ query: query });
      }

      if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language) {
        getModelGroups();
      }

      if (prevProps.shared.sessionPreferences.market !== shared.sessionPreferences.market) {
        this.updateForMarket();
      }

      if (prevProps.shared.sessionPreferences.currency !== shared.sessionPreferences.currency || prevProps.shared.currencyConversion.exchangeRates !== shared.currencyConversion.exchangeRates) {
        convertPrices();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          shared = _props2.shared,
          _props2$state = _props2.state,
          inventoryGroup = _props2$state.inventoryGroup,
          modelGroups = _props2$state.modelGroups,
          dispatch = _props2.dispatch,
          config = _props2.config,
          marketInfo = _props2.marketInfo,
          globalStyling = _props2.globalStyling,
          featureFlags = _props2.featureFlags,
          linkedModules = _props2.linkedModules,
          pathByModule = _props2.pathByModule;
      var showStickySearch = this.state.showStickySearch;

      var ResetBtn = function ResetBtn() {
        return _react2.default.createElement(_Global.Button, {
          onClick: _this2.resetFilters,
          text: config.translations.searchCTAReset,
          buttonStyle: config.stylingConfig.resetButtonStyle ? config.stylingConfig.resetButtonStyle.buttonStyle : globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          applyStyle: 'secondary',
          styleOverride: function styleOverride() {
            return _theme2.default.max.large(_templateObject5, config.stylingConfig && config.stylingConfig.resetButtonStyle && (0, _Global.buttonStyleOverride)(config.stylingConfig.resetButtonStyle.font, config.stylingConfig.resetButtonStyle.backgroundColour, config.stylingConfig.resetButtonStyle.hoverBackgroundColour, config.stylingConfig.resetButtonStyle.buttonSize, config.stylingConfig.resetButtonStyle.hoverColour, config.stylingConfig.resetButtonStyle.border));
          }
        });
      };

      var SearchBtn = function SearchBtn(topSearchButton) {
        return _react2.default.createElement(_Global.Button, {
          onClick: function onClick() {
            return dispatch(_filters.actions.searchWithFilters(config.customSearchResultPath));
          },
          text: config.translations.searchCTASearch,
          buttonStyle: config.stylingConfig.submitButtonStyle ? config.stylingConfig.submitButtonStyle.buttonStyle : globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          applyStyle: 'primary',
          styleOverride: function styleOverride() {
            return '\n            ' + _theme2.default.max.large(_templateObject6) + '\n            ' + (topSearchButton && '\n              width: 280px;\n              min-width: 190px;\n              min-height: 45px;\n              text-transform: capitalize;\n            ') + '\n         ';
          }
        });
      };

      var filtersConfig = (0, _values2.default)(linkedModules).find(function (lm) {
        return lm.name === 'Filters';
      });

      var filters = _react2.default.createElement(_FiltersContainer2.default, (0, _extends3.default)({}, filtersConfig, {
        featureFlags: featureFlags,
        globalStyling: globalStyling,
        toggle: this.toggle,
        locale: marketInfo.locale,
        currencyCode: marketInfo.currencyCode,
        displayMobileFilterOptions: this.state.displayMobileFilterOptions,
        ref: this.filtersContainerRef,
        shared: shared,
        dispatch: dispatch,
        searchCountryCode: marketInfo.searchCountryCode
      }));

      var vehicleModels = (modelGroups || []).map(function (modelWithPrice) {
        var matchingModel = (inventoryGroup || []).find(function (m) {
          return (0, _reducer.matchGroup)(modelWithPrice, m);
        }) || { position: 9999 };
        return inventoryGroup && inventoryGroup.length > 0 && (0, _extends3.default)({}, matchingModel, {
          imageUrl: modelWithPrice.imageUrl,
          position: modelWithPrice.position,
          translationKey: modelWithPrice.translationKey
        });
      }).filter(function (m) {
        return m.count !== undefined;
      });

      var placeholderVehicleModels = Array(16).fill({
        count: 0,
        position: 9999,
        loading: true
      });

      return _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: globalStyling.contentWidth },
        _react2.default.createElement(
          SearchButtonTopContainer,
          null,
          _react2.default.createElement(SearchBtn, { topSearchButton: true })
        ),
        _react2.default.createElement(
          Container,
          { padding: config.matrixWrapperPadding },
          _react2.default.createElement(
            MobileContainer,
            null,
            _react2.default.createElement(_MobileFilters2.default, {
              filters: filters,
              translations: config.translations,
              activeFiltersCount: shared.filters.activeFiltersCount,
              globalStyling: globalStyling,
              backgroundColour: filtersConfig.config.stylingConfig && filtersConfig.config.stylingConfig.backgroundColour,
              borderColour: filtersConfig.config.stylingConfig && filtersConfig.config.stylingConfig.borderColour,
              config: config,
              searchCountryCode: marketInfo.searchCountryCode
            })
          ),
          _react2.default.createElement(
            LeftColumn,
            null,
            _react2.default.createElement(
              FiltersContainerWrapper,
              null,
              filters
            )
          ),
          _react2.default.createElement(
            RightColumn,
            null,
            _react2.default.createElement(_ModelMatrixGrid2.default, {
              vehicleModels: vehicleModels.length > 0 ? vehicleModels : placeholderVehicleModels,
              onItemClick: this.toggleModelFilter,
              selectedModels: shared.filters.selectedFilters && shared.filters.selectedFilters.model,
              translations: config.translations,
              selectedIconColour: config.carFilterSelectedIconColour && config.carFilterSelectedIconColour.value,
              locale: marketInfo.locale,
              titleFont: config.modelTitleFont,
              featureFlags: featureFlags,
              numberOfColumns: config.numberOfColumns,
              borderColour: config.borderColour,
              showPrice: config.showPrice,
              showSubtitle: config.showSubtitle,
              showStockNumber: config.showStockNumber,
              modelCardStyling: config.modelCardStyling,
              gridTilePadding: config.gridTilePadding,
              globalStyling: globalStyling,
              titleBottomMargin: config.titleBottomMargin,
              showFinancePopup: config.showFinancePopup
            })
          )
        ),
        _react2.default.createElement(_FooterActionBanner2.default, {
          stickyFooterBackgroundColour: config.stickyFooterBackgroundColour,
          stickyFooterBorderTop: config.stickyFooterBorderTop,
          leftButton: ResetBtn,
          rightButton: SearchBtn
        }),
        config.useStickySearchBar && _react2.default.createElement(
          DesktopOnly,
          null,
          showStickySearch && _react2.default.createElement(_FiltersStickyLocationSearchBar2.default, {
            count: shared.filters.total,
            translations: config.translations,
            dropdownTheme: config.dropdown,
            display: showStickySearch,
            globalStyling: globalStyling,
            pathByModule: pathByModule,
            shared: shared,
            onReset: this.onStickySearchBarReset,
            config: config
          }),
          _react2.default.createElement(_reactWaypoint2.default, { onPositionChange: this.toggleStickySearchBar })
        )
      );
    }
  }]);
  return ModelMatrixSearch;
}(_react.Component);

exports.default = ModelMatrixSearch;