'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Pin;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Pin(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 28 25' },
      _react2.default.createElement('path', { d: 'M12.893 1C8.055 1 4 4.87 4 9.488c0 2.037.711 4.074 2.134 5.432 1.637 1.969 6.83 8.08 6.83 8.08s5.265-6.383 6.902-8.216C21.146 13.358 22 11.389 22 9.352 21.787 4.735 17.66 1 12.893 1zm-.213 13.648c-2.846 0-5.478-2.376-5.478-5.228 0-2.716 2.49-5.229 5.478-5.229 2.988 0 5.478 2.377 5.478 5.229 0 2.852-2.419 5.228-5.478 5.228z' })
    )
  );
}

Pin.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};