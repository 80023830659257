'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n  '], ['\n    display: flex;\n  ']);

var _history = require('history');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _router = require('../../../actions/router');

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _AppState = require('../../../types/AppState');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SubNavBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__SubNavBarContainer',
  componentId: 'sc-141ysa3-0'
})(['align-items:center;background-color:#1a1a1a;display:flex;height:48px;justify-content:center;@media print{display:none;}']);

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__Content',
  componentId: 'sc-141ysa3-1'
})(['box-sizing:border-box;display:flex;justify-content:space-between;width:100%;max-width:1920px;', ';', ''], function () {
  return _Genesis.mixins.pageGuttering();
}, function (_ref) {
  var alignRight = _ref.alignRight;
  return alignRight && 'justify-content: flex-end;';
});

var SubNavLink = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__SubNavLink',
  componentId: 'sc-141ysa3-2'
})(['align-items:center;color:#999;cursor:pointer;display:flex;font-family:\'GenesisSansHeadGlobal-Regular\';font-weight:normal;padding-left:48px;text-transform:uppercase;transition:color 0.2s linear;&:hover{color:#fff;}']);

var LinksContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__LinksContainer',
  componentId: 'sc-141ysa3-3'
})(['display:flex;']);

var Button = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__Button',
  componentId: 'sc-141ysa3-4'
})(['align-items:center;color:#999;cursor:pointer;display:flex;font-family:\'GenesisSansHeadGlobal-Regular\';font-weight:normal;text-transform:uppercase;transition:color 0.2s linear;&:hover{color:#fff;}img{padding-right:10px;}']);

var DesktopLinks = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__DesktopLinks',
  componentId: 'sc-141ysa3-5'
})(['display:flex;overflow:hidden;', ';'], _Genesis2.default.max.medium(_templateObject));

var MobileLinks = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__MobileLinks',
  componentId: 'sc-141ysa3-6'
})(['display:none;overflow:hidden;', ';'], _Genesis2.default.max.medium(_templateObject2));

var AvailableLinks = function AvailableLinks(_ref2) {
  var links = _ref2.links;

  var dispatch = (0, _reactRedux.useDispatch)();
  var shared = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  });
  var vehicleCounters = {
    compare: shared.compare.vehicles.length,
    shortlist: shared.shortlist.vehicles.length
  };

  return _react2.default.createElement(
    LinksContainer,
    null,
    links && links.map(function (link, index) {
      return _react2.default.createElement(
        SubNavLink,
        {
          key: index.toString(),
          onClick: function onClick() {
            return dispatch(_router.actions.navigate(link.path));
          }
        },
        link.translations.linkText + ' ' + (vehicleCounters[link.key] ? '(' + vehicleCounters[link.key] + ')' : '')
      );
    })
  );
};

var SubNavBar = function SubNavBar(_ref3) {
  var config = _ref3.config,
      history = _ref3.history;

  var links = config.items;
  var mobileLinks = links && links.filter(function (f) {
    return f.path !== '/compare';
  });

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences;
  }),
      language = _useSelector.language;

  var prevPath = (0, _reactRedux.useSelector)(function (state) {
    return state.router.history && state.router.navigationHistory[1];
  });

  var path = !config.goBackPathname ? prevPath ? undefined : config.defaultGoBackPathname : config.goBackPathname === '/' ? '/' : '/' + language + config.goBackPathname;

  return _react2.default.createElement(
    SubNavBarContainer,
    null,
    _react2.default.createElement(
      Content,
      { alignRight: config.hideBackButton },
      !config.hideBackButton && _react2.default.createElement(
        Button,
        {
          onClick: function onClick() {
            path ? history.push(path) : history.goBack();
          }
        },
        _react2.default.createElement('img', {
          src: config.goBackIconUrl,
          alt: config.translations.backButtonText,
          style: { position: 'relative', top: '-1px' }
        }),
        prevPath ? config.translations.backButtonText : config.translations.defaultBackButtonText
      ),
      _react2.default.createElement(
        DesktopLinks,
        null,
        _react2.default.createElement(AvailableLinks, { links: links })
      ),
      _react2.default.createElement(
        MobileLinks,
        null,
        _react2.default.createElement(AvailableLinks, { links: mobileLinks })
      )
    )
  );
};

exports.default = SubNavBar;