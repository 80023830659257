'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    min-width: 100%;\n  '], ['\n    min-width: 100%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    justify-content: flex-start;\n    gap: 20px;\n    padding-bottom: 49px;\n    width: 100%;\n  '], ['\n    flex-direction: column;\n    justify-content: flex-start;\n    gap: 20px;\n    padding-bottom: 49px;\n    width: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding-top: 20px;\n'], ['\n    padding-top: 20px;\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 11px;\n'], ['\n  font-size: 11px;\n']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n      font-size: 13px;\n'], ['\n      font-size: 13px;\n']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    padding-top: 10px;\n  '], ['\n    padding-top: 10px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    margin: 0 auto;\n  '], ['\n    width: 100%;\n    margin: 0 auto;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _RetailerInformation = require('../../../modules/RetailerInformation/RetailerInformation');

var _vehicleDetailsPageTypes = require('../../../types/RollsRoyce/vehicleDetailsPageTypes');

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

var _Global = require('../../Global');

var _StyledIcon = require('../../StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _Button = require('../Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _VdpLocationPinIcon = require('./VdpLocationPinIcon');

var _VdpLocationPinIcon2 = _interopRequireDefault(_VdpLocationPinIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var REGULAR_FONT = 'RivieraNights-Regular,Jost-Regular';
var RRMC_PREFIX = /^(Rolls-Royce Motor Cars|劳斯莱斯汽车)\s*/i;

var NVL_INVENTORY_STATUS = 'new';

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__Wrapper',
  componentId: 'sc-lnrn64-0'
})(['display:flex;flex-direction:column;align-items:center;height:100%;min-width:350px;margin:0 auto;', ';', ';background-image:', ';'], function () {
  return _RollsRoyce.mixins.pageGuttering();
}, _RollsRoyce2.default.max.small(_templateObject), function (_ref) {
  var background = _ref.background;
  return 'url(' + background + ')' || '';
});

var BrandImg = _styledComponents2.default.img.withConfig({
  displayName: 'VdpRetailerInfo__BrandImg',
  componentId: 'sc-lnrn64-1'
})(['width:180px;height:45px;padding-bottom:5px;']);

var ImageTitleWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__ImageTitleWrapper',
  componentId: 'sc-lnrn64-2'
})(['display:flex;flex-direction:column;text-align:center;width:auto;padding-top:75px;padding-bottom:50px;align-items:center;']);

var TitleWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__TitleWrapper',
  componentId: 'sc-lnrn64-3'
})(['position:relative;max-width:300px;width:auto;']);

var BrandTitleWrapper = (0, _styledComponents2.default)(TitleWrapper).withConfig({
  displayName: 'VdpRetailerInfo__BrandTitleWrapper',
  componentId: 'sc-lnrn64-4'
})(['display:flex;justify-content:center;']);

var MainInfoWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__MainInfoWrapper',
  componentId: 'sc-lnrn64-5'
})(['display:flex;flex-direction:row;justify-content:center;gap:56px;padding-bottom:230px;', ';'], _RollsRoyce2.default.max.medium(_templateObject2));

var InfoPanel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__InfoPanel',
  componentId: 'sc-lnrn64-6'
})(['display:flex;flex-direction:column;width:auto;align-items:flex-start;']);

var AddressWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__AddressWrapper',
  componentId: 'sc-lnrn64-7'
})(['padding-top:23px;display:flex;justify-content:start;max-width:300px;gap:4px;svg{margin-top:6px;}', ';'], _RollsRoyce2.default.max.medium(_templateObject3));

var TitleLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__TitleLabel',
  componentId: 'sc-lnrn64-8'
})(['color:#676776;font-family:', ';font-size:12px;font-weight:400;line-height:18px;letter-spacing:2px;text-align:left;text-transform:uppercase;', ';'], REGULAR_FONT, _RollsRoyce2.default.max.medium(_templateObject4));

var TextLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__TextLabel',
  componentId: 'sc-lnrn64-9'
})(['color:#fff;font-family:', ';font-size:14px;font-weight:400;line-height:24px;letter-spacing:0.5px;text-align:left;text-transform:', ';', ';'], REGULAR_FONT, function (_ref2) {
  var capitalize = _ref2.capitalize;
  return capitalize ? 'capitalize' : 'none';
}, _RollsRoyce2.default.max.medium(_templateObject5));

var PhoneRow = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__PhoneRow',
  componentId: 'sc-lnrn64-10'
})(['position:relative;display:flex;gap:6px;color:#fff;font-family:', ';font-size:14px;font-weight:400;line-height:24px;letter-spacing:0.5px;text-align:left;padding-bottom:40px;', ';'], REGULAR_FONT, _RollsRoyce2.default.max.medium(_templateObject6));

var ButtonsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__ButtonsWrapper',
  componentId: 'sc-lnrn64-11'
})(['display:flex;flex-direction:column;justify-content:center;align-items:center;width:278px;height:48px;', ';'], _RollsRoyce2.default.max.medium(_templateObject7));
var ContactLink = _styledComponents2.default.a.withConfig({
  displayName: 'VdpRetailerInfo__ContactLink',
  componentId: 'sc-lnrn64-12'
})(['font-size:14px;text-decoration:none;color:#fff;']);

var ViewDealerStockBtn = (0, _styledComponents2.default)(_Button2.default).withConfig({
  displayName: 'VdpRetailerInfo__ViewDealerStockBtn',
  componentId: 'sc-lnrn64-13'
})(['width:276px;height:46px;']);

var TelLinkRow = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__TelLinkRow',
  componentId: 'sc-lnrn64-14'
})(['display:flex;gap:4px;']);

var TelNumber = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__TelNumber',
  componentId: 'sc-lnrn64-15'
})(['color:#fff;border-bottom:1px solid #fff;']);

var Line = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerInfo__Line',
  componentId: 'sc-lnrn64-16'
})(['display:', ';border-bottom:1px solid #fff;margin-top:-2px;width:94px;'], function (_ref3) {
  var visible = _ref3.visible;
  return visible === true ? 'block' : 'none';
});
/*
 * FormatTitleName
 * Removes the RRMC_PREFIX from the retailer name
 * This function used to show the town name but it was causing issues where the town name didn't match the retailer name
 * For example the retailer name is "Rolls-Royce Motor Cars Leeds" but the town name is "Lower wortley"
 */
var FormatTitleName = function FormatTitleName(name) {
  return name.replace(RRMC_PREFIX, '');
};

var AddressSection = function AddressSection(_ref4) {
  var street = _ref4.street,
      town = _ref4.town,
      postcode = _ref4.postcode,
      country = _ref4.country;

  var address = [street, postcode, town, country].filter(function (str) {
    return str.length > 0;
  }).join(', ');

  return _react2.default.createElement(
    AddressWrapper,
    null,
    _react2.default.createElement(_VdpLocationPinIcon2.default, { width: 18, height: 13, stroke: '#fff' }),
    _react2.default.createElement(
      TextLabel,
      null,
      '' + address
    )
  );
};

var PhoneNumber = function PhoneNumber(_ref5) {
  var _ref5$number = _ref5.number,
      number = _ref5$number === undefined ? '' : _ref5$number,
      _ref5$iconUrl = _ref5.iconUrl,
      iconUrl = _ref5$iconUrl === undefined ? '' : _ref5$iconUrl,
      _ref5$width = _ref5.width,
      width = _ref5$width === undefined ? 12 : _ref5$width,
      _ref5$height = _ref5.height,
      height = _ref5$height === undefined ? 13 : _ref5$height;

  return _react2.default.createElement(
    PhoneRow,
    null,
    _react2.default.createElement(
      'div',
      { style: { marginTop: '2px' } },
      _react2.default.createElement(_StyledIcon2.default, {
        link: iconUrl,
        width: width,
        height: height,
        colour: '#ffffff',
        alt: 'phone-icon'
      })
    ),
    _react2.default.createElement(
      ContactLink,
      { href: 'tel:' + number },
      _react2.default.createElement(
        TelLinkRow,
        null,
        _react2.default.createElement(
          'div',
          null,
          'Tel:'
        ),
        _react2.default.createElement(
          TelNumber,
          null,
          '' + number
        )
      )
    )
  );
};

var VdpRetailerInfo = function VdpRetailerInfo(_ref6) {
  var config = _ref6.config,
      translations = _ref6.translations,
      retailer = _ref6.retailer,
      viewRetailerStock = _ref6.viewRetailerStock;
  var _retailer$name = retailer.name,
      retailerName = _retailer$name === undefined ? null : _retailer$name,
      _retailer$phone = retailer.phone,
      retailerPhoneNumber = _retailer$phone === undefined ? null : _retailer$phone,
      _retailer$address = retailer.address,
      address = _retailer$address === undefined ? [] : _retailer$address;
  var vdpDealerPhoneIconUrl = config.vdpDealerPhoneIconUrl,
      rrmcBrandLogoUrl = config.rrmcBrandLogoUrl;
  var _translations$vdpReta = translations.vdpRetailerContectText,
      contactLabel = _translations$vdpReta === undefined ? null : _translations$vdpReta,
      _translations$vdpReta2 = translations.vdpRetailerStockButtonText,
      viewDealerStock = _translations$vdpReta2 === undefined ? '' : _translations$vdpReta2;

  var _address = (0, _slicedToArray3.default)(address, 4),
      _address$ = _address[0],
      street = _address$ === undefined ? '' : _address$,
      _address$2 = _address[1],
      town = _address$2 === undefined ? '' : _address$2,
      _address$3 = _address[2],
      postcode = _address$3 === undefined ? '' : _address$3,
      _address$4 = _address[3],
      country = _address$4 === undefined ? '' : _address$4;

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state;
  }),
      _useSelector$config = _useSelector.config;

  _useSelector$config = _useSelector$config === undefined ? null : _useSelector$config;
  var _useSelector$config$c = _useSelector$config.config;
  _useSelector$config$c = _useSelector$config$c === undefined ? null : _useSelector$config$c;
  var _useSelector$config$c2 = _useSelector$config$c.global;
  _useSelector$config$c2 = _useSelector$config$c2 === undefined ? null : _useSelector$config$c2;
  var _useSelector$config$c3 = _useSelector$config$c2.inventory;
  _useSelector$config$c3 = _useSelector$config$c3 === undefined ? null : _useSelector$config$c3;
  var _useSelector$config$c4 = _useSelector$config$c3.inventoryStatus,
      inventoryStatus = _useSelector$config$c4 === undefined ? null : _useSelector$config$c4;


  return _react2.default.createElement(
    Wrapper,
    {
      background: config.dealerInformationBackgroundImageUrl,
      id: 'retailerMap'
    },
    _react2.default.createElement(
      ImageTitleWrapper,
      null,
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(BrandImg, { src: rrmcBrandLogoUrl, alt: 'Rolls Royce Brand image' })
      ),
      _react2.default.createElement(Line, { visible: true }),
      _react2.default.createElement(
        BrandTitleWrapper,
        null,
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)({
                typeface: {
                  value: REGULAR_FONT
                }
              }) + ' \n            color:#fff;\n            font-size: 12px;\n            font-weight: 400;\n            letter-spacing: 0.5px;\n            text-align: center;  \n            padding: 5px;\n            text-transform: uppercase;\n          ';
            }
          },
          FormatTitleName(retailerName)
        )
      )
    ),
    _react2.default.createElement(
      MainInfoWrapper,
      null,
      _react2.default.createElement(
        InfoPanel,
        null,
        _react2.default.createElement(
          TitleWrapper,
          null,
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)({
                  typeface: {
                    value: REGULAR_FONT
                  }
                }) + ' \n            color:#fff;\n            font-size: 16px;\n            font-weight: 400;\n            line-height: 28px;\n            letter-spacing: 2.5px;\n            text-align: left;\n            text-transform: uppercase;\n\n          ';
              }
            },
            retailerName
          )
        ),
        _react2.default.createElement(AddressSection, {
          street: street,
          town: town,
          postcode: postcode,
          country: country
        })
      ),
      _react2.default.createElement(
        InfoPanel,
        null,
        _react2.default.createElement(
          TitleLabel,
          null,
          contactLabel + ' '
        ),
        _react2.default.createElement(PhoneNumber, {
          number: retailerPhoneNumber,
          iconUrl: vdpDealerPhoneIconUrl
        }),
        inventoryStatus && inventoryStatus !== NVL_INVENTORY_STATUS && _react2.default.createElement(
          ButtonsWrapper,
          null,
          _react2.default.createElement(ViewDealerStockBtn, {
            styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)({
                typeface: {
                  value: REGULAR_FONT
                }
              }) + ' \n                         color: #fff;\n                         font-size: 12px;\n                         font-weight: 500;\n                         line-height: 24px;\n                         letter-spacing: 2px;\n                         text-align: center;\n                         &:hover,\n                         &:active {\n                           background-color: #502387;\n                         }\n                     ';
            },
            text: viewDealerStock,
            variant: 'primary',
            onClick: function onClick() {
              viewRetailerStock(retailer);
            },
            icon: 'https://res.cloudinary.com/motortrak/v1706726136/locator/rolls-royce/global/button-arrow.svg'
          })
        )
      )
    )
  );
};

exports.default = VdpRetailerInfo;