'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return {
    translations: {
      headlineText: 'Headline placeholder',
      subHeading: 'Subheading placeholder'
    },
    bodyFont: {
      kerning: '0.4',
      fontSize: '15'
    },
    headlineFont: {
      kerning: '0.4',
      fontSize: '18'
    },
    selectedImage: {
      label: 'image label placeholder',
      value: 'https://res.cloudinary.com/motortrak/image/upload/v1537798827/default-image_btd6xy.png'
    },
    contentAlign: 'top'
  };
};