'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-wrap: wrap;\n  '], ['\n    flex-wrap: wrap;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n    width: 40%;\n  '], ['\n    padding: 0;\n    width: 40%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 60%;\n    padding-left: 15px;\n  '], ['\n    width: 60%;\n    padding-left: 15px;\n  ']);

exports.default = TestDriveVehicleTile;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TestDriveVehicleTileContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveVehicleTile__TestDriveVehicleTileContainer',
  componentId: 'sc-5r54rl-0'
})(['position:relative;overflow:hidden;display:flex;', ';'], _theme2.default.min.large(_templateObject));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'Image'
}).withConfig({
  displayName: 'TestDriveVehicleTile__Image',
  componentId: 'sc-5r54rl-1'
})(['display:block;max-width:376px;max-height:283px;width:100%;', ';'], _theme2.default.max.large(_templateObject2));

var Header = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveVehicleTile__Header',
  componentId: 'sc-5r54rl-2'
})(['font-size:20px;margin-top:32px;color:#000;font-weight:600;']);

var Details = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveVehicleTile__Details',
  componentId: 'sc-5r54rl-3'
})(['font-size:16px;margin:5px 0;color:#444444;display:flex;flex-wrap:wrap;']);

var Detail = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveVehicleTile__Detail',
  componentId: 'sc-5r54rl-4'
})(['margin-right:5px;']);

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImageContainer'
}).withConfig({
  displayName: 'TestDriveVehicleTile__ImageContainer',
  componentId: 'sc-5r54rl-5'
})(['padding:5%;', ';'], _theme2.default.max.large(_templateObject3));

var TextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveVehicleTile__TextContainer',
  componentId: 'sc-5r54rl-6'
})(['padding:0 5%;width:100%;', ';'], _theme2.default.max.large(_templateObject4));

function TestDriveVehicleTile(_ref) {
  var vehicle = _ref.vehicle;

  return _react2.default.createElement(
    TestDriveVehicleTileContainer,
    null,
    _react2.default.createElement(
      ImageContainer,
      null,
      vehicle.images.length > 0 ? _react2.default.createElement(Image, { src: vehicle.images[0] }) : _react2.default.createElement(Image, { src: vehicle.placeholdingImage })
    ),
    _react2.default.createElement(
      TextContainer,
      null,
      _react2.default.createElement(
        Header,
        null,
        vehicle.name
      ),
      _react2.default.createElement(
        Details,
        null,
        _react2.default.createElement(
          Detail,
          null,
          vehicle.specification.odometer && vehicle.specification.odometer.display + ' | '
        ),
        _react2.default.createElement(
          Detail,
          null,
          vehicle.specification.engine && vehicle.specification.engine + ' | '
        ),
        _react2.default.createElement(
          Detail,
          null,
          vehicle.specification.transmission && vehicle.specification.transmission + ' | '
        ),
        _react2.default.createElement(
          Detail,
          null,
          vehicle.specification.fuelType
        )
      )
    )
  );
}