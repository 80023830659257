'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactShare = require('react-share');

var _SocialNetworks = require('./SocialNetworks/');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SocialMediaShareContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SocialMediaShare__SocialMediaShareContainer',
  componentId: 'sc-13dftcb-0'
})(['display:flex;justify-content:center;align-items:center;height:', ';'], function (_ref) {
  var height = _ref.height;
  return height !== 0 ? height + 'px;' : 'auto;';
});

var ShareButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SocialMediaShare__ShareButtonWrapper',
  componentId: 'sc-13dftcb-1'
})(['cursor:pointer;padding:0 8px;', ';& > div:focus{outline:0;}'], function (_ref2) {
  var enabled = _ref2.enabled;
  return !enabled && 'display: none';
});

var SocialMediaShare = function SocialMediaShare(_ref3) {
  var shareUrl = _ref3.shareUrl,
      socialShare = _ref3.socialShare,
      _ref3$height = _ref3.height,
      height = _ref3$height === undefined ? 125 : _ref3$height;

  return _react2.default.createElement(
    SocialMediaShareContainer,
    { height: height },
    _react2.default.createElement(
      ShareButtonWrapper,
      {
        'data-cy': 'socialShareFacebook',
        enabled: socialShare.facebook
      },
      _react2.default.createElement(
        _reactShare.FacebookShareButton,
        { url: shareUrl },
        _react2.default.createElement(_SocialNetworks.Facebook, null)
      )
    ),
    _react2.default.createElement(
      ShareButtonWrapper,
      {
        'data-cy': 'socialShareTwitter',
        enabled: socialShare.twitter
      },
      _react2.default.createElement(
        _reactShare.TwitterShareButton,
        { url: shareUrl },
        _react2.default.createElement(_SocialNetworks.Twitter, null)
      )
    ),
    _react2.default.createElement(
      ShareButtonWrapper,
      {
        'data-cy': 'socialShareWhatsApp',
        enabled: socialShare.whatsApp
      },
      _react2.default.createElement(
        _reactShare.WhatsappShareButton,
        { url: shareUrl },
        _react2.default.createElement(_SocialNetworks.Whatsapp, null)
      )
    ),
    _react2.default.createElement(
      ShareButtonWrapper,
      {
        'data-cy': 'socialShareEmail',
        enabled: socialShare.email
      },
      _react2.default.createElement(
        _reactShare.EmailShareButton,
        { url: shareUrl },
        _react2.default.createElement(_SocialNetworks.Email, null)
      )
    )
  );
};

exports.default = SocialMediaShare;