'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FormFieldContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FormField__FormFieldContainer',
  componentId: 'sc-41rz8j-0'
})(['position:relative;margin:0;width:100%;&:focus{outline:none;}', ''], function (_ref) {
  var orientation = _ref.orientation;
  return orientation === 'horizontal' ? 'display: inline-flex' : 'display: flex; flex-direction: column';
});

function FormField(_ref2) {
  var children = _ref2.children,
      _ref2$orientation = _ref2.orientation,
      orientation = _ref2$orientation === undefined ? 'vertical' : _ref2$orientation;

  return _react2.default.createElement(
    FormFieldContainer,
    { orientation: orientation },
    children
  );
}