'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']);

var _framerMotion = require('framer-motion');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PageOverlay = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'SlideMenu__PageOverlay',
  componentId: 'sc-1k1chc2-0'
})(['position:fixed;inset:0;top:0;right:0;width:100%;height:100%;z-index:11;']);

var Drawer = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'SlideMenu__Drawer',
  componentId: 'sc-1k1chc2-1'
})(['display:flex;flex-direction:column;box-sizing:border-box;align-items:end;justify-content:center;height:100%;background:rgba(0,0,0,0.002);backdrop-filter:blur(150px) saturate(1.8);position:fixed;top:0;left:0;overflow:hidden;width:calc(43% + 16px);max-width:100%;z-index:15;padding:0;padding-right:calc(16px + 6.5vw);', ''], _theme2.default.max.medium(_templateObject));

var LinkContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SlideMenu__LinkContainer',
  componentId: 'sc-1k1chc2-2'
})(['display:flex;flex-direction:column;justify-content:center;align-items:end;width:100;gap:30px;box-sizing:border-box;']);

var Link = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'SlideMenu__Link',
  componentId: 'sc-1k1chc2-3'
})(['color:white;cursor:pointer;font-size:16px;letter-spacing:1.88px;line-height:20.94px;font-weight:500;opacity:0.5;font-family:\'RivieraNights-Regular\';']);

var LinkSpan = _styledComponents2.default.span.withConfig({
  displayName: 'SlideMenu__LinkSpan',
  componentId: 'sc-1k1chc2-4'
})(['opacity:0.5;&:hover,&:active{opacity:1;}transition:all 0.3s;']);

var LanguagePicklist = _styledComponents2.default.select.withConfig({
  displayName: 'SlideMenu__LanguagePicklist',
  componentId: 'sc-1k1chc2-5'
})(['background:none;border:none;cursor:pointer;color:#000;font-size:16px;font-family:\'RivieraNights-Medium\',Arial;font-size:16px;font-weight:500;text-transform:uppercase;letter-spacing:1.88px;box-shadow:none;appearance:none;padding:0 30px 0 3px;background-image:url(\'https://res.cloudinary.com/motortrak/v1709224292/locator/rolls-royce/global/icons/chev-down.svg\');background-position:right 8px center;background-repeat:no-repeat;filter:invert(1);option{color:black;background:white;display:flex;white-space:pre;min-height:20px;padding:0px 2px 1px;}&:focus{outline:none;border:none;}']);

var PickListWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SlideMenu__PickListWrapper',
  componentId: 'sc-1k1chc2-6'
})(['align-items:center;bottom:0;color:#fff;display:flex;font-family:\'RivieraNights-Regular\',Arial;font-size:16px;font-weight:500;justify-content:center;letter-spacing:1.88px;position:absolute;text-transform:uppercase;transition:opacity 0.3s;opacity:0.5;margin-bottom:30px;&:hover{opacity:1;}']);

var pageOverlayVariants = {
  open: {
    opacity: 0.5,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'block',
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  },
  closed: {
    opacity: 0,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    transition: {
      duration: 1,
      delay: 0.5,
      ease: 'easeInOut'
    },
    transitionEnd: {
      display: 'none'
    }
  }
};

var menuVariants = {
  slideIn: function slideIn(_ref) {
    var _ref2 = (0, _slicedToArray3.default)(_ref, 2),
        i = _ref2[0],
        length = _ref2[1];

    return {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        delay: (length - 1 - i) * 0.1
      }
    };
  },
  slideOut: function slideOut(_ref3) {
    var _ref4 = (0, _slicedToArray3.default)(_ref3, 2),
        i = _ref4[0],
        length = _ref4[1];

    return {
      x: '-300%',
      opacity: 0,
      initial: {
        x: 0,
        opacity: 1
      },

      transition: {
        duration: 0.7,
        delay: (length - 1 - i) * 0.1
      }
    };
  },
  freeze: {
    opacity: 1,
    x: 0
  }
};

var drawerVariants = {
  open: {
    x: 0,
    display: 'flex',
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  },
  closed: {
    x: '-100%',
    transition: {
      delay: 0.5,
      duration: 1,
      ease: 'easeInOut'
    },
    transitionEnd: {
      display: 'none'
    }
  }
};

var SlideMenu = function SlideMenu(_ref5) {
  var config = _ref5.config,
      links = _ref5.links,
      navigate = _ref5.navigate,
      languages = _ref5.languages,
      selectedLanguage = _ref5.selectedLanguage,
      changeLanguage = _ref5.changeLanguage,
      open = _ref5.open,
      onClose = _ref5.onClose;

  // stop scroll on open

  var initialAnimationRef = (0, _react.useRef)(true);
  document.body.style.overflow = open ? 'hidden' : 'auto';
  initialAnimationRef.current = open ? true : false;

  var onLanguageSelected = function onLanguageSelected(value, selectedLanguage) {
    if (changeLanguage && selectedLanguage !== value) {
      changeLanguage(languages.find(function (l) {
        return l.value === value;
      }));
    }
  };

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(PageOverlay, {
      onClick: onClose,
      variants: pageOverlayVariants,
      initial: initialAnimationRef.current ? {} : 'closed',
      animate: open ? 'open' : initialAnimationRef.current ? {} : 'closed'
    }),
    _react2.default.createElement(
      Drawer,
      {
        initial: initialAnimationRef.current ? {} : 'closed',
        variants: drawerVariants,
        animate: open ? 'open' : initialAnimationRef.current ? {} : 'closed'
      },
      _react2.default.createElement(
        LinkContainer,
        null,
        links.map(function (item, i, array) {
          return _react2.default.createElement(
            Link,
            {
              key: (0, _v2.default)(),
              onClick: function onClick() {
                navigate(item.link);
                onClose();
              },
              initial: initialAnimationRef.current ? { x: '-300%', opacity: 0 } : open ? { opacity: 0, x: 0 } : { x: 0, opacity: 1 },
              custom: [i, array.length],
              animate: initialAnimationRef.current ? 'slideIn' : open ? 'freeze' : 'slideOut',
              variants: menuVariants
            },
            _react2.default.createElement(
              LinkSpan,
              null,
              config.translations['menuBarItems-' + i]
            )
          );
        })
      ),
      languages.length > 0 && _react2.default.createElement(
        PickListWrapper,
        null,
        config.translations.languagePrefix,
        _react2.default.createElement(
          LanguagePicklist,
          {
            value: selectedLanguage.value,
            onChange: function onChange(e) {
              onLanguageSelected(e.target.value, selectedLanguage);
            }
          },
          languages.map(function (language) {
            return _react2.default.createElement(
              'option',
              { key: language.value, value: language.value },
              language.label
            );
          })
        )
      )
    )
  );
};

exports.default = SlideMenu;