'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

exports.default = SortMenuBar;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SortContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SortMenuBar__SortContainer',
  componentId: 'sc-bu53qw-0'
})(['align-items:', ';display:flex;margin-top:0;height:auto;width:', ';'], function (props) {
  return props.filtersExpanded ? 'flex-start' : 'center';
}, function (props) {
  return props.filtersExpanded ? '100%' : 'auto';
});
var Sort = _styledComponents2.default.div.withConfig({
  displayName: 'SortMenuBar__Sort',
  componentId: 'sc-bu53qw-1'
})(['display:flex;width:100%;@media only screen and (min-width:993px){margin-top:23px;}']);

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'SortMenuBar__Select',
  componentId: 'sc-bu53qw-2'
})(['font-size:14px;box-sizing:content-box;border:none;border-radius:0%;color:#151515;font-family:\'RivieraNights-Regular\';font-size:10px;text-transform:uppercase;letter-spacing:2px;padding:10px 25px 10px 10px;width:auto;background-image:url(\'https://res.cloudinary.com/motortrak/v1709224292/locator/rolls-royce/global/icons/chev-down.svg\');background-position-x:100%;background-position-y:50%;background-repeat:no-repeat;background-color:#f2f2f2;-moz-appearance:none;-webkit-appearance:none;text-align:right;text-align-last:right;@media (min-width:993px){border-bottom:1px solid #151515;font-size:12px;text-align:initial;text-align-last:initial;background-color:#fff;}@media only screen and (min-width:1024px){background-position-x:95%;background-position-y:center;border-bottom:1px solid #151515;padding:10px 25px 10px 10px;}&:focus{outline:none;}']);

function SortMenuBar(_ref) {
  var sortOptions = _ref.sortOptions,
      sortValue = _ref.sortValue,
      filtersExpanded = _ref.filtersExpanded,
      onSortChange = _ref.onSortChange,
      translations = _ref.translations;

  var sortOptionsField = function sortOptionsField(fieldValue) {
    return ((0, _values2.default)(sortOptions).find(function (so) {
      return so.value === fieldValue;
    }) || {}).field;
  };

  var sortOptionsOrder = function sortOptionsOrder(fieldValue) {
    return ((0, _values2.default)(sortOptions).find(function (so) {
      return so.value === fieldValue;
    }) || {}).order;
  };

  var updateFilters = function updateFilters(sortField) {
    var payload = [{ key: 'sortField', value: [sortOptionsField(sortField)] }, { key: 'sortDirection', value: [sortOptionsOrder(sortField)] }];
    onSortChange(payload, sortField);
  };

  return _react2.default.createElement(
    SortContainer,
    { filtersExpanded: filtersExpanded },
    _react2.default.createElement(
      Sort,
      null,
      _react2.default.createElement(
        Select,
        {
          'data-cy': 'sort-dropdown',
          value: sortValue,
          onChange: function onChange(e) {
            return updateFilters(e.target.value);
          },
          'aria-label': translations.sortByAlternativeText
        },
        _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.priceAsc.value,
              field: sortOptions.priceAsc.field,
              order: sortOptions.priceAsc.order
            },
            translations.searchResultsSortPriceAscLabel
          ),
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.priceDesc.value,
              field: sortOptions.priceDesc.field,
              order: sortOptions.priceDesc.order
            },
            translations.searchResultsSortPriceDescLabel
          )
        ),
        _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.mileageAsc.value,
              field: sortOptions.mileageAsc.field,
              order: sortOptions.mileageAsc.order
            },
            translations.searchResultsSortMileageAscLabel
          ),
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.mileageDesc.value,
              field: sortOptions.mileageDesc.field,
              order: sortOptions.mileageDesc.order
            },
            translations.searchResultsSortMileageDescLabel
          )
        ),
        _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.ageAsc.value,
              field: sortOptions.ageAsc.field,
              order: sortOptions.ageAsc.order
            },
            translations.searchResultsSortAgeAscLabel
          ),
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.ageDesc.value,
              field: sortOptions.ageDesc.field,
              order: sortOptions.ageDesc.order
            },
            translations.searchResultsSortAgeDescLabel
          )
        ),
        _react2.default.createElement(
          'option',
          {
            value: sortOptions.distanceAsc.value,
            field: sortOptions.distanceAsc.field,
            order: sortOptions.distanceAsc.order
          },
          translations.searchResultsSortDistanceLabel
        )
      )
    )
  );
}
SortMenuBar.defaultProps = {
  filtersExpanded: false
};