'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VehicleSubHeader = exports.formattedVehicleDescription = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _Global = require('../../Global');

var _numbers = require('../../../shared/localisation/numbers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// eslint-disable-next-line no-confusing-arrow
var formattedVehicleDescription = exports.formattedVehicleDescription = function formattedVehicleDescription(type, _ref, showOdometerInformation, translations, badge) {
  var description = _ref.description,
      modelVariant = _ref.modelVariant,
      modelYear = _ref.modelYear,
      transmissionType = _ref.transmissionType,
      _ref$specification = _ref.specification,
      fuelType = _ref$specification.fuelType,
      odometer = _ref$specification.odometer,
      locale = _ref.locale;
  return type === 'VehicleDescription' ? description : showOdometerInformation ? ' ' + modelYear + ' | ' + (!badge ? modelVariant + ' |' : '') + ' ' + transmissionType + ' | ' + fuelType + ' |\n      ' + (odometer ? (0, _numbers.localiseNumber)(odometer.reading, locale) + ' ' + odometer.units : '' + translations.zeroMileageText) : ' ' + modelYear + ' | ' + (!badge ? modelVariant + ' |' : '') + ' ' + transmissionType + ' | ' + fuelType + ' ';
};

var VehicleSubHeader = exports.VehicleSubHeader = function VehicleSubHeader(_ref2) {
  var subHeaderType = _ref2.subHeaderType,
      subHeaderFont = _ref2.subHeaderFont,
      vehicleInfo = _ref2.vehicleInfo,
      showOdometerInformation = _ref2.showOdometerInformation,
      translations = _ref2.translations,
      badge = _ref2.badge;
  return _react2.default.createElement(
    _Global.HeadingThree,
    {
      styleOverride: function styleOverride() {
        return '' + (0, _Global.fontStyleOverride)(subHeaderFont);
      },
      maxMediumMobileStyleOverride: function maxMediumMobileStyleOverride() {
        return '\n                font-size: 12px;\n              ';
      }
    },
    formattedVehicleDescription(subHeaderType, vehicleInfo, showOdometerInformation, translations, badge)
  );
};

var mapStateToProps = function mapStateToProps(_ref3) {
  var translation = _ref3.translation;
  return {
    translations: translation.translations
  };
};

exports.default = (0, _reactRedux.connect)(mapStateToProps, null)(VehicleSubHeader);