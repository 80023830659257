'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    min-width: 350px;\n  '], ['\n    min-width: 350px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _SearchFilters = require('../components/SearchFilters');

var _SearchFilters2 = _interopRequireDefault(_SearchFilters);

var _searchFilter = require('../shared/searchFilter');

var _sessionPreferences = require('../shared/sessionPreferences');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mapStateToProps = function mapStateToProps(_ref) {
  var shared = _ref.shared;
  return {
    searchFilters: shared.searchFilter.searchFilters,
    selectedCheckboxFilters: shared.searchFilter.selectedCheckboxFilters,
    selectedRangeFilters: shared.searchFilter.selectedRangeFilters,
    filterSearchUrl: shared.searchFilter.filterSearchUrl,
    retailer: shared.searchFilter.retailer,
    maxDistance: shared.searchFilter.maxDistance,
    location: shared.sessionPreferences.location || {}
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    goToPath: function goToPath(path) {
      return dispatch(_searchFilter.actions.navigate(path));
    },
    clearCheckboxesBySection: function clearCheckboxesBySection(section) {
      return dispatch(_searchFilter.actions.clearCheckboxSection({ section: section }));
    },
    toggleCheckboxFilter: function toggleCheckboxFilter(section, filter) {
      return dispatch(_searchFilter.actions.toggleCheckboxFilter({ section: section, filter: filter }));
    },
    handleRangeFilters: function handleRangeFilters(id, values) {
      return dispatch(_searchFilter.actions.changeRangeFilter({ id: id, values: values }));
    },
    handleLocationChange: function handleLocationChange(_ref2) {
      var latitude = _ref2.latitude,
          longitude = _ref2.longitude,
          locationTown = _ref2.locationTown;

      dispatch(_sessionPreferences.actions.updateSessionPreferences('location', {
        latitude: latitude,
        longitude: longitude,
        locationTown: locationTown
      }));
    },
    handleMaxDistanceChange: function handleMaxDistanceChange(maxDistance) {
      return dispatch(_searchFilter.actions.changeMaxDistance({ maxDistance: maxDistance }));
    },
    getAllSearchFilters: function getAllSearchFilters(config) {
      return dispatch(_searchFilter.actions.getAllSearchFilters((0, _extends3.default)({}, config)));
    },
    getSearchFilters: function getSearchFilters(config) {
      return dispatch(_searchFilter.actions.getSearchFilters((0, _extends3.default)({}, config)));
    },
    removeRangeFilter: function removeRangeFilter(id) {
      return dispatch(_searchFilter.actions.removeRangeFilter({ id: id }));
    }
  };
};

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'SearchFiltersContainer__Container',
  componentId: 'sc-kfxnbm-0'
})(['display:flex;justify-content:space-between;width:100%;color:white;', ';'], _theme2.default.min.medium(_templateObject));

var SearchFiltersContainer = function (_Component) {
  (0, _inherits3.default)(SearchFiltersContainer, _Component);

  function SearchFiltersContainer() {
    var _ref3;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, SearchFiltersContainer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref3 = SearchFiltersContainer.__proto__ || (0, _getPrototypeOf2.default)(SearchFiltersContainer)).call.apply(_ref3, [this].concat(args))), _this), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            _this$props$config = _this$props.config,
            market = _this$props$config.market,
            make = _this$props$config.make,
            searchFilters = _this$props.searchFilters,
            getAllSearchFilters = _this$props.getAllSearchFilters;

        if (!searchFilters.loaded && !searchFilters.loadingAllFilters) {
          getAllSearchFilters({ market: market, make: make });
        }
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(SearchFiltersContainer, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (prevProps.filterSearchUrl !== this.props.filterSearchUrl) {
        var _props = this.props,
            _getSearchFilters = _props.getSearchFilters,
            _searchFilters = _props.searchFilters,
            _props$config = _props.config,
            _market = _props$config.market,
            _make = _props$config.make,
            _props$filterSearchUr = _props.filterSearchUrl,
            _props$filterSearchUr2 = _props$filterSearchUr.checkboxUrl,
            _checkboxUrl = _props$filterSearchUr2 === undefined ? '' : _props$filterSearchUr2,
            _props$filterSearchUr3 = _props$filterSearchUr.rangeUrl,
            _rangeUrl = _props$filterSearchUr3 === undefined ? '' : _props$filterSearchUr3,
            _props$filterSearchUr4 = _props$filterSearchUr.locationUrl,
            _locationUrl = _props$filterSearchUr4 === undefined ? '' : _props$filterSearchUr4,
            _props$filterSearchUr5 = _props$filterSearchUr.maxDistanceUrl,
            _maxDistanceUrl = _props$filterSearchUr5 === undefined ? '' : _props$filterSearchUr5;

        if (!_searchFilters.loadingAvailableFilters) {
          _getSearchFilters({
            market: _market,
            make: _make,
            filters: '' + _checkboxUrl + _rangeUrl + _locationUrl + _maxDistanceUrl
          });
        }
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          searchFilters = _props2.searchFilters,
          selectedCheckboxFilters = _props2.selectedCheckboxFilters,
          selectedRangeFilters = _props2.selectedRangeFilters,
          config = _props2.config,
          clearCheckboxesBySection = _props2.clearCheckboxesBySection,
          toggleCheckboxFilter = _props2.toggleCheckboxFilter,
          handleRangeFilters = _props2.handleRangeFilters,
          handleLocationChange = _props2.handleLocationChange,
          handleMaxDistanceChange = _props2.handleMaxDistanceChange,
          removeRangeFilter = _props2.removeRangeFilter,
          featureFlags = _props2.featureFlags,
          maxDistance = _props2.maxDistance,
          location = _props2.location,
          locationIconUrl = _props2.locationIconUrl,
          stylingConfig = _props2.stylingConfig;


      return _react2.default.createElement(
        Container,
        null,
        searchFilters.loaded ? _react2.default.createElement(_SearchFilters2.default, {
          allFilters: searchFilters.allFilters,
          resetRangeCount: searchFilters.resetRangeCount,
          availableFilters: searchFilters.availableFilters,
          selectedCheckboxFilters: selectedCheckboxFilters,
          selectedRangeFilters: selectedRangeFilters,
          config: config,
          clearCheckboxesBySection: clearCheckboxesBySection,
          toggleCheckboxFilter: toggleCheckboxFilter,
          handleRangeFilters: handleRangeFilters,
          handleLocationChange: handleLocationChange,
          handleMaxDistanceChange: handleMaxDistanceChange,
          removeRangeFilter: removeRangeFilter,
          featureFlags: featureFlags,
          maxDistance: maxDistance,
          location: location,
          stylingConfig: stylingConfig,
          locationIconUrl: locationIconUrl
        }) : null
      );
    }
  }]);
  return SearchFiltersContainer;
}(_react.Component);

Object.defineProperty(SearchFiltersContainer, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    searchFilters: {}
  }
});
exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(SearchFiltersContainer);