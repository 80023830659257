'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

exports.useRegionsHook = useRegionsHook;

var _reactRedux = require('react-redux');

var _filters = require('../filters');

var _settings = require('../selectors/settings');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useRegionsHook() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var countriesResult = [];
  var dispatch = (0, _reactRedux.useDispatch)();
  var mappedRegions = (0, _reactRedux.useSelector)(_settings.getMappedRegions);
  var isEnabledRegionsPresetFilters = (0, _reactRedux.useSelector)(_settings.getUseRegionsPresetFilters);

  var mappedCountries = (0, _reactRedux.useSelector)(_settings.getMappedCountries);
  var isEnabledCountriesPresetFilters = (0, _reactRedux.useSelector)(_settings.getUseCountriesPresetFilters);

  var availableRegions = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.filters.availableFilterValues ? state.shared.filters.availableFilterValues.regions : [];
  });

  var findRegionAndCountryByUserCountry = function findRegionAndCountryByUserCountry(regionsList, userCountryCode) {
    var regionsEntries = (0, _entries2.default)(regionsList);
    for (var i = 0; regionsEntries.length > i; i++) {
      var countryList = regionsEntries[i][1];
      var country = countryList.find(function (r) {
        return r.countryCode.toLowerCase() === userCountryCode.toLowerCase();
      });
      if (country) {
        return {
          region: regionsEntries[i][0],
          country: {
            code: userCountryCode,
            name: country.countryName
          }
        };
      }
    }
    return null;
  };

  var setRegionFilters = function setRegionFilters(userCountry, additionalFilter) {
    if (isEnabledRegionsPresetFilters) {
      var userLocation = findRegionAndCountryByUserCountry(mappedRegions, userCountry);

      if (userLocation && userLocation.region && availableRegions[userLocation.region]) {
        countriesResult = availableRegions[userLocation.region].filter(function (country) {
          return options.ignoreEnabledFilters || country.enabled;
        }).map(function (c) {
          return c.name;
        });
      }
      dispatch(_filters.actions.updateFilters([{
        key: 'subregions',
        value: countriesResult
      }].concat((0, _toConsumableArray3.default)(additionalFilter ? [additionalFilter] : []))));
    }
    if (isEnabledCountriesPresetFilters) {
      var userLocationCountry = findRegionAndCountryByUserCountry(mappedCountries, userCountry);

      if (userLocationCountry) {
        countriesResult = availableRegions[userLocationCountry.region].filter(function (country) {
          return country.name === userLocationCountry.country.name && (options.ignoreEnabledFilters || country.enabled);
        }).map(function (c) {
          return c.name;
        });
      }

      dispatch(_filters.actions.updateFilters([{
        key: 'subregions',
        value: countriesResult
      }]));
    }
  };

  return {
    setRegionFilters: setRegionFilters
  };
}