'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserLocation = undefined;

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useLocation2 = require('../../../hooks/useLocation');

var _useLocation3 = _interopRequireDefault(_useLocation2);

var _settings = require('../../../shared/selectors/settings');

var _Spinner = require('../../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__Wrapper',
  componentId: 'sc-lybc68-0'
})(['display:flex;width:100%;', ''], function (_ref) {
  var inputTheme = _ref.inputTheme;
  return inputTheme && 'border: 1px solid #aaaba6;';
});

var BrowserLocation = _styledComponents2.default.button.withConfig({
  displayName: 'UserLocation__BrowserLocation',
  componentId: 'sc-lybc68-1'
})(['align-items:center;background-color:#000;border:none;box-sizing:border-box;cursor:pointer;display:flex;flex:0 0 30px;justify-content:center;padding:5px 13px;', ''], function (_ref2) {
  var inputTheme = _ref2.inputTheme;
  return inputTheme && ' background-color: #fff;border-right: 1px solid #aaaba6;';
});

var InputWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__InputWrapper',
  componentId: 'sc-lybc68-2'
})(['flex:1 1 0%;position:relative;']);

var InputLocation = _styledComponents2.default.input.withConfig({
  displayName: 'UserLocation__InputLocation',
  componentId: 'sc-lybc68-3'
})(['background-color:#000;border-radius:0;border:none;box-sizing:border-box;color:#fff;padding:15px 8px;text-shadow:none;width:100%;', ' &:focus{outline:none;}::-ms-input-placeholder{color:#a2a2a2;}::placeholder{color:#a2a2a2;opacity:1;}'], function (_ref3) {
  var inputTheme = _ref3.inputTheme;
  return inputTheme && 'background-color: #fff;color: #000;';
});

var LocationSuggestions = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__LocationSuggestions',
  componentId: 'sc-lybc68-4'
})(['background-color:#ffffff;box-sizing:border-box;position:absolute;width:100%;', ';z-index:10;'], function (_ref4) {
  var position = _ref4.position;
  return position === 'below' ? 'top: 50px;' : 'bottom: 50px;';
});

var Suggestion = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__Suggestion',
  componentId: 'sc-lybc68-5'
})(['border:none;cursor:pointer;font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;outline:none;padding:10px;&:hover{background-color:#d5d5d5;}&:last-child{border-bottom:none;}']);

var SpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__SpinnerWrapper',
  componentId: 'sc-lybc68-6'
})(['position:absolute;right:10px;top:calc(50% - 10px);']);

var GetDirectionsButton = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__GetDirectionsButton',
  componentId: 'sc-lybc68-7'
})(['background-color:#000;cursor:pointer;padding:15px;display:flex;justify-content:center;']);

var UserLocation = function UserLocation(_ref5) {
  var placeholderText = _ref5.placeholderText,
      locationAlternativeText = _ref5.locationAlternativeText,
      iconUrl = _ref5.iconUrl,
      direction = _ref5.direction,
      browserLocationCallback = _ref5.browserLocationCallback,
      inputLocationCallback = _ref5.inputLocationCallback,
      _ref5$options = _ref5.options,
      options = _ref5$options === undefined ? {} : _ref5$options,
      newFilters = _ref5.newFilters,
      inputTheme = _ref5.inputTheme,
      onButtonClick = _ref5.onButtonClick,
      displayButtonCTA = _ref5.displayButtonCTA;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      colours = _useSelector.colours;

  var _options$autocomplete = options.autocompletePosition,
      autocompletePosition = _options$autocomplete === undefined ? 'below' : _options$autocomplete,
      rest = (0, _objectWithoutProperties3.default)(options, ['autocompletePosition']);

  var _useLocation = (0, _useLocation3.default)(browserLocationCallback, inputLocationCallback, rest),
      formattedLocation = _useLocation.formattedLocation,
      getBrowserLocation = _useLocation.getBrowserLocation,
      getInputLocation = _useLocation.getInputLocation,
      onInputChange = _useLocation.onInputChange,
      suggestions = _useLocation.suggestions,
      visible = _useLocation.visible,
      currentlySelected = _useLocation.currentlySelected,
      setCurrentlySelected = _useLocation.setCurrentlySelected,
      inputRef = _useLocation.inputRef,
      onInputKeyDown = _useLocation.onInputKeyDown,
      onInputBlur = _useLocation.onInputBlur,
      onInputFocus = _useLocation.onInputFocus,
      isLoading = _useLocation.isLoading;

  var onClick = (0, _react.useCallback)(function () {
    onButtonClick && onButtonClick();
  }, [onButtonClick]);

  var ref = (0, _react.useRef)(inputRef);

  return _react2.default.createElement(
    Wrapper,
    { role: 'group', inputTheme: inputTheme },
    _react2.default.createElement(
      BrowserLocation,
      {
        onClick: getBrowserLocation,
        direction: direction,
        newFilters: newFilters,
        'aria-label': locationAlternativeText || '',
        inputTheme: inputTheme
      },
      _react2.default.createElement('img', { src: iconUrl, alt: 'location-icon' })
    ),
    _react2.default.createElement(
      InputWrapper,
      null,
      _react2.default.createElement(InputLocation, {
        'data-cy': 'user-location-input',
        ref: ref,
        placeholder: placeholderText,
        value: formattedLocation,
        onChange: onInputChange,
        onKeyDown: onInputKeyDown,
        onBlur: function onBlur(event) {
          return onInputBlur(event, placeholderText);
        },
        onFocus: onInputFocus,
        newFilters: newFilters,
        'aria-label': placeholderText,
        inputTheme: inputTheme
      }),
      suggestions.length > 0 && formattedLocation.length > 0 && visible && _react2.default.createElement(
        LocationSuggestions,
        {
          position: autocompletePosition,
          'data-cy': 'user-location-suggestion-list',
          role: 'group'
        },
        suggestions.map(function (s, index) {
          return _react2.default.createElement(
            Suggestion,
            {
              role: 'button',
              'data-cy': 'user-location-suggestion-list-option',
              onMouseDown: function onMouseDown() {
                setCurrentlySelected(0);
                getInputLocation(s);
              },
              onMouseEnter: function onMouseEnter() {
                return setCurrentlySelected(index);
              },
              inFocus: currentlySelected === index,
              key: s.description,
              'aria-label': s.description
            },
            s.description
          );
        })
      ),
      isLoading && _react2.default.createElement(
        SpinnerWrapper,
        null,
        _react2.default.createElement(_Spinner2.default, { size: 16, colour: colours.primaryBrandColour })
      )
    ),
    displayButtonCTA && _react2.default.createElement(
      GetDirectionsButton,
      { onClick: onClick, 'data-ca-tracking': 'getDirections' },
      _react2.default.createElement('img', { src: 'https://res.cloudinary.com/motortrak/image/upload/v1695830589/locator/genesis/icons/arrow_left.svg' })
    )
  );
};

exports.UserLocation = UserLocation;
exports.default = UserLocation;