'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    display: none;\n  '], ['\n    flex-direction: column;\n    display: none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    align-items: flex-end;\n    display: flex;\n    margin: 0 10px;\n    justify-content: center;\n  '], ['\n    align-items: flex-end;\n    display: flex;\n    margin: 0 10px;\n    justify-content: center;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 15px;\n    max-width: none;\n'], ['\n  ', ';\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 15px;\n    max-width: none;\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['display: flex;'], ['display: flex;']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    width: auto;\n    align-items: center;\n    border-bottom: solid 1px #444444;\n    padding: 8px 24px;\n    margin:0;\n    line-height: 30px;\n  '], ['\n    display: flex;\n    width: auto;\n    align-items: center;\n    border-bottom: solid 1px #444444;\n    padding: 8px 24px;\n    margin:0;\n    line-height: 30px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n  border: none;\n  padding: 2%;\n  &:focus {\n    border: none;\n    outline: none;\n  }\n'], ['\n  border: none;\n  padding: 2%;\n  &:focus {\n    border: none;\n    outline: none;\n  }\n']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: flex-start;\n  '], ['\n    justify-content: flex-start;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%\n  '], ['\n    width: 100%\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SlideOut = require('./SlideOut');

var _SlideOut2 = _interopRequireDefault(_SlideOut);

var _InputChange = require('./InputChange');

var _InputChange2 = _interopRequireDefault(_InputChange);

var _Close = require('./JaguarIcons/Global/Close');

var _Close2 = _interopRequireDefault(_Close);

var _Menu = require('./JaguarIcons/Global/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _ThinRight = require('./JaguarIcons/Arrows/ThinRight');

var _ThinRight2 = _interopRequireDefault(_ThinRight);

var _Search = require('./JaguarIcons/Global/Search');

var _Search2 = _interopRequireDefault(_Search);

var _Global = require('./Global');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _SocialShareButton = require('../modules/Header/SocialShareButton');

var _SocialShareButton2 = _interopRequireDefault(_SocialShareButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MenuBarLinks = _styledComponents2.default.ul.withConfig({
  displayName: 'MenuBar__MenuBarLinks',
  componentId: 'sc-1ayodo5-0'
})(['list-style-type:none;display:flex;width:100%;padding:0;margin:0;height:100%;', ';'], _theme2.default.max.large(_templateObject));

var MenuBarLinksMobile = (0, _styledComponents2.default)(MenuBarLinks).withConfig({
  displayName: 'MenuBar__MenuBarLinksMobile',
  componentId: 'sc-1ayodo5-1'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject2));

var MenuBarListItem = _styledComponents2.default.li.withConfig({
  displayName: 'MenuBar__MenuBarListItem',
  componentId: 'sc-1ayodo5-2'
})(['width:auto;', ';'], function (_ref) {
  var maxWidth = _ref.maxWidth;
  return '' + (maxWidth && 'max-width: ' + maxWidth + 'px');
});

var MenuBarLink = _styledComponents2.default.a.withConfig({
  displayName: 'MenuBar__MenuBarLink',
  componentId: 'sc-1ayodo5-3'
})(['outline:none;text-decoration:none;height:100%;align-items:center;display:flex;background-color:transparent;padding:0 8px;', ';&:hover{', ';}', ';'], function (_ref2) {
  var borderColour = _ref2.borderColour;
  return borderColour && 'border-right: 1px solid ' + borderColour;
}, function (_ref3) {
  var backgroundHoverColour = _ref3.backgroundHoverColour;
  return backgroundHoverColour && 'background-color: ' + backgroundHoverColour.value;
}, _theme2.default.max.large(_templateObject3, function (_ref4) {
  var border = _ref4.border;
  return 'border-bottom: ' + (border ? '' + border : '1px solid #444444');
}));

var Chevron = _styledComponents2.default.div.withConfig({
  displayName: 'MenuBar__Chevron',
  componentId: 'sc-1ayodo5-4'
})(['display:none;cursor:pointer;', ';'], _theme2.default.max.medium(_templateObject4));

var CloseButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MenuBar__CloseButtonWrapper',
  componentId: 'sc-1ayodo5-5'
})(['margin:14px;']);

var MenuBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MenuBar__MenuBarContainer',
  componentId: 'sc-1ayodo5-6'
})(['display:flex;align-items:center;width:100%;height:', 'px;flex-direction:', ';', ';'], function (_ref5) {
  var height = _ref5.height;
  return height;
}, function (_ref6) {
  var align = _ref6.align;
  return align && align === 'left' ? 'row-reverse' : 'unset';
}, function (_ref7) {
  var borderColour = _ref7.borderColour;
  return borderColour && 'border-top: 1px solid ' + borderColour;
});

var SearchContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MenuBar__SearchContainer',
  componentId: 'sc-1ayodo5-7'
})(['width:200px;display:flex;align-items:center;', ';'], _theme2.default.max.large(_templateObject5));

var SearchContainerMobile = (0, _styledComponents2.default)(SearchContainer).withConfig({
  displayName: 'MenuBar__SearchContainerMobile',
  componentId: 'sc-1ayodo5-8'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject6));

var StyledInput = _styledComponents2.default.input.withConfig({
  displayName: 'MenuBar__StyledInput',
  componentId: 'sc-1ayodo5-9'
})(['width:80%;padding-left:5%;margin:0;color:', ';border:none;background-color:', ';text-transform:', ';font-family:', ';font-size:', ';letter-spacing:', ';border-left:', ';&::placeholder{color:', ';}&:focus{border-left:', ';outline:none;}', ';'], function (_ref8) {
  var theme = _ref8.theme;
  return theme.colour;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.backgroundColour;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.textTransform;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.fontFamily;
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.fontSize;
}, function (_ref13) {
  var theme = _ref13.theme;
  return '' + theme.characterSpacing;
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.showSearchInputBorder ? 'solid 1px #ffffff' : 'none';
}, function (_ref15) {
  var theme = _ref15.theme;
  return theme.colour;
}, function (_ref16) {
  var theme = _ref16.theme;
  return theme.showSearchInputBorder ? 'solid 1px #ffffff' : 'none';
}, _theme2.default.max.medium(_templateObject7));

var SearchIconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MenuBar__SearchIconWrapper',
  componentId: 'sc-1ayodo5-10'
})(['display:flex;align-items:center;justify-content:center;cursor:pointer;']);

var IconWithLabelContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MenuBar__IconWithLabelContainer',
  componentId: 'sc-1ayodo5-11'
})(['display:flex;padding:15px 15px 15px 27px;justify-content:center;cursor:pointer;', ';'], _theme2.default.max.medium(_templateObject8));

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'MenuBar__IconWrapper',
  componentId: 'sc-1ayodo5-12'
})(['width:16px;height:16px;']);

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'MenuBar__Select',
  componentId: 'sc-1ayodo5-13'
})(['', ';', ';', ';', ';background-color:transparent;padding-left:8px;border:none;cursor:pointer;-moz-appearance:none;-webkit-appearance:none;&::-ms-expand{display:none;}', ';'], function (_ref17) {
  var font = _ref17.font;
  return '' + (font && font.typeface && 'font-family: ' + font.typeface.value);
}, function (_ref18) {
  var font = _ref18.font;
  return '' + (font && font.colour && 'color: ' + font.colour.value);
}, function (_ref19) {
  var font = _ref19.font;
  return '' + (font && font.transform && 'text-transform: ' + font.transform);
}, function (_ref20) {
  var font = _ref20.font;
  return '' + (font && font.fontSize && 'font-size: ' + font.fontSize + 'px');
}, _theme2.default.max.medium(_templateObject9));

var MaybeSearch = function MaybeSearch(_ref21) {
  var withSearch = _ref21.withSearch,
      Component = _ref21.wrapper,
      onSearch = _ref21.onSearch,
      theme = _ref21.theme,
      placeholder = _ref21.placeholder,
      searchIconAlign = _ref21.searchIconAlign;
  return withSearch && _react2.default.createElement(
    _InputChange2.default,
    null,
    function (_ref22) {
      var value = _ref22.value,
          inputChange = _ref22.inputChange;
      return _react2.default.createElement(
        Component,
        null,
        searchIconAlign === 'left' && _react2.default.createElement(
          SearchIconWrapper,
          { onClick: function onClick() {
              return onSearch(value);
            } },
          _react2.default.createElement(_Search2.default, { colour: theme.iconColour, width: '1.5em', height: '1.5em' })
        ),
        _react2.default.createElement(StyledInput, {
          theme: theme,
          type: 'text',
          placeholder: placeholder,
          onChange: function onChange(e) {
            return inputChange(e.target.value);
          },
          onKeyDown: function onKeyDown(e) {
            return e.key === 'Enter' && onSearch(value);
          }
        }),
        searchIconAlign === 'right' && _react2.default.createElement(
          SearchIconWrapper,
          { onClick: function onClick() {
              return onSearch(value);
            } },
          _react2.default.createElement(_Search2.default, { colour: theme.iconColour, width: '1.5em', height: '1.5em' })
        )
      );
    }
  );
};

var renderTitle = function renderTitle(colour) {
  return function () {
    return _react2.default.createElement(_Menu2.default, { width: '1.5em', height: '1.5em', colour: colour });
  };
};
var renderClose = function renderClose(colour) {
  return function () {
    return _react2.default.createElement(
      CloseButtonWrapper,
      null,
      _react2.default.createElement(_Close2.default, { width: '1em', height: '1em', colour: colour })
    );
  };
};

var isExternal = function isExternal(href) {
  return href.match(/^http/);
};

var RenderLinks = function RenderLinks(_ref23) {
  var _ref23$theme = _ref23.theme,
      font = _ref23$theme.font,
      hoverColour = _ref23$theme.hoverColour,
      backgroundHoverColour = _ref23$theme.backgroundHoverColour,
      underLineColor = _ref23$theme.underLineColor,
      preview = _ref23.preview,
      items = _ref23.items,
      keyPrefix = _ref23.keyPrefix,
      navigateIfInternal = _ref23.navigateIfInternal,
      borderColour = _ref23.borderColour,
      burgerMenuItemBorder = _ref23.burgerMenuItemBorder,
      socialShareEnabled = _ref23.socialShareEnabled,
      utilityBarLanguageEnabled = _ref23.utilityBarLanguageEnabled,
      utilityBarCurrencyEnabled = _ref23.utilityBarCurrencyEnabled,
      socialIcon = _ref23.socialIcon,
      languageIcon = _ref23.languageIcon,
      currencyIcon = _ref23.currencyIcon,
      socialShareProps = _ref23.socialShareProps,
      languageValues = _ref23.languageValues,
      languageActions = _ref23.languageActions,
      currencyValues = _ref23.currencyValues,
      currencyActions = _ref23.currencyActions,
      burgerMenuLanguageFont = _ref23.burgerMenuLanguageFont,
      hideChevronOnMobile = _ref23.hideChevronOnMobile,
      languageChevron = _ref23.languageChevron,
      currentLanguage = _ref23.currentLanguage,
      currentCurrency = _ref23.currentCurrency,
      maxWidth = _ref23.maxWidth,
      config = _ref23.config,
      toggle = _ref23.toggle;
  return _react2.default.createElement(
    _react.Fragment,
    null,
    items.map(function (_ref24) {
      var label = _ref24.label,
          link = _ref24.link,
          navId = _ref24.navId,
          analyticsClassName = _ref24.analyticsClassName;
      return _react2.default.createElement(
        MenuBarListItem,
        { key: keyPrefix + ' - ' + navId, maxWidth: maxWidth },
        _react2.default.createElement(
          MenuBarLink,
          {
            className: analyticsClassName || '',
            borderColour: borderColour,
            href: link,
            'data-cy': 'menubar-link-' + analyticsClassName,
            target: isExternal(link) ? '_blank' : '_self',
            backgroundHoverColour: backgroundHoverColour,
            onClick: function onClick(event) {
              if (preview || navigateIfInternal(link)) {
                event.preventDefault();
                if (toggle) {
                  toggle();
                }
              }
            },
            border: burgerMenuItemBorder
          },
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              className: analyticsClassName || '',
              styleOverride: function styleOverride() {
                return '\n          ' + (0, _Global.fontStyleOverride)(font) + '\n          margin:0;\n          max-width: 220px;\n          ' + (underLineColor && '\n            &:after {\n              display:block;\n              content: \'\';\n              height: 1px;\n              margin-top: 4px;\n              box-sizing: border-box;\n              ' + (underLineColor && 'background-color: ' + underLineColor.value) + ';\n              transform: scaleX(0);\n              transition: transform 250ms ease-in-out;\n            }\n          ') + ';\n          ' + (underLineColor && hoverColour && '\n            &:hover {\n              ' + (hoverColour && 'color: ' + hoverColour.value) + ';\n              &:after { transform: scaleX(1); }\n            }\n         ') + ';\n         ' + (underLineColor && !hoverColour && '\n            &:hover {\n              &:after { transform: scaleX(1); }\n            }\n         ') + ';\n         ' + (hoverColour && !underLineColor && '\n            &:hover {\n              ' + (hoverColour && 'color: ' + hoverColour.value) + ';\n            }\n          ') + ';\n         ';
              },
              mobileStyleOverride: function mobileStyleOverride() {
                return [{
                  queryPath: 'max.medium',
                  template: 'text-align: left;'
                }];
              }
            },
            label
          ),
          hideChevronOnMobile ? null : _react2.default.createElement(
            Chevron,
            null,
            _react2.default.createElement(_ThinRight2.default, { colour: font && font.colour && font.colour.value })
          )
        )
      );
    }),
    utilityBarLanguageEnabled && _react2.default.createElement(
      IconWithLabelContainer,
      null,
      _react2.default.createElement(IconWrapper, { src: languageIcon, alt: 'drop down icon' }),
      _react2.default.createElement(
        Select,
        {
          onChange: function onChange(e) {
            return languageActions({ value: e.target.value });
          },
          font: burgerMenuLanguageFont,
          defaultValue: currentLanguage
        },
        languageValues.options.map(function (item, index) {
          return [_react2.default.createElement(
            'option',
            { key: index.toString(), value: item.value },
            languageValues.prefixLabel + item.label
          )];
        })
      ),
      _react2.default.createElement(IconWrapper, { src: languageChevron, alt: 'language chevron icon' })
    ),
    utilityBarCurrencyEnabled && _react2.default.createElement(
      IconWithLabelContainer,
      null,
      _react2.default.createElement(IconWrapper, { src: currencyIcon, alt: '' }),
      _react2.default.createElement(
        Select,
        {
          onChange: function onChange(e) {
            return currencyActions({ value: e.target.value });
          },
          font: burgerMenuLanguageFont,
          defaultValue: currentCurrency
        },
        currencyValues.options.map(function (item, index) {
          return [_react2.default.createElement(
            'option',
            { key: index.toString(), value: item.value },
            currencyValues.prefixLabel + item.label
          )];
        })
      ),
      _react2.default.createElement(IconWrapper, { src: languageChevron, alt: '' })
    ),
    socialShareEnabled && _react2.default.createElement(
      IconWithLabelContainer,
      null,
      _react2.default.createElement(_SocialShareButton2.default, {
        socialShare: socialShareProps,
        socialShareIcon: socialIcon,
        config: config
      })
    )
  );
};

var MenuBar = function (_React$Component) {
  (0, _inherits3.default)(MenuBar, _React$Component);

  function MenuBar(props) {
    (0, _classCallCheck3.default)(this, MenuBar);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MenuBar.__proto__ || (0, _getPrototypeOf2.default)(MenuBar)).call(this, props));

    Object.defineProperty(_this, 'toggle', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.setState(function (prevState) {
          return { collapsed: !prevState.collapsed };
        });
      }
    });

    _this.state = {
      collapsed: true
    };
    return _this;
  }

  (0, _createClass3.default)(MenuBar, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          _props$items = _props.items,
          items = _props$items === undefined ? [] : _props$items,
          preview = _props.preview,
          theme = _props.theme,
          backgroundColour = _props.backgroundColour,
          borderColour = _props.borderColour,
          navigateIfInternal = _props.navigateIfInternal,
          _props$withSearch = _props.withSearch,
          withSearch = _props$withSearch === undefined ? false : _props$withSearch,
          _props$onSearch = _props.onSearch,
          onSearch = _props$onSearch === undefined ? function () {} : _props$onSearch,
          searchBarTheme = _props.searchBarTheme,
          searchBarAlign = _props.searchBarAlign,
          height = _props.height,
          placeholder = _props.placeholder,
          searchIconAlign = _props.searchIconAlign,
          mobileMenuIconsColour = _props.mobileMenuIconsColour,
          mobileMenuIconsColourClose = _props.mobileMenuIconsColourClose,
          burgerMenuItemBorder = _props.burgerMenuItemBorder,
          socialShareEnabled = _props.socialShareEnabled,
          utilityBarLanguageEnabled = _props.utilityBarLanguageEnabled,
          utilityBarCurrencyEnabled = _props.utilityBarCurrencyEnabled,
          socialIcon = _props.socialIcon,
          languageIcon = _props.languageIcon,
          currencyIcon = _props.currencyIcon,
          socialShareProps = _props.socialShareProps,
          utilityBarOptions = _props.utilityBarOptions,
          languageActions = _props.languageActions,
          currencyActions = _props.currencyActions,
          burgerMenuLanguageFont = _props.burgerMenuLanguageFont,
          hideChevronOnMobile = _props.hideChevronOnMobile,
          languageChevron = _props.languageChevron,
          currentLanguage = _props.currentLanguage,
          currentCurrency = _props.currentCurrency,
          maxWidth = _props.maxWidth,
          config = _props.config;

      var languageValues = utilityBarOptions && utilityBarOptions.find(function (f) {
        return f.key === 'Language';
      });
      var currencyValues = utilityBarOptions && utilityBarOptions.find(function (f) {
        return f.key === 'Currency';
      });
      return _react2.default.createElement(
        MenuBarContainer,
        {
          height: height,
          align: searchBarAlign,
          borderColour: borderColour
        },
        _react2.default.createElement(
          MenuBarLinks,
          null,
          _react2.default.createElement(RenderLinks, {
            items: items,
            theme: theme,
            preview: preview,
            keyPrefix: 'Large',
            navigateIfInternal: navigateIfInternal,
            borderColour: borderColour,
            burgerMenuItemBorder: burgerMenuItemBorder,
            burgerMenuLanguageFont: burgerMenuLanguageFont,
            maxWidth: maxWidth,
            config: config
          })
        ),
        _react2.default.createElement(
          MenuBarLinksMobile,
          null,
          _react2.default.createElement(
            _SlideOut2.default,
            {
              renderTitle: renderTitle(mobileMenuIconsColour),
              renderClose: renderClose(mobileMenuIconsColourClose && mobileMenuIconsColourClose || mobileMenuIconsColour),
              backgroundColour: backgroundColour,
              collapsed: this.state.collapsed,
              toggle: this.toggle
            },
            _react2.default.createElement(MaybeSearch, {
              theme: searchBarTheme,
              withSearch: withSearch,
              wrapper: SearchContainerMobile,
              onSearch: function (_onSearch) {
                function onSearch(_x) {
                  return _onSearch.apply(this, arguments);
                }

                onSearch.toString = function () {
                  return _onSearch.toString();
                };

                return onSearch;
              }(function (value) {
                return onSearch(value);
              }),
              placeholder: placeholder,
              searchIconAlign: searchIconAlign
            }),
            _react2.default.createElement(RenderLinks, {
              items: items,
              theme: theme,
              preview: preview,
              keyPrefix: 'Small',
              navigateIfInternal: navigateIfInternal,
              burgerMenuItemBorder: burgerMenuItemBorder,
              socialShareEnabled: socialShareEnabled,
              utilityBarLanguageEnabled: utilityBarLanguageEnabled,
              utilityBarCurrencyEnabled: utilityBarCurrencyEnabled,
              socialIcon: socialIcon,
              languageIcon: languageIcon,
              currencyIcon: currencyIcon,
              socialShareProps: socialShareProps,
              languageValues: languageValues,
              languageActions: languageActions,
              currencyValues: currencyValues,
              currencyActions: currencyActions,
              burgerMenuLanguageFont: burgerMenuLanguageFont,
              hideChevronOnMobile: hideChevronOnMobile,
              languageChevron: languageChevron,
              currentLanguage: currentLanguage,
              currentCurrency: currentCurrency,
              config: config,
              toggle: this.toggle
            })
          )
        ),
        _react2.default.createElement(MaybeSearch, {
          theme: searchBarTheme,
          withSearch: withSearch,
          wrapper: SearchContainer,
          onSearch: function (_onSearch2) {
            function onSearch(_x2) {
              return _onSearch2.apply(this, arguments);
            }

            onSearch.toString = function () {
              return _onSearch2.toString();
            };

            return onSearch;
          }(function (value) {
            return onSearch(value);
          }),
          placeholder: placeholder,
          searchIconAlign: searchIconAlign
        })
      );
    }
  }]);
  return MenuBar;
}(_react2.default.Component);

exports.default = MenuBar;