'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-wrap: wrap;\n  '], ['\n    flex-wrap: wrap;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    margin-bottom: 2px;\n  '], ['\n    width: 100%;\n    margin-bottom: 2px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'TabContainer'
}).withConfig({
  displayName: 'Tabs__Container',
  componentId: 'sc-1b6kuzo-0'
})(['width:100%;']);
var TabContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Tabs__TabContainer',
  componentId: 'sc-1b6kuzo-1'
})(['width:100%;display:flex;', ' align-items:Center;justify-content:center;padding:33px 0;'], _theme2.default.max.small(_templateObject));
var Tab = _styledComponents2.default.button.withConfig({
  displayName: 'Tabs__Tab',
  componentId: 'sc-1b6kuzo-2'
})(['border:0;background-color:', ';color:', ';font-weight:600;font-size:13px;outline:none;margin-right:1px;', ' min-width:100px;text-transform:uppercase;padding:10px 15px;cursor:pointer;'], function (props) {
  return props.active ? '#474747' : '#f0f0f0';
}, function (props) {
  return props.active ? '#fff' : '#444';
}, _theme2.default.max.small(_templateObject2));

var TabComponent = _styledComponents2.default.div.withConfig({
  displayName: 'Tabs__TabComponent',
  componentId: 'sc-1b6kuzo-3'
})(['display:', ';'], function (props) {
  return props.active ? 'block' : 'none';
});

var Tabs = function (_Component) {
  (0, _inherits3.default)(Tabs, _Component);

  function Tabs(props) {
    (0, _classCallCheck3.default)(this, Tabs);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Tabs.__proto__ || (0, _getPrototypeOf2.default)(Tabs)).call(this, props));

    Object.defineProperty(_this, 'onTabClick', {
      enumerable: true,
      writable: true,
      value: function value(activeTab) {
        return _this.setState(function () {
          return { activeTab: activeTab };
        });
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var tabs = _this.props.tabs;
        var activeTab = _this.state.activeTab;

        return _react2.default.createElement(
          Container,
          null,
          _react2.default.createElement(
            TabContainer,
            null,
            tabs.map(function (tab) {
              return _react2.default.createElement(
                Tab,
                {
                  key: tab.title,
                  onClick: function onClick() {
                    return _this.onTabClick(tab);
                  },
                  active: tab.title === activeTab.title
                },
                _react2.default.createElement(
                  _Global.HeadingTwo,
                  {
                    styleOverride: function styleOverride() {
                      return '\n                  margin: 0;\n                  font-size: 13px;\n                  background-color: ' + (tab.title === activeTab.title ? '#474747' : '#f0f0f0') + ';\n                  color: ' + (tab.title === activeTab.title ? '#fff' : '#444') + ';\n                ';
                    }
                  },
                  tab.title
                )
              );
            })
          ),
          _react2.default.createElement(
            'div',
            null,
            tabs.map(function (tab) {
              return _react2.default.createElement(
                TabComponent,
                {
                  key: tab.title,
                  active: tab.title === activeTab.title
                },
                activeTab.component
              );
            })
          )
        );
      }
    });


    _this.state = {
      activeTab: props.tabs[0]
    };
    return _this;
  }

  return Tabs;
}(_react.Component);

exports.default = Tabs;