'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.constants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends7 = require('babel-runtime/helpers/extends');

var _extends8 = _interopRequireDefault(_extends7);

exports.reducer = reducer;

var _helper = require('./helper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  TOGGLE_CHECKBOX_FILTER: 'SearchFilters:ToggleCheckbox',
  SELECT_MULTIPLE_CHECKBOX_FILTERS: 'SearchFilters:SelectMultipleCheckboxes',
  CLEAR_CHECKBOX_SECTION: 'SearchFilters:ClearCheckboxSection',
  CHANGE_RANGE_FILTER: 'SearchFilters:ChangeRangeFilter',
  CHANGE_LOCATION: 'SearchFilters:ChangeLocation',
  CHANGE_MAX_DISTANCE: 'SearchFilters:MaxDistance',
  RESET_FILTERS: 'SearchFilters:ResetFilters',
  GET_SEARCH_FILTERS: 'SearchFilters:GetAvailableFilters',
  GET_SEARCH_FILTERS_SUCCESS: 'SearchFilters:GetAvailableFiltersSuccess',
  GET_ALL_SEARCH_FILTERS: 'SearchFilters:GetAllFilters',
  GET_ALL_SEARCH_FILTERS_SUCCESS: 'SearchFilters:GetAllFiltersSuccess',
  FILTER_BY_MODEL_RANGE: 'SearchFilters:FilterByModelRange',
  REMOVE_RANGE_FILTER: 'SearchFilters:RemoveRangeFilter',
  UPDATE_QUERY: 'SearchFilters:UpdateQuery'
};

var actions = exports.actions = {
  toggleCheckboxFilter: function toggleCheckboxFilter(payload) {
    return {
      type: constants.TOGGLE_CHECKBOX_FILTER,
      payload: payload
    };
  },
  selectMultipleCheckboxes: function selectMultipleCheckboxes(payload) {
    return {
      type: constants.SELECT_MULTIPLE_CHECKBOX_FILTERS,
      payload: payload
    };
  },
  clearCheckboxSection: function clearCheckboxSection(payload) {
    return {
      type: constants.CLEAR_CHECKBOX_SECTION,
      payload: payload
    };
  },
  changeRangeFilter: function changeRangeFilter(payload) {
    return {
      type: constants.CHANGE_RANGE_FILTER,
      payload: payload
    };
  },
  changeLocation: function changeLocation(payload) {
    return { type: constants.CHANGE_LOCATION, payload: payload };
  },
  changeMaxDistance: function changeMaxDistance(payload) {
    return {
      type: constants.CHANGE_MAX_DISTANCE,
      payload: payload
    };
  },
  resetFilters: function resetFilters(payload) {
    return { type: constants.RESET_FILTERS, payload: payload };
  },
  resetRetailer: function resetRetailer(payload) {
    return { type: constants.RESET_RETAILER, payload: payload };
  },
  getSearchFilters: function getSearchFilters(payload) {
    return {
      type: constants.GET_SEARCH_FILTERS,
      payload: payload
    };
  },
  getSearchFiltersSuccess: function getSearchFiltersSuccess(payload) {
    return {
      type: constants.GET_SEARCH_FILTERS_SUCCESS,
      payload: payload
    };
  },
  getAllSearchFilters: function getAllSearchFilters(payload) {
    return {
      type: constants.GET_ALL_SEARCH_FILTERS,
      payload: payload
    };
  },
  getAllSearchFiltersSuccess: function getAllSearchFiltersSuccess(payload) {
    return {
      type: constants.GET_ALL_SEARCH_FILTERS_SUCCESS,
      payload: payload
    };
  },
  filterByModelRange: function filterByModelRange(payload) {
    return {
      type: constants.FILTER_BY_MODEL_RANGE,
      payload: payload
    };
  },
  removeRangeFilter: function removeRangeFilter(payload) {
    return {
      type: constants.REMOVE_RANGE_FILTER,
      payload: payload
    };
  },
  updateQuery: function updateQuery() {
    return {
      type: constants.UPDATE_QUERY
    };
  }
};

var initialState = exports.initialState = {
  selectedCheckboxFilters: {
    models: [],
    variants: [],
    engine: [],
    engineSizes: [],
    transmissions: [],
    bodyStyles: [],
    exteriorColours: [],
    interiorShades: [],
    features: []
  },
  selectedRangeFilters: {},
  location: {},
  searchFilters: {
    loaded: false,
    loadingAllFilters: false,
    loadingAvailableFilters: false,
    allFilters: {},
    availableFilters: {}
  },
  searchUrl: '',
  modelSearchUrl: '',
  filterSearchUrl: '',
  retailer: '',
  resetRangeCount: 0
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var payload = action.payload,
      type = action.type;
  var selectedCheckboxFilters = state.selectedCheckboxFilters;

  switch (type) {
    case constants.FILTER_BY_RETAILER:
      return (0, _extends8.default)({}, initialState, {
        retailer: payload
      });
    case constants.SELECT_MULTIPLE_CHECKBOX_FILTERS:
      {
        var section = payload.section,
            values = payload.values;

        var selected = [].concat((0, _toConsumableArray3.default)(values));
        var updatedFilters = (0, _extends8.default)({}, selectedCheckboxFilters, (0, _defineProperty3.default)({}, section, selected));
        return (0, _extends8.default)({}, state, {
          selectedCheckboxFilters: updatedFilters,
          searchUrl: (0, _extends8.default)({}, state.searchUrl, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)(updatedFilters)
          }),
          modelSearchUrl: (0, _extends8.default)({}, state.modelSearchUrl, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)((0, _extends8.default)({}, updatedFilters, {
              models: []
            }))
          }),
          filterSearchUrl: (0, _extends8.default)({}, state.filterSearchUrl, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)({
              models: updatedFilters.models
            })
          })
        });
      }
    case constants.FILTER_BY_MODEL_RANGE:
    case constants.TOGGLE_CHECKBOX_FILTER:
      {
        var _section = payload.section,
            filter = payload.filter;

        var filterGroup = selectedCheckboxFilters[_section] || [];
        var isSelected = filterGroup.includes(filter);
        var _selected = isSelected ? filterGroup.filter(function (f) {
          return f !== filter;
        }) : [].concat((0, _toConsumableArray3.default)(filterGroup), [filter]);
        var _updatedFilters = (0, _extends8.default)({}, selectedCheckboxFilters, (0, _defineProperty3.default)({}, _section, _selected));
        return (0, _extends8.default)({}, state, {
          selectedCheckboxFilters: _updatedFilters,
          searchUrl: (0, _extends8.default)({}, state.searchUrl, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)(_updatedFilters)
          }),
          modelSearchUrl: (0, _extends8.default)({}, state.modelSearchUrl, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)((0, _extends8.default)({}, _updatedFilters, {
              models: []
            }))
          }),
          filterSearchUrl: (0, _extends8.default)({}, state.filterSearchUrl, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)({
              models: _updatedFilters.models
            })
          })
        });
      }
    case constants.CLEAR_CHECKBOX_SECTION:
      {
        var _section2 = payload.section;

        var _updatedFilters2 = (0, _extends8.default)({}, state.selectedCheckboxFilters, (0, _defineProperty3.default)({}, _section2, []));
        var checkboxUrl = (0, _helper.generateCheckboxFilterUrl)(_updatedFilters2);
        var filterCheckBoxUrl = (0, _helper.generateCheckboxFilterUrl)({
          models: _updatedFilters2.models
        });
        return (0, _extends8.default)({}, state, {
          selectedCheckboxFilters: _updatedFilters2,
          searchUrl: (0, _extends8.default)({}, state.searchUrl, { checkboxUrl: checkboxUrl }),
          modelSearchUrl: (0, _extends8.default)({}, state.modelSearchUrl, { checkboxUrl: checkboxUrl }),
          filterSearchUrl: (0, _extends8.default)({}, state.filterSearchUrl, {
            checkboxUrl: filterCheckBoxUrl
          })
        });
      }
    case constants.CHANGE_RANGE_FILTER:
      {
        var id = payload.id,
            _values = payload.values;

        var selectedRanges = (0, _extends8.default)({}, state.selectedRangeFilters, (0, _defineProperty3.default)({}, id, { min: _values[0], max: _values[1], active: true }));
        return (0, _extends8.default)({}, state, {
          selectedRangeFilters: selectedRanges,
          searchUrl: (0, _extends8.default)({}, state.searchUrl, {
            rangeUrl: (0, _helper.generateRangeUrl)(selectedRanges)
          }),
          modelSearchUrl: (0, _extends8.default)({}, state.modelSearchUrl, {
            rangeUrl: (0, _helper.generateRangeUrl)(selectedRanges)
          }),
          filterSearchUrl: (0, _extends8.default)({}, state.filterSearchUrl, {
            rangeUrl: (0, _helper.generateRangeUrl)(selectedRanges)
          })
        });
      }
    case constants.REMOVE_RANGE_FILTER:
      {
        var _id = payload.id;

        var _selectedRanges = (0, _extends8.default)({}, state.selectedRangeFilters, (0, _defineProperty3.default)({}, _id, (0, _extends8.default)({}, state.selectedRangeFilters[_id], { active: false })));
        return (0, _extends8.default)({}, state, state.selectedRangeFilters[_id] ? {
          selectedRangeFilters: _selectedRanges,
          searchUrl: (0, _extends8.default)({}, state.searchUrl, {
            rangeUrl: (0, _helper.generateRangeUrl)(_selectedRanges)
          }),
          modelSearchUrl: (0, _extends8.default)({}, state.modelSearchUrl, {
            rangeUrl: (0, _helper.generateRangeUrl)(_selectedRanges)
          }),
          filterSearchUrl: (0, _extends8.default)({}, state.filterSearchUrl, {
            rangeUrl: (0, _helper.generateRangeUrl)(_selectedRanges)
          })
        } : {});
      }
    case constants.CHANGE_LOCATION:
      {
        var location = payload.location;

        return (0, _extends8.default)({}, state, {
          location: location,
          searchUrl: (0, _extends8.default)({}, state.searchUrl, {
            locationUrl: (0, _helper.generateLocationUrl)(location)
          }),
          modelSearchUrl: (0, _extends8.default)({}, state.modelSearchUrl, {
            locationUrl: (0, _helper.generateLocationUrl)(location)
          }),
          filterSearchUrl: (0, _extends8.default)({}, state.filterSearchUrl, {
            rangeUrl: (0, _helper.generateLocationUrl)(location)
          })
        });
      }
    case constants.CHANGE_MAX_DISTANCE:
      {
        var maxDistance = payload.maxDistance;

        return (0, _extends8.default)({}, state, {
          maxDistance: maxDistance,
          searchUrl: (0, _extends8.default)({}, state.searchUrl, {
            maxDistanceUrl: (0, _helper.generateMaxDistanceUrl)(maxDistance)
          }),
          modelSearchUrl: (0, _extends8.default)({}, state.modelSearchUrl, {
            maxDistanceUrl: (0, _helper.generateMaxDistanceUrl)(maxDistance)
          }),
          filterSearchUrl: (0, _extends8.default)({}, state.filterSearchUrl, {
            maxDistanceUrl: (0, _helper.generateMaxDistanceUrl)(maxDistance)
          })
        });
      }
    case constants.RESET_FILTERS:
      {
        var allFilters = state.searchFilters.allFilters;

        return (0, _extends8.default)({}, initialState, {
          searchFilters: (0, _extends8.default)({}, state.searchFilters, {
            availableFilters: allFilters,
            resetRangeCount: Math.random()
          })
        });
      }
    case constants.GET_SEARCH_FILTERS:
      {
        return (0, _extends8.default)({}, state, {
          searchFilters: (0, _extends8.default)({}, state.searchFilters, {
            loadingAvailableFilters: true
          })
        });
      }
    case constants.GET_SEARCH_FILTERS_SUCCESS:
      {
        var filters = action.payload.filters;

        return (0, _extends8.default)({}, state, {
          searchFilters: (0, _extends8.default)({}, state.searchFilters, {
            availableFilters: filters,
            loadingAvailableFilters: false
          })
        });
      }
    case constants.GET_ALL_SEARCH_FILTERS:
      {
        return (0, _extends8.default)({}, state, {
          searchFilters: (0, _extends8.default)({}, state.searchFilters, { loadingAllFilters: true })
        });
      }
    case constants.GET_ALL_SEARCH_FILTERS_SUCCESS:
      {
        var _allFilters = action.payload.allFilters;

        return (0, _extends8.default)({}, state, {
          searchFilters: {
            allFilters: _allFilters,
            availableFilters: _allFilters,
            loadingAllFilters: false,
            loaded: true
          }
        });
      }
    default:
      return state;
  }
}