'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledButton = _styledComponents2.default.button.withConfig({
  displayName: 'CollapsableMarkdown__StyledButton',
  componentId: 'sc-1fdrla0-0'
})(['background:none !important;border:none;padding:0 !important;cursor:pointer;font-size:14px;text-decoration:underline;font-weight:bold;margin-bottom:47px;']);

var ScrollableDiv = _styledComponents2.default.div.withConfig({
  displayName: 'CollapsableMarkdown__ScrollableDiv',
  componentId: 'sc-1fdrla0-1'
})(['font-size:14px;overflow-x:hidden;overflow-y:scroll;max-height:450px;border-bottom:1px solid #9b9b9b;margin-bottom:25px;width:100%;']);

var StyledDiv = _styledComponents2.default.div.withConfig({
  displayName: 'CollapsableMarkdown__StyledDiv',
  componentId: 'sc-1fdrla0-2'
})(['font-size:14px;width:100%;']);

var CollapsableMarkdown = function CollapsableMarkdown(_ref) {
  var translations = _ref.translations,
      config = _ref.config;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var LinkRenderer = function LinkRenderer(props) {
    return _react2.default.createElement(
      'a',
      { href: props.href, target: '_blank', rel: 'noopener noreferrer' },
      props.children
    );
  };

  return open ? _react2.default.createElement(
    ScrollableDiv,
    null,
    _react2.default.createElement(_reactMarkdown2.default, {
      source: translations[config.topText],
      renderers: { link: LinkRenderer }
    }),
    _react2.default.createElement(_reactMarkdown2.default, {
      source: translations[config.expandedText],
      renderers: { link: LinkRenderer }
    })
  ) : _react2.default.createElement(
    StyledDiv,
    null,
    _react2.default.createElement(_reactMarkdown2.default, {
      source: translations[config.topText],
      renderers: { link: LinkRenderer }
    }),
    translations[config.expandedText] && _react2.default.createElement(
      StyledButton,
      { onClick: function onClick() {
          return setOpen(true);
        } },
      translations[config.moreTextLabel]
    )
  );
};

exports.default = CollapsableMarkdown;