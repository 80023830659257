'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _VideoImageTypes = require('../../../types/Lamborghini/VideoImageTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VideoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VideoImage__VideoContainer',
  componentId: 'sc-1jzouz4-0'
})(['display:flex;position:relative;']);

var PlayButton = _styledComponents2.default.button.withConfig({
  displayName: 'VideoImage__PlayButton',
  componentId: 'sc-1jzouz4-1'
})(['position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);-webkit-transform:translate(-50%,-50%);background:rgba(0,0,0,0);border:none;z-index:1;cursor:pointer;']);

var Video = _styledComponents2.default.video.withConfig({
  displayName: 'VideoImage__Video',
  componentId: 'sc-1jzouz4-2'
})(['width:100%;object-fit:cover;height:100%;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VideoImage__Image',
  componentId: 'sc-1jzouz4-3'
})(['width:100%;height:100%;']);

var VideoImagePage = function VideoImagePage(_ref) {
  var config = _ref.config;

  var isMobile = (0, _useCheckIsMobileScreen2.default)();
  var image = isMobile ? config.mobileImageUrl : config.imageUrl;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      videoPlaying = _useState2[0],
      setVideoPlaying = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      hover = _useState4[0],
      setHover = _useState4[1];

  var videoRef = (0, _react.useRef)();

  var handlePlayVideo = function handlePlayVideo() {
    if (videoRef.current) {
      videoRef.current && videoRef.current.play();
      setVideoPlaying(true);
      videoRef.current.controls = true;
    }
  };

  var handleVideoPaused = function handleVideoPaused() {
    if (videoRef.current && !videoRef.current.seeking) {
      setVideoPlaying(false);
    }
  };

  return config.videoUrl ? _react2.default.createElement(
    VideoContainer,
    null,
    !videoPlaying && _react2.default.createElement(
      PlayButton,
      { type: 'button', id: 'playVideo', onClick: handlePlayVideo },
      _react2.default.createElement('img', {
        src: hover ? 'https://res.cloudinary.com/motortrak/image/upload/v1706183417/locator/lamborghini/icons/play-active.svg' : 'https://res.cloudinary.com/motortrak/image/upload/v1706183417/locator/lamborghini/icons/play-inactive.svg',
        alt: 'play',
        onMouseOver: function onMouseOver() {
          return setHover(true);
        },
        onMouseOut: function onMouseOut() {
          return setHover(false);
        }
      })
    ),
    _react2.default.createElement(
      Video,
      {
        poster: image,
        ref: videoRef,
        onPause: handleVideoPaused,
        onPlay: handlePlayVideo
      },
      _react2.default.createElement('source', { src: config.videoUrl, type: 'video/mp4' })
    )
  ) : _react2.default.createElement(
    VideoContainer,
    null,
    _react2.default.createElement(Image, { src: image, alt: config.translations.altText })
  );
};
exports.default = VideoImagePage;