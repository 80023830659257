'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _MyAccount = require('../../components/MyAccount/MyAccount');

var _MyAccount2 = _interopRequireDefault(_MyAccount);

var _SubNavBar = require('../../components/SubNavBar');

var _SubNavBar2 = _interopRequireDefault(_SubNavBar);

var _router = require('../../actions/router');

var _reducer = require('../../shared/CustomerLogin/reducer');

var _vehicle = require('../../helpers/vehicle');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MyAccount = function (_Component) {
  (0, _inherits3.default)(MyAccount, _Component);

  function MyAccount() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, MyAccount);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = MyAccount.__proto__ || (0, _getPrototypeOf2.default)(MyAccount)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        // We call init to force a first run of the reducer
        // - there used to be a more generic solution,
        //   but it broke the builder
        var _this$props = _this.props,
            dispatch = _this$props.dispatch,
            shared = _this$props.shared,
            _this$props$actions = _this$props.actions,
            init = _this$props$actions.init,
            loadTestDrives = _this$props$actions.loadTestDrives,
            loadMyDeals = _this$props$actions.loadMyDeals,
            signOut = _this$props$actions.signOut;

        if ((0, _reducer.isTokenValid)(shared)) {
          init();
          loadTestDrives();
          loadMyDeals();
        } else {
          dispatch(_router.actions.navigate('/signin'));
          dispatch(signOut());
        }
      }
    }), Object.defineProperty(_this, 'componentWillUnmount', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.props.actions.reset();
      }
    }), Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props2 = _this.props,
            _this$props2$state = _this$props2.state,
            context = _this$props2$state.context,
            detailsFeedback = _this$props2$state.detailsFeedback,
            testDrives = _this$props2$state.testDrives,
            myDeals = _this$props2$state.myDeals,
            printing = _this$props2$state.printing,
            config = _this$props2.config,
            dispatch = _this$props2.dispatch,
            actions = _this$props2.actions,
            history = _this$props2.history,
            preview = _this$props2.preview,
            shared = _this$props2.shared,
            pathByModule = _this$props2.pathByModule,
            featureFlags = _this$props2.featureFlags,
            globalStyling = _this$props2.globalStyling,
            marketInfo = _this$props2.marketInfo,
            globalFinance = _this$props2.finance;

        var fnIfNotPreview = function fnIfNotPreview(fn) {
          return preview ? function () {} : fn;
        };
        var userDetails = preview ? { firstName: 'NAME' } : (0, _reducer.getCustomerDetails)(shared) || {};
        var links = [{
          text: config.translations.myAccountMyAccountLabel,
          onClick: fnIfNotPreview(function () {
            return actions.changeAccountView('Account');
          }),
          availableOnMobile: true,
          visible: true,
          path: 'Account'
        }, {
          text: config.translations.myAccountDetailsLabel,
          onClick: fnIfNotPreview(function () {
            return actions.changeAccountView('Details');
          }),
          availableOnMobile: true,
          visible: true,
          path: 'Details'
        }, {
          text: config.translations.myAccountDealsLabel,
          onClick: fnIfNotPreview(function () {
            return actions.changeAccountView('Deals');
          }),
          availableOnMobile: true,
          visible: true,
          path: 'Deals'
        }, {
          text: config.translations.myAccountTestDrivesLabel,
          onClick: fnIfNotPreview(function () {
            return actions.changeAccountView('TestDrives');
          }),
          availableOnMobile: true,
          visible: featureFlags.testDrive,
          path: 'TestDrives'
        }];
        return context ? _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(_SubNavBar2.default, {
            onBackButtonClick: history.goBack,
            links: links.filter(function (l) {
              return l.visible;
            }),
            title: config.translations.myAccount,
            subNavBGColour: config.subNavBGColour,
            subNavBorderColour: config.subNavBorderColour,
            subNavChevronColour: config.subNavChevronColour,
            subNavFontTheme: config.subNavFont,
            selected: links.find(function (link) {
              return link.path === context;
            }),
            subNavBarItemSelectedBackgroundColour: config.subNavBarItemSelectedBackgroundColour,
            globalStyling: globalStyling,
            subNavBarItemSelectedFontColour: config.subNavBarItemSelectedFontColour
          }),
          _react2.default.createElement(_MyAccount2.default, {
            finance: globalFinance,
            globalStyling: globalStyling,
            featureFlags: featureFlags,
            translations: config.translations,
            iconColour: config.iconColour && config.iconColour.value,
            context: context,
            changeAccountView: function changeAccountView(view) {
              return fnIfNotPreview(function () {
                return actions.changeAccountView(view);
              });
            },
            userDetails: userDetails,
            saveUserDetails: function saveUserDetails(uDetails) {
              return fnIfNotPreview(function () {
                return actions.saveUserDetails(uDetails);
              });
            },
            changePassword: function changePassword(passwords) {
              return fnIfNotPreview(function () {
                return actions.changePassword(passwords);
              });
            },
            testDrives: testDrives,
            myDeals: myDeals,
            deleteMyDeal: fnIfNotPreview(function (vehicle) {
              return actions.deleteMyDeal(vehicle.id);
            }),
            printMyDeal: function printMyDeal(finance) {
              return actions.printMyDeal(finance);
            },
            onDeleteTestDriveClick: fnIfNotPreview(function (vehicle) {
              return actions.deleteTestDrive(vehicle.vin);
            }),
            onContactClick: function onContactClick(vehicle) {
              return fnIfNotPreview(dispatch(_router.actions.navigate('/enquiry/' + vehicle.id)));
            },
            goToVdp: function goToVdp(vehicle) {
              return fnIfNotPreview(dispatch(_router.actions.navigateWithPayload('/vdp/' + vehicle.id + '-' + (0, _vehicle.vanityUrlFormatter)(config, vehicle, marketInfo.locale), vehicle)));
            },
            deleteAccount: function deleteAccount(password) {
              return fnIfNotPreview(function () {
                return actions.deleteAccount(password);
              });
            },
            detailsFeedback: detailsFeedback,
            signOut: fnIfNotPreview(function () {
              return actions.signOut();
            }),
            onShortListClick: function onShortListClick() {
              return dispatch(_router.actions.navigate(pathByModule('Shortlist')));
            },
            printing: printing,
            brandLogoUrl: config.brandLogoUrl,
            config: config
          })
        ) : null;
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  return MyAccount;
}(_react.Component);

exports.default = MyAccount;