'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var InactiveFilter = _styledComponents2.default.div.withConfig({
  displayName: 'InactiveFilter',
  componentId: 'sc-1xak9e0-0'
})(['display:flex;align-items:center;justify-content:center;font-family:Bentley-Light;font-size:16px;font-size:14px;text-align:center;text-transform:none;padding:10px;']);

exports.default = InactiveFilter;