'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n  '], ['\n    display: flex;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _modelTypes = require('../../../types/AstonMartin/modelTypes');

var _Checkbox = require('../Form/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Card = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__Card',
  componentId: 'sc-k5hxrg-0'
})(['@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){height:100%;}display:flex;flex-direction:column;margin-bottom:30px;']);

var ImageTextWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__ImageTextWrapper',
  componentId: 'sc-k5hxrg-1'
})(['width:100%;display:block;', ';'], _theme2.default.min.small(_templateObject));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'ModelItem__Image',
  componentId: 'sc-k5hxrg-2'
})(['max-width:100%;min-width:100%;']);

var SelectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__SelectionWrapper',
  componentId: 'sc-k5hxrg-3'
})(['display:flex;flex-direction:row;justify-content:space-between;font-size:14px;color:#000;text-transform:capitalize;padding:20px 0;']);

var ModelItem = function ModelItem(_ref) {
  var tile = _ref.tile,
      imageLabel = _ref.imageLabel,
      translations = _ref.translations,
      onTileSelected = _ref.onTileSelected,
      isChecked = _ref.isChecked;
  var imageUrl = tile.imageUrl,
      name = tile.name,
      translationNameKey = tile.translationNameKey;


  return _react2.default.createElement(
    Card,
    null,
    _react2.default.createElement(
      ImageTextWrapper,
      null,
      _react2.default.createElement(Image, { src: imageUrl, alt: imageLabel })
    ),
    _react2.default.createElement(
      SelectionWrapper,
      null,
      _react2.default.createElement(
        'span',
        null,
        translationNameKey && translations[translationNameKey] ? translations[translationNameKey] : name
      ),
      _react2.default.createElement(_Checkbox2.default, {
        onClick: function onClick() {
          return onTileSelected(tile.tileId);
        },
        checked: isChecked,
        enabled: true
      })
    )
  );
};

exports.default = _react2.default.memo(ModelItem);