'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  margin-bottom: 30px;\n'], ['\n  margin-bottom: 30px;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _VehicleCard = require('../VehicleCard');

var _NoResults = require('../NoResults');

var _NoResults2 = _interopRequireDefault(_NoResults);

var _reactRedux = require('react-redux');

var _sort = require('../../../helpers/sort');

var _vehicle = require('../../../helpers/vehicle');

var _router = require('../../../actions/router');

var _types = require('./types');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicleTypes = require('../../../types/AstonMartin/vehicleTypes');

var _AppShared = require('../../../types/AppShared');

var _shortlist = require('../../../shared/shortlist');

var _isEmpty = require('ramda/src/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SEARCH_RESULTS_PATH = '/searchresults';
var Body = _styledComponents2.default.div.withConfig({
  displayName: 'Index__Body',
  componentId: 'sc-oz9qcv-0'
})(['display:flex;width:100%;align-items:baseline;font-size:14px;font-family:AstonMartinFlare-Medium;text-transform:uppercase;']);

var BackButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Index__BackButtonContainer',
  componentId: 'sc-oz9qcv-1'
})(['display:flex;align-items:center;flex-basis:500px;padding-right:30px;']);

var BackButton = _styledComponents2.default.div.withConfig({
  displayName: 'Index__BackButton',
  componentId: 'sc-oz9qcv-2'
})(['display:flex;align-items:center;cursor:pointer;padding-right:16px;gap:12px;']);

var ListContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Index__ListContainer',
  componentId: 'sc-oz9qcv-3'
})(['width:100%;margin-bottom:40px;']);

var ListHeader = _styledComponents2.default.div.withConfig({
  displayName: 'Index__ListHeader',
  componentId: 'sc-oz9qcv-4'
})(['display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin-bottom:40px;', ';'], _theme2.default.max.large(_templateObject));

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Index__Wrapper',
  componentId: 'sc-oz9qcv-5'
})(['display:flex;flex-direction:column;margin-bottom:40px;']);

var Shortlist = function Shortlist(_ref) {
  var config = _ref.config,
      translations = _ref.translations,
      vehicles = _ref.vehicles,
      locale = _ref.locale,
      searchSort = _ref.searchSort,
      shared = _ref.shared;

  var dispatch = (0, _reactRedux.useDispatch)();
  var mobile = (0, _useCheckIsMobileScreen2.default)();
  var sortedList = (0, _react.useMemo)(function () {
    return (0, _sort.sortVehicles)(vehicles || [], searchSort.value);
  }, [vehicles, _sort.sortVehicles, searchSort]);

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  }),
      _useSelector$filters = _useSelector.filters,
      filters = _useSelector$filters === undefined ? null : _useSelector$filters;

  var isObject = function isObject(item) {
    return item != null && (typeof item === 'undefined' ? 'undefined' : (0, _typeof3.default)(item)) === 'object' && !Array.isArray(item);
  };

  var hasKey = function hasKey(obj, key) {
    return obj != null && obj[key];
  };

  var findFilters = function findFilters(obj) {
    var filters = '';
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] && Array.isArray(obj[key])) {
          filters = filters.concat(key + '=' + obj[key].join(',') + '&');
        }
        if (obj[key] && typeof obj[key] === 'string') {
          filters = filters.concat(key + '=' + obj[key] + '&');
        }
      }
    }
    return filters.substring(0, filters.length - 1);
  };

  var onMoreInfoClick = (0, _react.useCallback)(function (_, vehicle) {
    var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, locale);
    dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
  }, [_vehicle.vanityUrlFormatter, config, locale]);

  var onBackButtonClick = function onBackButtonClick() {
    var selectedFilters = '';
    if (isObject(filters) && hasKey(filters, 'selectedFilters')) {
      selectedFilters = findFilters(filters.selectedFilters);
    }
    var path = selectedFilters.length > 0 ? SEARCH_RESULTS_PATH + '?' + selectedFilters : SEARCH_RESULTS_PATH;

    dispatch(_router.actions.navigate(path));
  };

  var noResults = !sortedList.length;

  (0, _react.useEffect)(function () {
    if (shared.sessionPreferences.currency && (0, _keys2.default)(shared.currencyConversion.exchangeRates).length > 0 && shared.currencyConversion.exchangeRates[shared.sessionPreferences.currency]) {
      dispatch(_shortlist.actions.updateVehiclePrices({
        currency: shared.sessionPreferences.currency,
        exchangeRates: shared.currencyConversion.exchangeRates
      }));
    }
  }, [shared.sessionPreferences.currency, shared.currencyConversion.exchangeRates]);

  (0, _react.useEffect)(function () {
    // if exchange rates have been deleted, reset the prices
    if (shared.sessionPreferences.currency === '' && (0, _isEmpty2.default)(shared.currencyConversion.exchangeRates)) {
      dispatch(_shortlist.actions.getVehicles());
    }
  }, [shared.currencyConversion.exchangeRates]);

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      Body,
      null,
      !mobile && _react2.default.createElement(
        BackButtonContainer,
        null,
        _react2.default.createElement(
          BackButton,
          { onClick: onBackButtonClick },
          _react2.default.createElement('img', {
            src: config.goBackIconUrl,
            alt: '',
            width: '12px',
            height: '12px'
          }),
          translations.back
        )
      ),
      !noResults && _react2.default.createElement(
        ListContainer,
        null,
        mobile && _react2.default.createElement(
          'div',
          null,
          translations.header
        ),
        _react2.default.createElement(
          ListHeader,
          null,
          !mobile && _react2.default.createElement(
            'div',
            null,
            translations.header
          )
        ),
        sortedList.map(function (vehicle) {
          return _react2.default.createElement(_VehicleCard.VehicleItem, {
            config: config,
            key: vehicle.id,
            translations: translations,
            onMoreInfoClick: onMoreInfoClick,
            locale: locale,
            vehicle: vehicle
          });
        })
      )
    ),
    noResults && _react2.default.createElement(_NoResults2.default, {
      text: translations.noVehicleText,
      iconUrl: config.noVehicleIconUrl
    })
  );
};

exports.default = Shortlist;