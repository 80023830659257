'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pathBuilders = exports.normalisers = exports.renderer = exports.GlobalStyling = exports.CookiePolicyContent = exports.CookiePolicyWrapper = exports.formFields = exports.config = exports.pods = exports.bands = exports.linkedModules = exports.modules = exports.mountComponent = exports.components = exports.shared = exports.actions = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _reducerMacros = require('./utilities/reducerMacros');

Object.defineProperty(exports, 'mountComponent', {
  enumerable: true,
  get: function get() {
    return _reducerMacros.mountComponent;
  }
});

var _formFields = require('./pods/components/formFields');

var formFields = _interopRequireWildcard(_formFields);

var _modules = require('./modules');

var genericModules = _interopRequireWildcard(_modules);

var _modules2 = require('./modules.jaguar');

var JaguarModules = _interopRequireWildcard(_modules2);

var _linkedModules = require('./linked-modules');

var linkedModules = _interopRequireWildcard(_linkedModules);

var _bands = require('./bands');

var bands = _interopRequireWildcard(_bands);

var _pods = require('./pods');

var pods = _interopRequireWildcard(_pods);

var _renderer = require('./renderer');

var _renderer2 = _interopRequireDefault(_renderer);

var _CookiePolicy = require('./pods/CookiePolicy/CookiePolicy');

var _GlobalStyling = require('./components/GlobalStyling');

var _GlobalStyling2 = _interopRequireDefault(_GlobalStyling);

var _pathBuilders = require('./path-builders');

var pathBuilders = _interopRequireWildcard(_pathBuilders);

var _config = require('./helpers/config');

var _config2 = _interopRequireDefault(_config);

var _normalisation = require('./normalisation');

var _normalisation2 = _interopRequireDefault(_normalisation);

var _actions2 = require('./actions');

var _actions = _interopRequireWildcard(_actions2);

var _shared2 = require('./shared');

var _shared = _interopRequireWildcard(_shared2);

var _components2 = require('./components');

var _components = _interopRequireWildcard(_components2);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.actions = _actions; /* eslint-disable import/prefer-default-export */

exports.shared = _shared;
exports.components = _components;


var modules = (0, _extends3.default)({}, genericModules, JaguarModules, bands);

exports.modules = modules;
exports.linkedModules = linkedModules;
exports.bands = bands;
exports.pods = pods;
exports.config = _config2.default;
exports.formFields = formFields;
exports.CookiePolicyWrapper = _CookiePolicy.CookiePolicyWrapper;
exports.CookiePolicyContent = _CookiePolicy.CookiePolicyContent;
exports.GlobalStyling = _GlobalStyling2.default;
exports.renderer = _renderer2.default;
exports.normalisers = _normalisation2.default;
exports.pathBuilders = pathBuilders;