'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      ', ';\n  '], ['\n      ', ';\n  ']);

exports.default = GridItem;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('./Global');

var _YouTubePlayer = require('./YouTubePlayer');

var _YouTubePlayer2 = _interopRequireDefault(_YouTubePlayer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'GridItem__Image',
  componentId: 'sc-sf0up1-0'
})(['flex:1 1 0%;max-width:100%;width:100%;position:absolute;top:0;bottom:0;left:0;right:0;cursor:pointer;']);

var GridItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GridItem__GridItemContainer',
  componentId: 'sc-sf0up1-1'
})(['display:flex;flex-direction:column;flex:1 0 0%;', ';'], function (_ref) {
  var bgColour = _ref.bgColour;
  return bgColour && 'background-color: ' + bgColour;
});

var BottomContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GridItem__BottomContainer',
  componentId: 'sc-sf0up1-2'
})(['display:flex;flex-direction:column;flex-grow:1;', ';align-items:', ';justify-content:space-between;', ';', ';', ';'], function (_ref2) {
  var margin = _ref2.margin;
  return '' + (margin && 'margin: ' + margin + 'px');
}, function (props) {
  return props.align || 'flex-start';
}, function (_ref3) {
  var bottomContentGrow = _ref3.bottomContentGrow;
  return bottomContentGrow && 'flex: 1 0 auto';
}, function (_ref4) {
  var padding = _ref4.padding;
  return '' + (padding && 'padding: ' + padding + 'px');
}, _theme2.default.min.large(_templateObject, function (_ref5) {
  var borderLeft = _ref5.borderLeft;
  return '' + (borderLeft && 'border-left: ' + borderLeft);
}));

var Description = _styledComponents2.default.div.withConfig({
  displayName: 'GridItem__Description',
  componentId: 'sc-sf0up1-3'
})(['width:100%;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GridItem__ButtonContainer',
  componentId: 'sc-sf0up1-4'
})(['width:90%;margin:0 19px 25px 19px;']);

var AspectRatioContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GridItem__AspectRatioContainer',
  componentId: 'sc-sf0up1-5'
})(['overflow:hidden;position:relative;padding-top:43.25%;', ';'], function (_ref6) {
  var aspectRatio = _ref6.aspectRatio,
      itemCount = _ref6.itemCount;
  return itemCount === 1 ? 'padding-top: 43.25' : aspectRatio && 'padding-top: ' + aspectRatio + '%';
});

function GridItem(_ref7) {
  var itemVideo = _ref7.itemVideo,
      itemCount = _ref7.itemCount,
      linkText = _ref7.linkText,
      imageUrl = _ref7.imageUrl,
      headline = _ref7.headline,
      bodyText = _ref7.bodyText,
      bottomText = _ref7.bottomText,
      headlineTheme = _ref7.headlineTheme,
      bodyTextTheme = _ref7.bodyTextTheme,
      bottomTextTheme = _ref7.bottomTextTheme,
      align = _ref7.align,
      buttonProps = _ref7.buttonProps,
      globalStyling = _ref7.globalStyling,
      bottomContentGrow = _ref7.bottomContentGrow,
      onClickLink = _ref7.onClickLink,
      margin = _ref7.margin,
      bgColour = _ref7.bgColour,
      imageAspectRatioPercentage = _ref7.imageAspectRatioPercentage,
      borderLeft = _ref7.borderLeft,
      padding = _ref7.padding,
      translations = _ref7.translations;

  return _react2.default.createElement(
    GridItemContainer,
    { bgColour: bgColour },
    imageUrl && _react2.default.createElement(
      AspectRatioContainer,
      {
        aspectRatio: imageAspectRatioPercentage,
        itemCount: itemCount
      },
      _react2.default.createElement(Image, {
        src: imageUrl,
        onClick: onClickLink,
        alt: translations && translations.alternativeText || ''
      })
    ),
    itemVideo && _react2.default.createElement(_YouTubePlayer2.default, {
      src: itemVideo.youtubeId,
      autoplay: itemVideo.autoplay,
      modestBranding: itemVideo.modestBranding,
      muted: itemVideo.muted,
      looping: itemVideo.looping,
      height: itemVideo.height,
      alternativeText: translations && translations.videoAlternativeText
    }),
    _react2.default.createElement(
      BottomContainer,
      {
        align: align,
        bottomContentGrow: bottomContentGrow,
        margin: margin,
        borderLeft: borderLeft,
        padding: padding
      },
      _react2.default.createElement(
        Description,
        null,
        _react2.default.createElement(
          _Global.HeadingTwo,
          { styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(headlineTheme);
            } },
          headline
        ),
        _react2.default.createElement(
          _Global.Paragraph,
          { styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(bodyTextTheme);
            } },
          bodyText
        ),
        _react2.default.createElement(
          _Global.Paragraph,
          { styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(bottomTextTheme);
            } },
          bottomText
        )
      )
    ),
    linkText && _react2.default.createElement(
      ButtonContainer,
      null,
      _react2.default.createElement(_Global.Button, {
        tabindex: '0',
        label: linkText + ' about ' + headline,
        buttonStyle: buttonProps.buttonStyle || globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
        applyStyle: buttonProps.buttonType,
        styleOverride: function styleOverride() {
          return (0, _Global.buttonStyleOverride)(buttonProps.font, buttonProps.backgroundColour, buttonProps.hover, buttonProps.buttonSize, buttonProps.buttonHoverColour);
        },
        text: linkText,
        onClick: onClickLink
      })
    )
  );
}
GridItem.defaultProps = {
  margin: 20
};