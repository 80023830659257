'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  flex-direction: column;\n  '], ['\n  flex-direction: column;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    text-align: center;\n    width: 100%;\n  '], ['\n    text-align: center;\n    width: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  padding: 0;\n  '], ['\n  padding: 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']); /* eslint-disable no-nested-ternary */


exports.default = TextWithImageGrid;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Global = require('../../components/Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'TextWithImageGrid__Container',
  componentId: 'sc-1hzyjuf-0'
})(['display:flex;justify-content:space-between;width:100%;', ';'], _theme2.default.max.large(_templateObject));

var DescriptionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TextWithImageGrid__DescriptionContainer',
  componentId: 'sc-1hzyjuf-1'
})(['display:flex;padding:16px;box-sizing:border-box;flex-direction:column;width:', ';', ';', ';'], function (_ref) {
  var width = _ref.width;
  return width;
}, function (_ref2) {
  var borderLeft = _ref2.borderLeft;
  return '' + (borderLeft && 'border-left: ' + borderLeft);
}, _theme2.default.max.large(_templateObject2));

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TextWithImageGrid__ImageContainer',
  componentId: 'sc-1hzyjuf-2'
})(['display:block;min-height:1px;padding:16px 16px 0 16px;', ';'], _theme2.default.max.large(_templateObject3));

var ParagraphContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TextWithImageGrid__ParagraphContainer',
  componentId: 'sc-1hzyjuf-3'
})(['display:flex;padding:0 16px 16px 16px;box-sizing:border-box;']);

var GridItemWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'TextWithImageGrid__GridItemWrapper',
  componentId: 'sc-1hzyjuf-4'
})(['display:flex;flex-direction:column;box-sizing:border-box;min-height:1px;width:', ';', ';&:last-child{', ';}', ';'], function (_ref3) {
  var width = _ref3.width;
  return width;
}, function (_ref4) {
  var borderLeft = _ref4.borderLeft;
  return '' + (borderLeft && 'border-left: ' + borderLeft);
}, function (_ref5) {
  var borderLeft = _ref5.borderLeft;
  return '' + (borderLeft && 'border-left: 0');
}, _theme2.default.max.large(_templateObject4));

var ImageItemContainer = _styledComponents2.default.img.withConfig({
  displayName: 'TextWithImageGrid__ImageItemContainer',
  componentId: 'sc-1hzyjuf-5'
})(['display:flex;width:100%;']);

function TextWithImageGrid(_ref6) {
  var config = _ref6.config,
      globalStyling = _ref6.globalStyling;

  return _react2.default.createElement(
    _ContentWrapper2.default,
    {
      colour: config.gridBackgroundColour,
      contentWidth: globalStyling.contentWidth
    },
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        DescriptionContainer,
        {
          width: 100 / (config.items.length + 1) + '%',
          borderLeft: config.column && config.column.borderLeft
        },
        config.translations.headlineText && _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(config.headlineFont);
            }
          },
          config.translations.headlineText
        ),
        config.translations.subHeadlineText && _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(config.descriptionFont);
            }
          },
          config.translations.subHeadlineText
        )
      ),
      config.items.map(function (item, index) {
        return [_react2.default.createElement(
          GridItemWrapper,
          {
            key: 'textWithImageGrid-' + index.toString(),
            width: 100 / (config.items.length + 1) + '%',
            borderLeft: config.column && config.column.borderLeft
          },
          _react2.default.createElement(
            ImageContainer,
            null,
            _react2.default.createElement(ImageItemContainer, { src: item.image.value })
          ),
          item.translations.body && _react2.default.createElement(
            ParagraphContainer,
            null,
            _react2.default.createElement(
              _Global.Paragraph,
              {
                styleOverride: function styleOverride() {
                  return '\n                    ' + (0, _Global.fontStyleOverride)(config.descriptionFont) + '\n                    width: 100%;\n                  ';
                }
              },
              item.translations.body
            )
          )
        )];
      })
    )
  );
}