'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CurrencyPopup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('./Global');

var _ModalCurrency = require('./ModalCurrency');

var _ModalCurrency2 = _interopRequireDefault(_ModalCurrency);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'CurrencyPopup__Container',
  componentId: 'sc-msymzd-0'
})(['display:flex;flex-direction:column;background-color:#f0f0f0;width:100%;align-items:center;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CurrencyPopup__ButtonContainer',
  componentId: 'sc-msymzd-1'
})(['display:flex;justify-content:center;background-color:#f0f0f0;']);

function CurrencyPopup(_ref) {
  var translations = _ref.translations,
      globalStyling = _ref.globalStyling,
      disableCurrencyPopup = _ref.disableCurrencyPopup,
      shared = _ref.shared;

  return _react2.default.createElement(
    _ModalCurrency2.default,
    {
      modalWidth: 600,
      globalStyling: globalStyling,
      translations: translations,
      shared: shared,
      closeButton: true
    },
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n            display: flex;\n            padding: 24px;\n            justify-content: center;\n            text-transform: uppercase;\n          ';
          }
        },
        translations.currencyPopupHeader
      ),
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n            display: flex;\n            width: 80%;\n            margin: 0;\n            justify-content: center;\n          ';
          }
        },
        translations.currencyPopupText
      ),
      _react2.default.createElement(
        ButtonContainer,
        null,
        _react2.default.createElement(_Global.Button, {
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          text: translations.currencyButton,
          applyStyle: 'primary',
          styleOverride: function styleOverride() {
            return 'margin: 24px;';
          },
          mobileStyleOverride: function mobileStyleOverride() {
            return 'margin: 20px;';
          },
          onClick: disableCurrencyPopup
        })
      )
    )
  );
}