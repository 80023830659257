'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = DropDownFilter;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _DropDown = require('../DropDown');

var _DropDown2 = _interopRequireDefault(_DropDown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'DropdownFilter__Container',
  componentId: 'sc-15xu2p8-0'
})(['display:flex;flex-direction:column;', ' width:100%;'], function (props) {
  return props.border === true && (0, _styledComponents.css)(['border:1px solid #ccc;']);
});


var theme = {
  link: {
    font: {
      colour: {
        value: '#818181',
        label: '#818181'
      },
      kerning: 1,
      fontSize: '12',
      typeface: {
        label: 'ProximaNova-Regular',
        value: 'ProximaNova-Regular'
      },
      transform: 'none'
    }
  },
  dropdown: {
    font: {
      colour: {
        value: '#333333',
        label: '#333333'
      },
      kerning: 1,
      fontSize: '12',
      typeface: {
        label: 'ProximaNova-Regular',
        value: 'ProximaNova-Regular'
      },
      transform: 'uppercase'
    }
  }
};

function DropDownFilter(props) {
  return _react2.default.createElement(
    Container,
    { border: props.border },
    _react2.default.createElement(_DropDown2.default, (0, _extends3.default)({ position: 'relative', theme: theme }, props))
  );
}

DropDownFilter.defaultProps = {
  getLabelFromOption: function getLabelFromOption(i) {
    return i.label;
  },
  getValueFromOption: function getValueFromOption(i) {
    return i.value;
  },
  height: 40,
  border: true
};