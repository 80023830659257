'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding-top: 12px;\n  '], ['\n    padding-top: 12px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding-right: 10px'], ['\n    padding-right: 10px']);

exports.default = LinkList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _BentleyLink = require('./BentleyLink');

var _BentleyLink2 = _interopRequireDefault(_BentleyLink);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LinkText = _styledComponents2.default.span.withConfig({
  displayName: 'LinkList__LinkText',
  componentId: 'sc-1sua2te-0'
})(['font-size:16px;font-family:Bentley-light;line-height:20px;']);

var Col = _styledComponents2.default.div.withConfig({
  displayName: 'LinkList__Col',
  componentId: 'sc-1sua2te-1'
})(['display:flex;flex-direction:column;gap:6px;margin-bottom:12px;padding-right:100px;', ';', ''], _theme2.default.max.large(_templateObject), _theme2.default.max.small(_templateObject2));

function LinkList(_ref) {
  var items = _ref.items,
      translations = _ref.translations;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      Col,
      null,
      items.map(function (_ref2) {
        var label = _ref2.label,
            link = _ref2.link,
            id = _ref2.id;
        return _react2.default.createElement(
          _BentleyLink2.default,
          { key: id, link: link },
          _react2.default.createElement(
            LinkText,
            null,
            translations['' + label]
          )
        );
      })
    )
  );
}