'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _CompareGrid = require('./CompareGrid');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_CompareGrid).default;
  }
});

var _reducer = require('../../CompareGrid/reducer');

Object.defineProperty(exports, 'actions', {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
Object.defineProperty(exports, 'constants', {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
Object.defineProperty(exports, 'reducer', {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, 'initialState', {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'AstonMartinCompare';
var id = exports.id = 'AstonMartinCompare';
var description = exports.description = 'Compare Page';