'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.distanceLabel = undefined;

var _translateFromTemplate = require('./translateFromTemplate');

var _numbers = require('./numbers');

var distanceLabel = exports.distanceLabel = function distanceLabel(distance, translations, country) {
  var isDistanceMiles = country && ['us', 'gb'].includes(country.toLowerCase());

  return distance > 0 && (0, _translateFromTemplate.translateFromTemplate)(isDistanceMiles && translations.distanceLabelMiles ? 'distanceLabelMiles' : 'distanceLabel', {
    DISTANCE: Math.ceil(isDistanceMiles ? (0, _numbers.converKmToMiles)(distance) : distance)
  }, translations);
};