'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n  '], ['\n    width: 50%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: ', ';\n  '], ['\n    width: ', ';\n  ']);

exports.default = ModelMatrixGrid;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ModelGridTile = require('./ModelGridTile');

var _ModelGridTile2 = _interopRequireDefault(_ModelGridTile);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilterTileGrid = _styledComponents2.default.div.withConfig({
  displayName: 'ModelMatrixGrid__FilterTileGrid',
  componentId: 'sc-gnf6da-0'
})(['display:flex;justify-content:flex-start;flex-wrap:wrap;']);

var ModelGridTileWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelMatrixGrid__ModelGridTileWrapper',
  componentId: 'sc-gnf6da-1'
})(['width:100%;', ';', ';', ';'], _theme2.default.min.small(_templateObject), _theme2.default.min.medium(_templateObject2, function (_ref) {
  var numberOfColumns = _ref.numberOfColumns;
  return 100 / Math.max(numberOfColumns - 1, 2) + '%';
}), _theme2.default.min.large(_templateObject2, function (_ref2) {
  var numberOfColumns = _ref2.numberOfColumns;
  return 100 / numberOfColumns + '%';
}));

function ModelMatrixGrid(_ref3) {
  var _ref3$vehicleModels = _ref3.vehicleModels,
      vehicleModels = _ref3$vehicleModels === undefined ? [] : _ref3$vehicleModels,
      onItemClick = _ref3.onItemClick,
      _ref3$selectedModels = _ref3.selectedModels,
      selectedModels = _ref3$selectedModels === undefined ? [] : _ref3$selectedModels,
      translations = _ref3.translations,
      selectedIconColour = _ref3.selectedIconColour,
      locale = _ref3.locale,
      titleFont = _ref3.titleFont,
      featureFlags = _ref3.featureFlags,
      numberOfColumns = _ref3.numberOfColumns,
      showStockNumber = _ref3.showStockNumber,
      showPrice = _ref3.showPrice,
      showSubtitle = _ref3.showSubtitle,
      backgroundColour = _ref3.backgroundColour,
      borderColour = _ref3.borderColour,
      modelCardStyling = _ref3.modelCardStyling,
      globalStyling = _ref3.globalStyling,
      titleBottomMargin = _ref3.titleBottomMargin,
      showFinancePopup = _ref3.showFinancePopup;

  return vehicleModels ? _react2.default.createElement(
    FilterTileGrid,
    null,
    vehicleModels.sort(function (a, b) {
      return a.position - b.position;
    }).map(function (vehicleModel) {
      return _react2.default.createElement(
        ModelGridTileWrapper,
        { numberOfColumns: numberOfColumns, key: (0, _v2.default)() },
        _react2.default.createElement(_ModelGridTile2.default, {
          align: 'left',
          onIconClick: function onIconClick() {
            return onItemClick(vehicleModel.model);
          },
          vehicleModel: vehicleModel,
          isSelected: selectedModels && selectedModels.includes(vehicleModel.model),
          translations: translations,
          selectedIconColour: selectedIconColour,
          locale: locale,
          titleFont: titleFont,
          featureFlags: featureFlags,
          width: 100 / numberOfColumns + '%',
          backgroundColour: backgroundColour,
          borderColour: borderColour,
          showStockNumber: showStockNumber,
          showPrice: showPrice,
          showSubtitle: showSubtitle,
          modelCardStyling: modelCardStyling,
          globalStyling: globalStyling,
          titleBottomMargin: titleBottomMargin,
          showFinancePopup: showFinancePopup,
          loading: vehicleModel.loading
        })
      );
    })
  ) : null;
}