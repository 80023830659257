'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  align-self: flex-end;\n  '], ['\n  display: flex;\n  align-self: flex-end;\n  ']);

exports.default = TemplateThree;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _UtilityBar = require('../../../../components/UtilityBar');

var _UtilityBar2 = _interopRequireDefault(_UtilityBar);

var _ContentWrapper = require('../../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _MenuBar = require('../../../../components/MenuBar');

var _MenuBar2 = _interopRequireDefault(_MenuBar);

var _CurrencyPopup = require('../../../../components/CurrencyPopup');

var _CurrencyPopup2 = _interopRequireDefault(_CurrencyPopup);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DesktopContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateThree__DesktopContainer',
  componentId: 'sc-fnj9qo-0'
})(['position:', ';width:100%;z-index:80;'], function (_ref) {
  var stickyHeader = _ref.stickyHeader;
  return stickyHeader ? 'fixed' : 'static';
});

var DesktopMenu = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateThree__DesktopMenu',
  componentId: 'sc-fnj9qo-1'
})(['display:flex;width:100%;', ';'], _theme2.default.max.large(_templateObject));

var MobileMenu = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateThree__MobileMenu',
  componentId: 'sc-fnj9qo-2'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject2));

var UtilityBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateThree__UtilityBarContainer',
  componentId: 'sc-fnj9qo-3'
})(['display:flex;', ';', ';', ';', ';'], function (_ref2) {
  var height = _ref2.height;
  return '' + (height && 'height: ' + height + 'px');
}, function (_ref3) {
  var backgroundColour = _ref3.backgroundColour;
  return (backgroundColour && 'background-color: ' + backgroundColour.value) + '; transition: background-color .5s ease;';
}, function (_ref4) {
  var borderBottom = _ref4.borderBottom;
  return borderBottom && 'border-bottom: ' + borderBottom;
}, _theme2.default.max.large(_templateObject));

var SectionWithMidLogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateThree__SectionWithMidLogoContainer',
  componentId: 'sc-fnj9qo-4'
})(['display:flex;flex-direction:column;align-items:center;', ';', ';'], function (_ref5) {
  var backgroundColour = _ref5.backgroundColour;
  return (backgroundColour && 'background-color: ' + backgroundColour.value) + '; transition: background-color .5s ease;';
}, function (_ref6) {
  var borderBottom = _ref6.borderBottom;
  return borderBottom && 'border-bottom: ' + borderBottom;
});

var BrandLogo = _styledComponents2.default.img.withConfig({
  displayName: 'TemplateThree__BrandLogo',
  componentId: 'sc-fnj9qo-5'
})(['', ';height:38px;'], function (_ref7) {
  var source = _ref7.source;
  return source && 'cursor: pointer;';
});

var CenterLogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateThree__CenterLogoContainer',
  componentId: 'sc-fnj9qo-6'
})(['display:flex;align-items:center;position:absolute;', ';'], function (_ref8) {
  var height = _ref8.height;
  return '' + (height && 'height: ' + height + 'px');
});

var SliceMenuItemsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateThree__SliceMenuItemsContainer',
  componentId: 'sc-fnj9qo-7'
})(['display:flex;justify-content:space-between;']);

var SliceMenuItems = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateThree__SliceMenuItems',
  componentId: 'sc-fnj9qo-8'
})(['padding:0 8px;width:auto;']);

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateThree__Container',
  componentId: 'sc-fnj9qo-9'
})(['@media print{display:none;}']);

var MenuItems = function MenuItems(_ref9) {
  var config = _ref9.config,
      links = _ref9.links,
      navigateIfInternal = _ref9.navigateIfInternal;
  return _react2.default.createElement(
    SliceMenuItems,
    null,
    _react2.default.createElement(_MenuBar2.default, {
      height: config.menuBarHeight,
      maxWidth: config.itemContainerMaxWidth,
      theme: {
        font: config.menuBarLinkFont,
        hoverColour: config.menuBarLinkHoverColour,
        backgroundHoverColour: config.menuBarLinkBgHoverColour
      },
      items: links,
      backgroundColour: config.menuBarBGColour && config.menuBarBGColour.value,
      navigateIfInternal: navigateIfInternal,
      mobileMenuIconsColour: config.mobileMenuIconsColour && config.mobileMenuIconsColour.value
    })
  );
};

function TemplateThree(_ref10) {
  var config = _ref10.config,
      globalStyling = _ref10.globalStyling,
      links = _ref10.links,
      navigateOnClick = _ref10.navigateOnClick,
      navigateIfInternal = _ref10.navigateIfInternal,
      filteredOptions = _ref10.filteredOptions,
      utilityBarActions = _ref10.utilityBarActions,
      utilityBarInitialValues = _ref10.utilityBarInitialValues,
      socialShare = _ref10.socialShare,
      currentLanguage = _ref10.currentLanguage,
      currentCurrency = _ref10.currentCurrency,
      disableCurrencyPopup = _ref10.disableCurrencyPopup,
      shared = _ref10.shared,
      headerHeroOverlay = _ref10.headerHeroOverlay;

  var heroVisibility = shared.sessionPreferences.isHeroVisible && headerHeroOverlay;
  var headerConfig = (0, _extends3.default)({}, config, heroVisibility);

  var translatedLinks = links.map(function (item) {
    return (0, _extends3.default)({}, item, {
      link: item.link.replace('{LANGUAGE}', currentLanguage.replace(/_([a-z]+)/, function (match) {
        return '' + match.toUpperCase();
      }))
    });
  });

  return _react2.default.createElement(
    Container,
    { 'data-nosnippet': true },
    _react2.default.createElement(_CurrencyPopup2.default, {
      translations: headerConfig.translations,
      globalStyling: globalStyling,
      disableCurrencyPopup: disableCurrencyPopup,
      shared: shared
    }),
    _react2.default.createElement(
      DesktopContainer,
      { stickyHeader: headerConfig.stickyHeader },
      _react2.default.createElement(
        UtilityBarContainer,
        {
          height: headerConfig.utilityBarHeight,
          backgroundColour: headerConfig.utilityBarBGColour,
          borderBottom: headerConfig.utilityBarBorderBottom
        },
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          _react2.default.createElement(_UtilityBar2.default, {
            config: headerConfig,
            theme: {
              link: {
                font: headerConfig.utilityBarLinkFont
              },
              dropdown: {
                font: headerConfig.utilityBarDropdownFont,
                backgroundColour: headerConfig.utilityBarDropdownColour,
                dropdownIconSize: headerConfig.languageDropdownIconSize,
                iconColour: headerConfig.dropdownIconColour
              }
            },
            utilityBarOptions: filteredOptions,
            dropDownActions: utilityBarActions,
            initialValues: utilityBarInitialValues,
            socialShare: socialShare
          })
        )
      ),
      _react2.default.createElement(
        SectionWithMidLogoContainer,
        {
          backgroundColour: headerConfig.menuBarBGColour,
          borderBottom: headerConfig.menuBarBorderBottom
        },
        _react2.default.createElement(
          CenterLogoContainer,
          { height: headerConfig.menuBarHeight },
          _react2.default.createElement(BrandLogo, {
            'data-cy': 'logo',
            source: headerConfig.brandLogoLink,
            onClick: navigateOnClick,
            src: headerConfig.brandLogoUrl,
            alt: 'rolls-royce'
          })
        ),
        _react2.default.createElement(
          DesktopMenu,
          null,
          _react2.default.createElement(
            _ContentWrapper2.default,
            { contentWidth: globalStyling.contentWidth },
            _react2.default.createElement(
              SliceMenuItemsContainer,
              null,
              _react2.default.createElement(MenuItems, {
                config: headerConfig,
                links: translatedLinks.slice(0, links.length / 2),
                navigateIfInternal: navigateIfInternal
              }),
              _react2.default.createElement(MenuItems, {
                config: headerConfig,
                links: translatedLinks.slice(links.length / 2, links.length),
                navigateIfInternal: navigateIfInternal
              })
            )
          )
        ),
        _react2.default.createElement(
          MobileMenu,
          null,
          _react2.default.createElement(_MenuBar2.default, {
            theme: {
              font: headerConfig.mobileMenuBarLinkFont || headerConfig.mobileBarLinkFont,
              hoverColour: headerConfig.menuBarLinkHoverColour,
              backgroundHoverColour: headerConfig.menuBarLinkBgHoverColour,
              underLineColor: headerConfig.menuBarUnderlineColor
            },
            items: links,
            height: headerConfig.menuBarHeight,
            backgroundColour: headerConfig.mobileMenuBarBGColour && headerConfig.mobileMenuBarBGColour.value || headerConfig.menuBarBGColour.value,
            navigateIfInternal: navigateIfInternal,
            withSearch: headerConfig.showSearchBar,
            searchBarAlign: headerConfig.searchBarAlign,
            searchIconAlign: headerConfig.searchIconAlign,
            mobileMenuIconsColour: headerConfig.mobileMenuIconsColour.value,
            mobileMenuIconsColourClose: headerConfig.mobileMenuIconsColourClose && headerConfig.mobileMenuIconsColourClose.value,
            burgerMenuItemBorder: headerConfig.burgerMenuItemBorder,
            socialShareEnabled: headerConfig.socialShareEnabled,
            utilityBarLanguageEnabled: headerConfig.utilityBarLanguageEnabled,
            utilityBarCurrencyEnabled: headerConfig.utilityBarCurrencyEnabled,
            socialIcon: headerConfig.socialIcon,
            languageIcon: headerConfig.languageIcon,
            currencyIcon: headerConfig.currencyIcon,
            translations: headerConfig.translations,
            socialShareProps: socialShare,
            utilityBarOptions: filteredOptions,
            languageActions: utilityBarActions.language,
            currencyActions: utilityBarActions.currency,
            burgerMenuLanguageFont: headerConfig.burgerMenuLanguageFont,
            languageChevron: headerConfig.burgerMenuLanguageChevron,
            hideChevronOnMobile: headerConfig.hideChevronOnMobile,
            currentLanguage: currentLanguage,
            currentCurrency: currentCurrency,
            config: config
          })
        )
      )
    )
  );
}