'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortVehicles = sortVehicles;

var _AppShared = require('../types/AppShared');

var SortValueDictionary = {
  priceAsc: function priceAsc(a, b) {
    return Number(a.price.value) - Number(b.price.value);
  },
  priceDesc: function priceDesc(a, b) {
    return Number(b.price.value) - Number(a.price.value);
  },
  mileageAsc: function mileageAsc(a, b) {
    return (a.odometer || { reading: 0 }).reading - (b.odometer || { reading: 0 }).reading;
  },
  mileageDesc: function mileageDesc(a, b) {
    return (b.odometer || { reading: 0 }).reading - (a.odometer || { reading: 0 }).reading;
  },
  ageAsc: function ageAsc(a, b) {
    return Number(b.registration) - Number(a.registration);
  },
  ageDesc: function ageDesc(a, b) {
    return Number(a.registration) - Number(b.registration);
  }
};

function sortVehicles(items, option) {
  return items.sort(SortValueDictionary[option]);
}