'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.icon = exports.description = exports.availableToAdministration = exports.id = exports.name = exports.initialState = exports.reducer = exports.constants = exports.actions = exports.defaultConfig = exports.config = exports.effects = exports.component = exports.reducerId = undefined;

var _reducer = require('../../modules/ResetPassword/reducer');

Object.defineProperty(exports, 'reducerId', {
  enumerable: true,
  get: function get() {
    return _reducer.id;
  }
});

var _ResetPassword = require('./ResetPassword');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ResetPassword).default;
  }
});

var _effects = require('../../modules/ResetPassword/effects');

Object.defineProperty(exports, 'effects', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_effects).default;
  }
});

var _config = require('../../modules/ResetPassword/config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _defaultConfig = require('../../modules/ResetPassword/defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});
Object.defineProperty(exports, 'actions', {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
Object.defineProperty(exports, 'constants', {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
Object.defineProperty(exports, 'reducer', {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, 'initialState', {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});

var _Icon = require('../../modules/ResetPassword/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'Reset Password';
var id = exports.id = 'ResetPassword';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'A module that allows the user to reset their password';
var icon = exports.icon = _Icon2.default;