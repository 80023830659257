'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 49%;\n  '], ['\n    width: 49%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: ', ';\n  '], ['\n    width: ', ';\n  ']);

exports.default = GridView;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SearchResultTile = require('../SearchResultTile');

var _SearchResultTile2 = _interopRequireDefault(_SearchResultTile);

var _SearchResultGridTile = require('../SearchResultGridTile');

var _SearchResultGridTile2 = _interopRequireDefault(_SearchResultGridTile);

var _Compare = require('../JaguarIcons/Global/Compare');

var _Compare2 = _interopRequireDefault(_Compare);

var _Heart = require('../JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Results = _styledComponents2.default.div.withConfig({
  displayName: 'GridView__Results',
  componentId: 'sc-1nlj3vs-0'
})(['width:100%;display:flex;flex-wrap:wrap;justify-content:space-between;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'GridView__IconWrapper',
  componentId: 'sc-1nlj3vs-1'
})(['margin-right:10px;width:25px;height:25px;cursor:pointer;', ';'], function (_ref) {
  var direction = _ref.direction;
  return direction === 'rtl' && 'margin-left: 10px;';
});

var CompareIconButton = function CompareIconButton(onCompareClick, iconColour) {
  var compareShortlistIconWidth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  return _react2.default.createElement(
    IconWrapper,
    { onClick: onCompareClick },
    _react2.default.createElement(_Compare2.default, {
      width: compareShortlistIconWidth + 'em',
      height: '1.75em',
      colour: iconColour
    })
  );
};

var HeartIconButton = function HeartIconButton(onHeartClick, iconColour) {
  var compareShortlistIconWidth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var direction = arguments[3];
  return _react2.default.createElement(
    IconWrapper,
    { onClick: onHeartClick, direction: direction },
    _react2.default.createElement(_Heart2.default, {
      width: compareShortlistIconWidth + 'em',
      height: '1.75em',
      colour: iconColour
    })
  );
};

var DoubleEmptyTile = function DoubleEmptyTile() {
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(ModelGridTileWrapper, { numberOfColumns: 4 }),
    _react2.default.createElement(ModelGridTileWrapper, { numberOfColumns: 4 })
  );
};

var ModelGridTileWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'GridView__ModelGridTileWrapper',
  componentId: 'sc-1nlj3vs-2'
})(['width:100%;margin-bottom:16px;', ';', ';'], _theme2.default.min.large(_templateObject), _theme2.default.min.extraLarge(_templateObject2, function (_ref2) {
  var numberOfColumns = _ref2.numberOfColumns;
  return 100 / numberOfColumns - 1 + '%';
}));

function GridView(_ref3) {
  var config = _ref3.config,
      results = _ref3.results,
      translations = _ref3.translations,
      onMoreInfoClick = _ref3.onMoreInfoClick,
      compareVehicle = _ref3.compareVehicle,
      shortlistVehicle = _ref3.shortlistVehicle,
      getShortListedIconColour = _ref3.getShortListedIconColour,
      getCompareIconColour = _ref3.getCompareIconColour,
      filtersExpanded = _ref3.filtersExpanded,
      globalStyling = _ref3.globalStyling,
      visibility = _ref3.visibility,
      locale = _ref3.locale,
      vehicleModelTitleFontWeight = _ref3.vehicleModelTitleFontWeight,
      showGridTiles = _ref3.showGridTiles,
      badgeStyling = _ref3.badgeStyling,
      pricingSectionImage = _ref3.pricingSectionImage,
      financeHeadingFont = _ref3.financeHeadingFont,
      financeSubHeadingFont = _ref3.financeSubHeadingFont,
      headerItemFont = _ref3.headerItemFont,
      gridViewColumns = _ref3.gridViewColumns,
      showFinancePopup = _ref3.showFinancePopup,
      handleZeroPriceAsText = _ref3.handleZeroPriceAsText,
      showNumberOfImagesInsteadOfViews = _ref3.showNumberOfImagesInsteadOfViews,
      badgeWithStripUrl = _ref3.badgeWithStripUrl,
      badgeWithoutStripUrl = _ref3.badgeWithoutStripUrl,
      warrantyBadgeMonths = _ref3.warrantyBadgeMonths,
      warrantyBadgeMaxMileage = _ref3.warrantyBadgeMaxMileage,
      backgroundColour = _ref3.backgroundColour,
      vehicleTitleFont = _ref3.vehicleTitleFont,
      vehicleSubTitleFont = _ref3.vehicleSubTitleFont,
      vehicleSpecContainer = _ref3.vehicleSpecContainer,
      actionRowStyles = _ref3.actionRowStyles,
      fullSubtitle = _ref3.fullSubtitle,
      countDivMargin = _ref3.countDivMargin,
      compareShortlistIconWidth = _ref3.compareShortlistIconWidth,
      showOnDemand = _ref3.showOnDemand,
      icon360 = _ref3.icon360,
      iconYoutubeVideo = _ref3.iconYoutubeVideo,
      onEnquiryClick = _ref3.onEnquiryClick;

  var usePadding = results && results.length > gridViewColumns;
  return _react2.default.createElement(
    Results,
    { length: results.length },
    results.map(function (vehicle) {
      return showGridTiles ? _react2.default.createElement(
        ModelGridTileWrapper,
        {
          key: vehicle.id,
          numberOfColumns: gridViewColumns,
          paddingBottom: usePadding,
          'data-cy': 'search-result-container-grid-column-vehicle-item'
        },
        _react2.default.createElement(_SearchResultGridTile2.default, {
          config: config,
          key: vehicle.id,
          align: 'left',
          onHeartClick: function onHeartClick() {},
          vehicle: vehicle,
          placeholdingImage: results.placeholdingImage,
          onMoreInfoClicked: function onMoreInfoClicked() {
            return onMoreInfoClick('VDP', vehicle);
          },
          onEnquiryClick: function (_onEnquiryClick) {
            function onEnquiryClick() {
              return _onEnquiryClick.apply(this, arguments);
            }

            onEnquiryClick.toString = function () {
              return _onEnquiryClick.toString();
            };

            return onEnquiryClick;
          }(function () {
            return onEnquiryClick(vehicle.id);
          }),
          translations: translations,
          compareVehicle: compareVehicle,
          shortlistVehicle: shortlistVehicle,
          shortListedIconColour: getShortListedIconColour(vehicle.id),
          compareIconColour: getCompareIconColour(vehicle.id),
          renderTopRightIcon: function renderTopRightIcon() {},
          globalStyling: globalStyling,
          visibility: visibility,
          locale: locale,
          badgeStyling: badgeStyling,
          headerItemFont: headerItemFont,
          handleZeroPriceAsText: handleZeroPriceAsText,
          showNumberOfImagesInsteadOfViews: showNumberOfImagesInsteadOfViews,
          icon360: icon360,
          iconYoutubeVideo: iconYoutubeVideo
        })
      ) : _react2.default.createElement(
        ModelGridTileWrapper,
        {
          numberOfColumns: gridViewColumns,
          'data-cy': 'search-result-container-grid-column-vehicle-item'
        },
        _react2.default.createElement(_SearchResultTile2.default, {
          key: vehicle.id,
          align: 'left',
          onHeartClick: function onHeartClick() {},
          carResult: vehicle,
          placeholdingImage: results.placeholdingImage,
          checkLength: results.length < 3,
          onMoreInfoClicked: function onMoreInfoClicked() {
            return onMoreInfoClick('VDP', vehicle);
          },
          translations: translations,
          filtersExpanded: filtersExpanded,
          renderTopRightIcon: function renderTopRightIcon() {},
          renderBottomIcon1: function renderBottomIcon1() {
            return CompareIconButton(function () {
              return compareVehicle(vehicle);
            }, getCompareIconColour(vehicle.id), compareShortlistIconWidth);
          },
          renderBottomIcon2: function renderBottomIcon2() {
            return HeartIconButton(function () {
              return shortlistVehicle(vehicle);
            }, getShortListedIconColour(vehicle.id), compareShortlistIconWidth, globalStyling.direction);
          },
          globalStyling: globalStyling,
          visibility: visibility,
          locale: locale,
          vehicleModelTitleFontWeight: vehicleModelTitleFontWeight,
          pricingSectionImage: pricingSectionImage,
          financeHeadingFont: financeHeadingFont,
          financeSubHeadingFont: financeSubHeadingFont,
          showFinancePopup: showFinancePopup,
          badgeWithStripUrl: badgeWithStripUrl,
          badgeWithoutStripUrl: badgeWithoutStripUrl,
          warrantyBadgeMonths: warrantyBadgeMonths,
          warrantyBadgeMaxMileage: warrantyBadgeMaxMileage,
          showFinanceCellBorder: false,
          backgroundColour: backgroundColour,
          vehicleTitleFont: vehicleTitleFont,
          vehicleSubTitleFont: vehicleSubTitleFont,
          vehicleSpecContainer: vehicleSpecContainer,
          actionRowStyles: actionRowStyles,
          fullSubtitle: fullSubtitle,
          countDivMargin: countDivMargin,
          compareShortlistIconWidth: compareShortlistIconWidth,
          showOnDemand: showOnDemand,
          onDemandFont: config.onDemandFont,
          icon360: icon360,
          iconYoutubeVideo: iconYoutubeVideo,
          showEmissions: config.showEmissions,
          convertedKwToHp: config.convertedKwToHp,
          config: config
        })
      );
    }),
    gridViewColumns === 3 && results.length % 3 === 2 && _react2.default.createElement(ModelGridTileWrapper, { numberOfColumns: 3 }),
    gridViewColumns === 4 && results.length % 4 === 3 && _react2.default.createElement(ModelGridTileWrapper, { numberOfColumns: 4 }),
    gridViewColumns === 4 && results.length % 4 === 2 && _react2.default.createElement(DoubleEmptyTile, null)
  );
}