'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ApprovedIconGrid;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _IconGrid = require('../IconGrid/IconGrid');

var _IconGrid2 = _interopRequireDefault(_IconGrid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ApprovedIconGrid(_ref) {
  var config = _ref.config,
      globalStyling = _ref.globalStyling,
      modInfo = _ref.modInfo;

  var page = (0, _reactRedux.useSelector)(function (state) {
    return state.pages[modInfo.page];
  });
  var approvedVehicle = page && page[modInfo.instance - 1] && page[modInfo.instance - 1].vdpConfig && page[modInfo.instance - 1].vdpConfig.approved;

  return !!approvedVehicle && _react2.default.createElement(_IconGrid2.default, { config: config, globalStyling: globalStyling });
}