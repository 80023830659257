'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.optionComponents = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 278px;\n  '], ['\n    width: 278px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FilterSection = require('./FilterSection');

var _FilterSection2 = _interopRequireDefault(_FilterSection);

var _MultiSelectFilter = require('./MultiSelectFilter');

var _MultiSelectFilter2 = _interopRequireDefault(_MultiSelectFilter);

var _RegionalFilter = require('./RegionalFilter');

var _RegionalFilter2 = _interopRequireDefault(_RegionalFilter);

var _LocationDistanceFilter = require('./LocationDistanceFilter');

var _LocationDistanceFilter2 = _interopRequireDefault(_LocationDistanceFilter);

var _LocationFilter = require('./LocationFilter');

var _LocationFilter2 = _interopRequireDefault(_LocationFilter);

var _OneHandledRangeFilter = require('./RangeFilters/OneHandledRangeFilter');

var _OneHandledRangeFilter2 = _interopRequireDefault(_OneHandledRangeFilter);

var _DualHandledRangeFilter = require('./RangeFilters/DualHandledRangeFilter');

var _DualHandledRangeFilter2 = _interopRequireDefault(_DualHandledRangeFilter);

var _CheckBox = require('../../components/CheckBox');

var _CheckBox2 = _interopRequireDefault(_CheckBox);

var _Global = require('../../components/Global');

var _numbers = require('../../shared/localisation/numbers');

var _helpers = require('../../shared/currencyConversion/helpers');

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CheckboxWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersLookup__CheckboxWrapper',
  componentId: 'sc-zkoaew-0'
})(['align-items:center;cursor:pointer;display:flex;width:100%;box-sizing:border-box;padding:0 16px;min-height:50px;border-top:1px solid #d5d5d5;&:last-child{border-bottom:1px solid #d5d5d5;}', ''], function (_ref) {
  var searchElectric = _ref.searchElectric;
  return searchElectric && '\n    background: #05141F;\n  ';
});

var MessageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersLookup__MessageOverlay',
  componentId: 'sc-zkoaew-1'
})(['display:flex;justify-content:center;align-items:center;box-sizing:border-box;background-color:rgb(255,255,255);padding:16px;width:100%;height:90px;z-index:1;', ';'], _theme2.default.min.medium(_templateObject));

var EVIcon = _styledComponents2.default.img.withConfig({
  displayName: 'FiltersLookup__EVIcon',
  componentId: 'sc-zkoaew-2'
})(['']);

var optionComponents = exports.optionComponents = {
  subregions: function subregions(filterKey, props) {
    return _react2.default.createElement(_RegionalFilter2.default, {
      key: filterKey,
      filterKey: filterKey,
      selectedData: props.selectedFilters.subregions,
      filtersStyling: props.config.stylingConfig,
      updateFilters: props.updateFilters,
      globalStyling: props.globalStyling,
      availableFilterValues: props.availableFilterValues,
      translations: props.config.translations
    });
  },
  location: function location(filterKey, props, ref) {
    return _react2.default.createElement(_LocationFilter2.default, {
      updateSessionPreferences: props.updateSessionPreferences,
      availableFilterValues: props.availableFilterValues,
      selectedFilters: props.selectedFilters,
      selectedData: props.selectedFilters,
      updateFilters: props.updateFilters,
      globalStyling: props.globalStyling,
      filtersStyling: props.config.stylingConfig,
      translations: props.config.translations,
      changeLocation: props.changeLocation,
      location: props.location,
      searchCountryCode: props.searchCountryCode,
      ref: ref,
      key: filterKey
    });
  },
  distance: function distance(filterKey, props, ref) {
    var googleAvailable = props.shared.google.googleAvailable;

    return googleAvailable && _react2.default.createElement(_LocationDistanceFilter2.default, {
      updateSessionPreferences: props.updateSessionPreferences,
      availableFilterValues: props.availableFilterValues,
      selectedFilters: props.selectedFilters,
      selectedData: props.selectedFilters,
      updateFilters: props.updateFilters,
      globalStyling: props.globalStyling,
      filtersStyling: props.config.stylingConfig,
      translations: props.config.translations,
      changeLocation: props.changeLocation,
      location: props.location,
      searchCountryCode: props.searchCountryCode,
      ref: ref,
      key: filterKey
    });
  },
  exteriorColours: function exteriorColours(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.exteriorColoursFilterTitle,
        active: !!props.selectedFilters.exteriorColours,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.exteriorColours,
        selectedData: props.selectedFilters.exteriorColours,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  interiorShades: function interiorShades(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.interiorShadeFilterTitle,
        active: !!props.selectedFilters.interiorShades,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.interiorShades,
        selectedData: props.selectedFilters.interiorShades,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  bodystyle: function bodystyle(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.bodystyleFilterTitle,
        active: !!props.selectedFilters.bodystyle,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.bodystyle,
        selectedData: props.selectedFilters.bodystyle,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  model: function model(filterKey, props, ref) {
    var translations = props.config.translations;

    var translateModel = function translateModel(models) {
      return models.map(function (model) {
        return translations[model.value] ? (0, _extends3.default)({}, model, { display: translations[model.value] }) : model;
      });
    };

    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.modelFilterTitle,
        active: !!props.selectedFilters.model,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: translateModel(props.availableFilterValues.model),
        selectedData: props.selectedFilters.model,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  variant: function variant(filterKey, props, ref) {
    var _props$config$styling = props.config.stylingConfig,
        stylingConfig = _props$config$styling === undefined ? {} : _props$config$styling;
    // variant text should never get transformed from what comes from IMS

    var stylingConfigNoTransform = (0, _extends3.default)({}, stylingConfig, {
      filterMenuFont: (0, _extends3.default)({}, stylingConfig.filterMenuFont || {}, {
        transform: 'none'
      })
    });

    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.variantFilterTitle,
        active: !!props.selectedFilters.variant,
        disabled: !props.selectedFilters.model || !props.availableFilterValues.variant.length,
        globalStyling: props.globalStyling,
        stylingConfig: stylingConfigNoTransform,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.variant,
        selectedData: props.selectedFilters.variant,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: stylingConfigNoTransform,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  modelVariants: function modelVariants(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.variantFilterTitle,
        active: !!props.selectedFilters.modelVariants,
        disabled: !props.selectedFilters.model || !props.availableFilterValues.modelVariants.length,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.modelVariants,
        selectedData: props.selectedFilters.modelVariants,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  vehicleTypes: function vehicleTypes(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.vehicleTypes,
        active: !!props.selectedFilters.vehicleTypes,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.vehicleTypes,
        selectedData: props.selectedFilters.vehicleTypes,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  engine: function engine(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.engineFilterTitle,
        active: !!props.selectedFilters.engine,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.engine,
        selectedData: props.selectedFilters.engine,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        applyOrdering: function applyOrdering(a, b) {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        },
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  transmission: function transmission(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.transmissionFilterTitle,
        active: !!props.selectedFilters.transmission,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.transmission,
        selectedData: props.selectedFilters.transmission,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  yearRange: function yearRange(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.ageFilterTitle,
        active: !!props.selectedFilters.yearRangeMin || !!props.selectedFilters.yearRangeMax,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_DualHandledRangeFilter2.default, {
        filterKeyMin: 'yearRangeMin',
        filterKeyMax: 'yearRangeMax',
        filterLabel: props.config.translations.ageFilterLabel,
        availableValues: {
          min: props.availableFilterValues.yearRange.min,
          max: props.availableFilterValues.yearRange.max
        },
        selectedValues: {
          min: props.selectedFilters.yearRangeMin || props.availableFilterValues.yearRange.min,
          max: props.selectedFilters.yearRangeMax || props.availableFilterValues.yearRange.max
        },
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        filtersStyling: props.config.stylingConfig
      })
    );
  },
  modelYearRange: function modelYearRange(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.modelYearFilterTitle,
        active: !!props.selectedFilters.modelYearRangeMin || !!props.selectedFilters.modelYearRangeMax,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_DualHandledRangeFilter2.default, {
        filterKeyMin: 'modelYearRangeMin',
        filterKeyMax: 'modelYearRangeMax',
        filterLabel: props.config.translations.modelYearFilterTitle,
        availableValues: {
          min: props.availableFilterValues.modelYearRange.min,
          max: props.availableFilterValues.modelYearRange.max
        },
        selectedValues: {
          min: props.selectedFilters.modelYearRangeMin || props.availableFilterValues.modelYearRange.min,
          max: props.selectedFilters.modelYearRangeMax || props.availableFilterValues.modelYearRange.max
        },
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        filtersStyling: props.config.stylingConfig
      })
    );
  },
  maxOdometer: function maxOdometer(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.odometerFilterTitle,
        active: !!props.selectedFilters.maxOdometer,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_OneHandledRangeFilter2.default, {
        filterKey: filterKey,
        filterLabel: props.config.translations.maxMilesFilterTitle,
        step: 500,
        selectedValue: props.selectedFilters.maxOdometer === undefined || props.selectedFilters.maxOdometer === null ? props.availableFilterValues.maxOdometer : props.selectedFilters.maxOdometer,
        maxValue: props.availableFilterValues.maxOdometer,
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        stylingConfig: props.config.stylingConfig,
        filtersStyling: props.config.stylingConfig,
        locale: props.locale,
        formatter: function formatter(value) {
          return (0, _numbers.localiseNumber)(value, props.locale);
        },
        translations: props.config.translations
      })
    );
  },
  features: function features(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.equipmentFilterTitle,
        active: !!props.selectedFilters.features,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.features,
        selectedData: props.selectedFilters.features,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  fuelType: function fuelType(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.fuelTypeFilterTitle,
        active: !!props.selectedFilters.fuelType,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.fuelType,
        selectedData: props.selectedFilters.fuelType,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  },
  co2Emissions: function co2Emissions(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.co2EmissionsFilterTitle,
        active: !!props.selectedFilters.co2Emissions,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_OneHandledRangeFilter2.default, {
        filterKey: filterKey,
        filterLabel: props.config.translations.co2EmissionsFilterSubTitle,
        step: 1,
        selectedValue: props.selectedFilters.co2Emissions === undefined || props.selectedFilters.co2Emissions === null ? props.availableFilterValues.maxCo2Emission : props.selectedFilters.co2Emissions,
        maxValue: props.availableFilterValues.maxCo2Emission,
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        stylingConfig: props.config.stylingConfig,
        filtersStyling: props.config.stylingConfig,
        locale: props.locale,
        unit: props.config.translations.co2Unit,
        translations: props.config.translations,
        formatter: function formatter(value) {
          var co2Value = (0, _numbers.localiseNumber)(value, props.locale);
          return (0, _translateFromTemplate.translateFromTemplate)('co2UnitDisplayText', {
            VALUE: co2Value
          }, props.config.translations);
        }
      })
    );
  },
  searchElectric: function searchElectric(filterKey, props) {
    var searchElectricChecked = function searchElectricChecked(selectedFuelType) {
      var typeElectric = ['electric', 'hev', 'phev'];
      return selectedFuelType ? typeElectric.every(function (v) {
        return selectedFuelType.includes(v);
      }) : false;
    };

    var updateFiltersSearchElectric = function updateFiltersSearchElectric(allElectricSelected) {
      var typeElectric = ['electric', 'hev', 'phev'];

      props.updateFilters([{
        key: 'fuelType',
        value: allElectricSelected ? typeElectric : []
      }]);
    };

    return _react2.default.createElement(
      CheckboxWrapper,
      {
        key: filterKey,
        role: 'checkbox',
        'aria-checked': searchElectricChecked(props.selectedFilters.fuelType),
        'aria-labelledby': 'only-electric',
        searchElectric: true,
        onClick: function onClick() {
          return updateFiltersSearchElectric(!searchElectricChecked(props.selectedFilters.fuelType));
        }
      },
      _react2.default.createElement(_CheckBox2.default, {
        id: 'only-electric',
        checked: searchElectricChecked(props.selectedFilters.fuelType),
        onColour: props.globalStyling.colours.primaryBrandColour.value
      }),
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n            margin: 0px 16px;\n            width: 100%;\n              ' + (0, _Global.fontStyleOverride)(props.config.stylingConfig.filterHeadingFontElectric) + '\n            ';
          }
        },
        props.config.translations.searchElectric
      ),
      _react2.default.createElement(EVIcon, { src: props.config.stylingConfig.filterElectricIcon })
    );
  },
  onlyApproved: function onlyApproved(filterKey, props) {
    return _react2.default.createElement(
      CheckboxWrapper,
      {
        tabIndex: 0,
        key: filterKey,
        role: 'checkbox',
        'aria-checked': props.selectedFilters.onlyApproved === 'true' ? 'true' : 'false',
        'aria-labelledby': 'only-approved',
        onClick: function onClick() {
          return props.updateFilters([{
            key: filterKey,
            value: props.selectedFilters.onlyApproved === 'true' ? 'false' : 'true'
          }]);
        },
        onKeyDown: function onKeyDown(e) {
          if (e.key === 'Enter') {
            props.updateFilters([{
              key: filterKey,
              value: props.selectedFilters.onlyApproved === 'true' ? 'false' : 'true'
            }]);
          }
        }
      },
      _react2.default.createElement(_CheckBox2.default, {
        id: 'only-approved',
        checked: props.selectedFilters.onlyApproved === 'true',
        onColour: props.globalStyling.colours.primaryBrandColour.value,
        ariaLabelText: props.config.translations.onlyApprovedTitle
      }),
      _react2.default.createElement(
        _Global.Paragraph,
        {
          role: 'checkbox',
          'aria-checked': props.selectedFilters.onlyApproved === 'true' ? 'true' : 'false',
          styleOverride: function styleOverride() {
            return '\n          margin: 0px 16px;\n            ' + (0, _Global.fontStyleOverride)(props.config.stylingConfig.filterHeadingFont) + '\n          ';
          }
        },
        props.config.translations.onlyApprovedTitle
      )
    );
  },
  maxFuelConsumption: function maxFuelConsumption(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.fuelconsumptionFilterTitle,
        active: !!props.selectedFilters.maxFuelConsumption,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_OneHandledRangeFilter2.default, {
        filterKey: filterKey,
        filterLabel: props.config.translations.fuelconsumptionFilterSubTitle,
        step: 2,
        selectedValue: props.selectedFilters.maxFuelConsumption === undefined || props.selectedFilters.maxFuelConsumption === null ? props.availableFilterValues.fuelConsumption[0].value : props.selectedFilters.maxFuelConsumption,
        maxValue: props.availableFilterValues.fuelConsumption[0].value,
        minValue: props.availableFilterValues.fuelConsumption[props.availableFilterValues.fuelConsumption.length - 1].value,
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        stylingConfig: props.config.stylingConfig,
        filtersStyling: props.config.stylingConfig,
        locale: props.locale,
        formatter: function formatter(value) {
          var fuelConsumptionValue = (0, _numbers.localiseNumber)(value, props.locale);

          return (0, _translateFromTemplate.translateFromTemplate)('fuelConsumptionText', {
            VALUE: fuelConsumptionValue
          }, props.config.translations);
        },
        translations: props.config.translations
      })
    );
  },
  defaultPriceRange: function defaultPriceRange(filterKey, props) {
    var currentCurrency = props.shared.sessionPreferences.currency;

    var exchangeRates = props.shared.currencyConversion.exchangeRates && props.shared.currencyConversion.exchangeRates[currentCurrency] && props.shared.currencyConversion.exchangeRates[currentCurrency].rates;

    var rangeSliderSteps = 500;

    var getMinAvailableValue = function getMinAvailableValue() {
      return (0, _helpers.convertPriceCurrency)(props.availableFilterValues.minDefaultPrice.value, exchangeRates, currentCurrency, props.availableFilterValues.minDefaultPrice.currency, rangeSliderSteps, 'min');
    };

    var getMaxAvailableValue = function getMaxAvailableValue() {
      return (0, _helpers.convertPriceCurrency)(props.availableFilterValues.maxDefaultPrice.value, exchangeRates, currentCurrency, props.availableFilterValues.maxDefaultPrice.currency, rangeSliderSteps);
    };

    return _react2.default.createElement(
      _react.Fragment,
      { key: filterKey },
      !currentCurrency ? _react2.default.createElement(
        MessageOverlay,
        { tabIndex: '0' },
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return 'text-align: center; font-size: 12px; width: 60%;';
            }
          },
          props.config.translations.currencyUnavailable
        )
      ) : _react2.default.createElement(_DualHandledRangeFilter2.default, {
        filterKeyMin: 'minDefaultPrice',
        filterKeyMax: 'maxDefaultPrice',
        filterLabel: props.config.translations.priceFilterTitle,
        'aria-label': props.config.translations.priceFilterTitle,
        step: rangeSliderSteps,
        availableValues: {
          min: getMinAvailableValue(),
          max: getMaxAvailableValue()
        },
        selectedValues: {
          min: props.selectedFilters.minDefaultPrice || getMinAvailableValue(),
          max: props.selectedFilters.maxDefaultPrice || getMaxAvailableValue()
        },
        globalStyling: props.globalStyling,
        updateFilters: function updateFilters(rangeFilterValues) {
          var updatedFilterValues = rangeFilterValues.map(function (rv) {
            return (0, _extends3.default)({}, rv, {
              value: rv.value
            });
          });
          props.updateFilters(updatedFilterValues);
        },
        filtersStyling: props.config.stylingConfig,
        hideValues: !currentCurrency,
        formatter: function formatter(value) {
          return (0, _numbers.localiseCurrency)(Number(value), props.locale, currentCurrency, 0);
        },
        translations: props.config.translations
      })
    );
  },
  priceRange: function priceRange(filterKey, props) {
    var step = 500;

    var getMinAvailableValue = function getMinAvailableValue() {
      var minPriceRange = props.availableFilterValues.minPriceRange;

      var min = Math.floor(minPriceRange / step) * step;
      return min;
    };

    var getMaxAvailableValue = function getMaxAvailableValue() {
      var maxPriceRange = props.availableFilterValues.maxPriceRange;

      return Math.ceil(maxPriceRange / step) * step;
    };

    return _react2.default.createElement(_DualHandledRangeFilter2.default, {
      key: filterKey,
      filterKeyMin: 'minPriceRange',
      filterKeyMax: 'maxPriceRange',
      filterLabel: props.config.translations.priceFilterTitle,
      step: step,
      availableValues: {
        min: getMinAvailableValue(),
        max: getMaxAvailableValue()
      },
      selectedValues: {
        min: props.selectedFilters.minPriceRange || getMinAvailableValue(),
        max: props.selectedFilters.maxPriceRange || getMaxAvailableValue()
      },
      globalStyling: props.globalStyling,
      updateFilters: props.updateFilters,
      filtersStyling: props.config.stylingConfig,
      formatter: function formatter(value) {
        return (0, _numbers.localiseCurrency)(Number(value), props.locale, props.currencyCode, 0);
      }
    });
  }
};