'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _BentleyLocationOverlay = require('./BentleyLocationOverlay');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_BentleyLocationOverlay).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'BentleyLocationOverlay';
var id = exports.id = 'BentleyLocationOverlay';
var availableToAdministration = exports.availableToAdministration = false;