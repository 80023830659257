'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _rcSlider = require('rc-slider');

var _useSliderValues2 = require('../../../../linked-modules/Filters/RangeFilters/useSliderValues');

var _useSliderValues3 = _interopRequireDefault(_useSliderValues2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__ContentWrapper',
  componentId: 'sc-1ttj915-0'
})(['padding-bottom:15px;']);

var UnstyledLink = _styledComponents2.default.a.withConfig({
  displayName: 'DualHandledRangeFilter__UnstyledLink',
  componentId: 'sc-1ttj915-1'
})(['text-decoration:none;']);

var Super = _styledComponents2.default.sup.withConfig({
  displayName: 'DualHandledRangeFilter__Super',
  componentId: 'sc-1ttj915-2'
})(['font-size:10.5px;font-family:\'AstonMartinSans-Regular\';color:#7a7a7a;padding-left:5px;']);

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__Label',
  componentId: 'sc-1ttj915-3'
})(['display:flex;justify-content:flex-end;align-items:center;font-size:14px;margin-bottom:10px;']);

var HandleContainer = (0, _styledComponents2.default)(_rcSlider.Handle).withConfig({
  displayName: 'DualHandledRangeFilter__HandleContainer',
  componentId: 'sc-1ttj915-4'
})(['margin-top:-8px;position:absolute;touch-action:pan-x;cursor:grab;outline:none;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__IconWrapper',
  componentId: 'sc-1ttj915-5'
})(['width:20px;height:20px;', ';background-size:cover;'], function (_ref) {
  var src = _ref.src;
  return 'background-image: url(' + src + ')';
});

exports.default = function (_ref2) {
  var _ref2$step = _ref2.step,
      step = _ref2$step === undefined ? 1 : _ref2$step,
      availableValues = _ref2.availableValues,
      selectedValues = _ref2.selectedValues,
      filtersStyling = _ref2.filtersStyling,
      _ref2$formatter = _ref2.formatter,
      formatter = _ref2$formatter === undefined ? function (val) {
    return val;
  } : _ref2$formatter,
      filterKeyMin = _ref2.filterKeyMin,
      filterKeyMax = _ref2.filterKeyMax,
      updateFilters = _ref2.updateFilters,
      disclaimerLink = _ref2.disclaimerLink,
      superText = _ref2.superText;

  var _useSliderValues = (0, _useSliderValues3.default)(selectedValues),
      updateSliderValues = _useSliderValues.updateSliderValues,
      selectedSliderValues = _useSliderValues.selectedSliderValues;

  var updateFilterValues = function updateFilterValues(values) {
    var _values = (0, _slicedToArray3.default)(values, 2),
        minValue = _values[0],
        maxValue = _values[1];

    updateFilters([{ key: filterKeyMin, value: minValue }, { key: filterKeyMax, value: maxValue }]);
  };

  var minValue = selectedSliderValues.minValue,
      maxValue = selectedSliderValues.maxValue;


  var CustomHandle = function CustomHandle(props) {
    return _react2.default.createElement(
      HandleContainer,
      {
        key: props.index,
        value: props.value,
        offset: props.offset
      },
      _react2.default.createElement(IconWrapper, { src: filtersStyling.filterSliderIcon, alt: '' })
    );
  };

  var minRangeValue = Number(availableValues.min);
  var maxRangeValue = Number(availableValues.max);

  var scrollToDisclaimer = function scrollToDisclaimer() {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  return _react2.default.createElement(
    ContentWrapper,
    null,
    _react2.default.createElement(
      Label,
      null,
      formatter(minValue) + ' - ' + formatter(maxValue),
      disclaimerLink && superText && _react2.default.createElement(
        UnstyledLink,
        {
          href: '#',
          onClick: function onClick(e) {
            e.preventDefault();
            scrollToDisclaimer();
          }
        },
        _react2.default.createElement(
          Super,
          null,
          superText
        )
      )
    ),
    _react2.default.createElement(_rcSlider.Range, {
      trackStyle: [{ backgroundColor: '#000000', height: '1px' }],
      handle: CustomHandle,
      step: step,
      value: [Number(minValue), Number(maxValue)],
      min: minRangeValue,
      max: maxRangeValue,
      allowCross: false,
      onChange: updateSliderValues,
      onAfterChange: updateFilterValues,
      railStyle: { height: '1px' },
      stepStyle: { height: '1px' }
    })
  );
};