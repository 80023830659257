'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useOutsideClick = require('../../../../hooks/useOutsideClick');

var _useOutsideClick2 = _interopRequireDefault(_useOutsideClick);

var _numbers = require('../../../../shared/localisation/numbers');

var _translateFromTemplate = require('../../../../shared/localisation/translateFromTemplate');

var _AppState = require('../../../../types/AppState');

var _searchResultsTypes = require('../../../../types/AstonMartin/searchResultsTypes');

var _filtersTypes = require('../../../../types/Bentley/filtersTypes');

var _DropdownlistIcon = require('./DropdownlistIcon');

var _DropdownlistIcon2 = _interopRequireDefault(_DropdownlistIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__Container',
  componentId: 'sc-8nk7rr-0'
})(['display:flex;flex-direction:column;width:100%;position:relative;box-sizing:border-box;padding:5px 10px 20px 10px;background-color:#ffffff;border-right:solid 1px #bbbbbb;border-left:solid 1px #bbbbbb;']);

var SelectContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__SelectContainer',
  componentId: 'sc-8nk7rr-1'
})(['display:flex;height:40px;cursor:pointer;width:100%;padding:22px 15px;border:1px solid #bbbbbb;box-sizing:border-box;align-items:center;justify-content:space-between;span{font-size:14px;}']);

var ExpandContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__ExpandContainer',
  componentId: 'sc-8nk7rr-2'
})(['width:calc(100% - 20px);border:1px solid #d5d5d5;box-sizing:border-box;position:absolute;top:50px;max-height:210px;overflow-y:auto;background-color:#ffffff;z-index:2;']);

var Option = _styledComponents2.default.div.withConfig({
  displayName: 'DistanceFilter__Option',
  componentId: 'sc-8nk7rr-3'
})(['padding:4px 10px;&:hover{color:#fff;background-color:#335b4c;']);

var DistanceFilter = function DistanceFilter(_ref) {
  var updateFilters = _ref.updateFilters,
      translations = _ref.translations,
      availableFilterValues = _ref.availableFilterValues,
      arrowUpIcon = _ref.arrowUpIcon,
      arrowDownIcon = _ref.arrowDownIcon,
      innerRef = _ref.innerRef;
  var distanceDefaultOption = translations.distanceDefaultOption;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      toggleOpen = _useState2[1];

  var _useState3 = (0, _react.useState)({
    value: undefined,
    label: distanceDefaultOption
  }),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      selectedValue = _useState4[0],
      setSelectedValue = _useState4[1];

  var isClickOutside = (0, _useOutsideClick2.default)(innerRef);
  var subregions = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.filters.selectedFilters ? state.shared.filters.selectedFilters.subregions : undefined;
  });

  (0, _react.useEffect)(function () {
    if ((subregions || []).length === 0) {
      setSelectedValue({
        value: undefined,
        label: (0, _translateFromTemplate.translateFromTemplate)('distanceDefaultOption', {}, translations)
      });

      updateFilters([{
        key: 'maxDistance',
        value: undefined
      }]);
    }
  }, [subregions, _translateFromTemplate.translateFromTemplate, translations]);

  (0, _react.useEffect)(function () {
    if (open && isClickOutside) {
      toggleOpen(false);
    }
  }, [isClickOutside, open]);

  var handleClick = (0, _react.useCallback)(function (option) {
    // @ts-ignore: Can't type hook state.
    setSelectedValue(option);
    toggleOpen(!open);
    updateFilters([{
      key: 'maxDistance',
      value: option.value ? Math.ceil((0, _numbers.convertMilesToKm)(option.value)) : []
    }]);
  }, [open]);

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      SelectContainer,
      { onClick: function onClick() {
          return toggleOpen(!open);
        } },
      _react2.default.createElement(
        'span',
        null,
        selectedValue.label
      ),
      _react2.default.createElement(_DropdownlistIcon2.default, {
        toggle: open,
        openIcon: arrowUpIcon,
        closeIcon: arrowDownIcon
      })
    ),
    open ? _react2.default.createElement(
      ExpandContainer,
      null,
      _react2.default.createElement(
        'div',
        {
          style: {
            display: 'flex',
            flexDirection: 'column',
            padding: '4px 0',
            fontSize: '14px',
            cursor: 'pointer'
          }
        },
        availableFilterValues.map(function (option) {
          var label = (0, _translateFromTemplate.translateFromTemplate)('distances', {
            MILES: option
          }, translations);
          return _react2.default.createElement(
            Option,
            {
              key: option,
              onClick: function onClick() {
                handleClick({ value: option, label: label });
              }
            },
            _react2.default.createElement(
              'span',
              null,
              label
            )
          );
        }),
        _react2.default.createElement(
          Option,
          {
            onClick: function onClick() {
              handleClick({ value: undefined, label: distanceDefaultOption });
            }
          },
          _react2.default.createElement(
            'span',
            null,
            distanceDefaultOption
          )
        )
      )
    ) : null
  );
};

exports.default = DistanceFilter;