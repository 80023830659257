'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LinkButtton = _styledComponents2.default.button.withConfig({
  displayName: 'Link__LinkButtton',
  componentId: 'sc-p8tey3-0'
})(['border:none;text-decoration:underline;', ';color:', ';font-family:', ';cursor:pointer;background-color:transparent;height:45px;outline:none;font-size:16px;'], function (_ref) {
  var fullWidth = _ref.fullWidth;
  return fullWidth && 'width: 100%;';
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.linkColour;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.fontFamily ? theme.fontFamily : 'inherit';
});

function Link(_ref4) {
  var props = (0, _objectWithoutProperties3.default)(_ref4, []);

  return _react2.default.createElement(LinkButtton, props);
}

Link.defaultProps = {
  fullWidth: false,
  theme: {
    fontFamily: 'inherit',
    linkColour: '#000000'
  }
};

exports.default = Link;