'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Spacer = require('./Spacer');

var _Spacer2 = _interopRequireDefault(_Spacer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Spacer2.default;