'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Chunky;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'JaguarArrow__IconWrapper',
  componentId: 'sc-wxbnhj-0'
})(['display:flex;align-items:center;svg > rect:not([fill=\'none\']){fill:', ';}svg > path:not([fill]){fill:', ';}svg{width:', ';height:', ';}'], function (_ref) {
  var colour = _ref.colour;
  return colour || '#fff';
}, function (_ref2) {
  var colour = _ref2.colour;
  return colour || '#fff';
}, function (_ref3) {
  var width = _ref3.width;
  return width;
}, function (_ref4) {
  var height = _ref4.height;
  return height;
});

function Chunky(_ref5) {
  var background = _ref5.background,
      colour = _ref5.colour,
      width = _ref5.width,
      height = _ref5.height;

  return _react2.default.createElement(
    IconWrapper,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 25 23' },
      _react2.default.createElement('path', { d: 'M9.8 20.4L7 17.7l6.5-6.5L7 4.7 9.8 2l9.2 9.2z' })
    )
  );
}

Chunky.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};