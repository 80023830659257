'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.territory = exports.enquiry = exports.language = exports.router = undefined;

var _router = require('./router');

var router = _interopRequireWildcard(_router);

var _language = require('./language');

var language = _interopRequireWildcard(_language);

var _enquiry = require('./enquiry');

var enquiry = _interopRequireWildcard(_enquiry);

var _territory = require('./territory');

var territory = _interopRequireWildcard(_territory);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.router = router;
exports.language = language;
exports.enquiry = enquiry;
exports.territory = territory;