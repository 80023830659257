'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    background-color: #F0F0F0;\n    display:flex;\n    flex-wrap:wrap;\n    min-height: 500px;\n    padding:1% 2% 2% 2%;\n  '], ['\n    background-color: #F0F0F0;\n    display:flex;\n    flex-wrap:wrap;\n    min-height: 500px;\n    padding:1% 2% 2% 2%;\n  ']);

exports.default = BudgetResultPane;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _BudgetResultTile = require('../BudgetResultTile');

var _BudgetResultTile2 = _interopRequireDefault(_BudgetResultTile);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BudgetResultsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'BudgetResultsContainer'
}).withConfig({
  displayName: 'BudgetResultsPane__BudgetResultsContainer',
  componentId: 'sc-14wbr3m-0'
})(['', ';'], _theme2.default.min.large(_templateObject));

function BudgetResultPane(_ref) {
  var _ref$results = _ref.results,
      results = _ref$results === undefined ? [] : _ref$results,
      moreInfoClick = _ref.moreInfoClick,
      translations = _ref.translations,
      viewNowClick = _ref.viewNowClick,
      placeholdingImage = _ref.placeholdingImage,
      globalStyling = _ref.globalStyling,
      buttonStyle = _ref.buttonStyle,
      locale = _ref.locale;

  return _react2.default.createElement(
    BudgetResultsContainer,
    null,
    results.map(function (result) {
      return _react2.default.createElement(_BudgetResultTile2.default, {
        key: '' + result.id + result.model,
        align: 'left',
        onMoreInfoClick: moreInfoClick,
        onViewNowClick: viewNowClick,
        result: result,
        translations: translations,
        placeholdingImage: placeholdingImage,
        globalStyling: globalStyling,
        buttonStyle: buttonStyle,
        locale: locale
      });
    })
  );
}