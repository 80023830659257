'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIndexContent = exports.searchable = exports.icon = exports.description = exports.availableToAdministration = exports.id = exports.name = exports.defaultConfig = exports.config = exports.component = undefined;

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _ModelGrid = require('./ModelGrid');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ModelGrid).default;
  }
});

var _config = require('./config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _defaultConfig = require('./defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});

var _Icon = require('./Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'ModelGrid';
var id = exports.id = 'ModelGrid';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'A grid view of car model items with image, text and button';
var icon = exports.icon = _Icon2.default;

var searchable = exports.searchable = true;
var getIndexContent = exports.getIndexContent = function getIndexContent(_ref) {
  var _ref$config = _ref.config;
  _ref$config = _ref$config === undefined ? {} : _ref$config;
  var _ref$config$items = _ref$config.items,
      items = _ref$config$items === undefined ? [] : _ref$config$items,
      relativePath = _ref.relativePath,
      instanceId = _ref.instanceId;
  return [].concat((0, _toConsumableArray3.default)(items.map(function (_ref2) {
    var _ref2$translations = _ref2.translations,
        headline = _ref2$translations.headline,
        body = _ref2$translations.body;
    return {
      title: headline,
      content: body
    };
  })), (0, _toConsumableArray3.default)(items.map(function (_ref3) {
    var _ref3$translations = _ref3.translations,
        headline = _ref3$translations.headline,
        link = _ref3$translations.link;
    return {
      title: headline,
      content: link
    };
  }))).map(function (_ref4, index) {
    var title = _ref4.title,
        content = _ref4.content;
    return {
      key: instanceId + '-' + index,
      title: title,
      content: content,
      link: relativePath
    };
  }).filter(function (_ref5) {
    var title = _ref5.title,
        content = _ref5.content;
    return !!title || !!content;
  });
};