'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      margin-top: 95px;\n      padding: 0;\n  '], ['\n      margin-top: 95px;\n      padding: 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 20px;\n    gap: 10px;\n  '], ['\n    padding: 0 20px;\n    gap: 10px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _filters = require('../../../../shared/filters');

var _sessionPreferences = require('../../../../shared/sessionPreferences');

var _FiltersLookup = require('./FiltersLookup');

var _TextLinkButton = require('../../../../components/Genesis/TextLinkButton');

var _TextLinkButton2 = _interopRequireDefault(_TextLinkButton);

var _AppShared = require('../../../../types/AppShared');

var _routing = require('../../../../shared/routing');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FiltersWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersContainer__FiltersWrapper',
  componentId: 'sc-1t9zcd2-0'
})(['box-sizing:border-box;padding:0 20px;width:100%;', ''], _Genesis2.default.min.medium(_templateObject));

var ActionsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersContainer__ActionsWrapper',
  componentId: 'sc-1t9zcd2-1'
})(['display:flex;justify-content:space-between;margin-top:10px;', ''], _Genesis2.default.max.medium(_templateObject2));

var Filters = function (_Component) {
  (0, _inherits3.default)(Filters, _Component);

  function Filters(props) {
    (0, _classCallCheck3.default)(this, Filters);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Filters.__proto__ || (0, _getPrototypeOf2.default)(Filters)).call(this, props));

    Object.defineProperty(_this, 'resetFilters', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            resetFilters = _this$props.resetFilters,
            defaultSelectedFilters = _this$props.config.defaultSelectedFilters;

        _this.collapseFilters();
        resetFilters(defaultSelectedFilters);
      }
    });
    Object.defineProperty(_this, 'collapseFilters', {
      enumerable: true,
      writable: true,
      value: function value() {
        var config = _this.props.config;

        config.filterOptions.items.forEach(function (element) {
          var current = _this.filterRefs[element.lookupKey].current;

          if (current && current.setOpenState) {
            current.setOpenState(false);
          }
        });
      }
    });

    _this.filterRefs = {};
    props.config.filterOptions.items.forEach(function (element) {
      _this.filterRefs[element.lookupKey] = _react2.default.createRef();
    });
    return _this;
  }

  (0, _createClass3.default)(Filters, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var props = this.props;
      var config = props.config;
      var translations = config.translations;

      var isDealer = (0, _routing.isDealerPage)();

      return props.availableFilterValues ? _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          FiltersWrapper,
          { 'data-cy': 'srp-filters' },
          props.config.filterOptions.items.filter(function (o) {
            return !isDealer || o.availableForDealer === isDealer;
          }).map(function (fo) {
            return _FiltersLookup.optionComponents[fo.lookupKey](fo.lookupKey, (0, _extends3.default)({}, props, {
              availableFilterValues: (0, _extends3.default)({}, props.availableFilterValues),
              config: config,
              translations: translations
            }), _this2.filterRefs[fo.lookupKey]);
          })
        ),
        _react2.default.createElement(
          ActionsWrapper,
          null,
          _react2.default.createElement(_TextLinkButton2.default, {
            variant: 'primary',
            text: translations.resetCTA,
            onClick: this.resetFilters,
            'data-cy': 'reset-filters',
            styleOverride: function styleOverride() {
              return 'width: 100%; justify-content:flex-start;';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.medium',
                template: 'width: 50%;'
              }];
            }
          }),
          _react2.default.createElement(_TextLinkButton2.default, {
            variant: 'primary',
            text: translations.applyCTA,
            onClick: function onClick() {
              return props.toggle();
            },
            styleOverride: function styleOverride() {
              return 'display:none;';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.medium',
                template: 'display:flex;'
              }];
            }
          })
        )
      ) : _react2.default.createElement('div', null);
    }
  }]);
  return Filters;
}(_react.Component);

var mapStateToProps = function mapStateToProps(_ref) {
  var router = _ref.router,
      shared = _ref.shared,
      config = _ref.config;
  return (0, _extends3.default)({}, shared.filters, {
    page: router.path,
    locale: config.settings.locale,
    currencyCode: config.config.global.inventory.currencyCode,
    location: shared.sessionPreferences.location || {}
  });
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, _ref2) {
  var _ref2$config$endpoint = _ref2.config.endpoints,
      endpoints = _ref2$config$endpoint === undefined ? {} : _ref2$config$endpoint;
  return {
    updateSessionPreferences: function updateSessionPreferences(key, value) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences(key, value));
    },
    updateFilters: function updateFilters(filters) {
      return dispatch(_filters.actions.updateFilters(filters, endpoints.filters));
    },
    resetFilters: function resetFilters(urlConfig) {
      dispatch(_filters.actions.resetFilters(urlConfig));
      var query = window.location.search.substring(1);
      dispatch(_filters.actions.initialiseFilters(query, urlConfig));
    },
    searchWithFilters: function searchWithFilters() {
      dispatch(_filters.actions.searchWithFilters());
      dispatch(_sessionPreferences.actions.updateSessionPreferences('resetSearchResultsPageNumber', true));
    }
  };
};

exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Filters);