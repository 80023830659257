'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n\n  '], ['\n\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Header = _styledComponents2.default.header.withConfig({
  displayName: 'KiaBenefitsGermany__Header',
  componentId: 'sc-1yn0to1-0'
})(['background-color:#d9d9d9;display:flex;justify-content:flex-end;height:80px;', ';'], _theme2.default.max.large(_templateObject));

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'KiaBenefitsGermany__Logo',
  componentId: 'sc-1yn0to1-1'
})(['height:95%;margin:5px;']);

var KiaBenefitsGermany = function KiaBenefitsGermany() {
  return _react2.default.createElement(
    Header,
    null,
    _react2.default.createElement(Logo, {
      src: 'https://res.cloudinary.com/motortrak/f_auto,q_auto/v1682071190/kia/CSP_Kia_DE_Nameplate.png',
      alt: 'Zertifizierte Gebrauchtwagen von Kia'
    })
  );
};

exports.default = KiaBenefitsGermany;