'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 22px;\n  line-height: 30px;'], ['\n  font-size: 22px;\n  line-height: 30px;']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 12px;\n    line-height: 16px;\n  '], ['\n    font-size: 12px;\n    line-height: 16px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'DealerComments__Container',
  componentId: 'sc-1sd1hh4-0'
})(['display:flex;flex-direction:column;align-items:center;padding:0px 24px 70px;border-bottom:solid 1px #707070;margin:auto;width:75%;max-width:1020px;']);

var Header = _styledComponents2.default.h2.withConfig({
  displayName: 'DealerComments__Header',
  componentId: 'sc-1sd1hh4-1'
})(['height:50px;color:#000000;font-size:36px;line-height:50px;text-align:center;width:90%;margin-top:0px;', ';'], _theme2.default.max.small(_templateObject));

var MarkdownWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DealerComments__MarkdownWrapper',
  componentId: 'sc-1sd1hh4-2'
})(['display:flex;flex-direction:row;text-align:center;justify-content:center;font-family:AstonMartinSans-Regular;font-size:14px;letter-spacing:0px;line-height:18px;width:90%;white-space:pre-wrap;', ''], _theme2.default.max.small(_templateObject2));

exports.default = function (_ref) {
  var text = _ref.text,
      translations = _ref.translations;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        Header,
        null,
        translations.extraInformation
      ),
      _react2.default.createElement(
        MarkdownWrapper,
        null,
        text
      )
    )
  );
};