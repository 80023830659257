'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px 5%;\n  '], ['\n    padding: 20px 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormFields = require('./FormFields');

var _EmailVerification = require('./EmailVerification');

var _EmailVerification2 = _interopRequireDefault(_EmailVerification);

var _CheckBox = require('./CheckBox');

var _CheckBox2 = _interopRequireDefault(_CheckBox);

var _PersonalDataConsent = require('./PersonalDataConsent');

var _PersonalDataConsent2 = _interopRequireDefault(_PersonalDataConsent);

var _JLRMarketingAndDistributionConsent = require('./DataConsent/JLRMarketingAndDistributionConsent');

var _JLRMarketingAndDistributionConsent2 = _interopRequireDefault(_JLRMarketingAndDistributionConsent);

var _Global = require('./Global');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _validation = require('../helpers/validation');

var _formTitleOptions = require('../helpers/formTitleOptions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RegisterContent = _styledComponents2.default.div.withConfig({
  displayName: 'Register__RegisterContent',
  componentId: 'sc-14g9h0x-0'
})(['padding:20px 30% 60px;', ';'], _theme2.default.max.large(_templateObject));

var RegisterFailure = _styledComponents2.default.div.withConfig({
  displayName: 'Register__RegisterFailure',
  componentId: 'sc-14g9h0x-1'
})(['font-size:16px;color:#9e1b32;font-weight:600;margin-bottom:10px;']);
var HorizontalLine = _styledComponents2.default.div.withConfig({
  displayName: 'Register__HorizontalLine',
  componentId: 'sc-14g9h0x-2'
})(['border-bottom:1px solid #dedede;margin:20px 0;']);
var Link = _styledComponents2.default.a.withConfig({
  displayName: 'Register__Link',
  componentId: 'sc-14g9h0x-3'
})(['padding-left:10px;text-decoration:underline;', ';'], function (_ref) {
  var colour = _ref.colour;
  return colour && 'color: ' + colour + ';';
});
var DoubleRow = _styledComponents2.default.div.withConfig({
  displayName: 'Register__DoubleRow',
  componentId: 'sc-14g9h0x-4'
})(['display:flex;justify-content:space-between;', ';'], _theme2.default.max.large(_templateObject2));
var MobileSingleRow = _styledComponents2.default.div.withConfig({
  displayName: 'Register__MobileSingleRow',
  componentId: 'sc-14g9h0x-5'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject3));

var Register = function (_Component) {
  (0, _inherits3.default)(Register, _Component);

  function Register(props) {
    (0, _classCallCheck3.default)(this, Register);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Register.__proto__ || (0, _getPrototypeOf2.default)(Register)).call(this, props));

    Object.defineProperty(_this, 'onSelectChange', {
      enumerable: true,
      writable: true,
      value: function (_value) {
        function value(_x) {
          return _value.apply(this, arguments);
        }

        value.toString = function () {
          return _value.toString();
        };

        return value;
      }(function (_ref2) {
        var keyValue = _ref2.keyValue,
            value = _ref2.value,
            label = _ref2.label;

        var error = _this.validateSingle(keyValue, value);
        _this.setState(function (prevState) {
          var _ref3;

          return _ref3 = {}, (0, _defineProperty3.default)(_ref3, keyValue, { label: label, value: value }), (0, _defineProperty3.default)(_ref3, 'errors', (0, _extends3.default)({}, prevState.errors, error)), _ref3;
        });
      })
    });
    Object.defineProperty(_this, 'onInputChange', {
      enumerable: true,
      writable: true,
      value: function (_value2) {
        function value(_x2, _x3) {
          return _value2.apply(this, arguments);
        }

        value.toString = function () {
          return _value2.toString();
        };

        return value;
      }(function (formKey, value) {
        var error = _this.validateSingle(formKey, value);
        _this.setState(function (prevState) {
          var _ref4;

          return _ref4 = {}, (0, _defineProperty3.default)(_ref4, formKey, value), (0, _defineProperty3.default)(_ref4, 'errors', (0, _extends3.default)({}, prevState.errors, error)), _ref4;
        });
      })
    });
    Object.defineProperty(_this, 'onRegisterClick', {
      enumerable: true,
      writable: true,
      value: function value() {
        var validations = _this.validateForm();
        var valid = (0, _values2.default)(validations).filter(function (val) {
          return val !== undefined;
        }).length === 0;
        var customer = {
          customer: {
            title: _this.state.title.value,
            firstName: _this.state.firstName,
            lastName: _this.state.lastName,
            email: _this.state.email,
            phoneNumber: _this.state.phoneNumber,
            postCode: _this.state.postCode,
            vehiclePreferences: {
              interestedIn: _this.state.interestedIn,
              currentVehicle: _this.state.currentVehicle
            },
            contactPreferences: {
              marketing: _this.state.marketingConsent,
              customisedServices: _this.state.customisedServicesConsent,
              email: _this.state.emailConsent,
              phone: _this.state.phoneConsent,
              sms: _this.state.smsConsent,
              distributionList: _this.state.distributionListConsent
            }
          },
          credentials: {
            password: _this.state.repeatPassword
          }
        };
        if (valid) {
          _this.props.register(customer)();
          return;
        }
        window.scroll(0, 0);
        _this.setState(function () {
          return { errors: validations };
        });
      }
    });
    Object.defineProperty(_this, 'onRadioSelect', {
      enumerable: true,
      writable: true,
      value: function value(event) {
        var _event$target = event.target,
            name = _event$target.name,
            value = _event$target.value;

        _this.setState(function () {
          return (0, _defineProperty3.default)({}, name, value);
        });
      }
    });
    Object.defineProperty(_this, 'validateSingle', {
      enumerable: true,
      writable: true,
      value: function (_value3) {
        function value(_x4, _x5) {
          return _value3.apply(this, arguments);
        }

        value.toString = function () {
          return _value3.toString();
        };

        return value;
      }(function (formKey, value) {
        var nonValidatedKeys = ['postCode', 'interestedIn', 'currentVehicle', 'smsConsent', 'phoneConsent', 'emailConsent', 'termsAndConditionsAccept', 'distributionListConsent', 'marketingConsent'];

        if (nonValidatedKeys.includes(formKey)) return undefined;
        var validations = {
          title: _validation.required,
          firstName: _validation.required,
          lastName: _validation.required,
          email: _validation.validEmail,
          emailConfirm: _validation.validEmail,
          phoneNumber: _validation.validPhone,
          password: _validation.meetsPasswordPolicy,
          repeatPassword: _validation.required
        };

        return (0, _defineProperty3.default)({}, formKey, validations[formKey](value, _this.props.translations, true));
      })
    });
    Object.defineProperty(_this, 'validateForm', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$state = _this.state,
            title = _this$state.title,
            firstName = _this$state.firstName,
            lastName = _this$state.lastName,
            email = _this$state.email,
            emailConfirm = _this$state.emailConfirm,
            phoneNumber = _this$state.phoneNumber,
            password = _this$state.password,
            repeatPassword = _this$state.repeatPassword,
            termsAndConditionsAccept = _this$state.termsAndConditionsAccept,
            marketingConsent = _this$state.marketingConsent,
            customisedServicesConsent = _this$state.customisedServicesConsent;
        var _this$props = _this.props,
            translations = _this$props.translations,
            showUserCommunication = _this$props.showUserCommunication;


        var validations = {
          title: (0, _validation.required)(title.value, translations),
          firstName: (0, _validation.required)(firstName, translations),
          lastName: (0, _validation.required)(lastName, translations),
          email: (0, _validation.validEmail)(email, translations),
          emailConfirm: (0, _validation.valueMatchesRequired)(email, emailConfirm, translations),
          phone: (0, _validation.validPhone)(phoneNumber, translations),
          password: (0, _validation.meetsPasswordPolicy)(password, translations, true),
          repeatPassword: (0, _validation.passwordMatchesRequired)(password, repeatPassword, translations)
        };
        var termsAndConditionsValidation = {
          termsAndConditionsAccept: !termsAndConditionsAccept ? [translations.formValidationTermsConditionsAccept] : undefined
        };
        var newValidations = {
          marketingConsent: (0, _validation.required)(marketingConsent, translations),
          customisedServicesConsent: (0, _validation.required)(customisedServicesConsent, translations)
        };
        if (translations.registerTermsCondsAccept && !showUserCommunication) return (0, _extends3.default)({}, validations, newValidations, termsAndConditionsValidation);
        if (!showUserCommunication) return (0, _extends3.default)({}, validations, newValidations);
        return validations;
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props2 = _this.props,
            translations = _this$props2.translations,
            registerFailed = _this$props2.registerFailed,
            context = _this$props2.context,
            goToPage = _this$props2.goToPage,
            globalStyling = _this$props2.globalStyling,
            termsLink = _this$props2.termsLink,
            registerTextTransform = _this$props2.registerTextTransform,
            showUserCommunication = _this$props2.showUserCommunication,
            marketingLink = _this$props2.marketingLink,
            fnIfNotPreview = _this$props2.fnIfNotPreview,
            showJLRMarketingAndDistributionConsent = _this$props2.showJLRMarketingAndDistributionConsent,
            dataConsentFont = _this$props2.dataConsentFont,
            checkboxLabelStyles = _this$props2.checkboxLabelStyles;
        var _this$state2 = _this.state,
            title = _this$state2.title,
            firstName = _this$state2.firstName,
            lastName = _this$state2.lastName,
            email = _this$state2.email,
            emailConfirm = _this$state2.emailConfirm,
            phoneNumber = _this$state2.phoneNumber,
            postCode = _this$state2.postCode,
            interestedIn = _this$state2.interestedIn,
            currentVehicle = _this$state2.currentVehicle,
            password = _this$state2.password,
            repeatPassword = _this$state2.repeatPassword,
            phoneConsent = _this$state2.phoneConsent,
            emailConsent = _this$state2.emailConsent,
            smsConsent = _this$state2.smsConsent,
            termsAndConditionsAccept = _this$state2.termsAndConditionsAccept,
            errors = _this$state2.errors,
            marketingConsent = _this$state2.marketingConsent,
            customisedServicesConsent = _this$state2.customisedServicesConsent,
            distributionListConsent = _this$state2.distributionListConsent;

        var hasError = function hasError(field) {
          return errors[field] && errors[field][0];
        };
        return context === 'FORM' ? _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n            display: flex;\n            align-items: Center;\n            justify-content: center;\n            border-bottom: 1px solid #d8d8d8;\n            font-size: 20px;\n            text-transform: uppercase;\n            height: 75px;\n          ';
              }
            },
            translations.registerHeader
          ),
          _react2.default.createElement(
            RegisterContent,
            null,
            _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return '\n              text-align: left;\n              font-size: 20px;\n              margin-bottom: 25px;\n              text-transform: ' + (registerTextTransform || 'none') + '\n            ';
                }
              },
              translations.registerContentHeader
            ),
            registerFailed && _react2.default.createElement(
              RegisterFailure,
              null,
              translations.signInFailure
            ),
            _react2.default.createElement(
              DoubleRow,
              null,
              _react2.default.createElement(_FormFields.SelectField, {
                keyValue: 'title',
                value: title.value,
                onChange: _this.onSelectChange,
                label: translations.registerTitleLabel,
                options: (0, _formTitleOptions.titleOptions)(translations),
                error: hasError('title'),
                doubleRow: true,
                required: true
              })
            ),
            _react2.default.createElement(
              MobileSingleRow,
              null,
              _react2.default.createElement(_FormFields.SelectField, {
                keyValue: 'title',
                value: title,
                onChange: _this.onSelectChange,
                label: translations.registerTitleLabel,
                options: (0, _formTitleOptions.titleOptions)(translations),
                error: hasError('title'),
                required: true
              })
            ),
            _react2.default.createElement(
              DoubleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerFirstNameLabel,
                value: firstName,
                onChange: _this.onInputChange,
                keyValue: 'firstName',
                error: hasError('firstName'),
                doubleRow: true,
                required: true
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerLastNameLabel,
                value: lastName,
                onChange: _this.onInputChange,
                keyValue: 'lastName',
                error: hasError('lastName'),
                doubleRow: true,
                required: true
              })
            ),
            _react2.default.createElement(
              MobileSingleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerFirstNameLabel,
                value: firstName,
                onChange: _this.onInputChange,
                keyValue: 'firstName',
                error: hasError('firstName'),
                required: true
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerLastNameLabel,
                value: lastName,
                onChange: _this.onInputChange,
                keyValue: 'lastName',
                error: hasError('lastName'),
                required: true
              })
            ),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.registerEmailLabel,
              value: email,
              onChange: _this.onInputChange,
              keyValue: 'email',
              error: hasError('email'),
              required: true
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.registerEmailConfirmLabel,
              value: emailConfirm,
              onChange: _this.onInputChange,
              keyValue: 'emailConfirm',
              error: hasError('emailConfirm'),
              required: true
            }),
            _react2.default.createElement(
              DoubleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerPhoneLabel,
                value: phoneNumber,
                onChange: _this.onInputChange,
                keyValue: 'phoneNumber',
                error: hasError('phoneNumber'),
                doubleRow: true
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerPostcodeLabel,
                value: postCode,
                onChange: _this.onInputChange,
                keyValue: 'postCode',
                error: hasError('postCode'),
                doubleRow: true
              })
            ),
            _react2.default.createElement(
              MobileSingleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerPhoneLabel,
                value: phoneNumber,
                onChange: _this.onInputChange,
                keyValue: 'phoneNumber',
                error: hasError('phoneNumber')
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerPostcodeLabel,
                value: postCode,
                onChange: _this.onInputChange,
                keyValue: 'postCode',
                error: hasError('postCode')
              })
            ),
            _react2.default.createElement(HorizontalLine, null),
            _react2.default.createElement(
              DoubleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerVehicleInterestLabel,
                value: interestedIn,
                onChange: _this.onInputChange,
                keyValue: 'interestedIn',
                error: hasError('interestedIn'),
                doubleRow: true
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerCurrentVehicleLabel,
                value: currentVehicle,
                onChange: _this.onInputChange,
                keyValue: 'currentVehicle',
                placeholder: translations.registerCurrentVehiclePlaceholder,
                error: hasError('currentVehicle'),
                doubleRow: true
              })
            ),
            _react2.default.createElement(
              MobileSingleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerVehicleInterestLabel,
                value: interestedIn,
                onChange: _this.onInputChange,
                keyValue: 'interestedIn',
                error: hasError('interestedIn')
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.registerCurrentVehicleLabel,
                value: currentVehicle,
                onChange: _this.onInputChange,
                keyValue: 'currentVehicle',
                placeholder: translations.registerCurrentVehiclePlaceholder,
                error: hasError('currentVehicle')
              })
            ),
            _react2.default.createElement(HorizontalLine, null),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'password',
              label: translations.registerPasswordLabel,
              value: password,
              onChange: _this.onInputChange,
              keyValue: 'password',
              error: hasError('password'),
              required: true
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'password',
              label: translations.registerRepeatPasswordLabel,
              value: repeatPassword,
              onChange: _this.onInputChange,
              keyValue: 'repeatPassword',
              error: hasError('repeatPassword'),
              required: true
            }),
            showJLRMarketingAndDistributionConsent && _react2.default.createElement(_JLRMarketingAndDistributionConsent2.default, {
              translations: translations,
              onCheckBoxClick: fnIfNotPreview(_this.onInputChange),
              onRadioSelect: _this.onRadioSelect,
              marketingChecked: marketingConsent,
              marketingKey: 'marketingConsent',
              distributionListConsentChecked: distributionListConsent,
              distributionListConsentKey: 'distributionListConsent',
              onColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
              linkColour: globalStyling.colours.primaryBrandColour,
              marketingLink: marketingLink,
              showUserCommunication: showUserCommunication,
              globalStyling: globalStyling,
              dataConsentFont: dataConsentFont,
              checkBoxError: hasError('checkBoxError'),
              labelFontSize: checkboxLabelStyles && checkboxLabelStyles.fontSize
            }),
            !showJLRMarketingAndDistributionConsent && _react2.default.createElement(_PersonalDataConsent2.default, {
              onColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
              translations: translations,
              onCheckBoxClick: _this.onInputChange,
              phoneChecked: phoneConsent,
              phoneKey: 'phoneConsent',
              emailChecked: emailConsent,
              emailKey: 'emailConsent',
              smsChecked: smsConsent,
              smsKey: 'smsConsent',
              labelFontSize: '13',
              checkboxDimension: '1.25',
              linkColour: globalStyling.colours.primaryBrandColour,
              onRadioSelect: _this.onRadioSelect,
              customisedServices: customisedServicesConsent,
              customisedServicesKey: 'customisedServicesConsent',
              customisedServicesError: hasError('customisedServicesConsent'),
              marketing: marketingConsent,
              marketingKey: 'marketingConsent',
              marketingError: hasError('marketingConsent'),
              marketingLink: marketingLink,
              showUserCommunication: showUserCommunication,
              globalStyling: globalStyling
            }),
            translations.registerTermsCondsAccept && _react2.default.createElement(
              _react.Fragment,
              null,
              _react2.default.createElement(HorizontalLine, null),
              _react2.default.createElement(
                'div',
                { style: { display: 'flex', alignItems: 'center' } },
                _react2.default.createElement(_CheckBox2.default, {
                  checked: termsAndConditionsAccept,
                  onClick: function onClick() {
                    return _this.onInputChange('termsAndConditionsAccept', !termsAndConditionsAccept);
                  },
                  onColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
                  checkboxDimension: '1.25'
                }),
                _react2.default.createElement(
                  Link,
                  {
                    href: termsLink,
                    target: '_blank',
                    colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
                  },
                  translations.registerTermsCondsAccept
                )
              )
            ),
            errors.termsAndConditionsAccept && _react2.default.createElement(
              RegisterFailure,
              null,
              translations.formValidationTermsConditionsAccept
            ),
            _react2.default.createElement(
              'div',
              { style: { marginTop: '20px' } },
              _react2.default.createElement(_Global.Button, {
                styleOverride: function styleOverride() {
                  return '\n                width: 100%;\n              ';
                },
                applyStyle: 'primary',
                buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
                onClick: _this.onRegisterClick,
                text: translations.registerButton
              }),
              registerFailed && _react2.default.createElement(
                RegisterFailure,
                null,
                translations.registerFailure
              )
            )
          )
        ) : _react2.default.createElement(_EmailVerification2.default, {
          globalStyling: globalStyling,
          translations: translations,
          email: email,
          goToPage: goToPage
        });
      }
    });


    _this.state = {
      title: {
        label: 'Please select',
        value: ''
      },
      firstName: '',
      lastName: '',
      email: '',
      emailConfirm: '',
      phoneNumber: '',
      postCode: '',
      interestedIn: '',
      currentVehicle: '',
      password: '',
      repeatPassword: '',
      phoneConsent: false,
      emailConsent: false,
      smsConsent: false,
      termsAndConditionsAccept: false,
      errors: {},
      marketingConsent: false,
      distributionListConsent: false,
      customisedServicesConsent: false
    };
    return _this;
  }

  return Register;
}(_react.Component);

exports.default = Register;

Register.defaultProps = {
  showUserCommunication: false
};