'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n background-image: url(\'https://res.cloudinary.com/motortrak/f_auto/v1709830237/locator/rolls-royce/content/components/provenance/provenance-bg.jpg\');\n  background-position: center;\n  background-size: cover;\n  '], ['\n background-image: url(\'https://res.cloudinary.com/motortrak/f_auto/v1709830237/locator/rolls-royce/content/components/provenance/provenance-bg.jpg\');\n  background-position: center;\n  background-size: cover;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 32px;\n    padding-top: 300px;\n  '], ['\n    font-size: 32px;\n    padding-top: 300px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n      grid-template-columns: repeat(2, 1fr);\n  '], ['\n      grid-template-columns: repeat(2, 1fr);\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n      padding-bottom: 300px;\n      grid-template-columns: repeat(3, 1fr);\n  '], ['\n      padding-bottom: 300px;\n      grid-template-columns: repeat(3, 1fr);\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ProvenancePreowned__Wrapper',
  componentId: 'sc-1brolau-0'
})(['align-items:center;background-image:url(\'https://res.cloudinary.com/motortrak/f_auto/v1709899382/locator/rolls-royce/content/components/provenance/provenance-bg-mobile.jpg\');background-repeat:no-repeat;background-size:100%;background-color:#000;color:#fff;display:flex;flex-direction:column;text-wrap:balance;width:100%;margin:0 auto;', ';', ';'], function () {
  return _RollsRoyce.mixins.pageGuttering();
}, _RollsRoyce2.default.min.medium(_templateObject));

var ContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ProvenancePreowned__ContentContainer',
  componentId: 'sc-1brolau-1'
})(['max-width:1920px;']);

var SectionTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'ProvenancePreowned__SectionTitle',
  componentId: 'sc-1brolau-2'
})(['font-family:\'RivieraNights-Light\';font-size:24px;font-weight:normal;text-align:center;text-transform:uppercase;letter-spacing:2px;padding-top:100px;margin-bottom:70px;', ';'], _RollsRoyce2.default.min.large(_templateObject2));

var GridContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ProvenancePreowned__GridContainer',
  componentId: 'sc-1brolau-3'
})(['display:grid;grid-template-columns:repeat(1,1fr);grid-gap:60px;padding-bottom:100px;width:100%;', ';', ';'], _RollsRoyce2.default.min.medium(_templateObject3), _RollsRoyce2.default.min.large(_templateObject4));

var GridItem = _styledComponents2.default.div.withConfig({
  displayName: 'ProvenancePreowned__GridItem',
  componentId: 'sc-1brolau-4'
})(['text-align:center;']);

var ItemTitle = _styledComponents2.default.h3.withConfig({
  displayName: 'ProvenancePreowned__ItemTitle',
  componentId: 'sc-1brolau-5'
})(['font-family:\'RivieraNights-Regular\';font-size:18px;font-weight:normal;text-transform:uppercase;letter-spacing:2px;line-height:20px;margin:0 0 10px;']);

var ItemText = _styledComponents2.default.div.withConfig({
  displayName: 'ProvenancePreowned__ItemText',
  componentId: 'sc-1brolau-6'
})(['font-family:\'RivieraNights-Light\';font-size:14px;font-weight:normal;letter-spacing:0.5px;line-height:20px;']);

var ProvenancePreowned = function ProvenancePreowned(_ref) {
  var config = _ref.config;

  var sectionRef = (0, _react.useRef)(null);

  (0, _react.useEffect)(function () {
    var handleScroll = function handleScroll() {
      if (window.location.hash === '#Preowned-Benefits' && sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: 'instant' });
      }
    };

    document.addEventListener('click', handleScroll);
    return function () {
      document.removeEventListener('click', handleScroll);
    };
  }, []);

  var items = config.provenancePreownedItems;

  return _react2.default.createElement(
    Wrapper,
    { ref: sectionRef, id: 'Preowned-Benefits' },
    _react2.default.createElement(
      ContentContainer,
      null,
      _react2.default.createElement(
        SectionTitle,
        null,
        config.translations.sectionTitle
      ),
      _react2.default.createElement(
        GridContainer,
        null,
        items.map(function (_ref2, index) {
          var title = _ref2.title,
              body = _ref2.body;
          return _react2.default.createElement(
            GridItem,
            { key: index },
            _react2.default.createElement(
              ItemTitle,
              null,
              config.translations[title]
            ),
            _react2.default.createElement(
              ItemText,
              null,
              config.translations[body]
            )
          );
        })
      )
    )
  );
};

exports.default = ProvenancePreowned;