'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column-reverse;\n  '], ['\n    flex-direction: column-reverse;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    gap:10px;\n    margin: 20px 0;\n  '], ['\n    gap:10px;\n    margin: 20px 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    font-weight: 400;\n    font-size: 22px;\n    line-height: 26px;\n    color: #000000;\n  '], ['\n    font-weight: 400;\n    font-size: 22px;\n    line-height: 26px;\n    color: #000000;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    font-weight: 300;\n    font-size: 14px;\n    color: #000000;\n  '], ['\n    font-weight: 300;\n    font-size: 14px;\n    color: #000000;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _certifiedProgrammeTypes = require('../../../types/Bentley/certifiedProgrammeTypes');

var _Form = require('../Form');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Item__Container',
  componentId: 'sc-xtfdhr-0'
})(['display:flex;margin:10px 40px;', ' ', ''], function (_ref) {
  var reverse = _ref.reverse;
  return reverse ? 'flex-direction: row-reverse;' : 'flex-direction: row;';
}, _theme2.default.max.large(_templateObject));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'Item__Image',
  componentId: 'sc-xtfdhr-1'
})(['object-fit:cover;width:100%;']);

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Item__ImageContainer',
  componentId: 'sc-xtfdhr-2'
})(['flex:1.5;']);
var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Item__ButtonContainer',
  componentId: 'sc-xtfdhr-3'
})(['', ''], _theme2.default.max.medium(_templateObject2));

var TextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Item__TextContainer',
  componentId: 'sc-xtfdhr-4'
})(['display:flex;flex-direction:column;justify-content:center;flex:1;margin:10px 40px;gap:15px;', ''], _theme2.default.max.large(_templateObject3));

var ItemTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Item__ItemTitle',
  componentId: 'sc-xtfdhr-5'
})(['font-family:Bentley-Light;font-style:normal;font-weight:300;font-size:40px;line-height:44px;color:#222222;', ';'], _theme2.default.max.medium(_templateObject4));

var ItemBodyText = _styledComponents2.default.div.withConfig({
  displayName: 'Item__ItemBodyText',
  componentId: 'sc-xtfdhr-6'
})(['font-family:Bentley-Regular;font-style:normal;font-weight:400;font-size:16px;line-height:24px;color:#222222;white-space:pre-wrap;', ''], _theme2.default.max.medium(_templateObject5));

var Item = function Item(_ref2) {
  var item = _ref2.item,
      reverse = _ref2.reverse,
      onClick = _ref2.onClick;

  var mobile = (0, _useCheckIsMobileScreen.useCheckMobileScreen)();
  var desktopImgUrl = item.desktopImgUrl,
      mobileImgUrl = item.mobileImgUrl,
      _item$translations = item.translations,
      itemBodyText = _item$translations.itemBodyText,
      textCTA = _item$translations.textCTA,
      titleCTA = _item$translations.titleCTA;


  return _react2.default.createElement(
    Container,
    { reverse: reverse },
    _react2.default.createElement(
      ImageContainer,
      null,
      _react2.default.createElement(Image, { src: mobile ? mobileImgUrl : desktopImgUrl, alt: titleCTA })
    ),
    _react2.default.createElement(
      TextContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        titleCTA
      ),
      _react2.default.createElement(
        ItemBodyText,
        null,
        _react2.default.createElement(
          'p',
          null,
          itemBodyText
        )
      ),
      _react2.default.createElement(
        ButtonContainer,
        null,
        _react2.default.createElement(_Form.Button, {
          styleOverride: function styleOverride() {
            return 'width: inherit;';
          },
          variant: 'primary',
          text: textCTA,
          onClick: onClick
        })
      )
    )
  );
};

exports.default = Item;