'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

exports.default = HeadingThree;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledComponentFactory = require('./styledComponentFactory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Heading = (0, _styledComponentFactory.createElement)('h3');

var HeadingDividerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'HeadingThree__HeadingDividerWrapper',
  componentId: 'sc-1h33g8u-0'
})(['margin:10px 0;text-align:center;']);
var HeadingDivider = _styledComponents2.default.span.withConfig({
  displayName: 'HeadingThree__HeadingDivider',
  componentId: 'sc-1h33g8u-1'
})(['display:inline-block;', ';', ';', ';'], function (_ref) {
  var headingDividerStyle = _ref.headingDividerStyle;
  return headingDividerStyle && 'background: ' + headingDividerStyle.colour;
}, function (_ref2) {
  var headingDividerStyle = _ref2.headingDividerStyle;
  return headingDividerStyle && 'height: ' + headingDividerStyle.height + 'px';
}, function (_ref3) {
  var headingDividerStyle = _ref3.headingDividerStyle;
  return headingDividerStyle && 'width: ' + headingDividerStyle.width + 'px';
});

function HeadingThree(_ref4) {
  var styleOverride = _ref4.styleOverride,
      mobileStyleOverride = _ref4.mobileStyleOverride,
      displayHeadingDivider = _ref4.displayHeadingDivider,
      headingDividerStyle = _ref4.headingDividerStyle,
      props = (0, _objectWithoutProperties3.default)(_ref4, ['styleOverride', 'mobileStyleOverride', 'displayHeadingDivider', 'headingDividerStyle']);

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(Heading, (0, _extends3.default)({
      styleOverride: styleOverride
    }, (0, _extends3.default)({}, props, (0, _styledComponentFactory.mediaProps)(mobileStyleOverride())))),
    displayHeadingDivider && _react2.default.createElement(
      HeadingDividerWrapper,
      null,
      _react2.default.createElement(HeadingDivider, { headingDividerStyle: headingDividerStyle })
    )
  );
}

HeadingThree.defaultProps = {
  styleOverride: function styleOverride() {
    return '';
  },
  mobileStyleOverride: function mobileStyleOverride() {
    return [];
  },
  children: null
};