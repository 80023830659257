'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 90%;\n    padding: 0 5%;\n  '], ['\n    width: 90%;\n    padding: 0 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n  '], ['\n    width: 50%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  padding: 23px 0;\n'], ['\n  padding: 23px 0;\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    margin: 20px 0;\n  '], ['\n    width: 100%;\n    margin: 20px 0;\n  ']);

exports.default = TestDriveDateAndTime;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _TestDriveVehicleTile = require('./TestDriveVehicleTile');

var _TestDriveVehicleTile2 = _interopRequireDefault(_TestDriveVehicleTile);

var _TestDriveSelectDateAndTime = require('./TestDriveSelectDateAndTime');

var _TestDriveSelectDateAndTime2 = _interopRequireDefault(_TestDriveSelectDateAndTime);

var _Button = require('./Global/Button');

var _Button2 = _interopRequireDefault(_Button);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'TestDriveDateAndTime__Container',
  componentId: 'sc-kj4ft5-0'
})(['height:auto;padding:0 15%;margin:20px auto 80px auto;max-width:930px;', ';'], _theme2.default.max.large(_templateObject));

var Block = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveDateAndTime__Block',
  componentId: 'sc-kj4ft5-1'
})(['margin-bottom:20px;text-align:center;']);

var Heading = _styledComponents2.default.span.withConfig({
  displayName: 'TestDriveDateAndTime__Heading',
  componentId: 'sc-kj4ft5-2'
})(['font-size:20px;']);

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'Row'
}).withConfig({
  displayName: 'TestDriveDateAndTime__Row',
  componentId: 'sc-kj4ft5-3'
})(['width:100%;display:flex;flex-wrap:wrap;align-items:flex-start;justify-content:space-around;']);

var Cell = _styledComponents2.default.div.withConfig({
  displayName: 'Cell'
}).withConfig({
  displayName: 'TestDriveDateAndTime__Cell',
  componentId: 'sc-kj4ft5-4'
})(['margin-top:20px;width:100%;display:flex;align-items:flex-start;', ';'], _theme2.default.min.large(_templateObject2));

var ActionButtonRow = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveDateAndTime__ActionButtonRow',
  componentId: 'sc-kj4ft5-5'
})(['padding-right:2.5%;display:flex;justify-content:flex-end;', ';'], _theme2.default.max.large(_templateObject3));

var ButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'buttonWrapper'
}).withConfig({
  displayName: 'TestDriveDateAndTime__ButtonWrapper',
  componentId: 'sc-kj4ft5-6'
})(['width:60%;margin:20px 0 0 20px;', ';'], _theme2.default.max.large(_templateObject4));

function TestDriveDateAndTime(props) {
  var colours = props.globalStyling.colours;

  var primaryBrandColour = colours.primaryBrandColour && colours.primaryBrandColour.value;
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Block,
      null,
      _react2.default.createElement(
        Heading,
        null,
        props.translations.testDriveDateAndTimeHeader
      )
    ),
    _react2.default.createElement(
      Row,
      null,
      _react2.default.createElement(
        Cell,
        null,
        _react2.default.createElement(_TestDriveVehicleTile2.default, { vehicle: props.testDriveConfig })
      ),
      _react2.default.createElement(
        Cell,
        null,
        props.useSimplifiedFlow && _react2.default.createElement(
          'div',
          { style: { backgroundColor: '#f0f0f0', marginTop: '5%' } },
          _react2.default.createElement(
            'div',
            { style: { padding: '25px' } },
            _react2.default.createElement(
              'div',
              {
                style: {
                  color: '#444444',
                  fontSize: '15px',
                  lineHeight: '21px'
                }
              },
              props.translations.spanishGetStartedText
            ),
            _react2.default.createElement(
              ButtonWrapper,
              null,
              props.userIsLoggedIn ? _react2.default.createElement(_Button2.default, {
                applyStyle: 'primary',
                buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
                text: props.translations.testdriveUserDetailsConfirmRequest,
                onClick: props.sendTestDriveLead
              }) : _react2.default.createElement(_Button2.default, {
                applyStyle: 'primary',
                buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
                text: props.translations.testDriveDateAndTimeNextButton,
                onClick: props.confirmDateAndTime
              })
            )
          )
        ),
        !props.useSimplifiedFlow && _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(_TestDriveSelectDateAndTime2.default, (0, _extends3.default)({
            globalStyling: props.globalStyling,
            disabled: !props.disableCalendar
          }, props)),
          _react2.default.createElement(
            ButtonWrapper,
            null,
            props.userIsLoggedIn ? _react2.default.createElement(_Button2.default, {
              applyStyle: 'primary',
              buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
              text: props.translations.testdriveUserDetailsConfirmRequest,
              onClick: props.sendTestDriveLead
            }) : _react2.default.createElement(_Button2.default, {
              applyStyle: 'primary',
              buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
              text: props.translations.testDriveDateAndTimeNextButton,
              onClick: props.confirmDateAndTime
            })
          )
        )
      )
    ),
    _react2.default.createElement(
      ActionButtonRow,
      null,
      _react2.default.createElement(
        ButtonWrapper,
        null,
        _react2.default.createElement(
          'div',
          {
            style: {
              color: primaryBrandColour,
              textAlign: 'left'
            }
          },
          props.somethingWentWrong && props.translations.testDriveRequestSomethingWentWrong
        )
      )
    )
  );
}

TestDriveDateAndTime.defaultProps = {
  useSimplifiedFlow: false
};