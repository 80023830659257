'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n   font-size: 40px;\n    line-height: 42px;\n    margin: 120px auto;\n  '], ['\n   font-size: 40px;\n    line-height: 42px;\n    margin: 120px auto;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 90px;\n  '], ['\n    padding: 0 90px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 103px;\n  '], ['\n    padding: 0 103px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 140px;\n  '], ['\n    padding: 0 140px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n     grid-template-columns: auto auto auto;\n  '], ['\n     grid-template-columns: auto auto auto;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    max-width: 100%;\n  '], ['\n    width: 100%;\n    max-width: 100%;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 16px;\n    line-height: 24px;\n    letter-spacing: -0.4px;\n  '], ['\n    font-size: 16px;\n    line-height: 24px;\n    letter-spacing: -0.4px;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 24px;\n  '], ['\n    margin-bottom: 24px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _TextLinkButton = require('../../../components/Genesis/TextLinkButton');

var _TextLinkButton2 = _interopRequireDefault(_TextLinkButton);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'WhyPreowned__Container',
  componentId: 'sc-17cnfx3-0'
})(['display:flex;flex-direction:column;background-color:#111;color:#fff;']);

var HeaderWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'WhyPreowned__HeaderWrapper',
  componentId: 'sc-17cnfx3-1'
})(['margin:0 auto;width:100%;max-width:1920px;', ';'], function () {
  return _Genesis.mixins.pageGuttering();
});

var Header = _styledComponents2.default.h2.withConfig({
  displayName: 'WhyPreowned__Header',
  componentId: 'sc-17cnfx3-2'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Light\';font-size:30px;font-weight:normal;line-height:32px;margin:80px 0;padding:0;', ';', ';', ';', ';'], _Genesis2.default.min.medium(_templateObject), _Genesis2.default.min.large(_templateObject2), _Genesis2.default.min.extraLarge(_templateObject3), _Genesis2.default.min.xxl(_templateObject4));

var ItemRow = _styledComponents2.default.div.withConfig({
  displayName: 'WhyPreowned__ItemRow',
  componentId: 'sc-17cnfx3-3'
})(['display:grid;grid-template-columns:auto;gap:40px;justify-content:space-between;max-width:1920px;align-items:center;margin:0 auto 120px;', ';', ';'], function () {
  return _Genesis.mixins.pageGuttering();
}, _Genesis2.default.min.small(_templateObject5));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'WhyPreowned__Item',
  componentId: 'sc-17cnfx3-4'
})(['display:flex;flex-direction:column;align-items:flex-start;gap:0px;box-sizing:border-box;', ';'], _Genesis2.default.max.medium(_templateObject6));

var ItemImage = _styledComponents2.default.img.withConfig({
  displayName: 'WhyPreowned__ItemImage',
  componentId: 'sc-17cnfx3-5'
})(['width:100%;height:auto;']);

var ItemTitle = _styledComponents2.default.h3.withConfig({
  displayName: 'WhyPreowned__ItemTitle',
  componentId: 'sc-17cnfx3-6'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Light\';font-size:18px;letter-spacing:-0.45px;margin:24px 0px;', ';'], _Genesis2.default.max.medium(_templateObject7));

var ItemDescription = _styledComponents2.default.p.withConfig({
  displayName: 'WhyPreowned__ItemDescription',
  componentId: 'sc-17cnfx3-7'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;line-height:24px;letter-spacing:-0.45px;margin:0px;margin-bottom:32px;', ';'], _Genesis2.default.max.medium(_templateObject8));

var WhyPreowned = function WhyPreowned(_ref) {
  var config = _ref.config,
      navigate = _ref.navigate;
  var t = config.translations;


  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      HeaderWrapper,
      null,
      _react2.default.createElement(
        Header,
        null,
        t.title
      )
    ),
    _react2.default.createElement(
      ItemRow,
      null,
      _react2.default.createElement(
        Item,
        null,
        _react2.default.createElement(ItemImage, { src: config.itemOneImage }),
        _react2.default.createElement(
          ItemTitle,
          null,
          t.itemOneTitle
        ),
        _react2.default.createElement(
          ItemDescription,
          null,
          t.itemOneDescription
        ),
        _react2.default.createElement(
          _TextLinkButton2.default,
          {
            onClick: function onClick() {
              return navigate(t.itemOneButtonUrl);
            },
            variant: 'secondary',
            underlined: true,
            hideChevron: true
          },
          t.itemOneButtonLabel
        )
      ),
      _react2.default.createElement(
        Item,
        null,
        _react2.default.createElement(ItemImage, { src: config.itemTwoImage }),
        _react2.default.createElement(
          ItemTitle,
          null,
          t.itemTwoTitle
        ),
        _react2.default.createElement(
          ItemDescription,
          null,
          t.itemTwoDescription
        ),
        _react2.default.createElement(
          _TextLinkButton2.default,
          {
            onClick: function onClick() {
              return navigate(t.itemTwoButtonUrl);
            },
            variant: 'secondary',
            underlined: true,
            hideChevron: true
          },
          t.itemTwoButtonLabel
        )
      ),
      _react2.default.createElement(
        Item,
        null,
        _react2.default.createElement(ItemImage, { src: config.itemThreeImage }),
        _react2.default.createElement(
          ItemTitle,
          null,
          t.itemThreeTitle
        ),
        _react2.default.createElement(
          ItemDescription,
          null,
          t.itemThreeDescription
        ),
        _react2.default.createElement(
          _TextLinkButton2.default,
          {
            onClick: function onClick() {
              return navigate(t.itemThreeButtonUrl);
            },
            variant: 'secondary',
            underlined: true,
            hideChevron: true
          },
          t.itemThreeButtonLabel
        )
      )
    )
  );
};

exports.default = WhyPreowned;