'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     ', '\n  '], ['\n     ', '\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _useCheckIsMobileScreen = require('../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__ImageContainer',
  componentId: 'sc-ad99li-0'
})(['background-color:#4d4d4d;position:relative;height:100%;width:100%;margin:0 auto;max-width:1920px;height:fit-content(768px);display:grid;grid-template-columns:auto;grid-template-rows:auto;grid-column-gap:1px;grid-row-gap:1px;cursor:pointer;grid-template-areas:\'large large large\' \'small1 small2 small3\';', ';'], _Genesis2.default.min.large(_templateObject, function (_ref) {
  var isVDP = _ref.isVDP;
  return isVDP && 'grid-template-areas: \'large large large small1\' \'large large large small2\' \'large large large small3\';';
}));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'ImgCollage__Image',
  componentId: 'sc-ad99li-1'
})(['aspect-ratio:3 / 2;display:block;object-fit:cover;width:100%;height:100%;max-width:100%;', ''], function (_ref2) {
  var gridArea = _ref2.gridArea;
  return gridArea && 'grid-area: ' + gridArea + ';';
});

var MainImage = (0, _styledComponents2.default)(Image).withConfig({
  displayName: 'ImgCollage__MainImage',
  componentId: 'sc-ad99li-2'
})(['width:1920px;']);

var UtilityInfo = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__UtilityInfo',
  componentId: 'sc-ad99li-3'
})(['position:absolute;top:8px;display:flex;color:#fff;font-family:GenesisSansTextGlobal-Regular;font-size:10px;text-transform:uppercase;', ';', ';'], _Genesis2.default.min.medium(_templateObject, function (_ref3) {
  var vdp = _ref3.vdp;
  return vdp && 'font-size: 14px;';
}), _Genesis2.default.min.large(_templateObject2, function (_ref4) {
  var vdp = _ref4.vdp;
  return vdp && 'font-size: 18px; top: 20px;';
}));

var UtilityInfoItem = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__UtilityInfoItem',
  componentId: 'sc-ad99li-4'
})(['align-items:center;background:#111;display:flex;padding:8px;margin-left:8px;', ';transition:all 0.2s ease-in-out;&:hover{transform:scale(1.1);}'], _Genesis2.default.min.large(_templateObject, function (_ref5) {
  var vdp = _ref5.vdp;
  return vdp && 'padding: 10px; margin-left: 20px;';
}));

var UtilityInfoImg = _styledComponents2.default.img.withConfig({
  displayName: 'ImgCollage__UtilityInfoImg',
  componentId: 'sc-ad99li-5'
})(['margin-right:10px;']);

var ImgCollage = function ImgCollage(_ref6) {
  var images = _ref6.images,
      video = _ref6.video,
      onClick = _ref6.onClick,
      placeholder = _ref6.placeholder,
      videoOnClick = _ref6.videoOnClick,
      config = _ref6.config,
      imageAltText = _ref6.imageAltText,
      translations = _ref6.translations,
      isVDP = _ref6.isVDP,
      views = _ref6.views;

  var getImage = function getImage(position) {
    return images[images.length === 1 ? 0 : position] || placeholder;
  };

  var isMobile = (0, _useCheckIsMobileScreen2.default)();

  var stopPropagation = function stopPropagation(func) {
    return function (event) {
      event.stopPropagation();
      func();
    };
  };

  return _react2.default.createElement(
    ImageContainer,
    { onClick: onClick, isVDP: isVDP },
    isVDP ? _react2.default.createElement(
      UtilityInfo,
      { vdp: true },
      _react2.default.createElement(
        UtilityInfoItem,
        { vdp: true },
        views,
        ' ',
        translations.views
      ),
      video && _react2.default.createElement(
        UtilityInfoItem,
        { vdp: true, onClick: stopPropagation(videoOnClick) },
        !isMobile && _react2.default.createElement(UtilityInfoImg, { src: config.iconVideo }),
        translations.video
      ),
      _react2.default.createElement(
        UtilityInfoItem,
        { vdp: true },
        !isMobile && _react2.default.createElement(UtilityInfoImg, { src: config.iconGallery }),
        translations.viewGallery
      )
    ) : _react2.default.createElement(
      UtilityInfo,
      null,
      _react2.default.createElement(
        UtilityInfoItem,
        null,
        images.length,
        ' ',
        translations.images
      ),
      video && _react2.default.createElement(
        UtilityInfoItem,
        null,
        translations.video
      )
    ),
    _react2.default.createElement(MainImage, { src: getImage(0), gridArea: 'large', alt: imageAltText }),
    _react2.default.createElement(Image, { src: getImage(1), gridArea: 'small1', alt: imageAltText }),
    _react2.default.createElement(Image, { src: getImage(2), gridArea: 'small2', alt: imageAltText }),
    _react2.default.createElement(Image, { src: getImage(3), gridArea: 'small3', alt: imageAltText })
  );
};

ImgCollage.defaultProps = {
  images: [],
  onClick: function onClick() {}
};

exports.default = ImgCollage;