'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Phone = require('../../components/JaguarIcons/Global/Phone');

var _Phone2 = _interopRequireDefault(_Phone);

var _Global = require('../../components/Global');

var _Shared = require('./Shared');

var _ComparedVehicleType = require('./../../types/ComparedVehicleType');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PhoneLink = _styledComponents2.default.a.withConfig({
  displayName: 'PhoneLink'
}).withConfig({
  displayName: 'RetailerPhoneRow__PhoneLink',
  componentId: 'sc-781b2h-0'
})(['text-decoration:none;']);

var RetailerPhoneRow = function RetailerPhoneRow(_ref) {
  var comparedVehicles = _ref.comparedVehicles,
      globalStyling = _ref.globalStyling,
      phoneIconColour = _ref.phoneIconColour,
      font = _ref.font,
      optionalIconFont = _ref.optionalIconFont,
      optionalIconColourFont = _ref.optionalIconColourFont,
      optionalHeadingThreeFont = _ref.optionalHeadingThreeFont,
      hideBottomBorder = _ref.hideBottomBorder;
  return _react2.default.createElement(
    _Shared.TableRow,
    { fontSize: font && font.fontSize },
    _react2.default.createElement('td', { style: { border: 'none' } }),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        _Shared.TableData,
        { key: '' + vehicle.id, hideBottomBorder: hideBottomBorder },
        _react2.default.createElement(
          'div',
          {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px'
            }
          },
          _react2.default.createElement(_Phone2.default, {
            width: optionalIconColourFont ? optionalIconColourFont.fontSize + 'em' : '2em',
            height: optionalIconColourFont ? optionalIconColourFont.fontSize + 'em' : '2em',
            colour: phoneIconColour
          }),
          _react2.default.createElement(
            PhoneLink,
            { href: 'tel:' + vehicle.retailerPhone },
            _react2.default.createElement(
              _Global.HeadingThree,
              {
                styleOverride: function styleOverride() {
                  return '\n                ' + (0, _Global.fontStyleOverride)(optionalHeadingThreeFont) + '\n                  font-size: ' + (optionalHeadingThreeFont ? optionalHeadingThreeFont.fontSize + 'px;' : '16px') + '\n                  padding: 0 2px;\n                  ' + (optionalIconFont && 'color: ' + (globalStyling && globalStyling.colours.primaryBrandColour.value) + ';') + '\n                  ';
                }
              },
              vehicle.retailerPhone || '-'
            )
          )
        )
      );
    })
  );
};

exports.default = RetailerPhoneRow;