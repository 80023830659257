'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    height: 60px;\n  '], ['\n    height: 60px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 24px;\n  '], ['\n    padding: 0 24px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: right;\n  '], ['\n    justify-content: right;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

exports.default = TemplateAstonMartin;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useScrollDirection = require('../../../../hooks/useScrollDirection');

var _useScrollDirection2 = _interopRequireDefault(_useScrollDirection);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _SlideMenu = require('./SlideMenu');

var _SlideMenu2 = _interopRequireDefault(_SlideMenu);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HeaderWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateAstonMartin__HeaderWrapper',
  componentId: 'sc-1pwdsx1-0'
})(['', ';box-sizing:border-box;width:100%;z-index:100;height:64px;position:fixed;width:100%;backdrop-filter:blur(0);background-color:rgba(0,0,0,0);top:0;left:0;', ';', ';', ';transition-property:top;transition-timing-function:cubic-bezier(0.4,0,0.2,1);transition-duration:500ms;'], _theme2.default.max.large(_templateObject), function (_ref) {
  var direction = _ref.direction;
  return direction === 'top' && 'backdrop-filter: blur(0);background-color: rgba(0, 0, 0, 0);';
}, function (_ref2) {
  var direction = _ref2.direction;
  return direction === 'up' && 'backdrop-filter: blur(46px);background-color: rgba(0, 0, 0, 0.04);';
}, function (_ref3) {
  var direction = _ref3.direction;
  return direction === 'down' && 'top: -64px;';
});

var TopWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateAstonMartin__TopWrapper',
  componentId: 'sc-1pwdsx1-1'
})(['padding:0 48px;width:100%;display:flex;align-items:center;justify-content:center;height:inherit;box-sizing:border-box;', ';'], _theme2.default.max.large(_templateObject2));

var ImageWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateAstonMartin__ImageWrapper',
  componentId: 'sc-1pwdsx1-2'
})(['display:flex;align-items:center;justify-content:center;flex-grow:1;z-index:9;', ';'], _theme2.default.max.large(_templateObject3));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'TemplateAstonMartin__Image',
  componentId: 'sc-1pwdsx1-3'
})(['cursor:pointer;max-width:140px;height:auto;']);

var ActionsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateAstonMartin__ActionsWrapper',
  componentId: 'sc-1pwdsx1-4'
})(['', ';flex:0 0 auto;'], _theme2.default.max.large(_templateObject4));
var MenuButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateAstonMartin__MenuButtonWrapper',
  componentId: 'sc-1pwdsx1-5'
})(['flex:0 0 200px;z-index:9;img{cursor:pointer;display:flex;padding:10px 0;width:20px;}']);

var HeaderButton = _styledComponents2.default.button.withConfig({
  displayName: 'TemplateAstonMartin__HeaderButton',
  componentId: 'sc-1pwdsx1-6'
})(['background-color:', ';border-radius:20px;border:0;color:', ';cursor:pointer;font-family:AstonMartinFlare-XBold;font-size:12px;padding:8px 16px;transition:0.3s;&:hover{background:', ';}'], function (_ref4) {
  var invertedHeader = _ref4.invertedHeader;
  return invertedHeader ? 'rgba(255, 255, 255, 0.08)' : 'rgba(65, 65, 65, 0.08)';
}, function (_ref5) {
  var invertedHeader = _ref5.invertedHeader;
  return invertedHeader ? '#fff' : '#000';
}, function (_ref6) {
  var invertedHeader = _ref6.invertedHeader;
  return invertedHeader ? 'rgba(65, 65, 65, 0.5)' : 'rgba(65,65,65,.2)';
});

function TemplateAstonMartin(props) {
  var config = props.config,
      links = props.links,
      navigate = props.navigate,
      currentLanguage = props.currentLanguage,
      socialShare = props.socialShare,
      utilityBarActions = props.utilityBarActions;

  var languageOptions = config.utilityBarOptions && config.utilityBarOptions.find(function (op) {
    return op.key === 'Language';
  }).options;
  var selectedLanguage = languageOptions.find(function (lo) {
    return lo.value === currentLanguage;
  });

  var direction = (0, _useScrollDirection2.default)();

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      HeaderWrapper,
      {
        direction: direction,
        invertedHeader: config.invertedHeader
      },
      _react2.default.createElement(
        TopWrapper,
        null,
        _react2.default.createElement(
          MenuButtonWrapper,
          { 'data-cy': 'burgerMenu' },
          _react2.default.createElement(
            'div',
            { onClick: function onClick() {
                return setOpen(true);
              }, role: 'button', tabIndex: 0 },
            _react2.default.createElement('img', { src: config.burgerMenuUrl, alt: 'menu button' })
          )
        ),
        _react2.default.createElement(
          ImageWrapper,
          null,
          _react2.default.createElement(Image, {
            src: config.brandLogoUrl,
            'data-cy': 'brandLogo',
            alt: 'header logo',
            onClick: function onClick() {
              return navigate('/');
            }
          })
        ),
        _react2.default.createElement(
          ActionsWrapper,
          null,
          _react2.default.createElement(
            HeaderButton,
            {
              'data-cy': 'header-search-button',
              onClick: function onClick() {
                return navigate('/search');
              },
              invertedHeader: config.invertedHeader
            },
            config.translations.headerCta
          )
        )
      )
    ),
    _react2.default.createElement(_SlideMenu2.default, {
      config: config,
      languages: languageOptions,
      links: links,
      navigate: navigate,
      selectedLanguage: selectedLanguage,
      changeLanguage: utilityBarActions.language,
      socialShare: socialShare,
      open: open,
      onClose: function onClose(e) {
        return setOpen(e);
      }
    })
  );
}