'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Play;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Play(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 24 21' },
      _react2.default.createElement('path', { d: 'M15.21 10.175l-5.104-3.491a.393.393 0 0 0-.616.325v6.982a.39.39 0 0 0 .616.325l5.104-3.491a.393.393 0 0 0 0-.65zm-4.93 3.07V7.752l4.008 2.747-4.008 2.744zM11.5 1.243a9.256 9.256 0 1 0 9.256 9.256A9.267 9.267 0 0 0 11.5 1.244zm0 17.723a8.467 8.467 0 1 1 8.467-8.467 8.477 8.477 0 0 1-8.467 8.467z' })
    )
  );
}

Play.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};