'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _settings = require('../../shared/selectors/settings');

var _sessionPreferences = require('../../shared/sessionPreferences');

var _actions = require('../../actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'LanguagePicklist__Select',
  componentId: 'sc-1mzxg8r-0'
})(['background-color:transparent;border:none;font-family:Bentley-Regular;font-size:10px;line-height:24px;outline:none;text-transform:uppercase;option{color:#5b5b5b;}']);

var LanguagePicklist = function LanguagePicklist() {
  var dispatch = (0, _reactRedux.useDispatch)();
  // Gets languages list from config project.
  var languages = (0, _reactRedux.useSelector)(_settings.getBrandLanguages);
  var languageSelected = (0, _reactRedux.useSelector)(_settings.getSelectedLanguage);

  var _onChange = function _onChange(selectedLanguage) {
    dispatch(_actions.language.actions.changeLanguage(selectedLanguage));
    dispatch(_sessionPreferences.actions.updateSessionPreferences('language', selectedLanguage));
  };

  return _react2.default.createElement(
    Select,
    {
      'data-cy': 'language-selector',
      value: languageSelected,
      onChange: function onChange(event) {
        return _onChange(event.currentTarget.value);
      }
    },
    languages.map(function (language, index) {
      return _react2.default.createElement(
        'option',
        { key: index, value: language.value },
        language.label
      );
    })
  );
};

exports.default = LanguagePicklist;