'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var formatUrl = exports.formatUrl = function formatUrl(url) {
  return url.toLowerCase().replace(/\s/g, '_').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

/** Indicates whether the site is a dealer website or not.
 * @returns True or false
 */
var isDealerPage = exports.isDealerPage = function isDealerPage() {
  return window.location.pathname.includes('dealer_') || window.location.pathname.includes('dealer');
};

var getDealerNameFromUrl = exports.getDealerNameFromUrl = function getDealerNameFromUrl() {
  var dealer = decodeURIComponent(window.location.pathname).split('/').filter(function (part) {
    return part.includes('dealer_');
  })[0];

  return dealer;
};