'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Criteria;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Criteria(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 29 25' },
      _react2.default.createElement('path', { d: 'M14.994 21.942c.293 0 .585-.292.585-.642v-.7c0-.291.585-1.515.585-1.515v-.117c.41-.117.877-.292 1.286-.525l.059.058s1.345.467 1.52.642l.468.466a.566.566 0 0 0 .819 0l1.403-1.4a.563.563 0 0 0 0-.815l-.468-.467c-.175-.175-.643-1.516-.643-1.516l-.058-.058c.292-.525.468-.758.643-1.283l.058-.058c.585-.059 1.287-.525 1.462-.525h.702a.585.585 0 0 0 .585-.583V10.92a.585.585 0 0 0-.585-.583h-.702c-.175 0-.935-.466-1.462-.525 0 0-.058 0-.058-.058-.117-.583-.35-.758-.643-1.283l.058-.058s.468-1.341.643-1.516l.468-.467a.563.563 0 0 0 0-.816l-1.403-1.4a.566.566 0 0 0-.819 0l-.468.467c-.292.233-1.579.7-1.579.7l-.058.058a4.972 4.972 0 0 0-1.287-.525V4.8s-.585-1.283-.585-1.516v-.7A.585.585 0 0 0 14.936 2h-1.989a.585.585 0 0 0-.584.583v.7c0 .291-.585 1.516-.585 1.516v.116a4.972 4.972 0 0 0-1.287.525V5.5s-1.345-.467-1.52-.642c-.234-.175-.527-.466-.527-.466a.566.566 0 0 0-.818 0L6.28 5.79a.563.563 0 0 0 0 .815l.468.467c.233.175.643 1.458.643 1.458l.058.058c-.234.408-.41.816-.526 1.283h-.117s-1.287.583-1.52.583h-.702a.585.585 0 0 0-.585.583v1.982c0 .292.234.583.585.583h.702c.292 0 1.52.584 1.52.584h.117c.117.466.292.874.526 1.282l-.058.059s-.468 1.34-.643 1.516l-.468.466a.563.563 0 0 0 0 .817l1.403 1.399a.566.566 0 0 0 .819 0l.468-.467c.175-.174 1.52-.64 1.52-.64l.059-.06c.41.234.818.409 1.286.526v.116s.585 1.283.585 1.516v.7c0 .291.234.583.585.583l1.988-.058zm-.468-6.648c-2.514.467-4.561-1.865-3.742-4.373.292-.933 1.052-1.69 2.046-2.04 2.574-.817 4.796 1.282 4.328 3.79-.234 1.34-1.228 2.39-2.632 2.623z' })
    )
  );
}

Criteria.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};