'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InputAdornment = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var InputAdornmentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'InputAdornment__InputAdornmentWrapper',
  componentId: 'sc-1o37d8a-0'
})(['display:flex;align-items:center;margin:', ';white-space:nowrap;'], function (_ref) {
  var position = _ref.position;
  return position === 'start' ? '0 8px 0 0' : '0 0 0 8px';
});

var InputAdornment = exports.InputAdornment = function InputAdornment(_ref2) {
  var position = _ref2.position,
      children = _ref2.children;

  return _react2.default.createElement(
    InputAdornmentWrapper,
    { position: position },
    children
  );
};

exports.default = InputAdornment;