'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 408px;\n    ', ';\n  '], ['\n    max-width: 408px;\n    ', ';\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 24px 48px;\n  '], ['\n    margin: 24px 48px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 48px;\n  '], ['\n    margin: 0 48px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    margin: auto 48px 48px;\n  '], ['\n    margin: auto 48px 48px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _LanguagePicklist = require('../../../../components/AstonMartin/LanguagePicklist');

var _LanguagePicklist2 = _interopRequireDefault(_LanguagePicklist);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SlideMenuWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SlideMenu__SlideMenuWrapper',
  componentId: 'sc-1oisbp1-0'
})(['width:100%;height:100vh;z-index:100;position:fixed;right:100%;transition:right 0.6s;', ';', ';transition:0.65s ease-in-out;'], function (_ref) {
  var open = _ref.open;
  return open && 'right: 0';
}, _theme2.default.min.large(_templateObject, function (_ref2) {
  var open = _ref2.open;
  return open && 'right: calc(100vw - 408px);';
}));

var BlurWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SlideMenu__BlurWrapper',
  componentId: 'sc-1oisbp1-1'
})(['width:100%;height:100%;display:inline-block;background-color:rgba(0,0,0,0.7);backdrop-filter:blur(12px);display:flex;flex-direction:column;']);
var Close = _styledComponents2.default.div.withConfig({
  displayName: 'SlideMenu__Close',
  componentId: 'sc-1oisbp1-2'
})(['cursor:pointer;padding-bottom:40px;display:flex;justify-content:flex-start;margin:24px;', ';& img{width:14px;}'], _theme2.default.min.large(_templateObject2));
var Links = _styledComponents2.default.div.withConfig({
  displayName: 'SlideMenu__Links',
  componentId: 'sc-1oisbp1-3'
})(['display:flex;font-family:AstonMartinFlare-Medium;flex-direction:column;margin:0 24px;width:100%;max-width:300px;', ';'], _theme2.default.min.large(_templateObject3));

var LinkItem = _styledComponents2.default.a.withConfig({
  displayName: 'SlideMenu__LinkItem',
  componentId: 'sc-1oisbp1-4'
})(['color:#ffffff;cursor:pointer;font-size:16px;margin-bottom:22px;&:hover{text-decoration:underline;}']);

var PicklistWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SlideMenu__PicklistWrapper',
  componentId: 'sc-1oisbp1-5'
})(['margin:auto 24px 100px 21px;', ';'], _theme2.default.min.large(_templateObject4));

var SlideMenu = function SlideMenu(_ref3) {
  var config = _ref3.config,
      links = _ref3.links,
      navigate = _ref3.navigate,
      languages = _ref3.languages,
      selectedLanguage = _ref3.selectedLanguage,
      changeLanguage = _ref3.changeLanguage,
      open = _ref3.open,
      onClose = _ref3.onClose;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      SlideMenuWrapper,
      {
        open: open,
        backgroundColour: config.backgroundColour && config.backgroundColour.value
      },
      _react2.default.createElement(
        BlurWrapper,
        null,
        _react2.default.createElement(
          Close,
          { onClick: function onClick() {
              return onClose(false);
            } },
          _react2.default.createElement('img', {
            src: config.closeIconUrl,
            'data-cy': 'menuClose',
            alt: 'menu close button'
          })
        ),
        _react2.default.createElement(
          Links,
          null,
          links.map(function (link) {
            return _react2.default.createElement(
              LinkItem,
              {
                'data-cy': link.label,
                key: (0, _v2.default)(),
                onClick: function onClick() {
                  navigate(link.link);
                  onClose(false);
                }
              },
              link.label
            );
          })
        ),
        _react2.default.createElement(
          PicklistWrapper,
          null,
          _react2.default.createElement(_LanguagePicklist2.default, {
            dataCy: 'header-language-picklist',
            value: selectedLanguage.value,
            onChange: function onChange(event) {
              return changeLanguage(languages.find(function (l) {
                return l.value === event.target.value;
              }));
            },
            options: languages,
            enableContrastMode: true
          })
        )
      )
    )
  );
};

exports.default = SlideMenu;