'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _CookiePolicy = require('./CookiePolicy');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_CookiePolicy).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'AstonMartinCookiePolicy';
var id = exports.id = 'AstonMartinCookiePolicy';
var description = exports.description = 'Add Cookies Policy to inform users about the use of cookies by a website or an app.';
var global = exports.global = true;
var required = exports.required = false;
var position = exports.position = 1;