'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ToggleIcon;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('./IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ToggleIcon(_ref) {
  var icon = _ref.icon;

  return _react2.default.createElement(
    _IconWrapper2.default,
    { icon: icon },
    _react2.default.createElement('img', { src: icon, alt: '' })
  );
}

ToggleIcon.defaultProps = {
  icon: null
};