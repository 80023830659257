'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _ = require('.');

var DataProvider = function DataProvider(props) {
  (0, _react.useEffect)(function () {
    var _props$config = props.config,
        actionName = _props$config.actionName,
        dataKey = _props$config.dataKey,
        dispatch = props.dispatch;


    dispatch(_.actions[actionName](dataKey));

    return function () {
      return dispatch(_.actions.clearDataProviderState(dataKey));
    };
  }, []);

  return null;
};

exports.default = DataProvider;