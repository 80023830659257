'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _BulletedList = require('./BulletedList');

var _BulletedList2 = _interopRequireDefault(_BulletedList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function VdpOtherEquipment(_ref) {
  var categories = _ref.equipment,
      columns = _ref.columns,
      styles = _ref.styles;

  return _react2.default.createElement(
    'div',
    { style: { columnCount: columns, columnGap: '40px', width: '100%' } },
    categories.map(function (_ref2) {
      var category = _ref2.category,
          equipment = _ref2.equipment;
      return _react2.default.createElement(_BulletedList2.default, {
        key: category,
        title: categories.length > 1 ? category : '',
        items: equipment,
        styles: styles
      });
    })
  );
}

exports.default = VdpOtherEquipment;