'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    row-gap: 20px;\n  '], ['\n    flex-direction: column;\n    row-gap: 20px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex: 1;\n    row-gap: 20px;\n  '], ['\n    flex: 1;\n    row-gap: 20px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    row-gap: 20px;\n  '], ['\n    row-gap: 20px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _settingsTyped = require('../../shared/selectors/settingsTyped');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _EmissionType = require('../../types/EmissionType');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__Title',
  componentId: 'sc-3xxic0-0'
})(['font-weight:400;font-size:16px;line-height:22px;text-transform:capitalize;']);

var EmissionText = _styledComponents2.default.span.withConfig({
  displayName: 'VehicleEfficiencyClass__EmissionText',
  componentId: 'sc-3xxic0-1'
})(['font-weight:400;font-size:14px;line-height:21px;font-family:', ';'], function (_ref) {
  var font = _ref.font;
  return font;
});

var EnergyEfficiencyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__EnergyEfficiencyContainer',
  componentId: 'sc-3xxic0-2'
})(['display:flex;flex-direction:column;row-gap:30px;}']);

var EnergyEfficiencyTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__EnergyEfficiencyTable',
  componentId: 'sc-3xxic0-3'
})(['display:flex;flex-direction:row;column-gap:30px;', ''], _theme2.default.max.medium(_templateObject));

var EnergyEfficiencyImage = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__EnergyEfficiencyImage',
  componentId: 'sc-3xxic0-4'
})(['flex:0.6;display:flex;align-items:flex-start;flex-direction:column;row-gap:30px;', ''], _theme2.default.max.large(_templateObject2));

var EnergyEfficiencyText = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__EnergyEfficiencyText',
  componentId: 'sc-3xxic0-5'
})(['display:flex;align-items:flex-start;flex-direction:column;row-gap:30px;flex:1;', ''], _theme2.default.max.large(_templateObject3));

var VehicleEfficiencyClass = function VehicleEfficiencyClass(_ref2) {
  var energyEfficiencyRating = _ref2.energyEfficiencyRating,
      energyEfficiencyText = _ref2.energyEfficiencyText,
      energyEfficiencyClass = _ref2.energyEfficiencyClass,
      energyEfficiencyRatingMedia = _ref2.energyEfficiencyRatingMedia,
      disclaimer = _ref2.disclaimer;

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    EnergyEfficiencyContainer,
    null,
    _react2.default.createElement(
      EnergyEfficiencyTable,
      null,
      _react2.default.createElement(
        EnergyEfficiencyImage,
        null,
        _react2.default.createElement(
          Title,
          null,
          energyEfficiencyClass
        ),
        energyEfficiencyRatingMedia[energyEfficiencyRating] && _react2.default.createElement('img', {
          src: energyEfficiencyRatingMedia[energyEfficiencyRating],
          style: {
            objectFit: 'cover',
            width: '100%'
          },
          alt: energyEfficiencyRating
        })
      ),
      energyEfficiencyText && _react2.default.createElement(
        EnergyEfficiencyText,
        null,
        _react2.default.createElement(
          Title,
          null,
          disclaimer
        ),
        _react2.default.createElement(
          EmissionText,
          { font: fonts.primaryFont.value },
          energyEfficiencyText
        )
      )
    )
  );
};

exports.default = VehicleEfficiencyClass;