'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _settings = require('../../../shared/selectors/settings');

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldValidationError = require('./FieldValidationError');

var _FieldValidationError2 = _interopRequireDefault(_FieldValidationError);

var _InputAdornment = require('./InputAdornment');

var _InputAdornment2 = _interopRequireDefault(_InputAdornment);

var _globalStyling = require('../../../types/globalStyling');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'InputField__Label',
  componentId: 'sc-bje2d3-0'
})(['color:#727376;font-size:16px;font-family:', ';pointer-events:none;top:10px;transition:0.2s ease all;'], function (_ref) {
  var fonts = _ref.fonts;
  return fonts.secondaryFont.value;
});

var Input = _styledComponents2.default.input.attrs(function () {
  return {
    required: true
  };
}).withConfig({
  displayName: 'InputField__Input',
  componentId: 'sc-bje2d3-1'
})(['font-family:Bentley-light;font-size:16px;color:#757575;padding:10px;height:18px;display:block;border-radius:0;', '};', ';border:solid 1px #9daca5;&:focus{outline:none;}&:disabled{-webkit-text-fill-color:#757575;-webkit-opacity:1;color:#757575;}'], function (_ref2) {
  var placeholderStyle = _ref2.placeholderStyle;

  return placeholderStyle ? '&::placeholder {' + placeholderStyle + '}' : '';
}, function (_ref3) {
  var orientation = _ref3.orientation;
  return orientation === 'horizontal' ? 'width: 100%' : '';
});

var InputField = function InputField(_ref4) {
  var id = _ref4.id,
      label = _ref4.label,
      required = _ref4.required,
      type = _ref4.type,
      value = _ref4.value,
      onChange = _ref4.onChange,
      onKeyPress = _ref4.onKeyPress,
      error = _ref4.error,
      disabled = _ref4.disabled,
      translations = _ref4.translations,
      validate = _ref4.validate,
      startAdornment = _ref4.startAdornment,
      endAdornment = _ref4.endAdornment,
      inputRef = _ref4.inputRef,
      placeholder = _ref4.placeholder,
      placeholderStyle = _ref4.placeholderStyle,
      _ref4$orientation = _ref4.orientation,
      orientation = _ref4$orientation === undefined ? 'vertical' : _ref4$orientation;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    _FormField2.default,
    { orientation: orientation },
    startAdornment && _react2.default.createElement(
      _InputAdornment2.default,
      null,
      startAdornment
    ),
    _react2.default.createElement(
      Label,
      { fonts: fonts },
      label,
      required
    ),
    _react2.default.createElement(Input, {
      type: type,
      value: value,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (event) {
        onChange({
          id: id,
          value: event.target.value,
          translations: translations,
          validate: validate,
          event: event
        });
      }),
      onKeyPress: onKeyPress,
      disabled: disabled,
      ref: inputRef,
      placeholder: placeholder,
      placeholderStyle: placeholderStyle,
      orientation: orientation
    }),
    endAdornment && _react2.default.createElement(
      _InputAdornment2.default,
      null,
      endAdornment
    ),
    error && _react2.default.createElement(
      _FieldValidationError2.default,
      null,
      error
    )
  );
};

exports.default = InputField;


InputField.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  onChange: function onChange() {},
  onKeyPress: function onKeyPress() {},
  onKeyDown: function onKeyDown() {},
  onBlur: function onBlur() {},
  onFocus: function onFocus() {},
  label: '',
  error: '',
  validate: false
};