'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModelSearchBar = exports.OverlayContent = exports.Overlay = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 34px 0;\n  '], ['\n    padding: 34px 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 450px;\n\n  '], ['\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 450px;\n\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 550px;\n  '], ['\n    width: 550px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 700px;\n  '], ['\n    width: 700px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _UserLocation = require('../UserLocation/UserLocation');

var _location = require('../../../shared/location');

var _location2 = _interopRequireDefault(_location);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _useLocationFilter2 = require('../../../hooks/useLocationFilter');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _types = require('./types');

var _OverlaySearch = require('../OverlaySearch');

var _OverlaySearch2 = _interopRequireDefault(_OverlaySearch);

var _useModelSearch2 = require('../../../modules/AstonMartin/ModelSearch/useModelSearch');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Overlay = exports.Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearchBar__Overlay',
  componentId: 'sc-139u62x-0'
})(['color:#fff;display:flex;justify-content:center;position:fixed;height:100vh;width:100%;top:0;left:0;background-color:rgba(0,0,0,0.7);backdrop-filter:blur(12px);visibility:hidden;opacity:0;', ';overflow:hidden;transition:visibility 0.35s,opacity 0.35s;', ';'], function (_ref) {
  var open = _ref.open;
  return open && 'z-index: 1000';
}, function (_ref2) {
  var open = _ref2.open;
  return open && 'visibility: visible; opacity: 1;';
});

var OverlayContent = exports.OverlayContent = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearchBar__OverlayContent',
  componentId: 'sc-139u62x-1'
})(['width:100%;max-width:680px;padding:24px;display:flex;align-items:center;justify-content:center;']);

var SearcherContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearchBar__SearcherContainer',
  componentId: 'sc-139u62x-2'
})(['border-top:1px solid #d5d5d5;outline:none;background:white;padding:25px 0;', ';border:1px solid rgba(26,29,29,0.2);border-width:1px 0;width:100%;z-index:10;'], _theme2.default.min.small(_templateObject));

var SearchWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearchBar__SearchWrapper',
  componentId: 'sc-139u62x-3'
})(['display:block;padding:0 24px;box-sizing:border-box;margin:0 auto;', ';', ';', ';'], _theme2.default.min.small(_templateObject2), _theme2.default.min.medium(_templateObject3), _theme2.default.min.large(_templateObject4));

var SearcherBar = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearchBar__SearcherBar',
  componentId: 'sc-139u62x-4'
})(['display:flex;flex:1 0 auto;']);

var ModelSearchBar = exports.ModelSearchBar = function ModelSearchBar(_ref3) {
  var _ref3$config = _ref3.config,
      translations = _ref3$config.translations,
      searchSections = _ref3$config.searchSections,
      locationIconUrl = _ref3$config.locationIconUrl,
      locationIconUrlWhite = _ref3$config.locationIconUrlWhite,
      cookieNotAcceptedPadding = _ref3$config.cookieNotAcceptedPadding,
      tilesSelection = _ref3.tilesSelection;

  var _useLocationFilter = (0, _useLocationFilter2.useLocationFilter)(),
      onButtonClick = _useLocationFilter.onButtonClick,
      updateLocationFilter = _useLocationFilter.updateLocationFilter,
      latitude = _useLocationFilter.latitude,
      longitude = _useLocationFilter.longitude;

  var _useModelSearch = (0, _useModelSearch2.useModelSearch)(searchSections),
      prepareSRPFilters = _useModelSearch.prepareSRPFilters;

  var _useLocation = (0, _location2.default)(),
      setLat = _useLocation.setLat,
      setLong = _useLocation.setLong,
      googleAvailable = _useLocation.googleAvailable,
      setLocationTown = _useLocation.setLocationTown,
      setUserCountry = _useLocation.setUserCountry;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var dispatch = (0, _reactRedux.useDispatch)();

  (0, _react.useEffect)(function () {
    if (cookieNotAcceptedPadding) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('stickyBarStatus', cookieNotAcceptedPadding));
    }
    return function () {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('stickyBarStatus', null));
    };
  }, [cookieNotAcceptedPadding]);

  var handleBrowserLocation = (0, _react.useCallback)(function (_ref4) {
    var lat = _ref4.latitude,
        long = _ref4.longitude,
        locationTown = _ref4.locationTown,
        userCountry = _ref4.userCountry;

    setLat(lat);
    setLong(long);
    setLocationTown(locationTown);
    setUserCountry(userCountry);
    updateLocationFilter({
      latitude: lat,
      longitude: long,
      locationTown: locationTown,
      userCountry: userCountry
    });
  }, [setLat, setLong, updateLocationFilter]);

  var handleOnClick = (0, _react.useCallback)(function () {
    prepareSRPFilters(tilesSelection);
    if (latitude && longitude) {
      onButtonClick();
      return;
    }

    setOpen(true);
  }, [latitude, longitude, onButtonClick]);

  return _react2.default.createElement(
    SearcherContainer,
    { id: 'sticky-search' },
    _react2.default.createElement(
      SearchWrapper,
      null,
      _react2.default.createElement(
        SearcherBar,
        null,
        googleAvailable && _react2.default.createElement(_UserLocation.UserLocation, {
          placeholderText: translations.locationPlaceholder,
          iconUrl: locationIconUrl || '',
          browserLocationCallback: function browserLocationCallback(lat, long, town, countryCode) {
            handleBrowserLocation({
              latitude: lat,
              longitude: long,
              locationTown: town,
              userCountry: countryCode
            });
          },
          inputLocationCallback: function inputLocationCallback(lat, long, town, countryCode) {
            handleBrowserLocation({
              latitude: lat,
              longitude: long,
              locationTown: town,
              userCountry: countryCode
            });
          },
          options: {
            autocompletePosition: 'below',
            latitude: latitude,
            longitude: longitude
          },
          translations: translations,
          onButtonClick: handleOnClick
        })
      )
    ),
    _react2.default.createElement(
      Overlay,
      { open: open },
      _react2.default.createElement(
        OverlayContent,
        null,
        googleAvailable && _react2.default.createElement(_OverlaySearch2.default, {
          iconUrl: locationIconUrlWhite || '',
          browserLocationCallback: function browserLocationCallback(lat, long, town, countryCode) {
            handleBrowserLocation({
              latitude: lat,
              longitude: long,
              locationTown: town,
              userCountry: countryCode
            });
          },
          inputLocationCallback: function inputLocationCallback(lat, long, town, countryCode) {
            handleBrowserLocation({
              latitude: lat,
              longitude: long,
              locationTown: town,
              userCountry: countryCode
            });
          },
          translations: translations,
          onButtonClick: handleOnClick
        })
      )
    )
  );
};

exports.default = ModelSearchBar;