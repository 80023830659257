'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Mail;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Mail(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 28 22' },
      _react2.default.createElement('path', { d: 'M13.438 14.842L24.878 7v12.256H2V7.001l11.438 7.84zM2 4h22.877v1.581l-11.439 7.644L2 5.582V4z' })
    )
  );
}

Mail.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};