'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mediaProps = exports.createElement = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['', ';'], ['', ';']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _path = require('ramda/src/path');

var _path2 = _interopRequireDefault(_path);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var screenSizeOptions = {
  max: {
    small: 'maxSmallMobileStyleOverride',
    medium: 'maxMediumMobileStyleOverride',
    large: 'maxLargeMobileStyleOverride'
  },
  min: {
    small: 'minSmallMobileStyleOverride',
    medium: 'minMediumMobileStyleOverride',
    large: 'minLargeMobileStyleOverride'
  }
};

var createElement = exports.createElement = function createElement(element) {
  return _styledComponents2.default[element].attrs(function () {
    return {
      className: 'global'
    };
  }).withConfig({
    displayName: 'styledComponentFactory',
    componentId: 'sc-fgrpai-0'
  })(['&&&{', ';', ';', ';', ';', ';', ';', ';}'], function (_ref) {
    var styleOverride = _ref.styleOverride;
    return styleOverride && styleOverride();
  }, _theme2.default.max.medium(_templateObject, function (_ref2) {
    var maxMediumMobileStyleOverride = _ref2.maxMediumMobileStyleOverride;
    return maxMediumMobileStyleOverride;
  }), _theme2.default.max.small(_templateObject, function (_ref3) {
    var maxSmallMobileStyleOverride = _ref3.maxSmallMobileStyleOverride;
    return maxSmallMobileStyleOverride;
  }), _theme2.default.max.large(_templateObject, function (_ref4) {
    var maxLargeMobileStyleOverride = _ref4.maxLargeMobileStyleOverride;
    return maxLargeMobileStyleOverride;
  }), _theme2.default.min.medium(_templateObject, function (_ref5) {
    var minMediumMobileStyleOverride = _ref5.minMediumMobileStyleOverride;
    return minMediumMobileStyleOverride;
  }), _theme2.default.min.small(_templateObject, function (_ref6) {
    var minSmallMobileStyleOverride = _ref6.minSmallMobileStyleOverride;
    return minSmallMobileStyleOverride;
  }), _theme2.default.min.large(_templateObject, function (_ref7) {
    var minLargeMobileStyleOverride = _ref7.minLargeMobileStyleOverride;
    return minLargeMobileStyleOverride;
  }));
};

var mediaProps = exports.mediaProps = function mediaProps() {
  var mobileStyleOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return mobileStyleOverride.reduce(function (ac, _ref8) {
    var queryPath = _ref8.queryPath,
        template = _ref8.template;
    return (0, _extends4.default)({}, ac, (0, _defineProperty3.default)({}, (0, _path2.default)(queryPath.split('.'), screenSizeOptions), template));
  }, {});
};