'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _router = require('../../actions/router');

var _CompareVehicles = require('./CompareVehicles');

var _CompareVehicles2 = _interopRequireDefault(_CompareVehicles);

var _compare = require('../../shared/compare');

var _shortlist = require('../../shared/shortlist');

var _preview = require('./preview.config');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _vehicle = require('../../helpers/vehicle');

var _vehicleStore = require('../../shared/selectors/vehicleStore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CompareGrid = function (_Component) {
  (0, _inherits3.default)(CompareGrid, _Component);

  function CompareGrid() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, CompareGrid);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = CompareGrid.__proto__ || (0, _getPrototypeOf2.default)(CompareGrid)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            dispatch = _this$props.dispatch,
            optionalExtras = _this$props.state.optionalExtras,
            toggleOptionalExtras = _this$props.actions.toggleOptionalExtras,
            preview = _this$props.preview,
            config = _this$props.config,
            shared = _this$props.shared,
            featureFlags = _this$props.featureFlags,
            globalStyling = _this$props.globalStyling,
            marketInfo = _this$props.marketInfo;


        var shortlistVehicles = (0, _vehicleStore.getShortList)(featureFlags);
        var comparedVehicles = (0, _vehicleStore.getCompareList)(featureFlags);

        var onMoreInfoClick = function onMoreInfoClick(name, vehicle) {
          var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, shared.sessionPreferences.language);
          dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
        };

        var sharedShortListedVehicles = preview ? _preview.config : shortlistVehicles(shared);

        var sharedComparedVehicles = preview ? _preview.config : comparedVehicles(shared);

        var getShortListedIconColour = function getShortListedIconColour(id) {
          var isShortListed = sharedShortListedVehicles.find(function (shortlistedVehicle) {
            return shortlistedVehicle.id === (id || {});
          });
          return isShortListed ? config.shortlistIconListedColour && config.shortlistIconListedColour.value : config.optionalShortlistIconListedColour && config.optionalShortlistIconListedColour.value || '#ACACAC';
        };

        /*
        shared.compare always exists and there is always a vehicles array
        because its in persisted state so we need a default value of [].
        */
        var _shared$compare$equip = shared.compare.equipmentList,
            equipmentList = _shared$compare$equip === undefined ? [] : _shared$compare$equip;

        var equipmentMetaData = equipmentList.sort(function (a, b) {
          return a.localeCompare(b);
        });

        return _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          _react2.default.createElement(_CompareVehicles2.default, {
            gridConfiguration: config.gridConfiguration,
            currentLanguage: marketInfo.locale,
            requestInformation: function requestInformation(vehicle) {
              return dispatch(_router.actions.navigate((config.ctaPath || '/enquiry') + '/' + vehicle.id));
            },
            removeVehicle: function removeVehicle(vehicle) {
              return dispatch(_compare.actions.toggleVehicle(vehicle));
            },
            shortlistVehicle: function shortlistVehicle(vehicle) {
              return dispatch(_shortlist.actions.toggleVehicle(vehicle));
            },
            comparedVehicles: sharedComparedVehicles,
            optionalExtras: optionalExtras,
            equipmentMetaData: equipmentMetaData,
            toggleOptionalExtras: toggleOptionalExtras,
            getShortListedIconColour: getShortListedIconColour,
            globalStyling: globalStyling,
            config: config,
            buttonSize: config.buttonSize,
            optionalCloseIcon: config.optionalCloseIcon,
            onMoreInfoClick: onMoreInfoClick,
            ctaToVdp: config.ctaToVdp
          })
        );
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(CompareGrid, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // We do this to force a first run of the reducer
      // - there used to be a more generic solution,
      //   but it broke the builder
      var _props = this.props,
          init = _props.actions.init,
          dispatch = _props.dispatch,
          shared = _props.shared,
          featureFlags = _props.featureFlags,
          translations = _props.config.translations;

      var currentLanguage = shared.sessionPreferences.language;
      var selectedLanguageToLocale = function selectedLanguageToLocale(language) {
        return language ? language.replace('_', '-') : '';
      };

      init();
      // load language from session storage and convert into locale to send to api
      dispatch(_compare.actions.getEquipmentList());
      dispatch(_compare.actions.checkVehicleStatus(selectedLanguageToLocale(currentLanguage), translations));
      dispatch(_shortlist.actions.getVehicles());
      if (shared.sessionPreferences.currency) {
        dispatch(_compare.actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
          statePath: (0, _vehicleStore.getVehicleStatePath)(featureFlags)
        }));
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _props2 = this.props,
          shared = _props2.shared,
          dispatch = _props2.dispatch,
          featureFlags = _props2.featureFlags;

      if (prevProps.shared.currencyConversion.exchangeRates !== shared.currencyConversion.exchangeRates) {
        dispatch(_compare.actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
          statePath: (0, _vehicleStore.getVehicleStatePath)(featureFlags)
        }));
      }

      if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language) {
        dispatch(_compare.actions.checkVehicleStatus());
        dispatch(_compare.actions.getEquipmentList());
      }
    }
  }]);
  return CompareGrid;
}(_react.Component);

exports.default = CompareGrid;