'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['display:none;'], ['display:none;']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin-right: 9px;\n    padding-right: 9px;\n  '], ['\n    margin-right: 9px;\n    padding-right: 9px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 130px;\n'], ['\n    width: 130px;\n']);

exports.default = VehicleInfoBanner;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Phone = require('./JaguarIcons/Global/Phone');

var _Phone2 = _interopRequireDefault(_Phone);

var _StyledIcon = require('./StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _Global = require('./Global');

var _ContentWrapper = require('./ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _helpers = require('../shared/currencyConversion/helpers');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _Tooltip = require('./Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

var _IconWrapper = require('./IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

var _CarfaxLogo = require('./RollsRoyce/CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Container',
  componentId: 'sc-1xjr1n3-0'
})(['background:#ffffff;position:fixed;top:0;left:0;display:flex;height:', 'px;border-bottom:1px solid ', ';width:100%;z-index:95;', ';'], function (_ref) {
  var styles = _ref.styles;
  return styles && styles.height ? ' ' + styles.height : '130';
}, function (_ref2) {
  var borderColour = _ref2.borderColour;
  return borderColour ? ' ' + borderColour : '#979797';
}, _theme2.default.max.large(_templateObject));

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Column',
  componentId: 'sc-1xjr1n3-1'
})(['display:flex;flex:1;flex-direction:column;height:100%;max-width:700px;', ';', ';'], function (_ref3) {
  var fillSpace = _ref3.fillSpace;
  return fillSpace && 'justify-content: space-between;flex: 0; min-width: 200px;';
}, function (_ref4) {
  var center = _ref4.center;
  return center && 'align-items: center;';
});

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Row',
  componentId: 'sc-1xjr1n3-2'
})(['display:flex;flex:1;align-items:center;', ';'], function (_ref5) {
  var padded = _ref5.padded;
  return padded && 'padding: 12px';
});

var PhoneRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__PhoneRow',
  componentId: 'sc-1xjr1n3-3'
})(['display:flex;flex:1;align-items:center;', ';'], function (_ref6) {
  var padded = _ref6.padded;
  return padded && 'padding: 12px';
});

var InfoRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__InfoRow',
  componentId: 'sc-1xjr1n3-4'
})(['flex-direction:column;flex-wrap:wrap;height:100%;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleInfoBanner__Image',
  componentId: 'sc-1xjr1n3-5'
})(['background-image:url(', ');background-size:contain;background-repeat:no-repeat;background-position:center center;height:100%;', ';'], function (_ref7) {
  var src = _ref7.src;
  return src;
}, _theme2.default.max.medium(_templateObject));

var Title = _styledComponents2.default.span.withConfig({
  displayName: 'VehicleInfoBanner__Title',
  componentId: 'sc-1xjr1n3-6'
})(['font-size:', ';color:', ';color:', ';', ';', ' ', ' ', ''], function (_ref8) {
  var font = _ref8.font;
  return font && font.fontSize ? font.fontSize + 'px' : '22px';
}, function (_ref9) {
  var bold = _ref9.bold;
  return bold ? '#000000' : '#444444';
}, function (_ref10) {
  var font = _ref10.font;
  return font.colour && font.colour.value;
}, function (_ref11) {
  var font = _ref11.font;
  return !font ? 'font-weight: 600' : '';
}, function (_ref12) {
  var font = _ref12.font;
  return font && font.typeface ? 'font-family: ' + font.typeface.value + ';' : '';
}, function (_ref13) {
  var font = _ref13.font;
  return font && font.transform ? 'text-transform:' + font.transform + ';' : '';
}, function (_ref14) {
  var font = _ref14.font;
  return font && font.kerning ? 'letter-spacing:' + font.kerning + 'px;' : '';
});

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'VehicleInfoBanner__Label',
  componentId: 'sc-1xjr1n3-7'
})(['font-size:', ';color:', ';', ' ', ''], function (_ref15) {
  var font = _ref15.font;
  return font && font.fontSize ? font.fontSize + 'px' : '14px';
}, function (_ref16) {
  var font = _ref16.font;
  return font.colour && font.colour.value || '#7e7e7e';
}, function (_ref17) {
  var font = _ref17.font;
  return font && font.typeface ? 'font-family: ' + font.typeface.value + ';' : '';
}, function (_ref18) {
  var font = _ref18.font;
  return font && font.kerning ? 'letter-spacing:' + font.kerning + 'px;' : '';
});

var BespokeFeatureLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__BespokeFeatureLabel',
  componentId: 'sc-1xjr1n3-8'
})(['', ';padding:2px 0;font-size:12px;font-family:\'RivieraNights-Regular\';text-transform:uppercase;'], function (_ref19) {
  var font = _ref19.font;
  return font && (0, _Global.fontStyleOverride)(font);
});

var CollectionsLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__CollectionsLabel',
  componentId: 'sc-1xjr1n3-9'
})(['line-height:16px;text-transform:uppercase;letter-spacing:0.57px;color:#000000;font-size:12px;font-family:RivieraNights-Regular;']);

var LabelledValue = function LabelledValue(_ref20) {
  var value = _ref20.value,
      label = _ref20.label,
      bold = _ref20.bold,
      separator = _ref20.separator,
      width = _ref20.width,
      qaHook = _ref20.qaHook,
      titleFont = _ref20.titleFont,
      labelFont = _ref20.labelFont,
      direction = _ref20.direction,
      modelCollectionName = _ref20.modelCollectionName;
  return _react2.default.createElement(
    VertSeparator,
    { hideBorder: separator, width: width, direction: direction },
    _react2.default.createElement(
      Column,
      { 'data-cy': qaHook },
      _react2.default.createElement(
        Title,
        { font: titleFont, bold: bold },
        value
      ),
      modelCollectionName && _react2.default.createElement(
        CollectionsLabel,
        null,
        modelCollectionName
      ),
      _react2.default.createElement(
        Label,
        { font: labelFont },
        label
      )
    )
  );
};

var PhoneNumber = function PhoneNumber(_ref21) {
  var number = _ref21.number,
      colour = _ref21.colour,
      size = _ref21.size;
  return _react2.default.createElement(
    PhoneRow,
    null,
    _react2.default.createElement(_Phone2.default, {
      width: size ? size + 'em' : '2em',
      height: size ? size + 'em' : '2em',
      colour: colour
    }),
    _react2.default.createElement(
      'div',
      { style: { marginLeft: '5px' } },
      '\u200E',
      number
    )
  );
};

function labelIcon(label, font, iconColour, icon) {
  return _react2.default.createElement(
    LabelWithIconWrapper,
    null,
    _react2.default.createElement(_StyledIcon2.default, { link: icon, width: 16, height: 16 }),
    _react2.default.createElement(
      _Global.Div,
      { styleOverride: function styleOverride() {
          return 'margin-left: 5px; ' + (0, _Global.fontStyleOverride)(font);
        } },
      label
    )
  );
}

var DealerLocation = function DealerLocation(_ref22) {
  var dealer = _ref22.dealer,
      iconColour = _ref22.iconColour,
      font = _ref22.font,
      icon = _ref22.icon;
  return labelIcon(dealer, font, iconColour, icon);
};

var VertSeparator = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__VertSeparator',
  componentId: 'sc-1xjr1n3-10'
})(['border-right:', ';', ';', ';', ';', ';'], function (_ref23) {
  var hideBorder = _ref23.hideBorder;
  return hideBorder ? 'none' : '1px solid #DEDEDE';
}, function (_ref24) {
  var direction = _ref24.direction;
  return direction === 'rtl' && 'border-right: none';
}, function (_ref25) {
  var height = _ref25.height;
  return height && 'height: ' + height + ';';
}, function (_ref26) {
  var width = _ref26.width;
  return width && 'width: ' + width + ';';
}, _theme2.default.max.medium(_templateObject2));

var LabelWithIconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__LabelWithIconWrapper',
  componentId: 'sc-1xjr1n3-11'
})(['display:flex;align-items:center;', ';'], function (_ref27) {
  var cursorPointer = _ref27.cursorPointer;
  return cursorPointer && 'cursor: pointer;';
});

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleInfoBanner__Logo',
  componentId: 'sc-1xjr1n3-12'
})(['width:140px;', ';'], _theme2.default.max.medium(_templateObject3));

var PriceWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__PriceWrapper',
  componentId: 'sc-1xjr1n3-13'
})(['text-align:left;', ';', ';', ';', ';', ';'], function (_ref28) {
  var font = _ref28.font;
  return font && font.fontSize && 'font-size: ' + font.fontSize + 'px';
}, function (_ref29) {
  var font = _ref29.font;
  return font && font.colour && 'color: ' + font.colour.value;
}, function (_ref30) {
  var font = _ref30.font;
  return font && font.transform && 'text-transform: ' + font.transform;
}, function (_ref31) {
  var font = _ref31.font;
  return font && font.typeface && 'font-family: ' + font.typeface.value;
}, function (_ref32) {
  var font = _ref32.font;
  return font && font.kerning && 'letter-spacing: ' + font.kerning + 'px';
});

var TaxDisclaimerContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__TaxDisclaimerContainer',
  componentId: 'sc-1xjr1n3-14'
})(['padding-top:6px;padding-left:4px;height:100%;white-space:nowrap;}}']);

function VehicleInfoBanner(_ref33) {
  var translations = _ref33.translations,
      imageSrc = _ref33.imageSrc,
      vehicleDetail = _ref33.vehicleDetail,
      dealerTelephone = _ref33.dealerTelephone,
      dealerName = _ref33.dealerName,
      info = _ref33.info,
      onReserveButtonClick = _ref33.onReserveButtonClick,
      onTestDriveButtonClick = _ref33.onTestDriveButtonClick,
      onContactUsButtonClick = _ref33.onContactUsButtonClick,
      buttonTheme = _ref33.buttonTheme,
      featureFlags = _ref33.featureFlags,
      iconColour = _ref33.iconColour,
      contentWidth = _ref33.contentWidth,
      globalStyling = _ref33.globalStyling,
      showStickyBannerDealerLocation = _ref33.showStickyBannerDealerLocation,
      showStickyBannerContactUsButton = _ref33.showStickyBannerContactUsButton,
      showStickyBannerLogo = _ref33.showStickyBannerLogo,
      mainInfoLogo = _ref33.mainInfoLogo,
      hideVertSeparators = _ref33.hideVertSeparators,
      _ref33$stickyBannerSt = _ref33.stickyBannerStyling,
      stickyBannerStyling = _ref33$stickyBannerSt === undefined ? {} : _ref33$stickyBannerSt,
      borderColour = _ref33.borderColour,
      title = _ref33.title,
      pinIcon = _ref33.pinIcon,
      vehicleInfo = _ref33.vehicleInfo,
      config = _ref33.config;
  var titleFont = stickyBannerStyling.titleFont,
      labelFont = stickyBannerStyling.labelFont,
      anchorFont = stickyBannerStyling.anchorFont,
      iconSize = stickyBannerStyling.iconSize;


  var showSalesTaxDisclaimer = config.showSectionSalesTaxDisclaimer && !!vehicleInfo.sectionSalesTaxDisclaimer;

  var netPrice = vehicleInfo.netPrice ? (0, _helpers.handleVehiclePrice)(vehicleInfo.netPrice, {
    currencyType: vehicleInfo.price.currency,
    locale: vehicleInfo.locale.replace('_', '-'),
    handleZeroPriceAsText: config.handleZeroPriceAsText,
    priceSpecial: vehicleInfo.priceSpecial,
    zeroPriceText: translations.zeroPriceText,
    translationsSold: translations.sold,
    vehiclePrice: vehicleInfo.price.value
  }) : undefined;

  return _react2.default.createElement(
    Container,
    {
      'data-cy': 'vehicle-banner',
      borderColour: borderColour,
      styles: stickyBannerStyling
    },
    _react2.default.createElement(
      _ContentWrapper2.default,
      {
        contentWidth: contentWidth,
        contentHeight: stickyBannerStyling && stickyBannerStyling.height || 130,
        flex: true
      },
      _react2.default.createElement(Image, { src: imageSrc, 'data-cy': 'vehicle-banner-image' }),
      _react2.default.createElement(
        Row,
        { padded: true },
        _react2.default.createElement(
          Column,
          null,
          _react2.default.createElement(LabelledValue, {
            qaHook: 'vehicle-title-description',
            value: title,
            label: vehicleDetail,
            titleFont: titleFont,
            labelFont: labelFont,
            bold: true,
            separator: hideVertSeparators,
            direction: globalStyling.direction,
            modelCollectionName: vehicleInfo.modelCollectionName
          }),
          showStickyBannerDealerLocation && _react2.default.createElement(DealerLocation, {
            colour: iconColour,
            size: iconSize,
            font: anchorFont,
            icon: pinIcon,
            dealer: dealerName
          }),
          _react2.default.createElement(
            _Global.Link,
            {
              href: 'tel:' + dealerTelephone,
              styleOverride: function styleOverride() {
                return 'text-decoration: none;\n                ' + (0, _Global.fontStyleOverride)(anchorFont);
              }
            },
            _react2.default.createElement(PhoneNumber, {
              number: dealerTelephone,
              colour: iconColour,
              size: iconSize,
              direction: globalStyling.direction
            })
          )
        ),
        _react2.default.createElement(VertSeparator, { height: '106px', hideBorder: hideVertSeparators }),
        _react2.default.createElement(
          InfoRow,
          { 'data-cy': 'vehicle-information' },
          info && info.map(function (_ref34, index) {
            var _ref35 = (0, _slicedToArray3.default)(_ref34, 2),
                value = _ref35[0],
                label = _ref35[1];

            return _react2.default.createElement(LabelledValue, {
              key: '' + label + value + (index + 1),
              value: value,
              label: label,
              titleFont: titleFont,
              labelFont: labelFont,
              separator: hideVertSeparators,
              direction: globalStyling.direction
            });
          }),
          vehicleInfo && vehicleInfo.bespoke === 1 && _react2.default.createElement(
            BespokeFeatureLabel,
            {
              font: config && config.bespokeFeatureLabelFont
            },
            translations.bespokeFeatureLabel
          ),
          netPrice && !showSalesTaxDisclaimer && _react2.default.createElement(
            _react2.default.Fragment,
            null,
            _react2.default.createElement(
              PriceWrapper,
              { font: config && config.priceWrapperFont },
              '' + (0, _translateFromTemplate.translateFromTemplate)('netPrice', { VALUE: netPrice }, translations)
            ),
            vehicleInfo.retailerInformation.countryCode === 'DE' && _react2.default.createElement(
              PriceWrapper,
              { font: config && config.priceWrapperFont },
              translations.netPriceLabel || ''
            )
          ),
          vehicleInfo.carfax && _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicleInfo.carfax })
        ),
        netPrice && showSalesTaxDisclaimer && _react2.default.createElement(
          _react2.default.Fragment,
          null,
          _react2.default.createElement(VertSeparator, { height: '106px', hideBorder: hideVertSeparators }),
          _react2.default.createElement(
            TaxDisclaimerContainer,
            null,
            _react2.default.createElement(
              _Tooltip2.default,
              { text: translations.salesTaxDisclaimer },
              _react2.default.createElement(
                _IconWrapper2.default,
                null,
                _react2.default.createElement(_StyledIcon2.default, { height: 20, width: 20, link: config.infoIcon })
              )
            )
          )
        ),
        _react2.default.createElement(VertSeparator, { height: '106px', hideBorder: hideVertSeparators }),
        _react2.default.createElement(
          Column,
          { center: true, fillSpace: true },
          featureFlags.reserve && _react2.default.createElement(
            _Global.Button,
            {
              'data-cy': 'reserve-vehicle-button',
              onClick: onReserveButtonClick,
              theme: buttonTheme,
              primary: true
            },
            translations.vdpReserveThisVehicle
          ),
          featureFlags.testDrive && _react2.default.createElement(
            _Global.Button,
            {
              'data-cy': 'book-test-drive-button',
              onClick: onTestDriveButtonClick,
              theme: buttonTheme,
              primary: true
            },
            translations.vdpBookTestDrive
          ),
          showStickyBannerContactUsButton && _react2.default.createElement(
            _react.Fragment,
            null,
            showStickyBannerLogo && _react2.default.createElement(Logo, { src: mainInfoLogo }),
            _react2.default.createElement(_Global.Button, {
              text: translations.vdpRetailerEnquiryButtonText,
              onClick: onContactUsButtonClick,
              applyStyle: 'primary',
              buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
            })
          )
        )
      )
    )
  );
}