'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    padding: 0 5%;\n    padding-bottom: 20px;\n  '], ['\n    width: 100%;\n    padding: 0 5%;\n    padding-bottom: 20px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 8px 0;\n  '], ['\n    padding: 8px 0;\n  ']);
/* eslint-env browser */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _VdpHeader = require('./VdpHeader');

var _VdpHeader2 = _interopRequireDefault(_VdpHeader);

var _DirectionSearcher = require('./DirectionSearcher');

var _DirectionSearcher2 = _interopRequireDefault(_DirectionSearcher);

var _GoogleMap = require('./GoogleMap');

var _GoogleMap2 = _interopRequireDefault(_GoogleMap);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _RetailerInformation = require('../modules/RetailerInformation/RetailerInformation');

var _Pin = require('./JaguarIcons/Global/Pin');

var _Pin2 = _interopRequireDefault(_Pin);

var _Global = require('./Global');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MapContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MapContainer'
}).withConfig({
  displayName: 'VdpRetailer__MapContainer',
  componentId: 'sc-wddye8-0'
})(['width:70%;', ';'], _theme2.default.max.large(_templateObject));

var GoogleMapStatic = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailer__GoogleMapStatic',
  componentId: 'sc-wddye8-1'
})(['width:100%;height:100%;background-repeat:no-repeat;background-position:50% 50%;line-height:0;', ';'], function (_ref) {
  var url = _ref.url;
  return url && 'background-image: url(' + url + ')';
});

var RetailerContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerContainer'
}).withConfig({
  displayName: 'VdpRetailer__RetailerContainer',
  componentId: 'sc-wddye8-2'
})(['display:flex;height:auto;']);
var RetailerInfo = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo'
}).withConfig({
  displayName: 'VdpRetailer__RetailerInfo',
  componentId: 'sc-wddye8-3'
})(['width:30%;padding:32px 50px;', ';'], _theme2.default.max.large(_templateObject2));

var StyledInfoSection = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailer__StyledInfoSection',
  componentId: 'sc-wddye8-4'
})(['padding:8px 0;border-bottom:', ';', ';'], function (props) {
  return props.lineBreak ? '1px solid #dedede' : null;
}, _theme2.default.max.large(_templateObject3));

var RetailerAddress = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailer__RetailerAddress',
  componentId: 'sc-wddye8-5'
})(['font-size:16px;line-height:22px;color:#444444;', ';'], function (_ref2) {
  var font = _ref2.font;
  return font && (0, _Global.fontStyleOverride)(font);
});
var ContactInfo = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailer__ContactInfo',
  componentId: 'sc-wddye8-6'
})(['font-size:15px;line-height:18px;font-weight:600;color:#444444;margin:10px 0;', ';'], function (_ref3) {
  var font = _ref3.font;
  return font && (0, _Global.fontStyleOverride)(font);
});

var InfoSection = function InfoSection(_ref4) {
  var children = _ref4.children,
      lineBreak = _ref4.lineBreak,
      qaHook = _ref4.qaHook;
  return _react2.default.createElement(
    StyledInfoSection,
    { 'data-cy': qaHook, lineBreak: lineBreak },
    children
  );
};

InfoSection.defaultProps = {
  lineBreak: false,
  qaHook: undefined
};

var FlexDisplay = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailer__FlexDisplay',
  componentId: 'sc-wddye8-7'
})(['display:flex;align-items:center;']);

var DistanceFrom = function DistanceFrom(_ref5) {
  var distanceFromRetailer = _ref5.distanceFromRetailer,
      iconColour = _ref5.iconColour;

  if (distanceFromRetailer) {
    return _react2.default.createElement(
      FlexDisplay,
      null,
      _react2.default.createElement(_Pin2.default, { colour: iconColour, width: '1.8em', height: '1.8em' }),
      _react2.default.createElement(
        'span',
        null,
        distanceFromRetailer
      )
    );
  }
  return null;
};

var VdpRetailer = function (_Component) {
  (0, _inherits3.default)(VdpRetailer, _Component);

  function VdpRetailer(props) {
    (0, _classCallCheck3.default)(this, VdpRetailer);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VdpRetailer.__proto__ || (0, _getPrototypeOf2.default)(VdpRetailer)).call(this, props));

    Object.defineProperty(_this, 'getDistanceFromRetailer', {
      enumerable: true,
      writable: true,
      value: function value(position) {
        var _position$coords = position.coords,
            userLat = _position$coords.latitude,
            userLong = _position$coords.longitude;
        var retailer = _this.props.retailer;

        var _retailer$location$co = (0, _slicedToArray3.default)(retailer.location.coordinates, 2),
            lat = _retailer$location$co[0],
            long = _retailer$location$co[1];

        var distanceService = new window.google.maps.DistanceMatrixService();
        distanceService.getDistanceMatrix({
          origins: [new window.google.maps.LatLng(userLat, userLong)],
          destinations: [new window.google.maps.LatLng(lat, long)],
          travelMode: 'DRIVING'
        }, function (response, status) {
          if (status === 'OK') {
            var distanceText = response.rows[0].elements[0].distance && response.rows[0].elements[0].distance.text;
            if (distanceText) {
              _this.setState({
                distanceFromRetailer: (0, _translateFromTemplate.translateFromTemplate)('vdpRetailerDistanceSuccess', {
                  DISTANCE: distanceText
                }, _this.props.translations)
              });
            } else {
              _this.setState({
                distanceFromRetailer: _this.props.translations.vdpRetailerDistanceFailed
              });
            }
          } else {
            _this.setState({
              distanceFromRetailer: null
            });
          }
        });
      }
    });


    _this.state = {
      distanceFromRetailer: null
    };
    return _this;
  }

  (0, _createClass3.default)(VdpRetailer, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          map = _props.map,
          retailer = _props.retailer,
          translations = _props.translations,
          viewRetailerStock = _props.viewRetailerStock,
          buttonBGColour = _props.buttonBGColour,
          iconColour = _props.iconColour,
          globalStyling = _props.globalStyling,
          retailerLocationCountry = _props.retailerLocationCountry,
          retailerHeaderFont = _props.retailerHeaderFont,
          retailerInfoFont = _props.retailerInfoFont,
          searchCountryCode = _props.searchCountryCode,
          googleAvailable = _props.googleAvailable;
      var distanceFromRetailer = this.state.distanceFromRetailer;

      return _react2.default.createElement(
        RetailerContainer,
        { 'data-cy': 'retailer-module' },
        _react2.default.createElement(
          RetailerInfo,
          null,
          _react2.default.createElement(
            InfoSection,
            { lineBreak: true },
            _react2.default.createElement(
              _VdpHeader2.default,
              {
                styles: { headerFont: retailerHeaderFont },
                qaHook: 'retailer-header'
              },
              retailer.name
            )
          ),
          _react2.default.createElement(
            InfoSection,
            { qaHook: 'retailer-address', lineBreak: true },
            retailer.address.map(function (line) {
              return _react2.default.createElement(
                RetailerAddress,
                { font: retailerInfoFont, key: line },
                line
              );
            }),
            _react2.default.createElement(
              'div',
              null,
              retailer.country
            )
          ),
          _react2.default.createElement(
            InfoSection,
            { qaHook: 'retailer-contact-info', lineBreak: true },
            _react2.default.createElement(
              ContactInfo,
              { font: retailerInfoFont },
              translations.vdpRetailerPhoneLabel,
              ': ',
              '\u200E',
              retailer.phone
            ),
            translations.vdpRetailerEmailLabel && retailer.email && _react2.default.createElement(
              ContactInfo,
              null,
              translations.vdpRetailerEmailLabel,
              ': ',
              retailer.email.split(',').map(function (e, index) {
                return _react2.default.createElement(
                  'div',
                  { key: index },
                  e
                );
              })
            )
          ),
          distanceFromRetailer && _react2.default.createElement(
            InfoSection,
            { qaHook: 'retailer-location' },
            _react2.default.createElement(DistanceFrom, {
              distanceFromRetailer: distanceFromRetailer,
              iconColour: iconColour
            })
          ),
          googleAvailable && _react2.default.createElement(
            InfoSection,
            null,
            _react2.default.createElement(_DirectionSearcher2.default, {
              onGetCurrentPosition: function onGetCurrentPosition(position) {
                return _this2.getDistanceFromRetailer(position);
              },
              placeholder: translations.vdpRetailerGetDirections,
              destination: retailer.location,
              buttonBGColour: buttonBGColour,
              iconColour: iconColour,
              retailerLocationCountry: retailerLocationCountry,
              locationFilterLabel: translations.locationFilterLabel,
              globalStyling: globalStyling,
              searchCountryCode: searchCountryCode
            })
          ),
          _react2.default.createElement(
            InfoSection,
            null,
            _react2.default.createElement(_Global.Button, {
              'data-cy': 'view-retailer-stock-button',
              applyStyle: 'primary',
              buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
              styleOverride: function styleOverride() {
                return '\n                width: 100%;\n              ';
              },
              text: translations.vdpRetailerStockButtonText,
              onClick: function onClick() {
                return viewRetailerStock(retailer);
              }
            })
          )
        ),
        _react2.default.createElement(
          MapContainer,
          null,
          map && map.provider === 'google' ? map.type === 'dynamic' && googleAvailable ? _react2.default.createElement(_GoogleMap2.default, {
            mapName: 'vdpMap',
            retailerLocation: retailer.location
          }) : _react2.default.createElement(GoogleMapStatic, { url: retailer.dealerLocationMapUrl }) : null
        )
      );
    }
  }]);
  return VdpRetailer;
}(_react.Component);

exports.default = VdpRetailer;