'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.default = RegionalFilter;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _intersection = require('ramda/src/intersection');

var _intersection2 = _interopRequireDefault(_intersection);

var _difference = require('ramda/src/difference');

var _difference2 = _interopRequireDefault(_difference);

var _RegionCheckboxGroup = require('./RegionCheckboxGroup');

var _RegionCheckboxGroup2 = _interopRequireDefault(_RegionCheckboxGroup);

var _filtersTypes = require('../../../../types/Genesis/filtersTypes');

var _DropdownlistIcon = require('./DropdownlistIcon');

var _DropdownlistIcon2 = _interopRequireDefault(_DropdownlistIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__Container',
  componentId: 'sc-gy5rwx-0'
})(['border-bottom:none;box-sizing:border-box;display:flex;flex-direction:column;padding:20px 10px 0 10px;position:relative;width:100%;']);

var HeadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__HeadingWrapper',
  componentId: 'sc-gy5rwx-1'
})(['font-size:14px;font-family:Bentley-Light;padding-bottom:15px;text-align:center;']);

var SelectContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__SelectContainer',
  componentId: 'sc-gy5rwx-2'
})(['align-items:center;box-sizing:border-box;cursor:pointer;display:flex;height:40px;justify-content:space-between;padding:22px 15px;width:100%;span{font-size:14px;}']);

var ExpandContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__ExpandContainer',
  componentId: 'sc-gy5rwx-3'
})(['background-color:#000;box-sizing:border-box;max-height:210px;overflow-y:auto;padding-top:10px;position:absolute;top:', 'px;width:calc(100% - 20px);z-index:2;'], function (_ref) {
  var longTitle = _ref.longTitle;
  return longTitle ? 104 : 92;
});

function RegionalFilter(_ref2) {
  var translations = _ref2.translations,
      availableFilterValues = _ref2.availableFilterValues,
      updateFilters = _ref2.updateFilters,
      filterKey = _ref2.filterKey,
      selectedData = _ref2.selectedData,
      arrowDownIcon = _ref2.arrowDownIcon,
      arrowUpIcon = _ref2.arrowUpIcon;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      toggleOpen = _useState2[1];

  var regions = availableFilterValues.regions;


  var formatTitles = (0, _keys2.default)(regions).map(function (key) {
    var enabledSubregionsForRegion = regions[key].some(function (r) {
      return r.enabled;
    }) && regions[key].filter(function (r) {
      return r.enabled;
    }).map(function (sr) {
      return sr.name;
    });
    var selected = selectedData && selectedData.length > 0 ? selectedData : [];
    var enabledRegionKey = regions[key].some(function (r) {
      return r.enabled;
    }) && [key];

    return (0, _difference2.default)(enabledSubregionsForRegion, selected || []).length === 0 ? enabledRegionKey : (0, _intersection2.default)(enabledSubregionsForRegion, selected);
  }).filter(function (el) {
    return el.length > 0;
  }).flat().map(function (text) {
    return translations[text.toLowerCase()];
  }).join(', ');

  var selectedValues = formatTitles.length > 0 ? [formatTitles] : translations.regionFilterPlaceholderText;

  var regionalCheckboxes = (0, _keys2.default)(regions).map(function (key) {
    return _react2.default.createElement(_RegionCheckboxGroup2.default, {
      key: key,
      region: translations[key.replace('_', ' ').toLowerCase()],
      subregions: regions[key],
      updateFilters: updateFilters,
      selectedData: selectedData,
      filterKey: filterKey,
      translations: translations
    });
  });
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      HeadingWrapper,
      null,
      translations.searchIn
    ),
    _react2.default.createElement(
      SelectContainer,
      { onClick: function onClick() {
          return toggleOpen(!open);
        } },
      _react2.default.createElement(
        'span',
        null,
        selectedValues
      ),
      _react2.default.createElement(_DropdownlistIcon2.default, {
        toggle: open,
        openIcon: arrowUpIcon,
        closeIcon: arrowDownIcon
      })
    ),
    open ? _react2.default.createElement(
      ExpandContainer,
      { longTitle: translations.searchIn.length > 35 },
      regionalCheckboxes
    ) : null
  );
}