'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.optionComponents = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _FilterSection = require('./FilterSection');

var _FilterSection2 = _interopRequireDefault(_FilterSection);

var _MultiSelectFilter = require('./MultiSelectFilter');

var _MultiSelectFilter2 = _interopRequireDefault(_MultiSelectFilter);

var _RegionalFilter = require('./RegionalFilter');

var _RegionalFilter2 = _interopRequireDefault(_RegionalFilter);

var _DistanceFilter = require('./DistanceFilter');

var _DistanceFilter2 = _interopRequireDefault(_DistanceFilter);

var _OneHandledRangeFilter = require('./OneHandledRangeFilter');

var _OneHandledRangeFilter2 = _interopRequireDefault(_OneHandledRangeFilter);

var _DualHandledRangeFilter = require('./DualHandledRangeFilter');

var _DualHandledRangeFilter2 = _interopRequireDefault(_DualHandledRangeFilter);

var _InactiveFilter = require('./InactiveFilter');

var _InactiveFilter2 = _interopRequireDefault(_InactiveFilter);

var _helpers = require('../../../../shared/currencyConversion/helpers');

var _filtersTypes = require('../../../../types/Bentley/filtersTypes');

var _numbers = require('../../../../shared/localisation/numbers');

var _Form = require('../../Form');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var optionComponents = exports.optionComponents = {
  condition: function condition(filterKey, props) {
    var conditionFilters = ['onlyApproved'];
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.conditionFilterTitle,
        active: true,
        disabled: false,
        stylingConfig: props.config.stylingConfig
      },
      conditionFilters.map(function (filter) {
        return _react2.default.createElement(
          'div',
          {
            style: {
              display: 'flex',
              flexDirection: 'row',
              margin: '10px 15px'
            },
            key: filter
          },
          _react2.default.createElement(_Form.Checkbox, {
            checked: props.selectedFilters[filter] === 'true',
            onClick: function onClick() {
              return props.updateFilters([{
                key: filter,
                value: props.selectedFilters[filter] === 'true' ? 'false' : 'true'
              }]);
            }
          }),
          _react2.default.createElement(
            'span',
            {
              style: {
                marginLeft: '10px',
                fontSize: '14px',
                cursor: 'pointer'
              },
              onClick: function onClick() {
                return props.updateFilters([{
                  key: filter,
                  value: props.selectedFilters[filter] === 'true' ? 'false' : 'true'
                }]);
              }
            },
            props.config.translations[filter]
          )
        );
      })
    );
  },
  subregions: function subregions(filterKey, props) {
    return _react2.default.createElement(_RegionalFilter2.default, {
      key: filterKey,
      filterKey: filterKey,
      selectedData: props.selectedFilters.subregions,
      updateFilters: props.updateFilters,
      availableFilterValues: props.availableFilterValues,
      translations: props.translations,
      arrowUpIcon: props.config.stylingConfig.filterSectionOpenIcon,
      arrowDownIcon: props.config.stylingConfig.filterSectionCloseIcon
    });
  },
  model: function model(filterKey, props, ref) {
    var translations = props.config.translations;

    var translateModel = function translateModel(models) {
      return models.map(function (model) {
        return translations[model.value] ? (0, _extends3.default)({}, model, { display: translations[model.value] }) : model;
      });
    };

    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.modelFilterTitle,
        active: !!props.selectedFilters.model,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        disabled: false
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: translateModel(props.availableFilterValues.model),
        selectedData: props.selectedFilters.model,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        getDisabledValue: function getDisabledValue() {
          return false;
        },
        noResultsLabel: '0',
        hideVehicleCounts: props.hideVehicleCounts
      })
    );
  },
  modelVariants: function modelVariants(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.variantFilterTitle,
        active: !!props.selectedFilters.modelVariants,
        disabled: !props.selectedFilters.model || !props.availableFilterValues.modelVariants.length,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.modelVariants,
        selectedData: props.selectedFilters.modelVariants,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        hideVehicleCounts: props.hideVehicleCounts
      })
    );
  },
  distance: function distance(filterKey, props, ref) {
    var availableFilterValues = [5, 10, 20, 50, 70, 100, 150, 200];

    return _react2.default.createElement(
      'div',
      { key: filterKey, ref: ref },
      _react2.default.createElement(_DistanceFilter2.default, {
        updateFilters: props.updateFilters,
        translations: props.translations,
        availableFilterValues: availableFilterValues,
        arrowUpIcon: props.config.stylingConfig.filterSectionOpenIcon,
        arrowDownIcon: props.config.stylingConfig.filterSectionCloseIcon,
        innerRef: ref
      })
    );
  },
  bodystyle: function bodystyle(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.bodystyleFilterTitle,
        active: !!props.selectedFilters.bodystyle,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.bodystyle,
        selectedData: props.selectedFilters.bodystyle,
        updateFilters: props.updateFilters,
        stylingConfig: props.config.stylingConfig,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        hideVehicleCounts: props.hideVehicleCounts
      })
    );
  },
  maxOdometer: function maxOdometer(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.odometerFilterTitle,
        active: !!props.selectedFilters.maxOdometer,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_OneHandledRangeFilter2.default, {
        filterKey: filterKey,
        filterLabel: props.config.translations.maxMilesFilterTitle,
        step: 500,
        selectedValue: props.selectedFilters.maxOdometer === undefined || props.selectedFilters.maxOdometer === null ? props.availableFilterValues.maxOdometer : props.selectedFilters.maxOdometer,
        maxValue: props.availableFilterValues.maxOdometer,
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        stylingConfig: props.config.stylingConfig,
        filtersStyling: props.config.stylingConfig,
        formatter: function formatter(value) {
          return (0, _numbers.localiseNumber)(value, props.locale);
        }
      })
    );
  },
  yearRange: function yearRange(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.ageFilterTitle,
        active: !!props.selectedFilters.yearRangeMin || !!props.selectedFilters.yearRangeMax,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_DualHandledRangeFilter2.default, {
        filterKeyMin: 'yearRangeMin',
        filterKeyMax: 'yearRangeMax',
        filterLabel: props.config.translations.ageFilterLabel,
        availableValues: {
          min: props.availableFilterValues.yearRange.min,
          max: props.availableFilterValues.yearRange.max
        },
        selectedValues: {
          min: props.selectedFilters.yearRangeMin || props.availableFilterValues.yearRange.min,
          max: props.selectedFilters.yearRangeMax || props.availableFilterValues.yearRange.max
        },
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        filtersStyling: props.config.stylingConfig
      })
    );
  },
  priceRange: function priceRange(filterKey, props, ref) {
    var currentCurrency = props.shared.sessionPreferences.currency;

    var exchangeRates = props.shared.currencyConversion.exchangeRates && props.shared.currencyConversion.exchangeRates[currentCurrency] && props.shared.currencyConversion.exchangeRates[currentCurrency].rates;

    var rangeSliderSteps = 500;

    var getMinAvailableValue = function getMinAvailableValue() {
      return (0, _helpers.convertPriceCurrency)(props.availableFilterValues.minDefaultPrice.value, exchangeRates, currentCurrency, props.availableFilterValues.minDefaultPrice.currency, rangeSliderSteps, 'min');
    };

    var getMaxAvailableValue = function getMaxAvailableValue() {
      return (0, _helpers.convertPriceCurrency)(props.availableFilterValues.maxDefaultPrice.value, exchangeRates, currentCurrency, props.availableFilterValues.maxDefaultPrice.currency, rangeSliderSteps);
    };

    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.priceFilterTitle,
        active: false,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      exchangeRates ? _react2.default.createElement(_DualHandledRangeFilter2.default, {
        filterKeyMin: 'minDefaultPrice',
        filterKeyMax: 'maxDefaultPrice',
        filterLabel: props.config.translations.priceFilterTitle,
        step: rangeSliderSteps,
        availableValues: {
          min: getMinAvailableValue(),
          max: getMaxAvailableValue()
        },
        selectedValues: {
          min: props.selectedFilters.minDefaultPrice || getMinAvailableValue(),
          max: props.selectedFilters.maxDefaultPrice || getMaxAvailableValue()
        },
        globalStyling: props.globalStyling,
        updateFilters: function updateFilters(rangeFilterValues) {
          var updatedFilterValues = rangeFilterValues.map(function (rv) {
            return (0, _extends3.default)({}, rv, {
              value: rv.value
            });
          });
          props.updateFilters(updatedFilterValues);
        },
        filtersStyling: props.config.stylingConfig,
        hideValues: !currentCurrency,
        formatter: function formatter(value) {
          return (0, _numbers.localiseCurrency)(Number(value), props.locale, currentCurrency, 0);
        },
        disclaimerLink: '#super-1',
        superText: props.config.translations.superText
      }) : _react2.default.createElement(
        _InactiveFilter2.default,
        null,
        props.config.translations.selectCurrencyText
      )
    );
  },
  cylinders: function cylinders(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.cylinderFilterTitle,
        active: !!props.selectedFilters.cylinders,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.cylinders,
        selectedData: props.selectedFilters.cylinders,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        hideVehicleCounts: props.hideVehicleCounts
      })
    );
  },
  veneer: function veneer(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.veneerFilterTitle,
        active: !!props.selectedFilters.veneer,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.veneer,
        selectedData: props.selectedFilters.veneer,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        hideVehicleCounts: props.hideVehicleCounts
      })
    );
  },
  exteriorColours: function exteriorColours(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.exteriorColoursFilterTitle,
        active: !!props.selectedFilters.exteriorColours,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.exteriorColours,
        selectedData: props.selectedFilters.exteriorColours,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        hideVehicleCounts: props.hideVehicleCounts
      })
    );
  }
};