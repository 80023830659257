'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _settings = require('../../../shared/selectors/settings');

var _reactPhoneInput = require('react-phone-input-2');

var _reactPhoneInput2 = _interopRequireDefault(_reactPhoneInput);

require('react-phone-input-2/lib/style.css');

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldValidationError = require('./FieldValidationError');

var _FieldValidationError2 = _interopRequireDefault(_FieldValidationError);

var _globalStyling = require('../../../types/globalStyling');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Documentation here: https://www.npmjs.com/package/react-phone-input-2?activeTab=readme

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'PhoneField__Label',
  componentId: 'sc-rfl7s6-0'
})(['color:#727376;font-size:16px;font-family:', ';pointer-events:none;top:10px;transition:0.2s ease all;'], function (_ref) {
  var fonts = _ref.fonts;
  return fonts.secondaryFont.value;
});

var PhoneField = function PhoneField(_ref2) {
  var id = _ref2.id,
      label = _ref2.label,
      required = _ref2.required,
      value = _ref2.value,
      onChange = _ref2.onChange,
      error = _ref2.error,
      validate = _ref2.validate;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    _FormField2.default,
    null,
    _react2.default.createElement(
      Label,
      { fonts: fonts },
      label,
      required
    ),
    _react2.default.createElement(_reactPhoneInput2.default, {
      inputStyle: {
        borderRadius: '0',
        height: '40px',
        width: '100%',
        fontFamily: fonts.secondaryFont.value,
        fontSize: '14px',
        border: 'solid 1px #9daca5',
        color: '#757575'
      },
      buttonStyle: { border: 'solid 1px #9daca5', borderRadius: '0' },
      value: value,
      placeholder: '',
      copyNumbersOnly: false,
      excludeCountries: ['cn', 'hk', 'tw', 'mo'],
      onChange: function (_onChange) {
        function onChange(_x, _x2, _x3, _x4) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (_, __, ___, formattedValue) {
        onChange({
          id: id,
          value: formattedValue,
          validate: validate
        });
      })
    }),
    error && _react2.default.createElement(
      _FieldValidationError2.default,
      null,
      error
    )
  );
};

exports.default = PhoneField;


PhoneField.defaultProps = {
  placeholder: '',
  required: false,
  onChange: function onChange() {},
  label: '',
  error: '',
  validate: false
};