'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 155px;\n  '], ['\n    width: 155px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DropdownRangeFilterWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DropdownRangeFilter__DropdownRangeFilterWrapper',
  componentId: 'sc-11fjoqx-0'
})(['display:flex;align-items:center;gap:20px;']);
var DropdownColumn = _styledComponents2.default.div.withConfig({
  displayName: 'DropdownRangeFilter__DropdownColumn',
  componentId: 'sc-11fjoqx-1'
})(['display:flex;flex-direction:column;width:108px;', ''], _RollsRoyce2.default.max.medium(_templateObject));

var Dash = _styledComponents2.default.span.withConfig({
  displayName: 'DropdownRangeFilter__Dash',
  componentId: 'sc-11fjoqx-2'
})(['margin-top:30px;border-top:1px solid #151515;height:1px;width:8px;']);

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'DropdownRangeFilter__Select',
  componentId: 'sc-11fjoqx-3'
})(['font-size:14px;box-sizing:border-box;border:1px solid #151515;border-radius:1%;color:#151515;font-family:\'RivieraNights-Regular\';text-transform:capitalize;letter-spacing:0.5px;padding:10px;background-image:url(\'https://res.cloudinary.com/motortrak/v1709224292/locator/rolls-royce/global/icons/chev-down.svg\');background-position-x:95%;background-position-y:45%;background-repeat:no-repeat;background-color:#fff;-webkit-appearance:none;-moz-appearance:none;&:focus{outline:none;}']);

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'DropdownRangeFilter__Label',
  componentId: 'sc-11fjoqx-4'
})(['margin-top:12px;font-size:12px;color:#151515;font-family:\'RivieraNights-Regular\';letter-spacing:0.5px;margin-bottom:4px;']);

var createOptions = function createOptions(min, max) {
  var options = [];
  for (var i = min; i <= max; i++) {
    options.push(i);
  }
  return options;
};

var DropdownRangeFilter = function DropdownRangeFilter(_ref) {
  var selectedValues = _ref.selectedValues,
      updateFilters = _ref.updateFilters,
      availableValues = _ref.availableValues,
      minLabel = _ref.minLabel,
      maxLabel = _ref.maxLabel,
      filterKeyMax = _ref.filterKeyMax,
      filterKeyMin = _ref.filterKeyMin,
      defaultLabel = _ref.defaultLabel;

  var getValue = function getValue(value) {
    return value && value === 'any' ? null : Number(value);
  };

  var handleChange = function handleChange(e) {
    var _e$target = e.target,
        name = _e$target.name,
        value = _e$target.value;


    updateFilters([{ key: name, value: getValue(value) }]);
  };

  var minValues = createOptions(availableValues.min, selectedValues.max || availableValues.max);
  var maxValues = createOptions(selectedValues.min || availableValues.min, availableValues.max);

  return _react2.default.createElement(
    DropdownRangeFilterWrapper,
    null,
    _react2.default.createElement(
      DropdownColumn,
      null,
      _react2.default.createElement(
        Label,
        null,
        minLabel
      ),
      _react2.default.createElement(
        Select,
        {
          value: selectedValues.min || 'any',
          name: filterKeyMin,
          onChange: handleChange
        },
        _react2.default.createElement(
          'option',
          { value: 'any', selected: true },
          defaultLabel
        ),
        minValues.map(function (value) {
          return _react2.default.createElement(
            'option',
            { key: value, value: value },
            value
          );
        })
      )
    ),
    _react2.default.createElement(Dash, null),
    _react2.default.createElement(
      DropdownColumn,
      null,
      _react2.default.createElement(
        Label,
        null,
        maxLabel
      ),
      _react2.default.createElement(
        Select,
        {
          value: selectedValues.max || 'any',
          name: filterKeyMax,
          onChange: handleChange
        },
        _react2.default.createElement(
          'option',
          { value: 'any', selected: true },
          defaultLabel
        ),
        maxValues.map(function (value) {
          return _react2.default.createElement(
            'option',
            { key: value, value: value },
            value
          );
        })
      )
    )
  );
};

exports.default = DropdownRangeFilter;