'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    background-color: #fff;\n    font-size: 16px;\n    flex-direction: row;\n    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.25));\n    margin: 20px 0;\n  '], ['\n    background-color: #fff;\n    font-size: 16px;\n    flex-direction: row;\n    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.25));\n    margin: 20px 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _searchResultsTypes = require('../../../types/Bentley/searchResultsTypes');

var _Form = require('../Form');

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CantFindWhatLookingFor__Wrapper',
  componentId: 'sc-q931kz-0'
})(['display:flex;font-size:14px;flex-direction:column;margin:20px 0 60px;width:100%;', ';'], _theme2.default.min.large(_templateObject));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'CantFindWhatLookingFor__Image',
  componentId: 'sc-q931kz-1'
})(['cursor:pointer;object-fit:cover;width:45%;max-width:510px;']);

var Text = _styledComponents2.default.div.withConfig({
  displayName: 'CantFindWhatLookingFor__Text',
  componentId: 'sc-q931kz-2'
})(['text-align:center;margin:0 0 20px;']);

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CantFindWhatLookingFor__ContentWrapper',
  componentId: 'sc-q931kz-3'
})(['display:flex;align-items:center;justify-content:center;flex-grow:1;']);

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'CantFindWhatLookingFor__Content',
  componentId: 'sc-q931kz-4'
})(['display:flex;flex-direction:column;align-items:center;justify-content:center;font-family:Bentley-Light;padding:20px;max-width:600px;']);

var CantFindWhatLookingFor = function CantFindWhatLookingFor(props) {
  var config = props.config,
      translations = props.translations;

  var _useNavigation = (0, _navigation2.default)(),
      navigateTo = _useNavigation.navigateTo;

  var notMobileTablet = (0, _useCheckIsMobileScreen2.default)('large');

  if (!config.cantFindLookingFor) return null;

  return _react2.default.createElement(
    Wrapper,
    null,
    !notMobileTablet && _react2.default.createElement(Image, {
      alt: translations.cantFindLookingForImageAlt,
      src: config.cantFindLookingFor.image,
      onClick: function onClick(e) {
        e.preventDefault();
        navigateTo(config.cantFindLookingFor.buttonLink);
      }
    }),
    _react2.default.createElement(
      ContentWrapper,
      null,
      _react2.default.createElement(
        Content,
        null,
        _react2.default.createElement(
          Text,
          null,
          translations.cantFindLookingForLine1
        ),
        _react2.default.createElement(
          Text,
          null,
          translations.cantFindLookingForLine2
        ),
        _react2.default.createElement(_Form.Button, {
          onClick: function onClick(e) {
            e.preventDefault();
            navigateTo(config.cantFindLookingFor.buttonLink);
          },
          variant: 'primary',
          styleOverride: function styleOverride() {
            return 'padding: 0 50px';
          },
          text: translations.cantFindLookingForButtonText,
          icon: config.cantFindLookingFor.buttonIcon
        })
      )
    )
  );
};

exports.default = CantFindWhatLookingFor;