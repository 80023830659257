'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _vehicleTypes = require('../../../types/vehicleTypes');

var _EmissionType = require('../../../types/EmissionType');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__Container',
  componentId: 'sc-2tnzbs-0'
})(['display:flex;flex-direction:row;justify-content:space-between;margin-top:auto;']);
var Information = _styledComponents2.default.ul.withConfig({
  displayName: 'Emissions__Information',
  componentId: 'sc-2tnzbs-1'
})(['font-family:AstonMartinSans-Light;font-size:12px;line-height:16px;color:#000000;text-transform:none;list-style-type:none;margin:0;padding:0;']);

var Emissions = function Emissions(props) {
  var emissionsData = props.emissionsData,
      consumptionsData = props.consumptionsData,
      efficiency = props.efficiency,
      translations = props.translations,
      energyEfficiencyRatingMedia = props.energyEfficiencyRatingMedia;
  var consumptionCombined = translations.consumptionCombined,
      co2EmissionsCombined = translations.co2EmissionsCombined,
      energyEfficiencyClass = translations.energyEfficiencyClass;


  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Information,
      null,
      consumptionsData.wltpConsumptionCombined && _react2.default.createElement(
        'li',
        null,
        consumptionCombined + ': ' + (consumptionsData.wltpConsumptionCombined || '-') + ' (WLTP)'
      ),
      emissionsData.wltpEmissionsCombined && _react2.default.createElement(
        'li',
        null,
        co2EmissionsCombined + ': ' + (emissionsData.wltpEmissionsCombined || '-') + ' (WLTP)'
      ),
      efficiency && _react2.default.createElement(
        'li',
        null,
        energyEfficiencyClass,
        ': ',
        efficiency || '-'
      )
    ),
    energyEfficiencyRatingMedia && energyEfficiencyRatingMedia[efficiency] && _react2.default.createElement('img', {
      src: energyEfficiencyRatingMedia[efficiency],
      style: {
        objectFit: 'cover',
        width: '100%',
        maxWidth: 75
      },
      alt: efficiency
    })
  );
};

exports.default = Emissions;