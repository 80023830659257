'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserLocation = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _useLocation2 = require('../../../hooks/useLocation');

var _useLocation3 = _interopRequireDefault(_useLocation2);

var _settings = require('../../../shared/selectors/settings');

var _Spinner = require('../../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _Form = require('../Form');

var _ModelSearchBar = require('../ModelSearchBar');

var _LocationBar = require('../ModelSearchBar/LocationBar');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__Wrapper',
  componentId: 'sc-en51m6-0'
})(['display:flex;width:100%;']);

var ActionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__ActionWrapper',
  componentId: 'sc-en51m6-1'
})(['display:flex;background-color:#335b4c;height:40px;width:40px;justify-content:center;align-items:center;border-radius:1px 0 0 1px;']);

var InputWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__InputWrapper',
  componentId: 'sc-en51m6-2'
})(['position:relative;flex:1 1 0%;']);

var SpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__SpinnerWrapper',
  componentId: 'sc-en51m6-3'
})(['position:absolute;right:160px;']);

var UserLocation = exports.UserLocation = function UserLocation(_ref) {
  var iconUrl = _ref.iconUrl,
      browserLocationCallback = _ref.browserLocationCallback,
      inputLocationCallback = _ref.inputLocationCallback,
      _ref$options = _ref.options,
      options = _ref$options === undefined ? {} : _ref$options,
      translations = _ref.translations,
      onButtonClick = _ref.onButtonClick;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      colours = _useSelector.colours;

  var isMobile = (0, _useCheckIsMobileScreen2.default)('small');

  var _useLocation = (0, _useLocation3.default)(browserLocationCallback, inputLocationCallback, options),
      formattedLocation = _useLocation.formattedLocation,
      getBrowserLocation = _useLocation.getBrowserLocation,
      getInputLocation = _useLocation.getInputLocation,
      onInputChange = _useLocation.onInputChange,
      suggestions = _useLocation.suggestions,
      visible = _useLocation.visible,
      currentlySelected = _useLocation.currentlySelected,
      setCurrentlySelected = _useLocation.setCurrentlySelected,
      inputRef = _useLocation.inputRef,
      onInputKeyDown = _useLocation.onInputKeyDown,
      onInputBlur = _useLocation.onInputBlur,
      onInputFocus = _useLocation.onInputFocus,
      isLoading = _useLocation.isLoading;

  var onClick = (0, _react.useCallback)(function () {
    onButtonClick && onButtonClick();
  }, [onButtonClick]);

  var ref = (0, _react.useRef)(inputRef);

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      InputWrapper,
      null,
      _react2.default.createElement(_Form.InputField, {
        id: 'locationSearch',
        type: 'text',
        'data-cy': 'user-location-input',
        inputRef: ref,
        placeholder: isMobile ? translations.locationPlaceholderMobile : translations.locationPlaceholder,
        translations: translations,
        onChange: function onChange(_ref2) {
          var event = _ref2.event;

          onInputChange(event);
        },
        onKeyDown: onInputKeyDown,
        onBlur: function onBlur(event) {
          return onInputBlur(event, translations.locationPlaceholder);
        },
        onFocus: onInputFocus,
        value: formattedLocation,
        orientation: 'horizontal',
        startAdornment: _react2.default.createElement(
          ActionWrapper,
          { onClick: getBrowserLocation },
          _react2.default.createElement('img', { src: iconUrl, height: 18, width: 18, alt: 'location-icon' })
        ),
        endAdornment: _react2.default.createElement(
          _react2.default.Fragment,
          null,
          isLoading && _react2.default.createElement(
            SpinnerWrapper,
            null,
            _react2.default.createElement(_Spinner2.default, { size: 8, colour: colours.primaryBrandColour })
          ),
          _react2.default.createElement(_Form.PrimaryButton, {
            text: translations.searchCTASearch,
            onClick: onClick,
            styleOverride: function styleOverride() {
              return 'border-radius: 0 1px 1px 0;';
            }
          })
        )
      }),
      suggestions.length > 0 && formattedLocation.length > 0 && visible && _react2.default.createElement(
        _LocationBar.LocationSuggestions,
        {
          position: ref.current && ref.current.getBoundingClientRect().y > 150 ? 'above' : 'below',
          'data-cy': 'user-location-suggestion-list'
        },
        suggestions.map(function (s, index) {
          return _react2.default.createElement(
            _LocationBar.Suggestion,
            {
              role: 'button',
              'data-cy': 'user-location-suggestion-list-option',
              onMouseDown: function onMouseDown() {
                setCurrentlySelected(0);
                getInputLocation(s);
              },
              onMouseEnter: function onMouseEnter() {
                return setCurrentlySelected(index);
              },
              inFocus: currentlySelected === index,
              key: s.description
            },
            s.description
          );
        })
      )
    )
  );
};

exports.default = UserLocation;