'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 50px 5%;\n  '], ['\n    padding: 50px 5%;\n  ']);
/* eslint-disable jsx-a11y/anchor-is-valid */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormFields = require('./FormFields');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Link = require('./Link');

var _Link2 = _interopRequireDefault(_Link);

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SignInContent = _styledComponents2.default.div.withConfig({
  displayName: 'SignIn__SignInContent',
  componentId: 'sc-mchmj7-0'
})(['padding:50px 35%;', ';'], _theme2.default.max.large(_templateObject));

var LoginFailure = _styledComponents2.default.div.withConfig({
  displayName: 'SignIn__LoginFailure',
  componentId: 'sc-mchmj7-1'
})(['font-size:16px;color:#9e1b32;font-weight:600;margin-bottom:10px;']);
var HorizontalLine = _styledComponents2.default.div.withConfig({
  displayName: 'SignIn__HorizontalLine',
  componentId: 'sc-mchmj7-2'
})(['border-bottom:1px solid #dedede;margin:40px 0;']);

var SignIn = function (_Component) {
  (0, _inherits3.default)(SignIn, _Component);

  function SignIn(props) {
    (0, _classCallCheck3.default)(this, SignIn);

    var _this = (0, _possibleConstructorReturn3.default)(this, (SignIn.__proto__ || (0, _getPrototypeOf2.default)(SignIn)).call(this, props));

    Object.defineProperty(_this, 'onInputChange', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value) {
        _this.setState(function () {
          return (0, _defineProperty3.default)({}, formKey, _value);
        });
      }
    });
    Object.defineProperty(_this, 'onFormSubmit', {
      enumerable: true,
      writable: true,
      value: function value(event) {
        var _this$state = _this.state,
            username = _this$state.username,
            password = _this$state.password;

        _this.props.login({ user: username, pass: password })();
        event.preventDefault();
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            translations = _this$props.translations,
            loginFailed = _this$props.loginFailed,
            register = _this$props.register,
            goToForgotPassword = _this$props.goToForgotPassword,
            globalStyling = _this$props.globalStyling;
        var _this$state2 = _this.state,
            username = _this$state2.username,
            password = _this$state2.password;

        return _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n            display: flex;\n            align-items: Center;\n            justify-content: center;\n            border-bottom: 1px solid #d8d8d8;\n            font-size: 20px;\n            text-transform: uppercase;\n            height: 75px;\n          ';
              }
            },
            translations.signInHeader
          ),
          _react2.default.createElement(
            SignInContent,
            null,
            loginFailed === 'UNAUTHORISED' && _react2.default.createElement(
              LoginFailure,
              null,
              translations.signInFailure
            ),
            loginFailed === 'VALIDATION REQUIRED' && _react2.default.createElement(
              LoginFailure,
              null,
              translations.accountValidationRequired
            ),
            _react2.default.createElement(
              'form',
              { onSubmit: _this.onFormSubmit },
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.signInUsernameLabel,
                value: username,
                onChange: _this.onInputChange,
                keyValue: 'username'
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'password',
                label: translations.signInPasswordLabel,
                value: password,
                onChange: _this.onInputChange,
                keyValue: 'password'
              }),
              _react2.default.createElement(
                'div',
                { style: { marginBottom: '40px' } },
                _react2.default.createElement(
                  _Link2.default,
                  {
                    type: 'button',
                    onClick: goToForgotPassword(),
                    theme: {
                      linkColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
                    }
                  },
                  translations.signInForgotPassword
                )
              ),
              _react2.default.createElement(_Global.Button, {
                type: 'submit',
                styleOverride: function styleOverride() {
                  return '\n                width: 100%;\n              ';
                },
                applyStyle: 'primary',
                buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
                text: translations.signInLoginButton
              })
            ),
            _react2.default.createElement(HorizontalLine, null),
            _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return '\n              text-align: center;\n              font-size: 20px;\n              margin-bottom: 25px;\n            ';
                }
              },
              translations.signInNoAccount
            ),
            _react2.default.createElement(_Global.Button, {
              styleOverride: function styleOverride() {
                return '\n              width: 100%;\n            ';
              },
              applyStyle: 'secondary',
              buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle,
              onClick: register(),
              text: translations.signInRegister
            })
          )
        );
      }
    });


    _this.state = {
      username: '',
      password: ''
    };
    return _this;
  }

  return SignIn;
}(_react.Component);

exports.default = SignIn;