'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.default = validate;
exports.validateAll = validateAll;

var _validationFunctions = require('./validationFunctions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var validations = {
  required: _validationFunctions.required,
  email: _validationFunctions.validEmail,
  phone: _validationFunctions.validPhone,
  checkboxes: _validationFunctions.validCheckboxes
};

function validate(field, translations) {
  if (!field.validation) {
    return undefined;
  }

  var result = validations[field.validation](field.value, translations);
  var returnValue = void 0;

  if (result && result.length > 0) {
    var _result = (0, _slicedToArray3.default)(result, 1);

    returnValue = _result[0];
  }

  return returnValue;
}

function validateAll(fields, translations) {
  var errors = (0, _values2.default)(fields).reduce(function (acc, field) {
    return (0, _extends4.default)({}, acc, (0, _defineProperty3.default)({}, field.name, validate(field, translations)));
  }, {});

  return errors;
}