'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'AccordionHeader__Container',
  componentId: 'sc-vnjxs5-0'
})(['display:flex;flex-direction:row;justify-content:space-between;cursor:', ';border-bottom:solid 1px #707070;margin-bottom:50px;text-tranform:{titleCasingDefault && titleCasingDefault ? \'initial\':\'uppercase\'}};padding-bottom:15px;'], function (_ref) {
  var isExpandable = _ref.isExpandable;
  return isExpandable ? 'pointer' : 'default';
});

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'AccordionHeader__Title',
  componentId: 'sc-vnjxs5-1'
})(['font-size:16px;']);

var AccordionHeader = function AccordionHeader(_ref2) {
  var title = _ref2.title,
      onClick = _ref2.onClick,
      isExpanded = _ref2.isExpanded,
      _ref2$isExpandable = _ref2.isExpandable,
      isExpandable = _ref2$isExpandable === undefined ? true : _ref2$isExpandable,
      titleCasingDefault = _ref2.titleCasingDefault,
      iconOpen = _ref2.iconOpen,
      iconClose = _ref2.iconClose;

  return _react2.default.createElement(
    Container,
    {
      isExpandable: isExpandable,
      titleCasingDefault: titleCasingDefault,
      onClick: onClick
    },
    _react2.default.createElement(
      Title,
      null,
      title
    ),
    isExpandable && _react2.default.createElement('img', { src: isExpanded ? iconClose : iconOpen })
  );
};

exports.default = AccordionHeader;