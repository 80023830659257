'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIndexContent = exports.searchable = exports.icon = exports.description = exports.availableToAdministration = exports.id = exports.name = exports.initialState = exports.reducer = exports.constants = exports.actions = exports.reducerId = exports.defaultConfig = exports.config = exports.effects = exports.component = undefined;

var _VDP = require('./VDP');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_VDP).default;
  }
});

var _effects = require('../../VDP/effects');

Object.defineProperty(exports, 'effects', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_effects).default;
  }
});

var _config = require('../../VDP/config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _defaultConfig = require('../../VDP/defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});

var _reducer = require('../../VDP/reducer');

Object.defineProperty(exports, 'reducerId', {
  enumerable: true,
  get: function get() {
    return _reducer.id;
  }
});
Object.defineProperty(exports, 'actions', {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
Object.defineProperty(exports, 'constants', {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
Object.defineProperty(exports, 'reducer', {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, 'initialState', {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});

var _Icon = require('../../VDP/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'VDP';
var id = exports.id = 'AstonMartinVDP';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'Vehicle Details Page';
var icon = exports.icon = _Icon2.default;

var searchable = exports.searchable = true;
var getIndexContent = exports.getIndexContent = function getIndexContent(_ref) {
  var _ref$config = _ref.config;
  _ref$config = _ref$config === undefined ? {} : _ref$config;
  var _ref$config$translati = _ref$config.translations,
      translations = _ref$config$translati === undefined ? {} : _ref$config$translati,
      relativePath = _ref.relativePath,
      instanceId = _ref.instanceId;
  return [{
    title: translations.vdpAccessoriesHeader,
    content: translations.vdpAccessoriesSubHeader
  }, {
    title: translations.vdpMyDealHeader,
    content: translations.vdpGalleryAndMyDealBottomText
  }].map(function (_ref2, index) {
    var title = _ref2.title,
        content = _ref2.content;
    return {
      key: instanceId + '-' + index,
      title: title,
      content: content,
      link: relativePath
    };
  }).filter(function (_ref3) {
    var title = _ref3.title,
        content = _ref3.content;
    return !!title || !!content;
  });
};