'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _styledComponents2.default.div.withConfig({
  displayName: 'IconWrapper',
  componentId: 'sc-1n0te8r-0'
})(['&&&{width:', ';height:', ';padding:', ';@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){width:auto;}background-color:', ';svg > rect:not([fill=\'none\']){fill:', ';}svg > path:not([fill]){fill:', ';}svg{width:', ';height:', ';}', ';}'], function (props) {
  return props.width;
}, function (props) {
  return props.height;
}, function (props) {
  return props.padding;
}, function (props) {
  return props.background;
}, function (props) {
  return props.colour;
}, function (props) {
  return props.colour;
}, function (props) {
  return props.width;
}, function (props) {
  return props.height;
}, function (_ref) {
  var direction = _ref.direction;
  return direction === 'rtl' ? 'transform:  scaleX(-1);' : 'transform:  scaleX(1);';
});