'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _reactRedux = require('react-redux');

var _ComponentContext = require('./ComponentContext');

var _ComponentContext2 = _interopRequireDefault(_ComponentContext);

var _configSelectors = require('./configSelectors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var errorPage = function errorPage(pagesState) {
  return pagesState['/$$ERROR$$'];
};
var notFoundPage = function notFoundPage(pagesState) {
  return pagesState['/404'];
};
var currentPage = function currentPage(pagesState, paths) {
  // Look for the page with the full path by checking the routeExact property, this means that the route must be exact.
  // This allows to have nested routes (/models/{model})
  var current = pagesState[paths.join('')];

  if (current && current.exactRoute) {
    return current;
  }

  var page = paths.find(function (path) {
    return pagesState[path];
  });
  return pagesState[page];
};

var pathFromHash = function pathFromHash() {
  var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  var index = hash.lastIndexOf('#');
  return hash.length > 1 ? hash.substring(index) : '/';
};

var matchesPathname = function matchesPathname(path) {
  return function (selector) {
    return RegExp(selector.regex).test(path);
  };
};

var bandsFromPage = function bandsFromPage(page) {
  return function (state) {
    var _ref = (page.moduleSelector || []).find(matchesPathname(window.location.pathname)) || {
      prop: 'modules'
    },
        prop = _ref.prop;

    return page[prop].map(function (m) {
      return state.config.bands[m];
    });
  };
};

var useBody = function useBody(path, hash) {
  var hashRouting = (0, _reactRedux.useSelector)(function (state) {
    return state.config.settings.hashRouting;
  });

  var reduxState = (0, _reactRedux.useSelector)(function (state) {
    return state;
  });

  var paths = (hashRouting ? pathFromHash(hash) : path).split('/').filter(function (p, index) {
    return index > 0 && !/^[a-z]{2}_[a-z]{2}$/.test(p);
  }).filter(function (p, index) {
    return index === 0 || p;
  }) // prevent trailing / matching homepage on non existent paths
  .map(function (split) {
    return '/' + split;
  });

  var errors = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.errors.error;
  });
  var page = (0, _reactRedux.useSelector)(function (state) {
    return errors ? errorPage(state.config.pages) : currentPage(state.config.pages, paths) || notFoundPage(state.config.pages);
  });
  var bands = (0, _reactRedux.useSelector)(bandsFromPage(page, path));
  var bandsForPath = bands.map(function (band) {
    return (0, _configSelectors.bandWithConfigSelector)(band, hashRouting, reduxState);
  });

  var _useContext = (0, _react.useContext)(_ComponentContext2.default),
      bandComponents = _useContext.bands;

  return {
    page: page,
    bands: bandsForPath,
    bandComponents: bandComponents
  };
};

exports.default = useBody;