'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    min-width: 350px;\n    padding: 0;\n    overflow: hidden;\n  '], ['\n    min-width: 350px;\n    padding: 0;\n    overflow: hidden;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SlideOut = require('../SlideOut');

var _SlideOut2 = _interopRequireDefault(_SlideOut);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _CheckboxGroupList = require('./CheckboxGroupList');

var _CheckboxGroupList2 = _interopRequireDefault(_CheckboxGroupList);

var _searchFiltersHelper = require('./search-filters-helper');

var _RangeSlider = require('../RangeSlider');

var _RangeSlider2 = _interopRequireDefault(_RangeSlider);

var _PriceRangeFilter = require('./PriceRangeFilter');

var _PriceRangeFilter2 = _interopRequireDefault(_PriceRangeFilter);

var _LocationSearchFilter = require('../LocationSearchFilter');

var _LocationSearchFilter2 = _interopRequireDefault(_LocationSearchFilter);

var _RangeHeading = require('./RangeHeading');

var _RangeHeading2 = _interopRequireDefault(_RangeHeading);

var _SectionTitle = require('./SectionTitle');

var _SectionTitle2 = _interopRequireDefault(_SectionTitle);

var _Section = require('./Section');

var _Section2 = _interopRequireDefault(_Section);

var _SubSection = require('./SubSection');

var _SubSection2 = _interopRequireDefault(_SubSection);

var _DropdownFilter = require('./DropdownFilter');

var _DropdownFilter2 = _interopRequireDefault(_DropdownFilter);

var _renderFilterTitle = require('./renderFilterTitle');

var _renderFilterTitle2 = _interopRequireDefault(_renderFilterTitle);

var _renderFilterClose = require('./renderFilterClose');

var _renderFilterClose2 = _interopRequireDefault(_renderFilterClose);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilterContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FilterContainer'
}).withConfig({
  displayName: 'SearchFilters__FilterContainer',
  componentId: 'sc-ra6f08-0'
})(['padding:20px 20px 0 20px;border-bottom:1px solid #d8d8d8;width:100%;', ';'], _theme2.default.min.medium(_templateObject));

var RangeContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RangeContainer'
}).withConfig({
  displayName: 'SearchFilters__RangeContainer',
  componentId: 'sc-ra6f08-1'
})(['padding:20px;border-top:1px solid #d8d8d8;']);

var SearchHeader = _styledComponents2.default.div.withConfig({
  displayName: 'SearchHeader'
}).withConfig({
  displayName: 'SearchFilters__SearchHeader',
  componentId: 'sc-ra6f08-2'
})(['padding:0 0 20px 0;color:#7e7e7e;', ';'], _theme2.default.min.medium(_templateObject2));


var initialFilterState = {
  locationDistance: true,
  priceRange: true,
  modelVariant: true,
  engineTransmission: true,
  bodyStyles: true,
  ageMileage: true,
  features: true,
  colour: true
};

var SearchFilters = function (_Component) {
  (0, _inherits3.default)(SearchFilters, _Component);

  function SearchFilters() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, SearchFilters);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = SearchFilters.__proto__ || (0, _getPrototypeOf2.default)(SearchFilters)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'state', {
      enumerable: true,
      writable: true,
      value: {
        filtersCollapsed: (0, _extends4.default)({}, initialFilterState)
      }
    }), Object.defineProperty(_this, 'onChangeRangeLabel', {
      enumerable: true,
      writable: true,
      value: function value(rangeValues, label) {
        _this.setState(function () {
          return (0, _defineProperty3.default)({}, label, { min: rangeValues[0], max: rangeValues[1] });
        });
      }
    }), Object.defineProperty(_this, 'handleAgeRangeChange', {
      enumerable: true,
      writable: true,
      value: function value(rangeValues) {
        var values = [(0, _searchFiltersHelper.calculateYearRegistered)(rangeValues[1]), (0, _searchFiltersHelper.calculateYearRegistered)(rangeValues[0])];
        _this.props.handleRangeFilters('yearRange', values);
      }
    }), Object.defineProperty(_this, 'toggle', {
      enumerable: true,
      writable: true,
      value: function value(filter) {
        _this.setState(function (prevState) {
          return {
            filtersCollapsed: (0, _extends4.default)({}, initialFilterState, (0, _defineProperty3.default)({}, filter, !prevState.filtersCollapsed[filter]))
          };
        });
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(SearchFilters, [{
    key: 'render',
    value: function render() {
      var _this2 = this,
          _ref4,
          _ref5;

      var _props = this.props,
          allFilters = _props.allFilters,
          availableFilters = _props.availableFilters,
          selectedRangeFilters = _props.selectedRangeFilters,
          maxDistance = _props.maxDistance,
          resetRangeCount = _props.resetRangeCount,
          selectedCheckboxFilters = _props.selectedCheckboxFilters,
          clearCheckboxesBySection = _props.clearCheckboxesBySection,
          toggleCheckboxFilter = _props.toggleCheckboxFilter,
          handleRangeFilters = _props.handleRangeFilters,
          handleLocationChange = _props.handleLocationChange,
          removeRangeFilter = _props.removeRangeFilter,
          _props$config = _props.config,
          _props$config$transla = _props$config.translations,
          translations = _props$config$transla === undefined ? {} : _props$config$transla,
          stylingConfig = _props$config.stylingConfig,
          _props$config$filterV = _props$config.filterVisibility,
          filterVisibility = _props$config$filterV === undefined ? {} : _props$config$filterV,
          distanceFilterInitialSelectedIndex = _props$config.distanceFilterInitialSelectedIndex,
          dropdown = _props$config.dropdown,
          searchCountryCode = _props$config.searchCountryCode,
          locationIconUrl = _props$config.locationIconUrl,
          locale = _props$config.locale,
          featureFlags = _props.featureFlags,
          location = _props.location;


      var checkboxes = (0, _searchFiltersHelper.transformCheckboxProps)(allFilters, availableFilters, selectedCheckboxFilters);

      var _transformRangeProps = (0, _searchFiltersHelper.transformRangeProps)(allFilters),
          mileageRange = _transformRangeProps.mileageRange,
          yearRange = _transformRangeProps.yearRange,
          priceRange = _transformRangeProps.priceRange,
          monthlyPaymentRange = _transformRangeProps.monthlyPaymentRange;

      var rangeAge = availableFilters.yearRange.min && availableFilters.yearRange.max ? {
        defaultRangeAgeHeader: {
          min: (0, _searchFiltersHelper.calculateAge)(availableFilters.yearRange.max),
          max: (0, _searchFiltersHelper.calculateAge)(availableFilters.yearRange.min)
        },
        defaultRangeAgeValues: (0, _searchFiltersHelper.getAgeInitialRangeValues)(1, availableFilters.yearRange)
      } : {
        defaultRangeAgeHeader: { min: 0, max: 0 },
        defaultRangeAgeValues: [0, 0]
      };

      var selectedLocationDistanceOption = function selectedLocationDistanceOption() {
        var options = (0, _searchFiltersHelper.distanceOptions)(translations);
        return options.find(function (o) {
          return o.value === maxDistance;
        }) || options[distanceFilterInitialSelectedIndex || 0];
      };

      return _react2.default.createElement(
        FilterContainer,
        null,
        _react2.default.createElement(
          SearchHeader,
          null,
          translations.searchHeadingFilters
        ),
        _react2.default.createElement(
          _SlideOut2.default,
          {
            qaHook: 'location-distance-filter',
            toggle: function toggle() {
              return _this2.toggle('locationDistance');
            },
            collapsed: this.state.filtersCollapsed.locationDistance,
            renderTitle: (0, _renderFilterTitle2.default)({
              title: translations.searchHeaderLocationDistance
            }),
            renderClose: (0, _renderFilterClose2.default)({
              title: translations.searchHeaderLocationDistance
            }),
            visible: filterVisibility.locationDistance
          },
          _react2.default.createElement(
            _Section2.default,
            null,
            _react2.default.createElement(
              _SubSection2.default,
              null,
              _react2.default.createElement(
                _SectionTitle2.default,
                null,
                translations.searchSubHeaderLocation
              ),
              _react2.default.createElement(_LocationSearchFilter2.default, {
                placeholder: translations.searchLocationInputPlaceholder,
                handleLocationChange: handleLocationChange,
                translations: translations,
                locationIconColour: stylingConfig.locationIconColour && stylingConfig.locationIconColour.value,
                rawLocation: location && location.rawLocation,
                location: location,
                key: resetRangeCount,
                searchCountryCode: searchCountryCode,
                locationIconUrl: locationIconUrl
              })
            ),
            _react2.default.createElement(
              _SubSection2.default,
              null,
              _react2.default.createElement(
                _SectionTitle2.default,
                null,
                translations.searchSubHeaderDistance
              ),
              _react2.default.createElement(_DropdownFilter2.default, {
                initialSelectedValue: selectedLocationDistanceOption(),
                onOptionClick: function onOptionClick(_ref3) {
                  var value = _ref3.value;
                  return _this2.props.handleMaxDistanceChange(value);
                },
                options: (0, _searchFiltersHelper.distanceOptions)(translations),
                key: resetRangeCount,
                selectedValue: maxDistance,
                theme: dropdown,
                border: true
              })
            )
          )
        ),
        _react2.default.createElement(
          _SlideOut2.default,
          {
            qaHook: 'price-range-filter',
            toggle: function toggle() {
              return _this2.toggle('priceRange');
            },
            collapsed: this.state.filtersCollapsed.priceRange,
            renderTitle: (0, _renderFilterTitle2.default)({
              title: translations.searchHeaderPriceRange
            }),
            renderClose: (0, _renderFilterClose2.default)({
              title: translations.searchHeaderPriceRange
            }),
            visible: filterVisibility.priceRange
          },
          _react2.default.createElement(_PriceRangeFilter2.default, {
            featureFlags: featureFlags,
            stylingConfig: stylingConfig,
            translations: translations,
            priceRange: priceRange,
            monthlyPaymentRange: monthlyPaymentRange,
            availableFilters: availableFilters,
            onChangeRangeLabel: this.onChangeRangeLabel,
            handleRangeFilters: handleRangeFilters,
            resetRangeCount: resetRangeCount,
            removeRangeFilter: removeRangeFilter,
            locale: locale,
            headingValues: (_ref4 = {}, (0, _defineProperty3.default)(_ref4, priceRange.id, this.state.priceRangeLabel || priceRange.fullRangeValues), (0, _defineProperty3.default)(_ref4, monthlyPaymentRange.id, this.state.monthlyPaymentRangeLabel || monthlyPaymentRange.fullRangeValues), _ref4),
            rangeValues: (_ref5 = {}, (0, _defineProperty3.default)(_ref5, priceRange.id, selectedRangeFilters[priceRange.id] || priceRange.fullRangeValues), (0, _defineProperty3.default)(_ref5, monthlyPaymentRange.id, selectedRangeFilters[monthlyPaymentRange.id] || monthlyPaymentRange.fullRangeValues), _ref5)
          })
        ),
        _react2.default.createElement(
          _SlideOut2.default,
          {
            qaHook: 'model-variant-filter',
            toggle: function toggle() {
              return _this2.toggle('modelVariant');
            },
            collapsed: this.state.filtersCollapsed.modelVariant,
            renderTitle: (0, _renderFilterTitle2.default)({
              title: translations.searchHeaderModelVariant
            }),
            renderClose: (0, _renderFilterClose2.default)({
              title: translations.searchHeaderModelVariant
            }),
            visible: filterVisibility.modelVariant
          },
          _react2.default.createElement(_CheckboxGroupList2.default, {
            clearCheckboxSection: clearCheckboxesBySection,
            handleCheckboxClick: toggleCheckboxFilter,
            checkboxGroups: [checkboxes.models, checkboxes.variants],
            translations: translations,
            stylingConfig: stylingConfig
          })
        ),
        _react2.default.createElement(
          _SlideOut2.default,
          {
            qaHook: 'engine-transmission-filter',
            toggle: function toggle() {
              return _this2.toggle('engineTransmission');
            },
            collapsed: this.state.filtersCollapsed.engineTransmission,
            renderTitle: (0, _renderFilterTitle2.default)({
              title: translations.searchHeaderEngineTransmission
            }),
            renderClose: (0, _renderFilterClose2.default)({
              title: translations.searchHeaderEngineTransmission
            }),
            visible: filterVisibility.engineTransmission
          },
          _react2.default.createElement(_CheckboxGroupList2.default, {
            clearCheckboxSection: clearCheckboxesBySection,
            handleCheckboxClick: toggleCheckboxFilter,
            checkboxGroups: [checkboxes.engineSizes, checkboxes.engine, checkboxes.transmissions],
            translations: translations,
            stylingConfig: stylingConfig
          })
        ),
        _react2.default.createElement(
          _SlideOut2.default,
          {
            qaHook: 'body-style-filter',
            toggle: function toggle() {
              return _this2.toggle('bodyStyles');
            },
            collapsed: this.state.filtersCollapsed.bodyStyles,
            renderTitle: (0, _renderFilterTitle2.default)({
              title: translations.searchHeaderBodyStyle
            }),
            renderClose: (0, _renderFilterClose2.default)({
              title: translations.searchHeaderBodyStyle
            }),
            visible: filterVisibility.bodyStyles
          },
          _react2.default.createElement(_CheckboxGroupList2.default, {
            clearCheckboxSection: clearCheckboxesBySection,
            handleCheckboxClick: toggleCheckboxFilter,
            checkboxGroups: [checkboxes.bodyStyles],
            translations: translations,
            stylingConfig: stylingConfig
          })
        ),
        _react2.default.createElement(
          _SlideOut2.default,
          {
            qaHook: 'age-mileage-filter',
            toggle: function toggle() {
              return _this2.toggle('ageMileage');
            },
            collapsed: this.state.filtersCollapsed.ageMileage,
            renderTitle: (0, _renderFilterTitle2.default)({
              title: translations.searchHeaderAgeMileage
            }),
            renderClose: (0, _renderFilterClose2.default)({
              title: translations.searchHeaderAgeMileage
            }),
            visible: filterVisibility.ageMileage
          },
          _react2.default.createElement(
            RangeContainer,
            null,
            _react2.default.createElement(_RangeHeading2.default, {
              range: this.state.yearRangeLabel || rangeAge.defaultRangeAgeHeader,
              heading: translations.searchSubHeaderAge,
              unit: translations.unitAge,
              locale: locale
            }),
            _react2.default.createElement(_RangeSlider2.default, {
              stylingConfig: stylingConfig,
              step: yearRange.step,
              rangeValue: yearRange.fullRangeValues,
              name: yearRange.name,
              defaultValue: rangeAge.defaultRangeAgeValues,
              onAfterChange: this.handleAgeRangeChange,
              onChange: function onChange(values) {
                return _this2.onChangeRangeLabel(values, 'yearRangeLabel');
              },
              key: resetRangeCount
            })
          ),
          _react2.default.createElement(
            RangeContainer,
            null,
            _react2.default.createElement(_RangeHeading2.default, {
              range: this.state.mileageRangeLabel || availableFilters.mileageRange,
              heading: translations.searchSubHeaderMileage,
              unit: allFilters.odometerUnits,
              locale: locale
            }),
            _react2.default.createElement(_RangeSlider2.default, {
              stylingConfig: stylingConfig,
              step: mileageRange.step,
              rangeValue: mileageRange.fullRangeValues,
              name: mileageRange.name,
              locale: locale,
              defaultValue: (0, _searchFiltersHelper.getInitialRangeValues)(500, availableFilters.mileageRange),
              onAfterChange: function onAfterChange(values) {
                return handleRangeFilters(mileageRange.id, values);
              },
              onChange: function onChange(values) {
                return _this2.onChangeRangeLabel(values, 'mileageRangeLabel');
              },
              key: resetRangeCount
            })
          )
        ),
        _react2.default.createElement(
          _SlideOut2.default,
          {
            qaHook: 'colour-filter',
            toggle: function toggle() {
              return _this2.toggle('colour');
            },
            collapsed: this.state.filtersCollapsed.colour,
            renderTitle: (0, _renderFilterTitle2.default)({
              title: translations.searchHeaderColour
            }),
            renderClose: (0, _renderFilterClose2.default)({
              title: translations.searchHeaderColour
            }),
            visible: filterVisibility.colour
          },
          _react2.default.createElement(_CheckboxGroupList2.default, {
            clearCheckboxSection: clearCheckboxesBySection,
            handleCheckboxClick: toggleCheckboxFilter,
            checkboxGroups: [checkboxes.exteriorColours, checkboxes.interiorShades],
            translations: translations,
            stylingConfig: stylingConfig
          })
        ),
        _react2.default.createElement(
          _SlideOut2.default,
          {
            qaHook: 'features-filter',
            toggle: function toggle() {
              return _this2.toggle('features');
            },
            collapsed: this.state.filtersCollapsed.features,
            renderTitle: (0, _renderFilterTitle2.default)({
              title: translations.searchHeaderFeatures
            }),
            renderClose: (0, _renderFilterClose2.default)({
              title: translations.searchHeaderFeatures
            }),
            visible: filterVisibility.features
          },
          _react2.default.createElement(_CheckboxGroupList2.default, {
            clearCheckboxSection: clearCheckboxesBySection,
            handleCheckboxClick: toggleCheckboxFilter,
            checkboxGroups: [checkboxes.features],
            translations: translations,
            stylingConfig: stylingConfig
          })
        )
      );
    }
  }], [{
    key: 'getDerivedStateFromProps',
    value: function getDerivedStateFromProps(props, state) {
      var _transformRangeProps2 = (0, _searchFiltersHelper.transformRangeProps)(props.allFilters),
          priceRange = _transformRangeProps2.priceRange,
          monthlyPaymentRange = _transformRangeProps2.monthlyPaymentRange;

      var priceRangeLabel = {
        min: !state.resetRangeCount && props.selectedRangeFilters[priceRange.id] ? props.selectedRangeFilters[priceRange.id].min : priceRange.defaultValues[0],
        max: !state.resetRangeCount && props.selectedRangeFilters[priceRange.id] ? props.selectedRangeFilters[priceRange.id].max : priceRange.defaultValues[1]
      };
      var monthlyPaymentRangeLabel = {
        min: !state.resetRangeCount && props.selectedRangeFilters[monthlyPaymentRange.id] ? props.selectedRangeFilters[monthlyPaymentRange.id].min : monthlyPaymentRange.defaultValues[0],
        max: !state.resetRangeCount && props.selectedRangeFilters[monthlyPaymentRange.id] ? props.selectedRangeFilters[monthlyPaymentRange.id].max : monthlyPaymentRange.defaultValues[1]
      };
      var valuesReset = props.resetRangeCount !== state.resetRangeCount;

      return valuesReset ? (0, _extends4.default)({}, state, {
        resetRangeCount: props.resetRangeCount,
        priceRangeLabel: priceRangeLabel,
        monthlyPaymentRangeLabel: monthlyPaymentRangeLabel
      }) : null;
    }
  }]);
  return SearchFilters;
}(_react.Component);

exports.default = SearchFilters;