'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

exports.default = StyledSlider;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _rcSlider = require('rc-slider');

var _rcSlider2 = _interopRequireDefault(_rcSlider);

require('rc-slider/assets/index.css');

var _Handle = require('./Handle');

var _Handle2 = _interopRequireDefault(_Handle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function StyledSlider(_ref) {
  var step = _ref.step,
      defaultValue = _ref.defaultValue,
      onAfterChange = _ref.onAfterChange,
      onChange = _ref.onChange,
      rangeValue = _ref.rangeValue,
      marks = _ref.marks,
      sliderColor = _ref.sliderColor;

  var marksOrStep = (0, _keys2.default)(marks).length ? null : step;
  return _react2.default.createElement(_rcSlider2.default, {
    handle: _Handle2.default,
    trackStyle: [{ backgroundColor: sliderColor }],
    railStyle: { backgroundColor: '#F0F0F0' },
    marks: marks,
    min: rangeValue.min,
    max: rangeValue.max,
    step: marksOrStep,
    defaultValue: defaultValue,
    allowCross: false,
    dotStyle: { borderColor: '#F0F0F0' },
    activeDotStyle: { borderColor: sliderColor },
    onAfterChange: onAfterChange,
    onChange: onChange
  });
}

StyledSlider.defaultProps = {
  step: 1,
  marks: {},
  defaultValue: 0,
  rangeValue: { min: 0, max: 100 },
  onAfterChange: function onAfterChange() {},
  onChange: function onChange() {}
};