'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Location = require('./JaguarIcons/Global/Location');

var _Location2 = _interopRequireDefault(_Location);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LocationContainer = _styledComponents2.default.div.withConfig({
  displayName: 'LocationInput__LocationContainer',
  componentId: 'sc-e7dr9k-0'
})(['background:#ffffff;display:flex;', ';width:100%;', ';'], function (_ref) {
  var inputStyles = _ref.inputStyles;
  return 'height: ' + (inputStyles && inputStyles.height || '40px') + ';\n    max-height: ' + (inputStyles && inputStyles.height || '40px');
}, function (_ref2) {
  var isStickySearchBar = _ref2.isStickySearchBar;
  return isStickySearchBar ? 'width: initial' : 'width: 100%;';
});


var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LocationInput__IconWrapper',
  componentId: 'sc-e7dr9k-1'
})(['align-items:center;border:1px solid #d8d8d8;', ';cursor:pointer;display:flex;justify-content:center;width:20%;', ';'], function (_ref3) {
  var direction = _ref3.direction;
  return direction === 'rtl' ? 'border-left: none;' : 'border-right: none;';
}, function (_ref4) {
  var inputStyles = _ref4.inputStyles;
  return 'height: ' + (inputStyles && inputStyles.height || 'auto');
});

var StyledInput = _styledComponents2.default.input.withConfig({
  displayName: 'LocationInput__StyledInput',
  componentId: 'sc-e7dr9k-2'
})(['width:80%;padding:0 5%;margin:0;border:1px solid #d8d8d8;', ';', ';&:focus{border:1px solid #999999;outline:none;}', ';'], function (_ref5) {
  var inputStyles = _ref5.inputStyles;
  return 'height: ' + (inputStyles && inputStyles.height || 'auto');
}, function (_ref6) {
  var direction = _ref6.direction;
  return direction === 'rtl' && 'font-size: 16px';
}, function (_ref7) {
  var isStickySearchBar = _ref7.isStickySearchBar;
  return isStickySearchBar && 'height: 46px; width: 100%; border: 0;';
});

var LocationInput = function (_Component) {
  (0, _inherits3.default)(LocationInput, _Component);

  function LocationInput() {
    var _ref8;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, LocationInput);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref8 = LocationInput.__proto__ || (0, _getPrototypeOf2.default)(LocationInput)).call.apply(_ref8, [this].concat(args))), _this), Object.defineProperty(_this, 'input', {
      enumerable: true,
      writable: true,
      value: null
    }), Object.defineProperty(_this, 'autocomplete', {
      enumerable: true,
      writable: true,
      value: null
    }), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this2 = _this,
            input = _this2.input;
        var searchCountryCode = _this.props.searchCountryCode;

        _this.autocomplete = new window.google.maps.places.Autocomplete(input, searchCountryCode && {
          types: ['geocode'],
          componentRestrictions: {
            country: searchCountryCode
          }
        });
        _this.autocomplete.addListener('place_changed', _this.getSearchLocation);
        window.addEventListener('scroll', _this.hideAutocomplete);
      }
    }), Object.defineProperty(_this, 'autocompleteResultsPosition', {
      enumerable: true,
      writable: true,
      value: function value() {
        var googleSuggest = document.querySelectorAll('.pac-container');
        return googleSuggest.forEach(function (el) {
          return _this.props.isStickySearchBar ? el.setAttribute('style', 'min-height: 176px; margin-top: -226px;') : el.removeAttribute('style');
        });
      }
    }), Object.defineProperty(_this, 'hideAutocomplete', {
      enumerable: true,
      writable: true,
      value: function value() {
        var googleSuggest = document.querySelectorAll('.pac-container');
        return googleSuggest.forEach(function (el) {
          return el.setAttribute('style', 'display: none;');
        });
      }
    }), Object.defineProperty(_this, 'getSearchLocation', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.props.handleBlur(_this.input.value);
      }
    }), Object.defineProperty(_this, 'getCurrentPosition', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (!('geolocation' in navigator)) {
          return;
        }
        navigator.geolocation.getCurrentPosition(function (res) {
          return _this.props.onGetCurrentPosition(res);
        });
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(LocationInput, [{
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          placeholder = _props.placeholder,
          locationValue = _props.locationValue,
          locationIconColour = _props.locationIconColour,
          handleChange = _props.handleChange,
          isStickySearchBar = _props.isStickySearchBar,
          globalStyling = _props.globalStyling,
          inputStyles = _props.inputStyles;

      return _react2.default.createElement(
        LocationContainer,
        {
          isStickySearchBar: isStickySearchBar,
          inputStyles: inputStyles
        },
        this.props.onGetCurrentPosition && _react2.default.createElement(
          IconWrapper,
          {
            'data-cy': 'get-location-button',
            onClick: this.getCurrentPosition,
            direction: globalStyling && globalStyling.direction,
            inputStyles: inputStyles
          },
          _react2.default.createElement(_Location2.default, { colour: locationIconColour, width: '2em', height: '2em' })
        ),
        _react2.default.createElement(StyledInput, {
          'data-cy': 'location-input',
          placeholder: placeholder,
          onClick: this.autocompleteResultsPosition,
          onChange: function onChange(event) {
            handleChange(event.target.value);
          },
          value: locationValue,
          isStickySearchBar: isStickySearchBar,
          direction: globalStyling && globalStyling.direction,
          ref: function ref(input) {
            _this3.input = input;
          },
          inputStyles: inputStyles
        })
      );
    }
  }]);
  return LocationInput;
}(_react.Component);

Object.defineProperty(LocationInput, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    handleBlur: function handleBlur() {}
  }
});
exports.default = LocationInput;