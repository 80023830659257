'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px 5%;\n  '], ['\n    padding: 20px 5%;\n  ']);

exports.default = FinanceCalculator;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reactScriptTag = require('react-script-tag');

var _reactScriptTag2 = _interopRequireDefault(_reactScriptTag);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Spinner = require('../../../components/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _dates = require('../../../shared/localisation/dates');

var _settings = require('../../../shared/selectors/settings');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AppState = require('../../../types/AppState');

var _vehicleTypes = require('../../../types/AstonMartin/vehicleTypes');

var _useChangeReleaseFlag = require('../../../utilities/useChangeReleaseFlag');

var _CalculationsResponseType = require('./CalculationsResponseType');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceCalculator__Container',
  componentId: 'sc-1bj7jcm-0'
})(['box-sizing:border-box;display:flex;border-bottom:1px solid #dddddd;background-color:#ffffff;justify-content:center;padding:20px 0;', ';'], _theme2.default.max.medium(_templateObject));

var ButtonContainer = (0, _styledComponents2.default)(Container).withConfig({
  displayName: 'FinanceCalculator__ButtonContainer',
  componentId: 'sc-1bj7jcm-1'
})(['padding:40px 0;']);

var SpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceCalculator__SpinnerWrapper',
  componentId: 'sc-1bj7jcm-2'
})(['display:flex;justify-content:center;width:100%;']);

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceCalculator__Wrapper',
  componentId: 'sc-1bj7jcm-3'
})(['display:flex;flex-direction:column;max-width:1200px;width:100%;']);

var PluginContent = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceCalculator__PluginContent',
  componentId: 'sc-1bj7jcm-4'
})(['height:auto;width:100%;padding-top:32px;background:white;']);

var FinanceButton = _styledComponents2.default.button.withConfig({
  displayName: 'FinanceCalculator__FinanceButton',
  componentId: 'sc-1bj7jcm-5'
})(['background-color:', ';border-radius:20px;border:0;color:', ';cursor:pointer;font-family:AstonMartinFlare-XBold;font-size:16px;padding:16px 32px;transition:0.3s;&:hover{background:', ';}'], function (_ref) {
  var invertedHeader = _ref.invertedHeader;
  return invertedHeader ? 'rgba(255, 255, 255, 0.08)' : 'rgba(65, 65, 65, 0.08)';
}, function (_ref2) {
  var invertedHeader = _ref2.invertedHeader;
  return invertedHeader ? '#fff' : '#000';
}, function (_ref3) {
  var invertedHeader = _ref3.invertedHeader;
  return invertedHeader ? 'rgba(65, 65, 65, 0.5)' : 'rgba(65,65,65,.2)';
});

function FinanceCalculator(_ref4) {
  var buttonText = _ref4.buttonText,
      vehicle = _ref4.vehicle,
      onCalculationsDone = _ref4.onCalculationsDone,
      navigateToEnquireForm = _ref4.navigateToEnquireForm;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      colours = _useSelector.colours;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      scriptLoaded = _useState2[0],
      setScriptLoaded = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      open = _useState4[0],
      setOpen = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      showSpinner = _useState6[0],
      setShowSpinner = _useState6[1];

  // We don't want to load or execute any of the below code if the finance calculator is disabled.


  if (!(0, _useChangeReleaseFlag.useChangeReleaseFlag)('MT-417')) return null;

  var _useSelector2 = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.financeCalculator;
  }),
      availableCountries = _useSelector2.availableCountries,
      url = _useSelector2.url;

  var loadPlugin = function loadPlugin(vehicle, setShowSpinner) {
    // formatedDate supported for the plugin: yyyy/MM/DD
    var formatedDate = vehicle.registrationDate && (0, _dates.localeFormattedDate)(vehicle.registrationDate, 'en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });

    window.codeweavers.main({
      pluginContentDivId: 'cw-plugin-container',
      vehicle: {
        identifierType: 'Vin',
        identifier: vehicle.vin,
        type: 'car',
        isNew: false,
        cashPrice: vehicle.price.value,
        imageUrl: vehicle.images[0],
        linkBackUrl: window.location.href,
        registration: {
          number: vehicle.vin,
          year: vehicle.registration,
          date: formatedDate
        },
        mileage: vehicle.specification.odometer && vehicle.specification.odometer.reading,
        mileageUnit: 'Miles',
        isFranchiseApproved: vehicle.approved
      },
      dealer: vehicle.retailerInformation.oemId,
      onDomainEvent: function onDomainEvent(name) {
        switch (name) {
          case 'plugin.loaded':
          case 'plugin.errored':
            setShowSpinner(false);
            break;
        }
      },
      // This callback is invoked each time the finance plugin performs a new calculation.
      onSuccessfulCalculation: function onSuccessfulCalculation(calculations) {
        onCalculationsDone({
          deposit: calculations.deposit_contribution,
          payment: calculations.final_payment,
          mileage: calculations.annual_mileage,
          term: calculations.term,
          financeType: calculations.product.type
        });
      },
      onUserEvent: function onUserEvent(eventName) {
        switch (eventName) {
          case 'plugin.loaded':
          case 'plugin.errored':
            setShowSpinner(false);
            break;
          case 'enquire.button.clicked':
            navigateToEnquireForm();
            break;
        }
      }
    });
  };

  (0, _react.useEffect)(function () {
    if (vehicle && scriptLoaded) {
      loadPlugin(vehicle, setShowSpinner);
    }
  }, [vehicle, scriptLoaded, setShowSpinner]);

  var displayFinanceCalculator = vehicle && availableCountries.includes(vehicle.retailerInformation.country) && vehicle.specification.odometer && vehicle.price.value;

  return displayFinanceCalculator ? !open ? _react2.default.createElement(
    ButtonContainer,
    null,
    _react2.default.createElement(
      FinanceButton,
      {
        'data-cy': 'discover-quote-cta',
        onClick: function onClick() {
          setOpen(true);
          setShowSpinner(true);
        }
      },
      buttonText
    )
  ) : _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Wrapper,
      null,
      showSpinner && _react2.default.createElement(
        SpinnerWrapper,
        null,
        _react2.default.createElement(_Spinner2.default, { size: 50, colour: colours.primaryBrandColour })
      ),
      scriptLoaded ? _react2.default.createElement(PluginContent, { id: 'cw-plugin-container' }) : _react2.default.createElement(_reactScriptTag2.default, {
        id: 'cw-plugin-container',
        type: 'text/javascript',
        onLoad: function onLoad() {
          setScriptLoaded(true);
        },
        src: url
      })
    )
  ) : null;
}