'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)([' \n    padding: 460px 0px 410px 0px;\n    '], [' \n    padding: 460px 0px 410px 0px;\n    ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)([' \n  font-size: 32px;\n  font-weight: 300;\n  line-height: 41px;\n  letter-spacing: 2px;\n  height: 42px;\n  '], [' \n  font-size: 32px;\n  font-weight: 300;\n  line-height: 41px;\n  letter-spacing: 2px;\n  height: 42px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  width: 640px;\n   '], ['\n  width: 640px;\n   ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 50px;\n   '], ['\n    margin-top: 50px;\n   ']);

exports.default = GoodByeSection;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Button = require('../../../components/RollsRoyce/Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _useCheckIsMobileScreen = require('../../../../dist/hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GoodByeSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'GoodbyeSection__GoodByeSectionWrapper',
  componentId: 'sc-osc4xk-0'
})(['width:100%;background-image:', ';background-repeat:no-repeat;background-size:cover;margin:0 auto;'], function (_ref) {
  var background = _ref.background;
  return 'url(' + background + ')' || '';
});

var GoodByeSectionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GoodbyeSection__GoodByeSectionContainer',
  componentId: 'sc-osc4xk-1'
})(['display:flex;flex-direction:column;align-items:center;justify-content:center;padding:285px 35px;', ';'], _theme2.default.min.large(_templateObject));
var GoodByeSectionTitle = _styledComponents2.default.div.withConfig({
  displayName: 'GoodbyeSection__GoodByeSectionTitle',
  componentId: 'sc-osc4xk-2'
})(['width:100%;text-align:center;color:#fff;font-size:24px;line-height:31px;text-align:center;text-transform:uppercase;', ';'], _theme2.default.min.large(_templateObject2));

var GoodByeSectionContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'GoodbyeSection__GoodByeSectionContentContainer',
  componentId: 'sc-osc4xk-3'
})(['margin-top:40px;']);

var GoodByeSectionContent = _styledComponents2.default.p.withConfig({
  displayName: 'GoodbyeSection__GoodByeSectionContent',
  componentId: 'sc-osc4xk-4'
})(['text-align:justify;color:#fff;width:324px;font-size:14px;font-weight:300;line-height:30px;letter-spacing:0.5px;text-align:center;margin:0;', ';'], _theme2.default.min.large(_templateObject3));

var ButtonsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'GoodbyeSection__ButtonsWrapper',
  componentId: 'sc-osc4xk-5'
})(['margin-top:30px;align-items:center;width:276px;height:46px;', ';'], _theme2.default.min.large(_templateObject4));

function GoodByeSection(_ref2) {
  var config = _ref2.config;
  var _config$callToActionU = config.callToActionUrl,
      callToActionUrl = _config$callToActionU === undefined ? '' : _config$callToActionU,
      _config$backgroundIma = config.backgroundImageUrlDesktop,
      backgroundImageUrlDesktop = _config$backgroundIma === undefined ? '' : _config$backgroundIma,
      _config$backgroundIma2 = config.backgroundImageUrlMobile,
      backgroundImageUrlMobile = _config$backgroundIma2 === undefined ? '' : _config$backgroundIma2,
      _config$translations = config.translations;
  _config$translations = _config$translations === undefined ? null : _config$translations;
  var _config$translations$ = _config$translations.title,
      title = _config$translations$ === undefined ? '' : _config$translations$,
      _config$translations$2 = _config$translations.contentSectionOne,
      contentSectionOne = _config$translations$2 === undefined ? '' : _config$translations$2,
      _config$translations$3 = _config$translations.contentSectionTwo,
      contentSectionTwo = _config$translations$3 === undefined ? '' : _config$translations$3,
      _config$translations$4 = _config$translations.buttonLabel,
      buttonLabel = _config$translations$4 === undefined ? '' : _config$translations$4;

  var isMobile = (0, _useCheckIsMobileScreen2.default)();
  var backgroundImageUrl = isMobile ? backgroundImageUrlMobile : backgroundImageUrlDesktop;

  var _useNavigation = (0, _navigation2.default)(),
      navigateTo = _useNavigation.navigateTo;

  return _react2.default.createElement(
    GoodByeSectionWrapper,
    { background: backgroundImageUrl },
    _react2.default.createElement(
      GoodByeSectionContainer,
      null,
      _react2.default.createElement(
        GoodByeSectionTitle,
        null,
        ' ',
        title,
        ' '
      ),
      _react2.default.createElement(
        GoodByeSectionContentContainer,
        null,
        _react2.default.createElement(
          GoodByeSectionContent,
          null,
          contentSectionOne
        ),
        _react2.default.createElement(
          GoodByeSectionContent,
          null,
          contentSectionTwo
        )
      ),
      _react2.default.createElement(
        ButtonsWrapper,
        null,
        callToActionUrl && _react2.default.createElement(_Button2.default, {
          variant: 'tertiary',
          text: buttonLabel,
          onClick: function onClick() {
            navigateTo(callToActionUrl);
          },
          styleOverride: function styleOverride() {
            return '\n                height:46px; \n                min-width:276px;\n                font-family: \'RivieraNights-Medium\';\n                & span {\n                  letter-spacing: 2px;\n                }\n              ';
          }
        })
      )
    )
  );
}