'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var _SectionIcons = require('../../helpers/SectionIcons');

var _SectionIcons2 = _interopRequireDefault(_SectionIcons);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var config = (0, _config.Config)('Linked Test', { id: 'LinkedTest', linked: true }, (0, _config.Slice)('Content', { id: 'content' }, (0, _config.Section)('Filter options', { icon: _SectionIcons2.default.TopTier }, (0, _config.DraggableListWithToggle)('filterfilter', 'filterOptions'))));

exports.default = config;