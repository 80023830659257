'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validPhone = exports.valueMatches = exports.passwordMatchesRequired = exports.meetsPasswordPolicy = exports.valueMatchesRequired = exports.valueIsTrue = exports.validEmail = exports.required = undefined;

var _validate = require('validate.js');

var _validate2 = _interopRequireDefault(_validate);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_validate2.default.validators.isValidPhone = function (value, options) {
  var regex = /^\+?[0-9]+$/;
  if (value !== '' && !regex.exec(value)) return options.message;
  return undefined;
}; /* eslint-disable import/extensions */
var required = exports.required = function required(value, translations) {
  return _validate2.default.single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    }
  });
};

var validEmail = exports.validEmail = function validEmail(value, translations) {
  return _validate2.default.single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    },
    email: {
      message: translations.formValidationEmail
    }
  });
};

var valueIsTrue = exports.valueIsTrue = function valueIsTrue(value, translations) {
  return value !== true ? [translations.formValidationRequired] : undefined;
};

var valueMatchesRequired = exports.valueMatchesRequired = function valueMatchesRequired(value, comparedValue, translations) {
  if (!comparedValue) return [translations.formValidationRequired];
  if (value !== comparedValue) return [translations.formValidationEmailMatch];
  return undefined;
};

var meetsPasswordPolicy = exports.meetsPasswordPolicy = function meetsPasswordPolicy(value, translations, isRequired) {
  var policy = [/[0-9]+/, /[A-Z]+/, /[a-z]+/, /.{10,}/, /[^\da-zA-Z]/];
  if (isRequired && !value) return [translations.formValidationRequired];
  if (value && !policy.every(function (rule) {
    return value.match(rule);
  })) {
    return [translations.formValidationPasswordPolicy];
  }
  return undefined;
};

var passwordMatchesRequired = exports.passwordMatchesRequired = function passwordMatchesRequired(value, comparedValue, translations) {
  if (!comparedValue) return [translations.formValidationRequired];
  if (value !== comparedValue) {
    return [translations.formValidationPasswordMatch];
  }
  return undefined;
};

var valueMatches = exports.valueMatches = function valueMatches(value, comparedValue, error) {
  if (value !== comparedValue) return [error];
  return undefined;
};

var validPhone = exports.validPhone = function validPhone(value, translations) {
  return _validate2.default.single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    },
    isValidPhone: {
      message: translations.formValidationPhone
    }
  });
};