'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _reactRedux = require('react-redux');

var _filters = require('../shared/filters');

var _sessionPreferences = require('../shared/sessionPreferences');

var _StickySearchBar = require('../components/StickySearchBar');

var _StickySearchBar2 = _interopRequireDefault(_StickySearchBar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mapStateToProps = function mapStateToProps(_ref) {
  var shared = _ref.shared;
  return {
    filterSearchUrl: shared.searchFilter.filterSearchUrl,
    maxDistance: shared.filters.selectedFilters && shared.filters.availableFilterValues && (parseInt(shared.filters.selectedFilters.maxDistance, 10) || shared.filters.availableFilterValues.maxDistance[0]),
    location: shared.sessionPreferences.location || {},
    distanceOptions: function distanceOptions(translations) {
      return shared.filters.availableFilterValues && shared.filters.availableFilterValues.maxDistance.map(function (f) {
        return f.translationKey && (0, _extends3.default)({}, f, {
          label: translations[f.translationKey]
        }) || f;
      }) || [];
    }
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    resetFilters: function resetFilters() {
      if (ownProps.onReset) {
        ownProps.onReset();
      }
      dispatch(_filters.actions.resetFilters());
      dispatch(_filters.actions.initialiseFilters());
    },
    handleLocationChange: function handleLocationChange(_ref2) {
      var latitude = _ref2.latitude,
          longitude = _ref2.longitude,
          locationTown = _ref2.locationTown;

      dispatch(_sessionPreferences.actions.updateSessionPreferences('location', {
        latitude: latitude,
        longitude: longitude,
        locationTown: locationTown
      }));
    },
    handleMaxDistanceChange: function handleMaxDistanceChange(maxDistance) {
      return dispatch(_filters.actions.updateFilters([{
        key: 'maxDistance',
        value: maxDistance
      }]));
    },
    buttonPress: function buttonPress() {
      dispatch(_filters.actions.searchWithFilters());
      dispatch(_sessionPreferences.actions.updateSessionPreferences('resetSearchResultsPageNumber', true));
    }
  };
};

exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_StickySearchBar2.default);