'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _FiltersContainer = require('../../linked-modules/Filters/FiltersContainer');

var _FiltersContainer2 = _interopRequireDefault(_FiltersContainer);

var _filters = require('../../shared/filters');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var config = _ref.config,
      globalStyling = _ref.globalStyling,
      _ref$config$endpoints = _ref.config.endpoints,
      endpoints = _ref$config$endpoints === undefined ? {} : _ref$config$endpoints;

  var dispatch = (0, _reactRedux.useDispatch)();
  var shared = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  });
  var queryParams = (0, _reactRedux.useSelector)(function (state) {
    return state.router.queryParams;
  });
  (0, _react.useEffect)(function () {
    dispatch(_filters.actions.initialiseFilters(queryParams, endpoints.filters));
  }, []);
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(_FiltersContainer2.default, {
      config: config,
      globalStyling: globalStyling,
      shared: shared,
      hideSearch: config.hideSearch
    })
  );
};