'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n  '], ['\n    flex-direction: row;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n    height: 450px;\n    background-color: #f0f0f0;\n  '], ['\n    width: 50%;\n    height: 450px;\n    background-color: #f0f0f0;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 150px 150px 150px 60px;\n  '], ['\n    padding: 150px 150px 150px 60px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n    font-weight: 600;\n    font-size: 20px;\n    letter-spacing: 3px;\n    text-transform: uppercase;\n    margin-bottom: 30px;\n  '], ['\n    display: block;\n    font-weight: 600;\n    font-size: 20px;\n    letter-spacing: 3px;\n    text-transform: uppercase;\n    margin-bottom: 30px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 15px;\n    color: #444444;\n    line-height: 20px;\n  '], ['\n    font-size: 15px;\n    color: #444444;\n    line-height: 20px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n    height: 450px;\n    object-fit: cover;\n  '], ['\n    width: 50%;\n    height: 450px;\n    object-fit: cover;\n  ']);

exports.default = FiftyFifty;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FiftyFiftyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty'
}).withConfig({
  displayName: 'FiftyFifty__FiftyFiftyContainer',
  componentId: 'sc-skjyrg-0'
})(['width:100%;display:flex;flex-direction:column-reverse;', ';'], _theme2.default.min.large(_templateObject));
var FiftyFiftyContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__FiftyFiftyContentWrapper',
  componentId: 'sc-skjyrg-1'
})(['width:100%;', ';'], _theme2.default.min.large(_templateObject2));
var FiftyFiftyContent = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__FiftyFiftyContent',
  componentId: 'sc-skjyrg-2'
})(['padding:20px 0;', ';'], _theme2.default.min.large(_templateObject3));
var FiftyFiftyContentHeader = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__FiftyFiftyContentHeader',
  componentId: 'sc-skjyrg-3'
})(['display:none;', ';'], _theme2.default.min.large(_templateObject4));
var FiftyFiftyMainContent = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__FiftyFiftyMainContent',
  componentId: 'sc-skjyrg-4'
})(['display:block;color:#343434;', ';'], _theme2.default.min.large(_templateObject5));
var FiftyFiftyImage = _styledComponents2.default.img.withConfig({
  displayName: 'FiftyFifty__FiftyFiftyImage',
  componentId: 'sc-skjyrg-5'
})(['width:100%;', ';'], _theme2.default.min.large(_templateObject6));

function FiftyFifty(props) {
  return _react2.default.createElement(
    FiftyFiftyContainer,
    null,
    _react2.default.createElement(
      FiftyFiftyContentWrapper,
      null,
      _react2.default.createElement(
        FiftyFiftyContent,
        null,
        _react2.default.createElement(
          FiftyFiftyContentHeader,
          null,
          props.title
        ),
        _react2.default.createElement(
          FiftyFiftyMainContent,
          null,
          props.content
        )
      )
    ),
    _react2.default.createElement(FiftyFiftyImage, { src: props.image })
  );
}