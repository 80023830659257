'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoundedCheckBox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'RoundedCheckBox__Container',
  componentId: 'sc-nlqi20-0'
})(['display:flex;align-items:center;cursor:pointer;text-align:left;']);

var CheckBoxWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RoundedCheckBox__CheckBoxWrapper',
  componentId: 'sc-nlqi20-1'
})(['border:1px solid ', ';border-radius:50%;padding:4px;'], function (_ref) {
  var colour = _ref.colour;
  return colour || '#444444';
});

var CheckBoxChecked = _styledComponents2.default.div.withConfig({
  displayName: 'RoundedCheckBox__CheckBoxChecked',
  componentId: 'sc-nlqi20-2'
})(['width:14px;height:14px;border-radius:50%;']);

var CheckBoxUnChecked = _styledComponents2.default.div.withConfig({
  displayName: 'RoundedCheckBox__CheckBoxUnChecked',
  componentId: 'sc-nlqi20-3'
})(['width:14px;height:14px;border-radius:50%;background-color:', ';'], function (_ref2) {
  var colour = _ref2.colour;
  return colour || '#444444';
});

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'RoundedCheckBox__Label',
  componentId: 'sc-nlqi20-4'
})(['font-size:', ';color:', ';padding:25px 5px;'], function (_ref3) {
  var labelFontSize = _ref3.labelFontSize;
  return labelFontSize ? labelFontSize + 'px' : '16px';
}, function (_ref4) {
  var colour = _ref4.colour;
  return colour || '#444444';
});

var Checkbox = function Checkbox(_ref5) {
  var checked = _ref5.checked,
      globalStyling = _ref5.globalStyling;
  return checked ? _react2.default.createElement(CheckBoxUnChecked, {
    colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
  }) : _react2.default.createElement(CheckBoxChecked, {
    colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
  });
};

Checkbox.defaultProps = {
  error: ''
};

function RoundedCheckBox(_ref6) {
  var onClick = _ref6.onClick,
      checked = _ref6.checked,
      label = _ref6.label,
      labelFontSize = _ref6.labelFontSize,
      globalStyling = _ref6.globalStyling,
      required = _ref6.required,
      error = _ref6.error;

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      Container,
      { onClick: onClick },
      _react2.default.createElement(
        CheckBoxWrapper,
        {
          colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
        },
        _react2.default.createElement(Checkbox, { checked: checked, globalStyling: globalStyling })
      ),
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          Label,
          { labelFontSize: labelFontSize, error: error },
          label,
          required && '*'
        )
      )
    ),
    error && _react2.default.createElement(
      'div',
      {
        style: {
          color: '#9e1b32'
        }
      },
      error
    )
  );
}