'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _ResultTileContainer = require('../../linked-modules/ResultTile/ResultTileContainer');

var _ResultTileContainer2 = _interopRequireDefault(_ResultTileContainer);

var _filters = require('../../shared/filters');

var _financeCalculator = require('../../shared/financeCalculator');

var _sessionPreferences = require('../../shared/sessionPreferences');

var _shortlist = require('../../shared/shortlist');

var _theme = require('../../theme');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FIRST_PAGE = '1';


var Results = _styledComponents2.default.div.withConfig({
  displayName: 'LinkedVehicleSearchResults__Results',
  componentId: 'sc-o7pr2z-0'
})(['display:flex;flex-wrap:wrap;margin-top:16px;']);

var LinkedVehicleSearchResults = function (_Component) {
  (0, _inherits3.default)(LinkedVehicleSearchResults, _Component);

  function LinkedVehicleSearchResults(props) {
    (0, _classCallCheck3.default)(this, LinkedVehicleSearchResults);

    var _this = (0, _possibleConstructorReturn3.default)(this, (LinkedVehicleSearchResults.__proto__ || (0, _getPrototypeOf2.default)(LinkedVehicleSearchResults)).call(this, props));

    _initialiseProps.call(_this);

    var selectedView = window.innerWidth < _theme.screenSizeOptions.large ? 'GRID' : 'LIST';
    _this.state = {
      filtersExpanded: false,
      resultPageSize: '6',
      resultPageNumber: FIRST_PAGE,
      leasingExampleExpanded: false,
      selectedView: selectedView
    };
    return _this;
  }

  (0, _createClass3.default)(LinkedVehicleSearchResults, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // We call init to force a first run of the reducer
      // - there used to be a more generic solution,
      //   but it broke the builder
      var _props = this.props,
          actions = _props.actions,
          dispatch = _props.dispatch,
          history = _props.history,
          featureFlags = _props.featureFlags;


      var query = history.location.search.substring(1);
      this.setState({ query: query });
      actions.init();

      this.updateInventory(this.state.resultPageSize, this.state.resultPageNumber, this.props.shared.sessionPreferences.searchSort);
      dispatch(_filters.actions.initialiseFilters(query));
      dispatch(_shortlist.actions.getVehicles());

      if (this.retailerId) {
        actions.getDealer(this.retailerId);
      }

      if (featureFlags.finance && !this.props.shared.financeCalculator.representativeExample) {
        dispatch(_financeCalculator.actions.loadRepresentativeExample());
      }

      window.addEventListener('resize', this.onViewportChange);
      window.scroll(0, 0);
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var query = this.props.history.location.search.substring(1);
      if (this.state.query !== query) {
        this.setState({ query: query });
        this.updateInventory(this.state.resultPageSize, FIRST_PAGE, this.props.shared.sessionPreferences.searchSort);
      }
      var shared = this.props.shared;

      if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language) {
        this.updateInventory(this.state.resultPageSize, FIRST_PAGE, shared.sessionPreferences.searchSort);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          state = _props2.state,
          pathByModule = _props2.pathByModule,
          globalStyling = _props2.globalStyling,
          linkedModules = _props2.linkedModules;

      var tileConfig = (0, _values2.default)(linkedModules).find(function (lm) {
        return lm.name === 'ResultTile';
      }).config;
      var results = state.searchResultConfig;

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          _react2.default.createElement(
            Results,
            null,
            results && results.contents && results.contents.map(function (vehicle) {
              return _react2.default.createElement(_ResultTileContainer2.default, {
                key: vehicle.id,
                vehicle: vehicle,
                placeholdingImage: results.placeholdingImage,
                globalStyling: globalStyling,
                pathByModule: pathByModule,
                moduleConfig: tileConfig
              });
            })
          )
        )
      );
    }
  }]);
  return LinkedVehicleSearchResults;
}(_react.Component);

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  Object.defineProperty(this, 'onViewportChange', {
    enumerable: true,
    writable: true,
    value: function value() {
      if (window.innerWidth < _theme.screenSizeOptions.large) {
        _this2.setState(function () {
          return { selectedView: 'GRID' };
        });
      }
    }
  });
  Object.defineProperty(this, 'onFilterMenuClick', {
    enumerable: true,
    writable: true,
    value: function value() {
      return _this2.setState(function (prevState) {
        return {
          filtersExpanded: !prevState.filtersExpanded
        };
      });
    }
  });
  Object.defineProperty(this, 'onShowAllResultsClick', {
    enumerable: true,
    writable: true,
    value: function value(totalResultCount) {
      var resultPageSize = totalResultCount.toString();
      _this2.setState({ resultPageSize: resultPageSize });
      _this2.updateInventory(resultPageSize, FIRST_PAGE, _this2.props.shared.sessionPreferences.searchSort, _this2.props.preview);
    }
  });
  Object.defineProperty(this, 'onPageNumberChange', {
    enumerable: true,
    writable: true,
    value: function value(_value) {
      _this2.setState({ resultPageNumber: _value });
      _this2.updateInventory(_this2.state.resultPageSize, _value, _this2.props.shared.sessionPreferences.searchSort);
      window.scroll(0, 0);
    }
  });
  Object.defineProperty(this, 'onSortChange', {
    enumerable: true,
    writable: true,
    value: function value(payload, sortField) {
      _this2.props.dispatch(_filters.actions.updateFilters(payload));
      _this2.props.dispatch(_sessionPreferences.actions.updateSessionPreferences('searchSort', sortField));
    }
  });
  Object.defineProperty(this, 'onViewChange', {
    enumerable: true,
    writable: true,
    value: function value(selectedView) {
      return _this2.setState(function () {
        return { selectedView: selectedView };
      });
    }
  });
  Object.defineProperty(this, 'toggleLeasingExample', {
    enumerable: true,
    writable: true,
    value: function value() {
      _this2.setState(function (prevState) {
        return (0, _extends3.default)({}, prevState, {
          leasingExampleExpanded: !prevState.leasingExampleExpanded
        });
      });
    }
  });
  Object.defineProperty(this, 'updateInventory', {
    enumerable: true,
    writable: true,
    value: function value(pageSize, pageNumber, sortField) {
      var _props3 = _this2.props,
          actions = _props3.actions,
          marketInfo = _props3.marketInfo,
          history = _props3.history;

      var filters = history.location.search.substring(1);

      actions.getInventory((0, _extends3.default)({}, marketInfo, {
        filters: filters,
        pageSize: pageSize,
        pageNumber: pageNumber,
        sortField: sortField
      }));
    }
  });
};

exports.default = LinkedVehicleSearchResults;