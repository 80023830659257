'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _QuickContact = require('./QuickContact');

var _QuickContact2 = _interopRequireDefault(_QuickContact);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _QuickContact2.default;