'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Summary: Formats emissions combined info.
 * @param {string} nedcValue
 * @param {string} wltdValue
 * @return string combined text.
 */
var formatCombinedValues = exports.formatCombinedValues = function formatCombinedValues(nedcValue, wltdValue) {
  var nedcCombinedText = nedcValue && '' + nedcValue;
  var wltpCombinedText = wltdValue && '' + wltdValue;

  return nedcCombinedText && wltpCombinedText ? [nedcCombinedText, wltpCombinedText].join(', ') : nedcCombinedText || wltpCombinedText;
};