'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FormFieldContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FormField__FormFieldContainer',
  componentId: 'sc-memd1a-0'
})(['position:relative;margin:0 0 50px;width:100%;&:focus{outline:none;}', ' ', ''], function (_ref) {
  var orientation = _ref.orientation;
  return orientation === 'horizontal' ? 'display: inline-flex;' : 'display: flex; flex-direction: column;';
}, function (_ref2) {
  var styleOveride = _ref2.styleOveride;
  return styleOveride && styleOveride();
});

function FormField(_ref3) {
  var children = _ref3.children,
      removeMargin = _ref3.removeMargin,
      orientation = _ref3.orientation,
      styleOverride = _ref3.styleOverride;

  return _react2.default.createElement(
    FormFieldContainer,
    {
      removeMargin: removeMargin,
      orientation: orientation,
      styleOveride: styleOverride
    },
    children
  );
}