'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n  '], ['\n    flex-direction: column;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 45px;\n  '], ['\n    margin-bottom: 45px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    flex:1;\n'], ['\n    flex:1;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('.');

var _vehicle = require('../../../helpers/vehicle');

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _dates = require('../../../shared/localisation/dates');

var _numbers = require('../../../shared/localisation/numbers');

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AppShared = require('../../../types/AppShared');

var _vehicleTypes = require('../../../types/Bentley/vehicleTypes');

var _mathHelper = require('../../../utilities/mathHelper');

var _CompareButton = require('../CompareButton');

var _CompareButton2 = _interopRequireDefault(_CompareButton);

var _Form = require('../Form');

var _ImgCollage = require('../ImgCollage');

var _ImgCollage2 = _interopRequireDefault(_ImgCollage);

var _ShortlistButton = require('../ShortlistButton');

var _ShortlistButton2 = _interopRequireDefault(_ShortlistButton);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__Container',
  componentId: 'sc-xfjs2o-0'
})(['width:100%;margin-bottom:20px;display:flex;box-sizing:border-box;background-color:#ffffff;filter:drop-shadow(4px 4px 8px rgba(0,0,0,0.25));', ';', ';'], _theme2.default.max.xxl(_templateObject), _theme2.default.min.large(_templateObject2));

var ImageSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__ImageSection',
  componentId: 'sc-xfjs2o-1'
})(['width:55%;', ';'], _theme2.default.max.xxl(_templateObject3));

var VehicleInfoSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleInfoSection',
  componentId: 'sc-xfjs2o-2'
})(['display:flex;flex-direction:column;flex:1;padding:20px;']);

var VehicleSpecs = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleSpecs',
  componentId: 'sc-xfjs2o-3'
})(['display:flex;justify-content:space-between;gap:8px;width:100%;border-bottom:0.5px solid rgba(34,34,34,0.25);']);

var BaseVehicleDetailRowStyle = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__BaseVehicleDetailRowStyle',
  componentId: 'sc-xfjs2o-4'
})(['width:100%;border-top:0.5px solid rgba(34,34,34,0.25);padding:6px 0;font-family:Bentley-light;font-weight:300;font-size:12px;line-height:16px;text-transform:none;']);

var VehicleDetailRowStyle = (0, _styledComponents2.default)(BaseVehicleDetailRowStyle).withConfig({
  displayName: 'VehicleItem__VehicleDetailRowStyle',
  componentId: 'sc-xfjs2o-5'
})(['display:grid;grid-template-columns:35% 35% 30%;']);

var VehicleDetailItem = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleDetailItem',
  componentId: 'sc-xfjs2o-6'
})(['display:flex;', ';'], _theme2.default.max.large(_templateObject4));

var CompareShortlistContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__CompareShortlistContainer',
  componentId: 'sc-xfjs2o-7'
})(['display:flex;gap:5px;flex-direction:flex-end;margin-left:10px;']);

var VehicleDetailRowWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleDetailRowWrapper',
  componentId: 'sc-xfjs2o-8'
})(['width:100%;border-top:', ';display:flex;flex-wrap:wrap;padding:6px 0;font-family:Bentley-light;font-weight:300;gap:4px;'], function (_ref) {
  var removeBorder = _ref.removeBorder;
  return removeBorder ? '0' : '0.5px solid rgba(34, 34, 34, 0.25)';
});

var VehicleColorRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleColorRow',
  componentId: 'sc-xfjs2o-9'
})(['display:flex;flex-direction:column;']);

var VehicleColorDetailRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleColorDetailRow',
  componentId: 'sc-xfjs2o-10'
})(['display:flex;gap:2px;flex:1;align-items:center;font-family:\'Bentley-light\';font-style:normal;font-weight:300;font-size:14px;line-height:16px;align-items:flex-start;color:#333333;']);

var FooterContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__FooterContainer',
  componentId: 'sc-xfjs2o-11'
})(['display:flex;gap:8px;justify-content:space-between;align-items:center;margin-top:6px;']);

var ActionSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__ActionSection',
  componentId: 'sc-xfjs2o-12'
})(['display:flex;flex-direction:column;flex:1;justify-content:flex-end;']);

var ActionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__ActionContainer',
  componentId: 'sc-xfjs2o-13'
})(['width:100%;display:flex;gap:9px;margin:8px 0;', ''], _theme2.default.max.medium(_templateObject));

var ActionItem = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__ActionItem',
  componentId: 'sc-xfjs2o-14'
})(['width:50%;', ''], _theme2.default.max.medium(_templateObject3));

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__IconWrapper',
  componentId: 'sc-xfjs2o-15'
})(['display:flex;align-items:center;']);

var VehicleDetailRow = function VehicleDetailRow(_ref2) {
  var data = _ref2.data,
      translations = _ref2.translations;
  return _react2.default.createElement(
    VehicleDetailRowStyle,
    null,
    data.filter(Boolean).map(function (item, index) {
      return _react2.default.createElement(
        VehicleDetailItem,
        { key: index },
        item === 'RHD' ? translations.rightHandDrive : item === 'LHD' ? translations.leftHandDrive : item
      );
    })
  );
};

var primaryButtonStyle = function primaryButtonStyle() {
  return 'width: 100%;';
};

var buildVehicleDetailString = function buildVehicleDetailString(exterior, interior, secondaryInterior, translations) {
  var details = [];

  if (exterior) {
    details.push((0, _translateFromTemplate.translateFromTemplate)('finishedInExterior', {
      EXTERIOR: exterior
    }, translations));
  }
  if (interior) {
    var interiorText = secondaryInterior && interior !== secondaryInterior ? (0, _translateFromTemplate.translateFromTemplate)('withInteriorAndSecondaryInterior', {
      INTERIOR: interior,
      SECONDARY_INTERIOR: secondaryInterior
    }, translations) : (0, _translateFromTemplate.translateFromTemplate)('withInterior', {
      INTERIOR: interior
    }, translations);
    details.push(interiorText);
  }

  return details.length > 0 ? details.join(' ') + '' : '-';
};

var VehicleItem = function VehicleItem(_ref3) {
  var vehicle = _ref3.vehicle,
      onMoreInfoClick = _ref3.onMoreInfoClick,
      onEnquireClick = _ref3.onEnquireClick,
      translations = _ref3.translations,
      locale = _ref3.locale,
      config = _ref3.config,
      shared = _ref3.shared;

  var sessionCurrency = shared.sessionPreferences.currency;
  var currency = !!sessionCurrency ? vehicle.price.currency : vehicle.price.originalCurrency || vehicle.price.currency;
  var priceValue = !!sessionCurrency ? vehicle.price.value : vehicle.price.originalValue || vehicle.price.value;

  var mobile = (0, _useCheckIsMobileScreen2.default)('medium');
  var tablet = (0, _useCheckIsMobileScreen2.default)('large');

  var financeCellTextNoDigits = function financeCellTextNoDigits(priceEntry, curCurrency) {
    return priceEntry > 0 && (0, _numbers.localiseCurrency)(priceEntry, locale, curCurrency, 0);
  };

  // Using fr-CH will format the date to use dots as a separator; you can use whichever locale
  // you want to get the desired output; in this case we want dots
  var registrationDate = vehicle.registrationDate ? (0, _dates.localeFormattedDate)(vehicle.registrationDate, 'fr-CH', {
    month: '2-digit',
    year: 'numeric'
  }) : '';

  var localisedRetailPrice = (0, _react.useMemo)(function () {
    return financeCellTextNoDigits(Number(priceValue || 0), currency);
  }, [vehicle, currency, financeCellTextNoDigits]);

  var localisedPriceLessTaxes = (0, _react.useMemo)(function () {
    return financeCellTextNoDigits(Number(vehicle.priceLessTaxes || 0), currency);
  }, [vehicle, currency, financeCellTextNoDigits]);

  var showEmissions = [].concat((0, _toConsumableArray3.default)((0, _values2.default)(vehicle.emissions)), (0, _toConsumableArray3.default)((0, _values2.default)(vehicle.consumption))).some(function (e) {
    return !!e;
  });

  var priceConverted = vehicle.price.currency === sessionCurrency;

  var vehicleRowData = ['' + (config.showModelYear && vehicle.modelYear ? vehicle.modelYear : ''), isNaN(Number(vehicle.engineCapacity)) ? vehicle.engineCapacity : (0, _numbers.localiseNumber)(Number(vehicle.engineCapacity), locale) + ' cc', vehicle.techspecs && vehicle.techspecs.powerBHP ? config.showPs ? (0, _vehicle.dashIfNull)((0, _mathHelper.bhpToPsConverter)(vehicle.techspecs.powerBHP)) + ' ' + translations.ps : (0, _vehicle.dashIfNull)(vehicle.techspecs.powerBHP) + ' BHP' : '', vehicle.handDrive].filter(Boolean);
  return _react2.default.createElement(
    Container,
    { 'data-cy': 'search-result-vehicle-item' },
    _react2.default.createElement(
      ImageSection,
      null,
      _react2.default.createElement(_ImgCollage2.default, {
        onClick: function onClick(e) {
          return onMoreInfoClick(e, vehicle);
        },
        videoOnClick: function videoOnClick(e) {
          return onMoreInfoClick(e, vehicle);
        },
        images: vehicle.images,
        config: config,
        video: vehicle.video,
        placeholder: '',
        imageAltText: vehicle.name
      })
    ),
    _react2.default.createElement(
      VehicleInfoSection,
      null,
      _react2.default.createElement(_.VehicleTitle, {
        vehicleName: vehicle.name,
        logoUrl: mobile ? config.certifiedLogoMobile : config.certifiedLogoDesktop,
        price: localisedRetailPrice || translations.noRetailPrice,
        onClick: function onClick() {
          return onMoreInfoClick('VDP', vehicle);
        },
        priceLessTaxes: localisedPriceLessTaxes,
        approved: vehicle.approved,
        translations: translations,
        priceConverted: priceConverted,
        hidePrice: config.hidePrice,
        hideYear: config.hideYear,
        showSectionSalesTaxDisclaimer: vehicle.sectionSalesTaxDisclaimer === 1
      }),
      _react2.default.createElement(
        VehicleSpecs,
        null,
        _react2.default.createElement(
          'div',
          { style: { flex: 1, display: 'flex', flexDirection: 'column' } },
          _react2.default.createElement(
            VehicleDetailRowWrapper,
            null,
            _react2.default.createElement(
              VehicleColorRow,
              null,
              _react2.default.createElement(
                VehicleColorDetailRow,
                null,
                buildVehicleDetailString(vehicle.exterior, vehicle.interior, vehicle.secondaryInterior, translations)
              )
            )
          ),
          _react2.default.createElement(VehicleDetailRow, {
            translations: translations,
            data: vehicleRowData
          }),
          config.customVehicleTileFields && _react2.default.createElement(VehicleDetailRow, {
            translations: translations,
            data: [vehicle.fuel]
          }),
          !config.customVehicleTileFields && _react2.default.createElement(VehicleDetailRow, {
            translations: translations,
            data: vehicle.retailerRegion !== 'Americas' ? [translations.regLabel + ': ' + registrationDate, vehicle.odometer.display, vehicle.fuel] : [vehicle.odometer.display, vehicle.fuel]
          })
        ),
        !tablet && vehicle.approved && _react2.default.createElement(
          'div',
          { style: { marginLeft: 30, marginTop: 4 } },
          _react2.default.createElement(
            IconWrapper,
            null,
            _react2.default.createElement('img', {
              src: mobile ? config.certifiedLogoMobile : config.certifiedLogoDesktop,
              alt: 'certified-logo'
            })
          )
        )
      ),
      showEmissions && _react2.default.createElement(_.Emissions, {
        emissionsData: vehicle.emissions,
        consumptionsData: vehicle.consumption,
        efficiency: vehicle.energyEfficiencyRating || vehicle.emissions.co2ClassWeightedCombined,
        translations: translations,
        vehicle: vehicle,
        config: config
      }),
      _react2.default.createElement(
        ActionSection,
        null,
        _react2.default.createElement(
          VehicleDetailRowWrapper,
          { removeBorder: !showEmissions },
          _react2.default.createElement(
            ActionContainer,
            null,
            _react2.default.createElement(
              ActionItem,
              null,
              _react2.default.createElement(_Form.Button, {
                variant: 'primary',
                text: translations.viewCTA,
                onClick: function onClick(e) {
                  onMoreInfoClick(e, vehicle);
                },
                styleOverride: primaryButtonStyle
              })
            ),
            _react2.default.createElement(
              ActionItem,
              null,
              _react2.default.createElement(_Form.Button, {
                variant: 'primary',
                text: translations.enquireCTA,
                onClick: function onClick(e) {
                  onEnquireClick(e, vehicle);
                },
                styleOverride: primaryButtonStyle
              })
            ),
            !mobile && _react2.default.createElement(
              CompareShortlistContainer,
              null,
              _react2.default.createElement(_CompareButton2.default, {
                vehicle: vehicle,
                iconOff: config.iconCompareOff,
                iconOn: config.iconCompareOn
              }),
              _react2.default.createElement(_ShortlistButton2.default, {
                vehicle: vehicle,
                iconOff: config.iconShortlistOff,
                iconOn: config.iconShortlistOn
              })
            )
          )
        ),
        _react2.default.createElement(
          FooterContainer,
          null,
          _react2.default.createElement(_.DistanceFromRetailer, {
            vehicle: vehicle,
            translations: translations,
            locale: locale,
            hideDistanceFrom: config.hideDistanceFrom
          }),
          _react2.default.createElement(
            CompareShortlistContainer,
            null,
            mobile && _react2.default.createElement(_ShortlistButton2.default, {
              vehicle: vehicle,
              iconOff: config.iconShortlistOff,
              iconOn: config.iconShortlistOn
            })
          )
        )
      )
    )
  );
};

exports.default = VehicleItem;