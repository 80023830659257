'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      justify-content: normal;\n      height: 69px;\n  '], ['\n      justify-content: normal;\n      height: 69px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      width: 100%;\n  '], ['\n      width: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n    width: 100%;\n    position: absolute;\n    left: 0;\n    top: 69px;\n    padding: 15px 0;\n\n    &:focus {\n      display: none;\n    }\n  '], ['\n    display: none;\n    width: 100%;\n    position: absolute;\n    left: 0;\n    top: 69px;\n    padding: 15px 0;\n\n    &:focus {\n      display: none;\n    }\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    cursor: pointer;\n    display: inline-block;\n    float: right;\n    padding: 28px 20px;\n    position: relative;\n    user-select: none;\n  '], ['\n    cursor: pointer;\n    display: inline-block;\n    float: right;\n    padding: 28px 20px;\n    position: relative;\n    user-select: none;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    &:checked {\n      & ~ label span:before {\n        transform: rotate(-45deg);\n        top: 0;\n      }\n      & ~ label span {\n        background: transparent;\n      }\n      & ~ label span:after {\n        transform: rotate(45deg);\n        top: 0;\n      }\n      & ~ ul {\n        display: block;\n      }\n    }\n  '], ['\n    &:checked {\n      & ~ label span:before {\n        transform: rotate(-45deg);\n        top: 0;\n      }\n      & ~ label span {\n        background: transparent;\n      }\n      & ~ label span:after {\n        transform: rotate(45deg);\n        top: 0;\n      }\n      & ~ ul {\n        display: block;\n      }\n    }\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n      ', ';\n  '], ['\n      ', ';\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n      padding: 10px;\n      line-height: 24px;\n    '], ['\n      padding: 10px;\n      line-height: 24px;\n    ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    position: absolute;\n    top: 24px;\n    margin-left: 45px;\n    width: 116px;\n    height: 58px;\n  '], ['\n    position: absolute;\n    top: 24px;\n    margin-left: 45px;\n    width: 116px;\n    height: 58px;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    border-top: solid 1px #D6D6D6;\n    padding-left: 26px;\n    padding-top: 8px;\n\n    select {\n      font-size: 12px;\n      line-height: 16px;\n      text-transform: none;\n    }\n  '], ['\n    border-top: solid 1px #D6D6D6;\n    padding-left: 26px;\n    padding-top: 8px;\n\n    select {\n      font-size: 12px;\n      line-height: 16px;\n      text-transform: none;\n    }\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Form = require('../../../components/Bentley/Form');

var _LanguagePicklist = require('../../../components/Bentley/LanguagePicklist');

var _LanguagePicklist2 = _interopRequireDefault(_LanguagePicklist);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _homePageTypes = require('../../../types/Bentley/homePageTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Header = _styledComponents2.default.header.withConfig({
  displayName: 'BentleyHeader__Header',
  componentId: 'sc-llen4i-0'
})(['background-color:#fff;display:flex;justify-content:center;border-bottom:solid 1px #94c11d;height:101px;', ';'], _theme2.default.max.large(_templateObject));

var Navbar = _styledComponents2.default.nav.withConfig({
  displayName: 'BentleyHeader__Navbar',
  componentId: 'sc-llen4i-1'
})(['width:100%;max-width:1366px;display:flex;align-items:center;justify-content:space-between;', ';'], _theme2.default.max.large(_templateObject2));

var Menu = _styledComponents2.default.ul.withConfig({
  displayName: 'BentleyHeader__Menu',
  componentId: 'sc-llen4i-2'
})(['margin:0;padding:0;z-index:3;list-style:none;overflow:hidden;background-color:#fff;max-height:none;display:flex;', ';'], _theme2.default.max.large(_templateObject3));

var MenuIcon = _styledComponents2.default.label.withConfig({
  displayName: 'BentleyHeader__MenuIcon',
  componentId: 'sc-llen4i-3'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject4));

var MenuBtn = _styledComponents2.default.input.withConfig({
  displayName: 'BentleyHeader__MenuBtn',
  componentId: 'sc-llen4i-4'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject5));

var MenuItem = _styledComponents2.default.li.attrs(function (props) {
  return {
    onlyMobile: props.onlyMobile
  };
}).withConfig({
  displayName: 'BentleyHeader__MenuItem',
  componentId: 'sc-llen4i-5'
})(['display:flex;', ';> span{display:block;padding:10px 30px;text-decoration:none;color:#5b5b5b;text-transform:uppercase;font-size:14px;cursor:pointer;line-height:19px;font-family:Bentley-Light;', ';}'], _theme2.default.min.large(_templateObject6, function (props) {
  return props.onlyMobile && 'display: none;';
}), _theme2.default.min.large(_templateObject7));

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'BentleyHeader__Logo',
  componentId: 'sc-llen4i-6'
})(['cursor:pointer;width:84px;height:42px;margin-left:20px;', ';'], _theme2.default.min.large(_templateObject8));

var NavIcon = _styledComponents2.default.span.withConfig({
  displayName: 'BentleyHeader__NavIcon',
  componentId: 'sc-llen4i-7'
})(['background:#333;display:block;height:2px;position:relative;transition:background 0.2s ease-out;width:18px;&:before,&:after{background:#333;content:\'\';display:block;height:100%;position:absolute;transition:all 0.2s ease-out;width:100%;}&:before{top:5px;}&:after{top:-5px;}']);

var SearchPreOwnedLi = (0, _styledComponents2.default)(MenuItem).withConfig({
  displayName: 'BentleyHeader__SearchPreOwnedLi',
  componentId: 'sc-llen4i-8'
})(['padding-left:40px;', ';'], _theme2.default.max.large(_templateObject9));

var LanguageLi = (0, _styledComponents2.default)(MenuItem).withConfig({
  displayName: 'BentleyHeader__LanguageLi',
  componentId: 'sc-llen4i-9'
})(['padding-left:70px;margin-top:5px;select{color:#333333;}', ';'], _theme2.default.max.large(_templateObject10));

var BentleyHeader = function BentleyHeader(props) {
  var config = props.config,
      links = props.links,
      navigate = props.navigate;

  var menuIconRef = (0, _react.useRef)(null);
  return _react2.default.createElement(
    Header,
    null,
    _react2.default.createElement(
      Navbar,
      null,
      _react2.default.createElement(Logo, { src: config.brandLogoUrl, onClick: function onClick() {
          return navigate('/');
        } }),
      _react2.default.createElement(MenuBtn, { type: 'checkbox', id: 'menu-btn' }),
      _react2.default.createElement('label', { ref: menuIconRef }),
      _react2.default.createElement(
        MenuIcon,
        { htmlFor: 'menu-btn', ref: menuIconRef },
        _react2.default.createElement(NavIcon, null)
      ),
      _react2.default.createElement(
        Menu,
        null,
        links.map(function (link) {
          return _react2.default.createElement(
            MenuItem,
            {
              key: link.navId,
              onlyMobile: link.onlyMobile,
              onClick: function onClick() {
                if (menuIconRef.current !== null) {
                  // @ts-ignore
                  menuIconRef.current.click();
                }
              }
            },
            _react2.default.createElement(
              'span',
              {
                onClick: function onClick() {
                  navigate(link.link);
                }
              },
              link.label
            )
          );
        }),
        _react2.default.createElement(
          SearchPreOwnedLi,
          null,
          _react2.default.createElement(_Form.Button, {
            variant: 'primary',
            'data-cy': 'search-preowned-button',
            onClick: function onClick() {
              return navigate('/search');
            },
            text: config.translations.headerCta,
            styleOverride: function styleOverride() {
              return 'font-family: Bentley-SemiBold; letter-spacing: 1.2px;';
            }
          })
        ),
        _react2.default.createElement(
          LanguageLi,
          null,
          _react2.default.createElement(_LanguagePicklist2.default, null)
        )
      )
    )
  );
};

exports.default = BentleyHeader;