'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Plus = require('./JaguarIcons/Global/Plus');

var _Plus2 = _interopRequireDefault(_Plus);

var _Minus = require('./JaguarIcons/Global/Minus');

var _Minus2 = _interopRequireDefault(_Minus);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable react/prop-types */
var AccordionBar = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccordion__AccordionBar',
  componentId: 'sc-1dakfhr-0'
})(['width:100%;border-top:', '  &:last-child{border-bottom:', '}'], function (_ref) {
  var removeBorders = _ref.removeBorders;
  return removeBorders ? '1px solid transparent;' : '1px solid #d8d8d8;';
}, function (_ref2) {
  var removeBorders = _ref2.removeBorders;
  return removeBorders ? '1px solid transparent;' : '1px solid #d8d8d8;';
});
var AccordionBarContent = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccordion__AccordionBarContent',
  componentId: 'sc-1dakfhr-1'
})(['display:flex;justify-content:space-between;align-items:center;font-size:16px;font-weight:600;color:#444444;padding:', ' text-transform:uppercase;'], function (_ref3) {
  var disableGutters = _ref3.disableGutters;
  return disableGutters ? '0;' : '20px 0;';
});

var IconButton = _styledComponents2.default.button.withConfig({
  displayName: 'IconButton'
}).withConfig({
  displayName: 'VdpAccordion__IconButton',
  componentId: 'sc-1dakfhr-2'
})(['background:none;border:none;cursor:pointer;&:focus{outline:none;}']);

var VdpAccordion = function (_Component) {
  (0, _inherits3.default)(VdpAccordion, _Component);

  function VdpAccordion(props) {
    (0, _classCallCheck3.default)(this, VdpAccordion);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VdpAccordion.__proto__ || (0, _getPrototypeOf2.default)(VdpAccordion)).call(this, props));

    Object.defineProperty(_this, 'onClickHandler', {
      enumerable: true,
      writable: true,
      value: function value() {
        var open = _this.state.open;

        _this.setState({ open: !open });
      }
    });
    Object.defineProperty(_this, 'renderIcon', {
      enumerable: true,
      writable: true,
      value: function value() {
        var open = _this.state.open;

        return open ? _react2.default.createElement(
          IconButton,
          { onClick: _this.onClickHandler },
          _react2.default.createElement(_Minus2.default, { width: '2em', height: '2em', colour: '#858585' })
        ) : _react2.default.createElement(
          IconButton,
          { onClick: _this.onClickHandler },
          _react2.default.createElement(_Plus2.default, { width: '2em', height: '2em', colour: '#858585' })
        );
      }
    });


    _this.state = {
      open: props.openByDefault || false
    };
    return _this;
  }

  (0, _createClass3.default)(VdpAccordion, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          title = _props.title,
          children = _props.children,
          removeBorders = _props.removeBorders,
          disableGutters = _props.disableGutters;
      var open = this.state.open;

      return _react2.default.createElement(
        AccordionBar,
        { removeBorders: removeBorders },
        _react2.default.createElement(
          AccordionBarContent,
          { disableGutters: disableGutters },
          _react2.default.isValidElement(title) ? title : _react2.default.createElement(
            'span',
            null,
            title
          ),
          this.renderIcon()
        ),
        open ? children : null
      );
    }
  }]);
  return VdpAccordion;
}(_react.Component);

exports.default = VdpAccordion;