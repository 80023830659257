'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n    padding: 0 5%;\n  '], ['\n  ', ';\n    padding: 0 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n  '], ['\n    flex-direction: row;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n      width: 48%;\n  '], ['\n      width: 48%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 48%;\n  '], ['\n    width: 48%;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 10px 0;\n  '], ['\n    padding: 10px 0;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: center;\n  '], ['\n    justify-content: center;\n  ']);
/* eslint-env browser */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormFields = require('../../../components/FormFields');

var _PersonalDataConsent = require('../../../components/PersonalDataConsent');

var _PersonalDataConsent2 = _interopRequireDefault(_PersonalDataConsent);

var _ConfirmationWindow = require('../../../components/ConfirmationWindow');

var _ConfirmationWindow2 = _interopRequireDefault(_ConfirmationWindow);

var _Global = require('../../../components/Global');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

require('../../../modules/Enquiry/reducer');

var _validation = require('../../../helpers/validation');

var _formTitleOptions = require('../../../helpers/formTitleOptions');

var _countryOptions = require('../../../helpers/countryOptions');

var _LabelledCheckBox = require('../../../components/LabelledCheckBox');

var _LabelledCheckBox2 = _interopRequireDefault(_LabelledCheckBox);

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateTwo__Container',
  componentId: 'sc-1nwujsl-0'
})(['display:flex;justify-content:center;']);

var EnquiryFormContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateTwo__EnquiryFormContainer',
  componentId: 'sc-1nwujsl-1'
})(['', ';width:40%;', ';height:auto;margin:40px 0;'], _theme2.default.max.large(_templateObject, function (_ref) {
  var width = _ref.width;
  return 'width: ' + (width ? width + 'px' : '90%');
}), function (_ref2) {
  var width = _ref2.width;
  return 'width: ' + (width ? width + 'px' : '40%');
});

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateTwo__Row',
  componentId: 'sc-1nwujsl-2'
})(['display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start;', ';'], _theme2.default.min.large(_templateObject2));

var MultiFieldRow = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateTwo__MultiFieldRow',
  componentId: 'sc-1nwujsl-3'
})(['display:flex;justify-content:space-between;width:100%;flex-direction:row;> div{', ';}', ';'], _theme2.default.max.large(_templateObject3), _theme2.default.min.large(_templateObject4));

var LineBreak = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateTwo__LineBreak',
  componentId: 'sc-1nwujsl-4'
})(['border-bottom:1px solid #dedede;margin-bottom:30px;']);

var ActionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateTwo__ActionsContainer',
  componentId: 'sc-1nwujsl-5'
})(['', ';display:flex;width:100%;justify-content:space-between;'], _theme2.default.max.large(_templateObject5));

var ActionButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateTwo__ActionButtonWrapper',
  componentId: 'sc-1nwujsl-6'
})(['', ';'], _theme2.default.max.large(_templateObject6));

var Error = _styledComponents2.default.div.withConfig({
  displayName: 'TemplateTwo__Error',
  componentId: 'sc-1nwujsl-7'
})(['width:100%;display:flex;justify-content:flex-end;color:#9e1b32;margin-top:10px;', ';'], _theme2.default.max.large(_templateObject7));

var EnquiryForm = function (_Component) {
  (0, _inherits3.default)(EnquiryForm, _Component);

  function EnquiryForm(props) {
    (0, _classCallCheck3.default)(this, EnquiryForm);

    var _this = (0, _possibleConstructorReturn3.default)(this, (EnquiryForm.__proto__ || (0, _getPrototypeOf2.default)(EnquiryForm)).call(this, props));

    Object.defineProperty(_this, 'onSelectChange', {
      enumerable: true,
      writable: true,
      value: function value(_ref3) {
        var _this$setState;

        var keyValue = _ref3.keyValue,
            _value = _ref3.value,
            label = _ref3.label;
        var errors = _this.state.errors;

        var error = _this.validate(keyValue, { value: _value, label: label });
        _this.setState((_this$setState = {}, (0, _defineProperty3.default)(_this$setState, keyValue, { label: label, value: _value }), (0, _defineProperty3.default)(_this$setState, 'errors', (0, _extends3.default)({}, errors, error)), _this$setState));
      }
    });
    Object.defineProperty(_this, 'onInputChange', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value2) {
        var _this$setState2;

        var errors = _this.state.errors;

        var error = _this.validate(formKey, _value2);
        _this.setState((_this$setState2 = {}, (0, _defineProperty3.default)(_this$setState2, formKey, _value2), (0, _defineProperty3.default)(_this$setState2, 'errors', (0, _extends3.default)({}, errors, error)), _this$setState2));
      }
    });
    Object.defineProperty(_this, 'onRadioSelect', {
      enumerable: true,
      writable: true,
      value: function value(event) {
        var errors = _this.state.errors;
        var _event$currentTarget = event.currentTarget,
            name = _event$currentTarget.name,
            value = _event$currentTarget.value;

        var error = _this.validate(name, value);
        _this.setState(function () {
          var _ref4;

          return _ref4 = {}, (0, _defineProperty3.default)(_ref4, name, value), (0, _defineProperty3.default)(_ref4, 'errors', (0, _extends3.default)({}, errors, error)), _ref4;
        });
      }
    });
    Object.defineProperty(_this, 'onSubmitMessage', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            submitMessage = _this$props.submitMessage,
            vehicle = _this$props.vehicle;
        var _this$state = _this.state,
            title = _this$state.title,
            enquiryType = _this$state.enquiryType,
            country = _this$state.country;
        var errors = _this.state.errors;

        var newErrors = (0, _entries2.default)(_this.state).map(function (entry) {
          return _this.validate(entry[0], entry[1]);
        }).filter(function (entry) {
          return entry !== undefined;
        }).reduce(function (acc, currVal) {
          return (0, _extends3.default)({}, acc, currVal);
        }, {});

        if ((0, _values2.default)(newErrors).filter(function (error) {
          return error !== undefined;
        }).length > 0) {
          window.scroll(0, 0);
          _this.setState({ errors: (0, _extends3.default)({}, errors, newErrors) });
          return;
        }
        submitMessage((0, _extends3.default)((0, _extends3.default)({}, vehicle, _this.state, {
          title: title.value,
          enquiryType: enquiryType.value,
          country: country.value
        })));
      }
    });
    Object.defineProperty(_this, 'validate', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value3) {
        var translations = _this.props.config.translations;
        var tradeInVisible = _this.state.tradeInVisible;


        var excludeKeys = ['vehicleBrand', 'vehicleModel'];
        var nonValidatedKeys = ['enquiryType', 'vehicleOfInterest', 'phoneConsent', 'smsConsent', 'emailConsent', 'errors', 'tradeInVisible', 'vehicleVersion', 'customisedServicesConsent', 'marketingConsent', 'address2'];
        var actualValidationKeys = [].concat(nonValidatedKeys);

        if (!tradeInVisible) {
          actualValidationKeys = [].concat((0, _toConsumableArray3.default)(actualValidationKeys), excludeKeys);
        }

        if (actualValidationKeys.includes(formKey)) return undefined;

        var validations = {
          title: _validation.required,
          firstName: _validation.required,
          lastName: _validation.required,
          address1: _validation.required,
          city: _validation.required,
          county: _validation.required,
          postCode: _validation.required,
          country: _validation.required,
          email: _validation.validEmail,
          phone: _validation.required,
          comments: _validation.required,
          accepted: _validation.valueIsTrue
        };

        if (tradeInVisible) {
          validations = (0, _extends3.default)({}, validations, {
            vehicleBrand: _validation.required,
            vehicleModel: _validation.required
          });
        }

        if (formKey === 'title' || formKey === 'country') {
          return (0, _defineProperty3.default)({}, formKey, validations[formKey](_value3.value, translations));
        }
        return (0, _defineProperty3.default)({}, formKey, validations[formKey](_value3, translations));
      }
    });

    _this.state = {
      title: {
        label: props.config.translations.formTitleSelect,
        value: ''
      },
      enquiryType: {
        label: 'General Enquiry',
        value: 'general_enquiry'
      },
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      postCode: '',
      comments: '',
      phoneConsent: false,
      emailConsent: false,
      smsConsent: false,
      marketingConsent: null,
      customisedServicesConsent: null,
      errors: {},
      address1: '',
      address2: '',
      city: '',
      county: '',
      country: {
        label: props.config.translations.formCountrySelect,
        value: ''
      },
      tradeInVisible: false,
      vehicleBrand: '',
      vehicleModel: '',
      vehicleVersion: '',
      accepted: false
    };
    return _this;
  }

  (0, _createClass3.default)(EnquiryForm, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      var _this2 = this;

      var _state = this.state,
          accepted = _state.accepted,
          errors = _state.errors;

      if (prevState.accepted !== accepted) {
        this.setState(function () {
          return {
            errors: (0, _extends3.default)({}, errors, _this2.validate('accepted', accepted))
          };
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          _props$config = _props.config,
          translations = _props$config.translations,
          marketingLink = _props$config.marketingLink,
          showUserCommunication = _props$config.showUserCommunication,
          formWidth = _props$config.formWidth,
          _props$config$enquiry = _props$config.enquiryFormStyles,
          enquiryFormStyles = _props$config$enquiry === undefined ? {} : _props$config$enquiry,
          tacLink = _props$config.tacLink,
          vehicle = _props.vehicle,
          history = _props.history,
          error = _props.error,
          submissionStatus = _props.submissionStatus,
          globalStyling = _props.globalStyling,
          requestPrivacyPolicy = _props.requestPrivacyPolicy;


      var vehicleOfInterest = vehicle ? vehicle.registration + ' ' + vehicle.description : '';

      var _state2 = this.state,
          title = _state2.title,
          firstName = _state2.firstName,
          lastName = _state2.lastName,
          email = _state2.email,
          phone = _state2.phone,
          postCode = _state2.postCode,
          comments = _state2.comments,
          phoneConsent = _state2.phoneConsent,
          emailConsent = _state2.emailConsent,
          smsConsent = _state2.smsConsent,
          address1 = _state2.address1,
          county = _state2.county,
          city = _state2.city,
          country = _state2.country,
          marketingConsent = _state2.marketingConsent,
          customisedServicesConsent = _state2.customisedServicesConsent,
          tradeInVisible = _state2.tradeInVisible,
          vehicleBrand = _state2.vehicleBrand,
          vehicleModel = _state2.vehicleModel,
          vehicleVersion = _state2.vehicleVersion,
          errors = _state2.errors,
          accepted = _state2.accepted;


      var hasError = function hasError(field) {
        return errors[field] && errors[field][0];
      };
      return submissionStatus === 'Successful' ? _react2.default.createElement(
        Container,
        null,
        _react2.default.createElement(_ConfirmationWindow2.default, {
          translations: translations,
          circleTickColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
          globalStyling: globalStyling,
          onButtonClick: history.goBack
        })
      ) : _react2.default.createElement(
        Container,
        null,
        _react2.default.createElement(
          EnquiryFormContainer,
          { width: formWidth },
          _react2.default.createElement(_FormFields.SelectField, {
            type: 'text',
            keyValue: 'title',
            value: title.value,
            onChange: this.onSelectChange,
            label: translations.titleLabel,
            options: (0, _formTitleOptions.titleOptions)(translations),
            error: hasError('title'),
            globalStyling: globalStyling,
            doubleRow: true,
            required: true
          }),
          _react2.default.createElement(
            Row,
            null,
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.firstNameLabel,
              value: firstName,
              onChange: this.onInputChange,
              keyValue: 'firstName',
              error: hasError('firstName'),
              doubleRow: true,
              required: true
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.lastNameLabel,
              value: lastName,
              onChange: this.onInputChange,
              keyValue: 'lastName',
              error: hasError('lastName'),
              doubleRow: true,
              required: true
            })
          ),
          _react2.default.createElement(
            Row,
            null,
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.address1Label,
              value: address1,
              onChange: this.onInputChange,
              keyValue: 'address1',
              error: hasError('address1'),
              doubleRow: true,
              required: true
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.cityLabel,
              value: city,
              onChange: this.onInputChange,
              keyValue: 'city',
              error: hasError('city'),
              doubleRow: true,
              required: true
            })
          ),
          _react2.default.createElement(
            Row,
            null,
            _react2.default.createElement(
              MultiFieldRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.countyLabel,
                value: county,
                onChange: this.onInputChange,
                keyValue: 'county',
                error: hasError('county'),
                doubleRow: true,
                required: true
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.postCodeLabel,
                value: postCode,
                onChange: this.onInputChange,
                keyValue: 'postCode',
                error: hasError('postCode'),
                doubleRow: true,
                required: true
              })
            ),
            _react2.default.createElement(_FormFields.SelectField, {
              type: 'text',
              keyValue: 'country',
              value: country.value,
              onChange: this.onSelectChange,
              label: translations.countryLabel,
              options: (0, _countryOptions.countryOptions)(translations),
              error: hasError('country'),
              globalStyling: globalStyling,
              doubleRow: true,
              required: true
            })
          ),
          _react2.default.createElement(
            Row,
            null,
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.emailLabel,
              value: email,
              onChange: this.onInputChange,
              keyValue: 'email',
              error: hasError('email'),
              doubleRow: true,
              required: true
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.phoneLabel,
              value: phone,
              onChange: this.onInputChange,
              keyValue: 'phone',
              error: hasError('phone'),
              doubleRow: true,
              required: true
            })
          ),
          _react2.default.createElement(_FormFields.InputField, {
            type: 'text',
            label: translations.vehicleOfInterestLabel,
            value: vehicleOfInterest,
            onChange: this.onInputChange,
            keyValue: 'vehicleOfInterest',
            disabled: !!vehicle,
            truncateChars: true
          }),
          _react2.default.createElement(_FormFields.TextAreaField, {
            type: 'text',
            label: translations.commentsLabel,
            value: comments,
            onChange: this.onInputChange,
            keyValue: 'comments',
            error: hasError('comments'),
            required: true
          }),
          _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(
              Row,
              { style: { marginBottom: '16px' } },
              _react2.default.createElement(_LabelledCheckBox2.default, {
                onClick: function onClick() {
                  _this3.setState(function () {
                    return {
                      tradeInVisible: !tradeInVisible,
                      vehicleBrand: '',
                      vehicleModel: '',
                      vehicleVersion: '',
                      errors: (0, _extends3.default)({}, errors, {
                        vehicleBrand: undefined,
                        vehicleModel: undefined,
                        vehicleVersion: undefined
                      })
                    };
                  });
                },
                checked: tradeInVisible,
                label: translations.requestTradeInLabel,
                onColour: '#DFB400',
                globalStyling: globalStyling
              })
            ),
            tradeInVisible && _react2.default.createElement(
              _react.Fragment,
              null,
              _react2.default.createElement(
                Row,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.vehicleBrandLabel,
                  value: vehicleBrand,
                  onChange: this.onInputChange,
                  keyValue: 'vehicleBrand',
                  error: hasError('vehicleBrand'),
                  doubleRow: true,
                  required: true
                }),
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.vehicleModelLabel,
                  value: vehicleModel,
                  onChange: this.onInputChange,
                  keyValue: 'vehicleModel',
                  error: hasError('vehicleModel'),
                  disabled: !vehicleBrand,
                  doubleRow: true,
                  required: true
                })
              ),
              _react2.default.createElement(
                Row,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.vehicleVersionLabel,
                  value: vehicleVersion,
                  onChange: this.onInputChange,
                  keyValue: 'vehicleVersion',
                  disabled: !vehicleBrand,
                  doubleRow: true
                })
              )
            )
          ),
          _react2.default.createElement(
            'div',
            {
              style: { fontSize: '13px', color: '#444444', marginBottom: '30px' }
            },
            translations.asteriskFormRules
          ),
          _react2.default.createElement(LineBreak, null),
          _react2.default.createElement(_PersonalDataConsent2.default, {
            translations: translations,
            onCheckBoxClick: this.onInputChange,
            onRadioSelect: this.onRadioSelect,
            customisedServices: customisedServicesConsent,
            customisedServicesKey: 'customisedServicesConsent',
            customisedServicesError: hasError('customisedServicesConsent'),
            marketing: marketingConsent,
            marketingKey: 'marketingConsent',
            marketingError: hasError('marketingConsent'),
            phoneChecked: phoneConsent,
            phoneKey: 'phoneConsent',
            emailChecked: emailConsent,
            emailKey: 'emailConsent',
            smsChecked: smsConsent,
            smsKey: 'smsConsent',
            onColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
            linkColour: globalStyling.colours.primaryBrandColour,
            marketingLink: marketingLink,
            showUserCommunication: showUserCommunication,
            enquiryFormStyles: enquiryFormStyles,
            globalStyling: globalStyling
          }),
          _react2.default.createElement(LineBreak, null),
          _react2.default.createElement(
            Row,
            null,
            _react2.default.createElement(
              'a',
              { href: tacLink, target: '_blank', rel: 'noopener noreferrer' },
              _react2.default.createElement(
                _Global.Paragraph,
                {
                  styleOverride: function styleOverride() {
                    return '\n                  margin-top: 0;\n                  text-decoration: underline;\n                  cursor: pointer;\n                ';
                  }
                },
                translations.tacLabel
              )
            )
          ),
          _react2.default.createElement(
            Row,
            null,
            _react2.default.createElement(
              _Global.Paragraph,
              {
                tabIndex: 0,
                styleOverride: function styleOverride() {
                  return '\n                margin-top: 0;\n                text-decoration: underline;\n                cursor: pointer;\n                &:focus {\n                  color: #aaaaaa;\n                  outline: none;\n                }\n              ';
                },
                onClick: function onClick() {
                  return requestPrivacyPolicy(vehicle && vehicle.retailerInformation);
                }
              },
              translations.privPolLabel
            )
          ),
          _react2.default.createElement(
            Row,
            { style: { marginBottom: '20px' } },
            _react2.default.createElement(_LabelledCheckBox2.default, {
              onClick: function onClick() {
                _this3.setState(function () {
                  return { accepted: !accepted };
                });
              },
              checked: accepted,
              label: (0, _translateFromTemplate.translateFromTemplate)('tacAccept', {
                DEALER: vehicle && vehicle.retailerInformation && vehicle.retailerInformation.name
              }, translations),
              onColour: '#DFB400',
              globalStyling: globalStyling
            })
          ),
          _react2.default.createElement(
            'div',
            { style: { width: '100%', display: 'block' } },
            hasError('accepted') && _react2.default.createElement(
              Error,
              {
                style: { justifyContent: 'flex-start', marginBottom: '20px' }
              },
              translations.formValidationTermsConditionsAccept
            )
          ),
          _react2.default.createElement(
            ActionsContainer,
            null,
            _react2.default.createElement(
              ActionButtonWrapper,
              null,
              _react2.default.createElement(_Global.Button, {
                applyStyle: 'secondary',
                buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle,
                text: translations.cancelActionButton,
                onClick: history.goBack
              })
            ),
            _react2.default.createElement(
              ActionButtonWrapper,
              null,
              _react2.default.createElement(_Global.Button, {
                applyStyle: 'primary',
                buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
                text: translations.submitMessageActionButton,
                onClick: this.onSubmitMessage
              })
            )
          ),
          error && _react2.default.createElement(
            Error,
            null,
            translations.somethingWentWrong
          )
        )
      );
    }
  }]);
  return EnquiryForm;
}(_react.Component);

exports.default = EnquiryForm;