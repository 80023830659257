'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VehicleFeatures = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n   font-size: 36px;\n    margin: 10px 0 40px;\n  '], ['\n   font-size: 36px;\n    margin: 10px 0 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    column-count: 2;\n  '], ['\n    column-count: 2;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    column-count: 1;\n  '], ['\n    column-count: 1;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _types = require('../types');

var _vehicleTypes = require('../../../../types/Genesis/vehicleTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__Wrapper',
  componentId: 'sc-sv6tum-0'
})(['color:#fff;margin:40px 0;']);

var SectionTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'VehicleFeatures__SectionTitle',
  componentId: 'sc-sv6tum-1'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Light\';font-size:24px;font-weight:normal;margin:10px 0 20px;text-transform:uppercase;', ';'], _Genesis2.default.min.medium(_templateObject));

var EquipmentTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__EquipmentTable',
  componentId: 'sc-sv6tum-2'
})(['column-count:3;column-gap:40px;', ';', ';'], _Genesis2.default.max.medium(_templateObject2), _Genesis2.default.max.small(_templateObject3));

var EquipmentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__EquipmentContainer',
  componentId: 'sc-sv6tum-3'
})(['margin-bottom:18px;break-inside:avoid;']);

var EquipmentTitle = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__EquipmentTitle',
  componentId: 'sc-sv6tum-4'
})(['font-family:\'GenesisSansHeadGlobal-Regular\';font-size:14px;border-bottom:1px solid #aaaba6;text-transform:uppercase;padding-bottom:12px;']);

var Equipment = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__Equipment',
  componentId: 'sc-sv6tum-5'
})(['font-size:14px;font-family:\'GenesisSansTextGlobal-Regular\';padding:12px 0;']);

var VehicleFeatures = function VehicleFeatures(_ref) {
  var vehicle = _ref.vehicle,
      translations = _ref.translations;

  var otherEquipment = (0, _react.useMemo)(function () {
    return vehicle.otherEquipment;
  }, [vehicle]);

  if (otherEquipment !== null) {
    return _react2.default.createElement(
      Wrapper,
      { id: 'Features' },
      _react2.default.createElement(
        SectionTitle,
        null,
        translations.titleFeatures
      ),
      _react2.default.createElement(
        EquipmentTable,
        null,
        otherEquipment.map(function (equipment, idx) {
          return _react2.default.createElement(
            EquipmentContainer,
            { key: idx },
            _react2.default.createElement(
              EquipmentTitle,
              null,
              translations[equipment.category] || equipment.category.replaceAll('_', ' ')
            ),
            equipment.equipment.map(function (e, index) {
              return _react2.default.createElement(
                Equipment,
                { key: index },
                e
              );
            })
          );
        })
      )
    );
  } else {
    return null;
  }
};

exports.VehicleFeatures = VehicleFeatures;