'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _isEmpty = require('ramda/src/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _router = require('../../../actions/router');

var _sort = require('../../../helpers/sort');

var _vehicle = require('../../../helpers/vehicle');

var _shortlist = require('../../../shared/shortlist');

var _AppShared = require('../../../types/AppShared');

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

var _VehicleCard = require('../VehicleCard');

var _NoResults = require('../VehicleSearchResults/NoResults');

var _NoResults2 = _interopRequireDefault(_NoResults);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ResultsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Index__ResultsContainer',
  componentId: 'sc-rvu90w-0'
})(['margin:0 auto;max-width:1200px;width:100%;']);

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Index__Wrapper',
  componentId: 'sc-rvu90w-1'
})(['display:flex;flex-direction:row;justify-content:center;width:100%;']);

var EmptyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Index__EmptyContainer',
  componentId: 'sc-rvu90w-2'
})(['align-items:center;display:flex;flex-direction:column;justify-content:center;padding:0px;']);

var NoResultsImgMessage = (0, _styledComponents2.default)(_NoResults2.default).withConfig({
  displayName: 'Index__NoResultsImgMessage',
  componentId: 'sc-rvu90w-3'
})(['&&{margin-bottom:10px;}']);

var Shortlist = function Shortlist(_ref) {
  var config = _ref.config,
      translations = _ref.translations,
      vehicles = _ref.vehicles,
      locale = _ref.locale,
      searchSort = _ref.searchSort,
      shared = _ref.shared;

  var dispatch = (0, _reactRedux.useDispatch)();

  var sortedList = (0, _react.useMemo)(function () {
    return (0, _sort.sortVehicles)(vehicles || [], searchSort.value);
  }, [vehicles, _sort.sortVehicles, searchSort]);

  var onMoreInfoClick = (0, _react.useCallback)(function (_, vehicle) {
    var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, locale);
    dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
  }, [_vehicle.vanityUrlFormatter, config, locale]);

  var onEnquireClick = function onEnquireClick(_, vehicle) {
    dispatch(_router.actions.navigate('/enquiry/' + vehicle.id));
  };

  var noResults = !sortedList.length;

  (0, _react.useEffect)(function () {
    if (shared.sessionPreferences.currency && (0, _keys2.default)(shared.currencyConversion.exchangeRates).length > 0 && shared.currencyConversion.exchangeRates[shared.sessionPreferences.currency]) {
      dispatch(_shortlist.actions.updateVehiclePrices({
        currency: shared.sessionPreferences.currency,
        exchangeRates: shared.currencyConversion.exchangeRates
      }));
    }
  }, [shared.sessionPreferences.currency, shared.currencyConversion.exchangeRates]);

  (0, _react.useEffect)(function () {
    // if exchange rates have been deleted, reset the prices
    if (shared.sessionPreferences.currency === '' && (0, _isEmpty2.default)(shared.currencyConversion.exchangeRates)) {
      dispatch(_shortlist.actions.getVehicles());
    }
  }, [shared.currencyConversion.exchangeRates]);

  return _react2.default.createElement(
    Wrapper,
    null,
    !noResults && _react2.default.createElement(
      ResultsContainer,
      null,
      sortedList.map(function (vehicle) {
        return _react2.default.createElement(_VehicleCard.VehicleItem, {
          config: config,
          key: vehicle.id,
          translations: translations,
          onMoreInfoClick: onMoreInfoClick,
          onEnquireClick: onEnquireClick,
          locale: locale,
          vehicle: vehicle,
          shared: shared,
          handleZeroPriceAsText: true
        });
      })
    ),
    noResults && _react2.default.createElement(
      EmptyContainer,
      null,
      _react2.default.createElement(NoResultsImgMessage, {
        text: translations.noVehicleText,
        iconUrl: config.noVehicleIconUrl
      })
    )
  );
};

exports.default = Shortlist;