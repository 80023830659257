'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n       padding: 26px;\n  '], ['\n       padding: 26px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _InputField = require('./InputField');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Input = _styledComponents2.default.textarea.withConfig({
  displayName: 'TextAreaField__Input',
  componentId: 'sc-larjzq-0'
})(['font-size:16px;padding:15px 10px;display:block;width:100%;height:160px;background-color:#1b0d2e;color:#fff;font-family:\'RivieraNights-Light\';letter-spacing:0.5;border:none;box-sizing:border-box;border-bottom:2px solid rgb(103,103,118);', ' &:focus{outline:none;}::placeholder{color:#fff;font-family:\'RivieraNights-Light\';font-size:16px;letter-spacing:0.5;}resize:none;'], _RollsRoyce2.default.min.medium(_templateObject));

var Error = _styledComponents2.default.div.withConfig({
  displayName: 'TextAreaField__Error',
  componentId: 'sc-larjzq-1'
})(['position:absolute;top:30px;color:red;font-size:11px;']);

var TextAreaField = function TextAreaField(_ref) {
  var id = _ref.id,
      value = _ref.value,
      _ref$onChange = _ref.onChange,
      onChange = _ref$onChange === undefined ? function () {} : _ref$onChange,
      _ref$error = _ref.error,
      error = _ref$error === undefined ? '' : _ref$error,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === undefined ? false : _ref$disabled,
      translations = _ref.translations,
      _ref$validate = _ref.validate,
      validate = _ref$validate === undefined ? false : _ref$validate,
      _ref$readOnly = _ref.readOnly,
      readOnly = _ref$readOnly === undefined ? false : _ref$readOnly,
      placeholder = _ref.placeholder,
      rows = _ref.rows;

  return _react2.default.createElement(
    _FormField2.default,
    null,
    _react2.default.createElement(Input, {
      value: value,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (event) {
        onChange({
          id: id,
          value: event.target.value,
          translations: translations,
          validate: validate,
          event: event
        });
      }),
      disabled: disabled,
      readOnly: readOnly,
      rows: rows,
      placeholder: placeholder
    }),
    error && _react2.default.createElement(
      Error,
      null,
      error
    )
  );
};

exports.default = TextAreaField;