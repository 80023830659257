'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    border: none;\n    margin: 0;\n  '], ['\n    width: 100%;\n    border: none;\n    margin: 0;\n  ']);

exports.default = EnquiryFormConfirmation;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Form = require('./Form');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryFormConfirmation__Container',
  componentId: 'sc-1ylfh6g-0'
})(['width:40%;height:auto;margin:5% 30%;border:1px solid #dedede;padding:30px;', ';'], _theme2.default.max.large(_templateObject));

var Header = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryFormConfirmation__Header',
  componentId: 'sc-1ylfh6g-1'
})(['text-align:center;font-size:20px;color:#000;font-weight:600;line-height:24px;margin-bottom:10px;']);
var Content = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryFormConfirmation__Content',
  componentId: 'sc-1ylfh6g-2'
})(['color:#444444;text-align:center;font-size:16px;line-height:25px;width:100%;white-space:pre-line;margin:0 auto;']);
var ActionButton = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryFormConfirmation__ActionButton',
  componentId: 'sc-1ylfh6g-3'
})(['display:flex;width:100%;justify-content:center;margin-top:30px;']);

function EnquiryFormConfirmation(_ref) {
  var translations = _ref.translations,
      onGoBack = _ref.onGoBack;

  (0, _react.useEffect)(function () {
    window.scroll(0, 0);
  }, []);

  return _react2.default.createElement(
    Container,
    { id: 'enquiry-submitted' },
    _react2.default.createElement(
      Header,
      null,
      translations.confirmationHeaderText
    ),
    _react2.default.createElement(
      Content,
      null,
      translations.confirmationContentText
    ),
    _react2.default.createElement(
      ActionButton,
      null,
      _react2.default.createElement(_Form.PrimaryButton, {
        'data-cy': 'confirmation-button',
        onClick: onGoBack,
        text: translations.confirmationGoBackLabel
      })
    )
  );
}