'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StickyWrapperComponent = _styledComponents2.default.div.withConfig({
  displayName: 'StickyWrapper__StickyWrapperComponent',
  componentId: 'sc-lqclhp-0'
})(['display:flex;width:100%;position:', ';z-index:100;', ' ', ';'], function (_ref) {
  var position = _ref.position;
  return position;
}, function (_ref2) {
  var layout = _ref2.layout;
  return layout === 'bottom' && (0, _styledComponents.css)(['bottom:0;left:0;']);
}, function (_ref3) {
  var layout = _ref3.layout;
  return layout === 'top' && (0, _styledComponents.css)(['top:1px;']);
});

var StickyWrapper = function StickyWrapper(_ref4) {
  var layout = _ref4.layout,
      children = _ref4.children,
      _ref4$position = _ref4.position,
      position = _ref4$position === undefined ? 'fixed' : _ref4$position;
  return _react2.default.createElement(
    StickyWrapperComponent,
    { position: position, layout: layout },
    children
  );
};

exports.default = StickyWrapper;