'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpSlideshowThumbnail__ImageContainer',
  componentId: 'sc-5wkm3s-0'
})(['height:100%;position:relative;white-space:nowrap;display:inline-block;box-sizing:border-box;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VdpSlideshowThumbnail__Image',
  componentId: 'sc-5wkm3s-1'
})(['', ';margin:0 1px;cursor:pointer;opacity:', ';'], function (_ref) {
  var height = _ref.height;
  return height && 'height: ' + height + 'px';
}, function (_ref2) {
  var selected = _ref2.selected;
  return selected ? 1.0 : 0.4;
});

exports.default = function (_ref3) {
  var height = _ref3.height,
      imageUrls = _ref3.imageUrls,
      onImageClick = _ref3.onImageClick,
      selectedImage = _ref3.selectedImage;
  return _react2.default.createElement(
    ImageContainer,
    null,
    imageUrls.map(function (image, idx) {
      return _react2.default.createElement(Image, {
        key: image,
        src: image,
        height: height,
        onClick: function onClick() {
          return onImageClick(idx);
        },
        selected: selectedImage === idx
      });
    })
  );
};