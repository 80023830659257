'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n    width: 100%;\n    text-align: center;\n    padding: 40px 0 0 0;\n  '], ['\n    margin: 0;\n    width: 100%;\n    text-align: center;\n    padding: 40px 0 0 0;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n    width: 100%;\n    padding: 40px 0;\n  '], ['\n    margin: 0;\n    width: 100%;\n    padding: 40px 0;\n  ']);

exports.default = MyAccountLanding;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _MyAccountHeading = require('./MyAccountHeading');

var _MyAccountHeading2 = _interopRequireDefault(_MyAccountHeading);

var _MyAccountContent = require('./MyAccountContent');

var _MyAccountContent2 = _interopRequireDefault(_MyAccountContent);

var _TestDrive = require('../JaguarIcons/Global/TestDrive');

var _TestDrive2 = _interopRequireDefault(_TestDrive);

var _Budget = require('../JaguarIcons/Global/Budget');

var _Budget2 = _interopRequireDefault(_Budget);

var _Criteria = require('../JaguarIcons/Global/Criteria');

var _Criteria2 = _interopRequireDefault(_Criteria);

var _User = require('../JaguarIcons/Global/User');

var _User2 = _interopRequireDefault(_User);

var _Heart = require('../JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountLanding__Content',
  componentId: 'sc-16ov5wu-0'
})(['display:flex;min-height:450px;', ';'], _theme2.default.max.large(_templateObject));
var GreetingContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountLanding__GreetingContainer',
  componentId: 'sc-16ov5wu-1'
})(['width:50%;display:flex;justify-content:center;flex-direction:column;height:100%;margin:auto;padding-right:135px;', ';'], _theme2.default.max.large(_templateObject2));

var MenuContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountLanding__MenuContainer',
  componentId: 'sc-16ov5wu-2'
})(['display:flex;justify-content:center;flex-direction:column;align-items:center;width:50%;height:100%;margin:auto;padding-left:135px;', ';'], _theme2.default.max.large(_templateObject3));
var MenuItem = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountLanding__MenuItem',
  componentId: 'sc-16ov5wu-3'
})(['width:100%;height:55px;display:flex;align-items:center;cursor:pointer;border-top:1px solid #d8d8d8;&:last-child{border-bottom:1px solid #d8d8d8;}']);
var MenuItemText = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountLanding__MenuItemText',
  componentId: 'sc-16ov5wu-4'
})(['margin-left:20px;']);

var Menu = function Menu(_ref) {
  var translations = _ref.translations,
      changeAccountView = _ref.changeAccountView,
      signOut = _ref.signOut,
      onShortListClick = _ref.onShortListClick,
      featureFlags = _ref.featureFlags;
  return _react2.default.createElement(
    MenuContainer,
    null,
    _react2.default.createElement(
      MenuItem,
      { onClick: changeAccountView('Details') },
      _react2.default.createElement(_Criteria2.default, { width: '1.5em', height: '1.5em', colour: '#7e7e7e' }),
      _react2.default.createElement(
        MenuItemText,
        null,
        translations.myAccountDetailsLabel
      )
    ),
    _react2.default.createElement(
      MenuItem,
      { onClick: changeAccountView('Deals') },
      _react2.default.createElement(_Budget2.default, { width: '1.5em', height: '1.5em', colour: '#7e7e7e' }),
      _react2.default.createElement(
        MenuItemText,
        null,
        translations.myAccountDealsLabel
      )
    ),
    featureFlags.testDrive && _react2.default.createElement(
      MenuItem,
      { onClick: changeAccountView('TestDrives') },
      _react2.default.createElement(_TestDrive2.default, { width: '1.5em', height: '1.5em', colour: '#7e7e7e' }),
      _react2.default.createElement(
        MenuItemText,
        null,
        translations.myAccountTestDrivesLabel
      )
    ),
    _react2.default.createElement(
      MenuItem,
      { onClick: onShortListClick },
      _react2.default.createElement(_Heart2.default, { width: '1.5em', height: '1.5em', colour: '#7e7e7e' }),
      _react2.default.createElement(
        MenuItemText,
        null,
        translations.myAccountShortlistLabel
      )
    ),
    _react2.default.createElement(
      MenuItem,
      { onClick: signOut },
      _react2.default.createElement(_User2.default, { width: '1.5em', height: '1.5em', colour: '#7e7e7e' }),
      _react2.default.createElement(
        MenuItemText,
        null,
        translations.myAccountSignOutLabel
      )
    )
  );
};

var Greeting = function Greeting(_ref2) {
  var translations = _ref2.translations,
      name = _ref2.name;
  return _react2.default.createElement(
    GreetingContainer,
    null,
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        styleOvveride: function styleOvveride() {
          return '\n        font-size: 22px;\n      ';
        }
      },
      translations.myAccountGreeting + ' ' + name
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 18px;\n        line-height: 22px;\n        margin-top: 30px;\n      ';
        }
      },
      translations.myAccountGreetingContent
    )
  );
};

function MyAccountLanding(_ref3) {
  var translations = _ref3.translations,
      changeAccountView = _ref3.changeAccountView,
      userDetails = _ref3.userDetails,
      signOut = _ref3.signOut,
      onShortListClick = _ref3.onShortListClick,
      featureFlags = _ref3.featureFlags;

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(_MyAccountHeading2.default, { heading: translations.myAccountMyAccountLabel }),
    _react2.default.createElement(
      _MyAccountContent2.default,
      null,
      _react2.default.createElement(
        Content,
        null,
        _react2.default.createElement(Greeting, { translations: translations, name: userDetails.firstName }),
        _react2.default.createElement(Menu, {
          featureFlags: featureFlags,
          translations: translations,
          changeAccountView: changeAccountView,
          signOut: signOut,
          onShortListClick: onShortListClick
        })
      )
    )
  );
}