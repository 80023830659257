'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserLocation = undefined;

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useLocation2 = require('../../hooks/useLocation');

var _useLocation3 = _interopRequireDefault(_useLocation2);

var _settings = require('../../shared/selectors/settings');

var _Spinner = require('../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__Wrapper',
  componentId: 'sc-1nhxw3n-0'
})(['display:flex;width:100%;']);

var BrowserLocation = _styledComponents2.default.button.withConfig({
  displayName: 'UserLocation__BrowserLocation',
  componentId: 'sc-1nhxw3n-1'
})(['flex:0 0 30px;background-color:#ffffff;border:1px solid #d5d5d5;', ';height:', ';cursor:pointer;padding:5px 13px;box-sizing:border-box;display:flex;justify-content:center;align-items:center;', ' &:focus{border:1px solid #999999;outline:none;}'], function (_ref) {
  var direction = _ref.direction;
  return '' + (direction === 'rtl' ? 'border-left: 1px solid transparent' : 'border-right: 1px solid transparent');
}, function (_ref2) {
  var newFilters = _ref2.newFilters;
  return newFilters ? '40px;' : '46px;';
}, function (_ref3) {
  var browserColour = _ref3.browserColour;
  return browserColour && 'background-color: ' + browserColour + ';';
});

var InputWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__InputWrapper',
  componentId: 'sc-1nhxw3n-2'
})(['position:relative;flex:1 1 0%;']);

var InputLocation = _styledComponents2.default.input.withConfig({
  displayName: 'UserLocation__InputLocation',
  componentId: 'sc-1nhxw3n-3'
})(['height:', ';width:100%;padding:0 12px;border:1px solid #d5d5d5;box-sizing:border-box;&:focus{border-color:#999999;outline:none;}'], function (_ref4) {
  var newFilters = _ref4.newFilters;
  return newFilters ? '40px;' : '46px;';
});

var LocationSuggestions = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__LocationSuggestions',
  componentId: 'sc-1nhxw3n-4'
})(['position:absolute;width:100%;', ';background-color:#ffffff;border:1px solid #d5d5d5;box-sizing:border-box;z-index:10;'], function (_ref5) {
  var position = _ref5.position;
  return position === 'below' ? 'top: 40px;' : 'bottom: 40px;';
});

var Suggestion = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__Suggestion',
  componentId: 'sc-1nhxw3n-5'
})(['font-size:12px;padding:5px;border-bottom:1px solid #d5d5d5;cursor:pointer;outline:none;', ';&:hover{background-color:#d5d5d5;}&:last-child{border-bottom:none;}'], function (_ref6) {
  var inFocus = _ref6.inFocus;
  return inFocus && 'background-color: #d5d5d5;';
});

var SpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__SpinnerWrapper',
  componentId: 'sc-1nhxw3n-6'
})(['position:absolute;right:10px;top:calc(50% - 10px);']);

var UserLocation = function UserLocation(_ref7) {
  var placeholderText = _ref7.placeholderText,
      locationAlternativeText = _ref7.locationAlternativeText,
      iconUrl = _ref7.iconUrl,
      direction = _ref7.direction,
      browserLocationCallback = _ref7.browserLocationCallback,
      inputLocationCallback = _ref7.inputLocationCallback,
      _ref7$options = _ref7.options,
      options = _ref7$options === undefined ? {} : _ref7$options,
      newFilters = _ref7.newFilters,
      mandatoryLocationInput = _ref7.mandatoryLocationInput;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      colours = _useSelector.colours;

  var _options$autocomplete = options.autocompletePosition,
      autocompletePosition = _options$autocomplete === undefined ? 'below' : _options$autocomplete,
      rest = (0, _objectWithoutProperties3.default)(options, ['autocompletePosition']);

  var _useLocation = (0, _useLocation3.default)(browserLocationCallback, inputLocationCallback, rest),
      formattedLocation = _useLocation.formattedLocation,
      getBrowserLocation = _useLocation.getBrowserLocation,
      getInputLocation = _useLocation.getInputLocation,
      onInputChange = _useLocation.onInputChange,
      suggestions = _useLocation.suggestions,
      visible = _useLocation.visible,
      currentlySelected = _useLocation.currentlySelected,
      setCurrentlySelected = _useLocation.setCurrentlySelected,
      inputRef = _useLocation.inputRef,
      onInputKeyDown = _useLocation.onInputKeyDown,
      onInputBlur = _useLocation.onInputBlur,
      onInputFocus = _useLocation.onInputFocus,
      isLoading = _useLocation.isLoading;

  var ref = (0, _react.useRef)(inputRef);

  return _react2.default.createElement(
    Wrapper,
    { role: 'group' },
    _react2.default.createElement(
      BrowserLocation,
      {
        onClick: getBrowserLocation,
        direction: direction,
        newFilters: newFilters,
        browserColour: options.browserColour,
        'aria-label': locationAlternativeText || '',
        tabIndex: mandatoryLocationInput ? '1' : '-1' // eslint-disable-line
      },
      _react2.default.createElement('img', { src: iconUrl, alt: 'location-icon' })
    ),
    _react2.default.createElement(
      InputWrapper,
      null,
      _react2.default.createElement(InputLocation, {
        'data-cy': 'user-location-input',
        ref: ref,
        placeholder: placeholderText,
        value: formattedLocation,
        onChange: onInputChange,
        onKeyDown: onInputKeyDown,
        onBlur: function onBlur(event) {
          return onInputBlur(event, placeholderText);
        },
        onFocus: onInputFocus,
        newFilters: newFilters,
        'aria-label': placeholderText,
        tabIndex: mandatoryLocationInput ? '1' : '-1' // eslint-disable-line
      }),
      suggestions.length > 0 && formattedLocation.length > 0 && visible && _react2.default.createElement(
        LocationSuggestions,
        {
          position: autocompletePosition,
          'data-cy': 'user-location-suggestion-list',
          role: 'group',
          'aria-live': 'polite'
        },
        suggestions.map(function (s, index) {
          return _react2.default.createElement(
            Suggestion,
            {
              role: 'button',
              'data-cy': 'user-location-suggestion-list-option',
              onMouseDown: function onMouseDown() {
                setCurrentlySelected(0);
                getInputLocation(s);
              },
              onMouseEnter: function onMouseEnter() {
                return setCurrentlySelected(index);
              },
              inFocus: currentlySelected === index,
              key: s.description,
              'aria-label': s.description
            },
            s.description
          );
        })
      ),
      isLoading && _react2.default.createElement(
        SpinnerWrapper,
        null,
        _react2.default.createElement(_Spinner2.default, { size: 16, colour: colours.primaryBrandColour })
      )
    )
  );
};

exports.UserLocation = UserLocation;
exports.default = UserLocation;