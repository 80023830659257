'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Globe;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Globe(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 24 20', width: '1em', height: '1em' },
      _react2.default.createElement('path', { d: 'M17.368 4.669C15.922 3.254 13.995 2.5 11.97 2.5c-1.06 0-2.12.189-3.084.566-2.795 1.226-4.627 3.96-4.627 6.978 0 1.98.868 3.866 2.314 5.28 1.445 1.509 3.373 2.263 5.397 2.263 1.06 0 2.025-.188 2.989-.566 2.891-1.225 4.723-3.96 4.626-6.977.097-2.075-.77-3.96-2.217-5.375zm.482 2.074a10.174 10.174 0 0 1-1.735 2.263c-1.253-2.546-3.18-4.526-4.434-5.563h.193c2.603-.094 4.82 1.32 5.976 3.3zm-7.325-3.206c.964.849 3.47 3.206 4.916 6.035-1.832 1.415-4.049 2.075-5.88 2.357C8.404 8.912 8.404 5.612 8.5 4.386c.289-.189.578-.283.867-.377.29-.283.771-.377 1.157-.472zM7.537 5.046c0 1.603.096 4.432 1.06 7.072-1.446.189-2.603.094-3.085 0-.192-.66-.385-1.32-.385-2.074 0-1.98.964-3.772 2.41-4.998zm-.386 9.712c-.578-.471-.964-1.131-1.253-1.697.675 0 1.831.094 3.084-.094.675 1.414 1.543 2.734 2.892 3.772-1.831-.095-3.47-.755-4.723-1.98zm6.073 1.792c-1.543-.849-2.603-2.263-3.374-3.772 1.06-.188 2.217-.471 3.277-.943a13.084 13.084 0 0 0 2.506-1.414c.579 1.508.868 3.111.482 4.809-.482.377-1.06.754-1.638.943-.29.188-.771.283-1.253.377zm4.144-2.452c.097-1.508-.289-2.923-.867-4.337.675-.566 1.253-1.32 1.831-2.17.29.755.482 1.51.482 2.358 0 1.603-.578 3.018-1.446 4.15z' })
    )
  );
}

Globe.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};