'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    align-items: start;\n  '], ['\n    align-items: start;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    align-items: start;\n    font-size: 12px;\n    letter-spacing: 0.57px;\n  '], ['\n    align-items: start;\n    font-size: 12px;\n    letter-spacing: 0.57px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _helpers = require('../../../shared/currencyConversion/helpers');

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../Global');

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

var _StyledIcon = require('../../StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _Tooltip = require('../../Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = (0, _styledComponents2.default)(_Global.HeadingOne).withConfig({
  displayName: 'VdpVehiclePrice__Container',
  componentId: 'sc-1irk71h-0'
})(['margin:0;display:flex;align-items:end;flex-direction:column;', ';'], _theme2.default.max.medium(_templateObject));

var BespokeFeatureLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__BespokeFeatureLabel',
  componentId: 'sc-1irk71h-1'
})(['font-family:\'RivieraNights-Medium, Jost-Light\';font-size:16px;color:#281432;text-transform:uppercase;padding-top:4px;letter-spacing:2px;', ';'], _theme2.default.max.medium(_templateObject2));

var PriceWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__PriceWrapper',
  componentId: 'sc-1irk71h-2'
})(['text-align:left;', ';', ';', ';', ';', ';'], function (_ref) {
  var font = _ref.font;
  return font && font.fontSize && 'font-size: ' + font.fontSize + 'px';
}, function (_ref2) {
  var font = _ref2.font;
  return font && font.colour && 'color: ' + font.colour.value;
}, function (_ref3) {
  var font = _ref3.font;
  return font && font.transform && 'text-transform: ' + font.transform;
}, function (_ref4) {
  var font = _ref4.font;
  return font && font.typeface && 'font-family: ' + font.typeface.value;
}, function (_ref5) {
  var font = _ref5.font;
  return font && font.kerning && 'letter-spacing: ' + font.kerning + 'px';
});

var PriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__PriceContainer',
  componentId: 'sc-1irk71h-3'
})(['display:flex;flex-direction:row;gap:10px;white-space:nowrap;']);

var VdpVehiclePrice = function VdpVehiclePrice(props) {
  var vehicleInfo = props.vehicleInfo,
      headerFont = props.headerFont,
      translations = props.translations,
      handleZeroPriceAsText = props.handleZeroPriceAsText,
      config = props.config,
      handlePrice = props.handlePrice;


  var locale = vehicleInfo.locale.replace('_', '-');

  var showSalesTaxDisclaimer = config.showSectionSalesTaxDisclaimer && !!vehicleInfo.sectionSalesTaxDisclaimer;

  var netPrice = vehicleInfo.netPrice ? (0, _helpers.handleVehiclePrice)(vehicleInfo.netPrice, {
    currencyType: vehicleInfo.price.currency,
    locale: locale,
    handleZeroPriceAsText: handleZeroPriceAsText,
    priceSpecial: vehicleInfo.priceSpecial,
    zeroPriceText: translations.zeroPriceText,
    translationsSold: translations.sold,
    vehiclePrice: vehicleInfo.price.value
  }) : undefined;

  var isMobile = (0, _useCheckIsMobileScreen2.default)('large');

  var priceStyle = (0, _react.useMemo)(function () {
    if (!config || !config.priceWrapperFont && !config.priceWrapperFontMobile) {
      return undefined;
    }

    return isMobile ? config.priceWrapperFontMobile : config.priceWrapperFont;
  }, [config, isMobile]);

  return _react2.default.createElement(
    Container,
    {
      styleOverride: function styleOverride() {
        return '' + (0, _Global.fontStyleOverride)(headerFont);
      },
      maxMediumMobileStyleOverride: function maxMediumMobileStyleOverride() {
        return 'font-size: 22px;';
      }
    },
    _react2.default.createElement(
      PriceContainer,
      null,
      handlePrice,
      showSalesTaxDisclaimer && netPrice && _react2.default.createElement(
        _Tooltip2.default,
        {
          text: translations.salesTaxDisclaimer,
          font: config.tooltipFont
        },
        _react2.default.createElement(
          _IconWrapper2.default,
          null,
          _react2.default.createElement(_StyledIcon2.default, { link: config.infoIcon })
        )
      )
    ),
    vehicleInfo.bespoke === 1 && _react2.default.createElement(
      BespokeFeatureLabel,
      null,
      translations.bespokeFeatureLabel
    ),
    netPrice && !showSalesTaxDisclaimer && _react2.default.createElement(
      PriceWrapper,
      { font: priceStyle && priceStyle },
      (0, _translateFromTemplate.translateFromTemplate)('netPrice', { VALUE: netPrice }, translations) + '  ' + (vehicleInfo.retailerInformation.countryCode === 'DE' ? translations.netPriceLabel : '')
    )
  );
};

exports.default = _react2.default.memo(VdpVehiclePrice);