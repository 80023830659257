'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     padding: 8px 30px 16px 30px;\n  '], ['\n     padding: 8px 30px 16px 30px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _rcSlider = require('rc-slider');

var _rcSlider2 = _interopRequireDefault(_rcSlider);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

require('rc-slider/assets/index.css');

var _Global = require('../../../components/Global');

var _mathHelper = require('../../../utilities/mathHelper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OneHandledRangeFilter__ContentWrapper',
  componentId: 'sc-e4p5t0-0'
})(['', ';padding:8px 16px 16px 16px;'], _theme2.default.max.medium(_templateObject));

var Labels = _styledComponents2.default.div.withConfig({
  displayName: 'OneHandledRangeFilter__Labels',
  componentId: 'sc-e4p5t0-1'
})(['display:flex;justify-content:space-between;align-items:center;']);

var SliderWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OneHandledRangeFilter__SliderWrapper',
  componentId: 'sc-e4p5t0-2'
})(['padding:0 8px;']);

var HandleContainer = (0, _styledComponents2.default)(_rcSlider.Handle).withConfig({
  displayName: 'OneHandledRangeFilter__HandleContainer',
  componentId: 'sc-e4p5t0-3'
})(['margin-top:-8px;position:absolute;touch-action:pan-x;cursor:grab;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OneHandledRangeFilter__IconWrapper',
  componentId: 'sc-e4p5t0-4'
})(['width:20px;height:20px;', ';background-size:cover;user-drag:none;'], function (_ref) {
  var src = _ref.src;
  return 'background-image: url(' + src + ')';
});
var calcMaxValue = function calcMaxValue(maxValue, step) {
  return maxValue % step !== 0 ? maxValue + (step - maxValue % step) : maxValue;
};

var OneHandledRangeFilter = function (_Component) {
  (0, _inherits3.default)(OneHandledRangeFilter, _Component);

  function OneHandledRangeFilter(props) {
    (0, _classCallCheck3.default)(this, OneHandledRangeFilter);

    var _this = (0, _possibleConstructorReturn3.default)(this, (OneHandledRangeFilter.__proto__ || (0, _getPrototypeOf2.default)(OneHandledRangeFilter)).call(this, props));

    _initialiseProps.call(_this);

    _this.state = {
      currentValue: props.selectedValue
    };
    return _this;
  }

  return OneHandledRangeFilter;
}(_react.Component);

Object.defineProperty(OneHandledRangeFilter, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    step: 1,
    minValue: 0
  }
});

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  Object.defineProperty(this, 'componentDidUpdate', {
    enumerable: true,
    writable: true,
    value: function value(prevProps) {
      var selectedValue = _this2.props.selectedValue;

      if (prevProps.selectedValue !== selectedValue) {
        _this2.updateSliderValue(selectedValue);
      }
    }
  });
  Object.defineProperty(this, 'updateSliderValue', {
    enumerable: true,
    writable: true,
    value: function value(_value) {
      _this2.setState(function () {
        return { currentValue: _this2.roundValues(_value) };
      });
    }
  });
  Object.defineProperty(this, 'incrementSliderValue', {
    enumerable: true,
    writable: true,
    value: function value(_value2) {
      _this2.updateSliderValue(Math.min(_value2 + _this2.props.step, _this2.props.maxValue));
    }
  });
  Object.defineProperty(this, 'decrementSliderValue', {
    enumerable: true,
    writable: true,
    value: function value(_value3) {
      _this2.updateSliderValue(Math.max(_value3 - _this2.props.step, _this2.props.minValue || 0));
    }
  });
  Object.defineProperty(this, 'updateFilter', {
    enumerable: true,
    writable: true,
    value: function value() {
      var filterKey = _this2.props.filterKey;
      var currentValue = _this2.state.currentValue;

      _this2.props.updateFilters([{ key: filterKey, value: currentValue }]);
    }
  });
  Object.defineProperty(this, 'roundValues', {
    enumerable: true,
    writable: true,
    value: function value(_value4) {
      var _props = _this2.props,
          step = _props.step,
          minValue = _props.minValue,
          maxValue = _props.maxValue;

      var minRangeValue = Number(minValue) || 0;
      var maxRangeValue = calcMaxValue(Number(maxValue), step);
      return (0, _mathHelper.roundRangeValues)(minRangeValue, maxRangeValue, _value4, _value4, step)[0];
    }
  });
  Object.defineProperty(this, 'render', {
    enumerable: true,
    writable: true,
    value: function value() {
      var _props2 = _this2.props,
          translations = _props2.translations,
          filterLabel = _props2.filterLabel,
          step = _props2.step,
          minValue = _props2.minValue,
          maxValue = _props2.maxValue,
          _props2$globalStyling = _props2.globalStyling,
          direction = _props2$globalStyling.direction,
          primaryBrandColour = _props2$globalStyling.colours.primaryBrandColour,
          stylingConfig = _props2.stylingConfig,
          filtersStyling = _props2.filtersStyling,
          formatter = _props2.formatter;
      var currentValue = _this2.state.currentValue;


      var CustomHandle = function CustomHandle(props) {
        return (
          // eslint-disable-next-line jsx-a11y/interactive-supports-focus
          _react2.default.createElement(
            'div',
            {
              role: 'slider',
              'aria-valuenow': props.value,
              'aria-valuemax': _this2.props.maxValue,
              'aria-valuemin': _this2.props.minValue,
              onKeyDown: function onKeyDown(e) {
                if (e.key === 'ArrowRight') {
                  _this2.incrementSliderValue(_this2.state.currentValue);
                }
                if (e.key === 'ArrowLeft') {
                  _this2.decrementSliderValue(_this2.state.currentValue);
                }
              },
              onKeyUp: function onKeyUp() {
                _this2.updateFilter();
              }
            },
            _react2.default.createElement(
              HandleContainer,
              {
                key: props.index,
                value: props.value,
                offset: props.offset,
                reverse: direction === 'rtl'
              },
              _react2.default.createElement(IconWrapper, { src: stylingConfig.filterSliderIcon, alt: '' })
            )
          )
        );
      };

      var calculatedMax = calcMaxValue(maxValue, step);
      return _react2.default.createElement(
        ContentWrapper,
        null,
        _react2.default.createElement(
          Labels,
          null,
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n            ' + (0, _Global.fontStyleOverride)(filtersStyling.filterMenuFont) + '\n          ';
              }
            },
            filterLabel
          ),
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n            ' + (0, _Global.fontStyleOverride)(filtersStyling.filterMenuFont) + '\n          ';
              }
            },
            formatter(currentValue)
          )
        ),
        _react2.default.createElement(
          SliderWrapper,
          null,
          _react2.default.createElement(_rcSlider2.default, {
            tabIndex: '0',
            'aria-label': translations.sliderAriaLabel,
            trackStyle: { backgroundColor: primaryBrandColour.value },
            handle: CustomHandle,
            step: step,
            value: currentValue,
            min: minValue || 0,
            max: calculatedMax,
            onChange: _this2.updateSliderValue,
            onAfterChange: _this2.updateFilter,
            reverse: direction === 'rtl'
          })
        )
      );
    }
  });
};

exports.default = OneHandledRangeFilter;


OneHandledRangeFilter.defaultProps = {
  formatter: function formatter(value) {
    return value;
  }
};