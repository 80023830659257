'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RRStyle1;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../Global');

var _LabelledCheckBox = require('../LabelledCheckBox');

var _LabelledCheckBox2 = _interopRequireDefault(_LabelledCheckBox);

var _RoundedCheckBox = require('../RoundedCheckBox');

var _RoundedCheckBox2 = _interopRequireDefault(_RoundedCheckBox);

var _MoreInfoCollapsible = require('../MoreInfoCollapsible');

var _MoreInfoCollapsible2 = _interopRequireDefault(_MoreInfoCollapsible);

var _ipInformation = require('../../shared/localisation/ipInformation');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MarketingContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RRStyle1__MarketingContainer',
  componentId: 'sc-rx1w4r-0'
})(['display:flex;']);


var CheckboxContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RRStyle1__CheckboxContainer',
  componentId: 'sc-rx1w4r-1'
})(['display:flex;flex-direction:column;']);

// this has jag colour but we are using as error colour too
var ErrorLabel = _styledComponents2.default.span.withConfig({
  displayName: 'RRStyle1__ErrorLabel',
  componentId: 'sc-rx1w4r-2'
})(['color:#9e1b32;margin-left:15px;']);

var SubHeader = (0, _styledComponents2.default)(_Global.Paragraph).withConfig({
  displayName: 'RRStyle1__SubHeader',
  componentId: 'sc-rx1w4r-3'
})(['', ';'], function (_ref) {
  var globalStyling = _ref.globalStyling;
  return globalStyling && 'font-family: ' + (globalStyling && globalStyling.colours.primaryBrandColour.value) + ';\n      font-weight: 700;\n  ';
});

var Link = _styledComponents2.default.a.withConfig({
  displayName: 'RRStyle1__Link',
  componentId: 'sc-rx1w4r-4'
})(['', ';'], function (_ref2) {
  var colour = _ref2.colour;
  return colour && 'color: ' + colour;
});

var LineBreak = _styledComponents2.default.div.withConfig({
  displayName: 'RRStyle1__LineBreak',
  componentId: 'sc-rx1w4r-5'
})(['border-bottom:1px solid #dedede;margin-bottom:30px;']);

var ConsentBodySectionOne = function ConsentBodySectionOne(font, translations) {
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _Global.Paragraph,
      { styleOverride: function styleOverride() {
          return font;
        } },
      translations.marketingConsentItemOne,
      _react2.default.createElement('br', null),
      translations.marketingConsentItemTwo,
      _react2.default.createElement('br', null),
      translations.marketingConsentItemThree
    )
  );
};

function RRStyle1(_ref3) {
  var translations = _ref3.translations,
      onCheckBoxClick = _ref3.onCheckBoxClick,
      marketingKey = _ref3.marketingKey,
      labelFontSize = _ref3.labelFontSize,
      globalStyling = _ref3.globalStyling,
      onColour = _ref3.onColour,
      phoneChecked = _ref3.phoneChecked,
      emailChecked = _ref3.emailChecked,
      smsChecked = _ref3.smsChecked,
      marketingChecked = _ref3.marketingChecked,
      inCarChecked = _ref3.inCarChecked,
      phoneKey = _ref3.phoneKey,
      emailKey = _ref3.emailKey,
      smsKey = _ref3.smsKey,
      inCarKey = _ref3.inCarKey,
      dataConsentFont = _ref3.dataConsentFont,
      checkBoxError = _ref3.checkBoxError;

  // This can be at Config, although this won't change quite often and
  // we need to add this countries array to multiple pages in Config.
  var useNATexts = ['MX', 'US', 'CA'].includes((0, _ipInformation.getIpCountryCode)() || '');

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      'div',
      null,
      _react2.default.createElement(
        SubHeader,
        {
          globalStyling: globalStyling,
          styleOverride: function styleOverride() {
            return dataConsentFont;
          }
        },
        translations.marketingConsentContentSubheader,
        ' '
      ),
      ConsentBodySectionOne(dataConsentFont, translations),
      _react2.default.createElement(
        MarketingContainer,
        null,
        _react2.default.createElement(_RoundedCheckBox2.default, {
          onClick: function onClick() {
            return onCheckBoxClick(marketingKey, !marketingChecked);
          },
          checked: marketingChecked,
          colour: globalStyling.colours.paragraphFontColour && globalStyling.colours.paragraphFontColour.value,
          globalStyling: globalStyling
        }),
        _react2.default.createElement(
          _Global.Paragraph,
          { styleOverride: function styleOverride() {
              return dataConsentFont;
            } },
          useNATexts ? translations.marketingConsentContentOneNA : translations.marketingConsentContentOne
        )
      ),
      _react2.default.createElement(LineBreak, null),
      _react2.default.createElement(
        _MoreInfoCollapsible2.default,
        {
          title: translations.moreInformationOneHeader,
          globalStyling: globalStyling
        },
        _react2.default.createElement(
          _Global.Paragraph,
          { styleOverride: function styleOverride() {
              return dataConsentFont;
            } },
          useNATexts ? translations.moreInformationOneBodyOneNA : translations.moreInformationOneBodyOne
        ),
        _react2.default.createElement(
          _Global.Paragraph,
          { styleOverride: function styleOverride() {
              return dataConsentFont;
            } },
          translations.moreInformationOneBodyTwo,
          ' ',
          _react2.default.createElement(
            Link,
            {
              colour: globalStyling && globalStyling.colours.primaryFontColour.value,
              rel: 'noopener noreferrer',
              target: '_blank',
              href: 'https://www.rolls-roycemotorcars.com/en-GB/information/privacy-policy.html'
            },
            translations.privacyPolicyHyperLinkText
          )
        )
      )
    ),
    marketingChecked && marketingChecked === true && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        _Global.Paragraph,
        { styleOverride: function styleOverride() {
            return dataConsentFont;
          } },
        translations.pleaseSelectChannelsText
      ),
      _react2.default.createElement(
        CheckboxContainer,
        null,
        _react2.default.createElement(_LabelledCheckBox2.default, {
          onClick: function onClick() {
            return onCheckBoxClick(emailKey, !emailChecked);
          },
          checked: emailChecked,
          label: translations.dataConsentEmailLabel,
          onColour: onColour,
          labelFontSize: labelFontSize,
          checkboxDimension: 1.3,
          globalStyling: globalStyling
        }),
        _react2.default.createElement(_LabelledCheckBox2.default, {
          onClick: function onClick() {
            return onCheckBoxClick(phoneKey, !phoneChecked);
          },
          checked: phoneChecked,
          label: translations.dataConsentPhoneLabel,
          onColour: onColour,
          labelFontSize: labelFontSize,
          checkboxDimension: 1.3,
          globalStyling: globalStyling
        }),
        _react2.default.createElement(_LabelledCheckBox2.default, {
          onClick: function onClick() {
            return onCheckBoxClick(inCarKey, !inCarChecked);
          },
          checked: inCarChecked,
          label: translations.dataConsentInCarLabel,
          onColour: onColour,
          labelFontSize: labelFontSize,
          checkboxDimension: 1.3,
          globalStyling: globalStyling
        }),
        _react2.default.createElement(_LabelledCheckBox2.default, {
          onClick: function onClick() {
            return onCheckBoxClick(smsKey, !smsChecked);
          },
          checked: smsChecked,
          label: translations.dataConsentSmsLabel,
          onColour: onColour,
          labelFontSize: labelFontSize,
          checkboxDimension: 1.3,
          globalStyling: globalStyling
        })
      ),
      checkBoxError && _react2.default.createElement(
        ErrorLabel,
        null,
        translations.formValidationRequired
      ),
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          { styleOverride: function styleOverride() {
              return dataConsentFont;
            } },
          translations.moreInformationTwoBodyOne
        ),
        _react2.default.createElement(
          _MoreInfoCollapsible2.default,
          {
            title: translations.moreInformationTwoHeader,
            globalStyling: globalStyling
          },
          _react2.default.createElement(
            _Global.Paragraph,
            { styleOverride: function styleOverride() {
                return dataConsentFont;
              } },
            translations.moreInformationChannels
          ),
          _react2.default.createElement(
            _Global.Paragraph,
            { styleOverride: function styleOverride() {
                return dataConsentFont;
              } },
            useNATexts ? translations.moreInformationTwoBodyTwoNA : translations.moreInformationTwoBodyTwo,
            ' ',
            translations.moreInformationTwoBodyThree,
            ' ',
            useNATexts ? translations.moreInformationTwoBodyEmailNA : translations.moreInformationTwoBodyEmail,
            '.',
            ' ',
            useNATexts ? translations.moreInformationTwoBodyFourNA : translations.moreInformationTwoBodyFour,
            ' ',
            _react2.default.createElement(
              Link,
              {
                colour: globalStyling && globalStyling.colours.primaryFontColour.value,
                rel: 'noopener noreferrer',
                target: '_blank',
                href: 'https://www.rolls-roycemotorcars.com/en-GB/information/privacy-policy.html'
              },
              translations.privacyPolicyHyperLinkText
            )
          )
        )
      )
    ),
    _react2.default.createElement('div', { style: { paddingBottom: '16px' } })
  );
}