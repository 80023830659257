'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['flex-direction: row;'], ['flex-direction: row;']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-grow: 0;\n    flex-shrink: 0;\n    flex-basis: ', ';\n    @supports(display: grid) {\n      padding: ', ';\n    }\n  '], ['\n    flex-grow: 0;\n    flex-shrink: 0;\n    flex-basis: ', ';\n    @supports(display: grid) {\n      padding: ', ';\n    }\n  ']);

exports.default = Grid;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _GridItem = require('../../components/GridItem');

var _GridItem2 = _interopRequireDefault(_GridItem);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _router = require('../../actions/router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var criteria = function criteria(itemLength) {
  return [2, 4].includes(itemLength);
};
var getNumberOfColumns = function getNumberOfColumns(itemLength, meetsCritera, defaultValue) {
  if (criteria(itemLength)) return meetsCritera;
  return defaultValue;
};

var GridWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Grid__GridWrapper',
  componentId: 'sc-hjovwi-0'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.min.medium(_templateObject));

var GridItemWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Grid__GridItemWrapper',
  componentId: 'sc-hjovwi-1'
})(['display:flex;flex-grow:0;flex-shrink:0;box-sizing:border-box;', ';'], _theme2.default.min.medium(_templateObject2, function (_ref) {
  var itemCount = _ref.itemCount;
  return itemCount === 1 ? '100%' : getNumberOfColumns(itemCount, '50%', 100 / 3 + '%');
}, function (_ref2) {
  var gridGap = _ref2.gridGap;
  return gridGap ? '0 ' + gridGap / 2 + 'px' : '0 5px';
}));

function Grid(_ref3) {
  var config = _ref3.config,
      globalStyling = _ref3.globalStyling,
      dispatch = _ref3.dispatch;

  var buttonProps = {
    backgroundColour: config.buttonBackgroundColour,
    hover: config.buttonHover,
    font: config.buttonFont,
    buttonSize: config.buttonSize,
    buttonType: config.buttonType,
    buttonStyle: config.buttonStyle
  };

  var alignItems = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  var gridGap = config.gridGap;

  var isExternal = function isExternal(href) {
    return href.match(/^http/);
  };
  var actionButton = function actionButton(link) {
    return isExternal(link) ? window.open(link, '_blank') : dispatch(_router.actions.navigate(link));
  };

  return _react2.default.createElement(
    _ContentWrapper2.default,
    {
      colour: config.gridBackgroundColour && config.gridBackgroundColour,
      contentWidth: globalStyling.contentWidth
    },
    _react2.default.createElement(
      'div',
      {
        style: {
          padding: gridGap ? gridGap + 'px ' + gridGap / 2 + 'px' : '10px 5px'
        }
      },
      _react2.default.createElement(
        GridWrapper,
        null,
        config.items.map(function (it, index) {
          return _react2.default.createElement(
            GridItemWrapper,
            {
              key: 'GridItem' + (index + 1),
              itemCount: config.items.length,
              gridGap: gridGap
            },
            _react2.default.createElement(_GridItem2.default, {
              itemVideo: it.video && it.video,
              itemCount: config.items.length,
              linkText: it.translations.link,
              imageUrl: it.image && it.image.value,
              headline: it.translations.headline,
              bodyText: it.translations.body,
              bodyTextTheme: config.gridItemBodyFont || {},
              headlineTheme: config.gridItemHeaderFont || {},
              align: alignItems[config.gridItemsAlign],
              buttonProps: buttonProps,
              globalStyling: globalStyling,
              onClickLink: function onClickLink() {
                return actionButton(it.link.url);
              },
              bgColour: config.itemBackgroundColour && config.itemBackgroundColour.value,
              imageAspectRatioPercentage: config.gridItemImageAspectRatioPercentage,
              borderLeft: config.column && config.column.borderLeft,
              margin: config.gridContainerMargin && config.gridContainerMargin,
              padding: config.gridContainerPadding && config.gridContainerPadding,
              translations: it.translations
            })
          );
        })
      )
    )
  );
}