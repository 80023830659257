'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useResponsiveImages = require('../../../hooks/useResponsiveImages');

var _useResponsiveImages2 = _interopRequireDefault(_useResponsiveImages);

var _ClickWrapper = require('./ClickWrapper');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BackgroundImage = _styledComponents2.default.img.withConfig({
  displayName: 'BackgroundImage'
}).withConfig({
  displayName: 'Image__BackgroundImage',
  componentId: 'sc-mtrvw9-0'
})(['display:flex;width:100%;object-fit:cover;', ' ', ''], function (_ref) {
  var mask = _ref.mask;
  return mask && '\n      mask-image: linear-gradient(\n        180deg,\n        rgba(17, 17, 17, 0.7) 0%,\n        rgba(17, 17, 17, 1) 60%,\n        rgba(17, 17, 17, 0.5) 100%\n      );\n  ';
}, function (_ref2) {
  var brand = _ref2.brand;
  return brand && brand == 'Genesis' && '\n  @media screen and (min-width: 0px) and (max-width: 767px) {\n    height: calc(100vh - 64px);\n    max-height: unset;\n    min-height: unset;\n  }\n  @media screen and (min-width: 768px) and (max-width: 1023px) {\n    height: calc(100vh - 80px);\n    max-height: 1140px;\n    min-height: 480px;\n  }\n  @media screen and (min-width: 1024px) and (max-width: 1199px) {\n    height: calc(100vh - 80px);\n    max-height: 614px;\n    min-height: 483px;\n  }\n  @media screen and (min-width: 1200px) and (max-width: 1399px) {\n    height: calc(100vh - 80px);\n    max-height: 720px;\n    min-height: 480px;\n  }\n  @media screen and (min-width: 1400px) and (max-width: 1919px) {\n    height: calc(100vh - 80px);\n    max-height: 840px;\n    min-height: 561px;\n  }\n  @media screen and (min-width: 1920px){\n    height: calc(100vh - 80px);\n    max-height: 1152px;\n    min-height: 768px;\n  }\n  ';
});

var Image = function Image(_ref3) {
  var url = _ref3.url,
      preview = _ref3.preview,
      mediaContent = _ref3.mediaContent,
      alternativeText = _ref3.alternativeText,
      onLinkClick = _ref3.onLinkClick,
      mask = _ref3.mask;

  var dynamicImage = mediaContent.dynamicImages && (0, _useResponsiveImages2.default)(mediaContent.dynamicImages);

  return _react2.default.createElement(
    _ClickWrapper.ClickWrapper,
    {
      fullScreen: mediaContent.fullScreen,
      pointer: url && url.value,
      onClick: function onClick(e) {
        if (preview) {
          e.preventDefault();
        }
        if (url) {
          onLinkClick(url && url.value);
        }
      }
    },
    _react2.default.createElement(BackgroundImage, {
      src: dynamicImage || mediaContent.value,
      width: mediaContent && mediaContent.defaultImageWidth,
      height: mediaContent && mediaContent.defaultImageHeight,
      brand: mediaContent && mediaContent.brand,
      'data-cy': mediaContent.value,
      alt: alternativeText,
      mask: mask
    })
  );
};

exports.default = Image;