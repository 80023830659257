'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _VehicleRetailerInfo = require('./VehicleRetailerInfo');

var _VehicleRetailerInfo2 = _interopRequireDefault(_VehicleRetailerInfo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _VehicleRetailerInfo2.default;