'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledHeaderBanner = _styledComponents2.default.div.withConfig({
  displayName: 'HeaderBanner__StyledHeaderBanner',
  componentId: 'sc-j5t2eg-0'
})(['width:100%;height:100px;margin-top:-100px;z-index:10;position:absolute;background:rgba(0,0,0,0.5);']);

var StyledBannerBlock = _styledComponents2.default.div.withConfig({
  displayName: 'HeaderBanner__StyledBannerBlock',
  componentId: 'sc-j5t2eg-1'
})(['width:calc(25% - 1px);height:100%;border-right:1px solid white;display:inline-block;&:last-child{border:none;}.section{display:inline-block;color:white;height:100%;span{display:block;vertical-align:middle;}span:first-child{line-height:50px;}}.right-section{float:right;width:75%;}.left-section{width:25%;.image-class{width:100%;height:100%;background:url(', ') no-repeat;background-size:80% 80%;background-position:center;}}'], function (props) {
  return props.image;
});

var BannerBlock = function BannerBlock(block, index) {
  return _react2.default.createElement(
    StyledBannerBlock,
    { key: index, image: block.image },
    _react2.default.createElement(
      'div',
      { className: 'right-section section' },
      _react2.default.createElement(
        'span',
        null,
        block.text
      ),
      _react2.default.createElement(
        'span',
        null,
        block.subText
      )
    ),
    _react2.default.createElement(
      'div',
      { className: 'left-section section' },
      _react2.default.createElement('div', { className: 'image-class' })
    )
  );
};

var renderBanner = function renderBanner(bannerInfo) {
  return bannerInfo.map(function (section, index) {
    return BannerBlock(section, index);
  });
};

var HeaderBanner = function HeaderBanner(_ref) {
  var bannerInfo = _ref.bannerInfo;
  return _react2.default.createElement(
    StyledHeaderBanner,
    null,
    renderBanner(bannerInfo)
  );
};

exports.default = HeaderBanner;