'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _router = require('../../actions/router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  TOGGLE_ENQUIRE_DRAWER: 'TOGGLE_DRAWER',
  TOGGLE_FINANCE_DRAWER: 'TOGGLE_FINANCE_DRAWER'
};

var actions = exports.actions = {
  toggleEnquireDrawer: function toggleEnquireDrawer(payload) {
    return {
      type: constants.TOGGLE_ENQUIRE_DRAWER,
      payload: payload
    };
  },
  toggleFinanceDrawer: function toggleFinanceDrawer(payload) {
    return {
      type: constants.TOGGLE_FINANCE_DRAWER,
      payload: payload
    };
  }
};

var initialState = {
  enquireDrawer: false,
  financeDrawer: false,
  currentVehicle: null
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var _ref = arguments[1];
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case constants.TOGGLE_ENQUIRE_DRAWER:
      return (0, _extends3.default)({}, state, {
        enquireDrawer: !state.enquireDrawer,
        currentVehicle: !state.enquireDrawer ? payload : null
      });
    case constants.TOGGLE_FINANCE_DRAWER:
      return (0, _extends3.default)({}, state, {
        financeDrawer: !state.financeDrawer
      }, payload ? { currentVehicle: payload } : {});
    case _router.constants.urlChangeNavigation:
      return (0, _extends3.default)({}, state, {
        enquireDrawer: false,
        financeDrawer: false,
        currentVehicle: null
      });
    default:
      return state;
  }
}