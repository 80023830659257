'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var constants = exports.constants = {
  GET_ENQUIRY_VEHICLE_INFO: 'GET_ENQUIRY_VEHICLE_INFO',
  GET_ENQUIRY_VEHICLE_INFO_SUCCESS: 'GET_ENQUIRY_VEHICLE_INFO_SUCCESS',
  CLEAR_ENQUIRY_VEHICLE_INFO: 'CLEAR_ENQUIRY_VEHICLE_INFO'
};

var actions = exports.actions = {
  getEnquiryVehicleInfo: function getEnquiryVehicleInfo(vehicleId) {
    return {
      type: constants.GET_ENQUIRY_VEHICLE_INFO,
      vehicleId: vehicleId
    };
  },
  getEnquiryVehicleInfoSuccess: function getEnquiryVehicleInfoSuccess(vehicleResponse) {
    return {
      type: constants.GET_ENQUIRY_VEHICLE_INFO_SUCCESS,
      payload: vehicleResponse
    };
  },
  clearEnquiryVehicleInfo: function clearEnquiryVehicleInfo() {
    return {
      type: constants.CLEAR_ENQUIRY_VEHICLE_INFO
    };
  }
};