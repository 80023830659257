'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px 5px;\n  '], ['\n    padding: 20px 5px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FieldError = require('./FieldError');

var _FieldError2 = _interopRequireDefault(_FieldError);

var _WidthWrapper = require('./WidthWrapper');

var _WidthWrapper2 = _interopRequireDefault(_WidthWrapper);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FormFieldContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Radio__FormFieldContainer',
  componentId: 'sc-1uez2ij-0'
})(['width:100%;display:flex;flex-direction:column;padding-bottom:5px;', ';'], _theme2.default.min.medium(_templateObject));

var RadioGroupTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Radio__RadioGroupTitle',
  componentId: 'sc-1uez2ij-1'
})(['margin-bottom:10px;']);

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'Radio__Item',
  componentId: 'sc-1uez2ij-2'
})(['display:flex;align-items:center;height:38px;position:relative;']);

var StyledRadioButton = _styledComponents2.default.div.withConfig({
  displayName: 'Radio__StyledRadioButton',
  componentId: 'sc-1uez2ij-3'
})(['position:absolute;left:4px;top:16px;width:24px;height:24px;border-radius:50%;border:1px solid ', ';background-color:', ';'], function (_ref) {
  var borderColour = _ref.borderColour;
  return borderColour || '#999999';
}, function (_ref2) {
  var backgroundColour = _ref2.backgroundColour;
  return backgroundColour || '#ffffff';
});

var RadioButton = _styledComponents2.default.input.withConfig({
  displayName: 'Radio__RadioButton',
  componentId: 'sc-1uez2ij-4'
})(['opacity:0;z-index:1;border-radius:50%;width:24px;height:24px;margin-right:10px;&:checked + ', '{background-color:', ';&::after{content:\'\';display:block;border-radius:50%;width:12px;height:12px;margin:5px;background:', ';}}'], StyledRadioButton, function (_ref3) {
  var backgroundColourChecked = _ref3.backgroundColourChecked;
  return backgroundColourChecked || '#ffffff';
}, function (_ref4) {
  var colourChecked = _ref4.colourChecked;
  return colourChecked || '#999999';
});

exports.default = function (_ref5) {
  var required = _ref5.required,
      name = _ref5.name,
      label = _ref5.label,
      onChange = _ref5.onChange,
      options = _ref5.options,
      value = _ref5.value,
      error = _ref5.error,
      radio = _ref5.uiElements.radio,
      translations = _ref5.translations;

  return _react2.default.createElement(
    _WidthWrapper2.default,
    null,
    _react2.default.createElement(
      FormFieldContainer,
      { role: 'group', 'aria-labelledby': name },
      _react2.default.createElement(
        RadioGroupTitle,
        { id: name },
        translations[name + 'Label'],
        ' ',
        required && '*'
      ),
      options.map(function (translationKey) {
        var optionLabel = translations[translationKey + 'Value'];
        var optionValue = translations[translationKey + 'Value'];

        return _react2.default.createElement(
          Item,
          { key: translationKey },
          _react2.default.createElement(
            'label',
            { htmlFor: name + '-' + translationKey },
            _react2.default.createElement(RadioButton, (0, _extends3.default)({
              type: 'radio',
              name: optionLabel,
              id: name + '-' + translationKey,
              value: optionValue,
              checked: optionValue === value,
              onChange: function (_onChange) {
                function onChange() {
                  return _onChange.apply(this, arguments);
                }

                onChange.toString = function () {
                  return _onChange.toString();
                };

                return onChange;
              }(function () {
                return onChange({ name: name, value: optionValue });
              })
            }, radio)),
            _react2.default.createElement(StyledRadioButton, null),
            optionLabel
          )
        );
      }),
      error && _react2.default.createElement(
        _FieldError2.default,
        null,
        error
      )
    )
  );
};