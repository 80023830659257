'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Shared = require('../Shared');

var _ComparedVehicleType = require('./../../../types/ComparedVehicleType');

var _emissionSrpText = require('../../../components/Kia/emissionSrpText');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_STRING = '-';

var KiaConsumptionEmissionsComponent = function KiaConsumptionEmissionsComponent(_ref) {
  var comparedVehicles = _ref.comparedVehicles,
      infoFont = _ref.infoFont,
      translations = _ref.translations;

  var vehicles = comparedVehicles.length > 3 ? comparedVehicles.slice(0, 3) : comparedVehicles;

  var dataStrings = vehicles.reduce(function (acc, vehicle, index) {
    var consumptionDataStr = (0, _emissionSrpText.displayKiaEmissionsConsumption)(vehicle, translations);
    var electricDataStr = (0, _emissionSrpText.displayKiaElectricRange)(vehicle, translations);

    if (consumptionDataStr != null) {
      acc.push(_react2.default.createElement(
        'div',
        {
          style: {
            lineHeight: '25px'
          }
        },
        consumptionDataStr
      ));
    }
    if (electricDataStr != null) {
      acc[index] = acc[index] !== undefined ? _react2.default.createElement(
        'div',
        null,
        acc[index],
        _react2.default.createElement(
          'div',
          {
            style: {
              lineHeight: '25px'
            }
          },
          _react2.default.createElement(
            'span',
            null,
            ' ',
            electricDataStr
          )
        )
      ) : _react2.default.createElement(
        'div',
        {
          style: {
            lineHeight: '25px'
          }
        },
        electricDataStr
      );
    }
    if (!acc[index]) {
      acc[index] = DEFAULT_STRING;
    }
    return acc;
  }, []);

  var renderRow = dataStrings.some(function (str) {
    return str !== DEFAULT_STRING;
  });
  return _react2.default.createElement(
    _react.Fragment,
    null,
    renderRow && _react2.default.createElement(
      _Shared.TableRow,
      { fontSize: infoFont && infoFont.fontSize },
      _react2.default.createElement(
        _Shared.TableData,
        { verticalAlign: 'top' },
        _react2.default.createElement(_Shared.RenderRow, {
          content: translations.consumptionDataTitle,
          font: infoFont
        })
      ),
      dataStrings.map(function (str, index) {
        return _react2.default.createElement(
          _Shared.TableData,
          {
            verticalAlign: 'top',
            key: str + '-' + index,
            style: {
              paddingBottom: '6px',
              paddingTop: '6px'
            }
          },
          _react2.default.createElement(_Shared.RenderRow, { content: str, font: infoFont })
        );
      })
    )
  );
};

exports.default = KiaConsumptionEmissionsComponent;