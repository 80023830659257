'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 50px 5%;\n    margin: 63px 24px;\n  '], ['\n    padding: 50px 5%;\n    margin: 63px 24px;\n  ']);
/* eslint-disable jsx-a11y/anchor-is-valid */


exports.default = EmailVerification;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Link = require('./Link');

var _Link2 = _interopRequireDefault(_Link);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'EmailVerification__Container',
  componentId: 'sc-4sk4a6-0'
})(['background-color:#f5f5f5;']);
var Box = _styledComponents2.default.div.withConfig({
  displayName: 'EmailVerification__Box',
  componentId: 'sc-4sk4a6-1'
})(['width:auto;margin:120px 332px;background-color:#fff;border:1px solid #d8d8d8;text-align:center;padding:40px 150px;', ';'], _theme2.default.max.extraLarge(_templateObject));
var Header = _styledComponents2.default.div.withConfig({
  displayName: 'EmailVerification__Header',
  componentId: 'sc-4sk4a6-2'
})(['font-size:20px;font-weight:600;margin-bottom:20px;']);
var Content = _styledComponents2.default.div.withConfig({
  displayName: 'EmailVerification__Content',
  componentId: 'sc-4sk4a6-3'
})(['font-size:16px;line-height:25px;color:#444;']);
var Email = _styledComponents2.default.div.withConfig({
  displayName: 'EmailVerification__Email',
  componentId: 'sc-4sk4a6-4'
})(['font-size:16px;font-weight:600;color:#444;margin:20px 0;']);

function EmailVerification(_ref) {
  var email = _ref.email,
      goToPage = _ref.goToPage,
      translations = _ref.translations,
      globalStyling = _ref.globalStyling;

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Box,
      null,
      _react2.default.createElement(
        Header,
        null,
        translations.emailVerifyHeader
      ),
      _react2.default.createElement(
        Content,
        null,
        translations.emailVerifyEmailSent
      ),
      _react2.default.createElement(
        Email,
        null,
        email
      ),
      goToPage && _react2.default.createElement(
        Content,
        null,
        translations.emailVerifyNavigationText,
        _react2.default.createElement(
          _Link2.default,
          {
            href: '',
            onClick: goToPage(),
            theme: {
              linkColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
            }
          },
          translations.emailVerifyNavigationTextLink
        )
      )
    )
  );
}