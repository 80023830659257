'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      font-size: 12px;\n      background-position-y: 0px;\n  '], ['\n      font-size: 12px;\n      background-position-y: 0px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      font-size: 14px;\n      background-position-y: 1px;\n  '], ['\n      font-size: 14px;\n      background-position-y: 1px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n      font-size: 16px;\n      background-position-y: 2px;\n  '], ['\n      font-size: 16px;\n      background-position-y: 2px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    background-image: url(\'https://res.cloudinary.com/motortrak/image/upload/c_scale,co_rgb:FFFFFF,e_colorize:100,f_png,h_32,w_32/locator/genesis/icons/down_chev.png\');\n    color: #fff;\n    margin-top: 20px;\n    width: 100px;\n  '], ['\n    background-image: url(\'https://res.cloudinary.com/motortrak/image/upload/c_scale,co_rgb:FFFFFF,e_colorize:100,f_png,h_32,w_32/locator/genesis/icons/down_chev.png\');\n    color: #fff;\n    margin-top: 20px;\n    width: 100px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _actions = require('../../../actions');

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _settings = require('../../../shared/selectors/settings');

var _sessionPreferences = require('../../../shared/sessionPreferences');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'LanguagePicklist__Select',
  componentId: 'sc-gy72uc-0'
})(['appearance:none;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:16px;color:#999;background:transparent;outline:transparent;border:transparent;background-image:url(\'https://res.cloudinary.com/motortrak/image/upload/c_scale,co_rgb:999999,e_colorize:100,f_png,h_32,w_32/locator/genesis/icons/down_chev.png\');background-repeat:no-repeat;background-position-x:100%;background-position-y:2px;background-size:10px;padding-right:16px;padding-left:0;', ';', ';', ';', ''], _Genesis2.default.min.medium(_templateObject), _Genesis2.default.min.large(_templateObject2), _Genesis2.default.min.extraLarge(_templateObject3), _Genesis2.default.max.medium(_templateObject4));

var LanguagePicklist = function LanguagePicklist() {
  var dispatch = (0, _reactRedux.useDispatch)();
  // Gets languages list from config project.
  var languages = (0, _reactRedux.useSelector)(_settings.getBrandLanguages);
  var languageSelected = (0, _reactRedux.useSelector)(_settings.getSelectedLanguage);

  var _onChange = function _onChange(selectedLanguage) {
    dispatch(_actions.language.actions.changeLanguage(selectedLanguage));
    dispatch(_sessionPreferences.actions.updateSessionPreferences('language', selectedLanguage));
  };

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    languages.length > 1 && _react2.default.createElement(
      Select,
      {
        'data-cy': 'language-selector',
        value: languageSelected,
        onChange: function onChange(event) {
          return _onChange(event.currentTarget.value);
        }
      },
      languages.map(function (language, index) {
        return _react2.default.createElement(
          'option',
          {
            key: index,
            value: language.value,
            style: { fontFamily: 'GenesisSansTextGlobal-Regular' }
          },
          language.label
        );
      })
    )
  );
};

exports.default = LanguagePicklist;