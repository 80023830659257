'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _modelTypes = require('../../../types/RollsRoyce/modelTypes');

var _DragSlider = require('../../DragSlider');

var _ModelItem = require('./ModelItem');

var _ModelItem2 = _interopRequireDefault(_ModelItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ModelVariantTile = function ModelVariantTile(_ref) {
  var section = _ref.section,
      config = _ref.config;

  var isMobile = (0, _useCheckIsMobileScreen2.default)('large');

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      _DragSlider.DragSlider,
      {
        animate: { x: [0, -50, 0] },
        dragElastic: 0.05,
        transition: {
          type: 'spring',
          repeat: Infinity,
          ease: 'easeInOut',
          duration: 2,
          delay: 2,
          repeatDelay: 5
        },
        styles: {
          gap: !isMobile ? '34px' : '10px',
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%'
        }
      },
      section.tiles.map(function (tile) {
        return _react2.default.createElement(_ModelItem2.default, {
          key: tile.tileId,
          translations: config.translations,
          config: config,
          tile: tile
        });
      })
    )
  );
};

exports.default = ModelVariantTile;