'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegionCheckboxGroup = exports.RegionCheckbox = undefined;

var _RegionCheckbox = require('./RegionCheckbox');

var _RegionCheckbox2 = _interopRequireDefault(_RegionCheckbox);

var _RegionCheckboxGroup = require('./RegionCheckboxGroup');

var _RegionCheckboxGroup2 = _interopRequireDefault(_RegionCheckboxGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.RegionCheckbox = _RegionCheckbox2.default;
exports.RegionCheckboxGroup = _RegionCheckboxGroup2.default;