'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FinanceExample = require('./FinanceExample');

var _FinanceExample2 = _interopRequireDefault(_FinanceExample);

var _Plus = require('./JaguarIcons/Global/Plus');

var _Plus2 = _interopRequireDefault(_Plus);

var _Minus = require('./JaguarIcons/Global/Minus');

var _Minus2 = _interopRequireDefault(_Minus);

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'RepresentativeExample__Container',
  componentId: 'sc-1hiir2p-0'
})(['display:flex;flex-direction:column;margin:', ';'], function (_ref) {
  var margin = _ref.margin;
  return margin ? '' + margin : '16px 10px';
});

var Toggle = _styledComponents2.default.div.withConfig({
  displayName: 'RepresentativeExample__Toggle',
  componentId: 'sc-1hiir2p-1'
})(['display:flex;flex-direction:row;justify-content:space-between;cursor:pointer;']);

var Body = _styledComponents2.default.span.withConfig({
  displayName: 'RepresentativeExample__Body',
  componentId: 'sc-1hiir2p-2'
})(['color:', ';margin:', ';'], function (_ref2) {
  var config = _ref2.config;
  return config.fontColour ? '' + config.fontColour.value : '#7e7e7e';
}, function (_ref3) {
  var margin = _ref3.margin;
  return margin ? '' + margin : '27px 0';
});

var BodyText = _styledComponents2.default.div.withConfig({
  displayName: 'RepresentativeExample__BodyText',
  componentId: 'sc-1hiir2p-3'
})(['', ';font-size:', ';margin-bottom:27px;'], function (_ref4) {
  var fontStyles = _ref4.fontStyles;
  return fontStyles.typeface && 'font-family: ' + fontStyles.typeface.value + ';';
}, function (_ref5) {
  var fontStyles = _ref5.fontStyles;
  return fontStyles.fontSize ? fontStyles.fontSize + 'px;' : '16px';
});

var PlusIcon = _styledComponents2.default.span.withConfig({
  displayName: 'RepresentativeExample__PlusIcon',
  componentId: 'sc-1hiir2p-4'
})(['', ';', ';', ';'], function (_ref6) {
  var iconFont = _ref6.iconFont;
  return iconFont.fontSize && 'font-size: ' + iconFont.fontSize + 'px';
}, function (_ref7) {
  var iconFont = _ref7.iconFont;
  return iconFont.lineHeight && 'line-height: ' + iconFont.lineHeight + 'px';
}, function (_ref8) {
  var iconColour = _ref8.iconColour;
  return iconColour.value && 'color: ' + iconColour.value;
});

var MinusIcon = _styledComponents2.default.span.withConfig({
  displayName: 'RepresentativeExample__MinusIcon',
  componentId: 'sc-1hiir2p-5'
})(['', ';', ';', ';'], function (_ref9) {
  var iconFont = _ref9.iconFont;
  return iconFont.fontSize && 'font-size: ' + iconFont.fontSize + 'px';
}, function (_ref10) {
  var iconFont = _ref10.iconFont;
  return iconFont.lineHeight && 'line-height: ' + iconFont.lineHeight + 'px';
}, function (_ref11) {
  var iconColour = _ref11.iconColour;
  return iconColour.value && 'color: ' + iconColour.value;
});

var RepresentativeExample = function (_Component) {
  (0, _inherits3.default)(RepresentativeExample, _Component);

  function RepresentativeExample(props) {
    (0, _classCallCheck3.default)(this, RepresentativeExample);

    var _this = (0, _possibleConstructorReturn3.default)(this, (RepresentativeExample.__proto__ || (0, _getPrototypeOf2.default)(RepresentativeExample)).call(this, props));

    Object.defineProperty(_this, 'toggle', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (!_this.props.preview) {
          _this.setState(function (prevState) {
            return {
              open: !prevState.open
            };
          });
        }
      }
    });


    _this.state = {
      open: props.finance.repexDefaultState === 'open'
    };
    return _this;
  }

  (0, _createClass3.default)(RepresentativeExample, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          representativeExample = _props.representativeExample,
          config = _props.config,
          props = (0, _objectWithoutProperties3.default)(_props, ['representativeExample', 'config']);

      var defaultTitleFont = { fontSize: 16 };

      var _ref12 = config || {},
          _ref12$optionalIcons = _ref12.optionalIcons,
          optionalIcons = _ref12$optionalIcons === undefined ? '' : _ref12$optionalIcons,
          _ref12$iconFont = _ref12.iconFont,
          iconFont = _ref12$iconFont === undefined ? '' : _ref12$iconFont,
          _ref12$iconColour = _ref12.iconColour,
          iconColour = _ref12$iconColour === undefined ? '' : _ref12$iconColour,
          _ref12$titleFont = _ref12.titleFont,
          titleFont = _ref12$titleFont === undefined ? '' : _ref12$titleFont;

      return _react2.default.createElement(
        Container,
        { 'data-cy': 'finance-example', margin: config.containerMargin },
        _react2.default.createElement(
          Toggle,
          { onClick: this.toggle },
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return '\n              ' + (0, _Global.fontStyleOverride)(config && config.titleFont || defaultTitleFont) + '\n              ' + (titleFont && 'font-family: ' + titleFont.typeface) + ';\n              ';
              }
            },
            props.translateFinanceTemplate('financeMyDealTitle', {
              PRODUCT: props.finance.defaultProduct
            })
          ),
          this.state.open ? _react2.default.createElement(
            _react.Fragment,
            null,
            optionalIcons ? _react2.default.createElement(
              MinusIcon,
              { iconFont: iconFont, iconColour: iconColour },
              '-'
            ) : _react2.default.createElement(_Minus2.default, null)
          ) : _react2.default.createElement(
            _react.Fragment,
            null,
            optionalIcons ? _react2.default.createElement(
              PlusIcon,
              { iconFont: iconFont, iconColour: iconColour },
              '+'
            ) : _react2.default.createElement(_Plus2.default, null)
          )
        ),
        this.state.open && representativeExample && _react2.default.createElement(
          Body,
          { config: config, margin: config.bodyMargin },
          _react2.default.createElement(
            BodyText,
            { fontStyles: config && config.bodyFont },
            props.translateFinanceTemplate('financeAccordionSubtitle', {
              MODEL: representativeExample.model,
              DURATION: representativeExample.numberOfPayments
            })
          ),
          _react2.default.createElement(_FinanceExample2.default, (0, _extends3.default)({}, props, {
            data: representativeExample,
            labelFontOverride: config && config.infoLabelFont,
            valueFontOverride: config && config.infoValueFont,
            financePricingTable: config.financePricingTable
          }))
        )
      );
    }
  }]);
  return RepresentativeExample;
}(_react.Component);

exports.default = RepresentativeExample;