'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  background-color:white;\n  margin-top: 1%;\n  ', '\n'], ['\n  background-color:white;\n  margin-top: 1%;\n  ', '\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n    width:32%;\n    margin-right: 1%;\n  '], ['\n    display: flex;\n    flex-direction: column;\n    width:32%;\n    margin-right: 1%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 auto;\n  padding:0 5%;\n'], ['\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 auto;\n  padding:0 5%;\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n', ';\nheight: 236px;\nbackground-repeat:no-repeat;\nbackground-size:cover;\nbackground-position:center;\n'], ['\n', ';\nheight: 236px;\nbackground-repeat:no-repeat;\nbackground-size:cover;\nbackground-position:center;\n']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: #444444;\n  font-size: 16px;\n  margin: 2% 0;\n  font-weight:600;\n'], ['\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: #444444;\n  font-size: 16px;\n  margin: 2% 0;\n  font-weight:600;\n']);
/* eslint-disable jsx-a11y/anchor-is-valid */


exports.default = BudgetResultTile;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ThinRight = require('../JaguarIcons/Arrows/ThinRight');

var _ThinRight2 = _interopRequireDefault(_ThinRight);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

var _numbers = require('../../shared/localisation/numbers');

var _FinancePopup = require('../FinancePopup');

var _FinancePopup2 = _interopRequireDefault(_FinancePopup);

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var namedDiv = function namedDiv(displayName) {
  return _styledComponents2.default.div.withConfig({ displayName: displayName });
};

var BudgetResultTileItemContainer = namedDiv('BudgetResultTileItemContainer')(_templateObject, _theme2.default.min.large(_templateObject2));

var BudgetResultSummaryInformation = namedDiv('BudgetResultSummaryInformation')(_templateObject3);

var ImageContainer = namedDiv('ImageContainer')(_templateObject4, function (_ref) {
  var url = _ref.url;
  return '' + (url && 'background-image: url(' + url + ')');
});

var FinancialInformationText = namedDiv('FinancialInformationText')(_templateObject5);

var Link = _styledComponents2.default.div.withConfig({
  displayName: 'Link'
}).withConfig({
  displayName: 'BudgetResultTile__Link',
  componentId: 'sc-ba8fzu-0'
})(['display:flex;justify-content:space-between;align-items:center;color:#444444;height:43px;background-color:transparent;font-weight:600;text-decoration:none;border-top:1px solid #dedede;border-bottom:1px solid #dedede;width:100%;font-size:13px;cursor:pointer;font-family:inherit;letter-spacing:0.1em;font-size:15px;line-height:21px;margin-bottom:3%;']);

var SubTitle = _styledComponents2.default.div.withConfig({
  displayName: 'SubTitle'
}).withConfig({
  displayName: 'BudgetResultTile__SubTitle',
  componentId: 'sc-ba8fzu-1'
})(['color:#444444;font-size:16px;font-weight:600;line-height:24px;margin:2% 0;']);

var Body = _styledComponents2.default.div.withConfig({
  displayName: 'Body'
}).withConfig({
  displayName: 'BudgetResultTile__Body',
  componentId: 'sc-ba8fzu-2'
})(['color:#444444;font-size:15px;line-height:21px;margin-top:2%;margin-bottom:5%;min-height:60px;']);

var AdditionalInfo = _styledComponents2.default.div.withConfig({
  displayName: 'AdditionalInfo'
}).withConfig({
  displayName: 'BudgetResultTile__AdditionalInfo',
  componentId: 'sc-ba8fzu-3'
})(['display:flex;flex:1 0 auto;flex-direction:column;justify-content:flex-end;align-items:flex-start;']);

var BottomText = _styledComponents2.default.div.withConfig({
  displayName: 'BottomText'
}).withConfig({
  displayName: 'BudgetResultTile__BottomText',
  componentId: 'sc-ba8fzu-4'
})(['color:#444444;font-size:16px;font-weight:600;line-height:19px;margin-top:10%;margin-bottom:5%;']);

var HorizontalLine = _styledComponents2.default.hr.withConfig({
  displayName: 'HorizontalLine'
}).withConfig({
  displayName: 'BudgetResultTile__HorizontalLine',
  componentId: 'sc-ba8fzu-5'
})(['display:block;height:1px;border:0;border-top:1px solid #dedede;margin:0;padding:0;justify-content:center;']);

function BudgetResultTile(_ref2) {
  var translations = _ref2.translations,
      result = _ref2.result,
      onViewNowClick = _ref2.onViewNowClick,
      onMoreInfoClick = _ref2.onMoreInfoClick,
      preview = _ref2.preview,
      placeholdingImage = _ref2.placeholdingImage,
      globalStyling = _ref2.globalStyling,
      buttonStyle = _ref2.buttonStyle,
      locale = _ref2.locale;

  return _react2.default.createElement(
    BudgetResultTileItemContainer,
    null,
    result.imageUrl ? _react2.default.createElement(ImageContainer, { url: result.imageUrl }) : _react2.default.createElement(ImageContainer, { url: placeholdingImage }),
    _react2.default.createElement(
      BudgetResultSummaryInformation,
      null,
      _react2.default.createElement(
        _Global.HeadingOne,
        {
          styleOverride: function styleOverride() {
            return '\n            font-size: 22px;\n            margin-top: 5%;\n          ';
          }
        },
        result.model
      ),
      _react2.default.createElement(
        SubTitle,
        null,
        (0, _translateFromTemplate.translateFromTemplate)('priceFrom', {
          PRICE: (0, _numbers.localiseCurrency)(result.minimumPrice, locale, 'EUR', 0)
        }, translations)
      ),
      result.finance && _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(HorizontalLine, null),
        _react2.default.createElement(
          FinancialInformationText,
          null,
          (0, _translateFromTemplate.translateFromTemplate)('financeFrom', {
            FINANCEPRICE: (0, _numbers.localiseCurrency)(parseFloat(result.financePrice.replace('.', '').replace(',', '.')), locale, 'EUR'),
            APR: result.apr
          }, translations),
          _react2.default.createElement(_FinancePopup2.default, {
            translations: translations,
            data: result.finance,
            locale: locale
          })
        )
      ),
      _react2.default.createElement(HorizontalLine, null),
      _react2.default.createElement(
        Body,
        null,
        result.description
      ),
      _react2.default.createElement(
        AdditionalInfo,
        null,
        _react2.default.createElement(
          Link,
          {
            onClick: function onClick(e) {
              if (preview) {
                e.preventDefault();
              } else {
                onMoreInfoClick(result.model);
              }
            }
          },
          translations.learnMoreButtonText,
          _react2.default.createElement(_ThinRight2.default, {
            colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
          })
        ),
        _react2.default.createElement(
          BottomText,
          null,
          (0, _translateFromTemplate.translateFromTemplate)(result.count >= 2 ? 'numberAvailable' : 'numberAvailableSingle', {
            COUNT: result.count
          }, translations)
        ),
        _react2.default.createElement(_Global.Button, {
          onClick: function onClick() {
            return onViewNowClick(result.model);
          },
          text: translations.viewButtonText,
          applyStyle: 'primary',
          buttonStyle: buttonStyle,
          styleOverride: function styleOverride() {
            return '\n              margin-bottom: 20px;\n            ';
          },
          mobileStyleOverride: function mobileStyleOverride() {
            return '\n              margin-bottom: 20px;\n              ';
          }
        })
      )
    )
  );
}