'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  display: none;\n'], ['\n  display: none;\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n  max-width: 80%;\n '], ['\n  max-width: 80%;\n ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  width: 100%;\n '], ['\n  width: 100%;\n ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n  padding: 2px 0;\n '], ['\n  padding: 2px 0;\n ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n  padding-top: 20px;\n  font-size: 16px;\n '], ['\n  padding-top: 20px;\n  font-size: 16px;\n ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 72px;\n '], ['\n  font-size: 72px;\n ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n  padding-top: 16px;\n  font-size: 16px;\n '], ['\n  padding-top: 16px;\n  font-size: 16px;\n ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpTechSpec__Container',
  componentId: 'sc-1qq0iph-0'
})(['display:flex;flex-direction:column;width:100%;padding-top:5%;padding-bottom:5%;background-color:#efede9;']);

var TechSpecHeader = _styledComponents2.default.h2.withConfig({
  displayName: 'VdpTechSpec__TechSpecHeader',
  componentId: 'sc-1qq0iph-1'
})(['height:22px;width:172px;color:#000000;font-family:AstonMartinFlare-Medium;font-size:16px;font-weight:500;letter-spacing:0;line-height:22px;margin:0 0 60px 118px;', ';'], _theme2.default.max.medium(_templateObject));

var TechSpecContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpTechSpec__TechSpecContainer',
  componentId: 'sc-1qq0iph-2'
})(['display:flex;background-color:#efede9;margin:15px auto;width:95%;', ';'], _theme2.default.min.large(_templateObject2));

var Table = _styledComponents2.default.div.withConfig({
  displayName: 'VdpTechSpec__Table',
  componentId: 'sc-1qq0iph-3'
})(['display:flex;flex-direction:column;width:50%;margin:5px;margin-left:5%;justify-content:space-evenly;', ''], _theme2.default.max.small(_templateObject3));

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'VdpTechSpec__Row',
  componentId: 'sc-1qq0iph-4'
})(['display:flex;justify-content:flex-end;border-bottom:0.5px solid #000000;padding:20px 0;', ';'], _theme2.default.min.large(_templateObject4));

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'VdpTechSpec__Label',
  componentId: 'sc-1qq0iph-5'
})(['font-size:14px;text-transform:uppercase;font-family:AstonMartinFlare-Regular;width:35%;margin-right:auto;padding-top:5px;', ';'], _theme2.default.min.large(_templateObject5));

var ValueContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpTechSpec__ValueContainer',
  componentId: 'sc-1qq0iph-6'
})(['display:flex;']);

var Value = _styledComponents2.default.div.withConfig({
  displayName: 'VdpTechSpec__Value',
  componentId: 'sc-1qq0iph-7'
})(['font-family:AstonMartinSans-Medium;font-size:36px;display:flex;justify-content:right;padding-left:15px;', ';'], _theme2.default.min.large(_templateObject6));

var Unit = _styledComponents2.default.div.withConfig({
  displayName: 'VdpTechSpec__Unit',
  componentId: 'sc-1qq0iph-8'
})(['margin-left:5px;font-family:AstonMartinFlare-Regular;font-size:14px;min-width:34px;padding-top:3px;', ';'], _theme2.default.min.large(_templateObject7));

var StyledImage = _styledComponents2.default.img.withConfig({
  displayName: 'VdpTechSpec__StyledImage',
  componentId: 'sc-1qq0iph-9'
})(['width:50%;object-fit:contain;', ';'], _theme2.default.max.small(_templateObject));

var VdpTechSpec = function VdpTechSpec(props) {
  var data = props.data,
      translations = props.translations;
  var powerBHP = data.powerBHP,
      powerKW = data.powerKW,
      maxSpeedMPH = data.maxSpeedMPH,
      maxSpeedKPH = data.maxSpeedKPH,
      accelerationZeroToHundredKPH = data.accelerationZeroToHundredKPH,
      torqueNM = data.torqueNM,
      torqueLBFT = data.torqueLBFT;


  var rows = [{
    label: translations.power,
    value1: powerBHP,
    unit1: translations.unitsBhp,
    value2: powerKW,
    unit2: translations.unitsKw
  }, {
    label: translations.topSpeed,
    value1: maxSpeedMPH,
    unit1: translations.unitsMph,
    value2: maxSpeedKPH,
    unit2: translations.unitsKmh
  }, {
    label: translations.acceleration,
    value1: accelerationZeroToHundredKPH,
    unit1: translations.unitsSecs
  }, {
    label: translations.torque,
    value1: torqueNM,
    unit1: translations.unitsNm,
    value2: torqueLBFT,
    unit2: translations.unitsLBFT
  }];

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        TechSpecHeader,
        null,
        translations.technicalSpecification
      ),
      _react2.default.createElement(
        TechSpecContainer,
        null,
        _react2.default.createElement(StyledImage, {
          src: 'https://res.cloudinary.com/motortrak/q_auto:eco/v1666797832/locator/aston-martin/global/aston-techspec.jpg',
          alt: 'aston tech spec'
        }),
        _react2.default.createElement(
          Table,
          null,
          rows.map(function (_ref) {
            var label = _ref.label,
                value1 = _ref.value1,
                value2 = _ref.value2,
                unit1 = _ref.unit1,
                unit2 = _ref.unit2;

            return _react2.default.createElement(
              Row,
              { key: label },
              _react2.default.createElement(
                Label,
                null,
                label
              ),
              value2 ? _react2.default.createElement(
                ValueContainer,
                null,
                _react2.default.createElement(
                  Value,
                  null,
                  value1 || '-'
                ),
                _react2.default.createElement(
                  Unit,
                  null,
                  unit1
                ),
                _react2.default.createElement(
                  Value,
                  null,
                  value2
                ),
                _react2.default.createElement(
                  Unit,
                  null,
                  unit2
                )
              ) : _react2.default.createElement(
                ValueContainer,
                null,
                _react2.default.createElement(
                  Value,
                  null,
                  value1 || '-'
                ),
                _react2.default.createElement(
                  Unit,
                  null,
                  unit1
                )
              )
            );
          })
        )
      )
    )
  );
};

exports.default = VdpTechSpec;