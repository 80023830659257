'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']);

exports.default = VdpHeader;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledHeader = _styledComponents2.default.div.withConfig({
  displayName: 'VdpHeader__StyledHeader',
  componentId: 'sc-12e7bvp-0'
})(['font-size:20px;text-transform:uppercase;margin-bottom:35px;letter-spacing:3px;', ';', ';'], function (_ref) {
  var headerFont = _ref.headerFont;
  return headerFont && (0, _Global.fontStyleOverride)(headerFont);
}, _theme2.default.max.medium(_templateObject, function (_ref2) {
  var mobileStyleOverride = _ref2.mobileStyleOverride;
  return (0, _Global.fontStyleOverride)(mobileStyleOverride);
}));

function VdpHeader(_ref3) {
  var children = _ref3.children,
      qaHook = _ref3.qaHook,
      styles = _ref3.styles;
  var headerFont = styles.headerFont;

  return _react2.default.createElement(
    StyledHeader,
    {
      'data-cy': qaHook,
      headerFont: headerFont,
      mobileStyleOverride: headerFont.mobile
    },
    children
  );
}
VdpHeader.defaultProps = {
  qaHook: undefined,
  styles: {}
};