'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-basis: 48%;\n    margin-right: ', ';\n  '], ['\n    flex-basis: 48%;\n    margin-right: ', ';\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-basis: ', '\n    margin-right: ', ';\n  '], ['\n    flex-basis: ', '\n    margin-right: ', ';\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    flex-basis: 100%;\n  '], ['\n    flex-basis: 100%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 20px!important;\n  '], ['\n    padding: 0 20px!important;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n                      font-size: 13px;\n                    '], ['\n                      font-size: 13px;\n                    ']);
/* eslint-disable no-shadow, max-len */


exports.default = SearchResultTile;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Pin = require('./JaguarIcons/Global/Pin');

var _Pin2 = _interopRequireDefault(_Pin);

var _Co = require('./JaguarIcons/Global/Co2');

var _Co2 = _interopRequireDefault(_Co);

var _Global = require('./Global');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _numbers = require('../shared/localisation/numbers');

var _dates = require('../shared/localisation/dates');

var _FinancePopup = require('./FinancePopup');

var _FinancePopup2 = _interopRequireDefault(_FinancePopup);

var _WarrantyBadge = require('./WarrantyBadge');

var _WarrantyBadge2 = _interopRequireDefault(_WarrantyBadge);

var _OnDemandBadge = require('./OnDemandBadge');

var _OnDemandBadge2 = _interopRequireDefault(_OnDemandBadge);

var _OfferTermsPopup = require('./OfferTermsPopup');

var _OfferTermsPopup2 = _interopRequireDefault(_OfferTermsPopup);

var _vehicle = require('../helpers/vehicle');

var _getWarrantyLabel = require('../helpers/getWarrantyLabel');

var _helpers = require('../shared/currencyConversion/helpers');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _Spinner = require('./Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _SearchCo2Accordian = require('./SearchCo2Accordian');

var _SearchCo2Accordian2 = _interopRequireDefault(_SearchCo2Accordian);

var _SearchResultEmissions = require('./SearchResultEmissions');

var _SearchResultEmissions2 = _interopRequireDefault(_SearchResultEmissions);

var _vehicleTypes = require('../types/AstonMartin/vehicleTypes');

var _emissionSrpText = require('../components/Kia/emissionSrpText');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TileItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__TileItemContainer',
  componentId: 'sc-19ycrzk-0'
})(['', ';display:flex;flex-direction:column;flex-basis:48%;max-width:100%;border:1px solid #cccccc;margin:20px 0 0;margin-right:', ';', ';', ';', ';', ';'], function (_ref) {
  var backgroundColour = _ref.backgroundColour;
  return backgroundColour && 'background-color: ' + backgroundColour.value;
}, function (_ref2) {
  var checkLength = _ref2.checkLength;
  return checkLength ? '10px' : 'unset';
}, _theme2.default.max.medium(_templateObject, function (_ref3) {
  var checkLength = _ref3.checkLength;
  return checkLength ? '10px' : 'unset';
}), _theme2.default.min.large(_templateObject2, function (props) {
  return props.filtersExpanded ? '48%' : '32%';
}, function (_ref4) {
  var checkLength = _ref4.checkLength;
  return checkLength ? '10px' : 'unset';
}), _theme2.default.max.small(_templateObject3), _theme2.default.max.large(_templateObject4, function (props) {
  return props.inSimilarVehicles && 'width: 100%;\n      display: ' + (props.mobileSelectedForSimVehicle ? 'flex' : 'none') + '\n      margin: auto;\n      ';
}));

var TopContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__TopContainer',
  componentId: 'sc-19ycrzk-1'
})(['background:', ';display:flex;justify-content:space-between;align-items:flex-start;padding:15px 12px;'], function (_ref5) {
  var backgroundColour = _ref5.backgroundColour;
  return backgroundColour ? '' + backgroundColour.value : 'linear-gradient(0deg, rgba(238, 238, 238, 0) 0%, #eeeeee 100%);';
});

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__ImageContainer',
  componentId: 'sc-19ycrzk-2'
})(['position:relative;overflow:hidden;display:inline-block;&:hover{cursor:pointer;}']);

var ApprovedImage = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultTile__ApprovedImage',
  componentId: 'sc-19ycrzk-3'
})(['width:35%;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultTile__Image',
  componentId: 'sc-19ycrzk-4'
})(['display:flex;align-self:center;max-width:100%;width:100%;']);

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__ImageOverlay',
  componentId: 'sc-19ycrzk-5'
})(['justify-content:center;align-items:center;display:flex;flex-direction:column;position:absolute;top:0;left:0;right:0;bottom:0;color:#ffffff;background-color:rgb(0,0,0,0.5);cursor:default;']);

var BottomContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__BottomContainer',
  componentId: 'sc-19ycrzk-6'
})(['display:flex;flex-direction:column;padding:', ';'], function (_ref6) {
  var padding = _ref6.padding;
  return padding || '0em 1em 0 1em';
});

var HorizontalLine = _styledComponents2.default.hr.withConfig({
  displayName: 'SearchResultTile__HorizontalLine',
  componentId: 'sc-19ycrzk-7'
})(['display:', ';height:1px;border:0;border-top:', ';margin:0;padding:0;justify-content:center;'], function (_ref7) {
  var visible = _ref7.visible;
  return visible === true ? 'block' : 'none';
}, function (_ref8) {
  var showRightBorder = _ref8.showRightBorder;
  return showRightBorder === true ? '1px solid #dedede' : '0';
});

var VerticalLine = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__VerticalLine',
  componentId: 'sc-19ycrzk-8'
})(['display:', ';border-left:', ';height:inherit;'], function (_ref9) {
  var visible = _ref9.visible;
  return visible === true ? 'flex' : 'none';
}, function (_ref10) {
  var showRightBorder = _ref10.showRightBorder;
  return showRightBorder === true ? '1px solid #dedede' : '0';
});

var IconRow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__IconRow',
  componentId: 'sc-19ycrzk-9'
})(['display:flex;align-items:center;font-size:15px;padding:', ';', ';'], function (_ref11) {
  var vehicleSpecPadding = _ref11.vehicleSpecPadding;
  return vehicleSpecPadding ? '10px 20px' : '10px 5px';
}, function (_ref12) {
  var gridBorder = _ref12.gridBorder;
  return gridBorder && 'border-top: 1px solid #cccccc';
});

var IconRowImg = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultTile__IconRowImg',
  componentId: 'sc-19ycrzk-10'
})(['padding-right:12px;']);

var MoreInfoRow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__MoreInfoRow',
  componentId: 'sc-19ycrzk-11'
})(['display:flex;align-items:center;justify-content:space-between;', ';'], function (_ref13) {
  var actionRowStyles = _ref13.actionRowStyles;
  return actionRowStyles && actionRowStyles.gridViewPadding && 'padding: ' + actionRowStyles.gridViewPadding;
});

var IconText = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__IconText',
  componentId: 'sc-19ycrzk-12'
})(['color:', ';font-size:', ';display:flex;align-items:center;width:100%;'], function (_ref14) {
  var valueFontStyle = _ref14.valueFontStyle;
  return valueFontStyle ? valueFontStyle.color : '#444444;';
}, function (_ref15) {
  var valueFontStyle = _ref15.valueFontStyle;
  return valueFontStyle ? valueFontStyle.fontSize : '15px';
});

var FinanceCol = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__FinanceCol',
  componentId: 'sc-19ycrzk-13'
})(['display:flex;flex-direction:column;justify-content:', ';width:70%;&:last-child{width:40%;}'], function (_ref16) {
  var noFinance = _ref16.noFinance;
  return noFinance ? 'initial' : 'center';
});

var FinanceRow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__FinanceRow',
  componentId: 'sc-19ycrzk-14'
})(['display:flex;justify-content:', ';max-width:100%;margin-bottom:', ';min-height:', ';'], function (_ref17) {
  var noSpaceEvenly = _ref17.noSpaceEvenly;
  return noSpaceEvenly ? '' : 'space-evenly';
}, function (_ref18) {
  var noMarginBottom = _ref18.noMarginBottom;
  return noMarginBottom ? 0 : '0.5em';
}, function (_ref19) {
  var noMinHeight = _ref19.noMinHeight,
      financeCellHeight = _ref19.financeCellHeight;
  return noMinHeight ? 0 : financeCellHeight || '110px';
});

var VatRow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__VatRow',
  componentId: 'sc-19ycrzk-15'
})(['display:flex;justify-content:space-evenly;max-width:100%;']);

var Co2ConsumptionInformationSection = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__Co2ConsumptionInformationSection',
  componentId: 'sc-19ycrzk-16'
})(['border-top:1px solid #dedede;border-bottom:1px solid #dedede;display:flex;margin-top:auto;min-height:26px;padding:10px 0px;']);

var Cell = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__Cell',
  componentId: 'sc-19ycrzk-17'
})(['display:', ';flex-wrap:wrap;justify-content:flex-start;flex-direction:column;align-items:left;'], function (_ref20) {
  var visible = _ref20.visible;
  return visible === true ? 'flex' : 'none';
});

var IconPadding = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__IconPadding',
  componentId: 'sc-19ycrzk-18'
})(['display:flex;height:25px;']);

var IconSpaceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__IconSpaceContainer',
  componentId: 'sc-19ycrzk-19'
})(['display:flex;align-items:center;font-size:15px;height:41px;']);

var DesktopOnly = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__DesktopOnly',
  componentId: 'sc-19ycrzk-20'
})(['display:block;', ';'], _theme2.default.max.large(_templateObject5));

var SoldHeading = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__SoldHeading',
  componentId: 'sc-19ycrzk-21'
})(['font-size:20px;font-weight:600;']);

var SoldBody = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__SoldBody',
  componentId: 'sc-19ycrzk-22'
})(['font-size:14px;max-width:165px;']);

var FinanceCellWithPopup = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__FinanceCellWithPopup',
  componentId: 'sc-19ycrzk-23'
})(['display:flex;align-items:center;display:', ';'], function (_ref21) {
  var visible = _ref21.visible;
  return visible === true ? 'flex' : 'none';
});

var LocationPin = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__LocationPin',
  componentId: 'sc-19ycrzk-24'
})(['display:flex;align-items:center;height:20%;padding:', ';border-top:1px solid #ccc;'], function (_ref22) {
  var paddingLeft = _ref22.paddingLeft;
  return paddingLeft ? '8px 0 8px ' + paddingLeft + 'px' : '8px 0';
});

var ThreeSixty = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__ThreeSixty',
  componentId: 'sc-19ycrzk-25'
})(['background:#000000;opacity:0.4;padding:4px;min-height:34px;position:absolute;z-index:20;top:0;', ';'], function (_ref23) {
  var direction = _ref23.direction;
  return direction === 'rtl' ? 'left: 0;' : 'right: 0;';
});

var YoutubeVideo = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__YoutubeVideo',
  componentId: 'sc-19ycrzk-26'
})(['background:#000000;opacity:0.4;padding:4px;position:absolute;z-index:20;', ';', ';'], function (_ref24) {
  var direction = _ref24.direction;
  return direction === 'rtl' ? 'left: 0;' : 'right: 0;';
}, function (_ref25) {
  var panoramicViews = _ref25.panoramicViews;
  return panoramicViews.exterior || panoramicViews.interior ? 'top: 42px' : 'top: 0';
});

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultTile__Icon',
  componentId: 'sc-19ycrzk-27'
})(['width:30px;height:30px;']);

var FinanceSpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__FinanceSpinnerWrapper',
  componentId: 'sc-19ycrzk-28'
})(['display:flex;flex-direction:row;padding:0 15px;width:100%;margin-top:4px;']);

var FinanceLoadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__FinanceLoadingWrapper',
  componentId: 'sc-19ycrzk-29'
})(['margin:10px;']);

var AvailabilityLabelWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__AvailabilityLabelWrapper',
  componentId: 'sc-19ycrzk-30'
})(['display:flex;flex-direction:column;']);

var AvailabilityLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__AvailabilityLabel',
  componentId: 'sc-19ycrzk-31'
})(['', ';', ';', ''], function (_ref26) {
  var bold = _ref26.bold;
  return bold && 'font-weight: bold;';
}, function (_ref27) {
  var size = _ref27.size;
  return 'font-size: ' + size + 'px;';
}, function (_ref28) {
  var dateTextColor = _ref28.dateTextColor;
  return dateTextColor && 'color: ' + dateTextColor + ';';
});

var EfficiencySummaryImage = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultTile__EfficiencySummaryImage',
  componentId: 'sc-19ycrzk-32'
})(['max-width:80px;padding:2px 10px;margin-left:auto;']);

var CarBasePriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__CarBasePriceContainer',
  componentId: 'sc-19ycrzk-33'
})(['display:flex;justify-content:space-between;margin-bottom:5px;']);

var OtherAmountContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__OtherAmountContainer',
  componentId: 'sc-19ycrzk-34'
})(['display:flex;justify-content:space-between;']);

var EmissionsStringText = (0, _styledComponents2.default)(_Global.Paragraph).withConfig({
  displayName: 'SearchResultTile__EmissionsStringText',
  componentId: 'sc-19ycrzk-35'
})(['margin:0;&:last-child{margin-bottom:10px;}', ';'], _theme2.default.max.xxl(_templateObject6));

function IconSpace(_ref29) {
  var icon = _ref29.icon;

  return icon ? _react2.default.createElement(
    IconSpaceContainer,
    null,
    _react2.default.createElement(
      IconPadding,
      null,
      icon(),
      _react2.default.createElement(VerticalLine, null)
    )
  ) : null;
}

function FinanceCell(_ref30) {
  var vehicle = _ref30.vehicle,
      _ref30$translations = _ref30.translations,
      translations = _ref30$translations === undefined ? {} : _ref30$translations,
      headerText = _ref30.headerText,
      secondaryHeaderText = _ref30.secondaryHeaderText,
      subHeaderText = _ref30.subHeaderText,
      visible = _ref30.visible,
      paddingLeft = _ref30.paddingLeft,
      financeHeadingFont = _ref30.financeHeadingFont,
      financeSubHeadingFont = _ref30.financeSubHeadingFont,
      hideBorders = _ref30.hideBorders,
      priceStyle = _ref30.priceStyle,
      offerPriceStyle = _ref30.offerPriceStyle,
      offerTerms = _ref30.offerTerms,
      specialOfferStyles = _ref30.specialOfferStyles,
      differentialTaxation = _ref30.differentialTaxation;

  return _react2.default.createElement(
    Cell,
    {
      visible: visible,
      paddingLeft: paddingLeft,
      showRightBorder: hideBorders
    },
    _react2.default.createElement(
      'div',
      {
        style: {
          display: 'flex',
          justifyContent: 'flex-start'
        }
      },
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n            width: 100%;\n            ' + (priceStyle ? (0, _Global.fontStyleOverride)(priceStyle) : offerPriceStyle ? (0, _Global.fontStyleOverride)(offerPriceStyle) : (0, _Global.fontStyleOverride)(financeHeadingFont)) + ';\n            font-size: ' + (priceStyle && priceStyle.gridFontSize || offerPriceStyle && offerPriceStyle.gridFontSize) + 'px};\n          ';
          }
        },
        headerText
      ),
      offerTerms && _react2.default.createElement(
        'div',
        { style: { paddingLeft: '6px' } },
        _react2.default.createElement(_OfferTermsPopup2.default, {
          modalTitle: offerTerms.offerTermModalTitle,
          offerDetailsLinkText: offerTerms.offerTermModalLinkText,
          offerTermText: offerTerms.offerTermText,
          offerDetailsUrl: offerTerms.offerDetailsUrl,
          colourValue: offerPriceStyle && offerPriceStyle.colour.value
        })
      )
    ),
    secondaryHeaderText && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n            width: 100%;\n            ' + (0, _Global.fontStyleOverride)(financeHeadingFont) + ';\n          ';
          }
        },
        secondaryHeaderText,
        'Secondary'
      )
    ),
    _react2.default.createElement(
      _Global.HeadingThree,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 14px;\n        color: #999999;\n        ' + (0, _Global.fontStyleOverride)(specialOfferStyles ? specialOfferStyles.offerTextStyle : financeSubHeadingFont) + ';\n        ';
        }
      },
      translations.priceIncludesVat ? (0, _translateFromTemplate.translateFromTemplate)('priceIncludesVat', {
        VALUE: vehicle.retailerCountryCode === 'BE' ? translations.vatRateBelgium : vehicle.retailerCountryCode === 'LU' ? translations.vatRateLuxembourg : ''
      }, translations) : subHeaderText
    ),
    differentialTaxation && _react2.default.createElement(
      _Global.HeadingThree,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 14px;\n        color: #999999;\n        padding-bottom: 0px;\n        ' + (0, _Global.fontStyleOverride)(specialOfferStyles ? specialOfferStyles.offerTextStyle : financeSubHeadingFont) + ';\n        ';
        }
      },
      differentialTaxation
    )
  );
}

var DistanceFromRetailer = function DistanceFromRetailer(_ref31) {
  var carResult = _ref31.carResult,
      actionRowStyles = _ref31.actionRowStyles,
      colour = _ref31.colour;

  var _ref32 = actionRowStyles || {},
      _ref32$locationPinPad = _ref32.locationPinPadding,
      locationPinPadding = _ref32$locationPinPad === undefined ? {} : _ref32$locationPinPad;

  return _react2.default.createElement(
    LocationPin,
    { paddingLeft: locationPinPadding.left },
    _react2.default.createElement(_Pin2.default, { width: '1.5em', height: '1.5em', colour: colour || '#acacac' }),
    _react2.default.createElement(
      'span',
      { style: { color: '#444', fontSize: '12px', marginLeft: '10px' } },
      carResult.distance > 0 && carResult.distance + ' km - ',
      '' + carResult.retailerName
    )
  );
};

var formattedLocale = function formattedLocale(locale) {
  return locale.replace('_', '-');
};

var IconWithInfo = function IconWithInfo(_ref33) {
  var label = _ref33.label,
      value = _ref33.value,
      children = _ref33.children;

  if (!value) return null;
  var Wrapper = _styledComponents2.default.div.withConfig({
    displayName: 'SearchResultTile__Wrapper',
    componentId: 'sc-19ycrzk-36'
  })(['display:flex;align-items:flex-start;box-sizing:border-box;padding-top:5px;']);
  return _react2.default.createElement(
    Wrapper,
    null,
    children,
    _react2.default.createElement(
      'div',
      {
        style: {
          marginLeft: '9px',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between'
        }
      },
      _react2.default.createElement(
        'div',
        { style: { lineHeight: '18px', color: '#545454', fontSize: '12px' } },
        label
      ),
      _react2.default.createElement(
        'div',
        {
          style: {
            lineHeight: '18px',
            color: '#444',
            fontWeight: '600',
            fontSize: '12px',
            marginLeft: '10px',
            textAlign: 'right'
          }
        },
        value
      )
    )
  );
};

var IconSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultTile__IconSectionWrapper',
  componentId: 'sc-19ycrzk-37'
})(['display:flex;flex-direction:column;img{padding-left:2px;max-width:25px;max-height:25px;}']);

var LamborghiniInfoSection = function LamborghiniInfoSection(_ref34) {
  var vehicle = _ref34.vehicle,
      translations = _ref34.translations,
      showApprovedVehicles = _ref34.showApprovedVehicles,
      formattedDate = _ref34.formattedDate,
      availabilityFont = _ref34.availabilityFont,
      emissionsEnabled = _ref34.emissionsEnabled;
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      'div',
      {
        style: {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid #d5d5d5',
          padding: '10px 0',
          marginBottom: '5px',
          flexDirection: 'row-reverse'
        }
      },
      formattedDate && availabilityFont && _react2.default.createElement(
        AvailabilityLabelWrapper,
        null,
        _react2.default.createElement(
          AvailabilityLabel,
          { bold: true, size: availabilityFont.gridTileSize },
          formattedDate
        ),
        _react2.default.createElement(
          AvailabilityLabel,
          {
            size: availabilityFont.gridTileDisclaimerSize,
            dateTextColor: availabilityFont.dateTextColor
          },
          translations.availabilityDate
        ),
        showApprovedVehicles && vehicle.approved && _react2.default.createElement(ApprovedImage, {
          src: vehicle.approvedImage,
          alt: 'approved vehicle image'
        })
      ),
      _react2.default.createElement(
        'div',
        { style: { display: 'flex', flexDirection: 'column' } },
        vehicle && vehicle.retailerCountryCode === 'JP' && !vehicle.priceSpecial && vehicle.basePrice && vehicle.expenses ? _react2.default.createElement(
          'div',
          { style: { display: 'flex', justifyContent: 'space-between' } },
          _react2.default.createElement(
            'div',
            null,
            translations.total
          ),
          _react2.default.createElement(
            'div',
            { style: { fontWeight: '700' } },
            vehicle.price
          )
        ) : _react2.default.createElement(
          'div',
          {
            style: {
              fontSize: '20px',
              fontFamily: 'RobotoBold',
              color: '#1F1F1F',
              textAlign: 'end'
            }
          },
          vehicle.price
        ),
        vehicle && vehicle.retailerCountryCode === 'JP' && !vehicle.priceSpecial && vehicle.basePrice && vehicle.expenses && _react2.default.createElement(
          'div',
          {
            style: {
              fontSize: '12px',
              borderTop: '1px solid rgb(213, 213, 213)',
              paddingTop: '5px',
              marginTop: '5px'
            }
          },
          _react2.default.createElement(
            CarBasePriceContainer,
            null,
            _react2.default.createElement(
              'div',
              { style: { paddingRight: '5px' } },
              translations.carBasePrice
            ),
            _react2.default.createElement(
              'div',
              null,
              vehicle.basePrice
            )
          ),
          _react2.default.createElement(
            OtherAmountContainer,
            null,
            _react2.default.createElement(
              'div',
              { style: { paddingRight: '5px' } },
              translations.otherAmount
            ),
            _react2.default.createElement(
              'div',
              null,
              vehicle.otherExpenses
            )
          )
        ),
        vehicle && vehicle.priceExcludesVat > 0 && _react2.default.createElement(
          'div',
          {
            style: { fontSize: '12px', color: '#545454', textAlign: 'right' }
          },
          translations.priceExcludesVat
        ),
        vehicle.vatQualifyingVehicle > 0 && _react2.default.createElement(
          'div',
          {
            style: { fontSize: '12px', color: '#545454', textAlign: 'right' }
          },
          translations.vatQualifyingVehicle
        ),
        vehicle.sectionSalesTaxDisclaimer === 1 && _react2.default.createElement(
          'div',
          {
            style: { fontSize: '12px', color: '#545454', textAlign: 'right' }
          },
          translations.differentialTaxation
        )
      ),
      showApprovedVehicles && vehicle.approved && !availabilityFont && _react2.default.createElement(ApprovedImage, {
        src: vehicle.approvedImage,
        alt: 'approved vehicle image'
      })
    ),
    _react2.default.createElement(
      IconSectionWrapper,
      null,
      vehicle.registrationDate && _react2.default.createElement(
        IconWithInfo,
        {
          label: translations.registrationDate,
          value: (0, _dates.localeFormattedDate)(vehicle.registrationDate, formattedLocale(vehicle.locale), {
            month: '2-digit',
            year: 'numeric'
          }) + ' '
        },
        _react2.default.createElement('img', {
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278373/locator/lamborghini/content/Reg_Date_2x.png',
          alt: translations.registrationDate
        })
      ),
      vehicle.odometer && _react2.default.createElement(
        IconWithInfo,
        {
          label: translations.searchResultsOdometerLabel,
          value: (0, _numbers.localiseNumber)(vehicle.odometer.reading, vehicle.locale) + ' ' + vehicle.odometer.units
        },
        _react2.default.createElement('img', {
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278364/locator/lamborghini/content/Mileage_2x.png',
          alt: translations.searchResultsOdometerLabel
        })
      ),
      vehicle.registration && _react2.default.createElement(
        IconWithInfo,
        {
          label: translations.registered,
          value: vehicle.registration
        },
        _react2.default.createElement('img', {
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278364/locator/lamborghini/content/Model_Year_2x.png',
          alt: translations.registered
        })
      ),
      vehicle.interior && _react2.default.createElement(
        IconWithInfo,
        { label: translations.interior, value: vehicle.interior },
        _react2.default.createElement('img', {
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278359/locator/lamborghini/content/Interior_2x.png',
          alt: translations.interior
        })
      ),
      vehicle.secondaryInterior && _react2.default.createElement(
        IconWithInfo,
        {
          label: translations.interiorSecondary,
          value: vehicle.secondaryInterior
        },
        _react2.default.createElement('img', {
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278359/locator/lamborghini/content/Interior_2x.png',
          alt: translations.interiorSecondary
        })
      ),
      vehicle.transmission && _react2.default.createElement(
        IconWithInfo,
        {
          label: translations.searchResultsTransmissionLabel,
          value: vehicle.transmission
        },
        _react2.default.createElement('img', {
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278357/locator/lamborghini/content/Transmission.svg',
          alt: translations.searchResultsTransmissionLabel
        })
      )
    ),
    (vehicle.emissions.wltpEmissionsCombined || vehicle.emissions.wltpEmissionsCombined || vehicle.consumption.nedcConsumptionCombined || vehicle.consumption.wltpConsumptionCombined) && emissionsEnabled && _react2.default.createElement(_SearchResultEmissions2.default, {
      emissionsData: vehicle.emissions,
      consumptionsData: vehicle.consumption,
      efficiency: vehicle.energyEfficiencyRating,
      translations: translations
    })
  );
};

var carbonTemplate = function carbonTemplate(vehicle, translations) {
  var fuelConsumption = (0, _translateFromTemplate.translateFromTemplate)('fuelConsumptionTemplate', {
    MINFUEL: vehicle.engineDetails.consumptionExtraurban,
    MAXFUEL: vehicle.engineDetails.consumptionUrban
  }, translations);

  var co2 = (0, _translateFromTemplate.translateFromTemplate)('co2ConsumptionTemplate', {
    MINCO2: vehicle.engineDetails.co2EmissionsMin,
    MAXCO2: vehicle.engineDetails.co2EmissionsMax
  }, translations);

  return fuelConsumption + '\n ' + co2;
};

var electricTemplate = function electricTemplate(vehicle, translations) {
  return (0, _translateFromTemplate.translateFromTemplate)('electricVehicleConsumptionTemplate', {
    MAXDISTANCE: vehicle.engineDetails.maxRange,
    MINPOWER: vehicle.engineDetails.minPowerKw,
    MAXPOWER: vehicle.engineDetails.maxPowerKw
  }, translations);
};

var co2LegalInfo = function co2LegalInfo(vehicle, translations) {
  return vehicle.engine === 'EV' ? electricTemplate(vehicle, translations) : carbonTemplate(vehicle, translations);
};

function SearchResultTile(_ref35) {
  var align = _ref35.align,
      carResult = _ref35.carResult,
      placeholdingImage = _ref35.placeholdingImage,
      checkLength = _ref35.checkLength,
      translations = _ref35.translations,
      renderTopRightIcon = _ref35.renderTopRightIcon,
      renderBottomIcon1 = _ref35.renderBottomIcon1,
      renderBottomIcon2 = _ref35.renderBottomIcon2,
      onMoreInfoClicked = _ref35.onMoreInfoClicked,
      filtersExpanded = _ref35.filtersExpanded,
      mobileSelectedForSimVehicle = _ref35.mobileSelectedForSimVehicle,
      inSimilarVehicles = _ref35.inSimilarVehicles,
      globalStyling = _ref35.globalStyling,
      visibility = _ref35.visibility,
      locale = _ref35.locale,
      pricingSectionImage = _ref35.pricingSectionImage,
      financeHeadingFont = _ref35.financeHeadingFont,
      financeSubHeadingFont = _ref35.financeSubHeadingFont,
      showFinancePopup = _ref35.showFinancePopup,
      badgeWithStripUrl = _ref35.badgeWithStripUrl,
      badgeWithoutStripUrl = _ref35.badgeWithoutStripUrl,
      warrantyBadgeMonths = _ref35.warrantyBadgeMonths,
      warrantyBadgeMaxMileage = _ref35.warrantyBadgeMaxMileage,
      _ref35$showFinanceCel = _ref35.showFinanceCellBorder,
      showFinanceCellBorder = _ref35$showFinanceCel === undefined ? true : _ref35$showFinanceCel,
      backgroundColour = _ref35.backgroundColour,
      vehicleTitleFont = _ref35.vehicleTitleFont,
      vehicleSubTitleFont = _ref35.vehicleSubTitleFont,
      vehicleSpecContainer = _ref35.vehicleSpecContainer,
      actionRowStyles = _ref35.actionRowStyles,
      compareShortlistIconWidth = _ref35.compareShortlistIconWidth,
      showOnDemand = _ref35.showOnDemand,
      onDemandFont = _ref35.onDemandFont,
      icon360 = _ref35.icon360,
      showEmissions = _ref35.showEmissions,
      convertedKwToHp = _ref35.convertedKwToHp,
      config = _ref35.config;
  var co2EmissionsCombined = carResult.co2EmissionsCombined,
      financeTextTheme = carResult.financeTextTheme,
      images = carResult.images,
      status = carResult.status,
      _carResult$price = carResult.price,
      price = _carResult$price === undefined ? { monthly: '', currency: '', apr: '', value: '' } : _carResult$price,
      offer = carResult.offer;


  var isGermanSpecification = config.visibility.tiles.vehicleSpecGermany;

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      rates: state.shared.currencyConversion.exchangeRates[price.currency] ? state.shared.currencyConversion.exchangeRates[price.currency].rates : null,
      secondaryCurrency: state.config.config.global.inventory.secondaryCurrency || null
    };
  }),
      rates = _useSelector.rates,
      secondaryCurrency = _useSelector.secondaryCurrency;

  var _useSelector2 = (0, _reactRedux.useSelector)(function (state) {
    return {
      financeFeatureEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.finance
    };
  }),
      financeFeatureEnabled = _useSelector2.financeFeatureEnabled;

  var financeCellText = function financeCellText(priceEntry) {
    return (0, _numbers.localiseCurrency)(priceEntry, locale, price.currency, 2) || translations.searchResultsNotAvailableLabel;
  };

  var financeCellTextNoDigits = function financeCellTextNoDigits(priceEntry, currency, carResult) {
    return priceEntry > 0 && (0, _numbers.localiseCurrency)(priceEntry, locale, currency, 0) || carResult.priceSpecial === 'SOLD' && translations.sold || translations.zeroPriceText || translations.searchResultsNotAvailableLabel;
  };

  var hasFinance = !!carResult.finance;
  var financeLoaded = carResult.financeLoaded;


  var generateSubTitle = function generateSubTitle(vehicle, subtitleData, locale, hideSubtitles) {
    if (hideSubtitles) {
      return null;
    }
    if (subtitleData) {
      return (0, _vehicle.vehicleDataFormatter)(subtitleData, vehicle, locale, ', ');
    }
    var shortVehicleTitles = vehicle.shortVehicleTitles,
        exterior = vehicle.exterior,
        fuel = vehicle.fuel;

    return (shortVehicleTitles ? [fuel, exterior] : [exterior]).filter(Boolean).join(' | ');
  };

  var FinanceLoadingText = function FinanceLoadingText() {
    return _react2.default.createElement(
      FinanceSpinnerWrapper,
      null,
      _react2.default.createElement(_Spinner2.default, { colour: globalStyling.colours.primaryBrandColour }),
      _react2.default.createElement(
        FinanceLoadingWrapper,
        null,
        translations.financeLoadingText
      )
    );
  };

  var hideYear = visibility.hideYear,
      showWarrantyExpiryDate = visibility.showWarrantyExpiryDate,
      titleBrandPrefix = visibility.titleBrandPrefix;

  var _useSelector3 = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.featureFlags;
  }),
      approvedVehicles = _useSelector3.approvedVehicles;

  var _useSelector4 = (0, _reactRedux.useSelector)(function (state) {
    return {
      emissionsEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.emissionsEnabled
    };
  }),
      emissionsEnabled = _useSelector4.emissionsEnabled;

  var _useSelector5 = (0, _reactRedux.useSelector)(function (state) {
    return {
      kiaEmissionsEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.kiaEmissionsEnabled
    };
  }),
      kiaEmissionsEnabled = _useSelector5.kiaEmissionsEnabled;

  var formattedDate = carResult.availabilityDate && (0, _dates.localeFormattedDate)(carResult.availabilityDate, locale, {
    month: 'long',
    year: 'numeric'
  });

  var brandName = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.make;
  });

  var getVehicleTitle = function getVehicleTitle() {
    return hideYear ? titleBrandPrefix ? brandName + ' ' + carResult.description : '' + carResult.description : carResult.registration + ' ' + carResult.description;
  };

  return _react2.default.createElement(
    TileItemContainer,
    {
      'data-cy': 'vehicle-tile',
      filtersExpanded: filtersExpanded,
      mobileSelectedForSimVehicle: mobileSelectedForSimVehicle,
      inSimilarVehicles: inSimilarVehicles,
      checkLength: checkLength,
      backgroundColour: backgroundColour,
      compareShortlistIconWidth: compareShortlistIconWidth
    },
    _react2.default.createElement(
      TopContainer,
      { backgroundColour: backgroundColour },
      _react2.default.createElement(
        'div',
        { 'data-cy': 'vehicle-title-description' },
        _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              onClick: function onClick() {
                return carResult.status !== 'sold' && onMoreInfoClicked('VDP', carResult);
              },
              styleOverride: function styleOverride() {
                return '\n                ' + (0, _Global.fontStyleOverride)(vehicleTitleFont) + '\n                cursor: pointer;\n                padding-top: 0;\n                padding-left: 0;\n                padding-right: 0;\n                text-transform: capitalize;\n             ';
              }
            },
            getVehicleTitle()
          ),
          _react2.default.createElement(
            _Global.HeadingThree,
            {
              styleOverride: function styleOverride() {
                return '\n                  ' + (0, _Global.fontStyleOverride)(vehicleSubTitleFont) + '\n                    ' + _theme2.default.max.extraLarge(_templateObject7) + ';\n                    padding-left: 0;\n                    padding-right:0;\n                    padding-bottom: 0;\n                ';
              }
            },
            generateSubTitle(carResult, visibility.gridSubtitleData, locale, visibility.hideSubTitles)
          )
        ),
        _react2.default.createElement(_WarrantyBadge2.default, {
          vehicle: carResult,
          badgeWithStripUrl: badgeWithStripUrl,
          badgeWithoutStripUrl: badgeWithoutStripUrl,
          warrantyOdometer: carResult.odometer.reading,
          warrantyBadgeMonths: warrantyBadgeMonths,
          warrantyBadgeMaxMileage: warrantyBadgeMaxMileage
        })
      ),
      status === 'sold' && renderTopRightIcon()
    ),
    _react2.default.createElement(
      ImageContainer,
      { onClick: status !== 'sold' && onMoreInfoClicked },
      showOnDemand && _react2.default.createElement(_OnDemandBadge2.default, {
        font: onDemandFont,
        backgroundColor: globalStyling.colours.primaryBrandColour,
        foregroundColor: globalStyling.colours.primaryForegroundColour,
        text: translations.onDemandBadge
      }),
      images.length > 0 ? _react2.default.createElement(Image, {
        'data-cy': 'vehicle-image',
        src: images[0],
        alt: getVehicleTitle()
      }) : _react2.default.createElement(Image, { src: placeholdingImage, alt: placeholdingImage }),
      status === 'sold' && _react2.default.createElement(
        ImageOverlay,
        null,
        _react2.default.createElement(
          SoldHeading,
          null,
          translations.sold
        ),
        _react2.default.createElement(
          SoldBody,
          null,
          translations.similarVehiclesAvailable
        )
      ),
      carResult.panoramicViews && (carResult.panoramicViews.exterior || carResult.panoramicViews.interior) && _react2.default.createElement(
        ThreeSixty,
        { direction: globalStyling.direction },
        _react2.default.createElement(Icon, { src: icon360, alt: '360' })
      ),
      carResult.video && _react2.default.createElement(
        YoutubeVideo,
        {
          direction: globalStyling.direction,
          panoramicViews: carResult.panoramicViews
        },
        _react2.default.createElement(Icon, { src: config.iconYoutubeVideo, alt: 'Youtube video play icon' })
      )
    ),
    _react2.default.createElement(
      BottomContainer,
      {
        align: align,
        padding: visibility.bottomContainerPadding
      },
      pricingSectionImage ? _react2.default.createElement(LamborghiniInfoSection, {
        vehicle: (0, _extends3.default)({}, carResult, {
          price: financeCellTextNoDigits(price.value && Number(price.value), price.currency, carResult),
          basePrice: financeCellTextNoDigits(carResult.basePrice && Number(carResult.basePrice), price.currency, carResult),
          otherExpenses: financeCellTextNoDigits(carResult.expenses && Number(carResult.expenses), price.currency, carResult),
          approvedImage: pricingSectionImage,
          approved: carResult.approved,
          locale: locale
        }),
        translations: translations,
        showApprovedVehicles: approvedVehicles,
        emissionsEnabled: emissionsEnabled,
        formattedDate: formattedDate,
        availabilityFont: config.availabilityFont,
        config: config
      }) : _react2.default.createElement(
        _react.Fragment,
        null,
        carResult.price.previousValue === null ? _react2.default.createElement(
          FinanceRow,
          {
            'data-cy': 'vehicle-finance',
            financeCellHeight: visibility.financeCellHeight
          },
          _react2.default.createElement(
            FinanceCol,
            { noFinance: !financeLoaded || !hasFinance },
            _react2.default.createElement(FinanceCell, {
              headerText: financeCellTextNoDigits(price.value && Number(price.value), price.currency, carResult),
              secondaryHeaderText: secondaryCurrency && rates && financeCellTextNoDigits((0, _helpers.convertSecondaryPriceCurrency)(price.value, rates, price.currency, secondaryCurrency), secondaryCurrency, carResult),
              subHeaderText: secondaryCurrency && rates ? (0, _translateFromTemplate.translateFromTemplate)('exchangeRate', {
                EXCHANGERATE: rates[secondaryCurrency].toFixed(2)
              }, translations) : translations.searchResultsPriceLabel,
              differentialTaxation: carResult.sectionSalesTaxDisclaimer === 1 ? translations.differentialTaxation : false,
              visible: visibility.showPrice,
              paddingLeft: visibility.gridTilePricePaddingLeft,
              financeHeadingFont: financeHeadingFont,
              financeSubHeadingFont: financeSubHeadingFont,
              translations: translations,
              vehicle: carResult
            }),
            financeFeatureEnabled && !financeLoaded ? _react2.default.createElement(FinanceLoadingText, null) : '\xA0',
            _react2.default.createElement(HorizontalLine, {
              visible: hasFinance && visibility.showMonthly && financeLoaded,
              showRightBorder: showFinanceCellBorder && visibility.showMonthly
            }),
            _react2.default.createElement(
              FinanceCellWithPopup,
              {
                visible: hasFinance && visibility.showMonthly && financeLoaded
              },
              _react2.default.createElement(FinanceCell, {
                headerText: hasFinance ? financeCellText(parseFloat(carResult.finance.monthlyPrice.replace('.', '').replace(',', '.'))) : translations.searchResultsNotAvailableLabel,
                subHeaderText: translations.searchResultsMonthlyLabel,
                financeHeadingFont: financeHeadingFont,
                financeSubHeadingFont: financeSubHeadingFont,
                visible: true,
                paddingLeft: visibility.gridTilePricePaddingLeft
              }),
              _react2.default.createElement(
                'div',
                { style: { paddingLeft: '10px' } },
                hasFinance && status !== 'sold' && carResult.finance && showFinancePopup && _react2.default.createElement(_FinancePopup2.default, {
                  translations: translations,
                  data: carResult.finance,
                  locale: locale
                })
              )
            )
          ),
          _react2.default.createElement(VerticalLine, {
            visible: hasFinance && visibility.showApr && financeLoaded,
            showRightBorder: showFinanceCellBorder && visibility.showMonthly
          }),
          _react2.default.createElement(
            FinanceCol,
            null,
            _react2.default.createElement(FinanceCell, {
              headerText: (hasFinance && carResult.finance.apr || translations.searchResultsNotAvailableLabel) + '%',
              subHeaderText: translations.searchResultsAprLabel,
              visible: hasFinance && visibility.showApr && financeLoaded,
              financeHeadingFont: financeHeadingFont,
              financeSubHeadingFont: financeSubHeadingFont,
              paddingLeft: visibility.gridTilePricePaddingLeft
            }),
            _react2.default.createElement(HorizontalLine, {
              visible: hasFinance && visibility.showInterestRate && financeLoaded,
              showRightBorder: showFinanceCellBorder && visibility.showMonthly
            }),
            _react2.default.createElement(FinanceCell, {
              headerText: (hasFinance && carResult.finance.interestRate || translations.searchResultsNotAvailableLabel) + '%',
              subHeaderText: translations.searchResultsInterestRateLabel,
              visible: hasFinance && visibility.showInterestRate && financeLoaded,
              financeHeadingFont: financeHeadingFont,
              financeSubHeadingFont: financeSubHeadingFont,
              paddingLeft: visibility.gridTilePricePaddingLeft
            })
          )
        ) : _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            FinanceCol,
            { noFinance: !financeLoaded || !hasFinance },
            _react2.default.createElement(
              FinanceRow,
              {
                'data-cy': 'vehicle-finance',
                noMinHeight: true,
                noMarginBottom: true,
                noSpaceEvenly: true
              },
              _react2.default.createElement(FinanceCell, {
                headerText: financeCellTextNoDigits(price.previousValue && Number(price.previousValue), price.currency, carResult),
                secondaryHeaderText: secondaryCurrency && rates && financeCellTextNoDigits((0, _helpers.convertSecondaryPriceCurrency)(price.value, rates, price.currency, secondaryCurrency), secondaryCurrency, carResult),
                subHeaderText: secondaryCurrency && rates ? (0, _translateFromTemplate.translateFromTemplate)('exchangeRate', {
                  EXCHANGERATE: rates[secondaryCurrency].toFixed(2)
                }, translations) : translations.searchResultsPriceLabel,
                visible: visibility.showPrice,
                paddingLeft: visibility.gridTilePricePaddingLeft,
                financeHeadingFont: financeHeadingFont,
                financeSubHeadingFont: financeSubHeadingFont,
                priceStyle: config.specialOfferStyling && config.specialOfferStyling.priceStyle
              })
            ),
            _react2.default.createElement(
              FinanceRow,
              {
                'data-cy': 'vehicle-finance',
                noMinHeight: true,
                noMarginBottom: true,
                noSpaceEvenly: true
              },
              _react2.default.createElement(FinanceCell, {
                headerText: financeCellTextNoDigits(price.value && Number(price.value), price.currency, carResult),
                secondaryHeaderText: secondaryCurrency && rates && financeCellTextNoDigits((0, _helpers.convertSecondaryPriceCurrency)(price.value, rates, price.currency, secondaryCurrency), secondaryCurrency, carResult),
                subHeaderText: secondaryCurrency && rates ? (0, _translateFromTemplate.translateFromTemplate)('exchangeRate', {
                  EXCHANGERATE: rates[secondaryCurrency].toFixed(2)
                }, translations) : translations.specialOfferPrice,
                visible: visibility.showPrice,
                paddingLeft: visibility.gridTilePricePaddingLeft,
                financeHeadingFont: financeHeadingFont,
                financeSubHeadingFont: financeSubHeadingFont,
                offerPriceStyle: config.specialOfferStyling && config.specialOfferStyling.offerPriceStyle,
                specialOfferStyles: config.specialOfferStyling,
                offerTerms: {
                  offerTermText: offer.termsConditions,
                  offerDetailsUrl: offer.detailsUrl,
                  offerTermModalTitle: translations.offerTermModalTitle,
                  offerTermModalLinkText: translations.offerTermModalLinkText
                }
              })
            )
          )
        ),
        translations.vatDisclaimer && _react2.default.createElement(
          VatRow,
          null,
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return 'font-size: 11px; line-height: 1.2em;';
              }
            },
            translations.vatDisclaimer
          )
        )
      ),
      translations.co2ConsumptionTemplate && _react2.default.createElement(
        Co2ConsumptionInformationSection,
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return 'font-size: 13px; padding-left: 20px; white-space: pre-line';
            }
          },
          co2LegalInfo(carResult, translations)
        )
      ),
      vehicleSpecContainer && !isGermanSpecification && _react2.default.createElement(
        'div',
        null,
        carResult.warranty.remainingWarrantyWholeYears > 0 && !showWarrantyExpiryDate && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(IconRowImg, {
            style: {
              maxWidth: vehicleSpecContainer.icons.iconWidth,
              maxHeight: vehicleSpecContainer.icons.iconHeight
            },
            src: vehicleSpecContainer.icons.warrantyYears,
            alt: translations.warrantyLabel
          }),
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.warrantyLabel + ' ',
            '' + (0, _getWarrantyLabel.getWarrantyLabel)(carResult.warranty.remainingWarrantyWholeYears, translations.warrantyYearsLabel, translations.warrantyYearsLabel2to4)
          )
        ),
        carResult.warranty.expiryDate !== null && showWarrantyExpiryDate && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(IconRowImg, {
            style: {
              maxWidth: vehicleSpecContainer.icons.iconWidth,
              maxHeight: vehicleSpecContainer.icons.iconHeight
            },
            src: vehicleSpecContainer.icons.warrantyYears,
            alt: translations.warrantyLabel
          }),
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.warrantyLabel + ' ',
            (0, _dates.localeFormattedDate)(carResult.warranty.expiryDate, formattedLocale(locale), {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }) + ' '
          )
        ),
        translations.efficiencyIcon && vehicleSpecContainer.icons.efficiencyIcon && parseInt(co2EmissionsCombined, 10) > 0 && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(IconRowImg, {
            style: {
              maxWidth: vehicleSpecContainer.icons.iconWidth,
              maxHeight: vehicleSpecContainer.icons.iconHeight
            },
            src: vehicleSpecContainer.icons.efficiencyIcon,
            alt: translations.efficiencyIcon
          }),
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.searchResultsEmissionsLabel + ': ' + (co2EmissionsCombined || translations.searchResultsNotAvailableLabel),
            ' ',
            translations.co2Unit
          )
        ),
        translations.fuelTypeIcon && !!carResult.engineDetails.combinedConsumption && translations.mpgLabel && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(IconRowImg, {
            style: {
              maxWidth: vehicleSpecContainer.icons.iconWidth,
              maxHeight: vehicleSpecContainer.icons.iconHeight
            },
            src: vehicleSpecContainer.icons.fuelTypeIcon,
            alt: translations.fuelTypeIcon
          }),
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.mpgLabel + ': ' + (carResult.engineDetails.combinedConsumption || translations.searchResultsNotAvailableLabel),
            ' '
          )
        ),
        convertedKwToHp && translations.powerReading && carResult.powerKwNumeric && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(IconRowImg, {
            style: {
              maxWidth: vehicleSpecContainer.icons.iconWidth,
              maxHeight: vehicleSpecContainer.icons.iconHeight
            },
            src: vehicleSpecContainer.icons.powerIcon,
            alt: translations.powerKw
          }),
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.searchResultsPowerLabel + ': ',
            (0, _translateFromTemplate.translateFromTemplate)('powerReading', {
              KW: carResult.powerKwNumeric,
              HP: (0, _vehicle.vehiclePropFormatter)(convertedKwToHp, carResult, carResult.locale)
            }, translations)
          )
        ),
        translations.registrationDate && carResult.regDate && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(IconRowImg, {
            style: {
              maxWidth: vehicleSpecContainer.icons.iconWidth,
              maxHeight: vehicleSpecContainer.icons.iconHeight
            },
            src: vehicleSpecContainer.icons.registrationDateIcon,
            alt: translations.registrationDate
          }),
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.registrationDate + ': ',
            (0, _dates.localeFormattedDate)(carResult.regDate, formattedLocale(locale), carResult.regDate && (0, _dates.dateValidityCheck)(carResult.regDate) ? { day: '2-digit', month: '2-digit', year: 'numeric' } : { month: '2-digit', year: 'numeric' })
          )
        ),
        translations.registrationDate && carResult.regDate && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.searchResultsFuelLabel + ': ',
            carResult.fuel
          )
        ),
        kiaEmissionsEnabled && _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            IconRow,
            { gridBorder: true, vehicleSpecPadding: true },
            _react2.default.createElement(IconRowImg, {
              style: {
                maxWidth: vehicleSpecContainer.icons.iconWidth,
                maxHeight: vehicleSpecContainer.icons.iconHeight
              },
              src: vehicleSpecContainer.icons.fuelConsumptions,
              alt: translations.fuelConsumptionLabel
            }),
            _react2.default.createElement(
              IconText,
              { valueFontStyle: vehicleSpecContainer.valueFont },
              translations.fuelConsumptionLabel + ': ',
              carResult.engineDetails.combinedConsumption || '-',
              ' 1/100km'
            )
          ),
          carResult.weightedCombinedFuelConsumption && _react2.default.createElement(
            IconRow,
            { gridBorder: true, vehicleSpecPadding: true },
            _react2.default.createElement(IconRowImg, {
              style: {
                maxWidth: vehicleSpecContainer.icons.iconWidth,
                maxHeight: vehicleSpecContainer.icons.iconHeight
              },
              src: vehicleSpecContainer.icons.powerIcon,
              alt: translations.combinedPowerLabel
            }),
            _react2.default.createElement(
              IconText,
              { valueFontStyle: vehicleSpecContainer.valueFont },
              translations.combinedPowerLabel + ': ',
              carResult.engineDetails.weightedCombinedFuelConsumption,
              ' ',
              'kWh/100 km'
            )
          ),
          _react2.default.createElement(
            IconRow,
            { gridBorder: true, vehicleSpecPadding: true },
            _react2.default.createElement(IconRowImg, {
              style: {
                maxWidth: vehicleSpecContainer.icons.iconWidth,
                maxHeight: vehicleSpecContainer.icons.iconHeight
              },
              src: vehicleSpecContainer.icons.co2Emissions,
              alt: translations.combinedCO2EmissionsLabel
            }),
            _react2.default.createElement(
              IconText,
              { valueFontStyle: vehicleSpecContainer.valueFont },
              translations.combinedCO2EmissionsLabel + ': ',
              carResult.engineDetails.weightedCombinedCo2Emission || carResult.engineDetails.co2EmissionsCombined || '-',
              ' ',
              'g/km'
            )
          ),
          carResult.energyEfficiencyRating && _react2.default.createElement(
            IconRow,
            { gridBorder: true, vehicleSpecPadding: true },
            _react2.default.createElement(
              IconText,
              { valueFontStyle: vehicleSpecContainer.valueFont },
              translations.efficiencyClassLabel + ': ',
              _react2.default.createElement(EfficiencySummaryImage, {
                src: 'https://res.cloudinary.com/motortrak/image/upload/v1674663402/locator/generic/emissions/kia-srp-labels/' + carResult.energyEfficiencyRating + '.jpg'
              })
            )
          ),
          _react2.default.createElement(
            IconRow,
            { gridBorder: true, vehicleSpecPadding: true },
            _react2.default.createElement(
              IconText,
              { valueFontStyle: vehicleSpecContainer.valueFont },
              translations.efficiencyStandardLabel + ': ',
              carResult.engineDetails.emissionsClass || '-'
            )
          )
        ),
        _react2.default.createElement(DistanceFromRetailer, {
          colour: globalStyling.colours.primaryBrandColour,
          actionRowStyles: actionRowStyles,
          carResult: carResult
        })
      ),
      isGermanSpecification && _react2.default.createElement(
        'div',
        null,
        carResult.odometer && carResult.odometer.reading && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(IconRowImg, {
            style: {
              maxWidth: vehicleSpecContainer.icons.iconWidth,
              maxHeight: vehicleSpecContainer.icons.iconHeight
            },
            src: vehicleSpecContainer.icons.odometerIcon,
            alt: translations.searchResultsOdometerLabel
          }),
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.searchResultsOdometerLabel + ': ' + carResult.odometer.reading + ' ' + carResult.odometer.units
          )
        ),
        carResult.transmission && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(IconRowImg, {
            style: {
              maxWidth: vehicleSpecContainer.icons.iconWidth,
              maxHeight: vehicleSpecContainer.icons.iconHeight
            },
            src: vehicleSpecContainer.icons.transmissionIcon,
            alt: translations.searchResultsTransmissionLabel
          }),
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.searchResultsTransmissionLabel + ': ' + carResult.transmission
          )
        ),
        carResult.fuel && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(IconRowImg, {
            style: {
              maxWidth: vehicleSpecContainer.icons.iconWidth,
              maxHeight: vehicleSpecContainer.icons.iconHeight
            },
            src: vehicleSpecContainer.icons.fuelTypeIcon,
            alt: translations.searchResultsFuelLabel
          }),
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.searchResultsFuelLabel + ': ' + carResult.fuel
          )
        ),
        carResult.regDate && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(IconRowImg, {
            style: {
              maxWidth: vehicleSpecContainer.icons.iconWidth,
              maxHeight: vehicleSpecContainer.icons.iconHeight
            },
            src: vehicleSpecContainer.icons.registrationDateIcon,
            alt: translations.registrationDate
          }),
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.registrationDate + ': ' + (0, _dates.localeFormattedDate)(carResult.regDate, formattedLocale(locale), carResult.regDate && (0, _dates.dateValidityCheck)(carResult.regDate) ? { day: '2-digit', month: '2-digit', year: 'numeric' } : { month: '2-digit', year: 'numeric' })
          )
        ),
        carResult.exterior && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.searchResultsColourLabel + ': ' + carResult.exterior
          )
        ),
        convertedKwToHp && translations.powerReading && carResult.powerKwNumeric && _react2.default.createElement(
          IconRow,
          { gridBorder: true, vehicleSpecPadding: true },
          _react2.default.createElement(
            IconText,
            { valueFontStyle: vehicleSpecContainer.valueFont },
            translations.searchResultsPowerLabel + ': ',
            (0, _translateFromTemplate.translateFromTemplate)('powerReading', {
              KW: carResult.powerKwNumeric,
              HP: (0, _vehicle.vehiclePropFormatter)(convertedKwToHp, carResult, carResult.locale)
            }, translations)
          )
        ),
        _react2.default.createElement(
          EmissionsStringText,
          {
            styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(vehicleSubTitleFont) + '; padding: 0 10px; text-transform: initial; text-wrap: pretty;';
            }
          },
          (0, _emissionSrpText.displayKiaEmissionsConsumption)(carResult, translations)
        ),
        (0, _emissionSrpText.displayKiaElectricRange)(carResult, translations) && _react2.default.createElement(
          EmissionsStringText,
          {
            styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(vehicleSubTitleFont) + '; padding: 0 10px; text-transform: initial; text-wrap: pretty;';
            }
          },
          (0, _emissionSrpText.displayKiaElectricRange)(carResult, translations)
        )
      ),
      /*  eslint-disable-next-line max-len */
      parseInt(co2EmissionsCombined, 10) > 0 && !pricingSectionImage && !vehicleSpecContainer && _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(HorizontalLine, { visible: true }),
        _react2.default.createElement(
          IconRow,
          { theme: financeTextTheme },
          _react2.default.createElement(_Co2.default, {
            width: '1.87em',
            height: '1.75em',
            colour: visibility.co2IconColour
          }),
          _react2.default.createElement(
            IconText,
            null,
            translations.searchResultsEmissionsCombinedLabel + ': ' + (co2EmissionsCombined || translations.searchResultsNotAvailableLabel),
            ' '
          )
        )
      ),
      _react2.default.createElement(
        MoreInfoRow,
        { actionRowStyles: actionRowStyles },
        status !== 'sold' && _react2.default.createElement(
          'div',
          {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }
          },
          _react2.default.createElement(
            IconRow,
            null,
            _react2.default.createElement(
              DesktopOnly,
              null,
              _react2.default.createElement(IconSpace, { icon: renderBottomIcon1 })
            ),
            _react2.default.createElement(IconSpace, { icon: renderBottomIcon2 })
          ),
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'more-info-button',
            onClick: status !== 'sold' && onMoreInfoClicked,
            text: translations.searchResultTileMoreInfo,
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            applyStyle: 'primary',
            mobileStyleOverride: function mobileStyleOverride() {
              return '\n                  width: unset;\n                ';
            }
          })
        )
      ),
      showEmissions && _react2.default.createElement(_SearchCo2Accordian2.default, {
        vehicle: carResult,
        translations: translations,
        translateFromTemplate: _translateFromTemplate.translateFromTemplate,
        globalStyling: globalStyling,
        font: config.searchCo2AccordionFont,
        locale: locale
      })
    )
  );
}

SearchResultTile.defaultProps = {
  onMoreInfoClicked: function onMoreInfoClicked() {},
  modelTheme: {},
  numberAvailableTheme: {},
  financeTextTheme: {},
  images: [],
  renderTopRightIcon: function renderTopRightIcon() {},
  price: {},
  mobileSelectedForSimVehicle: false,
  inSimilarVehicles: false,
  buttonTheme: {
    fontFamily: 'inherit'
  }
};