'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n        &&& {font-size: 16px;}\n      '], ['\n        &&& {font-size: 16px;}\n      ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _settings = require('../../shared/selectors/settings');

var _globalStyling = require('../../types/globalStyling');

var _styledComponentFactory = require('../Global/styledComponentFactory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/** Creating button with createElement to be able to use:
 * styleOverride?: () => string;
 * mobileStyleOverride?: () => { queryPath: string; template: string }[];
 */
var ButtonElement = (0, _styledComponentFactory.createElement)('button');

var variantStyles = function variantStyles(fonts) {
  var variant = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'primary';
  return {
    primary: (0, _styledComponents.css)(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:18px;font-style:normal;font-weight:400;line-height:32px;color:#fff;', ';'], _Genesis2.default.max.medium(_templateObject)),
    secondary: (0, _styledComponents.css)(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:18px;font-style:normal;font-weight:400;line-height:32px;color:#fff;&:hover{color:#c36f51;text-underline-color:#c36f51;}', ';'], _Genesis2.default.max.medium(_templateObject)),
    tertiary: (0, _styledComponents.css)(['border:solid 1px #335b4c !important;color:#222;font-family:', ';'], fonts.tertiaryButtonFont && fonts.tertiaryButtonFont.value)
  }[variant];
};

var BaseButton = (0, _styledComponents2.default)(ButtonElement).withConfig({
  displayName: 'TextLinkButton__BaseButton',
  componentId: 'sc-rv93an-0'
})(['align-items:center;background-color:transparent;display:flex;font-family:GenesisSansHeadGlobal-Regular;height:40px;padding:0;', ' &:disabled{color:#666;cursor:not-allowed;}'], function (_ref) {
  var fonts = _ref.fonts,
      variant = _ref.variant;
  return variantStyles(fonts, variant);
});

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'TextLinkButton__Label',
  componentId: 'sc-rv93an-1'
})(['display:flex;align-items:center;flex-direction:row;font-size:18px;line-height:24px;font-family:GenesisSansTextGlobal-Regular;display:flex;align-items:flex-start;display:flex;font-family:GenesisSansTextGlobal-Regular;font-size:18px;justify-content:flex-start;text-decoration:none;background-image:linear-gradient(#fff,#fff);background-size:0% 1px;background-position-y:100%;background-position-x:0%;background-repeat:no-repeat;transition:all 0.3s ease-out;letter-spacing:0;', ''], function (_ref2) {
  var underlined = _ref2.underlined,
      disabled = _ref2.disabled;
  return underlined ? '\n      text-decoration: underline;\n      text-underline-offset: 10px;\n    ' : '\n      &:hover,\n      ' + (disabled ? '' : '&:hover {background-size: calc(100% - 18px) 1px;') + '\n      :focus,\n      :active {\n        background-size: calc(100% - 18px) 1px;\n      }\n    ';
});

var ButtonIcon = _styledComponents2.default.img.withConfig({
  displayName: 'TextLinkButton__ButtonIcon',
  componentId: 'sc-rv93an-2'
})(['', ' margin-left:10px;margin-top 4px;height:14px;position:relative;'], function (_ref3) {
  var disabled = _ref3.disabled;
  return disabled && '\n    opacity: 0.5;\n  ';
});

var Button = function Button(props) {
  var onClick = props.onClick,
      text = props.text,
      children = props.children,
      styleOverride = props.styleOverride,
      mobileStyleOverride = props.mobileStyleOverride,
      disabled = props.disabled,
      _props$underlined = props.underlined,
      underlined = _props$underlined === undefined ? false : _props$underlined,
      _props$hideChevron = props.hideChevron,
      hideChevron = _props$hideChevron === undefined ? false : _props$hideChevron,
      rest = (0, _objectWithoutProperties3.default)(props, ['onClick', 'text', 'children', 'styleOverride', 'mobileStyleOverride', 'disabled', 'underlined', 'hideChevron']);

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts,
      colours = _useSelector.colours;

  return _react2.default.createElement(
    BaseButton,
    (0, _extends3.default)({
      onClick: function (_onClick) {
        function onClick(_x2) {
          return _onClick.apply(this, arguments);
        }

        onClick.toString = function () {
          return _onClick.toString();
        };

        return onClick;
      }(function (e) {
        return !disabled && onClick(e);
      }),
      fonts: fonts,
      colours: colours,
      disabled: disabled
    }, rest, {
      styleOverride: styleOverride
    }, (0, _extends3.default)({}, props, (0, _styledComponentFactory.mediaProps)(mobileStyleOverride && mobileStyleOverride()))),
    _react2.default.createElement(
      Label,
      { disabled: disabled, underlined: underlined },
      text || children,
      !hideChevron && _react2.default.createElement(ButtonIcon, {
        src: 'https://res.cloudinary.com/motortrak/image/upload/v1695830589/locator/genesis/icons/arrow_left.svg',
        'aria-hidden': 'true',
        disabled: disabled
      })
    )
  );
};

Button.defaultProps = {
  styleOverride: function styleOverride() {
    return '';
  },
  mobileStyleOverride: function mobileStyleOverride() {
    return [];
  },
  children: ''
};

exports.default = Button;