'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldError = require('./FieldError');

var _FieldError2 = _interopRequireDefault(_FieldError);

var _WidthWrapper = require('./WidthWrapper');

var _WidthWrapper2 = _interopRequireDefault(_WidthWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TextArea = _styledComponents2.default.textarea.withConfig({
  displayName: 'TextArea',
  componentId: 'sc-ei75qy-0'
})(['width:100%;font-size:16px;font-family:inherit;line-height:40px;box-sizing:border-box;border:1px solid ', ';color:#7e7e7e;background-color:#fff;padding:0 8px;&:disabled{border:none;background-color:#d6d6d6;}&:focus{border:1px solid #999999;color:#444444;outline:none;}', ';'], function (props) {
  return props.error ? '#BF0C0C' : '#d8d8d8';
}, function (props) {
  return props.truncateChars && (0, _styledComponents.css)(['white-space:nowrap;overflow:hidden;text-overflow:ellipsis;']);
});

exports.default = function (_ref) {
  var label = _ref.label,
      required = _ref.required,
      name = _ref.name,
      value = _ref.value,
      onChange = _ref.onChange,
      onBlur = _ref.onBlur,
      error = _ref.error,
      isDisabled = _ref.isDisabled,
      placeholder = _ref.placeholder,
      _ref$truncateChars = _ref.truncateChars,
      truncateChars = _ref$truncateChars === undefined ? false : _ref$truncateChars,
      font = _ref.font;

  return _react2.default.createElement(
    _WidthWrapper2.default,
    null,
    _react2.default.createElement(
      _FormField2.default,
      { label: label, name: name, required: required, font: font },
      _react2.default.createElement(TextArea, {
        type: 'text',
        name: name,
        id: name,
        value: value,
        onChange: function (_onChange) {
          function onChange(_x) {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function (e) {
          onChange({ name: e.target.name, value: e.target.value });
        }),
        onBlur: function (_onBlur) {
          function onBlur(_x2) {
            return _onBlur.apply(this, arguments);
          }

          onBlur.toString = function () {
            return _onBlur.toString();
          };

          return onBlur;
        }(function (e) {
          onBlur({ name: e.target.name, value: e.target.value });
        }),
        placeholder: placeholder,
        error: error,
        disabled: isDisabled,
        truncateChars: truncateChars,
        ariaRequired: required
      }),
      error && _react2.default.createElement(
        _FieldError2.default,
        null,
        error
      )
    )
  );
};