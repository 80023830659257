'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

exports.default = GlobalStyling;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _fallbackFonts = require('./Global/fallbackFonts');

var _fallbackFonts2 = _interopRequireDefault(_fallbackFonts);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GlobalStyle = (0, _styledComponents.createGlobalStyle)(['body{direction:', ';overflow-x:hidden;}h1.global{', ';', ';font-weight:normal;}input[type="text"]{', ';', ';', ';}textarea{', ';', ';', ';}h2.global{', ';', ';font-weight:normal;margin:0;}h3.global{', ';', ';font-weight:normal;margin:0;}a.global{', ';', ';cursor:pointer;}p.global{', ';', ';', ';', ';}button.global{display:flex;align-items:center;justify-content:center;letter-spacing:1px;cursor:pointer;min-height:30px;font-size:13px;border:none;margin:0;&:focus{outline:none;}}button.global.primary{', ' ', ';', ';', ';', ';', ';', ';svg > rect:not([fill=\'none\']){', ';}svg > path:not([fill]){', ';}svg{', ';', ';}&:hover{', ';', ';', ';svg > rect:not([fill=\'none\']){', ';}svg > path:not([fill]){', ';}}}button.global.secondary{', ' ', ';', ';', ';', ';', ';', ';&:hover{', ';', ';', ';svg > rect:not([fill=\'none\']){', ';}svg > path:not([fill]){', ';}}svg > rect:not([fill=\'none\']){', ';}svg > path:not([fill]){', ';}svg{', ';', ';}}button.global.tertiary{', ' ', ';', ';', ';', ';', ';', ';&:hover{', ';', ';', ';svg > rect:not([fill=\'none\']){', ';}svg > path:not([fill]){', ';}}svg > rect:not([fill=\'none\']){', ';}svg > path:not([fill]){', ';}}button.global.disabled{border:none;cursor:not-allowed;', ';', ';svg > rect:not([fill=\'none\']){', ';}svg > path:not([fill]){', ';}svg{', ';', ';}&:hover{border:none;', ';', ';', ';svg > rect:not([fill=\'none\']){', ';}svg > path:not([fill]){', ';}svg{', ';}}}div.content{', ';', ';}select.global{margin:0;box-sizing:border-box;outline:none;', ';', ''], function (_ref) {
  var direction = _ref.styles.direction;
  return direction || 'ltr';
}, function (_ref2) {
  var styles = _ref2.styles;
  return 'font-family: ' + (styles.fonts.primaryFont ? styles.fonts.primaryFont.value + ',' : '') + _fallbackFonts2.default;
}, function (_ref3) {
  var styles = _ref3.styles;
  return styles.colours.primaryFontColour && 'color:  ' + styles.colours.primaryFontColour.value;
}, function (_ref4) {
  var styles = _ref4.styles;
  return 'font-family: ' + (styles.input ? styles.input.text.fontFamily + ',' : '') + _fallbackFonts2.default;
}, function (_ref5) {
  var styles = _ref5.styles;
  return '' + (styles.input && styles.input.text && styles.input.text.fontSize && 'font-size: ' + styles.input.text.fontSize + 'px');
}, function (_ref6) {
  var styles = _ref6.styles;
  return '' + (styles.input && styles.input.text && styles.input.text.kerning && 'letter-spacing: ' + styles.input.text.kerning + 'px');
}, function (_ref7) {
  var styles = _ref7.styles;
  return 'font-family: ' + (styles.textarea ? styles.textarea.text.fontFamily + ',' : '') + _fallbackFonts2.default;
}, function (_ref8) {
  var styles = _ref8.styles;
  return '' + (styles.textarea && styles.textarea.text && styles.textarea.text.fontSize && 'font-size: ' + styles.textarea.text.fontSize + 'px');
}, function (_ref9) {
  var styles = _ref9.styles;
  return '' + (styles.textarea && styles.textarea.text && styles.textarea.text.kerning && 'letter-spacing: ' + styles.textarea.text.kerning + 'px');
}, function (_ref10) {
  var styles = _ref10.styles;
  return 'font-family: ' + (styles.fonts.secondaryFont && styles.fonts.secondaryFont.value + ',' || (styles.fonts.primaryFont ? styles.fonts.primaryFont.value + ',' : '')) + _fallbackFonts2.default;
}, function (_ref11) {
  var styles = _ref11.styles;
  return styles.colours.secondaryFontColour && 'color:  ' + styles.colours.secondaryFontColour.value || styles.colours.primaryFontColour && 'color:  ' + styles.colours.primaryFontColour.value;
}, function (_ref12) {
  var styles = _ref12.styles;
  return 'font-family: ' + (styles.fonts.highlightedLabelsFont && styles.fonts.highlightedLabelsFont.value + ',' || styles.fonts.paragraphFont && styles.fonts.paragraphFont.value + ',' || styles.fonts.secondaryFont && styles.fonts.secondaryFont.value + ',' || (styles.fonts.primaryFont ? styles.fonts.primaryFont.value + ',' : '')) + _fallbackFonts2.default;
}, function (_ref13) {
  var styles = _ref13.styles;
  return styles.colours.highlightedLabelsColour && 'color:  ' + styles.colours.highlightedLabelsColour.value || styles.colours.paragraphFontColour && 'color:  ' + styles.colours.paragraphFontColour.value || styles.colours.secondaryFontColour && 'color:  ' + styles.colours.secondaryFontColour.value || styles.colours.primaryFontColour && 'color:  ' + styles.colours.primaryFontColour.value;
}, function (_ref14) {
  var styles = _ref14.styles;
  return 'font-family: ' + (styles.fonts.paragraphFont && styles.fonts.paragraphFont.value || styles.fonts.secondaryFont && styles.fonts.secondaryFont.value || styles.fonts.primaryFont && styles.fonts.primaryFont.value);
}, function (_ref15) {
  var styles = _ref15.styles;
  return styles.colours.paragraphFontColour && 'color:  ' + styles.colours.paragraphFontColour.value || styles.colours.secondaryFontColour && 'color:  ' + styles.colours.secondaryFontColour.value || styles.colours.primaryFontColour && 'color:  ' + styles.colours.primaryFontColour.value;
}, function (_ref16) {
  var styles = _ref16.styles;
  return 'font-family: ' + (styles.fonts.paragraphFont && styles.fonts.paragraphFont.value + ',' || styles.fonts.secondaryFont && styles.fonts.secondaryFont.value + ',' || (styles.fonts.primaryFont ? styles.fonts.primaryFont.value + ',' : '')) + _fallbackFonts2.default;
}, function (_ref17) {
  var styles = _ref17.styles;
  return styles.colours.paragraphFontColour && 'color:  ' + styles.colours.paragraphFontColour.value || styles.colours.secondaryFontColour && 'color:  ' + styles.colours.secondaryFontColour.value || styles.colours.primaryFontColour && 'color:  ' + styles.colours.primaryFontColour.value;
}, function (_ref18) {
  var styles = _ref18.styles;
  return styles.fonts.paragraphFont && styles.fonts.paragraphFont.lineHeight && 'line-height:  ' + styles.fonts.paragraphFont.lineHeight + 'px';
}, function (_ref19) {
  var styles = _ref19.styles;
  return '' + (styles.fonts.paragraphFont && styles.fonts.paragraphFont.fontSize && 'font-size: ' + styles.fonts.paragraphFont.fontSize + 'px');
}, function (_ref20) {
  var styles = _ref20.styles;
  return styles.fonts.primaryButtonFont && 'font-family: ' + styles.fonts.primaryButtonFont.value + ',' + _fallbackFonts2.default + ';';
}, function (_ref21) {
  var styles = _ref21.styles;
  return styles.uiElements.primaryButton.primaryButtonCasing && 'text-transform: ' + styles.uiElements.primaryButton.primaryButtonCasing;
}, function (_ref22) {
  var styles = _ref22.styles;
  return styles.uiElements.primaryButton.primaryButtonHeight && 'height: ' + styles.uiElements.primaryButton.primaryButtonHeight + 'px';
}, function (_ref23) {
  var styles = _ref23.styles;
  return styles.uiElements.primaryButton.fontSize && 'font-size: ' + styles.uiElements.primaryButton.fontSize + 'px';
}, function (_ref24) {
  var styles = _ref24.styles;
  return styles.colours.primaryBorderColour && 'border: 1px solid ' + styles.colours.primaryBorderColour.value;
}, function (_ref25) {
  var styles = _ref25.styles;
  return styles.colours.primaryBackgroundColour && 'background-color: ' + styles.colours.primaryBackgroundColour.value;
}, function (_ref26) {
  var styles = _ref26.styles;
  return styles.colours.primaryForegroundColour && 'color: ' + styles.colours.primaryForegroundColour.value;
}, function (_ref27) {
  var styles = _ref27.styles;
  return styles.colours.primaryForegroundColour && 'fill: ' + styles.colours.primaryForegroundColour.value;
}, function (_ref28) {
  var styles = _ref28.styles;
  return styles.colours.primaryForegroundColour && 'fill: ' + styles.colours.primaryForegroundColour.value;
}, function (_ref29) {
  var styles = _ref29.styles;
  return styles.colours.primaryBackgroundColour && 'stroke: ' + styles.colours.primaryBackgroundColour.value;
}, function (_ref30) {
  var styles = _ref30.styles;
  return styles.direction === 'rtl' && 'transform:  scaleX(-1);';
}, function (_ref31) {
  var styles = _ref31.styles;
  return styles.colours.primaryBorderHoverColour && 'border: 1px solid ' + styles.colours.primaryBorderHoverColour.value;
}, function (_ref32) {
  var styles = _ref32.styles;
  return styles.colours.primaryBackgroundHoverColour && 'background-color: ' + styles.colours.primaryBackgroundHoverColour.value;
}, function (_ref33) {
  var styles = _ref33.styles;
  return styles.colours.primaryForegroundHoverColour && 'color: ' + styles.colours.primaryForegroundHoverColour.value;
}, function (_ref34) {
  var styles = _ref34.styles;
  return styles.colours.primaryForegroundHoverColour && 'fill: ' + styles.colours.primaryForegroundHoverColour.value;
}, function (_ref35) {
  var styles = _ref35.styles;
  return styles.colours.primaryForegroundHoverColour && 'fill: ' + styles.colours.primaryForegroundHoverColour.value;
}, function (_ref36) {
  var styles = _ref36.styles;
  return styles.fonts.secondaryButtonFont && 'font-family: ' + styles.fonts.secondaryButtonFont.value + ',' + _fallbackFonts2.default + ';';
}, function (_ref37) {
  var styles = _ref37.styles;
  return styles.uiElements.secondaryButton.secondaryButtonCasing && 'text-transform: ' + styles.uiElements.secondaryButton.secondaryButtonCasing;
}, function (_ref38) {
  var styles = _ref38.styles;
  return styles.uiElements.secondaryButton.secondaryButtonHeight && 'height: ' + styles.uiElements.secondaryButton.secondaryButtonHeight + 'px';
}, function (_ref39) {
  var styles = _ref39.styles;
  return styles.uiElements.secondaryButton.fontSize && 'font-size: ' + styles.uiElements.secondaryButton.fontSize + 'px';
}, function (_ref40) {
  var styles = _ref40.styles;
  return styles.colours.secondaryBorderColour && 'border: ' + (styles.uiElements.secondaryButton.borderSize || 1) + 'px solid ' + styles.colours.secondaryBorderColour.value;
}, function (_ref41) {
  var styles = _ref41.styles;
  return styles.colours.secondaryBackgroundColour && 'background-color: ' + styles.colours.secondaryBackgroundColour.value;
}, function (_ref42) {
  var styles = _ref42.styles;
  return styles.colours.secondaryForegroundColour && 'color: ' + styles.colours.secondaryForegroundColour.value;
}, function (_ref43) {
  var styles = _ref43.styles;
  return styles.colours.secondaryBorderHoverColour && 'border: ' + (styles.uiElements.secondaryButton.borderSize || 1) + 'px solid ' + styles.colours.secondaryBorderHoverColour.value;
}, function (_ref44) {
  var styles = _ref44.styles;
  return styles.colours.secondaryBackgroundHoverColour && 'background-color: ' + styles.colours.secondaryBackgroundHoverColour.value;
}, function (_ref45) {
  var styles = _ref45.styles;
  return styles.colours.secondaryForegroundHoverColour && 'color: ' + styles.colours.secondaryForegroundHoverColour.value;
}, function (_ref46) {
  var styles = _ref46.styles;
  return styles.colours.secondaryForegroundHoverColour && 'fill: ' + styles.colours.secondaryForegroundHoverColour.value;
}, function (_ref47) {
  var styles = _ref47.styles;
  return styles.colours.secondaryForegroundHoverColour && 'fill: ' + styles.colours.secondaryForegroundHoverColour.value;
}, function (_ref48) {
  var styles = _ref48.styles;
  return styles.colours.secondaryForegroundColour && 'fill: ' + styles.colours.secondaryForegroundColour.value;
}, function (_ref49) {
  var styles = _ref49.styles;
  return styles.colours.secondaryForegroundColour && 'fill: ' + styles.colours.secondaryForegroundColour.value;
}, function (_ref50) {
  var styles = _ref50.styles;
  return styles.colours.secondaryBackgroundColour && 'stroke: ' + styles.colours.secondaryBackgroundColour.value;
}, function (_ref51) {
  var styles = _ref51.styles;
  return styles.direction === 'rtl' && 'transform:  scaleX(-1);';
}, function (_ref52) {
  var styles = _ref52.styles;
  return styles.fonts.tertiaryButtonFont && 'font-family: ' + styles.fonts.tertiaryButtonFont.value + ',' + _fallbackFonts2.default + ';';
}, function (_ref53) {
  var styles = _ref53.styles;
  return styles.uiElements.tertiaryButton.tertiaryButtonCasing && 'text-transform: ' + styles.uiElements.tertiaryButton.tertiaryButtonCasing;
}, function (_ref54) {
  var styles = _ref54.styles;
  return styles.uiElements.tertiaryButton.tertiaryButtonHeight && 'height: ' + styles.uiElements.tertiaryButton.tertiaryButtonHeight + 'px';
}, function (_ref55) {
  var styles = _ref55.styles;
  return styles.uiElements.tertiaryButton.fontSize && 'font-size: ' + styles.uiElements.tertiaryButton.fontSize + 'px';
}, function (_ref56) {
  var styles = _ref56.styles;
  return styles.colours.tertiaryBorderColour && 'border: 1px solid ' + styles.colours.tertiaryBorderColour.value;
}, function (_ref57) {
  var styles = _ref57.styles;
  return styles.colours.tertiaryBackgroundColour && 'background-color: ' + styles.colours.tertiaryBackgroundColour.value;
}, function (_ref58) {
  var styles = _ref58.styles;
  return styles.colours.tertiaryForegroundColour && 'color: ' + styles.colours.tertiaryForegroundColour.value;
}, function (_ref59) {
  var styles = _ref59.styles;
  return styles.colours.tertiaryBorderHoverColour && 'border: 1px solid ' + styles.colours.tertiaryBorderHoverColour.value;
}, function (_ref60) {
  var styles = _ref60.styles;
  return styles.colours.tertiaryBackgroundHoverColour && 'background-color: ' + styles.colours.tertiaryBackgroundHoverColour.value;
}, function (_ref61) {
  var styles = _ref61.styles;
  return styles.colours.tertiaryForegroundHoverColour && 'color: ' + styles.colours.tertiaryForegroundHoverColour.value;
}, function (_ref62) {
  var styles = _ref62.styles;
  return styles.colours.tertiaryForegroundHoverColour && 'fill:  ' + styles.colours.tertiaryForegroundHoverColour.value;
}, function (_ref63) {
  var styles = _ref63.styles;
  return styles.colours.tertiaryForegroundHoverColour && 'fill:  ' + styles.colours.tertiaryForegroundHoverColour.value;
}, function (_ref64) {
  var styles = _ref64.styles;
  return styles.colours.tertiaryForegroundColour && 'fill:  ' + styles.colours.tertiaryForegroundColour.value;
}, function (_ref65) {
  var styles = _ref65.styles;
  return styles.colours.tertiaryForegroundColour && 'fill:  ' + styles.colours.tertiaryForegroundColour.value;
}, function (_ref66) {
  var styles = _ref66.styles;
  return styles.colours.disabledBackgroundColour && 'background-color: ' + styles.colours.disabledBackgroundColour.value;
}, function (_ref67) {
  var styles = _ref67.styles;
  return styles.colours.disabledForegroundColour && 'color: ' + styles.colours.disabledForegroundColour.value;
}, function (_ref68) {
  var styles = _ref68.styles;
  return styles.colours.disabledForegroundColour && 'fill: ' + styles.colours.disabledForegroundColour.value;
}, function (_ref69) {
  var styles = _ref69.styles;
  return styles.colours.disabledForegroundColour && 'fill: ' + styles.colours.disabledForegroundColour.value;
}, function (_ref70) {
  var styles = _ref70.styles;
  return styles.colours.disabledForegroundColour && 'stroke: ' + styles.colours.disabledForegroundColour.value;
}, function (_ref71) {
  var styles = _ref71.styles;
  return styles.direction === 'rtl' && 'transform:  scaleX(-1);';
}, function (_ref72) {
  var styles = _ref72.styles;
  return styles.colours.disabledBackgroundColour && 'background-color: ' + styles.colours.disabledBackgroundColour.value;
}, function (_ref73) {
  var styles = _ref73.styles;
  return styles.colours.disabledForegroundColour && 'color: ' + styles.colours.disabledForegroundColour.value;
}, function (_ref74) {
  var styles = _ref74.styles;
  return styles.colours.disabledForegroundColour && 'color: ' + styles.colours.disabledForegroundColour.value;
}, function (_ref75) {
  var styles = _ref75.styles;
  return styles.colours.disabledForegroundColour && 'fill: ' + styles.colours.disabledForegroundColour.value;
}, function (_ref76) {
  var styles = _ref76.styles;
  return styles.colours.disabledForegroundColour && 'fill: ' + styles.colours.disabledForegroundColour.value;
}, function (_ref77) {
  var styles = _ref77.styles;
  return styles.colours.disabledForegroundColour && 'stroke: ' + styles.colours.disabledForegroundColour.value;
}, function (_ref78) {
  var styles = _ref78.styles;
  return styles.colours.pageBackgroundColour && 'background-color: ' + styles.colours.pageBackgroundColour.value;
}, function (_ref79) {
  var styles = _ref79.styles;
  return 'font-family: ' + (styles.fonts.paragraphFont && styles.fonts.paragraphFont.value + ',' || styles.fonts.secondaryFont && styles.fonts.secondaryFont.value + ',' || styles.fonts.primaryFont && styles.fonts.primaryFont.value + ',') + _fallbackFonts2.default;
}, function (_ref80) {
  var styles = _ref80.styles;
  return 'font-family: ' + (styles.fonts.secondaryFont && styles.fonts.secondaryFont.value || styles.fonts.primaryFont && styles.fonts.primaryFont.value);
}, function (_ref81) {
  var _ref81$styles$uiEleme = _ref81.styles.uiElements.select;
  _ref81$styles$uiEleme = _ref81$styles$uiEleme === undefined ? {} : _ref81$styles$uiEleme;
  var _ref81$styles$uiEleme2 = _ref81$styles$uiEleme.appearance,
      appearance = _ref81$styles$uiEleme2 === undefined ? 'none' : _ref81$styles$uiEleme2,
      _ref81$styles$uiEleme3 = _ref81$styles$uiEleme.font;
  _ref81$styles$uiEleme3 = _ref81$styles$uiEleme3 === undefined ? {} : _ref81$styles$uiEleme3;
  var _ref81$styles$uiEleme4 = _ref81$styles$uiEleme3.fontSize,
      fontSize = _ref81$styles$uiEleme4 === undefined ? '14px' : _ref81$styles$uiEleme4,
      _ref81$styles$uiEleme5 = _ref81$styles$uiEleme3.colour,
      colour = _ref81$styles$uiEleme5 === undefined ? { value: '#444444' } : _ref81$styles$uiEleme5,
      _ref81$styles$uiEleme6 = _ref81$styles$uiEleme3.padding,
      padding = _ref81$styles$uiEleme6 === undefined ? { left: '15px', right: '15px' } : _ref81$styles$uiEleme6,
      _ref81$styles$uiEleme7 = _ref81$styles$uiEleme3.lineHeight,
      lineHeight = _ref81$styles$uiEleme7 === undefined ? '40px' : _ref81$styles$uiEleme7,
      _ref81$styles$uiEleme8 = _ref81$styles$uiEleme.width,
      width = _ref81$styles$uiEleme8 === undefined ? '100%' : _ref81$styles$uiEleme8,
      _ref81$styles$uiEleme9 = _ref81$styles$uiEleme.height,
      height = _ref81$styles$uiEleme9 === undefined ? '40px' : _ref81$styles$uiEleme9,
      _ref81$styles$uiEleme10 = _ref81$styles$uiEleme.backgroundColour,
      backgroundColour = _ref81$styles$uiEleme10 === undefined ? { value: '#ffffff' } : _ref81$styles$uiEleme10,
      backgroundImageURL = _ref81$styles$uiEleme.backgroundImageURL,
      _ref81$styles$uiEleme11 = _ref81$styles$uiEleme.backgroundSize,
      backgroundSize = _ref81$styles$uiEleme11 === undefined ? '1em auto, 100%' : _ref81$styles$uiEleme11;

  return ['appearance: ' + appearance + ';', 'line-height: ' + lineHeight + ';', 'font-size: ' + fontSize + ';', 'color: ' + colour.value + ';', 'width: ' + width + ';', 'height: ' + height + ';', 'background-color:  ' + backgroundColour.value + ';', 'background-image: url(\'' + (backgroundImageURL || 'https://res.cloudinary.com/motortrak/image/upload/v1706278361/locator/lamborghini/content/chevron-active.svg') + '\');', 'background-position: right .7em top 50%, 0 0;', 'background-size: ' + backgroundSize + ';', 'background-repeat: no-repeat;', padding && (0, _keys2.default)(padding).map(function (k) {
    return 'padding-' + k + ': ' + padding[k] + ';';
  }).filter(Boolean)];
});
function GlobalStyling(props) {
  return _react2.default.createElement(GlobalStyle, { styles: props.globalStyling });
}