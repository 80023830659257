'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _set = require('babel-runtime/core-js/set');

var _set2 = _interopRequireDefault(_set);

var _from = require('babel-runtime/core-js/array/from');

var _from2 = _interopRequireDefault(_from);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    min-width: 45%;\n  '], ['\n    min-width: 45%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    min-width: 100%;\n  '], ['\n    min-width: 100%;\n  ']);

exports.default = VehicleGridShortlist;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _SearchResultGridTile = require('./SearchResultGridTile');

var _SearchResultGridTile2 = _interopRequireDefault(_SearchResultGridTile);

var _SearchResultGridBlock = require('./SearchResultGridBlock');

var _SearchResultGridBlock2 = _interopRequireDefault(_SearchResultGridBlock);

var _SearchResultBlock = require('./SearchResultBlock');

var _SearchResultBlock2 = _interopRequireDefault(_SearchResultBlock);

var _SearchResultTile = require('./SearchResultTile');

var _SearchResultTile2 = _interopRequireDefault(_SearchResultTile);

var _Close = require('./JaguarIcons/Global/Close');

var _Close2 = _interopRequireDefault(_Close);

var _Compare = require('./JaguarIcons/Global/Compare');

var _Compare2 = _interopRequireDefault(_Compare);

var _Heart = require('./JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGridShortlist__Container',
  componentId: 'sc-aevikx-0'
})(['display:flex;flex-wrap:wrap;padding-top:16px;']);

var IconWrapper = _styledComponents2.default.button.withConfig({
  displayName: 'VehicleGridShortlist__IconWrapper',
  componentId: 'sc-aevikx-1'
})(['background:none;border:none;cursor:pointer;&:focus{outline:none;}']);

var SearchResultTileWrapper = (0, _styledComponents2.default)(_SearchResultTile2.default).withConfig({
  displayName: 'VehicleGridShortlist__SearchResultTileWrapper',
  componentId: 'sc-aevikx-2'
})(['padding-right:10px;']);

var CloseIconButton = function CloseIconButton(onCloseClick) {
  return function () {
    return _react2.default.createElement(
      IconWrapper,
      { onClick: onCloseClick },
      _react2.default.createElement(_Close2.default, { width: '1.5em', height: '1.3125em', colour: '#ACACAC' })
    );
  };
};

var CompareIconButton = function CompareIconButton(onCompareClick, compareIconColour) {
  return function () {
    return _react2.default.createElement(
      IconWrapper,
      { onClick: onCompareClick },
      _react2.default.createElement(_Compare2.default, { width: '2em', height: '1.75em', colour: compareIconColour })
    );
  };
};

var HeartIconButton = function HeartIconButton(onHeartClick, heartIconColour) {
  return function () {
    return _react2.default.createElement(
      IconWrapper,
      { onClick: onHeartClick },
      _react2.default.createElement(_Heart2.default, { width: '2em', height: '1.75em', colour: heartIconColour })
    );
  };
};

var ModelGridTileWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGridShortlist__ModelGridTileWrapper',
  componentId: 'sc-aevikx-3'
})(['display:flex;margin-bottom:16px;', ';', ';', ';'], function (_ref) {
  var numberOfColumns = _ref.numberOfColumns;
  return '' + (numberOfColumns && 'width: ' + (100 / numberOfColumns - 1) + '%');
}, _theme2.default.max.medium(_templateObject), _theme2.default.max.small(_templateObject2));

var GridViewWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGridShortlist__GridViewWrapper',
  componentId: 'sc-aevikx-4'
})(['width:100%;display:flex;flex-wrap:wrap;justify-content:', ';flex:1 0 0%;'], function (_ref2) {
  var useLegacyTiles = _ref2.useLegacyTiles;
  return useLegacyTiles ? 'center' : 'space-between';
});

var DoubleEmptyTile = function DoubleEmptyTile() {
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(ModelGridTileWrapper, { numberOfColumns: 4 }),
    _react2.default.createElement(ModelGridTileWrapper, { numberOfColumns: 4 })
  );
};

function VehicleGridShortlist(_ref3) {
  var config = _ref3.config,
      vehicles = _ref3.vehicles,
      placeholdingImage = _ref3.placeholdingImage,
      onCloseClick = _ref3.onCloseClick,
      compareVehicle = _ref3.compareVehicle,
      shortlistVehicle = _ref3.shortlistVehicle,
      onMoreInfoClick = _ref3.onMoreInfoClick,
      getCompareIconColour = _ref3.getCompareIconColour,
      getShortListedIconColour = _ref3.getShortListedIconColour,
      selectedView = _ref3.selectedView,
      sortValue = _ref3.sortValue,
      sortValueDictionary = _ref3.sortValueDictionary,
      globalStyling = _ref3.globalStyling,
      locale = _ref3.locale,
      handleZeroPriceAsText = _ref3.handleZeroPriceAsText,
      onEnquiryClick = _ref3.onEnquiryClick;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    vehicles && vehicles.length ? _react2.default.createElement(
      Container,
      { length: vehicles.length },
      selectedView === 'GRID' ? _react2.default.createElement(
        GridViewWrapper,
        { useLegacyTiles: config.useLegacyTiles },
        (0, _from2.default)(new _set2.default(vehicles.map(function (v) {
          return v.id;
        }))).map(function (id) {
          return (0, _extends3.default)({}, vehicles.find(function (v) {
            return v.id === id;
          }));
        }).sort(sortValueDictionary[sortValue]).map(function (vehicle) {
          return config.useLegacyTiles ? _react2.default.createElement(SearchResultTileWrapper, {
            key: vehicle.id,
            align: 'left',
            onHeartClick: function onHeartClick() {},
            carResult: vehicle,
            placeholdingImage: placeholdingImage,
            checkLength: vehicles.length < 3,
            onMoreInfoClicked: function onMoreInfoClicked() {
              return onMoreInfoClick('VDP', vehicle);
            },
            translations: config.translations,
            filtersExpanded: false,
            renderTopRightIcon: CloseIconButton(function () {
              return onCloseClick(vehicle);
            }),
            renderBottomIcon1: CompareIconButton(function () {
              return compareVehicle(vehicle);
            }, getCompareIconColour(vehicle.id)),
            renderBottomIcon2: HeartIconButton(function () {
              return shortlistVehicle(vehicle);
            }, getShortListedIconColour(vehicle.id)),
            globalStyling: globalStyling,
            visibility: config.visibility && config.visibility.tiles,
            locale: locale,
            headerItemFont: config.headerItemFont,
            badgeStyling: config.badgeStyling,
            pricingSectionImage: config.pricingSectionImage,
            VehicleTitleFont: config.vehicleTitleFont,
            vehicleSubTitleFont: config.vehicleSubTitleFont,
            backgroundColour: config.backgroundColour,
            actionRowStyles: config.actionRowStyles,
            moreInfoButtonRightAlign: config.moreInfoButtonRightAlign,
            vehicleSpecContainer: config.vehicleSpecContainer,
            countDivMargin: config.countDivMargin,
            compareShortlistIconWidth: config.compareShortlistIconWidth,
            convertedKwToHp: config && config.convertedKwToHp,
            config: config
          }) : _react2.default.createElement(
            ModelGridTileWrapper,
            {
              numberOfColumns: config.gridViewColumns,
              key: vehicle.id
            },
            _react2.default.createElement(_SearchResultGridTile2.default, {
              config: config,
              shortList: true,
              compareVehicle: compareVehicle,
              shortlistVehicle: shortlistVehicle,
              placeholdingImage: placeholdingImage,
              translations: config.translations,
              align: 'left',
              vehicle: vehicle,
              onMoreInfoClicked: function onMoreInfoClicked() {
                return onMoreInfoClick('VDP', vehicle);
              },
              onEnquiryClick: function (_onEnquiryClick) {
                function onEnquiryClick() {
                  return _onEnquiryClick.apply(this, arguments);
                }

                onEnquiryClick.toString = function () {
                  return _onEnquiryClick.toString();
                };

                return onEnquiryClick;
              }(function () {
                return onEnquiryClick(vehicle.id);
              }),
              shortListedIconColour: getShortListedIconColour(vehicle.id),
              compareIconColour: getCompareIconColour(vehicle.id),
              globalStyling: globalStyling,
              visibility: config.visibility && config.visibility.tiles,
              locale: locale,
              tileBackgroundColor: config.tileBackgroundColor,
              showLinearBackground: config.showLinearBackground,
              headerItemFont: config.headerItemFont,
              badgeStyling: config.badgeStyling,
              handleZeroPriceAsText: handleZeroPriceAsText,
              showNumberOfImagesInsteadOfViews: config.showNumberOfImagesInsteadOfViews,
              countDivMargin: config.countDivMargin,
              compareShortlistIconWidth: config.compareShortlistIconWidth,
              icon360: config.icon360
            })
          );
        }),
        config.gridViewColumns === 3 && vehicles.length % 3 === 2 && _react2.default.createElement(ModelGridTileWrapper, { numberOfColumns: 3 }),
        config.gridViewColumns === 4 && vehicles.length % 4 === 3 && _react2.default.createElement(ModelGridTileWrapper, { numberOfColumns: 4 }),
        config.gridViewColumns === 4 && vehicles.length % 4 === 2 && _react2.default.createElement(DoubleEmptyTile, null)
      ) : null,
      selectedView === 'LIST' ? (0, _from2.default)(new _set2.default(vehicles.map(function (v) {
        return v.id;
      }))).map(function (id) {
        return (0, _extends3.default)({}, vehicles.find(function (v) {
          return v.id === id;
        }));
      }).sort(sortValueDictionary[sortValue]).map(function (vehicle) {
        return config.useLegacyTiles ? _react2.default.createElement(_SearchResultBlock2.default, {
          key: vehicle.id,
          vehicle: vehicle,
          placeholdingImage: placeholdingImage,
          translations: config.translations,
          onMoreInfoClick: onMoreInfoClick,
          vehiclePriceSectionWidth: 25,
          displayImage: true,
          compareVehicle: compareVehicle,
          shortlistVehicle: shortlistVehicle,
          renderTopRightIcon: CloseIconButton(function () {
            return onCloseClick(vehicle);
          }),
          shortListedIconColour: getShortListedIconColour(vehicle.id),
          compareIconColour: getCompareIconColour(vehicle.id),
          globalStyling: globalStyling,
          visibility: config.visibility && config.visibility.tiles,
          locale: locale,
          badgeStyling: config.badgeStyling,
          headerItemFont: config.headerItemFont,
          pricingSectionImage: config.pricingSectionImage,
          financeHeadingFont: config.financeHeadingFont,
          financeSubHeadingFont: config.financeSubHeadingFont,
          vehicleTitleFont: config.vehicleTitleFont,
          vehicleSubTitleFont: config.vehicleSubTitleFont,
          backgroundColour: config.backgroundColour,
          financeWidthOverride: config.financeWidthOverride,
          financePaddingOverride: config.financePaddingOverride,
          actionRowStyles: config.actionRowStyles,
          moreInfoButtonRightAlign: config.moreInfoButtonRightAlign,
          vehicleSpecContainer: config.vehicleSpecContainer,
          icon360: config.icon360,
          iconYoutubeVideo: config.iconYoutubeVideo,
          convertedKwToHp: config && config.convertedKwToHp,
          config: config
        }) : _react2.default.createElement(_SearchResultGridBlock2.default, {
          config: config,
          key: vehicle.id,
          translations: config.translations,
          vehicle: vehicle,
          placeholdingImage: placeholdingImage,
          onMoreInfoClick: onMoreInfoClick,
          compareVehicle: compareVehicle,
          shortlistVehicle: shortlistVehicle,
          compareIconColour: getCompareIconColour(vehicle.id),
          shortListedIconColour: getShortListedIconColour(vehicle.id),
          renderTopRightIcon: CloseIconButton(function () {
            return onCloseClick(vehicle);
          }),
          globalStyling: globalStyling,
          visibility: config.visibility && config.visibility.tiles,
          locale: locale,
          tileBackgroundColor: config.tileBackgroundColor,
          headerItemFont: config.headerItemFont,
          badgeStyling: config.badgeStyling,
          listViewImageWidth: config.listViewImageWidth,
          handleZeroPriceAsText: handleZeroPriceAsText,
          showNumberOfImagesInsteadOfViews: config.showNumberOfImagesInsteadOfViews,
          imageWidth: '30',
          icon360: config.icon360,
          iconYoutubeVideo: config.iconYoutubeVideo,
          onEnquiryClick: function (_onEnquiryClick2) {
            function onEnquiryClick() {
              return _onEnquiryClick2.apply(this, arguments);
            }

            onEnquiryClick.toString = function () {
              return _onEnquiryClick2.toString();
            };

            return onEnquiryClick;
          }(function () {
            return onEnquiryClick(vehicle.id);
          })
        });
      }) : null
    ) : null
  );
}