'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var _SectionIcons = require('../../helpers/SectionIcons');

var _SectionIcons2 = _interopRequireDefault(_SectionIcons);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var hintText = function hintText(item) {
  return item && item.label ? '' : 'The default placeholder for videos is the first frame of the video. If you would like an alternative image to be used, please upload it here.';
};

var config = (0, _config.Config)('VideoPlayer', { id: 'video-player' }, (0, _config.Slice)('Content', { id: 'content' }, (0, _config.Section)('Video', { icon: _SectionIcons2.default.TopTier }, (0, _config.Video)('Video File', 'videoFile')), (0, _config.Section)('Poster', { icon: _SectionIcons2.default.TopTier }, (0, _config.Image)('Image', 'posterImage', { hintText: hintText })), (0, _config.Section)('Text Overlay', { icon: _SectionIcons2.default.TopTier }, (0, _config.TextArea)('Heading Row 1', 'heading'), (0, _config.TextArea)('Heading Row 2', 'subheading'), (0, _config.TextArea)('Paragraph Row 1', 'body'), (0, _config.TextArea)('Paragraph Row 2', 'footer'))), (0, _config.Slice)('Styling', { id: 'styling' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.TopTier }, (0, _config.Font)('Heading Font', 'headingFont'), (0, _config.Font)('Body Font', 'bodyFont'), (0, _config.Toggle)('Overlay', 'overlayVisible'), (0, _config.Colour)('Background Colour', 'backgroundColour'), (0, _config.Pixels)('Padding Vertical', 'paddingVertical'), (0, _config.Pixels)('Padding Horizontal', 'paddingHorizontal'))));

exports.default = config;