'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 10px 20px;\n    display: flex;\n    flex-direction: column;\n  '], ['\n    padding: 10px 20px;\n    display: flex;\n    flex-direction: column;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHelmet = require('react-helmet');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FinanceContainer = require('../../containers/FinanceContainer');

var _FinanceContainer2 = _interopRequireDefault(_FinanceContainer);

var _PathnameSubNavBar = require('../../components/PathnameSubNavBar');

var _PathnameSubNavBar2 = _interopRequireDefault(_PathnameSubNavBar);

var _VehicleModel = require('../../components/Range/VehicleModel');

var _VehicleModel2 = _interopRequireDefault(_VehicleModel);

var _RepresentativeExample = require('../../components/RepresentativeExample');

var _RepresentativeExample2 = _interopRequireDefault(_RepresentativeExample);

var _router = require('../../actions/router');

var _searchFilter = require('../../shared/searchFilter');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _numbers = require('../../shared/localisation/numbers');

var _routing = require('../../shared/routing');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RepresentativeExampleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RepresentativeExampleContainer'
}).withConfig({
  displayName: 'Range__RepresentativeExampleContainer',
  componentId: 'sc-gtfcsf-0'
})(['', ';'], _theme2.default.min.large(_templateObject));

var Range = function (_Component) {
  (0, _inherits3.default)(Range, _Component);

  function Range() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Range);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Range.__proto__ || (0, _getPrototypeOf2.default)(Range)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'onSearchClick', {
      enumerable: true,
      writable: true,
      value: function value(model) {
        var _this$props = _this.props,
            dispatch = _this$props.dispatch,
            _this$props$marketInf = _this$props.marketInfo,
            make = _this$props$marketInf.make,
            market = _this$props$marketInf.market;

        dispatch(_searchFilter.actions.resetFilters());
        dispatch(_searchFilter.actions.filterByModelRange({
          section: 'models',
          filter: model
        }));
        dispatch(_searchFilter.actions.getSearchFilters({
          market: market,
          make: make,
          filters: 'models=' + model
        }));
        dispatch(_router.actions.navigate('/searchresults'));
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Range, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // We call init to force a first run of the reducer
      // - there used to be a more generic solution,
      //   but it broke the builder
      var getModelGroups = this.props.actions.getModelGroups;


      if (!this.props.state.modelGroups || this.props.state.modelGroups.length === 0) {
        getModelGroups({ preview: this.props.preview });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          preview = _props.preview,
          config = _props.config,
          dispatch = _props.dispatch,
          shared = _props.shared,
          history = _props.history,
          state = _props.state,
          marketInfo = _props.marketInfo,
          modelGroups = _props.state.modelGroups;


      var representativeExample = preview ? {} : shared.financeCalculator.representativeExample;
      var vehicle = history.location.pathname.split('/').pop().replace(/\s/g, '');
      var navigateToFinance = function navigateToFinance() {
        return dispatch(_router.actions.navigate('/financeoptions'));
      };
      var patchWithModelGroupData = function patchWithModelGroupData(vehicleModel) {
        var _ref2 = (modelGroups || []).find(function (_ref3) {
          var model = _ref3.model;
          return model.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/_/g, ' ').replace(/\s/g, '') === vehicleModel.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/_/g, ' ').replace(/\s/g, '');
        }) || {},
            priceFrom = _ref2.priceFrom,
            description = _ref2.description,
            imageUrl = _ref2.imageUrl,
            modelName = _ref2.model,
            modelDisplay = _ref2.modelDisplay;

        var localisedPriceFrom = priceFrom && (0, _numbers.localiseCurrency)(priceFrom, marketInfo.locale, 'EUR', 0);
        return {
          priceFrom: localisedPriceFrom,
          description: description,
          imageUrl: imageUrl,
          searchModelName: modelDisplay || modelName
        };
      };

      var lowerCaseLinks = config.modelLinks.map(function (ml) {
        return ml.toLowerCase();
      });
      var links = (state.modelGroups || []).filter(function (model) {
        return lowerCaseLinks.includes(model.model.toLowerCase());
      }).map(function (_ref4) {
        var model = _ref4.model,
            modelDisplay = _ref4.modelDisplay;
        return {
          text: modelDisplay || model,
          onClick: function onClick() {
            return dispatch(_router.actions.navigate((0, _routing.formatUrl)('range/' + model)));
          },
          availableOnMobile: true,
          path: (0, _routing.formatUrl)('range/' + model)
        };
      });

      var _props2 = this.props,
          _props2$config = _props2.config,
          items = _props2$config.items,
          translations = _props2$config.translations,
          subNavBGColour = _props2$config.subNavBGColour,
          subNavBorderColour = _props2$config.subNavBorderColour,
          subNavChevronColour = _props2$config.subNavChevronColour,
          subNavFont = _props2$config.subNavFont,
          subNavBarItemSelectedBackgroundColour = _props2$config.subNavBarItemSelectedBackgroundColour,
          subNavBarItemSelectedFontColour = _props2$config.subNavBarItemSelectedFontColour,
          globalStyling = _props2.globalStyling;


      var vehicleFromConfig = items.find(function (model) {
        return model.key === vehicle.toLowerCase();
      });
      var vehicleModel = (0, _extends3.default)({}, vehicleFromConfig, patchWithModelGroupData(vehicle));

      var featureFlags = this.props.featureFlags;


      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _reactHelmet.Helmet,
          null,
          vehicleModel.searchModelName && _react2.default.createElement(
            'title',
            null,
            (0, _translateFromTemplate.translateFromTemplate)('rangeTabTitle', {
              MODEL: vehicleModel.searchModelName
            }, translations)
          )
        ),
        _react2.default.createElement(_PathnameSubNavBar2.default, {
          title: vehicleModel.searchModelName,
          links: [].concat((0, _toConsumableArray3.default)(links)),
          subNavBGColour: subNavBGColour,
          subNavBorderColour: subNavBorderColour,
          subNavChevronColour: subNavChevronColour,
          subNavFontTheme: subNavFont,
          subNavBarItemSelectedBackgroundColour: subNavBarItemSelectedBackgroundColour,
          subNavBarItemSelectedFontColour: subNavBarItemSelectedFontColour,
          contentWidth: globalStyling.contentWidth,
          history: history
        }),
        featureFlags.finance && config.showRepresentativeExample && _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          _react2.default.createElement(
            RepresentativeExampleContainer,
            null,
            _react2.default.createElement(_FinanceContainer2.default, {
              component: function component(props) {
                return _react2.default.createElement(_RepresentativeExample2.default, (0, _extends3.default)({}, props, {
                  representativeExample: representativeExample,
                  preview: preview
                }));
              }
            })
          )
        ),
        vehicleFromConfig ? _react2.default.createElement(_VehicleModel2.default, {
          vehicle: vehicleModel,
          searchForModel: this.onSearchClick,
          navigateToFinance: navigateToFinance,
          translations: translations,
          globalStyling: globalStyling,
          featureFlags: featureFlags,
          contentWidth: globalStyling.contentWidth,
          videoContentLeft: config.videoContentLeft
        }) : _react2.default.createElement(
          'div',
          null,
          translations.modelNotFound
        )
      );
    }
  }]);
  return Range;
}(_react.Component);

exports.default = Range;