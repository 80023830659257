'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FullBleedBG = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['margin-left: 24px;'], ['margin-left: 24px;']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']); /* eslint-disable arrow-parens */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../../components/Global');

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _AstonLink = require('../../../components/AstonMartin/AstonLink');

var _AstonLink2 = _interopRequireDefault(_AstonLink);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _Video = require('./Video');

var _Video2 = _interopRequireDefault(_Video);

var _Image = require('./Image');

var _Image2 = _interopRequireDefault(_Image);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SlideContainer = _styledComponents2.default.div.withConfig({ displayName: 'SlideContainer' }).withConfig({
  displayName: 'fullBleedBackground__SlideContainer',
  componentId: 'sc-ycbf2s-0'
})(['display:flex;flex-direction:column;min-height:1px;']);

var SlideOneContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SlideOneContentContainer'
}).withConfig({
  displayName: 'fullBleedBackground__SlideOneContentContainer',
  componentId: 'sc-ycbf2s-1'
})(['position:absolute;bottom:68px;text-align:left;margin-left:64px;margin-right:auto;left:0;right:0;', ' ', ''], _theme2.default.max.medium(_templateObject), function (_ref) {
  var width = _ref.width;
  return width && 'width: ' + width + 'px; left: calc(50% - ' + width + 'px / 2);';
});

var TopSlideContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TopSlideContentContainer'
}).withConfig({
  displayName: 'fullBleedBackground__TopSlideContentContainer',
  componentId: 'sc-ycbf2s-2'
})(['display:flex;flex-direction:column;font-weight:bold;margin:0 auto;', ' ', ';'], function (_ref2) {
  var width = _ref2.width;
  return width && 'width : ' + width + 'px;';
}, _theme2.default.max.medium(_templateObject2));

var SectionChevron = _styledComponents2.default.img.withConfig({
  displayName: 'fullBleedBackground__SectionChevron',
  componentId: 'sc-ycbf2s-3'
})(['cursor:pointer;position:absolute;color:white;left:calc(50% - 18px);bottom:50px;']);

var headingOneStyleOverride = function headingOneStyleOverride(headerTheme) {
  return '\nposition: relative;\nz-index: 2;\nheight: auto;\noverflow-wrap: break-word;\n' + (headerTheme.fontFamily && 'font-family: ' + headerTheme.fontFamily + ';') + '\n' + (headerTheme.textTransform && 'text-transform: ' + headerTheme.textTransform + ';') + '\n' + (headerTheme.fontSize && 'font-size: ' + headerTheme.fontSize + ';') + '\n' + (headerTheme.colour && 'color: ' + headerTheme.colour + ';') + '\n' + (headerTheme.characterSpacing && 'letter-spacing: ' + headerTheme.characterSpacing + ';') + '\n';
};

var bodyStyleOverride = function bodyStyleOverride(bodyTheme) {
  return '\nposition: relative;\nz-index: 2;\nheight: auto;\noverflow-wrap: break-word;\n' + (bodyTheme.fontFamily && 'font-family: ' + bodyTheme.fontFamily + ';') + '\n' + (bodyTheme.textTransform && 'text-transform: ' + bodyTheme.textTransform + ';') + '\n' + (bodyTheme.fontSize && 'font-size: ' + bodyTheme.fontSize + ';') + '\n' + (bodyTheme.colour && 'color: ' + bodyTheme.colour + ';') + '\n' + (bodyTheme.characterSpacing && 'letter-spacing: ' + bodyTheme.characterSpacing + ';') + '\n';
};

var FullBleedBG = exports.FullBleedBG = function FullBleedBG(slideProps) {
  var bodyMobileFontColour = slideProps.bodyMobileFontColour,
      bodyMobileFontSize = slideProps.bodyMobileFontSize,
      bodyTheme = slideProps.bodyTheme,
      contentWidth = slideProps.contentWidth,
      dispatch = slideProps.dispatch,
      headerMobileFontColour = slideProps.headerMobileFontColour,
      headerMobileFontSize = slideProps.headerMobileFontSize,
      headerTheme = slideProps.headerTheme,
      link = slideProps.link,
      _slideProps$mediaCont = slideProps.mediaContent,
      mediaContent = _slideProps$mediaCont === undefined ? { value: '', label: '', type: 'image' } : _slideProps$mediaCont,
      onLinkClick = slideProps.onLinkClick,
      _slideProps$preview = slideProps.preview,
      preview = _slideProps$preview === undefined ? false : _slideProps$preview,
      slideContentWidth = slideProps.slideContentWidth,
      translations = slideProps.translations,
      url = slideProps.url;

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl;

  return _react2.default.createElement(
    SlideContainer,
    { dispatch: dispatch },
    mediaContent.type === 'image' && _react2.default.createElement(_Image2.default, {
      url: url,
      preview: preview,
      mediaContent: mediaContent,
      onLinkClick: onLinkClick
    }),
    mediaContent.type === 'video' && _react2.default.createElement(_Video2.default, { url: url, preview: preview, mediaContent: mediaContent, onLinkClick: onLinkClick }),
    mediaContent.fullScreen && _react2.default.createElement(SectionChevron, {
      src: mediaContent.sectionChevron,
      onClick: function onClick() {
        return onLinkClick(mediaContent.sectionURL);
      }
    }),
    _react2.default.createElement(
      _ContentWrapper2.default,
      { contentWidth: slideContentWidth },
      _react2.default.createElement(
        SlideOneContentContainer,
        { width: contentWidth },
        _react2.default.createElement(
          TopSlideContentContainer,
          null,
          translations.header && _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n                    margin: 0 0 45px 0;                    \n                    line-height: 1.1;\n                    ' + headingOneStyleOverride(headerTheme) + '\n                  ';
              },
              mobileStyleOverride: function mobileStyleOverride() {
                return [{
                  queryPath: 'max.small',
                  template: '\n                       ' + (headerMobileFontSize && 'font-size : ' + headerMobileFontSize + 'px;') + '\n                       ' + (headerMobileFontColour && 'color : ' + headerMobileFontColour)
                }];
              }
            },
            translations.header
          ),
          translations.body && _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n                    margin: 0 0 20px 0;\n                    ' + bodyStyleOverride(bodyTheme) + '\n                  ';
              },
              mobileStyleOverride: function mobileStyleOverride() {
                return [{
                  queryPath: 'max.small',
                  template: '\n                       ' + (bodyMobileFontSize && 'font-size : ' + bodyMobileFontSize + 'px;') + '\n                       ' + (bodyMobileFontColour && 'color : ' + bodyMobileFontColour)
                }];
              }
            },
            translations.body.replace(/-/gm, '-\u2060')
          )
        ),
        link && _react2.default.createElement(_AstonLink2.default, {
          text: translations.link,
          target: getTargetUrl(link.url),
          link: link.url,
          fontFamily: headerTheme.fontFamily
        })
      )
    ),
    _react2.default.createElement(_reactWaypoint2.default, {
      dispatch: dispatch,
      topOffset: '20%',
      onPositionChange: function onPositionChange(props) {
        return dispatch(_sessionPreferences.actions.updateSessionPreferences('isHeroVisible', props.currentPosition === 'inside'));
      }
    })
  );
};