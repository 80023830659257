'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']);

exports.default = ContentWrapper;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ContentWrapper__Container',
  componentId: 'sc-12zicjs-0'
})(['@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){min-height:1px;}width:100%;max-height:100%;display:flex;box-sizing:border-box;', ';', ';'], function (_ref) {
  var colour = _ref.colour;
  return colour && 'background-color: ' + colour.value;
}, function (_ref2) {
  var borderColour = _ref2.borderColour;
  return borderColour && 'border-top: 1px solid ' + borderColour.value;
});

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ContentWrapper__Wrapper',
  componentId: 'sc-12zicjs-1'
})(['min-width:1px;margin:0 auto;', ';', ';', ';', ';', ';', ';'], function (_ref3) {
  var relativePosition = _ref3.relativePosition;
  return relativePosition && 'position: relative';
}, function (_ref4) {
  var contentWidth = _ref4.contentWidth;
  return 'width: ' + (contentWidth ? contentWidth + 'px' : '100%');
}, function (_ref5) {
  var contentHeight = _ref5.contentHeight;
  return contentHeight && 'height: ' + contentHeight + 'px';
}, function (_ref6) {
  var marginTopBottom = _ref6.marginTopBottom;
  return marginTopBottom && 'margin: ' + marginTopBottom + ' auto';
}, function (_ref7) {
  var flex = _ref7.flex;
  return flex && 'display: flex';
}, _theme2.default.max.extraLarge(_templateObject));

function ContentWrapper(_ref8) {
  var children = _ref8.children,
      colour = _ref8.colour,
      contentWidth = _ref8.contentWidth,
      contentHeight = _ref8.contentHeight,
      flex = _ref8.flex,
      relativePosition = _ref8.relativePosition,
      borderColour = _ref8.borderColour,
      marginTopBottom = _ref8.marginTopBottom,
      id = _ref8.id;

  return _react2.default.createElement(
    Container,
    { colour: colour, borderColour: borderColour, id: id },
    _react2.default.createElement(
      Wrapper,
      {
        contentWidth: contentWidth,
        contentHeight: contentHeight,
        flex: flex,
        relativePosition: relativePosition,
        marginTopBottom: marginTopBottom
      },
      children
    )
  );
}