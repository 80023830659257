'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var buttonAlignMapping = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};


var ButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FormButton__ButtonWrapper',
  componentId: 'sc-1lflpci-0'
})(['display:flex;justify-content:', ';'], function (props) {
  return props.alignment || 'center';
});

exports.default = function (props) {
  var _props$config = props.config,
      translations = _props$config.translations,
      alignment = _props$config.alignment,
      button = _props$config.button,
      _props$globalStyling = props.globalStyling;
  _props$globalStyling = _props$globalStyling === undefined ? {} : _props$globalStyling;
  var _props$globalStyling$ = _props$globalStyling.uiElements,
      uiElements = _props$globalStyling$ === undefined ? {} : _props$globalStyling$,
      handleSubmit = props.handleSubmit;


  return _react2.default.createElement(
    ButtonWrapper,
    { alignment: buttonAlignMapping[alignment] },
    _react2.default.createElement(_Global.Button, {
      buttonStyle: uiElements.primaryButton && uiElements.primaryButton.buttonStyle,
      applyStyle: 'primary',
      styleOverride: function styleOverride() {
        return (0, _Global.buttonStyleOverride)(button);
      },
      text: translations.submitbuttonLabel,
      onClick: handleSubmit
    })
  );
};