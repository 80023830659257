'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FieldSet;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledFieldset = _styledComponents2.default.fieldset.withConfig({
  displayName: 'FieldSet__StyledFieldset',
  componentId: 'sc-ds6k5a-0'
})(['border:0;display:flex;flex-direction:column;margin:0 0 20px;padding:0 0 20px;', ';'], function (props) {
  return props.borderBottom ? (0, _styledComponents.css)(['border-bottom:1px solid #9b9b9b;']) : (0, _styledComponents.css)(['border:0;']);
});

function FieldSet(props) {
  return _react2.default.createElement(StyledFieldset, props);
}

FieldSet.defaultProps = {
  borderBottom: true
};