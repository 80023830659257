'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Tick;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Tick(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height,
      fill = _ref.fill,
      ariaLabelText = _ref.ariaLabelText;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height,
      role: 'checkbox',
      'aria-checked': 'false',
      'aria-label': ariaLabelText
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 29 25' },
      _react2.default.createElement('path', {
        fill: fill,
        d: 'M23.708 5.331a.998.998 0 0 0-1.412 0L10.34 17.29l-4.596-4.597a.998.998 0 0 0-1.412 1.412l5.302 5.302a.998.998 0 0 0 1.412 0L23.708 6.743a.998.998 0 0 0 0-1.412z'
      })
    )
  );
}

Tick.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
  fill: 'none',
  ariaLabelText: null
};