'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  padding: 0 15px;\n  '], ['\n  padding: 0 15px;\n  ']);

exports.default = SelectBar;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useSortResults2 = require('../../hooks/useSortResults');

var _useSortResults3 = _interopRequireDefault(_useSortResults2);

var _useCurrencySelector2 = require('../../hooks/useCurrencySelector');

var _useCurrencySelector3 = _interopRequireDefault(_useCurrencySelector2);

var _useCheckIsMobileScreen = require('../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _settings = require('../../shared/sortOptions/settings');

var _searchResultsTypes = require('../../types/Bentley/searchResultsTypes');

var _Form = require('./Form');

var _sortDropdownListOptions = require('../../constants/sortDropdownListOptions');

var _types = require('./Shortlist/types');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SelectBar__Container',
  componentId: 'sc-135nswg-0'
})(['display:flex;width:', ';justify-content:space-between;padding-bottom:', ';', ''], function (_ref) {
  var fullWidth = _ref.fullWidth;
  return fullWidth ? '100%' : 'auto';
}, function (_ref2) {
  var minimal = _ref2.minimal;
  return minimal ? '0' : '30px';
}, _theme2.default.max.medium(_templateObject));

var Sort = _styledComponents2.default.div.withConfig({
  displayName: 'SelectBar__Sort',
  componentId: 'sc-135nswg-1'
})(['display:flex;']);
var Currency = _styledComponents2.default.div.withConfig({
  displayName: 'SelectBar__Currency',
  componentId: 'sc-135nswg-2'
})(['display:flex;margin-left:10px;']);

function SelectBar(_ref3) {
  var currencies = _ref3.currencies,
      currencyValue = _ref3.currencyValue,
      searchSort = _ref3.searchSort,
      translations = _ref3.translations,
      minimal = _ref3.minimal,
      fullWidth = _ref3.fullWidth;

  var sortValue = searchSort && searchSort.value;

  var isMobile = (0, _useCheckIsMobileScreen2.default)();

  var _useSortResults = (0, _useSortResults3.default)({ sortOptions: _settings.sortOptions }),
      onSortChange = _useSortResults.onSortChange;

  var _useCurrencySelector = (0, _useCurrencySelector3.default)(),
      onCurrencyChange = _useCurrencySelector.onCurrencyChange;

  var sortOptionsField = function sortOptionsField(fieldValue) {
    return ((0, _values2.default)(_settings.sortOptions).find(function (so) {
      return so.value === fieldValue;
    }) || {}).field;
  };

  var sortOptionsOrder = function sortOptionsOrder(fieldValue) {
    return ((0, _values2.default)(_settings.sortOptions).find(function (so) {
      return so.value === fieldValue;
    }) || {}).order;
  };

  var updateFilters = function updateFilters(sortField) {
    var payload = [{ key: 'sortField', value: [sortOptionsField(sortField)] }, { key: 'sortDirection', value: [sortOptionsOrder(sortField)] }];
    onSortChange(payload, sortField);
  };
  var currencyOptions = currencies ? currencies.filter(function (c) {
    return isMobile ? c !== '' : true;
  }).map(function (c) {
    return {
      value: c,
      label: c ? translations['convertTo' + c] : translations.pleaseSelectCurrency
    };
  }) : [];

  return _react2.default.createElement(
    Container,
    {
      minimal: minimal,
      fullWidth: fullWidth,
      onClick: function onClick(e) {
        return e.stopPropagation();
      }
    },
    _react2.default.createElement(
      Sort,
      null,
      _react2.default.createElement(_Form.SelectField, {
        dataCy: 'sort-dropdown',
        onChange: function onChange(e) {
          return updateFilters(e.value);
        },
        id: 'sort',
        value: sortValue,
        removeMargin: true,
        options: (0, _sortDropdownListOptions.sortDropdownListOptions)(translations),
        minimal: minimal,
        label: minimal ? translations.sortBy : null,
        orientation: 'horizontal'
      })
    ),
    _react2.default.createElement(
      Currency,
      null,
      _react2.default.createElement(_Form.SelectField, {
        dataCy: 'currency-dropdown',
        onChange: function onChange(e) {
          return onCurrencyChange(e.value);
        },
        id: 'currency',
        minimal: minimal,
        removeMargin: true,
        value: currencyValue,
        label: minimal ? translations.currency : null,
        orientation: 'horizontal',
        options: currencyOptions
      })
    )
  );
}