'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AdditionalFeatures = undefined;

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding-top: 50px;\n  '], ['\n    padding-top: 50px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 50px 0;\n'], ['\n    padding: 50px 0;\n']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 22px;\n'], ['\n    font-size: 22px;\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n     flex-direction: row;\n  '], ['\n     flex-direction: row;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n\n  '], ['\n    width: 100%;\n\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n  width: 100%;\n  padding: 0px;\n'], ['\n  width: 100%;\n  padding: 0px;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../../Global');

var _types = require('../types');

var _vehicleTypes = require('../../../../types/RollsRoyce/vehicleTypes');

var _utils = require('./utils/utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var KEY_FEATURE_NUMBER = 12;
var ADDITIONAL_EQUIPMENT_KEY = 'default';
var NUMBER_COLUMNS = 3;

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'AdditionalFeatures__Container',
  componentId: 'sc-ix97fq-0'
})(['display:flex;flex-direction:column;padding:100px 0;', ';', ';'], _theme2.default.max.large(_templateObject), _theme2.default.max.medium(_templateObject2));

var Header = (0, _styledComponents2.default)(_Global.HeadingTwo).withConfig({
  displayName: 'AdditionalFeatures__Header',
  componentId: 'sc-ix97fq-1'
})(['font-size:32px;letter-spacing:2px;text-transform:uppercase;width:100%;padding-bottom:10px;text-align:center;display:inline-block;padding-bottom:50px;', ';'], _theme2.default.max.large(_templateObject3));

var ItemsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'AdditionalFeatures__ItemsContainer',
  componentId: 'sc-ix97fq-2'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.min.large(_templateObject4));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'AdditionalFeatures__Item',
  componentId: 'sc-ix97fq-3'
})(['display:flex;min-height:40px;height:auto;align-items:center;gap:10px;border-bottom:1px solid #151515;padding-top:10px;font-size:14px;line-height:20px;letter-spacing:0.5px;color:#151515;font-weight:300;', ';'], _theme2.default.max.large(_templateObject5));

var ItemColumn = _styledComponents2.default.div.withConfig({
  displayName: 'AdditionalFeatures__ItemColumn',
  componentId: 'sc-ix97fq-4'
})(['display:flex;flex-direction:column;padding:0 16px;width:30%;', ';'], _theme2.default.max.large(_templateObject6));

var createItemColumn = function createItemColumn(items) {
  return _react2.default.createElement(
    ItemColumn,
    null,
    items.map(function (name, index) {
      return _react2.default.createElement(
        Item,
        { key: 'additional-feature-' + index.toString() },
        name
      );
    })
  );
};

var AdditionalFeatures = function AdditionalFeatures(_ref) {
  var translations = _ref.translations,
      vehicle = _ref.vehicle;

  var _ref2 = vehicle || {},
      _ref2$bespokeFeatures = _ref2.bespokeFeatures,
      bespokeFeatures = _ref2$bespokeFeatures === undefined ? [] : _ref2$bespokeFeatures,
      _ref2$otherEquipment = _ref2.otherEquipment,
      otherEquipment = _ref2$otherEquipment === undefined ? [] : _ref2$otherEquipment;

  var additionalKeyFeatures = Array.isArray(bespokeFeatures) && bespokeFeatures.length > KEY_FEATURE_NUMBER ? bespokeFeatures.slice(KEY_FEATURE_NUMBER).map(_utils.getName) : [];

  var additionalEquipmentFeatures = Array.isArray(otherEquipment) ? otherEquipment.find(function (item) {
    return item.category === ADDITIONAL_EQUIPMENT_KEY;
  }) || { equipment: [] } : { equipment: [] };

  var additionalEquipment = [].concat((0, _toConsumableArray3.default)(additionalKeyFeatures), (0, _toConsumableArray3.default)(additionalEquipmentFeatures.equipment));

  var chunkedItems = (0, _utils.chunkArrayByNumber)(additionalEquipment, NUMBER_COLUMNS);

  return _react2.default.createElement(
    _react.Fragment,
    null,
    additionalEquipment.length > 0 && _react2.default.createElement(
      Container,
      null,
      translations.vdpOtherEquipmentHeader && _react2.default.createElement(
        Header,
        null,
        translations.vdpOtherEquipmentHeader
      ),
      _react2.default.createElement(
        ItemsContainer,
        null,
        chunkedItems.map(function (items, index) {
          return _react2.default.createElement(
            _react.Fragment,
            { key: index },
            createItemColumn(items)
          );
        })
      )
    )
  );
};

exports.AdditionalFeatures = AdditionalFeatures;