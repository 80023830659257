'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 25px 25px;\n  '], ['\n    padding: 25px 25px;\n  ']);

exports.default = TextCTA;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _types = require('../../types');

var _Global = require('../../components/Global');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _router = require('../../actions/router');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _useCheckIsMobileScreen = require('../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _globalStyling = require('../../types/globalStyling');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'TextCTAContainer'
}).withConfig({
  displayName: 'TextCTA__Container',
  componentId: 'sc-qfc0n4-0'
})(['text-align:', ';padding:', ' ', ';', ';', ';', ';'], function (_ref) {
  var align = _ref.align;
  return '' + (align || 'unset');
}, function (_ref2) {
  var verticalPadding = _ref2.verticalPadding;
  return (verticalPadding || 32) + 'px';
}, function (_ref3) {
  var horizontalPadding = _ref3.horizontalPadding;
  return (horizontalPadding || 0) + 'px';
}, _theme2.default.max.medium(_templateObject), function (_ref4) {
  var border = _ref4.border,
      bottomBorder = _ref4.bottomBorder;
  return bottomBorder && 'border-bottom: 1px solid ' + border + ';\n  ';
}, function (_ref5) {
  var border = _ref5.border,
      topBorder = _ref5.topBorder;
  return topBorder && 'border-top: 1px solid ' + border + ';\n  ';
});

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TextCTA__ButtonContainer',
  componentId: 'sc-qfc0n4-1'
})(['display:flex;justify-content:center;']);

var LinkContainer = (0, _styledComponents2.default)(ButtonContainer).withConfig({
  displayName: 'TextCTA__LinkContainer',
  componentId: 'sc-qfc0n4-2'
})(['display:flex;justify-content:center;padding:20px 0;']);

var TextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TextCTA__TextContainer',
  componentId: 'sc-qfc0n4-3'
})(['margin:10px;', ';'], function (_ref6) {
  var maxWidth = _ref6.maxWidth;
  return maxWidth && '\n    max-width: ' + maxWidth + 'px;\n    margin: 10px auto;\n  ';
});

var InjectBorders = _styledComponents2.default.div.withConfig({
  displayName: 'TextCTA__InjectBorders',
  componentId: 'sc-qfc0n4-4'
})(['border-top:', ';border-bottom:', ';display:inline-block;'], function (_ref7) {
  var thickness = _ref7.thickness,
      borderStyle = _ref7.borderStyle;
  return (thickness || 0) + 'px ' + (borderStyle || 'solid');
}, function (_ref8) {
  var thickness = _ref8.thickness,
      borderStyle = _ref8.borderStyle;
  return (thickness || '0') + 'px ' + (borderStyle || 'solid');
});

var Link = _styledComponents2.default.a.withConfig({
  displayName: 'TextCTA__Link',
  componentId: 'sc-qfc0n4-5'
})(['text-decoration:none;', ';'], function (_ref9) {
  var colour = _ref9.colour;
  return colour && 'color: ' + colour.value;
});

function TextCTA(_ref10) {
  var config = _ref10.config,
      globalStyling = _ref10.globalStyling;

  var mobile = (0, _useCheckIsMobileScreen2.default)('small');
  var dispatch = (0, _reactRedux.useDispatch)();

  return _react2.default.createElement(
    _ContentWrapper2.default,
    {
      colour: mobile && config.backgroundColourMobile ? config.backgroundColourMobile : config.backgroundColour,
      contentWidth: globalStyling.contentWidth
    },
    _react2.default.createElement(
      Container,
      {
        align: config.alignment,
        horizontalPadding: config.containerHorizontalPadding,
        verticalPadding: config.containerVerticalPadding,
        border: config.borderColour && config.borderColour.value,
        topBorder: !mobile && !!config.topBorder,
        bottomBorder: !mobile && !!config.bottomBorder
      },
      config.translations.headlineText && _react2.default.createElement(
        TextContainer,
        { maxWidth: config.contentMaxWidth },
        _react2.default.createElement(
          _Global.HeadingOne,
          {
            'data-cy': config.translations.headlineText,
            styleOverride: function styleOverride() {
              return '\n                ' + (0, _Global.fontStyleOverride)(config.headlineFont) + '\n                max-width: 800px;\n                margin: auto;\n                ' + (config.headlineFont && config.headlineFont.marginBottom && 'margin-bottom: ' + config.headlineFont.marginBottom + 'px') + ';\n              ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.medium',
                template: '\n                ' + (0, _Global.fontStyleOverride)(config.mobileHeadlineFont)
              }];
            },
            displayHeadingDivider: config.displayHeadingDivider,
            headingDividerStyle: config.headingDividerStyle
          },
          _react2.default.createElement(
            InjectBorders,
            {
              thickness: config.headerBorders && config.headerBorders.active ? config.headerBorders.borderThickness : null,
              borderStyle: config.headerBorders && config.headerBorders.active ? config.headerBorders.borderStyle : null
            },
            config.translations.headlineText
          )
        )
      ),
      config.translations.bodyText && _react2.default.createElement(
        TextContainer,
        { maxWidth: config.contentMaxWidth },
        _react2.default.createElement(
          _Global.Paragraph,
          {
            'data-cy': 'bodyText',
            styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(config.bodyFont) + ';\n                 ' + (config.applyWhiteSpace && 'white-space: pre-wrap;') + ';\n                ';
            }
          },
          config.translations.bodyText
        )
      ),
      config.translations.smallBodyText && _react2.default.createElement(
        TextContainer,
        { maxWidth: config.contentMaxWidth },
        _react2.default.createElement(
          _Global.Paragraph,
          {
            'data-cy': 'smallBodyText',
            styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(config.smallBodyFont) + ' ' + (config.applyWhiteSpace && 'white-space: pre-wrap;');
            }
          },
          config.translations.smallBodyText
        )
      ),
      config.buttonText.on && _react2.default.createElement(
        ButtonContainer,
        null,
        _react2.default.createElement(_Global.Button, {
          className: config.buttonText.analyticsClassName,
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          applyStyle: 'primary',
          styleOverride: function styleOverride() {
            return (0, _Global.buttonStyleOverride)(config.buttonFont, config.buttonBackgroundColour, config.buttonHover, config.buttonSize, config.buttonHoverColour);
          },
          text: config.translations.buttonText,
          onClick: function onClick() {
            return dispatch(_router.actions.navigate(encodeURI(config.buttonText.url).replaceAll(',', '%2C')));
          }
        })
      ),
      config.buttonLink && config.buttonLink.on && config.buttonLink.url && _react2.default.createElement(
        LinkContainer,
        null,
        _react2.default.createElement(
          Link,
          {
            'data-cy': 'privacyFooterLink',
            href: config.buttonLink.url,
            onClick: function onClick(event) {
              event.preventDefault();
              if (!config.buttonLink) {
                return;
              }
              dispatch(_router.actions.navigate(encodeURI(config.buttonLink.url)));
            }
          },
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)(config.bottomLinkFont);
              }
            },
            config.translations.buttonLinkText
          )
        )
      )
    )
  );
}