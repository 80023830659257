'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Emissions = undefined;

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n   margin: 40px 0 120px;\n  '], ['\n   margin: 40px 0 120px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n   font-size: 36px;\n    margin: 10px 0 40px;\n  '], ['\n   font-size: 36px;\n    margin: 10px 0 40px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 1fr;\n    width: 100%;\n    margin: 0;\n  '], ['\n    grid-template-columns: 1fr;\n    width: 100%;\n    margin: 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    text-align: center;\n    ', '\n  '], ['\n    text-align: center;\n    ', '\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    row-gap: 20px;\n  '], ['\n    flex-direction: column;\n    row-gap: 20px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    flex: 1;\n  '], ['\n    flex: 1;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _types = require('../types');

var _vehicleTypes = require('../../../../types/vehicleTypes');

var _vehicleTypes2 = require('../../../../types/Genesis/vehicleTypes');

var _vehicle = require('../../../../helpers/vehicle');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__Wrapper',
  componentId: 'sc-us1eha-0'
})(['margin:40px 0;', ';'], _Genesis2.default.min.medium(_templateObject));

var SectionTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'Emissions__SectionTitle',
  componentId: 'sc-us1eha-1'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Light\';font-size:24px;font-weight:normal;margin:10px 0 20px;text-transform:uppercase;', ';'], _Genesis2.default.min.medium(_templateObject2));

var SectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__SectionWrapper',
  componentId: 'sc-us1eha-2'
})(['font-family:\'GenesisSansTextGlobal-Regular\';color:#fff;display:flex;flex-direction:column;']);

var SectionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__SectionContainer',
  componentId: 'sc-us1eha-3'
})(['width:100%;display:flex;justify-content:center;']);

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__Column',
  componentId: 'sc-us1eha-4'
})(['display:flex;flex-direction:column;width:', ';'], function (_ref) {
  var width = _ref.width;
  return width || '33.33%';
});

var EmissionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionContainer',
  componentId: 'sc-us1eha-5'
})(['margin-bottom:18px;break-inside:avoid;']);

var EmissionsTable = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionsTable',
  componentId: 'sc-us1eha-6'
})(['display:grid;grid-template-columns:1fr 1fr;grid-gap:10px 30px;', ';'], _Genesis2.default.max.large(_templateObject3));

var InfoTableItem = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__InfoTableItem',
  componentId: 'sc-us1eha-7'
})(['display:flex;flex-direction:row;justify-content:space-between;align-items:center;border-bottom:1px solid #acacac;width:100%;', ''], _Genesis2.default.max.small(_templateObject4, function (_ref2) {
  var center = _ref2.center;
  return center && 'justify-content: center;';
}));

var EmissionTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionTitle',
  componentId: 'sc-us1eha-8'
})(['font-family:\'GenesisSansHeadGlobal-Regular\';border-bottom:1px solid #aaaba6;text-transform:uppercase;padding-bottom:8px;']);

var EfficiencyTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EfficiencyTitle',
  componentId: 'sc-us1eha-9'
})(['font-family:\'GenesisSansHeadGlobal-Regular\';border-bottom:1px solid #aaaba6;text-transform:uppercase;padding-bottom:8px;margin-bottom:20px;']);

var EmissionsDisclaimerText = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionsDisclaimerText',
  componentId: 'sc-us1eha-10'
})(['color:#bbb;margin-bottom:10px;']);

var DatLink = _styledComponents2.default.a.withConfig({
  displayName: 'Emissions__DatLink',
  componentId: 'sc-us1eha-11'
})(['color:#fff;text-decoration:none;']);

var SectionSpacer = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__SectionSpacer',
  componentId: 'sc-us1eha-12'
})(['', ' ', ';'], function (_ref3) {
  var spacerSizeSmall = _ref3.spacerSizeSmall;
  return spacerSizeSmall && 'height: ' + spacerSizeSmall + 'px;';
}, _Genesis2.default.min.large(_templateObject5, function (_ref4) {
  var spacerSizeLarge = _ref4.spacerSizeLarge;
  return spacerSizeLarge && 'height: ' + spacerSizeLarge + 'px;';
}));

var InfoTableLabel = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__InfoTableLabel',
  componentId: 'sc-us1eha-13'
})(['text-transform:uppercase;margin:10px 0;']);

var InfoTableValue = (0, _styledComponents2.default)(InfoTableLabel).withConfig({
  displayName: 'Emissions__InfoTableValue',
  componentId: 'sc-us1eha-14'
})(['text-transform:uppercase;text-align:right;letter-spacing:normal;']);

var EnergyEfficiencyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EnergyEfficiencyContainer',
  componentId: 'sc-us1eha-15'
})(['display:flex;flex-direction:column;row-gap:10px;']);

var EnergyEfficiencyTable = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EnergyEfficiencyTable',
  componentId: 'sc-us1eha-16'
})(['display:flex;flex-direction:row;column-gap:30px;', ''], _Genesis2.default.max.medium(_templateObject6));

var EnergyEfficiencyImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EnergyEfficiencyImageContainer',
  componentId: 'sc-us1eha-17'
})(['flex:0.5;display:flex;flex-direction:column;', ''], _Genesis2.default.max.medium(_templateObject7));

var EnergyEfficiencyText = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EnergyEfficiencyText',
  componentId: 'sc-us1eha-18'
})(['flex:1;']);

var Emissions = function Emissions(_ref5) {
  var vehicle = _ref5.vehicle,
      translations = _ref5.translations,
      config = _ref5.config,
      userLocationGermany = _ref5.userLocationGermany;

  var emissionsNull = function emissionsNull(emissions) {
    return (0, _keys2.default)(emissions).every(function (key) {
      return emissions[key] === null;
    });
  };

  var emissionsData = (0, _react.useMemo)(function () {
    var nedcConsumption = {
      category: 'nedcConsumptions',
      items: [vehicle.consumption.nedcConsumptionLow ? {
        label: 'urban',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.nedcConsumptionLow)
      } : null, vehicle.consumption.nedcConsumptionMid ? {
        label: 'extraUrban',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.nedcConsumptionMid)
      } : null, vehicle.consumption.nedcConsumptionCombined ? {
        label: 'nedcConsumptionCombined',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.nedcConsumptionCombined)
      } : null].filter(function (item) {
        return item !== null;
      })
    };

    var nedcEmissions = {
      category: 'nedcEmissions',
      items: [vehicle.emissions.nedcEmissionsCombined ? {
        label: 'nedcEmissionsCombined',
        value: vehicle.emissions.nedcEmissionsCombined + ' g/km'
      } : null]
    };

    var wltpConsumptions = {
      category: 'wltpConsumptions',
      items: [vehicle.consumption.wltpConsumptionLow ? {
        label: 'wltpConsumptionLow',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionLow)
      } : null, vehicle.consumption.wltpConsumptionMid ? {
        label: 'wltpConsumptionMid',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionMid)
      } : null, vehicle.consumption.wltpConsumptionHigh ? {
        label: 'wltpConsumptionHigh',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionHigh)
      } : null, vehicle.consumption.wltpConsumptionExtraHigh ? {
        label: 'wltpConsumptionExtraHigh',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionExtraHigh)
      } : null, vehicle.consumption.wltpConsumptionCombined ? {
        label: 'wltpConsumptionCombined',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionCombined)
      } : null].filter(function (item) {
        return item !== null;
      })
    };

    var wltpEmissions = {
      category: 'wltpEmissions',
      items: [vehicle.emissions.wltpEmissionsCombined ? {
        label: 'wltpEmissionsCombined',
        value: vehicle.emissions.wltpEmissionsCombined + ' g/km'
      } : null]
    };

    return [nedcConsumption, nedcEmissions, wltpConsumptions, wltpEmissions].filter(function (category) {
      return category.items.some(function (item) {
        return item && item.value !== null;
      });
    });
  }, [vehicle]);

  var emissionsDataWithoutNedc = (0, _react.useMemo)(function () {
    var wltpConsumptions = {
      category: 'wltpConsumptions',
      items: [vehicle.consumption.wltpConsumptionLow ? {
        label: 'wltpConsumptionLow',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionLow)
      } : null, vehicle.consumption.wltpConsumptionMid ? {
        label: 'wltpConsumptionMid',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionMid)
      } : null, vehicle.consumption.wltpConsumptionHigh ? {
        label: 'wltpConsumptionHigh',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionHigh)
      } : null, vehicle.consumption.wltpConsumptionExtraHigh ? {
        label: 'wltpConsumptionExtraHigh',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionExtraHigh)
      } : null, vehicle.consumption.wltpConsumptionCombined ? {
        label: 'wltpConsumptionCombined',
        value: (0, _vehicle.fromMpgToLitresPer100ToString)(vehicle.consumption.wltpConsumptionCombined)
      } : null].filter(function (item) {
        return item !== null;
      })
    };

    var wltpEmissions = {
      category: 'wltpEmissions',
      items: [vehicle.emissions.wltpEmissionsCombined ? {
        label: 'wltpEmissionsCombined',
        value: vehicle.emissions.wltpEmissionsCombined + ' g/km'
      } : null]
    };

    return [wltpConsumptions, wltpEmissions].filter(function (category) {
      return category.items.some(function (item) {
        return item && item.value !== null;
      });
    });
  }, [vehicle]);

  if (!emissionsNull(vehicle.emissions)) {
    return _react2.default.createElement(
      Wrapper,
      { id: 'Emissions' },
      _react2.default.createElement(
        SectionTitle,
        null,
        translations.titleEmissions
      ),
      _react2.default.createElement(
        SectionContainer,
        null,
        _react2.default.createElement(
          Column,
          { width: '100%' },
          _react2.default.createElement(
            SectionWrapper,
            null,
            _react2.default.createElement(
              EmissionsTable,
              null,
              !config.hideNedc ? emissionsData.map(function (emission, idx) {
                return _react2.default.createElement(
                  EmissionContainer,
                  { key: idx },
                  _react2.default.createElement(
                    EmissionTitle,
                    {
                      style: {
                        textTransform: 'capitalize'
                      }
                    },
                    translations[emission.category]
                  ),
                  emission.items.map(function (e, index) {
                    return _react2.default.createElement(
                      InfoTableItem,
                      { key: index },
                      _react2.default.createElement(
                        InfoTableLabel,
                        {
                          style: {
                            textTransform: 'capitalize'
                          }
                        },
                        translations[e.label]
                      ),
                      _react2.default.createElement(
                        InfoTableValue,
                        {
                          style: {
                            textTransform: 'lowercase'
                          }
                        },
                        e.value
                      )
                    );
                  })
                );
              }) : emissionsDataWithoutNedc.map(function (emission, idx) {
                return _react2.default.createElement(
                  EmissionContainer,
                  { key: idx },
                  _react2.default.createElement(
                    EmissionTitle,
                    {
                      style: {
                        textTransform: 'capitalize'
                      }
                    },
                    translations[emission.category]
                  ),
                  emission.items.map(function (e, index) {
                    return _react2.default.createElement(
                      InfoTableItem,
                      { key: index },
                      _react2.default.createElement(
                        InfoTableLabel,
                        {
                          style: {
                            textTransform: 'capitalize'
                          }
                        },
                        translations[e.label]
                      ),
                      _react2.default.createElement(
                        InfoTableValue,
                        {
                          style: {
                            textTransform: 'lowercase'
                          }
                        },
                        e.value
                      )
                    );
                  })
                );
              })
            ),
            _react2.default.createElement(SectionSpacer, {
              role: 'separator',
              spacerSizeSmall: '20',
              spacerSizeLarge: '60'
            }),
            vehicle.energyEfficiencyRating && _react2.default.createElement(
              EnergyEfficiencyContainer,
              null,
              _react2.default.createElement(
                EnergyEfficiencyTable,
                null,
                _react2.default.createElement(
                  EnergyEfficiencyImageContainer,
                  null,
                  _react2.default.createElement(
                    EfficiencyTitle,
                    null,
                    translations.energyEfficiencyClass
                  ),
                  config.energyEfficiencyRatingMedia[vehicle.energyEfficiencyRating] && _react2.default.createElement('img', {
                    src: config.energyEfficiencyRatingMedia[vehicle.energyEfficiencyRating],
                    style: {
                      objectFit: 'cover',
                      width: '100%',
                      maxWidth: 450
                    },
                    alt: vehicle.energyEfficiencyRating
                  })
                ),
                translations.disclaimerText && _react2.default.createElement(
                  EnergyEfficiencyText,
                  null,
                  _react2.default.createElement(
                    EfficiencyTitle,
                    null,
                    translations.titleDisclaimer
                  ),
                  _react2.default.createElement(
                    EmissionsDisclaimerText,
                    null,
                    translations.disclaimerText
                  ),
                  userLocationGermany && _react2.default.createElement(
                    EmissionsDisclaimerText,
                    null,
                    translations.disclaimerTextGermany,
                    ' ',
                    _react2.default.createElement(
                      DatLink,
                      {
                        href: translations.datLinkURL,
                        target: '_blank'
                      },
                      translations.datLinkText
                    )
                  )
                )
              )
            )
          )
        )
      )
    );
  } else {
    return null;
  }
};

exports.Emissions = Emissions;