'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  margin-left: 24px;\n  top: 50px;\n  '], ['\n  margin-left: 24px;\n  top: 50px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 20px 0;\n    width: 100%;\n  '], ['\n    margin: 20px 0;\n    width: 100%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Global = require('../../../components/Global');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _types = require('../../../types');

var _Image = require('./Image');

var _Image2 = _interopRequireDefault(_Image);

var _types2 = require('./types');

var _Video = require('./Video');

var _Video2 = _interopRequireDefault(_Video);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SlideContainer = _styledComponents2.default.div.withConfig({ displayName: 'SlideContainer' }).withConfig({
  displayName: 'LamborghiniModelSlide__SlideContainer',
  componentId: 'sc-1uoj56w-0'
})(['display:flex;flex-direction:column;min-height:1px;']);

var SlideOneContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SlideOneContentContainer'
}).withConfig({
  displayName: 'LamborghiniModelSlide__SlideOneContentContainer',
  componentId: 'sc-1uoj56w-1'
})(['position:absolute;top:30%;text-align:left;margin-left:64px;margin-right:auto;left:0;right:0;font-family:Roboto;', ' ', ''], _theme2.default.max.medium(_templateObject), function (_ref) {
  var width = _ref.width;
  return width && 'width: ' + width + 'px; left: calc(50% - ' + width + 'px / 2);';
});

var TopSlideContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TopSlideContentContainer'
}).withConfig({
  displayName: 'LamborghiniModelSlide__TopSlideContentContainer',
  componentId: 'sc-1uoj56w-2'
})(['display:flex;flex-direction:column;font-weight:bold;margin:0;max-width:900px;', ' ', ';'], function (_ref2) {
  var width = _ref2.width;
  return width && 'width : ' + width + 'px;';
}, _theme2.default.max.medium(_templateObject2));

var LamborghiniModelSlide = function LamborghiniModelSlide(slideProps) {
  var bodyMobileFontColour = slideProps.bodyMobileFontColour,
      bodyMobileFontSize = slideProps.bodyMobileFontSize,
      bodyTheme = slideProps.bodyTheme,
      contentWidth = slideProps.contentWidth,
      dispatch = slideProps.dispatch,
      headerMobileFontColour = slideProps.headerMobileFontColour,
      headerMobileFontSize = slideProps.headerMobileFontSize,
      headerTheme = slideProps.headerTheme,
      mediaContent = slideProps.mediaContent,
      onLinkClick = slideProps.onLinkClick,
      _slideProps$preview = slideProps.preview,
      preview = _slideProps$preview === undefined ? false : _slideProps$preview,
      slideContentWidth = slideProps.slideContentWidth,
      translations = slideProps.translations,
      url = slideProps.url;


  var headingOneStyleOverride = function headingOneStyleOverride(headerTheme) {
    return '\n  position: relative;\n  z-index: 2;\n  height: auto;\n  overflow-wrap: break-word;\n  margin: 0;\n  font-weight: 600;\n  ' + (headerTheme.fontFamily && 'font-family: ' + headerTheme.fontFamily + ';') + '\n  ' + (headerTheme.textTransform && 'text-transform: ' + headerTheme.textTransform + ';') + '\n  ' + (headerTheme.fontSize && 'font-size: ' + headerTheme.fontSize + ';') + '\n  ' + (headerTheme.colour && 'color: ' + headerTheme.colour + ';') + '\n  ' + (headerTheme.characterSpacing && 'letter-spacing: ' + headerTheme.characterSpacing + ';') + '\n';
  };

  var bodyStyleOverride = function bodyStyleOverride(bodyTheme) {
    return '\n  position: relative;\n  z-index: 2;\n  height: auto;\n  overflow-wrap: break-word;\n  ' + (bodyTheme.fontFamily && 'font-family: ' + bodyTheme.fontFamily + ';') + '\n  ' + (bodyTheme.textTransform && 'text-transform: ' + bodyTheme.textTransform + ';') + '\n  ' + (bodyTheme.fontSize && 'font-size: ' + bodyTheme.fontSize + ';') + '\n  ' + (bodyTheme.colour && 'color: ' + bodyTheme.colour + ';') + '\n  ' + (bodyTheme.characterSpacing && 'letter-spacing: ' + bodyTheme.characterSpacing + ';') + '\n';
  };

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      SlideContainer,
      { dispatch: dispatch },
      mediaContent.type === 'image' && _react2.default.createElement(_Image2.default, {
        url: url,
        preview: preview,
        mediaContent: mediaContent,
        onLinkClick: onLinkClick,
        alternativeText: translations.alternativeText
      }),
      mediaContent.type === 'video' && _react2.default.createElement(_Video2.default, { url: url, preview: preview, mediaContent: mediaContent, onLinkClick: onLinkClick }),
      _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: slideContentWidth },
        _react2.default.createElement(
          SlideOneContentContainer,
          { width: contentWidth },
          _react2.default.createElement(
            TopSlideContentContainer,
            null,
            translations.header && _react2.default.createElement(
              _Global.HeadingOne,
              {
                styleOverride: function styleOverride() {
                  return headingOneStyleOverride(headerTheme);
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'max.medium',
                    template: '\n                      margin: 0;\n                      ' + (headerMobileFontSize && 'font-size : ' + headerMobileFontSize + 'px;') + '\n                       ' + (headerMobileFontColour && 'color : ' + headerMobileFontColour)
                  }];
                }
              },
              translations.header
            ),
            translations.body && _react2.default.createElement(
              _Global.HeadingOne,
              {
                styleOverride: function styleOverride() {
                  return '\n                    margin: 0;\n                    ' + bodyStyleOverride(bodyTheme) + '\n                  ';
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'max.medium',
                    template: '\n                       ' + (bodyMobileFontSize && 'font-size : ' + bodyMobileFontSize + 'px;') + '\n                       ' + (bodyMobileFontColour && 'color : ' + bodyMobileFontColour)
                  }];
                }
              },
              translations.body
            )
          )
        )
      ),
      _react2.default.createElement(_reactWaypoint2.default, {
        dispatch: dispatch,
        topOffset: '20%',
        onPositionChange: function onPositionChange(props) {
          return dispatch(_sessionPreferences.actions.updateSessionPreferences('isHeroVisible', props.currentPosition === 'inside'));
        }
      })
    )
  );
};

exports.default = LamborghiniModelSlide;