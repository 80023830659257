'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _settings = require('../../../shared/selectors/settings');

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldValidationError = require('./FieldValidationError');

var _FieldValidationError2 = _interopRequireDefault(_FieldValidationError);

var _InputAdornment = require('./InputAdornment');

var _InputAdornment2 = _interopRequireDefault(_InputAdornment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'InputField__Label',
  componentId: 'sc-oka5w1-0'
})(['color:#000000;font-size:14px;font-family:', ';position:absolute;pointer-events:none;top:10px;transition:0.2s ease all;input:focus ~ &,input:not(:placeholder-shown).input:not(:focus) ~ .label,input:disabled ~ &,input:valid ~ &{top:-8px;font-size:12px;color:#000000;}'], function (_ref) {
  var fonts = _ref.fonts;
  return fonts.secondaryFont.value;
});


var Input = _styledComponents2.default.input.attrs(function () {
  return {
    required: true
  };
}).withConfig({
  displayName: 'InputField__Input',
  componentId: 'sc-oka5w1-1'
})(['padding:10px 0;display:block;width:100%;background-color:transparent;', '};border:0;&:focus{outline:none;border-bottom:', ';}'], function (_ref2) {
  var placeholderStyle = _ref2.placeholderStyle;

  return placeholderStyle ? '&::placeholder {' + placeholderStyle + '}' : '';
}, function (_ref3) {
  var disabledRipple = _ref3.disabledRipple;
  return disabledRipple ? 'inherits' : 'solid 0.5px #000000';
});

var InputField = function InputField(_ref4) {
  var id = _ref4.id,
      label = _ref4.label,
      required = _ref4.required,
      type = _ref4.type,
      value = _ref4.value,
      onChange = _ref4.onChange,
      onKeyPress = _ref4.onKeyPress,
      error = _ref4.error,
      disabled = _ref4.disabled,
      translations = _ref4.translations,
      validate = _ref4.validate,
      readOnly = _ref4.readOnly,
      maxLength = _ref4.maxLength,
      startAdornment = _ref4.startAdornment,
      endAdornment = _ref4.endAdornment,
      inputRef = _ref4.inputRef,
      placeholder = _ref4.placeholder,
      placeholderStyle = _ref4.placeholderStyle,
      disabledRipple = _ref4.disabledRipple;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    _FormField2.default,
    null,
    startAdornment && _react2.default.createElement(
      _InputAdornment2.default,
      { position: 'start' },
      startAdornment
    ),
    _react2.default.createElement(Input, {
      type: type,
      value: value,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (event) {
        onChange({
          id: id,
          value: event.target.value,
          translations: translations,
          validate: validate,
          event: event
        });
      }),
      onKeyPress: onKeyPress,
      disabled: disabled,
      error: error,
      readOnly: readOnly,
      maxLength: maxLength,
      fonts: fonts,
      ref: inputRef,
      placeholder: placeholder,
      placeholderStyle: placeholderStyle,
      disabledRipple: disabledRipple
    }),
    endAdornment && _react2.default.createElement(
      _InputAdornment2.default,
      { position: 'end' },
      endAdornment
    ),
    _react2.default.createElement(
      Label,
      { fonts: fonts },
      label,
      required && '*'
    ),
    error && _react2.default.createElement(
      _FieldValidationError2.default,
      null,
      error
    )
  );
};

exports.default = InputField;


InputField.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  onChange: function onChange() {},
  onKeyPress: function onKeyPress() {},
  onKeyDown: function onKeyDown() {},
  onBlur: function onBlur() {},
  onFocus: function onFocus() {},
  label: '',
  error: '',
  validate: false,
  readOnly: false
};