'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 20px 0;\n  '], ['\n    margin: 20px 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 20px;\n    flex: 1 1 40%;\n  '], ['\n    margin-bottom: 20px;\n    flex: 1 1 40%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 75px;\n  '], ['\n    width: 75px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAtGlanceSection__Container',
  componentId: 'sc-1d76h29-0'
})(['display:flex;width:100%;flex-wrap:wrap;', ';'], _theme2.default.max.medium(_templateObject));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAtGlanceSection__Item',
  componentId: 'sc-1d76h29-1'
})(['display:flex;flex-direction:column;align-items:center;justify-content:top;flex:1 1 160px;padding:0 10px;text-align:center;', ';'], _theme2.default.max.medium(_templateObject2));

var ItemImage = _styledComponents2.default.img.withConfig({
  displayName: 'VdpAtGlanceSection__ItemImage',
  componentId: 'sc-1d76h29-2'
})(['width:100px;margin-bottom:15px;', ';'], _theme2.default.max.medium(_templateObject3));

var VdpAtGlanceSection = function VdpAtGlanceSection(props) {
  var translations = props.translations,
      vehicle = props.vehicle,
      showOdometerInformation = props.showOdometerInformation,
      atGlanceSection = props.atGlanceSection;


  var HandDrive = {
    L: translations.leftHandDrive,
    R: translations.rightHandDrive
  };

  var getItemImageUrl = function getItemImageUrl(key) {
    return atGlanceSection.items.find(function (i) {
      return i.key === key;
    }).imageUrl;
  };

  var specs = [];
  specs.push({
    name: translations.specificationModelYear,
    value: vehicle.modelYear,
    imageUrl: getItemImageUrl('year')
  }, {
    name: translations.specificationFuelType,
    value: vehicle.specification.fuelType,
    imageUrl: getItemImageUrl('engine')
  }, {
    name: translations.specificationTransmission,
    value: vehicle.transmissionType,
    imageUrl: getItemImageUrl('transmission')
  }, {
    name: translations.specificationCapacity,
    value: vehicle.specification.capacity,
    imageUrl: getItemImageUrl('capacity')
  }, {
    name: translations.specificationHandDrive,
    value: HandDrive[vehicle.handDrive],
    imageUrl: getItemImageUrl('drive')
  }, showOdometerInformation && {
    name: translations.specificationOdometer,
    value: vehicle.specification.odometer ? vehicle.specification.odometer.display : translations.zeroMileageText,
    imageUrl: getItemImageUrl('mileage')
  });

  var specifications = specs.reduce(function (result, spec) {
    if (spec.value && spec.value !== '') {
      result.push(spec);
    }
    return result;
  }, []);

  return _react2.default.createElement(
    Container,
    null,
    specifications.map(function (item, index) {
      return _react2.default.createElement(
        Item,
        {
          key: 'specificationItem-' + index.toString(),
          border: atGlanceSection.itemBorder
        },
        _react2.default.createElement(ItemImage, { src: item.imageUrl }),
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(atGlanceSection.styles.itemHeader) + '\n            ';
            },
            maxMediumMobileStyleOverride: function maxMediumMobileStyleOverride() {
              return 'font-size: 12px;';
            }
          },
          item.name
        ),
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(atGlanceSection.styles.item) + '\n              margin: 0;\n            ';
            },
            maxMediumMobileStyleOverride: function maxMediumMobileStyleOverride() {
              return 'font-size: 12px;';
            }
          },
          item.value
        )
      );
    })
  );
};

exports.default = _react2.default.memo(VdpAtGlanceSection);