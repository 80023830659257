'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _modelTypes = require('../../../types/AstonMartin/modelTypes');

var _Accordion = require('../Accordion/Accordion');

var _Accordion2 = _interopRequireDefault(_Accordion);

var _ModelItem = require('./ModelItem');

var _ModelItem2 = _interopRequireDefault(_ModelItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__Wrapper',
  componentId: 'sc-c9jise-0'
})(['margin-top:40px;display:flex;justify-content:center;']);

var AccordionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__AccordionWrapper',
  componentId: 'sc-c9jise-1'
})(['max-width:1000px;margin:0 30px;']);

var AccordionBody = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__AccordionBody',
  componentId: 'sc-c9jise-2'
})(['display:grid;grid-template-columns:48% 48%;grid-column-gap:4%;padding-top:30px;']);

var ModelVariantTile = function ModelVariantTile(_ref) {
  var section = _ref.section,
      config = _ref.config,
      onTileSelected = _ref.onTileSelected,
      isTileSelected = _ref.isTileSelected;

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      AccordionWrapper,
      null,
      _react2.default.createElement(
        _Accordion2.default,
        {
          title: section.translationNameKey && config.translations[section.translationNameKey] ? config.translations[section.translationNameKey] : section.name,
          isExpandable: section.isExpandable,
          isExpanded: section.isExpanded,
          iconOpen: config.accordionPlusIconUrl,
          iconClose: config.accordionMinusIconUrl
        },
        _react2.default.createElement(
          AccordionBody,
          null,
          section.tiles.map(function (tile) {
            return _react2.default.createElement(_ModelItem2.default, {
              key: tile.tileId,
              translations: config.translations,
              tile: tile,
              imageLabel: config.translations.imageLabel,
              onTileSelected: onTileSelected,
              isChecked: isTileSelected(tile.tileId)
            });
          })
        )
      )
    )
  );
};

exports.default = _react2.default.memo(ModelVariantTile);