'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ShortlistedVehicles = require('../../components/ShortlistedVehicles');

var _ShortlistedVehicles2 = _interopRequireDefault(_ShortlistedVehicles);

var _theme = require('../../theme');

var _SubNav = require('../../containers/SubNav');

var _SubNav2 = _interopRequireDefault(_SubNav);

var _financeCalculator = require('../../shared/financeCalculator');

var _router = require('../../actions/router');

var _compare = require('../../shared/compare');

var _shortlist = require('../../shared/shortlist');

var _preview = require('./preview.config');

var _vehicleStore = require('../../shared/selectors/vehicleStore');

var _settings = require('../../shared/sortOptions/settings');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _vehicle = require('../../helpers/vehicle');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var themeFromProps = function themeFromProps(_ref) {
  var typeface = _ref.typeface,
      fontSize = _ref.fontSize,
      kerning = _ref.kerning,
      transform = _ref.transform,
      colour = _ref.colour;
  return {
    fontFamily: typeface ? typeface.label : '',
    fontSize: fontSize ? fontSize + 'px' : '',
    characterSpacing: kerning ? kerning + 'px' : '',
    textTransform: transform || '',
    colour: colour ? colour.value : ''
  };
};

var Shortlist = function (_Component) {
  (0, _inherits3.default)(Shortlist, _Component);

  function Shortlist(props) {
    (0, _classCallCheck3.default)(this, Shortlist);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Shortlist.__proto__ || (0, _getPrototypeOf2.default)(Shortlist)).call(this, props));

    _initialiseProps.call(_this);

    var selectedView = window.innerWidth < _theme.screenSizeOptions.large ? 'GRID' : 'LIST';

    var sortValueDictionary = {
      priceAsc: function priceAsc(a, b) {
        return a.price.value - b.price.value;
      },
      priceDesc: function priceDesc(a, b) {
        return b.price.value - a.price.value;
      },
      mileageAsc: function mileageAsc(a, b) {
        return (a.odometer || { reading: 0 }).reading - (b.odometer || { reading: 0 }).reading;
      },
      mileageDesc: function mileageDesc(a, b) {
        return (b.odometer || { reading: 0 }).reading - (a.odometer || { reading: 0 }).reading;
      },
      ageAsc: function ageAsc(a, b) {
        return b.registration - a.registration;
      },
      ageDesc: function ageDesc(a, b) {
        return a.registration - b.registration;
      }
    };
    _this.state = {
      sortField: _this.props.shared.sessionPreferences.searchSort,
      selectedView: selectedView,
      sortValueDictionary: sortValueDictionary
    };
    return _this;
  }

  (0, _createClass3.default)(Shortlist, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _props = this.props,
          init = _props.actions.init,
          dispatch = _props.dispatch,
          preview = _props.preview,
          shared = _props.shared,
          featureFlags = _props.featureFlags;

      init();
      if (!preview && !shared.financeCalculator.representativeExample) {
        dispatch(_financeCalculator.actions.loadRepresentativeExample());
      }
      window.addEventListener('resize', this.onViewportChange);
      window.scroll(0, 0);

      if (!preview && shared.sessionPreferences.currency && shared.currencyConversion.exchangeRates[shared.sessionPreferences.currency]) {
        dispatch(_shortlist.actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
          statePath: (0, _vehicleStore.getVehicleStatePath)(featureFlags)
        }));
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _props2 = this.props,
          shared = _props2.shared,
          dispatch = _props2.dispatch,
          featureFlags = _props2.featureFlags;

      if (shared.sessionPreferences.currency && shared.currencyConversion.exchangeRates[shared.sessionPreferences.currency] && prevProps.shared.currencyConversion.exchangeRates !== shared.currencyConversion.exchangeRates) {
        dispatch(_shortlist.actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
          statePath: (0, _vehicleStore.getVehicleStatePath)(featureFlags)
        }));
      }

      if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language) {
        dispatch(_shortlist.actions.getVehicles());
      }
    }
  }]);
  return Shortlist;
}(_react.Component);

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  Object.defineProperty(this, 'onSortChange', {
    enumerable: true,
    writable: true,
    value: function (_value) {
      function value(_x) {
        return _value.apply(this, arguments);
      }

      value.toString = function () {
        return _value.toString();
      };

      return value;
    }(function (value) {
      _this2.setState({
        sortField: _settings.sortOptions[value]
      });
    })
  });
  Object.defineProperty(this, 'onViewportChange', {
    enumerable: true,
    writable: true,
    value: function value() {
      if (window.innerWidth < _theme.screenSizeOptions.large) {
        _this2.setState(function () {
          return { selectedView: 'GRID' };
        });
      }
    }
  });
  Object.defineProperty(this, 'onViewChange', {
    enumerable: true,
    writable: true,
    value: function value(selectedView) {
      return _this2.setState(function () {
        return { selectedView: selectedView };
      });
    }
  });
  Object.defineProperty(this, 'render', {
    enumerable: true,
    writable: true,
    value: function value() {
      var _state = _this2.state,
          selectedView = _state.selectedView,
          sortValueDictionary = _state.sortValueDictionary;
      var _props3 = _this2.props,
          history = _props3.history,
          pathByModule = _props3.pathByModule,
          preview = _props3.preview,
          config = _props3.config,
          shared = _props3.shared,
          dispatch = _props3.dispatch,
          globalStyling = _props3.globalStyling,
          marketInfo = _props3.marketInfo,
          featureFlags = _props3.featureFlags;


      var shortlistVehicles = (0, _vehicleStore.getShortList)(featureFlags);
      var compareVehicles = (0, _vehicleStore.getCompareList)(featureFlags);

      var shortlistedVehicles = preview ? [] : shortlistVehicles(shared);
      var sharedCompareVehicles = preview ? [] : compareVehicles(shared);

      var sharedShortListedVehicles = preview ? _preview.previewShortListedVehicles : shortlistVehicles(shared);

      var onMoreInfoClick = function onMoreInfoClick(name, vehicle) {
        var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, shared.sessionPreferences.language);
        dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
      };

      var representativeExample = preview ? {} : shared.financeCalculator.representativeExample;

      var isListed = function isListed(id, vehicles) {
        return vehicles.find(function (vehicle) {
          return vehicle.id === (id || {});
        });
      };

      var getCompareIconColour = function getCompareIconColour(id) {
        return isListed(id, sharedCompareVehicles) ? config.compareIconListedColour && config.compareIconListedColour.value : '#ACACAC';
      };

      var getShortListedIconColour = function getShortListedIconColour(id) {
        return isListed(id, sharedShortListedVehicles) ? config.shortlistIconListedColour && config.shortlistIconListedColour.value : '#ACACAC';
      };

      var distanceAsc = _settings.sortOptions.distanceAsc,
          optionsForSort = (0, _objectWithoutProperties3.default)(_settings.sortOptions, ['distanceAsc']);


      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_SubNav2.default, {
          pathByModule: pathByModule,
          title: config.translations.mobileVehicleDetails,
          preview: preview,
          onBackButtonClick: history.goBack,
          translations: config.translations,
          subNavBGColour: config.subNavBGColour,
          subNavBorderColour: config.subNavBorderColour,
          subNavFontTheme: config.subNavFont && themeFromProps(config.subNavFont),
          subNavChevronColour: config.subNavChevronColour,
          subNavBarItemSelectedBackgroundColour: config.subNavBarItemSelectedBackgroundColour,
          subNavBarItemSelectedFontColour: config.subNavBarItemSelectedFontColour,
          contentWidth: globalStyling.contentWidth,
          showNavBarItemBorder: config.showNavBarItemBorder,
          subNavHeight: config.subNavHeight,
          alignMenuItems: config.alignMenuItems,
          navBarBottomBorder: config.navBarBottomBorder,
          globalStyling: globalStyling,
          history: history
        }),
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          _react2.default.createElement(_ShortlistedVehicles2.default, {
            translations: config.translations,
            vehicles: shortlistedVehicles,
            placeholdingImage: shared.shortlist.placeholdingImage,
            representativeExample: representativeExample,
            sortOptions: optionsForSort,
            sortValue: _this2.state.sortField.value,
            sortValueDictionary: sortValueDictionary,
            onSortChange: _this2.onSortChange,
            onViewChange: _this2.onViewChange,
            selectedView: selectedView,
            getCompareIconColour: getCompareIconColour,
            getShortListedIconColour: getShortListedIconColour,
            onMoreInfoClick: onMoreInfoClick,
            onHeartClick: function onHeartClick(vehicle) {
              return dispatch(_shortlist.actions.toggleVehicle(vehicle));
            },
            onCloseClick: function onCloseClick(vehicle) {
              return dispatch(_shortlist.actions.toggleVehicle(vehicle));
            },
            onCompareClick: function onCompareClick(vehicle) {
              return dispatch(_compare.actions.toggleVehicle(vehicle));
            },
            compareVehicle: function compareVehicle(vehicle) {
              return dispatch(_compare.actions.toggleVehicle(vehicle));
            },
            shortlistVehicle: function shortlistVehicle(vehicle) {
              return dispatch(_shortlist.actions.toggleVehicle(vehicle));
            },
            globalStyling: globalStyling,
            visibility: config.visibility,
            locale: marketInfo.locale,
            headingFont: config.headingFont,
            subHeadingFont: config.subHeadingFont,
            headingAlignment: config.headingAlignment,
            preview: preview,
            headingBottomLineDisable: config.headingBottomLineDisable,
            financeWidthOverride: config.financeWidthOverride,
            financeHeadingFont: config.financeHeadingFont,
            vehicleSpecContainer: config.vehicleSpecContainer,
            icon360: config.icon360,
            iconYoutubeVideo: config.iconYoutubeVideo
          })
        )
      );
    }
  });
};

exports.default = Shortlist;