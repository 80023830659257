'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var OpenClose = function (_React$Component) {
  (0, _inherits3.default)(OpenClose, _React$Component);

  function OpenClose(props) {
    (0, _classCallCheck3.default)(this, OpenClose);

    var _this = (0, _possibleConstructorReturn3.default)(this, (OpenClose.__proto__ || (0, _getPrototypeOf2.default)(OpenClose)).call(this, props));

    Object.defineProperty(_this, 'setOpen', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _this.setState(function () {
          return { open: true };
        }, function () {
          return _this.props.onOpenClosed && _this.props.onOpenClosed(true);
        });
      }
    });
    Object.defineProperty(_this, 'setClosed', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _this.setState(function () {
          return { open: false };
        }, function () {
          return _this.props.onOpenClosed && _this.props.onOpenClosed(false);
        });
      }
    });
    Object.defineProperty(_this, 'openClose', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _this.setState(function (_ref) {
          var open = _ref.open;
          return { open: !open };
        }, function () {
          return _this.props.onOpenClosed && _this.props.onOpenClosed(_this.state.open);
        });
      }
    });

    _this.state = {
      open: false
    };
    return _this;
  }

  (0, _createClass3.default)(OpenClose, [{
    key: 'render',
    value: function render() {
      return this.props.children({
        open: this.state.open,
        openClose: this.openClose,
        setOpen: this.setOpen,
        setClosed: this.setClosed
      });
    }
  }]);
  return OpenClose;
}(_react2.default.Component);

Object.defineProperty(OpenClose, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    onOpenClosed: function onOpenClosed() {}
  }
});
exports.default = OpenClose;