'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = MobileFilters;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('./Global');

var _Chunky = require('./JaguarIcons/Arrows/Chunky');

var _Chunky2 = _interopRequireDefault(_Chunky);

var _Criteria = require('./JaguarIcons/Global/Criteria');

var _Criteria2 = _interopRequireDefault(_Criteria);

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__Container',
  componentId: 'sc-16en0ku-0'
})(['display:flex;width:100%;']);

var MobileFiltersOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__MobileFiltersOverlay',
  componentId: 'sc-16en0ku-1'
})(['display:block;width:100%;position:fixed;top:0;left:0;bottom:0;z-index:100;background:white;overflow-y:auto;']);

var SearchMenuContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__SearchMenuContainer',
  componentId: 'sc-16en0ku-2'
})(['display:flex;height:40px;width:100%;justify-content:space-between;', ';', ';align-items:center;margin-bottom:8px;'], function (_ref) {
  var backgroundColour = _ref.backgroundColour;
  return backgroundColour && 'background-color: ' + backgroundColour + ';';
}, function (_ref2) {
  var borderColour = _ref2.borderColour;
  return borderColour && 'border-bottom: 1px ' + borderColour.value + ' solid;';
});

var IconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__IconContainer',
  componentId: 'sc-16en0ku-3'
})(['display:flex;align-items:center;padding:', ';', ';'], function (_ref3) {
  var padding = _ref3.padding;
  return padding ? padding.top + 'px ' + padding.right + 'px ' + padding.bottom + 'px ' + padding.left + 'px' : '4px 0 0 12px';
}, function (_ref4) {
  var direction = _ref4.direction;
  return direction === 'rtl' ? 'padding: 4px 12px 0 0' : 'padding: 4px 0 0 12px';
});

var MenuLabel = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__MenuLabel',
  componentId: 'sc-16en0ku-4'
})(['display:flex;color:#fff;flex:1 0 0%;padding:0 8px;']);

var ToggleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__ToggleContainer',
  componentId: 'sc-16en0ku-5'
})(['display:flex;position:relative;cursor:pointer;']);

var BackChevron = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__BackChevron',
  componentId: 'sc-16en0ku-6'
})(['transform:rotate(180deg);display:flex;width:20px;height:20px;cursor:pointer;']);

var ToggleButton = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__ToggleButton',
  componentId: 'sc-16en0ku-7'
})(['display:flex;justify-content:center;width:16px;padding:0 16px;transform:', ';top:', ';right:', ';'], function (_ref5) {
  var collapsed = _ref5.collapsed;
  return collapsed ? 'rotate(0deg)' : 'rotate(90deg)';
}, function (_ref6) {
  var collapsed = _ref6.collapsed;
  return collapsed ? '18px' : '18px';
}, function (_ref7) {
  var collapsed = _ref7.collapsed;
  return collapsed ? '20px' : '16px';
});

var FilterHeader = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__FilterHeader',
  componentId: 'sc-16en0ku-8'
})(['display:flex;margin:14px 12px;']);

var FilterHeaderTitle = _styledComponents2.default.div.withConfig({
  displayName: 'MobileFilters__FilterHeaderTitle',
  componentId: 'sc-16en0ku-9'
})(['margin:0 auto;']);

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'MobileFilters__IconWrapper',
  componentId: 'sc-16en0ku-10'
})(['width:24px;height:24px;']);

var Menu = function Menu(_ref8) {
  var config = _ref8.config,
      displayMobileFilterOptions = _ref8.displayMobileFilterOptions,
      toggle = _ref8.toggle,
      activeFiltersCount = _ref8.activeFiltersCount,
      backgroundColour = _ref8.backgroundColour,
      borderColour = _ref8.borderColour,
      chevronColour = _ref8.chevronColour,
      translations = _ref8.translations,
      globalStyling = _ref8.globalStyling;
  return _react2.default.createElement(
    SearchMenuContainer,
    {
      backgroundColour: backgroundColour,
      borderColour: borderColour,
      onClick: toggle
    },
    _react2.default.createElement(
      IconContainer,
      {
        padding: config.cogIconPadding,
        direction: globalStyling.direction
      },
      _react2.default.createElement(_Criteria2.default, {
        colour: config.mobileIconColor && config.mobileIconColor.value || '#7E7E7E'
      })
    ),
    _react2.default.createElement(
      MenuLabel,
      null,
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '' + (0, _Global.fontStyleOverride)(config.headlineFont);
          }
        },
        activeFiltersCount ? (0, _translateFromTemplate.translateFromTemplate)('mobileSearchMenuLabel', {
          COUNT: activeFiltersCount
        }, translations) : translations.mobileSearchMenuNoCountLabel
      )
    ),
    _react2.default.createElement(
      ToggleContainer,
      null,
      _react2.default.createElement(
        ToggleButton,
        { collapsed: !displayMobileFilterOptions },
        config.arrowRightIcon ? _react2.default.createElement(IconWrapper, {
          direction: globalStyling.direction,
          src: config.arrowRightIcon,
          alt: ''
        }) : _react2.default.createElement(_Chunky2.default, { direction: globalStyling.direction, colour: chevronColour })
      )
    )
  );
};

var FilterOverlay = function FilterOverlay(_ref9) {
  var translations = _ref9.translations,
      toggle = _ref9.toggle,
      filters = _ref9.filters,
      globalStyling = _ref9.globalStyling;
  return _react2.default.createElement(
    MobileFiltersOverlay,
    null,
    _react2.default.createElement(
      FilterHeader,
      null,
      _react2.default.createElement(
        BackChevron,
        { onClick: toggle },
        _react2.default.createElement(_Chunky2.default, {
          colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
        })
      ),
      _react2.default.createElement(
        FilterHeaderTitle,
        null,
        translations.mobileSearchHeaderLabel
      )
    ),
    filters
  );
};

function MobileFilters(props) {
  var filterProps = props.filters.props;
  var globalStyling = props.globalStyling,
      config = props.config;

  var chevronColour = config.chevronColour && config.chevronColour.value || globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value;
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(Menu, (0, _extends3.default)({
      toggle: filterProps.toggle,
      displayMobileFilterOptions: filterProps.displayMobileFilterOptions
    }, props, {
      chevronColour: chevronColour
    })),
    filterProps.displayMobileFilterOptions && _react2.default.createElement(FilterOverlay, (0, _extends3.default)({ toggle: filterProps.toggle }, props))
  );
}