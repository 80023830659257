'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.position = exports.required = exports.global = exports.icon = exports.description = exports.availableToAdministration = exports.id = exports.name = exports.defaultConfig = exports.config = exports.component = undefined;

var _BackToTop = require('./BackToTop');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_BackToTop).default;
  }
});

var _config = require('./config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _defaultConfig = require('./defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});

var _Icon = require('./Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'BackToTop';
var id = exports.id = 'BackToTop';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'Add clickable button that scrolls user back to top of page';
var icon = exports.icon = _Icon2.default;
var global = exports.global = true;
var required = exports.required = false;
var position = exports.position = 3;