'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _StyledIcon = require('../../../StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DropdownlistIcon = function DropdownlistIcon(_ref) {
  var toggle = _ref.toggle,
      openIcon = _ref.openIcon,
      closeIcon = _ref.closeIcon;

  return toggle ? _react2.default.createElement(_StyledIcon2.default, { link: openIcon, width: 12, height: 12 }) : _react2.default.createElement(_StyledIcon2.default, { link: closeIcon, width: 12, height: 12 });
};

exports.default = DropdownlistIcon;