'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 25px;\n    padding: 0 10px 0 20px;\n  '], ['\n    margin-bottom: 25px;\n    padding: 0 10px 0 20px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 40px 110px;\n  '], ['\n    padding: 40px 110px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    row-gap: 20px;\n  '], ['\n    flex-direction: column;\n    row-gap: 20px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    flex: 1;\n    row-gap: 20px;\n  '], ['\n    flex: 1;\n    row-gap: 20px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    row-gap: 20px;\n  '], ['\n    row-gap: 20px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n  flex-direction: column'], ['\n  flex-direction: column']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _EmissionType = require('../../../types/EmissionType');

var _vehicleDetailsTypes = require('../../../types/Lamborghini/vehicleDetailsTypes');

var _ipInformation = require('../../../shared/localisation/ipInformation');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__Title',
  componentId: 'sc-cnc59c-0'
})(['font-family:RobotoBold;font-size:16px;text-transform:uppercase;line-height:19px;']);

var EmissionText = _styledComponents2.default.p.withConfig({
  displayName: 'VehicleEfficiencyClass__EmissionText',
  componentId: 'sc-cnc59c-1'
})(['font-weight:400;font-size:12px;line-height:21px;']);

var EnergyEfficiencyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__EnergyEfficiencyContainer',
  componentId: 'sc-cnc59c-2'
})(['display:flex;flex-direction:column;', ';', ' margin:0 auto;background-color:#fff;'], _theme2.default.max.medium(_templateObject), _theme2.default.min.large(_templateObject2));

var EnergyEfficiencyContent = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__EnergyEfficiencyContent',
  componentId: 'sc-cnc59c-3'
})(['max-width:1920px;margin:0 auto;']);

var EnergyEfficiencyTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__EnergyEfficiencyTable',
  componentId: 'sc-cnc59c-4'
})(['display:flex;flex-direction:row;column-gap:30px;', ''], _theme2.default.max.medium(_templateObject3));

var EnergyEfficiencyImage = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__EnergyEfficiencyImage',
  componentId: 'sc-cnc59c-5'
})(['flex:0.6;display:flex;align-items:flex-start;flex-direction:column;row-gap:30px;', ''], _theme2.default.max.large(_templateObject4));

var EnergyEfficiencyText = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__EnergyEfficiencyText',
  componentId: 'sc-cnc59c-6'
})(['display:flex;align-items:flex-start;flex-direction:column;flex:1;', ''], _theme2.default.max.large(_templateObject5));

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__HeaderContainer',
  componentId: 'sc-cnc59c-7'
})(['display:flex;flex-direction:row;border-bottom:1px solid #dddddd;padding-bottom:30px;margin-bottom:30px;', ''], _theme2.default.max.small(_templateObject6));

var HeaderItem = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiencyClass__HeaderItem',
  componentId: 'sc-cnc59c-8'
})(['padding-right:40px;p{&:first-child{text-transform:uppercase;font-family:RobotoBold;font-size:16px;line-height:19px;}&:last-child{font-size:14px;font-weight:400;span{font-size:12px;font-weight:300;}}}']);

var VehicleEfficiencyClass = function VehicleEfficiencyClass(_ref) {
  var config = _ref.config,
      energyEfficiencyRating = _ref.energyEfficiencyRating,
      energyEfficiencyRatingMedia = _ref.energyEfficiencyRatingMedia,
      translations = _ref.translations,
      vehicleModelYear = _ref.vehicleModelYear,
      vehicleName = _ref.vehicleName;

  var ipCountryCode = (0, _ipInformation.getIpCountryCode)();
  var annualTaxCountries = config.annualTaxCountries,
      creationDateCountries = config.creationDateCountries,
      disclaimers = config.disclaimers,
      fuelCostCountries = config.fuelCostCountries,
      displayEfficiencyIn = config.displayEfficiencyIn,
      modelsInfo = config.modelsInfo,
      energyEfficiencyRatingMediaSwiss = config.energyEfficiencyRatingMediaSwiss;


  if (!ipCountryCode) return null;

  // Efficiency only available in some countries.
  if (!displayEfficiencyIn.includes(ipCountryCode)) return null;

  var modelInfo = modelsInfo.filter(function (model) {
    return model.modelNames.includes(vehicleName.toUpperCase());
  });

  var disclaimerConfig = disclaimers.find(function (p) {
    return p.countries.includes(ipCountryCode);
  });

  if (!disclaimerConfig) return null;
  var disclaimersToDisplay = Number(vehicleModelYear) < 2022 ? disclaimerConfig.before : disclaimerConfig.after;

  return _react2.default.createElement(
    EnergyEfficiencyContainer,
    null,
    _react2.default.createElement(
      EnergyEfficiencyContent,
      null,
      _react2.default.createElement(
        HeaderContainer,
        null,
        ipCountryCode && creationDateCountries.includes(ipCountryCode) && _react2.default.createElement(
          HeaderItem,
          null,
          _react2.default.createElement(
            'p',
            null,
            translations.creationDate
          ),
          _react2.default.createElement(
            'p',
            null,
            new Date().toLocaleDateString('en-GB')
          )
        ),
        ipCountryCode && annualTaxCountries.includes(ipCountryCode) && modelInfo.length > 0 && _react2.default.createElement(
          HeaderItem,
          null,
          _react2.default.createElement(
            'p',
            null,
            translations.annualTaxVehicle
          ),
          _react2.default.createElement(
            'p',
            null,
            '\u20AC',
            modelInfo[0].annualTax
          )
        ),
        ipCountryCode && fuelCostCountries.includes(ipCountryCode) && modelInfo.length > 0 && _react2.default.createElement(
          HeaderItem,
          null,
          _react2.default.createElement(
            'p',
            null,
            translations.fuelCostMileage
          ),
          _react2.default.createElement(
            'p',
            null,
            '\u20AC',
            modelInfo[0].fuelCost,
            ' ',
            _react2.default.createElement(
              'span',
              null,
              translations.fuelCost
            )
          )
        )
      ),
      _react2.default.createElement(
        EnergyEfficiencyTable,
        null,
        _react2.default.createElement(
          EnergyEfficiencyImage,
          null,
          _react2.default.createElement(
            Title,
            null,
            translations.energyEfficiencyClass
          ),
          energyEfficiencyRatingMedia[energyEfficiencyRating] && _react2.default.createElement('img', {
            src: ipCountryCode === 'CH' ? energyEfficiencyRatingMediaSwiss[energyEfficiencyRating] : energyEfficiencyRatingMedia[energyEfficiencyRating],
            style: {
              objectFit: 'cover',
              width: '100%',
              maxWidth: ipCountryCode && ipCountryCode === 'CH' ? 250 : 350
            },
            alt: energyEfficiencyRating
          })
        ),
        _react2.default.createElement(
          EnergyEfficiencyText,
          null,
          _react2.default.createElement(
            Title,
            null,
            translations.disclaimer
          ),
          disclaimersToDisplay.map(function (translationKey, index) {
            var textWithLink = disclaimerConfig.dat && disclaimersToDisplay.length - 1 === index ? translations[translationKey].toString().replace('##link-to-dat##', '<a href="' + disclaimerConfig.dat.link + '" target="_blank">' + disclaimerConfig.dat.text + '</a>') : translations[translationKey];
            return _react2.default.createElement(EmissionText, {
              key: translationKey,
              dangerouslySetInnerHTML: { __html: textWithLink }
            });
          })
        )
      )
    )
  );
};

exports.default = VehicleEfficiencyClass;