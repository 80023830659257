'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _globalDrawers = require('../../../shared/globalDrawers');

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _Form = require('../Form');

var _TableElements = require('./TableElements');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContactEnquiryRow = function ContactEnquiryRow(_ref) {
  var comparedVehicles = _ref.comparedVehicles,
      buttonLabel = _ref.buttonLabel;

  var dispatch = (0, _reactRedux.useDispatch)();
  return _react2.default.createElement(
    _TableElements.TableRow,
    null,
    _react2.default.createElement(_TableElements.TableDataFirstColumn, null),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        _TableElements.TableData,
        { key: '' + vehicle.id },
        _react2.default.createElement(_Form.Button, {
          variant: 'secondary',
          onClick: function onClick() {
            return vehicle.status !== 'sold' && dispatch(_globalDrawers.actions.toggleEnquireDrawer(vehicle));
          },
          text: buttonLabel
        })
      );
    })
  );
};

exports.default = ContactEnquiryRow;