'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildTitleOptions = exports.titleOptions = undefined;

var _set = require('babel-runtime/core-js/set');

var _set2 = _interopRequireDefault(_set);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var titleOption = function titleOption(value) {
  return { label: value, value: value };
};

var titleOptions = exports.titleOptions = function titleOptions(translations) {
  var formTitleSelect = translations.formTitleSelect,
      formTitleMr = translations.formTitleMr,
      formTitleMrs = translations.formTitleMrs,
      formTitleMs = translations.formTitleMs,
      formTitleMiss = translations.formTitleMiss,
      formTitleLady = translations.formTitleLady,
      formTitleLord = translations.formTitleLord,
      formTitleDr = translations.formTitleDr,
      formTitleProfessor = translations.formTitleProfessor,
      formTitleReverand = translations.formTitleReverand,
      formTitleSir = translations.formTitleSir,
      formTitleDame = translations.formTitleDame;


  return [{
    label: formTitleSelect,
    value: ''
  }, titleOption(formTitleMr), titleOption(formTitleMrs), titleOption(formTitleMiss), titleOption(formTitleMs), titleOption(formTitleLady), titleOption(formTitleLord), titleOption(formTitleDr), titleOption(formTitleProfessor), titleOption(formTitleReverand), titleOption(formTitleSir), titleOption(formTitleDame)].filter(function (_ref) {
    var label = _ref.label;
    return label && label.trim();
  });
};

var brandTitles = {
  astonmartin: ['formTitleMr', 'formTitleMrs', 'formTitleMs'],
  bentley: ['formTitleMr', 'formTitleMrs', 'formTitleMs', 'formTitleMiss', 'formTitleLady', 'formTitleLord', 'formTitleDr', 'formTitleProfessor', 'formTitleReverand', 'formTitleSir', 'formTitleDame']
};

/**
 * Title options per brand
 */
var buildTitleOptions = exports.buildTitleOptions = function buildTitleOptions(translations, brandName) {
  var titles = [];
  var brandTitlesList = brandTitles[brandName];
  brandTitlesList.forEach(function (title) {
    var translatedTitle = translations[title];
    if (translatedTitle) {
      titles.push(translations[title]);
    }
  });
  var options = [].concat((0, _toConsumableArray3.default)(new _set2.default(titles))); // Remove duplicates,some languages don’t have words that are different for Mrs / Miss / Ms (German for example)
  return options.map(function (o) {
    return titleOption(o);
  }).filter(function (_ref2) {
    var label = _ref2.label;
    return label && label.trim();
  });
};