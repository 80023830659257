'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../../../components/Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LanguageCurrencySelectionModal__Wrapper',
  componentId: 'sc-l53xzu-0'
})(['width:100%;height:100%;box-sizing:border-box;padding:30px;']);


var Column = _styledComponents2.default.div.withConfig({
  displayName: 'LanguageCurrencySelectionModal__Column',
  componentId: 'sc-l53xzu-1'
})(['display:flex;flex-direction:column;']);

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'LanguageCurrencySelectionModal__Select',
  componentId: 'sc-l53xzu-2'
})(['width:100%;height:40px;margin-bottom:20px;', ';'], function (_ref) {
  var font = _ref.font;
  return font && (0, _Global.fontStyleOverride)(font);
});

var headingOverride = '\n  margin: 0;\n  ' + (0, _Global.fontStyleOverride)({
  fontSize: 22,
  transform: 'uppercase',
  alignment: 'center',
  padding: {
    bottom: 20
  }
}) + '\n';

exports.default = function (_ref2) {
  var selectedLanguage = _ref2.selectedLanguage,
      selectedCurrency = _ref2.selectedCurrency,
      selectedTerritory = _ref2.selectedTerritory,
      languages = _ref2.languages,
      currencies = _ref2.currencies,
      territories = _ref2.territories,
      changeLanguage = _ref2.changeLanguage,
      changeCurrency = _ref2.changeCurrency,
      changeTerritory = _ref2.changeTerritory,
      translations = _ref2.translations,
      selectFont = _ref2.selectFont,
      button = _ref2.button,
      closeModalFn = _ref2.closeModalFn;

  var _useState = (0, _react.useState)(selectedLanguage.value),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      language = _useState2[0],
      setLanguage = _useState2[1];

  var currencyState = selectedCurrency ? selectedCurrency.value : '';

  var _useState3 = (0, _react.useState)(currencyState),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      currency = _useState4[0],
      setCurrency = _useState4[1];

  var _useState5 = (0, _react.useState)(selectedTerritory),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      territory = _useState6[0],
      setTerritory = _useState6[1];

  // TODO move this to config


  var territoryLanguageMap = {
    na: {
      en_gb: 'en_us'
    },
    row: {
      en_us: 'en_gb'
    }
  };

  var onSelectClick = function onSelectClick(lang, curr, terr) {
    var langObj = languages.find(function (l) {
      return l.value === lang;
    }) || {};
    var currObj = currencies.find(function (c) {
      return c.value === curr;
    }) || {};

    if (lang !== selectedLanguage.value && terr === selectedTerritory) {
      changeLanguage(langObj);
    }
    if (selectedCurrency && curr !== selectedCurrency.value || !selectedCurrency && curr !== '') {
      changeCurrency(currObj);
    }
    if (selectedTerritory && terr !== selectedTerritory) {
      var terrLanguage = territoryLanguageMap[terr][lang] || lang;
      changeTerritory({ territory: terr, language: terrLanguage });
    }
    closeModalFn();
  };

  return _react2.default.createElement(
    Wrapper,
    null,
    territories && _react2.default.createElement(
      Column,
      null,
      _react2.default.createElement(
        'label',
        { htmlFor: 'territory' },
        _react2.default.createElement(
          _Global.HeadingOne,
          { styleOverride: function styleOverride() {
              return headingOverride;
            } },
          translations.territorySelectionTitle
        )
      ),
      _react2.default.createElement(
        Select,
        {
          id: 'territory',
          'data-cy': 'territory',
          value: territory,
          font: selectFont,
          onChange: function onChange(event) {
            return setTerritory(event.target.value);
          }
        },
        territories.map(function (t) {
          return _react2.default.createElement(
            'option',
            { key: t.value, value: t.value },
            t.label
          );
        })
      )
    ),
    _react2.default.createElement(
      Column,
      null,
      _react2.default.createElement(
        'label',
        { htmlFor: 'language' },
        _react2.default.createElement(
          _Global.HeadingOne,
          { styleOverride: function styleOverride() {
              return headingOverride;
            } },
          translations.languageSelectionTitle
        )
      ),
      _react2.default.createElement(
        Select,
        {
          id: 'language',
          'data-cy': 'language',
          value: language,
          font: selectFont,
          onChange: function onChange(event) {
            return setLanguage(event.target.value);
          }
        },
        languages.map(function (l) {
          return _react2.default.createElement(
            'option',
            { key: l.value, value: l.value },
            l.label
          );
        })
      )
    ),
    _react2.default.createElement(
      Column,
      null,
      _react2.default.createElement(
        'label',
        { htmlFor: 'currency' },
        _react2.default.createElement(
          _Global.HeadingOne,
          { styleOverride: function styleOverride() {
              return headingOverride;
            } },
          translations.currencySelectionTitle
        )
      ),
      _react2.default.createElement(
        Select,
        {
          id: 'currency',
          'data-cy': 'currency',
          value: currency,
          font: selectFont,
          onChange: function onChange(event) {
            return setCurrency(event.target.value);
          }
        },
        !currencyState && _react2.default.createElement(
          'option',
          { value: '' },
          translations.selectCurrency
        ),
        currencies.map(function (c) {
          return _react2.default.createElement(
            'option',
            { key: c.value, value: c.value },
            c.label
          );
        })
      )
    ),
    _react2.default.createElement(_Global.Button, {
      'data-cy': 'select_button',
      styleOverride: function styleOverride() {
        return 'width: 100%; margin-top: 20px;';
      },
      buttonStyle: button.buttonStyle,
      applyStyle: 'primary',
      text: 'select',
      onClick: function onClick() {
        return onSelectClick(language, currency, territory);
      }
    })
  );
};