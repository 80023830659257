'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _VehicleCarousel = require('./VehicleCarousel');

var _VehicleCarousel2 = _interopRequireDefault(_VehicleCarousel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _VehicleCarousel2.default;