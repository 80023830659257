'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWindowDimensions = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var WindowDimensionsCtx = (0, _react.createContext)(null);

var windowDims = function windowDims() {
  return {
    height: window.innerHeight,
    width: window.innerWidth
  };
};

var WindowDimensionsProvider = function WindowDimensionsProvider(_ref) {
  var children = _ref.children;

  var _useState = (0, _react.useState)(windowDims()),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      dimensions = _useState2[0],
      setDimensions = _useState2[1];

  (0, _react.useEffect)(function () {
    var isThrottled = false;
    var delay = 200;

    var handleResize = function handleResize() {
      if (!isThrottled) {
        setDimensions(windowDims());

        isThrottled = true;

        setTimeout(function () {
          isThrottled = false;
        }, delay);
      }
    };

    window.addEventListener('resize', handleResize);
    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return _react2.default.createElement(
    WindowDimensionsCtx.Provider,
    { value: dimensions },
    children
  );
};

exports.default = WindowDimensionsProvider;
var useWindowDimensions = exports.useWindowDimensions = function useWindowDimensions() {
  return (0, _react.useContext)(WindowDimensionsCtx);
};