'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    align-items: baseline;\n  '], ['\n    flex-direction: column;\n    align-items: baseline;\n  ']);

exports.default = CookiePolicy;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__Container',
  componentId: 'sc-hk9k3a-0'
})(['@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){min-height:1px;}width:100%;max-height:100%;box-sizing:border-box;background-color:#ffffff;padding:10px 16px;display:flex;box-sizing:border-box;justify-content:space-between;align-items:center;padding:16px 32px;', ';'], _theme2.default.max.large(_templateObject));

var TextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__TextContainer',
  componentId: 'sc-hk9k3a-1'
})(['margin:0;margin-bottom:16px;']);
var Link = _styledComponents2.default.a.withConfig({
  displayName: 'CookiePolicy__Link',
  componentId: 'sc-hk9k3a-2'
})(['cursor:pointer;font-family:AstonMartinSans-Medium;font-size:14px;line-height:18px;text-decoration:none;color:#000000;']);

var Title = _styledComponents2.default.h2.withConfig({
  displayName: 'CookiePolicy__Title',
  componentId: 'sc-hk9k3a-3'
})(['font-family:AstonMartinFlare-Medium;font-size:16px;font-weight:normal;margin-bottom:16px;line-height:22px;text-transform:uppercase;']);
var BodyText = _styledComponents2.default.p.withConfig({
  displayName: 'CookiePolicy__BodyText',
  componentId: 'sc-hk9k3a-4'
})(['font-family:AstonMartinSans-Light;font-size:14px;line-height:18px;']);

var Button = _styledComponents2.default.button.withConfig({
  displayName: 'CookiePolicy__Button',
  componentId: 'sc-hk9k3a-5'
})(['background-color:#e5e5e3;border-radius:20px;border:0;color:#000;cursor:pointer;font-family:AstonMartinFlare-Medium;font-size:14px;padding:8px 40px;transition:0.3s;margin-right:16px;white-space:nowrap;text-transform:uppercase;&:hover{background-color:#c7c7c7;}']);

var StickyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__StickyContainer',
  componentId: 'sc-hk9k3a-6'
})(['position:fixed;width:100%;z-index:999;bottom:0px;']);

function CookiePolicy(props) {
  var config = props.config,
      globalStyling = props.globalStyling;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      hide = _useState2[0],
      setHide = _useState2[1];

  var localStorageKey = 'cookiesAccepted';

  var cookiesAccepted = localStorage.getItem(localStorageKey);
  if (cookiesAccepted === 'true' || hide) return null;

  return _react2.default.createElement(
    StickyContainer,
    null,
    _react2.default.createElement(
      Container,
      {
        colour: '#ffffff',
        borderColour: config.borderColour && config.borderColour,
        contentWidth: globalStyling.contentWidth,
        'data-nosnippet': true
      },
      _react2.default.createElement(
        TextContainer,
        null,
        _react2.default.createElement(
          Title,
          null,
          config.translations.titleText
        ),
        _react2.default.createElement(
          BodyText,
          null,
          config.translations.bodyText
        ),
        _react2.default.createElement(
          Link,
          { href: config.policyLink.url, target: '_blank' },
          config.translations.policyLink
        )
      ),
      _react2.default.createElement(
        Button,
        {
          onClick: function onClick() {
            localStorage.setItem(localStorageKey, true);
            setHide(true);
          }
        },
        config.translations.buttonText
      )
    )
  );
}