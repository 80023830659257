'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 8px 16px;\n  '], ['\n    padding: 8px 16px;\n  ']);

exports.default = SocialMediaLinks;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _socialFacebook = require('react-icons/lib/io/social-facebook');

var _socialFacebook2 = _interopRequireDefault(_socialFacebook);

var _socialTwitter = require('react-icons/lib/io/social-twitter');

var _socialTwitter2 = _interopRequireDefault(_socialTwitter);

var _socialInstagram = require('react-icons/lib/io/social-instagram');

var _socialInstagram2 = _interopRequireDefault(_socialInstagram);

var _socialYoutube = require('react-icons/lib/io/social-youtube');

var _socialYoutube2 = _interopRequireDefault(_socialYoutube);

var _socialLinkedin = require('react-icons/lib/io/social-linkedin');

var _socialLinkedin2 = _interopRequireDefault(_socialLinkedin);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SocialLinks = _styledComponents2.default.ul.withConfig({
  displayName: 'SocialMediaLinks__SocialLinks',
  componentId: 'sc-sqplcm-0'
})(['list-style-type:none;display:flex;justify-content:center;padding-left:0;padding-right:0;margin:0;', ';'], _theme2.default.max.large(_templateObject));

var SocialLink = _styledComponents2.default.a.withConfig({
  displayName: 'SocialMediaLinks__SocialLink',
  componentId: 'sc-sqplcm-1'
})(['color:', ';font-size:26px;padding:0 6px;'], function (props) {
  return props.colour;
});

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'SocialMediaLinks__IconWrapper',
  componentId: 'sc-sqplcm-2'
})(['width:', ';height:', ';'], function (_ref) {
  var iconWidth = _ref.iconWidth;
  return iconWidth ? iconWidth + 'px' : '26px';
}, function (_ref2) {
  var iconHeight = _ref2.iconHeight;
  return iconHeight ? iconHeight + 'px' : '26px';
});
function SocialMediaLinks(_ref3) {
  var translations = _ref3.translations,
      socialIconsColour = _ref3.socialIconsColour,
      urlLinkedIn = _ref3.urlLinkedIn,
      urlInstagram = _ref3.urlInstagram,
      urlYouTube = _ref3.urlYouTube,
      urlTwitter = _ref3.urlTwitter,
      urlFacebook = _ref3.urlFacebook,
      preview = _ref3.preview,
      facebookIconLink = _ref3.facebookIconLink,
      twitterIconLink = _ref3.twitterIconLink,
      youtubeIconLink = _ref3.youtubeIconLink,
      instagramIconLink = _ref3.instagramIconLink,
      linkedinIconLink = _ref3.linkedinIconLink,
      iconWidth = _ref3.iconWidth,
      iconHeight = _ref3.iconHeight;

  var interceptClick = preview ? function (event) {
    return event.preventDefault();
  } : function () {};
  return _react2.default.createElement(
    SocialLinks,
    null,
    urlFacebook && _react2.default.createElement(
      'li',
      null,
      _react2.default.createElement(
        SocialLink,
        {
          'aria-label': translations.facebookAriaLabel,
          colour: socialIconsColour,
          href: urlFacebook,
          target: '_blank',
          'data-cy': 'sml-facebook',
          onClick: interceptClick
        },
        facebookIconLink ? _react2.default.createElement(IconWrapper, {
          src: facebookIconLink,
          alt: translations.facebookAriaLabel,
          iconWidth: iconWidth,
          iconHeight: iconHeight
        }) : _react2.default.createElement(_socialFacebook2.default, null)
      )
    ),
    urlTwitter && _react2.default.createElement(
      'li',
      null,
      _react2.default.createElement(
        SocialLink,
        {
          'aria-label': translations.twitterAriaLabel,
          colour: socialIconsColour,
          href: urlTwitter,
          target: '_blank',
          'data-cy': 'sml-twitter',
          onClick: interceptClick
        },
        twitterIconLink ? _react2.default.createElement(IconWrapper, {
          src: twitterIconLink,
          alt: translations.twitterAriaLabel,
          iconWidth: iconWidth,
          iconHeight: iconHeight
        }) : _react2.default.createElement(_socialTwitter2.default, null)
      )
    ),
    urlYouTube && _react2.default.createElement(
      'li',
      null,
      _react2.default.createElement(
        SocialLink,
        {
          'aria-label': translations.youtubeAriaLabel,
          colour: socialIconsColour,
          href: urlYouTube,
          target: '_blank',
          'data-cy': 'sml-youtube',
          onClick: interceptClick
        },
        youtubeIconLink ? _react2.default.createElement(IconWrapper, {
          src: youtubeIconLink,
          alt: translations.youtubeAriaLabel,
          iconWidth: iconWidth,
          iconHeight: iconHeight
        }) : _react2.default.createElement(_socialYoutube2.default, null)
      )
    ),
    urlInstagram && _react2.default.createElement(
      'li',
      null,
      _react2.default.createElement(
        SocialLink,
        {
          'aria-label': translations.instagramAriaLabel,
          colour: socialIconsColour,
          href: urlInstagram,
          'data-cy': 'sml-instagram',
          target: '_blank',
          onClick: interceptClick
        },
        instagramIconLink ? _react2.default.createElement(IconWrapper, {
          src: instagramIconLink,
          alt: translations.instagramAriaLabel,
          iconWidth: iconWidth,
          iconHeight: iconHeight
        }) : _react2.default.createElement(_socialInstagram2.default, null)
      )
    ),
    urlLinkedIn && _react2.default.createElement(
      'li',
      null,
      _react2.default.createElement(
        SocialLink,
        {
          'aria-label': translations.linkedinAriaLabel,
          colour: socialIconsColour,
          href: urlLinkedIn,
          'data-cy': 'sml-linkedin',
          target: '_blank',
          onClick: interceptClick
        },
        linkedinIconLink ? _react2.default.createElement(IconWrapper, {
          src: linkedinIconLink,
          alt: translations.linkedinAriaLabel,
          iconWidth: iconWidth,
          iconHeight: iconHeight
        }) : _react2.default.createElement(_socialLinkedin2.default, null)
      )
    )
  );
}