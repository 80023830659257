'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SquareCheckBox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SquareCheckBox__Container',
  componentId: 'sc-c1c0e3-0'
})(['display:flex;align-items:flex-start;cursor:pointer;text-align:left;']);

var CheckBoxImg = _styledComponents2.default.img.withConfig({
  displayName: 'SquareCheckBox__CheckBoxImg',
  componentId: 'sc-c1c0e3-1'
})(['width:26px;height:26px;cursor:pointer;']);
var CheckBoxImgWithBorder = _styledComponents2.default.img.withConfig({
  displayName: 'SquareCheckBox__CheckBoxImgWithBorder',
  componentId: 'sc-c1c0e3-2'
})(['width:26px;height:26px;cursor:pointer;border:2px solid #676767;box-sizing:border-box;padding:3px;']);

var ErrorText = _styledComponents2.default.div.withConfig({
  displayName: 'SquareCheckBox__ErrorText',
  componentId: 'sc-c1c0e3-3'
})(['color:#f94564 !important;font-family:\'RivieraNights-Light\',\'NotoSansKR-Light\',Helvetica,Arial,-apple-system,sans-serif;font-size:16px;font-weight:normal;margin-bottom:16px;width:100%;']);

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'SquareCheckBox__Label',
  componentId: 'sc-c1c0e3-4'
})(['padding-left:10px;']);

var Checkbox = function Checkbox(_ref) {
  var checked = _ref.checked;
  return checked ? _react2.default.createElement(CheckBoxImgWithBorder, { src: 'https://res.cloudinary.com/motortrak/v1718117018/locator/rolls-royce/global/icons/tick_white.svg' }) : _react2.default.createElement(CheckBoxImg, { src: 'https://res.cloudinary.com/motortrak/image/upload/v1717664642/locator/rolls-royce/global/icons/rr-checkbox-unchecked.svg' });
};

Checkbox.defaultProps = {
  error: ''
};

function SquareCheckBox(_ref2) {
  var onClick = _ref2.onClick,
      checked = _ref2.checked,
      label = _ref2.label,
      required = _ref2.required,
      error = _ref2.error,
      hideValidation = _ref2.hideValidation;

  return _react2.default.createElement(
    'div',
    null,
    error && !hideValidation && _react2.default.createElement(
      ErrorText,
      null,
      error
    ),
    _react2.default.createElement(
      Container,
      { onClick: onClick },
      _react2.default.createElement(Checkbox, { checked: checked }),
      _react2.default.createElement(
        Label,
        null,
        label,
        required && '*'
      )
    )
  );
}