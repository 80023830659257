'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  getFeaturedVehicle: 'GET_FEATURED_VEHICLE',
  getFeaturedVehicleSuccess: 'GET_FEATURED_VEHICLE_SUCCESS',
  getFeaturedVehicleFailure: 'GET_FEATURED_VEHICLE_FAILURE'
};

var actions = exports.actions = {
  getFeaturedVehicle: function getFeaturedVehicle(vehicleId) {
    return {
      type: constants.getFeaturedVehicle,
      payload: vehicleId
    };
  },
  getFeaturedVehicleSuccess: function getFeaturedVehicleSuccess(payload) {
    return {
      type: constants.getFeaturedVehicleSuccess,
      payload: payload
    };
  },
  getFeaturedVehicleFailure: function getFeaturedVehicleFailure() {
    return {
      type: constants.getFeaturedVehicleFailure
    };
  }
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments[1];
  var type = action.type,
      vehicle = action.payload;

  switch (type) {
    case constants.getFeaturedVehicleSuccess:
      return (0, _extends4.default)({}, state, (0, _defineProperty3.default)({}, vehicle.id, vehicle));
    default:
      return state;
  }
}