'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDealerInfo = undefined;

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _reactRedux = require('react-redux');

var _AppState = require('../types/AppState');

var _routing = require('../shared/routing');

var _DealerTypes = require('../types/DealerTypes');

var _react = require('react');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useDealerInfo = exports.useDealerInfo = function useDealerInfo() {
  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      dealerInfo = _useState2[0],
      setDealerInfo = _useState2[1];

  var isDealer = (0, _routing.isDealerPage)();

  // Gets the Dealer's page state based on url.
  var page = (0, _reactRedux.useSelector)(function (state) {
    return state.pages['/' + (0, _routing.getDealerNameFromUrl)()];
  });

  (0, _react.useEffect)(function () {
    if (isDealer && dealerInfo === null) {
      if (page) {
        var dealer = page[(0, _keys2.default)(page)[0]].dealer;
        if (dealer) {
          //@ts-ignore: TODO, we need to support types in useState.
          setDealerInfo(dealer);
        }
      }
    }
  }, [dealerInfo, isDealer, page, setDealerInfo]);

  return {
    dealerInfo: dealerInfo,
    isDealer: isDealer
  };
};

exports.default = useDealerInfo;