'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 100%;\n  '], ['\n    max-width: 100%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useOutsideClick = require('../../../hooks/useOutsideClick');

var _useOutsideClick2 = _interopRequireDefault(_useOutsideClick);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _modelTypes = require('../../../types/Genesis/modelTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Dropdown__Container',
  componentId: 'sc-fygm9h-0'
})(['', ' max-width:300px;width:100%;'], _Genesis2.default.max.medium(_templateObject));

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'Dropdown__Icon',
  componentId: 'sc-fygm9h-1'
})(['transition:all 0.3s;', ''], function (_ref) {
  var open = _ref.open;
  return open && 'transform: rotate(-180deg); top: 2px; position: relative;';
});

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'Dropdown__Label',
  componentId: 'sc-fygm9h-2'
})(['background:transparent;border:none;color:#fff;font-family:\'GenesisSansTextGlobal-Regular\';font-size:18px;line-height:23.76px;text-transform:uppercase;']);

var LabelContainer = _styledComponents2.default.label.withConfig({
  displayName: 'Dropdown__LabelContainer',
  componentId: 'sc-fygm9h-3'
})(['align-items:center;background:#111;border-bottom:1px solid #fff;cursor:pointer;display:flex;flex:1 0 0;justify-content:space-between;padding:13px 0px 13px 10px;']);

var List = _styledComponents2.default.div.withConfig({
  displayName: 'Dropdown__List',
  componentId: 'sc-fygm9h-4'
})(['align-items:flex-start;display:flex;flex-direction:column;max-width:300px;position:absolute;width:100%;z-index:1;']);

var ListItem = _styledComponents2.default.button.withConfig({
  displayName: 'Dropdown__ListItem',
  componentId: 'sc-fygm9h-5'
})(['align-items:flex-start;background:#111;border:none;border-bottom:1px solid #1e1e1e;cursor:pointer;display:flex;flex-direction:column;gap:10px;height:49px;justify-content:justify-content;padding:0px 20px;text-transform:uppercase;width:300px;']);

var ItemLabel = _styledComponents2.default.label.withConfig({
  displayName: 'Dropdown__ItemLabel',
  componentId: 'sc-fygm9h-6'
})(['color:#999;cursor:pointer;font-family:\'GenesisSansTextGlobal-Regular\';font-size:18px;line-height:49px;margin:auto;text-align:left;width:100%;&:hover{color:#fff;transition:color 0.2s linear;}']);

var Dropdown = function Dropdown(_ref2) {
  var vehicleTypes = _ref2.vehicleTypes,
      translations = _ref2.translations,
      _onClick = _ref2.onClick,
      value = _ref2.value;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var ref = (0, _react.createRef)();

  var isClickOutside = (0, _useOutsideClick2.default)(ref);

  // get object values and create array
  var vehicleTypesArray = (0, _values2.default)(vehicleTypes);

  (0, _react.useEffect)(function () {
    if (open && isClickOutside) {
      setOpen(false);
    }
  }, [isClickOutside, open]);

  return _react2.default.createElement(
    Container,
    { ref: ref },
    _react2.default.createElement(
      LabelContainer,
      { onClick: function onClick() {
          return setOpen(!open);
        } },
      _react2.default.createElement(
        Label,
        null,
        translations[value]
      ),
      _react2.default.createElement(Icon, {
        open: open,
        src: 'https://res.cloudinary.com/motortrak/image/upload/v1695728287/locator/genesis/icons/down_chev.svg'
      })
    ),
    open && _react2.default.createElement(
      List,
      null,
      vehicleTypesArray.map(function (vehicleType) {
        return _react2.default.createElement(
          ListItem,
          {
            onClick: function onClick() {
              _onClick(vehicleType);
              setOpen(!open);
            },
            key: vehicleType
          },
          _react2.default.createElement(
            ItemLabel,
            null,
            translations[vehicleType]
          )
        );
      })
    )
  );
};

exports.default = Dropdown;