'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Fade = require('./Fade');

var _Fade2 = _interopRequireDefault(_Fade);

var _StickyWrapper = require('./StickyWrapper');

var _StickyWrapper2 = _interopRequireDefault(_StickyWrapper);

var _FiltersLocationSearchBarContainer = require('../containers/FiltersLocationSearchBarContainer');

var _FiltersLocationSearchBarContainer2 = _interopRequireDefault(_FiltersLocationSearchBarContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var translations = _ref.translations,
      dropdownTheme = _ref.dropdownTheme,
      count = _ref.count,
      display = _ref.display,
      globalStyling = _ref.globalStyling,
      pathByModule = _ref.pathByModule,
      shared = _ref.shared,
      onReset = _ref.onReset,
      config = _ref.config;
  return _react2.default.createElement(
    _Fade2.default,
    { display: display },
    _react2.default.createElement(
      _StickyWrapper2.default,
      { id: 'stickywrapper', layout: 'bottom' },
      _react2.default.createElement(_FiltersLocationSearchBarContainer2.default, {
        count: count,
        translations: translations,
        dropdownTheme: dropdownTheme,
        globalStyling: globalStyling,
        pathByModule: pathByModule,
        shared: shared,
        onReset: onReset,
        config: config,
        newFilters: true
      })
    )
  );
};