'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n  '], ['\n    ', ';\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']); /* eslint-disable no-nested-ternary */


exports.default = IconGrid;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../components/Global');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__Container',
  componentId: 'sc-nfbly2-0'
})(['width:100%;display:flex;box-sizing:border-box;', ';', ';', ';', ';@media print{display:none;}'], function (_ref) {
  var backgroundImage = _ref.backgroundImage;
  return backgroundImage && 'background-image: url("' + backgroundImage.value + '")';
}, function (_ref2) {
  var backgroundImage = _ref2.backgroundImage;
  return backgroundImage && 'background-size: cover';
}, function (_ref3) {
  var backgroundImage = _ref3.backgroundImage;
  return backgroundImage && 'background-position: center';
}, function (_ref4) {
  var colour = _ref4.colour;
  return colour && 'background-color: ' + colour.value;
});

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__ImageOverlay',
  componentId: 'sc-nfbly2-1'
})(['', ';width:100%;'], function (_ref5) {
  var showOverlay = _ref5.showOverlay;
  return '' + (showOverlay && 'background-color: rgba(0,0,0,0.6);');
});

var IconGridContainer = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__IconGridContainer',
  componentId: 'sc-nfbly2-2'
})(['', ';box-sizing:border-box;text-align:center;width:100%;', ';'], function (_ref6) {
  var padding = _ref6.padding;
  return 'padding:\n    ' + (padding.horizontal !== undefined && padding.horizontal + 'px' || '30px') + '\n    ' + (padding.vertical !== undefined && padding.vertical + 'px' || '25px');
}, _theme2.default.max.medium(_templateObject, function (_ref7) {
  var padding = _ref7.padding;
  return '' + (padding.horizontal !== undefined && 'padding: ' + padding.horizontal + 'px 5%');
}));

var HeadlineWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__HeadlineWrapper',
  componentId: 'sc-nfbly2-3'
})(['width:100%;display:flex;flex-direction:column;', ';', ';', ';'], function (_ref8) {
  var align = _ref8.align;
  return '' + (align && 'align-items: ' + align);
}, function (_ref9) {
  var marginTop = _ref9.marginTop;
  return '' + (marginTop && 'margin-top: ' + marginTop + 'px');
}, function (_ref10) {
  var marginBottom = _ref10.marginBottom;
  return '' + (marginBottom && 'margin-bottom: ' + marginBottom + 'px');
});

var ItemsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__ItemsContainer',
  componentId: 'sc-nfbly2-4'
})(['width:100%;display:flex;flex-wrap:wrap;justify-content:center;padding:0px;']);

var IconGridItem = _styledComponents2.default.div.withConfig({
  displayName: 'IconGrid__IconGridItem',
  componentId: 'sc-nfbly2-5'
})(['', ';min-width:33%;align-items:center;display:flex;flex-direction:column;justify-content:flex-start;', ';', ';'], function (_ref11) {
  var width = _ref11.width;
  return '' + (width && 'width:' + width + '%');
}, function (_ref12) {
  var paddingBottom = _ref12.paddingBottom;
  return 'padding-bottom: ' + (paddingBottom ? paddingBottom + 'px' : '30px');
}, _theme2.default.max.medium(_templateObject2));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'IconGrid__Image',
  componentId: 'sc-nfbly2-6'
})(['margin:', ';', ';', ';', ';', ';'], function (_ref13) {
  var margin = _ref13.margin;
  return '' + (margin || '4px 0');
}, function (_ref14) {
  var width = _ref14.width;
  return '' + (width && 'width:' + width + 'px');
}, function (_ref15) {
  var percentWidth = _ref15.percentWidth;
  return '' + (percentWidth && 'width:' + percentWidth + '%');
}, function (_ref16) {
  var maxWidth = _ref16.maxWidth;
  return '' + (maxWidth && 'max-width:' + maxWidth + 'px');
}, function (_ref17) {
  var height = _ref17.height;
  return '' + (height && 'height:' + height + 'px');
});

var alignItems = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

var StyledLink = _styledComponents2.default.a.withConfig({
  displayName: 'IconGrid__StyledLink',
  componentId: 'sc-nfbly2-7'
})(['cursor:pointer;color:#333;']);

function IconGrid(_ref18) {
  var config = _ref18.config,
      globalStyling = _ref18.globalStyling;

  var showOverlay = config.backgroundImageActive && config.backgroundImage;

  var translateLinkFromTemplate = function translateLinkFromTemplate(template, linkUrl, linkText) {
    var Link = function Link() {
      return _react2.default.createElement(
        StyledLink,
        { href: linkUrl, target: '_blank' },
        linkText
      );
    };
    var textArray = template.split('{DESCRIPTION_LINK}');

    return textArray.length > 1 ? textArray.toSpliced(1, 0, _react2.default.createElement(Link, { href: linkUrl })) : textArray;
  };

  return _react2.default.createElement(
    Container,
    {
      id: config.moduleId,
      colour: config.backgroundColour,
      backgroundImage: config.backgroundImageActive && config.backgroundImage
    },
    _react2.default.createElement(
      ImageOverlay,
      { showOverlay: showOverlay },
      _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: globalStyling.contentWidth },
        _react2.default.createElement(
          IconGridContainer,
          {
            padding: {
              horizontal: config.horizontalPadding,
              vertical: config.verticalPadding
            }
          },
          _react2.default.createElement(
            HeadlineWrapper,
            {
              marginTop: config.headlineFont.marginTop,
              marginBottom: config.marginBottom || config.headlineFont.marginBottom,
              align: alignItems[config.headLineAlignment]
            },
            config.translations.headLineText && _react2.default.createElement(
              _Global.HeadingTwo,
              {
                'data-cy': config.translations.headLineText,
                styleOverride: function styleOverride() {
                  return '\n                    ' + (0, _Global.fontStyleOverride)(config.headlineFont) + '\n                  ';
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'max.medium',
                    template: '\n                    ' + (0, _Global.fontStyleOverride)(config.mobileHeadlineFont)
                  }];
                },
                displayHeadingDivider: config.displayHeadingDivider,
                headingDividerStyle: config.headingDividerStyle
              },
              config.translations.headLineText
            ),
            config.translations.subHeadlineText && _react2.default.createElement(
              _Global.Paragraph,
              {
                'data-cy': config.translations.subHeadlineText,
                styleOverride: function styleOverride() {
                  return '\n                    ' + (config.marginBetweenSubheader && 'margin: ' + config.marginBetweenSubheader + ';') + '\n                      ' + (0, _Global.fontStyleOverride)(config.descriptionFont) + '\n                      max-width: 65%;\n                    ';
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'max.medium',
                    template: 'max-width: 100%'
                  }];
                }
              },
              config.translations.subHeadlineText
            ),
            config.brandLogoUrl && _react2.default.createElement(Image, {
              src: config.brandLogoUrl.value,
              alt: config.brandLogoUrl.label,
              percentWidth: config.brandLogoUrl.percentWidth,
              maxWidth: config.brandLogoUrl.maxWidth,
              height: config.brandLogoUrl.height,
              margin: config.brandLogoUrl.margin
            }),
            config.headerImage && _react2.default.createElement(Image, {
              'data-cy': config.headerImage.value,
              src: config.headerImage.value,
              alt: config.headerImage.label,
              percentWidth: config.headerImage.percentWidth,
              maxWidth: config.headerImage.maxWidth,
              height: config.headerImage.height,
              margin: config.headerImage.margin
            })
          ),
          _react2.default.createElement(
            ItemsContainer,
            null,
            config.items.map(function (icongrid, index) {
              return [_react2.default.createElement(
                IconGridItem,
                {
                  key: index.toString(),
                  paddingBottom: config.itemBottomPadding,
                  width: config.items.length !== 4 ? 100 / config.items.length : 50
                },
                icongrid.image.value && _react2.default.createElement(Image, {
                  'data-cy': icongrid.image.value,
                  src: icongrid.image.value,
                  alt: icongrid.image.label,
                  width: icongrid.image.width,
                  height: icongrid.image.height
                }),
                icongrid.translations.titleItemText && _react2.default.createElement(
                  _Global.HeadingTwo,
                  {
                    styleOverride: function styleOverride() {
                      return '\n                        ' + (0, _Global.fontStyleOverride)(config.itemHeaderFont) + '\n                        width: ' + (config.itemHeaderFont && config.itemHeaderFont.width ? config.itemHeaderFont.width + '%' : '80%') + ';\n                        margin: 4px 0;\n                      ';
                    },
                    displayHeadingDivider: config.displayHeadingDivider,
                    headingDividerStyle: config.headingDividerStyle,
                    'data-cy': icongrid.translations.titleItemText
                  },
                  icongrid.translations.titleItemText
                ),
                icongrid.translations.descriptionItemText && _react2.default.createElement(
                  _Global.Paragraph,
                  {
                    styleOverride: function styleOverride() {
                      return '\n                        ' + (0, _Global.fontStyleOverride)(config.itemDescriptionFont || config.descriptionFont) + '\n                        width: 80%;\n                        margin: 4px 0px;\n                      ';
                    }
                  },
                  translateLinkFromTemplate(icongrid.translations.descriptionItemText, icongrid.translations.descriptionItemLinkUrl, icongrid.translations.descriptionItemLinkText)
                )
              )];
            })
          )
        )
      )
    )
  );
}