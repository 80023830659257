'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 550px;\n  '], ['\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 550px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 650px;\n  '], ['\n    width: 650px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 800px;\n  '], ['\n    width: 800px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding-left: 0px;\n  '], ['\n    padding-left: 0px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

var _UserLocation = require('../../components/UserLocation');

var _UserLocation2 = _interopRequireDefault(_UserLocation);

var _location = require('../../shared/location');

var _location2 = _interopRequireDefault(_location);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _useLocationHook2 = require('./useLocationHook');

var _sessionPreferences = require('../../shared/sessionPreferences');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SearcherContainer = _styledComponents2.default.div.withConfig({
  displayName: 'StickyBottomSearchBar__SearcherContainer',
  componentId: 'sc-1avjg34-0'
})(['border-top:1px solid #d5d5d5;outline:none;background:white;', ' width:100%;'], function (_ref) {
  var isSticky = _ref.isSticky;
  return isSticky ? '\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    z-index: 10;\n  ' : '';
});

var SearchWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'StickyBottomSearchBar__SearchWrapper',
  componentId: 'sc-1avjg34-1'
})(['display:block;padding:5px;box-sizing:border-box;margin:0 auto;', ';', ';', ';'], _theme2.default.min.small(_templateObject), _theme2.default.min.medium(_templateObject2), _theme2.default.min.large(_templateObject3));

var SearcherBar = _styledComponents2.default.div.withConfig({
  displayName: 'StickyBottomSearchBar__SearcherBar',
  componentId: 'sc-1avjg34-2'
})(['display:flex;flex:1 0 auto;']);

var ButtonDiv = _styledComponents2.default.div.withConfig({
  displayName: 'StickyBottomSearchBar__ButtonDiv',
  componentId: 'sc-1avjg34-3'
})(['padding:5px 0px 5px;', ';'], _theme2.default.min.small(_templateObject4));

exports.default = function (_ref2) {
  var _ref2$config = _ref2.config,
      translations = _ref2$config.translations,
      locationIconUrl = _ref2$config.locationIconUrl,
      className = _ref2$config.className,
      cookieNotAcceptedPadding = _ref2$config.cookieNotAcceptedPadding,
      _ref2$globalStyling = _ref2.globalStyling,
      direction = _ref2$globalStyling.direction,
      buttonStyle = _ref2$globalStyling.uiElements.primaryButton.buttonStyle;

  var _useLocationHook = (0, _useLocationHook2.useLocationHook)(),
      onButtonClick = _useLocationHook.onButtonClick,
      updateLocationFilter = _useLocationHook.updateLocationFilter,
      latitude = _useLocationHook.latitude,
      longitude = _useLocationHook.longitude;
  // Only Lamborghini has this component enabled.


  var domEl = document.getElementById('footer-sticky-bar-container');

  var _useLocation = (0, _location2.default)(),
      lat = _useLocation.lat,
      long = _useLocation.long,
      setLat = _useLocation.setLat,
      setLong = _useLocation.setLong,
      googleAvailable = _useLocation.googleAvailable;

  var dispatch = (0, _reactRedux.useDispatch)();

  (0, _react.useEffect)(function () {
    if (cookieNotAcceptedPadding) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('stickyBarStatus', cookieNotAcceptedPadding));
    }
    return function () {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('stickyBarStatus', null));
    };
  }, [cookieNotAcceptedPadding]);

  var SearchBarComponent = function SearchBarComponent() {
    return _react2.default.createElement(
      SearcherContainer,
      { id: 'sticky-search', isSticky: !domEl },
      _react2.default.createElement(
        SearchWrapper,
        null,
        _react2.default.createElement(
          SearcherBar,
          null,
          googleAvailable && _react2.default.createElement(_UserLocation2.default, {
            direction: direction,
            placeholderText: translations.locationPlaceholder,
            iconUrl: locationIconUrl,
            browserLocationCallback: function browserLocationCallback(latResponse, longResponse, locationTownResponse, userCountryResponse) {
              setLat(latResponse);
              setLong(longResponse);
              updateLocationFilter(latResponse, longResponse, locationTownResponse, userCountryResponse);
            },
            inputLocationCallback: function inputLocationCallback(latResponse, longResponse, locationTownResponse, userCountryResponse) {
              setLat(latResponse);
              setLong(longResponse);
              updateLocationFilter(latResponse, longResponse, locationTownResponse, userCountryResponse);
            },
            options: {
              autocompletePosition: 'above',
              latitude: latitude,
              longitude: longitude
            }
          })
        ),
        _react2.default.createElement(
          ButtonDiv,
          null,
          googleAvailable ? _react2.default.createElement(_Global.Button, {
            className: className,
            buttonStyle: buttonStyle,
            styleOverride: function styleOverride() {
              return '\n              height: 46px;\n              border-radius: 0px;\n            ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return 'height: 46px;';
            },
            text: translations.searchCTASearch,
            onClick: onButtonClick,
            disabled: !(latitude && longitude) && !(lat && long)
          }) : _react2.default.createElement(_Global.Button, {
            className: className,
            buttonStyle: buttonStyle,
            styleOverride: function styleOverride() {
              return '\n              height: 46px;\n              border-radius: 0px;\n            ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return 'height: 46px;';
            },
            text: translations.searchCTASearch,
            onClick: onButtonClick,
            disabled: false
          })
        )
      )
    );
  };

  return domEl ? _reactDom2.default.createPortal(_react2.default.createElement(SearchBarComponent, null), domEl) : _react2.default.createElement(SearchBarComponent, null);
};