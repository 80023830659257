'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  margin: 60px 0 45px 0px;\n'], ['\n  margin: 60px 0 45px 0px;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _AnimatedCarousel = require('../../components/AstonMartin/AnimatedCarousel');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AnimatedCarouselTypes = require('../../types/AnimatedCarouselTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'AnimatedCarousel__Container',
  componentId: 'sc-180ka0o-0'
})(['display:flex;flex-direction:column;justify-items:flex-end;margin:94px 0 70px 0px;', ';'], _theme2.default.max.medium(_templateObject));

var AnimatedCarousel = function AnimatedCarousel(_ref) {
  var config = _ref.config;
  var translations = config.translations,
      props = (0, _objectWithoutProperties3.default)(config, ['translations']);


  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(_AnimatedCarousel.AnimatedCarousel, (0, _extends3.default)({}, props, {
      title: translations.title,
      subtitle: translations.subtitle,
      footerLinkText: translations.footerLinkText
    }))
  );
};

exports.default = AnimatedCarousel;