'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _splitEvery = require('ramda/src/splitEvery');

var _splitEvery2 = _interopRequireDefault(_splitEvery);

var _Global = require('../components/Global');

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var dealer = _ref.dealer,
      translations = _ref.translations,
      onButtonClick = _ref.onButtonClick,
      buttonStyle = _ref.buttonStyle;

  var infoWindow = document.createElement('div');
  _reactDom2.default.render(_react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _Global.HeadingTwo,
      { styleOverride: function styleOverride() {
          return 'margin-bottom: 10px;';
        } },
      dealer.name
    ),
    (0, _splitEvery2.default)(2, dealer.address).map(function (addLine) {
      return _react2.default.createElement(
        'div',
        { key: addLine },
        addLine.join(', ')
      );
    }),
    _react2.default.createElement(
      'div',
      null,
      dealer.country
    ),
    _react2.default.createElement(
      'div',
      { style: { marginTop: '10px', fontWeight: 'bold' } },
      (0, _translateFromTemplate.translateFromTemplate)('phoneLabel', { PHONE: dealer.phone }, translations)
    ),
    _react2.default.createElement(_Global.Button, {
      text: translations.buttonText,
      applyStyle: 'secondary',
      buttonStyle: buttonStyle,
      styleOverride: function styleOverride() {
        return 'margin-top: 10px;';
      },
      mobileStyleOverride: function mobileStyleOverride() {
        return 'margin-top: 10px;';
      },
      onClick: onButtonClick
    })
  ), infoWindow);
  return infoWindow;
};