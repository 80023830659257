'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-wrap: nowrap;\n     margin: 40px 0;\n  '], ['\n    flex-wrap: nowrap;\n     margin: 40px 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 48px;\n  '], ['\n    padding: 0 48px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n     padding: 0 0 0 20px;\n  '], ['\n     padding: 0 0 0 20px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n     display: none;\n  '], ['\n     display: none;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _FilterPills = require('../FilterPills/FilterPills');

var _FilterPills2 = _interopRequireDefault(_FilterPills);

var _SortMenuBar = require('./SortMenuBar');

var _SortMenuBar2 = _interopRequireDefault(_SortMenuBar);

var _Spinner = require('../../Spinner');

var _VehicleResults = require('./VehicleResults');

var _VehicleResults2 = _interopRequireDefault(_VehicleResults);

var _FiltersContainer = require('../../../linked-modules/RollsRoyceFilters/FiltersContainer');

var _FiltersContainer2 = _interopRequireDefault(_FiltersContainer);

var _MobileFilters = require('../../RollsRoyce/VehicleSearchResults/MobileFilters');

var _MobileFilters2 = _interopRequireDefault(_MobileFilters);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Wrapper',
  componentId: 'sc-1tdf5f5-0'
})(['display:flex;flex-direction:row;flex-wrap:wrap;width:100%;margin:0;', ';'], _RollsRoyce2.default.min.medium(_templateObject));

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Column',
  componentId: 'sc-1tdf5f5-1'
})(['display:flex;flex-direction:column;flex:1;']);

var FilterWrapper = (0, _styledComponents2.default)(Column).withConfig({
  displayName: 'SearchResults__FilterWrapper',
  componentId: 'sc-1tdf5f5-2'
})(['max-width:288px;']);
var ResultsWrapper = (0, _styledComponents2.default)(Column).withConfig({
  displayName: 'SearchResults__ResultsWrapper',
  componentId: 'sc-1tdf5f5-3'
})(['width:70%;']);

var ResultsTopContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__ResultsTopContainer',
  componentId: 'sc-1tdf5f5-4'
})(['display:flex;flex-direction:row;flex-wrap:nowrap;']);

var ResultsSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__ResultsSectionWrapper',
  componentId: 'sc-1tdf5f5-5'
})(['padding:0 20px;', ';', ';'], _RollsRoyce2.default.min.large(_templateObject2), _RollsRoyce2.default.min.extraLarge(_templateObject3));

var FilterBarMobile = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__FilterBarMobile',
  componentId: 'sc-1tdf5f5-6'
})(['background-color:#f2f2f2;width:100%;border-top:1px solid rgba(34,34,34,0.1);display:flex;padding:9.5px 20px;', ';'], _RollsRoyce2.default.min.medium(_templateObject4));

var SortMenuBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__SortMenuBarContainer',
  componentId: 'sc-1tdf5f5-7'
})(['display:flex;justify-content:flex-end;margin-left:auto;']);

var ResultsSection = function ResultsSection(_ref) {
  var config = _ref.config,
      shared = _ref.shared,
      globalStyling = _ref.globalStyling,
      updateFilters = _ref.updateFilters,
      dispatch = _ref.dispatch,
      router = _ref.router,
      translations = _ref.translations,
      sortOptions = _ref.sortOptions,
      sortValue = _ref.sortValue,
      onSortChange = _ref.onSortChange,
      results = _ref.results,
      onMoreInfoClick = _ref.onMoreInfoClick,
      onEnquiryClick = _ref.onEnquiryClick,
      onShowAllResultsClick = _ref.onShowAllResultsClick,
      onPageNumberChange = _ref.onPageNumberChange,
      compareVehicle = _ref.compareVehicle,
      shortlistVehicle = _ref.shortlistVehicle,
      marketInfo = _ref.marketInfo,
      handleZeroPriceAsText = _ref.handleZeroPriceAsText,
      infiniteScroll = _ref.infiniteScroll,
      loading = _ref.loading,
      fullSubtitle = _ref.fullSubtitle,
      currentPage = _ref.currentPage,
      pageSize = _ref.pageSize,
      totalResults = _ref.totalResults,
      icon360 = _ref.icon360,
      iconYoutubeVideo = _ref.iconYoutubeVideo,
      getSimilarCars = _ref.getSimilarCars,
      isDesktop = _ref.isDesktop;

  return _react2.default.createElement(
    ResultsSectionWrapper,
    null,
    _react2.default.createElement(
      ResultsTopContainer,
      null,
      _react2.default.createElement(_FilterPills2.default, {
        config: config,
        shared: shared,
        globalStyling: globalStyling,
        dispatch: dispatch,
        router: router,
        updateFilters: updateFilters,
        marketInfo: marketInfo
      }),
      !isDesktop && _react2.default.createElement(
        SortMenuBarContainer,
        null,
        totalResults > 0 && _react2.default.createElement(_SortMenuBar2.default, {
          sortOptions: sortOptions,
          sortValue: sortValue,
          onSortChange: onSortChange,
          translations: translations
        })
      )
    ),
    _react2.default.createElement(_VehicleResults2.default, {
      config: config,
      shared: shared,
      results: results,
      onMoreInfoClick: onMoreInfoClick,
      onEnquiryClick: onEnquiryClick,
      translations: translations,
      onShowAllResultsClick: onShowAllResultsClick,
      onPageNumberChange: onPageNumberChange,
      compareVehicle: compareVehicle,
      shortlistVehicle: shortlistVehicle,
      globalStyling: globalStyling,
      locale: marketInfo.locale,
      handleZeroPriceAsText: handleZeroPriceAsText,
      infiniteScroll: infiniteScroll,
      loading: loading,
      fullSubtitle: fullSubtitle,
      currentPage: currentPage,
      pageSize: pageSize,
      totalResults: totalResults,
      icon360: icon360,
      iconYoutubeVideo: iconYoutubeVideo,
      getSimilarCars: getSimilarCars
    })
  );
};

var Filters = function Filters(_ref2) {
  var filtersConfig = _ref2.filtersConfig,
      globalStyling = _ref2.globalStyling,
      toggle = _ref2.toggle,
      marketInfo = _ref2.marketInfo,
      shared = _ref2.shared;
  return _react2.default.createElement(_FiltersContainer2.default, (0, _extends3.default)({}, filtersConfig, {
    globalStyling: globalStyling,
    locale: marketInfo.locale,
    toggle: toggle,
    currencyCode: marketInfo.currencyCode,
    marketInfo: marketInfo,
    shared: shared,
    searchCountryCode: marketInfo.searchCountryCode,
    hideSearch: true
  }));
};

var SearchResults = function SearchResults(props) {
  var config = props.config,
      filtersConfig = props.filtersConfig,
      globalStyling = props.globalStyling,
      translations = props.translations,
      activeFiltersCount = props.activeFiltersCount,
      infiniteScroll = props.infiniteScroll,
      marketInfo = props.marketInfo,
      loading = props.loading,
      shared = props.shared,
      sortOptions = props.sortOptions,
      sortValue = props.sortValue,
      onSortChange = props.onSortChange,
      totalResults = props.totalResults;


  var isDesktop = (0, _useCheckIsMobileScreen2.default)('large');

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      displayMobileFilterOptions = _useState2[0],
      setDisplayMobileFilterOptions = _useState2[1];

  var _toggle = function _toggle() {
    setDisplayMobileFilterOptions(!displayMobileFilterOptions);
  };

  var filters = _react2.default.createElement(Filters, {
    filtersConfig: filtersConfig,
    globalStyling: globalStyling,
    toggle: function toggle() {
      return _toggle();
    },
    marketInfo: marketInfo,
    shared: shared,
    displayMobileFilterOptions: displayMobileFilterOptions
  });

  return _react2.default.createElement(
    Wrapper,
    { 'data-cy': 'search-results' },
    isDesktop && _react2.default.createElement(
      FilterBarMobile,
      null,
      _react2.default.createElement(_MobileFilters2.default, {
        filters: filters,
        translations: translations,
        activeFiltersCount: activeFiltersCount,
        globalStyling: globalStyling,
        config: config,
        shared: shared,
        market: marketInfo.market
      }),
      _react2.default.createElement(
        SortMenuBarContainer,
        null,
        totalResults > 0 && _react2.default.createElement(_SortMenuBar2.default, {
          sortOptions: sortOptions,
          sortValue: sortValue,
          onSortChange: onSortChange,
          translations: translations
        })
      )
    ),
    !isDesktop && _react2.default.createElement(
      FilterWrapper,
      null,
      filters
    ),
    _react2.default.createElement(
      ResultsWrapper,
      null,
      _react2.default.createElement(
        _Spinner.SpinnerWrapper,
        {
          loaded: infiniteScroll || !loading,
          globalStyling: globalStyling,
          label: translations.loadingText
        },
        function () {
          return _react2.default.createElement(ResultsSection, (0, _extends3.default)({ isDesktop: isDesktop }, props));
        }
      )
    )
  );
};

exports.default = SearchResults;