'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('.');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ListItemData = _styledComponents2.default.div.withConfig({
  displayName: 'BulletedList__ListItemData',
  componentId: 'sc-7lbwb4-0'
})(['', ';'], function (_ref) {
  var itemFont = _ref.itemFont;
  return itemFont.fontSize && 'font-size: ' + itemFont.fontSize + 'px';
});

var SeparatedListTitleExtended = (0, _styledComponents2.default)(_.SeparatedListTitle).withConfig({
  displayName: 'BulletedList__SeparatedListTitleExtended',
  componentId: 'sc-7lbwb4-1'
})(['', ';'], function (_ref2) {
  var hideEquipmentListTitle = _ref2.hideEquipmentListTitle;
  return hideEquipmentListTitle && 'display: none';
});

var ListItemContent = function ListItemContent(_ref3) {
  var font = _ref3.font,
      item = _ref3.item;
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      'div',
      { style: { paddingRight: '5px' } },
      '\u2022'
    ),
    _react2.default.createElement(
      ListItemData,
      { itemFont: font },
      item
    )
  );
};

function BulletedList(_ref4) {
  var title = _ref4.title,
      items = _ref4.items,
      styles = _ref4.styles;

  var _ref5 = styles || {},
      _ref5$itemFont = _ref5.itemFont,
      itemFont = _ref5$itemFont === undefined ? '' : _ref5$itemFont,
      _ref5$hideEquipmentLi = _ref5.hideEquipmentListTitle,
      hideEquipmentListTitle = _ref5$hideEquipmentLi === undefined ? '' : _ref5$hideEquipmentLi;

  return _react2.default.createElement(
    _.SeparatedListContainer,
    null,
    _react2.default.createElement(
      _.SeparatedList,
      null,
      items && items.map(function (item, idx) {
        return idx === 0 ? _react2.default.createElement(
          _.SeparatedListItem,
          { key: idx + '_' + item },
          title === 'default' ? _react2.default.createElement(_react.Fragment, null) : _react2.default.createElement(
            SeparatedListTitleExtended,
            {
              hideEquipmentListTitle: hideEquipmentListTitle
            },
            title
          ),
          _react2.default.createElement(
            'div',
            { style: { display: 'flex' } },
            _react2.default.createElement(ListItemContent, { font: itemFont, item: item })
          )
        ) : _react2.default.createElement(
          _.SeparatedListItem,
          { key: idx + '_' + item, flex: true },
          _react2.default.createElement(ListItemContent, { font: itemFont, item: item })
        );
      })
    )
  );
}

BulletedList.defaultProps = {
  title: null
};

exports.default = BulletedList;