'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Shortlist = require('./Shortlist');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Shortlist).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'GenesisShortlistGrid';
var id = exports.id = 'GenesisShortlistGrid';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'A module that displays the shortlisted vehicles of the user';