'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'LanguagePicklist__Select',
  componentId: 'sc-un3jyf-0'
})(['background:none;border:none;cursor:pointer;color:#222;font-size:12px;line-height:24px;letter-spacing:2px;font-family:\'RivieraNights-Medium\';text-transform:uppercase;box-shadow:none;appearance:none;padding-right:25px;&:focus{outline:none;border:none;}background-image:url(\'https://res.cloudinary.com/motortrak/v1709224292/locator/rolls-royce/global/icons/chev-down.svg\');background-position-x:calc(100% - 10px);background-position-y:50%;background-repeat:no-repeat;']);

function LanguagePicklist(_ref) {
  var font = _ref.font,
      value = _ref.value,
      _onChange = _ref.onChange,
      options = _ref.options,
      dataCy = _ref.dataCy;

  return _react2.default.createElement(
    Select,
    {
      'data-cy': dataCy,
      font: font,
      value: value,
      onChange: function onChange(event) {
        return _onChange(event);
      }
    },
    options.map(function (lang) {
      return _react2.default.createElement(
        'option',
        { key: (0, _v2.default)(), value: lang.value },
        lang.label
      );
    })
  );
}
exports.default = LanguagePicklist;