'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = VdpWell;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _dateFns = require('date-fns');

var _reactLinkify = require('react-linkify');

var _reactLinkify2 = _interopRequireDefault(_reactLinkify);

var _Well = require('./Well');

var _Well2 = _interopRequireDefault(_Well);

var _VdpPerformance = require('./VdpPerformance');

var _VdpPerformance2 = _interopRequireDefault(_VdpPerformance);

var _VdpHighlightedFeatures = require('./VdpHighlightedFeatures');

var _VdpHighlightedFeatures2 = _interopRequireDefault(_VdpHighlightedFeatures);

var _VdpOtherEquipment = require('./VdpOtherEquipment');

var _VdpOtherEquipment2 = _interopRequireDefault(_VdpOtherEquipment);

var _VdpSpecification = require('./VdpSpecification');

var _VdpSpecification2 = _interopRequireDefault(_VdpSpecification);

var _VdpFeatures = require('./VdpFeatures');

var _VdpFeatures2 = _interopRequireDefault(_VdpFeatures);

var _VdpRemarks = require('./VdpRemarks');

var _VdpRemarks2 = _interopRequireDefault(_VdpRemarks);

var _ContentWrapper = require('./ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var WellRow = _styledComponents2.default.div.withConfig({
  displayName: 'WellRow'
}).withConfig({
  displayName: 'VdpWell__WellRow',
  componentId: 'sc-1n78vpa-0'
})(['margin:0 auto;box-sizing:border-box;padding:', ';'], function (_ref) {
  var padding = _ref.padding;
  return padding ? '0' : '0 20px';
});


var WellFeatures = _styledComponents2.default.div.withConfig({
  displayName: 'WellFeaturesRow'
}).withConfig({
  displayName: 'VdpWell__WellFeatures',
  componentId: 'sc-1n78vpa-1'
})(['background-color:#f0f0f0;padding:50px 60px;']);

var WellOtherFeatures = _styledComponents2.default.div.withConfig({
  displayName: 'WellOtherFeaturesRow'
}).withConfig({
  displayName: 'VdpWell__WellOtherFeatures',
  componentId: 'sc-1n78vpa-2'
})(['background-color:', ';padding:', ';'], function (_ref2) {
  var backgroundColour = _ref2.backgroundColour;
  return backgroundColour ? '' + backgroundColour.value : '#ffffff';
}, function (_ref3) {
  var padding = _ref3.padding;
  return padding ? '' + padding : '50px 60px';
});

var WellRemarks = _styledComponents2.default.div.withConfig({
  displayName: 'WellRemarksRow'
}).withConfig({
  displayName: 'VdpWell__WellRemarks',
  componentId: 'sc-1n78vpa-3'
})(['background-color:#f0f0f0;padding:50px 60px;']);

var WellSpecs = _styledComponents2.default.div.withConfig({
  displayName: 'WellSpecsRow'
}).withConfig({
  displayName: 'VdpWell__WellSpecs',
  componentId: 'sc-1n78vpa-4'
})(['background-color:', ';padding:50px 60px;'], function (_ref4) {
  var backgroundColour = _ref4.backgroundColour;
  return backgroundColour ? '' + backgroundColour : '#f0f0f0';
});

var WellPerformance = _styledComponents2.default.div.withConfig({
  displayName: 'WellPerformanceRow'
}).withConfig({
  displayName: 'VdpWell__WellPerformance',
  componentId: 'sc-1n78vpa-5'
})(['background-color:#ffffff;padding:50px 60px;']);

var WellContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpWell__WellContainer',
  componentId: 'sc-1n78vpa-6'
})(['display:flex;flex-direction:column;']);

var WellFeaturesHighlights = _styledComponents2.default.div.withConfig({
  displayName: 'VdpWell__WellFeaturesHighlights',
  componentId: 'sc-1n78vpa-7'
})(['background-color:', ';padding:0 60px 50px;'], function (_ref5) {
  var backgroundColour = _ref5.backgroundColour;
  return backgroundColour ? '' + backgroundColour : '#f0f0f0';
});

function VdpWell(_ref6) {
  var featureFlags = _ref6.featureFlags,
      configuration = _ref6.configuration,
      showVin = _ref6.showVin,
      translations = _ref6.translations,
      showRegistration = _ref6.showRegistration,
      showOdometerInformation = _ref6.showOdometerInformation,
      contentWidth = _ref6.contentWidth,
      specificationList = _ref6.specificationList,
      equipmentList = _ref6.equipmentList,
      featuresList = _ref6.featuresList,
      remarksList = _ref6.remarksList,
      headerFont = _ref6.headerFont,
      wellWrapper = _ref6.wellWrapper,
      showEngineCapacity = _ref6.showEngineCapacity,
      locale = _ref6.locale,
      featuredSpec = _ref6.featuredSpec,
      displaySpecs = _ref6.displaySpecs,
      hidePowerKw = _ref6.hidePowerKw,
      convertedKwToHp = _ref6.convertedKwToHp,
      kiaEmissionsEnabled = _ref6.kiaEmissionsEnabled;

  var _ref7 = specificationList || {},
      _ref7$backgroundColou = _ref7.backgroundColour,
      backgroundColour = _ref7$backgroundColou === undefined ? '' : _ref7$backgroundColou,
      _ref7$orderSpecSectio = _ref7.orderSpecSection,
      orderSpecSection = _ref7$orderSpecSectio === undefined ? '' : _ref7$orderSpecSectio;

  var _ref8 = equipmentList || {},
      _ref8$bgColour = _ref8.bgColour,
      bgColour = _ref8$bgColour === undefined ? '' : _ref8$bgColour,
      _ref8$orderEquipmentS = _ref8.orderEquipmentSection,
      orderEquipmentSection = _ref8$orderEquipmentS === undefined ? '' : _ref8$orderEquipmentS,
      _ref8$rowPadding = _ref8.rowPadding,
      rowPadding = _ref8$rowPadding === undefined ? '' : _ref8$rowPadding;

  var _ref9 = featuresList || {},
      _ref9$orderFeaturesSe = _ref9.orderFeaturesSection,
      orderFeaturesSection = _ref9$orderFeaturesSe === undefined ? '' : _ref9$orderFeaturesSe;

  var _ref10 = remarksList || {},
      _ref10$orderRemarksSe = _ref10.orderRemarksSection,
      orderRemarksSection = _ref10$orderRemarksSe === undefined ? '' : _ref10$orderRemarksSe;

  var displayFeatures = configuration.specification.warranty.remainingWarrantyWholeYears > 0 || configuration.co2Emission > 0;

  return _react2.default.createElement(
    WellContainer,
    null,
    _react2.default.createElement(
      _Well2.default,
      null,
      configuration.highlightedFeatures && _react2.default.createElement(
        WellFeatures,
        null,
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: !wellWrapper && contentWidth },
          _react2.default.createElement(
            WellRow,
            null,
            _react2.default.createElement(
              _Global.HeadingTwo,
              { styleOverride: function styleOverride() {
                  return (0, _Global.fontStyleOverride)(headerFont);
                } },
              translations.vdpHighlightedFeaturesHeader
            )
          ),
          _react2.default.createElement(
            WellRow,
            null,
            _react2.default.createElement(_VdpHighlightedFeatures2.default, {
              features: configuration.highlightedFeatures,
              columns: 3
            })
          )
        )
      )
    ),
    _react2.default.createElement(
      _Well2.default,
      { order: orderEquipmentSection },
      configuration.otherEquipment && configuration.otherEquipment.length > 0 && _react2.default.createElement(
        WellOtherFeatures,
        { backgroundColour: bgColour, padding: rowPadding },
        _react2.default.createElement(
          _ContentWrapper2.default,
          {
            contentWidth: contentWidth,
            colour: bgColour.value
          },
          _react2.default.createElement(
            WellRow,
            { padding: wellWrapper },
            _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return (0, _Global.fontStyleOverride)(headerFont);
                }
              },
              translations.vdpOtherEquipmentHeader
            )
          ),
          _react2.default.createElement(
            WellRow,
            { padding: wellWrapper },
            _react2.default.createElement(_VdpOtherEquipment2.default, {
              equipment: configuration.otherEquipment,
              columns: 3,
              styles: equipmentList
            })
          )
        )
      )
    ),
    _react2.default.createElement(
      _Well2.default,
      { order: orderRemarksSection },
      configuration.remarks && _react2.default.createElement(
        WellRemarks,
        null,
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: contentWidth, colour: bgColour.value },
          _react2.default.createElement(
            WellRow,
            null,
            _react2.default.createElement(_VdpRemarks2.default, { content: configuration.remarks })
          )
        )
      )
    ),
    _react2.default.createElement(
      _Well2.default,
      { order: orderSpecSection },
      displaySpecs && !kiaEmissionsEnabled && _react2.default.createElement(
        WellSpecs,
        { backgroundColour: backgroundColour },
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: contentWidth },
          _react2.default.createElement(
            WellRow,
            { padding: wellWrapper },
            _react2.default.createElement(
              _Global.HeadingTwo,
              { styleOverride: function styleOverride() {
                  return (0, _Global.fontStyleOverride)(headerFont);
                } },
              translations.vdpSpecificationHeader
            )
          ),
          _react2.default.createElement(
            WellRow,
            { padding: wellWrapper },
            _react2.default.createElement(_VdpSpecification2.default, {
              id: 'vehicle-specification',
              specification: configuration.specification,
              showVin: showVin,
              columns: 3,
              translations: translations,
              registration: configuration.registrationDate && (0, _dateFns.format)(configuration.registrationDate, 'DD-MM-YYYY') || configuration.registration,
              showRegistration: showRegistration,
              showOdometerInformation: showOdometerInformation,
              styles: specificationList,
              showEngineCapacity: showEngineCapacity,
              locale: locale,
              featuredSpec: featuredSpec,
              vehicleInfo: configuration,
              hidePowerKw: hidePowerKw,
              convertedKwToHp: convertedKwToHp
            })
          )
        ),
        translations.disclaimerText && _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: contentWidth },
          _react2.default.createElement(
            _Global.HeadingTwo,
            { styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)(headerFont);
              } },
            translations.disclaimerHeader
          ),
          _react2.default.createElement(
            WellRow,
            { padding: wellWrapper },
            _react2.default.createElement(
              _reactLinkify2.default,
              {
                properties: {
                  target: 'blank'
                }
              },
              translations.disclaimerText
            )
          )
        )
      ),
      configuration.specification && !featuredSpec && _react2.default.createElement(
        WellSpecs,
        { backgroundColour: backgroundColour },
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: contentWidth },
          _react2.default.createElement(
            WellRow,
            { padding: wellWrapper },
            _react2.default.createElement(
              _Global.HeadingTwo,
              { styleOverride: function styleOverride() {
                  return (0, _Global.fontStyleOverride)(headerFont);
                } },
              translations.vdpSpecificationHeader
            )
          ),
          _react2.default.createElement(
            WellRow,
            { padding: wellWrapper },
            _react2.default.createElement(_VdpSpecification2.default, {
              id: 'vehicle-specification',
              specification: configuration.specification,
              showVin: showVin,
              columns: 3,
              translations: translations,
              registration: configuration.registrationDate && (0, _dateFns.format)(configuration.registrationDate, 'DD-MM-YYYY') || configuration.registration,
              showRegistration: showRegistration,
              showOdometerInformation: showOdometerInformation,
              styles: specificationList,
              showEngineCapacity: showEngineCapacity,
              locale: locale,
              vehicleInfo: configuration,
              hidePowerKw: hidePowerKw
            })
          )
        )
      ),
      configuration.performance && _react2.default.createElement(
        WellPerformance,
        null,
        _react2.default.createElement(
          WellRow,
          null,
          _react2.default.createElement(
            _Global.HeadingTwo,
            { styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)(headerFont);
              } },
            translations.vdpPerformanceHeader
          )
        ),
        _react2.default.createElement(
          WellRow,
          null,
          _react2.default.createElement(_VdpPerformance2.default, { performance: configuration.performance })
        )
      )
    ),
    featureFlags.vdpFeatures && displayFeatures && _react2.default.createElement(
      _Well2.default,
      { order: orderFeaturesSection },
      _react2.default.createElement(
        WellFeaturesHighlights,
        {
          backgroundColour: featuresList.backgroundColour
        },
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: contentWidth },
          _react2.default.createElement(
            WellRow,
            { padding: wellWrapper },
            _react2.default.createElement(
              _Global.HeadingTwo,
              { styleOverride: function styleOverride() {
                  return (0, _Global.fontStyleOverride)(headerFont);
                } },
              translations.vdpFeaturesHeader
            )
          ),
          _react2.default.createElement(
            WellRow,
            { padding: wellWrapper },
            _react2.default.createElement(_VdpFeatures2.default, {
              featuresList: featuresList,
              translations: translations,
              locale: locale,
              vehicleDetails: configuration
            })
          )
        )
      )
    )
  );
}