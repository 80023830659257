'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Close;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Close(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 27 25' },
      _react2.default.createElement('path', { d: 'M4.244 21.756a.831.831 0 0 0 1.179 0L14 13.178l8.577 8.578a.831.831 0 0 0 1.179 0 .832.832 0 0 0 0-1.179L15.178 12l8.578-8.577a.832.832 0 1 0-1.179-1.179L14 10.822 5.423 2.244a.832.832 0 1 0-1.179 1.179L12.822 12l-8.578 8.577a.832.832 0 0 0 0 1.179' })
    )
  );
}

Close.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};