'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.useQuickContact = useQuickContact;

var _react = require('react');

var _validations = require('./validations');

var _validations2 = _interopRequireDefault(_validations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useQuickContact(initialState, translations) {
  var _useState = (0, _react.useState)(initialState),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      values = _useState2[0],
      setValues = _useState2[1];

  var _useState3 = (0, _react.useState)({}),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      errors = _useState4[0],
      setErrors = _useState4[1];

  var _useState5 = (0, _react.useState)('unsubmitted'),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      submissionStatus = _useState6[0],
      setSubmissionStatus = _useState6[1];

  (0, _react.useEffect)(function () {
    if (submissionStatus === 'isSubmitting') {
      var noErrors = (0, _keys2.default)(errors).length === 0;
      if (noErrors) {
        setSubmissionStatus('submitted');
      } else {
        setSubmissionStatus('unsubmitted');
      }
    }
  }, [submissionStatus]);

  function handleChange(event) {
    setValues((0, _extends4.default)({}, values, (0, _defineProperty3.default)({}, event.target.name, (0, _extends4.default)({}, values[event.target.name], {
      value: event.target.value
    }))));
  }

  function handleBlur(event) {
    var blurError = (0, _validations2.default)(values[event.target.name], translations);
    setErrors((0, _extends4.default)({}, errors, blurError));
  }

  function handleSubmit(event) {
    event.preventDefault();
    var submissionErrors = (0, _validations.validateAll)(values, translations);
    setErrors(submissionErrors);
    setSubmissionStatus('submitted');
  }

  return {
    handleChange: handleChange,
    handleSubmit: handleSubmit,
    handleBlur: handleBlur,
    values: values,
    errors: errors,
    submissionStatus: submissionStatus
  };
}