'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalContentWrapper = exports.Overlay = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    height: auto;\n    width: ', ';\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  '], ['\n    height: auto;\n    width: ', ';\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Overlay = exports.Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'ModalOverlay__Overlay',
  componentId: 'sc-y95d6z-0'
})(['position:fixed;height:100vh;width:100%;top:0;left:0;background-color:rgba(0,0,0,0.6);visibility:hidden;opacity:0;', ';overflow:hidden;', ';'], function (_ref) {
  var open = _ref.open;
  return open && 'z-index: 1000';
}, function (_ref2) {
  var open = _ref2.open;
  return open && 'visibility: visible; opacity: 1;';
});

var ModalContentWrapper = exports.ModalContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModalOverlay__ModalContentWrapper',
  componentId: 'sc-y95d6z-1'
})(['width:100%;height:100vh;background-color:#ffffff;position:absolute;top:0;left:0;', ';'], _theme2.default.min.medium(_templateObject, function (_ref3) {
  var modalWidth = _ref3.modalWidth;
  return modalWidth ? modalWidth + 'px;' : 'auto';
}));

var ModalContent = _styledComponents2.default.div.withConfig({
  displayName: 'ModalOverlay__ModalContent',
  componentId: 'sc-y95d6z-2'
})(['width:100%;height:100%;']);

var Close = _styledComponents2.default.img.withConfig({
  displayName: 'ModalOverlay__Close',
  componentId: 'sc-y95d6z-3'
})(['position:absolute;top:0;right:0;box-sizing:border-box;margin:10px 10px;cursor:pointer;']);

var ClickComponent = _styledComponents2.default.div.withConfig({
  displayName: 'ModalOverlay__ClickComponent',
  componentId: 'sc-y95d6z-4'
})(['display:flex;*{cursor:pointer;}']);

var ModalOverlay = function (_Component) {
  (0, _inherits3.default)(ModalOverlay, _Component);

  function ModalOverlay(props) {
    (0, _classCallCheck3.default)(this, ModalOverlay);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ModalOverlay.__proto__ || (0, _getPrototypeOf2.default)(ModalOverlay)).call(this, props));

    Object.defineProperty(_this, 'onToggleModal', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.setState(function (prevState) {
          return { open: !prevState.open };
        });
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var open = _this.state.open;
        var _this$props = _this.props,
            closeIconUrl = _this$props.closeIconUrl,
            component = _this$props.component,
            modalWidth = _this$props.modalWidth,
            renderComponentWithProps = _this$props.renderComponentWithProps,
            children = _this$props.children;

        return _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            ClickComponent,
            { onClick: _this.onToggleModal },
            component
          ),
          _react2.default.createElement(
            Overlay,
            { open: open, 'data-cy': 'localisation' },
            _react2.default.createElement(
              ModalContentWrapper,
              { modalWidth: modalWidth },
              _react2.default.createElement(Close, {
                tabIndex: '0',
                'aria-label': 'Close overlay',
                src: closeIconUrl,
                alt: 'close',
                'data-cy': 'close',
                onClick: function onClick() {
                  return _this.onToggleModal();
                },
                onKeyDown: function onKeyDown(e) {
                  if (e.key === 'Enter' || e.key === 'Escape') {
                    _this.onToggleModal();
                  }
                }
              }),
              _react2.default.createElement(
                ModalContent,
                null,
                renderComponentWithProps ? renderComponentWithProps(_this.onToggleModal) : children
              )
            )
          )
        );
      }
    });


    _this.state = {
      open: false
    };
    return _this;
  }

  return ModalOverlay;
}(_react.Component);

exports.default = ModalOverlay;