'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Efficiency;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Efficiency(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 25 20' },
      _react2.default.createElement('path', { d: 'M5.717 16.005l.036.03c.943-3.05 8.83-9.558 14.128-13.112-1.508 1.041-11.903 9.523-13.333 13.872C19.1 24.333 21.845 1 21.845 1-1.914 4.462 5.717 16.005 5.717 16.005zm-.01.657a5.124 5.124 0 0 1-2.38 1.774 1.138 1.138 0 0 1-.84-.062.3.3 0 0 0-.33.082.287.287 0 0 0 .13.544c.066.002.133-.002.2-.01.145-.028.288-.071.425-.128.764-.252 2.938-1.057 3.251-1.97.23-.682-.087-.625-.251-.487a1.407 1.407 0 0 0-.205.257z' })
    )
  );
}

Efficiency.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};