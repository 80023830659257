'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _styledComponents2.default.div.withConfig({
  displayName: 'Icon',
  componentId: 'sc-1ww06pa-0'
})(['width:100%;background-image:url(', ');background-size:auto;background-repeat:no-repeat;background-position:', ';min-height:', 'px;'], function (props) {
  return props.src;
}, function (props) {
  return props.imagePosition || 'center';
}, function (props) {
  return props.imageHeight || 300;
});