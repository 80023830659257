'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = StyledHandle;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _rcSlider = require('rc-slider');

var _Chunky = require('../JaguarIcons/Arrows/Chunky');

var _Chunky2 = _interopRequireDefault(_Chunky);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HandleContainer = (0, _styledComponents2.default)(_rcSlider.Handle).withConfig({
  displayName: 'Handle__HandleContainer',
  componentId: 'sc-pgbywm-0'
})(['width:25px;height:25px;border:1px solid #0c0c0c;margin-top:-11px;border-radius:50%;background-color:#fff;position:absolute;touch-action:pan-x;cursor:grab;']);

var Arrow = _styledComponents2.default.div.withConfig({
  displayName: 'Handle__Arrow',
  componentId: 'sc-pgbywm-1'
})(['position:absolute;left:', ';top:', ';width:10px;transform:', ';'], function (_ref) {
  var left = _ref.left;
  return left || '7px';
}, function (_ref2) {
  var top = _ref2.top;
  return top || '3px';
}, function (_ref3) {
  var transform = _ref3.transform;
  return transform || 'none';
});

function StyledHandle(props) {
  return _react2.default.createElement(
    HandleContainer,
    {
      'data-cy': 'slider-handle',
      key: props.index,
      value: props.value,
      offset: props.offset
    },
    _react2.default.createElement(
      Arrow,
      null,
      _react2.default.createElement(_Chunky2.default, { height: '10px', colour: '#0C0C0C' })
    ),
    _react2.default.createElement(
      Arrow,
      { transform: 'rotateY(180deg)', left: '5px' },
      _react2.default.createElement(_Chunky2.default, { height: '10px', colour: '#0C0C0C' })
    )
  );
}