'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n  '], ['\n  ', ';\n  ']);

exports.default = DatePickerWrapper;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _DatePicker = require('./DatePicker');

var _DatePicker2 = _interopRequireDefault(_DatePicker);

var _Calendar = require('./JaguarIcons/Global/Calendar');

var _Calendar2 = _interopRequireDefault(_Calendar);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DatePickerContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DatePickerWrapper__DatePickerContainer',
  componentId: 'sc-xikwn0-0'
})(['position:absolute;z-index:1;width:300px;border:1px solid #d8d8d8;background-color:#ffffff;font-family:', ';display:flex;padding-top:15px;box-shadow:0 4px 20px 0 rgba(0,0,0,0.18);display:', ';box-sizing:border-box;', ';'], function (props) {
  return props.theme.fontFamily;
}, function (_ref) {
  var open = _ref.open;
  return open ? 'block' : 'none';
}, _theme2.default.max.small(_templateObject, function (_ref2) {
  var mobileWidth = _ref2.mobileWidth;
  return '' + (mobileWidth && 'width: ' + mobileWidth + '%');
}));

var CalendarIconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DatePickerWrapper__CalendarIconContainer',
  componentId: 'sc-xikwn0-1'
})(['padding:', ';width:auto;border:1px solid ', ';background-color:#ffffff;display:flex;justify-content:space-between;'], function (_ref3) {
  var calendarIconContainer = _ref3.calendarIconContainer;
  return calendarIconContainer && calendarIconContainer.padding ? calendarIconContainer.padding + 'px' : '10px';
}, function (_ref4) {
  var calendarIconContainer = _ref4.calendarIconContainer;
  return calendarIconContainer && calendarIconContainer.borderColour ? calendarIconContainer.borderColour : '#999999';
});

var DatePickerWrapperContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DatePickerWrapper__DatePickerWrapperContainer',
  componentId: 'sc-xikwn0-2'
})(['height:auto;width:100%;margin-top:', ';'], function (_ref5) {
  var datePickerWrapperContainer = _ref5.datePickerWrapperContainer;
  return datePickerWrapperContainer && datePickerWrapperContainer.marginTop ? datePickerWrapperContainer.marginTop : '15px';
});

var FormattedDateContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DatePickerWrapper__FormattedDateContainer',
  componentId: 'sc-xikwn0-3'
})(['float:left;margin:5px;color:#7e7e7e;font-size:', ';line-height:17px;text-transform:capitalize;'], function (_ref6) {
  var formattedDateContainer = _ref6.formattedDateContainer;
  return formattedDateContainer && formattedDateContainer.fontSize ? formattedDateContainer.fontSize + 'px' : '14px';
});

var IconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DatePickerWrapper__IconContainer',
  componentId: 'sc-xikwn0-4'
})(['float:right;cursor:pointer;margin-top:', ';'], function (_ref7) {
  var iconContainer = _ref7.iconContainer;
  return iconContainer && iconContainer.marginTop ? iconContainer.marginTop + 'px' : '0';
});

var DatePickerItem = function DatePickerItem(_ref8) {
  var open = _ref8.open,
      date = _ref8.date,
      selected = _ref8.selected,
      toggleCalendar = _ref8.toggleCalendar,
      onSelectDate = _ref8.onSelectDate,
      globalStyling = _ref8.globalStyling,
      calendarStyles = _ref8.calendarStyles,
      translations = _ref8.translations,
      locale = _ref8.locale,
      mobileWidth = _ref8.mobileWidth;

  var handleOnDateSelected = function handleOnDateSelected(_ref9) {
    var selectedDate = _ref9.date;

    onSelectDate('date', selectedDate);
    toggleCalendar();
  };

  var localeFormatted = 'en-GB';

  if (locale) {
    localeFormatted = locale.replace('_', '-');
  }

  var formattedDate = selected ? new Date(selected).toLocaleDateString(localeFormatted, {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }) : null;

  var _ref10 = calendarStyles || '',
      _ref10$calendarIconCo = _ref10.calendarIconContainer,
      calendarIconContainer = _ref10$calendarIconCo === undefined ? '' : _ref10$calendarIconCo,
      _ref10$datePickerWrap = _ref10.datePickerWrapperContainer,
      datePickerWrapperContainer = _ref10$datePickerWrap === undefined ? '' : _ref10$datePickerWrap,
      _ref10$iconContainer = _ref10.iconContainer,
      iconContainer = _ref10$iconContainer === undefined ? '' : _ref10$iconContainer,
      _ref10$formattedDateC = _ref10.formattedDateContainer,
      formattedDateContainer = _ref10$formattedDateC === undefined ? '' : _ref10$formattedDateC;

  var primaryBrandColour = globalStyling.colours.primaryBrandColour;

  return _react2.default.createElement(
    DatePickerWrapperContainer,
    {
      datePickerWrapperContainer: datePickerWrapperContainer
    },
    _react2.default.createElement(
      CalendarIconContainer,
      {
        onClick: toggleCalendar,
        calendarIconContainer: calendarIconContainer
      },
      _react2.default.createElement(
        FormattedDateContainer,
        { formattedDateContainer: formattedDateContainer },
        formattedDate
      ),
      _react2.default.createElement(
        IconContainer,
        { iconContainer: iconContainer },
        _react2.default.createElement(_Calendar2.default, {
          colour: primaryBrandColour.value || '#7E7E7E',
          width: '27px',
          height: '24px'
        })
      )
    ),
    _react2.default.createElement(
      DatePickerContainer,
      {
        open: open,
        date: new Date(date),
        mobileWidth: mobileWidth
      },
      _react2.default.createElement(_DatePicker2.default, {
        selected: selected,
        date: new Date(date),
        onDateSelected: handleOnDateSelected,
        globalStyling: globalStyling,
        calendarStyles: calendarStyles,
        translations: translations
      })
    )
  );
};

function DatePickerWrapper(_ref11) {
  var open = _ref11.open,
      date = _ref11.date,
      selected = _ref11.selected,
      toggleCalendar = _ref11.toggleCalendar,
      onSelectDate = _ref11.onSelectDate,
      globalStyling = _ref11.globalStyling,
      calendarStyles = _ref11.calendarStyles,
      translations = _ref11.translations,
      locale = _ref11.locale,
      mobileWidth = _ref11.mobileWidth;

  return _react2.default.createElement(DatePickerItem, {
    open: open,
    date: date,
    selected: selected,
    toggleCalendar: toggleCalendar,
    onSelectDate: onSelectDate,
    globalStyling: globalStyling,
    calendarStyles: calendarStyles,
    translations: translations,
    locale: locale,
    mobileWidth: mobileWidth
  });
}