'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 42px;\n    letter-spacing: -1.05px;\n    line-height: 40px;\n  '], ['\n    font-size: 42px;\n    letter-spacing: -1.05px;\n    line-height: 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 16px;\n    letter-spacing: -0.4px;\n    line-height: 24px;\n  '], ['\n    font-size: 16px;\n    letter-spacing: -0.4px;\n    line-height: 24px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SearchHeader__Container',
  componentId: 'sc-j8oytn-0'
})(['align-items:flex-start;display:flex;flex-direction:column;gap:4px;max-width:1220px;width:100%;']);

var Header = _styledComponents2.default.h1.withConfig({
  displayName: 'SearchHeader__Header',
  componentId: 'sc-j8oytn-1'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Light\';font-size:60px;font-weight:normal;letter-spacing:-1.5px;line-height:56px;margin:0;', ''], _Genesis2.default.max.large(_templateObject));

var SubHeader = _styledComponents2.default.div.withConfig({
  displayName: 'SearchHeader__SubHeader',
  componentId: 'sc-j8oytn-2'
})(['color:#ccc;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:18px;letter-spacing:-0.45px;line-height:24px;', ''], _Genesis2.default.max.large(_templateObject2));

var SearchHeader = function SearchHeader(_ref) {
  var title = _ref.title,
      subtitle = _ref.subtitle;
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Header,
      null,
      title
    ),
    _react2.default.createElement(
      SubHeader,
      null,
      subtitle
    )
  );
};
exports.default = SearchHeader;