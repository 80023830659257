'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _sessionPreferences = require('../../shared/sessionPreferences');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'FooterStickyBar__Container',
  componentId: 'sc-1fjr8fx-0'
})(['box-sizing:border-box;display:flex;align-items:center;justify-content:center;flex-direction:column;width:100%;position:fixed;bottom:0;z-index:100;']);

var FooterStickyBar = function FooterStickyBar() {
  var dispatch = (0, _reactRedux.useDispatch)();

  // Default configuration.
  var cookieNotAcceptedPadding = {
    paddingBottom: 20,
    paddingBottomCookies: 60,
    paddingBottomMobile: 100,
    paddingBottomCookiesMobile: 70
  };

  // Element displayed on Search page, where the user enters its location for searching.
  var hasSearchBar = document.getElementById('sticky-search') !== null;
  // Element that displays vehicles emissions information.
  var hasEmissionsBar = document.getElementById('emissions-bar') !== null;

  // For each different component status, we need to apply different styling.
  (0, _react.useEffect)(function () {
    if (hasSearchBar && hasEmissionsBar) {
      cookieNotAcceptedPadding = (0, _extends3.default)({}, cookieNotAcceptedPadding, {
        paddingBottom: 120,
        paddingBottomCookies: 170,
        paddingBottomMobile: 240,
        paddingBottomCookiesMobile: 320
      });
    } else if (hasSearchBar && !hasEmissionsBar) {
      cookieNotAcceptedPadding = (0, _extends3.default)({}, cookieNotAcceptedPadding, {
        paddingBottom: 70,
        paddingBottomCookies: 120,
        paddingBottomMobile: 120,
        paddingBottomCookiesMobile: 200
      });
    } else if (!hasSearchBar && !hasEmissionsBar) {
      cookieNotAcceptedPadding = (0, _extends3.default)({}, cookieNotAcceptedPadding, {
        paddingBottom: 20,
        paddingBottomCookies: 70,
        paddingBottomMobile: 20,
        paddingBottomCookiesMobile: 100
      });
    } else if (!hasSearchBar && hasEmissionsBar) {
      cookieNotAcceptedPadding = (0, _extends3.default)({}, cookieNotAcceptedPadding, {
        paddingBottom: 40,
        paddingBottomCookies: 100,
        paddingBottomMobile: 120,
        paddingBottomCookiesMobile: 220
      });
    }

    // This configuration will be used from Footer component.
    dispatch(_sessionPreferences.actions.updateSessionPreferences('footerStickyBar', cookieNotAcceptedPadding));
  }, [hasSearchBar, hasEmissionsBar]);

  return _react2.default.createElement(Container, { id: 'footer-sticky-bar-container' });
};

exports.default = FooterStickyBar;