'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  width: 75%;\n   ', '\n  '], ['\n  width: 75%;\n   ', '\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Arrow = require('../../../assets/AstonMartin/Arrow');

var _Arrow2 = _interopRequireDefault(_Arrow);

var _useIsInViewport = require('../../../hooks/useIsInViewport');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Slide__Container',
  componentId: 'sc-dcxhph-0'
})(['display:flex;flex-direction:column;flex-shrink:0;border:1px solid rgba(0,0,0,0.15);width:40%;z-index:0;', ' margin:10px;', ''], _theme2.default.max.medium(_templateObject, function (_ref) {
  var index = _ref.index;
  return index === 0 && 'margin-left: 24px;';
}), function (_ref2) {
  var index = _ref2.index,
      initialMargin = _ref2.initialMargin;
  return index === 0 && 'margin-left: ' + (initialMargin || 64) + 'px';
});

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'Slide__Image',
  componentId: 'sc-dcxhph-1'
})(['width:100%;pointer-events:none;']);

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'Slide__Title',
  componentId: 'sc-dcxhph-2'
})(['font-size:14px;letter-spacing:0;line-height:20px;font-family:AstonMartinFlare-Regular;color:#000000;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;']);

var Subtitle = _styledComponents2.default.div.withConfig({
  displayName: 'Slide__Subtitle',
  componentId: 'sc-dcxhph-3'
})(['font-size:14px;font-weight:300;letter-spacing:0;line-height:18px;font-family:AstonMartinSans-Light;overflow:hidden;text-overflow:ellipsis;white-space:normal;-webkit-line-clamp:3;-webkit-box-orient:vertical;display:-webkit-box;']);

var TitleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Slide__TitleContainer',
  componentId: 'sc-dcxhph-4'
})(['display:flex;margin-top:30px;']);
var SubtitleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Slide__SubtitleContainer',
  componentId: 'sc-dcxhph-5'
})(['display:flex;margin-top:10px;']);

var SlideBody = _styledComponents2.default.div.withConfig({
  displayName: 'Slide__SlideBody',
  componentId: 'sc-dcxhph-6'
})(['display:flex;flex-direction:column;padding:10px;justify-content:space-between;flex:1;']);

var ExploreLinkContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Slide__ExploreLinkContainer',
  componentId: 'sc-dcxhph-7'
})(['display:flex;margin-top:21px;']);

var FooterText = _styledComponents2.default.span.withConfig({
  displayName: 'Slide__FooterText',
  componentId: 'sc-dcxhph-8'
})(['margin-right:1em;font-size:14px;line-height:\'20px\';display:flex;justify-content:space-between;', ''], function (_ref3) {
  var fontFamily = _ref3.fontFamily;
  return fontFamily && 'font-family: ' + fontFamily + ';';
});

var Slide = function Slide(_ref4) {
  var img = _ref4.img,
      title = _ref4.title,
      subtitle = _ref4.subtitle,
      exploreLink = _ref4.exploreLink,
      exploreLinkText = _ref4.exploreLinkText,
      index = _ref4.index,
      onClick = _ref4.onClick,
      onUpdate = _ref4.onUpdate,
      onMouseEnter = _ref4.onMouseEnter,
      onMouseLeave = _ref4.onMouseLeave,
      initialMargin = _ref4.initialMargin;

  var ref = (0, _react.useRef)(null);
  //Set true when the element is 35% in view port
  var isVisible = (0, _useIsInViewport.useIsInViewport)(ref, {
    threshold: 0.35
  });

  (0, _react.useEffect)(function () {
    if (!onUpdate || !ref.current) {
      return;
    }

    var div = ref.current;
    var clientWidth = div.clientWidth || 0;
    onUpdate({ clientWidth: clientWidth });
  }, [ref]);

  var handleClick = (0, _react.useCallback)(function (event) {
    if (!ref.current) {
      return;
    }
    var div = ref.current;
    var clientWidth = div.clientWidth || 0;
    onClick && onClick({ event: event, isVisible: isVisible, clientWidth: clientWidth, index: index, exploreLink: exploreLink });
  }, [ref, onClick, isVisible, index, exploreLink]);

  return _react2.default.createElement(
    Container,
    {
      ref: ref,
      onClick: handleClick,
      index: index,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      initialMargin: initialMargin
    },
    _react2.default.createElement(Image, { alt: 'img-' + index, src: img }),
    _react2.default.createElement(
      SlideBody,
      null,
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          TitleContainer,
          null,
          _react2.default.createElement(
            Title,
            null,
            title
          )
        ),
        _react2.default.createElement(
          SubtitleContainer,
          null,
          _react2.default.createElement(
            Subtitle,
            null,
            subtitle
          )
        )
      ),
      _react2.default.createElement(
        ExploreLinkContainer,
        null,
        _react2.default.createElement(
          'div',
          { style: { display: 'flex', justifyContent: 'space-between' } },
          _react2.default.createElement(
            FooterText,
            { color: '#000000', fontFamily: 'AstonMartinFlare-Regular' },
            exploreLinkText
          ),
          _react2.default.createElement(_Arrow2.default, { colour: '#000000' })
        )
      )
    )
  );
};

exports.default = Slide;