'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AdditionalComments = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n   margin: 120px 0 40px;\n  '], ['\n   margin: 120px 0 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n   font-size: 36px;\n    margin: 10px 0 40px;\n  '], ['\n   font-size: 36px;\n    margin: 10px 0 40px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _vehicleTypes = require('../../../../types/Genesis/vehicleTypes');

var _types = require('../types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'AdditionalComments__Wrapper',
  componentId: 'sc-xlkwe7-0'
})(['color:#fff;margin:40px 0;', ';'], _Genesis2.default.min.medium(_templateObject));

var SectionTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'AdditionalComments__SectionTitle',
  componentId: 'sc-xlkwe7-1'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Light\';font-size:24px;font-weight:normal;margin:10px 0 20px;text-transform:uppercase;', ';'], _Genesis2.default.min.medium(_templateObject2));

var AdditionalComments = function AdditionalComments(_ref) {
  var vehicle = _ref.vehicle,
      translations = _ref.translations;

  if (vehicle.additionalComments !== null) {
    return _react2.default.createElement(
      Wrapper,
      { id: 'AdditionalComments' },
      _react2.default.createElement(
        SectionTitle,
        null,
        translations.titleAdditionalComments
      ),
      _react2.default.createElement(
        'div',
        null,
        vehicle.additionalComments
      )
    );
  } else {
    return null;
  }
};

exports.AdditionalComments = AdditionalComments;