'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none'], ['\n    display: none']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Chunky = require('../JaguarIcons/Arrows/Chunky');

var _Chunky2 = _interopRequireDefault(_Chunky);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TitleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'renderFilterClose__TitleContainer',
  componentId: 'sc-1gacas8-0'
})(['position:relative;width:100%;cursor:pointer;text-transform:uppercase;color:#444;line-height:40px;height:40px;text-align:center;', ';'], _theme2.default.min.medium(_templateObject));

var Chevron = _styledComponents2.default.div.withConfig({
  displayName: 'renderFilterClose__Chevron',
  componentId: 'sc-1gacas8-1'
})(['display:flex;position:absolute;left:15px;top:25px;width:10px;height:10px;transform:rotate(180deg);']);

var renderFilterClose = function renderFilterClose(props) {
  return function () {
    return _react2.default.createElement(
      TitleContainer,
      null,
      props.title,
      _react2.default.createElement(
        Chevron,
        null,
        _react2.default.createElement(_Chunky2.default, { colour: '#7E7E7E' })
      )
    );
  };
};

exports.default = renderFilterClose;