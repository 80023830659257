'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px 5px;\n  '], ['\n    padding: 20px 5px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FieldError = require('./FieldError');

var _FieldError2 = _interopRequireDefault(_FieldError);

var _WidthWrapper = require('./WidthWrapper');

var _WidthWrapper2 = _interopRequireDefault(_WidthWrapper);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CheckboxGroupTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Checkbox__CheckboxGroupTitle',
  componentId: 'sc-3416u9-0'
})(['margin-bottom:10px;']);

var FormFieldContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Checkbox__FormFieldContainer',
  componentId: 'sc-3416u9-1'
})(['width:100%;display:flex;flex-direction:column;padding-bottom:5px;', ';'], _theme2.default.min.medium(_templateObject));

var defaultBackgroundImage = 'https://res.cloudinary.com/motortrak/image/upload/v1571994264/cms/global/icons/Tick.svg';

var Icon = _styledComponents2.default.div.withConfig({
  displayName: 'Checkbox__Icon',
  componentId: 'sc-3416u9-2'
})(['width:24px;height:24px;background-image:url(', ');background-repeat:no-repeat;background-position:35% 30%;'], function (_ref) {
  var backgroundImage = _ref.backgroundImage;
  return backgroundImage || defaultBackgroundImage;
});

var HiddenCheckbox = _styledComponents2.default.input.withConfig({
  displayName: 'Checkbox__HiddenCheckbox',
  componentId: 'sc-3416u9-3'
})(['border:0;clip:rect(0 0 0 0);clippath:inset(50%);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;white-space:nowrap;width:1px;']);

var StyledCheckbox = _styledComponents2.default.div.withConfig({
  displayName: 'Checkbox__StyledCheckbox',
  componentId: 'sc-3416u9-4'
})(['display:inline-block;width:24px;height:24px;border:2px solid ', ';background-color:', ';', '{visibility:', ';}'], function (_ref2) {
  var borderColour = _ref2.borderColour;
  return borderColour || '#999999';
}, function (_ref3) {
  var backgroundColour = _ref3.backgroundColour;
  return backgroundColour || '#dddddd';
}, Icon, function (props) {
  return props.checked ? 'visible' : 'hidden';
});

var CheckboxLabel = _styledComponents2.default.label.withConfig({
  displayName: 'Checkbox__CheckboxLabel',
  componentId: 'sc-3416u9-5'
})(['height:24px;margin-bottom:8px;display:flex;align-items:center;']);

var CheckboxLabelText = _styledComponents2.default.span.withConfig({
  displayName: 'Checkbox__CheckboxLabelText',
  componentId: 'sc-3416u9-6'
})(['margin-left:10px;display:flex;']);

exports.default = function (_ref4) {
  var required = _ref4.required,
      name = _ref4.name,
      label = _ref4.label,
      onChange = _ref4.onChange,
      options = _ref4.options,
      values = _ref4.value,
      error = _ref4.error,
      checkbox = _ref4.uiElements.checkbox,
      translations = _ref4.translations;

  function updateChecked(key) {
    var value = (0, _extends3.default)({}, values);
    value[key] = !value[key];
    onChange({ name: name, value: value });
  }

  return _react2.default.createElement(
    _WidthWrapper2.default,
    null,
    _react2.default.createElement(
      FormFieldContainer,
      { role: 'group', 'aria-labelledby': 'cbGroupName' },
      _react2.default.createElement(
        CheckboxGroupTitle,
        { id: 'cbGroupName' },
        label,
        ' ',
        required && '*'
      ),
      options.map(function (translationKey) {
        var optionLabel = translations[translationKey];

        return _react2.default.createElement(
          CheckboxLabel,
          { htmlFor: optionLabel, key: translationKey },
          _react2.default.createElement(
            StyledCheckbox,
            (0, _extends3.default)({ checked: values[translationKey] }, checkbox),
            _react2.default.createElement(Icon, checkbox)
          ),
          _react2.default.createElement(HiddenCheckbox, {
            type: 'checkbox',
            id: optionLabel,
            name: translationKey,
            checked: values[translationKey],
            onChange: function onChange(e) {
              return updateChecked(e.target.name);
            },
            ariaRequired: required
          }),
          _react2.default.createElement(
            CheckboxLabelText,
            null,
            optionLabel
          )
        );
      }),
      error && _react2.default.createElement(
        _FieldError2.default,
        null,
        error
      )
    )
  );
};