'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Remove;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Remove(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      {
        width: '1em',
        height: '1em',
        viewBox: '0 0 22 22',
        xmlnsXlink: 'http://www.w3.org/1999/xlink'
      },
      _react2.default.createElement(
        'defs',
        null,
        _react2.default.createElement('circle', { cx: 11, cy: 11, r: 11 })
      ),
      _react2.default.createElement(
        'g',
        { fill: 'none', fillRule: 'evenodd' },
        _react2.default.createElement(
          'g',
          null,
          _react2.default.createElement('use', { fill: colour || '#9E1B32', xlinkHref: '#a' }),
          _react2.default.createElement('circle', { stroke: '#FFF', cx: 11, cy: 11, r: 10.5 })
        ),
        _react2.default.createElement('path', { fill: '#FFF', d: 'M7 13.477L13.478 7l1.298 1.298-6.477 6.477z' }),
        _react2.default.createElement('path', { fill: '#FFF', d: 'M8.298 7l6.477 6.478-1.298 1.298L7 8.299z' })
      )
    )
  );
}

Remove.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};