'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Stopwatch;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Stopwatch(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 25 21' },
      _react2.default.createElement('path', { d: 'M12.49 3.612c4.268.429 7.587 3.707 7.587 7.674-.001 4.15-3.635 7.556-8.224 7.709-4.59.152-8.49-3.003-8.83-7.141-.338-4.14 3.006-7.779 7.57-8.238v-.9H9.17V1h4.742v1.712H12.49v.9zM11.54 18.14c4.173 0 7.588-3.085 7.588-6.856s-3.415-6.856-7.588-6.856c-4.172 0-7.587 3.085-7.587 6.856 0 3.77 3.415 6.856 7.587 6.856zm-4.345-2.884c-1.212-1.06-1.892-2.5-1.888-4-.004-1.499.676-2.938 1.888-3.999 1.213-1.06 2.858-1.654 4.573-1.651v11.302c-1.715.003-3.36-.591-4.573-1.652zM17.73 2.675A10.289 10.289 0 0 1 21 5.64l-1.345 1.22c-.794-1.224-1.92-2.245-3.27-2.965l1.345-1.22z' })
    )
  );
}

Stopwatch.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};