'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex: 1 0 0%;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  '], ['\n    flex: 1 0 0%;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['flex: 1 0 0%'], ['flex: 1 0 0%']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['display: none;'], ['display: none;']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['display: flex;'], ['display: flex;']);

exports.default = Header;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _actions = require('../../actions');

var _router = require('../../actions/router');

var _BrandLogo = require('../../components/BrandLogo');

var _BrandLogo2 = _interopRequireDefault(_BrandLogo);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _CurrencyPopup = require('../../components/CurrencyPopup');

var _CurrencyPopup2 = _interopRequireDefault(_CurrencyPopup);

var _Global = require('../../components/Global');

var _User = require('../../components/JaguarIcons/Global/User');

var _User2 = _interopRequireDefault(_User);

var _HeaderMenu = require('../../components/Lamborghini/HeaderMenu/HeaderMenu');

var _HeaderMenu2 = _interopRequireDefault(_HeaderMenu);

var _LocationSettings = require('../../components/LocationSettings');

var _LocationSettings2 = _interopRequireDefault(_LocationSettings);

var _MenuBar = require('../../components/MenuBar');

var _MenuBar2 = _interopRequireDefault(_MenuBar);

var _UtilityBar = require('../../components/UtilityBar');

var _UtilityBar2 = _interopRequireDefault(_UtilityBar);

var _reducer = require('../../shared/CustomerLogin/reducer');

var _currencyConversion = require('../../shared/currencyConversion');

var _filters = require('../../shared/filters');

var _sessionPreferences = require('../../shared/sessionPreferences');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _BentleyHeader = require('./Bentley/BentleyHeader');

var _BentleyHeader2 = _interopRequireDefault(_BentleyHeader);

var _GenesisHeader = require('./Genesis/GenesisHeader');

var _GenesisHeader2 = _interopRequireDefault(_GenesisHeader);

var _RollsRoyceHeader = require('./RollsRoyce/RollsRoyceHeader');

var _RollsRoyceHeader2 = _interopRequireDefault(_RollsRoyceHeader);

var _SocialShareButton = require('./SocialShareButton');

var _SocialShareButton2 = _interopRequireDefault(_SocialShareButton);

var _TemplateAstonMartin = require('./Templates/TemplateAstonMartin/TemplateAstonMartin');

var _TemplateAstonMartin2 = _interopRequireDefault(_TemplateAstonMartin);

var _KiaBenefitsGermany = require('./Templates/TemplateKia/KiaBenefitsGermany');

var _KiaBenefitsGermany2 = _interopRequireDefault(_KiaBenefitsGermany);

var _TemplateThree = require('./Templates/TemplateThree/TemplateThree');

var _TemplateThree2 = _interopRequireDefault(_TemplateThree);

var _TemplateTwo = require('./Templates/TemplateTwo/TemplateTwo');

var _TemplateTwo2 = _interopRequireDefault(_TemplateTwo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var justifyContent = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

var UtilitySection = _styledComponents2.default.div.withConfig({
  displayName: 'Header__UtilitySection',
  componentId: 'sc-1in4oh6-0'
})(['display:flex;flex-direction:column;align-items:center;', ';', ';', ';'], function (_ref) {
  var height = _ref.height;
  return '' + (height && 'height: ' + height + 'px');
}, function (_ref2) {
  var backgroundColour = _ref2.backgroundColour;
  return '' + (backgroundColour && 'background-color: ' + backgroundColour.value);
}, function (_ref3) {
  var borderBottomHeight = _ref3.borderBottomHeight,
      borderBottomColour = _ref3.borderBottomColour;
  return borderBottomHeight && borderBottomColour && 'border-bottom: ' + borderBottomHeight + 'px solid ' + borderBottomColour.value;
});

var Section = _styledComponents2.default.div.withConfig({
  displayName: 'Header__Section',
  componentId: 'sc-1in4oh6-1'
})(['display:flex;', ';background-color:', ';max-height:', ';justify-content:', ';', ';', ';position:relative;z-index:', ';'], function (_ref4) {
  var contentWidth = _ref4.contentWidth;
  return 'width: ' + (contentWidth ? contentWidth + 'px' : '100%');
}, function (props) {
  return props.backgroundColour;
}, function (_ref5) {
  var height = _ref5.height;
  return height && height + 'px';
}, function (props) {
  return props.align ? justifyContent[props.align] : justifyContent.left;
}, function (_ref6) {
  var borderTop = _ref6.borderTop;
  return '' + (borderTop && 'border-top: ' + borderTop);
}, function (_ref7) {
  var borderBottom = _ref7.borderBottom;
  return '' + (borderBottom && 'border-bottom: ' + borderBottom);
}, function (_ref8) {
  var zIndex = _ref8.zIndex;
  return zIndex || 9;
});

var SectionWithPageContainer = (0, _styledComponents2.default)(Section).withConfig({
  displayName: 'Header__SectionWithPageContainer',
  componentId: 'sc-1in4oh6-2'
})(['justify-content:space-between;align-items:center;flex:1 0 0%;padding-left:8px;', ';box-sizing:border-box;'], function (_ref9) {
  var direction = _ref9.direction;
  return direction === 'rtl' && 'padding-right: 8px;';
});

var LogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Header__LogoContainer',
  componentId: 'sc-1in4oh6-3'
})(['display:flex;background-color:', ';padding:', ';', ';'], function (props) {
  return props.backgroundColour;
}, function (props) {
  return props.padding;
}, function (props) {
  return props.borderColour && 'border-right: 1px solid ' + props.borderColour;
});

var ActionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Header__ActionsContainer',
  componentId: 'sc-1in4oh6-4'
})(['display:flex;flex-direction:column;width:auto;', ' ', ';'], function (_ref10) {
  var calculatedHeight = _ref10.calculatedHeight;
  return calculatedHeight && 'height: ' + calculatedHeight + 'px;';
}, _theme2.default.max.large(_templateObject));

var MenuContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Header__MenuContainer',
  componentId: 'sc-1in4oh6-5'
})(['background-color:', ';display:flex;border-top:', ';border-bottom:', ';'], function (props) {
  return props.backgroundColour;
}, function (_ref11) {
  var borderTop = _ref11.borderTop;
  return borderTop && borderTop;
}, function (_ref12) {
  var borderBottom = _ref12.borderBottom;
  return borderBottom && borderBottom;
});

var Right = _styledComponents2.default.div.withConfig({
  displayName: 'Header__Right',
  componentId: 'sc-1in4oh6-6'
})(['display:flex;justify-content:flex-end;align-items:center;', ';'], _theme2.default.max.small(_templateObject2));

var AccountContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Header__AccountContainer',
  componentId: 'sc-1in4oh6-7'
})(['display:flex;align-items:center;cursor:pointer;']);

var AccountTitle = _styledComponents2.default.span.withConfig({
  displayName: 'Header__AccountTitle',
  componentId: 'sc-1in4oh6-8'
})(['', ';padding-right:20px;letter-spacing:1px;'], function (_ref13) {
  var theme = _ref13.theme;
  return (0, _Global.fontStyleOverride)(theme);
});

var LogoMenuContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Header__LogoMenuContainer',
  componentId: 'sc-1in4oh6-9'
})(['display:flex;margin:0 auto;', ';'], function (_ref14) {
  var contentWidth = _ref14.contentWidth;
  return 'width: ' + (contentWidth ? contentWidth + 'px' : '100%');
});

var SectionWithLogoContainer = (0, _styledComponents2.default)(Section).withConfig({
  displayName: 'Header__SectionWithLogoContainer',
  componentId: 'sc-1in4oh6-10'
})(['flex-direction:', ';z-index:80;'], function (_ref15) {
  var logoOnRight = _ref15.logoOnRight;
  return logoOnRight ? 'row-reverse' : 'row';
});

var ColumnRight = _styledComponents2.default.div.withConfig({
  displayName: 'Header__ColumnRight',
  componentId: 'sc-1in4oh6-11'
})(['width:100%;']);

var colourValue = function colourValue(colour) {
  return colour && colour.value;
};
var fontColourValueOrDefault = function fontColourValueOrDefault(font) {
  return font && colourValue(font.colour) || '#FFF';
};
var textTitle = function textTitle(text) {
  return text && !text.disabled && text.value;
};

var basicTheme = function basicTheme(font) {
  return {
    fontFamily: font.typeface && font.typeface.label,
    fontSize: font.fontSize + 'px',
    characterSpacing: font.kerning + 'px',
    textTransform: font.transform
  };
};

// each option knows which config key tells it to show/hide
// this filters out hidden options
var filterUtilityBarOptions = function filterUtilityBarOptions(_ref16) {
  var utilityBarOptions = _ref16.utilityBarOptions,
      config = (0, _objectWithoutProperties3.default)(_ref16, ['utilityBarOptions']);
  return utilityBarOptions && utilityBarOptions.filter(function (o) {
    return config[o.enabledKey];
  }).map(function (options) {
    return (0, _extends3.default)({}, options, {
      options: [options.includePlaceholderOption && {
        value: '',
        label: config.translations[options.key.toLowerCase() + 'Placeholder']
      }].concat((0, _toConsumableArray3.default)(options.options)).filter(Boolean),
      prefixLabel: config.translations[options.key.toLowerCase() + 'PrefixLabel'],
      placeholder: config.translations[options.key.toLowerCase() + 'Placeholder']
    });
  });
};

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Header__HeaderContainer',
  componentId: 'sc-1in4oh6-12'
})(['position:', ';width:100%;z-index:10;', ' ', ';'], function (_ref17) {
  var stickyHeader = _ref17.stickyHeader;
  return stickyHeader ? 'fixed' : 'static';
}, function (_ref18) {
  var opacity = _ref18.opacity,
      totalHeaderHeight = _ref18.totalHeaderHeight,
      backgroundColour = _ref18.backgroundColour;
  return opacity && '\n    &:after {\n      content: \'\';\n      width: 100%;\n      height: ' + totalHeaderHeight + 'px;\n      background-color: ' + backgroundColour + ';\n      opacity: ' + opacity + ';\n      position: absolute;\n      top: 0;\n      left: 0;\n      z-index: 8;\n    }\n  ';
}, _theme2.default.max.large(_templateObject3));

var HeaderContainerMedium = (0, _styledComponents2.default)(HeaderContainer).withConfig({
  displayName: 'Header__HeaderContainerMedium',
  componentId: 'sc-1in4oh6-13'
})(['display:none;', ';position:', ';width:100%;z-index:10;'], _theme2.default.max.large(_templateObject4), function (_ref19) {
  var stickyHeader = _ref19.stickyHeader;
  return stickyHeader ? 'fixed' : 'static';
});

var SocialShare = function SocialShare(_ref20) {
  var config = _ref20.config,
      socialShare = _ref20.socialShare;
  return config.socialShareTitle && !config.socialShareTitle.disabled ? _react2.default.createElement(_SocialShareButton2.default, { config: config, socialShare: socialShare }) : null;
};

function Header(props) {
  var config = props.config,
      preview = props.preview,
      navigateIfInternal = props.navigateIfInternal,
      pathByModule = props.pathByModule,
      dispatch = props.dispatch,
      shared = props.shared,
      globalStyling = props.globalStyling,
      locale = props.marketInfo.locale,
      socialShare = props.socialShare,
      _props$config$endpoin = props.config.endpoints,
      endpoints = _props$config$endpoin === undefined ? {} : _props$config$endpoin;


  var headerHeroOverlay = (0, _extends3.default)({}, config.headerHeroOverlay);
  var currentLanguage = shared.sessionPreferences.language || locale;
  var currentCurrency = shared.sessionPreferences.currency;
  var currentMarket = shared.sessionPreferences.market;

  var utilityBarActions = {
    language: function language(option) {
      dispatch(_actions.language.actions.changeLanguage(option.value));
      dispatch(_sessionPreferences.actions.updateSessionPreferences('language', option.value));
    },
    currency: function currency(option) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('currency', option.value));
      dispatch(_currencyConversion.actions.getExchangeRates(option.value));
      dispatch(_filters.actions.resetPriceSliderValues(endpoints.filters));
    },
    countries: function countries(option) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('market', option.value));
    },
    territory: function territory(option) {
      return dispatch(_actions.territory.actions.changeTerritory(option));
    }
  };

  var utilityBarInitialValues = {
    language: currentLanguage,
    currency: currentCurrency,
    countries: currentMarket
  };

  var disableCurrencyPopup = function disableCurrencyPopup() {
    return dispatch(_sessionPreferences.actions.updateSessionPreferences('disableCurrencyPopup', true));
  };

  var fnIfNotPreview = function fnIfNotPreview(fn) {
    return preview ? function () {} : fn;
  };
  var onSearch = fnIfNotPreview(function (value) {
    return dispatch(_router.actions.navigate(pathByModule('SiteSearch') + '?query=' + value));
  });

  var pageBackgroundColour = globalStyling.colours.pageBackgroundColour && globalStyling.colours.pageBackgroundColour.value;
  var sectionBackgroundColour = function sectionBackgroundColour(colour) {
    return colour || pageBackgroundColour;
  };

  var globalSearchTextColour = globalStyling.colours.secondaryFontColour && globalStyling.colours.secondaryFontColour.value;
  var searchTextColour = function searchTextColour(colour) {
    return colour || globalSearchTextColour;
  };

  var SearchBarTheme = (0, _extends3.default)({}, basicTheme(config.searchTextFont || {
    typeface: (0, _extends3.default)({}, globalStyling.fonts.secondaryFont),
    kerning: 0,
    fontSize: 14,
    transform: 'uppercase'
  }), {
    colour: searchTextColour(colourValue(config.searchTextFont && config.searchTextFont.colour)),
    backgroundColour: config.opacity ? 'transparent' : sectionBackgroundColour(colourValue(config.searchBGColour)),
    iconColour: searchTextColour(colourValue(config.searchIconColour)),
    showSearchInputBorder: config.showSearchInputBorder
  });

  var noUser = {
    firstName: '',
    lastName: ''
  };

  var _ref21 = preview ? noUser : (0, _reducer.getCustomerDetails)(shared) || noUser,
      firstName = _ref21.firstName,
      lastName = _ref21.lastName;

  var accountTitle = firstName ? { value: firstName + ' ' + lastName, disabled: false } : (0, _extends3.default)({}, config.actionsBarsAccountTitle, {
    value: config.translations.actionsBarsAccountTitle
  });
  var onSignInClick = fnIfNotPreview(function () {
    return dispatch(_router.actions.navigate(pathByModule(firstName ? 'MyAccount' : 'SignIn')));
  });

  var navigateOnClick = fnIfNotPreview(function () {
    return dispatch(_router.actions.navigate(config.brandLogoLink.value));
  });

  var menuBarItems = (config.menuBarItems || { items: [] }).items.map(function (value, idx) {
    return (0, _extends3.default)({}, value, {
      label: config.translations['menuBarItems-' + idx]
    });
  });

  var utilityBarItems = (config.utilityBarItems || { items: [] }).items.map(function (value, idx) {
    return (0, _extends3.default)({}, value, {
      label: config.translations['utilityBarItems-' + idx]
    });
  });

  var filteredOptions = filterUtilityBarOptions(config);
  var actionsBarHeightCalc = function actionsBarHeightCalc(configuration) {
    var height = configuration.menuBarHeight;
    // Add 1 pixel when border exists
    if (config.menuBarBorderColour) height += 1;
    if (configuration.showActionsBar) height += configuration.actionsBarHeight;
    return height;
  };
  var totalHeaderHeight = actionsBarHeightCalc(config) + config.utilityBarHeight;

  var onChangeLanguage = function onChangeLanguage(languageCode) {
    dispatch(_sessionPreferences.actions.updateSessionPreferences('language', languageCode));
    dispatch(_actions.language.actions.changeLanguage(languageCode));
  };

  switch (config.headerTemplate) {
    case 'KiaBenefitsGermany':
      return _react2.default.createElement(_KiaBenefitsGermany2.default, null);
    case 'TemplateTwo':
      return _react2.default.createElement(_TemplateTwo2.default, (0, _extends3.default)({}, props, {
        links: menuBarItems,
        navigate: function navigate(path) {
          return dispatch(_router.actions.navigate(path));
        },
        currentLanguage: currentLanguage,
        currentCurrency: currentCurrency,
        utilityBarActions: (0, _extends3.default)({}, utilityBarActions, {
          disableCurrencyPopup: disableCurrencyPopup
        }),
        globalStyling: globalStyling
      }));
    case 'Lamborghini':
      return _react2.default.createElement(_HeaderMenu2.default, (0, _extends3.default)({}, props, {
        links: menuBarItems,
        navigate: function navigate(path) {
          return dispatch(_router.actions.navigate(path));
        },
        currentLanguage: currentLanguage,
        currentCurrency: currentCurrency,
        utilityBarActions: (0, _extends3.default)({}, utilityBarActions, {
          disableCurrencyPopup: disableCurrencyPopup
        }),
        globalStyling: globalStyling
      }));
    case 'TemplateThree':
      return _react2.default.createElement(_TemplateThree2.default, (0, _extends3.default)({}, props, {
        globalStyling: globalStyling,
        links: menuBarItems,
        utilityBarInitialValues: utilityBarInitialValues,
        filteredOptions: filteredOptions,
        navigateIfInternal: navigateIfInternal,
        navigateOnClick: navigateOnClick,
        utilityBarActions: utilityBarActions,
        socialShare: socialShare,
        navigate: function navigate(path) {
          return dispatch(_router.actions.navigate(path));
        },
        currentLanguage: currentLanguage,
        currentCurrency: currentCurrency,
        changeLanguage: onChangeLanguage,
        disableCurrencyPopup: disableCurrencyPopup,
        shared: shared,
        headerHeroOverlay: headerHeroOverlay
      }));
    case 'TemplateAstonMartin':
      return _react2.default.createElement(_TemplateAstonMartin2.default, (0, _extends3.default)({}, props, {
        links: menuBarItems,
        navigate: function navigate(path) {
          return dispatch(_router.actions.navigate(path));
        },
        currentLanguage: currentLanguage,
        currentCurrency: currentCurrency,
        utilityBarActions: (0, _extends3.default)({}, utilityBarActions, {
          disableCurrencyPopup: disableCurrencyPopup
        }),
        globalStyling: globalStyling
      }));
    case 'RollsRoyce':
      return _react2.default.createElement(_RollsRoyceHeader2.default, (0, _extends3.default)({}, props, {
        links: menuBarItems,
        navigate: function navigate(path) {
          return dispatch(_router.actions.navigate(path));
        },
        currentLanguage: currentLanguage,
        currentCurrency: currentCurrency,
        utilityBarActions: (0, _extends3.default)({}, utilityBarActions, {
          disableCurrencyPopup: disableCurrencyPopup
        })
      }));
    case 'Bentley':
      return _react2.default.createElement(_BentleyHeader2.default, (0, _extends3.default)({}, props, {
        links: menuBarItems,
        navigate: function navigate(path) {
          return dispatch(_router.actions.navigate(path));
        }
      }));
    case 'Genesis':
      return _react2.default.createElement(_GenesisHeader2.default, (0, _extends3.default)({}, props, {
        links: menuBarItems,
        navigate: function navigate(path) {
          return dispatch(_router.actions.navigate(path));
        }
      }));
    default:
      return _react2.default.createElement(
        'div',
        {
          'data-nosnippet': true,
          style: config.menuBarBGColour && {
            backgroundColor: config.menuBarBGColour.value
          }
        },
        _react2.default.createElement(_CurrencyPopup2.default, {
          translations: config.translations,
          globalStyling: globalStyling,
          disableCurrencyPopup: disableCurrencyPopup,
          shared: shared
        }),
        _react2.default.createElement(
          HeaderContainer,
          {
            stickyHeader: config.stickyHeader,
            opacity: config.opacity,
            totalHeaderHeight: totalHeaderHeight,
            backgroundColour: sectionBackgroundColour(colourValue(config.utilityBarBGColour)),
            globalStyling: globalStyling
          },
          _react2.default.createElement(
            UtilitySection,
            {
              height: config.utilityBarHeight,
              backgroundColour: config.opacity < 1 ? 'transparent' : config.utilityBarBGColour,
              borderBottomHeight: config.utilityBarBorderBottomHeight,
              borderBottomColour: config.utilityBarBorderBottomColour,
              globalStyling: globalStyling
            },
            _react2.default.createElement(
              _ContentWrapper2.default,
              { contentWidth: globalStyling.contentWidth },
              _react2.default.createElement(
                Section,
                {
                  backgroundColour: config.opacity ? '' : sectionBackgroundColour(colourValue(config.utilityBarBGColour)),
                  align: config.utilityBarAlign,
                  zIndex: config.opacity && 85 || 84
                },
                _react2.default.createElement(_UtilityBar2.default, {
                  config: config,
                  theme: {
                    link: {
                      font: config.utilityBarLinkFont,
                      hoverColour: config.utilityBarDropdownHoverColour
                    },
                    dropdown: {
                      font: config.utilityBarDropdownFont,
                      hoverColour: config.utilityBarLinkHoverColour,
                      backgroundColour: config.utilityBarDropdownColour,
                      dropdownIconSize: config.languageDropdownIconSize,
                      iconColour: config.dropdownIconColour
                    }
                  },
                  items: utilityBarItems,
                  utilityBarOptions: filteredOptions,
                  preview: preview,
                  dropDownActions: utilityBarActions,
                  initialValues: utilityBarInitialValues,
                  socialShare: socialShare,
                  direction: globalStyling.direction
                })
              )
            )
          ),
          _react2.default.createElement(
            _ContentWrapper2.default,
            {
              contentWidth: !config.menuWrapperFull && globalStyling.contentWidth
            },
            _react2.default.createElement(
              SectionWithLogoContainer,
              {
                backgroundColour: config.opacity ? '' : sectionBackgroundColour(colourValue(config.brandLogoBGColour)),
                logoOnRight: config.logoAlign === 'right',
                borderBottom: config.menuBarBorderBottomHeight + 'px solid ' + colourValue(config.menuBarBorderBottomColour)
              },
              _react2.default.createElement(
                LogoMenuContainer,
                { contentWidth: globalStyling.contentWidth },
                _react2.default.createElement(
                  LogoContainer,
                  {
                    borderColour: colourValue(config.brandLogoBorderColour),
                    backgroundColour: config.opacity ? '' : sectionBackgroundColour(colourValue(config.brandLogoBGColour)),
                    padding: '0 ' + config.brandLogoHorizontalPadding + 'px'
                  },
                  _react2.default.createElement(_BrandLogo2.default, {
                    logoUrl: config.brandLogoUrl,
                    fallbackUrl: config.fallbackUrl,
                    brandLogoVisibleDesktop: config.brandLogoVisibleDesktop,
                    preview: preview,
                    navigateOnClick: navigateOnClick
                  })
                ),
                _react2.default.createElement(
                  ColumnRight,
                  null,
                  _react2.default.createElement(
                    ActionsContainer,
                    {
                      calculatedHeight: actionsBarHeightCalc(config)
                    },
                    config.showActionsBar && _react2.default.createElement(
                      SectionWithPageContainer,
                      {
                        height: config.actionsBarHeight,
                        backgroundColour: config.opacity ? '' : sectionBackgroundColour(colourValue(config.actionsBarBGColour)),
                        borderBottom: config.actionsBarBorderBottomHeight + 'px solid ' + colourValue(config.actionsBarBorderBottomColour),
                        direction: globalStyling.direction
                      },
                      _react2.default.createElement(
                        _Global.HeadingOne,
                        {
                          styleOverride: function styleOverride() {
                            return '\n                              display: flex;\n                              ' + (0, _Global.fontStyleOverride)(config.actionsBarPageFont) + '\n                            ';
                          },
                          mobileStyleOverride: function mobileStyleOverride() {
                            return [{
                              queryPath: 'max.small',
                              template: 'display:none;'
                            }, {
                              queryPath: 'max.medium',
                              template: 'justify-content: center;'
                            }];
                          }
                        },
                        textTitle((0, _extends3.default)({}, config.actionsBarPageTitle, {
                          value: config.translations.actionsBarPageTitle
                        }))
                      ),
                      _react2.default.createElement(
                        Right,
                        null,
                        _react2.default.createElement(SocialShare, (0, _extends3.default)({
                          config: props.config,
                          socialShare: props.socialShare
                        }, props)),
                        !config.actionsBarsAccountTitle.disabled && _react2.default.createElement(
                          AccountContainer,
                          { onClick: onSignInClick },
                          _react2.default.createElement(_User2.default, {
                            width: config.accountIconWidth + 'px',
                            height: config.accountIconHeight + 'px',
                            colour: fontColourValueOrDefault(config.actionsBarAccountFont)
                          }),
                          _react2.default.createElement(
                            AccountTitle,
                            {
                              theme: config.actionsBarAccountFont
                            },
                            textTitle(accountTitle)
                          )
                        )
                      )
                    ),
                    _react2.default.createElement(
                      MenuContainer,
                      {
                        backgroundColour: config.opacity ? '' : sectionBackgroundColour(colourValue(config.actionsBarBGColour))
                      },
                      _react2.default.createElement(_MenuBar2.default, {
                        height: config.menuBarHeight,
                        theme: {
                          font: config.menuBarLinkFont,
                          hoverColour: config.menuBarLinkHoverColour,
                          underLineColor: config.menuBarUnderlineColor,
                          backgroundHoverColour: config.menuBarLinkBgHoverColour
                        },
                        items: menuBarItems,
                        preview: preview,
                        backgroundColour: config.opacity ? '' : colourValue(config.menuBarBGColour),
                        borderColour: colourValue(config.menuBarBorderColour),
                        navigateIfInternal: navigateIfInternal,
                        withSearch: config.showSearchBar,
                        onSearch: onSearch,
                        placeholder: config.translations.searchBarPlaceholder,
                        searchBarTheme: SearchBarTheme,
                        searchBarAlign: config.searchBarAlign,
                        searchIconAlign: config.searchIconAlign,
                        mobileMenuIconsColour: colourValue(config.mobileMenuIconsColour)
                      })
                    )
                  )
                )
              )
            )
          )
        ),
        _react2.default.createElement(
          HeaderContainerMedium,
          { stickyHeader: config.stickyHeader },
          _react2.default.createElement(
            SectionWithLogoContainer,
            {
              backgroundColour: sectionBackgroundColour(colourValue(config.menuBarBGColour)),
              logoOnRight: config.logoAlign === 'right',
              borderBottom: config.menuBarBorderBottomHeight + 'px solid ' + colourValue(config.menuBarBorderBottomColour)
            },
            _react2.default.createElement(
              LogoContainer,
              {
                borderColour: colourValue(config.brandLogoMobileBorderColour),
                backgroundColour: sectionBackgroundColour(colourValue(config.brandLogoBGColour))
              },
              _react2.default.createElement(_BrandLogo2.default, {
                pageLink: config.brandLogoLink.value,
                logoUrl: config.brandLogoUrl,
                brandLogoVisibleDesktop: config.brandLogoVisibleDesktop,
                preview: preview,
                navigateOnClick: navigateOnClick
              })
            ),
            _react2.default.createElement(
              ActionsContainer,
              null,
              _react2.default.createElement(
                _Global.HeadingOne,
                {
                  styleOverride: function styleOverride() {
                    return '\n                    display: flex;\n                    ' + (0, _Global.fontStyleOverride)(config.actionsBarPageFont) + '\n                  ';
                  },
                  mobileStyleOverride: function mobileStyleOverride() {
                    return [{ queryPath: 'max.small', template: 'display:none;' }, {
                      queryPath: 'max.medium',
                      template: 'justify-content: center;'
                    }];
                  }
                },
                textTitle((0, _extends3.default)({}, config.actionsBarPageTitle, {
                  value: config.translations.actionsBarPageTitle
                }))
              ),
              _react2.default.createElement(
                Right,
                null,
                _react2.default.createElement(SocialShare, (0, _extends3.default)({
                  config: props.config,
                  socialShare: props.socialShare
                }, props)),
                filteredOptions && filteredOptions.length > 0 && _react2.default.createElement(_LocationSettings2.default, {
                  title: config.translations.locationSettingsTitle,
                  confirm: config.translations.confirm,
                  iconColour: fontColourValueOrDefault(config.actionsBarAccountFont),
                  locationOptions: filteredOptions,
                  actions: utilityBarActions,
                  initialValues: utilityBarInitialValues,
                  globalStyling: globalStyling
                }),
                !config.actionsBarsAccountTitle.disabled && _react2.default.createElement(
                  'div',
                  { role: 'button', tabIndex: 0, onClick: onSignInClick },
                  _react2.default.createElement(_User2.default, {
                    width: config.mobileAccountIconWidth + 'px',
                    height: config.mobileAccountIconHeight + 'px',
                    colour: fontColourValueOrDefault(config.actionsBarAccountFont)
                  })
                ),
                _react2.default.createElement(_MenuBar2.default, {
                  theme: {
                    font: config.menuBarLinkFont,
                    hoverColour: config.menuBarLinkHoverColour,
                    backgroundHoverColour: config.menuBarLinkBgHoverColour,
                    underLineColor: config.menuBarUnderlineColor
                  },
                  items: menuBarItems,
                  preview: preview,
                  backgroundColour: sectionBackgroundColour(colourValue(config.menuBarBGColour)),
                  borderColour: colourValue(config.menuBarMobileBorderColour),
                  navigateIfInternal: navigateIfInternal,
                  withSearch: config.showSearchBar,
                  onSearch: onSearch,
                  searchBarTheme: SearchBarTheme,
                  searchBarAlign: config.searchBarAlign,
                  searchIconAlign: config.searchIconAlign,
                  mobileMenuIconsColour: colourValue(config.mobileMenuIconsColour),
                  burgerMenuItemBorder: config.burgerMenuItemBorder,
                  socialShareEnabled: config.socialShareEnabled,
                  utilityBarLanguageEnabled: config.utilityBarLanguageEnabled && !config.mobileLanguageInHeader,
                  utilityBarCurrencyEnabled: config.utilityBarCurrencyEnabled && !config.mobileLanguageInHeader,
                  socialIcon: config.socialIcon,
                  languageIcon: config.languageIcon,
                  currencyIcon: config.currencyIcon,
                  translations: config.translations,
                  socialShareProps: socialShare,
                  utilityBarOptions: filteredOptions,
                  languageActions: utilityBarActions.language,
                  burgerMenuLanguageFont: config.burgerMenuLanguageFont,
                  languageChevron: config.burgerMenuLanguageChevron,
                  hideChevronOnMobile: config.hideChevronOnMobile,
                  currentLanguage: currentLanguage,
                  currentCurrency: currentCurrency
                })
              )
            )
          )
        )
      );
  }
}