'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _pods = require('../pods');

var _pods2 = _interopRequireDefault(_pods);

var _bands = require('../bands');

var bands = _interopRequireWildcard(_bands);

var _Body = require('./Body');

var _Body2 = _interopRequireDefault(_Body);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ComponentContext = (0, _react.createContext)({ pods: _pods2.default, bands: bands, Body: _Body2.default });

exports.default = ComponentContext;