'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _components = require('../components');

var _WindowDimensionsProvider = require('../../shared/WindowDimensionsProvider');

var _theme = require('../../theme');

var _useTimeout = require('./useTimeout');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Slider = _styledComponents2.default.div.withConfig({
  displayName: 'HeroCarousel__Slider',
  componentId: 'sc-12hl9ty-0'
})(['position:relative;height:', 'px;overflow:hidden;'], function (_ref) {
  var height = _ref.height;
  return height;
});

var Slide = _styledComponents2.default.div.withConfig({
  displayName: 'HeroCarousel__Slide',
  componentId: 'sc-12hl9ty-1'
})(['position:absolute;top:0;left:0;width:100%;height:100%;display:flex;justify-content:center;align-items:center;opacity:0;transition:2.5s;', ''], function (_ref2) {
  var active = _ref2.active;
  return active && 'opacity: 1;\n    transition-duration: 2.5s;\n  ';
});

var Controls = _styledComponents2.default.div.withConfig({
  displayName: 'HeroCarousel__Controls',
  componentId: 'sc-12hl9ty-2'
})(['min-width:200px;height:75px;position:absolute;left:0;right:0;bottom:15px;margin-left:auto;margin-right:auto;display:flex;justify-content:center;align-items:center;z-index:699;']);

var ControlBtn = _styledComponents2.default.div.withConfig({
  displayName: 'HeroCarousel__ControlBtn',
  componentId: 'sc-12hl9ty-3'
})(['height:20px;width:20px;margin:0 2px;background-color:#bbbbbb;border-radius:50%;display:inline-block;opacity:0.4;cursor:pointer;', ''], function (_ref3) {
  var active = _ref3.active;
  return active && 'opacity: 0.8;\n    transition-duration: 2.5s;\n  ';
});

var SliderWrapper = function SliderWrapper(_ref4) {
  var config = _ref4.config,
      uiElements = _ref4.globalStyling.uiElements,
      dispatch = _ref4.dispatch;
  var items = config.items,
      transitionTime = config.transitionTime,
      height = config.height,
      overlayConfig = (0, _objectWithoutProperties3.default)(config, ['items', 'transitionTime', 'height']);

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      currentIndex = _useState2[0],
      setCurrentIndex = _useState2[1];

  var _items$currentIndex$t = items[currentIndex].translations,
      headlineText = _items$currentIndex$t.headlineText,
      bodyText = _items$currentIndex$t.bodyText,
      buttonText = _items$currentIndex$t.button;
  var _items$currentIndex = items[currentIndex],
      button = _items$currentIndex.button,
      horizontalAlignment = _items$currentIndex.horizontalAlignment,
      verticalAlignment = _items$currentIndex.verticalAlignment;
  var length = items.length;


  var currentIndexRef = (0, _react.useRef)(currentIndex);

  (0, _react.useEffect)(function () {
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);

  var goToNext = function goToNext() {
    var nextIndex = currentIndexRef.current === length - 1 ? 0 : currentIndexRef.current + 1;

    setCurrentIndex(nextIndex);
  };

  var type = items[currentIndex].media.componentType;


  (0, _useTimeout.useTimeout)(goToNext, type === 'image' ? transitionTime * 1000 : null);

  var _useWindowDimensions = (0, _WindowDimensionsProvider.useWindowDimensions)(),
      width = _useWindowDimensions.width;

  return _react2.default.createElement(
    Slider,
    { height: height },
    items.map(function (_ref5, i) {
      var _ref5$media = _ref5.media,
          componentType = _ref5$media.componentType,
          src = _ref5$media.src,
          autoplay = _ref5$media.autoplay,
          muted = _ref5$media.muted,
          id = _ref5.id;

      var isCurrent = i === currentIndex;
      var Component = {
        image: _components.Image,
        video: _components.Video
      }[componentType];
      return _react2.default.createElement(
        Slide,
        { active: isCurrent, key: id, 'aria-hidden': !isCurrent },
        _react2.default.createElement(Component, {
          src: src,
          index: i,
          isCurrent: isCurrent,
          imageHeight: height,
          handleComplete: goToNext,
          autoplay: autoplay && width > _theme.screenSizeOptions.medium,
          muted: muted
        })
      );
    }),
    _react2.default.createElement(
      Controls,
      null,
      items.map(function (slide, i) {
        return _react2.default.createElement(ControlBtn, {
          key: slide.id,
          active: currentIndex === i,
          onClick: function onClick() {
            setCurrentIndex(i);
          }
        });
      })
    ),
    _react2.default.createElement(_components.Overlay, (0, _extends3.default)({}, overlayConfig, {
      headlineText: headlineText,
      bodyText: bodyText,
      buttonText: buttonText,
      button: button,
      uiElements: uiElements,
      dispatch: dispatch,
      horizontalAlignment: horizontalAlignment,
      verticalAlignment: verticalAlignment
    }))
  );
};

exports.default = function (props) {
  return _react2.default.createElement(
    _components.Wrapper,
    null,
    _react2.default.createElement(SliderWrapper, props)
  );
};