'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = View360;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function View360(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 23 19' },
      _react2.default.createElement('path', { d: 'M7.745 12.334c-.735 0-1-.148-1-.563h-.997c0 1.189.923 1.456 1.995 1.456 1.284 0 2.025-.363 2.025-1.846 0-.556-.055-1.041-.583-1.267.504-.218.528-.66.528-1.18 0-1.285-.64-1.623-1.97-1.623-1.072 0-1.995.27-1.995 1.458h.994c0-.416.266-.573 1-.573.835 0 .976.166.976.763 0 .669-.228.764-.976.764h-.824v.885h.827c.935 0 1.03.088 1.03.859 0 .712-.29.867-1.03.867m5.183-4.137c.607 0 .85.146.85.572h.977c0-1.127-.864-1.458-1.842-1.458-1.072 0-1.79.365-1.965 1.285-.088.632-.124 1.27-.108 1.909a5.068 5.068 0 0 0 .19 1.682c.275.71.986 1.04 1.858 1.04 1.347 0 1.98-.65 1.98-1.942 0-1.483-.583-1.961-1.942-1.961a1.8 1.8 0 0 0-1.077.304c0-.077 0-.191.009-.268.046-.756.1-1.163 1.068-1.163m.041 2.012c.695 0 .907.174.907 1.085 0 .824-.245 1.04-.994 1.04-.723 0-1.014-.26-1.014-1.084 0-.728.337-1.041 1.1-1.041m4.901-2.898c-2.128 0-2.033 1.45-2.033 2.959 0 1.476-.116 2.957 2.033 2.957 2.09 0 2.028-1.36 2.028-2.957 0-1.51.1-2.959-2.028-2.959m.931 4.53c-.096.294-.283.493-.931.493-.648 0-.836-.2-.936-.494a12.963 12.963 0 0 1 0-3.141c.092-.294.283-.496.936-.496s.835.2.931.496a12.963 12.963 0 0 1 0 3.141m2.419-6.2a.771.771 0 1 0 .002-.002m-5.316 10.077a7.893 7.893 0 0 1-11.056 0 7.647 7.647 0 0 1 0-10.905 7.885 7.885 0 0 1 9.635-1.1h-.69a.772.772 0 1 0 0 1.543h2.734a.776.776 0 0 0 .782-.771v-2.7a.782.782 0 0 0-1.563 0v.903A9.46 9.46 0 0 0 3.743 3.72a9.173 9.173 0 0 0 0 13.085 9.472 9.472 0 0 0 13.267 0 .767.767 0 0 0 0-1.09.789.789 0 0 0-1.106 0' })
    )
  );
}

View360.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};