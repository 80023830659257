'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 33.3%;\n    display: table-cell;\n  '], ['\n    width: 33.3%;\n    display: table-cell;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    min-height: 200px;\n  '], ['\n    min-height: 200px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    min-height: 300px;\n  '], ['\n    min-height: 300px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    min-height: 250px;\n  '], ['\n    min-height: 250px;\n  ']);

exports.default = AccessoryBox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Box = _styledComponents2.default.div.withConfig({
  displayName: 'AccessoryBox'
}).withConfig({
  displayName: 'AccessoryBox__Box',
  componentId: 'sc-1rndbv5-0'
})(['width:100%;margin:auto;background-color:#252525;display:block;color:#fff;', ';'], _theme2.default.min.large(_templateObject));
var Image = _styledComponents2.default.div.withConfig({
  displayName: 'AccessoryBox__Image',
  componentId: 'sc-1rndbv5-1'
})(['height:180px;background-size:cover;background-repeat:no-repeat;background-position:center;background-image:', ';width:100%;', ';', ';', ';'], function (props) {
  return '' + (props.url && 'url(' + props.url + ')');
}, _theme2.default.min.small(_templateObject2), _theme2.default.min.medium(_templateObject3), _theme2.default.min.large(_templateObject4));
var Banner = _styledComponents2.default.div.withConfig({
  displayName: 'AccessoryBox__Banner',
  componentId: 'sc-1rndbv5-2'
})(['min-height:80px;padding:30px;']);

function AccessoryBox(_ref) {
  var accessory = _ref.accessory,
      translations = _ref.translations;

  return _react2.default.createElement(
    Box,
    null,
    _react2.default.createElement(Image, { url: accessory.imageUrl }),
    _react2.default.createElement(
      Banner,
      null,
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n            font-size: 20px;\n            margin-bottom: 10px;\n            text-transform: uppercase;\n            color: #ffffff;\n          ';
          }
        },
        translations.title
      ),
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n            font-size: 15px;\n            color: #ffffff;\n          ';
          }
        },
        translations.description
      )
    )
  );
}