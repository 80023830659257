'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.newArray = undefined;

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _without = require('ramda/src/without');

var _without2 = _interopRequireDefault(_without);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var newArray = exports.newArray = function newArray() {
  var currentArr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var value = arguments[1];

  if (currentArr.some(function (i) {
    return i === value;
  })) {
    return (0, _without2.default)([value], currentArr);
  }
  return [].concat((0, _toConsumableArray3.default)(currentArr), [value]);
};
/* eslint-disable import/prefer-default-export */