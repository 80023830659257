'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 20px 60px;\n  '], ['\n    margin: 20px 60px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 20px 32px;\n    display: flex;\n    flex-direction: column;\n  '], ['\n    margin: 20px 32px;\n    display: flex;\n    flex-direction: column;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    justify-content: space-between;\n  '], ['\n    display: flex;\n    justify-content: space-between;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n  '], ['\n    display: flex;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n    padding: 0 5px 0 0;\n  '], ['\n    width: 50%;\n    padding: 0 5px 0 0;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 0px;\n  '], ['\n    margin-bottom: 0px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: center;\n  '], ['\n    justify-content: center;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: space-between;\n  '], ['\n    justify-content: space-between;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 14px;\n  padding-right: 0px;\n'], ['\n  font-size: 14px;\n  padding-right: 0px;\n']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 14px;\n'], ['\n  font-size: 14px;\n']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n  padding-right: 150px'], ['\n  padding-right: 150px']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 16px;\n'], ['\n  font-size: 16px;\n']),
    _templateObject13 = (0, _taggedTemplateLiteral3.default)(['\n    padding-right: 100px'], ['\n    padding-right: 100px']),
    _templateObject14 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 14px;\n  margin-bottom: 5px;\n  margin-right: 10px;\n  padding-right: 10px;\n  '], ['\n  font-size: 14px;\n  margin-bottom: 5px;\n  margin-right: 10px;\n  padding-right: 10px;\n  ']);

exports.default = Footer;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _SocialMediaLinks = require('./SocialMediaLinks');

var _SocialMediaLinks2 = _interopRequireDefault(_SocialMediaLinks);

var _footerTypes = require('../../../types/Bentley/footerTypes');

var _LinkList = require('../../../components/Bentley/LinkList');

var _LinkList2 = _interopRequireDefault(_LinkList);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _BentleyLink = require('../BentleyLink');

var _BentleyLink2 = _interopRequireDefault(_BentleyLink);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterWrapper',
  componentId: 'sc-166ho3g-0'
})(['display:flex;justify-content:center;background:#ffffff;']);

var FooterContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterContainer',
  componentId: 'sc-166ho3g-1'
})(['display:flex;width:100%;max-width:1367px;flex-direction:column;margin:20px 28px;', ';', ';'], _theme2.default.min.medium(_templateObject), _theme2.default.max.small(_templateObject2));

var ModelLinksTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__ModelLinksTitle',
  componentId: 'sc-166ho3g-2'
})(['display:flex;font-size:16px;font-family:Bentley-SemiBold;color:#727376;margin-bottom:20px;text-transform:uppercase;']);

var ModelLinksWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__ModelLinksWrapper',
  componentId: 'sc-166ho3g-3'
})(['display:flex;color:#335b4c;font:16px Bentley-light;margin-top:20px;', ';', ';'], _theme2.default.max.small(_templateObject3), _theme2.default.max.large(_templateObject4));

var ModelLinksContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__ModelLinksContainer',
  componentId: 'sc-166ho3g-4'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.max.large(_templateObject5));

var SocialsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__SocialsContainer',
  componentId: 'sc-166ho3g-5'
})(['display:flex;margin:20px 0px;', ';'], _theme2.default.max.small(_templateObject6));

var BottomSectionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__BottomSectionContainer',
  componentId: 'sc-166ho3g-6'
})(['display:flex;flex-direction:column;justify-content:flex-start;border-top:1px solid #d8d9dc;padding-top:20px;flex-wrap:wrap;line-height:16px;margin:20px 0 20px;', ';'], _theme2.default.max.large(_templateObject7));
var NavContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__NavContainer',
  componentId: 'sc-166ho3g-7'
})(['display:flex;', ';'], _theme2.default.max.small(_templateObject8));
var NavItem = _styledComponents2.default.span.withConfig({
  displayName: 'Footer__NavItem',
  componentId: 'sc-166ho3g-8'
})(['text-decoration:none;font:16px Bentley-light;color:#335b4c;line-height:20px;letter-spacing:1.2px;padding-right:30px;', ';'], _theme2.default.max.small(_templateObject9));

var AddressText = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__AddressText',
  componentId: 'sc-166ho3g-9'
})(['font:16px Bentley-light;line-height:18px;color:#4c4d4f;margin-top:20px;', ';', ';'], _theme2.default.max.small(_templateObject10), _theme2.default.min.medium(_templateObject11));

var CopyrightText = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__CopyrightText',
  componentId: 'sc-166ho3g-10'
})(['font:16px Bentley-light;line-height:18px;color:#4c4d4f;margin-top:20px;', ';', ';'], _theme2.default.max.small(_templateObject12), _theme2.default.min.medium(_templateObject11));

var BottomLinksContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__BottomLinksContainer',
  componentId: 'sc-166ho3g-11'
})(['display:flex;justify-content:start;margin:20px 0px 0px;flex-wrap:wrap;', ';'], _theme2.default.min.medium(_templateObject13));
var BottomLink = _styledComponents2.default.a.withConfig({
  displayName: 'Footer__BottomLink',
  componentId: 'sc-166ho3g-12'
})(['font:16px Bentley-light;line-height:18px;color:#333333;border-right:2px solid #4c4d4f;padding-right:15px;margin-right:15px;margin-bottom:15px;height:18px;:last-child{border-right:none;}:hover{text-decoration:none;}', ''], _theme2.default.max.small(_templateObject14));

var TermsandConditionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__TermsandConditionsContainer',
  componentId: 'sc-166ho3g-13'
})(['font-family:Bentley-light;color:#757575;max-width:900px;font-size:14px;']);

var TermsandConditionsTitle = _styledComponents2.default.h3.withConfig({
  displayName: 'Footer__TermsandConditionsTitle',
  componentId: 'sc-166ho3g-14'
})(['text-transform:uppercase;font-size:14px;font-family:Bentley-light;font-weight:normal;']);

var TermsandConditionsItem = _styledComponents2.default.p.withConfig({
  displayName: 'Footer__TermsandConditionsItem',
  componentId: 'sc-166ho3g-15'
})(['']);

var EmissionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__EmissionsContainer',
  componentId: 'sc-166ho3g-16'
})(['font-family:Bentley-light;color:#757575;font-size:14px;white-space:pre-wrap;']);

function Footer(_ref) {
  var config = _ref.config;

  var handleCookies = function handleCookies(e) {
    e.preventDefault();
    global.UC_UI.showSecondLayer();
  };

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl;

  var getCurrentYear = new Date().getFullYear();

  return _react2.default.createElement(
    FooterWrapper,
    null,
    _react2.default.createElement(
      FooterContainer,
      null,
      config.modelLinks && config.previousModelLinks && _react2.default.createElement(
        ModelLinksWrapper,
        null,
        _react2.default.createElement(
          ModelLinksContainer,
          null,
          _react2.default.createElement(
            ModelLinksTitle,
            null,
            config.translations.modelLinksTitle
          ),
          _react2.default.createElement(_LinkList2.default, {
            items: config.modelLinks.map(function (item) {
              return item;
            }),
            translations: config.translations
          })
        ),
        _react2.default.createElement(
          ModelLinksContainer,
          null,
          _react2.default.createElement(
            ModelLinksTitle,
            null,
            config.translations.previousModelLinksTitle
          ),
          _react2.default.createElement(_LinkList2.default, {
            items: config.previousModelLinks.map(function (item) {
              return item;
            }),
            translations: config.translations
          })
        )
      ),
      _react2.default.createElement(
        SocialsContainer,
        null,
        _react2.default.createElement(_SocialMediaLinks2.default, { socialMediaIconsList: config.socialMediaLinks })
      ),
      _react2.default.createElement(
        TermsandConditionsContainer,
        null,
        _react2.default.createElement(
          TermsandConditionsTitle,
          null,
          config.translations.termsConditionsTitle
        ),
        _react2.default.createElement(
          TermsandConditionsItem,
          null,
          config.translations.termsConditionsContent1
        ),
        _react2.default.createElement(
          TermsandConditionsItem,
          null,
          config.translations.termsConditionsContent2
        ),
        _react2.default.createElement(
          TermsandConditionsItem,
          null,
          config.translations.termsConditionsContent3
        )
      ),
      config.translations.footerModelEmissions && _react2.default.createElement(
        EmissionsContainer,
        null,
        _react2.default.createElement(
          _reactMarkdown2.default,
          null,
          config.translations.footerModelEmissions
        )
      ),
      _react2.default.createElement(
        BottomSectionContainer,
        null,
        _react2.default.createElement(
          NavContainer,
          null,
          config.navItems.map(function (_ref2) {
            var link = _ref2.link,
                label = _ref2.label,
                navId = _ref2.navId;
            return _react2.default.createElement(
              _BentleyLink2.default,
              { key: navId, link: link },
              _react2.default.createElement(
                NavItem,
                null,
                config.translations['' + label]
              )
            );
          })
        ),
        _react2.default.createElement(
          CopyrightText,
          null,
          config.translations.copyrightSiteName + ' ' + getCurrentYear
        ),
        _react2.default.createElement(
          AddressText,
          null,
          config.translations.registeredOffice
        ),
        _react2.default.createElement(
          BottomLinksContainer,
          null,
          config.bottomFooterBarItems.map(function (_ref3) {
            var link = _ref3.link,
                label = _ref3.label,
                navId = _ref3.navId,
                cookieControl = _ref3.cookieControl;
            return _react2.default.createElement(
              BottomLink,
              {
                href: link,
                key: navId,
                target: getTargetUrl(link),
                onClick: cookieControl ? handleCookies : null
              },
              config.translations['' + label]
            );
          })
        )
      )
    )
  );
}