'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CarouselUnselected;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ = require('..');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function CarouselUnselected(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _.IconWrapper,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 12 12', width: '1em', height: '1em' },
      _react2.default.createElement('circle', {
        cx: 6,
        cy: 6,
        r: 5,
        fillRule: 'nonzero',
        stroke: colour,
        strokeWidth: 2,
        fill: 'none'
      })
    )
  );
}

CarouselUnselected.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};