'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dealerPodStatus = exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  getAllDealers: 'GET_ALL_DEALERS',
  getAllDealersSuccess: 'GET_ALL_DEALERS_SUCCESS',
  getAllDealersFailure: 'GET_ALL_DEALERS_FAILURE',
  getClosestDealers: 'GET_CLOSEST_DEALERS',
  getClosestDealersSuccess: 'GET_CLOSEST_DEALERS_SUCCESS',
  getClosestDealersFailure: 'GET_CLOSEST_DEALERS_FAILURE'
};

var actions = exports.actions = {
  getAllDealers: function getAllDealers() {
    return { type: constants.getAllDealers };
  },
  getAllDealersSuccess: function getAllDealersSuccess(payload) {
    return {
      type: constants.getAllDealersSuccess,
      payload: payload
    };
  },
  getAllDealersFailure: function getAllDealersFailure(payload) {
    return {
      type: constants.getAllDealersFailure,
      payload: payload
    };
  },
  getClosestDealers: function getClosestDealers(payload) {
    return {
      type: constants.getClosestDealers,
      payload: payload
    };
  },
  getClosestDealersSuccess: function getClosestDealersSuccess(payload) {
    return {
      type: constants.getClosestDealersSuccess,
      payload: payload
    };
  },
  getClosestDealersFailure: function getClosestDealersFailure(payload) {
    return {
      type: constants.getClosestDealersFailure,
      payload: payload
    };
  }
};

var dealerPodStatus = exports.dealerPodStatus = {
  default: 'Default',
  fetching: 'Fetching',
  fetchingSuccess: 'FetchingSuccess',
  fetchingFailure: 'FetchingFailure'
};
var initialState = {
  error: null,
  dealers: [],
  centerPoint: null,
  status: dealerPodStatus.default
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.getAllDealers:
    case constants.getClosestDealers:
      return (0, _extends3.default)({}, state, {
        status: dealerPodStatus.fetching
      });
    case constants.getAllDealersSuccess:
    case constants.getClosestDealersSuccess:
      return (0, _extends3.default)({}, state, {
        dealers: action.payload.dealers,
        centerPoint: action.payload.centerPoint,
        status: dealerPodStatus.fetchingSuccess
      });
    case constants.getAllDealersFailure:
    case constants.getClosestDealersFailure:
      return (0, _extends3.default)({}, state, {
        error: action.payload,
        status: dealerPodStatus.fetchingFailure
      });
    default:
      return state;
  }
}