'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Subtitle = exports.Header = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 20px;\n  '], ['\n    font-size: 20px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 14px;\n  '], ['\n    font-size: 14px;\n  ']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Header = _styledComponents2.default.h1.withConfig({
  displayName: 'Titles__Header',
  componentId: 'sc-18ts70v-0'
})(['color:#fff;text-align:center;font-family:\'GenesisSansHeadGlobal-Light\';font-size:40px;line-height:40px;font-weight:300;margin-bottom:0;', ';'], _Genesis2.default.max.medium(_templateObject));

var Subtitle = _styledComponents2.default.h2.withConfig({
  displayName: 'Titles__Subtitle',
  componentId: 'sc-18ts70v-1'
})(['color:#fff;text-align:center;font-family:\'GenesisSansHeadGlobal-Light\';font-weight:300;font-size:18px;line-height:14px;', ';'], _Genesis2.default.max.medium(_templateObject2));

exports.Header = Header;
exports.Subtitle = Subtitle;