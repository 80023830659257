'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (viewpoint, canvas) {
  var modalWrapper = document.createElement('div');
  modalWrapper.id = 'viewpoint-modal-wrapper';
  modalWrapper.style.height = canvas.height + 'px';

  var modalClose = document.createElement('div');
  modalClose.id = 'viewpoint-modal-close';
  modalClose.innerHTML = 'X';
  modalClose.onclick = function () {
    document.getElementById(canvas.id).parentNode.removeChild(modalWrapper);
  };

  var modalContent = document.createElement('div');
  modalContent.id = 'viewpoint-modal-content';

  var viewpointImg = document.createElement('img');
  viewpointImg.id = 'viewpoint-image';
  viewpointImg.src = viewpoint.url;

  var viewpointDesc = document.createElement('p');
  viewpointDesc.id = 'viewpoint-desc';
  viewpointDesc.innerHTML = viewpoint.description;

  modalContent.appendChild(viewpointImg);
  modalContent.appendChild(viewpointDesc);
  modalWrapper.appendChild(modalClose);
  modalWrapper.appendChild(modalContent);
  document.getElementById(canvas.id).parentNode.appendChild(modalWrapper);
};