'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translateFinanceFromTemplate = undefined;

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _numbers = require('../shared/localisation/numbers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var financePlaceholdersAndPropNames = {
  FINANCEPRICE: {
    prop: 'monthlyPrice',
    formatter: function formatter(price, currency, locale) {
      return (0, _numbers.localiseCurrency)(parseFloat(price.replace('.', '').replace(',', '.')), locale, currency);
    }
  },
  APR: {
    prop: 'apr',
    formatter: function formatter(apr) {
      return apr;
    }
  },
  INTERESTRATE: {
    prop: 'interestRate',
    formatter: function formatter(interestRate) {
      return interestRate;
    }
  },
  DURATION: {
    prop: 'duration',
    formatter: function formatter(duration) {
      return duration;
    }
  }
}; /* eslint-disable import/prefer-default-export */
var translateFinanceFromTemplate = exports.translateFinanceFromTemplate = function translateFinanceFromTemplate(template, finance, locale) {
  var replaceValue = function replaceValue(t, entry) {
    return t.replace('{' + entry[0] + '}', entry[1].formatter(finance[entry[1].prop], finance.currency, locale));
  };
  return template ? (0, _entries2.default)(financePlaceholdersAndPropNames).reduce(replaceValue, template) : '';
};