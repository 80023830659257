'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.constants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends4 = require('babel-runtime/helpers/extends');

var _extends5 = _interopRequireDefault(_extends4);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

exports.reducer = reducer;

var _isEmpty = require('ramda/src/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _omit = require('ramda/src/omit');

var _omit2 = _interopRequireDefault(_omit);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  INITIALISE_FILTERS: 'INITIALISE_FILTERS',
  LOAD_FILTERS_SUCCESS: 'LOAD_FILTERS_SUCCESS',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  UPDATE_FILTERS_STORE: 'UPDATE_FILTERS_STORE',
  RESET_FILTERS: 'RESET_FILTERS',
  SEARCH_WITH_FILTERS: 'SEARCH_WITH_FILTERS',
  GET_INVENTORY_SUCCESS: 'GET_INVENTORY_SUCCESS',
  GET_DEALER_SUCCESS: 'GET_DEALER_SUCCESS',
  CHANGE_LOCATION: 'CHANGE_LOCATION',
  RESET_PRICE_SLIDER_VALUES: 'RESET_PRICE_SLIDER_VALUES'
};

var actions = exports.actions = {
  getInventorySuccess: function getInventorySuccess(payload) {
    return {
      type: constants.GET_INVENTORY_SUCCESS,
      payload: payload
    };
  },
  getDealerSuccess: function getDealerSuccess(payload) {
    return {
      type: constants.GET_DEALER_SUCCESS,
      payload: payload
    };
  },
  initialiseFilters: function initialiseFilters(queryStringFromUrl, urlConfig) {
    return {
      type: constants.INITIALISE_FILTERS,
      payload: { queryStringFromUrl: queryStringFromUrl, urlConfig: urlConfig }
    };
  },
  loadFiltersSuccess: function loadFiltersSuccess(payload) {
    return {
      type: constants.LOAD_FILTERS_SUCCESS,
      payload: payload
    };
  },
  updateFilters: function updateFilters(filters, urlConfig) {
    var reloadData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return {
      type: constants.UPDATE_FILTERS,
      payload: { filters: filters, urlConfig: urlConfig, reloadData: reloadData }
    };
  },
  updateFiltersStore: function updateFiltersStore(payload) {
    return {
      type: constants.UPDATE_FILTERS_STORE,
      payload: payload
    };
  },
  resetFilters: function resetFilters(payload) {
    return {
      type: constants.RESET_FILTERS,
      payload: payload
    };
  },
  searchWithFilters: function searchWithFilters(payload) {
    return {
      type: constants.SEARCH_WITH_FILTERS,
      payload: payload
    };
  },
  changeLocation: function changeLocation(payload) {
    return {
      type: constants.CHANGE_LOCATION,
      payload: payload
    };
  },
  resetPriceSliderValues: function resetPriceSliderValues(payload) {
    return {
      type: constants.RESET_PRICE_SLIDER_VALUES,
      payload: payload
    };
  }
};

var arrayFilters = ['bodystyle', 'model', 'variant', 'vehicleTypes', 'engine', 'transmission', 'exteriorColours', 'interiorShades', 'features', 'fuelType', 'subregions', 'modelVariants'];

var countFilters = function countFilters(selectedFilters) {
  var exclude = ['yearRangeMin', 'minPriceRange', 'latitude', 'sortField', 'sortDirection', 'retailerId'];
  return (0, _keys2.default)(selectedFilters).filter(function (f) {
    return !exclude.includes(f);
  }).length;
};

var transformFilters = function transformFilters(_ref) {
  var selectedFilters = _ref.selectedFilters,
      availableFilterValues = _ref.availableFilterValues;

  var transformedFilters = (0, _entries2.default)(selectedFilters).map(function (entry) {
    if (arrayFilters.some(function (arrayFilter) {
      return arrayFilter === entry[0];
    })) {
      return typeof entry[1] === 'string' ? [entry[0], [entry[1]]] : entry;
    }
    return entry;
  }).reduce(function (acc, curr) {
    return (0, _extends5.default)({}, acc, (0, _defineProperty3.default)({}, curr[0], curr[1]));
  }, {});

  return {
    availableFilterValues: availableFilterValues,
    selectedFilters: (0, _extends5.default)({}, transformedFilters),
    activeFiltersCount: countFilters(transformedFilters)
  };
};

var initialState = exports.initialState = {
  selectedFilters: null,
  availableFilterValues: null,
  activeFiltersCount: 0,
  subregionsChanged: false
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  var retailerId = state.shared && state.shared.filters.selectedFilters && state.shared.filters.selectedFilters.retailerId;
  var subregionsChanged = action.payload && action.payload.selectedFilters && !!action.payload.selectedFilters.subregions;
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case constants.GET_DEALER_SUCCESS:
      return (0, _extends5.default)({}, state, {
        dealer: payload
      });
    case constants.LOAD_FILTERS_SUCCESS:
      return (0, _extends5.default)({}, state, transformFilters(payload), {
        total: payload.resultsCount,
        subregionsChanged: subregionsChanged
      });
    case constants.UPDATE_FILTERS_STORE:
      {
        var updatedState = state;

        for (var idx = 0; idx < payload.length; idx += 1) {
          var data = payload[idx];
          updatedState = data.value === undefined || data.value === null || (0, _isEmpty2.default)(data.value) ? (0, _extends5.default)({}, updatedState, {
            selectedFilters: (0, _omit2.default)([data.key], (0, _extends5.default)({}, updatedState.selectedFilters))
          }) : (0, _extends5.default)({}, updatedState, {
            selectedFilters: (0, _extends5.default)({}, updatedState.selectedFilters, (0, _defineProperty3.default)({}, data.key, data.value))
          });
        }

        return (0, _extends5.default)({}, updatedState, {
          activeFiltersCount: countFilters(updatedState.selectedFilters),
          total: state.total,
          subregionsChanged: subregionsChanged
        });
      }
    case constants.RESET_FILTERS:
      {
        // All brands that handle subregions, will keep them as they are
        var keepRegions = state.selectedFilters && state.selectedFilters.subregions;
        return (0, _extends5.default)({}, state, {
          selectedFilters: retailerId ? (0, _extends5.default)({ retailerId: retailerId }, payload) : keepRegions ? (0, _extends5.default)({}, payload, {
            subregions: state.selectedFilters.subregions
          }) : (0, _extends5.default)({}, payload),
          activeFiltersCount: 0,
          location: '',
          subregionsChanged: false
        });
      }
    case constants.CHANGE_LOCATION:
      return (0, _extends5.default)({}, state, {
        location: payload
      });
    default:
      return state;
  }
}