'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin-right: 40px;\n  '], ['\n    margin-right: 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n  '], ['\n    flex-direction: column;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    &:nth-child(-n + 2) {\n      border-top: 1px solid #d8d8d8;\n    }\n    &:last-child{\n      border-bottom: 1px solid #d8d8d8;\n    }\n    margin: 0 20px 0 0;\n    width: calc(33.33% - 20px);\n  '], ['\n    &:nth-child(-n + 2) {\n      border-top: 1px solid #d8d8d8;\n    }\n    &:last-child{\n      border-bottom: 1px solid #d8d8d8;\n    }\n    margin: 0 20px 0 0;\n    width: calc(33.33% - 20px);\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EmissionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsGroup__EmissionsContainer',
  componentId: 'sc-tu9khb-0'
})(['align-self:start;display:flex;flex-direction:row;flex-wrap:wrap;width:100%;', ';'], _theme2.default.min.large(_templateObject));

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsGroup__Row',
  componentId: 'sc-tu9khb-1'
})(['display:flex;flex-direction:row;', ';'], _theme2.default.max.medium(_templateObject2));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsGroup__Item',
  componentId: 'sc-tu9khb-2'
})(['display:flex;border-bottom:1px solid #d8d8d8;box-sizing:border-box;font-size:14px;margin:0;padding:20px 0;width:100%;&:nth-child(-n + 3){border-top:1px solid #d8d8d8;}&:last-child{border-bottom:none;}', ';'], _theme2.default.min.large(_templateObject3));

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsGroup__Label',
  componentId: 'sc-tu9khb-3'
})(['font-family:\'kia_bold\';color:#1f1f1f;']);

var Value = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsGroup__Value',
  componentId: 'sc-tu9khb-4'
})(['display:flex;justify-content:flex-end;font-family:\'kia_medium\';color:#1f1f1f;text-wrap:nowrap;margin-left:auto;']);

var renderItems = function renderItems(items) {
  return items.map(function (_ref) {
    var label = _ref.label,
        value = _ref.value;
    return _react2.default.createElement(
      Item,
      { key: label },
      _react2.default.createElement(
        Label,
        null,
        label
      ),
      _react2.default.createElement(
        Value,
        null,
        value
      )
    );
  });
};

var EmissionsGroup = function EmissionsGroup(_ref2) {
  var translations = _ref2.translations,
      data = _ref2.data,
      headerFont = _ref2.headerFont;
  var fuelType = data.fuelType,
      fuelConsumptionCombined = data.fuelConsumptionCombined,
      weightedCombinedFuelConsumption = data.weightedCombinedFuelConsumption,
      combinedPowerConsumption = data.combinedPowerConsumption,
      combinedPowerConsumptionEV = data.combinedPowerConsumptionEV,
      weightedCombinedCo2Emission = data.weightedCombinedCo2Emission,
      co2EmissionsCombined = data.co2EmissionsCombined,
      co2EmissionsClassWeightedCombined = data.co2EmissionsClassWeightedCombined,
      combinedFuelConsumptionDischargedBattery = data.combinedFuelConsumptionDischargedBattery,
      co2ClassWithDischargedBatteryLabel = data.co2ClassWithDischargedBatteryLabel,
      electricRange = data.electricRange,
      electricRangeHybrid = data.electricRangeHybrid,
      emissionsClass = data.emissionsClass;


  var items = [];

  switch (fuelType) {
    case 'Strom':
      if (combinedPowerConsumptionEV !== null && co2EmissionsCombined !== null && emissionsClass !== null && electricRange !== null) {
        items = [{
          label: translations.combinedPowerLabel,
          value: combinedPowerConsumptionEV + ' kWh/100 km'
        }, {
          label: translations.combinedCO2EmissionsLabel,
          value: co2EmissionsCombined + ' g/km'
        }, {
          label: translations.co2ClassLabel,
          value: emissionsClass
        }, {
          label: translations.electricRangeLabel,
          value: electricRange + ' km'
        }];
      }
      break;

    case 'Plug-in Hybrid – (Benzin/Elektro)':
    case 'Plug-in Hybrid– (Diesel/Elektro)':
      if (weightedCombinedFuelConsumption !== null && combinedPowerConsumption !== null && weightedCombinedCo2Emission !== null && co2EmissionsClassWeightedCombined !== null && combinedFuelConsumptionDischargedBattery !== null && co2ClassWithDischargedBatteryLabel !== null && electricRangeHybrid !== null) {
        items = [{
          label: translations.weightedCombinedFuelConsumptionLabel,
          value: weightedCombinedFuelConsumption + ' kW\u22C5h/100 km'
        }, {
          label: translations.powerConsumptionCombinedLabel,
          value: combinedPowerConsumption + ' kWh/100 km'
        }, {
          label: translations.co2EmissionsCombinedLabel,
          value: weightedCombinedCo2Emission + ' g/km'
        }, {
          label: translations.co2ClassCombinedLabel,
          value: co2EmissionsClassWeightedCombined
        }, {
          label: translations.fuelConsumptionWithDischargedBatteryLabel,
          value: combinedFuelConsumptionDischargedBattery + ' l/100 km'
        }, {
          label: translations.co2ClassWithDischargedBatteryLabel,
          value: co2ClassWithDischargedBatteryLabel
        }, {
          label: translations.electricRangeHybridLabel,
          value: electricRangeHybrid + ' km'
        }];
      }
      break;

    default:
      if (fuelConsumptionCombined !== null && co2EmissionsCombined !== null && emissionsClass !== null) {
        items = [{
          label: translations.fuelConsumptionLabel,
          value: fuelConsumptionCombined + ' l/100 km'
        }, {
          label: translations.combinedCO2EmissionsLabel,
          value: co2EmissionsCombined + ' g/km'
        }, {
          label: translations.co2ClassLabel,
          value: emissionsClass
        }];
      }
      break;
  }

  if (items.length === 0) {
    return null;
  }

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        styleOverride: function styleOverride() {
          return (0, _Global.fontStyleOverride)(headerFont);
        },
        mobileStyleOverride: function mobileStyleOverride() {
          return [{
            queryPath: 'max.small',
            template: 'font-size: 16px;'
          }];
        }
      },
      translations.vdpEmissionsHeader
    ),
    _react2.default.createElement(
      Row,
      null,
      _react2.default.createElement(
        EmissionsContainer,
        null,
        renderItems(items)
      )
    )
  );
};

exports.default = EmissionsGroup;