'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('../../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var config = _ref.config;
  return _react2.default.createElement(
    _components.Wrapper,
    null,
    _react2.default.createElement(
      _components.HeadingTextButton,
      { config: config },
      function (_ref2) {
        var _ref2$config = _ref2.config,
            imageLabel = _ref2$config.translations.imageLabel,
            src = _ref2$config.image.src,
            imageBehaviour = _ref2$config.imageBehaviour,
            imagePosition = _ref2$config.imagePosition,
            imageHeight = _ref2$config.imageHeight;
        return _react2.default.createElement(_components.Image, (0, _extends3.default)({ src: src, imageBehaviour: imageBehaviour, imagePosition: imagePosition, imageHeight: imageHeight }, {
          role: 'img',
          'aria-label': imageLabel
        }));
      }
    )
  );
};