'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 16px 5% 0;\n  '], ['\n    padding: 16px 5% 0;\n  ']);

exports.default = ShortlistedGridVehicles;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _VehicleGridShortlist = require('./VehicleGridShortlist');

var _VehicleGridShortlist2 = _interopRequireDefault(_VehicleGridShortlist);

var _Global = require('./Global');

var _StyledIcon = require('./StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ShortlistedGridVehicles__Container',
  componentId: 'sc-1160td3-0'
})(['padding:', ';', ';'], function (_ref) {
  var padding = _ref.padding;
  return padding ? '0 ' + padding.right + 'px 0 ' + padding.left + 'px' : '16px 16px 0';
}, _theme2.default.max.medium(_templateObject));

var EmptyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ShortlistedGridVehicles__EmptyContainer',
  componentId: 'sc-1160td3-1'
})(['display:flex;flex-direction:column;align-items:center;padding:72px 0 280px 0;']);

var Footer = _styledComponents2.default.p.withConfig({
  displayName: 'ShortlistedGridVehicles__Footer',
  componentId: 'sc-1160td3-2'
})(['text-align:center;color:#7e7e7e;font-size:13px;margin:auto 25%;margin-top:27px;']);

function ShortlistedGridVehicles(_ref2) {
  var config = _ref2.config,
      vehicles = _ref2.vehicles,
      onCloseClick = _ref2.onCloseClick,
      onHeartClick = _ref2.onHeartClick,
      onCompareClick = _ref2.onCompareClick,
      onMoreInfoClick = _ref2.onMoreInfoClick,
      getCompareIconColour = _ref2.getCompareIconColour,
      getShortListedIconColour = _ref2.getShortListedIconColour,
      compareVehicle = _ref2.compareVehicle,
      shortlistVehicle = _ref2.shortlistVehicle,
      sortValue = _ref2.sortValue,
      sortValueDictionary = _ref2.sortValueDictionary,
      selectedView = _ref2.selectedView,
      placeholdingImage = _ref2.placeholdingImage,
      globalStyling = _ref2.globalStyling,
      locale = _ref2.locale,
      translations = _ref2.translations,
      onEnquiryClick = _ref2.onEnquiryClick;

  var vehiclesNoDistance = vehicles.map(function (v) {
    var distance = v.distance,
        rest = (0, _objectWithoutProperties3.default)(v, ['distance']);

    return rest;
  });

  var singularOrPluralHeading = function singularOrPluralHeading() {
    return vehicles.length > 1 ? vehicles.length + ' ' + translations.searchResultsAvailableAtLabel : vehicles.length + ' ' + translations.searchResultsAvailableAtSingleLabel;
  };

  var containerPadding = config.containerPadding;


  return _react2.default.createElement(
    Container,
    { padding: containerPadding },
    config.showVehicleCount && _react2.default.createElement(
      _Global.HeadingThree,
      {
        styleOverride: function styleOverride() {
          return '\n            ' + (0, _Global.fontStyleOverride)(config.vehicleSpecContainer.subHeaderFont) + '\n          ';
        }
      },
      vehicles.length > 0 && singularOrPluralHeading()
    ),
    vehicles.length === 0 ? _react2.default.createElement(
      EmptyContainer,
      null,
      _react2.default.createElement(_StyledIcon2.default, { link: config.emptyIcon, width: 100, height: 100 }),
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n              margin-top: 40px;\n              font-size: 16px;\n              text-align: center;\n              ' + (0, _Global.fontStyleOverride)(config.noVehicleTextFont) + '\n            ';
          }
        },
        config.translations.noVehicleText
      )
    ) : _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(_VehicleGridShortlist2.default, {
        config: config,
        vehicles: vehiclesNoDistance,
        onCloseClick: onCloseClick,
        onHeartClick: onHeartClick,
        onCompareClick: onCompareClick,
        onMoreInfoClick: onMoreInfoClick,
        onEnquiryClick: onEnquiryClick,
        getCompareIconColour: getCompareIconColour,
        getShortListedIconColour: getShortListedIconColour,
        compareVehicle: compareVehicle,
        shortlistVehicle: shortlistVehicle,
        selectedView: selectedView,
        sortValue: sortValue,
        sortValueDictionary: sortValueDictionary,
        placeholdingImage: placeholdingImage,
        globalStyling: globalStyling,
        locale: locale,
        vehicleSpecContainer: config.vehicleSpecContainer,
        handleZeroPriceAsText: config.handleZeroPriceAsText
      }),
      _react2.default.createElement(
        Footer,
        null,
        config.translations.footer
      )
    )
  );
}