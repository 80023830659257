'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _theme = require('../theme');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var selectImageSize = function selectImageSize(width, images) {
  if (width <= +_theme.screenSizeOptions.medium) {
    return images.mobile;
  }
  if (width >= _theme.screenSizeOptions.medium && width <= _theme.screenSizeOptions.xxl) {
    return images.tablet;
  }

  return images.desktop;
};

var useResponsiveImages = function useResponsiveImages(images) {
  var _useState = (0, _react.useState)(window.innerWidth),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      width = _useState2[0],
      setWidth = _useState2[1];

  var handleWindowSizeChange = function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  };

  (0, _react.useEffect)(function () {
    window.addEventListener('resize', handleWindowSizeChange);
    return function () {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return selectImageSize(width, images);
};

exports.default = useResponsiveImages;