'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.default = RegionCheckboxGroup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _difference = require('ramda/src/difference');

var _difference2 = _interopRequireDefault(_difference);

var _filters = require('../../../../shared/filters');

var _Form = require('../../Form');

var _useCurrencySelector2 = require('../../../../hooks/useCurrencySelector');

var _useCurrencySelector3 = _interopRequireDefault(_useCurrencySelector2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ItemLabel = _styledComponents2.default.span.withConfig({
  displayName: 'RegionCheckboxGroup__ItemLabel',
  componentId: 'sc-fktlvy-0'
})(['', ';'], function (_ref) {
  var disabled = _ref.disabled;
  return disabled && 'color: #CCCCCC;';
});

function RegionCheckboxGroup(_ref2) {
  var region = _ref2.region,
      subregions = _ref2.subregions,
      updateFilters = _ref2.updateFilters,
      filterKey = _ref2.filterKey,
      selectedData = _ref2.selectedData,
      config = _ref2.config,
      translations = _ref2.translations;

  var _useCurrencySelector = (0, _useCurrencySelector3.default)(),
      onCurrencyChange = _useCurrencySelector.onCurrencyChange;

  var regionCurrencies = config.regionCurrencies;

  var updateFiltersForRegions = function updateFiltersForRegions(values) {
    var filteredValues = values.filter(function (v) {
      return v.enabled;
    }).map(function (v) {
      return v.name;
    });

    if (selectedData) {
      filteredValues = filteredValues.every(function (v) {
        return selectedData.includes(v);
      }) ? (0, _difference2.default)(selectedData, filteredValues) : [].concat((0, _toConsumableArray3.default)(filteredValues), (0, _toConsumableArray3.default)(selectedData));
    }

    updateFilters([{
      key: filterKey,
      value: filteredValues
    }]);
  };

  var updateFiltersForSubregions = function updateFiltersForSubregions(subRegion, enabled) {
    if (enabled) {
      onCurrencyChange(regionCurrencies[subRegion]);
      updateFilters([{
        key: filterKey,
        value: _filters.helpers.newArray(selectedData && selectedData.length > 0 ? selectedData.filter(Boolean) : [], subRegion)
      }]);
    }
  };

  var enabledSubregions = subregions.filter(function (sr) {
    return sr.enabled;
  });
  var enableRegion = subregions.some(function (s) {
    return s.enabled;
  });
  var regionSelected = subregions.some(function (s) {
    return s.enabled;
  }) && selectedData && (0, _difference2.default)(enabledSubregions.map(function (sr) {
    return sr.name;
  }), selectedData).length === 0;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      'div',
      {
        style: {
          display: 'flex',
          displayDirection: 'row',
          justifyContent: 'space-between',
          padding: '5px 15px 10px 8px',
          fontSize: '14px'
        }
      },
      _react2.default.createElement(
        ItemLabel,
        { disabled: !enableRegion },
        region
      ),
      _react2.default.createElement(_Form.Checkbox, {
        key: region,
        onClick: function onClick() {
          return updateFiltersForRegions(subregions);
        },
        checked: regionSelected,
        enabled: enableRegion
      })
    ),
    subregions.map(function (subregion) {
      var subRegionSelected = subregion.enabled && selectedData && selectedData.includes(subregion.name);
      return _react2.default.createElement(
        'div',
        {
          key: subregion.name,
          style: {
            display: 'flex',
            displayDirection: 'row',
            justifyContent: 'space-between',
            padding: '10px 15px',
            fontSize: '14px'
          }
        },
        _react2.default.createElement(
          ItemLabel,
          { disabled: !subregion.enabled },
          translations[subregion.name.toLowerCase()] || '-' + subregion.name + '-'
        ),
        _react2.default.createElement(_Form.Checkbox, {
          key: region + '-' + subregion.name,
          onClick: function onClick() {
            return updateFiltersForSubregions(subregion.name, subregion.enabled);
          },
          checked: subRegionSelected,
          enabled: subregion.enabled
        })
      );
    })
  );
}