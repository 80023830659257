'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends4 = require('babel-runtime/helpers/extends');

var _extends5 = _interopRequireDefault(_extends4);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n    padding: 50px;\n  '], ['\n    display: block;\n    padding: 50px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n  width: 100%;\n  padding: 20px 0 20px 0;\n  '], ['\n  width: 100%;\n  padding: 20px 0 20px 0;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n  '], ['\n    flex-direction: column;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 10px;\n    width: 100%;\n  '], ['\n    margin-top: 10px;\n    width: 100%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Slider = require('../RangeSlider/Slider');

var _Slider2 = _interopRequireDefault(_Slider);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _LabelledCheckBox = require('../LabelledCheckBox');

var _LabelledCheckBox2 = _interopRequireDefault(_LabelledCheckBox);

var _numbers = require('../../shared/localisation/numbers');

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PersonalContractInfo = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalContractInfo'
}).withConfig({
  displayName: 'PersonalContractPurchase__PersonalContractInfo',
  componentId: 'sc-sm30l6-0'
})(['width:93%;margin:0 auto;background-color:#ffffff;padding-top:15px;text-align:center;']);

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalContractPurchase__Title',
  componentId: 'sc-sm30l6-1'
})(['']);

var Body = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalContractPurchase__Body',
  componentId: 'sc-sm30l6-2'
})(['width:100%;text-align:center;padding-top:15px;']);

var SlidersContainer = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalContractPurchase__SlidersContainer',
  componentId: 'sc-sm30l6-3'
})(['background-color:#ffffff;padding:0 20px 20px;display:flex;justify-content:space-between;', ';'], _theme2.default.max.large(_templateObject));

var RangeContainer = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalContractPurchase__RangeContainer',
  componentId: 'sc-sm30l6-4'
})(['width:45%;padding:20px;', ';'], _theme2.default.max.large(_templateObject2));

var StyledRangeHeading = _styledComponents2.default.h4.withConfig({
  displayName: 'PersonalContractPurchase__StyledRangeHeading',
  componentId: 'sc-sm30l6-5'
})(['margin:0 0 20px -8px;display:flex;justify-content:space-between;font-weight:bold;font-size:16px;color:#444;']);

var WithSpaceRight = _styledComponents2.default.span.withConfig({
  displayName: 'PersonalContractPurchase__WithSpaceRight',
  componentId: 'sc-sm30l6-6'
})(['padding-right:5px;']);

var ASContainer = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalContractPurchase__ASContainer',
  componentId: 'sc-sm30l6-7'
})(['padding:0 35px;background-color:#ffffff;']);

var ASTitle = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalContractPurchase__ASTitle',
  componentId: 'sc-sm30l6-8'
})(['text-align:left;color:#444444;font-size:18px;font-weight:800;']);

var ASSection = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalContractPurchase__ASSection',
  componentId: 'sc-sm30l6-9'
})(['display:flex;width:100%;align-items:center;margin:15px 0 25px;', ';'], _theme2.default.max.small(_templateObject3));

var Block = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalContractPurchase__Block',
  componentId: 'sc-sm30l6-10'
})(['width:50%;', ';'], _theme2.default.max.small(_templateObject4));

var OptionTitle = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalContractPurchase__OptionTitle',
  componentId: 'sc-sm30l6-11'
})(['font-size:16px;color:#444444;font-family:ProximaNova-Semibold;']);

var PersonalContract = function (_Component) {
  (0, _inherits3.default)(PersonalContract, _Component);

  function PersonalContract(props) {
    (0, _classCallCheck3.default)(this, PersonalContract);

    var _this = (0, _possibleConstructorReturn3.default)(this, (PersonalContract.__proto__ || (0, _getPrototypeOf2.default)(PersonalContract)).call(this, props));

    _initialiseProps.call(_this);

    var financeExample = _this.props.financeExample;

    var _ref = financeExample || { additionalServices: [] },
        additionalServices = _ref.additionalServices;

    var selectedServices = additionalServices.reduce(function (acc, current) {
      return (0, _extends5.default)({}, acc, (0, _defineProperty3.default)({}, current.id, current.selected));
    }, {});

    _this.state = {
      sliderDeposit: _this.props.downpaymentRange.defaultValue,
      serviceOption: (0, _extends5.default)({}, selectedServices)
    };
    _this.calculateMaxDepositStep();
    return _this;
  }

  return PersonalContract;
}(_react.Component);

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  Object.defineProperty(this, 'setSliderDeposit', {
    enumerable: true,
    writable: true,
    value: function value(_value) {
      var step = _this2.props.downpaymentRange.step;

      var deposit = _value >= _this2.maxDepositStep ? _this2.props.downpaymentRange.fullRangeValues.max : Math.floor(_value / step) * step;

      _this2.setState({ sliderDeposit: deposit });
    }
  });
  Object.defineProperty(this, 'getSelectedServiceOptions', {
    enumerable: true,
    writable: true,
    value: function value() {
      return (0, _keys2.default)(_this2.state.serviceOption).filter(function (soKey) {
        return _this2.state.serviceOption[soKey];
      });
    }
  });
  Object.defineProperty(this, 'calculateMaxDepositStep', {
    enumerable: true,
    writable: true,
    value: function value() {
      var step = _this2.props.downpaymentRange.step;

      var maxStep = Math.floor(_this2.props.downpaymentRange.fullRangeValues.max / step) * step;
      var maxStepBuffer = (_this2.props.downpaymentRange.fullRangeValues.max - maxStep) / 2;
      _this2.maxDepositStep = maxStep + maxStepBuffer;
    }
  });
  Object.defineProperty(this, 'checkServiceOption', {
    enumerable: true,
    writable: true,
    value: function value(key, duration, deposit) {
      _this2.setState(function (_ref2) {
        var serviceOption = _ref2.serviceOption;
        return {
          serviceOption: (0, _extends5.default)({}, serviceOption, (0, _defineProperty3.default)({}, key, !serviceOption[key]))
        };
      }, function () {
        _this2.props.requestFinanceUpdate(duration, deposit, [].concat((0, _toConsumableArray3.default)(_this2.getSelectedServiceOptions())), _this2.props.productKey);
      });
    }
  });
  Object.defineProperty(this, 'maxDepositStep', {
    enumerable: true,
    writable: true,
    value: undefined
  });
  Object.defineProperty(this, 'render', {
    enumerable: true,
    writable: true,
    value: function value() {
      var _props = _this2.props,
          downpaymentRange = _props.downpaymentRange,
          termsRange = _props.termsRange,
          financeExample = _props.financeExample,
          requestFinanceUpdate = _props.requestFinanceUpdate,
          financeExampleRenderProp = _props.financeExampleRenderProp,
          productKey = _props.productKey,
          translations = _props.translations,
          globalStyling = _props.globalStyling,
          locale = _props.locale,
          showDescription = _props.showDescription;


      var duration = (financeExample || {}).duration || termsRange.defaultValue;
      var monthlyPayment = (financeExample || {}).monthlyPrice;

      var _ref3 = financeExample || { additionalServices: [] },
          additionalServices = _ref3.additionalServices;

      var currency = (financeExample || {}).currency || 'EUR';

      var convertedMonthyPrice = monthlyPayment && (0, _numbers.localiseCurrency)(parseFloat(monthlyPayment.replace('.', '').replace(',', '.')), locale, currency);

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          PersonalContractInfo,
          null,
          _react2.default.createElement(
            Body,
            null,
            showDescription ? financeExample.description : (0, _translateFromTemplate.translateFromTemplate)('vdpMyDealHirePurchaseSummary', {
              PAYMENT: convertedMonthyPrice,
              DURATION: '' + duration
            }, translations)
          )
        ),
        _react2.default.createElement(
          SlidersContainer,
          null,
          _react2.default.createElement(
            RangeContainer,
            null,
            _react2.default.createElement(
              StyledRangeHeading,
              null,
              _react2.default.createElement(
                Title,
                null,
                downpaymentRange.title
              ),
              _react2.default.createElement(
                'span',
                null,
                _react2.default.createElement(
                  WithSpaceRight,
                  null,
                  (0, _numbers.localiseCurrency)(_this2.state.sliderDeposit, locale, currency, 0)
                )
              )
            ),
            _react2.default.createElement(_Slider2.default, {
              step: 1,
              marks: downpaymentRange.marks,
              rangeValue: downpaymentRange.fullRangeValues,
              defaultValue: downpaymentRange.defaultValue,
              sliderColor: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
              onChange: function onChange(value) {
                _this2.setSliderDeposit(value);
              },
              onAfterChange: function onAfterChange() {
                return requestFinanceUpdate(duration, _this2.state.sliderDeposit, [].concat((0, _toConsumableArray3.default)(_this2.getSelectedServiceOptions())), productKey);
              }
            })
          ),
          _react2.default.createElement(
            RangeContainer,
            null,
            _react2.default.createElement(
              StyledRangeHeading,
              null,
              _react2.default.createElement(
                Title,
                null,
                termsRange.title
              ),
              _react2.default.createElement(
                'span',
                null,
                _react2.default.createElement(
                  WithSpaceRight,
                  null,
                  duration
                ),
                _react2.default.createElement(
                  WithSpaceRight,
                  null,
                  termsRange.unit
                )
              )
            ),
            _react2.default.createElement(_Slider2.default, {
              step: termsRange.step,
              marks: termsRange.marks,
              rangeValue: termsRange.fullRangeValues,
              defaultValue: termsRange.defaultValue,
              value: _this2.state.sliderDeposit,
              sliderColor: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
              onAfterChange: function onAfterChange(value) {
                return requestFinanceUpdate(value, _this2.state.sliderDeposit, [].concat((0, _toConsumableArray3.default)(_this2.getSelectedServiceOptions())), productKey);
              }
            })
          )
        ),
        financeExample && _react2.default.createElement(
          ASContainer,
          null,
          _react2.default.createElement(
            ASTitle,
            null,
            translations.additionalServicesLabel
          ),
          _react2.default.createElement(
            ASSection,
            null,
            additionalServices.sort(function (a, b) {
              return a.id - b.id;
            }).map(function (_ref4) {
              var id = _ref4.id,
                  selected = _ref4.selected,
                  description = _ref4.description,
                  name = _ref4.name,
                  value = _ref4.value;
              return [_react2.default.createElement(
                Block,
                null,
                _react2.default.createElement(_LabelledCheckBox2.default, {
                  key: id,
                  label: _react2.default.createElement(
                    OptionTitle,
                    null,
                    name,
                    value && ' - ' + (0, _numbers.localiseCurrency)(parseFloat(value.replace('.', '').replace(',', '.')), locale, currency)
                  ),
                  description: description,
                  onColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
                  checked: _this2.state.serviceOption[id] || selected,
                  onClick: function onClick() {
                    return _this2.checkServiceOption(id, duration, _this2.state.sliderDeposit);
                  },
                  globalStyling: globalStyling
                })
              )];
            })
          )
        ),
        financeExampleRenderProp()
      );
    }
  });
};

exports.default = PersonalContract;