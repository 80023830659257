'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  (0, _react.useEffect)(function () {
    return function () {
      // eslint-disable-next-line no-undef
      showDrawer();
    };
  }, []);

  return _react2.default.createElement(
    ConsentPolicy,
    null,
    _react2.default.createElement('div', { className: 'epaas-policy-page-container' })
  );
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ConsentPolicy = _styledComponents2.default.div.withConfig({
  displayName: 'ConsentPolicy',
  componentId: 'sc-1yjvvw4-0'
})(['position:relative;height:auto;display:block;width:100%;']);