'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClickWrapper = undefined;

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ClickWrapper = exports.ClickWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ClickWrapper',
  componentId: 'sc-147za0b-0'
})(['cursor:', ';min-height:1px;max-height:', ';display:flex;align-items:center;overflow:hidden;'], function (_ref) {
  var pointer = _ref.pointer;
  return pointer ? 'pointer' : 'default';
}, function (_ref2) {
  var fullScreen = _ref2.fullScreen;
  return fullScreen ? '100vh' : '600px';
});