'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.useMarketSelector = useMarketSelector;

var _react = require('react');

var _reactRedux = require('react-redux');

var _actions = require('./actions');

var _language = require('../../actions/language');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useMarketSelector(translations) {
  var dispatch = (0, _reactRedux.useDispatch)();
  var marketSelected = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences.marketSelected;
  });
  var languages = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.languages;
  });
  var currencies = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.currencies;
  });
  var markets = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.markets;
  });

  var locale = (0, _reactRedux.useSelector)(function (state) {
    return state.config.settings.locale;
  });

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      languageOpen = _useState2[0],
      toggleLanguageOpen = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      currencyOpen = _useState4[0],
      toggleCurrencyOpen = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      marketOpen = _useState6[0],
      toggleMarketOpen = _useState6[1];

  var _useState7 = (0, _react.useState)(currencies[0]),
      _useState8 = (0, _slicedToArray3.default)(_useState7, 2),
      currency = _useState8[0],
      setCurrency = _useState8[1];

  var _useState9 = (0, _react.useState)(languages.find(function (l) {
    return l.value === locale;
  })),
      _useState10 = (0, _slicedToArray3.default)(_useState9, 2),
      language = _useState10[0],
      setLanguage = _useState10[1];

  var _useState11 = (0, _react.useState)(),
      _useState12 = (0, _slicedToArray3.default)(_useState11, 2),
      market = _useState12[0],
      setMarket = _useState12[1];

  var closeAll = function closeAll() {
    toggleMarketOpen(false);
    toggleCurrencyOpen(false);
    toggleLanguageOpen(false);
  };

  var submit = function submit() {
    return dispatch(_actions.actions.setMarketSelection({
      currency: currency.value,
      language: language.value,
      market: market ? market.value : null
    }));
  };

  var dropDowns = [{
    open: marketOpen,
    heading: translations.selectCountry,
    title: market && market.label || translations.allCountries,
    setter: function setter(opt) {
      return function (e) {
        e.stopPropagation();
        toggleMarketOpen(false);
        setMarket(opt);
      };
    },
    toggle: function toggle(e) {
      e.stopPropagation();
      toggleMarketOpen(!marketOpen);
    },
    showResetOption: !!market,
    resetLabel: translations.allCountries,
    options: markets
  }, {
    open: currencyOpen,
    heading: translations.selectCurrency,
    title: currency.label,
    setter: function setter(opt) {
      return function (e) {
        e.stopPropagation();
        toggleCurrencyOpen(false);
        setCurrency(opt);
      };
    },
    toggle: function toggle(e) {
      e.stopPropagation();
      toggleCurrencyOpen(!currencyOpen);
    },
    options: currencies
  }, {
    open: languageOpen,
    heading: translations.selectLanguage,
    title: language.label,
    setter: function setter(opt) {
      return function (e) {
        e.stopPropagation();
        toggleLanguageOpen(false);
        if (language !== opt) {
          setLanguage(opt);
        }
      };
    },
    toggle: function toggle(e) {
      e.stopPropagation();
      toggleLanguageOpen(!languageOpen);
    },
    options: languages
  }];

  (0, _react.useEffect)(function () {
    if (language.value !== locale) {
      dispatch(_language.actions.changeLanguage(language.value));
    }
  }, [language.value]);

  return {
    marketSelected: marketSelected,
    currency: currency,
    language: language,
    dropDowns: dropDowns,
    closeAll: closeAll,
    submit: submit
  };
}