'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _searchFiltersHelper = require('./SearchFilters/search-filters-helper');

var _RangeSlider = require('./RangeSlider');

var _RangeSlider2 = _interopRequireDefault(_RangeSlider);

var _RangeHeading = require('./SearchFilters/RangeHeading');

var _RangeHeading2 = _interopRequireDefault(_RangeHeading);

var _FilterSectionTitle = require('./SearchFilters/FilterSectionTitle');

var _FilterSectionTitle2 = _interopRequireDefault(_FilterSectionTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilterContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FilterContainer'
}).withConfig({
  displayName: 'PriceRangeFilter__FilterContainer',
  componentId: 'sc-u3rqcz-0'
})(['padding:20px 20px 0 20px;border-bottom:1px solid #d8d8d8;width:100%;box-sizing:border-box;']);

var RangeContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RangeContainer'
}).withConfig({
  displayName: 'PriceRangeFilter__RangeContainer',
  componentId: 'sc-u3rqcz-1'
})(['padding:20px;border-top:1px solid #d8d8d8;']);

var CloseButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CloseButtonWrapper'
}).withConfig({
  displayName: 'PriceRangeFilter__CloseButtonWrapper',
  componentId: 'sc-u3rqcz-2'
})(['display:flex;cursor:pointer;justify-content:flex-end;']);

var PriceRangeFilter = function (_Component) {
  (0, _inherits3.default)(PriceRangeFilter, _Component);

  function PriceRangeFilter(props) {
    (0, _classCallCheck3.default)(this, PriceRangeFilter);

    var _this = (0, _possibleConstructorReturn3.default)(this, (PriceRangeFilter.__proto__ || (0, _getPrototypeOf2.default)(PriceRangeFilter)).call(this, props));

    Object.defineProperty(_this, 'onChangeRangeLabel', {
      enumerable: true,
      writable: true,
      value: function value(rangeValues, label) {
        _this.setState((0, _defineProperty3.default)({}, label, { min: rangeValues[0], max: rangeValues[1] }));
      }
    });
    var _props$availableFilte = props.availableFilters.priceRange,
        priceRange = _props$availableFilte === undefined ? { min: 0, max: 0 } : _props$availableFilte;


    _this.state = {
      priceRangeLabel: priceRange
    };
    return _this;
  }

  (0, _createClass3.default)(PriceRangeFilter, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          allFilters = _props.allFilters,
          availableFilters = _props.availableFilters,
          handleRangeFilters = _props.handleRangeFilters,
          closeFilter = _props.closeFilter,
          _props$config$transla = _props.config.translations,
          translations = _props$config$transla === undefined ? {} : _props$config$transla;

      var _transformRangeProps = (0, _searchFiltersHelper.transformRangeProps)(allFilters),
          priceRange = _transformRangeProps.priceRange;

      return _react2.default.createElement(
        FilterContainer,
        null,
        _react2.default.createElement(
          CloseButtonWrapper,
          { onClick: closeFilter },
          _react2.default.createElement(_FilterSectionTitle2.default, { title: translations.searchHeaderPriceRange })
        ),
        _react2.default.createElement(
          RangeContainer,
          null,
          _react2.default.createElement(_RangeHeading2.default, {
            range: this.state.priceRangeLabel,
            heading: translations.searchSubHeaderPrice,
            unit: translations.unitPrice
          }),
          _react2.default.createElement(_RangeSlider2.default, {
            step: priceRange.step,
            rangeValue: priceRange.fullRangeValues,
            name: priceRange.name,
            defaultValue: (0, _searchFiltersHelper.getInitialRangeValues)(100, availableFilters.priceRange),
            onAfterChange: function onAfterChange(values) {
              return handleRangeFilters(priceRange.id, values);
            },
            onChange: function onChange(values) {
              return _this2.onChangeRangeLabel(values, 'priceRangeLabel');
            }
          })
        )
      );
    }
  }]);
  return PriceRangeFilter;
}(_react.Component);

exports.default = PriceRangeFilter;