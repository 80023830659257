'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      margin-top: 34px;\n  '], ['\n      margin-top: 34px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    letter-spacing: 2.5px;\n    line-height: 80px;\n    font-size: 70px;\n  '], ['\n    letter-spacing: 2.5px;\n    line-height: 80px;\n    font-size: 70px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 4px 0;\n    line-height: 34px;\n    letter-spacing: 6px;\n    font-size: 24px;\n  '], ['\n    margin: 4px 0;\n    line-height: 34px;\n    letter-spacing: 6px;\n    font-size: 24px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 12px 0;\n  '], ['\n    margin: 12px 0;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n      margin: 30px 0;\n  '], ['\n      margin: 30px 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Button = require('../../../components/RollsRoyce/Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _Image = require('./Image');

var _Image2 = _interopRequireDefault(_Image);

var _Video = require('./Video');

var _Video2 = _interopRequireDefault(_Video);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SlideContainer = _styledComponents2.default.div.withConfig({ displayName: 'SlideContainer' }).withConfig({
  displayName: 'RollsRoyceSlide__SlideContainer',
  componentId: 'sc-7pna0i-0'
})(['background-color:#000;display:flex;flex-direction:column;min-height:1px;']);

var SlideContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceSlide__SlideContentContainer',
  componentId: 'sc-7pna0i-1'
})(['align-items:center;display:flex;justify-content:center;flex-direction:column;position:absolute;width:100%;height:100%;top:0;left:0;margin-top:42px;opacity:', ';transition:opacity 0.1s linear;', ''], function (props) {
  return props.opacity;
}, _RollsRoyce2.default.min.medium(_templateObject));

var Header = _styledComponents2.default.h1.withConfig({
  displayName: 'RollsRoyceSlide__Header',
  componentId: 'sc-7pna0i-2'
})(['color:#fff;font-family:\'RivieraNights-Light\';font-size:40px;font-weight:normal;text-transform:uppercase;margin:0;line-height:36px;letter-spacing:2px;', ''], _RollsRoyce2.default.min.medium(_templateObject2));

var Subtitle = _styledComponents2.default.h2.withConfig({
  displayName: 'RollsRoyceSlide__Subtitle',
  componentId: 'sc-7pna0i-3'
})(['color:#fff;font-family:\'RivieraNights-Medium\';font-size:17px;font-weight:normal;text-transform:uppercase;margin:0;line-height:30px;letter-spacing:3px;', ' ', ''], _RollsRoyce2.default.min.medium(_templateObject3), _RollsRoyce2.default.min.large(_templateObject4));

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceSlide__ButtonContainer',
  componentId: 'sc-7pna0i-4'
})(['margin:20px 0;', ''], _RollsRoyce2.default.min.medium(_templateObject5));

var RollsRoyceSlide = function RollsRoyceSlide(_ref) {
  var dispatch = _ref.dispatch,
      mediaContent = _ref.mediaContent,
      onLinkClick = _ref.onLinkClick,
      _ref$preview = _ref.preview,
      preview = _ref$preview === undefined ? false : _ref$preview,
      translations = _ref.translations,
      url = _ref.url,
      primaryCTA = _ref.primaryCTA;

  var _useNavigation = (0, _navigation2.default)(),
      navigateInternalUrl = _useNavigation.navigateInternalUrl;

  var _useState = (0, _react.useState)(1),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      opacity = _useState2[0],
      setOpacity = _useState2[1];

  (0, _react.useEffect)(function () {
    var handleScroll = function handleScroll() {
      var scrollTop = window.scrollY;
      var fadeStart = 0;
      var fadeEnd = 100;
      var newOpacity = 1 - Math.min(1, Math.max(0, (scrollTop - fadeStart) / (fadeEnd - fadeStart)));
      setOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);

    return function () {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  var navigate = function navigate(e, url) {
    e.preventDefault();
    navigateInternalUrl(url);
  };

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      SlideContainer,
      { dispatch: dispatch },
      mediaContent.type === 'image' && _react2.default.createElement(_Image2.default, {
        url: url,
        preview: preview,
        mediaContent: mediaContent,
        onLinkClick: onLinkClick,
        mask: true
      }),
      mediaContent.type === 'video' && _react2.default.createElement(_Video2.default, { url: url, preview: preview, mediaContent: mediaContent, onLinkClick: onLinkClick }),
      _react2.default.createElement(
        _ContentWrapper2.default,
        null,
        _react2.default.createElement(
          SlideContentContainer,
          { opacity: opacity },
          translations.header && _react2.default.createElement(
            Header,
            null,
            translations.header
          ),
          translations.subHeader && _react2.default.createElement(
            Subtitle,
            null,
            translations.subHeader
          ),
          _react2.default.createElement(
            ButtonContainer,
            null,
            _react2.default.createElement(_Button2.default, {
              variant: 'tertiary',
              text: translations.primaryCTA,
              onClick: function onClick(e) {
                navigate(e, '' + primaryCTA.url);
              },
              styleOverride: function styleOverride() {
                return '\n                  height:46px; \n                  min-width:276px;\n                  font-family: \'RivieraNights-Medium\';\n              \n                  & span {\n                    letter-spacing: 2px;\n                    text-transform: uppercase;\n                    font-weight: 500;\n                  }\n                ';
              }
            })
          )
        )
      ),
      _react2.default.createElement(_reactWaypoint2.default, {
        dispatch: dispatch,
        topOffset: '20%',
        onPositionChange: function onPositionChange(props) {
          return dispatch(_sessionPreferences.actions.updateSessionPreferences('isHeroVisible', props.currentPosition === 'inside'));
        }
      })
    )
  );
};

exports.default = RollsRoyceSlide;