'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchResults = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 40px;\n  '], ['\n    margin-bottom: 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n    width: 100%;\n    position: sticky;\n    top: 0;\n    background-color: #FFF;\n    z-index: 1;\n  '], ['\n    display: block;\n    width: 100%;\n    position: sticky;\n    top: 0;\n    background-color: #FFF;\n    z-index: 1;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n     margin: 40px 0 20px;\n  '], ['\n     margin: 40px 0 20px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n     font-size: 16px;\n  '], ['\n     font-size: 16px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

var _AppShared = require('../../../types/AppShared');

var _searchResultsTypes = require('../../../types/Genesis/searchResultsTypes');

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _Spinner = require('../../Spinner');

var _SelectBar = require('../SelectBar');

var _SelectBar2 = _interopRequireDefault(_SelectBar);

var _CantFindWhatLookingFor = require('./CantFindWhatLookingFor');

var _CantFindWhatLookingFor2 = _interopRequireDefault(_CantFindWhatLookingFor);

var _FiltersContainer = require('./Filters/FiltersContainer');

var _FiltersContainer2 = _interopRequireDefault(_FiltersContainer);

var _MobileFilters = require('./Filters/MobileFilters');

var _MobileFilters2 = _interopRequireDefault(_MobileFilters);

var _VehicleResults = require('./VehicleResults');

var _VehicleResults2 = _interopRequireDefault(_VehicleResults);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Container',
  componentId: 'sc-1n1985z-0'
})(['display:flex;flex-direction:column;width:100%;align-items:center;margin:0 auto;max-width:1920px;', ';', ''], function () {
  return _Genesis.mixins.pageGuttering();
}, _Genesis2.default.min.large(_templateObject));

var Grid = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Grid',
  componentId: 'sc-1n1985z-1'
})(['@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){min-height:100%;}width:100%;display:flex;justify-content:space-between;flex-direction:row;']);

var FiltersColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__FiltersColumn',
  componentId: 'sc-1n1985z-2'
})(['font-family:GenesisSansTextGlobal-Regular;display:flex;flex-direction:column;width:270px;margin-right:80px;min-width:270px;', ''], _Genesis2.default.max.medium(_templateObject2));

var GridResultColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__GridResultColumn',
  componentId: 'sc-1n1985z-3'
})(['display:flex;flex-direction:column;width:100%;']);

var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__MobileOnly',
  componentId: 'sc-1n1985z-4'
})(['display:none;', ';'], _Genesis2.default.max.medium(_templateObject3));

var ResultsSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__ResultsSectionWrapper',
  componentId: 'sc-1n1985z-5'
})(['min-height:1px;']);

var VehicleResultsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__VehicleResultsWrapper',
  componentId: 'sc-1n1985z-6'
})(['']);

var FiltersContainerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__FiltersContainerWrapper',
  componentId: 'sc-1n1985z-7'
})(['display:flex;flex-direction:column;margin-top:0px;']);

var SearchResultsTopBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__SearchResultsTopBarContainer',
  componentId: 'sc-1n1985z-8'
})(['align-items:center;display:flex;margin:20px 0;', ''], _Genesis2.default.min.medium(_templateObject4));

var VehicleCountTitle = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__VehicleCountTitle',
  componentId: 'sc-1n1985z-9'
})(['color:#fff;font-size:14px;font-family:\'GenesisSansHeadGlobal-Regular\';flex-grow:1;text-transform:uppercase;', ''], _Genesis2.default.min.medium(_templateObject5));

var SelectBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__SelectBarContainer',
  componentId: 'sc-1n1985z-10'
})(['display:flex;', ''], _Genesis2.default.max.medium(_templateObject2));

var ResultsSection = function ResultsSection(_ref) {
  var config = _ref.config,
      translations = _ref.translations,
      results = _ref.results,
      onMoreInfoClick = _ref.onMoreInfoClick,
      onPageNumberChange = _ref.onPageNumberChange,
      marketInfo = _ref.marketInfo,
      currentPage = _ref.currentPage,
      pageSize = _ref.pageSize,
      totalResults = _ref.totalResults,
      onEnquireClick = _ref.onEnquireClick,
      getSimilarCars = _ref.getSimilarCars,
      shared = _ref.shared;
  return _react2.default.createElement(
    ResultsSectionWrapper,
    null,
    _react2.default.createElement(
      VehicleResultsWrapper,
      null,
      _react2.default.createElement(_VehicleResults2.default, {
        config: config,
        results: results,
        onMoreInfoClick: onMoreInfoClick,
        onEnquireClick: onEnquireClick,
        translations: translations,
        locale: marketInfo.locale,
        currentPage: currentPage,
        pageSize: pageSize,
        totalResults: totalResults,
        onPageNumberChange: onPageNumberChange,
        getSimilarCars: getSimilarCars,
        shared: shared
      }),
      _react2.default.createElement(_CantFindWhatLookingFor2.default, { config: config, translations: translations })
    )
  );
};

var Filters = function Filters(_ref2) {
  var filtersConfig = _ref2.filtersConfig,
      globalStyling = _ref2.globalStyling,
      toggle = _ref2.toggle,
      marketInfo = _ref2.marketInfo,
      shared = _ref2.shared,
      translations = _ref2.translations;
  return _react2.default.createElement(_FiltersContainer2.default, (0, _extends3.default)({}, filtersConfig, {
    globalStyling: globalStyling,
    locale: marketInfo.locale,
    toggle: toggle,
    currencyCode: marketInfo.currencyCode,
    marketInfo: marketInfo,
    shared: shared,
    searchCountryCode: marketInfo.searchCountryCode,
    hideSearch: true,
    translations: translations
  }));
};

var SearchResults = exports.SearchResults = function SearchResults(props) {
  var config = props.config,
      filtersConfig = props.filtersConfig,
      translations = props.translations,
      marketInfo = props.marketInfo,
      loading = props.loading,
      filtersLoaded = props.filtersLoaded,
      totalResults = props.totalResults,
      shared = props.shared;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      displayMobileFilterOptions = _useState2[0],
      setDisplayMobileFilterOptions = _useState2[1];

  var globalStyling = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling);

  var _toggle = function _toggle() {
    return setDisplayMobileFilterOptions(function (state) {
      return !state;
    });
  };

  var filters = _react2.default.createElement(Filters, {
    filtersConfig: filtersConfig,
    globalStyling: globalStyling,
    toggle: function toggle() {
      return _toggle();
    },
    marketInfo: marketInfo,
    shared: shared,
    displayMobileFilterOptions: displayMobileFilterOptions,
    translations: translations
  });

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      MobileOnly,
      null,
      _react2.default.createElement(_MobileFilters2.default, {
        filters: filters,
        translations: translations,
        shared: shared,
        displayMobileFilterOptions: displayMobileFilterOptions,
        filtersCloseIcon: config.filtersCloseIcon,
        searchSort: shared.sessionPreferences.searchSort,
        currencies: config.currencies,
        hideSelectBar: config.hideSelectBar
      })
    ),
    _react2.default.createElement(
      Container,
      { 'data-cy': 'search-results' },
      _react2.default.createElement(
        _Spinner.SpinnerWrapper,
        {
          loaded: filtersLoaded,
          globalStyling: globalStyling,
          label: translations.loadingText
        },
        function () {
          return _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(
              Grid,
              null,
              _react2.default.createElement(
                FiltersColumn,
                null,
                _react2.default.createElement(
                  FiltersContainerWrapper,
                  null,
                  filters
                )
              ),
              _react2.default.createElement(
                GridResultColumn,
                { 'data-cy': 'search-result-container-grid-column' },
                _react2.default.createElement(
                  _Spinner.SpinnerWrapper,
                  {
                    loaded: !loading,
                    globalStyling: globalStyling,
                    label: translations.loadingText
                  },
                  function () {
                    return _react2.default.createElement(
                      _react.Fragment,
                      null,
                      _react2.default.createElement(
                        SearchResultsTopBarContainer,
                        null,
                        _react2.default.createElement(
                          VehicleCountTitle,
                          null,
                          (filtersLoaded || !loading) && totalResults !== null && totalResults > 0 && _react2.default.createElement(
                            _react2.default.Fragment,
                            null,
                            totalResults + ' ' + translations.totalCount
                          )
                        ),
                        _react2.default.createElement(
                          SelectBarContainer,
                          null,
                          !config.hideSelectBar && _react2.default.createElement(_SelectBar2.default, {
                            searchSort: shared.sessionPreferences.searchSort,
                            currencyValue: shared.sessionPreferences.currency,
                            currencies: config.currencies,
                            translations: config.translations
                          })
                        )
                      ),
                      _react2.default.createElement(ResultsSection, props)
                    );
                  }
                )
              )
            )
          );
        }
      )
    )
  );
};

exports.default = SearchResults;