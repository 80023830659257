'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    border-bottom: 1px solid rgba(59, 59, 59, 0.4);\n    padding: 10px 0;\n  '], ['\n    border-bottom: 1px solid rgba(59, 59, 59, 0.4);\n    padding: 10px 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin-right: 60px;\n  '], ['\n    margin-right: 60px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__Container',
  componentId: 'sc-rk8xtd-0'
})(['display:flex;flex-wrap:wrap;border-top:1px solid rgba(59,59,59,0.4);padding:10px 12px;flex-wrap:nowrap;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject));

var EmissionsItemWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionsItemWrapper',
  componentId: 'sc-rk8xtd-1'
})(['display:flex;flex-direction:column;margin-right:auto;']);

var EmissionsItem = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionsItem',
  componentId: 'sc-rk8xtd-2'
})(['color:#151515;font-family:\'RivieraNights-Regular\';font-size:12px;font-weight:400;line-height:normal;letter-spacing:0.5px;width:100%;margin-bottom:6px;&:last-child{margin-bottom:0;}']);

var EfficiencyLabelWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EfficiencyLabelWrapper',
  componentId: 'sc-rk8xtd-3'
})(['display:flex;flex-direction:column;max-width:90px;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject2));

var Emissions = function Emissions(props) {
  var emissionsData = props.emissionsData,
      consumptionsData = props.consumptionsData,
      efficiency = props.efficiency,
      emissionsClass = props.emissionsClass,
      translations = props.translations,
      config = props.config;
  var consumptionCombined = translations.consumptionCombined,
      co2EmissionsCombined = translations.co2EmissionsCombined,
      emissionsClassLabel = translations.emissionsClassLabel;


  if (emissionsData.wltpEmissionsCombined || emissionsClass || consumptionsData.wltpConsumptionCombined || efficiency || efficiency && config.energyEfficiencyRatingMedia[efficiency]) {
    var fuelConsumptionLabel = consumptionsData.wltpConsumptionCombined ? consumptionCombined + ': ' + consumptionsData.wltpConsumptionCombined + ' l/100km (WLTP)' : consumptionCombined + ':  - l/1000km (WLTP)';

    return _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        EmissionsItemWrapper,
        null,
        consumptionsData.wltpConsumptionCombined && _react2.default.createElement(
          EmissionsItem,
          null,
          fuelConsumptionLabel
        ),
        emissionsData.wltpEmissionsCombined && _react2.default.createElement(
          EmissionsItem,
          null,
          co2EmissionsCombined + ': ' + (emissionsData.wltpEmissionsCombined || '-') + ' g/km (WLTP)'
        ),
        emissionsClass && _react2.default.createElement(
          EmissionsItem,
          null,
          emissionsClassLabel,
          ': ',
          emissionsClass
        )
      ),
      efficiency && config.energyEfficiencyRatingMedia[efficiency] && _react2.default.createElement(
        EfficiencyLabelWrapper,
        null,
        _react2.default.createElement('img', {
          src: config.energyEfficiencyRatingMedia[efficiency],
          alt: efficiency
        })
      )
    );
  } else {
    return null;
  }
};

exports.default = Emissions;