'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 5% 0;\n  '], ['\n    margin: 0 5% 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Carousel = require('../Carousel');

var _Carousel2 = _interopRequireDefault(_Carousel);

var _View = require('../JaguarIcons/VDP/View360');

var _View2 = _interopRequireDefault(_View);

var _Play = require('../JaguarIcons/VDP/Play');

var _Play2 = _interopRequireDefault(_Play);

var _Eye = require('../JaguarIcons/VDP/Eye');

var _Eye2 = _interopRequireDefault(_Eye);

var _VehicleFilmStrip = require('./VehicleFilmStrip');

var _VehicleFilmStrip2 = _interopRequireDefault(_VehicleFilmStrip);

var _Magnify = require('../JaguarIcons/VDP/Magnify');

var _Magnify2 = _interopRequireDefault(_Magnify);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ModalOverlay = require('./ModalOverlay');

var _ModalOverlay2 = _interopRequireDefault(_ModalOverlay);

var _parseYouTubeId = require('../YouTubePlayer/parseYouTubeId');

var _parseYouTubeId2 = _interopRequireDefault(_parseYouTubeId);

var _useVehicleGallery2 = require('./useVehicleGallery');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Footer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGallery__Footer',
  componentId: 'sc-1yyocou-0'
})(['height:55px;', ';', ';', ';display:flex;align-items:center;justify-content:space-between;padding-bottom:10px;', ';'], function (_ref) {
  var horizontalPadding = _ref.horizontalPadding;
  return horizontalPadding && 'padding: ' + horizontalPadding + 'px 0';
}, function (_ref2) {
  var backgroundColour = _ref2.backgroundColour;
  return backgroundColour ? 'background: ' + backgroundColour.value : 'background: #000000';
}, function (_ref3) {
  var border = _ref3.border;
  return border && 'border: ' + border.size + 'px ' + border.type + ' ' + border.colour;
}, _theme2.default.max.large(_templateObject));

var FooterButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGallery__FooterButtonContainer',
  componentId: 'sc-1yyocou-1'
})(['border-left:1px solid #acacac;padding:0 10px;&:hover{cursor:pointer;}']);

var Counter = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGallery__Counter',
  componentId: 'sc-1yyocou-2'
})(['text-align:center;color:', ';font-size:15px;display:flex;justify-content:end;white-space:nowrap;padding-right:10px;', ';'], function (_ref4) {
  var fontColour = _ref4.fontColour;
  return fontColour ? '' + fontColour.value : '#FFFFFF';
}, _theme2.default.max.medium(_templateObject2, function (_ref5) {
  var hideZoomMobile = _ref5.hideZoomMobile;
  return hideZoomMobile && ' margin-left: 8px;';
}));

var ViewsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGallery__ViewsContainer',
  componentId: 'sc-1yyocou-3'
})(['opacity:1;color:#fff;margin-right:8px;']);
var ViewsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGallery__ViewsWrapper',
  componentId: 'sc-1yyocou-4'
})(['padding-left:20px;display:flex;align-items:center;']);

var ButtonsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGallery__ButtonsWrapper',
  componentId: 'sc-1yyocou-5'
})(['display:flex;justify-content:flex-end;padding-right:20px;']);

var FilmStripContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGallery__FilmStripContainer',
  componentId: 'sc-1yyocou-6'
})(['flex:1 0 0%;min-width:0;height:100%;position:relative;overflow-x:hidden;overflow-y:hidden;box-sizing:border-box;']);

var ThumbnailsScroller = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGallery__ThumbnailsScroller',
  componentId: 'sc-1yyocou-7'
})(['padding-top:5px;flex:1 0 0%;min-width:0;height:100%;width:100%;scroll-behavior:smooth;position:relative;', ';box-sizing:border-box;transition:', ';'], function (_ref6) {
  var direction = _ref6.direction;
  return direction === 'rtl' ? 'right: 0;' : 'left: 0;';
}, function (_ref7) {
  var direction = _ref7.direction;
  return direction === 'rtl' ? 'right 0.5s ease;' : 'left 0.5s ease;';
});

var MagnifyWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGallery__MagnifyWrapper',
  componentId: 'sc-1yyocou-8'
})(['cursor:pointer;margin:0 8px;', ';'], _theme2.default.max.medium(_templateObject2, function (_ref8) {
  var hideZoomMobile = _ref8.hideZoomMobile;
  return hideZoomMobile && 'display: none;';
}));

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleGallery__IconWrapper',
  componentId: 'sc-1yyocou-9'
})(['width:32px;height:32px;']);

var ArrowIconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleGallery__ArrowIconWrapper',
  componentId: 'sc-1yyocou-10'
})(['width:32px;height:24px;']);

var FlipperButton = _styledComponents2.default.button.withConfig({
  displayName: 'VehicleGallery__FlipperButton',
  componentId: 'sc-1yyocou-11'
})(['display:flex;justify-content:center;align-items:center;position:absolute;top:25%;border-radius:50%;background-color:rgba(0,0,0,0.8);color:rgba(255,255,255,0.8);height:30px;width:30px;border:0;z-index:66;cursor:pointer;', ';', ';', ';'], function (_ref9) {
  var left = _ref9.left;
  return left ? 'left: 8px' : 'right: 8px';
}, function (_ref10) {
  var direction = _ref10.direction;
  return direction === 'rtl' && 'transform: scaleX(-1);';
}, function (_ref11) {
  var arrowSize = _ref11.arrowSize;
  return arrowSize && 'font-size: ' + arrowSize + 'px;';
});

var VehicleGallery = function VehicleGallery(props) {
  var _props$config = props.config,
      config = _props$config === undefined ? {
    translations: {}
  } : _props$config,
      isLooping = props.isLooping,
      views = props.views,
      placeholdingImage = props.placeholdingImage,
      vehicleInfo = props.vehicleInfo,
      rotateGallery = props.rotateGallery,
      imageCarousel = props.imageCarousel,
      hideZoomMobile = props.hideZoomMobile,
      globalStyling = props.globalStyling,
      _props$globalStyling$ = props.globalStyling.colours.primaryIconColour;
  _props$globalStyling$ = _props$globalStyling$ === undefined ? {} : _props$globalStyling$;
  var _props$globalStyling$2 = _props$globalStyling$.value,
      iconColour = _props$globalStyling$2 === undefined ? '#ccc' : _props$globalStyling$2,
      translations = props.translations,
      vehicleGalleryArrowFont = props.vehicleGalleryArrowFont;


  var parsedYouTubeId = vehicleInfo.video ? (0, _parseYouTubeId2.default)(vehicleInfo.video) : null;

  var images = config.slides.map(function (slide) {
    return slide.image;
  });

  var _useVehicleGallery = (0, _useVehicleGallery2.useVehicleGallery)({
    rotateGallery: props.rotateGallery,
    direction: props.globalStyling.direction,
    slides: config.slides,
    placeholdingImage: placeholdingImage
  }),
      isOpen = _useVehicleGallery.isOpen,
      currentCarouselImage = _useVehicleGallery.currentCarouselImage,
      containerRef = _useVehicleGallery.containerRef,
      openLightbox = _useVehicleGallery.openLightbox,
      closeLightbox = _useVehicleGallery.closeLightbox,
      goToSlide = _useVehicleGallery.goToSlide,
      handleThumbnailClick = _useVehicleGallery.handleThumbnailClick,
      nextLeftImageIndex = _useVehicleGallery.nextLeftImageIndex,
      nextRightImageIndex = _useVehicleGallery.nextRightImageIndex;

  var carouselProps = {
    hideZoomMobile: hideZoomMobile,
    slides: config.slides.map(function (slide) {
      return (0, _extends3.default)({}, slide, { component: _Carousel.Slide });
    }),
    isLooping: isLooping,
    rotate: rotateGallery,
    onClickNext: function onClickNext() {
      return goToSlide(currentCarouselImage + 1);
    },
    onClickPrev: function onClickPrev() {
      return goToSlide(currentCarouselImage - 1);
    },
    slideIndex: currentCarouselImage,
    arrowRight: imageCarousel && imageCarousel.arrowRight,
    arrowLeft: imageCarousel && imageCarousel.arrowLeft,
    icon360: imageCarousel && imageCarousel.icon360,
    enable360View: imageCarousel && imageCarousel.enable360View,
    imageHeight: imageCarousel && imageCarousel.imageHeight,
    video: config.video,
    close360Icon: imageCarousel && imageCarousel.close360Icon,
    vehicleInfo: vehicleInfo,
    globalStyling: globalStyling,
    translations: translations,
    imageCarousel: imageCarousel,
    parsedYouTubeId: parsedYouTubeId
  };

  var leftArrow = function leftArrow() {
    return imageCarousel && imageCarousel.arrowLeft ? _react2.default.createElement(ArrowIconWrapper, { src: imageCarousel.arrowLeft, alt: 'Left arrow' }) : '<';
  };
  var rightArrow = function rightArrow() {
    return imageCarousel && imageCarousel.arrowRight ? _react2.default.createElement(ArrowIconWrapper, { src: imageCarousel.arrowRight, alt: 'Right arrow' }) : '>';
  };

  var magnifyRef = (0, _react.useRef)();
  var focusOnMagnify = function focusOnMagnify() {
    magnifyRef.current.focus();
  };

  return _react2.default.createElement(
    _react.Fragment,
    null,
    isOpen && _react2.default.createElement(
      _ModalOverlay2.default,
      {
        close: function close() {
          closeLightbox();
          focusOnMagnify();
        },
        config: config,
        modalWidth: '80%',
        direction: globalStyling.direction
      },
      _react2.default.createElement(_Carousel2.default, (0, _extends3.default)({}, carouselProps, { fullscreen: true }))
    ),
    _react2.default.createElement(_Carousel2.default, (0, _extends3.default)({}, carouselProps, {
      renderFooter: function renderFooter(_ref12) {
        var showMedia = _ref12.showMedia;
        return _react2.default.createElement(
          Footer,
          {
            backgroundColour: imageCarousel && imageCarousel.footerBackgroundColour,
            border: imageCarousel && imageCarousel.borderFooter,
            horizontalPadding: imageCarousel && imageCarousel.horizontalPadding
          },
          imageCarousel && !imageCarousel.hideViews && _react2.default.createElement(
            ViewsContainer,
            null,
            _react2.default.createElement(
              ViewsWrapper,
              null,
              _react2.default.createElement(_Eye2.default, { width: '2em', height: '2em', colour: '#fff' }),
              _react2.default.createElement(
                'span',
                null,
                views
              )
            )
          ),
          _react2.default.createElement(
            FilmStripContainer,
            null,
            _react2.default.createElement(
              FlipperButton,
              {
                'aria-label': translations.leftArrowAlternativeText,
                arrowSize: vehicleGalleryArrowFont && vehicleGalleryArrowFont.fontSize,
                left: true,
                onClick: function onClick() {
                  goToSlide(nextLeftImageIndex);
                },
                onKeyDown: function onKeyDown(e) {
                  return e.key === 'Enter' && goToSlide(nextLeftImageIndex);
                },
                direction: globalStyling.direction
              },
              leftArrow()
            ),
            _react2.default.createElement(
              ThumbnailsScroller,
              {
                ref: containerRef,
                direction: globalStyling.direction
              },
              _react2.default.createElement(_VehicleFilmStrip2.default, {
                height: 45,
                imageUrls: images,
                onImageClick: handleThumbnailClick,
                selectedImage: currentCarouselImage,
                alternativeText: vehicleInfo.name
              })
            ),
            _react2.default.createElement(
              FlipperButton,
              {
                'aria-label': translations.rightArrowAlternativeText,
                arrowSize: vehicleGalleryArrowFont && vehicleGalleryArrowFont.fontSize,
                onClick: function onClick() {
                  goToSlide(nextRightImageIndex);
                },
                onKeyDown: function onKeyDown(e) {
                  return e.key === 'Enter' && goToSlide(nextRightImageIndex);
                },
                direction: globalStyling.direction
              },
              rightArrow()
            )
          ),
          _react2.default.createElement(
            MagnifyWrapper,
            {
              ref: magnifyRef,
              hideZoomMobile: hideZoomMobile,
              onClick: openLightbox,
              tabIndex: '0',
              onKeyDown: function onKeyDown(e) {
                if (e.key === 'Enter') {
                  openLightbox();
                }
              },
              'aria-label': translations.openGalleryIconAlternativeText || ''
            },
            imageCarousel && imageCarousel.zoomIcon ? _react2.default.createElement(IconWrapper, {
              src: imageCarousel.zoomIcon,
              alt: translations.openGalleryIconAlternativeText || ''
            }) : _react2.default.createElement(_Magnify2.default, { width: '30px', height: '30px', alt: 'Hola' })
          ),
          _react2.default.createElement(
            Counter,
            {
              hideZoomMobile: hideZoomMobile,
              fontColour: imageCarousel && imageCarousel.counterColour
            },
            currentCarouselImage + 1 + ' / ' + images.length
          ),
          _react2.default.createElement(
            ButtonsWrapper,
            null,
            parsedYouTubeId && _react2.default.createElement(
              FooterButtonContainer,
              { onClick: function onClick() {
                  return showMedia('video');
                } },
              _react2.default.createElement(_Play2.default, { width: '2em', height: '2em', colour: iconColour })
            ),
            vehicleInfo.media && (vehicleInfo.media.exterior || vehicleInfo.media.interior) ? _react2.default.createElement(
              FooterButtonContainer,
              { onClick: function onClick() {
                  return showMedia('view360');
                } },
              _react2.default.createElement(_View2.default, { width: '2em', height: '2em', colour: iconColour })
            ) : null
          )
        );
      }
    }))
  );
};

exports.default = VehicleGallery;