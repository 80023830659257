'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VehicleMainInfoAndPrice = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 10px;\n  '], ['\n    margin-top: 10px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding-left: 20px\n  '], ['\n    padding-left: 20px\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useShortListVehicle2 = require('../../../hooks/useShortListVehicle');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../Global');

var _StyledIcon = require('../../StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _CarfaxLogo = require('../CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

var _VdpRetailerLocation = require('./VdpRetailerLocation');

var _VdpRetailerLocation2 = _interopRequireDefault(_VdpRetailerLocation);

var _VdpVehiclePrice = require('./VdpVehiclePrice');

var _VdpVehiclePrice2 = _interopRequireDefault(_VdpVehiclePrice);

var _VehicleSubHeader = require('./VehicleSubHeader');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleMainInfoAndPrice__Container',
  componentId: 'sc-r2wzxy-0'
})(['width:100%;margin-top:40px;display:flex;flex-direction:row;justify-content:space-between;align-items:center;', ';'], _theme2.default.max.medium(_templateObject));

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleMainInfoAndPrice__Title',
  componentId: 'sc-r2wzxy-1'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.max.medium(_templateObject2));

var ShortlistIconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleMainInfoAndPrice__ShortlistIconContainer',
  componentId: 'sc-r2wzxy-2'
})(['margin-right:30px;']);

var CollectionsLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleMainInfoAndPrice__CollectionsLabel',
  componentId: 'sc-r2wzxy-3'
})(['display:flex;padding-top:8px;padding-bottom:8px;line-height:16px;text-transform:uppercase;', ';color:#281432;', ';font-family:RivieraNights-Medium,Jost-Light;'], function (_ref) {
  var letterSpacing = _ref.letterSpacing;
  return letterSpacing ? 'letter-spacing: 0.57px' : 'letter-spacing: 2px';
}, function (_ref2) {
  var font = _ref2.font;
  return font ? 'font-size: 12px' : 'font-size: 16px';
});

var VehicleMainInfoAndPrice = exports.VehicleMainInfoAndPrice = function VehicleMainInfoAndPrice(props) {
  var vehicleInfo = props.vehicleInfo,
      title = props.title,
      subHeaderType = props.subHeaderType,
      showOdometerInformation = props.showOdometerInformation,
      config = props.config,
      globalStyling = props.globalStyling,
      translations = props.translations,
      handleZeroPriceAsText = props.handleZeroPriceAsText,
      badge = props.badge,
      handlePrice = props.handlePrice;
  var icons = globalStyling.icons;
  var subHeaderFont = config.subHeaderFont,
      headerFont = config.headerFont;

  var _useShortListVehicle = (0, _useShortListVehicle2.useShortListVehicle)({
    vehicleId: vehicleInfo.id
  }),
      isShortlisted = _useShortListVehicle.isShortlisted,
      toggleShortlist = _useShortListVehicle.toggleShortlist;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _Global.DesktopOnly,
      null,
      _react2.default.createElement(
        Container,
        { 'data-cy': 'vehicle-title-description' },
        _react2.default.createElement(
          Title,
          null,
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n            margin: 0;\n            ' + (0, _Global.fontStyleOverride)(headerFont) + '\n          ';
              }
            },
            title
          ),
          vehicleInfo.modelCollectionName && _react2.default.createElement(
            CollectionsLabel,
            null,
            vehicleInfo.modelCollectionName
          ),
          _react2.default.createElement(_VehicleSubHeader.VehicleSubHeader, {
            subHeaderType: subHeaderType,
            subHeaderFont: subHeaderFont,
            vehicleInfo: vehicleInfo,
            showOdometerInformation: showOdometerInformation,
            translations: translations,
            badge: badge
          })
        ),
        _react2.default.createElement(
          'div',
          { style: { display: 'flex' } },
          vehicleInfo.carfax && _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicleInfo.carfax }),
          _react2.default.createElement(_VdpVehiclePrice2.default, {
            vehicleInfo: vehicleInfo,
            headerFont: headerFont,
            translations: translations,
            config: config,
            handlePrice: handlePrice,
            handleZeroPriceAsText: handleZeroPriceAsText
          })
        )
      )
    ),
    _react2.default.createElement(
      _Global.MobileOnly,
      null,
      _react2.default.createElement(
        Container,
        { 'data-cy': 'vehicle-title-description' },
        _react2.default.createElement(
          Title,
          null,
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n                margin: 0;\n                ' + (0, _Global.fontStyleOverride)(headerFont) + '\n              ';
              },
              maxMediumMobileStyleOverride: function maxMediumMobileStyleOverride() {
                return '\n                font-size: 18px;\n              ';
              }
            },
            title
          ),
          vehicleInfo.modelCollectionName && _react2.default.createElement(
            CollectionsLabel,
            { font: true, letterSpacing: true },
            vehicleInfo.modelCollectionName
          ),
          _react2.default.createElement(_VehicleSubHeader.VehicleSubHeader, {
            subHeaderType: subHeaderType,
            subHeaderFont: subHeaderFont,
            vehicleInfo: vehicleInfo,
            showOdometerInformation: showOdometerInformation,
            translations: translations,
            badge: badge
          }),
          _react2.default.createElement(_VdpRetailerLocation2.default, {
            config: config,
            globalStyling: globalStyling,
            retailerName: vehicleInfo.retailerInformation && vehicleInfo.retailerInformation.name
          })
        ),
        !config.hideCompareShortlist && _react2.default.createElement(
          ShortlistIconContainer,
          {
            onClick: function onClick() {
              return toggleShortlist(vehicleInfo);
            }
          },
          _react2.default.createElement(_StyledIcon2.default, {
            link: isShortlisted ? icons.heartselected : icons.heart,
            width: 24,
            height: 24
          })
        )
      )
    )
  );
};