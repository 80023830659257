'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n  '], ['\n    flex-direction: row;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ComponentContext = require('../../renderer/ComponentContext');

var _ComponentContext2 = _interopRequireDefault(_ComponentContext);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _WindowDimensionsProvider = require('../../shared/WindowDimensionsProvider');

var _WindowDimensionsProvider2 = _interopRequireDefault(_WindowDimensionsProvider);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Band__Wrapper',
  componentId: 'sc-1jgfb17-0'
})(['width:100%;height:100%;']);

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Band__ContentWrapper',
  componentId: 'sc-1jgfb17-1'
})(['max-width:', ';display:flex;flex-direction:column;justify-content:center;align-content:stretch;align-items:stretch;margin:0 auto;*{box-sizing:border-box;}', ';'], function (_ref) {
  var contain = _ref.contain,
      contentWidth = _ref.contentWidth;
  return contain ? contentWidth + 'px;' : '100%;';
}, _theme2.default.min.medium(_templateObject));
// TODO fix types/interfaces

exports.default = function (_ref2) {
  var band = _ref2.band,
      globalStyling = _ref2.globalStyling;
  var _band$config = band.config,
      pods = _band$config.pods,
      contain = _band$config.contain;

  var _useContext = (0, _react.useContext)(_ComponentContext2.default),
      availablePods = _useContext.pods;

  var pod1 = (0, _reactRedux.useSelector)(function (s) {
    return s.config.pods[pods[0]];
  });
  var pod2 = (0, _reactRedux.useSelector)(function (s) {
    return s.config.pods[pods[1]];
  });

  var pod3 = (0, _reactRedux.useSelector)(function (s) {
    return s.config.pods[pods[2]];
  });
  var pod4 = (0, _reactRedux.useSelector)(function (s) {
    return s.config.pods[pods[3]];
  });

  var dispatch = (0, _reactRedux.useDispatch)();
  var podsToRender = [pod1, pod2, pod3, pod4].filter(Boolean);

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      _WindowDimensionsProvider2.default,
      null,
      _react2.default.createElement(
        ContentWrapper,
        {
          contain: contain,
          contentWidth: globalStyling.contentWidth || 1366
        },
        podsToRender.map(function (p) {
          var Pod = availablePods[p.id];
          return _react2.default.createElement(
            _react.Fragment,
            { key: p.instanceId },
            _react2.default.createElement(Pod, (0, _extends3.default)({}, p, { dispatch: dispatch, globalStyling: globalStyling }))
          );
        })
      )
    )
  );
};