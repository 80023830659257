'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var animateFadeIn = (0, _styledComponents.keyframes)(['from{opacity:0;}to{opacity:1;}']);

var animateFadeOut = (0, _styledComponents.keyframes)(['from{opacity:1;}to{opacity:0;}']);

var FadeIn = _styledComponents2.default.div.withConfig({
  displayName: 'Fade__FadeIn',
  componentId: 'sc-9idja3-0'
})(['opacity:1;animation:', ' 0.5s linear;'], animateFadeIn);

var FadeOut = _styledComponents2.default.div.withConfig({
  displayName: 'Fade__FadeOut',
  componentId: 'sc-9idja3-1'
})(['opacity:0;animation:', ' 0.5s linear;display:', ';'], animateFadeOut, function (_ref) {
  var removeFadeOut = _ref.removeFadeOut;
  return removeFadeOut ? 'none' : 'block';
});

var Fade = function (_Component) {
  (0, _inherits3.default)(Fade, _Component);

  function Fade(props) {
    (0, _classCallCheck3.default)(this, Fade);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Fade.__proto__ || (0, _getPrototypeOf2.default)(Fade)).call(this, props));

    Object.defineProperty(_this, 'componentDidUpdate', {
      enumerable: true,
      writable: true,
      value: function value(prevProps) {
        if (prevProps.display !== _this.props.display && !_this.state.fadeOut && _this.props.display) {
          _this.setState({
            removeFadeOut: false
          });
        }
      }
    });
    Object.defineProperty(_this, 'setFadeOutNone', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.setState({
          removeFadeOut: true
        });
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            children = _this$props.children,
            display = _this$props.display;


        return display ? _react2.default.createElement(
          FadeIn,
          null,
          children
        ) : _react2.default.createElement(
          FadeOut,
          {
            removeFadeOut: _this.state.removeFadeOut,
            onAnimationEnd: function onAnimationEnd() {
              return _this.setFadeOutNone();
            }
          },
          children
        );
      }
    });

    _this.state = {
      removeFadeOut: false
    };
    return _this;
  }

  return Fade;
}(_react.Component);

exports.default = Fade;