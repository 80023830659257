'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Icon;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('../../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Icon(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _components.IconWrapper,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 18 18', width: '1em', height: '1em' },
      _react2.default.createElement(
        'g',
        { fill: 'none', fillRule: 'evenodd' },
        _react2.default.createElement('path', { stroke: '#0065DB', d: 'M.5 1.5h17v15H.5z' }),
        _react2.default.createElement('path', { fill: '#D6D6D6', d: 'M2 15v-3h14v3z' }),
        _react2.default.createElement(
          'g',
          { transform: 'translate(6 3)' },
          _react2.default.createElement('path', {
            d: 'M2.857 8.406C4.762 5.76 5.714 3.91 5.714 2.857 5.714 1.28 4.286 0 2.857 0 1.43 0 0 1.28 0 2.857 0 3.91.952 5.76 2.857 8.407z',
            fill: '#0065DB'
          }),
          _react2.default.createElement('circle', { fill: '#FFF', cx: 2.857, cy: 2.857, r: 1.429 })
        )
      )
    )
  );
}

Icon.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};