'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: ', ';\n    overflow: unset;\n  '], ['\n    flex-direction: ', ';\n    overflow: unset;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: ', '%;\n    min-height: 500px;\n    height: auto;\n    background-color: #f0f0f0;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  '], ['\n    width: ', '%;\n    min-height: 500px;\n    height: auto;\n    background-color: #f0f0f0;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 60px;\n    margin: auto 0;\n  '], ['\n    padding: 0 60px;\n    margin: auto 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    min-height: 500px;\n    object-fit: cover;\n    height: 100%;\n  '], ['\n    min-height: 500px;\n    object-fit: cover;\n    height: 100%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _CarouselUnselected = require('./CarouselUnselected');

var _CarouselUnselected2 = _interopRequireDefault(_CarouselUnselected);

var _CarouselSelected = require('./CarouselSelected');

var _CarouselSelected2 = _interopRequireDefault(_CarouselSelected);

var _Global = require('../Global');

var _ContentWrapper = require('../ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Background = _styledComponents2.default.div.withConfig({
  displayName: 'TextImage__Background',
  componentId: 'sc-1l4bzo2-0'
})(['background-color:#f0f0f0;']);

var TextImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TextImageContainer'
}).withConfig({
  displayName: 'TextImage__TextImageContainer',
  componentId: 'sc-1l4bzo2-1'
})(['width:100%;display:flex;flex-direction:column-reverse;overflow:hidden;', ';'], _theme2.default.min.large(_templateObject, function (props) {
  return props.alignment;
}));
var TextImageContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'TextImage__TextImageContentWrapper',
  componentId: 'sc-1l4bzo2-2'
})(['width:100%;', ';'], _theme2.default.min.large(_templateObject2, function (props) {
  return props.contentWidth;
}));
var TextImageContent = _styledComponents2.default.div.withConfig({
  displayName: 'TextImage__TextImageContent',
  componentId: 'sc-1l4bzo2-3'
})(['padding:20px 5%;', ';'], _theme2.default.min.large(_templateObject3));
var TextImageImage = _styledComponents2.default.img.withConfig({
  displayName: 'TextImage__TextImageImage',
  componentId: 'sc-1l4bzo2-4'
})(['width:100%;', ';'], _theme2.default.min.large(_templateObject4));

var CarouselIndicatorsWapper = _styledComponents2.default.div.withConfig({
  displayName: 'CarouselIndicatorsWapper'
}).withConfig({
  displayName: 'TextImage__CarouselIndicatorsWapper',
  componentId: 'sc-1l4bzo2-5'
})(['z-index:100;position:relative;width:100%;bottom:60px;display:flex;justify-content:', ';'], function (props) {
  return props.flexJustify;
});

var CarouselIndicators = _styledComponents2.default.div.withConfig({
  displayName: 'CarouselIndicators'
}).withConfig({
  displayName: 'TextImage__CarouselIndicators',
  componentId: 'sc-1l4bzo2-6'
})(['display:flex;justify-content:space-between;width:', 'px;margin:0px auto;'], function (props) {
  return props.numberOfImages * 25;
});

var TextImage = function (_React$Component) {
  (0, _inherits3.default)(TextImage, _React$Component);

  function TextImage() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, TextImage);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = TextImage.__proto__ || (0, _getPrototypeOf2.default)(TextImage)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'state', {
      enumerable: true,
      writable: true,
      value: { counter: 0 }
    }), Object.defineProperty(_this, 'interval', {
      enumerable: true,
      writable: true,
      value: 0
    }), Object.defineProperty(_this, 'handleClick', {
      enumerable: true,
      writable: true,
      value: function value(index) {
        _this.setState({ counter: index });
        _this.resetInterval();
        _this.startInterval();
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(TextImage, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.startInterval();
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.resetInterval();
    }
  }, {
    key: 'startInterval',
    value: function startInterval() {
      var _this2 = this;

      this.interval = setInterval(function () {
        _this2.nextImage();
      }, 6000);
    }
  }, {
    key: 'resetInterval',
    value: function resetInterval() {
      clearInterval(this.interval);
    }
  }, {
    key: 'nextImage',
    value: function nextImage() {
      var counter = this.state.counter;

      var numImages = this.props.images.length;

      if (numImages === 1) return;

      this.setState({
        counter: (counter + 1) % numImages
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement(
        Background,
        null,
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: this.props.contentWidth },
          _react2.default.createElement(
            TextImageContainer,
            { alignment: this.props.alignment },
            _react2.default.createElement(
              TextImageContentWrapper,
              {
                contentWidth: 100 - this.props.imageWidthPercent
              },
              _react2.default.createElement(
                TextImageContent,
                null,
                _react2.default.createElement(
                  _Global.HeadingTwo,
                  {
                    styleOverride: function styleOverride() {
                      return '\n                    display: block;\n                    font-size: 20px;\n                    letter-spacing: 3px;\n                    text-transform: uppercase;\n                    padding-top: 50px;\n                    padding-bottom: 6px;\n                  ';
                    },
                    mobileStyleOverride: function mobileStyleOverride() {
                      return [{
                        queryPath: 'max.large',
                        template: 'display: none;'
                      }];
                    }
                  },
                  this.props.title
                ),
                _react2.default.createElement(
                  _Global.Paragraph,
                  {
                    styleOverride: function styleOverride() {
                      return '\n                    font-size: 15px;\n                    padding-bottom: 10px;\n                    line-height: 20px;\n                  ';
                    }
                  },
                  this.props.content
                )
              )
            ),
            _react2.default.createElement(
              TextImageContentWrapper,
              {
                contentWidth: this.props.imageWidthPercent
              },
              _react2.default.createElement(TextImageImage, { src: this.props.images[this.state.counter] }),
              this.props.images.length > 1 && _react2.default.createElement(
                CarouselIndicatorsWapper,
                {
                  flexJustify: this.props.alignment === 'row-reverse' ? 'flex-start' : 'flex-end'
                },
                _react2.default.createElement(
                  CarouselIndicators,
                  { numberOfImages: this.props.images.length },
                  this.props.images.map(function (image, index) {
                    return _react2.default.createElement(
                      'div',
                      {
                        role: 'none',
                        onClick: function onClick() {
                          return _this3.handleClick(index);
                        },
                        key: image
                      },
                      index === _this3.state.counter ? _react2.default.createElement(_CarouselSelected2.default, {
                        width: '12px',
                        height: '12px',
                        colour: _this3.props.iconColour
                      }) : _react2.default.createElement(_CarouselUnselected2.default, {
                        width: '12px',
                        height: '12px',
                        colour: _this3.props.iconColour
                      })
                    );
                  })
                )
              )
            )
          )
        )
      );
    }
  }]);
  return TextImage;
}(_react2.default.Component);

exports.default = TextImage;