'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 36px;\n    margin: 10px 0 40px;\n  '], ['\n    font-size: 36px;\n    margin: 10px 0 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-gap: 30px;\n  '], ['\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-gap: 30px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'PreownedBenefits__Wrapper',
  componentId: 'sc-14xi5fy-0'
})(['margin:40px auto;width:100%;max-width:1280px;', ';'], function () {
  return _Genesis.mixins.pageGuttering();
});

var SectionTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'PreownedBenefits__SectionTitle',
  componentId: 'sc-14xi5fy-1'
})(['color:#000;font-family:\'GenesisSansHeadGlobal-Light\';font-weight:normal;font-size:24px;margin:10px 0 20px;text-transform:uppercase;', ';'], _Genesis2.default.min.medium(_templateObject));

var ItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'PreownedBenefits__ItemContainer',
  componentId: 'sc-14xi5fy-2'
})(['', ';'], _Genesis2.default.min.medium(_templateObject2));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'PreownedBenefits__Item',
  componentId: 'sc-14xi5fy-3'
})(['margin-bottom:30px;']);

var ItemImg = _styledComponents2.default.img.withConfig({
  displayName: 'PreownedBenefits__ItemImg',
  componentId: 'sc-14xi5fy-4'
})(['width:100%;height:auto;']);

var ItemTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'PreownedBenefits__ItemTitle',
  componentId: 'sc-14xi5fy-5'
})(['font-family:\'GenesisSansHeadGlobal-Regular\';font-size:16px;font-weight:normal;text-transform:uppercase;']);

var ItemContent = _styledComponents2.default.p.withConfig({
  displayName: 'PreownedBenefits__ItemContent',
  componentId: 'sc-14xi5fy-6'
})(['font-size:14px;line-height:18px;']);

var ItemLinkText = _styledComponents2.default.a.withConfig({
  displayName: 'PreownedBenefits__ItemLinkText',
  componentId: 'sc-14xi5fy-7'
})(['font-family:\'GenesisSansHeadGlobal-Regular\';font-size:14px;cursor:pointer;color:#141414;text-transform:uppercase;letter-spacing:1.2px;margin-top:auto;padding-bottom:10px;transition:0.3s;text-decoration:underline;text-underline-offset:10px;&:hover{color:#af6249;}']);

var PreownedBenefits = function PreownedBenefits(_ref) {
  var config = _ref.config,
      translations = _ref.translations;

  var _useNavigation = (0, _navigation2.default)(),
      navigateTo = _useNavigation.navigateTo;

  var items = config.preownedBenefitsItems;

  return _react2.default.createElement(
    Wrapper,
    { id: 'Certified-Preowned-Benefits' },
    _react2.default.createElement(
      SectionTitle,
      null,
      translations.titleCertifiedPreownedBenefits
    ),
    _react2.default.createElement(
      ItemContainer,
      null,
      items.map(function (_ref2) {
        var imagePath = _ref2.imagePath,
            title = _ref2.title,
            body = _ref2.body,
            linkText = _ref2.linkText,
            linkURL = _ref2.linkURL,
            itemId = _ref2.itemId;
        return _react2.default.createElement(
          Item,
          { key: itemId },
          _react2.default.createElement(ItemImg, { src: imagePath }),
          config.translations['' + title] && _react2.default.createElement(
            ItemTitle,
            null,
            config.translations['' + title]
          ),
          config.translations['' + body] && _react2.default.createElement(
            ItemContent,
            null,
            config.translations['' + body]
          ),
          config.translations['' + linkURL] && _react2.default.createElement(
            ItemLinkText,
            {
              onClick: function onClick() {
                navigateTo(config.translations['' + linkURL]);
              }
            },
            config.translations['' + linkText]
          )
        );
      })
    )
  );
};

exports.default = PreownedBenefits;