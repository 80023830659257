'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var constants = exports.constants = {
  navigate: 'ROUTER_NAVIGATE',
  navigateWithPayload: 'ROUTER_NAVIGATE_WITH_PAYLOAD',
  navigateWithPayloadSuccess: 'ROUTER_NAVIGATE_WITH_PAYLOAD_SUCCESS',
  navigateWithQuery: 'ROUTER_NAVIGATE_WITH_QUERY',
  replace: 'ROUTER_REPLACE',
  navigateSuccess: 'ROUTER_NAVIGATE_SUCCESS',
  navigateWithFilters: 'ROUTER_NAVIGATE_WITH_FILTERS',
  historyCreated: 'HISTORY_CREATED',
  urlChangeNavigation: 'ROUTER_URL_CHANGE_NAVIGATION'
};

var actions = exports.actions = {
  navigate: function navigate(path) {
    return {
      type: constants.navigate,
      payload: path
    };
  },
  navigateWithPayload: function navigateWithPayload(path, data) {
    return {
      type: constants.navigateWithPayload,
      payload: {
        path: path,
        data: data
      }
    };
  },
  navigateWithQuery: function navigateWithQuery(path, queryParams) {
    return {
      type: constants.navigateWithQuery,
      payload: {
        path: path,
        queryParams: queryParams
      }
    };
  },
  navigateWithPayloadSuccess: function navigateWithPayloadSuccess(payload) {
    return {
      type: constants.navigateWithPayloadSuccess,
      payload: payload
    };
  },
  replace: function replace(path) {
    return {
      type: constants.replace,
      payload: path
    };
  },
  navigateSuccess: function navigateSuccess(path) {
    return {
      type: constants.navigateSuccess,
      payload: path
    };
  },
  navigateWithFilters: function navigateWithFilters(path, disableScroll) {
    return {
      type: constants.navigateWithFilters,
      payload: {
        path: path,
        disableScroll: disableScroll
      }
    };
  },
  historyCreated: function historyCreated(history, basePath) {
    return {
      type: constants.historyCreated,
      payload: { history: history, basePath: basePath }
    };
  },
  urlChangeNavigate: function urlChangeNavigate(location) {
    return {
      type: constants.urlChangeNavigation,
      payload: location
    };
  }
};