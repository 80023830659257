'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n  '], ['\n    ', ';\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      ', ';\n    '], ['\n      ', ';\n    ']);

exports.default = KiaArrow;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'KiaArrow__IconWrapper',
  componentId: 'sc-m34vyp-0'
})(['display:flex;align-items:center;', ';svg > rect:not([fill=\'none\']){fill:', ';}svg > g > path:not([fill=\'none\']){fill:', ';', ';}svg > g > circle:not([fill=\'none\']){', ';}svg{width:', ';height:', ';padding:0 3px;}'], _theme2.default.max.medium(_templateObject, function (_ref) {
  var mobileStyleOverride = _ref.mobileStyleOverride;
  return mobileStyleOverride && mobileStyleOverride.margin && 'margin: ' + mobileStyleOverride.margin;
}), function (_ref2) {
  var colour = _ref2.colour;
  return colour || '#fff';
}, function (_ref3) {
  var colour = _ref3.colour;
  return colour || '#fff';
}, _theme2.default.max.medium(_templateObject2, function (_ref4) {
  var mobileStyleOverride = _ref4.mobileStyleOverride;
  return mobileStyleOverride && mobileStyleOverride.strokeColour && 'stroke: ' + mobileStyleOverride.strokeColour;
}), _theme2.default.max.medium(_templateObject2, function (_ref5) {
  var mobileStyleOverride = _ref5.mobileStyleOverride;
  return mobileStyleOverride && mobileStyleOverride.circleColour && 'fill: ' + mobileStyleOverride.circleColour;
}), function (_ref6) {
  var width = _ref6.width;
  return width;
}, function (_ref7) {
  var height = _ref7.height;
  return height;
});
function KiaArrow(_ref8) {
  var colour = _ref8.colour,
      width = _ref8.width,
      height = _ref8.height,
      mobileStyleOverride = _ref8.mobileStyleOverride;

  return _react2.default.createElement(
    IconWrapper,
    {
      colour: colour,
      width: width,
      height: height,
      mobileStyleOverride: mobileStyleOverride
    },
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 24 24', width: '1em', height: '1em' },
      _react2.default.createElement(
        'g',
        { fill: 'none', fillRule: 'evenodd' },
        _react2.default.createElement('circle', { fill: '#FFF', cx: 12, cy: 12, r: 12 }),
        _react2.default.createElement('path', {
          strokeWidth: 2,
          d: 'M10 8l5 5-5 5',
          transform: 'translate(0, -1)'
        })
      )
    )
  );
}
KiaArrow.defaultProps = {
  colour: null,
  width: null,
  height: null
};