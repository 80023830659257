'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var _SectionIcons = require('../../helpers/SectionIcons');

var _SectionIcons2 = _interopRequireDefault(_SectionIcons);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var config = (0, _config.Config)('Representative Example', { id: 'Repex' }, (0, _config.Slice)('Styling', { id: 'styling' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.TopTier }, (0, _config.Colour)('Background Colour', 'backgroundColour'), (0, _config.Font)('Title Text', 'titleFont'), (0, _config.Toggle)('Horizontal Border', 'horizontalBorder'), (0, _config.Toggle)('Vertical Border', 'verticalBorder'), (0, _config.Colour)('Border Colour', 'borderColour'))));

exports.default = config;