'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n  '], ['\n    flex-direction: column;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    height: 450px;\n  '], ['\n    width: 100%;\n    height: 450px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    line-height: 12px;\n    font-size: 18px;\n  '], ['\n    line-height: 12px;\n    font-size: 18px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    line-height: 24px;\n    font-size: 18px;\n  '], ['\n    line-height: 24px;\n    font-size: 18px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _FiftyFiftyTypes = require('../../../types/Bentley/FiftyFiftyTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__Container',
  componentId: 'sc-19bbczf-0'
})(['display:flex;', ''], _theme2.default.max.medium(_templateObject));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__Item',
  componentId: 'sc-19bbczf-1'
})(['position:relative;flex:1;min-height:450px;cursor:pointer;&:hover{div div{background:#ffffff;}}']);

var Image = _styledComponents2.default.div.withConfig({
  displayName: 'Image'
}).withConfig({
  displayName: 'FiftyFifty__Image',
  componentId: 'sc-19bbczf-2'
})(['width:100%;height:100%;background-position:center center;background-image:url(', ');background-size:cover;', ';'], function (props) {
  return props.src;
}, _theme2.default.max.medium(_templateObject2));

var ActionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__ActionContainer',
  componentId: 'sc-19bbczf-3'
})(['position:absolute;z-index:1;top:0;left:0;']);

var ItemTitle = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__ItemTitle',
  componentId: 'sc-19bbczf-4'
})(['font-size:20px;line-height:27px;font-weight:300;padding:15px;background:#ffffffe5;color:#222222;font-family:', ';border:0;', ';', ';&:hover{background:#fff;}'], function (_ref) {
  var font = _ref.font;
  return font;
}, _theme2.default.max.large(_templateObject3), _theme2.default.max.medium(_templateObject4));

var FiftyFifty = function FiftyFifty(_ref2) {
  var config = _ref2.config;

  var _useNavigation = (0, _navigation2.default)(),
      navigateTo = _useNavigation.navigateTo;

  var items = config.items;

  var mobile = (0, _useCheckIsMobileScreen2.default)();

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    Container,
    null,
    items.map(function (item, idx) {
      return _react2.default.createElement(
        Item,
        {
          key: idx,
          onClick: function onClick(e) {
            e.preventDefault();
            navigateTo(item.titleUrl);
          }
        },
        _react2.default.createElement(Image, { src: mobile ? item.mobileImgUrl : item.desktopImgUrl }),
        item.translations.titleCTA && _react2.default.createElement(
          ActionContainer,
          null,
          _react2.default.createElement(
            ItemTitle,
            { font: fonts.primaryFont.value },
            item.translations.titleCTA
          )
        )
      );
    })
  );
};

exports.default = FiftyFifty;