'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column-reverse;\n    align-items: center;\n    gap: 40px;\n  '], ['\n    flex-direction: column-reverse;\n    align-items: center;\n    gap: 40px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Button = require('../../../components/Genesis/ModelsSearch/Button');

var _ModelVariantTile = require('../../../components/Genesis/ModelsSearch/ModelVariantTile');

var _ModelVariantTile2 = _interopRequireDefault(_ModelVariantTile);

var _SearchHeader = require('../../../components/Genesis/ModelsSearch/SearchHeader');

var _SearchHeader2 = _interopRequireDefault(_SearchHeader);

var _Spinner = require('../../../components/Spinner');

var _filters = require('../../../shared/filters');

var _modelTypes = require('../../../types/Genesis/modelTypes');

var _globalStyling = require('../../../types/globalStyling');

var _useModelSearch2 = require('./useModelSearch');

var _Dropdown = require('../../../components/Genesis/ModelsSearch/Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__Container',
  componentId: 'sc-agw7ic-0'
})(['align-items:center;background:#111;display:flex;flex-direction:column;', ';'], function (_ref) {
  var theme = _ref.theme;
  return _Genesis.mixins.pageGuttering(theme);
});

var ModelSearchWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__ModelSearchWrapper',
  componentId: 'sc-agw7ic-1'
})(['margin-top:80px;width:100%;max-width:1920px;']);

var ActionBar = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__ActionBar',
  componentId: 'sc-agw7ic-2'
})(['display:flex;flex-direction:row;justify-content:space-between;margin:36px 0 48px;width:100%;', ''], _Genesis2.default.max.medium(_templateObject));

var ModelSearch = function ModelSearch(_ref2) {
  var config = (0, _objectWithoutProperties3.default)(_ref2.config, []),
      globalStyling = _ref2.globalStyling,
      dispatch = _ref2.dispatch;

  var _useState = (0, _react.useState)(config.vehicleTypes.all),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      selectedVehicleType = _useState2[0],
      setVehicleType = _useState2[1];

  var _useModelSearch = (0, _useModelSearch2.useModelSearch)(config.searchSections),
      getModelGroups = _useModelSearch.getModelGroups,
      onTileSelected = _useModelSearch.onTileSelected,
      initialiseFilters = _useModelSearch.initialiseFilters,
      clearState = _useModelSearch.clearState,
      modelGroups = _useModelSearch.modelGroups,
      clearQueryParams = _useModelSearch.clearQueryParams,
      selectedTiles = _useModelSearch.selectedTiles,
      prepareSRPFilters = _useModelSearch.prepareSRPFilters,
      isTileSelected = _useModelSearch.isTileSelected,
      selectAllTiles = _useModelSearch.selectAllTiles;

  var clearAllState = function clearAllState() {
    clearQueryParams();
    clearState();
    initialiseFilters();
    getModelGroups();
  };

  (0, _react.useEffect)(function () {
    clearAllState();
    return function () {
      clearState();
    };
  }, []);

  var currentModels = (0, _react.useMemo)(function () {
    return config.searchSections.filter(function (section) {
      return section.name === 'Current';
    });
  }, [config.searchSections]);

  var handleOnClick = (0, _react.useCallback)(function () {
    if (selectedTiles.length === 0) {
      var filteredModels = currentModels.flatMap(function (section) {
        if (selectedVehicleType === config.vehicleTypes.all) {
          return [];
        }
        if (selectedVehicleType === config.vehicleTypes.ev) {
          return section.tiles.filter(function (tile) {
            return tile.ev === true ? tile : false;
          }).map(function (tile) {
            return tile.tileId;
          });
        }
        return section.tiles.filter(function (tile) {
          return tile.type === selectedVehicleType ? tile.tileId : false;
        }).map(function (tile) {
          return tile.tileId;
        });
      });

      prepareSRPFilters(filteredModels);
      dispatch(_filters.actions.searchWithFilters());
    } else {
      prepareSRPFilters(selectedTiles);
      dispatch(_filters.actions.searchWithFilters());
    }
  }, [selectedTiles, currentModels, selectedVehicleType]);

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      _Spinner.SpinnerWrapper,
      {
        loaded: modelGroups.length > 0,
        globalStyling: globalStyling
      },
      function () {
        return _react2.default.createElement(
          ModelSearchWrapper,
          null,
          _react2.default.createElement(_SearchHeader2.default, {
            title: config.translations.title,
            subtitle: config.translations.subtitle
          }),
          _react2.default.createElement(
            ActionBar,
            null,
            _react2.default.createElement(_Dropdown2.default, {
              vehicleTypes: config.vehicleTypes,
              translations: config.translations,
              value: selectedVehicleType,
              onClick: function onClick(type) {
                setVehicleType(type);
                selectAllTiles(false);
              }
            }),
            _react2.default.createElement(
              _Button.Button,
              { onClick: handleOnClick },
              selectedTiles.length > 0 ? config.translations.searchButtonModelsSelected : config.translations.searchButton
            )
          ),
          currentModels.map(function (section) {
            return _react2.default.createElement(_ModelVariantTile2.default, {
              key: section.name,
              section: section,
              config: config,
              selectedVehicleType: selectedVehicleType,
              onTileSelected: onTileSelected,
              isTileSelected: isTileSelected
            });
          })
        );
      }
    )
  );
};

exports.default = ModelSearch;