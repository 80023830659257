'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n    background-size: 100% 100%;\n  '], ['\n    ', ';\n    background-size: 100% 100%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 80%;\n    margin: 0 10%;\n  '], ['\n    width: 80%;\n    margin: 0 10%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 5%;\n  '], ['\n    padding: 0 5%;\n  ']);

exports.default = ApprovedBenefits;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Inspection = require('./JaguarIcons/Approved/Inspection');

var _Inspection2 = _interopRequireDefault(_Inspection);

var _Mileage = require('./JaguarIcons/Approved/Mileage');

var _Mileage2 = _interopRequireDefault(_Mileage);

var _RoadTest = require('./JaguarIcons/Approved/RoadTest');

var _RoadTest2 = _interopRequireDefault(_RoadTest);

var _Technicians = require('./JaguarIcons/Approved/Technicians');

var _Technicians2 = _interopRequireDefault(_Technicians);

var _TwentyFourHours = require('./JaguarIcons/Approved/TwentyFourHours');

var _TwentyFourHours2 = _interopRequireDefault(_TwentyFourHours);

var _Warranty = require('./JaguarIcons/Approved/Warranty');

var _Warranty2 = _interopRequireDefault(_Warranty);

var _ContentWrapper = require('./ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ApprovedBenefits__Container',
  componentId: 'sc-1l4ficp-0'
})(['width:100%;height:auto;', ';', ';background-size:100% 100%;', ';', ';'], function (_ref) {
  var backgroundImage = _ref.backgroundImage;
  return backgroundImage && 'background: url(' + backgroundImage + ');';
}, function (_ref2) {
  var backgroundColour = _ref2.backgroundColour;
  return backgroundColour && 'background-color: ' + backgroundColour + ';';
}, function (_ref3) {
  var horizontalPadding = _ref3.horizontalPadding;
  return horizontalPadding ? 'padding: ' + horizontalPadding + 'px 0' : 'padding: 100px 0;';
}, _theme2.default.max.medium(_templateObject, function (_ref4) {
  var backgroundImageMobile = _ref4.backgroundImageMobile;
  return backgroundImageMobile && 'background: url(' + backgroundImageMobile + ')';
}));

var TopSectionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ApprovedBenefits__TopSectionContainer',
  componentId: 'sc-1l4ficp-1'
})(['width:50%;text-align:center;margin:0 25%;color:#ffffff;', ';'], _theme2.default.max.large(_templateObject2));

var Heading = _styledComponents2.default.div.withConfig({
  displayName: 'ApprovedBenefits__Heading',
  componentId: 'sc-1l4ficp-2'
})(['font-size:20px;line-height:24px;font-weight:600;text-transform:uppercase;margin-bottom:40px;letter-spacing:3px;']);

var ItemHeading = _styledComponents2.default.div.withConfig({
  displayName: 'ApprovedBenefits__ItemHeading',
  componentId: 'sc-1l4ficp-3'
})(['font-weight:600;text-transform:uppercase;line-height:18px;font-size:15px;margin-bottom:10px;letter-spacing:3px;margin-top:15px;']);

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'ApprovedBenefits__Content',
  componentId: 'sc-1l4ficp-4'
})(['font-size:18px;line-height:22px;']);

var ItemContent = _styledComponents2.default.div.withConfig({
  displayName: 'ApprovedBenefits__ItemContent',
  componentId: 'sc-1l4ficp-5'
})(['color:#f0f0f0;font-size:15px;line-height:21px;']);

var TopSection = function TopSection(_ref5) {
  var headingText = _ref5.headingText,
      contentText = _ref5.contentText;
  return _react2.default.createElement(
    TopSectionContainer,
    null,
    _react2.default.createElement(
      Heading,
      null,
      headingText
    ),
    _react2.default.createElement(
      Content,
      null,
      contentText
    )
  );
};

var BenefitBox = _styledComponents2.default.div.withConfig({
  displayName: 'ApprovedBenefits__BenefitBox',
  componentId: 'sc-1l4ficp-6'
})(['width:30%;text-align:center;margin-top:30px;', ';'], _theme2.default.max.large(_templateObject3));

var BottomSectionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ApprovedBenefits__BottomSectionContainer',
  componentId: 'sc-1l4ficp-7'
})(['display:flex;flex-wrap:wrap;justify-content:space-between;color:#ffffff;', ';'], _theme2.default.max.large(_templateObject4));

var iconSelector = {
  Warranty: _react2.default.createElement(_Warranty2.default, { colour: '#acacac', height: '3em', width: '3em' }),
  Mileage: _react2.default.createElement(_Mileage2.default, { colour: '#acacac', height: '3em', width: '3em' }),
  RoadTest: _react2.default.createElement(_RoadTest2.default, { colour: '#acacac', height: '3em', width: '3em' }),
  Technicians: _react2.default.createElement(_Technicians2.default, { colour: '#acacac', height: '3em', width: '3em' }),
  TwentyFourHours: _react2.default.createElement(_TwentyFourHours2.default, { colour: '#acacac', height: '3em', width: '3em' }),
  Inspection: _react2.default.createElement(_Inspection2.default, { colour: '#acacac', height: '3em', width: '3em' })
};

var BottomSection = function BottomSection(_ref6) {
  var options = _ref6.options;
  return _react2.default.createElement(
    BottomSectionContainer,
    null,
    options.map(function (o, index) {
      return _react2.default.createElement(
        BenefitBox,
        { key: 'approved-benefits-item' + index.toString() },
        _react2.default.createElement(
          'div',
          { style: { display: 'flex', justifyContent: 'center' } },
          iconSelector[o.icon] || null
        ),
        _react2.default.createElement(
          ItemHeading,
          null,
          o.heading
        ),
        _react2.default.createElement(
          ItemContent,
          null,
          o.content
        )
      );
    })
  );
};

function ApprovedBenefits(_ref7) {
  var backgroundImage = _ref7.backgroundImage,
      backgroundImageMobile = _ref7.backgroundImageMobile,
      backgroundColour = _ref7.backgroundColour,
      topSection = _ref7.topSection,
      bottomSection = _ref7.bottomSection,
      contentWidth = _ref7.contentWidth,
      horizontalPadding = _ref7.horizontalPadding;

  return _react2.default.createElement(
    Container,
    {
      id: 'provenance',
      horizontalPadding: horizontalPadding,
      backgroundImage: backgroundImage,
      backgroundImageMobile: backgroundImageMobile,
      backgroundColour: backgroundColour
    },
    _react2.default.createElement(
      _ContentWrapper2.default,
      { contentWidth: contentWidth },
      _react2.default.createElement(TopSection, {
        headingText: topSection.headingText,
        contentText: topSection.contentText
      }),
      _react2.default.createElement(BottomSection, { options: bottomSection.options })
    )
  );
}