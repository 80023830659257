'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _actions = require('./actions');

var _router = require('../../actions/router');

var _Spinner = require('../../components/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'DealerChecker__Overlay',
  componentId: 'sc-a4p88q-0'
})(['position:fixed;top:0;left:0;width:100%;height:100vh;background:white;z-index:999;display:flex;flex-direction:column;justify-content:center;align-items:center;']);

var DealerChecker = function DealerChecker(props) {
  var _props$config = props.config,
      active = _props$config.active,
      _props$config$endpoin = _props$config.endpoints,
      endpoints = _props$config$endpoin === undefined ? {} : _props$config$endpoin,
      redirect = _props$config.redirect;

  var dispatch = (0, _reactRedux.useDispatch)();

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.dealerChecker;
  }),
      status = _useSelector.status;

  var _useSelector2 = (0, _reactRedux.useSelector)(function (state) {
    return state.router;
  }),
      path = _useSelector2.path;

  (0, _react.useEffect)(function () {
    if (active) {
      dispatch(_actions.actions.getDealerInfo(endpoints.dealers));
    }
  }, [active]);

  (0, _react.useEffect)(function () {
    if (status === 'does-not-exist' && !path.endsWith(redirect)) {
      dispatch(_router.actions.navigate(redirect));
    }
  }, [status, path, redirect]);

  return active && status === 'unknown' ? _react2.default.createElement(
    Overlay,
    null,
    _react2.default.createElement(_Spinner2.default, { size: 50, colour: { value: '#333333' } })
  ) : null;
};

exports.default = DealerChecker;