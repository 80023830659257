'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SubNav = require('../../containers/SubNav');

var _SubNav2 = _interopRequireDefault(_SubNav);

var _router = require('../../actions/router');

var _CompareVehicles = require('../../components/CompareVehicles');

var _CompareVehicles2 = _interopRequireDefault(_CompareVehicles);

var _compare = require('../../shared/compare');

var _shortlist = require('../../shared/shortlist');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _vehicle = require('../../helpers/vehicle');

var _vehicleStore = require('../../shared/selectors/vehicleStore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var themeFromProps = function themeFromProps(_ref) {
  var typeface = _ref.typeface,
      fontSize = _ref.fontSize,
      kerning = _ref.kerning,
      transform = _ref.transform,
      colour = _ref.colour;
  return {
    fontFamily: typeface ? typeface.label : '',
    fontSize: fontSize ? fontSize + 'px' : '',
    characterSpacing: kerning ? kerning + 'px' : '',
    textTransform: transform || '',
    colour: colour ? colour.value : ''
  };
};

var Compare = function (_Component) {
  (0, _inherits3.default)(Compare, _Component);

  function Compare() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Compare);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = Compare.__proto__ || (0, _getPrototypeOf2.default)(Compare)).call.apply(_ref2, [this].concat(args))), _this), Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            dispatch = _this$props.dispatch,
            optionalExtras = _this$props.state.optionalExtras,
            toggleOptionalExtras = _this$props.actions.toggleOptionalExtras,
            history = _this$props.history,
            pathByModule = _this$props.pathByModule,
            preview = _this$props.preview,
            config = _this$props.config,
            shared = _this$props.shared,
            globalStyling = _this$props.globalStyling,
            locale = _this$props.marketInfo.locale,
            featureFlags = _this$props.featureFlags;


        var sharedShortListedVehicles = (0, _vehicleStore.getShortList)(featureFlags)(shared);
        var comparedVehicles = (0, _vehicleStore.getCompareList)(featureFlags)(shared);

        var onMoreInfoClick = function onMoreInfoClick(name, vehicle) {
          var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, shared.sessionPreferences.language);
          dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
        };

        var getShortListedIconColour = function getShortListedIconColour(id) {
          var isShortListed = sharedShortListedVehicles.find(function (shortlistedVehicle) {
            return shortlistedVehicle.id === (id || {});
          });
          return isShortListed ? config.shortlistIconListedColour && config.shortlistIconListedColour.value : '#ACACAC';
        };

        var equipmentMetaData = shared.compare.equipmentList || [];
        return _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(_SubNav2.default, {
            pathByModule: pathByModule,
            title: config.translations.mobileVehicleDetails,
            preview: preview,
            onBackButtonClick: history.goBack,
            translations: config.translations,
            subNavBGColour: config.subNavBGColour,
            subNavBorderColour: config.subNavBorderColour,
            subNavFontTheme: config.subNavFont && themeFromProps(config.subNavFont),
            subNavChevronColour: config.subNavChevronColour,
            subNavBarItemSelectedBackgroundColour: config.subNavBarItemSelectedBackgroundColour,
            subNavBarItemSelectedFontColour: config.subNavBarItemSelectedFontColour,
            contentWidth: globalStyling.contentWidth,
            globalStyling: globalStyling,
            history: history
          }),
          _react2.default.createElement(
            _ContentWrapper2.default,
            { contentWidth: globalStyling.contentWidth },
            _react2.default.createElement(_CompareVehicles2.default, {
              requestInformation: function requestInformation(vehicle) {
                return dispatch(_router.actions.navigate('/enquiry/' + vehicle.id));
              },
              translations: config.translations,
              removeVehicle: function removeVehicle(vehicle) {
                return dispatch(_compare.actions.toggleVehicle(vehicle));
              },
              shortlistVehicle: function shortlistVehicle(vehicle) {
                return dispatch(_shortlist.actions.toggleVehicle(vehicle));
              },
              comparedVehicles: comparedVehicles,
              optionalExtras: optionalExtras,
              equipmentMetaData: equipmentMetaData,
              toggleOptionalExtras: toggleOptionalExtras,
              getShortListedIconColour: getShortListedIconColour,
              onMoreInfoClick: onMoreInfoClick,
              phoneIconColour: config.phoneIconColour && config.phoneIconColour.value,
              closeIconColour: config.closeIconColour && config.closeIconColour.value,
              buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
              gridConfiguration: config.gridConfiguration,
              globalStyling: globalStyling,
              headerFont: config.headerFont,
              infoFont: config.infoFont,
              borderBottomOnly: config.borderBottomOnly,
              headerOverSubheader: config.headerOverSubheader,
              locale: locale
            })
          )
        );
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Compare, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // We do this to force a first run of the reducer
      // - there used to be a more generic solution,
      //   but it broke the builder
      var _props = this.props,
          init = _props.actions.init,
          dispatch = _props.dispatch,
          featureFlags = _props.featureFlags,
          _props$shared = _props.shared,
          sessionPreferences = _props$shared.sessionPreferences,
          currencyConversion = _props$shared.currencyConversion;


      init();
      dispatch(_compare.actions.checkVehicleStatus());
      dispatch(_shortlist.actions.getVehicles());
      dispatch(_compare.actions.getEquipmentList());

      if (sessionPreferences.currency && currencyConversion.exchangeRates[sessionPreferences.currency]) {
        dispatch(_compare.actions.updateVehiclePrices({
          currency: sessionPreferences.currency,
          exchangeRates: currencyConversion.exchangeRates,
          statePath: (0, _vehicleStore.getVehicleStatePath)(featureFlags)
        }));
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _props2 = this.props,
          shared = _props2.shared,
          dispatch = _props2.dispatch,
          featureFlags = _props2.featureFlags,
          _props2$shared = _props2.shared,
          sessionPreferences = _props2$shared.sessionPreferences,
          currencyConversion = _props2$shared.currencyConversion;

      if (sessionPreferences.currency && currencyConversion.exchangeRates[sessionPreferences.currency] && prevProps.shared.currencyConversion.exchangeRates !== currencyConversion.exchangeRates) {
        dispatch(_compare.actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates,
          statePath: (0, _vehicleStore.getVehicleStatePath)(featureFlags)
        }));
      }

      if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language) {
        dispatch(_compare.actions.checkVehicleStatus());
        dispatch(_compare.actions.getEquipmentList());
      }
    }
  }]);
  return Compare;
}(_react.Component);

exports.default = Compare;