'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    border: ', ';\n  '], ['\n    border: ', ';\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'CarouselSlide__Container',
  componentId: 'sc-xngjnc-0'
})(['position:relative;padding-bottom:', ';border:', ';', ';', ';'], function (_ref) {
  var fullscreen = _ref.fullscreen;
  return fullscreen ? '100vh' : '75%';
}, function (_ref2) {
  var border = _ref2.border;
  return border;
}, function (_ref3) {
  var borderBottom = _ref3.borderBottom;
  return borderBottom && 'border-bottom: ' + borderBottom + ' ';
}, _theme2.default.max.medium(_templateObject, function (_ref4) {
  var mobileBorder = _ref4.mobileBorder;
  return mobileBorder || 'inherit';
}));

var CarouselSlides = function (_React$Component) {
  (0, _inherits3.default)(CarouselSlides, _React$Component);

  function CarouselSlides(props) {
    (0, _classCallCheck3.default)(this, CarouselSlides);

    var _this = (0, _possibleConstructorReturn3.default)(this, (CarouselSlides.__proto__ || (0, _getPrototypeOf2.default)(CarouselSlides)).call(this, props));

    _this.state = {
      prevSlide: {}
    };
    return _this;
  }

  (0, _createClass3.default)(CarouselSlides, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(_ref5) {
      var prevSlide = _ref5.slide;
      var slide = this.props.slide;


      if (slide.id !== prevSlide.id) {
        this.setState({ prevSlide: prevSlide });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          _props$slide = _props.slide,
          Slide = _props$slide.component,
          id = _props$slide.id,
          image = _props$slide.image,
          fullscreen = _props.fullscreen,
          imageCarousel = _props.imageCarousel,
          alternativeText = _props.alternativeText;
      var _state$prevSlide = this.state.prevSlide,
          prevId = _state$prevSlide.id,
          prevImage = _state$prevSlide.image;


      return _react2.default.createElement(
        Container,
        {
          border: fullscreen ? '0' : imageCarousel ? imageCarousel.mainImageBorder : '0',
          borderBottom: imageCarousel && imageCarousel.mainImageBorderBottom,
          mobileBorder: imageCarousel && imageCarousel.mobileBorder,
          key: image,
          fullscreen: fullscreen
        },
        prevImage && _react2.default.createElement(Slide, {
          image: prevImage,
          id: prevId,
          fullscreen: fullscreen,
          alt: alternativeText
        }),
        _react2.default.createElement(Slide, {
          image: image,
          id: id,
          active: true,
          fullscreen: fullscreen,
          alt: alternativeText
        })
      );
    }
  }]);
  return CarouselSlides;
}(_react2.default.Component);

/*// @ts-ignore */


CarouselSlides.defaultProps = {
  imageWidth: 800,
  imageHeight: 650,
  transitionTime: 500
};

exports.default = CarouselSlides;