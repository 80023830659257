'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Magnify;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Magnify(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 31 31', width: '1em', height: '1em' },
      _react2.default.createElement(
        'g',
        { fill: 'none', fillRule: 'evenodd' },
        _react2.default.createElement('path', { fill: '#FFF', d: 'M8.238 11.65h9.12v1.536h-9.12z' }),
        _react2.default.createElement('path', { fill: '#FFF', d: 'M13.558 7.81v9.215h-1.52V7.81z' }),
        _react2.default.createElement('path', {
          d: 'M2.606 12.361c0-5.457 4.44-9.898 9.898-9.898 5.458 0 9.898 4.44 9.898 9.898 0 5.458-4.44 9.898-9.898 9.898-5.457 0-9.898-4.44-9.898-9.898zm28.31 16.32l-9.244-9.244a11.526 11.526 0 0 0 2.421-7.076c0-6.39-5.199-11.588-11.589-11.588C6.114.773.916 5.97.916 12.36c0 6.39 5.199 11.589 11.588 11.589 2.663 0 5.117-.905 7.076-2.42l9.243 9.243 2.093-2.093z',
          fill: '#FEFEFE'
        })
      )
    )
  );
}

Magnify.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};