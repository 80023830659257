'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _set = require('babel-runtime/core-js/set');

var _set2 = _interopRequireDefault(_set);

var _from = require('babel-runtime/core-js/array/from');

var _from2 = _interopRequireDefault(_from);

exports.default = VehicleShortlist;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SearchResultTile = require('./SearchResultTile');

var _SearchResultTile2 = _interopRequireDefault(_SearchResultTile);

var _SearchResultBlock = require('./SearchResultBlock');

var _SearchResultBlock2 = _interopRequireDefault(_SearchResultBlock);

var _Close = require('./JaguarIcons/Global/Close');

var _Close2 = _interopRequireDefault(_Close);

var _Compare = require('./JaguarIcons/Global/Compare');

var _Compare2 = _interopRequireDefault(_Compare);

var _Heart = require('./JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleShortlist__Container',
  componentId: 'sc-u5zahr-0'
})(['width:100%;display:flex;flex-wrap:wrap;justify-content:', ';}'], function (_ref) {
  var length = _ref.length;
  return length && length % 3 > 0 ? 'flex-start' : 'space-between';
});


var IconWrapper = _styledComponents2.default.button.withConfig({
  displayName: 'IconButton'
}).withConfig({
  displayName: 'VehicleShortlist__IconWrapper',
  componentId: 'sc-u5zahr-1'
})(['background:none;border:none;cursor:pointer;&:focus{outline:none;}']);

var CloseIconButton = function CloseIconButton(onCloseClick) {
  return function () {
    return _react2.default.createElement(
      IconWrapper,
      { onClick: onCloseClick },
      _react2.default.createElement(_Close2.default, { width: '1.5em', height: '1.3125em', colour: '#ACACAC' })
    );
  };
};

var CompareIconButton = function CompareIconButton(onCompareClick, compareIconColour) {
  return function () {
    return _react2.default.createElement(
      IconWrapper,
      { onClick: onCompareClick },
      _react2.default.createElement(_Compare2.default, { width: '2em', height: '1.75em', colour: compareIconColour })
    );
  };
};

var HeartIconButton = function HeartIconButton(onHeartClick, heartIconColour) {
  return function () {
    return _react2.default.createElement(
      IconWrapper,
      { onClick: onHeartClick },
      _react2.default.createElement(_Heart2.default, { width: '2em', height: '1.75em', colour: heartIconColour })
    );
  };
};

function VehicleShortlist(_ref2) {
  var translations = _ref2.translations,
      vehicles = _ref2.vehicles,
      placeholdingImage = _ref2.placeholdingImage,
      onHeartClick = _ref2.onHeartClick,
      onCloseClick = _ref2.onCloseClick,
      onCompareClick = _ref2.onCompareClick,
      compareVehicle = _ref2.compareVehicle,
      shortlistVehicle = _ref2.shortlistVehicle,
      onMoreInfoClick = _ref2.onMoreInfoClick,
      getCompareIconColour = _ref2.getCompareIconColour,
      getShortListedIconColour = _ref2.getShortListedIconColour,
      selectedView = _ref2.selectedView,
      sortValue = _ref2.sortValue,
      sortValueDictionary = _ref2.sortValueDictionary,
      globalStyling = _ref2.globalStyling,
      visibility = _ref2.visibility,
      locale = _ref2.locale,
      financeHeadingFont = _ref2.financeHeadingFont,
      showFinancePopup = _ref2.showFinancePopup,
      vehicleSpecContainer = _ref2.vehicleSpecContainer,
      financeWidthOverride = _ref2.financeWidthOverride,
      icon360 = _ref2.icon360,
      iconYoutubeVideo = _ref2.iconYoutubeVideo,
      config = _ref2.config;

  return _react2.default.createElement(
    'div',
    null,
    vehicles && vehicles.length ? _react2.default.createElement(
      Container,
      { length: vehicles.length },
      selectedView === 'GRID' ? (0, _from2.default)(new _set2.default(vehicles.map(function (v) {
        return v.id;
      }))).map(function (id) {
        return (0, _extends3.default)({}, vehicles.find(function (v) {
          return v.id === id;
        }));
      }).sort(sortValueDictionary[sortValue]).map(function (vehicle) {
        return _react2.default.createElement(_SearchResultTile2.default, {
          checkLength: vehicles.length % 3 > 0,
          shortList: true,
          placeholdingImage: placeholdingImage,
          key: vehicle.id,
          translations: translations,
          align: 'left',
          carResult: vehicle,
          onMoreInfoClicked: function onMoreInfoClicked() {
            return onMoreInfoClick('VDP', vehicle);
          },
          renderTopRightIcon: CloseIconButton(function () {
            return onCloseClick(vehicle);
          }),
          renderBottomIcon1: CompareIconButton(function () {
            return onCompareClick(vehicle);
          }, getCompareIconColour(vehicle.id)),
          renderBottomIcon2: HeartIconButton(function () {
            return onHeartClick(vehicle);
          }, getShortListedIconColour(vehicle.id)),
          globalStyling: globalStyling,
          visibility: visibility.tiles,
          locale: locale,
          financeHeadingFont: financeHeadingFont,
          showFinancePopup: showFinancePopup,
          icon360: icon360,
          iconYoutubeVideo: iconYoutubeVideo,
          convertedKwToHp: config && config.convertedKwToHp,
          config: config
        });
      }) : null,
      selectedView === 'LIST' ? (0, _from2.default)(new _set2.default(vehicles.map(function (v) {
        return v.id;
      }))).map(function (id) {
        return (0, _extends3.default)({}, vehicles.find(function (v) {
          return v.id === id;
        }));
      }).sort(sortValueDictionary[sortValue]).map(function (vehicle) {
        return _react2.default.createElement(_SearchResultBlock2.default, {
          key: vehicle.id,
          translations: translations,
          vehicle: vehicle,
          placeholdingImage: placeholdingImage,
          onMoreInfoClick: onMoreInfoClick,
          compareVehicle: compareVehicle,
          shortlistVehicle: shortlistVehicle,
          compareIconColour: getCompareIconColour(vehicle.id),
          shortListedIconColour: getShortListedIconColour(vehicle.id),
          renderTopRightIcon: CloseIconButton(function () {
            return onCloseClick(vehicle);
          }),
          globalStyling: globalStyling,
          visibility: visibility.tiles,
          locale: locale,
          financeHeadingFont: financeHeadingFont,
          displayImage: true,
          vehiclePriceSectionWidth: 25,
          showFinancePopup: showFinancePopup,
          vehicleSpecContainer: vehicleSpecContainer,
          financeWidthOverride: financeWidthOverride,
          icon360: icon360,
          iconYoutubeVideo: iconYoutubeVideo,
          convertedKwToHp: config && config.convertedKwToHp
        });
      }) : null
    ) : null
  );
}