'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectStyleOverride = exports.linkStyleOverride = exports.buttonStyleOverride = exports.fontStyleOverride = undefined;

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var fontStyleOverride = exports.fontStyleOverride = function fontStyleOverride() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      typeface = _ref.typeface,
      fontSize = _ref.fontSize,
      kerning = _ref.kerning,
      transform = _ref.transform,
      colour = _ref.colour,
      alignment = _ref.alignment,
      padding = _ref.padding;

  return [typeface && 'font-family: ' + typeface.value + ';', fontSize && 'font-size: ' + fontSize + 'px;', kerning !== undefined && 'letter-spacing: ' + kerning + 'px;', transform && 'text-transform: ' + transform + ';', colour && 'color: ' + colour.value + ';', alignment && 'text-align: ' + alignment + ';', padding && padding.top && 'padding-top: ' + padding.top + 'px;', padding && padding.bottom && 'padding-bottom: ' + padding.bottom + 'px;', padding && padding.left && 'padding-left: ' + padding.left + 'px;', padding && padding.right && 'padding-right: ' + padding.right + 'px;'].filter(Boolean).join('');
};
var buttonStyleOverride = exports.buttonStyleOverride = function buttonStyleOverride() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      font = _ref2.font,
      backgroundColour = _ref2.backgroundColour,
      hoverBackgroundColour = _ref2.hoverBackgroundColour,
      buttonSize = _ref2.buttonSize,
      hoverColour = _ref2.hoverColour;

  return [fontStyleOverride(font), backgroundColour && 'background-color: ' + backgroundColour.value + ';', hoverBackgroundColour && 'background-color: ' + hoverBackgroundColour.value + ';', buttonSize && buttonSize.width && 'width: ' + buttonSize.width + ';', buttonSize && buttonSize.height && 'height: ' + buttonSize.height + ';', hoverColour && 'color: ' + hoverColour.value + ';'].filter(Boolean).join('');
};

var linkStyleOverride = exports.linkStyleOverride = function linkStyleOverride() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      borderColour = _ref3.borderColour,
      borderBottom = _ref3.borderBottom;

  return [borderColour && 'border-color: ' + borderColour.value + ';', borderBottom && 'border-bottom: solid ' + borderBottom + 'px;'].filter(Boolean).join('');
};

var selectStyleOverride = exports.selectStyleOverride = function selectStyleOverride() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      font = _ref4.font,
      borderColour = _ref4.borderColour,
      borderWidth = _ref4.borderWidth,
      width = _ref4.width,
      height = _ref4.height,
      margin = _ref4.margin;

  return [fontStyleOverride(font), borderColour && 'border-color: ' + borderColour.value + ';', borderWidth && 'border-width: ' + borderWidth.value + ';', width && 'width: ' + width + ';', height && 'height: ' + height + ';', margin && (0, _keys2.default)(margin).map(function (k) {
    return 'margin-' + k + ': ' + margin[k] + ';';
  }).filter(Boolean)].filter(Boolean);
};