'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _reactRedux = require('react-redux');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function () {
  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.google;
  }),
      googleAvailable = _useSelector.googleAvailable;

  var _useState = (0, _react.useState)(''),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      lat = _useState2[0],
      setLat = _useState2[1];

  var _useState3 = (0, _react.useState)(''),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      long = _useState4[0],
      setLong = _useState4[1];

  var _useState5 = (0, _react.useState)(''),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      locationTown = _useState6[0],
      setLocationTown = _useState6[1];

  var _useState7 = (0, _react.useState)(''),
      _useState8 = (0, _slicedToArray3.default)(_useState7, 2),
      userCountry = _useState8[0],
      setUserCountry = _useState8[1];

  return {
    lat: lat,
    long: long,
    locationTown: locationTown,
    userCountry: userCountry,
    setLat: setLat,
    setLong: setLong,
    googleAvailable: googleAvailable,
    setLocationTown: setLocationTown,
    setUserCountry: setUserCountry
  };
};