'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Budget;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Budget(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 28 23' },
      _react2.default.createElement('path', { d: 'M6.17 15a3.001 3.001 0 0 1 5.66 0H25v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm10-9a3.001 3.001 0 0 1 5.66 0H25v2h-3.17a3.001 3.001 0 0 1-5.66 0H2V6h14.17z' })
    )
  );
}

Budget.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};