'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    height: 90vh;\n    width: ', ';\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  '], ['\n    height: 90vh;\n    width: ', ';\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactScrolllock = require('react-scrolllock');

var _reactScrolllock2 = _interopRequireDefault(_reactScrolllock);

var _Genesis = require('../../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'ModalOverlay__Overlay',
  componentId: 'sc-339q8n-0'
})(['position:fixed;height:100%;width:100%;top:0;left:0;background-color:rgba(0,0,0,0.7);backdrop-filter:blur(12px);visibility:hidden;opacity:0;z-index:100;overflow:hidden;transition:visibility 0.35s,opacity 0.35s;visibility:visible;opacity:1;']);

var ModalContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModalOverlay__ModalContentWrapper',
  componentId: 'sc-339q8n-1'
})(['width:100%;height:100vh;position:absolute;top:0;left:0;*{box-sizing:border-box;}* img{display:block;}', ';'], _Genesis2.default.min.medium(_templateObject, function (_ref) {
  var modalWidth = _ref.modalWidth;
  return modalWidth ? modalWidth + ';' : 'auto';
}));

var ModalContent = _styledComponents2.default.div.withConfig({
  displayName: 'ModalOverlay__ModalContent',
  componentId: 'sc-339q8n-2'
})(['width:100%;height:100%;']);

var Close = _styledComponents2.default.div.withConfig({
  displayName: 'ModalOverlay__Close',
  componentId: 'sc-339q8n-3'
})(['position:absolute;top:0;', ';box-sizing:border-box;margin:10px 10px;cursor:pointer;z-index:999;font-size:1.5em;color:white;'], function (_ref2) {
  var direction = _ref2.direction;
  return direction === 'rtl' ? 'left: 0;' : 'right: 0;';
});

var CloseImg = _styledComponents2.default.img.withConfig({
  displayName: 'ModalOverlay__CloseImg',
  componentId: 'sc-339q8n-4'
})(['background-color:rgba(0,0,0,0.4);border-radius:40px;padding:5px;']);

exports.default = function (_ref3) {
  var close = _ref3.close,
      modalWidth = _ref3.modalWidth,
      children = _ref3.children,
      direction = _ref3.direction;
  return _react2.default.createElement(
    Overlay,
    null,
    _react2.default.createElement(
      Close,
      { onClick: close, 'data-cy': 'close', direction: direction },
      _react2.default.createElement(CloseImg, {
        src: 'https://res.cloudinary.com/motortrak/image/upload/v1564484782/cms/global/icons/x.svg',
        alt: 'close'
      })
    ),
    _react2.default.createElement(
      ModalContentWrapper,
      { modalWidth: modalWidth },
      _react2.default.createElement(
        ModalContent,
        null,
        children
      )
    ),
    _react2.default.createElement(_reactScrolllock2.default, null)
  );
};