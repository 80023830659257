'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Chunky = require('./JaguarIcons/Arrows/Chunky');

var _Chunky2 = _interopRequireDefault(_Chunky);

var _LocationInput = require('./LocationInput');

var _LocationInput2 = _interopRequireDefault(_LocationInput);

var _StyledIcon = require('./StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SearcherContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DirectionSearcher__SearcherContainer',
  componentId: 'sc-1szoy8a-0'
})(['display:flex;', ';'], function (_ref) {
  var directionSearcherStyles = _ref.directionSearcherStyles;
  return 'height: ' + (directionSearcherStyles && directionSearcherStyles.height || '40px') + ';\n    max-height: ' + (directionSearcherStyles && directionSearcherStyles.height || '40px');
});
/* eslint-disable jsx-a11y/anchor-is-valid */


var LocationInputContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DirectionSearcher__LocationInputContainer',
  componentId: 'sc-1szoy8a-1'
})(['width:90%;display:flex;']);

var Link = _styledComponents2.default.a.withConfig({
  displayName: 'DirectionSearcher__Link',
  componentId: 'sc-1szoy8a-2'
})(['color:#ffffff;text-decoration:none;background-color:', ';', ';font-weight:600;display:flex;line-height:1em;text-align:center;align-items:center;justify-content:center;', ';', ';', ';&:hover{opacity:1;}', ' ', ';'], function (_ref2) {
  var backgroundColour = _ref2.backgroundColour,
      disabledBackgroundColour = _ref2.disabledBackgroundColour,
      disabled = _ref2.disabled;
  return (disabled ? disabledBackgroundColour : backgroundColour) || '#a01e35';
}, function (_ref3) {
  var width = _ref3.width;
  return width ? 'min-width: ' + width + 'px' : 'width: 10%';
}, function (_ref4) {
  var borderRadius = _ref4.borderRadius;
  return borderRadius && 'border-radius: ' + borderRadius + 'px';
}, function (_ref5) {
  var opacity = _ref5.opacity;
  return opacity && 'opacity: ' + opacity;
}, function (_ref6) {
  var disabled = _ref6.disabled,
      disabledFontColour = _ref6.disabledFontColour;
  return disabled && disabledFontColour && 'color: ' + disabledFontColour.value;
}, function (_ref7) {
  var directionSearcherStyles = _ref7.directionSearcherStyles;
  return 'height: ' + (directionSearcherStyles && directionSearcherStyles.height || 'auto');
}, function (_ref8) {
  var globalStyling = _ref8.globalStyling;
  return globalStyling.direction === 'rtl' && 'transform: scaleX(-1);';
});

var DirectionSearcher = function (_Component) {
  (0, _inherits3.default)(DirectionSearcher, _Component);

  function DirectionSearcher(props) {
    (0, _classCallCheck3.default)(this, DirectionSearcher);

    var _this = (0, _possibleConstructorReturn3.default)(this, (DirectionSearcher.__proto__ || (0, _getPrototypeOf2.default)(DirectionSearcher)).call(this, props));

    Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.geocoder = new window.google.maps.Geocoder();
      }
    });
    Object.defineProperty(_this, 'onGetCurrentPosition', {
      enumerable: true,
      writable: true,
      value: function value(pos) {
        var placeholder = _this.props.placeholder;

        _this.setState({
          directionValue: placeholder,
          userLat: pos.coords.latitude,
          userLong: pos.coords.longitude
        });
        _this.parseCoordinates(pos.coords.latitude, pos.coords.longitude);
      }
    });
    Object.defineProperty(_this, 'getCoordinates', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (!('geolocation' in navigator)) {
          return;
        }
        navigator.geolocation.getCurrentPosition(function (res) {
          var _res$coords = res.coords,
              latitude = _res$coords.latitude,
              longitude = _res$coords.longitude;

          _this.parseCoordinates(latitude, longitude);
        });
      }
    });
    Object.defineProperty(_this, 'parseCoordinates', {
      enumerable: true,
      writable: true,
      value: function value(latitude, longitude) {
        if (latitude && longitude) {
          _this.geocoder.geocode({
            location: {
              lat: parseFloat(latitude),
              lng: parseFloat(longitude)
            }
          }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
              var address = results.find(function (res) {
                return res.types.find(function (t) {
                  return t === 'locality';
                });
              }) || results.find(function (res) {
                return res.types.find(function (t) {
                  return t === 'postal_code';
                });
              });
              if (address) {
                _this.setState(function () {
                  return {
                    directionValue: address.formatted_address
                  };
                });
                _this.handleLocationChange(address.formatted_address);
              }
            }
          });
        } else {
          _this.setState(function () {
            return { directionValue: '' };
          });
          _this.handleLocationChange('');
        }
      }
    });
    Object.defineProperty(_this, 'handleLocationChange', {
      enumerable: true,
      writable: true,
      value: function value(locationAddress) {
        var onGetCurrentPosition = _this.props.onGetCurrentPosition;

        _this.geocoder.geocode({ address: locationAddress }, function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
            var location = results[0].geometry.location;

            var lat = location.lat();
            var long = location.lng();

            onGetCurrentPosition({ coords: { latitude: lat, longitude: long } }, results[0].address_components.find(function (_ref9) {
              var types = _ref9.types;
              return types.includes('country');
            }).short_name);
          }
          _this.setState(function () {
            return {
              directionValue: locationAddress
            };
          });
        });
      }
    });
    Object.defineProperty(_this, 'constructUrl', {
      enumerable: true,
      writable: true,
      value: function value(destination) {
        var _destination$coordina = (0, _slicedToArray3.default)(destination.coordinates, 2),
            lat = _destination$coordina[0],
            long = _destination$coordina[1];

        var _this$state = _this.state,
            userLat = _this$state.userLat,
            userLong = _this$state.userLong,
            directionValue = _this$state.directionValue;

        var dest = lat + ',' + long;
        var origin = '';

        var MAPS_DIR_URL = function MAPS_DIR_URL(ori, des) {
          return 'https://www.google.com/maps/dir/?api=1&origin=' + ori + '&destination=' + des;
        };

        // they click the button without having done anything else
        if (userLat === undefined && userLong === undefined && directionValue === '') {
          return '';
          // they type a thing without location present.
        }
        if (directionValue !== '' && userLat === undefined && userLong === undefined) {
          origin = directionValue.replace(' ', '+') + '+' + (_this.props.retailerLocationCountry || '');
          return '' + MAPS_DIR_URL(origin, dest);
        }
        // otherwise we have location, go ahead and use coords
        // need the && here as flow moans about coercing undefined to string.
        // the || is to prevent MAP_DIR_URL param "ori" from ever being underfined
        // and therefore cannot be coerced.
        origin = userLat && userLong && userLat + ',' + userLong || '';
        return '' + MAPS_DIR_URL(origin, dest);
      }
    });
    Object.defineProperty(_this, 'handleAddressChange', {
      enumerable: true,
      writable: true,
      value: function value(locationAddress) {
        _this.setState({
          directionValue: locationAddress,
          userLat: undefined,
          userLong: undefined
        });
      }
    });


    _this.state = {
      directionValue: '',
      userLat: undefined,
      userLong: undefined
    };
    return _this;
  }

  (0, _createClass3.default)(DirectionSearcher, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          placeholder = _props.placeholder,
          destination = _props.destination,
          buttonBGColour = _props.buttonBGColour,
          iconColour = _props.iconColour,
          opacityDirectionButton = _props.opacityDirectionButton,
          locationButtonWidth = _props.locationButtonWidth,
          arrowRightIcon = _props.arrowRightIcon,
          directionButtonText = _props.directionButtonText,
          borderRadiusButton = _props.borderRadiusButton,
          globalStyling = _props.globalStyling,
          searchCountryCode = _props.searchCountryCode,
          directionSearcherStyles = _props.directionSearcherStyles;
      var directionValue = this.state.directionValue;

      var searchDisabled = !directionValue || !directionValue.trim();

      return _react2.default.createElement(
        SearcherContainer,
        { directionSearcherStyles: directionSearcherStyles },
        _react2.default.createElement(
          LocationInputContainer,
          null,
          _react2.default.createElement(_LocationInput2.default, {
            placeholder: placeholder,
            locationValue: directionValue,
            handleChange: function handleChange(value) {
              return _this2.handleAddressChange(value);
            },
            onGetCurrentPosition: function onGetCurrentPosition(pos) {
              return _this2.onGetCurrentPosition(pos);
            },
            locationIconColour: iconColour,
            handleBlur: this.handleLocationChange,
            searchCountryCode: searchCountryCode,
            globalStyling: globalStyling,
            ref: function ref(input) {
              _this2.input = input;
            },
            inputStyles: directionSearcherStyles
          })
        ),
        _react2.default.createElement(
          Link,
          {
            width: locationButtonWidth,
            'data-cy': 'location-search-button',
            backgroundColour: buttonBGColour,
            disabledBackgroundColour: globalStyling.colours.disabledBackgroundColour && globalStyling.colours.disabledBackgroundColour.value,
            disabledFontColour: globalStyling.colours.disabledForegroundColour && globalStyling.colours.disabledForegroundColour.value,
            borderRadius: borderRadiusButton,
            href: !searchDisabled ? this.constructUrl(destination) : undefined,
            target: '_blank',
            rel: 'noopener noreferrer',
            opacity: opacityDirectionButton,
            disabled: searchDisabled,
            globalStyling: globalStyling,
            directionSearcherStyles: directionSearcherStyles
          },
          arrowRightIcon ? _react2.default.createElement(_StyledIcon2.default, { link: arrowRightIcon, width: 20, height: 20 }) : directionButtonText || _react2.default.createElement(_Chunky2.default, { colour: '#fff' })
        )
      );
    }
  }]);
  return DirectionSearcher;
}(_react.Component);

exports.default = DirectionSearcher;