'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 240px;\n  '], ['\n    width: 240px;\n  ']);

exports.default = InputField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldError = require('./FieldError');

var _FieldError2 = _interopRequireDefault(_FieldError);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var WidthWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'InputField__WidthWrapper',
  componentId: 'sc-lfhtq1-0'
})(['width:100%;', ';'], _theme2.default.min.medium(_templateObject));
var Input = _styledComponents2.default.input.withConfig({
  displayName: 'InputField__Input',
  componentId: 'sc-lfhtq1-1'
})(['width:100%;height:45px;font-size:16px;font-family:inherit;line-height:40px;box-sizing:border-box;border:1px solid ', ';color:#7e7e7e;background-color:#fff;&:disabled{border:none;background-color:#d6d6d6;}&:focus{border:1px solid #999999;color:#444444;outline:none;}', ';'], function (props) {
  return props.error ? '#BF0C0C' : '#d8d8d8';
}, function (props) {
  return props.truncateChars && (0, _styledComponents.css)(['white-space:nowrap;overflow:hidden;text-overflow:ellipsis;']);
});

function InputField(_ref) {
  var label = _ref.label,
      required = _ref.required,
      type = _ref.type,
      name = _ref.name,
      value = _ref.value,
      onChange = _ref.onChange,
      onBlur = _ref.onBlur,
      error = _ref.error,
      disabled = _ref.disabled,
      placeholder = _ref.placeholder,
      _ref$truncateChars = _ref.truncateChars,
      truncateChars = _ref$truncateChars === undefined ? false : _ref$truncateChars,
      font = _ref.font;

  return _react2.default.createElement(
    WidthWrapper,
    null,
    _react2.default.createElement(
      _FormField2.default,
      { label: label, required: required, font: font },
      _react2.default.createElement(Input, {
        type: type,
        name: name,
        value: value,
        onChange: onChange,
        onBlur: onBlur,
        placeholder: placeholder,
        error: error,
        disabled: disabled,
        truncateChars: truncateChars
      }),
      error && _react2.default.createElement(
        _FieldError2.default,
        null,
        error
      )
    )
  );
}
InputField.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  onChange: function onChange() {},
  onKeyPress: function onKeyPress() {},
  label: '',
  error: '',
  validate: false
};