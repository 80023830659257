'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var mergeHostname = function mergeHostname(baseUrl, servicePath) {
  var url = new URL(baseUrl);
  var path = servicePath.replace(/\//g, '');
  url.hostname = path + '.' + url.host;

  return url;
};

var extractNewUrl = function extractNewUrl(baseUrl, servicePath) {
  var url = mergeHostname(baseUrl, servicePath);
  return url.href + 'api/';
};

var getBaseUrl = exports.getBaseUrl = function getBaseUrl(state) {
  return state.config.settings.baseUrl;
};

var getModelGroupsBaseUrl = exports.getModelGroupsBaseUrl = function getModelGroupsBaseUrl(state) {
  return state.config.settings.modelGroupsUrl;
};

var imsLeadsHostname = function imsLeadsHostname(state) {
  return state.config.settings.apac ? state.config.settings.imsApacLeadsHostName : state.config.settings.imsLeadsHostName;
};

var leadsUrl = function leadsUrl(state, baseUrl) {
  var leadsPath = state.config.settings.leadsPath;

  return '' + baseUrl + leadsPath;
};

var getLeadsUrl = exports.getLeadsUrl = function getLeadsUrl(state) {
  var useImsLeadsAndGroups = state.config.settings.useImsLeadsAndGroups;

  return leadsUrl(state, useImsLeadsAndGroups ? imsLeadsHostname(state) : extractNewUrl(getBaseUrl(state), 'leads'));
};

var imsAnalyticsUrl = exports.imsAnalyticsUrl = function imsAnalyticsUrl(state) {
  return state.config.settings.imsAnalyticsUrl;
};
var lamboFinanceCalcUrl = exports.lamboFinanceCalcUrl = function lamboFinanceCalcUrl(state) {
  return state.config.settings;
};
var getPlaceholderBrandImageUrl = exports.getPlaceholderBrandImageUrl = function getPlaceholderBrandImageUrl(state) {
  return state.config.config.global.globalStyling.placeholderBrandImageUrl;
};
var getLocale = exports.getLocale = function getLocale(state) {
  return state.shared.sessionPreferences.language;
};
var getMarket = exports.getMarket = function getMarket(state) {
  return state.config.config.global.inventory.market;
};
var getRegion = exports.getRegion = function getRegion(state) {
  return state.config.config.global.inventory.region;
};
var getSearchMarket = exports.getSearchMarket = function getSearchMarket(state) {
  return state.shared.sessionPreferences.market;
};
var getCountry = exports.getCountry = function getCountry(state) {
  return state.config.config.global.inventory.country;
};
var getModelGroupCountry = exports.getModelGroupCountry = function getModelGroupCountry(state) {
  return state.config.config.global.inventory.modelGroupCountry;
};
var getMake = exports.getMake = function getMake(state) {
  return state.config.config.global.inventory.make;
};
var getTerritory = exports.getTerritory = function getTerritory(state) {
  return state.config.config.global.inventory.territory;
};
var getSiteWideEmissionsUrl = exports.getSiteWideEmissionsUrl = function getSiteWideEmissionsUrl(state) {
  return state.config.config.global.inventory.getSiteWideEmissionsUrl;
};
var getSiteId = exports.getSiteId = function getSiteId(state) {
  return state.config.settings.siteId;
};
var getInventoryStatus = exports.getInventoryStatus = function getInventoryStatus(state) {
  return state.config.config.global.inventory.inventoryStatus;
};
var getAccessoryStatus = exports.getAccessoryStatus = function getAccessoryStatus(state) {
  return state.config.config.global.inventory.displayAccessories;
};
var getImageSize = exports.getImageSize = function getImageSize(state) {
  return state.config.config.global.inventory.imageSize;
};
var getFinanceStatus = exports.getFinanceStatus = function getFinanceStatus(state) {
  return state.config.config.global.featureFlags && state.config.config.global.featureFlags.finance;
};
var getGlobalFinance = exports.getGlobalFinance = function getGlobalFinance(state) {
  return state.config.config.global.finance;
};
var getMarketLevelDealer = exports.getMarketLevelDealer = function getMarketLevelDealer(state) {
  return state.config.config.global.inventory.marketLevelDealerId;
};
var getCurrencyCode = exports.getCurrencyCode = function getCurrencyCode(state) {
  return state.config.config.global.inventory.currencyCode;
};
var getSearchCountryCode = exports.getSearchCountryCode = function getSearchCountryCode(state) {
  return state.config.config.global.inventory.searchCountryCode;
};
var getInventorySimilarVehicles = exports.getInventorySimilarVehicles = function getInventorySimilarVehicles(state) {
  return state.config.config.global.inventory.similarVehicles;
};

var getThirdPartyLeadsBaseUrl = exports.getThirdPartyLeadsBaseUrl = function getThirdPartyLeadsBaseUrl(state) {
  return state.config.settings.thirdPartyLeadsBaseUrl;
};

var usesGoogleAnalytics = exports.usesGoogleAnalytics = function usesGoogleAnalytics(state) {
  return !!(state.config.config.global.analytics && state.config.config.global.analytics.googleAnalyticsTrackingId);
};

var getGoogleAnalyticsTrackingId = exports.getGoogleAnalyticsTrackingId = function getGoogleAnalyticsTrackingId(state) {
  return state.config.config.global.analytics && state.config.config.global.analytics.googleAnalyticsTrackingId;
};

var additionalLocationTypes = exports.additionalLocationTypes = function additionalLocationTypes(state) {
  return state.config.config.global.inventory.additionalLocationAddressTypes || [];
};

var vehicleValuationServicePath = exports.vehicleValuationServicePath = function vehicleValuationServicePath() {
  return 'vehicle-valuations/';
};
var vehicleValuationServiceUrl = exports.vehicleValuationServiceUrl = function vehicleValuationServiceUrl(state) {
  return extractNewUrl(getBaseUrl(state), vehicleValuationServicePath(state));
};

var vehiclesShortlistServicePath = exports.vehiclesShortlistServicePath = function vehiclesShortlistServicePath(state) {
  return state.config.settings.vehiclesShortlistServicePath;
};
var vehiclesShortListServiceUrl = exports.vehiclesShortListServiceUrl = function vehiclesShortListServiceUrl(state) {
  return extractNewUrl(getBaseUrl(state), vehiclesShortlistServicePath(state));
};

var testDrivesServicePath = exports.testDrivesServicePath = function testDrivesServicePath(state) {
  return state.config.settings.testDrivesServicePath;
};
var testDrivesServiceUrl = exports.testDrivesServiceUrl = function testDrivesServiceUrl(state) {
  return extractNewUrl(getBaseUrl(state), testDrivesServicePath(state));
};

var inventorySearchServicePath = exports.inventorySearchServicePath = function inventorySearchServicePath(state) {
  return state.config.settings.inventorySearchServicePath;
};

var inventorySearchServiceUrl = exports.inventorySearchServiceUrl = function inventorySearchServiceUrl(state) {
  return extractNewUrl(getBaseUrl(state), inventorySearchServicePath(state));
};

var myDealsServicePath = exports.myDealsServicePath = function myDealsServicePath(state) {
  return state.config.settings.myDealsServicePath;
};
var myDealsServiceUrl = exports.myDealsServiceUrl = function myDealsServiceUrl(state) {
  return extractNewUrl(getBaseUrl(state), myDealsServicePath(state));
};

var avlCustomersServicePath = exports.avlCustomersServicePath = function avlCustomersServicePath(state) {
  return state.config.settings.avlCustomersServicePath;
};
var avlCustomersServiceUrl = exports.avlCustomersServiceUrl = function avlCustomersServiceUrl(state) {
  return extractNewUrl(getBaseUrl(state), avlCustomersServicePath(state));
};

var appendLocaleQueryString = exports.appendLocaleQueryString = function appendLocaleQueryString(state) {
  return state.config.settings.appendLocaleQueryString;
};

var pdfGeneratorServiceUrl = exports.pdfGeneratorServiceUrl = function pdfGeneratorServiceUrl(state) {
  return state.config.settings.pdfGeneratorServiceUrl;
};

var currencyConversionServiceUrl = exports.currencyConversionServiceUrl = function currencyConversionServiceUrl(state) {
  return state.config.settings.currencyConversionServiceUrl;
};

var postsServicePath = exports.postsServicePath = function postsServicePath(state) {
  return state.config.settings.postsServicePath;
};
var postsServiceUrl = exports.postsServiceUrl = function postsServiceUrl(state) {
  return extractNewUrl(getBaseUrl(state), postsServicePath(state));
};

var getInventorySelector = exports.getInventorySelector = function getInventorySelector(state) {
  return state.config.config.global.inventory;
};

var getAVLConfig = exports.getAVLConfig = function getAVLConfig(state) {
  return {
    locale: getLocale(state),
    market: getMarket(state),
    country: getCountry(state),
    modelGroupCountry: getModelGroupCountry(state),
    make: getMake(state),
    siteId: getSiteId(state),
    currencyCode: getCurrencyCode(state),
    region: getRegion(state),
    searchCountryCode: getSearchCountryCode(state),
    territory: getTerritory(state),
    getSiteWideEmissionsUrl: getSiteWideEmissionsUrl(state),
    inventory: getInventorySelector(state)
  };
};

var getVehicleStoreConfig = exports.getVehicleStoreConfig = function getVehicleStoreConfig(state) {
  return {
    locale: getLocale(state),
    market: state.config.config.global.featureFlags && state.config.config.global.featureFlags.allowGlobalVehicleStore ? 'global' : getMarket(state),
    make: getMake(state),
    region: getRegion(state),
    country: getCountry(state)
  };
};

var getEnquiryFormPostMessageUrl = exports.getEnquiryFormPostMessageUrl = function getEnquiryFormPostMessageUrl(state) {
  return state.config.settings.enquiryFormPostMessageUrl;
};

var getUseEnquiryFormPostMessageUrl = exports.getUseEnquiryFormPostMessageUrl = function getUseEnquiryFormPostMessageUrl(state) {
  return state.config.settings.useEnquiryFormPostMessageUrl;
};

var getUseRegionsPresetFilters = exports.getUseRegionsPresetFilters = function getUseRegionsPresetFilters(state) {
  return state.config.settings.useRegionsPresetFilters;
};

var getMappedRegions = exports.getMappedRegions = function getMappedRegions(state) {
  return state.config.config.global.mappedRegions;
};

var getUseCountriesPresetFilters = exports.getUseCountriesPresetFilters = function getUseCountriesPresetFilters(state) {
  return state.config.settings.useCountriesPresetFilters;
};

var getMappedCountries = exports.getMappedCountries = function getMappedCountries(state) {
  return state.config.config.global.mappedCountries;
};

var getBrandLanguages = exports.getBrandLanguages = function getBrandLanguages(state) {
  return state.config.config.global.languages;
};
var getSelectedLanguage = exports.getSelectedLanguage = function getSelectedLanguage(state) {
  return state.shared.sessionPreferences.language;
};

var getGlobalStyling = exports.getGlobalStyling = function getGlobalStyling(state) {
  return state.config.config.global.globalStyling;
};

/** Gets Leads api url from Settings file in Reader project. */
var getLeadsApiUrl = exports.getLeadsApiUrl = function getLeadsApiUrl(state) {
  return state.config.settings.leadsApiUrl;
};

/** Indicates whether use or not Leads Api url value from Settings file.
 * This flag is set in settings/avl.json file, at Config project.
 * */
var getUseLeadsApiUrl = exports.getUseLeadsApiUrl = function getUseLeadsApiUrl(state) {
  return state.config.settings.useLeadsApiUrl;
};

var getUseNewModelExplore = exports.getUseNewModelExplore = function getUseNewModelExplore(state) {
  return state.config.settings.useAstonMartinModelExplore || state.config.settings.useNewModelExplore;
};