'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Badge = _styledComponents2.default.div.withConfig({
  displayName: 'OnDemandBadge__Badge',
  componentId: 'sc-igk34b-0'
})(['position:absolute;max-width:100%;margin:10px;height:25px;padding-left:6px;padding-right:6px;font-size:16px;color:', ';background-color:', ';font-family:', ';border:none;outline:none;display:flex;align-items:center;'], function (_ref) {
  var foregroundColor = _ref.foregroundColor;
  return foregroundColor.value;
}, function (_ref2) {
  var backgroundColor = _ref2.backgroundColor;
  return backgroundColor.value;
}, function (_ref3) {
  var font = _ref3.font;
  return font.value || 'inherit';
});

var OnDemandBadge = function OnDemandBadge(props) {
  return _react2.default.createElement(
    Badge,
    props,
    props.text
  );
};

exports.default = OnDemandBadge;