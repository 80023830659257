'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    flex-direction: column;\n    height: 132px;\n    justify-content: space-between;\n  '], ['\n    width: 100%;\n    flex-direction: column;\n    height: 132px;\n    justify-content: space-between;\n  ']);

exports.default = PersonalDataConsent;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactLinkify = require('react-linkify');

var _reactLinkify2 = _interopRequireDefault(_reactLinkify);

var _Global = require('./Global');

var _FormFields = require('./FormFields');

var _LabelledCheckBox = require('./LabelledCheckBox');

var _LabelledCheckBox2 = _interopRequireDefault(_LabelledCheckBox);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CheckboxContainer = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalDataConsent__CheckboxContainer',
  componentId: 'sc-5ixcno-0'
})(['display:flex;margin:20px 0;', ';'], _theme2.default.max.large(_templateObject));

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'PersonalDataConsent__Label',
  componentId: 'sc-5ixcno-1'
})(['font-size:16px;color:', ';margin-left:4px;'], function (_ref) {
  var error = _ref.error;
  return error ? '#9e1b32' : '#444444';
});

var Url = _styledComponents2.default.a.withConfig({
  displayName: 'PersonalDataConsent__Url',
  componentId: 'sc-5ixcno-2'
})(['color:', ';'], function (_ref2) {
  var theme = _ref2.theme;
  return theme.color;
});
// this has jag colour but we are using as error colour too
var ErrorLabel = _styledComponents2.default.span.withConfig({
  displayName: 'PersonalDataConsent__ErrorLabel',
  componentId: 'sc-5ixcno-3'
})(['color:#9e1b32;margin-left:15px;']);

var StyledLink = _styledComponents2.default.a.withConfig({
  displayName: 'PersonalDataConsent__StyledLink',
  componentId: 'sc-5ixcno-4'
})(['cursor:pointer;']);

var RadioButtonRow = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalDataConsent__RadioButtonRow',
  componentId: 'sc-5ixcno-5'
})(['display:flex;align-items:center;div:last-of-type{margin-left:40px;}']);

var RadioButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'PersonalDataConsent__RadioButtonWrapper',
  componentId: 'sc-5ixcno-6'
})(['display:flex;align-items:center;']);

var UserConsentPreferences = function UserConsentPreferences(_ref3) {
  var translations = _ref3.translations,
      onRadioSelect = _ref3.onRadioSelect,
      linkColour = _ref3.linkColour,
      marketingLink = _ref3.marketingLink,
      marketingKey = _ref3.marketingKey,
      marketing = _ref3.marketing,
      marketingError = _ref3.marketingError,
      customisedServices = _ref3.customisedServices,
      customisedServicesKey = _ref3.customisedServicesKey,
      customisedServicesError = _ref3.customisedServicesError;
  return _react2.default.createElement(
    'div',
    { style: { marginBottom: '20px' } },
    _react2.default.createElement(
      _Global.HeadingOne,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 16px;\n        font-weight: 600;\n        margin-bottom: 20px;';
        }
      },
      translations.marketingHeaderText
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 13px;\n        line-height: 16px;\n        margin-top: 20px;';
        }
      },
      _react2.default.createElement(
        _reactLinkify2.default,
        {
          component: function component(_ref4) {
            var children = _ref4.children,
                props = (0, _objectWithoutProperties3.default)(_ref4, ['children']);
            return _react2.default.createElement(
              Url,
              (0, _extends3.default)({}, props, { theme: { color: linkColour && linkColour.value } }),
              children
            );
          },
          properties: { target: '_blank' }
        },
        translations.marketingConsentContentOne
      )
    ),
    _react2.default.createElement(
      Url,
      {
        href: marketingLink,
        target: '_blank',
        theme: { color: linkColour && linkColour.value }
      },
      translations.marketingLinkText
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 13px;\n        line-height: 16px;\n        margin-top: 20px;';
        }
      },
      _react2.default.createElement(
        _reactLinkify2.default,
        {
          component: function component(_ref5) {
            var children = _ref5.children,
                props = (0, _objectWithoutProperties3.default)(_ref5, ['children']);
            return _react2.default.createElement(
              Url,
              (0, _extends3.default)({}, props, { theme: { color: linkColour && linkColour.value } }),
              children
            );
          },
          properties: { target: '_blank' }
        },
        translations.marketingConsentContentTwo
      )
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      { styleOverride: function styleOverride() {
          return 'text-transform: uppercase';
        } },
      translations.marketingConsentSubheaderOne
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 13px;\n        line-height: 16px;\n        margin-top: 20px;';
        }
      },
      _react2.default.createElement(
        _reactLinkify2.default,
        {
          component: function component(_ref6) {
            var children = _ref6.children,
                props = (0, _objectWithoutProperties3.default)(_ref6, ['children']);
            return _react2.default.createElement(
              Url,
              (0, _extends3.default)({}, props, { theme: { color: linkColour && linkColour.value } }),
              children
            );
          },
          properties: { target: '_blank' }
        },
        translations.marketingConsentContentThree
      )
    ),
    _react2.default.createElement(
      RadioButtonRow,
      null,
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement('input', {
          checked: marketing === 'yes',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'marketingYes',
          name: marketingKey,
          value: 'yes'
        }),
        _react2.default.createElement(
          Label,
          { error: marketingError, htmlFor: 'marketingYes' },
          translations.marketingConsentYes
        )
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement('input', {
          checked: marketing === 'no',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'marketingNo',
          name: marketingKey,
          value: 'no'
        }),
        _react2.default.createElement(
          Label,
          { error: marketingError, htmlFor: 'marketingNo' },
          translations.marketingConsentNo
        )
      ),
      marketingError && _react2.default.createElement(
        ErrorLabel,
        null,
        translations.formValidationRequired
      )
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      { styleOverride: function styleOverride() {
          return 'text-transform: uppercase';
        } },
      translations.marketingConsentSubheaderTwo
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 13px;\n        line-height: 16px;\n        margin-top: 20px;';
        }
      },
      _react2.default.createElement(
        _reactLinkify2.default,
        {
          component: function component(_ref7) {
            var children = _ref7.children,
                props = (0, _objectWithoutProperties3.default)(_ref7, ['children']);
            return _react2.default.createElement(
              Url,
              (0, _extends3.default)({}, props, { theme: { color: linkColour && linkColour.value } }),
              children
            );
          },
          properties: { target: '_blank' }
        },
        translations.marketingConsentContentFour
      )
    ),
    _react2.default.createElement(
      RadioButtonRow,
      null,
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement('input', {
          checked: customisedServices === 'yes',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'marketingYes',
          name: customisedServicesKey,
          value: 'yes'
        }),
        _react2.default.createElement(
          Label,
          { error: customisedServicesError, htmlFor: 'marketingYes' },
          translations.marketingConsentYes
        )
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement('input', {
          checked: customisedServices === 'no',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'marketingNo',
          name: customisedServicesKey,
          value: 'no'
        }),
        _react2.default.createElement(
          Label,
          { error: customisedServicesError, htmlFor: 'marketingNo' },
          translations.marketingConsentNo
        )
      ),
      customisedServicesError && _react2.default.createElement(
        ErrorLabel,
        null,
        translations.formValidationRequired
      )
    )
  );
};

var UserCommunicationsPreferenceDialogueType = function UserCommunicationsPreferenceDialogueType(properties) {
  var onCheckBoxClick = properties.onCheckBoxClick,
      phoneKey = properties.phoneKey,
      phoneChecked = properties.phoneChecked,
      translations = properties.translations,
      onColour = properties.onColour,
      enquiryFormStyles = properties.enquiryFormStyles,
      labelFontSize = properties.labelFontSize,
      checkboxDimension = properties.checkboxDimension,
      globalStyling = properties.globalStyling,
      emailKey = properties.emailKey,
      emailChecked = properties.emailChecked,
      smsChecked = properties.smsChecked,
      smsKey = properties.smsKey,
      showFullConsentSection = properties.showFullConsentSection,
      handleRadioChange = properties.handleRadioChange,
      consentRadioOptions = properties.consentRadioOptions,
      enquiryConsentTypes = properties.enquiryConsentTypes;

  return !showFullConsentSection ? _react2.default.createElement(
    CheckboxContainer,
    null,
    enquiryConsentTypes && enquiryConsentTypes.consentPhone && _react2.default.createElement(_LabelledCheckBox2.default, {
      onClick: function onClick() {
        return onCheckBoxClick(phoneKey, !phoneChecked);
      },
      checked: phoneChecked,
      label: translations.dataConsentPhoneLabel,
      onColour: onColour,
      labelFontSize: enquiryFormStyles.labelFontSize || labelFontSize,
      checkboxDimension: checkboxDimension,
      globalStyling: globalStyling
    }),
    enquiryConsentTypes && enquiryConsentTypes.consentEmail && _react2.default.createElement(_LabelledCheckBox2.default, {
      onClick: function onClick() {
        return onCheckBoxClick(emailKey, !emailChecked);
      },
      checked: emailChecked,
      label: translations.dataConsentEmailLabel,
      onColour: onColour,
      labelFontSize: enquiryFormStyles.labelFontSize || labelFontSize,
      checkboxDimension: checkboxDimension,
      globalStyling: globalStyling
    }),
    enquiryConsentTypes && enquiryConsentTypes.consentSMS && _react2.default.createElement(_LabelledCheckBox2.default, {
      onClick: function onClick() {
        return onCheckBoxClick(smsKey, !smsChecked);
      },
      checked: smsChecked,
      label: translations.dataConsentSmsLabel,
      onColour: onColour,
      labelFontSize: enquiryFormStyles.labelFontSize || labelFontSize,
      checkboxDimension: checkboxDimension,
      globalStyling: globalStyling
    })
  ) : _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 13px;\n        line-height: 16px;\n        margin: 20px 0 0;\n        ' + (0, _Global.fontStyleOverride)(enquiryFormStyles.paragraphFont) + '\n        ';
        }
      },
      translations.additionalDataConsent
    ),
    _react2.default.createElement(
      'div',
      { style: { margin: '10px 0 0' } },
      _react2.default.createElement(_FormFields.RadioGroup, {
        radioOptions: consentRadioOptions,
        handleRadioChange: handleRadioChange,
        translations: translations,
        globalStyling: globalStyling,
        labelFont: enquiryFormStyles.paragraphFont
      })
    )
  );
};

var UserCommunicationPreferences = function UserCommunicationPreferences(_ref8) {
  var translations = _ref8.translations,
      onCheckBoxClick = _ref8.onCheckBoxClick,
      phoneChecked = _ref8.phoneChecked,
      phoneKey = _ref8.phoneKey,
      emailChecked = _ref8.emailChecked,
      emailKey = _ref8.emailKey,
      smsChecked = _ref8.smsChecked,
      smsKey = _ref8.smsKey,
      onColour = _ref8.onColour,
      labelFontSize = _ref8.labelFontSize,
      checkboxDimension = _ref8.checkboxDimension,
      linkColour = _ref8.linkColour,
      _ref8$enquiryFormStyl = _ref8.enquiryFormStyles,
      enquiryFormStyles = _ref8$enquiryFormStyl === undefined ? {} : _ref8$enquiryFormStyl,
      globalStyling = _ref8.globalStyling,
      handleRadioChange = _ref8.handleRadioChange,
      showFullConsentSection = _ref8.showFullConsentSection,
      consentRadioOptions = _ref8.consentRadioOptions,
      enquiryConsentTypes = _ref8.enquiryConsentTypes;

  var translateLinkFromTemplate = function translateLinkFromTemplate(template, linkUrl, linkText) {
    var Link = function Link() {
      return _react2.default.createElement(
        StyledLink,
        { href: linkUrl, target: '_blank' },
        linkText
      );
    };
    var textArray = template.split('{PRIVACY_LINK}');

    return textArray.length > 1 ? textArray.toSpliced(1, 0, _react2.default.createElement(Link, { href: linkUrl })) : textArray;
  };

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _Global.HeadingOne,
      {
        styleOverride: function styleOverride() {
          return '\n          font-size: 16px;\n          font-weight: 600;\n          margin-bottom: 20px;\n          ' + (0, _Global.fontStyleOverride)(enquiryFormStyles.headerFont) + '\n          ';
        }
      },
      translations.dataConsentHeader
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 13px;\n        line-height: 16px;\n        margin: 20px 0 0;\n        ' + (0, _Global.fontStyleOverride)(enquiryFormStyles.paragraphFont) + '\n        ';
        }
      },
      translateLinkFromTemplate(translations.dataConsentContent, translations.privacyPolicyLinkUrl, translations.privacyPolicyLinkText)
    ),
    translations.dataConsentContactHeader && _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n            font-size: 16px;\n            line-height: 16px;\n            margin: 30px 0 0';
        }
      },
      translations.dataConsentContactHeader
    ),
    _react2.default.createElement(UserCommunicationsPreferenceDialogueType, {
      translations: translations,
      onCheckBoxClick: onCheckBoxClick,
      phoneChecked: phoneChecked,
      phoneKey: phoneKey,
      emailChecked: emailChecked,
      emailKey: emailKey,
      smsChecked: smsChecked,
      smsKey: smsKey,
      onColour: onColour,
      labelFontSize: labelFontSize,
      checkboxDimension: checkboxDimension,
      linkColour: linkColour,
      enquiryFormStyles: enquiryFormStyles,
      globalStyling: globalStyling,
      handleRadioChange: handleRadioChange,
      showFullConsentSection: showFullConsentSection,
      consentRadioOptions: consentRadioOptions,
      enquiryConsentTypes: enquiryConsentTypes
    })
  );
};

function PersonalDataConsent(props) {
  return _react2.default.createElement(
    'div',
    null,
    props.showUserCommunication ? _react2.default.createElement(UserCommunicationPreferences, props) : _react2.default.createElement(UserConsentPreferences, props)
  );
}