'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  width: 350px;\n'], ['\n  width: 350px;\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n                font-size: 13px;\n              '], ['\n                font-size: 13px;\n              ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n      flex-basis: ', ';\n      ', ';\n    '], ['\n      flex-basis: ', ';\n      ', ';\n    ']);

exports.default = SearchResultBlock;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _Global = require('./Global');

var _Pin = require('./JaguarIcons/Global/Pin');

var _Pin2 = _interopRequireDefault(_Pin);

var _Efficiency = require('./JaguarIcons/VDP/Efficiency');

var _Efficiency2 = _interopRequireDefault(_Efficiency);

var _Mileage = require('./JaguarIcons/VDP/Mileage');

var _Mileage2 = _interopRequireDefault(_Mileage);

var _Power = require('./JaguarIcons/VDP/Power');

var _Power2 = _interopRequireDefault(_Power);

var _Manual = require('./JaguarIcons/Search/Manual');

var _Manual2 = _interopRequireDefault(_Manual);

var _Fuel = require('./JaguarIcons/VDP/Fuel');

var _Fuel2 = _interopRequireDefault(_Fuel);

var _Heart = require('./JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

var _Compare = require('./JaguarIcons/Global/Compare');

var _Compare2 = _interopRequireDefault(_Compare);

var _Calendar = require('./JaguarIcons/VDP/Calendar');

var _Calendar2 = _interopRequireDefault(_Calendar);

var _numbers = require('../shared/localisation/numbers');

var _dates = require('../shared/localisation/dates');

var _FinancePopup = require('./FinancePopup');

var _FinancePopup2 = _interopRequireDefault(_FinancePopup);

var _OfferTermsPopup = require('./OfferTermsPopup');

var _OfferTermsPopup2 = _interopRequireDefault(_OfferTermsPopup);

var _WarrantyBadge = require('./WarrantyBadge');

var _WarrantyBadge2 = _interopRequireDefault(_WarrantyBadge);

var _vehicle = require('../helpers/vehicle');

var _getWarrantyLabel = require('../helpers/getWarrantyLabel');

var _OnDemandBadge = require('./OnDemandBadge');

var _OnDemandBadge2 = _interopRequireDefault(_OnDemandBadge);

var _helpers = require('../shared/currencyConversion/helpers');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _Spinner = require('./Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _SearchCo2Accordian = require('./SearchCo2Accordian');

var _SearchCo2Accordian2 = _interopRequireDefault(_SearchCo2Accordian);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _SearchResultEmissions = require('./SearchResultEmissions');

var _SearchResultEmissions2 = _interopRequireDefault(_SearchResultEmissions);

var _emissionSrpText = require('../components/Kia/emissionSrpText');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__Container',
  componentId: 'sc-18sy1b6-0'
})(['width:100%;margin-bottom:20px;display:flex;border:1px solid #d8d8d8;box-sizing:border-box;', ';'], function (_ref) {
  var backgroundColour = _ref.backgroundColour;
  return backgroundColour && 'background-color: ' + backgroundColour.value;
});

var ImageSection = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__ImageSection',
  componentId: 'sc-18sy1b6-1'
})(['&:hover{cursor:pointer;}position:relative;margin:auto;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultBlock__Image',
  componentId: 'sc-18sy1b6-2'
})(['', ';height:auto;max-width:', ';display:flex;'], _theme2.default.max.extraLarge(_templateObject), function (_ref2) {
  var maxWidth = _ref2.maxWidth;
  return (maxWidth || 400) + 'px';
});

var VehicleInfoSection = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__VehicleInfoSection',
  componentId: 'sc-18sy1b6-3'
})(['']);

var Line = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__Line',
  componentId: 'sc-18sy1b6-4'
})(['display:', ';border-bottom:1px solid #dedede;margin:0;width:100%;'], function (_ref3) {
  var visible = _ref3.visible;
  return visible === true ? 'block' : 'none';
});

var ThreeSixty = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__ThreeSixty',
  componentId: 'sc-18sy1b6-5'
})(['background:#000000;opacity:0.4;padding:4px;min-height:34px;position:absolute;z-index:20;top:0;', ';'], function (_ref4) {
  var direction = _ref4.direction;
  return direction === 'rtl' ? 'left: 0;' : 'right: 0;';
});

var YoutubeVideo = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__YoutubeVideo',
  componentId: 'sc-18sy1b6-6'
})(['background:#000000;opacity:0.4;padding:4px;position:absolute;z-index:20;', ';', ';'], function (_ref5) {
  var direction = _ref5.direction;
  return direction === 'rtl' ? 'left: 0;' : 'right: 0;';
}, function (_ref6) {
  var panoramicViews = _ref6.panoramicViews;
  return panoramicViews.exterior || panoramicViews.interior ? 'top: 42px;' : 'top: 0;';
});

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultBlock__Icon',
  componentId: 'sc-18sy1b6-7'
})(['height:30px;width:30px;']);

var ActionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__ActionsContainer',
  componentId: 'sc-18sy1b6-8'
})(['display:flex;justify-content:space-between;margin:5px 0;span{color:#444;font-size:15px;font-weight:600;margin-left:10px;', ';', ';', ';', ';', ';}'], function (_ref7) {
  var direction = _ref7.direction;
  return direction === 'rtl' && 'margin-right: 5px;';
}, function (_ref8) {
  var actionRowStyles = _ref8.actionRowStyles;
  return actionRowStyles && actionRowStyles.fontSize && 'font-size: ' + actionRowStyles.fontSize + 'px';
}, function (_ref9) {
  var actionRowStyles = _ref9.actionRowStyles;
  return actionRowStyles && actionRowStyles.colour && 'color: ' + actionRowStyles.colour.value;
}, function (_ref10) {
  var actionRowStyles = _ref10.actionRowStyles;
  return actionRowStyles && actionRowStyles.margin && 'margin: ' + actionRowStyles.margin;
}, function (_ref11) {
  var actionRowStyles = _ref11.actionRowStyles;
  return actionRowStyles && 'font-weight: normal';
});

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__IconWrapper',
  componentId: 'sc-18sy1b6-9'
})(['display:flex;align-items:center;cursor:pointer;font-size:12px;color:#1f1f1f;justify-content:center;width:50%;']);

var VehicleSpecs = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__VehicleSpecs',
  componentId: 'sc-18sy1b6-10'
})(['display:flex;flex-wrap:wrap;', ';', ';', ';', ';'], function (_ref12) {
  var padding = _ref12.padding;
  return padding && padding.paddingLeft && 'padding-left: ' + padding.paddingLeft + 'px';
}, function (_ref13) {
  var padding = _ref13.padding;
  return padding && padding.paddingRight && 'padding-right: ' + padding.paddingRight + 'px';
}, function (_ref14) {
  var padding = _ref14.padding;
  return padding && padding.paddingTop && 'padding-top: ' + padding.paddingTop + 'px';
}, function (_ref15) {
  var lamboVehicleSpecs = _ref15.lamboVehicleSpecs;
  return lamboVehicleSpecs && 'min-height: 120px;';
});

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'ImageOverlay'
}).withConfig({
  displayName: 'SearchResultBlock__ImageOverlay',
  componentId: 'sc-18sy1b6-11'
})(['justify-content:center;align-items:center;display:flex;flex-direction:column;position:absolute;top:0;left:0;right:0;bottom:0;color:#ffffff;background-color:rgb(0,0,0,0.5);cursor:default;']);

var SoldHeading = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__SoldHeading',
  componentId: 'sc-18sy1b6-12'
})(['font-size:20px;font-weight:600;']);

var SoldBody = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__SoldBody',
  componentId: 'sc-18sy1b6-13'
})(['font-size:14px;max-width:165px;']);

var VehiclePriceSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehiclePriceSection'
}).withConfig({
  displayName: 'SearchResultBlock__VehiclePriceSection',
  componentId: 'sc-18sy1b6-14'
})(['display:flex;flex-wrap:wrap;align-content:space-between;', ';padding:0 10px;', ';', ';width:', ';', ';max-height:295px;'], function (_ref16) {
  var flexDirection = _ref16.flexDirection;
  return flexDirection && 'flex-direction: column';
}, function (_ref17) {
  var showBorder = _ref17.showBorder;
  return showBorder && 'border-left: 1px solid #D3D3D3';
}, function (_ref18) {
  var direction = _ref18.direction;
  return direction && 'border-left: none';
}, function (_ref19) {
  var width = _ref19.width;
  return width + '%';
}, function (_ref20) {
  var direction = _ref20.direction,
      showBorder = _ref20.showBorder;
  return direction === 'rtl' && showBorder && 'border-right: 1px solid #D3D3D3';
});

var FinanceRow = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceRow'
}).withConfig({
  displayName: 'SearchResultBlock__FinanceRow',
  componentId: 'sc-18sy1b6-15'
})(['width:100%;display:flex;flex-direction:column;']);
var ActionsRow = _styledComponents2.default.div.withConfig({
  displayName: 'ActionsRow'
}).withConfig({
  displayName: 'SearchResultBlock__ActionsRow',
  componentId: 'sc-18sy1b6-16'
})(['display:flex;flex-direction:column;width:100%;margin-top:auto;']);

var FinanceRowContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceRowContainer'
}).withConfig({
  displayName: 'SearchResultBlock__FinanceRowContainer',
  componentId: 'sc-18sy1b6-17'
})(['width:100%;']);

var Cell = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceCell'
}).withConfig({
  displayName: 'SearchResultBlock__Cell',
  componentId: 'sc-18sy1b6-18'
})(['display:', ';width:', ';border-right:', ';', ';box-sizing:border-box;&:last-child{width:', ';border-right:0;padding-left:', ';}', ';', ';&&&&{', ';}'], function (_ref21) {
  var visible = _ref21.visible;
  return visible === true ? 'block' : 'none';
}, function (_ref22) {
  var financeWidthOverride = _ref22.financeWidthOverride;
  return financeWidthOverride ? financeWidthOverride + '%' : '65%';
}, function (_ref23) {
  var showRightBorder = _ref23.showRightBorder;
  return showRightBorder === true ? '1px solid #d3d3d3' : '0';
}, function (_ref24) {
  var noPadding = _ref24.noPadding,
      horizontalPadding = _ref24.horizontalPadding;
  return 'padding: ' + (noPadding ? 0 : 10) + 'px\n    ' + (horizontalPadding !== undefined ? horizontalPadding : 20) + 'px\n    0px';
}, function (_ref25) {
  var financeWidthOverride = _ref25.financeWidthOverride;
  return financeWidthOverride ? financeWidthOverride + '%' : '35%';
}, function (_ref26) {
  var horizontalPadding = _ref26.horizontalPadding;
  return (horizontalPadding !== undefined ? horizontalPadding : 20) + 'px';
}, function (_ref27) {
  var financePaddingOverride = _ref27.financePaddingOverride;
  return financePaddingOverride && 'padding: 0;';
}, function (_ref28) {
  var hideBorders = _ref28.hideBorders;
  return hideBorders === true && 'border-right: 0;';
}, function (_ref29) {
  var unlockWidth = _ref29.unlockWidth;
  return unlockWidth === true && 'width: 100%;';
});

var AvailabilityLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__AvailabilityLabel',
  componentId: 'sc-18sy1b6-19'
})(['', ';', ';', ' ', ' ', ''], function (_ref30) {
  var bold = _ref30.bold;
  return bold && 'font-weight: bold;';
}, function (_ref31) {
  var size = _ref31.size;
  return 'font-size: ' + size + 'px;';
}, function (_ref32) {
  var dateTextColor = _ref32.dateTextColor;
  return dateTextColor && 'color: ' + dateTextColor + ';';
}, function (_ref33) {
  var padding = _ref33.padding;
  return padding && 'padding: ' + padding + ';';
}, function (_ref34) {
  var sidePadding = _ref34.sidePadding;
  return sidePadding && 'padding: ' + sidePadding + ';';
});

var VehicleInfoSectionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__VehicleInfoSectionContainer',
  componentId: 'sc-18sy1b6-20'
})(['display:flex;flex-direction:column;flex:1;', ';'], function (_ref35) {
  var vehicleTitleFont = _ref35.vehicleTitleFont;
  return vehicleTitleFont && vehicleTitleFont.margin ? vehicleTitleFont.margin : 'margin: 0';
});

var FinanceSpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__FinanceSpinnerWrapper',
  componentId: 'sc-18sy1b6-21'
})(['display:flex;flex-direction:row;padding:0 15px;']);

var ValueAsImage = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultBlock__ValueAsImage',
  componentId: 'sc-18sy1b6-22'
})(['max-width:80px;padding:2px 0;']);

var CarBasePriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__CarBasePriceContainer',
  componentId: 'sc-18sy1b6-23'
})(['display:flex;justify-content:space-between;']);

var OtherAmountContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__OtherAmountContainer',
  componentId: 'sc-18sy1b6-24'
})(['display:flex;justify-content:space-between;']);

var EmissionsStringText = (0, _styledComponents2.default)(_Global.Paragraph).withConfig({
  displayName: 'SearchResultBlock__EmissionsStringText',
  componentId: 'sc-18sy1b6-25'
})(['margin:0;&:last-child{margin-bottom:10px;}']);

var generateSubTitle = function generateSubTitle(vehicle, subtitleData, locale, hideSubtitles) {
  if (hideSubtitles) {
    return null;
  }
  if (subtitleData) {
    return (0, _vehicle.vehicleDataFormatter)(subtitleData, vehicle, locale, ', ');
  }
  var shortVehicleTitles = vehicle.shortVehicleTitles,
      exterior = vehicle.exterior,
      odometer = vehicle.odometer,
      transmission = vehicle.transmission,
      fuel = vehicle.fuel;

  return (shortVehicleTitles ? [fuel, exterior] : [exterior, odometer.display, transmission, fuel]).filter(Boolean).join(' | ');
};

var getVehicleTitle = function getVehicleTitle(vehicle, hideYear, titleBrandPrefix) {
  var brandName = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.make;
  });
  return hideYear ? titleBrandPrefix ? brandName + ' ' + vehicle.description : '' + vehicle.description : vehicle.registration + ' ' + vehicle.description;
};

var VehicleTitle = function VehicleTitle(_ref36) {
  var vehicle = _ref36.vehicle,
      vehicleSubTitleFont = _ref36.vehicleSubTitleFont,
      vehicleTitleFont = _ref36.vehicleTitleFont,
      locale = _ref36.locale,
      hideYear = _ref36.hideYear,
      titleBrandPrefix = _ref36.titleBrandPrefix,
      shortVehicleTitles = _ref36.shortVehicleTitles,
      subtitleData = _ref36.subtitleData,
      onMoreInfoClick = _ref36.onMoreInfoClick,
      hideSubTitles = _ref36.hideSubTitles;
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        onClick: function onClick() {
          return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
        },
        styleOverride: function styleOverride() {
          return '\n              cursor: pointer;\n              text-transform: capitalize;\n              ' + (0, _Global.fontStyleOverride)(vehicleTitleFont) + '\n          ';
        }
      },
      getVehicleTitle(vehicle, hideYear, titleBrandPrefix)
    ),
    _react2.default.createElement(
      _Global.HeadingThree,
      {
        styleOverride: function styleOverride() {
          return '\n            ' + (0, _Global.fontStyleOverride)(vehicleSubTitleFont) + '\n              ' + _theme2.default.max.extraLarge(_templateObject2) + ';\n          ';
        }
      },
      generateSubTitle((0, _extends3.default)({}, vehicle, { shortVehicleTitles: shortVehicleTitles }), subtitleData, locale, hideSubTitles)
    )
  );
};

var IconWithLabel = function IconWithLabel(_ref37) {
  var text = _ref37.text,
      children = _ref37.children,
      onClickFn = _ref37.onClickFn,
      qaHook = _ref37.qaHook;
  return _react2.default.createElement(
    IconWrapper,
    { 'data-cy': qaHook, onClick: onClickFn, 'aria-label': text },
    children,
    _react2.default.createElement(
      'span',
      { style: { fontSize: '12px' } },
      text
    )
  );
};

var displayRetailerIcon = function displayRetailerIcon(vehicle, vehicleSpecContainer) {
  return vehicle.retailerName && vehicleSpecContainer && vehicleSpecContainer.icons && vehicleSpecContainer.icons.locationIcon;
};

var DistanceFromRetailer = function DistanceFromRetailer(_ref38) {
  var vehicle = _ref38.vehicle,
      colour = _ref38.colour;
  return _react2.default.createElement(
    'div',
    {
      style: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        bottom: '0'
      }
    },
    _react2.default.createElement(_Pin2.default, { width: '1.5em', height: '1.5em', colour: colour || '#32363F' }),
    _react2.default.createElement(
      'span',
      { style: { color: '#444', fontSize: '12px', marginLeft: '10px' } },
      vehicle.distance > 0 && vehicle.distance + ' km - ',
      vehicle.retailerName
    )
  );
};

var IconWithInfoWrapper = function IconWithInfoWrapper(_ref39) {
  var label = _ref39.label,
      value = _ref39.value,
      interiorSecondary = _ref39.interiorSecondary,
      children = _ref39.children,
      labelFontStyle = _ref39.labelFontStyle,
      valueAsImage = _ref39.valueAsImage;

  if (!value) return null;

  var Wrapper = _styledComponents2.default.div.withConfig({
    displayName: 'SearchResultBlock__Wrapper',
    componentId: 'sc-18sy1b6-26'
  })(['display:flex;align-items:flex-start;padding:', ';flex:0 0 100%;', ';min-width:30%;max-width:150px;'], labelFontStyle ? '10px 0 10px 10px' : '2px', _theme2.default.min.extraLarge(_templateObject3, labelFontStyle ? 'auto' : '48%', interiorSecondary && 'flex-basis: 100%; max-width: 61%'));

  return _react2.default.createElement(
    Wrapper,
    { labelFontStyle: labelFontStyle },
    children,
    _react2.default.createElement(
      'div',
      {
        style: {
          marginLeft: labelFontStyle && labelFontStyle.iconMargin ? labelFontStyle.iconMargin.marginLeft : '9px',
          marginRight: labelFontStyle && labelFontStyle.iconMargin && labelFontStyle.iconMargin.marginRight
        }
      },
      _react2.default.createElement(
        'div',
        {
          style: {
            lineHeight: labelFontStyle && labelFontStyle.labelFont ? labelFontStyle.labelFont.lineHeight : '18px',
            color: labelFontStyle && labelFontStyle.labelFont ? labelFontStyle.labelFont.color : '#545454',
            fontSize: labelFontStyle && labelFontStyle.labelFont ? labelFontStyle.labelFont.fontSize : '12px'
          }
        },
        label
      ),
      _react2.default.createElement(
        'div',
        {
          style: {
            lineHeight: labelFontStyle && labelFontStyle.valueFont ? labelFontStyle.valueFont.lineHeight : '18px',
            color: labelFontStyle && labelFontStyle.valueFont ? labelFontStyle.valueFont.color : '#444',
            fontWeight: labelFontStyle && labelFontStyle.valueFont ? 'normal' : '600',
            fontSize: labelFontStyle && labelFontStyle.valueFont ? labelFontStyle.valueFont.fontSize : '12px'
          }
        },
        valueAsImage ? _react2.default.createElement(ValueAsImage, { src: valueAsImage }) : value
      )
    )
  );
};

var IconWithInfo = function IconWithInfo(_ref40) {
  var enabled = _ref40.enabled,
      label = _ref40.label,
      value = _ref40.value,
      labelFontStyle = _ref40.labelFontStyle,
      iconWidth = _ref40.iconWidth,
      iconHeight = _ref40.iconHeight,
      iconSrc = _ref40.iconSrc,
      CustomIcon = _ref40.CustomIcon,
      valueAsImage = _ref40.valueAsImage,
      IconSpacer = _ref40.IconSpacer;
  return enabled ? _react2.default.createElement(
    IconWithInfoWrapper,
    {
      label: label,
      value: value,
      labelFontStyle: labelFontStyle,
      valueAsImage: valueAsImage
    },
    iconSrc ? _react2.default.createElement(
      'div',
      null,
      _react2.default.createElement('img', {
        style: {
          width: iconWidth,
          height: iconHeight
        },
        src: iconSrc,
        alt: label,
        colour: '#acacac'
      })
    ) : CustomIcon && _react2.default.createElement(CustomIcon, { width: iconWidth, height: iconHeight, colour: '#acacac' }) || IconSpacer && _react2.default.createElement('div', {
      style: {
        width: iconWidth,
        height: iconHeight
      }
    })
  ) : null;
};

var FinanceCell = function FinanceCell(_ref41) {
  var headerText = _ref41.headerText,
      subHeaderText = _ref41.subHeaderText,
      financeIcon = _ref41.financeIcon,
      visible = _ref41.visible,
      showRightBorder = _ref41.showRightBorder,
      _ref41$renderChild = _ref41.renderChild,
      renderChild = _ref41$renderChild === undefined ? function () {} : _ref41$renderChild,
      financeHeadingFont = _ref41.financeHeadingFont,
      horizontalPadding = _ref41.horizontalPadding,
      financeSubHeadingFont = _ref41.financeSubHeadingFont,
      hideBorders = _ref41.hideBorders,
      secondaryHeaderText = _ref41.secondaryHeaderText,
      priceStyle = _ref41.priceStyle,
      offerPriceStyle = _ref41.offerPriceStyle,
      specialOfferStyles = _ref41.specialOfferStyles,
      noPadding = _ref41.noPadding,
      offerTerms = _ref41.offerTerms,
      _ref41$translations = _ref41.translations,
      translations = _ref41$translations === undefined ? {} : _ref41$translations,
      vehicle = _ref41.vehicle,
      differentialTaxation = _ref41.differentialTaxation;

  var childComponent = renderChild();
  return _react2.default.createElement(
    Cell,
    {
      visible: visible,
      showRightBorder: showRightBorder,
      horizontalPadding: horizontalPadding,
      hideBorders: hideBorders,
      noPadding: noPadding,
      unlockWidth: true
    },
    _react2.default.createElement(
      'div',
      {
        style: {
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column'
        }
      },
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n          margin: 0;\n            ' + (priceStyle ? (0, _Global.fontStyleOverride)(priceStyle) : offerPriceStyle ? (0, _Global.fontStyleOverride)(offerPriceStyle) : (0, _Global.fontStyleOverride)(financeHeadingFont)) + ';\n             font-size: ' + (priceStyle && priceStyle.listFontSize || offerPriceStyle && offerPriceStyle.listFontSize) + 'px};\n            ';
          }
        },
        headerText
      ),
      financeIcon && _react2.default.createElement(
        'div',
        { style: { paddingLeft: '5px' } },
        financeIcon
      ),
      offerTerms && _react2.default.createElement(
        'div',
        { style: { paddingLeft: '6px' } },
        _react2.default.createElement(_OfferTermsPopup2.default, {
          modalTitle: offerTerms.offerTermModalTitle,
          offerDetailsLinkText: offerTerms.offerTermModalLinkText,
          offerTermText: offerTerms.offerTermText,
          offerDetailsUrl: offerTerms.offerDetailsUrl,
          colourValue: offerPriceStyle && offerPriceStyle.colour.value
        })
      ),
      translations && translations.searchResultsSubHeader && _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n              display: flex;\n              width: 100%;\n              justify-content: flex-start;\n              font-size: 14px;\n              color: #999;\n              margin: 0;\n            ' + (0, _Global.fontStyleOverride)(specialOfferStyles ? specialOfferStyles.offerTextStyle : financeSubHeadingFont) + ';';
          }
        },
        translations && translations.searchResultsSubHeader
      ),
      differentialTaxation && _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n            display: flex;\n            width: 100%;\n            justify-content: flex-start;\n            font-size: 14px;\n            color: #999;\n            margin: 0;\n            ' + (0, _Global.fontStyleOverride)(financeSubHeadingFont) + ';';
          }
        },
        differentialTaxation
      )
    ),
    secondaryHeaderText && _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n            ' + (0, _Global.fontStyleOverride)(financeHeadingFont) + ';\n            margin: 0;\n          ';
        }
      },
      secondaryHeaderText
    ),
    translations.priceIncludesVat && _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n          display: flex;\n          width: 100%;\n          -webkit-box-pack: start;\n          justify-content: flex-start;\n          margin: 0px;\n          font-family: kia_medium, Arial, sans-serif;\n          font-size: 13px;\n          color: rgb(102, 102, 102);\n          padding-bottom: 14px;\n          padding-left: 10px;\n        ';
        }
      },
      (0, _translateFromTemplate.translateFromTemplate)('priceIncludesVat', {
        VALUE: vehicle.retailerCountryCode === 'BE' ? translations.vatRateBelgium : vehicle.retailerCountryCode === 'LU' ? translations.vatRateLuxembourg : '%'
      }, translations)
    ),
    vehicle && vehicle.priceExcludesVat === 0 && vehicle.vatQualifyingVehicle === 0 && subHeaderText && _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n              display: flex;\n              width: 100%;\n              justify-content: flex-start;\n              font-size: 14px;\n              color: #545454;\n              margin: 0;\n            ' + (0, _Global.fontStyleOverride)(specialOfferStyles ? specialOfferStyles.offerTextStyle : financeSubHeadingFont) + ';';
        }
      },
      subHeaderText
    ),
    vehicle && vehicle.priceExcludesVat > 0 && _react2.default.createElement(
      _Global.HeadingThree,
      {
        styleOverride: function styleOverride() {
          return '\n          display: flex;\n          width: 100%;\n          justify-content: flex-start;\n          font-size: 14px;\n          color: #545454;\n        ' + (0, _Global.fontStyleOverride)(specialOfferStyles ? specialOfferStyles.offerTextStyle : financeSubHeadingFont) + ';';
        }
      },
      translations.priceExcludesVat
    ),
    vehicle && vehicle.vatQualifyingVehicle > 0 && _react2.default.createElement(
      _Global.HeadingThree,
      {
        styleOverride: function styleOverride() {
          return '\n          display: flex;\n          width: 100%;\n          justify-content: flex-start;\n          font-size: 14px;\n          color: #545454;\n        ' + (0, _Global.fontStyleOverride)(specialOfferStyles ? specialOfferStyles.offerTextStyle : financeSubHeadingFont) + ';';
        }
      },
      translations.vatQualifyingVehicle
    ),
    childComponent && _react2.default.createElement(
      'div',
      {
        style: {
          display: 'flex',
          position: 'absolute',
          top: 5,
          right: 0,
          zIndex: 100
        }
      },
      childComponent
    )
  );
};

var BottomInfo = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__BottomInfo',
  componentId: 'sc-18sy1b6-27'
})(['border-top:1px solid #dedede;display:flex;margin-top:auto;min-height:26px;padding:', ';'], function (_ref42) {
  var vehicleSpecContainer = _ref42.vehicleSpecContainer;
  return vehicleSpecContainer && vehicleSpecContainer.bottomInfoPaddingLeft ? '10px 0 10px ' + vehicleSpecContainer.bottomInfoPaddingLeft + 'px' : '5px 0';
});

var EmissionsInfo = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__EmissionsInfo',
  componentId: 'sc-18sy1b6-28'
})(['border-top:1px solid #dedede;display:flex;margin-top:auto;padding:', ';'], function (_ref43) {
  var vehicleSpecContainer = _ref43.vehicleSpecContainer;
  return vehicleSpecContainer && vehicleSpecContainer.bottomInfoPaddingLeft ? '10px 0 10px ' + vehicleSpecContainer.bottomInfoPaddingLeft + 'px' : '10px 0';
});

var Co2ConsumptionInformationSection = (0, _styledComponents2.default)(BottomInfo).withConfig({
  displayName: 'SearchResultBlock__Co2ConsumptionInformationSection',
  componentId: 'sc-18sy1b6-29'
})(['']);

var FinanceLoadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__FinanceLoadingWrapper',
  componentId: 'sc-18sy1b6-30'
})(['margin:10px;']);

var WarrantyBadgeWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultBlock__WarrantyBadgeWrapper',
  componentId: 'sc-18sy1b6-31'
})(['align-self:center;margin-left:auto;display:flex;']);

var formattedLocale = function formattedLocale(locale) {
  return locale.replace('_', '-');
};

var carbonTemplate = function carbonTemplate(vehicle, translations) {
  var fuelConsumption = (0, _translateFromTemplate.translateFromTemplate)('fuelConsumptionTemplate', {
    MINFUEL: vehicle.engineDetails.consumptionExtraurban,
    MAXFUEL: vehicle.engineDetails.consumptionUrban
  }, translations);
  var co2 = (0, _translateFromTemplate.translateFromTemplate)('co2ConsumptionTemplate', {
    MINCO2: vehicle.engineDetails.co2EmissionsMin,
    MAXCO2: vehicle.engineDetails.co2EmissionsMax
  }, translations);

  return fuelConsumption + '\n ' + co2;
};

var electricTemplate = function electricTemplate(vehicle, translations) {
  return (0, _translateFromTemplate.translateFromTemplate)('electricVehicleConsumptionTemplate', {
    MAXDISTANCE: vehicle.engineDetails.maxRange,
    MINPOWER: vehicle.engineDetails.minPowerKw,
    MAXPOWER: vehicle.engineDetails.maxPowerKw
  }, translations);
};

var co2LegalInfo = function co2LegalInfo(vehicle, translations) {
  return vehicle.engine === 'EV' ? electricTemplate(vehicle, translations) : carbonTemplate(vehicle, translations);
};

function SearchResultBlock(_ref44) {
  var vehicle = _ref44.vehicle,
      placeholdingImage = _ref44.placeholdingImage,
      vehiclePriceSectionWidth = _ref44.vehiclePriceSectionWidth,
      displayImage = _ref44.displayImage,
      onMoreInfoClick = _ref44.onMoreInfoClick,
      translations = _ref44.translations,
      compareVehicle = _ref44.compareVehicle,
      shortlistVehicle = _ref44.shortlistVehicle,
      shortListedIconColour = _ref44.shortListedIconColour,
      compareIconColour = _ref44.compareIconColour,
      renderTopRightIcon = _ref44.renderTopRightIcon,
      globalStyling = _ref44.globalStyling,
      visibility = _ref44.visibility,
      locale = _ref44.locale,
      vehicleModelTitleFontWeight = _ref44.vehicleModelTitleFontWeight,
      pricingSectionImage = _ref44.pricingSectionImage,
      financeHeadingFont = _ref44.financeHeadingFont,
      backgroundColour = _ref44.backgroundColour,
      financeCellHorizontalPadding = _ref44.financeCellHorizontalPadding,
      _ref44$showFinanceCel = _ref44.showFinanceCellBorder,
      showFinanceCellBorder = _ref44$showFinanceCel === undefined ? true : _ref44$showFinanceCel,
      imageMaxWidth = _ref44.imageMaxWidth,
      moreInfoButtonRightAlign = _ref44.moreInfoButtonRightAlign,
      showFinancePopup = _ref44.showFinancePopup,
      financeSubHeadingFont = _ref44.financeSubHeadingFont,
      financeWidthOverride = _ref44.financeWidthOverride,
      vehicleTitleFont = _ref44.vehicleTitleFont,
      vehicleSubTitleFont = _ref44.vehicleSubTitleFont,
      actionRowStyles = _ref44.actionRowStyles,
      vehicleSpecContainer = _ref44.vehicleSpecContainer,
      badgeWithStripUrl = _ref44.badgeWithStripUrl,
      badgeWithoutStripUrl = _ref44.badgeWithoutStripUrl,
      warrantyBadgeMonths = _ref44.warrantyBadgeMonths,
      warrantyBadgeMaxMileage = _ref44.warrantyBadgeMaxMileage,
      showOnDemand = _ref44.showOnDemand,
      onDemandFont = _ref44.onDemandFont,
      icon360 = _ref44.icon360,
      iconYoutubeVideo = _ref44.iconYoutubeVideo,
      showEmissions = _ref44.showEmissions,
      convertedKwToHp = _ref44.convertedKwToHp,
      config = _ref44.config;

  var isGermanSpecification = config.visibility.tiles.vehicleSpecGermany;
  var currency = vehicle.price.currency;

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      rates: state.shared.currencyConversion.exchangeRates[currency] ? state.shared.currencyConversion.exchangeRates[currency].rates : null,
      secondaryCurrency: state.config.config.global.inventory.secondaryCurrency || null
    };
  }),
      rates = _useSelector.rates,
      secondaryCurrency = _useSelector.secondaryCurrency;

  var _useSelector2 = (0, _reactRedux.useSelector)(function (state) {
    return {
      financeFeatureEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.finance
    };
  }),
      financeFeatureEnabled = _useSelector2.financeFeatureEnabled;

  var _useSelector3 = (0, _reactRedux.useSelector)(function (state) {
    return {
      emissionsEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.emissionsEnabled
    };
  }),
      emissionsEnabled = _useSelector3.emissionsEnabled;

  var _useSelector4 = (0, _reactRedux.useSelector)(function (state) {
    return {
      kiaEmissionsEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.kiaEmissionsEnabled
    };
  }),
      kiaEmissionsEnabled = _useSelector4.kiaEmissionsEnabled;

  var financeCellText = function financeCellText(priceEntry) {
    return (0, _numbers.localiseCurrency)(priceEntry, locale, vehicle.price.currency, 2) || translations.searchResultsNotAvailableLabel;
  };

  var financeCellTextNoDigits = function financeCellTextNoDigits(priceEntry, curCurrency) {
    return priceEntry > 0 && (0, _numbers.localiseCurrency)(priceEntry, locale, curCurrency, 0) || vehicle.priceSpecial === 'SOLD' && translations.sold || translations.zeroPriceText || translations.searchResultsNotAvailableLabel;
  };

  var hasFinance = !!vehicle.finance;
  var financeLoaded = vehicle.financeLoaded;


  var icons = vehicleSpecContainer && vehicleSpecContainer.icons;

  var iconWidth = icons && icons.iconWidth && icons.iconWidth + 'px' || '2em';
  var iconHeight = icons && icons.iconHeight && icons.iconHeight + 'px' || '2em';

  var FinanceLoadingText = function FinanceLoadingText() {
    return _react2.default.createElement(
      FinanceSpinnerWrapper,
      null,
      _react2.default.createElement(_Spinner2.default, { colour: globalStyling.colours.primaryBrandColour }),
      _react2.default.createElement(
        FinanceLoadingWrapper,
        null,
        translations.financeLoadingText
      )
    );
  };

  var _useSelector5 = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.featureFlags;
  }),
      approvedVehicles = _useSelector5.approvedVehicles;

  var formattedDate = vehicle.availabilityDate && (0, _dates.localeFormattedDate)(vehicle.availabilityDate, locale, {
    month: 'long',
    year: 'numeric'
  });

  var isJapaneseDealer = vehicle.retailerCountryCode === 'JP' && !vehicle.priceSpecial && vehicle.basePrice && vehicle.expenses;

  return _react2.default.createElement(
    Container,
    {
      'data-cy': 'search-result-vehicle-item',
      key: vehicle.id,
      backgroundColour: backgroundColour
    },
    displayImage && _react2.default.createElement(
      ImageSection,
      {
        onClick: function onClick() {
          return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
        }
      },
      showOnDemand && _react2.default.createElement(_OnDemandBadge2.default, {
        font: onDemandFont,
        backgroundColor: globalStyling.colours.primaryBrandColour,
        foregroundColor: globalStyling.colours.primaryForegroundColour,
        text: translations.onDemandBadge
      }),
      vehicle.images.length > 0 ? _react2.default.createElement(Image, {
        src: vehicle.images[0],
        maxWidth: imageMaxWidth,
        alt: getVehicleTitle(vehicle, visibility.hideYear)
      }) : _react2.default.createElement(Image, {
        src: placeholdingImage,
        alt: placeholdingImage,
        maxWidth: imageMaxWidth
      }),
      vehicle.status === 'sold' && _react2.default.createElement(
        ImageOverlay,
        null,
        _react2.default.createElement(
          SoldHeading,
          null,
          translations.sold
        ),
        _react2.default.createElement(
          SoldBody,
          null,
          translations.similarVehiclesAvailable
        )
      ),
      vehicle.panoramicViews && (vehicle.panoramicViews.exterior || vehicle.panoramicViews.interior) && _react2.default.createElement(
        ThreeSixty,
        { direction: globalStyling.direction },
        _react2.default.createElement(Icon, { src: icon360, alt: '360' })
      ),
      vehicle.video && _react2.default.createElement(
        YoutubeVideo,
        {
          direction: globalStyling.direction,
          panoramicViews: vehicle.panoramicViews
        },
        _react2.default.createElement(Icon, { src: iconYoutubeVideo, alt: 'YoutubeVideo' })
      )
    ),
    _react2.default.createElement(
      VehicleInfoSectionContainer,
      {
        vehicleTitleFont: vehicleTitleFont,
        vehicleTitleSubFont: vehicleSubTitleFont,
        vehicleSpecContainer: vehicleSpecContainer,
        globalStyling: globalStyling
      },
      _react2.default.createElement(
        VehicleInfoSection,
        null,
        _react2.default.createElement(VehicleTitle, {
          vehicle: vehicle,
          fontWeight: vehicleModelTitleFontWeight,
          vehicleTitleFont: vehicleTitleFont,
          vehicleSubTitleFont: vehicleSubTitleFont,
          locale: locale,
          hideYear: visibility.hideYear,
          titleBrandPrefix: visibility.titleBrandPrefix,
          shortVehicleTitles: visibility.fshortVehicleTitles,
          subtitleData: visibility.listSubtitleData,
          onMoreInfoClick: onMoreInfoClick,
          hideSubTitles: visibility.hideSubTitles
        }),
        _react2.default.createElement(Line, { visible: true }),
        !pricingSectionImage && !isGermanSpecification && _react2.default.createElement(
          VehicleSpecs,
          { padding: vehicleSpecContainer },
          _react2.default.createElement(IconWithInfo, {
            enabled: vehicle.warranty.remainingWarrantyWholeYears > 0 || visibility.showWarrantyExpiryDate,
            label: translations.warrantyLabel,
            value: visibility.showWarrantyExpiryDate ? vehicle.warranty.expiryDate && (0, _dates.localeFormattedDate)(vehicle.warranty.expiryDate, formattedLocale(locale), {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }) : (0, _getWarrantyLabel.getWarrantyLabel)(vehicle.warranty.remainingWarrantyWholeYears, translations.warrantyYearsLabel, translations.warrantyYearsLabel2to4),
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.warrantyYears
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: visibility.showOdometer && vehicle.odometer,
            label: translations.searchResultsOdometerLabel,
            value: visibility.showOdometer && (0, _numbers.localiseNumber)(vehicle.odometer.reading, locale) + ' ' + vehicle.odometer.units,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.odometerIcon,
            CustomIcon: _Mileage2.default
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: vehicle.transmission,
            label: translations.searchResultsTransmissionLabel,
            value: vehicle.transmission,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.transmissionIcon,
            CustomIcon: _Manual2.default
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: vehicle.engineDetails && vehicle.engineDetails.combinedConsumption && translations.searchResultsConsumptionCombinedLabel,
            label: translations.searchResultsConsumptionCombinedLabel,
            value: vehicle.engineDetails.combinedConsumption,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.fuelTypeIcon,
            CustomIcon: _Fuel2.default
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: visibility.showFuel && vehicle.fuel,
            label: translations.searchResultsFuelLabel,
            value: vehicle.fuel,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.fuelTypeIcon,
            CustomIcon: _Fuel2.default
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: translations.searchResultsEmissionLabel && parseInt(vehicle.co2EmissionsCombined, 10) > 0,
            label: translations.searchResultsEmissionsLabel,
            value: (0, _translateFromTemplate.translateFromTemplate)('co2UnitDisplayText', {
              VALUE: vehicle.co2EmissionsCombined
            }, translations),
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.efficiencyIcon,
            CustomIcon: _Efficiency2.default
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: !visibility.hideYear && vehicle.registrationDate,
            label: translations.registered,
            value: (0, _dates.localeFormattedDate)(vehicle.registrationDate, formattedLocale(locale), {
              month: '2-digit',
              year: 'numeric'
            }) + ' ',
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            CustomIcon: _Calendar2.default
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: vehicle.powerOutput,
            label: translations.searchResultsPowerLabel,
            value: vehicle.powerOutput,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            CustomIcon: _Power2.default
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: visibility.showVehicleType && vehicle.vehicleType && vehicle.vehicleType.length > 0,
            label: translations.vehicleType,
            value: vehicle.vehicleType,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.typesOfVehicle
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: displayRetailerIcon(vehicle, vehicleSpecContainer),
            label: translations.retailerLocationLabel,
            value: vehicle.location,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.locationIcon
          }),
          convertedKwToHp && _react2.default.createElement(IconWithInfo, {
            enabled: translations.powerReading && vehicle.powerKwNumeric,
            label: translations.powerKw,
            value: (0, _translateFromTemplate.translateFromTemplate)('powerReading', {
              KW: vehicle.powerKwNumeric,
              HP: (0, _vehicle.vehiclePropFormatter)(convertedKwToHp, vehicle, vehicle.locale)
            }, translations),
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.powerIcon
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: translations.registrationDate && vehicle.regDate,
            label: translations.registrationDate,
            value: (0, _dates.localeFormattedDate)(vehicle.regDate, formattedLocale(locale), vehicle.regDate && (0, _dates.dateValidityCheck)(vehicle.regDate) ? { day: '2-digit', month: '2-digit', year: 'numeric' } : { month: '2-digit', year: 'numeric' }) + ' ',
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.registrationDateIcon
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: translations.searchResultsFuelLabel && vehicle.fuel,
            label: translations.searchResultsFuelLabel,
            value: vehicle.fuel,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            IconSpacer: true
          }),
          kiaEmissionsEnabled && _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(IconWithInfo, {
              enabled: vehicle.engineDetails.combinedConsumption,
              label: translations.fuelConsumptionLabel,
              value: vehicle.engineDetails.combinedConsumption + ' 1/100km',
              labelFontStyle: vehicleSpecContainer,
              iconWidth: iconWidth,
              iconHeight: iconHeight,
              iconSrc: icons && icons.fuelConsumptions
            }),
            _react2.default.createElement(IconWithInfo, {
              enabled: vehicle.engineDetails.weightedCombinedFuelConsumption,
              label: translations.combinedPowerLabel,
              value: vehicle.engineDetails.weightedCombinedFuelConsumption + ' kWh/100 km',
              labelFontStyle: vehicleSpecContainer,
              iconWidth: iconWidth,
              iconHeight: iconHeight,
              iconSrc: icons && icons.powerIcon
            }),
            _react2.default.createElement(IconWithInfo, {
              enabled: vehicle.engineDetails.weightedCombinedCo2Emission,
              label: translations.combinedCO2EmissionsLabel,
              value: vehicle.engineDetails.weightedCombinedCo2Emission + '  g/km',
              labelFontStyle: vehicleSpecContainer,
              iconWidth: iconWidth,
              iconHeight: iconHeight,
              iconSrc: icons && icons.co2Emissions
            }),
            vehicle.engineDetails.weightedCombinedCo2Emission === null && _react2.default.createElement(IconWithInfo, {
              enabled: vehicle.engineDetails.co2EmissionsCombined,
              label: translations.combinedCO2EmissionsLabel,
              value: vehicle.engineDetails.co2EmissionsCombined + '  g/km',
              labelFontStyle: vehicleSpecContainer,
              iconWidth: iconWidth,
              iconHeight: iconHeight,
              iconSrc: icons && icons.co2Emissions
            }),
            _react2.default.createElement(IconWithInfo, {
              enabled: vehicle.energyEfficiencyRating,
              label: translations.efficiencyClassLabel,
              value: 'A',
              labelFontStyle: vehicleSpecContainer,
              valueAsImage: 'https://res.cloudinary.com/motortrak/image/upload/v1674663402/locator/generic/emissions/kia-srp-labels/' + vehicle.energyEfficiencyRating + '.jpg',
              IconSpacer: true,
              iconWidth: iconWidth,
              iconHeight: iconHeight
            }),
            _react2.default.createElement(IconWithInfo, {
              enabled: vehicle.engineDetails.emissionsClass,
              label: translations.efficiencyStandardLabel,
              value: vehicle.engineDetails.emissionsClass,
              labelFontStyle: vehicleSpecContainer,
              IconSpacer: true,
              iconWidth: iconWidth,
              iconHeight: iconHeight
            })
          )
        ),
        isGermanSpecification && _react2.default.createElement(
          VehicleSpecs,
          { padding: vehicleSpecContainer },
          _react2.default.createElement(IconWithInfo, {
            enabled: visibility.showOdometer && vehicle.odometer,
            label: translations.searchResultsOdometerLabel,
            value: visibility.showOdometer && (0, _numbers.localiseNumber)(vehicle.odometer.reading, locale) + ' ' + vehicle.odometer.units,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.odometerIcon
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: vehicle.transmission,
            label: translations.searchResultsTransmissionLabel,
            value: vehicle.transmission,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.transmissionIcon
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: translations.searchResultsFuelLabel && vehicle.fuel,
            label: translations.searchResultsFuelLabel,
            value: vehicle.fuel,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.fuelTypeIcon
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: translations.registrationDate && vehicle.regDate,
            label: translations.registrationDate,
            value: (0, _dates.localeFormattedDate)(vehicle.regDate, formattedLocale(locale), vehicle.regDate && (0, _dates.dateValidityCheck)(vehicle.regDate) ? { day: '2-digit', month: '2-digit', year: 'numeric' } : { month: '2-digit', year: 'numeric' }) + ' ',
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight,
            iconSrc: icons && icons.registrationDateIcon
          }),
          _react2.default.createElement(IconWithInfo, {
            enabled: translations.searchResultsColourLabel && vehicle.exterior,
            label: translations.searchResultsColourLabel,
            value: vehicle.exterior,
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight
          }),
          convertedKwToHp && _react2.default.createElement(IconWithInfo, {
            enabled: translations.searchResultsPowerLabel && vehicle.powerKwNumeric,
            label: translations.searchResultsPowerLabel,
            value: (0, _translateFromTemplate.translateFromTemplate)('powerReading', {
              KW: vehicle.powerKwNumeric,
              HP: (0, _vehicle.vehiclePropFormatter)(convertedKwToHp, vehicle, vehicle.locale)
            }, translations),
            labelFontStyle: vehicleSpecContainer,
            iconWidth: iconWidth,
            iconHeight: iconHeight
          }),
          _react2.default.createElement(
            EmissionsStringText,
            {
              styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)(vehicleSubTitleFont) + '; padding: 0 10px; text-transform: initial; text-wrap: pretty;';
              }
            },
            (0, _emissionSrpText.displayKiaEmissionsConsumption)(vehicle, translations)
          ),
          (0, _emissionSrpText.displayKiaElectricRange)(vehicle, translations) && _react2.default.createElement(
            EmissionsStringText,
            {
              styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)(vehicleSubTitleFont) + '; padding: 0 10px; text-transform: initial; text-wrap: pretty;';
              }
            },
            (0, _emissionSrpText.displayKiaElectricRange)(vehicle, translations)
          )
        ),
        pricingSectionImage && _react2.default.createElement(
          VehicleSpecs,
          { padding: vehicleSpecContainer, lamboVehicleSpecs: true },
          vehicle.registrationDate && _react2.default.createElement(
            IconWithInfoWrapper,
            {
              label: translations.registrationDate,
              value: (0, _dates.localeFormattedDate)(vehicle.registrationDate, formattedLocale(locale), {
                month: '2-digit',
                year: 'numeric'
              }) + ' '
            },
            _react2.default.createElement('img', {
              style: { maxWidth: 26, maxHeight: 26 },
              src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278373/locator/lamborghini/content/Reg_Date_2x.png',
              alt: translations.registrationDate
            })
          ),
          vehicle.odometer && _react2.default.createElement(
            IconWithInfoWrapper,
            {
              label: translations.searchResultsOdometerLabel,
              value: (0, _numbers.localiseNumber)(vehicle.odometer.reading, locale) + ' ' + vehicle.odometer.units
            },
            _react2.default.createElement('img', {
              style: { maxWidth: 26, maxHeight: 26 },
              src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278373/locator/lamborghini/content/Reg_Date_2x.png',
              alt: translations.searchResultsOdometerLabel
            })
          ),
          vehicle.registration && _react2.default.createElement(
            IconWithInfoWrapper,
            {
              label: translations.registered,
              value: vehicle.registration
            },
            _react2.default.createElement('img', {
              style: { maxWidth: 26, maxHeight: 26 },
              src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278364/locator/lamborghini/content/Model_Year_2x.png',
              alt: translations.registered
            })
          ),
          vehicle.interior && _react2.default.createElement(
            IconWithInfoWrapper,
            {
              label: translations.interior,
              value: vehicle.interior
            },
            _react2.default.createElement('img', {
              style: { maxWidth: 26, maxHeight: 26 },
              src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278359/locator/lamborghini/content/Interior_2x.png',
              alt: translations.interior
            })
          ),
          vehicle.secondaryInterior && _react2.default.createElement(
            IconWithInfoWrapper,
            (0, _extends3.default)({
              label: translations.interiorSecondary,
              value: vehicle.secondaryInterior
            }, vehicle.secondaryInterior.length > 50 ? { interiorSecondary: true } : {}),
            _react2.default.createElement('img', {
              style: { maxWidth: 26, maxHeight: 26 },
              src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278359/locator/lamborghini/content/Interior_2x.png',
              alt: translations.interiorSecondary
            })
          ),
          vehicle.transmission && _react2.default.createElement(
            IconWithInfoWrapper,
            {
              label: translations.searchResultsTransmissionLabel,
              value: vehicle.transmission
            },
            _react2.default.createElement('img', {
              style: { maxWidth: 26, maxHeight: 26 },
              src: 'https://res.cloudinary.com/motortrak/image/upload/v1706278357/locator/lamborghini/content/Transmission.svg',
              alt: translations.searchResultsTransmissionLabel
            })
          ),
          (vehicle.emissions.wltpEmissionsCombined || vehicle.emissions.wltpEmissionsCombined || vehicle.consumption.nedcConsumptionCombined || vehicle.consumption.wltpConsumptionCombined) && emissionsEnabled && _react2.default.createElement(_SearchResultEmissions2.default, {
            emissionsData: vehicle.emissions,
            consumptionsData: vehicle.consumption,
            efficiency: vehicle.energyEfficiencyRating,
            translations: translations
          })
        )
      ),
      translations.co2ConsumptionTemplate && _react2.default.createElement(
        Co2ConsumptionInformationSection,
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return 'font-size: 11px; padding-left: 10px; white-space: pre-line';
            }
          },
          co2LegalInfo(vehicle, translations)
        )
      ),
      _react2.default.createElement(
        BottomInfo,
        { vehicleSpecContainer: vehicleSpecContainer },
        _react2.default.createElement(DistanceFromRetailer, {
          colour: visibility.locationIconColour,
          vehicle: vehicle,
          vehicleSpecContainer: vehicleSpecContainer
        }),
        _react2.default.createElement(
          WarrantyBadgeWrapper,
          null,
          _react2.default.createElement(_WarrantyBadge2.default, {
            vehicle: vehicle,
            badgeWithStripUrl: badgeWithStripUrl,
            badgeWithoutStripUrl: badgeWithoutStripUrl,
            warrantyOdometer: vehicle.odometer.reading,
            warrantyBadgeMonths: warrantyBadgeMonths,
            warrantyBadgeMaxMileage: warrantyBadgeMaxMileage
          })
        )
      ),
      showEmissions && _react2.default.createElement(
        EmissionsInfo,
        { vehicleSpecContainer: vehicleSpecContainer },
        _react2.default.createElement(_SearchCo2Accordian2.default, {
          vehicle: vehicle,
          translations: translations,
          translateFromTemplate: _translateFromTemplate.translateFromTemplate,
          globalStyling: globalStyling,
          font: config.searchCo2AccordionFont,
          locale: locale
        })
      )
    ),
    _react2.default.createElement(
      VehiclePriceSection,
      {
        width: vehiclePriceSectionWidth,
        flexDirection: visibility.flexDirection,
        direction: globalStyling.direction,
        showBorder: visibility.showVehiclePriceSectionLeftBorder !== undefined ? visibility.showVehiclePriceSectionLeftBorder : true
      },
      _react2.default.createElement(
        FinanceRowContainer,
        null,
        vehicle.price.previousValue === null ? _react2.default.createElement(
          FinanceRow,
          {
            style: {
              position: 'relative',
              justifyContent: hasFinance ? 'initial' : ''
            }
          },
          _react2.default.createElement(FinanceCell, {
            headerText: isJapaneseDealer ? _react2.default.createElement(
              'div',
              {
                style: {
                  display: 'flex',
                  justifyContent: 'space-between'
                }
              },
              _react2.default.createElement(
                'div',
                {
                  style: {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '18px',
                    fontWeight: 'normal'
                  }
                },
                translations.total
              ),
              _react2.default.createElement(
                'div',
                { style: { fontWeight: '700' } },
                financeCellTextNoDigits(vehicle.price.value && Number(vehicle.price.value), currency)
              )
            ) : financeCellTextNoDigits(vehicle.price.value && Number(vehicle.price.value), currency),
            secondaryHeaderText: secondaryCurrency && rates && financeCellTextNoDigits((0, _helpers.convertSecondaryPriceCurrency)(vehicle.price.value, rates, currency, secondaryCurrency), secondaryCurrency),
            subHeaderText: secondaryCurrency && rates ? (0, _translateFromTemplate.translateFromTemplate)('exchangeRate', {
              EXCHANGERATE: rates[secondaryCurrency].toFixed(2)
            }, translations) : '',
            visible: visibility.showPrice,
            showRightBorder: showFinanceCellBorder && visibility.showApr && hasFinance && financeLoaded,
            headerColour: visibility.financeCellHeaderColour,
            renderChild: function renderChild() {
              return !hasFinance && vehicle.status === 'sold' && renderTopRightIcon();
            },
            financeHeadingFont: financeHeadingFont,
            horizontalPadding: financeCellHorizontalPadding,
            hideBorders: visibility.hideBorders,
            financeSubHeadingFont: financeSubHeadingFont,
            financeWidthOverride: financeWidthOverride,
            vehicle: vehicle,
            translations: translations,
            differentialTaxation: vehicle.sectionSalesTaxDisclaimer === 1 ? translations.differentialTaxation : false
          }),
          isJapaneseDealer && _react2.default.createElement(
            'div',
            {
              style: {
                fontSize: '12px',
                borderTop: '1px solid rgb(213, 213, 213)',
                padding: '10px 20px 0px'
              }
            },
            _react2.default.createElement(
              CarBasePriceContainer,
              null,
              _react2.default.createElement(
                'div',
                { style: { paddingRight: '5px' } },
                translations.carBasePrice
              ),
              _react2.default.createElement(
                'div',
                { style: { fontSize: '16px', fontWeight: '700' } },
                financeCellTextNoDigits(vehicle.basePrice && Number(vehicle.basePrice), currency)
              )
            ),
            _react2.default.createElement(
              OtherAmountContainer,
              null,
              _react2.default.createElement(
                'div',
                { style: { paddingRight: '5px' } },
                translations.otherAmount
              ),
              _react2.default.createElement(
                'div',
                { style: { fontSize: '16px', fontWeight: '700' } },
                financeCellTextNoDigits(vehicle.expenses && Number(vehicle.expenses), currency)
              )
            )
          ),
          hasFinance && _react2.default.createElement(FinanceCell, {
            headerText: hasFinance && financeLoaded && vehicle.finance.apr + '% ',
            subHeaderText: translations.searchResultsAprLabel,
            renderChild: function renderChild() {
              return vehicle.status === 'sold' && renderTopRightIcon();
            },
            visible: visibility.showApr && hasFinance && financeLoaded,
            financeHeadingFont: financeHeadingFont,
            horizontalPadding: financeCellHorizontalPadding,
            showRightBorder: showFinanceCellBorder,
            hideBorders: visibility.hideBorders,
            financeSubHeadingFont: financeSubHeadingFont,
            financeWidthOverride: financeWidthOverride
          }),
          formattedDate && config.availabilityFont && _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(
              AvailabilityLabel,
              {
                bold: true,
                size: config.availabilityFont.dateSize,
                padding: config.availabilityFont.listDatePadding
              },
              formattedDate
            ),
            _react2.default.createElement(
              AvailabilityLabel,
              {
                size: config.availabilityFont.dateSizeText,
                dateTextColor: config.availabilityFont.dateTextColor,
                padding: config.availabilityFont.listDatePadding
              },
              translations.availabilityDate
            )
          )
        ) : _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            FinanceRow,
            null,
            _react2.default.createElement(FinanceCell, {
              headerText: financeCellTextNoDigits(Number(vehicle.price.previousValue), currency),
              secondaryHeaderText: secondaryCurrency && rates && financeCellTextNoDigits((0, _helpers.convertSecondaryPriceCurrency)(vehicle.price.previousValue, rates, currency, secondaryCurrency), secondaryCurrency),
              subHeaderText: secondaryCurrency && rates ? (0, _translateFromTemplate.translateFromTemplate)('exchangeRate', {
                EXCHANGERATE: rates[secondaryCurrency].toFixed(2)
              }, translations) : translations.searchResultsPriceLabel,
              visible: visibility.showPrice,
              showRightBorder: showFinanceCellBorder && visibility.showApr && hasFinance && financeLoaded,
              headerColour: visibility.financeCellHeaderColour,
              renderChild: function renderChild() {
                return !hasFinance && vehicle.status === 'sold' && renderTopRightIcon();
              },
              financeHeadingFont: financeHeadingFont,
              horizontalPadding: config && config.specialOfferStyling && config.specialOfferStyling.leftPadding || financeCellHorizontalPadding,
              hideBorders: visibility.hideBorders,
              financeSubHeadingFont: financeSubHeadingFont,
              financeWidthOverride: financeWidthOverride,
              priceStyle: config && config.specialOfferStyling && config.specialOfferStyling.priceStyle
            })
          ),
          _react2.default.createElement(
            FinanceRow,
            null,
            _react2.default.createElement(FinanceCell, {
              headerText: financeCellTextNoDigits(vehicle.price.previousValue && Number(vehicle.price.value), currency),
              secondaryHeaderText: secondaryCurrency && rates && financeCellTextNoDigits((0, _helpers.convertSecondaryPriceCurrency)(vehicle.price.value, rates, currency, secondaryCurrency), secondaryCurrency),
              offerTerms: {
                offerTermText: vehicle.offer && vehicle.offer.termsConditions,
                offerDetailsUrl: vehicle.offer && vehicle.offer.detailsUrl,
                offerTermModalTitle: translations.offerTermModalTitle && translations.offerTermModalTitle,
                offerTermModalLinkText: translations.offerTermModalTitle && translations.offerTermModalLinkText
              },
              subHeaderText: secondaryCurrency && rates ? (0, _translateFromTemplate.translateFromTemplate)('exchangeRate', {
                EXCHANGERATE: rates[secondaryCurrency].toFixed(2)
              }, translations) : translations.specialOfferPrice,
              visible: visibility.showPrice,
              showRightBorder: showFinanceCellBorder && visibility.showApr && hasFinance && financeLoaded,
              headerColour: visibility.financeCellHeaderColour,
              renderChild: function renderChild() {
                return !hasFinance && vehicle.status === 'sold' && renderTopRightIcon();
              },
              financeHeadingFont: financeHeadingFont,
              horizontalPadding: config && config.specialOfferStyling && config.specialOfferStyling.leftPadding || financeCellHorizontalPadding,
              hideBorders: visibility.hideBorders,
              financeSubHeadingFont: financeSubHeadingFont,
              financeWidthOverride: financeWidthOverride,
              offerPriceStyle: config && config.specialOfferStyling && config.specialOfferStyling.offerPriceStyle,
              specialOfferStyles: config && config.specialOfferStyling,
              noPadding: true
            })
          )
        ),
        financeFeatureEnabled && !financeLoaded ? _react2.default.createElement(FinanceLoadingText, null) : '\xA0',
        _react2.default.createElement(Line, {
          visible: !visibility.hideBorders && showFinanceCellBorder && visibility.showMonthly && hasFinance && financeLoaded
        }),
        hasFinance && _react2.default.createElement(
          FinanceRow,
          {
            style: { marginTop: visibility.hasMargin ? '-20px' : '' }
          },
          _react2.default.createElement(FinanceCell, {
            headerText: hasFinance && financeLoaded && financeCellText(hasFinance && parseFloat(financeLoaded && vehicle.finance.monthlyPrice.replace('.', '').replace(',', '.'))),
            subHeaderText: translations.searchResultsMonthlyLabel,
            financeIcon: hasFinance && vehicle.status !== 'sold' && vehicle.finance && showFinancePopup && _react2.default.createElement(_FinancePopup2.default, {
              translations: translations,
              data: vehicle.finance,
              locale: locale
            }),
            visible: visibility.showMonthly && hasFinance && financeLoaded,
            showRightBorder: showFinanceCellBorder && visibility.showMonthly,
            financeHeadingFont: financeHeadingFont,
            horizontalPadding: financeCellHorizontalPadding,
            hideBorders: visibility.hideBorders,
            financeSubHeadingFont: financeSubHeadingFont,
            financeWidthOverride: financeWidthOverride
          }),
          _react2.default.createElement(FinanceCell, {
            headerText: vehicle.finance.interestRate + '% ',
            subHeaderText: translations.searchResultsInterestRateLabel,
            visible: visibility.showInterestRate && hasFinance && financeLoaded,
            financeHeadingFont: financeHeadingFont,
            horizontalPadding: financeCellHorizontalPadding,
            showRightBorder: showFinanceCellBorder,
            hideBorders: visibility.hideBorders,
            financeSubHeadingFont: financeSubHeadingFont,
            financeWidthOverride: financeWidthOverride
          })
        ),
        translations.vatDisclaimer && _react2.default.createElement(
          FinanceRow,
          null,
          _react2.default.createElement(
            _Global.Paragraph,
            { styleOverride: function styleOverride() {
                return 'font-size: 11px';
              } },
            translations.vatDisclaimer
          )
        ),
        _react2.default.createElement(Line, {
          visible: !visibility.hideBorders && visibility.showMonthly && hasFinance && financeLoaded,
          style: { paddingTop: visibility.hasPadding ? '10px' : '' }
        })
      ),
      approvedVehicles && vehicle.approved && pricingSectionImage && _react2.default.createElement('img', {
        style: { width: '100%', maxWidth: '240px', margin: '0 auto' },
        src: pricingSectionImage,
        alt: translations.selezioneLogoAlternativeText || ''
      }),
      vehicle.status !== 'sold' && _react2.default.createElement(
        ActionsRow,
        { actionRowStyles: actionRowStyles },
        _react2.default.createElement(
          ActionsContainer,
          {
            actionRowStyles: actionRowStyles,
            direction: globalStyling.direction
          },
          _react2.default.createElement(
            IconWithLabel,
            {
              qaHook: 'compare-vehicle-button',
              text: translations.searchResultsAddToCompare,
              onClickFn: function onClickFn() {
                return compareVehicle(vehicle);
              }
            },
            _react2.default.createElement(_Compare2.default, {
              width: actionRowStyles && actionRowStyles.iconWidth ? actionRowStyles.iconWidth : '18px',
              height: actionRowStyles && actionRowStyles.iconHeight ? actionRowStyles.iconHeight : '18px',
              colour: compareIconColour
            })
          ),
          _react2.default.createElement(
            IconWithLabel,
            {
              qaHook: 'shortlist-vehicle-button',
              text: translations.searchResultsSaveVehicle,
              onClickFn: function onClickFn() {
                return shortlistVehicle(vehicle);
              }
            },
            _react2.default.createElement(_Heart2.default, {
              width: actionRowStyles && actionRowStyles.iconWidth ? actionRowStyles.iconWidth : '18px',
              height: actionRowStyles && actionRowStyles.iconHeight ? actionRowStyles.iconHeight : '18px',
              colour: shortListedIconColour
            })
          )
        ),
        _react2.default.createElement(Line, { visible: visibility.showMonthly && !visibility.hideBorders }),
        _react2.default.createElement(_Global.Button, {
          'data-cy': 'more-info-button',
          onClick: function onClick() {
            return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
          },
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          applyStyle: 'primary',
          text: translations.searchResultTileMoreInfo,
          styleOverride: function styleOverride() {
            return '\n                max-width: 100%;\n                margin: 10px;\n                align-self: auto;\n                ' + (moreInfoButtonRightAlign && 'align-self: flex-end;') + '\n                ';
          }
        })
      )
    )
  );
}