'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SubNav;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _router = require('../../actions/router');

var _PathnameSubNav = require('../../components/PathnameSubNav');

var _PathnameSubNav2 = _interopRequireDefault(_PathnameSubNav);

var _vehicleStore = require('../../shared/selectors/vehicleStore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function SubNav(_ref) {
  var config = _ref.config,
      dispatch = _ref.dispatch,
      history = _ref.history,
      globalStyling = _ref.globalStyling,
      shared = _ref.shared,
      featureFlags = _ref.featureFlags;

  var goToPath = function goToPath(path) {
    return dispatch(_router.actions.navigate(path));
  };
  var vehicleCounters = {
    compare: (0, _vehicleStore.getCompareList)(featureFlags)(shared).length,
    shortlist: (0, _vehicleStore.getShortList)(featureFlags)(shared).length
  };

  var goBack = function goBack() {
    if (config.goBackPathname) {
      goToPath(config.goBackPathname);
    } else {
      history.goBack();
    }
  };

  return _react2.default.createElement(_PathnameSubNav2.default, {
    globalStyling: globalStyling,
    onBackButtonClick: goBack,
    links: config.items.map(function (link) {
      return {
        text: link.translations.linkText + ' ' + (vehicleCounters[link.path.substr(1)] ? '(' + vehicleCounters[link.path.substr(1)] + ')' : ''),
        onClick: function onClick() {
          return goToPath(link.path);
        },
        availableOnMobile: link.availableOnMobile,
        active: link.active,
        path: link.path
      };
    }),
    config: config,
    history: history
  });
}