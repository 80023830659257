'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  padding-top: 100px;\n  '], ['\n  padding-top: 100px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NoResults = _styledComponents2.default.div.withConfig({
  displayName: 'NoResults',
  componentId: 'sc-yq3f3w-0'
})(['width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;', ';'], _theme2.default.min.medium(_templateObject));

var NoResultsText = _styledComponents2.default.p.withConfig({
  displayName: 'NoResults__NoResultsText',
  componentId: 'sc-yq3f3w-1'
})(['font-family:\'AstonMartinFlare-Regular\';font-style:normal;text-transform:uppercase;font-size:14px;color:#000;margin:1.5em 0;']);

exports.default = function (_ref) {
  var text = _ref.text,
      _ref$iconUrl = _ref.iconUrl,
      iconUrl = _ref$iconUrl === undefined ? 'https://res.cloudinary.com/motortrak/image/upload/v1565104295/cms/global/icons/results-empty.svg' : _ref$iconUrl;
  return _react2.default.createElement(
    NoResults,
    null,
    _react2.default.createElement('img', { src: iconUrl, alt: text }),
    _react2.default.createElement(
      NoResultsText,
      null,
      text
    )
  );
};