'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LocationSettings;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Close = require('./JaguarIcons/Global/Close');

var _Close2 = _interopRequireDefault(_Close);

var _Globe = require('./JaguarIcons/Global/Globe');

var _Globe2 = _interopRequireDefault(_Globe);

var _Modal = require('./Modal');

var _Modal2 = _interopRequireDefault(_Modal);

var _FormLocation = require('./FormLocation');

var _FormLocation2 = _interopRequireDefault(_FormLocation);

var _OpenClose = require('./OpenClose');

var _OpenClose2 = _interopRequireDefault(_OpenClose);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LocationContainer = _styledComponents2.default.div.withConfig({
  displayName: 'LocationContainer'
}).withConfig({
  displayName: 'LocationSettings__LocationContainer',
  componentId: 'sc-9jxtf-0'
})(['display:flex;background:#f0f0f0;align-items:center;justify-content:space-around;padding:0;width:100%;']);


var IConWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LocationSettings__IConWrapper',
  componentId: 'sc-9jxtf-1'
})(['margin:0 10px;cursor:pointer;']);

var renderClose = function renderClose() {
  return _react2.default.createElement(_Close2.default, { width: '1em', height: '1em', colour: '#ACACAC' });
};

function LocationSettings(_ref) {
  var iconColour = _ref.iconColour,
      title = _ref.title,
      confirm = _ref.confirm,
      locationOptions = _ref.locationOptions,
      actions = _ref.actions,
      initialValues = _ref.initialValues,
      globalStyling = _ref.globalStyling;

  return _react2.default.createElement(
    _OpenClose2.default,
    null,
    function (_ref2) {
      var open = _ref2.open,
          openClose = _ref2.openClose;
      return _react2.default.createElement(
        'div',
        null,
        open ? _react2.default.createElement(
          _Modal2.default,
          { overlay: true, onClickOutside: openClose },
          _react2.default.createElement(
            LocationContainer,
            null,
            _react2.default.createElement(_FormLocation2.default, {
              title: title,
              confirm: confirm,
              formOptions: locationOptions,
              onSubmit: function onSubmit(selected) {
                if (selected.Language && selected.Language !== initialValues.language) {
                  actions.language({ value: selected.Language });
                }

                if (selected.Currency && selected.Currency !== initialValues.currency) {
                  actions.currency({ value: selected.Currency });
                }

                if (selected.Countries !== initialValues.countries) {
                  actions.countries({ value: selected.Countries });
                }

                openClose();
              },
              renderClose: renderClose,
              onClose: openClose,
              initialValues: initialValues,
              globalStyling: globalStyling
            })
          )
        ) : _react2.default.createElement(
          IConWrapper,
          { onClick: openClose },
          _react2.default.createElement(_Globe2.default, { width: '2em', height: '2em', colour: iconColour })
        )
      );
    }
  );
}