'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.labelWithIcon = labelWithIcon;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCompareVehicle2 = require('../../../../hooks/useCompareVehicle');

var _useShortListVehicle2 = require('../../../../hooks/useShortListVehicle');

var _SocialShareButton = require('../../../../modules/Header/SocialShareButton');

var _SocialShareButton2 = _interopRequireDefault(_SocialShareButton);

var _vehicleTypes = require('../../../../types/RollsRoyce/vehicleTypes');

var _Global = require('../../../Global');

var _Spinner = require('../../../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _StyledIcon = require('../../../StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_FONT = 'RivieraNights-Regular,Jost-Regular';

var UserActionsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpUserActions__UserActionsWrapper',
  componentId: 'sc-1qbpr95-0'
})(['align-items:center;border-bottom:1px solid #3b3b3b;display:flex;height:44px;justify-content:space-evenly;text-transform:uppercase;']);

var LabelWithIconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpUserActions__LabelWithIconWrapper',
  componentId: 'sc-1qbpr95-1'
})(['display:', ';'], function (_ref) {
  var onlyDesktop = _ref.onlyDesktop;
  return onlyDesktop ? 'none' : 'flex';
});

var AnchorText = _styledComponents2.default.div.withConfig({
  displayName: 'VdpUserActions__AnchorText',
  componentId: 'sc-1qbpr95-2'
})(['font-size:14px;', ''], function (_ref2) {
  var styleOverride = _ref2.styleOverride;
  return styleOverride;
});

var ErrorAnchorText = (0, _styledComponents2.default)(AnchorText).withConfig({
  displayName: 'VdpUserActions__ErrorAnchorText',
  componentId: 'sc-1qbpr95-3'
})(['']);

function labelWithIcon(label, icon, onClick, font, onlyDesktop) {
  var fontStyle = font != null ? font : DEFAULT_FONT;
  return _react2.default.createElement(
    LabelWithIconWrapper,
    {
      onClick: onClick,
      cursorPointer: true,
      onlyDesktop: onlyDesktop
    },
    ' ',
    _react2.default.createElement(
      'div',
      null,
      _react2.default.createElement(_StyledIcon2.default, { link: icon, width: 16, height: 16 })
    ),
    _react2.default.createElement(
      AnchorText,
      {
        styleOverride: function styleOverride() {
          return (0, _Global.fontStyleOverride)({
            typeface: {
              value: fontStyle
            }
          }) + '\n          margin-left: 8px;\n          ';
        }
      },
      label
    )
  );
}

var VdpUserActions = function VdpUserActions(props) {
  var config = props.config,
      globalStyling = props.globalStyling,
      translations = props.translations,
      vehicleInfo = props.vehicleInfo,
      socialShare = props.socialShare,
      printingState = props.printingState,
      printVehiclePdf = props.printVehiclePdf;
  var icons = globalStyling.icons;

  var _useCompareVehicle = (0, _useCompareVehicle2.useCompareVehicle)({
    vehicleId: vehicleInfo.id
  }),
      isCompared = _useCompareVehicle.isCompared,
      toggleCompare = _useCompareVehicle.toggleCompare;

  var _useShortListVehicle = (0, _useShortListVehicle2.useShortListVehicle)({
    vehicleId: vehicleInfo.id
  }),
      isShortlisted = _useShortListVehicle.isShortlisted,
      toggleShortlist = _useShortListVehicle.toggleShortlist;

  var printingDisplay = printingState.error ? _react2.default.createElement(
    ErrorAnchorText,
    { onClick: printVehiclePdf },
    translations.printError
  ) : labelWithIcon(translations.printPage, icons.print, printVehiclePdf, config.userActions, false);

  return _react2.default.createElement(
    UserActionsWrapper,
    null,
    config.socialShareConfig.socialShareEnabled && _react2.default.createElement(
      LabelWithIconWrapper,
      { onlyDesktop: false },
      _react2.default.createElement(_SocialShareButton2.default, (0, _extends3.default)({
        config: (0, _extends3.default)({}, config.socialShareConfig, config)
      }, {
        socialShare: socialShare
      }))
    ),
    printingState.isPrinting ? _react2.default.createElement(
      LabelWithIconWrapper,
      null,
      _react2.default.createElement(_Spinner2.default, {
        size: 10,
        colour: globalStyling.colours.primaryBrandColour
      })
    ) : _react2.default.createElement(
      _react.Fragment,
      null,
      printingDisplay
    ),
    !config.hideCompareShortlist && labelWithIcon(translations.compareActionLabel, isCompared ? icons.compareselected : icons.compare, function () {
      return toggleCompare(vehicleInfo);
    }, config.userActions, true),
    !config.hideCompareShortlist && labelWithIcon(translations.shortlistActionLabel, isShortlisted ? icons.heartselected : icons.heart, function () {
      return toggleShortlist(vehicleInfo);
    }, config.userActions, false)
  );
};

exports.default = VdpUserActions;