'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localisations = undefined;

var _es = require('react-phone-input-2/lang/es.json');

var _es2 = _interopRequireDefault(_es);

var _de = require('react-phone-input-2/lang/de.json');

var _de2 = _interopRequireDefault(_de);

var _ru = require('react-phone-input-2/lang/ru.json');

var _ru2 = _interopRequireDefault(_ru);

var _fr = require('react-phone-input-2/lang/fr.json');

var _fr2 = _interopRequireDefault(_fr);

var _jp = require('react-phone-input-2/lang/jp.json');

var _jp2 = _interopRequireDefault(_jp);

var _cn = require('react-phone-input-2/lang/cn.json');

var _cn2 = _interopRequireDefault(_cn);

var _pt = require('react-phone-input-2/lang/pt.json');

var _pt2 = _interopRequireDefault(_pt);

var _it = require('react-phone-input-2/lang/it.json');

var _it2 = _interopRequireDefault(_it);

var _ir = require('react-phone-input-2/lang/ir.json');

var _ir2 = _interopRequireDefault(_ir);

var _ar = require('react-phone-input-2/lang/ar.json');

var _ar2 = _interopRequireDefault(_ar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var localisations = exports.localisations = {
  es: _es2.default,
  de: _de2.default,
  ru: _ru2.default,
  fr: _fr2.default,
  jp: _jp2.default,
  cn: _cn2.default,
  pt: _pt2.default,
  it: _it2.default,
  ir: _ir2.default,
  ar: _ar2.default
};