'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bandWithConfigSelector = exports.matchesPathname = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _lensPath = require('ramda/src/lensPath');

var _lensPath2 = _interopRequireDefault(_lensPath);

var _view = require('ramda/src/view');

var _view2 = _interopRequireDefault(_view);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var matchesPathname = exports.matchesPathname = function matchesPathname(selector, hashRouting) {
  return RegExp(selector.regex).test(hashRouting ? window.location.hash : window.location.pathname);
};

var matchReduxPath = function matchReduxPath(selector, state) {
  return RegExp(selector.valueCheck).test((0, _view2.default)((0, _lensPath2.default)(selector.reduxPath.split('.')), state));
};

var bandWithConfigSelector = exports.bandWithConfigSelector = function bandWithConfigSelector(band, hashRouting, state) {
  var configProp = (band.configSelector || []).find(function (selector) {
    return selector.reduxPath ? matchReduxPath(selector, state) : matchesPathname(selector, hashRouting);
  }) || {
    prop: 'config'
  };
  return (0, _extends3.default)({}, band, { config: band[configProp.prop] });
};