'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _settings = require('../../../shared/selectors/settings');

var _InputField = require('./InputField');

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'TextAreaField__Label',
  componentId: 'sc-1noh27x-0'
})(['color:#000000;font-size:14px;font-family:', ' font-weight:normal;position:absolute;pointer-events:none;top:10px;transition:0.2s ease all;textarea:focus ~ &,textarea:not(:placeholder-shown).textarea:not(:focus) ~ .label,textarea:valid ~ &{top:-6px;font-size:12px;color:#000000;}'], function (_ref) {
  var fonts = _ref.fonts;
  return fonts.secondaryFont.value;
});

var Input = _styledComponents2.default.textarea.attrs(function () {
  return {
    required: true
  };
}).withConfig({
  displayName: 'TextAreaField__Input',
  componentId: 'sc-1noh27x-1'
})(['font-family:', ' font-size:14px;padding:10px 0;display:block;width:100%;background-color:transparent;border:0;margin-top:15px;&:focus{outline:none;border-bottom:solid 0.5px #000000;}'], function (_ref2) {
  var fonts = _ref2.fonts;
  return fonts.secondaryFont.value;
});

var Error = _styledComponents2.default.div.withConfig({
  displayName: 'TextAreaField__Error',
  componentId: 'sc-1noh27x-2'
})(['position:absolute;top:30px;color:red;font-size:11px;']);

var TextAreaField = function TextAreaField(_ref3) {
  var id = _ref3.id,
      label = _ref3.label,
      required = _ref3.required,
      type = _ref3.type,
      value = _ref3.value,
      onChange = _ref3.onChange,
      onKeyPress = _ref3.onKeyPress,
      error = _ref3.error,
      disabled = _ref3.disabled,
      translations = _ref3.translations,
      validate = _ref3.validate,
      readOnly = _ref3.readOnly,
      maxLength = _ref3.maxLength,
      rows = _ref3.rows;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    _FormField2.default,
    null,
    _react2.default.createElement(Input, {
      type: type,
      value: value,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (event) {
        onChange({
          id: id,
          value: event.target.value,
          translations: translations,
          validate: validate,
          event: event
        });
      }),
      onKeyPress: onKeyPress,
      disabled: disabled,
      error: error,
      readOnly: readOnly,
      maxLength: maxLength,
      fonts: fonts,
      rows: rows
    }),
    _react2.default.createElement(
      Label,
      { fonts: fonts },
      label,
      required && '*'
    ),
    error && _react2.default.createElement(
      Error,
      null,
      error
    )
  );
};

exports.default = TextAreaField;


TextAreaField.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  onChange: function onChange() {},
  onKeyPress: function onKeyPress() {},
  label: '',
  error: '',
  validate: false,
  readOnly: false
};