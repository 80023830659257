'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.globalArray = exports.pageArray = exports.pageEntity = undefined;

var _normalizr = require('normalizr');

var podEntity = new _normalizr.schema.Entity('pods', {}, {
  idAttribute: 'instanceId'
});
var moduleEntity = new _normalizr.schema.Entity('bands', {
  config: {
    pods: [podEntity]
  }
}, {
  idAttribute: 'instanceId'
});

var pageEntity = new _normalizr.schema.Entity('pages', {
  modules: [moduleEntity]
}, {
  idAttribute: 'relativePath'
});
var pageArray = [pageEntity];
var globalArray = [moduleEntity];

exports.pageEntity = pageEntity;
exports.pageArray = pageArray;
exports.globalArray = globalArray;