'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.default = CompareVehicles;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _path = require('ramda/src/path');

var _path2 = _interopRequireDefault(_path);

var _reactRedux = require('react-redux');

var _Plus = require('./JaguarIcons/Global/Plus');

var _Plus2 = _interopRequireDefault(_Plus);

var _Minus = require('./JaguarIcons/Global/Minus');

var _Minus2 = _interopRequireDefault(_Minus);

var _Heart = require('./JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

var _Phone = require('./JaguarIcons/Global/Phone');

var _Phone2 = _interopRequireDefault(_Phone);

var _Remove = require('./JaguarIcons/Global/Remove');

var _Remove2 = _interopRequireDefault(_Remove);

var _Tick = require('./JaguarIcons/Global/Tick');

var _Tick2 = _interopRequireDefault(_Tick);

var _Close = require('./JaguarIcons/Global/Close');

var _Close2 = _interopRequireDefault(_Close);

var _Global = require('./Global');

var _numbers = require('../shared/localisation/numbers');

var _helpers = require('../shared/currencyConversion/helpers');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__HeaderContainer',
  componentId: 'sc-jnazx2-0'
})(['width:100%;display:flex;margin:3% 0;']);


var Container = _styledComponents2.default.table.withConfig({
  displayName: 'CompareVehiclesContainer'
}).withConfig({
  displayName: 'CompareVehicles__Container',
  componentId: 'sc-jnazx2-1'
})(['width:95%;height:auto;margin:0 2.5% 5% 2.5%;border-collapse:separate;border-spacing:10px 0;']);
var TableRow = _styledComponents2.default.tr.withConfig({
  displayName: 'CompareVehicles__TableRow',
  componentId: 'sc-jnazx2-2'
})(['font-size:', ';&:nth-child(2){td{', ';}}'], function (props) {
  return props.fontSize ? props.fontSize + 'px' : '20px';
}, function (props) {
  return !props.borderBottomOnly && 'border-top: 1px solid #d8d8d8';
});
var TableData = _styledComponents2.default.td.withConfig({
  displayName: 'CompareVehicles__TableData',
  componentId: 'sc-jnazx2-3'
})(['border:solid #d8d8d8;border-width:', ';padding:10px;color:#444444;'], function (props) {
  return props.borderBottomOnly ? '0 0 1px 0' : '0px 1px 1px 1px';
});

var StyledImage = _styledComponents2.default.img.withConfig({
  displayName: 'CompareVehicles__StyledImage',
  componentId: 'sc-jnazx2-4'
})(['width:100%;']);

var IconButton = _styledComponents2.default.button.withConfig({
  displayName: 'IconButton'
}).withConfig({
  displayName: 'CompareVehicles__IconButton',
  componentId: 'sc-jnazx2-5'
})(['background:none;border:none;cursor:pointer;display:flex;align-items:center;color:#444444;font-size:15px;font-weight:bold;&:focus{outline:none;}']);

var IconWrapper = _styledComponents2.default.button.withConfig({
  displayName: 'IconWrapper'
}).withConfig({
  displayName: 'CompareVehicles__IconWrapper',
  componentId: 'sc-jnazx2-6'
})(['background:none;border:none;vertical-align:middle;&:focus{outline:none;}']);

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__ImageContainer',
  componentId: 'sc-jnazx2-7'
})(['position:relative;cursor:pointer;']);

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'ImageOverlay'
}).withConfig({
  displayName: 'CompareVehicles__ImageOverlay',
  componentId: 'sc-jnazx2-8'
})(['justify-content:center;align-items:center;display:flex;flex-direction:column;position:absolute;top:0;left:0;right:0;bottom:0;color:#ffffff;background-color:rgb(0,0,0,0.5);cursor:default;']);

var SoldHeading = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__SoldHeading',
  componentId: 'sc-jnazx2-9'
})(['font-size:20px;font-weight:600;']);

var SoldBody = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__SoldBody',
  componentId: 'sc-jnazx2-10'
})(['font-size:14px;max-width:165px;']);

var CellLabel = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__CellLabel',
  componentId: 'sc-jnazx2-11'
})(['font-size:14px;color:#8b8b8b;text-align:center;']);

var CloseIconBlock = function CloseIconBlock(iconColour) {
  return _react2.default.createElement(
    IconWrapper,
    null,
    _react2.default.createElement(_Close2.default, { width: '1.5em', height: '1.3125em', colour: iconColour || '#fff' })
  );
};

var TickIconBlock = function TickIconBlock(iconColour) {
  return _react2.default.createElement(
    IconWrapper,
    null,
    _react2.default.createElement(_Tick2.default, {
      width: '1.5em',
      height: '1.3125em',
      colour: iconColour || '#fff',
      fill: '#000'
    })
  );
};

var RenderRow = function RenderRow(_ref) {
  var content = _ref.content,
      font = _ref.font,
      secondaryContent = _ref.secondaryContent,
      secondaryLabel = _ref.secondaryLabel;
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n      ' + (0, _Global.fontStyleOverride)(font) + '\n      margin: 0;\n    ';
        }
      },
      content
    ),
    secondaryContent && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n      ' + (0, _Global.fontStyleOverride)(font) + '\n      margin: 0;\n      line-height: 16px;\n    ';
          }
        },
        secondaryContent
      ),
      _react2.default.createElement(
        CellLabel,
        null,
        secondaryLabel
      )
    )
  );
};

var RetailerPhoneRow = function RetailerPhoneRow(_ref2) {
  var comparedVehicles = _ref2.comparedVehicles,
      phoneIconColour = _ref2.phoneIconColour,
      font = _ref2.font,
      primaryBrandFontColourOnIconButtons = _ref2.primaryBrandFontColourOnIconButtons,
      borderBottomOnly = _ref2.borderBottomOnly,
      direction = _ref2.direction;
  return _react2.default.createElement(
    TableRow,
    {
      fontSize: font && font.fontSize,
      borderBottomOnly: borderBottomOnly
    },
    _react2.default.createElement('td', { style: { border: 'none' } }),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        TableData,
        { key: '' + vehicle.id, borderBottomOnly: borderBottomOnly },
        _react2.default.createElement(
          'div',
          {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          },
          _react2.default.createElement(_Phone2.default, {
            width: primaryBrandFontColourOnIconButtons ? '1em' : '2em',
            height: '2em',
            colour: phoneIconColour
          }),
          _react2.default.createElement(
            _Global.HeadingThree,
            {
              styleOverride: function styleOverride() {
                return '\n              font-size: 16px;\n              ' + (direction === 'rtl' && 'direction: ltr;') + ';\n            ';
              }
            },
            vehicle.retailerPhone || '-'
          )
        )
      );
    })
  );
};
var MoreInfoRow = function MoreInfoRow(_ref3) {
  var requestInformation = _ref3.requestInformation,
      comparedVehicles = _ref3.comparedVehicles,
      buttonLabel = _ref3.buttonLabel,
      buttonStyle = _ref3.buttonStyle,
      font = _ref3.font,
      borderBottomOnly = _ref3.borderBottomOnly;
  return _react2.default.createElement(
    TableRow,
    {
      fontSize: font && font.fontSize,
      borderBottomOnly: borderBottomOnly
    },
    _react2.default.createElement('td', { style: { border: 'none' } }),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        TableData,
        { key: '' + vehicle.id, borderBottomOnly: borderBottomOnly },
        _react2.default.createElement(
          'div',
          {
            style: {
              padding: '10px 20px',
              display: 'flex',
              justifyContent: 'center'
            }
          },
          _react2.default.createElement(_Global.Button, {
            onClick: function onClick() {
              return requestInformation(vehicle);
            },
            text: buttonLabel,
            applyStyle: 'primary',
            buttonStyle: buttonStyle,
            styleOverride: function styleOverride() {
              return '\n              width: 100%;\n            ';
            }
          })
        )
      );
    })
  );
};

var ShortlistRow = function ShortlistRow(_ref4) {
  var comparedVehicles = _ref4.comparedVehicles,
      shortlistVehicle = _ref4.shortlistVehicle,
      getShortListedIconColour = _ref4.getShortListedIconColour,
      translations = _ref4.translations,
      globalStyling = _ref4.globalStyling,
      font = _ref4.font,
      primaryBrandFontColourOnIconButtons = _ref4.primaryBrandFontColourOnIconButtons,
      borderBottomOnly = _ref4.borderBottomOnly;
  return _react2.default.createElement(
    TableRow,
    {
      fontSize: font && font.fontSize,
      borderBottomOnly: borderBottomOnly
    },
    _react2.default.createElement('td', { style: { border: 'none' } }),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        TableData,
        { key: '' + vehicle.id, borderBottomOnly: borderBottomOnly },
        _react2.default.createElement(
          'div',
          { style: { display: 'flex', justifyContent: 'center' } },
          _react2.default.createElement(
            IconButton,
            { onClick: function onClick() {
                return shortlistVehicle(vehicle);
              } },
            _react2.default.createElement(_Heart2.default, {
              width: primaryBrandFontColourOnIconButtons ? '1em' : '2em',
              height: '2em',
              colour: getShortListedIconColour(vehicle.id)
            }),
            _react2.default.createElement(
              _Global.HeadingThree,
              {
                styleOverride: function styleOverride() {
                  return '\n              padding-left: 10px;\n              ' + (primaryBrandFontColourOnIconButtons && 'color: ' + (globalStyling && globalStyling.colours.primaryBrandColour.value) + ';') + '\n            ';
                }
              },
              translations.compareVehiclesSaveText
            )
          )
        )
      );
    })
  );
};

var hasDataToDisplayInRow = function hasDataToDisplayInRow(comparedVehicles, keyValues, selector) {
  return [0, 1, 2].some(function (n) {
    return keyValues ? (0, _path2.default)(keyValues, comparedVehicles[n]) : selector(comparedVehicles[n]);
  });
};

var DataRow = function DataRow(_ref5) {
  var label = _ref5.label,
      comparedVehicles = _ref5.comparedVehicles,
      keyValues = _ref5.keyValues,
      font = _ref5.font,
      _ref5$visible = _ref5.visible,
      visible = _ref5$visible === undefined ? true : _ref5$visible,
      borderBottomOnly = _ref5.borderBottomOnly,
      selector = _ref5.selector,
      secondarySelector = _ref5.secondarySelector,
      secondaryLabel = _ref5.secondaryLabel;

  if (!hasDataToDisplayInRow(comparedVehicles, keyValues, selector)) {
    return _react2.default.createElement('td', { style: { border: 'none' } });
  }
  return visible ? _react2.default.createElement(
    TableRow,
    {
      fontSize: font && font.fontSize,
      borderBottomOnly: borderBottomOnly
    },
    _react2.default.createElement(
      TableData,
      { borderBottomOnly: borderBottomOnly },
      _react2.default.createElement(RenderRow, { content: label })
    ),
    [0, 1, 2].map(function (n) {
      return comparedVehicles[n] ? _react2.default.createElement(
        TableData,
        {
          key: '' + comparedVehicles[n].id,
          borderBottomOnly: borderBottomOnly
        },
        _react2.default.createElement(RenderRow, {
          content: keyValues ? (0, _path2.default)(keyValues, comparedVehicles[n]) || '-' : selector(comparedVehicles[n]),
          secondaryContent: secondarySelector && secondarySelector(comparedVehicles[n]),
          secondaryLabel: secondaryLabel && secondaryLabel(comparedVehicles[n]),
          font: font
        })
      ) : _react2.default.createElement('td', { style: { border: 'none' } });
    })
  ) : null;
};

DataRow.defaultProps = {
  borderBottom: false
};

var getEquipmentMetaDataIcon = function getEquipmentMetaDataIcon(vehicle, label, iconColour) {
  var uppercaseLabel = label.toUpperCase();
  var filteredOtherCategory = vehicle.otherEquipment && vehicle.otherEquipment.filter(function (equipment) {
    return equipment.category.toUpperCase() === uppercaseLabel;
  }) || [];
  var filteredOtherInitialCategory = vehicle.otherEquipment && vehicle.otherEquipment[0] && vehicle.otherEquipment[0].equipment.filter(function (equipment) {
    return equipment === label;
  }) || [];
  var filteredEquipmentData = vehicle.equipmentMetaData && vehicle.equipmentMetaData.filter(function (equipment) {
    return equipment.toUpperCase() === uppercaseLabel;
  }) || [];
  var exists = [].concat((0, _toConsumableArray3.default)(filteredEquipmentData), (0, _toConsumableArray3.default)(filteredOtherInitialCategory), (0, _toConsumableArray3.default)(filteredOtherCategory)).length > 0;
  return exists ? TickIconBlock(iconColour) : CloseIconBlock(iconColour);
};

var EquipmentMetaDataRow = function EquipmentMetaDataRow(_ref6) {
  var label = _ref6.label,
      comparedVehicles = _ref6.comparedVehicles,
      font = _ref6.font,
      iconColour = _ref6.iconColour,
      borderBottomOnly = _ref6.borderBottomOnly;
  return _react2.default.createElement(
    TableRow,
    {
      fontSize: font && font.fontSize,
      borderBottomOnly: borderBottomOnly
    },
    _react2.default.createElement(
      TableData,
      { borderBottomOnly: borderBottomOnly },
      _react2.default.createElement(RenderRow, { content: label, alignment: 'left' })
    ),
    comparedVehicles.map(function (vehicle) {
      return vehicle && vehicle.equipmentMetaData ? _react2.default.createElement(
        TableData,
        { key: '' + vehicle.id, borderBottomOnly: borderBottomOnly },
        _react2.default.createElement(RenderRow, {
          content: getEquipmentMetaDataIcon(vehicle, label, iconColour || '#000000'),
          font: font
        })
      ) : _react2.default.createElement(
        TableData,
        { key: '' + vehicle.id, borderBottomOnly: borderBottomOnly },
        _react2.default.createElement(RenderRow, {
          content: CloseIconBlock(iconColour || '#000000'),
          font: font
        })
      );
    })
  );
};

EquipmentMetaDataRow.defaultProps = {
  borderBottom: false
};

var OptionalExtrasRow = function OptionalExtrasRow(_ref7) {
  var label = _ref7.label,
      comparedVehicles = _ref7.comparedVehicles,
      optionalExtras = _ref7.optionalExtras,
      toggleOptionalExtras = _ref7.toggleOptionalExtras,
      font = _ref7.font,
      optionalExtrasBackGroundColour = _ref7.optionalExtrasBackGroundColour,
      borderBottomOnly = _ref7.borderBottomOnly;
  return _react2.default.createElement(
    TableRow,
    {
      fontSize: font && font.fontSize,
      borderBottomOnly: borderBottomOnly
    },
    _react2.default.createElement(
      TableData,
      {
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: optionalExtrasBackGroundColour ? '' + optionalExtrasBackGroundColour : '#f0f0f0',
          color: font && font.colour ? '' + font.colour.value : '#000000',
          textTransform: 'uppercase',
          lineHeight: '40px',
          fontSize: font ? font.fontSize : 20
        },
        font: font,
        borderBottomOnly: borderBottomOnly
      },
      label,
      _react2.default.createElement(
        IconButton,
        { onClick: toggleOptionalExtras },
        optionalExtras ? _react2.default.createElement(_Minus2.default, { width: '2em', height: '2em', colour: '#7e7e7e' }) : _react2.default.createElement(_Plus2.default, { width: '2em', height: '2em', colour: '#7e7e7e' })
      )
    ),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(TableData, {
        font: font,
        key: '' + vehicle.id,
        style: {
          backgroundColor: optionalExtrasBackGroundColour ? '' + optionalExtrasBackGroundColour : '#f0f0f0'
        },
        borderBottomOnly: borderBottomOnly
      });
    })
  );
};

function CompareVehicles(props) {
  var requestInformation = props.requestInformation,
      translations = props.translations,
      optionalExtras = props.optionalExtras,
      toggleOptionalExtras = props.toggleOptionalExtras,
      _props$comparedVehicl = props.comparedVehicles,
      comparedVehicles = _props$comparedVehicl === undefined ? [] : _props$comparedVehicl,
      removeVehicle = props.removeVehicle,
      shortlistVehicle = props.shortlistVehicle,
      getShortListedIconColour = props.getShortListedIconColour,
      equipmentMetaData = props.equipmentMetaData,
      closeIconColour = props.closeIconColour,
      phoneIconColour = props.phoneIconColour,
      buttonStyle = props.buttonStyle,
      gridConfiguration = props.gridConfiguration,
      globalStyling = props.globalStyling,
      headerFont = props.headerFont,
      infoFont = props.infoFont,
      subHeaderFont = props.subHeaderFont,
      primaryBrandFontColourOnIconButtons = props.primaryBrandFontColourOnIconButtons,
      optionalExtrasBackgroundColour = props.optionalExtrasBackgroundColour,
      borderBottomOnly = props.borderBottomOnly,
      headerOverSubheader = props.headerOverSubheader,
      locale = props.locale,
      onMoreInfoClick = props.onMoreInfoClick;

  var headingStyleOverride = function headingStyleOverride() {
    return '\n    ' + (0, _Global.fontStyleOverride)(headerFont) + '\n    flex: 1 0 0%;';
  };

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      exchangeRates: state.shared.currencyConversion.exchangeRates || null,
      secondaryCurrency: state.config.config.global.inventory.secondaryCurrency || null
    };
  }),
      exchangeRates = _useSelector.exchangeRates,
      secondaryCurrency = _useSelector.secondaryCurrency;

  return comparedVehicles.length > 0 ? _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      HeaderContainer,
      null,
      !headerOverSubheader && _react2.default.createElement(
        _Global.HeadingTwo,
        { styleOverride: headingStyleOverride },
        translations.compareVehiclesHeader
      )
    ),
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        'tbody',
        null,
        _react2.default.createElement(
          TableRow,
          {
            fontSize: subHeaderFont && subHeaderFont.fontSize,
            borderBottomOnly: borderBottomOnly
          },
          _react2.default.createElement(
            'td',
            { style: { width: '25%' } },
            headerOverSubheader && _react2.default.createElement(
              _Global.HeadingTwo,
              { styleOverride: headingStyleOverride },
              translations.compareVehiclesHeader
            ),
            _react2.default.createElement(
              _Global.Paragraph,
              {
                styleOverride: function styleOverride() {
                  return '\n                font-size: ' + (subHeaderFont ? subHeaderFont.fontSize + 'px' : '18px') + ';\n                line-height: ' + (subHeaderFont ? subHeaderFont.lineHeight + 'px' : '22px') + ';\n                text-align: ' + (subHeaderFont ? '' + subHeaderFont.align : 'center') + ';\n              ';
                }
              },
              translations.compareVehiclesSubHeader
            )
          ),
          comparedVehicles.map(function (vehicle) {
            return _react2.default.createElement(
              'td',
              { key: '' + vehicle.id, style: { width: '25%' } },
              _react2.default.createElement(
                'div',
                { style: { display: 'flex', justifyContent: 'flex-end' } },
                _react2.default.createElement(
                  IconButton,
                  { onClick: function onClick() {
                      return removeVehicle(vehicle);
                    } },
                  _react2.default.createElement(_Remove2.default, { width: '2em', height: '2em', colour: closeIconColour })
                )
              ),
              _react2.default.createElement(
                ImageContainer,
                {
                  onClick: function onClick() {
                    return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
                  }
                },
                vehicle.images.length > 0 ? _react2.default.createElement(StyledImage, {
                  src: vehicle.images[0],
                  width: '100%',
                  alt: vehicle.name
                }) : _react2.default.createElement(StyledImage, {
                  src: 'https://res.cloudinary.com/motortrak/image/upload/v1536583771/Placeholder%20Images/PlaceholderJAG.png',
                  alt: 'placeholderImage'
                }),
                vehicle.status === 'sold' && _react2.default.createElement(
                  ImageOverlay,
                  null,
                  _react2.default.createElement(
                    SoldHeading,
                    null,
                    translations.sold
                  ),
                  _react2.default.createElement(
                    SoldBody,
                    null,
                    translations.similarVehiclesAvailable
                  )
                )
              )
            );
          })
        ),
        _react2.default.createElement(DataRow, {
          label: translations.comparePrice,
          comparedVehicles: comparedVehicles,
          font: infoFont,
          borderBottomOnly: borderBottomOnly,
          selector: function selector(v) {
            return (0, _numbers.localiseCurrency)(v.price.value, locale, v.price.currency, 0);
          },
          secondarySelector: function secondarySelector(v) {
            return secondaryCurrency && exchangeRates[v.price.currency] && (0, _numbers.localiseCurrency)((0, _helpers.convertSecondaryPriceCurrency)(v.price.value, exchangeRates[v.price.currency].rates, v.price.currency, secondaryCurrency), locale, secondaryCurrency, 0);
          },
          secondaryLabel: function secondaryLabel(v) {
            return secondaryCurrency && exchangeRates[v.price.currency] && (0, _translateFromTemplate.translateFromTemplate)('exchangeRate', {
              EXCHANGERATE: exchangeRates[v.price.currency].rates[secondaryCurrency].toFixed(2)
            }, translations);
          }
        }),
        _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesModelYear,
          comparedVehicles: comparedVehicles,
          keyValues: ['name'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesExterior,
          comparedVehicles: comparedVehicles,
          keyValues: ['exterior'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesInterior,
          comparedVehicles: comparedVehicles,
          keyValues: ['interior'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        translations.compareVehiclesVeneer && _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesVeneer,
          comparedVehicles: comparedVehicles,
          keyValues: ['veneer'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesOdometer,
          comparedVehicles: comparedVehicles,
          keyValues: ['odometer', 'display'],
          visible: gridConfiguration.showOdometer,
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesTransmission,
          comparedVehicles: comparedVehicles,
          keyValues: ['transmission'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        translations.compareVehiclesEmissions && _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesEmissions,
          comparedVehicles: comparedVehicles,
          keyValues: ['performance', 'emissions'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        translations.compareVehiclesFuelConsumption && _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesFuelConsumption,
          comparedVehicles: comparedVehicles,
          keyValues: ['performance', 'fuelConsumption'],
          visible: gridConfiguration.showFuelConsumption,
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesBodystyle,
          comparedVehicles: comparedVehicles,
          keyValues: ['bodystyle'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesEngine,
          comparedVehicles: comparedVehicles,
          keyValues: ['engine'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesFuelType,
          comparedVehicles: comparedVehicles,
          keyValues: ['fuel'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesRegistration,
          comparedVehicles: comparedVehicles,
          keyValues: ['registration'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        _react2.default.createElement(DataRow, {
          label: translations.compareVehiclesLocation,
          comparedVehicles: comparedVehicles,
          keyValues: ['location'],
          font: infoFont,
          borderBottomOnly: borderBottomOnly
        }),
        equipmentMetaData && equipmentMetaData.length && _react2.default.createElement(OptionalExtrasRow, {
          label: translations.compareVehiclesOptionalExtras,
          comparedVehicles: comparedVehicles,
          optionalExtras: optionalExtras,
          toggleOptionalExtras: toggleOptionalExtras,
          font: infoFont,
          iconColour: phoneIconColour,
          optionalExtrasBackgroundColour: optionalExtrasBackgroundColour,
          borderBottomOnly: borderBottomOnly
        }),
        optionalExtras && equipmentMetaData.map(function (equipment) {
          return _react2.default.createElement(EquipmentMetaDataRow, {
            key: '' + equipment,
            label: equipment,
            comparedVehicles: comparedVehicles,
            font: infoFont,
            globalStyling: globalStyling,
            borderBottomOnly: borderBottomOnly
          });
        }),
        _react2.default.createElement(RetailerPhoneRow, {
          comparedVehicles: comparedVehicles,
          globalStyling: globalStyling,
          phoneIconColour: phoneIconColour,
          font: infoFont,
          primaryBrandFontColourOnIconButtons: primaryBrandFontColourOnIconButtons,
          borderBottomOnly: borderBottomOnly,
          direction: globalStyling.direction
        }),
        _react2.default.createElement(MoreInfoRow, {
          requestInformation: requestInformation,
          comparedVehicles: comparedVehicles,
          buttonLabel: translations.compareVehiclesRequestInfo,
          buttonStyle: buttonStyle,
          font: infoFont,
          primaryBrandFontColourOnIconButtons: primaryBrandFontColourOnIconButtons,
          borderBottomOnly: borderBottomOnly
        }),
        _react2.default.createElement(ShortlistRow, {
          comparedVehicles: comparedVehicles,
          shortlistVehicle: shortlistVehicle,
          getShortListedIconColour: getShortListedIconColour,
          translations: translations,
          globalStyling: globalStyling,
          font: infoFont,
          primaryBrandFontColourOnIconButtons: primaryBrandFontColourOnIconButtons,
          borderBottomOnly: borderBottomOnly
        })
      )
    )
  ) : _react2.default.createElement(
    'div',
    { style: { width: '95%', margin: '5% 2.5%' } },
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: ' + (headerFont ? headerFont.fontSize + 'px' : '26px') + ';\n        text-transform: ' + (headerFont ? '' + headerFont.transform : 'uppercase;') + '\n        text-align: ' + (headerFont ? '' + headerFont.align : 'center;') + ';\n        flex: 1 0 0%;\n        letter-spacing: ' + (headerFont ? headerFont.kerning + 'px' : '3px;') + ';\n      ';
        }
      },
      translations.compareVehiclesHeader
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n          font-size: 18px;\n          line-height: 22px;\n          text-align: center;\n        ';
        }
      },
      translations.compareVehiclesSubHeader
    )
  );
}