'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Controls = require('./Controls');

var _Controls2 = _interopRequireDefault(_Controls);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var YouTubeWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'YouTubePlayer__YouTubeWrapper',
  componentId: 'sc-13nrpmm-0'
})(['display:flex;flex-direction:column;justify-content:center;align-items:center;position:relative;height:100%;width:100%;> iframe{width:100%;max-height:100vh;', ';}'], function (_ref) {
  var height = _ref.height;
  return height ? 'height: ' + height + ';' : 'min-height: 550px;';
});

exports.default = function (props) {
  var src = props.src,
      autoplay = props.autoplay,
      modestBranding = props.modestBranding,
      muted = props.muted,
      imagesIcon = props.imagesIcon,
      toggleImageView = props.toggleImageView,
      looping = props.looping,
      height = props.height,
      alternativeText = props.alternativeText,
      translations = props.translations;


  var boolToBinary = function boolToBinary(bool) {
    return bool ? 1 : 0;
  };
  var extraParams = '';
  if (looping) {
    extraParams = '&loop=1&playlist=' + src;
  }

  return _react2.default.createElement(
    YouTubeWrapper,
    { height: height },
    _react2.default.createElement('iframe', {
      width: '100%',
      src: 'https://www.youtube.com/embed/' + src + '?autoplay=' + boolToBinary(autoplay) + '&modestbranding=' + boolToBinary(modestBranding) + '&controls=1&mute=' + boolToBinary(muted) + extraParams,
      frameBorder: '0',
      allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;',
      allowFullScreen: true,
      title: alternativeText || 'Embedded youtube',
      alt: alternativeText || 'Embedded youtube',
      'aria-label': translations && translations.label
    }),
    imagesIcon && toggleImageView && _react2.default.createElement(_Controls2.default, { imagesIcon: imagesIcon, toggleImageView: toggleImageView })
  );
};