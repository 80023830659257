'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 32px 20px;\n  '], ['\n    padding: 32px 20px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'DealerNotFound__Container',
  componentId: 'sc-104wn56-0'
})(['text-align:', ';padding:32px 0px;', ';'], function (_ref) {
  var align = _ref.align;
  return align;
}, _theme2.default.max.medium(_templateObject));

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DealerNotFound__ButtonContainer',
  componentId: 'sc-104wn56-1'
})(['display:flex;justify-content:center;']);

var TextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DealerNotFound__TextContainer',
  componentId: 'sc-104wn56-2'
})(['margin:10px;']);

var DealerNotFound = function DealerNotFound(_ref2) {
  var config = _ref2.config,
      globalStyling = _ref2.globalStyling;
  return _react2.default.createElement(
    _ContentWrapper2.default,
    {
      colour: config.backgroundColour && config.backgroundColour,
      contentWidth: globalStyling.contentWidth
    },
    _react2.default.createElement(
      Container,
      { align: config.alignment },
      _react2.default.createElement(
        TextContainer,
        null,
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n                ' + (0, _Global.fontStyleOverride)(config.headlineFont) + '\n                max-width: 800px;\n                margin: auto;\n              ';
            }
          },
          config.translations.headlineText
        )
      ),
      _react2.default.createElement(
        TextContainer,
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          null,
          config.translations.bodyText
        )
      ),
      _react2.default.createElement(
        ButtonContainer,
        null,
        _react2.default.createElement(_Global.Button, {
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          applyStyle: 'primary',
          text: config.translations.buttonText,
          onClick: function onClick() {
            window.location = window.location.origin;
          }
        })
      )
    )
  );
};

exports.default = DealerNotFound;