'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AstonMartinArrow;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Arrow__IconWrapper',
  componentId: 'sc-179qz6b-0'
})(['display:flex;align-items:center;svg > path{stroke:', ';}svg{width:', ';height:', ';}'], function (_ref) {
  var colour = _ref.colour;
  return colour || '#fff';
}, function (_ref2) {
  var width = _ref2.width;
  return width;
}, function (_ref3) {
  var height = _ref3.height;
  return height;
});
function AstonMartinArrow(_ref4) {
  var colour = _ref4.colour,
      width = _ref4.width,
      height = _ref4.height,
      mobileStyleOverride = _ref4.mobileStyleOverride;

  return _react2.default.createElement(
    IconWrapper,
    {
      colour: colour,
      width: width,
      height: height,
      mobileStyleOverride: mobileStyleOverride
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 25 23' },
      _react2.default.createElement('path', { d: 'M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z' })
    )
  );
}
AstonMartinArrow.defaultProps = {
  colour: null,
  width: null,
  height: null
};