'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n    padding: 80px 24px 0;\n  '], ['\n    display: flex;\n    flex-direction: column;\n    padding: 80px 24px 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 70px 0 0;\n  '], ['\n    margin: 70px 0 0;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n  '], ['\n    display: flex;\n    flex-direction: column;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    & > *:nth-child(n+2) {\n      margin-right: 100px;\n    }\n  '], ['\n    & > *:nth-child(n+2) {\n      margin-right: 100px;\n    }\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: center;\n    margin-bottom: 80px;\n  '], ['\n    justify-content: center;\n    margin-bottom: 80px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 110px;\n  '], ['\n    margin-bottom: 110px;\n  ']);

exports.default = Footer;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _actions = require('../../../actions');

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AppShared = require('../../../types/AppShared');

var _footerTypes = require('../../../types/AstonMartin/footerTypes');

var _LanguagePicklist = require('../LanguagePicklist');

var _LanguagePicklist2 = _interopRequireDefault(_LanguagePicklist);

var _LinkList = require('../LinkList');

var _LinkList2 = _interopRequireDefault(_LinkList);

var _SocialMediaLinks = require('./SocialMediaLinks');

var _SocialMediaLinks2 = _interopRequireDefault(_SocialMediaLinks);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterContainer',
  componentId: 'sc-ob5fg5-0'
})(['color:#000;display:flex;padding:100px 48px 0;', ';flex-direction:column;background:#fff;'], _theme2.default.max.large(_templateObject));
var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__HeaderContainer',
  componentId: 'sc-ob5fg5-1'
})(['display:flex;border-bottom:1px solid #000;']);
var FooterHeader = _styledComponents2.default.h2.withConfig({
  displayName: 'Footer__FooterHeader',
  componentId: 'sc-ob5fg5-2'
})(['font:16px AstonMartinFlare-Medium;text-transform:uppercase;margin:0 0 40px;']);

var SocialsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__SocialsContainer',
  componentId: 'sc-ob5fg5-3'
})(['width:160px;']);
var BottomContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__BottomContainer',
  componentId: 'sc-ob5fg5-4'
})(['display:flex;justify-content:space-between;margin:200px 0 0;', ';'], _theme2.default.min.large(_templateObject2));

var LinkContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__LinkContainer',
  componentId: 'sc-ob5fg5-5'
})(['display:flex;transition:all 0.5s ease;margin-top:60px;', ';', ';'], _theme2.default.max.large(_templateObject3), _theme2.default.min.large(_templateObject4));

var EmissionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__EmissionsContainer',
  componentId: 'sc-ob5fg5-6'
})(['font:12px AstonMartinSans-Light;line-height:16px;margin:130px 0 0;max-width:920px;']);
var SuperContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__SuperContainer',
  componentId: 'sc-ob5fg5-7'
})(['font:12px AstonMartinSans-Light;line-height:16px;margin:20px 0 0;max-width:920px;']);
var EmissionsLinkText = _styledComponents2.default.a.withConfig({
  displayName: 'Footer__EmissionsLinkText',
  componentId: 'sc-ob5fg5-8'
})(['cursor:pointer;display:block;padding-top:20px;text-transform:uppercase;text-decoration:underline;color:inherit;:visited{color:inherit;}']);

var CopyrightBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__CopyrightBarContainer',
  componentId: 'sc-ob5fg5-9'
})(['display:flex;justify-content:end;font:14px AstonMartinSans-Light;flex-wrap:wrap;line-height:16px;margin:48px 0 20px;margin-bottom:60px;', ';', ';'], _theme2.default.max.large(_templateObject5), _theme2.default.max.small(_templateObject6));

var CopyrightBarLink = _styledComponents2.default.a.withConfig({
  displayName: 'Footer__CopyrightBarLink',
  componentId: 'sc-ob5fg5-10'
})(['color:#000;cursor:pointer;font:14px AstonMartinSans-Light;line-height:16px;text-decoration:none;padding:0 16px 28px 0;&:hover{text-decoration:underline;}']);

function Footer(_ref) {
  var config = _ref.config,
      navigateIfInternal = _ref.navigateIfInternal,
      dispatch = _ref.dispatch,
      shared = _ref.shared,
      locale = _ref.marketInfo.locale;

  var currentLanguage = shared.sessionPreferences.language || locale;
  var changeLanguage = function changeLanguage(option) {
    if (option) {
      dispatch(_actions.language.actions.changeLanguage(option.value));
      dispatch(_sessionPreferences.actions.updateSessionPreferences('language', option.value));
    }
  };

  var languageOptions = config.languageOptions;
  var selectedLanguage = languageOptions.find(function (lo) {
    return lo.value === currentLanguage;
  });

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl;

  return _react2.default.createElement(
    FooterContainer,
    null,
    _react2.default.createElement(
      HeaderContainer,
      null,
      _react2.default.createElement(
        FooterHeader,
        { 'data-cy': 'footer-title' },
        config.translations.heading
      )
    ),
    _react2.default.createElement(
      LinkContainer,
      null,
      _react2.default.createElement(_LinkList2.default, {
        items: config.navItems.map(function (value, idx) {
          return (0, _extends3.default)({}, value, {
            label: config.translations['navItems' + idx]
          });
        }).filter(function (item) {
          return item.link;
        }),
        title: config.translations.navigation,
        navigateIfInternal: navigateIfInternal,
        config: config
      }),
      _react2.default.createElement(_LinkList2.default, {
        items: config.browseAston.map(function (value, idx) {
          return (0, _extends3.default)({}, value, {
            label: config.translations['browseAston' + idx]
          });
        }).filter(function (item) {
          return item.link;
        }),
        title: config.translations.browseAstonMartin,
        navigateIfInternal: navigateIfInternal,
        config: config
      }),
      _react2.default.createElement(_LinkList2.default, {
        items: config.infoItems.map(function (value, idx) {
          return (0, _extends3.default)({}, value, {
            label: config.translations['infoItems' + idx]
          });
        }).filter(function (item) {
          return item.link;
        }),
        title: config.translations.information,
        navigateIfInternal: navigateIfInternal,
        config: config
      })
    ),
    _react2.default.createElement(
      EmissionsContainer,
      null,
      config.translations.emissionsFooterText,
      _react2.default.createElement(
        EmissionsLinkText,
        {
          href: config.emissionsLinkUrl,
          target: getTargetUrl(config.emissionsLinkUrl),
          'data-cy': 'footer-emissions'
        },
        config.translations.emissionsLinkText
      )
    ),
    _react2.default.createElement(
      SuperContainer,
      { id: 'super-1' },
      config.translations.priceSuper,
      ' ',
      config.translations.priceSuperText
    ),
    _react2.default.createElement(
      BottomContainer,
      null,
      _react2.default.createElement(_LanguagePicklist2.default, {
        font: '',
        dataCy: 'footer-language-picklist',
        value: selectedLanguage && selectedLanguage.value,
        onChange: function onChange(event) {
          return changeLanguage(languageOptions.find(function (l) {
            return l.value === event.target.value;
          }));
        },
        options: languageOptions,
        enableContrastMode: false
      }),
      _react2.default.createElement(
        SocialsContainer,
        null,
        _react2.default.createElement(_SocialMediaLinks2.default, { socialMediaIconsList: config.socialMediaLinks })
      )
    ),
    _react2.default.createElement(
      CopyrightBarContainer,
      null,
      config.bottomFooterBarItems.map(function (_ref2) {
        var label = _ref2.label,
            link = _ref2.link,
            navId = _ref2.navId;
        return _react2.default.createElement(
          CopyrightBarLink,
          {
            href: link,
            'data-cy': 'footer-' + config.translations['' + label],
            target: getTargetUrl(link),
            onClick: function onClick() {
              return navigateIfInternal(link);
            },
            key: navId
          },
          config.translations['' + label]
        );
      }),
      config.translations.copyrightSiteName,
      ' ',
      new Date().getFullYear()
    )
  );
}