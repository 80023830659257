'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CheckboxUnchecked;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function CheckboxUnchecked(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height,
      ariaLabelText = _ref.ariaLabelText;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height,
      role: 'checkbox',
      'aria-checked': 'true',
      'aria-label': ariaLabelText
    },
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 26 26', width: '1em', height: '1em' },
      _react2.default.createElement('path', {
        d: 'M.5.5h25v25H.5z',
        fill: 'none',
        fillRule: 'evenodd',
        stroke: '#757575'
      })
    )
  );
}

CheckboxUnchecked.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null,
  ariaLabelText: null
};