'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoundedCheckBox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'RoundedCheckBox__Container',
  componentId: 'sc-hyfig0-0'
})(['display:flex;align-items:flex-start;cursor:pointer;text-align:left;']);

var CheckBoxWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RoundedCheckBox__CheckBoxWrapper',
  componentId: 'sc-hyfig0-1'
})(['border:2px solid ', ';border-radius:50%;padding:4px;'], function (_ref) {
  var colour = _ref.colour;
  return colour || '#676776';
});

var CheckBoxChecked = _styledComponents2.default.div.withConfig({
  displayName: 'RoundedCheckBox__CheckBoxChecked',
  componentId: 'sc-hyfig0-2'
})(['width:12px;height:12px;border-radius:50%;']);

var CheckBoxUnChecked = (0, _styledComponents2.default)(CheckBoxChecked).withConfig({
  displayName: 'RoundedCheckBox__CheckBoxUnChecked',
  componentId: 'sc-hyfig0-3'
})(['background-color:', ';'], function (_ref2) {
  var colour = _ref2.colour;
  return colour || '#6D30A7';
});

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'RoundedCheckBox__Label',
  componentId: 'sc-hyfig0-4'
})(['padding:0 5px 35px 10px;']);

var Checkbox = function Checkbox(_ref3) {
  var checked = _ref3.checked,
      colour = _ref3.colour;
  return checked ? _react2.default.createElement(CheckBoxUnChecked, { colour: colour }) : _react2.default.createElement(CheckBoxChecked, null);
};

Checkbox.defaultProps = {
  error: ''
};

function RoundedCheckBox(_ref4) {
  var onClick = _ref4.onClick,
      checked = _ref4.checked,
      label = _ref4.label,
      labelFontSize = _ref4.labelFontSize,
      required = _ref4.required,
      error = _ref4.error,
      colour = _ref4.colour;

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      Container,
      { onClick: onClick },
      _react2.default.createElement(
        CheckBoxWrapper,
        { colour: colour },
        _react2.default.createElement(Checkbox, { checked: checked, colour: colour })
      ),
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          Label,
          { labelFontSize: labelFontSize },
          label,
          required && '*'
        )
      )
    ),
    error && _react2.default.createElement(
      'div',
      {
        style: {
          color: '#9e1b32'
        }
      },
      error
    )
  );
}