'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TestDrive = require('../../components/TestDrive');

var _TestDrive2 = _interopRequireDefault(_TestDrive);

var _router = require('../../actions/router');

var _reducer = require('../../shared/CustomerLogin/reducer');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TestDrive = function (_Component) {
  (0, _inherits3.default)(TestDrive, _Component);

  function TestDrive() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, TestDrive);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = TestDrive.__proto__ || (0, _getPrototypeOf2.default)(TestDrive)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var actions = _this.props.actions;

        var location = _this.props.history.location.pathname;
        var vehicleId = location.split('/').pop();
        actions.getVehicleInfo(vehicleId);
      }
    }), Object.defineProperty(_this, 'componentWillUnmount', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.props.actions.reset();
      }
    }), Object.defineProperty(_this, 'onTitleChange', {
      enumerable: true,
      writable: true,
      value: function (_value) {
        function value(_x) {
          return _value.apply(this, arguments);
        }

        value.toString = function () {
          return _value.toString();
        };

        return value;
      }(function (selected) {
        _this.props.dispatch(_this.props.actions.setFormValueFromDropdown(selected, _this.props.config.translations));
      })
    }), Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            _this$props$state = _this$props.state,
            testDriveConfig = _this$props$state.testDriveConfig,
            context = _this$props$state.context,
            open = _this$props$state.open,
            bookingForm = _this$props$state.bookingForm,
            bookingFormError = _this$props$state.bookingFormError,
            selectDateMode = _this$props$state.selectDateMode,
            selectDateModeOptions = _this$props$state.selectDateModeOptions,
            selectTimeMode = _this$props$state.selectTimeMode,
            selectTimeModeOptions = _this$props$state.selectTimeModeOptions,
            selectedDateMode = _this$props$state.selectedDateMode,
            selectedTimeMode = _this$props$state.selectedTimeMode,
            disableCalendar = _this$props$state.disableCalendar,
            loginFailed = _this$props$state.loginFailed,
            somethingWentWrong = _this$props$state.somethingWentWrong,
            userCreated = _this$props$state.userCreated,
            newUserEmail = _this$props$state.newUserEmail,
            config = _this$props.config,
            dispatch = _this$props.dispatch,
            pathByModule = _this$props.pathByModule,
            actions = _this$props.actions,
            shared = _this$props.shared,
            preview = _this$props.preview,
            globalStyling = _this$props.globalStyling,
            history = _this$props.history;


        var vehicleId = history.location.pathname.split('/').pop();
        var navigateToVdp = function navigateToVdp() {
          return dispatch(_router.actions.navigate(pathByModule('VDP') + '/' + vehicleId));
        };
        var userIsLoggedIn = preview ? false : (0, _reducer.isTokenValid)(shared);
        return testDriveConfig ? _react2.default.createElement(_TestDrive2.default, {
          goBack: function goBack(ctx) {
            return actions.changeContext(ctx);
          },
          translations: config.translations,
          context: context,
          setBookingFormValue: actions.setBookingFormValue,
          onTitleChange: _this.onTitleChange,
          continueAsGuest: actions.continueAsGuest,
          userCreated: userCreated,
          newUserEmail: newUserEmail,
          confirmRequest: actions.confirmRequest,
          toggleCalendar: actions.toggleCalendar,
          loginAndConfirm: actions.loginAndConfirm,
          loginFailed: loginFailed,
          userIsLoggedIn: userIsLoggedIn,
          somethingWentWrong: somethingWentWrong,
          open: open,
          bookingForm: bookingForm,
          bookingFormError: bookingFormError,
          testDriveConfig: testDriveConfig,
          selectDateMode: selectDateMode,
          selectDateModeOptions: selectDateModeOptions,
          setSelectRadioGroupMode: actions.setSelectRadioGroupMode,
          selectTimeMode: selectTimeMode,
          selectTimeModeOptions: selectTimeModeOptions,
          disableCalendar: disableCalendar,
          confirmDateAndTime: actions.confirmDateAndTime,
          sendTestDriveLead: actions.userLoggedIn,
          selectedDateMode: selectedDateMode,
          selectedTimeMode: selectedTimeMode,
          interestedClicked: actions.interestedClicked,
          globalStyling: globalStyling,
          contactUs: function contactUs(vehicle) {
            return dispatch(_router.actions.navigate('/enquiry/' + vehicle.id));
          },
          goToMyAccount: function goToMyAccount() {
            return dispatch(_router.actions.navigate('/myaccount'));
          },
          onVisitUs: function onVisitUs(retailerInformation) {
            return dispatch(_router.actions.navigateWithPayload(pathByModule('RetailerLocationSearch'), retailerInformation));
          },
          navigateToVdp: navigateToVdp
        }) : null;
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  return TestDrive;
}(_react.Component);

exports.default = TestDrive;