'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 32px 0px;\n  '], ['\n    padding: 32px 0px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['flex-direction: row;'], ['flex-direction: row;']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    flex-basis: ', ';\n    @supports(display: grid) {\n      padding: 0px 32px 32px 0px;\n      &:nth-child(', ') {\n        padding-right: 0px;\n      }\n    }\n  '], ['\n    flex-basis: ', ';\n    @supports(display: grid) {\n      padding: 0px 32px 32px 0px;\n      &:nth-child(', ') {\n        padding-right: 0px;\n      }\n    }\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 20%;\n    min-width: 2.5em;\n    height: 2.5em;\n    margin: 20px 24px 20px 32px;\n  '], ['\n    width: 20%;\n    min-width: 2.5em;\n    height: 2.5em;\n    margin: 20px 24px 20px 32px;\n  ']); /* eslint-disable no-nested-ternary */


exports.default = VehicleDataGrid;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Global = require('../../components/Global');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicle = require('../../helpers/vehicle');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var criteria = function criteria(itemLength) {
  return [2, 4].includes(itemLength);
};
var getNumberOfColumns = function getNumberOfColumns(itemLength, meetsCritera, defaultValue) {
  return criteria(itemLength) ? meetsCritera : defaultValue;
};

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDataGrid__Container',
  componentId: 'sc-c78x8r-0'
})(['', ';'], _theme2.default.min.medium(_templateObject));

var ItemsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDataGrid__ItemsWrapper',
  componentId: 'sc-c78x8r-1'
})(['display:flex;flex-direction:column;flex-wrap:wrap;', ';'], _theme2.default.min.medium(_templateObject2));

var ItemWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDataGrid__ItemWrapper',
  componentId: 'sc-c78x8r-2'
})(['display:flex;flex-grow:0;flex-shrink:0;box-sizing:border-box;', ';'], _theme2.default.min.medium(_templateObject3, function (_ref) {
  var itemCount = _ref.itemCount;
  return getNumberOfColumns(itemCount, '50%', '33.3%');
}, function (_ref2) {
  var itemCount = _ref2.itemCount;
  return getNumberOfColumns(itemCount, '2n+2', '3n+3');
}));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDataGrid__Item',
  componentId: 'sc-c78x8r-3'
})(['box-sizing:border-box;display:flex;flex-direction:row;flex:1 1 0%;align-items:center;border:1px solid #989898;', ';'], function (_ref3) {
  var backgroundColour = _ref3.backgroundColour;
  return backgroundColour && 'background-color: ' + backgroundColour.value + ';';
});

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleDataGrid__Image',
  componentId: 'sc-c78x8r-4'
})(['width:auto;margin:8px 16px;', ';'], _theme2.default.min.medium(_templateObject4));

var ItemText = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDataGrid__ItemText',
  componentId: 'sc-c78x8r-5'
})(['width:100%;display:flex;flex-direction:column;justify-content:center;']);

function VehicleDataGrid(_ref4) {
  var config = _ref4.config,
      globalStyling = _ref4.globalStyling,
      shared = _ref4.shared,
      locale = _ref4.marketInfo.locale;
  var vehicle = shared.vehicleInformation.vehicle;


  return vehicle && _react2.default.createElement(
    _ContentWrapper2.default,
    { contentWidth: globalStyling.contentWidth },
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return 'margin: 0px 0px 32px;\n              font-size: 28px;\n              line-height: 35px;\n              ' + (0, _Global.fontStyleOverride)(config.headingFont);
          }
        },
        config.translations.heading
      ),
      _react2.default.createElement(
        ItemsWrapper,
        null,
        config.items.map(function (item) {
          return _react2.default.createElement(
            ItemWrapper,
            { itemCount: config.items.length },
            _react2.default.createElement(
              Item,
              { backgroundColour: config.itemBackgroundColour },
              _react2.default.createElement(Image, { src: item.image.value, alt: item.image.label }),
              _react2.default.createElement(
                ItemText,
                null,
                _react2.default.createElement(
                  _Global.Paragraph,
                  {
                    styleOverride: function styleOverride() {
                      return 'margin: 0;\n                        font-size: 16px;\n                        line-height: 19px;\n                        ' + (0, _Global.fontStyleOverride)(config.titleFont);
                    }
                  },
                  item.translations.title
                ),
                _react2.default.createElement(
                  _Global.HeadingThree,
                  {
                    styleOverride: function styleOverride() {
                      return 'margin: 0;\n                        line-height: 25px;\n                        font-size: 20px;\n                        ' + (0, _Global.fontStyleOverride)(config.dataFont);
                    }
                  },
                  (0, _vehicle.vehicleDataFormatter)(item.data, vehicle, locale)
                )
              )
            )
          );
        })
      )
    )
  );
}