'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVehicleGallery = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useVehicleGallery = exports.useVehicleGallery = function useVehicleGallery(props) {
  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  var _useState3 = (0, _react.useState)(0),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      currentCarouselImage = _useState4[0],
      setCurrentCarouselImage = _useState4[1];

  var rotateGallery = props.rotateGallery,
      direction = props.direction,
      slides = props.slides,
      placeholdingImage = props.placeholdingImage;


  var containerRef = _react2.default.createRef();

  /**
   * Opens a vehicle image in a modal.
   */
  var openLightbox = function openLightbox() {
    return setIsOpen(true);
  };

  /**
   * Closes a vehicle image displayed in a modal.
   */
  var closeLightbox = function closeLightbox() {
    return setIsOpen(false);
  };

  /**
   * Selects a specific image in the slideshow image component.
   */
  var goToSlide = function goToSlide(slide) {
    var newSlide = slide;

    if (rotateGallery && slide < 0) {
      newSlide = slides.length - 1;
    }

    if (rotateGallery && slide >= slides.length) {
      newSlide = 0;
    }

    setCurrentCarouselImage(newSlide);

    var _containerRef$current = containerRef.current,
        clientWidth = _containerRef$current.clientWidth,
        scrollWidth = _containerRef$current.scrollWidth;

    var singleScrollDist = (scrollWidth - clientWidth) / (slides.length - 1);
    var scrollDist = singleScrollDist * newSlide;

    if (containerRef.current) {
      if (direction === 'rtl') {
        containerRef.current.style.right = scrollDist * -1 + 'px';
      } else {
        containerRef.current.style.left = scrollDist * -1 + 'px';
      }
    }
  };

  /**
   * Handles thumbnail click event.
   */
  var handleThumbnailClick = function handleThumbnailClick(nextSlide) {
    return setCurrentCarouselImage(nextSlide);
  };

  /**
   * Parse slides collection.
   * @return An array of images.
   */
  var getSlides = function getSlides() {
    return slides.length > 0 ? slides : [{ id: placeholdingImage, image: placeholdingImage }];
  };

  /**
   * Gets next left image index.
   */
  var nextLeftImageIndex = direction === 'rtl' ? currentCarouselImage + 1 : currentCarouselImage - 1;

  /**
   * Gets next right image index.
   */
  var nextRightImageIndex = direction === 'rtl' ? currentCarouselImage - 1 : currentCarouselImage + 1;

  return {
    // state
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    currentCarouselImage: currentCarouselImage,
    setCurrentCarouselImage: setCurrentCarouselImage,
    containerRef: containerRef,
    // Functions
    openLightbox: openLightbox,
    closeLightbox: closeLightbox,
    goToSlide: goToSlide,
    handleThumbnailClick: handleThumbnailClick,
    getSlides: getSlides,
    // Calculations
    nextLeftImageIndex: nextLeftImageIndex,
    nextRightImageIndex: nextRightImageIndex
  };
};