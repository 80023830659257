'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.constants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends6 = require('babel-runtime/helpers/extends');

var _extends7 = _interopRequireDefault(_extends6);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

var _helpers = require('../currencyConversion/helpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_SHORTLIST_SHARED';

var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['TOGGLE_VEHICLE', 'TOGGLE_VEHICLE_SUCCESS', 'GET_VEHICLES', 'GET_VEHICLES_SUCCESS', 'SAVE_SHORTLISTED_VEHICLES', 'SAVE_SHORTLISTED_VEHICLES_SUCCESS', 'SAVE_SHORTLISTED_VEHICLES_FAILURE', 'UPDATE_FINANCE_SUCCESS', 'UPDATE_VEHICLE_PRICES']);

var actions = exports.actions = {
  toggleVehicle: function toggleVehicle(payload) {
    return { type: constants.TOGGLE_VEHICLE, payload: payload };
  },
  toggleVehicleSuccess: function toggleVehicleSuccess(payload) {
    return {
      type: constants.TOGGLE_VEHICLE_SUCCESS,
      payload: payload
    };
  },
  getVehicles: function getVehicles() {
    return { type: constants.GET_VEHICLES };
  },
  getVehiclesSuccess: function getVehiclesSuccess(payload) {
    return {
      type: constants.GET_VEHICLES_SUCCESS,
      payload: payload
    };
  },
  saveShorlistedVehicles: function saveShorlistedVehicles() {
    return { type: constants.SAVE_SHORTLISTED_VEHICLES };
  },
  saveShorlistedVehiclesSuccess: function saveShorlistedVehiclesSuccess(payload) {
    return {
      type: constants.SAVE_SHORTLISTED_VEHICLES_SUCCESS,
      payload: payload
    };
  },
  saveShorlistedVehiclesFailure: function saveShorlistedVehiclesFailure(error) {
    return {
      type: constants.SAVE_SHORTLISTED_VEHICLES_FAILURE,
      error: error
    };
  },
  updateFinanceSuccess: function updateFinanceSuccess(payload) {
    return {
      type: constants.UPDATE_FINANCE_SUCCESS,
      payload: payload
    };
  },
  updateVehiclePrices: function updateVehiclePrices(payload) {
    return {
      type: constants.UPDATE_VEHICLE_PRICES,
      payload: payload
    };
  }
};

var initialState = exports.initialState = {
  vehicles: []
};

var patch = function patch(vehicles, vehicle) {
  return [].concat((0, _toConsumableArray3.default)(vehicles.find(function (v) {
    return v.id === vehicle.id;
  }) ? [].concat((0, _toConsumableArray3.default)(vehicles.filter(function (v) {
    return v.id !== vehicle.id;
  }))) : [].concat((0, _toConsumableArray3.default)(vehicles), [vehicle])));
};

var replaceIfExists = function replaceIfExists(vehicles, vehicle) {
  return [].concat((0, _toConsumableArray3.default)(vehicles.map(function (v) {
    return v.id === vehicle.id ? vehicle : v;
  })));
};

var updateVehicleCurrencies = function updateVehicleCurrencies(state, action) {
  var payload = action.payload;
  var exchangeRates = payload.exchangeRates,
      currency = payload.currency;

  var vehiclesToUpdate = state[payload.statePath] || state.vehicles;

  var vehicles = (0, _helpers.convertVehiclesPriceCurrency)(vehiclesToUpdate, exchangeRates[currency].rates, currency);

  return (0, _extends7.default)({}, state, {
    vehicles: vehicles
  });
};

function reducer() {
  var _extends2;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case constants.GET_VEHICLES_SUCCESS:
      return (0, _extends7.default)({}, state, (_extends2 = {}, (0, _defineProperty3.default)(_extends2, payload.statePath, payload.vehicles), (0, _defineProperty3.default)(_extends2, 'placeholdingImage', payload.placeholdingImage), _extends2));
    case constants.TOGGLE_VEHICLE_SUCCESS:
      {
        var _extends3;

        return (0, _extends7.default)({}, state, (_extends3 = {}, (0, _defineProperty3.default)(_extends3, payload.statePath, patch(state[payload.statePath] || [], payload.vehicle, payload.placeholdingImage)), (0, _defineProperty3.default)(_extends3, 'placeholdingImage', payload.placeholdingImage), _extends3));
      }
    case constants.SAVE_SHORTLISTED_VEHICLES_SUCCESS:
      return (0, _extends7.default)({}, state, (0, _defineProperty3.default)({}, payload.statePath, payload.vehicles));
    case constants.SAVE_SHORTLISTED_VEHICLES_FAILURE:
      return (0, _extends7.default)({}, state, {
        error: payload
      });
    case constants.UPDATE_FINANCE_SUCCESS:
      return (0, _extends7.default)({}, state, (0, _defineProperty3.default)({}, payload.statePath, replaceIfExists(state.vehicles, payload.vehicle)));
    case constants.UPDATE_VEHICLE_PRICES:
      return updateVehicleCurrencies(state, action);
    default:
      return state;
  }
}