'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 40px;\n  '], ['\n    padding: 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 335px;\n    padding: 0 0 0 30px;\n  '], ['\n    max-width: 335px;\n    padding: 0 0 0 30px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    box-sizing: content-box;\n    max-width: 406px;\n    ', '\n  '], ['\n    box-sizing: content-box;\n    max-width: 406px;\n    ', '\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

var _types = require('./types');

var _VehicleInformation = require('./VehicleInformation');

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleMainInfo__Wrapper',
  componentId: 'sc-19w7m39-0'
})(['align-items:flex-start;background-color:#ffffff;box-sizing:border-box;display:flex;flex-direction:column;padding:20px 20px 0 20px;width:100%;', ' ', ' ', ' &&&{', '}'], _RollsRoyce2.default.min.small(_templateObject), _RollsRoyce2.default.min.medium(_templateObject2), _RollsRoyce2.default.min.large(_templateObject3, function (_ref) {
  var darkMode = _ref.darkMode;
  return darkMode && '  width: 378px';
}), function (_ref2) {
  var darkMode = _ref2.darkMode;
  return darkMode && ' background-color: #281432;padding: 0px;';
});

var VehicleDetailsPage = function VehicleDetailsPage(_ref3) {
  var config = _ref3.config,
      printingState = _ref3.printingState,
      translations = _ref3.translations,
      vehicle = _ref3.vehicle,
      globalStyling = _ref3.globalStyling,
      makeAnEnquiry = _ref3.makeAnEnquiry,
      printVehiclePdf = _ref3.printVehiclePdf,
      darkMode = _ref3.darkMode,
      isEnquiry = _ref3.isEnquiry;

  return _react2.default.createElement(
    Wrapper,
    { darkMode: darkMode },
    _react2.default.createElement(_VehicleInformation.VehicleTitleInfo, {
      vehicle: vehicle,
      translations: translations,
      config: config,
      darkMode: darkMode
    }),
    _react2.default.createElement(_VehicleInformation.VehicleGeneralInformation, {
      darkMode: darkMode,
      isEnquiry: isEnquiry,
      vehicle: vehicle,
      translations: translations,
      config: config,
      globalStyling: globalStyling,
      makeAnEnquiry: makeAnEnquiry,
      printVehiclePdf: printVehiclePdf,
      printingState: printingState
    })
  );
};

exports.default = VehicleDetailsPage;