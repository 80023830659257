'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 50px 5%;\n  '], ['\n    padding: 50px 5%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormFields = require('./FormFields');

var _ConfirmationWindow = require('./ConfirmationWindow');

var _ConfirmationWindow2 = _interopRequireDefault(_ConfirmationWindow);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('./Global');

var _validation = require('../helpers/validation');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ResetPasswordContent = _styledComponents2.default.div.withConfig({
  displayName: 'ResetPassword__ResetPasswordContent',
  componentId: 'sc-170yt2h-0'
})(['padding:50px 35%;', ';'], _theme2.default.max.large(_templateObject));
var ResetPasswordFailure = _styledComponents2.default.div.withConfig({
  displayName: 'ResetPassword__ResetPasswordFailure',
  componentId: 'sc-170yt2h-1'
})(['font-size:16px;color:#9e1b32;font-weight:600;margin-bottom:10px;']);

var ResetPassword = function (_Component) {
  (0, _inherits3.default)(ResetPassword, _Component);

  function ResetPassword(props) {
    (0, _classCallCheck3.default)(this, ResetPassword);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ResetPassword.__proto__ || (0, _getPrototypeOf2.default)(ResetPassword)).call(this, props));

    Object.defineProperty(_this, 'onInputChange', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value) {
        var error = _this.validateSingle(formKey, _value);
        _this.setState(function (prevState) {
          var _ref;

          return _ref = {}, (0, _defineProperty3.default)(_ref, formKey, _value), (0, _defineProperty3.default)(_ref, 'errors', (0, _extends3.default)({}, prevState.errors, error)), _ref;
        });
      }
    });
    Object.defineProperty(_this, 'onFormSubmit', {
      enumerable: true,
      writable: true,
      value: function value(event) {
        var validations = _this.validateForm();
        _this.setState(function () {
          return { errors: validations };
        });
        var valid = (0, _values2.default)(validations).filter(function (val) {
          return val !== undefined;
        }).length === 0;
        if (valid) {
          var _changePassword = _this.props.changePassword;
          var _password = _this.state.password;

          _changePassword(_password);
        }
        event.preventDefault();
      }
    });
    Object.defineProperty(_this, 'validateSingle', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value2) {
        var error = void 0;

        switch (formKey) {
          case 'password':
            {
              error = (0, _validation.meetsPasswordPolicy)(_value2, _this.props.translations, true);
              break;
            }
          case 'repeatPassword':
            {
              error = (0, _validation.passwordMatchesRequired)(_this.state.password, _value2, _this.props.translations);
              break;
            }
          default:
            break;
        }

        return (0, _defineProperty3.default)({}, formKey, error);
      }
    });
    Object.defineProperty(_this, 'validateForm', {
      enumerable: true,
      writable: true,
      value: function value() {
        var validations = {
          password: (0, _validation.meetsPasswordPolicy)(_this.state.password, _this.props.translations, true),
          repeatPassword: (0, _validation.passwordMatchesRequired)(_this.state.password, _this.state.repeatPassword, _this.props.translations)
        };

        return validations;
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            translations = _this$props.translations,
            resetPasswordStatus = _this$props.resetPasswordStatus,
            goToLogin = _this$props.goToLogin,
            globalStyling = _this$props.globalStyling;
        var _this$state = _this.state,
            password = _this$state.password,
            repeatPassword = _this$state.repeatPassword,
            errors = _this$state.errors;

        var hasError = function hasError(field) {
          return errors[field] && errors[field][0];
        };

        return resetPasswordStatus === 'SUCCESS' ? _react2.default.createElement(_ConfirmationWindow2.default, {
          translations: translations,
          onButtonClick: goToLogin,
          circleTickColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
        }) : _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n            height: 75px;\n            border-bottom: 1px solid #d8d8d8;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            font-size: 20px;\n            text-transform: uppercase;\n          ';
              }
            },
            translations.changePasswordHeader
          ),
          _react2.default.createElement(
            ResetPasswordContent,
            null,
            resetPasswordStatus === 'EXPIRED' && _react2.default.createElement(
              ResetPasswordFailure,
              null,
              translations.expiredMessage
            ),
            _react2.default.createElement(
              'form',
              { onSubmit: _this.onFormSubmit },
              _react2.default.createElement(_FormFields.InputField, {
                type: 'password',
                label: translations.newPassword,
                value: password,
                onChange: _this.onInputChange,
                keyValue: 'password',
                error: hasError('password')
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'password',
                label: translations.confirmPassword,
                value: repeatPassword,
                onChange: _this.onInputChange,
                keyValue: 'repeatPassword',
                error: hasError('repeatPassword')
              }),
              _react2.default.createElement(_Global.Button, {
                styleOverride: function styleOverride() {
                  return '\n                width: 100%;\n              ';
                },
                type: 'submit',
                text: translations.changeButtonText,
                applyStyle: 'primary',
                buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
              })
            )
          )
        );
      }
    });

    _this.state = {
      password: '',
      repeatPassword: '',
      errors: {}
    };
    return _this;
  }

  return ResetPassword;
}(_react.Component);

exports.default = ResetPassword;