'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n  '], ['\n  ', ';\n  ']);

var _splitEvery = require('ramda/src/splitEvery');

var _splitEvery2 = _interopRequireDefault(_splitEvery);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactGoogleMaps = require('react-google-maps');

var _MarkerClusterer = require('react-google-maps/lib/components/addons/MarkerClusterer');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _DealerTypes = require('../../types/DealerTypes');

var _Global = require('../Global');

var _Pin = require('../JaguarIcons/Global/Pin');

var _Pin2 = _interopRequireDefault(_Pin);

var _Button = require('./Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _GenesisInput = require('./GenesisInput');

var _Titles = require('./Titles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ZOOM_THRESHOLD = 10;

var RetailerInfoDiv = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerMap__RetailerInfoDiv',
  componentId: 'sc-1oz43pd-0'
})(['margin:15px 8px 15px 12px;max-width:400px;', ';'], _Genesis2.default.max.small(_templateObject, function (_ref) {
  var direction = _ref.direction;
  return direction === 'rtl' ? 'margin: 0 12px 0 0' : 'margin: 5px 4px 5px 0';
}));

var RetailerName = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerMap__RetailerName',
  componentId: 'sc-1oz43pd-1'
})(['color:#111;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:18px;font-weight:normal;margin-bottom:10px;']);

var RetailerAddress = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerMap__RetailerAddress',
  componentId: 'sc-1oz43pd-2'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:14px;line-height:14px;color:#111;margin-bottom:10px;']);

var RetailerPhone = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerMap__RetailerPhone',
  componentId: 'sc-1oz43pd-3'
})(['font-family:\'GenesisSansTextGlobal-Regular\';margin-bottom:10px;font-weight:600;font-size:14px;line-height:14px;']);

var RetailerDistance = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerMap__RetailerDistance',
  componentId: 'sc-1oz43pd-4'
})(['display:flex;align-items:center;margin-left:-3px;margin-bottom:10px;font-size:14px;']);

var TitleSearchWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerMap__TitleSearchWrapper',
  componentId: 'sc-1oz43pd-5'
})(['max-width:640px;margin:0 auto 80px;padding:0 20px;display:flex;flex-direction:column;align-items:center;justify-content:center;']);

var RetailerInfo = function RetailerInfo(props) {
  return _react2.default.createElement(
    RetailerInfoDiv,
    { direction: props.direction },
    _react2.default.createElement(
      RetailerName,
      null,
      props.retailer.name
    ),
    (0, _splitEvery2.default)(2, props.retailer.address).map(function (addLine) {
      return _react2.default.createElement(
        RetailerAddress,
        { tabIndex: '0' },
        addLine.join(', ')
      );
    }),
    props.retailer.phone && _react2.default.createElement(
      RetailerPhone,
      null,
      props.retailer.phone
    ),
    props.retailer.distanceToRetailer ? _react2.default.createElement(
      RetailerDistance,
      null,
      _react2.default.createElement(_Pin2.default, { width: '2em', height: '2em' }),
      props.retailer.distanceToRetailer,
      ' ',
      props.translations.distanceAway
    ) : null,
    _react2.default.createElement(_Button2.default, {
      variant: 'primary',
      onClick: function onClick() {
        return props.viewStockAction(props.retailer);
      },
      text: props.translations.viewStock,
      styleOverride: function styleOverride() {
        return 'width: 100%; border: 1px solid #111; margin-top: 20px';
      }
    })
  );
};

var RetailerPin = function RetailerPin(fillColor) {
  return {
    fillColor: fillColor,
    fillOpacity: 1,
    strokeWeight: 0,
    scale: 1.2,
    anchor: { x: 12, y: 33 },
    path: 'M11.7984563,32.328125 C19.5397634,22.5334923 23.4888381,15.841435 23.6456803,12.2519531 C23.8937768,6.57403174 19.6927416,0 11.7984563,0 C3.90417106,0 -0.230694235,6.58264819 0.00993810445,12.2519531 C0.162299051,15.8415815 4.09180512,22.5336388 11.7984563,32.328125 Z'
  };
};

var Map = function (_Component) {
  (0, _inherits3.default)(Map, _Component);

  function Map(props) {
    (0, _classCallCheck3.default)(this, Map);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Map.__proto__ || (0, _getPrototypeOf2.default)(Map)).call(this, props));

    Object.defineProperty(_this, 'onMapMounted', {
      enumerable: true,
      writable: true,
      value: function value(ref) {
        _this.map = ref;
      }
    });
    Object.defineProperty(_this, 'map', {
      enumerable: true,
      writable: true,
      value: null
    });
    Object.defineProperty(_this, 'toggle', {
      enumerable: true,
      writable: true,
      value: function value(marker) {
        if (_this.state.active && _this.state.active.id === marker.id) {
          _this.setState(function () {
            return { active: undefined };
          });
        } else {
          _this.setState(function () {
            return { active: marker };
          });
        }
      }
    });
    Object.defineProperty(_this, 'handleZoomChanged', {
      enumerable: true,
      writable: true,
      value: function value() {
        var zoomLevel = _this.map.getZoom();
        if (zoomLevel < _this.state.zoom) {
          _this.props.zoomChangedAction();
        }
      }
    });
    Object.defineProperty(_this, 'flipCoordinates', {
      enumerable: true,
      writable: true,
      value: function value(coordinates) {
        return _this.props.flipCoordinates ? { lat: coordinates.lng, lng: coordinates.lat } : coordinates;
      }
    });
    Object.defineProperty(_this, 'mapMarkers', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            retailers = _this$props.retailers,
            pin = _this$props.pin;
        var toggle = _this.toggle;

        if (retailers.length) {
          return retailers.map(function (marker) {
            var icon = _this.state.active && _this.state.active.id === marker.id ? pin.selectedColour.value : pin.colour.value;
            return _react2.default.createElement(
              _reactGoogleMaps.Marker,
              {
                icon: RetailerPin(icon),
                key: marker.id,
                position: _this.flipCoordinates(marker.location),
                onClick: function onClick() {
                  return _this.toggle(marker);
                }
              },
              _this.state.active && _this.state.active.id === marker.id && _react2.default.createElement(Map.InfoView, (0, _extends3.default)({}, _this.props, {
                pin: pin,
                marker: marker,
                toggle: toggle
              }))
            );
          });
        }
        return null;
      }
    });

    _this.state = {
      active: undefined,
      zoom: props.mapZoomLevel
    };
    return _this;
  }

  (0, _createClass3.default)(Map, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _this2 = this;

      var _props = this.props,
          retailers = _props.retailers,
          loadingNearest = _props.loadingNearest;

      if (prevProps.loadingNearest && !loadingNearest) {
        var bounds = new window.google.maps.LatLngBounds();
        retailers.forEach(function (retailer) {
          return bounds.extend(new window.google.maps.LatLng(_this2.flipCoordinates(retailer.location)));
        });
        this.map.fitBounds(bounds);
        this.map.panToBounds(bounds);
      }
    }
  }, {
    key: 'zoomIn',
    value: function zoomIn(zoomLevel) {
      this.setState({ zoom: zoomLevel });
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          defaultCenter = _props2.defaultCenter,
          onMarkerClustererClick = _props2.onMarkerClustererClick,
          pin = _props2.pin,
          mapStyles = _props2.mapStyles;
      var zoom = this.state.zoom;
      var mapMarkers = this.mapMarkers,
          onMapMounted = this.onMapMounted,
          handleZoomChanged = this.handleZoomChanged;

      var defaultClusterProps = {
        onClick: onMarkerClustererClick,
        averageCenter: true,
        enableRetinaIcons: true,
        gridSize: 60,
        maxZoom: 10
      };
      var customClusterProps = pin.mapClusterMarkerUrl ? (0, _assign2.default)({}, defaultClusterProps, {
        styles: [{
          url: pin.mapClusterMarkerUrl,
          height: 26,
          width: 26,
          anchorText: pin.anchorText || undefined,
          textColor: '#FFF'
        }]
      }) : defaultClusterProps;

      return _react2.default.createElement(
        _reactGoogleMaps.GoogleMap,
        {
          zoom: zoom,
          center: defaultCenter,
          ref: onMapMounted,
          onZoomChanged: handleZoomChanged,
          options: { styles: mapStyles || null }
        },
        zoom < ZOOM_THRESHOLD ? _react2.default.createElement(
          _MarkerClusterer.MarkerClusterer,
          customClusterProps,
          mapMarkers()
        ) : _react2.default.createElement(
          'div',
          null,
          mapMarkers()
        )
      );
    }
  }]);
  return Map;
}(_react.Component);

// This loads the Google Maps API dynamically and creates a HOC for the react-google-maps library


Object.defineProperty(Map, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    retailers: []
  }
});
Object.defineProperty(Map, 'InfoView', {
  enumerable: true,
  writable: true,
  value: function value(_ref2) {
    var marker = _ref2.marker,
        toggle = _ref2.toggle,
        translations = _ref2.translations,
        pin = _ref2.pin,
        viewStockAction = _ref2.viewStockAction,
        globalStyling = _ref2.globalStyling,
        infoWindowButtonType = _ref2.infoWindowButtonType,
        retailerFontStyles = _ref2.retailerFontStyles,
        buttonStyleOverride = _ref2.buttonStyleOverride;
    return _react2.default.createElement(
      _reactGoogleMaps.InfoWindow,
      { onCloseClick: function onCloseClick() {
          return toggle(marker);
        } },
      _react2.default.createElement(RetailerInfo, {
        retailer: marker,
        translations: translations,
        pin: pin,
        viewStockAction: viewStockAction,
        buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle,
        buttonType: infoWindowButtonType,
        direction: globalStyling.direction,
        fontStyles: retailerFontStyles,
        buttonStyleOverride: buttonStyleOverride
      })
    );
  }
});
var MapWithAPI = (0, _reactGoogleMaps.withScriptjs)((0, _reactGoogleMaps.withGoogleMap)(Map));

// This will dynamically load the Google Map js API with this component
var MapWithControlsContainer = function MapWithControlsContainer(props) {
  return _react2.default.createElement(
    'div',
    { id: 'retailer-map-wrapper' },
    _react2.default.createElement(
      TitleSearchWrapper,
      null,
      _react2.default.createElement(
        _Titles.Header,
        { style: { marginTop: '80px' } },
        props.translations.title
      ),
      _react2.default.createElement(
        _Titles.Subtitle,
        null,
        props.translations.subtitle,
        ' '
      ),
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n        font-family: GenesisSansTextGlobal-Regular;\n        font-size: 16px;\n\n        margin: 40px 20px 0 20px;\n        text-align: center;\n        color: #ffffff;\n      ';
          }
        },
        props.translations.paragraph
      ),
      _react2.default.createElement(_GenesisInput.GenesisInput, {
        placeholder: props.translations.placeholder,
        buttonLabel: props.translations.search,
        onClick: function onClick(location) {
          if (props.retailers.length >= 3) {
            props.returnPlace(location);
          }
        }
      })
    ),
    _react2.default.createElement(MapWithAPI, {
      googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + props.mapKey + '&libraries=geometry,drawing,places',
      loadingElement: _react2.default.createElement('div', { style: { height: '100%' } }),
      containerElement: _react2.default.createElement('div', { style: { height: '600px' } }),
      mapElement: _react2.default.createElement('div', { style: { height: '100%' } }),
      translations: {
        phone: props.translations.phone,
        distanceAway: props.translations.distanceAway,
        viewStock: props.translations.viewStock
      },
      defaultCenter: props.defaultCenter,
      centreLocation: props.centreLocation,
      retailers: props.retailers,
      viewStockAction: props.viewStockAction,
      zoomChangedAction: props.zoomChangedAction,
      pin: props.pin,
      mapZoomLevel: props.mapZoomLevel,
      globalStyling: props.globalStyling,
      infoWindowButtonType: props.infoWindowButtonType,
      flipCoordinates: props.flipCoordinates,
      loadingNearest: props.loadingNearest,
      mapStyles: props.mapStyles,
      retailerFontStyles: props.config.retailerFontStyles
    })
  );
};

exports.default = MapWithControlsContainer;