'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    height: 50%;\n  '], ['\n    width: 100%;\n    height: 50%;\n  ']);

exports.default = VdpGalleryRr;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _VdpSlideshow = require('./VdpSlideshow');

var _VdpSlideshow2 = _interopRequireDefault(_VdpSlideshow);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGalleryContainer'
}).withConfig({
  displayName: 'VdpGalleryRr__Container',
  componentId: 'sc-s9amiy-0'
})(['width:60%;', ';'], _theme2.default.max.large(_templateObject));

var MediaWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryRr__MediaWrapper',
  componentId: 'sc-s9amiy-1'
})(['width:100%;']);

function VdpGalleryRr(props) {
  var vehicleImages = props.vehicleImages,
      placeholdingImage = props.placeholdingImage,
      vehicleInfo = props.vehicleInfo,
      imageCarousel = props.imageCarousel,
      data360 = props.data360,
      get360Info = props.get360Info,
      globalStyling = props.globalStyling,
      translations = props.translations;

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      MediaWrapper,
      { 'data-cy': 'vehicle-gallery' },
      _react2.default.createElement(_VdpSlideshow2.default, {
        config: vehicleImages,
        placeholdingImage: placeholdingImage,
        views: vehicleInfo.views,
        vehicleInfo: vehicleInfo,
        rotateGallery: true,
        isLooping: false,
        imageCarousel: imageCarousel,
        data360: data360,
        get360Info: get360Info,
        globalStyling: globalStyling,
        translations: translations
      })
    )
  );
}