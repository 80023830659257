'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TextWithImageGrid = require('./TextWithImageGrid');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_TextWithImageGrid).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'Text with image grid';
var id = exports.id = 'TextWithImageGrid';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Text with image grid';
var searchable = exports.searchable = true;