'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.icon = exports.description = exports.name = exports.availableToAdministration = exports.linked = exports.id = exports.config = exports.defaultConfig = undefined;

var _defaultConfig = require('./defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});

var _config = require('./config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _Icon = require('./Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var id = exports.id = 'Filters';
var linked = exports.linked = true;
var availableToAdministration = exports.availableToAdministration = false;
var name = exports.name = 'Filters';

var description = exports.description = 'Filters Linked Module';
var icon = exports.icon = _Icon2.default;