'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      padding: 15px 26px;\n  '], ['\n      padding: 15px 26px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _useOutsideClick = require('../../../hooks/useOutsideClick');

var _useOutsideClick2 = _interopRequireDefault(_useOutsideClick);

var _FieldValidationError = require('./FieldValidationError');

var _FieldValidationError2 = _interopRequireDefault(_FieldValidationError);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'Dropdown__Icon',
  componentId: 'sc-1rmg3kq-0'
})(['transition:all 0.3s;', ''], function (_ref) {
  var open = _ref.open;
  return open && 'transform: rotate(180deg); top: 2px; position: relative;';
});

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'Dropdown__Label',
  componentId: 'sc-1rmg3kq-1'
})(['background:transparent;border:none;color:#fff;font-family:\'RivieraNights-light\';font-size:16px;line-height:18px;letter-spacing:0.57px;']);

var LabelContainer = _styledComponents2.default.label.withConfig({
  displayName: 'Dropdown__LabelContainer',
  componentId: 'sc-1rmg3kq-2'
})(['align-items:center;background:#1b0d2e;border-bottom:', ';cursor:pointer;display:flex;flex:1 0 0;justify-content:space-between;padding:15px 10px;', ''], function (_ref2) {
  var error = _ref2.error;
  return error ? '2px solid #F94564' : '2px solid #727281';
}, _RollsRoyce2.default.min.medium(_templateObject));

var List = _styledComponents2.default.div.withConfig({
  displayName: 'Dropdown__List',
  componentId: 'sc-1rmg3kq-3'
})(['align-items:flex-start;display:flex;flex-direction:column;position:absolute;z-index:1;width:100%;margin-top:53px;max-height:300px;overflow-y:scroll;']);

var ListItem = _styledComponents2.default.button.withConfig({
  displayName: 'Dropdown__ListItem',
  componentId: 'sc-1rmg3kq-4'
})(['align-items:flex-start;background:#110619;border:none;cursor:pointer;display:flex;flex-direction:column;gap:10px;height:49px;justify-content:justify-content;padding:0px 20px;width:100%;']);

var ItemLabel = _styledComponents2.default.label.withConfig({
  displayName: 'Dropdown__ItemLabel',
  componentId: 'sc-1rmg3kq-5'
})(['color:#9b9b9b;cursor:pointer;font-family:\'RivieraNights-light\';font-size:16px;line-height:51px;margin:auto;text-align:left;width:100%;&:hover{color:#fff;transition:color 0.2s linear;}']);

var Dropdown = function Dropdown(_ref3) {
  var options = _ref3.options,
      translations = _ref3.translations,
      _onClick = _ref3.onClick,
      value = _ref3.value,
      removeMargin = _ref3.removeMargin,
      _ref3$orientation = _ref3.orientation,
      orientation = _ref3$orientation === undefined ? 'vertical' : _ref3$orientation,
      styleOverride = _ref3.styleOverride,
      pleaseSelect = _ref3.pleaseSelect,
      error = _ref3.error;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var ref = (0, _react.createRef)();

  var isClickOutside = (0, _useOutsideClick2.default)(ref);

  (0, _react.useEffect)(function () {
    if (open && isClickOutside) {
      setOpen(false);
    }
  }, [isClickOutside, open]);

  return _react2.default.createElement(
    _FormField2.default,
    {
      removeMargin: removeMargin,
      orientation: orientation,
      styleOverride: styleOverride
    },
    _react2.default.createElement(
      LabelContainer,
      { onClick: function onClick() {
          return setOpen(!open);
        }, error: error },
      _react2.default.createElement(
        Label,
        null,
        value === '' ? pleaseSelect : translations[value]
      ),
      _react2.default.createElement(Icon, {
        open: open,
        src: 'https://res.cloudinary.com/motortrak/v1715878634/locator/rolls-royce/global/icons/drop-down-white.svg'
      })
    ),
    open && _react2.default.createElement(
      List,
      null,
      options.map(function (value) {
        return _react2.default.createElement(
          ListItem,
          {
            onClick: function onClick() {
              _onClick(value);
              setOpen(!open);
            },
            key: value
          },
          _react2.default.createElement(
            ItemLabel,
            null,
            translations[value]
          )
        );
      })
    ),
    error && _react2.default.createElement(
      _FieldValidationError2.default,
      null,
      error
    )
  );
};

exports.default = Dropdown;