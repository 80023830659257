'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ControlsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Controls__ControlsWrapper',
  componentId: 'sc-1r81iq7-0'
})(['height:100px;width:100%;border:1px solid #dddddd;box-sizing:border-box;display:flex;align-items:center;padding:20px;']);


var ImageViewToggle = _styledComponents2.default.button.withConfig({
  displayName: 'Controls__ImageViewToggle',
  componentId: 'sc-1r81iq7-1'
})(['width:25px;height:25px;outline:none;background:none;border:none;padding:0;cursor:pointer;']);

var ImageViewIcon = _styledComponents2.default.img.withConfig({
  displayName: 'Controls__ImageViewIcon',
  componentId: 'sc-1r81iq7-2'
})(['width:100%;']);

var ToggleViewWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Controls__ToggleViewWrapper',
  componentId: 'sc-1r81iq7-3'
})(['width:100%;padding-left:-35px;display:flex;align-items:center;justify-content:center;']);

exports.default = function (_ref) {
  var imagesIcon = _ref.imagesIcon,
      toggleImageView = _ref.toggleImageView,
      setPlayerView = _ref.setPlayerView,
      globalStyling = _ref.globalStyling,
      currentView = _ref.currentView,
      media = _ref.media,
      translations = _ref.translations;
  var _globalStyling$colour = globalStyling.colours,
      primaryForegroundColour = _globalStyling$colour.primaryForegroundColour,
      primaryBackgroundColour = _globalStyling$colour.primaryBackgroundColour;

  var toggleStylingOn = '\n    border: 1px solid;\n    border-color: ' + (primaryBackgroundColour && primaryBackgroundColour.value) + ';\n    background-color: ' + (primaryBackgroundColour && primaryBackgroundColour.value) + ';\n    color: ' + (primaryForegroundColour && primaryForegroundColour.value) + ';\n    &:hover {\n      border-color: ' + (primaryBackgroundColour && primaryBackgroundColour.value) + ';\n      background-color: ' + (primaryBackgroundColour && primaryBackgroundColour.value) + ';\n      color: ' + (primaryForegroundColour && primaryForegroundColour.value) + ';\n    }\n  ';
  var toggleStylingOff = '\n    border: 1px solid;\n    border-color: ' + (primaryBackgroundColour && primaryBackgroundColour.value) + ';\n    background-color: ' + (primaryForegroundColour && primaryForegroundColour.value) + ';\n    color: ' + (primaryBackgroundColour && primaryBackgroundColour.value) + ';\n    &:hover {\n      ' + toggleStylingOn + ';\n    }\n  ';

  return _react2.default.createElement(
    ControlsWrapper,
    null,
    _react2.default.createElement(
      ImageViewToggle,
      { onClick: toggleImageView },
      _react2.default.createElement(ImageViewIcon, { src: imagesIcon })
    ),
    _react2.default.createElement(
      ToggleViewWrapper,
      null,
      media.exterior && media.interior && _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(_Global.Button, {
          text: translations.interior,
          buttonStyle: 'ButtonStyle1',
          styleOverride: function styleOverride() {
            return currentView === 'interior' ? toggleStylingOn : toggleStylingOff;
          },
          onClick: function onClick() {
            setPlayerView('interior');
          }
        }),
        _react2.default.createElement(_Global.Button, {
          text: translations.exterior,
          buttonStyle: 'ButtonStyle1',
          styleOverride: function styleOverride() {
            return currentView === 'exterior' ? toggleStylingOn : toggleStylingOff;
          },
          onClick: function onClick() {
            setPlayerView('exterior');
          }
        })
      )
    )
  );
};