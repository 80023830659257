'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     margin: 20px 0 30px;\n     font-size: 16px;\n  '], ['\n     margin: 20px 0 30px;\n     font-size: 16px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _VehicleCard = require('../VehicleCard');

var _SelectBar = require('../SelectBar');

var _SelectBar2 = _interopRequireDefault(_SelectBar);

var _NoResults = require('../NoResults');

var _NoResults2 = _interopRequireDefault(_NoResults);

var _reactRedux = require('react-redux');

var _sort = require('../../../helpers/sort');

var _vehicle = require('../../../helpers/vehicle');

var _router = require('../../../actions/router');

var _types = require('./types');

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _AppShared = require('../../../types/AppShared');

var _shortlist = require('../../../shared/shortlist');

var _isEmpty = require('ramda/src/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BackButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Index__BackButtonContainer',
  componentId: 'sc-1ghqpv9-0'
})(['margin-right:80px;min-width:270px;']);

var Body = _styledComponents2.default.div.withConfig({
  displayName: 'Index__Body',
  componentId: 'sc-1ghqpv9-1'
})(['align-items:baseline;display:flex;width:100%;']);

var EmptyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Index__EmptyContainer',
  componentId: 'sc-1ghqpv9-2'
})(['align-items:center;display:flex;flex-direction:column;justify-content:center;padding:72px 0 280px 0;']);

var ListContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Index__ListContainer',
  componentId: 'sc-1ghqpv9-3'
})(['margin-bottom:40px;width:100%;']);

var ListHeader = _styledComponents2.default.div.withConfig({
  displayName: 'Index__ListHeader',
  componentId: 'sc-1ghqpv9-4'
})(['color:#fff;flex-grow:1;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:14px;font-weight:normal;margin:20px 0;text-transform:uppercase;', ''], _Genesis2.default.min.medium(_templateObject));

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Index__Wrapper',
  componentId: 'sc-1ghqpv9-5'
})(['display:flex;flex-direction:column;margin-bottom:40px;']);

var Shortlist = function Shortlist(_ref) {
  var config = _ref.config,
      translations = _ref.translations,
      vehicles = _ref.vehicles,
      locale = _ref.locale,
      searchSort = _ref.searchSort,
      shared = _ref.shared;

  var dispatch = (0, _reactRedux.useDispatch)();
  var mobile = (0, _useCheckIsMobileScreen2.default)();
  var sortedList = (0, _react.useMemo)(function () {
    return (0, _sort.sortVehicles)(vehicles || [], searchSort.value);
  }, [vehicles, _sort.sortVehicles, searchSort]);

  var onMoreInfoClick = (0, _react.useCallback)(function (_, vehicle) {
    var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, locale);
    dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
  }, [_vehicle.vanityUrlFormatter, config, locale]);

  var onEnquireClick = function onEnquireClick(_, vehicle) {
    dispatch(_router.actions.navigate('/enquiry/' + vehicle.id));
  };

  var noResults = !sortedList.length;

  (0, _react.useEffect)(function () {
    if (shared.sessionPreferences.currency && (0, _keys2.default)(shared.currencyConversion.exchangeRates).length > 0 && shared.currencyConversion.exchangeRates[shared.sessionPreferences.currency]) {
      dispatch(_shortlist.actions.updateVehiclePrices({
        currency: shared.sessionPreferences.currency,
        exchangeRates: shared.currencyConversion.exchangeRates
      }));
    }
  }, [shared.sessionPreferences.currency, shared.currencyConversion.exchangeRates]);

  (0, _react.useEffect)(function () {
    // if exchange rates have been deleted, reset the prices
    if (shared.sessionPreferences.currency === '' && (0, _isEmpty2.default)(shared.currencyConversion.exchangeRates)) {
      dispatch(_shortlist.actions.getVehicles());
    }
  }, [shared.currencyConversion.exchangeRates]);

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      Body,
      null,
      !mobile && _react2.default.createElement(BackButtonContainer, null),
      !noResults && _react2.default.createElement(
        ListContainer,
        null,
        _react2.default.createElement(
          ListHeader,
          null,
          translations.header,
          !config.hideSelectBar && _react2.default.createElement(_SelectBar2.default, {
            searchSort: shared.sessionPreferences.searchSort,
            currencies: config.currencies,
            currencyValue: shared.sessionPreferences.currency,
            translations: translations
          })
        ),
        sortedList.map(function (vehicle) {
          return _react2.default.createElement(_VehicleCard.VehicleItem, {
            config: config,
            key: vehicle.id,
            translations: translations,
            onMoreInfoClick: onMoreInfoClick,
            locale: locale,
            vehicle: vehicle,
            onEnquireClick: onEnquireClick,
            shared: shared
          });
        })
      )
    ),
    noResults && _react2.default.createElement(
      EmptyContainer,
      null,
      _react2.default.createElement(_NoResults2.default, {
        text: translations.noVehicleText,
        iconUrl: config.noVehicleIconUrl
      })
    )
  );
};

exports.default = Shortlist;