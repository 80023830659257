'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 15px;\n  '], ['\n    padding: 0 15px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin-left: 5px;\n  '], ['\n    margin-left: 5px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding-left: 5px;\n  '], ['\n    padding-left: 5px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

exports.default = Pagination;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _range = require('ramda/src/range');

var _range2 = _interopRequireDefault(_range);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Pagination__Container',
  componentId: 'sc-binjzw-0'
})(['width:100%;height:auto;display:flex;margin:50px auto;align-items:center;justify-content:center;']);

var PaginationItem = _styledComponents2.default.div.withConfig({
  displayName: 'Pagination__PaginationItem',
  componentId: 'sc-binjzw-1'
})(['color:#444444;font-size:16px;min-height:45px;padding:0 40px;display:flex;align-items:center;border-right:1px solid #dedede;&:last-child{border:none;}', ';'], _theme2.default.max.large(_templateObject));

var PageTotal = _styledComponents2.default.div.withConfig({
  displayName: 'Pagination__PageTotal',
  componentId: 'sc-binjzw-2'
})(['font-size:16px;font-weight:600;margin-left:20px;', ';'], _theme2.default.max.medium(_templateObject2));

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'Pagination__Select',
  componentId: 'sc-binjzw-3'
})(['width:100%;height:45px;font-size:14px;line-height:40px;box-sizing:border-box;border:1px solid #d8d8d8;padding-left:15px;color:#7e7e7e;background-color:#fff;&:disabled{border:none;background-color:#d6d6d6;}&:focus{border:1px solid #999999;color:#444444;outline:none;}', ';'], _theme2.default.max.medium(_templateObject3));

var PaginationArrow = _styledComponents2.default.img.withConfig({
  displayName: 'Pagination__PaginationArrow',
  componentId: 'sc-binjzw-4'
})(['', ';'], _theme2.default.min.medium(_templateObject4));

var renderOptions = function renderOptions(pageTotal) {
  return (0, _range2.default)(1, pageTotal + 1).map(function (p) {
    return _react2.default.createElement(
      'option',
      { key: p, value: p },
      p
    );
  });
};

var directions = {
  next: 'next',
  prev: 'prev'
};
var onDirectionClick = function onDirectionClick(direction, currentPage, onChangeFn) {
  if (direction === directions.next) {
    return function () {
      return onChangeFn(currentPage + 1);
    };
  }
  if (direction === directions.prev) {
    return function () {
      return onChangeFn(currentPage - 1);
    };
  }
  return function () {};
};

function Pagination(props) {
  var currentPage = props.currentPage,
      totalResults = props.totalResults,
      pageSize = props.pageSize,
      onPageNumberChange = props.onPageNumberChange,
      onShowAllResultsClick = props.onShowAllResultsClick,
      translations = props.translations;

  var pageTotal = Math.ceil(totalResults / pageSize);
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      PaginationItem,
      null,
      _react2.default.createElement(_Global.Button, {
        styleOverride: function styleOverride() {
          return '\n              text-transform: none;\n              border: none;\n              background-color: #ffffff;\n              color: #444444;\n                &:hover {\n                  border: none;\n                  background-color: #ffffff;\n                  color: #444444;\n                }\n              ';
        },
        mobileStyleOverride: function mobileStyleOverride() {
          return '\n            display: none;\n          ';
        },
        'data-cy': 'pagination-button-previous',
        onClick: onDirectionClick(directions.prev, currentPage, onPageNumberChange),
        disabled: currentPage === 1,
        text: translations.searchResultsPreviousLabel,
        buttonStyle: 'ButtonStyleBTT'
      }),
      _react2.default.createElement(PaginationArrow, {
        src: 'https://res.cloudinary.com/motortrak/image/upload/v1564589170/cms/global/icons/paging-left.svg',
        onClick: function onClick() {
          return currentPage !== 1 && onDirectionClick(directions.prev, currentPage, onPageNumberChange);
        }
      })
    ),
    _react2.default.createElement(
      PaginationItem,
      null,
      _react2.default.createElement(
        Select,
        {
          'data-cy': 'pagination-dropdown',
          value: currentPage,
          onChange: function onChange(e) {
            return onPageNumberChange(parseInt(e.target.value, 10));
          }
        },
        renderOptions(pageTotal)
      ),
      _react2.default.createElement(
        PageTotal,
        null,
        '/'
      ),
      _react2.default.createElement(
        PageTotal,
        null,
        pageTotal
      )
    ),
    _react2.default.createElement(
      PaginationItem,
      null,
      _react2.default.createElement(_Global.Button, {
        styleOverride: function styleOverride() {
          return '\n              text-transform: unset;\n              border: none;\n              background-color: #ffffff;\n              color: #444444;\n                &:hover {\n                  border: none;\n                  background-color: #ffffff;\n                  color: #444444;\n                }\n              ';
        },
        mobileStyleOverride: function mobileStyleOverride() {
          return '\n            display: none;\n          ';
        },
        'data-cy': 'pagination-button-next',
        onClick: onDirectionClick(directions.next, currentPage, onPageNumberChange),
        disabled: currentPage === pageTotal,
        text: translations.searchResultsNextLabel,
        buttonStyle: 'ButtonStyleBTT'
      }),
      _react2.default.createElement(PaginationArrow, {
        src: 'https://res.cloudinary.com/motortrak/image/upload/v1564589170/cms/global/icons/paging-right.svg',
        onClick: function onClick() {
          return currentPage !== pageTotal && onDirectionClick(directions.next, currentPage, onPageNumberChange);
        }
      })
    ),
    _react2.default.createElement(
      PaginationItem,
      null,
      _react2.default.createElement(_Global.Button, {
        styleOverride: function styleOverride() {
          return '\n                text-transform: unset;\n                border: none;\n                background-color: #ffffff;\n                color: #444444;\n                &:hover {\n                  border: none;\n                  background-color: #ffffff;\n                  color: #444444;\n                }\n              ';
        },
        'data-cy': 'pagination-button-all',
        onClick: function onClick() {
          return onShowAllResultsClick();
        },
        text: translations.searchResultsShowAllLabel,
        buttonStyle: 'ButtonStyleBTT'
      })
    )
  );
}