'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n  '], ['\n    ', ';\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['flex-direction: row;'], ['flex-direction: row;']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    flex: 0 1 0%;\n  '], ['\n    flex: 0 1 0%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n  '], ['\n    flex-direction: row;\n  ']);

exports.default = BrickPanel;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _splitEvery = require('ramda/src/splitEvery');

var _splitEvery2 = _interopRequireDefault(_splitEvery);

var _router = require('../../actions/router');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../components/Global');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'Brick__Image',
  componentId: 'sc-3i68bp-0'
})(['width:20%;min-width:2.5em;height:2.5em;', ';', ';'], function (_ref) {
  var styles = _ref.styles;
  return '' + (styles.direction === 'rtl' ? 'margin-left: 10px;' : 'margin-right: 10px;');
}, _theme2.default.max.medium(_templateObject, function (_ref2) {
  var styles = _ref2.styles;
  return '' + (styles.direction === 'rtl' ? 'margin-left: 20px;' : 'margin-right: 20px;');
}));

var StyledImage = (0, _styledComponents2.default)(Image).withConfig({
  displayName: 'Brick__StyledImage',
  componentId: 'sc-3i68bp-1'
})(['', ';', ';', ';', ';'], function (_ref3) {
  var styles = _ref3.styles;
  return '' + (styles.brickItemIcon && 'width:   ' + styles.brickItemIcon.width.value);
}, function (_ref4) {
  var styles = _ref4.styles;
  return '' + (styles.brickItemIcon && 'height:   ' + styles.brickItemIcon.height.value);
}, function (_ref5) {
  var styles = _ref5.styles;
  return '' + (styles.brickItemIcon && 'min-width:   ' + styles.brickItemIcon.minWidth);
}, function (_ref6) {
  var styles = _ref6.styles;
  return '' + (styles.brickItemIcon && styles.brickItemIcon.MarginRight && (styles.direction === 'rtl' ? 'margin-left:' : 'margin-right:') + '   ' + styles.brickItemIcon.MarginRight.value);
});

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Brick__Wrapper',
  componentId: 'sc-3i68bp-2'
})(['display:flex;flex-direction:column;height:auto;width:100%;box-sizing:border-box;flex-wrap:wrap;justify-content:center;', ';'], function (_ref7) {
  var itemLength = _ref7.itemLength;
  return itemLength === 4 ? _theme2.default.min.large(_templateObject2) : _theme2.default.min.medium(_templateObject2);
});

var BrickWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Brick__BrickWrapper',
  componentId: 'sc-3i68bp-3'
})(['flex:0 1 260px;display:flex;padding:24px 20px;', ' ', ';'], function (_ref8) {
  var brickLink = _ref8.brickLink;
  return brickLink && 'cursor: pointer;';
}, _theme2.default.max.medium(_templateObject3));

var BrickContent = _styledComponents2.default.div.withConfig({
  displayName: 'Brick__BrickContent',
  componentId: 'sc-3i68bp-4'
})(['box-sizing:border-box;display:flex;flex-direction:row;flex:1 1 260px;align-items:center;']);

var BrickText = _styledComponents2.default.div.withConfig({
  displayName: 'Brick__BrickText',
  componentId: 'sc-3i68bp-5'
})(['width:100%;display:flex;flex-direction:column;']);

var StackedItemWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Brick__StackedItemWrapper',
  componentId: 'sc-3i68bp-6'
})(['flex:1 1 0%;display:flex;flex-direction:column;', ';'], _theme2.default.min.medium(_templateObject4));

var Brick = function Brick(_ref9) {
  var config = _ref9.config,
      brick = _ref9.brick,
      direction = _ref9.direction,
      dispatch = _ref9.dispatch;
  return _react2.default.createElement(
    BrickWrapper,
    {
      onClick: brick.link ? function () {
        return dispatch(_router.actions.navigate(brick.link));
      } : function () {},
      brickLink: brick.link
    },
    _react2.default.createElement(
      BrickContent,
      null,
      _react2.default.createElement(StyledImage, {
        src: brick.image && brick.image.value,
        alt: brick.image && brick.image.label,
        styles: (0, _extends3.default)({}, config, { direction: direction })
      }),
      _react2.default.createElement(
        BrickText,
        null,
        _react2.default.createElement(
          _Global.HeadingOne,
          {
            styleOverride: function styleOverride() {
              return '\n          ' + (0, _Global.fontStyleOverride)(config.brickItemHeaderFont) + ';\n          margin: 0;\n          ';
            }
          },
          brick.translations.headlineText
        ),
        brick.translations.descriptionText && _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n            ' + (0, _Global.fontStyleOverride)(config.brickItemDescriptionFont) + ';\n            margin: 0;\n            ';
            }
          },
          brick.translations.descriptionText
        )
      )
    )
  );
};

var StackedItems = function StackedItems(_ref10) {
  var config = _ref10.config,
      globalStyling = _ref10.globalStyling,
      dispatch = _ref10.dispatch;

  if (config.items.length === 4) {
    return _react2.default.createElement(
      Wrapper,
      { itemLength: config.items.length },
      (0, _splitEvery2.default)(2, config.items).map(function (brickPair, idx) {
        return _react2.default.createElement(
          StackedItemWrapper,
          { key: 'brickPair-' + (idx + 1) },
          brickPair.map(function (brick, index) {
            return _react2.default.createElement(Brick, {
              key: 'brickItem-' + (index + 1),
              brick: brick,
              config: config,
              direction: globalStyling.direction,
              dispatch: dispatch
            });
          })
        );
      })
    );
  }
  return _react2.default.createElement(
    Wrapper,
    { itemLength: config.items.length },
    config.items.map(function (brick, index) {
      return _react2.default.createElement(Brick, {
        key: 'brickItem-' + (index + 1),
        brick: brick,
        config: config,
        direction: globalStyling.direction,
        dispatch: dispatch
      });
    })
  );
};

function BrickPanel(props) {
  var config = props.config,
      globalStyling = props.globalStyling;

  var dispatch = (0, _reactRedux.useDispatch)();
  return _react2.default.createElement(
    _ContentWrapper2.default,
    {
      colour: config.brickBackgroundColour && config.brickBackgroundColour,
      contentWidth: globalStyling.contentWidth
    },
    _react2.default.createElement(StackedItems, (0, _extends3.default)({}, props, { dispatch: dispatch }))
  );
}