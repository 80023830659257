'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../Global');

var _StyledIcon = require('../../StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerLocation__Container',
  componentId: 'sc-s5897p-0'
})(['display:flex;flex-direction:row;align-items:center;']);

var DesktopOnly = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerLocation__DesktopOnly',
  componentId: 'sc-s5897p-1'
})(['', ';'], _theme2.default.max.small(_templateObject));

var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerLocation__MobileOnly',
  componentId: 'sc-s5897p-2'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject2));

var VdpRetailerLocation = function VdpRetailerLocation(props) {
  var retailerName = props.retailerName,
      globalStyling = props.globalStyling,
      config = props.config;


  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      DesktopOnly,
      null,
      _react2.default.createElement(_StyledIcon2.default, { link: globalStyling.icons.location })
    ),
    _react2.default.createElement(
      MobileOnly,
      null,
      _react2.default.createElement(_StyledIcon2.default, { link: globalStyling.icons.location, width: 14, height: 14 })
    ),
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        styleOverride: function styleOverride() {
          return '\n            ' + (0, _Global.fontStyleOverride)(config.retailerNameFont) + '\n            padding-left: 10px\n        ';
        },
        maxMediumMobileStyleOverride: function maxMediumMobileStyleOverride() {
          return '\n           ' + (0, _Global.fontStyleOverride)(config.retailerNameFont) + '\n            padding-left: 5px\n            font-size: 10px;\n        ';
        }
      },
      retailerName
    )
  );
};

exports.default = _react2.default.memo(VdpRetailerLocation);