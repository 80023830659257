'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.icon = exports.description = exports.availableToAdministration = exports.id = exports.name = exports.defaultConfig = exports.config = exports.component = undefined;

var _Brick = require('./Brick');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Brick).default;
  }
});

var _config = require('./config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _defaultConfig = require('./defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});
exports.template = template;

var _Icon = require('./Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'Brick';
var id = exports.id = 'Brick';
var availableToAdministration = exports.availableToAdministration = true;

var description = exports.description = 'Brick Module';
var icon = exports.icon = _Icon2.default;

function template() {
  return {
    items: {
      translations: {
        headlineText: 'Title Placeholder',
        descriptionText: 'Description Placeholder'
      },
      image: {
        label: 'default_brick_icon',
        value: 'https://res.cloudinary.com/motortrak/image/upload/v1538484990/Placeholder%20Images/default-brick-icon.svg'
      }
    }
  };
}