'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

exports.default = ShortlistedVehicles;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FinanceContainer = require('../containers/FinanceContainer');

var _FinanceContainer2 = _interopRequireDefault(_FinanceContainer);

var _VehicleShortlist = require('./VehicleShortlist');

var _VehicleShortlist2 = _interopRequireDefault(_VehicleShortlist);

var _RepresentativeExample = require('./RepresentativeExample');

var _RepresentativeExample2 = _interopRequireDefault(_RepresentativeExample);

var _SortMenuBar = require('./SearchResults/SortMenuBar');

var _SortMenuBar2 = _interopRequireDefault(_SortMenuBar);

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ShortlistedVehicles__Container',
  componentId: 'sc-65a480-0'
})(['margin:0 2.5%;width:95%;']);


var SortMenuBarStyled = _styledComponents2.default.div.withConfig({
  displayName: 'ShortlistedVehicles__SortMenuBarStyled',
  componentId: 'sc-65a480-1'
})(['width:100%;display:flex;justify-content:flex-end;']);

var Line = _styledComponents2.default.hr.withConfig({
  displayName: 'ShortlistedVehicles__Line',
  componentId: 'sc-65a480-2'
})(['display:', ';background-color:#dedede;border-bottom:none;'], function (_ref) {
  var visible = _ref.visible;
  return visible ? 'none' : 'flex';
});

var Footer = _styledComponents2.default.p.withConfig({
  displayName: 'ShortlistedVehicles__Footer',
  componentId: 'sc-65a480-3'
})(['text-align:center;color:#7e7e7e;font-size:13px;margin:auto 25%;margin-top:27px;']);

function ShortlistedVehicles(_ref2) {
  var translations = _ref2.translations,
      vehicles = _ref2.vehicles,
      onCloseClick = _ref2.onCloseClick,
      onHeartClick = _ref2.onHeartClick,
      onCompareClick = _ref2.onCompareClick,
      representativeExample = _ref2.representativeExample,
      onMoreInfoClick = _ref2.onMoreInfoClick,
      getCompareIconColour = _ref2.getCompareIconColour,
      getShortListedIconColour = _ref2.getShortListedIconColour,
      compareVehicle = _ref2.compareVehicle,
      shortlistVehicle = _ref2.shortlistVehicle,
      sortOptions = _ref2.sortOptions,
      sortValue = _ref2.sortValue,
      sortValueDictionary = _ref2.sortValueDictionary,
      onSortChange = _ref2.onSortChange,
      selectedView = _ref2.selectedView,
      onViewChange = _ref2.onViewChange,
      placeholdingImage = _ref2.placeholdingImage,
      globalStyling = _ref2.globalStyling,
      visibility = _ref2.visibility,
      locale = _ref2.locale,
      headingFont = _ref2.headingFont,
      subHeadingFont = _ref2.subHeadingFont,
      headingAlignment = _ref2.headingAlignment,
      preview = _ref2.preview,
      headingBottomLineDisable = _ref2.headingBottomLineDisable,
      financeHeadingFont = _ref2.financeHeadingFont,
      vehicleSpecContainer = _ref2.vehicleSpecContainer,
      financeWidthOverride = _ref2.financeWidthOverride,
      icon360 = _ref2.icon360,
      iconYoutubeVideo = _ref2.iconYoutubeVideo;

  var vehiclesNoDistance = vehicles.map(function (v) {
    var distance = v.distance,
        rest = (0, _objectWithoutProperties3.default)(v, ['distance']);

    return rest;
  });

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        styleOverride: function styleOverride() {
          return '\n        ' + (0, _Global.fontStyleOverride)(headingFont) + '\n          margin-top: 34px;\n          margin-bottom: 10px;\n          text-align: ' + (headingAlignment || 'center') + ';\n        ';
        }
      },
      translations.shortlistHeader
    ),
    translations.shortlistSubheader && _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n          ' + (0, _Global.fontStyleOverride)(subHeadingFont) + '\n            text-align: ' + (headingAlignment || 'center') + ';\n            margin-bottom: 35px;\n          ';
        }
      },
      vehicles.length,
      ' ',
      translations.shortlistSubheader
    ),
    _react2.default.createElement(Line, { visible: headingBottomLineDisable }),
    visibility.showFinanceExample && _react2.default.createElement(_FinanceContainer2.default, {
      component: function component(componentProps) {
        return _react2.default.createElement(_RepresentativeExample2.default, (0, _extends3.default)({}, componentProps, {
          representativeExample: representativeExample,
          preview: preview
        }));
      }
    }),
    visibility.showFinanceExample && _react2.default.createElement(Line, null),
    _react2.default.createElement(
      SortMenuBarStyled,
      null,
      _react2.default.createElement(_SortMenuBar2.default, {
        sortOptions: sortOptions,
        sortValue: sortValue,
        onSortChange: onSortChange,
        selectedView: selectedView,
        onViewChange: onViewChange,
        translations: translations,
        showAge: visibility.sorting.showSortByAge,
        showMileage: visibility.sorting.showSortByMileage
      })
    ),
    _react2.default.createElement(_VehicleShortlist2.default, {
      translations: translations,
      vehicles: vehiclesNoDistance,
      onCloseClick: onCloseClick,
      onHeartClick: onHeartClick,
      onCompareClick: onCompareClick,
      onMoreInfoClick: onMoreInfoClick,
      getCompareIconColour: getCompareIconColour,
      getShortListedIconColour: getShortListedIconColour,
      compareVehicle: compareVehicle,
      shortlistVehicle: shortlistVehicle,
      selectedView: selectedView,
      sortValue: sortValue,
      sortValueDictionary: sortValueDictionary,
      placeholdingImage: placeholdingImage,
      globalStyling: globalStyling,
      visibility: visibility,
      locale: locale,
      financeHeadingFont: financeHeadingFont,
      showFinancePopup: true,
      vehicleSpecContainer: vehicleSpecContainer,
      financeWidthOverride: financeWidthOverride,
      icon360: icon360,
      iconYoutubeVideo: iconYoutubeVideo
    }),
    _react2.default.createElement(
      Footer,
      null,
      translations.footer
    )
  );
}