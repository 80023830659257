'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ThinLeft = require('../JaguarIcons/Arrows/ThinLeft');

var _ThinLeft2 = _interopRequireDefault(_ThinLeft);

var _ThinRight = require('../JaguarIcons/Arrows/ThinRight');

var _ThinRight2 = _interopRequireDefault(_ThinRight);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'renderNavigation__ButtonContainer',
  componentId: 'sc-1s423mw-0'
})(['display:flex;align-items:center;justify-content:center;position:absolute;', ';', ';top:45%;width:30px;height:50px;text-align:center;background:', ';opacity:0.7;border-radius:', 'px;cursor:pointer;'], function (_ref) {
  var left = _ref.left;
  return left ? 'left: ' + left + 'px' : '';
}, function (_ref2) {
  var right = _ref2.right;
  return right ? 'right: ' + right + 'px' : '';
}, function (_ref3) {
  var arrowStyles = _ref3.arrowStyles;
  return arrowStyles && arrowStyles.background || '#000000';
}, function (_ref4) {
  var arrowStyles = _ref4.arrowStyles;
  return arrowStyles && arrowStyles.radius || '1';
});

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'renderNavigation__IconWrapper',
  componentId: 'sc-1s423mw-1'
})(['width:16px;height:16px;filter:', ';'], function (_ref5) {
  var arrowStyles = _ref5.arrowStyles;
  return arrowStyles && arrowStyles.filter || 'none';
});

var renderNavigation = function renderNavigation(_ref6) {
  var goToNext = _ref6.goToNext,
      goToPrev = _ref6.goToPrev,
      arrowRight = _ref6.arrowRight,
      arrowLeft = _ref6.arrowLeft,
      arrowStyles = _ref6.arrowStyles,
      leftAlternativeText = _ref6.leftAlternativeText,
      rightAlternativeText = _ref6.rightAlternativeText;
  return [_react2.default.createElement(
    ButtonContainer,
    {
      tabIndex: 0,
      key: '1',
      left: '20',
      onClick: function onClick() {
        return goToPrev();
      },
      arrowStyles: arrowStyles,
      'aria-label': leftAlternativeText,
      role: 'button',
      onKeyDown: function onKeyDown(e) {
        return e.key === 'Enter' && goToPrev();
      }
    },
    arrowLeft ? _react2.default.createElement(IconWrapper, { src: arrowLeft, alt: '', arrowStyles: arrowStyles }) : _react2.default.createElement(_ThinLeft2.default, {
      width: '1.5em',
      height: '1.5em',
      colour: '#FFFFFF',
      'aria-label': leftAlternativeText
    })
  ), _react2.default.createElement(
    ButtonContainer,
    {
      tabIndex: 0,
      key: '2',
      right: '20',
      onClick: function onClick() {
        return goToNext();
      },
      arrowStyles: arrowStyles,
      'aria-label': rightAlternativeText,
      role: 'button',
      onKeyDown: function onKeyDown(e) {
        if (e.key === 'Enter') {
          goToNext();
        }
      }
    },
    arrowRight ? _react2.default.createElement(IconWrapper, { src: arrowRight, alt: '', arrowStyles: arrowStyles }) : _react2.default.createElement(_ThinRight2.default, {
      width: '1.5em',
      height: '1.5em',
      colour: '#FFFFFF',
      'aria-label': rightAlternativeText
    })
  )];
};

exports.default = renderNavigation;