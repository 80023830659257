'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TextAreaField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TextArea = _styledComponents2.default.textarea.withConfig({
  displayName: 'TextAreaField__TextArea',
  componentId: 'sc-77g652-0'
})(['width:100%;min-height:', ';font-size:16px;line-height:20px;box-sizing:border-box;border:1px solid ', ';color:#7e7e7e;background-color:#fff;padding-left:15px;padding-right:15px;&:disabled{border:none;background-color:#d6d6d6;}&:focus{border:1px solid #999999;color:#444444;outline:none;}'], function (props) {
  return props.TextArea ? 'initial' : '110px';
}, function (props) {
  return props.error ? '#9e1b32' : '#d8d8d8';
});

function TextAreaField(_ref) {
  var doubleRow = _ref.doubleRow,
      label = _ref.label,
      required = _ref.required,
      type = _ref.type,
      keyValue = _ref.keyValue,
      value = _ref.value,
      onChange = _ref.onChange,
      disabled = _ref.disabled,
      placeholder = _ref.placeholder,
      error = _ref.error,
      globalStyling = _ref.globalStyling,
      commentsSingleLine = _ref.commentsSingleLine;

  var _ref2 = globalStyling || {},
      _ref2$direction = _ref2.direction,
      direction = _ref2$direction === undefined ? '' : _ref2$direction;

  return _react2.default.createElement(
    _FormField2.default,
    {
      doubleRow: doubleRow,
      label: label,
      required: required,
      globalStyling: globalStyling,
      labelFor: keyValue
    },
    _react2.default.createElement(TextArea, {
      type: type,
      value: value,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (e) {
        return onChange(keyValue, e.target.value);
      }),
      disabled: disabled,
      placeholder: placeholder,
      error: error,
      dir: direction,
      commentsSingleLine: commentsSingleLine,
      id: keyValue,
      name: keyValue
    }),
    error && _react2.default.createElement(
      'div',
      {
        style: {
          color: '#9e1b32',
          textAlign: '' + (direction === 'rtl' ? 'right' : 'left')
        }
      },
      error
    )
  );
}
TextAreaField.defaultProps = {
  error: ''
};