'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.constants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  GET_EXCHANGE_RATES: 'GET_EXCHANGE_RATES',
  GET_EXCHANGE_RATES_SUCCESS: 'GET_EXCHANGE_RATES_SUCCESS',
  CLEAR_EXCHANGE_RATES: 'CLEAR_EXCHANGE_RATES'
};

var actions = exports.actions = {
  getExchangeRates: function getExchangeRates(payload) {
    return {
      type: constants.GET_EXCHANGE_RATES,
      payload: payload
    };
  },
  getExchangeRatesSuccess: function getExchangeRatesSuccess(payload) {
    return {
      type: constants.GET_EXCHANGE_RATES_SUCCESS,
      payload: payload
    };
  },
  clearExchangeRates: function clearExchangeRates() {
    return {
      type: constants.CLEAR_EXCHANGE_RATES
    };
  }
};

var initialState = exports.initialState = {
  exchangeRates: {}
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case constants.GET_EXCHANGE_RATES_SUCCESS:
      return (0, _extends4.default)({}, state, {
        exchangeRates: (0, _extends4.default)({}, state.exchangeRates, (0, _defineProperty3.default)({}, payload.base, payload)),
        currency: payload.base
      });
    case constants.CLEAR_EXCHANGE_RATES:
      return (0, _extends4.default)({}, state, {
        exchangeRates: {}
      });
    default:
      return state;
  }
}