'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    margin-bottom: 70px;\n\n  '], ['\n    flex-direction: column;\n    margin-bottom: 70px;\n\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    align-items: flex-start;\n    margin-top:30px;\n  '], ['\n    flex-direction: column;\n    align-items: flex-start;\n    margin-top:30px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../Global');

var _HeadingTwo = require('../../Global/HeadingTwo');

var _HeadingTwo2 = _interopRequireDefault(_HeadingTwo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Section = _styledComponents2.default.div.withConfig({
  displayName: 'Ownership__Section',
  componentId: 'sc-39lbbv-0'
})(['width:100%,display:flex,flex-direction:column,']);

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'Ownership__Row',
  componentId: 'sc-39lbbv-1'
})(['display:flex;gap:16px;margin-bottom:154px;flex-direction:', ';', ';'], function (_ref) {
  var reverse = _ref.reverse;
  return reverse ? 'row-reverse' : 'row';
}, _theme2.default.max.medium(_templateObject));
var TextColumn = _styledComponents2.default.div.withConfig({
  displayName: 'Ownership__TextColumn',
  componentId: 'sc-39lbbv-2'
})(['display:flex;flex:1;align-items:center;justify-content:flex-end;flex-direction:', ';', ';'], function (_ref2) {
  var reverse = _ref2.reverse;
  return reverse ? 'row-reverse' : 'row';
}, _theme2.default.max.medium(_templateObject2));

var Title = _styledComponents2.default.h1.withConfig({
  displayName: 'Ownership__Title',
  componentId: 'sc-39lbbv-3'
})(['color:#1a1d1d;font-family:', ';font-size:16px;font-weight:500;text-transform:uppercase;'], function (_ref3) {
  var font = _ref3.font;
  return font;
});

var ImageColumn = _styledComponents2.default.div.withConfig({
  displayName: 'Ownership__ImageColumn',
  componentId: 'sc-39lbbv-4'
})(['display:flex;flex:1;']);

var TextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Ownership__TextContainer',
  componentId: 'sc-39lbbv-5'
})(['display:flex;flex-direction:column;max-width:360px;']);

var HeaderWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Ownership__HeaderWrapper',
  componentId: 'sc-39lbbv-6'
})(['margin-bottom:200px;']);

var Ownership = function Ownership(_ref4) {
  var items = _ref4.items,
      translations = _ref4.translations;

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      fonts = _useSelector.fonts;

  var mobile = (0, _useCheckIsMobileScreen2.default)();
  return _react2.default.createElement(
    Section,
    null,
    _react2.default.createElement(
      HeaderWrapper,
      null,
      _react2.default.createElement(
        _HeadingTwo2.default,
        {
          styleOverride: function styleOverride() {
            return '\n          font-family: ' + fonts.primaryFont.value + ';\n          font-size: 16px;\n          color: #1A1D1D;\n          text-transform: uppercase;\n        ';
          }
        },
        translations.timelessOwnership
      )
    ),
    items.map(function (item, index) {
      var reverse = index % 2 !== 0;
      var bodyTranslationKeys = item.bodyTranslationKeys,
          translations = item.translations;


      return _react2.default.createElement(
        Row,
        { key: index, reverse: reverse },
        _react2.default.createElement(
          ImageColumn,
          null,
          _react2.default.createElement('img', {
            style: {
              objectFit: 'cover',
              width: '100%'
            },
            src: mobile ? item.dynamicImages.mobile : item.dynamicImages.desktop
          })
        ),
        _react2.default.createElement(
          TextColumn,
          { reverse: reverse },
          _react2.default.createElement(
            TextContainer,
            null,
            _react2.default.createElement(
              Title,
              { font: fonts.primaryFont.value },
              translations.title
            ),
            bodyTranslationKeys.map(function (key) {
              return _react2.default.createElement(
                _Global.Paragraph,
                {
                  key: key,
                  styleOverride: function styleOverride() {
                    return 'color: #1a1d1d;\n                     font-family: ' + fonts.paragraphFont.value + ';\n                     font-size: 14px;\n                     font-weight: 300;\n                     line-height: 18px;';
                  }
                },
                translations[key]
              );
            })
          )
        )
      );
    })
  );
};

exports.default = Ownership;