'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Calendar;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Calendar(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '2em', height: '2em', viewBox: '0 -2 25 30' },
      _react2.default.createElement('path', {
        d: 'M20,20 L16,20 L16,16 L20,16 L20,20 Z M14,10 L10,10 L10,14 L14,14 L14,10 Z M20,10 L16,10 L16,14 L20,14 L20,10 Z M8,16 L4,16 L4,20 L8,20 L8,16 Z M14,16 L10,16 L10,20 L14,20 L14,16 Z M8,10 L4,10 L4,14 L8,14 L8,10 Z M24,2 L24,24 L0,24 L0,2 L3,2 L3,3 C3,4.103 3.897,5 5,5 C6.103,5 7,4.103 7,3 L7,2 L17,2 L17,3 C17,4.103 17.897,5 19,5 C20.103,5 21,4.103 21,3 L21,2 L24,2 Z M22,8 L2,8 L2,22 L22,22 L22,8 Z M20,1 C20,0.448 19.553,0 19,0 C18.447,0 18,0.448 18,1 L18,3 C18,3.552 18.447,4 19,4 C19.553,4 20,3.552 20,3 L20,1 Z M6,3 C6,3.552 5.553,4 5,4 C4.447,4 4,3.552 4,3 L4,1 C4,0.448 4.447,0 5,0 C5.553,0 6,0.448 6,1 L6,3 Z',
        id: 'Shape'
      })
    )
  );
}

Calendar.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};