'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _VehicleRetailerInfoDetails = require('./VehicleRetailerInfoDetails');

var _VehicleRetailerInfoDetails2 = _interopRequireDefault(_VehicleRetailerInfoDetails);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  var vehicleInfo = (0, _reactRedux.useSelector)(function (state) {
    if (!state.dataProvider.vehicleInfo) {
      return null;
    }

    var _state$dataProvider$v = state.dataProvider.vehicleInfo,
        vehicleId = _state$dataProvider$v.id,
        retailerInformation = _state$dataProvider$v.retailerInformation;


    if (props.config.flipRetailerCoordinates) {
      var clonedLatlong = [].concat((0, _toConsumableArray3.default)(retailerInformation.location.coordinates));

      return {
        vehicleId: vehicleId,
        retailerInformation: (0, _extends3.default)({}, retailerInformation, {
          location: { coordinates: clonedLatlong.reverse() }
        })
      };
    }

    return { vehicleId: vehicleId, retailerInformation: retailerInformation };
  });

  return vehicleInfo && _react2.default.createElement(_VehicleRetailerInfoDetails2.default, (0, _extends3.default)({}, props, {
    vehicleInfo: vehicleInfo
  }));
};