'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _reactRedux = require('react-redux');

var _router = require('../actions/router');

var _http = require('../helpers/http');

var _useChangeReleaseFlag = require('../utilities/useChangeReleaseFlag');

var _ComponentContext = require('./ComponentContext');

var _ComponentContext2 = _interopRequireDefault(_ComponentContext);

var _configSelectors = require('./configSelectors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var httpClientNext = function httpClientNext(dispatch, history) {
  return function (response) {
    if (response && response.status === 401 && !/\/signin$/.test(history.location.pathname)) {
      dispatch(_router.actions.navigate('/signin'));
    }
  };
};

var useSite = function useSite() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var hashRouting = (0, _reactRedux.useSelector)(function (state) {
    return state.config.settings.hashRouting;
  });
  var reduxState = (0, _reactRedux.useSelector)(function (state) {
    return state;
  });

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global;
  }),
      analytics = _useSelector.analytics,
      featureFlags = _useSelector.featureFlags,
      maps = _useSelector.maps;

  var _useSelector2 = (0, _reactRedux.useSelector)(function (state) {
    return state.router;
  }),
      platformMode = _useSelector2.platformMode;

  var ignoreBandList = platformMode ? ['CookiePolicy', 'Header', 'Footer', 'Push'] : null;

  var history = (0, _reactRedux.useSelector)(function (state) {
    return state.router.history;
  });
  var site = (0, _reactRedux.useSelector)(function (s) {
    return s.config.site;
  });
  var siteBands = (0, _reactRedux.useSelector)(function (s) {
    return site.map(function (gm) {
      return s.config.bands[gm];
    });
  });
  var siteBandsForPath = siteBands.map(function (band) {
    return (0, _configSelectors.bandWithConfigSelector)(band, hashRouting, reduxState);
  });

  var _useContext = (0, _react.useContext)(_ComponentContext2.default),
      Body = _useContext.Body;

  (0, _react.useEffect)(function () {
    (0, _http.httpClientIntercept)(httpClientNext(dispatch, history));
  }, [dispatch]);

  var env = (0, _useChangeReleaseFlag.getEnviromentFromUrl)();

  return {
    maps: (0, _extends3.default)({}, maps, env === 'prod' && maps.prodApiKey ? { apiKey: maps.prodApiKey } : {}, env === 'prod' && maps.mapsEmbedKeyProd ? { mapsEmbedKey: maps.mapsEmbedKeyProd } : {}),
    analytics: analytics,
    featureFlags: featureFlags,
    siteBands: siteBandsForPath,
    Body: Body,
    ignoreBandList: ignoreBandList
  };
};

exports.default = useSite;