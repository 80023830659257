'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Location = undefined;
exports.default = LocationFilter;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ipInformation = require('../../../../shared/localisation/ipInformation');

var _UserLocation = require('../../UserLocation');

var _UserLocation2 = _interopRequireDefault(_UserLocation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LocationFilter__ContentWrapper',
  componentId: 'sc-1e6ouiz-0'
})(['padding:0;border-bottom:1px solid #fff;']);

var LocationFilterTitle = _styledComponents2.default.div.withConfig({
  displayName: 'LocationFilter__LocationFilterTitle',
  componentId: 'sc-1e6ouiz-1'
})(['border-bottom:1px solid #fff;color:#fff;font-family:\'GenesisSansHeadGlobal-Regular\';padding-bottom:10px;text-transform:uppercase;']);

var Location = exports.Location = function Location(props) {
  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      userLocationEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.userLocationEnabled
    };
  }),
      userLocationEnabled = _useSelector.userLocationEnabled;

  var userIpCity = (0, _ipInformation.getIpCity)();
  var updateSessionPreferences = props.updateSessionPreferences,
      _props$location = props.location,
      latitude = _props$location.latitude,
      longitude = _props$location.longitude,
      searchCountryCode = props.searchCountryCode,
      updateFilters = props.updateFilters;


  var handleLocationChange = (0, _react.useCallback)(function (lat, long) {
    updateSessionPreferences('location', {
      latitude: lat,
      longitude: long
    });
    updateFilters({
      key: 'location',
      value: {
        latitude: lat,
        longitude: long
      }
    });
  }, [updateSessionPreferences]);

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(_UserLocation2.default, {
      direction: props.globalStyling.direction,
      placeholderText: userLocationEnabled ? userIpCity : props.translations.locationInputPlaceholder,
      iconUrl: props.filtersStyling.filterLocationIcon,
      browserLocationCallback: handleLocationChange,
      inputLocationCallback: handleLocationChange,
      options: {
        latitude: latitude,
        longitude: longitude,
        countryCode: searchCountryCode
      }
    })
  );
};

function LocationFilter(props) {
  return _react2.default.createElement(
    ContentWrapper,
    null,
    _react2.default.createElement(
      LocationFilterTitle,
      null,
      props.translations.locationFilterTitle
    ),
    _react2.default.createElement(Location, props)
  );
}