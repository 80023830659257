'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: ', ';\n    width: 33%;\n    position: absolute;\n    top: 50px;\n    ', ';\n  '], ['\n    display: ', ';\n    width: 33%;\n    position: absolute;\n    top: 50px;\n    ', ';\n  ']);
/* eslint-env browser */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _PlayVideo = require('../../components/JaguarIcons/VideoPlayer/PlayVideo');

var _PlayVideo2 = _interopRequireDefault(_PlayVideo);

var _Global = require('../../components/Global');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Wrapper'
}).withConfig({
  displayName: 'VideoPlayer__Wrapper',
  componentId: 'sc-d6dikv-0'
})(['display:inline-block;position:relative;width:100%;']);

var OpacityParent = _styledComponents2.default.div.withConfig({
  displayName: 'VideoPlayer__OpacityParent',
  componentId: 'sc-d6dikv-1'
})(['display:none;', ';'], _theme2.default.min.large(_templateObject, function (_ref) {
  var visible = _ref.visible;
  return visible ? 'block' : 'none';
}, function (_ref2) {
  var left = _ref2.left;
  return left && 'left : ' + left + '%';
}));
var OpacityWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VideoPlayer__OpacityWrapper',
  componentId: 'sc-d6dikv-2'
})(['&:after{content:\'\';width:100%;height:100%;', ';', ';position:absolute;top:0;left:0;z-index:15;}position:relative;margin:0 0 1px 0;padding:20px 40px;'], function (_ref3) {
  var theme = _ref3.theme;
  return theme.overlayVisible ? theme.backgroundColour && 'background-color: ' + theme.backgroundColour.value : '';
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.overlayVisible ? theme.backgroundOpacity && 'opacity: ' + theme.backgroundOpacity : '';
});

var PlayButton = _styledComponents2.default.div.withConfig({
  displayName: 'PlayButton'
}).withConfig({
  displayName: 'VideoPlayer__PlayButton',
  componentId: 'sc-d6dikv-3'
})(['position:absolute;z-index:5;width:100%;height:100%;display:', ';align-items:center;justify-content:center;background-color:#f3f3f3;', ' background-position:center;background-repeat:no-repeat;background-size:cover;cursor:pointer;'], function (props) {
  return props.visible ? 'flex' : 'none';
}, function (props) {
  return props.backgroundImage && 'background-image: url(' + props.backgroundImage + ');';
});

var ecoSetting = 'q_auto:eco';

// request video at the size of component keeping the aspect ratio
var convertToEcoQuality = function convertToEcoQuality(url, width, height) {
  return url.replace('video/upload', 'video/upload/' + ecoSetting + ',w_' + width + ',h_' + height + ',c_fit');
};

var toThumbnail = function toThumbnail(resource) {
  return resource && (resource.endsWith('.mp4') ? resource.slice(0, -4) + '.jpg' : resource);
};

var VideoPlayer = function (_React$Component) {
  (0, _inherits3.default)(VideoPlayer, _React$Component);

  function VideoPlayer(props) {
    (0, _classCallCheck3.default)(this, VideoPlayer);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VideoPlayer.__proto__ || (0, _getPrototypeOf2.default)(VideoPlayer)).call(this, props));

    Object.defineProperty(_this, 'videoRef', {
      enumerable: true,
      writable: true,
      value: null
    });
    Object.defineProperty(_this, 'sourceRef', {
      enumerable: true,
      writable: true,
      value: null
    });
    Object.defineProperty(_this, 'play', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (!_this.props.preview) {
          _this.videoRef.play();
          _this.setState(function () {
            return {
              playButtonVisible: false
            };
          });
        }
      }
    });
    Object.defineProperty(_this, 'showPlayButton', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.setState(function () {
          return {
            playButtonVisible: true
          };
        });
      }
    });


    _this.state = {
      playButtonVisible: true
    };
    return _this;
  }

  (0, _createClass3.default)(VideoPlayer, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _props$config = this.props.config,
          noTransform = _props$config.noTransform,
          videoFile = _props$config.videoFile;

      this.sourceRef.src = noTransform ? videoFile.value : convertToEcoQuality(videoFile.value, this.videoRef.clientWidth, this.videoRef.clientHeight);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var playButtonVisible = this.state.playButtonVisible;
      var _props$config2 = this.props.config,
          _props$config2$poster = _props$config2.posterImage,
          posterImage = _props$config2$poster === undefined ? {} : _props$config2$poster,
          _props$config2$videoF = _props$config2.videoFile,
          videoFile = _props$config2$videoF === undefined ? {} : _props$config2$videoF,
          translations = _props$config2.translations,
          overlayVisible = _props$config2.overlayVisible,
          backgroundColour = _props$config2.backgroundColour,
          backgroundOpacity = _props$config2.backgroundOpacity,
          headingFont = _props$config2.headingFont,
          bodyFont = _props$config2.bodyFont,
          contentWidth = _props$config2.contentWidth,
          videoContentLeft = _props$config2.videoContentLeft;

      return _react2.default.createElement(
        Wrapper,
        null,
        translations && _react2.default.createElement(
          _ContentWrapper2.default,
          {
            contentWidth: this.props.globalStyling && this.props.globalStyling.contentWidth || contentWidth
          },
          _react2.default.createElement(
            OpacityParent,
            {
              visible: overlayVisible && playButtonVisible,
              left: videoContentLeft
            },
            _react2.default.createElement(
              OpacityWrapper,
              {
                theme: { backgroundColour: backgroundColour, backgroundOpacity: backgroundOpacity, overlayVisible: overlayVisible }
              },
              translations.heading && _react2.default.createElement(
                _Global.HeadingOne,
                {
                  styleOverride: function styleOverride() {
                    return '\n                    position: relative;\n                    z-index: 20;\n                    ' + (0, _Global.fontStyleOverride)(headingFont) + '\n                  ';
                  }
                },
                translations.heading
              ),
              translations.subheading && _react2.default.createElement(
                _Global.HeadingOne,
                {
                  styleOverride: function styleOverride() {
                    return '\n                    position: relative;\n                    z-index: 20;\n                    ' + (0, _Global.fontStyleOverride)(headingFont) + '\n                  ';
                  }
                },
                translations.subheading
              ),
              translations.body && _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n                    position: relative;\n                    z-index: 20;\n                    ' + (0, _Global.fontStyleOverride)(bodyFont) + '\n                  ';
                  }
                },
                translations.body
              ),
              translations.footer && _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n                    position: relative;\n                    z-index: 20;\n                    margin-top: 20px;\n                    ' + (0, _Global.fontStyleOverride)(bodyFont) + '\n                  ';
                  }
                },
                translations.footer
              )
            )
          )
        ),
        _react2.default.createElement(
          PlayButton,
          {
            onClick: this.play,
            backgroundImage: posterImage.value || toThumbnail(videoFile.value),
            visible: playButtonVisible
          },
          _react2.default.createElement(_PlayVideo2.default, { height: '5em', width: '5em', colour: '#A9A9A9' })
        ),
        _react2.default.createElement(
          _ContentWrapper2.default,
          {
            contentWidth: this.props.globalStyling && this.props.globalStyling.contentWidth || contentWidth
          },
          _react2.default.createElement(
            'video',
            {
              ref: function ref(vid) {
                _this2.videoRef = vid;
              },
              width: '100%',
              controls: true,
              disablePictureInPicture: true,
              playsInline: true,
              controlsList: 'nodownload',
              onEnded: this.showPlayButton
            },
            _react2.default.createElement('source', {
              ref: function ref(source) {
                _this2.sourceRef = source;
              },
              src: this.state.videoUrl || 'novideo',
              type: 'video/mp4'
            }),
            'Sorry, your browser does not support embedded videos'
          )
        )
      );
    }
  }]);
  return VideoPlayer;
}(_react2.default.Component);

exports.default = VideoPlayer;