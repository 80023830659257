'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _One = require('./One');

var _One2 = _interopRequireDefault(_One);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  One: _One2.default
};