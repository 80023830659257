'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    z-index: 8;\n  '], ['\n    display: flex;\n    z-index: 8;\n  ']);

exports.default = FooterActionBanner;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'FooterActionBanner__Container',
  componentId: 'sc-nyhu0d-0'
})(['', ';', ';position:sticky;bottom:0;display:none;height:75px;width:100%;z-index:9;', ';'], function (_ref) {
  var colour = _ref.colour;
  return colour ? 'background: ' + colour : 'background: #0F0F0F';
}, function (_ref2) {
  var border = _ref2.border;
  return '' + (border && 'border-top: ' + border);
}, _theme2.default.max.large(_templateObject));

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FooterActionBanner__Wrapper',
  componentId: 'sc-nyhu0d-1'
})(['width:100%;margin:0 10px;display:flex;flex-direction:row;align-items:center;justify-content:space-between;']);

function FooterActionBanner(_ref3) {
  var leftButton = _ref3.leftButton,
      rightButton = _ref3.rightButton,
      stickyFooterBackgroundColour = _ref3.stickyFooterBackgroundColour,
      stickyFooterBorderTop = _ref3.stickyFooterBorderTop;

  return _react2.default.createElement(
    Container,
    {
      colour: stickyFooterBackgroundColour,
      border: stickyFooterBorderTop
    },
    _react2.default.createElement(
      Wrapper,
      null,
      leftButton && _react2.default.createElement(
        'div',
        { style: { width: rightButton ? '49%' : '100%' } },
        leftButton()
      ),
      rightButton && _react2.default.createElement(
        'div',
        { style: { width: leftButton ? '49%' : '100%' } },
        rightButton()
      )
    )
  );
}