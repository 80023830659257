'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _GenesisFooter = require('./GenesisFooter');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_GenesisFooter).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var id = exports.id = 'GenesisFooter';

var description = exports.description = 'GenesisFooter Module';