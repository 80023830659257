'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _router = require('../../../actions/router');

var _CompareVehicles = require('../../../components/AstonMartin/CompareVehicles');

var _CompareVehicles2 = _interopRequireDefault(_CompareVehicles);

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _vehicle = require('../../../helpers/vehicle');

var _compare = require('../../../shared/compare');

var _shortlist = require('../../../shared/shortlist');

var _AppShared = require('../../../types/AppShared');

var _comparePageTypes = require('../../../types/AstonMartin/comparePageTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CompareGrid = function (_Component) {
  (0, _inherits3.default)(CompareGrid, _Component);

  function CompareGrid() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, CompareGrid);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = CompareGrid.__proto__ || (0, _getPrototypeOf2.default)(CompareGrid)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _React$createElement;

        var _this$props = _this.props,
            dispatch = _this$props.dispatch,
            config = _this$props.config,
            shared = _this$props.shared,
            globalStyling = _this$props.globalStyling,
            marketInfo = _this$props.marketInfo;


        var onMoreInfoClick = function onMoreInfoClick(vehicle) {
          var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, shared.sessionPreferences.language);
          dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
        };

        var sharedComparedVehicles = shared.compare.vehicles;

        return _react2.default.createElement(
          _ContentWrapper2.default,
          null,
          _react2.default.createElement(_CompareVehicles2.default, (_React$createElement = {
            config: config,
            translations: config.translations,
            gridConfiguration: config.gridConfiguration,
            currentLanguage: marketInfo.locale,
            requestInformation: function requestInformation(vehicle) {
              return dispatch(_router.actions.navigate((config.ctaPath || '/enquiry') + '/' + vehicle.id));
            },
            removeVehicle: function removeVehicle(vehicle) {
              return dispatch(_compare.actions.toggleVehicle(vehicle));
            },
            comparedVehicles: sharedComparedVehicles,
            globalStyling: globalStyling
          }, (0, _defineProperty3.default)(_React$createElement, 'config', config), (0, _defineProperty3.default)(_React$createElement, 'onMoreInfoClick', onMoreInfoClick), (0, _defineProperty3.default)(_React$createElement, 'ctaToVdp', config.ctaToVdp), _React$createElement))
        );
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(CompareGrid, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // We do this to force a first run of the reducer
      // - there used to be a more generic solution,
      //   but it broke the builder
      var _props = this.props,
          init = _props.actions.init,
          dispatch = _props.dispatch,
          shared = _props.shared,
          translations = _props.config.translations;

      var currentLanguage = shared.sessionPreferences.language;
      var selectedLanguageToLocale = function selectedLanguageToLocale(language) {
        return language ? language.replace('_', '-') : '';
      };

      init();
      // load language from session storage and convert into locale to send to api
      dispatch(_compare.actions.getEquipmentList());
      dispatch(_compare.actions.checkVehicleStatus(selectedLanguageToLocale(currentLanguage), translations));
      dispatch(_shortlist.actions.getVehicles());
      if (shared.sessionPreferences.currency) {
        dispatch(_compare.actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates
          // statePath: getVehicleStatePath(featureFlags),
        }));
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _props2 = this.props,
          shared = _props2.shared,
          dispatch = _props2.dispatch;

      if (prevProps.shared.currencyConversion.exchangeRates !== shared.currencyConversion.exchangeRates) {
        dispatch(_compare.actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates
          // statePath: getVehicleStatePath(featureFlags),
        }));
      }

      if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language) {
        dispatch(_compare.actions.checkVehicleStatus());
        dispatch(_compare.actions.getEquipmentList());
      }
    }
  }]);
  return CompareGrid;
}(_react.Component);

exports.default = CompareGrid;