'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ListView;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SearchResultBlock = require('../SearchResultBlock');

var _SearchResultBlock2 = _interopRequireDefault(_SearchResultBlock);

var _SearchResultGridBlock = require('../SearchResultGridBlock');

var _SearchResultGridBlock2 = _interopRequireDefault(_SearchResultGridBlock);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Results = _styledComponents2.default.div.withConfig({
  displayName: 'ListView__Results',
  componentId: 'sc-168gt7h-0'
})(['width:100%;']);

function ListView(_ref) {
  var results = _ref.results,
      translations = _ref.translations,
      onMoreInfoClick = _ref.onMoreInfoClick,
      filtersExpanded = _ref.filtersExpanded,
      compareVehicle = _ref.compareVehicle,
      shortlistVehicle = _ref.shortlistVehicle,
      getShortListedIconColour = _ref.getShortListedIconColour,
      getCompareIconColour = _ref.getCompareIconColour,
      globalStyling = _ref.globalStyling,
      visibility = _ref.visibility,
      locale = _ref.locale,
      vehicleModelTitleFontWeight = _ref.vehicleModelTitleFontWeight,
      headerItemFont = _ref.headerItemFont,
      showGridTiles = _ref.showGridTiles,
      badgeStyling = _ref.badgeStyling,
      financeHeadingFont = _ref.financeHeadingFont,
      showFinancePopup = _ref.showFinancePopup,
      financeSubHeadingFont = _ref.financeSubHeadingFont,
      iconYoutubeVideo = _ref.iconYoutubeVideo,
      onEnquiryClick = _ref.onEnquiryClick;

  return _react2.default.createElement(
    Results,
    { 'data-cy': 'search-result-vehicle-container' },
    results.map(function (vehicle) {
      return showGridTiles ? _react2.default.createElement(_SearchResultGridBlock2.default, {
        key: vehicle.id,
        vehicle: vehicle,
        placeholdingImage: results.placeholdingImage,
        translations: translations,
        onMoreInfoClick: onMoreInfoClick,
        onEnquiryClick: function (_onEnquiryClick) {
          function onEnquiryClick() {
            return _onEnquiryClick.apply(this, arguments);
          }

          onEnquiryClick.toString = function () {
            return _onEnquiryClick.toString();
          };

          return onEnquiryClick;
        }(function () {
          return onEnquiryClick(vehicle.id);
        }),
        filtersExpanded: filtersExpanded,
        compareVehicle: compareVehicle,
        shortlistVehicle: shortlistVehicle,
        shortListedIconColour: getShortListedIconColour(vehicle.id),
        compareIconColour: getCompareIconColour(vehicle.id),
        globalStyling: globalStyling,
        visibility: visibility,
        locale: locale,
        vehicleModelTitleFontWeight: vehicleModelTitleFontWeight,
        badgeStyling: badgeStyling,
        headerItemFont: headerItemFont,
        iconYoutubeVideo: iconYoutubeVideo
      }) : _react2.default.createElement(_SearchResultBlock2.default, {
        key: vehicle.id,
        vehicle: vehicle,
        placeholdingImage: results.placeholdingImage,
        translations: translations,
        onMoreInfoClick: onMoreInfoClick,
        onEnquiryClick: function (_onEnquiryClick2) {
          function onEnquiryClick() {
            return _onEnquiryClick2.apply(this, arguments);
          }

          onEnquiryClick.toString = function () {
            return _onEnquiryClick2.toString();
          };

          return onEnquiryClick;
        }(function () {
          return onEnquiryClick(vehicle.id);
        }),
        vehiclePriceSectionWidth: filtersExpanded ? 40 : 25,
        displayImage: !filtersExpanded,
        compareVehicle: compareVehicle,
        shortlistVehicle: shortlistVehicle,
        shortListedIconColour: getShortListedIconColour(vehicle.id),
        compareIconColour: getCompareIconColour(vehicle.id),
        globalStyling: globalStyling,
        visibility: visibility,
        locale: locale,
        vehicleModelTitleFontWeight: vehicleModelTitleFontWeight,
        badgeStyling: badgeStyling,
        headerItemFont: headerItemFont,
        financeHeadingFont: financeHeadingFont,
        showFinancePopup: showFinancePopup,
        financeSubHeadingFont: financeSubHeadingFont,
        iconYoutubeVideo: iconYoutubeVideo
      });
    })
  );
}