'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocationFilter = useLocationFilter;

var _reactRedux = require('react-redux');

var _filters = require('../shared/filters');

var _useRegionsHook2 = require('../shared/hooks/useRegionsHook');

var _sessionPreferences = require('../shared/sessionPreferences');

function useLocationFilter() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var selectedFilters = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences.location || {};
  });

  var _useRegionsHook = (0, _useRegionsHook2.useRegionsHook)(),
      setRegionFilters = _useRegionsHook.setRegionFilters;

  var updateLocationFilter = function updateLocationFilter(_ref) {
    var latitude = _ref.latitude,
        longitude = _ref.longitude,
        locationTown = _ref.locationTown,
        userCountry = _ref.userCountry;

    var locationFilters = {
      latitude: latitude,
      longitude: longitude,
      locationTown: locationTown,
      userCountry: userCountry
    };

    if (!locationTown) delete locationFilters.locationTown;

    return dispatch(_sessionPreferences.actions.updateSessionPreferences('location', locationFilters));
  };

  var onButtonClick = function onButtonClick(checkForMainlandUK, checkForChannelIslands) {
    setRegionFilters(selectedFilters.userCountry);
    dispatch(_filters.actions.searchWithFilters());
    var decodedUrl = decodeURIComponent(window.location.href);

    if (checkForMainlandUK && decodedUrl.includes('Mainland UK')) {
      var filterUpdate = [{
        key: 'subregions',
        value: ['Mainland UK', 'Channel Islands']
      }];
      dispatch(_filters.actions.updateFilters(filterUpdate));
    }
    if (checkForChannelIslands && ['GG', 'JE', 'IM'].includes(selectedFilters.userCountry)) {
      var _filterUpdate = [{
        key: 'subregions',
        value: ['United Kingdom', 'Jersey', 'Guernsey', 'Isle of Man']
      }];
      dispatch(_filters.actions.updateFilters(_filterUpdate));
    }
  };

  return {
    onButtonClick: onButtonClick,
    updateLocationFilter: updateLocationFilter,
    latitude: selectedFilters.latitude,
    longitude: selectedFilters.longitude
  };
}