'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _set = require('babel-runtime/core-js/set');

var _set2 = _interopRequireDefault(_set);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _getIterator2 = require('babel-runtime/core-js/get-iterator');

var _getIterator3 = _interopRequireDefault(_getIterator2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.useModelSearch = useModelSearch;

var _react = require('react');

var _reactRedux = require('react-redux');

var _router = require('../../../actions/router');

var _reducer = require('../../../pods/ModelSearch/reducer');

var _filters = require('../../../shared/filters');

var _AppState = require('../../../types/AppState');

var _modelTypes = require('../../../types/Genesis/modelTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useModelSearch(config) {
  var dispatch = (0, _reactRedux.useDispatch)();

  var _useState = (0, _react.useState)([]),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      selectedTiles = _useState2[0],
      setSelectedTiles = _useState2[1];

  // Model groups retrieved from Model Explore (see config project).


  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.modelSearch;
  }),
      modelGroups = _useSelector.modelGroups;

  var totalTiles = (0, _react.useMemo)(function () {
    var tilesList = [];
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = (0, _getIterator3.default)(config), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var section = _step.value;

        tilesList = tilesList.concat(section.tiles);
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return tilesList.map(function (t) {
      return t.tileId;
    }).length;
  }, []);

  // Gets a model groups list from Model Explore.
  var getModelGroups = function getModelGroups() {
    return dispatch(_reducer.actions.getGenesisModelGroups());
  };

  var initialiseFilters = function initialiseFilters() {
    return dispatch(_filters.actions.initialiseFilters());
  };

  var clearState = function clearState() {
    return dispatch(_reducer.actions.clearState());
  };

  var clearQueryParams = function clearQueryParams() {
    return dispatch(_router.actions.replace('/search'));
  };

  var getTilesFromConfig = function getTilesFromConfig() {
    var tilesList = [];
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = (0, _getIterator3.default)(config), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var section = _step2.value;

        tilesList = tilesList.concat(section.tiles);
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    return tilesList;
  };

  /** Add or remove tile id from selection.
   * @param tileId Tile id.
   */
  var onTileSelected = function onTileSelected(tileId) {
    setSelectedTiles(function (state) {
      return state.includes(tileId) ? state.filter(function (id) {
        return id !== tileId;
      }) : [].concat((0, _toConsumableArray3.default)(state), [tileId]);
    });
  };

  /** Checks whether a tile is selected or not.
   *
   * @param tileId Tile id.
   * @returns If tile is selected.
   */
  var isTileSelected = function isTileSelected(tileId) {
    return selectedTiles.includes(tileId);
  };

  /** Check "Select all" state.
   *
   * @returns If "Select all" must be checked.
   */
  var isSelectAllChecked = function isSelectAllChecked() {
    return selectedTiles.length === totalTiles;
  };

  /** Forces the select/deselect action of all tiles.
   *
   * @param checked
   */
  var selectAllTiles = function selectAllTiles(checked) {
    if (!checked) {
      setSelectedTiles([]);
      return;
    }
    setSelectedTiles(getTilesFromConfig().map(function (t) {
      return t.tileId;
    }));
  };

  /** Sets the SRP filters state for building querystring parameters.
   * Uses tile ids to get model groups and variants from config project.
   * @param selection Tile ids list.
   */
  var prepareSRPFilters = function prepareSRPFilters(selection) {
    var tilesList = getTilesFromConfig();

    var filteredTiles = tilesList.filter(function (tile) {
      return selection.includes(tile.tileId);
    });

    var groupModels = filteredTiles.map(function (tile) {
      return tile.groups;
    });
    var groupVariants = filteredTiles.map(function (tile) {
      return tile.models;
    });

    // Get model groups and create an unique array with unique values.
    var mergedGroups = [].concat((0, _toConsumableArray3.default)(new _set2.default(groupModels.flat(1))));
    var mergedVariants = [].concat((0, _toConsumableArray3.default)(new _set2.default(groupVariants.flat(1))));

    dispatch(_filters.actions.updateFilters([{ key: 'model', value: mergedGroups }, {
      key: 'modelVariants',
      value: mergedVariants
    }], null, false));
  };

  return {
    selectedTiles: selectedTiles,
    getModelGroups: getModelGroups,
    initialiseFilters: initialiseFilters,
    clearState: clearState,
    modelGroups: modelGroups,
    prepareSRPFilters: prepareSRPFilters,
    onTileSelected: onTileSelected,
    clearQueryParams: clearQueryParams,
    isTileSelected: isTileSelected,
    selectAllTiles: selectAllTiles,
    isSelectAllChecked: isSelectAllChecked
  };
}