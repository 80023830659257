'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Button = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Button = _styledComponents2.default.button.withConfig({
  displayName: 'Button',
  componentId: 'sc-9651vo-0'
})(['align-items:center;background-color:#111;border:1px solid #fff;color:#fff;cursor:pointer;display:flex;flex-shrink:0;font-family:\'GenesisSansTextGlobal-Regular\';font-size:18px;height:56px;justify-content:center;text-align:center;width:290px;box-shadow:inset 0 0 0 0 #fff;transition:ease-out 0.4s;', ' &:hover{border:2px solid #fff;color:#000;box-shadow:inset 600px 0 0 0 #fff;}'], _Genesis2.default.max.medium(_templateObject));

exports.Button = Button;