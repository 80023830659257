'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_RESET_PASSWORD';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['INIT', 'REQUEST_PASSWORD_RESET', 'REQUEST_PASSWORD_RESET_SUCCESS', 'REQUEST_PASSWORD_RESET_FAILURE', 'RESET_PASSWORD', 'RESET_PASSWORD_SUCCESS', 'RESET_PASSWORD_FAILURE']);

var id = exports.id = 'ResetPassword';

var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  requestPasswordReset: (0, _reducerMacros.moduleActionWithArgs)(constants.REQUEST_PASSWORD_RESET, id, function (payload) {
    return payload;
  }),
  requestPasswordResetSuccess: (0, _reducerMacros.moduleAction)(constants.REQUEST_PASSWORD_RESET_SUCCESS, id),
  requestPasswordResetFailure: (0, _reducerMacros.moduleAction)(constants.REQUEST_PASSWORD_RESET_FAILURE, id),
  resetPassword: (0, _reducerMacros.moduleActionWithArgs)(constants.RESET_PASSWORD, id, function (payload) {
    return payload;
  }),
  resetPasswordSuccess: (0, _reducerMacros.moduleAction)(constants.RESET_PASSWORD_SUCCESS, id),
  resetPasswordFailure: (0, _reducerMacros.moduleAction)(constants.RESET_PASSWORD_FAILURE, id)
};

var initialState = exports.initialState = {
  resetRequestStatus: 'NONE',
  resetPasswordStatus: 'NONE'
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.INIT:
      return (0, _extends3.default)({}, initialState);
    case constants.REQUEST_PASSWORD_RESET_SUCCESS:
      return (0, _extends3.default)({}, state, {
        resetRequestStatus: 'SUCCESS'
      });
    case constants.REQUEST_PASSWORD_RESET_FAILURE:
      return (0, _extends3.default)({}, state, {
        resetRequestStatus: 'FAILURE'
      });
    case constants.RESET_PASSWORD_SUCCESS:
      return (0, _extends3.default)({}, state, {
        resetPasswordStatus: 'SUCCESS'
      });
    case constants.RESET_PASSWORD_FAILURE:
      return (0, _extends3.default)({}, state, {
        resetPasswordStatus: 'EXPIRED'
      });
    default:
      return state;
  }
}