'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Fade = require('./Fade');

var _Fade2 = _interopRequireDefault(_Fade);

var _StickyWrapper = require('./StickyWrapper');

var _StickyWrapper2 = _interopRequireDefault(_StickyWrapper);

var _SearchFilterLocationSearchBarContainer = require('../containers/SearchFilterLocationSearchBarContainer');

var _SearchFilterLocationSearchBarContainer2 = _interopRequireDefault(_SearchFilterLocationSearchBarContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var translations = _ref.translations,
      dropdownTheme = _ref.dropdownTheme,
      count = _ref.count,
      display = _ref.display,
      globalStyling = _ref.globalStyling,
      pathByModule = _ref.pathByModule,
      distanceFilterInitialSelectedIndex = _ref.distanceFilterInitialSelectedIndex,
      locationIconUrl = _ref.locationIconUrl,
      searchCountryCode = _ref.searchCountryCode,
      fullTileSelect = _ref.fullTileSelect;
  return _react2.default.createElement(
    _Fade2.default,
    { display: display },
    _react2.default.createElement(
      _StickyWrapper2.default,
      { id: 'stickywrapper', layout: 'bottom' },
      _react2.default.createElement(_SearchFilterLocationSearchBarContainer2.default, {
        count: count,
        translations: translations,
        dropdownTheme: dropdownTheme,
        globalStyling: globalStyling,
        pathByModule: pathByModule,
        distanceFilterInitialSelectedIndex: distanceFilterInitialSelectedIndex,
        locationIconUrl: locationIconUrl,
        searchCountryCode: searchCountryCode,
        fullTileSelect: fullTileSelect
      })
    )
  );
};