'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('../../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var config = _ref.config,
      _ref$config = _ref.config,
      imageBehaviour = _ref$config.imageBehaviour,
      imagePosition = _ref$config.imagePosition,
      imageHeight = _ref$config.imageHeight,
      _ref$postData = _ref.postData,
      _ref$postData$transla = _ref$postData.translations,
      headlineText = _ref$postData$transla.headlineText,
      bodyText = _ref$postData$transla.bodyText,
      imageLabel = _ref$postData$transla.imageLabel,
      src = _ref$postData.image.src,
      _ref$postData$globalS = _ref$postData.globalStyling;
  _ref$postData$globalS = _ref$postData$globalS === undefined ? {} : _ref$postData$globalS;
  var _ref$postData$globalS2 = _ref$postData$globalS.uiElements,
      uiElements = _ref$postData$globalS2 === undefined ? {} : _ref$postData$globalS2,
      headlineFont = _ref$postData.headlineFont,
      padding = _ref$postData.padding,
      verticalAlignment = _ref$postData.verticalAlignment,
      textPadding = _ref$postData.textPadding,
      dispatch = _ref$postData.dispatch,
      overlayConfig = (0, _objectWithoutProperties3.default)(_ref$postData, ['translations', 'image', 'globalStyling', 'headlineFont', 'padding', 'verticalAlignment', 'textPadding', 'dispatch']);

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      _components.Image,
      (0, _extends3.default)({ src: src, imageBehaviour: imageBehaviour, imagePosition: imagePosition, imageHeight: imageHeight }, {
        role: 'img',
        'aria-label': imageLabel
      }),
      _react2.default.createElement(_components.Overlay, (0, _extends3.default)({}, overlayConfig, {
        headlineText: headlineText,
        verticalAlignment: verticalAlignment,
        padding: padding,
        headlineFont: headlineFont,
        uiElements: uiElements,
        dispatch: dispatch
      }))
    ),
    _react2.default.createElement(
      _components.TextWrapper,
      { padding: textPadding },
      _react2.default.createElement(_components.Text, { config: (0, _extends3.default)({}, config, { bodyText: bodyText }) })
    )
  );
};