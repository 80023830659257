'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.types = undefined;

var _framerMotion = require('framer-motion');

var _react = require('react');

var types = exports.types = {
  HOVER_END_NEXT: 'HOVER_END_NEXT',
  HOVER_END_PREV: 'HOVER_END_PREV',
  HOVER_START_NEXT: 'HOVER_START_NEXT',
  HOVER_START_PREV: 'HOVER_START_PREV',
  MOVE_X: 'MOVE_X',
  RESET: 'RESET',
  SET_CURRENT_POS: 'SET_CURRENT_POS',
  SET_DRAGGING: 'SET_DRAGGING',
  SET_WIDTH: 'SET_WIDTH',
  UPDATE_CLIENT_WIDTH: 'UPDATE_CLIENT_WIDTH',
  SET_CURSOR_VARIANT: 'SET_CURSOR_VARIANT'
};