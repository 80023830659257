'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 40px;\n  '], ['\n    margin-bottom: 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 40px 0 50px;\n  '], ['\n    padding: 0 40px 0 50px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  flex-direction: column;\n  padding:0 10px;\n  '], ['\n  flex-direction: column;\n  padding:0 10px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    margin-right: 20px;\n    max-width: 260px;\n  '], ['\n    margin-right: 20px;\n    max-width: 260px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n    width: 100%;\n    position: sticky;\n    top: 0;\n    background-color: #FFF;\n    z-index: 1;\n  '], ['\n    display: block;\n    width: 100%;\n    position: sticky;\n    top: 0;\n    background-color: #FFF;\n    z-index: 1;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 20px;\n    color: #333333;\n  '], ['\n    font-size: 20px;\n    color: #333333;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['font-weight: 400;\n    font-size: 24px;\n    line-height: 33px;\n    color: #000000;\n  '], ['font-weight: 400;\n    font-size: 24px;\n    line-height: 33px;\n    color: #000000;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 16px;\n    line-height: 24px;\n  '], ['\n    font-size: 16px;\n    line-height: 24px;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 16px;\n    color: #5B5B5B;\n  '], ['\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 16px;\n    color: #5B5B5B;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    gap:4px;\n  '], ['\n    gap:4px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _sessionStorage = require('../../../helpers/sessionStorage');

var sessionStorageManager = _interopRequireWildcard(_sessionStorage);

var _filters = require('../../../shared/filters');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _VehicleResults = require('./VehicleResults');

var _VehicleResults2 = _interopRequireDefault(_VehicleResults);

var _CantFindWhatLookingFor = require('./CantFindWhatLookingFor');

var _CantFindWhatLookingFor2 = _interopRequireDefault(_CantFindWhatLookingFor);

var _Spinner = require('../../Spinner');

var _FiltersContainer = require('./Filters/FiltersContainer');

var _FiltersContainer2 = _interopRequireDefault(_FiltersContainer);

var _MobileFilters = require('./Filters/MobileFilters');

var _MobileFilters2 = _interopRequireDefault(_MobileFilters);

var _searchResultsTypes = require('../../../types/Bentley/searchResultsTypes');

var _SelectBar = require('../SelectBar');

var _SelectBar2 = _interopRequireDefault(_SelectBar);

var _AppShared = require('../../../types/AppShared');

var _vehicleTypes = require('../../../types/Bentley/vehicleTypes');

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

var _ipInformation = require('../../../shared/localisation/ipInformation');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Container',
  componentId: 'sc-5rldqv-0'
})(['display:flex;flex-direction:column;width:100%;text-transform:uppercase;align-items:center;max-width:1920px;', ''], _theme2.default.min.large(_templateObject));

var Grid = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Grid',
  componentId: 'sc-5rldqv-1'
})(['@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){min-height:100%;}width:100%;display:flex;justify-content:space-between;flex-direction:row;box-sizing:border-box;padding:0 140px 0 50px;', ' ', ''], _theme2.default.max.xxl(_templateObject2), _theme2.default.max.medium(_templateObject3));

var FiltersColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__FiltersColumn',
  componentId: 'sc-5rldqv-2'
})(['font-family:Bentley-Regular;display:flex;flex-direction:column;max-width:380px;min-width:270px;margin-right:30px;', ' ', ''], _theme2.default.max.large(_templateObject4), _theme2.default.max.medium(_templateObject5));

var GridResultColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__GridResultColumn',
  componentId: 'sc-5rldqv-3'
})(['display:flex;flex-direction:column;width:100%;']);

var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__MobileOnly',
  componentId: 'sc-5rldqv-4'
})(['display:none;', ';'], _theme2.default.max.medium(_templateObject6));

var ResultsSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__ResultsSectionWrapper',
  componentId: 'sc-5rldqv-5'
})(['min-height:1px;']);

var VehicleResultsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__VehicleResultsWrapper',
  componentId: 'sc-5rldqv-6'
})(['']);

var FiltersContainerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__FiltersContainerWrapper',
  componentId: 'sc-5rldqv-7'
})(['margin-top:0px;display:flex;flex-direction:column;']);

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Title',
  componentId: 'sc-5rldqv-8'
})(['font-family:Bentley-Regular;font-style:normal;font-weight:300;font-size:30px;line-height:28px;text-align:center;text-transform:uppercase;color:#222222;', ' ', ''], _theme2.default.max.medium(_templateObject7), _theme2.default.max.small(_templateObject8));
var SubTitle = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__SubTitle',
  componentId: 'sc-5rldqv-9'
})(['font-family:Bentley-Regular;font-style:normal;font-weight:400;font-size:18px;line-height:24px;text-align:center;text-transform:uppercase;color:#727376;height:50px;', ' ', ''], _theme2.default.max.medium(_templateObject9), _theme2.default.max.small(_templateObject10));

var SearchResultHeader = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__SearchResultHeader',
  componentId: 'sc-5rldqv-10'
})(['display:flex;flex-direction:column;align-items:center;justify-content:space-between;gap:16px;padding:30px 0;', ''], _theme2.default.max.small(_templateObject11));

var SelectBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__SelectBarContainer',
  componentId: 'sc-5rldqv-11'
})(['display:flex;flex-direction:column;align-items:flex-end;', ''], _theme2.default.max.medium(_templateObject5));

var DisclaimerText = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__DisclaimerText',
  componentId: 'sc-5rldqv-12'
})(['font-family:Bentley-light;font-size:14px;color:#7a7a7a;text-transform:none;letter-spacing:1.2px;']);

var ResultsSection = function ResultsSection(_ref) {
  var config = _ref.config,
      translations = _ref.translations,
      results = _ref.results,
      onMoreInfoClick = _ref.onMoreInfoClick,
      onPageNumberChange = _ref.onPageNumberChange,
      marketInfo = _ref.marketInfo,
      currentPage = _ref.currentPage,
      pageSize = _ref.pageSize,
      totalResults = _ref.totalResults,
      onEnquireClick = _ref.onEnquireClick,
      getSimilarCars = _ref.getSimilarCars,
      shared = _ref.shared;
  return _react2.default.createElement(
    ResultsSectionWrapper,
    null,
    _react2.default.createElement(
      VehicleResultsWrapper,
      null,
      _react2.default.createElement(_VehicleResults2.default, {
        config: config,
        results: results,
        onMoreInfoClick: onMoreInfoClick,
        onEnquireClick: onEnquireClick,
        translations: translations,
        locale: marketInfo.locale,
        currentPage: currentPage,
        pageSize: pageSize,
        totalResults: totalResults,
        onPageNumberChange: onPageNumberChange,
        getSimilarCars: getSimilarCars,
        shared: shared
      }),
      _react2.default.createElement(_CantFindWhatLookingFor2.default, { config: config, translations: translations })
    )
  );
};

var Filters = function Filters(_ref2) {
  var filtersConfig = _ref2.filtersConfig,
      globalStyling = _ref2.globalStyling,
      toggle = _ref2.toggle,
      marketInfo = _ref2.marketInfo,
      shared = _ref2.shared,
      translations = _ref2.translations,
      hideVehicleCounts = _ref2.hideVehicleCounts;
  return _react2.default.createElement(_FiltersContainer2.default, (0, _extends3.default)({}, filtersConfig, {
    globalStyling: globalStyling,
    locale: marketInfo.locale,
    toggle: toggle,
    currencyCode: marketInfo.currencyCode,
    marketInfo: marketInfo,
    shared: shared,
    searchCountryCode: marketInfo.searchCountryCode,
    hideSearch: true,
    translations: translations,
    hideVehicleCounts: hideVehicleCounts
  }));
};

var SearchResults = function SearchResults(props) {
  var dispatch = (0, _reactRedux.useDispatch)();
  var config = props.config,
      filtersConfig = props.filtersConfig,
      translations = props.translations,
      marketInfo = props.marketInfo,
      loading = props.loading,
      filtersLoaded = props.filtersLoaded,
      totalResults = props.totalResults,
      shared = props.shared;


  var hasLocation = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences.location || {};
  });
  var ipCountryName = (0, _ipInformation.getIpCountryName)();

  var displaySingleCountryStock = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.displaySingleCountryStock;
  });

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      displayMobileFilterOptions = _useState2[0],
      setDisplayMobileFilterOptions = _useState2[1];

  var globalStyling = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling);

  var _toggle = function _toggle() {
    return setDisplayMobileFilterOptions(function (state) {
      return !state;
    });
  };

  var formattedRegions = (0, _react.useMemo)(function () {
    var subRegions = shared && shared.filters && shared.filters.selectedFilters && shared.filters.selectedFilters.subregions || '';

    var initialFormattedRegions = translations.regionFilterPlaceholderText;

    if (displaySingleCountryStock) {
      var urlContainsRegions = window.location.search.includes('subregions');
      var regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
      var countryCode = hasLocation && hasLocation.userCountry ? hasLocation.userCountry : '';

      if (countryCode) {
        var userCountryLongName = regionNames.of(countryCode);
        sessionStorageManager.saveKey('userCountryLongName', userCountryLongName);
      }

      if (!urlContainsRegions && typeof subRegions === 'string') {
        var userCountryName = sessionStorageManager.getKey('userCountryLongName') || ipCountryName;
        var filterUpdate = [{
          key: 'subregions',
          value: userCountryName
        }];
        dispatch(_filters.actions.updateFilters(filterUpdate));
      }
    }

    if (subRegions.length === 0) {
      return initialFormattedRegions;
    }

    var selectedRegions = (0, _keys2.default)(config.regions).filter(function (k) {
      return config.regions[k].some(function (s) {
        return subRegions.includes(s);
      }) ? k : undefined;
    }).filter(Boolean);

    return selectedRegions.map(function (text) {
      return translations[text.toLowerCase()];
    }).join(', ');
  }, [shared, config.regions, translations, displaySingleCountryStock, hasLocation, ipCountryName, dispatch]);

  var hideVehicleCounts = shared && shared.filters && shared.filters.selectedFilters && shared.filters.selectedFilters.subregions && shared.filters.selectedFilters.subregions.includes('United Kingdom') && config.showHideVehicleCount;

  var filters = _react2.default.createElement(Filters, {
    filtersConfig: filtersConfig,
    globalStyling: globalStyling,
    toggle: function toggle() {
      return _toggle();
    },
    marketInfo: marketInfo,
    shared: shared,
    displayMobileFilterOptions: displayMobileFilterOptions,
    translations: translations,
    hideVehicleCounts: hideVehicleCounts
  });

  return _react2.default.createElement(
    Container,
    { 'data-cy': 'search-results' },
    _react2.default.createElement(
      MobileOnly,
      null,
      _react2.default.createElement(_MobileFilters2.default, {
        filters: filters,
        translations: translations,
        shared: shared,
        displayMobileFilterOptions: displayMobileFilterOptions,
        filtersCloseIcon: config.filtersCloseIcon,
        searchSort: shared.sessionPreferences.searchSort,
        currencies: config.currencies,
        hideSelectBar: config.hideSelectBar
      })
    ),
    _react2.default.createElement(
      SearchResultHeader,
      null,
      _react2.default.createElement(
        Title,
        null,
        translations.title
      ),
      _react2.default.createElement(
        SubTitle,
        null,
        (filtersLoaded || !loading) && totalResults !== null && totalResults > 0 && !hideVehicleCounts && formattedRegions && _react2.default.createElement(
          _react2.default.Fragment,
          null,
          totalResults + ' ' + translations.totalCount
        )
      ),
      shared.sessionPreferences.currency && _react2.default.createElement(
        DisclaimerText,
        { id: 'super-1' },
        translations.priceDisclaimer
      )
    ),
    _react2.default.createElement(
      _Spinner.SpinnerWrapper,
      {
        loaded: filtersLoaded,
        globalStyling: globalStyling,
        label: translations.loadingText
      },
      function () {
        return _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            Grid,
            null,
            _react2.default.createElement(
              FiltersColumn,
              null,
              _react2.default.createElement(
                FiltersContainerWrapper,
                null,
                filters
              )
            ),
            _react2.default.createElement(
              GridResultColumn,
              { 'data-cy': 'search-result-container-grid-column' },
              _react2.default.createElement(
                _Spinner.SpinnerWrapper,
                {
                  loaded: !loading,
                  globalStyling: globalStyling,
                  label: translations.loadingText
                },
                function () {
                  return _react2.default.createElement(
                    _react.Fragment,
                    null,
                    _react2.default.createElement(
                      SelectBarContainer,
                      null,
                      !config.hideSelectBar && _react2.default.createElement(_SelectBar2.default, {
                        searchSort: shared.sessionPreferences.searchSort,
                        currencyValue: shared.sessionPreferences.currency,
                        currencies: config.currencies,
                        translations: config.translations
                      })
                    ),
                    _react2.default.createElement(ResultsSection, props)
                  );
                }
              )
            )
          )
        );
      }
    )
  );
};

exports.default = SearchResults;