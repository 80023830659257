'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RenderRow = exports.TableDataFirstColumn = exports.TableData = exports.TableRow = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TableRow = exports.TableRow = _styledComponents2.default.tr.withConfig({
  displayName: 'TableElements__TableRow',
  componentId: 'sc-1p0nv1z-0'
})(['font-size:20px;']);
var BaseTableData = _styledComponents2.default.td.withConfig({
  displayName: 'TableElements__BaseTableData',
  componentId: 'sc-1p0nv1z-1'
})(['color:#444444;']);

var TableData = exports.TableData = (0, _styledComponents2.default)(BaseTableData).withConfig({
  displayName: 'TableElements__TableData',
  componentId: 'sc-1p0nv1z-2'
})(['']);

var TableDataFirstColumn = exports.TableDataFirstColumn = (0, _styledComponents2.default)(BaseTableData).withConfig({
  displayName: 'TableElements__TableDataFirstColumn',
  componentId: 'sc-1p0nv1z-3'
})(['padding:0px 20px;background-color:#ffffff;']);

var DisclaimerStyle = _styledComponents2.default.span.withConfig({
  displayName: 'TableElements__DisclaimerStyle',
  componentId: 'sc-1p0nv1z-4'
})(['font-size:12px;margin:0;padding:0;']);

var RenderRow = exports.RenderRow = function RenderRow(_ref) {
  var content = _ref.content,
      unit = _ref.unit,
      disclaimer = _ref.disclaimer;
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n      line-height: 35px;\n      margin: 20px 0 0 0;\n      border-bottom: solid 0.5px #000000;\n      font-size: 16px;\n      ' + (disclaimer ? 'line-height: 17.5px' : '') + '\n    ';
        }
      },
      content,
      ' ',
      unit,
      disclaimer && _react2.default.createElement(
        DisclaimerStyle,
        { style: { fontSize: '12px' } },
        _react2.default.createElement('br', null),
        disclaimer
      )
    )
  );
};