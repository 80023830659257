'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DesktopOnlyStyled = _styledComponents2.default.div.withConfig({
  displayName: 'DesktopOnly__DesktopOnlyStyled',
  componentId: 'sc-pcdnpp-0'
})(['', ';'], _theme2.default.max.large(_templateObject));

var DesktopOnly = function DesktopOnly(_ref) {
  var children = _ref.children;
  return _react2.default.createElement(
    DesktopOnlyStyled,
    null,
    children
  );
};

exports.default = DesktopOnly;