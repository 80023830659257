'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _settings = require('../../../shared/selectors/settings');

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _InputField = require('./InputField');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'TextAreaField__Label',
  componentId: 'sc-1y40kt5-0'
})(['color:#727376;font-size:16px;font-family:', ' font-weight:normal;pointer-events:none;top:10px;transition:0.2s ease all;', ''], function (_ref) {
  var fonts = _ref.fonts;
  return fonts.secondaryFont.value;
}, function (_ref2) {
  var alignRight = _ref2.alignRight;
  return alignRight && 'text-align: end;';
});

var Input = _styledComponents2.default.textarea.attrs(function () {
  return {
    required: true
  };
}).withConfig({
  displayName: 'TextAreaField__Input',
  componentId: 'sc-1y40kt5-1'
})(['font-family:', ' font-size:14px;color:#757575;padding:10px;display:block;width:100%;height:160px;background-color:#FFFFFF;border:1px solid #9DACA5;box-sizing:border-box;&:focus{outline:none;border-bottom:solid 0.5px #000000;}'], function (_ref3) {
  var fonts = _ref3.fonts;
  return fonts.secondaryFont.value;
});

var Error = _styledComponents2.default.div.withConfig({
  displayName: 'TextAreaField__Error',
  componentId: 'sc-1y40kt5-2'
})(['position:absolute;top:30px;color:red;font-size:11px;']);

var DefaultMaxLength = 524288;

var TextAreaField = function TextAreaField(_ref4) {
  var id = _ref4.id,
      label = _ref4.label,
      required = _ref4.required,
      type = _ref4.type,
      value = _ref4.value,
      onChange = _ref4.onChange,
      onKeyPress = _ref4.onKeyPress,
      error = _ref4.error,
      disabled = _ref4.disabled,
      translations = _ref4.translations,
      validate = _ref4.validate,
      readOnly = _ref4.readOnly,
      _ref4$maxLength = _ref4.maxLength,
      maxLength = _ref4$maxLength === undefined ? DefaultMaxLength : _ref4$maxLength,
      rows = _ref4.rows;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    _FormField2.default,
    null,
    _react2.default.createElement(
      Label,
      { fonts: fonts },
      label,
      !required && ' (optional)'
    ),
    _react2.default.createElement(Input, {
      type: type,
      value: value,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (event) {
        onChange({
          id: id,
          value: event.target.value,
          translations: translations,
          validate: validate,
          event: event
        });
      }),
      onKeyPress: onKeyPress,
      disabled: disabled,
      error: error,
      readOnly: readOnly,
      maxLength: maxLength,
      fonts: fonts,
      rows: rows
    }),
    maxLength !== DefaultMaxLength && _react2.default.createElement(
      Label,
      { fonts: fonts, alignRight: true },
      value.length,
      '/',
      maxLength
    ),
    error && _react2.default.createElement(
      Error,
      null,
      error
    )
  );
};

exports.default = TextAreaField;


TextAreaField.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  onChange: function onChange() {},
  onKeyPress: function onKeyPress() {},
  label: '',
  error: '',
  validate: false,
  readOnly: false
};