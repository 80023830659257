'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  display: block;\n  width: 83%;\n  ', ';\n'], ['\n  display: block;\n  width: 83%;\n  ', ';\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  display: none;\n  ', ';\n'], ['\n  display: none;\n  ', ';\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n  '], ['\n    display: flex;\n    flex-direction: column;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n  background-color: #f0f0f0;\n  color: #000000;\n  height: auto;\n  padding: 20px 10px;\n  display: flex;\n  justify-content: center;\n'], ['\n  background-color: #f0f0f0;\n  color: #000000;\n  height: auto;\n  padding: 20px 10px;\n  display: flex;\n  justify-content: center;\n']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  flex-direction: column;\n  background-color: #fff;\n  border: 1px solid #dedede;\n  margin-bottom: 20px;\n  ', ';\n'], ['\n  display: flex;\n  flex-direction: column;\n  background-color: #fff;\n  border: 1px solid #dedede;\n  margin-bottom: 20px;\n  ', ';\n']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n    height: 220px;\n  '], ['\n    flex-direction: row;\n    height: 220px;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  flex-direction: column;\n  ', ';\n'], ['\n  display: flex;\n  flex-direction: column;\n  ', ';\n']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n    padding: 10px;\n  '], ['\n    width: 50%;\n    padding: 10px;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n  margin-left: 15px;\n  margin-right: 10px;\n  padding: 10px;\n  ', ';\n'], ['\n  margin-left: 15px;\n  margin-right: 10px;\n  padding: 10px;\n  ', ';\n']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 20px;\n  font-weight: 600;\n  letter-spacing: 3px;\n  text-transform: uppercase;\n  font-size: 18px;\n  &:hover {\n    cursor: pointer;\n  }\n'], ['\n  font-size: 20px;\n  font-weight: 600;\n  letter-spacing: 3px;\n  text-transform: uppercase;\n  font-size: 18px;\n  &:hover {\n    cursor: pointer;\n  }\n']),
    _templateObject13 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 15px;\n  color: #7E7E7E;\n  line-height: 30px;\n  text-transform: uppercase;\n  font-size: 14px;\n'], ['\n  font-size: 15px;\n  color: #7E7E7E;\n  line-height: 30px;\n  text-transform: uppercase;\n  font-size: 14px;\n']),
    _templateObject14 = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n'], ['\n  ', ';\n']),
    _templateObject15 = (0, _taggedTemplateLiteral3.default)(['\n    width: 20%;\n    margin: auto;\n  '], ['\n    width: 20%;\n    margin: auto;\n  ']),
    _templateObject16 = (0, _taggedTemplateLiteral3.default)(['\n  padding: 10px 0;\n  display: flex;\n'], ['\n  padding: 10px 0;\n  display: flex;\n']),
    _templateObject17 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  flex-flow: column nowrap;\n  font-size: .8rem;\n  margin: 0.5rem;\n  line-height: 1.5;\n  flex: 1 1 auto;\n'], ['\n  display: flex;\n  flex-flow: column nowrap;\n  font-size: .8rem;\n  margin: 0.5rem;\n  line-height: 1.5;\n  flex: 1 1 auto;\n']),
    _templateObject18 = (0, _taggedTemplateLiteral3.default)(['\n  width: 100%;\n  display: flex;\n  flex-flow: row nowrap;\n'], ['\n  width: 100%;\n  display: flex;\n  flex-flow: row nowrap;\n']),
    _templateObject19 = (0, _taggedTemplateLiteral3.default)(['\n  width: auto;\n  height: 100%;\n  position: relative;\n  ', ';\n'], ['\n  width: auto;\n  height: 100%;\n  position: relative;\n  ', ';\n']),
    _templateObject20 = (0, _taggedTemplateLiteral3.default)(['\n    width: 25%;\n    &:hover{\n      cursor: pointer;\n    }\n  '], ['\n    width: 25%;\n    &:hover{\n      cursor: pointer;\n    }\n  ']),
    _templateObject21 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n  ', ';\n'], ['\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n  ', ';\n']),
    _templateObject22 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    padding: 0;\n  '], ['\n    flex-direction: column;\n    padding: 0;\n  ']),
    _templateObject23 = (0, _taggedTemplateLiteral3.default)(['\n  display: block;\n  width: 100%;\n  height: 1px;\n  margin: 20px 0;\n  border-top: 1px solid #dedede;\n  justify-content: center;\n'], ['\n  display: block;\n  width: 100%;\n  height: 1px;\n  margin: 20px 0;\n  border-top: 1px solid #dedede;\n  justify-content: center;\n']),
    _templateObject24 = (0, _taggedTemplateLiteral3.default)(['\n  display: block;\n  height: 220px;\n  border: 0;\n  border-left: 1px solid #dedede;\n  margin: 0;\n  padding: 0;\n  justify-content: center;\n  width: 1%;\n'], ['\n  display: block;\n  height: 220px;\n  border: 0;\n  border-left: 1px solid #dedede;\n  margin: 0;\n  padding: 0;\n  justify-content: center;\n  width: 1%;\n']),
    _templateObject25 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 25px;\n  ', ';\n'], ['\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 25px;\n  ', ';\n']),
    _templateObject26 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 20px;\n  '], ['\n    font-size: 20px;\n  ']),
    _templateObject27 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 15px;\n  line-height: 30px;\n  color: #474747;\n  ', ';\n'], ['\n  font-size: 15px;\n  line-height: 30px;\n  color: #474747;\n  ', ';\n']),
    _templateObject28 = (0, _taggedTemplateLiteral3.default)(['\n    padding-left: 5px;\n    font-size: 15px;\n  '], ['\n    padding-left: 5px;\n    font-size: 15px;\n  ']),
    _templateObject29 = (0, _taggedTemplateLiteral3.default)(['\n  font-size: 15px;\n  font-weight: bold;\n  line-height: 30px;\n  ', ';\n'], ['\n  font-size: 15px;\n  font-weight: bold;\n  line-height: 30px;\n  ', ';\n']),
    _templateObject30 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 14px;\n  '], ['\n    font-size: 14px;\n  ']);

exports.default = MyAccountTestDrive;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _MyAccountHeading = require('./MyAccountHeading');

var _MyAccountHeading2 = _interopRequireDefault(_MyAccountHeading);

var _Button = require('../Global/Button');

var _Button2 = _interopRequireDefault(_Button);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Mail = require('../JaguarIcons/Global/Mail');

var _Mail2 = _interopRequireDefault(_Mail);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var namedDiv = function namedDiv(displayName) {
  return _styledComponents2.default.div.withConfig({ displayName: displayName });
};

var DesktopView = namedDiv('DesktopView')(_templateObject, _theme2.default.max.medium(_templateObject2));

var MobileView = namedDiv('MobileView')(_templateObject3, _theme2.default.max.medium(_templateObject4));

var MyAccountContent = namedDiv('MyAccountContent')(_templateObject5);

var TileItemContainer = namedDiv('TileItemContainer')(_templateObject6, _theme2.default.min.large(_templateObject7));

var DetailsContainer = namedDiv('DetailsContainer')(_templateObject8, _theme2.default.min.large(_templateObject9));

var HeadingContainer = namedDiv('HeadingContainer')(_templateObject10, _theme2.default.min.large(_templateObject11));

var ModelName = namedDiv('ModelName')(_templateObject12);

var SubHeading = namedDiv('SubHeading')(_templateObject13);

var BottomContainer = namedDiv('BottomContainer')(_templateObject14, _theme2.default.min.large(_templateObject15));

var InfoContainer = namedDiv('BottomContainer')(_templateObject16);

var TableWrap = namedDiv('TableWrap')(_templateObject17);

var TableRow = namedDiv('TableRow')(_templateObject18);

var ImageContainer = namedDiv('ImageContainer')(_templateObject19, _theme2.default.min.large(_templateObject20));

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'ImageOverlay'
}).withConfig({
  displayName: 'MyAccountTestDrives__ImageOverlay',
  componentId: 'sc-8f1kho-0'
})(['justify-content:center;align-items:center;display:flex;flex-direction:column;position:absolute;top:0;left:0;right:0;bottom:0;color:#ffffff;background-color:rgb(0,0,0,0.5);cursor:default;']);

var SoldHeading = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountTestDrives__SoldHeading',
  componentId: 'sc-8f1kho-1'
})(['font-size:20px;font-weight:600;']);

var SoldBody = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountTestDrives__SoldBody',
  componentId: 'sc-8f1kho-2'
})(['font-size:14px;max-width:165px;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'MyAccountTestDrives__Image',
  componentId: 'sc-8f1kho-3'
})(['height:100%;width:100%;object-fit:cover;']);

var ButtonContainer = namedDiv('ButtonContainer')(_templateObject21, _theme2.default.min.large(_templateObject22));

var HorizontalLine = namedDiv('HorizontalLine')(_templateObject23);

var VerticalLine = namedDiv('VerticalLine')(_templateObject24);

var RetailerName = namedDiv('RetailerName')(_templateObject25, _theme2.default.max.small(_templateObject26));

var RetailerAddress = namedDiv('RetailerAddress')(_templateObject27, _theme2.default.max.small(_templateObject28));

var RetailerPhone = namedDiv('RetailerPhone')(_templateObject29, _theme2.default.max.small(_templateObject30));

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountTestDrives__IconWrapper',
  componentId: 'sc-8f1kho-4'
})(['display:flex;align-items:center;justify-content:center;']);

function labelWithIcon(label, iconColour, Icon) {
  return _react2.default.createElement(
    IconWrapper,
    null,
    _react2.default.createElement(Icon, { width: '2em', height: '2em', colour: iconColour }),
    _react2.default.createElement(
      'div',
      { style: { marginLeft: '8px' } },
      label
    )
  );
}

var TestDriveInfo = function TestDriveInfo(_ref) {
  var vehicle = _ref.vehicle,
      placeholdingImage = _ref.placeholdingImage,
      _ref$dealer = _ref.dealer,
      name = _ref$dealer.name,
      address = _ref$dealer.address,
      phone = _ref$dealer.phone,
      onContactClick = _ref.onContactClick,
      onDeleteTestDriveClick = _ref.onDeleteTestDriveClick,
      translations = _ref.translations,
      goToVdp = _ref.goToVdp,
      colours = _ref.globalStyling.colours;
  return _react2.default.createElement(
    TileItemContainer,
    null,
    _react2.default.createElement(
      ImageContainer,
      { onClick: function onClick() {
          return goToVdp((0, _extends3.default)({}, vehicle));
        } },
      vehicle.imageUrl ? _react2.default.createElement(Image, { src: vehicle.imageUrl, alt: vehicle.imageUrl }) : _react2.default.createElement(Image, { src: placeholdingImage, alt: placeholdingImage }),
      vehicle.status === 'sold' && _react2.default.createElement(
        ImageOverlay,
        null,
        _react2.default.createElement(
          SoldHeading,
          null,
          translations.sold
        ),
        _react2.default.createElement(
          SoldBody,
          null,
          translations.similarVehiclesAvailable
        )
      )
    ),
    _react2.default.createElement(
      DetailsContainer,
      null,
      _react2.default.createElement(
        HeadingContainer,
        null,
        _react2.default.createElement(
          ModelName,
          { onClick: function onClick() {
              return goToVdp((0, _extends3.default)({}, vehicle));
            } },
          vehicle.year,
          ' ',
          vehicle.model
        ),
        _react2.default.createElement(
          SubHeading,
          null,
          vehicle.odometer.display + ' | ' + vehicle.engine + ' | ' + vehicle.transmission + ' | ' + vehicle.fuel
        )
      ),
      _react2.default.createElement(
        InfoContainer,
        null,
        _react2.default.createElement(
          TableWrap,
          null,
          _react2.default.createElement(
            TableRow,
            null,
            _react2.default.createElement(
              RetailerName,
              null,
              name
            )
          ),
          _react2.default.createElement(
            TableRow,
            null,
            _react2.default.createElement(
              RetailerAddress,
              null,
              address
            )
          ),
          _react2.default.createElement(
            RetailerPhone,
            null,
            translations.myAccountRetailerPhone,
            '\xA0',
            phone
          ),
          _react2.default.createElement(TableRow, null)
        )
      )
    ),
    _react2.default.createElement(VerticalLine, null),
    _react2.default.createElement(
      BottomContainer,
      null,
      _react2.default.createElement(
        ButtonContainer,
        null,
        vehicle.status !== 'sold' && _react2.default.createElement(_Button2.default, {
          'data-cy': 'contact-us-button',
          applyStyle: 'secondary',
          styleOverride: function styleOverride() {
            return '\n                    width: 100%;\n                  ';
          },
          text: labelWithIcon(translations.myAccountRetailerContact, colours && colours.primaryBrandColour.value, _Mail2.default),
          onClick: function onClick() {
            return onContactClick(vehicle);
          },
          buttonStyle: 'ButtonStyleBTT'
        }) || _react2.default.createElement(_Button2.default, {
          'data-cy': 'delete-button',
          applyStyle: 'secondary',
          styleOverride: function styleOverride() {
            return '\n                    width: 100%;\n                  ';
          },
          text: translations.myAccountDealDelete,
          onClick: function onClick() {
            return onDeleteTestDriveClick(vehicle);
          },
          buttonStyle: 'ButtonStyle2'
        })
      )
    )
  );
};

var TestDriveInfoMobile = function TestDriveInfoMobile(_ref2) {
  var vehicle = _ref2.vehicle,
      placeholdingImage = _ref2.placeholdingImage,
      _ref2$dealer = _ref2.dealer,
      name = _ref2$dealer.name,
      address = _ref2$dealer.address,
      phone = _ref2$dealer.phone,
      onContactClick = _ref2.onContactClick,
      translations = _ref2.translations,
      onDeleteTestDriveClick = _ref2.onDeleteTestDriveClick,
      goToVdp = _ref2.goToVdp,
      colours = _ref2.globalStyling.colours;
  return _react2.default.createElement(
    TileItemContainer,
    null,
    _react2.default.createElement(
      HeadingContainer,
      null,
      _react2.default.createElement(
        ModelName,
        { onClick: function onClick() {
            return goToVdp((0, _extends3.default)({}, vehicle));
          } },
        vehicle.year,
        ' ',
        vehicle.model
      ),
      _react2.default.createElement(
        SubHeading,
        null,
        vehicle.odometer.display + ' | ' + vehicle.engine + ' | ' + vehicle.transmission + ' | ' + vehicle.fuel
      )
    ),
    _react2.default.createElement(
      ImageContainer,
      { onClick: function onClick() {
          return goToVdp((0, _extends3.default)({}, vehicle));
        } },
      vehicle.imageUrl ? _react2.default.createElement(Image, { src: vehicle.imageUrl, alt: vehicle.imageUrl }) : _react2.default.createElement(Image, { src: placeholdingImage, alt: placeholdingImage }),
      vehicle.status === 'sold' && _react2.default.createElement(
        ImageOverlay,
        null,
        _react2.default.createElement(
          SoldHeading,
          null,
          translations.sold
        ),
        _react2.default.createElement(
          SoldBody,
          null,
          translations.similarVehiclesAvailable
        )
      )
    ),
    _react2.default.createElement(
      BottomContainer,
      null,
      _react2.default.createElement(
        InfoContainer,
        null,
        _react2.default.createElement(
          TableWrap,
          null,
          _react2.default.createElement(
            TableRow,
            null,
            _react2.default.createElement(
              RetailerName,
              null,
              name
            )
          ),
          _react2.default.createElement(
            TableRow,
            null,
            _react2.default.createElement(
              RetailerAddress,
              null,
              address
            )
          ),
          _react2.default.createElement(
            RetailerPhone,
            null,
            translations.myAccountRetailerPhone,
            ':\xA0',
            phone
          ),
          _react2.default.createElement(TableRow, null)
        )
      ),
      _react2.default.createElement(HorizontalLine, null),
      _react2.default.createElement(
        ButtonContainer,
        null,
        vehicle.status !== 'sold' && _react2.default.createElement(_Button2.default, {
          'data-cy': 'contact-us-button',
          applyStyle: 'secondary',
          styleOverride: function styleOverride() {
            return '\n                  width: 100%;\n                ';
          },
          text: labelWithIcon(translations.myAccountRetailerContact, colours && colours.primaryBrandColour.value, _Mail2.default),
          onClick: function onClick() {
            return onContactClick(vehicle);
          },
          buttonStyle: 'ButtonStyleBTT'
        }) || _react2.default.createElement(_Button2.default, {
          'data-cy': 'delete-button',
          applyStyle: 'secondary',
          styleOverride: function styleOverride() {
            return '\n                width: 100%;\n              ';
          },
          text: translations.myAccountDealDelete,
          onClick: function onClick() {
            return onDeleteTestDriveClick(vehicle);
          },
          buttonStyle: 'ButtonStyle2'
        })
      )
    )
  );
};

function MyAccountTestDrive(props) {
  var goToVdpIfNotSold = function goToVdpIfNotSold(vehicle) {
    return vehicle.status !== 'sold' && props.goToVdp(vehicle);
  };
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(_MyAccountHeading2.default, {
      heading: props.translations.myAccountTestDrivesHeading,
      subHeading: props.translations.myAccountTestDrivesSubheading
    }),
    _react2.default.createElement(
      MyAccountContent,
      null,
      _react2.default.createElement(
        DesktopView,
        null,
        props.testDrives.map(function (testDrive) {
          return _react2.default.createElement(TestDriveInfo, (0, _extends3.default)({
            key: testDrive.vehicle.vin
          }, testDrive, {
            onContactClick: props.onContactClick,
            onDeleteTestDriveClick: props.onDeleteTestDriveClick,
            translations: props.translations,
            goToVdp: goToVdpIfNotSold,
            globalStyling: props.globalStyling
          }));
        })
      ),
      _react2.default.createElement(
        MobileView,
        null,
        props.testDrives.map(function (testDrive) {
          return _react2.default.createElement(TestDriveInfoMobile, (0, _extends3.default)({
            key: testDrive.vehicle.vin
          }, testDrive, {
            onContactClick: props.onContactClick,
            onDeleteTestDriveClick: props.onDeleteTestDriveClick,
            translations: props.translations,
            goToVdp: goToVdpIfNotSold,
            globalStyling: props.globalStyling
          }));
        })
      )
    )
  );
}