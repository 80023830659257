'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.normaliseGlobal = exports.normalisePages = exports.normalisePage = undefined;

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _normalizr = require('normalizr');

var _configSchema = require('./configSchema');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var normalisePage = function normalisePage(page) {
  var _normalize = (0, _normalizr.normalize)(page, _configSchema.pageEntity),
      pages = _normalize.entities.pages;

  return (0, _values2.default)(pages)[0];
};
var normalisePages = function normalisePages(pages) {
  return (0, _normalizr.normalize)(pages, _configSchema.pageArray);
};
var normaliseGlobal = function normaliseGlobal(modules) {
  return (0, _normalizr.normalize)(modules, _configSchema.globalArray);
};

exports.normalisePage = normalisePage;
exports.normalisePages = normalisePages;
exports.normaliseGlobal = normaliseGlobal;