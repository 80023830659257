'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _modelTypes = require('../../../types/Bentley/modelTypes');

var _DragSlider = require('../../DragSlider');

var _ModelItem = require('./ModelItem');

var _ModelItem2 = _interopRequireDefault(_ModelItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ModelVariantTile = function ModelVariantTile(_ref) {
  var section = _ref.section,
      config = _ref.config,
      width = _ref.width,
      onTileSelected = _ref.onTileSelected,
      isTileSelected = _ref.isTileSelected;

  var isDesktop = (0, _useCheckIsMobileScreen2.default)('large');

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      _DragSlider.DragSlider,
      {
        styles: (0, _extends3.default)({
          gap: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          height: '555px'
        }, width ? { maxWidth: width } : {}, {
          margin: '0 auto',
          padding: '0 10px'
        }),
        aditionalRightMargin: isDesktop ? 20 : 40
      },
      section.tiles.map(function (tile) {
        return _react2.default.createElement(_ModelItem2.default, {
          key: tile.tileId,
          translations: config.translations,
          tile: tile,
          onTileSelected: onTileSelected,
          isChecked: isTileSelected(tile.tileId)
        });
      })
    )
  );
};

exports.default = _react2.default.memo(ModelVariantTile);