'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 20px;\n  '], ['\n    padding: 0 20px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding-right: 140px;\n  '], ['\n    padding-right: 140px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n      margin-top: 31px;\n  '], ['\n      margin-top: 31px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n    width: 100%;\n  '], ['\n    display: block;\n    width: 100%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AppShared = require('../../../types/AppShared');

var _searchResultsTypes = require('../../../types/AstonMartin/searchResultsTypes');

var _globalStyling = require('../../../types/globalStyling');

var _MarketInfo = require('../../../types/MarketInfo');

var _Spinner = require('../../Spinner');

var _SelectBar = require('../SelectBar');

var _SelectBar2 = _interopRequireDefault(_SelectBar);

var _FiltersContainer = require('./Filters/FiltersContainer');

var _FiltersContainer2 = _interopRequireDefault(_FiltersContainer);

var _MobileFilters = require('./Filters/MobileFilters');

var _MobileFilters2 = _interopRequireDefault(_MobileFilters);

var _VehicleResults = require('./VehicleResults');

var _VehicleResults2 = _interopRequireDefault(_VehicleResults);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Container',
  componentId: 'sc-jn8m7r-0'
})(['display:flex;flex-direction:column;width:100%;font-family:AstonMartinFlare-Regular;text-transform:uppercase;align-items:center;max-width:1920px;']);

var Grid = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Grid',
  componentId: 'sc-jn8m7r-1'
})(['@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){min-height:100%;}width:100%;display:flex;justify-content:space-between;flex-direction:row;box-sizing:border-box;padding:0 140px 0 50px;', ';', ';'], _theme2.default.max.large(_templateObject), _theme2.default.max.medium(_templateObject2));
var GridColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__GridColumn',
  componentId: 'sc-jn8m7r-2'
})(['display:flex;flex-direction:column;max-width:380px;min-width:300px;padding-right:30px;', ';', ';'], _theme2.default.min.xxl(_templateObject3), _theme2.default.max.medium(_templateObject4));
var GridResultColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__GridResultColumn',
  componentId: 'sc-jn8m7r-3'
})(['display:flex;flex-direction:column;width:100%;', ';'], _theme2.default.min.medium(_templateObject5));

var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__MobileOnly',
  componentId: 'sc-jn8m7r-4'
})(['display:none;', ';'], _theme2.default.max.medium(_templateObject6));

var SearchResultHeader = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__SearchResultHeader',
  componentId: 'sc-jn8m7r-5'
})(['display:flex;align-items:center;justify-content:space-between;padding:10px 0;', ';'], _theme2.default.max.medium(_templateObject4));

var ResultsSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__ResultsSectionWrapper',
  componentId: 'sc-jn8m7r-6'
})(['min-height:1px;']);

var VehicleResultsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__VehicleResultsWrapper',
  componentId: 'sc-jn8m7r-7'
})(['']);

var FiltersContainerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__FiltersContainerWrapper',
  componentId: 'sc-jn8m7r-8'
})(['margin-top:70px;']);

var ResultsCounter = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__ResultsCounter',
  componentId: 'sc-jn8m7r-9'
})(['font-size:14px;color:#000000;']);
var MobileResultsCounter = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__MobileResultsCounter',
  componentId: 'sc-jn8m7r-10'
})(['font-size:12px;color:#000000;']);

var MobileContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__MobileContainer',
  componentId: 'sc-jn8m7r-11'
})(['padding:10px 20px;']);

var ResultsSection = function ResultsSection(_ref) {
  var config = _ref.config,
      translations = _ref.translations,
      results = _ref.results,
      onMoreInfoClick = _ref.onMoreInfoClick,
      onPageNumberChange = _ref.onPageNumberChange,
      marketInfo = _ref.marketInfo,
      currentPage = _ref.currentPage,
      pageSize = _ref.pageSize,
      totalResults = _ref.totalResults,
      getSimilarCars = _ref.getSimilarCars,
      shared = _ref.shared;
  return _react2.default.createElement(
    ResultsSectionWrapper,
    null,
    _react2.default.createElement(
      VehicleResultsWrapper,
      null,
      _react2.default.createElement(_VehicleResults2.default, {
        config: config,
        results: results,
        onMoreInfoClick: onMoreInfoClick,
        translations: translations,
        locale: marketInfo.locale,
        currentPage: currentPage,
        pageSize: pageSize,
        totalResults: totalResults,
        onPageNumberChange: onPageNumberChange,
        getSimilarCars: getSimilarCars,
        shared: shared
      })
    )
  );
};

var Filters = function Filters(_ref2) {
  var filtersConfig = _ref2.filtersConfig,
      globalStyling = _ref2.globalStyling,
      toggle = _ref2.toggle,
      marketInfo = _ref2.marketInfo,
      shared = _ref2.shared,
      translations = _ref2.translations;
  return _react2.default.createElement(_FiltersContainer2.default, (0, _extends3.default)({}, filtersConfig, {
    globalStyling: globalStyling,
    locale: marketInfo.locale,
    toggle: toggle,
    currencyCode: marketInfo.currencyCode,
    marketInfo: marketInfo,
    shared: shared,
    searchCountryCode: marketInfo.searchCountryCode,
    hideSearch: true,
    translations: translations
  }));
};

var SearchResults = function (_Component) {
  (0, _inherits3.default)(SearchResults, _Component);

  function SearchResults(props) {
    (0, _classCallCheck3.default)(this, SearchResults);

    var _this = (0, _possibleConstructorReturn3.default)(this, (SearchResults.__proto__ || (0, _getPrototypeOf2.default)(SearchResults)).call(this, props));

    Object.defineProperty(_this, 'toggle', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.setState(function (_ref3) {
          var displayMobileFilterOptions = _ref3.displayMobileFilterOptions;
          return {
            displayMobileFilterOptions: !displayMobileFilterOptions
          };
        });
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            config = _this$props.config,
            filtersConfig = _this$props.filtersConfig,
            globalStyling = _this$props.globalStyling,
            translations = _this$props.translations,
            activeFiltersCount = _this$props.activeFiltersCount,
            marketInfo = _this$props.marketInfo,
            loading = _this$props.loading,
            filtersLoaded = _this$props.filtersLoaded,
            totalResults = _this$props.totalResults,
            shared = _this$props.shared;
        var displayMobileFilterOptions = _this.state.displayMobileFilterOptions;

        var filters = _react2.default.createElement(Filters, {
          filtersConfig: filtersConfig,
          globalStyling: globalStyling,
          toggle: function toggle() {
            return _this.toggle();
          },
          marketInfo: marketInfo,
          shared: shared,
          displayMobileFilterOptions: displayMobileFilterOptions,
          translations: translations
        });
        return _react2.default.createElement(
          Container,
          { 'data-cy': 'search-results' },
          _react2.default.createElement(
            MobileOnly,
            null,
            _react2.default.createElement(_MobileFilters2.default, {
              filters: filters,
              translations: translations,
              activeFiltersCount: activeFiltersCount,
              globalStyling: globalStyling,
              config: config
            }),
            _react2.default.createElement(
              MobileContainer,
              null,
              _react2.default.createElement(
                MobileResultsCounter,
                null,
                totalResults + ' ' + translations.totalCount
              ),
              _react2.default.createElement(_SelectBar2.default, {
                searchSort: shared.sessionPreferences.searchSort,
                currencies: config.currencies,
                currencyValue: shared.sessionPreferences.currency,
                translations: config.translations,
                fullWidth: true
              })
            )
          ),
          _react2.default.createElement(
            Grid,
            null,
            _react2.default.createElement(
              _Spinner.SpinnerWrapper,
              {
                loaded: filtersLoaded,
                globalStyling: globalStyling,
                label: translations.loadingText
              },
              function () {
                return _react2.default.createElement(
                  _react.Fragment,
                  null,
                  _react2.default.createElement(
                    GridColumn,
                    null,
                    _react2.default.createElement(
                      FiltersContainerWrapper,
                      null,
                      filters
                    )
                  ),
                  _react2.default.createElement(
                    GridResultColumn,
                    { 'data-cy': 'search-result-container-grid-column' },
                    _react2.default.createElement(
                      _Spinner.SpinnerWrapper,
                      {
                        loaded: !loading,
                        globalStyling: globalStyling,
                        label: translations.loadingText
                      },
                      function () {
                        return _react2.default.createElement(
                          _react.Fragment,
                          null,
                          _react2.default.createElement(
                            SearchResultHeader,
                            null,
                            _react2.default.createElement(
                              ResultsCounter,
                              null,
                              totalResults + ' ' + translations.totalCount
                            ),
                            _react2.default.createElement(_SelectBar2.default, {
                              searchSort: shared.sessionPreferences.searchSort,
                              currencyValue: shared.sessionPreferences.currency,
                              currencies: config.currencies,
                              translations: config.translations
                            })
                          ),
                          _react2.default.createElement(ResultsSection, _this.props)
                        );
                      }
                    )
                  )
                );
              }
            )
          )
        );
      }
    });

    _this.state = {
      displayMobileFilterOptions: false
    };
    return _this;
  }

  return SearchResults;
}(_react.Component);

exports.default = SearchResults;