'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DragSlider = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _framerMotion = require('framer-motion');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Carousel = _styledComponents2.default.div.withConfig({
  displayName: 'DragSlider__Carousel',
  componentId: 'sc-1kxnd66-0'
})(['overflow-x:scroll;cursor:ew-resize;height:100%;-ms-overflow-style:none;scrollbar-width:none;&::-webkit-scrollbar{display:none;}']);
var Slider = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'DragSlider__Slider',
  componentId: 'sc-1kxnd66-1'
})(['display:flex;']);

var SliderWrap = function SliderWrap(_ref) {
  var children = _ref.children,
      sliderRef = _ref.sliderRef,
      sliderConstraints = _ref.sliderConstraints,
      bounceStiffness = _ref.bounceStiffness,
      bounceDamping = _ref.bounceDamping,
      animate = _ref.animate,
      transition = _ref.transition,
      styles = _ref.styles,
      dragElastic = _ref.dragElastic,
      onDrag = _ref.onDrag,
      onDragEnd = _ref.onDragEnd,
      x = _ref.x;

  var isMobile = (0, _useCheckIsMobileScreen2.default)('medium');
  return _react2.default.createElement(
    Carousel,
    null,
    _react2.default.createElement(
      Slider,
      {
        ref: sliderRef,
        drag: isMobile ? false : 'x',
        initial: { x: 0 },
        animate: animate,
        transition: transition,
        style: (0, _extends3.default)({}, styles, { x: x }),
        dragConstraints: {
          left: -sliderConstraints,
          right: 0
        },
        dragElastic: dragElastic,
        dragTransition: { bounceStiffness: bounceStiffness, bounceDamping: bounceDamping },
        onDrag: onDrag,
        onDragEnd: onDragEnd
      },
      children
    )
  );
};

var DragSlider = exports.DragSlider = function DragSlider(_ref2) {
  var children = _ref2.children,
      _ref2$bounceStiffness = _ref2.bounceStiffness,
      bounceStiffness = _ref2$bounceStiffness === undefined ? 100 : _ref2$bounceStiffness,
      _ref2$bounceDamping = _ref2.bounceDamping,
      bounceDamping = _ref2$bounceDamping === undefined ? 10 : _ref2$bounceDamping,
      _ref2$styles = _ref2.styles,
      styles = _ref2$styles === undefined ? {} : _ref2$styles,
      _ref2$animate = _ref2.animate,
      animate = _ref2$animate === undefined ? {} : _ref2$animate,
      _ref2$transition = _ref2.transition,
      transition = _ref2$transition === undefined ? {} : _ref2$transition,
      _ref2$aditionalRightM = _ref2.aditionalRightMargin,
      aditionalRightMargin = _ref2$aditionalRightM === undefined ? 0 : _ref2$aditionalRightM,
      onDrag = _ref2.onDrag,
      onDragEnd = _ref2.onDragEnd,
      _ref2$dragElastic = _ref2.dragElastic,
      dragElastic = _ref2$dragElastic === undefined ? 0.05 : _ref2$dragElastic;

  var carousel = (0, _react.useRef)();
  var x = (0, _framerMotion.useMotionValue)(0);

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      sliderConstraints = _useState2[0],
      setSliderConstraints = _useState2[1];

  (0, _react.useEffect)(function () {
    var calcSliderConstraints = function calcSliderConstraints() {
      if (!carousel.current) {
        return;
      }

      var div = carousel.current;
      var leftMarginConstrain = div.scrollWidth - window.innerWidth;

      setSliderConstraints(leftMarginConstrain < 0 ? 0 : leftMarginConstrain + aditionalRightMargin);
    };

    calcSliderConstraints();
    window.addEventListener('resize', calcSliderConstraints);
    return function () {
      window.removeEventListener('resize', calcSliderConstraints);
    };
  }, []);

  return _react2.default.createElement(
    SliderWrap,
    {
      sliderRef: carousel,
      sliderConstraints: sliderConstraints,
      bounceStiffness: bounceStiffness,
      bounceDamping: bounceDamping,
      styles: styles,
      animate: animate,
      transition: transition,
      onDrag: onDrag,
      onDragEnd: onDragEnd,
      dragElastic: dragElastic,
      x: x
    },
    children
  );
};