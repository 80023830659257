'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Mileage;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Mileage(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 40 40' },
      _react2.default.createElement('path', { d: 'M19.148 25.077a1.388 1.388 0 0 0 1.298-.67 1.425 1.425 0 0 0 0-1.473 1.388 1.388 0 0 0-1.298-.669l-6.845-4.684 5.334 6.178a1.471 1.471 0 0 0 1.511 1.318zm-8.193 4.15h15.09v3.332h-15.09v-3.331zm20.626-17.186a19.493 19.493 0 0 0-26.16 0A16.523 16.523 0 0 0 0 24.213c0 2.944.8 5.832 2.313 8.346h3.458L8.3 31.202 7.183 29.4 3.93 31.15a14.31 14.31 0 0 1-1.737-8.25l3.878.639.388-2.05-3.923-.643A14.994 14.994 0 0 1 7 13.386l2.658 2.948 1.711-1.336-2.678-2.97a17.022 17.022 0 0 1 8.691-3.005v3.94h2.234v-3.94a17.022 17.022 0 0 1 8.691 3.005l-2.678 2.97 1.71 1.336L30 13.385a14.998 14.998 0 0 1 4.464 7.461l-3.923.643.388 2.05 3.877-.64a14.314 14.314 0 0 1-1.736 8.25l-3.252-1.75-1.117 1.803 2.529 1.357h3.458A16.201 16.201 0 0 0 37 24.213a16.527 16.527 0 0 0-5.419-12.172z' })
    )
  );
}

Mileage.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};