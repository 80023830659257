'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends4 = require('babel-runtime/helpers/extends');

var _extends5 = _interopRequireDefault(_extends4);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_SEARCH_RESULTS';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['SHOW_ALL', 'GET_INVENTORY', 'GET_INVENTORY_SUCCESS', 'INIT', 'GET_FINANCE_SUCCESS', 'GET_DEALER', 'GET_DEALER_SUCCESS', 'CLEAR_ALL', 'CHANGE_PAGE', 'LOAD_FINANCE', 'CANCEL_LOADING', 'CLEAR_LOADED']);

var id = exports.id = 'SearchResults';

var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  showAll: (0, _reducerMacros.moduleActionWithArgs)(constants.SHOW_ALL, id, function (payload) {
    return payload;
  }),
  getInventory: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY, id, function (payload) {
    return payload;
  }),
  getInventorySuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY_SUCCESS, id, function (config) {
    return config;
  }),
  getFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  }),
  getDealer: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_DEALER, id, function (dealerId) {
    return dealerId;
  }),
  getDealerSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_DEALER_SUCCESS, id, function (dealer) {
    return dealer;
  }),
  changePage: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_PAGE, id, function (payload) {
    return payload;
  }),
  loadFinance: (0, _reducerMacros.moduleActionWithArgs)(constants.LOAD_FINANCE, id, function (payload) {
    return payload;
  }),
  cancelLoading: (0, _reducerMacros.moduleActionWithArgs)(constants.CANCEL_LOADING, id, function (payload) {
    return payload;
  }),
  clearLoaded: (0, _reducerMacros.moduleAction)(constants.CLEAR_LOADED, id)
};

var replaceIfExists = function replaceIfExists(vehicles, vehicle) {
  return [].concat((0, _toConsumableArray3.default)(vehicles.map(function (v) {
    return v.id === vehicle.id ? vehicle : v;
  })));
};

var initialState = exports.initialState = {
  infiniteScroll: false,
  loading: false,
  searchResults: {},
  pageSize: 9,
  currentPage: 1,
  dealer: {}
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.SHOW_ALL:
      {
        return (0, _extends5.default)({}, state, {
          infiniteScroll: true
        });
      }
    case constants.CLEAR_LOADED:
      {
        return (0, _extends5.default)({}, state, {
          searchResults: {},
          totalResults: null,
          infiniteScroll: false,
          currentPage: 1,
          loading: false
        });
      }
    case constants.CANCEL_LOADING:
      {
        var resetToFirstPage = action.payload;
        return (0, _extends5.default)({}, state, {
          loading: false,
          infiniteScroll: false
        }, resetToFirstPage && {
          currentPage: 1
        });
      }
    case constants.GET_INVENTORY:
      {
        return (0, _extends5.default)({}, state, {
          loading: true,
          filters: action.payload.filters
        });
      }
    case constants.CHANGE_PAGE:
      {
        return (0, _extends5.default)({}, state, {
          currentPage: action.payload,
          loading: false
        });
      }
    case constants.GET_INVENTORY_SUCCESS:
      {
        var _action$payload = action.payload,
            pageNumber = _action$payload.pageNumber,
            contents = _action$payload.contents,
            total = _action$payload.total;

        return (0, _extends5.default)({}, state, {
          loading: false,
          searchResults: (0, _extends5.default)({}, state.searchResults, (0, _defineProperty3.default)({}, pageNumber, contents)),
          totalResults: total,
          currentPage: pageNumber
        });
      }
    case constants.GET_FINANCE_SUCCESS:
      {
        var _action$payload2 = action.payload,
            vehicle = _action$payload2.vehicle,
            _pageNumber = _action$payload2.pageNumber;

        return (0, _extends5.default)({}, state, {
          searchResults: (0, _extends5.default)({}, state.searchResults, (0, _defineProperty3.default)({}, _pageNumber, replaceIfExists(state.searchResults[_pageNumber] || [], vehicle)))
        });
      }
    case constants.GET_DEALER_SUCCESS:
      {
        return (0, _extends5.default)({}, state, {
          dealer: action.payload
        });
      }
    default:
      return state;
  }
}