'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var translateMonthName = function translateMonthName(month, locale) {
  var dateOptions = {
    month: 'long'
  };

  var _locale$split = locale.split('_'),
      _locale$split2 = (0, _slicedToArray3.default)(_locale$split, 2),
      firstChars = _locale$split2[0],
      lastChars = _locale$split2[1];

  var language = firstChars + '-' + lastChars.toUpperCase();

  // Any year and day will do as we only required a month value to be returned
  return new Date(2019, month, 1).toLocaleDateString(language, dateOptions);
};

var useTranslateMonthName = function useTranslateMonthName(month, locale) {
  return (0, _react.useMemo)(function () {
    return translateMonthName(month, locale);
  }, [month, locale]);
};

exports.default = useTranslateMonthName;