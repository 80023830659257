'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    margin-top: 60px;\n  '], ['\n    width: 100%;\n    margin-top: 60px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _MyAccountHeading = require('./MyAccountHeading');

var _MyAccountHeading2 = _interopRequireDefault(_MyAccountHeading);

var _MyAccountContent = require('./MyAccountContent');

var _MyAccountContent2 = _interopRequireDefault(_MyAccountContent);

var _FormFields = require('../FormFields');

var _Global = require('../Global');

var _validation = require('../../helpers/validation');

var _formTitleOptions = require('../../helpers/formTitleOptions');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDetails__Content',
  componentId: 'sc-462pds-0'
})(['display:flex;min-height:450px;padding:60px 0 150px 0;', ';'], _theme2.default.max.large(_templateObject));

var UserDetails = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDetails__UserDetails',
  componentId: 'sc-462pds-1'
})(['width:50%;height:100%;box-sizing:border-box;', ';'], _theme2.default.max.large(_templateObject2));

var UserPasswordDetails = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDetails__UserPasswordDetails',
  componentId: 'sc-462pds-2'
})(['width:50%;height:100%;', ';'], _theme2.default.max.large(_templateObject3));

var DoubleRow = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDetails__DoubleRow',
  componentId: 'sc-462pds-3'
})(['display:flex;justify-content:space-between;', ';'], _theme2.default.max.large(_templateObject4));

var MobileSingleRow = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDetails__MobileSingleRow',
  componentId: 'sc-462pds-4'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject));

var VerticalLine = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDetails__VerticalLine',
  componentId: 'sc-462pds-5'
})(['height:inherit;border-left:1px solid #d8d8d8;margin:0 35px;', ';'], _theme2.default.max.large(_templateObject4));

var HorizontalLine = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDetails__HorizontalLine',
  componentId: 'sc-462pds-6'
})(['width:100%;border-top:1px solid #d8d8d8;box-sizing:border-box;margin:', 'px 0;'], function (props) {
  return props.marginSize;
});

var DeleteMessage = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDetails__DeleteMessage',
  componentId: 'sc-462pds-7'
})(['font-weight:600;color:#444;margin-bottom:20px;font-size:16px;']);

var FeedbackMessage = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDetails__FeedbackMessage',
  componentId: 'sc-462pds-8'
})(['color:', ';font-weight:600;'], function (props) {
  return props.success ? '#68B414' : '#9E1B32';
});

function validateDetails(formKey, value, translations) {
  var nonValidatedKeys = ['email', 'postCode', 'vehicleOfInterest', 'currentVehicle', 'phoneConsent', 'smsConsent', 'emailConsent', 'errors', 'currentPassword', 'newPassword', 'newPasswordConfirm', 'deleteConfirm', 'passwordOnDelete'];
  if (nonValidatedKeys.includes(formKey)) return undefined;

  var validations = {
    title: _validation.required,
    firstName: _validation.required,
    lastName: _validation.required,
    phoneNumber: _validation.validPhone
  };

  return (0, _defineProperty3.default)({}, formKey, validations[formKey](value, translations));
}
function validatePassword(formKey, value, translations) {
  var nonValidatedKeys = ['title', 'email', 'postCode', 'vehicleOfInterest', 'currentVehicle', 'phoneConsent', 'smsConsent', 'emailConsent', 'errors', 'firstName', 'lastName', 'phoneNumber', 'deleteConfirm', 'passwordOnDelete'];
  if (nonValidatedKeys.includes(formKey)) return undefined;

  var validations = {
    currentPassword: _validation.required,
    newPassword: _validation.meetsPasswordPolicy,
    newPasswordConfirm: _validation.required
  };
  return (0, _defineProperty3.default)({}, formKey, validations[formKey](value, translations, true));
}

var MyAccountDetails = function (_Component) {
  (0, _inherits3.default)(MyAccountDetails, _Component);

  function MyAccountDetails(props) {
    (0, _classCallCheck3.default)(this, MyAccountDetails);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MyAccountDetails.__proto__ || (0, _getPrototypeOf2.default)(MyAccountDetails)).call(this, props));

    _initialiseProps.call(_this);

    var _this$props$userDetai = _this.props.userDetails,
        _this$props$userDetai2 = _this$props$userDetai.title,
        title = _this$props$userDetai2 === undefined ? props.translations.formTitleSelect : _this$props$userDetai2,
        _this$props$userDetai3 = _this$props$userDetai.firstName,
        firstName = _this$props$userDetai3 === undefined ? '' : _this$props$userDetai3,
        _this$props$userDetai4 = _this$props$userDetai.lastName,
        lastName = _this$props$userDetai4 === undefined ? '' : _this$props$userDetai4,
        _this$props$userDetai5 = _this$props$userDetai.email,
        email = _this$props$userDetai5 === undefined ? '' : _this$props$userDetai5,
        _this$props$userDetai6 = _this$props$userDetai.phoneNumber,
        phoneNumber = _this$props$userDetai6 === undefined ? '' : _this$props$userDetai6,
        _this$props$userDetai7 = _this$props$userDetai.postCode,
        postCode = _this$props$userDetai7 === undefined ? '' : _this$props$userDetai7,
        _this$props$userDetai8 = _this$props$userDetai.vehiclePreferences,
        vehiclePreferences = _this$props$userDetai8 === undefined ? {
      interestedIn: '',
      currentVehicle: ''
    } : _this$props$userDetai8,
        _this$props$userDetai9 = _this$props$userDetai.contactPreferences,
        contactPreferences = _this$props$userDetai9 === undefined ? {
      sms: false,
      email: false,
      phone: false
    } : _this$props$userDetai9;


    _this.state = {
      title: title,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      postCode: postCode,
      vehicleOfInterest: vehiclePreferences.interestedIn,
      currentVehicle: vehiclePreferences.currentVehicle,
      smsConsent: contactPreferences && contactPreferences.sms,
      emailConsent: contactPreferences && contactPreferences.email,
      phoneConsent: contactPreferences && contactPreferences.phone,
      errors: {},
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      deleteConfirm: false,
      passwordOnDelete: ''
    };
    return _this;
  }

  return MyAccountDetails;
}(_react.Component);

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  Object.defineProperty(this, 'onInputChange', {
    enumerable: true,
    writable: true,
    value: function value(formKey, _value) {
      var passwordValidation = ['currentPassword', 'newPassword', 'newPasswordConfirm'];
      var error = void 0;
      if (passwordValidation.includes(formKey)) {
        error = validatePassword(formKey, _value, _this2.props.translations);
      } else {
        error = validateDetails(formKey, _value, _this2.props.translations);
      }
      _this2.setState(function (prevState) {
        var _ref3;

        return _ref3 = {}, (0, _defineProperty3.default)(_ref3, formKey, _value), (0, _defineProperty3.default)(_ref3, 'errors', (0, _extends3.default)({}, prevState.errors, error)), _ref3;
      });
    }
  });
  Object.defineProperty(this, 'onSelectChange', {
    enumerable: true,
    writable: true,
    value: function value(_ref4) {
      var keyValue = _ref4.keyValue,
          _value2 = _ref4.value;

      var error = validateDetails(keyValue, _value2, _this2.props.translations);
      _this2.setState(function (prevState) {
        var _ref5;

        return _ref5 = {}, (0, _defineProperty3.default)(_ref5, keyValue, _value2), (0, _defineProperty3.default)(_ref5, 'errors', (0, _extends3.default)({}, prevState.errors, error)), _ref5;
      });
    }
  });
  Object.defineProperty(this, 'onSaveDetails', {
    enumerable: true,
    writable: true,
    value: function value() {
      var errors = (0, _entries2.default)(_this2.state).map(function (entry) {
        return validateDetails(entry[0], entry[1], _this2.props.translations);
      }).filter(function (entry) {
        return entry !== undefined;
      }).reduce(function (acc, currVal) {
        return (0, _extends3.default)({}, acc, currVal);
      }, {});

      if ((0, _values2.default)(errors).filter(function (error) {
        return error !== undefined;
      }).length > 0) {
        window.scroll(0, 0);
        _this2.setState(function (prevState) {
          return {
            errors: (0, _extends3.default)({}, prevState.errors, errors)
          };
        });
        return;
      }
      _this2.props.saveUserDetails(_this2.state)();
    }
  });
  Object.defineProperty(this, 'onChangePassword', {
    enumerable: true,
    writable: true,
    value: function value() {
      var errors = (0, _entries2.default)(_this2.state).map(function (entry) {
        return validatePassword(entry[0], entry[1], _this2.props.translations);
      }).filter(function (entry) {
        return entry !== undefined;
      }).reduce(function (acc, currVal) {
        return (0, _extends3.default)({}, acc, currVal);
      }, {});

      if ((0, _values2.default)(errors).filter(function (error) {
        return error !== undefined;
      }).length > 0) {
        window.scroll(0, 0);
        _this2.setState(function (prevState) {
          return {
            errors: (0, _extends3.default)({}, prevState.errors, errors)
          };
        });
        return;
      }
      var _state = _this2.state,
          currentPassword = _state.currentPassword,
          newPassword = _state.newPassword,
          newPasswordConfirm = _state.newPasswordConfirm;

      var error = (0, _validation.passwordMatchesRequired)(newPassword, newPasswordConfirm, _this2.props.translations);
      if (error) {
        window.scroll(0, 0);
        _this2.setState(function (prevState) {
          return {
            errors: (0, _extends3.default)({}, prevState.errors, {
              newPasswordConfirm: error
            })
          };
        });
        return;
      }
      _this2.props.changePassword({ currentPassword: currentPassword, newPassword: newPassword })();
    }
  });
  Object.defineProperty(this, 'onDeleteAccount', {
    enumerable: true,
    writable: true,
    value: function value() {
      var password = _this2.state.passwordOnDelete;

      _this2.props.deleteAccount({ password: password })();
    }
  });
  Object.defineProperty(this, 'render', {
    enumerable: true,
    writable: true,
    value: function value() {
      var _props = _this2.props,
          translations = _props.translations,
          feedback = _props.feedback,
          globalStyling = _props.globalStyling;
      var _state2 = _this2.state,
          title = _state2.title,
          firstName = _state2.firstName,
          lastName = _state2.lastName,
          email = _state2.email,
          phoneNumber = _state2.phoneNumber,
          postCode = _state2.postCode,
          vehicleOfInterest = _state2.vehicleOfInterest,
          currentVehicle = _state2.currentVehicle,
          currentPassword = _state2.currentPassword,
          newPassword = _state2.newPassword,
          newPasswordConfirm = _state2.newPasswordConfirm,
          deleteConfirm = _state2.deleteConfirm,
          passwordOnDelete = _state2.passwordOnDelete;

      var hasError = function hasError(field) {
        return _this2.state.errors[field] && _this2.state.errors[field][0];
      };
      var formTitleOptions = (0, _formTitleOptions.titleOptions)(translations);
      var titleOption = (formTitleOptions.find(function (opt) {
        return opt.value === title;
      }) || {}).value;
      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_MyAccountHeading2.default, {
          heading: translations.myAccountDetailsHeader,
          subHeading: translations.myAccountDetailsSubHeader
        }),
        _react2.default.createElement(
          _MyAccountContent2.default,
          null,
          _react2.default.createElement(
            Content,
            null,
            _react2.default.createElement(
              UserDetails,
              null,
              _react2.default.createElement(
                DoubleRow,
                null,
                _react2.default.createElement(_FormFields.SelectField, {
                  type: 'text',
                  keyValue: 'title',
                  value: titleOption,
                  onChange: _this2.onSelectChange,
                  label: translations.formTitleLabel,
                  options: formTitleOptions,
                  error: hasError('title'),
                  doubleRow: true,
                  required: true
                })
              ),
              _react2.default.createElement(
                MobileSingleRow,
                null,
                _react2.default.createElement(_FormFields.SelectField, {
                  keyValue: 'title',
                  initialValue: titleOption,
                  onChange: _this2.onSelectChange,
                  label: translations.formTitleLabel,
                  options: formTitleOptions,
                  error: hasError('title'),
                  doubleRow: true,
                  required: true
                })
              ),
              _react2.default.createElement(
                DoubleRow,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.firstNameLabel,
                  value: firstName,
                  onChange: _this2.onInputChange,
                  keyValue: 'firstName',
                  error: hasError('firstName'),
                  doubleRow: true,
                  required: true
                }),
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.lastNameLabel,
                  value: lastName,
                  onChange: _this2.onInputChange,
                  keyValue: 'lastName',
                  error: hasError('lastName'),
                  doubleRow: true,
                  required: true
                })
              ),
              _react2.default.createElement(
                MobileSingleRow,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.firstNameLabel,
                  value: firstName,
                  onChange: _this2.onInputChange,
                  keyValue: 'firstName',
                  error: hasError('firstName'),
                  required: true
                }),
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.lastNameLabel,
                  value: lastName,
                  onChange: _this2.onInputChange,
                  keyValue: 'lastName',
                  error: hasError('lastName'),
                  required: true
                })
              ),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.emailLabel,
                value: email,
                onChange: _this2.onInputChange,
                keyValue: 'email',
                disabled: true,
                required: true
              }),
              _react2.default.createElement(
                DoubleRow,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.phoneLabel,
                  value: phoneNumber,
                  onChange: _this2.onInputChange,
                  keyValue: 'phoneNumber',
                  error: hasError('phoneNumber'),
                  doubleRow: true
                }),
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.postcodeLabel,
                  value: postCode,
                  onChange: _this2.onInputChange,
                  keyValue: 'postCode',
                  doubleRow: true
                })
              ),
              _react2.default.createElement(
                MobileSingleRow,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.phoneLabel,
                  value: phoneNumber,
                  onChange: _this2.onInputChange,
                  keyValue: 'phoneNumber',
                  error: hasError('phoneNumber')
                }),
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.postcodeLabel,
                  value: postCode,
                  onChange: _this2.onInputChange,
                  keyValue: 'postCode'
                })
              ),
              _react2.default.createElement(HorizontalLine, { marginSize: 45 }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.vehicleOfInterestLabel,
                value: vehicleOfInterest,
                onChange: _this2.onInputChange,
                keyValue: 'vehicleOfInterest'
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.currentVehicleLabel,
                placeholder: translations.currentVehiclePlaceholder,
                value: currentVehicle,
                onChange: _this2.onInputChange,
                keyValue: 'currentVehicle'
              }),
              _react2.default.createElement(_Global.Button, {
                styleOverride: function styleOverride() {
                  return '\n                  float: right;\n                ';
                },
                onClick: _this2.onSaveDetails,
                text: translations.myAccountSaveActionButton,
                applyStyle: 'primary',
                buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
              }),
              feedback.saveDetails && _react2.default.createElement(
                FeedbackMessage,
                { success: feedback.saveDetails === 'SUCCESS' },
                feedback.saveDetails === 'SUCCESS' ? translations.myAccountSaveDetailsSuccess : translations.myAccountSaveDetailsFailure
              )
            ),
            _react2.default.createElement(VerticalLine, null),
            _react2.default.createElement(
              UserPasswordDetails,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'password',
                label: translations.myAccountDetailsCurrentPassword,
                value: currentPassword,
                onChange: _this2.onInputChange,
                keyValue: 'currentPassword',
                error: hasError('currentPassword'),
                required: true
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'password',
                label: translations.myAccountDetailsNewPassword,
                value: newPassword,
                onChange: _this2.onInputChange,
                keyValue: 'newPassword',
                error: hasError('newPassword'),
                required: true
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'password',
                label: translations.myAccountDetailsConfirmNewPassword,
                value: newPasswordConfirm,
                onChange: _this2.onInputChange,
                keyValue: 'newPasswordConfirm',
                error: hasError('newPasswordConfirm'),
                required: true
              }),
              _react2.default.createElement(_Global.Button, {
                styleOverride: function styleOverride() {
                  return '\n                  float: right;\n                ';
                },
                onClick: _this2.onChangePassword,
                text: _this2.props.translations.myAccountChangePassLabel,
                applyStyle: 'secondary',
                buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
              }),
              feedback.changePassword && _react2.default.createElement(
                FeedbackMessage,
                {
                  success: feedback.changePassword === 'SUCCESS'
                },
                feedback.changePassword === 'SUCCESS' ? translations.myAccountChangePasswordSuccess : translations.myAccountChangePasswordFailure
              ),
              _react2.default.createElement(HorizontalLine, { marginSize: 60 }),
              deleteConfirm ? _react2.default.createElement(
                'div',
                null,
                _react2.default.createElement(
                  DeleteMessage,
                  null,
                  translations.myAccountConfirmDeletionMessage
                ),
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'password',
                  label: translations.myAccountYourPasswordLabel,
                  value: passwordOnDelete,
                  onChange: _this2.onInputChange,
                  keyValue: 'passwordOnDelete',
                  error: hasError('passwordOnDelete'),
                  required: true
                }),
                _react2.default.createElement(
                  'div',
                  { style: { display: 'flex' } },
                  _react2.default.createElement(
                    'div',
                    { style: { width: '100%', marginRight: '10px' } },
                    _react2.default.createElement(_Global.Button, {
                      styleOverride: function styleOverride() {
                        return '\n                          width: 100%;\n                        ';
                      },
                      onClick: function onClick() {
                        return _this2.setState(function (prevState) {
                          return {
                            deleteConfirm: !prevState.deleteConfirm
                          };
                        });
                      },
                      text: _this2.props.translations.myAccountCancelLabel,
                      applyStyle: 'secondary',
                      buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle
                    })
                  ),
                  _react2.default.createElement(
                    'div',
                    { style: { width: '100%', marginLeft: '10px' } },
                    _react2.default.createElement(_Global.Button, {
                      styleOverride: function styleOverride() {
                        return '\n                          width: 100%;\n                        ';
                      },
                      onClick: _this2.onDeleteAccount,
                      text: _this2.props.translations.deleteMyAccountLabel,
                      applyStyle: 'primary',
                      buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
                    })
                  )
                ),
                feedback.deleteAccount && _react2.default.createElement(
                  FeedbackMessage,
                  null,
                  translations.myAccountSaveDetailsFailure
                )
              ) : _react2.default.createElement(_Global.Button, {
                styleOverride: function styleOverride() {
                  return '\n                    float: right;\n                  ';
                },
                onClick: function onClick() {
                  return _this2.setState(function (prevState) {
                    return {
                      deleteConfirm: !prevState.deleteConfirm
                    };
                  });
                },
                text: _this2.props.translations.deleteMyAccountLabel,
                applyStyle: 'secondary',
                buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle
              })
            )
          )
        )
      );
    }
  });
};

exports.default = MyAccountDetails;