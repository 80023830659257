'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 5%;\n  '], ['\n    margin: 0 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    justify-content: space-between;\n  '], ['\n    display: flex;\n    justify-content: space-between;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 48%;\n  '], ['\n    width: 48%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n  margin: 0 30px 30px 30px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n'], ['\n  margin: 0 30px 30px 30px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _FormFields = require('./FormFields');

var _Global = require('./Global');

var _numbers = require('../shared/localisation/numbers');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VehicleDetailsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetailsContainer'
}).withConfig({
  displayName: 'VehicleValuation__VehicleDetailsContainer',
  componentId: 'sc-1e5spn8-0'
})(['width:auto;margin:0 170px;', ';'], _theme2.default.max.medium(_templateObject));
var HeadingTextWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleValuation__HeadingTextWrapper',
  componentId: 'sc-1e5spn8-1'
})(['width:100%;text-align:center;margin-top:20px;margin-bottom:35px;']);
var InputRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleValuation__InputRow',
  componentId: 'sc-1e5spn8-2'
})(['', ';'], _theme2.default.min.medium(_templateObject2));
var SelectWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleValuation__SelectWrapper',
  componentId: 'sc-1e5spn8-3'
})(['width:100%;', ';'], _theme2.default.min.medium(_templateObject3));
var SelectWrapperDoubled = (0, _styledComponents2.default)(SelectWrapper).withConfig({
  displayName: 'VehicleValuation__SelectWrapperDoubled',
  componentId: 'sc-1e5spn8-4'
})(['display:flex;justify-content:space-between;']);
var ErrorText = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleValuation__ErrorText',
  componentId: 'sc-1e5spn8-5'
})(['color:#9e1b32;font-size:14px;margin-right:10px;']);
var ActionButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleValuation__ActionButtonContainer',
  componentId: 'sc-1e5spn8-6'
})(['width:auto;margin:0 5% 20px 5%;', ';'], _theme2.default.min.medium(_templateObject4));
var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleValuation__Image',
  componentId: 'sc-1e5spn8-7'
})(['height:200px;']);

var VehicleValuation = function (_Component) {
  (0, _inherits3.default)(VehicleValuation, _Component);

  function VehicleValuation(props) {
    (0, _classCallCheck3.default)(this, VehicleValuation);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VehicleValuation.__proto__ || (0, _getPrototypeOf2.default)(VehicleValuation)).call(this, props));

    Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.props.getValuationMakes();
      }
    });
    Object.defineProperty(_this, 'onInputChange', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value) {
        _this.setState((0, _defineProperty3.default)({}, formKey, _value));
      }
    });
    Object.defineProperty(_this, 'onlyNumericValues', {
      enumerable: true,
      writable: true,
      value: function value(event) {
        if (!event.key.match(/([0-9])/g)) {
          event.preventDefault();
        }
      }
    });
    Object.defineProperty(_this, 'onSelectChange', {
      enumerable: true,
      writable: true,
      value: function value(_ref) {
        var _extends2;

        var keyValue = _ref.keyValue,
            _value2 = _ref.value,
            label = _ref.label;

        var id = void 0;
        var resetValues = void 0;
        var _this$props = _this.props,
            valuationMakes = _this$props.valuationMakes,
            getValuationModels = _this$props.getValuationModels,
            valuationModels = _this$props.valuationModels,
            getValuationVersions = _this$props.getValuationVersions,
            valuationVersions = _this$props.valuationVersions,
            valuationYears = _this$props.valuationYears,
            getValuationYears = _this$props.getValuationYears,
            getValuationMonths = _this$props.getValuationMonths,
            valuationMonths = _this$props.valuationMonths;

        if (keyValue === 'make') {
          var _valuationMakes$find = valuationMakes.find(function (make) {
            return make.name === _value2;
          });

          id = _valuationMakes$find.id;

          getValuationModels(id);
          resetValues = {
            model: {},
            regYear: {},
            regMonth: {},
            version: {}
          };
        }
        if (keyValue === 'model') {
          var _valuationModels$find = valuationModels.find(function (model) {
            return model.name === _value2;
          });

          id = _valuationModels$find.id;

          getValuationVersions(_this.state.make.id, id);
          resetValues = {
            regYear: {},
            regMonth: {},
            version: {}
          };
        }
        if (keyValue === 'version') {
          var _valuationVersions$fi = valuationVersions.find(function (version) {
            return version.name === _value2;
          });

          id = _valuationVersions$fi.id;

          getValuationYears(_this.state.make.id, _this.state.model.id, id);
        }
        if (keyValue === 'regYear') {
          var _valuationYears$find = valuationYears.find(function (year) {
            return year.value === _value2;
          });

          id = _valuationYears$find.id;

          getValuationMonths(_this.state.make.id, _this.state.model.id, _value2);
          resetValues = {
            regMonth: {}
          };
        }
        if (keyValue === 'regMonth') {
          var _valuationMonths$find = valuationMonths.find(function (month) {
            return month.value === _value2;
          });

          id = _valuationMonths$find.id;
        }
        _this.setState((0, _extends4.default)({}, resetValues, (_extends2 = {}, (0, _defineProperty3.default)(_extends2, keyValue, { id: id, label: label, value: _value2 }), (0, _defineProperty3.default)(_extends2, 'errors', {
          make: false,
          model: false,
          version: false,
          regMonth: false,
          regYear: false
        }), _extends2)));
      }
    });
    Object.defineProperty(_this, 'onSubmit', {
      enumerable: true,
      writable: true,
      value: function value() {
        var errors = _this.validate();
        if ((0, _values2.default)(errors).some(function (v) {
          return !!v;
        })) {
          _this.setState(function () {
            return { errors: errors };
          });
        } else {
          _this.props.getVehicleValuation(_this.state.make, _this.state.model, _this.state.version.id, _this.state.regYear.id, _this.state.regMonth.id, _this.state.odometer);
        }
      }
    });
    Object.defineProperty(_this, 'validate', {
      enumerable: true,
      writable: true,
      value: function value() {
        return {
          make: (0, _keys2.default)(_this.state.make).length === 0 || _this.state.make.value === '',
          model: (0, _keys2.default)(_this.state.model).length === 0 || _this.state.model.value === '',
          version: (0, _keys2.default)(_this.state.version).length === 0 || _this.state.version.value === '',
          regMonth: (0, _keys2.default)(_this.state.regMonth).length === 0 || _this.state.regMonth.value === '',
          regYear: (0, _keys2.default)(_this.state.regYear).length === 0 || _this.state.regYear.value === '',
          odometer: !_this.state.odometer.match(/([0-9])/g)
        };
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props2 = _this.props,
            vehicleInfo = _this$props2.vehicleInfo,
            translations = _this$props2.translations,
            valuationMakes = _this$props2.valuationMakes,
            valuationModels = _this$props2.valuationModels,
            valuationVersions = _this$props2.valuationVersions,
            valuationYears = _this$props2.valuationYears,
            valuationMonths = _this$props2.valuationMonths,
            vehicleValuation = _this$props2.vehicleValuation,
            updateMyDealWithValuation = _this$props2.updateMyDealWithValuation,
            vehicleValuationRequestLimit = _this$props2.vehicleValuationRequestLimit,
            editVehicleValuation = _this$props2.editVehicleValuation,
            globalStyling = _this$props2.globalStyling,
            networkError = _this$props2.networkError;

        var makeDisabled = networkError;
        var modelDisabled = networkError || !_this.state.make.value;
        var versionDisabled = networkError || !_this.state.make.value || !_this.state.model.value;
        var yearDisabled = networkError || !_this.state.make.value || !_this.state.model.value || !_this.state.version.value;
        var monthDisabled = networkError || !_this.state.make.value || !_this.state.model.value || !_this.state.regYear.value;

        return !vehicleValuation ? _react2.default.createElement(
          'div',
          { style: { backgroundColor: '#ffffff' } },
          _react2.default.createElement(
            VehicleDetailsContainer,
            null,
            _react2.default.createElement(
              HeadingTextWrapper,
              null,
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n                color: #141414;\n                font-size: 18px;\n                font-weight: 600;\n                line-height: 22px;\n              ';
                  }
                },
                translations.vehicleValuationHeading
              ),
              _react2.default.createElement(
                _Global.Paragraph,
                {
                  styleOverride: function styleOverride() {
                    return '\n                color: #474747;\n                font-size: 14px;\n                line-height: 20px;\n              ';
                  }
                },
                translations.vehicleValuationSubHeading
              )
            ),
            _react2.default.createElement(
              InputRow,
              null,
              _react2.default.createElement(
                SelectWrapper,
                null,
                _react2.default.createElement(_FormFields.SelectField, {
                  keyValue: 'make',
                  value: _this.state.make.value,
                  onChange: _this.onSelectChange,
                  label: translations.vehicleValuationsMakeLabel,
                  options: [{
                    label: translations.vehicleValuationsMakePlaceholder,
                    value: ''
                  }].concat((0, _toConsumableArray3.default)(valuationMakes.map(function (make) {
                    return {
                      label: make.name,
                      value: make.name
                    };
                  }))),
                  error: _this.state.errors.make,
                  disabled: makeDisabled,
                  required: true
                })
              ),
              _react2.default.createElement(
                SelectWrapper,
                null,
                _react2.default.createElement(_FormFields.SelectField, {
                  keyValue: 'model',
                  value: _this.state.model.value,
                  onChange: _this.onSelectChange,
                  label: translations.vehicleValuationsModelLabel,
                  options: [{
                    label: translations.vehicleValuationsModelPlaceholder,
                    value: ''
                  }].concat((0, _toConsumableArray3.default)(valuationModels.map(function (model) {
                    return {
                      label: model.name,
                      value: model.name
                    };
                  }))),
                  disabled: modelDisabled,
                  error: _this.state.errors.model,
                  required: true
                })
              )
            ),
            _react2.default.createElement(
              InputRow,
              null,
              _react2.default.createElement(
                SelectWrapper,
                null,
                _react2.default.createElement(_FormFields.SelectField, {
                  keyValue: 'version',
                  value: _this.state.version.value,
                  onChange: _this.onSelectChange,
                  label: translations.vehicleValuationsVersionLabel,
                  options: [{
                    label: translations.vehicleValuationsVersionPlaceholder,
                    value: ''
                  }].concat((0, _toConsumableArray3.default)(valuationVersions.map(function (version) {
                    return {
                      label: version.name,
                      value: version.name
                    };
                  }))),
                  disabled: versionDisabled,
                  error: _this.state.errors.version,
                  required: true
                })
              ),
              _react2.default.createElement(
                SelectWrapperDoubled,
                null,
                _react2.default.createElement(
                  SelectWrapper,
                  null,
                  _react2.default.createElement(_FormFields.SelectField, {
                    keyValue: 'regYear',
                    value: _this.state.regYear.value,
                    onChange: _this.onSelectChange,
                    label: translations.vehicleValuationsRegLabel,
                    options: [{
                      label: translations.vehicleValuationsYearPlaceholder,
                      value: ''
                    }].concat((0, _toConsumableArray3.default)(valuationYears)),
                    disabled: yearDisabled,
                    error: _this.state.errors.regYear,
                    required: true
                  })
                ),
                _react2.default.createElement(
                  SelectWrapper,
                  null,
                  _react2.default.createElement(_FormFields.SelectField, {
                    keyValue: 'regMonth',
                    value: _this.state.regMonth.value,
                    onChange: _this.onSelectChange,
                    label: '\xA0',
                    options: [{
                      label: translations.vehicleValuationsMonthPlaceholder,
                      value: ''
                    }].concat((0, _toConsumableArray3.default)(valuationMonths)),
                    disabled: monthDisabled,
                    error: _this.state.errors.regMonth
                  })
                )
              )
            ),
            _react2.default.createElement(
              InputRow,
              null,
              _react2.default.createElement(
                SelectWrapper,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  value: _this.state.odometer,
                  keyValue: 'odometer',
                  placeholder: translations.vehicleValuationsOdometerPlaceholder,
                  onChange: _this.onInputChange,
                  onKeyPress: _this.onlyNumericValues,
                  label: translations.vehicleValuationsOdometerLabel,
                  error: _this.state.errors.odometer,
                  required: true
                })
              )
            ),
            _react2.default.createElement(
              _Global.Paragraph,
              {
                styleOverride: function styleOverride() {
                  return '\n              color: #7e7e7e;\n              font-size: 12px;\n              margin-top: 30px;\n              margin-bottom: 30px;\n            ';
                }
              },
              translations.valuationDisclaimer
            )
          ),
          _react2.default.createElement(
            ActionButtonContainer,
            null,
            (0, _values2.default)(_this.state.errors).some(function (v) {
              return !!v;
            }) && !networkError && _react2.default.createElement(
              ErrorText,
              null,
              translations.vehicleValuationsInvalid
            ),
            vehicleValuationRequestLimit && _react2.default.createElement(
              ErrorText,
              null,
              translations.vehicleValuationsRequestLimit
            ),
            networkError && _react2.default.createElement(
              ErrorText,
              null,
              translations.valuationNetworkError
            ),
            _react2.default.createElement(_Global.Button, {
              buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
              text: translations.vehicleValuationsCTA,
              applyStyle: 'primary',
              styleOverride: function styleOverride() {},
              mobileStyleOverride: function mobileStyleOverride() {},
              onClick: _this.onSubmit
            })
          )
        ) : _react2.default.createElement(
          'div',
          { style: { backgroundColor: '#ffffff' } },
          _react2.default.createElement(
            VehicleDetailsContainer,
            null,
            _react2.default.createElement(
              HeadingTextWrapper,
              null,
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n              color: #141414;\n              font-size: 18px;\n              font-weight: 600;\n              line-height: 22px;\n            ';
                  }
                },
                (0, _translateFromTemplate.translateFromTemplate)('valuationSuccessHeading', {
                  VALUE: (0, _numbers.localiseCurrency)(vehicleValuation[(0, _keys2.default)(vehicleValuation)[0]].valuation, vehicleInfo.locale, vehicleInfo.price.currency, 0)
                }, translations)
              ),
              _react2.default.createElement(Image, {
                src: vehicleValuation[(0, _keys2.default)(vehicleValuation)[0]].image
              }),
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n              color: #141414;\n              font-size: 18px;\n              font-weight: 600;\n              line-height: 22px;\n            ';
                  }
                },
                vehicleValuation[(0, _keys2.default)(vehicleValuation)[0]].vehicle
              )
            ),
            _react2.default.createElement(
              _Global.Paragraph,
              {
                styleOverride: function styleOverride() {
                  return '\n              color: #7e7e7e;\n              font-size: 12px;\n              margin-top: 30px;\n              margin-bottom: 30px;\n            ';
                }
              },
              translations.valuationDisclaimer
            )
          ),
          _react2.default.createElement(
            ActionButtonContainer,
            null,
            _react2.default.createElement(_Global.Button, {
              buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
              text: translations.editValuationCTA,
              applyStyle: 'primary',
              styleOverride: function styleOverride() {
                return 'margin-right: 20px;';
              },
              mobileStyleOverride: function mobileStyleOverride() {},
              onClick: function onClick() {
                return editVehicleValuation(vehicleInfo.id);
              }
            }),
            _react2.default.createElement(_Global.Button, {
              buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
              text: translations.valuationSuccessCTA,
              applyStyle: 'primary',
              styleOverride: function styleOverride() {},
              mobileStyleOverride: function mobileStyleOverride() {},
              onClick: updateMyDealWithValuation
            })
          )
        );
      }
    });


    _this.state = {
      make: {},
      model: {},
      version: {},
      regMonth: {},
      regYear: {},
      odometer: '',
      errors: {
        make: false,
        model: false,
        version: false,
        regMonth: false,
        regYear: false,
        odometer: false
      }
    };
    return _this;
  }

  return VehicleValuation;
}(_react.Component);

exports.default = VehicleValuation;