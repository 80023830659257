'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)([' flex-direction: column;'], [' flex-direction: column;']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n  '], ['\n    margin: 0;\n  ']);

exports.default = FinanceExample;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _splitEvery = require('ramda/src/splitEvery');

var _splitEvery2 = _interopRequireDefault(_splitEvery);

var _Global = require('./Global');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _data = require('../shared/financeCalculator/data');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RowBody = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceExample__RowBody',
  componentId: 'sc-17pz6ly-0'
})(['display:flex;justify-content:space-between;', ';', ';'], function (_ref) {
  var singleColumn = _ref.singleColumn;
  return singleColumn && 'flex-direction: column;';
}, _theme2.default.max.medium(_templateObject));

var ItemBody = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceExample__ItemBody',
  componentId: 'sc-17pz6ly-1'
})(['align-items:center;min-height:25px;display:flex;justify-content:space-between;flex:1 0 0%;', ';', ';', ';', ';', ';', ';'], function (_ref2) {
  var marginRight = _ref2.marginRight;
  return marginRight && 'margin-right: 20px;';
}, function (_ref3) {
  var marginLeft = _ref3.marginLeft;
  return marginLeft && 'margin-left: 20px;';
}, function (_ref4) {
  var financePricingTable = _ref4.financePricingTable;
  return financePricingTable && 'padding-right: ' + financePricingTable.paddingRight + 'px;';
}, function (_ref5) {
  var financePricingTable = _ref5.financePricingTable;
  return financePricingTable && 'padding-left: ' + financePricingTable.paddingLeft + 'px;';
}, function (_ref6) {
  var financePricingTable = _ref6.financePricingTable;
  return financePricingTable && financePricingTable.borderColour ? 'border-' + (financePricingTable.borderPosition || 'top') + ': 1px solid ' + financePricingTable.borderColour.value : 'border-top: 1px solid #ACACAC;';
}, _theme2.default.max.medium(_templateObject2));

var DisclaimerRow = (0, _styledComponents2.default)(ItemBody).withConfig({
  displayName: 'FinanceExample__DisclaimerRow',
  componentId: 'sc-17pz6ly-2'
})(['border:none;']);

var Disclaimer = function Disclaimer(_ref7) {
  var text = _ref7.text,
      labelFont = _ref7.labelFont,
      headingMargin = _ref7.headingMargin;
  return _react2.default.createElement(
    RowBody,
    { singleColumn: true },
    _react2.default.createElement(
      DisclaimerRow,
      null,
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n        flex: 1 0 0%;\n        margin: ' + (headingMargin ? headingMargin + 'px;' : '4px 0;') + ';\n        ' + (0, _Global.fontStyleOverride)(labelFont) + ';\n        ';
          }
        },
        text
      )
    )
  );
};

var ItemRow = function ItemRow(_ref8) {
  var _ref8$pair = (0, _slicedToArray3.default)(_ref8.pair, 2),
      first = _ref8$pair[0],
      second = _ref8$pair[1],
      last = _ref8.last,
      labelFont = _ref8.labelFont,
      valueFont = _ref8.valueFont,
      singleColumn = _ref8.singleColumn,
      financePricingTable = _ref8.financePricingTable,
      headingMargin = _ref8.headingMargin;

  return _react2.default.createElement(
    RowBody,
    { singleColumn: singleColumn },
    _react2.default.createElement(
      ItemBody,
      {
        marginRight: !singleColumn,
        last: last,
        financePricingTable: financePricingTable
      },
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n          flex: 1 0 0%;\n          margin: ' + (headingMargin ? headingMargin + 'px;' : '4px 0;') + ';\n          ' + (0, _Global.fontStyleOverride)(labelFont) + ';\n          ';
          }
        },
        first.label
      ),
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n          margin: ' + (headingMargin ? headingMargin + 'px;' : '4px 0;') + ';\n          ' + (0, _Global.fontStyleOverride)(valueFont) + ';\n          ';
          }
        },
        first.value
      )
    ),
    second ? _react2.default.createElement(
      ItemBody,
      {
        marginLeft: !singleColumn,
        last: last,
        financePricingTable: financePricingTable
      },
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n            flex: 1 0 0%;\n            margin: ' + (headingMargin ? headingMargin + 'px;' : '4px 0;') + ';\n            ' + (0, _Global.fontStyleOverride)(labelFont) + ';\n            ';
          }
        },
        second.label
      ),
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n            margin: ' + (headingMargin ? headingMargin + 'px;' : '4px 0;') + ';\n            ' + (0, _Global.fontStyleOverride)(valueFont) + ';\n            ';
          }
        },
        second.value
      )
    ) : _react2.default.createElement(ItemBody, {
      marginLeft: !singleColumn,
      financePricingTable: financePricingTable
    })
  );
};

function FinanceExample(_ref9) {
  var data = _ref9.data,
      finance = _ref9.finance,
      labelFontOverride = _ref9.labelFontOverride,
      valueFontOverride = _ref9.valueFontOverride,
      singleColumn = _ref9.singleColumn,
      financePricingTable = _ref9.financePricingTable;

  var items = singleColumn ? (0, _data.getFinanceDisplayList)(data, finance) : (0, _data.reorderFinanceDisplayList)((0, _data.getFinanceDisplayList)(data, finance));
  var pairs = (0, _splitEvery2.default)(2, items);

  var infoLabelFont = financePricingTable && financePricingTable.infoLabelFont || labelFontOverride;
  var infoValueFont = financePricingTable && financePricingTable.infoValueFont || valueFontOverride;

  var rows = pairs.map(function (pair, i) {
    return _react2.default.createElement(ItemRow, {
      key: i.toString(),
      pair: pair,
      mark: 'prop',
      last: i === pairs.length - 1,
      labelFont: infoLabelFont,
      valueFont: infoValueFont,
      singleColumn: singleColumn,
      financePricingTable: financePricingTable
    });
  });

  var disclaimer = finance.showExampleDisclaimer && _react2.default.createElement(Disclaimer, {
    text: finance.translations.exampleDisclaimer,
    labelFont: infoLabelFont
  });

  return [].concat((0, _toConsumableArray3.default)(rows), [disclaimer]).filter(Boolean);
}

FinanceExample.defaultProps = {
  valueFontOverride: {
    fontSize: '14',
    colour: {
      label: '#444444',
      value: '#444444'
    }
  },
  labelFontOverride: {
    fontSize: '14',
    colour: {
      label: '#7e7e7e',
      value: '#7e7e7e'
    }
  },
  singleColumn: false
};