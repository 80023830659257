'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHelmet = require('react-helmet');

var _router = require('../../../actions/router');

var _filters = require('../../../shared/filters');

var _financeCalculator = require('../../../shared/financeCalculator');

var _printing = require('../../../shared/printing');

var _searchFilter = require('../../../shared/searchFilter');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _vehicle = require('../../../helpers/vehicle');

var _pathBuilders = require('../../../path-builders');

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Accessories = require('../../../components/Genesis/VDP/Accessories');

var _Accessories2 = _interopRequireDefault(_Accessories);

var _PreownedBenefits = require('../../../components/Genesis/VDP/PreownedBenefits');

var _PreownedBenefits2 = _interopRequireDefault(_PreownedBenefits);

var _VdpRetailerMap = require('../../../components/Genesis/VDP/VdpRetailerMap');

var _VdpRetailerMap2 = _interopRequireDefault(_VdpRetailerMap);

var _VehicleDetails = require('../../../components/Genesis/VDP/VehicleDetails');

var _VehicleDetails2 = _interopRequireDefault(_VehicleDetails);

var _VehicleGallery = require('../../../components/Genesis/VDP/VehicleGallery');

var _VehicleGallery2 = _interopRequireDefault(_VehicleGallery);

var _FinanceCalculator = require('../../../components/Genesis/VDP/VehicleInformation/FinanceCalculator');

var _FinanceCalculator2 = _interopRequireDefault(_FinanceCalculator);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VDP = function (_Component) {
  (0, _inherits3.default)(VDP, _Component);

  function VDP(props) {
    (0, _classCallCheck3.default)(this, VDP);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VDP.__proto__ || (0, _getPrototypeOf2.default)(VDP)).call(this, props));

    Object.defineProperty(_this, 'state', {
      enumerable: true,
      writable: true,
      value: {
        financeInformationForEnquiry: {
          deposit: null,
          payment: null,
          mileage: null,
          term: null,
          financeType: null
        }
      }
    });
    Object.defineProperty(_this, 'componentDidUpdate', {
      enumerable: true,
      writable: true,
      value: function value(prevProps) {
        var _this$props = _this.props,
            state = _this$props.state,
            shared = _this$props.shared,
            actions = _this$props.actions,
            dispatch = _this$props.dispatch,
            router = _this$props.router,
            _this$props$history$l = _this$props.history.location,
            pathname = _this$props$history$l.pathname,
            hash = _this$props$history$l.hash,
            _this$props$history$l2 = _this$props$history$l.state,
            payload = _this$props$history$l2 === undefined ? { finance: null } : _this$props$history$l2,
            globalFinance = _this$props.finance,
            context = _this$props.state.context,
            hashRouting = _this$props.hashRouting;


        if (prevProps.shared.currencyConversion.exchangeRates !== shared.currencyConversion.exchangeRates) {
          dispatch(actions.updateVehiclePrices({
            vehicle: state.vdpConfig,
            similarVehicles: state.similarVehicles
          }));
        }

        if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language || prevProps.router.navigationHistory[0] !== router.navigationHistory[0]) {
          var vehicleId = (hashRouting ? hash : pathname).match(/(\/)([A-Za-z0-9]*)(-)/)[2];
          var _finance = payload.finance;

          var financeProductToUse = context || globalFinance && globalFinance.defaultProduct;
          actions.getVehicleInfo({
            vehicleId: vehicleId,
            finance: _finance,
            financeProductToUse: financeProductToUse
          });
        }
      }
    });
    Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props2 = _this.props,
            _this$props2$history$ = _this$props2.history.location,
            pathname = _this$props2$history$.pathname,
            hash = _this$props2$history$.hash,
            _this$props2$history$2 = _this$props2$history$.state,
            payload = _this$props2$history$2 === undefined ? { finance: null } : _this$props2$history$2,
            actions = _this$props2.actions,
            dispatch = _this$props2.dispatch,
            featureFlags = _this$props2.featureFlags,
            globalFinance = _this$props2.finance,
            context = _this$props2.state.context,
            config = _this$props2.config,
            financeState = _this$props2.finance,
            hashRouting = _this$props2.hashRouting;


        if (featureFlags.finance) {
          dispatch(_financeCalculator.actions.resetFinanceProduct({
            type: financeState.defaultProduct
          }));
        }

        var vehicleId = (hashRouting ? hash : pathname).match(/(\/)([A-Za-z0-9]*)(-)/)[2];
        var finance = payload.finance;

        var financeProductToUse = context || globalFinance && globalFinance.defaultProduct;
        actions.getVehicleInfo({
          vehicleId: vehicleId,
          finance: finance,
          financeProductToUse: financeProductToUse
        });

        if (!config.hideSimilarVehicle) {
          actions.getSimilarVehicles({ vehicleId: vehicleId, financeProductToUse: financeProductToUse });
        }
        if (featureFlags.finance) {
          dispatch(_financeCalculator.actions.loadFinanceOptions(vehicleId));
        }

        var isDWS = _this.isDealerWebsite();
        if (isDWS !== _this.state.isDWS) {
          _this.setState({ isDWS: isDWS });
        }
      }
    });
    Object.defineProperty(_this, 'termsRange', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props3 = _this.props,
            config = _this$props3.config,
            shared = _this$props3.shared,
            _this$props3$state = _this$props3.state,
            vdpConfig = _this$props3$state.vdpConfig,
            context = _this$props3$state.context,
            finance = _this$props3.finance;

        var marketFinanceOptions = shared.financeCalculator.financeOptions[context || finance && finance.defaultProduct];
        if (marketFinanceOptions) {
          var sortedOptions = (marketFinanceOptions.durationOptions || []).sort(function (a, b) {
            return a - b;
          });
          var exampleDefaultTerm = shared.financeCalculator.financeProductExample[context] && shared.financeCalculator.financeProductExample[context].duration;
          var defaultTerm = (vdpConfig && vdpConfig.finance && vdpConfig || { finance: {} }).finance.duration || sortedOptions[0];
          return {
            id: 'termsRange',
            name: 'term',
            title: config.translations.term,
            unit: config.translations.months,
            step: 0,
            marks: sortedOptions.reduce(function (ac, cv) {
              return (0, _extends4.default)({}, ac, (0, _defineProperty3.default)({}, cv, '' + cv));
            }, {}),
            defaultValue: exampleDefaultTerm || defaultTerm,
            fullRangeValues: {
              min: sortedOptions[0],
              max: sortedOptions[sortedOptions.length - 1]
            }
          };
        }
        return {};
      }
    });
    Object.defineProperty(_this, 'isDealerWebsite', {
      enumerable: true,
      writable: true,
      value: function value() {
        var navigationHistory = _this.props.router.navigationHistory;

        if (!navigationHistory) {
          return false;
        }
        return navigationHistory.length >= 2 && navigationHistory[1].includes('dealer_');
      }
    });


    _this.setState({
      isDWS: false
    });
    return _this;
  }

  (0, _createClass3.default)(VDP, [{
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      var _props = this.props,
          dispatch = _props.dispatch,
          history = _props.history;

      var searchResultsMatcher = /.*\/searchresults\/.*/i;
      var goingToSrp = searchResultsMatcher.test(history.location.pathname);
      if (!goingToSrp) {
        dispatch(_sessionPreferences.actions.updateSessionPreferences('infiniteScroll', false));
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          _props2$state = _props2.state,
          vdpConfig = _props2$state.vdpConfig,
          placeholdingImage = _props2$state.placeholdingImage,
          dispatch = _props2.dispatch,
          shared = _props2.shared,
          pathByModule = _props2.pathByModule,
          config = _props2.config,
          featureFlags = _props2.featureFlags,
          globalStyling = _props2.globalStyling,
          marketInfo = _props2.marketInfo,
          socialShare = _props2.socialShare,
          googleAvailable = _props2.shared.google.googleAvailable;


      var vehicleDetails = vdpConfig;
      var translations = config.translations;

      var language = marketInfo.locale;

      var goToModule = function goToModule(name, retailer) {
        var path = (0, _pathBuilders.pathWithDealerID)(pathByModule(name), retailer.name, config.specialDealerRedirection ? retailer.oemId : retailer.id, language, config.specialDealerRedirection && featureFlags.dealerUrl);

        window.open(window.location.origin + '/' + path, config.newTab);
      };

      var goToModuleWithGroup = function goToModuleWithGroup(name, retailer) {
        return dispatch(_router.actions.navigate((0, _pathBuilders.pathWithDealerGroup)(pathByModule(name), retailer)));
      };

      var resetFilters = function resetFilters() {
        _this2.props.dispatch(_sessionPreferences.actions.updateSessionPreferences('resetSearchResultsPageNumber', true));
        _this2.props.dispatch(_searchFilter.actions.resetFilters());
        _this2.props.dispatch(_filters.actions.resetFilters());
      };

      var printVehiclePdf = function printVehiclePdf() {
        return dispatch(_printing.actions.printVehicleInfo(vehicleDetails, translations, config));
      };
      var printingState = shared.printing;

      var getVehicleTitle = function getVehicleTitle() {
        if (translations.customSeoTitle) {
          return (0, _vehicle.translateTemplateWithVehicleData)('customSeoTitle', config.customSeoProps, vehicleDetails, translations);
        }
        return config.hideYear ? vehicleDetails.description : vehicleDetails.registration + ' ' + vehicleDetails.description;
      };

      var getVehicleDescription = function getVehicleDescription() {
        if (translations.customSeoDescription) {
          return (0, _vehicle.translateTemplateWithVehicleData)('customSeoDescription', config.customSeoProps, vehicleDetails, translations);
        }
        return '';
      };

      // Convert finance information into querystring parameters.
      var navigateToEnquiryForm = function navigateToEnquiryForm() {
        var financeInfo = _this2.state.financeInformationForEnquiry;
        (0, _keys2.default)(financeInfo).forEach(function (key) {
          if (financeInfo[key] === null) {
            delete financeInfo[key];
          }
        });
        var parameters = new URLSearchParams(financeInfo).toString();

        dispatch(_router.actions.navigate('/enquiry/' + vehicleDetails.id + (parameters ? '?' + parameters : '')));
      };

      return vehicleDetails ? _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          _reactHelmet.Helmet,
          null,
          _react2.default.createElement(
            'title',
            null,
            getVehicleTitle()
          ),
          _react2.default.createElement('meta', { name: 'description', content: getVehicleDescription() })
        ),
        _react2.default.createElement(_VehicleGallery2.default, {
          globalStyling: globalStyling,
          featureFlags: featureFlags,
          vehicleImages: {
            slides: vehicleDetails.images.map(function (i) {
              return {
                id: i,
                image: i
              };
            })
          },
          vehicleInfo: vehicleDetails,
          translations: config.translations,
          placeholdingImage: placeholdingImage,
          rotateGallery: true,
          isLooping: false,
          config: config
        }),
        _react2.default.createElement(
          _ContentWrapper2.default,
          null,
          _react2.default.createElement(_VehicleDetails2.default, {
            vehicle: vehicleDetails,
            config: config,
            translations: config.translations,
            locale: marketInfo.locale,
            printVehiclePdf: printVehiclePdf,
            printingState: printingState,
            placeholdingImage: placeholdingImage,
            makeAnEnquiry: function makeAnEnquiry(e) {
              e.preventDefault();
              navigateToEnquiryForm();
            },
            bookATestDrive: function bookATestDrive(e) {
              e.preventDefault();
              dispatch(_router.actions.navigate('/testdrive/' + vehicleDetails.id));
            },
            reserveCar: function reserveCar(e) {
              e.preventDefault();
              dispatch(_router.actions.navigate('/reserve/' + vehicleDetails.id));
            },
            globalStyling: globalStyling,
            socialShare: socialShare
          })
        ),
        !config.hideAccessories && _react2.default.createElement(
          _ContentWrapper2.default,
          { colour: { value: 'white' } },
          _react2.default.createElement(_Accessories2.default, {
            vehicle: vehicleDetails,
            translations: config.translations,
            config: config
          })
        ),
        config.showFinanceSection && _react2.default.createElement(
          _ContentWrapper2.default,
          { colour: { value: 'white' } },
          _react2.default.createElement(_FinanceCalculator2.default, {
            vehicle: vehicleDetails,
            translations: config.translations,
            config: config,
            locale: marketInfo.locale,
            globalStyling: globalStyling
          })
        ),
        vehicleDetails.approved && !config.hideCertifiedSection && _react2.default.createElement(
          _ContentWrapper2.default,
          { colour: { value: 'white' } },
          _react2.default.createElement(_PreownedBenefits2.default, {
            vehicle: vehicleDetails,
            translations: config.translations,
            config: config
          })
        ),
        _react2.default.createElement(
          _ContentWrapper2.default,
          { id: 'retailer-map' },
          _react2.default.createElement(_VdpRetailerMap2.default, {
            retailer: vehicleDetails.retailerInformation,
            translations: config.translations,
            viewRetailerStock: function viewRetailerStock(retailer) {
              resetFilters();
              goToModule('GenesisVehicleSearchResults', retailer);
            },
            viewGroupStock: function viewGroupStock(retailer) {
              resetFilters();
              goToModuleWithGroup('GenesisVehicleSearchResults', retailer);
            },
            globalStyling: globalStyling,
            config: config.retailerMapDetails,
            getDirectionsToRetailer: function getDirectionsToRetailer(url) {
              return dispatch(_router.actions.navigate(url));
            },
            locale: marketInfo.locale,
            googleAvailable: googleAvailable,
            hideContactButton: config.hideContactButton,
            vehicleInfo: vehicleDetails,
            isDWS: this.state.isDWS
          })
        )
      ) : null;
    }
  }]);
  return VDP;
}(_react.Component);
/* eslint-disable react/jsx-indent, no-return-assign */


exports.default = VDP;