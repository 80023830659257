'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _components = require('../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ImageTitle = _styledComponents2.default.div.withConfig({
  displayName: 'ImageTitle',
  componentId: 'sc-scndqs-0'
})(['width:100%;height:100%;min-height:', 'px;background-image:url(', ');background-size:cover;background-position:center;'], function (props) {
  return props.imageHeight || 300;
}, function (props) {
  return props.src;
});

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      src = _ref$config.image.src,
      _ref$config$translati = _ref$config.translations,
      headlineText = _ref$config$translati.headlineText,
      bodyText = _ref$config$translati.bodyText,
      imageLabel = _ref$config$translati.imageLabel,
      overlayConfig = (0, _objectWithoutProperties3.default)(_ref$config, ['image', 'translations']),
      _ref$globalStyling = _ref.globalStyling;
  _ref$globalStyling = _ref$globalStyling === undefined ? {} : _ref$globalStyling;
  var _ref$globalStyling$ui = _ref$globalStyling.uiElements,
      uiElements = _ref$globalStyling$ui === undefined ? {} : _ref$globalStyling$ui,
      dispatch = _ref.dispatch;
  return _react2.default.createElement(
    _components.Wrapper,
    null,
    _react2.default.createElement(
      ImageTitle,
      (0, _extends3.default)({ src: src }, { role: 'img', 'aria-label': imageLabel }),
      _react2.default.createElement(_components.Overlay, (0, _extends3.default)({}, overlayConfig, {
        headlineText: headlineText,
        bodyText: bodyText,
        uiElements: uiElements,
        dispatch: dispatch
      }))
    )
  );
};