'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (props) {
  var socialShare = props.socialShare,
      config = props.config;

  return _react2.default.createElement(
    _SocialSharePopup2.default,
    { socialShare: socialShare, config: config },
    _react2.default.createElement(
      SocialShareButton,
      { 'data-cy': 'socialShare', font: config.socialShareFont },
      config.socialShareIcon ? _react2.default.createElement(IconWrapper, {
        src: config.socialShareIcon,
        alt: '',
        width: config.socialShareIconWidth,
        height: config.socialShareIconHeight
      }) : _react2.default.createElement(_SocialShare2.default, {
        width: config.socialShareIconWidth + 'px',
        height: config.socialShareIconHeight + 'px'
      }),
      _react2.default.createElement(
        'span',
        null,
        config.translations.socialShare
      )
    )
  );
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SocialShare = require('../../components/JaguarIcons/Global/SocialShare');

var _SocialShare2 = _interopRequireDefault(_SocialShare);

var _SocialSharePopup = require('../../components/SocialSharePopup');

var _SocialSharePopup2 = _interopRequireDefault(_SocialSharePopup);

var _Global = require('../../components/Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SocialShareButton = _styledComponents2.default.div.withConfig({
  displayName: 'SocialShareButton',
  componentId: 'sc-oregxg-0'
})(['', ';display:flex;align-items:center;'], function (_ref) {
  var font = _ref.font;
  return (0, _Global.fontStyleOverride)(font);
});


var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'SocialShareButton__IconWrapper',
  componentId: 'sc-oregxg-1'
})(['width:', ';height:', ';padding-right:8px;'], function (_ref2) {
  var width = _ref2.width;
  return width || '12px';
}, function (_ref3) {
  var height = _ref3.height;
  return height || '11px';
});