'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseFilter = exports.parseFiltersToQueryString = exports.queryFromHistory = exports.adjustPriceRangeFilterValues = exports.removeNonNumericDistance = exports.filterKeysWithArrays = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends4 = require('babel-runtime/helpers/extends');

var _extends5 = _interopRequireDefault(_extends4);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _isNan = require('babel-runtime/core-js/number/is-nan');

var _isNan2 = _interopRequireDefault(_isNan);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

exports.default = applyModelVariantLogic;

var _omit = require('ramda/src/omit');

var _omit2 = _interopRequireDefault(_omit);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var filterKeysWithArrays = exports.filterKeysWithArrays = ['model', 'variant', 'modelVariants', 'subregions', 'exteriorColours', 'interiorShades', 'bodystyle', 'fuelType', 'transmission', 'engine', 'cylinders', 'veneer'];

function applyModelVariantLogic(filtersObject) {
  var keys = (0, _keys2.default)(filtersObject);
  if (keys.find(function (k) {
    return k === 'variant' || k === 'modelVariants';
  }) && !keys.find(function (k) {
    return k === 'model';
  })) {
    return (0, _omit2.default)(['variant', 'modelVariants'], filtersObject);
  }
  return filtersObject;
}
var isNumeric = function isNumeric(num) {
  return !(0, _isNan2.default)(Number(num));
};

var removeNonNumericDistance = exports.removeNonNumericDistance = function removeNonNumericDistance(filtersObject) {
  var entries = (0, _entries2.default)(filtersObject);

  var numericalDistance = entries.find(function (e) {
    return e[0] === 'maxDistance' && isNumeric(e[1]);
  });

  return numericalDistance ? filtersObject : (0, _omit2.default)(['maxDistance'], filtersObject);
};

var convertToPounds = function convertToPounds(value, exchange) {
  if (value === 0) {
    return value;
  }

  return Math.round(value * exchange.rates.GBP);
};

// AVL3-2528 - Temporary fix to handle fluctuating currency conversion rates.
// Adds a 1% buffer to price filter values.
// - Updated to 1.5%
var adjustPriceRangeFilterValues = exports.adjustPriceRangeFilterValues = function adjustPriceRangeFilterValues(filtersObject, exchange) {
  return (0, _keys2.default)(filtersObject).reduce(function (acc, key) {
    if (key === 'minDefaultPrice' || key === 'maxDefaultPrice') {
      var priceValue = Number(filtersObject[key]);
      var convertedGBPValue = exchange ? convertToPounds(priceValue, exchange) : priceValue;

      var coef = key === 'minDefaultPrice' ? -1 : 1;
      var adjustedValue = Math.round(convertedGBPValue + convertedGBPValue / 66.666 * coef);

      return (0, _extends5.default)({}, acc, (0, _defineProperty3.default)({}, key, adjustedValue));
    }

    return acc;
  }, filtersObject);
};

var queryFromHistory = exports.queryFromHistory = function queryFromHistory(history, hashRouting) {
  var query = void 0;
  // this this may be the cause of crazy whit with no ?
  if (hashRouting) {
    var _history$location$has = history.location.hash.split('?');

    var _history$location$has2 = (0, _slicedToArray3.default)(_history$location$has, 2);

    query = _history$location$has2[1];
  } else {
    query = history.location.search.substring(1);
  }

  return query;
};

/**
 * Converts an object into query string parameters.
 * @param {object} params Object to be converted.
 * @returns A string that represents query string parameters.
 */
var parseFiltersToQueryString = exports.parseFiltersToQueryString = function parseFiltersToQueryString(params) {
  return (0, _keys2.default)(params).map(function (key) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key].toString());
  }).join('&');
};

/**
 * Converts a comma separated string into an array.
 * @param {object} originalFilters contains all filters values gathered from UI.
 * @param {string} filterKey Filter name to find what property should be parsed into an array.
 * @returns Altered filters value with parsed filter value.
 */
var parseFilter = exports.parseFilter = function parseFilter(originalFilters, filterKey) {
  return originalFilters[filterKey] && !Array.isArray(originalFilters[filterKey]) ? (0, _extends5.default)({}, originalFilters, (0, _defineProperty3.default)({}, filterKey, originalFilters[filterKey].split(','))) : originalFilters;
};