'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 12px;\n  '], ['\n    font-size: 12px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NoResults = _styledComponents2.default.div.withConfig({
  displayName: 'NoResults',
  componentId: 'sc-1yj3j8-0'
})(['width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;margin-bottom:50px;']);

var Message = _styledComponents2.default.div.withConfig({
  displayName: 'NoResults__Message',
  componentId: 'sc-1yj3j8-1'
})(['font-family:Bentley-Regular;text-transform:none;font-style:normal;font-weight:300;font-size:16px;line-height:21px;text-align:center;color:#333333;margin-top:20px;max-width:350px;', ''], _theme2.default.max.small(_templateObject));

exports.default = function (_ref) {
  var text = _ref.text,
      _ref$iconUrl = _ref.iconUrl,
      iconUrl = _ref$iconUrl === undefined ? 'https://res.cloudinary.com/motortrak/image/upload/v1565104295/cms/global/icons/results-empty.svg' : _ref$iconUrl;
  return _react2.default.createElement(
    NoResults,
    null,
    _react2.default.createElement('img', { src: iconUrl, alt: text }),
    _react2.default.createElement(
      Message,
      null,
      text
    )
  );
};