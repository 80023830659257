'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    &:last-child {\n        border-bottom: 0;\n    }\n  '], ['\n    &:last-child {\n        border-bottom: 0;\n    }\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ = require('.');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ListColumn = _styledComponents2.default.div.withConfig({
  displayName: 'ListColumn'
}).withConfig({
  displayName: 'KeyValueList__ListColumn',
  componentId: 'sc-aas8jr-0'
})(['width:50%;']);

var StyledListItem = (0, _styledComponents2.default)(_.SeparatedListItem).withConfig({
  displayName: 'KeyValueList__StyledListItem',
  componentId: 'sc-aas8jr-1'
})(['border-top:1px solid #dedede;padding:10px 0;line-height:24px;color:#444444;&:last-child{border-bottom:1px solid #dedede;}', ';'], _theme2.default.max.large(_templateObject));
var Label = _styledComponents2.default.div.withConfig({
  displayName: 'KeyValueList__Label',
  componentId: 'sc-aas8jr-2'
})(['font-size:16px;font-weight:600;']);

function KeyValueList(_ref) {
  var items = _ref.items;

  return _react2.default.createElement(
    _.SeparatedListContainer,
    null,
    _react2.default.createElement(
      _.SeparatedList,
      null,
      items.map(function (_ref2) {
        var key = _ref2.key,
            value = _ref2.value;
        return _react2.default.createElement(
          StyledListItem,
          { key: key },
          _react2.default.createElement(
            ListColumn,
            null,
            _react2.default.createElement(
              Label,
              null,
              key
            )
          ),
          _react2.default.createElement(
            ListColumn,
            null,
            value
          )
        );
      })
    )
  );
}

exports.default = KeyValueList;