'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Calendar;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Calendar(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 28 26' },
      _react2.default.createElement('path', { d: 'M22.686 21.249a.44.44 0 0 1-.437.436H4.751a.44.44 0 0 1-.437-.436V5.717a.44.44 0 0 1 .437-.437h2.843v1.31a.656.656 0 0 0 1.31 0V5.28h3.939v1.31a.656.656 0 0 0 1.31 0V5.28h3.939v1.31a.656.656 0 0 0 1.31 0V5.28h2.843a.44.44 0 0 1 .436.437v15.532h.005zm-.441-17.28h-2.843V2.656a.656.656 0 0 0-1.31 0v1.31h-3.939v-1.31a.656.656 0 0 0-1.31 0v1.31H8.904v-1.31a.656.656 0 0 0-1.31 0v1.31H4.75A1.756 1.756 0 0 0 3 5.718v15.532C3 22.215 3.785 23 4.751 23h17.498c.966 0 1.751-.785 1.751-1.751V5.717a1.753 1.753 0 0 0-1.755-1.747z' }),
      _react2.default.createElement('path', { d: 'M7 9h3v2H7V9zm0 4h3v2H7v-2zm0 4h3v2H7v-2zm5-8h3v2h-3V9zm0 4h3v2h-3v-2zm0 4h3v2h-3v-2zm5-8h3v2h-3V9zm0 4h3v2h-3v-2zm0 4h3v2h-3v-2z' })
    )
  );
}

Calendar.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};