'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return {
    headline: 'Finance Explained',
    backgroundColour: {
      label: 'grey',
      value: '#f0f0f0'
    }
  };
};