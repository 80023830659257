'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    box-sizing: border-box;\n    padding: 20px 5% 0 5%;\n  '], ['\n    width: 100%;\n    box-sizing: border-box;\n    padding: 20px 5% 0 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      padding-bottom: 20px;\n    '], ['\n      padding-bottom: 20px;\n    ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    display: ', ';\n  '], ['\n    width: 100%;\n    display: ', ';\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    display:  ', ';\n  '], ['\n    display:  ', ';\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    display: ', ';\n  '], ['\n    display: ', ';\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    '], ['\n    width: 100%;\n    ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    width: 80%;\n    margin-left: 10%;\n    text-align: center;\n  '], ['\n    width: 80%;\n    margin-left: 10%;\n    text-align: center;\n  ']);

exports.default = VdpAccessories;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _VdpHeader = require('./VdpHeader');

var _VdpHeader2 = _interopRequireDefault(_VdpHeader);

var _Chunky = require('./JaguarIcons/Arrows/Chunky');

var _Chunky2 = _interopRequireDefault(_Chunky);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ContentWrapper = require('./ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NUM_VISIBLE = 3;

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'AccessoriesContainer'
}).withConfig({
  displayName: 'VdpAccessories__Container',
  componentId: 'sc-8op7xn-0'
})(['', ';', ';width:100%;height:auto;', ';', ';', ';', ';'], function (_ref) {
  var config = _ref.config;
  return 'background-color: ' + (config && config.backgroundColour || '#ffffff');
}, function (_ref2) {
  var config = _ref2.config;
  return config && config.borderTop && 'border-top: ' + config.borderTop.size + 'px ' + config.borderTop.type + ' ' + config.borderTop.colour;
}, function (_ref3) {
  var config = _ref3.config;
  return 'padding-top: ' + (config && config.padding && config.padding.paddingTop !== undefined ? config.padding.paddingTop : '52') + 'px';
}, function (_ref4) {
  var config = _ref4.config;
  return 'padding-bottom: ' + (config && config.padding && config.padding.paddingBottom !== undefined ? config.padding.paddingBottom : '82') + 'px';
}, function (_ref5) {
  var config = _ref5.config;
  return config && config.box && config.box.border && 'border: ' + config.box.border.size + 'px ' + config.box.border.type + ' ' + config.box.border.colour;
}, _theme2.default.max.large(_templateObject));

var Section = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccessories__Section',
  componentId: 'sc-8op7xn-1'
})(['width:90%;margin:0 5%;color:#444444;font-size:16px;display:flex;flex-wrap:wrap;justify-content:', ';align-items:center;padding-bottom:30px;'], function (props) {
  return props.alignment;
});

var PaginationSection = (0, _styledComponents2.default)(Section).withConfig({
  displayName: 'VdpAccessories__PaginationSection',
  componentId: 'sc-8op7xn-2'
})(['width:100%;margin:0;', ';'], _theme2.default.max.medium(_templateObject2));

var AccessoryBoxWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccessories__AccessoryBoxWrapper',
  componentId: 'sc-8op7xn-3'
})(['display:flex;justify-content:center;width:100%;']);

var AccessoryBox = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccessories__AccessoryBox',
  componentId: 'sc-8op7xn-4'
})(['width:33.3%;background-color:', ';display:flex;flex-direction:column;', ';background-color:', ';', ';', ';'], function (_ref6) {
  var backgroundColour = _ref6.backgroundColour;
  return '' + (backgroundColour && backgroundColour || '#f0f0f0');
}, function (_ref7) {
  var box = _ref7.box;
  return box && box.margin && 'margin: ' + box.margin + 'px';
}, function (_ref8) {
  var box = _ref8.box;
  return '' + (box && box.backgroundColour || '#f0f0f0');
}, function (_ref9) {
  var box = _ref9.box;
  return box && box.border && 'border: ' + box.border.size + 'px ' + box.border.type + ' ' + box.border.colour;
}, _theme2.default.max.medium(_templateObject3, function (props) {
  return props.mobileSelected ? 'block' : 'none';
}));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VdpAccessories__Image',
  componentId: 'sc-8op7xn-5'
})(['width:100%;border-top:1px solid #f0f0f0;background-size:cover;background-repeat:no-repeat;background-position:center;']);

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccessories__Content',
  componentId: 'sc-8op7xn-6'
})(['min-height:100px;flex:1 1 auto;padding:5%;box-sizing:border-box;', ';'], function (_ref10) {
  var border = _ref10.border;
  return border && 'border: ' + border.size + 'px ' + border.type + ' ' + border.colour;
});

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccessories__Title',
  componentId: 'sc-8op7xn-7'
})(['', ';line-height:24px;', ';padding-bottom:10px;', ' ', ';'], function (_ref11) {
  var font = _ref11.font;
  return 'font-size: ' + (font && font.fontSize || '20') + 'px';
}, function (_ref12) {
  var font = _ref12.font;
  return 'color: ' + (font && font.colour && font.colour.value || '#000');
}, function (_ref13) {
  var styleOverride = _ref13.styleOverride;
  return (0, _Global.fontStyleOverride)(styleOverride);
}, _theme2.default.max.medium(_templateObject4, function (_ref14) {
  var mobileStyleOverride = _ref14.mobileStyleOverride;
  return (0, _Global.fontStyleOverride)(mobileStyleOverride);
}));

var Subtitle = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccessories__Subtitle',
  componentId: 'sc-8op7xn-8'
})(['font-size:15px;color:#444444;line-height:21px;']);

var Description = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccessories__Description',
  componentId: 'sc-8op7xn-9'
})(['font-size:15px;color:#444444;line-height:21px;', ''], function (_ref15) {
  var styleOverride = _ref15.styleOverride;
  return (0, _Global.fontStyleOverride)(styleOverride);
});

var Button = _styledComponents2.default.button.withConfig({
  displayName: 'VdpAccessories__Button',
  componentId: 'sc-8op7xn-10'
})(['padding:0;cursor:pointer;background:none;border:none;display:', ';&:focus{outline:none;}', ';'], function (props) {
  return props.hideOnDesktop ? 'none' : 'flex';
}, _theme2.default.max.large(_templateObject5, function (props) {
  return props.hideOnDesktop ? 'flex' : 'none';
}));

var MobileSection = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccessories__MobileSection',
  componentId: 'sc-8op7xn-11'
})(['display:', ';', ';'], function (props) {
  return props.hideOnDesktop ? 'none' : 'flex';
}, _theme2.default.max.medium(_templateObject6, function (props) {
  return props.hideOnDesktop ? 'flex' : 'none';
}));

var SubHeader = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccessories__SubHeader',
  componentId: 'sc-8op7xn-12'
})(['color:#444444;font-size:15px;line-height:21px;width:40%;display:block;', ';', ''], _theme2.default.max.large(_templateObject7), function (_ref16) {
  var styleOverride = _ref16.styleOverride;
  return styleOverride;
});

var HeadingContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAccessories__HeadingContainer',
  componentId: 'sc-8op7xn-13'
})(['', ';', ';', ';'], function (_ref17) {
  var config = _ref17.config;
  return 'margin-left: ' + (config && config.marginLeft !== undefined ? config.marginLeft : '5') + '%';
}, function (_ref18) {
  var config = _ref18.config;
  return 'margin-bottom: ' + (config && config.marginBottom !== undefined ? config.marginBottom : '50') + 'px';
}, _theme2.default.max.large(_templateObject8));
function VdpAccessories(_ref19) {
  var accessories = _ref19.accessories,
      accessoriesInView = _ref19.accessoriesInView,
      nextMobile = _ref19.nextMobile,
      previousMobile = _ref19.previousMobile,
      totalPages = _ref19.totalPages,
      currentPage = _ref19.currentPage,
      mobileSelected = _ref19.mobileSelected,
      mobileCurrentPage = _ref19.mobileCurrentPage,
      mobileTotalPages = _ref19.mobileTotalPages,
      config = _ref19.config,
      globalStyling = _ref19.globalStyling;
  var accessoriesList = config.accessoriesList,
      translations = config.translations,
      paginationArrowUrl = config.paginationArrowUrl,
      accessoriesSubHeaderStyles = config.accessoriesSubHeaderStyles;


  return accessories && accessoriesInView && accessories.length ? _react2.default.createElement(
    Container,
    { 'data-cy': 'vehicle-accessories-module', config: accessoriesList },
    _react2.default.createElement(
      _ContentWrapper2.default,
      {
        contentWidth: config.contentWidth || globalStyling.contentWidth
      },
      _react2.default.createElement(
        HeadingContainer,
        { config: accessoriesList },
        _react2.default.createElement(
          _VdpHeader2.default,
          {
            qaHook: 'vehicle-accessories-header',
            styles: accessoriesList
          },
          translations.vdpAccessoriesHeader
        ),
        _react2.default.createElement(
          SubHeader,
          {
            'data-cy': 'vehicle-accessories-subheader',
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(accessoriesSubHeaderStyles) + '\n              ' + (accessoriesSubHeaderStyles && accessoriesSubHeaderStyles.divStyle) + '\n          ';
            }
          },
          translations.vdpAccessoriesSubHeader
        )
      ),
      _react2.default.createElement(
        PaginationSection,
        null,
        _react2.default.createElement(
          AccessoryBoxWrapper,
          {
            'data-cy': 'vehicle-accessories-list',
            centralise: accessories.length <= NUM_VISIBLE,
            config: accessoriesList
          },
          accessoriesInView.map(function (a, index) {
            return _react2.default.createElement(
              AccessoryBox,
              {
                key: a.title,
                mobileSelected: mobileSelected === index,
                backgroundColour: accessoriesList && accessoriesList.backgroundColour,
                box: accessoriesList && accessoriesList.box
              },
              _react2.default.createElement(Image, { src: a.imageUrl, alt: a.title || '' }),
              _react2.default.createElement(
                Content,
                {
                  border: accessoriesList && accessoriesList.itemTextBorder
                },
                _react2.default.createElement(
                  Title,
                  {
                    font: accessoriesList && accessoriesList.titleFont,
                    styleOverride: accessoriesList && accessoriesList.titleFont
                  },
                  a.title
                ),
                _react2.default.createElement(
                  Subtitle,
                  null,
                  a.subTitle
                ),
                _react2.default.createElement(
                  Description,
                  {
                    styleOverride: accessoriesList && accessoriesList.description
                  },
                  a.description
                )
              )
            );
          })
        )
      ),
      accessories.length > NUM_VISIBLE && _react2.default.createElement(
        MobileSection,
        null,
        _react2.default.createElement(
          Section,
          { alignment: 'center' },
          currentPage + '/' + totalPages
        )
      ),
      _react2.default.createElement(
        MobileSection,
        { hideOnDesktop: true },
        _react2.default.createElement(
          Section,
          { alignment: 'center' },
          _react2.default.createElement(
            Button,
            {
              style: { transform: 'rotateY(180deg)' },
              onClick: previousMobile,
              hideOnDesktop: true
            },
            paginationArrowUrl ? _react2.default.createElement('img', {
              style: { width: '20px', paddingLeft: '20px' },
              src: paginationArrowUrl,
              alt: 'view-previous-accessory'
            }) : _react2.default.createElement(_Chunky2.default, { width: '2em', height: '2em' })
          ),
          mobileCurrentPage + '/' + mobileTotalPages,
          _react2.default.createElement(
            Button,
            { onClick: nextMobile, hideOnDesktop: true },
            paginationArrowUrl ? _react2.default.createElement('img', {
              style: { width: '20px', paddingLeft: '20px' },
              src: paginationArrowUrl,
              alt: 'view-next-accessory'
            }) : _react2.default.createElement(_Chunky2.default, { width: '2em', height: '2em' })
          )
        )
      )
    )
  ) : null;
}