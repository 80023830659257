'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var useIsWindows = function useIsWindows() {
  return (0, _react.useMemo)(function () {
    return navigator.userAgent.includes('Windows');
  }, []);
};

exports.default = useIsWindows;