'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _FilterPills = require('./FilterPills');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_FilterPills).default;
  }
});

var _config = require('./config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _defaultConfig = require('./defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'Filter Pills';
var id = exports.id = 'FilterPills';
var availableToAdministration = exports.availableToAdministration = true;

var description = exports.description = 'Filter Pills Module';