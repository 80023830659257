'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    align-items: baseline;\n    flex-direction: column;\n  '], ['\n    align-items: baseline;\n    flex-direction: column;\n  ']);

exports.default = CookiePolicy;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__Container',
  componentId: 'sc-1lh1jde-0'
})(['@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){min-height:1px;}align-items:flex-end;background-color:#ffffff;box-sizing:border-box;color:#000000;display:flex;justify-content:space-between;max-height:100%;padding:40px 20px;width:100%;', ';'], _Genesis2.default.max.large(_templateObject));

var TextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__TextContainer',
  componentId: 'sc-1lh1jde-1'
})(['']);

var Title = _styledComponents2.default.h2.withConfig({
  displayName: 'CookiePolicy__Title',
  componentId: 'sc-1lh1jde-2'
})(['font-family:\'GenesisSansHeadGlobal-Light\';font-size:30px;font-weight:normal;line-height:28px;margin:0;']);

var BodyText = _styledComponents2.default.p.withConfig({
  displayName: 'CookiePolicy__BodyText',
  componentId: 'sc-1lh1jde-3'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:15px;font-weight:normal;margin:22px 0;']);

var Link = _styledComponents2.default.a.withConfig({
  displayName: 'CookiePolicy__Link',
  componentId: 'sc-1lh1jde-4'
})(['color:#000000;cursor:pointer;font-family:\'GenesisSansTextGlobal-Regular\';font-weight:normal;text-decoration:underline;']);

var Button = _styledComponents2.default.button.withConfig({
  displayName: 'CookiePolicy__Button',
  componentId: 'sc-1lh1jde-5'
})(['background-color:#000;border:0;color:#fff;cursor:pointer;font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;font-weight:normal;margin-top:22px;padding:15px 40px;white-space:nowrap;&:hover{background-color:#4c4c4c;}']);

var StickyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CookiePolicy__StickyContainer',
  componentId: 'sc-1lh1jde-6'
})(['bottom:0px;position:fixed;width:100%;z-index:999;']);

function CookiePolicy(props) {
  var config = props.config;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      hide = _useState2[0],
      setHide = _useState2[1];

  var localStorageKey = 'cookiesAccepted';

  var cookiesAccepted = localStorage.getItem(localStorageKey);
  if (cookiesAccepted === 'true' || hide) return null;

  return _react2.default.createElement(
    StickyContainer,
    null,
    _react2.default.createElement(
      Container,
      {
        'data-nosnippet': true
      },
      _react2.default.createElement(
        TextContainer,
        null,
        _react2.default.createElement(
          Title,
          null,
          config.translations.titleText
        ),
        _react2.default.createElement(
          BodyText,
          null,
          config.translations.bodyText
        ),
        _react2.default.createElement(
          Link,
          { href: config.policyLink.url, target: '_blank' },
          config.translations.policyLink
        )
      ),
      _react2.default.createElement(
        Button,
        {
          onClick: function onClick() {
            localStorage.setItem(localStorageKey, true);
            setHide(true);
          }
        },
        config.translations.buttonText
      )
    )
  );
}