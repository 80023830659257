'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']);

exports.default = SearchInput;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Search = require('./JaguarIcons/Global/Search');

var _Search2 = _interopRequireDefault(_Search);

var _Chunky = require('./JaguarIcons/Arrows/Chunky');

var _Chunky2 = _interopRequireDefault(_Chunky);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SearchContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchContainer'
}).withConfig({
  displayName: 'SearchInput__SearchContainer',
  componentId: 'sc-10654eh-0'
})(['display:flex;height:40px;width:50%;padding:0 5%;', ';'], _theme2.default.max.large(_templateObject));

var SearchIconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchIconWrapper'
}).withConfig({
  displayName: 'SearchInput__SearchIconWrapper',
  componentId: 'sc-10654eh-1'
})(['width:50px;border:1px solid #d8d8d8;box-sizing:border-box;justify-content:center;align-items:center;display:flex;']);

var GoIconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'GoIconWrapper'
}).withConfig({
  displayName: 'SearchInput__GoIconWrapper',
  componentId: 'sc-10654eh-2'
})(['background-color:', ';width:10%;display:flex;align-items:center;justify-content:center;cursor:pointer;'], function (props) {
  return props.colour ? '' + props.colour : '#000000';
});

var StyledInput = _styledComponents2.default.input.withConfig({
  displayName: 'StyledInput'
}).withConfig({
  displayName: 'SearchInput__StyledInput',
  componentId: 'sc-10654eh-3'
})(['width:80%;padding-left:5%;margin:0;border:1px solid #d8d8d8;font-size:16px;&:focus{border:1px solid #999999;outline:none;}']);

function SearchInput(_ref) {
  var onSearch = _ref.onSearch,
      _ref$placeholder = _ref.placeholder,
      placeholder = _ref$placeholder === undefined ? '' : _ref$placeholder,
      value = _ref.value,
      onChange = _ref.onChange,
      globalStyling = _ref.globalStyling;

  return _react2.default.createElement(
    SearchContainer,
    null,
    _react2.default.createElement(
      SearchIconWrapper,
      null,
      _react2.default.createElement(_Search2.default, {
        colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
        width: '1.5em',
        height: '1.5em'
      })
    ),
    _react2.default.createElement(StyledInput, {
      type: 'text',
      placeholder: placeholder,
      value: value,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (e) {
        return onChange(e.target.value);
      }),
      onKeyDown: function onKeyDown(e) {
        return e.key === 'Enter' && onSearch(value);
      }
    }),
    _react2.default.createElement(
      GoIconWrapper,
      {
        colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
        onClick: function onClick() {
          return onSearch(value);
        }
      },
      _react2.default.createElement(_Chunky2.default, { colour: '#FFFFFF' })
    )
  );
}