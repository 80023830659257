'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['padding-bottom: 20px'], ['padding-bottom: 20px']);

exports.default = SocialMediaLinks;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _footerTypes = require('../../../types/Bentley/footerTypes');

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SocialLinks = _styledComponents2.default.ul.withConfig({
  displayName: 'SocialMediaLinks__SocialLinks',
  componentId: 'sc-fvmd0f-0'
})(['list-style-type:none;display:flex;flex-wrap:wrap;padding-left:0;padding-right:0;margin:0;']);

var SocialLink = _styledComponents2.default.a.withConfig({
  displayName: 'SocialMediaLinks__SocialLink',
  componentId: 'sc-fvmd0f-1'
})(['font-size:26px;padding-right:25px;']);

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'SocialMediaLinks__IconWrapper',
  componentId: 'sc-fvmd0f-2'
})(['width:32px;height:32px;', ''], _theme2.default.max.small(_templateObject));

function SocialMediaLinks(_ref) {
  var socialMediaIconsList = _ref.socialMediaIconsList;

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl;

  return _react2.default.createElement(
    SocialLinks,
    null,
    socialMediaIconsList.map(function (socialMediaItem) {
      return _react2.default.createElement(
        'li',
        { key: socialMediaItem.id },
        _react2.default.createElement(
          SocialLink,
          {
            href: socialMediaItem.url,
            target: getTargetUrl(socialMediaItem.url),
            'data-cy': socialMediaItem.id
          },
          _react2.default.createElement(IconWrapper, { src: socialMediaItem.icon })
        )
      );
    })
  );
}