'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _router = require('../../actions/router');

var _RequestResetPassword = require('../../components/RequestResetPassword');

var _RequestResetPassword2 = _interopRequireDefault(_RequestResetPassword);

var _ResetPassword = require('../../components/ResetPassword');

var _ResetPassword2 = _interopRequireDefault(_ResetPassword);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ResetPassword = function (_Component) {
  (0, _inherits3.default)(ResetPassword, _Component);

  function ResetPassword() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, ResetPassword);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = ResetPassword.__proto__ || (0, _getPrototypeOf2.default)(ResetPassword)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        // use fragment as always kept client side
        var fragment = _this.props.history.location.hash;
        _this.resetToken = fragment && fragment.substr(1);
        var init = _this.props.actions.init;

        init();
      }
    }), Object.defineProperty(_this, 'resetToken', {
      enumerable: true,
      writable: true,
      value: undefined
    }), Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            state = _this$props.state,
            config = _this$props.config,
            actions = _this$props.actions,
            dispatch = _this$props.dispatch,
            globalStyling = _this$props.globalStyling;

        var submitResetRequest = function submitResetRequest(email) {
          return actions.requestPasswordReset({ email: email });
        };
        var changePassword = function changePassword(password) {
          return actions.resetPassword({ password: password, token: _this.resetToken });
        };
        var goToLogin = function goToLogin() {
          return dispatch(_router.actions.navigate('/signin'));
        };
        return _this.resetToken ? _react2.default.createElement(_ResetPassword2.default, {
          globalStyling: globalStyling,
          translations: config.translations,
          resetPasswordStatus: state.resetPasswordStatus,
          changePassword: changePassword,
          goToLogin: goToLogin
        }) : _react2.default.createElement(_RequestResetPassword2.default, {
          globalStyling: globalStyling,
          resetRequestStatus: state.resetRequestStatus,
          translations: config.translations,
          goToLogin: goToLogin,
          submitResetRequest: submitResetRequest
        });
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  return ResetPassword;
}(_react.Component);

exports.default = ResetPassword;