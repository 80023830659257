'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSymbolFromCurrency = undefined;

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _currencySymbolMap = require('./currencySymbolMap');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getSymbolFromCurrency = exports.getSymbolFromCurrency = function getSymbolFromCurrency(currencyCode) {
  if (typeof currencyCode !== 'string') return undefined;
  var code = currencyCode.toUpperCase();
  if (!(0, _keys2.default)(_currencySymbolMap.currencySymbolMap).find(function (c) {
    return c === code;
  })) return undefined;

  return _currencySymbolMap.currencySymbolMap[code];
};
/* eslint-disable import/prefer-default-export */