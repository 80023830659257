'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVideoThumbnail__Container',
  componentId: 'sc-1178zeo-0'
})(['display:flex;align-items:center;justify-content:center;cursor:pointer;height:60px;width:120px;background-color:#281432;background:linear-gradient( 0deg,rgba(40,20,50,0.7),rgba(40,20,50,0.7) ),', ';background-size:cover;'], function (_ref) {
  var bgImage = _ref.bgImage;
  return 'url(http:' + bgImage + ')';
});


var PlayIcon = _styledComponents2.default.img.withConfig({
  displayName: 'VdpVideoThumbnail__PlayIcon',
  componentId: 'sc-1178zeo-1'
})(['']);

var VdpVideoThumbnail = function VdpVideoThumbnail(props) {
  var imageUrl = props.imageUrl,
      showMedia = props.showMedia;

  var globalStyling = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.globalStyling;
  });

  return _react2.default.createElement(
    Container,
    { onClick: function onClick() {
        return showMedia('video');
      }, bgImage: imageUrl },
    _react2.default.createElement(PlayIcon, { src: globalStyling.icons.iconYoutubeVideo })
  );
};

exports.default = VdpVideoThumbnail;