'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  translations: {
    title: 'Jaguar Range',
    subTitle: 'Discover Jaguar’s range of award winning performance cars.',
    financeButtonText: 'Finance',
    searchButtonText: 'Search',
    monthlyPayment: 'Monthly payment',
    finalPayment: 'Final payment',
    numberOfPayments: 'Number of payments',
    totalCost: 'Total Cost',
    deposit: 'Deposit',
    apr: 'APR',
    fromText: 'From',
    vehiclePrice: 'Vehicle Price',
    interestRate: 'Interest Rate',
    respresentativeExampleHeading: 'Representative Hire Purchase Finance Example',
    representativeExampleBody: 'Representative example for XE subject to 36 month, 60,000 mile agreement',
    availableText: 'available',
    noAvailableText: 'There are currently no vehicles available',
    findOutMoreText: 'Find Out More',
    /* TODO:When this module is made configurable
    these will need to be pulled from backend services and incorporated into the model */
    disclaimerText: 'Imagery for illustration purposes only. Actual specifications may vary. Please check with your local retailer for further details.',
    financeSummary: 'Finance from {FINANCEPRICE} € per month / {APR}% APR',
    representativeExampleTermsAndConditions: 'Ejemplo de arrendamiento no vinculante con facturación de millaje dispuesto para Jaguar Bank, sucursal de FCA Bank Spain en condiciones válidas y actualizadas. Más. Costos de transferencia y admisión. Las cuotas mensuales y el monto total incluyen el seguro GAP Plus + de AXA (colisión diferencial). Esto no está sujeto al IVA. Todos los demás precios incluyen 21'
  }
};