'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: auto;\n    padding: 0;\n\n    &:after {\n      opacity: 1;\n    }\n  '], ['\n    width: auto;\n    padding: 0;\n\n    &:after {\n      opacity: 1;\n    }\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      position: static;\n      top: auto;\n    '], ['\n      position: static;\n      top: auto;\n    ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px;\n  '], ['\n    padding: 20px;\n  ']); /* eslint-disable arrow-parens */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Global = require('../../../components/Global');

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _types = require('../../../types');

var _Image = require('./Image');

var _Image2 = _interopRequireDefault(_Image);

var _types2 = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var buttonStyleOverride = function buttonStyleOverride(linkTheme) {
  return '\n  min-width: ' + (linkTheme.buttonWidth ? linkTheme.buttonWidth + 'px' : '340px') + ';\n  height: ' + (linkTheme.buttonHeight ? linkTheme.buttonHeight + 'px' : '34px') + ';\n  justify-content: flex-start;\n  position: relative;\n  z-index: 2;\n  ' + (linkTheme.backgroundColour && 'background-color: ' + linkTheme.backgroundColour + ';') + '\n  ' + (linkTheme.fontFamily && 'font-family: ' + linkTheme.fontFamily + ';') + '\n  ' + (linkTheme.textTransform && 'text-transform: ' + linkTheme.textTransform + ';') + '\n  ' + (linkTheme.fontSize && 'font-size: ' + linkTheme.fontSize + ';') + '\n  ' + (linkTheme.colour && 'color: ' + linkTheme.colour + ';') + '\n  ' + (linkTheme.characterSpacing && 'letter-spacing: ' + linkTheme.characterSpacing + ';') + '\n  ' + (linkTheme.alignment && 'justify-content: ' + linkTheme.alignment + ';') + '\n  svg > rect:not([fill=\'none\']) {\n    ' + (linkTheme.colour && 'fill: ' + linkTheme.colour + ';') + '\n  }\n  svg > path:not([fill]) {\n    ' + (linkTheme.colour && 'fill: ' + linkTheme.colour + ';') + '\n  }\n';
};

var headingOneStyleOverride = function headingOneStyleOverride(headerTheme) {
  return '\nposition: relative;\nz-index: 2;\nheight: auto;\noverflow-wrap: break-word;\n' + (headerTheme.fontFamily && 'font-family: ' + headerTheme.fontFamily + ';') + '\n' + (headerTheme.textTransform && 'text-transform: ' + headerTheme.textTransform + ';') + '\n' + (headerTheme.fontSize && 'font-size: ' + headerTheme.fontSize + ';') + '\n' + (headerTheme.colour && 'color: ' + headerTheme.colour + ';') + '\n' + (headerTheme.characterSpacing && 'letter-spacing: ' + headerTheme.characterSpacing + ';') + '\n';
};

var headingTwoStyleOverride = function headingTwoStyleOverride(bodyTheme) {
  return '\nposition: relative;\nz-index: 2;\noverflow-wrap: break-word;\n' + (bodyTheme.fontFamily && 'font-family: ' + bodyTheme.fontFamily + ';') + '\n' + (bodyTheme.textTransform && 'text-transform: ' + bodyTheme.textTransform + ';') + '\n' + (bodyTheme.fontSize && 'font-size: ' + bodyTheme.fontSize + ';') + '\n' + (bodyTheme.colour && 'color: ' + bodyTheme.colour + ';') + '\n' + (bodyTheme.characterSpacing && 'letter-spacing: ' + bodyTheme.characterSpacing + ';') + '\n' + (bodyTheme.lineHeight && 'line-height: ' + bodyTheme.lineHeight + ';') + '\n' + (bodyTheme.whiteSpace ? 'white-space: ' + bodyTheme.whiteSpace + ';' : '') + '\n';
};

var opacity = function opacity(theme) {
  return '\n  &:after {\n    content: \'\';\n    width: 100%;\n    height: 100%;\n    ' + (theme.backgroundColour && 'background-color: ' + theme.backgroundColour + ';') + '\n    ' + (theme.backgroundOpacity && 'opacity: ' + theme.backgroundOpacity + ';') + '\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 1;\n  }\n  position: relative;\n  margin: 0 0 1px 0;\n';
};

var FullWidthOpacityWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'KiaSlide__FullWidthOpacityWrapper',
  componentId: 'sc-1a8hem6-0'
})(['', ' width:100%;padding:', '%;', ';'], function (_ref) {
  var theme = _ref.theme;
  return opacity(theme);
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.padding || theme.padding === 0 ? theme.padding : 5;
}, _theme2.default.max.large(_templateObject));

var SlideContainer = _styledComponents2.default.div.withConfig({ displayName: 'SlideContainer' }).withConfig({
  displayName: 'KiaSlide__SlideContainer',
  componentId: 'sc-1a8hem6-1'
})(['display:flex;flex-direction:column;min-height:1px;']);

var SlideContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'KiaSlide__SlideContentContainer',
  componentId: 'sc-1a8hem6-2'
})(['position:absolute;top:', ';', ' ', ';'], function (_ref3) {
  var top = _ref3.top;
  return top ? top + '%' : '50px';
}, function (_ref4) {
  var align = _ref4.align,
      alignmentWidth = _ref4.alignmentWidth;
  return align && alignmentWidth && align + ': ' + alignmentWidth + '%;';
}, _theme2.default.max.large(_templateObject2));

var TopSlideContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'KiaSlide__TopSlideContentContainer',
  componentId: 'sc-1a8hem6-3'
})(['display:flex;flex-direction:column;font-weight:bold;margin:0 auto;', ' ', ';'], function (_ref5) {
  var width = _ref5.width;
  return width && 'width : ' + width + 'px;';
}, _theme2.default.max.large(_templateObject3));

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'KiaSlide__ButtonContainer',
  componentId: 'sc-1a8hem6-4'
})(['', ';'], _theme2.default.max.large(_templateObject4));

var KiaSlide = function KiaSlide(_ref6) {
  var mobileSlideHeaderFontSize = _ref6.mobileSlideHeaderFontSize,
      alignmentWidth = _ref6.alignmentWidth,
      align = _ref6.align,
      url = _ref6.url,
      translations = _ref6.translations,
      topLink = _ref6.topLink,
      midLink = _ref6.midLink,
      bottomLink = _ref6.bottomLink,
      headerTheme = _ref6.headerTheme,
      contentWidth = _ref6.contentWidth,
      _ref6$mediaContent = _ref6.mediaContent,
      mediaContent = _ref6$mediaContent === undefined ? { type: 'image', value: '', label: '' } : _ref6$mediaContent,
      bodyTheme = _ref6.bodyTheme,
      _ref6$preview = _ref6.preview,
      preview = _ref6$preview === undefined ? false : _ref6$preview,
      onLinkClick = _ref6.onLinkClick,
      linkTheme = _ref6.linkTheme,
      top = _ref6.top,
      slideContentWidth = _ref6.slideContentWidth;

  var isMobile = (0, _useCheckIsMobileScreen2.default)('large');
  var mobileOverride = function mobileOverride() {
    return isMobile && '&&&{\n      height: unset;\n      width: 100%;\n      font-size: 16px;\n      min-height: 40px;\n      margin: 5px 0;\n      min-width: unset;\n      padding: 0 20px;\n    }';
  };
  return _react2.default.createElement(
    SlideContainer,
    null,
    _react2.default.createElement(_Image2.default, { url: url, preview: preview, mediaContent: mediaContent, onLinkClick: onLinkClick }),
    _react2.default.createElement(
      _ContentWrapper2.default,
      { contentWidth: slideContentWidth },
      _react2.default.createElement(
        SlideContentContainer,
        {
          align: align,
          alignmentWidth: alignmentWidth,
          top: top
        },
        _react2.default.createElement(
          FullWidthOpacityWrapper,
          { theme: bodyTheme },
          _react2.default.createElement(
            TopSlideContentContainer,
            { width: contentWidth },
            translations.header && _react2.default.createElement(
              _Global.HeadingOne,
              {
                styleOverride: function styleOverride() {
                  return '\n                  margin: 0 0 15px;\n                  ' + headingOneStyleOverride(headerTheme) + '\n                  ' + (align && 'display: flex; justify-content: ' + align + ';') + '\n                ';
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'max.large',
                    template: 'margin: 0; padding: 5% 5% 15px;'
                  }].concat((0, _toConsumableArray3.default)(mobileSlideHeaderFontSize ? [{
                    queryPath: 'max.small',
                    template: 'font-size: ' + mobileSlideHeaderFontSize + 'px;'
                  }] : []));
                }
              },
              translations.header
            ),
            translations.body && _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return '\n                  margin: 0;\n                  ' + headingTwoStyleOverride(bodyTheme) + '\n                  ' + (align && 'display: flex; justify-content: ' + align + ';') + '\n                ';
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'max.large',
                    template: 'margin: 0; padding: 0 5% 5%;'
                  }];
                }
              },
              translations.body
            )
          )
        ),
        _react2.default.createElement(
          ButtonContainer,
          null,
          topLink.on && _react2.default.createElement(_Global.Button, {
            buttonStyle: linkTheme.buttonStyle,
            text: translations.topLink,
            'data-cy': 'button-topLink',
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return '\n                margin: 20px 5%;\n                \n                & span {\n                  margin: 0 30px;\n                }\n                \n                ' + buttonStyleOverride(linkTheme) + '\n                ' + mobileOverride() + '\n              ';
            },
            onClick: function onClick() {
              return onLinkClick(topLink.url);
            }
          }),
          midLink.on && _react2.default.createElement(_Global.Button, {
            buttonStyle: linkTheme.buttonStyle,
            text: translations.midLink,
            'data-cy': 'button-midLink',
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return '\n                margin: 20px 5%;\n  \n                & span {\n                  margin: 0 30px;\n                }\n                \n                \n                ' + buttonStyleOverride(linkTheme) + '\n                ' + mobileOverride() + '\n              ';
            },
            onClick: function onClick() {
              return onLinkClick(midLink.url);
            }
          }),
          bottomLink.on && _react2.default.createElement(_Global.Button, {
            buttonStyle: linkTheme.buttonStyle,
            text: translations.bottomLink,
            'data-cy': 'button-bottomLink',
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return '\n                margin: 20px 5%;\n\n                & span {\n                  margin: 0 30px;\n                }\n\n                \n                ' + buttonStyleOverride(linkTheme) + '\n                ' + mobileOverride() + '\n              ';
            },
            onClick: function onClick() {
              return onLinkClick(bottomLink.url);
            }
          })
        )
      )
    )
  );
};

exports.default = KiaSlide;