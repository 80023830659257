'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px 20px 0 20px;\n  '], ['\n    padding: 20px 20px 0 20px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 20px; \n  '], ['\n    padding: 0 20px; \n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _filters = require('../../../../shared/filters');

var _sessionPreferences = require('../../../../shared/sessionPreferences');

var _FiltersLookup = require('./FiltersLookup');

var _Form = require('../../Form');

var _MobileMenu = require('../../../../modules/Header/MobileMenu');

var _actions = require('../../../../actions');

var _currencyConversion = require('../../../../shared/currencyConversion');

var _routing = require('../../../../shared/routing');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FiltersWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersContainer__FiltersWrapper',
  componentId: 'sc-1ig5au9-0'
})(['', ''], _theme2.default.max.medium(_templateObject));

var SelectMenuWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersContainer__SelectMenuWrapper',
  componentId: 'sc-1ig5au9-1'
})(['border-top:0.5px solid #707070;display:flex;padding:10px;justify-content:space-around;']);

var ActionsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersContainer__ActionsWrapper',
  componentId: 'sc-1ig5au9-2'
})(['padding-left:0;display:flex;justify-content:space-between;', ''], _theme2.default.max.small(_templateObject2));

var Filters = function (_Component) {
  (0, _inherits3.default)(Filters, _Component);

  function Filters(props) {
    (0, _classCallCheck3.default)(this, Filters);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Filters.__proto__ || (0, _getPrototypeOf2.default)(Filters)).call(this, props));

    _initialiseProps.call(_this);

    _this.state = { dealerPage: (0, _routing.isDealerPage)() };
    _this.filterRefs = {};
    props.config.filterOptions.items.forEach(function (element) {
      _this.filterRefs[element.lookupKey] = _react2.default.createRef();
    });
    return _this;
  }

  (0, _createClass3.default)(Filters, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      var dealerPage = (0, _routing.isDealerPage)();
      if (dealerPage != null && dealerPage !== prevState.dealerPage) {
        this.setState({ dealerPage: dealerPage });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var props = this.props;
      var config = props.config,
          shared = props.shared,
          locale = props.locale,
          platformMode = props.platformMode,
          translations = props.translations;

      var currentLanguage = shared.sessionPreferences.language || locale;
      var currentCurrency = shared.sessionPreferences.currency;

      var languageOptions = config.utilityBarOptions && config.utilityBarOptions.find(function (op) {
        return op.key === 'Language';
      }).options;
      var currencyOptions = config.utilityBarOptions && config.utilityBarOptions.find(function (op) {
        return op.key === 'Currency';
      }).options;
      var territoryOptions = (config.utilityBarOptions && config.utilityBarOptions.find(function (op) {
        return op.key === 'Territory';
      }) || {}).options;

      var selectedLanguage = languageOptions && languageOptions.find(function (lo) {
        return lo.value === currentLanguage;
      });
      var selectedCurrency = currencyOptions && currencyOptions.find(function (co) {
        return co.value === currentCurrency;
      });

      var setPageFilters = this.createFilters(this.state.dealerPage, this.props, config, translations);
      return props.availableFilterValues ? _react2.default.createElement(
        _react.Fragment,
        null,
        platformMode && _react2.default.createElement(
          SelectMenuWrapper,
          null,
          _react2.default.createElement(_MobileMenu.SelectMenu, {
            config: props.config,
            changeLanguage: props.language,
            selectedLanguage: selectedLanguage,
            languages: languageOptions,
            changeCurrency: props.currency,
            selectedCurrency: selectedCurrency,
            currencies: currencyOptions,
            onMenuToggle: function onMenuToggle() {},
            territories: territoryOptions,
            selectedTerritory: props.marketInfo.territory,
            changeTerritory: props.territory,
            enableTitles: true
          })
        ),
        _react2.default.createElement(
          FiltersWrapper,
          { 'data-cy': 'srp-filters' },
          this.props.config.filterOptions.items.filter(function (o) {
            return !o.locked && o.active;
          }).sort(function (a, b) {
            return a.position - b.position;
          }).map(setPageFilters)
        ),
        _react2.default.createElement(
          ActionsWrapper,
          null,
          _react2.default.createElement(_Form.PrimaryButton, {
            text: props.config.translations.resetCTA,
            onClick: this.resetFilters,
            'data-cy': 'reset-filters',
            styleOverride: function styleOverride() {
              return 'padding-left: 0;\n            ';
            }
          }),
          _react2.default.createElement(_Form.PrimaryButton, {
            text: props.config.translations.applyCTA,
            onClick: function onClick() {
              return props.toggle();
            },
            styleOverride: function styleOverride() {
              return 'display:none;';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.small',
                template: 'display:flex;'
              }];
            }
          })
        )
      ) : _react2.default.createElement('div', null);
    }
  }]);
  return Filters;
}(_react.Component);

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  Object.defineProperty(this, 'resetFilters', {
    enumerable: true,
    writable: true,
    value: function value() {
      var _props = _this2.props,
          resetFilters = _props.resetFilters,
          defaultSelectedFilters = _props.config.defaultSelectedFilters;

      _this2.collapseFilters();
      resetFilters(defaultSelectedFilters);
    }
  });
  Object.defineProperty(this, 'collapseFilters', {
    enumerable: true,
    writable: true,
    value: function value() {
      var config = _this2.props.config;

      config.filterOptions.items.forEach(function (element) {
        var current = _this2.filterRefs[element.lookupKey].current;

        if (current && current.setOpenState) {
          current.setOpenState(false);
        }
      });
    }
  });
  Object.defineProperty(this, 'createFilters', {
    enumerable: true,
    writable: true,
    value: function value(onDealerPage, props, config, translations) {
      return function (fo) {
        return _FiltersLookup.optionComponents[fo.lookupKey]({
          filterKey: fo.lookupKey,
          props: (0, _extends3.default)({}, props, {
            availableFilterValues: (0, _extends3.default)({}, props.availableFilterValues, props.config.AvailableFilterOverride),
            config: config,
            translations: translations
          }),
          ref: _this2.filterRefs[fo.lookupKey],
          isDealerPage: onDealerPage
        });
      };
    }
  });
};

var mapStateToProps = function mapStateToProps(_ref) {
  var router = _ref.router,
      shared = _ref.shared,
      config = _ref.config;
  return (0, _extends3.default)({}, shared.filters, {
    page: router.path,
    locale: config.settings.locale,
    currencyCode: config.config.global.inventory.currencyCode,
    location: shared.sessionPreferences.location || {},
    platformMode: router.platformMode
  });
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, _ref2) {
  var _ref2$config$endpoint = _ref2.config.endpoints,
      endpoints = _ref2$config$endpoint === undefined ? {} : _ref2$config$endpoint;
  return {
    updateSessionPreferences: function updateSessionPreferences(key, value) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences(key, value));
    },
    updateFilters: function updateFilters(filters) {
      return dispatch(_filters.actions.updateFilters(filters, endpoints.filters));
    },
    resetFilters: function resetFilters(urlConfig) {
      dispatch(_filters.actions.resetFilters(urlConfig));
      var query = window.location.search.substring(1);
      dispatch(_filters.actions.initialiseFilters(query, urlConfig));
    },
    searchWithFilters: function searchWithFilters() {
      dispatch(_filters.actions.searchWithFilters());
      dispatch(_sessionPreferences.actions.updateSessionPreferences('resetSearchResultsPageNumber', true));
    },
    changeLocation: function changeLocation(payload) {
      return dispatch(_filters.actions.changeLocation(payload));
    },
    language: function language(option) {
      dispatch(_actions.language.actions.changeLanguage(option.value));
      dispatch(_sessionPreferences.actions.updateSessionPreferences('language', option.value));
    },
    currency: function currency(option) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('currency', option.value));
      dispatch(_currencyConversion.actions.getExchangeRates(option.value));
      dispatch(_filters.actions.resetPriceSliderValues(endpoints.filters));
    },
    countries: function countries(option) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('market', option.value));
    },
    territory: function territory(option) {
      return dispatch(_actions.territory.actions.changeTerritory(option));
    },
    onChangeLanguage: function onChangeLanguage(languageCode) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('language', languageCode));
      dispatch(_actions.language.actions.changeLanguage(languageCode));
    }
  };
};

exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Filters);