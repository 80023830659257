'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FieldValidationError = require('./FieldValidationError');

var _FieldValidationError2 = _interopRequireDefault(_FieldValidationError);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _InputAdornment = require('./InputAdornment');

var _InputAdornment2 = _interopRequireDefault(_InputAdornment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'InputField__Label',
  componentId: 'sc-66xujd-0'
})(['color:#111;font-size:14px;margin-bottom:14px;font-family:\'GenesisSansTextGlobal-Regular\';pointer-events:none;top:10px;transition:0.2s ease all;']);

var SubLabel = _styledComponents2.default.div.withConfig({
  displayName: 'InputField__SubLabel',
  componentId: 'sc-66xujd-1'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:12px;color:#111;margin-bottom:16px;']);

var Input = _styledComponents2.default.input.attrs(function () {
  return {
    required: true
  };
}).withConfig({
  displayName: 'InputField__Input',
  componentId: 'sc-66xujd-2'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px !important;padding:12px 16px;border-radius:0;border:none;border-bottom:1px solid #111;-webkit-appearance:none;-moz-appearance:none;appearance:none;-moz-appearance:none;background:#f8f8f8;&:focus{outline:none;}&:disabled{-webkit-text-fill-color:#111;-webkit-opacity:1;color:#757575;}-moz-appearance:textfield;&:-webkit-outer-spin-button,&:-webkit-inner-spin-button{-webkit-appearance:none;margin:0;}']);

var InputField = function InputField(_ref) {
  var id = _ref.id,
      label = _ref.label,
      required = _ref.required,
      type = _ref.type,
      value = _ref.value,
      onChange = _ref.onChange,
      onKeyPress = _ref.onKeyPress,
      error = _ref.error,
      disabled = _ref.disabled,
      translations = _ref.translations,
      validate = _ref.validate,
      startAdornment = _ref.startAdornment,
      endAdornment = _ref.endAdornment,
      inputRef = _ref.inputRef,
      placeholder = _ref.placeholder,
      placeholderStyle = _ref.placeholderStyle,
      _ref$orientation = _ref.orientation,
      orientation = _ref$orientation === undefined ? 'vertical' : _ref$orientation,
      subLabel = _ref.subLabel;

  return _react2.default.createElement(
    _FormField2.default,
    { orientation: orientation },
    startAdornment && _react2.default.createElement(
      _InputAdornment2.default,
      null,
      startAdornment
    ),
    _react2.default.createElement(
      Label,
      null,
      label,
      required
    ),
    subLabel && _react2.default.createElement(
      SubLabel,
      null,
      subLabel
    ),
    _react2.default.createElement(Input, {
      onWheel: function onWheel(e) {
        return e.target.blur();
      },
      type: type,
      value: value,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (event) {
        onChange({
          id: id,
          value: event.target.value,
          translations: translations,
          validate: validate,
          event: event
        });
      }),
      onKeyPress: onKeyPress,
      disabled: disabled,
      ref: inputRef,
      placeholder: placeholder,
      placeholderStyle: placeholderStyle,
      orientation: orientation
    }),
    endAdornment && _react2.default.createElement(
      _InputAdornment2.default,
      null,
      endAdornment
    ),
    error && _react2.default.createElement(
      _FieldValidationError2.default,
      null,
      error
    )
  );
};

exports.default = InputField;


InputField.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  onChange: function onChange() {},
  onKeyPress: function onKeyPress() {},
  onKeyDown: function onKeyDown() {},
  onBlur: function onBlur() {},
  onFocus: function onFocus() {},
  label: '',
  error: '',
  validate: false
};