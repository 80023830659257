'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

exports.default = Paragraph;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponentFactory = require('./styledComponentFactory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Para = (0, _styledComponentFactory.createElement)('p');

function Paragraph(_ref) {
  var styleOverride = _ref.styleOverride,
      mobileStyleOverride = _ref.mobileStyleOverride,
      props = (0, _objectWithoutProperties3.default)(_ref, ['styleOverride', 'mobileStyleOverride']);

  return _react2.default.createElement(Para, (0, _extends3.default)({
    styleOverride: styleOverride
  }, (0, _extends3.default)({}, props, (0, _styledComponentFactory.mediaProps)(mobileStyleOverride()))));
}

Paragraph.defaultProps = {
  styleOverride: function styleOverride() {
    return '';
  },
  mobileStyleOverride: function mobileStyleOverride() {
    return [];
  },
  children: null
};