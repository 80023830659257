'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.default = function (selectedValues) {
  var _useState = (0, _react.useState)({
    minValue: selectedValues.min,
    maxValue: selectedValues.max
  }),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      selectedSliderValues = _useState2[0],
      setSelectedSliderValues = _useState2[1];

  var updateSliderValues = function updateSliderValues(values) {
    var _values = (0, _slicedToArray3.default)(values, 2),
        minValue = _values[0],
        maxValue = _values[1];

    setSelectedSliderValues({
      minValue: minValue,
      maxValue: maxValue
    });
  };

  (0, _react.useEffect)(function () {
    setSelectedSliderValues({
      minValue: selectedValues.min,
      maxValue: selectedValues.max
    });
  }, [selectedValues]);

  return {
    updateSliderValues: updateSliderValues,
    selectedSliderValues: selectedSliderValues
  };
};

var _react = require('react');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }