'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  LOAD_VEHICLE_MAKES: 'LOAD_VEHICLE_MAKES',
  LOAD_VEHICLE_MAKES_SUCCESS: 'LOAD_VEHICLE_MAKES_SUCCESS',
  LOAD_VEHICLE_MAKES_FAILURE: 'LOAD_VEHICLE_MAKES_FAILURE',
  LOAD_VEHICLE_MODELS: 'LOAD_VEHICLE_MODELS',
  LOAD_VEHICLE_MODELS_SUCCESS: 'LOAD_VEHICLE_MODELS_SUCCESS',
  LOAD_VEHICLE_MODELS_FAILURE: 'LOAD_VEHICLE_MODELS_FAILURE',
  LOAD_VEHICLE_VERSIONS: 'LOAD_VEHICLE_VERSIONS',
  LOAD_VEHICLE_VERSIONS_SUCCESS: 'LOAD_VEHICLE_VERSIONS_SUCCESS',
  LOAD_VEHICLE_VERSIONS_FAILURE: 'LOAD_VEHICLE_VERSIONS_FAILURE',
  LOAD_VEHICLE_REGISTRATION_MONTHS: 'LOAD_VEHICLE_REGISTRATION_MONTHS',
  LOAD_VEHICLE_REGISTRATION_MONTHS_SUCCESS: 'LOAD_VEHICLE_REGISTRATION_MONTHS_SUCCESS',
  LOAD_VEHICLE_REGISTRATION_MONTHS_FAILURE: 'LOAD_VEHICLE_REGISTRATION_MONTHS_FAILURE',
  LOAD_VEHICLE_REGISTRATION_YEARS: 'LOAD_VEHICLE_REGISTRATION_YEARS',
  LOAD_VEHICLE_REGISTRATION_YEARS_SUCCESS: 'LOAD_VEHICLE_REGISTRATION_YEARS_SUCCESS',
  LOAD_VEHICLE_REGISTRATION_YEARS_FAILURE: 'LOAD_VEHICLE_REGISTRATION_YEARS_FAILURE',
  GET_VEHICLE_VALUATION: 'GET_VEHICLE_VALUATION',
  GET_VEHICLE_VALUATION_SUCCESS: 'GET_VEHICLE_VALUATION_SUCCESS',
  GET_VEHICLE_VALUATION_FAILURE: 'GET_VEHICLE_VALUATION_FAILURE',
  VALUATION_LIMIT_REACHED: 'VALUATION_LIMIT_REACHED',
  EDIT_VEHICLE_VALUATION: 'EDIT_VEHICLE_VALUATION',
  CANCEL_EDIT_VEHICLE_VALUATION: 'CANCEL_EDIT_VEHICLE_VALUATION'
};

var actions = exports.actions = {
  loadVehicleMakes: function loadVehicleMakes() {
    return {
      type: constants.LOAD_VEHICLE_MAKES
    };
  },
  loadVehicleMakesSuccess: function loadVehicleMakesSuccess(makes) {
    return {
      type: constants.LOAD_VEHICLE_MAKES_SUCCESS,
      payload: makes
    };
  },
  loadVehicleMakesFailure: function loadVehicleMakesFailure(error) {
    return {
      type: constants.LOAD_VEHICLE_MAKES_FAILURE,
      payload: error
    };
  },
  loadVehicleModels: function loadVehicleModels(makeId) {
    return {
      type: constants.LOAD_VEHICLE_MODELS,
      payload: makeId
    };
  },
  loadVehicleModelsSuccess: function loadVehicleModelsSuccess(models) {
    return {
      type: constants.LOAD_VEHICLE_MODELS_SUCCESS,
      payload: models
    };
  },
  loadVehicleModelsFailure: function loadVehicleModelsFailure(error) {
    return {
      type: constants.LOAD_VEHICLE_MODELS_FAILURE,
      payload: error
    };
  },
  loadVehicleVersions: function loadVehicleVersions(makeId, modelId) {
    return {
      type: constants.LOAD_VEHICLE_VERSIONS,
      payload: {
        makeId: makeId,
        modelId: modelId
      }
    };
  },
  loadVehicleVersionsSuccess: function loadVehicleVersionsSuccess(versions) {
    return {
      type: constants.LOAD_VEHICLE_VERSIONS_SUCCESS,
      payload: versions
    };
  },
  loadVehicleVersionsFailure: function loadVehicleVersionsFailure(error) {
    return {
      type: constants.LOAD_VEHICLE_VERSIONS_FAILURE,
      payload: error
    };
  },
  loadVehicleRegistrationMonths: function loadVehicleRegistrationMonths(makeId, modelId, year) {
    return {
      type: constants.LOAD_VEHICLE_REGISTRATION_MONTHS,
      payload: {
        makeId: makeId,
        modelId: modelId,
        year: year
      }
    };
  },
  loadVehicleRegistrationMonthsSuccess: function loadVehicleRegistrationMonthsSuccess(months) {
    return {
      type: constants.LOAD_VEHICLE_REGISTRATION_MONTHS_SUCCESS,
      payload: months
    };
  },
  loadVehicleRegistrationMonthsFailure: function loadVehicleRegistrationMonthsFailure(error) {
    return {
      type: constants.LOAD_VEHICLE_REGISTRATION_MONTHS_FAILURE,
      payload: error
    };
  },
  loadVehicleRegistrationYears: function loadVehicleRegistrationYears(makeId, modelId, versionId) {
    return {
      type: constants.LOAD_VEHICLE_REGISTRATION_YEARS,
      payload: {
        makeId: makeId,
        modelId: modelId,
        versionId: versionId
      }
    };
  },
  loadVehicleRegistrationYearsSuccess: function loadVehicleRegistrationYearsSuccess(years) {
    return {
      type: constants.LOAD_VEHICLE_REGISTRATION_YEARS_SUCCESS,
      payload: years
    };
  },
  loadVehicleRegistrationYearsFailure: function loadVehicleRegistrationYearsFailure(error) {
    return {
      type: constants.LOAD_VEHICLE_REGISTRATION_YEARS_FAILURE,
      payload: error
    };
  },
  getVehicleValuation: function getVehicleValuation(make, model, versionId, year, month, odometer, vehicleDetails) {
    return {
      type: constants.GET_VEHICLE_VALUATION,
      payload: {
        make: make,
        model: model,
        versionId: versionId,
        year: year,
        month: month,
        odometer: odometer,
        vehicleDetails: vehicleDetails
      }
    };
  },
  getVehicleValuationSuccess: function getVehicleValuationSuccess(valuation) {
    return {
      type: constants.GET_VEHICLE_VALUATION_SUCCESS,
      payload: valuation
    };
  },
  getVehicleValuationFailure: function getVehicleValuationFailure(error) {
    return {
      type: constants.GET_VEHICLE_VALUATION_FAILURE,
      payload: error
    };
  },
  valuationRequestLimitReached: function valuationRequestLimitReached() {
    return {
      type: constants.VALUATION_LIMIT_REACHED
    };
  },
  editVehicleValuation: function editVehicleValuation(vehicleId) {
    return {
      type: constants.EDIT_VEHICLE_VALUATION,
      payload: vehicleId
    };
  },
  cancelEditVehicleValuation: function cancelEditVehicleValuation() {
    return {
      type: constants.CANCEL_EDIT_VEHICLE_VALUATION
    };
  }
};

var initialState = {
  makes: [],
  models: [],
  versions: [],
  years: [],
  months: [],
  networkError: null,
  valuation: null,
  tempValuation: null,
  valuationRequestLimit: false
};

function reducer() {
  var _extends2;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case constants.LOAD_VEHICLE_MAKES_FAILURE:
    case constants.LOAD_VEHICLE_MODELS_FAILURE:
    case constants.LOAD_VEHICLE_VERSIONS_FAILURE:
    case constants.LOAD_VEHICLE_REGISTRATION_YEARS_FAILURE:
    case constants.LOAD_VEHICLE_REGISTRATION_MONTHS_FAILURE:
    case constants.GET_VEHICLE_VALUATION_FAILURE:
      return (0, _extends4.default)({}, state, {
        networkError: true
      });
    case constants.LOAD_VEHICLE_MAKES_SUCCESS:
      return (0, _extends4.default)({}, state, {
        makes: payload,
        networkError: null
      });
    case constants.LOAD_VEHICLE_MODELS_SUCCESS:
      return (0, _extends4.default)({}, state, {
        models: payload,
        networkError: null
      });
    case constants.LOAD_VEHICLE_VERSIONS_SUCCESS:
      return (0, _extends4.default)({}, state, {
        versions: payload,
        networkError: null
      });
    case constants.LOAD_VEHICLE_REGISTRATION_YEARS_SUCCESS:
      return (0, _extends4.default)({}, state, {
        years: payload,
        networkError: null
      });
    case constants.LOAD_VEHICLE_REGISTRATION_MONTHS_SUCCESS:
      return (0, _extends4.default)({}, state, {
        months: payload,
        networkError: null
      });
    case constants.GET_VEHICLE_VALUATION_SUCCESS:
      return (0, _extends4.default)({}, state, {
        valuation: (0, _extends4.default)({}, state.valuation, (_extends2 = {}, (0, _defineProperty3.default)(_extends2, payload.id, payload), (0, _defineProperty3.default)(_extends2, 'created', new Date()), _extends2)),
        networkError: null
      });
    case constants.VALUATION_LIMIT_REACHED:
      return (0, _extends4.default)({}, state, {
        valuationRequestLimit: true
      });
    case constants.EDIT_VEHICLE_VALUATION:
      return (0, _extends4.default)({}, state, {
        valuation: null,
        tempValuation: (0, _extends4.default)({}, state.valuation)
      });
    case constants.CANCEL_EDIT_VEHICLE_VALUATION:
      {
        var isEditing = !!state.tempValuation;
        if (isEditing) {
          return (0, _extends4.default)({}, state, {
            valuation: (0, _extends4.default)({}, state.tempValuation),
            tempValuation: null
          });
        }
        return state;
      }
    default:
      return state;
  }
}