'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  display: block;\n  cursor: pointer;\n'], ['\n  display: block;\n  cursor: pointer;\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n   ', ';\n  ', ';\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n  margin: 10px 0;\n  width: 100%;\n  ', ';\n  ', ';\n  ', ';\n'], ['\n   ', ';\n  ', ';\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n  margin: 10px 0;\n  width: 100%;\n  ', ';\n  ', ';\n  ', ';\n']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 336px;\n  '], ['\n    max-width: 336px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: calc(50% - 80px);\n  '], ['\n    width: calc(50% - 80px);\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  justify-content: space-between;\n'], ['\n  display: flex;\n  justify-content: space-between;\n']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n  align-self: left;\n'], ['\n  align-self: left;\n']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 300px;\n  '], ['\n    max-width: 300px;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  flex-direction: column;\n  align-items: ', ';\n  margin-top: -40px;\n'], ['\n  display: flex;\n  flex-direction: column;\n  align-items: ', ';\n  margin-top: -40px;\n']);

exports.default = FilterByTile;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Tick = require('./JaguarIcons/Global/Tick');

var _Tick2 = _interopRequireDefault(_Tick);

var _Plus = require('./JaguarIcons/Global/Plus');

var _Plus2 = _interopRequireDefault(_Plus);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _numbers = require('../shared/localisation/numbers');

var _finance = require('../helpers/finance');

var _FinancePopup = require('./FinancePopup');

var _FinancePopup2 = _interopRequireDefault(_FinancePopup);

var _Global = require('./Global');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _Spinner = require('./Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var alignItems = {
  left: 'flext-start',
  center: 'center',
  right: 'flex-end'
};

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'IconWrapper'
}).withConfig({
  displayName: 'FilterByTile__IconWrapper',
  componentId: 'sc-1f4wvu0-0'
})(['width:27px;height:28px;padding:2px 0 0 3px;border-radius:50%;background-color:', ';margin-top:5px;'], function (_ref) {
  var colour = _ref.colour;
  return colour;
});

var namedDiv = function namedDiv(displayName) {
  return _styledComponents2.default.div.withConfig({ displayName: displayName });
};

var TileItemContainerClick = namedDiv('ModelAndPrice')(_templateObject);

var TileItemContainer = namedDiv('TileItemContainer')(_templateObject2, function (_ref2) {
  var fullTileSelect = _ref2.fullTileSelect;
  return fullTileSelect && 'transition: background-color .2s ease-in;';
}, function (_ref3) {
  var isSelected = _ref3.isSelected;
  return isSelected ? 'background-color: #F0F0F0;' : 'background-color: none';
}, _theme2.default.min.medium(_templateObject3), _theme2.default.max.medium(_templateObject4), _theme2.default.max.small(_templateObject5));

var TopContainer = namedDiv('TopContainer')(_templateObject6);

var ModelAndPrice = namedDiv('ModelAndPrice')(_templateObject7);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'Image'
}).withConfig({
  displayName: 'FilterByTile__Image',
  componentId: 'sc-1f4wvu0-1'
})(['display:flex;align-self:center;margin-top:-20px;max-width:380px;', ';'], _theme2.default.max.small(_templateObject8));

var BottomContainer = namedDiv('BottomContainer')(_templateObject9, function (props) {
  return props.align ? alignItems[props.align] : alignItems.left;
});

var Divider = _styledComponents2.default.hr.withConfig({
  displayName: 'Divider'
}).withConfig({
  displayName: 'FilterByTile__Divider',
  componentId: 'sc-1f4wvu0-2'
})(['display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0;']);

var FinanceText = _styledComponents2.default.div.withConfig({
  displayName: 'FilterByTile__FinanceText',
  componentId: 'sc-1f4wvu0-3'
})(['display:flex;justify-content:space-between;align-items:center;width:100%;']);

function Toggle(_ref4) {
  var onClick = _ref4.onClick,
      isSelected = _ref4.isSelected,
      selectedIconColour = _ref4.selectedIconColour,
      fullTileSelect = _ref4.fullTileSelect;

  return isSelected ? _react2.default.createElement(
    IconWrapper,
    {
      colour: selectedIconColour,
      onClick: fullTileSelect ? undefined : onClick
    },
    _react2.default.createElement(_Tick2.default, { width: '25px', height: '25px', colour: '#FFFFFF', fill: '#FFFFFF' })
  ) : _react2.default.createElement(
    IconWrapper,
    {
      colour: '#7e7e7e',
      onClick: fullTileSelect ? undefined : onClick
    },
    _react2.default.createElement(_Plus2.default, { width: '25px', height: '25px', colour: '#FFFFFF' })
  );
}

var ToggleButton = (0, _styledComponents2.default)(Toggle).withConfig({
  displayName: 'ToggleButton'
}).withConfig({
  displayName: 'FilterByTile__ToggleButton',
  componentId: 'sc-1f4wvu0-4'
})(['align-self:right;']);

var FinanceLoadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FilterByTile__FinanceLoadingWrapper',
  componentId: 'sc-1f4wvu0-5'
})(['margin:10px;']);

function FilterByTile(_ref5) {
  var isSelected = _ref5.isSelected,
      align = _ref5.align,
      onIconClick = _ref5.onIconClick,
      car = _ref5.car,
      translations = _ref5.translations,
      selectedIconColour = _ref5.selectedIconColour,
      titleFont = _ref5.titleFont,
      featureFlags = _ref5.featureFlags,
      locale = _ref5.locale,
      globalStyling = _ref5.globalStyling,
      fullTileSelect = _ref5.fullTileSelect;
  var model = car.model,
      modelDisplay = car.modelDisplay,
      currencyType = car.currencyType,
      minimumPrice = car.minimumPrice,
      count = car.count,
      imageUrl = car.imageUrl,
      finance = car.finance,
      financeLoaded = car.financeLoaded;


  var FinanceLoadingText = function FinanceLoadingText() {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(_Spinner2.default, { colour: globalStyling.colours.primaryBrandColour }),
      _react2.default.createElement(
        FinanceLoadingWrapper,
        null,
        translations.financeLoadingText
      )
    );
  };

  var renderFinance = function renderFinance() {
    if (featureFlags.finance) {
      return finance ? _react2.default.createElement(
        FinanceText,
        null,
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n                margin: 0;\n                font-size: 15px;\n                color: #000000;\n              ';
            }
          },
          (0, _finance.translateFinanceFromTemplate)(translations.financeFrom, finance, locale)
        ),
        _react2.default.createElement(_FinancePopup2.default, {
          translations: translations,
          data: finance,
          locale: locale
        })
      ) : _react2.default.createElement(
        FinanceText,
        null,
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n                margin: 0;\n                font-size: 15px;\n                color: #000000;\n                line-height: 24px;\n                display: flex;\n                flex-direction: row;\n              ';
            }
          },
          featureFlags.finance && !financeLoaded ? _react2.default.createElement(FinanceLoadingText, null) : '\xA0'
        )
      );
    }
    return null;
  };

  return _react2.default.createElement(
    TileItemContainer,
    {
      isSelected: fullTileSelect && isSelected,
      fullTileSelect: fullTileSelect
    },
    _react2.default.createElement(
      TileItemContainerClick,
      {
        onClick: fullTileSelect ? onIconClick : undefined,
        isSelected: fullTileSelect && isSelected,
        fullTileSelect: fullTileSelect
      },
      _react2.default.createElement(
        TopContainer,
        null,
        _react2.default.createElement(
          ModelAndPrice,
          null,
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n              ' + (0, _Global.fontStyleOverride)(titleFont) + '\n              margin-top: 0;\n            ';
              }
            },
            modelDisplay || model
          ),
          minimumPrice && _react2.default.createElement(
            _Global.HeadingThree,
            {
              styleOverride: function styleOverride() {
                return '\n                margin: 0;\n                font-size: 15px;\n                color: #000000;\n              ';
              }
            },
            (0, _translateFromTemplate.translateFromTemplate)('searchCarFilterFromValue', {
              VALUE: (0, _numbers.localiseCurrency)(minimumPrice, locale, currencyType, 0)
            }, translations)
          )
        ),
        _react2.default.createElement(ToggleButton, {
          onClick: onIconClick,
          isSelected: isSelected,
          selectedIconColour: selectedIconColour,
          fullTileSelect: fullTileSelect
        })
      ),
      imageUrl && _react2.default.createElement(Image, { src: imageUrl })
    ),
    _react2.default.createElement(
      BottomContainer,
      { align: align },
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n            margin: 0;\n            font-size: 16px;\n          ';
          }
        },
        (0, _translateFromTemplate.translateFromTemplate)(count >= 2 ? 'searchCarFilterNumberAvailable' : 'searchCarFilterNumberAvailableSingle', {
          COUNT: count,
          MODEL: modelDisplay || model
        }, translations)
      ),
      _react2.default.createElement(Divider, null),
      renderFinance()
    )
  );
}

FilterByTile.defaultProps = {
  fullTileSelect: false,
  onClick: function onClick() {},
  modelTheme: {},
  numberAvailableTheme: {},
  financeTextTheme: {},
  selected: false
};