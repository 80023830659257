'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Text = require('../../components/Text');

var _Text2 = _interopRequireDefault(_Text);

var _LinksList = require('../../components/LinksList');

var _LinksList2 = _interopRequireDefault(_LinksList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var alignmentToFlex = function alignmentToFlex(alignment) {
  return alignment && {
    left: 'flex-start',
    right: 'flex-end'
  }[alignment] || 'center';
};

var FooterWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'One__FooterWrapper',
  componentId: 'sc-kyo1xj-0'
})(['word-break:break-word;display:flex;flex-direction:column;flex-wrap:nowrap;align-content:flex-start;width:100%;margin:0;position:relative;', ';*{box-sizing:border-box;}'], function (_ref) {
  var backgroundColour = _ref.backgroundColour;
  return backgroundColour ? 'background-color: ' + backgroundColour.value : '';
});

var NavSectionWrapper = _styledComponents2.default.nav.withConfig({
  displayName: 'One__NavSectionWrapper',
  componentId: 'sc-kyo1xj-1'
})(['display:flex;width:100%;justify-content:', ';'], function (_ref2) {
  var horizontalAlignment = _ref2.horizontalAlignment;
  return alignmentToFlex(horizontalAlignment);
});

var LogoWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'One__LogoWrapper',
  componentId: 'sc-kyo1xj-2'
})(['display:flex;width:100px;height:100%;align-items:center;justify-content:', ';'], function (_ref3) {
  var horizontalAlignment = _ref3.horizontalAlignment;
  return alignmentToFlex(horizontalAlignment);
});

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'One__Logo',
  componentId: 'sc-kyo1xj-3'
})(['max-width:150px;max-height:50px;']);

exports.default = function (_ref4) {
  var _ref4$config = _ref4.config,
      _ref4$config$nav = _ref4$config.nav,
      nav = _ref4$config$nav === undefined ? { links: [] } : _ref4$config$nav,
      _ref4$config$social = _ref4$config.social,
      social = _ref4$config$social === undefined ? { links: [] } : _ref4$config$social,
      _ref4$config$logo = _ref4$config.logo,
      logo = _ref4$config$logo === undefined ? {} : _ref4$config$logo,
      _ref4$config$footerFo = _ref4$config.footerFont,
      footerFont = _ref4$config$footerFo === undefined ? {} : _ref4$config$footerFo,
      _ref4$config$footnote = _ref4$config.footnoteFont,
      footnoteFont = _ref4$config$footnote === undefined ? {} : _ref4$config$footnote,
      _ref4$config$translat = _ref4$config.translations,
      translations = _ref4$config$translat === undefined ? {
    imageLabel: String
  } : _ref4$config$translat;

  var socialLinks = social.links.filter(function (link) {
    return link && typeof link.url === 'string';
  }).map(function (link) {
    return link.url.length && (0, _extends3.default)({}, link, {
      label: translations[link.key]
    });
  });
  return _react2.default.createElement(
    FooterWrapper,
    null,
    _react2.default.createElement(
      NavSectionWrapper,
      { horizontalAlignment: nav.horizontalAlignment },
      _react2.default.createElement(_LinksList2.default, { links: nav.links, font: footerFont })
    ),
    _react2.default.createElement(
      NavSectionWrapper,
      null,
      _react2.default.createElement(_LinksList2.default, { links: socialLinks, font: footerFont })
    ),
    _react2.default.createElement(
      NavSectionWrapper,
      null,
      _react2.default.createElement(
        LogoWrapper,
        { horizontalAlignment: logo.horizontalAlignment },
        _react2.default.createElement(Logo, { alt: translations.imageLabel, src: logo.src })
      )
    ),
    translations.footnoteText && _react2.default.createElement(
      NavSectionWrapper,
      null,
      _react2.default.createElement(_Text2.default, {
        config: {
          bodyText: translations.footnoteText,
          bodyFont: footnoteFont,
          alignment: 'center'
        }
      })
    )
  );
};