'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ThinUp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Chunky = require('./Chunky');

var _Chunky2 = _interopRequireDefault(_Chunky);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ThinUp__Wrapper',
  componentId: 'sc-198qvx4-0'
})(['transform:rotate(-90deg);']);
function ThinUp(props) {
  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(_Chunky2.default, props)
  );
}

ThinUp.defaultProps = {
  width: '1em',
  height: '1em',
  colour: null,
  background: null
};