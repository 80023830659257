'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_RANGE';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['INIT', 'GET_MODEL_GROUPS', 'GET_MODEL_GROUPS_SUCCESS', 'GET_MODEL_GROUPS_FAILURE']);

var id = exports.id = 'Range';

var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  getModelGroups: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_MODEL_GROUPS, id, function (payload) {
    return payload;
  }),
  getModelGroupsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_MODEL_GROUPS_SUCCESS, id, function (payload) {
    return payload;
  })
};

var initialState = exports.initialState = {
  error: null,
  modelGroups: []
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.GET_MODEL_GROUPS_SUCCESS:
      return (0, _extends3.default)({}, state, action.payload, {
        error: null
      });
    case constants.GET_MODEL_GROUPS_FAILURE:
      return (0, _extends3.default)({}, state, {
        error: action.payload
      });
    default:
      return state;
  }
}