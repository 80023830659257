'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Modal = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    height: auto;\n    ', '\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  '], ['\n    height: auto;\n    ', '\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  ']);

exports.useModal = useModal;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _reactScrolllock = require('react-scrolllock');

var _reactScrolllock2 = _interopRequireDefault(_reactScrolllock);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useModal() {
  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      isModalVisible = _useState2[0],
      setModalVisible = _useState2[1];

  var toggleModal = function toggleModal() {
    setModalVisible(!isModalVisible);
  };

  return {
    isModalVisible: isModalVisible,
    toggleModal: toggleModal
  };
}

var Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'useModal__Overlay',
  componentId: 'sc-bc9o62-0'
})(['position:fixed;height:100vh;width:100%;top:0;left:0;background-color:rgba(0,0,0,0.9);visibility:hidden;opacity:0;z-index:100;overflow:hidden;transition:visibility 0.35s,opacity 0.35s;visibility:visible;opacity:1;']);

var ModalContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'useModal__ModalContentWrapper',
  componentId: 'sc-bc9o62-1'
})(['width:100%;height:100vh;position:absolute;top:0;left:0;*{box-sizing:border-box;}* img{display:block;}', ';'], _theme2.default.min.medium(_templateObject, function (_ref) {
  var width = _ref.width;
  return width && 'width: ' + width + ';';
}));

var ModalContent = _styledComponents2.default.div.withConfig({
  displayName: 'useModal__ModalContent',
  componentId: 'sc-bc9o62-2'
})(['width:100%;height:100%;background:white;']);

var Close = _styledComponents2.default.div.withConfig({
  displayName: 'useModal__Close',
  componentId: 'sc-bc9o62-3'
})(['position:absolute;top:0;right:0;box-sizing:border-box;margin:10px 10px;cursor:pointer;z-index:999;font-size:1.5em;color:white;']);

var CloseImg = _styledComponents2.default.img.withConfig({
  displayName: 'useModal__CloseImg',
  componentId: 'sc-bc9o62-4'
})(['background-color:rgba(0,0,0,0.4);border-radius:40px;padding:5px;']);

var closeImgURL = 'https://res.cloudinary.com/motortrak/image/upload/v1564484782/cms/global/icons/x.svg';

var Modal = exports.Modal = function Modal(_ref2) {
  var isModalVisible = _ref2.isModalVisible,
      hide = _ref2.hide,
      width = _ref2.width,
      children = _ref2.children;
  return isModalVisible ? _reactDom2.default.createPortal(_react2.default.createElement(
    Overlay,
    null,
    _react2.default.createElement(
      Close,
      { onClick: hide },
      _react2.default.createElement(CloseImg, { src: closeImgURL, alt: 'close' })
    ),
    _react2.default.createElement(
      ModalContentWrapper,
      {
        modalWidth: width,
        'aria-modal': true,
        'aria-hidden': true,
        tabIndex: -1,
        role: 'dialog'
      },
      _react2.default.createElement(
        ModalContent,
        null,
        children
      )
    ),
    _react2.default.createElement(_reactScrolllock2.default, null)
  ), document.body) : null;
};