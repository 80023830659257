'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends5 = require('babel-runtime/helpers/extends');

var _extends6 = _interopRequireDefault(_extends5);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

var _helpers = require('../../shared/currencyConversion/helpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_VEHICLE_SEARCH_RESULTS';

var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['SHOW_ALL', 'GET_INVENTORY', 'GET_INVENTORY_SUCCESS', 'INIT', 'GET_FINANCE_SUCCESS', 'GET_DEALER', 'GET_DEALER_SUCCESS', 'CLEAR_ALL', 'CHANGE_PAGE', 'LOAD_FINANCE', 'CANCEL_LOADING', 'CLEAR_LOADED', 'UPDATE_VEHICLE_PRICES', 'UPDATE_VEHICLE_PRICES_SUCCESS', 'GET_SIMILAR_CARS', 'GET_SIMILAR_CARS_SUCCESS']);

var id = exports.id = 'VehicleSearchResults';

var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  showAll: (0, _reducerMacros.moduleActionWithArgs)(constants.SHOW_ALL, id, function (payload) {
    return payload;
  }),
  getInventory: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY, id, function (payload) {
    return payload;
  }),
  getInventorySuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY_SUCCESS, id, function (config) {
    return config;
  }),
  getFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  }),
  getDealer: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_DEALER, id, function (dealerId) {
    return dealerId;
  }),
  getDealerSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_DEALER_SUCCESS, id, function (dealer) {
    return dealer;
  }),
  changePage: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_PAGE, id, function (payload) {
    return payload;
  }),
  loadFinance: (0, _reducerMacros.moduleActionWithArgs)(constants.LOAD_FINANCE, id, function (payload) {
    return payload;
  }),
  cancelLoading: (0, _reducerMacros.moduleActionWithArgs)(constants.CANCEL_LOADING, id, function (payload) {
    return payload;
  }),
  clearLoaded: (0, _reducerMacros.moduleAction)(constants.CLEAR_LOADED, id),
  updateVehiclePrices: (0, _reducerMacros.moduleActionWithArgs)(constants.UPDATE_VEHICLE_PRICES, id, function (payload) {
    return payload;
  }),
  updateVehiclePricesSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.UPDATE_VEHICLE_PRICES_SUCCESS, id, function (payload) {
    return payload;
  }),
  getSimilarCars: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_SIMILAR_CARS, id, function (payload) {
    return payload;
  }),
  getSimilarCarsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_SIMILAR_CARS_SUCCESS, id, function (payload) {
    return payload;
  })
};

var replaceIfExists = function replaceIfExists(vehicles, vehicle) {
  return [].concat((0, _toConsumableArray3.default)(vehicles.map(function (v) {
    return v.id === vehicle.id ? vehicle : v;
  })));
};

var updateVehicleCurrencies = function updateVehicleCurrencies(state, action) {
  var payload = action.payload;
  var exchangeRates = payload.exchangeRates;
  var currency = payload.currency;

  var baseRates = exchangeRates && exchangeRates[currency] && exchangeRates[currency].rates;
  var pageNumbers = (0, _keys2.default)(state.searchResults);

  var convertedResultsPerPage = pageNumbers.map(function (pN) {
    var results = state.searchResults[pN];
    var converted = baseRates ? (0, _helpers.convertVehiclesPriceCurrency)(results, baseRates, currency) : results;
    return { page: pN, results: converted };
  });
  var reduced = convertedResultsPerPage.reduce(function (acc, curr) {
    return (0, _extends6.default)({}, acc, (0, _defineProperty3.default)({}, curr.page, curr.results));
  }, {});

  return (0, _extends6.default)({}, state, {
    searchResults: reduced
  });
};

var initialState = exports.initialState = {
  infiniteScroll: false,
  loading: false,
  searchResults: {},
  similarCars: null,
  pageSize: 9,
  currentPage: 1
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.SHOW_ALL:
      {
        return (0, _extends6.default)({}, state, {
          infiniteScroll: true
        });
      }
    case constants.CLEAR_LOADED:
      {
        return (0, _extends6.default)({}, state, {
          searchResults: {},
          totalResults: null,
          infiniteScroll: false,
          currentPage: 1,
          loading: false
        });
      }
    case constants.CANCEL_LOADING:
      {
        var resetToFirstPage = action.payload;
        return (0, _extends6.default)({}, state, {
          loading: false,
          infiniteScroll: false
        }, resetToFirstPage && {
          currentPage: 1
        });
      }
    case constants.GET_INVENTORY:
      {
        return (0, _extends6.default)({}, state, {
          loading: true,
          filters: action.payload.filters,
          similarCars: null
        });
      }
    case constants.CHANGE_PAGE:
      {
        return (0, _extends6.default)({}, state, {
          currentPage: action.payload,
          loading: false
        });
      }
    case constants.GET_INVENTORY_SUCCESS:
      {
        var _action$payload = action.payload,
            pageNumber = _action$payload.pageNumber,
            contents = _action$payload.contents,
            total = _action$payload.total;

        return (0, _extends6.default)({}, state, {
          loading: false,
          searchResults: (0, _extends6.default)({}, state.searchResults, (0, _defineProperty3.default)({}, pageNumber, contents)),
          totalResults: total,
          currentPage: pageNumber
        });
      }
    case constants.GET_FINANCE_SUCCESS:
      {
        var _action$payload2 = action.payload,
            vehicle = _action$payload2.vehicle,
            _pageNumber = _action$payload2.pageNumber;

        return (0, _extends6.default)({}, state, {
          searchResults: (0, _extends6.default)({}, state.searchResults, (0, _defineProperty3.default)({}, _pageNumber, replaceIfExists(state.searchResults[_pageNumber] || [], vehicle)))
        });
      }
    case constants.GET_DEALER_SUCCESS:
      {
        return (0, _extends6.default)({}, state, {
          dealer: action.payload
        });
      }
    case constants.UPDATE_VEHICLE_PRICES:
      return updateVehicleCurrencies(state, action);
    case constants.GET_SIMILAR_CARS:
      {
        return (0, _extends6.default)({}, state, {
          loading: true,
          similarCars: null
        });
      }
    case constants.GET_SIMILAR_CARS_SUCCESS:
      {
        return (0, _extends6.default)({}, state, {
          loading: false,
          similarCars: action.payload
        });
      }
    default:
      return state;
  }
}