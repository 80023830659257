'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _lodash = require('lodash.debounce');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _reactRedux = require('react-redux');

var _isoCountries = require('../constants/isoCountries');

var _useRegionsHook2 = require('../shared/hooks/useRegionsHook');

var _ipInformation = require('../shared/localisation/ipInformation');

var _settings = require('../shared/selectors/settings');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (browserLocationCallback, inputLocationCallback, options) {
  var _useRegionsHook = (0, _useRegionsHook2.useRegionsHook)(),
      setRegionFilters = _useRegionsHook.setRegionFilters;

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      userLocationEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.userLocationEnabled
    };
  }),
      userLocationEnabled = _useSelector.userLocationEnabled;

  var googleAvailable = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.google.googleAvailable;
  });
  var countryName = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.country;
  });
  var userIpLatitude = (0, _ipInformation.getIpLatitude)();
  var userIpLongitude = (0, _ipInformation.getIpLongitude)();
  var userIpCountryCode = (0, _ipInformation.getIpCountryCode)();
  var storedLocation = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.location;
  });
  var geocoder = googleAvailable ? new window.google.maps.Geocoder() : {};

  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      formattedLocation = _useState2[0],
      setFormattedLocation = _useState2[1];

  var _useState3 = (0, _react.useState)([]),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      suggestions = _useState4[0],
      setSuggestions = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      visible = _useState6[0],
      setVisible = _useState6[1];

  var _useState7 = (0, _react.useState)(0),
      _useState8 = (0, _slicedToArray3.default)(_useState7, 2),
      currentlySelected = _useState8[0],
      setCurrentlySelected = _useState8[1];

  var _useState9 = (0, _react.useState)('unsubmitted'),
      _useState10 = (0, _slicedToArray3.default)(_useState9, 2),
      status = _useState10[0],
      setStatus = _useState10[1];

  var _useState11 = (0, _react.useState)(false),
      _useState12 = (0, _slicedToArray3.default)(_useState11, 2),
      isLoading = _useState12[0],
      setLoading = _useState12[1];

  var _useState13 = (0, _react.useState)(false),
      _useState14 = (0, _slicedToArray3.default)(_useState13, 2),
      isError = _useState14[0],
      setError = _useState14[1];

  var _useState15 = (0, _react.useState)(0),
      _useState16 = (0, _slicedToArray3.default)(_useState15, 2),
      attempts = _useState16[0],
      setAttempts = _useState16[1];

  var inputRef = (0, _react.useRef)(null);
  var countryCode = options.countryCode,
      latitude = options.latitude,
      longitude = options.longitude,
      ignorePreferredLocation = options.ignorePreferredLocation;

  var applyRequestParams = function applyRequestParams(params, country) {
    var restrictions = country ? { componentRestrictions: { country: country } } : {};
    return (0, _extends3.default)({}, params, restrictions);
  };
  /*
  these strings are response types from the geocode api that
  are the types we want to display. This is essentially so we dont show things
  like building names in our results.
  */
  var configLocationTypes = ['locality', 'postal_code'].concat((0, _toConsumableArray3.default)((0, _reactRedux.useSelector)(_settings.additionalLocationTypes)));

  var findResults = function findResults(results) {
    return results.find(function (res) {
      return res.types.find(function (t) {
        return configLocationTypes.includes(t);
      });
    });
  };

  var getCountryShortName = function getCountryShortName(results) {
    return results.address_components.find(function (t) {
      return t.types[0].includes('country');
    }).short_name;
  };

  (0, _react.useEffect)(function () {
    if (latitude && longitude) {
      setAttempts(0);
      setStatus('submitting');
      setRegionFilters(userIpCountryCode);
    } else {
      setFormattedLocation(null);
    }
    if (userLocationEnabled && !latitude && !longitude) {
      browserLocationCallback(userIpLatitude, userIpLongitude, '', '');
    }
  }, [latitude, longitude]);

  (0, _react.useEffect)(function () {
    if (googleAvailable && attempts < 3 && status === 'submitting') {
      setLoading(true);
      geocoder.geocode(applyRequestParams({
        location: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude)
        }
      }), function (results, stat) {
        setLoading(false);

        var timeout = void 0;
        if (stat === 'OVER_QUERY_LIMIT') {
          timeout = setTimeout(function () {
            return setAttempts(attempts + 1);
          }, 2000);
        }
        if (stat === window.google.maps.GeocoderStatus.OK && results.length) {
          var address = findResults(results);
          if (address) {
            clearTimeout(timeout);
            setRegionFilters(getCountryShortName(findResults(results)));
            setFormattedLocation(userLocationEnabled && latitude === userIpLatitude ? null : address.formatted_address);
            setAttempts(4);
            setStatus('successful');
          }
        }
      });
    }
    setLoading(false);
  }, [status, attempts]);

  var getInputLocation = function getInputLocation() {
    var suggestion = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    setSuggestions([]);
    setFormattedLocation(suggestion.description);
    setLoading(true);

    geocoder.geocode(applyRequestParams({ placeId: suggestion.place_id }), function (results, stat) {
      setLoading(false);
      if (stat === window.google.maps.GeocoderStatus.OK && results.length) {
        var address = findResults(results);
        var locationTown = address.address_components[0].short_name;
        if (address) {
          inputLocationCallback(address.geometry.location.lat().toString(), address.geometry.location.lng().toString(), locationTown, getCountryShortName(findResults(results)));
        }
      }
    });
  };

  var getBrowserLocation = function getBrowserLocation() {
    if (!('geolocation' in navigator)) {
      return;
    }
    setLoading(true);
    navigator.geolocation.getCurrentPosition(function (geo) {
      geocoder.geocode(applyRequestParams({
        location: {
          lat: parseFloat(geo.coords.latitude),
          lng: parseFloat(geo.coords.longitude)
        }
      }), function (results, stat) {
        setLoading(false);
        if (stat === window.google.maps.GeocoderStatus.OK && results.length) {
          var address = findResults(results);
          if (address) {
            setFormattedLocation(address.formatted_address);
            browserLocationCallback(address.geometry.location.lat().toString(), address.geometry.location.lng().toString(), address.address_components[1].long_name, getCountryShortName(findResults(results)));
          }
        }
      });
    }, function (err) {
      setLoading(false);
      // TODO: For now only register the error on the console
      // eslint-disable-next-line no-console
      console.error({ err: err });
      setError(true);
    });
  };
  var loadPredictions = function loadPredictions(query) {
    if (googleAvailable) {
      var googleService = new window.google.maps.places.AutocompleteService();
      if (query.length < 3) {
        return;
      }
      setLoading(true);
      setVisible(true);
      googleService.getPlacePredictions(applyRequestParams({
        input: query
      }, countryCode || _isoCountries.isoCountries[countryName]), function (results, stat) {
        setLoading(false);
        if (stat === google.maps.places.PlacesServiceStatus.OK && results && results.length) {
          var filteredResults = results.filter(function (r) {
            return r.types.some(function (t) {
              return configLocationTypes.includes(t);
            });
          });

          setSuggestions(filteredResults);
        }
      });
    }
  };

  var delayedPredictions = (0, _react.useCallback)((0, _lodash2.default)(function (q) {
    return loadPredictions(q);
  }, 1000), [googleAvailable]);

  var onInputChange = function onInputChange(_ref) {
    var value = _ref.currentTarget.value;

    setFormattedLocation(value);
    delayedPredictions(value);
  };

  var keyboardCodes = {
    space: ' ',
    enter: 'Enter',
    down: 'ArrowDown',
    up: 'ArrowUp'
  };

  var onInputKeyDown = function onInputKeyDown(ev) {
    if (ev.key === keyboardCodes.up) {
      ev.preventDefault();
      var newSelected = currentlySelected === 0 ? suggestions.length - 1 : currentlySelected - 1;
      setCurrentlySelected(newSelected);
    }
    if (ev.key === keyboardCodes.down) {
      ev.preventDefault();
      var _newSelected = currentlySelected === suggestions.length - 1 ? 0 : currentlySelected + 1;
      setCurrentlySelected(_newSelected);
    }
    if (ev.key === keyboardCodes.enter) {
      ev.preventDefault();
      if (suggestions.length > 0) {
        getInputLocation(suggestions[currentlySelected]);
        if (inputRef && inputRef.current) {
          inputRef.current.blur();
        }
      }
    }
  };

  var onInputBlur = function onInputBlur(e, defaultPlaceholderText) {
    e.target.placeholder = defaultPlaceholderText;
    setVisible(false);
  };

  var onInputFocus = function onInputFocus(e) {
    e.target.placeholder = '';
    setCurrentlySelected(0);
  };

  return {
    currentlySelected: currentlySelected,
    formattedLocation: formattedLocation === null && !ignorePreferredLocation ? storedLocation.formattedLocation : formattedLocation,
    getBrowserLocation: getBrowserLocation,
    getInputLocation: getInputLocation,
    googleAvailable: googleAvailable,
    inputRef: inputRef,
    isError: isError,
    isLoading: isLoading,
    onInputBlur: onInputBlur,
    onInputChange: onInputChange,
    onInputFocus: onInputFocus,
    onInputKeyDown: onInputKeyDown,
    setCurrentlySelected: setCurrentlySelected,
    suggestions: suggestions,
    visible: visible
  };
};