'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 8px 16px;\n  '], ['\n    padding: 8px 16px;\n  ']);

exports.default = SocialMediaLinks;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _footerTypes = require('../../../types/AstonMartin/footerTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SocialLinks = _styledComponents2.default.ul.withConfig({
  displayName: 'SocialMediaLinks__SocialLinks',
  componentId: 'sc-fia74p-0'
})(['list-style-type:none;display:flex;justify-content:center;padding-left:0;padding-right:0;margin:0;', ';'], _theme2.default.max.large(_templateObject));

var SocialLink = _styledComponents2.default.a.withConfig({
  displayName: 'SocialMediaLinks__SocialLink',
  componentId: 'sc-fia74p-1'
})(['font-size:26px;padding:0 10px;']);

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'SocialMediaLinks__IconWrapper',
  componentId: 'sc-fia74p-2'
})(['width:18px;height:18px;']);

function SocialMediaLinks(_ref) {
  var socialMediaIconsList = _ref.socialMediaIconsList;

  return _react2.default.createElement(
    SocialLinks,
    null,
    socialMediaIconsList.map(function (socialMediaItem) {
      return _react2.default.createElement(
        'li',
        { key: socialMediaItem.id },
        _react2.default.createElement(
          SocialLink,
          {
            href: socialMediaItem.url,
            target: '_blank',
            'data-cy': socialMediaItem.id
          },
          _react2.default.createElement(IconWrapper, { src: socialMediaItem.icon })
        )
      );
    })
  );
}