'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _styledComponents2.default.div.withConfig({
  displayName: 'Wrapper',
  componentId: 'sc-fflq05-0'
})(['display:flex;position:relative;word-break:break-all;flex-direction:', ';flex:1 0 25%;', ';', ';', ';'], function (props) {
  return props.flexDirection || 'column';
}, function (_ref) {
  var border = _ref.border;
  return border && border.map(function (val) {
    return 'border-' + val + ': 1px solid;';
  }).join(' ');
}, function (_ref2) {
  var borderColour = _ref2.borderColour;
  return borderColour && 'border-color: ' + borderColour.value + ';';
}, function (_ref3) {
  var backgroundColour = _ref3.backgroundColour;
  return backgroundColour && 'background-color: ' + backgroundColour.value;
});