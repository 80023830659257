'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px 5%;\n  '], ['\n    padding: 20px 5%;\n  ']);

exports.default = CodeweaversFinance;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reactScriptTag = require('react-script-tag');

var _reactScriptTag2 = _interopRequireDefault(_reactScriptTag);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _dates = require('../../shared/localisation/dates');

var _Global = require('../../components/Global');

var _Spinner = require('../../components/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _useChangeReleaseFlag = require('../../utilities/useChangeReleaseFlag');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'CodeweaversFinance__Container',
  componentId: 'sc-nco2sk-0'
})(['box-sizing:border-box;display:flex;border-bottom:1px solid #dddddd;background-color:#ffffff;justify-content:center;padding:20px 0;', ';'], _theme2.default.max.medium(_templateObject));

var ButtonContainer = (0, _styledComponents2.default)(Container).withConfig({
  displayName: 'CodeweaversFinance__ButtonContainer',
  componentId: 'sc-nco2sk-1'
})(['padding:40px 0;']);

var SpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CodeweaversFinance__SpinnerWrapper',
  componentId: 'sc-nco2sk-2'
})(['display:flex;justify-content:center;width:100%;']);

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CodeweaversFinance__Wrapper',
  componentId: 'sc-nco2sk-3'
})(['display:flex;flex-direction:column;max-width:1200px;width:100%;']);

var PluginContent = _styledComponents2.default.div.withConfig({
  displayName: 'CodeweaversFinance__PluginContent',
  componentId: 'sc-nco2sk-4'
})(['height:auto;width:100%;padding-top:32px;background:white;']);

function loadPlugin() {
  var vehicle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var setShowSpinner = arguments[1];

  var formatedDate = vehicle.regDate && (0, _dates.localeFormattedDate)(vehicle.regDate, 'en-gb', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  window.codeweavers.main({
    pluginContentDivId: 'pluginContent',
    vehicle: {
      type: 'car',
      identifier: vehicle.capCode,
      identifierType: 'CapLongCode',
      isNew: false,
      cashPrice: vehicle.price.value,
      mileage: vehicle.specification.odometer && vehicle.specification.odometer.reading,
      registration: {
        year: vehicle.registration,
        plate: vehicle.registrationPlate,
        date: formatedDate
      },
      imageUrl: vehicle.images[0],
      linkBackUrl: window.location.href
    },
    organisationIdentifier: {
      type: 'AssociatedDealerKey',
      value: vehicle.retailerInformation.oemId
    },
    defaultParameters: {
      deposit: {
        defaultValue: '10',
        defaultType: 'Percentage'
      },
      term: {
        defaultValue: 48
      },
      annualMileage: {
        defaultValue: 10000
      }
    },
    onDomainEvent: function onDomainEvent(eventName) {
      if (eventName === 'plugin.loaded' || eventName === 'plugin.errored') {
        setShowSpinner(false);
      }
    }
  });
}

function CodeweaversFinance(_ref) {
  var _ref$config = _ref.config,
      codeWeaversKey = _ref$config.codeWeaversKey,
      financeAvailableCountries = _ref$config.financeAvailableCountries,
      translations = _ref$config.translations,
      globalStyling = _ref.globalStyling;

  var _useState = (0, _react.useState)(),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      scriptLoaded = _useState2[0],
      setScriptLoaded = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      open = _useState4[0],
      setOpen = _useState4[1];

  var _useState5 = (0, _react.useState)(),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      showSpinner = _useState6[0],
      setShowSpinner = _useState6[1];

  var vdp = (0, _reactRedux.useSelector)(function (state) {
    var vdpState = state.pages['/vdp'];

    var _ref2 = (0, _values2.default)(vdpState || {}).find(function (v) {
      return !!v.vdpConfig;
    }) || {},
        vehicle = _ref2.vdpConfig;

    return vehicle;
  });

  (0, _react.useEffect)(function () {
    if (vdp && scriptLoaded) {
      loadPlugin(vdp, setShowSpinner);
    }
  }, [vdp, scriptLoaded, setShowSpinner]);

  var shouldDisplayFinance = function shouldDisplayFinance() {
    return (0, _useChangeReleaseFlag.useChangeReleaseFlag)('WO-21607') && vdp && financeAvailableCountries.includes(vdp.retailerInformation.country) && vdp.specification.odometer && vdp.price.value !== 0;
  };

  return shouldDisplayFinance() ? !open ? _react2.default.createElement(
    ButtonContainer,
    { id: 'pluginContent' },
    _react2.default.createElement(_Global.Button, {
      'data-cy': 'discover-quote-cta',
      text: translations.financeQuoteTextButton,
      applyStyle: 'primary',
      buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
      styleOverride: function styleOverride() {
        return '\n            padding: 0 20px;\n            width: 500px;\n            height: 60px;\n            font-size: 22px;\n          ';
      },
      mobileStyleOverride: function mobileStyleOverride() {
        return 'font-size: 12px;';
      },
      onClick: function onClick() {
        setOpen(true);
        setShowSpinner(true);
      }
    })
  ) : _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Wrapper,
      null,
      showSpinner && _react2.default.createElement(
        SpinnerWrapper,
        null,
        _react2.default.createElement(_Spinner2.default, {
          size: 50,
          colour: globalStyling.colours.primaryBrandColour
        })
      ),
      scriptLoaded ? _react2.default.createElement(PluginContent, { id: 'pluginContent' }) : _react2.default.createElement(_reactScriptTag2.default, {
        id: 'pluginContent',
        type: 'text/javascript',
        onLoad: function onLoad() {
          setScriptLoaded(true);
        },
        src: 'https://plugins.codeweavers.net/scripts/v1/codeweavers/finance?ApiKey=' + codeWeaversKey
      })
    )
  ) : null;
}