'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _querystring = require('querystring');

var _querystring2 = _interopRequireDefault(_querystring);

var _SearchResults = require('../../components/SearchResults/SearchResults');

var _SearchResults2 = _interopRequireDefault(_SearchResults);

var _MobileSearchPanel = require('../../components/MobileSearchPanel');

var _MobileSearchPanel2 = _interopRequireDefault(_MobileSearchPanel);

var _router = require('../../actions/router');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _SubNav = require('../../containers/SubNav');

var _SubNav2 = _interopRequireDefault(_SubNav);

var _compare = require('../../shared/compare');

var _searchFilter = require('../../shared/searchFilter');

var _shortlist = require('../../shared/shortlist');

var _sessionPreferences = require('../../shared/sessionPreferences');

var _settings = require('../../shared/sortOptions/settings');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _searchFiltersHelper = require('../../components/SearchFilters/search-filters-helper');

var _vehicle = require('../../helpers/vehicle');

var _vehicleStore = require('../../shared/selectors/vehicleStore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FIRST_PAGE = 1;

var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__MobileOnly',
  componentId: 'sc-11xalv4-0'
})(['display:flex;', ';'], _theme2.default.min.large(_templateObject));

var themeFromProps = function themeFromProps(_ref) {
  var typeface = _ref.typeface,
      fontSize = _ref.fontSize,
      kerning = _ref.kerning,
      transform = _ref.transform,
      colour = _ref.colour;
  return {
    fontFamily: typeface ? typeface.label : '',
    fontSize: fontSize ? fontSize + 'px' : '',
    characterSpacing: kerning ? kerning + 'px' : '',
    textTransform: transform || '',
    colour: colour ? colour.value : ''
  };
};

var SearchResults = function (_Component) {
  (0, _inherits3.default)(SearchResults, _Component);

  function SearchResults(props) {
    (0, _classCallCheck3.default)(this, SearchResults);

    var _this = (0, _possibleConstructorReturn3.default)(this, (SearchResults.__proto__ || (0, _getPrototypeOf2.default)(SearchResults)).call(this, props));

    _initialiseProps.call(_this);

    var selectedView = window.innerWidth < _theme.screenSizeOptions.large ? 'GRID' : 'LIST';
    _this.state = {
      filtersExpanded: false,
      leasingExampleExpanded: false,
      selectedView: selectedView,
      searchUrl: ''
    };
    return _this;
  }

  (0, _createClass3.default)(SearchResults, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // We call init to force a first run of the reducer
      // - there used to be a more generic solution,
      //   but it broke the builder
      var _props = this.props,
          actions = _props.actions,
          dispatch = _props.dispatch,
          history = _props.history,
          shared = _props.shared,
          router = _props.router;


      var queryParams = _querystring2.default.parse(history.location.search.substring(1));

      this.retailerId = router.pathProps.retailerId;

      if (queryParams.models) {
        var models = typeof queryParams.models === 'string' ? [queryParams.models] : [].concat((0, _toConsumableArray3.default)(queryParams.models));

        dispatch(_searchFilter.actions.selectMultipleCheckboxes({
          section: 'models',
          values: models
        }));
      }

      dispatch(_searchFilter.actions.updateQuery());
      if ((0, _keys2.default)(shared.sessionPreferences.location).length && this.retailerId) {
        dispatch(_searchFilter.actions.changeMaxDistance('all'));
      }
      actions.init();
      dispatch(_shortlist.actions.getVehicles());

      if (this.retailerId) {
        actions.getDealer(this.retailerId);
      }

      window.addEventListener('resize', this.onViewportChange);

      var vdpMatcher = /.*\/vdp\/.*/i;
      var previousRoute = router.navigationHistory[1];
      var comeFromVdp = vdpMatcher.test(previousRoute);

      this.forceUpdate = !comeFromVdp || comeFromVdp && this.retailerId;

      if (shared.sessionPreferences.infiniteScroll) {
        this.onShowAllResultsClick();
      } else {
        actions.cancelLoading(this.forceUpdate);
        window.scroll(0, 0);
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (prevProps.shared.searchFilter.filterSearchUrl !== this.props.shared.searchFilter.filterSearchUrl && !this.props.shared.searchFilter.filterSearchUrl.checkboxUrl) {
        window.history.replaceState({}, '', window.location.pathname);
      }

      if (this.forceUpdate) {
        this.props.actions.clearLoaded();
        this.updateInventory(FIRST_PAGE, this.props.shared.sessionPreferences.searchSort, this.props.preview, true);
        this.forceUpdate = false;
      } else if (prevProps.shared.searchFilter.searchUrl !== this.props.shared.searchFilter.searchUrl || this.state.searchUrl !== this.props.shared.searchFilter.searchUrl || !this.props.state.loading && !this.props.state.searchResults[FIRST_PAGE]) {
        this.props.actions.clearLoaded();

        this.setState({
          searchUrl: this.props.shared.searchFilter.searchUrl
        });

        this.updateInventory(FIRST_PAGE, this.props.shared.sessionPreferences.searchSort, this.props.preview);
      }
      var modelSearchUrl = this.props.shared.searchFilter.modelSearchUrl;

      if (prevProps.shared.searchFilter.modelSearchUrl !== modelSearchUrl || this.props.shared.sessionPreferences.location !== prevProps.shared.sessionPreferences.location) {
        this.updateInventory(FIRST_PAGE, this.props.shared.sessionPreferences.searchSort, this.props.preview, true);
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      var _props2 = this.props,
          actions = _props2.actions,
          dispatch = _props2.dispatch,
          history = _props2.history;

      var vdpMatcher = /.*\/vdp\/.*/i;
      var goingToVdp = vdpMatcher.test(history.location.pathname);

      if (!goingToVdp) {
        dispatch(_sessionPreferences.actions.updateSessionPreferences('infiniteScroll', false));
      }

      actions.cancelLoading(!goingToVdp);
    }
  }, {
    key: 'getMaxDistanceUrl',
    value: function getMaxDistanceUrl() {
      var config = this.props.config;


      var options = (0, _searchFiltersHelper.distanceOptions)(config.translations);
      var defaultOption = config.distanceFilterInitialSelectedIndex && 'MaxDistance=' + options[config.distanceFilterInitialSelectedIndex].value + '&' || '';
      return defaultOption;
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props3 = this.props,
          state = _props3.state,
          dispatch = _props3.dispatch,
          pathByModule = _props3.pathByModule,
          preview = _props3.preview,
          config = _props3.config,
          shared = _props3.shared,
          history = _props3.history,
          marketInfo = _props3.marketInfo,
          globalStyling = _props3.globalStyling,
          featureFlags = _props3.featureFlags;
      var _state = this.state,
          filtersExpanded = _state.filtersExpanded,
          selectedView = _state.selectedView,
          leasingExampleExpanded = _state.leasingExampleExpanded;

      var representativeExample = preview ? {} : shared.financeCalculator.representativeExample;
      var onMoreInfoClick = function onMoreInfoClick(name, vehicle) {
        var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, shared.sessionPreferences.language);
        dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
      };
      var onEnquiryClick = function onEnquiryClick(vehicle) {
        dispatch(_router.actions.navigate('/vdp/' + vehicle.id));
      };
      var goToModule = function goToModule(name) {
        return dispatch(_router.actions.navigate(pathByModule(name)));
      };

      var sharedShortListedVehicles = (0, _vehicleStore.getShortList)(featureFlags)(shared);

      var retailer = preview ? null : this.retailerId && (0, _extends3.default)({
        id: this.retailerId
      }, state.dealer);

      var sharedCompareVehicles = (0, _vehicleStore.getCompareList)(featureFlags)(shared);

      var isListed = function isListed(id, vehicles) {
        return vehicles.find(function (vehicle) {
          return vehicle.id === (id || {});
        });
      };

      var getShortListedIconColour = function getShortListedIconColour(id) {
        return isListed(id, sharedShortListedVehicles) ? config.shortlistIconListedColour && config.shortlistIconListedColour.value : '#ACACAC';
      };

      var getCompareIconColour = function getCompareIconColour(id) {
        return isListed(id, sharedCompareVehicles) ? config.compareIconListedColour && config.compareIconListedColour.value : '#ACACAC';
      };

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_SubNav2.default, {
          pathByModule: pathByModule,
          title: config.translations.searchHeaderMobile,
          preview: preview,
          onBackButtonClick: history.goBack,
          subNavBGColour: config.subNavBGColour,
          subNavBorderColour: config.subNavBorderColour,
          subNavFontTheme: config.subNavFont && themeFromProps(config.subNavFont),
          subNavChevronColour: config.subNavChevronColour,
          translations: config.translations,
          subNavBarItemSelectedBackgroundColour: config.subNavBarItemSelectedBackgroundColour,
          subNavBarItemSelectedFontColour: config.subNavBarItemSelectedFontColour,
          contentWidth: globalStyling.contentWidth,
          showNavBarItemBorder: config.showNavBarItemBorder,
          subNavHeight: config.subNavHeight,
          alignMenuItems: config.alignMenuItems,
          navBarBottomBorder: config.navBarBottomBorder,
          globalStyling: globalStyling
        }),
        preview ? null : _react2.default.createElement(
          MobileOnly,
          null,
          _react2.default.createElement(_MobileSearchPanel2.default, {
            resetFilters: this.resetFilters,
            goBack: function goBack() {
              return goToModule('Search');
            },
            config: (0, _extends3.default)({}, marketInfo, {
              translations: config.translations,
              stylingConfig: config.stylingConfig,
              filterVisibility: config.visibility.filters,
              distanceFilterInitialSelectedIndex: config.distanceFilterInitialSelectedIndex,
              dropdown: config.dropdown,
              locationIconUrl: config.locationIconUrl
            }),
            backgroundColour: config.subNavBGColour,
            chevronColour: config.subNavChevronColour,
            fontTheme: config.subNavFont && themeFromProps(config.subNavFont),
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            featureFlags: featureFlags
          })
        ),
        _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          _react2.default.createElement(_SearchResults2.default, {
            locationIconUrl: config.locationIconUrl,
            filtersStyling: config.stylingConfig,
            marketInfo: marketInfo,
            translations: config.translations,
            filtersExpanded: filtersExpanded,
            onFilterMenuClick: this.onFilterMenuClick,
            results: state.searchResults,
            currentPage: state.currentPage,
            totalResults: state.totalResults,
            onMoreInfoClick: onMoreInfoClick,
            onEnquiryClick: onEnquiryClick,
            onShowAllResultsClick: this.onShowAllResultsClick,
            onPageNumberChange: function onPageNumberChange(value) {
              return _this2.onPageNumberChange(value, state.infiniteScroll);
            },
            sortOptions: _settings.sortOptions,
            sortValue: shared.sessionPreferences.searchSort && shared.sessionPreferences.searchSort.value,
            onSortChange: this.onSortChange,
            showSortByDistance: shared.searchFilter.location && shared.searchFilter.location.lat && shared.searchFilter.location.long,
            showSortByAge: config.visibility.sorting && config.visibility.sorting.showSortByAge,
            showSortByMileage: config.visibility.sorting && config.visibility.sorting.showSortByMileage,
            fontFamily: config.sortMenuBarFontFamily,
            selectedView: selectedView,
            onViewChange: this.onViewChange,
            toggleLeasingExample: this.toggleLeasingExample,
            leasingExampleExpanded: leasingExampleExpanded,
            preview: preview,
            compareVehicle: function compareVehicle(vehicle) {
              return dispatch(_compare.actions.toggleVehicle(vehicle));
            },
            shortlistVehicle: function shortlistVehicle(vehicle) {
              return dispatch(_shortlist.actions.toggleVehicle(vehicle));
            },
            getShortListedIconColour: getShortListedIconColour,
            representativeExample: representativeExample,
            getCompareIconColour: getCompareIconColour,
            resetFilters: this.resetFilters,
            retailer: retailer,
            globalStyling: globalStyling,
            tilesVisibility: config.visibility.tiles,
            filterVisibility: config.visibility.filters,
            headingFont: config.headingFont,
            subHeadingFont: config.subHeadingFont,
            headerItemFont: config.headerItemFont,
            capitalizeSubHeaderCountryName: config.capitalizeSubHeaderCountryName,
            headingAlignment: config.headingAlignment,
            history: history,
            featureFlags: featureFlags,
            vehicleModelTitleFontWeight: config.vehicleModelTitleFontWeight,
            headingBottomLineDisable: config.headingBottomLineDisable,
            subHeadingAlignment: config.subHeadingAlignment,
            showGridTiles: config.showGridTiles,
            badgeStyling: config.badgeStyling,
            financeHeadingFont: config.financeHeadingFont,
            infiniteScroll: state.infiniteScroll,
            loading: state.loading,
            pageSize: state.pageSize,
            distanceFilterInitialSelectedIndex: config.distanceFilterInitialSelectedIndex,
            dropdownTheme: config.dropdown,
            config: config
          })
        )
      );
    }
  }]);
  return SearchResults;
}(_react.Component);

var _initialiseProps = function _initialiseProps() {
  var _this3 = this;

  Object.defineProperty(this, 'onViewportChange', {
    enumerable: true,
    writable: true,
    value: function value() {
      if (window.innerWidth < _theme.screenSizeOptions.large) {
        _this3.setState(function () {
          return { selectedView: 'GRID' };
        });
      }
    }
  });
  Object.defineProperty(this, 'onFilterMenuClick', {
    enumerable: true,
    writable: true,
    value: function value() {
      return _this3.setState(function (prevState) {
        return {
          filtersExpanded: !prevState.filtersExpanded
        };
      });
    }
  });
  Object.defineProperty(this, 'onShowAllResultsClick', {
    enumerable: true,
    writable: true,
    value: function value() {
      var _props4 = _this3.props,
          actions = _props4.actions,
          shared = _props4.shared,
          marketInfo = _props4.marketInfo,
          state = _props4.state,
          dispatch = _props4.dispatch;
      var searchUrl = shared.searchFilter.searchUrl;
      var _searchUrl$checkboxUr = searchUrl.checkboxUrl,
          checkboxUrl = _searchUrl$checkboxUr === undefined ? '' : _searchUrl$checkboxUr,
          _searchUrl$rangeUrl = searchUrl.rangeUrl,
          rangeUrl = _searchUrl$rangeUrl === undefined ? '' : _searchUrl$rangeUrl,
          _searchUrl$locationUr = searchUrl.locationUrl,
          locationUrl = _searchUrl$locationUr === undefined ? '' : _searchUrl$locationUr,
          _searchUrl$maxDistanc = searchUrl.maxDistanceUrl,
          maxDistanceUrl = _searchUrl$maxDistanc === undefined ? _this3.getMaxDistanceUrl() : _searchUrl$maxDistanc;

      var filtersUrl = '' + checkboxUrl + rangeUrl + locationUrl + maxDistanceUrl;
      var sortField = shared.sessionPreferences.searchSort;
      var sortingParams = '&SortField=' + sortField.field + '&SortDirection=' + sortField.order;
      var retailerParam = !_this3.retailerId ? '' : '&RetailerId=' + _this3.retailerId;

      dispatch(_sessionPreferences.actions.updateSessionPreferences('infiniteScroll', true));

      actions.showAll((0, _extends3.default)({}, marketInfo, {
        filters: ''.concat(filtersUrl, sortingParams, retailerParam),
        pageNumber: state.currentPage,
        sortField: shared.sessionPreferences.searchSort
      }));
    }
  });
  Object.defineProperty(this, 'onPageNumberChange', {
    enumerable: true,
    writable: true,
    value: function value(_value, infiniteScroll) {
      var shared = _this3.props.shared;

      _this3.updateInventory(_value, shared.sessionPreferences.searchSort);

      if (!infiniteScroll) {
        window.scroll(0, 0);
      }
    }
  });
  Object.defineProperty(this, 'onSortChange', {
    enumerable: true,
    writable: true,
    value: function value(_value2) {
      _this3.props.dispatch(_sessionPreferences.actions.updateSessionPreferences('searchSort', _settings.sortOptions[_value2]));
      _this3.props.actions.clearLoaded();
      _this3.updateInventory(FIRST_PAGE, _settings.sortOptions[_value2], _this3.props.preview);
    }
  });
  Object.defineProperty(this, 'onViewChange', {
    enumerable: true,
    writable: true,
    value: function value(selectedView) {
      return _this3.setState(function () {
        return { selectedView: selectedView };
      });
    }
  });
  Object.defineProperty(this, 'toggleLeasingExample', {
    enumerable: true,
    writable: true,
    value: function value() {
      _this3.setState(function (prevState) {
        return (0, _extends3.default)({}, prevState, {
          leasingExampleExpanded: !prevState.leasingExampleExpanded
        });
      });
    }
  });
  Object.defineProperty(this, 'updateInventory', {
    enumerable: true,
    writable: true,
    value: function value(pageNumber, sortField, preview, forceUpdate) {
      var _props5 = _this3.props,
          actions = _props5.actions,
          shared = _props5.shared,
          marketInfo = _props5.marketInfo;

      var searchUrl = preview ? {} : shared.searchFilter.searchUrl;

      var _searchUrl$checkboxUr2 = searchUrl.checkboxUrl,
          checkboxUrl = _searchUrl$checkboxUr2 === undefined ? '' : _searchUrl$checkboxUr2,
          _searchUrl$rangeUrl2 = searchUrl.rangeUrl,
          rangeUrl = _searchUrl$rangeUrl2 === undefined ? '' : _searchUrl$rangeUrl2,
          _searchUrl$locationUr2 = searchUrl.locationUrl,
          locationUrl = _searchUrl$locationUr2 === undefined ? '' : _searchUrl$locationUr2,
          _searchUrl$maxDistanc2 = searchUrl.maxDistanceUrl,
          maxDistanceUrl = _searchUrl$maxDistanc2 === undefined ? _this3.getMaxDistanceUrl() : _searchUrl$maxDistanc2;

      var filtersUrl = '' + checkboxUrl + rangeUrl + locationUrl + maxDistanceUrl;

      var sortingParams = '&SortField=' + sortField.field + '&SortDirection=' + sortField.order;
      var retailerParam = preview || !_this3.retailerId ? '' : '&RetailerId=' + _this3.retailerId;

      actions.getInventory((0, _extends3.default)({}, marketInfo, {
        filters: ''.concat(filtersUrl, sortingParams, retailerParam),
        forceUpdate: forceUpdate,
        preview: preview,
        pageNumber: pageNumber
      }));
    }
  });
  Object.defineProperty(this, 'resetFilters', {
    enumerable: true,
    writable: true,
    value: function value() {
      return _this3.props.dispatch(_searchFilter.actions.resetFilters());
    }
  });
  Object.defineProperty(this, 'retailerId', {
    enumerable: true,
    writable: true,
    value: null
  });
  Object.defineProperty(this, 'forceUpdate', {
    enumerable: true,
    writable: true,
    value: false
  });
};

exports.default = SearchResults;