'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translateFinanceTemplate = undefined;

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mapStateToProps = function mapStateToProps(_ref) {
  var config = _ref.config;
  return {
    finance: (0, _extends3.default)({}, config.config.global.finance, {
      locale: config.settings.locale
    })
  };
};

var translateFinanceTemplate = exports.translateFinanceTemplate = function translateFinanceTemplate(translations) {
  return function (translationsKey, placeholdersAndValues) {
    var template = translations[translationsKey];
    var replaceValue = function replaceValue(t, key) {
      return t.replace('{' + key + '}', placeholdersAndValues[key]);
    };
    return template ? (0, _keys2.default)(placeholdersAndValues).reduce(replaceValue, template) : '';
  };
};

var FinanceContainer = function FinanceContainer(_ref2) {
  var Component = _ref2.component,
      props = (0, _objectWithoutProperties3.default)(_ref2, ['component']);
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(Component, (0, _extends3.default)({}, props, {
      translateFinanceTemplate: translateFinanceTemplate(props.finance.translations)
    }))
  );
};

exports.default = (0, _reactRedux.connect)(mapStateToProps)(FinanceContainer);