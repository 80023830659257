'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _trunc = require('babel-runtime/core-js/math/trunc');

var _trunc2 = _interopRequireDefault(_trunc);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _numbers = require('../../../shared/localisation/numbers');

var _vehicleTypes = require('../../../types/Bentley/vehicleTypes');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DistanceLabel = _styledComponents2.default.span.withConfig({
  displayName: 'DistanceFromRetailer__DistanceLabel',
  componentId: 'sc-1mshzal-0'
})(['font-family:\'Bentley-regular\';font-style:normal;font-weight:400;font-size:14px;line-height:19px;color:#222222;']);

var DistanceFromRetailer = function DistanceFromRetailer(_ref) {
  var vehicle = _ref.vehicle,
      translations = _ref.translations,
      locale = _ref.locale,
      hideDistanceFrom = _ref.hideDistanceFrom;
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      DistanceLabel,
      null,
      '' + vehicle.retailerName + (!!vehicle.distance && !hideDistanceFrom ? ' - ' + (0, _numbers.localiseNumber)((0, _trunc2.default)((0, _numbers.converKmToMiles)(vehicle.distance)), locale) + ' ' + translations.milesAway : '')
    )
  );
};

exports.default = DistanceFromRetailer;