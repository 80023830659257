'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.actions = exports.effects = undefined;

var _FeaturedVehicle = require('./FeaturedVehicle');

var _FeaturedVehicle2 = _interopRequireDefault(_FeaturedVehicle);

var _effects = require('./effects');

var _effects2 = _interopRequireDefault(_effects);

var _reducer = require('./reducer');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.effects = _effects2.default;
exports.actions = _reducer.actions;
exports.reducer = _reducer.reducer;
exports.default = _FeaturedVehicle2.default;