'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    width: 100%;\n    gap: 10px;\n\n  '], ['\n    flex-direction: column;\n    width: 100%;\n    gap: 10px;\n\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    text-align: center;\n  '], ['\n    text-align: center;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    text-align: center;\n    ', '\n  '], ['\n    text-align: center;\n    ', '\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _settings = require('../../shared/selectors/settings');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _globalStyling = require('../../types/globalStyling');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiency__Container',
  componentId: 'sc-18tt6ck-0'
})(['display:flex;flex-direction:column;']);

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiency__Row',
  componentId: 'sc-18tt6ck-1'
})(['display:flex;flex-direction:row;gap:30px;', ';'], _theme2.default.max.large(_templateObject));

var ItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiency__ItemContainer',
  componentId: 'sc-18tt6ck-2'
})(['margin-bottom:18px;break-inside:avoid;font-size:14px;line-height:19px;flex:1;', ';'], _theme2.default.max.small(_templateObject2));

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiency__Title',
  componentId: 'sc-18tt6ck-3'
})(['font-family:', ';padding-bottom:8px;'], function (_ref) {
  var font = _ref.font;
  return font ? font.primaryButtonFont.value : 'inherit';
});

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiency__Item',
  componentId: 'sc-18tt6ck-4'
})(['display:flex;flex-direction:row;justify-content:space-between;align-items:center;border-bottom:1px solid #707070;width:100%;', ''], _theme2.default.max.small(_templateObject3, function (_ref2) {
  var center = _ref2.center;
  return center && 'justify-content: center;';
}));

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleEfficiency__Label',
  componentId: 'sc-18tt6ck-5'
})(['font-family:', ';text-transform:capitalize;margin:8px 0;'], function (_ref3) {
  var font = _ref3.font;
  return font ? font.primaryFont.value : 'inherit';
});

var Value = (0, _styledComponents2.default)(Label).withConfig({
  displayName: 'VehicleEfficiency__Value',
  componentId: 'sc-18tt6ck-6'
})(['font-family:', ';text-align:right;letter-spacing:normal;'], function (_ref4) {
  var font = _ref4.font;
  return font ? font.paragraphFont.value : 'inherit';
});

var Section = function Section(_ref5) {
  var fonts = _ref5.fonts,
      category = _ref5.category,
      items = _ref5.items;

  return _react2.default.createElement(
    ItemContainer,
    null,
    _react2.default.createElement(
      Title,
      {
        style: {
          textTransform: 'capitalize'
        },
        font: fonts
      },
      category
    ),
    items.map(function (e, index) {
      return e.value ? _react2.default.createElement(
        Item,
        { key: index },
        _react2.default.createElement(
          Label,
          { font: fonts },
          e.label
        ),
        _react2.default.createElement(
          Value,
          { font: fonts },
          e.value
        )
      ) : null;
    })
  );
};

var VehicleEfficiency = function VehicleEfficiency(_ref6) {
  var nedcConsumption = _ref6.nedcConsumption,
      nedcEmissions = _ref6.nedcEmissions,
      wltpConsumptions = _ref6.wltpConsumptions,
      wltpEmissions = _ref6.wltpEmissions;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts;

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Row,
      null,
      nedcConsumption && _react2.default.createElement(Section, {
        category: nedcConsumption.category,
        fonts: fonts,
        items: nedcConsumption.items
      }),
      nedcEmissions && _react2.default.createElement(Section, {
        category: nedcEmissions.category,
        fonts: fonts,
        items: nedcEmissions.items
      })
    ),
    _react2.default.createElement(
      Row,
      null,
      wltpConsumptions && _react2.default.createElement(Section, {
        category: wltpConsumptions.category,
        fonts: fonts,
        items: wltpConsumptions.items
      }),
      wltpEmissions && _react2.default.createElement(Section, {
        category: wltpEmissions.category,
        fonts: fonts,
        items: wltpEmissions.items
      })
    )
  );
};

exports.default = VehicleEfficiency;