'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 10px;\n    font-size: 14px;\n  '], ['\n    margin: 0 10px;\n    font-size: 14px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 50px;\n  '], ['\n    margin: 0 50px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 20px 80px;\n  '], ['\n    margin: 20px 80px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    gap: 20px;\n    margin: 30px 0;\n  '], ['\n    gap: 20px;\n    margin: 30px 0;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 30px;\n  '], ['\n    margin-bottom: 30px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Item = require('./Item');

var _Item2 = _interopRequireDefault(_Item);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _certifiedProgrammeTypes = require('../../../types/Bentley/certifiedProgrammeTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'CertifiedProgramme__Container',
  componentId: 'sc-a5rzjq-0'
})(['display:flex;flex-direction:column;align-items:center;']);

var MaxWidth = _styledComponents2.default.div.withConfig({
  displayName: 'CertifiedProgramme__MaxWidth',
  componentId: 'sc-a5rzjq-1'
})(['max-width:1500px;display:flex;flex-direction:column;align-items:center;']);

var BodyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CertifiedProgramme__BodyContainer',
  componentId: 'sc-a5rzjq-2'
})(['display:flex;justify-items:center;flex-direction:column;max-width:650px;']);

var BodeText = _styledComponents2.default.div.withConfig({
  displayName: 'CertifiedProgramme__BodeText',
  componentId: 'sc-a5rzjq-3'
})(['font-family:Bentley-Regular;font-style:normal;font-weight:300;font-size:16px;text-align:center;color:#222222;text-align:center;line-height:24px;', ' ', ''], _theme2.default.max.medium(_templateObject), _theme2.default.max.small(_templateObject2));

var Divider = _styledComponents2.default.div.withConfig({
  displayName: 'CertifiedProgramme__Divider',
  componentId: 'sc-a5rzjq-4'
})(['border:0.5px solid #222;margin:20px 40px;', ''], _theme2.default.max.small(_templateObject3));

var ItemsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CertifiedProgramme__ItemsContainer',
  componentId: 'sc-a5rzjq-5'
})(['display:flex;margin:100px 0;gap:40px;flex-direction:column;', ''], _theme2.default.max.medium(_templateObject4));

var VideoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CertifiedProgramme__VideoContainer',
  componentId: 'sc-a5rzjq-6'
})(['display:flex;margin-bottom:60px;', ''], _theme2.default.max.medium(_templateObject5));

var VideoBg = _styledComponents2.default.video.withConfig({
  displayName: 'CertifiedProgramme__VideoBg',
  componentId: 'sc-a5rzjq-7'
})(['object-fit:cover;width:100%;height:100%;']);

var CertifiedProgrammePage = function CertifiedProgrammePage(_ref) {
  var config = _ref.config;

  var _useNavigation = (0, _navigation2.default)(),
      navigateTo = _useNavigation.navigateTo;

  var bodyText = config.translations.bodyText,
      items = config.items,
      videoUrl = config.videoUrl,
      videoThumbnail = config.videoThumbnail;


  var _onClick = function _onClick(url) {
    navigateTo(url);
  };

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      VideoContainer,
      null,
      _react2.default.createElement(
        VideoBg,
        { autoPlay: true, muted: true, loop: true, playsInline: true, poster: videoThumbnail },
        _react2.default.createElement('source', { src: videoUrl, type: 'video/mp4' })
      )
    ),
    _react2.default.createElement(
      MaxWidth,
      null,
      _react2.default.createElement(
        BodyContainer,
        null,
        _react2.default.createElement(Divider, null),
        _react2.default.createElement(
          BodeText,
          null,
          bodyText
        ),
        _react2.default.createElement(Divider, null)
      ),
      _react2.default.createElement(
        ItemsContainer,
        null,
        items.map(function (item, index) {
          return _react2.default.createElement(_Item2.default, {
            key: index,
            reverse: index % 2 !== 0,
            item: item,
            onClick: function onClick() {
              return _onClick(item.redirectUrl);
            }
          });
        })
      )
    )
  );
};

exports.default = CertifiedProgrammePage;