"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require("babel-runtime/helpers/extends");

var _extends3 = _interopRequireDefault(_extends2);

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VdpLocationPinIcon = function VdpLocationPinIcon(props) {
  return React.createElement(
    "svg",
    (0, _extends3.default)({
      xmlns: "http://www.w3.org/2000/svg",
      width: 18,
      height: 13,
      fill: "none",
      strokeWidth: 1
    }, props),
    React.createElement("path", {
      stroke: props.stroke,
      strokeWidth: props.strokeWidth,
      d: "M5.923 7.109c-1.1 0-1.993-.837-1.993-1.87 0-1.031.892-1.868 1.993-1.868s1.994.837 1.994 1.869-.893 1.869-1.994 1.869Z",
      clipRule: "evenodd"
    }),
    React.createElement("path", {
      stroke: props.stroke,
      strokeWidth: props.strokeWidth,
      d: "M10.66 5.24c0-2.415-2.087-4.373-4.664-4.373-2.576 0-4.664 1.958-4.664 4.373l.002.042c-.006.13-.008.58.186 1.18a4.28 4.28 0 0 0 .937 1.622c.148.17.311.342.5.511 2.04 1.826 2.966 3.472 2.966 3.472S7 10.421 9.04 8.595a5.61 5.61 0 0 0 .675-.722c.188-.233.35-.483.486-.748l.022-.043c.035-.071.07-.143.102-.217.405-.923.336-1.625.336-1.625Z",
      clipRule: "evenodd"
    })
  );
};
exports.default = VdpLocationPinIcon;