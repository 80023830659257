'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Facebook = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Facebook__IconWrapper',
  componentId: 'sc-9j623k-0'
})(['width:32px;height:32px;']);

var Facebook = function Facebook() {
  return _react2.default.createElement(
    IconWrapper,
    null,
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 24 24' },
      _react2.default.createElement('path', { d: 'M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.407.593 24 1.324 24h11.504v-9.281H9.703v-3.633h3.125V8.412c0-3.099 1.895-4.787 4.659-4.787.931-.002 1.862.045 2.789.14v3.24h-1.904c-1.506 0-1.8.712-1.8 1.763v2.313h3.6l-.467 3.633h-3.153V24h6.124c.731 0 1.324-.593 1.324-1.324V1.324C24 .593 23.407 0 22.676 0z' })
    )
  );
};

exports.Facebook = Facebook;