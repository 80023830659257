'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _core = require('@popperjs/core');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Tooltip = require('../VehicleCard/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
  Provides vehicle tax information icon and tooltip
*/
var GERMANY_COUNTRY_CODE = 'DE';

var TaxIcon = _styledComponents2.default.img.withConfig({
  displayName: 'GermanTaxDisclaimer__TaxIcon',
  componentId: 'sc-1tuddd6-0'
})(['width:14px;height:14px;margin-left:8px;', ''], function (_ref) {
  var darkMode = _ref.darkMode;
  return darkMode && 'filter: invert(1);';
});

var GermanTaxDisclaimer = function GermanTaxDisclaimer(_ref2) {
  var countryCode = _ref2.countryCode,
      showSalesTaxDisclaimer = _ref2.showSalesTaxDisclaimer,
      salesTaxDisclaimer = _ref2.salesTaxDisclaimer,
      _ref2$tooltipPlacemen = _ref2.tooltipPlacement,
      tooltipPlacement = _ref2$tooltipPlacemen === undefined ? 'bottom-end' : _ref2$tooltipPlacemen,
      darkMode = _ref2.darkMode;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    countryCode === GERMANY_COUNTRY_CODE && showSalesTaxDisclaimer && _react2.default.createElement(
      _Tooltip2.default,
      { text: salesTaxDisclaimer, placement: tooltipPlacement },
      _react2.default.createElement(TaxIcon, {
        src: 'https://res.cloudinary.com/motortrak/image/upload/v1708535816/locator/rolls-royce/global/icons/info-taxation.svg',
        alt: 'Tax information icon. Symbol f\xFCr steuerinformationen',
        darkMode: darkMode
      })
    )
  );
};

exports.default = GermanTaxDisclaimer;