'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BentleyFooter;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Footer = require('../../../components/Bentley/Footer');

var _Footer2 = _interopRequireDefault(_Footer);

var _footerTypes = require('../../../types/Bentley/footerTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function BentleyFooter(_ref) {
  var config = _ref.config;

  return _react2.default.createElement(_Footer2.default, { config: config });
}