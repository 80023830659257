'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n  '], ['\n    ', ';\n  ']);

exports.default = Link;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledComponentFactory = require('./styledComponentFactory');

var _router = require('../../../actions/router');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Anchor = _styledComponents2.default.a.attrs(function (props) {
  return {
    className: 'global ' + props.applyStyle
  };
}).withConfig({
  displayName: 'Link__Anchor',
  componentId: 'sc-1wckxv4-0'
})(['&&&{text-decoration:none;', ';', '}'], function (_ref) {
  var styleOverride = _ref.styleOverride;
  return styleOverride && styleOverride;
}, _theme2.default.min.large(_templateObject, function (_ref2) {
  var mobileStyleOverride = _ref2.mobileStyleOverride;
  return mobileStyleOverride && mobileStyleOverride;
}));

function Link(_ref3) {
  var styleOverride = _ref3.styleOverride,
      mobileStyleOverride = _ref3.mobileStyleOverride,
      href = _ref3.href,
      props = (0, _objectWithoutProperties3.default)(_ref3, ['styleOverride', 'mobileStyleOverride', 'href']);

  var dispatch = (0, _reactRedux.useDispatch)();
  return _react2.default.createElement(Anchor, (0, _extends3.default)({
    styleOverride: styleOverride,
    mobileStyleOverride: mobileStyleOverride,
    onClick: function onClick() {
      return dispatch(_router.actions.navigate(href));
    }
  }, (0, _extends3.default)({}, props, (0, _styledComponentFactory.mediaProps)(mobileStyleOverride()))));
}

Link.defaultProps = {
  styleOverride: function styleOverride() {
    return '';
  },
  mobileStyleOverride: function mobileStyleOverride() {
    return [];
  }
};