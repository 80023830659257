'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ThinRight = require('../JaguarIcons/Arrows/ThinRight');

var _ThinRight2 = _interopRequireDefault(_ThinRight);

var _Criteria = require('../JaguarIcons/Global/Criteria');

var _Criteria2 = _interopRequireDefault(_Criteria);

var _SearchFiltersContainer = require('../../containers/SearchFiltersContainer');

var _SearchFiltersContainer2 = _interopRequireDefault(_SearchFiltersContainer);

var _Button = require('../Global/Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'MobileSearchPanel__Container',
  componentId: 'sc-x3424p-0'
})(['display:flex;width:100%;']);

var SearchBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchBarContainer'
}).withConfig({
  displayName: 'MobileSearchPanel__SearchBarContainer',
  componentId: 'sc-x3424p-1'
})(['display:flex;width:100%;flex-flow:column;background:', ';justify-content:space-between;'], function (_ref) {
  var backgroundColour = _ref.backgroundColour;
  return backgroundColour && backgroundColour.value || '#474747';
});

var SearchHeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchHeaderContainer'
}).withConfig({
  displayName: 'MobileSearchPanel__SearchHeaderContainer',
  componentId: 'sc-x3424p-2'
})(['display:flex;height:50px;width:100%;background:', ';justify-content:space-between;border-top:1px #acacac solid;'], function (_ref2) {
  var backgroundColour = _ref2.backgroundColour;
  return backgroundColour && backgroundColour.value || '#474747';
});

var Chevron = _styledComponents2.default.div.withConfig({
  displayName: 'MobileSearchPanel__Chevron',
  componentId: 'sc-x3424p-3'
})(['display:flex;position:absolute;width:16px;height:10px;']);

var ToggleButton = (0, _styledComponents2.default)(Chevron).withConfig({
  displayName: 'MobileSearchPanel__ToggleButton',
  componentId: 'sc-x3424p-4'
})(['transform:', ';top:', ';right:', ';'], function (_ref3) {
  var collapsed = _ref3.collapsed;
  return collapsed ? 'rotate(0deg)' : 'rotate(90deg)';
}, function (_ref4) {
  var collapsed = _ref4.collapsed;
  return collapsed ? '18px' : '18px';
}, function (_ref5) {
  var collapsed = _ref5.collapsed;
  return collapsed ? '20px' : '16px';
});

var SearchHeader = _styledComponents2.default.div.withConfig({
  displayName: 'MobileSearchPanel__SearchHeader',
  componentId: 'sc-x3424p-5'
})(['display:flex;line-height:55px;color:', ';flex:1 0 0%;padding-left:8px;justify-content:center;font-family:', ';font-size:', ';'], function (_ref6) {
  var theme = _ref6.theme;
  return theme.colour || '#FFF';
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.fontFamily;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.fontSize || '14px';
});

var ToggleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MobileSearchPanel__ToggleContainer',
  componentId: 'sc-x3424p-6'
})(['width:10%;display:flex;position:relative;']);

var IconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'IconContainer'
}).withConfig({
  displayName: 'MobileSearchPanel__IconContainer',
  componentId: 'sc-x3424p-7'
})(['display:flex;align-items:center;padding:4px 0 0 18px;']);

var SearchOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'SearchOverlay'
}).withConfig({
  displayName: 'MobileSearchPanel__SearchOverlay',
  componentId: 'sc-x3424p-8'
})(['position:fixed;top:0;left:0;right:0;bottom:0;z-index:10000;width:100%;height:100%;background:#fff;overflow:hidden;z-index:999;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ButtonContainer'
}).withConfig({
  displayName: 'MobileSearchPanel__ButtonContainer',
  componentId: 'sc-x3424p-9'
})(['background-color:#f0f0f0;display:flex;width:100%;justify-content:flex-end;padding:20px;box-sizing:border-box;position:fixed;left:0;right:0;bottom:0;']);

var OverlayWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OverlayWrapper'
}).withConfig({
  displayName: 'MobileSearchPanel__OverlayWrapper',
  componentId: 'sc-x3424p-10'
})(['overflow:scroll;float:left;height:100%;width:100%;padding-bottom:100px;box-sizing:border-box;']);

var MobileSearchPanel = function (_React$Component) {
  (0, _inherits3.default)(MobileSearchPanel, _React$Component);

  function MobileSearchPanel(props) {
    (0, _classCallCheck3.default)(this, MobileSearchPanel);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MobileSearchPanel.__proto__ || (0, _getPrototypeOf2.default)(MobileSearchPanel)).call(this, props));

    Object.defineProperty(_this, 'toggle', {
      enumerable: true,
      writable: true,
      value: function value(el) {
        return _this.setState(function (_ref9) {
          var collapsed = _ref9.collapsed;
          return {
            collapsed: (0, _extends4.default)({}, collapsed, (0, _defineProperty3.default)({}, el, !collapsed[el]))
          };
        });
      }
    });
    Object.defineProperty(_this, 'resetFiltersAndClose', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.props.resetFilters();
        _this.toggle('advancedSearch');
        _this.setState(function (_ref10) {
          var resetValue = _ref10.resetValue;
          return { resetValue: resetValue + 1 };
        });
      }
    });
    Object.defineProperty(_this, 'colourValue', {
      enumerable: true,
      writable: true,
      value: function value(colour) {
        return colour && colour.value;
      }
    });

    _this.state = {
      collapsed: {
        advancedSearch: true,
        searchLocation: false
      },
      resetValue: 0
    };
    return _this;
  }

  (0, _createClass3.default)(MobileSearchPanel, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          config = _props.config,
          buttonStyle = _props.buttonStyle,
          featureFlags = _props.featureFlags,
          backgroundColour = _props.backgroundColour,
          chevronColour = _props.chevronColour,
          fontTheme = _props.fontTheme;

      return _react2.default.createElement(
        Container,
        null,
        _react2.default.createElement(
          SearchBarContainer,
          { backgroundColour: backgroundColour },
          _react2.default.createElement(
            SearchHeaderContainer,
            { backgroundColour: backgroundColour },
            _react2.default.createElement(
              IconContainer,
              null,
              _react2.default.createElement(_Criteria2.default, { colour: this.colourValue(chevronColour) || '#FFFFFF' })
            ),
            _react2.default.createElement(
              SearchHeader,
              { theme: fontTheme },
              config.translations.searchHeaderAdvancedSearch
            ),
            _react2.default.createElement(
              ToggleContainer,
              { onClick: function onClick() {
                  return _this2.toggle('advancedSearch');
                } },
              _react2.default.createElement(
                ToggleButton,
                { collapsed: this.state.collapsed.advancedSearch },
                _react2.default.createElement(_ThinRight2.default, {
                  colour: this.colourValue(chevronColour) || '#FFFFFF'
                })
              )
            )
          )
        ),
        !this.state.collapsed.advancedSearch && _react2.default.createElement(
          SearchOverlay,
          null,
          _react2.default.createElement(
            OverlayWrapper,
            null,
            _react2.default.createElement(_SearchFiltersContainer2.default, {
              config: config,
              featureFlags: featureFlags
            }),
            _react2.default.createElement(
              ButtonContainer,
              null,
              _react2.default.createElement(_Button2.default, {
                buttonStyle: buttonStyle,
                applyStyle: 'secondary',
                onClick: this.resetFiltersAndClose,
                text: config.translations.searchCTAReset,
                mobileStyleOverride: function mobileStyleOverride() {
                  return 'margin: 0 10px;';
                }
              }),
              _react2.default.createElement(_Button2.default, {
                buttonStyle: buttonStyle,
                applyStyle: 'primary',
                onClick: function onClick() {
                  return _this2.toggle('advancedSearch');
                },
                text: config.translations.searchCTADone,
                mobileStyleOverride: function mobileStyleOverride() {
                  return 'margin: 0 10px;';
                }
              })
            )
          )
        )
      );
    }
  }]);
  return MobileSearchPanel;
}(_react2.default.Component);

exports.default = MobileSearchPanel;