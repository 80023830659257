'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = PathnameSubNav;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _DisplaySubNavBar = require('./DisplaySubNavBar');

var _DisplaySubNavBar2 = _interopRequireDefault(_DisplaySubNavBar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function PathnameSubNav(props) {
  var selected = props.links && props.links.find(function (link) {
    return link.path === props.history.location.pathname.substr(1);
  });
  return _react2.default.createElement(_DisplaySubNavBar2.default, (0, _extends3.default)({}, props, { selected: selected }));
}