'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_VEHICLE_INFO_SHARED';

var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_VEHICLE_INFO', 'GET_VEHICLE_INFO_SUCCESS', 'CLEAR_VEHICLE_INFO', 'SET_VEHICLE']);

var actions = exports.actions = {
  getVehicleInfo: function getVehicleInfo(payload) {
    return { type: constants.GET_VEHICLE_INFO, payload: payload };
  },
  getVehicleInfoSuccess: function getVehicleInfoSuccess(payload) {
    return {
      type: constants.GET_VEHICLE_INFO_SUCCESS,
      payload: payload
    };
  },
  clearVehicleInfo: function clearVehicleInfo() {
    return {
      type: constants.CLEAR_VEHICLE_INFO
    };
  },
  setVehicle: function setVehicle(payload) {
    return { type: constants.SET_VEHICLE, payload: payload };
  }
};

var initialState = exports.initialState = {
  vehicle: null
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.GET_VEHICLE_INFO_SUCCESS:
      {
        return (0, _extends3.default)({}, state, {
          vehicle: action.payload
        });
      }
    case constants.SET_VEHICLE:
      {
        return (0, _extends3.default)({}, state, {
          vehicle: action.payload
        });
      }
    case constants.CLEAR_VEHICLE_INFO:
      {
        return (0, _extends3.default)({}, state, {
          vehicle: null
        });
      }
    default:
      return state;
  }
}