'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SectionTitle;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledSectionTitle = _styledComponents2.default.h4.withConfig({
  displayName: 'SectionTitle__StyledSectionTitle',
  componentId: 'sc-k3cxmd-0'
})(['width:100%;display:flex;height:55px;align-items:center;font-weight:bold;font-size:16px;margin:0;color:#444;padding:10px 0;justify-content:flex-start;border-bottom:', ';'], function (_ref) {
  var hasBorder = _ref.hasBorder;
  return hasBorder ? '1px solid #d8d8d8' : 'none';
});

function SectionTitle(props) {
  return _react2.default.createElement(
    StyledSectionTitle,
    { hasBorder: props.hasBorder },
    props.children
  );
}