'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    top: 0; \n    justify-content: center;\n    margin: 0 52px;\n    max-width: 390px;\n  '], ['\n    top: 0; \n    justify-content: center;\n    margin: 0 52px;\n    max-width: 390px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 64px;\n  '], ['\n    margin: 0 64px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    gap: 20px;\n  '], ['\n    width: 100%;\n    gap: 20px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    color: #fff;\n    line-height: 36px;\n    font-size: 24px;\n    text-align: center;\n  '], ['\n    color: #fff;\n    line-height: 36px;\n    font-size: 24px;\n    text-align: center;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    color: #fff;\n    line-height: 24px;\n    font-size: 16px;\n    text-align: center;\n  '], ['\n    color: #fff;\n    line-height: 24px;\n    font-size: 16px;\n    text-align: center;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Form = require('../../../components/Bentley/Form');

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _sessionStorage = require('../../../helpers/sessionStorage');

var sessionStorageManager = _interopRequireWildcard(_sessionStorage);

var _ipInformation = require('../../../shared/localisation/ipInformation');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _types = require('../../../types');

var _Image = require('./Image');

var _Image2 = _interopRequireDefault(_Image);

var _types2 = require('./types');

var _Video = require('./Video');

var _Video2 = _interopRequireDefault(_Video);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SlideContainer = _styledComponents2.default.div.withConfig({ displayName: 'SlideContainer' }).withConfig({
  displayName: 'BentleySlide__SlideContainer',
  componentId: 'sc-1r77d5k-0'
})(['background-color:#000;display:flex;flex-direction:column;min-height:1px;']);

var SlideOneContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SlideOneContentContainer'
}).withConfig({
  displayName: 'BentleySlide__SlideOneContentContainer',
  componentId: 'sc-1r77d5k-1'
})(['display:flex;justify-content:flex-start;flex-direction:column;position:absolute;bottom:0;top:64px;margin:0 20px;width:calc(100% - 40px);', ';', ';'], _theme2.default.min.medium(_templateObject), _theme2.default.min.large(_templateObject2));

var TopSlideContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TopSlideContentContainer'
}).withConfig({
  displayName: 'BentleySlide__TopSlideContentContainer',
  componentId: 'sc-1r77d5k-2'
})(['display:flex;flex-direction:column;gap:10px;', ' ', ';'], function (_ref) {
  var width = _ref.width;
  return width && 'width : ' + width + 'px;';
}, _theme2.default.max.medium(_templateObject3));

var Header1 = _styledComponents2.default.h1.withConfig({
  displayName: 'BentleySlide__Header1',
  componentId: 'sc-1r77d5k-3'
})(['font-family:Bentley-Regular;font-size:24px;line-height:36px;letter-spacing:1.2px;color:', ';margin:0;', ';'], function (_ref2) {
  var type = _ref2.type;
  return type && type === 'video' ? '#335b4c;' : '#fff;';
}, _theme2.default.max.medium(_templateObject4));

var Subtitle = _styledComponents2.default.p.withConfig({
  displayName: 'BentleySlide__Subtitle',
  componentId: 'sc-1r77d5k-4'
})(['font-family:Bentley-Light;font-size:16px;line-height:24px;color:', ';margin:10px 0;', ';'], function (_ref3) {
  var type = _ref3.type;
  return type && type === 'video' ? '#000;' : '#fff;';
}, _theme2.default.max.medium(_templateObject5));

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'BentleySlide__ButtonContainer',
  componentId: 'sc-1r77d5k-5'
})(['display:flex;flex-direction:column;gap:10px;']);

var styleOverride = function styleOverride() {
  return 'font-family: Bentley-SemiBold; letter-spacing: 1.2px;';
};

var BentleySlide = function BentleySlide(_ref4) {
  var dispatch = _ref4.dispatch,
      mediaContent = _ref4.mediaContent,
      onLinkClick = _ref4.onLinkClick,
      _ref4$preview = _ref4.preview,
      preview = _ref4$preview === undefined ? false : _ref4$preview,
      slideContentWidth = _ref4.slideContentWidth,
      translations = _ref4.translations,
      url = _ref4.url,
      primaryCTA = _ref4.primaryCTA,
      secondaryCTA = _ref4.secondaryCTA;

  var _useNavigation = (0, _navigation2.default)(),
      navigateInternalUrl = _useNavigation.navigateInternalUrl;

  var ipCountryName = (0, _ipInformation.getIpCountryName)();
  var userCountryName = sessionStorageManager.getKey('userCountryLongName') ? sessionStorageManager.getKey('userCountryLongName') : ipCountryName;
  var displaySingleCountryStock = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.displaySingleCountryStock;
  });

  var navigate = function navigate(e, url) {
    e.preventDefault();
    navigateInternalUrl(url);
  };

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      SlideContainer,
      { dispatch: dispatch },
      mediaContent.type === 'image' && _react2.default.createElement(_Image2.default, {
        url: url,
        preview: preview,
        mediaContent: mediaContent,
        onLinkClick: onLinkClick
      }),
      mediaContent.type === 'video' && _react2.default.createElement(_Video2.default, { url: url, preview: preview, mediaContent: mediaContent, onLinkClick: onLinkClick }),
      _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: slideContentWidth },
        _react2.default.createElement(
          SlideOneContentContainer,
          null,
          _react2.default.createElement(
            TopSlideContentContainer,
            null,
            _react2.default.createElement(
              'div',
              null,
              translations.header1 && _react2.default.createElement(
                Header1,
                { type: mediaContent.type },
                translations.header1
              ),
              translations.header2 && _react2.default.createElement(
                Header1,
                { type: mediaContent.type },
                translations.header2
              )
            ),
            translations.body && _react2.default.createElement(
              Subtitle,
              { type: mediaContent.type },
              translations.body
            ),
            _react2.default.createElement(
              ButtonContainer,
              null,
              translations.primaryCTA && _react2.default.createElement(_Form.Button, {
                variant: 'primary',
                text: translations.primaryCTA,
                onClick: function onClick(e) {
                  navigate(e, '' + primaryCTA.url + (displaySingleCountryStock ? '&subregions=' + userCountryName : ''));
                },
                styleOverride: styleOverride
              }),
              translations.body2 && _react2.default.createElement(
                Subtitle,
                { type: mediaContent.type },
                translations.body2
              ),
              translations.secondaryCTA && _react2.default.createElement(_Form.Button, {
                variant: 'secondary',
                text: translations.secondaryCTA,
                onClick: function onClick(e) {
                  navigate(e, secondaryCTA.url);
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'min.medium',
                    template: '' + (mediaContent.type === 'video' && 'border: 1px solid #335b4c !important; color: #335b4c')
                  }];
                }
              })
            )
          )
        )
      ),
      _react2.default.createElement(_reactWaypoint2.default, {
        dispatch: dispatch,
        topOffset: '20%',
        onPositionChange: function onPositionChange(props) {
          return dispatch(_sessionPreferences.actions.updateSessionPreferences('isHeroVisible', props.currentPosition === 'inside'));
        }
      })
    )
  );
};

exports.default = BentleySlide;