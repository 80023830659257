'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FilterMenuBar;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Plus = require('../JaguarIcons/Global/Plus');

var _Plus2 = _interopRequireDefault(_Plus);

var _Minus = require('../JaguarIcons/Global/Minus');

var _Minus2 = _interopRequireDefault(_Minus);

var _Global = require('../Global');

var _SearchFiltersContainer = require('../../containers/SearchFiltersContainer');

var _SearchFiltersContainer2 = _interopRequireDefault(_SearchFiltersContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FiltersContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FilterMenuBar__FiltersContainer',
  componentId: 'sc-pgmfch-0'
})(['width:', ';height:auto;overflow-x:hidden;', ';'], function (props) {
  return props.filtersExpanded ? '400px' : '50%';
}, function (props) {
  return props.filtersExpanded && 'border-right: 1px solid #acacac; box-sizing: border-box; padding-right: 20px;';
});
var FilterAccordion = _styledComponents2.default.div.withConfig({
  displayName: 'FilterMenuBar__FilterAccordion',
  componentId: 'sc-pgmfch-1'
})(['display:flex;align-items:center;height:90px;justify-content:flex-start;']);
var FilterButton = _styledComponents2.default.div.withConfig({
  displayName: 'FilterMenuBar__FilterButton',
  componentId: 'sc-pgmfch-2'
})(['border:1px solid #999999;height:45px;width:350px;display:flex;align-items:center;justify-content:space-between;font-size:14px;color:#444444;box-sizing:border-box;padding:0 15px;cursor:pointer;']);
var Filters = _styledComponents2.default.div.withConfig({
  displayName: 'FilterMenuBar__Filters',
  componentId: 'sc-pgmfch-3'
})(['display:', ';width:100%;padding-bottom:50px;'], function (props) {
  return props.filtersExpanded ? 'block' : 'none';
});

var Icon = function Icon(_ref) {
  var filtersExpanded = _ref.filtersExpanded;
  return _react2.default.createElement(
    'div',
    null,
    filtersExpanded ? _react2.default.createElement(_Minus2.default, { width: '1.5em', height: '1.5em', colour: '#7e7e7e' }) : _react2.default.createElement(_Plus2.default, { width: '1.5em', height: '1.5em', colour: '#7e7e7e' })
  );
};

function FilterMenuBar(_ref2) {
  var filtersExpanded = _ref2.filtersExpanded,
      onFilterMenuClick = _ref2.onFilterMenuClick,
      translations = _ref2.translations,
      preview = _ref2.preview,
      resetFilters = _ref2.resetFilters,
      config = _ref2.config,
      history = _ref2.history,
      globalStyling = _ref2.globalStyling,
      featureFlags = _ref2.featureFlags;

  return _react2.default.createElement(
    FiltersContainer,
    { filtersExpanded: filtersExpanded },
    _react2.default.createElement(
      FilterAccordion,
      null,
      _react2.default.createElement(
        FilterButton,
        { 'data-cy': 'filters-accordion', onClick: onFilterMenuClick },
        translations.searchResultsFilterLabel,
        _react2.default.createElement(Icon, { filtersExpanded: filtersExpanded })
      )
    ),
    _react2.default.createElement(
      Filters,
      { filtersExpanded: filtersExpanded },
      preview ? null : _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_SearchFiltersContainer2.default, {
          config: config,
          history: history,
          featureFlags: featureFlags
        }),
        _react2.default.createElement(_Global.Button, {
          onClick: resetFilters,
          text: translations.searchCTAReset,
          applyStyle: 'secondary',
          buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle,
          styleOverride: function styleOverride() {
            return '\n                width: 100%;\n                margin-top: 20px;\n              ';
          }
        })
      )
    )
  );
}
FilterMenuBar.defaultProps = {
  filtersExpanded: false
};