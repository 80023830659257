'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: ', ';\n  '], ['\n    padding: ', ';\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('./Global');

var _types = require('../types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAdditionalInfo__HeaderContainer',
  componentId: 'sc-10omu4s-0'
})(['display:flex;padding:', ';', ';'], function (_ref) {
  var padding = _ref.padding;
  return '' + (padding === 0 ? '' + padding : '32px 0');
}, _theme2.default.max.large(_templateObject, function (_ref2) {
  var padding = _ref2.padding;
  return '' + (padding === 0 ? '' + padding : '32px 5%');
}));

var MarkdownWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpAdditionalInfo__MarkdownWrapper',
  componentId: 'sc-10omu4s-1'
})(['margin:0px 16px 48px 16px;']);

exports.default = function (_ref3) {
  var text = _ref3.text,
      padding = _ref3.padding,
      font = _ref3.font,
      translations = _ref3.translations;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      HeaderContainer,
      { padding: padding },
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n            width: 100%;\n            ' + (0, _Global.fontStyleOverride)(font) + '\n          ';
          }
        },
        translations.extraInformation
      )
    ),
    _react2.default.createElement(
      MarkdownWrapper,
      null,
      _react2.default.createElement(_reactMarkdown2.default, { source: text })
    )
  );
};