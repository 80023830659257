'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PdfButton = exports.BadgeLabel = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRedux = require('react-redux');

var _Spinner = require('./Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _printing = require('../shared/printing');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _numbers = require('../shared/localisation/numbers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var efficiencyColours = {
  'A+': '#00A550',
  A: '#00A550',
  B: '#50B948',
  C: '#BED632',
  D: '#FEF003',
  E: '#FBB714',
  F: '#F36E22',
  G: '#ED1E23'
};


var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'SearchCo2Accordian__Icon',
  componentId: 'sc-h3449-0'
})(['width:22px;cursor:pointer;']);

var Arrow = (0, _styledComponents2.default)(Icon).withConfig({
  displayName: 'SearchCo2Accordian__Arrow',
  componentId: 'sc-h3449-1'
})(['transform:rotate(0deg);transition:transform 0.2s ease-out;', ''], function (_ref) {
  var open = _ref.open;
  return open && (0, _styledComponents.css)(['transform:rotate(180deg);']);
});

var AccordianContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchCo2Accordian__AccordianContainer',
  componentId: 'sc-h3449-2'
})(['width:100%;']);

var EfficiencyBadgeContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchCo2Accordian__EfficiencyBadgeContainer',
  componentId: 'sc-h3449-3'
})(['display:flex;']);

var BadgeLabel = exports.BadgeLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SearchCo2Accordian__BadgeLabel',
  componentId: 'sc-h3449-4'
})(['display:flex;height:24px;padding:0 10px;align-items:center;color:white;font-size:12px;padding-top:2px;font-weight:bold;background-color:', ';'], function (_ref2) {
  var emissionsClass = _ref2.emissionsClass;
  return efficiencyColours[emissionsClass];
});

var BadgeArrow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchCo2Accordian__BadgeArrow',
  componentId: 'sc-h3449-5'
})(['width:0;height:0;border-top:13px solid transparent;border-bottom:13px solid transparent;border-left:13px solid ', ';'], function (_ref3) {
  var emissionsClass = _ref3.emissionsClass;
  return efficiencyColours[emissionsClass] || '#FFFFFF';
});

var FlexRow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchCo2Accordian__FlexRow',
  componentId: 'sc-h3449-6'
})(['display:flex;flex-direction:row;justify-content:space-between;align-items:center;width:', ';'], function (_ref4) {
  var width = _ref4.width;
  return width || '100%';
});

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'SearchCo2Accordian__Label',
  componentId: 'sc-h3449-7'
})(['font-size:', ';'], function (_ref5) {
  var font = _ref5.font;
  return font && font.fontSize + 'px' || '16px';
});

var FlexColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchCo2Accordian__FlexColumn',
  componentId: 'sc-h3449-8'
})(['display:flex;flex-direction:column;width:', ';padding:10px 10px 0px 10px;'], function (_ref6) {
  var width = _ref6.width;
  return width || '100%';
});

var PdfButton = exports.PdfButton = function PdfButton(_ref7) {
  var vehicle = _ref7.vehicle,
      globalStyling = _ref7.globalStyling;

  var dispatch = (0, _reactRedux.useDispatch)();
  var isPrinting = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.printing.isPrinting;
  });

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      isSpinning = _useState2[0],
      setIsSpinning = _useState2[1];

  (0, _react.useEffect)(function () {
    if (isSpinning && !isPrinting) setIsSpinning(false);
  }, [isPrinting, isSpinning]);

  return isSpinning ? _react2.default.createElement(_Spinner2.default, { colour: globalStyling.colours.primaryBrandColour, size: 18 }) : _react2.default.createElement(Icon, {
    src: 'https://res.cloudinary.com/motortrak/image/upload/c_fit,w_28/v1583338975/cms/global/icons/pdf.svg',
    onClick: function onClick() {
      setIsSpinning(true);
      dispatch(_printing.actions.printEmissionInfo(vehicle));
    }
  });
};

var getTranslationsFromTemplate = function getTranslationsFromTemplate(engineDetails, fuel, translate, locale) {
  var consumptionInTown =
  // use !! to prevent 0 from being displayed on UI
  fuel !== 'Elektro' && !!engineDetails.consumptionUrban && translate('consumptionInTown', {
    COMBINEDURBAN: (0, _numbers.localiseNumber)(engineDetails.consumptionUrban, locale)
  });
  var consumptionOutside = fuel !== 'Elektro' && !!engineDetails.consumptionExtraurban && translate('consumptionOutside', {
    COMBINEDEXTRAURBAN: (0, _numbers.localiseNumber)(engineDetails.consumptionExtraurban, locale)
  });
  var consumptionCombined = fuel !== 'Elektro' && !!engineDetails.combinedConsumption && translate('consumptionCombined', {
    COMBINEDCONSUMPTION: (0, _numbers.localiseNumber)(engineDetails.combinedConsumption, locale)
  });
  var co2EmissionsCombined = translate('co2EmissionsCombined', {
    EMISSIONS: engineDetails.co2EmissionsCombined === 0 ? '0' : (0, _numbers.localiseNumber)(engineDetails.co2EmissionsCombined, locale)
  });

  var combinedPower = !!engineDetails.combinedPowerConsumption && translate('powerConsumption', {
    COMBINEDPOWER: (0, _numbers.localiseNumber)(engineDetails.combinedPowerConsumption, locale)
  });

  return {
    consumptionInTown: consumptionInTown,
    consumptionOutside: consumptionOutside,
    consumptionCombined: consumptionCombined,
    co2EmissionsCombined: co2EmissionsCombined,
    combinedPower: combinedPower
  };
};

var SearchCo2Accordian = function SearchCo2Accordian(_ref8) {
  var vehicle = _ref8.vehicle,
      translations = _ref8.translations,
      globalStyling = _ref8.globalStyling,
      font = _ref8.font,
      locale = _ref8.locale;

  var _useState3 = (0, _react.useState)(true),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      open = _useState4[0],
      setOpen = _useState4[1];

  var emissionsClass = vehicle.engineDetails.emissionsClass;

  var _getTranslationsFromT = getTranslationsFromTemplate(vehicle.engineDetails, vehicle.fuel, function (key, placeholder) {
    return (0, _translateFromTemplate.translateFromTemplate)(key, placeholder, translations);
  }, locale),
      consumptionInTown = _getTranslationsFromT.consumptionInTown,
      consumptionOutside = _getTranslationsFromT.consumptionOutside,
      consumptionCombined = _getTranslationsFromT.consumptionCombined,
      co2EmissionsCombined = _getTranslationsFromT.co2EmissionsCombined,
      combinedPower = _getTranslationsFromT.combinedPower;

  return _react2.default.createElement(
    AccordianContainer,
    null,
    emissionsClass ? _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        FlexRow,
        null,
        _react2.default.createElement(
          FlexRow,
          { width: '200px' },
          _react2.default.createElement(
            EfficiencyBadgeContainer,
            null,
            _react2.default.createElement(
              BadgeLabel,
              { emissionsClass: emissionsClass },
              emissionsClass
            ),
            _react2.default.createElement(BadgeArrow, { emissionsClass: emissionsClass })
          ),
          _react2.default.createElement(
            Label,
            null,
            translations.efficiencyClass
          ),
          _react2.default.createElement(PdfButton, { vehicle: vehicle, globalStyling: globalStyling })
        ),
        _react2.default.createElement(Arrow, {
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1553256851/cms/jaguar/global/icons/arrow-down.svg',
          onClick: function onClick() {
            return setOpen(!open);
          },
          open: open
        })
      ),
      open && _react2.default.createElement(
        FlexColumn,
        null,
        _react2.default.createElement(
          Label,
          { font: font },
          consumptionInTown
        ),
        _react2.default.createElement(
          Label,
          { font: font },
          consumptionOutside
        ),
        _react2.default.createElement(
          Label,
          { font: font },
          consumptionCombined
        ),
        _react2.default.createElement(
          Label,
          { font: font },
          combinedPower
        ),
        _react2.default.createElement(
          Label,
          { font: font },
          co2EmissionsCombined
        )
      )
    ) : _react2.default.createElement(
      Label,
      null,
      translations.noEmissions
    )
  );
};
exports.default = SearchCo2Accordian;