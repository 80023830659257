'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 36px;\n    margin: 0 0 40px;\n  '], ['\n    font-size: 36px;\n    margin: 0 0 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    gap: 20px;\n  '], ['\n    flex-direction: column;\n    gap: 20px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n      height: 400px;\n  '], ['\n      height: 400px;\n  ']); /* eslint-env browser */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _GoogleMap = require('../../GoogleMap');

var _GoogleMap2 = _interopRequireDefault(_GoogleMap);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _RetailerInformation = require('../../../modules/RetailerInformation/RetailerInformation');

var _RetailerInfo = require('./RetailerInfo');

var _RetailerInfo2 = _interopRequireDefault(_RetailerInfo);

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _types = require('./types');

var _Location = require('../../../types/Location');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__Wrapper',
  componentId: 'sc-1j2uswc-0'
})(['margin:80px auto;width:100%;max-width:1280px;', ';'], function () {
  return _Genesis.mixins.pageGuttering();
});

var SectionTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'VdpRetailerMap__SectionTitle',
  componentId: 'sc-1j2uswc-1'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Light\';font-weight:normal;font-size:24px;margin:0 0 20px;text-transform:uppercase;', ';'], _Genesis2.default.min.medium(_templateObject));

var RetailerContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__RetailerContainer',
  componentId: 'sc-1j2uswc-2'
})(['display:flex;gap:40px;margin-bottom:40px;width:100%;justify-content:space-between;', ';'], _Genesis2.default.max.medium(_templateObject2));

var RetailerInfoWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__RetailerInfoWrapper',
  componentId: 'sc-1j2uswc-3'
})(['display:flex;flex-direction:column;']);

var MapContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__MapContainer',
  componentId: 'sc-1j2uswc-4'
})(['flex:auto;height:250px;max-width:586px;', ';'], _Genesis2.default.min.large(_templateObject3));
var GoogleMapStatic = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__GoogleMapStatic',
  componentId: 'sc-1j2uswc-5'
})(['width:100%;height:100%;background-repeat:no-repeat;background-position:center right;background-size:cover;line-height:0;', ';'], function (_ref) {
  var url = _ref.url;
  return url && 'background-image: url(' + url + ')';
});

var VdpRetailerMap = function VdpRetailerMap(_ref2) {
  var config = _ref2.config,
      retailer = _ref2.retailer,
      translations = _ref2.translations,
      viewRetailerStock = _ref2.viewRetailerStock,
      googleAvailable = _ref2.googleAvailable,
      vehicleInfo = _ref2.vehicleInfo,
      makeAnEnquiry = _ref2.makeAnEnquiry;

  var flipRetailerCoordinates = function flipRetailerCoordinates() {
    if (config.flipRetailerCoordinates && retailer.location && retailer.location.coordinates && retailer.location.coordinates.length === 2) {
      return {
        coordinates: [retailer.location.coordinates[1], retailer.location.coordinates[0]]
      };
    }
    return retailer.location;
  };

  return _react2.default.createElement(
    Wrapper,
    { id: 'retailerMap' },
    _react2.default.createElement(
      SectionTitle,
      null,
      translations.titleRetailerInfo
    ),
    _react2.default.createElement(
      RetailerContainer,
      null,
      _react2.default.createElement(
        RetailerInfoWrapper,
        null,
        _react2.default.createElement(_RetailerInfo2.default, {
          config: config,
          retailer: retailer,
          translations: translations,
          vehicle: vehicleInfo,
          viewRetailerStock: viewRetailerStock,
          makeAnEnquiry: makeAnEnquiry
        })
      ),
      _react2.default.createElement(
        MapContainer,
        null,
        config.map.provider === 'google' ? config.map.type === 'dynamic' && googleAvailable ? _react2.default.createElement(_GoogleMap2.default, {
          mapName: 'vdpMap',
          retailerLocation: flipRetailerCoordinates()
        }) : _react2.default.createElement(GoogleMapStatic, { url: retailer.dealerLocationMapUrl }) : null
      )
    )
  );
};

exports.default = VdpRetailerMap;