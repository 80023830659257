'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    height: 60px;\n  '], ['\n    height: 60px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 50px;\n  '], ['\n    padding: 0 50px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 72px;\n  '], ['\n    width: 72px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    flex: 1 1 80%;\n    display: flex;\n    align-items: center;\n    z-index: 9;\n    position: relative;\n  '], ['\n    flex: 1 1 80%;\n    display: flex;\n    align-items: center;\n    z-index: 9;\n    position: relative;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    flex: 1 1 10%;\n    display: flex;\n    justify-content: flex-end;\n    z-index: 9;\n    position: relative;\n  '], ['\n    flex: 1 1 10%;\n    display: flex;\n    justify-content: flex-end;\n    z-index: 9;\n    position: relative;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

exports.default = HeaderMenu;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Header = require('../../../modules/Header/Header');

var _MobileMenu = require('../../../modules/Header/MobileMenu');

var _MobileMenu2 = _interopRequireDefault(_MobileMenu);

var _LanguageCurrencySelectionModal = require('../../../modules/Header/Templates/TemplateTwo/LanguageCurrencySelectionModal');

var _LanguageCurrencySelectionModal2 = _interopRequireDefault(_LanguageCurrencySelectionModal);

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _types = require('../../../types');

var _MarketInfo = require('../../../types/MarketInfo');

var _CurrencyPopup = require('../../CurrencyPopup');

var _CurrencyPopup2 = _interopRequireDefault(_CurrencyPopup);

var _Global = require('../../Global');

var _HeaderSubNav = require('../../HeaderSubNav');

var _HeaderSubNav2 = _interopRequireDefault(_HeaderSubNav);

var _ModalOverlay = require('../../ModalOverlay');

var _ModalOverlay2 = _interopRequireDefault(_ModalOverlay);

var _SocialSharePopup = require('../../SocialSharePopup');

var _SocialSharePopup2 = _interopRequireDefault(_SocialSharePopup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HeaderWrapper = _styledComponents2.default.header.withConfig({
  displayName: 'HeaderMenu__HeaderWrapper',
  componentId: 'sc-wmbgxh-0'
})(['', ';box-sizing:border-box;width:100%;z-index:100;height:94px;', ';'], _theme2.default.max.large(_templateObject), function (_ref) {
  var stickyHeader = _ref.stickyHeader,
      opacity = _ref.opacity,
      backgroundColour = _ref.backgroundColour;
  return '\n      position: ' + (stickyHeader ? 'fixed' : 'static') + ';\n      ' + (opacity && '\n        &:after {\n          content: \'\';\n          width: 100%;\n          height: inherit;\n          background-color: ' + backgroundColour + ';\n          opacity: ' + opacity + ';\n          position: absolute;\n          top: 0;\n          left: 0;\n        }\n      ') + ';\n    ';
});

var TopWrapper = _styledComponents2.default.nav.withConfig({
  displayName: 'HeaderMenu__TopWrapper',
  componentId: 'sc-wmbgxh-1'
})(['padding:0 20px 0 10px;width:100%;display:flex;align-items:center;height:inherit;box-sizing:border-box;', ';'], _theme2.default.min.large(_templateObject2));

var ImageWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'HeaderMenu__ImageWrapper',
  componentId: 'sc-wmbgxh-2'
})(['flex:1 1 0%;z-index:9;position:relative;top:4px;left:4px;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'HeaderMenu__Image',
  componentId: 'sc-wmbgxh-3'
})(['', ';width:50px;', ';'], function (_ref2) {
  var source = _ref2.source;
  return source && 'cursor: pointer;';
}, _theme2.default.min.large(_templateObject3));

var LinksWrapper = _styledComponents2.default.ul.withConfig({
  displayName: 'HeaderMenu__LinksWrapper',
  componentId: 'sc-wmbgxh-4'
})(['list-style:none;display:none;', ';'], _theme2.default.min.large(_templateObject4));

var ActionsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'HeaderMenu__ActionsWrapper',
  componentId: 'sc-wmbgxh-5'
})(['display:none;margin:15px 0;', ';'], _theme2.default.min.large(_templateObject5));
var MenuButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'HeaderMenu__MenuButtonWrapper',
  componentId: 'sc-wmbgxh-6'
})(['z-index:9;position:relative;', ';'], _theme2.default.min.large(_templateObject6));

var ShareIcon = _styledComponents2.default.img.withConfig({
  displayName: 'HeaderMenu__ShareIcon',
  componentId: 'sc-wmbgxh-7'
})(['padding-right:16px;display:flex;&:hover{opacity:0.6;}&:focus:not(:focus-visible):{outline:none;}']);

var UnstyledButton = _styledComponents2.default.button.withConfig({
  displayName: 'HeaderMenu__UnstyledButton',
  componentId: 'sc-wmbgxh-8'
})(['background:inherit;border:transparent;padding:0;']);

var MenuItem = _styledComponents2.default.li.withConfig({
  displayName: 'HeaderMenu__MenuItem',
  componentId: 'sc-wmbgxh-9'
})(['margin:0 12px;']);

var Link = _styledComponents2.default.a.withConfig({
  displayName: 'HeaderMenu__Link',
  componentId: 'sc-wmbgxh-10'
})(['text-decoration:none;&:after{display:block;content:\'\';height:1px;margin-top:4px;box-sizing:border-box;background-color:', ';transform:scaleX(0);transition:transform 250ms ease-in-out;}&:hover:after{transform:scaleX(1);}&.active{&:after{border-bottom:1px solid ', ';transform:scaleX(1);}}'], function (_ref3) {
  var underlineColour = _ref3.underlineColour;
  return underlineColour && underlineColour.value;
}, function (_ref4) {
  var underlineColour = _ref4.underlineColour;
  return underlineColour && underlineColour.value;
});

var SubNav = _styledComponents2.default.div.withConfig({
  displayName: 'HeaderMenu__SubNav',
  componentId: 'sc-wmbgxh-11'
})(['position:absolute;top:', ';left:0;width:100%;height:auto;background-color:rgba(32,32,32,0.95);box-sizing:border-box;transition:all 500ms cubic-bezier(0.455,0.03,0.515,0.955);'], function (_ref5) {
  var open = _ref5.open;
  return open ? '0' : '-500px';
});

var RightMenuWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'HeaderMenu__RightMenuWrapper',
  componentId: 'sc-wmbgxh-12'
})(['display:flex;gap:10px;']);

var SkipToMainLink = _styledComponents2.default.a.withConfig({
  displayName: 'HeaderMenu__SkipToMainLink',
  componentId: 'sc-wmbgxh-13'
})(['color:white;position:absolute;transform:translateY(-100%);&:focus{position:fixed;transform:translateY(0%);padding:20px;background-color:#dfb400;z-index:100;font-family:RobotoLight,Arial,sans-serif;font-size:22px;}']);

function HeaderMenu(props) {
  var config = props.config,
      links = props.links,
      navigate = props.navigate,
      globalStyling = props.globalStyling,
      currentLanguage = props.currentLanguage,
      currentCurrency = props.currentCurrency,
      socialShare = props.socialShare,
      shared = props.shared,
      utilityBarActions = props.utilityBarActions;

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      primaryBrandColour = _useSelector.colours.primaryBrandColour,
      primaryButton = _useSelector.uiElements.primaryButton;

  var languageOptions = config.utilityBarOptions && config.utilityBarOptions.find(function (op) {
    return op.key === 'Language';
  }).options;
  var currencyOptions = config.utilityBarOptions && config.utilityBarOptions.find(function (op) {
    return op.key === 'Currency';
  }).options;
  var territoryOptions = (config.utilityBarOptions.find(function (op) {
    return op.key === 'Territory';
  }) || {}).options;
  var selectedLanguage = languageOptions.find(function (lo) {
    return lo.value === currentLanguage;
  });
  var selectedCurrency = currencyOptions.find(function (co) {
    return co.value === currentCurrency;
  });

  var _useState = (0, _react.useState)(),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      subNavData = _useState2[0],
      setSubNavData = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      nextActiveLink = _useState4[0],
      setNextActiveLink = _useState4[1];

  var _useState5 = (0, _react.useState)(null),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      activeLink = _useState6[0],
      setActiveLink = _useState6[1];

  var Modal = function Modal(closeModalFn) {
    return _react2.default.createElement(_LanguageCurrencySelectionModal2.default, {
      languages: languageOptions,
      selectedLanguage: selectedLanguage,
      currencies: currencyOptions,
      selectedCurrency: selectedCurrency,
      territories: territoryOptions,
      selectedTerritory: props.marketInfo.territory || '',
      translations: config.translations,
      changeLanguage: utilityBarActions.language,
      changeCurrency: utilityBarActions.currency,
      changeTerritory: utilityBarActions.territory,
      selectFont: config.modalSelectionFont,
      button: primaryButton,
      closeModalFn: closeModalFn
    });
  };

  return _react2.default.createElement(
    HeaderWrapper,
    {
      stickyHeader: config.stickyHeader,
      opacity: config.opacity,
      backgroundColour: config.backgroundColour && config.backgroundColour.value,
      onMouseLeave: function onMouseLeave() {
        return setSubNavData(undefined);
      },
      'data-nosnippet': true
    },
    _react2.default.createElement(_CurrencyPopup2.default, {
      translations: config.translations,
      globalStyling: globalStyling,
      disableCurrencyPopup: utilityBarActions.disableCurrencyPopup,
      shared: shared
    }),
    _react2.default.createElement(
      SkipToMainLink,
      { href: '#main-start-here' },
      config.translations.skipToMainContent
    ),
    _react2.default.createElement(
      TopWrapper,
      null,
      _react2.default.createElement(
        ImageWrapper,
        null,
        _react2.default.createElement(
          Link,
          {
            href: config.brandLogoLink ? config.brandLogoLink.value : undefined
          },
          _react2.default.createElement(Image, {
            'data-cy': 'logo',
            source: config.brandLogoLink.value,
            src: config.brandLogoUrl,
            alt: config.translations.logoAlternativeText
          })
        )
      ),
      _react2.default.createElement(
        SubNav,
        { open: subNavData, onMouseLeave: function onMouseLeave() {
            return setActiveLink(null);
          } },
        subNavData && _react2.default.createElement(_HeaderSubNav2.default, {
          globalStyling: globalStyling,
          links: subNavData,
          navigate: navigate,
          config: config,
          nextActiveLink: nextActiveLink
        })
      ),
      _react2.default.createElement(
        LinksWrapper,
        null,
        links.map(function (link, i) {
          return _react2.default.createElement(
            MenuItem,
            { key: link.navId },
            _react2.default.createElement(
              Link,
              {
                id: link.navId,
                className: activeLink === link.navId && link.subLinks ? 'active' : '',
                key: link.navId,
                href: link.link,
                'data-cy': link.link,
                onClick: function onClick(event) {
                  event.preventDefault();
                  navigate(link.link);
                },
                onMouseEnter: function onMouseEnter() {
                  setSubNavData(link.subLinks);
                  setActiveLink(link.navId);
                },
                onFocus: function onFocus() {
                  if (link.subLinks) {
                    setSubNavData(link.subLinks);
                    setActiveLink(link.navId);
                    if (links[i + 1]) {
                      setNextActiveLink(links[i + 1].navId);
                    }
                  } else {
                    setSubNavData(undefined);
                  }
                },
                onKeyDown: function onKeyDown(e) {
                  if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    document.getElementById(link.subLinks[0].navId).focus();
                  }
                },
                underlineColour: primaryBrandColour
              },
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return (0, _Global.fontStyleOverride)(config.menuBarLinkFont);
                  }
                },
                link.label
              )
            )
          );
        })
      ),
      _react2.default.createElement(
        ActionsWrapper,
        null,
        _react2.default.createElement(
          _SocialSharePopup2.default,
          { config: config, socialShare: socialShare },
          _react2.default.createElement(
            UnstyledButton,
            null,
            _react2.default.createElement(ShareIcon, {
              'data-cy': 'socialShare',
              src: config.socialShareIconUrl,
              alt: 'share this page'
            })
          )
        ),
        _react2.default.createElement(
          _ModalOverlay2.default,
          {
            component: _react2.default.createElement(
              RightMenuWrapper,
              null,
              territoryOptions && _react2.default.createElement(
                UnstyledButton,
                null,
                _react2.default.createElement(
                  _Global.HeadingTwo,
                  {
                    'data-cy': 'nav2-territory',
                    title: props.marketInfo.territory,
                    styleOverride: function styleOverride() {
                      return '\n                        border: 1px solid #ffffff;\n                        cursor: pointer;\n                        height: 100%;\n                        align-items: center;\n                        justify-content: center;\n                        display: flex;\n                        &:hover {\n                          opacity: 0.5;\n                        }\n                        ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, config.menuBarLinkFont, {
                        fontSize: 14,
                        padding: {
                          left: 8,
                          right: 8,
                          top: 0,
                          bottom: 0
                        }
                      })) + '\n                      ';
                    }
                  },
                  props.marketInfo.territory
                )
              ),
              _react2.default.createElement(
                UnstyledButton,
                null,
                _react2.default.createElement(
                  _Global.HeadingTwo,
                  {
                    title: selectedLanguage.labelShorthand,
                    'data-cy': 'nav2-language',
                    styleOverride: function styleOverride() {
                      return '\n                      // margin-left: 10px;\n                      border: 1px solid #ffffff;\n                      cursor: pointer;\n                      height: 100%;\n                      align-items: center;\n                      justify-content: center;\n                      display: flex;\n                      &:hover {\n                        opacity: 0.5;\n                      }\n                      ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, config.menuBarLinkFont, {
                        fontSize: 14,
                        padding: {
                          left: 8,
                          right: 8,
                          top: 0,
                          bottom: 0
                        }
                      })) + '\n                    ';
                    }
                  },
                  selectedLanguage.labelShorthand
                )
              ),
              _react2.default.createElement(
                UnstyledButton,
                null,
                _react2.default.createElement(
                  _Global.HeadingTwo,
                  {
                    title: selectedCurrency ? selectedCurrency.labelShorthand : '',
                    'data-cy': 'nav2-currency',
                    styleOverride: function styleOverride() {
                      return '\n                      border: 1px solid #ffffff;\n                      cursor: pointer;\n                      height: 100%;\n                      min-width: 30px;\n                      align-items: center;\n                      justify-content: center;\n                      display: flex;\n                      &:hover {\n                        opacity: 0.5;\n                      }\n                      ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, config.menuBarLinkFont, {
                        fontSize: 14,
                        padding: {
                          left: 8,
                          right: 8,
                          top: 0,
                          bottom: 0
                        }
                      })) + '\n                    ';
                    }
                  },
                  selectedCurrency ? selectedCurrency.labelShorthand : '£ / $'
                )
              )
            ),
            modalWidth: 300,
            closeIconUrl: config.closeIconUrl,
            renderComponentWithProps: Modal
          },
          Modal
        )
      ),
      _react2.default.createElement(
        MenuButtonWrapper,
        { 'data-cy': 'mobile-menu-button' },
        _react2.default.createElement(_MobileMenu2.default, {
          config: config,
          languages: languageOptions,
          currencies: currencyOptions,
          links: links,
          selectedLanguage: selectedLanguage,
          changeLanguage: utilityBarActions.language,
          selectedCurrency: selectedCurrency,
          changeCurrency: utilityBarActions.currency,
          socialShare: socialShare,
          territories: territoryOptions,
          selectedTerritory: props.marketInfo.territory || '',
          changeTerritory: utilityBarActions.territory
        })
      )
    )
  );
}