'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Facebook = require('./Facebook');

Object.defineProperty(exports, 'Facebook', {
  enumerable: true,
  get: function get() {
    return _Facebook.Facebook;
  }
});

var _Twitter = require('./Twitter');

Object.defineProperty(exports, 'Twitter', {
  enumerable: true,
  get: function get() {
    return _Twitter.Twitter;
  }
});

var _Whatsapp = require('./Whatsapp');

Object.defineProperty(exports, 'Whatsapp', {
  enumerable: true,
  get: function get() {
    return _Whatsapp.Whatsapp;
  }
});

var _Email = require('./Email');

Object.defineProperty(exports, 'Email', {
  enumerable: true,
  get: function get() {
    return _Email.Email;
  }
});