'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.effects = undefined;

var _effects = require('./effects');

var _effects2 = _interopRequireDefault(_effects);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.effects = _effects2.default;