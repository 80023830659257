'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _DropdownFilter = require('./SearchFilters/DropdownFilter');

var _DropdownFilter2 = _interopRequireDefault(_DropdownFilter);

var _Global = require('./Global');

var _Refresh = require('./JaguarIcons/Global/Refresh');

var _Refresh2 = _interopRequireDefault(_Refresh);

var _UserLocation = require('./UserLocation');

var _UserLocation2 = _interopRequireDefault(_UserLocation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'StickySearchBar__Container',
  componentId: 'sc-1dyl6dn-0'
})(['display:flex;justify-content:center;align-items:center;width:100%;color:black;background-color:#d8d8d8;padding:6px 20px;height:80px;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'StickySearchBar__ButtonContainer',
  componentId: 'sc-1dyl6dn-1'
})(['display:flex;justify-content:space-evenly;margin:6px 20px;padding:10px;']);

var InputContainer = _styledComponents2.default.div.withConfig({
  displayName: 'StickySearchBar__InputContainer',
  componentId: 'sc-1dyl6dn-2'
})(['display:flex;justify-content:center;margin:5px 0 5px 15px;background:#ffffff;', ''], function (_ref) {
  var newFilters = _ref.newFilters;
  return !newFilters ? '\n    box-sizing: border-box;\n    height: 46px;\n    border-right: 1px solid #d8d8d8;\n  ' : '';
});
var VehicleCount = _styledComponents2.default.div.withConfig({
  displayName: 'StickySearchBar__VehicleCount',
  componentId: 'sc-1dyl6dn-3'
})(['display:flex;flex-direction:column;color:#0c121c;margin-top:-5px;text-align:center;& span:first-child{height:37px;width:64px;font-size:24px;line-height:37px;}& span:nth-child(2){height:16px;width:65px;font-size:13px;line-height:16px;text-align:center;text-transform:uppercase;}']);

var VehicleCountNearTown = _styledComponents2.default.div.withConfig({
  displayName: 'StickySearchBar__VehicleCountNearTown',
  componentId: 'sc-1dyl6dn-4'
})(['align-items:center;display:flex;']);

var VehicleCountTown = _styledComponents2.default.span.withConfig({
  displayName: 'StickySearchBar__VehicleCountTown',
  componentId: 'sc-1dyl6dn-5'
})(['font-weight:bold;line-height:16px;']);

var DropdownContainer = _styledComponents2.default.div.withConfig({
  displayName: 'StickySearchBar__DropdownContainer',
  componentId: 'sc-1dyl6dn-6'
})(['height:', ';box-sizing:border-box;width:160px;', ';display:flex;align-items:center;background:#ffffff;margin:5px 15px 5px 0;& h2{height:21px;width:99px;margin-top:8px;font-size:14px;}& span{color:#1f1f1f;font-size:14px;line-height:17px;}'], function (_ref2) {
  var newFilters = _ref2.newFilters;
  return newFilters ? '40px;' : '46px;';
}, function (_ref3) {
  var newFilters = _ref3.newFilters;
  return newFilters ? 'border: 1px solid #d8d8d8' : '';
});

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'StickySearchBar__IconWrapper',
  componentId: 'sc-1dyl6dn-7'
})(['display:flex;align-items:center;justify-content:center;']);

function labelWithIcon(label, iconColour, Icon) {
  return _react2.default.createElement(
    IconWrapper,
    null,
    _react2.default.createElement(Icon, { width: '24px', height: '24px', colour: iconColour }),
    _react2.default.createElement(
      'div',
      { style: { marginLeft: '8px', marginRight: '8px' } },
      label
    )
  );
}

var StickySearchBar = function (_Component) {
  (0, _inherits3.default)(StickySearchBar, _Component);

  function StickySearchBar() {
    var _ref4;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, StickySearchBar);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref4 = StickySearchBar.__proto__ || (0, _getPrototypeOf2.default)(StickySearchBar)).call.apply(_ref4, [this].concat(args))), _this), Object.defineProperty(_this, 'resetAllFilters', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _this.props.resetFilters();
      }
    }), Object.defineProperty(_this, 'handleSelectedDistanceChange', {
      enumerable: true,
      writable: true,
      value: function value(_ref5) {
        var _value = _ref5.value;
        return _this.props.handleMaxDistanceChange(_value);
      }
    }), Object.defineProperty(_this, 'goToSearch', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.props.buttonPress();
      }
    }), Object.defineProperty(_this, 'handleLocationChange', {
      enumerable: true,
      writable: true,
      value: function value(latitude, longitude, locationTown) {
        _this.props.handleLocationChange({
          latitude: latitude,
          longitude: longitude,
          locationTown: locationTown
        });
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(StickySearchBar, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          translations = _props.translations,
          dropdownTheme = _props.dropdownTheme,
          maxDistance = _props.maxDistance,
          globalStyling = _props.globalStyling,
          resetRangeCount = _props.filterSearchUrl.resetRangeCount,
          count = _props.count,
          distanceOptions = _props.distanceOptions,
          distanceFilterInitialSelectedIndex = _props.distanceFilterInitialSelectedIndex,
          searchCountryCode = _props.searchCountryCode,
          config = _props.config,
          newFilters = _props.newFilters,
          locationIconUrl = _props.locationIconUrl,
          fullTileSelect = _props.fullTileSelect,
          _props$location = _props.location,
          latitude = _props$location.latitude,
          longitude = _props$location.longitude,
          locationTown = _props$location.locationTown;
      var resetAllFilters = this.resetAllFilters,
          handleSelectedDistanceChange = this.handleSelectedDistanceChange,
          goToSearch = this.goToSearch;


      var selectedLocationDistanceOption = function selectedLocationDistanceOption() {
        var options = distanceOptions(translations);
        return options.find(function (o) {
          return o.value === maxDistance;
        }) || options[distanceFilterInitialSelectedIndex || 0];
      };

      var brandPrimaryColour = globalStyling.colours.brandPrimaryColour,
          secondaryButton = globalStyling.uiElements.secondaryButton;


      return _react2.default.createElement(
        Container,
        null,
        _react2.default.createElement(
          ButtonContainer,
          null,
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'reset-search-button',
            applyStyle: 'secondary',
            buttonStyle: 'ButtonStyleBTT',
            styleOverride: function styleOverride() {
              return 'height: 44px; width: 238px;';
            },
            text: labelWithIcon(translations.searchCTAResetSearch, brandPrimaryColour && brandPrimaryColour.value, _Refresh2.default),
            onClick: resetAllFilters
          })
        ),
        _react2.default.createElement(
          InputContainer,
          { newFilters: newFilters },
          newFilters ? _react2.default.createElement(_UserLocation2.default, {
            placeholderText: translations.searchLocationInputPlaceholder,
            newFilters: newFilters,
            iconUrl: config.locationIconUrl,
            browserLocationCallback: this.handleLocationChange,
            inputLocationCallback: this.handleLocationChange,
            options: {
              latitude: latitude,
              longitude: longitude,
              countryCode: searchCountryCode,
              autocompletePosition: 'above'
            }
          }) : _react2.default.createElement(_UserLocation2.default, {
            placeholderText: translations.searchLocationInputPlaceholder,
            iconUrl: locationIconUrl,
            newFilters: newFilters,
            browserLocationCallback: this.handleLocationChange,
            inputLocationCallback: this.handleLocationChange,
            options: {
              latitude: latitude,
              longitude: longitude,
              countryCode: searchCountryCode,
              autocompletePosition: 'above'
            }
          })
        ),
        _react2.default.createElement(
          DropdownContainer,
          { newFilters: newFilters },
          _react2.default.createElement(_DropdownFilter2.default, {
            width: 120,
            position: 'relative',
            theme: dropdownTheme,
            layout: 'above',
            onOptionClick: handleSelectedDistanceChange,
            border: false,
            height: 30,
            options: distanceOptions(translations),
            key: resetRangeCount,
            initialSelectedValue: selectedLocationDistanceOption(),
            selectedValue: maxDistance,
            placeholder: translations.distanceDropdownPlaceholder
          })
        ),
        fullTileSelect && fullTileSelect ? _react2.default.createElement(
          VehicleCountNearTown,
          null,
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return 'font-size: 26px; margin: 0 10px; color: #0C121C;height: 48px;';
              }
            },
            count
          ),
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return 'font-size: 13px; margin: 0; color: #0C121C; text-transform: uppercase; max-width: 170px';
              }
            },
            locationTown && translations.searchCarNumberAvailableWithin || translations.searchCarNumberAvailable,
            ' ',
            locationTown && selectedLocationDistanceOption().label,
            ' ',
            locationTown && translations.searchCarNumberAvailableOf,
            ' ',
            locationTown ? _react2.default.createElement(
              VehicleCountTown,
              null,
              locationTown
            ) : null
          )
        ) : _react2.default.createElement(
          VehicleCount,
          null,
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return 'font-size: 26px; margin: 0; color: #0C121C;';
              }
            },
            count
          ),
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return 'font-size: 13px; margin: 0; color: #0C121C; text-transform: uppercase;';
              }
            },
            translations.searchCarNumberAvailable
          )
        ),
        _react2.default.createElement(
          ButtonContainer,
          null,
          _react2.default.createElement(_Global.Button, {
            buttonStyle: secondaryButton && secondaryButton.buttonStyle,
            applyStyle: 'primary',
            text: translations.searchCTASearch,
            styleOverride: function styleOverride() {
              return 'height: 44px; width: 238px;';
            },
            onClick: goToSearch
          })
        )
      );
    }
  }]);
  return StickySearchBar;
}(_react.Component);

Object.defineProperty(StickySearchBar, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    count: 0
  }
});
exports.default = StickySearchBar;