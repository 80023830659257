'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Slide;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SlideContainer = _styledComponents2.default.img.withConfig({
  displayName: 'renderSlide__SlideContainer',
  componentId: 'sc-10bpzce-0'
})(['width:100%;position:absolute;@keyframes fadein{from{opacity:0.5;}to{opacity:1;}}', ' ', ''], function (_ref) {
  var active = _ref.active;
  return active && 'animation: fadein 0.6s ease-out;\n  ';
}, function (_ref2) {
  var fullscreen = _ref2.fullscreen;
  return fullscreen && '\n    height: 100%;\n    object-fit: contain;\n    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {\n    height: auto;\n    }\n  ';
});

function Slide(props) {
  return _react2.default.createElement(SlideContainer, {
    src: props.image,
    active: props.active,
    fullscreen: props.fullscreen,
    alt: props.alt
  });
}