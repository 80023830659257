'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_SIGN_IN';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['INIT', 'SIGN_IN', 'SIGN_IN_FAILURE']);

var id = exports.id = 'SignIn';

var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  signIn: (0, _reducerMacros.moduleActionWithArgs)(constants.SIGN_IN, id, function (payload) {
    return (0, _extends3.default)({}, payload);
  }),
  signInFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.SIGN_IN_FAILURE, id, function (payload) {
    return (0, _extends3.default)({}, payload);
  })
};

var initialState = exports.initialState = {
  error: null
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.INIT:
      return (0, _extends3.default)({}, state, {
        loginFailed: undefined
      });
    case constants.SIGN_IN_FAILURE:
      return (0, _extends3.default)({}, state, {
        loginFailed: action.payload.reason
      });
    default:
      return state;
  }
}