'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _chevronDown = require('react-icons/lib/io/chevron-down');

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronLeft = require('react-icons/lib/io/chevron-left');

var _chevronLeft2 = _interopRequireDefault(_chevronLeft);

var _chevronRight = require('react-icons/lib/io/chevron-right');

var _chevronRight2 = _interopRequireDefault(_chevronRight);

var _chevronUp = require('react-icons/lib/io/chevron-up');

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ContentWrapper = require('./ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SubNavBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBarContainer'
}).withConfig({
  displayName: 'SubNavBar__SubNavBarContainer',
  componentId: 'sc-1wo1z7q-0'
})(['display:flex;', ';background-color:', ';', ';'], function (_ref) {
  var theme = _ref.theme;
  return _theme.mixins.themeTemplate(theme);
}, function (_ref2) {
  var subNavBGColour = _ref2.subNavBGColour;
  return subNavBGColour || '#474747';
}, function (_ref3) {
  var bottomBorder = _ref3.bottomBorder;
  return '' + (bottomBorder && 'border-bottom: ' + bottomBorder);
});

var SubNavLink = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavLink'
}).withConfig({
  displayName: 'SubNavBar__SubNavLink',
  componentId: 'sc-1wo1z7q-1'
})(['display:flex;align-items:center;padding:0 26px;cursor:pointer;', ';border-left:', ';border-bottom:', ';border-right:', ';text-transform:uppercase;min-width:100px;justify-content:center;color:', ';background-color:', ';&:last-child{', ';', ';}'], function (_ref4) {
  var linkHeight = _ref4.linkHeight;
  return '' + (linkHeight && 'height: ' + linkHeight);
}, function (_ref5) {
  var subNavBorderColour = _ref5.subNavBorderColour,
      showNavBarItemBorder = _ref5.showNavBarItemBorder;
  return showNavBarItemBorder ? '0.5px solid  ' + (subNavBorderColour || '#7e7e7e') : 'none';
}, function (_ref6) {
  var subNavBorderColour = _ref6.subNavBorderColour,
      showNavBarItemBorder = _ref6.showNavBarItemBorder;
  return showNavBarItemBorder ? '0.5px solid  ' + (subNavBorderColour || '#7e7e7e') : 'none';
}, function (_ref7) {
  var subNavBorderColour = _ref7.subNavBorderColour,
      showNavBarItemBorder = _ref7.showNavBarItemBorder;
  return showNavBarItemBorder ? '0.5px solid  ' + (subNavBorderColour || '#7e7e7e') : 'none';
}, function (_ref8) {
  var selected = _ref8.selected,
      selectedFontColour = _ref8.selectedFontColour,
      theme = _ref8.theme;
  return selected ? selectedFontColour || theme.colour || '#FFF' : theme.colour || '#FFF';
}, function (_ref9) {
  var selected = _ref9.selected,
      selectedBackgroundColour = _ref9.selectedBackgroundColour;
  return selected ? selectedBackgroundColour : 'transparent';
}, function (props) {
  return props.isExploreRange && 'background-color: ' + props.exploreRangeBackgroundColor;
}, function (props) {
  return props.isExploreRange && 'color: ' + props.exploreRangeForegroundColour;
});

var DesktopLinksContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DesktopLinksContainer'
}).withConfig({
  displayName: 'SubNavBar__DesktopLinksContainer',
  componentId: 'sc-1wo1z7q-2'
})(['flex:1 0 0%;display:flex;justify-content:', ';max-width:100%;flex-wrap:wrap;', ';'], function (_ref10) {
  var align = _ref10.align;
  return align ? '' + align : 'center';
}, _theme2.default.max.large(_templateObject));

var PhoneTitleBar = _styledComponents2.default.div.withConfig({
  displayName: 'PhoneTitleBar'
}).withConfig({
  displayName: 'SubNavBar__PhoneTitleBar',
  componentId: 'sc-1wo1z7q-3'
})(['display:flex;flex:1 0 0%;justify-content:center;align-items:center;text-align:center;text-transform:uppercase;height:50px;', ';'], _theme2.default.min.large(_templateObject));

var PhoneOpenCloseButton = _styledComponents2.default.div.withConfig({
  displayName: 'PhoneOpenCloseButton'
}).withConfig({
  displayName: 'SubNavBar__PhoneOpenCloseButton',
  componentId: 'sc-1wo1z7q-4'
})(['display:flex;align-items:center;margin-right:20px;margin-left:20px;', ';'], _theme2.default.min.large(_templateObject));

var PhoneLinks = _styledComponents2.default.div.withConfig({
  displayName: 'PhoneLinks'
}).withConfig({
  displayName: 'SubNavBar__PhoneLinks',
  componentId: 'sc-1wo1z7q-5'
})(['display:flex;flex-direction:column;background-color:#474747;width:100%;', ';'], _theme2.default.min.large(_templateObject));

var PhoneLink = _styledComponents2.default.div.withConfig({
  displayName: 'PhoneLink'
}).withConfig({
  displayName: 'SubNavBar__PhoneLink',
  componentId: 'sc-1wo1z7q-6'
})(['display:flex;align-items:center;justify-content:center;text-align:center;', ';background-color:', ';border-top:1px solid #7e7e7e;height:40px;&:first-child{border:none;}'], function (_ref11) {
  var theme = _ref11.theme;
  return _theme.mixins.themeTemplate(theme);
}, function (_ref12) {
  var subNavBGColour = _ref12.subNavBGColour;
  return subNavBGColour || '#474747';
});

var BackButton = _styledComponents2.default.div.withConfig({
  displayName: 'BackButton'
}).withConfig({
  displayName: 'SubNavBar__BackButton',
  componentId: 'sc-1wo1z7q-7'
})(['display:', ';align-items:center;cursor:pointer;margin-left:20px;margin-right:20px;'], function (props) {
  return props.hidden ? 'none' : 'flex';
});

var SubNavBar = function (_React$Component) {
  (0, _inherits3.default)(SubNavBar, _React$Component);

  function SubNavBar() {
    var _ref13;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, SubNavBar);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref13 = SubNavBar.__proto__ || (0, _getPrototypeOf2.default)(SubNavBar)).call.apply(_ref13, [this].concat(args))), _this), Object.defineProperty(_this, 'state', {
      enumerable: true,
      writable: true,
      value: {
        open: false
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(SubNavBar, [{
    key: 'toggle',
    value: function toggle() {
      this.setState(function (prev) {
        return (0, _extends3.default)({}, prev, { open: !prev.open });
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          onBackButtonClick = _props.onBackButtonClick,
          links = _props.links,
          title = _props.title,
          isExploreRange = _props.isExploreRange,
          subNavBGColour = _props.subNavBGColour,
          subNavBorderColour = _props.subNavBorderColour,
          subNavFontTheme = _props.subNavFontTheme,
          subNavChevronColour = _props.subNavChevronColour,
          exploreRangeBackgroundColor = _props.exploreRangeBackgroundColor,
          showNavBarItemBorder = _props.showNavBarItemBorder,
          exploreRangeForegroundColour = _props.exploreRangeForegroundColour,
          selected = _props.selected,
          subNavBarItemSelectedBackgroundColour = _props.subNavBarItemSelectedBackgroundColour,
          subNavBarItemSelectedFontColour = _props.subNavBarItemSelectedFontColour,
          contentWidth = _props.contentWidth,
          subNavHeight = _props.subNavHeight,
          alignMenuItems = _props.alignMenuItems,
          navBarBottomBorder = _props.navBarBottomBorder,
          globalStyling = _props.globalStyling;

      var colourValue = function colourValue(colour) {
        return colour && colour.value;
      };

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          SubNavBarContainer,
          {
            theme: subNavFontTheme,
            subNavBGColour: colourValue(subNavBGColour),
            bottomBorder: navBarBottomBorder
          },
          _react2.default.createElement(
            _ContentWrapper2.default,
            { contentWidth: contentWidth, flex: true },
            onBackButtonClick && _react2.default.createElement(
              BackButton,
              { onClick: onBackButtonClick },
              globalStyling.direction === 'rtl' ? _react2.default.createElement(_chevronRight2.default, { color: colourValue(subNavChevronColour) }) : _react2.default.createElement(_chevronLeft2.default, { color: colourValue(subNavChevronColour) })
            ),
            _react2.default.createElement(
              DesktopLinksContainer,
              { align: alignMenuItems },
              links.map(function (link, i) {
                return _react2.default.createElement(
                  SubNavLink,
                  {
                    linkHeight: subNavHeight,
                    theme: subNavFontTheme,
                    subNavBorderColour: colourValue(subNavBorderColour),
                    key: i.toString(),
                    onClick: link.onClick,
                    isExploreRange: isExploreRange,
                    showNavBarItemBorder: showNavBarItemBorder,
                    exploreRangeBackgroundColor: exploreRangeBackgroundColor,
                    exploreRangeForegroundColour: colourValue(exploreRangeForegroundColour),
                    selectedBackgroundColour: colourValue(subNavBarItemSelectedBackgroundColour),
                    selectedFontColour: colourValue(subNavBarItemSelectedFontColour),
                    selected: selected === link
                  },
                  '\u200E',
                  link.text,
                  '\u200E'
                );
              })
            ),
            _react2.default.createElement(
              PhoneTitleBar,
              null,
              title
            ),
            _react2.default.createElement(
              PhoneOpenCloseButton,
              { onClick: function onClick() {
                  return _this2.toggle();
                } },
              this.state.open ? _react2.default.createElement(_chevronUp2.default, null) : _react2.default.createElement(_chevronDown2.default, null)
            )
          )
        ),
        this.state.open && _react2.default.createElement(
          PhoneLinks,
          null,
          links.filter(function (link) {
            return link.availableOnMobile;
          }).map(function (link, i) {
            return _react2.default.createElement(
              PhoneLink,
              {
                subNavBGColour: colourValue(subNavBGColour),
                theme: subNavFontTheme,
                key: i.toString(),
                onClick: link.onClick
              },
              '\u200E',
              link.text,
              '\u200E'
            );
          })
        )
      );
    }
  }]);
  return SubNavBar;
}(_react2.default.Component);

Object.defineProperty(SubNavBar, 'defaultProps', {
  enumerable: true,
  writable: true,
  value: {
    title: '',
    isExploreRange: false,
    subNavBGColour: {
      value: '#474747',
      label: 'grey'
    },
    subNavBorderColour: {
      value: '#7e7e7e',
      label: 'light grey'
    },
    subNavChevronColour: {
      value: '#FFF',
      label: 'white'
    },
    subNavFontTheme: {
      characterSpacing: '0',
      colour: '#fff',
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: '14px',
      textTransform: 'uppercase'
    },
    showNavBarItemBorder: true,
    subNavHeight: '50px',
    exploreRangeBackgroundColor: '#9e1b32',
    exploreRangeForegroundColour: '#ffffff',
    alignMenuItems: 'center'
  }
});
exports.default = SubNavBar;