'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _modelTypes = require('../../../types/Bentley/modelTypes');

var _Form = require('../../Bentley/Form');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Card = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__Card',
  componentId: 'sc-vpkbbt-0'
})(['display:flex;align-items:center;flex-direction:column;padding-top:30px;color:#ffffff;min-width:300px;max-width:300px;cursor:ew-resize;background-size:cover;background-image:url(', ');'], function (_ref) {
  var backgroundImage = _ref.backgroundImage;
  return backgroundImage;
});

var CardTitle = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__CardTitle',
  componentId: 'sc-vpkbbt-1'
})(['display:flex;text-align:center;font-family:Bentley-Regular;font-size:24px;line-height:28px;text-transform:uppercase;margin:0 20px;justify-content:center;']);

var CardDescription = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__CardDescription',
  componentId: 'sc-vpkbbt-2'
})(['font-family:Bentley-Light;font-size:16px;line-height:18px;margin:15px 60px;text-align:center;']);

var SelectedButton = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__SelectedButton',
  componentId: 'sc-vpkbbt-3'
})(['bottom:0;display:flex;font-size:14px;text-transform:uppercase;margin-top:auto;background-color:#335b4c;width:100%;justify-content:center;padding:10px 0;opacity:', ';transition:0.3s all ease;'], function (_ref2) {
  var visible = _ref2.visible;
  return visible ? '1' : '0';
});

var TitleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__TitleContainer',
  componentId: 'sc-vpkbbt-4'
})(['flex:0.5;display:flex;flex-direction:column;flex-basis:48px;']);

var BodyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__BodyContainer',
  componentId: 'sc-vpkbbt-5'
})(['display:flex;flex:1;align-items:flex-start;width:100%;height:100%;flex-direction:column;justify-content:space-between;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__ButtonContainer',
  componentId: 'sc-vpkbbt-6'
})(['display:flex;justify-content:center;width:100%;flex:1;']);

var ModelItem = function ModelItem(_ref3) {
  var tile = _ref3.tile,
      translations = _ref3.translations,
      onTileSelected = _ref3.onTileSelected,
      isChecked = _ref3.isChecked;
  var imageUrl = tile.imageUrl,
      title = tile.title,
      subTitle = tile.subTitle,
      translationNameKey = tile.translationNameKey;


  return _react2.default.createElement(
    Card,
    { backgroundImage: imageUrl },
    _react2.default.createElement(
      TitleContainer,
      null,
      _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          CardTitle,
          null,
          translationNameKey && translations[translationNameKey] ? translations[translationNameKey] : title
        ),
        subTitle && _react2.default.createElement(
          CardTitle,
          null,
          translationNameKey && translations[subTitle] ? translations[subTitle] : subTitle
        )
      ),
      _react2.default.createElement(
        CardDescription,
        null,
        translations[tile.description]
      )
    ),
    _react2.default.createElement(
      BodyContainer,
      null,
      _react2.default.createElement(
        ButtonContainer,
        null,
        _react2.default.createElement(_Form.Button, {
          variant: 'secondary',
          text: isChecked ? translations.removeModelFromSearch : translations.addModelToSearch,
          onClick: function onClick() {
            return onTileSelected(tile.tileId);
          }
        })
      ),
      _react2.default.createElement(
        SelectedButton,
        { visible: isChecked },
        translations.addedModelToSearch
      )
    )
  );
};

exports.default = _react2.default.memo(ModelItem);