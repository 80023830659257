'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 90%;\n    padding: 3%;\n    margin: 3% 0;\n  '], ['\n    width: 90%;\n    padding: 3%;\n    margin: 3% 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SearchInput = require('../../components/SearchInput');

var _SearchInput2 = _interopRequireDefault(_SearchInput);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../components/Global');

var _router = require('../../actions/router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var InputContainer = _styledComponents2.default.div.withConfig({
  displayName: 'InputContainer'
}).withConfig({
  displayName: 'SiteSearch__InputContainer',
  componentId: 'sc-psui6p-0'
})(['height:100px;background-color:#f0f0f0;line-height:19px;display:flex;align-items:center;justify-content:center;border-top:1px solid #d8d8d8;']);

var ResultsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ResultsContainer'
}).withConfig({
  displayName: 'SiteSearch__ResultsContainer',
  componentId: 'sc-psui6p-1'
})(['display:flex;flex-direction:column;align-items:center;padding:5%;']);

var ResultItem = _styledComponents2.default.div.withConfig({
  displayName: 'ResultItem'
}).withConfig({
  displayName: 'SiteSearch__ResultItem',
  componentId: 'sc-psui6p-2'
})(['width:75%;display:flex;flex-direction:column;justify-content:space-around;padding-left:3%;margin:1% 0;border:1px solid #d6d6d6;background-color:#ffffff;padding:16px 24px;', ';'], _theme2.default.max.large(_templateObject));

var Link = _styledComponents2.default.a.withConfig({
  displayName: 'Link'
}).withConfig({
  displayName: 'SiteSearch__Link',
  componentId: 'sc-psui6p-3'
})(['color:', ';font-size:16px;line-height:22px;padding:1% 0;'], function (props) {
  return props.colour ? '' + props.colour : 'unset';
});

var InfoContainer = _styledComponents2.default.span.withConfig({
  displayName: 'InfoContainer'
}).withConfig({
  displayName: 'SiteSearch__InfoContainer',
  componentId: 'sc-psui6p-4'
})(['display:flex;align-self:flex-start;padding-left:12%;', ';'], _theme2.default.max.large(_templateObject2));

var NoResultsContainer = _styledComponents2.default.span.withConfig({
  displayName: 'NoResultsContainer'
}).withConfig({
  displayName: 'SiteSearch__NoResultsContainer',
  componentId: 'sc-psui6p-5'
})(['display:flex;flex-direction:column;']);

var Hints = _styledComponents2.default.span.withConfig({
  displayName: 'Hints'
}).withConfig({
  displayName: 'SiteSearch__Hints',
  componentId: 'sc-psui6p-6'
})(['color:#757575;font-size:16px;line-height:22px;']);

var Info = function Info(_ref) {
  var resultsCount = _ref.resultsCount,
      hints = _ref.hints,
      noResultMessage = _ref.noResultMessage,
      resultsMessage = _ref.resultsMessage;
  return _react2.default.createElement(
    InfoContainer,
    null,
    resultsCount ? _react2.default.createElement(
      _Global.HeadingThree,
      {
        styleOverride: function styleOverride() {
          return '\n          font-size: 16px;\n        ';
        }
      },
      resultsCount + ' ' + resultsMessage
    ) : _react2.default.createElement(
      NoResultsContainer,
      null,
      _react2.default.createElement(
        _Global.Paragraph,
        null,
        noResultMessage
      ),
      _react2.default.createElement(
        Hints,
        null,
        hints
      )
    )
  );
};

var Result = function Result(_ref2) {
  var title = _ref2.title,
      content = _ref2.content,
      link = _ref2.link,
      colour = _ref2.colour;
  return _react2.default.createElement(
    ResultItem,
    null,
    _react2.default.createElement(
      _Global.HeadingTwo,
      null,
      title
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      null,
      content
    ),
    _react2.default.createElement(
      Link,
      { colour: colour, href: link },
      link
    )
  );
};

var getInitialQueryTerm = function getInitialQueryTerm(_ref3) {
  var _ref3$history$locatio = _ref3.history.location;
  _ref3$history$locatio = _ref3$history$locatio === undefined ? {} : _ref3$history$locatio;
  var search = _ref3$history$locatio.search,
      queryPattern = _ref3.queryPattern;
  return search && search.startsWith(queryPattern) ? search.replace(queryPattern, '') : undefined;
};

var SiteSearch = function (_React$Component) {
  (0, _inherits3.default)(SiteSearch, _React$Component);

  function SiteSearch() {
    var _ref4;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, SiteSearch);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref4 = SiteSearch.__proto__ || (0, _getPrototypeOf2.default)(SiteSearch)).call.apply(_ref4, [this].concat(args))), _this), Object.defineProperty(_this, 'state', {
      enumerable: true,
      writable: true,
      value: {
        term: getInitialQueryTerm({
          history: _this.props.history,
          queryPattern: '?query='
        })
      }
    }), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var searchFor = _this.props.actions.searchFor;

        if (_this.state.term !== undefined) {
          searchFor(_this.state.term);
        }
      }
    }), Object.defineProperty(_this, 'handleChange', {
      enumerable: true,
      writable: true,
      value: function value(term) {
        return _this.setState(function () {
          return { term: term };
        });
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(SiteSearch, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          _props$state$searchRe = _props.state.searchResults,
          searchResults = _props$state$searchRe === undefined ? [] : _props$state$searchRe,
          _props$config = _props.config;
      _props$config = _props$config === undefined ? {} : _props$config;
      var translations = _props$config.translations,
          searchFor = _props.actions.searchFor,
          pathByModule = _props.pathByModule,
          dispatch = _props.dispatch,
          globalStyling = _props.globalStyling;

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _Global.HeadingOne,
          {
            styleOverride: function styleOverride() {
              return '\n            text-transform: uppercase;\n            text-align: center;\n            color: #474747;\n            font-size: 24;\n          ';
            }
          },
          translations.header
        ),
        _react2.default.createElement(
          InputContainer,
          null,
          _react2.default.createElement(_SearchInput2.default, {
            placeholder: '',
            value: this.state.term,
            onSearch: function onSearch(term) {
              dispatch(_router.actions.replace(pathByModule('SiteSearch') + '?query=' + (term || '')));
              searchFor(term);
            },
            onChange: this.handleChange,
            globalStyling: globalStyling
          })
        ),
        _react2.default.createElement(
          ResultsContainer,
          null,
          _react2.default.createElement(Info, {
            resultsCount: searchResults.length,
            hints: translations.hints,
            noResultMessage: translations.noResultMessage,
            resultsMessage: translations.resultsMessage
          }),
          searchResults.map(function (_ref5) {
            var title = _ref5.title,
                content = _ref5.content,
                link = _ref5.link,
                key = _ref5.key;
            return _react2.default.createElement(Result, {
              key: key,
              title: title,
              content: content,
              link: link,
              colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
            });
          })
        )
      );
    }
  }]);
  return SiteSearch;
}(_react2.default.Component);

exports.default = SiteSearch;