'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImageTileSection = exports.IconWithLabel = exports.RetailerSection = exports.location = exports.BespokeFeatureSection = exports.ExteriorSection = exports.VehicleSubTitle = exports.VehicleTitle = exports.titleHeaderText = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n  '], ['\n    ', ';\n  ']);

exports.default = SearchResultGridBlock;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _helpers = require('../shared/currencyConversion/helpers');

var _distance = require('../shared/localisation/distance');

var _numbers = require('../shared/localisation/numbers');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('./Global');

var _Compare = require('./JaguarIcons/Global/Compare');

var _Compare2 = _interopRequireDefault(_Compare);

var _Heart = require('./JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

var _CarfaxLogo = require('./RollsRoyce/CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

var _StyledIcon = require('./StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _Tooltip = require('./Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__Container',
  componentId: 'sc-1ak01by-0'
})(['display:flex;', ';width:100%;display:flex;border:1px solid #d8d8d8;box-sizing:border-box;flex-direction:row;justify-content:space-between;position:relative;'], function (_ref) {
  var backgroundColor = _ref.backgroundColor;
  return '' + (backgroundColor && 'background-color: ' + backgroundColor);
});

var ImageSection = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__ImageSection',
  componentId: 'sc-1ak01by-1'
})(['', ';display:flex;flex-direction:column;justify-content:center;min-height:10px;position:relative;&:hover{cursor:pointer;}'], function (_ref2) {
  var width = _ref2.width;
  return '' + (width && 'width: ' + width + '%');
});

var VehicleInfoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__VehicleInfoContainer',
  componentId: 'sc-1ak01by-2'
})(['display:flex;flex-direction:column;', ';'], function (_ref3) {
  var width = _ref3.width;
  return '' + (width && 'width: ' + width + '%');
});

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultGridBlock__Image',
  componentId: 'sc-1ak01by-3'
})(['width:100%;height:auto;']);

var InfoSection = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__InfoSection',
  componentId: 'sc-1ak01by-4'
})(['display:flex;width:100%;flex-direction:row;justify-content:space-between;']);

var TitleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__TitleContainer',
  componentId: 'sc-1ak01by-5'
})(['display:flex;flex-direction:column;padding:8px 0px;flex:2;']);

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__ImageOverlay',
  componentId: 'sc-1ak01by-6'
})(['justify-content:center;align-items:center;display:flex;flex-direction:column;position:absolute;top:0;left:0;right:0;bottom:0;color:#ffffff;background-color:rgb(0,0,0,0.5);cursor:default;']);

var Views = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__Views',
  componentId: 'sc-1ak01by-7'
})(['padding:0px 2px;', ';', ';', ';', ';'], function (_ref4) {
  var font = _ref4.font;
  return font && font.fontSize && 'font-size: ' + font.fontSize;
}, function (_ref5) {
  var font = _ref5.font;
  return font && font.colour && 'color: ' + font.colour;
}, function (_ref6) {
  var font = _ref6.font;
  return font && font.fontFamily && 'font-family: ' + font.fontFamily;
}, function (_ref7) {
  var font = _ref7.font;
  return font && font.kerning && 'letter-spacing: ' + font.kerning + 'px';
});

var SoldHeading = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__SoldHeading',
  componentId: 'sc-1ak01by-8'
})(['font-size:20px;font-weight:600;']);

var SoldBody = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__SoldBody',
  componentId: 'sc-1ak01by-9'
})(['font-size:14px;max-width:165px;']);

var ActionsRow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__ActionsRow',
  componentId: 'sc-1ak01by-10'
})(['display:flex;flex:1 0 0%;flex-direction:row;width:100%;justify-content:space-between;@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){min-height:200px;}display:flex;flex-direction:column;height:100%;padding-left:16px;box-sizing:border-box;justify-content:space-around;width:70%;padding-bottom:8px;']);

var RetailerSectionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__RetailerSectionContainer',
  componentId: 'sc-1ak01by-11'
})(['display:flex;align-items:center;']);

var DistanceSection = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__DistanceSection',
  componentId: 'sc-1ak01by-12'
})(['display:flex;flex-direction:column;padding-left:10px;']);

var NameAndDistanceSection = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__NameAndDistanceSection',
  componentId: 'sc-1ak01by-13'
})(['display:flex;']);

var RetailerSectionLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__RetailerSectionLabel',
  componentId: 'sc-1ak01by-14'
})(['font-size:14px;padding-right:8px;', ';', ';', ';', ';'], function (_ref8) {
  var font = _ref8.font;
  return font && font.fontSize && 'font-size: ' + font.fontSize;
}, function (_ref9) {
  var font = _ref9.font;
  return font && font.colour && 'color: ' + font.colour;
}, function (_ref10) {
  var font = _ref10.font;
  return font && font.fontFamily && 'font-family: ' + font.fontFamily;
}, function (_ref11) {
  var font = _ref11.font;
  return font && font.kerning && 'letter-spacing: ' + font.kerning + 'px';
});

var Badge = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__Badge',
  componentId: 'sc-1ak01by-15'
})(['', ';'], function (_ref12) {
  var badgeBackgroundColour = _ref12.badgeBackgroundColour;
  return badgeBackgroundColour && 'background-color: ' + badgeBackgroundColour + ';\n     color:#FFFFFF;\n     ';
});

var SectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__SectionWrapper',
  componentId: 'sc-1ak01by-16'
})(['padding-top:8px;padding-bottom:8px;border-top:1px solid #dedede;']);

var CollectionsLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__CollectionsLabel',
  componentId: 'sc-1ak01by-17'
})(['display:flex;padding-top:8px;padding-bottom:8px;line-height:16px;text-transform:uppercase;letter-spacing:0.57px;color:#281432;font-size:12px;font-family:RivieraNights-Medium,Jost-Medium;']);

var useCustomTitle = function useCustomTitle(visible) {
  return visible && (visible.showModelName || visible.showYear || visible.showEngine || visible.showBodystyle || visible.showTransmission || visible.showFuel || visible.showHandDrive || visible.showOdometer || visible.showExteriorColor);
};

var generateTitle = function generateTitle(vehicle, visible) {
  return '\n  ' + (visible.showYear && vehicle.registration ? '' + vehicle.registration : '') + '\n  ' + (visible.showModelName && vehicle.modelGroupName ? '' + vehicle.modelGroupName : '') + '\n  ' + (visible.showEngine && vehicle.engine ? '' + vehicle.engine : '') + '\n  ' + (visible.showBodystyle && vehicle.bodystyle ? '' + vehicle.bodystyle : '') + '\n  ' + (visible.showOdometer && vehicle.odometer && vehicle.odometer.display ? '' + vehicle.odometer.display : '') + '\n  ' + (visible.showExteriorColor && vehicle.exterior ? '' + vehicle.exterior : '') + '\n  ' + (visible.showTransmission && vehicle.transmission ? '' + vehicle.transmission : '') + '\n  ' + (visible.showFuel && vehicle.fuel ? '' + vehicle.fuel : '') + '\n  ' + (visible.showHandDrive && vehicle.handDrive ? '' + vehicle.handDrive : '') + '\n';
};

var generateSubTitle = function generateSubTitle(_ref13, locale, translations, badge) {
  var modelYear = _ref13.modelYear,
      modelVariant = _ref13.modelVariant,
      bodystyle = _ref13.bodystyle,
      fuel = _ref13.fuel,
      odometer = _ref13.odometer;
  return [modelYear].concat((0, _toConsumableArray3.default)(badge ? [] : [modelVariant]), [bodystyle, fuel, odometer ? (0, _numbers.localiseNumber)(odometer.reading, locale) + ' ' + odometer.units : translations.zeroMileageText]).filter(Boolean).join(' | ');
};

var CustomTitle = function CustomTitle(vehicle, visible, translations) {
  return generateTitle(vehicle, visible, translations);
};

var DefaultTitle = function DefaultTitle(vehicle) {
  return vehicle.registration + ' ' + vehicle.description;
};

var DefaultSubtitle = function DefaultSubtitle(vehicle) {
  return vehicle.colourWithTrim;
};

var defaultOrCustomText = function defaultOrCustomText(vehicle, visible) {
  return useCustomTitle(visible && visible.showTitle) ? CustomTitle(vehicle, visible && visible.showTitle) : DefaultTitle(vehicle);
};

var titleHeaderText = exports.titleHeaderText = function titleHeaderText(vehicle, visible, titleFont, displayModelAndVariant, badge) {
  switch (badge) {
    case false:
      return displayModelAndVariant ? '' + vehicle.modelGroupName : defaultOrCustomText(vehicle, visible, titleFont);
    case true:
      return vehicle.modelVariant;

    default:
      return '' + vehicle.modelGroupName;
  }
};

var subTitleHeaderText = function subTitleHeaderText(vehicle, visible, locale, translations) {
  var badge = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  return useCustomTitle(visible && visible.showSubtitle) ? generateSubTitle(vehicle, locale, translations, badge) : DefaultSubtitle(vehicle);
};

var VehicleTitle = exports.VehicleTitle = function VehicleTitle(_ref14) {
  var vehicle = _ref14.vehicle,
      titleFont = _ref14.titleFont,
      visible = _ref14.visible,
      badgeStyling = _ref14.badgeStyling,
      badge = _ref14.badge,
      displayModelAndVariant = _ref14.displayModelAndVariant;
  return _react2.default.createElement(
    _Global.HeadingTwo,
    {
      styleOverride: function styleOverride() {
        return badge ? (0, _Global.fontStyleOverride)(badgeStyling.titleFont) : (0, _Global.fontStyleOverride)(titleFont);
      }
    },
    titleHeaderText(vehicle, visible, titleFont, displayModelAndVariant, badge)
  );
};

var VehicleSubTitle = exports.VehicleSubTitle = function VehicleSubTitle(_ref15) {
  var vehicle = _ref15.vehicle,
      subtitleFont = _ref15.subtitleFont,
      _ref15$visible = _ref15.visible,
      visible = _ref15$visible === undefined ? { showSubtitle: true } : _ref15$visible,
      badgeStyling = _ref15.badgeStyling,
      badge = _ref15.badge,
      locale = _ref15.locale,
      translations = _ref15.translations;
  return _react2.default.createElement(
    _Global.HeadingThree,
    {
      styleOverride: function styleOverride() {
        return badge ? (0, _Global.fontStyleOverride)(badgeStyling.subtitleFont) : (0, _Global.fontStyleOverride)(subtitleFont);
      }
    },
    subTitleHeaderText(vehicle, visible, locale, translations, badge)
  );
};

var ExteriorSection = exports.ExteriorSection = function ExteriorSection(_ref16) {
  var vehicle = _ref16.vehicle,
      translations = _ref16.translations,
      icon = _ref16.icon,
      config = _ref16.config;
  return _react2.default.createElement(
    DesignHighlightsDataContainer,
    null,
    icon && _react2.default.createElement(
      DesignHighlightsDataColumn,
      null,
      _react2.default.createElement(
        IconWrapper,
        null,
        _react2.default.createElement(_StyledIcon2.default, { link: icon })
      )
    ),
    _react2.default.createElement(
      DesignHighlightsDataColumn,
      null,
      vehicle.exterior && _react2.default.createElement(
        DesignHighlightsDataRow,
        null,
        _react2.default.createElement(
          DesignHighlightsLabel,
          {
            font: config && config.designHighlightsLabelFont
          },
          translations.exterior
        ),
        _react2.default.createElement(
          DesignHighlightsValue,
          null,
          vehicle.exterior
        )
      ),
      vehicle.interior && _react2.default.createElement(
        DesignHighlightsDataRow,
        null,
        _react2.default.createElement(
          DesignHighlightsLabel,
          {
            font: config && config.designHighlightsLabelFont
          },
          translations.interior
        ),
        _react2.default.createElement(
          DesignHighlightsValue,
          null,
          vehicle.interior
        )
      ),
      vehicle.veneer && _react2.default.createElement(
        DesignHighlightsDataRow,
        null,
        _react2.default.createElement(
          DesignHighlightsLabel,
          {
            font: config && config.designHighlightsLabelFont
          },
          translations.veneer
        ),
        _react2.default.createElement(
          DesignHighlightsValue,
          null,
          vehicle.veneer
        )
      )
    )
  );
};

var BespokeFeatureSection = exports.BespokeFeatureSection = function BespokeFeatureSection(_ref17) {
  var vehicle = _ref17.vehicle,
      icon = _ref17.icon,
      config = _ref17.config;
  return _react2.default.createElement(
    DesignHighlightsDataContainer,
    null,
    icon && _react2.default.createElement(
      DesignHighlightsDataColumn,
      null,
      _react2.default.createElement(
        IconWrapper,
        null,
        _react2.default.createElement(_StyledIcon2.default, { link: icon })
      )
    ),
    _react2.default.createElement(
      DesignHighlightsDataColumn,
      null,
      vehicle.bespokeFeatures && vehicle.bespokeFeatures.slice(0, 3).map(function (feature, index) {
        return _react2.default.createElement(
          DesignHighlightsDataRow,
          {
            key: 'feature-' + feature.name + '-' + index.toString()
          },
          _react2.default.createElement(
            DesignHighlightsLabel,
            { font: config && config.designHighlightsFont },
            feature.name
          )
        );
      })
    )
  );
};

var location = exports.location = function location(retailerName, label) {
  return [retailerName && '' + retailerName, label && '' + label].filter(Boolean).join(' - ');
};

var RetailerSection = exports.RetailerSection = function RetailerSection(_ref18) {
  var vehicle = _ref18.vehicle,
      icon = _ref18.icon,
      translations = _ref18.translations,
      config = _ref18.config,
      country = _ref18.country;
  return _react2.default.createElement(
    RetailerSectionContainer,
    null,
    _react2.default.createElement(_StyledIcon2.default, { link: icon }),
    _react2.default.createElement(
      DistanceSection,
      null,
      _react2.default.createElement(
        NameAndDistanceSection,
        null,
        _react2.default.createElement(
          RetailerSectionLabel,
          { font: config && config.retailerLabelFont },
          location(vehicle.retailerName, (0, _distance.distanceLabel)(vehicle.distance, translations, country))
        )
      )
    )
  );
};

var IconWithLabel = exports.IconWithLabel = function IconWithLabel(_ref19) {
  var text = _ref19.text,
      children = _ref19.children,
      onClickFn = _ref19.onClickFn,
      qaHook = _ref19.qaHook,
      styleOverride = _ref19.styleOverride,
      anchorTextWidth = _ref19.anchorTextWidth,
      hideOnMobile = _ref19.hideOnMobile;
  return _react2.default.createElement(
    IconWrapper,
    {
      styleOverride: styleOverride,
      'data-cy': qaHook,
      onClick: onClickFn,
      hideOnMobile: hideOnMobile
    },
    children,
    text && _react2.default.createElement(
      AnchorText,
      { width: anchorTextWidth },
      text
    )
  );
};

var DesignHighlightsDataContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__DesignHighlightsDataContainer',
  componentId: 'sc-1ak01by-18'
})(['line-height:16px;font-size:14px;color:#151515;display:flex;flex-direction:row;']);
var DesignHighlightsDataColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__DesignHighlightsDataColumn',
  componentId: 'sc-1ak01by-19'
})(['', ';display:flex;flex-direction:column;'], function (_ref20) {
  var padding = _ref20.padding;
  return padding && 'padding-left: 10px;';
});
var DesignHighlightsDataRow = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__DesignHighlightsDataRow',
  componentId: 'sc-1ak01by-20'
})(['display:flex;flex-direction:row;font-size:14px;']);
var DesignHighlightsLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__DesignHighlightsLabel',
  componentId: 'sc-1ak01by-21'
})(['line-height:20px;', ';', ';', ';', ';'], function (_ref21) {
  var font = _ref21.font;
  return font && font.fontSize && 'font-size: ' + font.fontSize;
}, function (_ref22) {
  var font = _ref22.font;
  return font && font.colour && 'color: ' + font.colour;
}, function (_ref23) {
  var font = _ref23.font;
  return font && font.fontFamily && 'font-family: ' + font.fontFamily;
}, function (_ref24) {
  var font = _ref24.font;
  return font && font.kerning && 'letter-spacing: ' + font.kerning + 'px';
});
var DesignHighlightsValue = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__DesignHighlightsValue',
  componentId: 'sc-1ak01by-22'
})(['font-size:14px;color:#333333;padding-left:5px;line-height:20px;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__IconWrapper',
  componentId: 'sc-1ak01by-23'
})(['display:flex;color:#6d30a7;font-size:16px;letter-spacing:1px;width:100%;height:100%;display:flex;justify-content:space-around;align-items:center;', ';', ';'], function (_ref25) {
  var styleOverride = _ref25.styleOverride;
  return styleOverride && styleOverride();
}, _theme2.default.max.medium(_templateObject, function (_ref26) {
  var hideOnMobile = _ref26.hideOnMobile;
  return '' + (hideOnMobile && 'display: none');
}));

var VehiclePriceSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__VehiclePriceSectionWrapper',
  componentId: 'sc-1ak01by-24'
})(['display:flex;flex-direction:column;justify-content:flex-start;padding-top:8px;gap:2px;padding-right:16px;flex:1;']);

var AnchorText = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__AnchorText',
  componentId: 'sc-1ak01by-25'
})(['', ';display:flex;align-items:center;'], function (_ref27) {
  var width = _ref27.width;
  return '' + (width && 'width: ' + width);
});
var PriceWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__PriceWrapper',
  componentId: 'sc-1ak01by-26'
})(['text-align:left;', ';', ';', ';', ';'], function (_ref28) {
  var font = _ref28.font;
  return font && font.fontSize && 'font-size: ' + font.fontSize + 'px';
}, function (_ref29) {
  var font = _ref29.font;
  return font && font.colour && 'color: ' + font.colour.value;
}, function (_ref30) {
  var font = _ref30.font;
  return font && font.typeface && 'font-family: ' + font.typeface.value;
}, function (_ref31) {
  var font = _ref31.font;
  return font && font.kerning && 'letter-spacing: ' + font.kerning + 'px';
});

var VdpButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__VdpButtonWrapper',
  componentId: 'sc-1ak01by-27'
})(['display:flex;justify-content:flex-end;']);

var ImageTileSection = exports.ImageTileSection = function ImageTileSection(_ref32) {
  var vehicle = _ref32.vehicle,
      translations = _ref32.translations,
      onMoreInfoClick = _ref32.onMoreInfoClick,
      imageSrc = _ref32.imageSrc,
      width = _ref32.width,
      icon360 = _ref32.icon360,
      iconYoutubeVideo = _ref32.iconYoutubeVideo,
      shortlistVehicle = _ref32.shortlistVehicle,
      compareVehicle = _ref32.compareVehicle,
      compareIconColour = _ref32.compareIconColour,
      shortListedIconColour = _ref32.shortListedIconColour,
      config = _ref32.config;
  return _react2.default.createElement(
    ImageSection,
    { width: width },
    _react2.default.createElement(Image, {
      src: imageSrc,
      onClick: function onClick() {
        return vehicle && vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
      }
    }),
    vehicle && vehicle.status === 'sold' && _react2.default.createElement(
      ImageOverlay,
      null,
      _react2.default.createElement(
        SoldHeading,
        null,
        translations.sold
      ),
      _react2.default.createElement(
        SoldBody,
        null,
        translations.similarVehiclesAvailable
      )
    ),
    shortlistVehicle && compareVehicle && compareIconColour && shortListedIconColour && _react2.default.createElement(
      ActionBarContainer,
      null,
      _react2.default.createElement(
        ActionBarIconBar,
        null,
        _react2.default.createElement(
          ActionBarIconContainer,
          { width: '62px' },
          _react2.default.createElement(
            IconWithLabel,
            {
              styleOverride: function styleOverride() {
                return 'cursor: pointer;';
              },
              qaHook: 'camera-icon-button',
              text: translations.searchResultsSaveVehicle,
              onClickFn: function onClickFn() {
                return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
              }
            },
            _react2.default.createElement(ActionBarIcon, { src: 'https://res.cloudinary.com/motortrak/image/upload/v1639670573/locator/rolls-royce/global/icons/srp-photos.svg' }),
            _react2.default.createElement(
              Views,
              { font: config && config.viewLabelFont },
              vehicle && vehicle.images && vehicle.images.length
            )
          )
        ),
        vehicle.panoramicViews && (vehicle.panoramicViews.exterior || vehicle.panoramicViews.interior) && _react2.default.createElement(
          ActionBarIconContainer,
          null,
          _react2.default.createElement(
            IconWithLabel,
            {
              styleOverride: function styleOverride() {
                return 'cursor: pointer;';
              },
              qaHook: '360-icon',
              text: translations.searchResultsSaveVehicle,
              onClickFn: function onClickFn() {
                return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
              }
            },
            _react2.default.createElement(ActionBarIcon, { src: icon360 })
          )
        ),
        vehicle.video && _react2.default.createElement(
          ActionBarIconContainer,
          null,
          _react2.default.createElement(
            IconWithLabel,
            {
              styleOverride: function styleOverride() {
                return 'cursor: pointer;';
              },
              qaHook: 'video-icon-button',
              text: translations.searchResultsSaveVehicle,
              onClickFn: function onClickFn() {
                return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
              }
            },
            _react2.default.createElement(ActionBarIcon, { src: iconYoutubeVideo })
          )
        ),
        _react2.default.createElement(
          ActionBarIconContainer,
          { hideOnMobile: true },
          _react2.default.createElement(
            IconWithLabel,
            {
              styleOverride: function styleOverride() {
                return 'cursor: pointer;';
              },
              qaHook: 'compare-vehicle-button',
              text: translations.searchResultsAddToCompare,
              onClickFn: function onClickFn() {
                return compareVehicle(vehicle);
              },
              hideOnMobile: true
            },
            _react2.default.createElement(_Compare2.default, {
              width: '30px',
              height: '30px',
              colour: compareIconColour
            })
          )
        ),
        _react2.default.createElement(
          ActionBarIconContainer,
          null,
          _react2.default.createElement(
            IconWithLabel,
            {
              styleOverride: function styleOverride() {
                return 'cursor: pointer;';
              },
              qaHook: 'shortlist-vehicle-button',
              text: translations.searchResultsSaveVehicle,
              onClickFn: function onClickFn() {
                return shortlistVehicle(vehicle);
              }
            },
            _react2.default.createElement(_Heart2.default, {
              width: '30px',
              height: '30px',
              colour: shortListedIconColour
            })
          )
        )
      )
    )
  );
};

var ActionBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__ActionBarContainer',
  componentId: 'sc-1ak01by-28'
})(['width:100%;height:45px;background:#ecf1f5;box-sizing:border-box;border:1px solid #d8d8d8;display:flex;border-top:none;justify-content:space-between;margin-bottom:14px;']);
var ActionBarIconBar = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__ActionBarIconBar',
  componentId: 'sc-1ak01by-29'
})(['display:flex;justify-content:space-between;align-items:center;']);
var ActionBarIcon = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultGridBlock__ActionBarIcon',
  componentId: 'sc-1ak01by-30'
})(['width:24px;height:24px;box-sizing:border-box;']);
var ActionBarIconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__ActionBarIconContainer',
  componentId: 'sc-1ak01by-31'
})(['width:', ';height:100%;border-right:1px solid #d8d8d8;box-sizing:border-box;', ';'], function (_ref33) {
  var width = _ref33.width;
  return width || '50px';
}, _theme2.default.max.medium(_templateObject, function (_ref34) {
  var hideOnMobile = _ref34.hideOnMobile;
  return '' + (hideOnMobile && 'display: none');
}));
var DetailsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__DetailsContainer',
  componentId: 'sc-1ak01by-32'
})(['display:flex;flex-direction:row;gap:8px;']);

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'SearchResultGridBlock__Logo',
  componentId: 'sc-1ak01by-33'
})(['height:38px;margin:10px;']);

var BespokeFeatureLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__BespokeFeatureLabel',
  componentId: 'sc-1ak01by-34'
})(['', ';padding:4px 0;'], function (_ref35) {
  var font = _ref35.font;
  return font && (0, _Global.fontStyleOverride)(font);
});

var PriceColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__PriceColumn',
  componentId: 'sc-1ak01by-35'
})(['display:flex;flex-direction:column;justify-content:space-between;']);

var PriceWithTooltip = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultGridBlock__PriceWithTooltip',
  componentId: 'sc-1ak01by-36'
})(['display:flex;gap:10px;align-items:center;']);

function SearchResultGridBlock(_ref36) {
  var config = _ref36.config,
      vehicle = _ref36.vehicle,
      placeholdingImage = _ref36.placeholdingImage,
      filtersExpanded = _ref36.filtersExpanded,
      onMoreInfoClick = _ref36.onMoreInfoClick,
      translations = _ref36.translations,
      compareVehicle = _ref36.compareVehicle,
      shortlistVehicle = _ref36.shortlistVehicle,
      shortListedIconColour = _ref36.shortListedIconColour,
      compareIconColour = _ref36.compareIconColour,
      globalStyling = _ref36.globalStyling,
      visibility = _ref36.visibility,
      vehicleModelTitleFontWeight = _ref36.vehicleModelTitleFontWeight,
      headerItemFont = _ref36.headerItemFont,
      locale = _ref36.locale,
      badgeStyling = _ref36.badgeStyling,
      listViewImageWidth = _ref36.listViewImageWidth,
      handleZeroPriceAsText = _ref36.handleZeroPriceAsText,
      showNumberOfImagesInsteadOfViews = _ref36.showNumberOfImagesInsteadOfViews,
      imageWidth = _ref36.imageWidth,
      icon360 = _ref36.icon360,
      iconYoutubeVideo = _ref36.iconYoutubeVideo,
      onEnquiryClick = _ref36.onEnquiryClick;

  var country = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences.location && state.shared.sessionPreferences.location.userCountry;
  });

  var imageSrc = vehicle && vehicle.images && vehicle.images.length > 0 ? vehicle.images[0] : placeholdingImage;
  var badge = badgeStyling && badgeStyling.badgeVariant && vehicle.modelVariant && vehicle.modelVariant.toLowerCase().includes(badgeStyling.badgeVariant.toLowerCase());

  var showModelAndVariant = badgeStyling && badgeStyling.displayModelAndVariant && vehicle.modelVariant !== null;

  var showSalesTaxDisclaimer = config.showSectionSalesTaxDisclaimer && !!vehicle.sectionSalesTaxDisclaimer;

  var handlePrice = (0, _helpers.handleVehiclePrice)(!showSalesTaxDisclaimer ? vehicle.price.value : vehicle.netPrice || vehicle.price.value, {
    currencyType: vehicle.price.currency,
    locale: locale.replace('_', '-'),
    handleZeroPriceAsText: handleZeroPriceAsText,
    priceSpecial: vehicle.priceSpecial,
    translationsSold: translations.sold,
    vehiclePrice: vehicle.price.value,
    zeroPriceText: translations.zeroPriceText
  });

  var displayExteriorSection = vehicle.exterior || vehicle.interior || vehicle.veneer;

  var netPrice = vehicle.netPrice ? (0, _helpers.handleVehiclePrice)(vehicle.netPrice, {
    currencyType: vehicle.price.currency,
    locale: locale.replace('_', '-'),
    handleZeroPriceAsText: config.handleZeroPriceAsText,
    priceSpecial: vehicle.priceSpecial,
    zeroPriceText: translations.zeroPriceText,
    translationsSold: translations.sold,
    vehiclePrice: vehicle.price.value
  }) : undefined;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      Container,
      {
        'data-cy': 'vehicle-search-result-block',
        key: vehicle.id,
        filtersExpanded: filtersExpanded,
        backgroundColor: 'white'
      },
      _react2.default.createElement(ImageTileSection, {
        vehicle: vehicle,
        imageSrc: imageSrc,
        translations: translations,
        onMoreInfoClick: onMoreInfoClick,
        listViewImageWidth: listViewImageWidth,
        width: imageWidth,
        showNumberOfImages: showNumberOfImagesInsteadOfViews,
        icon: config.imagesIcon,
        globalStyling: globalStyling,
        icon360: icon360,
        iconYoutubeVideo: iconYoutubeVideo,
        config: config
      }),
      _react2.default.createElement(
        VehicleInfoContainer,
        { width: 100 - imageWidth },
        _react2.default.createElement(
          DetailsContainer,
          null,
          _react2.default.createElement(
            ActionsRow,
            null,
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                Badge,
                {
                  badgeBackgroundColour: badge && badgeStyling && badgeStyling.badgeBackgroundColour
                },
                _react2.default.createElement(
                  InfoSection,
                  null,
                  _react2.default.createElement(
                    TitleContainer,
                    null,
                    _react2.default.createElement(VehicleTitle, {
                      vehicle: vehicle,
                      fontWeight: vehicleModelTitleFontWeight,
                      titleFont: headerItemFont && headerItemFont.titleFont,
                      visible: visibility,
                      globalStyling: globalStyling,
                      badge: badge,
                      badgeStyling: badgeStyling,
                      displayModelAndVariant: showModelAndVariant
                    }),
                    vehicle.modelCollectionName && _react2.default.createElement(
                      CollectionsLabel,
                      null,
                      vehicle.modelCollectionName
                    ),
                    _react2.default.createElement(VehicleSubTitle, {
                      vehicle: vehicle,
                      locale: locale,
                      fontWeight: vehicleModelTitleFontWeight,
                      subtitleFont: headerItemFont && headerItemFont.subtitleFont,
                      visible: visibility,
                      globalStyling: globalStyling,
                      badge: badge,
                      badgeStyling: badgeStyling,
                      translations: translations
                    })
                  )
                )
              )
            ),
            displayExteriorSection && _react2.default.createElement(
              SectionWrapper,
              null,
              _react2.default.createElement(ExteriorSection, {
                vehicle: vehicle,
                translations: translations,
                icon: config.coloursIcon,
                config: config
              })
            ),
            vehicle.bespokeFeatures && _react2.default.createElement(
              SectionWrapper,
              null,
              _react2.default.createElement(BespokeFeatureSection, {
                vehicle: vehicle,
                icon: config.designIcon,
                config: config
              })
            ),
            visibility.showDistance && _react2.default.createElement(
              SectionWrapper,
              null,
              _react2.default.createElement(RetailerSection, {
                vehicle: vehicle,
                icon: config.pinIcon,
                translations: translations,
                config: config,
                country: country
              })
            )
          ),
          _react2.default.createElement(
            PriceColumn,
            null,
            _react2.default.createElement(
              VehiclePriceSectionWrapper,
              null,
              _react2.default.createElement(
                PriceWrapper,
                { font: config && config.priceWrapperFont },
                _react2.default.createElement(
                  PriceWithTooltip,
                  null,
                  _react2.default.createElement(
                    _Global.HeadingTwo,
                    {
                      styleOverride: function styleOverride() {
                        return badge ? (0, _Global.fontStyleOverride)(badgeStyling.titleFont) : (0, _Global.fontStyleOverride)(headerItemFont && headerItemFont.titleFont);
                      }
                    },
                    handlePrice
                  ),
                  netPrice && showSalesTaxDisclaimer && _react2.default.createElement(
                    _Tooltip2.default,
                    { text: translations.salesTaxDisclaimer },
                    _react2.default.createElement(
                      IconWrapper,
                      null,
                      _react2.default.createElement(_StyledIcon2.default, { link: config.infoIcon })
                    )
                  )
                ),
                vehicle.bespoke === 1 && _react2.default.createElement(
                  BespokeFeatureLabel,
                  { font: config.bespokeFeatureLabelFont },
                  translations.bespokeFeatureLabel
                )
              ),
              netPrice && !showSalesTaxDisclaimer && _react2.default.createElement(
                _react2.default.Fragment,
                null,
                _react2.default.createElement(
                  PriceWrapper,
                  { font: config && config.priceWrapperFont },
                  (0, _translateFromTemplate.translateFromTemplate)('netPrice', { VALUE: netPrice }, translations)
                ),
                vehicle.retailerCountryCode === 'DE' && _react2.default.createElement(
                  PriceWrapper,
                  { font: config && config.priceWrapperFont },
                  translations.netPriceLabel
                )
              ),
              vehicle.carfax && _react2.default.createElement(
                'div',
                { style: { width: '94px', height: '32px' } },
                _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicle.carfax })
              )
            ),
            _react2.default.createElement(Logo, { src: config.tileLogo })
          )
        )
      )
    ),
    _react2.default.createElement(
      ActionBarContainer,
      null,
      _react2.default.createElement(
        ActionBarIconBar,
        null,
        _react2.default.createElement(
          ActionBarIconContainer,
          { width: '62px' },
          _react2.default.createElement(
            IconWithLabel,
            {
              styleOverride: function styleOverride() {
                return 'cursor: pointer;';
              },
              onClickFn: function onClickFn() {
                return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
              }
            },
            _react2.default.createElement(ActionBarIcon, { src: config.photosIcon }),
            _react2.default.createElement(
              Views,
              { font: config.viewLabelFont },
              vehicle && vehicle.images && vehicle.images.length
            )
          )
        ),
        vehicle.panoramicViews && (vehicle.panoramicViews.exterior || vehicle.panoramicViews.interior) && _react2.default.createElement(
          ActionBarIconContainer,
          null,
          _react2.default.createElement(
            IconWithLabel,
            {
              styleOverride: function styleOverride() {
                return 'cursor: pointer;';
              },
              qaHook: 'shortlist-vehicle-button',
              text: translations.searchResultsSaveVehicle,
              onClickFn: function onClickFn() {
                return shortlistVehicle(vehicle);
              }
            },
            _react2.default.createElement(ActionBarIcon, { src: icon360 })
          )
        ),
        vehicle.video && _react2.default.createElement(
          ActionBarIconContainer,
          null,
          _react2.default.createElement(
            IconWithLabel,
            {
              styleOverride: function styleOverride() {
                return 'cursor: pointer;';
              },
              qaHook: 'video-icon-button',
              text: translations.searchResultsSaveVehicle,
              onClickFn: function onClickFn() {
                return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
              }
            },
            _react2.default.createElement(ActionBarIcon, { src: iconYoutubeVideo })
          )
        ),
        _react2.default.createElement(
          ActionBarIconContainer,
          null,
          _react2.default.createElement(
            IconWithLabel,
            {
              styleOverride: function styleOverride() {
                return 'cursor: pointer;';
              },
              qaHook: 'compare-vehicle-button',
              text: translations.searchResultsAddToCompare,
              onClickFn: function onClickFn() {
                return compareVehicle(vehicle);
              },
              hideOnMobile: true
            },
            _react2.default.createElement(_Compare2.default, { width: '30px', height: '30px', colour: compareIconColour })
          )
        ),
        _react2.default.createElement(
          ActionBarIconContainer,
          null,
          _react2.default.createElement(
            IconWithLabel,
            {
              styleOverride: function styleOverride() {
                return 'cursor: pointer;';
              },
              qaHook: 'shortlist-vehicle-button',
              text: translations.searchResultsSaveVehicle,
              onClickFn: function onClickFn() {
                return shortlistVehicle(vehicle);
              }
            },
            _react2.default.createElement(_Heart2.default, {
              width: '30px',
              height: '30px',
              colour: shortListedIconColour
            })
          )
        )
      ),
      _react2.default.createElement(
        ActionBarIconBar,
        null,
        vehicle.status !== 'sold' && _react2.default.createElement(
          VdpButtonWrapper,
          null,
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'more-info-button',
            onClick: function onClick() {
              return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
            },
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            applyStyle: 'secondary',
            text: translations.searchResultTileMoreInfo,
            styleOverride: function styleOverride() {
              return 'margin-right: 5px; width: 180px';
            }
          })
        ),
        !config.hideEnquiry && _react2.default.createElement(
          VdpButtonWrapper,
          null,
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'enquiry-button',
            onClick: function onClick() {
              return onEnquiryClick();
            },
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            applyStyle: 'primary',
            text: translations.searchResultTileEnquire,
            styleOverride: function styleOverride() {
              return 'margin-right: 5px; width: 180px';
            }
          })
        )
      )
    )
  );
}