'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _router = require('../../../actions/router');

var _CompareVehicles = require('../../../components/Genesis/CompareVehicles');

var _CompareVehicles2 = _interopRequireDefault(_CompareVehicles);

var _compare = require('../../../shared/compare');

var _shortlist = require('../../../shared/shortlist');

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _vehicle = require('../../../helpers/vehicle');

var _AppShared = require('../../../types/AppShared');

var _MarketInfo = require('../../../types/MarketInfo');

var _comparePageTypes = require('../../../types/Genesis/comparePageTypes');

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CompareGrid = function CompareGrid(props) {
  var init = props.actions.init,
      dispatch = props.dispatch,
      shared = props.shared,
      config = props.config,
      marketInfo = props.marketInfo;


  (0, _react.useEffect)(function () {
    // We do this to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder

    var currentLanguage = shared.sessionPreferences.language;
    var selectedLanguageToLocale = function selectedLanguageToLocale(language) {
      return language ? language.replace('_', '-') : '';
    };

    init();
    // load language from session storage and convert into locale to send to api
    dispatch(_compare.actions.getEquipmentList());
    dispatch(_compare.actions.checkVehicleStatus(selectedLanguageToLocale(currentLanguage), config.translations));
    dispatch(_shortlist.actions.getVehicles());
    if (shared.sessionPreferences.currency) {
      dispatch(_compare.actions.updateVehiclePrices({
        currency: shared.sessionPreferences.currency,
        exchangeRates: shared.currencyConversion.exchangeRates
      }));
    }
  }, []);

  (0, _react.useEffect)(function () {
    dispatch(_compare.actions.updateVehiclePrices({
      currency: shared.sessionPreferences.currency,
      exchangeRates: shared.currencyConversion.exchangeRates
    }));
  }, [dispatch, shared.currencyConversion.exchangeRates]);

  (0, _react.useEffect)(function () {
    dispatch(_compare.actions.checkVehicleStatus());
    dispatch(_compare.actions.getEquipmentList());
  }, [dispatch, shared.sessionPreferences.language]);

  var onMoreInfoClick = function onMoreInfoClick(vehicle) {
    var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, shared.sessionPreferences.language);
    dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
  };

  var sharedComparedVehicles = shared.compare.vehicles;
  return _react2.default.createElement(
    _ContentWrapper2.default,
    null,
    _react2.default.createElement(_CompareVehicles2.default, {
      config: config,
      translations: config.translations,
      currentLanguage: marketInfo.locale,
      removeVehicle: function removeVehicle(vehicle) {
        return dispatch(_compare.actions.toggleVehicle(vehicle));
      },
      comparedVehicles: sharedComparedVehicles,
      onMoreInfoClick: onMoreInfoClick
    })
  );
};
exports.default = CompareGrid;