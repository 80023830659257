'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.useWindowSize = useWindowSize;

var _react = require('react');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useWindowSize() {
  var initState = {
    width: undefined,
    height: undefined
  };

  var _useState = (0, _react.useState)(initState),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      windowSize = _useState2[0],
      setWindowSize = _useState2[1];

  (0, _react.useEffect)(function () {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return function () {
      return window.removeEventListener('resize', handleResize);
    };
  }, []);
  return windowSize;
}

exports.default = useWindowSize;