'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _router = require('../../actions/router');

var _compare = require('../../shared/compare');

var _shortlist = require('../../shared/shortlist');

var _templates = require('./templates');

var _templates2 = _interopRequireDefault(_templates);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  var dispatch = (0, _reactRedux.useDispatch)();
  var sharedCompareVehicles = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.compare && state.shared.compare.vehicles;
  }) || [];
  var sharedShortlistVehicles = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.shortlist && state.shared.shortlist.vehicles;
  }) || [];
  var Template = _templates2.default.DefaultTemplate;
  var id = props.vehicle.id;


  var isCompare = sharedCompareVehicles.some(function (v) {
    return id === v.id;
  });
  var isShortlisted = sharedShortlistVehicles.some(function (v) {
    return id === v.id;
  });

  var handleMoreInfoClick = function handleMoreInfoClick() {
    return dispatch(_router.actions.navigate('VDP/' + id));
  };

  var handleCompareVehicleClick = function handleCompareVehicleClick() {
    return dispatch(_compare.actions.toggleVehicle(props.vehicle));
  };

  var handleShortlistVehicleClick = function handleShortlistVehicleClick() {
    return dispatch(_shortlist.actions.toggleVehicle(props.vehicle));
  };

  return _react2.default.createElement(Template, (0, _extends3.default)({}, props, {
    isCompare: isCompare,
    isShortlisted: isShortlisted,
    handleMoreInfoClick: handleMoreInfoClick,
    handleCompareVehicleClick: handleCompareVehicleClick,
    handleShortlistVehicleClick: handleShortlistVehicleClick
  }));
};