'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _styledComponents2.default.div.withConfig({
  displayName: 'TextWrapper',
  componentId: 'sc-p0p6bq-0'
})(['display:flex;flex-direction:column;flex:1 0 auto;padding-top:', 'px;padding-right:', 'px;padding-bottom:', 'px;padding-left:', 'px;', ';list-style:inside;word-break:break-word;'], function (_ref) {
  var padding = _ref.padding;
  return padding && padding.top || 16;
}, function (_ref2) {
  var padding = _ref2.padding;
  return padding && padding.right || 16;
}, function (_ref3) {
  var padding = _ref3.padding;
  return padding && padding.bottom || 16;
}, function (_ref4) {
  var padding = _ref4.padding;
  return padding && padding.left || 16;
}, function (_ref5) {
  var alignment = _ref5.alignment;
  return '' + (alignment && 'text-align: ' + alignment);
});