'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = UtilityBar;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _DropDown = require('./DropDown');

var _DropDown2 = _interopRequireDefault(_DropDown);

var _Global = require('./Global');

var _currencySymbol = require('../shared/localisation/currencySymbol');

var _SocialShareButton = require('../modules/Header/SocialShareButton');

var _SocialShareButton2 = _interopRequireDefault(_SocialShareButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FlexWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UtilityBar__FlexWrapper',
  componentId: 'sc-1niya4n-0'
})(['display:flex;align-items:center;']);

var SocialShareContainer = _styledComponents2.default.div.withConfig({
  displayName: 'UtilityBar__SocialShareContainer',
  componentId: 'sc-1niya4n-1'
})(['display:flex;padding:0 16px;']);

var SocialShare = function SocialShare(_ref) {
  var config = _ref.config,
      socialShare = _ref.socialShare;
  return _react2.default.createElement(
    SocialShareContainer,
    null,
    config && config.socialShareTitle && !config.socialShareTitle.disabled && _react2.default.createElement(
      FlexWrapper,
      null,
      _react2.default.createElement(_SocialShareButton2.default, { config: config, socialShare: socialShare })
    )
  );
};

var UtilityBarLink = _styledComponents2.default.a.withConfig({
  displayName: 'UtilityBar__UtilityBarLink',
  componentId: 'sc-1niya4n-2'
})(['margin:0 10px;padding:15px 10px;text-decoration:none;cursor:pointer;']);

var UtilityBarList = _styledComponents2.default.ul.withConfig({
  displayName: 'UtilityBar__UtilityBarList',
  componentId: 'sc-1niya4n-3'
})(['display:flex;margin:0;']);

var StyledUtilityBar = _styledComponents2.default.div.withConfig({
  displayName: 'UtilityBar__StyledUtilityBar',
  componentId: 'sc-1niya4n-4'
})(['display:flex;justify-content:flex-end;']);

function UtilityBar(_ref2) {
  var config = _ref2.config,
      utilityBarOptions = _ref2.utilityBarOptions,
      _ref2$items = _ref2.items,
      items = _ref2$items === undefined ? [] : _ref2$items,
      theme = _ref2.theme,
      preview = _ref2.preview,
      dropDownActions = _ref2.dropDownActions,
      initialValues = _ref2.initialValues,
      socialShare = _ref2.socialShare,
      direction = _ref2.direction;

  var interceptClick = preview ? function (event) {
    return event.preventDefault();
  } : function () {};
  return _react2.default.createElement(
    StyledUtilityBar,
    null,
    items.length !== 0 && _react2.default.createElement(
      UtilityBarList,
      null,
      items.map(function (_ref3) {
        var label = _ref3.label,
            itemId = _ref3.itemId;
        return _react2.default.createElement(
          'li',
          { key: itemId },
          _react2.default.createElement(
            UtilityBarLink,
            { onClick: interceptClick },
            _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return '\n                    ' + (0, _Global.fontStyleOverride)(theme.link.font) + '\n                    padding: 8px;\n                    &:hover {\n                      ' + (theme.link.hoverColour && 'color: ' + theme.link.hoverColour.value) + ';\n                    }';
                }
              },
              label
            )
          )
        );
      })
    ),
    config && config.socialShareEnabled && _react2.default.createElement(SocialShare, { config: config, socialShare: socialShare }),
    utilityBarOptions.map(function (_ref4, index) {
      var initialSelectedValue = _ref4.initialSelectedValue,
          options = _ref4.options,
          placeholder = _ref4.placeholder,
          key = _ref4.key,
          prefixLabel = _ref4.prefixLabel;

      var selectedValue = initialValues[key.toLowerCase()];
      var selectedOption = void 0;

      if (selectedValue) {
        selectedOption = options.find(function (opt) {
          return opt.value === selectedValue;
        }) || initialSelectedValue;
      }

      var dropdownProps = {
        onOptionClick: dropDownActions[key.toLowerCase()],
        initialSelectedValue: selectedOption,
        options: options,
        theme: theme,
        placeholder: placeholder,
        selectedValue: selectedValue,
        direction: direction,
        getLabelFromOption: function getLabelFromOption(i) {
          return i.label;
        },
        getValueFromOption: function getValueFromOption(i) {
          return i.value;
        },
        getDisplayFromOption: function getDisplayFromOption(i) {
          return key === 'Currency' ? '' + prefixLabel + (i.display || (0, _currencySymbol.getSymbolFromCurrency)(i.value)) : prefixLabel + ' ' + (i.display || i.label);
        },
        width: key === 'Currency' ? 290 : 200
      };

      return _react2.default.createElement(_DropDown2.default, (0, _extends3.default)({ key: 'Dropdown-' + (index + 1) }, dropdownProps));
    })
  );
}