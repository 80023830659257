'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _renderSlide = require('./renderSlide');

Object.defineProperty(exports, 'Slide', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_renderSlide).default;
  }
});

var _Carousel = require('./Carousel');

Object.defineProperty(exports, 'default', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Carousel).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }