'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Chunky;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Chunky(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height,
      direction = _ref.direction;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height,
      direction: direction
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 25 23' },
      _react2.default.createElement('path', { d: 'M9.8 20.4L7 17.7l6.5-6.5L7 4.7 9.8 2l9.2 9.2z' })
    )
  );
}

Chunky.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};