/* eslint-env browser */
const appSettings = window.AppSettings || {};

const apiUseHttps =
  appSettings.useHttps !== undefined ? appSettings.useHttps : false;
const apiHostProtocol = apiUseHttps ? 'https' : 'http';
const apiHostUrl = appSettings.hostName || 'dev.mtkip.com';
const baseUrl = `${apiHostProtocol}://${apiHostUrl}`;

const { imsModelGroupsBaseUrl } = appSettings;

const vehiclesShortlistServicePath = 'vehicles-shortlist/';
const testDrivesServicePath = 'test-drives/';
const myDealsServicePath = 'avl-deals/';
const inventorySearchServicePath = 'inventory-search/';

const avlCustomersServicePath = 'avl-customers/';

const postsServicePath = 'posts/';
const useLegacyApiUrls =
  appSettings.useLegacyApiPattern !== undefined
    ? appSettings.useLegacyApiPattern
    : true;
const configBaseUrl =
  appSettings.configBaseUrl ||
  'https://s3-eu-west-1.amazonaws.com/publish-preview.dev.avl-builder.com';

const { configMarketByUrl, configSource = 'local' } = appSettings;

const localSiteId =
  appSettings.localSiteId || '69c1380d-2e83-422c-a453-bc5692a225bb';
const { imsLeadsHostName, imsApacLeadsHostName } = appSettings;
const inventoryStatus = appSettings.inventoryStatus || 'retail';

const placeholderBrandImageUrl =
  appSettings.placeholderBrandImageUrl ||
  'http://res.cloudinary.com/motortrak/image/upload/c_scale,h_768,w_1024/v1536583771/Placeholder%20Images/PlaceholderJAG.png';

const flags = appSettings ? appSettings.flags : {};
const appendLocaleQueryString =
  appSettings.appendLocaleQueryString !== undefined
    ? appSettings.appendLocaleQueryString
    : false;
const { noLanguageInUrl } = appSettings;

const {
  thirdPartyLeadsBaseUrl,
  enquiryFormPostMessageUrl,
  leadsApiUrl,
} = appSettings;

const settings = {
  ...appSettings,
  baseUrl,
  vehiclesShortlistServicePath,
  testDrivesServicePath,
  myDealsServicePath,
  inventorySearchServicePath,
  avlCustomersServicePath,
  imsModelGroupsBaseUrl,
  useLegacyApiUrls,
  configBaseUrl,
  configMarketByUrl,
  configSource,
  localSiteId,
  placeholderBrandImageUrl,
  flags,
  appendLocaleQueryString,
  imsLeadsHostName,
  imsApacLeadsHostName,
  inventoryStatus,
  postsServicePath,
  noLanguageInUrl,
  thirdPartyLeadsBaseUrl,
  enquiryFormPostMessageUrl,
  leadsApiUrl,
};

export default settings;
