'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 60px 50px;\n  '], ['\n    padding: 0 60px 50px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _EmissionsGroup = require('./EmissionsGroup');

var _EmissionsGroup2 = _interopRequireDefault(_EmissionsGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpEmissions__Container',
  componentId: 'sc-kyyjn2-0'
})(['display:flex;flex-direction:column;margin:0 auto;padding:0 5% 40px;max-width:1400px;', ';'], _theme2.default.min.large(_templateObject));


function VdpEmissions(_ref) {
  var translations = _ref.translations,
      vehicleInfo = _ref.vehicleInfo,
      headerFont = _ref.headerFont,
      contentWidth = _ref.contentWidth;
  var specification = vehicleInfo.specification,
      emissions = vehicleInfo.emissions,
      consumption = vehicleInfo.consumption,
      energyEfficiencyRating = vehicleInfo.energyEfficiencyRating;


  return _react2.default.createElement(
    Container,
    { contentWidth: contentWidth },
    _react2.default.createElement(_EmissionsGroup2.default, {
      translations: translations,
      data: {
        fuelType: specification.fuelType,
        weightedCombinedFuelConsumption: specification.engineDetails.weightedCombinedFuelConsumption,
        combinedPowerConsumption: consumption.wltpPowerConsumptionWeightedCombined,
        combinedPowerConsumptionEV: consumption.wltpPowerConsumptionCombined,
        weightedCombinedCo2Emission: specification.engineDetails.weightedCombinedCo2Emission,
        fuelConsumptionCombined: consumption.wltpConsumptionCombined,
        co2EmissionsCombined: emissions.wltpEmissionsCombined,
        co2EmissionsClassWeightedCombined: emissions.co2ClassWeightedCombined,
        combinedFuelConsumptionDischargedBattery: consumption.wltpWeightedCombinedConsumptionDischargedBattery,
        co2ClassWithDischargedBatteryLabel: emissions.co2ClassDischaredBattery,
        electricRange: consumption.wltpBatteryRange,
        electricRangeHybrid: consumption.wltpBatteryRange,
        emissionsClass: energyEfficiencyRating
      },
      headerFont: headerFont
    })
  );
}

exports.default = VdpEmissions;