'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _router = require('../../actions/router');

var _reducer = require('../../shared/CustomerLogin/reducer');

var _Register = require('../../components/Register');

var _Register2 = _interopRequireDefault(_Register);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Register = function (_Component) {
  (0, _inherits3.default)(Register, _Component);

  function Register() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Register);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Register.__proto__ || (0, _getPrototypeOf2.default)(Register)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        // We call init to force a first run of the reducer
        // - there used to be a more generic solution,
        //   but it broke the builder
        var _this$props = _this.props,
            shared = _this$props.shared,
            init = _this$props.actions.init,
            dispatch = _this$props.dispatch;

        if ((0, _reducer.isTokenValid)(shared)) {
          dispatch(_router.actions.navigate('/'));
        } else {
          init();
        }
      }
    }), Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props2 = _this.props,
            state = _this$props2.state,
            config = _this$props2.config,
            actions = _this$props2.actions,
            preview = _this$props2.preview,
            dispatch = _this$props2.dispatch,
            globalStyling = _this$props2.globalStyling;

        var fnIfNotPreview = function fnIfNotPreview(fn) {
          return preview ? function () {} : fn;
        };
        return _react2.default.createElement(_Register2.default, {
          translations: config.translations,
          termsLink: config.termsAndConditionsLink,
          register: function register(user) {
            return fnIfNotPreview(function () {
              return actions.register(user);
            });
          },
          registerFailed: state.registerFailed,
          context: state.context,
          fnIfNotPreview: fnIfNotPreview,
          goToPage: function goToPage() {
            return fnIfNotPreview(function () {
              return dispatch(_router.actions.navigate('signin'));
            });
          },
          globalStyling: globalStyling,
          registerTextTransform: config.registerTextTransform,
          marketingLink: config.marketingLink,
          showUserCommunication: config.showUserCommunication,
          dataConsentFont: config.dataConsentFont,
          checkboxLabelStyles: config.checkboxLabelFont,
          showJLRMarketingAndDistributionConsent: config.showJLRMarketingAndDistributionConsent
        });
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  return Register;
}(_react.Component);

exports.default = Register;