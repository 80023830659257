'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _VehicleTitleInfo = require('./VehicleTitleInfo');

Object.defineProperty(exports, 'VehicleTitleInfo', {
  enumerable: true,
  get: function get() {
    return _VehicleTitleInfo.VehicleTitleInfo;
  }
});

var _VehicleGeneralInformation = require('./VehicleGeneralInformation');

Object.defineProperty(exports, 'VehicleGeneralInformation', {
  enumerable: true,
  get: function get() {
    return _VehicleGeneralInformation.VehicleGeneralInformation;
  }
});

var _AtaGlance = require('./AtaGlance');

Object.defineProperty(exports, 'AtaGlance', {
  enumerable: true,
  get: function get() {
    return _AtaGlance.AtaGlance;
  }
});

var _VehicleFeatures = require('./VehicleFeatures');

Object.defineProperty(exports, 'VehicleFeatures', {
  enumerable: true,
  get: function get() {
    return _VehicleFeatures.VehicleFeatures;
  }
});

var _AdditionalFeatures = require('./AdditionalFeatures');

Object.defineProperty(exports, 'AdditionalFeatures', {
  enumerable: true,
  get: function get() {
    return _AdditionalFeatures.AdditionalFeatures;
  }
});

var _AdditionalComments = require('./AdditionalComments');

Object.defineProperty(exports, 'AdditionalComments', {
  enumerable: true,
  get: function get() {
    return _AdditionalComments.AdditionalComments;
  }
});

var _Emissions = require('./Emissions');

Object.defineProperty(exports, 'Emissions', {
  enumerable: true,
  get: function get() {
    return _Emissions.Emissions;
  }
});