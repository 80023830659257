'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

exports.default = validate;
exports.validateAll = validateAll;

var _validationFunctions = require('./validationFunctions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var validations = {
  required: _validationFunctions.required,
  email: _validationFunctions.validEmail,
  phone: _validationFunctions.validPhone
};

function validate(field, translations) {
  if (!field.validation) {
    return undefined;
  }
  return (0, _extends4.default)((0, _defineProperty3.default)({}, field.name, validations[field.validation](field.value, translations)[0]));
}

function validateAll(fields, translations) {
  var errors = (0, _values2.default)(fields).map(function (fieldValue) {
    return validate(fieldValue, translations);
  }).filter(function (fieldError) {
    return fieldError !== undefined;
  }).reduce(function (acc, currVal) {
    return (0, _extends4.default)({}, acc, currVal);
  }, {});

  return errors;
}