'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TextCTA = require('./TextCTA');

var _TextCTA2 = _interopRequireDefault(_TextCTA);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TextCTA2.default;