'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    height: auto;\n  '], ['\n    width: 100%;\n    height: auto;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']);

exports.default = SortMenuBar;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SortContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SortMenuBar__SortContainer',
  componentId: 'sc-p95jr6-0'
})(['display:flex;align-items:center;width:', ';justify-content:flex-end;', ';'], function (props) {
  return props.filtersExpanded ? '100%' : 'auto';
}, _theme2.default.max.large(_templateObject));
var Sort = _styledComponents2.default.div.withConfig({
  displayName: 'SortMenuBar__Sort',
  componentId: 'sc-p95jr6-1'
})(['display:flex;', ';'], _theme2.default.max.large(_templateObject2));

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'SortMenuBar__Select',
  componentId: 'sc-p95jr6-2'
})(['', ';min-width:250px;height:45px;font-size:14px;line-height:40px;box-sizing:border-box;border:1px solid #999999;padding-left:15px;padding-right:15px;color:#444444;background-color:#fff;', ';&:focus{outline:none;}'], function (_ref) {
  var fontFamily = _ref.fontFamily;
  return fontFamily && 'font-family: ' + fontFamily;
}, _theme2.default.max.large(_templateObject2));

function SortMenuBar(_ref2) {
  var sortOptions = _ref2.sortOptions,
      sortValue = _ref2.sortValue,
      filtersExpanded = _ref2.filtersExpanded,
      onSortChange = _ref2.onSortChange,
      translations = _ref2.translations,
      showDistance = _ref2.showDistance,
      _ref2$showMileage = _ref2.showMileage,
      showMileage = _ref2$showMileage === undefined ? true : _ref2$showMileage,
      _ref2$showAge = _ref2.showAge,
      showAge = _ref2$showAge === undefined ? true : _ref2$showAge,
      _ref2$showPrice = _ref2.showPrice,
      showPrice = _ref2$showPrice === undefined ? true : _ref2$showPrice,
      fontFamily = _ref2.fontFamily;

  var sortOptionsField = function sortOptionsField(fieldValue) {
    return ((0, _values2.default)(sortOptions).find(function (so) {
      return so.value === fieldValue;
    }) || {}).field;
  };

  var sortOptionsOrder = function sortOptionsOrder(fieldValue) {
    return ((0, _values2.default)(sortOptions).find(function (so) {
      return so.value === fieldValue;
    }) || {}).order;
  };

  var updateFilters = function updateFilters(sortField) {
    var payload = [{ key: 'sortField', value: [sortOptionsField(sortField)] }, { key: 'sortDirection', value: [sortOptionsOrder(sortField)] }];
    onSortChange(payload, sortField);
  };

  return _react2.default.createElement(
    SortContainer,
    { filtersExpanded: filtersExpanded },
    _react2.default.createElement(
      Sort,
      null,
      _react2.default.createElement(
        Select,
        {
          'data-cy': 'sort-dropdown',
          value: sortValue,
          onChange: function onChange(e) {
            return updateFilters(e.target.value);
          },
          fontFamily: fontFamily,
          'aria-label': translations.sortByAlternativeText
        },
        showPrice && _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.priceAsc.value,
              field: sortOptions.priceAsc.field,
              order: sortOptions.priceAsc.order
            },
            translations.searchResultsSortPriceAscLabel
          ),
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.priceDesc.value,
              field: sortOptions.priceDesc.field,
              order: sortOptions.priceDesc.order
            },
            translations.searchResultsSortPriceDescLabel
          )
        ),
        showMileage && _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.mileageAsc.value,
              field: sortOptions.mileageAsc.field,
              order: sortOptions.mileageAsc.order
            },
            translations.searchResultsSortMileageAscLabel
          ),
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.mileageDesc.value,
              field: sortOptions.mileageDesc.field,
              order: sortOptions.mileageDesc.order
            },
            translations.searchResultsSortMileageDescLabel
          )
        ),
        showAge && _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.ageAsc.value,
              field: sortOptions.ageAsc.field,
              order: sortOptions.ageAsc.order
            },
            translations.searchResultsSortAgeAscLabel
          ),
          _react2.default.createElement(
            'option',
            {
              value: sortOptions.ageDesc.value,
              field: sortOptions.ageDesc.field,
              order: sortOptions.ageDesc.order
            },
            translations.searchResultsSortAgeDescLabel
          )
        ),
        showDistance && _react2.default.createElement(
          'option',
          {
            value: sortOptions.distanceAsc.value,
            field: sortOptions.distanceAsc.field,
            order: sortOptions.distanceAsc.order
          },
          translations.searchResultsSortDistanceLabel
        )
      )
    )
  );
}
SortMenuBar.defaultProps = {
  filtersExpanded: false
};