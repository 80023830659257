'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldError = require('./FieldError');

var _FieldError2 = _interopRequireDefault(_FieldError);

var _WidthWrapper = require('./WidthWrapper');

var _WidthWrapper2 = _interopRequireDefault(_WidthWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Input = _styledComponents2.default.input.withConfig({
  displayName: 'TextInput__Input',
  componentId: 'sc-xak06p-0'
})(['width:100%;height:45px;font-size:16px;font-family:inherit;line-height:40px;box-sizing:border-box;border:1px solid ', ';background-color:', ';padding:0 8px;&:disabled{border:none;background-color:#d6d6d6;}&:focus{border:1px solid #999999;color:#444444;outline:none;}', ';'], function (_ref) {
  var error = _ref.error;
  return error ? '#BF0C0C' : '#d8d8d8';
}, function (_ref2) {
  var backgroundColour = _ref2.backgroundColour;
  return backgroundColour || '#ffffff';
}, function (props) {
  return props.truncateChars && (0, _styledComponents.css)(['white-space:nowrap;overflow:hidden;text-overflow:ellipsis;']);
});

exports.default = function (_ref3) {
  var name = _ref3.name,
      label = _ref3.label,
      required = _ref3.required,
      value = _ref3.value,
      onChange = _ref3.onChange,
      onBlur = _ref3.onBlur,
      error = _ref3.error,
      isDisabled = _ref3.isDisabled,
      placeholder = _ref3.placeholder,
      _ref3$truncateChars = _ref3.truncateChars,
      truncateChars = _ref3$truncateChars === undefined ? false : _ref3$truncateChars,
      font = _ref3.font;

  return _react2.default.createElement(
    _WidthWrapper2.default,
    null,
    _react2.default.createElement(
      _FormField2.default,
      { label: label, name: name, required: required, font: font },
      _react2.default.createElement(Input, {
        type: 'text',
        name: name,
        id: name,
        value: value,
        onChange: function (_onChange) {
          function onChange(_x) {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function (e) {
          onChange({ name: e.target.name, value: e.target.value });
        }),
        onBlur: function (_onBlur) {
          function onBlur(_x2) {
            return _onBlur.apply(this, arguments);
          }

          onBlur.toString = function () {
            return _onBlur.toString();
          };

          return onBlur;
        }(function (e) {
          onBlur({ name: e.target.name, value: e.target.value });
        }),
        placeholder: placeholder,
        error: error,
        disabled: isDisabled,
        truncateChars: truncateChars,
        ariaRequired: required
      }),
      error && _react2.default.createElement(
        _FieldError2.default,
        null,
        error
      )
    )
  );
};