'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SingleSelectFilter;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'SingleSelectFilter__Select',
  componentId: 'sc-1utnp2g-0'
})(['height:40px;cursor:pointer;width:100%;padding:0 8px;border:1px solid #d5d5d5;box-sizing:border-box;display:flex;align-items:center;justify-content:space-between;outline:none;background-color:#ffffff;color:#444444;']);

function SingleSelectFilter(_ref) {
  var filterKey = _ref.filterKey,
      selectedData = _ref.selectedData,
      data = _ref.data,
      updateFilters = _ref.updateFilters,
      fuelConsumptionText = _ref.fuelConsumptionText,
      distanceToLabel = _ref.distanceToLabel;

  var fuelConsumptionOptionText = fuelConsumptionText || '';

  return _react2.default.createElement(
    Select,
    {
      value: selectedData || data[0].value,
      onChange: function onChange(event) {
        var value = event.target.value;

        updateFilters([{
          key: filterKey,
          value: value === data[0].value || value === distanceToLabel ? [] : value
        }]);
      }
    },
    data.map(function (d) {
      return _react2.default.createElement(
        'option',
        { key: d.label, value: d.value },
        distanceToLabel && d.label.replace('Worldwide', distanceToLabel) || fuelConsumptionOptionText && fuelConsumptionOptionText.replace(/{VALUE}/g, d.value) || d.label
      );
    })
  );
}