'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _vehicleTypes = require('../../types/Bentley/vehicleTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContactLink = _styledComponents2.default.a.withConfig({
  displayName: 'PhoneLink__ContactLink',
  componentId: 'sc-18hzykq-0'
})(['display:flex;text-decoration:none;font-family:Bentley-Regular;font-size:14px;line-height:18px;color:#000000;align-items:center;img{margin-right:8px;}']);

var PhoneIcon = _styledComponents2.default.img.withConfig({
  displayName: 'PhoneLink__PhoneIcon',
  componentId: 'sc-18hzykq-1'
})(['width:12px;height:12px;']);

var PhoneLink = function PhoneLink(_ref) {
  var iconPhoneUrl = _ref.iconPhoneUrl,
      vehicle = _ref.vehicle;

  if (vehicle.retailerPhone) return _react2.default.createElement(
    ContactLink,
    { href: 'tel:' + vehicle.retailerPhone },
    _react2.default.createElement(PhoneIcon, { src: iconPhoneUrl }),
    vehicle.retailerPhone
  );
  return null;
};

exports.default = PhoneLink;