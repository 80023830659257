'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHelmet = require('react-helmet');

var _reactRedux = require('react-redux');

var _CompiledModule = require('./CompiledModule');

var _CompiledModule2 = _interopRequireDefault(_CompiledModule);

var _useBody2 = require('./useBody');

var _useBody3 = _interopRequireDefault(_useBody2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Body = function Body(_ref) {
  var path = _ref.path,
      globalStyling = _ref.globalStyling,
      hash = _ref.hash,
      ignoreBandList = _ref.ignoreBandList;

  var _useBody = (0, _useBody3.default)(path, hash),
      page = _useBody.page,
      bands = _useBody.bands,
      bandComponents = _useBody.bandComponents;

  var lang = (0, _reactRedux.useSelector)(function (state) {
    return state.config.settings.locale;
  });
  var metaNoIndex = page.relativePath && page.relativePath === '/404' || page.relativePath && page.relativePath === '/$$ERROR$$';

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _reactHelmet.Helmet,
      { htmlAttributes: { lang: (lang || 'en-GB').replace(/_/g, '-') } },
      metaNoIndex && _react2.default.createElement('meta', { name: 'robots', content: 'noindex' }),
      page.seo && page.seo.title && _react2.default.createElement(
        'title',
        null,
        page.seo.title
      ),
      page.seo && page.seo.description && _react2.default.createElement('meta', { name: 'description', content: page.seo.description }),
      page.seo && page.seo.og && (0, _entries2.default)(page.seo.og).map(function (og) {
        return _react2.default.createElement('meta', { property: 'og:' + og[0], content: og[1] });
      }),
      _react2.default.createElement('link', {
        rel: 'canonical',
        href: page.seo && page.seo.canonicalUrl ? page.seo.canonicalUrl : window.location.href.split('?')[0]
      })
    ),
    bands.filter(function (band) {
      return ignoreBandList ? !ignoreBandList.includes(band.id) : band;
    }).map(function (band, index) {
      var component = bandComponents[band.id];
      var BandComponent = component ? component.component : _CompiledModule2.default;
      return _react2.default.createElement(BandComponent, {
        key: band.instanceId,
        band: band,
        config: band.config,
        path: path,
        globalStyling: globalStyling,
        index: index
      });
    })
  );
};

exports.default = Body;