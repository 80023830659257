'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 36px;\n  '], ['\n    font-size: 36px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useLocation2 = require('../../../hooks/useLocation');

var _useLocation3 = _interopRequireDefault(_useLocation2);

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

var _Spinner = require('../../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _modelTypes = require('../../../types/Bentley/modelTypes');

var _Form = require('../Form');

var _LocationBar = require('../ModelSearchBar/LocationBar');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__Wrapper',
  componentId: 'sc-bnzudb-0'
})(['flex-direction:column;display:flex;gap:20px;width:100%;']);

var InputRow = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__InputRow',
  componentId: 'sc-bnzudb-1'
})(['display:flex;width:100%;']);

var ActionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__ActionWrapper',
  componentId: 'sc-bnzudb-2'
})(['display:flex;', ' height:40px;width:40px;justify-content:center;align-items:center;cursor:pointer;border-radius:1px 0 0 1px;'], function (_ref) {
  var color = _ref.color;
  return 'background-color: ' + color + ';';
});

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__Title',
  componentId: 'sc-bnzudb-3'
})(['display:flex;flex-direction:column;text-transform:uppercase;font-family:Bentley-Regular;', ' font-size:22px;', ';'], function (_ref2) {
  var color = _ref2.color;
  return 'color: ' + color + ';';
}, _theme2.default.min.large(_templateObject));

var InputWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__InputWrapper',
  componentId: 'sc-bnzudb-4'
})(['position:relative;flex:1 1 0%;']);

var Input = _styledComponents2.default.input.withConfig({
  displayName: 'OverlaySearch__Input',
  componentId: 'sc-bnzudb-5'
})(['padding:10px 40px 10px 10px;height:40px;width:100%;-webkit-appearance:none;border-radius:0;box-sizing:border-box;', ' &:focus{outline:none;}'], function (_ref3) {
  var borderColor = _ref3.borderColor;
  return 'border: solid 1px ' + borderColor + ';';
});

var SpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'OverlaySearch__SpinnerWrapper',
  componentId: 'sc-bnzudb-6'
})(['position:absolute;right:10px;top:calc(50% - 10px);']);

var OverlaySearch = function OverlaySearch(_ref4) {
  var iconUrl = _ref4.iconUrl,
      browserLocationCallback = _ref4.browserLocationCallback,
      inputLocationCallback = _ref4.inputLocationCallback,
      translations = _ref4.translations,
      onButtonClick = _ref4.onButtonClick;

  var _useLocation = (0, _useLocation3.default)(browserLocationCallback, inputLocationCallback, {}),
      formattedLocation = _useLocation.formattedLocation,
      getBrowserLocation = _useLocation.getBrowserLocation,
      getInputLocation = _useLocation.getInputLocation,
      onInputChange = _useLocation.onInputChange,
      suggestions = _useLocation.suggestions,
      visible = _useLocation.visible,
      currentlySelected = _useLocation.currentlySelected,
      setCurrentlySelected = _useLocation.setCurrentlySelected,
      inputRef = _useLocation.inputRef,
      onInputKeyDown = _useLocation.onInputKeyDown,
      onInputBlur = _useLocation.onInputBlur,
      onInputFocus = _useLocation.onInputFocus,
      isLoading = _useLocation.isLoading;

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      colours = _useSelector.colours;

  var ref = (0, _react.useRef)(inputRef);

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      Title,
      { color: colours.primaryBrandColour.value },
      translations.locationPromptTitle
    ),
    _react2.default.createElement(
      InputRow,
      null,
      _react2.default.createElement(
        ActionWrapper,
        {
          color: colours.primaryBrandColour.value,
          onClick: getBrowserLocation
        },
        _react2.default.createElement('img', { src: iconUrl, height: 18, width: 18, alt: 'location-icon' })
      ),
      _react2.default.createElement(
        InputWrapper,
        null,
        _react2.default.createElement(Input, {
          id: 'locationSearch',
          type: 'text',
          borderColor: colours.primaryBrandColour.value,
          'data-cy': 'user-location-input',
          autoComplete: 'off',
          ref: ref,
          placeholder: translations.locationPlaceholder,
          onChange: onInputChange,
          onKeyDown: onInputKeyDown,
          onBlur: function onBlur(event) {
            return onInputBlur(event, translations.locationPlaceholder);
          },
          onFocus: onInputFocus,
          value: formattedLocation
        }),
        suggestions.length > 0 && formattedLocation.length > 0 && visible && _react2.default.createElement(
          _LocationBar.LocationSuggestions,
          { top: '40px' },
          suggestions.map(function (s, index) {
            return _react2.default.createElement(
              _LocationBar.Suggestion,
              {
                color: colours.primaryBrandColour.value,
                role: 'button',
                'data-cy': 'user-location-suggestion-list-option',
                onMouseDown: function onMouseDown() {
                  setCurrentlySelected(0);
                  getInputLocation(s);
                },
                onMouseEnter: function onMouseEnter() {
                  return setCurrentlySelected(index);
                },
                inFocus: currentlySelected === index,
                key: s.description
              },
              s.description
            );
          })
        ),
        isLoading && _react2.default.createElement(
          SpinnerWrapper,
          null,
          _react2.default.createElement(_Spinner2.default, { size: 10, colour: colours.primaryBrandColour })
        )
      ),
      _react2.default.createElement(_Form.PrimaryButton, {
        text: translations.searchCTAOverlay,
        onClick: onButtonClick,
        styleOverride: function styleOverride() {
          return 'border-radius: 0 1px 1px 0;';
        }
      })
    )
  );
};

exports.default = OverlaySearch;