'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('./Global');

var _ContentWrapper = require('./ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpOnDemandSection__Container',
  componentId: 'sc-njvziq-0'
})(['text-align:', ';padding:20px;', ';'], function (_ref) {
  var align = _ref.align;
  return '' + (align || 'unset');
}, function (_ref2) {
  var maxWidth = _ref2.maxWidth;
  return maxWidth && 'max-width: ' + maxWidth + 'px;\n    margin: 0 auto;';
});

var TextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpOnDemandSection__TextContainer',
  componentId: 'sc-njvziq-1'
})(['margin:10px;']);

var OnDemandLink = _styledComponents2.default.div.withConfig({
  displayName: 'VdpOnDemandSection__OnDemandLink',
  componentId: 'sc-njvziq-2'
})(['', ';', ';cursor:pointer;'], function (_ref3) {
  var colour = _ref3.colour;
  return colour && 'color: ' + colour + ';';
}, function (_ref4) {
  var icon = _ref4.icon;
  return icon && '&:before {\n    content: \'\';\n    height: 19px;\n    width: 19px;\n    position: absolute;\n    margin: 0 0 0 -24px;\n    background: url(\'' + icon + '\') no-repeat center center;\n  }\n  ';
});

function VdpOnDemandSection(_ref5) {
  var config = _ref5.config,
      globalStyling = _ref5.globalStyling,
      navigate = _ref5.navigate;

  return _react2.default.createElement(
    _ContentWrapper2.default,
    {
      colour: config.onDemandSection.backgroundColour && config.onDemandSection.backgroundColour,
      config: config,
      contentWidth: globalStyling.contentWidth
    },
    _react2.default.createElement(
      Container,
      {
        align: config.onDemandSection.alignment,
        maxWidth: config.onDemandSection.contentMaxWidth
      },
      _react2.default.createElement(
        TextContainer,
        null,
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n                ' + (0, _Global.fontStyleOverride)(config.onDemandSection.HeadingFont) + '\n              ';
            }
          },
          config.translations.onDemandSectionTitle
        )
      ),
      _react2.default.createElement(
        TextContainer,
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          null,
          config.translations.onDemandSectionPara1
        ),
        _react2.default.createElement(
          _Global.Paragraph,
          null,
          config.translations.onDemandSectionPara2
        )
      ),
      _react2.default.createElement(
        OnDemandLink,
        {
          onClick: function onClick() {
            return navigate(config.onDemandSection.onDemandURL);
          },
          colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
          icon: config.onDemandSection.icon
        },
        config.translations.onDemandSectionButtonText
      )
    )
  );
}

exports.default = VdpOnDemandSection;