'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    height: 100px;\n    position: relative;\n    margin-top: -100px;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(31 31, 31, 0.7);\n  '], ['\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    height: 100px;\n    position: relative;\n    margin-top: -100px;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(31 31, 31, 0.7);\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 16px 25px;\n  '], ['\n    padding: 16px 25px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  padding: 31px 30px;\n  '], ['\n  padding: 31px 30px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _reactRedux = require('react-redux');

var _EmissionsSectionTypes = require('../../../types/Lamborghini/EmissionsSectionTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EmissionsSectionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsSection__EmissionsSectionContainer',
  componentId: 'sc-3ghjoh-0'
})(['flex-direction:column;align-items:baseline;height:100%;position:unset;margin-top:0;background-color:#1f1f1f;', ';'], _theme2.default.min.large(_templateObject));

var EmissionsSectionItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsSection__EmissionsSectionItemContainer',
  componentId: 'sc-3ghjoh-1'
})(['display:flex;flex-direction:column;justify-content:center;height:100%;padding:31px 60px;', ';', ';'], _theme2.default.max.medium(_templateObject2), _theme2.default.max.large(_templateObject3));

var EmissionsTitle = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsSection__EmissionsTitle',
  componentId: 'sc-3ghjoh-2'
})(['font-family:RobotoBold;font-size:16px;text-transform:uppercase;color:#ffffff;']);
var EmissionsValue = _styledComponents2.default.div.withConfig({
  displayName: 'EmissionsSection__EmissionsValue',
  componentId: 'sc-3ghjoh-3'
})(['font-family:RobotoLight;font-size:16px;text-transform:uppercase;color:#ffffff;']);

var EmissionsSectionPage = function EmissionsSectionPage(_ref) {
  var config = _ref.config;
  var translations = config.translations;

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      emissionsEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.emissionsEnabled
    };
  }),
      emissionsEnabled = _useSelector.emissionsEnabled;

  return emissionsEnabled && _react2.default.createElement(
    EmissionsSectionContainer,
    null,
    translations.combinedConsumptionValue && _react2.default.createElement(
      EmissionsSectionItemContainer,
      null,
      _react2.default.createElement(
        EmissionsTitle,
        null,
        translations.combinedConsumptionTitle
      ),
      _react2.default.createElement(
        EmissionsValue,
        null,
        translations.combinedConsumptionValue
      )
    ),
    translations.combinedCo2EmissionValue && _react2.default.createElement(
      EmissionsSectionItemContainer,
      null,
      _react2.default.createElement(
        EmissionsTitle,
        null,
        translations.combinedCo2EmissionTitle
      ),
      _react2.default.createElement(
        EmissionsValue,
        null,
        translations.combinedCo2EmissionValue
      )
    ),
    translations.powerConsumptionValue && _react2.default.createElement(
      EmissionsSectionItemContainer,
      null,
      _react2.default.createElement(
        EmissionsTitle,
        null,
        translations.powerConsumptionTitle
      ),
      _react2.default.createElement(
        EmissionsValue,
        null,
        translations.powerConsumptionValue
      )
    ),
    translations.efficiencyClassValue && _react2.default.createElement(
      EmissionsSectionItemContainer,
      null,
      _react2.default.createElement(
        EmissionsTitle,
        null,
        translations.efficiencyClassTitle
      ),
      _react2.default.createElement(
        EmissionsValue,
        null,
        translations.efficiencyClassValue
      )
    )
  );
};

exports.default = EmissionsSectionPage;