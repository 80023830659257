'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Fuel;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Fuel(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 22 20' },
      _react2.default.createElement('path', { d: 'M20.203 4.416l-2.465-1.243a.597.597 0 0 0-.614.028.629.629 0 0 0-.278.563.624.624 0 0 0 .343.523l.94.47a.626.626 0 0 0-.058.216c.04.778.514 1.463 1.219 1.757v7.59c0 .348-.276.632-.617.632a.625.625 0 0 1-.618-.633V9.335c-.076-1.464-1.08-2.704-2.47-3.054v-3.8c0-.66-.256-1.295-.714-1.76A2.39 2.39 0 0 0 13.147 0h-7.41C4.379-.017 3.263 1.09 3.235 2.48v13.946l-.892.449a.628.628 0 0 0-.343.557v1.87a.653.653 0 0 0 .65.632h13.584a.586.586 0 0 0 .424-.189c.11-.119.169-.279.162-.443v-1.87a.633.633 0 0 0-.338-.557l-.897-.449V7.584c.709.288 1.19.971 1.235 1.751v4.984c0 1.048.83 1.897 1.852 1.897 1.024 0 1.853-.85 1.853-1.897V4.973a.619.619 0 0 0-.322-.557zM13.115 8.72a.636.636 0 0 1-.182.445.606.606 0 0 1-.435.182H6.323a.624.624 0 0 1-.618-.627V3.741a.624.624 0 0 1 .617-.627h6.176a.606.606 0 0 1 .435.182.636.636 0 0 1 .182.445v4.978z' })
    )
  );
}

Fuel.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};