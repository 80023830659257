'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var InputChange = function (_React$Component) {
  (0, _inherits3.default)(InputChange, _React$Component);

  function InputChange() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, InputChange);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = InputChange.__proto__ || (0, _getPrototypeOf2.default)(InputChange)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'state', {
      enumerable: true,
      writable: true,
      value: {
        inputValue: ''
      }
    }), Object.defineProperty(_this, 'handleChange', {
      enumerable: true,
      writable: true,
      value: function (_value) {
        function value(_x) {
          return _value.apply(this, arguments);
        }

        value.toString = function () {
          return _value.toString();
        };

        return value;
      }(function (value) {
        return _this.setState(function () {
          return { inputValue: value };
        });
      })
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(InputChange, [{
    key: 'render',
    value: function render() {
      return this.props.children({
        value: this.state.inputValue,
        inputChange: this.handleChange
      });
    }
  }]);
  return InputChange;
}(_react2.default.Component);

exports.default = InputChange;