'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RadioGroup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RadioGroupWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RadioGroup__RadioGroupWrapper',
  componentId: 'sc-fr12xu-0'
})(['display:flex;justify-content:flex-start;', ';'], function (props) {
  return props.radioVertical && (0, _styledComponents.css)(['flex-direction:column;flex:1;justify-content:space-between;margin-top:40px;margin-bottom:10px;padding:0 20px;']);
});

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'RadioGroup__Label',
  componentId: 'sc-fr12xu-1'
})(['padding:2px 20px 2px 30px;margin-right:20px;position:relative;cursor:pointer;', ';'], function (_ref) {
  var font = _ref.font;
  return font ? 'font-size: ' + font.fontSize + 'px' : '';
});

var CustomRadio = _styledComponents2.default.span.withConfig({
  displayName: 'RadioGroup__CustomRadio',
  componentId: 'sc-fr12xu-2'
})(['position:absolute;top:0;left:0;height:14px;width:14px;background-color:#fff;border-radius:50%;border:1px solid #000;padding:2px;&:after{content:\'\';position:absolute;display:none;top:2px;left:2px;width:14px;height:14px;border-radius:50%;background:', ';}'], function (_ref2) {
  var fillColour = _ref2.fillColour;
  return fillColour ? '' + fillColour : '#dfb400';
});

var RadioButton = _styledComponents2.default.input.withConfig({
  displayName: 'RadioGroup__RadioButton',
  componentId: 'sc-fr12xu-3'
})(['cursor:pointer;top:0;right:0;bottom:0;left:0;position:absolute;opacity:0;width:100%;height:100%;z-index:1;&:checked ~ ', ':after{display:block;}'], CustomRadio);

function RadioGroup(_ref3) {
  var doubleRow = _ref3.doubleRow,
      label = _ref3.label,
      required = _ref3.required,
      error = _ref3.error,
      globalStyling = _ref3.globalStyling,
      radioOptions = _ref3.radioOptions,
      handleRadioChange = _ref3.handleRadioChange,
      radioVertical = _ref3.radioVertical,
      translations = _ref3.translations,
      labelFont = _ref3.labelFont,
      defaultCheckedOverride = _ref3.defaultCheckedOverride;

  var _ref4 = globalStyling || {},
      _ref4$direction = _ref4.direction,
      direction = _ref4$direction === undefined ? '' : _ref4$direction;

  return _react2.default.createElement(
    _FormField2.default,
    {
      doubleRow: doubleRow,
      label: label,
      required: required,
      globalStyling: globalStyling,
      translations: translations
    },
    radioOptions.map(function (_ref5) {
      var propertyName = _ref5.propertyName,
          options = _ref5.options,
          checkedIndex = _ref5.checkedIndex;
      return _react2.default.createElement(
        RadioGroupWrapper,
        { radioVertical: radioVertical, key: propertyName },
        options.map(function (opt, index) {
          return _react2.default.createElement(
            Label,
            { key: opt.value, font: labelFont },
            _react2.default.createElement(RadioButton, {
              type: 'radio',
              value: opt.value,
              name: propertyName,
              defaultChecked: index === (defaultCheckedOverride !== undefined ? defaultCheckedOverride : checkedIndex),
              onChange: handleRadioChange(propertyName),
              'aria-label': translations['' + opt.label],
              tabIndex: 0
            }),
            _react2.default.createElement(CustomRadio, {
              fillColour: globalStyling && globalStyling.colours && globalStyling.colours.primaryBrandColour.value
            }),
            translations['' + opt.label]
          );
        })
      );
    }),
    error && _react2.default.createElement(
      'div',
      {
        style: {
          color: '#9e1b32',
          textAlign: '' + (direction === 'rtl' ? 'right' : 'left')
        }
      },
      error
    )
  );
}
RadioGroup.defaultProps = {
  required: false,
  doubleRow: false,
  label: '',
  error: ''
};