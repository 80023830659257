'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _VehicleCarouselDetails = require('./VehicleCarouselDetails');

var _VehicleCarouselDetails2 = _interopRequireDefault(_VehicleCarouselDetails);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var imagesSelector = function imagesSelector(state) {
  if (!state.dataProvider.vehicleInfo) {
    return null;
  }

  var _state$dataProvider$v = state.dataProvider.vehicleInfo,
      images = _state$dataProvider$v.images,
      vehicleId = _state$dataProvider$v.id,
      views = _state$dataProvider$v.views;


  return { images: images, vehicleId: vehicleId, views: views };
};

exports.default = function (props) {
  var vehicleInfo = (0, _reactRedux.useSelector)(imagesSelector);

  return vehicleInfo && _react2.default.createElement(_VehicleCarouselDetails2.default, (0, _extends3.default)({}, props, {
    vehicleInfo: vehicleInfo
  }));
};