'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px 5px;\n  '], ['\n    padding: 20px 5px;\n  ']);

exports.default = FormField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../../components/Global');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FormFieldContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FormField__FormFieldContainer',
  componentId: 'sc-1u02ew8-0'
})(['width:100%;display:flex;flex-direction:column;padding-bottom:5px;', ';'], _theme2.default.min.medium(_templateObject));

function FormField(_ref) {
  var label = _ref.label,
      required = _ref.required,
      font = _ref.font,
      children = _ref.children;

  return _react2.default.createElement(
    FormFieldContainer,
    null,
    label && _react2.default.createElement(
      _Global.Paragraph,
      { styleOverride: function styleOverride() {
          return (0, _Global.fontStyleOverride)(font);
        } },
      label,
      required && '*'
    ),
    children
  );
}