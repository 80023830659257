'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CircleTick;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function CircleTick(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 30 25' },
      _react2.default.createElement('path', { d: 'M9.793 10.917a.76.76 0 0 0-1.07-.002.75.75 0 0 0-.002 1.065l3.49 3.468a.741.741 0 0 0 .536.219h.038a.847.847 0 0 0 .554-.275l8.733-10.4a.752.752 0 0 0-.45-1.23.762.762 0 0 0-.713.257l-8.218 9.777-2.898-2.88zm10.04-8.243a11 11 0 1 0 3.487 3.484.77.77 0 0 0-1.308.814 9.374 9.374 0 0 1-1.327 11.704A9.445 9.445 0 1 1 19.017 3.98a.77.77 0 1 0 .815-1.306z' })
    )
  );
}

CircleTick.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};