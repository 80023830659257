'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _chevronUp = require('react-icons/lib/io/chevron-up');

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _chevronDown = require('react-icons/lib/io/chevron-down');

var _chevronDown2 = _interopRequireDefault(_chevronDown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable react/prop-types */
var MoreInfoWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MoreInfoCollapsible__MoreInfoWrapper',
  componentId: 'sc-17jirrw-0'
})(['cursor:pointer;margin-bottom:20px;span{', ';font-size:14px;}'], function (_ref) {
  var globalStyling = _ref.globalStyling;
  return globalStyling && 'color: ' + (globalStyling && globalStyling.colours.primaryBrandColour.value) + ';\n  ';
});
var MoreInfoContent = _styledComponents2.default.div.withConfig({
  displayName: 'MoreInfoCollapsible__MoreInfoContent',
  componentId: 'sc-17jirrw-1'
})(['']);

var IconButton = _styledComponents2.default.button.withConfig({
  displayName: 'IconButton'
}).withConfig({
  displayName: 'MoreInfoCollapsible__IconButton',
  componentId: 'sc-17jirrw-2'
})(['background:none;border:none;cursor:pointer;&:focus{outline:none;}']);

var MoreInfoCollapsible = function (_Component) {
  (0, _inherits3.default)(MoreInfoCollapsible, _Component);

  function MoreInfoCollapsible(props) {
    (0, _classCallCheck3.default)(this, MoreInfoCollapsible);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MoreInfoCollapsible.__proto__ || (0, _getPrototypeOf2.default)(MoreInfoCollapsible)).call(this, props));

    Object.defineProperty(_this, 'onClickHandler', {
      enumerable: true,
      writable: true,
      value: function value() {
        var open = _this.state.open;

        _this.setState({ open: !open });
      }
    });
    Object.defineProperty(_this, 'renderIcon', {
      enumerable: true,
      writable: true,
      value: function value(globalStyling) {
        var open = _this.state.open;

        return open ? _react2.default.createElement(
          IconButton,
          null,
          _react2.default.createElement(_chevronUp2.default, { color: globalStyling.colours.primaryBrandColour.value })
        ) : _react2.default.createElement(
          IconButton,
          null,
          _react2.default.createElement(_chevronDown2.default, { color: globalStyling.colours.primaryBrandColour.value })
        );
      }
    });


    _this.state = {
      open: false
    };
    return _this;
  }

  (0, _createClass3.default)(MoreInfoCollapsible, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          title = _props.title,
          globalStyling = _props.globalStyling,
          children = _props.children;
      var open = this.state.open;

      return _react2.default.createElement(
        MoreInfoWrapper,
        {
          onClick: this.onClickHandler,
          globalStyling: globalStyling
        },
        _react2.default.createElement(
          'span',
          null,
          title
        ),
        this.renderIcon(globalStyling),
        _react2.default.createElement(
          MoreInfoContent,
          null,
          open ? children : null
        )
      );
    }
  }]);
  return MoreInfoCollapsible;
}(_react.Component);

exports.default = MoreInfoCollapsible;