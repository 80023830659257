'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Spinner = require('../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _useExterior = require('./useExterior');

var _useExterior2 = _interopRequireDefault(_useExterior);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Player = _styledComponents2.default.div.withConfig({
  displayName: 'Exterior__Player',
  componentId: 'sc-r4jgw0-0'
})(['width:100%;visibility:hidden;&.visible{visibility:visible;cursor:grab;}&.visible:active{cursor:grabbing;}']);

var Loader = _styledComponents2.default.div.withConfig({
  displayName: 'Exterior__Loader',
  componentId: 'sc-r4jgw0-1'
})(['position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);']);

exports.default = function (_ref) {
  var loader = _ref.loader,
      exterior = _ref.exterior,
      spinnerColour = _ref.spinnerColour;

  var ref = _react.useRef < HTMLDivElement > null;
  (0, _useExterior2.default)(ref, loader, exterior);
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(Player, { ref: ref }),
    _react2.default.createElement(
      Loader,
      { id: '360-player-spinner' },
      _react2.default.createElement(_Spinner2.default, { colour: spinnerColour })
    )
  );
};