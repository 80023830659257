'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  margin: 90px auto;\n'], ['\n  margin: 90px auto;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _AnimatedCarousel = require('../../../components/AstonMartin/AnimatedCarousel');

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AnimatedCarouselTypes = require('../../../types/AnimatedCarouselTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AnimatedCarouselWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'AnimatedCarouselSection__AnimatedCarouselWrapper',
  componentId: 'sc-1d3xirq-0'
})(['max-width:1920px;margin:132px auto;', ';'], _theme2.default.max.large(_templateObject));

var AnimatedCarouselSection = function AnimatedCarouselSection(_ref) {
  var config = _ref.config;

  var isMobile = (0, _useCheckIsMobileScreen2.default)('large');
  return _react2.default.createElement(
    AnimatedCarouselWrapper,
    null,
    _react2.default.createElement(_AnimatedCarousel.AnimatedCarousel, {
      footerLink: config.footerLink,
      footerLinkText: config.translations.footerLinkText,
      items: config.items,
      title: config.translations.title,
      nextIconUrl: config.nextIconUrl,
      prevIconUrl: config.prevIconUrl,
      plusIconUrl: config.plusIconUrl,
      initialMargin: isMobile ? 64 : 118
    })
  );
};

exports.default = AnimatedCarouselSection;