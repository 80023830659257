'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    position: relative;\n  '], ['\n    position: relative;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    position: relative;\n    height: auto;\n    width: 100%;\n    margin: 0;\n    opacity: 1;\n    left: 0;\n    padding: 0px 20px;\n  '], ['\n    position: relative;\n    height: auto;\n    width: 100%;\n    margin: 0;\n    opacity: 1;\n    left: 0;\n    padding: 0px 20px;\n  ']);

exports.default = ImageTitle;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../components/Global');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ImageTitle__Container',
  componentId: 'sc-1gtv45o-0'
})(['height:', ';max-height:', ';flex-direction:column;display:flex;'], function (_ref) {
  var height = _ref.height;
  return height ? height + 'px' : '100px';
}, function (_ref2) {
  var height = _ref2.height;
  return height ? height + 'px' : '100px';
});

var Image = _styledComponents2.default.div.withConfig({
  displayName: 'ImageTitle__Image',
  componentId: 'sc-1gtv45o-1'
})(['height:inherit;width:100%;background-image:url(', ');background-size:cover;position:absolute;background-position:center;', ';'], function (props) {
  return props.src;
}, _theme2.default.max.medium(_templateObject));

var HeadingBackgroundColor = _styledComponents2.default.div.withConfig({
  displayName: 'ImageTitle__HeadingBackgroundColor',
  componentId: 'sc-1gtv45o-2'
})(['', ' ', ' ', ';', ' ', ' ', ' ', ' ', ' ', ' ', ' display:flex;position:absolute;box-sizing:border-box;', ' ', ';'], function (_ref3) {
  var colour = _ref3.colour;
  return colour && 'background-color: ' + colour + ';';
}, function (_ref4) {
  var width = _ref4.width;
  return width && 'width: ' + width + 'px;';
}, function (_ref5) {
  var height = _ref5.height;
  return height && 'height: ' + height + 'px';
}, function (_ref6) {
  var marginTop = _ref6.marginTop;
  return marginTop && 'margin-top: ' + marginTop + 'px;';
}, function (_ref7) {
  var marginLeft = _ref7.marginLeft;
  return marginLeft && 'margin-left: ' + marginLeft + 'px;';
}, function (_ref8) {
  var padding = _ref8.padding;
  return padding && 'padding-left: ' + padding + 'px;';
}, function (_ref9) {
  var opacity = _ref9.opacity;
  return opacity && 'opacity: ' + opacity + ';';
}, function (_ref10) {
  var align = _ref10.align;
  return align && 'justify-content: ' + align + ';';
}, function (_ref11) {
  var alignItems = _ref11.alignItems;
  return alignItems && 'align-items: ' + alignItems + ';';
}, function (_ref12) {
  var top = _ref12.top;
  return top && 'top: ' + top + 'px;';
}, function (_ref13) {
  var padding = _ref13.padding,
      direction = _ref13.direction;
  return padding && direction === 'rtl' && 'padding-right: ' + padding + 'px;';
}, _theme2.default.max.medium(_templateObject2));

function ImageTitle(_ref14) {
  var config = _ref14.config,
      globalStyling = _ref14.globalStyling;

  return _react2.default.createElement(
    Container,
    { height: config.imageHeight },
    _react2.default.createElement(Image, { src: config.selectedImage && config.selectedImage.value }),
    _react2.default.createElement(
      _ContentWrapper2.default,
      { contentWidth: globalStyling.contentWidth },
      _react2.default.createElement(
        HeadingBackgroundColor,
        {
          colour: config.backgroundColour && config.backgroundColour.value,
          width: config.contentWidth,
          height: config.contentHeight,
          marginTop: config.marginTop,
          marginLeft: config.marginLeft,
          padding: config.paddingLeft,
          opacity: config.opacity,
          direction: globalStyling.direction,
          align: config.align,
          alignItems: config.alignItems,
          top: config.contentTop
        },
        config.translations.headlineText && _react2.default.createElement(
          _Global.HeadingOne,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(config.headlineFont) + '\n                margin: 10px 0;\n                display: flex;\n                align-items: center;\n              ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.small',
                template: '' + (0, _Global.fontStyleOverride)(config.mobileHeadlineFont)
              }];
            }
          },
          config.translations.headlineText
        ),
        config.translations.subHeading && _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)(config.bodyFont);
            }
          },
          config.translations.subHeading
        )
      )
    )
  );
}