'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _modelTypes = require('../../../types/Genesis/modelTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BodyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__BodyContainer',
  componentId: 'sc-d6r1k7-0'
})(['align-items:flex-start;box-sizing:border-box;display:flex;flex-direction:column;gap:24px;height:100%;justify-content:center;padding:24px;width:100%;']);

var Card = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__Card',
  componentId: 'sc-d6r1k7-1'
})(['align-items:flex-start;background:#1a1a1a;box-sizing:border-box;cursor:pointer;display:flex;flex-direction:column;position:relative;', ' ', ''], function (props) {
  return props.disabled ? 'opacity: 0.4;' : '&:hover {\n          background-color: #333;\n          transition: all 0.2s linear;\n        }';
}, function (props) {
  return props.isChecked && 'outline-color: #fff; outline-style: solid; &:hover{background: #1a1a1a;}';
});

var CardTitle = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__CardTitle',
  componentId: 'sc-d6r1k7-2'
})(['color:#bbb;font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;padding-bottom:6px;text-transform:uppercase;']);

var EVIcon = _styledComponents2.default.img.withConfig({
  displayName: 'ModelItem__EVIcon',
  componentId: 'sc-d6r1k7-3'
})(['padding-right:4px;']);

var EVLabel = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__EVLabel',
  componentId: 'sc-d6r1k7-4'
})(['border:1px solid #fff;color:#fff;display:flex;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:16px;font-weight:normal;padding:4px 8px;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'ModelItem__Image',
  componentId: 'sc-d6r1k7-5'
})(['width:100%;']);

var Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__Overlay',
  componentId: 'sc-d6r1k7-6'
})(['background:rgba(187,187,187,0.4);cursor:default;height:100%;left:0;position:absolute;top:0;width:100%;z-index:1;']);

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__Title',
  componentId: 'sc-d6r1k7-7'
})(['color:#bbb;color:#bbb;font-family:\'GenesisSansHeadGlobal-Light\';font-size:24px;letter-spacing:-0.25px;line-height:28px;']);

var TitleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelItem__TitleContainer',
  componentId: 'sc-d6r1k7-8'
})(['display:flex;flex-direction:row;justify-content:space-between;width:100%;']);

var ModelItem = function ModelItem(_ref) {
  var tile = _ref.tile,
      translations = _ref.translations,
      onTileSelected = _ref.onTileSelected,
      isChecked = _ref.isChecked,
      disabledVehicles = _ref.disabledVehicles;
  var imageUrl = tile.imageUrl,
      title = tile.title,
      _tile$ev = tile.ev,
      ev = _tile$ev === undefined ? false : _tile$ev,
      _tile$showEvEyebrow = tile.showEvEyebrow,
      showEvEyebrow = _tile$showEvEyebrow === undefined ? false : _tile$showEvEyebrow,
      translationNameKey = tile.translationNameKey;

  var disabled = disabledVehicles.includes(tile.tileId);
  return _react2.default.createElement(
    Card,
    {
      onClick: function onClick() {
        return !disabled && onTileSelected(tile.tileId);
      },
      isChecked: isChecked,
      disabled: disabled
    },
    disabled && _react2.default.createElement(Overlay, null),
    _react2.default.createElement(Image, {
      src: imageUrl,
      alt: 'Genesis ' + (translationNameKey && translations[translationNameKey] ? translations[translationNameKey] : title)
    }),
    _react2.default.createElement(
      BodyContainer,
      null,
      ev && showEvEyebrow && _react2.default.createElement(
        CardTitle,
        null,
        translations.electrified
      ),
      _react2.default.createElement(
        TitleContainer,
        null,
        _react2.default.createElement(
          Title,
          null,
          translationNameKey && translations[translationNameKey] ? translations[translationNameKey] : title
        ),
        ev && _react2.default.createElement(
          EVLabel,
          null,
          _react2.default.createElement(EVIcon, { src: 'https://res.cloudinary.com/motortrak/image/upload/v1698786282/locator/genesis/icons/electric.svg' }),
          translations.electricLabel
        )
      )
    )
  );
};

exports.default = ModelItem;