'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     flex: 1 1 calc(50% - 10px);\n  '], ['\n     flex: 1 1 calc(50% - 10px);\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _vehicle = require('../../../helpers/vehicle');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__Container',
  componentId: 'sc-zf0sk7-0'
})(['color:#fff;display:flex;flex-wrap:wrap;']);

var EmissionsItem = _styledComponents2.default.div.withConfig({
  displayName: 'Emissions__EmissionsItem',
  componentId: 'sc-zf0sk7-1'
})(['border-top:1px solid #fff;display:flex;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:14px;flex-direction:row;padding:10px 10px 8px 0;text-transform:uppercase;width:100%;:nth-last-of-type(-n + 2){flex:1 1 calc(50% - 10px);}:nth-last-child(2){flex:1 1 calc(70% - 10px);}:last-child{flex:1 1 calc(30% - 10px);}', ';', ';'], _Genesis2.default.min.medium(_templateObject), function (_ref) {
  var efficiencyLabel = _ref.efficiencyLabel;
  return efficiencyLabel && ' padding: 10px 0 8px; flex: 1 1 50%; justify-content: flex-end; & img {max-width: 75px}';
});

var Emissions = function Emissions(props) {
  var emissionsData = props.emissionsData,
      consumptionsData = props.consumptionsData,
      efficiency = props.efficiency,
      emissionsClass = props.emissionsClass,
      translations = props.translations,
      config = props.config;
  var consumptionCombined = translations.consumptionCombined,
      co2EmissionsCombined = translations.co2EmissionsCombined,
      emissionsClassLabel = translations.emissionsClassLabel;


  if (emissionsData.wltpEmissionsCombined || emissionsClass || consumptionsData.wltpConsumptionCombined || efficiency || efficiency && config.energyEfficiencyRatingMedia[efficiency]) {
    return _react2.default.createElement(
      Container,
      null,
      emissionsData.wltpEmissionsCombined && _react2.default.createElement(
        EmissionsItem,
        null,
        _react2.default.createElement(
          'div',
          null,
          co2EmissionsCombined + ': ' + (emissionsData.wltpEmissionsCombined || '-') + ' g/km (WLTP)'
        )
      ),
      emissionsClass && _react2.default.createElement(
        EmissionsItem,
        null,
        _react2.default.createElement(
          'div',
          null,
          emissionsClassLabel,
          ': ',
          emissionsClass
        )
      ),
      consumptionsData.wltpConsumptionCombined && _react2.default.createElement(
        EmissionsItem,
        null,
        _react2.default.createElement(
          'div',
          null,
          consumptionCombined + ': ' + ((0, _vehicle.fromMpgToLitresPer100ToString)(consumptionsData.wltpConsumptionCombined) || '-') + ' (WLTP)'
        )
      ),
      _react2.default.createElement(
        EmissionsItem,
        { efficiencyLabel: true },
        efficiency && config.energyEfficiencyRatingMedia[efficiency] && _react2.default.createElement('img', {
          src: config.energyEfficiencyRatingMedia[efficiency],
          alt: efficiency
        })
      )
    );
  } else {
    return _react2.default.createElement(Container, null);
  }
};

exports.default = Emissions;