'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'SimilarVehicles';

var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_SIMILAR_VEHICLES', 'GET_SIMILAR_VEHICLES_SUCCESS', 'SIMILAR_VEHICLES_NEXT', 'SIMILAR_VEHICLES_PREV', 'SIMILAR_VEHICLES_NEXT_MOBILE', 'SIMILAR_VEHICLES_PREV_MOBILE', 'INIT']);

var id = exports.id = 'SimilarVehicles';

var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  getSimilarVehicles: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_SIMILAR_VEHICLES, id, function (vehicleId) {
    return {
      vehicleId: vehicleId
    };
  }),
  getSimilarVehiclesSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_SIMILAR_VEHICLES_SUCCESS, id, function (payload) {
    return payload;
  }),
  similarVehiclesNext: (0, _reducerMacros.moduleAction)(constants.SIMILAR_VEHICLES_NEXT, id),
  similarVehiclesPrev: (0, _reducerMacros.moduleAction)(constants.SIMILAR_VEHICLES_PREV, id),
  similarVehiclesNextMobile: (0, _reducerMacros.moduleAction)(constants.SIMILAR_VEHICLES_NEXT_MOBILE, id),
  similarVehiclesPrevMobile: (0, _reducerMacros.moduleAction)(constants.SIMILAR_VEHICLES_NEXT_MOBILE, id)
};

var initialState = exports.initialState = {
  error: null,
  similarVehicles: null,
  vehiclesInView: null,
  similarVehiclePage: 1,
  similarVehiclePageTotal: null,
  selectedVehicleMobile: 0,
  similarVehiclePageMobile: 1,
  similarVehiclePageTotalMobile: null
};

var maxVehiclesPerPage = 3;

function defineVehiclesInView(state, similarVehiclePage) {
  return state.similarVehicles.slice(maxVehiclesPerPage * (similarVehiclePage - 1),
  // eslint-disable-next-line
  maxVehiclesPerPage * (similarVehiclePage - 1) + maxVehiclesPerPage);
}

function similarVehiclesNextMobile(state) {
  var selectedVehicleMobile = state.selectedVehicleMobile;

  selectedVehicleMobile = selectedVehicleMobile === state.vehiclesInView.length - 1 ? 0 : selectedVehicleMobile + 1;

  var similarVehiclePageMobile = state.similarVehiclePageMobile;

  similarVehiclePageMobile = similarVehiclePageMobile === state.similarVehiclePageTotalMobile ? 1 : similarVehiclePageMobile += 1;

  var similarVehiclePage = state.similarVehiclePage;

  similarVehiclePage = state.selectedVehicleMobile === state.vehiclesInView.length - 1 ? similarVehiclePage += 1 : similarVehiclePage;

  if (state.similarVehiclePageMobile === state.similarVehiclePageTotalMobile) {
    similarVehiclePage = 1;
  }

  var vehiclesInView = defineVehiclesInView(state, similarVehiclePage);

  return (0, _extends3.default)({}, state, {
    selectedVehicleMobile: selectedVehicleMobile,
    similarVehiclePageMobile: similarVehiclePageMobile,
    similarVehiclePage: similarVehiclePage,
    vehiclesInView: vehiclesInView
  });
}

function similarVehiclesPrevMobile(state) {
  var similarVehiclePageMobile = state.similarVehiclePageMobile;

  similarVehiclePageMobile = similarVehiclePageMobile === 1 ? state.similarVehiclePageTotalMobile : similarVehiclePageMobile -= 1;

  var similarVehiclePage = state.similarVehiclePage;

  similarVehiclePage = state.selectedVehicleMobile === 0 ? similarVehiclePage -= 1 : similarVehiclePage;

  if (state.similarVehiclePageMobile === 1) {
    similarVehiclePage = state.similarVehiclePageTotal;
  }

  var vehiclesInView = defineVehiclesInView(state, similarVehiclePage);

  var selectedVehicleMobile = state.selectedVehicleMobile;

  selectedVehicleMobile = selectedVehicleMobile === 0 ? vehiclesInView.length - 1 : selectedVehicleMobile -= 1;

  return (0, _extends3.default)({}, state, {
    selectedVehicleMobile: selectedVehicleMobile,
    similarVehiclePageMobile: similarVehiclePageMobile,
    similarVehiclePage: similarVehiclePage,
    vehiclesInView: vehiclesInView
  });
}

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.GET_SIMILAR_VEHICLES_SUCCESS:
      {
        var similarVehicles = action.payload.similarVehicles;

        var similarVehiclePageTotal = Math.ceil(similarVehicles.length / maxVehiclesPerPage);
        var vehiclesInView = similarVehicles.slice(0, 3);
        return (0, _extends3.default)({}, state, {
          similarVehicles: similarVehicles,
          vehiclesInView: vehiclesInView,
          similarVehiclePageTotal: similarVehiclePageTotal,
          similarVehiclePageTotalMobile: similarVehicles.length
        });
      }
    case constants.SIMILAR_VEHICLES_NEXT:
      {
        var similarVehiclePage = state.similarVehiclePage;

        similarVehiclePage = similarVehiclePage === state.similarVehiclePageTotal ? 1 : similarVehiclePage += 1;
        var _vehiclesInView = defineVehiclesInView(state, similarVehiclePage);
        return (0, _extends3.default)({}, state, {
          vehiclesInView: _vehiclesInView,
          similarVehiclePage: similarVehiclePage
        });
      }
    case constants.SIMILAR_VEHICLES_PREV:
      {
        var _similarVehiclePage = state.similarVehiclePage;

        _similarVehiclePage = _similarVehiclePage === 1 ? state.similarVehiclePageTotal : _similarVehiclePage -= 1;
        var _vehiclesInView2 = defineVehiclesInView(state, _similarVehiclePage);
        return (0, _extends3.default)({}, state, {
          vehiclesInView: _vehiclesInView2,
          similarVehiclePage: _similarVehiclePage
        });
      }
    case constants.SIMILAR_VEHICLES_NEXT_MOBILE:
      {
        return similarVehiclesNextMobile(state);
      }

    case constants.SIMILAR_VEHICLES_PREV_MOBILE:
      {
        return similarVehiclesPrevMobile(state);
      }
    default:
      return state;
  }
}