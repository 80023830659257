'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIndexContent = exports.searchable = exports.icon = exports.description = exports.availableToAdministration = exports.id = exports.name = exports.defaultConfig = exports.config = exports.component = undefined;

var _ImageTitle = require('./ImageTitle');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ImageTitle).default;
  }
});

var _config = require('./config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _defaultConfig = require('./defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});

var _Icon = require('./Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'Image title';
var id = exports.id = 'ImageTitle';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Full screen image with header overlay';
var icon = exports.icon = _Icon2.default;
var searchable = exports.searchable = true;
var getIndexContent = exports.getIndexContent = function getIndexContent(_ref) {
  var _ref$config = _ref.config;
  _ref$config = _ref$config === undefined ? {} : _ref$config;
  var _ref$config$translati = _ref$config.translations,
      translations = _ref$config$translati === undefined ? {} : _ref$config$translati;
  return [{
    title: translations.headlineText,
    content: translations.bodyText
  }];
};