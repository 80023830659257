'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      imageLabel = _ref$config.translations.imageLabel,
      src = _ref$config.image.src,
      imageHeight = _ref$config.imageHeight;
  return _react2.default.createElement(
    _components.Wrapper,
    null,
    _react2.default.createElement(_components.Image, (0, _extends3.default)({ src: src, imageHeight: imageHeight }, { role: 'img', 'aria-label': imageLabel }))
  );
};