'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n  '], ['\n    ', ';\n  ']);

exports.default = function (_ref) {
  var config = _ref.config;

  return _react2.default.createElement(Push, {
    id: config.id || null,
    height: config.height,
    mobileHeight: config.mobileHeight,
    background: config.background
  });
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Push = _styledComponents2.default.div.withConfig({
  displayName: 'Push',
  componentId: 'sc-19783fr-0'
})(['position:relative;height:', 'px;display:block;width:100%;background:', ';', ';@media print{display:none;}&:focus{outline:none;}'], function (props) {
  return props.height;
}, function (props) {
  return props.background;
}, _theme2.default.max.large(_templateObject, function (props) {
  return props.mobileHeight && 'height: ' + props.mobileHeight + 'px;';
}));