'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TestDrive;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _chevronLeft = require('react-icons/lib/io/chevron-left');

var _chevronLeft2 = _interopRequireDefault(_chevronLeft);

var _TestDriveDateAndTime = require('./TestDriveDateAndTime');

var _TestDriveDateAndTime2 = _interopRequireDefault(_TestDriveDateAndTime);

var _TestDriveLogin = require('./TestDriveLogin');

var _TestDriveLogin2 = _interopRequireDefault(_TestDriveLogin);

var _TestDriveUserDetails = require('./TestDriveUserDetails');

var _TestDriveUserDetails2 = _interopRequireDefault(_TestDriveUserDetails);

var _TestDriveSummary = require('./TestDriveSummary');

var _TestDriveSummary2 = _interopRequireDefault(_TestDriveSummary);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveContainer'
}).withConfig({
  displayName: 'TestDrive__Container',
  componentId: 'sc-a72lyb-0'
})(['width:100%;height:auto;']);


var HeaderBar = _styledComponents2.default.div.withConfig({
  displayName: 'TestDrive__HeaderBar',
  componentId: 'sc-a72lyb-1'
})(['text-transform:uppercase;text-align:center;vertical-align:middle;border-bottom:1px solid #d8d8d8;font-weight:600;display:flex;align-items:center;']);

var HeaderWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'HeaderWrapper'
}).withConfig({
  displayName: 'TestDrive__HeaderWrapper',
  componentId: 'sc-a72lyb-2'
})(['width:100%;margin-left:', ';'], function (props) {
  return props.hidden ? 'none' : '-40px';
});

var TestDriveHeader = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveHeader'
}).withConfig({
  displayName: 'TestDrive__TestDriveHeader',
  componentId: 'sc-a72lyb-3'
})(['font-family:', ';font-size:20px;margin:10px 0;color:#000;'], function (props) {
  return props.font && props.font.value;
});

var TestDriveSubHeader = _styledComponents2.default.div.withConfig({
  displayName: 'TestDrive__TestDriveSubHeader',
  componentId: 'sc-a72lyb-4'
})(['font-size:16px;margin-bottom:10px;color:#474747;']);

var BackButton = _styledComponents2.default.div.withConfig({
  displayName: 'BackButton'
}).withConfig({
  displayName: 'TestDrive__BackButton',
  componentId: 'sc-a72lyb-5'
})(['display:', ';align-items:center;cursor:pointer;margin-left:20px;'], function (props) {
  return props.hidden ? 'none' : 'flex';
});

var SubHeaderDictionary = {
  DateAndTime: function DateAndTime(_ref) {
    var translations = _ref.translations;
    return translations.testDriveSubHeaderDateAndTime;
  },
  Login: function Login(_ref2) {
    var translations = _ref2.translations;
    return translations.testDriveSubHeaderLogin;
  },
  UserDetails: function UserDetails(_ref3) {
    var translations = _ref3.translations;
    return translations.testDriveSubHeaderUserDetails;
  },
  Summary: function Summary(_ref4) {
    var translations = _ref4.translations;
    return translations.testDriveSubHeaderSummary;
  }
};

var getOnClick = function getOnClick(props) {
  if (props.context === 'DateAndTime') return function () {
    return props.navigateToVdp();
  };
  if (props.context === 'Login') return function () {
    return props.goBack('DateAndTime');
  };
  if (props.context === 'UserDetails') return function () {
    return props.goBack('Login');
  };
  return function () {};
};

var contextRenderer = {
  DateAndTime: function DateAndTime(props) {
    return _react2.default.createElement(_TestDriveDateAndTime2.default, {
      translations: props.translations,
      open: props.open,
      bookingForm: props.bookingForm,
      bookingFormError: props.bookingFormError,
      selected: props.selected,
      onSelectDate: props.setBookingFormValue,
      toggleCalendar: props.toggleCalendar,
      testDriveConfig: props.testDriveConfig,
      selectDateModeOptions: props.selectDateModeOptions,
      setSelectRadioGroupMode: props.setSelectRadioGroupMode,
      selectTimeModeOptions: props.selectTimeModeOptions,
      disableCalendar: props.disableCalendar,
      confirmDateAndTime: props.confirmDateAndTime,
      sendTestDriveLead: props.sendTestDriveLead,
      selectedDateMode: props.selectedDateMode,
      selectedTimeMode: props.selectedTimeMode,
      interestedClicked: props.interestedClicked,
      userIsLoggedIn: props.userIsLoggedIn,
      somethingWentWrong: props.somethingWentWrong,
      globalStyling: props.globalStyling
    });
  },
  Login: function Login(props) {
    return _react2.default.createElement(_TestDriveLogin2.default, {
      translations: props.translations,
      onChange: props.setBookingFormValue,
      loginAndConfirm: props.loginAndConfirm,
      continueAsGuest: props.continueAsGuest,
      bookingForm: props.bookingForm,
      bookingFormError: props.bookingFormError,
      loginFailed: props.loginFailed,
      globalStyling: props.globalStyling
    });
  },
  UserDetails: function UserDetails(props) {
    return _react2.default.createElement(_TestDriveUserDetails2.default, {
      translations: props.translations,
      bookingForm: props.bookingForm,
      bookingFormError: props.bookingFormError,
      onChange: props.setBookingFormValue,
      onTitleChange: props.onTitleChange,
      confirmRequest: props.confirmRequest,
      testDriveConfig: props.testDriveConfig,
      goBack: props.goBack,
      globalStyling: props.globalStyling
    });
  },
  Summary: function Summary(props) {
    return _react2.default.createElement(_TestDriveSummary2.default, {
      translations: props.translations,
      bookingForm: props.bookingForm,
      bookingFormError: props.bookingFormError,
      testDriveConfig: props.testDriveConfig,
      contactUs: props.contactUs,
      userIsLoggedIn: props.userIsLoggedIn,
      userCreated: props.userCreated,
      newUserEmail: props.newUserEmail,
      goToMyAccount: props.goToMyAccount,
      onVisitUs: props.onVisitUs,
      globalStyling: props.globalStyling,
      navigateToVdp: props.navigateToVdp
    });
  }
};

function TestDrive(props) {
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      HeaderBar,
      null,
      _react2.default.createElement(
        'div',
        { style: { width: '40px', zIndex: '10' } },
        _react2.default.createElement(
          BackButton,
          {
            onClick: getOnClick(props),
            hidden: ['Summary'].includes(props.context)
          },
          _react2.default.createElement(_chevronLeft2.default, null)
        )
      ),
      _react2.default.createElement(
        HeaderWrapper,
        null,
        _react2.default.createElement(
          TestDriveHeader,
          { font: props.globalStyling.fonts.primaryFont },
          props.translations.testDriveHeader
        ),
        _react2.default.createElement(
          TestDriveSubHeader,
          null,
          SubHeaderDictionary[props.context](props)
        )
      )
    ),
    contextRenderer[props.context](props)
  );
}