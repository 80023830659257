'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    height: auto;\n    flex-direction: column;\n    align-items: flex-start;\n  '], ['\n    height: auto;\n    flex-direction: column;\n    align-items: flex-start;\n  ']);

exports.default = Footer;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FooterNavigation = require('./FooterNavigation');

var _FooterNavigation2 = _interopRequireDefault(_FooterNavigation);

var _SocialMediaLinks = require('../../../components/SocialMediaLinks');

var _SocialMediaLinks2 = _interopRequireDefault(_SocialMediaLinks);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _footerTypes = require('../../../types/RollsRoyce/footerTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterContainer',
  componentId: 'sc-pl505s-0'
})(['border-top:1px solid #3b3b3b;border-bottom:1px solid #3b3b3b;display:flex;justify-content:space-between;align-items:center;margin-bottom:50px;', ';'], _theme2.default.max.large(_templateObject));

var FooterWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterWrapper',
  componentId: 'sc-pl505s-1'
})(['border-top:1px solid #151515;background-color:#151515;width:100%;max-height:100%;display:flex;box-sizing:border-box;@media print{display:none;}']);

var CenterLogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__CenterLogoContainer',
  componentId: 'sc-pl505s-2'
})(['display:flex;align-items:center;position:absolute;']);

var BrandLogo = _styledComponents2.default.img.withConfig({
  displayName: 'Footer__BrandLogo',
  componentId: 'sc-pl505s-3'
})(['', ';height:30.31px;width:120px;'], function (_ref) {
  var src = _ref.src;
  return src && 'cursor: pointer;';
});

var SectionWithMidLogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__SectionWithMidLogoContainer',
  componentId: 'sc-pl505s-4'
})(['display:flex;flex-direction:column;align-items:center;padding-top:97px;height:68px;']);

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__Wrapper',
  componentId: 'sc-pl505s-5'
})(['min-width:1px;margin:0px auto;width:1200px;']);

function Footer(_ref2) {
  var config = _ref2.config;

  return _react2.default.createElement(
    FooterWrapper,
    { 'data-nosnippet': true },
    _react2.default.createElement(
      Wrapper,
      null,
      _react2.default.createElement(
        SectionWithMidLogoContainer,
        null,
        _react2.default.createElement(
          CenterLogoContainer,
          null,
          _react2.default.createElement(BrandLogo, {
            'data-cy': 'footer-logo',
            src: config.brandLogoUrl || '',
            alt: 'logo'
          })
        )
      ),
      _react2.default.createElement(
        FooterContainer,
        null,
        _react2.default.createElement(_FooterNavigation2.default, {
          items: config.items.map(function (value) {
            return (0, _extends3.default)({}, value, {
              label: config.translations[value.id]
            });
          }).filter(function (item) {
            return item.link;
          }),
          itemsConfig: config.itemsConfig
        }),
        _react2.default.createElement(_SocialMediaLinks2.default, (0, _extends3.default)({}, config, {
          socialIconsColour: '#151515',
          preview: false,
          facebookIconLink: config.facebookIconLink,
          twitterIconLink: config.twitterIconLink,
          youtubeIconLink: config.youtubeIconLink,
          instagramIconLink: config.instagramIconLink,
          linkedinIconLink: config.linkedinIconLink,
          urlFacebook: config.urlFacebook,
          urlTwitter: config.urlTwitter,
          urlYouTube: config.urlYouTube,
          urlInstagram: config.urlInstagram,
          urlLinkedIn: config.urlLinkedIn,
          translations: config.translations,
          iconWidth: 26,
          iconHeight: 26
        }))
      )
    )
  );
}