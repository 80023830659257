'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return {
    items: [{
      translations: {
        header: 'Placeholder',
        body: 'placeholder',
        topLink: 'Placeholder'
      },
      align: 'left',
      topLink: {
        url: 'search',
        label: 'search',
        on: true
      },
      midLink: {
        on: false
      },
      bottomLink: {
        on: false
      },
      mediaContent: {
        label: 'placeholder',
        value: 'https://res.cloudinary.com/motortrak/image/upload/v1537798827/default-image_btd6xy.png'
      }
    }],
    displaySlide: 0,
    slideContentStyle: {
      label: 'slideContentOne',
      value: 'slideContentOne'
    },
    paginationStyle: {
      label: 'paginationOne',
      value: 'paginationOne'
    },
    slideBodyFont: {
      kerning: 1,
      fontSize: 14,
      transform: 'uppercase'
    },
    headerBGColour: {
      label: '#000000',
      value: '#000000'
    },
    bodyBGColour: {
      label: '#000000',
      value: '#000000'
    },
    paginationActiveColour: {
      label: '#000000',
      value: '#000000'
    },
    paginationInactiveColour: {
      label: '#000000',
      value: '#000000'
    },
    slideButtonFont: {
      kerning: 1,
      fontSize: 13,
      transform: 'uppercase'
    },
    slideHeaderFont: {
      kerning: 1,
      fontSize: 20,
      transform: 'uppercase'
    },
    slidesLoopEnabled: true,
    backgroundOpacity: 0.8,
    slideTransitionSpeed: 20
  };
};