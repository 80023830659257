'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Heart = require('../../components/JaguarIcons/Global/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

var _Global = require('../../components/Global');

var _Shared = require('./Shared');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ShortlistRow = function ShortlistRow(_ref) {
  var comparedVehicles = _ref.comparedVehicles,
      shortlistVehicle = _ref.shortlistVehicle,
      getShortListedIconColour = _ref.getShortListedIconColour,
      translations = _ref.translations,
      globalStyling = _ref.globalStyling,
      font = _ref.font,
      optionalIconFont = _ref.optionalIconFont,
      optionalIconColourFont = _ref.optionalIconColourFont,
      optionalHeadingThreeFont = _ref.optionalHeadingThreeFont,
      hideBottomBorder = _ref.hideBottomBorder;
  return _react2.default.createElement(
    _Shared.TableRow,
    {
      hideBottomBorder: hideBottomBorder,
      fontSize: font && font.fontSize
    },
    _react2.default.createElement('td', { style: { border: 'none' } }),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        _Shared.TableData,
        { key: '' + vehicle.id, hideBottomBorder: hideBottomBorder },
        _react2.default.createElement(
          'div',
          {
            style: { display: 'flex', justifyContent: 'center', height: '40px' }
          },
          _react2.default.createElement(
            _Shared.IconButton,
            { onClick: function onClick() {
                return shortlistVehicle(vehicle);
              } },
            _react2.default.createElement(_Heart2.default, {
              width: (optionalIconColourFont && optionalIconColourFont.fontSize) + 'em' || '2em',
              height: (optionalIconColourFont && optionalIconColourFont.fontSize) + 'em' || '2em',
              colour: getShortListedIconColour(vehicle.id)
            }),
            _react2.default.createElement(
              _Global.HeadingThree,
              {
                styleOverride: function styleOverride() {
                  return '\n                ' + (0, _Global.fontStyleOverride)(optionalHeadingThreeFont) + '\n                padding-left: ' + (optionalIconColourFont ? '5px' : '10px') + ';\n                font-size: ' + (optionalHeadingThreeFont ? optionalHeadingThreeFont.fontSize + 'px;' : '16px') + ';\n                ' + (optionalIconFont && 'color: ' + (globalStyling && globalStyling.colours.primaryBrandColour.value) + ';') + '\n              ';
                }
              },
              translations.compareVehiclesSaveText
            )
          )
        )
      );
    })
  );
};

exports.default = ShortlistRow;