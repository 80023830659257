'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Tick = require('../../components/JaguarIcons/Global/Tick');

var _Tick2 = _interopRequireDefault(_Tick);

var _Close = require('../../components/JaguarIcons/Global/Close');

var _Close2 = _interopRequireDefault(_Close);

var _Shared = require('./Shared');

var _ComparedVehicleType = require('../../types/ComparedVehicleType');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.button.withConfig({
  displayName: 'IconWrapper'
}).withConfig({
  displayName: 'EquipmentMetaDataRow__IconWrapper',
  componentId: 'sc-1r5ki7j-0'
})(['background:none;border:none;vertical-align:middle;&:focus{outline:none;}']);

var CloseIconBlock = function CloseIconBlock(iconColour) {
  return _react2.default.createElement(
    IconWrapper,
    null,
    _react2.default.createElement(_Close2.default, {
      width: '1.5em',
      height: '1.3125em',
      colour: iconColour || '#000000'
    })
  );
};

var TickIconBlock = function TickIconBlock(iconColour) {
  return _react2.default.createElement(
    IconWrapper,
    null,
    _react2.default.createElement(_Tick2.default, {
      width: '1.5em',
      height: '1.3125em',
      colour: iconColour || '#FFFFFF',
      fill: iconColour || '#FFFFFF'
    })
  );
};

var getEquipmentMetaDataIcon = function getEquipmentMetaDataIcon(vehicle, label, iconColour) {
  var uppercaseLabel = label.toUpperCase();
  var filteredOtherCategory = vehicle.otherEquipment && vehicle.otherEquipment.filter(function (equipment) {
    return equipment.category.toUpperCase() === uppercaseLabel;
  }) || [];
  var filteredOtherInitialCategory = vehicle.otherEquipment && vehicle.otherEquipment[0] && vehicle.otherEquipment[0].equipment.filter(function (equipment) {
    return equipment === label;
  }) || [];
  var filteredEquipmentData = vehicle.equipmentMetaData && vehicle.equipmentMetaData.filter(function (equipment) {
    return equipment.toUpperCase() === uppercaseLabel;
  }) || [];
  var exists = [].concat((0, _toConsumableArray3.default)(filteredEquipmentData), (0, _toConsumableArray3.default)(filteredOtherInitialCategory), (0, _toConsumableArray3.default)(filteredOtherCategory)).length > 0;
  return exists ? TickIconBlock(iconColour) : CloseIconBlock(iconColour);
};

var EquipmentMetaDataRow = function EquipmentMetaDataRow(_ref) {
  var label = _ref.label,
      comparedVehicles = _ref.comparedVehicles,
      font = _ref.font,
      optionalIconColourFont = _ref.optionalIconColourFont;
  return _react2.default.createElement(
    _Shared.TableRow,
    { fontSize: font && font.fontSize },
    _react2.default.createElement(
      _Shared.TableData,
      null,
      _react2.default.createElement(_Shared.RenderRow, { content: label, alignment: 'left', font: font })
    ),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        _Shared.TableData,
        { key: '' + vehicle.id },
        _react2.default.createElement(_Shared.RenderRow, {
          content: getEquipmentMetaDataIcon(vehicle, label, optionalIconColourFont && optionalIconColourFont.colour && optionalIconColourFont.colour.value || '#000000'),
          font: font
        })
      );
    })
  );
};

EquipmentMetaDataRow.defaultProps = {
  borderBottom: false
};

exports.default = EquipmentMetaDataRow;