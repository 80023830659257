'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _CertifiedProgramme = require('../../../components/Bentley/CertifiedProgramme');

var _CertifiedProgramme2 = _interopRequireDefault(_CertifiedProgramme);

var _certifiedProgrammeTypes = require('../../../types/Bentley/certifiedProgrammeTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CertifiedProgramme = function CertifiedProgramme(_ref) {
  var config = _ref.config;

  return _react2.default.createElement(_CertifiedProgramme2.default, { config: config });
};

exports.default = CertifiedProgramme;