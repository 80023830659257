'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = GridDisabled;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function GridDisabled(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 45 45' },
      _react2.default.createElement(
        'g',
        { fill: 'none', fillRule: 'evenodd' },
        _react2.default.createElement('path', { fill: background, d: 'M0 0h45v45H0z' }),
        _react2.default.createElement('path', {
          fill: colour,
          d: 'M11 11h6v6h-6zM11 19h6v6h-6zM11 27h6v6h-6zM19 11h6v6h-6zM19 19h6v6h-6zM19 27h6v6h-6zM27 11h6v6h-6zM27 19h6v6h-6zM27 27h6v6h-6z'
        })
      )
    )
  );
}

GridDisabled.defaultProps = {
  colour: '#ACACAC',
  width: null,
  height: null,
  background: '#F0F0F0'
};