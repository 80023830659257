'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_SEARCH';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_INVENTORY_GROUP', 'GET_INVENTORY_GROUP_SUCCESS', 'GET_MODEL_GROUPS_AND_INVENTORY', 'GET_MODEL_GROUPS_AND_INVENTORY_SUCCESS', 'GET_FINANCE_SUCCESS', 'RESET_FILTERS_FAILURE', 'INIT']);

var id = exports.id = 'Search';

// @TODO: The models have accents and trailing white spaces in some cases, the modelsGroups do not
// so need to normalize the string to perform lookups
// we need to get the ModelGroups end point to return proper model name data :-(
var normalize = function normalize(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();
};
var modelLookup = function modelLookup(modelGroups, model) {
  return modelGroups.find(function (m) {
    return normalize(m.name.toLowerCase()) === normalize(model.toLowerCase());
  }) || {};
};

var transform = function transform(lookupModels, placeholdingImage) {
  return function (_ref) {
    var model = _ref.model,
        modelDisplay = _ref.modelDisplay,
        count = _ref.count,
        minimumPrice = _ref.minimumPrice,
        finance = _ref.finance,
        financeLoaded = _ref.financeLoaded;

    var m = modelLookup(lookupModels, model);
    return {
      model: model,
      modelDisplay: modelDisplay,
      count: count,
      minimumPrice: minimumPrice,
      finance: finance,
      financeLoaded: financeLoaded,
      position: m.position || 0,
      imageUrl: m.imageUrl || placeholdingImage,
      modelSubTitle: m.modelSubTitle
    };
  };
};

var mergeAndSortInventoryGroup = function mergeAndSortInventoryGroup(inventoryGroup, modelGroups, placeholdingImage) {
  return inventoryGroup.map(transform(modelGroups, placeholdingImage)).sort(function (a, b) {
    return a.position - b.position;
  });
};

var actions = exports.actions = {
  getInventoryGroup: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY_GROUP, id, function (payload) {
    return payload;
  }),
  getInventoryGroupSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY_GROUP_SUCCESS, id, function (config) {
    return config;
  }),
  getModelGroupsAndInventory: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_MODEL_GROUPS_AND_INVENTORY, id, function (payload) {
    return payload;
  }),
  getModelGroupsAndInventorySuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_MODEL_GROUPS_AND_INVENTORY_SUCCESS, id, function (payload) {
    return payload;
  }),
  getFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  }),
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id)
};

var replaceIfExists = function replaceIfExists(groups, group) {
  return [].concat((0, _toConsumableArray3.default)(groups.map(function (g) {
    return g.model === group.model ? group : g;
  })));
};

var initialState = exports.initialState = {
  searchConfig: {
    inventoryGroup: [],
    modelGroups: [],
    placeholdingImage: ''
  }
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.GET_INVENTORY_GROUP_SUCCESS:
      {
        var _action$payload = action.payload,
            _inventoryGroup = _action$payload.inventoryGroup,
            _placeholdingImage = _action$payload.placeholdingImage;


        var mergedInventoryGroup = mergeAndSortInventoryGroup(_inventoryGroup, state.searchConfig.modelGroups, _placeholdingImage);

        return (0, _extends3.default)({}, state, {
          searchConfig: (0, _extends3.default)({}, state.searchConfig, {
            inventoryGroup: mergedInventoryGroup
          })
        });
      }
    case constants.GET_MODEL_GROUPS_AND_INVENTORY_SUCCESS:
      {
        var _action$payload2 = action.payload,
            _modelGroups = _action$payload2.modelGroups,
            _inventoryGroup2 = _action$payload2.inventoryGroup,
            _placeholdingImage2 = _action$payload2.placeholdingImage,
            modelSubTitle = _action$payload2.modelSubTitle;

        var _mergedInventoryGroup = mergeAndSortInventoryGroup(_inventoryGroup2, _modelGroups, _placeholdingImage2, modelSubTitle);

        return (0, _extends3.default)({}, state, {
          searchConfig: (0, _extends3.default)({}, state.searchConfig, {
            modelGroups: _modelGroups,
            inventoryGroup: _mergedInventoryGroup
          })
        });
      }
    case constants.GET_INVENTORY_GROUP_FAILURE:
    case constants.GET_MODEL_GROUPS_AND_INVENTORY_FAILURE:
    case constants.GET_FINANCE_SUCCESS:
      {
        var _action$payload3 = action.payload,
            group = _action$payload3.group,
            _placeholdingImage3 = _action$payload3.placeholdingImage;

        var _inventoryGroup3 = replaceIfExists(state.searchConfig.inventoryGroup, group);
        var _mergedInventoryGroup2 = mergeAndSortInventoryGroup(_inventoryGroup3, state.searchConfig.modelGroups, _placeholdingImage3);

        return (0, _extends3.default)({}, state, {
          searchConfig: (0, _extends3.default)({}, state.searchConfig, {
            inventoryGroup: _mergedInventoryGroup2
          })
        });
      }
    default:
      return state;
  }
}