'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RangeHeading;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _numbers = require('../../shared/localisation/numbers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledRangeHeading = _styledComponents2.default.h4.withConfig({
  displayName: 'RangeHeading__StyledRangeHeading',
  componentId: 'sc-12ygxbl-0'
})(['margin:0 0 20px 0;display:flex;justify-content:space-between;font-weight:normal;font-size:16px;color:#444;']);


var WithSpaceRight = _styledComponents2.default.span.withConfig({
  displayName: 'RangeHeading__WithSpaceRight',
  componentId: 'sc-12ygxbl-1'
})(['padding-right:5px;']);

function RangeHeading(_ref) {
  var heading = _ref.heading,
      _ref$range = _ref.range,
      range = _ref$range === undefined ? { min: 0, max: 0 } : _ref$range,
      locale = _ref.locale,
      unit = _ref.unit,
      id = _ref.id;

  return _react2.default.createElement(
    StyledRangeHeading,
    null,
    _react2.default.createElement(
      'span',
      null,
      heading
    ),
    _react2.default.createElement(
      'span',
      { 'data-cy': 'range-values' },
      _react2.default.createElement(
        WithSpaceRight,
        null,
        id === 'priceRange' ? (0, _numbers.localiseCurrency)(range.min, locale, '', 0) : (0, _numbers.localiseNumber)(range.min, locale)
      ),
      _react2.default.createElement(
        WithSpaceRight,
        null,
        '-'
      ),
      _react2.default.createElement(
        WithSpaceRight,
        null,
        id === 'priceRange' ? (0, _numbers.localiseCurrency)(range.max, locale, '', 0) : (0, _numbers.localiseNumber)(range.max, locale)
      ),
      id !== 'priceRange' ? _react2.default.createElement(
        WithSpaceRight,
        null,
        unit
      ) : ''
    )
  );
}

RangeHeading.defaultProps = {
  heading: '',
  unit: ''
};