'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.useDealerLocator = useDealerLocator;

var _react = require('react');

var _reactRedux = require('react-redux');

var _markerclustererplus = require('@google/markerclustererplus');

var _markerclustererplus2 = _interopRequireDefault(_markerclustererplus);

var _InfoWindow = require('./InfoWindow');

var _InfoWindow2 = _interopRequireDefault(_InfoWindow);

var _reducer = require('./reducer');

var _router = require('../../actions/router');

var _DealerLocator = require('./DealerLocator');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useDealerLocator(dispatch, config, globalStyling) {
  var flipCoordinates = config.flipCoordinates,
      defaultCenterPoint = config.defaultCenterPoint,
      defaultZoomLevel = config.defaultZoomLevel,
      translations = config.translations,
      pinIcon = config.pinIcon,
      selectedPinIcon = config.selectedPinIcon,
      clusterIcon = config.clusterIcon,
      clusterFontColour = config.clusterFontColour;

  var ref = (0, _react.useRef)();
  var map = (0, _react.useRef)();
  var markers = (0, _react.useRef)([]);
  var activeMarker = (0, _react.useRef)();
  var activeWindow = (0, _react.useRef)();
  var zoomThreshold = 8;

  var _useState = (0, _react.useState)(defaultZoomLevel),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      currentZoomLevel = _useState2[0],
      setZoomLevel = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      locationSubmitted = _useState4[0],
      setLocationSubmitted = _useState4[1];

  var countryCode = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory.searchCountryCode;
  });
  var location = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences.location;
  });

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.dealerLocator;
  }),
      dealers = _useSelector.dealers,
      status = _useSelector.status;

  var buttonStyle = globalStyling.uiElements.secondaryButton.buttonStyle;


  var applyCoordinateFlip = function applyCoordinateFlip(coordinates) {
    return flipCoordinates ? { lat: coordinates[1], lng: coordinates[0] } : { lat: coordinates[0], lng: coordinates[1] };
  };

  // initialise map
  (0, _react.useEffect)(function () {
    map.current = new window.google.maps.Map(ref.current, {
      zoom: defaultZoomLevel,
      center: defaultCenterPoint,
      gestureHandling: 'none'
    });
    var zoomListener = map.current.addListener('zoom_changed', function () {
      var newZoomLevel = map.current.getZoom();
      setZoomLevel(newZoomLevel);
    });
    return function () {
      window.google.maps.event.removeListener(zoomListener);
    };
  }, [config]);

  // compare zoom level to threshold before fetching all dealers
  (0, _react.useEffect)(function () {
    if (currentZoomLevel <= zoomThreshold && locationSubmitted) {
      dispatch(_reducer.actions.getAllDealers());
      setLocationSubmitted(false);
    }
  }, [currentZoomLevel]);

  (0, _react.useEffect)(function () {
    dispatch(_reducer.actions.getAllDealers());
  }, []);

  /*
  when dealers changes, if there are results then map over them.
  - if markers already exist on map then remove them (this was causing rendering issues).
  - reset markers array.
  - generate a new bounds object
  - for each dealer, generate an infowindow and a marker, adding onclick and close
  listeners to each instance of them.
  - extend the bounds of the map after each dealer has been created.
  - generate the markercluster.
  - if we have submitted our location, apply bounds to the map.
  */
  (0, _react.useEffect)(function () {
    if (dealers.length > 0) {
      markers.current.forEach(function (m) {
        m.setOptions({ map: null, visible: false });
      });
      markers.current = [];
      var bounds = new window.google.maps.LatLngBounds();

      dealers.forEach(function (d, index) {
        var infoWindow = new window.google.maps.InfoWindow({
          content: (0, _InfoWindow2.default)({
            dealer: d,
            translations: translations,
            buttonStyle: buttonStyle,
            onButtonClick: function onButtonClick() {
              return dispatch(_router.actions.navigate('/searchresults?dealerId=' + d.id));
            }
          })
        });
        var marker = new window.google.maps.Marker({
          position: applyCoordinateFlip(d.location.coordinates),
          map: map.current,
          icon: index === activeMarker && activeMarker.current && activeMarker.current.id ? selectedPinIcon.src : pinIcon.src,
          id: index
        });
        infoWindow.addListener('closeclick', function () {
          marker.setIcon(pinIcon.src);
          activeMarker.current = null;
        });
        marker.addListener('click', function () {
          if (activeMarker && activeMarker.current) {
            activeMarker.current.setIcon(pinIcon.src);
          }
          if (activeWindow && activeWindow.current) {
            activeWindow.current.close();
          }
          marker.setIcon(selectedPinIcon.src);
          activeMarker.current = marker;
          activeWindow.current = infoWindow;
          infoWindow.open(map.current, marker);
        });
        markers.current = [].concat((0, _toConsumableArray3.default)(markers.current), [marker]);
        bounds.extend(applyCoordinateFlip(d.location.coordinates));
      });
      // eslint-disable-next-line
      var markerCluster = new _markerclustererplus2.default(map.current, markers.current, {
        styles: [{
          url: clusterIcon.src,
          height: 26,
          width: 26,
          textColor: clusterFontColour ? clusterFontColour.value : globalStyling.colours.primaryColour.value
        }]
      });
      if (locationSubmitted) {
        map.current.fitBounds(bounds);
        map.current.panToBounds(bounds);
      }
    }
  }, [dealers, config]);

  var locationCallback = function locationCallback(lat, long) {
    dispatch(_reducer.actions.getClosestDealers({ latitude: lat, longitude: long }));
    setLocationSubmitted(true);
  };

  return {
    countryCode: countryCode,
    location: location,
    ref: ref,
    locationCallback: locationCallback,
    dealers: dealers,
    status: status
  };
}