'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Templates = require('./Templates');

var _Templates2 = _interopRequireDefault(_Templates);

var _useSummaryPost = require('./useSummaryPost');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var config = _ref.config,
      _ref$config = _ref.config,
      postId = _ref$config.postId,
      template = _ref$config.template;

  var postData = (0, _useSummaryPost.useSummaryPost)(postId);
  var Template = _Templates2.default[template];

  var podConfig = (0, _extends3.default)({}, config, postData, {
    translations: (0, _extends3.default)({}, config.translations, postData && (0, _extends3.default)({}, postData.translations))
  });

  return _react2.default.createElement(Template, { config: podConfig });
};