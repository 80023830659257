'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Manual;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Manual(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 30 30' },
      _react2.default.createElement('path', { d: 'M22.364 15.636V5.818a.818.818 0 1 1 1.636 0v10.637a.818.818 0 0 1-.818.818h-7.364v9.818a.818.818 0 0 1-1.636 0v-9.818H7.636v9.818a.818.818 0 0 1-1.636 0V5.818a.818.818 0 0 1 1.636 0v9.818h6.546V5.818a.818.818 0 0 1 1.636 0v9.818h6.546z' })
    )
  );
}

Manual.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};