'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SpinnerWrapper;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _globalStyling = require('../../types/globalStyling');

var _Spinner = require('./Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SpinnerSection = _styledComponents2.default.div.withConfig({
  displayName: 'SpinnerWrapper__SpinnerSection',
  componentId: 'sc-rt25jm-0'
})(['display:flex;flex-grow:1;align-items:center;justify-content:center;flex-direction:column;width:100%;min-height:', ';', ';'], function (_ref) {
  var minHeight = _ref.minHeight;
  return minHeight;
}, function (_ref2) {
  var backgroundColor = _ref2.backgroundColor;
  return backgroundColor && 'background-color: ' + backgroundColor;
});

function SpinnerWrapper(_ref3) {
  var loaded = _ref3.loaded,
      globalStyling = _ref3.globalStyling,
      label = _ref3.label,
      _ref3$minHeight = _ref3.minHeight,
      minHeight = _ref3$minHeight === undefined ? '100px' : _ref3$minHeight,
      backgroundColor = _ref3.backgroundColor,
      children = _ref3.children;

  return loaded ? children() : _react2.default.createElement(
    SpinnerSection,
    { backgroundColor: backgroundColor, minHeight: minHeight },
    _react2.default.createElement(_Spinner2.default, { colour: globalStyling.colours.primaryBrandColour }),
    label
  );
}

SpinnerWrapper.defaultProps = {
  label: ''
};