'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RadioButtonActive;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function RadioButtonActive(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      {
        viewBox: '0 0 25 25',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
        width: '1em',
        height: '1em'
      },
      _react2.default.createElement(
        'defs',
        null,
        _react2.default.createElement('circle', { id: 'i', cx: 12.5, cy: 12.5, r: 12.5 })
      ),
      _react2.default.createElement(
        'g',
        { fill: 'none', fillRule: 'evenodd' },
        _react2.default.createElement('use', { fill: '#FFF', xlinkHref: '#a' }),
        _react2.default.createElement('circle', { stroke: colour, cx: 12.5, cy: 12.5, r: 12 }),
        _react2.default.createElement('circle', { fill: colour, cx: 12.5, cy: 12.5, r: 6.875 })
      )
    )
  );
}

RadioButtonActive.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};