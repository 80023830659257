'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isCentralRetailer;
// add CentralRetailer's id here

var CentralRetailer_IDs = ['130267'];

function isCentralRetailer(id) {
  // check if the id is in the list of CentralRetailer's id
  return CentralRetailer_IDs.includes(id);
}