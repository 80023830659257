'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 16px repeat(4, 1fr 16px) 1fr 16px;\n  '], ['\n    grid-template-columns: 16px repeat(4, 1fr 16px) 1fr 16px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    grid-column: 2 / span 9;\n  '], ['\n    grid-column: 2 / span 9;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['    \n    grid-column: 5/span 3;\n  '], ['    \n    grid-column: 5/span 3;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 12px;\n    font-weight: 500;\n  '], ['\n    font-size: 12px;\n    font-weight: 500;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    grid-column: 2/span 2;\n  '], ['\n    grid-column: 2/span 2;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    grid-column: 8/span 3;\n    '], ['\n    grid-column: 8/span 3;\n    ']);

exports.default = RollsRoyceHeader;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _rollsRoyceScrollTransition = require('../../../helpers/rollsRoyceScrollTransition');

var _rollsRoyceScrollTransition2 = _interopRequireDefault(_rollsRoyceScrollTransition);

var _useCheckIsMobileScreenRollsRoyce = require('../../../hooks/useCheckIsMobileScreenRollsRoyce');

var _useCheckIsMobileScreenRollsRoyce2 = _interopRequireDefault(_useCheckIsMobileScreenRollsRoyce);

var _MenuIcon = require('./MenuIcon');

var _MenuIcon2 = _interopRequireDefault(_MenuIcon);

var _SlideMenu = require('./SlideMenu');

var _SlideMenu2 = _interopRequireDefault(_SlideMenu);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PRE_OWNED_SEARCH_LABEL = 'PRE-OWNED SEARCH';

var NVL_INVENTORY_STATUS = 'new';

var HeaderWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceHeader__HeaderWrapper',
  componentId: 'sc-fhckii-0'
})(['display:grid;justify-content:center;align-items:center;transition:all 0.3s;height:', ';background:', ';backdrop-filter:', ';', ' position:fixed;z-index:10;width:100%;grid-template-columns:16px 2fr 16px repeat(12,96px 16px) 2fr 16px;grid-template-rows:', ' auto auto;', ''], function (props) {
  return (0, _rollsRoyceScrollTransition2.default)(props.scrollTop, 120, 60) + 'px';
}, function (props) {
  return props.scrollTop > 0 ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);' : 'linear-gradient(0deg, rgba(0,0,0,0) 15%, rgba(0,0,0,0.25) 100%);';
}, function (props) {
  return 'blur(' + (0, _rollsRoyceScrollTransition2.default)(props.scrollTop, 0, 20) + 'px);';
}, function (_ref) {
  var invert = _ref.invert;
  return invert && 'background: #fff; background-filter: none;';
}, function (props) {
  return (0, _rollsRoyceScrollTransition2.default)(props.scrollTop, 120, 60) + 'px';
}, _RollsRoyce2.default.max.large(_templateObject));

var Keyline = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceHeader__Keyline',
  componentId: 'sc-fhckii-1'
})(['display:flex;position:relative;grid-row:2 / span 1;align-self:flex-start;height:1px;background-color:#fff;grid-column:4 / span 23;transition:all 0.3s;opacity:', ';', ' ', ''], function (props) {
  return (0, _rollsRoyceScrollTransition2.default)(props.scrollTop, 1, 0);
}, function (_ref2) {
  var invert = _ref2.invert;
  return invert ? 'display: none;' : '';
}, _RollsRoyce2.default.max.large(_templateObject2));

var BrandLogoLink = _styledComponents2.default.a.withConfig({
  displayName: 'RollsRoyceHeader__BrandLogoLink',
  componentId: 'sc-fhckii-2'
})(['grid-column:13 / span 5;display:flex;align-items:center;position:relative;justify-content:center;transition:all 0.3s;', ''], _RollsRoyce2.default.max.large(_templateObject3));

var BrandLogo = _styledComponents2.default.img.withConfig({
  displayName: 'RollsRoyceHeader__BrandLogo',
  componentId: 'sc-fhckii-3'
})(['height:', ';position:absolute;opacity:', ';transition:all 0.3s;', ''], function (_ref3) {
  var scrollTop = _ref3.scrollTop;
  return (0, _rollsRoyceScrollTransition2.default)(scrollTop, 48, 30) + 'px';
}, function (_ref4) {
  var scrollTop = _ref4.scrollTop;
  return (0, _rollsRoyceScrollTransition2.default)(scrollTop, 1, 0);
}, function (_ref5) {
  var invert = _ref5.invert;
  return invert ? 'filter: invert(1);' : '';
});

var SecondaryBrandLogo = (0, _styledComponents2.default)(BrandLogo).withConfig({
  displayName: 'RollsRoyceHeader__SecondaryBrandLogo',
  componentId: 'sc-fhckii-4'
})(['opacity:', ';'], function (_ref6) {
  var scrollTop = _ref6.scrollTop;
  return (0, _rollsRoyceScrollTransition2.default)(scrollTop, 0, 1);
});

var Button = _styledComponents2.default.button.withConfig({
  displayName: 'RollsRoyceHeader__Button',
  componentId: 'sc-fhckii-5'
})(['', ' min-width:200px;text-wrap:nowrap;display:flex;flex-direction:row;align-items:center;background:none;border:none;cursor:pointer;font-size:12px;line-height:22px;letter-spacing:2px;font-family:\'RivieraNights-Medium\';text-transform:uppercase;&:hover{opacity:0.7;}', ' & svg{', '}'], function (_ref7) {
  var invert = _ref7.invert,
      open = _ref7.open;
  return invert && !open ? 'color: #222;' : 'color: #fff;';
}, _RollsRoyce2.default.max.large(_templateObject4), function (_ref8) {
  var invert = _ref8.invert,
      open = _ref8.open;
  return invert && !open ? 'fill: #222;' : 'fill: #fff;';
});
var MenuButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceHeader__MenuButtonContainer',
  componentId: 'sc-fhckii-6'
})(['position:relative;display:flex;align-items:center;justify-content:start;grid-column:4 / span 2;', ''], _RollsRoyce2.default.max.large(_templateObject5));

var SearchButtonContainer = (0, _styledComponents2.default)(MenuButtonContainer).withConfig({
  displayName: 'RollsRoyceHeader__SearchButtonContainer',
  componentId: 'sc-fhckii-7'
})(['justify-content:end;grid-column:25 / span 2;', ''], _RollsRoyce2.default.max.large(_templateObject6));

var MenuButton = (0, _styledComponents2.default)(Button).withConfig({
  displayName: 'RollsRoyceHeader__MenuButton',
  componentId: 'sc-fhckii-8'
})(['position:fixed;transition:all 0.3s;height:', ';z-index:20;'], function (props) {
  return (0, _rollsRoyceScrollTransition2.default)(props.scrollTop, 120, 60) + 'px';
});

var HeaderIcon = _styledComponents2.default.img.withConfig({
  displayName: 'RollsRoyceHeader__HeaderIcon',
  componentId: 'sc-fhckii-9'
})(['height:16px;margin:0;', ''], function (_ref9) {
  var invert = _ref9.invert,
      open = _ref9.open;
  return invert && !open ? 'filter: invert(1);' : '';
});

var getLeftOffset = function getLeftOffset(ref, setLeftOffset) {
  if (ref.current) {
    setLeftOffset(ref.current.offsetLeft);
  }
};

function RollsRoyceHeader(_ref10) {
  var config = _ref10.config,
      links = _ref10.links,
      navigate = _ref10.navigate,
      currentLanguage = _ref10.currentLanguage,
      utilityBarActions = _ref10.utilityBarActions;

  var notDesktop = (0, _useCheckIsMobileScreenRollsRoyce2.default)('small');
  var invert = config.invert,
      translations = config.translations;

  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      leftOffset = _useState2[0],
      setLeftOffset = _useState2[1];

  var _useState3 = (0, _react.useState)(0),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      scrollTop = _useState4[0],
      setScrollTop = _useState4[1];

  var onScroll = function onScroll() {
    return setScrollTop(document.documentElement.scrollTop);
  };
  var ref = (0, _react.useRef)();

  var _useState5 = (0, _react.useState)(false),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      open = _useState6[0],
      setOpen = _useState6[1];

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state;
  }),
      _useSelector$config$c = _useSelector.config.config.global.inventory;

  _useSelector$config$c = _useSelector$config$c === undefined ? {} : _useSelector$config$c;
  var _useSelector$config$c2 = _useSelector$config$c.inventoryStatus,
      inventoryStatus = _useSelector$config$c2 === undefined ? null : _useSelector$config$c2;


  var languageOptions = config.utilityBarOptions && config.utilityBarOptions.find(function (op) {
    return op.key === 'Language';
  }).options;
  var selectedLanguage = languageOptions.length > 0 ? languageOptions.find(function (lo) {
    return lo.value === currentLanguage;
  }) : '';

  var _useNavigation = (0, _navigation2.default)(),
      navigateTo = _useNavigation.navigateTo;

  var searchPreownedLinkObj = links.find(function (link) {
    return link.label === PRE_OWNED_SEARCH_LABEL;
  });

  var navigateToSearch = function navigateToSearch(linkObj) {
    if (linkObj && linkObj.link) {
      navigateTo(linkObj.link);
    }
  };

  // add scroll event listener
  (0, _react.useEffect)(function () {
    window.addEventListener('scroll', onScroll);

    return function () {
      return window.removeEventListener('scroll', onScroll);
    };
  }, []);

  // get the left offset for the menu button
  (0, _react.useEffect)(function () {
    getLeftOffset(ref, setLeftOffset);
    window.addEventListener('resize', function () {
      return getLeftOffset(ref, setLeftOffset);
    });
    return function () {
      window.removeEventListener('resize', function () {
        return getLeftOffset(ref, setLeftOffset);
      });
    };
  }, [ref]);

  return _react2.default.createElement(
    _react.Fragment,
    null,
    leftOffset && _react2.default.createElement(
      MenuButton,
      {
        scrollTop: scrollTop,
        onClick: function onClick() {
          return setOpen(!open);
        },
        style: {
          left: leftOffset
        },
        invert: invert,
        open: open
      },
      _react2.default.createElement(_MenuIcon2.default, { open: open }),
      open ? translations.close : translations.menu
    ),
    _react2.default.createElement(
      HeaderWrapper,
      { scrollTop: scrollTop, invert: invert },
      inventoryStatus && inventoryStatus !== NVL_INVENTORY_STATUS && _react2.default.createElement(MenuButtonContainer, { ref: ref }),
      _react2.default.createElement(
        BrandLogoLink,
        { href: config.brandLogoLink, target: '_blank' },
        _react2.default.createElement(BrandLogo, {
          invert: invert,
          src: config.brandLogoUrl,
          scrollTop: scrollTop
        }),
        _react2.default.createElement(SecondaryBrandLogo, {
          invert: invert,
          src: config.secondaryBrandLogoUrl,
          scrollTop: scrollTop
        })
      ),
      inventoryStatus && inventoryStatus !== NVL_INVENTORY_STATUS && _react2.default.createElement(
        SearchButtonContainer,
        null,
        _react2.default.createElement(
          Button,
          {
            invert: invert,
            scrollTop: scrollTop,
            style: {
              justifyContent: 'flex-end'
            },
            onClick: function onClick() {
              navigateToSearch(searchPreownedLinkObj);
            }
          },
          _react2.default.createElement(HeaderIcon, {
            invert: invert,
            style: {
              marginRight: '8px'
            },
            src: config.searchIconUrl,
            alt: translations.searchPreownedLink + ' icon'
          }),
          notDesktop ? '' + translations.searchPreownedLinkMobile : '' + translations.searchPreownedLink
        )
      ),
      _react2.default.createElement(Keyline, { scrollTop: scrollTop, invert: invert })
    ),
    _react2.default.createElement(_SlideMenu2.default, {
      config: config,
      languages: languageOptions,
      links: links,
      navigate: navigate,
      selectedLanguage: selectedLanguage,
      changeLanguage: function changeLanguage(e) {
        setOpen(false);
        // 1500ms delay to allow the menu to close before changing the language
        // this is a workaround to avoid the animations rerunning
        setTimeout(function () {
          utilityBarActions.language(e);
        }, 1500);
      },
      open: open,
      onClose: function onClose() {
        return setOpen(!open);
      }
    })
  );
}