'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  LAMBO_FINANCE_INIT: 'LAMBO_FINANCE_INIT',
  LAMBO_INIT_SUCCESS: 'LAMBO_INIT_SUCCESS',
  LAMBO_GET_MONTHLY_PAYMENT: 'LAMBO_GET_MONTHLY_PAYMENT',
  LAMBO_GET_MONTHLY_PAYMENT_SUCCESS: 'LAMBO_GET_MONTHLY_PAYMENT_SUCCESS',
  LAMBO_CLEAR: 'LAMBO_CLEAR',
  LAMBO_CLEAR_FINANCE: 'LAMBO_CLEAR_FINANCE',
  LAMBO_ERROR: 'LAMBO_ERROR'
};

var actions = exports.actions = {
  init: function init(payload) {
    return { type: constants.LAMBO_FINANCE_INIT, payload: payload };
  },
  setInitialValues: function setInitialValues(payload) {
    return {
      type: constants.LAMBO_INIT_SUCCESS,
      payload: payload
    };
  },
  getMonthlyPayment: function getMonthlyPayment(payload) {
    return {
      type: constants.LAMBO_GET_MONTHLY_PAYMENT,
      payload: payload
    };
  },
  getMonthlyPaymentSuccess: function getMonthlyPaymentSuccess(payload) {
    return {
      type: constants.LAMBO_GET_MONTHLY_PAYMENT_SUCCESS,
      payload: payload
    };
  },
  clear: function clear() {
    return { type: constants.LAMBO_CLEAR };
  },
  clearFinance: function clearFinance() {
    return { type: constants.LAMBO_CLEAR_FINANCE };
  },
  error: function error(payload) {
    return { type: constants.LAMBO_ERROR, origin: origin, payload: payload };
  }
};

var initialState = {};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case constants.LAMBO_INIT_SUCCESS:
      return (0, _extends3.default)({}, state, {
        finance: (0, _extends3.default)({}, payload),
        error: null
      });
    case constants.LAMBO_GET_MONTHLY_PAYMENT_SUCCESS:
      return (0, _extends3.default)({}, state, {
        payments: (0, _extends3.default)({}, payload),
        error: null
      });
    case constants.LAMBO_CLEAR:
      return (0, _extends3.default)({}, state, {
        payments: null,
        finance: null,
        error: null
      });

    case constants.LAMBO_CLEAR_FINANCE:
      return (0, _extends3.default)({}, state, {
        finance: null,
        error: null
      });
    case constants.LAMBO_ERROR:
      return (0, _extends3.default)({}, state, {
        error: (0, _extends3.default)({}, payload)
      });

    default:
      return state;
  }
}