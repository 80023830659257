'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserLocation = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _useLocation2 = require('../../../hooks/useLocation');

var _useLocation3 = _interopRequireDefault(_useLocation2);

var _settings = require('../../../shared/selectors/settings');

var _Spinner = require('../../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _AstonLink = require('../AstonLink');

var _AstonLink2 = _interopRequireDefault(_AstonLink);

var _Form = require('../Form');

var _ModelSearchBar = require('../ModelSearchBar');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__Wrapper',
  componentId: 'sc-1ozvtp3-0'
})(['display:flex;width:100%;']);

var ActionWrapper = _styledComponents2.default.button.withConfig({
  displayName: 'UserLocation__ActionWrapper',
  componentId: 'sc-1ozvtp3-1'
})(['background-color:transparent;border:0;cursor:pointer;color:#000;padding:', ';display:flex;font-size:12px;font-family:AstonMartinFlare-Regular;text-transform:uppercase;&:hover{text-decoration:underline;}'], function (_ref) {
  var position = _ref.position;
  return position === 'start' ? '0 10px 0 0' : '0 0 0 10px';
});

var InputWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__InputWrapper',
  componentId: 'sc-1ozvtp3-2'
})(['position:relative;flex:1 1 0%;']);

var LocationSuggestions = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__LocationSuggestions',
  componentId: 'sc-1ozvtp3-3'
})(['position:absolute;width:100%;', ';background-color:#ffffff;border:1px solid #d5d5d5;box-sizing:border-box;z-index:10;'], function (_ref2) {
  var position = _ref2.position;
  return position === 'below' ? 'top: 55px;' : 'bottom: 55px;';
});

var Suggestion = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__Suggestion',
  componentId: 'sc-1ozvtp3-4'
})(['font-size:12px;padding:5px;border-bottom:1px solid #d5d5d5;cursor:pointer;outline:none;', ';&:hover{background-color:#d5d5d5;}&:last-child{border-bottom:none;}'], function (_ref3) {
  var inFocus = _ref3.inFocus;
  return inFocus && 'background-color: #d5d5d5;';
});

var SpinnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__SpinnerWrapper',
  componentId: 'sc-1ozvtp3-5'
})(['margin:0 10px;']);

var EndAdornmentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'UserLocation__EndAdornmentWrapper',
  componentId: 'sc-1ozvtp3-6'
})(['display:flex;flex-direction:row;align-items:center;']);

var UserLocation = exports.UserLocation = function UserLocation(_ref4) {
  var iconUrl = _ref4.iconUrl,
      browserLocationCallback = _ref4.browserLocationCallback,
      inputLocationCallback = _ref4.inputLocationCallback,
      _ref4$options = _ref4.options,
      options = _ref4$options === undefined ? {} : _ref4$options,
      translations = _ref4.translations,
      onButtonClick = _ref4.onButtonClick;

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      colours = _useSelector.colours;

  var isMobile = (0, _useCheckIsMobileScreen2.default)('small');

  var _useLocation = (0, _useLocation3.default)(browserLocationCallback, inputLocationCallback, options),
      formattedLocation = _useLocation.formattedLocation,
      getBrowserLocation = _useLocation.getBrowserLocation,
      getInputLocation = _useLocation.getInputLocation,
      onInputChange = _useLocation.onInputChange,
      suggestions = _useLocation.suggestions,
      visible = _useLocation.visible,
      currentlySelected = _useLocation.currentlySelected,
      setCurrentlySelected = _useLocation.setCurrentlySelected,
      inputRef = _useLocation.inputRef,
      onInputKeyDown = _useLocation.onInputKeyDown,
      onInputBlur = _useLocation.onInputBlur,
      onInputFocus = _useLocation.onInputFocus,
      isLoading = _useLocation.isLoading;

  var onClick = (0, _react.useCallback)(function () {
    onButtonClick && onButtonClick();
  }, [onButtonClick]);

  var ref = (0, _react.useRef)(inputRef);

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      InputWrapper,
      null,
      _react2.default.createElement(_Form.InputField, {
        id: 'locationSearch',
        type: 'text',
        'data-cy': 'user-location-input',
        inputRef: ref,
        placeholder: translations.locationPlaceholder,
        translations: translations,
        onChange: function onChange(_ref5) {
          var event = _ref5.event;

          onInputChange(event);
        },
        onKeyDown: onInputKeyDown,
        onBlur: function onBlur(event) {
          return onInputBlur(event, translations.locationPlaceholder);
        },
        onFocus: onInputFocus,
        value: formattedLocation,
        disabledRipple: true,
        placeholderStyle: 'font-size: ' + (isMobile ? '11' : '12') + 'px;',
        startAdornment: _react2.default.createElement(
          ActionWrapper,
          { position: 'start', onClick: getBrowserLocation },
          _react2.default.createElement('img', { src: iconUrl, height: 18, width: 18, alt: 'location-icon' })
        ),
        endAdornment: _react2.default.createElement(
          EndAdornmentWrapper,
          null,
          isLoading && _react2.default.createElement(
            SpinnerWrapper,
            null,
            _react2.default.createElement(_Spinner2.default, { size: 8, colour: colours.primaryBrandColour })
          ),
          _react2.default.createElement(
            ActionWrapper,
            { position: 'end' },
            _react2.default.createElement(_AstonLink2.default, {
              text: translations.searchCTASearch,
              colour: 'black',
              onClick: onClick
            })
          )
        )
      }),
      suggestions.length > 0 && formattedLocation.length > 0 && visible && _react2.default.createElement(
        LocationSuggestions,
        {
          position: ref.current && ref.current.getBoundingClientRect().y > 150 ? 'above' : 'below',
          'data-cy': 'user-location-suggestion-list'
        },
        suggestions.map(function (s, index) {
          return _react2.default.createElement(
            Suggestion,
            {
              role: 'button',
              'data-cy': 'user-location-suggestion-list-option',
              onMouseDown: function onMouseDown() {
                setCurrentlySelected(0);
                getInputLocation(s);
              },
              onMouseEnter: function onMouseEnter() {
                return setCurrentlySelected(index);
              },
              inFocus: currentlySelected === index,
              key: s.description
            },
            s.description
          );
        })
      )
    )
  );
};

exports.default = UserLocation;