'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    '], ['\n    width: 100%;\n    ']);

exports.default = RegionCheckbox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Checkbox = _styledComponents2.default.div.withConfig({
  displayName: 'RegionCheckbox__Checkbox',
  componentId: 'sc-z3me3k-0'
})(['display:flex;width:100%;align-items:center;justify-content:space-between;box-sizing:border-box;margin-bottom:10px;outline:none;background-color:#ffffff;&:last-child{border-bottom:none;}&:hover{cursor:pointer;}@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){width:228px;', ';}'], _theme2.default.max.medium(_templateObject));

var LabelHeading = _styledComponents2.default.h2.withConfig({
  displayName: 'RegionCheckbox__LabelHeading',
  componentId: 'sc-z3me3k-1'
})(['color:#151515;font-family:\'RivieraNights-Regular\',\'Jost-Light\';font-weight:normal;font-size:16px;line-height:26px;letter-spacing:0.5px;margin:0;']);

function RegionCheckbox(_ref) {
  var onClick = _ref.onClick,
      label = _ref.label,
      renderIcon = _ref.renderIcon,
      enabled = _ref.enabled;

  return _react2.default.createElement(
    Checkbox,
    { enabled: enabled, onClick: onClick },
    _react2.default.createElement(
      LabelHeading,
      null,
      label
    ),
    renderIcon
  );
}