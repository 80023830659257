'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SubHeading;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledSubHeading = _styledComponents2.default.div.withConfig({
  displayName: 'SubHeading__StyledSubHeading',
  componentId: 'sc-z1q0ur-0'
})(['padding:45px;color:#000;font-size:21px;font-weight:600;character-spacing:0.4px;text-transform:uppercase;letter-spacing:3px;']);
function SubHeading(_ref) {
  var config = _ref.config;

  return _react2.default.createElement(
    _ContentWrapper2.default,
    { colour: config.backgroundColour && config.backgroundColour },
    _react2.default.createElement(
      StyledSubHeading,
      null,
      config.translations.headline
    )
  );
}