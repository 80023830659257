'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 80px auto 40px;\n  '], ['\n    margin: 80px auto 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n   font-size: 36px;\n    margin: 10px 0 40px;\n  '], ['\n   font-size: 36px;\n    margin: 10px 0 40px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-gap: 30px;\n  '], ['\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-gap: 30px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Accessories__Wrapper',
  componentId: 'sc-1ase1m4-0'
})(['margin:40px auto 20px;width:100%;max-width:1280px;', ';', ';'], function () {
  return _Genesis.mixins.pageGuttering();
}, _Genesis2.default.min.medium(_templateObject));

var SectionTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'Accessories__SectionTitle',
  componentId: 'sc-1ase1m4-1'
})(['color:#000;font-family:\'GenesisSansHeadGlobal-Light\';font-size:24px;font-weight:normal;margin:10px 0 20px;text-transform:uppercase;', ';'], _Genesis2.default.min.medium(_templateObject2));

var ItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Accessories__ItemContainer',
  componentId: 'sc-1ase1m4-2'
})(['', ';'], _Genesis2.default.min.medium(_templateObject3));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'Accessories__Item',
  componentId: 'sc-1ase1m4-3'
})(['flex:1;border:1px solid #000;margin-bottom:40px;']);

var ItemImg = _styledComponents2.default.img.withConfig({
  displayName: 'Accessories__ItemImg',
  componentId: 'sc-1ase1m4-4'
})(['width:100%;height:auto;']);

var ItemContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Accessories__ItemContentWrapper',
  componentId: 'sc-1ase1m4-5'
})(['padding:0 20px;']);

var ItemTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'Accessories__ItemTitle',
  componentId: 'sc-1ase1m4-6'
})(['border-bottom:1px solid #000;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:18px;font-weight:normal;text-transform:uppercase;padding-bottom:10px;']);

var ItemContent = _styledComponents2.default.p.withConfig({
  displayName: 'Accessories__ItemContent',
  componentId: 'sc-1ase1m4-7'
})(['font-size:14px;line-height:18px;']);

var Accessories = function Accessories(_ref) {
  var translations = _ref.translations;
  return _react2.default.createElement(
    Wrapper,
    { id: 'Genesis-Accessories' },
    _react2.default.createElement(
      SectionTitle,
      null,
      translations.titleAccessories
    ),
    _react2.default.createElement(
      ItemContainer,
      null,
      _react2.default.createElement(
        Item,
        null,
        _react2.default.createElement(ItemImg, {
          src: 'https://res.cloudinary.com/motortrak/q_auto/v1697721155/locator/genesis/content/vdp/accessories/accessory-placeholder1.jpg',
          alt: 'All Weather Floor Mats'
        }),
        _react2.default.createElement(
          ItemContentWrapper,
          null,
          _react2.default.createElement(
            ItemTitle,
            null,
            'All Weather Floor Mats'
          ),
          _react2.default.createElement(
            ItemContent,
            null,
            'Made from durable rubber, these custom-fit floor mats are designed to help protect your vehicle from dirt. With their recessed Genesis emblems and pronounced edges, they keep mud and water off the car\'s carpet while simultaneously helping to prevent slipping.'
          )
        )
      ),
      _react2.default.createElement(
        Item,
        null,
        _react2.default.createElement(ItemImg, {
          src: 'https://res.cloudinary.com/motortrak/q_auto/v1697721156/locator/genesis/content/vdp/accessories/accessory-placeholder2.jpg',
          alt: 'All Weather Floor Mats'
        }),
        _react2.default.createElement(
          ItemContentWrapper,
          null,
          _react2.default.createElement(
            ItemTitle,
            null,
            'Boot Liner'
          ),
          _react2.default.createElement(
            ItemContent,
            null,
            'This light, durable and waterproof boot liner is the perfect fit for your Genesis. It features raised edges to protect the boot from dirt and wear and tear and textured anti-slip rubber to prevent loads sliding around.'
          )
        )
      ),
      _react2.default.createElement(
        Item,
        null,
        _react2.default.createElement(ItemImg, {
          src: 'https://res.cloudinary.com/motortrak/q_auto/v1697721155/locator/genesis/content/vdp/accessories/accessory-placeholder3.jpg',
          alt: 'All Weather Floor Mats'
        }),
        _react2.default.createElement(
          ItemContentWrapper,
          null,
          _react2.default.createElement(
            ItemTitle,
            null,
            'Mode 3 charging leads and case'
          ),
          _react2.default.createElement(
            ItemContent,
            null,
            'Connect to a charging station or wall box safely with this 5m long Genesis Mode 3 cable. Fitted with Type 2 plug according to IEC 62196. Mode 3 charging mode according to IEC 61851. Rated up to 32A charging current three phase & up to 22 kW charging power. Splashproof IP44 rating.'
          )
        )
      )
    )
  );
};

exports.default = Accessories;