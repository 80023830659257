'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = renderMedia;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _YouTubePlayer = require('../YouTubePlayer');

var _YouTubePlayer2 = _interopRequireDefault(_YouTubePlayer);

var _Player = require('../360/Player');

var _Player2 = _interopRequireDefault(_Player);

var _types = require('../../types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'RenderMedia__Container',
  componentId: 'sc-1eqydek-0'
})(['position:relative;z-index:9;']);

function renderMedia(_ref) {
  var hideMedia = _ref.hideMedia,
      mediaType = _ref.mediaType,
      media = _ref.vehicleInfo.media,
      enable360View = _ref.enable360View,
      globalStyling = _ref.globalStyling,
      close360Icon = _ref.close360Icon,
      translations = _ref.translations,
      parsedYouTubeId = _ref.parsedYouTubeId;

  return _react2.default.createElement(
    Container,
    null,
    mediaType === 'view360' && enable360View && _react2.default.createElement(_Player2.default, {
      imagesIcon: close360Icon,
      toggleImageView: hideMedia,
      globalStyling: globalStyling,
      media: media,
      translations: translations
    }),
    mediaType === 'video' && _react2.default.createElement(_YouTubePlayer2.default, {
      src: parsedYouTubeId,
      autoplay: true,
      toggleImageView: hideMedia,
      imagesIcon: close360Icon
    })
  );
}