'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)([' flex-direction: column;'], [' flex-direction: column;']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n  '], ['\n    margin: 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('./Global');

var _vehicle = require('../helpers/vehicle');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _dates = require('../shared/localisation/dates');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FeaturedSpecRowBody = _styledComponents2.default.div.withConfig({
  displayName: 'VdpFeaturedSpecification__FeaturedSpecRowBody',
  componentId: 'sc-2xdggp-0'
})(['display:flex;justify-content:space-between;', ';'], _theme2.default.max.medium(_templateObject));

var FeaturedSpecBody = _styledComponents2.default.div.withConfig({
  displayName: 'VdpFeaturedSpecification__FeaturedSpecBody',
  componentId: 'sc-2xdggp-1'
})(['align-items:center;display:flex;justify-content:space-between;flex:1 0 0%;', ';', ';'], function (_ref) {
  var featuredSpec = _ref.featuredSpec;
  return featuredSpec && featuredSpec.borderColour ? 'border-bottom: 1px solid ' + featuredSpec.borderColour.value : 'border-bottom: 1px solid #ACACAC;';
}, _theme2.default.max.medium(_templateObject2));

var DataRow = function DataRow(_ref2) {
  var featuredSpec = _ref2.featuredSpec,
      label = _ref2.label,
      value = _ref2.value,
      capitaliseValue = _ref2.capitaliseValue,
      locale = _ref2.locale;

  var formattedLocale = function formattedLocale(locale) {
    return locale.replace('_', '-');
  };

  var formattedValue = label && label.toLowerCase().includes('erstzulassung') && value ? (0, _dates.localeFormattedDate)(value, formattedLocale(locale), value && (0, _dates.dateValidityCheck)(value) ? { day: '2-digit', month: '2-digit', year: 'numeric' } : { month: '2-digit', year: 'numeric' }) : value;

  return label && value ? _react2.default.createElement(
    FeaturedSpecRowBody,
    null,
    _react2.default.createElement(
      FeaturedSpecBody,
      null,
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n          ' + (0, _Global.fontStyleOverride)(featuredSpec.infoLabelFont) + '\n        ';
          }
        },
        label
      ),
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n            ' + (0, _Global.fontStyleOverride)(featuredSpec.infoValueFont) + '\n            ' + (capitaliseValue && 'text-transform: capitalize;') + '\n        ';
          }
        },
        formattedValue
      )
    )
  ) : null;
};

function LegacyVdpFeaturedSpecification(_ref3) {
  var vehicleInfo = _ref3.vehicleInfo,
      translations = _ref3.translations,
      featuredSpec = _ref3.featuredSpec;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        styleOverride: function styleOverride() {
          return '\n          ' + (0, _Global.fontStyleOverride)(featuredSpec.title) + '\n        ';
        }
      },
      translations.vdpSpecificationHeader
    ),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.model,
      value: vehicleInfo.modelGroupName
    }),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.powerOutput,
      value: vehicleInfo.powerOutput
    }),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.variant,
      value: vehicleInfo.modelVariant,
      capitaliseValue: true
    }),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.engine,
      value: vehicleInfo.specification.engine
    }),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.registration,
      value: vehicleInfo.registration
    }),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.exterior,
      value: vehicleInfo.specification.exterior
    }),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.interior,
      value: vehicleInfo.specification.interior
    }),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.display,
      value: vehicleInfo.specification.odometer.display
    }),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.transmission,
      value: vehicleInfo.specification.transmission
    }),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.fuelType,
      value: vehicleInfo.specification.fuelType
    }),
    !featuredSpec.hideCapacity && _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.engine,
      value: vehicleInfo.specification.capacity + ' ' + (translations.engineUnit ? translations.engineUnit : '')
    }),
    _react2.default.createElement(DataRow, {
      featuredSpec: featuredSpec,
      label: translations.bodystyle,
      value: vehicleInfo.specification.bodystyle
    })
  );
}

var ConfigVdpFeaturedSpecification = function ConfigVdpFeaturedSpecification(_ref4) {
  var vehicleInfo = _ref4.vehicleInfo,
      translations = _ref4.translations,
      featuredSpec = _ref4.featuredSpec,
      locale = _ref4.locale,
      dataConfig = _ref4.dataConfig,
      convertedKwToHp = _ref4.convertedKwToHp;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        styleOverride: function styleOverride() {
          return '\n        ' + (0, _Global.fontStyleOverride)(featuredSpec.title) + '\n      ';
        }
      },
      translations.vdpSpecificationHeader
    ),
    _react2.default.createElement(SpecificationsList, {
      vehicleInfo: vehicleInfo,
      translations: translations,
      featuredSpec: featuredSpec,
      locale: locale,
      dataConfig: dataConfig,
      convertedKwToHp: convertedKwToHp
    }),
    translations.specificationsFootnote && _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return (0, _Global.fontStyleOverride)(featuredSpec.infoValueFont) + ';\n            margin-bottom: 0;\n            padding-bottom: 0;';
        }
      },
      translations.specificationsFootnote
    )
  );
};

function VdpFeaturedSpecification(_ref5) {
  var vehicleInfo = _ref5.vehicleInfo,
      translations = _ref5.translations,
      featuredSpec = _ref5.featuredSpec,
      locale = _ref5.locale,
      dataConfig = _ref5.dataConfig,
      convertedKwToHp = _ref5.convertedKwToHp;

  var FeatureSpecification = dataConfig ? ConfigVdpFeaturedSpecification : LegacyVdpFeaturedSpecification;
  return _react2.default.createElement(FeatureSpecification, {
    vehicleInfo: vehicleInfo,
    translations: translations,
    featuredSpec: featuredSpec,
    locale: locale,
    dataConfig: dataConfig,
    convertedKwToHp: convertedKwToHp
  });
}

var SpecificationsList = function SpecificationsList(_ref6) {
  var vehicleInfo = _ref6.vehicleInfo,
      translations = _ref6.translations,
      featuredSpec = _ref6.featuredSpec,
      locale = _ref6.locale,
      dataConfig = _ref6.dataConfig,
      convertedKwToHp = _ref6.convertedKwToHp;

  return dataConfig.map(function (data) {
    var newFeaturedSpec = (0, _extends3.default)({}, featuredSpec, {
      infoLabelFont: (0, _extends3.default)({}, featuredSpec.infoLabelFont, data.font || {}),
      infoValueFont: (0, _extends3.default)({}, featuredSpec.infoValueFont, data.font || {})
    });

    if (data.kwToPkFormat && vehicleInfo.powerKwNumeric) {
      return _react2.default.createElement(DataRow, {
        featuredSpec: newFeaturedSpec,
        label: translations[data.translationProp],
        value: (0, _translateFromTemplate.translateFromTemplate)('powerReading', {
          KW: vehicleInfo.powerKwNumeric,
          HP: (0, _vehicle.vehiclePropFormatter)(convertedKwToHp, vehicleInfo, vehicleInfo.locale)
        }, translations),
        key: data.prop,
        locale: locale
      });
    }
    return _react2.default.createElement(DataRow, {
      featuredSpec: newFeaturedSpec,
      label: translations[data.translationProp],
      value: (0, _vehicle.vehiclePropFormatter)(data, vehicleInfo, locale),
      key: data.prop,
      locale: locale
    });
  });
};

exports.default = VdpFeaturedSpecification;