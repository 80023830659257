'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.icon = exports.description = exports.availableToAdministration = exports.id = exports.name = exports.initialState = exports.reducer = exports.constants = exports.actions = exports.defaultConfig = exports.config = exports.component = exports.reducerId = undefined;

var _reducer = require('../../modules/Compare/reducer');

Object.defineProperty(exports, 'reducerId', {
  enumerable: true,
  get: function get() {
    return _reducer.id;
  }
});

var _Compare = require('./Compare');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Compare).default;
  }
});

var _config = require('../../modules/Compare/config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _defaultConfig = require('../../modules/Compare/defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});
Object.defineProperty(exports, 'actions', {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
Object.defineProperty(exports, 'constants', {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
Object.defineProperty(exports, 'reducer', {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, 'initialState', {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});

var _Icon = require('../../modules/Compare/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'Compare';
var id = exports.id = 'Compare';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'Compare Page';
var icon = exports.icon = _Icon2.default;