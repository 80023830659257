'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: repeat(4, 1fr);\n    grid-template-rows: repeat(2, 1fr);\n  '], ['\n    grid-template-columns: repeat(4, 1fr);\n    grid-template-rows: repeat(2, 1fr);\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: repeat(1, 1fr);\n    grid-template-rows: repeat(8, 1fr);\n    grid-row-gap: 40px;\n  '], ['\n    grid-template-columns: repeat(1, 1fr);\n    grid-template-rows: repeat(8, 1fr);\n    grid-row-gap: 40px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _modelTypes = require('../../../types/Genesis/modelTypes');

var _ModelItem = require('./ModelItem');

var _ModelItem2 = _interopRequireDefault(_ModelItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__Wrapper',
  componentId: 'sc-1h2laod-0'
})(['width:100%;display:grid;grid-template-columns:repeat(2,1fr);grid-template-rows:repeat(4,1fr);grid-column-gap:20px;grid-row-gap:20px;margin-bottom:80px;', ' ', ''], _Genesis2.default.min.large(_templateObject), _Genesis2.default.max.medium(_templateObject2));

var ModelVariantTile = function ModelVariantTile(_ref) {
  var section = _ref.section,
      config = _ref.config,
      onTileSelected = _ref.onTileSelected,
      isTileSelected = _ref.isTileSelected,
      selectedVehicleType = _ref.selectedVehicleType;

  var disabledVehicles = (0, _react.useMemo)(function () {
    if (selectedVehicleType === config.vehicleTypes.all) {
      return [];
    }
    if (selectedVehicleType === config.vehicleTypes.ev) {
      return section.tiles.filter(function (tile) {
        return tile.ev === false ? tile.tileId : false;
      }).map(function (tile) {
        return tile.tileId;
      });
    }
    // return model id matching vehicle type
    return section.tiles.filter(function (tile) {
      return tile.type !== selectedVehicleType ? tile.tileId : false;
    }).map(function (tile) {
      return tile.tileId;
    });
  }, [section, selectedVehicleType]);

  return _react2.default.createElement(
    Wrapper,
    null,
    section.tiles.map(function (tile) {
      return _react2.default.createElement(_ModelItem2.default, {
        key: tile.tileId,
        translations: config.translations,
        tile: tile,
        onTileSelected: onTileSelected,
        isChecked: isTileSelected(tile.tileId),
        disabledVehicles: disabledVehicles
      });
    })
  );
};

exports.default = ModelVariantTile;