'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.optionComponents = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _FilterSection = require('./FilterSection');

var _FilterSection2 = _interopRequireDefault(_FilterSection);

var _MultiSelectFilter = require('./MultiSelectFilter');

var _MultiSelectFilter2 = _interopRequireDefault(_MultiSelectFilter);

var _RegionalFilter = require('./RegionalFilter');

var _RegionalFilter2 = _interopRequireDefault(_RegionalFilter);

var _OneHandledRangeFilter = require('./OneHandledRangeFilter');

var _OneHandledRangeFilter2 = _interopRequireDefault(_OneHandledRangeFilter);

var _DualHandledRangeFilter = require('./DualHandledRangeFilter');

var _DualHandledRangeFilter2 = _interopRequireDefault(_DualHandledRangeFilter);

var _InactiveFilter = require('./InactiveFilter');

var _InactiveFilter2 = _interopRequireDefault(_InactiveFilter);

var _numbers = require('../../../../shared/localisation/numbers');

var _helpers = require('../../../../shared/currencyConversion/helpers');

var _Form = require('../../Form');

var _DistanceFilter = require('./DistanceFilter');

var _DistanceFilter2 = _interopRequireDefault(_DistanceFilter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var optionComponents = exports.optionComponents = {
  condition: function condition(_ref) {
    var filterKey = _ref.filterKey,
        props = _ref.props;

    var conditionFilters = ['onlyApproved', 'isQ'];

    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.conditionFilterTitle,
        active: true,
        stylingConfig: props.config.stylingConfig,
        disabledBorder: true
      },
      conditionFilters.map(function (filter) {
        return _react2.default.createElement(
          'div',
          {
            style: {
              display: 'flex',
              flexDirection: 'row',
              margin: '10px 0'
            },
            key: filter
          },
          _react2.default.createElement(_Form.Checkbox, {
            checked: props.selectedFilters[filter] === 'true',
            onClick: function onClick() {
              return props.updateFilters([{
                key: filter,
                value: props.selectedFilters[filter] === 'true' ? 'false' : 'true'
              }]);
            }
          }),
          _react2.default.createElement(
            'span',
            { style: { marginLeft: '10px', fontSize: '14px' } },
            props.config.translations[filter]
          )
        );
      })
    );
  },
  subregions: function subregions(_ref2) {
    var filterKey = _ref2.filterKey,
        props = _ref2.props,
        _ref2$isDealerPage = _ref2.isDealerPage,
        isDealerPage = _ref2$isDealerPage === undefined ? false : _ref2$isDealerPage;

    if (isDealerPage) {
      return null;
    }
    return _react2.default.createElement(_RegionalFilter2.default, {
      key: filterKey,
      filterKey: filterKey,
      selectedData: props.selectedFilters.subregions,
      filtersStyling: props.config.stylingConfig,
      updateFilters: props.updateFilters,
      availableFilterValues: props.availableFilterValues,
      translations: props.translations,
      config: props.config
    });
  },
  bodystyle: function bodystyle(_ref3) {
    var filterKey = _ref3.filterKey,
        props = _ref3.props,
        ref = _ref3.ref;
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.bodystyleFilterTitle,
        active: !!props.selectedFilters.bodystyle,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.bodystyle,
        selectedData: props.selectedFilters.bodystyle,
        updateFilters: props.updateFilters,
        stylingConfig: props.config.stylingConfig,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        }
      })
    );
  },
  model: function model(_ref4) {
    var filterKey = _ref4.filterKey,
        props = _ref4.props,
        ref = _ref4.ref;
    var translations = props.config.translations;

    var translateModel = function translateModel(models) {
      return models.map(function (model) {
        return translations[model.value] ? (0, _extends3.default)({}, model, { display: translations[model.value] }) : model;
      });
    };

    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.modelFilterTitle,
        active: !!props.selectedFilters.model,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: translateModel(props.availableFilterValues.model),
        selectedData: props.selectedFilters.model,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue() {
          return false;
        },
        noResultsLabel: '0'
      })
    );
  },
  modelVariants: function modelVariants(_ref5) {
    var filterKey = _ref5.filterKey,
        props = _ref5.props,
        ref = _ref5.ref;
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.variantFilterTitle,
        active: !!props.selectedFilters.modelVariants,
        disabled: !props.selectedFilters.model || !props.availableFilterValues.modelVariants.length,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.modelVariants,
        selectedData: props.selectedFilters.modelVariants,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        }
      })
    );
  },
  exteriorColours: function exteriorColours(_ref6) {
    var filterKey = _ref6.filterKey,
        props = _ref6.props,
        ref = _ref6.ref;
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.exteriorColoursFilterTitle,
        active: !!props.selectedFilters.exteriorColours,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.exteriorColours,
        selectedData: props.selectedFilters.exteriorColours,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        }
      })
    );
  },
  interiorShades: function interiorShades(_ref7) {
    var filterKey = _ref7.filterKey,
        props = _ref7.props,
        ref = _ref7.ref;
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.interiorShadeFilterTitle,
        active: !!props.selectedFilters.interiorShades,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.interiorShades,
        selectedData: props.selectedFilters.interiorShades,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        }
      })
    );
  },
  yearRange: function yearRange(_ref8) {
    var filterKey = _ref8.filterKey,
        props = _ref8.props,
        ref = _ref8.ref;
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.ageFilterTitle,
        active: !!props.selectedFilters.yearRangeMin || !!props.selectedFilters.yearRangeMax,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_DualHandledRangeFilter2.default, {
        filterKeyMin: 'yearRangeMin',
        filterKeyMax: 'yearRangeMax',
        filterLabel: props.config.translations.ageFilterLabel,
        availableValues: {
          min: props.availableFilterValues.yearRange.min,
          max: props.availableFilterValues.yearRange.max
        },
        selectedValues: {
          min: props.selectedFilters.yearRangeMin || props.availableFilterValues.yearRange.min,
          max: props.selectedFilters.yearRangeMax || props.availableFilterValues.yearRange.max
        },
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        filtersStyling: props.config.stylingConfig
      })
    );
  },
  maxOdometer: function maxOdometer(_ref9) {
    var filterKey = _ref9.filterKey,
        props = _ref9.props,
        ref = _ref9.ref;
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.odometerFilterTitle,
        active: !!props.selectedFilters.maxOdometer,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_OneHandledRangeFilter2.default, {
        filterKey: filterKey,
        filterLabel: props.config.translations.maxMilesFilterTitle,
        step: 500,
        selectedValue: props.selectedFilters.maxOdometer === undefined || props.selectedFilters.maxOdometer === null ? props.availableFilterValues.maxOdometer : props.selectedFilters.maxOdometer,
        maxValue: props.availableFilterValues.maxOdometer,
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        stylingConfig: props.config.stylingConfig,
        filtersStyling: props.config.stylingConfig,
        locale: props.locale,
        formatter: function formatter(value) {
          return (0, _numbers.localiseNumber)(value, props.locale);
        }
      })
    );
  },
  cylinders: function cylinders(_ref10) {
    var filterKey = _ref10.filterKey,
        props = _ref10.props,
        ref = _ref10.ref;
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.cylinderFilterTitle,
        active: !!props.selectedFilters.cylinders,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.cylinders,
        selectedData: props.selectedFilters.cylinders,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        }
      })
    );
  },
  defaultPriceRange: function defaultPriceRange(_ref11) {
    var filterKey = _ref11.filterKey,
        props = _ref11.props,
        ref = _ref11.ref;

    var currentCurrency = props.shared.sessionPreferences.currency;

    var exchangeRates = props.shared.currencyConversion.exchangeRates && props.shared.currencyConversion.exchangeRates[currentCurrency] && props.shared.currencyConversion.exchangeRates[currentCurrency].rates;

    var rangeSliderSteps = 500;

    var getMinAvailableValue = function getMinAvailableValue() {
      return (0, _helpers.convertPriceCurrency)(props.availableFilterValues.minDefaultPrice.value, exchangeRates, currentCurrency, props.availableFilterValues.minDefaultPrice.currency, rangeSliderSteps, 'min');
    };

    var getMaxAvailableValue = function getMaxAvailableValue() {
      return (0, _helpers.convertPriceCurrency)(props.availableFilterValues.maxDefaultPrice.value, exchangeRates, currentCurrency, props.availableFilterValues.maxDefaultPrice.currency, rangeSliderSteps);
    };

    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.priceFilterTitle,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      exchangeRates ? _react2.default.createElement(_DualHandledRangeFilter2.default, {
        filterKeyMin: 'minDefaultPrice',
        filterKeyMax: 'maxDefaultPrice',
        filterLabel: props.config.translations.priceFilterTitle,
        step: rangeSliderSteps,
        availableValues: {
          min: getMinAvailableValue(),
          max: getMaxAvailableValue()
        },
        selectedValues: {
          min: props.selectedFilters.minDefaultPrice || getMinAvailableValue(),
          max: props.selectedFilters.maxDefaultPrice || getMaxAvailableValue()
        },
        globalStyling: props.globalStyling,
        updateFilters: function updateFilters(rangeFilterValues) {
          var updatedFilterValues = rangeFilterValues.map(function (rv) {
            return (0, _extends3.default)({}, rv, {
              value: rv.value
            });
          });
          props.updateFilters(updatedFilterValues);
        },
        filtersStyling: props.config.stylingConfig,
        hideValues: !currentCurrency,
        formatter: function formatter(value) {
          return (0, _numbers.localiseCurrency)(Number(value), props.locale, currentCurrency, 0);
        },
        disclaimerLink: '#super-1',
        superText: props.config.translations.superText
      }) : _react2.default.createElement(
        _InactiveFilter2.default,
        null,
        props.config.translations.selectCurrencyText
      )
    );
  },
  distance: function distance(_ref12) {
    var filterKey = _ref12.filterKey,
        props = _ref12.props,
        ref = _ref12.ref,
        isDealerPage = _ref12.isDealerPage;

    var availableFilterValues = [5, 10, 20, 50, 70, 100, 150, 200];
    if (isDealerPage) {
      return null;
    }
    return _react2.default.createElement(
      'div',
      { key: filterKey, ref: ref },
      _react2.default.createElement(_DistanceFilter2.default, {
        updateFilters: props.updateFilters,
        translations: props.config.translations,
        availableFilterValues: availableFilterValues,
        arrowUpIcon: props.config.stylingConfig.arrowUpIcon || props.config.stylingConfig.filterSectionOpenIcon,
        arrowDownIcon: props.config.stylingConfig.arrowDownIcon || props.config.stylingConfig.filterSectionCloseIcon,
        innerRef: ref,
        primaryBackgroundColour: props.globalStyling.colours.primaryBackgroundColour
      })
    );
  }
};