'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FinanceProducts;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _HirePurchase = require('./HirePurchase');

var _HirePurchase2 = _interopRequireDefault(_HirePurchase);

var _PersonalContractPurchase = require('./PersonalContractPurchase');

var _PersonalContractPurchase2 = _interopRequireDefault(_PersonalContractPurchase);

var _Leasing = require('./Leasing');

var _Leasing2 = _interopRequireDefault(_Leasing);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceProductsContainer'
}).withConfig({
  displayName: 'FinanceProducts__Container',
  componentId: 'sc-xa1fnc-0'
})(['width:100%;height:auto;']);


var contextRenderer = {
  RS: function RS(props) {
    return _react2.default.createElement(_HirePurchase2.default, {
      productKey: 'RS',
      downpaymentRange: props.downpaymentRange,
      termsRange: props.termsRange,
      financeExample: props.financeExample,
      financeExampleRenderProp: props.financeExampleRenderProp,
      requestFinanceUpdate: props.requestFinanceUpdate,
      translations: props.translations,
      globalStyling: props.globalStyling,
      locale: props.locale,
      showDescription: props.showDescription
    });
  },
  PC: function PC(props) {
    return _react2.default.createElement(_PersonalContractPurchase2.default, {
      productKey: 'PC',
      downpaymentRange: props.downpaymentRange,
      termsRange: props.termsRange,
      financeExample: props.financeExample,
      financeExampleRenderProp: props.financeExampleRenderProp,
      requestFinanceUpdate: props.requestFinanceUpdate,
      translations: props.translations,
      globalStyling: props.globalStyling,
      locale: props.locale,
      showDescription: props.showDescription
    });
  },
  LE: function LE(props) {
    return _react2.default.createElement(_Leasing2.default, {
      productKey: 'LE',
      downpaymentRange: props.downpaymentRange,
      termsRange: props.termsRange,
      financeExample: props.financeExample,
      financeExampleRenderProp: props.financeExampleRenderProp,
      requestFinanceUpdate: props.requestFinanceUpdate,
      translations: props.translations,
      globalStyling: props.globalStyling,
      locale: props.locale,
      showDescription: props.showDescription
    });
  }
};

function FinanceProducts(props) {
  return _react2.default.createElement(
    Container,
    null,
    contextRenderer[props.context](props)
  );
}