'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return {
    items: [{
      translations: {
        itemTitle: 'Title Placeholder'
      },
      image: {
        label: 'default_icongrid_icon',
        value: 'https://res.cloudinary.com/motortrak/image/upload/v1538484990/Placeholder%20Images/default-brick-icon.svg'
      }
    }, {
      translations: {
        itemTitle: 'Title Placeholder'
      },
      image: {
        label: 'default_icongrid_icon',
        value: 'https://res.cloudinary.com/motortrak/image/upload/v1538484990/Placeholder%20Images/default-brick-icon.svg'
      },
      itemId: (0, _v2.default)()
    }],
    translations: {
      headLine: 'Headline'
    },
    headlineFont: {
      fontSize: '22',
      alignment: 'center'
    },
    itemTitleFont: {
      fontSize: '16'
    },
    descriptionFont: {
      fontSize: '14'
    }
  };
};

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }