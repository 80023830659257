'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     padding: 8px 30px 16px 30px;\n  '], ['\n     padding: 8px 30px 16px 30px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _rcSlider = require('rc-slider');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../../components/Global');

var _useSliderValues2 = require('./useSliderValues');

var _useSliderValues3 = _interopRequireDefault(_useSliderValues2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__ContentWrapper',
  componentId: 'sc-1bou1n3-0'
})(['', ';padding:8px 16px 16px 16px;'], _theme2.default.max.medium(_templateObject));

var Labels = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__Labels',
  componentId: 'sc-1bou1n3-1'
})(['display:flex;justify-content:space-between;align-items:center;']);

var SliderWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__SliderWrapper',
  componentId: 'sc-1bou1n3-2'
})(['padding:0 8px;']);


var HandleContainerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__HandleContainerWrapper',
  componentId: 'sc-1bou1n3-3'
})(['']);

var HandleContainer = (0, _styledComponents2.default)(_rcSlider.Handle).withConfig({
  displayName: 'DualHandledRangeFilter__HandleContainer',
  componentId: 'sc-1bou1n3-4'
})(['margin-top:-8px;position:absolute;touch-action:pan-x;cursor:grab;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__IconWrapper',
  componentId: 'sc-1bou1n3-5'
})(['width:20px;height:20px;', ';background-size:cover;'], function (_ref) {
  var src = _ref.src;
  return 'background-image: url(' + src + ')';
});

exports.default = function (_ref2) {
  var _customMarks;

  var filterLabel = _ref2.filterLabel,
      _ref2$step = _ref2.step,
      step = _ref2$step === undefined ? 1 : _ref2$step,
      availableValues = _ref2.availableValues,
      selectedValues = _ref2.selectedValues,
      _ref2$globalStyling = _ref2.globalStyling,
      direction = _ref2$globalStyling.direction,
      primaryBrandColour = _ref2$globalStyling.colours.primaryBrandColour,
      filtersStyling = _ref2.filtersStyling,
      _ref2$formatter = _ref2.formatter,
      formatter = _ref2$formatter === undefined ? function (val) {
    return val;
  } : _ref2$formatter,
      hideValues = _ref2.hideValues,
      filterKeyMin = _ref2.filterKeyMin,
      filterKeyMax = _ref2.filterKeyMax,
      updateFilters = _ref2.updateFilters;

  var _useSliderValues = (0, _useSliderValues3.default)(selectedValues),
      updateSliderValues = _useSliderValues.updateSliderValues,
      selectedSliderValues = _useSliderValues.selectedSliderValues;

  var updateFilterValues = function updateFilterValues(values) {
    var _values = (0, _slicedToArray3.default)(values, 2),
        minValue = _values[0],
        maxValue = _values[1];

    updateFilters([{ key: filterKeyMin, value: minValue }, { key: filterKeyMax, value: maxValue }]);
  };
  var minValue = selectedSliderValues.minValue,
      maxValue = selectedSliderValues.maxValue;

  var minRangeValue = Number(availableValues.min);
  var maxRangeValue = Number(availableValues.max);

  var CustomHandle = function CustomHandle(props) {
    return _react2.default.createElement(
      HandleContainerWrapper,
      {
        onKeyDown: function onKeyDown(e) {
          if (e.key === 'ArrowLeft') {
            e.preventDefault();
            if (props.value === Number(maxValue) && props.value - step >= minValue) {
              updateFilterValues([minValue, props.value - step]);
            }
            if (props.value === Number(minValue) && props.value - step >= availableValues.min) {
              updateFilterValues([props.value - step, maxValue]);
            }
          }
          if (e.key === 'ArrowRight') {
            e.preventDefault();
            if (props.value === Number(maxValue) && props.value + step <= availableValues.max) {
              updateFilterValues([minValue, props.value + step]);
            }
            if (props.value === Number(minValue) && props.value + step <= maxValue) {
              updateFilterValues([props.value + step, maxValue]);
            }
          }
        },
        onKeyUp: function onKeyUp() {
          return updateFilterValues([selectedSliderValues.minValue, selectedSliderValues.maxValue]);
        }
      },
      _react2.default.createElement(
        HandleContainer,
        {
          key: props.index,
          value: props.value,
          offset: props.offset,
          reverse: direction === 'rtl'
        },
        _react2.default.createElement(IconWrapper, { src: filtersStyling.filterSliderIcon, alt: '' })
      )
    );
  };
  var customDotStyle = {
    cursor: 'auto',
    backgroundColor: filtersStyling.rangeRailStyle,
    border: 'none'
  };
  var customRailColour = {
    backgroundColor: filtersStyling.rangeRailStyle || '#D0D4D6'
  };
  var customMarks = (_customMarks = {}, (0, _defineProperty3.default)(_customMarks, minRangeValue, ''), (0, _defineProperty3.default)(_customMarks, maxRangeValue, ''), _customMarks);

  return _react2.default.createElement(
    ContentWrapper,
    null,
    _react2.default.createElement(
      Labels,
      null,
      _react2.default.createElement(
        _Global.Paragraph,
        {
          tabindex: '0',
          'aria-label': filterLabel,
          styleOverride: function styleOverride() {
            return '\n            ' + (0, _Global.fontStyleOverride)(filtersStyling.filterMenuFont) + '\n          ';
          }
        },
        filterLabel
      ),
      hideValues ? null : _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n              ' + (0, _Global.fontStyleOverride)(filtersStyling.filterMenuFont) + '\n              text-align: right;\n            ';
          }
        },
        formatter(minValue) + ' - ' + formatter(maxValue)
      )
    ),
    _react2.default.createElement(
      SliderWrapper,
      null,
      _react2.default.createElement(_rcSlider.Range, {
        trackStyle: [{
          backgroundColor: primaryBrandColour.value
        }],
        marks: filtersStyling.rangeRailStyle && customMarks,
        dotStyle: filtersStyling.rangeRailStyle && customDotStyle,
        railStyle: customRailColour,
        handle: CustomHandle,
        step: step,
        value: [Number(minValue), Number(maxValue)],
        min: minRangeValue,
        max: maxRangeValue,
        allowCross: false,
        onChange: updateSliderValues,
        onKeyboard: true,
        onAfterChange: updateFilterValues,
        reverse: direction === 'rtl'
      })
    )
  );
};