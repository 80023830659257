'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n  '], ['\n    display: flex;\n    flex-direction: column;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n    height: 220px;\n  '], ['\n    flex-direction: row;\n    height: 220px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n    padding: 10px;\n  '], ['\n    width: 50%;\n    padding: 10px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    width: 20%;\n    margin: auto;\n  '], ['\n    width: 20%;\n    margin: auto;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n      margin-right: 40px;\n    '], ['\n      margin-right: 40px;\n    ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n      padding-left: 40px;\n    '], ['\n      padding-left: 40px;\n    ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    width: 25%;\n    &:hover{\n      cursor: pointer;\n    }\n  '], ['\n    width: 25%;\n    &:hover{\n      cursor: pointer;\n    }\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    padding: 0;\n  '], ['\n    flex-direction: column;\n    padding: 0;\n  ']);

exports.default = MyAccountDeals;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _MyAccountHeading = require('./MyAccountHeading');

var _MyAccountHeading2 = _interopRequireDefault(_MyAccountHeading);

var _Print = require('../JaguarIcons/VDP/Print');

var _Print2 = _interopRequireDefault(_Print);

var _Global = require('../Global');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _numbers = require('../../shared/localisation/numbers');

var _Spinner = require('../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DesktopView = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__DesktopView',
  componentId: 'sc-xsg6rl-0'
})(['display:block;width:83%;', ';'], _theme2.default.max.medium(_templateObject));

var MobileView = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__MobileView',
  componentId: 'sc-xsg6rl-1'
})(['display:none;', ';'], _theme2.default.max.medium(_templateObject2));

var ActionButton = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__ActionButton',
  componentId: 'sc-xsg6rl-2'
})(['width:100%;padding:10px 0;display:flex;align-items:center;justify-content:center;cursor:pointer;&:first-child{border-bottom:1px solid #dedede;}']);
var ActionButtonText = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__ActionButtonText',
  componentId: 'sc-xsg6rl-3'
})(['padding-left:10px;font-size:15px;font-weight:600;color:#343434;']);

var MyAccountContent = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__MyAccountContent',
  componentId: 'sc-xsg6rl-4'
})(['background-color:#f0f0f0;color:#000000;height:auto;padding:20px 10px;display:flex;justify-content:center;']);

var TileItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__TileItemContainer',
  componentId: 'sc-xsg6rl-5'
})(['display:flex;flex-direction:column;background-color:#fff;border:1px solid #dedede;margin-bottom:20px;', ';'], _theme2.default.min.large(_templateObject3));

var DetailsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__DetailsContainer',
  componentId: 'sc-xsg6rl-6'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.min.large(_templateObject4));

var HeadingContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__HeadingContainer',
  componentId: 'sc-xsg6rl-7'
})(['margin-left:15px;margin-right:10px;padding:10px;', ';'], _theme2.default.min.large(_templateObject5));

var ModelName = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__ModelName',
  componentId: 'sc-xsg6rl-8'
})(['font-size:20px;font-weight:600;letter-spacing:3px;text-transform:uppercase;font-size:18px;&:hover{cursor:pointer;}']);

var SubHeading = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__SubHeading',
  componentId: 'sc-xsg6rl-9'
})(['font-size:15px;color:#7e7e7e;line-height:30px;text-transform:uppercase;font-size:14px;']);

var BottomContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__BottomContainer',
  componentId: 'sc-xsg6rl-10'
})(['', ';'], _theme2.default.min.large(_templateObject6));

var InfoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__InfoContainer',
  componentId: 'sc-xsg6rl-11'
})(['padding:10px 0;display:flex;']);

var TableWrap = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__TableWrap',
  componentId: 'sc-xsg6rl-12'
})(['display:flex;flex-flow:column nowrap;font-size:0.8rem;margin:0.5rem;line-height:1.5;flex:1 1 auto;']);

var TableRow = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__TableRow',
  componentId: 'sc-xsg6rl-13'
})(['width:100%;display:flex;flex-flow:row nowrap;']);

var TableCol = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__TableCol',
  componentId: 'sc-xsg6rl-14'
})(['display:flex;flex-flow:column nowrap;padding:0.5em;word-break:break-word;width:100%;white-space:nowrap;width:100% &:first-child{border-right:1px solid #dedede;', ';}&:last-child{border-left:1px solid #dedede;', ';}'], _theme2.default.min.large(_templateObject7), _theme2.default.min.large(_templateObject8));

var DealValue = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__DealValue',
  componentId: 'sc-xsg6rl-15'
})(['font-size:26px;font-weight:bold;font-size:20px;']);

var DealText = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__DealText',
  componentId: 'sc-xsg6rl-16'
})(['font-size:14px;color:#999999;']);

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__ImageContainer',
  componentId: 'sc-xsg6rl-17'
})(['width:auto;height:100%;position:relative;', ';'], _theme2.default.min.large(_templateObject9));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'MyAccountDeals__Image',
  componentId: 'sc-xsg6rl-18'
})(['height:100%;width:100%;object-fit:cover;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__ButtonContainer',
  componentId: 'sc-xsg6rl-19'
})(['display:flex;flex-direction:row;padding:10px;', ';'], _theme2.default.min.large(_templateObject10));

var HorizontalLine = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__HorizontalLine',
  componentId: 'sc-xsg6rl-20'
})(['display:block;width:100%;height:1px;margin:20px 0;border-top:1px solid #dedede;justify-content:center;']);

var VerticalLine = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__VerticalLine',
  componentId: 'sc-xsg6rl-21'
})(['display:block;height:220px;border:0;border-left:1px solid #dedede;margin:0;padding:0;justify-content:center;width:1%;']);

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__ImageOverlay',
  componentId: 'sc-xsg6rl-22'
})(['justify-content:center;align-items:center;display:flex;flex-direction:column;position:absolute;top:0;left:0;right:0;bottom:0;color:#ffffff;background-color:rgb(0,0,0,0.5);cursor:default;']);

var SoldHeading = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__SoldHeading',
  componentId: 'sc-xsg6rl-23'
})(['font-size:20px;font-weight:600;']);

var SoldBody = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__SoldBody',
  componentId: 'sc-xsg6rl-24'
})(['font-size:14px;max-width:165px;']);

var ExpiredSection = _styledComponents2.default.div.withConfig({
  displayName: 'MyAccountDeals__ExpiredSection',
  componentId: 'sc-xsg6rl-25'
})(['color:', ';'], function (props) {
  return props.colour ? '' + props.colour : '#fe0000';
});

function hasExpired(validUntil) {
  var today = new Date();
  var validUntilDay = new Date(validUntil);
  return !validUntil || today.getTime() - validUntilDay.getTime() >= 0;
}

var MyDealsInfo = function MyDealsInfo(_ref) {
  var vehicle = _ref.vehicle,
      finance = _ref.finance,
      placeholdingImage = _ref.placeholdingImage,
      translations = _ref.translations,
      goToVdp = _ref.goToVdp,
      deleteMyDeal = _ref.deleteMyDeal,
      printMyDeal = _ref.printMyDeal,
      iconColour = _ref.iconColour,
      globalStyling = _ref.globalStyling,
      expired = _ref.expired,
      printing = _ref.printing,
      pdfConfig = _ref.pdfConfig;
  return _react2.default.createElement(
    TileItemContainer,
    null,
    _react2.default.createElement(
      ImageContainer,
      {
        onClick: function onClick() {
          return goToVdp((0, _extends3.default)({}, vehicle, { finance: expired ? null : finance }));
        }
      },
      vehicle.images && vehicle.images[0] ? _react2.default.createElement(Image, { src: vehicle.images[0], alt: vehicle.images[0] }) : _react2.default.createElement(Image, { src: placeholdingImage, alt: placeholdingImage }),
      vehicle.status === 'sold' && _react2.default.createElement(
        ImageOverlay,
        null,
        _react2.default.createElement(
          SoldHeading,
          null,
          translations.sold
        ),
        _react2.default.createElement(
          SoldBody,
          null,
          translations.similarVehiclesAvailable
        )
      )
    ),
    _react2.default.createElement(
      DetailsContainer,
      null,
      _react2.default.createElement(
        HeadingContainer,
        null,
        expired && _react2.default.createElement(
          ExpiredSection,
          {
            colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
          },
          translations.myAccountDealHasExpired
        ),
        _react2.default.createElement(
          ModelName,
          {
            onClick: function onClick() {
              return goToVdp((0, _extends3.default)({}, vehicle, { finance: expired ? null : finance }));
            }
          },
          vehicle.year,
          ' ',
          vehicle.model
        ),
        _react2.default.createElement(
          SubHeading,
          null,
          [vehicle.specification && vehicle.specification.odometer && vehicle.specification.odometer.display, vehicle.specification && vehicle.specification.engine, vehicle.specification && vehicle.specification.transmission, vehicle.specification && vehicle.specification.fuel].filter(Boolean).join(' | ')
        )
      ),
      _react2.default.createElement(
        InfoContainer,
        null,
        _react2.default.createElement(
          TableWrap,
          null,
          _react2.default.createElement(
            TableRow,
            null,
            _react2.default.createElement(
              TableCol,
              null,
              _react2.default.createElement(
                DealValue,
                null,
                (0, _numbers.localiseCurrency)(finance.vehiclePrice, 'es-ES', finance.currency, 0)
              ),
              _react2.default.createElement(
                DealText,
                null,
                translations.myAccountDealPrice
              )
            ),
            finance.monthlyPrice && _react2.default.createElement(
              TableCol,
              null,
              _react2.default.createElement(
                DealValue,
                null,
                (0, _numbers.localiseCurrency)(parseFloat(finance.monthlyPrice.replace('.', '').replace(',', '.')), 'es-ES', finance.currency, 2)
              ),
              _react2.default.createElement(
                DealText,
                null,
                translations.myAccountDealMonthlyPrice
              )
            ),
            _react2.default.createElement(
              TableCol,
              null,
              _react2.default.createElement(
                DealValue,
                null,
                finance.apr,
                '%'
              ),
              _react2.default.createElement(
                DealText,
                null,
                translations.myAccountDealAPR
              )
            )
          )
        )
      )
    ),
    _react2.default.createElement(VerticalLine, null),
    _react2.default.createElement(
      BottomContainer,
      null,
      _react2.default.createElement(
        ButtonContainer,
        null,
        vehicle.status !== 'sold' && _react2.default.createElement(_Global.Button, {
          styleOverride: function styleOverride() {
            return '\n            margin: 5px 0;';
          },
          onClick: function onClick() {
            return goToVdp((0, _extends3.default)({}, vehicle, { finance: expired ? null : finance }));
          },
          text: expired ? translations.myAccountDealUpdate : translations.myAccountDealEdit,
          applyStyle: 'secondary',
          buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle
        }),
        _react2.default.createElement(_Global.Button, {
          styleOverride: function styleOverride() {
            return '\n          margin: 5px 0;';
          },
          onClick: function onClick() {
            return deleteMyDeal(vehicle);
          },
          text: translations.myAccountDealDelete,
          applyStyle: 'secondary',
          buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle
        }),
        vehicle.status !== 'sold' && !expired && _react2.default.createElement(
          ActionButton,
          {
            'data-cy': 'print-my-deal-button',
            onClick: function onClick() {
              return !printing.includes(vehicle.id) && printMyDeal({ vehicle: vehicle, finance: finance, pdfConfig: pdfConfig });
            }
          },
          printing.includes(vehicle.id) ? _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(_Spinner2.default, { colour: { value: iconColour } }),
            _react2.default.createElement(
              ActionButtonText,
              null,
              translations.printingDeal
            )
          ) : _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(_Print2.default, { width: '2em', height: '2em', colour: iconColour }),
            _react2.default.createElement(
              ActionButtonText,
              null,
              translations.printDealLabel
            )
          )
        )
      )
    )
  );
};

var MyDealsInfoMobile = function MyDealsInfoMobile(_ref2) {
  var vehicle = _ref2.vehicle,
      placeholdingImage = _ref2.placeholdingImage,
      finance = _ref2.finance,
      translations = _ref2.translations,
      goToVdp = _ref2.goToVdp,
      deleteMyDeal = _ref2.deleteMyDeal,
      printMyDeal = _ref2.printMyDeal,
      iconColour = _ref2.iconColour,
      globalStyling = _ref2.globalStyling,
      expired = _ref2.expired,
      pdfConfig = _ref2.pdfConfig;
  return _react2.default.createElement(
    TileItemContainer,
    null,
    _react2.default.createElement(
      HeadingContainer,
      null,
      expired && _react2.default.createElement(
        ExpiredSection,
        {
          colour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
        },
        translations.myAccountDealHasExpired
      ),
      _react2.default.createElement(
        ModelName,
        {
          onClick: function onClick() {
            return goToVdp((0, _extends3.default)({}, vehicle, { finance: expired ? null : finance }));
          }
        },
        vehicle.year,
        ' ',
        vehicle.model
      ),
      _react2.default.createElement(
        SubHeading,
        null,
        [vehicle.specification && vehicle.specification.odometer && vehicle.specification.odometer.display, vehicle.specification && vehicle.specification.engine, vehicle.specification && vehicle.specification.transmission, vehicle.specification && vehicle.specification.fuel].filter(Boolean).join(' | ')
      )
    ),
    _react2.default.createElement(
      ImageContainer,
      { onClick: function onClick() {
          return goToVdp((0, _extends3.default)({}, vehicle, finance));
        } },
      vehicle.images[0] ? _react2.default.createElement(Image, { src: vehicle.images[0], alt: vehicle.images[0] }) : _react2.default.createElement(Image, { src: placeholdingImage, alt: placeholdingImage }),
      vehicle.status === 'sold' && _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          ImageOverlay,
          null,
          _react2.default.createElement(
            SoldHeading,
            null,
            translations.sold
          ),
          _react2.default.createElement(
            SoldBody,
            null,
            translations.similarVehiclesAvailable
          )
        )
      )
    ),
    _react2.default.createElement(
      BottomContainer,
      null,
      _react2.default.createElement(
        InfoContainer,
        null,
        _react2.default.createElement(
          TableWrap,
          null,
          _react2.default.createElement(
            TableRow,
            null,
            _react2.default.createElement(
              TableCol,
              null,
              _react2.default.createElement(
                DealValue,
                null,
                (0, _numbers.localiseCurrency)(finance.vehiclePrice, 'es-ES', finance.currency, 0)
              ),
              _react2.default.createElement(
                DealText,
                null,
                translations.myAccountDealPrice
              )
            ),
            finance.monthlyPrice && _react2.default.createElement(
              TableCol,
              null,
              _react2.default.createElement(
                DealValue,
                null,
                (0, _numbers.localiseCurrency)(parseFloat(finance.monthlyPrice.replace('.', '').replace(',', '.')), 'es-ES', finance.currency, 2)
              ),
              _react2.default.createElement(
                DealText,
                null,
                translations.myAccountDealMonthlyPrice
              )
            ),
            _react2.default.createElement(
              TableCol,
              null,
              _react2.default.createElement(
                DealValue,
                null,
                finance.apr,
                '%'
              ),
              _react2.default.createElement(
                DealText,
                null,
                translations.myAccountDealAPR
              )
            )
          )
        )
      ),
      _react2.default.createElement(HorizontalLine, null),
      _react2.default.createElement(
        ButtonContainer,
        null,
        vehicle.status !== 'sold' && _react2.default.createElement(_Global.Button, {
          mobileStyleOverride: function mobileStyleOverride() {
            return '\n            width: 45%;\n            margin: 0 10px;';
          },
          onClick: function onClick() {
            return goToVdp((0, _extends3.default)({}, vehicle, { finance: expired ? null : finance }));
          },
          text: expired ? translations.myAccountDealUpdate : translations.myAccountDealEdit,
          applyStyle: 'secondary',
          buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle
        }),
        _react2.default.createElement(_Global.Button, {
          mobileStyleOverride: function mobileStyleOverride() {
            return '\n          width: 45%;\n          margin: 0 10px;';
          },
          onClick: function onClick() {
            return deleteMyDeal(vehicle);
          },
          text: translations.myAccountDealDelete,
          applyStyle: 'secondary',
          buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle
        })
      ),
      vehicle.status !== 'sold' && !expired && _react2.default.createElement(
        ActionButton,
        {
          'data-cy': 'print-my-deal-button',
          onClick: function onClick() {
            return printMyDeal({ vehicle: vehicle, finance: finance, pdfConfig: pdfConfig });
          }
        },
        _react2.default.createElement(_Print2.default, { width: '2em', height: '2em', colour: iconColour }),
        _react2.default.createElement(
          ActionButtonText,
          null,
          translations.printDealLabel
        )
      )
    )
  );
};

function MyAccountDeals(props) {
  var financeCellText = function financeCellText(priceEntry) {
    return (0, _numbers.localiseCurrency)(priceEntry, 'es-ES', props.myDeals.finance.currency, 0) || props.translations.searchResultsNotAvailableLabel;
  };
  var goToVdpIfNotSold = function goToVdpIfNotSold(vehicleAndFinance) {
    return vehicleAndFinance.status !== 'sold' && props.goToVdp(vehicleAndFinance);
  };

  var pdfConfig = {
    brandLogoUrl: props.brandLogoUrl,
    translations: props.translations,
    pdfLineColor: { value: props.iconColour }
  };
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(_MyAccountHeading2.default, {
      heading: props.translations.myAccountDealsHeading,
      subHeading: props.translations.myAccountDealsSubheading
    }),
    _react2.default.createElement(
      MyAccountContent,
      null,
      _react2.default.createElement(
        DesktopView,
        null,
        props.myDeals.map(function (myDeal) {
          return _react2.default.createElement(MyDealsInfo, (0, _extends3.default)({
            financeTranslations: props.finance.translations,
            key: myDeal.vehicle.id,
            translations: props.translations,
            goToVdp: goToVdpIfNotSold,
            deleteMyDeal: props.deleteMyDeal,
            printMyDeal: props.printMyDeal,
            financeCellText: financeCellText,
            iconColour: props.iconColour,
            globalStyling: props.globalStyling,
            expired: myDeal.finance ? hasExpired(myDeal.finance.validUntil) : true,
            printing: props.printing,
            pdfConfig: pdfConfig
          }, myDeal));
        })
      ),
      _react2.default.createElement(
        MobileView,
        null,
        props.myDeals.map(function (myDeal) {
          return _react2.default.createElement(MyDealsInfoMobile, (0, _extends3.default)({
            financeTranslations: props.finance.translations,
            key: myDeal.vehicle.id,
            translations: props.translations,
            goToVdp: goToVdpIfNotSold,
            deleteMyDeal: props.deleteMyDeal,
            printMyDeal: props.printMyDeal,
            financeCellText: financeCellText,
            iconColour: props.iconColour,
            globalStyling: props.globalStyling,
            expired: myDeal.finance ? hasExpired(myDeal.finance.validUntil) : true,
            pdfConfig: pdfConfig
          }, myDeal));
        })
      )
    )
  );
}

MyAccountDeals.defaultProps = {
  iconColour: '#9E1B32'
};