'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('../../helpers/config');

var _SectionIcons = require('../../helpers/SectionIcons');

var _SectionIcons2 = _interopRequireDefault(_SectionIcons);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var config = (0, _config.Config)('PageTitle', { id: 'PageTitle' }, (0, _config.Slice)('Layout', { id: 'layout' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.SideCarousel }, (0, _config.Align)('Alignment', 'alignment'))), (0, _config.Slice)('Content', { id: 'content' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.TopTier }, (0, _config.TextArea)('Page Title', 'pageTitle'), (0, _config.TextArea)('Page Subtitle', 'pageSubTitle'))), (0, _config.Slice)('Styling', { id: 'styling' }, (0, _config.Section)('Global', { icon: _SectionIcons2.default.TopTier }, (0, _config.Font)('Page Title', 'pageTitleFont'), (0, _config.Font)('Page SubTitle', 'pageSubTitleFont'), (0, _config.Colour)('Background', 'backgroundColour'))));

exports.default = config;