'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PopUp = exports.PopUpLink = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n          max-height: 95%;\n  '], ['\n          max-height: 95%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n        ', '\n  '], ['\n        ', '\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PopupBlur = _styledComponents2.default.div.withConfig({
  displayName: 'PopUp__PopupBlur',
  componentId: 'sc-f87bb3-0'
})(['position:fixed;left:0;bottom:0;width:100vw;height:100dvh;background-color:rgba(40,20,50,0.4);z-index:1000;display:flex;justify-content:center;align-items:center;']);
var PopupWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'PopUp__PopupWrapper',
  componentId: 'sc-f87bb3-1'
})(['position:relative;background-color:#110619;background-color:', ';max-width:664px;box-sizing:border-box;overflow:auto;height:auto;max-height:90%;', ' ', ';*{color:#fff;', ';}'], function (props) {
  return props.theme === 'lightTheme' ? '#fff' : '#110619';
}, function (props) {
  return props.theme === 'lightTheme' ? 'padding: 64px 16px; width: clamp(260px, 85vw, 664px);' : 'padding: 40px;max-width: 670px;';
}, _RollsRoyce2.default.min.medium(_templateObject), function (props) {
  return props.theme === 'lightTheme' && 'color:#151515';
});
var PopupContent = _styledComponents2.default.div.withConfig({
  displayName: 'PopUp__PopupContent',
  componentId: 'sc-f87bb3-2'
})(['', ';margin:0 auto;'], _RollsRoyce2.default.min.small(_templateObject2, function (props) {
  return props.theme === 'lightTheme' ? 'width: clamp(260px, 85vw, 398px);' : null;
}));
var PopupCloseButton = _styledComponents2.default.img.withConfig({
  displayName: 'PopUp__PopupCloseButton',
  componentId: 'sc-f87bb3-3'
})(['cursor:pointer;right:30px;top:30px;position:absolute;']);

var MarkDownWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'PopUp__MarkDownWrapper',
  componentId: 'sc-f87bb3-4'
})(['& h3,& ul{font-size:16px;font-family:\'RivieraNights-Regular\',\'NotoSansKR-Regular\',Helvetica,Arial;font-weight:normal;letter-spacing:2.5px;line-height:26px;margin:0 0 24px;}& h4{', ' font-weight:normal;letter-spacing:2.5px;margin:30px 0 0;}& ul{letter-spacing:1.5px;padding-left:24px;}& p{font-family:\'RivieraNights-Light\',\'NotoSansKR-Light\',Helvetica,Arial;', ' letter-spacing:0.5px;}& hr{border-top:1px solid #7e7284;border-bottom:none;}& a{font-family:\'RivieraNights-Regular\',\'NotoSansKR-Regular\',Helvetica,Arial;&:hover{text-decoration:none;}}'], function (props) {
  return props.theme === 'lightTheme' ? 'font-family: \'RivieraNights-Medium\', \'NotoSansKR-Medium\', Helvetica, Arial;font-size: 14px;line-height: 22px;' : 'font-family: \'RivieraNights-Regular\', \'NotoSansKR-Regular\', Helvetica, Arial;font-size: 16px;line-height: 26px;';
}, function (props) {
  return props.theme === 'lightTheme' ? 'font-size: 14px;line-height: 28px;' : 'font-size: 16px;line-height: 24px;';
});

var PopUpLink = exports.PopUpLink = _styledComponents2.default.div.withConfig({
  displayName: 'PopUp__PopUpLink',
  componentId: 'sc-f87bb3-5'
})(['color:#fff;font-family:\'RivieraNights-Light\';text-decoration:underline;cursor:pointer;width:100%;margin-bottom:40px;']);

var PopUp = exports.PopUp = function PopUp(markdown, onClose, theme) {
  return _react2.default.createElement(
    PopupBlur,
    { onClick: onClose },
    _react2.default.createElement(
      PopupWrapper,
      {
        theme: theme,
        onClick: function onClick(event) {
          event.stopPropagation();
        }
      },
      _react2.default.createElement(
        PopupContent,
        { theme: theme },
        _react2.default.createElement(PopupCloseButton, {
          src: theme === 'lightTheme' ? 'https://res.cloudinary.com/motortrak/v1720783577/locator/rolls-royce/global/icons/close-dark.svg' : 'https://res.cloudinary.com/motortrak/v1715878634/locator/rolls-royce/global/icons/close-white.svg',
          alt: 'close button',
          onClick: onClose
        }),
        _react2.default.createElement(
          MarkDownWrapper,
          { theme: theme },
          _react2.default.createElement(
            _reactMarkdown2.default,
            { linkTarget: '_blank' },
            markdown
          )
        )
      )
    )
  );
};