'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      height: 70px;\n  '], ['\n      height: 70px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      width: 100%;\n      justify-content: space-between\n  '], ['\n      width: 100%;\n      justify-content: space-between\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    position: fixed;\n    left: 100%;\n    flex-direction: column;\n    top: 60px;\n    height: 100vh;\n    background-color: #151515;\n    \n    & > select {\n      margin-left: 0;\n    }\n    ', ';\n  '], ['\n    position: fixed;\n    left: 100%;\n    flex-direction: column;\n    top: 60px;\n    height: 100vh;\n    background-color: #151515;\n    \n    & > select {\n      margin-left: 0;\n    }\n    ', ';\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    cursor: pointer;\n    display: inline-block;\n    float: right;\n    position: relative;\n    user-select: none;\n    height: 16px;\n    width: 16px;\n    margin-top: 15px;\n  '], ['\n    cursor: pointer;\n    display: inline-block;\n    float: right;\n    position: relative;\n    user-select: none;\n    height: 16px;\n    width: 16px;\n    margin-top: 15px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n  & ~ label span {\n    background: transparent;\n  }\n    &:checked {\n      & ~ label span:before {\n        transform: rotate(-45deg);\n        top: 0;\n      }\n      & ~ label span:after {\n        transform: rotate(45deg);\n        top: 0;\n      }\n      & ~ ul {\n        left: 0;\n      }\n    }\n  '], ['\n  & ~ label span {\n    background: transparent;\n  }\n    &:checked {\n      & ~ label span:before {\n        transform: rotate(-45deg);\n        top: 0;\n      }\n      & ~ label span:after {\n        transform: rotate(45deg);\n        top: 0;\n      }\n      & ~ ul {\n        left: 0;\n      }\n    }\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n      ', ';\n  '], ['\n      ', ';\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    background-color: #151515;\n    box-sizing: border-box;\n    border-bottom: 1px solid #333;\n    padding: 24px 0;\n '], ['\n    background-color: #151515;\n    box-sizing: border-box;\n    border-bottom: 1px solid #333;\n    padding: 24px 0;\n ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n      width: 100px;\n  '], ['\n      width: 100px;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n      padding-right: 56px;\n  '], ['\n      padding-right: 56px;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n      padding-right: 72px;\n      width: 128px;\n  '], ['\n      padding-right: 72px;\n      width: 128px;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n      color: #999;\n      font-size: 12px;\n  '], ['\n      color: #999;\n      font-size: 12px;\n  ']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n      font-size: 14px;\n  '], ['\n      font-size: 14px;\n  ']),
    _templateObject13 = (0, _taggedTemplateLiteral3.default)(['\n      font-size: 16px;\n      margin-right: 40px;\n  '], ['\n      font-size: 16px;\n      margin-right: 40px;\n  ']),
    _templateObject14 = (0, _taggedTemplateLiteral3.default)(['\n      font-size: 16px;\n      margin-right: 56px;\n  '], ['\n      font-size: 16px;\n      margin-right: 56px;\n  ']),
    _templateObject15 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _homePageTypes = require('../../../types/Genesis/homePageTypes');

var _LanguagePicklist = require('./LanguagePicklist');

var _LanguagePicklist2 = _interopRequireDefault(_LanguagePicklist);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Header = _styledComponents2.default.header.withConfig({
  displayName: 'GenesisHeader__Header',
  componentId: 'sc-c3wm7e-0'
})(['background-color:#000;display:flex;height:60px;border-bottom:1px solid #333;', ';', ';'], _Genesis2.default.min.medium(_templateObject), function () {
  return _Genesis.mixins.pageGuttering();
});

var Navbar = _styledComponents2.default.nav.withConfig({
  displayName: 'GenesisHeader__Navbar',
  componentId: 'sc-c3wm7e-1'
})(['width:100%;display:flex;align-items:center;', ';'], _Genesis2.default.max.medium(_templateObject2));

var Menu = _styledComponents2.default.ul.withConfig({
  displayName: 'GenesisHeader__Menu',
  componentId: 'sc-c3wm7e-2'
})(['width:100%;margin:0;padding:0;z-index:3;list-style:none;overflow:hidden;background-color:#000;max-height:none;display:flex;transition:all 0.2s ease-in-out;& > select{margin-left:auto;}', ';'], _Genesis2.default.max.medium(_templateObject3, function () {
  return _Genesis.mixins.pageGuttering();
}));

var MenuIcon = _styledComponents2.default.label.withConfig({
  displayName: 'GenesisHeader__MenuIcon',
  componentId: 'sc-c3wm7e-3'
})(['display:none;', ';'], _Genesis2.default.max.medium(_templateObject4));

var MenuBtn = _styledComponents2.default.input.withConfig({
  displayName: 'GenesisHeader__MenuBtn',
  componentId: 'sc-c3wm7e-4'
})(['display:none;', ';'], _Genesis2.default.max.medium(_templateObject5));

var MenuItem = _styledComponents2.default.li.withConfig({
  displayName: 'GenesisHeader__MenuItem',
  componentId: 'sc-c3wm7e-5'
})(['display:flex;', ';', ''], _Genesis2.default.min.medium(_templateObject6, function (props) {
  return props.onlyMobile && 'display: none;';
}), _Genesis2.default.max.medium(_templateObject7));

var NavIcon = _styledComponents2.default.span.withConfig({
  displayName: 'GenesisHeader__NavIcon',
  componentId: 'sc-c3wm7e-6'
})(['background:#fff;display:block;height:2px;position:relative;transition:background 0.2s ease-out;width:16px;&:before,&:after{background:#fff;content:\'\';display:block;height:100%;position:absolute;transition:all 0.2s ease-out;width:100%;}&:before{top:5px;}&:after{top:-5px;}']);

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'GenesisHeader__Logo',
  componentId: 'sc-c3wm7e-7'
})(['cursor:pointer;position:relative;padding-right:32px;top:2px;left:1px;width:84px;', ';', ';', ';'], _Genesis2.default.min.large(_templateObject8), _Genesis2.default.min.extraLarge(_templateObject9), _Genesis2.default.min.xxl(_templateObject10));

var ItemLabel = _styledComponents2.default.span.withConfig({
  displayName: 'GenesisHeader__ItemLabel',
  componentId: 'sc-c3wm7e-8'
})(['align-items:center;color:#fff;cursor:pointer;display:flex;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:16px;justify-content:space-between;letter-spacing:-0.4px;line-height:24px;margin-right:16px;text-decoration:none;text-transform:uppercase;transition:color 0.2s linear;width:100%;&:hover{color:#fff;}', ';', ';', ';', ';'], _Genesis2.default.min.medium(_templateObject11), _Genesis2.default.min.large(_templateObject12), _Genesis2.default.min.extraLarge(_templateObject13), _Genesis2.default.min.xxl(_templateObject14));

var ButtonIcon = _styledComponents2.default.img.withConfig({
  displayName: 'GenesisHeader__ButtonIcon',
  componentId: 'sc-c3wm7e-9'
})(['height:14px;', ';'], _Genesis2.default.min.medium(_templateObject15));

var GenesisHeader = function GenesisHeader(props) {
  var config = props.config,
      links = props.links,
      navigate = props.navigate;

  var menuIconRef = (0, _react.useRef)(null);
  return _react2.default.createElement(
    Header,
    null,
    _react2.default.createElement(
      Navbar,
      null,
      _react2.default.createElement(Logo, { src: config.brandLogoUrl, onClick: function onClick() {
          return navigate('/');
        } }),
      _react2.default.createElement(MenuBtn, { type: 'checkbox', id: 'menu-btn' }),
      _react2.default.createElement('label', { ref: menuIconRef }),
      _react2.default.createElement(
        MenuIcon,
        { htmlFor: 'menu-btn', ref: menuIconRef },
        _react2.default.createElement(NavIcon, null)
      ),
      _react2.default.createElement(
        Menu,
        null,
        links.map(function (link) {
          return _react2.default.createElement(
            MenuItem,
            {
              key: link.navId,
              onlyMobile: link.onlyMobile,
              onClick: function onClick() {
                if (menuIconRef.current !== null) {
                  // @ts-ignore
                  menuIconRef.current.click();
                }
              }
            },
            _react2.default.createElement(
              ItemLabel,
              {
                onClick: function onClick() {
                  navigate(link.link);
                }
              },
              link.label,
              _react2.default.createElement(ButtonIcon, {
                src: 'https://res.cloudinary.com/motortrak/image/upload/v1695830589/locator/genesis/icons/arrow_left.svg',
                'aria-hidden': 'true'
              })
            )
          );
        }),
        _react2.default.createElement(_LanguagePicklist2.default, null)
      )
    )
  );
};

exports.default = GenesisHeader;