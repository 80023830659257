'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  padding: 0;\n  '], ['\n  padding: 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  '], ['\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n  display:none;\n'], ['\n  display:none;\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    text-align: left;\n  '], ['\n    text-align: left;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    width: 45%;\n  '], ['\n    width: 45%;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    width: 45%;\n    margin-bottom: 40px;\n  '], ['\n    width: 45%;\n    margin-bottom: 40px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RangeSlider = require('../../components/RangeSlider/RangeSlider');

var _RangeSlider2 = _interopRequireDefault(_RangeSlider);

var _BudgetResultsPane = require('../../components/BudgetResultsPane/BudgetResultsPane');

var _BudgetResultsPane2 = _interopRequireDefault(_BudgetResultsPane);

var _PathnameSubNavBar = require('../../components/PathnameSubNavBar');

var _PathnameSubNavBar2 = _interopRequireDefault(_PathnameSubNavBar);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _router = require('../../actions/router');

var _searchFilter = require('../../shared/searchFilter');

var _helper = require('../../shared/searchFilter/helper');

var _numbers = require('../../shared/localisation/numbers');

var _routing = require('../../shared/routing');

var _Global = require('../../components/Global');

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BudgetSearchContainer = _styledComponents2.default.div.withConfig({
  displayName: 'BudgetSearchContainer'
}).withConfig({
  displayName: 'BudgetSearch__BudgetSearchContainer',
  componentId: 'sc-1wo8yls-0'
})(['', ';'], _theme2.default.min.large(_templateObject));

var BudgetSliderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'BudgetSliderContainer'
}).withConfig({
  displayName: 'BudgetSearch__BudgetSliderContainer',
  componentId: 'sc-1wo8yls-1'
})(['padding:0 13%;']);

var SliderSection = _styledComponents2.default.div.withConfig({
  displayName: 'SliderSection'
}).withConfig({
  displayName: 'BudgetSearch__SliderSection',
  componentId: 'sc-1wo8yls-2'
})(['margin-top:40px;', ';'], _theme2.default.min.large(_templateObject2));

var HorizontalLine = _styledComponents2.default.div.withConfig({
  displayName: 'HorizontalLine'
}).withConfig({
  displayName: 'BudgetSearch__HorizontalLine',
  componentId: 'sc-1wo8yls-3'
})(['border-top:1px solid #dedede;box-sizing:border-box;', ';'], _theme2.default.min.large(_templateObject3));

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'Title'
}).withConfig({
  displayName: 'BudgetSearch__Title',
  componentId: 'sc-1wo8yls-4'
})(['text-align:center;margin:40px 0;font-size:20px;font-weight:600;text-transform:uppercase;letter-spacing:3px;', ';'], _theme2.default.min.large(_templateObject4));

var BodyText = _styledComponents2.default.div.withConfig({
  displayName: 'BodyText'
}).withConfig({
  displayName: 'BudgetSearch__BodyText',
  componentId: 'sc-1wo8yls-5'
})(['margin-bottom:40px;width:100%;color:#444;font-size:18px;', ';'], _theme2.default.min.large(_templateObject5));

var SliderLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SliderLabel'
}).withConfig({
  displayName: 'BudgetSearch__SliderLabel',
  componentId: 'sc-1wo8yls-6'
})(['font-size:16px;font-weight:600;margin-bottom:20px;display:flex;flex-direction:row;width:100%;justify-content:space-between;']);

var SliderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SliderContainer'
}).withConfig({
  displayName: 'BudgetSearch__SliderContainer',
  componentId: 'sc-1wo8yls-7'
})(['', ';'], _theme2.default.min.large(_templateObject6));

var SimilarPricesBanner = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarPricesBanner'
}).withConfig({
  displayName: 'BudgetSearch__SimilarPricesBanner',
  componentId: 'sc-1wo8yls-8'
})(['height:150px;font-size:24px;background-color:#252525;display:flex;justify-content:center;align-items:center;color:#ffffff;padding:0 5%;']);

var BudgetSearch = function (_Component) {
  (0, _inherits3.default)(BudgetSearch, _Component);

  function BudgetSearch(props) {
    (0, _classCallCheck3.default)(this, BudgetSearch);

    var _this = (0, _possibleConstructorReturn3.default)(this, (BudgetSearch.__proto__ || (0, _getPrototypeOf2.default)(BudgetSearch)).call(this, props));

    Object.defineProperty(_this, 'onChangeRangeLabel', {
      enumerable: true,
      writable: true,
      value: function value(rangeValues) {
        return _this.setState(function () {
          return {
            range: { min: rangeValues[0], max: rangeValues[1] }
          };
        });
      }
    });

    _this.state = {
      range: {},
      budgetIncreasePercentage: 20
    };
    return _this;
  }

  (0, _createClass3.default)(BudgetSearch, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // We call init to force a first run of the reducer
      // - there used to be a more generic solution,
      //   but it broke the builder
      var _props$actions = this.props.actions,
          init = _props$actions.init,
          getBudgetSearchRange = _props$actions.getBudgetSearchRange;
      var _props = this.props,
          preview = _props.preview,
          dispatch = _props.dispatch;

      init();
      getBudgetSearchRange({
        preview: preview,
        budgetIncreasePercentage: this.state.budgetIncreasePercentage
      });

      dispatch(_searchFilter.actions.resetFilters());
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props$config = this.props.config,
          translations = _props$config.translations,
          market = _props$config.market,
          make = _props$config.make,
          stylingConfig = _props$config.stylingConfig;
      var searching = this.props.state.searching;
      var _props2 = this.props,
          preview = _props2.preview,
          dispatch = _props2.dispatch,
          state = _props2.state,
          pathByModule = _props2.pathByModule,
          globalStyling = _props2.globalStyling,
          config = _props2.config,
          history = _props2.history;

      var fnIfNotPreview = function fnIfNotPreview(fn) {
        return preview ? function () {} : fn;
      };
      var goToModule = function goToModule(name) {
        return dispatch(_router.actions.navigate(pathByModule(name)));
      };
      var getBudgetSearchGroups = this.props.actions.getBudgetSearchGroups;


      var fullRangeOptions = state.fullRangeOptions || {};
      var defaultStep = fullRangeOptions.min ? fullRangeOptions.min : fullRangeOptions.min;
      var monthlyPaymentRangeId = 'monthlyPaymentRange';

      var getMinValue = this.state.range.min || fullRangeOptions.min;
      var getMaxValue = this.state.range.max || fullRangeOptions.max;

      var budgetSearchRange = {
        id: 'budgetSearchRange',
        name: 'budgetSearchRange',
        title: 'My Budget Search',
        unit: '€',
        step: defaultStep,
        defaultValue: [fullRangeOptions.min, fullRangeOptions.min + fullRangeOptions.max],
        fullRangeValues: {
          min: Math.round(fullRangeOptions.min / defaultStep) * defaultStep,
          max: Math.round(fullRangeOptions.max / defaultStep) * defaultStep
        }
      };

      var learnMore = function learnMore(model) {
        return dispatch(_router.actions.navigate((0, _routing.formatUrl)('range/' + model)));
      };
      var rangeFilterUrl = function rangeFilterUrl(_ref) {
        var _ref2 = (0, _slicedToArray3.default)(_ref, 2),
            minValue = _ref2[0],
            maxValue = _ref2[1];

        var _rangePayloads$monthl = (0, _slicedToArray3.default)(_helper.rangePayloads[monthlyPaymentRangeId], 2),
            minfilter = _rangePayloads$monthl[0],
            maxfilter = _rangePayloads$monthl[1];

        return minfilter + '=' + minValue + '&' + maxfilter + '=' + maxValue;
      };

      var searchClick = function searchClick(modelRange) {
        var isBudgetIncreased = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var min = isBudgetIncreased ? getMaxValue : getMinValue;
        var max = isBudgetIncreased ? Math.round(getMaxValue * 1.2) : getMaxValue;
        dispatch(_searchFilter.actions.resetFilters());
        dispatch(_searchFilter.actions.filterByModelRange({
          section: 'models',
          filter: modelRange
        }));
        dispatch(_searchFilter.actions.changeRangeFilter({
          id: monthlyPaymentRangeId,
          values: [min, max]
        }));
        dispatch(_searchFilter.actions.getSearchFilters({
          market: market,
          make: make,
          filters: 'models=' + modelRange + '&' + rangeFilterUrl([min, max])
        }));
        goToModule('SearchResults');
      };

      var searchIncreasedClick = function searchIncreasedClick(modelRange) {
        return searchClick(modelRange, true);
      };

      var links = [{
        text: translations.budgetSearchFinanceLink,
        onClick: fnIfNotPreview(function () {
          return dispatch(_router.actions.navigate('financeoptions'));
        }),
        availableOnMobile: true,
        path: 'financeoptions'
      }, {
        text: translations.budgetSearchBudgetLink,
        onClick: fnIfNotPreview(function () {
          return dispatch(_router.actions.navigate('budgetsearch'));
        }),
        availableOnMobile: true,
        path: 'budgetsearch'
      }];

      var localisedMinValue = getMinValue && (0, _numbers.localiseCurrency)(getMinValue, this.props.marketInfo.locale, 'EUR', 0);
      var localisedMaxValue = getMaxValue && (0, _numbers.localiseCurrency)(getMaxValue, this.props.marketInfo.locale, 'EUR', 0);

      return state.fullRangeOptions ? _react2.default.createElement(
        BudgetSearchContainer,
        null,
        _react2.default.createElement(_PathnameSubNavBar2.default, {
          onBackButtonClick: history.goBack,
          links: links,
          title: translations.budgetSearchTitle,
          subNavFontTheme: config.subNavFont,
          subNavBGColour: config.subNavBGColour,
          subNavBorderColour: config.subNavBorderColour,
          subNavBarItemSelectedBackgroundColour: config.subNavBarItemSelectedBackgroundColour,
          globalStyling: globalStyling,
          subNavBarItemSelectedFontColour: config.subNavBarItemSelectedFontColour,
          history: history
        }),
        _react2.default.createElement(
          BudgetSliderContainer,
          null,
          _react2.default.createElement(
            Title,
            null,
            translations.budgetSearchTitle
          ),
          _react2.default.createElement(HorizontalLine, null),
          _react2.default.createElement(
            SliderSection,
            null,
            _react2.default.createElement(
              BodyText,
              null,
              (0, _translateFromTemplate.translateFromTemplate)('bodyText', {
                MIN: localisedMinValue || '0 €',
                MAX: localisedMaxValue || '0 €',
                COUNT: state.affordableModels ? state.affordableModels.length : 0
              }, translations)
            ),
            _react2.default.createElement(
              SliderContainer,
              null,
              _react2.default.createElement(
                SliderLabel,
                null,
                _react2.default.createElement(
                  'div',
                  null,
                  translations.budgetSearchSliderTitle
                ),
                _react2.default.createElement(
                  'div',
                  null,
                  getMinValue + ' \u20AC - ' + getMaxValue + ' \u20AC'
                )
              ),
              _react2.default.createElement(_RangeSlider2.default, {
                stylingConfig: stylingConfig,
                step: budgetSearchRange.step,
                rangeValue: budgetSearchRange.fullRangeValues,
                defaultValue: budgetSearchRange.defaultValue,
                name: budgetSearchRange.name,
                onChange: function onChange(values) {
                  return _this2.onChangeRangeLabel(values);
                },
                onAfterChange: function onAfterChange(values) {
                  return fnIfNotPreview(getBudgetSearchGroups({
                    budgetRange: {
                      min: values[0],
                      max: values[1]
                    },
                    budgetIncreasePercentage: _this2.state.budgetIncreasePercentage,
                    preview: preview
                  }));
                }
              })
            )
          ),
          _react2.default.createElement(
            _Global.HeadingThree,
            {
              styleOverride: function styleOverride() {
                return '\n              font-size: 18px;\n              margin: 20px 0;\n              text-align: center;\n            ';
              },
              mobileStyleOverride: function mobileStyleOverride() {
                return [{ queryPath: 'max.large', template: 'margin: 40px 0' }];
              }
            },
            searching ? translations.searching : state.affordableModels != null && state.affordableModels.length > 0 ? (0, _translateFromTemplate.translateFromTemplate)('budgetMatch', {
              COUNT: state.affordableModels ? state.affordableModels.length : 0
            }, translations) : translations.noResultsText
          )
        ),
        !searching && state.affordableModels && state.affordableModels.length && _react2.default.createElement(_BudgetResultsPane2.default, {
          viewNowClick: fnIfNotPreview(searchClick),
          results: state.affordableModels,
          translations: translations,
          moreInfoClick: learnMore,
          placeholdingImage: state.placeholdingImage,
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          globalStyling: globalStyling,
          locale: this.props.marketInfo.locale
        }),
        !searching && state.suggestedModels && state.suggestedModels.length > 0 && _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            SimilarPricesBanner,
            null,
            (0, _translateFromTemplate.translateFromTemplate)('similarPricesText', {
              INCREASE: state.suggestedBudgetIncrease
            }, translations)
          ),
          _react2.default.createElement(_BudgetResultsPane2.default, {
            results: state.suggestedModels,
            translations: translations,
            viewNowClick: fnIfNotPreview(searchIncreasedClick),
            moreInfoClick: learnMore,
            placeholdingImage: state.placeholdingImage,
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            globalStyling: globalStyling,
            locale: this.props.marketInfo.locale
          })
        ),
        _react2.default.createElement(
          SimilarPricesBanner,
          null,
          _react2.default.createElement(_Global.Button, {
            onClick: function onClick() {
              goToModule('SearchResults');
            },
            text: translations.searchButtonText,
            applyStyle: 'primary',
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
          })
        )
      ) : null;
    }
  }]);
  return BudgetSearch;
}(_react.Component);

exports.default = BudgetSearch;