'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilterSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FilterSection__FilterSectionWrapper',
  componentId: 'sc-14shr9r-0'
})(['display:flex;flex-direction:column;width:100%;padding:10px 0;', ''], function (_ref) {
  var disabledBorder = _ref.disabledBorder;
  return !disabledBorder && ' border-top: 1px solid #d5d5d5;';
});

var FilterSectionTitle = _styledComponents2.default.div.withConfig({
  displayName: 'FilterSection__FilterSectionTitle',
  componentId: 'sc-14shr9r-1'
})(['min-height:50px;display:flex;align-items:center;justify-content:space-between;width:100%;box-sizing:border-box;', ';'], function (_ref2) {
  var disabled = _ref2.disabled;
  return !disabled && '\n    cursor: pointer;\n  ';
});

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'FilterSection__IconWrapper',
  componentId: 'sc-14shr9r-2'
})(['width:12px;height:12px;']);

var FilterSection = function (_Component) {
  (0, _inherits3.default)(FilterSection, _Component);

  function FilterSection(props) {
    (0, _classCallCheck3.default)(this, FilterSection);

    var _this = (0, _possibleConstructorReturn3.default)(this, (FilterSection.__proto__ || (0, _getPrototypeOf2.default)(FilterSection)).call(this, props));

    Object.defineProperty(_this, 'onClickHandler', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (!_this.props.disabled) {
          _this.setState(function (prevState) {
            return { open: !prevState.open };
          });
        }
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            children = _this$props.children,
            title = _this$props.title,
            disabled = _this$props.disabled,
            stylingConfig = _this$props.stylingConfig,
            disabledBorder = _this$props.disabledBorder;
        var open = _this.state.open;

        var filterIcon = open ? stylingConfig.filterSectionOpenIcon : stylingConfig.filterSectionCloseIcon;
        return _react2.default.createElement(
          FilterSectionWrapper,
          { disabledBorder: disabledBorder },
          _react2.default.createElement(
            FilterSectionTitle,
            {
              'data-cy': title,
              disabled: disabled,
              onClick: _this.onClickHandler
            },
            _react2.default.createElement(
              'span',
              { style: { fontSize: '16px' } },
              title
            ),
            !disabled && _react2.default.createElement(IconWrapper, { src: filterIcon, alt: '' })
          ),
          open && children
        );
      }
    });

    _this.state = {
      open: props.active && !_this.props.disabled
    };
    return _this;
  }

  (0, _createClass3.default)(FilterSection, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      if (this.props.disabled && this.state.open) {
        this.setState(function () {
          return { open: false };
        });
      }
    }
  }, {
    key: 'setOpenState',
    value: function setOpenState(open) {
      this.setState(function () {
        return { open: open };
      });
    }
  }]);
  return FilterSection;
}(_react.Component);

exports.default = FilterSection;