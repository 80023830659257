'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Item = require('../../../components/Genesis/FiftyFifty/Item');

var _Item2 = _interopRequireDefault(_Item);

var _Genesis = require('../../../customThemes/Genesis');

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _FiftyFiftyTypes = require('../../../types/Genesis/FiftyFiftyTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__Container',
  componentId: 'sc-1uka71r-0'
})(['display:flex;flex-direction:column;align-items:center;background-color:#111;', ';'], function () {
  return _Genesis.mixins.pageGuttering();
});

var ItemsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty__ItemsContainer',
  componentId: 'sc-1uka71r-1'
})(['display:flex;flex-direction:column;max-width:1920px;']);

var FiftyFifty = function FiftyFifty(_ref) {
  var config = _ref.config;

  var _useNavigation = (0, _navigation2.default)(),
      navigateTo = _useNavigation.navigateTo;

  var items = config.items;


  var _onClick = function _onClick(url) {
    navigateTo(url);
  };

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      ItemsContainer,
      null,
      items.map(function (item, index) {
        return _react2.default.createElement(_Item2.default, {
          key: index,
          reverse: index % 2 !== 0,
          item: item,
          onClick: function onClick() {
            return _onClick(item.redirectUrl);
          }
        });
      })
    )
  );
};

exports.default = FiftyFifty;