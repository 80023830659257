'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fileDownload = exports.multipartFormConfig = exports.localeConfigSerializer = exports.blobContentConfig = exports.defaultConfig = undefined;

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.get = get;
exports.post = post;
exports.put = put;
exports.remove = remove;
exports.httpClientIntercept = httpClientIntercept;

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

var _querystring = require('querystring');

var _querystring2 = _interopRequireDefault(_querystring);

var _compose = require('ramda/src/compose');

var _compose2 = _interopRequireDefault(_compose);

var _reduxSaga = require('redux-saga');

var _error = require('./error');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var axiosRequestErrorMap = {
  put: _error.errorType.Update,
  get: _error.errorType.Load,
  delete: _error.errorType.Delete,
  post: _error.errorType.Create
};
/* eslint-env browser */
var CancelToken = _axios2.default.CancelToken;
var defaultConfig = exports.defaultConfig = function defaultConfig(_ref) {
  var token = _ref.token;
  return {
    responseType: 'json',
    headers: token ? { Authorization: 'Bearer ' + token } : {}
  };
};

var blobContentConfig = exports.blobContentConfig = function blobContentConfig(token) {
  return (0, _extends3.default)({}, defaultConfig({ token: token }), {
    responseType: 'blob'
  });
};

var localeConfigSerializer = exports.localeConfigSerializer = function localeConfigSerializer(locale, additionalParams) {
  return {
    paramsSerializer: function paramsSerializer(params) {
      return _querystring2.default.stringify(params);
    },
    params: (0, _extends3.default)({}, locale && { languageCode: locale }, additionalParams)
  };
};

var multipartFormConfig = exports.multipartFormConfig = function multipartFormConfig(token) {
  var postConfig = defaultConfig({ token: token });

  return (0, _extends3.default)({}, postConfig, {
    headers: (0, _extends3.default)({}, postConfig.headers, {
      'content-type': 'multipart/form-data'
    })
  });
};

function get(options) {
  var url = options.url,
      _options$config = options.config,
      config = _options$config === undefined ? {} : _options$config,
      token = options.token;

  return _axios2.default.get(url, (0, _extends3.default)({}, defaultConfig({ token: token }), config));
}

function post(options) {
  var url = options.url,
      _options$config2 = options.config,
      config = _options$config2 === undefined ? {} : _options$config2,
      data = options.data,
      token = options.token;

  var cancelTokenSource = CancelToken.source();
  var promise = _axios2.default.post(url, data, (0, _extends3.default)({}, defaultConfig({ token: token }), {
    cancelToken: cancelTokenSource.token
  }, config));
  promise[_reduxSaga.CANCEL] = function () {
    return cancelTokenSource.cancel();
  };
  return promise;
}

function put(options) {
  var url = options.url,
      _options$config3 = options.config,
      config = _options$config3 === undefined ? {} : _options$config3,
      data = options.data,
      token = options.token;

  return _axios2.default.put(url, data, (0, _extends3.default)({}, defaultConfig({ token: token }), config));
}

function remove(options) {
  var url = options.url,
      _options$config4 = options.config,
      config = _options$config4 === undefined ? {} : _options$config4,
      data = options.data,
      token = options.token;

  return _axios2.default.delete(url, (0, _extends3.default)({}, defaultConfig({ token: token }), { data: data }, config));
}

var extractLongFileName = function extractLongFileName(headers) {
  return headers['content-disposition'].split(' ')[1];
};
var pretifyLongFileName = function pretifyLongFileName(longFileName) {
  return longFileName ? longFileName.replace('filename=', '').replace(';', '') : 'download.csv';
};

var fileNameFromHeaders = (0, _compose2.default)(pretifyLongFileName, extractLongFileName);

var blobFactory = function blobFactory(headers, data) {
  return new Blob([data], {
    type: headers['content-type'] || 'application/octet-stream'
  });
};

var downloadWithHiddenAnchorTag = function downloadWithHiddenAnchorTag(blob, fileName) {
  var blobURL = window.URL.createObjectURL(blob);
  var tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  window.document.body.appendChild(tempLink);
  tempLink.click();
  window.document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
};

var fileDownload = exports.fileDownload = function fileDownload(_ref2) {
  var headers = _ref2.headers,
      data = _ref2.data;

  var fileName = fileNameFromHeaders(headers);
  var blob = blobFactory(headers, data);

  return navigator.msSaveBlob ? navigator.msSaveBlob(blob, fileName) : downloadWithHiddenAnchorTag(blob, fileName);
};

function httpClientIntercept(next) {
  _axios2.default.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    var config = error.config,
        response = error.response;
    // eslint-disable-next-line no-console

    console.warn('[HTTP ERROR INTERCEPTOR]:', error);

    next(response);

    return _promise2.default.reject((0, _extends3.default)({
      message: error.message
    }, config && {
      type: axiosRequestErrorMap[error.config && error.config.method.toLowerCase()]
    }, response && { status: response.status, data: response.data }));
  });
}