'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    border: none;\n    height: auto;\n    padding-top: 0;\n  '], ['\n    border: none;\n    height: auto;\n    padding-top: 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: ', ';\n    justify-content: normal;\n'], ['\n    display: ', ';\n    justify-content: normal;\n']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 130px;\n'], ['\n    width: 130px;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../Global');

var _CarfaxLogo = require('../CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

var _VdpRetailerLocation = require('./VdpRetailerLocation');

var _VdpRetailerLocation2 = _interopRequireDefault(_VdpRetailerLocation);

var _VdpUserActions = require('./VdpUserActions');

var _VdpUserActions2 = _interopRequireDefault(_VdpUserActions);

var _VdpVehiclePrice = require('./VdpVehiclePrice');

var _VdpVehiclePrice2 = _interopRequireDefault(_VdpVehiclePrice);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpHighlights__Container',
  componentId: 'sc-15j5fop-0'
})(['display:flex;width:100%;border:1px solid #dddddd;border-bottom:0;box-sizing:border-box;background-color:#ffffff;flex-direction:column;margin:0 auto;flex-flow:column;align-items:stretch;height:602px;', ';'], _theme2.default.max.medium(_templateObject));

var HighlightCellLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpHighlights__HighlightCellLabel',
  componentId: 'sc-15j5fop-1'
})(['font-size:14px;']);

var DesignHighlightsDataRow = _styledComponents2.default.div.withConfig({
  displayName: 'VdpHighlights__DesignHighlightsDataRow',
  componentId: 'sc-15j5fop-2'
})(['display:flex;flex-direction:row;font-size:14px;line-height:20px;']);
var DesignHighlightsLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpHighlights__DesignHighlightsLabel',
  componentId: 'sc-15j5fop-3'
})(['font-weight:bold;min-width:60px;']);

var DesignHighlightsValue = _styledComponents2.default.div.withConfig({
  displayName: 'VdpHighlights__DesignHighlightsValue',
  componentId: 'sc-15j5fop-4'
})(['color:#333333;padding-left:5px;']);

var MainInfoSection = (0, _styledComponents2.default)(_Global.Div).withConfig({
  displayName: 'VdpHighlights__MainInfoSection',
  componentId: 'sc-15j5fop-5'
})(['display:flex;flex-direction:column;border-bottom:solid 1px #d8d8d8;padding:10px 20px;', ';'], _theme2.default.max.medium(_templateObject2, function (_ref) {
  var onlyDesktop = _ref.onlyDesktop;
  return onlyDesktop ? 'none' : 'flex';
}));

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'VdpHighlights__Logo',
  componentId: 'sc-15j5fop-6'
})(['width:140px;', ';'], _theme2.default.max.medium(_templateObject3));

var VdpHighlights = function VdpHighlights(_ref2) {
  var config = _ref2.config,
      vehicleInfo = _ref2.vehicleInfo,
      translations = _ref2.translations,
      globalStyling = _ref2.globalStyling,
      makeAnEnquiry = _ref2.makeAnEnquiry,
      makeATestDriveRequest = _ref2.makeATestDriveRequest,
      makeAReservation = _ref2.makeAReservation,
      socialShare = _ref2.socialShare,
      printVehiclePdf = _ref2.printVehiclePdf,
      printingState = _ref2.printingState,
      headerFont = _ref2.headerFont,
      handleZeroPriceAsText = _ref2.handleZeroPriceAsText,
      handlePrice = _ref2.handlePrice;

  var showSpecification = vehicleInfo.specification && (vehicleInfo.specification.exterior || vehicleInfo.specification.interior || vehicleInfo.specification.veneer);

  var HighLight = function HighLight(_ref3) {
    var label = _ref3.label,
        value = _ref3.value;
    return _react2.default.createElement(
      DesignHighlightsDataRow,
      null,
      _react2.default.createElement(
        DesignHighlightsLabel,
        null,
        label
      ),
      _react2.default.createElement(
        DesignHighlightsValue,
        null,
        value
      )
    );
  };

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      Container,
      { 'data-cy': 'my-deal' },
      _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          MainInfoSection,
          {
            styleOverride: function styleOverride() {
              return '\n                flex-direction: row;\n                justify-content: space-between;\n                align-items: center;\n            ';
            }
          },
          _react2.default.createElement(
            _Global.DesktopOnly,
            null,
            _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return '' + (0, _Global.fontStyleOverride)(config.highlightsFont);
                }
              },
              translations.designHighlights
            )
          ),
          _react2.default.createElement(
            _Global.MobileOnly,
            null,
            _react2.default.createElement(_VdpVehiclePrice2.default, {
              handlePrice: handlePrice,
              vehicleInfo: vehicleInfo,
              headerFont: headerFont,
              translations: translations,
              handleZeroPriceAsText: handleZeroPriceAsText,
              config: config,
              style: { display: 'flex', alignSelf: 'start' }
            }),
            vehicleInfo.carfax && _react2.default.createElement(
              'div',
              { style: { marginTop: '10px' } },
              _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicleInfo.carfax })
            )
          ),
          _react2.default.createElement(Logo, {
            src: config.mainInfoLogo,
            style: { display: 'flex', alignSelf: 'start' }
          })
        ),
        showSpecification && _react2.default.createElement(
          MainInfoSection,
          null,
          vehicleInfo.specification.exterior && _react2.default.createElement(HighLight, {
            label: translations.exterior,
            value: vehicleInfo.specification.exterior
          }),
          vehicleInfo.specification.interior && _react2.default.createElement(HighLight, {
            label: translations.interior,
            value: vehicleInfo.specification.interior
          }),
          vehicleInfo.specification.veneer && _react2.default.createElement(HighLight, {
            label: translations.veneer,
            value: vehicleInfo.specification.veneer
          })
        ),
        vehicleInfo.bespokeFeatures && _react2.default.createElement(
          MainInfoSection,
          null,
          vehicleInfo.bespokeFeatures.slice(0, 5).map(function (feature) {
            return _react2.default.createElement(
              DesignHighlightsDataRow,
              { key: feature.name },
              _react2.default.createElement(
                HighlightCellLabel,
                null,
                feature.name
              )
            );
          })
        ),
        _react2.default.createElement(
          MainInfoSection,
          {
            styleOverride: function styleOverride() {
              return '\n                flex: 1;\n                justify-content: center;\n            ';
            },
            maxMediumMobileStyleOverride: function maxMediumMobileStyleOverride() {
              return '\n              flex: 0;\n              padding-bottom: 20px;\n            ';
            }
          },
          _react2.default.createElement(_VdpUserActions2.default, {
            config: config,
            globalStyling: globalStyling,
            translations: translations,
            makeATestDriveRequest: makeATestDriveRequest,
            makeAReservation: makeAReservation,
            makeAnEnquiry: makeAnEnquiry,
            vehicleInfo: vehicleInfo,
            socialShare: socialShare,
            printingState: printingState,
            printVehiclePdf: printVehiclePdf
          })
        ),
        _react2.default.createElement(
          MainInfoSection,
          {
            styleOverride: function styleOverride() {
              return '\n                flex-direction: row;\n                align-items: center;\n                padding-left: 50px;\n                justify-content: center;\n                height: 40px;\n            ';
            },
            onlyDesktop: true
          },
          _react2.default.createElement(_VdpRetailerLocation2.default, {
            config: config,
            globalStyling: globalStyling,
            retailerName: vehicleInfo.retailerInformation && vehicleInfo.retailerInformation.name
          })
        )
      )
    )
  );
};

VdpHighlights.defaultProps = {
  iconColour: '#9E1B32'
};

exports.default = VdpHighlights;