'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\nmargin: 0 0 40px 24px;\n'], ['\nmargin: 0 0 40px 24px;\n']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n  margin: 80px 0 0 24px;\n'], ['\n  margin: 80px 0 0 24px;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _framerMotion = require('framer-motion');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _useWindowSize = require('../../../hooks/useWindowSize');

var _useWindowSize2 = _interopRequireDefault(_useWindowSize);

var _types = require('./types');

var _Slide = require('./Slide');

var _Slide2 = _interopRequireDefault(_Slide);

var _useAnimatedCarouselState = require('./useAnimatedCarouselState');

var _useAnimatedCarouselState2 = _interopRequireDefault(_useAnimatedCarouselState);

var _AstonLink = require('../AstonLink');

var _AstonLink2 = _interopRequireDefault(_AstonLink);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../../../dist/components/Global');

var _reactRedux = require('react-redux');

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

var _useCheckIsMobileScreen = require('../../../../dist/hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'AnimatedCarousel__Container',
  componentId: 'sc-ik2knc-0'
})(['width:100%;']);

var CarouselBody = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'AnimatedCarousel__CarouselBody',
  componentId: 'sc-ik2knc-1'
})(['display:flex;']);

var Carousel = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'AnimatedCarousel__Carousel',
  componentId: 'sc-ik2knc-2'
})(['overflow:hidden;']);

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'AnimatedCarousel__HeaderContainer',
  componentId: 'sc-ik2knc-3'
})(['', ' ', ';'], function (_ref) {
  var initialMargin = _ref.initialMargin;
  return 'margin:  0 0 60px ' + initialMargin + 'px;';
}, _theme2.default.max.medium(_templateObject));
var FooterLinkContainer = _styledComponents2.default.div.withConfig({
  displayName: 'AnimatedCarousel__FooterLinkContainer',
  componentId: 'sc-ik2knc-4'
})(['', ' ', ';'], function (_ref2) {
  var initialMargin = _ref2.initialMargin;
  return 'margin:  100px 0 0 ' + initialMargin + 'px;';
}, _theme2.default.max.medium(_templateObject2));

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'AnimatedCarousel__Title',
  componentId: 'sc-ik2knc-5'
})(['text-transform:uppercase;font-family:AstonMartinFlare-Medium;font-size:16px;font-weight:500;letter-spacing:0;line-height:22px;']);

var Subtitle = _styledComponents2.default.div.withConfig({
  displayName: 'AnimatedCarousel__Subtitle',
  componentId: 'sc-ik2knc-6'
})(['margin-top:16px;font-family:AstonMartinSans-Light;font-size:14px;font-weight:300;letter-spacing:0;line-height:18px;']);

var FooterLink = (0, _styledComponents2.default)(Title).withConfig({
  displayName: 'AnimatedCarousel__FooterLink',
  componentId: 'sc-ik2knc-7'
})(['font-size:14px;']);
var NextContainer = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'AnimatedCarousel__NextContainer',
  componentId: 'sc-ik2knc-8'
})(['top:0;position:absolute;height:100%;width:16.66%;z-index:', ';opacity:0;right:0;'], function (_ref3) {
  var mobile = _ref3.mobile;
  return mobile ? -1 : 2;
});
var PrevContainer = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'AnimatedCarousel__PrevContainer',
  componentId: 'sc-ik2knc-9'
})(['top:0;position:absolute;height:100%;width:16.66%;z-index:', ';opacity:0;left:0;'], function (_ref4) {
  var mobile = _ref4.mobile;
  return mobile ? -1 : 2;
});

var Cursor = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'AnimatedCarousel__Cursor',
  componentId: 'sc-ik2knc-10'
})(['position:absolute;z-index:10;display:flex;flex-flow:row;flex-wrap:wrap;align-content:center;justify-content:center;height:46px;width:46px;background-color:', ';color:', ';border-radius:50%;pointer-events:none;'], function (_ref5) {
  var bgcolor = _ref5.bgcolor;
  return bgcolor || '#FFF';
}, function (_ref6) {
  var color = _ref6.color;
  return color || '#000';
});

var cursorVariants = {
  default: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.1
    }
  },
  hoverNext: {
    opacity: 1,
    scale: 1
  },
  hoverPrev: {
    opacity: 1,
    scale: 1
  },
  exit: {
    scale: 0,
    transition: { duration: 0.1 }
  },
  initial: {
    scale: 0.5,
    opacity: 1
  }
};

var carouselVariants = {
  hoverStart: function hoverStart(_ref7) {
    var posX = _ref7.posX;

    return {
      x: posX,
      transition: {
        duration: 0.3
      }
    };
  },
  hoverEnd: function hoverEnd(_ref8) {
    var posX = _ref8.posX;

    return {
      x: posX,
      transition: {
        duration: 0.3
      }
    };
  },
  click: function click(_ref9) {
    var posX = _ref9.posX;

    return {
      x: posX,
      transition: {
        duration: 0.5
      }
    };
  }
};

var AnimatedCarousel = function AnimatedCarousel(_ref10) {
  var title = _ref10.title,
      footerLinkText = _ref10.footerLinkText,
      footerLink = _ref10.footerLink,
      _ref10$items = _ref10.items,
      items = _ref10$items === undefined ? [] : _ref10$items,
      subtitle = _ref10.subtitle,
      nextIconUrl = _ref10.nextIconUrl,
      prevIconUrl = _ref10.prevIconUrl,
      plusIconUrl = _ref10.plusIconUrl,
      _ref10$initialMargin = _ref10.initialMargin,
      initialMargin = _ref10$initialMargin === undefined ? _useAnimatedCarouselState.CarouselConstants.INITIAL_MARGIN : _ref10$initialMargin;

  var carouselRef = (0, _react.useRef)(null);
  var windowSize = (0, _useWindowSize2.default)();

  var _useAnimatedCarouselS = (0, _useAnimatedCarouselState2.default)(),
      _useAnimatedCarouselS2 = (0, _slicedToArray3.default)(_useAnimatedCarouselS, 2),
      carouselState = _useAnimatedCarouselS2[0],
      dispatch = _useAnimatedCarouselS2[1];

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl,
      navigateInternalUrl = _useNavigation.navigateInternalUrl;

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      colours = _useSelector.colours;

  var isMobile = (0, _useCheckIsMobileScreen2.default)();

  var _useState = (0, _react.useState)({
    x: undefined,
    y: undefined
  }),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      mousePosition = _useState2[0],
      setMousePosition = _useState2[1];

  var clientWidth = carouselState.clientWidth,
      cursor = carouselState.cursor,
      isDragging = carouselState.isDragging,
      posXNew = carouselState.posXNew,
      selectedVariant = carouselState.selectedVariant,
      width = carouselState.width,
      cursorVariant = carouselState.cursorVariant,
      currentPos = carouselState.currentPos;


  (0, _react.useLayoutEffect)(function () {
    if (!carouselRef.current || !windowSize.width) {
      return;
    }

    var div = carouselRef.current;
    dispatch({
      type: _types.types.SET_WIDTH,
      payload: { value: div.scrollWidth - div.offsetWidth }
    });
  }, [windowSize, carouselRef]);

  var handleUpdatePos = (0, _react.useCallback)(function (latest) {
    dispatch({
      type: _types.types.SET_CURRENT_POS,
      payload: {
        value: Number(latest.x)
      }
    });
  }, []);

  var handleClientUpdate = (0, _react.useCallback)(function (_ref11) {
    var clientWidth = _ref11.clientWidth;

    dispatch({
      type: _types.types.UPDATE_CLIENT_WIDTH,
      payload: { value: clientWidth }
    });
  }, []);

  var handleHover = (0, _react.useCallback)(function (action) {
    dispatch({
      type: action,
      payload: undefined
    });
  }, []);

  var dragConstraints = (0, _react.useMemo)(function () {
    return { right: 0, left: -width };
  }, [width]);

  var handleSlideClick = (0, _react.useCallback)(function (_ref12) {
    var exploreLink = _ref12.exploreLink;

    dispatch({
      type: _types.types.SET_DRAGGING,
      payload: { value: false }
    });

    if (isDragging) {
      return;
    }
    navigateInternalUrl(exploreLink);
  }, [isDragging]);

  var onMouseMove = function onMouseMove(e) {
    if (!carouselRef || !carouselRef.current) {
      return;
    }
    var element = carouselRef.current;

    var _element$getBoundingC = element.getBoundingClientRect(),
        left = _element$getBoundingC.left,
        top = _element$getBoundingC.top;

    var newX = e.clientX - left;
    var newY = e.clientY - top;
    setMousePosition({ x: newX, y: newY });

    //handle hover in the first slide
    if (currentPos !== 0) {
      return;
    }

    if (newX <= initialMargin) {
      dispatch({
        type: _types.types.SET_CURSOR_VARIANT,
        payload: { value: 'none' }
      });
    } else if (newX <= (windowSize.width || 0) * _useAnimatedCarouselState.CarouselConstants.PERCENTAGE) {
      dispatch({
        type: _types.types.SET_CURSOR_VARIANT,
        payload: { value: 'default' }
      });
    }
  };

  var cursorIcon = (0, _react.useMemo)(function () {
    if (cursorVariant === 'none') {
      return;
    }

    if (cursorVariant === 'default' || posXNew >= 0 || // first Slide
    width - Math.abs(posXNew) === 0 // Last Slide
    ) {
        return plusIconUrl;
      }

    return cursorVariant === 'hoverPrev' ? prevIconUrl : nextIconUrl;
  }, [cursorVariant, width, posXNew, mousePosition]);

  (0, _react.useEffect)(function () {
    if (currentPos > 0 && !isDragging) {
      dispatch({ type: _types.types.MOVE_X, payload: { value: 0 } });
    }
    if (Math.abs(currentPos) > Math.abs(width)) {
      dispatch({ type: _types.types.MOVE_X, payload: { value: -width } });
    }
  }, [currentPos, isDragging, width]);

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      HeaderContainer,
      { initialMargin: initialMargin },
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          'data-cy': 'animatedCarousel-title',
          styleOverride: function styleOverride() {
            return '\n         font-family: AstonMartinFlare-Medium;\n         font-size: 16px;\n         color: #1A1D1D;\n         text-transform: uppercase;\n         line-height: 22px;\n       ';
          }
        },
        title
      ),
      subtitle && _react2.default.createElement(
        Subtitle,
        null,
        subtitle
      )
    ),
    _react2.default.createElement(
      _framerMotion.AnimatePresence,
      null,
      !isMobile && mousePosition.x && mousePosition.y && _react2.default.createElement(
        Cursor,
        {
          style: {
            x: mousePosition.x - 18,
            y: mousePosition.y - 18
          },
          initial: 'initial',
          exit: 'exit',
          whileTap: { scale: 2.9 },
          variants: cursorVariants,
          animate: cursorVariant,
          bgcolor: colours.secondaryBackgroundColour.value
        },
        cursorIcon && _react2.default.createElement('img', { width: 18, height: 18, src: cursorIcon })
      )
    ),
    _react2.default.createElement(
      Carousel,
      {
        ref: carouselRef,
        style: {
          cursor: isMobile ? cursor : 'none',
          position: 'relative'
        },
        onMouseMove: onMouseMove,
        onMouseLeave: function onMouseLeave() {
          setMousePosition({ x: undefined, y: undefined });
        }
      },
      _react2.default.createElement(PrevContainer, {
        mobile: isMobile,
        onHoverStart: function onHoverStart() {
          handleHover(_types.types.HOVER_START_PREV);
        },
        onHoverEnd: function onHoverEnd() {
          handleHover(_types.types.HOVER_END_PREV);
        },
        onMouseEnter: function onMouseEnter() {
          dispatch({
            type: _types.types.SET_CURSOR_VARIANT,
            payload: { value: 'hoverPrev' }
          });
        },
        onMouseLeave: function onMouseLeave() {
          dispatch({
            type: _types.types.SET_CURSOR_VARIANT,
            payload: { value: 'default' }
          });
        },
        onClick: function onClick() {
          if (posXNew === 0 && cursorVariant !== 'none') {
            //first slide
            var item = items[0];
            if (!item) {
              return;
            }
            navigateInternalUrl(item.exploreLink);
          }
          dispatch({
            type: _types.types.MOVE_X,
            payload: {
              value: clientWidth
            }
          });
        }
      }),
      _react2.default.createElement(
        CarouselBody,
        {
          key: width,
          drag: 'x',
          dragConstraints: dragConstraints,
          onUpdate: handleUpdatePos,
          onDragStart: function onDragStart() {
            dispatch({
              type: _types.types.SET_DRAGGING,
              payload: { value: true }
            });
          },
          onDragTransitionEnd: function onDragTransitionEnd() {
            dispatch({
              type: _types.types.SET_DRAGGING,
              payload: { value: false }
            });
          },
          variants: carouselVariants,
          animate: selectedVariant,
          custom: { posX: posXNew }
        },
        items.map(function (item, index) {
          var translations = item.translations,
              props = (0, _objectWithoutProperties3.default)(item, ['translations']);


          return _react2.default.createElement(_Slide2.default, (0, _extends3.default)({
            key: index,
            index: index
          }, props, {
            exploreLinkText: translations.exploreLinkText,
            link: translations.link,
            subtitle: translations.subtitle,
            title: translations.title,
            initialMargin: initialMargin,
            onUpdate: handleClientUpdate,
            onClick: handleSlideClick,
            onMouseEnter: function onMouseEnter() {
              dispatch({
                type: _types.types.SET_CURSOR_VARIANT,
                payload: { value: 'default' }
              });
            },
            onMouseLeave: function onMouseLeave() {
              dispatch({
                type: _types.types.SET_CURSOR_VARIANT,
                payload: { value: 'none' }
              });
            }
          }));
        })
      ),
      _react2.default.createElement(NextContainer, {
        mobile: isMobile,
        onHoverStart: function onHoverStart() {
          handleHover(_types.types.HOVER_START_NEXT);
        },
        onMouseEnter: function onMouseEnter() {
          dispatch({
            type: _types.types.SET_CURSOR_VARIANT,
            payload: { value: 'hoverNext' }
          });
        },
        onMouseLeave: function onMouseLeave() {
          dispatch({
            type: _types.types.SET_CURSOR_VARIANT,
            payload: { value: 'default' }
          });
        },
        onHoverEnd: function onHoverEnd() {
          handleHover(_types.types.HOVER_END_NEXT);
        },
        onClick: function onClick() {
          if (width - Math.abs(posXNew) === 0) {
            //Last item
            var item = items.at(-1);
            if (!item) {
              return;
            }
            navigateInternalUrl(item.exploreLink);
          }
          dispatch({
            type: _types.types.MOVE_X,
            payload: {
              value: -clientWidth
            }
          });
        }
      })
    ),
    _react2.default.createElement(
      FooterLinkContainer,
      { initialMargin: initialMargin },
      _react2.default.createElement(
        FooterLink,
        null,
        _react2.default.createElement(_AstonLink2.default, {
          text: footerLinkText,
          target: getTargetUrl(footerLink),
          link: footerLink,
          colour: '#000000',
          fontFamily: 'AstonMartinFlare-Regular'
        })
      )
    )
  );
};

exports.default = AnimatedCarousel;