'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _history = require('history');

var _SubNavBar = require('../../../components/Bentley/SubNavBar');

var _SubNavBar2 = _interopRequireDefault(_SubNavBar);

var _types = require('../../../components/Bentley/SubNavBar/types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BentleySubNav = function BentleySubNav(_ref) {
  var config = _ref.config,
      history = _ref.history;

  return _react2.default.createElement(_SubNavBar2.default, { config: config, history: history });
};

exports.default = _react2.default.memo(BentleySubNav);