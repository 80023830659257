'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: ', ';\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  '], ['\n    width: ', ';\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  ']);

exports.default = ModalCurrency;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'ModalCurrency__Overlay',
  componentId: 'sc-pj395f-0'
})(['position:fixed;height:100vh;width:100%;top:0;left:0;background-color:rgba(0,0,0,0.6);opacity:1;visibility:visible;z-index:100;overflow:hidden;transition:visibility 0.35s,opacity 0.35s;']);

var ModalContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModalCurrency__ModalContentWrapper',
  componentId: 'sc-pj395f-1'
})(['width:100%;background-color:#ffffff;position:absolute;top:0;left:0;', ';'], _theme2.default.min.medium(_templateObject, function (_ref) {
  var modalWidth = _ref.modalWidth;
  return modalWidth ? modalWidth + 'px;' : 'auto';
}));

var ModalContent = _styledComponents2.default.div.withConfig({
  displayName: 'ModalCurrency__ModalContent',
  componentId: 'sc-pj395f-2'
})(['width:100%;height:100%;']);

function ModalCurrency(_ref2) {
  var children = _ref2.children,
      modalWidth = _ref2.modalWidth,
      shared = _ref2.shared;
  var _shared$sessionPrefer = shared.sessionPreferences,
      disableCurrencyPopup = _shared$sessionPrefer.disableCurrencyPopup,
      currency = _shared$sessionPrefer.currency;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    currency !== null && !disableCurrencyPopup && _react2.default.createElement(
      Overlay,
      null,
      _react2.default.createElement(
        ModalContentWrapper,
        { modalWidth: modalWidth },
        _react2.default.createElement(
          ModalContent,
          null,
          children
        )
      )
    )
  );
}