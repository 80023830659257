'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VehicleGeneralInformation = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n   margin: 40px 0 120px;\n  '], ['\n   margin: 40px 0 120px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n   font-size: 36px;\n  '], ['\n   font-size: 36px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 1fr;\n    width: 100%;\n    margin: 0;\n  '], ['\n    grid-template-columns: 1fr;\n    width: 100%;\n    margin: 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _vehicleTypes = require('../../../../types/Genesis/vehicleTypes');

var _types = require('../types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__Wrapper',
  componentId: 'sc-1c9oxpo-0'
})(['margin:40px 0;', ';'], _Genesis2.default.min.medium(_templateObject));

var SectionTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'VehicleGeneralInformation__SectionTitle',
  componentId: 'sc-1c9oxpo-1'
})(['color:#fff;font-family:\'GenesisSansHeadGlobal-Light\';font-size:24px;font-weight:normal;margin:10px 0 30px;text-transform:uppercase;', ';'], _Genesis2.default.min.medium(_templateObject2));

var InfoTable = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__InfoTable',
  componentId: 'sc-1c9oxpo-2'
})(['display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:20px 30px;', ';'], _Genesis2.default.max.medium(_templateObject3));

var InfoTableItem = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__InfoTableItem',
  componentId: 'sc-1c9oxpo-3'
})(['display:flex;flex-direction:row;border-bottom:1px solid #aaaba6;width:100%;']);

var InfoTableLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__InfoTableLabel',
  componentId: 'sc-1c9oxpo-4'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:14px;color:#fff;margin:10px 0;white-space:nowrap;']);

var InfoTableValue = (0, _styledComponents2.default)(InfoTableLabel).withConfig({
  displayName: 'VehicleGeneralInformation__InfoTableValue',
  componentId: 'sc-1c9oxpo-5'
})(['margin-left:10px;white-space:initial;']);

var VehicleGeneralInformation = function VehicleGeneralInformation(_ref) {
  var vehicle = _ref.vehicle,
      translations = _ref.translations;

  var _useMemo = (0, _react.useMemo)(function () {
    var interiorColour = {
      label: 'interiorColour',
      value: vehicle.specification.interior
    };
    var exteriorColour = {
      label: 'exteriorColour',
      value: vehicle.specification.exterior
    };
    var bodystyle = {
      label: 'bodystyle',
      value: vehicle.specification.bodystyle
    };
    var fuelType = {
      label: 'fuelType',
      value: vehicle.specification.fuelType
    };
    var odometer = {
      label: 'mileage',
      value: vehicle.specification.odometer.units === null ? translations.mileageZero : vehicle.specification.odometer.display
    };
    var transmission = {
      label: 'transmission',
      value: vehicle.specification.transmission
    };
    var registration = {
      label: 'registration',
      value: vehicle.registration
    };
    var handdrive = {
      label: 'driver',
      value: vehicle.handDrive
    };
    var capacity = {
      label: 'capacity',
      value: vehicle.specification.capacity + ' cc'
    };

    var summaryData = [interiorColour, exteriorColour, bodystyle, fuelType, odometer, transmission, registration, handdrive, capacity];

    return [summaryData.filter(function (item) {
      return !!item.value;
    })];
  }, [vehicle]),
      _useMemo2 = (0, _slicedToArray3.default)(_useMemo, 1),
      summaryInfo = _useMemo2[0];

  return _react2.default.createElement(
    Wrapper,
    { id: 'General-Information' },
    _react2.default.createElement(
      SectionTitle,
      null,
      translations.titleGeneralInformation
    ),
    _react2.default.createElement(
      InfoTable,
      null,
      summaryInfo.map(function (_ref2, index) {
        var label = _ref2.label,
            value = _ref2.value;
        return _react2.default.createElement(
          InfoTableItem,
          { key: index },
          _react2.default.createElement(
            InfoTableLabel,
            null,
            translations[label],
            ':'
          ),
          _react2.default.createElement(
            InfoTableValue,
            null,
            value
          )
        );
      })
    )
  );
};

exports.VehicleGeneralInformation = VehicleGeneralInformation;