'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    min-width: 100%;\n  '], ['\n    min-width: 100%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _isCentralRetailer = require('../../../helpers/isCentralRetailer');

var _isCentralRetailer2 = _interopRequireDefault(_isCentralRetailer);

var _RetailerInformation = require('../../../modules/RetailerInformation/RetailerInformation');

var _vehicleDetailsPageTypes = require('../../../types/Genesis/vehicleDetailsPageTypes');

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _Button = require('../Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _UserLocation = require('../UserLocation');

var _UserLocation2 = _interopRequireDefault(_UserLocation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__Wrapper',
  componentId: 'sc-wuje68-0'
})(['display:flex;flex-direction:column;height:100%;min-width:350px;', ''], _Genesis2.default.max.small(_templateObject));
var LocationLabel = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__LocationLabel',
  componentId: 'sc-wuje68-1'
})(['color:#fff;display:flex;font-family:\'GenesisSansHeadGlobal-Regular\';font-weight:normal;margin-bottom:10px;']);

var AddressLabel = _styledComponents2.default.address.withConfig({
  displayName: 'RetailerInfo__AddressLabel',
  componentId: 'sc-wuje68-2'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:14px;font-style:normal;color:#fff;']);

var ContactLink = _styledComponents2.default.a.withConfig({
  displayName: 'RetailerInfo__ContactLink',
  componentId: 'sc-wuje68-3'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:14px;text-decoration:none;color:#fff;']);

var Underline = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__Underline',
  componentId: 'sc-wuje68-4'
})(['width:100%;height:1px;background-color:#aaaba6;margin:10px 0;']);

var LocationContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__LocationContainer',
  componentId: 'sc-wuje68-5'
})(['margin:20px 0;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__ButtonContainer',
  componentId: 'sc-wuje68-6'
})(['display:flex;flex-direction:column;gap:10px;']);

var RetailerInfo = function RetailerInfo(_ref) {
  var config = _ref.config,
      translations = _ref.translations,
      vehicle = _ref.vehicle,
      retailer = _ref.retailer,
      viewRetailerStock = _ref.viewRetailerStock;

  var _useState = (0, _react.useState)({
    latitude: '',
    longitude: ''
  }),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      location = _useState2[0],
      setLocation = _useState2[1];

  var originCoordinates = location.latitude + ',' + location.longitude;
  var dealerCoordinates = vehicle.retailerInformation.location.coordinates[1] + ',' + vehicle.retailerInformation.location.coordinates[0];

  var directionsUrl = 'https://www.google.com/maps/dir/?api=1&origin=' + originCoordinates + '&destination=' + dealerCoordinates;

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      LocationLabel,
      { mobile: true },
      vehicle.retailerInformation.name
    ),
    vehicle.retailerInformation.address.map(function (address, index) {
      return _react2.default.createElement(
        AddressLabel,
        { key: index },
        address
      );
    }),
    _react2.default.createElement(Underline, null),
    !(0, _isCentralRetailer2.default)(vehicle.retailerInformation.id) && vehicle.retailerInformation.phone && _react2.default.createElement(
      ContactLink,
      { href: 'tel:' + vehicle.retailerInformation.phone },
      translations.phoneLabel,
      ': ',
      vehicle.retailerInformation.phone
    ),
    _react2.default.createElement(
      LocationContainer,
      null,
      _react2.default.createElement(_UserLocation2.default, {
        iconUrl: config.locateUserIconUrl,
        placeholderText: translations.locationPlaceholder,
        browserLocationCallback: function browserLocationCallback(lat, long) {
          setLocation({
            latitude: lat,
            longitude: long
          });
        },
        inputLocationCallback: function inputLocationCallback(lat, long) {
          setLocation({
            latitude: lat,
            longitude: long
          });
        },
        translations: translations,
        onButtonClick: function onButtonClick() {
          window.open(directionsUrl, '_blank');
        },
        inputTheme: 'light',
        displayButtonCTA: true
      })
    ),
    _react2.default.createElement(
      ButtonContainer,
      null,
      _react2.default.createElement(
        _Button2.default,
        {
          variant: 'secondary',
          onClick: function onClick() {
            return viewRetailerStock(retailer);
          },
          styleOverride: function styleOverride() {
            return 'width: 100%;';
          },
          'data-ca-tracking': 'retailerStock'
        },
        translations.viewDealerStock
      )
    )
  );
};

exports.default = RetailerInfo;