'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _BlogPost = require('./BlogPost');

var _BlogPost2 = _interopRequireDefault(_BlogPost);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _BlogPost2.default;