'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useShortListVehicle2 = require('../../hooks/useShortListVehicle');

var _vehicleTypes = require('../../types/AstonMartin/vehicleTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ShortlistButton__IconWrapper',
  componentId: 'sc-1yvy865-0'
})(['display:flex;align-items:center;cursor:pointer;']);

var ShortlistButton = function ShortlistButton(_ref) {
  var vehicle = _ref.vehicle,
      iconOn = _ref.iconOn,
      iconOff = _ref.iconOff;

  var _useShortListVehicle = (0, _useShortListVehicle2.useShortListVehicle)({
    vehicleId: vehicle.id
  }),
      isShortlisted = _useShortListVehicle.isShortlisted,
      toggleShortlist = _useShortListVehicle.toggleShortlist;

  return _react2.default.createElement(
    IconWrapper,
    {
      'data-qa-hook': 'shortlist-vehicle-button',
      onClick: function onClick() {
        return toggleShortlist(vehicle);
      }
    },
    _react2.default.createElement('img', {
      src: isShortlisted ? iconOn : iconOff,
      style: { width: '40px', height: '40px' },
      alt: ''
    })
  );
};

exports.default = ShortlistButton;