'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _reactRedux = require('react-redux');

var _router = require('../actions/router');

var _settings = require('../shared/selectors/settings');

var _ = require('..');

var _bands = require('../bands');

var bandModules = _interopRequireWildcard(_bands);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var navigate = _router.actions.navigate;


var bindActionToInstance = function bindActionToInstance(_ref, dispatch, action) {
  var page = _ref.page,
      instance = _ref.instance;
  return function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return dispatch(action.apply(undefined, [page, instance].concat(args)));
  };
};

var bindActionsToInstance = function bindActionsToInstance(moduleConfig, actions, dispatch) {
  return (0, _keys2.default)(actions).reduce(function (newActions, name) {
    return (0, _extends4.default)({}, newActions, (0, _defineProperty3.default)({}, name, bindActionToInstance(moduleConfig, dispatch, actions[name])));
  }, {});
};

var bindPathByModule = function bindPathByModule(pages, bands) {
  return function (moduleId) {
    var band = bands.find(function (m) {
      return m.id === moduleId || m.name === moduleId;
    });

    var _ref2 = pages.find(function (page) {
      return page.modules.includes(band.instanceId);
    }) || {
      relativePath: ''
    },
        relativePath = _ref2.relativePath;

    return relativePath;
  };
};

var bindNavigateIfInternal = function bindNavigateIfInternal(dispatch) {
  return function (href) {
    if (!href.match(/^http/)) {
      dispatch(navigate(href));
      return true;
    }
    return false;
  };
};

var useCompiledModule = function useCompiledModule(id, index, path) {
  var allModules = [].concat((0, _toConsumableArray3.default)((0, _values2.default)(_.modules)), (0, _toConsumableArray3.default)((0, _values2.default)(bandModules)));

  var _allModules$find = allModules.find(function (m) {
    return m.id === id;
  }),
      ReactComponent = _allModules$find.component,
      _allModules$find$acti = _allModules$find.actions,
      actions = _allModules$find$acti === undefined ? {} : _allModules$find$acti;

  var dispatch = (0, _reactRedux.useDispatch)();
  // we dont want to key in state by the locale as this may change!

  var _path$replace$split = path.replace(/^\/[a-z]{2}_[a-z]{2}\//, '/').split('/'),
      _path$replace$split2 = (0, _slicedToArray3.default)(_path$replace$split, 2),
      page = _path$replace$split2[1];

  var pagePath = '/' + page;
  var moduleConfig = {
    id: id,
    instance: index,
    page: pagePath
  };

  var moduleState = (0, _reactRedux.useSelector)(function (state) {
    return state.pages && state.pages[pagePath] && state.pages[pagePath][index];
  }) || {};

  var bands = (0, _reactRedux.useSelector)(function (state) {
    return state.config.bands;
  });
  var pages = (0, _reactRedux.useSelector)(function (state) {
    return state.config.pages;
  });

  var boundActions = bindActionsToInstance(moduleConfig, actions, dispatch);
  var shared = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  });
  var router = (0, _reactRedux.useSelector)(function (state) {
    return state.router;
  });
  var vehicleInContext = (0, _reactRedux.useSelector)(function (state) {
    return state.vehicleInContext;
  });
  var marketInfo = (0, _reactRedux.useSelector)(function (state) {
    return (0, _settings.getAVLConfig)(state);
  });
  var history = (0, _reactRedux.useSelector)(function (state) {
    return state.router.history;
  });
  var linkedModules = (0, _reactRedux.useSelector)(function (state) {
    return state.config.linked;
  });

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global;
  }),
      featureFlags = _useSelector.featureFlags,
      finance = _useSelector.finance,
      socialShare = _useSelector.socialShare,
      maps = _useSelector.maps,
      analytics = _useSelector.analytics;

  var hashRouting = (0, _reactRedux.useSelector)(function (state) {
    return state.config.settings.hashRouting;
  });

  var pathByModule = bindPathByModule((0, _values2.default)(pages), (0, _values2.default)(bands));
  var navigateIfInternal = bindNavigateIfInternal(dispatch);

  return {
    ReactComponent: ReactComponent,
    moduleConfig: moduleConfig,
    shared: shared,
    moduleState: moduleState,
    boundActions: boundActions,
    router: router,
    marketInfo: marketInfo,
    vehicleInContext: vehicleInContext,
    history: history,
    linkedModules: linkedModules,
    featureFlags: featureFlags,
    maps: maps,
    analytics: analytics,
    finance: finance,
    socialShare: socialShare,
    pathByModule: pathByModule,
    navigateIfInternal: navigateIfInternal,
    dispatch: dispatch,
    hashRouting: hashRouting
  };
};

exports.default = useCompiledModule;