'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _filters = require('../../../shared/filters');

var _numbers = require('../../../shared/localisation/numbers');

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

var _FilterPill = require('./FilterPill');

var _FilterPill2 = _interopRequireDefault(_FilterPill);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FilterPills__Wrapper',
  componentId: 'sc-aunky8-0'
})(['display:none;@media only screen and (min-width:993px){display:block;margin-right:auto;}']);

var PillsControls = _styledComponents2.default.div.withConfig({
  displayName: 'FilterPills__PillsControls',
  componentId: 'sc-aunky8-1'
})(['display:flex;padding:0 0 15px;']);

var ResultsCount = _styledComponents2.default.div.withConfig({
  displayName: 'FilterPills__ResultsCount',
  componentId: 'sc-aunky8-2'
})(['font-family:\'RivieraNights-Medium\';font-size:12px;text-transform:uppercase;letter-spacing:2px;margin-top:5px;']);

var FilterPills = function (_Component) {
  (0, _inherits3.default)(FilterPills, _Component);

  function FilterPills(props) {
    (0, _classCallCheck3.default)(this, FilterPills);

    var _this = (0, _possibleConstructorReturn3.default)(this, (FilterPills.__proto__ || (0, _getPrototypeOf2.default)(FilterPills)).call(this, props));

    Object.defineProperty(_this, 'createGeocoder', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (!_this.geocoder || _this.geocoder.notGeocoder) {
          var googleAvailable = _this.props.shared.google.googleAvailable;

          _this.geocoder = googleAvailable ? new window.google.maps.Geocoder() : { notGeocoder: true };
        }
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            shared = _this$props.shared,
            router = _this$props.router,
            dispatch = _this$props.dispatch,
            marketInfo = _this$props.marketInfo,
            translations = _this$props.config.translations;


        var updateFilters = function updateFilters(payload) {
          return dispatch(_filters.actions.updateFilters(payload));
        };

        var distanceDisplay = function distanceDisplay(value) {
          return (0, _translateFromTemplate.translateFromTemplate)('distanceDisplayText', {
            VALUE: value
          }, translations);
        };
        var co2UnitDisplay = function co2UnitDisplay(value) {
          return (0, _translateFromTemplate.translateFromTemplate)('co2UnitDisplayText', {
            VALUE: value
          }, translations);
        };
        var odometerDisplay = function odometerDisplay(value) {
          return (0, _translateFromTemplate.translateFromTemplate)('odometerDisplayText', {
            VALUE: Number(value.toString()).toLocaleString()
          }, translations);
        };
        var fuelConsumptionDisplay = function fuelConsumptionDisplay(value) {
          return (0, _translateFromTemplate.translateFromTemplate)('fuelConsumptionText', {
            VALUE: Number(value.toString()).toLocaleString()
          }, translations);
        };

        var currentCurrency = shared.sessionPreferences.currency;
        var currencySymbol = currentCurrency || marketInfo.currencyCode || 'EUR';

        var priceDisplay = function priceDisplay(min, max) {
          return (0, _numbers.localiseCurrency)(Number(min), marketInfo.locale, currentCurrency || currencySymbol, 0) + ' -\n        ' + (0, _numbers.localiseCurrency)(Number(max), marketInfo.locale, currentCurrency || currencySymbol, 0);
        };

        var yearRangeDisplay = function yearRangeDisplay(prefixTranslation) {
          return function (min, max) {
            return '' + (prefixTranslation || '') + (min || translations.any) + ' - ' + (max || translations.any);
          };
        };

        var selectedFilters = shared && shared.filters && shared.filters.selectedFilters;

        var RangePill = function RangePill(_ref) {
          var minFilterKey = _ref.minFilterKey,
              maxFilterKey = _ref.maxFilterKey,
              rangeDisplay = _ref.rangeDisplay;

          var min = selectedFilters && selectedFilters[minFilterKey];
          var max = selectedFilters && selectedFilters[maxFilterKey];

          return min || max ? _react2.default.createElement(_FilterPill2.default, {
            pillText: rangeDisplay ? rangeDisplay(min, max) : min + ' - ' + max,
            onClose: function onClose() {
              updateFilters([{ key: minFilterKey, value: null }, { key: maxFilterKey, value: null }]);
            }
          }) : null;
        };

        var getFilterDisplayText = function getFilterDisplayText(filterKey, item) {
          if (filterKey === 'model') {
            return item + ' ' + translations.filterPillFamily;
          }
          var availableFilterValues = shared && shared.filters && shared.filters.availableFilterValues && shared.filters.availableFilterValues[filterKey];

          var value = availableFilterValues && availableFilterValues.find(function (f) {
            return f.value.toString() === item;
          });

          return !value ? '' : translations[value.display] || value.display;
        };

        var MultiItemsPills = function MultiItemsPills(_ref2) {
          var filterKey = _ref2.filterKey;

          var items = selectedFilters && selectedFilters[filterKey];
          return items && items.length > 0 ? items.map(function (item) {
            var text = getFilterDisplayText(filterKey, item);
            if (text.length === 0) return null;
            return _react2.default.createElement(_FilterPill2.default, {
              key: '' + filterKey + item,
              pillText: text,
              onClose: function onClose() {
                return updateFilters([{
                  key: filterKey,
                  value: _filters.helpers.newArray(items, item)
                }]);
              }
            });
          }) : null;
        };

        var ValuePill = function ValuePill(_ref3) {
          var descriptionDisplay = _ref3.descriptionDisplay,
              filterKey = _ref3.filterKey;

          var value = selectedFilters && selectedFilters[filterKey];
          return value ? _react2.default.createElement(_FilterPill2.default, {
            pillText: descriptionDisplay(value),
            onClose: function onClose() {
              return updateFilters([{ key: filterKey, value: [] }]);
            }
          }) : null;
        };
        ValuePill.defaultProps = {
          descriptionDisplay: function descriptionDisplay(value) {
            return value;
          }
        };

        var _router$pathProps = router.pathProps,
            retailerId = _router$pathProps.retailerId,
            dealerGroup = _router$pathProps.dealerGroup;

        var resultsCountLabel = shared.filters.total !== 1 ? shared.filters.total + ' ' + translations.searchResultsAvailableAtLabel : '' + translations.searchResultsAvailableAtSingleLabel;

        var getDealerNameOrGroup = function getDealerNameOrGroup(sharedFilters) {
          if (sharedFilters.dealer) {
            return sharedFilters.dealer.name;
          }

          if (sharedFilters.selectedFilters && sharedFilters.selectedFilters.dealerGroup) {
            return sharedFilters.selectedFilters.dealerGroup;
          }

          return '';
        };

        var retailerLabel = (0, _translateFromTemplate.translateFromTemplate)(shared.filters.total !== 1 ? 'searchResultsAvailableRetailerLabel' : 'searchResultsAvailableRetailerSingleLabel', {
          COUNT: shared.filters.total,
          RETAILER: getDealerNameOrGroup(shared.filters)
        }, translations);

        var singularOrPluralHeading = function singularOrPluralHeading() {
          return retailerId || dealerGroup ? retailerLabel : resultsCountLabel;
        };

        var availableVehiclesText = function availableVehiclesText() {
          return shared.filters.total !== undefined && singularOrPluralHeading();
        };

        return _react2.default.createElement(
          Wrapper,
          null,
          _react2.default.createElement(
            PillsControls,
            null,
            _react2.default.createElement(
              ResultsCount,
              null,
              availableVehiclesText()
            )
          ),
          _react2.default.createElement(ValuePill, {
            descriptionDisplay: distanceDisplay,
            filterKey: 'maxDistance'
          }),
          _react2.default.createElement(RangePill, {
            minFilterKey: 'minPriceRange',
            maxFilterKey: 'maxPriceRange',
            rangeDisplay: priceDisplay
          }),
          !currentCurrency ? null : _react2.default.createElement(RangePill, {
            minFilterKey: 'minDefaultPrice',
            maxFilterKey: 'maxDefaultPrice',
            rangeDisplay: priceDisplay
          }),
          _react2.default.createElement(ValuePill, {
            descriptionDisplay: co2UnitDisplay,
            filterKey: 'co2Emissions'
          }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'model' }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'variant' }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'modelVariants' }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'vehicleTypes' }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'engine' }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'fuelType' }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'transmission' }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'bodystyle' }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'exteriorColours' }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'interiorShades' }),
          _react2.default.createElement(ValuePill, {
            descriptionDisplay: odometerDisplay,
            filterKey: 'maxOdometer'
          }),
          _react2.default.createElement(RangePill, {
            minFilterKey: 'yearRangeMin',
            maxFilterKey: 'yearRangeMax',
            rangeDisplay: yearRangeDisplay(translations.regYearPrefix)
          }),
          _react2.default.createElement(RangePill, {
            minFilterKey: 'modelYearRangeMin',
            maxFilterKey: 'modelYearRangeMax',
            rangeDisplay: yearRangeDisplay(translations.modelYearPrefix)
          }),
          _react2.default.createElement(ValuePill, {
            descriptionDisplay: fuelConsumptionDisplay,
            filterKey: 'maxFuelConsumption'
          }),
          _react2.default.createElement(MultiItemsPills, { filterKey: 'features' })
        );
      }
    });


    _this.state = {
      attempts: 0
    };
    return _this;
  }

  (0, _createClass3.default)(FilterPills, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      this.createGeocoder();
      var _props = this.props,
          shared = _props.shared,
          dispatch = _props.dispatch;


      var updateFilters = function updateFilters(payload) {
        return dispatch(_filters.actions.updateFilters(payload));
      };

      if (!shared.sessionPreferences.currency && (shared.filters.selectedFilters && shared.filters.selectedFilters.maxDefaultPrice || shared.filters.selectedFilters && shared.filters.selectedFilters.minDefaultPrice)) {
        updateFilters([{ key: 'minDefaultPrice', value: [] }, { key: 'maxDefaultPrice', value: [] }]);
      }
    }
  }]);
  return FilterPills;
}(_react.Component);

exports.default = FilterPills;