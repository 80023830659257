'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      margin: 0 auto;\n  '], ['\n      margin: 0 auto;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      min-height: 55vh;\n  '], ['\n      min-height: 55vh;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _Index = require('../../../components/RollsRoyce/Shortlist/Index');

var _Index2 = _interopRequireDefault(_Index);

var _AppShared = require('../../../types/AppShared');

var _shortlistPageTypes = require('../../../types/RollsRoyce/shortlistPageTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ShortListContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Shortlist__ShortListContainer',
  componentId: 'sc-1s1z88v-0'
})(['display:flex;flex-direction:column;width:100%;align-items:center;max-width:1920px;min-height:55vh;', ';margin:35px auto 10px;', ';', ';@media only screen and (max-device-width:1024px) and (max-device-width:1024px){min-height:75vh;}'], function () {
  return _RollsRoyce.mixins.pageGuttering();
}, _RollsRoyce2.default.max.large(_templateObject), _RollsRoyce2.default.min.extraLarge(_templateObject2));

var ShortList = function ShortList(_ref) {
  var config = _ref.config,
      shared = _ref.shared;
  var translations = config.translations,
      rest = (0, _objectWithoutProperties3.default)(config, ['translations']);
  var vehicles = shared.shortlist.vehicles,
      sessionPreferences = shared.sessionPreferences;


  return _react2.default.createElement(
    ShortListContainer,
    null,
    _react2.default.createElement(_Index2.default, {
      config: rest,
      translations: translations,
      vehicles: vehicles || [],
      locale: sessionPreferences.language,
      searchSort: sessionPreferences.searchSort,
      shared: shared
    })
  );
};

exports.default = ShortList;