'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _PathnameSubNavBar = require('../components/PathnameSubNavBar');

var _PathnameSubNavBar2 = _interopRequireDefault(_PathnameSubNavBar);

var _vehicleStore = require('../shared/selectors/vehicleStore');

var _router = require('../actions/router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mapStateToProps = function mapStateToProps(_ref, props) {
  var shared = _ref.shared;
  return (0, _extends3.default)({
    compare: (0, _vehicleStore.getCompareList)(props)(shared).length,
    shortlist: (0, _vehicleStore.getShortList)(props)(shared).length
  }, props);
};


var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    goToPath: function goToPath(path) {
      return dispatch(_router.actions.navigate(path));
    }
  };
};

function SubNav(_ref2) {
  var goToPath = _ref2.goToPath,
      preview = _ref2.preview,
      title = _ref2.title,
      compare = _ref2.compare,
      shortlist = _ref2.shortlist,
      onBackButtonClick = _ref2.onBackButtonClick,
      translations = _ref2.translations,
      subNavBGColour = _ref2.subNavBGColour,
      subNavBorderColour = _ref2.subNavBorderColour,
      subNavChevronColour = _ref2.subNavChevronColour,
      subNavFontTheme = _ref2.subNavFontTheme,
      subNavBarItemSelectedBackgroundColour = _ref2.subNavBarItemSelectedBackgroundColour,
      subNavBarItemSelectedFontColour = _ref2.subNavBarItemSelectedFontColour,
      contentWidth = _ref2.contentWidth,
      showNavBarItemBorder = _ref2.showNavBarItemBorder,
      subNavHeight = _ref2.subNavHeight,
      alignMenuItems = _ref2.alignMenuItems,
      navBarBottomBorder = _ref2.navBarBottomBorder,
      globalStyling = _ref2.globalStyling,
      history = _ref2.history;

  var fnIfNotPreview = function fnIfNotPreview(fn) {
    return preview ? function () {} : fn;
  };
  return _react2.default.createElement(_PathnameSubNavBar2.default, {
    subNavFontTheme: subNavFontTheme,
    subNavBGColour: subNavBGColour,
    subNavBorderColour: subNavBorderColour,
    subNavChevronColour: subNavChevronColour,
    showNavBarItemBorder: showNavBarItemBorder,
    subNavHeight: subNavHeight,
    alignMenuItems: alignMenuItems,
    navBarBottomBorder: navBarBottomBorder,
    title: title,
    onBackButtonClick: onBackButtonClick,
    links: [{
      text: translations.compareLink + ' ' + (compare ? '(' + compare + ')' : ''),
      onClick: fnIfNotPreview(function () {
        return goToPath('/compare');
      }),
      availableOnMobile: false,
      path: 'compare'
    }, {
      text: translations.shortlistLink + ' ' + (shortlist ? '(' + shortlist + ')' : ''),
      onClick: fnIfNotPreview(function () {
        return goToPath('/shortlist');
      }),
      availableOnMobile: true,
      path: 'shortlist'
    }],
    subNavBarItemSelectedBackgroundColour: subNavBarItemSelectedBackgroundColour,
    subNavBarItemSelectedFontColour: subNavBarItemSelectedFontColour,
    contentWidth: contentWidth,
    globalStyling: globalStyling,
    history: history
  });
}

exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(SubNav);