'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    height: auto;\n  '], ['\n    width: 100%;\n    height: auto;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    margin-right: 0;\n  '], ['\n    width: 100%;\n    margin-right: 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

exports.default = SortMenuBar;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _GridEnabled = require('../JaguarIcons/SearchResults/GridEnabled');

var _GridEnabled2 = _interopRequireDefault(_GridEnabled);

var _GridDisabled = require('../JaguarIcons/SearchResults/GridDisabled');

var _GridDisabled2 = _interopRequireDefault(_GridDisabled);

var _ListEnabled = require('../JaguarIcons/SearchResults/ListEnabled');

var _ListEnabled2 = _interopRequireDefault(_ListEnabled);

var _ListDisabled = require('../JaguarIcons/SearchResults/ListDisabled');

var _ListDisabled2 = _interopRequireDefault(_ListDisabled);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SortContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SortMenuBar__SortContainer',
  componentId: 'sc-h4sn24-0'
})(['display:flex;align-items:center;height:90px;width:', ';justify-content:flex-end;', ';'], function (props) {
  return props.filtersExpanded ? '100%' : '50%';
}, _theme2.default.max.large(_templateObject));
var Sort = _styledComponents2.default.div.withConfig({
  displayName: 'SortMenuBar__Sort',
  componentId: 'sc-h4sn24-1'
})(['display:flex;', ';'], _theme2.default.max.large(_templateObject2));
var Button = _styledComponents2.default.button.withConfig({
  displayName: 'SortMenuBar__Button',
  componentId: 'sc-h4sn24-2'
})(['padding:0;margin:0 5px 0 0;border:none;background:none;min-height:45px;cursor:pointer;&:focus{outline:none;}&:last-child{margin:0 0 0 5px;}']);
var Select = _styledComponents2.default.select.withConfig({
  displayName: 'SortMenuBar__Select',
  componentId: 'sc-h4sn24-3'
})(['', ';margin-right:10px;min-width:250px;height:45px;font-size:14px;line-height:40px;box-sizing:border-box;border:1px solid #999999;padding-left:15px;padding-right:15px;color:#444444;background-color:#fff;', ';&:focus{outline:none;}'], function (_ref) {
  var fontFamily = _ref.fontFamily;
  return '' + (fontFamily && 'font-family: ' + fontFamily);
}, _theme2.default.max.large(_templateObject3));

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'SortMenuBar__IconWrapper',
  componentId: 'sc-h4sn24-4'
})(['width:45px;height:45px;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SortMenuBar__ButtonContainer',
  componentId: 'sc-h4sn24-5'
})(['', ';'], _theme2.default.max.large(_templateObject4));

var SortButtonsRenderer = function SortButtonsRenderer(_ref2) {
  var selectedView = _ref2.selectedView,
      onViewChange = _ref2.onViewChange,
      gridSelected = _ref2.gridSelected,
      gridDeselected = _ref2.gridDeselected,
      listSelected = _ref2.listSelected,
      listDeselected = _ref2.listDeselected,
      optionalSelectedColour = _ref2.optionalSelectedColour,
      optionalSelectedBgColour = _ref2.optionalSelectedBgColour;

  switch (selectedView) {
    case 'GRID':
      return _react2.default.createElement(
        ButtonContainer,
        null,
        _react2.default.createElement(
          Button,
          {
            'data-cy': 'sort-button-grid',
            onClick: function onClick() {
              return onViewChange('GRID');
            }
          },
          gridSelected ? _react2.default.createElement(IconWrapper, { src: gridSelected, alt: '' }) : _react2.default.createElement(_GridEnabled2.default, {
            background: optionalSelectedBgColour,
            colour: optionalSelectedColour,
            width: '45px',
            height: '45px'
          })
        ),
        _react2.default.createElement(
          Button,
          {
            'data-cy': 'sort-button-list',
            onClick: function onClick() {
              return onViewChange('LIST');
            }
          },
          listDeselected ? _react2.default.createElement(IconWrapper, { src: listDeselected, alt: '' }) : _react2.default.createElement(_ListDisabled2.default, { width: '45px', height: '45px' })
        )
      );
    case 'LIST':
      return _react2.default.createElement(
        ButtonContainer,
        null,
        _react2.default.createElement(
          Button,
          {
            'data-cy': 'sort-button-grid',
            onClick: function onClick() {
              return onViewChange('GRID');
            }
          },
          gridDeselected ? _react2.default.createElement(IconWrapper, { src: gridDeselected, alt: '' }) : _react2.default.createElement(_GridDisabled2.default, { width: '45px', height: '45px' })
        ),
        _react2.default.createElement(
          Button,
          {
            'data-cy': 'sort-button-list',
            onClick: function onClick() {
              return onViewChange('LIST');
            }
          },
          listSelected ? _react2.default.createElement(IconWrapper, { src: listSelected, alt: '' }) : _react2.default.createElement(_ListEnabled2.default, {
            background: optionalSelectedBgColour,
            colour: optionalSelectedColour,
            width: '45px',
            height: '45px'
          })
        )
      );
    default:
      return null;
  }
};

function SortMenuBar(_ref3) {
  var sortOptions = _ref3.sortOptions,
      sortValue = _ref3.sortValue,
      filtersExpanded = _ref3.filtersExpanded,
      onSortChange = _ref3.onSortChange,
      selectedView = _ref3.selectedView,
      onViewChange = _ref3.onViewChange,
      translations = _ref3.translations,
      showDistance = _ref3.showDistance,
      _ref3$showMileage = _ref3.showMileage,
      showMileage = _ref3$showMileage === undefined ? true : _ref3$showMileage,
      _ref3$showAge = _ref3.showAge,
      showAge = _ref3$showAge === undefined ? true : _ref3$showAge,
      _ref3$showPrice = _ref3.showPrice,
      showPrice = _ref3$showPrice === undefined ? true : _ref3$showPrice,
      fontFamily = _ref3.fontFamily,
      optionalSelectedColour = _ref3.optionalSelectedColour,
      optionalSelectedBgColour = _ref3.optionalSelectedBgColour,
      gridSelected = _ref3.gridSelected,
      gridDeselected = _ref3.gridDeselected,
      listSelected = _ref3.listSelected,
      listDeselected = _ref3.listDeselected;

  return _react2.default.createElement(
    SortContainer,
    { filtersExpanded: filtersExpanded },
    _react2.default.createElement(
      Sort,
      null,
      _react2.default.createElement(
        Select,
        {
          'data-cy': 'sort-dropdown',
          value: sortValue,
          onChange: function onChange(e) {
            return onSortChange(e.target.value);
          },
          fontFamily: fontFamily
        },
        showPrice && _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            'option',
            { value: sortOptions.priceAsc.value },
            translations.searchResultsSortPriceAscLabel
          ),
          _react2.default.createElement(
            'option',
            { value: sortOptions.priceDesc.value },
            translations.searchResultsSortPriceDescLabel
          )
        ),
        showMileage && _react2.default.createElement(
          'option',
          { value: sortOptions.mileageAsc.value },
          translations.searchResultsSortMileageAscLabel
        ),
        showMileage && _react2.default.createElement(
          'option',
          { value: sortOptions.mileageDesc.value },
          translations.searchResultsSortMileageDescLabel
        ),
        showAge && _react2.default.createElement(
          'option',
          { value: sortOptions.ageAsc.value },
          translations.searchResultsSortAgeAscLabel
        ),
        showAge && _react2.default.createElement(
          'option',
          { value: sortOptions.ageDesc.value },
          translations.searchResultsSortAgeDescLabel
        ),
        showDistance && _react2.default.createElement(
          'option',
          { value: sortOptions.distanceAsc.value },
          translations.searchResultsSortDistanceLabel
        )
      ),
      _react2.default.createElement(SortButtonsRenderer, {
        selectedView: selectedView,
        onViewChange: onViewChange,
        gridSelected: gridSelected,
        gridDeselected: gridDeselected,
        listSelected: listSelected,
        listDeselected: listDeselected,
        optionalSelectedColour: optionalSelectedColour,
        optionalSelectedBgColour: optionalSelectedBgColour
      })
    )
  );
}
SortMenuBar.defaultProps = {
  filtersExpanded: false
};