'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectors = exports.isTokenValid = exports.getCustomerDetails = exports.getToken = exports.initialState = exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_CUSTOMER_LOGIN';
var id = 'Login';

var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['CREATE_ACCOUNT', 'LOGIN', 'LOGIN_SUCCESS', 'LOGIN_FAILURE', 'CREATE_CUSTOMER_SUCCESS', 'CREATE_CUSTOMER_FAILURE', 'GET_CUSTOMER_SUCCESS', 'GET_CUSTOMER_FAILURE', 'SAVE_USER_DETAILS', 'SAVE_USER_DETAILS_SUCCESS', 'SAVE_USER_DETAILS_FAILURE', 'CHANGE_PASSWORD', 'CHANGE_PASSWORD_SUCCESS', 'CHANGE_PASSWORD_FAILURE', 'DELETE_ACCOUNT', 'DELETE_ACCOUNT_SUCCESS', 'DELETE_ACCOUNT_FAILURE', 'SIGN_OUT']);

var MILLISECONDS_IN_MINUTE = 60000;
var OFFSET = 10 * MILLISECONDS_IN_MINUTE;

var actions = exports.actions = {
  postCreateAccount: (0, _reducerMacros.moduleActionWithArgs)(constants.CREATE_ACCOUNT, id, function (payload) {
    return (0, _extends3.default)({}, payload);
  }),
  postLogin: (0, _reducerMacros.moduleActionWithArgs)(constants.LOGIN, id, function (payload) {
    return (0, _extends3.default)({}, payload);
  }),
  postLoginSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.LOGIN_SUCCESS, id, function (payload) {
    return (0, _extends3.default)({}, payload);
  }),
  postLoginFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.LOGIN_FAILURE, id, function (payload) {
    return (0, _extends3.default)({}, payload);
  }),
  createCustomerAccountSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.CREATE_CUSTOMER_SUCCESS, id, function (payload) {
    return (0, _extends3.default)({}, payload);
  }),
  createCustomerAccountFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.CREATE_CUSTOMER_FAILURE, id, function (failMessage) {
    return (0, _extends3.default)({}, failMessage);
  }),
  postGetCustomerSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_CUSTOMER_SUCCESS, id, function (payload) {
    return (0, _extends3.default)({}, payload);
  }),
  postGetCustomerFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_CUSTOMER_FAILURE, id, function (failMessage) {
    return (0, _extends3.default)({}, failMessage);
  }),
  saveUserDetails: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_USER_DETAILS, id, function (details) {
    return (0, _extends3.default)({}, details);
  }),
  saveUserDetailsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_USER_DETAILS_SUCCESS, id, function (successMessage) {
    return (0, _extends3.default)({}, successMessage);
  }),
  saveUserDetailsFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_USER_DETAILS_FAILURE, id, function (failMessage) {
    return (0, _extends3.default)({}, failMessage);
  }),
  changePassword: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_PASSWORD, id, function (passwords) {
    return (0, _extends3.default)({}, passwords);
  }),
  changePasswordSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_PASSWORD_SUCCESS, id, function (successMessage) {
    return (0, _extends3.default)({}, successMessage);
  }),
  changePasswordFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_PASSWORD_FAILURE, id, function (failMessage) {
    return (0, _extends3.default)({}, failMessage);
  }),
  deleteAccount: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_ACCOUNT, id, function (password) {
    return (0, _extends3.default)({}, password);
  }),
  deleteAccountSuccess: (0, _reducerMacros.moduleAction)(constants.DELETE_ACCOUNT_SUCCESS, id),
  deleteAccountFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_ACCOUNT_FAILURE, id, function (failMessage) {
    return (0, _extends3.default)({}, failMessage);
  }),
  signOut: (0, _reducerMacros.moduleAction)(constants.SIGN_OUT, id)
};

var initialState = exports.initialState = {
  // @TODO: authenticationDetails will be renamed
  authenticationDetails: null,
  customerDetails: null
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case constants.LOGIN_SUCCESS:
      {
        return (0, _extends3.default)({}, state, {
          authenticationDetails: payload.auth
        });
      }
    case constants.LOGIN_FAILURE:
      {
        return (0, _extends3.default)({}, state, {
          authenticationDetails: null
        });
      }
    case constants.GET_CUSTOMER_SUCCESS:
      {
        return (0, _extends3.default)({}, state, {
          customerDetails: payload.customerDetails
        });
      }
    case constants.DELETE_ACCOUNT_SUCCESS:
    case constants.SIGN_OUT:
      {
        return (0, _extends3.default)({}, state, {
          customerDetails: null,
          authenticationDetails: null
        });
      }
    default:
      return state;
  }
}

// selectors
var getToken = exports.getToken = function getToken(state) {
  return (state.customerLogin.authenticationDetails || {}).accessToken;
};
var getCustomerDetails = exports.getCustomerDetails = function getCustomerDetails(state) {
  return state.customerLogin.customerDetails;
};
var isTokenValid = exports.isTokenValid = function isTokenValid(state) {
  return Date.parse((state.customerLogin.authenticationDetails || {}).expiresAt) > Date.now() + OFFSET;
};

var tokenExists = function tokenExists(state) {
  return !!state.customerLogin.authenticationDetails;
};

var selectors = exports.selectors = {
  getToken: getToken,
  getCustomerDetails: getCustomerDetails,
  isTokenValid: isTokenValid,
  tokenExists: tokenExists
};