'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = VehiclePerformance;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _VdpPerformance = require('../../components/VdpPerformance');

var _VdpPerformance2 = _interopRequireDefault(_VdpPerformance);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function VehiclePerformance(_ref) {
  var config = _ref.config;

  return _react2.default.createElement(_VdpPerformance2.default, { performance: config.performance });
}