'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.default = RegionCheckboxGroup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _difference = require('ramda/src/difference');

var _difference2 = _interopRequireDefault(_difference);

var _filters = require('../../shared/filters');

var _RegionCheckbox = require('./RegionCheckbox');

var _RegionCheckbox2 = _interopRequireDefault(_RegionCheckbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function RegionCheckboxGroup(_ref) {
  var filtersStyling = _ref.filtersStyling,
      region = _ref.region,
      subregions = _ref.subregions,
      updateFilters = _ref.updateFilters,
      filterKey = _ref.filterKey,
      selectedData = _ref.selectedData,
      renderIcon = _ref.renderIcon;

  var updateFiltersForRegions = function updateFiltersForRegions(values) {
    var filteredValues = values.filter(function (v) {
      return v.enabled;
    }).map(function (v) {
      return v.name;
    });

    if (selectedData) {
      filteredValues = filteredValues.every(function (v) {
        return selectedData.includes(v);
      }) ? (0, _difference2.default)(selectedData, filteredValues) : [].concat((0, _toConsumableArray3.default)(filteredValues), (0, _toConsumableArray3.default)(selectedData));
    }

    updateFilters([{
      key: filterKey,
      value: filteredValues
    }]);
  };

  var updateFiltersForSubregions = function updateFiltersForSubregions(subRegion, enabled) {
    if (enabled) {
      updateFilters([{
        key: filterKey,
        value: _filters.helpers.newArray(selectedData && selectedData.length > 0 ? selectedData.filter(Boolean) : [], subRegion)
      }]);
    }
  };

  var shouldRenderIcon = function shouldRenderIcon(checkBoxEnabled, renderLogic) {
    return checkBoxEnabled ? renderIcon(renderLogic, filtersStyling.checkboxSelectedIcon, filtersStyling.checkboxIcon) : null;
  };

  var enabledSubregions = subregions.filter(function (sr) {
    return sr.enabled;
  });
  var enableRegion = subregions.some(function (s) {
    return s.enabled;
  });
  var shouldRenderRegion = enableRegion && selectedData && (0, _difference2.default)(enabledSubregions.map(function (sr) {
    return sr.name;
  }), selectedData).length === 0;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(_RegionCheckbox2.default, {
      key: region,
      onClick: function onClick() {
        return updateFiltersForRegions(subregions);
      },
      label: region,
      filtersStyling: filtersStyling,
      renderIcon: shouldRenderIcon(enableRegion, shouldRenderRegion),
      enabled: enableRegion
    }),
    subregions.map(function (subregion) {
      var shouldRenderSubregionIcon = subregion.enabled && selectedData && selectedData.includes(subregion.name);
      return _react2.default.createElement(_RegionCheckbox2.default, {
        key: region + '-' + subregion.name,
        onClick: function onClick() {
          return updateFiltersForSubregions(subregion.name, subregion.enabled);
        },
        label: subregion.name,
        filtersStyling: filtersStyling,
        renderIcon: shouldRenderIcon(subregion.enabled, shouldRenderSubregionIcon),
        region: true,
        enabled: subregion.enabled
      });
    })
  );
}