'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MultiDropdown = exports.Email = exports.SocialLinks = exports.Posts = exports.Styles = exports.PageAndExternalLink = exports.EditText = exports.EditTextWithToggle = exports.MediaPicker = exports.Video = exports.Markdown = exports.Link = exports.Image = exports.Toggle = exports.EmailTextArea = exports.EditorTextArea = exports.EditorLabel = exports.EditorText = exports.Text = exports.TextArea = exports.URLWithoutLabel = exports.URL = exports.Items = exports.Checkbox = exports.Colour = exports.FontWithAlignment = exports.Font = exports.AlignLeftRight = exports.Box = exports.HorizontalVerticalAlign = exports.VerticalAlign = exports.Align = exports.Page = exports.CheckField = exports.Number = exports.Pixels = exports.Opacity = exports.DraggableListWithToggle = exports.Field = exports.OptionalSection = exports.Section = exports.ListMany = exports.ListSlice = exports.Slice = exports.Config = exports.getSection = exports.getSlice = exports.getFields = exports.getSections = exports.getSlices = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getSlices = exports.getSlices = function getSlices(config) {
  return config.slices;
};
var getSections = exports.getSections = function getSections(slice) {
  return slice.sections;
};
var getFields = exports.getFields = function getFields(section) {
  return section.fields;
};

var getSlice = exports.getSlice = function getSlice(config, sliceName) {
  return config.slices.find(function (slice) {
    return slice.name === sliceName;
  });
};

var getSection = exports.getSection = function getSection(config, sliceName, sectionName) {
  return getSlice(config, sliceName).sections.find(function (section) {
    return section.name === sectionName;
  });
};

var Config = exports.Config = function Config(name, config) {
  for (var _len = arguments.length, slices = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    slices[_key - 2] = arguments[_key];
  }

  return (0, _extends3.default)({}, config, {
    name: name,
    slices: slices
  });
};

var Slice = exports.Slice = function Slice(name, config) {
  for (var _len2 = arguments.length, sections = Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
    sections[_key2 - 2] = arguments[_key2];
  }

  return (0, _extends3.default)({}, config, {
    name: name,
    sections: sections
  });
};
var ListSlice = exports.ListSlice = function ListSlice(name, config) {
  for (var _len3 = arguments.length, fields = Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
    fields[_key3 - 2] = arguments[_key3];
  }

  return (0, _extends3.default)({}, config, {
    name: name,
    fields: fields,
    list: true
  });
};
var ListMany = exports.ListMany = function ListMany(name, config) {
  return (0, _extends3.default)({}, config, { name: name });
};
var Section = exports.Section = function Section(name, _ref) {
  for (var _len4 = arguments.length, fields = Array(_len4 > 2 ? _len4 - 2 : 0), _key4 = 2; _key4 < _len4; _key4++) {
    fields[_key4 - 2] = arguments[_key4];
  }

  var icon = _ref.icon,
      help = _ref.help;
  return {
    name: name,
    icon: icon,
    help: help,
    fields: fields
  };
};
var OptionalSection = exports.OptionalSection = function OptionalSection(name) {
  for (var _len5 = arguments.length, fields = Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
    fields[_key5 - 1] = arguments[_key5];
  }

  return {
    name: name,
    fields: fields,
    optional: true
  };
};

var translatable = function translatable(id) {
  return 'translations.' + id;
};

var Field = exports.Field = function Field(datatype, props) {
  return function (name, ids, extraProps) {
    return {
      name: name,
      ids: ids,
      datatype: datatype,
      props: (0, _extends3.default)({}, props, extraProps)
    };
  };
};

var DraggableListWithToggle = exports.DraggableListWithToggle = function DraggableListWithToggle(name, id) {
  return Field('DraggableListWithToggle')(name, { id: id });
};
var Opacity = exports.Opacity = function Opacity(name, id) {
  return Field('Opacity')(name, { id: id });
};
var Pixels = exports.Pixels = function Pixels(name, id) {
  return Field('Pixels')(name, { id: id });
};
var Number = exports.Number = function Number(name, id) {
  for (var _len6 = arguments.length, rest = Array(_len6 > 2 ? _len6 - 2 : 0), _key6 = 2; _key6 < _len6; _key6++) {
    rest[_key6 - 2] = arguments[_key6];
  }

  return Field('Number').apply(undefined, [name, { id: id }].concat(rest));
};
var CheckField = exports.CheckField = function CheckField(name, id) {
  return Field('CheckField')(name, { id: id });
};

var Page = exports.Page = function Page(name, id) {
  return Field('Page')(name, { id: id });
};
var Align = exports.Align = function Align(name, id) {
  return Field('Align')(name, { id: id });
};
var VerticalAlign = exports.VerticalAlign = function VerticalAlign(name, id) {
  return Field('VerticalAlign')(name, { id: id });
};
var HorizontalVerticalAlign = exports.HorizontalVerticalAlign = function HorizontalVerticalAlign(name, ids) {
  return Field('HorizontalVerticalAlign')(name, (0, _extends3.default)({}, ids));
};
var Box = exports.Box = function Box(name, id) {
  return Field('Box')(name, { id: id });
};
var AlignLeftRight = exports.AlignLeftRight = function AlignLeftRight(name, id) {
  return Field('Align', { leftRightOnly: true })(name, { id: id });
};
var Font = exports.Font = function Font(name, id) {
  return Field('Font')(name, { id: id });
};
var FontWithAlignment = exports.FontWithAlignment = function FontWithAlignment(name, id) {
  return Field('Font', {
    withParagraphAlignment: true
  })(name, { id: id });
};
var Colour = exports.Colour = function Colour(name, id) {
  for (var _len7 = arguments.length, rest = Array(_len7 > 2 ? _len7 - 2 : 0), _key7 = 2; _key7 < _len7; _key7++) {
    rest[_key7 - 2] = arguments[_key7];
  }

  return Field('Colour').apply(undefined, [name, { id: id }].concat(rest));
};
var Checkbox = exports.Checkbox = function Checkbox(name, id) {
  return Field('Checkbox')(name, { id: id });
};
var Items = exports.Items = function Items(name, id) {
  for (var _len8 = arguments.length, rest = Array(_len8 > 2 ? _len8 - 2 : 0), _key8 = 2; _key8 < _len8; _key8++) {
    rest[_key8 - 2] = arguments[_key8];
  }

  return Field('Items').apply(undefined, [name, { items: id, translations: 'translations' }].concat(rest));
};
var URL = exports.URL = function URL(name, id) {
  return Field('URL', { displayLabel: true })(name, { id: id });
};
var URLWithoutLabel = exports.URLWithoutLabel = function URLWithoutLabel(name, id) {
  return Field('URL', { displayLabel: false })(name, { id: id });
};
var TextArea = exports.TextArea = function TextArea(name, id) {
  for (var _len9 = arguments.length, rest = Array(_len9 > 2 ? _len9 - 2 : 0), _key9 = 2; _key9 < _len9; _key9++) {
    rest[_key9 - 2] = arguments[_key9];
  }

  return Field('TextArea').apply(undefined, [name, { id: translatable(id) }].concat(rest));
};
var Text = exports.Text = function Text(name, id) {
  return Field('Text', { displayLabel: true })(name, { id: translatable(id) });
};
var EditorText = exports.EditorText = function EditorText(name, id) {
  return Field('Text', { displayLabel: true })(name, { id: id });
};
var EditorLabel = exports.EditorLabel = function EditorLabel(name, id) {
  return Field('Text', { displayLabel: true })(name, { id: id });
};
var EditorTextArea = exports.EditorTextArea = function EditorTextArea(name, id) {
  for (var _len10 = arguments.length, rest = Array(_len10 > 2 ? _len10 - 2 : 0), _key10 = 2; _key10 < _len10; _key10++) {
    rest[_key10 - 2] = arguments[_key10];
  }

  return Field('TextArea').apply(undefined, [name, { id: id }].concat(rest));
};
var EmailTextArea = exports.EmailTextArea = function EmailTextArea(name, id) {
  for (var _len11 = arguments.length, rest = Array(_len11 > 2 ? _len11 - 2 : 0), _key11 = 2; _key11 < _len11; _key11++) {
    rest[_key11 - 2] = arguments[_key11];
  }

  return Field('TextArea').apply(undefined, [name, { id: id }].concat(rest));
};
var Toggle = exports.Toggle = function Toggle(name, id) {
  return Field('Toggle')(name, { id: id });
};
var Image = exports.Image = function Image(name, ids) {
  for (var _len12 = arguments.length, rest = Array(_len12 > 2 ? _len12 - 2 : 0), _key12 = 2; _key12 < _len12; _key12++) {
    rest[_key12 - 2] = arguments[_key12];
  }

  return Field('Media', { type: 'image' }).apply(undefined, [name, (0, _extends3.default)({
    media: ids.image
  }, ids.accessibilityText && {
    accessibilityText: translatable(ids.accessibilityText)
  })].concat(rest));
};
var Link = exports.Link = function Link(name, ids) {
  return Field('Link')(name, (0, _extends3.default)({}, ids, { text: translatable(ids.text) }));
};
var Markdown = exports.Markdown = function Markdown(name, textId) {
  return Field('Markdown')(name, { text: translatable(textId) });
};
var Video = exports.Video = function Video(name, video) {
  for (var _len13 = arguments.length, rest = Array(_len13 > 2 ? _len13 - 2 : 0), _key13 = 2; _key13 < _len13; _key13++) {
    rest[_key13 - 2] = arguments[_key13];
  }

  return Field('Media', { type: 'video' }).apply(undefined, [name, { media: video }].concat(rest));
};
var MediaPicker = exports.MediaPicker = function MediaPicker(name, ids) {
  for (var _len14 = arguments.length, rest = Array(_len14 > 2 ? _len14 - 2 : 0), _key14 = 2; _key14 < _len14; _key14++) {
    rest[_key14 - 2] = arguments[_key14];
  }

  return Field('MediaPicker').apply(undefined, [name, (0, _extends3.default)({}, ids, {
    bodyText: translatable(ids.bodyText),
    headingText: translatable(ids.headingText)
  }, ids.accesibilityText && {
    accessibilityText: translatable(ids.accesibilityText)
  })].concat(rest));
};
var EditTextWithToggle = exports.EditTextWithToggle = function EditTextWithToggle(name, id) {
  return Field('EditText', {
    small: true,
    hasToggle: true
  })(name, { id: id });
};
var EditText = exports.EditText = function EditText(name, id) {
  return Field('EditText', {
    small: false,
    hasToggle: false
  })(name, { id: id });
};
var PageAndExternalLink = exports.PageAndExternalLink = function PageAndExternalLink(name, id) {
  return Field('Page', {
    withExternalLink: true
  })(name, { id: id });
};
var Styles = exports.Styles = function Styles(name, id) {
  for (var _len15 = arguments.length, rest = Array(_len15 > 2 ? _len15 - 2 : 0), _key15 = 2; _key15 < _len15; _key15++) {
    rest[_key15 - 2] = arguments[_key15];
  }

  return Field('Styles').apply(undefined, [name, { id: id }].concat(rest));
};

var Posts = exports.Posts = function Posts(name, ids) {
  for (var _len16 = arguments.length, rest = Array(_len16 > 2 ? _len16 - 2 : 0), _key16 = 2; _key16 < _len16; _key16++) {
    rest[_key16 - 2] = arguments[_key16];
  }

  return Field('Posts').apply(undefined, [name, ids].concat(rest));
};
var SocialLinks = exports.SocialLinks = function SocialLinks(name, id) {
  for (var _len17 = arguments.length, rest = Array(_len17 > 2 ? _len17 - 2 : 0), _key17 = 2; _key17 < _len17; _key17++) {
    rest[_key17 - 2] = arguments[_key17];
  }

  return Field('SocialLinks').apply(undefined, [name, { links: id, translations: 'translations' }].concat(rest));
};
var Email = exports.Email = function Email() {
  return {
    name: 'Email',
    ids: { email: 'email' },
    datatype: 'Email'
  };
};
var MultiDropdown = exports.MultiDropdown = function MultiDropdown(name, id) {
  for (var _len18 = arguments.length, rest = Array(_len18 > 2 ? _len18 - 2 : 0), _key18 = 2; _key18 < _len18; _key18++) {
    rest[_key18 - 2] = arguments[_key18];
  }

  return Field('MultiDropdown').apply(undefined, [name, { id: id, translations: 'translations' }].concat(rest));
};

exports.default = {
  Align: Align,
  AlignLeftRight: AlignLeftRight,
  Box: Box,
  Colour: Colour,
  Config: Config,
  EditText: EditText,
  EditorLabel: EditorLabel,
  EditorTextArea: EditorTextArea,
  EditorText: EditorText,
  Font: Font,
  Link: Link,
  Markdown: Markdown,
  Number: Number,
  Opacity: Opacity,
  Section: Section,
  Slice: Slice,
  Image: Image,
  Styles: Styles,
  Text: Text,
  TextArea: TextArea,
  Video: Video,
  MediaPicker: MediaPicker,
  ListSlice: ListSlice,
  VerticalAlign: VerticalAlign,
  HorizontalVerticalAlign: HorizontalVerticalAlign,
  Toggle: Toggle,
  Items: Items,
  Posts: Posts,
  SocialLinks: SocialLinks,
  Email: Email,
  MultiDropdown: MultiDropdown
};