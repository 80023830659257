'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Twitter = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Twitter__IconWrapper',
  componentId: 'sc-12jm47p-0'
})(['width:42px;height:42px;']);

var Twitter = function Twitter() {
  return _react2.default.createElement(
    IconWrapper,
    null,
    _react2.default.createElement(
      'svg',
      { viewBox: '0 0 22 22', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
      _react2.default.createElement('path', {
        d: 'M0 4.58333C0 2.05203 2.05203 0 4.58333 0H17.4167C19.948 0 22 2.05203 22 4.58333V17.4167C22 19.948 19.948 22 17.4167 22H4.58333C2.05203 22 0 19.948 0 17.4167V4.58333Z',
        fill: 'white'
      }),
      _react2.default.createElement('path', {
        d: 'M3.47438 4.09694L9.31397 11.7118L3.4375 17.9031H4.76006L9.9049 12.4825L14.0618 17.9031H18.5625L12.3943 9.85984L17.8641 4.09694H16.5416L11.8034 9.08913L7.97509 4.09694H3.47438ZM5.4193 5.04703H7.48693L16.6173 16.9528H14.5496L5.4193 5.04703Z',
        fill: '#000'
      })
    )
  );
};

exports.Twitter = Twitter;