'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 90%;\n    padding: 0 5%;\n  '], ['\n    width: 90%;\n    padding: 0 5%;\n  ']);
/* eslint-env browser */


exports.default = TestDriveLogin;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _InputField = require('./FormFields/InputField');

var _InputField2 = _interopRequireDefault(_InputField);

var _Button = require('./Global/Button');

var _Button2 = _interopRequireDefault(_Button);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveLoginContainer'
}).withConfig({
  displayName: 'TestDriveLogin__Container',
  componentId: 'sc-1rlrm77-0'
})(['height:auto;text-align:center;margin:20px auto 80px auto;max-width:430px;', ';'], _theme2.default.max.large(_templateObject));

var LineBreak = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveLogin__LineBreak',
  componentId: 'sc-1rlrm77-1'
})(['border-bottom:1px solid #dedede;margin:40px 0;']);

var Block = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveLogin__Block',
  componentId: 'sc-1rlrm77-2'
})(['margin-bottom:20px;']);

var Heading = _styledComponents2.default.span.withConfig({
  displayName: 'TestDriveLogin__Heading',
  componentId: 'sc-1rlrm77-3'
})(['font-size:20px;color:#444444;']);
var LoginFailedHeading = _styledComponents2.default.span.withConfig({
  displayName: 'TestDriveLogin__LoginFailedHeading',
  componentId: 'sc-1rlrm77-4'
})(['font-size:16px;color:#9e1b32;']);

var ButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveLogin__ButtonWrapper',
  componentId: 'sc-1rlrm77-5'
})(['display:flex;justify-content:center;']);

function TestDriveLogin(props) {
  window.scroll(0, 0);
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Block,
      null,
      _react2.default.createElement(
        Heading,
        null,
        props.translations.testDriveLoginHeader
      )
    ),
    _react2.default.createElement(
      Block,
      null,
      props.loginFailed === 'UNAUTHORISED' && _react2.default.createElement(
        LoginFailedHeading,
        null,
        props.translations.testDriveLoginInvalidUsernamePassword
      ),
      props.loginFailed === 'VALIDATION REQUIRED' && _react2.default.createElement(
        LoginFailedHeading,
        null,
        props.translations.accountValidationRequired
      )
    ),
    _react2.default.createElement(_InputField2.default, {
      type: 'text',
      label: props.translations.testDriveLoginUsernameLabel,
      value: props.bookingForm.username || '',
      onChange: props.onChange,
      keyValue: 'username'
    }),
    _react2.default.createElement(_InputField2.default, {
      type: 'password',
      label: props.translations.testDriveLoginPasswordLabel,
      value: props.bookingForm.password || '',
      onChange: props.onChange,
      keyValue: 'password'
    }),
    _react2.default.createElement(
      Block,
      null,
      _react2.default.createElement(
        ButtonWrapper,
        null,
        _react2.default.createElement(_Button2.default, {
          applyStyle: 'primary',
          buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
          text: props.translations.testDriveLoginAndConfirmButtonLabel,
          styleOverride: function styleOverride() {
            return 'min-width: 242px;';
          },
          onClick: function onClick() {
            return props.loginAndConfirm({
              credentials: {
                user: props.bookingForm.username,
                pass: props.bookingForm.password
              }
            });
          }
        })
      )
    ),
    _react2.default.createElement(LineBreak, null),
    _react2.default.createElement(
      Block,
      null,
      _react2.default.createElement(
        Block,
        null,
        _react2.default.createElement(
          Heading,
          null,
          props.translations.testDriveLoginNewUserHeader
        )
      ),
      _react2.default.createElement(
        'div',
        null,
        props.translations.testDriveLoginNewUserInfo
      )
    ),
    _react2.default.createElement(
      Block,
      null,
      _react2.default.createElement(
        ButtonWrapper,
        null,
        _react2.default.createElement(_Button2.default, {
          applyStyle: 'primary',
          buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
          styleOverride: function styleOverride() {
            return 'min-width: 242px;';
          },
          text: props.translations.testDriveLoginContinueAsGuestLabel,
          onClick: props.continueAsGuest
        })
      )
    )
  );
}