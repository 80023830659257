'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  GOOGLE_LOADED: 'GOOGLE_LOADED'
};

var actions = exports.actions = {
  googleLoaded: function googleLoaded() {
    return {
      type: constants.GOOGLE_LOADED
    };
  }
};

var initialState = {
  googleAvailable: false
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var _ref = arguments[1];
  var type = _ref.type;

  switch (type) {
    case constants.GOOGLE_LOADED:
      return (0, _extends3.default)({}, state, {
        googleAvailable: true
      });
    default:
      return state;
  }
}