'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIndexContent = exports.searchable = exports.icon = exports.description = exports.availableToAdministration = exports.id = exports.name = exports.defaultConfig = exports.config = exports.component = undefined;

var _TextCTA = require('./TextCTA');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_TextCTA).default;
  }
});

var _config = require('./config');

Object.defineProperty(exports, 'config', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_config).default;
  }
});

var _defaultConfig = require('./defaultConfig');

Object.defineProperty(exports, 'defaultConfig', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_defaultConfig).default;
  }
});

var _Icon = require('./Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'Text with CTA';
var id = exports.id = 'TextCTA';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Text with CTA';
var icon = exports.icon = _Icon2.default;
var searchable = exports.searchable = true;
var getIndexContent = exports.getIndexContent = function getIndexContent(_ref) {
  var _ref$config = _ref.config;
  _ref$config = _ref$config === undefined ? {} : _ref$config;
  var _ref$config$translati = _ref$config.translations,
      translations = _ref$config$translati === undefined ? {} : _ref$config$translati,
      relativePath = _ref.relativePath,
      instanceId = _ref.instanceId;
  return [{
    title: translations.headlineText,
    content: translations.bodyText
  }, {
    title: translations.headlineText,
    content: translations.smallBodyText
  }, {
    title: translations.headlineText,
    content: translations.buttonText
  }].map(function (_ref2, index) {
    var title = _ref2.title,
        content = _ref2.content;
    return {
      key: instanceId + '-' + index,
      title: title,
      content: content,
      link: relativePath
    };
  }).filter(function (_ref3) {
    var title = _ref3.title,
        content = _ref3.content;
    return !!title || !!content;
  });
};