'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 16px;\n  '], ['\n    padding: 0 16px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n  '], ['\n  ', ';\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n    padding: 0;\n  '], ['\n    display: none;\n    padding: 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 16px 5%;\n  '], ['\n    padding: 16px 5%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FiltersContainer = require('../../linked-modules/Filters/FiltersContainer');

var _FiltersContainer2 = _interopRequireDefault(_FiltersContainer);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _MobileFilters = require('../MobileFilters');

var _MobileFilters2 = _interopRequireDefault(_MobileFilters);

var _Spinner = require('../Spinner');

var _SortMenuBar = require('./SortMenuBar');

var _SortMenuBar2 = _interopRequireDefault(_SortMenuBar);

var _VehicleResults = require('./VehicleResults');

var _VehicleResults2 = _interopRequireDefault(_VehicleResults);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Container',
  componentId: 'sc-ccex85-0'
})(['display:flex;flex-direction:column;width:100%;', ';'], _theme2.default.min.large(_templateObject));

var Line = _styledComponents2.default.hr.withConfig({
  displayName: 'SearchResults__Line',
  componentId: 'sc-ccex85-1'
})(['background-color:#dedede;border-bottom:none;']);

var Grid = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Grid',
  componentId: 'sc-ccex85-2'
})(['@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){min-height:100%;}width:100%;display:flex;flex-flow:row wrap;flex-wrap:nowrap;box-sizing:border-box;display:flex;flex-direction:row;', ';', ';', ';'], function (_ref) {
  var applyPadding = _ref.applyPadding;
  return applyPadding && 'padding: 0 50px;';
}, _theme2.default.max.large(_templateObject2, function (_ref2) {
  var applyPadding = _ref2.applyPadding;
  return applyPadding && 'padding: 0 20px;';
}), _theme2.default.max.medium(_templateObject3));
var GridColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__GridColumn',
  componentId: 'sc-ccex85-3'
})(['display:flex;flex-direction:column;']);
var GridResultColumn = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__GridResultColumn',
  componentId: 'sc-ccex85-4'
})(['display:flex;width:100%;flex-direction:column;']);
var SortMenuBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__SortMenuBarContainer',
  componentId: 'sc-ccex85-5'
})(['display:flex;justify-content:flex-end;']);

var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__MobileOnly',
  componentId: 'sc-ccex85-6'
})(['display:none;', ';'], _theme2.default.max.medium(_templateObject4));

var ResultsSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__ResultsSectionWrapper',
  componentId: 'sc-ccex85-7'
})(['min-height:1px;', ';', ';'], function (_ref3) {
  var direction = _ref3.direction;
  return direction === 'rtl' ? 'padding: 0 16px 0 0;' : 'padding: 0 0 0 16px;';
}, _theme2.default.max.large(_templateObject5));

var VehicleResultsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__VehicleResultsWrapper',
  componentId: 'sc-ccex85-8'
})(['padding-top:16px;']);

var FiltersContainerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__FiltersContainerWrapper',
  componentId: 'sc-ccex85-9'
})(['width:280px;']);

var ResultsSection = function ResultsSection(_ref4) {
  var config = _ref4.config,
      globalStyling = _ref4.globalStyling,
      translations = _ref4.translations,
      sortOptions = _ref4.sortOptions,
      sortValue = _ref4.sortValue,
      onSortChange = _ref4.onSortChange,
      selectedView = _ref4.selectedView,
      showSortByDistance = _ref4.showSortByDistance,
      filtersExpanded = _ref4.filtersExpanded,
      showSortByMileage = _ref4.showSortByMileage,
      showSortByAge = _ref4.showSortByAge,
      showSortByPrice = _ref4.showSortByPrice,
      results = _ref4.results,
      onMoreInfoClick = _ref4.onMoreInfoClick,
      onShowAllResultsClick = _ref4.onShowAllResultsClick,
      onPageNumberChange = _ref4.onPageNumberChange,
      compareVehicle = _ref4.compareVehicle,
      getShortListedIconColour = _ref4.getShortListedIconColour,
      shortlistVehicle = _ref4.shortlistVehicle,
      getCompareIconColour = _ref4.getCompareIconColour,
      tilesVisibility = _ref4.tilesVisibility,
      marketInfo = _ref4.marketInfo,
      vehicleModelTitleFontWeight = _ref4.vehicleModelTitleFontWeight,
      showGridTiles = _ref4.showGridTiles,
      gridSelected = _ref4.gridSelected,
      gridDeselected = _ref4.gridDeselected,
      listSelected = _ref4.listSelected,
      listDeselected = _ref4.listDeselected,
      badgeStyling = _ref4.badgeStyling,
      optionalSelectedColour = _ref4.optionalSelectedColour,
      optionalSelectedBgColour = _ref4.optionalSelectedBgColour,
      pricingSectionImage = _ref4.pricingSectionImage,
      headerItemFont = _ref4.headerItemFont,
      listViewImageWidth = _ref4.listViewImageWidth,
      gridViewColumns = _ref4.gridViewColumns,
      resultItemBackgroundColour = _ref4.resultItemBackgroundColour,
      moreInfoButtonRightAlign = _ref4.moreInfoButtonRightAlign,
      tileImageMaxWidth = _ref4.tileImageMaxWidth,
      financeCellHorizontalPadding = _ref4.financeCellHorizontalPadding,
      showFinancePopup = _ref4.showFinancePopup,
      handleZeroPriceAsText = _ref4.handleZeroPriceAsText,
      showNumberOfImagesInsteadOfViews = _ref4.showNumberOfImagesInsteadOfViews,
      badgeWithStripUrl = _ref4.badgeWithStripUrl,
      badgeWithoutStripUrl = _ref4.badgeWithoutStripUrl,
      warrantyBadgeMonths = _ref4.warrantyBadgeMonths,
      warrantyBadgeMaxMileage = _ref4.warrantyBadgeMaxMileage,
      vehicleTitleFont = _ref4.vehicleTitleFont,
      vehicleSubTitleFont = _ref4.vehicleSubTitleFont,
      backgroundColour = _ref4.backgroundColour,
      financeWidthOverride = _ref4.financeWidthOverride,
      financePaddingOverride = _ref4.financePaddingOverride,
      financeHeadingFont = _ref4.financeHeadingFont,
      financeSubHeadingFont = _ref4.financeSubHeadingFont,
      actionRowStyles = _ref4.actionRowStyles,
      vehicleSpecContainer = _ref4.vehicleSpecContainer,
      infiniteScroll = _ref4.infiniteScroll,
      loading = _ref4.loading,
      fullSubtitle = _ref4.fullSubtitle,
      currentPage = _ref4.currentPage,
      pageSize = _ref4.pageSize,
      totalResults = _ref4.totalResults,
      countDivMargin = _ref4.countDivMargin,
      compareShortlistIconWidth = _ref4.compareShortlistIconWidth,
      onEnquiryClick = _ref4.onEnquiryClick;
  return _react2.default.createElement(
    ResultsSectionWrapper,
    { direction: globalStyling.direction },
    _react2.default.createElement(
      SortMenuBarContainer,
      null,
      totalResults > 0 && _react2.default.createElement(_SortMenuBar2.default, {
        sortOptions: sortOptions,
        sortValue: sortValue,
        onSortChange: onSortChange,
        selectedView: selectedView,
        translations: translations,
        showDistance: showSortByDistance,
        filtersExpanded: filtersExpanded,
        showMileage: showSortByMileage,
        showAge: showSortByAge,
        showPrice: showSortByPrice,
        gridSelected: gridSelected,
        gridDeselected: gridDeselected,
        listSelected: listSelected,
        listDeselected: listDeselected,
        optionalSelectedColour: optionalSelectedColour,
        optionalSelectedBgColour: optionalSelectedBgColour
      })
    ),
    _react2.default.createElement(
      VehicleResultsWrapper,
      null,
      _react2.default.createElement(_VehicleResults2.default, {
        config: config,
        results: results,
        onMoreInfoClick: onMoreInfoClick,
        onEnquiryClick: onEnquiryClick,
        translations: translations,
        onShowAllResultsClick: onShowAllResultsClick,
        onPageNumberChange: onPageNumberChange,
        selectedView: selectedView,
        filtersExpanded: filtersExpanded,
        compareVehicle: compareVehicle,
        shortlistVehicle: shortlistVehicle,
        getShortListedIconColour: getShortListedIconColour,
        getCompareIconColour: getCompareIconColour,
        globalStyling: globalStyling,
        tilesVisibility: tilesVisibility,
        locale: marketInfo.locale,
        vehicleModelTitleFontWeight: vehicleModelTitleFontWeight,
        showGridTiles: showGridTiles,
        badgeStyling: badgeStyling,
        pricingSectionImage: pricingSectionImage,
        headerItemFont: headerItemFont,
        listViewImageWidth: listViewImageWidth,
        gridViewColumns: gridViewColumns,
        resultItemBackgroundColour: resultItemBackgroundColour,
        moreInfoButtonRightAlign: moreInfoButtonRightAlign,
        tileImageMaxWidth: tileImageMaxWidth,
        financeCellHorizontalPadding: financeCellHorizontalPadding,
        showFinancePopup: showFinancePopup,
        handleZeroPriceAsText: handleZeroPriceAsText,
        showNumberOfImagesInsteadOfViews: showNumberOfImagesInsteadOfViews,
        badgeWithStripUrl: badgeWithStripUrl,
        badgeWithoutStripUrl: badgeWithoutStripUrl,
        warrantyBadgeMonths: warrantyBadgeMonths,
        warrantyBadgeMaxMileage: warrantyBadgeMaxMileage,
        vehicleTitleFont: vehicleTitleFont,
        vehicleSubTitleFont: vehicleSubTitleFont,
        backgroundColour: backgroundColour,
        financeWidthOverride: financeWidthOverride,
        financePaddingOverride: financePaddingOverride,
        financeHeadingFont: financeHeadingFont,
        financeSubHeadingFont: financeSubHeadingFont,
        actionRowStyles: actionRowStyles,
        vehicleSpecContainer: vehicleSpecContainer,
        infiniteScroll: infiniteScroll,
        loading: loading,
        fullSubtitle: fullSubtitle,
        currentPage: currentPage,
        pageSize: pageSize,
        totalResults: totalResults,
        countDivMargin: countDivMargin,
        compareShortlistIconWidth: compareShortlistIconWidth
      })
    )
  );
};

var Filters = function Filters(_ref5) {
  var filtersConfig = _ref5.filtersConfig,
      globalStyling = _ref5.globalStyling,
      toggle = _ref5.toggle,
      marketInfo = _ref5.marketInfo,
      shared = _ref5.shared;
  return _react2.default.createElement(_FiltersContainer2.default, (0, _extends3.default)({}, filtersConfig, {
    globalStyling: globalStyling,
    locale: marketInfo.locale,
    toggle: toggle,
    currencyCode: marketInfo.currencyCode,
    marketInfo: marketInfo,
    shared: shared,
    searchCountryCode: marketInfo.searchCountryCode,
    hideSearch: true
  }));
};

var SearchResults = function (_Component) {
  (0, _inherits3.default)(SearchResults, _Component);

  function SearchResults(props) {
    (0, _classCallCheck3.default)(this, SearchResults);

    var _this = (0, _possibleConstructorReturn3.default)(this, (SearchResults.__proto__ || (0, _getPrototypeOf2.default)(SearchResults)).call(this, props));

    Object.defineProperty(_this, 'toggle', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.setState(function (_ref6) {
          var displayMobileFilterOptions = _ref6.displayMobileFilterOptions;
          return {
            displayMobileFilterOptions: !displayMobileFilterOptions
          };
        });
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            config = _this$props.config,
            headingBottomLineDisable = _this$props.headingBottomLineDisable,
            filtersConfig = _this$props.filtersConfig,
            globalStyling = _this$props.globalStyling,
            translations = _this$props.translations,
            activeFiltersCount = _this$props.activeFiltersCount,
            infiniteScroll = _this$props.infiniteScroll,
            marketInfo = _this$props.marketInfo,
            loading = _this$props.loading,
            filtersLoaded = _this$props.filtersLoaded,
            shared = _this$props.shared;
        var displayMobileFilterOptions = _this.state.displayMobileFilterOptions;

        var filters = _react2.default.createElement(Filters, {
          filtersConfig: filtersConfig,
          globalStyling: globalStyling,
          toggle: function toggle() {
            return _this.toggle();
          },
          marketInfo: marketInfo,
          shared: shared,
          displayMobileFilterOptions: displayMobileFilterOptions
        });
        return _react2.default.createElement(
          Container,
          { 'data-cy': 'search-results' },
          _react2.default.createElement(
            MobileOnly,
            null,
            _react2.default.createElement(_MobileFilters2.default, {
              filters: filters,
              translations: translations,
              activeFiltersCount: activeFiltersCount,
              globalStyling: globalStyling,
              backgroundColour: filtersConfig.config.stylingConfig && filtersConfig.config.stylingConfig.backgroundColour,
              borderColour: filtersConfig.config.stylingConfig && filtersConfig.config.stylingConfig.borderColour,
              config: config,
              shared: shared,
              market: marketInfo.market
            }),
            !(headingBottomLineDisable === true) && _react2.default.createElement(Line, null),
            _react2.default.createElement(ResultsSection, _this.props)
          ),
          _react2.default.createElement(
            Grid,
            { applyPadding: !globalStyling.contentWidth },
            _react2.default.createElement(
              _Spinner.SpinnerWrapper,
              {
                loaded: filtersLoaded,
                globalStyling: globalStyling,
                label: translations.loadingText
              },
              function () {
                return _react2.default.createElement(
                  _react.Fragment,
                  null,
                  _react2.default.createElement(
                    GridColumn,
                    null,
                    _react2.default.createElement(
                      FiltersContainerWrapper,
                      null,
                      filters
                    )
                  ),
                  _react2.default.createElement(
                    GridResultColumn,
                    { 'data-cy': 'search-result-container-grid-column' },
                    _react2.default.createElement(
                      _Spinner.SpinnerWrapper,
                      {
                        loaded: infiniteScroll || !loading,
                        globalStyling: globalStyling,
                        label: translations.loadingText
                      },
                      function () {
                        return _react2.default.createElement(
                          _react.Fragment,
                          null,
                          _react2.default.createElement(ResultsSection, _this.props)
                        );
                      }
                    )
                  )
                );
              }
            )
          )
        );
      }
    });

    _this.state = {
      displayMobileFilterOptions: false
    };
    return _this;
  }

  return SearchResults;
}(_react.Component);

exports.default = SearchResults;