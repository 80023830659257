'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return {
    translations: {
      'filterOptions-0': 'Location',
      'filterOptions-1': 'Distance',
      'filterOptions-2': 'Model',
      'filterOptions-3': 'Variant',
      'filterOptions-4': 'Bodystyle',
      'filterOptions-5': 'Engine',
      'filterOptions-6': 'Transmission',
      'filterOptions-7': 'Exterior Colours',
      'filterOptions-8': 'Interior Shades',
      'filterOptions-9': 'Age',
      'filterOptions-10': 'Mileage'
    },
    filterOptions: {
      items: [{
        id: '01d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: true,
        lookupKey: 'location'
      }, {
        id: '02d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: true,
        lookupKey: 'distance'
      }, {
        id: '03d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: false,
        lookupKey: 'model'
      }, {
        id: '04d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: false,
        lookupKey: 'variant'
      }, {
        id: '05d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: false,
        lookupKey: 'bodystyle'
      }, {
        id: '06d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: false,
        lookupKey: 'engine'
      }, {
        id: '07d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: false,
        lookupKey: 'transmission'
      }, {
        id: '08d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: false,
        lookupKey: 'exteriorColours'
      }, {
        id: '09d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: false,
        lookupKey: 'interiorShades'
      }, {
        id: '10d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: false,
        lookupKey: 'yearRange'
      }, {
        id: '11d42eee-64da-4362-98b8-8c6463d40176',
        active: true,
        locked: false,
        lookupKey: 'maxOdometer'
      }]
    }
  };
};