'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 90%;\n    padding: 0 5%;\n  '], ['\n    width: 90%;\n    padding: 0 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']);
/* eslint-env browser */
/* eslint-disable jsx-a11y/anchor-is-valid */


exports.default = UserDetails;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormFields = require('./FormFields');

var _Button = require('./Global/Button');

var _Button2 = _interopRequireDefault(_Button);

var _CheckBox = require('./CheckBox');

var _CheckBox2 = _interopRequireDefault(_CheckBox);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _formTitleOptions = require('../helpers/formTitleOptions');

var _PersonalDataConsent = require('./PersonalDataConsent');

var _PersonalDataConsent2 = _interopRequireDefault(_PersonalDataConsent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveLoginContainer'
}).withConfig({
  displayName: 'TestDriveUserDetails__Container',
  componentId: 'sc-18g87e1-0'
})(['height:auto;text-align:center;margin:20px auto 80px auto;max-width:430px;', ';'], _theme2.default.max.large(_templateObject));

var LineBreak = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveUserDetails__LineBreak',
  componentId: 'sc-18g87e1-1'
})(['border-bottom:1px solid #dedede;margin-bottom:20px;']);

var Block = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveUserDetails__Block',
  componentId: 'sc-18g87e1-2'
})(['margin-bottom:20px;']);

var Heading = _styledComponents2.default.span.withConfig({
  displayName: 'TestDriveUserDetails__Heading',
  componentId: 'sc-18g87e1-3'
})(['font-size:20px;']);

var DoubleRow = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveUserDetails__DoubleRow',
  componentId: 'sc-18g87e1-4'
})(['display:flex;justify-content:space-between;', ';'], _theme2.default.max.large(_templateObject2));
var MobileSingleRow = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveUserDetails__MobileSingleRow',
  componentId: 'sc-18g87e1-5'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject3));

var PasswordAccountCreation = _styledComponents2.default.div.withConfig({
  displayName: 'TestDriveUserDetails__PasswordAccountCreation',
  componentId: 'sc-18g87e1-6'
})(['background-color:#f3f3f3;border:1px solid #d8d8d8;padding:20px;']);
var Link = _styledComponents2.default.a.withConfig({
  displayName: 'TestDriveUserDetails__Link',
  componentId: 'sc-18g87e1-7'
})(['padding-left:10px;text-decoration:underline;color:', ';'], function (_ref) {
  var colour = _ref.colour;
  return colour;
});

function UserDetails(props) {
  var colours = props.globalStyling.colours;

  var primaryBrandColour = colours.primaryBrandColour && colours.primaryBrandColour.value;
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Block,
      null,
      _react2.default.createElement(
        Heading,
        null,
        props.translations.testDriveUserDetailsHeader
      )
    ),
    _react2.default.createElement(
      DoubleRow,
      null,
      _react2.default.createElement(_FormFields.SelectField, {
        keyValue: 'title',
        value: props.bookingForm.title || '',
        onChange: props.onTitleChange,
        label: props.translations.testDriveUserDetailsTitle,
        options: (0, _formTitleOptions.titleOptions)(props.translations),
        error: props.bookingFormError.title && props.bookingFormError.title[0],
        doubleRow: true,
        required: true
      })
    ),
    _react2.default.createElement(
      MobileSingleRow,
      null,
      _react2.default.createElement(_FormFields.SelectField, {
        keyValue: 'title',
        value: props.bookingForm.title || '',
        onChange: props.onTitleChange,
        label: props.translations.testDriveUserDetailsTitle,
        options: (0, _formTitleOptions.titleOptions)(props.translations),
        error: props.bookingFormError.title && props.bookingFormError.title[0],
        required: true
      })
    ),
    _react2.default.createElement(
      DoubleRow,
      null,
      _react2.default.createElement(_FormFields.InputField, {
        type: 'text',
        label: props.translations.testDriveUserDetailsFirstName,
        value: props.bookingForm.firstname || '',
        onChange: props.onChange,
        keyValue: 'firstname',
        error: props.bookingFormError.firstname && props.bookingFormError.firstname[0],
        translations: props.translations,
        doubleRow: true,
        required: true,
        validate: true
      }),
      _react2.default.createElement(_FormFields.InputField, {
        type: 'text',
        label: props.translations.testDriveUserDetailsLastName,
        value: props.bookingForm.lastname || '',
        onChange: props.onChange,
        keyValue: 'lastname',
        error: props.bookingFormError.lastname && props.bookingFormError.lastname[0],
        translations: props.translations,
        doubleRow: true,
        required: true,
        validate: true
      })
    ),
    _react2.default.createElement(
      MobileSingleRow,
      null,
      _react2.default.createElement(_FormFields.InputField, {
        type: 'text',
        label: props.translations.testDriveUserDetailsFirstName,
        value: props.bookingForm.firstname || '',
        onChange: props.onChange,
        error: props.bookingFormError.firstname && props.bookingFormError.firstname[0],
        translations: props.translations,
        keyValue: 'firstname',
        required: true,
        validate: true
      }),
      _react2.default.createElement(_FormFields.InputField, {
        type: 'text',
        label: props.translations.testDriveUserDetailsLastName,
        value: props.bookingForm.lastname || '',
        onChange: props.onChange,
        error: props.bookingFormError.lastname && props.bookingFormError.lastname[0],
        translations: props.translations,
        keyValue: 'lastname',
        required: true,
        validate: true
      })
    ),
    _react2.default.createElement(_FormFields.InputField, {
      type: 'text',
      label: props.translations.testDriveUserDetailsEmail,
      value: props.bookingForm.email || '',
      onChange: props.onChange,
      keyValue: 'email',
      error: props.bookingFormError.email && props.bookingFormError.email[0],
      translations: props.translations,
      required: true,
      validate: true
    }),
    _react2.default.createElement(_FormFields.InputField, {
      type: 'text',
      label: props.translations.testDriveUserDetailsEmailConfirm,
      value: props.bookingForm.emailConfirm || '',
      onChange: props.onChange,
      keyValue: 'emailConfirm',
      error: props.bookingFormError.emailConfirm && props.bookingFormError.emailConfirm[0],
      translations: props.translations,
      required: true,
      validate: true
    }),
    _react2.default.createElement(
      DoubleRow,
      null,
      _react2.default.createElement(_FormFields.InputField, {
        type: 'text',
        label: props.translations.testDriveUserDetailsPhone,
        value: props.bookingForm.phone || '',
        onChange: props.onChange,
        keyValue: 'phone',
        error: props.bookingFormError.phone && props.bookingFormError.phone[0],
        translations: props.translations,
        doubleRow: true,
        required: true,
        validate: true
      }),
      _react2.default.createElement(_FormFields.InputField, {
        type: 'text',
        label: props.translations.testDriveUserDetailsPostcode,
        value: props.bookingForm.postcode || '',
        onChange: props.onChange,
        keyValue: 'postcode',
        error: props.bookingFormError.postcode && props.bookingFormError.postcode[0],
        translations: props.translations,
        doubleRow: true,
        required: true,
        validate: true
      })
    ),
    _react2.default.createElement(
      MobileSingleRow,
      null,
      _react2.default.createElement(_FormFields.InputField, {
        type: 'text',
        label: props.translations.testDriveUserDetailsPhone,
        value: props.bookingForm.phone || '',
        onChange: props.onChange,
        error: props.bookingFormError.phone && props.bookingFormError.phone[0],
        translations: props.translations,
        keyValue: 'phone',
        required: true,
        validate: true
      }),
      _react2.default.createElement(_FormFields.InputField, {
        type: 'text',
        label: props.translations.testDriveUserDetailsPostcode,
        value: props.bookingForm.postcode || '',
        onChange: props.onChange,
        error: props.bookingFormError.postcode && props.bookingFormError.postcode[0],
        translations: props.translations,
        keyValue: 'postcode',
        required: true,
        validate: true
      })
    ),
    _react2.default.createElement(LineBreak, null),
    _react2.default.createElement(_FormFields.InputField, {
      type: 'text',
      label: props.translations.testDriveUserDetailsVehicleInterest,
      value: props.testDriveConfig.name || '',
      translations: props.translations,
      keyValue: 'interestedIn',
      disabled: true
    }),
    _react2.default.createElement(_FormFields.InputField, {
      type: 'text',
      label: props.translations.testDriveUserDetailsCurrentVehicle,
      value: props.bookingForm.currentVehicle || '',
      onChange: props.onChange,
      keyValue: 'currentVehicle',
      placeholder: props.translations.testDriveUserDetailsCurrentVehiclePlaceholder,
      translations: props.translations
    }),
    _react2.default.createElement(
      Block,
      null,
      _react2.default.createElement(
        PasswordAccountCreation,
        null,
        _react2.default.createElement(
          Heading,
          null,
          props.translations.testDriveUserDetailsCreateAccount
        ),
        _react2.default.createElement(
          'div',
          { style: { fontSize: '14px', margin: '10px 0', color: '#444' } },
          props.translations.testDriveUserDetailsCreatePassword
        ),
        _react2.default.createElement(_FormFields.InputField, {
          type: 'password',
          value: props.bookingForm.accountPassword || '',
          onChange: props.onChange,
          keyValue: 'accountPassword',
          placeholder: props.translations.testDriveLoginPasswordLabel,
          translations: props.translations,
          error: props.bookingFormError.accountPassword && props.bookingFormError.accountPassword[0],
          validate: true
        }),
        _react2.default.createElement(_FormFields.InputField, {
          type: 'password',
          value: props.bookingForm.accountPasswordConfirm || '',
          onChange: props.onChange,
          keyValue: 'accountPasswordConfirm',
          placeholder: props.translations.testDriveUserDetailsConfirmPwdPlaceholder,
          translations: props.translations,
          error: props.bookingFormError.accountPasswordConfirm && props.bookingFormError.accountPasswordConfirm[0],
          validate: true
        })
      )
    ),
    _react2.default.createElement(
      'div',
      { style: { textAlign: 'left' } },
      _react2.default.createElement(_PersonalDataConsent2.default, {
        translations: props.translations,
        onCheckBoxClick: function onCheckBoxClick(formKey, value) {
          return props.onChange(formKey, value, props.translations);
        },
        phoneChecked: props.bookingForm.phoneConsent,
        phoneKey: 'phoneConsent',
        emailChecked: props.bookingForm.emailConsent,
        emailKey: 'emailConsent',
        smsChecked: props.bookingForm.smsConsent,
        smsKey: 'smsConsent',
        globalStyling: props.globalStyling
      })
    ),
    _react2.default.createElement(
      'div',
      { style: { marginBottom: '20px' } },
      _react2.default.createElement(
        'div',
        { style: { display: 'flex', alignItems: 'center' } },
        _react2.default.createElement(_CheckBox2.default, {
          checked: props.bookingForm.termsAndConditionsAccept,
          onClick: function onClick() {
            return props.onChange('termsAndConditionsAccept', !props.bookingForm.termsAndConditionsAccept, props.translations);
          }
        }),
        _react2.default.createElement(
          Link,
          { colour: primaryBrandColour },
          props.translations.testDriveUserDetailsTermsAndConditionsAccept
        )
      ),
      _react2.default.createElement(
        'div',
        {
          style: {
            color: primaryBrandColour,
            textAlign: 'left'
          }
        },
        props.bookingFormError.termsAndConditionsNotChecked && props.bookingFormError.termsAndConditionsNotChecked[0]
      )
    ),
    _react2.default.createElement(
      'div',
      { style: { display: 'flex' } },
      _react2.default.createElement(_Button2.default, {
        applyStyle: 'secondary',
        buttonStyle: props.globalStyling.uiElements.secondaryButton && props.globalStyling.uiElements.secondaryButton.buttonStyle,
        styleOverride: function styleOverride() {
          return 'width: 30%; margin-right: 10%; ';
        },
        text: props.translations.testDriveGoBack,
        onClick: function onClick() {
          return props.goBack('Login');
        }
      }),
      _react2.default.createElement(_Button2.default, {
        applyStyle: 'primary',
        buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
        text: props.translations.testdriveUserDetailsConfirmRequest,
        onClick: function onClick() {
          return props.confirmRequest(props);
        },
        styleOverride: function styleOverride() {
          return 'width: 60%; ';
        }
      })
    )
  );
}