'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    gap: 24px;\n    padding: 64px 0px;\n  '], ['\n    flex-direction: column;\n    gap: 24px;\n    padding: 64px 0px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 18px;\n    letter: -0.45px;\n  '], ['\n    font-size: 18px;\n    letter: -0.45px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _certifiedProgrammeTypes = require('../../../types/Bentley/certifiedProgrammeTypes');

var _Form = require('../Form');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Item__Container',
  componentId: 'sc-1ksc9ht-0'
})(['display:flex;padding:80px 0px;gap:144px;align-items:center;', ' ', ''], function (_ref) {
  var reverse = _ref.reverse;
  return reverse ? 'flex-direction: row-reverse;' : 'flex-direction: row;';
}, _Genesis2.default.max.medium(_templateObject));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'Item__Image',
  componentId: 'sc-1ksc9ht-1'
})(['object-fit:cover;aspect-ratio:16/9;width:100%;']);

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Item__ImageContainer',
  componentId: 'sc-1ksc9ht-2'
})(['flex:1.5;']);
var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Item__ButtonContainer',
  componentId: 'sc-1ksc9ht-3'
})(['margin-top:16px;', ''], _Genesis2.default.max.medium(_templateObject2));

var TextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Item__TextContainer',
  componentId: 'sc-1ksc9ht-4'
})(['display:flex;flex-direction:column;justify-content:center;gap:24px;flex:1;']);

var ItemTitle = _styledComponents2.default.div.withConfig({
  displayName: 'Item__ItemTitle',
  componentId: 'sc-1ksc9ht-5'
})(['font-family:\'GenesisSansHeadGlobal-Regular\';color:#fff;font-size:20px;line-height:24px;letter-spacing:-0.5px;', ''], _Genesis2.default.max.medium(_templateObject3));

var ItemBodyText = _styledComponents2.default.p.withConfig({
  displayName: 'Item__ItemBodyText',
  componentId: 'sc-1ksc9ht-6'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;line-height:24px;color:#fff;margin:0;']);

var Item = function Item(_ref2) {
  var item = _ref2.item,
      reverse = _ref2.reverse,
      onClick = _ref2.onClick;

  var mobile = (0, _useCheckIsMobileScreen.useCheckMobileScreen)();
  var desktopImgUrl = item.desktopImgUrl,
      mobileImgUrl = item.mobileImgUrl,
      _item$translations = item.translations,
      itemBodyText = _item$translations.itemBodyText,
      textCTA = _item$translations.textCTA,
      titleCTA = _item$translations.titleCTA;


  return _react2.default.createElement(
    Container,
    { reverse: reverse },
    _react2.default.createElement(
      ImageContainer,
      null,
      _react2.default.createElement(Image, { src: mobile ? mobileImgUrl : desktopImgUrl, alt: titleCTA })
    ),
    _react2.default.createElement(
      TextContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        titleCTA
      ),
      _react2.default.createElement(
        ItemBodyText,
        null,
        itemBodyText
      ),
      _react2.default.createElement(
        ButtonContainer,
        null,
        _react2.default.createElement(_Form.Button, {
          styleOverride: function styleOverride() {
            return 'width: inherit;';
          },
          variant: 'tertiary',
          text: textCTA,
          onClick: onClick
        })
      )
    )
  );
};

exports.default = Item;