'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _reducer = require('../../../pods/ModelSearch/reducer');

Object.defineProperty(exports, 'actions', {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
Object.defineProperty(exports, 'constants', {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
Object.defineProperty(exports, 'reducer', {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});

var _ModelSearch = require('./ModelSearch');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ModelSearch).default;
  }
});

var _effects = require('./effects');

Object.defineProperty(exports, 'effects', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_effects).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = exports.name = 'GenesisSearch';
var id = exports.id = 'GenesisSearch';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'Genesis Search';