'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.alignDetails = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      &&& {display: none;}\n  '], ['\n      &&& {display: none;}\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    height: 100%;\n  '], ['\n    display: flex;\n    height: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    /* Safary bug: when the flex parent doesn\'t have enough height the children\'s height shrinks and move inside the parent, min-height avoids the height shrinking */\n    min-height: 40px;\n    height: 40px;\n  '], ['\n    /* Safary bug: when the flex parent doesn\'t have enough height the children\'s height shrinks and move inside the parent, min-height avoids the height shrinking */\n    min-height: 40px;\n    height: 40px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n  '], ['\n    display: flex;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 14px;\n    line-height: 14px;\n  '], ['\n    font-size: 14px;\n    line-height: 14px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _chevronDown = require('react-icons/lib/io/chevron-down');

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronLeft = require('react-icons/lib/io/chevron-left');

var _chevronLeft2 = _interopRequireDefault(_chevronLeft);

var _chevronRight = require('react-icons/lib/io/chevron-right');

var _chevronRight2 = _interopRequireDefault(_chevronRight);

var _chevronUp = require('react-icons/lib/io/chevron-up');

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _reactRedux = require('react-redux');

var _navigation = require('../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _settingsTyped = require('../shared/selectors/settingsTyped');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _types = require('../types');

var _AppState = require('../types/AppState');

var _globalStyling = require('../types/globalStyling');

var _ContentWrapper = require('./ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SubNavBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DisplaySubNavBar__SubNavBarContainer',
  componentId: 'sc-r3j00e-0'
})(['display:flex;', ';', ';', ';', ';', ';@media print{display:none;}'], function (_ref) {
  var height = _ref.height;
  return '' + (height && 'height: ' + height + 'px');
}, function (_ref2) {
  var backgroundColour = _ref2.backgroundColour;
  return '' + (backgroundColour && 'background-color: ' + backgroundColour.value);
}, function (_ref3) {
  var bottomBorder = _ref3.bottomBorder;
  return '' + (bottomBorder && 'border-bottom: ' + bottomBorder.size + 'px solid ' + bottomBorder.colour);
}, function (_ref4) {
  var removeSubNav = _ref4.removeSubNav;
  return removeSubNav && _theme2.default.max.large(_templateObject);
}, _theme2.default.max.large(_templateObject2));

var SubNavLink = _styledComponents2.default.div.withConfig({
  displayName: 'DisplaySubNavBar__SubNavLink',
  componentId: 'sc-r3j00e-1'
})(['display:flex;padding:0 10px;align-items:center;min-width:100px;&:first-child{', ';}', ';', ';', ';', ';'], function (_ref5) {
  var itemBorder = _ref5.itemBorder;
  return '' + (itemBorder && 'border-left: ' + itemBorder.size + 'px solid ' + itemBorder.colour);
}, function (_ref6) {
  var itemBorder = _ref6.itemBorder;
  return '' + (itemBorder && 'border-right: ' + itemBorder.size + 'px solid ' + itemBorder.colour);
}, function (_ref7) {
  var selected = _ref7.selected,
      selectedItemBackgroundColour = _ref7.selectedItemBackgroundColour;
  return '' + (selected && selectedItemBackgroundColour && 'background-color: ' + selectedItemBackgroundColour.value);
}, _theme2.default.max.large(_templateObject3), function (_ref8) {
  var itemBorder = _ref8.itemBorder,
      direction = _ref8.direction;
  return '' + (itemBorder && direction === 'rtl' && 'border-left: ' + itemBorder.size + 'px solid ' + itemBorder.colour);
});

var LinksContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DisplaySubNavBar__LinksContainer',
  componentId: 'sc-r3j00e-2'
})(['display:flex;flex:1 0 0%;', ';'], function (_ref9) {
  var align = _ref9.align;
  return '' + (align && 'justify-content: ' + align);
});

var Button = _styledComponents2.default.div.withConfig({
  displayName: 'DisplaySubNavBar__Button',
  componentId: 'sc-r3j00e-3'
})(['display:flex;align-items:center;cursor:pointer;padding:0 5px;']);

var AccordionButton = (0, _styledComponents2.default)(Button).withConfig({
  displayName: 'DisplaySubNavBar__AccordionButton',
  componentId: 'sc-r3j00e-4'
})(['visibility:', ';', ';'], function (_ref10) {
  var visible = _ref10.visible;
  return visible ? 'visible' : 'hidden';
}, _theme2.default.min.large(_templateObject4));

var DesktopLinks = _styledComponents2.default.div.withConfig({
  displayName: 'DisplaySubNavBar__DesktopLinks',
  componentId: 'sc-r3j00e-5'
})(['display:flex;width:100%;overflow:hidden;', ';'], _theme2.default.max.medium(_templateObject4));
var MobileLinks = _styledComponents2.default.div.withConfig({
  displayName: 'DisplaySubNavBar__MobileLinks',
  componentId: 'sc-r3j00e-6'
})(['display:none;overflow:hidden;', ';'], _theme2.default.max.medium(_templateObject5));

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'DisplaySubNavBar__Content',
  componentId: 'sc-r3j00e-7'
})(['display:flex;width:100%;']);

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DisplaySubNavBar__HeaderContainer',
  componentId: 'sc-r3j00e-8'
})(['display:flex;flex:1 0 auto;padding:10px 0px;']);

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'DisplaySubNavBar__IconWrapper',
  componentId: 'sc-r3j00e-9'
})(['width:16px;height:16px;']);

var BackText = _styledComponents2.default.span.withConfig({
  displayName: 'DisplaySubNavBar__BackText',
  componentId: 'sc-r3j00e-10'
})(['text-transform:uppercase;font-family:kia_bold;font-size:12px;line-height:12px;', ';'], _theme2.default.min.large(_templateObject6));

var alignDetails = exports.alignDetails = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

var AccordionController = function AccordionController(_ref11) {
  var onButtonClick = _ref11.onButtonClick,
      arrowDownIcon = _ref11.arrowDownIcon,
      subNavChevronColour = _ref11.subNavChevronColour,
      open = _ref11.open,
      visible = _ref11.visible;

  var Chevron = open ? _chevronUp2.default : _chevronDown2.default;

  return _react2.default.createElement(
    AccordionButton,
    { onClick: onButtonClick, visible: visible },
    arrowDownIcon ? _react2.default.createElement(IconWrapper, { src: arrowDownIcon, alt: '' }) : _react2.default.createElement(Chevron, { color: subNavChevronColour })
  );
};

var AvailableLinks = function AvailableLinks(_ref12) {
  var links = _ref12.links,
      config = _ref12.config,
      selected = _ref12.selected,
      direction = _ref12.direction;
  return _react2.default.createElement(
    LinksContainer,
    { align: alignDetails[config.alignMenuItems] },
    links && links.map(function (link, index) {
      return _react2.default.createElement(
        SubNavLink,
        {
          role: 'link',
          tabIndex: 0,
          key: index.toString(),
          onClick: link.onClick,
          onKeyDown: function onKeyDown(e) {
            e.key === 'Enter' && link.onClick();
          },
          itemBorder: config.showNavBarItemBorder,
          selectedItemBackgroundColour: config.selectedItemBackgroundColour,
          selected: selected && selected.path === link.path,
          direction: direction,
          'aria-label': link.text
        },
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n            cursor: pointer;\n            width: 100%;\n            ' + (0, _Global.fontStyleOverride)(config.subNavFont) + '\n            ' + (selected === link && config.selectedItemFontColour && 'color: ' + config.selectedItemFontColour.value) + '\n          ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.large',
                template: 'text-align: center; font-size: 12px'
              }];
            }
          },
          link.text
        )
      );
    })
  );
};

var DisplaySubNavBar = function DisplaySubNavBar(_ref13) {
  var onBackButtonClick = _ref13.onBackButtonClick,
      links = _ref13.links,
      config = _ref13.config,
      selected = _ref13.selected;
  var removeSubNav = config.removeSubNav,
      chevronSize = config.chevronSize,
      showChevron = config.showChevron,
      showNavBarBottomBorder = config.showNavBarBottomBorder,
      subNavBGColour = config.subNavBGColour,
      subNavHeight = config.subNavHeight,
      subNavChevronColour = config.subNavChevronColour,
      hideAccordion = config.hideAccordion,
      arrowDownIcon = config.arrowDownIcon,
      translations = config.translations,
      backToSearchUrl = config.backToSearchUrl,
      hideBackButton = config.hideBackButton;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var _useNavigation = (0, _navigation2.default)(),
      navigateTo = _useNavigation.navigateTo;

  var platformMode = (0, _reactRedux.useSelector)(function (state) {
    return state.router.platformMode;
  });
  var path = (0, _reactRedux.useSelector)(function (state) {
    return state.router.path;
  });

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      colours = _useSelector.colours,
      contentWidth = _useSelector.contentWidth,
      direction = _useSelector.direction;

  var mobileLinks = links && links.filter(function (f) {
    return f.path !== '/compare';
  });

  var chevronColour = subNavChevronColour && subNavChevronColour.value || colours.primaryBrandColour && colours.primaryBrandColour.value;

  var showBackButton = platformMode ? !path.includes('searchresults') && showChevron : showChevron;
  return _react2.default.createElement(
    SubNavBarContainer,
    {
      backgroundColour: subNavBGColour,
      bottomBorder: showNavBarBottomBorder,
      height: subNavHeight,
      removeSubNav: removeSubNav
    },
    _react2.default.createElement(
      _ContentWrapper2.default,
      { contentWidth: contentWidth, flex: true },
      _react2.default.createElement(
        Content,
        null,
        _react2.default.createElement(
          HeaderContainer,
          null,
          !hideBackButton && showBackButton && onBackButtonClick && _react2.default.createElement(
            Button,
            {
              onClick: onBackButtonClick,
              tabIndex: 0,
              onKeyDown: function onKeyDown(e) {
                return e.key === 'Enter' && onBackButtonClick();
              }
            },
            direction === 'rtl' ? _react2.default.createElement(_chevronRight2.default, {
              size: chevronSize + 'px',
              color: chevronColour
            }) : _react2.default.createElement(_chevronLeft2.default, {
              size: chevronSize + 'px',
              color: chevronColour
            }),
            translations.back && _react2.default.createElement(
              BackText,
              { style: { marginLeft: '3px' } },
              translations.back
            )
          ),
          !hideBackButton && backToSearchUrl && '|',
          backToSearchUrl && _react2.default.createElement(
            Button,
            {
              onClick: function onClick() {
                navigateTo(backToSearchUrl);
              }
            },
            translations.backToSearch && _react2.default.createElement(
              BackText,
              null,
              translations.backToSearch
            )
          ),
          hideAccordion && _react2.default.createElement(AccordionController, {
            onButtonClick: function onButtonClick() {
              return setOpen(function (open) {
                return !open;
              });
            },
            subNavChevronColour: chevronColour,
            arrowDownIcon: arrowDownIcon,
            open: open,
            visible: mobileLinks && mobileLinks.length > 0
          })
        ),
        _react2.default.createElement(
          DesktopLinks,
          null,
          _react2.default.createElement(AvailableLinks, {
            links: links,
            config: config,
            selected: selected,
            direction: direction || 'ltr'
          })
        ),
        _react2.default.createElement(
          MobileLinks,
          null,
          _react2.default.createElement(AvailableLinks, {
            links: mobileLinks,
            config: config,
            selected: selected,
            direction: direction || 'ltr'
          })
        )
      )
    )
  );
};

exports.default = DisplaySubNavBar;