'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = reducer;

var _actions = require('./actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var defaultState = {
  status: 'unknown'
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var _ref = arguments[1];
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case _actions.constants.GET_DEALER_INFO_SUCCESS:
      return (0, _extends3.default)({}, state, {
        status: 'exists',
        dealer: payload
      });
    case _actions.constants.GET_DEALER_INFO_FAILED:
      return (0, _extends3.default)({}, state, {
        status: 'does-not-exist'
      });
    default:
      return state;
  }
}