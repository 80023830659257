'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAnimatedCarouselState = exports.CarouselConstants = exports.actions = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// remove any when ts/linter accept enums and <custom>
var actions = exports.actions = {
  reset: function reset() {
    return {
      type: _types.types.RESET
    };
  },
  setDragging: function setDragging(value) {
    return { type: _types.types.SET_DRAGGING, payload: { value: value } };
  },
  setWidth: function setWidth(value) {
    return { type: _types.types.SET_WIDTH, payload: { value: value } };
  },
  moveX: function moveX(value) {
    return {
      type: _types.types.MOVE_X,
      payload: { value: value }
    };
  },
  setCurrentPos: function setCurrentPos(value) {
    return {
      type: _types.types.SET_CURRENT_POS,
      payload: { value: value }
    };
  },
  updateClientWidth: function updateClientWidth(value) {
    return {
      type: _types.types.UPDATE_CLIENT_WIDTH,
      payload: { value: value }
    };
  },
  hoverStartNext: function hoverStartNext() {
    return {
      type: _types.types.HOVER_START_NEXT,
      payload: undefined
    };
  },
  hoverEndNext: function hoverEndNext() {
    return {
      type: _types.types.HOVER_END_NEXT,
      payload: undefined
    };
  },
  hoverEndPrev: function hoverEndPrev() {
    return {
      type: _types.types.HOVER_END_PREV,
      payload: undefined
    };
  },
  hoverStartPrev: function hoverStartPrev() {
    return {
      type: _types.types.HOVER_START_PREV,
      payload: undefined
    };
  },
  setCursorVariant: function setCursorVariant(value) {
    return {
      type: _types.types.SET_CURSOR_VARIANT,
      payload: { cursorVariant: value }
    };
  }
};

var CarouselConstants = exports.CarouselConstants = {
  PERCENTAGE: 0.1, //Percentage to move carousel on hover actions
  INITIAL_MARGIN: 64 // Initial left margin
};

var animatedCarouselReducer = function animatedCarouselReducer(state, action) {
  switch (action.type) {
    case _types.types.SET_DRAGGING:
      {
        return (0, _extends3.default)({}, state, {
          isDragging: action.payload.value
        });
      }
    case _types.types.SET_WIDTH:
      {
        return (0, _extends3.default)({}, state, {
          width: action.payload.value,
          posXNew: 0,
          isDragging: false,
          cursor: 'pointer',
          selectedVariant: undefined,
          currentPos: 0
        });
      }
    case _types.types.MOVE_X:
      {
        var x = state.posXNew + action.payload.value;
        var newPos = x >= 0 // go to the init of container
        ? 0 : Math.abs(x) >= state.width // got to the end of container
        ? -state.width : state.posXNew + action.payload.value;

        return (0, _extends3.default)({}, state, {
          posXNew: newPos,
          selectedVariant: 'click',
          cursor: 'pointer'
        });
      }
    case _types.types.SET_CURRENT_POS:
      {
        return (0, _extends3.default)({}, state, {
          currentPos: action.payload.value
        });
      }
    case _types.types.UPDATE_CLIENT_WIDTH:
      {
        return (0, _extends3.default)({}, state, {
          clientWidth: action.payload.value
        });
      }
    case _types.types.HOVER_START_PREV:
      {
        if (state.isDragging || state.currentPos >= 0) {
          return state;
        }

        return (0, _extends3.default)({}, state, {
          cursor: 'pointer',
          posXNew: state.currentPos + state.clientWidth * CarouselConstants.PERCENTAGE,
          selectedVariant: 'hoverStart',
          cursorVariant: 'hoverPrev'
        });
      }
    case _types.types.HOVER_END_PREV:
      {
        if (state.isDragging || state.currentPos === 0 || Math.abs(state.currentPos) < state.clientWidth * CarouselConstants.PERCENTAGE) {
          return state;
        }

        return (0, _extends3.default)({}, state, {
          cursor: 'pointer',
          posXNew: state.currentPos + state.clientWidth * -CarouselConstants.PERCENTAGE,
          selectedVariant: 'hoverEnd',
          cursorVariant: 'default'
        });
      }
    case _types.types.HOVER_START_NEXT:
      {
        if (state.isDragging || Math.abs(state.currentPos) === state.width) {
          return state;
        }

        return (0, _extends3.default)({}, state, {
          cursor: 'pointer',
          posXNew: state.currentPos + state.clientWidth * -CarouselConstants.PERCENTAGE,
          selectedVariant: 'hoverStart',
          cursorVariant: 'hoverNext'
        });
      }
    case _types.types.HOVER_END_NEXT:
      {
        //No more items to show
        if (state.isDragging || Math.abs(state.posXNew) === state.width) {
          return state;
        }

        return (0, _extends3.default)({}, state, {
          cursor: 'pointer',
          posXNew: state.posXNew + state.clientWidth * CarouselConstants.PERCENTAGE,
          selectedVariant: 'hoverEnd',
          cursorVariant: 'default'
        });
      }
    case _types.types.SET_CURSOR_VARIANT:
      {
        return (0, _extends3.default)({}, state, { cursorVariant: action.payload.value });
      }
    case _types.types.RESET:
      {
        return init();
      }
  }
  return state;
};

var init = function init() {
  return {
    width: 0,
    posXNew: 0,
    isDragging: false,
    cursor: 'pointer',
    selectedVariant: undefined,
    currentPos: 0,
    clientWidth: 0,
    cursorVariant: 'default'
  };
};

var useAnimatedCarouselState = exports.useAnimatedCarouselState = function useAnimatedCarouselState() {
  return (0, _react.useReducer)(animatedCarouselReducer, {
    currentPos: 0,
    cursor: 'pointer',
    isDragging: false,
    posXNew: 0,
    selectedVariant: undefined,
    width: 0,
    clientWidth: 0,
    cursorVariant: 'default'
  }, init);
};

exports.default = useAnimatedCarouselState;