'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldError = require('./FieldError');

var _FieldError2 = _interopRequireDefault(_FieldError);

var _WidthWrapper = require('./WidthWrapper');

var _WidthWrapper2 = _interopRequireDefault(_WidthWrapper);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var overrideStyles = {
  font: {
    padding: {
      left: '10',
      right: '10'
    }
  }
};

exports.default = function (_ref) {
  var label = _ref.label,
      required = _ref.required,
      name = _ref.name,
      value = _ref.value,
      options = _ref.options,
      onChange = _ref.onChange,
      onBlur = _ref.onBlur,
      error = _ref.error,
      isDisabled = _ref.isDisabled,
      _ref$truncateChars = _ref.truncateChars,
      truncateChars = _ref$truncateChars === undefined ? false : _ref$truncateChars,
      font = _ref.font,
      select = _ref.uiElements.select,
      translations = _ref.translations;

  return _react2.default.createElement(
    _WidthWrapper2.default,
    null,
    _react2.default.createElement(
      _FormField2.default,
      { label: label, name: name, required: required, font: font },
      _react2.default.createElement(
        _Global.GlobalSelect,
        (0, _extends3.default)({
          name: name,
          id: name,
          value: value,
          onChange: function (_onChange) {
            function onChange(_x) {
              return _onChange.apply(this, arguments);
            }

            onChange.toString = function () {
              return _onChange.toString();
            };

            return onChange;
          }(function (e) {
            onChange({ name: e.target.name, value: e.target.value });
          }),
          onBlur: function (_onBlur) {
            function onBlur(_x2) {
              return _onBlur.apply(this, arguments);
            }

            onBlur.toString = function () {
              return _onBlur.toString();
            };

            return onBlur;
          }(function (e) {
            onBlur({ name: e.target.name, value: e.target.value });
          }),
          error: error,
          disabled: isDisabled,
          truncateChars: truncateChars,
          ariaRequired: required,
          styleOverride: function styleOverride() {
            return (0, _Global.selectStyleOverride)(overrideStyles);
          }
        }, select),
        options.map(function (translationKey) {
          return _react2.default.createElement(
            'option',
            {
              key: translationKey,
              value: translations[translationKey + 'Value']
            },
            translations[translationKey + 'Label']
          );
        })
      ),
      error && _react2.default.createElement(
        _FieldError2.default,
        null,
        error
      )
    )
  );
};