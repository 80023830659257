'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n    border: 0;\n    padding: 0 30px 0 10px;\n  '], ['\n    margin: 0;\n    border: 0;\n    padding: 0 30px 0 10px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 10px;\n  '], ['\n    margin: 10px;\n  ']);

exports.default = FinancePopup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _GlobalInfo = require('./JaguarIcons/Global/GlobalInfo');

var _GlobalInfo2 = _interopRequireDefault(_GlobalInfo);

var _Close = require('./JaguarIcons/Global/Close');

var _Close2 = _interopRequireDefault(_Close);

var _OpenClose = require('./OpenClose');

var _OpenClose2 = _interopRequireDefault(_OpenClose);

var _Modal = require('./Modal');

var _Modal2 = _interopRequireDefault(_Modal);

var _Global = require('./Global');

var _FinanceContainer = require('../containers/FinanceContainer');

var _FinanceContainer2 = _interopRequireDefault(_FinanceContainer);

var _FinanceExample = require('./FinanceExample');

var _FinanceExample2 = _interopRequireDefault(_FinanceExample);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ActionButton = _styledComponents2.default.div.withConfig({
  displayName: 'FinancePopup__ActionButton',
  componentId: 'sc-entdz5-0'
})(['&:hover{cursor:pointer;}']);

var ModalTitleWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModalTitleWrapper'
}).withConfig({
  displayName: 'FinancePopup__ModalTitleWrapper',
  componentId: 'sc-entdz5-1'
})(['display:flex;background-color:#f0f0f0;width:100%;align-items:center;']);

var CloseButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'CloseButtonWrapper'
}).withConfig({
  displayName: 'FinancePopup__CloseButtonWrapper',
  componentId: 'sc-entdz5-2'
})(['display:flex;justify-content:center;cursor:pointer;width:10%;']);

var FinanceModalContent = _styledComponents2.default.div.withConfig({
  displayName: 'FinanceModalContent'
}).withConfig({
  displayName: 'FinancePopup__FinanceModalContent',
  componentId: 'sc-entdz5-3'
})(['padding:20px;margin:40px;border:1px solid #acacac;', ';', ';'], _theme2.default.max.small(_templateObject), _theme2.default.max.medium(_templateObject2));

function FinancePopup(_ref) {
  var data = _ref.data;

  return _react2.default.createElement(
    _OpenClose2.default,
    null,
    function (_ref2) {
      var open = _ref2.open,
          openClose = _ref2.openClose;
      return _react2.default.createElement(
        'div',
        null,
        open ? _react2.default.createElement(
          _Modal2.default,
          { overlay: true, onClickOutside: openClose },
          _react2.default.createElement(_FinanceContainer2.default, {
            component: function component(props) {
              return _react2.default.createElement(
                _react.Fragment,
                null,
                _react2.default.createElement(
                  ModalTitleWrapper,
                  null,
                  _react2.default.createElement(
                    _Global.HeadingTwo,
                    {
                      'data-cy': 'Popup-Title',
                      styleOverride: function styleOverride() {
                        return '\n                        display: flex;\n                        justify-content: center;\n                        width: 80%;\n                        margin-left: 10%;\n                        padding: 20px 0;\n                        text-transform: uppercase;\n                      ';
                      }
                    },
                    props.finance.translations.financeModalTitle
                  ),
                  _react2.default.createElement(
                    CloseButtonWrapper,
                    {
                      onClick: openClose,
                      'data-cy': 'Close-Button'
                    },
                    _react2.default.createElement(_Close2.default, { width: '1em', height: '1em', colour: '#ACACAC' })
                  )
                ),
                _react2.default.createElement(
                  FinanceModalContent,
                  null,
                  _react2.default.createElement(
                    _Global.Paragraph,
                    {
                      styleOverride: function styleOverride() {
                        return '\n                        display: flex;\n                        justify-content: space-between;\n                        padding: 10px;\n                        color: #444;\n                        font-weight: 600;\n                        white-space: pre-line;\n                      ';
                      }
                    },
                    props.translateFinanceTemplate('financeMyDealTitle', {
                      PRODUCT: props.finance.translations['finance' + props.finance.defaultProduct + 'Label']
                    })
                  ),
                  _react2.default.createElement(_FinanceExample2.default, (0, _extends3.default)({}, props, { data: data }))
                )
              );
            }
          })
        ) : _react2.default.createElement(
          ActionButton,
          { onClick: openClose },
          _react2.default.createElement(_GlobalInfo2.default, { width: '1.5em', height: '1.5em', colour: '#000000' })
        )
      );
    }
  );
}