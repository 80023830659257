'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Fade = require('./Fade');

var _Fade2 = _interopRequireDefault(_Fade);

var _StyledIcon = require('./StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var OverlayWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpHighlightsImageOverlay__OverlayWrapper',
  componentId: 'sc-1lraekb-0'
})(['position:relative;&:focus{outline:none;}']);

var Img = _styledComponents2.default.img.withConfig({
  displayName: 'VdpHighlightsImageOverlay__Img',
  componentId: 'sc-1lraekb-1'
})(['width:200px;height:auto;right:0;background-color:#ffffff;-moz-box-shadow:0 2px 3px 0 rgba(0,0,0,0.18);-webkit-box-shadow:0 2px 3px 0 rgba(0,0,0,0.18);box-shadow:0 2px 3px 0 rgba(0,0,0,0.18);box-sizing:border-box;border-radius:4px;']);

var VdpHighlightsImageOverlay = function (_Component) {
  (0, _inherits3.default)(VdpHighlightsImageOverlay, _Component);

  function VdpHighlightsImageOverlay(props) {
    (0, _classCallCheck3.default)(this, VdpHighlightsImageOverlay);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VdpHighlightsImageOverlay.__proto__ || (0, _getPrototypeOf2.default)(VdpHighlightsImageOverlay)).call(this, props));

    Object.defineProperty(_this, 'onToggleImage', {
      enumerable: true,
      writable: true,
      value: function value() {
        var open = _this.state.open;

        _this.setState(function () {
          return { open: !open, allowFade: true };
        });
      }
    });
    Object.defineProperty(_this, 'onCloseImage', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.setState(function () {
          return { open: false, allowFade: false };
        });
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _react2.default.createElement(
          'div',
          { style: { position: 'relative' } },
          _react2.default.createElement(
            OverlayWrapper,
            {
              tabIndex: '0',
              onClick: _this.onToggleImage,
              onBlur: _this.onCloseImage
            },
            _react2.default.createElement(_StyledIcon2.default, { link: _this.props.infoIcon, width: 16, height: 16 })
          ),
          _this.state.allowFade && _react2.default.createElement(
            _Fade2.default,
            { display: _this.state.open },
            _react2.default.createElement(
              'div',
              {
                style: {
                  position: 'absolute',
                  right: '0px',
                  top: '20px',
                  zIndex: 100
                }
              },
              _react2.default.createElement(Img, { src: _this.props.imageUrl, alt: '' })
            )
          )
        );
      }
    });


    _this.state = {
      open: false,
      allowFade: false
    };
    return _this;
  }

  return VdpHighlightsImageOverlay;
}(_react.Component);

exports.default = VdpHighlightsImageOverlay;