'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useFeaturedVehicle2 = require('./useFeaturedVehicle');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FeaturedVehicle__Wrapper',
  componentId: 'sc-pyk17t-0'
})(['margin:10px 5px;display:flex;box-sizing:border-box;']);

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      template = _ref$config.template,
      vehicleId = _ref$config.vehicleId,
      translations = _ref$config.translations,
      rates = _ref$config.rates,
      config = (0, _objectWithoutProperties3.default)(_ref$config, ['template', 'vehicleId', 'translations', 'rates']);

  var _useFeaturedVehicle = (0, _useFeaturedVehicle2.useFeaturedVehicle)(template, vehicleId, translations),
      Template = _useFeaturedVehicle.Template,
      vehicle = _useFeaturedVehicle.vehicle,
      locale = _useFeaturedVehicle.locale,
      shared = _useFeaturedVehicle.shared,
      globalStyling = _useFeaturedVehicle.globalStyling,
      priceNoDigits = _useFeaturedVehicle.priceNoDigits,
      mileageNoDigits = _useFeaturedVehicle.mileageNoDigits,
      generateSubTitle = _useFeaturedVehicle.generateSubTitle,
      onMoreInfoClick = _useFeaturedVehicle.onMoreInfoClick,
      compareVehicle = _useFeaturedVehicle.compareVehicle,
      shortlistVehicle = _useFeaturedVehicle.shortlistVehicle;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    vehicle && _react2.default.createElement(
      Wrapper,
      null,
      _react2.default.createElement(Template, {
        config: config,
        translations: translations,
        vehicle: vehicle,
        locale: locale,
        shared: shared,
        globalStyling: globalStyling,
        priceNoDigits: priceNoDigits,
        mileageNoDigits: mileageNoDigits,
        generateSubTitle: generateSubTitle,
        onMoreInfoClick: onMoreInfoClick,
        compareVehicle: compareVehicle,
        shortlistVehicle: shortlistVehicle
      })
    )
  );
};