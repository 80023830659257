'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    flex-direction: column;\n  '], ['\n    width: 100%;\n    flex-direction: column;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 30px;\n    justify-content: flex-end;\n  '], ['\n    margin-top: 30px;\n    justify-content: flex-end;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Spinner = require('../../../components/Spinner');

var _useModelSearch2 = require('./useModelSearch');

var _ModelVariantTile = require('../../../components/AstonMartin/ModelsSearch/ModelVariantTile');

var _ModelVariantTile2 = _interopRequireDefault(_ModelVariantTile);

var _modelTypes = require('../../../types/AstonMartin/modelTypes');

var _globalStyling = require('../../../types/globalStyling');

var _ModelSearchBar = require('../../../components/AstonMartin/ModelSearchBar');

var _Checkbox = require('../../../components/AstonMartin/Form/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _useElementHeight = require('../../../hooks/useElementHeight');

var _useElementHeight2 = _interopRequireDefault(_useElementHeight);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Header = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__Header',
  componentId: 'sc-1ae9u24-0'
})(['display:flex;justify-content:space-between;margin:50px 0;font-size:16px;font-family:AstonMartinFlare-Medium;text-transform:uppercase;text-align:center;justify-content:center;width:100%;']);

var HeaderTitle = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__HeaderTitle',
  componentId: 'sc-1ae9u24-1'
})(['display:flex;width:1000px;justify-content:space-between;margin:0 30px;', ';'], _theme2.default.max.small(_templateObject));

var SelectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__SelectionWrapper',
  componentId: 'sc-1ae9u24-2'
})(['display:flex;flex-direction:row;justify-content:space-between;font-size:14px;color:#000;text-transform:uppercase;align-items:center;span{margin-right:10px;}', ';'], _theme2.default.max.small(_templateObject2));

var ModelSearchWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__ModelSearchWrapper',
  componentId: 'sc-1ae9u24-3'
})(['padding:20px 0;height:100%;width:100%;', ';'], _theme2.default.max.small(_templateObject3));

var StickyBottomSearchBarWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelSearch__StickyBottomSearchBarWrapper',
  componentId: 'sc-1ae9u24-4'
})(['position:sticky;bottom:', 'px;left:0;width:100%;'], function (_ref) {
  var emissionHeight = _ref.emissionHeight;
  return emissionHeight || 0;
});

var ModelSearch = function ModelSearch(_ref2) {
  var config = (0, _objectWithoutProperties3.default)(_ref2.config, []),
      globalStyling = _ref2.globalStyling;

  var _useModelSearch = (0, _useModelSearch2.useModelSearch)(config.searchSections),
      getModelGroups = _useModelSearch.getModelGroups,
      onTileSelected = _useModelSearch.onTileSelected,
      initialiseFilters = _useModelSearch.initialiseFilters,
      clearState = _useModelSearch.clearState,
      modelGroups = _useModelSearch.modelGroups,
      clearQueryParams = _useModelSearch.clearQueryParams,
      selectedTiles = _useModelSearch.selectedTiles,
      prepareSRPFilters = _useModelSearch.prepareSRPFilters,
      isTileSelected = _useModelSearch.isTileSelected,
      selectAllTiles = _useModelSearch.selectAllTiles,
      isSelectAllChecked = _useModelSearch.isSelectAllChecked;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      selectAllChecked = _useState2[0],
      setSelectAllChecked = _useState2[1];

  var emissionHeight = (0, _useElementHeight2.default)('emissions-bar');

  (0, _react.useEffect)(function () {
    clearQueryParams();
    clearState();
    initialiseFilters();
    getModelGroups();
    return function () {
      clearState();
    };
  }, []);

  // Handles the Select all state based on tiles selection.
  (0, _react.useEffect)(function () {
    setSelectAllChecked(isSelectAllChecked());
  }, [selectedTiles]);

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      Header,
      null,
      _react2.default.createElement(
        HeaderTitle,
        null,
        _react2.default.createElement(
          'span',
          null,
          config.translations.title
        ),
        _react2.default.createElement(
          SelectionWrapper,
          null,
          _react2.default.createElement(
            'span',
            null,
            config.translations.selectAll
          ),
          _react2.default.createElement(_Checkbox2.default, {
            onClick: function onClick() {
              return selectAllTiles(!selectAllChecked);
            },
            checked: selectAllChecked
          })
        )
      )
    ),
    _react2.default.createElement(
      _Spinner.SpinnerWrapper,
      {
        loaded: modelGroups.length > 0,
        globalStyling: globalStyling
      },
      function () {
        return _react2.default.createElement(
          ModelSearchWrapper,
          null,
          config.searchSections.map(function (section) {
            return _react2.default.createElement(_ModelVariantTile2.default, {
              key: section.name,
              section: section,
              config: config,
              onTileSelected: onTileSelected,
              isTileSelected: isTileSelected
            });
          })
        );
      }
    ),
    _react2.default.createElement(
      StickyBottomSearchBarWrapper,
      { emissionHeight: emissionHeight },
      _react2.default.createElement(_ModelSearchBar.ModelSearchBar, {
        config: config,
        tilesSelection: selectedTiles,
        prepareSRPFilters: prepareSRPFilters
      })
    )
  );
};

exports.default = ModelSearch;