'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      font-size: 12px;\n  '], ['\n      font-size: 12px;\n  ']);

exports.default = SelectField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

var _FieldValidationError = require('./FieldValidationError');

var _FieldValidationError2 = _interopRequireDefault(_FieldValidationError);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'SelectField__Select',
  componentId: 'sc-1rl5e7f-0'
})(['font-family:\'GenesisSansTextGlobal-Regular\';font-size:16px;background:#000;border:none;color:#fff;padding:0 30px 10px 10px;&:disabled{border:none;-moz-appearance:none;-webkit-appearance:none;padding:0 8px;::-ms-expand{display:none;}}&:focus{color:#fff;outline:none;}-webkit-appearance:none;-moz-appearance:none;background-image:url(\'https://res.cloudinary.com/motortrak/image/upload/v1696323878/locator/genesis/icons/down_chev.svg\');background-repeat:no-repeat;background-position-x:100%;background-position-y:2px;', ''], function (_ref) {
  var minimal = _ref.minimal;
  return minimal && 'border: none;\n      background-color: transparent;\n      padding: 0;\n      background-position: right;\n      background-position-x: 100%;\n      background-size: 12px 6px;\n      background-image: url(\'https://res.cloudinary.com/motortrak/image/upload/v1696323878/locator/genesis/icons/down_chev.svg\');\n      font-size: 12px;\n      color: #fff;\n      min-width: 45px;\n  ';
});

var Label = _styledComponents2.default.label.withConfig({
  displayName: 'SelectField__Label',
  componentId: 'sc-1rl5e7f-1'
})(['color:#fff;', ''], _Genesis2.default.max.medium(_templateObject));

function SelectField(_ref2) {
  var required = _ref2.required,
      id = _ref2.id,
      label = _ref2.label,
      onChange = _ref2.onChange,
      disabled = _ref2.disabled,
      options = _ref2.options,
      valueFromOption = _ref2.valueFromOption,
      labelFromOption = _ref2.labelFromOption,
      pleaseSelect = _ref2.pleaseSelect,
      error = _ref2.error,
      value = _ref2.value,
      dataCy = _ref2.dataCy,
      removeMargin = _ref2.removeMargin,
      minimal = _ref2.minimal,
      _ref2$orientation = _ref2.orientation,
      orientation = _ref2$orientation === undefined ? 'vertical' : _ref2$orientation;

  return _react2.default.createElement(
    _FormField2.default,
    { removeMargin: removeMargin, orientation: orientation },
    label && _react2.default.createElement(
      Label,
      { minimal: minimal },
      label,
      !required && !minimal && ' (optional)'
    ),
    _react2.default.createElement(
      Select,
      {
        onChange: function (_onChange) {
          function onChange(_x) {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function (_ref3) {
          var _ref3$target = _ref3.target,
              val = _ref3$target.value,
              op = _ref3$target.options,
              selectedIndex = _ref3$target.selectedIndex;

          var optionLabel = op[selectedIndex].innerText;
          onChange({ id: id, value: val, label: optionLabel });
        }),
        disabled: disabled,
        value: value,
        'data-cy': dataCy || '',
        minimal: minimal
      },
      [!value && pleaseSelect && _react2.default.createElement(
        'option',
        { key: 'default' },
        pleaseSelect
      )].concat((0, _toConsumableArray3.default)(options.map(function (o) {
        var optionLabel = labelFromOption(o);
        var optionValue = valueFromOption(o);
        return _react2.default.createElement(
          'option',
          { key: '' + optionValue + optionLabel, value: optionValue },
          optionLabel
        );
      }))).filter(Boolean)
    ),
    error && _react2.default.createElement(
      _FieldValidationError2.default,
      null,
      error
    )
  );
}

SelectField.defaultProps = {
  valueFromOption: function valueFromOption(i) {
    return i.value;
  },
  labelFromOption: function labelFromOption(i) {
    return i.label;
  },
  error: '',
  disabled: false,
  required: false,
  removeMargin: false
};