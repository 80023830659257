'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FinanceContainer = require('../../containers/FinanceContainer');

var _FinanceContainer2 = _interopRequireDefault(_FinanceContainer);

var _RepresentativeExample = require('../../components/RepresentativeExample');

var _RepresentativeExample2 = _interopRequireDefault(_RepresentativeExample);

var _financeCalculator = require('../../shared/financeCalculator');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Repex__Wrapper',
  componentId: 'sc-8fn8ad-0'
})(['box-sizing:border-box;', ';', ';', ';'], function (_ref) {
  var config = _ref.config;
  return config.horizontalBorder && '\n  border-top: 1px solid ' + (config.borderColour && config.borderColour.value) + ';\n  border-bottom: 1px solid ' + (config.borderColour && config.borderColour.value) + ';\n  ';
}, function (_ref2) {
  var config = _ref2.config;
  return config.verticalBorder && '\n  border-left: 1px solid ' + (config.borderColour && config.borderColour.value) + ';\n  border-right: 1px solid ' + (config.borderColour && config.borderColour.value) + ';\n  ';
}, function (_ref3) {
  var config = _ref3.config;
  return config.backgroundColour && 'background-color: ' + config.backgroundColour.value;
});

var Repex = function (_Component) {
  (0, _inherits3.default)(Repex, _Component);

  function Repex() {
    var _ref4;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Repex);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref4 = Repex.__proto__ || (0, _getPrototypeOf2.default)(Repex)).call.apply(_ref4, [this].concat(args))), _this), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (_this.props.featureFlags.finance && !_this.props.shared.financeCalculator.representativeExample) {
          _this.props.dispatch(_financeCalculator.actions.loadRepresentativeExample());
        }
      }
    }), Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            config = _this$props.config,
            shared = _this$props.shared,
            globalStyling = _this$props.globalStyling;

        var representativeExample = shared && shared.financeCalculator.representativeExample;
        return _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          _react2.default.createElement(
            Wrapper,
            { config: config },
            _react2.default.createElement(_FinanceContainer2.default, {
              component: function component(financeProps) {
                return _react2.default.createElement(_RepresentativeExample2.default, (0, _extends3.default)({}, financeProps, {
                  representativeExample: representativeExample,
                  config: config
                }));
              }
            })
          )
        );
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  return Repex;
}(_react.Component);

exports.default = Repex;