'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IconButton = _styledComponents2.default.button.withConfig({
  displayName: 'IconButton'
}).withConfig({
  displayName: 'IconButton',
  componentId: 'sc-lx381t-0'
})(['background:none;border:none;cursor:pointer;display:flex;align-items:center;color:#444444;font-size:15px;font-weight:bold;']);

exports.default = IconButton;