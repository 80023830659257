'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Carousel = require('../../Carousel');

var _Carousel2 = _interopRequireDefault(_Carousel);

var _ModalOverlay = require('./ModalOverlay');

var _ModalOverlay2 = _interopRequireDefault(_ModalOverlay);

var _parseYouTubeId = require('../../YouTubePlayer/parseYouTubeId');

var _parseYouTubeId2 = _interopRequireDefault(_parseYouTubeId);

var _useVehicleGallery2 = require('../../../hooks/useVehicleGallery');

var _YouTubePlayer = require('../../YouTubePlayer');

var _YouTubePlayer2 = _interopRequireDefault(_YouTubePlayer);

var _ImgCollage = require('../ImgCollage');

var _ImgCollage2 = _interopRequireDefault(_ImgCollage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VehicleGallery = function VehicleGallery(props) {
  var _props$config = props.config,
      config = _props$config === undefined ? {
    translations: {}
  } : _props$config,
      isLooping = props.isLooping,
      placeholdingImage = props.placeholdingImage,
      vehicleInfo = props.vehicleInfo,
      rotateGallery = props.rotateGallery,
      imageCarousel = props.imageCarousel,
      hideZoomMobile = props.hideZoomMobile,
      globalStyling = props.globalStyling,
      translations = props.translations,
      vehicleImages = props.vehicleImages;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      isVideoModalOpen = _useState2[0],
      setIsVideoModalOpen = _useState2[1];

  var parsedYouTubeId = vehicleInfo.video ? (0, _parseYouTubeId2.default)(vehicleInfo.video) : null;

  var _useVehicleGallery = (0, _useVehicleGallery2.useVehicleGallery)({
    rotateGallery: props.rotateGallery,
    direction: props.globalStyling.direction,
    slides: vehicleImages.slides,
    placeholdingImage: placeholdingImage
  }),
      isOpen = _useVehicleGallery.isOpen,
      currentCarouselImage = _useVehicleGallery.currentCarouselImage,
      containerRef = _useVehicleGallery.containerRef,
      openLightbox = _useVehicleGallery.openLightbox,
      closeLightbox = _useVehicleGallery.closeLightbox,
      goToSlide = _useVehicleGallery.goToSlide;

  var carouselProps = {
    hideZoomMobile: hideZoomMobile,
    slides: vehicleImages.slides.map(function (slide) {
      return (0, _extends3.default)({}, slide, { component: _Carousel.Slide });
    }),
    isLooping: isLooping,
    rotate: rotateGallery,
    onClickNext: function onClickNext() {
      return goToSlide(currentCarouselImage + 1);
    },
    onClickPrev: function onClickPrev() {
      return goToSlide(currentCarouselImage - 1);
    },
    slideIndex: currentCarouselImage,
    arrowRight: imageCarousel && imageCarousel.arrowRight,
    arrowLeft: imageCarousel && imageCarousel.arrowLeft,
    icon360: imageCarousel && imageCarousel.icon360,
    enable360View: imageCarousel && imageCarousel.enable360View,
    imageHeight: imageCarousel && imageCarousel.imageHeight,
    video: config.video,
    close360Icon: imageCarousel && imageCarousel.close360Icon,
    vehicleInfo: vehicleInfo,
    globalStyling: globalStyling,
    translations: translations,
    imageCarousel: imageCarousel,
    parsedYouTubeId: parsedYouTubeId
  };

  return _react2.default.createElement(
    _react.Fragment,
    null,
    isOpen && _react2.default.createElement(
      _ModalOverlay2.default,
      {
        close: closeLightbox,
        config: config,
        modalWidth: '80%',
        direction: globalStyling.direction
      },
      _react2.default.createElement(_Carousel2.default, (0, _extends3.default)({}, carouselProps, { fullscreen: true }))
    ),
    isVideoModalOpen && _react2.default.createElement(
      _ModalOverlay2.default,
      {
        close: function close() {
          return setIsVideoModalOpen(false);
        },
        config: config,
        modalWidth: '80%',
        direction: globalStyling.direction
      },
      _react2.default.createElement(_YouTubePlayer2.default, {
        src: parsedYouTubeId,
        autoplay: false,
        height: 'calc(100vw * 0.56)'
      })
    ),
    _react2.default.createElement(_ImgCollage2.default, {
      ref: containerRef,
      onClick: openLightbox,
      images: vehicleInfo.images,
      config: config,
      video: parsedYouTubeId,
      videoOnClick: function videoOnClick() {
        setIsVideoModalOpen(true);
      },
      cameraOnClick: openLightbox,
      vdp: true,
      imageAltText: vehicleInfo.name
    })
  );
};
exports.default = VehicleGallery;