'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n    padding: 0 5%;\n  '], ['\n  ', ';\n    padding: 0 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n  '], ['\n    flex-direction: row;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: center;\n  '], ['\n    justify-content: center;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHelmet = require('react-helmet');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Form = require('../../../components/AstonMartin/Form');

var _EnquiryFormConfirmation = require('../../../components/AstonMartin/EnquiryFormConfirmation');

var _EnquiryFormConfirmation2 = _interopRequireDefault(_EnquiryFormConfirmation);

var _Global = require('../../../components/Global');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

require('../../../modules/Enquiry/reducer');

var _validation = require('../../../helpers/validation');

var _formTitleOptions = require('../../../helpers/formTitleOptions');

var _numbers = require('../../../shared/localisation/numbers');

var _InputField = require('../../../components/AstonMartin/Form/InputField');

var _vehicle = require('../../../helpers/vehicle');

var _router = require('../../../actions/router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'AstonMartinTemplate__Container',
  componentId: 'sc-m5ikh7-0'
})(['display:flex;justify-content:center;']);

var Header = _styledComponents2.default.div.withConfig({
  displayName: 'AstonMartinTemplate__Header',
  componentId: 'sc-m5ikh7-1'
})(['display:flex;justify-content:center;margin:80px 0px;font-size:16px;font-family:AstonMartinFlare-Medium;text-transform:uppercase;line-height:22px;color:#000000;']);

var EnquiryFormContainer = _styledComponents2.default.div.withConfig({
  displayName: 'AstonMartinTemplate__EnquiryFormContainer',
  componentId: 'sc-m5ikh7-2'
})(['', ';width:40%;', ';height:auto;margin:40px 0;'], _theme2.default.max.large(_templateObject, function (_ref) {
  var width = _ref.width;
  return 'width: ' + (width ? width + 'px' : '90%');
}), function (_ref2) {
  var width = _ref2.width;
  return 'width: ' + (width ? width + 'px' : '40%');
});

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'AstonMartinTemplate__Row',
  componentId: 'sc-m5ikh7-3'
})(['display:flex;flex-direction:column;align-items:flex-start;', ';'], _theme2.default.min.large(_templateObject2));

var ActionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'AstonMartinTemplate__ActionsContainer',
  componentId: 'sc-m5ikh7-4'
})(['display:flex;width:100%;justify-content:space-between;margin-top:50px;']);

var Error = _styledComponents2.default.div.withConfig({
  displayName: 'AstonMartinTemplate__Error',
  componentId: 'sc-m5ikh7-5'
})(['width:100%;clear:both;color:#9e1b32;', ';'], _theme2.default.max.large(_templateObject3));

var StayConnectedContainer = _styledComponents2.default.div.withConfig({
  displayName: 'AstonMartinTemplate__StayConnectedContainer',
  componentId: 'sc-m5ikh7-6'
})(['margin-top:40px;display:flex;flex-direction:column;']);

var StayConnectedTitle = (0, _styledComponents2.default)(Header).withConfig({
  displayName: 'AstonMartinTemplate__StayConnectedTitle',
  componentId: 'sc-m5ikh7-7'
})(['margin:20px 0;justify-content:flex-start;text-transform:uppercase;']);

var StayConnectedList = _styledComponents2.default.ul.withConfig({
  displayName: 'AstonMartinTemplate__StayConnectedList',
  componentId: 'sc-m5ikh7-8'
})(['padding-left:20px;']);

var StyledLink = _styledComponents2.default.a.withConfig({
  displayName: 'AstonMartinTemplate__StyledLink',
  componentId: 'sc-m5ikh7-9'
})(['cursor:pointer;color:inherit;:visited{color:inherit;}']);

var EnquiryForm = function (_Component) {
  (0, _inherits3.default)(EnquiryForm, _Component);

  function EnquiryForm(props) {
    (0, _classCallCheck3.default)(this, EnquiryForm);

    var _this = (0, _possibleConstructorReturn3.default)(this, (EnquiryForm.__proto__ || (0, _getPrototypeOf2.default)(EnquiryForm)).call(this, props));

    Object.defineProperty(_this, 'onSelectChange', {
      enumerable: true,
      writable: true,
      value: function value(_ref3) {
        var _this$setState;

        var id = _ref3.id,
            _value = _ref3.value,
            label = _ref3.label;
        var errors = _this.state.errors;

        var error = _this.validate(id, { value: _value, label: label });
        _this.setState((_this$setState = {}, (0, _defineProperty3.default)(_this$setState, id, { label: label, value: _value }), (0, _defineProperty3.default)(_this$setState, 'errors', (0, _extends3.default)({}, errors, error)), _this$setState));
      }
    });
    Object.defineProperty(_this, 'onInputChange', {
      enumerable: true,
      writable: true,
      value: function value(_ref4) {
        var _this$setState2;

        var formKey = _ref4.id,
            _value2 = _ref4.value;
        var errors = _this.state.errors;

        var error = _this.validate(formKey, _value2);
        _this.setState((_this$setState2 = {}, (0, _defineProperty3.default)(_this$setState2, formKey, _value2), (0, _defineProperty3.default)(_this$setState2, 'errors', (0, _extends3.default)({}, errors, error)), _this$setState2));
      }
    });
    Object.defineProperty(_this, 'onSubmitMessage', {
      enumerable: true,
      writable: true,
      value: function value() {
        var params = new URLSearchParams(window.location.search);
        var _this$props = _this.props,
            submitMessage = _this$props.submitMessage,
            vehicle = _this$props.vehicle;
        var _this$state = _this.state,
            title = _this$state.title,
            enquiryType = _this$state.enquiryType,
            consent = _this$state.consent;
        var errors = _this.state.errors;

        var newErrors = (0, _entries2.default)(_this.state).map(function (entry) {
          return _this.validate(entry[0], entry[1]);
        }).filter(function (entry) {
          return entry !== undefined;
        }).reduce(function (acc, currVal) {
          return (0, _extends3.default)({}, acc, currVal);
        }, {});

        if ((0, _values2.default)(newErrors).filter(function (error) {
          return error !== undefined;
        }).length > 0) {
          window.scroll(0, 0);
          _this.setState({ errors: (0, _extends3.default)({}, errors, newErrors) });
          return;
        }

        submitMessage((0, _extends3.default)((0, _extends3.default)({}, vehicle, _this.state, {
          emailConsent: consent.email,
          phoneConsent: consent.phone,
          marketingConsent: consent.post,
          smsConsent: consent.sms,
          title: title.value,
          enquiryType: enquiryType.value,
          // Finance values (comes from app state. They are set when user changes finance calculator values.)
          financeType: params.get('financeType'),
          deposit: params.get('deposit'),
          term: params.get('term'),
          payment: params.get('payment'),
          mileage: params.get('mileage')
        })));
      }
    });
    Object.defineProperty(_this, 'validate', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value3) {
        var translations = _this.props.config.translations;


        var nonValidatedKeys = ['enquiryType', 'vehicleOfInterest', 'errors', 'comments', 'consent'];
        var actualValidationKeys = [].concat(nonValidatedKeys);

        actualValidationKeys = [].concat((0, _toConsumableArray3.default)(actualValidationKeys));

        if (actualValidationKeys.includes(formKey)) return undefined;

        var validations = {
          title: _validation.required,
          firstName: _validation.required,
          lastName: _validation.required,
          email: _validation.validEmail,
          phone: _validation.required,
          comments: _validation.required,
          accepted: _validation.valueIsTrue
        };

        if (formKey === 'title') {
          return (0, _defineProperty3.default)({}, formKey, validations[formKey](_value3.value, translations));
        }
        return (0, _defineProperty3.default)({}, formKey, validations[formKey](_value3, translations));
      }
    });

    _this.state = {
      title: {
        label: props.config.translations.formTitleSelect,
        value: ''
      },
      enquiryType: {
        label: props.config.translations.enquiryType,
        value: props.config.translations.enquiryType
      },
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      comments: '',
      errors: {},
      accepted: false,
      consent: {
        sms: false,
        email: false,
        phone: false,
        post: false
      }
    };
    return _this;
  }

  (0, _createClass3.default)(EnquiryForm, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      var _this2 = this;

      var _state = this.state,
          accepted = _state.accepted,
          errors = _state.errors;

      if (prevState.accepted !== accepted) {
        this.setState(function () {
          return {
            errors: (0, _extends3.default)({}, errors, _this2.validate('accepted', accepted))
          };
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          _props$config = _props.config,
          translations = _props$config.translations,
          formWidth = _props$config.formWidth,
          stayConnectedContentKeys = _props$config.stayConnectedContentKeys,
          personalDataTextKeys = _props$config.personalDataTextKeys,
          privacyPolicyLink = _props$config.privacyPolicyLink,
          vdpUrlMetaInfo = _props$config.vdpUrlMetaInfo,
          customSeoProps = _props$config.customSeoProps,
          vehicle = _props.vehicle,
          error = _props.error,
          submissionStatus = _props.submissionStatus,
          dispatch = _props.dispatch,
          shared = _props.shared;


      var vehicleOfInterest = vehicle ? vehicle.modelYear + ' ' + vehicle.description + ' ' + (vehicle.price.value !== 0 ? '' + (0, _numbers.localiseCurrency)(vehicle.price.value, vehicle.locale, vehicle.price.currency, 0) : '') : '';

      var _state2 = this.state,
          title = _state2.title,
          firstName = _state2.firstName,
          lastName = _state2.lastName,
          email = _state2.email,
          phone = _state2.phone,
          comments = _state2.comments,
          errors = _state2.errors,
          accepted = _state2.accepted,
          consent = _state2.consent;


      var hasError = function hasError(field) {
        return errors && errors[field] && errors[field][0];
      };

      var enquiryFormGoBack = function enquiryFormGoBack() {
        var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)({ vdpUrlMetaInfo: vdpUrlMetaInfo }, vehicle, shared.sessionPreferences.language);
        dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
      };

      if (!vehicle) return null;

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          _reactHelmet.Helmet,
          null,
          _react2.default.createElement(
            'title',
            null,
            (0, _vehicle.translateTemplateWithVehicleData)('customSeoTitle', customSeoProps, vehicle, translations)
          )
        ),
        _react2.default.createElement(
          Container,
          null,
          submissionStatus === 'Successful' ? _react2.default.createElement(_EnquiryFormConfirmation2.default, {
            translations: translations,
            onGoBack: enquiryFormGoBack
          }) : _react2.default.createElement(
            EnquiryFormContainer,
            { width: formWidth },
            _react2.default.createElement(
              Header,
              null,
              translations.enquiryFormHeader
            ),
            _react2.default.createElement(
              Row,
              null,
              _react2.default.createElement(_Form.InputField, {
                id: 'vehicleOfInterest',
                type: 'text',
                label: translations.vehicleOfInterestLabel,
                value: vehicleOfInterest,
                onChange: this.onInputChange,
                disabled: true
              })
            ),
            _react2.default.createElement(
              Row,
              null,
              _react2.default.createElement(_Form.SelectField, {
                type: 'text',
                id: 'title',
                value: title.value,
                onChange: this.onSelectChange,
                label: translations.titleLabel,
                pleaseSelect: translations.formTitleSelect,
                options: (0, _formTitleOptions.buildTitleOptions)(translations, 'astonmartin'),
                error: hasError('title'),
                required: true
              })
            ),
            _react2.default.createElement(
              Row,
              null,
              _react2.default.createElement(_Form.InputField, {
                id: 'firstName',
                type: 'text',
                label: translations.firstNameLabel,
                value: firstName,
                onChange: this.onInputChange,
                error: hasError('firstName'),
                required: true
              }),
              _react2.default.createElement(_Form.InputField, {
                id: 'lastName',
                type: 'text',
                label: translations.lastNameLabel,
                value: lastName,
                onChange: this.onInputChange,
                error: hasError('lastName'),
                required: true
              })
            ),
            _react2.default.createElement(
              Row,
              null,
              _react2.default.createElement(_Form.InputField, {
                id: 'email',
                type: 'text',
                label: translations.emailLabel,
                value: email,
                onChange: this.onInputChange,
                error: hasError('email'),
                required: true
              }),
              _react2.default.createElement(_Form.InputField, {
                id: 'phone',
                type: 'text',
                label: translations.phoneLabel,
                value: phone,
                onChange: this.onInputChange,
                error: hasError('phone'),
                doubleRow: true,
                required: true
              })
            ),
            _react2.default.createElement(_Form.TextAreaField, {
              id: 'comments',
              type: 'text',
              label: translations.commentsLabel,
              value: comments,
              onChange: this.onInputChange,
              rows: 5
            }),
            _react2.default.createElement(
              StayConnectedContainer,
              null,
              _react2.default.createElement(
                StayConnectedTitle,
                null,
                translations.stayConnectedTitle
              ),
              _react2.default.createElement(
                _Global.Paragraph,
                { styleOverride: function styleOverride() {
                    return 'margin-bottom: 8px;';
                  } },
                translations.stayConnectedSuvTitle
              ),
              _react2.default.createElement(
                StayConnectedList,
                { style: { margin: 0 } },
                stayConnectedContentKeys.map(function (key) {
                  return _react2.default.createElement(
                    'li',
                    { key: key },
                    _react2.default.createElement(
                      _Global.Paragraph,
                      { styleOverride: function styleOverride() {
                          return 'margin: 10px 0';
                        } },
                      translations[key]
                    )
                  );
                })
              ),
              _react2.default.createElement(
                _Global.Paragraph,
                { styleOverride: function styleOverride() {
                    return 'margin-bottom: 8px;';
                  } },
                translations.consentTitle
              ),
              _react2.default.createElement(
                'div',
                {
                  style: {
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20
                  }
                },
                _react2.default.createElement(
                  Row,
                  { style: { marginBottom: '20px', flexDirection: 'row' } },
                  _react2.default.createElement(_Form.Checkbox, {
                    onClick: function onClick() {
                      _this3.setState(function () {
                        return {
                          consent: (0, _extends3.default)({}, consent, { email: !consent.email })
                        };
                      });
                    },
                    checked: consent.email
                  }),
                  _react2.default.createElement(
                    _Global.Paragraph,
                    { style: { margin: '0 0 0 10px' } },
                    translations.consentEmail
                  )
                ),
                _react2.default.createElement(
                  Row,
                  { style: { marginBottom: '20px', flexDirection: 'row' } },
                  _react2.default.createElement(_Form.Checkbox, {
                    onClick: function onClick() {
                      _this3.setState(function () {
                        return {
                          consent: (0, _extends3.default)({}, consent, { phone: !consent.phone })
                        };
                      });
                    },
                    checked: consent.phone
                  }),
                  _react2.default.createElement(
                    _Global.Paragraph,
                    { style: { margin: '0 0 0 10px' } },
                    translations.consentPhone
                  )
                ),
                _react2.default.createElement(
                  Row,
                  { style: { marginBottom: '20px', flexDirection: 'row' } },
                  _react2.default.createElement(_Form.Checkbox, {
                    onClick: function onClick() {
                      _this3.setState(function () {
                        return {
                          consent: (0, _extends3.default)({}, consent, { post: !consent.post })
                        };
                      });
                    },
                    checked: consent.post
                  }),
                  _react2.default.createElement(
                    _Global.Paragraph,
                    { style: { margin: '0 0 0 10px' } },
                    translations.consentPost
                  )
                ),
                _react2.default.createElement(
                  Row,
                  { style: { marginBottom: '20px', flexDirection: 'row' } },
                  _react2.default.createElement(_Form.Checkbox, {
                    onClick: function onClick() {
                      _this3.setState(function () {
                        return {
                          consent: (0, _extends3.default)({}, consent, { sms: !consent.sms })
                        };
                      });
                    },
                    checked: consent.sms
                  }),
                  _react2.default.createElement(
                    _Global.Paragraph,
                    { style: { margin: '0 0 0 10px' } },
                    translations.consentSMS
                  )
                )
              ),
              personalDataTextKeys.map(function (key) {
                return _react2.default.createElement(
                  _Global.Paragraph,
                  { key: key },
                  translations[key]
                );
              }),
              _react2.default.createElement(
                _Global.Paragraph,
                null,
                translations.personalDataTextPrivacy + ' ',
                _react2.default.createElement(
                  StyledLink,
                  {
                    href: privacyPolicyLink,
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  },
                  translations.privacyPolicy
                )
              )
            ),
            _react2.default.createElement(
              Row,
              { style: { margin: '20px 0', flexDirection: 'row' } },
              _react2.default.createElement(_Form.Checkbox, {
                onClick: function onClick() {
                  _this3.setState(function () {
                    return { accepted: !accepted };
                  });
                },
                checked: accepted
              }),
              _react2.default.createElement(
                _Global.Paragraph,
                { style: { margin: '0 0 0 10px' } },
                translations.policyAgreement + ' ',
                _react2.default.createElement(
                  StyledLink,
                  {
                    href: privacyPolicyLink,
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  },
                  translations.privacyPolicy
                )
              )
            ),
            hasError('accepted') && _react2.default.createElement(
              Error,
              null,
              translations.termsConditionsAccept
            ),
            _react2.default.createElement(
              ActionsContainer,
              null,
              _react2.default.createElement(_Form.PrimaryButton, {
                onClick: enquiryFormGoBack,
                text: translations.cancelActionButton
              }),
              _react2.default.createElement(_Form.PrimaryButton, {
                onClick: this.onSubmitMessage,
                text: translations.submitMessageActionButton
              })
            ),
            (error || submissionStatus === 'Failed') && _react2.default.createElement(
              Error,
              null,
              translations.somethingWentWrong
            )
          )
        )
      );
    }
  }]);
  return EnquiryForm;
}(_react.Component);

exports.default = EnquiryForm;