'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Location = undefined;
exports.default = LocationFilter;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

var _ipInformation = require('../../shared/localisation/ipInformation');

var _UserLocation = require('./UserLocation');

var _UserLocation2 = _interopRequireDefault(_UserLocation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LocationFilter__ContentWrapper',
  componentId: 'sc-1a64t5i-0'
})(['padding:0 0 12px;']);

var HeadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LocationFilter__HeadingWrapper',
  componentId: 'sc-1a64t5i-1'
})(['padding-bottom:10px;']);

var Location = exports.Location = function Location(props) {
  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      userLocationEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.userLocationEnabled
    };
  }),
      userLocationEnabled = _useSelector.userLocationEnabled;

  var userIpCity = (0, _ipInformation.getIpCity)();
  var updateSessionPreferences = props.updateSessionPreferences,
      updateFilters = props.updateFilters,
      _props$location = props.location,
      latitude = _props$location.latitude,
      longitude = _props$location.longitude,
      searchCountryCode = props.searchCountryCode;


  var handleLocationChange = (0, _react.useCallback)(function (lat, long) {
    updateSessionPreferences('location', {
      latitude: lat,
      longitude: long
    });

    updateFilters([{
      key: 'latitude',
      value: lat
    }, {
      key: 'longitude',
      value: long
    }], false);
  }, [updateSessionPreferences]);

  return _react2.default.createElement(
    'div',
    null,
    props.translations.yourLocation && _react2.default.createElement(
      HeadingWrapper,
      null,
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n            ' + props.filtersStyling.filterHeadingFont + ';\n              text-transform: uppercase;\n              font-size: 16px;\n              font-weight: 400;\n              line-height: 26px;\n              letter-spacing: 0.5px;\n            ';
          }
        },
        props.translations.yourLocation
      )
    ),
    _react2.default.createElement(_UserLocation2.default, {
      placeholderText: userLocationEnabled ? userIpCity : props.translations.locationInputPlaceholder,
      iconUrl: props.filtersStyling.filterLocationIcon,
      browserLocationCallback: handleLocationChange,
      inputLocationCallback: handleLocationChange,
      options: {
        latitude: latitude,
        longitude: longitude,
        countryCode: searchCountryCode
      }
    })
  );
};

function LocationFilter(props) {
  return _react2.default.createElement(
    ContentWrapper,
    null,
    _react2.default.createElement(Location, props)
  );
}