'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    margin-bottom: 25px;\n  '], ['\n    flex-direction: column;\n    margin-bottom: 25px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n  '], ['\n    margin: 0;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    border: 0;\n    &:first-child {\n      border: 0;\n    }\n  '], ['\n    border: 0;\n    &:first-child {\n      border: 0;\n    }\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _numbers = require('../shared/localisation/numbers');

var _translateFromTemplate = require('../shared/localisation/translateFromTemplate');

var _vehicle = require('../helpers/vehicle');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SpecContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SpecContainer'
}).withConfig({
  displayName: 'VdpSpecification__SpecContainer',
  componentId: 'sc-3hkatd-0'
})(['display:flex;margin-bottom:10px;', ';'], _theme2.default.max.medium(_templateObject));

var SpecGroup = _styledComponents2.default.div.withConfig({
  displayName: 'SpecGroup'
}).withConfig({
  displayName: 'VdpSpecification__SpecGroup',
  componentId: 'sc-3hkatd-1'
})(['width:100%;margin-right:', ';margin-left:10px;&:first-child{margin-left:0;}&:last-child{margin-right:0;}', ';'], function (_ref) {
  var marginRight = _ref.marginRight;
  return marginRight ? marginRight + 'px' : '10px';
}, _theme2.default.max.medium(_templateObject2));

var SpecItem = _styledComponents2.default.div.withConfig({
  displayName: 'SpecItem'
}).withConfig({
  displayName: 'VdpSpecification__SpecItem',
  componentId: 'sc-3hkatd-2'
})(['display:flex;line-height:40px;border-bottom:1px solid ', ';&:first-child{border-top:', ';}', ';', ';'], function (_ref2) {
  var itemBorder = _ref2.itemBorder;
  return itemBorder ? '' + itemBorder.colour : '#d8d8d8';
}, function (_ref3) {
  var borderTop = _ref3.borderTop;
  return borderTop ? borderTop.size + 'px solid #e5e5e5' : '1px solid #d8d8d8';
}, function (_ref4) {
  var padding = _ref4.padding;
  return padding && 'padding: 0 ' + padding + 'px';
}, _theme2.default.max.medium(_templateObject3));

var SpecItemLabel = _styledComponents2.default.div.withConfig({
  displayName: 'SpecItemLabel'
}).withConfig({
  displayName: 'VdpSpecification__SpecItemLabel',
  componentId: 'sc-3hkatd-3'
})(['width:', ';max-width:200px;font-size:', ';line-height:', ';', ';font-weight:600;align-items:center;display:flex;padding:15px 0;'], function (_ref5) {
  var labelItemFont = _ref5.labelItemFont;
  return labelItemFont ? '100%' : '50%';
}, function (_ref6) {
  var labelItemFont = _ref6.labelItemFont;
  return labelItemFont.fontSize ? labelItemFont.fontSize + 'px' : '16px';
}, function (_ref7) {
  var labelItemFont = _ref7.labelItemFont;
  return labelItemFont.fontSize ? labelItemFont.fontSize + 'px' : '16px';
}, function (_ref8) {
  var labelItemFont = _ref8.labelItemFont;
  return labelItemFont.colour && 'color: ' + labelItemFont.colour.value;
});

var SpecItemValue = _styledComponents2.default.div.withConfig({
  displayName: 'SpecItemValue'
}).withConfig({
  displayName: 'VdpSpecification__SpecItemValue',
  componentId: 'sc-3hkatd-4'
})(['font-size:', ';line-height:', ';', ';', ';', ';', ' align-items:center;display:flex;flex:1 1 0%;padding:15px 0;justify-content:flex-end;text-align:right;'], function (_ref9) {
  var itemFont = _ref9.itemFont;
  return itemFont.fontSize ? itemFont.fontSize + 'px' : '16px';
}, function (_ref10) {
  var itemFont = _ref10.itemFont;
  return itemFont.fontSize ? itemFont.fontSize + 'px' : '16px';
}, function (_ref11) {
  var itemFont = _ref11.itemFont;
  return itemFont.alignment && 'text-align: ' + itemFont.alignment;
}, function (_ref12) {
  var itemFont = _ref12.itemFont;
  return itemFont.alignment && 'width: 100%';
}, function (_ref13) {
  var itemFont = _ref13.itemFont;
  return itemFont.colour && 'color: ' + itemFont.colour.value;
}, function (_ref14) {
  var itemFont = _ref14.itemFont;
  return itemFont && 'flex: 1; min-width: 100px;';
});

var hidePowerKwItemsGroup1 = function hidePowerKwItemsGroup1(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations) {
  return [specification.engineDetails.consumptionExtraurban > 0 && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.consumptionExtraUrban
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.consumptionExtraurban,
      ' ',
      translations.consumptionExtraUrbanUnit
    )
  ), specification.length && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.length
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.length,
      ' ',
      translations.dimensionsUnit
    )
  ), specification.engineDetails.weightedCombinedCo2Emission && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.weightedCombinedCo2Emission
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.weightedCombinedCo2Emission,
      ' ',
      translations.co2Unit
    )
  ), specification.width && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.width
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.width,
      ' ',
      translations.dimensionsUnit
    )
  )];
};

var dontHidePKwGroup1 = function dontHidePKwGroup1(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations, vehicleInfo, convertedKwToHp) {
  return [specification.engineDetails.powerKw && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.power
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      convertedKwToHp && vehicleInfo.powerKwNumeric ? (0, _translateFromTemplate.translateFromTemplate)('powerReading', {
        KW: vehicleInfo.powerKwNumeric,
        HP: (0, _vehicle.vehiclePropFormatter)(convertedKwToHp, vehicleInfo, vehicleInfo.locale)
      }, translations) : specification.engineDetails.powerKw + ' ' + translations.powerUnit
    )
  ), specification.engineDetails.consumptionExtraurban > 0 && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.consumptionExtraUrban
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.consumptionExtraurban,
      ' ',
      translations.consumptionExtraUrbanUnit
    )
  ), specification.length && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.length
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.length,
      ' ',
      translations.dimensionsUnit
    )
  ), specification.engineDetails.weightedCombinedCo2Emission && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.weightedCombinedCo2Emission
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.weightedCombinedCo2Emission,
      ' ',
      translations.co2Unit
    )
  )];
};

var hidePowerKwItemsGroup2 = function hidePowerKwItemsGroup2(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations) {
  return [specification.engineDetails.combinedConsumption > 0 && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.consumptionCombined
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.combinedConsumption,
      ' ',
      translations.consumptionCombinedUnit
    )
  ), specification.engineDetails.co2EmissionsCombined > 0 && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.co2
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.co2EmissionsCombined,
      ' ',
      translations.co2Unit
    )
  ), specification.height && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.height
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.height,
      ' ',
      translations.dimensionsUnit
    )
  ), specification.engineDetails.weightedCombinedFuelConsumption && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.weightedCombinedFuelConsumption
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.weightedCombinedFuelConsumption,
      ' ',
      translations.weightedCombinedFuelConsumptionUnit
    )
  )];
};

var dontHidePKwGroup2 = function dontHidePKwGroup2(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations) {
  return [specification.engineDetails.combinedConsumption > 0 && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.consumptionCombined
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.combinedConsumption,
      ' ',
      translations.consumptionCombinedUnit
    )
  ), specification.engineDetails.co2EmissionsCombined > 0 && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.co2
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.co2EmissionsCombined,
      ' ',
      translations.co2Unit
    )
  ), specification.width && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.width
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.width,
      ' ',
      translations.dimensionsUnit
    )
  ), specification.engineDetails.weightedCombinedFuelConsumption && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.weightedCombinedFuelConsumption
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.weightedCombinedFuelConsumption,
      ' ',
      translations.weightedCombinedFuelConsumptionUnit
    )
  )];
};

var hidePowerKwItemsGroup3 = function hidePowerKwItemsGroup3(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations) {
  return [specification.engineDetails.consumptionUrban > 0 && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.consumptionUrban
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.consumptionUrban,
      ' ',
      translations.consumptionUrbanUnit
    )
  ), specification.engineDetails.emissionsClass && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.emissionsClass
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.emissionsClass
    )
  )];
};

var dontHidePKwGroup3 = function dontHidePKwGroup3(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations) {
  return [specification.engineDetails.consumptionUrban > 0 && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.consumptionUrban
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.consumptionUrban,
      ' ',
      translations.consumptionUrbanUnit
    )
  ), specification.engineDetails.emissionsClass && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.emissionsClass
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.engineDetails.emissionsClass
    )
  ), specification.height && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.height
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      specification.height,
      ' ',
      translations.dimensionsUnit
    )
  )];
};

var addVinToSpec = function addVinToSpec(vehicleInfo, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations) {
  return [vehicleInfo.vin && _react2.default.createElement(
    SpecItem,
    {
      padding: specItemPadding,
      borderTop: borderTop,
      itemBorder: itemBorder
    },
    _react2.default.createElement(
      SpecItemLabel,
      { labelItemFont: labelItemFont },
      translations.vin
    ),
    _react2.default.createElement(
      SpecItemValue,
      { itemFont: itemFont },
      vehicleInfo.vin
    )
  )];
};

var items = function items(specification, showVin, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations, hidePowerKw, vehicleInfo, convertedKwToHp) {
  return [].concat((0, _toConsumableArray3.default)(hidePowerKw ? hidePowerKwItemsGroup1(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations) : dontHidePKwGroup1(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations, vehicleInfo, convertedKwToHp)), (0, _toConsumableArray3.default)(hidePowerKw ? hidePowerKwItemsGroup2(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations) : dontHidePKwGroup2(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations)), (0, _toConsumableArray3.default)(hidePowerKw ? hidePowerKwItemsGroup3(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations) : dontHidePKwGroup3(specification, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations)), (0, _toConsumableArray3.default)(showVin ? addVinToSpec(vehicleInfo, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations) : [])).filter(Boolean);
};

var itemGroups = function itemGroups(specification, showVin, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations, hidePowerKw, specGroupMarginRight, vehicleInfo, convertedKwToHp) {
  var specItems = items(specification, showVin, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations, hidePowerKw, vehicleInfo, convertedKwToHp);
  var groupLength = Math.ceil(specItems.length / 3);
  var group1 = specItems.slice(0, groupLength);
  var group2 = specItems.slice(groupLength, groupLength * 2);
  var group3 = specItems.slice(groupLength * 2, groupLength * 3);

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      SpecGroup,
      { marginRight: specGroupMarginRight },
      group1.map(function (item) {
        return item;
      })
    ),
    _react2.default.createElement(
      SpecGroup,
      { marginRight: specGroupMarginRight },
      group2.map(function (item) {
        return item;
      })
    ),
    _react2.default.createElement(
      SpecGroup,
      null,
      group3.map(function (item) {
        return item;
      })
    )
  );
};

function VdpSpecification(_ref15) {
  var specification = _ref15.specification,
      showVin = _ref15.showVin,
      translations = _ref15.translations,
      showOdometerInformation = _ref15.showOdometerInformation,
      registration = _ref15.registration,
      showRegistration = _ref15.showRegistration,
      showFuelType = _ref15.showFuelType,
      styles = _ref15.styles,
      showEngineCapacity = _ref15.showEngineCapacity,
      locale = _ref15.locale,
      featuredSpec = _ref15.featuredSpec,
      hidePowerKw = _ref15.hidePowerKw,
      vehicleInfo = _ref15.vehicleInfo,
      convertedKwToHp = _ref15.convertedKwToHp;

  var _ref16 = styles || {},
      _ref16$labelItemFont = _ref16.labelItemFont,
      labelItemFont = _ref16$labelItemFont === undefined ? '' : _ref16$labelItemFont,
      _ref16$itemFont = _ref16.itemFont,
      itemFont = _ref16$itemFont === undefined ? '' : _ref16$itemFont,
      _ref16$specGroupMargi = _ref16.specGroupMarginRight,
      specGroupMarginRight = _ref16$specGroupMargi === undefined ? '' : _ref16$specGroupMargi,
      _ref16$specItemPaddin = _ref16.specItemPadding,
      specItemPadding = _ref16$specItemPaddin === undefined ? '' : _ref16$specItemPaddin,
      _ref16$borderTop = _ref16.borderTop,
      borderTop = _ref16$borderTop === undefined ? '' : _ref16$borderTop,
      _ref16$itemBorder = _ref16.itemBorder,
      itemBorder = _ref16$itemBorder === undefined ? '' : _ref16$itemBorder;

  return _react2.default.createElement(
    SpecContainer,
    null,
    featuredSpec ? itemGroups(specification, showVin, specItemPadding, borderTop, itemBorder, labelItemFont, itemFont, translations, hidePowerKw, specGroupMarginRight, vehicleInfo, convertedKwToHp) : _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        SpecGroup,
        { marginRight: specGroupMarginRight },
        _react2.default.createElement(
          SpecItem,
          {
            padding: specItemPadding,
            borderTop: borderTop,
            itemBorder: itemBorder
          },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.exterior
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            specification.exterior
          )
        ),
        _react2.default.createElement(
          SpecItem,
          { padding: specItemPadding, itemBorder: itemBorder },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.interior
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            specification.interior
          )
        ),
        specification.secondaryInterior && _react2.default.createElement(
          SpecItem,
          { padding: specItemPadding, itemBorder: itemBorder },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.interiorSecondary
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            specification.secondaryInterior
          )
        )
      ),
      _react2.default.createElement(
        SpecGroup,
        { marginRight: specGroupMarginRight },
        _react2.default.createElement(
          SpecItem,
          { padding: specItemPadding, itemBorder: itemBorder },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.bodystyle
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            specification.bodystyle
          )
        ),
        showOdometerInformation && _react2.default.createElement(
          SpecItem,
          {
            padding: specItemPadding,
            borderTop: borderTop,
            itemBorder: itemBorder
          },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.display
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            (0, _numbers.localiseNumber)(specification.odometer.reading, locale) + ' ' + specification.odometer.units
          )
        ),
        _react2.default.createElement(
          SpecItem,
          {
            padding: specItemPadding,
            borderTop: borderTop,
            itemBorder: itemBorder
          },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.transmission
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            specification.transmission
          )
        )
      ),
      _react2.default.createElement(
        SpecGroup,
        null,
        !showEngineCapacity && specification.engine && _react2.default.createElement(
          SpecItem,
          { itemBorder: itemBorder },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.engine
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            specification.engine
          )
        ),
        showEngineCapacity && specification.capacity && _react2.default.createElement(
          SpecItem,
          { itemBorder: itemBorder },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.engine
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            specification.capacity
          )
        ),
        showRegistration && _react2.default.createElement(
          SpecItem,
          { padding: specItemPadding, itemBorder: itemBorder },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.registration
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            registration
          )
        ),
        showFuelType && _react2.default.createElement(
          SpecItem,
          {
            padding: specItemPadding,
            borderTop: borderTop,
            itemBorder: itemBorder
          },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.fuelType
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            specification.fuelType
          )
        ),
        _react2.default.createElement(
          SpecItem,
          {
            padding: specItemPadding,
            borderTop: borderTop,
            itemBorder: itemBorder
          },
          _react2.default.createElement(
            SpecItemLabel,
            { labelItemFont: labelItemFont },
            translations.location
          ),
          _react2.default.createElement(
            SpecItemValue,
            { itemFont: itemFont },
            specification.location
          )
        )
      )
    )
  );
}

exports.default = VdpSpecification;