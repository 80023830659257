'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    background-color: #474747;\n  '], ['\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    background-color: #474747;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    display: block;\n  '], ['\n    width: 100%;\n    display: block;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    height: 150px;\n    width: 100%;\n  '], ['\n    display: flex;\n    height: 150px;\n    width: 100%;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n    width: 33%;\n    position: absolute;\n    top: 50px;\n    ', ';\n  '], ['\n    display: block;\n    width: 33%;\n    position: absolute;\n    top: 50px;\n    ', ';\n  ']);
/* eslint-env browser */


exports.default = VehicleModel;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _VideoPlayer = require('../../modules/VideoPlayer/VideoPlayer');

var _VideoPlayer2 = _interopRequireDefault(_VideoPlayer);

var _FooterActionBanner = require('../FooterActionBanner');

var _FooterActionBanner2 = _interopRequireDefault(_FooterActionBanner);

var _TextImage = require('./TextImage');

var _TextImage2 = _interopRequireDefault(_TextImage);

var _FiftyFifty = require('./FiftyFifty');

var _FiftyFifty2 = _interopRequireDefault(_FiftyFifty);

var _Tabs = require('./Tabs');

var _Tabs2 = _interopRequireDefault(_Tabs);

var _Performance = require('./Performance');

var _Performance2 = _interopRequireDefault(_Performance);

var _VdpAccordion = require('../VdpAccordion');

var _VdpAccordion2 = _interopRequireDefault(_VdpAccordion);

var _AccessoryBox = require('./AccessoryBox');

var _AccessoryBox2 = _interopRequireDefault(_AccessoryBox);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../Global');

var _ContentWrapper = require('../ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TopImageTextMobile = _styledComponents2.default.div.withConfig({
  displayName: 'TopImageText'
}).withConfig({
  displayName: 'VehicleModel__TopImageTextMobile',
  componentId: 'sc-3qe8k4-0'
})(['display:block;background-color:rgba(0,0,0,0.8);width:100%;height:auto;', ';'], _theme2.default.min.large(_templateObject));

var AnchorsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleModel__AnchorsContainer',
  componentId: 'sc-3qe8k4-1'
})(['', ' display:none;'], _theme2.default.min.large(_templateObject2));

var VehicleMarketing = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleModel__VehicleMarketing',
  componentId: 'sc-3qe8k4-2'
})(['', ' display:none;'], _theme2.default.min.large(_templateObject3));

var VehicleMarketingMobile = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleModel__VehicleMarketingMobile',
  componentId: 'sc-3qe8k4-3'
})(['', ';display:block;width:90%;padding:0 5%;'], _theme2.default.min.large(_templateObject));

var ActionButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleModel__ActionButtonContainer',
  componentId: 'sc-3qe8k4-4'
})(['display:none;', ';'], _theme2.default.min.large(_templateObject4));

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleModel__ImageContainer',
  componentId: 'sc-3qe8k4-5'
})(['position:relative;']);

var ImageOverlayParent = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleModel__ImageOverlayParent',
  componentId: 'sc-3qe8k4-6'
})(['display:none;', ';'], _theme2.default.min.large(_templateObject5, function (_ref) {
  var left = _ref.left;
  return left && 'left : ' + left + '%';
}));
var ImageOverlayWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleModel__ImageOverlayWrapper',
  componentId: 'sc-3qe8k4-7'
})(['&:after{content:\'\';width:100%;height:100%;background-color:#000000;opacity:0.8;position:absolute;top:0;left:0;z-index:15;}position:relative;margin:0 0 1px 0;padding:20px 40px;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleModel__Image',
  componentId: 'sc-3qe8k4-8'
})(['width:100%;']);

var ImageWithOverlay = function ImageWithOverlay(_ref2) {
  var heading = _ref2.heading,
      subheading = _ref2.subheading,
      body = _ref2.body,
      footer = _ref2.footer,
      image = _ref2.image,
      bodyFont = _ref2.bodyFont,
      headingFont = _ref2.headingFont;
  return _react2.default.createElement(
    ImageContainer,
    null,
    _react2.default.createElement(
      ImageOverlayParent,
      null,
      _react2.default.createElement(
        ImageOverlayWrapper,
        null,
        heading && _react2.default.createElement(
          _Global.HeadingOne,
          {
            styleOverride: function styleOverride() {
              return '\n                    position: relative;\n                    z-index: 20;\n                    ' + (0, _Global.fontStyleOverride)(headingFont) + '\n                  ';
            }
          },
          heading
        ),
        subheading && _react2.default.createElement(
          _Global.HeadingOne,
          {
            styleOverride: function styleOverride() {
              return '\n                    position: relative;\n                    z-index: 20;\n                    ' + (0, _Global.fontStyleOverride)(headingFont) + '\n                  ';
            }
          },
          subheading
        ),
        body && _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n                    position: relative;\n                    z-index: 20;\n                    ' + (0, _Global.fontStyleOverride)(bodyFont) + '\n                  ';
            }
          },
          body
        ),
        footer && _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n                    position: relative;\n                    z-index: 20;\n                    margin-top: 20px;\n                    ' + (0, _Global.fontStyleOverride)(bodyFont) + '\n                  ';
            }
          },
          footer
        )
      )
    ),
    _react2.default.createElement(Image, { src: image.value, alt: image.label })
  );
};

function VehicleModel(props) {
  var vehicle = props.vehicle,
      searchForModel = props.searchForModel,
      navigateToFinance = props.navigateToFinance,
      translations = props.translations,
      globalStyling = props.globalStyling,
      modelPageAccessories = props.featureFlags.modelPageAccessories,
      contentWidth = props.contentWidth,
      videoContentLeft = props.videoContentLeft;

  var FinanceBtn = function FinanceBtn() {
    return _react2.default.createElement(_Global.Button, {
      applyStyle: 'secondary',
      buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle,
      onClick: navigateToFinance,
      text: translations.financeButtonText
    });
  };
  var SearchBtn = function SearchBtn() {
    return _react2.default.createElement(_Global.Button, {
      applyStyle: 'primary',
      buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
      onClick: function onClick() {
        return searchForModel(vehicle.searchModelName);
      },
      text: translations.searchButtonText
    });
  };
  var goToAnchor = function goToAnchor(ele) {
    return document.getElementById(ele).scrollIntoView(true);
  };

  var smallImage = 35;
  var largeImage = 65;

  var headingFont = {
    colour: {
      label: '#ffffff',
      value: '#ffffff'
    },
    kerning: 1,
    fontSize: 22,
    transform: 'uppercase',
    typeface: globalStyling.fonts.primaryFont && globalStyling.fonts.primaryFont
  };

  var bodyFont = {
    colour: {
      label: '#cdcdcd',
      value: '#cdcdcd'
    },
    kerning: 1,
    fontSize: 16,
    transform: 'uppercase',
    typeface: globalStyling.fonts.secondaryFont && globalStyling.fonts.secondaryFont
  };

  return _react2.default.createElement(
    'div',
    null,
    vehicle.headerImage && _react2.default.createElement(ImageWithOverlay, {
      heading: vehicle.translations.year,
      subheading: vehicle.translations.model,
      body: vehicle.description,
      footer: vehicle.priceFrom && translations.priceFrom + ' ' + vehicle.priceFrom,
      headingFont: headingFont,
      bodyFont: bodyFont,
      image: vehicle.headerImage
    }),
    vehicle.headerVideo && _react2.default.createElement(_VideoPlayer2.default, {
      config: (0, _extends3.default)({}, vehicle.headerVideo, {
        headingFont: headingFont,
        bodyFont: bodyFont,
        translations: {
          heading: vehicle.translations.year,
          subheading: vehicle.translations.model,
          body: vehicle.description,
          footer: vehicle.priceFrom && translations.priceFrom + ' ' + vehicle.priceFrom
        },
        overlayVisible: true,
        backgroundColour: {
          label: '#000000',
          value: '#000000'
        },
        backgroundOpacity: 0.8,
        contentWidth: contentWidth,
        videoContentLeft: videoContentLeft
      }),
      preview: false
    }),
    _react2.default.createElement(
      TopImageTextMobile,
      null,
      _react2.default.createElement(
        _Global.HeadingOne,
        {
          styleOverride: function styleOverride() {
            return '\n            padding: 16px 30px 0 30px;\n            line-height: 26px;\n            color: #ffffff;\n            text-transform: uppercase;\n            font-size: 22px;\n          ';
          }
        },
        vehicle.translations.year,
        ' ',
        vehicle.translations.model
      ),
      _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return '\n            padding: 8px 30px;\n            line-height: 22px;\n            color: #cdcdcd;\n            text-transform: uppercase;\n            font-size: 16px;\n          ';
          }
        },
        vehicle.description
      ),
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n            padding: 0 30px 20px 30px;\n            line-height: 22px;\n            color: #ffffff;\n            text-transform: uppercase;\n            font-size: 16px;\n          ';
          }
        },
        translations.priceFrom,
        vehicle.priceFrom
      )
    ),
    _react2.default.createElement(
      AnchorsContainer,
      null,
      _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: contentWidth, flex: true },
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n            background-color: #474747;\n            flex: 1 0 0%;\n            align-items: center;\n            text-align: center;\n            justify-content: center;\n            padding: 20px 0;\n            cursor: pointer;\n            width: calc(100%/5);\n            border-right: 1px solid #565656;\n            text-transform: uppercase;\n            color: #fff;\n            font-size: 15px;\n            margin: 0;\n          ';
            },
            onClick: function onClick() {
              return goToAnchor('design');
            }
          },
          translations.design
        ),
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n            background-color: #474747;\n            flex: 1 0 0%;\n            align-items: center;\n            text-align: center;\n            justify-content: center;\n            padding: 20px 0;\n            cursor: pointer;\n            width: calc(100%/5);\n            border-right: 1px solid #565656;\n            text-transform: uppercase;\n            color: #fff;\n            font-size: 15px;\n            margin: 0;\n          ';
            },
            onClick: function onClick() {
              return goToAnchor('innovation');
            }
          },
          translations.innovation
        ),
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n            background-color: #474747;\n            flex: 1 0 0%;\n            align-items: center;\n            text-align: center;\n            justify-content: center;\n            padding: 20px 0;\n            cursor: pointer;\n            width: calc(100%/5);\n            border-right: 1px solid #565656;\n            text-transform: uppercase;\n            color: #fff;\n            font-size: 15px;\n            margin: 0;\n          ';
            },
            onClick: function onClick() {
              return goToAnchor('performance');
            }
          },
          translations.performance
        ),
        modelPageAccessories && _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n            background-color: #474747;\n            flex: 1 0 0%;\n            align-items: center;\n            text-align: center;\n            justify-content: center;\n            padding: 20px 0;\n            cursor: pointer;\n            width: calc(100%/5);\n            border-right: 1px solid #565656;\n            text-transform: uppercase;\n            color: #fff;\n            font-size: 15px;\n            margin: 0;\n          ';
            },
            onClick: function onClick() {
              return goToAnchor('accessories');
            }
          },
          translations.accessories
        ),
        vehicle.priceFrom && _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n            background-color:\n              ' + (globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value) + ';\n            flex: 1 0 0%;\n            align-items: center;\n            text-align: center;\n            justify-content: center;\n            padding: 20px 0;\n            cursor: pointer;\n            width: calc(100%/5);\n            border-right: 1px solid #565656;\n            text-transform: uppercase;\n            color: #fff;\n            font-size: 15px;\n            margin: 0;\n          ';
            },
            onClick: function onClick() {
              return searchForModel(vehicle.searchModelName);
            }
          },
          (0, _translateFromTemplate.translateFromTemplate)('searchModelButtonText', {
            MODEL: vehicle.translations.model
          }, translations)
        )
      )
    ),
    _react2.default.createElement(
      VehicleMarketing,
      null,
      _react2.default.createElement(_TextImage2.default, {
        imageWidthPercent: 50,
        alignment: 'row',
        title: vehicle.translations.conceptTitle,
        content: vehicle.translations.conceptDescription,
        images: vehicle.concept.imageUrls,
        iconColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
        contentWidth: contentWidth
      }),
      _react2.default.createElement(
        'div',
        { id: 'design' },
        _react2.default.createElement(_Tabs2.default, {
          tabs: [{
            title: translations.interior,
            component: _react2.default.createElement(_TextImage2.default, {
              imageWidthPercent: largeImage,
              alignment: 'row-reverse',
              title: translations.design,
              content: vehicle.translations.designDescriptionInterior,
              images: vehicle.design.images.interior,
              iconColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
              contentWidth: contentWidth
            })
          }, {
            title: translations.exterior,
            component: _react2.default.createElement(_TextImage2.default, {
              imageWidthPercent: largeImage,
              alignment: 'row',
              title: translations.design,
              content: vehicle.translations.designDescriptionExterior,
              images: vehicle.design.images.exterior,
              iconColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
              contentWidth: contentWidth
            })
          }]
        })
      ),
      _react2.default.createElement(
        'div',
        { id: 'innovation' },
        _react2.default.createElement(_TextImage2.default, {
          imageWidthPercent: largeImage,
          alignment: 'row',
          title: translations.innovation,
          content: vehicle.translations.innovationDescription,
          images: vehicle.innovation.imageUrls,
          iconColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
          contentWidth: contentWidth
        })
      ),
      _react2.default.createElement(_Tabs2.default, {
        tabs: vehicle.tabs.map(function (tab, index) {
          return {
            title: vehicle.translations['technologyTab' + (index + 1) + 'Title'],
            component: _react2.default.createElement(_TextImage2.default, {
              imageWidthPercent: smallImage,
              alignment: 'row-reverse',
              title: '',
              content: vehicle.translations['technologyTab' + (index + 1) + 'Description'],
              images: tab.imageUrls,
              iconColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
              contentWidth: contentWidth
            })
          };
        })
      }),
      _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: contentWidth },
        _react2.default.createElement(
          'div',
          { id: 'performance' },
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return '\n                text-transform: uppercase;\n                font-size: 20px;\n                margin: 50px 5% 6px;\n              ';
              }
            },
            translations.performance
          ),
          _react2.default.createElement(_Performance2.default, {
            translations: vehicle.translations,
            performance: vehicle.performance
          })
        )
      ),
      vehicle.footerImage && _react2.default.createElement(ImageWithOverlay, {
        heading: vehicle.translations.footerVideoHeading,
        subheading: vehicle.translations.footerVideoSubHeading,
        body: vehicle.translations.footerVideoBody,
        headingFont: headingFont,
        bodyFont: bodyFont,
        image: vehicle.footerImage
      }),
      vehicle.footerVideo && _react2.default.createElement(_VideoPlayer2.default, {
        config: (0, _extends3.default)({}, vehicle.footerVideo, {
          headingFont: {
            colour: {
              label: '#ffffff',
              value: '#ffffff'
            },
            kerning: 1,
            fontSize: 15,
            transform: 'uppercase',
            typeface: globalStyling.fonts.primaryFont && globalStyling.fonts.primaryFont
          },
          bodyFont: {
            colour: {
              label: '#cdcdcd',
              value: '#cdcdcd'
            },
            kerning: 1,
            fontSize: 16,
            transform: 'uppercase',
            typeface: globalStyling.fonts.secondaryFont && globalStyling.fonts.secondaryFont
          },
          translations: {
            heading: vehicle.translations.footerVideoHeading,
            subheading: vehicle.translations.footerVideoSubHeading,
            body: vehicle.translations.footerVideoBody
          },
          overlayVisible: true,
          backgroundColour: {
            label: '#000000',
            value: '#000000'
          },
          backgroundOpacity: 0.8,
          contentWidth: contentWidth,
          videoContentLeft: videoContentLeft
        }),
        preview: false,
        contentWidth: contentWidth
      }),
      modelPageAccessories && _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: contentWidth },
        _react2.default.createElement(
          'div',
          { id: 'accessories' },
          vehicle.accessories.length > 0 && _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return '\n                text-transform: uppercase;\n                font-size: 20px;\n                margin: 50px 5%;\n              ';
              }
            },
            translations.accessories
          ),
          _react2.default.createElement(
            'div',
            {
              style: {
                width: '100%',
                display: 'table',
                borderCollapse: 'separate',
                borderSpacing: '1px'
              }
            },
            vehicle.accessories.map(function (accessory, index) {
              return _react2.default.createElement(_AccessoryBox2.default, {
                key: accessory.title,
                accessory: accessory,
                translations: {
                  title: vehicle.translations['accessories' + (index + 1) + 'Title'],
                  description: vehicle.translations['accessories' + (index + 1) + 'Description']
                }
              });
            })
          )
        )
      )
    ),
    _react2.default.createElement(
      VehicleMarketingMobile,
      null,
      _react2.default.createElement(
        _VdpAccordion2.default,
        { title: vehicle.translations.conceptTitle, openByDefault: true },
        _react2.default.createElement(_FiftyFifty2.default, {
          title: vehicle.translations.conceptTitle,
          content: vehicle.translations.conceptDescription,
          image: vehicle.concept.imageUrl
        })
      ),
      _react2.default.createElement(
        _VdpAccordion2.default,
        { title: translations.design },
        _react2.default.createElement(_Tabs2.default, {
          tabs: [{
            title: translations.interior,
            component: _react2.default.createElement(_TextImage2.default, {
              imageWidthPercent: smallImage,
              alignment: 'row-reverse',
              title: translations.design,
              content: vehicle.translations.designDescriptionInterior,
              images: vehicle.design.images.interior,
              iconColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
              contentWidth: contentWidth
            })
          }, {
            title: translations.exterior,
            component: _react2.default.createElement(_TextImage2.default, {
              imageWidthPercent: smallImage,
              alignment: 'row',
              title: translations.design,
              content: vehicle.translations.designDescriptionExterior,
              images: vehicle.design.images.exterior,
              iconColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
              contentWidth: contentWidth
            })
          }]
        })
      ),
      _react2.default.createElement(
        _VdpAccordion2.default,
        { title: translations.innovation },
        _react2.default.createElement(_TextImage2.default, {
          alignment: 'row',
          title: translations.innovation,
          content: vehicle.translations.innovationDescription,
          images: vehicle.innovation.imageUrls,
          iconColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
          contentWidth: contentWidth
        })
      ),
      _react2.default.createElement(
        _VdpAccordion2.default,
        { title: translations.technology },
        _react2.default.createElement(_Tabs2.default, {
          tabs: vehicle.tabs.map(function (tab, index) {
            return {
              title: vehicle.translations['technologyTab' + (index + 1) + 'Title'],
              component: _react2.default.createElement(_TextImage2.default, {
                imageWidthPercent: smallImage,
                alignment: index % 2 === 0 ? 'row-reverse' : 'row',
                title: '',
                content: vehicle.translations['technologyTab' + (index + 1) + 'Description'],
                images: tab.imageUrls,
                iconColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
                contentWidth: contentWidth
              })
            };
          })
        })
      ),
      _react2.default.createElement(
        _VdpAccordion2.default,
        { title: translations.performance },
        _react2.default.createElement(_Performance2.default, {
          translations: vehicle.translations,
          performance: vehicle.performance
        })
      ),
      _react2.default.createElement(
        _VdpAccordion2.default,
        { title: translations.accessories },
        _react2.default.createElement(
          'div',
          { id: 'accessories', style: { width: '100%' } },
          vehicle.accessories.map(function (accessory, index) {
            return _react2.default.createElement(_AccessoryBox2.default, {
              key: accessory.title,
              accessory: accessory,
              translations: {
                title: vehicle.translations['accessories' + (index + 1) + 'Title'],
                description: vehicle.translations['accessories' + (index + 1) + 'Description']
              }
            });
          })
        )
      )
    ),
    vehicle.priceFrom && _react2.default.createElement(
      ActionButtonContainer,
      null,
      _react2.default.createElement(
        'div',
        { style: { margin: 'auto' } },
        _react2.default.createElement(_Global.Button, {
          applyStyle: 'primary',
          buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
          onClick: function onClick() {
            return searchForModel(vehicle.searchModelName);
          },
          text: translations.searchButtonText + ' ' + vehicle.translations.model
        })
      )
    ),
    vehicle.priceFrom && _react2.default.createElement(_FooterActionBanner2.default, { leftButton: FinanceBtn, rightButton: SearchBtn })
  );
}