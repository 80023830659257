'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateValidityCheck = exports.localeFormattedDate = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable import/prefer-default-export */
var localeFormattedDate = exports.localeFormattedDate = function localeFormattedDate(date, locale, options) {
  return (
    // Hard coding the time zone to UTC prevents a bug where Date will sometimes modify the date passed into it
    // by changing the date based on the users timezone, this means for people in a timezone behind the UTC the
    // date will change for them. This function should not be used for formating dates that include times.
    new Date(date).toLocaleDateString(locale.replace('_', '-'), (0, _extends3.default)({
      timeZone: 'UTC'
    }, options))
  );
};
var dateValidityCheck = exports.dateValidityCheck = function dateValidityCheck(date) {
  var sanitisedDate = date && date.trim();
  return (/^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/.test(sanitisedDate)
  );
};