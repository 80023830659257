'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row-reverse;\n    margin-top: 20px;\n  '], ['\n    flex-direction: row-reverse;\n    margin-top: 20px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _filters = require('../../shared/filters');

var _sessionPreferences = require('../../shared/sessionPreferences');

var _RollsRoyce = require('../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _actions = require('../../actions');

var _Global = require('../../components/Global');

var _PulseSkeletons = require('../../components/PulseSkeletons');

var _MobileMenu = require('../../modules/Header/MobileMenu');

var _currencyConversion = require('../../shared/currencyConversion');

var _FiltersLookup = require('./FiltersLookup');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FiltersWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersContainer__FiltersWrapper',
  componentId: 'sc-1t7dx2d-0'
})(['background-color:#fff;padding:0 20px;']);

var LineBreak = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersContainer__LineBreak',
  componentId: 'sc-1t7dx2d-1'
})(['border-top:1px solid #d5d5d5;width:100%;']);

var SelectMenuWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersContainer__SelectMenuWrapper',
  componentId: 'sc-1t7dx2d-2'
})(['border-top:1px solid #d5d5d5;display:flex;padding:10px;justify-content:space-around;']);

var ActionsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersContainer__ActionsWrapper',
  componentId: 'sc-1t7dx2d-3'
})(['box-sizing:border-box;display:flex;flex-direction:column;margin-top:18px;padding:0 20px 40px;width:100%;', ';'], _RollsRoyce2.default.max.medium(_templateObject));

var Filters = function (_Component) {
  (0, _inherits3.default)(Filters, _Component);

  function Filters(props) {
    (0, _classCallCheck3.default)(this, Filters);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Filters.__proto__ || (0, _getPrototypeOf2.default)(Filters)).call(this, props));

    Object.defineProperty(_this, 'resetFilters', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            resetFilters = _this$props.resetFilters,
            defaultSelectedFilters = _this$props.config.defaultSelectedFilters;

        _this.collapseFilters();
        resetFilters(defaultSelectedFilters);
      }
    });
    Object.defineProperty(_this, 'collapseFilters', {
      enumerable: true,
      writable: true,
      value: function value() {
        var config = _this.props.config;

        config.filterOptions.items.forEach(function (element) {
          var current = _this.filterRefs[element.lookupKey].current;

          if (current && current.setOpenState) {
            current.setOpenState(false);
          }
        });
      }
    });

    _this.filterRefs = {};
    props.config.filterOptions.items.forEach(function (element) {
      _this.filterRefs[element.lookupKey] = _react2.default.createRef();
    });
    return _this;
  }

  (0, _createClass3.default)(Filters, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var props = this.props;
      var config = props.config,
          shared = props.shared,
          locale = props.locale,
          platformMode = props.platformMode;

      var filtersBackgroundColour = props.config.stylingConfig && props.config.stylingConfig.backgroundColour;
      var currentLanguage = shared.sessionPreferences.language || locale;
      var currentCurrency = shared.sessionPreferences.currency;

      var languageOptions = config.utilityBarOptions && config.utilityBarOptions.find(function (op) {
        return op.key === 'Language';
      }).options;
      var currencyOptions = config.utilityBarOptions && config.utilityBarOptions.find(function (op) {
        return op.key === 'Currency';
      }).options;
      var territoryOptions = (config.utilityBarOptions && config.utilityBarOptions.find(function (op) {
        return op.key === 'Territory';
      }) || {}).options;

      var selectedLanguage = languageOptions && languageOptions.find(function (lo) {
        return lo.value === currentLanguage;
      });
      var selectedCurrency = currencyOptions && currencyOptions.find(function (co) {
        return co.value === currentCurrency;
      });

      return props.availableFilterValues ? _react2.default.createElement(
        _react.Fragment,
        null,
        platformMode && _react2.default.createElement(
          SelectMenuWrapper,
          null,
          _react2.default.createElement(_MobileMenu.SelectMenu, {
            config: props.config,
            changeLanguage: props.language,
            selectedLanguage: selectedLanguage,
            languages: languageOptions,
            changeCurrency: props.currency,
            selectedCurrency: selectedCurrency,
            currencies: currencyOptions,
            onMenuToggle: function onMenuToggle() {},
            territories: territoryOptions,
            selectedTerritory: props.marketInfo.territory,
            changeTerritory: props.territory,
            enableTitles: true
          })
        ),
        _react2.default.createElement(
          FiltersWrapper,
          {
            backgroundColour: filtersBackgroundColour,
            'data-cy': 'srp-filters'
          },
          props.config.filterOptions.items.filter(function (o) {
            return !o.locked && o.active;
          }).map(function (fo) {
            return _FiltersLookup.optionComponents[fo.lookupKey](fo.lookupKey, (0, _extends3.default)({}, props, {
              availableFilterValues: (0, _extends3.default)({}, props.availableFilterValues, props.config.AvailableFilterOverride)
            }), _this2.filterRefs[fo.lookupKey]);
          })
        ),
        _react2.default.createElement(
          ActionsWrapper,
          null,
          _react2.default.createElement(_Global.Button, {
            text: props.config.translations.resetCTA,
            onClick: this.resetFilters,
            applyStyle: 'secondary',
            buttonStyle: props.config.stylingConfig.resetButtonStyle ? props.config.stylingConfig.resetButtonStyle.buttonStyle : props.globalStyling.uiElements.secondaryButton && props.globalStyling.uiElements.secondaryButton.buttonStyle,
            'data-cy': 'reset-filters',
            'aria-label': props.config.translations.resetCTA,
            styleOverride: function styleOverride() {
              return '\n              border: 2px solid #151515; \n              color:#151515;\n              display: flex;\n\n            &::after {\n              background-image: url(\'https://res.cloudinary.com/motortrak/locator/rolls-royce/global/button-arrow-dark.svg\');\n              right: 2px;\n              position: relative;\n              transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;\n            }\n            &:hover::after {\n              right: 0;\n            }';
            }
          })
        )
      ) : _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          FiltersWrapper,
          { backgroundColour: '#FFF' },
          _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px', width: '100px', height: '15px' }),
          _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px', width: '90%', height: '45px' }),
          _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px 15px 25px 15px', width: '90%', height: '45px' }),
          _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px', width: '100px', height: '15px' }),
          _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px', width: '90%', height: '45px' }),
          _react2.default.createElement(LineBreak, null),
          _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px', width: '135px', height: '20px' }),
          _react2.default.createElement(LineBreak, null),
          _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px', width: '100px', height: '20px' }),
          _react2.default.createElement(LineBreak, null),
          _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px', width: '125px', height: '20px' }),
          _react2.default.createElement(LineBreak, null),
          _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px', width: '110px', height: '20px' })
        ),
        _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px 0px', width: '100%', height: '45px' }),
        _react2.default.createElement(_PulseSkeletons.PulseLine, { margin: '15px 0px', width: '100%', height: '45px' })
      );
    }
  }]);
  return Filters;
}(_react.Component);

var mapStateToProps = function mapStateToProps(_ref) {
  var router = _ref.router,
      shared = _ref.shared,
      config = _ref.config;
  return (0, _extends3.default)({}, shared.filters, {
    page: router.path,
    locale: config.settings.locale,
    currencyCode: config.config.global.inventory.currencyCode,
    location: shared.filters.selectedFilters && shared.filters.selectedFilters.longitude && shared.filters.selectedFilters.latitude ? {
      longitude: shared.filters.selectedFilters.longitude,
      latitude: shared.filters.selectedFilters.latitude
    } : shared.sessionPreferences.location || {},
    platformMode: router.platformMode
  });
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, _ref2) {
  var _ref2$config$endpoint = _ref2.config.endpoints,
      endpoints = _ref2$config$endpoint === undefined ? {} : _ref2$config$endpoint;
  return {
    updateSessionPreferences: function updateSessionPreferences(key, value) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences(key, value));
    },
    updateFilters: function updateFilters(filters) {
      return dispatch(_filters.actions.updateFilters(filters, endpoints.filters));
    },
    resetFilters: function resetFilters(urlConfig) {
      dispatch(_filters.actions.resetFilters(urlConfig));
      var query = window.location.search.substring(1);
      dispatch(_filters.actions.initialiseFilters(query, urlConfig));
    },
    searchWithFilters: function searchWithFilters(customSearchResultPath) {
      dispatch(_filters.actions.searchWithFilters(customSearchResultPath));
      dispatch(_sessionPreferences.actions.updateSessionPreferences('resetSearchResultsPageNumber', true));
    },
    changeLocation: function changeLocation(payload) {
      return dispatch(_filters.actions.changeLocation(payload));
    },
    language: function language(option) {
      dispatch(_actions.language.actions.changeLanguage(option.value));
      dispatch(_sessionPreferences.actions.updateSessionPreferences('language', option.value));
    },
    currency: function currency(option) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('currency', option.value));
      dispatch(_currencyConversion.actions.getExchangeRates(option.value));
      dispatch(_filters.actions.resetPriceSliderValues(endpoints.filters));
    },
    countries: function countries(option) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('market', option.value));
    },
    territory: function territory(option) {
      return dispatch(_actions.territory.actions.changeTerritory(option));
    },
    onChangeLanguage: function onChangeLanguage(languageCode) {
      dispatch(_sessionPreferences.actions.updateSessionPreferences('language', languageCode));
      dispatch(_actions.language.actions.changeLanguage(languageCode));
    }
  };
};

exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Filters);