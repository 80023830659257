'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCheckMobileScreen = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _theme = require('../theme');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//TODO: This hook is duplicated in lambo features/emissions branch, please make necessary changes by merging it
var useCheckMobileScreen = exports.useCheckMobileScreen = function useCheckMobileScreen() {
  var breakpoint = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'medium';

  var _useState = (0, _react.useState)(window.innerWidth),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      width = _useState2[0],
      setWidth = _useState2[1];

  var handleWindowSizeChange = function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  };

  (0, _react.useEffect)(function () {
    window.addEventListener('resize', handleWindowSizeChange);
    return function () {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= _theme.screenSizeOptions[breakpoint];
};

exports.default = useCheckMobileScreen;