'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactPhoneInput = require('react-phone-input-2');

var _reactPhoneInput2 = _interopRequireDefault(_reactPhoneInput);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FieldError = _styledComponents2.default.div.withConfig({
  displayName: 'PhoneNumberInput__FieldError',
  componentId: 'sc-1qa0d5u-0'
})(['width:100%;display:flex;justify-content:flex-start;color:#9e1b32;']);

var StyledDiv = _styledComponents2.default.div.withConfig({
  displayName: 'PhoneNumberInput__StyledDiv',
  componentId: 'sc-1qa0d5u-1'
})(['width:100%;margin-bottom:20px;']);

var PhoneNumberInput = function PhoneNumberInput(_ref) {
  var value = _ref.value,
      preferredCountries = _ref.preferredCountries,
      onChange = _ref.onChange,
      error = _ref.error,
      localisations = _ref.localisations;

  return _react2.default.createElement(
    StyledDiv,
    null,
    _react2.default.createElement(_reactPhoneInput2.default, {
      placeholder: '',
      localization: localisations,
      value: value,
      preferredCountries: preferredCountries,
      onChange: onChange,
      inputProps: {
        name: 'phone',
        id: 'phone',
        focus: true
      },
      containerStyle: (0, _extends3.default)({
        width: '100%',
        height: '45px',
        borderRadius: 'unset'
      }, error && {
        border: 'solid 1px #9e1b32'
      }),
      inputStyle: {
        width: '100%',
        height: '45px',
        borderRadius: 'unset'
      }
    }),
    error && _react2.default.createElement(
      FieldError,
      null,
      error
    )
  );
};

exports.default = PhoneNumberInput;