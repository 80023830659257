'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n        display: flex;\n    '], ['\n        display: flex;\n    ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ThinUp = require('../../components/JaguarIcons/Arrows/ThinUp');

var _ThinUp2 = _interopRequireDefault(_ThinUp);

var _Global = require('../../components/Global');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TOP_OFFSET = 300;

var ButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'BackToTop__ButtonWrapper',
  componentId: 'sc-1l8f7vk-0'
})(['display:none;', ';'], _theme2.default.min.medium(_templateObject));

var BackToTop = function (_Component) {
  (0, _inherits3.default)(BackToTop, _Component);

  function BackToTop() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, BackToTop);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = BackToTop.__proto__ || (0, _getPrototypeOf2.default)(BackToTop)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'state', {
      enumerable: true,
      writable: true,
      value: {
        show: _this.props.config.active
      }
    }), Object.defineProperty(_this, 'componentWillUnmount', {
      enumerable: true,
      writable: true,
      value: function value() {
        window.removeEventListener('scroll', _this.scrollEventListener);
      }
    }), Object.defineProperty(_this, 'backToTop', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (!_this.props.preview) {
          _this.setState(function () {
            return { show: false };
          });
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      }
    }), Object.defineProperty(_this, 'showBackToTop', {
      enumerable: true,
      writable: true,
      value: function value(show) {
        if ((window.scrollY || document.documentElement.scrollTop) > TOP_OFFSET) {
          if (!show) {
            _this.setState(function () {
              return { show: true };
            });
          }
        } else if (show) {
          _this.setState(function () {
            return { show: false };
          });
        }
      }
    }), Object.defineProperty(_this, 'scrollEventListener', {
      enumerable: true,
      writable: true,
      value: function value() {
        var show = _this.state.show;
        var _this$props$config$fo = _this.props.config.footerOffset,
            footerOffset = _this$props$config$fo === undefined ? null : _this$props$config$fo;

        var documentHeight = _this.getDocumentHeight();

        if (footerOffset && window.scrollY > documentHeight - footerOffset) {
          if (show) {
            _this.setState(function () {
              return { show: false };
            });
          }
        } else {
          _this.showBackToTop(show);
        }
      }
    }), Object.defineProperty(_this, 'getDocumentHeight', {
      enumerable: true,
      writable: true,
      value: function value() {
        var body = document.body;
        var html = document.documentElement;
        return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(BackToTop, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      window.addEventListener('scroll', this.scrollEventListener);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          config = _props.config,
          globalStyling = _props.globalStyling;

      var styleInjection = function styleInjection() {
        return '\n      ' + (_this2.state.show ? 'display: flex' : 'display: none') + ';\n      ' + (config.buttonBackgroundColour && 'background-color: ' + config.buttonBackgroundColour.value) + ';\n      font-family: unset;\n      position: fixed;\n      bottom: 50px;\n      ' + (globalStyling.direction === 'rtl' ? 'left: 5%;' : 'right: 5%;') + ';\n      border: none;\n      outline: none;\n      cursor: pointer;\n      height: 50px;\n      width: 50px;\n      z-index: 98;\n    ';
      };

      return config.active ? _react2.default.createElement(
        ButtonWrapper,
        null,
        _react2.default.createElement(_Global.Button, {
          buttonStyle: 'ButtonStyleBTT',
          applyStyle: 'primary',
          styleOverride: styleInjection,
          mobileStyleOverride: styleInjection,
          'data-cy': 'back-to-top',
          onClick: this.backToTop,
          text: _react2.default.createElement(_ThinUp2.default, {
            colour: config.buttonIconColour && config.buttonIconColour.value,
            direction: globalStyling.direction
          })
        })
      ) : null;
    }
  }]);
  return BackToTop;
}(_react.Component);

exports.default = BackToTop;