'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _Global = require('../../Global');

var _reactAnchorLinkSmoothScrollV = require('react-anchor-link-smooth-scroll-v2');

var _reactAnchorLinkSmoothScrollV2 = _interopRequireDefault(_reactAnchorLinkSmoothScrollV);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitle__Container',
  componentId: 'sc-m93bhi-0'
})(['display:flex;justify-content:space-between;margin-bottom:10px;gap:10px;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitle__IconWrapper',
  componentId: 'sc-m93bhi-1'
})(['display:flex;margin-left:auto;']);

var UnstyledLink = (0, _styledComponents2.default)(_reactAnchorLinkSmoothScrollV2.default).withConfig({
  displayName: 'VehicleTitle__UnstyledLink',
  componentId: 'sc-m93bhi-2'
})(['text-decoration:none;']);

var Super = _styledComponents2.default.sup.withConfig({
  displayName: 'VehicleTitle__Super',
  componentId: 'sc-m93bhi-3'
})(['font-size:9px;line-height:12px;font-family:\'Bentley-regular\';color:#7a7a7a;text-transform:none;']);

var PriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitle__PriceContainer',
  componentId: 'sc-m93bhi-4'
})(['text-align:right;']);

var VehicleTitle = function VehicleTitle(_ref) {
  var vehicleName = _ref.vehicleName,
      approved = _ref.approved,
      logoUrl = _ref.logoUrl,
      onClick = _ref.onClick,
      price = _ref.price,
      priceLessTaxes = _ref.priceLessTaxes,
      translations = _ref.translations,
      priceConverted = _ref.priceConverted,
      hidePrice = _ref.hidePrice,
      hideYear = _ref.hideYear,
      showSectionSalesTaxDisclaimer = _ref.showSectionSalesTaxDisclaimer;

  var mobile = (0, _useCheckIsMobileScreen2.default)('medium');
  var tablet = (0, _useCheckIsMobileScreen2.default)('large');
  var parsedVehicleName = hideYear ? vehicleName.substring(5) : vehicleName;
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      'div',
      { onClick: onClick, style: { flex: 1 } },
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n              font-family: \'Bentley-Regular\';\n              font-style: normal;\n              font-size: 17px;\n              color: #222222:\n              text-transform: none;\n              cursor: pointer;\n              :hover {\n                text-decoration: underline;\n              }\n              :active {\n                text-decoration: underline;\n              }\n            ';
          },
          mobileStyleOverride: function mobileStyleOverride() {
            return [{
              queryPath: 'max.large',
              template: 'font-size: 16px;\n              line-height: 22px;'
            }];
          }
        },
        parsedVehicleName
      )
    ),
    _react2.default.createElement(
      'div',
      {
        style: {
          flex: approved && tablet && !mobile ? 1 : undefined,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: mobile ? 'column' : 'row',
          gap: 4
        }
      },
      !hidePrice && _react2.default.createElement(
        PriceContainer,
        null,
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n              display:flex;\n              flex-direction: column;\n              font-family: \'Bentley-Regular\';\n              font-style: normal;\n              font-size: 22px;\n              color: #222222;\n            ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.large',
                template: 'font-size: 18px;\n                color: #000000;'
              }];
            }
          },
          price,
          priceConverted && _react2.default.createElement(
            UnstyledLink,
            { href: '#super-1', offset: '100' },
            _react2.default.createElement(
              Super,
              null,
              translations.priceSuper
            )
          )
        ),
        priceLessTaxes && _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n                font-family: \'Bentley-Regular\';\n                font-style: normal;\n                font-size: 12px;\n                color: #222222;\n                text-transform: none;\n            ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.large',
                template: 'font-size: 12px; color: #000000;'
              }];
            }
          },
          priceLessTaxes,
          ' ',
          translations.excludeVAT
        ),
        showSectionSalesTaxDisclaimer && _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n                font-family: \'Bentley-Regular\';\n                font-style: normal;\n                font-size: 12px;\n                color: #222222;\n                text-transform: none;\n            ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.large',
                template: 'font-size: 12px; color: #000000;'
              }];
            }
          },
          translations.differentialTaxation
        )
      ),
      tablet && approved && _react2.default.createElement(
        IconWrapper,
        null,
        _react2.default.createElement('img', { src: logoUrl, alt: 'certified-logo' })
      )
    )
  );
};

exports.default = VehicleTitle;