'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.useModelSearch = useModelSearch;

var _react = require('react');

var _reactRedux = require('react-redux');

var _src = require('ramda/src');

var _Templates = require('./Templates');

var _Templates2 = _interopRequireDefault(_Templates);

var _reducer = require('./reducer');

var _filters = require('../../shared/filters');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useModelSearch(template) {
  var TileComponent = _Templates2.default[template];
  var dispatch = (0, _reactRedux.useDispatch)();
  var queryParams = (0, _reactRedux.useSelector)(function (state) {
    return state.router.queryParams;
  });
  var useSubVariants = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.featureFlags.useSubVariantsSearch;
  });

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.modelSearch;
  }),
      modelGroups = _useSelector.modelGroups,
      loaded = _useSelector.loaded;

  (0, _react.useEffect)(function () {
    dispatch(_reducer.actions.clearState());
    dispatch(_filters.actions.initialiseFilters(queryParams));
    dispatch(_reducer.actions.getModelGroups());
    return function () {
      dispatch(_reducer.actions.clearState());
    };
  }, []);

  var selectedModels = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.filters.selectedFilters && state.shared.filters.selectedFilters.model;
  }) || [];

  var selectedVariants = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.filters.selectedFilters && (state.shared.filters.selectedFilters.modelVariants || state.shared.filters.selectedFilters.variant);
  }) || [];

  function onModelTileClick(model) {
    var modelFilterValue = _filters.helpers.newArray(selectedModels, model.modelGroupName);

    // RollsRoyce has merged Phantom VII variants into Phantoms.
    if (model.mergeWithModelGroup) {
      if (!modelFilterValue.some(function (i) {
        return i === model.mergeWithModelGroup;
      })) modelFilterValue.push(model.mergeWithModelGroup);else modelFilterValue = modelFilterValue.filter(function (i) {
        return i !== model.mergeWithModelGroup;
      });
    }

    var allModelVariants = model.variants ? useSubVariants || model.mergeWithModelGroup ? model.variants.map(function (variants) {
      return variants.subVariants.map(function (sv) {
        return sv;
      });
    }).flat() : model.variants.map(function (v) {
      return v.value;
    }) : [];

    var variantFilterValue = modelFilterValue.includes(model.modelGroupName) ? (0, _src.uniq)([].concat((0, _toConsumableArray3.default)(selectedVariants), (0, _toConsumableArray3.default)(allModelVariants))) : (0, _src.without)(allModelVariants, selectedVariants);

    dispatch(_filters.actions.updateFilters([{ key: 'model', value: modelFilterValue }, {
      key: useSubVariants ? 'variant' : 'modelVariants',
      value: variantFilterValue
    }], null, false));
  }

  function onVariantTileClick(model, variant) {
    // RollsRoyce has merged Phantom VII variants into Phantoms.
    var models = model.mergeWithModelGroup ? [model.modelGroupName, model.mergeWithModelGroup] : [model.modelGroupName];

    var variantValue = variant.value,
        subVariants = variant.subVariants;


    var variantFilterValue = subVariants ? subVariants.some(function (sub) {
      return selectedVariants.includes(sub);
    }) ? selectedVariants.filter(function (selected) {
      return subVariants.every(function (sub) {
        return sub !== selected;
      });
    }) : [].concat((0, _toConsumableArray3.default)(selectedVariants), (0, _toConsumableArray3.default)(subVariants.map(function (sub) {
      return sub;
    }))) : _filters.helpers.newArray(selectedVariants, variantValue);
    var modelFilterValue = selectedModels;

    if (subVariants && subVariants.map(function (sV) {
      return variantFilterValue.includes(sV);
    }) || variantFilterValue.includes(variantValue)) {
      modelFilterValue = models.every(function (i) {
        return modelFilterValue.includes(i);
      }) ? modelFilterValue : [].concat((0, _toConsumableArray3.default)(modelFilterValue), models);
    } else if (!model.mergeWithModelGroup) {
      modelFilterValue = model.variants.some(function (v) {
        return variantFilterValue.includes(v.value);
      }) ? modelFilterValue : _filters.helpers.newArray(modelFilterValue, model.modelGroupName);
    }

    dispatch(_filters.actions.updateFilters([{
      key: useSubVariants ? 'variant' : 'modelVariants',
      value: variantFilterValue
    }, { key: 'model', value: modelFilterValue }], null, false));
  }

  return {
    TileComponent: TileComponent,
    modelGroups: modelGroups,
    selectedModels: selectedModels,
    selectedVariants: selectedVariants,
    onModelTileClick: onModelTileClick,
    onVariantTileClick: onVariantTileClick,
    loaded: loaded
  };
}