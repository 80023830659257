'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SocialSharePopup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('./Global');

var _SocialMediaShare = require('./SocialMediaShare');

var _SocialMediaShare2 = _interopRequireDefault(_SocialMediaShare);

var _ModalOverlay = require('./ModalOverlay');

var _ModalOverlay2 = _interopRequireDefault(_ModalOverlay);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ModalTitleWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModalTitleWrapper'
}).withConfig({
  displayName: 'SocialSharePopup__ModalTitleWrapper',
  componentId: 'sc-ctf9xu-0'
})(['display:flex;background-color:#f0f0f0;width:100%;align-items:center;']);
function SocialSharePopup(_ref) {
  var children = _ref.children,
      socialShare = _ref.socialShare,
      _ref$config = _ref.config,
      translations = _ref$config.translations,
      closeIconUrl = _ref$config.closeIconUrl;

  return _react2.default.createElement(
    _ModalOverlay2.default,
    {
      component: children,
      modalWidth: 500,
      closeIconUrl: closeIconUrl
    },
    _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ModalTitleWrapper,
        null,
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            'data-cy': 'Popup-Title',
            styleOverride: function styleOverride() {
              return '\n            display: flex;\n            justify-content: center;\n            width: 80%;\n            margin-left: 10%;\n            padding: 20px 0;\n            text-transform: uppercase;\n          ';
            }
          },
          translations.shareThisPage
        )
      ),
      _react2.default.createElement(_SocialMediaShare2.default, {
        shareUrl: window.location.href,
        socialShare: socialShare
      })
    )
  );
}