'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Section;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledSection = _styledComponents2.default.div.withConfig({
  displayName: 'Section__StyledSection',
  componentId: 'sc-asxwkt-0'
})(['display:flex;justify-content:center;flex-direction:column;padding-bottom:20px;border-top:1px solid #d8d8d8;']);

function Section(props) {
  return _react2.default.createElement(
    StyledSection,
    null,
    props.children
  );
}