'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ListView;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SearchResultBlock = require('../SearchResultBlock');

var _SearchResultBlock2 = _interopRequireDefault(_SearchResultBlock);

var _SearchResultGridBlock = require('../SearchResultGridBlock');

var _SearchResultGridBlock2 = _interopRequireDefault(_SearchResultGridBlock);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Results = _styledComponents2.default.div.withConfig({
  displayName: 'ListView__Results',
  componentId: 'sc-1q1dvhw-0'
})(['width:100%;']);

function ListView(_ref) {
  var config = _ref.config,
      results = _ref.results,
      translations = _ref.translations,
      onMoreInfoClick = _ref.onMoreInfoClick,
      filtersExpanded = _ref.filtersExpanded,
      compareVehicle = _ref.compareVehicle,
      shortlistVehicle = _ref.shortlistVehicle,
      getShortListedIconColour = _ref.getShortListedIconColour,
      getCompareIconColour = _ref.getCompareIconColour,
      globalStyling = _ref.globalStyling,
      visibility = _ref.visibility,
      locale = _ref.locale,
      vehicleModelTitleFontWeight = _ref.vehicleModelTitleFontWeight,
      showGridTiles = _ref.showGridTiles,
      badgeStyling = _ref.badgeStyling,
      pricingSectionImage = _ref.pricingSectionImage,
      headerItemFont = _ref.headerItemFont,
      listViewImageWidth = _ref.listViewImageWidth,
      resultItemBackgroundColour = _ref.resultItemBackgroundColour,
      moreInfoButtonRightAlign = _ref.moreInfoButtonRightAlign,
      tileImageMaxWidth = _ref.tileImageMaxWidth,
      financeCellHorizontalPadding = _ref.financeCellHorizontalPadding,
      showFinancePopup = _ref.showFinancePopup,
      handleZeroPriceAsText = _ref.handleZeroPriceAsText,
      showNumberOfImagesInsteadOfViews = _ref.showNumberOfImagesInsteadOfViews,
      badgeWithStripUrl = _ref.badgeWithStripUrl,
      badgeWithoutStripUrl = _ref.badgeWithoutStripUrl,
      warrantyBadgeMonths = _ref.warrantyBadgeMonths,
      warrantyBadgeMaxMileage = _ref.warrantyBadgeMaxMileage,
      vehicleTitleFont = _ref.vehicleTitleFont,
      vehicleSubTitleFont = _ref.vehicleSubTitleFont,
      financeWidthOverride = _ref.financeWidthOverride,
      financePaddingOverride = _ref.financePaddingOverride,
      financeHeadingFont = _ref.financeHeadingFont,
      financeSubHeadingFont = _ref.financeSubHeadingFont,
      actionRowStyles = _ref.actionRowStyles,
      vehicleSpecContainer = _ref.vehicleSpecContainer,
      showOnDemand = _ref.showOnDemand,
      icon360 = _ref.icon360,
      iconYoutubeVideo = _ref.iconYoutubeVideo,
      onEnquiryClick = _ref.onEnquiryClick;

  return _react2.default.createElement(
    Results,
    { 'data-cy': 'search-result-vehicle-container' },
    results.map(function (vehicle) {
      return showGridTiles ? _react2.default.createElement(_SearchResultGridBlock2.default, {
        config: config,
        key: vehicle.id,
        vehicle: vehicle,
        placeholdingImage: results.placeholdingImage,
        translations: translations,
        onMoreInfoClick: onMoreInfoClick,
        filtersExpanded: filtersExpanded,
        compareVehicle: compareVehicle,
        shortlistVehicle: shortlistVehicle,
        shortListedIconColour: getShortListedIconColour(vehicle.id),
        compareIconColour: getCompareIconColour(vehicle.id),
        globalStyling: globalStyling,
        visibility: visibility,
        locale: locale,
        badgeStyling: badgeStyling,
        headerItemFont: headerItemFont,
        listViewImageWidth: listViewImageWidth,
        handleZeroPriceAsText: handleZeroPriceAsText,
        showNumberOfImagesInsteadOfViews: showNumberOfImagesInsteadOfViews,
        imageWidth: '35',
        icon360: icon360,
        iconYoutubeVideo: iconYoutubeVideo,
        onEnquiryClick: function (_onEnquiryClick) {
          function onEnquiryClick() {
            return _onEnquiryClick.apply(this, arguments);
          }

          onEnquiryClick.toString = function () {
            return _onEnquiryClick.toString();
          };

          return onEnquiryClick;
        }(function () {
          return onEnquiryClick(vehicle.id);
        })
      }) : _react2.default.createElement(_SearchResultBlock2.default, {
        key: vehicle.id,
        vehicle: vehicle,
        placeholdingImage: results.placeholdingImage,
        translations: translations,
        onMoreInfoClick: onMoreInfoClick,
        vehiclePriceSectionWidth: config.vehiclePriceSectionWidth || 24,
        displayImage: !filtersExpanded,
        compareVehicle: compareVehicle,
        shortlistVehicle: shortlistVehicle,
        shortListedIconColour: getShortListedIconColour(vehicle.id),
        compareIconColour: getCompareIconColour(vehicle.id),
        globalStyling: globalStyling,
        visibility: visibility,
        locale: locale,
        vehicleModelTitleFontWeight: vehicleModelTitleFontWeight,
        pricingSectionImage: pricingSectionImage,
        backgroundColour: resultItemBackgroundColour,
        financeCellHorizontalPadding: financeCellHorizontalPadding,
        showFinanceCellBorder: false,
        imageMaxWidth: tileImageMaxWidth || 400,
        moreInfoButtonRightAlign: moreInfoButtonRightAlign,
        showFinancePopup: showFinancePopup,
        badgeWithStripUrl: badgeWithStripUrl,
        badgeWithoutStripUrl: badgeWithoutStripUrl,
        onEnquiryClick: function (_onEnquiryClick2) {
          function onEnquiryClick() {
            return _onEnquiryClick2.apply(this, arguments);
          }

          onEnquiryClick.toString = function () {
            return _onEnquiryClick2.toString();
          };

          return onEnquiryClick;
        }(function () {
          return onEnquiryClick(vehicle.id);
        }),
        warrantyBadgeMonths: warrantyBadgeMonths,
        warrantyBadgeMaxMileage: warrantyBadgeMaxMileage,
        vehicleTitleFont: vehicleTitleFont,
        vehicleSubTitleFont: vehicleSubTitleFont,
        financeWidthOverride: financeWidthOverride,
        financePaddingOverride: financePaddingOverride,
        financeHeadingFont: financeHeadingFont,
        financeSubHeadingFont: financeSubHeadingFont,
        actionRowStyles: actionRowStyles,
        vehicleSpecContainer: vehicleSpecContainer,
        showOnDemand: showOnDemand,
        onDemandFont: config.onDemandFont,
        icon360: icon360,
        iconYoutubeVideo: iconYoutubeVideo,
        showEmissions: config.showEmissions,
        convertedKwToHp: config && config.convertedKwToHp,
        config: config
      });
    })
  );
}