'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 20px;\n  '], ['\n    padding: 0 20px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    max-height: 100%;\n    padding: 0 20px;\n  '], ['\n    max-height: 100%;\n    padding: 0 20px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 100%;\n    max-height: 100%;\n    padding: 44px;\n    margin: 0;\n    margin-top: auto;\n    '], ['\n    max-width: 100%;\n    max-height: 100%;\n    padding: 44px;\n    margin: 0;\n    margin-top: auto;\n    ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 12px;\n  '], ['\n    font-size: 12px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 28px;\n    line-height: 34px;\n  '], ['\n    font-size: 28px;\n    line-height: 34px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    max-width: 100%;\n  '], ['\n    width: 100%;\n    max-width: 100%;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['max-width: 100%; margin-bottom: 0px;'], ['max-width: 100%; margin-bottom: 0px;']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    gap: 40px;\n\n    margin:  0;\n    margin-bottom: 40px;\n  '], ['\n    flex-direction: column;\n    gap: 40px;\n\n    margin:  0;\n    margin-bottom: 40px;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n      width: 50%\n    '], ['\n      width: 50%\n    ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%\n    '], ['\n    width: 50%\n    ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['width: 100%;\n  max-width: 100%;'], ['width: 100%;\n  max-width: 100%;']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 40px;\n    '], ['\n    margin-top: 40px;\n    ']),
    _templateObject13 = (0, _taggedTemplateLiteral3.default)(['\n    margin-right: 0;\n    margin-top: 0;\n  '], ['\n    margin-right: 0;\n    margin-top: 0;\n  ']),
    _templateObject14 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject15 = (0, _taggedTemplateLiteral3.default)(['\n    width: calc(100% - 48px);\n    margin: 0 20px;\n  '], ['\n    width: calc(100% - 48px);\n    margin: 0 20px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Button = require('./Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _VehicleDetails = require('./VehicleDetails');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PromotionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__PromotionContainer',
  componentId: 'sc-5xzveh-0'
})(['max-width:1920px;min-height:800px;width:100%;background-color:#f9f9f9;padding:0 112px;box-sizing:border-box;', ';', ';'], _theme2.default.max.xxl(_templateObject), _theme2.default.max.large(_templateObject2));

var CardContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__CardContainer',
  componentId: 'sc-5xzveh-1'
})(['display:flex;flex-direction:column;justify-content:space-between;align-items:end;height:100%;min-height:800px;background-image:url(', ');background-size:cover;background-repeat:no-repeat;background-position:center;position:relative;'], function (_ref) {
  var backgroundImg = _ref.backgroundImg;
  return backgroundImg;
});

var BlurBox = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__BlurBox',
  componentId: 'sc-5xzveh-2'
})(['width:240px;height:240px;backdrop-filter:blur(40px);position:absolute;top:6rem;right:38rem;']);

var TextCard = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__TextCard',
  componentId: 'sc-5xzveh-3'
})(['display:flex;flex-direction:column;margin:auto 0;margin-right:60px;width:100%;padding:20px;max-width:630px;max-height:468px;padding:48px;backdrop-filter:blur(40px);box-sizing:border-box;', ''], _theme2.default.max.large(_templateObject3));

var Eyebrow = _styledComponents2.default.h3.withConfig({
  displayName: 'Promotion__Eyebrow',
  componentId: 'sc-5xzveh-4'
})(['color:#ffffff;font-family:\'Bentley-Light\';font-size:14px;letter-spacing:0.14px;margin:0;font-weight:300;text-transform:uppercase;', ''], _theme2.default.max.large(_templateObject4));

var CardTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'Promotion__CardTitle',
  componentId: 'sc-5xzveh-5'
})(['color:#ffffff;font-family:\'Bentley-Light\';font-size:44px;line-height:50px;font-weight:300;margin:16px 0;text-transform:uppercase;', ''], _theme2.default.max.large(_templateObject5));

var VideoCardTitle = (0, _styledComponents2.default)(CardTitle).withConfig({
  displayName: 'Promotion__VideoCardTitle',
  componentId: 'sc-5xzveh-6'
})(['width:\'100%\';max-width:\'560px\';margin-top:\'0\';', ''], _theme2.default.max.large(_templateObject6));

var CardParagraph = _styledComponents2.default.p.withConfig({
  displayName: 'Promotion__CardParagraph',
  componentId: 'sc-5xzveh-7'
})(['color:#ffffff;font-family:\'Bentley-Light\';font-size:18px;line-height:26px;margin-top:16px;letter-spacing:0.18px;font-weight:300;margin:0;margin-bottom:40px;', ''], _theme2.default.max.large(_templateObject7));

var VideoCardParagraph = (0, _styledComponents2.default)(CardParagraph).withConfig({
  displayName: 'Promotion__VideoCardParagraph',
  componentId: 'sc-5xzveh-8'
})(['width:\'100%\';max-width:\'560px\';margin-top:\'0\';', ''], _theme2.default.max.large(_templateObject6));

var VideoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__VideoContainer',
  componentId: 'sc-5xzveh-9'
})(['width:100%;height:100%;position:relative;background-image:url(', ');background-size:cover;background-repeat:no-repeat;background-position:center;'], function (_ref2) {
  var backgroundImg = _ref2.backgroundImg;
  return backgroundImg;
});
var BackgroundOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__BackgroundOverlay',
  componentId: 'sc-5xzveh-10'
})(['width:100%;height:100%;backdrop-filter:blur(40px);display:flex;flex-direction:column;justify-content:space-between;padding:20px;box-sizing:border-box;']);

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__Row',
  componentId: 'sc-5xzveh-11'
})(['display:flex;flex-direction:row;gap:100px;margin:60px;margin-top:24px;margin-bottom:0;', ''], _theme2.default.max.large(_templateObject8));

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__Column',
  componentId: 'sc-5xzveh-12'
})(['display:flex;flex-direction:column;position:relative;', ' ', ' ', ';'], function (_ref3) {
  var first = _ref3.first;
  return first && 'width: 45%;\n     ' + _theme2.default.max.xxl(_templateObject9) + '\n  ';
}, function (_ref4) {
  var second = _ref4.second;
  return second && '\n    width: 55%;\n    justify-content: center;\n    align-items: center;\n    max-width: 900px;\n    ' + _theme2.default.max.xxl(_templateObject10) + '\n    ';
}, _theme2.default.max.large(_templateObject11));

var DisclaimerText = _styledComponents2.default.p.withConfig({
  displayName: 'Promotion__DisclaimerText',
  componentId: 'sc-5xzveh-13'
})(['color:#ffffff;font-family:\'Bentley-Light\';font-size:14px;line-height:26px;letter-spacing:0.18px;font-weight:300;align-self:center;margin:0;margin-bottom:26px;text-align:center;', ''], _theme2.default.max.large(_templateObject12));

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'Promotion__Icon',
  componentId: 'sc-5xzveh-14'
})(['height:16px;margin-right:12px;']);

var CloseButton = _styledComponents2.default.img.withConfig({
  displayName: 'Promotion__CloseButton',
  componentId: 'sc-5xzveh-15'
})(['width:26px;height:26px;cursor:pointer;align-self:flex-end;margin-right:60px;margin-top:40px;', ''], _theme2.default.max.large(_templateObject13));

var VideoPlayIcon = _styledComponents2.default.img.withConfig({
  displayName: 'Promotion__VideoPlayIcon',
  componentId: 'sc-5xzveh-16'
})(['width:48px;height:48px;cursor:pointer;position:absolute;top:calc(50% - 24px);left:calc(50% - 24px);object-fit:cover;transition:all 0.3s;opacity:0.5;&:hover{opacity:1;width:64px;height:64px;top:calc(50% - 32px);left:calc(50% - 32px);}']);

var Video = _styledComponents2.default.video.withConfig({
  displayName: 'Promotion__Video',
  componentId: 'sc-5xzveh-17'
})(['width:100%;height:100%;']);

var buttonStyleOverride = function buttonStyleOverride() {
  return (0, _styledComponents.css)(['height:50px;font-size:13px;line-height:16px;text-transform:uppercase;width:fit-content;align-self:center;margin-top:40px;', ''], _theme2.default.max.large(_templateObject14));
};

var videoButtonStyleOverride = function videoButtonStyleOverride() {
  return (0, _styledComponents.css)(['', ' margin-bottom:38px;', ''], buttonStyleOverride(), _theme2.default.max.large(_templateObject15));
};


var PromotionCard = function PromotionCard(_ref5) {
  var handleClick = _ref5.handleClick,
      backgroundImg = _ref5.backgroundImg,
      translations = _ref5.translations,
      learnMoreIcon = _ref5.learnMoreIcon;

  var isMobile = (0, _useCheckIsMobileScreen2.default)('large');
  return _react2.default.createElement(
    CardContainer,
    { backgroundImg: backgroundImg },
    !isMobile && _react2.default.createElement(BlurBox, null),
    _react2.default.createElement(
      TextCard,
      null,
      _react2.default.createElement(
        Eyebrow,
        null,
        translations.promoMainEyebrow
      ),
      _react2.default.createElement(
        CardTitle,
        null,
        translations.promoMainTitle
      ),
      _react2.default.createElement(
        CardParagraph,
        null,
        translations.promoMainParagraph
      ),
      _react2.default.createElement(
        _Button2.default,
        {
          onClick: handleClick,
          variant: 'primary',
          styleOverride: buttonStyleOverride
        },
        _react2.default.createElement(Icon, { src: learnMoreIcon, alt: translations.promoLearnMore }),
        translations.promoLearnMore
      ),
      isMobile && _react2.default.createElement(
        DisclaimerText,
        {
          style: {
            marginBottom: '0',
            marginTop: '40px'
          }
        },
        translations.promoCardDisclaimer
      )
    ),
    !isMobile && _react2.default.createElement(
      DisclaimerText,
      null,
      translations.promoCardDisclaimer
    )
  );
};

var PromotionVideoCard = function PromotionVideoCard(_ref6) {
  var backgroundImg = _ref6.backgroundImg,
      handleEnquireClick = _ref6.handleEnquireClick,
      handleCloseClick = _ref6.handleCloseClick,
      translations = _ref6.translations,
      closeIcon = _ref6.closeIcon,
      playIcon = _ref6.playIcon,
      enquireIcon = _ref6.enquireIcon,
      promoVideo = _ref6.promoVideo;

  var isMobile = (0, _useCheckIsMobileScreen2.default)();
  var image = isMobile ? backgroundImg : backgroundImg;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      videoPlaying = _useState2[0],
      setVideoPlaying = _useState2[1];

  var videoRef = (0, _react.useRef)();

  var handlePlayVideo = function handlePlayVideo() {
    if (videoRef.current) {
      videoRef.current && videoRef.current.play();
      setVideoPlaying(true);
      videoRef.current.controls = true;
    }
  };

  var handleVideoPaused = function handleVideoPaused() {
    if (videoRef.current && !videoRef.current.seeking) {
      setVideoPlaying(false);
    }
  };

  return _react2.default.createElement(
    VideoContainer,
    { backgroundImg: backgroundImg },
    _react2.default.createElement(
      BackgroundOverlay,
      null,
      _react2.default.createElement(CloseButton, {
        src: closeIcon,
        alt: 'Close',
        'aria-label': 'Close',
        onClick: handleCloseClick
      }),
      _react2.default.createElement(
        Row,
        null,
        _react2.default.createElement(
          Column,
          { first: true },
          _react2.default.createElement(
            VideoCardTitle,
            null,
            translations.promoVideoTitle
          ),
          _react2.default.createElement(
            VideoCardParagraph,
            null,
            translations.promoVideoParagraph
          )
        ),
        _react2.default.createElement(
          Column,
          { second: true },
          _react2.default.createElement(Video, {
            ref: videoRef,
            onPause: handleVideoPaused,
            onPlay: handlePlayVideo,
            src: promoVideo,
            poster: image
          }),
          !videoPlaying && _react2.default.createElement(VideoPlayIcon, {
            src: playIcon,
            alt: 'Play',
            'aria-label': 'Play',
            onClick: handlePlayVideo
          })
        )
      ),
      _react2.default.createElement(
        _Button2.default,
        {
          variant: 'primary',
          onClick: handleEnquireClick,
          styleOverride: videoButtonStyleOverride
        },
        _react2.default.createElement(Icon, { src: enquireIcon, alt: 'Enquire' }),
        translations.promoVideoButton
      ),
      _react2.default.createElement(
        DisclaimerText,
        null,
        translations.promoCardDisclaimer
      )
    )
  );
};

var Promotion = function Promotion(_ref7) {
  var config = _ref7.config,
      enquire = _ref7.enquire;
  var _config$promotion = config.promotion,
      mainCardBackground = _config$promotion.mainCardBackground,
      videoBackgroundImage = _config$promotion.videoBackgroundImage,
      learnMoreIcon = _config$promotion.learnMoreIcon,
      closeIcon = _config$promotion.closeIcon,
      playIcon = _config$promotion.playIcon,
      enquireIcon = _config$promotion.enquireIcon,
      promoVideo = _config$promotion.promoVideo,
      translations = config.translations;

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      learnMore = _useState4[0],
      setLearnMore = _useState4[1];

  var handleLearnMoreClick = function handleLearnMoreClick() {
    setLearnMore(true);
  };

  var handleCloseClick = function handleCloseClick() {
    setLearnMore(false);
  };

  return _react2.default.createElement(
    PromotionContainer,
    null,
    learnMore ? _react2.default.createElement(PromotionVideoCard, {
      backgroundImg: videoBackgroundImage,
      handleEnquireClick: enquire,
      handleCloseClick: handleCloseClick,
      translations: translations,
      learnMoreIcon: learnMoreIcon,
      closeIcon: closeIcon,
      playIcon: playIcon,
      enquireIcon: enquireIcon,
      promoVideo: promoVideo
    }) : _react2.default.createElement(PromotionCard, {
      handleClick: handleLearnMoreClick,
      translations: translations,
      backgroundImg: mainCardBackground,
      learnMoreIcon: learnMoreIcon
    })
  );
};

exports.default = Promotion;