'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-areas: \'large large large\' \'small1 small2 small3\';        \n  '], ['\n    grid-template-areas: \'large large large\' \'small1 small2 small3\';        \n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n  bottom: auto;\n    top:0;\n    border-radius: 0px 0px 10px 0px;\n  '], ['\n  bottom: auto;\n    top:0;\n    border-radius: 0px 0px 10px 0px;\n  ']);

exports.default = ImgCollage;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__ImageContainer',
  componentId: 'sc-wpl2l-0'
})(['position:relative;height:100%;width:100%;max-width:1920px;height:fit-content(768px);display:grid;grid-template-columns:auto;grid-template-rows:auto;grid-column-gap:0px;grid-row-gap:0px;cursor:pointer;grid-template-areas:\'large large large small1\' \'large large large small2\' \'large large large small3\';', ';'], _theme2.default.max.medium(_templateObject));

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'ImgCollage__Image',
  componentId: 'sc-wpl2l-1'
})(['aspect-ratio:4 / 3;display:block;object-fit:cover;width:100%;height:100%;max-width:100%;', ''], function (_ref) {
  var gridArea = _ref.gridArea;
  return gridArea && 'grid-area: ' + gridArea + ';';
});
var MainImage = (0, _styledComponents2.default)(Image).withConfig({
  displayName: 'ImgCollage__MainImage',
  componentId: 'sc-wpl2l-2'
})(['width:1920px;']);

var IconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__IconContainer',
  componentId: 'sc-wpl2l-3'
})(['position:absolute;bottom:0;left:0;display:flex;justify-content:space-between;background:#335b4c;border-radius:0px 10px 0px 0px;padding:7px 14px;color:#fff;font-family:Bentley-Regular;font-size:14px;align-items:center;gap:10px;', ''], _theme2.default.max.large(_templateObject2));

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__ButtonContainer',
  componentId: 'sc-wpl2l-4'
})(['cursor:pointer;display:flex;align-items:center;justify-content:center;']);

var CountContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__CountContainer',
  componentId: 'sc-wpl2l-5'
})(['font-size:16px;letter-spacing:0;line-height:inherit;']);

var ImgButton = _styledComponents2.default.img.withConfig({
  displayName: 'ImgCollage__ImgButton',
  componentId: 'sc-wpl2l-6'
})(['height:14px;']);

var CameraButton = (0, _styledComponents2.default)(ImgButton).withConfig({
  displayName: 'ImgCollage__CameraButton',
  componentId: 'sc-wpl2l-7'
})(['margin-left:10px;']);

function ImgCollage(_ref2) {
  var images = _ref2.images,
      video = _ref2.video,
      onClick = _ref2.onClick,
      placeholder = _ref2.placeholder,
      videoOnClick = _ref2.videoOnClick,
      config = _ref2.config,
      cameraOnClick = _ref2.cameraOnClick,
      imageAltText = _ref2.imageAltText;

  var getImage = function getImage(position) {
    return images[images.length === 1 ? 0 : position] || placeholder;
  };

  var stopPropagation = function stopPropagation(func) {
    return function (event) {
      event.stopPropagation();
      func();
    };
  };

  return _react2.default.createElement(
    ImageContainer,
    { onClick: onClick },
    _react2.default.createElement(
      IconContainer,
      null,
      _react2.default.createElement(
        ButtonContainer,
        {
          role: 'button',
          onClick: stopPropagation(cameraOnClick),
          tabIndex: '0'
        },
        _react2.default.createElement(ImgButton, { src: config.iconCamera, alt: 'camera icon' })
      ),
      _react2.default.createElement(
        CountContainer,
        null,
        images.length
      ),
      video && _react2.default.createElement(
        ButtonContainer,
        {
          role: 'button',
          onClick: stopPropagation(videoOnClick),
          tabIndex: '0'
        },
        _react2.default.createElement(CameraButton, { src: config.iconVideo, alt: 'video icon' })
      )
    ),
    _react2.default.createElement(MainImage, { src: getImage(0), gridArea: 'large', alt: imageAltText }),
    _react2.default.createElement(Image, { src: getImage(1), gridArea: 'small1', alt: imageAltText }),
    _react2.default.createElement(Image, { src: getImage(2), gridArea: 'small2', alt: imageAltText }),
    _react2.default.createElement(Image, { src: getImage(3), gridArea: 'small3', alt: imageAltText })
  );
}

ImgCollage.defaultProps = {
  images: [],
  onClick: function onClick() {}
};