'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CheckBox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _CheckboxUnchecked = require('./JaguarIcons/Global/CheckboxUnchecked');

var _CheckboxUnchecked2 = _interopRequireDefault(_CheckboxUnchecked);

var _Tick = require('./JaguarIcons/Global/Tick');

var _Tick2 = _interopRequireDefault(_Tick);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'CheckBox__Container',
  componentId: 'sc-1lrlo4z-0'
})(['display:flex;cursor:pointer;border:1px solid #757575;', ''], function (_ref) {
  var checked = _ref.checked;
  return checked && '\n    border: 1px solid #fff;\n  ';
});

var Check = function Check(_ref2) {
  var checked = _ref2.checked,
      onColour = _ref2.onColour,
      checkboxDimension = _ref2.checkboxDimension,
      ariaLabelText = _ref2.ariaLabelText;
  return checked ? _react2.default.createElement(_Tick2.default, {
    background: onColour,
    colour: '#fff',
    width: (checkboxDimension || 1.5) + 'em',
    height: (checkboxDimension || 1.5) + 'em',
    fill: '#fff',
    ariaLabelText: ariaLabelText
  }) : _react2.default.createElement(_CheckboxUnchecked2.default, {
    width: (checkboxDimension || 1.5) + 'em',
    height: (checkboxDimension || 1.5) + 'em',
    ariaLabelText: ariaLabelText
  });
};
Check.defaultProps = {
  onColour: '#000000'
};
function CheckBox(_ref3) {
  var id = _ref3.id,
      onClick = _ref3.onClick,
      checked = _ref3.checked,
      onColour = _ref3.onColour,
      checkboxDimension = _ref3.checkboxDimension,
      ariaLabelText = _ref3.ariaLabelText;

  return _react2.default.createElement(
    Container,
    { onClick: onClick, id: id, checked: checked },
    _react2.default.createElement(Check, {
      checked: checked,
      onColour: onColour,
      checkboxDimension: checkboxDimension,
      ariaLabelText: ariaLabelText
    })
  );
}