'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)([' flex-wrap: wrap;'], [' flex-wrap: wrap;']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)([' width: 100%;'], [' width: 100%;']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../Global');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RadioButtonRow = _styledComponents2.default.div.withConfig({
  displayName: 'JLRKoreaSpecificConsent__RadioButtonRow',
  componentId: 'sc-nd6n98-0'
})(['display:flex;align-items:center;', ';'], _theme2.default.max.medium(_templateObject));

var RadioButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'JLRKoreaSpecificConsent__RadioButtonWrapper',
  componentId: 'sc-nd6n98-1'
})(['display:flex;align-items:center;margin-left:20px;']);

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'JLRKoreaSpecificConsent__Label',
  componentId: 'sc-nd6n98-2'
})(['font-size:16px;color:', ';margin-left:4px;'], function (_ref) {
  var error = _ref.error;
  return error ? '#9e1b32' : '#444444';
});

var RadioLabel = _styledComponents2.default.div.withConfig({
  displayName: 'JLRKoreaSpecificConsent__RadioLabel',
  componentId: 'sc-nd6n98-3'
})(['font-size:16px;color:', ';margin-left:4px;white-space:nowrap;'], function (_ref2) {
  var error = _ref2.error;
  return error ? '#9e1b32' : '#444444';
});

var Table = _styledComponents2.default.table.withConfig({
  displayName: 'JLRKoreaSpecificConsent__Table',
  componentId: 'sc-nd6n98-4'
})(['border-collapse:collapse;margin-top:10px;']);

var Td = _styledComponents2.default.td.withConfig({
  displayName: 'JLRKoreaSpecificConsent__Td',
  componentId: 'sc-nd6n98-5'
})(['border:1px solid #7e7e7e;padding:10px;font-family:', ';color:#444444;font-size:13px;', ''], function (_ref3) {
  var font = _ref3.font;
  return font || 'ProximaNova-Regular';
}, function (_ref4) {
  var width = _ref4.width;
  return width && 'width: ' + width + '%;';
});

var StyledParagraph = (0, _styledComponents2.default)(_Global.Paragraph).withConfig({
  displayName: 'JLRKoreaSpecificConsent__StyledParagraph',
  componentId: 'sc-nd6n98-6'
})(['font-size:14px;line-height:17px;', ';'], _theme2.default.max.medium(_templateObject2));

var RadioButtonLabel = _styledComponents2.default.label.withConfig({
  displayName: 'JLRKoreaSpecificConsent__RadioButtonLabel',
  componentId: 'sc-nd6n98-7'
})(['position:absolute;width:18px;height:18px;border-radius:50%;background:#fff;border:1px solid #ddd;']);
var RadioButton = _styledComponents2.default.input.withConfig({
  displayName: 'JLRKoreaSpecificConsent__RadioButton',
  componentId: 'sc-nd6n98-8'
})(['opacity:0;z-index:1;border-radius:50%;width:18px;height:18px;margin-right:10px;&:checked + ', '{background:#fff;border:1px solid #ddd;&::after{content:\'\';display:block;border-radius:50%;width:12px;height:12px;margin:3px;background:', ';}}'], RadioButtonLabel, function (_ref5) {
  var globalStyling = _ref5.globalStyling;
  return globalStyling ? globalStyling.colours.primaryBrandColour.value : '#dddddd';
});

var JLRKoreaSpecificConsent = function JLRKoreaSpecificConsent(_ref6) {
  var translations = _ref6.translations,
      onRadioSelect = _ref6.onRadioSelect,
      onRadioSelectMultiple = _ref6.onRadioSelectMultiple,
      dataCollectionConsent = _ref6.dataCollectionConsent,
      dataCollectionConsentError = _ref6.dataCollectionConsentError,
      dataCollectionConsentKey = _ref6.dataCollectionConsentKey,
      promotionConsent = _ref6.promotionConsent,
      promotionConsentError = _ref6.promotionConsentError,
      promotionConsentKey = _ref6.promotionConsentKey,
      thirdPartyConsent = _ref6.thirdPartyConsent,
      thirdPartyConsentError = _ref6.thirdPartyConsentError,
      thirdPartyConsentKey = _ref6.thirdPartyConsentKey,
      personalInfoHandling = _ref6.personalInfoHandling,
      personalInfoHandlingError = _ref6.personalInfoHandlingError,
      personalInfoHandlingKey = _ref6.personalInfoHandlingKey,
      consentToAll = _ref6.consentToAll,
      consentToAllKey = _ref6.consentToAllKey,
      globalStyling = _ref6.globalStyling;

  return _react2.default.createElement(
    'div',
    { style: { marginBottom: '20px' } },
    _react2.default.createElement(
      _Global.HeadingOne,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 16px;\n        font-weight: 600;\n        margin-bottom: 20px;';
        }
      },
      translations.personalInfoCollection
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 14px;\n        line-height: 17px;\n        margin-top: 20px;';
        }
      },
      translations.dearJlrKorea
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 14px;\n        line-height: 17px;\n        margin-top: 20px;';
        }
      },
      translations.privacyAct
    ),
    _react2.default.createElement(
      RadioButtonRow,
      null,
      _react2.default.createElement(
        StyledParagraph,
        { styleOverride: function styleOverride() {
            return 'margin-right: 40px;';
          } },
        translations.allAgree
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement(RadioButton, {
          globalStyling: globalStyling,
          checked: consentToAll === '1',
          onChange: function onChange(event) {
            return onRadioSelectMultiple(event);
          },
          type: 'radio',
          id: 'consentToAllYes',
          name: consentToAllKey,
          value: '1'
        }),
        _react2.default.createElement(RadioButtonLabel, null),
        _react2.default.createElement(
          RadioLabel,
          { htmlFor: 'consentToAllYes' },
          translations.agree
        )
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement(RadioButton, {
          globalStyling: globalStyling,
          checked: consentToAll === '0',
          onChange: function onChange(event) {
            return onRadioSelectMultiple(event);
          },
          type: 'radio',
          id: 'consentToAllNo',
          name: consentToAllKey,
          value: '0'
        }),
        _react2.default.createElement(RadioButtonLabel, null),
        _react2.default.createElement(
          RadioLabel,
          { htmlFor: 'marketingNo' },
          translations.doNotAgree
        )
      )
    ),
    _react2.default.createElement(
      RadioButtonRow,
      null,
      _react2.default.createElement(
        StyledParagraph,
        null,
        translations.dataCollectionConsent
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement(RadioButton, {
          globalStyling: globalStyling,
          checked: dataCollectionConsent === '1',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'dataCollectionYes',
          name: dataCollectionConsentKey,
          value: '1'
        }),
        _react2.default.createElement(RadioButtonLabel, null),
        _react2.default.createElement(
          RadioLabel,
          {
            error: dataCollectionConsentError,
            htmlFor: 'dataCollectionYes'
          },
          translations.agree
        )
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement(RadioButton, {
          globalStyling: globalStyling,
          checked: dataCollectionConsent === '0',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'dataCollectionNo',
          name: dataCollectionConsentKey,
          value: '0'
        }),
        _react2.default.createElement(RadioButtonLabel, null),
        _react2.default.createElement(
          RadioLabel,
          {
            error: dataCollectionConsentError,
            htmlFor: 'dataCollectionNo'
          },
          translations.doNotAgree
        )
      )
    ),
    dataCollectionConsentError && _react2.default.createElement(
      Label,
      { error: dataCollectionConsentError },
      translations.serviceWarning
    ),
    _react2.default.createElement(
      Table,
      null,
      _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          Td,
          { width: 30 },
          translations.collectionItem
        ),
        _react2.default.createElement(
          Td,
          null,
          translations.collectionItemText
        )
      ),
      _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          Td,
          { width: 30 },
          translations.purposeOfCollectionAndUse
        ),
        _react2.default.createElement(
          Td,
          null,
          translations.purposeOfCollectionText
        )
      ),
      _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          Td,
          { width: 30 },
          translations.retentionAndUsePeriod
        ),
        _react2.default.createElement(
          Td,
          null,
          translations.retentionAndUsePeriodText
        )
      )
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      null,
      translations.refuseConsent
    ),
    _react2.default.createElement(
      RadioButtonRow,
      null,
      _react2.default.createElement(
        StyledParagraph,
        null,
        translations.marketingConsent
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement(RadioButton, {
          globalStyling: globalStyling,
          checked: promotionConsent === '1',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'promotionConsentYes',
          name: promotionConsentKey,
          value: '1'
        }),
        _react2.default.createElement(RadioButtonLabel, null),
        _react2.default.createElement(
          RadioLabel,
          {
            error: promotionConsentError,
            htmlFor: 'promotionConsentYes'
          },
          translations.agree
        )
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement(RadioButton, {
          globalStyling: globalStyling,
          checked: promotionConsent === '0',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'promotionConsentNo',
          name: promotionConsentKey,
          value: '0'
        }),
        _react2.default.createElement(RadioButtonLabel, null),
        _react2.default.createElement(
          RadioLabel,
          {
            error: promotionConsentError,
            htmlFor: 'promotionConsentNo'
          },
          translations.doNotAgree
        )
      )
    ),
    promotionConsentError && _react2.default.createElement(
      Label,
      { error: promotionConsentError },
      translations.serviceWarning
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      null,
      translations.refuseConsent
    ),
    _react2.default.createElement(
      RadioButtonRow,
      null,
      _react2.default.createElement(
        StyledParagraph,
        null,
        translations.thirdPartyProvision
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement(RadioButton, {
          globalStyling: globalStyling,
          checked: thirdPartyConsent === '1',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'thirdPartyConsentYes',
          name: thirdPartyConsentKey,
          value: '1'
        }),
        _react2.default.createElement(RadioButtonLabel, null),
        _react2.default.createElement(
          RadioLabel,
          {
            error: thirdPartyConsentError,
            htmlFor: 'thirdPartyConsentYes'
          },
          translations.agree
        )
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement(RadioButton, {
          globalStyling: globalStyling,
          checked: thirdPartyConsent === '0',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'thirdPartyConsentNo',
          name: thirdPartyConsentKey,
          value: '0'
        }),
        _react2.default.createElement(RadioButtonLabel, null),
        _react2.default.createElement(
          RadioLabel,
          {
            error: thirdPartyConsentError,
            htmlFor: 'thirdPartyConsentNo'
          },
          translations.doNotAgree
        )
      )
    ),
    thirdPartyConsentError && _react2.default.createElement(
      Label,
      { error: thirdPartyConsentError },
      translations.serviceWarning
    ),
    _react2.default.createElement(
      Table,
      null,
      _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          Td,
          { width: 30 },
          translations.recipientOfData
        ),
        _react2.default.createElement(
          Td,
          null,
          translations.recipientOfDataText
        )
      ),
      _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          Td,
          { width: 30 },
          translations.purposeOfUse
        ),
        _react2.default.createElement(
          Td,
          null,
          translations.purposeOfUseText
        )
      ),
      _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          Td,
          { width: 30 },
          translations.personalInfoProvided
        ),
        _react2.default.createElement(
          Td,
          null,
          translations.personalInfoProvidedText
        )
      ),
      _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          Td,
          { width: 30 },
          translations.retentionAndUsePeriod
        ),
        _react2.default.createElement(
          Td,
          null,
          translations.retentionAndUsePeriodText
        )
      )
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      null,
      translations.refuseConsent
    ),
    _react2.default.createElement(
      RadioButtonRow,
      null,
      _react2.default.createElement(
        StyledParagraph,
        null,
        translations.personalInfoHandling
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement(RadioButton, {
          globalStyling: globalStyling,
          checked: personalInfoHandling === '1',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'personalInfoHandlingYes',
          name: personalInfoHandlingKey,
          value: '1'
        }),
        _react2.default.createElement(RadioButtonLabel, null),
        _react2.default.createElement(
          RadioLabel,
          {
            error: personalInfoHandlingError,
            htmlFor: 'personalInfoHandlingYes'
          },
          translations.agree
        )
      ),
      _react2.default.createElement(
        RadioButtonWrapper,
        null,
        _react2.default.createElement(RadioButton, {
          globalStyling: globalStyling,
          checked: personalInfoHandling === '0',
          onChange: function onChange(event) {
            return onRadioSelect(event);
          },
          type: 'radio',
          id: 'personalInfoHandlingNo',
          name: personalInfoHandlingKey,
          value: '0'
        }),
        _react2.default.createElement(RadioButtonLabel, null),
        _react2.default.createElement(
          RadioLabel,
          {
            error: personalInfoHandlingError,
            htmlFor: 'personalInfoHandlingNo'
          },
          translations.doNotAgree
        )
      )
    ),
    personalInfoHandlingError && _react2.default.createElement(
      Label,
      { error: personalInfoHandlingError },
      translations.serviceWarning
    ),
    _react2.default.createElement(
      Table,
      null,
      _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          Td,
          { width: 30 },
          translations.entrusteeOfPersonalInfo
        ),
        _react2.default.createElement(
          Td,
          { width: 30 },
          translations.contentAndScope
        ),
        _react2.default.createElement(
          Td,
          { width: 30 },
          translations.consignmentItem
        )
      ),
      _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          Td,
          null,
          translations.jlrEntrustee
        ),
        _react2.default.createElement(
          Td,
          null,
          translations.jlrScopeOfWork
        ),
        _react2.default.createElement(
          Td,
          { rowSpan: '2' },
          translations.consignmentItemText
        )
      ),
      _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          Td,
          null,
          translations.otherEntrustee
        ),
        _react2.default.createElement(
          Td,
          null,
          translations.otherScopeOfWork
        )
      )
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      null,
      translations.privacyPolicyReferal
    )
  );
};
exports.default = JLRKoreaSpecificConsent;