'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    --slide-size: 80%;\n  '], ['\n    --slide-size: 80%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    --slide-size: 55%;\n  '], ['\n    --slide-size: 55%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n     scale: 0.81;\n  '], ['\n     scale: 0.81;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    scale: initial;\n  '], ['\n    scale: initial;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n    margin-bottom: -40px;\n    min-height: 690px;\n  '], ['\n    padding: 0;\n    margin-bottom: -40px;\n    min-height: 690px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    min-height: 820px;\n  '], ['\n    min-height: 820px;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 50px 0 100px;\n  '], ['\n    padding: 50px 0 100px;\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 100px 0;\n  '], ['\n    padding: 100px 0;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 30px 20px 0;\n    min-height: 200px;\n  '], ['\n    padding: 30px 20px 0;\n    min-height: 200px;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    scale: 1.19;\n    min-height: 150px;\n  '], ['\n    scale: 1.19;\n    min-height: 150px;\n  ']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n    scale: initial;\n    min-height: 280px;\n  '], ['\n    scale: initial;\n    min-height: 280px;\n  ']),
    _templateObject13 = (0, _taggedTemplateLiteral3.default)(['\n    min-height: 120px;\n  '], ['\n    min-height: 120px;\n  ']),
    _templateObject14 = (0, _taggedTemplateLiteral3.default)(['\n    scale: 0.8\n  '], ['\n    scale: 0.8\n  ']);

var _emblaCarouselAutoplay = require('embla-carousel-autoplay');

var _emblaCarouselAutoplay2 = _interopRequireDefault(_emblaCarouselAutoplay);

var _emblaCarouselReact = require('embla-carousel-react');

var _emblaCarouselReact2 = _interopRequireDefault(_emblaCarouselReact);

var _framerMotion = require('framer-motion');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _Global = require('../../../components/Global');

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EmblaCarousel = _styledComponents2.default.div.withConfig({
  displayName: 'Accessories__EmblaCarousel',
  componentId: 'sc-7lnn6z-0'
})(['margin:0;--slide-height:100%;--slide-size:100%;', ' ', ''], _RollsRoyce2.default.min.small(_templateObject), _RollsRoyce2.default.min.medium(_templateObject2));

var EmblaContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Accessories__EmblaContainer',
  componentId: 'sc-7lnn6z-1'
})(['backface-visibility:hidden;display:flex;touch-action:pan-y pinch-zoom;', ' ', ''], _RollsRoyce2.default.min.large(_templateObject3), _RollsRoyce2.default.min.xxl(_templateObject4));

var EmblaSlide = _styledComponents2.default.div.withConfig({
  displayName: 'Accessories__EmblaSlide',
  componentId: 'sc-7lnn6z-2'
})(['flex:0 0 var(--slide-size);min-width:0;']);

var EmblaViewPort = _styledComponents2.default.div.withConfig({
  displayName: 'Accessories__EmblaViewPort',
  componentId: 'sc-7lnn6z-3'
})(['box-sizing:border-box;overflow:hidden;padding:0 20px;width:100%;min-height:530px;@media screen and (min-width:500px){min-height:580px;}', ' ', ' ', ' @media screen and (min-width:2500px){min-height:1000px;}'], _RollsRoyce2.default.min.small(_templateObject5), _RollsRoyce2.default.min.large(_templateObject6), _RollsRoyce2.default.min.xxl(_templateObject7));

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Accessories__Wrapper',
  componentId: 'sc-7lnn6z-4'
})(['display:flex;flex-direction:column;background-color:#f2f2f2;padding:50px 0;transition:height 0.3s ease;', ' ', ''], _RollsRoyce2.default.min.large(_templateObject8), _RollsRoyce2.default.min.xxl(_templateObject9));

var Item = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'Accessories__Item',
  componentId: 'sc-7lnn6z-5'
})(['aspect-ratio:16 / 9;cursor:ew-resize;margin:0 auto;width:100%;height:100%;']);

var ImageItem = _styledComponents2.default.img.withConfig({
  displayName: 'Accessories__ImageItem',
  componentId: 'sc-7lnn6z-6'
})(['aspect-ratio:16 / 9;', ';object-fit:cover;width:100%;'], function (_ref) {
  var centerSlide = _ref.centerSlide;
  return centerSlide && 'cursor: pointer';
});

var InfoContainer = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'Accessories__InfoContainer',
  componentId: 'sc-7lnn6z-7'
})(['box-sizing:border-box;cursor:auto;display:flex;flex-direction:column;font-size:16px;height:auto;letter-spacing:2px;line-height:auto;min-height:280px;padding:18px 20px 0;text-align:center;width:100%;', ' ', ' ', ''], _RollsRoyce2.default.min.medium(_templateObject10), _RollsRoyce2.default.min.large(_templateObject11), _RollsRoyce2.default.min.xxl(_templateObject12));

var AccessoriesParagraph = (0, _styledComponents2.default)(_Global.Paragraph).withConfig({
  displayName: 'Accessories__AccessoriesParagraph',
  componentId: 'sc-7lnn6z-8'
})(['font-family:\'RivieraNights-Light\',\'NotoSansKR-Regular\',Helvetica,Arial;cursor:pointer;font-size:14px;font-style:normal;font-weight:normal;letter-spacing:0.5px;line-height:21px;margin:0 auto;max-width:640px;', ''], _RollsRoyce2.default.min.large(_templateObject13));

var DotContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Accessories__DotContainer',
  componentId: 'sc-7lnn6z-9'
})(['background-color:#f2f2f2;display:flex;flex-direction:row;height:40px;justify-content:center;width:100%;', ''], _RollsRoyce2.default.max.medium(_templateObject14));

var Dot = _styledComponents2.default.button.withConfig({
  displayName: 'Accessories__Dot',
  componentId: 'sc-7lnn6z-10'
})(['background-color:#f2f2f2;border:none;cursor:pointer;height:40px;opacity:', ';position:relative;width:30px;'], function (_ref2) {
  var active = _ref2.active;
  return active ? 1 : 0.6;
});

var InnerDot = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'Accessories__InnerDot',
  componentId: 'sc-7lnn6z-11'
})(['background-color:#3b3b3b;border-radius:50%;height:7px;left:calc(50% - 4px);position:absolute;width:7px;']);

var TRANSFORM_OPTIONS = {
  duration: 1,
  ease: [0.2, 0.5, 0.9, 1]
};

var TRANSFORM_TEXT_OPTIONS = {
  duration: 0.9,
  delay: 0.2,
  ease: [0.2, 0.4, 0.8, 1]
};

var SvgCircleComponent = function SvgCircleComponent(props) {
  return _react2.default.createElement(
    'svg',
    (0, _extends3.default)({ xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 100 100' }, props),
    _react2.default.createElement(_framerMotion.motion.path, {
      initial: { pathLength: 0 },
      animate: { pathLength: 1 },
      transition: {
        duration: 9,
        ease: 'easeOut'
      },
      strokeWidth: 4,
      d: ' M 20, 45 a 20,20 0 1,1 45,0 a 20,20 0 1,1 -45,0 '
    })
  );
};

var StyledSvgCircle = (0, _styledComponents2.default)(SvgCircleComponent).withConfig({
  displayName: 'Accessories__StyledSvgCircle',
  componentId: 'sc-7lnn6z-12'
})(['position:absolute;top:10px;left:2px;height:30px;width:30px;']);

var Dots = function Dots(_ref3) {
  var activeIndex = _ref3.activeIndex,
      list = _ref3.list,
      onDotClick = _ref3.onDotClick;

  return _react2.default.createElement(
    DotContainer,
    null,
    list.map(function (_, index) {
      return _react2.default.createElement(
        Dot,
        {
          id: index,
          key: index,
          onClick: onDotClick,
          active: activeIndex === index
        },
        _react2.default.createElement(InnerDot, {
          id: index,
          key: index,
          animate: {
            scale: activeIndex === index ? 0.7 : 1,
            opacity: activeIndex === index ? 1 : 1
          },
          onClick: onDotClick
        }),
        activeIndex === index && _react2.default.createElement(StyledSvgCircle, { stroke: '#151515', fillOpacity: 0 })
      );
    })
  );
};

var AccessoriesInfo = function AccessoriesInfo(_ref4) {
  var index = _ref4.index,
      list = _ref4.list,
      item = _ref4.item,
      convertedLanguageCode = _ref4.convertedLanguageCode,
      getNavigationLink = _ref4.getNavigationLink,
      config = _ref4.config;

  var _useNavigation = (0, _navigation2.default)(),
      navigateTo = _useNavigation.navigateTo;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      InfoContainer,
      {
        key: index,
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: TRANSFORM_TEXT_OPTIONS
      },
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n            font-family: \'RivieraNights-Regular\', \'NotoSansKR-Regular\', Helvetica, Arial;\n            cursor: pointer;\n            font-size: 16px;\n            line-height:21px;\n            letter-spacing: 2px;\n            font-style: normal;\n            font-weight: normal;\n            height: auto;\n            padding-bottom: 18px;\n            text-transform: uppercase;\n          ';
          },
          onClick: function onClick() {
            var link = getNavigationLink(config, item);

            if (link) {
              navigateTo(link.replace('en_GB', convertedLanguageCode));
            }
          }
        },
        list[index].title
      ),
      _react2.default.createElement(
        AccessoriesParagraph,
        {
          onClick: function onClick() {
            var link = getNavigationLink(config, item);

            if (link) {
              navigateTo(link.replace('en_GB', convertedLanguageCode));
            }
          }
        },
        list[index].description
      )
    )
  );
};

var Accessories = function Accessories(_ref5) {
  var accessories = _ref5.accessories,
      language = _ref5.language,
      config = _ref5.config;

  var getNavigationLink = function getNavigationLink(config, item) {
    var chineseAccessoriesLinkOverride = config.chineseAccessoriesLinkOverride;

    if (language === 'zh_cn' && chineseAccessoriesLinkOverride) {
      return chineseAccessoriesLinkOverride;
    }
    return item.externalLink;
  };

  var convertLanguageCode = function convertLanguageCode(code) {
    if (code.length !== 5 || code === 'zh_cn') {
      return 'en_GB';
    }
    return code.slice(0, 2) + '_' + code.slice(3).toUpperCase();
  };

  var convertedLanguageCode = convertLanguageCode(language);

  var _useNavigation2 = (0, _navigation2.default)(),
      navigateTo = _useNavigation2.navigateTo;

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      currentSlideNumber = _useState2[0],
      setCurrentSlideNumber = _useState2[1];

  var _useEmblaCarousel = (0, _emblaCarouselReact2.default)({
    loop: true,
    duration: 40
  }, [(0, _emblaCarouselAutoplay2.default)({ playOnInit: true, delay: 8000, stopOnInteraction: false })]),
      _useEmblaCarousel2 = (0, _slicedToArray3.default)(_useEmblaCarousel, 2),
      emblaRef = _useEmblaCarousel2[0],
      emblaApi = _useEmblaCarousel2[1];

  var autoplay = emblaApi && emblaApi.plugins().autoplay;
  var setCurrentSlide = (0, _react.useCallback)(function (emblaApi) {
    setCurrentSlideNumber(emblaApi.selectedScrollSnap());
  }, []);

  var setSelectedSlide = (0, _react.useCallback)(function (emblaApi) {
    setCurrentSlideNumber(emblaApi.selectedScrollSnap());
  }, []);

  (0, _react.useEffect)(function () {
    if (emblaApi) emblaApi.on('slidesInView', setCurrentSlide);
    if (emblaApi) emblaApi.on('select', setSelectedSlide);
  }, [emblaApi]);

  var onDotClick = function onDotClick(e) {
    var index = e.target.id;
    if (emblaApi) emblaApi.scrollTo(index);
    if (autoplay) autoplay.reset();
  };

  var onSlideClick = function onSlideClick(index) {
    if (emblaApi) emblaApi.scrollTo(index);
    if (autoplay) autoplay.reset();
  };

  if (!accessories || accessories.length === 0) {
    return null;
  }

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      EmblaCarousel,
      null,
      _react2.default.createElement(
        EmblaViewPort,
        { ref: emblaRef },
        _react2.default.createElement(
          EmblaContainer,
          null,
          accessories.map(function (item, index) {
            return _react2.default.createElement(
              EmblaSlide,
              { key: index },
              _react2.default.createElement(
                Item,
                {
                  key: index,
                  animate: {
                    scale: currentSlideNumber === index ? 1 : 0.8
                  },
                  transition: TRANSFORM_OPTIONS,
                  onClick: function onClick() {
                    onSlideClick(index);
                  }
                },
                _react2.default.createElement(ImageItem, {
                  src: item.imageUrl,
                  alt: item.title + ' image',
                  onClick: function onClick() {
                    var link = getNavigationLink(config, item);

                    if (link && currentSlideNumber === index) {
                      navigateTo(link.replace('en_GB', convertedLanguageCode));
                    }
                  },
                  centerSlide: currentSlideNumber === index
                }),
                currentSlideNumber === index && _react2.default.createElement(
                  _react.Fragment,
                  null,
                  _react2.default.createElement(AccessoriesInfo, {
                    index: currentSlideNumber,
                    list: accessories,
                    item: item,
                    convertedLanguageCode: convertedLanguageCode,
                    getNavigationLink: getNavigationLink,
                    config: config
                  })
                )
              )
            );
          })
        )
      ),
      _react2.default.createElement(Dots, {
        activeIndex: currentSlideNumber,
        list: accessories,
        onDotClick: onDotClick
      })
    )
  );
};

exports.default = Accessories;