'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column\n  '], ['\n    flex-direction: column\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 50% 50%;\n  '], ['\n    grid-template-columns: 50% 50%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    flex:1;\n'], ['\n    flex:1;\n']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n  flex-direction: column;\n  gap: 0;\n'], ['\n  flex-direction: column;\n  gap: 0;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('.');

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _dates = require('../../../shared/localisation/dates');

var _numbers = require('../../../shared/localisation/numbers');

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicleTypes = require('../../../types/AstonMartin/vehicleTypes');

var _CompareButton = require('../CompareButton');

var _CompareButton2 = _interopRequireDefault(_CompareButton);

var _Form = require('../Form');

var _ImgCollage = require('../ImgCollage');

var _ImgCollage2 = _interopRequireDefault(_ImgCollage);

var _ShortlistButton = require('../ShortlistButton');

var _ShortlistButton2 = _interopRequireDefault(_ShortlistButton);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__Container',
  componentId: 'sc-15ufgh-0'
})(['width:100%;margin-bottom:20px;display:flex;box-sizing:border-box;background-color:#ffffff;', ';'], _theme2.default.max.xxl(_templateObject));
var UnstyledLink = _styledComponents2.default.a.withConfig({
  displayName: 'VehicleItem__UnstyledLink',
  componentId: 'sc-15ufgh-1'
})(['text-decoration:none;']);
var Super = _styledComponents2.default.sup.withConfig({
  displayName: 'VehicleItem__Super',
  componentId: 'sc-15ufgh-2'
})(['font-size:10.5px;font-family:\'AstonMartinSans-Regular\';color:#7a7a7a;']);
var ImageSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__ImageSection',
  componentId: 'sc-15ufgh-3'
})(['width:50%;', ';'], _theme2.default.max.xxl(_templateObject2));

var VehicleInfoSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleInfoSection',
  componentId: 'sc-15ufgh-4'
})(['display:flex;flex-direction:column;flex:1;padding:20px;']);

var VehicleSpecs = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleSpecs',
  componentId: 'sc-15ufgh-5'
})(['display:flex;flex-wrap:wrap;']);

var BaseVehicleDetailRowStyle = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__BaseVehicleDetailRowStyle',
  componentId: 'sc-15ufgh-6'
})(['width:100%;border-top:solid 0.5px #707070;padding:15px 0;font-family:AstonMartinSans-Light;font-size:14px;text-transform:none;&:last-child{border-bottom:solid 0.5px #707070;}', ''], _theme2.default.max.medium(_templateObject3));

var VehicleDetailRowStyle = (0, _styledComponents2.default)(BaseVehicleDetailRowStyle).withConfig({
  displayName: 'VehicleItem__VehicleDetailRowStyle',
  componentId: 'sc-15ufgh-7'
})(['display:grid;grid-template-columns:40% 40% 20%;']);

var VehicleDetailRowFullStyle = (0, _styledComponents2.default)(BaseVehicleDetailRowStyle).withConfig({
  displayName: 'VehicleItem__VehicleDetailRowFullStyle',
  componentId: 'sc-15ufgh-8'
})(['display:flex;']);

var LineBreak = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__LineBreak',
  componentId: 'sc-15ufgh-9'
})(['width:100%;border-bottom:solid 0.5px #707070;margin:20px 0;']);

var VehicleDetailItem = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleDetailItem',
  componentId: 'sc-15ufgh-10'
})(['display:flex;', ';'], _theme2.default.max.large(_templateObject4));

var PriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__PriceContainer',
  componentId: 'sc-15ufgh-11'
})(['width:100%;display:flex;justify-content:space-between;flex:1;align-items:center;align-content:center;padding-top:20px;padding-bottom:12px;text-transform:capitalize;']);

var PrimaryPrice = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__PrimaryPrice',
  componentId: 'sc-15ufgh-12'
})(['font-size:18px;font-family:AstonMartinFlare-XBold;display:flex;align-items:baseline;']);

var PriceCol = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__PriceCol',
  componentId: 'sc-15ufgh-13'
})(['display:flex;flex-direction:column;align-items:flex-start;']);

var SecondaryPrice = (0, _styledComponents2.default)(PrimaryPrice).withConfig({
  displayName: 'VehicleItem__SecondaryPrice',
  componentId: 'sc-15ufgh-14'
})(['font-size:16px;']);

var SubPriceLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__SubPriceLabel',
  componentId: 'sc-15ufgh-15'
})(['font-size:10.5px;font-family:AstonMartinSans-Regular;color:#7a7a7a;padding:5px;']);

var SubPriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__SubPriceContainer',
  componentId: 'sc-15ufgh-16'
})(['display:flex;width:100%;gap:20px;text-transform:capitalize;', ';'], _theme2.default.max.large(_templateObject5));

var CompareShortlistContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__CompareShortlistContainer',
  componentId: 'sc-15ufgh-17'
})(['display:flex;gap:10px;flex-direction:flex-end;']);

var scrollToDisclaimer = function scrollToDisclaimer() {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
};

var VehicleDetailRow = function VehicleDetailRow(_ref) {
  var fullWidth = _ref.fullWidth,
      data = _ref.data;
  return fullWidth ? _react2.default.createElement(
    VehicleDetailRowFullStyle,
    null,
    data.map(function (item, index) {
      return _react2.default.createElement(
        VehicleDetailItem,
        { key: index },
        item || '-'
      );
    })
  ) : _react2.default.createElement(
    VehicleDetailRowStyle,
    null,
    data.map(function (item, index) {
      return _react2.default.createElement(
        VehicleDetailItem,
        { key: index },
        item || '-'
      );
    })
  );
};

var VehicleItem = function VehicleItem(_ref2) {
  var vehicle = _ref2.vehicle,
      onMoreInfoClick = _ref2.onMoreInfoClick,
      translations = _ref2.translations,
      locale = _ref2.locale,
      config = _ref2.config;
  var currency = vehicle.price.currency;

  var mobile = (0, _useCheckIsMobileScreen2.default)();

  var financeCellTextNoDigits = function financeCellTextNoDigits(priceEntry, curCurrency) {
    return priceEntry > 0 && (0, _numbers.localiseCurrency)(priceEntry, locale, curCurrency, 0);
  };

  var registrationDate = vehicle.registrationDate ? (0, _dates.localeFormattedDate)(vehicle.registrationDate, locale, {
    month: '2-digit',
    year: 'numeric'
  }).split('/').join('.') : '';

  var _useMemo = (0, _react.useMemo)(function () {
    return [financeCellTextNoDigits(Number(vehicle.price.value || 0), currency), !!vehicle.priceExcludesVat ? financeCellTextNoDigits(Number(vehicle.priceExcludesVat || 0), currency) : undefined, !!vehicle.priceSpecial ? financeCellTextNoDigits(Number(vehicle.priceSpecial || 0), currency) : undefined];
  }, [vehicle, currency, financeCellTextNoDigits]),
      _useMemo2 = (0, _slicedToArray3.default)(_useMemo, 3),
      localisedRetailPrice = _useMemo2[0],
      priceSubjectToVat = _useMemo2[1],
      priceIncludingVat = _useMemo2[2];

  var priceConverted = !!vehicle.price.originalValue && vehicle.price.value !== vehicle.price.originalValue;

  var gotToVDP = function gotToVDP() {
    return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
  };

  return _react2.default.createElement(
    Container,
    { 'data-cy': 'search-result-vehicle-item', key: vehicle.id },
    _react2.default.createElement(
      ImageSection,
      null,
      _react2.default.createElement(_ImgCollage2.default, {
        onClick: gotToVDP,
        cameraOnClick: gotToVDP,
        videoOnClick: gotToVDP,
        images: vehicle.images,
        config: config,
        video: vehicle.video
      })
    ),
    _react2.default.createElement(
      VehicleInfoSection,
      null,
      _react2.default.createElement(_.VehicleTitle, {
        vehicle: vehicle,
        config: config,
        onClick: function onClick() {
          return onMoreInfoClick('VDP', vehicle);
        }
      }),
      _react2.default.createElement(
        VehicleSpecs,
        null,
        _react2.default.createElement(VehicleDetailRow, {
          data: vehicle.seatColour && vehicle.interior && vehicle.seatColour !== vehicle.interior ? [(0, _translateFromTemplate.translateFromTemplate)('coloursSpecificationTwoInteriors', {
            EXTERIOR: vehicle.exterior,
            INTERIOR: vehicle.seatColour,
            INTERIOR2: vehicle.interior
          }, translations)] : [(0, _translateFromTemplate.translateFromTemplate)('coloursSpecification', {
            EXTERIOR: vehicle.exterior,
            INTERIOR: vehicle.interior
          }, translations)],
          fullWidth: true
        }),
        _react2.default.createElement(
          'div',
          {
            style: {
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap'
            }
          },
          mobile ? _react2.default.createElement(
            _react2.default.Fragment,
            null,
            _react2.default.createElement(VehicleDetailRow, {
              data: [registrationDate, vehicle.powerOutput]
            }),
            _react2.default.createElement(VehicleDetailRow, {
              data: [vehicle.odometer.display, vehicle.engineCapacity + ' cc']
            }),
            _react2.default.createElement(VehicleDetailRow, {
              data: [vehicle.transmission, vehicle.handDrive]
            })
          ) : _react2.default.createElement(
            _react2.default.Fragment,
            null,
            _react2.default.createElement(VehicleDetailRow, {
              data: [registrationDate, vehicle.powerOutput, vehicle.odometer.display]
            }),
            _react2.default.createElement(VehicleDetailRow, {
              data: [vehicle.engineCapacity + ' cc', vehicle.transmission, vehicle.handDrive]
            })
          )
        ),
        _react2.default.createElement(
          PriceContainer,
          null,
          _react2.default.createElement(
            PriceCol,
            null,
            _react2.default.createElement(
              PrimaryPrice,
              null,
              localisedRetailPrice || translations.noRetailPrice,
              priceConverted && _react2.default.createElement(
                UnstyledLink,
                {
                  href: '#',
                  onClick: function onClick(e) {
                    e.preventDefault();
                    scrollToDisclaimer();
                  }
                },
                _react2.default.createElement(
                  Super,
                  null,
                  translations.priceSuper
                )
              ),
              _react2.default.createElement(
                SubPriceLabel,
                null,
                priceConverted ? translations.convertedPrice : translations.retailPrice
              )
            ),
            vehicle.sectionSalesTaxDisclaimer === 1 && _react2.default.createElement(
              SubPriceLabel,
              { style: { padding: 0 } },
              translations.differentialTaxation
            )
          ),
          _react2.default.createElement(
            CompareShortlistContainer,
            null,
            _react2.default.createElement(_ShortlistButton2.default, {
              vehicle: vehicle,
              iconOff: config.iconShortlistOff,
              iconOn: config.iconShortlistOn
            }),
            !mobile && _react2.default.createElement(_CompareButton2.default, {
              vehicle: vehicle,
              iconOff: config.iconCompareOff,
              iconOn: config.iconCompareOn
            })
          )
        ),
        _react2.default.createElement(
          SubPriceContainer,
          null,
          priceSubjectToVat && _react2.default.createElement(
            SecondaryPrice,
            null,
            priceSubjectToVat,
            _react2.default.createElement(
              SubPriceLabel,
              null,
              translations.subjectToVat
            )
          ),
          priceIncludingVat && _react2.default.createElement(
            SecondaryPrice,
            null,
            priceIncludingVat,
            _react2.default.createElement(
              SubPriceLabel,
              null,
              translations.includingVat
            )
          )
        )
      ),
      _react2.default.createElement(
        'div',
        {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }
        },
        _react2.default.createElement(_Form.PrimaryButton, {
          'data-cy': 'confirmation-button',
          onClick: function onClick() {
            return onMoreInfoClick('VDP', vehicle);
          },
          styleOverride: function styleOverride() {
            return 'margin: 0; padding: 0';
          },
          text: translations.showDetails
        }),
        _react2.default.createElement(_.PhoneLink, { iconPhoneUrl: config.iconPhone, vehicle: vehicle })
      ),
      _react2.default.createElement(_.DistanceFromRetailer, { vehicle: vehicle, translations: translations }),
      mobile && _react2.default.createElement(LineBreak, null),
      _react2.default.createElement(_.Emissions, {
        emissionsData: vehicle.emissions,
        consumptionsData: vehicle.consumption,
        efficiency: vehicle.energyEfficiencyRating,
        translations: translations,
        energyEfficiencyRatingMedia: config.energyEfficiencyRatingMedia
      })
    )
  );
};

exports.default = VehicleItem;