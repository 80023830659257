'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GenesisInput = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _GenesisInput = require('../../../components/Genesis/GenesisInput');

var _Titles = require('../../../components/Genesis/Titles');

var _location = require('../../../shared/location');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'DealerSearch__Container',
  componentId: 'sc-s6apnj-0'
})(['display:flex;flex-direction:column;align-items:center;background-color:#111;color:#fff;padding:0 30px 120px 30px;box-sizing:border-box;']);

var DealerSearch = function DealerSearch(_ref) {
  var config = _ref.config,
      navigateIfInternal = _ref.navigateIfInternal,
      dispatch = _ref.dispatch;
  var t = config.translations;

  var onClick = function onClick(_ref2, formattedLocation) {
    var lat = _ref2.lat,
        lng = _ref2.lng;

    dispatch(_location.actions.setPreferredLocation({ lat: lat, lng: lng, formattedLocation: formattedLocation }));
    navigateIfInternal('/find-a-dealer');
  };
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      _Titles.Header,
      null,
      t.title
    ),
    _react2.default.createElement(
      _Titles.Subtitle,
      null,
      t.subtitle
    ),
    _react2.default.createElement(_GenesisInput.GenesisInput, {
      onClick: onClick,
      ignorePreferredLocation: true,
      placeholder: t.searchPlaceholder,
      buttonLabel: t.searchButtonLabel
    })
  );
};

exports.default = DealerSearch;
exports.GenesisInput = _GenesisInput.GenesisInput;