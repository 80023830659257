'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  saveEmissionInfo: 'SAVE_EMISSION_INFO',
  updateEmissionInfo: 'UPDATE_EMISSION_INFO'
};

var actions = exports.actions = {
  saveEmissionInfo: function saveEmissionInfo(payload) {
    return {
      type: constants.saveEmissionInfo,
      payload: payload
    };
  },
  updateEmissionInfo: function updateEmissionInfo(payload) {
    return {
      type: constants.updateEmissionInfo,
      payload: payload
    };
  }
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments[1];
  var payload = action.payload,
      type = action.type;

  switch (type) {
    case constants.saveEmissionInfo:
    case constants.updateEmissionInfo:
      return (0, _extends3.default)({}, state, payload);
    default:
      return state;
  }
}