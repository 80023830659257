'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateMaxDistanceUrl = exports.generateLocationUrl = exports.generateRangeUrl = exports.rangePayloads = exports.generateCheckboxFilterUrl = exports.engineSizeValueDown = undefined;

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var engineSizeValueUp = function engineSizeValueUp(engineSize) {
  return engineSize.replace(/\D/g, '') + '00';
};
var engineSizeValueDown = exports.engineSizeValueDown = function engineSizeValueDown(engineSize, option) {
  return option ? engineSize.toString()[0] + ',' + engineSize.toString()[1] + ' L' : engineSize.toString()[0] + '.' + engineSize.toString()[1] + 'L';
};

var generateCheckboxFilterUrl = exports.generateCheckboxFilterUrl = function generateCheckboxFilterUrl(selectedCheckboxFilters) {
  var keys = (0, _keys2.default)(selectedCheckboxFilters);
  return keys.map(function (key) {
    return selectedCheckboxFilters[key].map(function (filter) {
      return key + '=' + (key === 'engineSizes' ? engineSizeValueUp(filter) : filter) + '&';
    }).join('');
  }).join('');
};

var rangePayloads = exports.rangePayloads = {
  mileageRange: ['MinMileage', 'MaxMileage'],
  yearRange: ['MinYear', 'MaxYear'],
  priceRange: ['MinPrice', 'MaxPrice'],
  monthlyPaymentRange: ['MinMonthlyPayment', 'MaxMonthlyPayment']
};

var generateRangeUrl = exports.generateRangeUrl = function generateRangeUrl(rangeFilters) {
  var keys = (0, _keys2.default)(rangeFilters);
  return keys.filter(function (key) {
    return rangeFilters[key].active;
  }).map(function (key) {
    return rangePayloads[key].map(function (filterName, index) {
      return filterName + '=' + (!index ? rangeFilters[key].min : rangeFilters[key].max) + '&';
    }).join('');
  }).join('');
};

var generateLocationUrl = exports.generateLocationUrl = function generateLocationUrl(location) {
  var lat = location.lat,
      long = location.long;

  return lat && long ? 'Latitude=' + lat + '&Longitude=' + long + '&' : '';
};

var generateMaxDistanceUrl = exports.generateMaxDistanceUrl = function generateMaxDistanceUrl(maxDistance) {
  return maxDistance && maxDistance !== 'all' ? 'MaxDistance=' + maxDistance + '&' : '';
};