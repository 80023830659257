'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 2px 0;\n  '], ['\n    padding: 2px 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SpecItems__Container',
  componentId: 'sc-1qd96sv-0'
})(['align-items:center;color:#151515;border-top:1px solid rgba(59,59,59,0.4);display:flex;flex-wrap:wrap;padding:5px 0;@media (min-width:1679px){align-items:flex-start;box-sizing:border-box;flex-wrap:nowrap;min-height:48px;padding:15px 0;}']);

var SpecItem = _styledComponents2.default.div.withConfig({
  displayName: 'SpecItems__SpecItem',
  componentId: 'sc-1qd96sv-1'
})(['display:flex;font-family:\'RivieraNights-Regular\';font-size:12px;flex-direction:row;line-height:28px;padding:2px 12px;width:100%;letter-spacing:0.5px;', ';@media (min-width:1679px){border-right:1px solid rgba(59,59,59,0.4);', ';padding:2px 20px;&:first-child{padding:2px 20px 2px 0;}&:last-child{border-right:none;max-width:40%;}line-height:initial;width:auto;height:100%;}'], _RollsRoyce2.default.min.extraLarge(_templateObject), function (_ref) {
  var twoColumns = _ref.twoColumns;
  return twoColumns ? 'max-width: 50%' : '';
});

var BaseDiv = _styledComponents2.default.div.withConfig({
  displayName: 'SpecItems__BaseDiv',
  componentId: 'sc-1qd96sv-2'
})(['font-size:12px;font-weight:400;line-height:22px;letter-spacing:0.5px;text-align:left;']);
var Label = (0, _styledComponents2.default)(BaseDiv).withConfig({
  displayName: 'SpecItems__Label',
  componentId: 'sc-1qd96sv-3'
})(['margin-right:8px;']);
var Value = (0, _styledComponents2.default)(BaseDiv).withConfig({
  displayName: 'SpecItems__Value',
  componentId: 'sc-1qd96sv-4'
})(['']);

var SpecItems = function SpecItems(_ref2) {
  var specItems = _ref2.specItems,
      twoColumns = _ref2.twoColumns;

  return _react2.default.createElement(
    Container,
    null,
    specItems.map(function (item, index) {
      return item.content !== 0 && item.content && _react2.default.createElement(
        SpecItem,
        { key: index, twoColumns: twoColumns },
        _react2.default.createElement(
          Label,
          null,
          item.label,
          ':'
        ),
        _react2.default.createElement(
          Value,
          null,
          item.content
        )
      );
    })
  );
};

exports.default = SpecItems;