'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 16px 0px;\n    width:95%;\n  '], ['\n    padding: 16px 0px;\n    width:95%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 28px;\n  '], ['\n    font-size: 28px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _TechnicalSpecsTypes = require('../../../types/Lamborghini/TechnicalSpecsTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TechnicalSpecsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TechnicalSpecs__TechnicalSpecsContainer',
  componentId: 'sc-1n7qm5h-0'
})(['display:flex;flex-direction:column;align-items:center;box-sizing:border-box;width:100%;height:100%;margin-bottom:50px;padding:50px 25px 30px;']);

var TechnicalSpecsItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TechnicalSpecs__TechnicalSpecsItemContainer',
  componentId: 'sc-1n7qm5h-1'
})(['display:flex;flex-direction:row;justify-content:center;height:100%;width:40%;justify-content:space-between;padding:31px 0px;border-bottom:1px solid #d5d5d5;', ';'], _theme2.default.max.medium(_templateObject));

var ItemTitle = _styledComponents2.default.div.withConfig({
  displayName: 'TechnicalSpecs__ItemTitle',
  componentId: 'sc-1n7qm5h-2'
})(['font-family:RobotoBold;font-size:16px;text-transform:uppercase;color:#1f1f1f;width:40%;']);
var ItemValue = _styledComponents2.default.div.withConfig({
  displayName: 'TechnicalSpecs__ItemValue',
  componentId: 'sc-1n7qm5h-3'
})(['font-family:RobotoLight;font-size:16px;text-transform:uppercase;color:#1f1f1f;align-self:center;width:60%;text-align:right;']);
var TechnicalSpecTitle = _styledComponents2.default.div.withConfig({
  displayName: 'TechnicalSpecs__TechnicalSpecTitle',
  componentId: 'sc-1n7qm5h-4'
})(['font-family:RobotoBold;font-size:24px;text-transform:uppercase;margin-bottom:20px;color:#1f1f1f;', ';'], _theme2.default.min.large(_templateObject2));

var TechnicalSpecsPage = function TechnicalSpecsPage(_ref) {
  var config = _ref.config;
  var translations = config.translations;


  return _react2.default.createElement(
    TechnicalSpecsContainer,
    null,
    _react2.default.createElement(
      TechnicalSpecTitle,
      null,
      translations.title
    ),
    translations.engineTypeValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.engineType
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.engineTypeValue
      )
    ),
    translations.displacementValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.displacement
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.displacementValue
      )
    ),
    translations.boreStrokeValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.boreStroke
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.boreStrokeValue
      )
    ),
    translations.maxPowerValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.maxPower
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.maxPowerValue
      )
    ),
    translations.topSpeedValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.topSpeed
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.topSpeedValue
      )
    ),
    translations.accelerationValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.acceleration
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.accelerationValue
      )
    ),
    translations.numberCylindersValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.numberCylinders
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.numberCylindersValue
      )
    ),
    translations.combinedConsumptionValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.combinedConsumptionTitle
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.combinedConsumptionValue
      )
    ),
    translations.combinedConsumptionTitleWithDischargeBattery && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.combinedConsumptionTitleWithDischargeBattery
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.combinedConsumptionValueWithDischargeBattery
      )
    ),
    translations.powerConsumptionValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.powerConsumptionTitle
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.powerConsumptionValue
      )
    ),
    translations.combinedCo2EmissionValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.combinedCo2EmissionTitle
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.combinedCo2EmissionValue
      )
    ),
    translations.efficiencyClassValue && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.efficiencyClassTitle
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.efficiencyClassValue
      )
    ),
    translations.efficiencyClassValueWithDischargeBattery && _react2.default.createElement(
      TechnicalSpecsItemContainer,
      null,
      _react2.default.createElement(
        ItemTitle,
        null,
        translations.efficiencyClassTitleWithDischargeBattery
      ),
      _react2.default.createElement(
        ItemValue,
        null,
        translations.efficiencyClassValueWithDischargeBattery
      )
    )
  );
};

exports.default = TechnicalSpecsPage;