'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 50px 5%;\n  '], ['\n    padding: 50px 5%;\n  ']);
/* eslint-disable jsx-a11y/anchor-is-valid */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormFields = require('./FormFields');

var _EmailVerification = require('./EmailVerification');

var _EmailVerification2 = _interopRequireDefault(_EmailVerification);

var _Link = require('./Link');

var _Link2 = _interopRequireDefault(_Link);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _validation = require('../helpers/validation');

var _Global = require('./Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ResetPasswordContent = _styledComponents2.default.div.withConfig({
  displayName: 'RequestResetPassword__ResetPasswordContent',
  componentId: 'sc-17paz2o-0'
})(['padding:50px 35%;', ';'], _theme2.default.max.large(_templateObject));

var ResetPasswordInformation = _styledComponents2.default.div.withConfig({
  displayName: 'RequestResetPassword__ResetPasswordInformation',
  componentId: 'sc-17paz2o-1'
})(['text-align:center;font-size:16px;padding-bottom:50px;width:100%;']);

var RequestResetPassword = function (_Component) {
  (0, _inherits3.default)(RequestResetPassword, _Component);

  function RequestResetPassword(props) {
    (0, _classCallCheck3.default)(this, RequestResetPassword);

    var _this = (0, _possibleConstructorReturn3.default)(this, (RequestResetPassword.__proto__ || (0, _getPrototypeOf2.default)(RequestResetPassword)).call(this, props));

    Object.defineProperty(_this, 'onInputChange', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value) {
        _this.setState(function () {
          return { email: _value };
        });
        var validationErrors = _this.validateEmail();
        _this.setState(function () {
          return {
            emailValidationErrors: [].concat((0, _toConsumableArray3.default)(validationErrors))
          };
        });
      }
    });
    Object.defineProperty(_this, 'onFormSubmit', {
      enumerable: true,
      writable: true,
      value: function value(event) {
        var validationErrors = _this.validateEmail();
        _this.setState(function () {
          return {
            emailValidationErrors: [].concat((0, _toConsumableArray3.default)(validationErrors))
          };
        });

        if (validationErrors.length === 0) {
          var _submitResetRequest = _this.props.submitResetRequest;
          var _email = _this.state.email;

          _submitResetRequest(_email);
        }
        event.preventDefault();
      }
    });
    Object.defineProperty(_this, 'validateEmail', {
      enumerable: true,
      writable: true,
      value: function value() {
        return (0, _validation.validEmail)(_this.state.email, _this.props.translations, true) || [];
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            resetRequestStatus = _this$props.resetRequestStatus,
            goToLogin = _this$props.goToLogin,
            translations = _this$props.translations,
            globalStyling = _this$props.globalStyling;
        var email = _this.state.email;

        return resetRequestStatus === 'SUCCESS' ? _react2.default.createElement(_EmailVerification2.default, {
          email: email,
          translations: translations,
          globalStyling: globalStyling
        }) : _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            _Global.HeadingOne,
            {
              styleOverride: function styleOverride() {
                return '\n            height: 75px;\n            border-bottom: 1px solid #d8d8d8;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            font-size: 20px;\n            text-transform: uppercase;\n          ';
              }
            },
            translations.forgotPasswordHeader
          ),
          _react2.default.createElement(
            ResetPasswordContent,
            null,
            _react2.default.createElement(
              ResetPasswordInformation,
              null,
              translations.resetPasswordInfoText
            ),
            _react2.default.createElement(
              'form',
              { onSubmit: _this.onFormSubmit },
              _react2.default.createElement(_FormFields.InputField, {
                type: 'email',
                label: translations.emailLabel,
                value: email,
                onChange: _this.onInputChange,
                keyValue: 'email',
                error: _this.state.emailValidationErrors.length > 0 && _this.state.emailValidationErrors[0]
              }),
              _react2.default.createElement(_Global.Button, {
                styleOverride: function styleOverride() {
                  return '\n                width: 100%;\n              ';
                },
                type: 'submit',
                text: translations.resetButtonText,
                applyStyle: 'primary',
                buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
              }),
              _react2.default.createElement(
                _Link2.default,
                {
                  theme: {
                    linkColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value
                  },
                  onClick: goToLogin,
                  fullWidth: true
                },
                translations.backToLoginLinkText
              )
            )
          )
        );
      }
    });

    _this.state = {
      email: '',
      emailValidationErrors: []
    };
    return _this;
  }

  return RequestResetPassword;
}(_react.Component);

exports.default = RequestResetPassword;