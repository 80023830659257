'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _UserLocation = require('../../components/UserLocation');

var _UserLocation2 = _interopRequireDefault(_UserLocation);

var _useDealerLocator2 = require('./useDealerLocator');

var _Global = require('../components/Global');

var _types = require('../../types');

var _reducer = require('./reducer');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DealerLocator__Wrapper',
  componentId: 'sc-1kffkyc-0'
})(['width:100%;']);

var HeadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DealerLocator__HeadingWrapper',
  componentId: 'sc-1kffkyc-1'
})(['margin:30px auto;max-width:600px;']);
var MapWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DealerLocator__MapWrapper',
  componentId: 'sc-1kffkyc-2'
})(['height:500px;position:relative;']);
var DisabledOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'DealerLocator__DisabledOverlay',
  componentId: 'sc-1kffkyc-3'
})(['height:100%;width:100%;position:absolute;z-index:10;background-color:rgba(00,00,00,0.5);top:0;']);

exports.default = function (_ref) {
  var config = _ref.config,
      globalStyling = _ref.globalStyling,
      dispatch = _ref.dispatch;
  var locationIcon = config.locationIcon;

  var _useDealerLocator = (0, _useDealerLocator2.useDealerLocator)(dispatch, config, globalStyling),
      countryCode = _useDealerLocator.countryCode,
      location = _useDealerLocator.location,
      ref = _useDealerLocator.ref,
      locationCallback = _useDealerLocator.locationCallback,
      dealers = _useDealerLocator.dealers,
      status = _useDealerLocator.status;

  var error = status === _reducer.dealerPodStatus.fetchingFailure || dealers.length === 0;
  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      HeadingWrapper,
      null,
      error ? _react2.default.createElement(
        _Global.Paragraph,
        {
          styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)({
              alignment: 'center'
            });
          }
        },
        config.translations.noDealers
      ) : _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)({ padding: { bottom: 40 } });
            }
          },
          config.translations.description
        ),
        _react2.default.createElement(_UserLocation2.default, {
          placeholderText: config.translations.inputPlaceholder,
          iconUrl: locationIcon.src,
          direction: globalStyling.direction,
          browserLocationCallback: locationCallback,
          inputLocationCallback: locationCallback,
          options: {
            countryCode: countryCode,
            latitude: location.latitude,
            longitude: location.longitude
          }
        })
      )
    ),
    _react2.default.createElement(
      MapWrapper,
      null,
      _react2.default.createElement('div', { ref: ref, id: 'map', style: { height: '100%' } }),
      error && _react2.default.createElement(DisabledOverlay, null)
    )
  );
};