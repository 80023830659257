'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Error = _styledComponents2.default.div.withConfig({
  displayName: 'FieldValidationError__Error',
  componentId: 'sc-q2l352-0'
})(['top:35px;color:#9e1b32;font-size:16px;margin-top:10px;font-family:\'GenesisSansTextGlobal-Regular\';']);

var FieldValidationError = function FieldValidationError(_ref) {
  var children = _ref.children;
  return _react2.default.createElement(
    Error,
    null,
    children
  );
};
exports.default = FieldValidationError;