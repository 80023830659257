'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n    width: 100%;\n  '], ['\n    display: block;\n    width: 100%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MobileOnlyStyled = _styledComponents2.default.div.withConfig({
  displayName: 'MobileOnly__MobileOnlyStyled',
  componentId: 'sc-lmmcp0-0'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject));

var MobileOnly = function MobileOnly(_ref) {
  var children = _ref.children;
  return _react2.default.createElement(
    MobileOnlyStyled,
    null,
    children
  );
};

exports.default = MobileOnly;