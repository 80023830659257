"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.chunkArrayByNumber = exports.getName = undefined;

var _from = require("babel-runtime/core-js/array/from");

var _from2 = _interopRequireDefault(_from);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getName = exports.getName = function getName(_ref) {
  var name = _ref.name;
  return name !== undefined ? name : null;
};

var chunkArrayByNumber = exports.chunkArrayByNumber = function chunkArrayByNumber(arr, number) {
  var size = Math.ceil(arr.length / number);
  return (0, _from2.default)({ length: number }, function (v, i) {
    return arr.slice(i * size, i * size + size);
  });
};