'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = StyledRange;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _rcSlider = require('rc-slider');

require('rc-slider/assets/index.css');

var _Handle = require('./Handle');

var _Handle2 = _interopRequireDefault(_Handle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function StyledRange(_ref) {
  var step = _ref.step,
      defaultValue = _ref.defaultValue,
      onAfterChange = _ref.onAfterChange,
      onChange = _ref.onChange,
      rangeValue = _ref.rangeValue,
      stylingConfig = _ref.stylingConfig;

  return _react2.default.createElement(_rcSlider.Range, {
    handle: _Handle2.default,
    trackStyle: [{
      backgroundColor: stylingConfig.rangeSliderColour && stylingConfig.rangeSliderColour.value
    }],
    railStyle: { backgroundColor: '#F0F0F0' },
    min: rangeValue.min,
    max: rangeValue.max,
    step: step,
    defaultValue: defaultValue,
    allowCross: false,
    onAfterChange: onAfterChange,
    onChange: onChange
  });
}

StyledRange.defaultProps = {
  step: 1,
  defaultValue: [],
  rangeValue: { min: 0, max: 100 },
  onAfterChange: function onAfterChange() {},
  onChange: function onChange() {}
};