'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Exterior = require('./Exterior');

var _Exterior2 = _interopRequireDefault(_Exterior);

var _Controls = require('./Controls');

var _Controls2 = _interopRequireDefault(_Controls);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PlayerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Player__PlayerWrapper',
  componentId: 'sc-1x0wplw-0'
})(['width:100%;position:relative;#exterior-canvas{position:absolute;left:0;top:0;}#viewpoint-modal-wrapper{width:100%;top:0px;left:0px;position:absolute;background-color:rgba(0,0,0,0.8);}#viewpoint-modal-close{box-sizing:border-box;height:40px;padding:10px;color:#ffffff;display:flex;justify-content:flex-end;}#viewpoint-modal-content{width:100%;height:100%;display:flex;align-items:center;margin-top:-40px;}#viewpoint-image{width:400px;height:200px;padding:10px;}#viewpoint-desc{color:#ffffff;padding:10px;}']);

exports.default = function (_ref) {
  var imagesIcon = _ref.imagesIcon,
      toggleImageView = _ref.toggleImageView,
      globalStyling = _ref.globalStyling,
      media = _ref.media,
      translations = _ref.translations;

  var loader = (0, _react.useRef)();
  var defaultView = media.exterior ? 'exterior' : 'interior';

  var _useState = (0, _react.useState)(defaultView),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      view = _useState2[0],
      setView = _useState2[1];

  var spinnerColour = globalStyling.colours.primaryBrandColour;
  return _react2.default.createElement(
    PlayerWrapper,
    { id: 'player-wrapper' },
    _react2.default.createElement(
      'div',
      null,
      view === 'interior' && _react2.default.createElement(
        'div',
        null,
        'Not implemented'
      ),
      view === 'exterior' && _react2.default.createElement(_Exterior2.default, {
        loader: loader,
        exterior: media.exterior,
        spinnerColour: spinnerColour
      })
    ),
    _react2.default.createElement(_Controls2.default, {
      imagesIcon: imagesIcon,
      toggleImageView: toggleImageView,
      setPlayerView: setView,
      globalStyling: globalStyling,
      currentView: view,
      media: media,
      translations: translations
    })
  );
};