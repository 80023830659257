'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SubSection;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledSubSection = _styledComponents2.default.div.withConfig({
  displayName: 'SubSection__StyledSubSection',
  componentId: 'sc-hy8b81-0'
})(['width:100%;justify-content:space-between;flex-wrap:wrap;display:flex;flex-direction:row;padding:0 10px;box-sizing:border-box;']);

function SubSection(props) {
  return _react2.default.createElement(
    StyledSubSection,
    null,
    props.children
  );
}