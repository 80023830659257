'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../Global');

var _ = require('..');

var _router = require('../../../actions/router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var positions = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  CENTER: 'center'
};


var mapAlignToPosition = function mapAlignToPosition(_ref) {
  var horizontalAlignment = _ref.horizontalAlignment,
      verticalAlignment = _ref.verticalAlignment,
      _ref$padding = _ref.padding,
      padding = _ref$padding === undefined ? 0 : _ref$padding;

  var top = void 0;
  var right = void 0;
  var bottom = void 0;
  var left = void 0;
  var translateX = 0;
  var translateY = 0;

  var paddingValue = padding + 'px';
  var fiftyPercent = '50%';
  var minusFiftyPercent = '-50%';

  if (horizontalAlignment === positions.LEFT) {
    left = paddingValue;
  }

  if (horizontalAlignment === positions.CENTER) {
    left = fiftyPercent;
    translateX = minusFiftyPercent;
  }

  if (horizontalAlignment === positions.RIGHT) {
    right = paddingValue;
  }

  if (verticalAlignment === positions.TOP) {
    top = paddingValue;
  }

  if (verticalAlignment === positions.CENTER) {
    top = fiftyPercent;
    translateY = minusFiftyPercent;
  }

  if (verticalAlignment === positions.BOTTOM) {
    bottom = paddingValue;
  }

  return {
    top: top,
    right: right,
    bottom: bottom,
    left: left,
    transform: 'translate(' + translateX + ', ' + translateY + ')'
  };
};

var OverlayWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Overlay__OverlayWrapper',
  componentId: 'sc-1xulhn0-0'
})(['width:33.3333%;display:flex;flex-direction:column;position:absolute;', ';', ';', ';', ';', ';'], function (props) {
  return props.top && 'top: ' + props.top;
}, function (props) {
  return props.right && 'right: ' + props.right;
}, function (props) {
  return props.bottom && 'bottom: ' + props.bottom;
}, function (props) {
  return props.left && 'left: ' + props.left;
}, function (props) {
  return props.transform && 'transform: ' + props.transform;
});

var Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'Overlay',
  componentId: 'sc-1xulhn0-1'
})(['display:flex;flex-direction:column;position:relative;&:after{content:\'\';width:100%;height:100%;background-color:', ';opacity:', ';position:absolute;top:0;left:0;z-index:1;}'], function (props) {
  return props.backgroundColour && props.backgroundColour.value || '#000000';
}, function (props) {
  return props.opacity || 0.8;
});

var alignMap = {
  left: 'flex-start',
  right: 'flex-end'
};

var ButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Overlay__ButtonWrapper',
  componentId: 'sc-1xulhn0-2'
})(['margin-top:16px;display:flex;justify-content:', ';'], function (_ref2) {
  var horizontalAlignment = _ref2.horizontalAlignment;
  return horizontalAlignment && alignMap[horizontalAlignment] || 'center';
});

exports.default = function (props) {
  var backgroundColour = props.backgroundColour,
      opacity = props.opacity,
      padding = props.padding,
      headlineFont = props.headlineFont,
      bodyFont = props.bodyFont,
      button = props.button,
      headlineText = props.headlineText,
      bodyText = props.bodyText,
      buttonText = props.buttonText,
      horizontalAlignment = props.horizontalAlignment,
      verticalAlignment = props.verticalAlignment,
      uiElements = props.uiElements,
      dispatch = props.dispatch;

  var position = mapAlignToPosition({
    horizontalAlignment: horizontalAlignment,
    verticalAlignment: verticalAlignment,
    padding: padding
  });
  return _react2.default.createElement(
    OverlayWrapper,
    position,
    _react2.default.createElement(
      Overlay,
      { backgroundColour: backgroundColour, opacity: opacity },
      _react2.default.createElement(
        _.TextWrapper,
        { padding: padding },
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, headlineFont, {
                alignment: horizontalAlignment
              })) + ';\n              z-index: 2;\n            ';
            }
          },
          headlineText
        ),
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, bodyFont, {
                alignment: horizontalAlignment
              })) + ';\n              z-index: 2;\n            ';
            }
          },
          bodyText
        )
      )
    ),
    button && _react2.default.createElement(
      ButtonWrapper,
      { horizontalAlignment: horizontalAlignment },
      _react2.default.createElement(_Global.Button, {
        buttonStyle: uiElements.primaryButton && uiElements.primaryButton.buttonStyle,
        applyStyle: 'primary',
        styleOverride: function styleOverride() {
          return (0, _Global.buttonStyleOverride)(button);
        },
        text: buttonText,
        onClick: function onClick() {
          return dispatch(_router.actions.navigate(button.url));
        }
      })
    )
  );
};