'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  translations: {
    monthlyPayment: 'Monthly payment',
    finalPayment: 'Final payment',
    numberOfPayments: 'Number of payments',
    totalCost: 'Total Cost',
    priceFrom: 'Price: From ',
    deposit: 'Deposit',
    apr: 'APR',
    fromText: 'From',
    vehiclePrice: 'Vehicle Price',
    interestRate: 'Interest Rate',
    respresentativeExampleHeading: 'Representative Hire Purchase Finance Example',
    representativeExampleBody: 'Representative example for XE subject to 36 month, 60,000 mile agreement',
    availableText: 'available',
    findOutMoreText: 'Find Out More',
    /* TODO:When this module is made configurable
    these will need to be pulled from backend services and incorporated into the model */
    disclaimerText: 'Representative photo disclaimer text',
    financialSummaryText: 'Finance from 349€ per month / 5.9% APR',
    representativeExampleTermsAndConditions: 'Ejemplo de arrendamiento no vinculante con facturación de millaje dispuesto para Jaguar Bank, sucursal de FCA Bank Spain en condiciones válidas y actualizadas. Más. Costos de transferencia y admisión. Las cuotas mensuales y el monto total incluyen el seguro GAP Plus + de AXA (colisión diferencial). Esto no está sujeto al IVA. Todos los demás precios incluyen 21'
  }
};