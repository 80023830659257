'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    object-fit: cover;\n  '], ['\n    object-fit: cover;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Video = _styledComponents2.default.video.withConfig({
  displayName: 'Video',
  componentId: 'sc-ctdjyo-0'
})(['width:100%;height:100%;', ';'], _theme2.default.min.large(_templateObject));

var PlayButton = _styledComponents2.default.button.withConfig({
  displayName: 'Video__PlayButton',
  componentId: 'sc-ctdjyo-1'
})(['width:100%;height:100%;position:absolute;background-color:rgba(255,255,255,0);background-image:url(', ');background-repeat:no-repeat;background-position:center center;z-index:66;color:white;'], function (props) {
  return props.src;
});

exports.default = function (props) {
  var src = props.src,
      isCurrent = props.isCurrent,
      handleComplete = props.handleComplete,
      autoplay = props.autoplay,
      _props$muted = props.muted,
      muted = _props$muted === undefined ? true : _props$muted;

  var playButtonSrc = 'https://res.cloudinary.com/motortrak/image/upload/v1579531581/cms/global/icons/play-btn.svg';

  var videoRef = (0, _react.useRef)(null);

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      isPlaying = _useState2[0],
      setIsPlaying = _useState2[1];

  (0, _react.useEffect)(function () {
    videoRef.current.addEventListener('ended', function () {
      handleComplete();
    }, false);
  }, []);

  (0, _react.useEffect)(function () {
    if (!isCurrent && !videoRef.current.paused || isCurrent && !videoRef.current.paused && !autoplay) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setIsPlaying(false);
    }

    if (isCurrent && videoRef.current.paused && autoplay) {
      videoRef.current.play();
    }
  }, [videoRef, isCurrent, autoplay]);

  (0, _react.useEffect)(function () {
    if (videoRef.current.paused || videoRef.current.ended) {
      setIsPlaying(false);
    }

    if (!videoRef.current.paused && !videoRef.current.ended) {
      setIsPlaying(true);
    }
  }, [isCurrent]);

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      Video,
      { muted: muted, ref: videoRef, src: src },
      _react2.default.createElement('source', { src: src, type: 'video/mp4' })
    ),
    !isPlaying && !autoplay && _react2.default.createElement(PlayButton, {
      onClick: function onClick() {
        videoRef.current.play();
        setIsPlaying(true);
      },
      src: playButtonSrc
    })
  );
};