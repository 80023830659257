'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    position: fixed;\n    height: auto;\n    max-height: 100vh;\n    overflow-y: auto;\n    box-shadow: 0 6px 14px 0 ', ';\n    width: ', ';\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 99;\n  '], ['\n    position: fixed;\n    height: auto;\n    max-height: 100vh;\n    overflow-y: auto;\n    box-shadow: 0 6px 14px 0 ', ';\n    width: ', ';\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 99;\n  ']);

exports.default = Modal;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _transparentize = require('polished/lib/color/transparentize');

var _transparentize2 = _interopRequireDefault(_transparentize);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'Overlay'
}).withConfig({
  displayName: 'Modal__Overlay',
  componentId: 'sc-13154kk-0'
})(['position:fixed;left:0;top:0;height:100vh;width:100%;background-color:', ';z-index:98;'], (0, _transparentize2.default)(0.3, '#444444'));

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ModalContainer'
}).withConfig({
  displayName: 'Modal__Container',
  componentId: 'sc-13154kk-1'
})(['position:absolute;background-color:#ffffff;width:100%;top:', 'px;left:0;z-index:99;', ';'], function (_ref) {
  var position = _ref.position;
  return position.y;
}, _theme2.default.min.medium(_templateObject, (0, _transparentize2.default)(0.2, '#444444'), function (_ref2) {
  var width = _ref2.width;
  return width ? width + 'px;' : 'auto';
}));

function Modal(_ref3) {
  var children = _ref3.children,
      onClickOutside = _ref3.onClickOutside,
      position = _ref3.position,
      overlay = _ref3.overlay,
      width = _ref3.width;

  return _react2.default.createElement(
    'div',
    null,
    overlay ? _react2.default.createElement(Overlay, { onClick: onClickOutside, position: position }) : null,
    _react2.default.createElement(
      Container,
      { position: { y: window.scrollY }, width: width },
      children
    )
  );
}

Modal.defaultProps = {
  children: null,
  onClickOutside: function onClickOutside() {},
  position: null,
  overlay: true,
  width: 880
};