'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactLinkify = require('react-linkify');

var _reactLinkify2 = _interopRequireDefault(_reactLinkify);

var _Global = require('../Global');

var _LabelledCheckBox = require('../LabelledCheckBox');

var _LabelledCheckBox2 = _interopRequireDefault(_LabelledCheckBox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Url = _styledComponents2.default.a.withConfig({
  displayName: 'JLRMarketingAndDistributionConsent__Url',
  componentId: 'sc-b8f9k1-0'
})(['color:', ';'], function (_ref) {
  var theme = _ref.theme;
  return theme.color;
});


var CheckboxContainer = _styledComponents2.default.div.withConfig({
  displayName: 'JLRMarketingAndDistributionConsent__CheckboxContainer',
  componentId: 'sc-b8f9k1-1'
})(['display:flex;margin:20px 0;width:100%;flex-direction:column;height:100%;justify-content:space-between;']);

var JLRMarketingAndDistributionConsent = function JLRMarketingAndDistributionConsent(_ref2) {
  var translations = _ref2.translations,
      onCheckBoxClick = _ref2.onCheckBoxClick,
      marketingChecked = _ref2.marketingChecked,
      marketingKey = _ref2.marketingKey,
      onColour = _ref2.onColour,
      labelFontSize = _ref2.labelFontSize,
      checkboxDimension = _ref2.checkboxDimension,
      linkColour = _ref2.linkColour,
      _ref2$enquiryFormStyl = _ref2.enquiryFormStyles,
      enquiryFormStyles = _ref2$enquiryFormStyl === undefined ? {} : _ref2$enquiryFormStyl,
      globalStyling = _ref2.globalStyling;

  return _react2.default.createElement(
    'div',
    { style: { marginBottom: '20px' } },
    _react2.default.createElement(
      _Global.HeadingOne,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 22px;\n        font-weight: 600;\n        margin-bottom: 20px;';
        }
      },
      translations.marketingHeaderText
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 14px;\n        line-height: 17px;\n        margin-top: 20px;';
        }
      },
      _react2.default.createElement(
        _reactLinkify2.default,
        {
          component: function component(_ref3) {
            var children = _ref3.children,
                props = (0, _objectWithoutProperties3.default)(_ref3, ['children']);
            return _react2.default.createElement(
              Url,
              (0, _extends3.default)({}, props, { theme: { color: linkColour && linkColour.value } }),
              children
            );
          },
          properties: { target: '_blank' }
        },
        translations.marketingConsentContent
      )
    ),
    _react2.default.createElement(
      CheckboxContainer,
      null,
      _react2.default.createElement(_LabelledCheckBox2.default, {
        onClick: function onClick() {
          return onCheckBoxClick(marketingKey, !marketingChecked);
        },
        checked: marketingChecked,
        label: translations.marketingConsentLabel,
        onColour: onColour,
        colour: globalStyling.colours.paragraphFontColour && globalStyling.colours.paragraphFontColour.value,
        labelFontSize: enquiryFormStyles.labelFontSize || labelFontSize,
        checkboxDimension: checkboxDimension,
        globalStyling: globalStyling
      })
    ),
    _react2.default.createElement(
      _Global.Paragraph,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 14px;\n        line-height: 17px;\n        margin-top: 20px;';
        }
      },
      _react2.default.createElement(
        _reactLinkify2.default,
        {
          component: function component(_ref4) {
            var children = _ref4.children,
                props = (0, _objectWithoutProperties3.default)(_ref4, ['children']);
            return _react2.default.createElement(
              Url,
              (0, _extends3.default)({}, props, { theme: { color: linkColour && linkColour.value } }),
              children
            );
          },
          properties: { target: '_blank' }
        },
        translations.dataUsageContent
      )
    )
  );
};
exports.default = JLRMarketingAndDistributionConsent;