'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n  '], ['\n    flex-direction: column;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 40px;\n  '], ['\n    margin-bottom: 40px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px;\n  '], ['\n    padding: 20px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('.');

var _TextLinkButton = require('../../../components/Genesis/TextLinkButton');

var _TextLinkButton2 = _interopRequireDefault(_TextLinkButton);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _globalDrawers = require('../../../shared/globalDrawers');

var _numbers = require('../../../shared/localisation/numbers');

var _AppShared = require('../../../types/AppShared');

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _CompareButton = require('../CompareButton');

var _CompareButton2 = _interopRequireDefault(_CompareButton);

var _ImgCollage = require('../ImgCollage');

var _ImgCollage2 = _interopRequireDefault(_ImgCollage);

var _ShortlistButton = require('../ShortlistButton');

var _ShortlistButton2 = _interopRequireDefault(_ShortlistButton);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__Container',
  componentId: 'sc-hxrle9-0'
})(['width:100%;margin-bottom:20px;display:flex;box-sizing:border-box;background-color:#1a1a1a;', ';', ';'], _Genesis2.default.max.extraLarge(_templateObject), _Genesis2.default.min.large(_templateObject2));

var ImageSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__ImageSection',
  componentId: 'sc-hxrle9-1'
})(['width:40%;', ';'], _Genesis2.default.max.extraLarge(_templateObject3));

var VehicleInfoSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__VehicleInfoSection',
  componentId: 'sc-hxrle9-2'
})(['display:flex;flex-direction:column;flex:1;padding:20px 8px;', ';'], _Genesis2.default.min.small(_templateObject4));

var ApprovedStrapLine = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__ApprovedStrapLine',
  componentId: 'sc-hxrle9-3'
})(['color:#c36f51;font-family:\'GenesisSansHeadGlobal-Regular\';font-size:12px;font-weight:normal;margin-bottom:15px;']);

var ActionsRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__ActionsRow',
  componentId: 'sc-hxrle9-4'
})(['border-top:1px solid #fff;display:flex;padding-top:10px;']);

var CompareShortlistContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__CompareShortlistContainer',
  componentId: 'sc-hxrle9-5'
})(['display:flex;gap:20px;margin-right:auto;']);

var PriceMobile = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleItem__PriceMobile',
  componentId: 'sc-hxrle9-6'
})(['border-top:1px solid #fff;font-family:\'GenesisSansHeadGlobal-Regular\';font-style:normal;font-size:24px;color:#fff;padding:20px 0;']);

var primaryButtonStyle = function primaryButtonStyle() {
  return 'width: auto;';
};

var VehicleItem = function VehicleItem(_ref) {
  var vehicle = _ref.vehicle,
      onMoreInfoClick = _ref.onMoreInfoClick,
      translations = _ref.translations,
      locale = _ref.locale,
      config = _ref.config,
      shared = _ref.shared;

  var dispatch = (0, _reactRedux.useDispatch)();
  var sessionCurrency = shared.sessionPreferences.currency;
  var currency = !!sessionCurrency ? vehicle.price.currency : vehicle.price.originalCurrency || vehicle.price.currency;
  var priceValue = !!sessionCurrency ? vehicle.price.value : vehicle.price.originalValue || vehicle.price.value;

  var mobile = (0, _useCheckIsMobileScreen2.default)('medium');
  var tablet = (0, _useCheckIsMobileScreen2.default)('large');

  var financeCellTextNoDigits = function financeCellTextNoDigits(priceEntry, curCurrency) {
    return priceEntry > 0 && (0, _numbers.localiseCurrency)(priceEntry, locale, curCurrency, 0);
  };

  var localisedRetailPrice = (0, _react.useMemo)(function () {
    return financeCellTextNoDigits(Number(priceValue || 0), currency);
  }, [vehicle, currency, financeCellTextNoDigits]);

  var priceConverted = vehicle.price.currency === sessionCurrency;

  return _react2.default.createElement(
    Container,
    { 'data-cy': 'search-result-vehicle-item' },
    _react2.default.createElement(
      ImageSection,
      null,
      _react2.default.createElement(_ImgCollage2.default, {
        onClick: function onClick(e) {
          return onMoreInfoClick(e, vehicle);
        },
        images: vehicle.images,
        config: config,
        video: vehicle.video,
        placeholder: '',
        imageAltText: vehicle.name,
        translations: translations
      })
    ),
    _react2.default.createElement(
      VehicleInfoSection,
      null,
      vehicle.approved && _react2.default.createElement(
        ApprovedStrapLine,
        { translations: translations },
        translations.approvedStraplineText
      ),
      _react2.default.createElement(_.VehicleTitle, {
        vehicleName: vehicle.name,
        price: localisedRetailPrice || vehicle.priceSpecial || translations.noRetailPrice,
        onClick: function onClick() {
          return onMoreInfoClick('VDP', vehicle);
        },
        approved: vehicle.approved,
        translations: translations,
        priceConverted: priceConverted,
        hidePrice: config.hidePrice,
        hideYear: config.hideYear
      }),
      _react2.default.createElement(_.VehicleSubTitle, {
        vehicleBodyStyle: vehicle.bodystyle,
        vehicleFuelType: vehicle.fuel,
        vehicleTransmission: vehicle.transmission
      }),
      _react2.default.createElement(_.SpecItems, {
        specItems: [{
          label: translations.odometer,
          content: vehicle.odometer.units === null ? translations.mileageZero : vehicle.odometer.display
        }, { label: translations.registration, content: vehicle.registration }, { label: translations.interior, content: vehicle.interior }, { label: translations.exterior, content: vehicle.exterior }]
      }),
      _react2.default.createElement(_.Emissions, {
        emissionsData: vehicle.emissions,
        consumptionsData: vehicle.consumption,
        efficiency: vehicle.energyEfficiencyRating,
        emissionsClass: vehicle.engineDetails.emissionsClass,
        translations: translations,
        config: config
      }),
      _react2.default.createElement(_.DistanceFromRetailer, {
        vehicle: vehicle,
        translations: translations,
        locale: locale,
        hideDistanceFrom: config.hideDistanceFrom
      }),
      tablet && _react2.default.createElement(
        PriceMobile,
        null,
        localisedRetailPrice || vehicle.priceSpecial || translations.noRetailPrice
      ),
      _react2.default.createElement(
        ActionsRow,
        null,
        _react2.default.createElement(
          CompareShortlistContainer,
          null,
          !mobile && _react2.default.createElement(_CompareButton2.default, {
            vehicle: vehicle,
            iconOff: config.iconCompareOff,
            iconOn: config.iconCompareOn
          }),
          _react2.default.createElement(_ShortlistButton2.default, {
            vehicle: vehicle,
            iconOff: config.iconShortlistOff,
            iconOn: config.iconShortlistOn
          })
        ),
        _react2.default.createElement(_TextLinkButton2.default, {
          variant: 'primary',
          text: translations.enquireCTA,
          onClick: function onClick() {
            dispatch(_globalDrawers.actions.toggleEnquireDrawer(vehicle));
          },
          styleOverride: primaryButtonStyle
        })
      )
    )
  );
};

exports.default = VehicleItem;