'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['display:none;'], ['display:none;']);

exports.default = VehicleInfoBanner;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactAnchorLinkSmoothScrollV = require('react-anchor-link-smooth-scroll-v2');

var _reactAnchorLinkSmoothScrollV2 = _interopRequireDefault(_reactAnchorLinkSmoothScrollV);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _Genesis2 = _interopRequireDefault(_Genesis);

var _isCentralRetailer = require('../../../helpers/isCentralRetailer');

var _isCentralRetailer2 = _interopRequireDefault(_isCentralRetailer);

var _globalDrawers = require('../../../shared/globalDrawers');

var _numbers = require('../../../shared/localisation/numbers');

var _vehicleDetailsPageTypes = require('../../../types/Genesis/vehicleDetailsPageTypes');

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _Form = require('../Form');

var _CarfaxLogo = require('./CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var slideInTop = (0, _styledComponents.keyframes)(['0%{top:-100px;}100%{top:0px;}']);

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Wrapper',
  componentId: 'sc-18hb8k2-0'
})(['animation-duration:0.4s;animation-iteration-count:forward;animation-name:', ';animation-timing-function:ease-out;background:#000;border-bottom:1px solid #333;color:#fff;display:flex;height:140px;font-family:\'GenesisSansHeadGlobal-Regular\';font-weight:normal;left:0;position:fixed;top:0;width:100%;z-index:95;', ';'], slideInTop, _Genesis2.default.max.large(_templateObject));

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Content',
  componentId: 'sc-18hb8k2-1'
})(['', ';display:flex;margin:0 auto;max-width:1920px;width:100%;'], function () {
  return _Genesis.mixins.pageGuttering();
});

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Column',
  componentId: 'sc-18hb8k2-2'
})(['display:flex;flex:1;flex-direction:column;height:100%;overflow:hidden;justify-content:center;']);

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Row',
  componentId: 'sc-18hb8k2-3'
})(['align-items:center;display:flex;flex:1;justify-content:space-between;padding:10px 15px;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleInfoBanner__Image',
  componentId: 'sc-18hb8k2-4'
})(['', ';aspect-ratio:3 / 2;display:block;object-fit:cover;max-height:140px;'], _Genesis2.default.max.medium(_templateObject));

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Title',
  componentId: 'sc-18hb8k2-5'
})(['font-family:\'GenesisSansHeadGlobal-Light\';font-size:24px;font-weight:normal;line-height:28px;margin-bottom:5px;']);

var InfoRowStyles = '\n  align-items: center;\n  display: flex;\n  padding-bottom: 5px;\n';

var InfoRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__InfoRow',
  componentId: 'sc-18hb8k2-6'
})(['', ''], InfoRowStyles);

var InfoRowLink = _styledComponents2.default.a.withConfig({
  displayName: 'VehicleInfoBanner__InfoRowLink',
  componentId: 'sc-18hb8k2-7'
})(['', ' color:#fff;text-decoration:none;'], InfoRowStyles);

var Price = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Price',
  componentId: 'sc-18hb8k2-8'
})(['display:flex;font-family:\'GenesisSansHeadGlobal-Light\';font-size:24px;justify-content:end;line-height:28px;margin-top:15px;']);

var CtaContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__CtaContainer',
  componentId: 'sc-18hb8k2-9'
})(['align-items:center;display:flex;justify-content:end;']);

function VehicleInfoBanner(_ref) {
  var vehicleDetails = _ref.vehicleDetails,
      config = _ref.config,
      placeholdingImage = _ref.placeholdingImage;
  var modelYear = vehicleDetails.modelYear,
      description = vehicleDetails.description,
      location = vehicleDetails.location,
      specification = vehicleDetails.specification,
      retailerInformation = vehicleDetails.retailerInformation,
      price = vehicleDetails.price,
      locale = vehicleDetails.locale,
      images = vehicleDetails.images;
  var bodystyle = specification.bodystyle,
      fuelType = specification.fuelType,
      transmission = specification.transmission;
  var name = retailerInformation.name,
      phone = retailerInformation.phone,
      id = retailerInformation.id;

  var dispatch = (0, _reactRedux.useDispatch)();

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      Content,
      null,
      _react2.default.createElement(Image, {
        src: images.length > 0 ? images[0] : placeholdingImage,
        'data-cy': 'vehicle-banner-image',
        alt: vehicleDetails.name
      }),
      _react2.default.createElement(
        Row,
        null,
        _react2.default.createElement(
          Column,
          null,
          vehicleDetails.approved && _react2.default.createElement(
            _reactAnchorLinkSmoothScrollV2.default,
            {
              href: '#Certified-Preowned-Benefits',
              offset: '200',
              style: { color: '#C36F51', textDecoration: 'none' }
            },
            config.translations.titleCertifiedPreownedBenefits
          ),
          _react2.default.createElement(
            Title,
            null,
            modelYear,
            ' ',
            description
          ),
          _react2.default.createElement(
            InfoRow,
            null,
            bodystyle,
            ' | ',
            fuelType,
            ' | ',
            transmission
          ),
          _react2.default.createElement(
            InfoRow,
            null,
            _react2.default.createElement('img', {
              src: 'https://res.cloudinary.com/motortrak/image/upload/v1697233489/locator/genesis/icons/location-pin-title.svg',
              alt: name + ', ' + location,
              style: { marginLeft: '-2px' }
            }),
            _react2.default.createElement(
              'div',
              { style: { paddingLeft: '5px' } },
              name + ', ' + location
            )
          ),
          !(0, _isCentralRetailer2.default)(id) && phone && _react2.default.createElement(
            InfoRowLink,
            { href: 'tel:' + phone },
            _react2.default.createElement('img', {
              src: 'https://res.cloudinary.com/motortrak/image/upload/v1700586964/locator/genesis/icons/sticky-header-phone.svg',
              alt: 'Call ' + name + ', ' + location
            }),
            _react2.default.createElement(
              'div',
              { style: { paddingLeft: '5px', textDecoration: 'none' } },
              phone
            )
          )
        ),
        _react2.default.createElement(
          Column,
          { style: { justifyContent: 'space-between' } },
          _react2.default.createElement(
            Price,
            null,
            price.value !== 0 ? (0, _numbers.localiseCurrency)(price.value, locale, price.currency, 0) : vehicleDetails.priceSpecial ? vehicleDetails.priceSpecial : config.translations.noRetailPrice
          ),
          _react2.default.createElement(
            CtaContainer,
            null,
            _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicleDetails.carfax }),
            _react2.default.createElement(
              'div',
              {
                style: {
                  display: 'flex',
                  width: '200px',
                  justifyContent: 'end'
                }
              },
              _react2.default.createElement(
                _Form.Button,
                {
                  variant: 'primary',
                  onClick: function onClick() {
                    return dispatch(_globalDrawers.actions.toggleEnquireDrawer(vehicleDetails));
                  },
                  styleOverride: function styleOverride() {
                    return 'width: 100%';
                  }
                },
                config.translations.contact
              )
            )
          )
        )
      )
    )
  );
}