'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TwentyFourHours;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function TwentyFourHours(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 40 40' },
      _react2.default.createElement('path', { d: 'M12.399 22.262l-.946.901a.675.675 0 0 0-.207.486v.383c0 .18.073.353.202.48a.687.687 0 0 0 .485.197h5.647c.182 0 .357-.07.485-.197a.676.676 0 0 0 .202-.48v-.495a.676.676 0 0 0-.202-.48.687.687 0 0 0-.485-.197h-3.12l.085-.08c2.038-1.928 3.482-3.445 3.482-5.42 0-1.584-1.044-3.176-3.383-3.176a4.776 4.776 0 0 0-2.815.901.673.673 0 0 0-.216.822l.174.378a.695.695 0 0 0 1.021.275c.454-.33 1-.514 1.563-.527 1.026 0 1.548.5 1.548 1.48-.014 1.327-1.139 2.522-3.52 4.75zm10.292-7.914c-.019 0-.038-.005-.056-.005h-.212a.7.7 0 0 0-.565.29l-4.217 5.925a.676.676 0 0 0-.122.388v.476c.003.374.31.677.687.677h3.85v1.933c.003.376.312.68.692.677h.654c.379.002.688-.3.692-.677v-1.933h.677c.182 0 .356-.07.485-.197a.676.676 0 0 0 .202-.48v-.44a.676.676 0 0 0-.202-.48.687.687 0 0 0-.485-.196h-.677V15.02a.685.685 0 0 0-.692-.677h-.654c-.02 0-.038.005-.057.005zm-.635 5.958H19.98c.527-.752 1.431-2.045 2.076-2.998v2.998zm-3.68 14.662c-7.84.081-14.333-6.02-14.677-13.791C3.355 13.405 9.284 6.763 17.1 6.162v2.302a.443.443 0 0 0 .24.388.418.418 0 0 0 .212.051.434.434 0 0 0 .25-.075l5.322-3.506a.444.444 0 0 0 0-.738l-5.323-3.507a.443.443 0 0 0-.46-.023.445.445 0 0 0-.24.392V3.23C9.446 3.837 3.087 9.325 1.418 16.76c-1.668 7.436 1.744 15.082 8.414 18.854 6.67 3.772 15.04 2.789 20.637-2.425l-2.037-2.13a14.79 14.79 0 0 1-10.057 3.909zM33.463 11.49a17.27 17.27 0 0 0-2.113-2.741l-2.188 1.984a14.441 14.441 0 0 1 1.76 2.284l2.541-1.527zm-.522 7.088l2.95-.397a17.058 17.058 0 0 0-.818-3.348l-2.81.962c.318.903.545 1.836.678 2.783zM28.513 6.321a17.676 17.676 0 0 0-3.055-1.69l-1.195 2.68a14.92 14.92 0 0 1 2.541 1.4l1.709-2.39zM29.73 29.7l2.297 1.858a17.41 17.41 0 0 0 1.953-2.862l-2.63-1.378a14.577 14.577 0 0 1-1.62 2.382zm3.3-8.05c-.075.954-.245 1.899-.509 2.82l2.866.794c.317-1.107.522-2.243.612-3.39l-2.97-.224z' })
    )
  );
}

TwentyFourHours.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};