'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return {
    items: [{
      translations: {
        titleItemText: 'Title Placeholder',
        descriptionItemText: 'Description Placeholder'
      },
      image: {
        label: 'default_icongrid_icon',
        value: 'https://res.cloudinary.com/motortrak/image/upload/v1538484990/Placeholder%20Images/default-brick-icon.svg'
      }
    }],
    translations: {
      headLineText: 'Headline',
      subHeadlineText: 'Headline Description'
    },
    headLineAlignment: 'center',
    headlineFont: {
      fontSize: '22',
      kerning: '2',
      transform: 'uppercase'
    },
    itemHeaderFont: {
      fontSize: '16',
      kerning: '1',
      transform: 'uppercase'
    },
    descriptionFont: {
      fontSize: '14',
      kerning: '0.4',
      transform: 'none'
    }
  };
};