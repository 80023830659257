"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
// sets the distance the header will scroll to fully transition
var SCROLL_DISTANCE = 170;

var calculateTransitionPercentage = function calculateTransitionPercentage(scrollTop, initialValue, finalValue) {
  var percentage = scrollTop / SCROLL_DISTANCE;
  var differance = (finalValue - initialValue) * percentage;

  return percentage < 1 ? initialValue + differance : finalValue;
};

exports.default = calculateTransitionPercentage;