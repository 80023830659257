'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _DealerChecker = require('./DealerChecker');

Object.defineProperty(exports, 'component', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_DealerChecker).default;
  }
});

var _effects = require('./effects');

Object.defineProperty(exports, 'effects', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_effects).default;
  }
});

var _reducer = require('./reducer');

Object.defineProperty(exports, 'reducer', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_reducer).default;
  }
});

var _actions = require('./actions');

Object.defineProperty(exports, 'actions', {
  enumerable: true,
  get: function get() {
    return _actions.actions;
  }
});
Object.defineProperty(exports, 'constants', {
  enumerable: true,
  get: function get() {
    return _actions.constants;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var id = exports.id = 'DealerChecker';
var description = exports.description = 'Dealer Checker Module';