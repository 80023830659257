'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mixins = exports.screenSizeOptions = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 58px;\n  '], ['\n    padding: 0 58px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 76px;\n  '], ['\n    padding: 0 76px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 80px;\n  '], ['\n    padding: 0 80px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 100px;\n  '], ['\n    padding: 0 100px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 140px;\n  '], ['\n    padding: 0 140px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 224px;\n  '], ['\n    padding: 0 224px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 250px;\n  '], ['\n    padding: 0 250px;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 338px;\n  '], ['\n    padding: 0 338px;\n  ']); /* eslint-disable */


var _styledComponents = require('styled-components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var screenSizeOptions = exports.screenSizeOptions = {
  small: 767,
  medium: 1023,
  large: 1199,
  extraLarge: 1399,
  xxl: 1919
};

var generateMediaQueries = function generateMediaQueries(widthType) {
  return (0, _keys2.default)(screenSizeOptions).reduce(function (acc, label) {
    acc[label] = function () {
      return (0, _styledComponents.css)(['@media screen and (', '-width:', 'px ){', ';}'], widthType, screenSizeOptions[label], _styledComponents.css.apply(undefined, arguments));
    };
    return acc;
  }, {});
};

var media = {
  min: generateMediaQueries('min'),
  max: generateMediaQueries('max')
};

var pageGuttering = function pageGuttering() {
  return (0, _styledComponents.css)(['box-sizing:border-box;padding:0 30px;', ';', ';', ';', ';', ';'], media.min.small(_templateObject), media.min.medium(_templateObject2), media.min.large(_templateObject3), media.min.extraLarge(_templateObject4), media.min.xxl(_templateObject5));
};

var heroGuttering = function heroGuttering() {
  return (0, _styledComponents.css)(['box-sizing:border-box;padding:0 30px;', ';', ';', ';', ';', ';'], media.min.small(_templateObject), media.min.medium(_templateObject2), media.min.large(_templateObject6), media.min.extraLarge(_templateObject7), media.min.xxl(_templateObject8));
};

var mixins = exports.mixins = {
  pageGuttering: pageGuttering,
  heroGuttering: heroGuttering
};

exports.default = media;