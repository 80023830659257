'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _history = require('history');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SubNavBar = require('../../../components/RollsRoyce/SubNavBar');

var _SubNavBar2 = _interopRequireDefault(_SubNavBar);

var _types = require('../../../components/RollsRoyce/SubNavBar/types');

var _useCheckIsMobileScreenRollsRoyce = require('../../../hooks/useCheckIsMobileScreenRollsRoyce');

var _useCheckIsMobileScreenRollsRoyce2 = _interopRequireDefault(_useCheckIsMobileScreenRollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RollsRoyceSubNav = function RollsRoyceSubNav(_ref) {
  var config = _ref.config,
      history = _ref.history;

  var isNVLChina = config.isNVLChina;
  var isDesktop = (0, _useCheckIsMobileScreenRollsRoyce2.default)('medium');

  if (isNVLChina && isDesktop) {
    return null;
  }

  return _react2.default.createElement(_SubNavBar2.default, { config: config, history: history });
};

exports.default = _react2.default.memo(RollsRoyceSubNav);