'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_BUDGET_SEARCH';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['INIT', 'GET_BUDGET_SEARCH_GROUPS', 'GET_BUDGET_SEARCH_GROUPS_SUCCESS', 'GET_BUDGET_SEARCH_RANGE', 'GET_BUDGET_SEARCH_RANGE_SUCCESS', 'GET_AFFORDABLE_MODEL_FINANCE_SUCCESS', 'GET_SUGGESTED_MODEL_FINANCE_SUCCESS']);

var id = exports.id = 'BudgetSearch';

var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  getBudgetSearchGroups: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_BUDGET_SEARCH_GROUPS, id, function (payload) {
    return payload;
  }),
  getBudgetSearchGroupsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_BUDGET_SEARCH_GROUPS_SUCCESS, id, function (config) {
    return config;
  }),
  getBudgetSearchRange: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_BUDGET_SEARCH_RANGE, id, function (payload) {
    return payload;
  }),
  getBudgetSearchRangeSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_BUDGET_SEARCH_RANGE_SUCCESS, id, function (config) {
    return config;
  }),
  getAffordableModelFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_AFFORDABLE_MODEL_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  }),
  getSuggestedModelFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_SUGGESTED_MODEL_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  })
};

var replaceIfExists = function replaceIfExists(groups, group) {
  return [].concat((0, _toConsumableArray3.default)(groups.map(function (g) {
    return g.model === group.model ? group : g;
  })));
};

var initialState = exports.initialState = {
  error: null,
  affordableModels: null,
  suggestedModels: null,
  suggestedBudgetIncrease: 10,
  priceRangeLabel: null,
  fullRangeOptions: null,
  searching: true
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.GET_BUDGET_SEARCH_GROUPS:
      return (0, _extends3.default)({}, state, {
        searching: true
      });
    case constants.GET_BUDGET_SEARCH_RANGE_SUCCESS:
      return (0, _extends3.default)({}, state, action.payload);
    case constants.GET_BUDGET_SEARCH_GROUPS_SUCCESS:
      return (0, _extends3.default)({}, state, action.payload, {
        searching: false
      });
    case constants.GET_AFFORDABLE_MODEL_FINANCE_SUCCESS:
      {
        var group = action.payload.group;

        return (0, _extends3.default)({}, state, {
          affordableModels: replaceIfExists(state.affordableModels, group)
        });
      }
    case constants.GET_SUGGESTED_MODEL_FINANCE_SUCCESS:
      {
        var _group = action.payload.group;

        return (0, _extends3.default)({}, state, {
          suggestedModels: replaceIfExists(state.suggestedModels, _group)
        });
      }
    default:
      return state;
  }
}