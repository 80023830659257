'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  padding: 40px 24px 0;\n'], ['\n  padding: 40px 24px 0;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Index = require('../../../components/Bentley/Shortlist/Index');

var _Index2 = _interopRequireDefault(_Index);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AppShared = require('../../../types/AppShared');

var _shortlistPageTypes = require('../../../types/Bentley/shortlistPageTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ShortListContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Shortlist__ShortListContainer',
  componentId: 'sc-ysmvfw-0'
})(['padding:50px 48px 0;', ';'], _theme2.default.max.large(_templateObject));

var ShortList = function ShortList(_ref) {
  var config = _ref.config,
      shared = _ref.shared;
  var translations = config.translations,
      rest = (0, _objectWithoutProperties3.default)(config, ['translations']);
  var vehicles = shared.shortlist.vehicles,
      sessionPreferences = shared.sessionPreferences;


  return _react2.default.createElement(
    ShortListContainer,
    null,
    _react2.default.createElement(_Index2.default, {
      config: rest,
      translations: translations,
      vehicles: vehicles || [],
      locale: sessionPreferences.language,
      searchSort: sessionPreferences.searchSort,
      shared: shared
    })
  );
};

exports.default = ShortList;