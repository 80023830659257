'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilterSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FilterSection__FilterSectionWrapper',
  componentId: 'sc-jgis6x-0'
})(['display:flex;flex-direction:column;width:100%;margin-bottom:12px;']);

var FilterSectionTitle = _styledComponents2.default.div.withConfig({
  displayName: 'FilterSection__FilterSectionTitle',
  componentId: 'sc-jgis6x-1'
})(['min-height:45px;display:flex;padding-top:13px;padding-bottom:6px;justify-content:space-between;text-transform:uppercase;align-items:center;border-bottom:1px solid #000;width:100%;box-sizing:border-box;font-family:\'RivieraNights-Regular\',\'Jost-Light\';font-weight:normal;font-size:16px;line-height:26px;letter-spacing:0.5px;', ';'], function (_ref) {
  var disabled = _ref.disabled;
  return !disabled && 'cursor: pointer;';
});

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'FilterSection__IconWrapper',
  componentId: 'sc-jgis6x-2'
})(['width:11px;height:11px;']);

var FilterSection = function (_Component) {
  (0, _inherits3.default)(FilterSection, _Component);

  function FilterSection(props) {
    (0, _classCallCheck3.default)(this, FilterSection);

    var _this = (0, _possibleConstructorReturn3.default)(this, (FilterSection.__proto__ || (0, _getPrototypeOf2.default)(FilterSection)).call(this, props));

    Object.defineProperty(_this, 'onClickHandler', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (!_this.props.disabled) {
          _this.setState(function (prevState) {
            return { open: !prevState.open };
          });
        }
      }
    });

    _this.state = {
      open: props.active && !props.disabled
    };
    return _this;
  }

  (0, _createClass3.default)(FilterSection, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      if (this.props.disabled && this.state.open) {
        this.setState({ open: false });
      }
    }
  }, {
    key: 'setOpenState',
    value: function setOpenState(open) {
      this.setState({ open: open });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          translations = _props.translations,
          children = _props.children,
          title = _props.title,
          disabled = _props.disabled,
          stylingConfig = _props.stylingConfig;
      var open = this.state.open;

      var displayIcon = open && stylingConfig.filterSectionOpenIcon || !open && stylingConfig.filterSectionCloseIcon;
      var disabledIcon = stylingConfig.filterSectionDisabledIcon;
      var filterIcon = open ? stylingConfig.filterSectionOpenIcon : stylingConfig.filterSectionCloseIcon;
      return _react2.default.createElement(
        FilterSectionWrapper,
        null,
        _react2.default.createElement(
          FilterSectionTitle,
          {
            tabIndex: 0,
            'data-cy': title,
            disabled: disabled,
            'aria-disabled': disabled,
            onClick: this.onClickHandler,
            onKeyDown: function onKeyDown(e) {
              if (e.keyCode === 13) {
                // 13 is the code for the Enter key
                _this2.onClickHandler();
              }
            },
            'aria-label': disabled ? title + ' ' + translations.notClickableAriaLabel : translations.clickOrHitEnterAriaLabel + ' ' + title,
            'aria-expanded': open
          },
          title,
          disabled && disabledIcon && _react2.default.createElement(IconWrapper, { src: disabledIcon, alt: '' }),
          !disabled && displayIcon && _react2.default.createElement(IconWrapper, { src: filterIcon, alt: '' })
        ),
        open && children
      );
    }
  }]);
  return FilterSection;
}(_react.Component);

exports.default = FilterSection;