'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _filters = require('../../shared/filters');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var constants = exports.constants = {
  getModelGroups: 'GET_MODEL_GROUPS',
  getAstonModelGroups: 'GET_ASTON_MODEL_GROUPS',
  getGenesisModelGroups: 'GET_GENESIS_MODEL_GROUPS',
  getModelGroupsSuccess: 'GET_MODEL_GROUPS_SUCCESS',
  getModelGroupsFailure: 'GET_MODEL_GROUPS_FAILURE',
  clearState: 'CLEAR_STATE'
};

var actions = exports.actions = {
  getModelGroups: function getModelGroups() {
    return { type: constants.getModelGroups };
  },
  getAstonModelGroups: function getAstonModelGroups() {
    return { type: constants.getAstonModelGroups };
  },
  getGenesisModelGroups: function getGenesisModelGroups() {
    return { type: constants.getGenesisModelGroups };
  },
  getModelGroupsSuccess: function getModelGroupsSuccess(payload) {
    return {
      type: constants.getModelGroupsSuccess,
      payload: payload
    };
  },
  getModelGroupsFailure: function getModelGroupsFailure() {
    return {
      type: constants.getModelGroupsFailure
    };
  },
  clearState: function clearState() {
    return {
      type: constants.clearState
    };
  }
};

var initialState = {
  modelGroups: [],
  loaded: false
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];
  var payload = action.payload;

  switch (action.type) {
    case constants.getModelGroupsSuccess:
      return (0, _extends3.default)({}, state, {
        modelGroups: payload
      });
    case _filters.constants.LOAD_FILTERS_SUCCESS:
      return (0, _extends3.default)({}, state, {
        loaded: true
      });
    case constants.clearState:
      return initialState;
    default:
      return state;
  }
}