'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin: 10px 15px;\n    max-width: 80px;\n'], ['\n    margin: 10px 15px;\n    max-width: 80px;\n']);
/* eslint-disable no-return-assign, no-param-reassign */


exports.default = BrandLogo;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Anchor = _styledComponents2.default.a.withConfig({
  displayName: 'Anchor'
}).withConfig({
  displayName: 'BrandLogo__Anchor',
  componentId: 'sc-bfv79q-0'
})(['display:flex;align-items:center;cursor:pointer;']);
var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'Logo'
}).withConfig({
  displayName: 'BrandLogo__Logo',
  componentId: 'sc-bfv79q-1'
})(['max-height:', ';max-width:', ';', ';'], function (_ref) {
  var height = _ref.height;
  return height ? height + 'px' : '50px';
}, function (_ref2) {
  var width = _ref2.width;
  return width ? width + 'px' : '100px';
}, _theme2.default.max.large(_templateObject));

function BrandLogo(_ref3) {
  var logoUrl = _ref3.logoUrl,
      brandLogoVisibleDesktop = _ref3.brandLogoVisibleDesktop,
      preview = _ref3.preview,
      navigateOnClick = _ref3.navigateOnClick,
      brandLogoSize = _ref3.brandLogoSize;

  return brandLogoVisibleDesktop && _react2.default.createElement(
    Anchor,
    {
      onClick: function onClick(e) {
        if (preview) {
          e.preventDefault();
        } else {
          navigateOnClick();
        }
      }
    },
    _react2.default.createElement(Logo, {
      width: brandLogoSize && brandLogoSize.width,
      height: brandLogoSize && brandLogoSize.height,
      src: logoUrl,
      alt: 'Brand Logo'
    })
  );
}

BrandLogo.defaultProps = {
  preview: false
};