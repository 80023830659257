'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _rcSlider = require('rc-slider');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactAnchorLinkSmoothScrollV = require('react-anchor-link-smooth-scroll-v2');

var _reactAnchorLinkSmoothScrollV2 = _interopRequireDefault(_reactAnchorLinkSmoothScrollV);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useSliderValues2 = require('../../../../linked-modules/Filters/RangeFilters/useSliderValues');

var _useSliderValues3 = _interopRequireDefault(_useSliderValues2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__ContentWrapper',
  componentId: 'sc-331eqv-0'
})(['color:#fff;padding:15px 0 10px;']);

var UnstyledLink = (0, _styledComponents2.default)(_reactAnchorLinkSmoothScrollV2.default).withConfig({
  displayName: 'DualHandledRangeFilter__UnstyledLink',
  componentId: 'sc-331eqv-1'
})(['text-decoration:none;color:inherit;']);

var Super = _styledComponents2.default.sup.withConfig({
  displayName: 'DualHandledRangeFilter__Super',
  componentId: 'sc-331eqv-2'
})(['font-size:22px;']);

var Label = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__Label',
  componentId: 'sc-331eqv-3'
})(['color:#fff;display:flex;align-items:center;font-size:14px;margin:10px 0;justify-content:space-between;']);

var HandleContainer = (0, _styledComponents2.default)(_rcSlider.Handle).withConfig({
  displayName: 'DualHandledRangeFilter__HandleContainer',
  componentId: 'sc-331eqv-4'
})(['margin-top:-8px;position:absolute;touch-action:pan-x;cursor:grab;outline:none;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__IconWrapper',
  componentId: 'sc-331eqv-5'
})(['width:20px;height:20px;', ';background-size:cover;'], function (_ref) {
  var src = _ref.src;
  return 'background-image: url(' + src + ')';
});

var MinValue = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__MinValue',
  componentId: 'sc-331eqv-6'
})(['']);
var MaxValue = _styledComponents2.default.div.withConfig({
  displayName: 'DualHandledRangeFilter__MaxValue',
  componentId: 'sc-331eqv-7'
})(['text-align:right;']);

exports.default = function (_ref2) {
  var _ref2$step = _ref2.step,
      step = _ref2$step === undefined ? 1 : _ref2$step,
      availableValues = _ref2.availableValues,
      selectedValues = _ref2.selectedValues,
      filtersStyling = _ref2.filtersStyling,
      _ref2$formatter = _ref2.formatter,
      formatter = _ref2$formatter === undefined ? function (val) {
    return val;
  } : _ref2$formatter,
      filterKeyMin = _ref2.filterKeyMin,
      filterKeyMax = _ref2.filterKeyMax,
      updateFilters = _ref2.updateFilters,
      disclaimerLink = _ref2.disclaimerLink,
      superText = _ref2.superText;

  var _useSliderValues = (0, _useSliderValues3.default)(selectedValues),
      updateSliderValues = _useSliderValues.updateSliderValues,
      selectedSliderValues = _useSliderValues.selectedSliderValues;

  var updateFilterValues = function updateFilterValues(values) {
    var _values = (0, _slicedToArray3.default)(values, 2),
        minValue = _values[0],
        maxValue = _values[1];

    updateFilters([{ key: filterKeyMin, value: minValue }, { key: filterKeyMax, value: maxValue }]);
  };

  var minValue = selectedSliderValues.minValue,
      maxValue = selectedSliderValues.maxValue;


  var CustomHandle = function CustomHandle(props) {
    return _react2.default.createElement(
      HandleContainer,
      {
        key: props.index,
        value: props.value,
        offset: props.offset
      },
      _react2.default.createElement(IconWrapper, { src: filtersStyling.filterSliderIcon })
    );
  };

  var minRangeValue = Number(availableValues.min);
  var maxRangeValue = Number(availableValues.max);

  return _react2.default.createElement(
    ContentWrapper,
    null,
    _react2.default.createElement(
      'div',
      { style: { padding: '5px 10px' } },
      _react2.default.createElement(_rcSlider.Range, {
        trackStyle: [{ backgroundColor: '#fff', height: '4px' }],
        handle: CustomHandle,
        step: step,
        value: [Number(minValue), Number(maxValue)],
        min: minRangeValue,
        max: maxRangeValue,
        allowCross: false,
        onChange: updateSliderValues,
        onAfterChange: updateFilterValues,
        railStyle: { height: '4px', backgroundColor: '#4D4D4D' },
        stepStyle: { height: '4px' }
      })
    ),
    _react2.default.createElement(
      Label,
      null,
      _react2.default.createElement(
        MinValue,
        null,
        formatter(minValue)
      ),
      _react2.default.createElement(
        MaxValue,
        null,
        formatter(maxValue)
      ),
      disclaimerLink && superText && _react2.default.createElement(
        UnstyledLink,
        { href: disclaimerLink, offset: '100' },
        _react2.default.createElement(
          Super,
          null,
          superText
        )
      )
    )
  );
};