'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 600px;\n    padding: 12px;\n  '], ['\n    width: 600px;\n    padding: 12px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    height: 265px;\n    width: 275px;\n  '], ['\n    height: 265px;\n    width: 275px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    width: 210px;\n  '], ['\n    width: 210px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _chevronDown = require('react-icons/lib/io/chevron-down');

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../components/Global');

var _ModalOverlay = require('../../components/ModalOverlay');

var _useMarketSelector2 = require('./useMarketSelector');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MarketSelector__ContentWrapper',
  componentId: 'sc-pcrgdv-0'
})(['display:flex;flex-wrap:wrap;align-items:center;justify-content:space-around;width:100%;background:#f3f3f3;', ''], _theme2.default.min.medium(_templateObject));

var ColumnWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MarketSelector__ColumnWrapper',
  componentId: 'sc-pcrgdv-1'
})(['display:flex;flex-direction:column;align-items:center;width:100%;padding:12px;justify-content:space-between;', ''], _theme2.default.min.medium(_templateObject2));

var Section = _styledComponents2.default.div.withConfig({
  displayName: 'MarketSelector__Section',
  componentId: 'sc-pcrgdv-2'
})(['display:flex;flex-direction:column;align-items:flex-start;width:100%;padding-bottom:12px;']);

var LeftSection = (0, _styledComponents2.default)(Section).withConfig({
  displayName: 'MarketSelector__LeftSection',
  componentId: 'sc-pcrgdv-3'
})(['justify-content:flex-start;flex:1 0 auto;']);

var SelectContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MarketSelector__SelectContainer',
  componentId: 'sc-pcrgdv-4'
})(['width:100%;position:relative;display:flex;flex-direction:column;box-sizing:border-box;', ''], _theme2.default.min.medium(_templateObject3));

var SelectHeader = _styledComponents2.default.div.withConfig({
  displayName: 'MarketSelector__SelectHeader',
  componentId: 'sc-pcrgdv-5'
})(['display:flex;justify-content:space-between;align-items:center;cursor:pointer;width:100%;padding:4px 6px;white-space:nowrap;overflow:hidden;border:1px solid #d5d5d5;box-sizing:border-box;background:#ffffff;']);

var SelectOptions = _styledComponents2.default.div.withConfig({
  displayName: 'MarketSelector__SelectOptions',
  componentId: 'sc-pcrgdv-6'
})(['display:flex;flex-direction:column;border:1px solid #d5d5d5;box-sizing:border-box;align-items:left;white-space:nowrap;overflow-x:hidden;overflow-y:auto;background:#ffffff;position:absolute;top:27px;z-index:100;width:100%;']);

var Icon = (0, _styledComponents2.default)(_chevronDown2.default).withConfig({
  displayName: 'MarketSelector__Icon',
  componentId: 'sc-pcrgdv-7'
})(['width:20x;height:20px;margin-right:6px;cursor:pointer;']);

var Centred = _styledComponents2.default.div.withConfig({
  displayName: 'MarketSelector__Centred',
  componentId: 'sc-pcrgdv-8'
})(['display:flex;justify-content:center;width:100%;']);

var subHeaderFont = {
  transform: 'uppercase',
  fontSize: 14
};

var selectorStyle = function selectorStyle() {
  return 'margin: 0;\nfont-size: 12px;\npadding: 2px;\ntext-transform: uppercase;\n';
};

var optionStyle = function optionStyle(hover, font) {
  return function () {
    return selectorStyle() + '\n  border-bottom: 1px solid #d5d5d5;\n  min-height: 13px;\n  ' + (font ? 'font-family: ' + font : '') + '\n  ' + (hover ? '\n      cursor: pointer;\n      padding: 4px 6px;\n    &:hover {\n    background: #F3F3F3;;\n  }' : '\n      background: #EEEEEE;\n      padding: 2px 4px;\n    ');
  };
};

var MarketSelector = function MarketSelector(props) {
  var _props$config = props.config,
      translations = _props$config.translations,
      groupsTitleFont = _props$config.groupsTitleFont;

  var _useMarketSelector = (0, _useMarketSelector2.useMarketSelector)(translations),
      marketSelected = _useMarketSelector.marketSelected,
      currency = _useMarketSelector.currency,
      language = _useMarketSelector.language,
      dropDowns = _useMarketSelector.dropDowns,
      closeAll = _useMarketSelector.closeAll,
      submit = _useMarketSelector.submit;

  var currentGroup = void 0;
  return !marketSelected && _react2.default.createElement(
    _ModalOverlay.Overlay,
    { open: true },
    _react2.default.createElement(
      _ModalOverlay.ModalContentWrapper,
      null,
      _react2.default.createElement(
        ContentWrapper,
        { onClick: closeAll },
        _react2.default.createElement(
          ColumnWrapper,
          null,
          dropDowns.map(function (dd) {
            return _react2.default.createElement(
              LeftSection,
              { key: dd.title },
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n                      ' + (0, _Global.fontStyleOverride)(subHeaderFont) + '\n                      margin-bottom: 18px;\n                    ';
                  }
                },
                dd.heading
              ),
              _react2.default.createElement(
                SelectContainer,
                null,
                _react2.default.createElement(
                  SelectHeader,
                  { onClick: dd.toggle },
                  _react2.default.createElement(
                    _Global.Paragraph,
                    { styleOverride: selectorStyle },
                    dd.title
                  ),
                  _react2.default.createElement(Icon, null)
                ),
                dd.open && _react2.default.createElement(
                  SelectOptions,
                  null,
                  dd.showResetOption && _react2.default.createElement(
                    _Global.Paragraph,
                    {
                      onClick: dd.setter(null),
                      styleOverride: optionStyle(true)
                    },
                    dd.resetLabel
                  ),
                  dd.options.map(function (opt) {
                    var option = _react2.default.createElement(
                      _react.Fragment,
                      { key: opt.value },
                      opt.group && opt.group !== currentGroup && _react2.default.createElement(
                        _Global.Paragraph,
                        {
                          styleOverride: optionStyle(false, groupsTitleFont),
                          onClick: function onClick(e) {
                            return e.preventDefault();
                          }
                        },
                        opt.group
                      ),
                      _react2.default.createElement(
                        _Global.Paragraph,
                        {
                          onClick: dd.setter(opt),
                          styleOverride: optionStyle(true)
                        },
                        opt.label
                      )
                    );
                    currentGroup = opt.group;
                    return option;
                  })
                )
              )
            );
          })
        ),
        _react2.default.createElement(
          ColumnWrapper,
          null,
          _react2.default.createElement(
            Section,
            null,
            _react2.default.createElement(
              Centred,
              null,
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return (0, _Global.fontStyleOverride)(subHeaderFont);
                  }
                },
                translations.currencyAdvisoryHeader
              )
            )
          ),
          _react2.default.createElement(
            Section,
            null,
            _react2.default.createElement(
              _Global.Paragraph,
              {
                styleOverride: function styleOverride() {
                  return 'width: 100%;\n                    font-size: 14px;\n                    line-height: 18px;';
                }
              },
              translations.currencyAdvisoryText
            )
          ),
          _react2.default.createElement(
            Section,
            null,
            _react2.default.createElement(
              Centred,
              null,
              _react2.default.createElement(_Global.Button, {
                text: translations.confirm,
                disabled: !currency || !language,
                onClick: submit,
                styleOverride: function styleOverride() {
                  return 'width: 150px;';
                }
              })
            )
          )
        )
      )
    )
  );
};

exports.default = MarketSelector;