'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n    ', ';\n    margin-top: ', ';\n  '], ['\n    ', ';\n    ', ';\n    margin-top: ', ';\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n  '], ['\n    display: flex;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n  '], ['\n    margin: 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 auto -1px auto;\n  '], ['\n    margin: 0 auto -1px auto;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactExpandAnimated = require('react-expand-animated');

var _reactExpandAnimated2 = _interopRequireDefault(_reactExpandAnimated);

var _Global = require('../../components/Global');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TileWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__TileWrapper',
  componentId: 'sc-e38xbr-0'
})(['@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){height:100%;}border:1px solid #dfdfdf;cursor:pointer;display:flex;width:100%;max-width:841px;margin:0 auto;background-color:#ffffff;position:relative;', ';', ';&:last-child{', ';}&:first-child{margin-top:0;}', ';'], function (_ref) {
  var model = _ref.model;
  return !model && 'border: 0';
}, function (_ref2) {
  var model = _ref2.model;
  return model && 'border-top: 1px solid #dfdfdf;';
}, function (_ref3) {
  var model = _ref3.model;
  return model && 'border-bottom: 1px solid #dfdfdf;';
}, _theme2.default.min.small(_templateObject, function (_ref4) {
  var model = _ref4.model;
  return model && 'border: 1px solid #DFDFDF;';
}, function (_ref5) {
  var model = _ref5.model,
      open = _ref5.open;
  return model && open && 'border-bottom: none;';
}, function (_ref6) {
  var model = _ref6.model;
  return model ? '10px' : 0;
}));

var ImageTextWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__ImageTextWrapper',
  componentId: 'sc-e38xbr-1'
})(['width:100%;display:block;', ';'], _theme2.default.min.small(_templateObject2));

var ImageWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__ImageWrapper',
  componentId: 'sc-e38xbr-2'
})(['flex:0 0 45%;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'ModelVariantTile__Image',
  componentId: 'sc-e38xbr-3'
})(['max-width:100%;min-width:100%;']);

var TextWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__TextWrapper',
  componentId: 'sc-e38xbr-4'
})(['flex:1 0 45%;display:flex;flex-direction:column;justify-content:space-between;margin-top:-80px;', ';'], _theme2.default.min.small(_templateObject3));

var TitleDescription = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__TitleDescription',
  componentId: 'sc-e38xbr-5'
})(['margin:0 auto;margin:0;width:100%;flex:1 1 0%;display:flex;flex-direction:column;justify-content:center;padding:20px 10px;@media (max-width:575px){padding:60px 20px 10px 20px;text-align:center;}']);

var ShowVariantsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__ShowVariantsWrapper',
  componentId: 'sc-e38xbr-6'
})(['z-index:1;border:1px solid rgba(255,255,255,0);align-self:flex-start;margin-bottom:-2px;justify-content:center;@media (max-width:575px){align-self:center;}']);

var ShowVariants = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__ShowVariants',
  componentId: 'sc-e38xbr-7'
})(['display:flex;margin:-15px auto -1px auto;border:1px solid rgba(255,255,255,0);padding-bottom:10px;padding-right:15px;padding:10px;', ';', ';'], _theme2.default.max.small(_templateObject4), function (_ref7) {
  var open = _ref7.open,
      borderColour = _ref7.borderColour;
  return open && borderColour && 'border: 1px solid ' + borderColour + ';border-bottom:3px solid #fff';
});

var ExpandWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__ExpandWrapper',
  componentId: 'sc-e38xbr-8'
})(['width:100%;max-width:841px;margin:0 auto;box-sizing:border-box;', ';', ''], function (_ref8) {
  var open = _ref8.open,
      borderColour = _ref8.borderColour;
  return open && borderColour && 'border: 1px solid ' + borderColour;
}, function (_ref9) {
  var open = _ref9.open;
  return !open && 'display: none';
});

var Selection = _styledComponents2.default.img.withConfig({
  displayName: 'ModelVariantTile__Selection',
  componentId: 'sc-e38xbr-9'
})(['margin:10px;']);

var TileContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__TileContainer',
  componentId: 'sc-e38xbr-10'
})(['margin:10px 30px 0 30px;@media (max-width:430px){margin:10px 5px 0 5px;}']);

var SelectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelVariantTile__SelectionWrapper',
  componentId: 'sc-e38xbr-11'
})(['@media (max-width:575px){position:absolute;right:0;}']);

var Tile = function Tile(_ref10) {
  var image = _ref10.image,
      name = _ref10.name,
      description = _ref10.description,
      onClick = _ref10.onClick,
      setOpen = _ref10.setOpen,
      _ref10$config = _ref10.config,
      titleFont = _ref10$config.titleFont,
      descFont = _ref10$config.descFont,
      labelFont = _ref10$config.labelFont,
      translations = _ref10$config.translations,
      arrowIconClosedUrl = _ref10$config.arrowIconClosedUrl,
      arrowIconOpenUrl = _ref10$config.arrowIconOpenUrl,
      borderColour = _ref10$config.borderColour,
      open = _ref10.open,
      selectionUrl = _ref10.selectionUrl,
      hasVariants = _ref10.hasVariants,
      isModel = _ref10.isModel,
      alternativeText = _ref10.alternativeText;

  var r = (Math.random() + 1).toString(36).substring(6);
  var uniqueId = name + '_' + r;
  return _react2.default.createElement(
    TileWrapper,
    {
      onClick: onClick,
      open: open && hasVariants,
      model: isModel,
      role: 'checkbox',
      'aria-labelledby': uniqueId,
      'aria-checked': selectionUrl.includes('-checked') ? 'true' : 'false',
      tabIndex: '0',
      onKeyDown: function onKeyDown(e) {
        // Enter key = 13
        if (e.keyCode === 13) {
          onClick();
        }
        // Tab key = 9
        if (e.keyCode !== 9) {
          e.preventDefault();
        }
      }
    },
    _react2.default.createElement(
      ImageTextWrapper,
      null,
      _react2.default.createElement(
        ImageWrapper,
        null,
        _react2.default.createElement(Image, { src: image, alt: translations.imageLabel || alternativeText })
      ),
      _react2.default.createElement(
        TextWrapper,
        null,
        _react2.default.createElement(
          TitleDescription,
          null,
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return '\n                  ' + (0, _Global.fontStyleOverride)(titleFont);
              },
              name: uniqueId,
              id: uniqueId
            },
            name
          ),
          _react2.default.createElement(
            _Global.Paragraph,
            { styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)(descFont);
              } },
            description
          )
        ),
        isModel && hasVariants && _react2.default.createElement(
          ShowVariantsWrapper,
          { open: open, tabIndex: '0' },
          _react2.default.createElement(
            ShowVariants,
            {
              onClick: function onClick(event) {
                event.stopPropagation();
                setOpen(!open);
              },
              open: open,
              borderColour: borderColour,
              'aria-expanded': open
            },
            _react2.default.createElement(
              _Global.Paragraph,
              {
                styleOverride: function styleOverride() {
                  return '\n                    line-height: initial;\n                    padding: 0px 5px 0px 0;\n                    ' + (0, _Global.fontStyleOverride)(labelFont) + ';\n                  ';
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'max.small',
                    template: 'width: 100%; text-align: center;'
                  }];
                }
              },
              open ? translations.hideVariants : translations.showVariants
            ),
            _react2.default.createElement('img', {
              src: open ? arrowIconOpenUrl : arrowIconClosedUrl,
              alt: 'show variants arrow'
            })
          )
        )
      )
    ),
    _react2.default.createElement(
      SelectionWrapper,
      null,
      _react2.default.createElement(Selection, {
        src: selectionUrl,
        alt: translations.imgSelectionAlternativeText || '',
        tabIndex: '0',
        onKeyDown: function onKeyDown(e) {
          // Enter key = 13
          if (e.keyCode === 13) {
            onClick();
          }
          // Tab key = 9
          if (e.keyCode !== 9) {
            e.preventDefault();
          }
        }
      })
    )
  );
};

exports.default = function (_ref11) {
  var modelGroup = _ref11.modelGroup,
      config = _ref11.config,
      onModelTileClick = _ref11.onModelTileClick,
      onVariantTileClick = _ref11.onVariantTileClick,
      selectedModels = _ref11.selectedModels,
      selectedVariants = _ref11.selectedVariants;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var modelGroupVariants = modelGroup.variants;

  (0, _react.useEffect)(function () {
    if (modelGroup.variants) {
      modelGroupVariants.forEach(function (v) {
        var image = document.createElement('img');
        image.src = v.image;
        image.style.visibility = 'hidden';
        image.style.display = 'none';
        document.getElementsByTagName('body')[0].appendChild(image);
      });
    }
  }, []);

  var modelSelectionUrl = function modelSelectionUrl() {
    var allSubvariants = modelGroupVariants.reduce(function (acc, curr) {
      return [].concat((0, _toConsumableArray3.default)(acc), (0, _toConsumableArray3.default)(curr.subVariants || []));
    }, []).flat();

    if (selectedModels.includes(modelGroup.modelGroupName) && (modelGroupVariants && allSubvariants && allSubvariants.every(function (sv) {
      return selectedVariants.includes(sv);
    }) || modelGroupVariants.every(function (v) {
      return selectedVariants.includes(v.value);
    }))) {
      return config.selectedUrl;
    }
    if (selectedModels.includes(modelGroup.modelGroupName) && modelGroupVariants && (modelGroupVariants.some(function (v) {
      return selectedVariants.includes(v.value);
    }) || modelGroupVariants.map(function (v) {
      return v.subVariants && v.subVariants.some(function (sV) {
        return selectedVariants.includes(sV);
      });
    }))) {
      return config.partSelectedUrl;
    }
    if (selectedModels.includes(modelGroup.modelGroupName) && !modelGroupVariants) {
      return config.selectedUrl;
    }
    return config.unselectedUrl;
  };
  var subVariantSelected = function subVariantSelected(variant) {
    return selectedVariants.some(function (selected) {
      return variant.subVariants.includes(selected);
    });
  };
  return _react2.default.createElement(
    TileContainer,
    null,
    _react2.default.createElement(Tile, {
      open: open,
      name: modelGroup.modelGroupName,
      description: modelGroup.modelSubTitle,
      image: modelGroup.imageDomain,
      config: config,
      onClick: function onClick() {
        onModelTileClick(modelGroup);
        if (!config.disableAutoCascade) {
          var modelGroupSelected = selectedModels.includes(modelGroup.modelGroupName);
          if (!open && !modelGroupSelected) {
            setOpen(true);
          }
          if (open && modelGroupSelected) {
            setOpen(false);
          }
        }
      },
      setOpen: setOpen,
      selectionUrl: modelSelectionUrl(),
      hasVariants: modelGroupVariants && modelGroupVariants.length > 1,
      isModel: true,
      alternativeText: modelGroup.alternativeText,
      role: 'group',
      tabIndex: '0'
    }),
    _react2.default.createElement(
      ExpandWrapper,
      {
        open: open && modelGroupVariants && modelGroupVariants.length > 1,
        borderColour: config.borderColour
      },
      _react2.default.createElement(
        _reactExpandAnimated2.default,
        { open: open, duration: 300 },
        modelGroupVariants && modelGroupVariants.length > 1 && modelGroupVariants.map(function (v) {
          return _react2.default.createElement(Tile, {
            alternativeText: v.alternativeText,
            key: v.name,
            open: open,
            name: v.name,
            description: v.description,
            image: v.image,
            config: config,
            onClick: function onClick() {
              return onVariantTileClick(modelGroup, v);
            },
            setOpen: setOpen,
            selectionUrl: selectedModels.includes(modelGroup.modelGroupName) && v.subVariants ? subVariantSelected(v) ? config.selectedUrl : config.unselectedUrl : selectedVariants.some(function (variant) {
              return variant === v.value;
            }) ? config.selectedUrl : config.unselectedUrl
          });
        })
      )
    )
  );
};