'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _path = require('ramda/src/path');

var _path2 = _interopRequireDefault(_path);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _OfferTermsPopup = require('../../../components/OfferTermsPopup');

var _OfferTermsPopup2 = _interopRequireDefault(_OfferTermsPopup);

var _Global = require('../../../components/Global');

var _numbers = require('../../../shared/localisation/numbers');

var _TableRow = require('./TableRow');

var _TableRow2 = _interopRequireDefault(_TableRow);

var _TableData = require('./TableData');

var _TableData2 = _interopRequireDefault(_TableData);

var _RenderRow = require('./RenderRow');

var _RenderRow2 = _interopRequireDefault(_RenderRow);

var _ComparedVehicleType = require('../../../types/ComparedVehicleType');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SpecialOfferDiv = _styledComponents2.default.div.withConfig({
  displayName: 'DataRow__SpecialOfferDiv',
  componentId: 'sc-qfuq8v-0'
})(['display:flex;flex-direction:row;justify-content:space-between;']);

var hasDataToDisplayInRow = function hasDataToDisplayInRow(comparedVehicles, keyValues, displayFunc) {
  return [0, 1, 2].some(function (n) {
    return keyValues ? (0, _path2.default)(keyValues, comparedVehicles[n]) : displayFunc(comparedVehicles[n]);
  });
};

var DataRow = function DataRow(_ref) {
  var label = _ref.label,
      comparedVehicles = _ref.comparedVehicles,
      disclaimerFunc = _ref.disclaimerFunc,
      keyValues = _ref.keyValues,
      font = _ref.font,
      _ref$visible = _ref.visible,
      visible = _ref$visible === undefined ? true : _ref$visible,
      displayFunc = _ref.displayFunc,
      unit = _ref.unit,
      specialOfferStyling = _ref.specialOfferStyling,
      currentLanguage = _ref.currentLanguage,
      translations = _ref.translations;

  if (!hasDataToDisplayInRow(comparedVehicles, keyValues, displayFunc)) {
    return _react2.default.createElement('tr', { style: { border: 'none' } });
  }

  return visible ? _react2.default.createElement(
    _TableRow2.default,
    { fontSize: font && font.fontSize },
    _react2.default.createElement(
      _TableData2.default,
      null,
      _react2.default.createElement(_RenderRow2.default, { content: label, font: font })
    ),
    [0, 1, 2].map(function (n) {
      return comparedVehicles[n] ? _react2.default.createElement(
        _TableData2.default,
        {
          key: '' + comparedVehicles[n].id,
          priceRow: comparedVehicles[n].price.previousValue
        },
        comparedVehicles[n].price.previousValue && specialOfferStyling ? _react2.default.createElement(
          SpecialOfferDiv,
          null,
          _react2.default.createElement(_RenderRow2.default, {
            content: keyValues ? keyValues && (0, _path2.default)(keyValues, comparedVehicles[n]) : (0, _numbers.localiseCurrency)(comparedVehicles[n].price.previousValue, currentLanguage, comparedVehicles[n].price.currency, 0) || '-',
            font: font,
            unit: unit,
            priceStyle: specialOfferStyling.priceStyle
          }),
          _react2.default.createElement(
            'div',
            { style: { display: 'flex', flexDirection: 'column' } },
            _react2.default.createElement(
              'div',
              { style: { display: 'flex', flexDirection: 'row' } },
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '\n                          ' + (0, _Global.fontStyleOverride)(specialOfferStyling.offerPriceStyle) + ';\n                          font-size: ' + (specialOfferStyling.offerPriceStyle && specialOfferStyling.offerPriceStyle.fontSize) + 'px};\n                        ';
                  }
                },
                keyValues ? keyValues && (0, _path2.default)(keyValues, comparedVehicles[n]) : displayFunc(comparedVehicles[n]) || '-'
              ),
              _react2.default.createElement(
                'div',
                { style: { paddingLeft: '6px' } },
                _react2.default.createElement(_OfferTermsPopup2.default, {
                  modalTitle: translations.offerTermModalTitle,
                  offerDetailsLinkText: translations.offerTermModalLinkText,
                  offerTermText: comparedVehicles[n].offer.termsConditions,
                  offerDetailsUrl: comparedVehicles[n].offer.detailsUrl,
                  colourValue: specialOfferStyling.offerPriceStyle.colour.value
                })
              )
            ),
            _react2.default.createElement(
              _Global.HeadingThree,
              {
                styleOverride: function styleOverride() {
                  return '\n                      ' + (0, _Global.fontStyleOverride)(specialOfferStyling.offerTextStyle) + ';\n                      padding-bottom: 5px;';
                }
              },
              translations.specialOfferPrice
            )
          )
        ) : _react2.default.createElement(_RenderRow2.default, {
          content: keyValues ? keyValues && (0, _path2.default)(keyValues, comparedVehicles[n]) : displayFunc(comparedVehicles[n]) || '-',
          font: font,
          unit: unit,
          disclaimer: disclaimerFunc && disclaimerFunc(comparedVehicles[n])
        })
      ) : _react2.default.createElement('td', { key: (0, _v2.default)() });
    })
  ) : null;
};

DataRow.defaultProps = {
  borderBottom: false
};

exports.default = DataRow;