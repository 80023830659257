'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);

exports.default = SearchResults;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _FinanceContainer = require('../../containers/FinanceContainer');

var _FinanceContainer2 = _interopRequireDefault(_FinanceContainer);

var _RepresentativeExample = require('../RepresentativeExample');

var _RepresentativeExample2 = _interopRequireDefault(_RepresentativeExample);

var _FilterMenuBar = require('./FilterMenuBar');

var _FilterMenuBar2 = _interopRequireDefault(_FilterMenuBar);

var _SortMenuBar = require('./SortMenuBar');

var _SortMenuBar2 = _interopRequireDefault(_SortMenuBar);

var _VehicleResults = require('./VehicleResults');

var _VehicleResults2 = _interopRequireDefault(_VehicleResults);

var _Global = require('../Global');

var _Spinner = require('../Spinner');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultsContainer'
}).withConfig({
  displayName: 'SearchResults__Container',
  componentId: 'sc-2wt441-0'
})(['width:95%;margin:0 2.5%;']);

var Line = _styledComponents2.default.hr.withConfig({
  displayName: 'SearchResults__Line',
  componentId: 'sc-2wt441-1'
})(['background-color:#dedede;border-bottom:none;']);

var Grid = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__Grid',
  componentId: 'sc-2wt441-2'
})(['width:100%;display:flex;flex-flow:row wrap;margin-top:50px;']);

var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__MobileOnly',
  componentId: 'sc-2wt441-3'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject));

var DesktopOnly = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResults__DesktopOnly',
  componentId: 'sc-2wt441-4'
})(['display:block;', ';'], _theme2.default.max.large(_templateObject2));

var FilterMenuBarSection = function FilterMenuBarSection(_ref) {
  var history = _ref.history,
      filtersExpanded = _ref.filtersExpanded,
      onFilterMenuClick = _ref.onFilterMenuClick,
      translations = _ref.translations,
      preview = _ref.preview,
      resetFilters = _ref.resetFilters,
      marketInfo = _ref.marketInfo,
      filtersStyling = _ref.filtersStyling,
      filterVisibility = _ref.filterVisibility,
      globalStyling = _ref.globalStyling,
      featureFlags = _ref.featureFlags,
      distanceFilterInitialSelectedIndex = _ref.distanceFilterInitialSelectedIndex,
      dropdownTheme = _ref.dropdownTheme,
      locationIconUrl = _ref.locationIconUrl;
  return _react2.default.createElement(_FilterMenuBar2.default, {
    filtersExpanded: filtersExpanded,
    onFilterMenuClick: onFilterMenuClick,
    translations: translations,
    preview: preview,
    resetFilters: resetFilters,
    config: (0, _extends3.default)({}, marketInfo, {
      locationIconUrl: locationIconUrl,
      translations: translations,
      stylingConfig: filtersStyling,
      filterVisibility: filterVisibility,
      distanceFilterInitialSelectedIndex: distanceFilterInitialSelectedIndex,
      dropdown: dropdownTheme
    }),
    globalStyling: globalStyling,
    history: history,
    featureFlags: featureFlags
  });
};

var ResultsSection = function ResultsSection(_ref2) {
  var globalStyling = _ref2.globalStyling,
      translations = _ref2.translations,
      sortOptions = _ref2.sortOptions,
      sortValue = _ref2.sortValue,
      onSortChange = _ref2.onSortChange,
      selectedView = _ref2.selectedView,
      onViewChange = _ref2.onViewChange,
      showSortByDistance = _ref2.showSortByDistance,
      filtersExpanded = _ref2.filtersExpanded,
      showSortByMileage = _ref2.showSortByMileage,
      showSortByAge = _ref2.showSortByAge,
      results = _ref2.results,
      onMoreInfoClick = _ref2.onMoreInfoClick,
      onEnquiryClick = _ref2.onEnquiryClick,
      onShowAllResultsClick = _ref2.onShowAllResultsClick,
      onPageNumberChange = _ref2.onPageNumberChange,
      compareVehicle = _ref2.compareVehicle,
      getShortListedIconColour = _ref2.getShortListedIconColour,
      shortlistVehicle = _ref2.shortlistVehicle,
      getCompareIconColour = _ref2.getCompareIconColour,
      tilesVisibility = _ref2.tilesVisibility,
      marketInfo = _ref2.marketInfo,
      vehicleModelTitleFontWeight = _ref2.vehicleModelTitleFontWeight,
      fontFamily = _ref2.fontFamily,
      headerItemFont = _ref2.headerItemFont,
      showGridTiles = _ref2.showGridTiles,
      badgeStyling = _ref2.badgeStyling,
      financeHeadingFont = _ref2.financeHeadingFont,
      infiniteScroll = _ref2.infiniteScroll,
      loading = _ref2.loading,
      currentPage = _ref2.currentPage,
      pageSize = _ref2.pageSize,
      totalResults = _ref2.totalResults,
      config = _ref2.config;
  return _react2.default.createElement(
    _Spinner.SpinnerWrapper,
    {
      loaded: infiniteScroll || !loading,
      globalStyling: globalStyling,
      label: translations.loadingText
    },
    function () {
      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(_SortMenuBar2.default, {
          sortOptions: sortOptions,
          sortValue: sortValue,
          onSortChange: onSortChange,
          selectedView: selectedView,
          onViewChange: onViewChange,
          translations: translations,
          showDistance: showSortByDistance,
          filtersExpanded: filtersExpanded,
          showMileage: showSortByMileage,
          showAge: showSortByAge,
          fontFamily: fontFamily
        }),
        _react2.default.createElement(_VehicleResults2.default, {
          results: results,
          onMoreInfoClick: onMoreInfoClick,
          onEnquiryClick: onEnquiryClick,
          translations: translations,
          onShowAllResultsClick: onShowAllResultsClick,
          onPageNumberChange: onPageNumberChange,
          selectedView: selectedView,
          filtersExpanded: filtersExpanded,
          compareVehicle: compareVehicle,
          shortlistVehicle: shortlistVehicle,
          getShortListedIconColour: getShortListedIconColour,
          getCompareIconColour: getCompareIconColour,
          globalStyling: globalStyling,
          tilesVisibility: tilesVisibility,
          locale: marketInfo.locale,
          vehicleModelTitleFontWeight: vehicleModelTitleFontWeight,
          headerItemFont: headerItemFont,
          showGridTiles: showGridTiles,
          badgeStyling: badgeStyling,
          financeHeadingFont: financeHeadingFont,
          infiniteScroll: infiniteScroll,
          loading: loading,
          currentPage: currentPage,
          pageSize: pageSize,
          totalResults: totalResults,
          showFinancePopup: true,
          iconYoutubeVideo: config.iconYoutubeVideo
        })
      );
    }
  );
};

function SearchResults(props) {
  var translations = props.translations,
      filtersExpanded = props.filtersExpanded,
      preview = props.preview,
      representativeExample = props.representativeExample,
      retailer = props.retailer,
      headingAlignment = props.headingAlignment,
      tilesVisibility = props.tilesVisibility,
      headingFont = props.headingFont,
      subHeadingFont = props.subHeadingFont,
      featureFlags = props.featureFlags,
      headingBottomLineDisable = props.headingBottomLineDisable,
      subHeadingAlignment = props.subHeadingAlignment,
      totalResults = props.totalResults;

  var singularOrPluralHeading = function singularOrPluralHeading() {
    return totalResults !== 1 ? translations.searchResultsAvailableAtLabel : translations.searchResultsAvailableAtSingleLabel;
  };
  return _react2.default.createElement(
    Container,
    { 'data-cy': 'search-results' },
    _react2.default.createElement(
      _Global.HeadingTwo,
      {
        'data-cy': 'header',
        styleOverride: function styleOverride() {
          return '\n            ' + (0, _Global.fontStyleOverride)(headingFont) + '\n            margin-top: 34px;\n            margin-bottom: 10px;\n            text-align: ' + (headingAlignment || 'center') + ';\n          ';
        }
      },
      translations.searchResultsHeader
    ),
    totalResults && _react2.default.createElement(
      _Global.Paragraph,
      {
        'data-cy': 'sub-header',
        styleOverride: function styleOverride() {
          return '\n              ' + (0, _Global.fontStyleOverride)(subHeadingFont) + '\n              margin-bottom: 35px;\n              text-align: ' + (subHeadingAlignment || headingAlignment || 'center') + ';\n            ';
        },
        mobileStyleOverride: function mobileStyleOverride() {
          return [{
            queryPath: 'max.small',
            template: 'margin-bottom: 0;'
          }];
        }
      },
      totalResults,
      retailer ? ' ' + singularOrPluralHeading() + ' (' + retailer.name + ')' : ' ' + singularOrPluralHeading()
    ),
    !(headingBottomLineDisable === true) && _react2.default.createElement(Line, null),
    featureFlags.finance && tilesVisibility.showFinanceExample && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(_FinanceContainer2.default, {
        component: function component(componentProps) {
          return _react2.default.createElement(_RepresentativeExample2.default, (0, _extends3.default)({}, componentProps, {
            representativeExample: representativeExample,
            preview: preview
          }));
        }
      }),
      _react2.default.createElement(Line, null)
    ),
    _react2.default.createElement(
      MobileOnly,
      null,
      _react2.default.createElement(ResultsSection, props)
    ),
    _react2.default.createElement(
      DesktopOnly,
      null,
      filtersExpanded ? _react2.default.createElement(
        Grid,
        null,
        _react2.default.createElement(FilterMenuBarSection, props),
        _react2.default.createElement(
          'div',
          { style: filtersExpanded && { width: 'calc(100% - 400px)' } },
          _react2.default.createElement(ResultsSection, props)
        )
      ) : _react2.default.createElement(
        Grid,
        null,
        _react2.default.createElement(FilterMenuBarSection, props),
        _react2.default.createElement(ResultsSection, props)
      )
    )
  );
}