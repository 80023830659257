'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCompiledModule2 = require('./useCompiledModule');

var _useCompiledModule3 = _interopRequireDefault(_useCompiledModule2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ModuleInstanceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CompiledModule__ModuleInstanceContainer',
  componentId: 'sc-1pn7s9j-0'
})(['height:100%;']);

var CompiledModule = function CompiledModule(_ref) {
  var band = _ref.band,
      globalStyling = _ref.globalStyling,
      index = _ref.index,
      path = _ref.path;
  var id = band.id,
      instanceId = band.instanceId,
      config = band.config;

  var _useCompiledModule = (0, _useCompiledModule3.default)(id, index, path),
      ReactComponent = _useCompiledModule.ReactComponent,
      moduleConfig = _useCompiledModule.moduleConfig,
      shared = _useCompiledModule.shared,
      moduleState = _useCompiledModule.moduleState,
      boundActions = _useCompiledModule.boundActions,
      router = _useCompiledModule.router,
      marketInfo = _useCompiledModule.marketInfo,
      vehicleInContext = _useCompiledModule.vehicleInContext,
      history = _useCompiledModule.history,
      linkedModules = _useCompiledModule.linkedModules,
      featureFlags = _useCompiledModule.featureFlags,
      finance = _useCompiledModule.finance,
      socialShare = _useCompiledModule.socialShare,
      maps = _useCompiledModule.maps,
      analytics = _useCompiledModule.analytics,
      pathByModule = _useCompiledModule.pathByModule,
      navigateIfInternal = _useCompiledModule.navigateIfInternal,
      dispatch = _useCompiledModule.dispatch,
      hashRouting = _useCompiledModule.hashRouting;

  return ReactComponent && _react2.default.createElement(
    ModuleInstanceContainer,
    {
      key: instanceId + '_' + id,
      'data-module-instance-id': instanceId
    },
    _react2.default.createElement(ReactComponent, (0, _extends3.default)({
      key: instanceId || '' + id,
      config: config,
      modInfo: moduleConfig,
      history: history,
      globalStyling: globalStyling,
      featureFlags: featureFlags,
      maps: maps,
      analytics: analytics,
      finance: finance,
      socialShare: socialShare
    }, {
      state: moduleState,
      shared: shared,
      router: router,
      vehicleInContext: vehicleInContext,
      marketInfo: marketInfo,
      pathByModule: pathByModule,
      dispatch: dispatch,
      actions: boundActions,
      linkedModules: linkedModules,
      navigateIfInternal: navigateIfInternal,
      hashRouting: hashRouting
    }))
  );
};

exports.default = CompiledModule;