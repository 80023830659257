'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CarFaxLogo = _styledComponents2.default.img.withConfig({
  displayName: 'CarfaxLogo__CarFaxLogo',
  componentId: 'sc-151p4vo-0'
})(['height:38px;margin-right:10px;']);

var carfaxImages = {
  default: 'https://res.cloudinary.com/motortrak/image/upload/v1680216433/locator/generic/carfax/showmecarfax.svg',
  'https://partnerstatic.carfax.com/img/valuebadge/1own.svg': 'https://res.cloudinary.com/motortrak/image/upload/v1680216432/locator/generic/carfax/showme_1own.svg',
  'https://partnerstatic.carfax.com/img/valuebadge/showme.svg': 'https://res.cloudinary.com/motortrak/image/upload/v1680216433/locator/generic/carfax/showmecarfax.svg'
};

var CarfaxLogo = function CarfaxLogo(_ref) {
  var _ref$carfax = _ref.carfax,
      carfax = _ref$carfax === undefined ? {
    iconUrl: 'default',
    historyReportUrl: undefined,
    oneOwner: 'N'
  } : _ref$carfax;
  return carfax.historyReportUrl ? _react2.default.createElement(
    'a',
    {
      href: carfax.historyReportUrl,
      target: '_blank',
      style: { height: '38px' }
    },
    _react2.default.createElement(CarFaxLogo, {
      alt: 'carfax logo',
      src: carfaxImages[carfax.iconUrl] || carfaxImages['default']
      //Safari Blur fix
      , style: {
        backgroundImage: 'url(' + (carfaxImages[carfax.iconUrl] || carfaxImages['default']) + '); background-size: contain; background-repeat: no-repeat; background-position: center center;'
      }
    })
  ) : null;
};

exports.default = CarfaxLogo;