'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var isoCountries = exports.isoCountries = {
  afghanistan: 'AF',
  'aland islands': 'AX',
  albania: 'AL',
  algeria: 'DZ',
  'american samoa': 'AS',
  andorra: 'AD',
  angola: 'AO',
  anguilla: 'AI',
  antarctica: 'AQ',
  'antigua and Barbuda': 'AG',
  argentina: 'AR',
  armenia: 'AM',
  aruba: 'AW',
  australia: 'AU',
  austria: 'AT',
  azerbaijan: 'AZ',
  bahamas: 'BS',
  bahrain: 'BH',
  bangladesh: 'BD',
  barbados: 'BB',
  belarus: 'BY',
  belgium: 'BE',
  belize: 'BZ',
  benin: 'BJ',
  bermuda: 'BM',
  bhutan: 'BT',
  bolivia: 'BO',
  'bosnia and Herzegovina': 'BA',
  botswana: 'BW',
  'bouvet island': 'BV',
  brazil: 'BR',
  'british indian Ocean Territory': 'IO',
  'brunei darussalam': 'BN',
  bulgaria: 'BG',
  'burkina faso': 'BF',
  burundi: 'BI',
  cambodia: 'KH',
  cameroon: 'CM',
  canada: 'CA',
  'cape verde': 'CV',
  'cayman islands': 'KY',
  'central african Republic': 'CF',
  chad: 'TD',
  chile: 'CL',
  china: 'CN',
  'christmas island': 'CX',
  'cocos (keeling) Islands': 'CC',
  colombia: 'CO',
  comoros: 'KM',
  congo: 'CG',
  'congo, Democratic Republic': 'CD',
  'cook islands': 'CK',
  'costa rica': 'CR',
  "cote d'Ivoire": 'CI',
  croatia: 'HR',
  cuba: 'CU',
  cyprus: 'CY',
  'czech republic': 'CZ',
  denmark: 'DK',
  djibouti: 'DJ',
  dominica: 'DM',
  'dominican republic': 'DO',
  ecuador: 'EC',
  egypt: 'EG',
  'el salvador': 'SV',
  'equatorial guinea': 'GQ',
  eritrea: 'ER',
  estonia: 'EE',
  ethiopia: 'ET',
  'falkland islands': 'FK',
  'faroe islands': 'FO',
  fiji: 'FJ',
  finland: 'FI',
  france: 'FR',
  'french guiana': 'GF',
  'french polynesia': 'PF',
  'french southern Territories': 'TF',
  gabon: 'GA',
  gambia: 'GM',
  georgia: 'GE',
  germany: 'DE',
  ghana: 'GH',
  gibraltar: 'GI',
  greece: 'GR',
  greenland: 'GL',
  grenada: 'GD',
  guadeloupe: 'GP',
  guam: 'GU',
  guatemala: 'GT',
  guernsey: 'GG',
  guinea: 'GN',
  'guinea-bissau': 'GW',
  guyana: 'GY',
  haiti: 'HT',
  'heard island & Mcdonald Islands': 'HM',
  'holy see (Vatican City State)': 'VA',
  honduras: 'HN',
  'hong kong': 'HK',
  hungary: 'HU',
  iceland: 'IS',
  india: 'IN',
  indonesia: 'ID',
  'iran, Islamic Republic Of': 'IR',
  iraq: 'IQ',
  ireland: 'IE',
  'isle of Man': 'IM',
  israel: 'IL',
  italy: 'IT',
  jamaica: 'JM',
  japan: 'JP',
  jersey: 'JE',
  jordan: 'JO',
  kazakhstan: 'KZ',
  kenya: 'KE',
  kiribati: 'KI',
  korea: 'KR',
  kuwait: 'KW',
  kyrgyzstan: 'KG',
  "lao people's Democratic Republic": 'LA',
  latvia: 'LV',
  lebanon: 'LB',
  lesotho: 'LS',
  liberia: 'LR',
  'libyan arab Jamahiriya': 'LY',
  liechtenstein: 'LI',
  lithuania: 'LT',
  luxembourg: 'LU',
  macao: 'MO',
  macedonia: 'MK',
  madagascar: 'MG',
  malawi: 'MW',
  malaysia: 'MY',
  maldives: 'MV',
  mali: 'ML',
  malta: 'MT',
  'marshall islands': 'MH',
  martinique: 'MQ',
  mauritania: 'MR',
  mauritius: 'MU',
  mayotte: 'YT',
  mexico: 'MX',
  'micronesia, Federated States Of': 'FM',
  moldova: 'MD',
  monaco: 'MC',
  mongolia: 'MN',
  montenegro: 'ME',
  montserrat: 'MS',
  morocco: 'MA',
  mozambique: 'MZ',
  myanmar: 'MM',
  namibia: 'NA',
  nauru: 'NR',
  nepal: 'NP',
  netherlands: 'NL',
  'netherlands antilles': 'AN',
  'new caledonia': 'NC',
  'new zealand': 'NZ',
  nicaragua: 'NI',
  niger: 'NE',
  nigeria: 'NG',
  niue: 'NU',
  'norfolk island': 'NF',
  'northern mariana Islands': 'MP',
  norway: 'NO',
  oman: 'OM',
  pakistan: 'PK',
  palau: 'PW',
  'palestinian territory, Occupied': 'PS',
  panama: 'PA',
  'papua new Guinea': 'PG',
  paraguay: 'PY',
  peru: 'PE',
  philippines: 'PH',
  pitcairn: 'PN',
  poland: 'PL',
  portugal: 'PT',
  'puerto rico': 'PR',
  qatar: 'QA',
  reunion: 'RE',
  romania: 'RO',
  'russian federation': 'RU',
  rwanda: 'RW',
  'saint barthelemy': 'BL',
  'saint helena': 'SH',
  'saint kitts And Nevis': 'KN',
  'saint lucia': 'LC',
  'saint martin': 'MF',
  'saint pierre And Miquelon': 'PM',
  'saint vincent And Grenadines': 'VC',
  samoa: 'WS',
  'san marino': 'SM',
  'sao tome And Principe': 'ST',
  'saudi arabia': 'SA',
  senegal: 'SN',
  serbia: 'RS',
  seychelles: 'SC',
  'sierra leone': 'SL',
  singapore: 'SG',
  slovakia: 'SK',
  slovenia: 'SI',
  'solomon islands': 'SB',
  somalia: 'SO',
  'south africa': 'ZA',
  'south georgia And Sandwich Isl.': 'GS',
  spain: 'ES',
  'sri lanka': 'LK',
  sudan: 'SD',
  suriname: 'SR',
  'svalbard and Jan Mayen': 'SJ',
  swaziland: 'SZ',
  sweden: 'SE',
  switzerland: 'CH',
  'syrian arab Republic': 'SY',
  taiwan: 'TW',
  tajikistan: 'TJ',
  tanzania: 'TZ',
  thailand: 'TH',
  'timor-leste': 'TL',
  togo: 'TG',
  tokelau: 'TK',
  tonga: 'TO',
  'trinidad and Tobago': 'TT',
  tunisia: 'TN',
  turkey: 'TR',
  turkmenistan: 'TM',
  'turks and Caicos Islands': 'TC',
  tuvalu: 'TV',
  uganda: 'UG',
  ukraine: 'UA',
  'united arab Emirates': 'AE',
  'united kingdom': 'GB',
  'united states': 'US',
  'united states Outlying Islands': 'UM',
  uruguay: 'UY',
  uzbekistan: 'UZ',
  vanuatu: 'VU',
  venezuela: 'VE',
  vietnam: 'VN',
  'virgin islands, British': 'VG',
  'virgin islands, U.S.': 'VI',
  'wallis and Futuna': 'WF',
  'western sahara': 'EH',
  yemen: 'YE',
  zambia: 'ZM',
  zimbabwe: 'ZW'
};

exports.default = isoCountries;