'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _emissions = require('../helpers/emissions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultEmissions__Container',
  componentId: 'sc-xh1euh-0'
})(['padding:10px 0;font-size:12px;color:#545454;width:100%;@media (min-width:1400px) and (max-width:1600px){font-size:11px;padding:0;}']);


var Row = _styledComponents2.default.div.withConfig({
  displayName: 'SearchResultEmissions__Row',
  componentId: 'sc-xh1euh-1'
})(['display:flex;width:100%;margin:5px 0;div{display:flex;justify-content:end;text-align:right;flex-grow:1;&:first-child{text-align:left;justify-content:start;margin-right:10px;}}']);

var SearchResultEmissions = function SearchResultEmissions(props) {
  var emissionsData = props.emissionsData,
      consumptionsData = props.consumptionsData,
      efficiency = props.efficiency,
      translations = props.translations;
  var consumptionCombined = translations.consumptionCombined,
      co2EmissionsCombined = translations.co2EmissionsCombined,
      energyEfficiencyClass = translations.energyEfficiencyClass;

  // Saves a formatted string for CO-2 Emissions Combined field.

  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      emissionsCombinedText = _useState2[0],
      setEmissionsCombinedText = _useState2[1];
  // Saves a formatted string for Combined Consumption field.


  var _useState3 = (0, _react.useState)(null),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      consumptionsCombinedText = _useState4[0],
      setCnsumptionsCombinedText = _useState4[1];

  (0, _react.useEffect)(function () {
    var nedcEmissionsCombined = emissionsData.nedcEmissionsCombined,
        wltpEmissionsCombined = emissionsData.wltpEmissionsCombined;

    setEmissionsCombinedText((0, _emissions.formatCombinedValues)(nedcEmissionsCombined, wltpEmissionsCombined));
  }, [emissionsData]);

  (0, _react.useEffect)(function () {
    var nedcConsumptionCombined = consumptionsData.nedcConsumptionCombined,
        wltpConsumptionCombined = consumptionsData.wltpConsumptionCombined;

    setCnsumptionsCombinedText((0, _emissions.formatCombinedValues)(nedcConsumptionCombined, wltpConsumptionCombined));
  }, [consumptionsData]);

  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      Row,
      null,
      _react2.default.createElement(
        'div',
        null,
        consumptionCombined
      ),
      _react2.default.createElement(
        'div',
        null,
        consumptionsCombinedText
      )
    ),
    _react2.default.createElement(
      Row,
      null,
      _react2.default.createElement(
        'div',
        null,
        co2EmissionsCombined
      ),
      _react2.default.createElement(
        'div',
        null,
        emissionsCombinedText
      )
    ),
    _react2.default.createElement(
      Row,
      null,
      _react2.default.createElement(
        'div',
        null,
        energyEfficiencyClass
      ),
      _react2.default.createElement(
        'div',
        null,
        efficiency
      )
    )
  );
};

exports.default = SearchResultEmissions;