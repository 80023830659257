'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 50px 20px;\n  '], ['\n    padding: 50px 20px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n  '], ['\n    flex-direction: column;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 0;\n  '], ['\n    margin-bottom: 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    height: 300px;\n  '], ['\n    height: 300px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0px;\n  '], ['\n    margin: 0px;\n  ']); /* eslint-env browser */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _GoogleMap = require('../GoogleMap');

var _GoogleMap2 = _interopRequireDefault(_GoogleMap);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _RetailerInformation = require('../../modules/RetailerInformation/RetailerInformation');

var _RetailerInfo = require('./RetailerInfo');

var _RetailerInfo2 = _interopRequireDefault(_RetailerInfo);

var _vehicleTypes = require('../../types/Bentley/vehicleTypes');

var _vehicleDetailsPageTypes = require('../../types/Bentley/vehicleDetailsPageTypes');

var _Location = require('../../types/Location');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__Container',
  componentId: 'sc-p8zt38-0'
})(['display:flex;padding:35px 0;width:100%;flex-direction:column;align-items:center;', ''], _theme2.default.min.large(_templateObject));

var RetailerContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__RetailerContainer',
  componentId: 'sc-p8zt38-1'
})(['display:flex;gap:20px;margin-bottom:40px;width:100%;justify-content:space-between;max-width:1200px;', ';', ';'], _theme2.default.max.medium(_templateObject2), _theme2.default.max.small(_templateObject3));

var RetailerInfoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__RetailerInfoContainer',
  componentId: 'sc-p8zt38-2'
})(['margin:20px;']);

var MapContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__MapContainer',
  componentId: 'sc-p8zt38-3'
})(['flex:auto;margin:20px;', ';', ';'], _theme2.default.max.medium(_templateObject4), _theme2.default.max.small(_templateObject5));
var GoogleMapStatic = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__GoogleMapStatic',
  componentId: 'sc-p8zt38-4'
})(['width:100%;height:100%;background-repeat:no-repeat;background-position:center;line-height:0;', ';'], function (_ref) {
  var url = _ref.url;
  return url && 'background-image: url(' + url + ')';
});

var SectionHeader = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__SectionHeader',
  componentId: 'sc-p8zt38-5'
})(['font-family:Bentley-Regular;font-size:24px;color:#222;display:block;text-align:center;text-transform:uppercase;', ';margin-bottom:15px;'], _theme2.default.max.small(_templateObject3));

var VdpRetailerMap = function VdpRetailerMap(_ref2) {
  var config = _ref2.config,
      retailer = _ref2.retailer,
      translations = _ref2.translations,
      viewRetailerStock = _ref2.viewRetailerStock,
      googleAvailable = _ref2.googleAvailable,
      vehicleInfo = _ref2.vehicleInfo,
      makeAnEnquiry = _ref2.makeAnEnquiry;

  var flipRetailerCoordinates = function flipRetailerCoordinates() {
    if (config.flipRetailerCoordinates && retailer.location && retailer.location.coordinates && retailer.location.coordinates.length === 2) {
      return {
        coordinates: [retailer.location.coordinates[1], retailer.location.coordinates[0]]
      };
    }
    return retailer.location;
  };

  return _react2.default.createElement(
    Container,
    { id: 'retailerMap' },
    _react2.default.createElement(
      SectionHeader,
      null,
      translations.dealerInfo
    ),
    _react2.default.createElement(
      RetailerContainer,
      null,
      _react2.default.createElement(
        RetailerInfoContainer,
        null,
        _react2.default.createElement(_RetailerInfo2.default, {
          config: config,
          retailer: retailer,
          translations: translations,
          vehicle: vehicleInfo,
          viewRetailerStock: viewRetailerStock,
          makeAnEnquiry: makeAnEnquiry
        })
      ),
      _react2.default.createElement(
        MapContainer,
        null,
        config.map.provider === 'google' ? config.map.type === 'dynamic' && googleAvailable ? _react2.default.createElement(_GoogleMap2.default, {
          mapName: 'vdpMap',
          retailerLocation: flipRetailerCoordinates()
        }) : _react2.default.createElement(GoogleMapStatic, { url: retailer.dealerLocationMapUrl }) : null
      )
    )
  );
};

exports.default = VdpRetailerMap;