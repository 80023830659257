'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Location = require('../types/Location');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-new, no-unused-vars */

/* eslint-env browser */
var GoogleMap = function (_React$Component) {
  (0, _inherits3.default)(GoogleMap, _React$Component);

  function GoogleMap() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, GoogleMap);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = GoogleMap.__proto__ || (0, _getPrototypeOf2.default)(GoogleMap)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'updateMap', {
      enumerable: true,
      writable: true,
      value: function value(_ref2) {
        var _ref3 = (0, _slicedToArray3.default)(_ref2, 2),
            lat = _ref3[0],
            long = _ref3[1];

        var map = new window.google.maps.Map(_this.googleMap, {
          zoom: 17,
          center: {
            lat: lat,
            lng: long
          }
        });

        var marker = new window.google.maps.Marker({
          position: {
            lat: lat,
            lng: long
          },
          map: map,
          icon: _this.props.iconPin
        });
      }
    }), Object.defineProperty(_this, 'googleMap', {
      enumerable: true,
      writable: true,
      value: null
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(GoogleMap, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.updateMap(this.props.retailerLocation.coordinates);
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (prevProps && prevProps.retailerLocation.coordinates !== this.props.retailerLocation.coordinates) {
        this.updateMap(this.props.retailerLocation.coordinates);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement('div', {
        'data-cy': 'google-map',
        id: this.props.mapName,
        style: { height: '100%' },
        ref: function ref(map) {
          _this2.googleMap = map;
        }
      });
    }
  }]);
  return GoogleMap;
}(_react2.default.Component);

exports.default = GoogleMap;