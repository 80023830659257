'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _Global = require('../../Global');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitle__Container',
  componentId: 'sc-1tk4req-0'
})(['display:flex;justify-content:space-between;margin-bottom:10px;gap:10px;']);

var PriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleTitle__PriceContainer',
  componentId: 'sc-1tk4req-1'
})(['text-align:right;']);

var VehicleTitle = function VehicleTitle(_ref) {
  var vehicleName = _ref.vehicleName,
      approved = _ref.approved,
      onClick = _ref.onClick,
      price = _ref.price,
      hidePrice = _ref.hidePrice,
      hideYear = _ref.hideYear;

  var mobile = (0, _useCheckIsMobileScreen2.default)('medium');
  var tablet = (0, _useCheckIsMobileScreen2.default)('large');
  var parsedVehicleName = hideYear ? vehicleName.substring(5) : vehicleName;

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        'div',
        { onClick: onClick },
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n              font-family: GenesisSansHeadGlobal-Regular;\n              font-size: 24px;\n              font-weight: normal;\n              line-height: 36px;\n              color: #fff;\n              text-transform: none;\n              cursor: pointer;\n              &:hover {text-decoration: underline;\n                text-underline-offset: 9px;}\n            ';
            }
          },
          parsedVehicleName
        )
      ),
      _react2.default.createElement(
        'div',
        {
          style: {
            flex: approved && tablet && !mobile ? 1 : undefined,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: mobile ? 'column' : 'row',
            gap: 4
          }
        },
        !tablet && !mobile && !hidePrice && _react2.default.createElement(
          PriceContainer,
          null,
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return '\n              display:flex;\n              flex-direction: column;\n              font-family: GenesisSansHeadGlobal-Regular;\n              font-style: normal;\n              font-size: 24px;\n              color: #fff;\n            ';
              }
            },
            price
          )
        )
      )
    )
  );
};

exports.default = VehicleTitle;