'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FeatureInventoryTemplate = require('./Templates/FeatureInventoryTemplate');

var _FeatureInventoryTemplate2 = _interopRequireDefault(_FeatureInventoryTemplate);

var _useFeatureInventoryRange = require('./useFeatureInventoryRange');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var templates = {
  FeatureInventoryTemplate: _FeatureInventoryTemplate2.default
};

var FeatureInventoryRangeWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FeatureInventoryRange__FeatureInventoryRangeWrapper',
  componentId: 'sc-1xidnpy-0'
})(['width:100%;display:flex;flex-flow:row wrap;']);

exports.default = function (_ref) {
  var config = _ref.config;

  var postData = (0, _useFeatureInventoryRange.useFeatureInventoryRange)();
  var Template = templates[config.template];
  return _react2.default.createElement(
    FeatureInventoryRangeWrapper,
    null,
    _react2.default.createElement(Template, { config: config, postData: postData })
  );
};