'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _components = require('../../components');

var _Global = require('../../components/Global');

var _currencyConversion = require('../../../shared/currencyConversion');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Default__Wrapper',
  componentId: 'sc-1yuolrn-0'
})(['border:1px solid #d5d5d5;max-width:250px;display:flex;flex-direction:column;margin:10px;']);

var TopSection = _styledComponents2.default.div.withConfig({
  displayName: 'Default__TopSection',
  componentId: 'sc-1yuolrn-1'
})(['padding:15px;flex:1;display:flex;flex-direction:column;justify-content:center;border-bottom:1px solid #d5d5d5;']);

var ImageSection = _styledComponents2.default.div.withConfig({
  displayName: 'Default__ImageSection',
  componentId: 'sc-1yuolrn-2'
})(['display:flex;width:100%;border-bottom:1px solid #d5d5d5;']);

var ApprovedImage = _styledComponents2.default.img.withConfig({
  displayName: 'Default__ApprovedImage',
  componentId: 'sc-1yuolrn-3'
})(['position:absolute;width:150px;height:35px;']);

var PriceSection = _styledComponents2.default.div.withConfig({
  displayName: 'Default__PriceSection',
  componentId: 'sc-1yuolrn-4'
})(['display:flex;flex-direction:row;align-items:stretch;border-bottom:1px solid #d5d5d5;justify-content:space-between;']);

var PriceWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Default__PriceWrapper',
  componentId: 'sc-1yuolrn-5'
})(['', ''], function (_ref) {
  var direction = _ref.direction;
  return '\n    text-align: ' + (direction === 'rtl' ? 'right' : 'left') + '\n    padding: ' + (direction === 'rtl' ? '15px 10px 15px 0px' : '15px 0px 15px 10px') + '\n  ';
});

var MileageWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Default__MileageWrapper',
  componentId: 'sc-1yuolrn-6'
})(['', ''], function (_ref2) {
  var direction = _ref2.direction;
  return '\n    text-align: ' + (direction === 'rtl' ? 'left' : 'right') + '\n    padding: ' + (direction === 'rtl' ? '15px 0px 15px 10px' : '15px 10px 15px 0px') + '\n  ';
});

var ButtonSection = _styledComponents2.default.div.withConfig({
  displayName: 'Default__ButtonSection',
  componentId: 'sc-1yuolrn-7'
})(['display:flex;flex-direction:row;justify-content:space-between;']);

var ButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Default__ButtonWrapper',
  componentId: 'sc-1yuolrn-8'
})(['display:flex;justify-content:space-between;align-items:center;margin:15px 10px;']);

var SingleButton = _styledComponents2.default.div.withConfig({
  displayName: 'Default__SingleButton',
  componentId: 'sc-1yuolrn-9'
})(['min-width:26px;min-height:26px;margin:0 5px;cursor:pointer;']);

exports.default = function (_ref3) {
  var translations = _ref3.translations,
      _ref3$config = _ref3.config,
      approvedImage = _ref3$config.approvedImage,
      compareIconOff = _ref3$config.compareIconOff,
      shortlistIconOff = _ref3$config.shortlistIconOff,
      headingFont = _ref3$config.headingFont,
      bodyFont = _ref3$config.bodyFont,
      subtitleData = _ref3.subtitleData,
      globalStyling = _ref3.globalStyling,
      uiElements = _ref3.globalStyling.uiElements,
      vehicle = _ref3.vehicle,
      _ref3$vehicle = _ref3.vehicle,
      id = _ref3$vehicle.id,
      approved = _ref3$vehicle.approved,
      name = _ref3$vehicle.name,
      _ref3$vehicle$specifi = _ref3$vehicle.specification.odometer,
      units = _ref3$vehicle$specifi.units,
      reading = _ref3$vehicle$specifi.reading,
      images = _ref3$vehicle.images,
      price = _ref3$vehicle.price,
      priceNoDigits = _ref3.priceNoDigits,
      mileageNoDigits = _ref3.mileageNoDigits,
      generateSubTitle = _ref3.generateSubTitle,
      compareVehicle = _ref3.compareVehicle,
      shortlistVehicle = _ref3.shortlistVehicle,
      onMoreInfoClick = _ref3.onMoreInfoClick;

  var dispatch = (0, _reactRedux.useDispatch)();
  (0, _react.useEffect)(function () {
    dispatch(_currencyConversion.actions.getExchangeRates(price.currency));
  }, []);

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      rates: state.shared.currencyConversion.exchangeRates && state.shared.currencyConversion.exchangeRates[vehicle.price.currency] && state.shared.currencyConversion.exchangeRates[vehicle.price.currency].rates
    };
  }),
      rates = _useSelector.rates;

  var shortListedIconOption = function shortListedIconOption() {
    return shortlistIconOff && shortlistIconOff;
  };

  var compareIconOption = function compareIconOption() {
    return compareIconOff && compareIconOff;
  };

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      TopSection,
      null,
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n            ' + (0, _Global.fontStyleOverride)(headingFont) + '\n          ';
          }
        },
        name
      ),
      _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return '\n            ' + (0, _Global.fontStyleOverride)(bodyFont) + '\n            padding-top: 4px;\n          ';
          }
        },
        generateSubTitle(subtitleData, vehicle)
      )
    ),
    _react2.default.createElement(
      ImageSection,
      null,
      approved && _react2.default.createElement(ApprovedImage, { src: approvedImage, alt: translations.imageLabel }),
      _react2.default.createElement(_components.Image, { src: images[0], imageHeight: '150', alt: '' })
    ),
    _react2.default.createElement(
      PriceSection,
      null,
      _react2.default.createElement(
        PriceWrapper,
        { direction: globalStyling.direction },
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(headingFont) + '\n            ';
            }
          },
          priceNoDigits(price.value, rates, price.currency)
        ),
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(bodyFont) + '\n            ';
            }
          },
          translations.priceText
        )
      ),
      _react2.default.createElement(
        MileageWrapper,
        { direction: globalStyling.direction },
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(headingFont) + '\n            ';
            }
          },
          mileageNoDigits(reading),
          ' ',
          units
        ),
        _react2.default.createElement(
          _Global.HeadingThree,
          {
            styleOverride: function styleOverride() {
              return '\n              ' + (0, _Global.fontStyleOverride)(bodyFont) + '\n            ';
            }
          },
          translations.mileage
        )
      )
    ),
    _react2.default.createElement(
      ButtonSection,
      null,
      _react2.default.createElement(
        ButtonWrapper,
        { alignment: 'left' },
        _react2.default.createElement(
          SingleButton,
          { onClick: function onClick() {
              return compareVehicle(vehicle);
            } },
          _react2.default.createElement(_components.Image, { src: compareIconOption, imageHeight: '26', alt: '' })
        ),
        _react2.default.createElement(
          SingleButton,
          { onClick: function onClick() {
              return shortlistVehicle(vehicle);
            } },
          _react2.default.createElement(_components.Image, { src: shortListedIconOption, imageHeight: '26', alt: '' })
        )
      ),
      _react2.default.createElement(
        ButtonWrapper,
        { aligment: 'right' },
        _react2.default.createElement(_Global.Button, {
          buttonStyle: uiElements.primaryButton.buttonStyle,
          applyStyle: 'primary',
          text: translations.view,
          onClick: function onClick() {
            return onMoreInfoClick(id);
          },
          styleOverride: function styleOverride() {
            return '\n              height: auto;\n            ';
          }
        })
      )
    )
  );
};