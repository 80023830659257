'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Whatsapp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Whatsapp(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      {
        viewBox: '0 0 24 24',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
        width: '1em',
        height: '1em'
      },
      _react2.default.createElement('path', { d: 'M17.498 14.382c-.298-.149-1.766-.867-2.04-.967-.274-.099-.473-.148-.672.149-.199.298-.771.967-.945 1.165-.174.198-.349.223-.647.075-.299-.15-1.26-.463-2.401-1.475-.888-.788-1.487-1.761-1.661-2.059-.175-.297-.019-.458.13-.606.135-.133.3-.347.448-.52.15-.174.2-.298.299-.497.1-.198.05-.372-.025-.52-.075-.149-.672-1.612-.92-2.207-.243-.579-.489-.5-.672-.51-.174-.008-.374-.01-.573-.01a1.1 1.1 0 0 0-.796.372c-.273.297-1.045 1.016-1.045 2.479 0 1.462 1.07 2.875 1.22 3.074.149.198 2.105 3.2 5.1 4.487.713.306 1.269.489 1.702.625.716.227 1.366.195 1.88.118.575-.085 1.767-.719 2.016-1.413.25-.694.25-1.289.174-1.413-.074-.124-.273-.198-.572-.347m-5.448 7.403h-.004a9.953 9.953 0 0 1-5.055-1.378l-.363-.214-3.759.982 1.003-3.648-.236-.374a9.826 9.826 0 0 1-1.518-5.26c.002-5.45 4.458-9.884 9.936-9.884a9.889 9.889 0 0 1 7.022 2.898 9.802 9.802 0 0 1 2.906 6.993c-.002 5.45-4.457 9.885-9.932 9.885m8.453-18.297A11.9 11.9 0 0 0 12.05 0C5.463 0 .103 5.335.1 11.892c0 2.096.55 4.142 1.595 5.945L0 24l6.335-1.654a11.985 11.985 0 0 0 5.71 1.448h.005c6.586 0 11.947-5.335 11.95-11.893a11.793 11.793 0 0 0-3.497-8.413' })
    )
  );
}

Whatsapp.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};