'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.alignDetails = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    height: 100%;\n  '], ['\n    display: flex;\n    height: 100%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    /* Safary bug: when the flex parent doesn\'t have enough height the children\'s height shrinks and move inside the parent, min-height avoids the height shrinking */\n    min-height: 40px;\n    height: 40px;\n  '], ['\n    /* Safary bug: when the flex parent doesn\'t have enough height the children\'s height shrinks and move inside the parent, min-height avoids the height shrinking */\n    min-height: 40px;\n    height: 40px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n  '], ['\n    display: flex;\n  ']);

var _history = require('history');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _router = require('../../../actions/router');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ContentWrapper = require('../../ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _Global = require('../../Global');

var _types = require('./types');

var _AppState = require('../../../types/AppState');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SubNavBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__SubNavBarContainer',
  componentId: 'sc-lt1t3x-0'
})(['display:flex;', ';@media print{display:none;}'], _theme2.default.max.large(_templateObject));

var SubNavLink = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__SubNavLink',
  componentId: 'sc-lt1t3x-1'
})(['display:flex;padding:0 18px;align-items:center;', ';'], _theme2.default.max.large(_templateObject2));

var LinksContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__LinksContainer',
  componentId: 'sc-lt1t3x-2'
})(['display:flex;flex:1 0 0%;']);

var Button = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__Button',
  componentId: 'sc-lt1t3x-3'
})(['display:flex;align-items:center;cursor:pointer;padding:0 24px;', ';'], _theme2.default.max.medium(_templateObject3));

var DesktopLinks = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__DesktopLinks',
  componentId: 'sc-lt1t3x-4'
})(['display:flex;width:100%;overflow:hidden;', ';'], _theme2.default.max.medium(_templateObject4));
var MobileLinks = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__MobileLinks',
  componentId: 'sc-lt1t3x-5'
})(['display:none;overflow:hidden;', ';'], _theme2.default.max.medium(_templateObject5));

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__Content',
  componentId: 'sc-lt1t3x-6'
})(['box-sizing:border-box;display:flex;width:100%;background-color:#ffffff;padding-left:30px;']);

var alignDetails = exports.alignDetails = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

var AvailableLinks = function AvailableLinks(_ref) {
  var links = _ref.links;

  var dispatch = (0, _reactRedux.useDispatch)();
  var shared = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  });
  var vehicleCounters = {
    compare: shared.compare.vehicles.length,
    shortlist: shared.shortlist.vehicles.length
  };

  return _react2.default.createElement(
    LinksContainer,
    null,
    links && links.map(function (link, index) {
      return _react2.default.createElement(
        SubNavLink,
        {
          key: index.toString(),
          onClick: function onClick() {
            return dispatch(_router.actions.navigate(link.path));
          }
        },
        _react2.default.createElement(
          _Global.Paragraph,
          {
            styleOverride: function styleOverride() {
              return '\n                cursor: pointer;\n                width: 100%;\n              ';
            },
            mobileStyleOverride: function mobileStyleOverride() {
              return [{
                queryPath: 'max.large',
                template: 'text-align: center;'
              }];
            }
          },
          link.translations.linkText + ' ' + (vehicleCounters[link.key] ? '(' + vehicleCounters[link.key] + ')' : '')
        )
      );
    })
  );
};

var SubNavBar = function SubNavBar(_ref2) {
  var config = _ref2.config,
      history = _ref2.history;

  var links = config.items;
  var mobileLinks = links && links.filter(function (f) {
    return f.path !== '/compare';
  });

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences;
  }),
      language = _useSelector.language;

  var path = !config.goBackPathname ? undefined : config.goBackPathname === '/' ? '/' : '/' + language + config.goBackPathname;

  return _react2.default.createElement(
    SubNavBarContainer,
    null,
    _react2.default.createElement(
      _ContentWrapper2.default,
      null,
      _react2.default.createElement(
        Content,
        null,
        !config.hideBackButton && _react2.default.createElement(
          Button,
          {
            onClick: function onClick() {
              path ? history.push(path) : history.goBack();
            }
          },
          _react2.default.createElement('img', {
            src: config.goBackIconUrl,
            alt: '',
            width: '12px',
            height: '12px',
            style: config.items.length === 0 ? { marginBottom: '14px' } : {}
          })
        ),
        _react2.default.createElement(
          DesktopLinks,
          null,
          _react2.default.createElement(AvailableLinks, { links: links })
        ),
        _react2.default.createElement(
          MobileLinks,
          null,
          _react2.default.createElement(AvailableLinks, { links: mobileLinks })
        )
      )
    )
  );
};

exports.default = SubNavBar;