'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactPopper = require('react-popper');

var _core = require('@popperjs/core');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PopperContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Tooltip__PopperContainer',
  componentId: 'sc-bx9msk-0'
})(['box-shadow:0 0 5px rgba(0,0,0,0.3);border-radius:5px;background-color:#333;padding:10px;text-align:center;z-index:9999;color:#fff;font-size:0.875rem;text-transform:none;white-space:nowrap;', ' ', ' ', ' #arrow{position:absolute;width:10px;height:10px;&:after{content:\' \';background-color:#333;box-shadow:-1px -1px 1px rgba(0,0,0,0.1);position:absolute;top:-15px;left:0;transform:rotate(45deg);width:10px;height:10px;}}&[data-popper-placement^=\'top\'] > #arrow{bottom:-20px;transform:rotate(45deg);:after{box-shadow:1px 1px 1px rgba(0,0,0,0.1);}}&[data-popper-placement^=\'left\'] > #arrow{right:-5px;:after{top:0;transform:rotate(-45deg);box-shadow:1px 1px 1px rgba(0,0,0,0.1);}}&[data-popper-placement^=\'right\'] > #arrow{left:-5px;:after{top:0;transform:rotate(135deg);box-shadow:1px 1px 1px rgba(0,0,0,0.1);}}'], function (_ref) {
  var fontFamily = _ref.fontFamily;
  return fontFamily && 'font-family: ' + fontFamily + '; ';
}, function (_ref2) {
  var letterSpacing = _ref2.letterSpacing;
  return letterSpacing && 'letter-spacing: ' + letterSpacing + '; ';
}, function (_ref3) {
  var fontSize = _ref3.fontSize;
  return fontSize && 'font-size: ' + fontSize + '; ';
});

var Tooltip = function Tooltip(_ref4) {
  var text = _ref4.text,
      placement = _ref4.placement,
      children = _ref4.children,
      font = _ref4.font;

  var referenceElement = (0, _react.useRef)(null);
  var popperRef = (0, _react.useRef)(null);

  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      arrowRef = _useState2[0],
      setArrowRef = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      isHovered = _useState4[0],
      setIsHovered = _useState4[1];

  var _usePopper = (0, _reactPopper.usePopper)(referenceElement.current, popperRef.current, {
    placement: placement,
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 10]
      }
    }, {
      name: 'arrow',
      enabled: true,
      options: {
        element: arrowRef,
        padding: 5
      }
    }]
  }),
      styles = _usePopper.styles,
      attributes = _usePopper.attributes;

  var handleMouseEnter = function handleMouseEnter() {
    setIsHovered(true);
  };

  var handleMouseLeave = function handleMouseLeave() {
    setIsHovered(false);
  };

  return _react2.default.createElement(
    'div',
    { style: { position: 'relative' } },
    _react2.default.createElement(
      'div',
      {
        ref: referenceElement,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
      },
      children
    ),
    isHovered && referenceElement && _react2.default.createElement(
      PopperContainer,
      (0, _extends3.default)({}, font, {
        ref: popperRef,
        style: styles.popper
      }, attributes.popper),
      _react2.default.createElement('div', { ref: setArrowRef, style: styles.arrow, id: 'arrow' }),
      _react2.default.createElement(
        'p',
        null,
        text
      )
    )
  );
};

exports.default = Tooltip;