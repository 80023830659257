'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Genesis = require('../../../customThemes/Genesis');

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _numbers = require('../../../shared/localisation/numbers');

var _vehicleTypes = require('../../../types/Genesis/vehicleTypes');

var _types = require('./types');

var _ipInformation = require('../../../shared/localisation/ipInformation');

var _VehicleInfoBanner = require('./VehicleInfoBanner');

var _VehicleInfoBanner2 = _interopRequireDefault(_VehicleInfoBanner);

var _VehicleInformation = require('./VehicleInformation/');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__Wrapper',
  componentId: 'sc-2axkt4-0'
})(['margin:0 auto;width:100%;max-width:1280px;', ';'], function () {
  return _Genesis.mixins.pageGuttering();
});

var VehicleDetailsPage = function VehicleDetailsPage(_ref) {
  var config = _ref.config,
      locale = _ref.locale,
      placeholdingImage = _ref.placeholdingImage,
      makeAnEnquiry = _ref.makeAnEnquiry,
      translations = _ref.translations,
      vehicle = _ref.vehicle,
      printVehiclePdf = _ref.printVehiclePdf,
      socialShare = _ref.socialShare;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      showStickyInfoBanner = _useState2[0],
      setShowStickyInfoBanner = _useState2[1];

  var countryCode = (0, _ipInformation.getIpCountryCode)();
  var userLocationGermany = countryCode === 'DE';
  var localisedRetailPrice = !!vehicle.price.value ? (0, _numbers.localiseCurrency)(vehicle.price.originalValue || vehicle.price.value, locale, vehicle.price.originalCurrency || vehicle.price.currency, 0) : null;

  var priceSubjectToVat = !!vehicle.priceLessTaxes ? (0, _numbers.localiseCurrency)(vehicle.priceLessTaxes, locale, vehicle.price.currency, 0) : null;

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(_reactWaypoint2.default, {
      onPositionChange: function onPositionChange(_ref2) {
        var currentPosition = _ref2.currentPosition;

        setShowStickyInfoBanner(currentPosition === _reactWaypoint2.default.above);
      }
    }),
    showStickyInfoBanner && _react2.default.createElement(_VehicleInfoBanner2.default, {
      vehicleDetails: vehicle,
      config: config,
      placeholdingImage: placeholdingImage,
      makeAnEnquiry: makeAnEnquiry,
      translations: translations
    }),
    _react2.default.createElement(_VehicleInformation.VehicleTitleInfo, {
      vehicle: vehicle,
      translations: translations,
      localisedRetailPrice: localisedRetailPrice,
      priceSubjectToVat: priceSubjectToVat,
      config: config,
      makeAnEnquiry: makeAnEnquiry,
      printVehiclePdf: printVehiclePdf,
      socialShare: socialShare
    }),
    _react2.default.createElement(_VehicleInformation.VehicleGeneralInformation, {
      vehicle: vehicle,
      translations: translations,
      config: config,
      locale: locale
    }),
    _react2.default.createElement(_VehicleInformation.Emissions, {
      vehicle: vehicle,
      translations: translations,
      config: config,
      userLocationGermany: userLocationGermany
    }),
    _react2.default.createElement(_VehicleInformation.VehicleFeatures, {
      vehicle: vehicle,
      translations: translations,
      config: config
    }),
    _react2.default.createElement(_VehicleInformation.AdditionalComments, {
      vehicle: vehicle,
      translations: translations,
      config: config
    })
  );
};

exports.default = VehicleDetailsPage;