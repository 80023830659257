'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    border: none;\n    margin: 0;\n  '], ['\n    width: 100%;\n    border: none;\n    margin: 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 60%;\n    display: block;\n  '], ['\n    width: 60%;\n    display: block;\n  ']);

exports.default = ConfirmationWindow;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _CircleTick = require('./JaguarIcons/Global/CircleTick');

var _CircleTick2 = _interopRequireDefault(_CircleTick);

var _Global = require('./Global');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ConfirmationWindow__Container',
  componentId: 'sc-ten0n0-0'
})(['width:40%;height:auto;margin:5% 30%;border:1px solid #dedede;padding:30px;', ';'], _theme2.default.max.large(_templateObject));
var Image = _styledComponents2.default.div.withConfig({
  displayName: 'ConfirmationWindow__Image',
  componentId: 'sc-ten0n0-1'
})(['display:flex;justify-content:center;']);
var Header = _styledComponents2.default.div.withConfig({
  displayName: 'ConfirmationWindow__Header',
  componentId: 'sc-ten0n0-2'
})(['text-align:center;font-size:20px;color:#000;font-weight:600;line-height:24px;margin-bottom:10px;']);
var Content = _styledComponents2.default.div.withConfig({
  displayName: 'ConfirmationWindow__Content',
  componentId: 'sc-ten0n0-3'
})(['color:#444444;text-align:center;font-size:16px;line-height:25px;width:100%;white-space:pre-line;margin:0 auto;']);
var ActionButton = _styledComponents2.default.div.withConfig({
  displayName: 'ConfirmationWindow__ActionButton',
  componentId: 'sc-ten0n0-4'
})(['display:flex;width:100%;margin:30px auto 0 auto;', ';'], _theme2.default.max.large(_templateObject2));

var confirmationButtonStyle = function confirmationButtonStyle() {
  return '\n  width: 100%;\n  margin: 10px;\n';
};

var BoutiqueText = _styledComponents2.default.div.withConfig({
  displayName: 'ConfirmationWindow__BoutiqueText',
  componentId: 'sc-ten0n0-5'
})(['padding:10px 0;text-align:center;']);

function ConfirmationWindow(_ref) {
  var translations = _ref.translations,
      circleTickColour = _ref.circleTickColour,
      onButtonClick = _ref.onButtonClick,
      globalStyling = _ref.globalStyling,
      config = _ref.config;

  return _react2.default.createElement(
    Container,
    { id: 'enquiry-submitted' },
    _react2.default.createElement(
      Image,
      null,
      _react2.default.createElement(_CircleTick2.default, { width: '4em', height: '4em', colour: circleTickColour })
    ),
    _react2.default.createElement(
      Header,
      null,
      translations.confirmationHeaderText
    ),
    _react2.default.createElement(
      Content,
      null,
      translations.confirmationContentText
    ),
    config.boutique && _react2.default.createElement(
      BoutiqueText,
      null,
      _react2.default.createElement(
        Content,
        null,
        translations.boutiqueText,
        ' '
      )
    ),
    _react2.default.createElement(
      ActionButton,
      null,
      config.boutique && _react2.default.createElement(_Global.Button, {
        onClick: function onClick() {
          return window.open(translations.boutiqueButtonURL, '_blank');
        },
        applyStyle: 'primary',
        buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
        text: translations.boutiqueButtonText,
        styleOverride: confirmationButtonStyle,
        mobileStyleOverride: function mobileStyleOverride() {
          return 'margin: 0 0 10px;';
        }
      }),
      _react2.default.createElement(_Global.Button, {
        'data-cy': 'confirmation-button',
        onClick: onButtonClick,
        applyStyle: 'primary',
        buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
        text: translations.confirmationGoBackLabel,
        styleOverride: confirmationButtonStyle
      })
    )
  );
}