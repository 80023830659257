'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (_ref) {
  var primaryBrandColour = _ref.globalStyling.colours.primaryBrandColour,
      links = _ref.links,
      config = _ref.config,
      nextActiveLink = _ref.nextActiveLink;

  return _react2.default.createElement(
    HeaderSubNav,
    null,
    links.map(function (link, index) {
      return _react2.default.createElement(
        MenuItem,
        { key: link.navId },
        _react2.default.createElement(
          Link,
          {
            href: link.link,
            tabindex: '0',
            id: link.navId,
            onKeyDown: function onKeyDown(e) {
              if (e.key === 'ArrowDown' && index < links.length - 1) {
                e.preventDefault();
                document.getElementById(links[index + 1].navId).focus();
              }

              if (e.key === 'ArrowUp' && index > 0) {
                e.preventDefault();
                document.getElementById(links[index - 1].navId).focus();
              }
              if (e.key === 'Tab') {
                e.preventDefault();
                document.getElementById(nextActiveLink).focus();
              }
            }
          },
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              key: link.navId,
              styleOverride: function styleOverride() {
                return '\n                ' + (0, _Global.fontStyleOverride)(config.subLinkFont) + ';\n                box-sizing: border-box;\n                cursor: pointer;\n                line-height: 55px;\n                position: relative;\n                width: 25%;\n\n                &:before {\n                  position: absolute;\n                  bottom: 0;\n                  left: 0;\n                  content: \'\';\n                  width: 100%;\n                  height: 1px;\n                  border-bottom: 1px solid #323232;\n                }\n                &:after {\n                  position: absolute;\n                  bottom: 0;\n                  left: 0;\n                  content: \'\';\n                  width: 0%;\n                  height: 1px;\n                  border-bottom: 1px solid ' + primaryBrandColour.value + ';\n                  transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);\n                }\n\n                &:hover:after {\n                  width: 100%;\n                }\n              ';
              }
            },
            link.text
          )
        )
      );
    })
  );
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HeaderSubNav = _styledComponents2.default.ul.withConfig({
  displayName: 'HeaderSubNav',
  componentId: 'sc-xieof9-0'
})(['list-style:none;box-sizing:border-box;display:flex;flex-direction:column;padding:100px 0 20px;width:100%;margin-left:150px;']);


var MenuItem = _styledComponents2.default.li.withConfig({
  displayName: 'HeaderSubNav__MenuItem',
  componentId: 'sc-xieof9-1'
})(['']);

var Link = _styledComponents2.default.a.withConfig({
  displayName: 'HeaderSubNav__Link',
  componentId: 'sc-xieof9-2'
})(['text-decoration:none;']);