'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FiltersSelection;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'FiltersSelection__Container',
  componentId: 'sc-18y65-0'
})(['transition:all 0.2s ease-out;position:relative;opacity:', ';width:', ';margin-right:', ';background-color:#fff;'], function (_ref) {
  var checked = _ref.checked,
      enabled = _ref.enabled;
  return enabled ? checked ? '1' : '0' : '';
}, function (_ref2) {
  var checked = _ref2.checked,
      enabled = _ref2.enabled;
  return enabled ? checked ? '3px' : '0' : '';
}, function (_ref3) {
  var checked = _ref3.checked,
      enabled = _ref3.enabled;
  return enabled ? checked ? '10px' : '0' : '';
});

function FiltersSelection(_ref4) {
  var onClick = _ref4.onClick,
      checked = _ref4.checked,
      _ref4$enabled = _ref4.enabled,
      enabled = _ref4$enabled === undefined ? true : _ref4$enabled;

  return _react2.default.createElement(Container, { checked: checked, onClick: onClick, enabled: enabled });
}