'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.optionComponents = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _numbers = require('../../../../shared/localisation/numbers');

var _filtersTypes = require('../../../../types/Genesis/filtersTypes');

var _Form = require('../../Form');

var _DistanceFilter = require('./DistanceFilter');

var _DistanceFilter2 = _interopRequireDefault(_DistanceFilter);

var _DualHandledRangeFilter = require('./DualHandledRangeFilter');

var _DualHandledRangeFilter2 = _interopRequireDefault(_DualHandledRangeFilter);

var _FilterSection = require('./FilterSection');

var _FilterSection2 = _interopRequireDefault(_FilterSection);

var _LocationFilter = require('./LocationFilter');

var _LocationFilter2 = _interopRequireDefault(_LocationFilter);

var _MultiSelectFilter = require('./MultiSelectFilter');

var _MultiSelectFilter2 = _interopRequireDefault(_MultiSelectFilter);

var _OneHandledRangeFilter = require('./OneHandledRangeFilter');

var _OneHandledRangeFilter2 = _interopRequireDefault(_OneHandledRangeFilter);

var _RegionalFilter = require('./RegionalFilter');

var _RegionalFilter2 = _interopRequireDefault(_RegionalFilter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CheckboxWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersLookup__CheckboxWrapper',
  componentId: 'sc-17fmf0t-0'
})(['align-items:center;border-bottom:1px solid #fff;color:#fff;cursor:pointer;font-size:16px;display:flex;width:100%;box-sizing:border-box;padding:20px 0 10px;text-transform:uppercase;']);

var CheckboxLabel = _styledComponents2.default.div.withConfig({
  displayName: 'FiltersLookup__CheckboxLabel',
  componentId: 'sc-17fmf0t-1'
})(['font-family:\'GenesisSansHeadGlobal-Regular\';font-weight:normal;margin-top:4px;margin-right:auto;']);

var optionComponents = exports.optionComponents = {
  onlyApproved: function onlyApproved(filterKey, props) {
    return _react2.default.createElement(
      CheckboxWrapper,
      {
        key: filterKey,
        role: 'checkbox',
        'aria-checked': props.selectedFilters.onlyApproved,
        'aria-labelledby': 'only-approved',
        onClick: function onClick() {
          return props.updateFilters([{
            key: filterKey,
            value: props.selectedFilters.onlyApproved === 'true' ? 'false' : 'true'
          }]);
        }
      },
      _react2.default.createElement(
        CheckboxLabel,
        null,
        props.config.translations.onlyApproved
      ),
      _react2.default.createElement(_Form.Checkbox, {
        id: 'only-approved',
        checked: props.selectedFilters.onlyApproved === 'true'
      })
    );
  },
  subregions: function subregions(filterKey, props) {
    return _react2.default.createElement(_RegionalFilter2.default, {
      key: filterKey,
      filterKey: filterKey,
      selectedData: props.selectedFilters.subregions,
      updateFilters: props.updateFilters,
      availableFilterValues: props.availableFilterValues,
      translations: props.translations,
      arrowUpIcon: props.config.stylingConfig.filterSectionOpenIcon,
      arrowDownIcon: props.config.stylingConfig.filterSectionCloseIcon
    });
  },
  location: function location(filterKey, props, ref) {
    return _react2.default.createElement(_LocationFilter2.default, {
      updateSessionPreferences: props.updateSessionPreferences,
      availableFilterValues: props.availableFilterValues,
      selectedFilters: props.selectedFilters,
      selectedData: props.selectedFilters,
      updateFilters: props.updateFilters,
      globalStyling: props.globalStyling,
      filtersStyling: props.config.stylingConfig,
      translations: props.config.translations,
      changeLocation: props.changeLocation,
      location: props.location,
      searchCountryCode: props.searchCountryCode,
      ref: ref,
      key: filterKey
    });
  },
  distance: function distance(filterKey, props, ref) {
    var availableFilterValues = [5, 10, 20, 50, 70, 100, 150, 200];

    return _react2.default.createElement(
      'div',
      { key: filterKey, ref: ref },
      _react2.default.createElement(_DistanceFilter2.default, {
        updateFilters: props.updateFilters,
        translations: props.translations,
        availableFilterValues: availableFilterValues,
        arrowUpIcon: props.config.stylingConfig.filterSectionOpenIcon,
        arrowDownIcon: props.config.stylingConfig.filterSectionCloseIcon,
        innerRef: ref,
        changeLocation: props.changeLocation,
        location: props.location,
        updateSessionPreferences: props.updateSessionPreferences,
        selectedFilters: props.selectedFilters,
        selectedData: props.selectedFilters,
        searchCountryCode: props.searchCountryCode
      })
    );
  },
  searchElectric: function searchElectric(filterKey, props) {
    var searchElectricChecked = function searchElectricChecked(selectedFuelType) {
      var typeElectric = ['electric', 'hev', 'phev'];
      return selectedFuelType ? typeElectric.every(function (v) {
        return selectedFuelType.includes(v);
      }) : false;
    };

    var updateFiltersSearchElectric = function updateFiltersSearchElectric(allElectricSelected) {
      var typeElectric = ['electric', 'hev', 'phev'];
      props.updateFilters([{
        key: 'fuelType',
        value: allElectricSelected ? typeElectric : []
      }]);
    };

    return _react2.default.createElement(
      CheckboxWrapper,
      {
        key: filterKey,
        role: 'checkbox',
        'aria-checked': searchElectricChecked(props.selectedFilters.fuelType),
        'aria-labelledby': 'only-electric',
        onClick: function onClick() {
          return updateFiltersSearchElectric(!searchElectricChecked(props.selectedFilters.fuelType));
        }
      },
      _react2.default.createElement(
        CheckboxLabel,
        null,
        props.config.translations.electric
      ),
      _react2.default.createElement(_Form.Checkbox, {
        id: 'only-electric',
        checked: searchElectricChecked(props.selectedFilters.fuelType)
      })
    );
  },
  model: function model(filterKey, props, ref) {
    var translations = props.config.translations;

    var translateModel = function translateModel(models) {
      return models.map(function (model) {
        return translations[model.value] ? (0, _extends3.default)({}, model, { display: translations[model.value] }) : model;
      });
    };

    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.modelFilterTitle,
        active: !!props.selectedFilters.model,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        disabled: false
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: translateModel(props.availableFilterValues.model),
        selectedData: props.selectedFilters.model,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        getDisabledValue: function getDisabledValue() {
          return false;
        },
        noResultsLabel: '0'
      })
    );
  },
  bodystyle: function bodystyle(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.bodystyleFilterTitle,
        active: !!props.selectedFilters.bodystyle,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.bodystyle,
        selectedData: props.selectedFilters.bodystyle,
        updateFilters: props.updateFilters,
        stylingConfig: props.config.stylingConfig,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        }
      })
    );
  },
  modelYearRange: function modelYearRange(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.modelYearFilterTitle,
        active: !!props.selectedFilters.modelYearRangeMin || !!props.selectedFilters.modelYearRangeMax,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_DualHandledRangeFilter2.default, {
        filterKeyMin: 'modelYearRangeMin',
        filterKeyMax: 'modelYearRangeMax',
        filterLabel: props.config.translations.modelYearFilterTitle,
        availableValues: {
          min: props.availableFilterValues.modelYearRange.min,
          max: props.availableFilterValues.modelYearRange.max
        },
        selectedValues: {
          min: props.selectedFilters.modelYearRangeMin || props.availableFilterValues.modelYearRange.min,
          max: props.selectedFilters.modelYearRangeMax || props.availableFilterValues.modelYearRange.max
        },
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        filtersStyling: props.config.stylingConfig
      })
    );
  },
  priceRange: function priceRange(filterKey, props) {
    var step = 500;

    var getMinAvailableValue = function getMinAvailableValue() {
      var minPriceRange = props.availableFilterValues.minPriceRange;

      var min = Math.floor(minPriceRange / step) * step;
      return min;
    };

    var getMaxAvailableValue = function getMaxAvailableValue() {
      var maxPriceRange = props.availableFilterValues.maxPriceRange;

      return Math.ceil(maxPriceRange / step) * step;
    };

    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.priceFilterTitle,
        stylingConfig: props.config.stylingConfig,
        active: !!props.selectedFilters.minPriceRange
      },
      _react2.default.createElement(_DualHandledRangeFilter2.default, {
        key: filterKey,
        filterKeyMin: 'minPriceRange',
        filterKeyMax: 'maxPriceRange',
        filterLabel: props.config.translations.priceFilterTitle,
        step: step,
        availableValues: {
          min: getMinAvailableValue(),
          max: getMaxAvailableValue()
        },
        selectedValues: {
          min: props.selectedFilters.minPriceRange || getMinAvailableValue(),
          max: props.selectedFilters.maxPriceRange || getMaxAvailableValue()
        },
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        filtersStyling: props.config.stylingConfig,
        formatter: function formatter(value) {
          return (0, _numbers.localiseCurrency)(Number(value), props.locale, props.currencyCode, 0);
        }
      })
    );
  },
  maxOdometer: function maxOdometer(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.odometerFilterTitle,
        active: !!props.selectedFilters.maxOdometer,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_OneHandledRangeFilter2.default, {
        filterKey: filterKey,
        filterLabel: props.config.translations.maxMilesFilterTitle,
        step: 500,
        selectedValue: props.selectedFilters.maxOdometer === undefined || props.selectedFilters.maxOdometer === null ? props.availableFilterValues.maxOdometer : props.selectedFilters.maxOdometer,
        maxValue: props.availableFilterValues.maxOdometer,
        globalStyling: props.globalStyling,
        updateFilters: props.updateFilters,
        stylingConfig: props.config.stylingConfig,
        filtersStyling: props.config.stylingConfig,
        formatter: function formatter(value) {
          return (0, _numbers.localiseNumber)(value, props.locale);
        }
      })
    );
  },
  exteriorColours: function exteriorColours(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.exteriorColoursFilterTitle,
        active: !!props.selectedFilters.exteriorColours,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.exteriorColours,
        selectedData: props.selectedFilters.exteriorColours,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        }
      })
    );
  },
  interiorShades: function interiorShades(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.interiorShadeFilterTitle,
        active: !!props.selectedFilters.interiorShades,
        stylingConfig: props.config.stylingConfig,
        ref: ref
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.interiorShades,
        selectedData: props.selectedFilters.interiorShades,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        }
      })
    );
  },
  engine: function engine(filterKey, props, ref) {
    return _react2.default.createElement(
      _FilterSection2.default,
      {
        key: filterKey,
        title: props.config.translations.engineFilterTitle,
        active: !!props.selectedFilters.engine,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        ref: ref,
        translations: props.config.translations
      },
      _react2.default.createElement(_MultiSelectFilter2.default, {
        filterKey: filterKey,
        data: props.availableFilterValues.engine,
        selectedData: props.selectedFilters.engine,
        updateFilters: props.updateFilters,
        globalStyling: props.globalStyling,
        stylingConfig: props.config.stylingConfig,
        viewAllLabel: props.config.translations.viewAllLabel,
        applyOrdering: function applyOrdering(a, b) {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        },
        getDisabledValue: function getDisabledValue(value) {
          return !value.selectedCount;
        },
        translations: props.config.translations
      })
    );
  }
};