'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  translations: {
    vdpMyDealHirePurchaseSummary: 'Your monthly payment would be {PAYMENT} based on a {DURATION} finance period.',
    vdpRetailerDistanceSuccess: '{DISTANCE} from your location.',
    vdpRetailerSomethingWentWrong: 'Sorry, something went wrong.',
    vdpRetailerDistanceFailed: 'Sorry, we could not calculate the distance',
    vdpRetailerGetDirections: 'Get Directions',
    vdpRetailerNoLocationInfo: 'There is no location information to show',
    vdpContactUs: 'contact',
    vdpReserveThisVehicle: 'reserve this vehicle',
    vdpBookTestDrive: 'book a test drive',
    addPartExchangeLabel: 'add part exchange',
    myDealHeader: 'my deal',
    compareLink: 'compare',
    shortlistLink: 'shortlist',
    vdpSimilarVehiclesHeader: 'similar vehicles',
    searchResultTileMoreInfo: 'more info',
    vdpRetailerPhoneLabel: 'Phone',
    vdpRetailerEmailLabel: 'Email',
    vdpRetailerOpeningTimesText: 'Open from',
    vdpRetailerStockButtonText: 'View Retailer Stock',
    vdpAccessoriesHeader: 'accessory packs',
    vdpAccessoriesSubHeader: 'We have selected a range of accessories to help you to personalise this F-Pace. Any items' + 'you select can be submitted to the selling retailer as part of your enquiry. The full' + 'range of accessories are availabel from your retailer.',
    vdpHighlightedFeaturesHeader: 'highlighted features',
    vdpPerformanceHeader: 'performance',
    vdpOtherEquipmentHeader: 'other equipment',
    vdpSpecificationHeader: 'specifications',
    vdpMyDealHeader: 'my deal',
    vdpGalleryAndMyDealBottomText: 'Unverbindliches Leasingbeispiel mit Kilometerabrechnung vermittelt für die Jaguar Bank, Zweigniederlassung der FCA Bank Deutschland GmbH, Salzstraße 138, 74076 Heilbronn auf Basisaktuell gültiger Konditionen. Zzgl. Überführungs- und Zulassungskosten. In den Monatsraten  sowie dem Gesamtbetrag ist eine GAP Plus+ Versicherung (Differenzkaskoversicherung) der AXA  enthalten. Diese unterliegt nicht der MwSt. Alle sonstigen Preise verstehen sich inkl. 19%',
    compareActionLabel: 'Compare',
    shortlistActionLabel: 'Shortlist',
    searchResultsEmissionsCombinedLabel: 'Emissions Combined',
    searchResultsNotAvailableLabel: 'N/A',
    searchResultsMonthlyLabel: 'Monthly',
    searchResultsAprLabel: 'APR',
    searchResultsInterestRateLabel: 'Interest Rate',
    searchResultsPriceLabel: 'Price',
    vdpTestDriveButtonLabel: 'Test Drive',
    vdpReserveButtonLabel: 'Reserve',
    financeMonthlyPayment: 'Monthly Payments',
    finalPayment: 'Final payment',
    financeNumberOfPayments: 'Number of Payments',
    totalCost: 'Total Amount Payable',
    financeDeposit: 'Deposit',
    months: 'months',
    term: 'term',
    financeAPR: 'APR',
    financeTotalCost: 'Vehicle Price',
    interestRate: 'Interest Rate',
    respresentativeExampleHeading: 'Representative Hire Purchase Finance Example',
    representativeExampleBody: 'Representative example for XE subject to 36 month, 60,000 mile agreement',
    representativeExampleTermsAndConditions: 'Ejemplo de arrendamiento no vinculante con facturación de millaje dispuesto para Jaguar Bank, sucursal de FCA Bank Spain en condiciones válidas y actualizadas. Más. Costos de transferencia y admisión. Las cuotas mensuales y el monto total incluyen el seguro GAP Plus + de AXA (colisión diferencial). Esto no está sujeto al IVA. Todos los demás precios incluyen 21',
    addFinanceLabel: 'edit finance',
    updateMyDealLabel: 'update my deal',
    configureFinanceTitle: 'configure finance',
    saveDealLabel: 'save my deal',
    hirePurchaseTitle: 'hire purchase',
    respresentativeHPExampleHeading: 'HP finance example',
    respresentativePCPExampleHeading: 'representative PCP finance example',
    printDealLabel: 'print my deal',
    myDealDocTitle: 'MY DEAL',
    price: 'Price',
    monthly: 'Monthly',
    financeDurationOfAgreement: 'Duration of Agreement (Months)',
    financeTotalAmountOwed: 'Total Amount Owed',
    financeValidFom: 'Valid from',
    financeValidUntil: 'Offer valid until',
    financeTermsAndConditions: 'Estas condiciones se mantendrán en vigor durante un periodo mínimo de 15 días naturales desde la fecha de su impresión y se aplicaran previo análisis crediticio favorable de FCA Capital España, EFC, SAU. El PVP es orientativo y no incluye ofertas promocionales ocasionales ni otras revisiones de precios que obedezcan a otros factores. Acuda a su concesionario más cercano para que le facilite el PVP así como un plan de financiación personalizado.'
  }
};