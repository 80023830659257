'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Global = require('../components/Global');

var _components = require('../components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var _ref$config = _ref.config,
      translations = _ref$config.translations,
      headingFont = _ref$config.headingFont,
      bodyFont = _ref$config.bodyFont,
      padding = _ref$config.padding,
      backgroundColour = _ref$config.backgroundColour,
      alignment = _ref$config.alignment;
  return _react2.default.createElement(
    _components.Wrapper,
    { backgroundColour: backgroundColour },
    _react2.default.createElement(
      _components.TextWrapper,
      { padding: padding, alignment: alignment },
      _react2.default.createElement(
        _Global.HeadingOne,
        { styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)(headingFont);
          } },
        translations.headingText
      ),
      translations.bodyText && _react2.default.createElement(
        _Global.Paragraph,
        { styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)(bodyFont);
          } },
        translations.bodyText
      )
    )
  );
};