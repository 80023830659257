'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SocialSharePopup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SocialMediaShare = require('./SocialMediaShare/SocialMediaShare');

var _SocialMediaShare2 = _interopRequireDefault(_SocialMediaShare);

var _ModalOverlay = require('../../ModalOverlay');

var _ModalOverlay2 = _interopRequireDefault(_ModalOverlay);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ModalTitleWrapper = _styledComponents2.default.h3.withConfig({
  displayName: 'SocialSharePopup__ModalTitleWrapper',
  componentId: 'sc-ys08mn-0'
})(['box-sizing:border-box;background-color:#151515;color:#fff;display:flex;font-family:\'GenesisSansHeadGlobal-Light\';font-size:28px;font-weight:400;justify-content:center;margin:0;padding:20px;text-align:center;width:100%;']);

function SocialSharePopup(_ref) {
  var children = _ref.children,
      socialShare = _ref.socialShare,
      _ref$config = _ref.config,
      translations = _ref$config.translations,
      closeIconUrl = _ref$config.closeIconUrl;

  return _react2.default.createElement(
    _ModalOverlay2.default,
    {
      component: children,
      modalWidth: 500,
      closeIconUrl: closeIconUrl
    },
    _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ModalTitleWrapper,
        null,
        translations.shareThisPage
      ),
      _react2.default.createElement(_SocialMediaShare2.default, {
        shareUrl: window.location.href,
        socialShare: socialShare
      })
    )
  );
}