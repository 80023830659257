'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n    padding: 0;\n    margin-top: 10px;\n  '], ['\n    display: flex;\n    flex-direction: column;\n    padding: 0;\n    margin-top: 10px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 10px 0;\n    width: 100%;\n  '], ['\n    padding: 10px 0;\n    width: 100%;\n  ']);

exports.default = VdpGalleryAndHighlights;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _helpers = require('../../../shared/currencyConversion/helpers');

var _numbers = require('../../../shared/localisation/numbers');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _FooterActionBanner = require('../../FooterActionBanner');

var _FooterActionBanner2 = _interopRequireDefault(_FooterActionBanner);

var _Global = require('../../Global');

var _SearchResultGridBlock = require('../../SearchResultGridBlock');

var _VehicleInfoBanner = require('../../VehicleInfoBanner');

var _VehicleInfoBanner2 = _interopRequireDefault(_VehicleInfoBanner);

var _VdpGalleryRr = require('./VdpGalleryRr');

var _VdpGalleryRr2 = _interopRequireDefault(_VdpGalleryRr);

var _VdpHighlights = require('./VdpHighlights');

var _VdpHighlights2 = _interopRequireDefault(_VdpHighlights);

var _VehicleMainInfoAndPrice = require('./VehicleMainInfoAndPrice');

var _VehicleSubHeader = require('./VehicleSubHeader');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndHighlights__Container',
  componentId: 'sc-1azr2hs-0'
})(['display:flex;margin-top:30px;', ';'], _theme2.default.max.large(_templateObject));

var MyDealContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndHighlights__MyDealContainer',
  componentId: 'sc-1azr2hs-1'
})(['display:flex;flex-direction:column;box-sizing:border-box;padding:0px 0 0 16px;width:40%;', ';'], _theme2.default.max.large(_templateObject2));

function VdpGalleryAndHighlights(props) {
  var translations = props.translations,
      config = props.config,
      subHeaderType = props.subHeaderType,
      vehicleImages = props.vehicleImages,
      vehicleInfo = props.vehicleInfo,
      globalStyling = props.globalStyling,
      badgeStyling = props.badgeStyling;
  var subHeaderFont = config.subHeaderFont,
      showOdometerInformation = config.showOdometerInformation;


  var rightButton = function rightButton() {
    return _react2.default.createElement(_Global.Button, {
      onClick: props.reserveThisVehicle,
      text: props.translations.vdpReserveButtonLabel,
      applyStyle: 'primary',
      buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
    });
  };
  var leftButton = function leftButton() {
    return _react2.default.createElement(_Global.Button, {
      onClick: props.makeATestDriveRequest,
      text: props.translations.vdpTestDriveButtonLabel,
      applyStyle: 'primary',
      buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle
    });
  };

  var vehicleTitle = function vehicleTitle() {
    return vehicleInfo.description ? '' + vehicleInfo.registration : vehicleInfo.name;
  };
  var hasFinance = !!vehicleInfo.finance;
  var price = (0, _numbers.localiseCurrency)(vehicleInfo.price.value, vehicleInfo.locale, vehicleInfo.price.currency, 0);
  var badge = badgeStyling && badgeStyling.badgeVariant && vehicleInfo.modelVariant && vehicleInfo.modelVariant.toLowerCase().includes(badgeStyling.badgeVariant.toLowerCase());
  var title = (0, _SearchResultGridBlock.titleHeaderText)(vehicleInfo, true, props.badgeStyling.titleFont, badgeStyling.displayModelAndVariant, badge);
  var showSalesTaxDisclaimer = props.config.showSectionSalesTaxDisclaimer && !!vehicleInfo.sectionSalesTaxDisclaimer;

  var handlePrice = (0, _helpers.handleVehiclePrice)(!showSalesTaxDisclaimer ? vehicleInfo.price.value : vehicleInfo.netPrice || vehicleInfo.price.value, {
    currencyType: vehicleInfo.price.currency,
    locale: vehicleInfo.locale.replace('_', '-'),
    handleZeroPriceAsText: props.handleZeroPriceAsText,
    priceSpecial: vehicleInfo.priceSpecial,
    translationsSold: translations.sold,
    vehiclePrice: vehicleInfo.price.value,
    zeroPriceText: translations.zeroPriceText
  });

  return _react2.default.createElement(
    _react.Fragment,
    null,
    props.showStickyBanner && _react2.default.createElement(_VehicleInfoBanner2.default, {
      config: props.config,
      vehicleInfo: props.vehicleInfo,
      title: title,
      iconColour: props.iconColour,
      pinIcon: props.config.pinIcon,
      featureFlags: props.featureFlags,
      translations: translations,
      borderColour: props.borderColour,
      imageSrc: vehicleImages.slides.length > 0 ? vehicleImages.slides[0].image : props.placeholdingImage,
      vehicleModel: vehicleTitle(),
      vehicleDetail: (0, _VehicleSubHeader.formattedVehicleDescription)(subHeaderType, vehicleInfo, config.showOdometerInformation, translations, badge),
      dealerTelephone: vehicleInfo.retailerInformation.phone,
      dealerName: vehicleInfo.retailerInformation.name,
      stickyBannerStyling: props.stickyBannerStyling,
      showStickyBannerDealerLocation: props.showStickyBannerDealerLocation,
      showStickyBannerLogo: props.showStickyBannerLogo,
      mainInfoLogo: props.mainInfoLogo,
      contentWidth: props.globalStyling.contentWidth,
      info: [[handlePrice, translations.searchResultsPriceLabel], hasFinance && [(0, _numbers.localiseCurrency)(parseFloat(vehicleInfo.finance.monthlyPrice.replace('.', '').replace(',', '.')), vehicleInfo.locale, vehicleInfo.price.currency, 2), translations.searchResultsMonthlyLabel] || [], hasFinance && [vehicleInfo.finance.apr + '%', translations.searchResultsAprLabel] || [], hasFinance && [vehicleInfo.finance.interestRate + '%', translations.searchResultsInterestRateLabel] || []],
      onReserveButtonClick: props.reserveThisVehicle,
      onTestDriveButtonClick: props.bookTestDrive,
      onContactUsButtonClick: props.makeAnEnquiry,
      buttonTheme: props.buttonTheme,
      hideVertSeparators: props.config.hideVertSeparators,
      globalStyling: globalStyling,
      showStickyBannerContactUsButton: props.showStickyBannerContactUsButton
    }),
    (props.featureFlags.testDrive || props.featureFlags.reserve) && _react2.default.createElement(_FooterActionBanner2.default, {
      leftButton: props.featureFlags.testDrive && leftButton,
      rightButton: props.featureFlags.reserve && rightButton
    }),
    _react2.default.createElement(_VehicleMainInfoAndPrice.VehicleMainInfoAndPrice, {
      config: config,
      vehicleInfo: vehicleInfo,
      title: title,
      subHeaderType: subHeaderType,
      showOdometerInformation: showOdometerInformation,
      translations: translations,
      handleZeroPriceAsText: props.handleZeroPriceAsText,
      globalStyling: globalStyling,
      badge: badge,
      handlePrice: handlePrice
    }),
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(_VdpGalleryRr2.default, {
        title: title,
        shortListedIconColour: props.shortListedIconColour,
        compareIconColour: props.compareIconColour,
        translations: props.translations,
        vehicleInfo: vehicleInfo,
        vehicleImages: vehicleImages,
        compareVehicle: props.compareVehicle,
        shortlistVehicle: props.shortlistVehicle,
        placeholdingImage: props.placeholdingImage,
        headerFont: config.headerFont,
        subHeaderType: subHeaderType,
        subHeaderFont: subHeaderFont,
        vehicleHeadingColour: props.vehicleHeadingColour,
        rotateGallery: props.rotateGallery,
        goToAnchor: props.goToAnchor,
        iconColour: props.iconColour,
        imageCarousel: props.imageCarousel,
        pinIcon: props.config.pinIcon,
        data360: props.data360,
        get360Info: props.get360Info,
        globalStyling: globalStyling,
        showOdometerInformation: props.config.showOdometerInformation,
        hideCompareShortlist: props.config.hideCompareShortlist,
        noBorder: true
      }),
      _react2.default.createElement(
        MyDealContainer,
        null,
        _react2.default.createElement(_VdpHighlights2.default, {
          config: props.config,
          translations: props.translations,
          globalStyling: globalStyling,
          vehicleInfo: vehicleInfo,
          headerFont: config.headerFont,
          price: price,
          makeAnEnquiry: props.makeAnEnquiry,
          makeATestDriveRequest: props.makeATestDriveRequest,
          makeAnOnDemandEnquiry: props.makeAnOnDemandEnquiry,
          makeAReservation: props.makeAReservation,
          goToAnchor: props.goToAnchor,
          iconColour: props.iconColour,
          socialShareConfig: props.socialShareConfig,
          socialShare: props.socialShare,
          handleZeroPriceAsText: props.handleZeroPriceAsText,
          featureFlags: props.featureFlags,
          printVehiclePdf: props.printVehiclePdf,
          printingState: props.printingState,
          handlePrice: handlePrice
        }),
        _react2.default.createElement(_reactWaypoint2.default, {
          onPositionChange: function onPositionChange(_ref) {
            var currentPosition = _ref.currentPosition;
            return props.toggleStickyBanner(currentPosition === 'above');
          }
        })
      )
    )
  );
}

VdpGalleryAndHighlights.defaultProps = {
  iconColour: '#9E1B32',
  showDeal: true,
  subHeaderType: 'VehicleDescription'
};