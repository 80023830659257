'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Backdrop = _styledComponents2.default.div.withConfig({
  displayName: 'Backdrop'
}).withConfig({
  displayName: 'Well__Backdrop',
  componentId: 'sc-1ag1qw4-0'
})(['width:100%;box-sizing:border-box;background-color:#f0f0f0;', ';'], function (_ref) {
  var order = _ref.order;
  return order && 'order: ' + order;
});

var Inset = _styledComponents2.default.div.withConfig({
  displayName: 'Inset'
}).withConfig({
  displayName: 'Well__Inset',
  componentId: 'sc-1ag1qw4-1'
})(['background-color:#ffffff;width:100%;box-sizing:border-box;']);

function Well(_ref2) {
  var children = _ref2.children,
      order = _ref2.order;

  return _react2.default.createElement(
    Backdrop,
    { order: order },
    _react2.default.createElement(
      Inset,
      { 'data-cy': 'vehicle-information' },
      children
    )
  );
}

exports.default = Well;