'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 24px;\n    margin: 30px 0 20px 0;\n  '], ['\n    font-size: 24px;\n    margin: 30px 0 20px 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AppState = require('../../../types/AppState');

var _searchResultsTypes = require('../../../types/Bentley/searchResultsTypes');

var _vehicleTypes = require('../../../types/Bentley/vehicleTypes');

var _AppShared = require('../../../types/AppShared');

var _VehicleItem = require('../VehicleCard/VehicleItem');

var _VehicleItem2 = _interopRequireDefault(_VehicleItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SimilarCarsTitle = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarCars__SimilarCarsTitle',
  componentId: 'sc-1q4zmea-0'
})(['text-align:center;margin:60px 0 40px 0;font-size:30px;font-family:Bentley-Regular;font-style:normal;font-weight:300;line-height:28px;text-transform:uppercase;color:#222222;', ''], _theme2.default.max.small(_templateObject));

var SimilarCars = function SimilarCars(props) {
  var getSimilarCars = props.getSimilarCars,
      config = props.config,
      translations = props.translations,
      onMoreInfoClick = props.onMoreInfoClick,
      onEnquireClick = props.onEnquireClick,
      locale = props.locale,
      shared = props.shared;


  var page = (0, _reactRedux.useSelector)(function (state) {
    return state.pages['/searchresults'];
  });

  if (!page || config.hideSimilarVehicles) return null;

  var similarCars = page[(0, _keys2.default)(page)[0]].similarCars;

  (0, _react.useEffect)(function () {
    if (similarCars === null) {
      // For similar cars sections, we only want to display 2 cars, randomly.
      // If you want to populate more cars, change pageSize value.
      getSimilarCars({ pageSize: 2, pageNumber: 1 });
    }
  }, [similarCars]);

  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    similarCars !== null && similarCars.length > 0 && _react2.default.createElement(
      'div',
      null,
      _react2.default.createElement(
        SimilarCarsTitle,
        null,
        translations.similarCars
      ),
      similarCars.map(function (vehicle) {
        return _react2.default.createElement(_VehicleItem2.default, {
          config: config,
          key: vehicle.id,
          translations: translations,
          onMoreInfoClick: onMoreInfoClick,
          onEnquireClick: onEnquireClick,
          locale: locale,
          vehicle: vehicle,
          shared: shared
        });
      })
    )
  );
};

exports.default = SimilarCars;