'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n    padding: 0 5%;\n  '], ['\n    display: flex;\n    flex-direction: column;\n    padding: 0 5%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 5%;\n  '], ['\n    padding: 0 5%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _router = require('../../actions/router');

var _compare = require('../../shared/compare');

var _shortlist = require('../../shared/shortlist');

var _Global = require('../../components/Global');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _StyledIcon = require('../../components/StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _SearchResultGridTile = require('../../components/SearchResultGridTile');

var _SearchResultGridTile2 = _interopRequireDefault(_SearchResultGridTile);

var _vehicleStore = require('../../shared/selectors/vehicleStore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarVehicles__Container',
  componentId: 'sc-1v6ahj1-0'
})(['display:flex;justify-content:center;padding:32px 0;border-top:1px solid #dddddd;border-bottom:1px solid #dddddd;', ';@media print{display:none;}'], function (_ref) {
  var colour = _ref.colour;
  return '' + (colour && 'background-color: ' + colour);
});

var HeaderContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarVehicles__HeaderContainer',
  componentId: 'sc-1v6ahj1-1'
})(['display:flex;justify-content:center;padding:0 16px 32px;']);

var DesktopContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarVehicles__DesktopContainer',
  componentId: 'sc-1v6ahj1-2'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.max.medium(_templateObject));

var MobileContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarVehicles__MobileContainer',
  componentId: 'sc-1v6ahj1-3'
})(['display:none;', ';'], _theme2.default.max.medium(_templateObject2));

var TilesContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarVehicles__TilesContainer',
  componentId: 'sc-1v6ahj1-4'
})(['display:flex;flex-direction:row;padding:0 16px;align-items:center;', ';'], _theme2.default.max.medium(_templateObject3));

var GridTilesContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarVehicles__GridTilesContainer',
  componentId: 'sc-1v6ahj1-5'
})(['display:flex;justify-content:space-between;width:100%;']);

var ItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarVehicles__ItemContainer',
  componentId: 'sc-1v6ahj1-6'
})(['width:32%;']);

var PagingContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarVehicles__PagingContainer',
  componentId: 'sc-1v6ahj1-7'
})(['display:flex;justify-content:center;align-items:center;padding-top:8px;font-size:20px;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SimilarVehicles__IconWrapper',
  componentId: 'sc-1v6ahj1-8'
})(['display:flex;cursor:pointer;']);

var SimilarVehicles = function (_Component) {
  (0, _inherits3.default)(SimilarVehicles, _Component);

  function SimilarVehicles() {
    (0, _classCallCheck3.default)(this, SimilarVehicles);
    return (0, _possibleConstructorReturn3.default)(this, (SimilarVehicles.__proto__ || (0, _getPrototypeOf2.default)(SimilarVehicles)).apply(this, arguments));
  }

  (0, _createClass3.default)(SimilarVehicles, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _props = this.props,
          _props$actions = _props.actions,
          init = _props$actions.init,
          getSimilarVehicles = _props$actions.getSimilarVehicles,
          pathname = _props.history.location.pathname;

      init();
      var vehicleId = pathname.split('/').pop();
      getSimilarVehicles(vehicleId);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          config = _props2.config,
          globalStyling = _props2.globalStyling,
          featureFlags = _props2.featureFlags,
          _props2$state = _props2.state,
          similarVehicles = _props2$state.similarVehicles,
          vehiclesInView = _props2$state.vehiclesInView,
          similarVehiclePageTotal = _props2$state.similarVehiclePageTotal,
          similarVehiclePage = _props2$state.similarVehiclePage,
          selectedVehicleMobile = _props2$state.selectedVehicleMobile,
          similarVehiclePageMobile = _props2$state.similarVehiclePageMobile,
          similarVehiclePageTotalMobile = _props2$state.similarVehiclePageTotalMobile,
          _props2$actions = _props2.actions,
          similarVehiclesNext = _props2$actions.similarVehiclesNext,
          similarVehiclesPrev = _props2$actions.similarVehiclesPrev,
          similarVehiclesNextMobile = _props2$actions.similarVehiclesNextMobile,
          similarVehiclesPrevMobile = _props2$actions.similarVehiclesPrevMobile,
          dispatch = _props2.dispatch,
          pathByModule = _props2.pathByModule,
          shared = _props2.shared,
          marketInfo = _props2.marketInfo;


      var sharedCompareVehicles = (0, _vehicleStore.getCompareList)(featureFlags)(shared);

      var sharedShortListedVehicles = (0, _vehicleStore.getShortList)(featureFlags)(shared);

      var onMoreInfoClick = function onMoreInfoClick(name, vehicle) {
        return dispatch(_router.actions.navigate(pathByModule('VDP') + '/' + vehicle.id));
      };
      var isListed = function isListed(id, vehicles) {
        return vehicles.find(function (vehicle) {
          return vehicle.id === (id || {});
        });
      };
      var getCompareIconColour = function getCompareIconColour(id) {
        return isListed(id, sharedCompareVehicles) ? config.compareIconListedColour : '#ACACAC';
      };
      var getShortListedIconColour = function getShortListedIconColour(id) {
        return isListed(id, sharedShortListedVehicles) ? config.shortlistIconListedColour : '#ACACAC';
      };
      var compareVehicle = function compareVehicle(vehicle) {
        return dispatch(_compare.actions.toggleVehicle(vehicle));
      };
      var shortlistVehicle = function shortlistVehicle(vehicle) {
        return dispatch(_shortlist.actions.toggleVehicle(vehicle));
      };

      var desktopPaging = similarVehiclePage + ' / ' + similarVehiclePageTotal;
      var mobilePaging = similarVehiclePageMobile + ' / ' + similarVehiclePageTotalMobile;
      return _react2.default.createElement(
        _react.Fragment,
        null,
        similarVehicles && similarVehicles.length > 0 && _react2.default.createElement(
          Container,
          { colour: config.backgroundColour },
          _react2.default.createElement(
            _ContentWrapper2.default,
            { contentWidth: globalStyling.contentWidth },
            config.translations.similarVehiclesTitle && _react2.default.createElement(
              HeaderContainer,
              null,
              _react2.default.createElement(
                _Global.HeadingTwo,
                {
                  styleOverride: function styleOverride() {
                    return '' + (0, _Global.fontStyleOverride)(config.headlineFont);
                  }
                },
                config.translations.similarVehiclesTitle
              )
            ),
            _react2.default.createElement(
              DesktopContainer,
              null,
              vehiclesInView && _react2.default.createElement(
                TilesContainer,
                null,
                similarVehiclePageTotal > 1 && _react2.default.createElement(
                  IconWrapper,
                  { onClick: similarVehiclesPrev },
                  _react2.default.createElement(_StyledIcon2.default, {
                    link: config.leftArrowIcon,
                    width: 40,
                    height: 120
                  })
                ),
                _react2.default.createElement(
                  GridTilesContainer,
                  null,
                  vehiclesInView.map(function (vehicle) {
                    return _react2.default.createElement(
                      ItemContainer,
                      { key: vehicle.id },
                      _react2.default.createElement(_SearchResultGridTile2.default, {
                        config: config,
                        key: vehicle.id,
                        align: 'left',
                        vehicle: vehicle,
                        onMoreInfoClicked: function onMoreInfoClicked() {
                          return onMoreInfoClick('VDP', vehicle);
                        },
                        translations: config.translations,
                        compareVehicle: compareVehicle,
                        shortlistVehicle: shortlistVehicle,
                        shortListedIconColour: getShortListedIconColour(vehicle.id),
                        compareIconColour: getCompareIconColour(vehicle.id),
                        renderTopRightIcon: function renderTopRightIcon() {},
                        globalStyling: globalStyling,
                        visibility: config.visibility,
                        locale: marketInfo.locale,
                        badgeStyling: config.badgeStyling,
                        handleZeroPriceAsText: config.handleZeroPriceAsText,
                        showNumberOfImagesInsteadOfViews: config.showNumberOfImagesInsteadOfViews,
                        headerItemFont: config.headerItemFont
                      })
                    );
                  }),
                  vehiclesInView.length % 3 === 2 && _react2.default.createElement(ItemContainer, null)
                ),
                similarVehiclePageTotal > 1 && _react2.default.createElement(
                  IconWrapper,
                  { onClick: similarVehiclesNext },
                  _react2.default.createElement(_StyledIcon2.default, {
                    link: config.rightArrowIcon,
                    width: 40,
                    height: 120
                  })
                )
              ),
              similarVehiclePageTotal > 1 && _react2.default.createElement(
                PagingContainer,
                null,
                desktopPaging
              )
            ),
            _react2.default.createElement(
              MobileContainer,
              null,
              similarVehicles && similarVehicles.map(function (vehicle, index) {
                return _react2.default.createElement(
                  _react.Fragment,
                  { key: vehicle.id },
                  selectedVehicleMobile === index && _react2.default.createElement(_SearchResultGridTile2.default, {
                    config: config,
                    key: vehicle.id,
                    align: 'left',
                    vehicle: vehicle,
                    onMoreInfoClicked: function onMoreInfoClicked() {
                      return onMoreInfoClick('VDP', vehicle);
                    },
                    translations: config.translations,
                    compareVehicle: compareVehicle,
                    shortlistVehicle: shortlistVehicle,
                    shortListedIconColour: getShortListedIconColour(vehicle.id),
                    compareIconColour: getCompareIconColour(vehicle.id),
                    renderTopRightIcon: function renderTopRightIcon() {},
                    globalStyling: globalStyling,
                    visibility: config.visibility,
                    locale: marketInfo.locale,
                    badgeStyling: config.badgeStyling,
                    handleZeroPriceAsText: config.handleZeroPriceAsText,
                    showNumberOfImagesInsteadOfViews: config.showNumberOfImagesInsteadOfViews,
                    headerItemFont: config.headerItemFont
                  })
                );
              }),
              _react2.default.createElement(
                PagingContainer,
                null,
                similarVehiclePageTotalMobile > 1 && _react2.default.createElement(
                  IconWrapper,
                  { onClick: similarVehiclesNextMobile },
                  _react2.default.createElement(_StyledIcon2.default, {
                    link: config.leftArrowIcon,
                    width: 32,
                    height: 32
                  })
                ),
                mobilePaging,
                similarVehiclePageTotalMobile > 1 && _react2.default.createElement(
                  IconWrapper,
                  { onClick: similarVehiclesPrevMobile },
                  _react2.default.createElement(_StyledIcon2.default, {
                    link: config.rightArrowIcon,
                    width: 32,
                    height: 32
                  })
                )
              )
            )
          )
        )
      );
    }
  }]);
  return SimilarVehicles;
}(_react.Component);

exports.default = SimilarVehicles;