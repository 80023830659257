'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

exports.default = VehicleResults;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Pagination = require('./Pagination');

var _Pagination2 = _interopRequireDefault(_Pagination);

var _GridView = require('./GridView');

var _GridView2 = _interopRequireDefault(_GridView);

var _ListView = require('./ListView');

var _ListView2 = _interopRequireDefault(_ListView);

var _Spinner = require('../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Results = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleResults__Results',
  componentId: 'sc-13xv8e0-0'
})(['width:', ';', ';text-align:left;'], function (props) {
  return props.filtersExpanded ? '97.5%' : '100%';
}, function (props) {
  return props.filtersExpanded && 'margin-left: 2.5%';
});

var SpinnerSection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleResults__SpinnerSection',
  componentId: 'sc-13xv8e0-1'
})(['display:flex;justify-content:center;width:100%;height:80px;']);

var resultsToDisplay = function resultsToDisplay(results, currentPage, infiniteScroll) {
  return infiniteScroll ? (0, _entries2.default)(results).sort(function (a, b) {
    return parseInt(a[0], 10) - parseInt(b[0], 10);
  }).reduce(function (previous, entry) {
    return [].concat((0, _toConsumableArray3.default)(previous), (0, _toConsumableArray3.default)(entry[1]));
  }, []) : results[currentPage];
};

var totalResultsLoaded = function totalResultsLoaded(results) {
  return (0, _values2.default)(results).reduce(function (previous, value) {
    return [].concat((0, _toConsumableArray3.default)(previous), (0, _toConsumableArray3.default)(value));
  }, []).length;
};

function VehicleResults(_ref) {
  var results = _ref.results,
      onMoreInfoClick = _ref.onMoreInfoClick,
      onEnquiryClick = _ref.onEnquiryClick,
      translations = _ref.translations,
      onShowAllResultsClick = _ref.onShowAllResultsClick,
      onPageNumberChange = _ref.onPageNumberChange,
      selectedView = _ref.selectedView,
      filtersExpanded = _ref.filtersExpanded,
      compareVehicle = _ref.compareVehicle,
      shortlistVehicle = _ref.shortlistVehicle,
      getShortListedIconColour = _ref.getShortListedIconColour,
      getCompareIconColour = _ref.getCompareIconColour,
      globalStyling = _ref.globalStyling,
      tilesVisibility = _ref.tilesVisibility,
      locale = _ref.locale,
      vehicleModelTitleFontWeight = _ref.vehicleModelTitleFontWeight,
      headerItemFont = _ref.headerItemFont,
      showGridTiles = _ref.showGridTiles,
      badgeStyling = _ref.badgeStyling,
      financeHeadingFont = _ref.financeHeadingFont,
      showFinancePopup = _ref.showFinancePopup,
      infiniteScroll = _ref.infiniteScroll,
      currentPage = _ref.currentPage,
      totalResults = _ref.totalResults,
      pageSize = _ref.pageSize,
      countDivMargin = _ref.countDivMargin,
      compareShortlistIconWidth = _ref.compareShortlistIconWidth,
      iconYoutubeVideo = _ref.iconYoutubeVideo;

  return totalResults ? _react2.default.createElement(
    Results,
    { filtersExpanded: filtersExpanded },
    selectedView === 'GRID' ? _react2.default.createElement(_GridView2.default, {
      results: resultsToDisplay(results, currentPage, infiniteScroll),
      translations: translations,
      onMoreInfoClick: onMoreInfoClick,
      onEnquiryClick: onEnquiryClick,
      compareVehicle: compareVehicle,
      shortlistVehicle: shortlistVehicle,
      getShortListedIconColour: getShortListedIconColour,
      getCompareIconColour: getCompareIconColour,
      filtersExpanded: filtersExpanded,
      globalStyling: globalStyling,
      visibility: tilesVisibility || {},
      locale: locale,
      vehicleModelTitleFontWeight: vehicleModelTitleFontWeight,
      headerItemFont: headerItemFont,
      showGridTiles: showGridTiles,
      badgeStyling: badgeStyling,
      financeHeadingFont: financeHeadingFont,
      showFinancePopup: showFinancePopup,
      countDivMargin: countDivMargin,
      compareShortlistIconWidth: compareShortlistIconWidth,
      iconYoutubeVideo: iconYoutubeVideo
    }) : _react2.default.createElement(_ListView2.default, {
      results: resultsToDisplay(results, currentPage, infiniteScroll),
      translations: translations,
      onMoreInfoClick: onMoreInfoClick,
      onEnquiryClick: onEnquiryClick,
      filtersExpanded: filtersExpanded,
      compareVehicle: compareVehicle,
      shortlistVehicle: shortlistVehicle,
      getShortListedIconColour: getShortListedIconColour,
      getCompareIconColour: getCompareIconColour,
      globalStyling: globalStyling,
      visibility: tilesVisibility || {},
      locale: locale,
      vehicleModelTitleFontWeight: vehicleModelTitleFontWeight,
      headerItemFont: headerItemFont,
      showGridTiles: showGridTiles,
      badgeStyling: badgeStyling,
      showFinancePopup: showFinancePopup,
      iconYoutubeVideo: iconYoutubeVideo
    }),
    !infiniteScroll ? _react2.default.createElement(_Pagination2.default, {
      pageSize: pageSize,
      currentPage: currentPage,
      totalResults: totalResults,
      onShowAllResultsClick: onShowAllResultsClick,
      onPageNumberChange: onPageNumberChange,
      translations: translations,
      globalStyling: globalStyling
    }) : totalResults > totalResultsLoaded(results) && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        SpinnerSection,
        null,
        _react2.default.createElement(_Spinner2.default, { colour: globalStyling.colours.primaryBrandColour })
      )
    )
  ) : _react2.default.createElement(
    Results,
    null,
    ' ',
    translations.noSearchResults,
    ' '
  );
}