'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _filters = require('../../shared/filters');

var _Global = require('../../components/Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ItemRow = _styledComponents2.default.div.withConfig({
  displayName: 'MultiSelectFilter__ItemRow',
  componentId: 'sc-1gd4tuz-0'
})(['height:auto;cursor:pointer;padding:0 16px 0 32px;color:', ';border-bottom:1px solid #d5d5d5;background-color:#ffffff;&:last-child{border-bottom:none;}display:flex;align-items:center;'], function (_ref) {
  var colour = _ref.colour;
  return colour;
});

var Selection = (0, _styledComponents2.default)(ItemRow).withConfig({
  displayName: 'MultiSelectFilter__Selection',
  componentId: 'sc-1gd4tuz-1'
})(['justify-content:space-between;', ';', ';'], function (_ref2) {
  var showHover = _ref2.showHover;
  return '' + (!showHover && 'cursor: default');
}, function (_ref3) {
  var showHover = _ref3.showHover;
  return showHover && '&:hover {\n    background-color: #f8f8f8;\n  }';
});

var IconWrapper = _styledComponents2.default.img.withConfig({
  displayName: 'MultiSelectFilter__IconWrapper',
  componentId: 'sc-1gd4tuz-2'
})(['width:20px;height:20px;']);

var MultiSelectFilter = function (_Component) {
  (0, _inherits3.default)(MultiSelectFilter, _Component);

  function MultiSelectFilter(props) {
    (0, _classCallCheck3.default)(this, MultiSelectFilter);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MultiSelectFilter.__proto__ || (0, _getPrototypeOf2.default)(MultiSelectFilter)).call(this, props));

    Object.defineProperty(_this, 'onViewAllClick', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.setState(function () {
          return { showAll: true };
        });
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            translations = _this$props.translations,
            filterKey = _this$props.filterKey,
            data = _this$props.data,
            selectedData = _this$props.selectedData,
            updateFilters = _this$props.updateFilters,
            _this$props$globalSty = _this$props.globalStyling,
            direction = _this$props$globalSty.direction,
            primaryBrandColour = _this$props$globalSty.colours.primaryBrandColour,
            applyOrdering = _this$props.applyOrdering,
            stylingConfig = _this$props.stylingConfig,
            viewAllLabel = _this$props.viewAllLabel,
            getDisabledValue = _this$props.getDisabledValue;
        var showAll = _this.state.showAll;

        var filterContent = showAll ? data.sort(applyOrdering) : data.sort(applyOrdering).slice(0, 7);

        return _react2.default.createElement(
          _react.Fragment,
          null,
          filterContent.map(function (d) {
            var itemSelected = selectedData && selectedData.some(function (sd) {
              return sd === d.value.toString();
            });
            var isDisabled = getDisabledValue(d);
            return _react2.default.createElement(
              Selection,
              {
                tabIndex: '0',
                'aria-label': isDisabled ? translations.cannotSelectAriaLabel : translations.clickToSelectAriaLabel,
                'aria-disabled': isDisabled,
                key: d.value,
                'data-cy': d.display,
                showHover: !getDisabledValue(d),
                onClick: function onClick() {
                  return (itemSelected || !getDisabledValue(d)) && updateFilters([{
                    key: filterKey,
                    value: _filters.helpers.newArray(selectedData || [], d.value.toString())
                  }]);
                }
              },
              _react2.default.createElement(
                _Global.Paragraph,
                {
                  styleOverride: function styleOverride() {
                    return '\n                  word-wrap: break-word;\n                  margin: 8px 0;\n                  ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, stylingConfig.filterMenuFont, getDisabledValue(d) && {
                      colour: stylingConfig.filterMenuDisabledColour || {
                        value: '#989898'
                      }
                    })) + '\n                ';
                  }
                },
                '\u200E',
                direction === 'rtl' ? _react2.default.createElement(
                  'span',
                  { dir: 'ltr' },
                  (d.selectedCount !== 0 ? '(' + d.selectedCount + ')' : '') + ' ' + d.display
                ) : d.display + ' ' + (d.selectedCount !== 0 ? '(' + d.selectedCount + ')' : '')
              ),
              itemSelected && _react2.default.createElement(IconWrapper, { src: stylingConfig.filterTickIcon, alt: '' })
            );
          }),
          !showAll && data.length >= 8 && _react2.default.createElement(
            ItemRow,
            {
              colour: primaryBrandColour.value,
              onClick: _this.onViewAllClick
            },
            _react2.default.createElement(
              _Global.Paragraph,
              {
                styleOverride: function styleOverride() {
                  return '\n              margin: 8px 0;\n              ' + (0, _Global.fontStyleOverride)((0, _extends3.default)({}, stylingConfig.filterMenuFont, stylingConfig.filterMenuViewAllColour && {
                    colour: stylingConfig.filterMenuViewAllColour
                  })) + '\n          ';
                }
              },
              viewAllLabel
            )
          )
        );
      }
    });


    _this.state = {
      showAll: false
    };
    return _this;
  }

  return MultiSelectFilter;
}(_react.Component);

exports.default = MultiSelectFilter;


MultiSelectFilter.defaultProps = {
  applyOrdering: function applyOrdering(a, b) {
    if (a.display < b.display) {
      return -1;
    }
    if (a.display > b.display) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }
};