'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['padding: 25px 25px 0;'], ['padding: 25px 25px 0;']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)([' flex-direction: column;'], [' flex-direction: column;']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)([' text-align: center;'], [' text-align: center;']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)([' margin-top: 32px; '], [' margin-top: 32px; ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)([' margin-right: 20%;'], [' margin-right: 20%;']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['margin-bottom: 16px;\n  margin-top: 66px;'], ['margin-bottom: 16px;\n  margin-top: 66px;']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)([' margin-bottom: 32px; '], [' margin-bottom: 32px; ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _Global = require('../../../pods/components/Global');

var _settingsTyped = require('../../../shared/selectors/settingsTyped');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ModelLandingTypes = require('../../../types/Lamborghini/ModelLandingTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelDescription__Wrapper',
  componentId: 'sc-zqjxie-0'
})(['display:flex;flex-direction:column;align-items:center;background:#fff;padding:66px 60px;', ''], _theme2.default.max.medium(_templateObject));

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ModelDescription__Container',
  componentId: 'sc-zqjxie-1'
})(['display:flex;max-width:1336px;']);

var DescriptionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ModelDescription__DescriptionWrapper',
  componentId: 'sc-zqjxie-2'
})(['display:flex;', ''], _theme2.default.max.medium(_templateObject2));

var SeventyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelDescription__SeventyContainer',
  componentId: 'sc-zqjxie-3'
})(['flex:1;display:flex;flex-direction:column;margin:8px;', ''], _theme2.default.max.medium(_templateObject3));
var ThirtyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelDescription__ThirtyContainer',
  componentId: 'sc-zqjxie-4'
})(['flex:0.3;display:flex;flex-direction:column;margin:8px;']);

var ParagraphsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelDescription__ParagraphsContainer',
  componentId: 'sc-zqjxie-5'
})(['display:flex;flex-direction:column;gap:18px;margin-top:18px;color:#333333;font-size:16px;letter-spacing:0;line-height:24px;', ' ', ';', ';'], function (_ref) {
  var font = _ref.font;
  return font && 'font-family:' + font + ';';
}, _theme2.default.max.medium(_templateObject4), _theme2.default.min.large(_templateObject5));
var ModelVariantTitleContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelDescription__ModelVariantTitleContainer',
  componentId: 'sc-zqjxie-6'
})(['margin-bottom:15px;', ''], _theme2.default.max.medium(_templateObject6));

var ModelVariantContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelDescription__ModelVariantContainer',
  componentId: 'sc-zqjxie-7'
})(['box-sizing:border-box;border-bottom:1px solid #cbcbca;padding:2px 0;']);

var ModelVariant = _styledComponents2.default.a.withConfig({
  displayName: 'ModelDescription__ModelVariant',
  componentId: 'sc-zqjxie-8'
})(['color:#333333;', ' font-size:14px;font-weight:500;letter-spacing:0;line-height:24px;text-transform:uppercase;text-decoration:none;&:hover{text-decoration:underline;}'], function (_ref2) {
  var font = _ref2.font;
  return font && 'font-family:' + font + ';';
});

var LogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ModelDescription__LogoContainer',
  componentId: 'sc-zqjxie-9'
})(['margin-bottom:36px;', ';'], _theme2.default.max.medium(_templateObject7));

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'ModelDescription__Logo',
  componentId: 'sc-zqjxie-10'
})(['max-width:300px;']);

var ModelDescription = function ModelDescription(_ref3) {
  var subtitle = _ref3.subtitle,
      modelLogo = _ref3.modelLogo,
      logoAlternativeText = _ref3.logoAlternativeText,
      paragraphs = _ref3.paragraphs,
      modelVariants = _ref3.modelVariants,
      titleVariants = _ref3.titleVariants;

  var _useSelector = (0, _reactRedux.useSelector)(_settingsTyped.getGlobalStyling),
      fonts = _useSelector.fonts;

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl,
      navigateInternalUrl = _useNavigation.navigateInternalUrl;

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        DescriptionWrapper,
        null,
        _react2.default.createElement(
          SeventyContainer,
          null,
          modelLogo && _react2.default.createElement(
            LogoContainer,
            null,
            _react2.default.createElement(Logo, { src: modelLogo, alt: logoAlternativeText })
          ),
          _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return '\n                font-family: ' + fonts.primaryFont.value + ';\n                font-size: 28px;\n                font-weight: bold;\n                color: #1F1F1F;\n                text-transform: uppercase;\n                letter-spacing: 0;\n                line-height: 33px;';
              },
              mobileStyleOverride: function mobileStyleOverride() {
                return [{
                  queryPath: 'max.medium',
                  template: 'font-size: 22px; \n                    line-height: 25px;'
                }];
              }
            },
            subtitle
          ),
          _react2.default.createElement(
            ParagraphsContainer,
            null,
            paragraphs.map(function (p) {
              return _react2.default.createElement(
                'div',
                { key: p },
                p
              );
            })
          )
        ),
        modelVariants && _react2.default.createElement(
          ThirtyContainer,
          null,
          _react2.default.createElement(
            ModelVariantTitleContainer,
            null,
            _react2.default.createElement(
              _Global.HeadingTwo,
              {
                styleOverride: function styleOverride() {
                  return '\n                  font-family: ' + fonts.primaryFont.value + '; \n                  font-size: 22px;\n                  font-weight: bold;\n                  color: #1F1F1F;\n                  text-transform: uppercase;\n                  letter-spacing: 0;\n                  line-height: 25px;';
                },
                mobileStyleOverride: function mobileStyleOverride() {
                  return [{
                    queryPath: 'max.medium',
                    template: 'font-size: 18px;'
                  }];
                }
              },
              titleVariants
            )
          ),
          modelVariants.map(function (variant) {
            return _react2.default.createElement(
              ModelVariantContainer,
              { key: variant.text },
              _react2.default.createElement(
                ModelVariant,
                {
                  font: fonts.paragraphFont.value,
                  target: getTargetUrl(variant.url),
                  href: variant.url,
                  onClick: function onClick(event) {
                    event.preventDefault();
                    navigateInternalUrl(variant.url);
                  }
                },
                variant.text
              )
            );
          })
        )
      )
    )
  );
};

exports.default = ModelDescription;