'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Minus;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconWrapper = require('../../IconWrapper');

var _IconWrapper2 = _interopRequireDefault(_IconWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Minus(_ref) {
  var background = _ref.background,
      colour = _ref.colour,
      width = _ref.width,
      height = _ref.height;

  return _react2.default.createElement(
    _IconWrapper2.default,
    {
      background: background,
      colour: colour,
      width: width,
      height: height
    },
    _react2.default.createElement(
      'svg',
      { width: '1em', height: '1em', viewBox: '0 0 50 10' },
      _react2.default.createElement('path', { d: 'M13 1h22v4H13z' })
    )
  );
}

Minus.defaultProps = {
  colour: null,
  width: null,
  height: null,
  background: null
};