'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.widthOptions = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n  '], ['\n    flex-direction: column;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: auto;\n    padding: 0;\n    height: 300px;\n  '], ['\n    width: auto;\n    padding: 0;\n    height: 300px;\n  ']);
/* eslint-env browser */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _GoogleMap = require('../GoogleMap');

var _GoogleMap2 = _interopRequireDefault(_GoogleMap);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _RetailerInformation = require('../../modules/RetailerInformation/RetailerInformation');

var _RetailerInfo = require('./RetailerInfo');

var _RetailerInfo2 = _interopRequireDefault(_RetailerInfo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var widthOptions = exports.widthOptions = {
  widthOptionOne: { mapWidth: 50, retailerWidth: 50 },
  widthOptionTwo: { mapWidth: 60, retailerWidth: 40 },
  widthOptionThree: { mapWidth: 70, retailerWidth: 30 }
};

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__Container',
  componentId: 'sc-1mw4k7b-0'
})(['background-color:#ffffff;display:flex;flex-direction:column;align-items:', ';'], function (_ref) {
  var alignItems = _ref.alignItems;
  return alignItems ? '' + alignItems : 'center';
});

var RetailerContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__RetailerContainer',
  componentId: 'sc-1mw4k7b-1'
})(['display:flex;justify-content:center;height:auto;height:100%;width:100%;max-width:1336px;', ';', ';', ';'], function (_ref2) {
  var minHeight = _ref2.minHeight;
  return minHeight && 'min-height: ' + minHeight + 'px';
}, _theme2.default.max.large(_templateObject), function (_ref3) {
  var retailerContainerPadding = _ref3.retailerContainerPadding;
  return retailerContainerPadding && 'padding: ' + retailerContainerPadding;
});

var MapContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__MapContainer',
  componentId: 'sc-1mw4k7b-2'
})(['margin-top:20px;', ';', ';'], function (_ref4) {
  var width = _ref4.width;
  return width && 'width: ' + width + '%';
}, _theme2.default.max.large(_templateObject2));
var GoogleMapStatic = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__GoogleMapStatic',
  componentId: 'sc-1mw4k7b-3'
})(['width:100%;height:100%;background-repeat:no-repeat;background-position:center;line-height:0;', ';'], function (_ref5) {
  var url = _ref5.url;
  return url && 'background-image: url(' + url + ')';
});

var StyledInfoSection = _styledComponents2.default.div.withConfig({
  displayName: 'VdpRetailerMap__StyledInfoSection',
  componentId: 'sc-1mw4k7b-4'
})(['padding:8px 0;', ';'], function (_ref6) {
  var borderBottom = _ref6.borderBottom;
  return '' + (borderBottom.lineBreak && 'border-bottom: ' + borderBottom.borderSectionColour);
});

var InfoSection = function InfoSection(_ref7) {
  var children = _ref7.children,
      lineBreak = _ref7.lineBreak,
      borderSectionColour = _ref7.borderSectionColour,
      qaHook = _ref7.qaHook;
  return _react2.default.createElement(
    StyledInfoSection,
    {
      'data-cy': qaHook,
      borderBottom: { lineBreak: lineBreak, borderSectionColour: borderSectionColour }
    },
    children
  );
};

InfoSection.defaultProps = {
  lineBreak: false,
  qaHook: undefined
};

var VdpRetailerMap = function (_Component) {
  (0, _inherits3.default)(VdpRetailerMap, _Component);

  function VdpRetailerMap(props) {
    (0, _classCallCheck3.default)(this, VdpRetailerMap);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VdpRetailerMap.__proto__ || (0, _getPrototypeOf2.default)(VdpRetailerMap)).call(this, props));

    Object.defineProperty(_this, 'flipRetailerCoordinates', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            config = _this$props.config,
            retailer = _this$props.retailer;

        return config.flipRetailerCoordinates ? retailer.location && retailer.location.coordinates && retailer.location.coordinates.length === 2 && {
          coordinates: [retailer.location.coordinates[1], retailer.location.coordinates[0]]
        } : retailer.location;
      }
    });


    _this.state = {};
    return _this;
  }

  (0, _createClass3.default)(VdpRetailerMap, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          config = _props.config,
          retailer = _props.retailer,
          translations = _props.translations,
          viewRetailerStock = _props.viewRetailerStock,
          makeAnEnquiry = _props.makeAnEnquiry,
          googleAvailable = _props.googleAvailable,
          vehicleInfo = _props.vehicleInfo,
          isDWS = _props.isDWS;


      return _react2.default.createElement(
        Container,
        {
          id: 'retailerMap',
          bgColour: config.backgroundColour,
          alignItems: config.alignItems
        },
        _react2.default.createElement(
          RetailerContainer,
          {
            'data-cy': 'retailer-module',
            minHeight: config.contentHeight,
            retailerContainerPadding: config.retailerContainerPadding
          },
          _react2.default.createElement(_RetailerInfo2.default, {
            config: config,
            translations: translations,
            vehicle: vehicleInfo,
            makeAnEnquiry: makeAnEnquiry,
            viewRetailerStock: viewRetailerStock,
            isDWS: isDWS
          }),
          _react2.default.createElement(
            MapContainer,
            { width: widthOptions[config.widthOptionStyle].mapWidth },
            config.map.provider === 'google' ? config.map.type === 'dynamic' && googleAvailable ? _react2.default.createElement(_GoogleMap2.default, {
              mapName: 'vdpMap',
              retailerLocation: this.flipRetailerCoordinates(),
              iconPin: config.iconPin
            }) : _react2.default.createElement(GoogleMapStatic, { url: retailer.dealerLocationMapUrl }) : null
          )
        )
      );
    }
  }]);
  return VdpRetailerMap;
}(_react.Component);

exports.default = VdpRetailerMap;