'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.default = SelectField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'SelectField__Select',
  componentId: 'sc-8okur6-0'
})(['width:100%;height:45px;font-size:16px;font-family:inherit;line-height:40px;box-sizing:border-box;border:1px solid ', ';padding-left:15px;padding-right:15px;color:#7e7e7e;background-color:#fff;&:disabled{border:none;background-color:#d6d6d6;-moz-appearance:none;-webkit-appearance:none;padding:0 8px;::-ms-expand{display:none;}}&:focus{border:1px solid #999999;color:#444444;outline:none;}'], function (props) {
  return props.error ? '#9e1b32' : '#d8d8d8';
});

function SelectField(_ref) {
  var doubleRow = _ref.doubleRow,
      mobileCode = _ref.mobileCode,
      label = _ref.label,
      required = _ref.required,
      keyValue = _ref.keyValue,
      onChange = _ref.onChange,
      disabled = _ref.disabled,
      options = _ref.options,
      valueFromOption = _ref.valueFromOption,
      labelFromOption = _ref.labelFromOption,
      error = _ref.error,
      value = _ref.value,
      placeholder = _ref.placeholder,
      globalStyling = _ref.globalStyling,
      dataCy = _ref.dataCy;

  return _react2.default.createElement(
    _FormField2.default,
    {
      doubleRow: doubleRow,
      mobileCode: mobileCode,
      label: label,
      required: required,
      globalStyling: globalStyling,
      labelFor: keyValue
    },
    _react2.default.createElement(
      Select,
      {
        onChange: function (_onChange) {
          function onChange(_x) {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function (_ref2) {
          var _ref2$target = _ref2.target,
              val = _ref2$target.value,
              op = _ref2$target.options,
              selectedIndex = _ref2$target.selectedIndex;

          var optionLabel = op[selectedIndex].innerText;
          onChange({ keyValue: keyValue, value: val, label: optionLabel });
        }),
        disabled: disabled,
        error: error,
        value: value || placeholder,
        'data-cy': dataCy,
        id: keyValue,
        name: keyValue
      },
      [!value && placeholder && _react2.default.createElement(
        'option',
        { key: '' + placeholder, value: placeholder },
        placeholder
      )].concat((0, _toConsumableArray3.default)(options.filter(function (opt) {
        return opt.label !== undefined;
      }).map(function (o) {
        var optionLabel = labelFromOption(o);
        var optionValue = valueFromOption(o);
        return _react2.default.createElement(
          'option',
          {
            key: '' + optionValue + optionLabel,
            value: optionValue
          },
          optionLabel
        );
      }))).filter(Boolean)
    ),
    error && _react2.default.createElement(
      'div',
      {
        style: {
          color: '#9e1b32',
          textAlign: '' + (globalStyling && globalStyling.direction === 'rtl' ? 'right' : 'left')
        }
      },
      error
    )
  );
}

SelectField.defaultProps = {
  valueFromOption: function valueFromOption(i) {
    return i.value;
  },
  labelFromOption: function labelFromOption(i) {
    return i.label;
  },
  error: ''
};