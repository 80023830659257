'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Checkbox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Checkbox__Container',
  componentId: 'sc-1c8h8qc-0'
})(['position:relative;cursor:', ';border:1px solid ', ';background-color:', ';width:19px;height:19px;'], function (_ref) {
  var enabled = _ref.enabled;
  return enabled ? 'pointer' : '';
}, function (_ref2) {
  var colour = _ref2.colour;
  return colour;
}, function (_ref3) {
  var checked = _ref3.checked,
      enabled = _ref3.enabled,
      altColour = _ref3.altColour;
  return enabled ? checked ? 'transparent' : altColour : '';
});

var CheckImage = _styledComponents2.default.div.withConfig({
  displayName: 'Checkbox__CheckImage',
  componentId: 'sc-1c8h8qc-1'
})(['position:absolute;transform:rotate(45deg);height:12px;width:5px;left:6px;top:0px;border-bottom:2px solid ', ';border-right:2px solid ', ';'], function (_ref4) {
  var colour = _ref4.colour;
  return colour;
}, function (_ref5) {
  var colour = _ref5.colour;
  return colour;
});

function Checkbox(_ref6) {
  var onClick = _ref6.onClick,
      checked = _ref6.checked,
      _ref6$enabled = _ref6.enabled,
      enabled = _ref6$enabled === undefined ? true : _ref6$enabled,
      _ref6$lightMode = _ref6.lightMode,
      lightMode = _ref6$lightMode === undefined ? false : _ref6$lightMode;

  var altColour = lightMode ? '#fff' : '#000';
  var colour = lightMode ? '#000' : '#fff';
  return _react2.default.createElement(
    Container,
    {
      checked: checked,
      onClick: onClick,
      enabled: enabled,
      colour: colour,
      altColour: altColour
    },
    checked && _react2.default.createElement(CheckImage, { colour: colour })
  );
}