'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)([' ', ''], [' ', '']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 10px;\n    line-height: 13px;\n  '], ['\n    font-size: 10px;\n    line-height: 13px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    height: 15px;\n  '], ['\n    height: 15px;\n  ']);

exports.default = ImgCollage;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__ImageContainer',
  componentId: 'sc-2r8sor-0'
})(['position:relative;width:100%;max-width:1920px;height:fit-content(768px);display:grid;grid-template-columns:auto;grid-template-rows:auto;grid-column-gap:0px;grid-row-gap:0px;grid-template-areas:', ';', ';', ';cursor:pointer;'], function (_ref) {
  var vdp = _ref.vdp;
  return vdp ? "'large large large small1' 'large large large small2' 'large large large small3';" : "'large large large' 'small1 small2 small3';";
}, _theme2.default.max.xxl(_templateObject, function (_ref2) {
  var vdp = _ref2.vdp;
  return !vdp && "grid-template-areas: 'large large large small1' 'large large large small2' 'large large large small3';";
}), _theme2.default.max.large(_templateObject2, function (_ref3) {
  var vdp = _ref3.vdp;
  return vdp && "grid-template-areas: 'large large large' 'small1 small2 small3';";
}));
var Image = _styledComponents2.default.img.withConfig({
  displayName: 'ImgCollage__Image',
  componentId: 'sc-2r8sor-1'
})(['aspect-ratio:3 / 2;display:block;object-fit:cover;width:100%;max-width:100%;', ''], function (_ref4) {
  var gridArea = _ref4.gridArea;
  return gridArea && 'grid-area: ' + gridArea + ';';
});
var MainImage = (0, _styledComponents2.default)(Image).withConfig({
  displayName: 'ImgCollage__MainImage',
  componentId: 'sc-2r8sor-2'
})(['width:1920px;max-width:100%;']);
var IconContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__IconContainer',
  componentId: 'sc-2r8sor-3'
})(['position:absolute;display:flex;justify-content:space-between;background-color:rgba(255,255,255,1);border-radius:18px;padding:7px 14px;margin:10px;font-family:AstonMartinSans-Regular;font-size:14px;align-items:center;padding-right:10px;gap:10px;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__ButtonContainer',
  componentId: 'sc-2r8sor-4'
})(['cursor:pointer;display:flex;align-items:center;justify-content:center;']);

var CountContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ImgCollage__CountContainer',
  componentId: 'sc-2r8sor-5'
})(['font-size:14px;letter-spacing:0;line-height:18px;', ';'], _theme2.default.max.medium(_templateObject3));

var ImgButton = _styledComponents2.default.img.withConfig({
  displayName: 'ImgCollage__ImgButton',
  componentId: 'sc-2r8sor-6'
})(['height:18px;', ';'], _theme2.default.max.medium(_templateObject4));

function ImgCollage(_ref5) {
  var images = _ref5.images,
      video = _ref5.video,
      onClick = _ref5.onClick,
      placeholder = _ref5.placeholder,
      videoOnClick = _ref5.videoOnClick,
      config = _ref5.config,
      cameraOnClick = _ref5.cameraOnClick,
      vdp = _ref5.vdp;

  var getImage = function getImage(position) {
    return images[images.length === 1 ? 0 : position] || placeholder;
  };

  var stopPropagation = function stopPropagation(func) {
    return function (event) {
      event.stopPropagation();
      func();
    };
  };

  return _react2.default.createElement(
    ImageContainer,
    { onClick: onClick, vdp: vdp },
    _react2.default.createElement(
      IconContainer,
      null,
      _react2.default.createElement(
        ButtonContainer,
        {
          role: 'button',
          onClick: stopPropagation(cameraOnClick),
          tabIndex: 0
        },
        _react2.default.createElement(ImgButton, { src: config.iconCamera, alt: 'camera icon' })
      ),
      _react2.default.createElement(
        CountContainer,
        null,
        images.length
      ),
      video && _react2.default.createElement(
        ButtonContainer,
        {
          role: 'button',
          onClick: stopPropagation(videoOnClick),
          tabIndex: 0
        },
        _react2.default.createElement(ImgButton, { src: config.iconVideo, alt: 'video icon' })
      )
    ),
    _react2.default.createElement(MainImage, { src: getImage(0), gridArea: 'large' }),
    _react2.default.createElement(Image, { src: getImage(1), gridArea: 'small1' }),
    _react2.default.createElement(Image, { src: getImage(2), gridArea: 'small2' }),
    _react2.default.createElement(Image, { src: getImage(3), gridArea: 'small3' })
  );
}