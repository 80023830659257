'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.constants = undefined;

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// todo move to another file
var id = 'Enquiry';
var modulePrefix = 'MOD_ENQUIRY';

var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['ADDRESS_SUGGESTIONS_REQUESTED', 'ADDRESS_SUGGESTIONS_RECEIVED', 'ADDRESS_SUGGESTIONS_FAILURE', 'ADDRESS_SELECTED', 'GET_LANGUAGE_OPTIONS', 'GET_LANGUAGE_OPTIONS_SUCCESS', 'SUBMIT_THIRD_PARTY_LEAD', 'GET_VIN_VALIDITY', 'GET_VIN_VALIDITY_SUCCESS', 'GET_VIN_VALIDITY_FAILURE', 'RECAPTCHA_VALIDATION_FAILED']);

var actions = exports.actions = {
  addressSuggestionsRequested: (0, _reducerMacros.moduleActionWithArgs)(constants.ADDRESS_SUGGESTIONS_REQUESTED, id, function (addressPart) {
    return addressPart;
  }),
  addressSuggestionsReceived: (0, _reducerMacros.moduleActionWithArgs)(constants.ADDRESS_SUGGESTIONS_RECEIVED, id, function (suggestions) {
    return suggestions;
  }),
  addressSelected: (0, _reducerMacros.moduleAction)(constants.ADDRESS_SELECTED, id, function (suggestions) {
    return suggestions;
  }),
  addressSuggestionsFailed: (0, _reducerMacros.moduleAction)(constants.ADDRESS_SUGGESTIONS_FAILURE, id, function (suggestions) {
    return suggestions;
  }),
  getLanguageOptions: (0, _reducerMacros.moduleAction)(constants.GET_LANGUAGE_OPTIONS, id),
  getLanguageOptionsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_LANGUAGE_OPTIONS_SUCCESS, id, function (languageOptions) {
    return languageOptions;
  }),
  submitThirdPartyLead: (0, _reducerMacros.moduleActionWithArgs)(constants.SUBMIT_THIRD_PARTY_LEAD, id, function (lead) {
    return lead;
  }),
  getVinValidity: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VIN_VALIDITY, id, function (payload) {
    return payload;
  }),
  getVinValiditySuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VIN_VALIDITY_SUCCESS, id, function (payload) {
    return payload;
  }),
  getVinValidityFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VIN_VALIDITY_FAILURE, id, function (payload) {
    return payload;
  }),
  submitRecaptchaValidationFailure: (0, _reducerMacros.moduleAction)(constants.RECAPTCHA_VALIDATION_FAILED, id)
};

function reducer(state, action) {
  switch (action.type) {
    case constants.ADDRESS_SUGGESTIONS_REQUESTED:
      return (0, _extends3.default)({}, state, {
        suggestionRequestStatus: 'loading'
      });
    case constants.ADDRESS_SUGGESTIONS_RECEIVED:
      return (0, _extends3.default)({}, state, {
        addressSuggestions: [].concat((0, _toConsumableArray3.default)(action.payload)),
        suggestionRequestStatus: 'loaded'
      });
    case constants.ADDRESS_SELECTED:
      return (0, _extends3.default)({}, state, {
        addressSuggestions: [],
        suggestionRequestStatus: 'idle'
      });
    case constants.ADDRESS_SUGGESTIONS_FAILURE:
      return (0, _extends3.default)({}, state, {
        addressSuggestions: [],
        suggestionRequestStatus: 'failure'
      });
    case constants.GET_LANGUAGE_OPTIONS_SUCCESS:
      return (0, _extends3.default)({}, state, {
        languageOptions: action.payload
      });
    case constants.GET_VIN_VALIDITY_SUCCESS:
      return (0, _extends3.default)({}, state, {
        vinValidity: action.payload
      });
    case constants.GET_VIN_VALIDITY_FAILURE:
      return (0, _extends3.default)({}, state, {
        vinValidity: action.payload
      });
    case constants.RECAPTCHA_VALIDATION_FAILED:
      {
        return (0, _extends3.default)({}, state, {
          submissionStatus: 'RecaptchaValidationFailed'
        });
      }
    default:
      return state;
  }
}