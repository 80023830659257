'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _components = require('../components');

var _Global = require('../components/Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Brick = _styledComponents2.default.div.withConfig({
  displayName: 'Brick',
  componentId: 'sc-1bkk233-0'
})(['display:flex;flex-direction:', ';align-items:center;'], function (_ref) {
  var alignment = _ref.alignment;
  return alignment;
});

var BrickText = _styledComponents2.default.div.withConfig({
  displayName: 'Brick__BrickText',
  componentId: 'sc-1bkk233-1'
})(['', ';'], function (_ref2) {
  var alignment = _ref2.alignment;
  return 'padding-' + alignment + ': 16px;';
});

var align = {
  left: 'row',
  right: 'row-reverse'
};

exports.default = function (_ref3) {
  var _ref3$config = _ref3.config,
      translations = _ref3$config.translations,
      image = _ref3$config.image,
      headlineFont = _ref3$config.headlineFont,
      bodyFont = _ref3$config.bodyFont,
      alignment = _ref3$config.alignment,
      padding = _ref3$config.padding,
      border = _ref3$config.border,
      borderColour = _ref3$config.borderColour,
      backgroundColour = _ref3$config.backgroundColour,
      imagePosition = _ref3$config.imagePosition;
  return _react2.default.createElement(
    _components.Wrapper,
    {
      border: border,
      backgroundColour: backgroundColour,
      borderColour: borderColour
    },
    _react2.default.createElement(
      _components.TextWrapper,
      { padding: padding },
      _react2.default.createElement(
        Brick,
        { alignment: align[alignment] },
        image && _react2.default.createElement(_components.Icon, {
          src: image.src,
          imagePosition: imagePosition,
          imageHeight: '40',
          role: 'img',
          'aria-label': translations.imageLabel
        }),
        _react2.default.createElement(
          BrickText,
          { alignment: alignment },
          _react2.default.createElement(
            _Global.HeadingOne,
            { styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)(headlineFont);
              } },
            translations.headlineText
          ),
          _react2.default.createElement(
            _Global.Paragraph,
            { styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)(bodyFont);
              } },
            translations.bodyText
          )
        )
      )
    )
  );
};